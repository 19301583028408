import { Flex, Grid, Group, Loader, Stack, Text } from '@mantine/core';
import React from 'react';
import { BackButton } from '~/components/back-button';
import { BreadcrumbCreator } from '~/components/breadcrumb-creator';
import {
    TopicDownloadIndicatorData,
    TopicIndicatorGraphView,
    TopicIndicatorInfoFilters,
    TopicIndicatorInfoViewBy,
    TopicIndicatorTableViewV2,
    TopicIndicators,
    TopicTitle,
} from '~/domains/indicator/ui/components';
import { useTopicPage } from '~/domains/indicator/ui/hooks';
import { routePaths } from '~/router/router';

import TopicIndicatorMapView from '~/domains/indicator/ui/components/topic-indicator-map-view';
import { useTopicDescriptionBank } from '~/domains/topic/ui/hooks/use-topics-descriptions-bank';

export const TopicPage: React.FC = function () {
    const {
        indicators,
        loading,
        title,
        selectedIndicator,
        onSelectedIndicatorsChange,
        indicatorInfo,
        loadingInfo,
        indicatorInfoExtra,
        onChangeFilter,
        selectedFilters,
        selectedView,
        onChangeSelectedView,
        viewOptions,
        mapData,
        selectedAreas,
        onChangeSelectedAreas,
        zoneInformation,
        selectedZones,
        setSelectedZones,
        defaultTimeValue,
        time_periods,
        tableDataV3,
        currentIndicatorName,
        isFiltersOpened,
        onOpenFilters,
        onCloseFilters,
        selectedFilterTab,
        onChangeSelectedFilterTab,
        flatZoneInformation
    } = useTopicPage();

    const { getTopicDescription } = useTopicDescriptionBank()

    const visualTitle = `${currentIndicatorName} ${(function () {
        const targetZone = zoneInformation.filter(
            (zone) => selectedZones.includes(zone.name),
        ).map(dt => dt.name).join(', ');

        return targetZone ? `- ${targetZone}` : '';
    })()} ${(function () {
        const periodString = time_periods?.join(', ') ?? ""
        return `(${periodString})`
    })()}`

    const breadCrumbs = React.useMemo(
        () => [{title: 'DHS', path: routePaths.root}, {title: 'Topics', path: routePaths.topics}, {title}],
        [title],
    );
    return (
        <Stack data-testid="container" spacing={'lg'}>
            <Group spacing={'xl'} position="apart">
                <BreadcrumbCreator data={breadCrumbs}/>
                <BackButton/>
            </Group>

            <TopicTitle title={title}/>

            <Text>
                {getTopicDescription(title)}
            </Text>

            <Grid bg={'white'} mt={'md'}>
                <Grid.Col span={4} sx={{overflowY: 'scroll'}}>
                    <TopicIndicators
                        indicators={indicators}
                        loading={loading}
                        selectedIndicator={selectedIndicator}
                        onSelectedIndicatorChange={onSelectedIndicatorsChange}
                        title={title}
                    />
                </Grid.Col>

                <Grid.Col
                    span={8}
                    bg={'white'}
                    h={'auto'}
                    p={'md'}
                    sx={(theme) => ({
                        overflowY: 'scroll',
                        borderRadius: theme.radius.md,
                        border: `1px solid ${theme.colors.gray[3]}`,
                    })}
                >
                    {selectedIndicator ? (
                        <Stack data-testid="indicators-info-box" spacing={'md'}>
                            {loadingInfo ? (
                                <Group spacing={'sm'}>
                                    <Loader size={'sm'}/>
                                    <Text size={'sm'} color="dimmed">
                                        loading {currentIndicatorName} information
                                    </Text>
                                </Group>
                            ) : (
                                <>
                                    <Flex gap={'md'} justify={'space-between'} align={'center'}>
                                        <TopicIndicatorInfoViewBy
                                            {...{
                                                selectedView,
                                                onChangeSelectedView,
                                                viewOptions,
                                                selectedTimePeriods: time_periods,
                                                selectedTimeSubGroups: selectedFilters.subGroups ?? [],
                                            }}
                                        />

                                        <Group>
                                            <TopicIndicatorInfoFilters
                                                indicatorInfoExtra={indicatorInfoExtra}
                                                onChangeFilter={onChangeFilter}
                                                selectedFilters={selectedFilters}
                                                defaultTimeValue={defaultTimeValue}
                                                selectedAreas={selectedAreas}
                                                onChangeSelectedAreas={onChangeSelectedAreas}
                                                selectedZones={selectedZones}
                                                setSelectedZones={setSelectedZones}
                                                zones={zoneInformation?.map((zone) => zone?.name) ?? []}
                                                isFiltersOpened={isFiltersOpened}
                                                onOpenFilters={onOpenFilters}
                                                onCloseFilters={onCloseFilters}
                                                selectedFilterTab={selectedFilterTab}
                                                onChangeSelectedFilterTab={onChangeSelectedFilterTab}
                                            />

                                            <TopicDownloadIndicatorData
                                                currentIndicatorName={visualTitle}
                                                time_periods={time_periods}
                                                columns1={tableDataV3.columns.column1}
                                                columns2={tableDataV3.columns.column2}
                                                data={tableDataV3.data}
                                            />
                                        </Group>
                                    </Flex>
                                    <div className='flex justify-center items-center'>
                                            <small>
                                                {tableDataV3.source.map((item, index) => (
                                                    time_periods.includes(item.time_value) && (
                                                        <span key={index}>
                                                            {item.source_name}
                                                            {index < tableDataV3.source.length - 1 && ', '}
                                                        </span>
                                                    )
                                                ))}
                                            </small>
                                        </div>
                                    <TopicIndicatorTableViewV2
                                        show={selectedView === 'table'}
                                        title={visualTitle}
                                        selectedTimePeriods={time_periods}
                                        tableDataV3={tableDataV3}
                                        zone={(function () {
                                            const targetZone = zoneInformation.find(
                                                (zone) => zone.name === selectedZones[0],
                                            );
                                            return {
                                                name: targetZone?.name as string,
                                                value: targetZone?.total,
                                            };
                                        })()}
                                    />

                                    <TopicIndicatorGraphView
                                        areasToNotShow={[]}
                                        show={selectedView === 'graph'}
                                        dataV2={tableDataV3}
                                        title={visualTitle}
                                        period={selectedFilters?.periods?.length ? selectedFilters?.periods[0] : ''}
                                        showSubTitle={selectedZones.length === 1}
                                        selectedZones={selectedZones}
                                        flatZoneInformation={flatZoneInformation}
                                        zone={(function () {
                                            const targetZone = zoneInformation.find(
                                                (zone) => zone.name === selectedZones[0],
                                            );
                                            return {
                                                name: targetZone?.name as string,
                                                value: targetZone?.total,
                                            };
                                        })()}
                                        tanzaniaValue={(function () {
                                            const valueIndex = tableDataV3.columns.column1.findIndex(
                                                (dt) => dt === 'Total',
                                            );

                                            if (valueIndex >= 0) {
                                                const valueCheck = indicatorInfo.find(
                                                    (dt) =>
                                                        dt.area_name === 'Tanzania' &&
                                                        dt.subgroup_desc === 'Total' &&
                                                        (selectedFilters.periods
                                                            ? dt.time_value === selectedFilters.periods[0]
                                                            : null),
                                                )?.data_value;

                                                return valueCheck ?? undefined;
                                            }
                                            return undefined;
                                        })()}
                                    />

                                    <TopicIndicatorMapView
                                        indicatorInfoExtra={indicatorInfoExtra}
                                        selectedFilters={selectedFilters}
                                        show={selectedView === 'map'}
                                        title={visualTitle}
                                        mapData={mapData}
                                        dataV2={tableDataV3}
                                    />
                                </>
                            )}
                        </Stack>
                    ) : (
                        <Text color={'dimmed'} size={'sm'}>
                            No indicator selected !!
                        </Text>
                    )}
                </Grid.Col>
            </Grid>
        </Stack>
    );
};

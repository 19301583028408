import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { MantineProvider } from "@mantine/core";
import "./index.css";
import i18n from "./i18n/i18n";
import { I18nextProvider } from "react-i18next";
import { Notifications } from "@mantine/notifications";

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
                colorScheme: "light",
                colors: {
                    brand: [
                        "#e3faff",
                        "#d0f1fd",
                        "#a3dff7",
                        "#74cdf2",
                        "#4ebeed",
                        "#37b5eb",
                        "#26b0ea",
                        "#129ad1",
                        "#0089bb",
                        "#0077a7",
                    ],
                },
                primaryColor: "brand",
                primaryShade: { light: 7, dark: 8 },
            }}
        >
            <I18nextProvider i18n={i18n}>
                <Notifications />
                <App />
            </I18nextProvider>
        </MantineProvider>
    </React.StrictMode>
);

import { useCallback, useEffect, useMemo, useState } from "react";
import { topicBank } from "../topic-bank";
import { MapShapeFileResponse } from "~/domains/indicator/indicator-repository-contract";
import { indicatorUseCases } from "~/domains/indicator/indicator-usecases";
import { choroplethMapTraits } from "~/domains/indicator/ui/utils/choropleth-map-trait";
import geoService from "~/domains/indicator/ui/utils/geo-server";
import { useGetTopics } from "~/domains/topic/ui/hooks";

export function useGetVisualizations() {
    const [topic, setTopic] = useState<string | undefined>();
    const onChangeTopic = useCallback((topic: string) => setTopic(topic), []);
    const topicInfo = useMemo(() => topicBank[topic] ?? undefined, [topic, topicBank]);

    const [loadingMapInfo, setLoadingMapInfo] = useState(false);
    const [, setMapShapeFiles] = useState<Awaited<MapShapeFileResponse> | undefined>(undefined);

    const {loading: topicsLoading, topics} = useGetTopics();

    useEffect(getMapShapeFilesUseEffect, []);

    return { topic, onChangeTopic, topicInfo, topicBank, loadingMapInfo, topicsLoading, topics: topics?.sort((a, b) => a.subsector_name > b.subsector_name ? 1 : -1) ?? [] };

    function getMapShapeFilesUseEffect() {
        (async function () {
            try {
                setLoadingMapInfo(true);

                const response: Awaited<MapShapeFileResponse> = await indicatorUseCases.getMapShapeFiles({
                    geoService,
                    getMapParams: choroplethMapTraits.getMapParams,
                });
                setMapShapeFiles(response);

                setLoadingMapInfo(false);
            } catch (error) {
                setLoadingMapInfo(false);
                throw error;
            }
        })();
    }
}

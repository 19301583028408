import { Box, Loader, Radio, Stack, Text, TextInput, rem } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import React, { useState } from "react";

export const TopicIndicators: React.FC<{
    indicators: {
        indicatorkey: string;
        indicator_name: string;
    }[];
    loading?: boolean;
    onSelectedIndicatorChange(value: string): void;
    selectedIndicator: string;
    title: string;
}> = function ({ indicators, loading, onSelectedIndicatorChange, selectedIndicator, title }) {
    const [search, setSearch] = useState("");
    const filteredIndicators = indicators.filter((indicator) =>
        search
            ? indicator.indicator_name.toLowerCase().split(" ").join().includes(search.toLowerCase().split(" ").join())
            : true
    );

    return (
        <Stack spacing={"xs"} data-testid="indicators-box">
            <Text size={"md"}>
                Main Indicators for {title}
            </Text>

            <TextInput
                value={search}
                onChange={({ target: { value } }) => setSearch(value)}
                variant="default"
                bg="white"
                icon={<IconSearch size={rem(15)} />}
                placeholder="Search ..."
            />

            <Box>
                <Stack>
                    <Radio.Group value={selectedIndicator} onChange={onSelectedIndicatorChange} name="indicator">
                        <Stack
                            mt="xs"
                            px={"sm"}
                            bg={"white"}
                            sx={(theme) => ({
                                border: `1px solid ${theme.colors.gray[4]}`,
                                borderRadius: theme.radius.md,
                            })}
                        >
                            {loading ? (
                                <Box p={"sm"}>
                                    <Loader size={"sm"} />
                                </Box>
                            ) : (
                                <Stack mah={"50vh"} h={"fit-content"} sx={{ overflowY: "scroll" }}>
                                    <Box h={10} />
                                    {filteredIndicators?.length ? (
                                        <>
                                            {filteredIndicators?.map((indicator) => (
                                                <Radio
                                                    mb={"sm"}
                                                    key={indicator.indicatorkey}
                                                    value={indicator.indicatorkey}
                                                    label={<Text size={"sm"}>{indicator.indicator_name}</Text>}
                                                />
                                            ))}
                                        </>
                                    ) : (
                                        <Text size={"sm"} color="dimmed">
                                            No indicators found!
                                        </Text>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    </Radio.Group>
                </Stack>
            </Box>
        </Stack>
    );
};

import { create } from "zustand";
import { TopicEntity } from "~/domains/topic/topic-entity.ts";

export interface useTopicsStoreI {
    topics?: TopicEntity[];
    updateTopics(topics: TopicEntity[] | undefined): void;
    topicsLoading: boolean;
}

export const useTopicsStore = create<useTopicsStoreI>()((set) => ({
    updateTopics(topics: TopicEntity[] | undefined) {
        set(() => ({ topics: topics }));
    },
    topicsLoading: false,
    onTopicsUnidentified() {},
}));

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IndicatorEntity } from "~/domains/indicator/indicator-entity.ts";
import {
    IndicatorInfoExtraI,
    indicatorUseCases,
} from "~/domains/indicator/indicator-usecases.ts";
import { topicUseCases } from "~/domains/topic/topic-usecases";
import { useTopicsStore } from "~/domains/topic/ui/state";
import { routePaths } from "~/router/router.tsx";
import { MapShapeFileResponse } from "../../indicator-repository-contract";
import { choroplethMapTraits } from "../utils/choropleth-map-trait";
import geoService from "../utils/geo-server";
import { useGetTopics } from "~/domains/topic/ui/hooks";
import { getTableData } from "~/utils/functions/get-table-data";
import { useDisclosure } from "@mantine/hooks";
import {
    areaZoneColorMapV2,
    areaZonesTv2,
} from "~/domains/visualization/utils/areaZonesColors.ts";

export const sampleZoneInfo: {
    name: areaZonesTv2;
    regions: string[];
}[] = [
    {
        name: "Lake Zone",
        regions: ["Mwanza", "Kagera", "Geita", "Mara", "Simiyu", "Shinyanga"],
    },
    {
        name: "Northern Zone",
        regions: ["Arusha", "Kilimanjaro", "Tanga"],
    },
    {
        name: "Central Zone",
        regions: ["Dodoma", "Singida", "Manyara"],
    },
    {
        name: "Southern Zone",
        regions: ["Lindi", "Mtwara"],
    },
    {
        name: "Eastern Zone",
        regions: ["Dar es Salaam", "Dar-es-salaam", "Pwani", "Morogoro"],
    },
    {
        name: "Western Zone",
        regions: ["Kigoma", "Tabora"],
    },
    {
        name: "Southern Highland Zone",
        regions: ["Iringa", "Njombe", "Ruvuma"],
    },
    {
        name: "Southwest Highlands Zone",
        regions: ["Mbeya", "Rukwa", "Katavi", "Songwe"],
    },
    {
        name: "Zanzibar Zone",
        regions: [
            "Mjini Magharibi",
            "Kusini Unguja",
            "Kaskazini Unguja",
            "Kaskazini Pemba",
            "Kusini Pemba",
        ],
    },
];

export type SelectedFilterKeyT =
    | "area"
    | "areas"
    | "subGroup"
    | "subGroups"
    | "period"
    | "periods";
export type zonesT =
    | "Lake Zone"
    | "Northern Zone"
    | "Central Zone"
    | "Southern Zone"
    | "Eastern Zone"
    | "Western Zone";

export interface SelectedFiltersI {
    area: string | null;
    areas: string[] | undefined;
    subGroup: string | null;
    subGroups: string[] | undefined;
    period: string | null;
    periods: string[] | undefined;
}

export type viewOptionT = "table" | "graph" | "map";

export const useTopicPage = function () {
    const { topicId } = useParams();

    const [loading, setLoading] = useState(false);

    const [indicators, setIndicators] = useState<
        {
            indicatorkey: string;
            indicator_name: string;
        }[]
    >([]);

    const [title, setTitle] = useState("");

    const navigate = useNavigate();

    const [selectedIndicator, setSelectedIndicators] = useState("");
    const onSelectedIndicatorsChange = function (value: string) {
        setSelectedIndicators(value);
    };

    const [loadingMapInfo, setLoadingMapInfo] = useState(false);
    const [mapShapeFiles, setMapShapeFiles] = useState<
        Awaited<MapShapeFileResponse> | undefined
    >(undefined);

    const [loadingInfo, setLoadingInfo] = useState(false);
    const [indicatorInfo, setIndicatorInfo] = useState<IndicatorEntity[]>([]);
    const [indicatorInfoExtra, setIndicatorInfoExtra] = useState<
        IndicatorInfoExtraI | undefined
    >(undefined);
    const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersI>({
        area: null,
        areas: undefined,
        period: null,
        periods: undefined,
        subGroup: null,
        subGroups: undefined,
    });

    const [selectedAreas, setSelectedAreas] = useState<string[]>([]);

    const onChangeFilter = React.useCallback(
        (
            type: SelectedFilterKeyT,
            value: string | string[] | null | undefined
        ) => {
            setSelectedFilters((prev) => {
                prev = { ...prev, [type]: value };
                return prev;
            });
        },
        []
    );

    const onChangeSelectedAreas = React.useCallback(
        (areaCode: string) => {
            if (selectedAreas.includes(areaCode)) {
                setSelectedAreas((prev) => {
                    prev = [...prev.filter((area) => area !== areaCode)];
                    return prev;
                });
                return;
            }

            setSelectedAreas((prev) => {
                prev = [...prev, areaCode];
                return prev;
            });
        },
        [selectedAreas]
    );

    const viewOptions: { label: string; value: viewOptionT }[] = [
        { label: "Table", value: "table" },
        { label: "Graph", value: "graph" },
        { label: "Map", value: "map" },
    ];

    const [selectedView, setSelectedView] = useState<viewOptionT>("table");

    const onChangeSelectedView = React.useCallback((type: viewOptionT) => {
        setSelectedView(type);
    }, []);

    const { allTopics: topicsFromHook, loading: topicsLoading } =
        useGetTopics();
    const { updateTopics, topics: _topics_from_store } = useTopicsStore();

    useEffect(effectHandler, [topicsFromHook]);
    useEffect(indicatorInfoUseEffect, [selectedIndicator, topicId]);
    useEffect(getMapShapeFilesUseEffect, []);

    const [selectedZones, setSelectedZones] = React.useState<areaZonesTv2[]>(
        []
    );
    const zoneInformation: {
        name: areaZonesTv2;
        regions: string[];
        total?: number;
        color: string;
    }[] = React.useMemo(
        () =>
            sampleZoneInfo.map((dt) => ({
                ...dt,
                color: areaZoneColorMapV2.get(dt.name),
            })),
        []
    );

    const defaultTimeValue = React.useMemo(
        () =>
            indicatorInfoExtra?.timeValues
                .map((val) => val.split("-")[0] ?? "")
                .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? -1 : 1))
                .map((val) =>
                    indicatorInfoExtra?.timeValues.find((value) =>
                        value.includes(val)
                    )
                )[0] ?? "",
        [indicatorInfoExtra?.timeValues]
    );

    const targetIndicatorInfo: IndicatorEntity[] = React.useMemo(() => {
        if (selectedZones.length) {
            const targetRegions: string[] = [];
            selectedZones.forEach((zone) => {
                let regions: string[] =
                    zoneInformation.find((dt) => dt.name === zone)?.regions ??
                    [];
                targetRegions.push(...regions);
            });

            return indicatorInfo
                .filter((dt) => selectedFilters.areas?.includes(dt.area_code))
                .filter((dt) => targetRegions.includes(dt.area_name));
        }
        return indicatorInfo.filter((dt) =>
            selectedFilters.areas?.includes(dt.area_code)
        );
    }, [selectedZones, zoneInformation, selectedFilters.areas, indicatorInfo]);

    const tableDataV3 = React.useMemo(
        () =>
            getTableData({
                indicatorInfo: targetIndicatorInfo,
                subgroups:
                    indicatorInfoExtra?.subGroups.filter((dt) =>
                        selectedFilters.subGroups?.includes(
                            dt._subgroupkey.toString()
                        )
                    ) ?? [],
                timePeriods: selectedFilters.periods ?? [],
            }),
        [
            indicatorInfoExtra?.subGroups,
            selectedFilters.periods,
            selectedFilters.areas,
            selectedFilters.subGroups,
            targetIndicatorInfo,
        ]
    );

    const [isFiltersOpened, { open: onOpenFilters, close: onCloseFilters }] =
        useDisclosure(false);
    const [selectedFilterTab, setSelectedFilterTab] =
        useState("selected-areas");
    const onChangeSelectedFilterTab = (value: string) =>
        setSelectedFilterTab(value);

    return {
        flatZoneInformation: zoneInformation
            .map((dt) =>
                dt.regions
                    .map((region) => ({
                        zone: dt.name,
                        region,
                        color: dt.color,
                        total: dt.total,
                    }))
                    .flat()
            )
            .flat(),
        title,
        indicators,
        loading: loading || topicsLoading,
        selectedIndicator,
        onSelectedIndicatorsChange,
        indicatorInfo,
        loadingInfo,
        indicatorInfoExtra,
        selectedFilters,
        onChangeFilter,
        selectedView,
        onChangeSelectedView,
        viewOptions,
        mapData: { loadingMapInfo, mapShapeFiles },
        selectedAreas,
        onChangeSelectedAreas,
        zoneInformation,
        selectedZones,
        setSelectedZones,
        defaultTimeValue,
        time_periods: selectedFilters?.periods?.length
            ? selectedFilters.periods
            : [defaultTimeValue],
        tableDataV3,
        currentIndicatorName: indicators?.find(
            (indicator) => indicator.indicatorkey === selectedIndicator
        )?.indicator_name,
        isFiltersOpened,
        onOpenFilters,
        onCloseFilters,
        selectedFilterTab,
        onChangeSelectedFilterTab,
    };

    function effectHandler() {
        if (!topicsFromHook) {
            (async function () {
                try {
                    setLoading(true);
                    const _topics = await topicUseCases.getAllTopics();
                    updateTopics(_topics.allTopics);
                    const topicInfo = await topicUseCases.getAllTopicIndicators(
                        Number(topicId!),
                        _topics.allTopics
                    );

                    if (topicInfo === false) {
                        navigate(routePaths.topics, { replace: true });
                    } else {
                        setIndicators(topicInfo.indicators);

                        const targetIndicator =
                            topicInfo.indicators[0].indicatorkey ?? "";
                        setSelectedIndicators(targetIndicator);

                        setTitle(topicInfo.title);

                        setLoading(false);
                    }
                } catch (error) {
                    setLoading(false);
                    throw error;
                }
            })();
        } else {
            (async function () {
                try {
                    setLoading(true);

                    const topicInfo = await topicUseCases.getAllTopicIndicators(
                        Number(topicId!),
                        topicsFromHook
                    );

                    if (topicInfo === false) {
                        navigate(routePaths.topics, { replace: true });
                    } else {
                        setIndicators(topicInfo.indicators);

                        const targetIndicator =
                            topicInfo.indicators[0].indicatorkey ?? "";
                        setSelectedIndicators(targetIndicator);

                        setTitle(topicInfo.title);

                        setLoading(false);
                    }
                } catch (error) {
                    setLoading(false);
                    throw error;
                }
            })();
        }
    }

    function indicatorInfoUseEffect() {
        if (topicId && selectedIndicator) {
            setSelectedZones([]);
            (async function () {
                try {
                    setLoadingInfo(true);

                    setSelectedFilters({
                        ...selectedFilters,
                        area: null,
                        areas: undefined,
                        period: null,
                        periods: undefined,
                        subGroup: null,
                        subGroups: undefined,
                    });

                    const response = await indicatorUseCases.getTopicInfo(
                        topicId,
                        selectedIndicator
                    );
                    const formattedData =
                        indicatorUseCases.formatData(response);
                    
                    setIndicatorInfoExtra(formattedData);
                    setIndicatorInfo(response);
                    setLoadingInfo(false);
                } catch (e) {
                    setLoadingInfo(false);
                    throw e;
                }
            })();
        }
    }

    function getMapShapeFilesUseEffect() {
        (async function () {
            try {
                setLoadingMapInfo(true);

                const response: Awaited<MapShapeFileResponse> =
                    await indicatorUseCases.getMapShapeFiles({
                        geoService,
                        getMapParams: choroplethMapTraits.getMapParams,
                    });
                setMapShapeFiles(response);

                setLoadingMapInfo(false);
            } catch (error) {
                setLoadingMapInfo(false);
                throw error;
            }
        })();
    }
};

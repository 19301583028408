import { AppShell, useMantineTheme } from "@mantine/core";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { FooterCmp } from "~/components/footer/footer-cmp";
import { HeaderCmp } from "~/components/header/header-cmp";

export const RootPage: React.FC = () => {
    const theme = useMantineTheme();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <AppShell
            styles={{
                main: {
                    backgroundColor: '#fff',
                    width: "100%",
                    maxWidth: 2000,
                    marginInline: "auto",
                    paddingTop: 40,
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing.lg,
                    backgroundColor: '#fff',
                },
            }}
            header={<HeaderCmp />}
            footer={<FooterCmp />}
        >
            <Outlet />
        </AppShell>
    );
};

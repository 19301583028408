import {areaZoneColorMapV2, areaZonesTv2} from "~/domains/visualization/utils/areaZonesColors.ts";

export function barSeriesGenerator(
  data: { name: string; value: number | null, zone: string }[],
  _genderKey: string,
  hasGenderSegregation: boolean,
  formatGraphLegendKey: (key: string) => string,
): { visible?: boolean; data: number[] | { y: number; color: string; name: string }[]; name: string }[] {
  const keysList = ['total'] as const;
  if (!hasGenderSegregation) {
    return keysList.map(key => {
      return ({
        name: `${formatGraphLegendKey(key)}`,
        data: data.map((dt) => ({
          y: dt.value || null,
          name: dt.zone,
          color: areaZoneColorMapV2.get(dt.zone as areaZonesTv2) ?? 'yellow',
          zone: dt.zone,
          region: dt.name,
        })),
        visible: true,
      })
    })
  }
}
import { notifications } from "@mantine/notifications";
import * as XLSX from "xlsx";

export function downloadTopicData(props: {
    columnHeaders1: string[];
    columnHeaders2: string[];
    data: (string | number | null)[][];
    name: string;
    extension: "xlsx" | "csv";
}) {
    try {
        const titleArray: string[] = props.columnHeaders1;
        const timePeriodsArray: string[] = props.columnHeaders2;

        const valuesData = props.data.map((data) => data.map((num) => (num ? num.toString() : null)));

        const csvData = [titleArray, timePeriodsArray, ...valuesData];

        const worksheet = XLSX.utils.json_to_sheet(csvData);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

        /* fix row A1 */
        XLSX.utils.sheet_add_aoa(worksheet, [titleArray.map((_, __) => "")], { origin: "A1" });

        /* calculate column width */
        let min_width = 20;
        let max_width = min_width;
        csvData.forEach((csvEntry) => {
            let _max_width = (csvEntry as string[]).reduce((w, r) => Math.max(w, r?.length ?? 10), min_width);
            max_width = Math.max(_max_width, max_width);
        });

        const fileExtension = `.${props.extension}`;

        worksheet["!cols"] = titleArray.map(() => ({ wch: max_width }));

        const fileName = `${props.name}${fileExtension}`;

        XLSX.writeFile(workbook, fileName);
    } catch (error) {
        notifications.show({ message: `Error downloading ${props.extension} file`, color: "red" });
        throw error;
    }
}

import { TopicEntity } from "./topic-entity";
import { topicRepository } from "./topic-repository";
import { TopicRepositoryContract } from "./topic-repository-contract";

export class TopicService implements TopicRepositoryContract {
    constructor(private repository: TopicRepositoryContract) {}

    getAll(): Promise<TopicEntity[]> {
        return this.repository.getAll();
    }
}

export const topicService = new TopicService(topicRepository);

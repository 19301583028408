import { useEffect, useState } from "react";
import { topicUseCases } from "../../topic-usecases";
import { useTopicsStore } from "../state";
import { TopicEntity } from "../../topic-entity";
import { notifications } from "@mantine/notifications";

export const useGetTopics = function () {
    const [loading, setLoading] = useState(false);

    const [topics, setTopics] = useState<TopicEntity[]>([]);
    const [allTopics, setAllTopics] = useState<TopicEntity[]>([]);

    const { updateTopics, topics: _topics_from_store } = useTopicsStore();

    useEffect(effectHandler, [_topics_from_store]);

    return { topics, loading, _topics_from_store, allTopics };

    function effectHandler() {
        if (!_topics_from_store) {
            (async function () {
                try {
                    setLoading(true);

                    const _topics = await topicUseCases.getAllTopics();
                    const allSubSectors = new Set<number>();
                    _topics.allTopics.forEach((topic) => {
                        allSubSectors.add(topic.subsectorkey);
                    });

                    setTopics(
                        [...allSubSectors].map(
                            (subSectorKey) => _topics.allTopics.find((topic) => topic.subsectorkey === subSectorKey)!
                        )
                    );
                    setAllTopics(_topics.allTopics);

                    updateTopics(_topics.allTopics);

                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    notifications.show({
                        title: "Error",
                        message: "Something went wrong",
                        color: "red",
                    });
                    throw error;
                }
            })();
        } else {
            try {
                setLoading(true);

                const allSubSectors = new Set<number>();
                _topics_from_store.forEach((topic) => {
                    allSubSectors.add(topic.subsectorkey);
                });

                setTopics(
                    [...allSubSectors].map(
                        (subSectorKey) => _topics_from_store.find((topic) => topic.subsectorkey === subSectorKey)!
                    )
                );

                setAllTopics(_topics_from_store);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                notifications.show({
                    title: "Error",
                    message: "Something went wrong",
                    color: "red",
                });
                throw error;
            }
        }
    }
};

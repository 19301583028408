import LeafLet from "leaflet";

export const choroplethMapTraits = Object.freeze({
    getMapParams: function (location: string) {
        return LeafLet.Util.extend({
            service: "WFS",
            request: "GetFeature",
            typeName: "cite:" + location,
            outputFormat: "application/json",
        });
    },

    // filter according to location shapefile
    getMapFilterParams: function (location: string, cql_filter: any) {
        return LeafLet.Util.extend({
            service: "WFS",
            request: "GetFeature",
            typeName: "cite:" + location,
            cql_filter: cql_filter,
            outputFormat: "application/json",
        });
    },
});

import { notifications } from "@mantine/notifications";
import { IndicatorEntity } from "~/domains/indicator/indicator-entity";

export function getTableData(props: {
    subgroups: {
        _subgroupkey: number;
        _subgroup_name: string;
        _subgroup_desc: string;
    }[];
    timePeriods: string[];
    indicatorInfo: IndicatorEntity[];
}): {
    columns: { column1: string[]; column2: string[] };
    data: string[][];
    source: {
        time_value: string, source_name: string
    }[];
} {
    try {
        const columnHeaders: string[] = [""];
        const columnHeaders2: string[] = ["Area name"];
        props.subgroups.forEach((sg) => {
            let dt = [sg._subgroup_desc, ...Array.from({ length: props.timePeriods.length - 1 }).map(() => "")];
            columnHeaders.push(...dt);
            columnHeaders2.push(...props.timePeriods);
        });

        const allData: string[][] = [];
        const allAreas = new Set<string>(props.indicatorInfo.map((info) => info.area_name));
        allAreas.forEach((area) => {
            let obj: string[] = [area];
            let info = props.indicatorInfo.filter((info) => info.area_name === area)!;

            props.subgroups.forEach((subgroup) => {
                props.timePeriods.forEach((period) => {
                    let value: string =
                        info
                            .find((dt) => dt.subgroupkey === subgroup._subgroupkey && dt.time_value === period)
                            ?.data_value.toString() ?? "";
                    obj.push(value);
                });
            });
            allData.push(obj);
        });

        const topLevelAreas = ["Tanzania", "Mainland", "Zanzibar"];
        const tanzania_mainland_zanzibar_values: string[][] = allData.filter((dt) => topLevelAreas.includes(dt[0]));
        const tanzania_mainland_zanzibar_values_arrangement: string[][] = [];

        topLevelAreas.forEach((area) => {
            let targetData = tanzania_mainland_zanzibar_values.find((dt) => dt[0] === area);
            if (targetData) tanzania_mainland_zanzibar_values_arrangement.push(targetData);
        });

        allData.sort((a, b) => (a[0] > b[0] ? 1 : -1));
        const other_values: string[][] = allData.filter((dt) => !topLevelAreas.includes(dt[0]));

        const uniqueSourceMap = Array.from(
            new Set(props.indicatorInfo.map(item => JSON.stringify({ time_value: item.time_value, source_name: item.source_name })))
        ).map(item => JSON.parse(item));
        

        return {
            columns: { column1: columnHeaders, column2: columnHeaders2 },
            data: [...tanzania_mainland_zanzibar_values_arrangement, ...other_values],
            source: uniqueSourceMap
        };
    } catch (error) {
        notifications.show({ message: "Something went wrong" });
        throw error;
    }
}

import { LanguageTypesI } from "./language-types";

const en: LanguageTypesI = {
    headerTitle: "Tanzania Demographic Health Survey Dashboard",
    english: "English",
    kiswahili: "Swahili",
    poweredBy: "Powered by ",
    navbar_home: "Home",
    navbar_publications: "Publications",
    navbar_topics: "Topics",
    navbar_visualizations: "Visualizations",
    home: "Home",
    home_search: "Search by Data Topic",
    search: "Search",
};

export default en;

import { LanguageTypesI } from "./language-types";

const kis: LanguageTypesI = {
    headerTitle: "Tanzania Demographic Health Survey Dashboard",
    english: "Kiingereza",
    kiswahili: "Kiswahili",
    poweredBy: "Imetengenezwa na ",
    navbar_home: "Nyumbani",
    navbar_publications: "Nakala",
    navbar_topics: "Mada",
    navbar_visualizations: "Taswira",
    home: "Nyumbani",
    home_search: "Tafuta kwa Mada",
    search: "Tafuta",
};

export default kis;

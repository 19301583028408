import { ActionIcon, Group, Text, UnstyledButton, rem } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export const BackButton: React.FC = function () {
    const navigate = useNavigate();
    return (
        <UnstyledButton w={"fit-content"} onClick={() => navigate(-1)}>
            <Group align="center" spacing={"xs"}>
                <ActionIcon variant="outline" size={"lg"}>
                    <IconArrowLeft size={rem(15)} />
                </ActionIcon>
                <Text color="dimmed" size={"sm"}>
                    Back
                </Text>
            </Group>
        </UnstyledButton>
    );
};

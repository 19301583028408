import { Breadcrumbs, Text, createStyles } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import React from "react";
import { NavLink } from "react-router-dom";

const style = createStyles((theme) => ({
    fontsize: {
        textDecoration: "none",
        fontSize: theme.fontSizes.sm,
        color: theme.colors.blue[4],
    },
}));

export const BreadcrumbCreator: React.FC<{ data: { title: string; path?: string }[] }> = function ({ data }) {
    const { classes } = style();

    const breadcrumbsItems = data.map((item, index) => {
        if (!item.path) {
            return (
                <Text key={index} size={"sm"}>
                    {item.title}
                </Text>
            );
        }
        return (
            <NavLink to={item.path} key={index} className={classes.fontsize}>
                {item.title}
            </NavLink>
        );
    });

    return (
        <Breadcrumbs data-testid="breadcrumbs" separator={<IconChevronRight size={18} />}>
            {breadcrumbsItems}
        </Breadcrumbs>
    );
};

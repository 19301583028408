import { IndicatorEntity } from "./indicator-entity";
import { indicatorRepository } from "./indicator-repository";
import {
    GetMapShapeFilesPropsT,
    IndicatorRepositoryContract,
    MapShapeFileResponse,
} from "./indicator-repository-contract";

export class IndicatorService implements IndicatorRepositoryContract {
    constructor(private repository: IndicatorRepositoryContract) {}

    getMapShapeFiles(props: GetMapShapeFilesPropsT): MapShapeFileResponse {
        return this.repository.getMapShapeFiles(props);
    }

    getIndicatorInfo(sectorKey: string, indicatorKey: string): Promise<IndicatorEntity[]> {
        return this.repository.getIndicatorInfo(sectorKey, indicatorKey);
    }
}

export const indicatorService = new IndicatorService(indicatorRepository);

import React, {useEffect} from 'react';
import {SegmentedControl} from '@mantine/core';
import {viewOptionT} from '../hooks/use-topic-page';

export const TopicIndicatorInfoViewBy: React.FC<{
	selectedView: viewOptionT;
	onChangeSelectedView: (type: viewOptionT) => void;
	viewOptions: {
		label: string;
		value: viewOptionT;
	}[];
	selectedTimePeriods: string[];
	selectedTimeSubGroups: string[];
}> = function ({onChangeSelectedView, selectedView, selectedTimePeriods, selectedTimeSubGroups}) {
	const shouldDisableMap = selectedTimePeriods.length !== 1 || selectedTimeSubGroups.length !== 1;
	const shouldDisableGraph = selectedTimePeriods.length !== 1;

	useEffect (() => {
		if (shouldDisableMap || shouldDisableGraph) {
			onChangeSelectedView ('table');
		}
	}, [shouldDisableMap, shouldDisableGraph]);

	return (
		<SegmentedControl
			w={'fit-content'}
			color="blue"
			radius="md"
			value={selectedView}
			onChange={(value) => onChangeSelectedView (value as viewOptionT)}
			data={[
				{label: 'Table', value: 'table', disabled: false},
				{label: 'Graph', value: 'graph', disabled: shouldDisableGraph},
				{label: 'Map', value: 'map', disabled: shouldDisableMap},
			]}
		/>
	);
};

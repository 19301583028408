import { useCallback, useEffect, useState } from "react";
import { IndicatorEntity } from "~/domains/indicator/indicator-entity";
import { MapShapeFileResponse } from "~/domains/indicator/indicator-repository-contract.ts";
import {
    IndicatorInfoExtraI,
    indicatorUseCases,
} from "~/domains/indicator/indicator-usecases.ts";
import { choroplethMapTraits } from "~/domains/indicator/ui/utils/choropleth-map-trait.ts";
import geoService from "~/domains/indicator/ui/utils/geo-server";
import { TopicEntity } from "~/domains/topic/topic-entity";
import { topicUseCases } from "~/domains/topic/topic-usecases";
import {
    areaZoneColorMapV2,
    areaZonesTv2,
} from "~/domains/visualization/utils/areaZonesColors.ts";
import { barSeriesGenerator } from "~/domains/visualization/utils/barSeriesGenerator.ts";
import { TEXTS } from "~/utils/texts";
import { ChartOptionsBuilder } from "../../utils/ChartOptionsBuilder";
import {
    gendersT,
    regionProfileT,
    tanzaniaStatesT,
    topicVisualizationDataTv2,
    trendDataT,
    zoneDistributionT,
    LineChartOptionsT
} from "../../utils/topicVisualizationDataType";
import { sampleZoneInfo } from "~/domains/indicator/ui/hooks/use-topic-page";

function formatGraphLegendKey(key: string): string {
    return `${key.charAt(0).toUpperCase()}${key.slice(1)}`;
}

function bubbleChartSeriesGenerator(
    data: zoneDistributionT[],
    _genderKey: string,
    hasGenderSegregation: boolean,
    _formatGraphLegendKey: (key: string) => string,
    yearSelected: number | string,
    stateSelection: tanzaniaStatesT
): {
    name: string;
    data: { name: string; value: number }[];
    color?: string;
    marker?: {
        fillColor?: string;
        lineWidth?: number;
        lineColor?: string;
    };
}[] {
    if (!hasGenderSegregation) {
        if (stateSelection === "zanzibar") {
            return data
                .filter((dt) => dt.zoneName === "Zanzibar Zone")
                .map((zoneInfo) => {
                    const color = areaZoneColorMapV2.get(
                        zoneInfo.zoneName.trim() as areaZonesTv2
                    );
                    return {
                        name: zoneInfo.zoneName.trim(),
                        data: [
                            {
                                name: `${zoneInfo.zoneName}`.trim(),
                                value:
                                    zoneInfo.zoneData.find(
                                        (dt) => dt.year === yearSelected
                                    )?.value ?? null,
                            },
                        ],
                        color: color,
                        marker: {
                            fillColor: color ?? "yellow",
                            lineWidth: 1,
                            lineColor: color, // inherit from series
                        },
                        // data: zoneInfo.regionProfiles.map(profile => ({name: profile.name, value: profile.total}))
                    };
                });
        }

        if (stateSelection === "mainland") {
            return data
                .filter((dt) => dt.zoneName !== "Zanzibar Zone")
                .map((zoneInfo) => {
                    const color = areaZoneColorMapV2.get(
                        zoneInfo.zoneName.trim() as areaZonesTv2
                    );
                    return {
                        name: zoneInfo.zoneName.trim(),
                        data: [
                            {
                                name: `${zoneInfo.zoneName}`.trim(),
                                value:
                                    zoneInfo.zoneData.find(
                                        (dt) => dt.year === yearSelected
                                    )?.value ?? null,
                            },
                        ],
                        color: color,
                        marker: {
                            fillColor: color ?? "yellow",
                            lineWidth: 1,
                            lineColor: color, // inherit from series
                        },
                        // data: zoneInfo.regionProfiles.map(profile => ({name: profile.name, value: profile.total}))
                    };
                });
        }

        return data.map((zoneInfo) => {
            const color = areaZoneColorMapV2.get(
                zoneInfo.zoneName.trim() as areaZonesTv2
            );
            return {
                name: zoneInfo.zoneName.trim(),
                data: [
                    {
                        name: `${zoneInfo.zoneName}`.trim(),
                        value:
                            zoneInfo.zoneData.find(
                                (dt) => dt.year === yearSelected
                            )?.value ?? null,
                    },
                ],
                color: color,
                marker: {
                    fillColor: color ?? "yellow",
                    lineWidth: 1,
                    lineColor: color, // inherit from series
                },
                // data: zoneInfo.regionProfiles.map(profile => ({name: profile.name, value: profile.total}))
            };
        });
    }

    return [];
}

export function useGetVisualizationsV2({
    topicKey,
    topics,
}: {
    topicKey: string;
    topics: TopicEntity[];
}) {
    const [dataSource, setDataSource] = useState([]);
    const [selectedIndicator, setSelectedIndicator] = useState("");
    const [selectedIndicatorData, setSelectedIndicatorData] = useState<
        | {
              indicatorOptions: {
                  regionProfileChartOptions: object;
                  bubbleChartOptions: object;
                  lineChartOptions: LineChartOptionsT;
                  mapOptions: object;
                  tableOptions: {
                      region: string;
                      zone: string;
                      value: number;
                  }[];
                  zoneOptions: {
                      region: string;
                      zone: string;
                      value: number;
                  }[];
              };
              key: string;
              title: { text: string };
              total: number;
              rural: number;
              urban: number;
              allAvailableYears: number[];
          }
        | undefined
    >(undefined);

    const [loadingIndicators, setLoadingIndicators] = useState(false);
    const [indicators, setIndicators] = useState<
        {
            indicator_name: string;
            indicatorkey: string;
        }[]
    >([]);

    const [availableYears, setAvailableYears] = useState<string[]>([]);

    const [_, setLoadingIndicatorInfo] = useState(false);
    const [__, setIndicatorInfo] = useState<IndicatorEntity[]>([]);
    const [___, setIndicatorInfoExtra] = useState<
        IndicatorInfoExtraI | undefined
    >(undefined);

    const [optionsList] = useState<
        {
            indicatorOptions: {
                regionProfileChartOptions: object;
                bubbleChartOptions: object;
                lineChartOptions: object;
                mapOptions: object;
                tableOptions: { region: string; zone: string; value: number }[];
                zoneOptions: { region: string; zone: string; value: number }[];
            };
            key: string;
            title: { text: string };
            total: number;
            rural: number;
            urban: number;
            allAvailableYears: number[];
        }[]
    >([]);
    const [selectedCategories, setSelectedCategories] = useState<
        {
            key: string;
            stateSelection: tanzaniaStatesT;
            genderSelection: gendersT;
            year: string;
            chartType: "bar" | "map" | "bubble" | "line";
        }[]
    >(
        dataSource?.map((dt) => ({
            key: dt.key,
            stateSelection: "all",
            genderSelection: "all",
            year: "",
            chartType: "bar",
        }))
    );

    useEffect(() => {
        if (topicKey && topics) {
            (async function () {
                try {
                    setLoadingIndicators(true);

                    const _topics = await topicUseCases.getAllTopics();

                    const topicInfo = await topicUseCases.getAllTopicIndicators(
                        Number(topicKey!),
                        _topics.allTopics
                    );

                    if (topicInfo === false) {
                    } else {
                        setIndicators(topicInfo.indicators);

                        const targetIndicator =
                            topicInfo.indicators[0]?.indicatorkey ?? "";
                        setSelectedIndicator(targetIndicator);
                    }
                } catch (error) {
                    throw error;
                } finally {
                    setLoadingIndicators(false);
                }
            })();
        }
    }, [topicKey, topics]);

    useEffect(() => {
        if (topicKey && selectedIndicator && indicators) {
            (async function () {
                try {
                    setLoadingIndicatorInfo(true);

                    const response = await indicatorUseCases.getTopicInfo(
                        topicKey,
                        selectedIndicator
                    );
                    const formattedData =
                        indicatorUseCases.formatData(response);
                    setIndicatorInfoExtra(formattedData);
                    setIndicatorInfo(response);

                    const availableZonesSet = new Set<areaZonesTv2>();

                    const zoneData = response
                        ?.filter((res) => res.area_level === "ZONE")
                        .filter((res) => res.subgroup_name === "Total");

                    zoneData?.forEach((dt) => {
                        availableZonesSet.add(dt.area_name as areaZonesTv2);
                    });

                    const availableZones: areaZonesTv2[] = [
                        ...availableZonesSet,
                    ];

                    const filteredResponse = response
                        // ?.filter((res) => res.area_name !== "Tanzania")
                        // .filter((res) => res.area_name !== "TZMAIN")
                        // .filter((res) => res.area_name !== "TZ002")
                        .filter((res) => res.subgroup_name === "Total");

                    const availableYearsSet = new Set<string>();
                    filteredResponse?.forEach((res) => {
                        availableYearsSet.add(res.time_value);
                    });

                    const _availableYears = [...availableYearsSet].sort(
                        (a, b) => (+a.split("-")[0] > +b.split("-")[0] ? 1 : -1)
                    );

                    setAvailableYears(_availableYears);

                    const _trendData: trendDataT[] = [];
                    _availableYears?.forEach((year) => {
                        const tzData = extractYearData({
                            area: "Tanzania",
                            year,
                            data: response,
                            selectedIndicator,
                        });
                        const tzMainlandData = extractYearData({
                            area: "Mainland",
                            year,
                            data: response,
                            selectedIndicator,
                        });
                        const tzZanzibarData = extractYearData({
                            area: "Zanzibar",
                            year,
                            data: response,
                            selectedIndicator,
                        });

                        _trendData.push({
                            year,
                            info: { tzData, tzMainlandData, tzZanzibarData },
                        });
                    });

                    const source =
                        indicators?.map((dt) => ({
                            key: dt.indicatorkey,
                            chartOptions: {
                                chartType: "bar",
                                description: "",
                                yAxisLabel: "",
                                tooltipSuffix: "",
                            },
                            national: {
                                totalRural: null,
                                totalUrban: null,
                            },
                            trendData: _trendData,
                            hasGenderSegregation: false,
                            indicatorName: dt.indicator_name,
                            description: TEXTS.welcome,
                            image: "",
                            distribution: availableZones.map((zone) => {
                                const ZONE_DATA =
                                    _availableYears?.map((year) => {
                                        return {
                                            year,
                                            value:
                                                zoneData?.find(
                                                    (zData) =>
                                                        zData.time_value ===
                                                            year &&
                                                        zData.area_name === zone
                                                )?.data_value ?? null,
                                        };
                                    }) ?? [];

                                return {
                                    zoneId: zone,
                                    zoneName: zone,
                                    zoneData: ZONE_DATA,
                                    totalUrban: null,
                                    totalRural: null,
                                    regionProfiles:
                                        filteredResponse
                                            .filter(
                                                (__dt) =>
                                                    ![
                                                        "ZONE",
                                                        "LVL2",
                                                        "LVL1",
                                                    ].includes(__dt.area_level)
                                            )
                                            .map((res) => {
                                                return {
                                                    name: res.area_name,
                                                    total: +res.data_value?.toFixed(
                                                        2
                                                    ),
                                                    state:
                                                        res.parent_code ===
                                                        "TZMAIN"
                                                            ? ("mainland" as tanzaniaStatesT)
                                                            : ("zanzibar" as tanzaniaStatesT),
                                                    year: res.time_value,
                                                    male: null,
                                                    female: null,
                                                };
                                            }) ?? [],
                                };
                            }),
                        })) ?? [];
                    setDataSource(source);
                } catch (e) {
                    throw e;
                } finally {
                    setLoadingIndicatorInfo(false);
                }
            })();
        }
    }, [topicKey, selectedIndicator, indicators]);

    const [loadingMapInfo, setLoadingMapInfo] = useState(false);
    const [mapShapeFiles, setMapShapeFiles] = useState<
        Awaited<MapShapeFileResponse> | undefined
    >(undefined);

    useEffect(getMapShapeFilesUseEffect, []);

    const [hoveredZone, setHoveredZone] = useState<string | undefined>(
        undefined
    );
    const [regionZoneMap, setRegionZoneMap] = useState<
        Map<areaZonesTv2, string[]> | undefined
    >(undefined);

    const onUpdateData = useCallback(
        (data: topicVisualizationDataTv2) => {
            const targetedData = data?.find((dt) => {
                return dt?.key === selectedIndicator;
            });
            if (targetedData) {
                let option = targetedData;

                const {
                    genderSelection: genderSelected,
                    year: yearSelected,
                    stateSelection,
                } = selectedCategories?.find(
                    (category) => category.key === option.key
                ) ?? {
                    genderSelection: "all",
                    year: null,
                    stateSelection: "mainland",
                };

                const genderValue =
                    genderSelected === "all" ? "total" : genderSelected;

                const allRegionProfiles: (regionProfileT & { zone: string })[] =
                    [];
                const allZoneDistributions: zoneDistributionT[] = [];

                const _regionZoneMap: Map<areaZonesTv2, string[]> = new Map();

                const _zonesSet = new Set<string>();

                option.distribution.forEach((distribution) => {
                    _zonesSet.add(distribution.zoneName);
                });

                // const _zoneArray = [..._zonesSet];

                const zoneToRegionMap: Map<areaZonesTv2, string[]> = new Map([
                    [
                        "Northern Zone",
                        ["Kilimanjaro", "Arusha", "Manyara", "Tanga"],
                    ],
                ]);

                option.distribution.forEach((distribution) => {
                    allZoneDistributions.push(distribution);
                    const zoneRegionsList = new Set<string>();
                    distribution.regionProfiles.forEach((profile) => {
                        allRegionProfiles.push({
                            ...profile,
                            zone: distribution.zoneName,
                        });
                        zoneRegionsList.add(profile.name);
                    });
                    _regionZoneMap.set(
                        distribution.zoneName,
                        sampleZoneInfo.find(
                            (dt) => dt.name === distribution.zoneName
                        )?.regions ?? []
                    );
                });

                setRegionZoneMap(zoneToRegionMap);

                const filteredRegionProfiles: (regionProfileT & {
                    zone: string;
                })[] =
                    allRegionProfiles.filter(
                        (profile) => profile.year.toString() === yearSelected
                    ) ?? [];

                const regionProfileChartOptions = (function () {
                    const sortedRegionProfiles = filteredRegionProfiles
                        .map((dt) => ({
                            name: dt.name,
                            value: dt.total,
                            zone: dt.zone,
                        }))
                        .sort((a, b) => (a.value > b.value ? 1 : -1));
                    const optionSeries = barSeriesGenerator(
                        sortedRegionProfiles,
                        genderValue,
                        option.hasGenderSegregation,
                        formatGraphLegendKey
                    );

                    const categories = sortedRegionProfiles.map(
                        (dt) => `${dt.name}`
                    );
                    const indicatorName = option.indicatorName;
                    const description = option.description;
                    const yAxisLabel = "";
                    const toolTipSuffix =
                        genderValue === "total" ? "" : genderValue;

                    return new ChartOptionsBuilder()
                        .setChartType("bar")
                        .setTitle(indicatorName)
                        .setSubtitle(description)
                        .setCategories(categories)
                        .setAccessibilityDescription("Regions")
                        .setMinimumYAxisValue(0)
                        .setYAxisLabel(yAxisLabel)
                        .setTooltipSuffix(toolTipSuffix)
                        .setSeries(optionSeries)
                        .build();
                })();

                let _allZoneDistributions: zoneDistributionT[] = [];

                const bubbleChartOptions = (function () {
                    _allZoneDistributions = (function () {
                        let _dist = [];
                        allZoneDistributions.forEach((dist) => {
                            let zone: zoneDistributionT = { ...dist };
                            zone.regionProfiles = zone.regionProfiles.filter(
                                (profile) =>
                                    profile.year?.toString() === yearSelected
                            );
                            _dist.push(zone);
                        });
                        return _dist;
                    })();

                    const optionSeries = bubbleChartSeriesGenerator(
                        _allZoneDistributions,
                        genderValue,
                        option.hasGenderSegregation,
                        formatGraphLegendKey,
                        yearSelected,
                        stateSelection
                    );

                    const categories = filteredRegionProfiles.map(
                        (profile) => profile.name
                    );
                    const indicatorName = option.indicatorName;
                    const description = option.description;
                    const yAxisLabel = "";
                    const toolTipSuffix =
                        genderValue === "total" ? "" : genderValue;

                    return new ChartOptionsBuilder()
                        .setChartType("bubble")
                        .setTitle(indicatorName)
                        .setSubtitle(description)
                        .setCategories(categories)
                        .setAccessibilityDescription("Regions")
                        .setMinimumYAxisValue(0)
                        .setYAxisLabel(yAxisLabel)
                        .setTooltipSuffix(toolTipSuffix)
                        .setSeries(optionSeries)
                        .build(
                            undefined,
                            (options) => {
                                setHoveredZone(options?.name ?? undefined);
                            },
                            () => {
                                setHoveredZone(undefined);
                            }
                        );
                })();

                const allAvailableYearsSet = new Set<number>(
                    allRegionProfiles.map((profile) => profile.year)
                );
                const allAvailableYears: number[] = [
                    ...allAvailableYearsSet,
                ].sort();

                const lineChartOptions = (function () {
                    const categories = option?.trendData.map(
                        ({ year }) => year
                    );

                    const optionSeries2 = option?.trendData.map(
                        ({
                            info: { tzData, tzMainlandData, tzZanzibarData },
                        }) => {
                            if (stateSelection === "all") return tzData.value;
                            if (stateSelection === "mainland")
                                return tzMainlandData.value;
                            if (stateSelection === "zanzibar")
                                return tzZanzibarData.value;
                            return null;
                        }
                    );

                    const indicatorName = option.indicatorName;
                    const description = option.description;
                    const yAxisLabel = "";
                    const toolTipSuffix =
                        genderValue === "total" ? "" : genderValue;

                    return new ChartOptionsBuilder()
                        .setChartType("line")
                        .setTitle(indicatorName)
                        .setSubtitle(description)
                        .setCategories(categories)
                        .setAccessibilityDescription("Regions")
                        .setMinimumYAxisValue(0)
                        .setYAxisLabel(yAxisLabel)
                        .setTooltipSuffix(toolTipSuffix)
                        .setSeries([
                            {
                                data: optionSeries2,
                                visible: true,
                                name: "Total",
                            },
                        ] as any)
                        .build();
                })();

                const shapeFileData = mapShapeFiles?.shapeFileData;
                // @ts-ignore
                let mapFeatures = shapeFileData?.features;
                let arr: {
                    Region_Nam?: string;
                    LAKES?: string;
                    value?: number | null;
                }[] = [];
                let dataArray = filteredRegionProfiles.map((dt) => ({
                    location: dt.name,
                    value: dt.total,
                }));

                const targetAreas = [
                    ...filteredRegionProfiles.map((dt) => `${dt.name}`),
                    ...(stateSelection === "all" ||
                    stateSelection === "mainland"
                        ? shapeFileData?.["features"]
                              ?.filter((dt) => dt?.properties?.LAKES)
                              .map((dt) => dt?.properties?.LAKES) ?? []
                        : []),
                ];
                const features = shapeFileData?.["features"]?.filter(
                    (dt) =>
                        targetAreas.includes(dt?.properties?.Region_Nam) ||
                        targetAreas.includes(dt?.properties?.LAKES)
                );

                let finalData: any = [];

                if (mapFeatures) {
                    features?.forEach((el: any) => {
                        el.drilldown = el.properties?.Region_Cod;
                        el.region_nam =
                            el.properties?.Region_Nam ??
                            el.properties?.LAKES ??
                            "";
                        const targetData = dataArray
                            .map((dt) =>
                                dt.value === 0
                                    ? { ...dt, value: undefined }
                                    : { ...dt, value: dt.value }
                            )
                            .find(({ location }) => location === el.region_nam);

                        if (!el.properties?.Region_Nam) {
                            el["color"] = "#87cefa";
                        } else {
                            if (!targetData?.location) {
                                let obj = { LAKES: el.properties?.LAKES ?? "" };
                                arr.push(obj);
                                el.value = undefined;
                            } else {
                                let obj = {
                                    Region_Nam: targetData.location,
                                    value: targetData?.value
                                        ? Number(targetData?.value)
                                        : null,
                                };
                                arr.push(obj);
                                el.value = targetData?.value
                                    ? Number(targetData?.value)
                                    : null;
                            }
                        }

                        finalData.push({
                            ...el,
                            drilldown: el.properties?.Region_Cod,
                            region_nam: el.properties?.Region_Nam,
                            value: el.value,
                            color: el["color"],
                        });

                        el["color"] = undefined;
                    });
                }

                const mapOptions = new ChartOptionsBuilder()
                    .setChartType("map")
                    .setTitle(option.indicatorName)
                    .setSubtitle(option.description)
                    .setAccessibilityDescription("Regions")
                    .setMinimumYAxisValue(0)
                    .setYAxisLabel("")
                    .setTooltipSuffix("")
                    .setSeries(finalData)
                    .build(shapeFileData);

                const tableRegionsDataSet = new Set<string>();
                filteredRegionProfiles.forEach((dt) => {
                    tableRegionsDataSet.add(dt.name);
                });

                const tableOptions = [...tableRegionsDataSet].map((region) => {
                    const dt = filteredRegionProfiles.find(
                        (_dt) => _dt.name === region
                    )!;
                    return {
                        region: dt.name,
                        zone: dt.zone,
                        value: dt.total,
                    };
                });

                const targetOptions = {
                    indicatorOptions: {
                        regionProfileChartOptions: {
                            ...regionProfileChartOptions,
                            title: { text: "" },
                        },
                        bubbleChartOptions: {
                            ...bubbleChartOptions,
                            title: { text: "" },
                        },
                        lineChartOptions: {
                            ...lineChartOptions,
                            title: { text: "" },
                        },
                        mapOptions: { ...mapOptions, title: { text: "" } },
                        tableOptions,
                        zoneOptions: _allZoneDistributions.map((dt) => ({
                            zone: dt.zoneName,
                            value:
                                dt.zoneData.find(
                                    (dt2) =>
                                        dt2.year?.toString() === yearSelected
                                )?.value ?? "",
                        })),
                    },
                    key: option.key,
                    title: { text: option.indicatorName },
                    total:
                        option.national.totalRural + option.national.totalUrban,
                    rural: option.national.totalRural,
                    urban: option.national.totalUrban,
                    allAvailableYears,
                };

                setSelectedIndicatorData(targetOptions as any);
            }
        },
        [selectedCategories, mapShapeFiles, selectedIndicator]
    );

    useEffect(() => {
        if (availableYears && dataSource) {
            setSelectedCategories((prev) => {
                prev = dataSource?.map((dt) => ({
                    key: dt.key,
                    stateSelection: "all",
                    genderSelection: "all",
                    year: availableYears[availableYears.length - 1] ?? "",
                    chartType: "bar",
                }));

                return prev;
            });
        }
    }, [availableYears, dataSource]);

    useEffect(() => {
        onUpdateData(dataSource);
        setSelectedIndicator(selectedIndicator);
    }, [dataSource, selectedIndicator]);

    /**
     * handles changes when Tanzania state value changes
     */
    useEffect(() => {
        const dt = [];
        dataSource?.forEach((_dt) => {
            if (_dt) {
                const targetSelection = selectedCategories.find(
                    (category) => category.key === _dt.key
                )?.stateSelection;

                const filteredData: typeof _dt = {
                    ..._dt,
                    distribution: (function () {
                        const _newDistribution: zoneDistributionT[] = [];
                        _dt.distribution.forEach((profile) => {
                            const profiles = profile.regionProfiles.filter(
                                (regProfile) =>
                                    targetSelection?.toString() === "all"
                                        ? true
                                        : regProfile.state ===
                                          targetSelection?.toString()
                            );

                            if (!!profiles.length)
                                _newDistribution.push({
                                    ...profile,
                                    regionProfiles: profiles,
                                });
                        });
                        return _newDistribution;
                    })(),
                };

                dt.push(filteredData);
            }
        });

        onUpdateData(dt);
    }, [selectedCategories, onUpdateData, dataSource]);

    const onUpdateState = useCallback(
        (key: string, selection: tanzaniaStatesT | undefined) => {
            setSelectedCategories((prev) => {
                prev = [...prev].map((dt) =>
                    dt.key === key ? { ...dt, stateSelection: selection } : dt
                );
                return prev;
            });
        },
        []
    );

    const onUpdateGender = useCallback(
        (key: string, selection: gendersT | undefined) => {
            setSelectedCategories((prev) => {
                prev = [...prev].map((dt) =>
                    dt.key === key ? { ...dt, genderSelection: selection } : dt
                );
                return prev;
            });
        },
        []
    );

    const onUpdateYear = useCallback(
        (key: string, year: string | undefined) => {
            setSelectedCategories((prev) => {
                prev = [...prev].map((dt) =>
                    dt.key === key ? { ...dt, year: year } : dt
                );
                return prev;
            });
        },
        []
    );

    const onUpdateChartType = useCallback(
        (key: string, chartType: "bar" | "map" | "bubble") => {
            setSelectedCategories((prev) => {
                prev = [...prev].map((dt) =>
                    dt.key === key ? { ...dt, chartType } : dt
                );
                return prev;
            });
        },
        []
    );

    const [tableSearchText, setTableSearchText] = useState("");

    return {
        indicatorsList: dataSource?.map((dt) => dt.indicatorName) ?? [],
        selectedIndicatorData,
        tableSearchText,
        setTableSearchText,
        selectedIndicator,
        onChangeSelectedIndicator: (indicator: string | null) =>
            setSelectedIndicator(indicator),
        optionsList,
        onUpdateCategory: onUpdateState,
        selectedCategories,
        onUpdateGender,
        onUpdateYear,
        onUpdateChartType,
        loadingMapInfo,
        mapShapeFiles,
        hoveredZone,
        regionZoneMap,
        loadingIndicators,
        indicators,
        dataSource,
        availableYears,
    };

    function getMapShapeFilesUseEffect() {
        (async function () {
            try {
                setLoadingMapInfo(true);

                const response: Awaited<MapShapeFileResponse> =
                    await indicatorUseCases.getMapShapeFiles({
                        geoService,
                        getMapParams: choroplethMapTraits.getMapParams,
                    });
                setMapShapeFiles(response);

                setLoadingMapInfo(false);
            } catch (error) {
                setLoadingMapInfo(false);
                throw error;
            }
        })();
    }
}

function extractYearData(props: {
    area: "Tanzania" | "Mainland" | "Zanzibar";
    year: string;
    data: IndicatorEntity[];
    selectedIndicator: string;
}): {
    year: string;
    area: string;
    value: number | null;
} {
    const res = props.data
        ?.filter((res) => res.area_name === props.area)
        .filter((res) => res.time_value === props.year)
        .filter((res) => res.indicatorkey === props.selectedIndicator)
        .filter((res) => res.subgroup_name === "Total")[0];

    return {
        area: props.area,
        year: props.year,
        value: res?.data_value ?? null,
    };
}

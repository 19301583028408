import { Loader, Stack, Tabs, Text } from "@mantine/core";
import { useGetVisualizations } from "~/domains/visualization/ui/hooks/use-get-visualizations";

import * as Highcharts from "highcharts";
import highChartsMore from "highcharts/highcharts-more";
import accessibilityModule from "highcharts/modules/accessibility";
import boostModule from "highcharts/modules/boost";
import dataModule from "highcharts/modules/data";
import drillDown from "highcharts/modules/drilldown";
import {
    default as exportModule,
    default as exporting,
} from "highcharts/modules/exporting";
import mapModule from "highcharts/modules/map";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbCreator } from "~/components/breadcrumb-creator";
import { topicVisualizationDataTv2 } from "~/domains/visualization/utils/topicVisualizationDataType";
import { routePaths } from "~/router/router";
import { Visualizer } from "~/domains/visualization/ui/components/Visualizer.tsx";

exporting(Highcharts);
accessibilityModule(Highcharts);
mapModule(Highcharts);
drillDown(Highcharts);
dataModule(Highcharts);
exportModule(Highcharts);
highChartsMore(Highcharts);
boostModule(Highcharts);

export const VisualizationsPage: React.FC = function () {
    const hook = useGetVisualizations();
    const breadCrumbs = [
        { title: "DHS", path: routePaths.root },
        { title: "Visualizations" },
    ];
    const [activeTab, setActiveTab] = useState<string | null>("mortality");

    useEffect(() => {
        if (hook.topics?.length) {
            // Sort Keys
            hook.topics.sort((a, b) => a.subsectorkey - b.subsectorkey);
            setActiveTab(hook.topics[0].subsectorkey.toString());
        }
    }, [hook.topics]);

    const tabsList = useMemo(
        () =>
            hook.topics?.map((dt) => ({
                key: dt.subsectorkey,
                label: dt.subsector_name,
                value: dt.subsectorkey.toString(),
            })),
        [hook.topics]
    );
     // Sort tabsList by key
    tabsList.sort((a, b) => a.key - b.key);


    const tabsListMapper = useCallback(
        (item: { label: string; value: string; key: number }) => {
            return (
                <Tabs.Tab key={item.key} value={item.value}>
                    <p className="font-bold">{item.label}</p>
                </Tabs.Tab>
            );
        },
        []
    );

     

    const tabsListPanelMapper = useCallback(
        (item: {
            label: string;
            value: string;
            key: number;
            source?: topicVisualizationDataTv2;
        }) => {
            return (
                <Tabs.Panel key={item.key} value={item.value}>
                    <Visualizer source={[]} topicKey={item.value} topics={hook.topics} />
                </Tabs.Panel>
            );
        },
        [hook.topics]
    );

    if (hook.loadingMapInfo || hook.topicsLoading)
        return <Loader size={"sm"} />;

    return (
        <Stack spacing={"sm"}>
            <BreadcrumbCreator data={breadCrumbs} />

            <Text size="sm"></Text>
            <Tabs
                variant="pills"
                radius="md"
                color={"blue"}
                value={activeTab}
                onTabChange={setActiveTab}
                keepMounted={false}
            >
                <Tabs.List mb={"md"}>{tabsList.map(tabsListMapper)}</Tabs.List>

                {tabsList.map(tabsListPanelMapper)}
            </Tabs>
        </Stack>
    );
};

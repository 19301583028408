export type IndicatorType = {
    datavaluekey: number;
    area_code: string;
    area_level: string;
    area_name: string;
    parent_code: string;
    data_value: number;
    time_value: string;
    indicatorkey: string;
    indicator_name: string;
    uom: string;
    subgroupkey: number;
    subgroup_name: string;
    subgroup_desc?: string;
    subsectorkey: number;
    subsector_name: string;
    sourcekey: number;
    source_name: string;
};

export class IndicatorEntity {
    constructor(props: IndicatorType) {
        this._datavaluekey = props.datavaluekey;
        this._area_code = props.area_code;
        this._area_level = props.area_level;
        this._area_name = props.area_name;
        this._parent_code = props.parent_code;
        this._data_value = props.data_value;
        this._time_value = props.time_value;
        this._indicatorkey = props.indicatorkey;
        this._indicator_name = props.indicator_name;
        this._uom = props.uom;
        this._subgroupkey = props.subgroupkey;
        this._subgroup_name = props.subgroup_name;
        this._subgroup_desc = props.subgroup_desc ?? props.subgroup_name;
        this._subsectorkey = props.subsectorkey;
        this._subsector_name = props.subsector_name;
        this._sourcekey = props.sourcekey;
        this._source_name = props.source_name;
    }

    private _datavaluekey: number;
    public get datavaluekey(): number {
        return this._datavaluekey;
    }

    private _area_code: string;
    public get area_code(): string {
        return this._area_code;
    }

    private _area_level: string;
    public get area_level(): string {
        return this._area_level;
    }

    private _area_name: string;
    public get area_name(): string {
        return this._area_name;
    }

    private _parent_code: string;
    public get parent_code(): string {
        return this._parent_code;
    }

    private _data_value: number;
    public get data_value(): number {
        return this._data_value;
    }

    private _time_value: string;
    public get time_value(): string {
        return this._time_value;
    }

    private _indicatorkey: string;
    public get indicatorkey(): string {
        return this._indicatorkey;
    }

    private _indicator_name: string;
    public get indicator_name(): string {
        return this._indicator_name;
    }

    private _uom: string;
    public get uom(): string {
        return this._uom;
    }

    private _subgroupkey: number;
    public get subgroupkey(): number {
        return this._subgroupkey;
    }

    private _subgroup_name: string;
    public get subgroup_name(): string {
        return this._subgroup_name;
    }

    private _subgroup_desc: string;
    public get subgroup_desc(): string {
        return this._subgroup_desc;
    }

    private _subsectorkey: number;
    public get subsectorkey(): number {
        return this._subsectorkey;
    }

    private _subsector_name: string;
    public get subsector_name(): string {
        return this._subsector_name;
    }

    private _sourcekey: number;
    public get sourcekey(): number {
        return this._sourcekey;
    }

    private _source_name: string;
    public get source_name(): string {
        return this._source_name;
    }
}

import { Box, Breadcrumbs, Center, createStyles, Flex, Loader, Stack, Text, TextInput, } from '@mantine/core';
import { IconChevronRight, IconSearch } from "@tabler/icons-react";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import homeImage from "~/assets/images/dhs-bg.jpeg";
import { TopicsGridUi } from "~/domains/topic/ui/components";
import { useGetTopics } from "~/domains/topic/ui/hooks";
import { useTranslator } from "~/i18n/translator-hook";
import { routePaths } from "~/router/router";

const style = createStyles((theme) => ({
    fontsize: {
        textDecoration: "none",
        fontSize: theme.fontSizes.sm,
        color: theme.colors.blue[4],
    },
}));

export const HomePage: React.FC = () => {
    const {classes} = style();
    const translator = useTranslator();
    const breadcrumbsItems = [{title: "DHS", path: routePaths.root}, {title: translator("home")}].map(
        (item, index) => {
            if (!item.path) {
                return <Text size={"sm"}>{item.title}</Text>;
            }
            return (
                <NavLink to={item.path} key={index} className={classes.fontsize}>
                    {item.title}
                </NavLink>
            );
        }
    );
    const {loading: topicsLoading, topics} = useGetTopics();

    const [search, setSearch] = useState("");
    const filteredTopics = React.useMemo(
        () =>
            search.length
                ? topics.filter((topic) =>
                    topic.subsector_name
                        .toLowerCase()
                        .trim()
                        .split(" ")
                        .join()
                        .includes(search.toLowerCase().trim().split(" ").join())
                )
                : topics,
        [search, topics]
    );

    const rgb = '242,243,245'

    return (
        <Stack spacing={'sm'}>
            <Breadcrumbs separator={<IconChevronRight size={18}/>}>{breadcrumbsItems}</Breadcrumbs>
            <Stack spacing={0}>

                <Flex p={'xl'} h={500} w={'100%'} gap={'xl'} justify={'center'} align={'center'} sx={() => ({
                    backgroundColor: `rgba(${rgb},1)`,
                    // background: 'rgb(2,0,36)',
                    // background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(21,21,64,0) 23%, rgba(18,18,80,0.2007177871148459) 36%, rgba(9,9,121,1) 68%, rgba(28,29,29,1) 100%)',

                })}>
                    <Box h='100%' w='33%' sx={{position: 'relative'}}>
                        <img src={homeImage} alt={''} style={{
                            objectFit: 'cover',
                            height: '100%',
                            width: '100%',
                        }}/>

                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: `linear-gradient(90deg, rgba(${rgb},1) 0%, rgba(${rgb},0) 33%, rgba(${rgb},0) 66%, rgba(${rgb},1) 100%), linear-gradient(180deg, rgba(${rgb},1) 0%, rgba(${rgb},0) 33%, rgba(${rgb},0) 66%, rgba(${rgb},1) 100%)`,
                        }}/>
                    </Box>
                    <Text w={"50%"} className={'text-xl'} align="left"
                          sx={() => ({fontSize: 'medium'})}>
                        <strong className={'underline underline-offset-2'}>The Demographic and Health Surveys (DHS)
                            Dashboard</strong> provides current and
                        reliable information on health issues that intends to
                        assist <strong>decision makers</strong> and <strong>programme managers</strong> in evaluating
                        and designing programmes and strategies for improving the health of Tanzania’s population
                        and {' '}
                        <strong>monitor</strong> national and
                        international programs such as <strong>FYDPs</strong> and <strong>SDGs</strong>.
                    </Text>
                </Flex>

                <Box
                    w={'100%'}
                    mb={'md'}
                >
                    <Center h={"100%"} my={60}>
                        <Stack
                            align="center"
                            w={"fit-content"}
                            p={"lg"}
                            sx={(theme) => ({
                                backdropFilter: "blur(10px)",
                                borderRadius: theme.radius.md,
                            })}
                        >
                            <Text>
                                {translator("home_search")}
                            </Text>

                            <Text color={'dimmed'}>
                                Use the search bar to find the specific topic you want to view
                            </Text>

                            {topicsLoading ? (
                                <Loader size={"xs"}/>
                            ) : (
                                <>
                                    <TextInput
                                        value={search}
                                        onChange={({target: {value}}) => setSearch(value)}
                                        maw={600}
                                        miw={"40vw"}
                                        w={"100%"}
                                        icon={<IconSearch size={14}/>}
                                        placeholder={translator("search")}
                                    />
                                </>
                            )}
                        </Stack>
                    </Center>

                    {filteredTopics.length ? (
                        <>
                            <TopicsGridUi topics={filteredTopics} showTitle filtered={!!search.length}/>
                        </>
                    ) : topicsLoading ? (
                        <></>
                    ) : (
                        <Text align={'center'} color={"dimmed"} size={"sm"}>
                            No topics found
                        </Text>
                    )}
                </Box>
            </Stack>
        </Stack>
    )

};

import { Group, Header } from "@mantine/core";
import React from "react";
import { Logo } from "../logo";
import { NavbarCmp } from "../navbar";
import { HeaderDropDown } from "./header-drop-down";

export const HeaderCmp: React.FC = () => {
    return (
        <Header height={60} p="xs" sx={(theme) => ({ backgroundColor: theme.colors.brand, position: "relative" })}>
            <Group spacing={"md"} position="apart">
                <Logo />
                <NavbarCmp />
                <HeaderDropDown />
            </Group>
        </Header>
    );
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translation files
import en from "./en";
import kis from "./kis";

export type languagesT = "en" | "kis";

i18n.use(initReactI18next).init({
    resources: { en: { translation: en }, kis: { translation: kis } },
    // Default language
    lng: "en" as languagesT,
    interpolation: { escapeValue: false },
});

export default i18n;

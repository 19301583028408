import { Container, Group, Text } from "@mantine/core";
import React, { useState } from "react";
import tzFlag from "~/assets/images/tz_flag.png";
import { changeLanguage } from "~/i18n/change-language";
import i18n, { languagesT } from "~/i18n/i18n";

export const HeaderDropDown: React.FC = () => {
    const [currentLanguage, setCurrentLanguage] = useState<languagesT>(i18n.language as languagesT);
    const currentLanguageObj = data.find((dt) => dt.value === currentLanguage)!;
    return (
        <Group>
            {data.map((dt, dtIndex) => (
                <Container
                    key={dtIndex}
                    bg={currentLanguageObj.value === dt.value ? "brand.8" : undefined}
                    px={"xs"}
                    onClick={() => {
                        changeLanguage(dt.value);
                        setCurrentLanguage(dt.value);
                    }}
                    sx={(theme) => ({ cursor: "pointer", borderRadius: theme.radius.sm })}
                >
                    <Text weight={"bolder"} color="white">
                        {dt.label}
                    </Text>
                </Container>
            ))}
        </Group>
    );
};

type dataT = { image?: string; label: string; value: languagesT };

const data: dataT[] = [
    { image: undefined, label: "English", value: "en" },
    { image: tzFlag, label: "Kiswahili", value: "kis" },
];

import {Box, Stack} from "@mantine/core";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import React, {useRef} from "react";
import {areaZonesTv2} from "~/domains/visualization/utils/areaZonesColors.ts";

exporting(Highcharts);

export const TopicIndicatorGraphView: React.FC<{
  show: boolean;
  dataV2: {
    columns: {
      column1: string[];
      column2: string[];
    };
    data: string[][];
  };
  title?: string;
  areasToNotShow: (string | string[])[];
  period: string;
  showSubTitle: boolean;
  zone?: { name: string; value?: number };
  tanzaniaValue?: number;
  flatZoneInformation: { zone: areaZonesTv2, region: string, color: string, total: number }[];
  selectedZones: areaZonesTv2[]
}> = function ({flatZoneInformation, selectedZones, show, title, dataV2, period}) {
  const topAreas = ["Tanzania", "Mainland", "Zanzibar"];

  let sortedData = dataV2.data.filter((dt) => !topAreas.includes(dt[0]));

  const sortedAreas = sortedData.map(data => {
    const areaName: string = data[0];
    const values: string[] = [...data].slice(1)
    const sum = values.reduce((accumulator, iterator) => accumulator + Number(iterator), 0)
    return {areaName, sum}
  }).sort((a, b) => a.sum > b.sum ? 1 : -1).map(dt => dt.areaName)

  sortedData = sortedAreas.map(area => sortedData.find(dt => dt[0] === area)!)

  const caption: string = `${(function () {
    const captionInfo2 = selectedZones?.map(_zone => `${_zone}: ${(function () {
      const regionsInZone = flatZoneInformation.filter(z => z.zone === _zone)?.map(dt => dt.region)
      const x = dataV2.columns.column1
        .filter((column) => column.length)
        .map((column, columnIndex) => ({
          label: column,
          value: sortedData?.filter(sorted => regionsInZone.includes(sorted[0]))?.map((sorted) => sorted[columnIndex + 1])?.reduce((acc, iterator) => acc + Number(iterator ?? '0'), 0) ?? null
        })).map(dt => `${dt.label} - ${dt.value.toFixed(2)}`).join(', ')

      return `${x}`
    })()}`).join(',<br> ')

    // const captionInfo = selectedZones?.map(_zone => `${_zone}: ${flatZoneInformation.find(zoneInfo => zoneInfo.zone === _zone)?.total}`).join(', ')
  
    return captionInfo2 ?? ''
  })()}`;

  const headerStyle = 'font-size: 14px; font-weight: bold; color: #333;';
  const pointStyleZone = 'font-size: 10px; font-weight: bold;';
  const pointStyleDataValue = 'font-size: 11px; color: #212121; font-weight: bold;';
  const options: Highcharts.Options = {
    title: {text: title},
    subtitle: {text: period, align: 'center'},
    caption: {text: caption, align: 'center'},
    chart: {type: "column"},
    exporting: {enabled: true},
    xAxis: {
      categories: sortedData.map((dt) => dt[0]),
      gridLineWidth: 1,
      lineWidth: 0,
      labels: {enabled: true},
    },
    yAxis: {
      title: {text: "", align: "high"},
      labels: {overflow: "justify"},
      gridLineWidth: 0,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      floating: false,
      borderWidth: 1,
      backgroundColor: Highcharts.defaultOptions.legend?.backgroundColor || "#FFFFFF",
      enabled: true
    },
    tooltip: {
      // headerFormat: '{point.x:.1f} <br>',
      headerFormat: '<span style="' + headerStyle + '">' + '{point.x:.1f}' + '</span><br>',
      pointFormat: '<span style="' + pointStyleZone + 'color: {point.color}' + '">' + '(' + '</span> <span>' + '{point.name} : {point.column}' + '</span> <span style="' + pointStyleZone + 'color: {point.color}' + '">' + ')' + '</span> <span style="' + pointStyleDataValue + '">' + ' {point.y}' + '</span> <br >',
      // pointFormat: '{point.name} <br>{series.name} - {point.y}',
      shared: true,
      style: {
        fontSize: '10px'
      }
    },
    series: [
      ...(dataV2.columns.column1
        .filter((column) => column.length)
        .map((column, columnIndex) => {
          return {
            name: column,
            data: sortedData.map((dt) => {
              let value: number | null =
                dt[columnIndex + 1] === "" ? null : Number(dt[columnIndex + 1]);

              const target = flatZoneInformation.find(dt2 => dt2.region === dt[0]);
              return {y: value, color: target?.color, name: target?.zone, column};
            }),
            dataLabels: {
              enabled: false,
              align: "center",
              format: "{point.y:.1f}", // one decimal
            },
          };
        }) as any),
    ],
    plotOptions: {
      column: {
        borderRadius: "10%",
        dataLabels: {enabled: true},
        // groupPadding: 0.1,
      },
    },
  };

  // @ts-ignore
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  if (!show) return undefined;

  return (
    <Stack>
      <Box>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
          // @ts-ignore
          {...HighchartsReact.Props}
          allowChartUpdate // allows chart options updating
          immutable // makes the entire chart re-render once an option updates
        />
      </Box>
    </Stack>
  );
};

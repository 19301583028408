export type seriesObjectT = {
    name: string;
    colorByPoint?: boolean,
    data: (number | null | { name: string, y: number | null } | { name: string, value: number | null })[]
} | any;

export type chartTypesT = 'column' | 'bar' | 'pie' | 'bubble' | 'map' | 'line'

export class ChartOptionsBuilder {
    private categories: string[] = [];
    private series: seriesObjectT[] = [];
    private title: string = '';
    private subtitle: string = '';
    private yAxisLabel: string = '';
    private tooltipSuffix: string = '';
    private accessibilityDescription: string = '';
    private minimumYAxisValue: number | undefined = undefined;
    private chartType: chartTypesT = undefined;
    private caption = ''

    setCaption(caption: typeof this.caption) {
        this.caption = caption;
        return this;
    }

    setCategories(categories: typeof this.categories) {
        this.categories = categories;
        return this;
    }

    setSeries(series: typeof this.series) {
        this.series = series;
        return this;
    }

    setTitle(title: typeof this.title) {
        this.title = title;
        return this;
    }

    setSubtitle(subtitle: typeof this.subtitle) {
        this.subtitle = subtitle;
        return this;
    }

    setYAxisLabel(label: typeof this.yAxisLabel) {
        this.yAxisLabel = label;
        return this;
    }

    setAccessibilityDescription(label: typeof this.accessibilityDescription) {
        this.accessibilityDescription = label;
        return this;
    }

    setMinimumYAxisValue(value: typeof this.minimumYAxisValue) {
        this.minimumYAxisValue = value;
        return this;
    }

    setTooltipSuffix(label: typeof this.tooltipSuffix) {
        this.tooltipSuffix = label;
        return this;
    }

    setChartType(type: typeof this.chartType) {
        this.chartType = type
        return this;
    }

    build(shapeFileData?: any, onBubbleChartPointHover?: (props?: {name?: string; value?: number | string}) => void, onBubbleChartPointLeave?: () => void) {
        switch (this.chartType) {
            case 'column':
                return {
                    chart: {type: this.chartType, height: '35%',},
                    title: {text: this.title, align: 'left'},
                    subtitle: {text: this.subtitle, align: 'left'},
                    xAxis: {
                        categories: this.categories,
                        crosshair: true,
                        accessibility: {description: this.accessibilityDescription},
                    },
                    yAxis: {min: undefined, title: {text: this.yAxisLabel}},
                    tooltip: {valueSuffix: ` ${this.tooltipSuffix}`},
                    plotOptions: {column: {pointPadding: 0.2, borderWidth: 0}},
                    series: this.series,
                    legend: {
                        backgroundColor: '#F2F2F2',
                        align: 'center',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: 0,
                    },
                    caption: {
                        text: this.caption
                    },
                };

            case 'pie':
                return {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: this.chartType,
                    },
                    title: {text: this.title, align: 'left'},
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%',
                        },
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            },
                        },
                    },
                    series: this.series,
                    caption: {
                        text: this.caption
                    },
                };

            case 'bubble':
                return {
                    chart: {
                        type: 'packedbubble',
                        height: '100%',
                    },
                    title: {text: this.title, align: 'left'},
                    tooltip: {
                        useHTML: true,
                        pointFormat: '<b>{point.name}:</b> {point.value}',
                    },
                    plotOptions: {
                        packedbubble: {
                            minSize: '30%',
                            maxSize: '100%',
                            zMin: 0,
                            zMax: 1000,
                            layoutAlgorithm: {
                                splitSeries: false,
                                gravitationalConstant: 0.02
                            },
                            dataLabels: {
                                enabled: true,
                                format: '{point.name}',
                                filter: {
                                    property: 'y',
                                    operator: '>',
                                    value: 250
                                },
                                style: {
                                    color: 'black',
                                    textOutline: 'none',
                                    fontWeight: 'normal'
                                }
                            }
                        },
                        series: {
                            point: {
                                events: {
                                    mouseOver: function() {
                                        if (onBubbleChartPointHover) {
                                            if (this.options?.name && this.options?.value) {
                                                onBubbleChartPointHover(this.options)
                                            }
                                        }
                                    },
                                    mouseOut: onBubbleChartPointLeave
                                }
                            }
                        }
                    },
                    series: this.series,
                    caption: {
                        text: this.caption
                    },
                };

            case 'bar': {
                const headerStyle = 'font-size: 14px; font-weight: bold; color: #333;';
                const pointStyleZone = 'font-size: 10px; font-weight: bold;';
                const pointStyleDataValue = 'font-size: 11px; color: #212121; font-weight: bold;';
                return {
                    chart: {type: this.chartType, height: '90%'},
                    title: {text: this.title, align: 'left'},
                    xAxis: {
                        categories: this.categories,
                        title: {text: null},
                        gridLineWidth: 1,
                        lineWidth: 0
                    },
                    yAxis: {
                        min: undefined, title: {text: this.yAxisLabel}, labels: {
                            overflow: 'justify'
                        },
                        gridLineWidth: 0
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: '50%',
                            dataLabels: {
                                enabled: true
                            },
                            groupPadding: 0.1
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: this.series,
                    legend: {enabled: false},
                    tooltip: {
                        headerFormat: '<span style="' + headerStyle + '">' + '{point.x:.1f}' + '</span><br>',
                        pointFormat: '<span style="' + pointStyleZone + 'color: {point.color}' + '">' + '(' + '</span> <span>' + '{point.name}' + '</span> <span style="' + pointStyleZone + 'color: {point.color}' + '">' + ')' + '</span> <span style="' + pointStyleDataValue + '">' + ' {point.y}' + '</span>',
                        shared: true,
                        style: {
                            fontSize: 10
                        }
                    },
                    caption: {
                        text: this.caption
                    },
                }
            }

            case "line": {
                return {
                    chart: {type: this.chartType},
                    title: {text: this.title, align: 'left'},
                    xAxis: {
                        categories: this.categories,
                        crosshair: true,
                        accessibility: {description: this.accessibilityDescription},
                    },
                    yAxis: {min: undefined, title: {text: this.yAxisLabel}},
                    plotOptions: {
                        line: {
                            dataLabels: {
                                enabled: true,
                                style: {
                                    fontSize: '1.2em'
                                }
                            },
                            enableMouseTracking: false,
                            lineWidth: 2.5
                        }
                    },
                    caption: {
                        text: this.caption
                    },
                    legend: {enabled: false},
                    series: this.series,
                }
            }

            case "map": {
                return {
                    title: {text: this.title, align: 'left'},
                    exporting: {enabled: true},
                    yAxis: {min: 0},
                    chart: {
                        type: "map",
                        height: `100%`,
                        animation: {
                            defer: 0,
                            duration: 300,
                        },
                        // @ts-ignore
                        mapNavigation: {
                            enabled: true,
                            buttonOptions: {
                                verticalAlign: "bottom",
                            },
                        },
                    },
                    credits: {
                        enabled: true,
                        position: {
                            align: "center",
                            x: 20,
                        },
                        text: "NBS Tanzania",
                        href: "https://www.nbs.go.tz/index.php/en/census-surveys/gis/568-tanzania-districts-shapefiles-2019",
                    },
                    legend: {
                        layout: "vertical",
                        align: "left",
                        verticalAlign: "middle",
                    },

                    colorAxis: {
                        min: 0,
                        minColor: "#F2FFE9",
                        maxColor: "#1E6F5C",
                    },

                    caption: {
                        text: this.caption
                    },

                    mapNavigation: {
                        enabled: true,
                        buttonOptions: {
                            verticalAlign: "top",
                            align: "right",
                        },
                        // @ts-ignore
                        x: 10,
                    },

                    plotOptions: {
                        map: {
                            states: {
                                hover: {
                                    color: "#EEDD66",
                                },
                            },
                            // @ts-ignore
                            animation: {defer: 0, duration: 200},

                            borderColor: "#dddddd", // Set a border color to differentiate lakes
                            borderWidth: 1,
                        },
                    },

                    series: [
                        // @ts-ignore
                        {
                            name: "base",
                            nullColor: "#87cefa",
                            allAreas: true,
                            color: "#fff",
                        },
                        {
                            mapData: shapeFileData,
                            data: this.series,
                            nullColor: "#ffffff",
                            type: "map",
                            name: "Region",
                            allAreas: false,
                            color: "#eb1818",
                            borderWidth: 1,
                            dataLabels: {
                                enabled: true,

                                formatter: function (): string {
                                    //@ts-ignore
                                    let regionName = this?.point?.properties?.Region_Nam ?? "";
                                    //@ts-ignore
                                    let lakeName = this?.point?.properties?.LAKES ?? "";
                                    //@ts-ignore
                                    let pointValue = this.point.value ?? "";

                                    //@ts-ignore
                                    if (typeof pointValue === typeof 0) {
                                        return regionName
                                            ? `${regionName}<br />${Intl.NumberFormat().format(pointValue ?? "")}`
                                            : lakeName;
                                    }

                                    if (lakeName) return lakeName;

                                    return regionName;
                                },

                                style: {
                                    color: "#fff",
                                    textShadow: "1px 1px 0 #000",
                                    fontSize: "14px",
                                },
                            },
                            tooltip: {
                                // @ts-ignore
                                shared: true,
                                useHTML: true,
                                headerFormat: "<ul>",
                                pointFormatter: function () {
                                    //@ts-ignore
                                    let value = this.value === undefined ? "" : this.value;
                                    let txt = ``;
                                    //@ts-ignore
                                    txt += "<h1><b>" + this.properties?.Region_Nam?.toUpperCase() + "</b></h1></br>";
                                    txt += `<h1><b> ${value ? value.toLocaleString() : ""}</b>  </h1> </br>`;
                                    return txt;
                                },
                                footerFormat: "</ul>",
                            },
                        }
                    ],
                    drilldown: {
                        activeDataLabelStyle: {
                            color: "#FFFFFF",
                            textDecoration: "none",
                            textOutline: "1px #000000",
                        },
                        // @ts-ignore
                        drillUpButton: {
                            relativeTo: "spacingBox",
                            position: {
                                x: 0,
                                y: 60,
                            },
                        },
                    },
                }
            }

            default:
                break;
        }
    }
}

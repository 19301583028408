export type areaZonesTv2 = 'Lake Zone' | 'Northern Zone' | 'Central Zone' | 'Southern Zone' | 'Southwest Highlands Zone' | 'Southern Highland Zone' | 'Eastern Zone' | 'Western Zone' | 'Zanzibar Zone';

export const areaZoneColorMapV2 = new Map<areaZonesTv2, string>();
areaZoneColorMapV2.set('Lake Zone', '#d4a373');
areaZoneColorMapV2.set('Northern Zone', '#264653');
areaZoneColorMapV2.set('Central Zone', '#2a9d8f');
areaZoneColorMapV2.set('Southern Zone', '#e9c46a');
areaZoneColorMapV2.set('Southern Highland Zone', '#cdb4db');
areaZoneColorMapV2.set('Southwest Highlands Zone', '#99582a');
areaZoneColorMapV2.set('Eastern Zone', '#c1121f');
areaZoneColorMapV2.set('Western Zone', '#8d99ae')
areaZoneColorMapV2.set('Zanzibar Zone', '#ccd5ae');
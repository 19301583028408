import React from "react";

export const useTopicDescriptionBank = function () {
    const bank = React.useMemo(
        () => ({
            Nutrition: "Visualize information that measure children’s nutritional status by comparing height and weight measurements against an international reference standard and Women’s Nutritional Status by weight and height measurements of women age 15–49 and Breastfeeding collected in Demographic Health Surveys.",
            Mortality: "Visualize key indicators related to   Child Mortality, Neonatal, Postnatal and Maternal Mortality collected in Demographic Health Surveys.",
            Immunization: "Provide Vaccination information on BCG, 3 doses of DPT, 3 doses of polio vaccine and Breastfeeding collected in Demographic Health Surveys.",
            "Reproductive Health": "Visualize key indicators related to reproductive health that includes Antenatal care Coverage at least four visits, Maternal death per 1,000 women, family planning, Birth at health facility, Fertility rate and Birth attended by health Provider. ",
            Violence: "Visualize key indicators related to violence such as Genital mutilation/cutting, women violence collected in Demographic Health Surveys.",
            Morbidity: "Visualize key indicators related to morbidity such as ITN, antimalaria drugs, Malaria doses, Pregnancy women slept under ITN collected in Demographic Health Surveys.",
            "HIV/AIDS": "Visualize Key indicators related to HIV/AIDS such as Population age 15-49 years tested HIV and Youth who had sexual intercourse collected in Demographic Health Surveys.",
        }),
        []
    );

    return {
        getTopicDescription: (topicName: string) => bank[topicName as any] ?? undefined,
    };
};

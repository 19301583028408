import { ActionIcon, Card, Group, Image, Select, SimpleGrid, Stack, Text, TextInput, Title } from "@mantine/core";
import { IconDownload, IconSearch, IconSquareRoundedChevronRight } from "@tabler/icons-react";
import React from "react";
import { BreadcrumbCreator } from "~/components/breadcrumb-creator";
import { usePublicationPage } from "~/domains/publication/ui/hooks";
import { routePaths } from "~/router/router";

export const PublicationsPage: React.FC = function () {
  const breadCrumbs = [{title: "DHS", path: routePaths.root}, {title: "Publications"}];
  const {
    filteredPublications,
    onClickPublication,
    textBank,
    search,
    onSearch,
    year,
    setYear,
    yearOptions,
    mainPublications,
    downloadPublication
  } =
    usePublicationPage();

  return (
    <Stack>
      <BreadcrumbCreator data={breadCrumbs}/>

      <Title>DHS Publications</Title>
      <Text>Preview and download</Text>

      <SimpleGrid className="grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {mainPublications?.map((publication) => {
          return (
            <Card padding="sm" radius="sm" withBorder
                  className={'relative cursor-pointer group hover:bg-gray-100 transition-all'}
                  onClick={() => onClickPublication(publication.sourceUri)}
            >
              <Card.Section>
                <Image
                  src={publication.coverImage}
                  height={500}
                  fit={'fill'}
                  alt={publication.title}
                />
              </Card.Section>

              <Text fw={500} mt={'xs'} className={'text-sm mb-10'}>{publication.title}</Text>

              <ActionIcon className={'absolute bottom-4 right-4'}
                          onClick={downloadPublication(publication.sourceUri, publication.title)}>
                <IconDownload
                  className={'text-gray-500 group-hover:text-gray-400 transition-all group-hover:scale-125'}/>
              </ActionIcon>
            </Card>
          )
        })}
      </SimpleGrid>

      <Text className={'text-sm mt-4 font-bold'}>Other publications</Text>

      <Group>
        <Select
          value={year}
          onChange={setYear}
          placeholder="Select year"
          data={yearOptions}
        />

        <TextInput
          value={search}
          onChange={({target: {value}}) => onSearch(value)}
          w={"100%"}
          maw={400}
          icon={<IconSearch size={14}/>}
          placeholder={textBank["searchPlaceholder"]}
        />


      </Group>

      <Stack spacing={0}>
        {filteredPublications.length === 0 && <Text color="dimmed">No publication found</Text>}
        {filteredPublications.map((publication, index) => (
          <Group onClick={() => onClickPublication(publication.sourceUri)}
                 className={'hover:bg-gray-100 group transition-all p-2 rounded-sm border border-transparent hover:border-gray-300 cursor-pointer'}>
            <Text align={'right'} w={20} className={'text-gray-500'}>{index + 1}.</Text>
            <Text>{publication.title}</Text>
            <IconSquareRoundedChevronRight
              className={'text-sm text-gray-400 ml-auto hidden transition-all group-hover:block'}/>
          </Group>
        ))}
      </Stack>
    </Stack>
  );
};

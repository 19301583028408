import { Loader, Stack, Text, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import React, { useState } from "react";
import { BreadcrumbCreator } from "~/components/breadcrumb-creator";
import { TopicsGridUi } from "~/domains/topic/ui/components";
import { useTopicsPage } from "~/domains/topic/ui/hooks";
import { useTranslator } from "~/i18n/translator-hook";
import { routePaths } from "~/router/router";

export const TopicsPage: React.FC = () => {
    const translator = useTranslator();

    const breadCrumbs = [{ title: "DHS", path: routePaths.root }, { title: "Topics" }];

    const { loading, topics } = useTopicsPage();

    const [search, setSearch] = useState("");

    const filteredTopics = topics.filter((topic) =>
        search
            ? topic.subsector_name.toLowerCase().split(" ").join().includes(search.toLowerCase().split(" ").join())
            : true
    );

    return (
        <Stack>
            <BreadcrumbCreator data={breadCrumbs} />

            <Text>Select specific topic to view its information.</Text>

            <TextInput
                value={search}
                onChange={({ target: { value } }) => setSearch(value)}
                w={"100%"}
                icon={<IconSearch size={14} />}
                placeholder={translator("search")}
            />

            {loading ? (
                <Loader size={"sm"} />
            ) : (
                <>
                    {filteredTopics.length ? (
                        <TopicsGridUi topics={filteredTopics} />
                    ) : (
                        <Text color={"dimmed"} size={"sm"} align={'center'}>
                            No topics found
                        </Text>
                    )}
                </>
            )}
        </Stack>
    );
};

const mortalityData = [
		{
	        title: {
	            text: "Trends in early childhood mortality rates",
	            align: "center",
	        },
	        subtitle: { text: "Deaths per 1,000 live births in the 5-year period before the survey", align: "center" },
	        series: [
	            {
	                name: "Under-5 mortality",
	                data: [43934, 48656, 65165, 81827],
	            },
	            {
	                name: "Infant mortality",
	                data: [11744, 30000, 16005, 19771],
	            },
	            {
	                name: "Neonatal mortality",
	                data: [24916, 37941, 29742, 29851],
	            },
	        ],
	        xAxis: {
	            categories: ["2004-05 TDHS", "2010 TDHS", "2015-16 TDHS-MIS", "2022 TDHS-MIS"],
	            title: { text: "Age groups" },
	        },
	        legend: {
	            layout: "vertical",
	            align: "right",
	            verticalAlign: "middle",
	        },

	        responsive: {
	            rules: [
	                {
	                    condition: {
	                        maxWidth: 500,
	                    },
	                    chartOptions: {
	                        legend: {
	                            layout: "horizontal",
	                            align: "center",
	                            verticalAlign: "bottom",
	                        },
	                    },
	                },
	            ],
	        },
	        description: {
	            text: "",
	        },
	    },

	    {
	        title: {
	            text: "Childhood mortality by previous birth interval",
	            align: "center",
	        },
	        subtitle: { text: "Deaths per 1,000 live births for the 10-year period before the survey", align: "center" },
	        chart: {
	            type: "column",
	        },
	        series: [
	            {
	                name: "count",
	                data: [45, 65],
	                dataLabels: {
	                    verticalAlign: "top",
	                    y: -20,
	                    align: "center",
	                    enabled: true,
	                },
	            },
	            {
	                name: "count",
	                data: [27, 41],
	                dataLabels: {
	                    verticalAlign: "top",
	                    y: -20,
	                    align: "center",
	                    enabled: true,
	                },
	            },
	            {
	                name: "count",
	                data: [23, 32,],
	                dataLabels: {
	                    verticalAlign: "top",
	                    y: -20,
	                    align: "center",
	                    enabled: true,
	                },
	            },
	            {
	                name: "count",
	                data: [34, 46,],
	                dataLabels: {
	                    verticalAlign: "top",
	                    y: -20,
	                    align: "center",
	                    enabled: true,
	                },
	            },
	        ],
	        xAxis: {
	            categories: ["Infant mortality", "Under-5 mortality"],
	            title: { text: "" },
	            crosshair: true,
	        },
	        legend: {
	            enabled: false,
	            layout: "vertical",
	            align: "right",
	            verticalAlign: "middle",
	        },

	        responsive: {
	            rules: [
	                {
	                    condition: {
	                        maxWidth: 500,
	                    },
	                    chartOptions: {
	                        legend: {
	                            layout: "horizontal",
	                            align: "center",
	                            verticalAlign: "bottom",
	                        },
	                    },
	                },
	            ],
	        },
	        description: {
	            text: "",
	        },
	    },

	    {
	        title: {
	            text: "Perinatal mortality by wealth",
	            align: "center",
	        },
	        subtitle: { text: "Deaths per 1,000 pregnancies of 28 or more weeks’ duration in the 5-year period before the survey", align: "center" },
	        chart: {
	            type: "column",
	        },
	        series: [
	            {
	                name: "count",
	                data: [26, 31, 36, 44, 55],
	                dataLabels: {
	                    verticalAlign: "top",
	                    y: -20,
	                    align: "center",
	                    enabled: true,
	                },
	            },
	        ],
	        xAxis: {
	            categories: ["Lowest", "Second", "Middle", 'Fourth', 'Highest'],
	            title: { text: "" },
	            crosshair: true,
	        },
	        legend: {
	            enabled: false,
	            layout: "vertical",
	            align: "right",
	            verticalAlign: "middle",
	        },

	        responsive: {
	            rules: [
	                {
	                    condition: {
	                        maxWidth: 500,
	                    },
	                    chartOptions: {
	                        legend: {
	                            layout: "horizontal",
	                            align: "center",
	                            verticalAlign: "bottom",
	                        },
	                    },
	                },
	            ],
	        },
	        description: {
	            text: "",
	        },
	    },
	]

export default mortalityData
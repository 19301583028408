import {
    ActionIcon,
    Badge,
    Group,
    Loader,
    Menu,
    ScrollArea,
    SegmentedControl,
    Select,
    SimpleGrid,
    Stack,
    Table,
    Text,
    TextInput,
} from "@mantine/core";
import {
    IconDownload,
    IconFile,
    IconFileCv,
    IconSearch,
} from "@tabler/icons-react";
import { useGetVisualizationsV2 } from "~/domains/visualization/ui/hooks/use-get-visualizations-v2.ts";
import {
    gendersT,
    tanzaniaStatesT,
    topicVisualizationDataTv2,
} from "~/domains/visualization/utils/topicVisualizationDataType.ts";

import * as Highcharts from "highcharts";
import formap from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import highChartsMore from "highcharts/highcharts-more";
import accessibilityModule from "highcharts/modules/accessibility";
import boostModule from "highcharts/modules/boost";
import dataModule from "highcharts/modules/data";
import drillDown from "highcharts/modules/drilldown";
import exportModule from "highcharts/modules/exporting";
import mapModule from "highcharts/modules/map";
import React, { useEffect, useState } from "react";
import { sampleZoneInfo } from "~/domains/indicator/ui/hooks/use-topic-page";
import { TopicEntity } from "~/domains/topic/topic-entity";
import {
    areaZoneColorMapV2,
    areaZonesTv2,
} from "~/domains/visualization/utils/areaZonesColors.ts";
import { downloadLineTrendData } from "~/utils/functions/download-line-trend-data.ts";
import { downloadRegionProfileData } from "~/utils/functions/download-region-profile-data.ts";
import { downloadZoneProfileData } from "~/utils/functions/download-zone-profile-data.ts";
import { TEXTS } from "~/utils/texts.ts";

accessibilityModule(Highcharts);
mapModule(Highcharts);
drillDown(Highcharts);
dataModule(Highcharts);
exportModule(Highcharts);
highChartsMore(Highcharts);
boostModule(Highcharts);

export function Visualizer(props: {
    source: topicVisualizationDataTv2;
    topicKey: string;
    topics: TopicEntity[];
}) {
    const hookV2 = useGetVisualizationsV2(props);

    if (hookV2.loadingIndicators) return <Loader size={"sm"} />;

    return (
        <Stack spacing={"lg"}>
            {(hookV2.selectedIndicatorData
                ? [hookV2.selectedIndicatorData]
                : []
            )?.map((optionItem) => {
                // Simon changes - Filter out categoies with null values
                
                optionItem.indicatorOptions.lineChartOptions.series.forEach(function(serie) {
                    serie.data = serie.data.filter(function(value) {
                        return value !== null;
                    });
                
                    // Remove corresponding category from xAxis if corresponding data is null
                    optionItem.indicatorOptions.lineChartOptions.xAxis.categories = optionItem.indicatorOptions.lineChartOptions.xAxis.categories.filter(function(idx) {
                        return serie.data[idx] !== null;
                    });
                });


                
                
                const stateSelection = hookV2.selectedCategories?.find(
                    (dt) => dt.key === optionItem.key
                )?.stateSelection;
                const sortedZoneBarDataValues =
                    optionItem.indicatorOptions.bubbleChartOptions?.["series"]
                        ?.map((dt) => ({
                            ...dt,
                            data: undefined,
                            y: dt?.data[0]?.value ?? null,
                        }))
                        .sort((a, b) => (a.y > b.y ? 1 : -1));
                const sortedZoneBarCategories = sortedZoneBarDataValues.map(
                    (dt) => dt.name
                );
                let zoneRegions = hookV2.hoveredZone
                    ? optionItem.indicatorOptions.regionProfileChartOptions?.[
                          "xAxis"
                      ]?.categories.filter((dt) => {
                          return sampleZoneInfo
                              .find((_dt) => _dt.name === hookV2.hoveredZone)
                              ?.regions.includes(dt);
                      })
                    : sortedZoneBarCategories;

                zoneRegions = [...new Set(zoneRegions ?? [])];

                const zoneBarData = [
                    {
                        name: "Total",
                        visible: true,
                        data: sortedZoneBarDataValues,
                    },
                ];

                return (
                    <Stack
                        key={optionItem.key}
                        spacing={50}
                        className={"bg-white"}
                    >
                        <Stack>
                            <Group position="apart" align="center">
                                <Select
                                    maw={600}
                                    w={"100%"}
                                    value={hookV2.selectedIndicator}
                                    onChange={hookV2?.onChangeSelectedIndicator}
                                    data={hookV2.indicators?.map((dt) => ({
                                        label: dt.indicator_name,
                                        value: dt.indicatorkey,
                                    }))}
                                    placeholder="Select indicator"
                                    withAsterisk
                                />

                                <Group spacing={"lg"} mih={50}>
                                    <SegmentedControl
                                        value={stateSelection}
                                        onChange={(value) =>
                                            hookV2.onUpdateCategory(
                                                optionItem.key,
                                                value as tanzaniaStatesT
                                            )
                                        }
                                        color="blue"
                                        radius={"md"}
                                        data={[
                                            { label: "Tanzania", value: "all" },
                                            {
                                                label: "Mainland",
                                                value: "mainland",
                                            },
                                            {
                                                label: "Zanzibar",
                                                value: "zanzibar",
                                            },
                                        ]}
                                    />

                                    {optionItem["hasGenderSegregation"] ? (
                                        <SegmentedControl
                                            value={
                                                hookV2.selectedCategories.find(
                                                    (dt) =>
                                                        dt.key ===
                                                        optionItem.key
                                                )?.genderSelection
                                            }
                                            onChange={(value) =>
                                                hookV2.onUpdateGender(
                                                    optionItem.key,
                                                    value as gendersT
                                                )
                                            }
                                            color="blue"
                                            radius={"md"}
                                            data={[
                                                { label: "All", value: "all" },
                                                {
                                                    label: "Male",
                                                    value: "male",
                                                },
                                                {
                                                    label: "Female",
                                                    value: "female",
                                                },
                                            ]}
                                        />
                                    ) : (
                                        <></> 
                                    )}
                                </Group>
                            </Group>
                        </Stack>
<div className="flex justify-center items-center"><small>Source: NBS_ TDHS (2004-2005)_2005, NBS_ TDHS (2010)_2011, NBS_ TDHS (2015-2016)_2016, Tanzania Demographic and Health Survey and Malaria Indicator Survey (TDHS-MIS) 2022</small></div>
                        <Stack
                            align={"center"}
                            justify={"stretch"}
                            w={"100%"}
                            className={"border border-gray-200 p-4 rounded-md"}
                        >
                            <Text className={"text-sm text-gray-500"}>
                                <span className={"capitalize"}>
                                    {optionItem?.title?.text}{" "}
                                    {stateSelection === "all"
                                        ? "Tanzania"
                                        : `Tanzania ${stateSelection}`}
                                </span>
                            </Text>

                        {optionItem.indicatorOptions.lineChartOptions.series?.[0].data.length> 1&& <ImmutableChart
                                option={
                                    optionItem.indicatorOptions.lineChartOptions
                                }
                                DownloadButton={
                                    <Menu shadow="md" width={200}>
                                        <Menu.Target>
                                            <ActionIcon
                                                className={
                                                    "self-end h-fit w-fit p-2 rounded-md"
                                                }
                                            >
                                                <Group spacing={"sm"}>
                                                    <IconDownload
                                                        className={"text-xs"}
                                                    />
                                                    <Text className={"text-sm"}>
                                                        Download
                                                    </Text>
                                                </Group>
                                            </ActionIcon>
                                        </Menu.Target>

                                        <Menu.Dropdown>
                                            <Menu.Item
                                                onClick={() =>
                                                    downloadLineTrendData({
                                                        extension: "csv",
                                                        name: `${optionItem.title.text} - line trend`,
                                                        columnHeaders: [
                                                            "Year",
                                                            "Total",
                                                        ],
                                                        data:
                                                            Array.from({
                                                                length: optionItem
                                                                    ?.indicatorOptions
                                                                    ?.lineChartOptions?.[
                                                                    "xAxis"
                                                                ]?.categories
                                                                    ?.length,
                                                            }).map(
                                                                (_, ind) => ({
                                                                    year: optionItem
                                                                        ?.indicatorOptions
                                                                        ?.lineChartOptions?.[
                                                                        "xAxis"
                                                                    ]
                                                                        ?.categories[
                                                                        ind
                                                                    ],
                                                                    value: optionItem
                                                                        ?.indicatorOptions
                                                                        ?.lineChartOptions?.[
                                                                        "series"
                                                                    ]?.[0]
                                                                        ?.data[
                                                                        ind
                                                                    ],
                                                                })
                                                            ) ?? [],
                                                    })
                                                }
                                                icon={<IconFileCv size={14} />}
                                            >
                                                CSV (.csv)
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={() =>
                                                    downloadLineTrendData({
                                                        extension: "xlsx",
                                                        name: `${optionItem.title.text} - line trend`,
                                                        columnHeaders: [
                                                            "Year",
                                                            "Total",
                                                        ],
                                                        data:
                                                            Array.from({
                                                                length: optionItem
                                                                    ?.indicatorOptions
                                                                    ?.lineChartOptions?.[
                                                                    "xAxis"
                                                                ]?.categories
                                                                    ?.length,
                                                            }).map(
                                                                (_, ind) => ({
                                                                    year: optionItem
                                                                        ?.indicatorOptions
                                                                        ?.lineChartOptions?.[
                                                                        "xAxis"
                                                                    ]
                                                                        ?.categories[
                                                                        ind
                                                                    ],
                                                                    value: optionItem
                                                                        ?.indicatorOptions
                                                                        ?.lineChartOptions?.[
                                                                        "series"
                                                                    ]?.[0]
                                                                        ?.data[
                                                                        ind
                                                                    ],
                                                                })
                                                            ) ?? [],
                                                    })
                                                }
                                                icon={<IconFile size={14} />}
                                            >
                                                Excel (.xlsx)
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                }
                            />}

                            <Text className={"hidden text-sm text-gray-600"}>
                                {TEXTS.welcome}
                            </Text>
                        </Stack>

                        <Group
                            position={"right"}
                            spacing={"lg"}
                            align={"center"}
                        >
                            {/* <Flex gap="md" ml="sm" justify={"start"}>
                                {[
                                    {
                                        label: "Total",
                                        value: optionItem.total,
                                        key: 1,
                                    },
                                    {
                                        label: "Rural",
                                        value: optionItem.rural,
                                        key: 2,
                                    },
                                    {
                                        label: "Urban",
                                        value: optionItem.urban,
                                        key: 3,
                                    },
                                ].map((dt) => (
                                    <Group key={dt.key} spacing={0}>
                                        <IconPointFilled size={16} />
                                        <Group spacing={"xs"}>
                                            <Text
                                                color="dimmed"
                                                sx={{ whiteSpace: "nowrap" }}
                                                fz="xs"
                                            >
                                                {dt.label}:
                                            </Text>
                                            <Text
                                                sx={{ whiteSpace: "nowrap" }}
                                                fz="sm"
                                                fw="700"
                                            >
                                                {dt.value
                                                    ? new Intl.NumberFormat().format(
                                                          dt.value
                                                      )
                                                    : "-"}
                                            </Text>
                                        </Group>
                                    </Group>
                                ))}
                            </Flex> */}

                            <Select
                                value={
                                    hookV2.selectedCategories.find(
                                        (dt) => dt.key === optionItem.key
                                    )?.year
                                }
                                onChange={(value) =>
                                    hookV2.onUpdateYear(optionItem.key, value)
                                }
                                placeholder="Select year"
                                data={hookV2.availableYears?.map((year) => ({
                                    value: year,
                                    label: `${year}`,
                                }))}
                            />
                        </Group>

                        <Stack className={"border border-gray-200 p-4 h-full"}>
                            <SimpleGrid cols={2} className={"h-full"}>
                                <ImmutableChart
                                    option={
                                        optionItem.indicatorOptions.mapOptions
                                    }
                                    isMap
                                    DownloadButton={
                                        <Menu shadow="md" width={200}>
                                            <Menu.Target>
                                                <ActionIcon
                                                    className={
                                                        "self-end h-fit w-fit p-2 rounded-md"
                                                    }
                                                >
                                                    <Group spacing={"sm"}>
                                                        <IconDownload
                                                            className={
                                                                "text-xs"
                                                            }
                                                        />
                                                        <Text
                                                            className={
                                                                "text-sm"
                                                            }
                                                        >
                                                            Download
                                                        </Text>
                                                    </Group>
                                                </ActionIcon>
                                            </Menu.Target>

                                            <Menu.Dropdown>
                                                <Menu.Item
                                                    onClick={() =>
                                                        downloadRegionProfileData(
                                                            {
                                                                extension:
                                                                    "csv",
                                                                name: `${optionItem.title.text} - region profile`,
                                                                columnHeaders: [
                                                                    "Region",
                                                                    "Zone",
                                                                    "Total",
                                                                ],
                                                                data:
                                                                    optionItem.indicatorOptions.tableOptions?.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            a.region?.toLowerCase() >
                                                                            b.region?.toLowerCase()
                                                                                ? 1
                                                                                : -1
                                                                    ) ?? [],
                                                            }
                                                        )
                                                    }
                                                    icon={
                                                        <IconFileCv size={14} />
                                                    }
                                                >
                                                    CSV (.csv)
                                                </Menu.Item>
                                                <Menu.Item
                                                    onClick={() =>
                                                        downloadRegionProfileData(
                                                            {
                                                                extension:
                                                                    "xlsx",
                                                                name: `${optionItem.title.text} - region profile`,
                                                                columnHeaders: [
                                                                    "Region",
                                                                    "Zone",
                                                                    "Total",
                                                                ],
                                                                data:
                                                                    optionItem.indicatorOptions.tableOptions?.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            a.region?.toLowerCase() >
                                                                            b.region?.toLowerCase()
                                                                                ? 1
                                                                                : -1
                                                                    ) ?? [],
                                                            }
                                                        )
                                                    }
                                                    icon={
                                                        <IconFile size={14} />
                                                    }
                                                >
                                                    Excel (.xlsx)
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    }
                                />

                                <Stack
                                    spacing={"sm"}
                                    className={"h-full overflow-y-scroll"}
                                >
                                    <Menu shadow="md" width={200}>
                                        <Menu.Target>
                                            <ActionIcon
                                                className={
                                                    "self-end h-fit w-fit p-2 rounded-md"
                                                }
                                            >
                                                <Group spacing={"sm"}>
                                                    <IconDownload
                                                        className={"text-xs"}
                                                    />
                                                    <Text className={"text-sm"}>
                                                        Download
                                                    </Text>
                                                </Group>
                                            </ActionIcon>
                                        </Menu.Target>

                                        <Menu.Dropdown>
                                            <Menu.Item
                                                onClick={() =>
                                                    downloadRegionProfileData({
                                                        extension: "csv",
                                                        name: `${optionItem.title.text} - region profile`,
                                                        columnHeaders: [
                                                            "Region",
                                                            "Zone",
                                                            "Total",
                                                        ],
                                                        data:
                                                            optionItem.indicatorOptions.tableOptions?.sort(
                                                                (a, b) =>
                                                                    a.region?.toLowerCase() >
                                                                    b.region?.toLowerCase()
                                                                        ? 1
                                                                        : -1
                                                            ) ?? [],
                                                    })
                                                }
                                                icon={<IconFileCv size={14} />}
                                            >
                                                CSV (.csv)
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={() =>
                                                    downloadRegionProfileData({
                                                        extension: "xlsx",
                                                        name: `${optionItem.title.text} - region profile`,
                                                        columnHeaders: [
                                                            "Region",
                                                            "Zone",
                                                            "Total",
                                                        ],
                                                        data:
                                                            optionItem.indicatorOptions.tableOptions?.sort(
                                                                (a, b) =>
                                                                    a.region?.toLowerCase() >
                                                                    b.region?.toLowerCase()
                                                                        ? 1
                                                                        : -1
                                                            ) ?? [],
                                                    })
                                                }
                                                icon={<IconFile size={14} />}
                                            >
                                                Excel (.xlsx)
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>

                                    <TextInput
                                        placeholder={"Search ..."}
                                        icon={<IconSearch />}
                                        w={"100%"}
                                        value={hookV2.tableSearchText}
                                        onChange={(e) =>
                                            hookV2.setTableSearchText(
                                                e.currentTarget.value
                                            )
                                        }
                                    />

                                    <ScrollArea
                                        type="always"
                                        h={"60vh"}
                                        className={
                                            "border border-gray-300 rounded-md"
                                        }
                                    >
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th
                                                        className={
                                                            "bg-blue-100"
                                                        }
                                                    >
                                                        Region
                                                    </th>
                                                    <th
                                                        className={
                                                            "bg-blue-100"
                                                        }
                                                    >
                                                        Data value
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {optionItem.indicatorOptions.tableOptions
                                                    ?.filter((dt) =>
                                                        dt.region.includes(
                                                            hookV2.tableSearchText
                                                        )
                                                    )
                                                    ?.sort(
                                                        (a, b) =>
                                                            b.value - a.value
                                                    )
                                                    ?.map((dt, dtIndex) => (
                                                        <tr key={dtIndex}>
                                                            <td>
                                                                <div
                                                                    className={
                                                                        "flex items-start gap-2"
                                                                    }
                                                                >
                                                                    <Text
                                                                        color={
                                                                            "dimmed"
                                                                        }
                                                                        className={
                                                                            "w-4 text-right"
                                                                        }
                                                                    >
                                                                        {dtIndex +
                                                                            1}
                                                                        .
                                                                    </Text>
                                                                    <Text>
                                                                        {
                                                                            dt.region
                                                                        }
                                                                    </Text>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {new Intl.NumberFormat().format(
                                                                    dt.value
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    </ScrollArea>
                                </Stack>
                            </SimpleGrid>

                            <Text className={"hidden text-sm text-gray-600"}>
                                {TEXTS.welcome}
                            </Text>
                        </Stack>

                        <Stack className={"border border-gray-200 p-4"}>
                            <SimpleGrid cols={2}>
                                <ImmutableChart
                                    option={
                                        optionItem.indicatorOptions
                                            .bubbleChartOptions
                                    }
                                    DownloadButton={
                                        <Menu shadow="md" width={200}>
                                            <Menu.Target>
                                                <ActionIcon
                                                    className={
                                                        "self-end h-fit w-fit p-2 rounded-md"
                                                    }
                                                >
                                                    <Group spacing={"sm"}>
                                                        <IconDownload
                                                            className={
                                                                "text-xs"
                                                            }
                                                        />
                                                        <Text
                                                            className={
                                                                "text-sm"
                                                            }
                                                        >
                                                            Download
                                                        </Text>
                                                    </Group>
                                                </ActionIcon>
                                            </Menu.Target>

                                            <Menu.Dropdown>
                                                <Menu.Item
                                                    onClick={() =>
                                                        downloadZoneProfileData(
                                                            {
                                                                extension:
                                                                    "csv",
                                                                name: `${optionItem.title.text} - zone profile`,
                                                                columnHeaders: [
                                                                    "Zone",
                                                                    "Total",
                                                                ],
                                                                data:
                                                                    optionItem.indicatorOptions.zoneOptions?.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            a.zone?.toLowerCase() >
                                                                            b.zone?.toLowerCase()
                                                                                ? 1
                                                                                : -1
                                                                    ) ?? [],
                                                            }
                                                        )
                                                    }
                                                    icon={
                                                        <IconFileCv size={14} />
                                                    }
                                                >
                                                    CSV (.csv)
                                                </Menu.Item>
                                                <Menu.Item
                                                    onClick={() =>
                                                        downloadZoneProfileData(
                                                            {
                                                                extension:
                                                                    "xlsx",
                                                                name: `${optionItem.title.text} - zone profile`,
                                                                columnHeaders: [
                                                                    "Zone",
                                                                    "Total",
                                                                ],
                                                                data:
                                                                    optionItem.indicatorOptions.zoneOptions?.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            a.zone?.toLowerCase() >
                                                                            b.zone?.toLowerCase()
                                                                                ? 1
                                                                                : -1
                                                                    ) ?? [],
                                                            }
                                                        )
                                                    }
                                                    icon={
                                                        <IconFile size={14} />
                                                    }
                                                >
                                                    Excel (.xlsx)
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    }
                                />

                                <Stack>
                                    <Badge className={"w-fit"}>
                                        {hookV2.hoveredZone}
                                    </Badge>

                                    <ImmutableChart
                                        option={{
                                            ...optionItem.indicatorOptions
                                                .regionProfileChartOptions,
                                            xAxis: {
                                                ...optionItem.indicatorOptions
                                                    .regionProfileChartOptions?.[
                                                    "xAxis"
                                                ],
                                                categories: zoneRegions,
                                            },
                                            series: hookV2.hoveredZone
                                                ? optionItem.indicatorOptions.regionProfileChartOptions?.[
                                                      "series"
                                                  ]?.map((dt) => {
                                                      const __data =
                                                          zoneRegions?.map(
                                                              (region) => {
                                                                  const target =
                                                                      dt?.data?.find(
                                                                          (
                                                                              dt2
                                                                          ) =>
                                                                              dt2[
                                                                                  "region"
                                                                              ] ===
                                                                              region
                                                                      ) ?? null;
                                                                  return target;
                                                              }
                                                          );
                                                      const color =
                                                          areaZoneColorMapV2.get(
                                                              hookV2.hoveredZone as areaZonesTv2
                                                          );
                                                      return {
                                                          ...{
                                                              ...dt,
                                                              data: __data?.map(
                                                                  (__dt) => ({
                                                                      ...__dt,
                                                                      color,
                                                                  })
                                                              ),
                                                          },
                                                      };
                                                  })
                                                : zoneBarData,
                                        }}
                                        DownloadButton={
                                            <Menu shadow="md" width={200}>
                                                <Menu.Target>
                                                    <ActionIcon
                                                        className={
                                                            "self-end h-fit w-fit p-2 rounded-md"
                                                        }
                                                    >
                                                        <Group spacing={"sm"}>
                                                            <IconDownload
                                                                className={
                                                                    "text-xs"
                                                                }
                                                            />
                                                            <Text
                                                                className={
                                                                    "text-sm"
                                                                }
                                                            >
                                                                Download
                                                            </Text>
                                                        </Group>
                                                    </ActionIcon>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    <Menu.Item
                                                        onClick={() =>
                                                            downloadRegionProfileData(
                                                                {
                                                                    extension:
                                                                        "csv",
                                                                    name: `${optionItem.title.text} - region profile`,
                                                                    columnHeaders:
                                                                        [
                                                                            "Region",
                                                                            "Zone",
                                                                            "Total",
                                                                        ],
                                                                    data:
                                                                        optionItem.indicatorOptions.tableOptions?.sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) =>
                                                                                a.region?.toLowerCase() >
                                                                                b.region?.toLowerCase()
                                                                                    ? 1
                                                                                    : -1
                                                                        ) ?? [],withZone: true
                                                                }
                                                            )
                                                        }
                                                        icon={
                                                            <IconFileCv
                                                                size={14}
                                                            />
                                                        }
                                                    >
                                                        CSV (.csv)
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        onClick={() =>
                                                            downloadRegionProfileData(
                                                                {
                                                                    extension:
                                                                        "xlsx",
                                                                    name: `${optionItem.title.text} - region profile`,
                                                                    columnHeaders:
                                                                        [
                                                                            "Region",
                                                                            "Zone",
                                                                            "Total",
                                                                        ],
                                                                    data:
                                                                        optionItem.indicatorOptions.tableOptions?.sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) =>
                                                                                a.region?.toLowerCase() >
                                                                                b.region?.toLowerCase()
                                                                                    ? 1
                                                                                    : -1
                                                                        ) ?? [],withZone: true
                                                                }
                                                            )
                                                        }
                                                        icon={
                                                            <IconFile
                                                                size={14}
                                                            />
                                                        }
                                                    >
                                                        Excel (.xlsx)
                                                    </Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>
                                        }
                                    />
                                </Stack>
                            </SimpleGrid>

                            <Text className={"hidden text-sm text-gray-600"}>
                                {TEXTS.welcome}
                            </Text>
                        </Stack>
                    </Stack>
                );
            })}
        </Stack>
    );
}

function ImmutableChart(props: {
    option: any;
    isMap?: boolean;
    withoutBorder?: boolean;
    DownloadButton?: React.ReactNode;
}) {
    const [chartOptions, setChartOptions] = useState<
        Highcharts.Options | undefined
    >(undefined);

    useEffect(() => {
        setChartOptions(props.option);
    }, [props.option]);

    const Chart = (
        <HighchartsReact
            constructorType={props.isMap ? "mapChart" : undefined}
            highcharts={props.isMap ? formap :Highcharts}
            options={chartOptions}
            // ref={chartComponentRef}
            {...HighchartsReact["Props"]}
            allowChartUpdate // allows chart options updating
            immutable // makes the entire chart re-render once an option updates
            className={"h-fit w-full grow"}
            oneToOne={true}
        />
    );

    if (!chartOptions || !Object.keys(chartOptions).length) return <div></div>;

    if (props.withoutBorder) return Chart;

    return (
        <div
            className={
                "flex flex-col gap-4 w-full h-fit border border-gray-300 rounded-md p-3"
            }
        >
            <div className={"self-end"}>{props.DownloadButton}</div>
            {Chart}
        </div>
    );
}

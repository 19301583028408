import React from "react";

import nutritionImage from "~/assets/images/nutrition.png";
import antenatalCareImage from "~/assets/images/antenatal-care.png";
import youthSexualIntercourseImage from "~/assets/images/youth-sexual-intercourse.png";
import familyPlanningImage from "~/assets/images/family-planning.png";
import childVaccinationImage from "~/assets/images/child-vaccination.png";
import childhoodMortalityImage from "~/assets/images/childhood-mortality.png";
import birthRegistrationImage from "~/assets/images/birth-registration.png";
import maternalMortalityImage from "~/assets/images/maternal-mortality.png";
import malariaImage from "~/assets/images/malaria.png";
import mortalityImage from "~/assets/images/mortality-2.png";
import immunizationImage from "~/assets/images/immunization.png";
import reproductiveHealthImage from "~/assets/images/reproductive-health-2.png";
import healthInsuranceImage from "~/assets/images/health-insurance-2.png";
import violenceImage from "~/assets/images/violence-2.png";
import fertilityImage from "~/assets/images/fertility-2.png";

export const useTopicsImageBank = function () {
    const topicsImageBank = React.useMemo(
        () => ({
            Nutrition: nutritionImage,
            "Antenatal Care": antenatalCareImage,
            "Youth Sexual Intercourse": youthSexualIntercourseImage,
            "Family Planning": familyPlanningImage,
            "Child Vaccination": childVaccinationImage,
            "Childhood Mortality": childhoodMortalityImage,
            "Birth Registration": birthRegistrationImage,
            "Maternal Mortality": maternalMortalityImage,
            Malaria: malariaImage,
            Mortality: mortalityImage,
            Immunization: immunizationImage,
            "Reproductive Health": reproductiveHealthImage,
            Violence: violenceImage,
            Insurance: "",
            Fertility: fertilityImage,
            "Health Insurance": healthInsuranceImage,
        }),
        []
    );

    return {
        getTopicImage: (topicName: string) => topicsImageBank[topicName as any] ?? undefined,
    };
};

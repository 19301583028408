import React, {useMemo, useState} from "react";
import publication_cover from "~/assets/images/publication-cover.png";
import publication1 from '~/assets/images/publication-1.png'
import publication2 from '~/assets/images/publication-2.png'
import Tanzania_DHS_MIS_2022_Summary_Report_English_and_Swahili from '~/assets/images/Tanzania_DHS-MIS_2022_Summary_Report_English_and_Swahili.png'
import MMR_English from '~/assets/images/MMR_English.png'
import TDHS_2022_Brochure from '~/assets/images/TDHS_2022_Brochure.png'
import Policy_Implications from '~/assets/images/Policy_Implications_23-10-2023.png'
import TDHS_MIS_Key_Indicators_Report from '~/assets/images/2022-TDHS-MIS-Key-Indicators-Report.png'

export function usePublicationPage() {
  const mainPublications: { coverImage: string; sourceUri: string; title: string, year: string }[] = useMemo(() => [
    {
      coverImage: publication1,
      sourceUri: "https://www.nbs.go.tz/nbs/takwimu/dhs/Tanzania_DHS-MIS_2022_Final_Report.pdf",
      title: "Tanzania_DHS-MIS 2022 Final Report",
      year: '2022'
    },

    {
      coverImage: Tanzania_DHS_MIS_2022_Summary_Report_English_and_Swahili,
      sourceUri: "https://www.nbs.go.tz/nbs/takwimu/dhs/Tanzania_DHS-MIS_2022_Summary_Report_English_and_Swahili.pdf",
      title: "Tanzania DHS-MIS 2022 Summary Report English and Swahili",
      year: '2022'
    },


    {
      coverImage: Policy_Implications,
      sourceUri:
        "https://www.nbs.go.tz/nbs/takwimu/dhs/Policy_Implications_23-10-2023.pdf",
      title: "The 2022 Tanzania Demographic and Health Survey and Malaria Indicator Survey - Policy Implications",
      year: '2022'
    },
    {
      coverImage: TDHS_MIS_Key_Indicators_Report,
      sourceUri:
        "https://www.nbs.go.tz/nbs/takwimu/dhs/2022-TDHS-MIS-Key-Indicators-Report.pdf",
      title: "DHS 2022 Key Indicators Report",
      year: '2022'
    },
  ], [])

  const publications: { coverImage: string; sourceUri: string; title: string, year: string }[] = React.useMemo(
    () => [
      {
        coverImage: TDHS_2022_Brochure,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/dhs/TDHS_2022_Brochure.pdf",
        title: "The 2022 Tanzania Demographic and Health Survey and Malaria Indicator Survey - Brochure",
        year: '2022'
      },

      {
        coverImage: MMR_English,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/dhs/MMR_English.pdf",
        title: "The 2022 Tanzania Demographic and Health Survey and Malaria Indicator Survey - Maternal Mortality",
        year: '2022'
      },
      {
        coverImage: publication2,
        sourceUri: "https://www.nbs.go.tz/nbs/takwimu/dhs/Tanzania_DHS-MIS_2022_Infographic.pdf",
        title: "Tanzania_DHS-MIS 2022 Infographic",
        year: '2022'
      },
      {
        coverImage: publication_cover,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/dhs/DocumentaryTDHS_MIS_2021_2022.pdf",
        title: "Utafiti wa Afya ya Uzazi na Mtoto na Viashiria vya Malaria Tanzania TDHS/MIS 2021-22",
        year: '2021-22'
      },
      {
        coverImage: publication_cover,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/dhs/2015-16_TDHS-MIS_Key_Findings_English.pdf",
        title: "The 2015-16 Tanzania Demographic and Health Survey and Malaria Indicator Survey - Key Findings report (English)",
        year: '2015-16'
      },
      {
        coverImage: publication_cover,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/dhs/2015-16_TDHS-MIS_Key_Findings_SWAHILI.pdf",
        title: "The 2015-16 Tanzania Demographic and Health Survey and Malaria Indicator Survey - Key Findings report (Swahili)",
        year: '2015-16'
      },
      {
        coverImage: publication_cover,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/dhs/Tanzania_Demographic_and_Health_and_Malaria_Indicators_Survey_2015-16_Report.pdf",
        title: "2015-16 Tanzania Demographic and Health Survey and Malaria Indicator Survey (TDHS-MIS)Report",
        year: '2015-16'
      },
      {
        coverImage: publication_cover,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/dhs/TDHS-MIS_FINAL_PPS_15.12.2016.ppt",
        title: "2015-16 Tanzania Demographic and Health Survey and Malaria Indicator Survey (TDHS-MIS)Report (Powerpoint presentation)",
        year: '2015-16'
      },
      {
        coverImage: publication_cover,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/dhs/Key_Findings_on_Gender-Data_from_the_2010TDHS.zip",
        title: "Key Findings on Gender - 2010 TDHS",
        year: '2010'
      },
      {
        coverImage: publication_cover,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/references/2010TDHSMicronutrientsReport.pdf",
        title: "Micronutrients Report of the 2010 TDHS",
        year: '2010'
      },
      {
        coverImage: publication_cover,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/references/2010TDHS.pdf",
        title: "2010 TDHS Main Report",
        year: '2010'
      },
      {
        coverImage: publication_cover,
        sourceUri:
          "https://www.nbs.go.tz/nbs/takwimu/references/2010TZKeyFindings.pdf",
        title: "2010 TDHS Key Findings",
        year: '2010'
      },
    ],
    []
  );

  const otherReports = useMemo(
    () =>
      [
        {
          title: "Tanzania_DHS-MIS_2022_Final_Report",
          link: "https://www.nbs.go.tz/nbs/takwimu/dhs/Tanzania_DHS-MIS_2022_Final_Report.zip",
          year: '2022'
        },
        {
          title: "Tanzania_DHS-MIS_2022_Summary_Report_English_and_Swahili",
          link: "https://www.nbs.go.tz/nbs/takwimu/dhs/Tanzania_DHS-MIS_2022_Summary_Report_English_and_Swahili.zip",
          year: '2022'
        },
      ].sort((a, b) => (a.title > b.title ? 1 : -1)),
    []
  );

  const textBankEnglish = React.useMemo(
    () =>
      Object.freeze({
        about: "About Publications.",
        paragraph1: "Browse DHS Final Reports, Analytical Reports, summary reports, and other resources.",
        searchLabel: "Search for Publication:",
        searchPlaceholder: "Search ...",
      }),
    []
  );

  const [search, setSearch] = useState("");
  const [year, setYear] = useState('All years');

  return {
    textBank: textBankEnglish,
    onClickPublication: React.useCallback((url: string) => {
      window.open(url, "_blank");
    }, []),
    search,
    onSearch: React.useCallback((text: string) => {
      setSearch(text);
    }, []),
    filteredPublications: publications.filter(publication => year === 'All years' ? true : publication.year === year).filter((publication) => publication.title.toLocaleLowerCase().includes(search.toLowerCase())),
    otherReports,
    filteredOtherReports: search.length
      ? otherReports.filter((report) => report.title.toLocaleLowerCase().includes(search.toLowerCase()))
      : otherReports,
    year,
    setYear,
    yearOptions: ['All years', ...new Set(publications.map(publication => publication.year))],
    mainPublications,
    downloadPublication: (url: string, title: string) => async () => {
      console.log(url)
      console.log(title)
    }
  };
}

import { IndicatorEntity } from "~/domains/indicator/indicator-entity.ts";
import { GetMapShapeFilesPropsT, MapShapeFileResponse } from "./indicator-repository-contract";
import { IndicatorService, indicatorService } from "./indicator-service";
import { AreaLevelsT } from "~/utils/types";

export interface selectAreaItemI {
    area_level: "LVL1" | "LVL2" | "LVL3" | "LVL4" | "LVL5" | "LVL6" | "LVL7";
    area_code: string;
    parent_code: string;
    area_name: string;
}

export interface RegionI {
    area_code: string;
    area_id: string;
    area_level: string;
    area_name: string;
    areakey: number;
    parent_code: string;
}

export interface StateI {
    area_code: string;
    area_id: string;
    area_level: string;
    area_name: string;
    areakey: number;
    parent_code: string;
    regions: RegionI[];
}

export interface CountryI {
    area_code: string;
    area_id: string;
    area_level: string;
    area_name: string;
    areakey: number;
    parent_code: string | null;
    states: StateI[];
}

export type selectAreaTransformedDataI = CountryI;

export interface areaI {
    area_code: string;
    area_name: string;
    parent_code: string | null;
}

export interface IndicatorInfoExtraI {
    areas: { _area_code: string; _area_level: string; _area_name: string; _parent_code: string }[];
    subGroups: { _subgroupkey: number; _subgroup_name: string; _subgroup_desc: string }[];
    timeValues: string[];
    uomList: string[];
    areasV2: {
        formattedData: CountryI[];
        countries: areaI[];
        states: areaI[];
        regions: areaI[];
    };
}

class IndicatorUseCases {
    constructor(private service: IndicatorService) {}

    async getTopicInfo(sectorKey: string, indicatorKey: string): Promise<IndicatorEntity[]> {
        return await this.service.getIndicatorInfo(sectorKey, indicatorKey);
    }

    formatData(indicatorDataValues: IndicatorEntity[]): IndicatorInfoExtraI {
        const areasMap = new Map<
            string,
            { _area_code: string; _area_level: string; _area_name: string; _parent_code: string }
        >();
        const subGroupsMap = new Map<
            number,
            { _subgroupkey: number; _subgroup_name: string; _subgroup_desc: string }
        >();
        const timeValues = new Set<string>();
        const uomList = new Set<string>();

        const countriesMap = new Map();
        const statesMap = new Map();
        const regionsMap = new Map();

        indicatorDataValues.forEach((indicatorDataValue) => {
            if (!areasMap.has(indicatorDataValue.area_code)) {
                areasMap.set(indicatorDataValue.area_code, {
                    _area_code: indicatorDataValue.area_code,
                    _area_level: indicatorDataValue.area_level,
                    _area_name: indicatorDataValue.area_name,
                    _parent_code: indicatorDataValue.parent_code,
                });
            }

            switch (indicatorDataValue.area_level as AreaLevelsT) {
                case "LVL1": {
                    if (!countriesMap.has(indicatorDataValue.area_code)) {
                        countriesMap.set(indicatorDataValue.area_code, {
                            area_code: indicatorDataValue.area_code,
                            area_level: indicatorDataValue.area_level,
                            area_name: indicatorDataValue.area_name,
                            parent_code: indicatorDataValue.parent_code,
                            states: new Map(),
                        });
                    }
                    break;
                }

                case "LVL2": {
                    if (!statesMap.has(indicatorDataValue.area_code)) {
                        statesMap.set(indicatorDataValue.area_code, {
                            area_code: indicatorDataValue.area_code,
                            area_level: indicatorDataValue.area_level,
                            area_name: indicatorDataValue.area_name,
                            parent_code: indicatorDataValue.parent_code,
                        });
                    }
                    break;
                }

                case "LVL3": {
                    if (!regionsMap.has(indicatorDataValue.area_code)) {
                        regionsMap.set(indicatorDataValue.area_code, {
                            area_code: indicatorDataValue.area_code,
                            area_level: indicatorDataValue.area_level,
                            area_name: indicatorDataValue.area_name,
                            parent_code: indicatorDataValue.parent_code,
                        });
                    }
                    break;
                }

                default:
                    break;
            }

            if (!subGroupsMap.has(indicatorDataValue.subgroupkey)) {
                subGroupsMap.set(indicatorDataValue.subgroupkey, {
                    _subgroup_name: indicatorDataValue.subgroup_desc ?? indicatorDataValue.subgroup_name,
                    // _subgroup_name: indicatorDataValue.subgroup_name,
                    _subgroup_desc: indicatorDataValue.subgroup_desc ?? indicatorDataValue.subgroup_name,
                    _subgroupkey: indicatorDataValue.subgroupkey,
                });
            }

            timeValues.add(indicatorDataValue.time_value);
            uomList.add(indicatorDataValue.uom);
        });

        let formattedData: selectAreaTransformedDataI[] = Array.from(countriesMap.values())
            .map(function (countryItem: selectAreaItemI) {
                return {
                    ...countryItem,
                    states: Array.from(statesMap.values())
                        .filter(({ parent_code }: selectAreaItemI) => parent_code === countryItem.area_code)
                        .map(function (stateItem: selectAreaItemI) {
                            return {
                                ...stateItem,
                                regions: Array.from(regionsMap.values())
                                    .filter(({ parent_code }: selectAreaItemI) => parent_code === stateItem.area_code)
                                    .map(function (regionItem: selectAreaItemI) {
                                        return regionItem;
                                    })
                                    .sort((a, b) => (a.area_name > b.area_name ? 1 : -1)),
                            };
                        })
                        .sort((a, b) => (a.area_name > b.area_name ? 1 : -1)),
                };
            })
            .sort((a, b) => (a.area_name > b.area_name ? 1 : -1)) as never as selectAreaTransformedDataI[];

        return {
            areas: Array.from(areasMap.values()).map((area) => area),
            subGroups: Array.from(subGroupsMap.values()).map((subGroup) => subGroup),
            timeValues: [...timeValues],
            uomList: [...uomList],
            areasV2: {
                formattedData,
                countries: Array.from(countriesMap.values()).map(
                    (dt) => ({ area_code: dt.area_code, parent_code: dt.parent_code, area_name: dt.area_name } as areaI)
                ) as areaI[],
                states: Array.from(statesMap.values()).map(
                    (dt) => ({ area_code: dt.area_code, parent_code: dt.parent_code, area_name: dt.area_name } as areaI)
                ) as areaI[],
                regions: Array.from(regionsMap.values()).map(
                    (dt) => ({ area_code: dt.area_code, parent_code: dt.parent_code, area_name: dt.area_name } as areaI)
                ) as areaI[],
            },
        };
    }

    async getMapShapeFiles(props: GetMapShapeFilesPropsT): MapShapeFileResponse {
        return this.service.getMapShapeFiles(props);
    }
}

export const indicatorUseCases = new IndicatorUseCases(indicatorService);

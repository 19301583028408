import {Button, Menu} from '@mantine/core';
import {IconCloudDownload, IconCsv, IconFileSpreadsheet} from '@tabler/icons-react';
import React from 'react';
import {downloadTopicData} from '~/utils/functions/download-topic-data';

interface TopicDownloadIndicatorDataI {
	currentIndicatorName?: string;
	time_periods: string[];
	columns1: string[];
	columns2: string[];
	data: (string | number | null)[][];
}

export const TopicDownloadIndicatorData: React.FC<TopicDownloadIndicatorDataI> = function (props) {
	const onDownloadCSV = React.useCallback (() => {
		props.currentIndicatorName
			? downloadTopicData ({
				name: props.currentIndicatorName,
				extension: 'csv',
				columnHeaders1: props.columns1,
				columnHeaders2: props.columns2,
				data: props.data,
			})
			: undefined;
	}, [props.currentIndicatorName, props.columns1, props.columns2, props.data]);

	const onDownloadXLSX = React.useCallback (() => {
		props.currentIndicatorName
			? downloadTopicData ({
				name: props.currentIndicatorName,
				extension: 'xlsx',
				columnHeaders1: props.columns1,
				columnHeaders2: props.columns2,
				data: props.data,
			})
			: undefined;
	}, [props.currentIndicatorName, props.columns1, props.columns2, props.data]);
	return (
		<Menu shadow="md" width={200}>
			<Menu.Target>
				<Button
					disabled={!props.currentIndicatorName}
					leftIcon={<IconCloudDownload/>}
					variant="subtle"
					w={'fit-content'}
					color={'blue.5'}
					sx={{
						':hover': {
							backgroundColor: 'whitesmoke',
						},
					}}
				>
					Download
				</Button>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Label>File formats</Menu.Label>
				<Menu.Item onClick={onDownloadCSV} icon={<IconCsv size={14}/>}>
					Excel (.csv)
				</Menu.Item>

				<Menu.Item onClick={onDownloadXLSX} icon={<IconFileSpreadsheet size={14}/>}>
					Excel (.xlsx)
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
};

import { Stack, Table, Text, Title } from "@mantine/core";
import React from "react";

export const TopicIndicatorTableViewV2: React.FC<{
    show: boolean,
    title?: string,
    selectedTimePeriods: string[],
    tableDataV3: {
        columns: {
            column1: string[];
            column2: string[];
        };
        data: string[][];
    },
    zone?: { name: string; value?: number };
}> = function ({ show, title, tableDataV3}) {
    if (!show) return undefined;

    return (
        <Stack>
            <Title order={3}>{title}</Title>

            <Stack sx={theme => ({border: `1px solid ${theme.colors.gray[4]}`, borderRadius: theme.radius.md, overflow: 'hidden', overflowX: "scroll"})}>
                <Table>
                    <thead>
                        <tr>
                            {tableDataV3.columns.column1.map((column, columnIndex) => (
                                <th key={columnIndex} style={{backgroundColor: '#e7f5ff'}}>{column}</th>
                            ))}
                        </tr>
                        <tr>
                            {tableDataV3.columns.column2.map((column, columnIndex) => (
                                <th key={columnIndex} style={{ whiteSpace: "nowrap", backgroundColor: '#e7f5ff' }}>
                                    {column}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableDataV3.data.map((dt) => (
                            <>
                                <tr style={{ whiteSpace: "nowrap" }}>
                                    {dt.map((value) => (
                                        <td>
                                            <Text size={"sm"}>{value.length ? value : "-"}</Text>
                                        </td>
                                    ))}
                                </tr>
                            </>
                        ))}
                    </tbody>
                </Table>
            </Stack>
        </Stack>
    );
};

export class TopicEntity {
    constructor(
        private _indicatorkey: number,
        private _indicator_name: string,
        private _uom: string,
        private _subsectorkey: number,
        private _subsector_name: string
    ) {}

    public get indicatorkey(): number {
        return this._indicatorkey;
    }

    public get indicator_name(): string {
        return this._indicator_name;
    }

    public get uom(): string {
        return this._uom;
    }

    public get subsectorkey(): number {
        return this._subsectorkey;
    }

    public get subsector_name(): string {
        return this._subsector_name;
    }
}

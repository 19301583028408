const mapData = {
    type: "FeatureCollection",
    features: [
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.1",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [29.66042588, -4.44644303],
                            [29.33952129, -4.46466968],
                            [29.33234991, -4.58659493],
                            [29.36820595, -4.89499416],
                            [29.36820595, -4.90216623],
                            [29.41123323, -5.21773757],
                            [29.41123322, -5.22490965],
                            [29.54031562, -5.60502979],
                            [29.56182885, -6.09273098],
                            [29.66939738, -6.46567908],
                            [29.8415074, -6.76690653],
                            [30.12835771, -7.04661794],
                            [30.31481021, -7.36218961],
                            [30.54429008, -7.80685876],
                            [30.70922874, -8.11525835],
                            [30.70205748, -8.10808626],
                            [30.87416737, -8.42365795],
                            [30.98890738, -8.58502988],
                            [31.04000255, -8.65002692],
                            [31.06689478, -8.66392287],
                            [31.10812957, -8.66392295],
                            [31.15698389, -8.63568298],
                            [31.18297979, -8.60968425],
                            [31.18409669, -8.60088814],
                            [31.20666936, -8.59370685],
                            [31.21075338, -8.62151279],
                            [31.20509519, -8.65797664],
                            [31.21263944, -8.67935201],
                            [31.20446651, -8.69255444],
                            [31.19817963, -8.73467648],
                            [31.18183377, -8.72524617],
                            [31.18057639, -8.70198475],
                            [31.15228547, -8.71015768],
                            [31.14536991, -8.72838961],
                            [31.11833637, -8.7384486],
                            [31.10827737, -8.7629674],
                            [31.06364059, -8.78119933],
                            [31.03975048, -8.77679852],
                            [31.03534967, -8.76548215],
                            [31.00894481, -8.74913628],
                            [31.00202925, -8.73404779],
                            [30.98065389, -8.72210273],
                            [30.97185227, -8.70387081],
                            [30.95676377, -8.69821262],
                            [30.94859084, -8.6699217],
                            [30.91967123, -8.65168977],
                            [30.89326637, -8.61208248],
                            [30.83857059, -8.57876206],
                            [30.80713624, -8.5422982],
                            [30.75432652, -8.5473277],
                            [30.73420853, -8.50709172],
                            [30.70466024, -8.5177794],
                            [30.6964873, -8.50143353],
                            [30.71220448, -8.48131554],
                            [30.7159766, -8.45868281],
                            [30.66505295, -8.47691473],
                            [30.68391356, -8.50206222],
                            [30.6430489, -8.51966546],
                            [30.62921778, -8.55109982],
                            [30.55943351, -8.58567762],
                            [30.54937452, -8.57121781],
                            [30.56257695, -8.55424326],
                            [30.59023918, -8.54544164],
                            [30.60092686, -8.51212121],
                            [30.56069089, -8.49137454],
                            [30.53239996, -8.51086384],
                            [30.50473773, -8.51023515],
                            [30.49342136, -8.4932606],
                            [30.50285167, -8.47565736],
                            [30.47267469, -8.46182624],
                            [30.46890257, -8.45050987],
                            [30.47518944, -8.40964521],
                            [30.48524843, -8.39644277],
                            [30.48713449, -8.3606076],
                            [30.55440402, -8.31219869],
                            [30.58646706, -8.2920807],
                            [30.57703675, -8.27699221],
                            [30.59778343, -8.25184472],
                            [30.59715474, -8.22103905],
                            [30.58709575, -8.19589157],
                            [30.61098586, -8.1550269],
                            [30.61287192, -8.13490891],
                            [30.56760645, -8.09655899],
                            [30.55126058, -8.09090081],
                            [30.55377533, -8.07015413],
                            [30.5210836, -8.03494765],
                            [30.47644681, -7.99911248],
                            [30.47015994, -7.98150924],
                            [30.45570014, -7.9783658],
                            [30.4380969, -7.94630275],
                            [30.41672154, -7.92618476],
                            [30.39723224, -7.92241264],
                            [30.37648556, -7.88846353],
                            [30.36328313, -7.87714716],
                            [30.32241847, -7.87086029],
                            [30.3161316, -7.84885624],
                            [30.29601361, -7.84068331],
                            [30.28532593, -7.81113501],
                            [30.28532593, -7.77970065],
                            [30.2916128, -7.75832529],
                            [30.27589563, -7.72689093],
                            [30.25074814, -7.71620325],
                            [30.24446127, -7.69231313],
                            [30.2237146, -7.66213615],
                            [30.21617035, -7.61687067],
                            [30.21617035, -7.58983712],
                            [30.2237146, -7.56971913],
                            [30.2287441, -7.51628072],
                            [30.22308591, -7.49553405],
                            [30.22811541, -7.46661444],
                            [30.20233924, -7.40311703],
                            [30.19982449, -7.37231136],
                            [30.19165156, -7.35282206],
                            [30.15393033, -7.3213877],
                            [30.14952952, -7.3018984],
                            [30.15707377, -7.28492384],
                            [30.13066891, -7.24531655],
                            [30.10866486, -7.20445188],
                            [30.0678002, -7.18307652],
                            [30.04076665, -7.15541428],
                            [30.02567816, -7.15415691],
                            [30.00241674, -7.13655367],
                            [29.97978401, -7.10386193],
                            [29.91440055, -7.06865545],
                            [29.88485226, -7.07431364],
                            [29.86096215, -7.06425464],
                            [29.83707204, -7.06551202],
                            [29.80563768, -7.04350796],
                            [29.77105989, -7.03093422],
                            [29.70693381, -6.95297701],
                            [29.69561745, -6.91462709],
                            [29.67990027, -6.89199435],
                            [29.64972329, -6.86118868],
                            [29.64532248, -6.83038301],
                            [29.62771924, -6.79769128],
                            [29.57993903, -6.78511753],
                            [29.56485054, -6.78511753],
                            [29.53718831, -6.7574553],
                            [29.50009577, -6.71344719],
                            [29.4887794, -6.69207183],
                            [29.48815072, -6.66943909],
                            [29.47683435, -6.61725806],
                            [29.47872041, -6.56319096],
                            [29.48815072, -6.54935984],
                            [29.48500728, -6.51038124],
                            [29.47683435, -6.50032224],
                            [29.45105818, -6.51100992],
                            [29.41648039, -6.47203132],
                            [29.40327797, -6.44248302],
                            [29.39887716, -6.40979129],
                            [29.36995755, -6.36389712],
                            [29.36807149, -6.31234477],
                            [29.35109694, -6.30857265],
                            [29.32091996, -6.26393586],
                            [29.31777653, -6.237531],
                            [29.32594946, -6.20609664],
                            [29.31966259, -6.19163683],
                            [29.30080198, -6.18660734],
                            [29.28382743, -6.16711803],
                            [29.28319874, -6.1394558],
                            [29.25993732, -6.11367962],
                            [29.23981934, -6.11870912],
                            [29.21970135, -6.08978951],
                            [29.19392519, -6.06967152],
                            [29.19518256, -6.03257898],
                            [29.18638094, -6.01308968],
                            [29.18575225, -5.97473976],
                            [29.198326, -5.90621286],
                            [29.23290378, -5.88358012],
                            [29.24924965, -5.86723425],
                            [29.27816925, -5.84963101],
                            [29.32972159, -5.80247947],
                            [29.3341224, -5.76853036],
                            [29.35424038, -5.73772469],
                            [29.35549776, -5.71886408],
                            [29.33789452, -5.68428628],
                            [29.33852321, -5.62707575],
                            [29.37247231, -5.62896181],
                            [29.37687312, -5.61575938],
                            [29.363042, -5.57929552],
                            [29.34606745, -5.55792016],
                            [29.32846422, -5.51454075],
                            [29.30331673, -5.49253669],
                            [29.29765855, -5.46801789],
                            [29.26748157, -5.44475647],
                            [29.23919066, -5.39194674],
                            [29.18512357, -5.31461822],
                            [29.18009408, -5.2863273],
                            [29.16123347, -5.266838],
                            [29.13922942, -5.22534464],
                            [29.13357124, -5.19391028],
                            [29.12414093, -5.18007917],
                            [29.12036881, -5.12726944],
                            [29.10087951, -5.09960721],
                            [29.09459264, -5.07760316],
                            [29.09710739, -5.04176799],
                            [29.10779507, -5.02102131],
                            [29.10339426, -4.98141402],
                            [29.09144921, -4.94432147],
                            [29.07698941, -4.91665924],
                            [29.06064355, -4.83367253],
                            [29.06190092, -4.80978242],
                            [29.07950416, -4.76891775],
                            [29.08453366, -4.73559733],
                            [29.08076153, -4.7223949],
                            [29.09836477, -4.70164822],
                            [29.10150821, -4.62997788],
                            [29.11911144, -4.60797383],
                            [29.12351225, -4.58156897],
                            [29.13985811, -4.55516411],
                            [29.13168518, -4.53001662],
                            [29.14488761, -4.49921095],
                            [29.1650056, -4.50235439],
                            [29.18323752, -4.5262445],
                            [29.20209813, -4.52058632],
                            [29.19581126, -4.4137095],
                            [29.20084076, -4.39987838],
                            [29.19643995, -4.34266785],
                            [29.2134145, -4.31311955],
                            [29.218444, -4.26093851],
                            [29.2329038, -4.24459265],
                            [29.23227511, -4.21818778],
                            [29.24107673, -4.17480837],
                            [29.23730461, -4.15783382],
                            [29.24107673, -4.11571178],
                            [29.22410218, -4.06415943],
                            [29.20587026, -4.05787256],
                            [29.19266783, -4.0754758],
                            [29.19078177, -4.09747985],
                            [29.17820803, -4.12074127],
                            [29.17632197, -4.16412069],
                            [29.16437691, -4.19115424],
                            [29.16374822, -4.2370484],
                            [29.15180317, -4.2615672],
                            [29.1505458, -4.30368924],
                            [29.14111549, -4.32757935],
                            [29.11030983, -4.33952441],
                            [29.08327629, -4.32192117],
                            [29.03612476, -4.31877773],
                            [29.03863951, -4.27979913],
                            [29.06504436, -4.26408195],
                            [29.08516235, -4.24144921],
                            [29.08956316, -4.20184192],
                            [29.06693043, -4.14526007],
                            [29.07636073, -4.11068228],
                            [29.06567305, -4.08930691],
                            [29.07384598, -4.05724387],
                            [29.0681878, -4.03398244],
                            [29.07258861, -4.01449314],
                            [29.06378699, -4.00003333],
                            [29.06693043, -3.97488585],
                            [29.08076154, -3.94093674],
                            [29.07384598, -3.92081875],
                            [29.0876771, -3.89504257],
                            [29.09773609, -3.83280255],
                            [29.08453366, -3.78942313],
                            [29.08516235, -3.7567314],
                            [29.09647872, -3.73724209],
                            [29.09773609, -3.70895117],
                            [29.12351226, -3.68694712],
                            [29.1266557, -3.62847921],
                            [29.11911145, -3.60396041],
                            [29.11848276, -3.56120968],
                            [29.12602701, -3.54360644],
                            [29.10905246, -3.49331147],
                            [29.10528034, -3.45433286],
                            [29.12351226, -3.41095345],
                            [29.11974014, -3.38329121],
                            [29.14300156, -3.36820272],
                            [29.19141046, -3.35814373],
                            [29.22913168, -3.36757404],
                            [29.24673492, -3.35751504],
                            [29.29765857, -3.37448959],
                            [29.30394544, -3.39586496],
                            [29.30520281, -3.50337047],
                            [29.30268806, -3.54800726],
                            [29.30394544, -3.60018829],
                            [29.30897493, -3.62722184],
                            [29.31400443, -3.69449137],
                            [29.30960362, -3.74038553],
                            [29.31400443, -3.78816576],
                            [29.33160767, -3.81142718],
                            [29.33412241, -3.84160417],
                            [29.32720686, -3.86675165],
                            [29.35046828, -3.90195814],
                            [29.37938788, -3.92647694],
                            [29.39070425, -3.95602523],
                            [29.3976198, -3.99437515],
                            [29.40013455, -4.03649719],
                            [29.43911315, -4.08302004],
                            [29.45168689, -4.11319703],
                            [29.48752205, -4.12388471],
                            [29.50826872, -4.15343301],
                            [29.51518428, -4.20121323],
                            [29.54473257, -4.29991712],
                            [29.56547924, -4.33638098],
                            [29.59314147, -4.34832603],
                            [29.63903563, -4.38541858],
                            [29.64280775, -4.4111017],
                            [29.66042588, -4.44644303],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Tanganyika",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.2",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [31.18409669, -8.60088814],
                            [31.18297979, -8.60968425],
                            [31.15698389, -8.63568298],
                            [31.10812957, -8.66392295],
                            [31.06689478, -8.66392287],
                            [31.04000255, -8.65002692],
                            [30.98890738, -8.58502988],
                            [30.87416737, -8.42365795],
                            [30.70205748, -8.10808626],
                            [30.70922874, -8.11525835],
                            [30.54429008, -7.80685876],
                            [30.31481021, -7.36218961],
                            [30.12835771, -7.04661794],
                            [29.8415074, -6.76690653],
                            [29.66939738, -6.46567908],
                            [29.56182885, -6.09273098],
                            [29.54031562, -5.60502979],
                            [29.41123322, -5.22490965],
                            [29.41123323, -5.21773757],
                            [29.36820595, -4.90216623],
                            [29.36820595, -4.89499416],
                            [29.33234991, -4.58659493],
                            [29.33952129, -4.46466968],
                            [29.66042588, -4.44644303],
                            [29.65514957, -4.47520355],
                            [29.65514957, -4.53354855],
                            [29.64861176, -4.5489025],
                            [29.63900316, -4.6206203],
                            [29.62424355, -4.66143209],
                            [29.61364438, -4.70353162],
                            [29.59908289, -4.79555626],
                            [29.61096982, -4.8127923],
                            [29.60581882, -4.83270291],
                            [29.61658652, -4.863406],
                            [29.62841088, -4.87643771],
                            [29.60805509, -4.90262654],
                            [29.63734428, -4.91580825],
                            [29.66420002, -4.91654582],
                            [29.7020906, -4.94020407],
                            [29.73145848, -4.9702752],
                            [29.76951123, -4.99635009],
                            [29.78434153, -5.02751034],
                            [29.77090389, -5.0409521],
                            [29.79419132, -5.06886762],
                            [29.82347809, -5.12897148],
                            [29.82243956, -5.21325451],
                            [29.80853272, -5.23741154],
                            [29.80981643, -5.27651251],
                            [29.78885939, -5.29325327],
                            [29.79043699, -5.33556929],
                            [29.76793393, -5.36716595],
                            [29.76823884, -5.39264208],
                            [29.74955445, -5.40151736],
                            [29.7482585, -5.43637195],
                            [29.75372378, -5.46598894],
                            [29.78243877, -5.49106997],
                            [29.79381774, -5.5111595],
                            [29.83936265, -5.55211677],
                            [29.83973521, -5.56980807],
                            [29.87315555, -5.62605122],
                            [29.89304084, -5.68091281],
                            [29.91834012, -5.73702169],
                            [29.9367978, -5.75964784],
                            [29.94894044, -5.79816962],
                            [29.95549267, -5.85615162],
                            [29.94675361, -5.91430424],
                            [29.91889387, -5.93319052],
                            [29.90161935, -5.93575785],
                            [29.87197981, -5.9643348],
                            [29.84005327, -5.98087852],
                            [29.82467501, -6.00131423],
                            [29.79754663, -5.99248393],
                            [29.74651089, -6.01658764],
                            [29.73222223, -6.04000767],
                            [29.72797726, -6.08937038],
                            [29.73175948, -6.15472791],
                            [29.74077515, -6.16755646],
                            [29.74241439, -6.19927974],
                            [29.72282068, -6.23652395],
                            [29.74562537, -6.27322378],
                            [29.75761217, -6.28027223],
                            [29.79204063, -6.3242391],
                            [29.81722776, -6.36274495],
                            [29.86574944, -6.42047146],
                            [29.92276865, -6.46305785],
                            [29.94264321, -6.46567417],
                            [29.95670545, -6.48293993],
                            [29.95120284, -6.50691655],
                            [30.00126966, -6.48136986],
                            [30.05578447, -6.46193642],
                            [30.10234277, -6.45119487],
                            [30.14215442, -6.44959205],
                            [30.17932671, -6.50045573],
                            [30.23435549, -6.55742446],
                            [30.24224276, -6.57117719],
                            [30.28265474, -6.6108812],
                            [30.32251126, -6.62547257],
                            [30.33824242, -6.63803391],
                            [30.34923642, -6.67423495],
                            [30.37121683, -6.69795056],
                            [30.37712024, -6.72158631],
                            [30.40625479, -6.74878935],
                            [30.40771442, -6.76526916],
                            [30.3990085, -6.80451823],
                            [30.44406044, -6.82830673],
                            [30.45237601, -6.85291632],
                            [30.47682621, -6.88651214],
                            [30.51581195, -6.93000504],
                            [30.57017986, -6.96621411],
                            [30.57578868, -6.9862842],
                            [30.56558842, -7.02754064],
                            [30.55122927, -7.04473422],
                            [30.56138916, -7.08648968],
                            [30.52300602, -7.1415458],
                            [30.53742063, -7.1601961],
                            [30.5216362, -7.1823691],
                            [30.53132585, -7.19036535],
                            [30.5489035, -7.22782837],
                            [30.55290222, -7.25664897],
                            [30.57867648, -7.28193875],
                            [30.58128917, -7.29880825],
                            [30.60045602, -7.318907],
                            [30.61142613, -7.35145895],
                            [30.61691025, -7.38543039],
                            [30.60284004, -7.41611294],
                            [30.5912236, -7.42614967],
                            [30.60138893, -7.44497604],
                            [30.59725183, -7.4630084],
                            [30.60680955, -7.48251885],
                            [30.57492634, -7.49282914],
                            [30.59227907, -7.52273304],
                            [30.61651699, -7.54414098],
                            [30.62005874, -7.56697581],
                            [30.651622, -7.6096235],
                            [30.65788374, -7.63736113],
                            [30.69262209, -7.66573144],
                            [30.71017669, -7.69732453],
                            [30.73504069, -7.70906186],
                            [30.75317425, -7.73678919],
                            [30.76462671, -7.77111293],
                            [30.76397605, -7.78934653],
                            [30.77991207, -7.81429926],
                            [30.78780173, -7.85886729],
                            [30.80653639, -7.88768614],
                            [30.82779606, -7.89310417],
                            [30.84234315, -7.91097251],
                            [30.83872287, -7.96055382],
                            [30.86061679, -7.96317797],
                            [30.90054704, -8.01481293],
                            [30.9095838, -8.0439416],
                            [30.90418074, -8.06897179],
                            [30.91861512, -8.10626964],
                            [30.94304022, -8.10646445],
                            [30.96044861, -8.11822903],
                            [30.9716523, -8.14125009],
                            [30.96742072, -8.15655512],
                            [30.94089814, -8.17521177],
                            [30.96162058, -8.21971778],
                            [30.98629101, -8.24388383],
                            [30.97935475, -8.27159414],
                            [31.00127797, -8.270334],
                            [31.02479254, -8.28453496],
                            [31.03754335, -8.33305806],
                            [31.05687199, -8.3352602],
                            [31.07817935, -8.36546415],
                            [31.12916615, -8.38376937],
                            [31.13089312, -8.40183813],
                            [31.14931816, -8.42360795],
                            [31.1460953, -8.44645976],
                            [31.13058054, -8.46685458],
                            [31.1432147, -8.47985829],
                            [31.13951169, -8.49549314],
                            [31.14509624, -8.52863091],
                            [31.17563296, -8.55987065],
                            [31.17465471, -8.58519785],
                            [31.18409669, -8.60088814],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Tanganyika",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.3",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [31.58055701, -1.43212866],
                            [31.59629578, -1.45809375],
                            [31.58629397, -1.48466673],
                            [31.56491468, -1.49396878],
                            [31.54649653, -1.49317107],
                            [31.51511295, -1.47455081],
                            [31.54444684, -1.46101241],
                            [31.56993229, -1.43205726],
                            [31.58055701, -1.43212866],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Ikimba",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.4",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [33.92948314, -9.7190817],
                            [33.94003829, -9.7138793],
                            [33.94240419, -9.67602477],
                            [33.95605316, -9.64480505],
                            [33.95314152, -9.58971488],
                            [33.94586216, -9.56369196],
                            [33.96879239, -9.52617964],
                            [33.99618123, -9.50185727],
                            [34.02529888, -9.50042127],
                            [34.05951204, -9.53273134],
                            [34.07552675, -9.5298595],
                            [34.17194121, -9.60977979],
                            [34.23542611, -9.67163199],
                            [34.29556165, -9.72515921],
                            [34.32679523, -9.7568465],
                            [34.33561219, -9.78579869],
                            [34.3719143, -9.82796602],
                            [34.3881993, -9.8539512],
                            [34.41621544, -9.88420966],
                            [34.45194525, -9.93529709],
                            [34.45741493, -9.94920931],
                            [34.49113404, -9.97731022],
                            [34.50615631, -10.00270842],
                            [34.50455413, -10.01450041],
                            [34.53944013, -10.06407611],
                            [34.53645036, -10.09471048],
                            [34.51996263, -10.1120774],
                            [34.52912756, -10.13552913],
                            [34.56814364, -10.20263644],
                            [34.56798305, -10.26392175],
                            [34.58108599, -10.28420679],
                            [34.5838041, -10.30776178],
                            [34.55955645, -10.40190394],
                            [34.55949334, -10.43042801],
                            [34.57027781, -10.45051443],
                            [34.58796619, -10.45467535],
                            [34.59328752, -10.48946691],
                            [34.57213821, -10.51209862],
                            [34.56907733, -10.53193003],
                            [34.58562662, -10.57328595],
                            [34.61864144, -10.59034518],
                            [34.62008412, -10.62179839],
                            [34.64461261, -10.64514968],
                            [34.65922974, -10.68750698],
                            [34.64988246, -10.71223234],
                            [34.67897001, -10.74539877],
                            [34.68173568, -10.75904062],
                            [34.67199797, -10.78197292],
                            [34.67942301, -10.81093125],
                            [34.67026156, -10.84095501],
                            [34.6758264, -10.85768536],
                            [34.66983372, -10.89319084],
                            [34.67826669, -10.90327671],
                            [34.67298329, -10.9338633],
                            [34.65075965, -10.95526758],
                            [34.63453243, -10.99060583],
                            [34.62220129, -11.00044904],
                            [34.61157531, -11.02691069],
                            [34.61558506, -11.04617764],
                            [34.63668061, -11.05045398],
                            [34.63826743, -11.11632401],
                            [34.65614036, -11.13328588],
                            [34.6983051, -11.15814069],
                            [34.70290875, -11.17672021],
                            [34.71692333, -11.18916017],
                            [34.75576757, -11.20579387],
                            [34.77556055, -11.25385999],
                            [34.80036033, -11.27705391],
                            [34.7854624, -11.29465134],
                            [34.80036688, -11.31860245],
                            [34.88893499, -11.36826374],
                            [34.92849168, -11.39833108],
                            [34.92189664, -11.41978719],
                            [34.93250164, -11.44312517],
                            [34.93304246, -11.46320431],
                            [34.95506677, -11.48992299],
                            [34.96357564, -11.54869405],
                            [34.96065811, -11.57618618],
                            [34.29172024, -11.53814155],
                            [34.27716167, -11.44396937],
                            [34.23057396, -11.25267506],
                            [34.21892711, -11.17555026],
                            [34.22183949, -10.94405338],
                            [34.19563434, -10.66086717],
                            [34.17525214, -10.5979008],
                            [34.21310522, -10.54351047],
                            [34.21310533, -10.50056387],
                            [34.24222313, -10.4375648],
                            [34.25678206, -10.39173926],
                            [34.18689988, -10.32298813],
                            [34.10828246, -10.23416237],
                            [34.01510627, -10.12237856],
                            [33.95395955, -9.97900918],
                            [33.94231255, -9.95606419],
                            [33.90309502, -9.82795508],
                            [33.90637083, -9.79585279],
                            [33.89799957, -9.76787281],
                            [33.88726246, -9.75746939],
                            [33.90527907, -9.73163888],
                            [33.92948314, -9.7190817],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Nyasa",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.5",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [31.22802618, -5.21786186],
                            [31.2530807, -5.22690613],
                            [31.2559937, -5.24673408],
                            [31.27395313, -5.25552986],
                            [31.29090818, -5.28827304],
                            [31.30940135, -5.24930287],
                            [31.34209132, -5.23585395],
                            [31.35569692, -5.25211765],
                            [31.37663351, -5.25244157],
                            [31.38962708, -5.26229813],
                            [31.4050916, -5.29274493],
                            [31.3997905, -5.30508841],
                            [31.37458889, -5.31715085],
                            [31.32794003, -5.32243433],
                            [31.30054803, -5.30451624],
                            [31.26769541, -5.29525579],
                            [31.2672678, -5.27121632],
                            [31.25126217, -5.24911595],
                            [31.23249974, -5.2408853],
                            [31.22802618, -5.21786186],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Sagara",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.6",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [31.80027135, -7.42259635],
                            [31.83192504, -7.43380209],
                            [31.87315586, -7.46297808],
                            [31.88985299, -7.48769641],
                            [31.93667689, -7.53130933],
                            [32.00067152, -7.58457795],
                            [32.05816755, -7.62165221],
                            [32.14596995, -7.71065432],
                            [32.17841132, -7.74920667],
                            [32.18938837, -7.74485103],
                            [32.2040576, -7.76701954],
                            [32.23769873, -7.78973712],
                            [32.25727416, -7.81320022],
                            [32.26876008, -7.81373517],
                            [32.29673096, -7.8355377],
                            [32.33606319, -7.87886238],
                            [32.37600151, -7.9082169],
                            [32.40019675, -7.9423031],
                            [32.41997324, -7.95674896],
                            [32.42392347, -7.97060437],
                            [32.44990651, -7.97069398],
                            [32.49742203, -8.00247061],
                            [32.54030429, -8.04303504],
                            [32.57117698, -8.06104473],
                            [32.57546443, -8.0892909],
                            [32.60754584, -8.13042143],
                            [32.62265708, -8.13126328],
                            [32.64041137, -8.11497317],
                            [32.65176618, -8.12888624],
                            [32.65501358, -8.15806855],
                            [32.67533062, -8.16752579],
                            [32.68426492, -8.19619749],
                            [32.72368227, -8.23982419],
                            [32.75335695, -8.27758408],
                            [32.76227287, -8.27823315],
                            [32.79914895, -8.3273814],
                            [32.82065738, -8.34889826],
                            [32.89770765, -8.38589718],
                            [32.89937799, -8.41107007],
                            [32.91226055, -8.41640906],
                            [32.92155718, -8.44592674],
                            [32.90790822, -8.46339313],
                            [32.9058275, -8.49192822],
                            [32.88445583, -8.49953729],
                            [32.87328595, -8.49254077],
                            [32.82829936, -8.48853829],
                            [32.79942587, -8.50087988],
                            [32.77591206, -8.49867304],
                            [32.73476328, -8.47557892],
                            [32.69207772, -8.48610392],
                            [32.67349372, -8.47354689],
                            [32.65274096, -8.47080254],
                            [32.61196672, -8.44792862],
                            [32.60184713, -8.42785321],
                            [32.59997746, -8.40030363],
                            [32.56674909, -8.38048806],
                            [32.54237228, -8.35205862],
                            [32.50550507, -8.33782634],
                            [32.46038602, -8.29678318],
                            [32.44501995, -8.26401087],
                            [32.44898263, -8.22842142],
                            [32.4242357, -8.20890779],
                            [32.40705063, -8.20560838],
                            [32.38850584, -8.15478454],
                            [32.39215543, -8.13707431],
                            [32.36724964, -8.11671785],
                            [32.32407198, -8.10228106],
                            [32.31669369, -8.09069544],
                            [32.28395997, -8.07745492],
                            [32.26665792, -8.09623107],
                            [32.26327442, -8.12549575],
                            [32.25328485, -8.1260021],
                            [32.25502664, -8.18628742],
                            [32.21528426, -8.16304039],
                            [32.20252301, -8.18282939],
                            [32.18414845, -8.18623493],
                            [32.11586162, -8.17670279],
                            [32.10734507, -8.20225008],
                            [32.09316206, -8.21449681],
                            [32.07030115, -8.21049202],
                            [32.0457423, -8.19656377],
                            [32.04090348, -8.16870245],
                            [32.02872384, -8.16085148],
                            [32.02998903, -8.1256186],
                            [32.00678306, -8.10681176],
                            [31.99617248, -8.06526263],
                            [31.95872537, -8.02572139],
                            [31.95471533, -8.00711547],
                            [31.92507606, -7.98444232],
                            [31.91464542, -7.96491323],
                            [31.90748927, -7.93033576],
                            [31.89529277, -7.92543397],
                            [31.8745641, -7.90087348],
                            [31.87569385, -7.86374856],
                            [31.86902537, -7.83173242],
                            [31.8593325, -7.83110008],
                            [31.84392501, -7.79764539],
                            [31.81461447, -7.7579742],
                            [31.77018193, -7.70398844],
                            [31.75783517, -7.70724008],
                            [31.71229902, -7.70151914],
                            [31.69593768, -7.71198676],
                            [31.65954003, -7.70229226],
                            [31.64391426, -7.68044155],
                            [31.616688, -7.67004949],
                            [31.60673316, -7.65056301],
                            [31.60218051, -7.61968885],
                            [31.58501553, -7.61175376],
                            [31.56477952, -7.59108484],
                            [31.57273276, -7.57891487],
                            [31.57093145, -7.55326889],
                            [31.62172719, -7.51555931],
                            [31.71486655, -7.50149207],
                            [31.76498157, -7.43935701],
                            [31.79495554, -7.44179753],
                            [31.80027135, -7.42259635],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Rukwa",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.7",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [35.85693161, -3.42383348],
                            [35.87871326, -3.42914945],
                            [35.88969038, -3.46190823],
                            [35.88185451, -3.50626547],
                            [35.88651461, -3.52935886],
                            [35.86366286, -3.58962948],
                            [35.87098094, -3.60519767],
                            [35.87291402, -3.66011781],
                            [35.8556101, -3.69423167],
                            [35.83986151, -3.70877725],
                            [35.80738004, -3.76006954],
                            [35.77517198, -3.78352838],
                            [35.76736683, -3.82243691],
                            [35.77599099, -3.839804],
                            [35.74378228, -3.84885662],
                            [35.72225177, -3.86784497],
                            [35.70442159, -3.84091688],
                            [35.69503552, -3.80094025],
                            [35.67790059, -3.76129438],
                            [35.70951133, -3.71549838],
                            [35.71479141, -3.65904331],
                            [35.72621167, -3.64163476],
                            [35.72985174, -3.61740694],
                            [35.71270129, -3.5758806],
                            [35.74232001, -3.5707551],
                            [35.77748113, -3.55800308],
                            [35.76642211, -3.54171675],
                            [35.77826222, -3.51123625],
                            [35.79141405, -3.5005698],
                            [35.7947279, -3.46432458],
                            [35.8155085, -3.44347494],
                            [35.821791, -3.42776868],
                            [35.84260611, -3.41962214],
                            [35.85693161, -3.42383348],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Manyara",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.8",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [35.30147002, -3.37952769],
                            [35.34532733, -3.43001652],
                            [35.35459714, -3.47049472],
                            [35.33994835, -3.49732945],
                            [35.29263441, -3.50018793],
                            [35.27188181, -3.51037028],
                            [35.27785303, -3.53198954],
                            [35.26823568, -3.54346919],
                            [35.24463623, -3.54471527],
                            [35.22860639, -3.60011449],
                            [35.24030275, -3.61275969],
                            [35.22910449, -3.62623731],
                            [35.18706679, -3.65410662],
                            [35.17193043, -3.66996893],
                            [35.14912196, -3.6677508],
                            [35.11193026, -3.69718991],
                            [35.0733883, -3.71491414],
                            [35.03835996, -3.7168542],
                            [35.00052707, -3.73315763],
                            [34.94747267, -3.74869412],
                            [34.93636797, -3.74573423],
                            [34.90075465, -3.76513179],
                            [34.84404543, -3.78481988],
                            [34.82591183, -3.79916963],
                            [34.82348264, -3.81456149],
                            [34.7936967, -3.82350381],
                            [34.78821382, -3.81042758],
                            [34.79021788, -3.77911299],
                            [34.82071959, -3.74754823],
                            [34.81381959, -3.73704823],
                            [34.78101959, -3.74964823],
                            [34.76401959, -3.76684823],
                            [34.74823501, -3.73693789],
                            [34.76107623, -3.70247257],
                            [34.79855316, -3.6811019],
                            [34.82149029, -3.65597135],
                            [34.83224322, -3.61559201],
                            [34.86695691, -3.62971822],
                            [34.89757764, -3.61637856],
                            [34.92413495, -3.61585268],
                            [34.93912274, -3.60645244],
                            [34.97350263, -3.61539252],
                            [34.99881096, -3.60448036],
                            [35.00873708, -3.58226162],
                            [35.0287208, -3.56543322],
                            [35.03983017, -3.54065078],
                            [35.07591919, -3.54229418],
                            [35.09967951, -3.5211149],
                            [35.10995592, -3.49674027],
                            [35.15800487, -3.47757238],
                            [35.18828984, -3.46079354],
                            [35.19932636, -3.44508548],
                            [35.20256724, -3.41235548],
                            [35.24046513, -3.38777149],
                            [35.26157466, -3.35883712],
                            [35.28317911, -3.35479266],
                            [35.30147002, -3.37952769],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Eyasi",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.9",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [31.9834553, -2.43534555],
                            [31.97506403, -2.46219823],
                            [31.96022323, -2.44178479],
                            [31.9834553, -2.43534555],
                        ],
                    ],
                    [
                        [
                            [32.19765801, -2.50042857],
                            [32.21156336, -2.50089144],
                            [32.22187688, -2.53437638],
                            [32.22994983, -2.54369133],
                            [32.24842638, -2.56285403],
                            [32.25997359, -2.57007183],
                            [32.26467296, -2.59261886],
                            [32.28828244, -2.60454898],
                            [32.34683387, -2.60232617],
                            [32.45040358, -2.61779691],
                            [32.49842731, -2.62752585],
                            [32.51736041, -2.63358129],
                            [32.5063785, -2.65578241],
                            [32.46794739, -2.65920207],
                            [32.41917626, -2.68360875],
                            [32.40444454, -2.73373157],
                            [32.42952896, -2.76826372],
                            [32.44463365, -2.77228163],
                            [32.46903347, -2.77573701],
                            [32.49408078, -2.77840684],
                            [32.5174712, -2.78567295],
                            [32.55038401, -2.7943669],
                            [32.54369697, -2.80929491],
                            [32.54313905, -2.82334674],
                            [32.56060377, -2.85020327],
                            [32.57729386, -2.8655745],
                            [32.58288283, -2.89393334],
                            [32.58329683, -2.90262729],
                            [32.58142641, -2.92893722],
                            [32.61595091, -2.93679272],
                            [32.64845631, -2.958761],
                            [32.6840248, -2.98937345],
                            [32.72003465, -3.00797568],
                            [32.73414136, -3.00740641],
                            [32.7598979, -2.98898111],
                            [32.77255505, -3.00093508],
                            [32.76560143, -3.03234355],
                            [32.78159039, -3.04719542],
                            [32.78388397, -3.06172062],
                            [32.77094773, -3.09618746],
                            [32.77230514, -3.12973493],
                            [32.79263459, -3.16649363],
                            [32.78006009, -3.20070966],
                            [32.78473834, -3.21518019],
                            [32.77497011, -3.24966551],
                            [32.75613652, -3.26991826],
                            [32.73909964, -3.30893196],
                            [32.68464687, -3.3233279],
                            [32.68240713, -3.35991576],
                            [32.69841943, -3.39119911],
                            [32.69367672, -3.44927793],
                            [32.66891112, -3.45099242],
                            [32.59529166, -3.42292813],
                            [32.58097904, -3.40932325],
                            [32.50276072, -3.38732199],
                            [32.52718186, -3.35032863],
                            [32.54290098, -3.30331873],
                            [32.54362556, -3.27893541],
                            [32.5283674, -3.22556951],
                            [32.52881293, -3.20571673],
                            [32.51191167, -3.19618322],
                            [32.49089456, -3.19712079],
                            [32.4561207, -3.22067665],
                            [32.42618472, -3.22720145],
                            [32.39848064, -3.26026723],
                            [32.37718821, -3.25734044],
                            [32.36641529, -3.27565632],
                            [32.35814039, -3.29328127],
                            [32.34659307, -3.32672313],
                            [32.31570032, -3.31256782],
                            [32.30385276, -3.33011141],
                            [32.31425548, -3.36956584],
                            [32.30784289, -3.39345527],
                            [32.30810438, -3.411792],
                            [32.32417538, -3.43783376],
                            [32.32985564, -3.46948383],
                            [32.32658423, -3.52905527],
                            [32.30238856, -3.56575453],
                            [32.31277992, -3.58947466],
                            [32.3160243, -3.62553576],
                            [32.34764323, -3.62029034],
                            [32.39381389, -3.59505643],
                            [32.4141814, -3.6500801],
                            [32.41403657, -3.66508579],
                            [32.39865914, -3.71061763],
                            [32.36927048, -3.71917539],
                            [32.37055525, -3.78572679],
                            [32.36637707, -3.81361118],
                            [32.30577692, -3.78661256],
                            [32.21927337, -3.73064553],
                            [32.1951516, -3.77030025],
                            [32.17422866, -3.76696966],
                            [32.15544606, -3.7433604],
                            [32.12503842, -3.77081265],
                            [32.09926375, -3.78218465],
                            [32.06175723, -3.77832782],
                            [32.00135298, -3.74417692],
                            [31.97759972, -3.74536459],
                            [31.92793586, -3.76833605],
                            [31.88737099, -3.78088981],
                            [31.87567123, -3.79096698],
                            [31.88281441, -3.82075723],
                            [31.86516706, -3.87073032],
                            [31.85944528, -3.88900586],
                            [31.83604576, -3.92683794],
                            [31.83262977, -3.94451568],
                            [31.79864068, -3.95613005],
                            [31.76892158, -3.95553225],
                            [31.75448902, -3.96654881],
                            [31.72417212, -3.97320999],
                            [31.70820237, -3.98371416],
                            [31.69679202, -3.99853575],
                            [31.69405578, -4.02914145],
                            [31.68403063, -4.04987976],
                            [31.68292799, -4.05310564],
                            [31.68288283, -4.05323726],
                            [31.6662612, -4.07011738],
                            [31.66017476, -4.07283569],
                            [31.63870978, -4.14040998],
                            [31.66370266, -4.15313839],
                            [31.67301563, -4.18291456],
                            [31.67933577, -4.20302274],
                            [31.68213326, -4.22154265],
                            [31.67038097, -4.23777919],
                            [31.67924773, -4.2550208],
                            [31.66909294, -4.27572983],
                            [31.65569692, -4.27762928],
                            [31.6578815, -4.30028241],
                            [31.67214161, -4.32231883],
                            [31.65616771, -4.37109818],
                            [31.63596988, -4.40129878],
                            [31.61380638, -4.42429352],
                            [31.59822833, -4.42777312],
                            [31.56836103, -4.42548294],
                            [31.49943207, -4.41131111],
                            [31.49558289, -4.42916624],
                            [31.46811305, -4.45914441],
                            [31.45744163, -4.45744871],
                            [31.45582664, -4.45850746],
                            [31.4368016, -4.46833187],
                            [31.4182444, -4.43215311],
                            [31.41995977, -4.38911287],
                            [31.4160612, -4.3630704],
                            [31.39843965, -4.33671605],
                            [31.39532079, -4.31737913],
                            [31.36927832, -4.30240861],
                            [31.36397626, -4.29196043],
                            [31.36698686, -4.23754113],
                            [31.35037299, -4.23082228],
                            [31.34744112, -4.17413964],
                            [31.33131589, -4.15056259],
                            [31.32911699, -4.13236062],
                            [31.34560871, -4.0921697],
                            [31.34573087, -4.07054722],
                            [31.35745832, -4.04000701],
                            [31.37859215, -4.02241584],
                            [31.37431651, -4.01178785],
                            [31.37895863, -3.97440662],
                            [31.35330485, -3.89695664],
                            [31.35342701, -3.86226295],
                            [31.34231037, -3.83098977],
                            [31.31580146, -3.79018804],
                            [31.3156793, -3.77088662],
                            [31.29503411, -3.73277243],
                            [31.24861299, -3.70846242],
                            [31.22833428, -3.68537402],
                            [31.22357001, -3.64628254],
                            [31.2117204, -3.62551519],
                            [31.21624036, -3.57542924],
                            [31.21007123, -3.55115995],
                            [31.21313441, -3.52786499],
                            [31.19659438, -3.50706164],
                            [31.21072111, -3.49226881],
                            [31.20879047, -3.46342988],
                            [31.1942255, -3.44977681],
                            [31.19157375, -3.42204188],
                            [31.17087487, -3.4130775],
                            [31.1509146, -3.37661158],
                            [31.15283067, -3.36009912],
                            [31.15892985, -3.35569496],
                            [31.21993858, -3.29834675],
                            [31.25082882, -3.2837597],
                            [31.28152679, -3.29185144],
                            [31.31251349, -3.3133152],
                            [31.3527237, -3.31936928],
                            [31.39758842, -3.31692957],
                            [31.4339426, -3.29684515],
                            [31.45905535, -3.31069786],
                            [31.47049274, -3.29813224],
                            [31.49258784, -3.28225809],
                            [31.5554409, -3.24300174],
                            [31.56037476, -3.20631958],
                            [31.55982181, -3.17403242],
                            [31.55466312, -3.13498419],
                            [31.54256697, -3.1062888],
                            [31.51215993, -3.06115502],
                            [31.5063361, -3.04227724],
                            [31.46276694, -2.95868667],
                            [31.47520068, -2.90398493],
                            [31.50183809, -2.88278952],
                            [31.52862423, -2.90881145],
                            [31.54436611, -2.9159175],
                            [31.55325498, -2.93385131],
                            [31.5741786, -2.94122443],
                            [31.57374981, -2.91152679],
                            [31.59352498, -2.86005028],
                            [31.63909267, -2.84718104],
                            [31.67639631, -2.84352534],
                            [31.70179129, -2.82167258],
                            [31.72057381, -2.8154756],
                            [31.72122212, -2.7890412],
                            [31.68431654, -2.76162695],
                            [31.67649938, -2.74417139],
                            [31.65767243, -2.75864458],
                            [31.62046389, -2.75657722],
                            [31.61628886, -2.76596205],
                            [31.58605461, -2.76527286],
                            [31.55455709, -2.74991519],
                            [31.55115746, -2.73940954],
                            [31.55402954, -2.72328457],
                            [31.53532163, -2.71185385],
                            [31.51465917, -2.72092976],
                            [31.50423669, -2.72001273],
                            [31.48388224, -2.69888395],
                            [31.46853861, -2.7214188],
                            [31.44936774, -2.71114844],
                            [31.44677032, -2.68364672],
                            [31.42899085, -2.6807292],
                            [31.40769774, -2.66541455],
                            [31.40320615, -2.62338653],
                            [31.42125761, -2.57953951],
                            [31.45222664, -2.54875947],
                            [31.49419505, -2.5445433],
                            [31.51421929, -2.52761841],
                            [31.5304568, -2.54030221],
                            [31.5544131, -2.52360352],
                            [31.5845889, -2.50967498],
                            [31.59766757, -2.51007835],
                            [31.6001094, -2.4866032],
                            [31.60889386, -2.45774848],
                            [31.62722378, -2.44276262],
                            [31.61329057, -2.41890219],
                            [31.61363411, -2.40675164],
                            [31.65595208, -2.38378559],
                            [31.63968616, -2.37692578],
                            [31.6489036, -2.34796262],
                            [31.66352737, -2.33125552],
                            [31.68651081, -2.30686059],
                            [31.70510482, -2.31176691],
                            [31.74824048, -2.30072754],
                            [31.75477204, -2.31305052],
                            [31.7439101, -2.33607683],
                            [31.7714649, -2.35556454],
                            [31.76163345, -2.37002621],
                            [31.75559436, -2.40584225],
                            [31.7906405, -2.42492311],
                            [31.79934014, -2.47154513],
                            [31.79482108, -2.49664403],
                            [31.7623958, -2.49841179],
                            [31.73807893, -2.50700142],
                            [31.71771496, -2.50647875],
                            [31.71406779, -2.53059806],
                            [31.72658969, -2.55728404],
                            [31.74632172, -2.55725995],
                            [31.74313781, -2.57579539],
                            [31.75124528, -2.62617753],
                            [31.80813114, -2.61073495],
                            [31.82819146, -2.59954786],
                            [31.85503243, -2.60403477],
                            [31.85702438, -2.62630835],
                            [31.82756771, -2.61844117],
                            [31.81122973, -2.64479925],
                            [31.763073, -2.65681097],
                            [31.78246656, -2.68206768],
                            [31.76262243, -2.70365612],
                            [31.78954305, -2.71799053],
                            [31.78174886, -2.73353642],
                            [31.78931341, -2.76437561],
                            [31.77561667, -2.82996431],
                            [31.7816708, -2.83846304],
                            [31.80830155, -2.83499489],
                            [31.81396932, -2.80777828],
                            [31.84374461, -2.79928029],
                            [31.84684959, -2.78893035],
                            [31.82428672, -2.7446326],
                            [31.83091068, -2.72993568],
                            [31.81816543, -2.71393473],
                            [31.82180273, -2.69909286],
                            [31.85365196, -2.68159533],
                            [31.87456043, -2.69852468],
                            [31.86627186, -2.72402493],
                            [31.87438043, -2.73034968],
                            [31.90059189, -2.693425],
                            [31.90518035, -2.65952767],
                            [31.92907366, -2.64866815],
                            [31.94532216, -2.65611626],
                            [31.94913568, -2.68803232],
                            [31.96032509, -2.70724082],
                            [31.97599027, -2.71140576],
                            [31.98139849, -2.74746054],
                            [31.97337941, -2.77064749],
                            [31.9930852, -2.78339098],
                            [32.038278, -2.77089614],
                            [32.0262183, -2.74982275],
                            [32.00905617, -2.74306861],
                            [32.00197457, -2.71221389],
                            [32.01086166, -2.69743687],
                            [31.98954078, -2.68253295],
                            [31.98519381, -2.6566581],
                            [31.95931895, -2.62415928],
                            [31.93096011, -2.6036664],
                            [31.93737708, -2.59103947],
                            [31.97732785, -2.59890542],
                            [32.0036167, -2.58441551],
                            [31.99388776, -2.5678556],
                            [32.01106866, -2.55150269],
                            [31.99622296, -2.52556315],
                            [32.00332561, -2.51811119],
                            [31.98271629, -2.49587176],
                            [32.01131534, -2.48040382],
                            [32.02368469, -2.49740492],
                            [32.05213209, -2.49063173],
                            [32.07069702, -2.5172711],
                            [32.09964455, -2.51533105],
                            [32.11465984, -2.54401056],
                            [32.12758711, -2.52678443],
                            [32.14301032, -2.54327733],
                            [32.15253227, -2.52112845],
                            [32.17799312, -2.51802347],
                            [32.19765801, -2.50042857],
                        ],
                    ],
                    [
                        [
                            [31.85180567, -2.25010205],
                            [31.84520181, -2.27067562],
                            [31.85637758, -2.30801284],
                            [31.8648864, -2.31766464],
                            [31.89739772, -2.32477649],
                            [31.89968367, -2.34293712],
                            [31.89066686, -2.36605064],
                            [31.91403438, -2.37633742],
                            [31.9130184, -2.39691099],
                            [31.92203521, -2.41405563],
                            [31.90616054, -2.43310524],
                            [31.88545997, -2.43666116],
                            [31.85961116, -2.4585353],
                            [31.84194726, -2.45657265],
                            [31.83507797, -2.42222618],
                            [31.84881656, -2.39376767],
                            [31.84390992, -2.36138385],
                            [31.82084872, -2.34519195],
                            [31.83458731, -2.30937348],
                            [31.82673668, -2.3064295],
                            [31.79680619, -2.3240934],
                            [31.77914229, -2.29514423],
                            [31.79189955, -2.29220025],
                            [31.82255975, -2.23786669],
                            [31.85180567, -2.25010205],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Geita", Region_Cod: 25, zoneId: 3 },
            drilldown: 25,
            region_nam: "Geita",
            value: 6.1,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.10",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [31.76133771, -2.07425942],
                            [31.75409779, -2.10601063],
                            [31.76848631, -2.12545124],
                            [31.79522579, -2.13730228],
                            [31.78377414, -2.15891678],
                            [31.75307079, -2.14748779],
                            [31.7441484, -2.12480622],
                            [31.76133771, -2.07425942],
                        ],
                    ],
                    [
                        [
                            [31.84486298, -1.76782044],
                            [31.84020373, -1.78585961],
                            [31.82200473, -1.79413391],
                            [31.82556639, -1.762017],
                            [31.84486298, -1.76782044],
                        ],
                    ],
                    [
                        [
                            [31.88913102, -1.60375857],
                            [31.89685936, -1.60946509],
                            [31.89306696, -1.63743997],
                            [31.90439626, -1.64190117],
                            [31.90591776, -1.66566172],
                            [31.87101306, -1.70704268],
                            [31.85218858, -1.70174504],
                            [31.86553499, -1.65410224],
                            [31.87346947, -1.64305865],
                            [31.85677776, -1.62421985],
                            [31.88913102, -1.60375857],
                        ],
                    ],
                    [
                        [
                            [31.66352737, -2.33125552],
                            [31.6489036, -2.34796262],
                            [31.63968616, -2.37692578],
                            [31.65595208, -2.38378559],
                            [31.61363411, -2.40675164],
                            [31.61329057, -2.41890219],
                            [31.62722378, -2.44276262],
                            [31.60889386, -2.45774848],
                            [31.6001094, -2.4866032],
                            [31.59766757, -2.51007835],
                            [31.5845889, -2.50967498],
                            [31.5544131, -2.52360352],
                            [31.5304568, -2.54030221],
                            [31.51421929, -2.52761841],
                            [31.49419505, -2.5445433],
                            [31.45222664, -2.54875947],
                            [31.42125761, -2.57953951],
                            [31.40320615, -2.62338653],
                            [31.40769774, -2.66541455],
                            [31.42899085, -2.6807292],
                            [31.44677032, -2.68364672],
                            [31.44936774, -2.71114844],
                            [31.46853861, -2.7214188],
                            [31.48388224, -2.69888395],
                            [31.50423669, -2.72001273],
                            [31.51465917, -2.72092976],
                            [31.53532163, -2.71185385],
                            [31.55402954, -2.72328457],
                            [31.55115746, -2.73940954],
                            [31.55455709, -2.74991519],
                            [31.58605461, -2.76527286],
                            [31.61628886, -2.76596205],
                            [31.62046389, -2.75657722],
                            [31.65767243, -2.75864458],
                            [31.67649938, -2.74417139],
                            [31.68431654, -2.76162695],
                            [31.72122212, -2.7890412],
                            [31.72057381, -2.8154756],
                            [31.70179129, -2.82167258],
                            [31.67639631, -2.84352534],
                            [31.63909267, -2.84718104],
                            [31.59352498, -2.86005028],
                            [31.57374981, -2.91152679],
                            [31.5741786, -2.94122443],
                            [31.55325498, -2.93385131],
                            [31.54436611, -2.9159175],
                            [31.52862423, -2.90881145],
                            [31.50183809, -2.88278952],
                            [31.47520068, -2.90398493],
                            [31.46276694, -2.95868667],
                            [31.5063361, -3.04227724],
                            [31.51215993, -3.06115502],
                            [31.54256697, -3.1062888],
                            [31.55466312, -3.13498419],
                            [31.55982181, -3.17403242],
                            [31.56037476, -3.20631958],
                            [31.5554409, -3.24300174],
                            [31.49258784, -3.28225809],
                            [31.47049274, -3.29813224],
                            [31.45905535, -3.31069786],
                            [31.4339426, -3.29684515],
                            [31.39758842, -3.31692957],
                            [31.3527237, -3.31936928],
                            [31.31251349, -3.3133152],
                            [31.28152679, -3.29185144],
                            [31.25082882, -3.2837597],
                            [31.21993858, -3.29834675],
                            [31.15892985, -3.35569496],
                            [31.15254785, -3.35964378],
                            [31.13587748, -3.33916368],
                            [31.11671009, -3.27572944],
                            [31.11979298, -3.2289557],
                            [31.10897748, -3.21126368],
                            [31.08017748, -3.18236368],
                            [31.03662963, -3.18489714],
                            [31.03057748, -3.20416368],
                            [30.99267748, -3.19306368],
                            [30.98808204, -3.17940927],
                            [31.00907748, -3.15646368],
                            [31.01279951, -3.13593152],
                            [31.03994777, -3.08085824],
                            [31.06999579, -3.03280576],
                            [31.06837613, -3.01255801],
                            [31.08073964, -2.96427293],
                            [31.06731959, -2.93464214],
                            [31.04898433, -2.92130496],
                            [31.04891372, -2.89301634],
                            [31.02785918, -2.8670889],
                            [31.02681015, -2.85318274],
                            [31.00813897, -2.82809888],
                            [30.98925241, -2.83170189],
                            [30.92053115, -2.88654454],
                            [30.86567748, -2.94666368],
                            [30.85098654, -2.97529493],
                            [30.8505877, -2.97619233],
                            [30.83831473, -2.97452316],
                            [30.77269758, -2.99385259],
                            [30.74398158, -2.99725086],
                            [30.72552579, -2.98989362],
                            [30.70424537, -2.97030624],
                            [30.67111478, -2.99023634],
                            [30.64858742, -2.97289819],
                            [30.6461744, -2.95230988],
                            [30.62003068, -2.92471876],
                            [30.59839056, -2.9190008],
                            [30.57485322, -2.89134713],
                            [30.56741478, -2.8920858],
                            [30.53820488, -2.93143827],
                            [30.50513863, -2.95119104],
                            [30.48175424, -2.94331741],
                            [30.45609932, -2.9071722],
                            [30.43564877, -2.89999131],
                            [30.43331167, -2.87236322],
                            [30.41067374, -2.8675557],
                            [30.42346597, -2.81574669],
                            [30.43755613, -2.79849075],
                            [30.44485186, -2.76839123],
                            [30.43854641, -2.74464734],
                            [30.48176307, -2.72049592],
                            [30.50664846, -2.69138419],
                            [30.5199623, -2.68534001],
                            [30.52773128, -2.66348807],
                            [30.5113286, -2.65474026],
                            [30.45868392, -2.68995938],
                            [30.43123682, -2.67827393],
                            [30.41841929, -2.66335093],
                            [30.44482238, -2.61414395],
                            [30.48313255, -2.55590299],
                            [30.48171378, -2.52729284],
                            [30.49507813, -2.52021841],
                            [30.51099326, -2.4600797],
                            [30.53953012, -2.40680604],
                            [30.56700223, -2.41179],
                            [30.60738107, -2.3921384],
                            [30.66043531, -2.4048152],
                            [30.68176623, -2.38601435],
                            [30.6952187, -2.36058352],
                            [30.7156573, -2.35313924],
                            [30.76609256, -2.38746673],
                            [30.77836086, -2.38478797],
                            [30.79548677, -2.36284221],
                            [30.81092466, -2.36033717],
                            [30.82692703, -2.32956339],
                            [30.82323417, -2.31041526],
                            [30.82528576, -2.25721082],
                            [30.84746163, -2.25611163],
                            [30.84184193, -2.23476995],
                            [30.84580015, -2.20053129],
                            [30.86250802, -2.17502981],
                            [30.86989466, -2.12174049],
                            [30.89557202, -2.07706892],
                            [30.87059815, -2.03556305],
                            [30.85476964, -2.02852816],
                            [30.83348908, -2.00654411],
                            [30.80693236, -2.01973454],
                            [30.8041184, -1.98209786],
                            [30.82135389, -1.96556586],
                            [30.80974632, -1.93390884],
                            [30.78864164, -1.92898442],
                            [30.81607772, -1.88642331],
                            [30.81642947, -1.87165004],
                            [30.83665479, -1.85353519],
                            [30.82346436, -1.77439264],
                            [30.82874053, -1.75258447],
                            [30.81062568, -1.72901758],
                            [30.8257507, -1.7193446],
                            [30.82188151, -1.68047681],
                            [30.83806177, -1.66253784],
                            [30.83683066, -1.64653346],
                            [30.8780721, -1.65183249],
                            [30.8915957, -1.71113913],
                            [30.9045749, -1.69976381],
                            [30.89340208, -1.67424857],
                            [30.91305357, -1.67004886],
                            [30.89451144, -1.64548449],
                            [30.89258618, -1.60855529],
                            [30.85884947, -1.63862496],
                            [30.83735828, -1.6106555],
                            [30.81818819, -1.61434882],
                            [30.80464602, -1.59781682],
                            [30.77386836, -1.57917435],
                            [30.772989, -1.5362615],
                            [30.75909509, -1.5184984],
                            [30.73218662, -1.50952891],
                            [30.72515173, -1.49756959],
                            [30.73939739, -1.47453032],
                            [30.74238722, -1.45500849],
                            [30.73693517, -1.42704479],
                            [30.7418596, -1.39767411],
                            [30.67467637, -1.39556364],
                            [30.67168654, -1.38518717],
                            [30.62050769, -1.37604181],
                            [30.61048297, -1.35089207],
                            [30.57548438, -1.33488769],
                            [30.55402795, -1.30498939],
                            [30.55824889, -1.28828152],
                            [30.55191748, -1.24888612],
                            [30.52184332, -1.20474216],
                            [30.50865289, -1.16851246],
                            [30.50003515, -1.16182931],
                            [30.50100675, -1.06455122],
                            [30.52412966, -1.07424489],
                            [30.58269514, -1.05859226],
                            [30.62912544, -1.06650651],
                            [30.65533041, -1.06070272],
                            [30.67713858, -1.02746285],
                            [30.70017786, -1.01057911],
                            [30.73904564, -1.00213724],
                            [30.76612998, -0.98314303],
                            [30.79898649, -0.99756948],
                            [30.82319282, -0.99627296],
                            [31.05733998, -0.99719862],
                            [31.13136929, -0.99532977],
                            [31.26192005, -0.99543149],
                            [31.34556836, -0.99743209],
                            [31.61829079, -0.99865157],
                            [31.74898241, -0.99644657],
                            [31.79137709, -0.99760169],
                            [31.77157863, -1.0248825],
                            [31.77035536, -1.04714256],
                            [31.77784256, -1.06893912],
                            [31.80925598, -1.06424598],
                            [31.82104225, -1.04606816],
                            [31.82891045, -1.00405954],
                            [31.84315675, -1.00700096],
                            [31.84191229, -1.03613771],
                            [31.87657623, -1.04032892],
                            [31.86534939, -1.06302497],
                            [31.84728203, -1.07724805],
                            [31.86623237, -1.10210109],
                            [31.85716254, -1.12209243],
                            [31.85982133, -1.14418593],
                            [31.87966768, -1.17299255],
                            [31.86698161, -1.19376759],
                            [31.86932678, -1.22008261],
                            [31.86291973, -1.24842672],
                            [31.86852605, -1.25633466],
                            [31.8463675, -1.31981591],
                            [31.81351516, -1.35268668],
                            [31.81477509, -1.39570832],
                            [31.80293895, -1.43063622],
                            [31.80607194, -1.44720138],
                            [31.79872321, -1.48152903],
                            [31.78301558, -1.4803158],
                            [31.76492683, -1.45651487],
                            [31.7525066, -1.46283211],
                            [31.74844503, -1.51134788],
                            [31.75220345, -1.53353094],
                            [31.75108253, -1.594196],
                            [31.75549099, -1.6120022],
                            [31.74363473, -1.64221383],
                            [31.72806869, -1.65278913],
                            [31.72494775, -1.73142857],
                            [31.70459427, -1.77193434],
                            [31.69919783, -1.79798995],
                            [31.70045722, -1.84730329],
                            [31.68815812, -1.86797457],
                            [31.68337973, -1.90240986],
                            [31.62631621, -1.96196578],
                            [31.61268309, -2.00026259],
                            [31.61017049, -2.02681625],
                            [31.61991382, -2.04803893],
                            [31.60444132, -2.08625266],
                            [31.62295016, -2.07895264],
                            [31.63464311, -2.0390276],
                            [31.6664434, -2.04081231],
                            [31.69039277, -2.01041921],
                            [31.70743088, -2.00399318],
                            [31.68987033, -2.06120787],
                            [31.7003427, -2.08576128],
                            [31.69287983, -2.11595708],
                            [31.69883302, -2.14420416],
                            [31.68562126, -2.16530741],
                            [31.66793251, -2.24302292],
                            [31.65870476, -2.25003052],
                            [31.66305426, -2.28700358],
                            [31.6735425, -2.30734117],
                            [31.66352737, -2.33125552],
                        ],
                        [
                            [30.81416601, -1.4105008],
                            [30.79899702, -1.4228998],
                            [30.78211327, -1.41577697],
                            [30.75718337, -1.45534825],
                            [30.82458644, -1.43820069],
                            [30.83692464, -1.41705273],
                            [30.81416601, -1.4105008],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Kagera", Region_Cod: 18, zoneId: 3 },
            drilldown: 18,
            region_nam: "Kagera",
            value: 5.7,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.11",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [36.24335793, -4.41222158],
                            [36.29618914, -4.42285428],
                            [36.34486737, -4.43502187],
                            [36.32121985, -4.47695227],
                            [36.32675814, -4.49994133],
                            [36.30781865, -4.5202099],
                            [36.29620829, -4.57076635],
                            [36.2873558, -4.58730652],
                            [36.28998308, -4.64254397],
                            [36.296273, -4.66688831],
                            [36.29266212, -4.69705665],
                            [36.30270318, -4.7392571],
                            [36.30802033, -4.81248873],
                            [36.33657158, -4.8454127],
                            [36.33721463, -4.88746793],
                            [36.32795476, -4.91267535],
                            [36.32132511, -4.99012963],
                            [36.31298691, -5.00836945],
                            [36.31663487, -5.05422956],
                            [36.32286126, -5.0788709],
                            [36.29649311, -5.15780564],
                            [36.26955207, -5.19699896],
                            [36.29992805, -5.20531686],
                            [36.2777946, -5.22148356],
                            [36.2749942, -5.24231727],
                            [36.29317878, -5.30013955],
                            [36.29813554, -5.41563194],
                            [36.24640884, -5.40868506],
                            [36.25781237, -5.46216371],
                            [36.26954584, -5.48258863],
                            [36.30412517, -5.51631862],
                            [36.34050283, -5.54257811],
                            [36.41002226, -5.59917355],
                            [36.42027062, -5.62188185],
                            [36.44029, -5.63631294],
                            [36.44143324, -5.655924],
                            [36.46324285, -5.67148973],
                            [36.51385833, -5.69097213],
                            [36.53359131, -5.72631228],
                            [36.57781146, -5.74359776],
                            [36.5998723, -5.79735504],
                            [36.64372036, -5.82623746],
                            [36.66612928, -5.8483337],
                            [36.69708485, -5.89502762],
                            [36.71478771, -5.94359301],
                            [36.73653009, -5.93849473],
                            [36.78888682, -5.93606859],
                            [36.82902364, -5.93921875],
                            [36.85403825, -5.95130914],
                            [36.88387193, -5.94558773],
                            [36.92373895, -5.92682678],
                            [36.96398607, -5.92518519],
                            [36.95764405, -5.93829875],
                            [36.94075493, -5.97346515],
                            [36.89748563, -5.99852774],
                            [36.86802579, -6.00768262],
                            [36.81622585, -6.05446279],
                            [36.82199186, -6.07739808],
                            [36.8402675, -6.18976542],
                            [36.84995059, -6.27912169],
                            [36.84938067, -6.35662899],
                            [36.80461492, -6.39942233],
                            [36.77638393, -6.40899075],
                            [36.74952525, -6.43233701],
                            [36.72528366, -6.45853382],
                            [36.72225427, -6.47671014],
                            [36.72171967, -6.49185707],
                            [36.74577657, -6.50468742],
                            [36.74755856, -6.55012822],
                            [36.72777845, -6.57062113],
                            [36.7163737, -6.61285435],
                            [36.70051932, -6.63834064],
                            [36.67111643, -6.66881273],
                            [36.66327569, -6.70587817],
                            [36.6308434, -6.73848866],
                            [36.64509939, -6.77466309],
                            [36.63614237, -6.80235023],
                            [36.64279145, -6.83177704],
                            [36.63605089, -6.84798188],
                            [36.64485959, -6.88635429],
                            [36.63720872, -6.9251664],
                            [36.64730682, -6.9484802],
                            [36.6448905, -6.98085182],
                            [36.65079038, -6.99180428],
                            [36.64786591, -7.02555338],
                            [36.5951652, -7.03949953],
                            [36.56556931, -7.05330805],
                            [36.53836927, -7.10516422],
                            [36.53402904, -7.13008018],
                            [36.51540868, -7.15538186],
                            [36.50444555, -7.19507165],
                            [36.5075232, -7.22726136],
                            [36.52936706, -7.28351362],
                            [36.53294926, -7.32311286],
                            [36.53310114, -7.33286337],
                            [36.51203357, -7.35575245],
                            [36.48142474, -7.3780201],
                            [36.46846134, -7.34982361],
                            [36.43202826, -7.33875657],
                            [36.39134419, -7.31534913],
                            [36.36361376, -7.32899252],
                            [36.33000123, -7.33520339],
                            [36.31790307, -7.32401849],
                            [36.31414522, -7.3041416],
                            [36.32212956, -7.28468417],
                            [36.30136285, -7.24342544],
                            [36.27847972, -7.23154955],
                            [36.24965369, -7.17711696],
                            [36.23091503, -7.16933225],
                            [36.20048528, -7.1693364],
                            [36.19224458, -7.14777455],
                            [36.16221959, -7.15212714],
                            [36.13648079, -7.13362747],
                            [36.1134531, -7.12528283],
                            [36.07418134, -7.13249735],
                            [36.05002541, -7.11837745],
                            [36.00974426, -7.12550954],
                            [35.97920565, -7.13554931],
                            [35.96103255, -7.1194166],
                            [35.92515235, -7.11386528],
                            [35.90987057, -7.10382605],
                            [35.89426181, -7.10844719],
                            [35.8796208, -7.09350223],
                            [35.84866492, -7.08414357],
                            [35.81547166, -7.09288545],
                            [35.81796599, -7.08939378],
                            [35.80399367, -7.09450992],
                            [35.77047163, -7.09081058],
                            [35.72054234, -7.07778508],
                            [35.70864278, -7.06478495],
                            [35.68303728, -7.0596549],
                            [35.65130203, -7.03093121],
                            [35.6022867, -7.02291138],
                            [35.5800376, -7.00267391],
                            [35.53999452, -7.01245604],
                            [35.51623664, -6.99096696],
                            [35.49986444, -6.95374668],
                            [35.48729473, -6.94507411],
                            [35.46678045, -6.94955112],
                            [35.43885572, -6.93036244],
                            [35.41739924, -6.92651568],
                            [35.36283205, -6.93710334],
                            [35.34731169, -6.93373724],
                            [35.29593151, -6.9408751],
                            [35.27299696, -6.97154341],
                            [35.24341645, -6.99428176],
                            [35.20568397, -6.97942226],
                            [35.17803125, -6.98036364],
                            [35.15519394, -6.96713727],
                            [35.13765626, -6.95640546],
                            [35.1335778, -6.95390389],
                            [35.11520795, -6.938785],
                            [35.08279273, -6.92395038],
                            [35.08305825, -6.92210864],
                            [35.08205557, -6.86560092],
                            [35.07247209, -6.76730447],
                            [35.05715183, -6.73285716],
                            [35.06147205, -6.71800046],
                            [35.09514404, -6.74372613],
                            [35.12797163, -6.73291648],
                            [35.1453521, -6.73314266],
                            [35.20016111, -6.72238294],
                            [35.18513197, -6.66621345],
                            [35.18568876, -6.64862373],
                            [35.19859996, -6.62312261],
                            [35.19819396, -6.58791563],
                            [35.20756267, -6.55213031],
                            [35.22461733, -6.52815255],
                            [35.21821063, -6.50019881],
                            [35.20174868, -6.46591231],
                            [35.20464212, -6.45743859],
                            [35.24141318, -6.43896604],
                            [35.24342759, -6.42306267],
                            [35.24272461, -6.37964282],
                            [35.21850548, -6.34511753],
                            [35.21018107, -6.29075981],
                            [35.19758376, -6.25352297],
                            [35.17443914, -6.24261946],
                            [35.16412356, -6.20848371],
                            [35.16725172, -6.18335852],
                            [35.19276634, -6.13988953],
                            [35.20480228, -6.10817074],
                            [35.21165007, -5.9581367],
                            [35.22211551, -5.94150457],
                            [35.22979473, -5.9074476],
                            [35.240667, -5.86455429],
                            [35.25308949, -5.84734054],
                            [35.25945157, -5.82371473],
                            [35.26670589, -5.78935811],
                            [35.27755829, -5.77224164],
                            [35.29254377, -5.74027186],
                            [35.33307239, -5.71484683],
                            [35.35013324, -5.66976718],
                            [35.31626542, -5.62941304],
                            [35.2903402, -5.60768638],
                            [35.26950016, -5.60286022],
                            [35.24926292, -5.578957],
                            [35.24341647, -5.54337174],
                            [35.20732241, -5.51964925],
                            [35.19912511, -5.50737911],
                            [35.19543713, -5.47418889],
                            [35.16339844, -5.43877999],
                            [35.124391, -5.37373021],
                            [35.12669999, -5.34060957],
                            [35.1144167, -5.32705901],
                            [35.1093724, -5.30553146],
                            [35.09003195, -5.26694437],
                            [35.09550987, -5.23401124],
                            [35.09268486, -5.1774851],
                            [35.06665926, -5.15079013],
                            [35.07394279, -5.12596978],
                            [35.11848803, -5.10323182],
                            [35.1440658, -5.06160054],
                            [35.1677179, -5.03650614],
                            [35.18867658, -5.03976145],
                            [35.22724913, -5.00214903],
                            [35.24665306, -4.9923012],
                            [35.28044723, -4.97805492],
                            [35.29427053, -4.96177909],
                            [35.3198734, -4.92424104],
                            [35.34065191, -4.87699079],
                            [35.34036729, -4.876764],
                            [35.34869385, -4.85760498],
                            [35.37609863, -4.86431885],
                            [35.40252685, -4.85968018],
                            [35.41790771, -4.86621094],
                            [35.43602682, -4.85866722],
                            [35.48723532, -4.83950219],
                            [35.50592041, -4.81720708],
                            [35.51061568, -4.79868868],
                            [35.53797371, -4.73822994],
                            [35.59444952, -4.70097397],
                            [35.60663179, -4.67617904],
                            [35.62135039, -4.64505972],
                            [35.64405909, -4.62224589],
                            [35.66308814, -4.57356937],
                            [35.65415185, -4.55685325],
                            [35.65846229, -4.53551128],
                            [35.65225946, -4.51164612],
                            [35.66161628, -4.48736043],
                            [35.67360143, -4.48326024],
                            [35.71460324, -4.45151012],
                            [35.7449215, -4.43250756],
                            [35.76356506, -4.40447213],
                            [35.81584619, -4.38916633],
                            [35.81132547, -4.36067533],
                            [35.81773858, -4.31273474],
                            [35.84160374, -4.33218432],
                            [35.86496049, -4.34246908],
                            [35.90216027, -4.34784912],
                            [35.9758584, -4.37875818],
                            [35.99846197, -4.39326652],
                            [36.04897896, -4.39527572],
                            [36.10147789, -4.40158889],
                            [36.14068596, -4.39992753],
                            [36.24335793, -4.41222158],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Dodoma", Region_Cod: 1, zoneId: 4 },
            drilldown: 1,
            region_nam: "Dodoma",
            value: 3.9,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.12",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [39.44194507, -7.1751823],
                            [39.42115794, -7.14975641],
                            [39.40385764, -7.14628259],
                            [39.41680988, -7.1235075],
                            [39.39548413, -7.07899588],
                            [39.40694927, -7.06851803],
                            [39.4052044, -7.00923443],
                            [39.38412085, -6.99363226],
                            [39.38541474, -6.9680978],
                            [39.36226988, -6.96893985],
                            [39.34371593, -6.95381975],
                            [39.32173098, -6.94711158],
                            [39.27569359, -6.95236932],
                            [39.267926, -6.94190629],
                            [39.24296272, -6.94916869],
                            [39.21936159, -6.99211835],
                            [39.1923356, -6.98056519],
                            [39.17353874, -7.00894516],
                            [39.15075193, -7.01757383],
                            [39.13286698, -7.07115789],
                            [39.11269573, -7.0720681],
                            [39.06904995, -7.05545752],
                            [39.06864173, -7.03236123],
                            [39.0293369, -7.0443969],
                            [39.02154759, -7.02516742],
                            [39.05027772, -7.02615275],
                            [39.06395727, -7.01230515],
                            [39.05831898, -6.99770665],
                            [39.0736111, -6.9775327],
                            [39.07815656, -6.94642945],
                            [39.10249319, -6.91738849],
                            [39.11783326, -6.87860502],
                            [39.07004714, -6.86427047],
                            [39.05410516, -6.84184006],
                            [39.05895603, -6.8272229],
                            [39.05326067, -6.78796462],
                            [39.00776557, -6.78620348],
                            [39.02927524, -6.76363479],
                            [39.02925951, -6.75172887],
                            [39.0604146, -6.70809768],
                            [39.06040987, -6.66293329],
                            [39.06750801, -6.65580522],
                            [39.07727043, -6.60780198],
                            [39.08509016, -6.59348898],
                            [39.1047196, -6.58815265],
                            [39.12055109, -6.56427855],
                            [39.15237153, -6.57874541],
                            [39.17759327, -6.62414631],
                            [39.1927285, -6.62589939],
                            [39.2212847, -6.66763449],
                            [39.22579342, -6.69423722],
                            [39.24433741, -6.74854035],
                            [39.26055656, -6.75832027],
                            [39.28224446, -6.73429578],
                            [39.28633246, -6.77945895],
                            [39.28112674, -6.79437123],
                            [39.29814571, -6.80948433],
                            [39.3470215, -6.82790964],
                            [39.35897627, -6.84788401],
                            [39.39113242, -6.84438286],
                            [39.42754741, -6.86080162],
                            [39.46811299, -6.86973628],
                            [39.48990382, -6.88549946],
                            [39.5035894, -6.93765854],
                            [39.52157832, -6.96920879],
                            [39.5440402, -6.98463591],
                            [39.55288567, -7.01337045],
                            [39.54774265, -7.02301347],
                            [39.54745609, -7.06222637],
                            [39.54099089, -7.07934787],
                            [39.54119659, -7.11307704],
                            [39.5229209, -7.12871661],
                            [39.51863094, -7.15896165],
                            [39.5005243, -7.1794188],
                            [39.48852935, -7.16132078],
                            [39.44194507, -7.1751823],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Dar-es-salaam", Region_Cod: 7, zoneId: 1 },
            drilldown: 7,
            region_nam: "Dar-es-salaam",
            value: 2.8,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.13",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [33.67121049, -9.58131667],
                            [33.66835018, -9.54764801],
                            [33.67856504, -9.52935363],
                            [33.6809887, -9.503187],
                            [33.67209827, -9.4666308],
                            [33.7022155, -9.46082371],
                            [33.71351363, -9.48016135],
                            [33.73642527, -9.49044462],
                            [33.76608615, -9.49386754],
                            [33.77956674, -9.47369314],
                            [33.81723042, -9.4488722],
                            [33.80890113, -9.42966344],
                            [33.82297219, -9.41808166],
                            [33.79371846, -9.37455739],
                            [33.78472793, -9.35224146],
                            [33.79012349, -9.32313004],
                            [33.758606, -9.32728986],
                            [33.75084891, -9.30164086],
                            [33.72570935, -9.29140076],
                            [33.72742746, -9.27360083],
                            [33.71981786, -9.23475447],
                            [33.73734966, -9.23867224],
                            [33.73731517, -9.19891628],
                            [33.74351704, -9.18910081],
                            [33.73397108, -9.16193782],
                            [33.69186638, -9.13777752],
                            [33.70693722, -9.10840213],
                            [33.73255989, -9.10223135],
                            [33.74256054, -9.08630682],
                            [33.73779149, -9.04420053],
                            [33.74468217, -9.01956189],
                            [33.72464585, -9.02175989],
                            [33.70116996, -9.0362456],
                            [33.66593859, -9.03723189],
                            [33.64417811, -8.99986101],
                            [33.60149342, -8.99149811],
                            [33.54340422, -8.99483084],
                            [33.52567177, -8.99233828],
                            [33.51199265, -8.97871791],
                            [33.45376151, -8.99936207],
                            [33.45915783, -9.05022741],
                            [33.4337326, -9.07038716],
                            [33.4333977, -9.10277683],
                            [33.45294908, -9.12009509],
                            [33.45038506, -9.14263275],
                            [33.43724023, -9.15085552],
                            [33.42876299, -9.21288268],
                            [33.43699569, -9.27478912],
                            [33.43387382, -9.29709498],
                            [33.41910581, -9.33381987],
                            [33.40531145, -9.32385433],
                            [33.38476831, -9.32484439],
                            [33.36938396, -9.28396498],
                            [33.34489336, -9.28782604],
                            [33.32693257, -9.26002402],
                            [33.32348561, -9.2398071],
                            [33.28360231, -9.23453481],
                            [33.23313498, -9.26404777],
                            [33.23303445, -9.27445377],
                            [33.20961094, -9.29774158],
                            [33.15034171, -9.29746126],
                            [33.11776758, -9.26914106],
                            [33.13090622, -9.24068161],
                            [33.14744128, -9.24464966],
                            [33.16626759, -9.20525019],
                            [33.19839157, -9.19130338],
                            [33.25368782, -9.18887735],
                            [33.2664657, -9.15462797],
                            [33.21109376, -9.13113928],
                            [33.21194483, -9.11724122],
                            [33.2348906, -9.08795687],
                            [33.21408671, -9.04381617],
                            [33.22335207, -8.99533925],
                            [33.21639322, -8.97973765],
                            [33.22700351, -8.96544542],
                            [33.21644574, -8.93649176],
                            [33.21486373, -8.90132137],
                            [33.20860748, -8.88183703],
                            [33.19134455, -8.87819227],
                            [33.16510348, -8.81681111],
                            [33.14124833, -8.8149314],
                            [33.09744051, -8.78762388],
                            [33.03220148, -8.73967468],
                            [33.01025721, -8.70954582],
                            [33.04440947, -8.64368762],
                            [33.06721739, -8.61231296],
                            [33.08894471, -8.60863969],
                            [33.12675572, -8.64487205],
                            [33.14232489, -8.68827572],
                            [33.16875655, -8.7044888],
                            [33.22675329, -8.7243303],
                            [33.22994027, -8.69980518],
                            [33.20907396, -8.68813486],
                            [33.20961972, -8.66072933],
                            [33.22416362, -8.63512662],
                            [33.26096847, -8.6077993],
                            [33.27563465, -8.57947654],
                            [33.31117144, -8.54662315],
                            [33.31243789, -8.51379569],
                            [33.32664352, -8.49656325],
                            [33.34462921, -8.4934308],
                            [33.33502507, -8.46229099],
                            [33.3570146, -8.38091337],
                            [33.3513131, -8.36495576],
                            [33.37495393, -8.33378222],
                            [33.36633115, -8.32640965],
                            [33.35996803, -8.29420521],
                            [33.32441599, -8.28598073],
                            [33.27581266, -8.28371883],
                            [33.25203764, -8.36306702],
                            [33.22980523, -8.38820763],
                            [33.22174821, -8.41210828],
                            [33.22119505, -8.48300394],
                            [33.2115422, -8.56295197],
                            [33.21465945, -8.58728056],
                            [33.19265637, -8.64633801],
                            [33.17537563, -8.61786441],
                            [33.13753492, -8.59018452],
                            [33.13093454, -8.57638028],
                            [33.12576698, -8.49373565],
                            [33.09298969, -8.48872425],
                            [33.07116667, -8.46208105],
                            [33.0474274, -8.45610554],
                            [33.06268667, -8.38596504],
                            [33.10882658, -8.36988299],
                            [33.11858, -8.34967343],
                            [33.14031351, -8.33815271],
                            [33.16313009, -8.31185816],
                            [33.15273891, -8.27118677],
                            [33.11769977, -8.25700563],
                            [33.0986578, -8.26207969],
                            [33.04748625, -8.25151667],
                            [33.03382204, -8.25419734],
                            [33.02226639, -8.27317042],
                            [32.99827955, -8.28982607],
                            [32.97748704, -8.29308514],
                            [32.97001995, -8.26826501],
                            [32.97977912, -8.24806232],
                            [32.97138712, -8.20879563],
                            [32.97084445, -8.17604657],
                            [32.99108639, -8.14497462],
                            [32.99186608, -8.10752977],
                            [33.00339023, -8.07237239],
                            [33.0180167, -8.0595306],
                            [33.02934747, -8.02592982],
                            [33.01976116, -7.98742905],
                            [33.04396459, -7.94849354],
                            [33.06806881, -7.89625533],
                            [33.08672408, -7.86899882],
                            [33.04522229, -7.87121813],
                            [32.93232953, -7.871799],
                            [32.96360875, -7.80112605],
                            [32.99213814, -7.7542559],
                            [33.03988621, -7.75526294],
                            [33.03186665, -7.73894269],
                            [33.0302527, -7.62588191],
                            [33.03139699, -7.590636],
                            [33.03933407, -7.53546678],
                            [33.01456125, -7.52764024],
                            [32.9721632, -7.52980961],
                            [32.94172142, -7.54553927],
                            [32.91889126, -7.5433656],
                            [32.91346362, -7.5021341],
                            [32.90099429, -7.47382576],
                            [32.89557535, -7.44216412],
                            [32.89554656, -7.33290907],
                            [32.89689142, -6.99982129],
                            [32.8936526, -6.92638219],
                            [32.90000292, -6.90850067],
                            [32.93195034, -6.89889352],
                            [32.99549802, -6.86508188],
                            [33.01043935, -6.87332135],
                            [33.02633443, -6.86506212],
                            [33.07602436, -6.86871886],
                            [33.11692589, -6.90420548],
                            [33.15368472, -6.91689765],
                            [33.17756462, -6.93268173],
                            [33.18444099, -6.9479565],
                            [33.23185225, -6.95566019],
                            [33.28887087, -6.94172482],
                            [33.31497903, -6.94205152],
                            [33.36789471, -6.95866383],
                            [33.38866871, -6.94663153],
                            [33.4137471, -6.94781188],
                            [33.42320173, -6.95638615],
                            [33.45170021, -6.94108283],
                            [33.48416285, -6.94122247],
                            [33.51269054, -6.95578277],
                            [33.54852723, -6.95495947],
                            [33.57417663, -6.99287406],
                            [33.63083712, -7.02215311],
                            [33.64165831, -7.02126948],
                            [33.66323604, -7.04219089],
                            [33.68238184, -7.05085285],
                            [33.69556941, -7.08124651],
                            [33.71470933, -7.08469327],
                            [33.73784215, -7.10724951],
                            [33.79062561, -7.11662414],
                            [33.80741185, -7.1370059],
                            [33.79531847, -7.24726357],
                            [33.78532216, -7.29694175],
                            [33.79477819, -7.33079511],
                            [33.80178472, -7.37924588],
                            [33.81421234, -7.40651364],
                            [33.81808222, -7.45387813],
                            [33.80130363, -7.49058541],
                            [33.8013197, -7.52400943],
                            [33.87034212, -7.52708168],
                            [33.91482002, -7.52516037],
                            [33.93662265, -7.53472562],
                            [33.98315398, -7.52844501],
                            [34.01558561, -7.47390826],
                            [34.00518007, -7.43480105],
                            [34.01819888, -7.41417614],
                            [34.05965519, -7.40057815],
                            [34.09998512, -7.39613264],
                            [34.15128505, -7.37998527],
                            [34.19352939, -7.37178801],
                            [34.19942522, -7.4944213],
                            [34.26781687, -7.58993377],
                            [34.2701752, -7.6170546],
                            [34.30434721, -7.65840831],
                            [34.35065022, -7.72747698],
                            [34.38640489, -7.73335743],
                            [34.50928298, -7.71137899],
                            [34.56839887, -7.72018818],
                            [34.62930233, -7.72326827],
                            [34.67124898, -7.73513468],
                            [34.68786515, -7.7443912],
                            [34.73528687, -7.80166161],
                            [34.75169981, -7.82534389],
                            [34.79168367, -7.85848993],
                            [34.81904248, -7.87486852],
                            [34.85079163, -7.90057712],
                            [34.85548809, -7.92471804],
                            [34.8753552, -7.95637612],
                            [34.90862079, -8.02226354],
                            [34.91934363, -8.04989734],
                            [34.94321244, -8.08681489],
                            [34.96825768, -8.09968022],
                            [34.95346729, -8.11524719],
                            [34.93767082, -8.14650646],
                            [34.90407941, -8.16987139],
                            [34.8655259, -8.22104445],
                            [34.83685082, -8.24628132],
                            [34.79736964, -8.30225075],
                            [34.78473978, -8.31154586],
                            [34.74854856, -8.35565391],
                            [34.72667589, -8.39152021],
                            [34.69886104, -8.4085794],
                            [34.68474165, -8.45215018],
                            [34.71408384, -8.47891508],
                            [34.72447606, -8.52221779],
                            [34.71281861, -8.58400106],
                            [34.71796971, -8.63539417],
                            [34.69934513, -8.66420703],
                            [34.67434284, -8.6649061],
                            [34.59220771, -8.68923539],
                            [34.5561552, -8.74643572],
                            [34.54585765, -8.78733286],
                            [34.51152508, -8.81012341],
                            [34.44648161, -8.81272798],
                            [34.41899033, -8.82524299],
                            [34.39182192, -8.82845318],
                            [34.29575016, -8.82352528],
                            [34.29221394, -8.83187497],
                            [34.28563001, -8.91950607],
                            [34.23670732, -8.93604187],
                            [34.20017058, -8.93777106],
                            [34.18046062, -8.90776456],
                            [34.15837812, -8.85856507],
                            [34.12367688, -8.83903715],
                            [34.05604046, -8.84246286],
                            [34.0555482, -8.87736639],
                            [34.03875011, -8.89870843],
                            [33.99323037, -8.8862375],
                            [33.99220179, -8.86678056],
                            [33.90117371, -8.87465805],
                            [33.90011907, -8.83956881],
                            [33.84558119, -8.84392277],
                            [33.82308102, -8.91038087],
                            [33.82627855, -9.01071996],
                            [33.82341609, -9.04786317],
                            [33.81116379, -9.05796297],
                            [33.82760062, -9.08843403],
                            [33.84945838, -9.11121135],
                            [33.84856552, -9.16816998],
                            [33.83794988, -9.19478743],
                            [33.87104289, -9.23440596],
                            [33.92156555, -9.2503302],
                            [33.94852393, -9.28720251],
                            [33.96568539, -9.2992784],
                            [33.98214311, -9.32802877],
                            [33.98079246, -9.37866142],
                            [33.99486798, -9.40004476],
                            [34.01000617, -9.40818874],
                            [34.04231821, -9.46103643],
                            [34.06757721, -9.49657296],
                            [34.08993918, -9.50752688],
                            [34.08490994, -9.52050052],
                            [34.05930994, -9.53510052],
                            [34.02592748, -9.50007888],
                            [33.99653396, -9.50155826],
                            [33.96913804, -9.52123195],
                            [33.945541, -9.57214555],
                            [33.95633097, -9.6140593],
                            [33.95580034, -9.65432634],
                            [33.94387062, -9.67785257],
                            [33.93817, -9.70968049],
                            [33.91393632, -9.70325353],
                            [33.85303395, -9.66156311],
                            [33.83670141, -9.64047928],
                            [33.81358957, -9.63133906],
                            [33.81436038, -9.61730721],
                            [33.78053332, -9.59939575],
                            [33.77387035, -9.58634708],
                            [33.73210791, -9.58441132],
                            [33.69759004, -9.59727474],
                            [33.67121049, -9.58131667],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Mbeya", Region_Cod: 12, zoneId: 5 },
            drilldown: 12,
            region_nam: "Mbeya",
            value: 4.3,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.14",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [36.33645151, -9.72295],
                            [36.35257775, -9.71936928],
                            [36.38953284, -9.7041897],
                            [36.40903486, -9.68606217],
                            [36.41248385, -9.66734384],
                            [36.41954526, -9.67020862],
                            [36.46654339, -9.61338577],
                            [36.46741975, -9.63834671],
                            [36.45405566, -9.67304358],
                            [36.46385873, -9.68822934],
                            [36.47978635, -9.68975268],
                            [36.49242469, -9.70495887],
                            [36.49251694, -9.74010724],
                            [36.50568254, -9.77992096],
                            [36.5095999, -9.81301971],
                            [36.53785553, -9.81261706],
                            [36.56065543, -9.80242489],
                            [36.58598124, -9.78250075],
                            [36.60458393, -9.75765263],
                            [36.64204395, -9.75791406],
                            [36.68200785, -9.75148616],
                            [36.70285446, -9.75650176],
                            [36.76614331, -9.75402848],
                            [36.80647471, -9.73325879],
                            [36.90058747, -9.67926442],
                            [36.99337252, -9.63391481],
                            [37.10545587, -9.59856724],
                            [37.13878332, -9.5849751],
                            [37.1234271, -9.6265822],
                            [37.11322957, -9.66188793],
                            [37.09548858, -9.70397509],
                            [37.07467646, -9.735497],
                            [37.05710878, -9.74594345],
                            [37.04449884, -9.76386143],
                            [37.03368675, -9.79481627],
                            [37.00980941, -9.81390741],
                            [37.00286348, -9.82503248],
                            [36.98195467, -9.86957158],
                            [36.96933904, -9.88686463],
                            [36.90403394, -9.92678293],
                            [36.8901114, -9.93963717],
                            [36.88425765, -9.974336],
                            [36.88155848, -10.00471258],
                            [36.88459846, -10.0183781],
                            [36.86812332, -10.04990668],
                            [36.86932607, -10.0573577],
                            [36.87410468, -10.09212379],
                            [36.88471563, -10.09653397],
                            [36.89778195, -10.10636593],
                            [36.94809701, -10.1713207],
                            [36.96214826, -10.19558057],
                            [36.98299067, -10.24303612],
                            [36.99369449, -10.25387182],
                            [37.0162225, -10.27219006],
                            [37.04667395, -10.28403396],
                            [37.16626685, -10.30469306],
                            [37.23060771, -10.34250239],
                            [37.34119667, -10.37589899],
                            [37.38185442, -10.38786617],
                            [37.42688734, -10.40390192],
                            [37.50147887, -10.43607311],
                            [37.55307347, -10.46488107],
                            [37.59024729, -10.47957574],
                            [37.60424816, -10.49683398],
                            [37.61857647, -10.50816335],
                            [37.64571786, -10.51262894],
                            [37.65917416, -10.51831955],
                            [37.7052379, -10.53560931],
                            [37.75271974, -10.5451553],
                            [37.7890846, -10.54671437],
                            [37.83644251, -10.5421479],
                            [37.86418052, -10.54956281],
                            [37.86051108, -10.56961399],
                            [37.88383445, -10.62638318],
                            [37.87900918, -10.64741139],
                            [37.8851696, -10.66648435],
                            [37.88345759, -10.69815838],
                            [37.88348344, -10.71361747],
                            [37.88559739, -10.73454073],
                            [37.90485647, -10.73582486],
                            [37.96038539, -10.73355195],
                            [37.98375308, -10.72561299],
                            [37.9990481, -10.72444003],
                            [38.04013986, -10.71897178],
                            [38.04711367, -10.7315967],
                            [38.04789434, -10.76886647],
                            [38.03346172, -10.78137526],
                            [38.02542206, -10.82063544],
                            [38.00061824, -10.85876821],
                            [38.01828331, -10.8979709],
                            [38.00710125, -10.92274571],
                            [38.02109007, -10.95590641],
                            [38.01476067, -10.99423951],
                            [38.01907277, -11.02064662],
                            [38.03877209, -11.05283706],
                            [38.05088012, -11.08243221],
                            [38.05090261, -11.13650331],
                            [38.07497131, -11.15140728],
                            [38.07027519, -11.18005909],
                            [38.09441519, -11.2370724],
                            [38.10702476, -11.24534457],
                            [38.10267706, -11.25686725],
                            [38.05987313, -11.27304368],
                            [38.01663176, -11.26934538],
                            [37.99814177, -11.25989387],
                            [37.96779101, -11.26404005],
                            [37.93268313, -11.25482299],
                            [37.91424514, -11.25930271],
                            [37.87371429, -11.28089564],
                            [37.85582153, -11.29881506],
                            [37.82903524, -11.31165759],
                            [37.8262189, -11.3638304],
                            [37.81656716, -11.3728844],
                            [37.80702076, -11.40457105],
                            [37.7871355, -11.41704118],
                            [37.77303427, -11.45839266],
                            [37.78608059, -11.47653322],
                            [37.77763631, -11.51212581],
                            [37.74708042, -11.54697901],
                            [37.68715266, -11.56170651],
                            [37.65796236, -11.57250467],
                            [37.62170692, -11.60263076],
                            [37.58265279, -11.62086916],
                            [37.53076454, -11.60874128],
                            [37.49971456, -11.61014319],
                            [37.48444646, -11.62193144],
                            [37.44414701, -11.63452156],
                            [37.42982057, -11.64724846],
                            [37.41859598, -11.67834047],
                            [37.38338086, -11.68657644],
                            [37.35778484, -11.67518837],
                            [37.33088295, -11.67066116],
                            [37.29845124, -11.63799286],
                            [37.26142472, -11.63246291],
                            [37.2283755, -11.6494369],
                            [37.21253996, -11.64902669],
                            [37.15596218, -11.63055819],
                            [37.0781092, -11.62161862],
                            [37.0472647, -11.59393348],
                            [37.01855043, -11.57937479],
                            [36.99952138, -11.58361524],
                            [36.98183467, -11.57724761],
                            [36.9538515, -11.59359947],
                            [36.90567905, -11.59918666],
                            [36.88829894, -11.58637737],
                            [36.87103375, -11.59079501],
                            [36.83296693, -11.57737368],
                            [36.82461479, -11.55626981],
                            [36.8040454, -11.55271825],
                            [36.79443865, -11.6047367],
                            [36.77919486, -11.61262054],
                            [36.77401477, -11.63535733],
                            [36.7280517, -11.66625806],
                            [36.71345911, -11.67199479],
                            [36.69546775, -11.69379758],
                            [36.6629243, -11.71577282],
                            [36.62776164, -11.73099167],
                            [36.58469769, -11.69844501],
                            [36.56956687, -11.69743927],
                            [36.55386922, -11.72715021],
                            [36.51267375, -11.76176063],
                            [36.50381875, -11.7330571],
                            [36.51781492, -11.69641369],
                            [36.49008043, -11.67764944],
                            [36.44587502, -11.68138513],
                            [36.38789826, -11.67757986],
                            [36.35020477, -11.7108506],
                            [36.32697523, -11.7059387],
                            [36.31829455, -11.71395688],
                            [36.28317705, -11.71590623],
                            [36.27323818, -11.69043633],
                            [36.23822455, -11.69467123],
                            [36.21458435, -11.70575261],
                            [36.19002656, -11.67855887],
                            [36.18157045, -11.66004213],
                            [36.18595789, -11.59874207],
                            [36.19835384, -11.57699968],
                            [36.18554442, -11.56161508],
                            [36.15240526, -11.56734668],
                            [36.12444068, -11.54187995],
                            [36.12750245, -11.52369874],
                            [36.11492232, -11.50943765],
                            [36.10095938, -11.52568106],
                            [36.07834445, -11.53641981],
                            [36.04397744, -11.50515841],
                            [36.04236335, -11.49298802],
                            [36.00802963, -11.48484317],
                            [35.98369385, -11.45605825],
                            [35.95659855, -11.46040071],
                            [35.94362508, -11.42156055],
                            [35.93008445, -11.43568538],
                            [35.90746403, -11.43505092],
                            [35.86123882, -11.41663511],
                            [35.84092724, -11.4236379],
                            [35.83376819, -11.43832112],
                            [35.8148408, -11.41762117],
                            [35.79750953, -11.41796321],
                            [35.74617836, -11.47409907],
                            [35.68377362, -11.48618131],
                            [35.68019919, -11.51022857],
                            [35.66479971, -11.5246673],
                            [35.61890131, -11.55363582],
                            [35.56871788, -11.56197638],
                            [35.55149488, -11.55663003],
                            [35.52131452, -11.56736412],
                            [35.50085942, -11.55724047],
                            [35.48230358, -11.58191078],
                            [35.47105358, -11.55999178],
                            [35.4456787, -11.55792235],
                            [35.44689941, -11.57507323],
                            [35.41149901, -11.58068846],
                            [35.3731079, -11.57000731],
                            [35.18072509, -11.57128905],
                            [34.96491556, -11.57324435],
                            [34.95506676, -11.48992265],
                            [34.93304246, -11.46320397],
                            [34.93250164, -11.44312483],
                            [34.92189664, -11.41978685],
                            [34.92849168, -11.39833074],
                            [34.88893499, -11.3682634],
                            [34.80036688, -11.31860211],
                            [34.7854624, -11.29465101],
                            [34.80036033, -11.27705357],
                            [34.77556054, -11.25385966],
                            [34.75576757, -11.20579354],
                            [34.71692333, -11.18915984],
                            [34.70290875, -11.17671987],
                            [34.6983051, -11.15814036],
                            [34.65614036, -11.13328555],
                            [34.63826743, -11.11632368],
                            [34.63668061, -11.05045365],
                            [34.61558505, -11.04617731],
                            [34.61157531, -11.02691036],
                            [34.62220129, -11.00044871],
                            [34.63453243, -10.9906055],
                            [34.65075965, -10.95526725],
                            [34.67298329, -10.93386297],
                            [34.67826669, -10.90327639],
                            [34.66983372, -10.89319052],
                            [34.6758264, -10.85768504],
                            [34.67026156, -10.84095468],
                            [34.67942301, -10.81093093],
                            [34.67199797, -10.7819726],
                            [34.68173568, -10.7590403],
                            [34.67897001, -10.74539845],
                            [34.64988246, -10.71223202],
                            [34.65922974, -10.68750666],
                            [34.64461261, -10.64514937],
                            [34.62008411, -10.62179807],
                            [34.61864144, -10.59034486],
                            [34.58562662, -10.57328564],
                            [34.57577362, -10.5535137],
                            [34.57943475, -10.53334154],
                            [34.61038138, -10.52626824],
                            [34.63340886, -10.53439795],
                            [34.68133186, -10.53428059],
                            [34.6978136, -10.52496426],
                            [34.70558936, -10.5084606],
                            [34.71910386, -10.50978439],
                            [34.76502489, -10.49006687],
                            [34.77734914, -10.4449068],
                            [34.80228286, -10.44454595],
                            [34.81447815, -10.45530752],
                            [34.81750081, -10.48822402],
                            [34.85927004, -10.46399594],
                            [34.87245703, -10.43976869],
                            [34.89913767, -10.41094133],
                            [34.9319518, -10.37966058],
                            [34.95740575, -10.33611286],
                            [34.96660597, -10.32200585],
                            [34.99625215, -10.30805502],
                            [35.00127816, -10.29265702],
                            [34.99382631, -10.26780764],
                            [35.01129236, -10.26644917],
                            [34.97986066, -10.2273087],
                            [34.98170071, -10.2024681],
                            [34.993891, -10.18889777],
                            [35.00930138, -10.18314763],
                            [35.03168006, -10.17099141],
                            [35.04793379, -10.16577795],
                            [35.06265414, -10.15443101],
                            [35.07676115, -10.16731132],
                            [35.10865525, -10.17436483],
                            [35.13251833, -10.15521686],
                            [35.14828071, -10.16115499],
                            [35.17273393, -10.12022121],
                            [35.18027784, -10.09287951],
                            [35.19566075, -10.06383863],
                            [35.21612227, -10.01159766],
                            [35.21794895, -9.98422268],
                            [35.19838438, -9.94815142],
                            [35.16666747, -9.91024372],
                            [35.16869493, -9.88309843],
                            [35.17858953, -9.8577927],
                            [35.17332476, -9.83249927],
                            [35.15379738, -9.82691179],
                            [35.1477681, -9.81040078],
                            [35.1056253, -9.77437928],
                            [35.14438558, -9.75545179],
                            [35.17143204, -9.74066343],
                            [35.19624058, -9.72182198],
                            [35.22746602, -9.69575274],
                            [35.23465126, -9.68803286],
                            [35.26826493, -9.66652118],
                            [35.33255092, -9.6327398],
                            [35.36771779, -9.61840612],
                            [35.38385979, -9.59442008],
                            [35.42513045, -9.55894965],
                            [35.44802682, -9.52882503],
                            [35.47289689, -9.5058147],
                            [35.49586458, -9.49232644],
                            [35.55820387, -9.45043178],
                            [35.63139725, -9.27263172],
                            [35.65562911, -9.26685371],
                            [35.68197306, -9.25217849],
                            [35.69756677, -9.28223018],
                            [35.72330618, -9.29330251],
                            [35.74413556, -9.29286043],
                            [35.79011441, -9.3161494],
                            [35.82095047, -9.29375163],
                            [35.8397612, -9.27243722],
                            [35.87117548, -9.25182807],
                            [35.91081177, -9.25217198],
                            [35.91820665, -9.29400635],
                            [35.91857078, -9.34137331],
                            [35.9394257, -9.38757966],
                            [35.94589308, -9.42716394],
                            [35.93352127, -9.45426558],
                            [35.88747449, -9.48120363],
                            [35.88606908, -9.50095416],
                            [35.84504575, -9.55515153],
                            [35.83067318, -9.6140937],
                            [35.81778657, -9.64201956],
                            [35.8108701, -9.67417653],
                            [35.7878304, -9.6942485],
                            [35.7756226, -9.71322705],
                            [35.74286466, -9.74274878],
                            [35.71289309, -9.76279989],
                            [35.69435188, -9.79507869],
                            [35.68455356, -9.8003119],
                            [35.67330591, -9.82768308],
                            [35.69899451, -9.8674156],
                            [35.73132856, -9.88415106],
                            [35.75553917, -9.91661823],
                            [35.77822381, -9.91744524],
                            [35.80662878, -9.89764678],
                            [35.84415376, -9.88583729],
                            [35.88571082, -9.83634943],
                            [35.9676846, -9.78420649],
                            [36.00511569, -9.76432831],
                            [36.03432278, -9.75410823],
                            [36.0471142, -9.73847793],
                            [36.06856084, -9.73570354],
                            [36.07002005, -9.75752171],
                            [36.08674345, -9.76386704],
                            [36.09919423, -9.78415872],
                            [36.14495625, -9.77500112],
                            [36.17374756, -9.7626586],
                            [36.2401083, -9.76314241],
                            [36.27375638, -9.76667227],
                            [36.30634695, -9.76365092],
                            [36.32420342, -9.75564006],
                            [36.33645151, -9.72295],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Ruvuma", Region_Cod: 10, zoneId: 5 },
            drilldown: 10,
            region_nam: "Ruvuma",
            value: 4.4,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.15",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [39.68871394, -10.33135661],
                            [39.72589784, -10.30351292],
                            [39.74600733, -10.28913618],
                            [39.77232028, -10.26551041],
                            [39.86622226, -10.25998214],
                            [39.89158408, -10.26180869],
                            [39.89274995, -10.23804421],
                            [39.90336719, -10.22424032],
                            [39.93033493, -10.21305568],
                            [39.9540035, -10.20087546],
                            [39.98805238, -10.19886822],
                            [39.99361417, -10.17003601],
                            [39.97298478, -10.14415371],
                            [39.9865954, -10.12508137],
                            [40.07239541, -10.15680134],
                            [40.10562455, -10.16325788],
                            [40.13014329, -10.17753474],
                            [40.12134967, -10.20361607],
                            [40.12681528, -10.24843138],
                            [40.14262688, -10.26088203],
                            [40.17926514, -10.25227227],
                            [40.2103373, -10.27387543],
                            [40.2435315, -10.27468392],
                            [40.24958783, -10.25993003],
                            [40.2271956, -10.24700456],
                            [40.19130883, -10.24649464],
                            [40.23355101, -10.20216982],
                            [40.24482502, -10.24051149],
                            [40.26218672, -10.25831103],
                            [40.30393032, -10.26834013],
                            [40.29044982, -10.30047403],
                            [40.32227846, -10.29750104],
                            [40.33583273, -10.31506403],
                            [40.32754322, -10.35020977],
                            [40.35051267, -10.35061546],
                            [40.37948159, -10.34103506],
                            [40.37985041, -10.32174265],
                            [40.40396058, -10.29404852],
                            [40.4242102, -10.31526931],
                            [40.44473484, -10.34650623],
                            [40.43771807, -10.36403353],
                            [40.40068938, -10.37354324],
                            [40.39040135, -10.38622409],
                            [40.36908335, -10.37581822],
                            [40.3440902, -10.41229404],
                            [40.35520768, -10.44341084],
                            [40.34715463, -10.45123191],
                            [40.3421057, -10.49139521],
                            [40.36530863, -10.51157514],
                            [40.38898953, -10.47527491],
                            [40.40630296, -10.50684293],
                            [40.38546996, -10.51445924],
                            [40.36377667, -10.55754385],
                            [40.33768316, -10.57130392],
                            [40.29182667, -10.5713488],
                            [40.26988174, -10.57699492],
                            [40.23002863, -10.60242865],
                            [40.18676095, -10.64661887],
                            [40.15470315, -10.67278083],
                            [40.11078416, -10.71431489],
                            [40.0694248, -10.74350423],
                            [40.02306142, -10.79192835],
                            [39.99630643, -10.81416551],
                            [39.92839667, -10.82359174],
                            [39.89511943, -10.8374034],
                            [39.79586942, -10.89906279],
                            [39.69112331, -10.93534929],
                            [39.61700453, -10.94578501],
                            [39.5600513, -10.972512],
                            [39.53758865, -10.97569848],
                            [39.5009733, -11.00544957],
                            [39.46270178, -11.02711334],
                            [39.44163958, -11.04954783],
                            [39.40886719, -11.05884221],
                            [39.34336596, -11.09456907],
                            [39.31573267, -11.1264204],
                            [39.28629798, -11.14984147],
                            [39.24873988, -11.17254866],
                            [39.22114067, -11.17462215],
                            [39.14272774, -11.1456939],
                            [39.0061592, -11.1693866],
                            [38.96704178, -11.17305772],
                            [38.93264477, -11.16564716],
                            [38.89042902, -11.16875707],
                            [38.85783154, -11.18829216],
                            [38.85006477, -11.19953785],
                            [38.80688199, -11.23133496],
                            [38.7744754, -11.24716643],
                            [38.74093232, -11.26985417],
                            [38.70741188, -11.26500189],
                            [38.6431729, -11.28011352],
                            [38.61370301, -11.30163244],
                            [38.5874609, -11.32892414],
                            [38.55248707, -11.35028977],
                            [38.52045804, -11.377149],
                            [38.48452291, -11.41769567],
                            [38.46063673, -11.41681832],
                            [38.4332718, -11.39361233],
                            [38.37538396, -11.38014482],
                            [38.35943349, -11.36622301],
                            [38.33393457, -11.3321718],
                            [38.31481064, -11.32267862],
                            [38.28028905, -11.3285299],
                            [38.27589944, -11.30346737],
                            [38.2639335, -11.28190212],
                            [38.24805008, -11.28368424],
                            [38.19658455, -11.27157035],
                            [38.18175928, -11.27606836],
                            [38.14754606, -11.2729464],
                            [38.11286482, -11.25620386],
                            [38.10702476, -11.24534457],
                            [38.09441519, -11.2370724],
                            [38.07027519, -11.18005909],
                            [38.07497131, -11.15140728],
                            [38.05090261, -11.13650331],
                            [38.05088012, -11.08243221],
                            [38.03877209, -11.05283706],
                            [38.01907277, -11.02064662],
                            [38.01476067, -10.99423951],
                            [38.02109007, -10.95590641],
                            [38.00710125, -10.92274571],
                            [38.01828331, -10.8979709],
                            [38.00061824, -10.85876821],
                            [38.02542206, -10.82063544],
                            [38.03346172, -10.78137526],
                            [38.04789434, -10.76886647],
                            [38.04711367, -10.7315967],
                            [38.04013986, -10.71897178],
                            [37.9990481, -10.72444003],
                            [38.00285341, -10.7211332],
                            [38.05892922, -10.70201111],
                            [38.10985884, -10.68531818],
                            [38.13681828, -10.68648774],
                            [38.19464065, -10.69488459],
                            [38.2046614, -10.69407204],
                            [38.26397339, -10.68418577],
                            [38.27616085, -10.65953743],
                            [38.28121634, -10.60567047],
                            [38.31261946, -10.60391272],
                            [38.32035307, -10.6043815],
                            [38.38116203, -10.60431505],
                            [38.42381402, -10.60478392],
                            [38.49447112, -10.57724471],
                            [38.51826414, -10.56219274],
                            [38.5664948, -10.54344582],
                            [38.62323022, -10.50447581],
                            [38.68632679, -10.5005343],
                            [38.69907423, -10.48213561],
                            [38.71678402, -10.48596369],
                            [38.75956402, -10.54487751],
                            [38.79155888, -10.54909641],
                            [38.8286259, -10.55036264],
                            [38.83989925, -10.54467458],
                            [38.87478345, -10.54514135],
                            [38.88527833, -10.51495826],
                            [38.91637539, -10.50030206],
                            [38.95017129, -10.49260282],
                            [38.96378894, -10.47778805],
                            [38.98187693, -10.46492325],
                            [39.00399358, -10.46339191],
                            [39.04885017, -10.45185517],
                            [39.08140353, -10.42890713],
                            [39.11048733, -10.41945539],
                            [39.12126194, -10.40538233],
                            [39.1415368, -10.39793297],
                            [39.15005468, -10.38508743],
                            [39.17304681, -10.39086075],
                            [39.20516391, -10.37265029],
                            [39.21356486, -10.36091917],
                            [39.24803779, -10.34718849],
                            [39.25916842, -10.35301205],
                            [39.29030548, -10.33577471],
                            [39.30444785, -10.33740318],
                            [39.33460571, -10.32702159],
                            [39.35774994, -10.34985652],
                            [39.39604889, -10.33297423],
                            [39.46138083, -10.36269089],
                            [39.48328379, -10.39458218],
                            [39.49251625, -10.45697885],
                            [39.50729438, -10.47619278],
                            [39.55083833, -10.51337259],
                            [39.56459041, -10.5318033],
                            [39.57443691, -10.56171911],
                            [39.57985529, -10.59775692],
                            [39.6192105, -10.5978248],
                            [39.6227791, -10.61198081],
                            [39.64634241, -10.61475346],
                            [39.66295844, -10.57981898],
                            [39.7050671, -10.54830753],
                            [39.72851453, -10.53807891],
                            [39.71284834, -10.50787542],
                            [39.70061351, -10.4917592],
                            [39.71675176, -10.45742643],
                            [39.70528754, -10.43294607],
                            [39.69202729, -10.37889762],
                            [39.68871394, -10.33135661],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Mtwara", Region_Cod: 9, zoneId: 6 },
            drilldown: 9,
            region_nam: "Mtwara",
            value: 3.3,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.16",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [35.08267619, -6.92475874],
                            [35.08279273, -6.92395038],
                            [35.11520795, -6.938785],
                            [35.1335778, -6.95390389],
                            [35.13765626, -6.95640546],
                            [35.15519394, -6.96713727],
                            [35.17803125, -6.98036364],
                            [35.20568397, -6.97942226],
                            [35.24341645, -6.99428176],
                            [35.27299696, -6.97154341],
                            [35.29593151, -6.9408751],
                            [35.34731169, -6.93373724],
                            [35.36283205, -6.93710334],
                            [35.41739924, -6.92651568],
                            [35.43885572, -6.93036244],
                            [35.46678045, -6.94955112],
                            [35.48729473, -6.94507411],
                            [35.49986444, -6.95374668],
                            [35.51623664, -6.99096696],
                            [35.53999452, -7.01245604],
                            [35.5800376, -7.00267391],
                            [35.6022867, -7.02291138],
                            [35.65130203, -7.03093121],
                            [35.68303728, -7.0596549],
                            [35.70864278, -7.06478495],
                            [35.72054234, -7.07778508],
                            [35.77047163, -7.09081058],
                            [35.80398933, -7.0937927],
                            [35.81796599, -7.08939378],
                            [35.81547166, -7.09288545],
                            [35.84866051, -7.08342637],
                            [35.87961634, -7.09278505],
                            [35.89425732, -7.10773003],
                            [35.90986605, -7.1031089],
                            [35.92514781, -7.11314814],
                            [35.96102795, -7.11869948],
                            [35.97920101, -7.1348322],
                            [36.00973958, -7.12479245],
                            [36.05002068, -7.11766039],
                            [36.07417656, -7.1317803],
                            [36.11344826, -7.12456581],
                            [36.13647591, -7.13291047],
                            [36.16221465, -7.15141016],
                            [36.1922396, -7.14705759],
                            [36.20048027, -7.16861944],
                            [36.23090998, -7.16861532],
                            [36.2496486, -7.17640004],
                            [36.27847455, -7.23083265],
                            [36.30135763, -7.24270856],
                            [36.32212428, -7.2839673],
                            [36.31413994, -7.30342472],
                            [36.31789776, -7.32330162],
                            [36.32999589, -7.33448653],
                            [36.36360838, -7.32827569],
                            [36.39133877, -7.31463232],
                            [36.43202276, -7.33803979],
                            [36.45045002, -7.33945965],
                            [36.47890989, -7.36177724],
                            [36.4813434, -7.37818588],
                            [36.47874465, -7.41003677],
                            [36.48894138, -7.41206965],
                            [36.50508795, -7.43820134],
                            [36.50940869, -7.46849376],
                            [36.51796023, -7.47795702],
                            [36.5227784, -7.50477337],
                            [36.53990665, -7.52124289],
                            [36.56500018, -7.51573933],
                            [36.58161917, -7.51313054],
                            [36.6086852, -7.53234129],
                            [36.6433437, -7.52781428],
                            [36.65329146, -7.531971],
                            [36.67726672, -7.56215343],
                            [36.69261904, -7.57721499],
                            [36.75394683, -7.58622802],
                            [36.77915857, -7.59484074],
                            [36.7904837, -7.59438868],
                            [36.78101747, -7.642013],
                            [36.79308284, -7.64700724],
                            [36.83749251, -7.64830576],
                            [36.84136373, -7.66981117],
                            [36.83333789, -7.71871984],
                            [36.83479379, -7.76880611],
                            [36.83017415, -7.78240064],
                            [36.81224933, -7.81777858],
                            [36.7495505, -7.91062072],
                            [36.74170504, -7.92060013],
                            [36.7016171, -7.97918802],
                            [36.70160834, -8.00978461],
                            [36.70870756, -8.01748422],
                            [36.71144358, -8.02382486],
                            [36.63175638, -8.02583351],
                            [36.53331916, -8.02739225],
                            [36.53331701, -8.02739227],
                            [36.46396683, -8.02664362],
                            [36.46339185, -7.97971622],
                            [36.41437248, -8.01614838],
                            [36.38913409, -8.02838153],
                            [36.33492459, -8.06573456],
                            [36.30143318, -8.08361011],
                            [36.18931525, -8.17853708],
                            [36.14026974, -8.22985477],
                            [36.10642835, -8.28771437],
                            [36.08430984, -8.30818491],
                            [36.02214506, -8.35589899],
                            [35.95612351, -8.42873745],
                            [35.91566866, -8.43570321],
                            [35.88847751, -8.45885861],
                            [35.88577522, -8.48717255],
                            [35.87323579, -8.51567422],
                            [35.87177647, -8.53900783],
                            [35.84939371, -8.56567158],
                            [35.81841648, -8.58656141],
                            [35.68915482, -8.66358886],
                            [35.69583767, -8.71449853],
                            [35.6877821, -8.75897262],
                            [35.66438379, -8.74050606],
                            [35.64516121, -8.76886524],
                            [35.62878361, -8.75234582],
                            [35.60399191, -8.70190072],
                            [35.58678291, -8.68412007],
                            [35.5425917, -8.68211991],
                            [35.51126772, -8.66242882],
                            [35.47473318, -8.65691531],
                            [35.4157136, -8.66256361],
                            [35.36207131, -8.68099174],
                            [35.35249232, -8.71027605],
                            [35.33096243, -8.72487259],
                            [35.34263966, -8.76300606],
                            [35.35130636, -8.77003064],
                            [35.35012039, -8.80186935],
                            [35.3572362, -8.82248696],
                            [35.32366416, -8.86609413],
                            [35.35348311, -8.88023953],
                            [35.34696598, -8.89040009],
                            [35.36656868, -8.9154936],
                            [35.36333577, -8.93802131],
                            [35.38180952, -8.94027921],
                            [35.40813461, -8.97712407],
                            [35.38024963, -9.0130466],
                            [35.35531724, -9.02859618],
                            [35.31642496, -9.02964663],
                            [35.30638049, -9.03760537],
                            [35.26248831, -9.03353692],
                            [35.20421048, -9.04048288],
                            [35.13920468, -9.02997711],
                            [35.12069701, -9.01911534],
                            [35.12840191, -8.97286965],
                            [35.11030444, -8.94094139],
                            [35.11371496, -8.92891206],
                            [35.14013096, -8.90140412],
                            [35.12905906, -8.87958919],
                            [35.14338006, -8.84379127],
                            [35.14063949, -8.81397388],
                            [35.1296224, -8.79901038],
                            [35.12737513, -8.77714064],
                            [35.09328246, -8.78207366],
                            [35.06779517, -8.77396158],
                            [35.04252713, -8.77609922],
                            [35.02646739, -8.80553293],
                            [34.97978536, -8.8269926],
                            [34.94915113, -8.82573498],
                            [34.9245589, -8.80760455],
                            [34.90285791, -8.80801208],
                            [34.85401787, -8.76907444],
                            [34.87376114, -8.73558122],
                            [34.89381111, -8.7258721],
                            [34.87526501, -8.69705668],
                            [34.85965757, -8.68613533],
                            [34.81998858, -8.67858285],
                            [34.74175406, -8.66898079],
                            [34.7335141, -8.67310077],
                            [34.69919951, -8.66420072],
                            [34.71777936, -8.63544176],
                            [34.71262826, -8.58404865],
                            [34.72428571, -8.52226538],
                            [34.71389348, -8.47896268],
                            [34.6845513, -8.45219777],
                            [34.69867069, -8.408627],
                            [34.72648554, -8.3915678],
                            [34.74835821, -8.3557015],
                            [34.78454942, -8.31159346],
                            [34.79717928, -8.30229834],
                            [34.83666047, -8.24632891],
                            [34.86533555, -8.22109204],
                            [34.90388906, -8.16991898],
                            [34.93748047, -8.14655405],
                            [34.95327694, -8.11529478],
                            [34.96806733, -8.09972782],
                            [34.94302209, -8.08686248],
                            [34.91915328, -8.04994493],
                            [34.90843044, -8.02231113],
                            [34.87516485, -7.95642372],
                            [34.85529774, -7.92476563],
                            [34.85060128, -7.90062471],
                            [34.81885213, -7.87491612],
                            [34.79149331, -7.85853752],
                            [34.75150945, -7.82539149],
                            [34.73509652, -7.80170921],
                            [34.6876748, -7.7444388],
                            [34.67105863, -7.73518227],
                            [34.62911198, -7.72331586],
                            [34.56820851, -7.72023577],
                            [34.50909263, -7.71142658],
                            [34.38621454, -7.73340502],
                            [34.35045987, -7.72752458],
                            [34.30415686, -7.6584559],
                            [34.26998485, -7.61710219],
                            [34.26762651, -7.58998137],
                            [34.19923487, -7.4944689],
                            [34.19379884, -7.37076858],
                            [34.23352233, -7.33260189],
                            [34.25138744, -7.32710316],
                            [34.27298585, -7.3343617],
                            [34.29234249, -7.32662141],
                            [34.31321264, -7.33538956],
                            [34.34283767, -7.33017874],
                            [34.36222738, -7.31886762],
                            [34.3876611, -7.32185472],
                            [34.41186702, -7.31313419],
                            [34.43107265, -7.31687018],
                            [34.45029323, -7.28254837],
                            [34.47124585, -7.27461327],
                            [34.51094587, -7.23002302],
                            [34.56147939, -7.20876685],
                            [34.60872389, -7.16533451],
                            [34.6139615, -7.14427653],
                            [34.64804433, -7.11067605],
                            [34.67453448, -7.05487327],
                            [34.69055674, -7.04232938],
                            [34.72206139, -7.03297573],
                            [34.72550085, -7.02230658],
                            [34.75624821, -7.00696257],
                            [34.78208415, -6.97640597],
                            [34.8188622, -6.95980146],
                            [34.8382846, -6.94097568],
                            [34.85593088, -6.93642512],
                            [34.87434365, -6.91751095],
                            [34.89474815, -6.91589557],
                            [34.91612315, -6.89085076],
                            [34.94759041, -6.88787802],
                            [34.97002446, -6.90372044],
                            [34.99326641, -6.89850213],
                            [35.01979893, -6.90221143],
                            [35.08267619, -6.92475874],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Iringa", Region_Cod: 11, zoneId: 5 },
            drilldown: 11,
            region_nam: "Iringa",
            value: 3.9,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.17",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [39.37459913, -8.05622006],
                            [39.36370277, -8.08469],
                            [39.38316115, -8.09583529],
                            [39.39075491, -8.11716171],
                            [39.38293095, -8.13517662],
                            [39.33478298, -8.112186],
                            [39.31735773, -8.08228816],
                            [39.3441414, -8.08703314],
                            [39.35763803, -8.06571001],
                            [39.37459913, -8.05622006],
                        ],
                    ],
                    [
                        [
                            [39.36213513, -8.02630128],
                            [39.38354911, -8.04295849],
                            [39.35661293, -8.06432041],
                            [39.34909354, -8.05615989],
                            [39.36213513, -8.02630128],
                        ],
                    ],
                    [
                        [
                            [39.74127541, -8.05059833],
                            [39.71719558, -8.07530237],
                            [39.67417615, -8.10308742],
                            [39.67819416, -8.08001391],
                            [39.7013319, -8.04682416],
                            [39.68276647, -8.03137244],
                            [39.70341029, -8.00926907],
                            [39.71297006, -8.02693802],
                            [39.74127541, -8.05059833],
                        ],
                    ],
                    [
                        [
                            [39.40807024, -7.94944575],
                            [39.39641349, -7.99073574],
                            [39.38562371, -7.98075852],
                            [39.39206364, -7.95966236],
                            [39.40807024, -7.94944575],
                        ],
                    ],
                    [
                        [
                            [39.53480668, -7.9331605],
                            [39.54801813, -7.95377034],
                            [39.53795789, -7.97147047],
                            [39.51250466, -7.93231177],
                            [39.53480668, -7.9331605],
                        ],
                    ],
                    [
                        [
                            [39.77573129, -7.92251001],
                            [39.78349162, -7.93942522],
                            [39.80358112, -7.94677003],
                            [39.80649057, -7.9621524],
                            [39.77822648, -8.0078143],
                            [39.7506552, -8.03864818],
                            [39.72315346, -8.00635898],
                            [39.73548433, -7.99360969],
                            [39.74684546, -7.95820262],
                            [39.7797507, -7.96554748],
                            [39.78653967, -7.94420623],
                            [39.76409481, -7.94531477],
                            [39.77573129, -7.92251001],
                        ],
                    ],
                    [
                        [
                            [39.27141956, -7.93611528],
                            [39.27883, -7.95626333],
                            [39.23588516, -7.96264567],
                            [39.24287038, -7.94366687],
                            [39.27141956, -7.93611528],
                        ],
                    ],
                    [
                        [
                            [39.907909, -7.63797593],
                            [39.90000864, -7.70833477],
                            [39.87977116, -7.7904922],
                            [39.85341504, -7.83614253],
                            [39.84819843, -7.86835148],
                            [39.80235335, -7.93362211],
                            [39.7988925, -7.91390539],
                            [39.77943112, -7.89504148],
                            [39.76453404, -7.90111196],
                            [39.75871557, -7.92318662],
                            [39.74306613, -7.92368825],
                            [39.73297709, -7.94382596],
                            [39.74336539, -7.95527697],
                            [39.73212885, -7.99238653],
                            [39.71856036, -8.00129278],
                            [39.69863815, -7.98932511],
                            [39.6653865, -7.99129588],
                            [39.64765211, -8.03465586],
                            [39.6267159, -8.03354714],
                            [39.61477002, -8.01556251],
                            [39.62585406, -7.97749933],
                            [39.58250397, -7.93389272],
                            [39.59001645, -7.91516909],
                            [39.64228203, -7.90559923],
                            [39.66437712, -7.88823503],
                            [39.69883504, -7.84535062],
                            [39.70935645, -7.85298043],
                            [39.72378424, -7.81812536],
                            [39.74723973, -7.80128627],
                            [39.80380076, -7.77955919],
                            [39.78745957, -7.77328095],
                            [39.75250452, -7.77187363],
                            [39.76494996, -7.7375602],
                            [39.78767616, -7.73734381],
                            [39.8166792, -7.71710222],
                            [39.8243629, -7.69999963],
                            [39.84319322, -7.69025772],
                            [39.86148258, -7.64717651],
                            [39.89416514, -7.61914134],
                            [39.907909, -7.63797593],
                        ],
                    ],
                    [
                        [
                            [37.87822644, -5.87718711],
                            [37.89906378, -5.87206263],
                            [37.91712568, -5.84870343],
                            [37.93275369, -5.84357894],
                            [37.95581139, -5.85024083],
                            [37.97272037, -5.84921595],
                            [37.99526566, -5.86997023],
                            [38.00679453, -5.86023368],
                            [38.05752147, -5.86330845],
                            [38.0639264, -5.85818394],
                            [38.06751316, -5.85229076],
                            [38.10466169, -5.85562174],
                            [38.13566149, -5.86074628],
                            [38.15257048, -5.85459689],
                            [38.1775358, -5.87036819],
                            [38.18952666, -5.87252065],
                            [38.20428463, -5.88174544],
                            [38.20828158, -5.88328291],
                            [38.22482111, -5.90517108],
                            [38.2262841, -5.90950751],
                            [38.23864967, -5.92119256],
                            [38.249016, -5.92698175],
                            [38.29922982, -5.92617218],
                            [38.31487776, -5.93428686],
                            [38.33701668, -5.95631239],
                            [38.33991443, -5.96894808],
                            [38.34165307, -5.9871481],
                            [38.36541477, -5.9830908],
                            [38.39647884, -5.98900295],
                            [38.40041982, -5.98158383],
                            [38.42245602, -6.00592963],
                            [38.42877688, -6.01391288],
                            [38.44806656, -6.00813336],
                            [38.4642878, -6.01973843],
                            [38.48259168, -5.99948662],
                            [38.51406061, -6.00652167],
                            [38.54144469, -5.99730295],
                            [38.56173169, -6.01571418],
                            [38.59626747, -5.98129665],
                            [38.62467405, -5.98649044],
                            [38.64211592, -5.97524583],
                            [38.65506403, -5.95434269],
                            [38.66992939, -5.95163522],
                            [38.69492705, -5.96151909],
                            [38.70474281, -5.9459938],
                            [38.73037033, -5.95322627],
                            [38.74571488, -5.95232365],
                            [38.79084592, -5.96676558],
                            [38.79535902, -5.9631551],
                            [38.7783316, -6.01828523],
                            [38.77926103, -6.04441184],
                            [38.79893778, -6.09032919],
                            [38.81978403, -6.11345521],
                            [38.83841723, -6.14543514],
                            [38.86305947, -6.17142214],
                            [38.86051979, -6.20874951],
                            [38.85133336, -6.26079293],
                            [38.83886269, -6.31105001],
                            [38.84325725, -6.3391823],
                            [38.8660314, -6.38023477],
                            [38.8846276, -6.39854242],
                            [38.90121543, -6.40290221],
                            [38.90387624, -6.42605986],
                            [38.9201206, -6.44766187],
                            [38.98237691, -6.47451914],
                            [38.99501297, -6.47347731],
                            [39.01309077, -6.49113495],
                            [39.05484527, -6.49453429],
                            [39.05743828, -6.51008195],
                            [39.08140148, -6.52382432],
                            [39.091395, -6.54333597],
                            [39.12055109, -6.56427855],
                            [39.1047196, -6.58815265],
                            [39.08509016, -6.59348898],
                            [39.07727043, -6.60780198],
                            [39.06750801, -6.65580522],
                            [39.06040987, -6.66293329],
                            [39.0604146, -6.70809768],
                            [39.02925951, -6.75172887],
                            [39.02927524, -6.76363479],
                            [39.00776557, -6.78620348],
                            [39.05326067, -6.78796462],
                            [39.05895603, -6.8272229],
                            [39.05410516, -6.84184006],
                            [39.07004714, -6.86427047],
                            [39.11783326, -6.87860502],
                            [39.10249319, -6.91738849],
                            [39.07815656, -6.94642945],
                            [39.0736111, -6.9775327],
                            [39.05831898, -6.99770665],
                            [39.06395727, -7.01230515],
                            [39.05027772, -7.02615275],
                            [39.02154759, -7.02516742],
                            [39.0293369, -7.0443969],
                            [39.06864173, -7.03236123],
                            [39.06904995, -7.05545752],
                            [39.11269573, -7.0720681],
                            [39.13286698, -7.07115789],
                            [39.15075193, -7.01757383],
                            [39.17353874, -7.00894516],
                            [39.1923356, -6.98056519],
                            [39.21936159, -6.99211835],
                            [39.24296272, -6.94916869],
                            [39.267926, -6.94190629],
                            [39.27569359, -6.95236932],
                            [39.32173098, -6.94711158],
                            [39.34371593, -6.95381975],
                            [39.36226988, -6.96893985],
                            [39.38541474, -6.9680978],
                            [39.38412085, -6.99363226],
                            [39.4052044, -7.00923443],
                            [39.40694927, -7.06851803],
                            [39.39548413, -7.07899588],
                            [39.41680988, -7.1235075],
                            [39.40385764, -7.14628259],
                            [39.42115794, -7.14975641],
                            [39.44194507, -7.1751823],
                            [39.42861898, -7.20307831],
                            [39.42403015, -7.23539709],
                            [39.40630876, -7.24953557],
                            [39.37878652, -7.28639614],
                            [39.35068905, -7.35563093],
                            [39.35210447, -7.39508897],
                            [39.33782082, -7.42533239],
                            [39.31944403, -7.4301624],
                            [39.30135989, -7.47620403],
                            [39.29489268, -7.52270081],
                            [39.27752226, -7.56943118],
                            [39.27151698, -7.60618224],
                            [39.27814323, -7.63916261],
                            [39.2998079, -7.67008746],
                            [39.28671672, -7.70484332],
                            [39.30015353, -7.73327182],
                            [39.29885178, -7.74839118],
                            [39.28133459, -7.75215136],
                            [39.28726901, -7.78116186],
                            [39.26498179, -7.8007309],
                            [39.23739592, -7.79403155],
                            [39.23491308, -7.8243489],
                            [39.21616232, -7.83253848],
                            [39.20428481, -7.86432337],
                            [39.23507984, -7.90441241],
                            [39.22084778, -7.94293628],
                            [39.25189184, -7.99147675],
                            [39.24214885, -8.01355803],
                            [39.25762587, -8.05456811],
                            [39.28404052, -8.06640059],
                            [39.29412638, -8.0845402],
                            [39.3191646, -8.10540945],
                            [39.29863607, -8.12001836],
                            [39.27505897, -8.10728574],
                            [39.28399253, -8.15951917],
                            [39.27224646, -8.19471822],
                            [39.25670955, -8.20975012],
                            [39.27335503, -8.25238297],
                            [39.25565586, -8.25076482],
                            [39.24110299, -8.27150529],
                            [39.2129523, -8.25900241],
                            [39.19155328, -8.26248367],
                            [39.17690381, -8.26703484],
                            [39.14354295, -8.29755979],
                            [39.11317075, -8.31967822],
                            [39.08703149, -8.33012046],
                            [39.04019206, -8.33118565],
                            [39.00540429, -8.3349597],
                            [38.98379953, -8.34971724],
                            [38.93882031, -8.37179828],
                            [38.83821485, -8.39836267],
                            [38.81040692, -8.41341328],
                            [38.77987014, -8.44330416],
                            [38.74443671, -8.49159485],
                            [38.71776699, -8.51251862],
                            [38.6731622, -8.50895304],
                            [38.62555016, -8.51129365],
                            [38.51624698, -8.50110924],
                            [38.51325437, -8.49662725],
                            [38.50997655, -8.41185173],
                            [38.51517185, -8.38313555],
                            [38.51035834, -8.35345914],
                            [38.48663141, -8.32406768],
                            [38.48250573, -8.31443995],
                            [38.42040949, -8.27913102],
                            [38.37702776, -8.26008919],
                            [38.30882448, -8.22191031],
                            [38.28669793, -8.20950825],
                            [38.12938055, -8.12524723],
                            [37.99893106, -8.08415009],
                            [37.89121607, -8.03259641],
                            [37.84270787, -8.00195606],
                            [37.8421, -7.99964588],
                            [37.83383296, -7.98007014],
                            [37.83979016, -7.96754656],
                            [37.80781192, -7.94533645],
                            [37.79705231, -7.91133498],
                            [37.80534627, -7.89070995],
                            [37.83687814, -7.87419504],
                            [37.84106251, -7.84886211],
                            [37.82948096, -7.83167453],
                            [37.82880853, -7.80185788],
                            [37.81789945, -7.78265265],
                            [37.82073897, -7.70291741],
                            [37.88335449, -7.62527469],
                            [37.89844799, -7.59956813],
                            [37.92093876, -7.57498252],
                            [37.98547586, -7.52662891],
                            [38.02551198, -7.55467593],
                            [38.0403641, -7.55739762],
                            [38.10014148, -7.63069803],
                            [38.12140494, -7.63120549],
                            [38.17098894, -7.61395305],
                            [38.30416064, -7.57888738],
                            [38.30659423, -7.45454843],
                            [38.3030328, -7.4202606],
                            [38.30564827, -7.37639992],
                            [38.3055135, -7.26820059],
                            [38.31471043, -7.24848864],
                            [38.34969144, -7.21120028],
                            [38.34919879, -7.20282269],
                            [38.38188055, -7.17604736],
                            [38.39584001, -7.18573914],
                            [38.42468878, -7.18752908],
                            [38.44145861, -7.1652834],
                            [38.45833761, -7.11972953],
                            [38.47915565, -7.10789853],
                            [38.48696249, -7.08411808],
                            [38.51574233, -7.03600261],
                            [38.5058374, -7.02397242],
                            [38.41810761, -7.02137733],
                            [38.38697771, -7.01194177],
                            [38.35325362, -7.01689525],
                            [38.32471788, -7.01217743],
                            [38.31693549, -6.98316344],
                            [38.29830475, -6.9706614],
                            [38.27990973, -6.98434272],
                            [38.26835404, -6.95603636],
                            [38.26290139, -6.91923249],
                            [38.26355402, -6.78445049],
                            [38.24615343, -6.70163458],
                            [38.21552918, -6.70630796],
                            [38.18695918, -6.69547648],
                            [38.17206798, -6.69684432],
                            [38.15107265, -6.6758655],
                            [38.08966938, -6.65575723],
                            [38.04573409, -6.65603576],
                            [38.03268568, -6.63666827],
                            [38.01586421, -6.62846433],
                            [38.01410384, -6.60896443],
                            [38.00091525, -6.60008315],
                            [38.00159088, -6.24762962],
                            [38.01047768, -6.22053958],
                            [38.05209007, -6.21437363],
                            [38.07785291, -6.20604253],
                            [38.05742482, -6.18244275],
                            [38.02938638, -6.18235882],
                            [38.01381958, -6.17226112],
                            [37.98740101, -6.17338791],
                            [37.96011405, -6.14427848],
                            [37.95138224, -6.12208256],
                            [37.90845074, -6.09151765],
                            [37.89062324, -6.0940647],
                            [37.86661075, -6.04348711],
                            [37.86661078, -6.01692479],
                            [37.84304196, -5.98855973],
                            [37.84543314, -5.97352784],
                            [37.86387931, -5.96737845],
                            [37.86456253, -5.94961349],
                            [37.84133406, -5.92091621],
                            [37.82049673, -5.9110088],
                            [37.81913039, -5.88401972],
                            [37.86524579, -5.89187736],
                            [37.87822644, -5.87718711],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Pwani", Region_Cod: 6, zoneId: 6 },
            drilldown: 6,
            region_nam: "Pwani",
            value: 3.8,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.18",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [32.77497011, -3.24966551],
                            [32.80406435, -3.24853125],
                            [32.8472395, -3.23330834],
                            [32.85838958, -3.26458886],
                            [32.88909581, -3.27162966],
                            [32.89879944, -3.28922301],
                            [32.91279984, -3.30169889],
                            [32.9194321, -3.33305964],
                            [32.96681001, -3.34340036],
                            [32.98123618, -3.36429828],
                            [32.99862745, -3.37156868],
                            [33.02223943, -3.38787839],
                            [33.05509825, -3.3901603],
                            [33.10154105, -3.43285299],
                            [33.12823067, -3.43455668],
                            [33.1457363, -3.4254289],
                            [33.15684117, -3.40613161],
                            [33.19555418, -3.40104964],
                            [33.21670611, -3.39026043],
                            [33.25134978, -3.39056015],
                            [33.28081064, -3.39729673],
                            [33.28822133, -3.39640289],
                            [33.31138711, -3.35739611],
                            [33.32380708, -3.3302484],
                            [33.34284699, -3.31182032],
                            [33.37994709, -3.30770182],
                            [33.41672319, -3.30952696],
                            [33.42305114, -3.30154239],
                            [33.43297966, -3.30698518],
                            [33.43657493, -3.30784651],
                            [33.45926945, -3.30438461],
                            [33.48322674, -3.29252299],
                            [33.52205751, -3.28304628],
                            [33.55711339, -3.28614526],
                            [33.5724783, -3.31471472],
                            [33.6057114, -3.3518723],
                            [33.61179438, -3.37045961],
                            [33.63629021, -3.38562469],
                            [33.66692819, -3.41717466],
                            [33.6690795, -3.4461097],
                            [33.68692587, -3.46087963],
                            [33.71326143, -3.47133188],
                            [33.72125432, -3.49310738],
                            [33.72381431, -3.53046551],
                            [33.76586226, -3.56431214],
                            [33.78359709, -3.55435362],
                            [33.7920009, -3.53600296],
                            [33.83145118, -3.5012796],
                            [33.85289365, -3.50923406],
                            [33.90596922, -3.50195489],
                            [33.92850934, -3.52481111],
                            [33.96387375, -3.53900106],
                            [33.98203778, -3.57084474],
                            [34.00026062, -3.57931062],
                            [34.01145983, -3.59984668],
                            [34.06014058, -3.59962477],
                            [34.05950757, -3.63889524],
                            [34.07815028, -3.65643614],
                            [34.07415046, -3.68496142],
                            [34.09240844, -3.71576132],
                            [34.08043026, -3.74154213],
                            [34.09902526, -3.76854193],
                            [34.10326941, -3.78525756],
                            [34.14046431, -3.82174997],
                            [34.16056852, -3.83407024],
                            [34.20107755, -3.89822717],
                            [34.21751894, -3.94718832],
                            [34.23357539, -3.96946371],
                            [34.20946227, -3.9925696],
                            [34.20153122, -4.07303114],
                            [34.20135153, -4.07321214],
                            [34.17373, -4.07706521],
                            [34.15604543, -4.06762605],
                            [34.15147562, -4.06423117],
                            [34.123776, -4.05332648],
                            [34.09119338, -4.02438447],
                            [34.06013354, -4.03270759],
                            [34.04924264, -4.03745434],
                            [33.99798912, -4.02257412],
                            [33.97381532, -4.01151851],
                            [33.94950921, -3.99534311],
                            [33.94445586, -3.97566011],
                            [33.92667952, -3.96109877],
                            [33.90213256, -3.96142579],
                            [33.86173302, -3.95053855],
                            [33.83795247, -3.95793473],
                            [33.78078112, -3.93802357],
                            [33.74913614, -3.93136114],
                            [33.72413994, -3.94341463],
                            [33.67903059, -3.93753712],
                            [33.65290079, -3.92311734],
                            [33.66115498, -3.8970939],
                            [33.63863459, -3.88745924],
                            [33.61503824, -3.88137802],
                            [33.60480156, -3.8785974],
                            [33.57210571, -3.88570005],
                            [33.53149384, -3.89424654],
                            [33.4959895, -3.90788434],
                            [33.46141966, -3.90081391],
                            [33.44329014, -3.91146994],
                            [33.41285407, -3.88697223],
                            [33.32972256, -3.8996734],
                            [33.30562393, -3.90586176],
                            [33.27267762, -3.91543827],
                            [33.2445366, -3.9340694],
                            [33.22945157, -3.93331188],
                            [33.21840066, -3.9536406],
                            [33.19503801, -3.94868662],
                            [33.17936052, -3.95939964],
                            [33.14212798, -3.96158314],
                            [33.12017593, -3.96198752],
                            [33.08568012, -3.96600072],
                            [33.06543785, -3.95872927],
                            [33.03858139, -3.9413781],
                            [33.02312909, -3.91869967],
                            [33.01269503, -3.90621499],
                            [32.98935567, -3.9069638],
                            [32.93331759, -3.91420861],
                            [32.90778868, -3.92481562],
                            [32.84937135, -3.91590112],
                            [32.83497737, -3.93537336],
                            [32.81243892, -3.9502012],
                            [32.75264906, -3.95106204],
                            [32.74224077, -3.94598711],
                            [32.7034329, -3.94501221],
                            [32.6998947, -4.00350015],
                            [32.68785195, -4.0416229],
                            [32.69290305, -4.07351503],
                            [32.67606604, -4.10167491],
                            [32.65922903, -4.12636919],
                            [32.63846338, -4.1297366],
                            [32.65393826, -4.15504671],
                            [32.67722974, -4.1701041],
                            [32.69722143, -4.19011806],
                            [32.73022605, -4.18738124],
                            [32.74500719, -4.22792912],
                            [32.7222489, -4.24649877],
                            [32.6725655, -4.25963445],
                            [32.66405995, -4.26611965],
                            [32.59007659, -4.28592471],
                            [32.592189, -4.25142566],
                            [32.58041836, -4.20269263],
                            [32.54779454, -4.19300255],
                            [32.53598605, -4.1739571],
                            [32.50224397, -4.19201004],
                            [32.50215703, -4.21484462],
                            [32.48688533, -4.22727507],
                            [32.44355634, -4.21129307],
                            [32.40664376, -4.21251106],
                            [32.41970498, -4.25111397],
                            [32.41231209, -4.3040633],
                            [32.39894367, -4.32336768],
                            [32.36578683, -4.30515048],
                            [32.35545237, -4.28605058],
                            [32.32446178, -4.26890757],
                            [32.28826529, -4.27517569],
                            [32.18744574, -4.25972177],
                            [32.14523355, -4.2445596],
                            [32.1076494, -4.2466326],
                            [32.08351716, -4.23634945],
                            [32.05495748, -4.21298164],
                            [32.00250732, -4.18771904],
                            [31.97932842, -4.16204878],
                            [31.95765833, -4.14138385],
                            [31.94795755, -4.13054148],
                            [31.88597939, -4.10177972],
                            [31.85704898, -4.09532736],
                            [31.79977801, -4.08767482],
                            [31.78447873, -4.08791637],
                            [31.72501581, -4.06825921],
                            [31.68846146, -4.05549799],
                            [31.68295186, -4.05311596],
                            [31.68403063, -4.04987976],
                            [31.69405578, -4.02914145],
                            [31.69679202, -3.99853575],
                            [31.70820237, -3.98371416],
                            [31.72417212, -3.97320999],
                            [31.75448902, -3.96654881],
                            [31.76892158, -3.95553225],
                            [31.79864068, -3.95613005],
                            [31.83262977, -3.94451568],
                            [31.83604576, -3.92683794],
                            [31.85944528, -3.88900586],
                            [31.86516706, -3.87073032],
                            [31.88281441, -3.82075723],
                            [31.87567123, -3.79096698],
                            [31.88737099, -3.78088981],
                            [31.92793586, -3.76833605],
                            [31.97759972, -3.74536459],
                            [32.00135298, -3.74417692],
                            [32.06175723, -3.77832782],
                            [32.09926375, -3.78218465],
                            [32.12503842, -3.77081265],
                            [32.15544606, -3.7433604],
                            [32.17422866, -3.76696966],
                            [32.1951516, -3.77030025],
                            [32.21927337, -3.73064553],
                            [32.30577692, -3.78661256],
                            [32.36637707, -3.81361118],
                            [32.37055525, -3.78572679],
                            [32.36927048, -3.71917539],
                            [32.39865914, -3.71061763],
                            [32.41403657, -3.66508579],
                            [32.4141814, -3.6500801],
                            [32.39381389, -3.59505643],
                            [32.34764323, -3.62029034],
                            [32.3160243, -3.62553576],
                            [32.31277992, -3.58947466],
                            [32.30238856, -3.56575453],
                            [32.32658423, -3.52905527],
                            [32.32985564, -3.46948383],
                            [32.32417538, -3.43783376],
                            [32.30810438, -3.411792],
                            [32.30784289, -3.39345527],
                            [32.31425548, -3.36956584],
                            [32.30385276, -3.33011141],
                            [32.31570032, -3.31256782],
                            [32.34659307, -3.32672313],
                            [32.35814039, -3.29328127],
                            [32.36641529, -3.27565632],
                            [32.37718821, -3.25734044],
                            [32.39848064, -3.26026723],
                            [32.42618472, -3.22720145],
                            [32.4561207, -3.22067665],
                            [32.49089456, -3.19712079],
                            [32.51191167, -3.19618322],
                            [32.52881293, -3.20571673],
                            [32.5283674, -3.22556951],
                            [32.54362556, -3.27893541],
                            [32.54290098, -3.30331873],
                            [32.52718186, -3.35032863],
                            [32.50276072, -3.38732199],
                            [32.58097904, -3.40932325],
                            [32.59529166, -3.42292813],
                            [32.66891112, -3.45099242],
                            [32.69367672, -3.44927793],
                            [32.69841943, -3.39119911],
                            [32.68240713, -3.35991576],
                            [32.68464687, -3.3233279],
                            [32.73909964, -3.30893196],
                            [32.75613652, -3.26991826],
                            [32.77497011, -3.24966551],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Shinyanga", Region_Cod: 17, zoneId: 3 },
            drilldown: 17,
            region_nam: "Shinyanga",
            value: 5.5,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.19",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [33.30476753, -2.17493016],
                            [33.30010369, -2.21374706],
                            [33.28846194, -2.22186257],
                            [33.26892601, -2.21692026],
                            [33.2725876, -2.19540011],
                            [33.30476753, -2.17493016],
                        ],
                    ],
                    [
                        [
                            [35.25606589, -1.68984348],
                            [35.23760984, -1.83975098],
                            [35.21052861, -2.06453686],
                            [35.23555541, -2.16293378],
                            [35.26582844, -2.30000364],
                            [35.26591682, -2.35533501],
                            [35.16019574, -2.39144831],
                            [35.20636943, -2.48506867],
                            [35.21749039, -2.49755518],
                            [35.12986561, -2.49755574],
                            [35.07761032, -2.49752348],
                            [34.98968891, -2.48563433],
                            [34.94393707, -2.48570287],
                            [34.90867382, -2.48575461],
                            [34.88300787, -2.48635294],
                            [34.79640737, -2.48624981],
                            [34.77509222, -2.4868401],
                            [34.74282742, -2.47812694],
                            [34.7091272, -2.48153902],
                            [34.65567907, -2.49103779],
                            [34.60801249, -2.48391192],
                            [34.56549568, -2.49047502],
                            [34.51883514, -2.48603897],
                            [34.48812686, -2.48941668],
                            [34.45137771, -2.44488034],
                            [34.41678292, -2.42785558],
                            [34.40090931, -2.4007735],
                            [34.36031725, -2.38824266],
                            [34.35052783, -2.37576945],
                            [34.32524914, -2.36531547],
                            [34.2949327, -2.31874707],
                            [34.27166756, -2.30248302],
                            [34.25411099, -2.31002265],
                            [34.2279377, -2.30657596],
                            [34.19960649, -2.29152591],
                            [34.17022062, -2.25854177],
                            [34.14760231, -2.25663509],
                            [34.12724129, -2.26793726],
                            [34.08726052, -2.25874168],
                            [34.04516595, -2.21247157],
                            [34.01180834, -2.19762237],
                            [33.9890408, -2.18187093],
                            [33.96928339, -2.18462593],
                            [33.94625707, -2.19026196],
                            [33.90659464, -2.17863264],
                            [33.88261641, -2.18865574],
                            [33.84701949, -2.19743887],
                            [33.83719122, -2.18411146],
                            [33.83170407, -2.12893998],
                            [33.81825464, -2.10948474],
                            [33.80540665, -2.11100986],
                            [33.79965109, -2.08138832],
                            [33.79097137, -2.06981593],
                            [33.76415991, -2.06589005],
                            [33.72702469, -2.08935827],
                            [33.71259135, -2.11335585],
                            [33.69280282, -2.11917823],
                            [33.66234036, -2.14180135],
                            [33.63348261, -2.15439889],
                            [33.62593703, -2.18045585],
                            [33.61135762, -2.18731763],
                            [33.57441747, -2.16786184],
                            [33.547156, -2.1663941],
                            [33.46361047, -2.15394435],
                            [33.41235577, -2.15360823],
                            [33.39392563, -2.16975255],
                            [33.37105662, -2.1635972],
                            [33.35932962, -2.17245073],
                            [33.33539753, -2.13511874],
                            [33.28615971, -2.10734851],
                            [33.27327892, -2.09497134],
                            [33.24982205, -2.09821478],
                            [33.21470381, -2.12225603],
                            [33.20560731, -2.09684374],
                            [33.23395183, -2.06885601],
                            [33.24044626, -2.05387231],
                            [33.26993768, -2.04166161],
                            [33.30571692, -2.07001408],
                            [33.29230008, -2.0330517],
                            [33.27252931, -1.99841214],
                            [33.29031093, -1.98719939],
                            [33.29767584, -2.02175266],
                            [33.31096704, -2.0449922],
                            [33.32869382, -2.04224247],
                            [33.32465755, -2.01876508],
                            [33.34100873, -2.01074014],
                            [33.37310913, -2.04641864],
                            [33.40502713, -2.03888109],
                            [33.38427706, -2.0122388],
                            [33.38150971, -1.97822507],
                            [33.41278135, -1.96934761],
                            [33.45184586, -1.99035824],
                            [33.46241376, -2.00760028],
                            [33.49278498, -2.0216374],
                            [33.51986559, -2.01144792],
                            [33.53377016, -2.02697194],
                            [33.56038381, -1.9980594],
                            [33.53490884, -1.98929574],
                            [33.53354141, -1.95927819],
                            [33.46772201, -1.9486415],
                            [33.48960758, -1.92996749],
                            [33.47230287, -1.90352831],
                            [33.45727636, -1.90337488],
                            [33.45116619, -1.88148259],
                            [33.42972675, -1.89928673],
                            [33.41423036, -1.89803045],
                            [33.35230916, -1.92392434],
                            [33.32589938, -1.94829481],
                            [33.29957231, -1.94688212],
                            [33.29137919, -1.92048047],
                            [33.31717385, -1.90160388],
                            [33.31690677, -1.88538998],
                            [33.33195257, -1.83125212],
                            [33.37358996, -1.79660113],
                            [33.39824213, -1.7993425],
                            [33.4232482, -1.80961442],
                            [33.44218819, -1.79742507],
                            [33.44410536, -1.83773751],
                            [33.46091213, -1.84417144],
                            [33.49479636, -1.8414469],
                            [33.53701247, -1.81780115],
                            [33.56468739, -1.79484815],
                            [33.56913723, -1.76401266],
                            [33.59862887, -1.76143402],
                            [33.61765021, -1.77781825],
                            [33.64876212, -1.78387181],
                            [33.65637098, -1.77453799],
                            [33.64190793, -1.74816637],
                            [33.59698589, -1.7335199],
                            [33.58524149, -1.71285079],
                            [33.56763725, -1.70819883],
                            [33.56815416, -1.68287195],
                            [33.60189989, -1.68213181],
                            [33.62738124, -1.66628046],
                            [33.6999522, -1.68221234],
                            [33.7118385, -1.65541788],
                            [33.69598528, -1.6377759],
                            [33.69541094, -1.60342451],
                            [33.67444226, -1.59190111],
                            [33.64846729, -1.58775707],
                            [33.64555475, -1.56916641],
                            [33.6777129, -1.55717082],
                            [33.69382782, -1.51754108],
                            [33.66822841, -1.50739002],
                            [33.66231997, -1.48946244],
                            [33.67777593, -1.46711176],
                            [33.70680091, -1.48818666],
                            [33.72783795, -1.49540051],
                            [33.75940531, -1.48123957],
                            [33.7758988, -1.49390263],
                            [33.81687021, -1.49626924],
                            [33.82872778, -1.52216495],
                            [33.85755809, -1.5302287],
                            [33.88493339, -1.52351737],
                            [33.91634843, -1.53680996],
                            [33.93452905, -1.51670188],
                            [33.93972186, -1.49530877],
                            [33.92646751, -1.49062509],
                            [33.89504979, -1.50294091],
                            [33.89132834, -1.48299377],
                            [33.86161196, -1.47324573],
                            [33.85255711, -1.43783661],
                            [33.83054016, -1.42956859],
                            [33.80423838, -1.44006345],
                            [33.78287617, -1.43080546],
                            [33.77864093, -1.40297746],
                            [33.8058918, -1.38994382],
                            [33.82733485, -1.40793589],
                            [33.84300173, -1.41080253],
                            [33.85173222, -1.39673099],
                            [33.81620035, -1.37558235],
                            [33.79400864, -1.34237841],
                            [33.79903493, -1.32970426],
                            [33.82775316, -1.33283849],
                            [33.84793968, -1.32155027],
                            [33.89840879, -1.32373022],
                            [33.92544275, -1.33326253],
                            [33.95725553, -1.33599055],
                            [33.95704046, -1.31570992],
                            [33.93685394, -1.28668739],
                            [33.89650376, -1.29048556],
                            [33.88935298, -1.27225178],
                            [33.87022656, -1.26976841],
                            [33.86643491, -1.25402322],
                            [33.87879066, -1.21669782],
                            [33.92099968, -1.19548465],
                            [33.9569802, -1.18758531],
                            [33.9858395, -1.16743365],
                            [34.00813186, -1.13898024],
                            [34.00148006, -1.12325061],
                            [33.97445309, -1.12580532],
                            [33.97722374, -1.10530121],
                            [34.00731404, -1.09076929],
                            [34.04411164, -1.09472091],
                            [34.05274096, -1.0755644],
                            [34.07515307, -1.06265937],
                            [34.09391708, -1.02704594],
                            [34.27582222, -1.12992349],
                            [34.55833919, -1.28916366],
                            [34.78302562, -1.41629943],
                            [34.9492111, -1.52030766],
                            [35.0304811, -1.58184315],
                            [35.18076311, -1.661],
                            [35.25606589, -1.68984348],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Mara", Region_Cod: 20, zoneId: 3 },
            drilldown: 20,
            region_nam: "Mara",
            value: 6.1,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.20",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [35.38024963, -9.0130466],
                            [35.38400215, -9.01061362],
                            [35.46439391, -9.03711268],
                            [35.52316866, -9.06680706],
                            [35.50776757, -9.11851504],
                            [35.49997865, -9.17670656],
                            [35.49990152, -9.36677907],
                            [35.5046299, -9.38173943],
                            [35.5020485, -9.45822497],
                            [35.49586458, -9.49232644],
                            [35.47289689, -9.5058147],
                            [35.44802682, -9.52882503],
                            [35.42513045, -9.55894965],
                            [35.38385979, -9.59442008],
                            [35.36771779, -9.61840612],
                            [35.33255092, -9.6327398],
                            [35.26826493, -9.66652118],
                            [35.23465126, -9.68803286],
                            [35.22746602, -9.69575274],
                            [35.19624058, -9.72182198],
                            [35.17143204, -9.74066343],
                            [35.14438558, -9.75545179],
                            [35.1056253, -9.77437928],
                            [35.1477681, -9.81040078],
                            [35.15379738, -9.82691179],
                            [35.17332476, -9.83249927],
                            [35.17858953, -9.8577927],
                            [35.16869493, -9.88309843],
                            [35.16666747, -9.91024372],
                            [35.19838438, -9.94815142],
                            [35.21794895, -9.98422268],
                            [35.21612227, -10.01159766],
                            [35.19566075, -10.06383863],
                            [35.18027784, -10.09287951],
                            [35.17273393, -10.12022121],
                            [35.14828071, -10.16115499],
                            [35.13251833, -10.15521686],
                            [35.10865525, -10.17436483],
                            [35.07676115, -10.16731132],
                            [35.06265414, -10.15443101],
                            [35.04793379, -10.16577795],
                            [35.03168006, -10.17099141],
                            [35.00930138, -10.18314763],
                            [34.993891, -10.18889777],
                            [34.98170071, -10.2024681],
                            [34.97986066, -10.2273087],
                            [35.01129236, -10.26644917],
                            [34.99382631, -10.26780764],
                            [35.00127816, -10.29265702],
                            [34.99625215, -10.30805502],
                            [34.96660597, -10.32200585],
                            [34.95740575, -10.33611286],
                            [34.9319518, -10.37966058],
                            [34.89913767, -10.41094133],
                            [34.87245703, -10.43976869],
                            [34.85927004, -10.46399594],
                            [34.81750081, -10.48822402],
                            [34.81447815, -10.45530752],
                            [34.80228286, -10.44454595],
                            [34.77734914, -10.4449068],
                            [34.76502489, -10.49006687],
                            [34.71910386, -10.50978439],
                            [34.70558936, -10.5084606],
                            [34.6978136, -10.52496426],
                            [34.68133186, -10.53428059],
                            [34.63340886, -10.53439795],
                            [34.61038138, -10.52626824],
                            [34.57943475, -10.53334154],
                            [34.57213821, -10.5120983],
                            [34.59328752, -10.4894666],
                            [34.58796619, -10.45467504],
                            [34.57027781, -10.45051412],
                            [34.55949334, -10.4304277],
                            [34.55955645, -10.40190363],
                            [34.5838041, -10.30776147],
                            [34.58108599, -10.28420649],
                            [34.56798305, -10.26392145],
                            [34.56814364, -10.20263614],
                            [34.52912756, -10.13552883],
                            [34.51996263, -10.1120771],
                            [34.53645036, -10.09471017],
                            [34.53944013, -10.06407581],
                            [34.50455413, -10.01450012],
                            [34.50615631, -10.00270812],
                            [34.49113404, -9.97730992],
                            [34.45741493, -9.94920902],
                            [34.45194525, -9.9352968],
                            [34.41621543, -9.88420936],
                            [34.3881993, -9.85395091],
                            [34.3719143, -9.82796572],
                            [34.33561219, -9.7857984],
                            [34.32679522, -9.75684621],
                            [34.29556165, -9.72515892],
                            [34.23542611, -9.6716317],
                            [34.21864057, -9.65242745],
                            [34.14229128, -9.58437153],
                            [34.07315325, -9.52978281],
                            [34.08974883, -9.50757448],
                            [34.06738686, -9.49662055],
                            [34.04212786, -9.46108402],
                            [34.00981582, -9.40823634],
                            [33.99467763, -9.40009235],
                            [33.98060211, -9.37870901],
                            [33.98195275, -9.32807636],
                            [33.96549503, -9.299326],
                            [33.94833357, -9.28725011],
                            [33.9213752, -9.25037779],
                            [33.87085254, -9.23445355],
                            [33.83775952, -9.19483502],
                            [33.84837516, -9.16821758],
                            [33.84926803, -9.11125894],
                            [33.82741027, -9.08848162],
                            [33.81097343, -9.05801056],
                            [33.82821346, -9.02967077],
                            [33.82519587, -8.96036824],
                            [33.81813388, -8.9085769],
                            [33.82826196, -8.91002077],
                            [33.84539084, -8.84397036],
                            [33.89992871, -8.83961641],
                            [33.90098336, -8.87470564],
                            [33.99201144, -8.86682815],
                            [33.99304002, -8.88628509],
                            [34.03855976, -8.89875602],
                            [34.05535785, -8.87741398],
                            [34.05585011, -8.84251046],
                            [34.12348653, -8.83908474],
                            [34.15818776, -8.85861266],
                            [34.18027027, -8.90781215],
                            [34.19998023, -8.93781866],
                            [34.23651696, -8.93608946],
                            [34.28543966, -8.91955366],
                            [34.29202358, -8.83192256],
                            [34.29555981, -8.82357288],
                            [34.39163157, -8.82850077],
                            [34.41879998, -8.82529059],
                            [34.44629126, -8.81277558],
                            [34.51133473, -8.810171],
                            [34.5456673, -8.78738045],
                            [34.55596485, -8.74648331],
                            [34.59201736, -8.68928298],
                            [34.67415249, -8.6649537],
                            [34.69919951, -8.66420072],
                            [34.7335141, -8.67310077],
                            [34.74175406, -8.66898079],
                            [34.81998858, -8.67858285],
                            [34.85965757, -8.68613533],
                            [34.87526501, -8.69705668],
                            [34.89381111, -8.7258721],
                            [34.87376114, -8.73558122],
                            [34.85401787, -8.76907444],
                            [34.90285791, -8.80801208],
                            [34.9245589, -8.80760455],
                            [34.94915113, -8.82573498],
                            [34.97978536, -8.8269926],
                            [35.02646739, -8.80553293],
                            [35.04252713, -8.77609922],
                            [35.06779517, -8.77396158],
                            [35.09328246, -8.78207366],
                            [35.12737513, -8.77714064],
                            [35.1296224, -8.79901038],
                            [35.14063949, -8.81397388],
                            [35.14338006, -8.84379127],
                            [35.12905906, -8.87958919],
                            [35.14013096, -8.90140412],
                            [35.11371496, -8.92891206],
                            [35.11030444, -8.94094139],
                            [35.12840191, -8.97286965],
                            [35.12069701, -9.01911534],
                            [35.13920468, -9.02997711],
                            [35.20421048, -9.04048288],
                            [35.26248831, -9.03353692],
                            [35.30638049, -9.03760537],
                            [35.31642496, -9.02964663],
                            [35.35531724, -9.02859618],
                            [35.38024963, -9.0130466],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Njombe", Region_Cod: 22, zoneId: 5 },
            drilldown: 22,
            region_nam: "Njombe",
            value: 4,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.21",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [37.06049722, -3.48858684],
                            [37.05839063, -3.45898951],
                            [37.05582762, -3.44926419],
                            [37.05421257, -3.37107524],
                            [37.06323585, -3.36742129],
                            [37.0568426, -3.33046608],
                            [37.04561995, -3.28496974],
                            [37.03045931, -3.25655257],
                            [36.99771463, -3.24401729],
                            [36.98007289, -3.22843834],
                            [36.97948146, -3.20727692],
                            [36.95244907, -3.20586328],
                            [36.93968297, -3.19615528],
                            [36.92408957, -3.18576932],
                            [36.90839521, -3.18416816],
                            [36.9081067, -3.16433383],
                            [36.88975813, -3.1325845],
                            [36.90859713, -3.10700903],
                            [36.91635776, -3.07521641],
                            [36.93451877, -3.04016373],
                            [36.93169146, -2.99469625],
                            [36.94383728, -2.97813639],
                            [36.97939482, -2.97266931],
                            [36.99204551, -2.97804982],
                            [37.03396446, -2.96148995],
                            [37.03660423, -2.94813243],
                            [37.06296267, -2.93589341],
                            [37.06995029, -2.91842434],
                            [37.09636829, -2.88331748],
                            [37.12577397, -2.85786254],
                            [37.25074846, -2.84977287],
                            [37.38260584, -2.88291668],
                            [37.40888754, -2.9031117],
                            [37.43235623, -2.90789239],
                            [37.54811665, -2.97525053],
                            [37.67068652, -3.05880407],
                            [37.69313931, -3.19020772],
                            [37.71154324, -3.30928078],
                            [37.68485762, -3.32142736],
                            [37.68485763, -3.34903317],
                            [37.66350913, -3.3567628],
                            [37.6119569, -3.40737195],
                            [37.58468615, -3.43829957],
                            [37.58468616, -3.45991749],
                            [37.61717937, -3.45925434],
                            [37.62526952, -3.48485102],
                            [37.60658655, -3.50377715],
                            [37.60721212, -3.52186862],
                            [37.64323174, -3.51689352],
                            [37.66392809, -3.50356028],
                            [37.68422644, -3.51171941],
                            [37.69278359, -3.52485363],
                            [37.71825603, -3.52903269],
                            [37.74333047, -3.5419679],
                            [37.76724921, -3.63112357],
                            [37.78503534, -3.67495069],
                            [37.9532704, -3.79381713],
                            [38.02768044, -3.84415326],
                            [38.24728557, -3.99858196],
                            [38.40367801, -4.10901599],
                            [38.2741183, -4.31218851],
                            [38.17905169, -4.45353188],
                            [38.1202895, -4.53087259],
                            [38.11870534, -4.57283266],
                            [38.10895664, -4.59629051],
                            [38.07894888, -4.6128938],
                            [38.04985507, -4.61396007],
                            [38.03744037, -4.5937194],
                            [38.00562897, -4.59187928],
                            [37.9892142, -4.60435019],
                            [37.95503108, -4.59502618],
                            [37.93862447, -4.59927699],
                            [37.92945634, -4.5743886],
                            [37.87549107, -4.58664187],
                            [37.8616648, -4.57534209],
                            [37.82678354, -4.57762304],
                            [37.81065636, -4.59543796],
                            [37.78421529, -4.60612691],
                            [37.71846126, -4.56449046],
                            [37.69101059, -4.55076686],
                            [37.68502309, -4.53183865],
                            [37.65663073, -4.51561447],
                            [37.63005004, -4.52069521],
                            [37.61705999, -4.50380817],
                            [37.59237892, -4.49406565],
                            [37.56965283, -4.46851427],
                            [37.55344127, -4.46685156],
                            [37.51395153, -4.4254913],
                            [37.49150472, -4.3783115],
                            [37.49129686, -4.35087655],
                            [37.50584571, -4.33986099],
                            [37.49360234, -4.31045541],
                            [37.5119182, -4.28072609],
                            [37.49470369, -4.22581883],
                            [37.49569702, -4.1734189],
                            [37.51283255, -4.13914784],
                            [37.51283253, -4.10363509],
                            [37.50215385, -4.06166548],
                            [37.49023347, -4.0502418],
                            [37.49257206, -4.02950934],
                            [37.47003903, -4.00959538],
                            [37.46472176, -3.96767753],
                            [37.46623701, -3.94373651],
                            [37.45887147, -3.92734004],
                            [37.46751687, -3.91355027],
                            [37.46306362, -3.8875894],
                            [37.44948424, -3.88348629],
                            [37.46100341, -3.82978283],
                            [37.46231813, -3.80137316],
                            [37.42748028, -3.74817228],
                            [37.41114428, -3.70062867],
                            [37.41016771, -3.66162721],
                            [37.43037033, -3.6279358],
                            [37.45899581, -3.59802856],
                            [37.43150701, -3.59851736],
                            [37.40964913, -3.60993904],
                            [37.39928575, -3.62487698],
                            [37.39903868, -3.64459636],
                            [37.36647344, -3.64658257],
                            [37.34145224, -3.62860652],
                            [37.32793639, -3.58863346],
                            [37.31200459, -3.57374916],
                            [37.30906489, -3.55193798],
                            [37.29718315, -3.53009397],
                            [37.30007863, -3.50001739],
                            [37.27624085, -3.49089053],
                            [37.26135893, -3.4956789],
                            [37.22298419, -3.4808978],
                            [37.21115227, -3.45200265],
                            [37.18285397, -3.4583926],
                            [37.1638597, -3.45256443],
                            [37.13759777, -3.48532164],
                            [37.10045188, -3.47749221],
                            [37.08840744, -3.48795526],
                            [37.06049722, -3.48858684],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Kilimanjaro", Region_Cod: 3, zoneId: 2 },
            drilldown: 3,
            region_nam: "Kilimanjaro",
            value: 4.4,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.22",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [36.29618914, -4.42285428],
                            [36.24335793, -4.41222158],
                            [36.14068596, -4.39992753],
                            [36.10147789, -4.40158889],
                            [36.04897896, -4.39527572],
                            [35.99846197, -4.39326652],
                            [35.9758584, -4.37875818],
                            [35.90216027, -4.34784912],
                            [35.86496049, -4.34246908],
                            [35.84160374, -4.33218432],
                            [35.81773858, -4.31273474],
                            [35.81132547, -4.36067533],
                            [35.81584619, -4.38916633],
                            [35.76356506, -4.40447213],
                            [35.7449215, -4.43250756],
                            [35.71460324, -4.45151012],
                            [35.67360143, -4.48326024],
                            [35.66161628, -4.48736043],
                            [35.65225946, -4.51164612],
                            [35.65846229, -4.53551128],
                            [35.65415185, -4.55685325],
                            [35.66308814, -4.57356937],
                            [35.64405909, -4.62224589],
                            [35.62135039, -4.64505972],
                            [35.60663179, -4.67617904],
                            [35.59444952, -4.70097397],
                            [35.53797371, -4.73822994],
                            [35.51061568, -4.79868868],
                            [35.50592041, -4.81720708],
                            [35.48723532, -4.83950219],
                            [35.43602682, -4.85866722],
                            [35.41790771, -4.86621094],
                            [35.40252685, -4.85968018],
                            [35.37609863, -4.86431885],
                            [35.34869385, -4.85760498],
                            [35.34036729, -4.876764],
                            [35.3390285, -4.87569721],
                            [35.31793635, -4.8622906],
                            [35.28341993, -4.80410311],
                            [35.21889373, -4.77443135],
                            [35.17353122, -4.76336945],
                            [35.1529351, -4.75157723],
                            [35.14543133, -4.73308407],
                            [35.14545797, -4.68027553],
                            [35.12771885, -4.62855557],
                            [35.08993295, -4.62396361],
                            [35.07298481, -4.61615181],
                            [35.04294781, -4.5467726],
                            [35.03198419, -4.52970217],
                            [35.0007683, -4.50560627],
                            [34.98499706, -4.48213433],
                            [34.96815353, -4.45505383],
                            [34.89927713, -4.42223054],
                            [34.87476763, -4.41121992],
                            [34.86630749, -4.39178028],
                            [34.86568543, -4.35968154],
                            [34.88177449, -4.32706502],
                            [34.89765377, -4.29601984],
                            [34.91073934, -4.26960942],
                            [34.95636597, -4.23678305],
                            [34.9675393, -4.21297778],
                            [34.96898825, -4.18941327],
                            [34.96418384, -4.15761263],
                            [34.969826, -4.14226644],
                            [34.96593169, -4.09003688],
                            [34.98484066, -4.01723734],
                            [34.98663174, -3.98871689],
                            [34.95407956, -3.99140491],
                            [34.93610949, -3.99230341],
                            [34.9106519, -3.96556225],
                            [34.89867186, -3.93000719],
                            [34.89139395, -3.90639787],
                            [34.98304516, -3.86090906],
                            [35.00798645, -3.87159523],
                            [35.04142709, -3.85658714],
                            [35.07105615, -3.83419412],
                            [35.107954, -3.81936669],
                            [35.13999507, -3.80125651],
                            [35.18064135, -3.7719355],
                            [35.20407722, -3.75064091],
                            [35.23611829, -3.73345946],
                            [35.25290979, -3.71726875],
                            [35.26868529, -3.70466395],
                            [35.29210323, -3.68728157],
                            [35.32855795, -3.68848868],
                            [35.3345935, -3.67955606],
                            [35.36235702, -3.64623983],
                            [35.38522913, -3.61479287],
                            [35.40929817, -3.62451105],
                            [35.46479363, -3.65929074],
                            [35.5229241, -3.67629998],
                            [35.54392004, -3.6907682],
                            [35.60528085, -3.69040762],
                            [35.6240634, -3.69545509],
                            [35.60493812, -3.74427376],
                            [35.59123076, -3.77293785],
                            [35.6633883, -3.76326156],
                            [35.67933516, -3.75825023],
                            [35.69772636, -3.69833092],
                            [35.71262372, -3.62912502],
                            [35.71444305, -3.58050966],
                            [35.71474128, -3.57098922],
                            [35.7147691, -3.56725219],
                            [35.71520192, -3.55455064],
                            [35.74244601, -3.56994353],
                            [35.7629189, -3.55754528],
                            [35.77591887, -3.55941674],
                            [35.77643703, -3.56666291],
                            [35.73841928, -3.61051537],
                            [35.75819588, -3.66032537],
                            [35.75819588, -3.67764525],
                            [35.74505243, -3.68722646],
                            [35.74511385, -3.72518278],
                            [35.73651533, -3.7489147],
                            [35.74695639, -3.76301627],
                            [35.74990445, -3.79648908],
                            [35.7687573, -3.78935643],
                            [35.80738004, -3.76006943],
                            [35.83986151, -3.70877714],
                            [35.87025071, -3.67058297],
                            [35.88095373, -3.64118617],
                            [35.94169956, -3.63486675],
                            [35.98403517, -3.66034328],
                            [36.00004356, -3.68651863],
                            [36.14839909, -3.86873473],
                            [36.2797412, -4.14585174],
                            [36.29508127, -4.14362006],
                            [36.29696188, -4.07355256],
                            [36.29514655, -4.03807127],
                            [36.29795203, -3.9605075],
                            [36.29283631, -3.94565477],
                            [36.29382628, -3.88987496],
                            [36.29085575, -3.85703414],
                            [36.29778697, -3.80323458],
                            [36.3942697, -3.79583482],
                            [36.42103891, -3.78788321],
                            [36.48100423, -3.77963531],
                            [36.4933494, -3.7608297],
                            [36.52846575, -3.7367032],
                            [36.55189102, -3.7092979],
                            [36.56246652, -3.71430135],
                            [36.58514575, -3.69511416],
                            [36.61013711, -3.68292325],
                            [36.61696401, -3.64866681],
                            [36.63644794, -3.63385127],
                            [36.6494036, -3.63450587],
                            [36.67100591, -3.60958357],
                            [36.68763923, -3.61405503],
                            [36.69083775, -3.59481442],
                            [36.71376003, -3.5601282],
                            [36.72699075, -3.55636336],
                            [36.74527711, -3.57217567],
                            [36.78367848, -3.61799912],
                            [36.80067405, -3.65263562],
                            [36.83595597, -3.64693457],
                            [36.86069632, -3.58691229],
                            [36.86532169, -3.58400799],
                            [36.88393075, -3.58217935],
                            [36.89262122, -3.59585254],
                            [36.9159883, -3.572328],
                            [36.96537228, -3.57064278],
                            [36.98243852, -3.53850099],
                            [37.02697412, -3.49770229],
                            [37.0604354, -3.49081114],
                            [37.08833611, -3.48794364],
                            [37.10038055, -3.47748059],
                            [37.13752645, -3.48531002],
                            [37.16378838, -3.45255281],
                            [37.18278264, -3.45838097],
                            [37.21108094, -3.45199103],
                            [37.22291286, -3.48088618],
                            [37.26128761, -3.49566729],
                            [37.27724544, -3.49127016],
                            [37.30000731, -3.50000578],
                            [37.29711183, -3.53008236],
                            [37.30899357, -3.55192638],
                            [37.31193327, -3.57373756],
                            [37.32786507, -3.58862186],
                            [37.34138091, -3.62859493],
                            [37.35347759, -3.64033681],
                            [37.40842694, -3.67319192],
                            [37.41107296, -3.70061709],
                            [37.42740895, -3.74816071],
                            [37.4622468, -3.8013616],
                            [37.46096131, -3.82966895],
                            [37.4494129, -3.88347473],
                            [37.46299228, -3.88757785],
                            [37.46744552, -3.91353872],
                            [37.45880012, -3.92732849],
                            [37.46616567, -3.94372496],
                            [37.46465041, -3.96766599],
                            [37.46996768, -4.00958384],
                            [37.49250071, -4.0294978],
                            [37.49016212, -4.05023027],
                            [37.50208249, -4.06165395],
                            [37.51276117, -4.10362356],
                            [37.51276119, -4.13913632],
                            [37.49562566, -4.17340738],
                            [37.49463232, -4.22580731],
                            [37.50115568, -4.25532312],
                            [37.51285523, -4.27823281],
                            [37.49598848, -4.31985043],
                            [37.50652214, -4.3389144],
                            [37.49094784, -4.35319623],
                            [37.49833223, -4.39848806],
                            [37.53511169, -4.45061508],
                            [37.55285321, -4.46741641],
                            [37.57021543, -4.46841706],
                            [37.59195984, -4.49408806],
                            [37.6136259, -4.50122306],
                            [37.62830716, -4.51946017],
                            [37.64446754, -4.51476568],
                            [37.68491575, -4.53258384],
                            [37.69457844, -4.55508613],
                            [37.71744896, -4.56366706],
                            [37.71953726, -4.57593578],
                            [37.74190379, -4.58015494],
                            [37.75450316, -4.60447463],
                            [37.77664409, -4.60510819],
                            [37.8069921, -4.5973474],
                            [37.82614571, -4.57770446],
                            [37.84264298, -4.57057428],
                            [37.87223458, -4.58453385],
                            [37.92967357, -4.57418525],
                            [37.93364307, -4.59740302],
                            [37.96682202, -4.59605487],
                            [37.99782898, -4.61200772],
                            [37.99361633, -4.70825015],
                            [37.98147386, -4.75185273],
                            [37.99527215, -4.7696985],
                            [37.99766386, -4.79471939],
                            [37.99398433, -4.83372253],
                            [37.99968764, -4.86591852],
                            [37.99453629, -4.91688018],
                            [38.00005564, -4.99010306],
                            [37.98015736, -4.98916019],
                            [37.94383335, -4.96111519],
                            [37.91071747, -4.96535818],
                            [37.89985132, -4.9756034],
                            [37.846038, -4.97891501],
                            [37.82689289, -5.00540772],
                            [37.82461617, -5.02051684],
                            [37.78767126, -5.0225866],
                            [37.7652936, -5.0094944],
                            [37.73535122, -5.0165022],
                            [37.68556861, -5.03943349],
                            [37.62912901, -5.01355672],
                            [37.58429658, -5.00517316],
                            [37.48988745, -5.05930108],
                            [37.45086502, -5.1121702],
                            [37.35897184, -5.21641096],
                            [37.38666518, -5.25291582],
                            [37.38666518, -5.26172734],
                            [37.33001971, -5.44928677],
                            [37.26981008, -5.52328298],
                            [37.25070035, -5.53639672],
                            [37.23745632, -5.57065971],
                            [37.25550961, -5.58461826],
                            [37.24405199, -5.61380235],
                            [37.22353594, -5.62770947],
                            [37.22204221, -5.64236943],
                            [37.18394734, -5.6830501],
                            [37.16526292, -5.71603419],
                            [37.20541115, -5.71469368],
                            [37.20131532, -5.74855173],
                            [37.14745672, -5.74122678],
                            [37.1162983, -5.77151955],
                            [37.10261965, -5.76859849],
                            [37.08568448, -5.78640675],
                            [37.06124602, -5.82371164],
                            [37.05007126, -5.82782266],
                            [37.01973902, -5.87850581],
                            [37.0218253, -5.89828195],
                            [36.96398607, -5.92518519],
                            [36.92373895, -5.92682678],
                            [36.87863449, -5.94746383],
                            [36.85403825, -5.95130914],
                            [36.82902364, -5.93921875],
                            [36.78888682, -5.93606859],
                            [36.73653009, -5.93849473],
                            [36.71478771, -5.94359301],
                            [36.69708485, -5.89502762],
                            [36.66612928, -5.8483337],
                            [36.64372036, -5.82623746],
                            [36.5998723, -5.79735504],
                            [36.57781146, -5.74359776],
                            [36.53359131, -5.72631228],
                            [36.51385833, -5.69097213],
                            [36.46324285, -5.67148973],
                            [36.44143324, -5.655924],
                            [36.44029, -5.63631294],
                            [36.42027062, -5.62188185],
                            [36.41002226, -5.59917355],
                            [36.34050283, -5.54257811],
                            [36.30412516, -5.51631862],
                            [36.26954584, -5.48258863],
                            [36.25781237, -5.46216371],
                            [36.24640884, -5.40868506],
                            [36.29813554, -5.41563194],
                            [36.29317878, -5.30013955],
                            [36.27539417, -5.24356163],
                            [36.27775286, -5.22148313],
                            [36.29992805, -5.20531686],
                            [36.26955207, -5.19699896],
                            [36.29649311, -5.15780564],
                            [36.32286126, -5.0788709],
                            [36.31663487, -5.05422956],
                            [36.31298691, -5.00836945],
                            [36.32132511, -4.99012963],
                            [36.32813294, -4.91250842],
                            [36.33721463, -4.88746793],
                            [36.33657158, -4.8454127],
                            [36.30802033, -4.81248873],
                            [36.30328547, -4.74640781],
                            [36.29266212, -4.69705665],
                            [36.296273, -4.66688831],
                            [36.28998308, -4.64254397],
                            [36.2873558, -4.58730652],
                            [36.29620829, -4.57076635],
                            [36.30781974, -4.52044889],
                            [36.32675923, -4.5001803],
                            [36.32122093, -4.47719125],
                            [36.34536644, -4.43538731],
                            [36.29618914, -4.42285428],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Manyara", Region_Cod: 21, zoneId: 2 },
            drilldown: 21,
            region_nam: "Manyara",
            value: 5.8,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.23",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [30.68848362, -5.13390475],
                            [30.70632741, -5.14337348],
                            [30.71363212, -5.1769029],
                            [30.74392024, -5.20414772],
                            [30.76519631, -5.2385116],
                            [30.78231662, -5.24634261],
                            [30.80307612, -5.25480099],
                            [30.82478395, -5.26565799],
                            [30.8259087, -5.28578628],
                            [30.83724929, -5.32320001],
                            [30.86354658, -5.32089599],
                            [30.8779231, -5.31016442],
                            [30.89966407, -5.31239506],
                            [30.91253849, -5.29634662],
                            [30.94195587, -5.29264934],
                            [30.97477313, -5.28852296],
                            [30.98505065, -5.27631887],
                            [31.00040844, -5.25970202],
                            [31.02010133, -5.24525583],
                            [31.04445858, -5.25119783],
                            [31.05771611, -5.25221678],
                            [31.129432, -5.2688595],
                            [31.18192195, -5.280221],
                            [31.18616629, -5.36560804],
                            [31.18239687, -5.39563605],
                            [31.18412699, -5.43253148],
                            [31.15988878, -5.44932195],
                            [31.13066391, -5.46293355],
                            [31.1030496, -5.46574531],
                            [31.06614314, -5.49671857],
                            [31.07054127, -5.51886548],
                            [31.05061123, -5.5360523],
                            [31.03968528, -5.55806822],
                            [31.0401366, -5.55939397],
                            [31.05545702, -5.58560402],
                            [31.06770601, -5.59463189],
                            [31.10598803, -5.61620699],
                            [31.12615852, -5.64048977],
                            [31.14243363, -5.65031476],
                            [31.138201, -5.66385777],
                            [31.1575574, -5.68313479],
                            [31.14281863, -5.69197484],
                            [31.15433422, -5.71227412],
                            [31.15798729, -5.7432795],
                            [31.16890472, -5.76212168],
                            [31.2026505, -5.73825052],
                            [31.21155697, -5.75013608],
                            [31.23232903, -5.75309937],
                            [31.24470793, -5.74813095],
                            [31.25301683, -5.78373011],
                            [31.28225149, -5.80508307],
                            [31.29637027, -5.8017809],
                            [31.30931869, -5.78258307],
                            [31.34637701, -5.79507774],
                            [31.36768958, -5.794349],
                            [31.37890489, -5.79257754],
                            [31.40316196, -5.81052533],
                            [31.41785159, -5.82637846],
                            [31.44401919, -5.82637344],
                            [31.48678891, -5.8263043],
                            [31.50600937, -5.82161187],
                            [31.5520985, -5.8051287],
                            [31.57599807, -5.82284717],
                            [31.59336472, -5.82289077],
                            [31.61412832, -5.83269365],
                            [31.61648624, -5.8369163],
                            [31.63520881, -5.82062163],
                            [31.66124378, -5.82727298],
                            [31.69181225, -5.80836838],
                            [31.70164395, -5.78487619],
                            [31.73057239, -5.773139],
                            [31.73850162, -5.75707909],
                            [31.76745886, -5.75055319],
                            [31.77698221, -5.72658875],
                            [31.81744603, -5.71433316],
                            [31.8359151, -5.72855652],
                            [31.87247883, -5.73337407],
                            [31.88746492, -5.73946575],
                            [31.89982568, -5.74265265],
                            [31.91587641, -5.74268332],
                            [31.91928464, -5.74927936],
                            [31.95644855, -5.75087094],
                            [31.96848683, -5.74327858],
                            [31.99559249, -5.75832219],
                            [32.02505726, -5.76259081],
                            [32.02843845, -5.79012875],
                            [32.05323059, -5.83083712],
                            [32.07343059, -5.83513712],
                            [32.08883059, -5.86833712],
                            [32.08353059, -5.89063712],
                            [32.0871023, -5.91308894],
                            [32.09233059, -5.92573712],
                            [32.09143059, -5.94903712],
                            [32.11193059, -5.95433712],
                            [32.1142193, -5.99307257],
                            [32.13227123, -6.00665944],
                            [32.13591424, -6.02615115],
                            [32.1547379, -6.05857915],
                            [32.1671284, -6.06506941],
                            [32.16627796, -6.10355454],
                            [32.17804385, -6.10696112],
                            [32.18954932, -6.14649273],
                            [32.19780966, -6.16094832],
                            [32.2043066, -6.17269349],
                            [32.20943707, -6.18813875],
                            [32.22170563, -6.19575974],
                            [32.24486437, -6.20226337],
                            [32.29145849, -6.23982129],
                            [32.3029337, -6.22977824],
                            [32.34583059, -6.23013712],
                            [32.36109839, -6.24186467],
                            [32.396543, -6.25871641],
                            [32.40791927, -6.27504046],
                            [32.43833059, -6.29793712],
                            [32.46348628, -6.30340275],
                            [32.50239172, -6.30344094],
                            [32.51190464, -6.31420964],
                            [32.53253059, -6.31123712],
                            [32.56299563, -6.31645663],
                            [32.58163059, -6.33103712],
                            [32.63373059, -6.32743712],
                            [32.65561656, -6.33880746],
                            [32.66273059, -6.35933712],
                            [32.68293196, -6.36903305],
                            [32.70318271, -6.39149712],
                            [32.72272524, -6.39038009],
                            [32.7495019, -6.40369621],
                            [32.75362525, -6.44244999],
                            [32.75355021, -6.44822763],
                            [32.75840818, -6.62554364],
                            [32.75435987, -6.66319293],
                            [32.75549618, -6.72979241],
                            [32.75499108, -6.73635846],
                            [32.74896384, -6.75258885],
                            [32.74626325, -6.78059394],
                            [32.74754148, -6.83884121],
                            [32.74691251, -6.97201372],
                            [32.74540964, -6.99768021],
                            [32.67951922, -6.996152],
                            [32.63689231, -6.99946893],
                            [32.58663712, -6.99868349],
                            [32.57571722, -7.03062819],
                            [32.58760009, -7.0624516],
                            [32.58152953, -7.09018675],
                            [32.55582641, -7.10262424],
                            [32.5461323, -7.11600614],
                            [32.48699725, -7.13183581],
                            [32.48946002, -7.15069653],
                            [32.46358301, -7.14331817],
                            [32.40208495, -7.18995378],
                            [32.36963659, -7.20140508],
                            [32.32460697, -7.22955503],
                            [32.31916489, -7.24052146],
                            [32.2789502, -7.2695551],
                            [32.26732044, -7.36299445],
                            [32.23874055, -7.418359],
                            [32.21042289, -7.46220582],
                            [32.19100709, -7.50130375],
                            [32.19020564, -7.5162346],
                            [32.14854961, -7.51499722],
                            [32.1308359, -7.53736064],
                            [32.11050255, -7.51517921],
                            [32.08947288, -7.51874035],
                            [32.08877263, -7.54525593],
                            [32.07892144, -7.56827669],
                            [32.06031721, -7.5822677],
                            [32.04243601, -7.61191124],
                            [32.00067152, -7.58457772],
                            [31.93667689, -7.5313091],
                            [31.88985299, -7.48769618],
                            [31.87315586, -7.46297785],
                            [31.83192504, -7.43380186],
                            [31.80670711, -7.4237358],
                            [31.79495554, -7.4417973],
                            [31.76498157, -7.43935678],
                            [31.71486655, -7.50149185],
                            [31.62172719, -7.51555908],
                            [31.57093145, -7.55326867],
                            [31.57273276, -7.57891464],
                            [31.53956538, -7.56678636],
                            [31.53208983, -7.54820463],
                            [31.52411399, -7.52911967],
                            [31.51105241, -7.52807242],
                            [31.49152262, -7.50844625],
                            [31.4796283, -7.46053529],
                            [31.42992666, -7.45829157],
                            [31.42929222, -7.46644215],
                            [31.37529374, -7.47073845],
                            [31.36061788, -7.4456566],
                            [31.35121404, -7.41655314],
                            [31.3401175, -7.35672944],
                            [31.33800387, -7.3258057],
                            [31.34677601, -7.25235373],
                            [31.32106427, -7.24511603],
                            [31.30189268, -7.22947001],
                            [31.27297828, -7.2171827],
                            [31.18664976, -7.21163711],
                            [31.1611255, -7.21774114],
                            [31.1444453, -7.19683082],
                            [31.14664516, -7.17470883],
                            [31.07949756, -7.1271283],
                            [31.05130117, -7.11495012],
                            [30.98544748, -7.11144837],
                            [30.95448417, -7.07671487],
                            [30.95319579, -7.06324477],
                            [30.96923442, -6.99950444],
                            [30.96949905, -6.96531715],
                            [30.94887109, -6.93999683],
                            [30.9311393, -6.93605614],
                            [30.85038687, -6.91807739],
                            [30.80483552, -6.9144743],
                            [30.77329121, -6.90929379],
                            [30.75044177, -6.89865033],
                            [30.70504303, -6.88883482],
                            [30.66500281, -6.88559719],
                            [30.60570461, -6.89285389],
                            [30.56642162, -6.90248767],
                            [30.55022383, -6.90810861],
                            [30.50287018, -6.91285293],
                            [30.47615025, -6.88448406],
                            [30.45170005, -6.85088824],
                            [30.44338449, -6.82627866],
                            [30.39833254, -6.80249015],
                            [30.40703846, -6.76324109],
                            [30.40557883, -6.74676127],
                            [30.37644429, -6.71955823],
                            [30.37054087, -6.69592249],
                            [30.34856046, -6.67220688],
                            [30.33756647, -6.63600584],
                            [30.3218353, -6.62344449],
                            [30.28197878, -6.60885313],
                            [30.26369268, -6.59288668],
                            [30.25052615, -6.57059187],
                            [30.2634972, -6.5453117],
                            [30.26988995, -6.52936813],
                            [30.27854055, -6.51193628],
                            [30.28228657, -6.48760676],
                            [30.26925665, -6.47181267],
                            [30.26702798, -6.44801377],
                            [30.26326301, -6.43106419],
                            [30.25766764, -6.39588979],
                            [30.26095717, -6.34104757],
                            [30.2553558, -6.30673067],
                            [30.25832529, -6.23024258],
                            [30.25331672, -6.20514392],
                            [30.25661984, -6.17294723],
                            [30.25335364, -6.11471121],
                            [30.21529325, -5.99967819],
                            [30.18639358, -5.95924471],
                            [30.191122, -5.91298302],
                            [30.18649791, -5.88216608],
                            [30.18060504, -5.85130945],
                            [30.18671841, -5.8270358],
                            [30.17773015, -5.81251285],
                            [30.16888076, -5.79529241],
                            [30.14643072, -5.77588437],
                            [30.15170546, -5.75954089],
                            [30.1460482, -5.73235668],
                            [30.13131201, -5.710161],
                            [30.09331119, -5.66873029],
                            [30.08686908, -5.65987846],
                            [30.08937199, -5.62252442],
                            [30.0912239, -5.61594796],
                            [30.08748208, -5.59761417],
                            [30.07304447, -5.57392186],
                            [30.04788132, -5.57758195],
                            [30.02842433, -5.55743944],
                            [30.02629394, -5.54379277],
                            [30.01206763, -5.54907329],
                            [30.01630775, -5.53486555],
                            [30.00687636, -5.51928347],
                            [30.02307396, -5.51035736],
                            [30.04390321, -5.48670181],
                            [30.0405467, -5.45404869],
                            [30.00414975, -5.43911712],
                            [29.98724078, -5.43446391],
                            [29.95432475, -5.4014035],
                            [29.93781424, -5.3696006],
                            [29.91090352, -5.34037182],
                            [29.91175117, -5.32802525],
                            [29.95190015, -5.33899519],
                            [29.98429599, -5.34502953],
                            [30.05815707, -5.3406887],
                            [30.07471749, -5.33632917],
                            [30.09517351, -5.30666025],
                            [30.0924068, -5.28562826],
                            [30.1027228, -5.22009983],
                            [30.10501931, -5.18981989],
                            [30.12542884, -5.1848138],
                            [30.14768423, -5.19128091],
                            [30.16687988, -5.18612451],
                            [30.17075, -5.21160733],
                            [30.17848126, -5.23673322],
                            [30.19269736, -5.23180385],
                            [30.22561163, -5.22196362],
                            [30.22865244, -5.20999273],
                            [30.23811394, -5.18169616],
                            [30.2799941, -5.17161335],
                            [30.30771157, -5.16277115],
                            [30.35205849, -5.16158666],
                            [30.40298278, -5.15259072],
                            [30.4396378, -5.14588339],
                            [30.47175396, -5.15081106],
                            [30.49932332, -5.14474937],
                            [30.52959032, -5.14839369],
                            [30.55042354, -5.14142175],
                            [30.5615201, -5.15174926],
                            [30.55332447, -5.17463836],
                            [30.55399698, -5.1775795],
                            [30.58081391, -5.19012264],
                            [30.59564116, -5.19595858],
                            [30.61463464, -5.18515764],
                            [30.63288105, -5.16494919],
                            [30.65644855, -5.15857143],
                            [30.67039681, -5.13775736],
                            [30.68848362, -5.13390475],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Katavi", Region_Cod: 23, zoneId: 5 },
            drilldown: 23,
            region_nam: "Katavi",
            value: 5.8,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.24",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [31.15254785, -3.35964378],
                            [31.15283067, -3.36009912],
                            [31.1509146, -3.37661158],
                            [31.17087487, -3.4130775],
                            [31.19157375, -3.42204188],
                            [31.1942255, -3.44977681],
                            [31.20879047, -3.46342988],
                            [31.21072111, -3.49226881],
                            [31.19659438, -3.50706164],
                            [31.21313441, -3.52786499],
                            [31.21007123, -3.55115995],
                            [31.21624036, -3.57542924],
                            [31.2117204, -3.62551519],
                            [31.22357001, -3.64628254],
                            [31.22833428, -3.68537402],
                            [31.24861299, -3.70846242],
                            [31.29503411, -3.73277243],
                            [31.3156793, -3.77088662],
                            [31.31580146, -3.79018804],
                            [31.34231037, -3.83098977],
                            [31.35342701, -3.86226295],
                            [31.35330485, -3.89695664],
                            [31.37895863, -3.97440662],
                            [31.37431651, -4.01178785],
                            [31.37859215, -4.02241584],
                            [31.35745832, -4.04000701],
                            [31.34573087, -4.07054722],
                            [31.34560871, -4.0921697],
                            [31.32911699, -4.13236062],
                            [31.33131589, -4.15056259],
                            [31.34744112, -4.17413964],
                            [31.35037299, -4.23082228],
                            [31.36698686, -4.23754113],
                            [31.36397626, -4.29196043],
                            [31.36927832, -4.30240861],
                            [31.39532079, -4.31737913],
                            [31.39843965, -4.33671605],
                            [31.4160612, -4.3630704],
                            [31.41995977, -4.38911287],
                            [31.4182444, -4.43215311],
                            [31.4368016, -4.46833187],
                            [31.45582664, -4.45850746],
                            [31.45546837, -4.46544659],
                            [31.46432436, -4.48556067],
                            [31.45798516, -4.5128866],
                            [31.47470694, -4.53707551],
                            [31.47631224, -4.56635592],
                            [31.48742134, -4.58478223],
                            [31.48373613, -4.6030726],
                            [31.49212676, -4.62619851],
                            [31.48415879, -4.64928945],
                            [31.45693127, -4.69092935],
                            [31.43544143, -4.70186015],
                            [31.41210696, -4.69887973],
                            [31.38599265, -4.72673616],
                            [31.40121645, -4.76765307],
                            [31.39747932, -4.80622562],
                            [31.37568437, -4.80836057],
                            [31.35395713, -4.8475602],
                            [31.3297387, -4.87570776],
                            [31.30891543, -4.90651388],
                            [31.31008428, -4.91584607],
                            [31.2685679, -4.9245901],
                            [31.24421658, -4.93505119],
                            [31.23464174, -4.94866027],
                            [31.23719384, -4.97545764],
                            [31.23881417, -4.9938805],
                            [31.26066204, -5.00953192],
                            [31.24931062, -5.03067856],
                            [31.2468547, -5.05564615],
                            [31.23636225, -5.06584351],
                            [31.21048303, -5.10909704],
                            [31.20473237, -5.15038717],
                            [31.22022683, -5.17572589],
                            [31.20637565, -5.18631608],
                            [31.20216797, -5.20396329],
                            [31.20234187, -5.21139384],
                            [31.21192591, -5.22251357],
                            [31.21532584, -5.22464367],
                            [31.20976652, -5.25633041],
                            [31.18018349, -5.25439236],
                            [31.1814517, -5.27020622],
                            [31.181862, -5.27894418],
                            [31.129432, -5.2688595],
                            [31.05771611, -5.25221678],
                            [31.04445858, -5.25119783],
                            [31.02010133, -5.24525583],
                            [31.00040844, -5.25970202],
                            [30.98505065, -5.27631887],
                            [30.97477313, -5.28852296],
                            [30.94195587, -5.29264934],
                            [30.91253849, -5.29634662],
                            [30.89966407, -5.31239506],
                            [30.8779231, -5.31016442],
                            [30.86354658, -5.32089599],
                            [30.83724929, -5.32320001],
                            [30.8259087, -5.28578628],
                            [30.82478395, -5.26565799],
                            [30.80307612, -5.25480099],
                            [30.78231662, -5.24634261],
                            [30.76519631, -5.2385116],
                            [30.74392024, -5.20414772],
                            [30.71363212, -5.1769029],
                            [30.70632741, -5.14337348],
                            [30.68848362, -5.13390475],
                            [30.67039681, -5.13775736],
                            [30.65644855, -5.15857143],
                            [30.63288105, -5.16494919],
                            [30.61463464, -5.18515764],
                            [30.59564116, -5.19595858],
                            [30.58081391, -5.19012264],
                            [30.55399698, -5.1775795],
                            [30.55332447, -5.17463836],
                            [30.5615201, -5.15174926],
                            [30.55042354, -5.14142175],
                            [30.52959032, -5.14839369],
                            [30.49932332, -5.14474937],
                            [30.47175396, -5.15081106],
                            [30.4396378, -5.14588339],
                            [30.40298278, -5.15259072],
                            [30.35205849, -5.16158666],
                            [30.30771157, -5.16277115],
                            [30.2799941, -5.17161335],
                            [30.23811394, -5.18169616],
                            [30.22865244, -5.20999273],
                            [30.22561163, -5.22196362],
                            [30.19269736, -5.23180385],
                            [30.17848126, -5.23673322],
                            [30.17075, -5.21160733],
                            [30.16687988, -5.18612451],
                            [30.14768423, -5.19128091],
                            [30.12542884, -5.1848138],
                            [30.10501931, -5.18981989],
                            [30.1027228, -5.22009983],
                            [30.0924068, -5.28562826],
                            [30.09517351, -5.30666025],
                            [30.07471749, -5.33632917],
                            [30.05815707, -5.3406887],
                            [29.98429599, -5.34502953],
                            [29.95190015, -5.33899519],
                            [29.91175117, -5.32802525],
                            [29.91090352, -5.34037182],
                            [29.93781424, -5.3696006],
                            [29.95432475, -5.4014035],
                            [29.98724078, -5.43446391],
                            [30.00414975, -5.43911712],
                            [30.0405467, -5.45404869],
                            [30.04390321, -5.48670181],
                            [30.02307396, -5.51035736],
                            [30.00687636, -5.51928347],
                            [30.01630775, -5.53486555],
                            [30.01206763, -5.54907329],
                            [30.02629394, -5.54379277],
                            [30.02842433, -5.55743944],
                            [30.04788132, -5.57758195],
                            [30.07304447, -5.57392186],
                            [30.08748208, -5.59761417],
                            [30.0912239, -5.61594796],
                            [30.08937199, -5.62252442],
                            [30.08686908, -5.65987846],
                            [30.09331119, -5.66873029],
                            [30.13131201, -5.710161],
                            [30.1460482, -5.73235668],
                            [30.15170546, -5.75954089],
                            [30.14643072, -5.77588437],
                            [30.16888076, -5.79529241],
                            [30.17773015, -5.81251285],
                            [30.18671841, -5.8270358],
                            [30.18060504, -5.85130945],
                            [30.18649791, -5.88216608],
                            [30.18978443, -5.92963428],
                            [30.18412379, -5.94887046],
                            [30.19053005, -5.96538287],
                            [30.21529325, -5.99967819],
                            [30.25335364, -6.11471121],
                            [30.25661984, -6.17294723],
                            [30.25331672, -6.20514392],
                            [30.25832529, -6.23024258],
                            [30.2553558, -6.30673067],
                            [30.26095717, -6.34104757],
                            [30.25766764, -6.39588979],
                            [30.26326301, -6.43106419],
                            [30.26702798, -6.44801377],
                            [30.26925665, -6.47181267],
                            [30.28228657, -6.48760676],
                            [30.27854055, -6.51193628],
                            [30.26988995, -6.52936813],
                            [30.2634972, -6.5453117],
                            [30.25052615, -6.57059187],
                            [30.17865075, -6.49842766],
                            [30.14147846, -6.44756398],
                            [30.10166682, -6.4491668],
                            [30.05510852, -6.45990835],
                            [30.00059371, -6.47934179],
                            [29.95052688, -6.50488849],
                            [29.95602949, -6.48091186],
                            [29.94196725, -6.4636461],
                            [29.9220927, -6.46102979],
                            [29.86507348, -6.41844339],
                            [29.8165518, -6.36071689],
                            [29.79136467, -6.32221103],
                            [29.75693621, -6.27824417],
                            [29.74494942, -6.27119572],
                            [29.72214472, -6.23449588],
                            [29.74173843, -6.19725168],
                            [29.74009919, -6.1655284],
                            [29.73108352, -6.15269986],
                            [29.7273013, -6.08734233],
                            [29.73154627, -6.03797961],
                            [29.74583493, -6.01455959],
                            [29.79687067, -5.99045588],
                            [29.82399906, -5.99928618],
                            [29.83937731, -5.97885047],
                            [29.87130386, -5.96230675],
                            [29.90094339, -5.9337298],
                            [29.91821791, -5.93116247],
                            [29.94607765, -5.91227619],
                            [29.95481671, -5.85412357],
                            [29.94826448, -5.79614157],
                            [29.93612184, -5.7576198],
                            [29.91766416, -5.73499365],
                            [29.89236488, -5.67888477],
                            [29.87247959, -5.62402317],
                            [29.83905925, -5.56778003],
                            [29.83868669, -5.55008873],
                            [29.79314178, -5.50913146],
                            [29.78176281, -5.48904193],
                            [29.75304782, -5.46396091],
                            [29.74758254, -5.43434391],
                            [29.74887849, -5.39948932],
                            [29.76756288, -5.39061404],
                            [29.76725798, -5.36513792],
                            [29.78976104, -5.33354126],
                            [29.78657385, -5.30509233],
                            [29.79544317, -5.27865371],
                            [29.80914047, -5.27448447],
                            [29.80785676, -5.23538351],
                            [29.8217636, -5.21122648],
                            [29.82280213, -5.12694345],
                            [29.79351536, -5.06683959],
                            [29.77022793, -5.03892407],
                            [29.78366558, -5.02548232],
                            [29.76883527, -4.99432207],
                            [29.73078252, -4.96824718],
                            [29.70141465, -4.93817605],
                            [29.66352406, -4.9145178],
                            [29.63666832, -4.91378023],
                            [29.60737914, -4.90059852],
                            [29.62773492, -4.87440969],
                            [29.61591057, -4.86137798],
                            [29.60514286, -4.83067489],
                            [29.61029386, -4.81076429],
                            [29.59840693, -4.79352824],
                            [29.61296842, -4.70150361],
                            [29.6235676, -4.65940407],
                            [29.6383272, -4.61859229],
                            [29.6479358, -4.54687449],
                            [29.65447361, -4.53152054],
                            [29.65447361, -4.47317554],
                            [29.66229917, -4.44365634],
                            [29.68131825, -4.45425551],
                            [29.71212521, -4.44732147],
                            [29.75412569, -4.47416612],
                            [29.76762567, -4.45396445],
                            [29.76269918, -4.43041121],
                            [29.77391983, -4.41144343],
                            [29.79486865, -4.39734876],
                            [29.81257251, -4.36248692],
                            [29.82310935, -4.3587219],
                            [29.8502814, -4.37593439],
                            [29.89345781, -4.35111771],
                            [29.92282323, -4.34575566],
                            [29.95925451, -4.32511776],
                            [29.96910122, -4.30087058],
                            [29.99796977, -4.29615243],
                            [30.01194179, -4.28177111],
                            [30.04106168, -4.26763369],
                            [30.04678373, -4.2419804],
                            [30.07804477, -4.16443113],
                            [30.14561558, -4.11961955],
                            [30.15726799, -4.1147308],
                            [30.17914774, -4.08789789],
                            [30.18718946, -4.05740062],
                            [30.21530214, -4.03388856],
                            [30.22376523, -4.01080306],
                            [30.21585923, -3.99753121],
                            [30.22236746, -3.97421868],
                            [30.2408768, -3.94507548],
                            [30.25680505, -3.88433156],
                            [30.27804648, -3.87325546],
                            [30.30815737, -3.84486951],
                            [30.32274611, -3.81197454],
                            [30.32675759, -3.79043909],
                            [30.34505227, -3.7744582],
                            [30.37574753, -3.77529479],
                            [30.39684466, -3.79090111],
                            [30.40890226, -3.78095413],
                            [30.40798605, -3.76235514],
                            [30.38737141, -3.71278837],
                            [30.41082629, -3.67568201],
                            [30.42246212, -3.63784268],
                            [30.45526231, -3.60412628],
                            [30.45590365, -3.57398309],
                            [30.46955513, -3.53724321],
                            [30.50831066, -3.51699504],
                            [30.53466096, -3.49741729],
                            [30.58330274, -3.482167],
                            [30.61730424, -3.46373405],
                            [30.66016417, -3.43002429],
                            [30.67300094, -3.4107161],
                            [30.67286832, -3.39026746],
                            [30.6467174, -3.37697982],
                            [30.63645867, -3.35424597],
                            [30.66597308, -3.31695412],
                            [30.68097956, -3.31560802],
                            [30.72131259, -3.29775978],
                            [30.73023671, -3.27048887],
                            [30.74409652, -3.26430679],
                            [30.75027859, -3.28719043],
                            [30.7972424, -3.27642166],
                            [30.84026762, -3.25224178],
                            [30.825311, -3.24476347],
                            [30.81105235, -3.22123171],
                            [30.84056676, -3.20462986],
                            [30.83732616, -3.19121875],
                            [30.84545259, -3.15975998],
                            [30.82830232, -3.12879976],
                            [30.83523223, -3.09444938],
                            [30.81374454, -3.0798916],
                            [30.79584645, -3.05252097],
                            [30.8111022, -3.03920958],
                            [30.82481244, -3.00854849],
                            [30.8505877, -2.97619233],
                            [30.85098654, -2.97529493],
                            [30.86567748, -2.94666368],
                            [30.92053115, -2.88654454],
                            [30.98925241, -2.83170189],
                            [31.00813897, -2.82809888],
                            [31.02681015, -2.85318274],
                            [31.02785918, -2.8670889],
                            [31.04891372, -2.89301634],
                            [31.04898433, -2.92130496],
                            [31.06731959, -2.93464214],
                            [31.08073964, -2.96427293],
                            [31.06837613, -3.01255801],
                            [31.06999579, -3.03280576],
                            [31.03994777, -3.08085824],
                            [31.01279951, -3.13593152],
                            [31.00907748, -3.15646368],
                            [30.98808204, -3.17940927],
                            [30.99267748, -3.19306368],
                            [31.03057748, -3.20416368],
                            [31.03662963, -3.18489714],
                            [31.08017748, -3.18236368],
                            [31.10897748, -3.21126368],
                            [31.11979298, -3.2289557],
                            [31.11671009, -3.27572944],
                            [31.13587748, -3.33916368],
                            [31.15254785, -3.35964378],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Kigoma", Region_Cod: 16, zoneId: 4 },
            drilldown: 16,
            region_nam: "Kigoma",
            value: 5.8,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.25",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [39.23901856, -5.80923529],
                            [39.22980738, -5.82613584],
                            [39.23698959, -5.84257623],
                            [39.23038743, -5.85821635],
                            [39.21157559, -5.84467408],
                            [39.21552224, -5.82103374],
                            [39.23901856, -5.80923529],
                        ],
                    ],
                    [
                        [
                            [39.39929864, -6.02153555],
                            [39.33573106, -6.03285058],
                            [39.27878269, -6.02796286],
                            [39.24082192, -6.03835761],
                            [39.20424357, -6.03544513],
                            [39.18602398, -5.9985165],
                            [39.18146779, -5.95581029],
                            [39.186583, -5.93842789],
                            [39.18421864, -5.90760785],
                            [39.1948829, -5.89586478],
                            [39.20864468, -5.91423644],
                            [39.23375235, -5.88066232],
                            [39.27319425, -5.86624043],
                            [39.28914785, -5.82971286],
                            [39.29596657, -5.79085106],
                            [39.28730799, -5.75138065],
                            [39.29175252, -5.7270169],
                            [39.30691693, -5.72329154],
                            [39.35085982, -5.78464089],
                            [39.36097632, -5.82677727],
                            [39.35391735, -5.87465182],
                            [39.35813219, -5.92234611],
                            [39.37807142, -5.98743408],
                            [39.39929864, -6.02153555],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "",
                Region_Nam: "Kaskazini Unguja",
                Region_Cod: 51,
                zoneId: 7,
            },
            drilldown: 51,
            region_nam: "Kaskazini Unguja",
            value: 4.5,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.26",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [39.86027571, -5.05002047],
                            [39.8726103, -5.06658541],
                            [39.8634658, -5.10902593],
                            [39.84203488, -5.10319385],
                            [39.8572092, -5.07726747],
                            [39.84793036, -5.05937026],
                            [39.86027571, -5.05002047],
                        ],
                    ],
                    [
                        [
                            [39.81757507, -5.17062279],
                            [39.79973207, -5.16459645],
                            [39.79318512, -5.16378202],
                            [39.74441079, -5.16053703],
                            [39.73632058, -5.14833393],
                            [39.71727084, -5.15449897],
                            [39.68561681, -5.13333883],
                            [39.68938698, -5.10559569],
                            [39.70046617, -5.07955015],
                            [39.71784039, -5.06061846],
                            [39.67700822, -5.0438481],
                            [39.69515156, -5.0155494],
                            [39.67571485, -4.98623975],
                            [39.69820052, -4.97643461],
                            [39.68878017, -4.95863417],
                            [39.6740138, -4.95796151],
                            [39.67920603, -4.88454577],
                            [39.70034534, -4.90775212],
                            [39.72655106, -4.90324157],
                            [39.74739505, -4.90972989],
                            [39.76189267, -4.92480364],
                            [39.78864219, -4.93256677],
                            [39.79860118, -4.959638],
                            [39.82166653, -4.95483004],
                            [39.8154785, -4.92698578],
                            [39.85571517, -4.95476212],
                            [39.84975661, -4.92266946],
                            [39.87409742, -4.90440844],
                            [39.87210538, -4.93914751],
                            [39.87616397, -4.9815013],
                            [39.86779525, -5.02343781],
                            [39.84064972, -5.01495723],
                            [39.83124105, -5.05955751],
                            [39.83795725, -5.0814671],
                            [39.82531917, -5.14407116],
                            [39.84084831, -5.14062921],
                            [39.84892127, -5.10791593],
                            [39.86572732, -5.11873667],
                            [39.86244467, -5.15886888],
                            [39.85230001, -5.1750977],
                            [39.81757507, -5.17062279],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "",
                Region_Nam: "Kaskazini Pemba",
                Region_Cod: 54,
                zoneId: 7,
            },
            drilldown: 54,
            region_nam: "Kaskazini Pemba",
            value: 5.7,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.27",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [39.27719211, -6.09206691],
                            [39.26953682, -6.14679146],
                            [39.27457417, -6.18070735],
                            [39.28613198, -6.20439438],
                            [39.30954604, -6.22886977],
                            [39.30995652, -6.23705736],
                            [39.28014732, -6.2565035],
                            [39.29648447, -6.29626348],
                            [39.29899216, -6.32087667],
                            [39.27214946, -6.31580486],
                            [39.24582899, -6.26967294],
                            [39.215371, -6.25247657],
                            [39.20333525, -6.21773656],
                            [39.20585605, -6.18773148],
                            [39.18592695, -6.16411384],
                            [39.21366577, -6.13383702],
                            [39.21541146, -6.09395563],
                            [39.20258932, -6.05494707],
                            [39.20424357, -6.03544513],
                            [39.24082192, -6.03835761],
                            [39.27878269, -6.02796286],
                            [39.278918, -6.02945098],
                            [39.28592647, -6.04710494],
                            [39.27642297, -6.07040984],
                            [39.27719211, -6.09206691],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "",
                Region_Nam: "Mjini Magharibi",
                Region_Cod: 53,
                zoneId: 7,
            },
            drilldown: 53,
            region_nam: "Mjini Magharibi",
            value: 4,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.28",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [39.39929864, -6.02153555],
                            [39.4312944, -6.07234684],
                            [39.42583725, -6.0899475],
                            [39.42544608, -6.13589858],
                            [39.43800644, -6.17112174],
                            [39.45054232, -6.19144089],
                            [39.46516215, -6.18210278],
                            [39.49487302, -6.18384415],
                            [39.50664457, -6.17767188],
                            [39.48969124, -6.14082027],
                            [39.51079257, -6.12760634],
                            [39.53476516, -6.18720626],
                            [39.53338055, -6.22741847],
                            [39.53693995, -6.27948516],
                            [39.55722784, -6.342903],
                            [39.58025743, -6.38356286],
                            [39.56822221, -6.43003469],
                            [39.55466006, -6.44834621],
                            [39.51602579, -6.4762944],
                            [39.48760404, -6.47349446],
                            [39.4657103, -6.44942108],
                            [39.45564549, -6.41920478],
                            [39.44183794, -6.40170734],
                            [39.43319079, -6.35190149],
                            [39.41605788, -6.36795784],
                            [39.3873391, -6.3651646],
                            [39.38306242, -6.32876317],
                            [39.36123403, -6.30564855],
                            [39.34744433, -6.3028577],
                            [39.337803, -6.27170501],
                            [39.31797102, -6.24846472],
                            [39.30954604, -6.22886977],
                            [39.28613198, -6.20439438],
                            [39.27457417, -6.18070735],
                            [39.26953682, -6.14679146],
                            [39.27719211, -6.09206691],
                            [39.27642294, -6.07040978],
                            [39.28592644, -6.04710488],
                            [39.278918, -6.02945098],
                            [39.27878269, -6.02796286],
                            [39.33573106, -6.03285058],
                            [39.39929864, -6.02153555],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Kusini Unguja", Region_Cod: 52, zoneId: 7 },
            drilldown: 52,
            region_nam: "Kusini Unguja",
            value: 5,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.29",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [33.93958287, -9.71772557],
                            [33.99002525, -9.73305022],
                            [34.02832968, -9.76315009],
                            [34.08852233, -9.82608613],
                            [34.14324283, -9.92185828],
                            [34.3402367, -10.2283292],
                            [34.41684539, -10.35967385],
                            [34.41684412, -10.86316093],
                            [34.48387591, -11.27361243],
                            [34.51533998, -11.40222057],
                            [34.53876694, -11.52569805],
                            [34.54198168, -11.55237471],
                            [34.29172024, -11.53814155],
                            [34.27716167, -11.44396937],
                            [34.23057396, -11.25267506],
                            [34.21892711, -11.17555026],
                            [34.22183949, -10.94405338],
                            [34.19563434, -10.66086717],
                            [34.17525214, -10.5979008],
                            [34.21310522, -10.54351047],
                            [34.21310533, -10.50056387],
                            [34.24222313, -10.4375648],
                            [34.25678206, -10.39173926],
                            [34.18689988, -10.32298813],
                            [34.10828246, -10.23416237],
                            [34.01510627, -10.12237856],
                            [33.95395955, -9.97900918],
                            [33.94231255, -9.95606419],
                            [33.90309502, -9.82795508],
                            [33.90637083, -9.79585279],
                            [33.89799957, -9.76787281],
                            [33.88726246, -9.75746939],
                            [33.90527907, -9.73163888],
                            [33.92948314, -9.7190817],
                            [33.93958287, -9.71772557],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Nyasa",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.30",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [37.37292973, -9.13842],
                            [37.34805995, -9.18244071],
                            [37.33421649, -9.2412207],
                            [37.32026275, -9.27208666],
                            [37.32578404, -9.28759897],
                            [37.31785248, -9.31654898],
                            [37.29030919, -9.35200302],
                            [37.28460096, -9.38503221],
                            [37.28493923, -9.43804367],
                            [37.27315511, -9.45600324],
                            [37.27131285, -9.4945599],
                            [37.25074401, -9.52223373],
                            [37.13878332, -9.5849751],
                            [37.10545587, -9.59856724],
                            [36.99337252, -9.63391481],
                            [36.90058747, -9.67926442],
                            [36.80647471, -9.73325879],
                            [36.76614331, -9.75402848],
                            [36.70285446, -9.75650176],
                            [36.68200785, -9.75148616],
                            [36.64204395, -9.75791406],
                            [36.60458393, -9.75765263],
                            [36.58598124, -9.78250075],
                            [36.56065543, -9.80242489],
                            [36.53785553, -9.81261706],
                            [36.5095999, -9.81301971],
                            [36.50568254, -9.77992096],
                            [36.49251694, -9.74010724],
                            [36.49242469, -9.70495887],
                            [36.47978635, -9.68975268],
                            [36.46385873, -9.68822934],
                            [36.45405566, -9.67304358],
                            [36.46741975, -9.63834671],
                            [36.46654339, -9.61338577],
                            [36.41954526, -9.67020862],
                            [36.41248385, -9.66734384],
                            [36.40903486, -9.68606217],
                            [36.38953284, -9.7041897],
                            [36.35257775, -9.71936928],
                            [36.33645151, -9.72295],
                            [36.32420342, -9.75564006],
                            [36.30634695, -9.76365092],
                            [36.27375638, -9.76667227],
                            [36.2401083, -9.76314241],
                            [36.17374756, -9.7626586],
                            [36.1537849, -9.76930522],
                            [36.13907894, -9.78440032],
                            [36.12648513, -9.77930968],
                            [36.09919423, -9.78415872],
                            [36.08674345, -9.76386704],
                            [36.07002005, -9.75752171],
                            [36.06856084, -9.73570354],
                            [36.0471142, -9.73847793],
                            [36.03432278, -9.75410823],
                            [36.00511569, -9.76432831],
                            [35.9676846, -9.78420649],
                            [35.88571082, -9.83634943],
                            [35.84415376, -9.88583729],
                            [35.80662878, -9.89764678],
                            [35.77822381, -9.91744524],
                            [35.75553917, -9.91661823],
                            [35.73132856, -9.88415106],
                            [35.69899452, -9.8674156],
                            [35.67330591, -9.82768308],
                            [35.68455356, -9.8003119],
                            [35.69435188, -9.79507869],
                            [35.71289309, -9.76279989],
                            [35.74286466, -9.74274878],
                            [35.7756226, -9.71322705],
                            [35.7878304, -9.6942485],
                            [35.8108701, -9.67417654],
                            [35.81778657, -9.64201957],
                            [35.83067318, -9.6140937],
                            [35.84504575, -9.55515153],
                            [35.88606908, -9.50095416],
                            [35.88747449, -9.48120363],
                            [35.93352127, -9.45426558],
                            [35.94589308, -9.42716394],
                            [35.9394257, -9.38757966],
                            [35.91857078, -9.34137331],
                            [35.91820665, -9.29400635],
                            [35.91081177, -9.25217198],
                            [35.87117548, -9.25182807],
                            [35.8397612, -9.27243722],
                            [35.82095047, -9.29375163],
                            [35.79011441, -9.3161494],
                            [35.74091171, -9.29178299],
                            [35.72330618, -9.29330251],
                            [35.69756677, -9.28223018],
                            [35.68197306, -9.25217849],
                            [35.65562911, -9.26685371],
                            [35.63139725, -9.27263172],
                            [35.55614167, -9.4554402],
                            [35.5003104, -9.48938937],
                            [35.5046299, -9.38173943],
                            [35.49990152, -9.36677907],
                            [35.49997865, -9.17670656],
                            [35.50776757, -9.11851504],
                            [35.52316866, -9.06680706],
                            [35.46439391, -9.03711268],
                            [35.38400215, -9.01061362],
                            [35.40813461, -8.97712407],
                            [35.38180952, -8.94027921],
                            [35.36333577, -8.93802131],
                            [35.36656868, -8.9154936],
                            [35.34696598, -8.89040009],
                            [35.35348311, -8.88023953],
                            [35.32366416, -8.86609413],
                            [35.3572362, -8.82248696],
                            [35.35012039, -8.80186935],
                            [35.35130636, -8.77003064],
                            [35.34263966, -8.76300606],
                            [35.33096243, -8.72487259],
                            [35.35249232, -8.71027605],
                            [35.3620713, -8.68099174],
                            [35.41571359, -8.66256361],
                            [35.47473318, -8.65691531],
                            [35.51126772, -8.66242882],
                            [35.5425917, -8.68211991],
                            [35.58678291, -8.68412007],
                            [35.60399191, -8.70190072],
                            [35.62878361, -8.75234582],
                            [35.64516121, -8.76886524],
                            [35.66438379, -8.74050606],
                            [35.6877821, -8.75897262],
                            [35.69583767, -8.71449853],
                            [35.6921418, -8.69032393],
                            [35.70436031, -8.67134708],
                            [35.68915482, -8.66358886],
                            [35.81841648, -8.58656141],
                            [35.87111824, -8.54725836],
                            [35.87323579, -8.51567422],
                            [35.88577522, -8.48717255],
                            [35.88847751, -8.45885861],
                            [35.91566866, -8.43570321],
                            [35.95612351, -8.42873744],
                            [36.02214506, -8.35589899],
                            [36.08430984, -8.30818491],
                            [36.10642835, -8.28771437],
                            [36.14026974, -8.22985477],
                            [36.18931525, -8.17853708],
                            [36.30143318, -8.08361011],
                            [36.33492459, -8.06573456],
                            [36.38913409, -8.02838153],
                            [36.41437248, -8.01614837],
                            [36.46339185, -7.97971622],
                            [36.46396683, -8.02664362],
                            [36.53331701, -8.02739227],
                            [36.53331916, -8.02739225],
                            [36.63175638, -8.02583351],
                            [36.71144358, -8.02382486],
                            [36.70870756, -8.01748422],
                            [36.70160834, -8.00978461],
                            [36.7016171, -7.97918802],
                            [36.74170504, -7.92060013],
                            [36.7495505, -7.91062072],
                            [36.81224933, -7.81777858],
                            [36.83017415, -7.78240064],
                            [36.83479379, -7.76880611],
                            [36.83333789, -7.71871984],
                            [36.84136373, -7.66981117],
                            [36.83749251, -7.64830576],
                            [36.79308284, -7.64700724],
                            [36.78101747, -7.642013],
                            [36.7904837, -7.59438868],
                            [36.77915857, -7.59484074],
                            [36.75394683, -7.58622802],
                            [36.69261904, -7.57721499],
                            [36.67726672, -7.56215343],
                            [36.65329146, -7.531971],
                            [36.6433437, -7.52781428],
                            [36.6086852, -7.53234129],
                            [36.58161917, -7.51313054],
                            [36.56500018, -7.51573933],
                            [36.53990665, -7.52124289],
                            [36.5227784, -7.50477337],
                            [36.51796023, -7.47795702],
                            [36.50940869, -7.46849376],
                            [36.50508795, -7.43820134],
                            [36.48894138, -7.41206965],
                            [36.47874465, -7.41003677],
                            [36.4813434, -7.37818588],
                            [36.51202794, -7.35503573],
                            [36.53294926, -7.32311286],
                            [36.52936706, -7.28351362],
                            [36.5075232, -7.22726136],
                            [36.50444555, -7.19507165],
                            [36.51540868, -7.15538186],
                            [36.53402904, -7.13008018],
                            [36.53836927, -7.10516422],
                            [36.56556382, -7.05259136],
                            [36.59515968, -7.03878286],
                            [36.64786032, -7.02483675],
                            [36.65078481, -6.99108766],
                            [36.64488495, -6.98013519],
                            [36.64730129, -6.94776357],
                            [36.63720323, -6.92444976],
                            [36.64476896, -6.88619107],
                            [36.63604484, -6.84726644],
                            [36.64278491, -6.83105995],
                            [36.63613698, -6.80163359],
                            [36.645094, -6.77394646],
                            [36.63083807, -6.73777201],
                            [36.66327033, -6.70516155],
                            [36.67111109, -6.66809611],
                            [36.70051397, -6.63762404],
                            [36.7163737, -6.61285435],
                            [36.72777845, -6.57062113],
                            [36.74755856, -6.55012822],
                            [36.74577657, -6.50468742],
                            [36.72171967, -6.49185707],
                            [36.72225427, -6.47671014],
                            [36.72528366, -6.45853382],
                            [36.74952525, -6.43233701],
                            [36.77638393, -6.40899075],
                            [36.80461492, -6.39942233],
                            [36.84938067, -6.35662899],
                            [36.84995059, -6.27912169],
                            [36.84026749, -6.18976542],
                            [36.82199186, -6.07739808],
                            [36.81622585, -6.05446279],
                            [36.86802579, -6.00768263],
                            [36.89748562, -5.99852774],
                            [36.94075492, -5.97346515],
                            [36.95764405, -5.93829875],
                            [36.96398607, -5.92518519],
                            [36.99529079, -5.91366458],
                            [37.07365578, -5.85841883],
                            [37.12251733, -5.95950784],
                            [37.14633712, -5.98950107],
                            [37.17387036, -5.99956721],
                            [37.22913997, -6.00010462],
                            [37.26441811, -5.99419727],
                            [37.28533822, -5.99121726],
                            [37.28998532, -5.98975945],
                            [37.31870541, -5.98125401],
                            [37.33712303, -5.97829226],
                            [37.36099074, -5.97533461],
                            [37.37602808, -5.96514843],
                            [37.39446852, -5.92851541],
                            [37.3929941, -5.88610824],
                            [37.39351159, -5.87529973],
                            [37.40837294, -5.84362821],
                            [37.41091186, -5.83979651],
                            [37.44192319, -5.80239237],
                            [37.46006616, -5.80006895],
                            [37.48393243, -5.82046295],
                            [37.54476977, -5.81556792],
                            [37.56417677, -5.8417157],
                            [37.57977936, -5.8398715],
                            [37.60826429, -5.85616613],
                            [37.61066462, -5.87433121],
                            [37.61154557, -5.89863079],
                            [37.6265727, -5.9123878],
                            [37.63348968, -5.94927015],
                            [37.66521901, -5.93468527],
                            [37.68880446, -5.91327134],
                            [37.71014791, -5.8763238],
                            [37.72156599, -5.86689986],
                            [37.72302791, -5.86966021],
                            [37.75233342, -5.87286468],
                            [37.75442534, -5.88855387],
                            [37.79332612, -5.88926701],
                            [37.85363148, -5.93628975],
                            [37.86627049, -5.96157068],
                            [37.84710279, -5.97381809],
                            [37.86661078, -6.01692479],
                            [37.86661074, -6.04348711],
                            [37.89062324, -6.0940647],
                            [37.90845074, -6.09151765],
                            [37.95138224, -6.12208256],
                            [37.96011405, -6.14427848],
                            [37.98740101, -6.17338791],
                            [38.01381958, -6.17226112],
                            [38.02938637, -6.18235882],
                            [38.05742482, -6.18244275],
                            [38.0778529, -6.20604253],
                            [38.05209007, -6.21437363],
                            [38.01047768, -6.22053958],
                            [38.00159088, -6.24762962],
                            [38.00091525, -6.60008315],
                            [38.01410384, -6.60896443],
                            [38.0158642, -6.62846433],
                            [38.03268568, -6.63666827],
                            [38.04573408, -6.65603576],
                            [38.08966938, -6.65575723],
                            [38.15107264, -6.6758655],
                            [38.17206798, -6.69684432],
                            [38.18695918, -6.69547647],
                            [38.21552918, -6.70630796],
                            [38.24615342, -6.70163458],
                            [38.26355401, -6.78445049],
                            [38.26290139, -6.91923249],
                            [38.26835404, -6.95603636],
                            [38.27990973, -6.98434272],
                            [38.29830475, -6.9706614],
                            [38.31693549, -6.98316344],
                            [38.32471788, -7.01217743],
                            [38.35325362, -7.01689525],
                            [38.38697771, -7.01194177],
                            [38.41810761, -7.02137733],
                            [38.4622083, -7.02751054],
                            [38.5058374, -7.02397242],
                            [38.51574233, -7.03600261],
                            [38.48696249, -7.08411808],
                            [38.47915565, -7.10789853],
                            [38.45833761, -7.11972953],
                            [38.44145861, -7.1652834],
                            [38.42468878, -7.18752908],
                            [38.39584001, -7.18573914],
                            [38.38188054, -7.17604736],
                            [38.34919879, -7.20282269],
                            [38.34969144, -7.21120028],
                            [38.31471043, -7.24848864],
                            [38.3055135, -7.26820059],
                            [38.30564826, -7.37639991],
                            [38.3030328, -7.4202606],
                            [38.30659422, -7.45454843],
                            [38.30416064, -7.57888738],
                            [38.17098894, -7.61395305],
                            [38.12140494, -7.63120549],
                            [38.10014147, -7.63069803],
                            [38.0403641, -7.55739762],
                            [38.02551197, -7.55467593],
                            [37.98547585, -7.52662891],
                            [37.92093875, -7.57498252],
                            [37.89844798, -7.59956813],
                            [37.88335449, -7.62527469],
                            [37.82073896, -7.70291741],
                            [37.81789944, -7.78265265],
                            [37.82880853, -7.80185788],
                            [37.82948095, -7.83167453],
                            [37.84106251, -7.84886211],
                            [37.83687813, -7.87419504],
                            [37.80534627, -7.89070995],
                            [37.79608101, -7.93350276],
                            [37.74190529, -7.95664027],
                            [37.71970202, -7.97864373],
                            [37.68748859, -8.05333916],
                            [37.67143896, -8.07167987],
                            [37.65160374, -8.1182086],
                            [37.63281777, -8.21623666],
                            [37.62165056, -8.2431733],
                            [37.58077586, -8.31782287],
                            [37.5682766, -8.37049997],
                            [37.55710368, -8.39620706],
                            [37.52119182, -8.45247268],
                            [37.50016318, -8.47813965],
                            [37.44091827, -8.51468777],
                            [37.43336066, -8.55634161],
                            [37.4071429, -8.64205369],
                            [37.40077728, -8.69106639],
                            [37.40178506, -8.74378769],
                            [37.41264914, -8.7965506],
                            [37.41254453, -8.82106973],
                            [37.40353229, -8.91052734],
                            [37.38089058, -9.01340932],
                            [37.3804954, -9.10167563],
                            [37.37292973, -9.13842],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Morogoro", Region_Cod: 5, zoneId: 6 },
            drilldown: 5,
            region_nam: "Morogoro",
            value: 4.4,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.31",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [34.54198168, -11.55237471],
                            [34.53876694, -11.52569805],
                            [34.51533998, -11.40222057],
                            [34.48387591, -11.27361243],
                            [34.41684412, -10.86316093],
                            [34.41684539, -10.35967385],
                            [34.3402367, -10.2283292],
                            [34.14324283, -9.92185828],
                            [34.08852233, -9.82608613],
                            [34.02832968, -9.76315009],
                            [33.99002525, -9.73305022],
                            [33.93958287, -9.71772557],
                            [33.94003829, -9.7138793],
                            [33.94240419, -9.67602477],
                            [33.95605316, -9.64480505],
                            [33.95314152, -9.58971488],
                            [33.94586216, -9.56369196],
                            [33.96879239, -9.52617964],
                            [33.99618123, -9.50185727],
                            [34.02529888, -9.50042127],
                            [34.05951204, -9.53273134],
                            [34.07552675, -9.5298595],
                            [34.17194121, -9.60977979],
                            [34.23542611, -9.67163199],
                            [34.29556165, -9.72515921],
                            [34.32679523, -9.7568465],
                            [34.33561219, -9.78579869],
                            [34.3719143, -9.82796602],
                            [34.3881993, -9.8539512],
                            [34.41621544, -9.88420966],
                            [34.45194525, -9.93529709],
                            [34.45741493, -9.94920931],
                            [34.49113404, -9.97731022],
                            [34.50615631, -10.00270842],
                            [34.50455413, -10.01450041],
                            [34.53944013, -10.06407611],
                            [34.53645036, -10.09471048],
                            [34.51996263, -10.1120774],
                            [34.52912756, -10.13552913],
                            [34.56814364, -10.20263644],
                            [34.56798305, -10.26392175],
                            [34.58108599, -10.28420679],
                            [34.5838041, -10.30776178],
                            [34.55955645, -10.40190394],
                            [34.55949334, -10.43042801],
                            [34.57027781, -10.45051443],
                            [34.58796619, -10.45467535],
                            [34.59328752, -10.48946691],
                            [34.57213821, -10.51209862],
                            [34.56907733, -10.53193003],
                            [34.58562662, -10.57328595],
                            [34.61864144, -10.59034518],
                            [34.62008412, -10.62179839],
                            [34.64461261, -10.64514968],
                            [34.65922974, -10.68750698],
                            [34.64988246, -10.71223234],
                            [34.67897001, -10.74539877],
                            [34.68173568, -10.75904062],
                            [34.67199797, -10.78197292],
                            [34.67942301, -10.81093125],
                            [34.67026156, -10.84095501],
                            [34.6758264, -10.85768536],
                            [34.66983372, -10.89319084],
                            [34.67826669, -10.90327671],
                            [34.67298329, -10.9338633],
                            [34.65075965, -10.95526758],
                            [34.63453243, -10.99060583],
                            [34.62220129, -11.00044904],
                            [34.61157531, -11.02691069],
                            [34.61558506, -11.04617764],
                            [34.63668061, -11.05045398],
                            [34.63826743, -11.11632401],
                            [34.65614036, -11.13328588],
                            [34.6983051, -11.15814069],
                            [34.70290875, -11.17672021],
                            [34.71692333, -11.18916017],
                            [34.75576757, -11.20579387],
                            [34.77556055, -11.25385999],
                            [34.80036033, -11.27705391],
                            [34.7854624, -11.29465134],
                            [34.80036688, -11.31860245],
                            [34.88893499, -11.36826374],
                            [34.92849168, -11.39833108],
                            [34.92189664, -11.41978719],
                            [34.93250164, -11.44312517],
                            [34.93304246, -11.46320431],
                            [34.95506677, -11.48992299],
                            [34.96357564, -11.54869405],
                            [34.96065811, -11.57618618],
                            [34.54198168, -11.55237471],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Nyasa",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.32",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [39.56048827, -9.0308495],
                            [39.56961869, -9.03083544],
                            [39.61686196, -9.06666692],
                            [39.59807259, -9.08739082],
                            [39.57712825, -9.06997772],
                            [39.59123272, -9.05392845],
                            [39.57785197, -9.04400916],
                            [39.55514504, -9.07234479],
                            [39.5477983, -9.03817204],
                            [39.56048827, -9.0308495],
                        ],
                    ],
                    [
                        [
                            [39.00540429, -8.3349597],
                            [39.04019206, -8.33118565],
                            [39.08703149, -8.33012046],
                            [39.11317075, -8.31967822],
                            [39.14354295, -8.29755979],
                            [39.17690381, -8.26703484],
                            [39.19155328, -8.26248367],
                            [39.2129523, -8.25900241],
                            [39.24110299, -8.27150529],
                            [39.25090274, -8.27848051],
                            [39.24895271, -8.30321516],
                            [39.29085571, -8.37285203],
                            [39.29026298, -8.39753682],
                            [39.28186407, -8.42594682],
                            [39.28425397, -8.47213263],
                            [39.29160387, -8.48453936],
                            [39.28572377, -8.51123515],
                            [39.2968127, -8.53305865],
                            [39.32741564, -8.55793317],
                            [39.348769, -8.58412516],
                            [39.36323182, -8.62327808],
                            [39.35474653, -8.66578862],
                            [39.36747346, -8.71429901],
                            [39.39321101, -8.73411292],
                            [39.43226734, -8.74304491],
                            [39.45220245, -8.79313128],
                            [39.47659839, -8.81440663],
                            [39.49599799, -8.80040239],
                            [39.50128579, -8.84150535],
                            [39.51591865, -8.86787953],
                            [39.52083697, -8.89974518],
                            [39.5481076, -8.9105375],
                            [39.53964182, -8.92728668],
                            [39.51450554, -8.92789353],
                            [39.48539483, -8.90104759],
                            [39.47957744, -8.88528029],
                            [39.43582883, -8.86522794],
                            [39.4219465, -8.85363929],
                            [39.4040657, -8.85907452],
                            [39.3844651, -8.88945221],
                            [39.39092088, -8.8985825],
                            [39.37483679, -8.92953664],
                            [39.32745865, -8.95014111],
                            [39.36492778, -8.98317916],
                            [39.38160783, -8.93896819],
                            [39.41489608, -8.96939433],
                            [39.4437732, -8.96655635],
                            [39.47631036, -8.93721463],
                            [39.49850045, -8.95285141],
                            [39.54131996, -8.94964498],
                            [39.53329158, -8.97049494],
                            [39.55054723, -8.97640051],
                            [39.55089381, -8.99242939],
                            [39.5365797, -9.01425053],
                            [39.50850012, -9.01143988],
                            [39.49539748, -9.03070168],
                            [39.4731308, -9.03459919],
                            [39.43713419, -9.02536028],
                            [39.44710745, -9.08153303],
                            [39.47090841, -9.11165653],
                            [39.511436, -9.13094497],
                            [39.54209337, -9.10505232],
                            [39.54651485, -9.09037081],
                            [39.57830271, -9.10640339],
                            [39.59396165, -9.14775595],
                            [39.61325003, -9.16273659],
                            [39.62101856, -9.18209562],
                            [39.63495446, -9.18133809],
                            [39.63985992, -9.22428832],
                            [39.62753279, -9.24171601],
                            [39.64402113, -9.26518477],
                            [39.65686313, -9.35207643],
                            [39.64927321, -9.36722967],
                            [39.62689848, -9.37930663],
                            [39.60277735, -9.40599157],
                            [39.58093356, -9.39941857],
                            [39.56345565, -9.40708351],
                            [39.56942323, -9.426475],
                            [39.6264213, -9.41861743],
                            [39.65167054, -9.45691849],
                            [39.66609188, -9.49176333],
                            [39.68619102, -9.49519584],
                            [39.70055431, -9.52002674],
                            [39.69574113, -9.53654425],
                            [39.71026269, -9.55702563],
                            [39.71496019, -9.57974501],
                            [39.72578972, -9.58823976],
                            [39.72840857, -9.61075078],
                            [39.71811811, -9.62772248],
                            [39.74567561, -9.64846383],
                            [39.73612003, -9.68279663],
                            [39.75098408, -9.69773322],
                            [39.74404748, -9.70962581],
                            [39.71966965, -9.71935986],
                            [39.7232102, -9.73900235],
                            [39.7567868, -9.74202878],
                            [39.75499362, -9.76383189],
                            [39.76608708, -9.78533937],
                            [39.78240865, -9.78615997],
                            [39.7964251, -9.80096612],
                            [39.80631616, -9.83800204],
                            [39.80442673, -9.86123117],
                            [39.78925448, -9.89061953],
                            [39.78457159, -9.92694941],
                            [39.76749798, -9.93516646],
                            [39.71452464, -9.9491721],
                            [39.7053541, -9.96362719],
                            [39.7166982, -9.99341504],
                            [39.71324007, -10.01232796],
                            [39.68409814, -10.03991014],
                            [39.66864413, -10.04637521],
                            [39.66143688, -10.09384228],
                            [39.69111971, -10.09694159],
                            [39.67894929, -10.06506254],
                            [39.71541344, -10.03893338],
                            [39.72556332, -10.00678878],
                            [39.7592081, -9.97614808],
                            [39.79473241, -9.96637321],
                            [39.8201427, -9.97048291],
                            [39.83134181, -9.99854698],
                            [39.84733839, -10.00357044],
                            [39.88357846, -10.00215475],
                            [39.9079272, -10.02395793],
                            [39.91458451, -10.04797389],
                            [39.92967902, -10.07416149],
                            [39.94257891, -10.08196605],
                            [39.9667321, -10.07798833],
                            [39.97684496, -10.08913767],
                            [39.97843748, -10.11091867],
                            [39.96282827, -10.14204926],
                            [39.97961457, -10.17426163],
                            [39.96356924, -10.20034303],
                            [39.93033493, -10.21305568],
                            [39.90336719, -10.22424032],
                            [39.89274995, -10.23804421],
                            [39.89158408, -10.26180869],
                            [39.86622226, -10.25998214],
                            [39.77232028, -10.26551041],
                            [39.74600733, -10.28913618],
                            [39.72589784, -10.30351292],
                            [39.68871394, -10.33135661],
                            [39.69202729, -10.37889762],
                            [39.70484878, -10.42899269],
                            [39.71675176, -10.45742643],
                            [39.70061351, -10.4917592],
                            [39.71284834, -10.50787542],
                            [39.72851453, -10.53807891],
                            [39.7050671, -10.54830753],
                            [39.66295844, -10.57981898],
                            [39.64634241, -10.61475346],
                            [39.6227791, -10.61198081],
                            [39.6192105, -10.5978248],
                            [39.57985529, -10.59775692],
                            [39.57443691, -10.56171911],
                            [39.56459041, -10.5318033],
                            [39.55083833, -10.51337259],
                            [39.50729438, -10.47619278],
                            [39.49251625, -10.45697885],
                            [39.48328379, -10.39458218],
                            [39.46138083, -10.36269089],
                            [39.39604889, -10.33297423],
                            [39.35774994, -10.34985652],
                            [39.33460571, -10.32702159],
                            [39.30444785, -10.33740318],
                            [39.29030548, -10.33577471],
                            [39.25916842, -10.35301205],
                            [39.24803779, -10.34718849],
                            [39.21356486, -10.36091917],
                            [39.20516391, -10.37265029],
                            [39.17304681, -10.39086075],
                            [39.15005468, -10.38508743],
                            [39.1415368, -10.39793297],
                            [39.12126194, -10.40538233],
                            [39.11048733, -10.41945539],
                            [39.08140353, -10.42890713],
                            [39.04885017, -10.45185517],
                            [39.00399358, -10.46339191],
                            [38.98187693, -10.46492325],
                            [38.96378894, -10.47778805],
                            [38.95017129, -10.49260282],
                            [38.91637539, -10.50030206],
                            [38.88527833, -10.51495826],
                            [38.87478345, -10.54514135],
                            [38.83989925, -10.54467458],
                            [38.8286259, -10.55036264],
                            [38.79155888, -10.54909641],
                            [38.75956402, -10.54487751],
                            [38.71678402, -10.48596369],
                            [38.69907423, -10.48213561],
                            [38.68632679, -10.5005343],
                            [38.62323022, -10.50447581],
                            [38.5664948, -10.54344582],
                            [38.51826414, -10.56219274],
                            [38.49447112, -10.57724471],
                            [38.42381402, -10.60478392],
                            [38.38116203, -10.60431505],
                            [38.32035307, -10.6043815],
                            [38.31261946, -10.60391272],
                            [38.28121634, -10.60567047],
                            [38.27616085, -10.65953743],
                            [38.26397339, -10.68418577],
                            [38.2046614, -10.69407204],
                            [38.19464065, -10.69488459],
                            [38.13681828, -10.68648774],
                            [38.10985884, -10.68531818],
                            [38.05892922, -10.70201111],
                            [38.00285341, -10.7211332],
                            [37.98375308, -10.72561299],
                            [37.96038539, -10.73355195],
                            [37.90485647, -10.73582486],
                            [37.88559739, -10.73454073],
                            [37.88348344, -10.71361747],
                            [37.88345759, -10.69815838],
                            [37.8851696, -10.66648435],
                            [37.87900918, -10.64741139],
                            [37.88383445, -10.62638318],
                            [37.86051108, -10.56961399],
                            [37.86418052, -10.54956281],
                            [37.83644251, -10.5421479],
                            [37.7890846, -10.54671437],
                            [37.75271974, -10.5451553],
                            [37.7052379, -10.53560931],
                            [37.65917416, -10.51831955],
                            [37.64571786, -10.51262894],
                            [37.61857647, -10.50816335],
                            [37.60424816, -10.49683398],
                            [37.59024729, -10.47957574],
                            [37.55307347, -10.46488107],
                            [37.50147887, -10.43607311],
                            [37.42688734, -10.40390192],
                            [37.38185442, -10.38786617],
                            [37.34119667, -10.37589899],
                            [37.23060771, -10.34250239],
                            [37.16626685, -10.30469306],
                            [37.04667395, -10.28403396],
                            [37.0162225, -10.27219006],
                            [36.99369449, -10.25387182],
                            [36.98299067, -10.24303612],
                            [36.96214826, -10.19558057],
                            [36.94809701, -10.1713207],
                            [36.89778195, -10.10636593],
                            [36.88471563, -10.09653397],
                            [36.87410468, -10.09212379],
                            [36.86932607, -10.0573577],
                            [36.86812332, -10.04990668],
                            [36.88459846, -10.0183781],
                            [36.88155848, -10.00471258],
                            [36.88425765, -9.974336],
                            [36.8901114, -9.93963717],
                            [36.90403394, -9.92678293],
                            [36.96933904, -9.88686463],
                            [36.98195467, -9.86957158],
                            [37.00286348, -9.82503248],
                            [37.00980941, -9.81390741],
                            [37.03368675, -9.79481627],
                            [37.04449884, -9.76386143],
                            [37.05710878, -9.74594345],
                            [37.07467646, -9.735497],
                            [37.09548858, -9.70397509],
                            [37.11322957, -9.66188793],
                            [37.1234271, -9.6265822],
                            [37.13878332, -9.5849751],
                            [37.25074401, -9.52223373],
                            [37.27131285, -9.4945599],
                            [37.27315511, -9.45600324],
                            [37.28493923, -9.43804367],
                            [37.28460096, -9.38503221],
                            [37.29030919, -9.35200302],
                            [37.31785248, -9.31654898],
                            [37.32578404, -9.28759897],
                            [37.32026275, -9.27208666],
                            [37.33421649, -9.2412207],
                            [37.34805995, -9.18244071],
                            [37.37292973, -9.13842],
                            [37.3804954, -9.10167563],
                            [37.38089058, -9.01340932],
                            [37.40353229, -8.91052734],
                            [37.41254453, -8.82106973],
                            [37.41264914, -8.7965506],
                            [37.40178506, -8.74378769],
                            [37.40077728, -8.69106639],
                            [37.4071429, -8.64205369],
                            [37.43336066, -8.55634161],
                            [37.44091827, -8.51468777],
                            [37.50016318, -8.47813965],
                            [37.52119182, -8.45247268],
                            [37.55710368, -8.39620706],
                            [37.5682766, -8.37049997],
                            [37.58077586, -8.31782287],
                            [37.62165056, -8.2431733],
                            [37.63281778, -8.21623666],
                            [37.65160374, -8.1182086],
                            [37.67143896, -8.07167987],
                            [37.68748859, -8.05333916],
                            [37.71970202, -7.97864373],
                            [37.7419053, -7.95664027],
                            [37.7628472, -7.94444116],
                            [37.8010574, -7.93252521],
                            [37.80781192, -7.94533645],
                            [37.83979016, -7.96754656],
                            [37.83383296, -7.98007014],
                            [37.8421, -7.99964588],
                            [37.84270787, -8.00195606],
                            [37.89121607, -8.03259641],
                            [37.99893106, -8.08415009],
                            [38.12938055, -8.12524723],
                            [38.28669793, -8.20950825],
                            [38.30882448, -8.22191031],
                            [38.37702776, -8.26008919],
                            [38.42040949, -8.27913102],
                            [38.48250573, -8.31443995],
                            [38.48663141, -8.32406768],
                            [38.51035834, -8.35345914],
                            [38.51517185, -8.38313555],
                            [38.50997655, -8.41185173],
                            [38.51325437, -8.49662725],
                            [38.51624698, -8.50110924],
                            [38.62555016, -8.51129365],
                            [38.6731622, -8.50895304],
                            [38.71776699, -8.51251862],
                            [38.74443671, -8.49159485],
                            [38.77987014, -8.44330416],
                            [38.81040692, -8.41341328],
                            [38.83821485, -8.39836267],
                            [38.93882031, -8.37179828],
                            [38.98379953, -8.34971724],
                            [39.00540429, -8.3349597],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Lindi", Region_Cod: 8, zoneId: 6 },
            drilldown: 8,
            region_nam: "Lindi",
            value: 4.1,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.33",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [38.79535902, -5.9631551],
                            [38.79084592, -5.96676558],
                            [38.74571488, -5.95232365],
                            [38.73037033, -5.95322627],
                            [38.70474281, -5.9459938],
                            [38.68900538, -5.96109681],
                            [38.65506403, -5.95434269],
                            [38.64211591, -5.97524583],
                            [38.62467404, -5.98649044],
                            [38.59626747, -5.98129665],
                            [38.56173168, -6.01571418],
                            [38.54144468, -5.99730295],
                            [38.51406061, -6.00652167],
                            [38.48259168, -5.99948662],
                            [38.4642878, -6.01973843],
                            [38.44806656, -6.00813336],
                            [38.42877688, -6.01391288],
                            [38.42245602, -6.00592963],
                            [38.40041982, -5.98158383],
                            [38.39647884, -5.98900295],
                            [38.36541477, -5.9830908],
                            [38.34165307, -5.9871481],
                            [38.33991443, -5.96894808],
                            [38.33701668, -5.95631239],
                            [38.31487776, -5.93428686],
                            [38.29922982, -5.92617218],
                            [38.249016, -5.92698175],
                            [38.23864967, -5.92119256],
                            [38.2262841, -5.90950751],
                            [38.22482111, -5.90517108],
                            [38.20828158, -5.88328291],
                            [38.20428463, -5.88174544],
                            [38.18952666, -5.87252065],
                            [38.1775358, -5.87036819],
                            [38.15257048, -5.85459689],
                            [38.13566149, -5.86074628],
                            [38.10466169, -5.85562174],
                            [38.06751316, -5.85229076],
                            [38.0639264, -5.85818394],
                            [38.05752147, -5.86330845],
                            [38.00679453, -5.86023368],
                            [37.99526566, -5.86997023],
                            [37.97272037, -5.84921595],
                            [37.95581139, -5.85024083],
                            [37.93275369, -5.84357894],
                            [37.91712568, -5.84870343],
                            [37.89906378, -5.87206263],
                            [37.87822644, -5.87718711],
                            [37.86524579, -5.89187736],
                            [37.81913039, -5.88401972],
                            [37.79292394, -5.89065662],
                            [37.75442534, -5.88855387],
                            [37.75233342, -5.87286468],
                            [37.72302791, -5.86966021],
                            [37.72156599, -5.86689986],
                            [37.71014791, -5.8763238],
                            [37.68880446, -5.91327134],
                            [37.66521901, -5.93468527],
                            [37.63348968, -5.94927015],
                            [37.6265727, -5.9123878],
                            [37.61154557, -5.89863079],
                            [37.61066462, -5.87433121],
                            [37.60826429, -5.85616613],
                            [37.57977936, -5.8398715],
                            [37.56417677, -5.8417157],
                            [37.54476977, -5.81556792],
                            [37.48393243, -5.82046295],
                            [37.46006616, -5.80006895],
                            [37.44192319, -5.80239237],
                            [37.41091186, -5.83979651],
                            [37.40837294, -5.84362821],
                            [37.39351159, -5.87529973],
                            [37.3929941, -5.88610824],
                            [37.39446852, -5.92851541],
                            [37.37602808, -5.96514843],
                            [37.36099074, -5.97533461],
                            [37.33712303, -5.97829226],
                            [37.31870541, -5.98125401],
                            [37.28998532, -5.98975945],
                            [37.28533822, -5.99121726],
                            [37.26441811, -5.99419727],
                            [37.22913997, -6.00010462],
                            [37.17387036, -5.99956721],
                            [37.14633712, -5.98950107],
                            [37.12251733, -5.95950784],
                            [37.07365578, -5.85841883],
                            [37.02389213, -5.89452068],
                            [37.01973902, -5.87850581],
                            [37.05007126, -5.82782266],
                            [37.06124602, -5.82371164],
                            [37.08568448, -5.78640675],
                            [37.1026434, -5.7685935],
                            [37.1162983, -5.77151955],
                            [37.14745672, -5.74122678],
                            [37.20131532, -5.74855173],
                            [37.20541115, -5.71469368],
                            [37.16526292, -5.71603419],
                            [37.18394734, -5.6830501],
                            [37.22204221, -5.64236943],
                            [37.22353594, -5.62770947],
                            [37.2422431, -5.6258027],
                            [37.25550961, -5.58461826],
                            [37.23745632, -5.57065971],
                            [37.25070035, -5.53639672],
                            [37.26981008, -5.52328298],
                            [37.33001971, -5.44928677],
                            [37.38666518, -5.26172734],
                            [37.38666518, -5.25291582],
                            [37.35897184, -5.21641096],
                            [37.38020052, -5.19393354],
                            [37.48228991, -5.07182922],
                            [37.48988581, -5.05906244],
                            [37.58429492, -5.00493455],
                            [37.62912733, -5.01331813],
                            [37.68556691, -5.03919491],
                            [37.7353495, -5.01626364],
                            [37.76529187, -5.00925586],
                            [37.78766952, -5.02234806],
                            [37.82461442, -5.02027831],
                            [37.82678765, -5.00666447],
                            [37.84839172, -4.97867649],
                            [37.94383158, -4.96087671],
                            [37.98015556, -4.98892172],
                            [38.00005383, -4.9898646],
                            [37.99453452, -4.91664171],
                            [37.99968587, -4.86568006],
                            [37.99398258, -4.83348407],
                            [37.99766213, -4.79448092],
                            [37.99527042, -4.76946003],
                            [37.98147214, -4.75161426],
                            [37.99361463, -4.70801168],
                            [37.99855631, -4.6113298],
                            [38.03736733, -4.59346953],
                            [38.04978201, -4.61371021],
                            [38.07887582, -4.61264395],
                            [38.10888358, -4.59604067],
                            [38.11863229, -4.57258283],
                            [38.12021646, -4.53062275],
                            [38.17897868, -4.45328206],
                            [38.27404534, -4.31193872],
                            [38.40366032, -4.10868981],
                            [38.49104921, -4.16895668],
                            [38.75990447, -4.35803869],
                            [38.80193641, -4.38519667],
                            [38.96363562, -4.49611985],
                            [39.19191752, -4.65801097],
                            [39.19182592, -4.67621871],
                            [39.17987995, -4.68650057],
                            [39.1872496, -4.71645464],
                            [39.16674532, -4.74391254],
                            [39.1754582, -4.7633561],
                            [39.16767598, -4.78451828],
                            [39.19606747, -4.828127],
                            [39.19177036, -4.84441651],
                            [39.21131319, -4.85740503],
                            [39.18545997, -4.90634784],
                            [39.1740489, -4.89823528],
                            [39.17803027, -4.85247335],
                            [39.16437499, -4.82826073],
                            [39.1547989, -4.86531315],
                            [39.13286825, -4.91945632],
                            [39.11497901, -4.90679716],
                            [39.09822496, -4.91642987],
                            [39.13553474, -4.93939841],
                            [39.14235464, -4.96083596],
                            [39.11800176, -4.98293297],
                            [39.13502654, -4.99935828],
                            [39.12771308, -5.02477556],
                            [39.10613237, -5.01866103],
                            [39.05026231, -5.04335895],
                            [39.09938669, -5.06609006],
                            [39.12503671, -5.05538355],
                            [39.1267159, -5.10306544],
                            [39.1002877, -5.13817204],
                            [39.09549198, -5.18049421],
                            [39.06431023, -5.20978994],
                            [39.08760609, -5.23058982],
                            [39.07047178, -5.26037605],
                            [39.06698563, -5.28498827],
                            [39.04804422, -5.33000608],
                            [39.02697213, -5.39108316],
                            [39.0172187, -5.4083779],
                            [38.992305, -5.41059215],
                            [38.98141715, -5.42887399],
                            [38.99998667, -5.45461994],
                            [38.99075095, -5.49015443],
                            [38.96879468, -5.53377807],
                            [38.96836133, -5.54952303],
                            [38.94964714, -5.56741867],
                            [38.93289104, -5.6131716],
                            [38.91613494, -5.62684612],
                            [38.89825778, -5.66167979],
                            [38.89103681, -5.71764227],
                            [38.82153502, -5.8367882],
                            [38.81521668, -5.86567206],
                            [38.8161193, -5.93427124],
                            [38.79535902, -5.9631551],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Tanga", Region_Cod: 4, zoneId: 2 },
            drilldown: 4,
            region_nam: "Tanga",
            value: 5.2,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.34",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [34.89765377, -4.29601984],
                            [34.88177449, -4.32706502],
                            [34.86568543, -4.35968154],
                            [34.86630749, -4.39178028],
                            [34.87476763, -4.41121992],
                            [34.89927713, -4.42223054],
                            [34.96815353, -4.45505383],
                            [34.98499706, -4.48213433],
                            [35.0007683, -4.50560627],
                            [35.03198419, -4.52970217],
                            [35.04294781, -4.5467726],
                            [35.07298481, -4.61615181],
                            [35.08993295, -4.62396361],
                            [35.12771885, -4.62855557],
                            [35.14545797, -4.68027553],
                            [35.14543133, -4.73308407],
                            [35.1529351, -4.75157723],
                            [35.17353122, -4.76336945],
                            [35.21889373, -4.77443135],
                            [35.28341993, -4.80410311],
                            [35.31793635, -4.8622906],
                            [35.3390285, -4.87569721],
                            [35.34065191, -4.87699079],
                            [35.3198734, -4.92424104],
                            [35.29427053, -4.96177909],
                            [35.28044723, -4.97805492],
                            [35.24665306, -4.9923012],
                            [35.22724913, -5.00214903],
                            [35.18867658, -5.03976145],
                            [35.1677179, -5.03650614],
                            [35.1440658, -5.06160054],
                            [35.11848803, -5.10323182],
                            [35.07394279, -5.12596978],
                            [35.06665926, -5.15079013],
                            [35.09268486, -5.1774851],
                            [35.09550987, -5.23401124],
                            [35.09003195, -5.26694437],
                            [35.1093724, -5.30553146],
                            [35.1144167, -5.32705901],
                            [35.12669999, -5.34060957],
                            [35.124391, -5.37373021],
                            [35.16339844, -5.43877999],
                            [35.19543713, -5.47418889],
                            [35.19912511, -5.50737911],
                            [35.20732241, -5.51964925],
                            [35.24341647, -5.54337174],
                            [35.24926292, -5.578957],
                            [35.26950016, -5.60286022],
                            [35.2903402, -5.60768638],
                            [35.31626542, -5.62941304],
                            [35.35013324, -5.66976718],
                            [35.33307239, -5.71484683],
                            [35.29254377, -5.74027186],
                            [35.27755829, -5.77224164],
                            [35.26670589, -5.78935811],
                            [35.25945157, -5.82371473],
                            [35.25308949, -5.84734054],
                            [35.240667, -5.86455429],
                            [35.22979473, -5.9074476],
                            [35.22211551, -5.94150457],
                            [35.21165007, -5.9581367],
                            [35.20480228, -6.10817074],
                            [35.19276634, -6.13988953],
                            [35.16725172, -6.18335852],
                            [35.16412356, -6.20848371],
                            [35.17443914, -6.24261946],
                            [35.19758376, -6.25352297],
                            [35.21018107, -6.29075981],
                            [35.21850548, -6.34511753],
                            [35.24272461, -6.37964282],
                            [35.24342759, -6.42306267],
                            [35.24141318, -6.43896604],
                            [35.20464212, -6.45743859],
                            [35.20174868, -6.46591231],
                            [35.21821063, -6.50019881],
                            [35.22461733, -6.52815255],
                            [35.20756267, -6.55213031],
                            [35.19819396, -6.58791563],
                            [35.19859996, -6.62312261],
                            [35.18568876, -6.64862373],
                            [35.18513197, -6.66621345],
                            [35.20016111, -6.72238294],
                            [35.1453521, -6.73314266],
                            [35.12797163, -6.73291648],
                            [35.09514404, -6.74372613],
                            [35.06147205, -6.71800046],
                            [35.05715183, -6.73285716],
                            [35.07247209, -6.76730447],
                            [35.08205557, -6.86560092],
                            [35.08305825, -6.92210864],
                            [35.08267619, -6.92475874],
                            [35.01979893, -6.90221143],
                            [34.99326641, -6.89850213],
                            [34.97002446, -6.90372044],
                            [34.94759041, -6.88787802],
                            [34.91612315, -6.89085076],
                            [34.89474815, -6.91589557],
                            [34.87434365, -6.91751095],
                            [34.85593088, -6.93642512],
                            [34.8382846, -6.94097568],
                            [34.8188622, -6.95980146],
                            [34.78208415, -6.97640597],
                            [34.75624821, -7.00696257],
                            [34.72550085, -7.02230658],
                            [34.72206139, -7.03297573],
                            [34.69055674, -7.04232938],
                            [34.67453448, -7.05487327],
                            [34.64804433, -7.11067605],
                            [34.6139615, -7.14427653],
                            [34.60872389, -7.16533451],
                            [34.56147939, -7.20876685],
                            [34.51094587, -7.23002302],
                            [34.47124585, -7.27461327],
                            [34.45029323, -7.28254837],
                            [34.43107265, -7.31687018],
                            [34.41186702, -7.31313419],
                            [34.3876611, -7.32185472],
                            [34.36222738, -7.31886762],
                            [34.34283767, -7.33017874],
                            [34.31321264, -7.33538956],
                            [34.29234249, -7.32662141],
                            [34.27298585, -7.3343617],
                            [34.25138744, -7.32710316],
                            [34.23352233, -7.33260189],
                            [34.19379884, -7.37076858],
                            [34.1510947, -7.38003287],
                            [34.09979477, -7.39618024],
                            [34.05946484, -7.40062574],
                            [34.01800853, -7.41422373],
                            [34.00498972, -7.43484865],
                            [34.01539526, -7.47395585],
                            [33.98296363, -7.5284926],
                            [33.9364323, -7.53477321],
                            [33.91462967, -7.52520796],
                            [33.87015176, -7.52712927],
                            [33.80112934, -7.52405702],
                            [33.80111328, -7.49063301],
                            [33.81789186, -7.45392572],
                            [33.81402198, -7.40656123],
                            [33.80159437, -7.37929348],
                            [33.79458784, -7.33084271],
                            [33.7851318, -7.29698934],
                            [33.79512811, -7.24731117],
                            [33.8072215, -7.1370535],
                            [33.79043526, -7.11667174],
                            [33.73765179, -7.1072971],
                            [33.71451898, -7.08474087],
                            [33.69537906, -7.0812941],
                            [33.68219149, -7.05090044],
                            [33.66304568, -7.04223849],
                            [33.64146796, -7.02131707],
                            [33.63064677, -7.0222007],
                            [33.57398627, -6.99292166],
                            [33.54833687, -6.95500706],
                            [33.51250019, -6.95583037],
                            [33.48654879, -6.94316312],
                            [33.49743059, -6.93253711],
                            [33.52073059, -6.88993711],
                            [33.52493059, -6.86393712],
                            [33.52463059, -6.81303711],
                            [33.52463059, -6.78593711],
                            [33.52303059, -6.72103712],
                            [33.52173059, -6.69703711],
                            [33.53333059, -6.64363712],
                            [33.54823059, -6.60593712],
                            [33.57530728, -6.57331989],
                            [33.58473059, -6.56433712],
                            [33.58963059, -6.55693712],
                            [33.64303059, -6.53753712],
                            [33.64693059, -6.53313711],
                            [33.67653059, -6.51093712],
                            [33.69913059, -6.46563712],
                            [33.72066902, -6.45124352],
                            [33.74903059, -6.43113712],
                            [33.77173059, -6.41873712],
                            [33.78143059, -6.41533712],
                            [33.78756225, -6.3886689],
                            [33.81475243, -6.37720755],
                            [33.82366864, -6.3759964],
                            [33.85659343, -6.35874997],
                            [33.86033059, -6.35833712],
                            [33.87439499, -6.34854125],
                            [33.88331597, -6.32687916],
                            [33.90303231, -6.30173368],
                            [33.90095841, -6.29251563],
                            [33.91511363, -6.2419925],
                            [33.92920861, -6.18144634],
                            [33.93056678, -6.16960666],
                            [33.95693178, -6.12634423],
                            [33.97486382, -6.07961337],
                            [33.96569018, -6.06371166],
                            [33.99427717, -6.02537718],
                            [34.01208472, -5.97534796],
                            [34.01216324, -5.96138472],
                            [34.01400503, -5.93727946],
                            [34.03080608, -5.90804605],
                            [34.09615501, -5.88112327],
                            [34.10671506, -5.86361431],
                            [34.10787144, -5.81309959],
                            [34.08364729, -5.78497955],
                            [34.06313861, -5.7629446],
                            [34.0525654, -5.72959831],
                            [34.02870789, -5.71306072],
                            [34.00573855, -5.70685526],
                            [34.01787466, -5.68022857],
                            [34.07073645, -5.64832254],
                            [34.10617397, -5.59940484],
                            [34.12124985, -5.57741212],
                            [34.15922937, -5.47925865],
                            [34.16825343, -5.46061541],
                            [34.18366993, -5.43676871],
                            [34.20785836, -5.4238981],
                            [34.21211009, -5.42078226],
                            [34.23716312, -5.40922667],
                            [34.24106064, -5.39391303],
                            [34.20992178, -5.35705575],
                            [34.20955864, -5.35385654],
                            [34.19173622, -5.3307724],
                            [34.18362577, -5.3105016],
                            [34.17139529, -5.29816433],
                            [34.18200591, -5.27720101],
                            [34.18219663, -5.24804449],
                            [34.18367567, -5.228659],
                            [34.15872678, -5.20353251],
                            [34.116232, -5.19135539],
                            [34.08662449, -5.19368186],
                            [34.06225497, -5.19384654],
                            [34.03180081, -5.20527266],
                            [34.00288909, -5.20363801],
                            [33.97602382, -5.18486871],
                            [33.97489789, -5.18394337],
                            [33.93141976, -5.156722],
                            [33.92593018, -5.12744817],
                            [33.92704666, -5.10641946],
                            [33.92617761, -5.07147136],
                            [33.92526304, -4.97467382],
                            [33.91653948, -4.94343262],
                            [33.91375088, -4.91008757],
                            [33.91931343, -4.8756237],
                            [33.915956, -4.84556616],
                            [33.92725919, -4.81865064],
                            [33.93062621, -4.76570824],
                            [33.93257085, -4.73255334],
                            [33.9237998, -4.7107539],
                            [33.92315661, -4.68318747],
                            [33.92071751, -4.63627081],
                            [33.91402627, -4.6062146],
                            [33.93592269, -4.55462912],
                            [33.94124402, -4.51011053],
                            [33.97432772, -4.45305535],
                            [34.00280959, -4.4310732],
                            [34.01642663, -4.42880907],
                            [34.04923059, -4.39953712],
                            [34.06120048, -4.37206089],
                            [34.07345666, -4.34526153],
                            [34.08253698, -4.29062962],
                            [34.09552211, -4.23840243],
                            [34.10217761, -4.15615582],
                            [34.10889417, -4.13881839],
                            [34.13259541, -4.10084969],
                            [34.15758567, -4.08383505],
                            [34.17373, -4.07706521],
                            [34.20135153, -4.07321214],
                            [34.20464179, -4.07825212],
                            [34.26525645, -4.08277163],
                            [34.310231, -4.0824074],
                            [34.33517599, -4.06921309],
                            [34.36578093, -4.04659779],
                            [34.37555105, -4.04360694],
                            [34.38651752, -4.04061608],
                            [34.38771386, -4.01170449],
                            [34.39489192, -3.9867807],
                            [34.42400374, -3.96555828],
                            [34.43309581, -3.95161228],
                            [34.45447088, -3.94643944],
                            [34.48197471, -3.93060335],
                            [34.51959959, -3.92917029],
                            [34.52017824, -3.9197711],
                            [34.55933022, -3.91089127],
                            [34.59646405, -3.92178924],
                            [34.63281443, -3.89953236],
                            [34.63945378, -3.88290998],
                            [34.64721832, -3.85770933],
                            [34.66877348, -3.86817747],
                            [34.68131093, -3.86426218],
                            [34.69620026, -3.85060308],
                            [34.70864532, -3.83207815],
                            [34.72390589, -3.81310468],
                            [34.74744895, -3.80392958],
                            [34.74922416, -3.8052874],
                            [34.75008778, -3.83692779],
                            [34.75141041, -3.90305938],
                            [34.76856465, -3.93970664],
                            [34.80142364, -3.9444378],
                            [34.81068665, -3.943995],
                            [34.83964959, -3.93214697],
                            [34.86706759, -3.90283806],
                            [34.88566374, -3.90569901],
                            [34.89867186, -3.93000719],
                            [34.9106519, -3.96556225],
                            [34.93610949, -3.99230341],
                            [34.95407956, -3.99140491],
                            [34.98663174, -3.98871689],
                            [34.98484066, -4.01723734],
                            [34.96593169, -4.09003688],
                            [34.969826, -4.14226644],
                            [34.96418384, -4.15761263],
                            [34.96898825, -4.18941327],
                            [34.9675393, -4.21297778],
                            [34.95636597, -4.23678305],
                            [34.91073934, -4.26960942],
                            [34.89765377, -4.29601984],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Singida", Region_Cod: 13, zoneId: 4 },
            drilldown: 13,
            region_nam: "Singida",
            value: 5.1,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.35",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [36.92408957, -3.18576932],
                            [36.93968297, -3.19615528],
                            [36.95244907, -3.20586328],
                            [36.9797411, -3.20781064],
                            [36.97979882, -3.22816427],
                            [36.99771463, -3.24401729],
                            [37.03045931, -3.25655257],
                            [37.04561995, -3.28496974],
                            [37.0568426, -3.33046608],
                            [37.06323585, -3.36742129],
                            [37.05421257, -3.37107524],
                            [37.05839063, -3.45898951],
                            [37.06049722, -3.48858684],
                            [37.0604354, -3.49081114],
                            [37.02697412, -3.49770229],
                            [36.98243852, -3.53850099],
                            [36.96537228, -3.57064278],
                            [36.9159883, -3.572328],
                            [36.89262122, -3.59585254],
                            [36.88393075, -3.58217935],
                            [36.86532169, -3.58400799],
                            [36.86069531, -3.58667345],
                            [36.83595495, -3.64669572],
                            [36.80067304, -3.65239676],
                            [36.78367749, -3.61776025],
                            [36.74527614, -3.5719368],
                            [36.72698979, -3.55612448],
                            [36.71375907, -3.55988932],
                            [36.69083678, -3.59457553],
                            [36.68763827, -3.61381614],
                            [36.67100495, -3.60934468],
                            [36.64940263, -3.63426697],
                            [36.63644698, -3.63361237],
                            [36.61696305, -3.6484279],
                            [36.61013614, -3.68268434],
                            [36.58514479, -3.69487524],
                            [36.56246556, -3.71406243],
                            [36.55189007, -3.70905897],
                            [36.52846479, -3.73646427],
                            [36.49334845, -3.76059076],
                            [36.48100327, -3.77939636],
                            [36.42103797, -3.78764425],
                            [36.39426877, -3.79559585],
                            [36.29778606, -3.8029956],
                            [36.29085483, -3.85679514],
                            [36.29399038, -3.89673223],
                            [36.29283537, -3.94541578],
                            [36.29795109, -3.96026851],
                            [36.29514559, -4.03783228],
                            [36.29696091, -4.07331357],
                            [36.29508127, -4.14362006],
                            [36.2797412, -4.14585174],
                            [36.14839909, -3.86873473],
                            [36.00004356, -3.68651863],
                            [35.98403517, -3.66034328],
                            [35.94169956, -3.63486675],
                            [35.88095373, -3.64118617],
                            [35.86366286, -3.58962938],
                            [35.88651461, -3.52935875],
                            [35.88185451, -3.50626537],
                            [35.88969038, -3.46190812],
                            [35.87871326, -3.42914935],
                            [35.84260611, -3.41962203],
                            [35.821791, -3.42776858],
                            [35.8155085, -3.44347484],
                            [35.7947279, -3.46432447],
                            [35.79141405, -3.50056969],
                            [35.76673279, -3.53180962],
                            [35.77591887, -3.55941674],
                            [35.7629189, -3.55754528],
                            [35.74244601, -3.56994353],
                            [35.71520192, -3.55455064],
                            [35.7147691, -3.56725219],
                            [35.71474128, -3.57098922],
                            [35.71444305, -3.58050966],
                            [35.71262372, -3.62912502],
                            [35.69772636, -3.69833092],
                            [35.67933516, -3.75825023],
                            [35.6633883, -3.76326156],
                            [35.59123076, -3.77293785],
                            [35.60493812, -3.74427376],
                            [35.6240634, -3.69545509],
                            [35.60528085, -3.69040762],
                            [35.54392004, -3.6907682],
                            [35.5229241, -3.67629998],
                            [35.46479363, -3.65929074],
                            [35.40929817, -3.62451105],
                            [35.38522913, -3.61479287],
                            [35.36235702, -3.64623983],
                            [35.3345935, -3.67955606],
                            [35.32855795, -3.68848868],
                            [35.29210323, -3.68728157],
                            [35.26868529, -3.70466395],
                            [35.25290979, -3.71726875],
                            [35.23611829, -3.73345946],
                            [35.20407722, -3.75064091],
                            [35.18064135, -3.7719355],
                            [35.13999507, -3.80125651],
                            [35.107954, -3.81936669],
                            [35.07105615, -3.83419412],
                            [35.04142709, -3.85658714],
                            [35.00798645, -3.87159523],
                            [34.98304516, -3.86090906],
                            [34.89139395, -3.90639787],
                            [34.88566374, -3.90569901],
                            [34.86706759, -3.90283806],
                            [34.83964959, -3.93214697],
                            [34.81068665, -3.943995],
                            [34.80142364, -3.9444378],
                            [34.76856465, -3.93970664],
                            [34.75141041, -3.90305938],
                            [34.75008778, -3.83692779],
                            [34.7492616, -3.80549957],
                            [34.74922416, -3.8052874],
                            [34.74920602, -3.80518463],
                            [34.77663886, -3.79626337],
                            [34.7936967, -3.82350369],
                            [34.82348264, -3.81456138],
                            [34.82591183, -3.79916952],
                            [34.84404543, -3.78481977],
                            [34.90075465, -3.76513167],
                            [34.93636797, -3.74573411],
                            [34.94747267, -3.748694],
                            [35.04445495, -3.7176847],
                            [35.08752201, -3.69631039],
                            [35.16089142, -3.65173151],
                            [35.18093286, -3.64588609],
                            [35.19384448, -3.63247878],
                            [35.21871095, -3.62644323],
                            [35.24624231, -3.60685937],
                            [35.23889912, -3.56729675],
                            [35.24052215, -3.52690143],
                            [35.2776193, -3.52407468],
                            [35.29263441, -3.50018782],
                            [35.33994835, -3.49732935],
                            [35.35459714, -3.47049461],
                            [35.34532733, -3.43001642],
                            [35.28317911, -3.35479256],
                            [35.26157466, -3.35883702],
                            [35.24046513, -3.38777139],
                            [35.20256724, -3.41235538],
                            [35.19932636, -3.44508537],
                            [35.18828984, -3.46079344],
                            [35.15800487, -3.47757228],
                            [35.12885262, -3.48599664],
                            [35.1069834, -3.49920311],
                            [35.09967951, -3.52111479],
                            [35.07591919, -3.54229407],
                            [35.03983017, -3.54065067],
                            [35.0287208, -3.56543312],
                            [35.00873708, -3.58226151],
                            [34.99881096, -3.60448025],
                            [34.97350263, -3.61539242],
                            [34.93912274, -3.60645233],
                            [34.92413495, -3.61585257],
                            [34.8904105, -3.62084614],
                            [34.8899374, -3.50144738],
                            [34.88683349, -3.23612206],
                            [34.88376735, -3.05036756],
                            [34.88081107, -2.96632569],
                            [34.88168464, -2.9042207],
                            [34.98714126, -2.98836744],
                            [35.01151103, -2.99929558],
                            [35.0046263, -2.97580007],
                            [35.04025206, -2.96530904],
                            [35.04549757, -2.96224916],
                            [35.06975806, -2.94312491],
                            [35.12887934, -2.91340034],
                            [35.13937036, -2.90061441],
                            [35.18479728, -2.71959245],
                            [35.20495935, -2.61556689],
                            [35.21643003, -2.53069406],
                            [35.21749039, -2.49755518],
                            [35.20636943, -2.48506867],
                            [35.16019574, -2.39144831],
                            [35.26591682, -2.35533501],
                            [35.26582844, -2.30000364],
                            [35.23555541, -2.16293378],
                            [35.2104991, -2.06649246],
                            [35.23760984, -1.83975098],
                            [35.25606589, -1.68984348],
                            [35.32058508, -1.7195165],
                            [35.56662709, -1.85862832],
                            [35.73732108, -1.9554764],
                            [36.07313384, -2.14333885],
                            [36.20274017, -2.22494648],
                            [36.25044318, -2.26338486],
                            [36.49730573, -2.38541986],
                            [36.77887231, -2.54087052],
                            [37.01994042, -2.67671938],
                            [37.27860134, -2.82235069],
                            [37.42937197, -2.90642488],
                            [37.40881536, -2.90286137],
                            [37.38253367, -2.88266633],
                            [37.25067631, -2.84952247],
                            [37.12570184, -2.85761209],
                            [37.09629616, -2.88306702],
                            [37.06987815, -2.91817387],
                            [37.06289052, -2.93564294],
                            [37.03653208, -2.94788196],
                            [37.03389231, -2.96123947],
                            [36.99197336, -2.97779934],
                            [36.97932267, -2.97241882],
                            [36.94376513, -2.97788588],
                            [36.93161931, -2.99444574],
                            [36.9344466, -3.03991323],
                            [36.91553547, -3.07662477],
                            [36.90852494, -3.10675852],
                            [36.88968593, -3.13233398],
                            [36.90803449, -3.16408333],
                            [36.90839511, -3.18479758],
                            [36.92408957, -3.18576932],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Arusha", Region_Cod: 2, zoneId: 2 },
            drilldown: 2,
            region_nam: "Arusha",
            value: 4.7,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.36",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [33.60480156, -3.8785974],
                            [33.61503824, -3.88137802],
                            [33.63863459, -3.88745924],
                            [33.66115498, -3.8970939],
                            [33.65290079, -3.92311734],
                            [33.67903059, -3.93753712],
                            [33.72413994, -3.94341463],
                            [33.74913614, -3.93136114],
                            [33.78078112, -3.93802357],
                            [33.83795247, -3.95793473],
                            [33.86173302, -3.95053855],
                            [33.90213256, -3.96142579],
                            [33.92667952, -3.96109877],
                            [33.94445586, -3.97566011],
                            [33.94950921, -3.99534311],
                            [33.97381532, -4.01151851],
                            [33.99798912, -4.02257412],
                            [34.04924264, -4.03745434],
                            [34.06013354, -4.03270759],
                            [34.09119338, -4.02438447],
                            [34.123776, -4.05332648],
                            [34.15147562, -4.06423117],
                            [34.15604543, -4.06762605],
                            [34.15758567, -4.08383505],
                            [34.13259541, -4.10084969],
                            [34.10889417, -4.13881839],
                            [34.10217761, -4.15615582],
                            [34.09552211, -4.23840243],
                            [34.08253698, -4.29062962],
                            [34.07345666, -4.34526153],
                            [34.06120048, -4.37206089],
                            [34.04923059, -4.39953712],
                            [34.01642663, -4.42880907],
                            [34.00280959, -4.4310732],
                            [33.97432772, -4.45305535],
                            [33.94124402, -4.51011053],
                            [33.93592269, -4.55462912],
                            [33.91402627, -4.6062146],
                            [33.92071751, -4.63627081],
                            [33.92315661, -4.68318747],
                            [33.9237998, -4.7107539],
                            [33.93257085, -4.73255334],
                            [33.93062621, -4.76570824],
                            [33.92725919, -4.81865064],
                            [33.915956, -4.84556616],
                            [33.91931343, -4.8756237],
                            [33.91375088, -4.91008757],
                            [33.91653948, -4.94343262],
                            [33.92526304, -4.97467382],
                            [33.92617761, -5.07147136],
                            [33.92704666, -5.10641946],
                            [33.92593018, -5.12744817],
                            [33.93141976, -5.156722],
                            [33.97489789, -5.18394337],
                            [33.97602382, -5.18486871],
                            [34.00288909, -5.20363801],
                            [34.03180081, -5.20527266],
                            [34.06225497, -5.19384654],
                            [34.08662449, -5.19368186],
                            [34.116232, -5.19135539],
                            [34.15872678, -5.20353251],
                            [34.18367567, -5.228659],
                            [34.18219663, -5.24804449],
                            [34.18200591, -5.27720101],
                            [34.17139529, -5.29816433],
                            [34.18362577, -5.3105016],
                            [34.19173622, -5.3307724],
                            [34.20955864, -5.35385654],
                            [34.20992178, -5.35705575],
                            [34.24106064, -5.39391303],
                            [34.23716311, -5.40922667],
                            [34.21211009, -5.42078226],
                            [34.20785836, -5.4238981],
                            [34.18366993, -5.43676871],
                            [34.16825343, -5.46061541],
                            [34.15922937, -5.47925865],
                            [34.12124985, -5.57741212],
                            [34.10617397, -5.59940484],
                            [34.07073645, -5.64832254],
                            [34.01787466, -5.68022857],
                            [34.00573855, -5.70685526],
                            [34.02870789, -5.71306072],
                            [34.0525654, -5.72959831],
                            [34.06313861, -5.7629446],
                            [34.08364729, -5.78497955],
                            [34.10787144, -5.81309959],
                            [34.10671506, -5.86361431],
                            [34.09615501, -5.88112327],
                            [34.03080608, -5.90804605],
                            [34.01400503, -5.93727946],
                            [34.01216324, -5.96138472],
                            [34.01208472, -5.97534796],
                            [33.99427717, -6.02537718],
                            [33.96569018, -6.06371166],
                            [33.97486382, -6.07961337],
                            [33.95693178, -6.12634423],
                            [33.93056678, -6.16960666],
                            [33.92920861, -6.18144634],
                            [33.91511363, -6.2419925],
                            [33.90095841, -6.29251563],
                            [33.90303231, -6.30173368],
                            [33.88331597, -6.32687916],
                            [33.87439499, -6.34854125],
                            [33.86033059, -6.35833712],
                            [33.85659343, -6.35874997],
                            [33.82366864, -6.3759964],
                            [33.81475243, -6.37720755],
                            [33.78756225, -6.3886689],
                            [33.78143059, -6.41533712],
                            [33.77173059, -6.41873712],
                            [33.74903059, -6.43113712],
                            [33.72066902, -6.45124352],
                            [33.69913059, -6.46563712],
                            [33.67653059, -6.51093712],
                            [33.64693059, -6.53313711],
                            [33.64303059, -6.53753712],
                            [33.58963059, -6.55693712],
                            [33.58473059, -6.56433712],
                            [33.57530728, -6.57331989],
                            [33.54823059, -6.60593712],
                            [33.53333059, -6.64363712],
                            [33.52173059, -6.69703711],
                            [33.52303059, -6.72103712],
                            [33.52463059, -6.78593711],
                            [33.52463059, -6.81303711],
                            [33.52493059, -6.86393712],
                            [33.52073059, -6.88993711],
                            [33.49743059, -6.93253711],
                            [33.48654879, -6.94316312],
                            [33.45150985, -6.94113042],
                            [33.42301137, -6.95643374],
                            [33.41355675, -6.94785947],
                            [33.38847836, -6.94667912],
                            [33.36770436, -6.95871143],
                            [33.31478868, -6.94209912],
                            [33.28868052, -6.94177242],
                            [33.2316619, -6.95570778],
                            [33.18425064, -6.94800409],
                            [33.17737426, -6.93272932],
                            [33.15349436, -6.91694525],
                            [33.11673554, -6.90425308],
                            [33.07583401, -6.86876645],
                            [33.02614408, -6.86510971],
                            [33.010249, -6.87336894],
                            [32.99530766, -6.86512947],
                            [32.93175999, -6.89894111],
                            [32.89981257, -6.90854826],
                            [32.88640816, -6.9387835],
                            [32.86819921, -6.94598836],
                            [32.84072218, -6.92950092],
                            [32.8132203, -6.93257436],
                            [32.78916851, -6.94446576],
                            [32.77907665, -6.97650353],
                            [32.74691251, -6.97201372],
                            [32.74754148, -6.83884121],
                            [32.74626325, -6.78059394],
                            [32.74896384, -6.75258885],
                            [32.75499108, -6.73635846],
                            [32.75549618, -6.72979241],
                            [32.75435987, -6.66319293],
                            [32.75840818, -6.62554364],
                            [32.75355021, -6.44822763],
                            [32.75362525, -6.44244999],
                            [32.7495019, -6.40369621],
                            [32.72272524, -6.39038009],
                            [32.70318271, -6.39149712],
                            [32.68293196, -6.36903305],
                            [32.66273059, -6.35933712],
                            [32.65561656, -6.33880746],
                            [32.63373059, -6.32743712],
                            [32.58163059, -6.33103712],
                            [32.56299563, -6.31645663],
                            [32.53253059, -6.31123712],
                            [32.51190464, -6.31420964],
                            [32.50239172, -6.30344094],
                            [32.46348628, -6.30340275],
                            [32.43833059, -6.29793712],
                            [32.40791927, -6.27504046],
                            [32.396543, -6.25871641],
                            [32.36109839, -6.24186467],
                            [32.34583059, -6.23013712],
                            [32.3029337, -6.22977824],
                            [32.29145849, -6.23982129],
                            [32.24486437, -6.20226337],
                            [32.22170563, -6.19575974],
                            [32.20943707, -6.18813875],
                            [32.2043066, -6.17269349],
                            [32.19780966, -6.16094832],
                            [32.18954932, -6.14649273],
                            [32.17804385, -6.10696112],
                            [32.16627796, -6.10355454],
                            [32.1671284, -6.06506941],
                            [32.1547379, -6.05857915],
                            [32.13591424, -6.02615115],
                            [32.13227123, -6.00665944],
                            [32.1142193, -5.99307257],
                            [32.11193059, -5.95433712],
                            [32.09143059, -5.94903712],
                            [32.09233059, -5.92573712],
                            [32.0871023, -5.91308894],
                            [32.08353059, -5.89063712],
                            [32.08883059, -5.86833712],
                            [32.07343059, -5.83513712],
                            [32.05323059, -5.83083712],
                            [32.02843845, -5.79012875],
                            [32.02505726, -5.76259081],
                            [31.99559249, -5.75832219],
                            [31.96848683, -5.74327858],
                            [31.95644855, -5.75087094],
                            [31.91928464, -5.74927936],
                            [31.91587641, -5.74268332],
                            [31.89982568, -5.74265265],
                            [31.88746492, -5.73946575],
                            [31.87247883, -5.73337407],
                            [31.8359151, -5.72855652],
                            [31.81744603, -5.71433316],
                            [31.77698221, -5.72658875],
                            [31.76745886, -5.75055319],
                            [31.73850162, -5.75707909],
                            [31.73057239, -5.773139],
                            [31.70164395, -5.78487619],
                            [31.69181225, -5.80836838],
                            [31.66124378, -5.82727298],
                            [31.63520881, -5.82062163],
                            [31.61648624, -5.8369163],
                            [31.61412832, -5.83269365],
                            [31.59336472, -5.82289077],
                            [31.57599807, -5.82284717],
                            [31.5520985, -5.8051287],
                            [31.50600937, -5.82161187],
                            [31.48678891, -5.8263043],
                            [31.44401919, -5.82637344],
                            [31.41785159, -5.82637846],
                            [31.40316196, -5.81052533],
                            [31.37890489, -5.79257754],
                            [31.36768958, -5.794349],
                            [31.34637701, -5.79507774],
                            [31.30931869, -5.78258307],
                            [31.29637027, -5.8017809],
                            [31.28225149, -5.80508307],
                            [31.25301683, -5.78373011],
                            [31.24470793, -5.74813095],
                            [31.23232903, -5.75309937],
                            [31.21155697, -5.75013608],
                            [31.2026505, -5.73825052],
                            [31.16890472, -5.76212168],
                            [31.15798729, -5.7432795],
                            [31.15433422, -5.71227412],
                            [31.14281863, -5.69197484],
                            [31.1575574, -5.68313479],
                            [31.138201, -5.66385777],
                            [31.14243363, -5.65031476],
                            [31.12615852, -5.64048977],
                            [31.10598803, -5.61620699],
                            [31.06770601, -5.59463189],
                            [31.05545702, -5.58560402],
                            [31.0401366, -5.55939397],
                            [31.03968528, -5.55806822],
                            [31.05129451, -5.53513851],
                            [31.07054127, -5.51886548],
                            [31.06614314, -5.49671857],
                            [31.1030496, -5.46574531],
                            [31.13066391, -5.46293355],
                            [31.15988878, -5.44932195],
                            [31.18412699, -5.43253148],
                            [31.18239687, -5.39563605],
                            [31.18616629, -5.36560804],
                            [31.18192195, -5.280221],
                            [31.1814517, -5.27020622],
                            [31.18018349, -5.25439236],
                            [31.20976652, -5.25633041],
                            [31.21532584, -5.22464367],
                            [31.21192591, -5.22251357],
                            [31.20234187, -5.21139384],
                            [31.20216797, -5.20396329],
                            [31.20637565, -5.18631608],
                            [31.22022683, -5.17572589],
                            [31.20473237, -5.15038717],
                            [31.21048303, -5.10909704],
                            [31.23636225, -5.06584351],
                            [31.2468547, -5.05564615],
                            [31.24931062, -5.03067856],
                            [31.26066204, -5.00953192],
                            [31.23881417, -4.9938805],
                            [31.23719384, -4.97545764],
                            [31.23464174, -4.94866027],
                            [31.24421658, -4.93505119],
                            [31.2685679, -4.9245901],
                            [31.31008428, -4.91584607],
                            [31.30891543, -4.90651388],
                            [31.3297387, -4.87570776],
                            [31.35395713, -4.8475602],
                            [31.37568437, -4.80836057],
                            [31.39747932, -4.80622562],
                            [31.40121645, -4.76765307],
                            [31.38599265, -4.72673616],
                            [31.41210696, -4.69887973],
                            [31.43544143, -4.70186015],
                            [31.45693127, -4.69092935],
                            [31.48415879, -4.64928945],
                            [31.49212676, -4.62619851],
                            [31.48373613, -4.6030726],
                            [31.48742134, -4.58478223],
                            [31.47631224, -4.56635592],
                            [31.47470694, -4.53707551],
                            [31.45798516, -4.5128866],
                            [31.46432436, -4.48556067],
                            [31.45546837, -4.46544659],
                            [31.45744163, -4.45744871],
                            [31.46811305, -4.45914441],
                            [31.49558289, -4.42916624],
                            [31.49943207, -4.41131111],
                            [31.56836103, -4.42548294],
                            [31.59822833, -4.42777312],
                            [31.61380638, -4.42429352],
                            [31.63596988, -4.40129878],
                            [31.65616771, -4.37109818],
                            [31.67214161, -4.32231883],
                            [31.6578815, -4.30028241],
                            [31.65569692, -4.27762928],
                            [31.66909294, -4.27572983],
                            [31.67924773, -4.2550208],
                            [31.67038097, -4.23777919],
                            [31.68213326, -4.22154265],
                            [31.67933577, -4.20302274],
                            [31.67301563, -4.18291456],
                            [31.66370266, -4.15313839],
                            [31.63870978, -4.14040998],
                            [31.66017476, -4.07283569],
                            [31.6662612, -4.07011738],
                            [31.68288283, -4.05323726],
                            [31.68292799, -4.05310564],
                            [31.68295186, -4.05311596],
                            [31.68846146, -4.05549799],
                            [31.72501581, -4.06825921],
                            [31.78447873, -4.08791637],
                            [31.79977801, -4.08767482],
                            [31.85704898, -4.09532736],
                            [31.88597939, -4.10177972],
                            [31.94795755, -4.13054148],
                            [31.95765833, -4.14138385],
                            [31.97932842, -4.16204878],
                            [32.00250732, -4.18771904],
                            [32.05495748, -4.21298164],
                            [32.08351716, -4.23634945],
                            [32.1076494, -4.2466326],
                            [32.14523355, -4.2445596],
                            [32.18744574, -4.25972177],
                            [32.28826529, -4.27517569],
                            [32.32446178, -4.26890757],
                            [32.35545237, -4.28605058],
                            [32.36578683, -4.30515048],
                            [32.39894367, -4.32336768],
                            [32.41231209, -4.3040633],
                            [32.41970498, -4.25111397],
                            [32.40664376, -4.21251106],
                            [32.44355634, -4.21129307],
                            [32.48688533, -4.22727507],
                            [32.50215703, -4.21484462],
                            [32.50224397, -4.19201004],
                            [32.53598605, -4.1739571],
                            [32.54779454, -4.19300255],
                            [32.58041836, -4.20269263],
                            [32.592189, -4.25142566],
                            [32.59007659, -4.28592471],
                            [32.66405995, -4.26611965],
                            [32.6725655, -4.25963445],
                            [32.7222489, -4.24649877],
                            [32.74500719, -4.22792912],
                            [32.73022605, -4.18738124],
                            [32.69722143, -4.19011806],
                            [32.67722974, -4.1701041],
                            [32.65393826, -4.15504671],
                            [32.63846338, -4.1297366],
                            [32.65922903, -4.12636919],
                            [32.67606604, -4.10167491],
                            [32.69290305, -4.07351503],
                            [32.68785195, -4.0416229],
                            [32.6998947, -4.00350015],
                            [32.7034329, -3.94501221],
                            [32.74224077, -3.94598711],
                            [32.75264906, -3.95106204],
                            [32.81243892, -3.9502012],
                            [32.83497737, -3.93537336],
                            [32.84937135, -3.91590112],
                            [32.90778868, -3.92481562],
                            [32.93331759, -3.91420861],
                            [32.98935567, -3.9069638],
                            [33.01269503, -3.90621499],
                            [33.02312909, -3.91869967],
                            [33.03858139, -3.9413781],
                            [33.06543785, -3.95872927],
                            [33.08568012, -3.96600072],
                            [33.12017593, -3.96198752],
                            [33.14212798, -3.96158314],
                            [33.17936052, -3.95939964],
                            [33.19503801, -3.94868662],
                            [33.21840066, -3.9536406],
                            [33.22945157, -3.93331188],
                            [33.2445366, -3.9340694],
                            [33.27267762, -3.91543827],
                            [33.30562393, -3.90586176],
                            [33.32972256, -3.8996734],
                            [33.41285407, -3.88697223],
                            [33.44329014, -3.91146994],
                            [33.46141966, -3.90081391],
                            [33.4959895, -3.90788434],
                            [33.53149384, -3.89424654],
                            [33.57210571, -3.88570005],
                            [33.60480156, -3.8785974],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Tabora", Region_Cod: 14, zoneId: 4 },
            drilldown: 14,
            region_nam: "Tabora",
            value: 6.4,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.37",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [33.94625707, -2.19026196],
                            [33.96928339, -2.18462593],
                            [33.9890408, -2.18187093],
                            [34.01180834, -2.19762237],
                            [34.04516595, -2.21247157],
                            [34.08726052, -2.25874168],
                            [34.12724129, -2.26793726],
                            [34.14760231, -2.25663509],
                            [34.17022062, -2.25854177],
                            [34.19960649, -2.29152591],
                            [34.2279377, -2.30657596],
                            [34.25411099, -2.31002265],
                            [34.27166756, -2.30248302],
                            [34.2949327, -2.31874707],
                            [34.32524914, -2.36531547],
                            [34.35052783, -2.37576945],
                            [34.36031725, -2.38824266],
                            [34.40090931, -2.4007735],
                            [34.41678292, -2.42785558],
                            [34.45137771, -2.44488034],
                            [34.48812686, -2.48941668],
                            [34.51883514, -2.48603897],
                            [34.56549568, -2.49047502],
                            [34.60801249, -2.48391192],
                            [34.65567907, -2.49103779],
                            [34.7091272, -2.48153902],
                            [34.74282742, -2.47812694],
                            [34.77509222, -2.4868401],
                            [34.79640737, -2.48624981],
                            [34.88300787, -2.48635294],
                            [34.90867382, -2.48575461],
                            [34.94393707, -2.48570287],
                            [34.98968891, -2.48563433],
                            [35.07761032, -2.49752348],
                            [35.12986561, -2.49755574],
                            [35.21749039, -2.49755518],
                            [35.21643003, -2.53069406],
                            [35.20495935, -2.61556689],
                            [35.18479728, -2.71959245],
                            [35.13937036, -2.90061441],
                            [35.12887934, -2.91340034],
                            [35.06975806, -2.94312491],
                            [35.04549757, -2.96224916],
                            [35.04025206, -2.96530904],
                            [35.0046263, -2.97580007],
                            [35.01151103, -2.99929558],
                            [34.98714126, -2.98836744],
                            [34.88168464, -2.9042207],
                            [34.88081107, -2.96632569],
                            [34.88376735, -3.05036756],
                            [34.88683349, -3.23612206],
                            [34.8899374, -3.50144738],
                            [34.890212, -3.62052222],
                            [34.86695691, -3.62971811],
                            [34.83224322, -3.6155919],
                            [34.82149029, -3.65597124],
                            [34.79855316, -3.68110179],
                            [34.76107623, -3.70247246],
                            [34.74823501, -3.73693778],
                            [34.75008621, -3.77334293],
                            [34.7780969, -3.74997252],
                            [34.81932758, -3.744801],
                            [34.80660915, -3.766233],
                            [34.77909786, -3.79266453],
                            [34.7492616, -3.80549957],
                            [34.74922416, -3.8052874],
                            [34.74920602, -3.80518463],
                            [34.74744895, -3.80392958],
                            [34.72390589, -3.81310468],
                            [34.70864532, -3.83207815],
                            [34.69620026, -3.85060308],
                            [34.68131093, -3.86426218],
                            [34.66877348, -3.86817747],
                            [34.64721832, -3.85770933],
                            [34.63945378, -3.88290998],
                            [34.63281443, -3.89953236],
                            [34.59646405, -3.92178924],
                            [34.55933022, -3.91089127],
                            [34.52017824, -3.9197711],
                            [34.51959959, -3.92917029],
                            [34.48197471, -3.93060335],
                            [34.45447088, -3.94643944],
                            [34.43309581, -3.95161228],
                            [34.42400374, -3.96555828],
                            [34.39489192, -3.9867807],
                            [34.38771386, -4.01170449],
                            [34.38651752, -4.04061608],
                            [34.37555105, -4.04360694],
                            [34.36578093, -4.04659779],
                            [34.33517599, -4.06921309],
                            [34.310231, -4.0824074],
                            [34.26525645, -4.08277163],
                            [34.20464179, -4.07825212],
                            [34.20153122, -4.07303114],
                            [34.20946227, -3.9925696],
                            [34.23357539, -3.96946371],
                            [34.21751894, -3.94718832],
                            [34.20107755, -3.89822717],
                            [34.16056852, -3.83407024],
                            [34.14046431, -3.82174997],
                            [34.10326941, -3.78525756],
                            [34.09902526, -3.76854193],
                            [34.08043026, -3.74154213],
                            [34.09240844, -3.71576132],
                            [34.07415046, -3.68496142],
                            [34.07815028, -3.65643614],
                            [34.05950757, -3.63889524],
                            [34.06014058, -3.59962477],
                            [34.01145983, -3.59984668],
                            [34.00026062, -3.57931062],
                            [33.98203778, -3.57084474],
                            [33.96387375, -3.53900106],
                            [33.92850934, -3.52481111],
                            [33.90596922, -3.50195489],
                            [33.85289365, -3.50923406],
                            [33.83145118, -3.5012796],
                            [33.7920009, -3.53600296],
                            [33.78359709, -3.55435362],
                            [33.76586226, -3.56431214],
                            [33.72381431, -3.53046551],
                            [33.72125432, -3.49310738],
                            [33.71326143, -3.47133188],
                            [33.68692587, -3.46087963],
                            [33.6690795, -3.4461097],
                            [33.66692819, -3.41717466],
                            [33.63629021, -3.38562469],
                            [33.61179438, -3.37045961],
                            [33.6057114, -3.3518723],
                            [33.5724783, -3.31471472],
                            [33.55711339, -3.28614526],
                            [33.52205751, -3.28304628],
                            [33.48322674, -3.29252299],
                            [33.45926945, -3.30438461],
                            [33.43657493, -3.30784651],
                            [33.43297966, -3.30698518],
                            [33.42307386, -3.30072565],
                            [33.44221341, -3.27064921],
                            [33.43771043, -3.23285393],
                            [33.43870982, -3.20617387],
                            [33.46321224, -3.19201299],
                            [33.47922348, -3.17424621],
                            [33.48831754, -3.09582672],
                            [33.48114873, -3.07083227],
                            [33.48702413, -3.04296604],
                            [33.50095811, -3.01320741],
                            [33.52002546, -3.00137653],
                            [33.52207761, -2.96640308],
                            [33.50886172, -2.92370962],
                            [33.5001619, -2.91796489],
                            [33.50102781, -2.8898504],
                            [33.53538737, -2.86232138],
                            [33.54687324, -2.8444585],
                            [33.57442071, -2.84065341],
                            [33.57611534, -2.82407948],
                            [33.61502997, -2.81213423],
                            [33.6372151, -2.81120867],
                            [33.65648338, -2.82301826],
                            [33.66011083, -2.83628539],
                            [33.68305333, -2.83356292],
                            [33.72861237, -2.81230203],
                            [33.74664075, -2.83153322],
                            [33.76121436, -2.83040506],
                            [33.78860945, -2.81226133],
                            [33.78276446, -2.79280638],
                            [33.78290259, -2.7330678],
                            [33.77257783, -2.71286718],
                            [33.74923489, -2.70440709],
                            [33.73583687, -2.68800487],
                            [33.70258354, -2.68303241],
                            [33.67837303, -2.6704357],
                            [33.65288803, -2.64734369],
                            [33.63074505, -2.64004039],
                            [33.62311309, -2.61336566],
                            [33.63112002, -2.54916161],
                            [33.58003343, -2.53740379],
                            [33.56836964, -2.50990178],
                            [33.5745639, -2.46536355],
                            [33.54609974, -2.46561223],
                            [33.51045914, -2.44842414],
                            [33.51485419, -2.43710255],
                            [33.5380856, -2.43292382],
                            [33.54412936, -2.41932524],
                            [33.56670429, -2.42167522],
                            [33.57182477, -2.39778542],
                            [33.55247157, -2.38673429],
                            [33.56242181, -2.36425156],
                            [33.58251987, -2.34828903],
                            [33.6067526, -2.3457228],
                            [33.63659087, -2.32799054],
                            [33.66131075, -2.29745649],
                            [33.68538768, -2.30396981],
                            [33.71005298, -2.27737965],
                            [33.72638116, -2.28641509],
                            [33.78011994, -2.26084128],
                            [33.80669648, -2.26058477],
                            [33.84056785, -2.23433423],
                            [33.84694102, -2.19747067],
                            [33.88261641, -2.18865574],
                            [33.90659464, -2.17863264],
                            [33.94625707, -2.19026196],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Simiyu", Region_Cod: 24, zoneId: 3 },
            drilldown: 24,
            region_nam: "Simiyu",
            value: 6.6,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.38",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [39.58499457, -5.41083937],
                            [39.61744759, -5.42831249],
                            [39.62576813, -5.44328918],
                            [39.65541742, -5.45692578],
                            [39.64128486, -5.47937439],
                            [39.60106453, -5.4375872],
                            [39.58499457, -5.41083937],
                        ],
                    ],
                    [
                        [
                            [39.79318512, -5.16378202],
                            [39.79973207, -5.16459645],
                            [39.81757507, -5.17062279],
                            [39.85230001, -5.1750977],
                            [39.85630826, -5.18902331],
                            [39.84266302, -5.24915336],
                            [39.81976584, -5.32049313],
                            [39.80084039, -5.33463916],
                            [39.80936179, -5.35516193],
                            [39.79274975, -5.37984899],
                            [39.79416132, -5.39544216],
                            [39.75242184, -5.44300209],
                            [39.72907994, -5.43977535],
                            [39.70655711, -5.45575674],
                            [39.69779903, -5.44253365],
                            [39.67100405, -5.43032647],
                            [39.64388839, -5.43689209],
                            [39.63076937, -5.41051353],
                            [39.64372385, -5.3789878],
                            [39.64361129, -5.3386926],
                            [39.65545515, -5.3276315],
                            [39.67388332, -5.33939886],
                            [39.66629904, -5.28418866],
                            [39.68502476, -5.29005106],
                            [39.69594299, -5.28037124],
                            [39.68652878, -5.25430915],
                            [39.71064535, -5.25533414],
                            [39.72837044, -5.24257076],
                            [39.69837644, -5.21576999],
                            [39.67565056, -5.21825997],
                            [39.64799897, -5.20145259],
                            [39.65487835, -5.18725968],
                            [39.67447766, -5.21416163],
                            [39.70498447, -5.20638148],
                            [39.74085166, -5.20520494],
                            [39.75500562, -5.16357379],
                            [39.79318512, -5.16378202],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Kusini Pemba", Region_Cod: 55, zoneId: 7 },
            drilldown: 55,
            region_nam: "Kusini Pemba",
            value: 6.4,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.39",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [32.74718698, -2.42238666],
                            [32.76361641, -2.42768879],
                            [32.75792961, -2.44727412],
                            [32.73764723, -2.43772941],
                            [32.74718698, -2.42238666],
                        ],
                    ],
                    [
                        [
                            [33.56836964, -2.50990178],
                            [33.58003343, -2.53740379],
                            [33.63112002, -2.54916161],
                            [33.62311309, -2.61336566],
                            [33.63074505, -2.64004039],
                            [33.65288803, -2.64734369],
                            [33.67837303, -2.6704357],
                            [33.70258354, -2.68303241],
                            [33.73583687, -2.68800487],
                            [33.74923489, -2.70440709],
                            [33.77257783, -2.71286718],
                            [33.78290259, -2.7330678],
                            [33.78276446, -2.79280638],
                            [33.78860945, -2.81226133],
                            [33.76121436, -2.83040506],
                            [33.74664075, -2.83153322],
                            [33.72861237, -2.81230203],
                            [33.68305333, -2.83356292],
                            [33.66011083, -2.83628539],
                            [33.65648338, -2.82301826],
                            [33.6372151, -2.81120867],
                            [33.61502997, -2.81213423],
                            [33.57611534, -2.82407948],
                            [33.57442071, -2.84065341],
                            [33.54687324, -2.8444585],
                            [33.53538737, -2.86232138],
                            [33.50102781, -2.8898504],
                            [33.5001619, -2.91796489],
                            [33.50886172, -2.92370962],
                            [33.52207761, -2.96640308],
                            [33.52002546, -3.00137653],
                            [33.50095811, -3.01320741],
                            [33.48702413, -3.04296604],
                            [33.48114873, -3.07083227],
                            [33.48831754, -3.09582672],
                            [33.47922348, -3.17424621],
                            [33.46321224, -3.19201299],
                            [33.43870982, -3.20617387],
                            [33.43771043, -3.23285393],
                            [33.44221341, -3.27064921],
                            [33.42307386, -3.30072565],
                            [33.42305114, -3.30154239],
                            [33.41672319, -3.30952696],
                            [33.37994709, -3.30770182],
                            [33.34284699, -3.31182032],
                            [33.32380708, -3.3302484],
                            [33.31138711, -3.35739611],
                            [33.28822133, -3.39640289],
                            [33.28081064, -3.39729673],
                            [33.25134978, -3.39056015],
                            [33.21670611, -3.39026043],
                            [33.19555418, -3.40104964],
                            [33.15684117, -3.40613161],
                            [33.1457363, -3.4254289],
                            [33.12823067, -3.43455668],
                            [33.10154105, -3.43285299],
                            [33.05509825, -3.3901603],
                            [33.02223943, -3.38787839],
                            [32.99862745, -3.37156868],
                            [32.98123618, -3.36429828],
                            [32.96681001, -3.34340036],
                            [32.9194321, -3.33305964],
                            [32.91279984, -3.30169889],
                            [32.89879944, -3.28922301],
                            [32.88909581, -3.27162966],
                            [32.85838958, -3.26458886],
                            [32.8472395, -3.23330834],
                            [32.80406435, -3.24853125],
                            [32.77497011, -3.24966551],
                            [32.78473834, -3.21518019],
                            [32.78006009, -3.20070966],
                            [32.79263459, -3.16649363],
                            [32.77230514, -3.12973493],
                            [32.77094773, -3.09618746],
                            [32.78388397, -3.06172062],
                            [32.78159039, -3.04719542],
                            [32.76560143, -3.03234355],
                            [32.77255505, -3.00093508],
                            [32.80192979, -2.97747549],
                            [32.81890359, -2.95532395],
                            [32.81806031, -2.93437254],
                            [32.82723727, -2.88222147],
                            [32.8485407, -2.84479556],
                            [32.89137348, -2.82735883],
                            [32.9126537, -2.80352034],
                            [32.92011038, -2.81659348],
                            [32.90952005, -2.8352448],
                            [32.94738953, -2.85655249],
                            [32.97570219, -2.85997406],
                            [33.01298204, -2.88113371],
                            [33.01782276, -2.85557778],
                            [32.97857012, -2.85554919],
                            [32.97144937, -2.82443853],
                            [32.93395599, -2.81704065],
                            [32.94729877, -2.79261619],
                            [32.95044007, -2.77201153],
                            [32.92663362, -2.76756173],
                            [32.91045408, -2.75620808],
                            [32.91352182, -2.74084154],
                            [32.90303336, -2.71891543],
                            [32.88205522, -2.69923583],
                            [32.92163407, -2.68072331],
                            [32.90503476, -2.65746661],
                            [32.88029494, -2.65057284],
                            [32.86259822, -2.63506748],
                            [32.86418695, -2.60839904],
                            [32.89536674, -2.57908566],
                            [32.90732491, -2.5610948],
                            [32.9004444, -2.53854075],
                            [32.8854766, -2.52019682],
                            [32.87699135, -2.49715584],
                            [32.87554691, -2.46877619],
                            [32.9095036, -2.46650259],
                            [32.90479666, -2.4397834],
                            [32.93261456, -2.40773673],
                            [32.96046484, -2.39516546],
                            [32.97072412, -2.38257448],
                            [32.99217031, -2.38550811],
                            [33.06993671, -2.37306205],
                            [33.07917492, -2.38598858],
                            [33.10398645, -2.38152042],
                            [33.13285434, -2.39915424],
                            [33.12295546, -2.42420071],
                            [33.12640003, -2.44161967],
                            [33.14461193, -2.46647515],
                            [33.16554537, -2.4667207],
                            [33.16634342, -2.48114697],
                            [33.18942588, -2.51109982],
                            [33.20225606, -2.51680894],
                            [33.24657848, -2.50496098],
                            [33.26431973, -2.51337119],
                            [33.29307905, -2.50376219],
                            [33.29946344, -2.54753211],
                            [33.33101708, -2.53562276],
                            [33.37336446, -2.53065485],
                            [33.3828592, -2.51974136],
                            [33.41396266, -2.53076399],
                            [33.44506611, -2.53349236],
                            [33.460345, -2.52879956],
                            [33.46718776, -2.49305454],
                            [33.51457294, -2.51491517],
                            [33.56462494, -2.50492932],
                            [33.56836964, -2.50990178],
                        ],
                    ],
                    [
                        [
                            [32.43890007, -2.2633168],
                            [32.43666229, -2.29237227],
                            [32.44847934, -2.30953051],
                            [32.4645975, -2.3158681],
                            [32.46775787, -2.33535779],
                            [32.49494499, -2.32037019],
                            [32.51515229, -2.316878],
                            [32.50236322, -2.29512696],
                            [32.51620225, -2.27757253],
                            [32.54188883, -2.27407846],
                            [32.54154967, -2.31869257],
                            [32.52698802, -2.32159873],
                            [32.509829, -2.36256424],
                            [32.52581161, -2.36878092],
                            [32.51979996, -2.39096132],
                            [32.4780458, -2.39544761],
                            [32.46432726, -2.41665945],
                            [32.43862999, -2.42410855],
                            [32.42462485, -2.39085117],
                            [32.44269669, -2.37634958],
                            [32.44743673, -2.3593245],
                            [32.41158154, -2.35125747],
                            [32.40516942, -2.3366211],
                            [32.41430228, -2.30376776],
                            [32.43346398, -2.28724717],
                            [32.43890007, -2.2633168],
                        ],
                    ],
                    [
                        [
                            [32.7598979, -2.98898111],
                            [32.73414136, -3.00740641],
                            [32.72003465, -3.00797568],
                            [32.6840248, -2.98937345],
                            [32.64845631, -2.958761],
                            [32.61595091, -2.93679272],
                            [32.58142641, -2.92893722],
                            [32.58329683, -2.90262729],
                            [32.58288283, -2.89393334],
                            [32.57729386, -2.8655745],
                            [32.56060377, -2.85020327],
                            [32.54313905, -2.82334674],
                            [32.54369697, -2.80929491],
                            [32.55038401, -2.7943669],
                            [32.5174712, -2.78567295],
                            [32.49408078, -2.77840684],
                            [32.46903347, -2.77573701],
                            [32.44463365, -2.77228163],
                            [32.42952896, -2.76826372],
                            [32.40444454, -2.73373157],
                            [32.41917626, -2.68360875],
                            [32.46794739, -2.65920207],
                            [32.5063785, -2.65578241],
                            [32.51736041, -2.63358129],
                            [32.49842731, -2.62752585],
                            [32.45040358, -2.61779691],
                            [32.34683387, -2.60232617],
                            [32.28828244, -2.60454898],
                            [32.26467296, -2.59261886],
                            [32.25997359, -2.57007183],
                            [32.24842638, -2.56285403],
                            [32.22994983, -2.54369133],
                            [32.22187688, -2.53437638],
                            [32.21156336, -2.50089144],
                            [32.19765801, -2.50042857],
                            [32.17172318, -2.4997464],
                            [32.14373827, -2.46400697],
                            [32.12986899, -2.46321151],
                            [32.11929697, -2.47884137],
                            [32.10141955, -2.46664674],
                            [32.10126461, -2.43956221],
                            [32.06910265, -2.42906548],
                            [32.05548332, -2.39727218],
                            [32.06910952, -2.37504937],
                            [32.08110313, -2.38261928],
                            [32.10821211, -2.37470555],
                            [32.13945527, -2.39174583],
                            [32.16031266, -2.38942105],
                            [32.19758465, -2.31655415],
                            [32.23337999, -2.3142959],
                            [32.22696458, -2.29444479],
                            [32.24106134, -2.27603072],
                            [32.25901645, -2.28159579],
                            [32.30174635, -2.27012413],
                            [32.31687659, -2.277532],
                            [32.33240069, -2.29965634],
                            [32.33544489, -2.32571898],
                            [32.31906409, -2.33156375],
                            [32.33716277, -2.35824323],
                            [32.33452118, -2.38831568],
                            [32.34633774, -2.40130896],
                            [32.36675321, -2.39575397],
                            [32.37617498, -2.41736654],
                            [32.36252299, -2.42195436],
                            [32.36654623, -2.44368832],
                            [32.41401178, -2.43420148],
                            [32.42590412, -2.4434754],
                            [32.42736843, -2.47539367],
                            [32.45280974, -2.46127182],
                            [32.48761813, -2.47596908],
                            [32.50588366, -2.47415898],
                            [32.51196458, -2.45473784],
                            [32.53038343, -2.45727737],
                            [32.54483892, -2.44459036],
                            [32.5440582, -2.48682346],
                            [32.53209221, -2.50143846],
                            [32.51591759, -2.49764699],
                            [32.51623737, -2.52122776],
                            [32.50826751, -2.53431592],
                            [32.51525383, -2.5531225],
                            [32.52962223, -2.55780821],
                            [32.56205817, -2.51786319],
                            [32.59971252, -2.50014494],
                            [32.61336704, -2.46519748],
                            [32.62818868, -2.46268256],
                            [32.6394127, -2.44037912],
                            [32.64289064, -2.39790753],
                            [32.65987544, -2.40666703],
                            [32.67782357, -2.43212933],
                            [32.69756556, -2.4351808],
                            [32.69403412, -2.47851539],
                            [32.67429232, -2.48850623],
                            [32.69933398, -2.50686229],
                            [32.71398327, -2.49472702],
                            [32.74101256, -2.50058169],
                            [32.74296341, -2.52060098],
                            [32.7548292, -2.53179203],
                            [32.78336139, -2.48781726],
                            [32.79522585, -2.4986439],
                            [32.81860931, -2.47961402],
                            [32.8326707, -2.49119178],
                            [32.83370453, -2.52151687],
                            [32.81534298, -2.53787561],
                            [32.82181837, -2.55796526],
                            [32.84017767, -2.5616383],
                            [32.83242316, -2.58625029],
                            [32.81804925, -2.60465822],
                            [32.82814556, -2.62913034],
                            [32.80944532, -2.62904224],
                            [32.81021928, -2.65559018],
                            [32.82887869, -2.6584257],
                            [32.83853385, -2.67137686],
                            [32.82107934, -2.68230087],
                            [32.81579594, -2.69707099],
                            [32.87222703, -2.72490025],
                            [32.87594619, -2.75363315],
                            [32.85279633, -2.82679172],
                            [32.83734621, -2.82452762],
                            [32.82860455, -2.84001059],
                            [32.78563922, -2.84929839],
                            [32.75059188, -2.86636934],
                            [32.72686197, -2.86109043],
                            [32.71812087, -2.88114109],
                            [32.76271736, -2.87347345],
                            [32.77342972, -2.8606991],
                            [32.80292569, -2.87749883],
                            [32.78326093, -2.88992609],
                            [32.8058269, -2.91265025],
                            [32.79167455, -2.92242702],
                            [32.76430798, -2.92677729],
                            [32.76859569, -2.95144721],
                            [32.77831406, -2.96231153],
                            [32.7598979, -2.98898111],
                        ],
                    ],
                    [
                        [
                            [32.03338969, -2.21469916],
                            [32.04802373, -2.22458152],
                            [32.04887755, -2.24994104],
                            [32.0770964, -2.27538327],
                            [32.05653395, -2.29518759],
                            [32.06387251, -2.3102892],
                            [32.04141701, -2.3447993],
                            [32.00071399, -2.35993343],
                            [31.97359826, -2.33977053],
                            [31.95741375, -2.35229156],
                            [31.94756663, -2.34133451],
                            [31.98048965, -2.31965438],
                            [31.98774279, -2.30117047],
                            [31.97204959, -2.28787093],
                            [31.95644382, -2.28806666],
                            [31.94261689, -2.27294138],
                            [31.97623959, -2.2692387],
                            [32.01101994, -2.24320039],
                            [32.02342777, -2.21801841],
                            [32.03338969, -2.21469916],
                        ],
                    ],
                    [
                        [
                            [32.88522305, -2.09826728],
                            [32.90646201, -2.11102124],
                            [32.90431949, -2.13012079],
                            [32.88805647, -2.13175014],
                            [32.86716576, -2.10595229],
                            [32.88522305, -2.09826728],
                        ],
                    ],
                    [
                        [
                            [33.11131186, -2.14711405],
                            [33.07584922, -2.10712111],
                            [33.05794543, -2.13558581],
                            [33.03079413, -2.1091207],
                            [32.97496372, -2.09081085],
                            [32.94765103, -2.08851734],
                            [32.93020266, -2.07672492],
                            [32.91399061, -2.08301304],
                            [32.88436716, -2.0765912],
                            [32.88319678, -2.05539876],
                            [32.86044156, -2.06184961],
                            [32.84523965, -2.05703325],
                            [32.83638689, -2.04032745],
                            [32.86962663, -1.99886295],
                            [32.88300547, -1.9994863],
                            [32.90279837, -1.97819696],
                            [32.89506008, -1.96438213],
                            [32.87355272, -1.95160625],
                            [32.85899209, -1.9284024],
                            [32.88548035, -1.92609518],
                            [32.90371861, -1.89460251],
                            [32.92145123, -1.88298882],
                            [32.94414874, -1.90179829],
                            [32.92690356, -1.92060623],
                            [32.92892813, -1.93988075],
                            [32.9526536, -1.95968009],
                            [32.96422704, -1.97709143],
                            [33.00201593, -1.98419598],
                            [33.03621665, -1.96579462],
                            [33.09250714, -1.96581771],
                            [33.11640891, -1.97868885],
                            [33.11475016, -1.99878405],
                            [33.10391107, -2.01055195],
                            [33.10498682, -2.03430731],
                            [33.13360238, -2.05486699],
                            [33.12115192, -2.06965678],
                            [33.13123649, -2.08343754],
                            [33.17032837, -2.1114636],
                            [33.15252379, -2.12876996],
                            [33.15044237, -2.15008257],
                            [33.16183057, -2.16099598],
                            [33.1495586, -2.17511452],
                            [33.11131186, -2.14711405],
                        ],
                    ],
                    [
                        [
                            [33.08575256, -1.78955924],
                            [33.08558321, -1.82220772],
                            [33.0990344, -1.8298471],
                            [33.09558774, -1.85772376],
                            [33.07224391, -1.86242315],
                            [33.05253934, -1.88232247],
                            [33.02579365, -1.88295988],
                            [33.00520375, -1.86323545],
                            [33.00219491, -1.82333379],
                            [33.00960217, -1.79848504],
                            [33.05140629, -1.79666017],
                            [33.07066694, -1.8014745],
                            [33.08575256, -1.78955924],
                        ],
                    ],
                    [
                        [
                            [33.35125437, -1.69906112],
                            [33.37082971, -1.72172341],
                            [33.36940122, -1.74003335],
                            [33.33161036, -1.71639171],
                            [33.32994737, -1.69804951],
                            [33.35125437, -1.69906112],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Mwanza", Region_Cod: 19, zoneId: 3 },
            drilldown: 19,
            region_nam: "Mwanza",
            value: 4.9,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.40",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [34.31891959, -4.08394824],
                            [34.29271959, -4.12314825],
                            [34.27651959, -4.13474825],
                            [34.26031959, -4.16674825],
                            [34.23691959, -4.16934825],
                            [34.23381959, -4.14804824],
                            [34.25401959, -4.15094824],
                            [34.26971959, -4.13264824],
                            [34.28758816, -4.08463273],
                            [34.31949062, -4.08091078],
                            [34.34524372, -4.05836195],
                            [34.37933947, -4.04241072],
                            [34.38281959, -4.05224824],
                            [34.36031959, -4.06694824],
                            [34.31891959, -4.08394824],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "", Region_Cod: null, zoneId: null },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.41",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [31.83788919, -0.99448667],
                            [34.02348941, -0.99160517],
                            [34.02731109, -1.03054824],
                            [34.03513646, -1.03546159],
                            [34.05514717, -1.07589726],
                            [34.04411164, -1.09472094],
                            [34.00731468, -1.09076885],
                            [33.97048378, -1.10667996],
                            [33.97445488, -1.12580494],
                            [34.00148201, -1.123251],
                            [34.00813186, -1.13898028],
                            [33.98584175, -1.16743283],
                            [33.9569802, -1.18758535],
                            [33.92099968, -1.19548469],
                            [33.87879066, -1.21669786],
                            [33.86643491, -1.25402325],
                            [33.87022591, -1.2697659],
                            [33.8893528, -1.27225118],
                            [33.89650536, -1.29048542],
                            [33.93685394, -1.28668743],
                            [33.95704079, -1.31571011],
                            [33.95725823, -1.33599095],
                            [33.92544365, -1.33326154],
                            [33.89840889, -1.32373052],
                            [33.84794009, -1.32155086],
                            [33.82775316, -1.33283853],
                            [33.79957262, -1.32572571],
                            [33.79400864, -1.34237845],
                            [33.81620217, -1.37558179],
                            [33.85173226, -1.39673047],
                            [33.84300177, -1.41080289],
                            [33.82011541, -1.41368311],
                            [33.80589104, -1.38994413],
                            [33.77864266, -1.40297704],
                            [33.78287697, -1.43080526],
                            [33.80423838, -1.44006349],
                            [33.83054161, -1.42956739],
                            [33.85255711, -1.43783665],
                            [33.86161232, -1.47324567],
                            [33.89132881, -1.48299413],
                            [33.89505196, -1.50294118],
                            [33.92646981, -1.49062543],
                            [33.93972234, -1.49530841],
                            [33.93453026, -1.5167008],
                            [33.91634941, -1.53680997],
                            [33.88493419, -1.5235177],
                            [33.85755809, -1.53022875],
                            [33.82872778, -1.522165],
                            [33.81687021, -1.49626928],
                            [33.77766498, -1.49406328],
                            [33.74409417, -1.48335307],
                            [33.72783795, -1.49540055],
                            [33.70680091, -1.4881867],
                            [33.67777593, -1.4671118],
                            [33.66232109, -1.48946195],
                            [33.66823006, -1.50738912],
                            [33.69383049, -1.51754003],
                            [33.6777134, -1.55717092],
                            [33.6455555, -1.56916624],
                            [33.64846993, -1.58775717],
                            [33.6744442, -1.59189993],
                            [33.69541359, -1.60342413],
                            [33.6959858, -1.63777548],
                            [33.71183968, -1.65541655],
                            [33.6999522, -1.68221239],
                            [33.62738204, -1.66628096],
                            [33.60189986, -1.68213186],
                            [33.56815416, -1.682872],
                            [33.56763725, -1.70819889],
                            [33.58524132, -1.71285063],
                            [33.59698677, -1.73351866],
                            [33.64190865, -1.74816519],
                            [33.65637098, -1.77453805],
                            [33.64876212, -1.78387186],
                            [33.61765099, -1.77781874],
                            [33.59862887, -1.76143408],
                            [33.56913948, -1.76401144],
                            [33.55062676, -1.80940634],
                            [33.49479636, -1.84144696],
                            [33.46091213, -1.8441715],
                            [33.44410536, -1.83773756],
                            [33.43997383, -1.79729468],
                            [33.4232502, -1.80961424],
                            [33.39824213, -1.79934255],
                            [33.37358996, -1.79660119],
                            [33.33195257, -1.83125218],
                            [33.31690788, -1.88538939],
                            [33.31717491, -1.90160376],
                            [33.29137993, -1.92048079],
                            [33.2995739, -1.94688231],
                            [33.32589913, -1.94829375],
                            [33.35230827, -1.92392356],
                            [33.41423072, -1.89802978],
                            [33.429727, -1.89928615],
                            [33.45116615, -1.88148123],
                            [33.4572773, -1.90337378],
                            [33.47230339, -1.90352828],
                            [33.48960758, -1.92996755],
                            [33.46772201, -1.94864155],
                            [33.53354141, -1.95927825],
                            [33.5349102, -1.98929603],
                            [33.56038475, -1.99805839],
                            [33.53377016, -2.026972],
                            [33.51986525, -2.01144808],
                            [33.49278498, -2.02163746],
                            [33.46241376, -2.00760034],
                            [33.45184517, -1.99035842],
                            [33.41278135, -1.96934767],
                            [33.38150971, -1.97822513],
                            [33.38427925, -2.01223762],
                            [33.40502739, -2.03888137],
                            [33.37310913, -2.0464187],
                            [33.34100914, -2.01074035],
                            [33.32465935, -2.01876456],
                            [33.3286953, -2.04224212],
                            [33.31096704, -2.04499226],
                            [33.29767584, -2.02175272],
                            [33.2903113, -1.98719978],
                            [33.27253151, -1.99841125],
                            [33.29230008, -2.03305176],
                            [33.30571938, -2.07001311],
                            [33.26993768, -2.04166167],
                            [33.24044626, -2.05387238],
                            [33.23395348, -2.06885535],
                            [33.20560731, -2.0968438],
                            [33.21470452, -2.12225539],
                            [33.24982205, -2.09821484],
                            [33.27327919, -2.09497077],
                            [33.28616142, -2.10734756],
                            [33.33539772, -2.13511855],
                            [33.35932962, -2.17245079],
                            [33.37105662, -2.16359727],
                            [33.39392578, -2.16975245],
                            [33.41235542, -2.15360839],
                            [33.4636097, -2.15394409],
                            [33.54715538, -2.16639335],
                            [33.57441902, -2.16786201],
                            [33.61135718, -2.18731721],
                            [33.62593659, -2.18045603],
                            [33.63348198, -2.15439804],
                            [33.66234017, -2.1418],
                            [33.69280282, -2.11917829],
                            [33.71259117, -2.11335571],
                            [33.72702469, -2.08935833],
                            [33.76415991, -2.06589011],
                            [33.79097176, -2.06981475],
                            [33.79965109, -2.08138838],
                            [33.80540657, -2.11100967],
                            [33.81825447, -2.10948379],
                            [33.83170509, -2.12893875],
                            [33.83749199, -2.17434127],
                            [33.83254433, -2.19624717],
                            [33.8429966, -2.2079602],
                            [33.8405704, -2.23433321],
                            [33.80669648, -2.26058484],
                            [33.78012139, -2.26084167],
                            [33.72638116, -2.28641516],
                            [33.7100544, -2.27738006],
                            [33.68538768, -2.30396988],
                            [33.6613106, -2.2974567],
                            [33.63659087, -2.32799061],
                            [33.6067526, -2.34572287],
                            [33.58252134, -2.34828969],
                            [33.56242181, -2.36425163],
                            [33.55247307, -2.38673409],
                            [33.57182503, -2.39778526],
                            [33.5667057, -2.42167481],
                            [33.54412936, -2.41932531],
                            [33.5380856, -2.4329239],
                            [33.51485419, -2.43710263],
                            [33.51045914, -2.44842422],
                            [33.54609974, -2.4656123],
                            [33.58816786, -2.46351929],
                            [33.57441391, -2.48125737],
                            [33.56736667, -2.50480518],
                            [33.51457294, -2.51491525],
                            [33.46718776, -2.49305462],
                            [33.460345, -2.52879963],
                            [33.44506611, -2.53349244],
                            [33.41396523, -2.53076371],
                            [33.38286017, -2.51974121],
                            [33.3733654, -2.53065499],
                            [33.33101845, -2.53562172],
                            [33.29946344, -2.54753219],
                            [33.29307905, -2.50376226],
                            [33.26431973, -2.51337127],
                            [33.24658013, -2.50496109],
                            [33.20225606, -2.51680902],
                            [33.18942588, -2.5110999],
                            [33.16634342, -2.48114705],
                            [33.16554451, -2.46672066],
                            [33.14461193, -2.46647522],
                            [33.12640003, -2.44161974],
                            [33.12295546, -2.42420078],
                            [33.13285434, -2.39915431],
                            [33.10398645, -2.3815205],
                            [33.07917492, -2.38598865],
                            [33.06993675, -2.37306221],
                            [32.9921703, -2.38550818],
                            [32.97072412, -2.38257455],
                            [32.96046639, -2.39516457],
                            [32.93261456, -2.4077368],
                            [32.90479835, -2.43978329],
                            [32.90950585, -2.46650132],
                            [32.87554691, -2.46877626],
                            [32.87699318, -2.49715622],
                            [32.88547707, -2.520197],
                            [32.90044594, -2.53853997],
                            [32.90732765, -2.56109437],
                            [32.89536858, -2.57908448],
                            [32.86418725, -2.60839874],
                            [32.86260033, -2.63506707],
                            [32.88029671, -2.65057191],
                            [32.90503502, -2.65746697],
                            [32.92163658, -2.68072328],
                            [32.88205528, -2.69923601],
                            [32.90303612, -2.71891412],
                            [32.91352272, -2.740841],
                            [32.9104557, -2.75620851],
                            [32.92663384, -2.76756105],
                            [32.95044136, -2.77201089],
                            [32.94729877, -2.79261628],
                            [32.93395805, -2.81703958],
                            [32.97144937, -2.82443861],
                            [32.97857094, -2.85554895],
                            [33.01782417, -2.85557756],
                            [33.01298204, -2.8811338],
                            [32.97570219, -2.85997415],
                            [32.94738953, -2.85655257],
                            [32.90952005, -2.83524489],
                            [32.92011038, -2.81659357],
                            [32.91265297, -2.80352029],
                            [32.89137459, -2.82735834],
                            [32.84854317, -2.84479532],
                            [32.82723808, -2.88222131],
                            [32.81806031, -2.93437263],
                            [32.81890359, -2.95532404],
                            [32.78608513, -2.99280939],
                            [32.76255434, -2.99624734],
                            [32.77831406, -2.96231162],
                            [32.76859474, -2.95144663],
                            [32.76430798, -2.92677738],
                            [32.79167366, -2.92242632],
                            [32.80718041, -2.90783319],
                            [32.78326093, -2.88992618],
                            [32.80292569, -2.87749892],
                            [32.77343178, -2.86069879],
                            [32.76271736, -2.87347354],
                            [32.71812087, -2.88114118],
                            [32.72686196, -2.8610898],
                            [32.75059211, -2.8663693],
                            [32.78563881, -2.84929857],
                            [32.82860375, -2.84000978],
                            [32.83510399, -2.82525262],
                            [32.85279633, -2.82679181],
                            [32.87594619, -2.75363323],
                            [32.87222703, -2.72490033],
                            [32.81579594, -2.69707107],
                            [32.82107934, -2.68230095],
                            [32.8385334, -2.67137727],
                            [32.82887869, -2.65842578],
                            [32.81021928, -2.65559026],
                            [32.80944634, -2.62904176],
                            [32.82814564, -2.62913007],
                            [32.81804848, -2.60465821],
                            [32.83242226, -2.58625039],
                            [32.84017754, -2.56163796],
                            [32.82181836, -2.55796534],
                            [32.81534298, -2.53787568],
                            [32.84046362, -2.53508971],
                            [32.83625221, -2.50663575],
                            [32.82154274, -2.50099953],
                            [32.81677017, -2.47891869],
                            [32.79522514, -2.49864396],
                            [32.78336139, -2.48781734],
                            [32.75483132, -2.53179177],
                            [32.74296341, -2.52060105],
                            [32.74101256, -2.50058177],
                            [32.71398327, -2.49472709],
                            [32.6993351, -2.50686272],
                            [32.67429161, -2.4885064],
                            [32.69403412, -2.47851546],
                            [32.69756508, -2.43518074],
                            [32.67782357, -2.4321294],
                            [32.64289284, -2.39790734],
                            [32.63941383, -2.44037827],
                            [32.628191, -2.4626828],
                            [32.61336704, -2.46519755],
                            [32.59971428, -2.50014504],
                            [32.56205872, -2.5178632],
                            [32.52962303, -2.557808],
                            [32.51525307, -2.55312165],
                            [32.50826751, -2.53431599],
                            [32.51623721, -2.52122738],
                            [32.51591683, -2.49764641],
                            [32.53209496, -2.50143822],
                            [32.54405785, -2.48682221],
                            [32.54483892, -2.44459043],
                            [32.53038343, -2.45727744],
                            [32.51196458, -2.45473791],
                            [32.50588417, -2.47415932],
                            [32.48761813, -2.47596915],
                            [32.45280974, -2.46127189],
                            [32.42736843, -2.47539374],
                            [32.42590523, -2.4434758],
                            [32.4046154, -2.43781288],
                            [32.36826549, -2.44333934],
                            [32.3604155, -2.42953126],
                            [32.37617684, -2.41736611],
                            [32.36675321, -2.39575404],
                            [32.34633774, -2.40130903],
                            [32.33452034, -2.38831528],
                            [32.33716277, -2.3582433],
                            [32.31906319, -2.33156403],
                            [32.33544489, -2.32571905],
                            [32.33240069, -2.29965641],
                            [32.31687659, -2.27753207],
                            [32.30174828, -2.27012451],
                            [32.25901645, -2.28159586],
                            [32.24106134, -2.27603079],
                            [32.22696495, -2.29444511],
                            [32.23338127, -2.31429489],
                            [32.19758465, -2.31655422],
                            [32.16031456, -2.38942154],
                            [32.13945527, -2.39174591],
                            [32.10821211, -2.37470562],
                            [32.08110313, -2.38261935],
                            [32.06910896, -2.37504967],
                            [32.05548332, -2.39727226],
                            [32.06910515, -2.42906578],
                            [32.10126686, -2.43956192],
                            [32.10387612, -2.46645173],
                            [32.12296441, -2.47636386],
                            [32.1437397, -2.4640065],
                            [32.17972374, -2.5049611],
                            [32.17799405, -2.5180237],
                            [32.15253227, -2.52112853],
                            [32.14301109, -2.54327782],
                            [32.12758824, -2.52678499],
                            [32.11465984, -2.54401063],
                            [32.09964455, -2.51533112],
                            [32.07069702, -2.51727118],
                            [32.05213223, -2.49063185],
                            [32.02368736, -2.49740418],
                            [32.01131534, -2.48040389],
                            [31.98271751, -2.49587067],
                            [32.00332832, -2.51811036],
                            [31.99622536, -2.52556237],
                            [32.01106866, -2.55150277],
                            [31.99388776, -2.56785568],
                            [32.00361824, -2.58441552],
                            [31.97732735, -2.59890565],
                            [31.93737984, -2.59103974],
                            [31.93096011, -2.60366648],
                            [31.95931895, -2.62415936],
                            [31.98519381, -2.65665818],
                            [31.98954201, -2.68253335],
                            [32.01086235, -2.69743737],
                            [32.00141716, -2.73080072],
                            [32.02622032, -2.74982271],
                            [32.03827858, -2.77089509],
                            [31.9930852, -2.78339107],
                            [31.97337914, -2.77064714],
                            [31.9813963, -2.74745657],
                            [31.97599027, -2.71140585],
                            [31.96032509, -2.7072409],
                            [31.94913618, -2.68803252],
                            [31.945322, -2.65611653],
                            [31.92907496, -2.64866872],
                            [31.90127373, -2.66575059],
                            [31.9146862, -2.68933305],
                            [31.90059471, -2.69342431],
                            [31.87438043, -2.73034976],
                            [31.86627186, -2.72402502],
                            [31.87456043, -2.69852476],
                            [31.85365196, -2.68159541],
                            [31.82180273, -2.69909294],
                            [31.81816673, -2.71393403],
                            [31.83091164, -2.72993478],
                            [31.82428932, -2.74463281],
                            [31.84684944, -2.78892908],
                            [31.84374461, -2.79928037],
                            [31.81397057, -2.8077775],
                            [31.80830193, -2.83499536],
                            [31.7816708, -2.83846312],
                            [31.77561667, -2.8299644],
                            [31.78931341, -2.76437569],
                            [31.78174886, -2.7335365],
                            [31.78954305, -2.71799062],
                            [31.76262474, -2.70365533],
                            [31.78246656, -2.68206776],
                            [31.763073, -2.65681105],
                            [31.81123161, -2.64479836],
                            [31.82756996, -2.61844071],
                            [31.85702438, -2.62630843],
                            [31.85503213, -2.6040347],
                            [31.82819146, -2.59954794],
                            [31.80813114, -2.61073503],
                            [31.75124528, -2.62617761],
                            [31.74313781, -2.57579547],
                            [31.74632172, -2.55726003],
                            [31.72658969, -2.55728412],
                            [31.71406746, -2.53059777],
                            [31.71771431, -2.50647744],
                            [31.73807893, -2.50700149],
                            [31.76239586, -2.49841126],
                            [31.79482079, -2.49664315],
                            [31.79934014, -2.47154521],
                            [31.79063988, -2.42492302],
                            [31.75559426, -2.40584191],
                            [31.76163292, -2.37002571],
                            [31.7714649, -2.35556461],
                            [31.74390984, -2.33607681],
                            [31.75477204, -2.31305059],
                            [31.74824048, -2.30072761],
                            [31.70510482, -2.31176698],
                            [31.67261694, -2.30539334],
                            [31.66305351, -2.28700264],
                            [31.65797997, -2.24752815],
                            [31.66720772, -2.24051864],
                            [31.67223931, -2.20751388],
                            [31.68489647, -2.16279609],
                            [31.6981729, -2.13948181],
                            [31.67507595, -2.12311375],
                            [31.69215584, -2.11344154],
                            [31.69961739, -2.08324249],
                            [31.68914604, -2.05868728],
                            [31.70743095, -2.00399329],
                            [31.69039277, -2.01041927],
                            [31.66644478, -2.04081161],
                            [31.63464311, -2.03902766],
                            [31.61958504, -2.08553893],
                            [31.6019764, -2.08170326],
                            [31.61347771, -2.04165275],
                            [31.61195946, -1.99773604],
                            [31.62559319, -1.95943648],
                            [31.68265343, -1.89987571],
                            [31.68743325, -1.86543662],
                            [31.69973183, -1.84476287],
                            [31.69847298, -1.79544646],
                            [31.70387077, -1.76938826],
                            [31.72422218, -1.72887808],
                            [31.72734261, -1.65023237],
                            [31.74291039, -1.63965612],
                            [31.75476647, -1.60944181],
                            [31.75108253, -1.59419605],
                            [31.75174141, -1.53410345],
                            [31.74844551, -1.51134687],
                            [31.7525066, -1.46283215],
                            [31.76492882, -1.45651441],
                            [31.78301793, -1.48031585],
                            [31.79872321, -1.48152908],
                            [31.80607194, -1.44720143],
                            [31.80293846, -1.4306355],
                            [31.81477509, -1.39570836],
                            [31.81250909, -1.3624879],
                            [31.81707192, -1.34660726],
                            [31.83597374, -1.33395009],
                            [31.8567958, -1.29371365],
                            [31.86852605, -1.2563347],
                            [31.86291973, -1.24842676],
                            [31.86932678, -1.22008265],
                            [31.86181235, -1.21409388],
                            [31.87989394, -1.16824936],
                            [31.85982133, -1.14418597],
                            [31.85716254, -1.12209247],
                            [31.86623194, -1.10210082],
                            [31.84728203, -1.07724809],
                            [31.86534939, -1.063025],
                            [31.87657623, -1.04032895],
                            [31.84191229, -1.03613774],
                            [31.83788919, -0.99448667],
                        ],
                        [
                            [31.88653621, -1.60007447],
                            [31.88080447, -1.61290147],
                            [31.85677776, -1.6242199],
                            [31.87346947, -1.6430587],
                            [31.86553499, -1.65410229],
                            [31.85218858, -1.70174509],
                            [31.87101306, -1.70704274],
                            [31.90591776, -1.66566177],
                            [31.90439626, -1.64190122],
                            [31.89306696, -1.63744002],
                            [31.89685936, -1.60946514],
                            [31.88653621, -1.60007447],
                        ],
                        [
                            [33.34721838, -1.69753336],
                            [33.33161036, -1.71639176],
                            [33.36940193, -1.74003226],
                            [33.37082971, -1.72172347],
                            [33.34721838, -1.69753336],
                        ],
                        [
                            [33.08407021, -1.78955847],
                            [33.07066694, -1.80147455],
                            [33.05140877, -1.79665953],
                            [33.00960217, -1.7984851],
                            [33.00219536, -1.8233338],
                            [33.00520516, -1.86323554],
                            [33.02579308, -1.88295943],
                            [33.05253934, -1.88232253],
                            [33.07224391, -1.86242321],
                            [33.09558794, -1.85772343],
                            [33.0990344, -1.82984716],
                            [33.08558321, -1.82220777],
                            [33.08407021, -1.78955847],
                        ],
                        [
                            [32.92287171, -1.88353549],
                            [32.90371895, -1.89460189],
                            [32.88548035, -1.92609524],
                            [32.85899209, -1.92840246],
                            [32.87355272, -1.95160631],
                            [32.89506008, -1.96438219],
                            [32.90279961, -1.97819716],
                            [32.88300547, -1.99948636],
                            [32.86962663, -1.99886301],
                            [32.83638954, -2.04032714],
                            [32.84523965, -2.05703332],
                            [32.86044121, -2.06184966],
                            [32.88319678, -2.05539883],
                            [32.88436716, -2.07659126],
                            [32.91399193, -2.0830117],
                            [32.93020266, -2.07672498],
                            [32.94765282, -2.0885163],
                            [32.97496372, -2.09081092],
                            [33.03079415, -2.10911948],
                            [33.05794624, -2.13558534],
                            [33.07584953, -2.10712058],
                            [33.09460259, -2.12385757],
                            [33.11836815, -2.15592201],
                            [33.1495586, -2.17511459],
                            [33.16183057, -2.16099605],
                            [33.15044237, -2.15008263],
                            [33.15252379, -2.12877003],
                            [33.17032814, -2.11146362],
                            [33.13123649, -2.0834376],
                            [33.12115192, -2.06965684],
                            [33.13360238, -2.05486706],
                            [33.10498619, -2.03430755],
                            [33.10391045, -2.01055152],
                            [33.11557961, -1.98163611],
                            [33.09250714, -1.96581777],
                            [33.03621665, -1.96579468],
                            [33.00201593, -1.98419604],
                            [32.96422704, -1.97709149],
                            [32.95265361, -1.95968034],
                            [32.92892813, -1.93988081],
                            [32.92690356, -1.92060629],
                            [32.94414874, -1.90179835],
                            [32.92287171, -1.88353549],
                        ],
                        [
                            [33.29558339, -2.17484316],
                            [33.29043932, -2.18932276],
                            [33.27258868, -2.19540033],
                            [33.26892662, -2.21691902],
                            [33.28846187, -2.22186265],
                            [33.30010369, -2.21374713],
                            [33.30688286, -2.18829162],
                            [33.29558339, -2.17484316],
                        ],
                        [
                            [32.06223071, -2.23453331],
                            [32.03279284, -2.21422679],
                            [32.01102257, -2.24320038],
                            [31.97623959, -2.26923877],
                            [31.94261742, -2.27294166],
                            [31.95627428, -2.2881275],
                            [31.97205162, -2.28787048],
                            [31.98774529, -2.30117043],
                            [31.98048973, -2.31965454],
                            [31.94756889, -2.34133347],
                            [31.95741375, -2.35229163],
                            [31.97360039, -2.33976944],
                            [32.00071399, -2.3599335],
                            [32.0414171, -2.3447993],
                            [32.06387251, -2.31028927],
                            [32.05653395, -2.29518766],
                            [32.0770964, -2.27538334],
                            [32.06621623, -2.26318637],
                            [32.04094284, -2.2744631],
                            [32.04189873, -2.25352485],
                            [32.06223071, -2.23453331],
                        ],
                        [
                            [31.8886349, -2.35792288],
                            [31.9080655, -2.33226941],
                            [31.8648864, -2.31766471],
                            [31.85637758, -2.30801291],
                            [31.84520149, -2.27067573],
                            [31.84917854, -2.24362638],
                            [31.82255975, -2.23786676],
                            [31.79189955, -2.29220032],
                            [31.77914238, -2.2951432],
                            [31.79680747, -2.32409242],
                            [31.82673668, -2.30642957],
                            [31.83458901, -2.30937393],
                            [31.82084872, -2.34519202],
                            [31.84391213, -2.36138352],
                            [31.84881783, -2.39376648],
                            [31.83507797, -2.42222625],
                            [31.84194756, -2.45657166],
                            [31.85961342, -2.45853432],
                            [31.88545997, -2.43666124],
                            [31.90616054, -2.43310531],
                            [31.92203521, -2.41405571],
                            [31.92267037, -2.39030655],
                            [31.91403402, -2.37633746],
                            [31.8886349, -2.35792288],
                        ],
                        [
                            [31.99320631, -2.43888171],
                            [31.97316933, -2.43330963],
                            [31.96022606, -2.44178399],
                            [31.9750646, -2.4621981],
                            [31.99320631, -2.43888171],
                        ],
                        [
                            [31.78683158, -2.15589009],
                            [31.79522514, -2.13730247],
                            [31.74622211, -2.11194389],
                            [31.75307079, -2.14748786],
                            [31.78683158, -2.15589009],
                        ],
                        [
                            [32.87364832, -2.09774237],
                            [32.86716652, -2.10595138],
                            [32.88805647, -2.13175021],
                            [32.90431949, -2.13012085],
                            [32.90646172, -2.11102111],
                            [32.87364832, -2.09774237],
                        ],
                        [
                            [31.81917379, -1.97356083],
                            [31.83547123, -1.96565271],
                            [31.82517325, -1.94790543],
                            [31.80910925, -1.95993189],
                            [31.81917379, -1.97356083],
                        ],
                        [
                            [32.43592644, -2.26600845],
                            [32.43033873, -2.29474983],
                            [32.41430473, -2.30376823],
                            [32.40516942, -2.33662117],
                            [32.41158154, -2.35125751],
                            [32.4474392, -2.35932358],
                            [32.44269669, -2.37634965],
                            [32.42462731, -2.39085014],
                            [32.43863106, -2.42410858],
                            [32.46432686, -2.41665848],
                            [32.4780458, -2.39544769],
                            [32.51980019, -2.39096077],
                            [32.52581161, -2.36878099],
                            [32.50982857, -2.36256416],
                            [32.52698708, -2.32159813],
                            [32.54155159, -2.31869133],
                            [32.54188883, -2.27407853],
                            [32.51620293, -2.27757271],
                            [32.50236321, -2.29512604],
                            [32.51515389, -2.31687744],
                            [32.46775787, -2.33535786],
                            [32.4645975, -2.31586817],
                            [32.44847934, -2.30953058],
                            [32.43666229, -2.29237234],
                            [32.43592644, -2.26600845],
                        ],
                        [
                            [32.74334526, -2.42500504],
                            [32.73764992, -2.43772896],
                            [32.75792885, -2.44727333],
                            [32.76361641, -2.42768886],
                            [32.74334526, -2.42500504],
                        ],
                    ],
                    [
                        [
                            [31.82887351, -0.99448667],
                            [31.82766899, -1.0247261],
                            [31.80925598, -1.06424601],
                            [31.77763898, -1.06304397],
                            [31.77035536, -1.04714259],
                            [31.78054619, -1.01764874],
                            [31.78219901, -0.99388276],
                            [31.82887351, -0.99448667],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Victoria",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.42",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [35.31724955, -4.36822146],
                            [35.2993363, -4.36251459],
                            [35.31120205, -4.33797209],
                            [35.34894443, -4.31008621],
                            [35.37889318, -4.27844509],
                            [35.3969528, -4.27363484],
                            [35.4234553, -4.29270596],
                            [35.38843105, -4.32383034],
                            [35.31724955, -4.36822146],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "", Region_Cod: null, zoneId: null },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.43",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [35.89105601, -3.88250975],
                            [35.87224514, -3.883063],
                            [35.86200976, -3.8468245],
                            [35.87611789, -3.8147355],
                            [35.88911951, -3.79869087],
                            [35.89354564, -3.845718],
                            [35.89105601, -3.88250975],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "", Region_Cod: null, zoneId: null },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.44",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [35.28124352, -4.63156978],
                            [35.2662654, -4.64692115],
                            [35.2339409, -4.64970628],
                            [35.19496902, -4.6661179],
                            [35.17533927, -4.6516964],
                            [35.1838084, -4.63992053],
                            [35.21391365, -4.63070665],
                            [35.23187465, -4.63276565],
                            [35.25933915, -4.61483203],
                            [35.28124352, -4.63156978],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "", Region_Cod: null, zoneId: null },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.45",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [35.97459755, -2.08817405],
                            [36.07292294, -2.14636184],
                            [36.07352809, -2.16906306],
                            [36.11001217, -2.18643679],
                            [36.12152678, -2.21282367],
                            [36.12573115, -2.27249097],
                            [36.1154866, -2.36975012],
                            [36.10220071, -2.39183992],
                            [36.09979643, -2.41476661],
                            [36.08451822, -2.45098439],
                            [36.06702313, -2.46917809],
                            [36.05864553, -2.4910584],
                            [36.00889579, -2.5774692],
                            [36.00891145, -2.59211146],
                            [35.99409172, -2.61479521],
                            [35.95212618, -2.57800675],
                            [35.93682425, -2.59063531],
                            [35.91481012, -2.59538566],
                            [35.88880467, -2.57494134],
                            [35.89719876, -2.54538109],
                            [35.91548853, -2.52352309],
                            [35.89226813, -2.48820218],
                            [35.89015029, -2.46537114],
                            [35.89941908, -2.39949823],
                            [35.90737688, -2.36811551],
                            [35.92063197, -2.36246759],
                            [35.94451281, -2.33555995],
                            [35.97135663, -2.33062369],
                            [35.97681114, -2.31449816],
                            [35.96284103, -2.28542322],
                            [35.97535062, -2.24304651],
                            [35.95278735, -2.20040995],
                            [35.9441718, -2.15277014],
                            [35.95183644, -2.13111827],
                            [35.94684128, -2.11306034],
                            [35.95609736, -2.07771316],
                            [35.97459755, -2.08817405],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Natron",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.46",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [30.56721838, -1.33471185],
                            [30.61048297, -1.35089211],
                            [30.62050769, -1.37604185],
                            [30.67168654, -1.38518721],
                            [30.67467637, -1.39556368],
                            [30.7418596, -1.39767415],
                            [30.73693517, -1.42704483],
                            [30.74238722, -1.45500853],
                            [30.73939739, -1.47453036],
                            [30.72515173, -1.49756964],
                            [30.73218662, -1.50952896],
                            [30.75909509, -1.51849845],
                            [30.772989, -1.53626155],
                            [30.77386836, -1.5791744],
                            [30.80464602, -1.59781687],
                            [30.81818819, -1.61434887],
                            [30.83735827, -1.61065555],
                            [30.85884947, -1.63862501],
                            [30.89258618, -1.60855534],
                            [30.89451143, -1.64548454],
                            [30.91305357, -1.67004891],
                            [30.89340208, -1.67424862],
                            [30.9045749, -1.69976386],
                            [30.8915957, -1.71113918],
                            [30.8780721, -1.65183254],
                            [30.83683066, -1.64653351],
                            [30.83806177, -1.66253789],
                            [30.82188151, -1.68047687],
                            [30.82557483, -1.71494784],
                            [30.81062568, -1.72901763],
                            [30.82874053, -1.75258452],
                            [30.82346436, -1.77439269],
                            [30.83665479, -1.85353524],
                            [30.81642947, -1.87165009],
                            [30.81607772, -1.88642337],
                            [30.78864164, -1.92898448],
                            [30.80974632, -1.9339089],
                            [30.82135389, -1.96556592],
                            [30.8041184, -1.98209792],
                            [30.80693236, -2.0197346],
                            [30.83348908, -2.00654418],
                            [30.85291715, -2.02864963],
                            [30.79931978, -2.036376],
                            [30.78705978, -2.0267545],
                            [30.7977534, -2.00825125],
                            [30.79010491, -1.99458675],
                            [30.75352003, -2.04235038],
                            [30.74639241, -2.07040238],
                            [30.71946454, -2.06428688],
                            [30.73978978, -2.04314475],
                            [30.75838391, -2.01155925],
                            [30.76829866, -1.97854137],
                            [30.76395916, -1.95311675],
                            [30.74638328, -1.92486687],
                            [30.76867078, -1.88877225],
                            [30.74442466, -1.88903437],
                            [30.73653241, -1.90166712],
                            [30.71727416, -1.90695537],
                            [30.68862316, -1.889049],
                            [30.70945229, -1.86201237],
                            [30.72873066, -1.85445587],
                            [30.71393904, -1.82124562],
                            [30.74674266, -1.78477699],
                            [30.75319303, -1.72815312],
                            [30.77997916, -1.69933912],
                            [30.78111728, -1.67395674],
                            [30.74892391, -1.69366424],
                            [30.73270778, -1.71711774],
                            [30.71950391, -1.71021774],
                            [30.72012579, -1.69163437],
                            [30.73677091, -1.67135799],
                            [30.71587504, -1.65488199],
                            [30.70515241, -1.67792199],
                            [30.67595191, -1.66999762],
                            [30.67342104, -1.64549899],
                            [30.69091341, -1.63248324],
                            [30.69620766, -1.60214786],
                            [30.68129266, -1.58163911],
                            [30.70997366, -1.54376824],
                            [30.72163429, -1.51710511],
                            [30.69833279, -1.51421711],
                            [30.67661054, -1.48686249],
                            [30.70935904, -1.45400174],
                            [30.70000604, -1.42401548],
                            [30.68356391, -1.42118036],
                            [30.66364091, -1.39701573],
                            [30.65323017, -1.43909936],
                            [30.65390892, -1.46720849],
                            [30.64008829, -1.47934949],
                            [30.62187029, -1.47060686],
                            [30.61284929, -1.45558536],
                            [30.60546242, -1.40930098],
                            [30.60800242, -1.37849761],
                            [30.57570917, -1.35742436],
                            [30.55893955, -1.33918073],
                            [30.5483233, -1.35134373],
                            [30.54447743, -1.37442986],
                            [30.52893468, -1.37386936],
                            [30.53027293, -1.32221498],
                            [30.54984491, -1.29682049],
                            [30.56721838, -1.33471185],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "", Region_Cod: null, zoneId: null },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.47",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [30.81961839, -1.41026383],
                            [30.83915052, -1.42072548],
                            [30.82458644, -1.43820074],
                            [30.75718337, -1.45534829],
                            [30.78211327, -1.41577701],
                            [30.81961839, -1.41026383],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "", Region_Cod: null, zoneId: null },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.48",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [37.73992037, -3.52890522],
                            [37.76372248, -3.56758235],
                            [37.77549384, -3.6247575],
                            [37.749055, -3.61822823],
                            [37.75171238, -3.60361266],
                            [37.73643246, -3.59364749],
                            [37.73443942, -3.55777288],
                            [37.72846032, -3.54515034],
                            [37.73992037, -3.52890522],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Lake Jipe",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.49",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [37.4610424, -3.58467685],
                            [37.45892525, -3.59777843],
                            [37.43552589, -3.62563716],
                            [37.41580391, -3.65749561],
                            [37.41134453, -3.67847263],
                            [37.37080956, -3.65280544],
                            [37.37444878, -3.6411153],
                            [37.40073586, -3.64387142],
                            [37.39837837, -3.62776195],
                            [37.40957832, -3.60968791],
                            [37.44781303, -3.59687816],
                            [37.4610424, -3.58467685],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Nyumba ya Mungu DAM",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.50",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [30.9311393, -6.93605614],
                            [30.94887109, -6.93999683],
                            [30.96949905, -6.96531715],
                            [30.96923442, -6.99950444],
                            [30.95319579, -7.06324477],
                            [30.95448417, -7.07671487],
                            [30.98544748, -7.11144837],
                            [31.05130117, -7.11495012],
                            [31.07949756, -7.1271283],
                            [31.14664516, -7.17470883],
                            [31.1444453, -7.19683082],
                            [31.1611255, -7.21774114],
                            [31.18664976, -7.21163711],
                            [31.27297828, -7.2171827],
                            [31.30189268, -7.22947001],
                            [31.32106427, -7.24511603],
                            [31.34677601, -7.25235373],
                            [31.33800387, -7.3258057],
                            [31.3401175, -7.35672944],
                            [31.35121404, -7.41655314],
                            [31.36061788, -7.4456566],
                            [31.37529374, -7.47073845],
                            [31.42929222, -7.46644215],
                            [31.42992666, -7.45829157],
                            [31.4796283, -7.46053529],
                            [31.49152262, -7.50844625],
                            [31.51105241, -7.52807242],
                            [31.52411399, -7.52911967],
                            [31.53208983, -7.54820463],
                            [31.53956538, -7.56678636],
                            [31.5710765, -7.58316871],
                            [31.57230531, -7.60005794],
                            [31.60218051, -7.61968862],
                            [31.60673316, -7.65056278],
                            [31.616688, -7.67004926],
                            [31.64391426, -7.68044132],
                            [31.65954003, -7.70229203],
                            [31.69593768, -7.71198653],
                            [31.71229902, -7.70151891],
                            [31.75783517, -7.70723985],
                            [31.77018193, -7.70398821],
                            [31.81461447, -7.75797397],
                            [31.84392501, -7.79764515],
                            [31.8593325, -7.83109984],
                            [31.86902537, -7.83173218],
                            [31.87569385, -7.86374832],
                            [31.8745641, -7.90087325],
                            [31.89529277, -7.92543373],
                            [31.90748927, -7.93033552],
                            [31.91464542, -7.96491299],
                            [31.92507606, -7.98444208],
                            [31.95471533, -8.00711523],
                            [31.95872537, -8.02572115],
                            [31.99617248, -8.06526239],
                            [32.00678306, -8.10681151],
                            [32.02998903, -8.12561836],
                            [32.02872384, -8.16085123],
                            [32.04090348, -8.1687022],
                            [32.0457423, -8.19656353],
                            [32.07030115, -8.21049178],
                            [32.09316206, -8.21449657],
                            [32.10734507, -8.20224983],
                            [32.11586162, -8.17670255],
                            [32.18414845, -8.18623468],
                            [32.20252301, -8.18282914],
                            [32.21528426, -8.16304015],
                            [32.25502664, -8.18628718],
                            [32.25328485, -8.12600185],
                            [32.26327442, -8.12549551],
                            [32.26665792, -8.09623082],
                            [32.28395997, -8.07745468],
                            [32.31669369, -8.09069519],
                            [32.32407198, -8.10228081],
                            [32.36724964, -8.11671761],
                            [32.39215543, -8.13707407],
                            [32.38850584, -8.15478429],
                            [32.40705063, -8.20560814],
                            [32.4242357, -8.20890754],
                            [32.45377578, -8.23699839],
                            [32.43250165, -8.24047793],
                            [32.41909444, -8.25760101],
                            [32.4158404, -8.27652417],
                            [32.40197916, -8.2831156],
                            [32.38615121, -8.31171625],
                            [32.36290748, -8.30684891],
                            [32.30655136, -8.31321787],
                            [32.27764037, -8.36113948],
                            [32.2657326, -8.38962933],
                            [32.27649666, -8.403337],
                            [32.27575351, -8.45345725],
                            [32.29768424, -8.47485592],
                            [32.30136355, -8.51069335],
                            [32.28803742, -8.55952208],
                            [32.29085156, -8.57063371],
                            [32.25397077, -8.61185707],
                            [32.25466486, -8.65579184],
                            [32.22853407, -8.6962767],
                            [32.21322328, -8.70846993],
                            [32.18251748, -8.71355949],
                            [32.16558165, -8.69503707],
                            [32.13512093, -8.69690939],
                            [32.11582455, -8.70512332],
                            [32.08204616, -8.73553231],
                            [32.05757504, -8.77811557],
                            [32.0201612, -8.80670321],
                            [32.02770124, -8.81907096],
                            [32.05735218, -8.83530024],
                            [32.08095525, -8.85570412],
                            [32.06490917, -8.87257109],
                            [32.10069881, -8.90577733],
                            [32.13374491, -8.92994555],
                            [32.14178459, -8.94481963],
                            [32.12197833, -8.95440911],
                            [32.10636569, -8.98822451],
                            [32.11325414, -9.05516304],
                            [32.0607717, -9.04780543],
                            [32.01834001, -9.05813274],
                            [32.00093231, -9.07334185],
                            [31.96827552, -9.05357882],
                            [31.93933418, -9.0073268],
                            [31.92999126, -8.98050148],
                            [31.94827796, -8.93960357],
                            [31.94467229, -8.92995792],
                            [31.85213845, -8.89325041],
                            [31.7891901, -8.88702932],
                            [31.76585938, -8.8932757],
                            [31.73336433, -8.92327518],
                            [31.70249185, -8.92106102],
                            [31.68102544, -8.91315736],
                            [31.64876367, -8.87751456],
                            [31.6134081, -8.86142806],
                            [31.58321741, -8.84114328],
                            [31.56819821, -8.81158163],
                            [31.58413858, -8.75805162],
                            [31.57190302, -8.71314635],
                            [31.5390694, -8.67568945],
                            [31.48642665, -8.67668851],
                            [31.45846455, -8.63442884],
                            [31.41096459, -8.63435754],
                            [31.39648676, -8.62466375],
                            [31.3790608, -8.59719547],
                            [31.34602247, -8.61391318],
                            [31.31036653, -8.60653321],
                            [31.29212207, -8.62842794],
                            [31.26460651, -8.62679931],
                            [31.24842156, -8.59481707],
                            [31.21147474, -8.5819766],
                            [31.19433062, -8.59280452],
                            [31.17397876, -8.58316972],
                            [31.174957, -8.55784252],
                            [31.14442029, -8.52660278],
                            [31.13883573, -8.49346501],
                            [31.14253874, -8.47783016],
                            [31.12990458, -8.46482645],
                            [31.14541934, -8.44443163],
                            [31.1486422, -8.42157982],
                            [31.13021716, -8.39981001],
                            [31.12849019, -8.38174125],
                            [31.07750339, -8.36343603],
                            [31.05619603, -8.33323207],
                            [31.03686739, -8.33102994],
                            [31.02411659, -8.28250684],
                            [30.97895505, -8.27024416],
                            [30.98561506, -8.24185571],
                            [30.96094462, -8.21768966],
                            [30.96059443, -8.20264044],
                            [30.94695002, -8.18555829],
                            [30.94738809, -8.16239237],
                            [30.96674477, -8.154527],
                            [30.97097634, -8.13922197],
                            [30.95977266, -8.11620092],
                            [30.94236426, -8.10443633],
                            [30.91793917, -8.10424153],
                            [30.90350478, -8.06694367],
                            [30.90890784, -8.04191348],
                            [30.89987108, -8.01278481],
                            [30.85994083, -7.96114985],
                            [30.83804691, -7.95852571],
                            [30.84166719, -7.9089444],
                            [30.8271201, -7.89107606],
                            [30.80586044, -7.88565803],
                            [30.78712577, -7.85683918],
                            [30.77923611, -7.81227115],
                            [30.76330009, -7.78731842],
                            [30.76395075, -7.76908482],
                            [30.75249829, -7.73476108],
                            [30.73436473, -7.70703376],
                            [30.70950074, -7.69529643],
                            [30.69194614, -7.66370334],
                            [30.65720779, -7.63533303],
                            [30.65094604, -7.6075954],
                            [30.61938278, -7.56494771],
                            [30.61584103, -7.54211288],
                            [30.59160311, -7.52070494],
                            [30.57425038, -7.49080105],
                            [30.60613359, -7.48049076],
                            [30.59657587, -7.46098031],
                            [30.60071297, -7.44294794],
                            [30.59054765, -7.42412157],
                            [30.60216409, -7.41408485],
                            [30.61623429, -7.3834023],
                            [30.61075017, -7.34943086],
                            [30.59978007, -7.31687891],
                            [30.58061321, -7.29678015],
                            [30.57800053, -7.27991066],
                            [30.55222627, -7.25462087],
                            [30.54822754, -7.22580028],
                            [30.5306499, -7.18833726],
                            [30.52096025, -7.18034102],
                            [30.53674468, -7.15816801],
                            [30.52233007, -7.13951771],
                            [30.5607132, -7.0844616],
                            [30.55055331, -7.04270613],
                            [30.56491246, -7.02551256],
                            [30.57511272, -6.98425612],
                            [30.56950391, -6.96418602],
                            [30.51513599, -6.92797696],
                            [30.50306681, -6.91292839],
                            [30.55116684, -6.90826212],
                            [30.56642162, -6.90248767],
                            [30.60570461, -6.89285389],
                            [30.66500281, -6.88559719],
                            [30.70504303, -6.88883482],
                            [30.75044177, -6.89865033],
                            [30.77329121, -6.90929379],
                            [30.80483552, -6.9144743],
                            [30.85038687, -6.91807739],
                            [30.9311393, -6.93605614],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Rukwa", Region_Cod: 15, zoneId: 5 },
            drilldown: 15,
            region_nam: "Rukwa",
            value: 5.6,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.51",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [33.67121049, -9.58131667],
                            [33.67109868, -9.60645567],
                            [33.62741831, -9.60250784],
                            [33.59303102, -9.5786073],
                            [33.56638446, -9.58845416],
                            [33.55343172, -9.58542546],
                            [33.5408491, -9.60809968],
                            [33.50751679, -9.62393084],
                            [33.44373953, -9.61224194],
                            [33.42489075, -9.59533615],
                            [33.42520437, -9.57533054],
                            [33.41093627, -9.55438613],
                            [33.36250735, -9.52398027],
                            [33.3254743, -9.48392818],
                            [33.30719819, -9.48182522],
                            [33.26425769, -9.49703247],
                            [33.25106627, -9.48507496],
                            [33.20897418, -9.50436385],
                            [33.19009328, -9.49525863],
                            [33.1433056, -9.49084648],
                            [33.12429463, -9.47978948],
                            [33.11664578, -9.46407531],
                            [33.06936103, -9.43825173],
                            [33.04543428, -9.41366986],
                            [33.0253619, -9.40198235],
                            [33.01076532, -9.37146526],
                            [32.99520884, -9.36602088],
                            [32.95309102, -9.39791807],
                            [32.9265636, -9.40073187],
                            [32.90898783, -9.37738463],
                            [32.89343934, -9.38871488],
                            [32.87450761, -9.37020571],
                            [32.79830007, -9.32326497],
                            [32.76714416, -9.3227611],
                            [32.75613425, -9.28586902],
                            [32.70872592, -9.27618369],
                            [32.64635623, -9.27274539],
                            [32.57468149, -9.24681117],
                            [32.5439272, -9.25451931],
                            [32.48387537, -9.17174256],
                            [32.49236117, -9.14851688],
                            [32.43445326, -9.11657501],
                            [32.36056891, -9.12605297],
                            [32.2496973, -9.12917564],
                            [32.19098425, -9.08762906],
                            [32.15972421, -9.05557518],
                            [32.13682799, -9.06201106],
                            [32.11319575, -9.05681157],
                            [32.10655604, -8.98817692],
                            [32.12216868, -8.95436151],
                            [32.14197495, -8.94477203],
                            [32.13393526, -8.92989795],
                            [32.10088917, -8.90572974],
                            [32.06509952, -8.8725235],
                            [32.08114561, -8.85565653],
                            [32.05754254, -8.83525265],
                            [32.0278916, -8.81902336],
                            [32.02035155, -8.80665562],
                            [32.05776539, -8.77806798],
                            [32.08223651, -8.73548472],
                            [32.1160149, -8.70507573],
                            [32.13531128, -8.6968618],
                            [32.165772, -8.69498947],
                            [32.18270784, -8.7135119],
                            [32.21341363, -8.70842234],
                            [32.22872442, -8.69622911],
                            [32.25485521, -8.65574424],
                            [32.25416112, -8.61180947],
                            [32.29104191, -8.57058611],
                            [32.28822777, -8.55947449],
                            [32.3015539, -8.51064575],
                            [32.29787459, -8.47480833],
                            [32.27594386, -8.45340966],
                            [32.27668701, -8.4032894],
                            [32.26519602, -8.38988337],
                            [32.27783073, -8.36109188],
                            [32.30674172, -8.31317028],
                            [32.36309784, -8.30680132],
                            [32.38634157, -8.31166866],
                            [32.40216951, -8.28306801],
                            [32.41603075, -8.27647658],
                            [32.4192848, -8.25755342],
                            [32.43285785, -8.24024822],
                            [32.45076463, -8.25024881],
                            [32.44521031, -8.26396303],
                            [32.46057637, -8.29673534],
                            [32.50569543, -8.33777849],
                            [32.54256263, -8.35201078],
                            [32.56693944, -8.38044022],
                            [32.60016782, -8.40025579],
                            [32.60203748, -8.42780536],
                            [32.61215708, -8.44788077],
                            [32.65293132, -8.4707547],
                            [32.67368407, -8.47349904],
                            [32.69226807, -8.48605607],
                            [32.73495363, -8.47553107],
                            [32.77610241, -8.49862519],
                            [32.79961622, -8.50083204],
                            [32.82848971, -8.48849044],
                            [32.8734763, -8.49249292],
                            [32.88464618, -8.49948945],
                            [32.90601785, -8.49188037],
                            [32.90809858, -8.46334529],
                            [32.92174753, -8.4458789],
                            [32.9124509, -8.41636121],
                            [32.89956835, -8.41102222],
                            [32.89789801, -8.38584934],
                            [32.82084773, -8.34885041],
                            [32.79933931, -8.32733356],
                            [32.76246323, -8.27818531],
                            [32.7535473, -8.27753624],
                            [32.72387262, -8.23977635],
                            [32.68445527, -8.19614965],
                            [32.67552097, -8.16747795],
                            [32.65520394, -8.15802071],
                            [32.65195654, -8.12883841],
                            [32.64060172, -8.11492534],
                            [32.6221598, -8.12984166],
                            [32.6077362, -8.1303736],
                            [32.57565478, -8.08924306],
                            [32.57136734, -8.0609969],
                            [32.54049464, -8.04298721],
                            [32.49761238, -8.00242277],
                            [32.45009686, -7.97064615],
                            [32.42411382, -7.97055654],
                            [32.42016359, -7.95670113],
                            [32.4003871, -7.94225527],
                            [32.37619186, -7.90816907],
                            [32.33625354, -7.87881455],
                            [32.29692132, -7.83548987],
                            [32.26895043, -7.81368734],
                            [32.25746452, -7.8131524],
                            [32.23788908, -7.78968929],
                            [32.20424795, -7.76697171],
                            [32.18957873, -7.74480321],
                            [32.17860168, -7.74915884],
                            [32.1461603, -7.71060649],
                            [32.04304286, -7.60535191],
                            [32.06050756, -7.58222011],
                            [32.07911179, -7.5682291],
                            [32.08896298, -7.54520833],
                            [32.08966323, -7.51869275],
                            [32.1106929, -7.51513162],
                            [32.13102625, -7.53731304],
                            [32.14873996, -7.51494963],
                            [32.19039599, -7.516187],
                            [32.19119744, -7.50125616],
                            [32.21061324, -7.46215823],
                            [32.23893091, -7.4183114],
                            [32.26751079, -7.36294685],
                            [32.27914055, -7.26950751],
                            [32.31935524, -7.24047387],
                            [32.32479732, -7.22950744],
                            [32.36982695, -7.20135749],
                            [32.4022753, -7.18990619],
                            [32.46377336, -7.14327058],
                            [32.48965037, -7.15064894],
                            [32.4871876, -7.13178822],
                            [32.54632265, -7.11595855],
                            [32.55601676, -7.10257665],
                            [32.58171988, -7.09013916],
                            [32.58779044, -7.06240401],
                            [32.57590758, -7.0305806],
                            [32.58682747, -6.9986359],
                            [32.63708266, -6.99942133],
                            [32.67970957, -6.99610441],
                            [32.74559999, -6.99763262],
                            [32.74700236, -6.97531975],
                            [32.779267, -6.97645594],
                            [32.78935886, -6.94441817],
                            [32.81341065, -6.93252677],
                            [32.84091253, -6.92945333],
                            [32.86838957, -6.94594077],
                            [32.8936526, -6.92638219],
                            [32.89689142, -6.99982129],
                            [32.89554656, -7.33290907],
                            [32.89557535, -7.44216412],
                            [32.90099429, -7.47382576],
                            [32.91346362, -7.5021341],
                            [32.91889126, -7.5433656],
                            [32.94172142, -7.54553927],
                            [32.9721632, -7.52980961],
                            [33.01456125, -7.52764024],
                            [33.03933407, -7.53546678],
                            [33.03139699, -7.590636],
                            [33.0302527, -7.62588191],
                            [33.03186665, -7.73894269],
                            [33.03988621, -7.75526294],
                            [32.99213814, -7.7542559],
                            [32.96360875, -7.80112605],
                            [32.93232953, -7.871799],
                            [33.04522229, -7.87121813],
                            [33.08672408, -7.86899882],
                            [33.06806881, -7.89625533],
                            [33.04396459, -7.94849354],
                            [33.01976116, -7.98742905],
                            [33.02934747, -8.02592982],
                            [33.0180167, -8.0595306],
                            [33.00339023, -8.07237239],
                            [32.99186608, -8.10752977],
                            [32.99108639, -8.14497462],
                            [32.97084445, -8.17604657],
                            [32.97138712, -8.20879563],
                            [32.97977912, -8.24806232],
                            [32.97001995, -8.26826501],
                            [32.97748704, -8.29308514],
                            [32.99827955, -8.28982607],
                            [33.02226639, -8.27317042],
                            [33.03382204, -8.25419734],
                            [33.04748625, -8.25151667],
                            [33.0986578, -8.26207969],
                            [33.11769977, -8.25700563],
                            [33.15273891, -8.27118677],
                            [33.16313009, -8.31185816],
                            [33.14031351, -8.33815271],
                            [33.11858, -8.34967343],
                            [33.10882658, -8.36988299],
                            [33.06268667, -8.38596504],
                            [33.0474274, -8.45610554],
                            [33.07116667, -8.46208105],
                            [33.09298969, -8.48872425],
                            [33.12576698, -8.49373565],
                            [33.13093454, -8.57638028],
                            [33.13753492, -8.59018452],
                            [33.17537563, -8.61786441],
                            [33.19265637, -8.64633801],
                            [33.21465945, -8.58728056],
                            [33.2115422, -8.56295197],
                            [33.22119505, -8.48300394],
                            [33.22174821, -8.41210828],
                            [33.22980523, -8.38820763],
                            [33.25203764, -8.36306702],
                            [33.27581266, -8.28371883],
                            [33.32441599, -8.28598073],
                            [33.35996803, -8.29420521],
                            [33.36633115, -8.32640965],
                            [33.37495393, -8.33378222],
                            [33.3513131, -8.36495576],
                            [33.3570146, -8.38091337],
                            [33.33502507, -8.46229099],
                            [33.34462921, -8.4934308],
                            [33.32664352, -8.49656325],
                            [33.31243789, -8.51379569],
                            [33.31117144, -8.54662315],
                            [33.27563465, -8.57947654],
                            [33.26096847, -8.6077993],
                            [33.22416362, -8.63512662],
                            [33.20961972, -8.66072933],
                            [33.20907396, -8.68813486],
                            [33.22994027, -8.69980518],
                            [33.22675329, -8.7243303],
                            [33.16875655, -8.7044888],
                            [33.14232489, -8.68827572],
                            [33.12675572, -8.64487205],
                            [33.08894471, -8.60863969],
                            [33.06721739, -8.61231296],
                            [33.04440947, -8.64368762],
                            [33.01025721, -8.70954582],
                            [33.03220148, -8.73967468],
                            [33.09744051, -8.78762388],
                            [33.14124833, -8.8149314],
                            [33.16510348, -8.81681111],
                            [33.19134455, -8.87819227],
                            [33.20860748, -8.88183703],
                            [33.21486373, -8.90132137],
                            [33.21644574, -8.93649176],
                            [33.22700351, -8.96544542],
                            [33.21639322, -8.97973765],
                            [33.22335207, -8.99533925],
                            [33.21408671, -9.04381617],
                            [33.2348906, -9.08795687],
                            [33.21194483, -9.11724122],
                            [33.21109376, -9.13113928],
                            [33.2664657, -9.15462797],
                            [33.25368782, -9.18887735],
                            [33.19839157, -9.19130338],
                            [33.16626759, -9.20525019],
                            [33.14744128, -9.24464966],
                            [33.13090622, -9.24068161],
                            [33.11776758, -9.26914106],
                            [33.15034171, -9.29746126],
                            [33.20961094, -9.29774158],
                            [33.23303445, -9.27445377],
                            [33.23313498, -9.26404777],
                            [33.28360231, -9.23453481],
                            [33.32348561, -9.2398071],
                            [33.32693257, -9.26002402],
                            [33.34489336, -9.28782604],
                            [33.36938396, -9.28396498],
                            [33.38476831, -9.32484439],
                            [33.40531145, -9.32385433],
                            [33.41910581, -9.33381987],
                            [33.43387382, -9.29709498],
                            [33.43699569, -9.27478912],
                            [33.42876299, -9.21288268],
                            [33.43724023, -9.15085552],
                            [33.45038506, -9.14263275],
                            [33.45294908, -9.12009509],
                            [33.4333977, -9.10277683],
                            [33.4337326, -9.07038716],
                            [33.45915783, -9.05022741],
                            [33.45376151, -8.99936207],
                            [33.51199265, -8.97871791],
                            [33.52567177, -8.99233828],
                            [33.54340422, -8.99483084],
                            [33.60149342, -8.99149811],
                            [33.64417811, -8.99986101],
                            [33.66593859, -9.03723189],
                            [33.70116996, -9.0362456],
                            [33.72464585, -9.02175989],
                            [33.74468217, -9.01956189],
                            [33.73779149, -9.04420053],
                            [33.74256054, -9.08630682],
                            [33.73255989, -9.10223135],
                            [33.70693722, -9.10840213],
                            [33.69186638, -9.13777752],
                            [33.73397108, -9.16193782],
                            [33.74351704, -9.18910081],
                            [33.73731517, -9.19891628],
                            [33.73734966, -9.23867224],
                            [33.71981786, -9.23475447],
                            [33.72742746, -9.27360083],
                            [33.72570935, -9.29140076],
                            [33.75084891, -9.30164086],
                            [33.758606, -9.32728986],
                            [33.79012349, -9.32313004],
                            [33.78472793, -9.35224146],
                            [33.79371846, -9.37455739],
                            [33.82297219, -9.41808166],
                            [33.80890113, -9.42966344],
                            [33.81723042, -9.4488722],
                            [33.77956674, -9.47369314],
                            [33.76608615, -9.49386754],
                            [33.73642527, -9.49044462],
                            [33.71351363, -9.48016135],
                            [33.7022155, -9.46082371],
                            [33.67209827, -9.4666308],
                            [33.6809887, -9.503187],
                            [33.67856504, -9.52935363],
                            [33.66835018, -9.54764801],
                            [33.67121049, -9.58131667],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: { LAKES: "", Region_Nam: "Songwe", Region_Cod: 26, zoneId: 5 },
            drilldown: 26,
            region_nam: "Songwe",
            value: 5.4,
        },
        {
            type: "Feature",
            id: "CENSUS-TZ-REGIONS.52",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [41.4986564, -10.5506175],
                            [40.32692319, -10.57135614],
                            [40.32684097, -10.56909006],
                            [40.33174475, -10.56960349],
                            [40.33676856, -10.56958224],
                            [40.34065553, -10.56863951],
                            [40.3459676, -10.56695943],
                            [40.3509308, -10.56435452],
                            [40.35604145, -10.56170017],
                            [40.35982351, -10.55934397],
                            [40.3628616, -10.55582097],
                            [40.36464809, -10.55175092],
                            [40.36534842, -10.55015539],
                            [40.36752272, -10.54536517],
                            [40.3677872, -10.5447825],
                            [40.36831946, -10.54263526],
                            [40.36909561, -10.54146374],
                            [40.36953351, -10.54025532],
                            [40.36996963, -10.53864473],
                            [40.37110363, -10.53615364],
                            [40.3717978, -10.53443224],
                            [40.37333482, -10.53120817],
                            [40.37597157, -10.52611469],
                            [40.37846408, -10.52182618],
                            [40.38103609, -10.51881695],
                            [40.38195225, -10.51720426],
                            [40.3845546, -10.51273243],
                            [40.38797378, -10.50924416],
                            [40.38675585, -10.5094323],
                            [40.39091132, -10.50806318],
                            [40.39391861, -10.50769656],
                            [40.394986, -10.50600184],
                            [40.39653473, -10.50461509],
                            [40.39790408, -10.50315584],
                            [40.39995648, -10.50096889],
                            [40.40132584, -10.49951057],
                            [40.40337818, -10.49732269],
                            [40.4049748, -10.49562052],
                            [40.4047311, -10.49563187],
                            [40.40051526, -10.49572638],
                            [40.39760089, -10.49543566],
                            [40.39781962, -10.49293025],
                            [40.39950028, -10.49163268],
                            [40.39971524, -10.48829247],
                            [40.40039014, -10.48495024],
                            [40.39876538, -10.48161813],
                            [40.39730112, -10.47987905],
                            [40.3986717, -10.47782393],
                            [40.39973497, -10.47561837],
                            [40.39781797, -10.47547502],
                            [40.39750387, -10.47380677],
                            [40.39460057, -10.47594449],
                            [40.39070005, -10.47793478],
                            [40.38863242, -10.47839917],
                            [40.38961743, -10.47581452],
                            [40.38960796, -10.47368956],
                            [40.38807422, -10.47354447],
                            [40.38585871, -10.47522377],
                            [40.38295833, -10.47804443],
                            [40.38089539, -10.47957125],
                            [40.38012111, -10.47782907],
                            [40.38194938, -10.47524078],
                            [40.38186667, -10.47387506],
                            [40.379949, -10.47357981],
                            [40.37810865, -10.473436],
                            [40.37581307, -10.47435666],
                            [40.37429204, -10.47709538],
                            [40.37545357, -10.47974662],
                            [40.37523232, -10.48172079],
                            [40.37439212, -10.48240746],
                            [40.37315259, -10.47945299],
                            [40.37184511, -10.47847202],
                            [40.36992641, -10.47794904],
                            [40.36824268, -10.47856343],
                            [40.3645659, -10.47918637],
                            [40.36134175, -10.47813768],
                            [40.36156608, -10.47684653],
                            [40.36063331, -10.47389068],
                            [40.35710491, -10.47337449],
                            [40.35227802, -10.4739263],
                            [40.3501271, -10.47287292],
                            [40.34719688, -10.46886296],
                            [40.34554065, -10.46779595],
                            [40.34554351, -10.46778304],
                            [40.34517203, -10.46727274],
                            [40.34452064, -10.46713881],
                            [40.34395232, -10.46702196],
                            [40.3427334, -10.46695398],
                            [40.34136582, -10.46666725],
                            [40.34062194, -10.46542727],
                            [40.34117066, -10.46421842],
                            [40.34238486, -10.46318956],
                            [40.34244897, -10.46092245],
                            [40.34266157, -10.45883752],
                            [40.34321216, -10.4580674],
                            [40.34409635, -10.45758837],
                            [40.34434838, -10.45608826],
                            [40.34434492, -10.45528391],
                            [40.3442277, -10.45378537],
                            [40.34462586, -10.45191903],
                            [40.3453221, -10.45067298],
                            [40.3462402, -10.44949913],
                            [40.34893969, -10.45040177],
                            [40.35141195, -10.44998912],
                            [40.35192069, -10.44808574],
                            [40.35242783, -10.44581676],
                            [40.35401243, -10.4450788],
                            [40.35448581, -10.44354119],
                            [40.35429311, -10.44167736],
                            [40.35333071, -10.44113301],
                            [40.35273613, -10.44025805],
                            [40.35217642, -10.43890763],
                            [40.35124795, -10.43766845],
                            [40.35039428, -10.43664833],
                            [40.35016675, -10.43525994],
                            [40.35012399, -10.43390733],
                            [40.3500028, -10.43215943],
                            [40.35054193, -10.43214192],
                            [40.35065161, -10.43147268],
                            [40.34946583, -10.43058598],
                            [40.34782932, -10.42958971],
                            [40.34782261, -10.42802924],
                            [40.34843451, -10.4263547],
                            [40.34848048, -10.42395803],
                            [40.34914458, -10.42133582],
                            [40.34851399, -10.41866334],
                            [40.34763681, -10.41849194],
                            [40.34570228, -10.41646154],
                            [40.34376613, -10.41395638],
                            [40.34355801, -10.41301417],
                            [40.34349838, -10.41274418],
                            [40.34345519, -10.41274174],
                            [40.34344421, -10.41269201],
                            [40.34335403, -10.41197452],
                            [40.34317594, -10.41055764],
                            [40.34324346, -10.41034927],
                            [40.3436861, -10.40898333],
                            [40.34523347, -10.40820903],
                            [40.34522642, -10.40656376],
                            [40.3479433, -10.40300582],
                            [40.3481109, -10.40279901],
                            [40.34923855, -10.40140752],
                            [40.35021086, -10.39923486],
                            [40.35171299, -10.39735907],
                            [40.35480304, -10.39600002],
                            [40.35743872, -10.39434375],
                            [40.35879042, -10.39261813],
                            [40.36013887, -10.39014474],
                            [40.36111042, -10.38782251],
                            [40.36132626, -10.38535392],
                            [40.36214581, -10.382808],
                            [40.36440285, -10.38092896],
                            [40.36620976, -10.37972483],
                            [40.36773533, -10.37809894],
                            [40.36814077, -10.37708242],
                            [40.36816871, -10.37407851],
                            [40.36980629, -10.3733534],
                            [40.37305125, -10.37296565],
                            [40.37375453, -10.37286307],
                            [40.37300646, -10.37225183],
                            [40.37170948, -10.37063999],
                            [40.3704392, -10.3689537],
                            [40.36909267, -10.36740293],
                            [40.36655216, -10.36597317],
                            [40.36439301, -10.36578915],
                            [40.36211956, -10.36441476],
                            [40.36160838, -10.36233076],
                            [40.35993755, -10.35994159],
                            [40.3587245, -10.36196359],
                            [40.35894949, -10.36425736],
                            [40.35912504, -10.36648438],
                            [40.35828962, -10.36849301],
                            [40.35619541, -10.36790076],
                            [40.35400201, -10.36740383],
                            [40.35238447, -10.36995131],
                            [40.34855855, -10.36981685],
                            [40.34691445, -10.36865705],
                            [40.34435101, -10.36726073],
                            [40.34138706, -10.36731035],
                            [40.34084151, -10.36533892],
                            [40.34300067, -10.36349244],
                            [40.34468289, -10.36234315],
                            [40.34642236, -10.36119861],
                            [40.34810077, -10.36000357],
                            [40.3496038, -10.35867501],
                            [40.35053442, -10.35594726],
                            [40.34872646, -10.35486472],
                            [40.34480884, -10.35574885],
                            [40.34250857, -10.35773747],
                            [40.34104759, -10.35915091],
                            [40.33823233, -10.35977652],
                            [40.33559644, -10.35841076],
                            [40.33432622, -10.35671973],
                            [40.33171702, -10.35557424],
                            [40.32948531, -10.35571443],
                            [40.32592638, -10.35470533],
                            [40.32417161, -10.35247737],
                            [40.32255427, -10.34999282],
                            [40.32154336, -10.34822841],
                            [40.32058588, -10.34640092],
                            [40.3196819, -10.34453062],
                            [40.31881974, -10.34263932],
                            [40.31800713, -10.34074608],
                            [40.31722903, -10.33887097],
                            [40.31625249, -10.33606589],
                            [40.31494796, -10.33368729],
                            [40.31543621, -10.33110824],
                            [40.3151654, -10.32910249],
                            [40.31455886, -10.32615916],
                            [40.31355189, -10.32334752],
                            [40.31274691, -10.32151341],
                            [40.31187718, -10.31969936],
                            [40.31096564, -10.31789676],
                            [40.3100501, -10.31609796],
                            [40.30915743, -10.31429436],
                            [40.30831433, -10.3124775],
                            [40.30721198, -10.30970968],
                            [40.30650625, -10.30681497],
                            [40.30646425, -10.30472146],
                            [40.30657876, -10.30258414],
                            [40.30664751, -10.30049826],
                            [40.30622397, -10.29767124],
                            [40.3055727, -10.2968538],
                            [40.3040423, -10.29708753],
                            [40.29947168, -10.29836922],
                            [40.29737222, -10.29860059],
                            [40.29535392, -10.29881209],
                            [40.29535594, -10.29878504],
                            [40.29154527, -10.29884087],
                            [40.28953692, -10.29872711],
                            [40.29038588, -10.2958822],
                            [40.29208656, -10.29088246],
                            [40.29382223, -10.28442122],
                            [40.29451998, -10.28372405],
                            [40.29517138, -10.28270088],
                            [40.29407061, -10.28196851],
                            [40.29356712, -10.27997601],
                            [40.29319707, -10.27783487],
                            [40.29286909, -10.27564217],
                            [40.29249141, -10.27349622],
                            [40.29162543, -10.27057769],
                            [40.29011872, -10.26831436],
                            [40.28791008, -10.26677783],
                            [40.28535425, -10.26560759],
                            [40.28253906, -10.26470913],
                            [40.28055547, -10.26421795],
                            [40.27851457, -10.26378016],
                            [40.27643949, -10.26337099],
                            [40.27435284, -10.2629647],
                            [40.27227776, -10.26253265],
                            [40.27023686, -10.26205104],
                            [40.26825333, -10.26149111],
                            [40.26543052, -10.26045625],
                            [40.26359574, -10.25964842],
                            [40.26179134, -10.2587624],
                            [40.26002137, -10.25780574],
                            [40.25828572, -10.25678902],
                            [40.25658819, -10.25571892],
                            [40.25414687, -10.25400975],
                            [40.25194579, -10.25198306],
                            [40.25056107, -10.25051804],
                            [40.24918779, -10.2490454],
                            [40.247765, -10.24762908],
                            [40.24626579, -10.24626326],
                            [40.24473626, -10.24491176],
                            [40.24323698, -10.24354025],
                            [40.24116571, -10.24137236],
                            [40.23947962, -10.23896694],
                            [40.23809115, -10.23632888],
                            [40.2372443, -10.23448809],
                            [40.23646988, -10.23259772],
                            [40.23577565, -10.23067204],
                            [40.23516917, -10.22872455],
                            [40.23466181, -10.2267693],
                            [40.23409345, -10.22384984],
                            [40.23398658, -10.22094563],
                            [40.23436427, -10.21897614],
                            [40.23492889, -10.21699321],
                            [40.23552007, -10.21501227],
                            [40.23610007, -10.2120785],
                            [40.23467329, -10.20955289],
                            [40.23260576, -10.20898352],
                            [40.22992787, -10.20996973],
                            [40.22763529, -10.21185059],
                            [40.22617044, -10.21327353],
                            [40.2247629, -10.21478335],
                            [40.22342786, -10.21633512],
                            [40.22164251, -10.21873377],
                            [40.22071933, -10.22052112],
                            [40.21987253, -10.2223686],
                            [40.21894948, -10.22415977],
                            [40.21714507, -10.22651654],
                            [40.21508511, -10.22867199],
                            [40.2129337, -10.23075693],
                            [40.21146123, -10.23211986],
                            [40.20997744, -10.23346848],
                            [40.20848196, -10.23481035],
                            [40.20698286, -10.23615329],
                            [40.20549514, -10.23750285],
                            [40.20402647, -10.23886672],
                            [40.20258075, -10.24025066],
                            [40.20047891, -10.24238229],
                            [40.1986785, -10.24476294],
                            [40.19853025, -10.24501166],
                            [40.2010665, -10.24500231],
                            [40.20790895, -10.24477753],
                            [40.21897956, -10.24493562],
                            [40.22630466, -10.24525076],
                            [40.22628407, -10.2452525],
                            [40.22659058, -10.24644786],
                            [40.22727763, -10.24846439],
                            [40.22811421, -10.25010643],
                            [40.22918098, -10.25271977],
                            [40.2313733, -10.25360885],
                            [40.23296412, -10.25509846],
                            [40.23492945, -10.25583881],
                            [40.23727044, -10.25612902],
                            [40.23908337, -10.25649602],
                            [40.24202789, -10.25670911],
                            [40.24414245, -10.25700014],
                            [40.24686259, -10.25773752],
                            [40.24867583, -10.25817924],
                            [40.24792808, -10.25997687],
                            [40.2477821, -10.26124872],
                            [40.24748692, -10.26296983],
                            [40.24605749, -10.26417183],
                            [40.24387617, -10.26612453],
                            [40.24418744, -10.26851635],
                            [40.24600689, -10.27052846],
                            [40.24571229, -10.27239914],
                            [40.24261957, -10.27293448],
                            [40.23967111, -10.27174926],
                            [40.23521274, -10.27049499],
                            [40.23332077, -10.2692309],
                            [40.22893937, -10.26835015],
                            [40.22312241, -10.26710084],
                            [40.21761056, -10.26667289],
                            [40.21611065, -10.26922112],
                            [40.21543873, -10.27116798],
                            [40.21453751, -10.27236788],
                            [40.21422898, -10.27248574],
                            [40.21515686, -10.27513711],
                            [40.21651258, -10.27717105],
                            [40.21546768, -10.27753612],
                            [40.215014, -10.27533223],
                            [40.21386041, -10.27377323],
                            [40.21324184, -10.27339255],
                            [40.21257696, -10.2731168],
                            [40.21158066, -10.27296876],
                            [40.21099239, -10.27269272],
                            [40.21030179, -10.27236647],
                            [40.20971399, -10.2722169],
                            [40.20942602, -10.27212581],
                            [40.20922093, -10.2720092],
                            [40.2090805, -10.27190301],
                            [40.20875656, -10.2716908],
                            [40.2084972, -10.27146767],
                            [40.20832369, -10.27115886],
                            [40.20821517, -10.27094584],
                            [40.20812828, -10.27075408],
                            [40.20793327, -10.27045602],
                            [40.20757612, -10.27000916],
                            [40.20716569, -10.2697119],
                            [40.20676656, -10.26955332],
                            [40.20641047, -10.26938391],
                            [40.20605435, -10.26920382],
                            [40.20576284, -10.2690235],
                            [40.20545972, -10.26861913],
                            [40.20504861, -10.26814045],
                            [40.2045615, -10.267502],
                            [40.20413921, -10.26691666],
                            [40.2038152, -10.26668309],
                            [40.20343794, -10.2666098],
                            [40.2030829, -10.26671783],
                            [40.20283554, -10.26681478],
                            [40.20252347, -10.26689064],
                            [40.20225415, -10.26688097],
                            [40.20189896, -10.26694631],
                            [40.2014041, -10.26710821],
                            [40.20111321, -10.26708794],
                            [40.20081122, -10.26698235],
                            [40.20047728, -10.26697292],
                            [40.20016537, -10.26709145],
                            [40.1998209, -10.26714608],
                            [40.19947624, -10.26714736],
                            [40.19925993, -10.26691339],
                            [40.1989569, -10.26653035],
                            [40.19877255, -10.26620023],
                            [40.19862015, -10.26577395],
                            [40.19864166, -10.26544544],
                            [40.19860842, -10.26520012],
                            [40.19840329, -10.26507283],
                            [40.19792861, -10.26487183],
                            [40.19734587, -10.26457519],
                            [40.19713005, -10.26446927],
                            [40.19673085, -10.26428934],
                            [40.19633149, -10.26406672],
                            [40.19588912, -10.2638656],
                            [40.19557625, -10.26372803],
                            [40.19542534, -10.26369657],
                            [40.19528568, -10.26379312],
                            [40.19512464, -10.26393244],
                            [40.19507003, -10.26395975],
                            [40.19490962, -10.26403994],
                            [40.19468396, -10.2641795],
                            [40.19437236, -10.2643834],
                            [40.19406057, -10.26453394],
                            [40.19403136, -10.2645313],
                            [40.1938343, -10.26451343],
                            [40.19358638, -10.26446099],
                            [40.1933276, -10.26438724],
                            [40.19307951, -10.26429212],
                            [40.192853, -10.26420758],
                            [40.19260503, -10.26414447],
                            [40.19236776, -10.26405997],
                            [40.19215198, -10.26396472],
                            [40.19193597, -10.26380545],
                            [40.19174149, -10.26364609],
                            [40.19159534, -10.26339147],
                            [40.19138365, -10.26310768],
                            [40.19103728, -10.26265365],
                            [40.19055744, -10.26235188],
                            [40.19013569, -10.26222063],
                            [40.1897338, -10.26227902],
                            [40.18935162, -10.2624891],
                            [40.18868108, -10.2623967],
                            [40.188067, -10.2620385],
                            [40.18786134, -10.26188068],
                            [40.18727967, -10.26143431],
                            [40.18653014, -10.26069719],
                            [40.18618855, -10.26020027],
                            [40.18604883, -10.25999702],
                            [40.18568241, -10.25929643],
                            [40.18537343, -10.25859564],
                            [40.18490965, -10.25745907],
                            [40.1846175, -10.25613217],
                            [40.1846522, -10.25516452],
                            [40.1848607, -10.25459463],
                            [40.18514552, -10.25394858],
                            [40.18556592, -10.25371939],
                            [40.18612028, -10.25347074],
                            [40.18653969, -10.25297597],
                            [40.1870554, -10.2526326],
                            [40.18749452, -10.25228952],
                            [40.18783804, -10.25198473],
                            [40.18818177, -10.25173685],
                            [40.18852536, -10.25145103],
                            [40.18852409, -10.25110955],
                            [40.18817873, -10.25092111],
                            [40.18790947, -10.25059959],
                            [40.1874493, -10.25043053],
                            [40.18679801, -10.25035703],
                            [40.18612757, -10.25028359],
                            [40.18549571, -10.2502859],
                            [40.18469131, -10.25023192],
                            [40.18367594, -10.25008385],
                            [40.18271871, -10.25012528],
                            [40.18189517, -10.25007137],
                            [40.18099524, -10.25007464],
                            [40.17996185, -10.25023016],
                            [40.17912, -10.25040396],
                            [40.17835452, -10.25052057],
                            [40.17793433, -10.25080665],
                            [40.17749499, -10.25109281],
                            [40.17705579, -10.25141691],
                            [40.17690416, -10.25183483],
                            [40.17661911, -10.25242396],
                            [40.17625601, -10.25261499],
                            [40.17589256, -10.25271116],
                            [40.17554839, -10.2528452],
                            [40.17510856, -10.25299856],
                            [40.17447634, -10.25290599],
                            [40.17405377, -10.25254707],
                            [40.17366949, -10.25218801],
                            [40.17321023, -10.25226555],
                            [40.17305803, -10.2525317],
                            [40.17277928, -10.25284081],
                            [40.17237151, -10.25329302],
                            [40.17177884, -10.25354178],
                            [40.17126276, -10.25379027],
                            [40.17063186, -10.25405814],
                            [40.16984723, -10.25417479],
                            [40.16910068, -10.2542344],
                            [40.16844952, -10.2541988],
                            [40.1679709, -10.25421949],
                            [40.16720555, -10.25437401],
                            [40.16647939, -10.25477501],
                            [40.16582927, -10.25502397],
                            [40.16516, -10.255273],
                            [40.16443301, -10.25544635],
                            [40.16376374, -10.25569537],
                            [40.16315177, -10.25590625],
                            [40.16244393, -10.25607952],
                            [40.16179339, -10.25621465],
                            [40.16129623, -10.25640614],
                            [40.16077993, -10.2565977],
                            [40.16018697, -10.25677056],
                            [40.15947898, -10.25690589],
                            [40.158713, -10.25688965],
                            [40.15829223, -10.25702395],
                            [40.15804455, -10.25736631],
                            [40.15752852, -10.25763375],
                            [40.15703067, -10.25763553],
                            [40.15641787, -10.25761874],
                            [40.15639996, -10.25796028],
                            [40.15594178, -10.25834134],
                            [40.15534868, -10.25847625],
                            [40.15490841, -10.25851576],
                            [40.15460246, -10.25863067],
                            [40.15454061, -10.25892221],
                            [40.15447716, -10.25905811],
                            [40.1544367, -10.25927794],
                            [40.15443772, -10.25955894],
                            [40.1544909, -10.26000325],
                            [40.15457108, -10.26078467],
                            [40.1545466, -10.2611424],
                            [40.15459974, -10.26157649],
                            [40.15474123, -10.2621993],
                            [40.15484038, -10.26252082],
                            [40.15483628, -10.26281206],
                            [40.15476517, -10.26314737],
                            [40.15468835, -10.26323499],
                            [40.15461368, -10.26332015],
                            [40.15435353, -10.26349332],
                            [40.15396323, -10.26373154],
                            [40.15348593, -10.26394853],
                            [40.15289958, -10.26405827],
                            [40.15231316, -10.26414647],
                            [40.15200931, -10.2642552],
                            [40.15177089, -10.26442829],
                            [40.15162064, -10.26494554],
                            [40.15179543, -10.26520328],
                            [40.15168771, -10.26546202],
                            [40.15147148, -10.26576421],
                            [40.15129834, -10.26595859],
                            [40.15114739, -10.26628208],
                            [40.15125729, -10.26662617],
                            [40.15143199, -10.26686238],
                            [40.15136766, -10.26709943],
                            [40.15104247, -10.26731589],
                            [40.15047901, -10.26774849],
                            [40.15017679, -10.26830934],
                            [40.14987535, -10.26908549],
                            [40.1493781, -10.26979774],
                            [40.14881619, -10.27066093],
                            [40.14873097, -10.27113489],
                            [40.14890622, -10.27152181],
                            [40.14938715, -10.27164458],
                            [40.1498278, -10.27180651],
                            [40.15045218, -10.2720677],
                            [40.15100357, -10.27242906],
                            [40.15121533, -10.27267355],
                            [40.15128907, -10.27278228],
                            [40.15126219, -10.27295495],
                            [40.15118056, -10.27320048],
                            [40.15106236, -10.27347339],
                            [40.15078916, -10.273983],
                            [40.15065315, -10.2744013],
                            [40.15070014, -10.27471904],
                            [40.15092087, -10.274909],
                            [40.15158126, -10.2749884],
                            [40.15190172, -10.27486918],
                            [40.15201065, -10.27456906],
                            [40.15211948, -10.27424168],
                            [40.15232003, -10.27392307],
                            [40.15263086, -10.27367672],
                            [40.15283183, -10.27347618],
                            [40.15295926, -10.27322141],
                            [40.15312366, -10.27304825],
                            [40.15343495, -10.27292906],
                            [40.15375543, -10.27281892],
                            [40.15390215, -10.27282749],
                            [40.15422363, -10.27298983],
                            [40.15445316, -10.27307985],
                            [40.15461861, -10.27319733],
                            [40.15495892, -10.27349586],
                            [40.15542797, -10.27390292],
                            [40.15547971, -10.27397778],
                            [40.15591645, -10.27460965],
                            [40.15635992, -10.27554361],
                            [40.15669271, -10.2762963],
                            [40.15676775, -10.27683149],
                            [40.15668149, -10.27678802],
                            [40.15522687, -10.27620862],
                            [40.1536369, -10.27585454],
                            [40.15186619, -10.27572593],
                            [40.1503683, -10.27573125],
                            [40.14987015, -10.2760478],
                            [40.14951011, -10.27690348],
                            [40.14951271, -10.27762297],
                            [40.14969818, -10.27870156],
                            [40.15001998, -10.27982464],
                            [40.15043354, -10.2812172],
                            [40.15084662, -10.28247486],
                            [40.15121448, -10.28377764],
                            [40.15108254, -10.2849473],
                            [40.1504916, -10.28624017],
                            [40.14953608, -10.28474802],
                            [40.14902884, -10.2833719],
                            [40.14836429, -10.28123083],
                            [40.14762097, -10.278669],
                            [40.14695631, -10.27648965],
                            [40.14668062, -10.27503616],
                            [40.14617079, -10.2729328],
                            [40.14545829, -10.26818915],
                            [40.14498258, -10.26482258],
                            [40.1436971, -10.26187989],
                            [40.14171694, -10.25913102],
                            [40.14100947, -10.25849061],
                            [40.13988237, -10.25747035],
                            [40.1374713, -10.25543744],
                            [40.13409681, -10.2528635],
                            [40.13210004, -10.25144151],
                            [40.13140293, -10.2513839],
                            [40.13051995, -10.25131092],
                            [40.1294048, -10.25154737],
                            [40.12812269, -10.25266675],
                            [40.12833668, -10.25120061],
                            [40.12790182, -10.24919105],
                            [40.12777651, -10.24840476],
                            [40.12590568, -10.24667918],
                            [40.12583654, -10.24256573],
                            [40.12609708, -10.23899241],
                            [40.12515599, -10.23547738],
                            [40.12509362, -10.23302794],
                            [40.12509259, -10.23298774],
                            [40.12530537, -10.23246243],
                            [40.12513241, -10.23135282],
                            [40.1248273, -10.22937183],
                            [40.12471172, -10.22859237],
                            [40.12373834, -10.22847912],
                            [40.12322392, -10.22718183],
                            [40.12336604, -10.22617096],
                            [40.1227047, -10.22451333],
                            [40.12175151, -10.22271236],
                            [40.12261894, -10.22083295],
                            [40.12283237, -10.21938881],
                            [40.12209619, -10.21715408],
                            [40.12084867, -10.21448807],
                            [40.12062056, -10.21174639],
                            [40.11991004, -10.20961285],
                            [40.12104846, -10.20914679],
                            [40.12125735, -10.2064036],
                            [40.12044813, -10.20409694],
                            [40.12044031, -10.20185969],
                            [40.12239888, -10.19961568],
                            [40.12486494, -10.19672038],
                            [40.12900804, -10.19439664],
                            [40.13081881, -10.19157574],
                            [40.13233544, -10.18796198],
                            [40.1329812, -10.18521729],
                            [40.13241936, -10.18311841],
                            [40.13180435, -10.18203564],
                            [40.13066757, -10.18035892],
                            [40.12941641, -10.17873939],
                            [40.12805844, -10.17721819],
                            [40.12581536, -10.17521337],
                            [40.1233741, -10.17354721],
                            [40.12081821, -10.17198868],
                            [40.11905975, -10.17100634],
                            [40.11727066, -10.17007256],
                            [40.11544726, -10.16919128],
                            [40.11360098, -10.16836533],
                            [40.11173183, -10.16759856],
                            [40.10984743, -10.16689368],
                            [40.10700174, -10.1659599],
                            [40.10412548, -10.16514444],
                            [40.10121872, -10.16441387],
                            [40.09926564, -10.16396657],
                            [40.09730111, -10.1635459],
                            [40.09533277, -10.1631463],
                            [40.093353, -10.16276282],
                            [40.09137316, -10.16239184],
                            [40.08938958, -10.16202849],
                            [40.08740599, -10.16166695],
                            [40.08542615, -10.1613036],
                            [40.08344644, -10.16093263],
                            [40.08147417, -10.16055008],
                            [40.07950971, -10.16015048],
                            [40.07658389, -10.15951048],
                            [40.07368101, -10.15880665],
                            [40.07081619, -10.15802264],
                            [40.06799718, -10.15712705],
                            [40.06550623, -10.15552083],
                            [40.06399945, -10.15410932],
                            [40.06251173, -10.15265481],
                            [40.06095539, -10.15135293],
                            [40.05831557, -10.15007587],
                            [40.05547381, -10.14923653],
                            [40.05257087, -10.14854502],
                            [40.05061015, -10.14814729],
                            [40.04863038, -10.14778394],
                            [40.04664292, -10.14744441],
                            [40.04464777, -10.14711531],
                            [40.04264906, -10.14678527],
                            [40.04065778, -10.14644192],
                            [40.0386742, -10.14607288],
                            [40.0367058, -10.14566559],
                            [40.03379148, -10.14495696],
                            [40.03093429, -10.14409374],
                            [40.02814578, -10.1430532],
                            [40.0254069, -10.14186859],
                            [40.02271382, -10.14056771],
                            [40.02093623, -10.13964924],
                            [40.01917002, -10.13870021],
                            [40.01741137, -10.13772831],
                            [40.01565672, -10.13674215],
                            [40.01390581, -10.13574924],
                            [40.01215491, -10.13475827],
                            [40.0104002, -10.1337778],
                            [40.0086378, -10.13281545],
                            [40.00686777, -10.1318798],
                            [40.00509406, -10.1309499],
                            [40.00245436, -10.12952401],
                            [39.99978798, -10.12814775],
                            [39.99705665, -10.1269145],
                            [39.99520275, -10.12616199],
                            [39.99334504, -10.12541998],
                            [39.99084265, -10.12497262],
                            [39.98873694, -10.12480954],
                            [39.98605524, -10.12567842],
                            [39.98369409, -10.12744764],
                            [39.98216818, -10.12878576],
                            [39.98069952, -10.13022308],
                            [39.97930723, -10.13173478],
                            [39.97800262, -10.13329705],
                            [39.97625552, -10.13570814],
                            [39.9752561, -10.13748593],
                            [39.97440542, -10.1393801],
                            [39.97372643, -10.14132297],
                            [39.97306656, -10.1441635],
                            [39.97398571, -10.14581192],
                            [39.98343613, -10.15401545],
                            [39.98906835, -10.15890456],
                            [39.98973367, -10.16061796],
                            [39.99056788, -10.16159045],
                            [39.99160548, -10.16332249],
                            [39.99242946, -10.16522048],
                            [39.99305875, -10.1671976],
                            [39.99349368, -10.16916522],
                            [39.99341729, -10.17202743],
                            [39.99247518, -10.17391591],
                            [39.99129264, -10.17558688],
                            [39.98960654, -10.17700038],
                            [39.98860416, -10.17917318],
                            [39.98911255, -10.18034929],
                            [39.98954934, -10.18112445],
                            [39.99055638, -10.1830225],
                            [39.99044956, -10.18594671],
                            [39.99011007, -10.18792289],
                            [39.98967138, -10.18991051],
                            [39.98919457, -10.19190295],
                            [39.98874058, -10.19389344],
                            [39.98837058, -10.19587344],
                            [39.98810351, -10.19880721],
                            [39.9884064, -10.20002845],
                            [39.98852612, -10.20030546],
                            [39.98980812, -10.20327197],
                            [39.99514467, -10.20319283],
                            [39.99851932, -10.206485],
                            [39.99850048, -10.20664803],
                            [39.99824229, -10.20668551],
                            [39.9990476, -10.2075046],
                            [39.99703732, -10.2084355],
                            [39.99477536, -10.2088971],
                            [39.99279933, -10.20952465],
                            [39.99084243, -10.21002063],
                            [39.98797005, -10.20984986],
                            [39.98607415, -10.20895046],
                            [39.98452925, -10.20764095],
                            [39.98354502, -10.20585266],
                            [39.98332839, -10.20518838],
                            [39.98114497, -10.20090213],
                            [39.97913149, -10.20020156],
                            [39.97709072, -10.20036751],
                            [39.97491644, -10.20075098],
                            [39.97278773, -10.20104756],
                            [39.97001079, -10.20037512],
                            [39.9694645, -10.19889906],
                            [39.96369292, -10.19779369],
                            [39.96352104, -10.19781518],
                            [39.96385736, -10.19727176],
                            [39.96441476, -10.19603726],
                            [39.96513142, -10.19404613],
                            [39.96604715, -10.19157713],
                            [39.96664437, -10.189586],
                            [39.96704251, -10.18803292],
                            [39.96724159, -10.18731611],
                            [39.96795824, -10.18687807],
                            [39.9687147, -10.18683825],
                            [39.9698693, -10.18683825],
                            [39.97098408, -10.18675861],
                            [39.97170073, -10.18628075],
                            [39.97213869, -10.18536483],
                            [39.9727359, -10.18424979],
                            [39.97297479, -10.18325423],
                            [39.97301461, -10.18221884],
                            [39.97317387, -10.18134274],
                            [39.9735322, -10.18054629],
                            [39.97397015, -10.1800286],
                            [39.9746868, -10.17959055],
                            [39.97564234, -10.17911268],
                            [39.97584141, -10.17855517],
                            [39.97568216, -10.17775871],
                            [39.97592105, -10.17688261],
                            [39.97639881, -10.17624545],
                            [39.976359, -10.17568794],
                            [39.97596087, -10.17513042],
                            [39.97588125, -10.17425432],
                            [39.97604051, -10.17365698],
                            [39.97671734, -10.17329858],
                            [39.97783213, -10.17301982],
                            [39.97870803, -10.17250213],
                            [39.97902655, -10.17186497],
                            [39.97878767, -10.17090923],
                            [39.97807103, -10.16991366],
                            [39.97703587, -10.16927649],
                            [39.97643867, -10.16844021],
                            [39.9765183, -10.16788269],
                            [39.97695626, -10.16752429],
                            [39.97755347, -10.16688713],
                            [39.97711552, -10.16640925],
                            [39.97631925, -10.16569244],
                            [39.97631925, -10.16477652],
                            [39.97663776, -10.16413936],
                            [39.97687665, -10.1635022],
                            [39.97639889, -10.16314379],
                            [39.97608038, -10.16242698],
                            [39.97627945, -10.16171017],
                            [39.97671741, -10.16115266],
                            [39.97683685, -10.16059514],
                            [39.9766776, -10.16019691],
                            [39.97661912, -10.16003311],
                            [39.97647854, -10.15963939],
                            [39.97663779, -10.15900223],
                            [39.9766378, -10.15840489],
                            [39.97619985, -10.1582456],
                            [39.9752045, -10.15836506],
                            [39.97440823, -10.1580863],
                            [39.97416935, -10.15744913],
                            [39.97381103, -10.15653321],
                            [39.97325364, -10.1558164],
                            [39.972537, -10.15509958],
                            [39.97126296, -10.15462171],
                            [39.97054631, -10.15450223],
                            [39.97022781, -10.1537456],
                            [39.96993201, -10.15321981],
                            [39.96939452, -10.15279981],
                            [39.96895782, -10.1523966],
                            [39.96848752, -10.15167419],
                            [39.9684614, -10.15135312],
                            [39.96831023, -10.15110111],
                            [39.9680079, -10.15091631],
                            [39.96747041, -10.15081551],
                            [39.96689933, -10.1507651],
                            [39.96616029, -10.1507651],
                            [39.96560601, -10.1508155],
                            [39.96485017, -10.15084909],
                            [39.96434627, -10.15074829],
                            [39.9637584, -10.15044588],
                            [39.9632881, -10.15004267],
                            [39.96290179, -10.14955547],
                            [39.96270023, -10.14911866],
                            [39.96246509, -10.14873225],
                            [39.96226353, -10.14821145],
                            [39.96180267, -10.14724574],
                            [39.96117561, -10.14590173],
                            [39.9609666, -10.14461744],
                            [39.96099647, -10.14318383],
                            [39.96106629, -10.14282687],
                            [39.96126522, -10.14180994],
                            [39.96192215, -10.14028673],
                            [39.96350476, -10.13840512],
                            [39.96454988, -10.13664297],
                            [39.96640123, -10.13479122],
                            [39.9676572, -10.13324597],
                            [39.96913032, -10.13125484],
                            [39.97167841, -10.12830798],
                            [39.97339042, -10.12508235],
                            [39.97385196, -10.12392291],
                            [39.97510244, -10.12078151],
                            [39.97601817, -10.11723729],
                            [39.97673484, -10.11365326],
                            [39.97717281, -10.11138337],
                            [39.97753114, -10.1091533],
                            [39.9776506, -10.10684358],
                            [39.9776108, -10.10397635],
                            [39.9775312, -10.09971532],
                            [39.97761083, -10.09808259],
                            [39.97757103, -10.09629057],
                            [39.9774516, -10.09374191],
                            [39.97701366, -10.09250741],
                            [39.9765359, -10.09067556],
                            [39.9762174, -10.08884372],
                            [39.97593871, -10.08737028],
                            [39.97482394, -10.08541896],
                            [39.9729527, -10.08318888],
                            [39.97124073, -10.08083933],
                            [39.96849359, -10.07844995],
                            [39.96582608, -10.07621987],
                            [39.96483074, -10.07574199],
                            [39.963278, -10.07625968],
                            [39.96160582, -10.07721541],
                            [39.96065028, -10.07797204],
                            [39.95893829, -10.07825079],
                            [39.95702723, -10.07833042],
                            [39.9549171, -10.07892775],
                            [39.95304585, -10.07944543],
                            [39.95002, -10.07988347],
                            [39.9477108, -10.08012239],
                            [39.94556085, -10.08032149],
                            [39.94388868, -10.08016219],
                            [39.94237575, -10.08028165],
                            [39.94169892, -10.08060023],
                            [39.94144385, -10.08098291],
                            [39.94167334, -10.08019789],
                            [39.94098324, -10.07934833],
                            [39.94045239, -10.07897665],
                            [39.9400808, -10.07860497],
                            [39.94029315, -10.07775542],
                            [39.94055858, -10.07674658],
                            [39.94055858, -10.07563155],
                            [39.94039933, -10.07515367],
                            [39.93954997, -10.07483509],
                            [39.93927249, -10.07490039],
                            [39.93825769, -10.07462601],
                            [39.9375709, -10.07510388],
                            [39.93637648, -10.07531294],
                            [39.93589872, -10.07516361],
                            [39.93562998, -10.07459613],
                            [39.9356897, -10.07414813],
                            [39.93589873, -10.07352092],
                            [39.93557026, -10.07316251],
                            [39.93473418, -10.07316251],
                            [39.93398767, -10.07328197],
                            [39.9335099, -10.07322224],
                            [39.93312172, -10.07277423],
                            [39.933062, -10.07235609],
                            [39.93306201, -10.07157955],
                            [39.93279327, -10.07047447],
                            [39.9327037, -10.06933952],
                            [39.93249468, -10.06844351],
                            [39.93198706, -10.0679059],
                            [39.93115097, -10.06772669],
                            [39.9303663, -10.06772005],
                            [39.92951694, -10.06819792],
                            [39.92941076, -10.06947224],
                            [39.92983544, -10.07111825],
                            [39.93010085, -10.07228638],
                            [39.92967617, -10.07265806],
                            [39.92877373, -10.07239257],
                            [39.92787129, -10.07154302],
                            [39.92734044, -10.07064036],
                            [39.92686268, -10.06989701],
                            [39.92622566, -10.06941913],
                            [39.92596024, -10.06841029],
                            [39.92569482, -10.06766693],
                            [39.92505781, -10.06708286],
                            [39.92442079, -10.06591472],
                            [39.92404908, -10.06528074],
                            [39.92378366, -10.06480286],
                            [39.92309356, -10.06384712],
                            [39.92250963, -10.06310376],
                            [39.92197878, -10.06220111],
                            [39.92139486, -10.06113916],
                            [39.92102327, -10.05991793],
                            [39.92065168, -10.05922767],
                            [39.92038625, -10.05859051],
                            [39.9205986, -10.05800644],
                            [39.92118254, -10.05726309],
                            [39.92118254, -10.05636044],
                            [39.92102329, -10.05588257],
                            [39.92033319, -10.0552985],
                            [39.91943075, -10.05460823],
                            [39.91863448, -10.05354629],
                            [39.91805054, -10.05306841],
                            [39.91783821, -10.05243125],
                            [39.91781757, -10.05121592],
                            [39.91665233, -10.05002549],
                            [39.91601531, -10.04917594],
                            [39.91551986, -10.04846798],
                            [39.91488284, -10.04768922],
                            [39.91445817, -10.04662728],
                            [39.91367959, -10.0462025],
                            [39.91332569, -10.04528215],
                            [39.91283024, -10.0443618],
                            [39.91259038, -10.04247915],
                            [39.91211853, -10.03926974],
                            [39.91126919, -10.03568274],
                            [39.91032548, -10.0311518],
                            [39.90919302, -10.02803677],
                            [39.90702247, -10.0221843],
                            [39.90532376, -10.02039079],
                            [39.90107698, -10.01567104],
                            [39.89777393, -10.01283919],
                            [39.8943765, -10.01019612],
                            [39.89258342, -10.00915778],
                            [39.89135657, -10.00840261],
                            [39.89088471, -10.00717548],
                            [39.88928037, -10.00547637],
                            [39.88559982, -10.00273891],
                            [39.88383061, -10.00131179],
                            [39.88267426, -10.00037903],
                            [39.87955995, -9.99867991],
                            [39.87915651, -9.99851436],
                            [39.8758794, -9.99716958],
                            [39.87182135, -9.9965088],
                            [39.86861265, -9.99650878],
                            [39.86540396, -9.99660316],
                            [39.863139, -9.99716951],
                            [39.86030779, -9.99849102],
                            [39.85766533, -9.99924616],
                            [39.85615536, -9.99943494],
                            [39.85313541, -10.00000129],
                            [39.85049295, -10.00075643],
                            [39.84643489, -10.00179475],
                            [39.84483054, -10.00179474],
                            [39.84332057, -10.00141716],
                            [39.84228246, -10.00141715],
                            [39.84011187, -10.00170032],
                            [39.83964001, -10.00056759],
                            [39.83888503, -9.99952924],
                            [39.83746944, -9.9983965],
                            [39.83605384, -9.99726376],
                            [39.83473262, -9.99698057],
                            [39.83350577, -9.99698056],
                            [39.83256203, -9.99698056],
                            [39.83043863, -9.99677078],
                            [39.83043864, -9.99513461],
                            [39.82993533, -9.99362429],
                            [39.82867703, -9.99161054],
                            [39.82792205, -9.98972264],
                            [39.8272929, -9.98796061],
                            [39.82729291, -9.98632443],
                            [39.82767041, -9.9851917],
                            [39.82817375, -9.98292624],
                            [39.82855125, -9.98091249],
                            [39.82842544, -9.97826944],
                            [39.82754463, -9.9765074],
                            [39.82641216, -9.97512295],
                            [39.82540551, -9.97361263],
                            [39.82439887, -9.97247989],
                            [39.82147925, -9.97014364],
                            [39.82125311, -9.96996268],
                            [39.81923982, -9.96870408],
                            [39.81659737, -9.96769719],
                            [39.81408075, -9.96656444],
                            [39.81246839, -9.96639467],
                            [39.81168996, -9.96631271],
                            [39.80980249, -9.96681614],
                            [39.80766336, -9.96769714],
                            [39.80668797, -9.96916053],
                            [39.80664142, -9.96892122],
                            [39.80658899, -9.96858404],
                            [39.80661099, -9.96816595],
                            [39.80659453, -9.96800128],
                            [39.80473144, -9.96760195],
                            [39.80228801, -9.96760193],
                            [39.79890483, -9.96553394],
                            [39.79383003, -9.96459392],
                            [39.78443225, -9.96515787],
                            [39.78029723, -9.96590984],
                            [39.77597424, -9.9668498],
                            [39.77277899, -9.96816577],
                            [39.77158001, -9.96869043],
                            [39.77146329, -9.96985774],
                            [39.77033554, -9.97418168],
                            [39.76802, -9.97447118],
                            [39.7658246, -9.97474565],
                            [39.76187754, -9.97436963],
                            [39.75830638, -9.97436961],
                            [39.75699069, -9.9749336],
                            [39.75605091, -9.97587358],
                            [39.75550436, -9.97707625],
                            [39.75511112, -9.97794155],
                            [39.7547352, -9.97982153],
                            [39.75266767, -9.98301748],
                            [39.74684104, -9.98470943],
                            [39.73988667, -9.98677736],
                            [39.73725529, -9.98846933],
                            [39.7333082, -9.99260526],
                            [39.73030089, -9.9963652],
                            [39.72635379, -10.0021931],
                            [39.72625192, -10.00236292],
                            [39.72466218, -10.00501306],
                            [39.72391033, -10.00896101],
                            [39.72391031, -10.01272096],
                            [39.72616575, -10.01798491],
                            [39.72560187, -10.02005288],
                            [39.72560175, -10.02005342],
                            [39.72517968, -10.02196695],
                            [39.72515386, -10.02208405],
                            [39.72490236, -10.02196407],
                            [39.72297047, -10.02400082],
                            [39.72015111, -10.02888874],
                            [39.71808359, -10.0317087],
                            [39.7145124, -10.03716061],
                            [39.70567846, -10.0422365],
                            [39.70079161, -10.04355246],
                            [39.6974084, -10.04599641],
                            [39.69477701, -10.04806437],
                            [39.69327336, -10.04994434],
                            [39.69026606, -10.05238829],
                            [39.68763467, -10.05408026],
                            [39.6870708, -10.05520824],
                            [39.68274781, -10.05765219],
                            [39.68086824, -10.05896816],
                            [39.67861276, -10.06141212],
                            [39.67804889, -10.06329209],
                            [39.67842479, -10.06460808],
                            [39.67974047, -10.06667606],
                            [39.68143206, -10.06780406],
                            [39.68049227, -10.06987203],
                            [39.67996287, -10.07146056],
                            [39.6797033, -10.07164722],
                            [39.67909736, -10.07285486],
                            [39.67891697, -10.07388066],
                            [39.67910216, -10.07514699],
                            [39.67965054, -10.07550778],
                            [39.68019935, -10.07607366],
                            [39.68032357, -10.07727979],
                            [39.68008322, -10.07872796],
                            [39.67966057, -10.08029714],
                            [39.68008866, -10.08132169],
                            [39.68100277, -10.08198332],
                            [39.68149159, -10.08294743],
                            [39.68161709, -10.08475675],
                            [39.68247189, -10.08614234],
                            [39.68369078, -10.0870446],
                            [39.68454496, -10.08812858],
                            [39.68667687, -10.08914958],
                            [39.68801773, -10.09017222],
                            [39.68856807, -10.09143778],
                            [39.68887566, -10.09300544],
                            [39.68973013, -10.09421005],
                            [39.69021899, -10.09517413],
                            [39.69034326, -10.09638026],
                            [39.69016379, -10.0978283],
                            [39.69024535, -10.09802447],
                            [39.69021944, -10.0980291],
                            [39.69017333, -10.09864318],
                            [39.69001399, -10.09871216],
                            [39.6888263, -10.09956069],
                            [39.6885276, -10.0997741],
                            [39.68799674, -10.10126081],
                            [39.68809702, -10.101662],
                            [39.68820237, -10.1020835],
                            [39.68830626, -10.10219686],
                            [39.68859521, -10.10253035],
                            [39.68881749, -10.10286384],
                            [39.68908422, -10.10330849],
                            [39.68926203, -10.10361975],
                            [39.6893954, -10.10386431],
                            [39.68959544, -10.10424226],
                            [39.68983994, -10.10473138],
                            [39.68991731, -10.10507406],
                            [39.68994543, -10.10506536],
                            [39.68996087, -10.10515459],
                            [39.68980219, -10.10534505],
                            [39.68916459, -10.10611033],
                            [39.68773128, -10.10855278],
                            [39.68544861, -10.11099523],
                            [39.68380297, -10.11280051],
                            [39.68321902, -10.11418103],
                            [39.68321901, -10.11683587],
                            [39.68337934, -10.11921892],
                            [39.68300775, -10.11985608],
                            [39.68226455, -10.12139588],
                            [39.68173369, -10.12309498],
                            [39.68114975, -10.12410382],
                            [39.67998187, -10.12484717],
                            [39.67923867, -10.12675866],
                            [39.67870781, -10.12861705],
                            [39.67785844, -10.13047543],
                            [39.67663748, -10.13148427],
                            [39.67462025, -10.13259929],
                            [39.67207216, -10.13371431],
                            [39.67010801, -10.1337674],
                            [39.66867472, -10.13302403],
                            [39.66755994, -10.13217448],
                            [39.66628982, -10.13067719],
                            [39.66620209, -10.13057378],
                            [39.66629165, -10.13062324],
                            [39.66630344, -10.1302761],
                            [39.66697013, -10.12894771],
                            [39.6675156, -10.12786085],
                            [39.66818291, -10.12683406],
                            [39.66897242, -10.1260483],
                            [39.66951876, -10.12538368],
                            [39.66988252, -10.12471942],
                            [39.67006296, -10.12369362],
                            [39.67012232, -10.12296966],
                            [39.66989867, -10.12256242],
                            [39.67003087, -10.12208307],
                            [39.669792, -10.12097799],
                            [39.66940382, -10.12011185],
                            [39.66910522, -10.11918597],
                            [39.66910421, -10.11917942],
                            [39.66962584, -10.11832607],
                            [39.67041446, -10.11711807],
                            [39.67113971, -10.11470381],
                            [39.67150145, -10.11307444],
                            [39.67192329, -10.11108303],
                            [39.67210159, -10.1090318],
                            [39.67209909, -10.10782542],
                            [39.67209645, -10.10655871],
                            [39.67196688, -10.10630315],
                            [39.67194202, -10.10610423],
                            [39.67167329, -10.10496928],
                            [39.67159057, -10.10453194],
                            [39.67146427, -10.1038642],
                            [39.67092679, -10.10329673],
                            [39.6699414, -10.10320712],
                            [39.66937405, -10.10344605],
                            [39.66918684, -10.10367492],
                            [39.66852993, -10.10261429],
                            [39.66846621, -10.10122706],
                            [39.66907211, -10.09995912],
                            [39.66979959, -10.09863061],
                            [39.66955332, -10.09724376],
                            [39.66900478, -10.09682263],
                            [39.66815287, -10.09682436],
                            [39.66724122, -10.09736909],
                            [39.66657222, -10.0975514],
                            [39.6654764, -10.09731234],
                            [39.6649886, -10.09683076],
                            [39.66480431, -10.09598666],
                            [39.66407212, -10.09502302],
                            [39.66315923, -10.09496454],
                            [39.66243013, -10.09550888],
                            [39.66163907, -10.09551047],
                            [39.66090763, -10.09490874],
                            [39.66048019, -10.09418576],
                            [39.66047835, -10.09328097],
                            [39.66053674, -10.09207445],
                            [39.66132484, -10.0906252],
                            [39.66186966, -10.08923675],
                            [39.66259714, -10.08790825],
                            [39.66314171, -10.08639917],
                            [39.66334537, -10.08556936],
                            [39.6639817, -10.08430136],
                            [39.66429884, -10.08316973],
                            [39.66484443, -10.08217336],
                            [39.66506955, -10.08067999],
                            [39.66524959, -10.07945815],
                            [39.66556617, -10.07805508],
                            [39.66556366, -10.07683361],
                            [39.6652879, -10.07588412],
                            [39.66501372, -10.07570372],
                            [39.66442046, -10.07570491],
                            [39.66378258, -10.07620383],
                            [39.66328216, -10.07697392],
                            [39.66287264, -10.07756286],
                            [39.66209766, -10.07797157],
                            [39.66155022, -10.07806315],
                            [39.6610295, -10.07754903],
                            [39.6608643, -10.07738592],
                            [39.66077082, -10.07630035],
                            [39.66090533, -10.07512385],
                            [39.66094903, -10.07417372],
                            [39.66099236, -10.07304264],
                            [39.66117287, -10.072047],
                            [39.66121768, -10.07163975],
                            [39.66030489, -10.07159634],
                            [39.65925529, -10.07159843],
                            [39.65811395, -10.07137451],
                            [39.6567439, -10.0708796],
                            [39.65569375, -10.07061024],
                            [39.65456332, -10.07019992],
                            [39.65632744, -10.06875381],
                            [39.65729751, -10.06703611],
                            [39.65815961, -10.06542587],
                            [39.65902214, -10.06403009],
                            [39.66042812, -10.06392005],
                            [39.66220399, -10.06194438],
                            [39.66226284, -10.0618789],
                            [39.66507215, -10.06037197],
                            [39.66506908, -10.05887068],
                            [39.66441657, -10.05715623],
                            [39.66268131, -10.05490777],
                            [39.6611648, -10.05383845],
                            [39.65889245, -10.05341404],
                            [39.65811666, -10.05258908],
                            [39.65748326, -10.05191554],
                            [39.6580217, -10.05073488],
                            [39.65863095, -10.05051387],
                            [39.65921065, -10.05030358],
                            [39.6610501, -10.05062161],
                            [39.66171409, -10.0507143],
                            [39.66332221, -10.05093877],
                            [39.66526962, -10.05114934],
                            [39.66754042, -10.05082306],
                            [39.66905163, -10.04931871],
                            [39.66934534, -10.0476488],
                            [39.66937238, -10.04749507],
                            [39.66861105, -10.04545913],
                            [39.66774398, -10.044603],
                            [39.66612155, -10.04460627],
                            [39.66471499, -10.04439462],
                            [39.66265969, -10.04429151],
                            [39.65995475, -10.04386796],
                            [39.65800739, -10.04365737],
                            [39.65638625, -10.044304],
                            [39.65476532, -10.04505785],
                            [39.65260228, -10.04516936],
                            [39.65087338, -10.04603065],
                            [39.64892473, -10.04517659],
                            [39.64951318, -10.04459087],
                            [39.64957243, -10.0445319],
                            [39.64989499, -10.04356615],
                            [39.65075943, -10.04313551],
                            [39.65053968, -10.04142017],
                            [39.65086459, -10.041634],
                            [39.65292096, -10.04227337],
                            [39.65421869, -10.04216357],
                            [39.65432384, -10.04066207],
                            [39.65399548, -10.03873247],
                            [39.6557254, -10.03840735],
                            [39.6573476, -10.0382969],
                            [39.65972691, -10.03818494],
                            [39.66189056, -10.03839509],
                            [39.66383767, -10.03849843],
                            [39.66621676, -10.03827919],
                            [39.66838019, -10.03838207],
                            [39.67140935, -10.03869765],
                            [39.67519497, -10.03868996],
                            [39.67789898, -10.03868444],
                            [39.68049482, -10.03867912],
                            [39.68319771, -10.03813738],
                            [39.68341133, -10.03685012],
                            [39.68286828, -10.03577889],
                            [39.68265984, -10.03516319],
                            [39.68254178, -10.03481445],
                            [39.68199874, -10.03374321],
                            [39.68015758, -10.03256741],
                            [39.67788492, -10.03192865],
                            [39.67604512, -10.03139623],
                            [39.67388085, -10.03086446],
                            [39.6715005, -10.03044035],
                            [39.66922698, -10.02937259],
                            [39.66706098, -10.02798289],
                            [39.66446018, -10.02552169],
                            [39.66196715, -10.02284578],
                            [39.65871945, -10.02135095],
                            [39.65774671, -10.02167458],
                            [39.65655829, -10.02232035],
                            [39.65525871, -10.02146503],
                            [39.65363552, -10.02103929],
                            [39.65168723, -10.02029247],
                            [39.65146857, -10.0191133],
                            [39.6525484, -10.0182533],
                            [39.65168252, -10.01793329],
                            [39.65137426, -10.01793389],
                            [39.65367992, -10.01603584],
                            [39.65327241, -10.01507152],
                            [39.65229871, -10.01491258],
                            [39.6512455, -10.01555806],
                            [39.64946129, -10.01572241],
                            [39.64873222, -10.0162064],
                            [39.64702912, -10.01637057],
                            [39.64645828, -10.01484357],
                            [39.6471872, -10.01427916],
                            [39.64872774, -10.01395445],
                            [39.65075432, -10.01330707],
                            [39.65196847, -10.01201786],
                            [39.65115541, -10.01105433],
                            [39.64985759, -10.01105687],
                            [39.64839754, -10.01105973],
                            [39.64725844, -10.00929255],
                            [39.64814893, -10.00840612],
                            [39.64960977, -10.0088054],
                            [39.65131234, -10.00839994],
                            [39.65261192, -10.00928208],
                            [39.65407341, -10.01000305],
                            [39.65358383, -10.00855633],
                            [39.65220218, -10.00719179],
                            [39.65300929, -10.00517953],
                            [39.6540644, -10.00549916],
                            [39.65463218, -10.00549805],
                            [39.65568358, -10.00396786],
                            [39.65689816, -10.00291992],
                            [39.6577151, -10.00581367],
                            [39.65804554, -10.00878881],
                            [39.6593453, -10.00975135],
                            [39.66064035, -10.00838153],
                            [39.66079851, -10.00637054],
                            [39.66063076, -10.00363636],
                            [39.66022017, -10.00114395],
                            [39.66167594, -9.99904996],
                            [39.6630558, -9.99952977],
                            [39.66330353, -10.0017008],
                            [39.66379265, -10.00290623],
                            [39.66355258, -10.00451524],
                            [39.66339411, -10.00636537],
                            [39.6648543, -10.00644288],
                            [39.66566608, -10.00676296],
                            [39.66566919, -10.00829106],
                            [39.66535162, -10.01166962],
                            [39.66535604, -10.01384113],
                            [39.6667404, -10.01649244],
                            [39.66925859, -10.01825676],
                            [39.66999177, -10.01978339],
                            [39.67137404, -10.02138912],
                            [39.67283596, -10.02227086],
                            [39.67510771, -10.02250752],
                            [39.67721757, -10.02290536],
                            [39.68192702, -10.02514765],
                            [39.6836293, -10.02458117],
                            [39.69102062, -10.02915015],
                            [39.69296761, -10.02922652],
                            [39.69605163, -10.02994389],
                            [39.69799828, -10.02985938],
                            [39.6990734, -10.02773847],
                            [39.69896156, -10.02602295],
                            [39.69906558, -10.02409251],
                            [39.70025206, -10.02258872],
                            [39.70219585, -10.02119056],
                            [39.70467945, -10.01936231],
                            [39.70564819, -10.01721555],
                            [39.70715954, -10.01592552],
                            [39.70725404, -10.01574762],
                            [39.70812896, -10.01410047],
                            [39.70837317, -10.01392817],
                            [39.70948711, -10.01314224],
                            [39.71072063, -10.01227194],
                            [39.71233915, -10.01055272],
                            [39.71326764, -10.00767749],
                            [39.71341339, -10.00722615],
                            [39.71435598, -10.00377251],
                            [39.71449715, -10.00299308],
                            [39.71459642, -10.00244496],
                            [39.71470671, -10.00193659],
                            [39.71489755, -10.00105697],
                            [39.71527037, -9.99970547],
                            [39.71538038, -9.99930667],
                            [39.71538697, -9.9992307],
                            [39.71541084, -9.99895573],
                            [39.71552657, -9.99762214],
                            [39.71555839, -9.99725542],
                            [39.71557626, -9.99684619],
                            [39.71561632, -9.99592827],
                            [39.71560202, -9.99497117],
                            [39.71559075, -9.99421716],
                            [39.71563169, -9.99393087],
                            [39.71583341, -9.99252015],
                            [39.71579815, -9.99204521],
                            [39.71579726, -9.99163806],
                            [39.71480818, -9.99064872],
                            [39.71339266, -9.98945068],
                            [39.71320759, -9.98931553],
                            [39.71167769, -9.98819819],
                            [39.71031661, -9.98689125],
                            [39.70995371, -9.98687115],
                            [39.70975201, -9.98685998],
                            [39.70944078, -9.98701123],
                            [39.70940226, -9.98670753],
                            [39.70938789, -9.98653513],
                            [39.70938411, -9.98648968],
                            [39.70937504, -9.98647414],
                            [39.7093015, -9.98634805],
                            [39.70925706, -9.98627184],
                            [39.70909371, -9.98576352],
                            [39.70908176, -9.98570136],
                            [39.70900296, -9.98529151],
                            [39.70891222, -9.98494659],
                            [39.70882147, -9.98460166],
                            [39.70829192, -9.98370503],
                            [39.70811167, -9.98307933],
                            [39.70726024, -9.98133453],
                            [39.70715806, -9.98112513],
                            [39.70699559, -9.98079219],
                            [39.70699417, -9.98075528],
                            [39.70696837, -9.98008426],
                            [39.70667109, -9.97964302],
                            [39.7061245, -9.97883177],
                            [39.70582507, -9.9778788],
                            [39.70549841, -9.97706196],
                            [39.70495399, -9.97512878],
                            [39.70460011, -9.97322282],
                            [39.70440757, -9.97256802],
                            [39.7043279, -9.97229707],
                            [39.70578835, -9.97116433],
                            [39.70572754, -9.97065129],
                            [39.70570499, -9.97046102],
                            [39.70565186, -9.97024479],
                            [39.70552834, -9.96974203],
                            [39.70527543, -9.96871265],
                            [39.70527619, -9.96851028],
                            [39.70527713, -9.96825909],
                            [39.70528044, -9.96737185],
                            [39.70527655, -9.96736688],
                            [39.70531639, -9.96735919],
                            [39.70492896, -9.96618943],
                            [39.70477281, -9.96517186],
                            [39.70459527, -9.96415434],
                            [39.70445572, -9.96289923],
                            [39.70445346, -9.96184741],
                            [39.70506786, -9.96116752],
                            [39.70619497, -9.96024903],
                            [39.70680892, -9.95936555],
                            [39.70745643, -9.95817664],
                            [39.70803397, -9.95627534],
                            [39.7082027, -9.95518923],
                            [39.70854257, -9.95413669],
                            [39.70905387, -9.95325343],
                            [39.70983851, -9.95219993],
                            [39.71041887, -9.95161511],
                            [39.71080526, -9.95065117],
                            [39.71125765, -9.95011044],
                            [39.71192487, -9.94926454],
                            [39.71253167, -9.94859974],
                            [39.71319915, -9.94787448],
                            [39.71362386, -9.94739101],
                            [39.71435243, -9.94678626],
                            [39.71514155, -9.94606074],
                            [39.71587129, -9.94599885],
                            [39.71690737, -9.94696175],
                            [39.71782103, -9.94756298],
                            [39.71867241, -9.94750084],
                            [39.71964413, -9.94683523],
                            [39.72018915, -9.9457483],
                            [39.72043019, -9.94472235],
                            [39.72146179, -9.94363437],
                            [39.72267571, -9.94248568],
                            [39.72440745, -9.94154403],
                            [39.72458814, -9.94161823],
                            [39.72572312, -9.94145492],
                            [39.72750686, -9.94129019],
                            [39.72904767, -9.94128684],
                            [39.72995914, -9.94110657],
                            [39.73101267, -9.94078257],
                            [39.73263457, -9.94077902],
                            [39.73417519, -9.94069522],
                            [39.7353909, -9.94037084],
                            [39.73619969, -9.93940395],
                            [39.73685106, -9.93846641],
                            [39.7369272, -9.93835682],
                            [39.73814344, -9.93827371],
                            [39.73968387, -9.93810946],
                            [39.74130485, -9.93770374],
                            [39.74251908, -9.93673594],
                            [39.74349003, -9.93576867],
                            [39.74437951, -9.93464073],
                            [39.74559172, -9.93278824],
                            [39.74648064, -9.93141902],
                            [39.74785666, -9.93028999],
                            [39.74858503, -9.92964496],
                            [39.75061049, -9.92883617],
                            [39.75158249, -9.92835144],
                            [39.75271759, -9.92826846],
                            [39.75433979, -9.92842566],
                            [39.75563763, -9.92858359],
                            [39.75734166, -9.92906229],
                            [39.75920788, -9.92954062],
                            [39.76010063, -9.9298603],
                            [39.76083157, -9.93034119],
                            [39.76188707, -9.93090177],
                            [39.7635928, -9.93210427],
                            [39.76481417, -9.93277358],
                            [39.76505433, -9.93290518],
                            [39.76659621, -9.93338421],
                            [39.76764967, -9.93306009],
                            [39.76852405, -9.93255535],
                            [39.76918836, -9.93217188],
                            [39.77072817, -9.93176621],
                            [39.77177992, -9.93071826],
                            [39.77315602, -9.92966956],
                            [39.77469601, -9.9293443],
                            [39.77607342, -9.92885856],
                            [39.77777596, -9.92869376],
                            [39.77890877, -9.9276456],
                            [39.78117645, -9.92643394],
                            [39.78247314, -9.92610921],
                            [39.78366952, -9.92516645],
                            [39.78403249, -9.92435129],
                            [39.78466892, -9.92344502],
                            [39.78567014, -9.92249265],
                            [39.78630602, -9.92136018],
                            [39.78719746, -9.92085219],
                            [39.7872626, -9.92081507],
                            [39.78762523, -9.91986419],
                            [39.78748418, -9.9181002],
                            [39.787709, -9.9167425],
                            [39.78788755, -9.91511347],
                            [39.78774823, -9.9140733],
                            [39.78765388, -9.91276159],
                            [39.78742301, -9.91158591],
                            [39.78737417, -9.91022885],
                            [39.7870992, -9.90968663],
                            [39.78668655, -9.90878281],
                            [39.78631918, -9.90774317],
                            [39.78606876, -9.90697938],
                            [39.78585974, -9.90634183],
                            [39.78494526, -9.90539395],
                            [39.78435104, -9.90485247],
                            [39.78350933, -9.9034539],
                            [39.783481, -9.90340685],
                            [39.78281209, -9.90262351],
                            [39.78260596, -9.90225076],
                            [39.78226325, -9.90198013],
                            [39.7817837, -9.90170981],
                            [39.78157773, -9.90140492],
                            [39.78133707, -9.90089654],
                            [39.7810276, -9.90021867],
                            [39.78085512, -9.89960834],
                            [39.78061439, -9.89906603],
                            [39.78020294, -9.89865984],
                            [39.77996165, -9.89788002],
                            [39.78009695, -9.89723505],
                            [39.78019805, -9.89659015],
                            [39.7801282, -9.89597958],
                            [39.77992136, -9.89530148],
                            [39.7798507, -9.89435161],
                            [39.77967791, -9.89360557],
                            [39.77960781, -9.89289321],
                            [39.77950732, -9.892474],
                            [39.77946963, -9.89231673],
                            [39.77939865, -9.89123115],
                            [39.77915817, -9.89079063],
                            [39.77850737, -9.89041891],
                            [39.77768554, -9.89004759],
                            [39.77696673, -9.88984568],
                            [39.77624792, -9.88964376],
                            [39.77578755, -9.88962308],
                            [39.7755295, -9.88961149],
                            [39.77470815, -9.88944373],
                            [39.77388681, -9.88927598],
                            [39.77306562, -9.88917608],
                            [39.77241593, -9.88927936],
                            [39.77162911, -9.88924724],
                            [39.77046594, -9.88918205],
                            [39.76954284, -9.88938774],
                            [39.76905736, -9.88949742],
                            [39.76827069, -9.88953315],
                            [39.76775744, -9.88946647],
                            [39.76700466, -9.88936639],
                            [39.76587522, -9.88909753],
                            [39.76505435, -9.88913333],
                            [39.76443911, -9.88933831],
                            [39.76361886, -9.88964554],
                            [39.76283219, -9.88968125],
                            [39.76197687, -9.88961534],
                            [39.76112132, -9.88944763],
                            [39.76026475, -9.88883883],
                            [39.7594431, -9.88853532],
                            [39.75865573, -9.88826566],
                            [39.75807376, -9.8880634],
                            [39.75735473, -9.88775965],
                            [39.75660087, -9.88718454],
                            [39.75591612, -9.88691465],
                            [39.75540318, -9.88698366],
                            [39.75485666, -9.88732418],
                            [39.7544475, -9.8879019],
                            [39.75400413, -9.8884797],
                            [39.75342301, -9.88865065],
                            [39.75304743, -9.88895686],
                            [39.75284305, -9.88933055],
                            [39.75243326, -9.88963683],
                            [39.75185206, -9.88977385],
                            [39.75116816, -9.88987717],
                            [39.75031307, -9.88991301],
                            [39.74983457, -9.89008372],
                            [39.74935683, -9.89059373],
                            [39.74867315, -9.89079884],
                            [39.74798825, -9.89046106],
                            [39.74747431, -9.89008898],
                            [39.74682393, -9.88988685],
                            [39.74624195, -9.88968456],
                            [39.74521583, -9.88972077],
                            [39.74456644, -9.88995971],
                            [39.743883, -9.89026659],
                            [39.74316512, -9.89047176],
                            [39.74248106, -9.8905072],
                            [39.74148884, -9.8904076],
                            [39.74029122, -9.89024059],
                            [39.73933306, -9.89007305],
                            [39.73810124, -9.88990611],
                            [39.73676657, -9.8896376],
                            [39.73594561, -9.8896394],
                            [39.73532997, -9.88967468],
                            [39.73427024, -9.88998236],
                            [39.73386044, -9.89028862],
                            [39.73307376, -9.89032427],
                            [39.7323888, -9.88995254],
                            [39.7321822, -9.88934225],
                            [39.73231708, -9.88845978],
                            [39.73231822, -9.8884552],
                            [39.7324527, -9.88791661],
                            [39.73293077, -9.88754234],
                            [39.73330628, -9.88720222],
                            [39.73378435, -9.88682795],
                            [39.73412521, -9.88628433],
                            [39.73391899, -9.88584369],
                            [39.73419181, -9.88546987],
                            [39.73480692, -9.88519709],
                            [39.73525115, -9.88499254],
                            [39.73569485, -9.88455048],
                            [39.73569387, -9.88410939],
                            [39.73620666, -9.88397255],
                            [39.73682176, -9.88369977],
                            [39.73719803, -9.88369894],
                            [39.73781404, -9.88383331],
                            [39.73832766, -9.88406969],
                            [39.73884151, -9.88440786],
                            [39.73945684, -9.88423686],
                            [39.73992204, -9.88423584],
                            [39.74029717, -9.88372607],
                            [39.74039834, -9.88308118],
                            [39.74032857, -9.8824706],
                            [39.74008768, -9.88182646],
                            [39.73998052, -9.88152156],
                            [39.73977786, -9.88094497],
                            [39.73957073, -9.88009718],
                            [39.73960282, -9.87914708],
                            [39.73984059, -9.8784001],
                            [39.74018143, -9.87785647],
                            [39.74076193, -9.87741411],
                            [39.74151399, -9.87720887],
                            [39.74226583, -9.87690185],
                            [39.7429491, -9.87652711],
                            [39.74352944, -9.87601689],
                            [39.74407642, -9.87587996],
                            [39.74489742, -9.87591207],
                            [39.74561573, -9.87591048],
                            [39.74633434, -9.87604461],
                            [39.74712137, -9.87617858],
                            [39.74766934, -9.87648273],
                            [39.74811569, -9.87722819],
                            [39.74839125, -9.87807582],
                            [39.74856381, -9.87875403],
                            [39.74873622, -9.87936439],
                            [39.74887427, -9.87990695],
                            [39.74887565, -9.88051769],
                            [39.74891139, -9.8811962],
                            [39.748913, -9.88190872],
                            [39.74891523, -9.88289268],
                            [39.74898572, -9.88380862],
                            [39.74915882, -9.88472434],
                            [39.74943408, -9.88543625],
                            [39.74994909, -9.88628334],
                            [39.7504289, -9.88668943],
                            [39.75118152, -9.88672168],
                            [39.75145432, -9.88634784],
                            [39.75135016, -9.88566948],
                            [39.75148606, -9.88526202],
                            [39.75172481, -9.88495612],
                            [39.75206571, -9.88444641],
                            [39.75226894, -9.88356379],
                            [39.7523701, -9.8829189],
                            [39.75312185, -9.88257792],
                            [39.75366915, -9.88257669],
                            [39.75421675, -9.88271118],
                            [39.7547984, -9.88277774],
                            [39.75551695, -9.88287792],
                            [39.75630431, -9.88314758],
                            [39.75698912, -9.88345141],
                            [39.75743504, -9.88399328],
                            [39.75747041, -9.88450215],
                            [39.75719738, -9.8847742],
                            [39.7571645, -9.88535107],
                            [39.75723423, -9.88592772],
                            [39.75730396, -9.88650437],
                            [39.75747616, -9.88701292],
                            [39.75798933, -9.8870457],
                            [39.75836498, -9.88677341],
                            [39.75887807, -9.88677226],
                            [39.75959671, -9.88690635],
                            [39.76024709, -9.88710846],
                            [39.76093137, -9.88717477],
                            [39.76120526, -9.88727594],
                            [39.76175334, -9.887614],
                            [39.76230142, -9.88795205],
                            [39.76278031, -9.88795096],
                            [39.76319071, -9.8879161],
                            [39.76390888, -9.88784661],
                            [39.76472959, -9.88774296],
                            [39.76572134, -9.88763891],
                            [39.76671332, -9.88763665],
                            [39.7674999, -9.887567],
                            [39.76828617, -9.88736163],
                            [39.76903887, -9.88742776],
                            [39.76982569, -9.88745989],
                            [39.77074941, -9.88752564],
                            [39.77180988, -9.88755713],
                            [39.77304162, -9.88769002],
                            [39.77413645, -9.88778929],
                            [39.77516256, -9.887753],
                            [39.77629136, -9.88775039],
                            [39.7770785, -9.88791822],
                            [39.77772924, -9.88821454],
                            [39.77894565, -9.88829215],
                            [39.77912613, -9.88830367],
                            [39.78016206, -9.88836976],
                            [39.78154082, -9.88852741],
                            [39.78308137, -9.88852382],
                            [39.78421746, -9.8889233],
                            [39.7856773, -9.88908074],
                            [39.7870555, -9.88899709],
                            [39.78835241, -9.8888332],
                            [39.78989123, -9.88810577],
                            [39.79126787, -9.8873787],
                            [39.7925617, -9.885928],
                            [39.79369432, -9.8848798],
                            [39.79514816, -9.88254404],
                            [39.79627843, -9.88053074],
                            [39.79732607, -9.87787423],
                            [39.79753949, -9.87726088],
                            [39.79797024, -9.87602293],
                            [39.79869548, -9.87417143],
                            [39.7998228, -9.87095175],
                            [39.79992589, -9.87054717],
                            [39.80078659, -9.86716948],
                            [39.80110838, -9.86643763],
                            [39.80223797, -9.8638686],
                            [39.80288112, -9.86161516],
                            [39.80352445, -9.85944215],
                            [39.80432681, -9.85598196],
                            [39.8048873, -9.85308532],
                            [39.80520691, -9.85115436],
                            [39.80536441, -9.84923986],
                            [39.80511612, -9.8471494],
                            [39.80535641, -9.84594245],
                            [39.80543319, -9.84417292],
                            [39.80534511, -9.84127782],
                            [39.80550043, -9.83846257],
                            [39.8054139, -9.83621087],
                            [39.80500234, -9.83363825],
                            [39.80499689, -9.83138636],
                            [39.80458611, -9.82913543],
                            [39.80417301, -9.8259194],
                            [39.80376282, -9.82390975],
                            [39.80343352, -9.82181947],
                            [39.80261764, -9.81964992],
                            [39.80204458, -9.81731895],
                            [39.80122776, -9.81474727],
                            [39.80104656, -9.81403539],
                            [39.80090041, -9.81346124],
                            [39.80068279, -9.81171326],
                            [39.80033869, -9.81083191],
                            [39.79982312, -9.80974738],
                            [39.7994787, -9.80873031],
                            [39.79892788, -9.80720478],
                            [39.79858403, -9.80642521],
                            [39.79813638, -9.80513695],
                            [39.79765575, -9.8043577],
                            [39.79693347, -9.802629],
                            [39.79658915, -9.80164585],
                            [39.79603941, -9.8005614],
                            [39.79552314, -9.7991715],
                            [39.79497381, -9.79825669],
                            [39.79449312, -9.79744351],
                            [39.79373829, -9.79639346],
                            [39.79274409, -9.79534397],
                            [39.79188669, -9.79429415],
                            [39.79072056, -9.7928379],
                            [39.78968526, -9.79175456],
                            [39.78941041, -9.79121233],
                            [39.78923871, -9.79090736],
                            [39.78893055, -9.79073843],
                            [39.78828074, -9.79070601],
                            [39.78783779, -9.79138563],
                            [39.78749711, -9.7919293],
                            [39.78712231, -9.79250697],
                            [39.786645, -9.79311881],
                            [39.78626972, -9.7934929],
                            [39.78565546, -9.7940372],
                            [39.78497249, -9.79444594],
                            [39.78439235, -9.79495622],
                            [39.78394922, -9.79556798],
                            [39.78309741, -9.7968932],
                            [39.78265468, -9.7976746],
                            [39.78245149, -9.79852331],
                            [39.78248768, -9.79937147],
                            [39.78242079, -9.80001629],
                            [39.78235566, -9.80140755],
                            [39.7821865, -9.80218832],
                            [39.78213023, -9.80239493],
                            [39.78198307, -9.80293524],
                            [39.7819849, -9.80371562],
                            [39.78253396, -9.80452867],
                            [39.7824666, -9.80496991],
                            [39.78205591, -9.80483514],
                            [39.78157747, -9.80497196],
                            [39.78157834, -9.80534518],
                            [39.7818524, -9.80554813],
                            [39.78229737, -9.80571675],
                            [39.78216105, -9.80592064],
                            [39.78168221, -9.80588782],
                            [39.7812716, -9.80578697],
                            [39.78079259, -9.80568629],
                            [39.78024495, -9.80548397],
                            [39.77969731, -9.80528165],
                            [39.77914952, -9.80501148],
                            [39.77860156, -9.80467344],
                            [39.77798498, -9.80423377],
                            [39.77740307, -9.8039976],
                            [39.77661621, -9.80386368],
                            [39.77606913, -9.80389886],
                            [39.77514588, -9.80393491],
                            [39.77442796, -9.80403834],
                            [39.77357303, -9.80404029],
                            [39.77312924, -9.8043806],
                            [39.77261667, -9.80455141],
                            [39.77268577, -9.80485662],
                            [39.77278908, -9.80516176],
                            [39.77261832, -9.80526393],
                            [39.77210528, -9.80523117],
                            [39.77176322, -9.80519802],
                            [39.77125026, -9.80519919],
                            [39.77066913, -9.8053023],
                            [39.77046512, -9.80581171],
                            [39.7705005, -9.80632058],
                            [39.77060426, -9.80682928],
                            [39.77053665, -9.80716874],
                            [39.77022973, -9.80754266],
                            [39.77005953, -9.80788234],
                            [39.76988948, -9.80828989],
                            [39.76961722, -9.80886731],
                            [39.76944772, -9.80951236],
                            [39.76907334, -9.81029359],
                            [39.76886948, -9.81087086],
                            [39.76842576, -9.81124509],
                            [39.76774273, -9.8116538],
                            [39.76712763, -9.81185877],
                            [39.76620443, -9.81192872],
                            [39.76601727, -9.81192227],
                            [39.765281, -9.81189688],
                            [39.76445953, -9.81159336],
                            [39.7641167, -9.81122091],
                            [39.76370476, -9.81054324],
                            [39.76353276, -9.81010255],
                            [39.76308733, -9.80973032],
                            [39.76260793, -9.80945996],
                            [39.76212962, -9.80966462],
                            [39.76182254, -9.80997068],
                            [39.7616182, -9.81034436],
                            [39.76148234, -9.81075183],
                            [39.76127792, -9.81109158],
                            [39.76079953, -9.81126231],
                            [39.76035526, -9.81139902],
                            [39.75997978, -9.81170523],
                            [39.75956947, -9.81174008],
                            [39.75929527, -9.81146926],
                            [39.75915801, -9.81126599],
                            [39.75891777, -9.8108933],
                            [39.75881433, -9.8105203],
                            [39.75884806, -9.81031665],
                            [39.75905263, -9.81004475],
                            [39.75932529, -9.80963699],
                            [39.7597007, -9.80929685],
                            [39.76004168, -9.80885499],
                            [39.76041732, -9.80861664],
                            [39.76092974, -9.80837798],
                            [39.7612366, -9.80797014],
                            [39.76157811, -9.80776579],
                            [39.76178346, -9.80783319],
                            [39.76222834, -9.80796791],
                            [39.7624808, -9.80790051],
                            [39.762741, -9.80783104],
                            [39.76325444, -9.80803346],
                            [39.763631, -9.80820226],
                            [39.76397322, -9.80830328],
                            [39.76428108, -9.80833651],
                            [39.76479444, -9.808505],
                            [39.76527422, -9.80894501],
                            [39.7658908, -9.8093847],
                            [39.76637012, -9.80962112],
                            [39.76722492, -9.80955133],
                            [39.76763444, -9.80917718],
                            [39.76780441, -9.8087357],
                            [39.76797415, -9.80819244],
                            [39.7681097, -9.80764926],
                            [39.76814202, -9.80683487],
                            [39.76834603, -9.80632547],
                            [39.76848079, -9.80544299],
                            [39.76861563, -9.80459444],
                            [39.76885384, -9.80408495],
                            [39.76919503, -9.80374488],
                            [39.76991256, -9.80347182],
                            [39.77052772, -9.80330077],
                            [39.77151937, -9.80326459],
                            [39.77244246, -9.80316069],
                            [39.77329709, -9.80302303],
                            [39.7739124, -9.80291983],
                            [39.77459612, -9.80281648],
                            [39.77517731, -9.8027473],
                            [39.77562149, -9.80257663],
                            [39.77575725, -9.80213524],
                            [39.77565308, -9.80145688],
                            [39.77545962, -9.80099075],
                            [39.77534357, -9.80071114],
                            [39.77510285, -9.80013488],
                            [39.77527297, -9.79976127],
                            [39.77588797, -9.79952235],
                            [39.77626421, -9.79955542],
                            [39.77670933, -9.79979191],
                            [39.77725617, -9.79965494],
                            [39.77776817, -9.79924661],
                            [39.77817735, -9.79873672],
                            [39.77841546, -9.7981933],
                            [39.77875577, -9.79748],
                            [39.77899317, -9.79663121],
                            [39.77919661, -9.79588429],
                            [39.77950359, -9.79554429],
                            [39.78022132, -9.79537299],
                            [39.78056352, -9.795474],
                            [39.7815891, -9.79533592],
                            [39.78223844, -9.79516477],
                            [39.78288746, -9.79485791],
                            [39.78367295, -9.79441501],
                            [39.78418502, -9.7940406],
                            [39.78473064, -9.79339468],
                            [39.7849648, -9.79306391],
                            [39.78513964, -9.79281693],
                            [39.7854113, -9.79200199],
                            [39.78558045, -9.79122122],
                            [39.78581735, -9.79016885],
                            [39.78591786, -9.78928645],
                            [39.78571005, -9.78816725],
                            [39.78529767, -9.78778668],
                            [39.78453837, -9.78660886],
                            [39.78399573, -9.78564501],
                            [39.78291322, -9.78489686],
                            [39.781507, -9.78436393],
                            [39.78010178, -9.78425992],
                            [39.77869706, -9.78437038],
                            [39.77761656, -9.78448009],
                            [39.77642774, -9.78448281],
                            [39.77523917, -9.78459276],
                            [39.77372687, -9.78491791],
                            [39.77178201, -9.78513681],
                            [39.77005207, -9.78481903],
                            [39.76810623, -9.78460897],
                            [39.76670027, -9.78418321],
                            [39.76518575, -9.78354321],
                            [39.76377808, -9.7823668],
                            [39.76204621, -9.7811911],
                            [39.76063832, -9.77990744],
                            [39.75966273, -9.7786228],
                            [39.75963989, -9.77704247],
                            [39.75966051, -9.77704247],
                            [39.75975489, -9.77638171],
                            [39.75928303, -9.77430503],
                            [39.75852806, -9.77232274],
                            [39.75777308, -9.7712844],
                            [39.75673498, -9.76977408],
                            [39.75475315, -9.76731981],
                            [39.75418692, -9.7657151],
                            [39.75390381, -9.76373281],
                            [39.75409256, -9.76203371],
                            [39.75494193, -9.7606178],
                            [39.75635754, -9.7589187],
                            [39.7579619, -9.75665324],
                            [39.75890565, -9.75419899],
                            [39.75975502, -9.75193352],
                            [39.76032127, -9.74957366],
                            [39.76041565, -9.74768577],
                            [39.76013255, -9.74570349],
                            [39.75871696, -9.74268285],
                            [39.75588577, -9.74022858],
                            [39.7522996, -9.73852946],
                            [39.74956277, -9.7375855],
                            [39.74654283, -9.73711351],
                            [39.7434285, -9.7387182],
                            [39.74340532, -9.73872377],
                            [39.74650775, -9.73712521],
                            [39.74546964, -9.73608686],
                            [39.74244971, -9.73448214],
                            [39.73858041, -9.7331606],
                            [39.73556046, -9.73268861],
                            [39.73320113, -9.73297178],
                            [39.73103053, -9.73533163],
                            [39.72970929, -9.73759709],
                            [39.72869182, -9.73788617],
                            [39.72709927, -9.73799236],
                            [39.72603757, -9.73762067],
                            [39.7252413, -9.7370366],
                            [39.72412652, -9.7368773],
                            [39.72230983, -9.73720178],
                            [39.7217259, -9.73603364],
                            [39.72108888, -9.73523718],
                            [39.71960251, -9.73449382],
                            [39.71885932, -9.73375046],
                            [39.71864699, -9.73284781],
                            [39.71864699, -9.73194516],
                            [39.71849973, -9.73072393],
                            [39.71822104, -9.7299673],
                            [39.71814141, -9.72936996],
                            [39.71826086, -9.72889209],
                            [39.7183803, -9.72817528],
                            [39.71826086, -9.72765758],
                            [39.71787606, -9.72703265],
                            [39.71777499, -9.7268685],
                            [39.71756266, -9.72649682],
                            [39.71708489, -9.72644372],
                            [39.71660713, -9.72644372],
                            [39.71650096, -9.72607204],
                            [39.7167133, -9.72554107],
                            [39.71703181, -9.72490391],
                            [39.71735033, -9.72389507],
                            [39.7176423, -9.72281543],
                            [39.71785465, -9.72196588],
                            [39.71785465, -9.72097474],
                            [39.7184209, -9.72033758],
                            [39.71858606, -9.71907899],
                            [39.7187694, -9.71755746],
                            [39.7191523, -9.71759228],
                            [39.72078024, -9.71794626],
                            [39.72254974, -9.71801707],
                            [39.72396535, -9.71737991],
                            [39.72531017, -9.71660117],
                            [39.72665499, -9.71575162],
                            [39.72785825, -9.71589322],
                            [39.72849528, -9.71532686],
                            [39.72870762, -9.71426492],
                            [39.72891997, -9.71334457],
                            [39.72998167, -9.71235344],
                            [39.73111416, -9.71171628],
                            [39.73252976, -9.71171629],
                            [39.73352068, -9.71114992],
                            [39.73628111, -9.70980482],
                            [39.73840451, -9.70881368],
                            [39.7405987, -9.70845972],
                            [39.74314678, -9.70782257],
                            [39.74420848, -9.707327],
                            [39.74464129, -9.70696625],
                            [39.74548253, -9.70626507],
                            [39.74753515, -9.70513235],
                            [39.74873842, -9.70470758],
                            [39.75064949, -9.70230053],
                            [39.75114496, -9.70031824],
                            [39.75100341, -9.69791118],
                            [39.75008328, -9.69592889],
                            [39.74880925, -9.69458376],
                            [39.74604885, -9.69168111],
                            [39.74583651, -9.69090236],
                            [39.74498715, -9.69019439],
                            [39.74477482, -9.68962803],
                            [39.74399624, -9.68927404],
                            [39.74328844, -9.68884926],
                            [39.74243908, -9.68842448],
                            [39.74173129, -9.68778732],
                            [39.74074037, -9.68764572],
                            [39.74017413, -9.68764572],
                            [39.73932477, -9.68700855],
                            [39.73805073, -9.68637138],
                            [39.73760249, -9.68609383],
                            [39.73656436, -9.68545102],
                            [39.7342994, -9.68545101],
                            [39.73337926, -9.68538021],
                            [39.73260068, -9.68495543],
                            [39.73068962, -9.68474303],
                            [39.7289909, -9.68509701],
                            [39.72743374, -9.68495541],
                            [39.72679672, -9.68424744],
                            [39.72750453, -9.68353949],
                            [39.72899091, -9.68268994],
                            [39.73097275, -9.68226518],
                            [39.73316693, -9.6821236],
                            [39.73415786, -9.68176962],
                            [39.73521956, -9.68099088],
                            [39.73613971, -9.67985815],
                            [39.73741376, -9.67801746],
                            [39.73882937, -9.67504404],
                            [39.7394664, -9.67270778],
                            [39.7405989, -9.66987594],
                            [39.74144828, -9.66697332],
                            [39.74154181, -9.66676969],
                            [39.74265155, -9.66435387],
                            [39.74378404, -9.66208841],
                            [39.74548278, -9.65932738],
                            [39.74626137, -9.65692032],
                            [39.74661528, -9.65401769],
                            [39.74633218, -9.65175222],
                            [39.74569517, -9.64927436],
                            [39.74477504, -9.64665491],
                            [39.74229776, -9.6430443],
                            [39.73890034, -9.63985847],
                            [39.73621071, -9.63823015],
                            [39.73380419, -9.63660183],
                            [39.73005286, -9.63518589],
                            [39.7277879, -9.63461951],
                            [39.72557014, -9.63369915],
                            [39.72401298, -9.63306198],
                            [39.7218188, -9.63214163],
                            [39.72075711, -9.63143366],
                            [39.71919995, -9.63030092],
                            [39.7179967, -9.62973454],
                            [39.71757202, -9.62916817],
                            [39.71721813, -9.62753987],
                            [39.71721814, -9.62591156],
                            [39.71757204, -9.62477883],
                            [39.71813829, -9.62322132],
                            [39.71898766, -9.621947],
                            [39.71962468, -9.62095586],
                            [39.71986539, -9.61963189],
                            [39.71991428, -9.61936296],
                            [39.72008659, -9.61925571],
                            [39.72142131, -9.61842493],
                            [39.72304926, -9.61615947],
                            [39.72316815, -9.6159811],
                            [39.72488956, -9.61339843],
                            [39.72651751, -9.61099138],
                            [39.72705432, -9.60987919],
                            [39.72750844, -9.60893831],
                            [39.72800391, -9.60653125],
                            [39.72842861, -9.6039826],
                            [39.72899487, -9.60037201],
                            [39.7289241, -9.59775256],
                            [39.72857021, -9.5952039],
                            [39.72814554, -9.59336321],
                            [39.72715464, -9.59060216],
                            [39.72623451, -9.58876146],
                            [39.7248897, -9.58642519],
                            [39.72269553, -9.58423051],
                            [39.72078448, -9.58274378],
                            [39.71844874, -9.58125706],
                            [39.71639613, -9.57977033],
                            [39.7140604, -9.57792962],
                            [39.71271559, -9.57566415],
                            [39.71254805, -9.57395136],
                            [39.71260114, -9.57251774],
                            [39.71276041, -9.57103103],
                            [39.71270733, -9.56911954],
                            [39.71254809, -9.56688946],
                            [39.71233576, -9.56540275],
                            [39.71201726, -9.56375674],
                            [39.71169875, -9.56237622],
                            [39.71138025, -9.56073021],
                            [39.71084941, -9.55887182],
                            [39.71042474, -9.55765059],
                            [39.70994698, -9.55680103],
                            [39.70978773, -9.55573909],
                            [39.70936305, -9.55520812],
                            [39.70855517, -9.55374131],
                            [39.70745035, -9.55174022],
                            [39.70578906, -9.54897843],
                            [39.70446785, -9.54671295],
                            [39.70276914, -9.54435308],
                            [39.70022107, -9.54161563],
                            [39.69757863, -9.53897257],
                            [39.69578555, -9.53680148],
                            [39.69484183, -9.5347248],
                            [39.69503058, -9.53340327],
                            [39.69559683, -9.53208175],
                            [39.69635182, -9.53038265],
                            [39.69786181, -9.52783401],
                            [39.69889993, -9.52575733],
                            [39.69946618, -9.52377505],
                            [39.69965493, -9.52198156],
                            [39.69984369, -9.52047124],
                            [39.69965495, -9.51820577],
                            [39.69871123, -9.51660106],
                            [39.6977675, -9.51565711],
                            [39.69588003, -9.51518513],
                            [39.69380382, -9.51480754],
                            [39.69182199, -9.51433556],
                            [39.69068951, -9.51358039],
                            [39.68929541, -9.51198855],
                            [39.68899681, -9.51148081],
                            [39.68878779, -9.51118214],
                            [39.68857877, -9.51088347],
                            [39.68851905, -9.51037573],
                            [39.68836975, -9.51025626],
                            [39.68810101, -9.51007705],
                            [39.68792185, -9.50959918],
                            [39.68777255, -9.50918104],
                            [39.68768297, -9.5087629],
                            [39.68756353, -9.5084045],
                            [39.68744409, -9.50798636],
                            [39.68750381, -9.50715008],
                            [39.68753368, -9.50676181],
                            [39.68753368, -9.50628394],
                            [39.68762326, -9.50571646],
                            [39.6875934, -9.50520872],
                            [39.68747396, -9.50496979],
                            [39.6872948, -9.50467112],
                            [39.68747397, -9.50413351],
                            [39.68750383, -9.50356604],
                            [39.68741425, -9.50302843],
                            [39.68726495, -9.50264016],
                            [39.68726496, -9.50228175],
                            [39.6872351, -9.50171428],
                            [39.68711566, -9.50120654],
                            [39.6870858, -9.50075853],
                            [39.68696636, -9.50040013],
                            [39.68681706, -9.50004172],
                            [39.6867872, -9.49974305],
                            [39.68666777, -9.49941452],
                            [39.68654833, -9.49914571],
                            [39.68651847, -9.49857824],
                            [39.68642889, -9.4981601],
                            [39.68621987, -9.49804063],
                            [39.68616015, -9.49768223],
                            [39.68616015, -9.49714462],
                            [39.6857399, -9.49528401],
                            [39.68562047, -9.494388],
                            [39.68529201, -9.49337252],
                            [39.68451565, -9.4921181],
                            [39.68358998, -9.49101302],
                            [39.68284348, -9.49026634],
                            [39.68096229, -9.48844444],
                            [39.68012621, -9.48757829],
                            [39.67929012, -9.48674201],
                            [39.67791655, -9.48563692],
                            [39.67678186, -9.48500971],
                            [39.67591592, -9.4847409],
                            [39.67510969, -9.48477076],
                            [39.67445276, -9.4851889],
                            [39.67436317, -9.48587584],
                            [39.67427359, -9.48620438],
                            [39.6735868, -9.48629397],
                            [39.67239239, -9.48671211],
                            [39.67152644, -9.48718997],
                            [39.67054104, -9.48796651],
                            [39.66946607, -9.48889238],
                            [39.66868969, -9.48954946],
                            [39.66731667, -9.49004585],
                            [39.66641422, -9.49025823],
                            [39.66519327, -9.48993964],
                            [39.664397, -9.48946177],
                            [39.66355133, -9.48896472],
                            [39.66299394, -9.48856649],
                            [39.66247636, -9.48808861],
                            [39.66183934, -9.48765056],
                            [39.66132176, -9.48721251],
                            [39.66076437, -9.48673463],
                            [39.66004773, -9.48605764],
                            [39.65933109, -9.48550012],
                            [39.65901258, -9.48510189],
                            [39.65885333, -9.48426561],
                            [39.65853482, -9.48346916],
                            [39.65805706, -9.48287182],
                            [39.65769874, -9.48235412],
                            [39.65722098, -9.48175678],
                            [39.65694228, -9.48107979],
                            [39.65654415, -9.48048245],
                            [39.65606639, -9.47960634],
                            [39.65570807, -9.47869042],
                            [39.65550901, -9.47753556],
                            [39.65511088, -9.47626123],
                            [39.65455349, -9.47490726],
                            [39.65391648, -9.47351346],
                            [39.65335909, -9.47247807],
                            [39.65276189, -9.4709648],
                            [39.65264246, -9.46980994],
                            [39.65264246, -9.46881437],
                            [39.65264247, -9.46805774],
                            [39.6524434, -9.46702235],
                            [39.65243442, -9.46695943],
                            [39.65232396, -9.46618607],
                            [39.65220453, -9.46491175],
                            [39.65196203, -9.46418407],
                            [39.6518064, -9.46371706],
                            [39.65140827, -9.46264185],
                            [39.65108976, -9.46140734],
                            [39.65108977, -9.46009319],
                            [39.65112959, -9.45873922],
                            [39.65101016, -9.4573056],
                            [39.65095247, -9.4564689],
                            [39.65095704, -9.45612635],
                            [39.6507723, -9.45509153],
                            [39.65144129, -9.45351283],
                            [39.65153489, -9.45340707],
                            [39.65253436, -9.45227778],
                            [39.65282373, -9.45082663],
                            [39.65172766, -9.45046602],
                            [39.65026552, -9.44959836],
                            [39.64865611, -9.4480782],
                            [39.64755761, -9.44641203],
                            [39.6463857, -9.44452839],
                            [39.6455063, -9.44286181],
                            [39.64462758, -9.44155787],
                            [39.64287259, -9.44025554],
                            [39.64133749, -9.43938797],
                            [39.63899633, -9.43699873],
                            [39.63804423, -9.43547731],
                            [39.63701952, -9.43417362],
                            [39.63614032, -9.43257953],
                            [39.63569887, -9.43076707],
                            [39.63533164, -9.42960724],
                            [39.63474601, -9.42881046],
                            [39.63401474, -9.42823153],
                            [39.63320886, -9.42678237],
                            [39.6323297, -9.42518827],
                            [39.63145042, -9.42352164],
                            [39.62998765, -9.42221871],
                            [39.62910839, -9.42055207],
                            [39.62793759, -9.41917607],
                            [39.62662089, -9.41787286],
                            [39.62552364, -9.41678685],
                            [39.62355135, -9.41635516],
                            [39.62216322, -9.41592243],
                            [39.62077819, -9.41723042],
                            [39.61880758, -9.41774161],
                            [39.61771178, -9.41745341],
                            [39.61632339, -9.41687559],
                            [39.6147162, -9.41644321],
                            [39.61310914, -9.41608336],
                            [39.61106551, -9.41659462],
                            [39.60924244, -9.41797587],
                            [39.60814792, -9.41841295],
                            [39.60661476, -9.41856065],
                            [39.60544632, -9.41849013],
                            [39.60398543, -9.41820251],
                            [39.60157466, -9.41755386],
                            [39.60084421, -9.41741004],
                            [39.59989446, -9.41712153],
                            [39.5986662, -9.41856488],
                            [39.59779097, -9.41914662],
                            [39.5958086, -9.4207188],
                            [39.59464177, -9.42159114],
                            [39.59332976, -9.42297145],
                            [39.59260054, -9.42355293],
                            [39.59179768, -9.42377187],
                            [39.59070323, -9.42428143],
                            [39.58975495, -9.42486327],
                            [39.58851482, -9.42559066],
                            [39.58720142, -9.4261731],
                            [39.58610732, -9.42690024],
                            [39.58493873, -9.42675712],
                            [39.58406137, -9.42610579],
                            [39.58303676, -9.42472939],
                            [39.58164912, -9.42458663],
                            [39.58070081, -9.42516844],
                            [39.579167, -9.42495338],
                            [39.57726725, -9.42423118],
                            [39.57617289, -9.42481322],
                            [39.57369017, -9.42481728],
                            [39.57252255, -9.42525437],
                            [39.57179186, -9.42496543],
                            [39.5698933, -9.42496851],
                            [39.56868064, -9.42521902],
                            [39.56852666, -9.424645],
                            [39.56671646, -9.42285276],
                            [39.56485, -9.42013582],
                            [39.56353061, -9.41703721],
                            [39.56297917, -9.41469894],
                            [39.56297495, -9.41208781],
                            [39.56302324, -9.41052261],
                            [39.56307902, -9.40871492],
                            [39.56241697, -9.40757293],
                            [39.56255925, -9.40525169],
                            [39.56408932, -9.40321836],
                            [39.56576906, -9.40343326],
                            [39.56810643, -9.40393723],
                            [39.56936167, -9.40385207],
                            [39.5702967, -9.40378863],
                            [39.57299701, -9.40298641],
                            [39.57474654, -9.40124281],
                            [39.57470634, -9.40097951],
                            [39.5745251, -9.39979254],
                            [39.57349941, -9.39769079],
                            [39.5721818, -9.39566204],
                            [39.56750068, -9.39066491],
                            [39.56406537, -9.38842192],
                            [39.56194704, -9.38784504],
                            [39.55873281, -9.38683469],
                            [39.55537165, -9.38524429],
                            [39.55266786, -9.38379789],
                            [39.55083922, -9.38169732],
                            [39.550251, -9.37908708],
                            [39.55089587, -9.37732382],
                            [39.55245176, -9.37625759],
                            [39.55497944, -9.37412603],
                            [39.55587561, -9.37295799],
                            [39.55663405, -9.37393002],
                            [39.55789972, -9.37402473],
                            [39.55877508, -9.37353981],
                            [39.55974995, -9.37440865],
                            [39.56150283, -9.37479271],
                            [39.56335088, -9.37382268],
                            [39.56471406, -9.37401393],
                            [39.56520299, -9.37536707],
                            [39.56578864, -9.37633323],
                            [39.56666635, -9.37729891],
                            [39.56608398, -9.37836364],
                            [39.56715717, -9.37981256],
                            [39.56676997, -9.38116711],
                            [39.56726097, -9.38377746],
                            [39.56882094, -9.38522559],
                            [39.57164807, -9.38763875],
                            [39.57603638, -9.39217694],
                            [39.57750164, -9.39517252],
                            [39.57886635, -9.39623409],
                            [39.58003685, -9.39758609],
                            [39.58266751, -9.39883897],
                            [39.58529704, -9.39941487],
                            [39.58802509, -9.40066755],
                            [39.59007085, -9.4014378],
                            [39.59152744, -9.40153976],
                            [39.59241283, -9.40174116],
                            [39.59411495, -9.40180592],
                            [39.59534144, -9.40241251],
                            [39.59609125, -9.40295227],
                            [39.59718088, -9.40315331],
                            [39.59901929, -9.40328545],
                            [39.60010986, -9.40402751],
                            [39.60188019, -9.40415975],
                            [39.60405724, -9.40327687],
                            [39.605892, -9.40131251],
                            [39.60786326, -9.39955079],
                            [39.60983378, -9.39738329],
                            [39.61214526, -9.39555333],
                            [39.61445565, -9.39311471],
                            [39.61635754, -9.39067679],
                            [39.6182588, -9.38790072],
                            [39.61948023, -9.38566686],
                            [39.62036186, -9.38377173],
                            [39.62335155, -9.38058796],
                            [39.62464111, -9.37842159],
                            [39.62600092, -9.37747239],
                            [39.62736242, -9.37746998],
                            [39.62988071, -9.37719499],
                            [39.63185501, -9.3772591],
                            [39.63328446, -9.37718892],
                            [39.63518884, -9.37623871],
                            [39.63688824, -9.3748831],
                            [39.63872353, -9.37339198],
                            [39.64110377, -9.37210275],
                            [39.64307418, -9.37007035],
                            [39.64484197, -9.36891746],
                            [39.64620081, -9.3674948],
                            [39.64722002, -9.36647852],
                            [39.64837525, -9.36539437],
                            [39.64880326, -9.36390888],
                            [39.64918958, -9.36226414],
                            [39.64986828, -9.36081228],
                            [39.64918491, -9.35974975],
                            [39.65054356, -9.35752298],
                            [39.6518054, -9.35558651],
                            [39.65189155, -9.35552922],
                            [39.65355537, -9.3544228],
                            [39.65384486, -9.35306836],
                            [39.65394004, -9.35190769],
                            [39.6532479, -9.35176675],
                            [39.65540004, -9.3521747],
                            [39.65548053, -9.35140113],
                            [39.65596506, -9.35023974],
                            [39.65586355, -9.34801565],
                            [39.65527373, -9.3449221],
                            [39.65419634, -9.3413459],
                            [39.65302307, -9.33854353],
                            [39.65253099, -9.3356432],
                            [39.65174961, -9.33419402],
                            [39.65096734, -9.3322613],
                            [39.65131033, -9.33186779],
                            [39.65125346, -9.33072553],
                            [39.65097819, -9.32991007],
                            [39.65092193, -9.3290942],
                            [39.65092011, -9.32811503],
                            [39.6510281, -9.32729886],
                            [39.65097133, -9.326211],
                            [39.65058597, -9.32506935],
                            [39.65052951, -9.32414468],
                            [39.65041829, -9.32322011],
                            [39.65041668, -9.32234975],
                            [39.65036113, -9.32191466],
                            [39.65025032, -9.32120769],
                            [39.65013911, -9.32028312],
                            [39.650247, -9.31941255],
                            [39.65024539, -9.31854218],
                            [39.65007953, -9.31767212],
                            [39.65013227, -9.31658406],
                            [39.65001965, -9.31489792],
                            [39.65018219, -9.31397285],
                            [39.64946785, -9.3125598],
                            [39.64869938, -9.31147323],
                            [39.64886202, -9.31060257],
                            [39.64951808, -9.31011179],
                            [39.64978961, -9.30891453],
                            [39.65033195, -9.30613923],
                            [39.65032934, -9.30472488],
                            [39.65049056, -9.30309264],
                            [39.6503225, -9.30102581],
                            [39.65042807, -9.29890409],
                            [39.64993205, -9.29710985],
                            [39.64950976, -9.29611434],
                            [39.64921726, -9.2954248],
                            [39.64877669, -9.29401125],
                            [39.64858164, -9.29351553],
                            [39.64839168, -9.29303278],
                            [39.64789748, -9.2922177],
                            [39.64756561, -9.29036876],
                            [39.64723355, -9.28841102],
                            [39.647285, -9.28661578],
                            [39.64728444, -9.28631059],
                            [39.64728291, -9.28547342],
                            [39.64651361, -9.28389726],
                            [39.64613004, -9.2820094],
                            [39.64612692, -9.28199402],
                            [39.6451936, -9.28052695],
                            [39.64546443, -9.27894891],
                            [39.64458607, -9.27759053],
                            [39.64458341, -9.27613592],
                            [39.6445819, -9.27530581],
                            [39.64392192, -9.27362065],
                            [39.64391924, -9.27215189],
                            [39.64380798, -9.27117292],
                            [39.64364266, -9.27057484],
                            [39.64385974, -9.26954088],
                            [39.64385786, -9.26850731],
                            [39.64388839, -9.26838115],
                            [39.64429251, -9.26671138],
                            [39.644782, -9.26496975],
                            [39.64527367, -9.26442488],
                            [39.64538206, -9.2638263],
                            [39.64527179, -9.26339131],
                            [39.64312353, -9.26333996],
                            [39.64195416, -9.26247169],
                            [39.64039571, -9.26170081],
                            [39.63854446, -9.2604469],
                            [39.63572059, -9.25948484],
                            [39.63464683, -9.25764929],
                            [39.63328201, -9.25629778],
                            [39.63250246, -9.25571891],
                            [39.63259787, -9.25465494],
                            [39.63240013, -9.25291454],
                            [39.63132641, -9.25107897],
                            [39.63103172, -9.24953215],
                            [39.62927598, -9.24721422],
                            [39.62919104, -9.24698755],
                            [39.62829823, -9.2446048],
                            [39.62897718, -9.2433464],
                            [39.62712503, -9.24151218],
                            [39.62848754, -9.2416065],
                            [39.63014181, -9.2416036],
                            [39.63023826, -9.24111989],
                            [39.62887523, -9.24073544],
                            [39.62663557, -9.23986898],
                            [39.62721789, -9.23899759],
                            [39.62857919, -9.23841495],
                            [39.62974672, -9.2383162],
                            [39.63159456, -9.2377327],
                            [39.63354091, -9.23782598],
                            [39.63441876, -9.23898493],
                            [39.63519793, -9.23937039],
                            [39.63548812, -9.23840279],
                            [39.63675088, -9.23714334],
                            [39.63762439, -9.23588457],
                            [39.637427, -9.23433759],
                            [39.63752031, -9.23211312],
                            [39.63897904, -9.23162699],
                            [39.64014603, -9.23123807],
                            [39.63868416, -9.22998347],
                            [39.63790293, -9.22843751],
                            [39.63809389, -9.22640629],
                            [39.63857624, -9.22408443],
                            [39.6389625, -9.2224397],
                            [39.63847268, -9.2206031],
                            [39.63769026, -9.21838019],
                            [39.63729827, -9.21683354],
                            [39.63846469, -9.21615452],
                            [39.63914441, -9.21537964],
                            [39.63787792, -9.21451151],
                            [39.63670977, -9.21422345],
                            [39.63622049, -9.21267698],
                            [39.63641336, -9.21170955],
                            [39.63670319, -9.21054853],
                            [39.6365072, -9.20977521],
                            [39.63651733, -9.20863886],
                            [39.63622323, -9.20740634],
                            [39.63592951, -9.20639142],
                            [39.63550033, -9.20603177],
                            [39.63665757, -9.20544722],
                            [39.63760482, -9.2046477],
                            [39.63731123, -9.20370531],
                            [39.6369448, -9.20283558],
                            [39.63694337, -9.20203773],
                            [39.63664914, -9.20073268],
                            [39.63642866, -9.19986269],
                            [39.63577034, -9.19899348],
                            [39.63606043, -9.19797752],
                            [39.63656968, -9.19710624],
                            [39.63700635, -9.19645269],
                            [39.63729591, -9.1951466],
                            [39.63743952, -9.19384078],
                            [39.63751029, -9.19260762],
                            [39.63714244, -9.19094004],
                            [39.63684835, -9.18970752],
                            [39.63611579, -9.18811312],
                            [39.63530845, -9.1855034],
                            [39.63501386, -9.18398075],
                            [39.63472042, -9.18311089],
                            [39.63413421, -9.18173381],
                            [39.63398569, -9.18028344],
                            [39.63405725, -9.17948547],
                            [39.63310838, -9.17934207],
                            [39.6298976, -9.17927515],
                            [39.626833, -9.17935301],
                            [39.62231027, -9.18015868],
                            [39.62012161, -9.18024303],
                            [39.61968071, -9.17864004],
                            [39.61923924, -9.17653737],
                            [39.61938128, -9.17428863],
                            [39.61784605, -9.17262303],
                            [39.61638581, -9.1721178],
                            [39.61514409, -9.17139459],
                            [39.6151421, -9.17023408],
                            [39.61462858, -9.16863925],
                            [39.61396975, -9.16740732],
                            [39.61396788, -9.16631935],
                            [39.6141112, -9.16479593],
                            [39.61323286, -9.16320171],
                            [39.6129385, -9.16175157],
                            [39.61235328, -9.16088218],
                            [39.6114767, -9.1603034],
                            [39.61016257, -9.15987043],
                            [39.60906684, -9.15914695],
                            [39.60789803, -9.15835106],
                            [39.60731185, -9.1569014],
                            [39.60680023, -9.15639454],
                            [39.60541401, -9.15646939],
                            [39.60410174, -9.15712437],
                            [39.60271625, -9.15763441],
                            [39.60257179, -9.15850503],
                            [39.60286512, -9.15937493],
                            [39.60286634, -9.16010025],
                            [39.60242879, -9.16024605],
                            [39.60199001, -9.15966652],
                            [39.60147767, -9.15872445],
                            [39.60169449, -9.15749104],
                            [39.60161957, -9.15633065],
                            [39.60139933, -9.15553316],
                            [39.60037673, -9.15488207],
                            [39.59964732, -9.15502834],
                            [39.59899209, -9.15589981],
                            [39.59870193, -9.15691575],
                            [39.59899549, -9.15793071],
                            [39.59928857, -9.15865555],
                            [39.59907125, -9.15959883],
                            [39.59826996, -9.16039801],
                            [39.59790513, -9.16039861],
                            [39.59768514, -9.15974618],
                            [39.59768344, -9.15873074],
                            [39.59738952, -9.15749817],
                            [39.59738795, -9.15655525],
                            [39.59745776, -9.1546693],
                            [39.59716397, -9.15350927],
                            [39.59752637, -9.15205802],
                            [39.59810814, -9.15089654],
                            [39.59774211, -9.15017183],
                            [39.59679273, -9.14966566],
                            [39.59525892, -9.14872527],
                            [39.59467316, -9.14749318],
                            [39.59394353, -9.14749438],
                            [39.59357775, -9.14691472],
                            [39.59306532, -9.1459001],
                            [39.59269762, -9.14415992],
                            [39.59218521, -9.14314531],
                            [39.5918918, -9.14220287],
                            [39.59152471, -9.14082535],
                            [39.59103407, -9.13955499],
                            [39.59147136, -9.13926415],
                            [39.59059295, -9.1375248],
                            [39.58898541, -9.13607677],
                            [39.58781517, -9.13433788],
                            [39.58693715, -9.13281612],
                            [39.58554924, -9.13180291],
                            [39.58510958, -9.1306431],
                            [39.58554415, -9.12868402],
                            [39.58525006, -9.12730637],
                            [39.58481136, -9.12672682],
                            [39.58298407, -9.12469884],
                            [39.58210551, -9.1228144],
                            [39.58064434, -9.12158369],
                            [39.58056939, -9.12035075],
                            [39.58071343, -9.11919],
                            [39.58078451, -9.11802936],
                            [39.58027158, -9.11665206],
                            [39.57954013, -9.1154927],
                            [39.57946554, -9.11447736],
                            [39.57924468, -9.11324466],
                            [39.57865834, -9.11157734],
                            [39.57850998, -9.11005439],
                            [39.57850671, -9.10802348],
                            [39.5780656, -9.10592073],
                            [39.57740679, -9.10454365],
                            [39.57601913, -9.10360292],
                            [39.57492365, -9.10287933],
                            [39.57433874, -9.10208239],
                            [39.57236827, -9.1016503],
                            [39.57271894, -9.10078522],
                            [39.5718421, -9.0999162],
                            [39.57047937, -9.09933807],
                            [39.56814346, -9.09847133],
                            [39.56609847, -9.09702386],
                            [39.56424607, -9.09431883],
                            [39.56181173, -9.09267851],
                            [39.56006011, -9.09219765],
                            [39.55772502, -9.09181439],
                            [39.55500071, -9.091335],
                            [39.55616661, -9.09046282],
                            [39.55412305, -9.08988567],
                            [39.55246694, -9.0882441],
                            [39.55032524, -9.08708681],
                            [39.54876805, -9.08650889],
                            [39.54889399, -9.0863654],
                            [39.5486604, -9.0864581],
                            [39.54842955, -9.08655743],
                            [39.54820162, -9.08666332],
                            [39.54797682, -9.08677567],
                            [39.54775531, -9.08689439],
                            [39.54753728, -9.0870194],
                            [39.54732291, -9.08715057],
                            [39.54711236, -9.08728782],
                            [39.54690583, -9.08743102],
                            [39.54670346, -9.08758007],
                            [39.54650543, -9.08773483],
                            [39.5463119, -9.08789518],
                            [39.54612303, -9.08806099],
                            [39.54593896, -9.08823213],
                            [39.54582815, -9.08831949],
                            [39.54572161, -9.08841202],
                            [39.54561958, -9.0885095],
                            [39.5455223, -9.08861172],
                            [39.54542998, -9.08871844],
                            [39.54534283, -9.08882943],
                            [39.54526104, -9.08894443],
                            [39.54518481, -9.08906319],
                            [39.5451143, -9.08918543],
                            [39.54504967, -9.08931088],
                            [39.54499107, -9.08943926],
                            [39.54493864, -9.08957028],
                            [39.54489248, -9.08970364],
                            [39.5448527, -9.08983905],
                            [39.5448194, -9.08997619],
                            [39.54479264, -9.09011475],
                            [39.5447725, -9.09025444],
                            [39.54478606, -9.09045397],
                            [39.54479291, -9.09065385],
                            [39.54479306, -9.09085384],
                            [39.54478649, -9.09105372],
                            [39.54477321, -9.09125328],
                            [39.54475325, -9.09145227],
                            [39.54472661, -9.09165048],
                            [39.54469334, -9.09184769],
                            [39.54465347, -9.09204367],
                            [39.54460704, -9.09223819],
                            [39.54455411, -9.09243105],
                            [39.54449474, -9.09262203],
                            [39.54442899, -9.0928109],
                            [39.54435693, -9.09299745],
                            [39.54427866, -9.09318149],
                            [39.54419425, -9.09336279],
                            [39.54410381, -9.09354115],
                            [39.54400743, -9.09371638],
                            [39.54390522, -9.09388827],
                            [39.5437973, -9.09405663],
                            [39.54368379, -9.09422127],
                            [39.54368184, -9.09459757],
                            [39.54368585, -9.09497385],
                            [39.54369583, -9.09535002],
                            [39.54371178, -9.09572599],
                            [39.54373368, -9.09610165],
                            [39.54376154, -9.09647692],
                            [39.54205073, -9.09904369],
                            [39.5420453, -9.09930458],
                            [39.54203316, -9.09956523],
                            [39.54201429, -9.09982549],
                            [39.54198873, -9.10008517],
                            [39.54195647, -9.10034411],
                            [39.54191755, -9.10060213],
                            [39.54187199, -9.10085906],
                            [39.54181983, -9.10111473],
                            [39.54176109, -9.10136897],
                            [39.54169581, -9.1016216],
                            [39.54162404, -9.10187248],
                            [39.54154583, -9.10212141],
                            [39.54146123, -9.10236825],
                            [39.54137029, -9.10261282],
                            [39.54127307, -9.10285497],
                            [39.54126448, -9.10294129],
                            [39.54124906, -9.10302666],
                            [39.5412269, -9.10311053],
                            [39.54119815, -9.10319237],
                            [39.54116299, -9.10327167],
                            [39.54112164, -9.10334793],
                            [39.54107437, -9.10342066],
                            [39.54102147, -9.1034894],
                            [39.54096327, -9.10355372],
                            [39.54090014, -9.10361321],
                            [39.5408325, -9.10366749],
                            [39.54076075, -9.10371623],
                            [39.54068535, -9.10375911],
                            [39.54060679, -9.10379586],
                            [39.54052556, -9.10382626],
                            [39.54044217, -9.1038501],
                            [39.54035715, -9.10386723],
                            [39.54027104, -9.10387756],
                            [39.54018438, -9.10388101],
                            [39.54009771, -9.10387756],
                            [39.5400116, -9.10386723],
                            [39.53992658, -9.10385009],
                            [39.53984319, -9.10382625],
                            [39.53976196, -9.10379586],
                            [39.5396834, -9.10375911],
                            [39.539608, -9.10371622],
                            [39.53953626, -9.10366749],
                            [39.53946861, -9.1036132],
                            [39.53940549, -9.10355371],
                            [39.53934729, -9.10348939],
                            [39.53929439, -9.10342065],
                            [39.53924711, -9.10334792],
                            [39.53920576, -9.10327166],
                            [39.5391706, -9.10319236],
                            [39.53914186, -9.10311052],
                            [39.5391197, -9.10302665],
                            [39.53910428, -9.10294128],
                            [39.53909569, -9.10285496],
                            [39.5387379, -9.10276744],
                            [39.53837855, -9.10268658],
                            [39.53801776, -9.10261239],
                            [39.53765566, -9.1025449],
                            [39.53729237, -9.10248414],
                            [39.53692802, -9.10243012],
                            [39.53656273, -9.10238286],
                            [39.53619662, -9.10234239],
                            [39.53582983, -9.10230871],
                            [39.53546248, -9.10228183],
                            [39.5350947, -9.10226177],
                            [39.5347266, -9.10224853],
                            [39.53435832, -9.10224212],
                            [39.53398999, -9.10224253],
                            [39.53362173, -9.10224977],
                            [39.53325366, -9.10226384],
                            [39.53288592, -9.10228473],
                            [39.53251863, -9.10231243],
                            [39.53215192, -9.10234694],
                            [39.53178591, -9.10238823],
                            [39.53125755, -9.10241625],
                            [39.53072891, -9.10243805],
                            [39.53020004, -9.10245362],
                            [39.52967103, -9.10246296],
                            [39.52914194, -9.10246607],
                            [39.52861286, -9.10246295],
                            [39.52808384, -9.1024536],
                            [39.52755498, -9.10243803],
                            [39.52702633, -9.10241623],
                            [39.52649798, -9.10238821],
                            [39.52633396, -9.10234438],
                            [39.52616825, -9.10230746],
                            [39.52600114, -9.10227752],
                            [39.52583292, -9.1022546],
                            [39.52566389, -9.10223875],
                            [39.52549434, -9.10223],
                            [39.52532458, -9.10222837],
                            [39.5251549, -9.10223384],
                            [39.52498559, -9.10224643],
                            [39.52481696, -9.10226609],
                            [39.5246493, -9.1022928],
                            [39.52448291, -9.10232652],
                            [39.52431808, -9.10236717],
                            [39.52415509, -9.1024147],
                            [39.52399424, -9.10246902],
                            [39.5238358, -9.10253003],
                            [39.52368006, -9.10259762],
                            [39.52352729, -9.10267168],
                            [39.52337775, -9.10275207],
                            [39.52323171, -9.10283866],
                            [39.52308942, -9.1029313],
                            [39.52295114, -9.10302981],
                            [39.52281711, -9.10313403],
                            [39.52268755, -9.10324378],
                            [39.52256196, -9.1033947],
                            [39.52244142, -9.10354967],
                            [39.52232605, -9.10370854],
                            [39.52221599, -9.10387113],
                            [39.52211135, -9.10403727],
                            [39.52201224, -9.10420677],
                            [39.52191878, -9.10437944],
                            [39.52183107, -9.10455511],
                            [39.5217492, -9.10473357],
                            [39.52167326, -9.10491464],
                            [39.52160333, -9.10509812],
                            [39.5215395, -9.1052838],
                            [39.52148182, -9.10547149],
                            [39.52143037, -9.10566098],
                            [39.52138519, -9.10585207],
                            [39.52134634, -9.10604454],
                            [39.52131387, -9.10623819],
                            [39.52128779, -9.1064328],
                            [39.52138236, -9.10664937],
                            [39.52148298, -9.10686318],
                            [39.52158957, -9.10707409],
                            [39.52170206, -9.10728191],
                            [39.52182034, -9.10748648],
                            [39.52194432, -9.10768765],
                            [39.52207392, -9.10788525],
                            [39.52220901, -9.10807912],
                            [39.52234951, -9.10826912],
                            [39.52203929, -9.10854407],
                            [39.5229891, -9.10948564],
                            [39.5236474, -9.11064522],
                            [39.52416011, -9.11202262],
                            [39.52474472, -9.11267458],
                            [39.52627587, -9.11201956],
                            [39.52758846, -9.11158246],
                            [39.52780946, -9.11303281],
                            [39.52700874, -9.11426704],
                            [39.52781244, -9.11506374],
                            [39.52970806, -9.11419058],
                            [39.5305091, -9.11317395],
                            [39.53196954, -9.11404221],
                            [39.5321897, -9.11491229],
                            [39.53224526, -9.11564482],
                            [39.53065898, -9.11585746],
                            [39.52890948, -9.11687547],
                            [39.52679497, -9.11774894],
                            [39.52467939, -9.11789707],
                            [39.5238779, -9.11862356],
                            [39.52278542, -9.11993073],
                            [39.5226414, -9.12123654],
                            [39.52125634, -9.1220364],
                            [39.52103925, -9.12326977],
                            [39.52118654, -9.1242125],
                            [39.52104135, -9.12472044],
                            [39.52038514, -9.12501151],
                            [39.51870664, -9.12472378],
                            [39.51717448, -9.12472596],
                            [39.51637296, -9.12545244],
                            [39.51513452, -9.1267598],
                            [39.51272828, -9.12777867],
                            [39.51141633, -9.12872345],
                            [39.51054131, -9.12908735],
                            [39.50769349, -9.12735166],
                            [39.50292163, -9.12445633],
                            [39.49844243, -9.1220442],
                            [39.49669088, -9.12194986],
                            [39.49386731, -9.12059946],
                            [39.48997253, -9.11857335],
                            [39.48588411, -9.11722458],
                            [39.48082169, -9.11510319],
                            [39.47712113, -9.11268975],
                            [39.4730318, -9.11056697],
                            [39.47001456, -9.10979702],
                            [39.46757985, -9.10805896],
                            [39.4644631, -9.1055479],
                            [39.46023615, -9.10188182],
                            [39.45843023, -9.0993848],
                            [39.45349097, -9.09088031],
                            [39.44621414, -9.07967066],
                            [39.4438716, -9.07309707],
                            [39.44178727, -9.06549155],
                            [39.43918022, -9.05453393],
                            [39.43774763, -9.04950659],
                            [39.43792045, -9.04496089],
                            [39.43683567, -9.03258295],
                            [39.43624121, -9.02349266],
                            [39.43925608, -9.02348906],
                            [39.44178491, -9.02367946],
                            [39.44421743, -9.02464365],
                            [39.44703911, -9.02570406],
                            [39.44986056, -9.02657103],
                            [39.45073585, -9.02656996],
                            [39.45151485, -9.0273427],
                            [39.45151701, -9.02908352],
                            [39.45278229, -9.02985567],
                            [39.45395008, -9.0304345],
                            [39.45346562, -9.03188579],
                            [39.45307877, -9.03362709],
                            [39.45473273, -9.03410861],
                            [39.45677694, -9.03555675],
                            [39.45736365, -9.03807055],
                            [39.45853294, -9.03980991],
                            [39.46028457, -9.04058141],
                            [39.46271602, -9.04057835],
                            [39.46456305, -9.03989903],
                            [39.46611731, -9.03844637],
                            [39.46737891, -9.03631709],
                            [39.4689324, -9.03428415],
                            [39.47077913, -9.03341137],
                            [39.47223709, -9.03273251],
                            [39.47447372, -9.0325362],
                            [39.4760293, -9.03214734],
                            [39.47748928, -9.03301585],
                            [39.48108839, -9.03349471],
                            [39.48213671, -9.03359756],
                            [39.48303376, -9.03368558],
                            [39.48536751, -9.03339236],
                            [39.48701891, -9.03193949],
                            [39.48798899, -9.03010067],
                            [39.49012808, -9.02971097],
                            [39.49178232, -9.03038574],
                            [39.49382533, -9.03086656],
                            [39.49460219, -9.0299951],
                            [39.49450335, -9.02883469],
                            [39.49284871, -9.0278698],
                            [39.49294373, -9.02622557],
                            [39.49158074, -9.02516356],
                            [39.49031423, -9.02352115],
                            [39.48992313, -9.02197428],
                            [39.48992091, -9.02033018],
                            [39.49254593, -9.01974639],
                            [39.49575511, -9.01964536],
                            [39.49808677, -9.01790139],
                            [39.50061331, -9.01644728],
                            [39.50304231, -9.01479986],
                            [39.50459576, -9.0129602],
                            [39.50595444, -9.01092738],
                            [39.50760579, -9.00957114],
                            [39.51133829, -9.00872966],
                            [39.51327336, -9.00920789],
                            [39.51601376, -9.00920406],
                            [39.5177865, -9.00888095],
                            [39.51826781, -9.00727718],
                            [39.51955694, -9.00695474],
                            [39.52358714, -9.00710933],
                            [39.5277783, -9.00710334],
                            [39.53032726, -9.00861929],
                            [39.53181177, -9.00950217],
                            [39.53568481, -9.01238211],
                            [39.53616723, -9.01157986],
                            [39.53842118, -9.00965286],
                            [39.53954625, -9.00740689],
                            [39.54179682, -9.00323555],
                            [39.5440483, -8.99970543],
                            [39.54629949, -8.99601499],
                            [39.5499987, -8.99055898],
                            [39.55143923, -8.98382386],
                            [39.55094792, -8.97869471],
                            [39.55045246, -8.9771017],
                            [39.54965217, -8.97452861],
                            [39.54417073, -8.97373518],
                            [39.53852671, -8.97198006],
                            [39.53239687, -8.96862245],
                            [39.53235656, -8.96784332],
                            [39.53223101, -8.9654165],
                            [39.53319131, -8.96076613],
                            [39.53544147, -8.95643453],
                            [39.53688735, -8.95322624],
                            [39.53914001, -8.95065801],
                            [39.54042514, -8.94777057],
                            [39.53929245, -8.94472634],
                            [39.53921999, -8.94460064],
                            [39.53735346, -8.94136265],
                            [39.53348354, -8.94008577],
                            [39.52961387, -8.93896915],
                            [39.52703333, -8.93769035],
                            [39.52622795, -8.93801212],
                            [39.52542624, -8.94089883],
                            [39.52333768, -8.94555078],
                            [39.52188897, -8.9468353],
                            [39.52027975, -8.94860098],
                            [39.51834795, -8.95020679],
                            [39.51512673, -8.95181439],
                            [39.50981043, -8.95358516],
                            [39.50636108, -8.95357655],
                            [39.50441599, -8.95328907],
                            [39.50266459, -8.95242104],
                            [39.50091279, -8.95126287],
                            [39.4989664, -8.95000824],
                            [39.49760645, -8.9509772],
                            [39.49643873, -8.95030178],
                            [39.4945892, -8.94875685],
                            [39.49176763, -8.94740664],
                            [39.48894543, -8.94557284],
                            [39.4858313, -8.94354597],
                            [39.48388526, -8.94248468],
                            [39.48232841, -8.9416163],
                            [39.48086918, -8.94103792],
                            [39.478535, -8.94055738],
                            [39.4770725, -8.93746446],
                            [39.47541683, -8.9353389],
                            [39.47269203, -8.93350485],
                            [39.47103826, -8.93282996],
                            [39.46938512, -8.93263863],
                            [39.46763623, -8.93360797],
                            [39.46627502, -8.93360968],
                            [39.46520574, -8.93380445],
                            [39.46404168, -8.9359336],
                            [39.46316917, -8.93796566],
                            [39.46190785, -8.94009492],
                            [39.46268643, -8.94067422],
                            [39.46414478, -8.94057569],
                            [39.46434035, -8.94144586],
                            [39.46375855, -8.94270385],
                            [39.46220394, -8.94357621],
                            [39.46055148, -8.94396512],
                            [39.45909433, -8.94503077],
                            [39.45851275, -8.94648219],
                            [39.45740001, -8.9473582],
                            [39.45666713, -8.94793516],
                            [39.45414062, -8.94919554],
                            [39.45229545, -8.95103535],
                            [39.45181131, -8.95268006],
                            [39.45142428, -8.95422794],
                            [39.44938282, -8.95461728],
                            [39.44860707, -8.95635905],
                            [39.45123565, -8.95896711],
                            [39.45143168, -8.96022414],
                            [39.45046122, -8.96177273],
                            [39.4489059, -8.96216147],
                            [39.44676668, -8.96216406],
                            [39.44443322, -8.9623603],
                            [39.44288023, -8.96468328],
                            [39.44269021, -8.9683586],
                            [39.44288797, -8.97106633],
                            [39.44318239, -8.97329037],
                            [39.44425462, -8.97541677],
                            [39.44483995, -8.97696347],
                            [39.44387012, -8.97909233],
                            [39.44241384, -8.98102834],
                            [39.44086147, -8.98393158],
                            [39.43911156, -8.98432052],
                            [39.43823439, -8.98267745],
                            [39.43706319, -8.97910045],
                            [39.4371558, -8.97523182],
                            [39.43686003, -8.97184721],
                            [39.43724598, -8.96933222],
                            [39.43753423, -8.96643048],
                            [39.43685101, -8.9643036],
                            [39.43568347, -8.9637247],
                            [39.43451891, -8.96566034],
                            [39.43296344, -8.9659523],
                            [39.43169774, -8.9645998],
                            [39.4298484, -8.96305454],
                            [39.42790206, -8.96170282],
                            [39.42595697, -8.96141492],
                            [39.4245001, -8.9628673],
                            [39.4225568, -8.9641268],
                            [39.41954352, -8.96509736],
                            [39.41750273, -8.96616352],
                            [39.41662725, -8.96587437],
                            [39.41536326, -8.96597251],
                            [39.41400367, -8.96752145],
                            [39.41322784, -8.96935987],
                            [39.41206467, -8.97264942],
                            [39.41119277, -8.9755518],
                            [39.40867035, -8.98077712],
                            [39.40769954, -8.9822289],
                            [39.40633954, -8.98348767],
                            [39.40488035, -8.98300571],
                            [39.40400345, -8.98145926],
                            [39.40234946, -8.98068736],
                            [39.40011129, -8.97923909],
                            [39.39816517, -8.97808063],
                            [39.39709646, -8.97895221],
                            [39.39573349, -8.97750297],
                            [39.3933975, -8.97547448],
                            [39.39329619, -8.97170276],
                            [39.3951402, -8.96841253],
                            [39.39747101, -8.96570205],
                            [39.39873237, -8.96318614],
                            [39.40038288, -8.96086323],
                            [39.40232475, -8.95824985],
                            [39.40183549, -8.95544569],
                            [39.40047397, -8.95525374],
                            [39.3978485, -8.95515986],
                            [39.39609783, -8.95477488],
                            [39.39454205, -8.95477655],
                            [39.39347298, -8.95526125],
                            [39.39181986, -8.95516629],
                            [39.3910397, -8.95303942],
                            [39.3912321, -8.95110494],
                            [39.39249502, -8.95003975],
                            [39.39453549, -8.94868359],
                            [39.39608925, -8.94684437],
                            [39.39735119, -8.94490875],
                            [39.39832174, -8.94326357],
                            [39.39958492, -8.9424885],
                            [39.40026439, -8.94142392],
                            [39.39919293, -8.93968423],
                            [39.39977432, -8.93784604],
                            [39.40045378, -8.93678146],
                            [39.39967382, -8.93484802],
                            [39.40045051, -8.93378333],
                            [39.40044892, -8.93233263],
                            [39.39860071, -8.93156091],
                            [39.39655983, -8.93243352],
                            [39.39471225, -8.93224207],
                            [39.39296211, -8.93224393],
                            [39.39354757, -8.93417758],
                            [39.39461856, -8.93553044],
                            [39.39393898, -8.9364983],
                            [39.39462095, -8.93775485],
                            [39.39481666, -8.93891521],
                            [39.39384497, -8.93949652],
                            [39.39277646, -8.94046479],
                            [39.39161081, -8.94152988],
                            [39.39005426, -8.94075781],
                            [39.3888846, -8.93805106],
                            [39.38781383, -8.93689161],
                            [39.38577167, -8.9366036],
                            [39.38440921, -8.93544445],
                            [39.38285402, -8.93592963],
                            [39.38071614, -8.9370924],
                            [39.38062052, -8.93863992],
                            [39.37955167, -8.93931801],
                            [39.37848281, -8.9399961],
                            [39.37906791, -8.94163964],
                            [39.37877721, -8.94260708],
                            [39.37712475, -8.94309233],
                            [39.37615202, -8.94270647],
                            [39.3740124, -8.94222506],
                            [39.37323513, -8.94280613],
                            [39.37391783, -8.94483644],
                            [39.37566933, -8.94609194],
                            [39.37586578, -8.94802602],
                            [39.37479749, -8.94928439],
                            [39.3748967, -8.95121856],
                            [39.37373077, -8.95209017],
                            [39.37227165, -8.95151136],
                            [39.37275891, -8.95257472],
                            [39.37227342, -8.9532522],
                            [39.37208022, -8.95450968],
                            [39.37091359, -8.95470428],
                            [39.37032909, -8.95364101],
                            [39.36848093, -8.95296586],
                            [39.3677046, -8.95451405],
                            [39.36955296, -8.95538264],
                            [39.37052601, -8.95605866],
                            [39.37004071, -8.95692957],
                            [39.37081958, -8.95789593],
                            [39.37208485, -8.95905523],
                            [39.37062687, -8.95963698],
                            [39.36916929, -8.96060557],
                            [39.36985122, -8.96186217],
                            [39.37170042, -8.96350445],
                            [39.37286856, -8.96476056],
                            [39.37218907, -8.96592181],
                            [39.37073048, -8.96592327],
                            [39.36907752, -8.96602164],
                            [39.36752237, -8.96670019],
                            [39.36849574, -8.96766636],
                            [39.37053797, -8.96785774],
                            [39.3720938, -8.96785618],
                            [39.37316433, -8.96872553],
                            [39.37306906, -8.9706599],
                            [39.37141755, -8.97220898],
                            [39.36957046, -8.97269441],
                            [39.36840269, -8.97182515],
                            [39.36665284, -8.97231046],
                            [39.36675154, -8.97376107],
                            [39.36753132, -8.97559786],
                            [39.36743554, -8.97704866],
                            [39.36588092, -8.97830748],
                            [39.36578494, -8.97956486],
                            [39.36432717, -8.98043673],
                            [39.36403631, -8.98130744],
                            [39.36588451, -8.98188589],
                            [39.36598302, -8.98314308],
                            [39.36734618, -8.98488257],
                            [39.36822275, -8.98623569],
                            [39.36725099, -8.98691365],
                            [39.36618149, -8.98710814],
                            [39.36452842, -8.9872065],
                            [39.36326356, -8.98653075],
                            [39.36209605, -8.98595161],
                            [39.36180297, -8.98459791],
                            [39.36131589, -8.98372796],
                            [39.36005191, -8.98392262],
                            [39.35907908, -8.98353672],
                            [39.35917471, -8.98189249],
                            [39.3598536, -8.98005426],
                            [39.35820028, -8.97986244],
                            [39.35722899, -8.98102395],
                            [39.35596501, -8.9812186],
                            [39.35567206, -8.9799616],
                            [39.3558656, -8.97899428],
                            [39.35557283, -8.97793071],
                            [39.35547399, -8.97628666],
                            [39.35702835, -8.97473774],
                            [39.35586117, -8.97444873],
                            [39.354501, -8.97570732],
                            [39.35343172, -8.9760952],
                            [39.35158375, -8.97571011],
                            [39.35168229, -8.97706401],
                            [39.3508086, -8.97861226],
                            [39.34915603, -8.97919412],
                            [39.34866852, -8.97784058],
                            [39.3474039, -8.9773582],
                            [39.3466256, -8.97697208],
                            [39.34720776, -8.97561754],
                            [39.34876253, -8.9744555],
                            [39.3479833, -8.97310224],
                            [39.34681668, -8.97339348],
                            [39.34594096, -8.97281402],
                            [39.34457903, -8.97223501],
                            [39.34321729, -8.97184942],
                            [39.34204977, -8.9711735],
                            [39.34214592, -8.97001284],
                            [39.34341005, -8.97001167],
                            [39.34622957, -8.96952547],
                            [39.3484661, -8.96952336],
                            [39.34963242, -8.96894198],
                            [39.35002074, -8.96826461],
                            [39.34943702, -8.96797502],
                            [39.34817216, -8.9672025],
                            [39.34865772, -8.96652505],
                            [39.34855937, -8.96536457],
                            [39.34710096, -8.96555937],
                            [39.34632415, -8.96672067],
                            [39.34525506, -8.96730196],
                            [39.34379683, -8.96769017],
                            [39.34282461, -8.9678845],
                            [39.34292076, -8.96672384],
                            [39.34379537, -8.96614275],
                            [39.34505894, -8.96556128],
                            [39.34447477, -8.96478812],
                            [39.34301519, -8.96372562],
                            [39.34359716, -8.96217765],
                            [39.3442772, -8.96150002],
                            [39.34495704, -8.96062896],
                            [39.34622077, -8.96024092],
                            [39.34651349, -8.9613045],
                            [39.34777759, -8.96130331],
                            [39.3477764, -8.96004603],
                            [39.34855301, -8.9586913],
                            [39.34913533, -8.95753018],
                            [39.34874527, -8.95636998],
                            [39.34718875, -8.95559773],
                            [39.34573083, -8.9562761],
                            [39.34407763, -8.95608421],
                            [39.34261744, -8.95434471],
                            [39.34300494, -8.95279693],
                            [39.34426982, -8.95366618],
                            [39.34533988, -8.95414875],
                            [39.34553362, -8.95337486],
                            [39.34670055, -8.95347048],
                            [39.34777005, -8.95337276],
                            [39.3482555, -8.95259859],
                            [39.34776831, -8.95153519],
                            [39.34912942, -8.95134048],
                            [39.349712, -8.95046951],
                            [39.34961283, -8.9484386],
                            [39.3498061, -8.94718114],
                            [39.35136092, -8.94621252],
                            [39.35009586, -8.94514987],
                            [39.34844279, -8.94505471],
                            [39.3469841, -8.94486265],
                            [39.34513684, -8.94505781],
                            [39.34533213, -8.94592805],
                            [39.34718031, -8.94670004],
                            [39.34834804, -8.94766608],
                            [39.34718168, -8.94815075],
                            [39.34611146, -8.94747475],
                            [39.34513966, -8.94805594],
                            [39.34475218, -8.94960373],
                            [39.34368305, -8.9500883],
                            [39.342614, -8.95066957],
                            [39.34115538, -8.95057421],
                            [39.34008552, -8.95028505],
                            [39.33979435, -8.9508656],
                            [39.33979606, -8.95270317],
                            [39.338727, -8.95328444],
                            [39.33707327, -8.95251223],
                            [39.33668575, -8.95406002],
                            [39.33551936, -8.95454465],
                            [39.33503451, -8.9559958],
                            [39.33454947, -8.95725352],
                            [39.33328547, -8.95735138],
                            [39.33270091, -8.95609462],
                            [39.33163121, -8.95599887],
                            [39.33104657, -8.95464539],
                            [39.33260095, -8.95309657],
                            [39.33259963, -8.95164585],
                            [39.33172398, -8.95106635],
                            [39.32958462, -8.95087484],
                            [39.32832056, -8.95087596],
                            [39.32608405, -8.95078122],
                            [39.3262774, -8.94952377],
                            [39.32656799, -8.94826622],
                            [39.32540048, -8.94749354],
                            [39.3266641, -8.94700885],
                            [39.3285113, -8.94671707],
                            [39.32938519, -8.9453623],
                            [39.32850887, -8.94400907],
                            [39.32967507, -8.94333104],
                            [39.3311327, -8.94236259],
                            [39.33404961, -8.94226326],
                            [39.33706402, -8.94245396],
                            [39.3401753, -8.94225768],
                            [39.34085531, -8.94158006],
                            [39.34279924, -8.94080455],
                            [39.34464585, -8.93993241],
                            [39.34717279, -8.93876948],
                            [39.34872713, -8.93731731],
                            [39.35090634, -8.93601877],
                            [39.3560635, -8.93569322],
                            [39.35880199, -8.93424776],
                            [39.36476368, -8.93263881],
                            [39.36814598, -8.93039108],
                            [39.37152889, -8.92878458],
                            [39.37394526, -8.92765996],
                            [39.37539363, -8.92557442],
                            [39.37603649, -8.92381033],
                            [39.37555037, -8.92124581],
                            [39.37683656, -8.91819855],
                            [39.37634979, -8.91499278],
                            [39.37457277, -8.91082643],
                            [39.37473164, -8.90858188],
                            [39.38198377, -8.9085745],
                            [39.38471994, -8.9052051],
                            [39.38697192, -8.90119493],
                            [39.39002911, -8.89670297],
                            [39.39228286, -8.89445622],
                            [39.38841352, -8.89285714],
                            [39.38550978, -8.88997452],
                            [39.38357348, -8.88757182],
                            [39.38034917, -8.88629262],
                            [39.37954227, -8.88517124],
                            [39.38195767, -8.88340533],
                            [39.38502014, -8.88404343],
                            [39.38824393, -8.88484166],
                            [39.39194675, -8.8814712],
                            [39.39081652, -8.87938831],
                            [39.39081449, -8.87746456],
                            [39.39210041, -8.87441725],
                            [39.39322602, -8.87217168],
                            [39.39467322, -8.86928451],
                            [39.39628325, -8.8680003],
                            [39.39877399, -8.86377263],
                            [39.4003298, -8.86415782],
                            [39.40084631, -8.86235194],
                            [39.40097383, -8.86041752],
                            [39.40239804, -8.85899751],
                            [39.40317376, -8.85719135],
                            [39.40433887, -8.85590057],
                            [39.40563345, -8.85448069],
                            [39.40679897, -8.85357676],
                            [39.40770696, -8.85422053],
                            [39.40641325, -8.85641413],
                            [39.40537846, -8.85834953],
                            [39.40499145, -8.86002633],
                            [39.40499315, -8.86157375],
                            [39.40499484, -8.86312117],
                            [39.40408894, -8.86441168],
                            [39.40406676, -8.86477111],
                            [39.40396158, -8.86647505],
                            [39.40513025, -8.86840806],
                            [39.40668538, -8.86814847],
                            [39.40655321, -8.86582747],
                            [39.40719831, -8.86311878],
                            [39.40823309, -8.86118337],
                            [39.40964711, -8.85988193],
                            [39.40991637, -8.8596341],
                            [39.41237789, -8.85859978],
                            [39.41257926, -8.85855119],
                            [39.41613567, -8.85769295],
                            [39.41911371, -8.85498164],
                            [39.42030444, -8.85300213],
                            [39.42105423, -8.85175566],
                            [39.42247966, -8.85149615],
                            [39.42274109, -8.85343013],
                            [39.42274404, -8.85600916],
                            [39.42378315, -8.85794226],
                            [39.42572958, -8.85987433],
                            [39.42793421, -8.86090343],
                            [39.4305274, -8.86167416],
                            [39.43160022, -8.86295194],
                            [39.43182535, -8.86322008],
                            [39.43225961, -8.86323758],
                            [39.43493626, -8.86334542],
                            [39.43830427, -8.86166512],
                            [39.44180432, -8.86204785],
                            [39.44504514, -8.86243086],
                            [39.44893329, -8.86229726],
                            [39.44893342, -8.86229725],
                            [39.4530667, -8.86170471],
                            [39.45346914, -8.86164702],
                            [39.4541443, -8.86143944],
                            [39.4559043, -8.86233717],
                            [39.45714491, -8.86327861],
                            [39.4581676, -8.86487313],
                            [39.45831566, -8.86668632],
                            [39.45766326, -8.86973359],
                            [39.45708528, -8.87401387],
                            [39.45730706, -8.87647979],
                            [39.45854727, -8.87705854],
                            [39.45951106, -8.8767188],
                            [39.45978621, -8.87662181],
                            [39.4601845, -8.87622459],
                            [39.46066005, -8.87575031],
                            [39.4610146, -8.87553501],
                            [39.46233573, -8.87473275],
                            [39.46263173, -8.8746882],
                            [39.4652516, -8.87429391],
                            [39.46663671, -8.87414712],
                            [39.46882485, -8.87479719],
                            [39.47019846, -8.87595758],
                            [39.47134722, -8.87628254],
                            [39.47238689, -8.87682524],
                            [39.47370061, -8.87785719],
                            [39.4746319, -8.87916164],
                            [39.47479791, -8.88068465],
                            [39.47583886, -8.88220656],
                            [39.47731617, -8.88285748],
                            [39.47868397, -8.88339974],
                            [39.47945125, -8.88470438],
                            [39.47999901, -8.88541089],
                            [39.48049287, -8.88671588],
                            [39.48082303, -8.88829308],
                            [39.48142627, -8.88959793],
                            [39.48241166, -8.89041267],
                            [39.48263183, -8.8915004],
                            [39.48301683, -8.89318633],
                            [39.4831282, -8.89470942],
                            [39.48334894, -8.89623236],
                            [39.48395235, -8.897646],
                            [39.483789, -8.89819022],
                            [39.48450121, -8.89916851],
                            [39.48535577, -8.89942886],
                            [39.48592363, -8.89960187],
                            [39.48701678, -8.89916523],
                            [39.48816461, -8.89872852],
                            [39.4882844, -8.89845945],
                            [39.4886008, -8.89774873],
                            [39.488599, -8.89638871],
                            [39.48867449, -8.89573022],
                            [39.48876118, -8.89497407],
                            [39.48892792, -8.89497008],
                            [39.49116727, -8.8949165],
                            [39.490951, -8.89676642],
                            [39.49062477, -8.89818128],
                            [39.48980592, -8.89927037],
                            [39.48898686, -8.90019627],
                            [39.48937118, -8.90133818],
                            [39.49079383, -8.90193472],
                            [39.49227022, -8.90182396],
                            [39.49347274, -8.90138716],
                            [39.49429202, -8.90062446],
                            [39.49554995, -8.90073159],
                            [39.49538897, -8.90301664],
                            [39.495446, -8.9047574],
                            [39.49550391, -8.90715096],
                            [39.49577925, -8.90856501],
                            [39.49649122, -8.90932567],
                            [39.49791221, -8.90867096],
                            [39.49867673, -8.90785393],
                            [39.50015492, -8.90904876],
                            [39.50021123, -8.9102455],
                            [39.50043124, -8.91117002],
                            [39.50015884, -8.911932],
                            [39.49983042, -8.91171483],
                            [39.49961063, -8.91095352],
                            [39.4987892, -8.91013861],
                            [39.49764113, -8.91041215],
                            [39.49739284, -8.91104585],
                            [39.49715063, -8.91166403],
                            [39.49801823, -8.91242347],
                            [39.49808143, -8.91247879],
                            [39.49895748, -8.91323923],
                            [39.49917867, -8.91503416],
                            [39.49983766, -8.9170461],
                            [39.50065874, -8.91758901],
                            [39.5024093, -8.91796745],
                            [39.50388672, -8.91856385],
                            [39.50470848, -8.91959635],
                            [39.50558365, -8.91970396],
                            [39.50585139, -8.92051961],
                            [39.50630364, -8.9212942],
                            [39.50667319, -8.9215793],
                            [39.50692008, -8.92215018],
                            [39.50704369, -8.92255801],
                            [39.50704477, -8.92333322],
                            [39.50696408, -8.92431254],
                            [39.50655509, -8.92516991],
                            [39.50647458, -8.92627164],
                            [39.50647519, -8.92672044],
                            [39.50663955, -8.92692422],
                            [39.50614795, -8.9273737],
                            [39.50565664, -8.92802718],
                            [39.50516516, -8.92855825],
                            [39.50459581, -8.92881876],
                            [39.50418134, -8.9290084],
                            [39.50340262, -8.92945826],
                            [39.50296305, -8.93033573],
                            [39.50291175, -8.93043814],
                            [39.50348745, -8.93149817],
                            [39.50406272, -8.9322318],
                            [39.50488437, -8.9331691],
                            [39.50484397, -8.93361796],
                            [39.5056653, -8.93431045],
                            [39.5062812, -8.93475841],
                            [39.50669217, -8.93532906],
                            [39.50693952, -8.93622633],
                            [39.50722733, -8.93671554],
                            [39.50808906, -8.93695917],
                            [39.50911465, -8.93703936],
                            [39.50944325, -8.93736531],
                            [39.50945708, -8.93740638],
                            [39.50969038, -8.93809938],
                            [39.50993712, -8.93854785],
                            [39.5103468, -8.93818008],
                            [39.51071563, -8.93793477],
                            [39.51149442, -8.93752569],
                            [39.5119855, -8.936709],
                            [39.5119834, -8.93519939],
                            [39.51173649, -8.93462852],
                            [39.51222786, -8.93401584],
                            [39.51280189, -8.93385184],
                            [39.51308833, -8.93336184],
                            [39.51329228, -8.93254555],
                            [39.51320956, -8.93205605],
                            [39.51288039, -8.9313221],
                            [39.51259212, -8.93050649],
                            [39.51259104, -8.92973128],
                            [39.51279527, -8.92911899],
                            [39.51304064, -8.92858825],
                            [39.51316175, -8.92720086],
                            [39.51361129, -8.92601703],
                            [39.51414332, -8.92515948],
                            [39.51434731, -8.92483067],
                            [39.51467534, -8.92430193],
                            [39.51553465, -8.92283192],
                            [39.51603968, -8.92238241],
                            [39.51699272, -8.92195268],
                            [39.51800772, -8.92182887],
                            [39.51896145, -8.92188873],
                            [39.51933165, -8.92262263],
                            [39.52025566, -8.92341694],
                            [39.52130264, -8.92414988],
                            [39.52213476, -8.92521972],
                            [39.52287421, -8.92601429],
                            [39.52401331, -8.92662469],
                            [39.52478217, -8.9264706],
                            [39.52588987, -8.92662203],
                            [39.52761242, -8.92649719],
                            [39.52896552, -8.92615866],
                            [39.52994918, -8.92563705],
                            [39.53053297, -8.92514661],
                            [39.53142492, -8.92502293],
                            [39.53277876, -8.92520459],
                            [39.53397829, -8.92504987],
                            [39.53520895, -8.9251399],
                            [39.53647036, -8.92522987],
                            [39.53763958, -8.92538118],
                            [39.53874709, -8.92541018],
                            [39.53911589, -8.92516484],
                            [39.53945329, -8.92449114],
                            [39.54071385, -8.92399971],
                            [39.54212812, -8.92344684],
                            [39.54323513, -8.92313922],
                            [39.54501882, -8.9227694],
                            [39.5465257, -8.92243058],
                            [39.54787903, -8.92227559],
                            [39.54867872, -8.9221826],
                            [39.54993907, -8.92156874],
                            [39.55008316, -8.92149339],
                            [39.55123009, -8.92089362],
                            [39.5520593, -8.91997438],
                            [39.5528279, -8.91966723],
                            [39.5533195, -8.9192687],
                            [39.55338061, -8.91899321],
                            [39.55301114, -8.91877955],
                            [39.55251866, -8.91859669],
                            [39.55196416, -8.91807731],
                            [39.55153266, -8.91752714],
                            [39.55113188, -8.91694634],
                            [39.55048454, -8.91605989],
                            [39.55026761, -8.9149892],
                            [39.55005017, -8.91358191],
                            [39.54992542, -8.91244989],
                            [39.54967869, -8.91202185],
                            [39.54937029, -8.91150211],
                            [39.54915437, -8.91110462],
                            [39.54881462, -8.91018712],
                            [39.54816749, -8.90942307],
                            [39.54721274, -8.90865947],
                            [39.54604294, -8.90807978],
                            [39.54462768, -8.90792886],
                            [39.54367435, -8.90811385],
                            [39.5422908, -8.90860548],
                            [39.54109092, -8.90848482],
                            [39.53804405, -8.90747944],
                            [39.53496537, -8.90573966],
                            [39.53096348, -8.90372578],
                            [39.52680744, -8.90143666],
                            [39.52443705, -8.90018539],
                            [39.52212855, -8.89917883],
                            [39.51994267, -8.89786607],
                            [39.51827892, -8.89597117],
                            [39.51716922, -8.89432029],
                            [39.51673698, -8.89318867],
                            [39.51578076, -8.89129277],
                            [39.51436301, -8.8893057],
                            [39.51346773, -8.88698131],
                            [39.51266457, -8.88453438],
                            [39.51164653, -8.88239374],
                            [39.5111523, -8.880895],
                            [39.51084033, -8.87933399],
                            [39.51154346, -8.87938484],
                            [39.51241794, -8.87905723],
                            [39.51296386, -8.87840367],
                            [39.51427602, -8.87823866],
                            [39.51536915, -8.87785635],
                            [39.51487602, -8.87714982],
                            [39.51356227, -8.87617242],
                            [39.51263176, -8.87552089],
                            [39.51252149, -8.87486823],
                            [39.5131773, -8.87459532],
                            [39.51427019, -8.87404981],
                            [39.51377631, -8.87279927],
                            [39.51344663, -8.87165731],
                            [39.51344542, -8.8707869],
                            [39.51410031, -8.86986118],
                            [39.51382607, -8.86926315],
                            [39.51398883, -8.86833811],
                            [39.5144796, -8.86735822],
                            [39.51502452, -8.86599745],
                            [39.51463903, -8.86403955],
                            [39.51348831, -8.86230031],
                            [39.51288547, -8.86132192],
                            [39.51261048, -8.86017988],
                            [39.51321038, -8.85903664],
                            [39.51353657, -8.85767617],
                            [39.51211309, -8.8563725],
                            [39.50986897, -8.85474354],
                            [39.50910129, -8.85316696],
                            [39.50926256, -8.8511539],
                            [39.50876828, -8.84957695],
                            [39.5067991, -8.84903561],
                            [39.50506993, -8.84851208],
                            [39.5047741, -8.84541766],
                            [39.5038002, -8.844065],
                            [39.50253496, -8.84290614],
                            [39.50126933, -8.84145714],
                            [39.50039201, -8.83962077],
                            [39.50058328, -8.83729941],
                            [39.50048099, -8.83352774],
                            [39.49960226, -8.83062752],
                            [39.49882231, -8.82888772],
                            [39.49852824, -8.82705057],
                            [39.49842831, -8.82501973],
                            [39.50027447, -8.8245337],
                            [39.50124725, -8.82511268],
                            [39.50192948, -8.82646575],
                            [39.50261211, -8.82810896],
                            [39.50309812, -8.82810831],
                            [39.50338815, -8.82694736],
                            [39.50425889, -8.82394809],
                            [39.50386694, -8.82162751],
                            [39.50347565, -8.81979049],
                            [39.50357023, -8.8178561],
                            [39.50454024, -8.8164041],
                            [39.50619129, -8.81543475],
                            [39.50638477, -8.8147575],
                            [39.50560692, -8.81456512],
                            [39.50445665, -8.81449145],
                            [39.50203821, -8.81501049],
                            [39.50048467, -8.8162161],
                            [39.49754735, -8.81639194],
                            [39.49650943, -8.81553364],
                            [39.49564338, -8.81398738],
                            [39.4956388, -8.81054869],
                            [39.49684331, -8.80676454],
                            [39.49683941, -8.80384166],
                            [39.49562507, -8.80023264],
                            [39.49510441, -8.79851398],
                            [39.49372279, -8.7990316],
                            [39.49199672, -8.80040934],
                            [39.48871507, -8.80144522],
                            [39.48526062, -8.8024813],
                            [39.48405444, -8.80506188],
                            [39.48353919, -8.80746963],
                            [39.48232986, -8.80764312],
                            [39.47973752, -8.80730257],
                            [39.47818349, -8.80816422],
                            [39.47714894, -8.80988489],
                            [39.47570515, -8.81251947],
                            [39.47415109, -8.81338111],
                            [39.47259571, -8.81321113],
                            [39.47121182, -8.81200933],
                            [39.4698275, -8.81046365],
                            [39.47017027, -8.80822806],
                            [39.4718958, -8.80633461],
                            [39.47103053, -8.80530409],
                            [39.4693381, -8.80347044],
                            [39.46907631, -8.80140754],
                            [39.46751909, -8.79973311],
                            [39.46544352, -8.79805931],
                            [39.46414487, -8.79586874],
                            [39.46284544, -8.79303341],
                            [39.46089872, -8.79071467],
                            [39.4588245, -8.79007244],
                            [39.4568813, -8.79059061],
                            [39.45364219, -8.79111033],
                            [39.45130974, -8.79124209],
                            [39.44949299, -8.78918103],
                            [39.44819494, -8.78737725],
                            [39.44684102, -8.78367516],
                            [39.44678492, -8.7824784],
                            [39.4464541, -8.78013952],
                            [39.44656071, -8.77785452],
                            [39.44644793, -8.77497137],
                            [39.44611608, -8.77176207],
                            [39.44545599, -8.76833554],
                            [39.44501683, -8.76681282],
                            [39.44304682, -8.76512868],
                            [39.44173379, -8.76425979],
                            [39.44151345, -8.76284561],
                            [39.44058092, -8.76012661],
                            [39.43997646, -8.75746163],
                            [39.43937245, -8.75517746],
                            [39.43887785, -8.75294756],
                            [39.43789324, -8.75240469],
                            [39.43696336, -8.75191615],
                            [39.43630618, -8.75088327],
                            [39.435649, -8.7498504],
                            [39.43488174, -8.74816482],
                            [39.43438749, -8.74620693],
                            [39.43340083, -8.74386878],
                            [39.4325247, -8.74256414],
                            [39.43137514, -8.741151],
                            [39.43011688, -8.740282],
                            [39.42836716, -8.73984877],
                            [39.42689137, -8.73995924],
                            [39.42601664, -8.73985142],
                            [39.42541497, -8.73952569],
                            [39.42448533, -8.73920032],
                            [39.42366507, -8.73892923],
                            [39.42322697, -8.7382225],
                            [39.42366433, -8.73827641],
                            [39.42432072, -8.73865649],
                            [39.42535963, -8.73892733],
                            [39.42607074, -8.73936175],
                            [39.42689069, -8.73936082],
                            [39.42689044, -8.73914322],
                            [39.42661682, -8.73887152],
                            [39.42486661, -8.73800305],
                            [39.42322642, -8.73773288],
                            [39.4215864, -8.7376259],
                            [39.42104026, -8.73806173],
                            [39.41945558, -8.7385531],
                            [39.41787108, -8.73920768],
                            [39.41710676, -8.74007895],
                            [39.41672189, -8.74010658],
                            [39.41640675, -8.74030665],
                            [39.41614904, -8.74059226],
                            [39.41566184, -8.74076399],
                            [39.41517455, -8.74085012],
                            [39.4146872, -8.74087919],
                            [39.41434313, -8.74085103],
                            [39.41376952, -8.74065193],
                            [39.41342542, -8.74059524],
                            [39.41290918, -8.74042461],
                            [39.41256502, -8.74031086],
                            [39.4123356, -8.74025404],
                            [39.41202017, -8.74019732],
                            [39.41158985, -8.73994099],
                            [39.41118775, -8.73928518],
                            [39.41136008, -8.73957032],
                            [39.41081544, -8.73900894],
                            [39.40986755, -8.73864729],
                            [39.40891974, -8.73835817],
                            [39.40840923, -8.73806858],
                            [39.40746213, -8.73843228],
                            [39.40615022, -8.73843369],
                            [39.40469143, -8.73741975],
                            [39.40330586, -8.73669586],
                            [39.40184748, -8.73604459],
                            [39.39958738, -8.73539415],
                            [39.39798302, -8.73452541],
                            [39.39659731, -8.73365643],
                            [39.39491969, -8.73242507],
                            [39.39360734, -8.73199121],
                            [39.39231959, -8.73221809],
                            [39.39064162, -8.73062403],
                            [39.38830696, -8.72830527],
                            [39.38604433, -8.72511599],
                            [39.38407399, -8.72265175],
                            [39.38217827, -8.72185577],
                            [39.38013634, -8.7206247],
                            [39.37685582, -8.71975754],
                            [39.37602728, -8.71924819],
                            [39.37532438, -8.71881608],
                            [39.37233462, -8.71715069],
                            [39.37225582, -8.71710878],
                            [39.37029289, -8.71606464],
                            [39.36825139, -8.71519619],
                            [39.36650156, -8.71447252],
                            [39.36498043, -8.71363694],
                            [39.36658258, -8.71240227],
                            [39.3668724, -8.71066112],
                            [39.36687007, -8.70826742],
                            [39.36650426, -8.70681704],
                            [39.36664691, -8.7036253],
                            [39.36649699, -8.69934579],
                            [39.36503685, -8.69666335],
                            [39.36262907, -8.69369165],
                            [39.35971029, -8.68977745],
                            [39.35832332, -8.68731251],
                            [39.35795551, -8.68368603],
                            [39.35642241, -8.680786],
                            [39.35496037, -8.67592741],
                            [39.35459227, -8.67193823],
                            [39.35437107, -8.66918204],
                            [39.35385602, -8.66388734],
                            [39.35436339, -8.6609854],
                            [39.35457956, -8.65837388],
                            [39.3554514, -8.65561668],
                            [39.35595903, -8.65300488],
                            [39.35646713, -8.65090084],
                            [39.35748461, -8.64807096],
                            [39.35770191, -8.64669256],
                            [39.35872054, -8.6450958],
                            [39.3593749, -8.64357191],
                            [39.35922765, -8.64197624],
                            [39.35944446, -8.64009008],
                            [39.36119151, -8.63827502],
                            [39.3609714, -8.63667942],
                            [39.35907506, -8.6347227],
                            [39.36009337, -8.63283579],
                            [39.36089364, -8.63152938],
                            [39.36023697, -8.63058702],
                            [39.36045425, -8.62920861],
                            [39.36096292, -8.6277574],
                            [39.3618348, -8.62514525],
                            [39.36380091, -8.62391027],
                            [39.3633629, -8.62304024],
                            [39.36234124, -8.62137286],
                            [39.36197548, -8.61984993],
                            [39.36153576, -8.61716648],
                            [39.36146007, -8.61419255],
                            [39.36094806, -8.61208946],
                            [39.35985275, -8.60955169],
                            [39.35766457, -8.60701495],
                            [39.3565689, -8.60404195],
                            [39.35591256, -8.60338973],
                            [39.35467302, -8.60237535],
                            [39.35306825, -8.6003458],
                            [39.35204589, -8.59780794],
                            [39.35080431, -8.59454491],
                            [39.35021866, -8.59149889],
                            [39.34904977, -8.58801817],
                            [39.34889975, -8.5833034],
                            [39.34802364, -8.58127315],
                            [39.34787879, -8.58221626],
                            [39.34715117, -8.58323244],
                            [39.34649521, -8.58294288],
                            [39.34489293, -8.58352462],
                            [39.34365478, -8.58396094],
                            [39.34360209, -8.58486656],
                            [39.34358304, -8.58519414],
                            [39.34424017, -8.58678937],
                            [39.34336741, -8.5884585],
                            [39.3423482, -8.58932985],
                            [39.34169784, -8.588899],
                            [39.34103602, -8.58846057],
                            [39.33979669, -8.58759122],
                            [39.33870269, -8.58628652],
                            [39.33870089, -8.58425548],
                            [39.33899058, -8.58229672],
                            [39.33804199, -8.5806292],
                            [39.3361465, -8.57918011],
                            [39.33454212, -8.57736808],
                            [39.33446564, -8.57323352],
                            [39.33329835, -8.57134857],
                            [39.33234982, -8.56968103],
                            [39.33234799, -8.56757745],
                            [39.33045215, -8.56562057],
                            [39.32913944, -8.56402587],
                            [39.3285557, -8.56293831],
                            [39.32767849, -8.56210034],
                            [39.32808758, -8.56128395],
                            [39.32796356, -8.56001919],
                            [39.32796258, -8.55887673],
                            [39.32771563, -8.55761207],
                            [39.32734613, -8.55679633],
                            [39.32652591, -8.55602179],
                            [39.32558324, -8.55581857],
                            [39.32459969, -8.55573779],
                            [39.32373864, -8.55512648],
                            [39.3231646, -8.55471894],
                            [39.32218086, -8.55439334],
                            [39.32181117, -8.55333279],
                            [39.32107329, -8.55300698],
                            [39.32017168, -8.55288532],
                            [39.31877854, -8.55300888],
                            [39.31832724, -8.55235642],
                            [39.31722057, -8.55203091],
                            [39.31672796, -8.55097045],
                            [39.31582602, -8.55044076],
                            [39.31484226, -8.55007434],
                            [39.31455602, -8.55080902],
                            [39.31443396, -8.55186997],
                            [39.31361464, -8.55215626],
                            [39.31291778, -8.5518712],
                            [39.31197569, -8.55236159],
                            [39.31091054, -8.55268887],
                            [39.31033662, -8.55240371],
                            [39.30963977, -8.55211866],
                            [39.30984393, -8.55122084],
                            [39.31049891, -8.55040427],
                            [39.31168699, -8.5500769],
                            [39.312711, -8.54954564],
                            [39.31291529, -8.54881103],
                            [39.31275091, -8.54823993],
                            [39.31229966, -8.54762826],
                            [39.31172558, -8.5471391],
                            [39.31156128, -8.5466496],
                            [39.31225759, -8.54628182],
                            [39.31279009, -8.54603658],
                            [39.31278973, -8.54558775],
                            [39.3118879, -8.54518046],
                            [39.31119109, -8.5449362],
                            [39.31024869, -8.54501856],
                            [39.30996231, -8.54559003],
                            [39.30971658, -8.54575343],
                            [39.30959329, -8.5453047],
                            [39.30987967, -8.54473324],
                            [39.30991999, -8.54391716],
                            [39.30975568, -8.54342767],
                            [39.3089768, -8.54302027],
                            [39.30819804, -8.54277607],
                            [39.3090995, -8.54273455],
                            [39.30996001, -8.54273386],
                            [39.31041059, -8.54252949],
                            [39.31020511, -8.54179521],
                            [39.30971283, -8.54110197],
                            [39.3093026, -8.54053106],
                            [39.30909661, -8.53914395],
                            [39.30880928, -8.53853214],
                            [39.3079077, -8.53841045],
                            [39.30708847, -8.53877832],
                            [39.30667965, -8.53996192],
                            [39.30704903, -8.54069607],
                            [39.30688584, -8.54159385],
                            [39.30602575, -8.54212496],
                            [39.30541081, -8.54175822],
                            [39.304632, -8.54143242],
                            [39.30422262, -8.54192237],
                            [39.30385418, -8.54237148],
                            [39.30377291, -8.5432284],
                            [39.30356883, -8.54424862],
                            [39.30315964, -8.54498338],
                            [39.30373364, -8.54539095],
                            [39.30467566, -8.54481898],
                            [39.30496211, -8.54432913],
                            [39.30557689, -8.54449185],
                            [39.3059052, -8.54510363],
                            [39.3057007, -8.54559342],
                            [39.30516826, -8.54592025],
                            [39.30447214, -8.54653284],
                            [39.30385835, -8.54763498],
                            [39.30320349, -8.54861475],
                            [39.30238407, -8.5487786],
                            [39.30131821, -8.5482082],
                            [39.30082571, -8.54722933],
                            [39.30057976, -8.54710711],
                            [39.29980138, -8.54735253],
                            [39.29935133, -8.54825053],
                            [39.29943446, -8.54976016],
                            [39.299271, -8.55033151],
                            [39.29865653, -8.5505768],
                            [39.29796013, -8.55086296],
                            [39.2977149, -8.55167919],
                            [39.29771585, -8.55290326],
                            [39.29718408, -8.55412774],
                            [39.29669279, -8.55469935],
                            [39.29583276, -8.55535285],
                            [39.29562818, -8.55576103],
                            [39.29612045, -8.55645429],
                            [39.29657147, -8.55678037],
                            [39.29698157, -8.55718808],
                            [39.29669494, -8.55747391],
                            [39.29599821, -8.55735204],
                            [39.2952194, -8.55706702],
                            [39.29431807, -8.55731252],
                            [39.29407308, -8.55845517],
                            [39.29382783, -8.55927141],
                            [39.29345937, -8.55972051],
                            [39.29313191, -8.56021039],
                            [39.29334898, -8.56103631],
                            [39.2933785, -8.56114866],
                            [39.29370699, -8.56200526],
                            [39.29350257, -8.56261745],
                            [39.29329808, -8.56314803],
                            [39.29342167, -8.56400479],
                            [39.2944875, -8.56449361],
                            [39.29502039, -8.56469721],
                            [39.29579962, -8.56551266],
                            [39.29625061, -8.56579793],
                            [39.29637431, -8.56677709],
                            [39.29678458, -8.56738881],
                            [39.29715371, -8.56779655],
                            [39.2971545, -8.56881661],
                            [39.29727811, -8.56967336],
                            [39.29756528, -8.57008117],
                            [39.2980984, -8.57057038],
                            [39.29846753, -8.57097812],
                            [39.29871395, -8.57167157],
                            [39.29904224, -8.57224255],
                            [39.29941147, -8.57277269],
                            [39.29908405, -8.57330338],
                            [39.29863371, -8.57387496],
                            [39.29773244, -8.57424287],
                            [39.29752738, -8.57403902],
                            [39.29756791, -8.57346776],
                            [39.29703464, -8.57277453],
                            [39.29650161, -8.57240772],
                            [39.29633696, -8.57146939],
                            [39.29637719, -8.5704901],
                            [39.296172, -8.57012304],
                            [39.29535244, -8.57016447],
                            [39.295352, -8.56959324],
                            [39.29453206, -8.56914504],
                            [39.29387604, -8.56869671],
                            [39.29350849, -8.56934848],
                            [39.29350773, -8.56934983],
                            [39.29268751, -8.56853441],
                            [39.29219598, -8.5688204],
                            [39.29236049, -8.56959552],
                            [39.29149973, -8.56935135],
                            [39.29059842, -8.56967845],
                            [39.29081991, -8.57041153],
                            [39.29084492, -8.57049431],
                            [39.29035357, -8.57102511],
                            [39.28978028, -8.57159678],
                            [39.29031389, -8.57273884],
                            [39.28986348, -8.57322881],
                            [39.29002799, -8.57400393],
                            [39.28994649, -8.57461603],
                            [39.28941393, -8.57486124],
                            [39.28851212, -8.5745355],
                            [39.28843065, -8.57518839],
                            [39.28851329, -8.57608598],
                            [39.28814493, -8.57669829],
                            [39.28773519, -8.57678021],
                            [39.28716115, -8.57637261],
                            [39.28670994, -8.57580171],
                            [39.28686929, -8.57548381],
                            [39.28716005, -8.57490373],
                            [39.2873233, -8.57400595],
                            [39.28670847, -8.5738432],
                            [39.28642241, -8.57490427],
                            [39.28588991, -8.57523109],
                            [39.28519306, -8.57498679],
                            [39.28474228, -8.57498712],
                            [39.28430116, -8.57540152],
                            [39.28367416, -8.57607882],
                            [39.28342761, -8.57634515],
                            [39.28284462, -8.57612796],
                            [39.28298958, -8.57511233],
                            [39.28299032, -8.57510712],
                            [39.28320701, -8.57358888],
                            [39.28327917, -8.57264584],
                            [39.28356993, -8.57177518],
                            [39.28371489, -8.57075955],
                            [39.28393253, -8.56952625],
                            [39.28385909, -8.56872839],
                            [39.28371268, -8.56778551],
                            [39.2839307, -8.56705997],
                            [39.2833474, -8.56640756],
                            [39.28392973, -8.5657543],
                            [39.28414759, -8.56481115],
                            [39.28494842, -8.56408518],
                            [39.2838557, -8.56415852],
                            [39.28385495, -8.563143],
                            [39.28356279, -8.56212769],
                            [39.2825433, -8.56270873],
                            [39.28152338, -8.56270948],
                            [39.2805762, -8.56256509],
                            [39.27897336, -8.56242118],
                            [39.27831707, -8.5615512],
                            [39.27824369, -8.56082588],
                            [39.27758745, -8.56002844],
                            [39.27780495, -8.55857753],
                            [39.27743986, -8.55741719],
                            [39.27816821, -8.55719905],
                            [39.27969785, -8.5569078],
                            [39.27926022, -8.55618274],
                            [39.27802103, -8.5551681],
                            [39.2791132, -8.5543694],
                            [39.28093434, -8.55422301],
                            [39.28290081, -8.55356873],
                            [39.28406586, -8.5528425],
                            [39.28530366, -8.55197114],
                            [39.28617742, -8.5513902],
                            [39.28530296, -8.55102815],
                            [39.28501086, -8.55008538],
                            [39.28581123, -8.54877911],
                            [39.28712169, -8.54769008],
                            [39.28843198, -8.54638343],
                            [39.28872239, -8.54507753],
                            [39.28923129, -8.54369894],
                            [39.29039598, -8.54253746],
                            [39.29105034, -8.54086861],
                            [39.29141199, -8.53745907],
                            [39.29162871, -8.53506516],
                            [39.29257461, -8.5336137],
                            [39.29461335, -8.53237901],
                            [39.29592362, -8.53114487],
                            [39.29599557, -8.52998422],
                            [39.29519344, -8.52889676],
                            [39.29504669, -8.52751866],
                            [39.29497262, -8.52592289],
                            [39.29562683, -8.52410895],
                            [39.29540713, -8.52258583],
                            [39.29438676, -8.52186123],
                            [39.2933669, -8.52178946],
                            [39.29292945, -8.52128203],
                            [39.2925649, -8.52084708],
                            [39.29176351, -8.5207026],
                            [39.29030642, -8.52041355],
                            [39.28943165, -8.51954375],
                            [39.28913968, -8.51874605],
                            [39.28826518, -8.51823893],
                            [39.28753675, -8.51823947],
                            [39.28804611, -8.51751372],
                            [39.28862803, -8.51642522],
                            [39.28884558, -8.51511938],
                            [39.28804356, -8.51410445],
                            [39.28600371, -8.51374327],
                            [39.28593027, -8.51294541],
                            [39.28505483, -8.5111326],
                            [39.28483496, -8.50931932],
                            [39.28497936, -8.50757831],
                            [39.28526981, -8.50634495],
                            [39.28541458, -8.50511171],
                            [39.28599622, -8.50366052],
                            [39.28701502, -8.50235409],
                            [39.28672339, -8.50199162],
                            [39.28635779, -8.5001059],
                            [39.28693937, -8.49858218],
                            [39.28788504, -8.49691311],
                            [39.28824793, -8.49517194],
                            [39.28853842, -8.49401112],
                            [39.28875585, -8.4925602],
                            [39.28926436, -8.49074638],
                            [39.28991827, -8.48856976],
                            [39.29035338, -8.48603061],
                            [39.29035229, -8.48457985],
                            [39.28969595, -8.48349227],
                            [39.28984108, -8.48276679],
                            [39.290715, -8.48262106],
                            [39.29064151, -8.48175066],
                            [39.28991217, -8.48044552],
                            [39.28969296, -8.47950269],
                            [39.28925486, -8.47805226],
                            [39.2889626, -8.47681933],
                            [39.28845183, -8.47558657],
                            [39.28808658, -8.47413608],
                            [39.28750352, -8.47362874],
                            [39.28699454, -8.47478973],
                            [39.2866308, -8.4753703],
                            [39.28531972, -8.47529872],
                            [39.2839919, -8.47492194],
                            [39.28378986, -8.47486461],
                            [39.28378954, -8.47442938],
                            [39.28443689, -8.47404191],
                            [39.28488158, -8.47377574],
                            [39.2859741, -8.47377494],
                            [39.28626507, -8.47326697],
                            [39.28611838, -8.47188885],
                            [39.28524378, -8.47109158],
                            [39.28276753, -8.47123845],
                            [39.28336528, -8.47021316],
                            [39.28288183, -8.46949207],
                            [39.28263941, -8.46816962],
                            [39.28191433, -8.46720823],
                            [39.28215474, -8.46576519],
                            [39.28239532, -8.46456263],
                            [39.28268539, -8.46397625],
                            [39.28200532, -8.46262406],
                            [39.28220835, -8.46167703],
                            [39.28180041, -8.46100098],
                            [39.2816639, -8.46005419],
                            [39.28118815, -8.45951346],
                            [39.28064434, -8.45876987],
                            [39.28071157, -8.45782294],
                            [39.28132254, -8.45755197],
                            [39.28084655, -8.45667306],
                            [39.28050603, -8.45532062],
                            [39.28036934, -8.45410329],
                            [39.28009727, -8.45349478],
                            [39.27935019, -8.45336004],
                            [39.27880659, -8.45288699],
                            [39.2782626, -8.45187286],
                            [39.27785462, -8.45112917],
                            [39.27663167, -8.45025079],
                            [39.27642766, -8.44984513],
                            [39.27588426, -8.44964261],
                            [39.27554444, -8.44923704],
                            [39.27527224, -8.44842562],
                            [39.27508453, -8.44736113],
                            [39.27589945, -8.44742819],
                            [39.27698605, -8.44756269],
                            [39.2777337, -8.44850905],
                            [39.27868415, -8.4481702],
                            [39.27915882, -8.44722298],
                            [39.27942957, -8.44600536],
                            [39.27997296, -8.44620788],
                            [39.27997355, -8.44701949],
                            [39.28010999, -8.44789864],
                            [39.2806532, -8.44783062],
                            [39.28078847, -8.44708654],
                            [39.28099156, -8.44620715],
                            [39.28126249, -8.44526007],
                            [39.28166949, -8.44465107],
                            [39.28268724, -8.44350055],
                            [39.28275589, -8.44451502],
                            [39.28298094, -8.44546457],
                            [39.28302835, -8.4456646],
                            [39.28384376, -8.44640799],
                            [39.28465894, -8.44681321],
                            [39.28581304, -8.44640656],
                            [39.28649221, -8.44654133],
                            [39.28665973, -8.44670794],
                            [39.28730768, -8.44735235],
                            [39.28717277, -8.44856987],
                            [39.2864951, -8.45046413],
                            [39.28608826, -8.45127604],
                            [39.28588583, -8.45303468],
                            [39.28697265, -8.45343969],
                            [39.28778714, -8.45289802],
                            [39.28907647, -8.45167965],
                            [39.28948331, -8.45086774],
                            [39.29077314, -8.4503257],
                            [39.29206342, -8.45039238],
                            [39.29274284, -8.45086531],
                            [39.29376216, -8.45181143],
                            [39.29403354, -8.45147305],
                            [39.29369298, -8.45012062],
                            [39.29308044, -8.44829495],
                            [39.29253591, -8.4466045],
                            [39.29117449, -8.44220927],
                            [39.29062907, -8.4393014],
                            [39.29137522, -8.43821869],
                            [39.29184943, -8.43673038],
                            [39.29273056, -8.43456542],
                            [39.29524055, -8.43131709],
                            [39.29843004, -8.42874456],
                            [39.30284216, -8.42671215],
                            [39.30528634, -8.42630445],
                            [39.30684719, -8.42515344],
                            [39.30779633, -8.42325894],
                            [39.30833713, -8.42021496],
                            [39.30826704, -8.41744201],
                            [39.30846865, -8.41480411],
                            [39.30975774, -8.41351804],
                            [39.31165825, -8.41263729],
                            [39.31097882, -8.41209675],
                            [39.30982446, -8.41203003],
                            [39.30860253, -8.41236916],
                            [39.30772052, -8.4132491],
                            [39.3066347, -8.41399393],
                            [39.30554845, -8.41419768],
                            [39.30364756, -8.41460496],
                            [39.30127088, -8.41440389],
                            [39.29984486, -8.41426971],
                            [39.29930213, -8.41487883],
                            [39.29977769, -8.41521664],
                            [39.30059245, -8.41514838],
                            [39.30181503, -8.41562089],
                            [39.30324126, -8.4160256],
                            [39.30473576, -8.41690369],
                            [39.30555121, -8.41771467],
                            [39.3058234, -8.41845844],
                            [39.30507834, -8.42082622],
                            [39.30345065, -8.42332996],
                            [39.30250049, -8.4239394],
                            [39.30159462, -8.4239401],
                            [39.30114245, -8.42394044],
                            [39.30087042, -8.42339958],
                            [39.30176121, -8.42310306],
                            [39.30209235, -8.42299283],
                            [39.30249834, -8.42116639],
                            [39.30263299, -8.41967833],
                            [39.30249592, -8.41805521],
                            [39.30140903, -8.41744733],
                            [39.29971125, -8.41711046],
                            [39.29767406, -8.4169091],
                            [39.29638399, -8.41697771],
                            [39.29590899, -8.41738388],
                            [39.29568014, -8.41802368],
                            [39.29557021, -8.41833102],
                            [39.29448466, -8.41948162],
                            [39.29299159, -8.42049725],
                            [39.29272054, -8.42124144],
                            [39.29244954, -8.42205325],
                            [39.29129566, -8.42266282],
                            [39.2905492, -8.42327208],
                            [39.29075376, -8.42442172],
                            [39.29075497, -8.42604495],
                            [39.29082338, -8.42672124],
                            [39.29021261, -8.42719513],
                            [39.28933053, -8.42807503],
                            [39.28905962, -8.42902212],
                            [39.28987481, -8.42949496],
                            [39.28946789, -8.4301716],
                            [39.2896044, -8.43111838],
                            [39.28919743, -8.4317274],
                            [39.28858585, -8.43111914],
                            [39.2876355, -8.43152564],
                            [39.28720715, -8.43210556],
                            [39.2866858, -8.43281139],
                            [39.28587209, -8.43436758],
                            [39.28532901, -8.43457088],
                            [39.28471708, -8.43348917],
                            [39.28396959, -8.43274573],
                            [39.28403681, -8.4317988],
                            [39.28417163, -8.43044601],
                            [39.28403513, -8.42949923],
                            [39.28349121, -8.42855274],
                            [39.28287974, -8.42807974],
                            [39.28287876, -8.42672705],
                            [39.28321778, -8.42605046],
                            [39.28247085, -8.426051],
                            [39.28179202, -8.42632202],
                            [39.28070577, -8.42659334],
                            [39.27968762, -8.42713514],
                            [39.2795523, -8.42781159],
                            [39.27867028, -8.42882673],
                            [39.27799154, -8.42923302],
                            [39.27697338, -8.42977482],
                            [39.27690504, -8.42916616],
                            [39.27717622, -8.42855725],
                            [39.2783297, -8.42733901],
                            [39.27880386, -8.42571545],
                            [39.27982153, -8.4244973],
                            [39.28097553, -8.42402303],
                            [39.28124645, -8.42307596],
                            [39.28178889, -8.42199341],
                            [39.28110948, -8.42145282],
                            [39.28049807, -8.42104745],
                            [39.28056504, -8.41976235],
                            [39.28165059, -8.41854415],
                            [39.28266911, -8.41854342],
                            [39.28280437, -8.41779934],
                            [39.28219277, -8.41712343],
                            [39.28205628, -8.41617665],
                            [39.2823272, -8.41522957],
                            [39.28151176, -8.4143509],
                            [39.28178209, -8.41259221],
                            [39.28246006, -8.4111714],
                            [39.28178043, -8.41029264],
                            [39.28279814, -8.40920975],
                            [39.2834085, -8.40819479],
                            [39.28408745, -8.40812667],
                            [39.28401886, -8.40717983],
                            [39.28442527, -8.40582685],
                            [39.28462773, -8.40413584],
                            [39.28578191, -8.40399973],
                            [39.28741154, -8.40406618],
                            [39.28870231, -8.40501212],
                            [39.2893816, -8.40541742],
                            [39.2897896, -8.40622874],
                            [39.2899261, -8.40717552],
                            [39.29019846, -8.40818984],
                            [39.29006326, -8.40900155],
                            [39.28938507, -8.41008421],
                            [39.28931792, -8.41109878],
                            [39.28979377, -8.41184241],
                            [39.29020203, -8.41299189],
                            [39.29122048, -8.4129235],
                            [39.29223868, -8.41251694],
                            [39.29318863, -8.41163698],
                            [39.29420708, -8.41156859],
                            [39.29420738, -8.4119744],
                            [39.29386829, -8.41251573],
                            [39.29373274, -8.412854],
                            [39.29515829, -8.41237949],
                            [39.29658384, -8.41190497],
                            [39.29773736, -8.41088958],
                            [39.29923049, -8.4100092],
                            [39.30052043, -8.40980531],
                            [39.30133533, -8.40993996],
                            [39.3026938, -8.41054763],
                            [39.30330579, -8.41169694],
                            [39.30398548, -8.41257566],
                            [39.30561556, -8.41318311],
                            [39.30643041, -8.41325011],
                            [39.30758339, -8.41155835],
                            [39.30880446, -8.41013707],
                            [39.30982119, -8.40790433],
                            [39.30968447, -8.40675465],
                            [39.30873239, -8.40486164],
                            [39.30764473, -8.40323926],
                            [39.30540291, -8.40175305],
                            [39.30139419, -8.39823914],
                            [39.30010357, -8.39749615],
                            [39.30017204, -8.39824008],
                            [39.3005122, -8.39911907],
                            [39.29983369, -8.39972829],
                            [39.30044581, -8.40108052],
                            [39.30078655, -8.40270349],
                            [39.30194271, -8.40513744],
                            [39.30187654, -8.40736943],
                            [39.30126576, -8.40777571],
                            [39.30017947, -8.40791181],
                            [39.29936468, -8.40791243],
                            [39.29902596, -8.40892721],
                            [39.29861908, -8.40960386],
                            [39.29794008, -8.40960438],
                            [39.29726109, -8.40960489],
                            [39.29685405, -8.41007864],
                            [39.29651434, -8.40980836],
                            [39.29610638, -8.40906468],
                            [39.29529087, -8.40811841],
                            [39.2948825, -8.40683366],
                            [39.29454219, -8.40575177],
                            [39.29413418, -8.40494046],
                            [39.29392967, -8.40385846],
                            [39.29338541, -8.40243854],
                            [39.2927727, -8.40027469],
                            [39.29256804, -8.39898978],
                            [39.29297487, -8.3982455],
                            [39.29277012, -8.39682533],
                            [39.29181879, -8.39581151],
                            [39.29073279, -8.39628576],
                            [39.28937434, -8.39561041],
                            [39.28808504, -8.39662588],
                            [39.28774615, -8.39743774],
                            [39.28781449, -8.3980464],
                            [39.28849392, -8.39865462],
                            [39.28876596, -8.39926313],
                            [39.28842702, -8.40000736],
                            [39.28768059, -8.40061662],
                            [39.28686636, -8.40136119],
                            [39.28625552, -8.40169981],
                            [39.28544642, -8.40069387],
                            [39.2855079, -8.40068583],
                            [39.2854397, -8.40028007],
                            [39.2838777, -8.39980776],
                            [39.28313023, -8.39899668],
                            [39.28306121, -8.39744114],
                            [39.2827203, -8.39547998],
                            [39.28271932, -8.39412728],
                            [39.28305768, -8.39257144],
                            [39.28412638, -8.39092726],
                            [39.28536494, -8.39087891],
                            [39.2861793, -8.39033725],
                            [39.28651819, -8.38952538],
                            [39.28692502, -8.38878111],
                            [39.28767148, -8.38823948],
                            [39.28873236, -8.38780386],
                            [39.28936868, -8.3879677],
                            [39.29011589, -8.3884406],
                            [39.29065875, -8.38803439],
                            [39.29140561, -8.38803384],
                            [39.29222061, -8.38837141],
                            [39.29317187, -8.38931758],
                            [39.29398713, -8.38999332],
                            [39.29554936, -8.39080377],
                            [39.29643279, -8.39181763],
                            [39.29704437, -8.39249351],
                            [39.29751995, -8.39289896],
                            [39.29867384, -8.39242464],
                            [39.29975982, -8.39195038],
                            [39.30016746, -8.39228824],
                            [39.3008469, -8.39289643],
                            [39.30152597, -8.39303118],
                            [39.30193299, -8.39255743],
                            [39.3018642, -8.39140769],
                            [39.30152268, -8.38877021],
                            [39.30172361, -8.38518542],
                            [39.30260379, -8.38200592],
                            [39.30551968, -8.37740452],
                            [39.3089114, -8.37361434],
                            [39.31243797, -8.36874188],
                            [39.31433549, -8.36441176],
                            [39.31514557, -8.35866218],
                            [39.31487331, -8.35778314],
                            [39.31419472, -8.35818949],
                            [39.31344868, -8.35913697],
                            [39.31154908, -8.36082933],
                            [39.30903791, -8.36184582],
                            [39.30618657, -8.36198331],
                            [39.30394569, -8.36137633],
                            [39.30183975, -8.35968708],
                            [39.30095753, -8.36016119],
                            [39.3014335, -8.36110772],
                            [39.30197731, -8.36198655],
                            [39.30272531, -8.36354158],
                            [39.30306592, -8.36502928],
                            [39.30245604, -8.36651771],
                            [39.30143792, -8.36685666],
                            [39.30048758, -8.36706029],
                            [39.30069173, -8.36766884],
                            [39.30137122, -8.36841231],
                            [39.30103223, -8.36902128],
                            [39.30042187, -8.36990099],
                            [39.29953931, -8.3699693],
                            [39.29920067, -8.37105171],
                            [39.29954055, -8.37159253],
                            [39.30062643, -8.37105062],
                            [39.30130572, -8.37152355],
                            [39.3018486, -8.37118496],
                            [39.30211966, -8.37050841],
                            [39.30279869, -8.37064316],
                            [39.30347804, -8.37118371],
                            [39.30429323, -8.3717918],
                            [39.30429365, -8.37233287],
                            [39.30347909, -8.3725364],
                            [39.30280047, -8.37294273],
                            [39.30286893, -8.37368666],
                            [39.302258, -8.3738224],
                            [39.30144307, -8.37355248],
                            [39.30076445, -8.37395881],
                            [39.3013759, -8.37449942],
                            [39.30137622, -8.37490523],
                            [39.3011049, -8.37524361],
                            [39.30049458, -8.37619096],
                            [39.29988437, -8.37727357],
                            [39.29879915, -8.37869473],
                            [39.29784996, -8.38045394],
                            [39.29690026, -8.38153681],
                            [39.2966296, -8.38275444],
                            [39.29629089, -8.38376921],
                            [39.29554435, -8.38417558],
                            [39.29479796, -8.38478485],
                            [39.29432346, -8.38579973],
                            [39.29432417, -8.38674661],
                            [39.29473211, -8.38749029],
                            [39.29425709, -8.38782881],
                            [39.29337429, -8.38762657],
                            [39.29262733, -8.38749185],
                            [39.29194837, -8.38749236],
                            [39.29086174, -8.38708735],
                            [39.29045371, -8.3862084],
                            [39.29113201, -8.38532865],
                            [39.29099572, -8.38465241],
                            [39.29004503, -8.3844502],
                            [39.2890938, -8.38350402],
                            [39.28861768, -8.38235458],
                            [39.28902435, -8.38140739],
                            [39.28943112, -8.38059548],
                            [39.28976974, -8.37944544],
                            [39.29038004, -8.37843047],
                            [39.2897004, -8.37748409],
                            [39.2896996, -8.37640193],
                            [39.29031029, -8.37592804],
                            [39.29044563, -8.37531923],
                            [39.29010536, -8.37423732],
                            [39.29003611, -8.37241124],
                            [39.28996712, -8.37092333],
                            [39.28935548, -8.37011216],
                            [39.28806522, -8.36970729],
                            [39.28670697, -8.3691672],
                            [39.28704569, -8.36815244],
                            [39.28718107, -8.36761126],
                            [39.28840309, -8.36754274],
                            [39.28894613, -8.36740707],
                            [39.28867421, -8.36693383],
                            [39.28826656, -8.36652831],
                            [39.28765553, -8.36652876],
                            [39.28697656, -8.36646162],
                            [39.28711165, -8.36551463],
                            [39.28697497, -8.36429731],
                            [39.28649899, -8.36328313],
                            [39.28595556, -8.36287772],
                            [39.28520885, -8.36301353],
                            [39.28364753, -8.36328519],
                            [39.28337514, -8.36213559],
                            [39.28351053, -8.36159442],
                            [39.28283044, -8.35997167],
                            [39.2822184, -8.35855178],
                            [39.28160695, -8.3579435],
                            [39.28147048, -8.35699671],
                            [39.28085918, -8.35659134],
                            [39.28031586, -8.35632118],
                            [39.28031523, -8.35544193],
                            [39.27977172, -8.35490124],
                            [39.27922812, -8.35422527],
                            [39.27854888, -8.35375231],
                            [39.27773453, -8.35422632],
                            [39.27705616, -8.35497078],
                            [39.27637721, -8.35490362],
                            [39.27542593, -8.35375449],
                            [39.2749499, -8.35260503],
                            [39.27522089, -8.35179323],
                            [39.27393089, -8.35165885],
                            [39.27352322, -8.35118569],
                            [39.27413366, -8.35037365],
                            [39.27460846, -8.34976461],
                            [39.27528764, -8.35016994],
                            [39.27583062, -8.34996666],
                            [39.27549055, -8.34908764],
                            [39.27467531, -8.34827659],
                            [39.27406407, -8.34793884],
                            [39.27453982, -8.3486825],
                            [39.27433657, -8.34929135],
                            [39.27372529, -8.34888596],
                            [39.27175678, -8.34929313],
                            [39.27067055, -8.34929387],
                            [39.27026293, -8.34888834],
                            [39.26992279, -8.34787405],
                            [39.26931109, -8.34685994],
                            [39.26815619, -8.34571093],
                            [39.26768019, -8.34456146],
                            [39.26686497, -8.34375039],
                            [39.26604976, -8.34293932],
                            [39.2650302, -8.34111386],
                            [39.26455453, -8.34043783],
                            [39.26435041, -8.33976162],
                            [39.26373965, -8.3401002],
                            [39.26312839, -8.3396948],
                            [39.26238104, -8.33881604],
                            [39.26278787, -8.33807178],
                            [39.26407818, -8.33874728],
                            [39.26502861, -8.33874664],
                            [39.26604743, -8.33948994],
                            [39.26611486, -8.33881355],
                            [39.26584276, -8.33800211],
                            [39.26658943, -8.33786634],
                            [39.26760829, -8.33867727],
                            [39.26842377, -8.33989415],
                            [39.26849222, -8.34070572],
                            [39.26815338, -8.34158521],
                            [39.26822159, -8.3420586],
                            [39.26808646, -8.34300559],
                            [39.26910497, -8.34327543],
                            [39.26971592, -8.34320738],
                            [39.26951137, -8.34192246],
                            [39.2700541, -8.34138101],
                            [39.27025847, -8.3423954],
                            [39.27053039, -8.34293629],
                            [39.27100533, -8.34253016],
                            [39.27154895, -8.34327377],
                            [39.27154923, -8.34367958],
                            [39.27100631, -8.34395049],
                            [39.27093889, -8.34462688],
                            [39.27093935, -8.34530323],
                            [39.27155072, -8.34584389],
                            [39.27229755, -8.34591101],
                            [39.2724329, -8.34530221],
                            [39.27290751, -8.34442263],
                            [39.27236352, -8.34313794],
                            [39.27215948, -8.342597],
                            [39.27215891, -8.34178538],
                            [39.2724299, -8.34097358],
                            [39.27215779, -8.34016214],
                            [39.27141084, -8.33989212],
                            [39.27073103, -8.33853988],
                            [39.27011921, -8.33732287],
                            [39.26957546, -8.33637635],
                            [39.26909951, -8.33529452],
                            [39.26814886, -8.33495699],
                            [39.26726569, -8.33401069],
                            [39.26679029, -8.33374047],
                            [39.26543274, -8.33401192],
                            [39.26475392, -8.33408001],
                            [39.26380314, -8.33353957],
                            [39.26292066, -8.33360779],
                            [39.26264992, -8.3348254],
                            [39.26217494, -8.33516389],
                            [39.2611565, -8.33496165],
                            [39.26061291, -8.33421803],
                            [39.26040804, -8.33239202],
                            [39.26017014, -8.33130315],
                            [39.26155359, -8.33144731],
                            [39.26388335, -8.33130069],
                            [39.26475671, -8.33079234],
                            [39.26395407, -8.32818149],
                            [39.26293312, -8.32571585],
                            [39.26183976, -8.32383057],
                            [39.25958131, -8.3216559],
                            [39.25805194, -8.31989388],
                            [39.25681259, -8.318466],
                            [39.25542783, -8.31621819],
                            [39.25273244, -8.3137536],
                            [39.25098406, -8.31208631],
                            [39.25003595, -8.30947552],
                            [39.24930598, -8.30642935],
                            [39.24879476, -8.30389081],
                            [39.24806509, -8.30127987],
                            [39.24820882, -8.29830569],
                            [39.24835283, -8.29576675],
                            [39.24864235, -8.29308263],
                            [39.2486408, -8.29061631],
                            [39.24878435, -8.28735198],
                            [39.24914653, -8.2844502],
                            [39.24941823, -8.28239043],
                            [39.2496537, -8.28060532],
                            [39.25001513, -8.27654292],
                            [39.24935699, -8.27182831],
                            [39.24877265, -8.2687095],
                            [39.2483771, -8.26745096],
                            [39.24804323, -8.26638871],
                            [39.24658582, -8.26399582],
                            [39.24466785, -8.26169419],
                            [39.24460342, -8.26177991],
                            [39.24471758, -8.26128035],
                            [39.24604133, -8.25971112],
                            [39.24761022, -8.25809286],
                            [39.24863979, -8.25642557],
                            [39.24971839, -8.25554288],
                            [39.25030672, -8.25500346],
                            [39.25104214, -8.25500346],
                            [39.25212074, -8.25505252],
                            [39.25315029, -8.25485636],
                            [39.25373863, -8.25451312],
                            [39.25393473, -8.25372849],
                            [39.25398379, -8.2523554],
                            [39.25349351, -8.25088425],
                            [39.2531013, -8.25034484],
                            [39.25315032, -8.24956022],
                            [39.25349352, -8.24897177],
                            [39.25476822, -8.24882466],
                            [39.25594488, -8.24970736],
                            [39.25741569, -8.25122755],
                            [39.25893553, -8.25220832],
                            [39.25967093, -8.25284582],
                            [39.26050438, -8.25343427],
                            [39.26158299, -8.25416987],
                            [39.26246547, -8.25441506],
                            [39.26329894, -8.2544641],
                            [39.26403435, -8.25436602],
                            [39.26491683, -8.25436603],
                            [39.26565225, -8.25456219],
                            [39.26673085, -8.2545622],
                            [39.26727014, -8.25387567],
                            [39.26815264, -8.25309106],
                            [39.26967249, -8.25245355],
                            [39.27089816, -8.251718],
                            [39.27182969, -8.25112954],
                            [39.27246704, -8.250443],
                            [39.27276121, -8.24965839],
                            [39.27295733, -8.24887378],
                            [39.27305537, -8.24823629],
                            [39.27261548, -8.24703637],
                            [39.2717487, -8.24595267],
                            [39.27001517, -8.24476058],
                            [39.26838997, -8.24324339],
                            [39.26719818, -8.24215968],
                            [39.26600638, -8.23923366],
                            [39.26513962, -8.23630766],
                            [39.26568136, -8.23349003],
                            [39.26643981, -8.23143098],
                            [39.26741492, -8.2291552],
                            [39.2675233, -8.22720454],
                            [39.26730659, -8.22536223],
                            [39.2663315, -8.2239534],
                            [39.26513968, -8.22297806],
                            [39.26373119, -8.2224362],
                            [39.26275607, -8.2224362],
                            [39.26113087, -8.22232783],
                            [39.25939734, -8.22243619],
                            [39.25831387, -8.22254455],
                            [39.25712208, -8.22221945],
                            [39.25668869, -8.22102737],
                            [39.25658034, -8.21907669],
                            [39.25647201, -8.2175595],
                            [39.25625534, -8.21669253],
                            [39.25517187, -8.21625905],
                            [39.2545218, -8.21539209],
                            [39.25408842, -8.21441674],
                            [39.25408842, -8.21311631],
                            [39.25419677, -8.21149074],
                            [39.25441347, -8.21029866],
                            [39.2549552, -8.20943169],
                            [39.25528026, -8.20856475],
                            [39.25582199, -8.20780614],
                            [39.25548049, -8.20698008],
                            [39.25566576, -8.20679042],
                            [39.25566704, -8.2067917],
                            [39.25703813, -8.20663932],
                            [39.2582569, -8.20564888],
                            [39.25985655, -8.20412507],
                            [39.26176086, -8.20206796],
                            [39.26404604, -8.20001086],
                            [39.26549332, -8.19787754],
                            [39.26671209, -8.19688708],
                            [39.26815938, -8.1961252],
                            [39.26914961, -8.19521092],
                            [39.27029221, -8.19414428],
                            [39.27135862, -8.19277287],
                            [39.27128246, -8.19163002],
                            [39.27135864, -8.190411],
                            [39.27135864, -8.19003005],
                            [39.2716286, -8.18925831],
                            [39.27170014, -8.18925281],
                            [39.27224998, -8.18917643],
                            [39.27263179, -8.18905423],
                            [39.27287618, -8.18885562],
                            [39.27316637, -8.1886112],
                            [39.27347184, -8.18829039],
                            [39.27385367, -8.1878932],
                            [39.27414387, -8.18748073],
                            [39.2742966, -8.18714464],
                            [39.2745257, -8.18673217],
                            [39.27467843, -8.18616693],
                            [39.27481589, -8.1856628],
                            [39.27492281, -8.18525033],
                            [39.27522827, -8.18477675],
                            [39.27551847, -8.18422679],
                            [39.27597667, -8.18360045],
                            [39.27623632, -8.18308105],
                            [39.27640433, -8.18269913],
                            [39.27652651, -8.18213389],
                            [39.27649597, -8.18176726],
                            [39.27629741, -8.1817367],
                            [39.27608359, -8.18185891],
                            [39.27576286, -8.18211862],
                            [39.27544212, -8.18245469],
                            [39.27501446, -8.18268384],
                            [39.27457153, -8.18291299],
                            [39.27428135, -8.18292828],
                            [39.27405225, -8.18286717],
                            [39.27376206, -8.18268384],
                            [39.2736246, -8.18231721],
                            [39.27357877, -8.18193528],
                            [39.2736246, -8.18172141],
                            [39.27370097, -8.18153809],
                            [39.27382316, -8.18137005],
                            [39.27402171, -8.18115618],
                            [39.27426608, -8.18082009],
                            [39.27440354, -8.18049928],
                            [39.27457154, -8.18034652],
                            [39.27480064, -8.18034652],
                            [39.27502972, -8.18025486],
                            [39.27528938, -8.18005626],
                            [39.27556431, -8.17973545],
                            [39.27582394, -8.17935352],
                            [39.27608361, -8.17915494],
                            [39.27626688, -8.17913964],
                            [39.27646544, -8.17913964],
                            [39.27678617, -8.17901745],
                            [39.27699999, -8.17872719],
                            [39.27712219, -8.17833],
                            [39.27716801, -8.17782587],
                            [39.27718329, -8.17739812],
                            [39.27718329, -8.17707731],
                            [39.27729019, -8.17690926],
                            [39.27742764, -8.17683289],
                            [39.27765674, -8.17683289],
                            [39.27794695, -8.17680233],
                            [39.27826769, -8.17674122],
                            [39.27852733, -8.17648152],
                            [39.27863425, -8.17608434],
                            [39.27861897, -8.17571771],
                            [39.27846625, -8.17535104],
                            [39.27826769, -8.17512191],
                            [39.27805388, -8.17483165],
                            [39.27794696, -8.17451082],
                            [39.27790113, -8.17415946],
                            [39.27794696, -8.17394559],
                            [39.27811497, -8.17374699],
                            [39.27828298, -8.17350257],
                            [39.27845096, -8.17322759],
                            [39.27852735, -8.17286096],
                            [39.27852735, -8.1725707],
                            [39.27845097, -8.17211239],
                            [39.27822188, -8.17188324],
                            [39.27794697, -8.1718374],
                            [39.27780951, -8.17176103],
                            [39.27773315, -8.17156243],
                            [39.27758041, -8.17151659],
                            [39.27747351, -8.17142493],
                            [39.27744296, -8.17119578],
                            [39.27756515, -8.17095136],
                            [39.27767207, -8.17069166],
                            [39.27771788, -8.17038612],
                            [39.27776369, -8.17006531],
                            [39.27784998, -8.16975159],
                            [39.27789317, -8.16940597],
                            [39.27800115, -8.16929796],
                            [39.27821711, -8.16903876],
                            [39.27837909, -8.16885515],
                            [39.27849785, -8.16863913],
                            [39.27853026, -8.16840153],
                            [39.27863823, -8.16805592],
                            [39.27876783, -8.16793711],
                            [39.27899459, -8.1678075],
                            [39.27930773, -8.16779671],
                            [39.2794913, -8.16765631],
                            [39.2794913, -8.16739709],
                            [39.27949131, -8.16721349],
                            [39.27959929, -8.16713788],
                            [39.27975045, -8.16713788],
                            [39.27995562, -8.16701908],
                            [39.28020397, -8.16681387],
                            [39.28036594, -8.16671667],
                            [39.28037674, -8.16655465],
                            [39.28025797, -8.16641425],
                            [39.28024717, -8.16627385],
                            [39.28012838, -8.16611183],
                            [39.28002041, -8.16612263],
                            [39.27981525, -8.16610103],
                            [39.27963168, -8.16596063],
                            [39.27952369, -8.16579862],
                            [39.27952369, -8.16557181],
                            [39.2795453, -8.1652046],
                            [39.2795345, -8.16490219],
                            [39.27965328, -8.16470778],
                            [39.27986924, -8.16467538],
                            [39.2800744, -8.16467538],
                            [39.28029036, -8.16474017],
                            [39.28058191, -8.16482658],
                            [39.28090585, -8.16480499],
                            [39.2811974, -8.16471858],
                            [39.28127297, -8.16449177],
                            [39.281219, -8.16422175],
                            [39.28110022, -8.16404896],
                            [39.28089506, -8.16388694],
                            [39.2806467, -8.16371413],
                            [39.28021478, -8.16362773],
                            [39.27996641, -8.16359532],
                            [39.27969647, -8.16353054],
                            [39.27946971, -8.16343332],
                            [39.27953451, -8.16335771],
                            [39.27972888, -8.1632821],
                            [39.28002043, -8.16323891],
                            [39.28032276, -8.16321731],
                            [39.28057111, -8.16321731],
                            [39.28081947, -8.16327131],
                            [39.28099223, -8.16334692],
                            [39.28127297, -8.16335772],
                            [39.28143495, -8.16324972],
                            [39.28153215, -8.16303371],
                            [39.28151056, -8.16275289],
                            [39.28148895, -8.16252608],
                            [39.28134858, -8.16231007],
                            [39.28103544, -8.16213727],
                            [39.28067911, -8.16201845],
                            [39.28036597, -8.16199685],
                            [39.28013921, -8.16210486],
                            [39.27981527, -8.16225607],
                            [39.27949133, -8.16240727],
                            [39.27916737, -8.16245048],
                            [39.27889743, -8.16246128],
                            [39.27868147, -8.16253687],
                            [39.27838992, -8.16269888],
                            [39.27814157, -8.16280688],
                            [39.2778932, -8.16280688],
                            [39.27770965, -8.16276369],
                            [39.27755846, -8.16265567],
                            [39.27738571, -8.16255848],
                            [39.27716975, -8.16241806],
                            [39.27698618, -8.16225606],
                            [39.27669463, -8.16205085],
                            [39.27643548, -8.16191043],
                            [39.27621952, -8.16183484],
                            [39.27596036, -8.16171603],
                            [39.27570121, -8.16154322],
                            [39.27546365, -8.16132721],
                            [39.27539887, -8.16114359],
                            [39.27537728, -8.16095998],
                            [39.27544206, -8.1607548],
                            [39.27555004, -8.16052797],
                            [39.27558243, -8.16024716],
                            [39.27555004, -8.15987994],
                            [39.27549606, -8.15958832],
                            [39.27531249, -8.15939393],
                            [39.27499933, -8.15911311],
                            [39.27463222, -8.15894029],
                            [39.27428667, -8.15889709],
                            [39.27393034, -8.15895109],
                            [39.27364959, -8.1590915],
                            [39.27337964, -8.15931831],
                            [39.27318528, -8.15959913],
                            [39.2730557, -8.15979352],
                            [39.27307729, -8.15999873],
                            [39.27317448, -8.16025795],
                            [39.27329325, -8.16051716],
                            [39.27333644, -8.16073315],
                            [39.27317447, -8.16085197],
                            [39.27298011, -8.16083037],
                            [39.27282894, -8.16072236],
                            [39.27271015, -8.16056037],
                            [39.27261297, -8.16039835],
                            [39.2724618, -8.16008514],
                            [39.27238621, -8.15981513],
                            [39.27238621, -8.15954511],
                            [39.27239701, -8.15928589],
                            [39.27235383, -8.15899429],
                            [39.27224585, -8.15870267],
                            [39.27205148, -8.15843267],
                            [39.27181393, -8.15822746],
                            [39.27164116, -8.15810864],
                            [39.2714252, -8.15810864],
                            [39.27116605, -8.15810864],
                            [39.2709285, -8.15808704],
                            [39.27070173, -8.15797903],
                            [39.27052897, -8.15786023],
                            [39.27035619, -8.15786023],
                            [39.27007544, -8.15792504],
                            [39.26987027, -8.15796823],
                            [39.26961113, -8.15796823],
                            [39.26943835, -8.15792503],
                            [39.2692548, -8.15792503],
                            [39.26906043, -8.15802224],
                            [39.26905281, -8.15802457],
                            [39.26903704, -8.1580908],
                            [39.26908853, -8.15765308],
                            [39.26919549, -8.15695763],
                            [39.26932921, -8.15642268],
                            [39.26946292, -8.15583421],
                            [39.26978382, -8.15529925],
                            [39.2702117, -8.15460381],
                            [39.27071979, -8.15433633],
                            [39.27082679, -8.15372113],
                            [39.27125933, -8.15328763],
                            [39.27119057, -8.15337018],
                            [39.2711794, -8.15338804],
                            [39.27120227, -8.15339376],
                            [39.27129441, -8.15352541],
                            [39.27138655, -8.15367022],
                            [39.2713997, -8.15385452],
                            [39.27141286, -8.15410465],
                            [39.27133389, -8.15432845],
                            [39.27114963, -8.15451275],
                            [39.27092587, -8.15469707],
                            [39.2707811, -8.15492086],
                            [39.27074161, -8.15514465],
                            [39.27079426, -8.15555276],
                            [39.27087323, -8.15585555],
                            [39.27108381, -8.15605304],
                            [39.27126805, -8.15627681],
                            [39.27134705, -8.15648745],
                            [39.27149183, -8.15665859],
                            [39.27168924, -8.15682973],
                            [39.27195248, -8.15688238],
                            [39.27220255, -8.15689556],
                            [39.27241313, -8.15689556],
                            [39.27254475, -8.15701403],
                            [39.27274218, -8.15723784],
                            [39.27316335, -8.15756697],
                            [39.27372929, -8.15780392],
                            [39.27415046, -8.15784341],
                            [39.27444003, -8.15775126],
                            [39.27470326, -8.15758013],
                            [39.27487436, -8.15739582],
                            [39.27503231, -8.15730368],
                            [39.27521657, -8.1572905],
                            [39.27540082, -8.157409],
                            [39.27557192, -8.15769861],
                            [39.27565089, -8.1580014],
                            [39.2758878, -8.15846216],
                            [39.27608521, -8.15879127],
                            [39.27621685, -8.15906773],
                            [39.27637478, -8.15925204],
                            [39.27662484, -8.15942319],
                            [39.27682227, -8.15952849],
                            [39.2769802, -8.15964698],
                            [39.27704603, -8.15981812],
                            [39.27704603, -8.15997609],
                            [39.27700654, -8.16014721],
                            [39.27699337, -8.16031837],
                            [39.27707234, -8.16048951],
                            [39.27726977, -8.160608],
                            [39.27745402, -8.16076597],
                            [39.277533, -8.16095029],
                            [39.27765145, -8.16116092],
                            [39.27782255, -8.16137155],
                            [39.27812528, -8.16143738],
                            [39.27841483, -8.16139786],
                            [39.27861226, -8.16129256],
                            [39.27877019, -8.16108193],
                            [39.27877019, -8.16085813],
                            [39.27866488, -8.16056852],
                            [39.27859909, -8.16042369],
                            [39.27875702, -8.16023938],
                            [39.27895447, -8.16002876],
                            [39.27904659, -8.15992345],
                            [39.27919136, -8.15991029],
                            [39.27940195, -8.15989712],
                            [39.27966519, -8.15985762],
                            [39.27988895, -8.15976547],
                            [39.28000738, -8.15954168],
                            [39.28000738, -8.15951261],
                            [39.28037684, -8.15938945],
                            [39.28062164, -8.15924955],
                            [39.28083146, -8.15886477],
                            [39.28107625, -8.15858494],
                            [39.2814959, -8.15868988],
                            [39.28198549, -8.15861992],
                            [39.28247509, -8.1581652],
                            [39.28271988, -8.15771049],
                            [39.28310455, -8.15757057],
                            [39.28401379, -8.15792036],
                            [39.28436349, -8.15830512],
                            [39.28474817, -8.15861993],
                            [39.28492302, -8.15921458],
                            [39.28509787, -8.15977423],
                            [39.28523775, -8.16008904],
                            [39.28502793, -8.16089354],
                            [39.28460827, -8.16120833],
                            [39.28450336, -8.16169803],
                            [39.28443341, -8.16250254],
                            [39.28467821, -8.16313216],
                            [39.28569235, -8.16414656],
                            [39.28716112, -8.16516092],
                            [39.28817526, -8.16558068],
                            [39.28901456, -8.16603539],
                            [39.28981887, -8.16631523],
                            [39.2900287, -8.16642017],
                            [39.29027349, -8.16694485],
                            [39.29062319, -8.16820407],
                            [39.29058822, -8.1689736],
                            [39.29058821, -8.16953327],
                            [39.29139254, -8.17051264],
                            [39.29188213, -8.17068755],
                            [39.29286129, -8.17040772],
                            [39.29359569, -8.17019786],
                            [39.29412024, -8.17009292],
                            [39.29450492, -8.17012791],
                            [39.2946448, -8.17047769],
                            [39.29467976, -8.17110729],
                            [39.29426012, -8.17177188],
                            [39.29370056, -8.17243647],
                            [39.29289627, -8.17261136],
                            [39.29188212, -8.17250643],
                            [39.29128761, -8.17233154],
                            [39.29097287, -8.17240149],
                            [39.29090294, -8.17271628],
                            [39.29100782, -8.17348583],
                            [39.29132257, -8.17436029],
                            [39.29167227, -8.1746751],
                            [39.2924766, -8.17464011],
                            [39.2933159, -8.17471008],
                            [39.29377052, -8.17450022],
                            [39.29415519, -8.17411544],
                            [39.29460982, -8.17411545],
                            [39.29478467, -8.17467511],
                            [39.29426011, -8.17512982],
                            [39.29359566, -8.17523476],
                            [39.29324596, -8.17551459],
                            [39.29279133, -8.17610922],
                            [39.29202197, -8.17673883],
                            [39.29156736, -8.17715856],
                            [39.290798, -8.17740342],
                            [39.29016852, -8.17747336],
                            [39.28967893, -8.17764825],
                            [39.28925929, -8.17803303],
                            [39.2891194, -8.17852273],
                            [39.28929425, -8.1790474],
                            [39.2891194, -8.17946714],
                            [39.28848992, -8.17988688],
                            [39.28765063, -8.18051648],
                            [39.28705613, -8.18111112],
                            [39.28702116, -8.18156584],
                            [39.28684631, -8.18202054],
                            [39.28653157, -8.18254524],
                            [39.28691624, -8.18247528],
                            [39.2874408, -8.18216047],
                            [39.28775554, -8.18237034],
                            [39.28730091, -8.18286004],
                            [39.28698617, -8.18317485],
                            [39.28674138, -8.18352463],
                            [39.28688127, -8.18397934],
                            [39.2868463, -8.18443408],
                            [39.28663646, -8.18446905],
                            [39.28625179, -8.18418922],
                            [39.28593705, -8.18411927],
                            [39.2856573, -8.184539],
                            [39.28569226, -8.18520358],
                            [39.28590208, -8.18586818],
                            [39.28579716, -8.18656775],
                            [39.28576219, -8.18719737],
                            [39.28607692, -8.18814179],
                            [39.28642662, -8.18905123],
                            [39.28684628, -8.18919115],
                            [39.28737084, -8.18880639],
                            [39.28803528, -8.18852656],
                            [39.2885948, -8.18835167],
                            [39.28880461, -8.18775705],
                            [39.28918932, -8.18705747],
                            [39.28971388, -8.18674266],
                            [39.29013352, -8.18656777],
                            [39.28981878, -8.18618299],
                            [39.28950404, -8.18604309],
                            [39.28946908, -8.18569329],
                            [39.28936418, -8.1852386],
                            [39.28908441, -8.18495876],
                            [39.28859482, -8.18481885],
                            [39.28859482, -8.18436412],
                            [39.28915435, -8.18397935],
                            [39.28978383, -8.18338474],
                            [39.29030839, -8.18296499],
                            [39.29062312, -8.18324483],
                            [39.29102528, -8.18344594],
                            [39.29136625, -8.18378699],
                            [39.29162853, -8.183787],
                            [39.29175967, -8.18404933],
                            [39.29157606, -8.18436413],
                            [39.2917859, -8.18465272],
                            [39.29204817, -8.18465272],
                            [39.29233667, -8.18449532],
                            [39.2927301, -8.18433791],
                            [39.29309728, -8.18441662],
                            [39.29325465, -8.18473143],
                            [39.29291369, -8.18504622],
                            [39.29238914, -8.18536102],
                            [39.29173343, -8.18564959],
                            [39.29141869, -8.18585947],
                            [39.29128755, -8.1862005],
                            [39.29139247, -8.18656778],
                            [39.29189079, -8.18662026],
                            [39.2923629, -8.18654156],
                            [39.29278252, -8.18627921],
                            [39.29322843, -8.18596441],
                            [39.2936743, -8.18578078],
                            [39.29417263, -8.18559714],
                            [39.29435623, -8.18530857],
                            [39.29435623, -8.18496752],
                            [39.29425132, -8.18439038],
                            [39.29406773, -8.18389195],
                            [39.29412018, -8.18355088],
                            [39.29435624, -8.1834722],
                            [39.29459229, -8.18376077],
                            [39.29485456, -8.18391816],
                            [39.29514308, -8.18352466],
                            [39.29527422, -8.18297377],
                            [39.29545781, -8.18265896],
                            [39.29598236, -8.18260648],
                            [39.2962971, -8.18234415],
                            [39.29634956, -8.18163583],
                            [39.29611352, -8.18084881],
                            [39.29603484, -8.18035038],
                            [39.29603484, -8.1798257],
                            [39.29585124, -8.17958959],
                            [39.29569388, -8.17924855],
                            [39.29569388, -8.17875011],
                            [39.29535292, -8.17833036],
                            [39.29498574, -8.17809427],
                            [39.29461853, -8.17780568],
                            [39.29453985, -8.17738594],
                            [39.29472346, -8.17688749],
                            [39.29527425, -8.17675631],
                            [39.29569389, -8.17673009],
                            [39.29592994, -8.17644152],
                            [39.29621845, -8.17610049],
                            [39.29674302, -8.1761005],
                            [39.29700529, -8.17573321],
                            [39.29713643, -8.17523477],
                            [39.2972938, -8.17497243],
                            [39.29745117, -8.17507737],
                            [39.29773967, -8.17486751],
                            [39.29752985, -8.17455268],
                            [39.2972938, -8.17436907],
                            [39.29671679, -8.17436907],
                            [39.29666434, -8.17408048],
                            [39.2970053, -8.17379191],
                            [39.29734627, -8.17352957],
                            [39.29755609, -8.17305737],
                            [39.29747085, -8.17279503],
                            [39.29731348, -8.17259827],
                            [39.29697907, -8.17242117],
                            [39.29666435, -8.17240151],
                            [39.29644797, -8.17222443],
                            [39.29646764, -8.17198833],
                            [39.29684139, -8.17175222],
                            [39.29731349, -8.17155547],
                            [39.29768724, -8.17155547],
                            [39.29802165, -8.17153581],
                            [39.29831671, -8.17131938],
                            [39.29831671, -8.1709849],
                            [39.29821836, -8.17057171],
                            [39.29798231, -8.17004047],
                            [39.2977856, -8.16958793],
                            [39.29766756, -8.16915507],
                            [39.29774626, -8.16878125],
                            [39.298061, -8.1685845],
                            [39.29839541, -8.16838774],
                            [39.29863146, -8.16834839],
                            [39.29898554, -8.16828937],
                            [39.29920192, -8.16832872],
                            [39.29939862, -8.1685845],
                            [39.29969368, -8.16880094],
                            [39.29998875, -8.16882061],
                            [39.30016578, -8.1685058],
                            [39.30010678, -8.16795489],
                            [39.30014612, -8.16767943],
                            [39.30046085, -8.16750235],
                            [39.30095264, -8.16728592],
                            [39.30122804, -8.16703014],
                            [39.30152311, -8.16665631],
                            [39.3016608, -8.16640056],
                            [39.30175916, -8.16622347],
                            [39.30193619, -8.1661054],
                            [39.30238862, -8.1661054],
                            [39.30262466, -8.1662825],
                            [39.30282138, -8.16663664],
                            [39.30301809, -8.16695145],
                            [39.30329349, -8.1668334],
                            [39.30343119, -8.16640057],
                            [39.30352955, -8.16592834],
                            [39.30352955, -8.1654758],
                            [39.30343119, -8.16488554],
                            [39.30341152, -8.16445268],
                            [39.30319514, -8.16405918],
                            [39.30291973, -8.16384275],
                            [39.30280172, -8.16358696],
                            [39.30278205, -8.16331151],
                            [39.30264436, -8.16317378],
                            [39.30244765, -8.16325248],
                            [39.30230995, -8.16348859],
                            [39.30219192, -8.16374437],
                            [39.30197554, -8.16380339],
                            [39.30189685, -8.16368534],
                            [39.30177884, -8.16350826],
                            [39.30185751, -8.16333118],
                            [39.3019362, -8.16311475],
                            [39.3019362, -8.16289832],
                            [39.30187719, -8.16270156],
                            [39.30187719, -8.16254416],
                            [39.30211323, -8.16254416],
                            [39.30236895, -8.16254416],
                            [39.30240831, -8.16236708],
                            [39.30232962, -8.16220968],
                            [39.30225094, -8.16197357],
                            [39.30215259, -8.16165874],
                            [39.30195588, -8.1614817],
                            [39.3018182, -8.16132429],
                            [39.30175918, -8.16106851],
                            [39.3018772, -8.16077338],
                            [39.30207389, -8.16045857],
                            [39.30213292, -8.16012408],
                            [39.30207389, -8.15988798],
                            [39.30191654, -8.15976992],
                            [39.30164116, -8.15984862],
                            [39.30144445, -8.16000603],
                            [39.3012084, -8.1600257],
                            [39.3010707, -8.15990765],
                            [39.30085432, -8.15984862],
                            [39.30063794, -8.15984862],
                            [39.30050024, -8.15971089],
                            [39.30048057, -8.15945509],
                            [39.30034287, -8.15917965],
                            [39.30014616, -8.15904194],
                            [39.29979208, -8.15888453],
                            [39.29951669, -8.15878615],
                            [39.29930032, -8.15860907],
                            [39.29916263, -8.15845167],
                            [39.29902493, -8.15843199],
                            [39.29884789, -8.15843199],
                            [39.29861184, -8.15853037],
                            [39.29837578, -8.1587468],
                            [39.29808071, -8.1589042],
                            [39.29776598, -8.15892387],
                            [39.29731354, -8.15892387],
                            [39.29695946, -8.15886484],
                            [39.29672342, -8.15872711],
                            [39.29656606, -8.15858938],
                            [39.29623165, -8.1583336],
                            [39.29593658, -8.1582549],
                            [39.29566119, -8.15817619],
                            [39.29548415, -8.15801879],
                            [39.29518908, -8.15790074],
                            [39.29491367, -8.15786138],
                            [39.29457928, -8.15801879],
                            [39.29444158, -8.15829424],
                            [39.29424488, -8.15845165],
                            [39.29398916, -8.15845164],
                            [39.29371377, -8.15821554],
                            [39.2936941, -8.15788105],
                            [39.29387114, -8.15756625],
                            [39.29398916, -8.15723176],
                            [39.29391048, -8.1569563],
                            [39.29373344, -8.1569563],
                            [39.29343838, -8.15699567],
                            [39.29320232, -8.15721208],
                            [39.29298594, -8.15744819],
                            [39.29286791, -8.15764494],
                            [39.29259251, -8.15774332],
                            [39.29237613, -8.15762527],
                            [39.29206139, -8.1579204],
                            [39.29178601, -8.15825488],
                            [39.2916483, -8.15855001],
                            [39.29143193, -8.15860904],
                            [39.29137292, -8.15833358],
                            [39.29149095, -8.15797942],
                            [39.29131391, -8.15776299],
                            [39.29119588, -8.15756623],
                            [39.29123523, -8.1571924],
                            [39.29135324, -8.15693662],
                            [39.29145161, -8.15662183],
                            [39.29145161, -8.15630702],
                            [39.29135324, -8.15612994],
                            [39.29115655, -8.15605123],
                            [39.29115655, -8.15589381],
                            [39.2912549, -8.1556774],
                            [39.2915303, -8.1555987],
                            [39.29172701, -8.15516584],
                            [39.29160899, -8.15481168],
                            [39.29133359, -8.15475265],
                            [39.29101886, -8.15475265],
                            [39.29066478, -8.15451654],
                            [39.29042873, -8.1542411],
                            [39.29029103, -8.15394596],
                            [39.29021235, -8.15347375],
                            [39.29021235, -8.15317862],
                            [39.2899763, -8.15304089],
                            [39.28966154, -8.15329667],
                            [39.28942552, -8.15369016],
                            [39.28915011, -8.15394596],
                            [39.28913406, -8.15394027],
                            [39.28913339, -8.15396348],
                            [39.2890023, -8.15411332],
                            [39.2890772, -8.15435682],
                            [39.28930193, -8.15439428],
                            [39.28969519, -8.15439428],
                            [39.29003229, -8.15454414],
                            [39.2903132, -8.1548251],
                            [39.29044428, -8.15518099],
                            [39.29057537, -8.15561182],
                            [39.29057537, -8.15598645],
                            [39.29038809, -8.15637979],
                            [39.29001354, -8.15660457],
                            [39.28954537, -8.15671696],
                            [39.28937683, -8.15679188],
                            [39.2893581, -8.15709158],
                            [39.28937683, -8.15731635],
                            [39.28956409, -8.15741001],
                            [39.28999481, -8.15739129],
                            [39.29031318, -8.15739129],
                            [39.29044427, -8.15752241],
                            [39.29051918, -8.15774719],
                            [39.29055663, -8.15804688],
                            [39.29068771, -8.15829038],
                            [39.29093117, -8.15851516],
                            [39.29096862, -8.15879613],
                            [39.29068771, -8.15896471],
                            [39.2904068, -8.15902091],
                            [39.29006972, -8.15913329],
                            [39.28990118, -8.15947046],
                            [39.29003226, -8.15978889],
                            [39.29006971, -8.16005113],
                            [39.29001353, -8.16033209],
                            [39.28980753, -8.1605194],
                            [39.28982627, -8.16074418],
                            [39.29008844, -8.16091276],
                            [39.2902757, -8.16108134],
                            [39.2903506, -8.16124991],
                            [39.2903506, -8.16149343],
                            [39.29016334, -8.16173693],
                            [39.28997607, -8.16186804],
                            [39.28984498, -8.16213029],
                            [39.28952662, -8.16209282],
                            [39.28928316, -8.16192424],
                            [39.28918954, -8.16164328],
                            [39.28911464, -8.16128738],
                            [39.28898353, -8.16106261],
                            [39.28883373, -8.16093149],
                            [39.28885246, -8.16070671],
                            [39.28883373, -8.16036954],
                            [39.28883373, -8.15997619],
                            [39.28877755, -8.15958283],
                            [39.28870182, -8.15929097],
                            [39.28837452, -8.15929096],
                            [39.28806544, -8.15938189],
                            [39.28782907, -8.15950919],
                            [39.28737451, -8.15950919],
                            [39.28690178, -8.15929096],
                            [39.28679268, -8.15890904],
                            [39.28679268, -8.15854532],
                            [39.28671996, -8.15827253],
                            [39.28653814, -8.15785424],
                            [39.28646541, -8.15756326],
                            [39.28657451, -8.15718135],
                            [39.28657451, -8.15687218],
                            [39.28637452, -8.15670851],
                            [39.28608357, -8.15665395],
                            [39.28575632, -8.15647209],
                            [39.28551996, -8.15621747],
                            [39.28548359, -8.15594469],
                            [39.28564722, -8.155781],
                            [39.2856836, -8.15547184],
                            [39.28550178, -8.15518086],
                            [39.28522904, -8.15510811],
                            [39.28493813, -8.15496263],
                            [39.28481085, -8.1545989],
                            [39.28473811, -8.15438066],
                            [39.28457449, -8.15425334],
                            [39.28433812, -8.15421699],
                            [39.28411995, -8.15403511],
                            [39.28413812, -8.1536714],
                            [39.28408357, -8.1533986],
                            [39.28382901, -8.15327129],
                            [39.2836472, -8.15327129],
                            [39.28344719, -8.1533986],
                            [39.28337446, -8.15370776],
                            [39.28310174, -8.15387143],
                            [39.28277445, -8.15387143],
                            [39.282429, -8.15378051],
                            [39.28222898, -8.15354409],
                            [39.28224718, -8.15323492],
                            [39.282429, -8.1528712],
                            [39.28270174, -8.15265296],
                            [39.28271991, -8.15236198],
                            [39.28248355, -8.15203462],
                            [39.28208355, -8.15214375],
                            [39.28190173, -8.15238017],
                            [39.28177443, -8.1528712],
                            [39.28164717, -8.15327129],
                            [39.28122898, -8.15332584],
                            [39.2809017, -8.15347133],
                            [39.28097443, -8.15387143],
                            [39.28106533, -8.1543079],
                            [39.28113806, -8.15508992],
                            [39.2810835, -8.1555082],
                            [39.28084715, -8.15565368],
                            [39.28071987, -8.15549001],
                            [39.28086532, -8.15527178],
                            [39.28073806, -8.15488986],
                            [39.28053805, -8.15474437],
                            [39.28011987, -8.1548353],
                            [39.27975622, -8.15485348],
                            [39.27941075, -8.15492622],
                            [39.27913803, -8.15505353],
                            [39.27895621, -8.15518083],
                            [39.27873801, -8.15539906],
                            [39.27857438, -8.1556173],
                            [39.27855396, -8.15567175],
                            [39.27852014, -8.15563177],
                            [39.27846751, -8.15544747],
                            [39.27852015, -8.15519735],
                            [39.27861228, -8.15498672],
                            [39.27877022, -8.15472342],
                            [39.27903346, -8.15452596],
                            [39.27929668, -8.15432849],
                            [39.2795336, -8.15418368],
                            [39.27975735, -8.15398621],
                            [39.2798758, -8.15385456],
                            [39.2798758, -8.15367026],
                            [39.27979684, -8.15347278],
                            [39.2797047, -8.15319633],
                            [39.27977051, -8.15299887],
                            [39.27995477, -8.15278824],
                            [39.28011272, -8.1525381],
                            [39.2801522, -8.15230115],
                            [39.28009955, -8.15206418],
                            [39.27988898, -8.15189304],
                            [39.27969155, -8.15173505],
                            [39.27966523, -8.15155075],
                            [39.27977052, -8.15139277],
                            [39.27998111, -8.15120847],
                            [39.28027064, -8.15102417],
                            [39.28058655, -8.15090569],
                            [39.28090241, -8.1508267],
                            [39.28124464, -8.15065556],
                            [39.28146838, -8.15039228],
                            [39.28167897, -8.15018165],
                            [39.28175793, -8.14999733],
                            [39.2819685, -8.14987886],
                            [39.28224491, -8.14986569],
                            [39.28242919, -8.14965505],
                            [39.28261345, -8.14939177],
                            [39.28266609, -8.14911531],
                            [39.28266609, -8.14883886],
                            [39.28269242, -8.14860189],
                            [39.28278455, -8.14841759],
                            [39.28295564, -8.14832544],
                            [39.28307411, -8.14837809],
                            [39.28319257, -8.14848342],
                            [39.2833505, -8.14865456],
                            [39.28346896, -8.14866772],
                            [39.28357424, -8.1487467],
                            [39.28367955, -8.14886519],
                            [39.28367955, -8.14902316],
                            [39.28367955, -8.14916797],
                            [39.28377167, -8.14928646],
                            [39.28382432, -8.14947076],
                            [39.28384735, -8.14962876],
                            [39.28384735, -8.14979659],
                            [39.28376838, -8.1499842],
                            [39.28362031, -8.1503495],
                            [39.28351172, -8.1506062],
                            [39.283413, -8.1508728],
                            [39.28336366, -8.15107026],
                            [39.28339327, -8.15126773],
                            [39.28354134, -8.15154419],
                            [39.28370913, -8.1516923],
                            [39.28381773, -8.15177128],
                            [39.28385721, -8.15192925],
                            [39.28377823, -8.15212672],
                            [39.28381773, -8.15230444],
                            [39.28391643, -8.15248216],
                            [39.2842027, -8.15283761],
                            [39.28453832, -8.15307457],
                            [39.28487393, -8.1532128],
                            [39.28517994, -8.15330167],
                            [39.28546622, -8.15338067],
                            [39.2856439, -8.1534399],
                            [39.28579196, -8.15349914],
                            [39.28587094, -8.15357813],
                            [39.28593016, -8.15371636],
                            [39.28601901, -8.15395332],
                            [39.2860881, -8.15420016],
                            [39.28623617, -8.15455561],
                            [39.28630526, -8.15481231],
                            [39.28630526, -8.15498019],
                            [39.28622629, -8.15510852],
                            [39.28610785, -8.15524675],
                            [39.28600913, -8.15536522],
                            [39.28594001, -8.15547384],
                            [39.28588081, -8.15561207],
                            [39.28591041, -8.15572066],
                            [39.28600913, -8.15582928],
                            [39.28609797, -8.15599712],
                            [39.28612757, -8.15614523],
                            [39.28618681, -8.15626372],
                            [39.28622629, -8.15639207],
                            [39.28633488, -8.15644144],
                            [39.2864632, -8.15644144],
                            [39.28658165, -8.15635258],
                            [39.28662113, -8.15624397],
                            [39.28671985, -8.15620448],
                            [39.28683831, -8.15621435],
                            [39.28694689, -8.15627358],
                            [39.28710483, -8.15637232],
                            [39.28716405, -8.15654018],
                            [39.28719367, -8.15672776],
                            [39.28726276, -8.15687587],
                            [39.28738122, -8.15699436],
                            [39.28756878, -8.15706347],
                            [39.28773659, -8.15709308],
                            [39.28795375, -8.1570141],
                            [39.28816105, -8.15680675],
                            [39.28832886, -8.15651056],
                            [39.28854603, -8.15624398],
                            [39.28881255, -8.15585891],
                            [39.28894089, -8.15557259],
                            [39.28895075, -8.15530601],
                            [39.28884218, -8.15505916],
                            [39.28871385, -8.15492093],
                            [39.28861514, -8.15495055],
                            [39.28847694, -8.15491106],
                            [39.28831899, -8.15484195],
                            [39.28820055, -8.15467409],
                            [39.2880426, -8.15446675],
                            [39.28803274, -8.15427916],
                            [39.28805248, -8.15412117],
                            [39.28816107, -8.15396321],
                            [39.288319, -8.15387435],
                            [39.2884572, -8.15376573],
                            [39.28854604, -8.15359789],
                            [39.2885954, -8.15330168],
                            [39.28863488, -8.15302522],
                            [39.2887336, -8.15268954],
                            [39.28888165, -8.15234394],
                            [39.28902974, -8.15208725],
                            [39.28924691, -8.15187004],
                            [39.28947395, -8.15163306],
                            [39.28965163, -8.15138624],
                            [39.28975034, -8.15116902],
                            [39.2897997, -8.15088269],
                            [39.28980958, -8.15062599],
                            [39.28977996, -8.15031991],
                            [39.28970099, -8.15023104],
                            [39.28958254, -8.15008295],
                            [39.28950356, -8.14986572],
                            [39.28941471, -8.14962878],
                            [39.28929628, -8.14942142],
                            [39.28919756, -8.14922396],
                            [39.28907911, -8.14909559],
                            [39.28887181, -8.14894748],
                            [39.28870399, -8.14881911],
                            [39.28858554, -8.14870065],
                            [39.28856581, -8.14859204],
                            [39.28856581, -8.14847357],
                            [39.28859542, -8.14832546],
                            [39.28872374, -8.14820699],
                            [39.28895078, -8.14797991],
                            [39.28915809, -8.14769356],
                            [39.28932589, -8.14730849],
                            [39.28935552, -8.14704191],
                            [39.28935552, -8.14685431],
                            [39.28929629, -8.14663709],
                            [39.28914822, -8.14652849],
                            [39.28900014, -8.14641989],
                            [39.28886195, -8.14628164],
                            [39.28878298, -8.1461138],
                            [39.28881259, -8.1458571],
                            [39.28887183, -8.14549178],
                            [39.28887183, -8.14518571],
                            [39.28887183, -8.14498825],
                            [39.28877311, -8.14484013],
                            [39.28860531, -8.1447019],
                            [39.28844736, -8.1446624],
                            [39.2882993, -8.14467228],
                            [39.28817097, -8.14473152],
                            [39.28799328, -8.14483025],
                            [39.287786, -8.14488949],
                            [39.28763792, -8.14488949],
                            [39.2875096, -8.14479075],
                            [39.28738128, -8.14471177],
                            [39.28727269, -8.14462291],
                            [39.2871641, -8.14454392],
                            [39.28698642, -8.14451429],
                            [39.28680874, -8.14456366],
                            [39.28664093, -8.14467227],
                            [39.2864435, -8.14478089],
                            [39.28620659, -8.14492897],
                            [39.28598943, -8.14512645],
                            [39.28583149, -8.14533378],
                            [39.28580187, -8.14558063],
                            [39.28581174, -8.14584721],
                            [39.2858611, -8.14619279],
                            [39.28588085, -8.14644949],
                            [39.28580186, -8.14679507],
                            [39.28567354, -8.14716039],
                            [39.28548598, -8.1473776],
                            [39.28523921, -8.14754544],
                            [39.28512075, -8.14760469],
                            [39.28491346, -8.14753556],
                            [39.28480487, -8.14742696],
                            [39.28471603, -8.14723937],
                            [39.28460744, -8.14712087],
                            [39.28446924, -8.14706164],
                            [39.28432119, -8.14704189],
                            [39.28412376, -8.14699251],
                            [39.28392633, -8.14687404],
                            [39.28380788, -8.14673582],
                            [39.28371904, -8.14657783],
                            [39.28374865, -8.14646923],
                            [39.28391646, -8.14633101],
                            [39.28411389, -8.14615329],
                            [39.28422248, -8.1459953],
                            [39.28434093, -8.14575834],
                            [39.28446925, -8.14551151],
                            [39.2846272, -8.14537328],
                            [39.28491347, -8.14522518],
                            [39.28517997, -8.14508695],
                            [39.28535766, -8.14484999],
                            [39.28545639, -8.14445507],
                            [39.28544651, -8.14405025],
                            [39.28529844, -8.14366517],
                            [39.28507141, -8.1433591],
                            [39.28484437, -8.14313201],
                            [39.28466669, -8.1430234],
                            [39.2843903, -8.14298392],
                            [39.28413365, -8.14298392],
                            [39.28399545, -8.14302339],
                            [39.28375854, -8.14316164],
                            [39.28361047, -8.14327023],
                            [39.28347227, -8.14331959],
                            [39.283186, -8.14326035],
                            [39.28301819, -8.14323072],
                            [39.28284051, -8.14323072],
                            [39.28267271, -8.14333935],
                            [39.28250488, -8.14367504],
                            [39.28243579, -8.14397123],
                            [39.2823272, -8.14429706],
                            [39.28224169, -8.14460493],
                            [39.28232917, -8.14373],
                            [39.28159794, -8.1431031],
                            [39.28118011, -8.14231947],
                            [39.28086672, -8.14148359],
                            [39.28086673, -8.14059549],
                            [39.28076228, -8.1394984],
                            [39.28039668, -8.13840132],
                            [39.27971769, -8.13819235],
                            [39.27856861, -8.13793113],
                            [39.27768071, -8.13735647],
                            [39.27736734, -8.13672957],
                            [39.27768072, -8.13568472],
                            [39.27820301, -8.13469212],
                            [39.27825525, -8.13396073],
                            [39.27809857, -8.13302037],
                            [39.27778518, -8.13239349],
                            [39.27736736, -8.13171433],
                            [39.27684505, -8.13098294],
                            [39.27611383, -8.13098294],
                            [39.27517368, -8.13103516],
                            [39.274338, -8.13113966],
                            [39.27339786, -8.13113966],
                            [39.27298002, -8.13098292],
                            [39.2724055, -8.13056498],
                            [39.27209211, -8.12972911],
                            [39.27219656, -8.12884099],
                            [39.27224881, -8.1284753],
                            [39.27214435, -8.12805736],
                            [39.27141444, -8.12808841],
                            [39.27135093, -8.12773003],
                            [39.2714707, -8.12747545],
                            [39.27141082, -8.12719093],
                            [39.27124612, -8.12719093],
                            [39.27111137, -8.12741555],
                            [39.27100657, -8.12759525],
                            [39.270782, -8.12761024],
                            [39.2706173, -8.12761024],
                            [39.27043765, -8.12773003],
                            [39.270258, -8.12787977],
                            [39.27021308, -8.12808943],
                            [39.27016855, -8.12814141],
                            [39.26961746, -8.12816485],
                            [39.26961421, -8.12811938],
                            [39.26961421, -8.12790973],
                            [39.26971901, -8.12762521],
                            [39.26997353, -8.12728077],
                            [39.27024302, -8.12699625],
                            [39.27052749, -8.12662187],
                            [39.2707371, -8.12645715],
                            [39.270782, -8.12627744],
                            [39.27061731, -8.12621754],
                            [39.27025801, -8.12621754],
                            [39.26997353, -8.12615765],
                            [39.2697789, -8.12611272],
                            [39.26958427, -8.12602286],
                            [39.26944953, -8.12579824],
                            [39.26941959, -8.12557362],
                            [39.26941959, -8.12542386],
                            [39.26961422, -8.12527411],
                            [39.26985376, -8.12519924],
                            [39.27006338, -8.12515431],
                            [39.2703628, -8.12512437],
                            [39.27067721, -8.12510938],
                            [39.27093172, -8.12513934],
                            [39.27111138, -8.12524418],
                            [39.27129105, -8.12536398],
                            [39.27147071, -8.12555864],
                            [39.27166534, -8.12572338],
                            [39.27193483, -8.12572338],
                            [39.27208455, -8.12563353],
                            [39.27218936, -8.12545383],
                            [39.2723241, -8.12531905],
                            [39.27248878, -8.12522921],
                            [39.27268342, -8.12522921],
                            [39.27289302, -8.12530409],
                            [39.2731176, -8.12543886],
                            [39.27334217, -8.12549876],
                            [39.27347691, -8.12548378],
                            [39.27377634, -8.12542388],
                            [39.27403086, -8.12533403],
                            [39.27419556, -8.12525916],
                            [39.27436024, -8.12539394],
                            [39.27448001, -8.12558861],
                            [39.27473454, -8.12558861],
                            [39.27498906, -8.12557364],
                            [39.27518369, -8.12557365],
                            [39.27539329, -8.12561856],
                            [39.27563283, -8.1257833],
                            [39.27578255, -8.12600792],
                            [39.27594723, -8.12635234],
                            [39.27608198, -8.12668179],
                            [39.27632151, -8.12689146],
                            [39.27659102, -8.12714602],
                            [39.27683055, -8.12723588],
                            [39.27701022, -8.12708612],
                            [39.27729468, -8.12707115],
                            [39.27760909, -8.12719096],
                            [39.27783366, -8.1273407],
                            [39.2781331, -8.12743057],
                            [39.27825287, -8.12725086],
                            [39.27826784, -8.12698131],
                            [39.27826784, -8.12681658],
                            [39.27835767, -8.12654703],
                            [39.27837264, -8.12623256],
                            [39.27841756, -8.12596301],
                            [39.27865711, -8.12570844],
                            [39.27885175, -8.12551376],
                            [39.27915117, -8.12527415],
                            [39.27931587, -8.12507948],
                            [39.27963027, -8.12491476],
                            [39.27979496, -8.12475003],
                            [39.27983987, -8.12451044],
                            [39.27983987, -8.12407615],
                            [39.27988479, -8.12371676],
                            [39.27992971, -8.1232675],
                            [39.28001954, -8.12287815],
                            [39.28003451, -8.1225487],
                            [39.28007943, -8.12223423],
                            [39.28016927, -8.1218898],
                            [39.28028904, -8.12163523],
                            [39.28040881, -8.1214106],
                            [39.28055853, -8.12121592],
                            [39.28069327, -8.12096134],
                            [39.28090289, -8.12078166],
                            [39.28082802, -8.1205121],
                            [39.28075316, -8.12022757],
                            [39.28051363, -8.11982324],
                            [39.28021419, -8.11944887],
                            [39.2799447, -8.11932908],
                            [39.27975007, -8.11938897],
                            [39.27954047, -8.11956866],
                            [39.27931589, -8.11986817],
                            [39.27912126, -8.12019762],
                            [39.27889666, -8.12052707],
                            [39.27859725, -8.12088645],
                            [39.2782529, -8.12120092],
                            [39.27777379, -8.12144056],
                            [39.27713002, -8.12169511],
                            [39.27669583, -8.12181491],
                            [39.27635149, -8.12184486],
                            [39.27585741, -8.12184486],
                            [39.2754981, -8.12176999],
                            [39.27512382, -8.12159028],
                            [39.2748693, -8.1214555],
                            [39.27462975, -8.12144055],
                            [39.27421053, -8.12151539],
                            [39.2738662, -8.12151539],
                            [39.27362664, -8.12147047],
                            [39.27332722, -8.12121589],
                            [39.27304275, -8.12090142],
                            [39.27280321, -8.12061689],
                            [39.27265349, -8.12028743],
                            [39.27254869, -8.11992804],
                            [39.2725487, -8.11952371],
                            [39.27250378, -8.11908941],
                            [39.27248881, -8.11858029],
                            [39.27251877, -8.11813103],
                            [39.27262356, -8.11784651],
                            [39.27278826, -8.11753203],
                            [39.27296791, -8.11714268],
                            [39.27326735, -8.11675333],
                            [39.27355181, -8.11630407],
                            [39.27391113, -8.11579493],
                            [39.27418062, -8.11536065],
                            [39.27431538, -8.11506116],
                            [39.27434532, -8.11465683],
                            [39.27428543, -8.11441723],
                            [39.2741357, -8.11422255],
                            [39.27397102, -8.11399793],
                            [39.27376142, -8.11371339],
                            [39.27356679, -8.11345883],
                            [39.27335719, -8.11308444],
                            [39.27313262, -8.11266515],
                            [39.27299788, -8.11229078],
                            [39.27275833, -8.11187147],
                            [39.27263856, -8.11151205],
                            [39.27239902, -8.11115266],
                            [39.27212953, -8.11082321],
                            [39.27174027, -8.11061356],
                            [39.271351, -8.11044882],
                            [39.27094677, -8.11020923],
                            [39.2705575, -8.10996962],
                            [39.27022811, -8.1096851],
                            [39.2699137, -8.10931071],
                            [39.26952444, -8.10890639],
                            [39.26910526, -8.10848709],
                            [39.26879085, -8.10815763],
                            [39.26850638, -8.10773834],
                            [39.26828182, -8.10724415],
                            [39.26817702, -8.10682485],
                            [39.26817702, -8.10657027],
                            [39.26807223, -8.10616595],
                            [39.2679225, -8.10576162],
                            [39.26769793, -8.1054172],
                            [39.26739851, -8.10511769],
                            [39.26703918, -8.10481819],
                            [39.26678465, -8.10456361],
                            [39.26656009, -8.1042641],
                            [39.26630557, -8.10400954],
                            [39.26603609, -8.10376995],
                            [39.26572168, -8.10362018],
                            [39.26537733, -8.10360521],
                            [39.26507791, -8.10360521],
                            [39.26474853, -8.10360521],
                            [39.26414966, -8.10360521],
                            [39.26340107, -8.1036052],
                            [39.26274233, -8.10363514],
                            [39.26215843, -8.10368007],
                            [39.26173921, -8.10368007],
                            [39.26140985, -8.10375494],
                            [39.26112537, -8.10385976],
                            [39.26091577, -8.10396459],
                            [39.26081098, -8.10417423],
                            [39.26073611, -8.10439889],
                            [39.26057142, -8.10463846],
                            [39.26027198, -8.10493797],
                            [39.25983781, -8.10534229],
                            [39.25946351, -8.10570168],
                            [39.25910419, -8.10601616],
                            [39.25870162, -8.10622581],
                            [39.25811604, -8.10662515],
                            [39.25755711, -8.1070511],
                            [39.25707802, -8.10737056],
                            [39.25659893, -8.10763678],
                            [39.25611984, -8.1077699],
                            [39.25564073, -8.10787638],
                            [39.2550818, -8.10784975],
                            [39.25468255, -8.10776989],
                            [39.25446961, -8.10763677],
                            [39.25417682, -8.10745041],
                            [39.25415065, -8.10742186],
                            [39.25516242, -8.10691587],
                            [39.25644919, -8.10654816],
                            [39.25706192, -8.10569011],
                            [39.25785847, -8.10458694],
                            [39.25847124, -8.10434179],
                            [39.25945163, -8.10360633],
                            [39.260432, -8.10244185],
                            [39.26055458, -8.10109352],
                            [39.26055458, -8.09999033],
                            [39.26079967, -8.09876456],
                            [39.26159627, -8.09796784],
                            [39.26282172, -8.09778396],
                            [39.26398595, -8.09882588],
                            [39.26429229, -8.10054193],
                            [39.2647825, -8.10207414],
                            [39.26502499, -8.10234699],
                            [39.26576286, -8.10317734],
                            [39.26790749, -8.1044644],
                            [39.26888786, -8.10483213],
                            [39.27023651, -8.10413269],
                            [39.27024214, -8.10413644],
                            [39.27036661, -8.10433567],
                            [39.27052843, -8.10454731],
                            [39.27066537, -8.10475898],
                            [39.27091432, -8.105008],
                            [39.27105124, -8.10521964],
                            [39.27122551, -8.10538149],
                            [39.27141222, -8.10559317],
                            [39.2715865, -8.10582973],
                            [39.271823, -8.10604139],
                            [39.27212175, -8.1062406],
                            [39.27237071, -8.10642737],
                            [39.27265701, -8.10657678],
                            [39.27289352, -8.10673864],
                            [39.27303043, -8.10687559],
                            [39.27322958, -8.10707481],
                            [39.27332919, -8.10726158],
                            [39.27341632, -8.10749812],
                            [39.27345366, -8.10778451],
                            [39.27355324, -8.10812066],
                            [39.27359058, -8.10839456],
                            [39.27361548, -8.10869338],
                            [39.2736379, -8.10902549],
                            [39.27364815, -8.10933326],
                            [39.27364815, -8.10961025],
                            [39.27364815, -8.10987699],
                            [39.27370969, -8.11002061],
                            [39.27385328, -8.11005141],
                            [39.2740174, -8.11006165],
                            [39.27419175, -8.11003088],
                            [39.27437638, -8.10986674],
                            [39.27455074, -8.10966155],
                            [39.27475587, -8.10943585],
                            [39.274961, -8.10924094],
                            [39.27516615, -8.10908705],
                            [39.27537129, -8.10905628],
                            [39.27557642, -8.10904602],
                            [39.27575077, -8.10911783],
                            [39.2760072, -8.10923068],
                            [39.27624309, -8.10936405],
                            [39.27641746, -8.10946664],
                            [39.27660208, -8.10958975],
                            [39.2767867, -8.10964105],
                            [39.27707389, -8.10964105],
                            [39.27726877, -8.10948717],
                            [39.27736108, -8.10924095],
                            [39.27732005, -8.10895368],
                            [39.27732005, -8.10870746],
                            [39.27740211, -8.10844074],
                            [39.27756622, -8.10817402],
                            [39.27774057, -8.10790728],
                            [39.27781238, -8.10760977],
                            [39.27782263, -8.10738408],
                            [39.2777816, -8.10723017],
                            [39.27762775, -8.10712759],
                            [39.27735081, -8.10701474],
                            [39.27702262, -8.1069532],
                            [39.2768585, -8.10696345],
                            [39.27668415, -8.10701474],
                            [39.27648927, -8.10710707],
                            [39.2763149, -8.10724043],
                            [39.27613026, -8.10738407],
                            [39.27599694, -8.10753794],
                            [39.27587385, -8.10765079],
                            [39.27575078, -8.10773287],
                            [39.2756277, -8.10778416],
                            [39.27545333, -8.10778416],
                            [39.27525845, -8.10769183],
                            [39.27512513, -8.10760976],
                            [39.27500204, -8.10760976],
                            [39.2748687, -8.10762001],
                            [39.27478666, -8.10766105],
                            [39.27467384, -8.10760976],
                            [39.27459178, -8.10748664],
                            [39.27456101, -8.10734302],
                            [39.27457126, -8.10721991],
                            [39.27450973, -8.1071481],
                            [39.27440714, -8.1071481],
                            [39.27433537, -8.10720965],
                            [39.27423279, -8.10728146],
                            [39.27410972, -8.10727121],
                            [39.27398663, -8.10715836],
                            [39.27389432, -8.10699421],
                            [39.27386356, -8.10688136],
                            [39.27386356, -8.10666593],
                            [39.2738328, -8.10641971],
                            [39.27384305, -8.10619401],
                            [39.27387381, -8.10595806],
                            [39.27394562, -8.10569132],
                            [39.27404818, -8.10550668],
                            [39.27417127, -8.10533226],
                            [39.27431486, -8.10525018],
                            [39.27451999, -8.10517837],
                            [39.27480718, -8.10505527],
                            [39.27504308, -8.10494242],
                            [39.27526871, -8.10481931],
                            [39.27554566, -8.10470646],
                            [39.27572003, -8.10465515],
                            [39.27597644, -8.1045218],
                            [39.27619184, -8.10437818],
                            [39.27636619, -8.10425507],
                            [39.27661235, -8.10401912],
                            [39.27679699, -8.10385498],
                            [39.27706366, -8.10370109],
                            [39.27733033, -8.10353694],
                            [39.27759699, -8.10346513],
                            [39.27788419, -8.10343436],
                            [39.27823292, -8.10339332],
                            [39.27852011, -8.10341384],
                            [39.27884831, -8.10339332],
                            [39.2792073, -8.10337281],
                            [39.27955602, -8.10328048],
                            [39.27983296, -8.10317788],
                            [39.28002785, -8.10298297],
                            [39.28013041, -8.10277779],
                            [39.2802022, -8.10253158],
                            [39.28027401, -8.10242898],
                            [39.28037657, -8.10237769],
                            [39.28054067, -8.10236744],
                            [39.28069453, -8.10240848],
                            [39.28082786, -8.10251106],
                            [39.28100223, -8.10262391],
                            [39.28115607, -8.10278805],
                            [39.28132019, -8.10291117],
                            [39.28147404, -8.10303427],
                            [39.28160738, -8.10315739],
                            [39.28182276, -8.10327024],
                            [39.28206892, -8.10329076],
                            [39.28236636, -8.10333178],
                            [39.28260227, -8.10334205],
                            [39.2827151, -8.10338309],
                            [39.28287918, -8.1034549],
                            [39.28296126, -8.10353696],
                            [39.28297151, -8.10364981],
                            [39.28290996, -8.10377293],
                            [39.28282792, -8.10391656],
                            [39.28272534, -8.10413199],
                            [39.28258175, -8.10433717],
                            [39.28241764, -8.10451158],
                            [39.28224328, -8.10469624],
                            [39.28200737, -8.10485012],
                            [39.28182275, -8.10500399],
                            [39.28168941, -8.10510659],
                            [39.28154582, -8.10522969],
                            [39.28148428, -8.10539385],
                            [39.28148428, -8.10559903],
                            [39.28161762, -8.10571188],
                            [39.28174069, -8.1057324],
                            [39.28192532, -8.10570161],
                            [39.28210994, -8.10572213],
                            [39.28225353, -8.10579394],
                            [39.28241763, -8.10591706],
                            [39.28256123, -8.10600939],
                            [39.2827356, -8.10612225],
                            [39.2829715, -8.10616328],
                            [39.28319714, -8.10616328],
                            [39.28346382, -8.10612226],
                            [39.28364844, -8.10601964],
                            [39.28383306, -8.10589654],
                            [39.28399716, -8.10587604],
                            [39.28421256, -8.10587604],
                            [39.28439718, -8.10589655],
                            [39.28456128, -8.10593759],
                            [39.2847459, -8.10601965],
                            [39.28489975, -8.10608121],
                            [39.28509463, -8.10610173],
                            [39.28522797, -8.10613248],
                            [39.2854331, -8.10624535],
                            [39.2855972, -8.10632743],
                            [39.28564848, -8.10645053],
                            [39.28568951, -8.10660442],
                            [39.28571003, -8.10677883],
                            [39.28563823, -8.10696348],
                            [39.28557667, -8.10718918],
                            [39.28552541, -8.10742513],
                            [39.28546386, -8.10760981],
                            [39.28541258, -8.10779447],
                            [39.28539206, -8.10797913],
                            [39.28540233, -8.10820482],
                            [39.28545359, -8.10847156],
                            [39.28553565, -8.10872803],
                            [39.28556643, -8.10889217],
                            [39.28556643, -8.1090871],
                            [39.28560746, -8.10928202],
                            [39.28573053, -8.1094872],
                            [39.28581259, -8.10969238],
                            [39.28594591, -8.10986679],
                            [39.28607925, -8.11002066],
                            [39.28616131, -8.11019507],
                            [39.28626388, -8.11039],
                            [39.28634592, -8.11056439],
                            [39.28641772, -8.1107388],
                            [39.28642798, -8.11089269],
                            [39.28644849, -8.11105683],
                            [39.28651004, -8.11123123],
                            [39.28658183, -8.11138512],
                            [39.28665362, -8.11161082],
                            [39.28669465, -8.11178521],
                            [39.28671517, -8.11196989],
                            [39.28671517, -8.11223661],
                            [39.28671516, -8.11246231],
                            [39.28679721, -8.11264698],
                            [39.28683824, -8.11286241],
                            [39.28683823, -8.1131394],
                            [39.2868485, -8.11335485],
                            [39.28676644, -8.11352926],
                            [39.28665361, -8.11366261],
                            [39.28649976, -8.11382676],
                            [39.28649976, -8.11398064],
                            [39.28660233, -8.11407298],
                            [39.28669464, -8.11414479],
                            [39.28677669, -8.11425764],
                            [39.28678695, -8.11436022],
                            [39.28678695, -8.11454488],
                            [39.2867254, -8.11476033],
                            [39.28665361, -8.11492447],
                            [39.28662284, -8.11505784],
                            [39.28663309, -8.11521171],
                            [39.28669464, -8.11537586],
                            [39.28677668, -8.11548871],
                            [39.28689977, -8.11563235],
                            [39.28701259, -8.11575545],
                            [39.28715618, -8.11591959],
                            [39.28716643, -8.11603244],
                            [39.28715618, -8.1161966],
                            [39.28708438, -8.11630946],
                            [39.28692026, -8.11632995],
                            [39.28676643, -8.11618633],
                            [39.28662284, -8.11606323],
                            [39.28642796, -8.11585805],
                            [39.28623308, -8.11566312],
                            [39.28606896, -8.11549898],
                            [39.28594589, -8.11540665],
                            [39.2858023, -8.11534509],
                            [39.28565871, -8.11534509],
                            [39.28554589, -8.11536561],
                            [39.28551512, -8.11545792],
                            [39.28549461, -8.11560156],
                            [39.28547409, -8.11578622],
                            [39.2854946, -8.11598114],
                            [39.28547409, -8.11614528],
                            [39.2854946, -8.11632994],
                            [39.2854946, -8.11654539],
                            [39.28557663, -8.11670953],
                            [39.2857305, -8.11683265],
                            [39.28591511, -8.11699679],
                            [39.28607922, -8.11716093],
                            [39.28623307, -8.11734559],
                            [39.28636641, -8.11755075],
                            [39.28647923, -8.11775596],
                            [39.28657154, -8.11801243],
                            [39.28664333, -8.11821761],
                            [39.28671514, -8.11844331],
                            [39.28682796, -8.11860745],
                            [39.28699206, -8.11868953],
                            [39.28723823, -8.11871005],
                            [39.2875049, -8.11874082],
                            [39.28769977, -8.11876134],
                            [39.28787414, -8.1188229],
                            [39.28804851, -8.11890496],
                            [39.28818184, -8.11899729],
                            [39.28829467, -8.11912041],
                            [39.28840749, -8.11916145],
                            [39.28857159, -8.11914092],
                            [39.28908337, -8.11938748],
                            [39.28932403, -8.11917453],
                            [39.28988081, -8.11880859],
                            [39.29021486, -8.11844264],
                            [39.29046938, -8.11807669],
                            [39.29058074, -8.11782212],
                            [39.29066027, -8.11747208],
                            [39.29064436, -8.11710611],
                            [39.29058074, -8.11674017],
                            [39.29050828, -8.11642548],
                            [39.29047998, -8.11588805],
                            [39.29059309, -8.11523746],
                            [39.29090419, -8.11441717],
                            [39.29127184, -8.11402115],
                            [39.29166776, -8.11359687],
                            [39.29192227, -8.11339888],
                            [39.29228991, -8.11322916],
                            [39.29257271, -8.11320088],
                            [39.29277067, -8.11345544],
                            [39.29291205, -8.11387974],
                            [39.29311003, -8.11444547],
                            [39.29330797, -8.11478491],
                            [39.29353421, -8.1149829],
                            [39.29367561, -8.11447374],
                            [39.29378874, -8.11393631],
                            [39.29393012, -8.11365347],
                            [39.29407154, -8.11337059],
                            [39.29446745, -8.1133423],
                            [39.29480681, -8.11368174],
                            [39.29483508, -8.11424747],
                            [39.29489164, -8.11509605],
                            [39.29489164, -8.1158315],
                            [39.29503304, -8.11636893],
                            [39.29551377, -8.11651036],
                            [39.29582487, -8.1162275],
                            [39.29610767, -8.11594464],
                            [39.29650359, -8.1156052],
                            [39.29684295, -8.11535064],
                            [39.29723888, -8.11535064],
                            [39.29746512, -8.11554865],
                            [39.29766306, -8.11600123],
                            [39.29757822, -8.11639723],
                            [39.29757821, -8.11690638],
                            [39.29760651, -8.11758524],
                            [39.29774789, -8.11806611],
                            [39.29820036, -8.11837725],
                            [39.29873768, -8.11843384],
                            [39.29907704, -8.11857528],
                            [39.2996709, -8.11882984],
                            [39.30016543, -8.11897412],
                            [39.30034963, -8.11902785],
                            [39.30102835, -8.11914102],
                            [39.30162222, -8.11905616],
                            [39.30198984, -8.11882985],
                            [39.30230094, -8.11832071],
                            [39.30266859, -8.11767014],
                            [39.3030645, -8.11704784],
                            [39.3033473, -8.1167084],
                            [39.30374319, -8.11656697],
                            [39.30405427, -8.11679327],
                            [39.3041957, -8.11733069],
                            [39.3041957, -8.11775501],
                            [39.30442191, -8.11820758],
                            [39.30476127, -8.118547],
                            [39.30515719, -8.11866015],
                            [39.30532685, -8.11815101],
                            [39.30552483, -8.11758528],
                            [39.30563797, -8.11713271],
                            [39.30583592, -8.11668013],
                            [39.3062884, -8.1163124],
                            [39.30665605, -8.11608613],
                            [39.30727818, -8.11602954],
                            [39.30795692, -8.11605783],
                            [39.30855078, -8.11594469],
                            [39.30897496, -8.11566184],
                            [39.30931432, -8.11523755],
                            [39.30954059, -8.11475668],
                            [39.30962544, -8.11416268],
                            [39.30965372, -8.11348382],
                            [39.3098234, -8.11297464],
                            [39.31013449, -8.11229578],
                            [39.310389, -8.11161692],
                            [39.31084148, -8.11110777],
                            [39.31120912, -8.11059863],
                            [39.31180301, -8.11014605],
                            [39.31236858, -8.10966516],
                            [39.31293417, -8.10926917],
                            [39.31352807, -8.10912776],
                            [39.31415022, -8.1090429],
                            [39.31522485, -8.10907118],
                            [39.31596013, -8.10918433],
                            [39.31599141, -8.10917951],
                            [39.31599286, -8.10917929],
                            [39.31632777, -8.10912777],
                            [39.31675196, -8.10844888],
                            [39.31717616, -8.10796803],
                            [39.31722218, -8.10790525],
                            [39.31723393, -8.10699988],
                            [39.31723393, -8.10676013],
                            [39.31723393, -8.10657252],
                            [39.31728604, -8.10639531],
                            [39.31735898, -8.10623897],
                            [39.31742151, -8.10609302],
                            [39.31753614, -8.10592627],
                            [39.31764034, -8.10574907],
                            [39.3177654, -8.10556144],
                            [39.31789048, -8.10541551],
                            [39.31795299, -8.10525915],
                            [39.31798425, -8.10509239],
                            [39.31802594, -8.10490477],
                            [39.31804678, -8.10475883],
                            [39.31802594, -8.10461291],
                            [39.31794257, -8.10446697],
                            [39.31782795, -8.10433146],
                            [39.31767163, -8.10423765],
                            [39.31757782, -8.10408131],
                            [39.31758826, -8.1039458],
                            [39.31766121, -8.10384156],
                            [39.31782795, -8.1037269],
                            [39.31797384, -8.10361225],
                            [39.31813016, -8.10354971],
                            [39.31827605, -8.10345588],
                            [39.31831775, -8.10330996],
                            [39.31840111, -8.10314318],
                            [39.31840111, -8.10296599],
                            [39.31832816, -8.10290345],
                            [39.31816143, -8.10280964],
                            [39.31798426, -8.10271582],
                            [39.31788005, -8.1024865],
                            [39.31789049, -8.10227804],
                            [39.31792174, -8.10214254],
                            [39.3180468, -8.10199661],
                            [39.3181927, -8.10188195],
                            [39.31843239, -8.10167349],
                            [39.3185991, -8.10162137],
                            [39.3187346, -8.10151712],
                            [39.31882838, -8.10138162],
                            [39.3188388, -8.10123571],
                            [39.31880755, -8.10108976],
                            [39.31870332, -8.10096469],
                            [39.31859911, -8.10089172],
                            [39.31841154, -8.10087085],
                            [39.31828648, -8.10096468],
                            [39.31820312, -8.10106892],
                            [39.31803638, -8.10113146],
                            [39.31789049, -8.10113146],
                            [39.31771333, -8.1010585],
                            [39.31754659, -8.1009751],
                            [39.31737984, -8.10086044],
                            [39.31720268, -8.10064156],
                            [39.31697342, -8.10043308],
                            [39.31681712, -8.1002246],
                            [39.31665038, -8.10001613],
                            [39.31648363, -8.09982851],
                            [39.31647321, -8.09962005],
                            [39.31649404, -8.09942198],
                            [39.31649404, -8.09927607],
                            [39.31644193, -8.0991614],
                            [39.31632733, -8.09907803],
                            [39.31621269, -8.09897379],
                            [39.3160772, -8.0988487],
                            [39.31598342, -8.09875489],
                            [39.31598342, -8.098536],
                            [39.31598343, -8.09832753],
                            [39.31599385, -8.09817119],
                            [39.31605638, -8.09795229],
                            [39.31607721, -8.09772296],
                            [39.31606679, -8.09742069],
                            [39.31601468, -8.09721221],
                            [39.31587922, -8.09716009],
                            [39.315775, -8.09720179],
                            [39.31566036, -8.09734772],
                            [39.31554574, -8.09763957],
                            [39.31550406, -8.09798354],
                            [39.31550405, -8.09827541],
                            [39.315577, -8.09852556],
                            [39.31559783, -8.09873404],
                            [39.31563951, -8.09896336],
                            [39.31562911, -8.09917182],
                            [39.31556658, -8.09941157],
                            [39.3154311, -8.09952622],
                            [39.31530606, -8.09945327],
                            [39.31516014, -8.09932818],
                            [39.3150351, -8.09921352],
                            [39.31487876, -8.09913014],
                            [39.31470163, -8.09913014],
                            [39.31452446, -8.09909885],
                            [39.31442025, -8.09905716],
                            [39.3143473, -8.09894249],
                            [39.31428479, -8.098807],
                            [39.31428479, -8.0986298],
                            [39.31433688, -8.09848388],
                            [39.31437857, -8.09833793],
                            [39.31433688, -8.09823371],
                            [39.31427435, -8.09807735],
                            [39.31417015, -8.09798353],
                            [39.3140451, -8.09788974],
                            [39.3139096, -8.09774379],
                            [39.31370119, -8.09739983],
                            [39.31364909, -8.09723305],
                            [39.31357615, -8.09708713],
                            [39.31340941, -8.09690993],
                            [39.313201, -8.09673272],
                            [39.31300297, -8.09660765],
                            [39.31277372, -8.09650341],
                            [39.31244025, -8.0963679],
                            [39.3122214, -8.09628451],
                            [39.31194003, -8.09622196],
                            [39.31168992, -8.09620111],
                            [39.31151275, -8.09609688],
                            [39.31135645, -8.09596138],
                            [39.31122097, -8.09584672],
                            [39.31116886, -8.09569036],
                            [39.31116886, -8.09560697],
                            [39.31123139, -8.09547148],
                            [39.31136687, -8.09533595],
                            [39.31152319, -8.09527341],
                            [39.31162739, -8.09517961],
                            [39.31184624, -8.09503368],
                            [39.31190877, -8.09488776],
                            [39.31189835, -8.09467929],
                            [39.31187751, -8.09442911],
                            [39.31189835, -8.09429362],
                            [39.31197131, -8.09408514],
                            [39.31210677, -8.09392879],
                            [39.31227351, -8.09374118],
                            [39.31250279, -8.09361609],
                            [39.31267994, -8.09358482],
                            [39.3128571, -8.09344931],
                            [39.31297174, -8.09329295],
                            [39.31303427, -8.09313661],
                            [39.31307594, -8.09293858],
                            [39.31304469, -8.09270925],
                            [39.31296132, -8.09254247],
                            [39.31277373, -8.09239655],
                            [39.31259659, -8.09230274],
                            [39.31246109, -8.09219848],
                            [39.31215889, -8.09206299],
                            [39.31188794, -8.09192749],
                            [39.31164827, -8.09183366],
                            [39.31135647, -8.09169817],
                            [39.31102298, -8.0915835],
                            [39.31071035, -8.09147926],
                            [39.31032477, -8.09139587],
                            [39.31011634, -8.09132292],
                            [39.30990793, -8.09123951],
                            [39.30970991, -8.09110402],
                            [39.30952234, -8.09094766],
                            [39.30928265, -8.09076003],
                            [39.30914718, -8.09061411],
                            [39.30887624, -8.09038479],
                            [39.30861571, -8.09014505],
                            [39.30840729, -8.08986361],
                            [39.30821971, -8.08968641],
                            [39.30811549, -8.0894571],
                            [39.30805297, -8.08925904],
                            [39.30798003, -8.0890193],
                            [39.30793833, -8.08871702],
                            [39.30787582, -8.08846686],
                            [39.3078654, -8.08824798],
                            [39.30777159, -8.08799779],
                            [39.30770908, -8.08781018],
                            [39.30760487, -8.08756002],
                            [39.30754234, -8.08735156],
                            [39.30750067, -8.08718478],
                            [39.30745897, -8.08702841],
                            [39.30735475, -8.08690334],
                            [39.30724012, -8.08678868],
                            [39.30702128, -8.08669488],
                            [39.30681286, -8.08659064],
                            [39.30661486, -8.08653852],
                            [39.30635434, -8.08651767],
                            [39.30607296, -8.0864968],
                            [39.30581244, -8.08645512],
                            [39.30558317, -8.08637173],
                            [39.30542685, -8.08624664],
                            [39.30523928, -8.08614242],
                            [39.30512464, -8.08606944],
                            [39.3048537, -8.08605901],
                            [39.30458274, -8.08610072],
                            [39.30430136, -8.0860903],
                            [39.30404084, -8.08607986],
                            [39.30383243, -8.08606943],
                            [39.30363441, -8.08605901],
                            [39.30343641, -8.08612155],
                            [39.30323842, -8.08623621],
                            [39.30318631, -8.08644469],
                            [39.30321756, -8.08666357],
                            [39.3032801, -8.08680952],
                            [39.30337388, -8.08693459],
                            [39.30350936, -8.08713264],
                            [39.30361357, -8.08734111],
                            [39.30371779, -8.08754958],
                            [39.30385325, -8.08784143],
                            [39.30390535, -8.08809161],
                            [39.30394495, -8.08830632],
                            [39.30393662, -8.08853147],
                            [39.30391995, -8.08873995],
                            [39.30389495, -8.08894006],
                            [39.30384502, -8.08908984],
                            [39.30383882, -8.08908123],
                            [39.30279715, -8.08840705],
                            [39.3017555, -8.08852963],
                            [39.30046874, -8.0891425],
                            [39.29967216, -8.08908121],
                            [39.29899816, -8.08871348],
                            [39.29850796, -8.08773286],
                            [39.29801775, -8.08687484],
                            [39.29654717, -8.0860168],
                            [39.29544423, -8.08522005],
                            [39.29489278, -8.08411686],
                            [39.29397366, -8.08325881],
                            [39.29323836, -8.08258463],
                            [39.29311586, -8.08160403],
                            [39.29317713, -8.07982667],
                            [39.29256439, -8.07835575],
                            [39.29195163, -8.07651711],
                            [39.29238058, -8.07559778],
                            [39.29262569, -8.07443332],
                            [39.2923193, -8.0733914],
                            [39.29140018, -8.07283981],
                            [39.29023599, -8.07283981],
                            [39.28888796, -8.07259465],
                            [39.28803012, -8.07155275],
                            [39.28747866, -8.07020442],
                            [39.28664533, -8.06805931],
                            [39.28523602, -8.06615939],
                            [39.28315273, -8.06444331],
                            [39.2814983, -8.06370784],
                            [39.27904734, -8.06248207],
                            [39.27782188, -8.06193046],
                            [39.27579982, -8.06156273],
                            [39.27341012, -8.06052084],
                            [39.2705302, -8.05904988],
                            [39.26826309, -8.05800799],
                            [39.26568957, -8.05665965],
                            [39.26348371, -8.05567902],
                            [39.26132685, -8.05449248],
                            [39.26030723, -8.05386487],
                            [39.25862097, -8.0534334],
                            [39.25673863, -8.05260967],
                            [39.25579745, -8.05166829],
                            [39.25493472, -8.05076612],
                            [39.25395432, -8.04943249],
                            [39.25277787, -8.0480204],
                            [39.25214985, -8.0474063],
                            [39.25181844, -8.0471723],
                            [39.25158451, -8.04689934],
                            [39.25146755, -8.04656786],
                            [39.25137007, -8.04615839],
                            [39.25117511, -8.04578791],
                            [39.25116707, -8.04575961],
                            [39.25118929, -8.04572399],
                            [39.2513562, -8.04488958],
                            [39.25160648, -8.04397169],
                            [39.25177329, -8.04255316],
                            [39.2517733, -8.0411346],
                            [39.2516065, -8.03979952],
                            [39.25135623, -8.03863133],
                            [39.25093907, -8.03754658],
                            [39.25027172, -8.03662869],
                            [39.2503551, -8.03495984],
                            [39.25068881, -8.03320754],
                            [39.25118935, -8.03170556],
                            [39.25110596, -8.03078766],
                            [39.25043858, -8.02861814],
                            [39.25018831, -8.02736652],
                            [39.24952093, -8.02653208],
                            [39.24918725, -8.02569764],
                            [39.2487701, -8.02394535],
                            [39.24743533, -8.02177583],
                            [39.24534976, -8.01885529],
                            [39.24343102, -8.01568446],
                            [39.24234651, -8.01301429],
                            [39.241262, -8.01159574],
                            [39.24117857, -8.01067786],
                            [39.24184597, -8.00950967],
                            [39.24284706, -8.00909245],
                            [39.24345088, -8.00868983],
                            [39.24246035, -8.00581659],
                            [39.24205732, -8.00480878],
                            [39.24165429, -8.00239002],
                            [39.240949, -8.00047516],
                            [39.240949, -7.99926576],
                            [39.24042773, -7.99891816],
                            [39.24044144, -7.99891372],
                            [39.24584968, -7.99622811],
                            [39.24613344, -7.99562498],
                            [39.246701, -7.99466707],
                            [39.24709117, -7.9939575],
                            [39.24776508, -7.99328343],
                            [39.24787152, -7.99246743],
                            [39.24769413, -7.99175785],
                            [39.2480843, -7.99055159],
                            [39.24808543, -7.99052479],
                            [39.24809768, -7.9905225],
                            [39.24825069, -7.99048421],
                            [39.2483884, -7.99041535],
                            [39.248572, -7.99033118],
                            [39.24871736, -7.99033118],
                            [39.24887038, -7.9903771],
                            [39.24900808, -7.9904077],
                            [39.2491764, -7.99046125],
                            [39.2493753, -7.9905531],
                            [39.24952831, -7.99062962],
                            [39.24973486, -7.99068319],
                            [39.24992613, -7.99072145],
                            [39.25015564, -7.99068319],
                            [39.25036987, -7.99062198],
                            [39.25055347, -7.99046126],
                            [39.25069118, -7.99026232],
                            [39.25083654, -7.99006336],
                            [39.2509054, -7.98988736],
                            [39.25097424, -7.98972667],
                            [39.25100485, -7.98951241],
                            [39.25091304, -7.98935173],
                            [39.25077534, -7.98916041],
                            [39.25069119, -7.98903032],
                            [39.25056877, -7.98890022],
                            [39.25036988, -7.988839],
                            [39.25014035, -7.9888084],
                            [39.24997205, -7.98876249],
                            [39.24975782, -7.98870127],
                            [39.24955127, -7.98850996],
                            [39.24942121, -7.98833397],
                            [39.24932176, -7.98818092],
                            [39.24928542, -7.98799536],
                            [39.24927586, -7.98775622],
                            [39.24935236, -7.98759361],
                            [39.24953407, -7.98744059],
                            [39.24976358, -7.98736406],
                            [39.25002179, -7.98723014],
                            [39.25024172, -7.98701971],
                            [39.25035649, -7.98684754],
                            [39.25049993, -7.98647449],
                            [39.25052861, -7.98625451],
                            [39.25057644, -7.98605363],
                            [39.25056686, -7.98587189],
                            [39.25050949, -7.98569972],
                            [39.25050949, -7.98544145],
                            [39.25047125, -7.9851832],
                            [39.25052862, -7.98491537],
                            [39.25069121, -7.98472407],
                            [39.25086334, -7.98451363],
                            [39.25087291, -7.98429363],
                            [39.25096853, -7.98405451],
                            [39.25109285, -7.98395885],
                            [39.2513128, -7.98394929],
                            [39.2516475, -7.98399711],
                            [39.25189615, -7.98410233],
                            [39.25211609, -7.98425536],
                            [39.25234561, -7.98430322],
                            [39.25271857, -7.9843893],
                            [39.25305329, -7.98443712],
                            [39.25334017, -7.9843893],
                            [39.25362706, -7.98421711],
                            [39.25393308, -7.98406409],
                            [39.25415301, -7.98385364],
                            [39.25428691, -7.98361452],
                            [39.25443035, -7.98339451],
                            [39.25451642, -7.98315539],
                            [39.25455467, -7.98298322],
                            [39.25456425, -7.98282061],
                            [39.25451642, -7.98271539],
                            [39.25443035, -7.98261973],
                            [39.25430604, -7.98258147],
                            [39.25424867, -7.98248583],
                            [39.25432517, -7.98236149],
                            [39.25438254, -7.98219887],
                            [39.25438254, -7.98203626],
                            [39.25430605, -7.98189278],
                            [39.25422955, -7.98179712],
                            [39.25408609, -7.98169191],
                            [39.25395222, -7.98169191],
                            [39.25382789, -7.98175886],
                            [39.25369401, -7.98179712],
                            [39.25359837, -7.98181626],
                            [39.2534645, -7.9818067],
                            [39.25336887, -7.98173973],
                            [39.25323498, -7.98157712],
                            [39.25311067, -7.98143364],
                            [39.25294809, -7.98126148],
                            [39.25275683, -7.98115624],
                            [39.25257513, -7.98109886],
                            [39.25229782, -7.98104147],
                            [39.25208742, -7.98100321],
                            [39.25176228, -7.98090755],
                            [39.25149452, -7.98079277],
                            [39.25135106, -7.98067798],
                            [39.25119807, -7.98057277],
                            [39.25107373, -7.98042931],
                            [39.25094942, -7.98026668],
                            [39.25084424, -7.98010407],
                            [39.25073904, -7.97991277],
                            [39.25062428, -7.97978842],
                            [39.2505382, -7.97976929],
                            [39.25040433, -7.97976929],
                            [39.25028956, -7.97977885],
                            [39.25017481, -7.9798458],
                            [39.25006963, -7.97991277],
                            [39.2499453, -7.98007538],
                            [39.24983054, -7.98026668],
                            [39.24976361, -7.9804293],
                            [39.24962972, -7.98063015],
                            [39.24944801, -7.98082146],
                            [39.24927589, -7.98093624],
                            [39.24913243, -7.98093623],
                            [39.24887423, -7.9809458],
                            [39.24864473, -7.9809458],
                            [39.24841522, -7.98087885],
                            [39.24828132, -7.98074493],
                            [39.24811877, -7.98057276],
                            [39.24789881, -7.98054406],
                            [39.24771711, -7.98051537],
                            [39.24758322, -7.98051537],
                            [39.24743978, -7.98051537],
                            [39.24724853, -7.98055362],
                            [39.24706682, -7.9806684],
                            [39.24691381, -7.98080231],
                            [39.24678949, -7.98093622],
                            [39.24668429, -7.98109883],
                            [39.24669385, -7.98137623],
                            [39.24674168, -7.98169188],
                            [39.24681817, -7.98183536],
                            [39.24691381, -7.98197884],
                            [39.24706682, -7.98214145],
                            [39.24720069, -7.98223709],
                            [39.24741108, -7.98232317],
                            [39.24752583, -7.98242841],
                            [39.24754497, -7.98260057],
                            [39.24745889, -7.9826771],
                            [39.24739196, -7.98283971],
                            [39.24735371, -7.98302144],
                            [39.24734414, -7.9831745],
                            [39.24738239, -7.98341361],
                            [39.24751626, -7.98367188],
                            [39.24765016, -7.98385361],
                            [39.24788923, -7.98398753],
                            [39.24816656, -7.98408319],
                            [39.24833868, -7.98402581],
                            [39.24852995, -7.98393015],
                            [39.24872121, -7.98379623],
                            [39.24890291, -7.98370058],
                            [39.24910373, -7.98369101],
                            [39.249295, -7.98369101],
                            [39.24953409, -7.98381537],
                            [39.24962014, -7.98406407],
                            [39.24975403, -7.98429363],
                            [39.24978271, -7.98447536],
                            [39.24982096, -7.98462841],
                            [39.24985921, -7.98475276],
                            [39.24986877, -7.98499188],
                            [39.24977315, -7.98514494],
                            [39.24960101, -7.98530753],
                            [39.24949582, -7.98548929],
                            [39.24927587, -7.98565188],
                            [39.24915155, -7.98587189],
                            [39.24894116, -7.98617796],
                            [39.24881684, -7.9864171],
                            [39.24868295, -7.98662754],
                            [39.24863512, -7.98692405],
                            [39.24850125, -7.98711536],
                            [39.24841519, -7.98734492],
                            [39.24843432, -7.98758404],
                            [39.24856819, -7.98786144],
                            [39.24879771, -7.98805274],
                            [39.24896984, -7.9882345],
                            [39.24906546, -7.98838752],
                            [39.24910371, -7.98856928],
                            [39.24903677, -7.98870319],
                            [39.24883595, -7.98866492],
                            [39.24867338, -7.98849275],
                            [39.24851081, -7.98826319],
                            [39.24843431, -7.98812926],
                            [39.24824857, -7.98810012],
                            [39.24817386, -7.98824957],
                            [39.24817385, -7.98847376],
                            [39.24822988, -7.98877267],
                            [39.24817385, -7.98894081],
                            [39.24802444, -7.98905289],
                            [39.2479291, -7.98910057],
                            [39.24787154, -7.9889196],
                            [39.24780057, -7.98799717],
                            [39.24787154, -7.98718117],
                            [39.24836812, -7.98657803],
                            [39.24858093, -7.98622325],
                            [39.24886467, -7.98540725],
                            [39.24886567, -7.98539131],
                            [39.24890015, -7.98483962],
                            [39.24854547, -7.98444935],
                            [39.24854001, -7.98444726],
                            [39.24799091, -7.9844489],
                            [39.24782953, -7.98456997],
                            [39.24752694, -7.98464057],
                            [39.24743616, -7.98493314],
                            [39.24732521, -7.98528624],
                            [39.24700246, -7.98552836],
                            [39.24664944, -7.98554853],
                            [39.24641745, -7.98572005],
                            [39.2461653, -7.98595208],
                            [39.24577195, -7.9857604],
                            [39.24525755, -7.98530641],
                            [39.24497514, -7.98490286],
                            [39.24478349, -7.98452959],
                            [39.24473307, -7.98396464],
                            [39.24473307, -7.98360145],
                            [39.24471289, -7.98296587],
                            [39.24473308, -7.98255225],
                            [39.24463221, -7.98226978],
                            [39.244491, -7.98209826],
                            [39.24432963, -7.98176534],
                            [39.24406739, -7.98135171],
                            [39.24399679, -7.98105914],
                            [39.24399679, -7.98084729],
                            [39.24410774, -7.98067579],
                            [39.24423886, -7.98042357],
                            [39.24430947, -7.98025207],
                            [39.24456161, -7.98003011],
                            [39.24468266, -7.97981826],
                            [39.2447129, -7.97962658],
                            [39.24469273, -7.97944499],
                            [39.24458179, -7.97928358],
                            [39.24427921, -7.97902126],
                            [39.24373456, -7.97875896],
                            [39.24344205, -7.97861773],
                            [39.24299826, -7.97865808],
                            [39.24271585, -7.97868834],
                            [39.24247378, -7.97865808],
                            [39.24215101, -7.97843613],
                            [39.24184843, -7.97812338],
                            [39.24158618, -7.97775011],
                            [39.24143492, -7.97727594],
                            [39.24140466, -7.97691277],
                            [39.24148533, -7.97656975],
                            [39.24175766, -7.97625701],
                            [39.24198965, -7.97606533],
                            [39.24238301, -7.97576268],
                            [39.24267552, -7.97544994],
                            [39.24287723, -7.97519772],
                            [39.24295793, -7.97489507],
                            [39.24294784, -7.97454197],
                            [39.24292767, -7.97419896],
                            [39.24291759, -7.97389631],
                            [39.24294784, -7.97366428],
                            [39.2429176, -7.97331118],
                            [39.24296802, -7.97299842],
                            [39.24286716, -7.9725041],
                            [39.24255449, -7.97194923],
                            [39.24231243, -7.97159613],
                            [39.24195941, -7.97148515],
                            [39.24164675, -7.97148515],
                            [39.24141476, -7.97163647],
                            [39.24132398, -7.97184834],
                            [39.24136432, -7.97207029],
                            [39.24141476, -7.97220144],
                            [39.24164674, -7.97249399],
                            [39.24173749, -7.97266553],
                            [39.2417476, -7.97285719],
                            [39.24162656, -7.9731195],
                            [39.24137439, -7.97338178],
                            [39.24105164, -7.97360371],
                            [39.24071879, -7.9737248],
                            [39.24024473, -7.9736239],
                            [39.23990181, -7.97350285],
                            [39.23965974, -7.97350285],
                            [39.23926638, -7.97331117],
                            [39.23894362, -7.9729984],
                            [39.23871164, -7.9726554],
                            [39.23856035, -7.97224178],
                            [39.23847964, -7.97197948],
                            [39.23841913, -7.97183823],
                            [39.2382376, -7.97163646],
                            [39.23805603, -7.97137416],
                            [39.23793502, -7.97114213],
                            [39.23783414, -7.97103116],
                            [39.23763244, -7.97110177],
                            [39.23736009, -7.97131363],
                            [39.23714827, -7.97153558],
                            [39.23692639, -7.97162638],
                            [39.23665406, -7.97161628],
                            [39.23631112, -7.97134388],
                            [39.23605897, -7.97105133],
                            [39.2359581, -7.97074866],
                            [39.23592786, -7.97043593],
                            [39.23604888, -7.97006264],
                            [39.23625061, -7.96977009],
                            [39.23649268, -7.96937663],
                            [39.2364019, -7.9691345],
                            [39.23622035, -7.9690538],
                            [39.23580682, -7.9690538],
                            [39.23551432, -7.96898318],
                            [39.23526217, -7.9688823],
                            [39.23503018, -7.96862],
                            [39.23484863, -7.96840813],
                            [39.23475786, -7.96841822],
                            [39.23460656, -7.96863007],
                            [39.2343746, -7.96882175],
                            [39.23396105, -7.96892264],
                            [39.23359794, -7.96883184],
                            [39.23331553, -7.96863007],
                            [39.23303312, -7.96823662],
                            [39.23277087, -7.96762122],
                            [39.23270028, -7.96709661],
                            [39.23270028, -7.96660228],
                            [39.23273053, -7.96589609],
                            [39.23265993, -7.96566405],
                            [39.23262968, -7.96542192],
                            [39.23261959, -7.96511927],
                            [39.23263976, -7.96484688],
                            [39.23262968, -7.9645745],
                            [39.23254898, -7.96444334],
                            [39.23232709, -7.96440298],
                            [39.23209511, -7.9643929],
                            [39.23184293, -7.96444333],
                            [39.23153029, -7.9646552],
                            [39.23120754, -7.96478635],
                            [39.23095537, -7.9648267],
                            [39.2308545, -7.96469555],
                            [39.23087467, -7.9645543],
                            [39.23095538, -7.96442315],
                            [39.23112684, -7.96433236],
                            [39.23126805, -7.96423148],
                            [39.23156054, -7.96414068],
                            [39.23185304, -7.96401963],
                            [39.23215563, -7.96399945],
                            [39.23244813, -7.96398936],
                            [39.23261959, -7.96397927],
                            [39.23262969, -7.96386829],
                            [39.23260952, -7.96362617],
                            [39.2326902, -7.96341432],
                            [39.23276081, -7.96319237],
                            [39.23299278, -7.96301076],
                            [39.23319451, -7.9629906],
                            [39.23341641, -7.96284936],
                            [39.23363831, -7.96278883],
                            [39.23376943, -7.96269803],
                            [39.2337896, -7.96257698],
                            [39.23381986, -7.96243575],
                            [39.23362823, -7.96219361],
                            [39.23355763, -7.96189096],
                            [39.2335778, -7.96170936],
                            [39.23365848, -7.96136636],
                            [39.23385013, -7.96100317],
                            [39.23410228, -7.96070052],
                            [39.23425357, -7.96048866],
                            [39.2342435, -7.96033733],
                            [39.23415272, -7.96019608],
                            [39.23407205, -7.95997414],
                            [39.23395099, -7.95987326],
                            [39.23382997, -7.95987326],
                            [39.23368875, -7.95988335],
                            [39.23356771, -7.95995396],
                            [39.23355763, -7.96014565],
                            [39.23344668, -7.96028688],
                            [39.2332853, -7.96039785],
                            [39.2330735, -7.96034742],
                            [39.23292221, -7.96023645],
                            [39.23282134, -7.96005485],
                            [39.23257927, -7.95984298],
                            [39.23235737, -7.95962103],
                            [39.23200436, -7.95937891],
                            [39.23165134, -7.95914686],
                            [39.23147988, -7.95889466],
                            [39.23127815, -7.95861218],
                            [39.23103609, -7.95826918],
                            [39.23074358, -7.95759324],
                            [39.2305822, -7.95713927],
                            [39.23040066, -7.95665502],
                            [39.23031997, -7.95641288],
                            [39.23012833, -7.95625148],
                            [39.22980558, -7.95611023],
                            [39.22954333, -7.95592863],
                            [39.22925084, -7.95565625],
                            [39.22905919, -7.95540405],
                            [39.22898859, -7.95507111],
                            [39.22903902, -7.95481891],
                            [39.2291399, -7.95467766],
                            [39.22927102, -7.95462722],
                            [39.22945255, -7.95464739],
                            [39.22966437, -7.95468775],
                            [39.22992661, -7.95471802],
                            [39.23023929, -7.95469784],
                            [39.23041075, -7.95468775],
                            [39.23059231, -7.95457678],
                            [39.23072343, -7.9543952],
                            [39.23070326, -7.9542136],
                            [39.23061248, -7.95415307],
                            [39.23048136, -7.9541127],
                            [39.23033007, -7.95419342],
                            [39.23011825, -7.95423377],
                            [39.22992662, -7.9542136],
                            [39.22970472, -7.95409253],
                            [39.22950299, -7.95390085],
                            [39.22932144, -7.95362845],
                            [39.22907937, -7.95322492],
                            [39.22887765, -7.95270031],
                            [39.22869611, -7.95216562],
                            [39.22862551, -7.95167129],
                            [39.22862551, -7.95132827],
                            [39.22869612, -7.95107607],
                            [39.22882724, -7.9508743],
                            [39.22903904, -7.95067253],
                            [39.2292105, -7.95062208],
                            [39.22954335, -7.950612],
                            [39.22986612, -7.95067253],
                            [39.23037043, -7.95076333],
                            [39.23086466, -7.95081378],
                            [39.23132862, -7.95085413],
                            [39.23144963, -7.95076334],
                            [39.2316413, -7.95061201],
                            [39.23175222, -7.95041024],
                            [39.23171189, -7.95011767],
                            [39.23165138, -7.9497343],
                            [39.2316413, -7.94934085],
                            [39.23167155, -7.94888688],
                            [39.23169171, -7.94861447],
                            [39.23164131, -7.94847324],
                            [39.23153036, -7.94842281],
                            [39.23137905, -7.94828158],
                            [39.23131854, -7.94803944],
                            [39.23132863, -7.94786794],
                            [39.23134881, -7.94775697],
                            [39.23143958, -7.94761572],
                            [39.23158078, -7.94748457],
                            [39.23181277, -7.94730298],
                            [39.23212545, -7.94716175],
                            [39.23235742, -7.9471113],
                            [39.2324583, -7.94694988],
                            [39.23247847, -7.94677838],
                            [39.2324482, -7.94652616],
                            [39.23239777, -7.94620334],
                            [39.23233726, -7.94603184],
                            [39.23233726, -7.94577962],
                            [39.23235743, -7.94540635],
                            [39.23234736, -7.9450936],
                            [39.23215571, -7.94482122],
                            [39.2318733, -7.944569],
                            [39.2315102, -7.94437732],
                            [39.231137, -7.94422598],
                            [39.2309151, -7.94417555],
                            [39.23063269, -7.94419572],
                            [39.23046123, -7.9442966],
                            [39.23034019, -7.94452864],
                            [39.23024941, -7.94488174],
                            [39.23016872, -7.94530546],
                            [39.22998717, -7.94565855],
                            [39.22973502, -7.94601165],
                            [39.229382, -7.94628403],
                            [39.22888777, -7.94659677],
                            [39.22847424, -7.94671784],
                            [39.22815147, -7.94679854],
                            [39.22775811, -7.94679854],
                            [39.2274656, -7.94671783],
                            [39.22727397, -7.94659676],
                            [39.22705208, -7.94648579],
                            [39.22687052, -7.94648579],
                            [39.22665872, -7.94656651],
                            [39.22647716, -7.94666738],
                            [39.22620483, -7.94670773],
                            [39.22574086, -7.946738],
                            [39.22528699, -7.94677835],
                            [39.2249138, -7.94688932],
                            [39.22463136, -7.94706082],
                            [39.22436913, -7.94716172],
                            [39.22421784, -7.94713144],
                            [39.22420775, -7.94698012],
                            [39.22423803, -7.94676827],
                            [39.2243994, -7.94652613],
                            [39.2246213, -7.94628401],
                            [39.22487346, -7.94615286],
                            [39.22498441, -7.94601163],
                            [39.22497432, -7.94588048],
                            [39.22497432, -7.94570896],
                            [39.22493398, -7.94549711],
                            [39.22479276, -7.94512384],
                            [39.22466165, -7.94483127],
                            [39.22461121, -7.94470012],
                            [39.22468182, -7.9445387],
                            [39.22480286, -7.94435713],
                            [39.2248432, -7.94417552],
                            [39.22487347, -7.94398383],
                            [39.22483311, -7.94372153],
                            [39.22467174, -7.94351976],
                            [39.22453053, -7.94341888],
                            [39.22439941, -7.94341888],
                            [39.22423804, -7.94349958],
                            [39.22407666, -7.943661],
                            [39.22387495, -7.94397375],
                            [39.22364294, -7.94414525],
                            [39.22337061, -7.94410489],
                            [39.2231588, -7.94402419],
                            [39.22293691, -7.94377197],
                            [39.22291674, -7.94356012],
                            [39.22283605, -7.94331798],
                            [39.2228663, -7.94302543],
                            [39.22299742, -7.94276313],
                            [39.22305795, -7.94258153],
                            [39.2230983, -7.94227887],
                            [39.22315881, -7.94192577],
                            [39.22324959, -7.94174419],
                            [39.22341098, -7.94151215],
                            [39.22359252, -7.94125994],
                            [39.22362278, -7.94105817],
                            [39.22355218, -7.94090683],
                            [39.22344123, -7.94081605],
                            [39.22322942, -7.94069498],
                            [39.2230176, -7.9405941],
                            [39.222594, -7.94036205],
                            [39.22219054, -7.94025108],
                            [39.22191821, -7.94013002],
                            [39.22167614, -7.93994843],
                            [39.22154503, -7.93973656],
                            [39.22152486, -7.93950453],
                            [39.22149459, -7.93924223],
                            [39.22144417, -7.93886896],
                            [39.2213433, -7.93851586],
                            [39.22117184, -7.93828382],
                            [39.22093985, -7.93799126],
                            [39.22047589, -7.93748682],
                            [39.22022376, -7.93704293],
                            [39.22016325, -7.93676045],
                            [39.22020358, -7.93643763],
                            [39.22024392, -7.93615514],
                            [39.22032461, -7.93593319],
                            [39.22026409, -7.93568099],
                            [39.22014305, -7.93560027],
                            [39.22000185, -7.93565072],
                            [39.21989091, -7.93574151],
                            [39.21965891, -7.93586258],
                            [39.21940677, -7.93597355],
                            [39.219084, -7.93593319],
                            [39.21882176, -7.93588275],
                            [39.21863013, -7.93587266],
                            [39.21848891, -7.9359231],
                            [39.21839813, -7.93607443],
                            [39.21834771, -7.93628628],
                            [39.21831745, -7.93660912],
                            [39.21830735, -7.93715389],
                            [39.21829727, -7.93766841],
                            [39.21845865, -7.93825354],
                            [39.21860994, -7.93862683],
                            [39.2187915, -7.93904045],
                            [39.21902347, -7.93944399],
                            [39.21919493, -7.93976682],
                            [39.21949753, -7.94018045],
                            [39.21971942, -7.94060417],
                            [39.21996149, -7.94096736],
                            [39.22011278, -7.94133054],
                            [39.22018338, -7.94169373],
                            [39.22016323, -7.94193585],
                            [39.22007243, -7.94221833],
                            [39.21976983, -7.94262187],
                            [39.21938657, -7.94301532],
                            [39.2191445, -7.94329779],
                            [39.2189125, -7.94351974],
                            [39.21859983, -7.94372151],
                            [39.21811569, -7.9438224],
                            [39.21769205, -7.94374168],
                            [39.2173088, -7.9436307],
                            [39.21695579, -7.94351973],
                            [39.21663302, -7.94332805],
                            [39.21640104, -7.9431061],
                            [39.21601776, -7.94279335],
                            [39.21563448, -7.94235955],
                            [39.21540251, -7.94210733],
                            [39.21517052, -7.94175424],
                            [39.2148276, -7.94121954],
                            [39.21459561, -7.94076556],
                            [39.21424259, -7.94019052],
                            [39.21395011, -7.93973653],
                            [39.21365761, -7.9394137],
                            [39.21339537, -7.93908078],
                            [39.21298182, -7.93869741],
                            [39.21274985, -7.93831405],
                            [39.21251786, -7.93791051],
                            [39.21233631, -7.93768858],
                            [39.21216484, -7.93758768],
                            [39.21192278, -7.93744644],
                            [39.21179166, -7.93728502],
                            [39.2116908, -7.93706307],
                            [39.21167063, -7.93682094],
                            [39.21172105, -7.93649812],
                            [39.21185218, -7.93622573],
                            [39.21204383, -7.93595333],
                            [39.21230607, -7.93567086],
                            [39.21261874, -7.93529759],
                            [39.21288098, -7.93484361],
                            [39.2131634, -7.93427867],
                            [39.21335258, -7.93376101],
                            [39.21339561, -7.93327317],
                            [39.21325216, -7.93294315],
                            [39.21277878, -7.93264186],
                            [39.21231975, -7.93245532],
                            [39.21186071, -7.93236923],
                            [39.21138734, -7.93235488],
                            [39.21101437, -7.93225444],
                            [39.21078484, -7.93208226],
                            [39.21056968, -7.93183836],
                            [39.2103545, -7.93193878],
                            [39.21009629, -7.93211097],
                            [39.20983808, -7.93228314],
                            [39.2095225, -7.93254139],
                            [39.2091065, -7.93282836],
                            [39.20863311, -7.93307227],
                            [39.20797326, -7.93335924],
                            [39.20725601, -7.9335601],
                            [39.2065818, -7.93358879],
                            [39.20618015, -7.93348837],
                            [39.20564939, -7.93331617],
                            [39.20511863, -7.93300052],
                            [39.20473132, -7.93288573],
                            [39.20443008, -7.93294312],
                            [39.20414318, -7.93320138],
                            [39.20394234, -7.93351705],
                            [39.20388497, -7.93400486],
                            [39.20377021, -7.9345501],
                            [39.20368415, -7.93496621],
                            [39.20356938, -7.93542534],
                            [39.20332552, -7.93569795],
                            [39.20289517, -7.93604231],
                            [39.20229268, -7.93648709],
                            [39.20180496, -7.93688884],
                            [39.20154675, -7.93720449],
                            [39.20153241, -7.93757754],
                            [39.20153241, -7.93802233],
                            [39.20146067, -7.9382519],
                            [39.20113075, -7.93858191],
                            [39.20062867, -7.93905539],
                            [39.20027004, -7.93942844],
                            [39.20011225, -7.93990192],
                            [39.20018396, -7.94027498],
                            [39.20039914, -7.94053324],
                            [39.20064301, -7.94057628],
                            [39.20077211, -7.94054759],
                            [39.20094425, -7.94060499],
                            [39.20107335, -7.94093499],
                            [39.201059, -7.94120761],
                            [39.20090121, -7.941595],
                            [39.20062866, -7.94203979],
                            [39.20016961, -7.94267111],
                            [39.20012658, -7.94305851],
                            [39.20025568, -7.94341723],
                            [39.20047086, -7.94367547],
                            [39.20080078, -7.94394808],
                            [39.20108768, -7.94416331],
                            [39.20115941, -7.94443592],
                            [39.20105899, -7.9446368],
                            [39.20064299, -7.9446655],
                            [39.2000692, -7.94440723],
                            [39.19966755, -7.9441633],
                            [39.19913679, -7.94403417],
                            [39.1987925, -7.94393373],
                            [39.19833348, -7.94361807],
                            [39.19813266, -7.94315892],
                            [39.19781706, -7.94262806],
                            [39.19737238, -7.94175282],
                            [39.19740107, -7.94139411],
                            [39.19760188, -7.94120759],
                            [39.19784576, -7.94107846],
                            [39.19820438, -7.94087759],
                            [39.19839086, -7.94054758],
                            [39.19840522, -7.94011714],
                            [39.19826176, -7.93945713],
                            [39.1980179, -7.93922755],
                            [39.19755887, -7.93888321],
                            [39.19717156, -7.93885451],
                            [39.19688466, -7.93894059],
                            [39.19675556, -7.93925626],
                            [39.19674121, -7.9396006],
                            [39.19672687, -7.9400741],
                            [39.19664079, -7.94041844],
                            [39.19643997, -7.94080584],
                            [39.19600961, -7.94123628],
                            [39.19556492, -7.94153758],
                            [39.195235, -7.94176715],
                            [39.19497679, -7.9418389],
                            [39.19466119, -7.9418389],
                            [39.19446037, -7.94195368],
                            [39.19435994, -7.94212586],
                            [39.19427388, -7.94235543],
                            [39.19417347, -7.94257064],
                            [39.19387222, -7.94268543],
                            [39.19344188, -7.94281456],
                            [39.19322671, -7.94292934],
                            [39.19301152, -7.94302978],
                            [39.19281071, -7.94325934],
                            [39.1925525, -7.94327369],
                            [39.19235167, -7.94327369],
                            [39.19205043, -7.94320195],
                            [39.1918496, -7.94295803],
                            [39.19186394, -7.9427428],
                            [39.19202175, -7.94255629],
                            [39.19217954, -7.94226933],
                            [39.19212215, -7.94203975],
                            [39.19199305, -7.94185323],
                            [39.19177789, -7.94170975],
                            [39.19183526, -7.94148018],
                            [39.1920361, -7.94132235],
                            [39.19227996, -7.94109278],
                            [39.19266726, -7.940978],
                            [39.19301153, -7.940978],
                            [39.19334147, -7.94090627],
                            [39.19347057, -7.94080583],
                            [39.19342754, -7.940648],
                            [39.19325541, -7.9405906],
                            [39.19298286, -7.94043277],
                            [39.19278202, -7.94036104],
                            [39.19249513, -7.9402606],
                            [39.19230865, -7.94021756],
                            [39.19207913, -7.9402319],
                            [39.19170616, -7.94049017],
                            [39.19144795, -7.94071972],
                            [39.1911754, -7.94077712],
                            [39.19064464, -7.94097799],
                            [39.18997043, -7.9412506],
                            [39.18961182, -7.9412793],
                            [39.18929622, -7.94142278],
                            [39.18893761, -7.94155191],
                            [39.1888085, -7.94182453],
                            [39.18886587, -7.94224062],
                            [39.18902366, -7.94248454],
                            [39.18926753, -7.94284323],
                            [39.1896405, -7.94311585],
                            [39.19007084, -7.94334542],
                            [39.19042946, -7.94358935],
                            [39.19071636, -7.94383327],
                            [39.19108932, -7.94420631],
                            [39.19144793, -7.94457936],
                            [39.19184959, -7.94519634],
                            [39.19197869, -7.94556941],
                            [39.19215084, -7.94601418],
                            [39.19232297, -7.94635852],
                            [39.19243773, -7.94668854],
                            [39.19255249, -7.94693244],
                            [39.19269594, -7.94721942],
                            [39.19286807, -7.94757813],
                            [39.19282504, -7.94792247],
                            [39.19276765, -7.94836726],
                            [39.19266723, -7.94862553],
                            [39.19265288, -7.94892685],
                            [39.19258117, -7.94920424],
                            [39.19254292, -7.94956772],
                            [39.19250465, -7.94991208],
                            [39.19258116, -7.95040948],
                            [39.19265766, -7.95084949],
                            [39.1928298, -7.95119383],
                            [39.19298281, -7.95155732],
                            [39.19315494, -7.95184428],
                            [39.19334622, -7.95230343],
                            [39.19349922, -7.95283909],
                            [39.19344183, -7.9533939],
                            [39.19309756, -7.95369997],
                            [39.19296368, -7.95389129],
                            [39.19250463, -7.95413997],
                            [39.19177783, -7.95412084],
                            [39.19078325, -7.95387213],
                            [39.1900182, -7.95358517],
                            [39.1890045, -7.95295386],
                            [39.18843069, -7.95281994],
                            [39.18810555, -7.95281994],
                            [39.18789515, -7.95287733],
                            [39.18762739, -7.95303037],
                            [39.18755088, -7.95316428],
                            [39.18755088, -7.95347037],
                            [39.18757, -7.95377648],
                            [39.18772303, -7.95402518],
                            [39.18806729, -7.95473302],
                            [39.18810554, -7.95532606],
                            [39.18841157, -7.95607217],
                            [39.1887941, -7.95685654],
                            [39.18898535, -7.95802351],
                            [39.18929137, -7.95854002],
                            [39.18961652, -7.95919049],
                            [39.18992254, -7.9603766],
                            [39.18999905, -7.96110357],
                            [39.19015205, -7.96177313],
                            [39.19007554, -7.96250012],
                            [39.18984601, -7.96349492],
                            [39.18963562, -7.96429841],
                            [39.18931046, -7.96492973],
                            [39.18910008, -7.96584801],
                            [39.18892794, -7.96661323],
                            [39.18831588, -7.9673402],
                            [39.18785685, -7.96776107],
                            [39.18749344, -7.96820108],
                            [39.18729197, -7.96857052],
                            [39.18730681, -7.96863283],
                            [39.18720434, -7.96869431],
                            [39.1869766, -7.96888792],
                            [39.18678303, -7.96908155],
                            [39.18653251, -7.96929795],
                            [39.18623646, -7.96952574],
                            [39.18591761, -7.96973074],
                            [39.18556462, -7.96996993],
                            [39.18522301, -7.97012936],
                            [39.18484726, -7.97027743],
                            [39.18452841, -7.97034576],
                            [39.18414125, -7.97045967],
                            [39.18389073, -7.97048244],
                            [39.18357192, -7.97048244],
                            [39.18318476, -7.97041411],
                            [39.18280898, -7.97035714],
                            [39.18246738, -7.97014074],
                            [39.18218271, -7.97006102],
                            [39.1820119, -7.96995852],
                            [39.1816589, -7.96983323],
                            [39.18153366, -7.96973073],
                            [39.18126036, -7.96953711],
                            [39.18102124, -7.96926375],
                            [39.18081627, -7.96896763],
                            [39.18057715, -7.96869428],
                            [39.18039496, -7.96847788],
                            [39.18020139, -7.96814757],
                            [39.18003059, -7.96774894],
                            [39.17997367, -7.96745281],
                            [39.17987117, -7.96715669],
                            [39.17974593, -7.96683778],
                            [39.17962067, -7.96639358],
                            [39.17960928, -7.9660633],
                            [39.17968898, -7.96575579],
                            [39.17991672, -7.96545966],
                            [39.18012169, -7.96517492],
                            [39.18037221, -7.96493574],
                            [39.1806455, -7.96466241],
                            [39.18093017, -7.96435488],
                            [39.18108959, -7.96407014],
                            [39.18130594, -7.96372845],
                            [39.18146536, -7.96327287],
                            [39.18162478, -7.96284007],
                            [39.18172728, -7.96244145],
                            [39.18172728, -7.96205421],
                            [39.18184113, -7.9616328],
                            [39.18195502, -7.96112027],
                            [39.18221692, -7.96051663],
                            [39.18233078, -7.96007244],
                            [39.18245604, -7.95960547],
                            [39.18261546, -7.95917268],
                            [39.18274072, -7.95877404],
                            [39.18287737, -7.9583754],
                            [39.18324173, -7.95772621],
                            [39.18350365, -7.95713396],
                            [39.18374278, -7.95657587],
                            [39.18386803, -7.95616584],
                            [39.18395914, -7.95582417],
                            [39.1839819, -7.95560777],
                            [39.18399329, -7.95540276],
                            [39.18407299, -7.95519774],
                            [39.18419825, -7.95522053],
                            [39.18431212, -7.95535721],
                            [39.18434628, -7.95557359],
                            [39.18438045, -7.95583556],
                            [39.18450569, -7.95593805],
                            [39.18459678, -7.95620002],
                            [39.18461958, -7.95649616],
                            [39.18464234, -7.95690615],
                            [39.18467651, -7.95725924],
                            [39.18469927, -7.95777177],
                            [39.1846765, -7.95846654],
                            [39.18458539, -7.95909295],
                            [39.1844943, -7.95969659],
                            [39.18436905, -7.96028884],
                            [39.18418686, -7.96083554],
                            [39.18398188, -7.96138223],
                            [39.18367442, -7.96203145],
                            [39.18337836, -7.96274897],
                            [39.18319617, -7.96317039],
                            [39.18276346, -7.96392209],
                            [39.18252434, -7.96435489],
                            [39.1822169, -7.96475352],
                            [39.1820347, -7.96509521],
                            [39.18182973, -7.96544828],
                            [39.18165892, -7.96590384],
                            [39.18140841, -7.96645055],
                            [39.18128316, -7.96687196],
                            [39.18116928, -7.96732754],
                            [39.1811579, -7.96763505],
                            [39.18114651, -7.96786285],
                            [39.18121482, -7.96799953],
                            [39.18130592, -7.96814757],
                            [39.18135147, -7.96832981],
                            [39.18147671, -7.96858037],
                            [39.18175001, -7.96883097],
                            [39.1820233, -7.96908153],
                            [39.1822738, -7.96926375],
                            [39.1825357, -7.96937765],
                            [39.18279759, -7.96949156],
                            [39.18311644, -7.96967378],
                            [39.18343527, -7.96974213],
                            [39.18389074, -7.9697649],
                            [39.18428928, -7.96971936],
                            [39.18475616, -7.96958268],
                            [39.18527996, -7.96932073],
                            [39.18572406, -7.96896765],
                            [39.18612259, -7.96853484],
                            [39.18655529, -7.96789703],
                            [39.18674887, -7.96740728],
                            [39.1869083, -7.96694032],
                            [39.18707911, -7.96649613],
                            [39.1871702, -7.96606333],
                            [39.18729546, -7.96551662],
                            [39.18742071, -7.96527746],
                            [39.18747766, -7.96493577],
                            [39.18750043, -7.96471937],
                            [39.18762567, -7.96443464],
                            [39.18780787, -7.96413851],
                            [39.18811531, -7.96359182],
                            [39.18832028, -7.96319318],
                            [39.18841138, -7.96291983],
                            [39.18852525, -7.96255538],
                            [39.18870745, -7.96215674],
                            [39.18874161, -7.96186062],
                            [39.18876437, -7.96154172],
                            [39.18877577, -7.96114308],
                            [39.18877577, -7.96083556],
                            [39.188753, -7.96053944],
                            [39.18863913, -7.9602547],
                            [39.18852527, -7.95985606],
                            [39.18844557, -7.95952577],
                            [39.18835448, -7.95924103],
                            [39.18827476, -7.95894491],
                            [39.18821783, -7.9587399],
                            [39.18812674, -7.95847795],
                            [39.1879787, -7.95820458],
                            [39.1878876, -7.95791984],
                            [39.18787622, -7.95769206],
                            [39.18771681, -7.95749843],
                            [39.18756878, -7.95725925],
                            [39.18738659, -7.95691757],
                            [39.187193, -7.95658728],
                            [39.187045, -7.95632532],
                            [39.18687418, -7.95604058],
                            [39.18668061, -7.95573305],
                            [39.18654396, -7.9554711],
                            [39.1864301, -7.95519775],
                            [39.18635037, -7.95495858],
                            [39.18620237, -7.95466245],
                            [39.18609987, -7.95425243],
                            [39.18599739, -7.95386519],
                            [39.18603156, -7.95343239],
                            [39.18611127, -7.95305652],
                            [39.18616822, -7.95278318],
                            [39.18625929, -7.95253261],
                            [39.18640733, -7.95237316],
                            [39.18664647, -7.95222509],
                            [39.18690836, -7.95209982],
                            [39.18712471, -7.95209982],
                            [39.18744353, -7.95211121],
                            [39.18759157, -7.95211121],
                            [39.18776238, -7.95207704],
                            [39.18791042, -7.95192898],
                            [39.18784208, -7.95180369],
                            [39.18767127, -7.95172395],
                            [39.18754603, -7.95162147],
                            [39.18745492, -7.95151895],
                            [39.18743216, -7.9513595],
                            [39.18751187, -7.95129115],
                            [39.18763712, -7.95122283],
                            [39.18786486, -7.95121144],
                            [39.18810398, -7.95123422],
                            [39.18828617, -7.95132534],
                            [39.18838867, -7.95139368],
                            [39.18849115, -7.95140507],
                            [39.18867333, -7.95145063],
                            [39.18888969, -7.95164424],
                            [39.18919713, -7.95199731],
                            [39.18958429, -7.95232762],
                            [39.1899259, -7.95256679],
                            [39.19029027, -7.95279459],
                            [39.19065465, -7.95291986],
                            [39.19101904, -7.95304515],
                            [39.19129232, -7.95322739],
                            [39.1914859, -7.95328434],
                            [39.19180473, -7.9533299],
                            [39.19210078, -7.95337547],
                            [39.1924424, -7.95330711],
                            [39.19262459, -7.9532274],
                            [39.19265876, -7.95293128],
                            [39.19260182, -7.95260097],
                            [39.19246517, -7.95225928],
                            [39.19226021, -7.95188344],
                            [39.19213497, -7.95162149],
                            [39.19188443, -7.95130257],
                            [39.19186167, -7.95110894],
                            [39.19182752, -7.95082421],
                            [39.1916681, -7.9505167],
                            [39.19152008, -7.95036863],
                            [39.19126956, -7.9504028],
                            [39.19101905, -7.9504028],
                            [39.1907002, -7.9502889],
                            [39.19038139, -7.9500839],
                            [39.19003976, -7.94990166],
                            [39.18978926, -7.94960552],
                            [39.18973232, -7.94946886],
                            [39.18961846, -7.94916133],
                            [39.18945906, -7.94889938],
                            [39.18928824, -7.94866019],
                            [39.18911743, -7.94845518],
                            [39.18888971, -7.94826155],
                            [39.18863919, -7.94804515],
                            [39.18827481, -7.94781736],
                            [39.18794459, -7.94763515],
                            [39.18755744, -7.94750984],
                            [39.18724999, -7.94749847],
                            [39.18704503, -7.9474643],
                            [39.1867945, -7.9474643],
                            [39.18656676, -7.94749846],
                            [39.18630486, -7.94756679],
                            [39.18600881, -7.94758957],
                            [39.18574691, -7.94761234],
                            [39.18545085, -7.94770346],
                            [39.18528006, -7.94784013],
                            [39.18507508, -7.94797681],
                            [39.18488151, -7.94809071],
                            [39.18460822, -7.94828432],
                            [39.18438048, -7.94848932],
                            [39.18415272, -7.94868295],
                            [39.18399332, -7.94880824],
                            [39.1838339, -7.94897908],
                            [39.18368586, -7.94912714],
                            [39.18350368, -7.94921827],
                            [39.18328733, -7.9492752],
                            [39.18288878, -7.94935492],
                            [39.18236498, -7.94935491],
                            [39.18190951, -7.94938911],
                            [39.18135155, -7.94941187],
                            [39.1808733, -7.94948021],
                            [39.18064556, -7.94950298],
                            [39.18040644, -7.94953714],
                            [39.18005344, -7.94960548],
                            [39.17993956, -7.94965105],
                            [39.17984848, -7.94969661],
                            [39.17962074, -7.94981049],
                            [39.17930191, -7.94983329],
                            [39.17902861, -7.94984466],
                            [39.17878949, -7.94986745],
                            [39.17850482, -7.94987884],
                            [39.1781632, -7.94985605],
                            [39.17792407, -7.94981048],
                            [39.17766218, -7.94968521],
                            [39.17741167, -7.94954852],
                            [39.17722948, -7.94936628],
                            [39.17699036, -7.94917269],
                            [39.17680817, -7.94894488],
                            [39.17663736, -7.94866014],
                            [39.17652349, -7.94835262],
                            [39.17650073, -7.94791983],
                            [39.17653489, -7.94756674],
                            [39.1766032, -7.94728202],
                            [39.17671709, -7.94702007],
                            [39.1768765, -7.94670115],
                            [39.17702453, -7.94649614],
                            [39.1771384, -7.94625696],
                            [39.1771384, -7.94606333],
                            [39.17705869, -7.945995],
                            [39.17695621, -7.94590389],
                            [39.17672848, -7.94590389],
                            [39.1765349, -7.94590389],
                            [39.17628439, -7.94592667],
                            [39.17599971, -7.94594944],
                            [39.17582891, -7.94604055],
                            [39.17560117, -7.94612029],
                            [39.1753962, -7.94622279],
                            [39.17524816, -7.94632529],
                            [39.17495211, -7.94648474],
                            [39.17480407, -7.94664419],
                            [39.17463326, -7.94679227],
                            [39.17450802, -7.94684921],
                            [39.17438276, -7.94706561],
                            [39.17418917, -7.94715672],
                            [39.174007, -7.94728201],
                            [39.1739159, -7.94745285],
                            [39.17376786, -7.94763509],
                            [39.17361983, -7.94787426],
                            [39.17350596, -7.94810205],
                            [39.17343765, -7.94826149],
                            [39.17332376, -7.94853485],
                            [39.17328961, -7.94875125],
                            [39.1732099, -7.94916126],
                            [39.17318712, -7.94948018],
                            [39.1731302, -7.94969658],
                            [39.17310741, -7.94991298],
                            [39.17305047, -7.95018633],
                            [39.17305047, -7.95052801],
                            [39.1730277, -7.95094942],
                            [39.17295939, -7.95130249],
                            [39.17279996, -7.95164417],
                            [39.17257221, -7.9520656],
                            [39.17228755, -7.95233893],
                            [39.17199148, -7.95255534],
                            [39.17161572, -7.95279451],
                            [39.17128549, -7.95299951],
                            [39.17094388, -7.95320453],
                            [39.17077308, -7.95335258],
                            [39.17056811, -7.95351204],
                            [39.17032898, -7.95363733],
                            [39.16998737, -7.95379677],
                            [39.16955467, -7.95399039],
                            [39.16924723, -7.95410429],
                            [39.16897394, -7.95425236],
                            [39.16882591, -7.95438903],
                            [39.16812951, -7.95669445],
                            [39.16822791, -7.95741623],
                            [39.16869751, -7.9586687],
                            [39.16955835, -7.95945148],
                            [39.16963664, -7.96062567],
                            [39.16877576, -7.96086051],
                            [39.16783659, -7.95968632],
                            [39.16642788, -7.95898181],
                            [39.16533219, -7.95906008],
                            [39.1649409, -7.96023427],
                            [39.16509739, -7.96172158],
                            [39.16564522, -7.96407],
                            [39.16603654, -7.96641839],
                            [39.16587999, -7.96837538],
                            [39.16525946, -7.96976473],
                            [39.16481129, -7.9701134],
                            [39.1640643, -7.97068619],
                            [39.16314305, -7.97091032],
                            [39.16182344, -7.97048694],
                            [39.16102666, -7.96996395],
                            [39.1599062, -7.96949073],
                            [39.158736, -7.96869381],
                            [39.15783965, -7.96827043],
                            [39.15639552, -7.96861908],
                            [39.15529995, -7.96904244],
                            [39.15477709, -7.96966507],
                            [39.15455301, -7.97033746],
                            [39.15465261, -7.97100988],
                            [39.15537467, -7.97113442],
                            [39.15609673, -7.97066124],
                            [39.15666941, -7.97016314],
                            [39.15721717, -7.96998883],
                            [39.157989, -7.97031259],
                            [39.15856171, -7.97083557],
                            [39.15876088, -7.97175705],
                            [39.15878575, -7.97242946],
                            [39.15841231, -7.97367467],
                            [39.15818822, -7.97472063],
                            [39.15798897, -7.97589116],
                            [39.1581384, -7.97718618],
                            [39.15861142, -7.97830688],
                            [39.15871106, -7.9789793],
                            [39.15831268, -7.97942757],
                            [39.15778982, -7.97987585],
                            [39.15756572, -7.98042375],
                            [39.15721712, -7.98104635],
                            [39.15679384, -7.98139501],
                            [39.15597214, -7.98159424],
                            [39.15534968, -7.98201762],
                            [39.15470236, -7.98234138],
                            [39.15410477, -7.98234137],
                            [39.15365659, -7.98219192],
                            [39.15348231, -7.98159423],
                            [39.15350722, -7.98112105],
                            [39.15378107, -7.98077239],
                            [39.15425419, -7.98062294],
                            [39.15500113, -7.98062295],
                            [39.15549911, -7.98059807],
                            [39.15579787, -7.98002528],
                            [39.15582282, -7.97945248],
                            [39.15557379, -7.97865551],
                            [39.15512561, -7.97765937],
                            [39.15407992, -7.97673788],
                            [39.1533827, -7.97616508],
                            [39.15263578, -7.97559229],
                            [39.15198839, -7.97491986],
                            [39.15163981, -7.97417274],
                            [39.15168962, -7.97312674],
                            [39.15188885, -7.97250415],
                            [39.15186391, -7.9717072],
                            [39.15154026, -7.97113441],
                            [39.15109208, -7.97068613],
                            [39.15032024, -7.97031256],
                            [39.14939895, -7.97013823],
                            [39.14842791, -7.96981446],
                            [39.14768097, -7.96931636],
                            [39.14715809, -7.96866885],
                            [39.14658538, -7.96822058],
                            [39.14556456, -7.9677972],
                            [39.1449421, -7.96774736],
                            [39.14436944, -7.96836997],
                            [39.14354775, -7.96901749],
                            [39.14280081, -7.96936615],
                            [39.14195426, -7.97028761],
                            [39.14125709, -7.97115926],
                            [39.14060971, -7.97200601],
                            [39.14013663, -7.9724792],
                            [39.13961373, -7.97282786],
                            [39.13906599, -7.97292747],
                            [39.13869252, -7.97270333],
                            [39.13839374, -7.97227995],
                            [39.13799532, -7.97180677],
                            [39.13762188, -7.97158263],
                            [39.1371239, -7.9715079],
                            [39.13667571, -7.97163244],
                            [39.13632715, -7.97220523],
                            [39.13607812, -7.97275309],
                            [39.13590383, -7.97332592],
                            [39.13590383, -7.9738738],
                            [39.13625244, -7.97422248],
                            [39.13694961, -7.97439681],
                            [39.13749737, -7.97469566],
                            [39.13794554, -7.97521865],
                            [39.13814471, -7.97564203],
                            [39.13784596, -7.97631445],
                            [39.13727326, -7.97683744],
                            [39.1363769, -7.97706157],
                            [39.13565485, -7.97691211],
                            [39.13545563, -7.97626463],
                            [39.13548056, -7.97551751],
                            [39.13560505, -7.97504431],
                            [39.13528136, -7.97494467],
                            [39.13460909, -7.97501941],
                            [39.13411111, -7.97501941],
                            [39.13368786, -7.97454621],
                            [39.13321476, -7.9738489],
                            [39.13261722, -7.97345043],
                            [39.13169592, -7.97330099],
                            [39.13089918, -7.97320136],
                            [39.12985346, -7.97285271],
                            [39.12925589, -7.9723546],
                            [39.12868325, -7.97153277],
                            [39.12798604, -7.97148296],
                            [39.1276126, -7.9718565],
                            [39.12758765, -7.97247915],
                            [39.12803583, -7.9729772],
                            [39.12823505, -7.97362474],
                            [39.12776191, -7.97412283],
                            [39.12694032, -7.97444659],
                            [39.12599416, -7.97454618],
                            [39.12494837, -7.97429715],
                            [39.12432591, -7.97424734],
                            [39.12357897, -7.97442166],
                            [39.12258301, -7.97459597],
                            [39.12181113, -7.97457107],
                            [39.12101437, -7.97427223],
                            [39.12029232, -7.97397336],
                            [39.11949556, -7.97382391],
                            [39.1188731, -7.97382391],
                            [39.11847474, -7.97414768],
                            [39.11762817, -7.97477028],
                            [39.11670693, -7.97521856],
                            [39.11615762, -7.97575041],
                            [39.11514486, -7.97686471],
                            [39.11463845, -7.97747255],
                            [39.11444426, -7.97742772],
                            [39.11444561, -7.97742592],
                            [39.11458022, -7.97720152],
                            [39.1146956, -7.9770156],
                            [39.11492355, -7.97670665],
                            [39.11512385, -7.97648126],
                            [39.11536172, -7.97618073],
                            [39.1156121, -7.97586768],
                            [39.11592508, -7.97572994],
                            [39.11605028, -7.97555464],
                            [39.1163883, -7.97525411],
                            [39.11657608, -7.97510385],
                            [39.11683899, -7.97499115],
                            [39.11715197, -7.97484089],
                            [39.11746495, -7.97469062],
                            [39.11777793, -7.97451532],
                            [39.11797823, -7.9742774],
                            [39.11816602, -7.97408957],
                            [39.11834129, -7.97382661],
                            [39.11850404, -7.97363878],
                            [39.11876695, -7.97358869],
                            [39.11921764, -7.97356365],
                            [39.11964329, -7.97361374],
                            [39.12003138, -7.97371392],
                            [39.12045703, -7.97382662],
                            [39.12082009, -7.9739268],
                            [39.12104543, -7.97407706],
                            [39.1212833, -7.97417724],
                            [39.12144605, -7.97423985],
                            [39.12163383, -7.97422733],
                            [39.12185918, -7.97413967],
                            [39.12217216, -7.97407707],
                            [39.12263537, -7.97407707],
                            [39.12299843, -7.97410211],
                            [39.123374, -7.9741522],
                            [39.12373706, -7.97421481],
                            [39.12405004, -7.97417725],
                            [39.12442561, -7.97413969],
                            [39.12458836, -7.97410212],
                            [39.12478867, -7.97411464],
                            [39.12506409, -7.97416473],
                            [39.12527691, -7.97425239],
                            [39.12567753, -7.97434004],
                            [39.12599051, -7.97435257],
                            [39.12621585, -7.97435257],
                            [39.12652883, -7.97435257],
                            [39.12685433, -7.97430248],
                            [39.12711723, -7.97420231],
                            [39.12739266, -7.97408961],
                            [39.12759296, -7.97392682],
                            [39.12771816, -7.97378908],
                            [39.12775571, -7.97356369],
                            [39.12766808, -7.97331325],
                            [39.12758045, -7.97307533],
                            [39.12739266, -7.97274976],
                            [39.12725495, -7.97246175],
                            [39.12716732, -7.97212366],
                            [39.1271548, -7.97187321],
                            [39.12722992, -7.9716353],
                            [39.12733007, -7.97151008],
                            [39.12755542, -7.97137234],
                            [39.12776824, -7.97130973],
                            [39.12814382, -7.97129721],
                            [39.12838168, -7.97135982],
                            [39.12870718, -7.97139739],
                            [39.12884489, -7.97151008],
                            [39.12900764, -7.97167287],
                            [39.12913283, -7.97196088],
                            [39.1292455, -7.97218627],
                            [39.12948337, -7.97231149],
                            [39.12988398, -7.97247428],
                            [39.13025955, -7.97254942],
                            [39.13069772, -7.97259951],
                            [39.13111086, -7.97259951],
                            [39.13149895, -7.97262455],
                            [39.13176185, -7.97262455],
                            [39.1319246, -7.97262455],
                            [39.13218751, -7.97274978],
                            [39.13243789, -7.97303778],
                            [39.13266323, -7.97311292],
                            [39.13307636, -7.97326318],
                            [39.13328919, -7.97345101],
                            [39.13352705, -7.97370145],
                            [39.13371484, -7.97396442],
                            [39.13385255, -7.9742399],
                            [39.13387759, -7.97440269],
                            [39.13399026, -7.97469069],
                            [39.13426568, -7.974866],
                            [39.13456614, -7.974866],
                            [39.13481652, -7.97487853],
                            [39.13516706, -7.97487853],
                            [39.13544248, -7.97485349],
                            [39.13554264, -7.97474079],
                            [39.13554264, -7.97459052],
                            [39.135455, -7.97442774],
                            [39.13529225, -7.97415225],
                            [39.1352547, -7.97397695],
                            [39.13534233, -7.97368894],
                            [39.13553012, -7.97341346],
                            [39.13570539, -7.97316302],
                            [39.1358431, -7.97298771],
                            [39.13594326, -7.97272475],
                            [39.13606845, -7.97246179],
                            [39.13614357, -7.97214874],
                            [39.13620616, -7.97187325],
                            [39.13630632, -7.97161029],
                            [39.13643151, -7.97147255],
                            [39.13658174, -7.97130977],
                            [39.13673197, -7.97125968],
                            [39.13691976, -7.97128472],
                            [39.13722022, -7.97133481],
                            [39.13742053, -7.97135986],
                            [39.13773351, -7.97144751],
                            [39.13794633, -7.97157273],
                            [39.13815916, -7.97182317],
                            [39.13840954, -7.97211118],
                            [39.13865992, -7.97243675],
                            [39.13888527, -7.97251189],
                            [39.13921076, -7.97252441],
                            [39.13958634, -7.97249937],
                            [39.13983672, -7.97232406],
                            [39.14011215, -7.9720611],
                            [39.14042513, -7.9716604],
                            [39.14072559, -7.97123465],
                            [39.14100101, -7.9708089],
                            [39.14122636, -7.97037064],
                            [39.14142667, -7.97004506],
                            [39.14168957, -7.96976958],
                            [39.14195248, -7.96956923],
                            [39.14234057, -7.96945653],
                            [39.14269111, -7.96926871],
                            [39.14289142, -7.96905583],
                            [39.14310424, -7.96893061],
                            [39.14329203, -7.96879287],
                            [39.14354241, -7.9686927],
                            [39.14383036, -7.96859252],
                            [39.14404318, -7.96842974],
                            [39.14406822, -7.96814173],
                            [39.14415586, -7.96797895],
                            [39.14425601, -7.96775355],
                            [39.14443128, -7.9675532],
                            [39.14468166, -7.96741546],
                            [39.14494457, -7.96736537],
                            [39.14521999, -7.96736537],
                            [39.14554549, -7.96745303],
                            [39.14577083, -7.96751564],
                            [39.14603373, -7.96767843],
                            [39.14622152, -7.96780365],
                            [39.14643435, -7.96787878],
                            [39.14672229, -7.96802904],
                            [39.1470603, -7.9682294],
                            [39.14741084, -7.96840471],
                            [39.14758611, -7.96861758],
                            [39.14774886, -7.96880541],
                            [39.14796168, -7.96906838],
                            [39.14814947, -7.96935638],
                            [39.14851252, -7.96966943],
                            [39.14876291, -7.96976961],
                            [39.14916352, -7.96988231],
                            [39.14951406, -7.96994492],
                            [39.14987711, -7.96998249],
                            [39.1503278, -7.97017032],
                            [39.15055315, -7.97025798],
                            [39.1506813, -7.97026099],
                            [39.15078754, -7.97022114],
                            [39.15092035, -7.97036726],
                            [39.15107972, -7.97056652],
                            [39.15119925, -7.97064622],
                            [39.15135862, -7.97069936],
                            [39.15155783, -7.97073921],
                            [39.15187657, -7.97087205],
                            [39.15192969, -7.97115101],
                            [39.15196953, -7.97137683],
                            [39.15198281, -7.97168236],
                            [39.15206249, -7.97192147],
                            [39.15206249, -7.97217386],
                            [39.15202265, -7.97234655],
                            [39.15195624, -7.97271849],
                            [39.15196952, -7.97298417],
                            [39.15200936, -7.97313029],
                            [39.1519828, -7.9734491],
                            [39.15206248, -7.97370149],
                            [39.15214217, -7.97387418],
                            [39.15202264, -7.97404687],
                            [39.15187655, -7.97425941],
                            [39.15194295, -7.97452509],
                            [39.15208904, -7.97472434],
                            [39.15235466, -7.97495017],
                            [39.15264683, -7.97518928],
                            [39.15292573, -7.97546824],
                            [39.15311166, -7.97572063],
                            [39.1534304, -7.97588004],
                            [39.15376241, -7.97611915],
                            [39.15398819, -7.97633169],
                            [39.15422724, -7.97653095],
                            [39.15463894, -7.97692946],
                            [39.15505064, -7.97736783],
                            [39.15534282, -7.97779291],
                            [39.15562171, -7.97821799],
                            [39.15584748, -7.97866964],
                            [39.15594045, -7.97901502],
                            [39.15600685, -7.97933383],
                            [39.15611309, -7.97975891],
                            [39.15619278, -7.98006444],
                            [39.15616622, -7.98035668],
                            [39.15607325, -7.98062235],
                            [39.15587404, -7.98078176],
                            [39.15559514, -7.98087474],
                            [39.15532952, -7.98090131],
                            [39.15490454, -7.98084817],
                            [39.15459908, -7.98079504],
                            [39.15429362, -7.98079503],
                            [39.15406785, -7.9808216],
                            [39.15394832, -7.98088802],
                            [39.1538952, -7.98106071],
                            [39.15390848, -7.98132638],
                            [39.1539616, -7.98153892],
                            [39.15405457, -7.98167176],
                            [39.15410769, -7.98181788],
                            [39.1543069, -7.98195072],
                            [39.15453267, -7.98201714],
                            [39.15487797, -7.98201714],
                            [39.15502406, -7.98191087],
                            [39.15532952, -7.98165848],
                            [39.15554201, -7.98149908],
                            [39.15582091, -7.98136624],
                            [39.15598028, -7.98123341],
                            [39.15623262, -7.98116699],
                            [39.15653807, -7.98119356],
                            [39.15673729, -7.98120684],
                            [39.15698962, -7.98108729],
                            [39.15713571, -7.98088803],
                            [39.1572818, -7.98056922],
                            [39.15740133, -7.98027698],
                            [39.15742789, -7.97995817],
                            [39.15760054, -7.97970578],
                            [39.15778647, -7.97944011],
                            [39.15807865, -7.97922757],
                            [39.15830443, -7.97910801],
                            [39.1584638, -7.97897518],
                            [39.15850364, -7.9787095],
                            [39.15842396, -7.97839069],
                            [39.15829115, -7.97832427],
                            [39.1580521, -7.97811173],
                            [39.1580521, -7.97783277],
                            [39.15793257, -7.9776468],
                            [39.15779976, -7.97736784],
                            [39.1577068, -7.97703574],
                            [39.15769352, -7.97669036],
                            [39.15774665, -7.97623872],
                            [39.15782633, -7.97569408],
                            [39.1578529, -7.975269],
                            [39.15797243, -7.97457825],
                            [39.15810524, -7.97410003],
                            [39.15817164, -7.97359525],
                            [39.15833101, -7.97310375],
                            [39.15842398, -7.97283807],
                            [39.1584107, -7.97251926],
                            [39.15837086, -7.97214732],
                            [39.15833102, -7.97182851],
                            [39.15826462, -7.9715097],
                            [39.15807869, -7.9711776],
                            [39.15787948, -7.97096506],
                            [39.15756074, -7.97067282],
                            [39.15725528, -7.97053998],
                            [39.15701623, -7.97046027],
                            [39.15673733, -7.97050012],
                            [39.15647172, -7.97064624],
                            [39.15615298, -7.97091192],
                            [39.15584752, -7.97112446],
                            [39.15543581, -7.9714167],
                            [39.15518348, -7.97154954],
                            [39.15497098, -7.97158939],
                            [39.15474521, -7.9714964],
                            [39.15446631, -7.97137684],
                            [39.1542671, -7.97115102],
                            [39.15416086, -7.97075251],
                            [39.15404133, -7.9701813],
                            [39.15414758, -7.96980936],
                            [39.15420071, -7.96942413],
                            [39.15433352, -7.96921159],
                            [39.15470538, -7.9690389],
                            [39.15490459, -7.96893263],
                            [39.15526317, -7.96883965],
                            [39.15555535, -7.96875995],
                            [39.15595377, -7.96864039],
                            [39.15616627, -7.96848099],
                            [39.15637876, -7.96828173],
                            [39.15657797, -7.96813561],
                            [39.15688343, -7.96804263],
                            [39.15714905, -7.96801606],
                            [39.15760059, -7.96800278],
                            [39.15798573, -7.96812234],
                            [39.15847712, -7.96826846],
                            [39.15895523, -7.96854742],
                            [39.15971223, -7.9689725],
                            [39.16020362, -7.9693843],
                            [39.16061532, -7.96949057],
                            [39.16145201, -7.96974297],
                            [39.16203636, -7.97007507],
                            [39.16227541, -7.97035403],
                            [39.16256759, -7.9704603],
                            [39.16281992, -7.9706197],
                            [39.16319179, -7.97056657],
                            [39.16382927, -7.97036732],
                            [39.16438706, -7.97008836],
                            [39.16474564, -7.96971642],
                            [39.16487845, -7.96937104],
                            [39.16501126, -7.96894596],
                            [39.16521047, -7.96842789],
                            [39.16529016, -7.96784341],
                            [39.16534329, -7.96712608],
                            [39.16536985, -7.96635563],
                            [39.16531673, -7.96575786],
                            [39.16526361, -7.96516009],
                            [39.16519721, -7.96462874],
                            [39.16505112, -7.96392469],
                            [39.16494488, -7.96324722],
                            [39.16481208, -7.96249005],
                            [39.16466599, -7.96202511],
                            [39.16463943, -7.96156018],
                            [39.16454647, -7.96116167],
                            [39.16441366, -7.96061703],
                            [39.16437382, -7.95985986],
                            [39.16440038, -7.95946135],
                            [39.16445351, -7.95914253],
                            [39.16465272, -7.95887686],
                            [39.16490506, -7.95877059],
                            [39.16517067, -7.95873074],
                            [39.16543629, -7.95874403],
                            [39.16575503, -7.95867761],
                            [39.16615345, -7.95858462],
                            [39.16653859, -7.95847836],
                            [39.16693702, -7.95845179],
                            [39.16730888, -7.95843851],
                            [39.16750809, -7.95833224],
                            [39.16752137, -7.95807985],
                            [39.16741513, -7.95777432],
                            [39.16730888, -7.9572961],
                            [39.16720264, -7.95695072],
                            [39.16713624, -7.95651236],
                            [39.16714952, -7.95604743],
                            [39.16736201, -7.95559578],
                            [39.16765419, -7.95502458],
                            [39.16790653, -7.95454637],
                            [39.16814559, -7.95405487],
                            [39.16839792, -7.95362979],
                            [39.16865026, -7.9533774],
                            [39.16890259, -7.95317814],
                            [39.16919477, -7.95295232],
                            [39.16943383, -7.95285933],
                            [39.16961976, -7.95279292],
                            [39.16979241, -7.95279292],
                            [39.17004474, -7.9528062],
                            [39.17033692, -7.9528062],
                            [39.1706291, -7.9528062],
                            [39.17094784, -7.95266008],
                            [39.17127986, -7.95250068],
                            [39.17163844, -7.95212874],
                            [39.17191734, -7.95179664],
                            [39.17210327, -7.95146455],
                            [39.17220952, -7.95110589],
                            [39.17223608, -7.95062767],
                            [39.17223608, -7.95009632],
                            [39.17223608, -7.94957825],
                            [39.17231577, -7.94899377],
                            [39.17235562, -7.94848899],
                            [39.17250171, -7.94805062],
                            [39.17263452, -7.94766539],
                            [39.17291341, -7.9473333],
                            [39.1733384, -7.94693479],
                            [39.17372354, -7.94653628],
                            [39.17422821, -7.94620419],
                            [39.17466648, -7.94592523],
                            [39.17537036, -7.94557985],
                            [39.17579535, -7.94540717],
                            [39.17648595, -7.94532747],
                            [39.17695078, -7.94535404],
                            [39.17721639, -7.94535404],
                            [39.1776281, -7.94551344],
                            [39.17778746, -7.94575255],
                            [39.17781403, -7.94599166],
                            [39.17781402, -7.94625734],
                            [39.17777418, -7.94662928],
                            [39.17778746, -7.94701451],
                            [39.17777418, -7.94733332],
                            [39.17778746, -7.94762556],
                            [39.17776089, -7.94793109],
                            [39.17778745, -7.94821005],
                            [39.17784058, -7.94854214],
                            [39.17790698, -7.94883439],
                            [39.17814603, -7.94906021],
                            [39.17841165, -7.94919305],
                            [39.17884991, -7.94923291],
                            [39.17923505, -7.94908678],
                            [39.1796202, -7.94896723],
                            [39.18001862, -7.94879455],
                            [39.18033736, -7.94867499],
                            [39.18086859, -7.94858201],
                            [39.18116077, -7.94860858],
                            [39.1814131, -7.94860858],
                            [39.181692, -7.94855544],
                            [39.18207714, -7.94856873],
                            [39.18252869, -7.9485156],
                            [39.18291383, -7.94832963],
                            [39.18325913, -7.94809052],
                            [39.18360443, -7.94785141],
                            [39.1839763, -7.9475326],
                            [39.18445441, -7.94735992],
                            [39.18487939, -7.94722708],
                            [39.18533094, -7.94702783],
                            [39.18579577, -7.94686842],
                            [39.18620747, -7.94680201],
                            [39.1866723, -7.94672231],
                            [39.18700432, -7.94673559],
                            [39.18740274, -7.94676216],
                            [39.18788085, -7.946895],
                            [39.18813318, -7.94701456],
                            [39.18863785, -7.94716068],
                            [39.18886362, -7.94717396],
                            [39.18910268, -7.94725367],
                            [39.18923549, -7.94742636],
                            [39.18939485, -7.94767875],
                            [39.18955422, -7.94790457],
                            [39.18981984, -7.94803741],
                            [39.19003233, -7.94826324],
                            [39.19016513, -7.94850235],
                            [39.19012529, -7.94879459],
                            [39.19020497, -7.94904698],
                            [39.19048387, -7.94920639],
                            [39.19082917, -7.94932594],
                            [39.19124087, -7.94929938],
                            [39.19140024, -7.94912669],
                            [39.19146665, -7.94851564],
                            [39.19150649, -7.9478913],
                            [39.1915065, -7.94710756],
                            [39.19146666, -7.94668247],
                            [39.19132057, -7.94628396],
                            [39.1911612, -7.94588545],
                            [39.1910284, -7.9455135],
                            [39.19094871, -7.94526111],
                            [39.19076278, -7.94504857],
                            [39.19055029, -7.94499543],
                            [39.19016515, -7.9449423],
                            [39.18975345, -7.94478289],
                            [39.18942143, -7.94450393],
                            [39.18899645, -7.94402571],
                            [39.18870427, -7.94382645],
                            [39.18842537, -7.94353421],
                            [39.18818632, -7.94333495],
                            [39.18786758, -7.94316226],
                            [39.18768165, -7.9428833],
                            [39.18756213, -7.94253792],
                            [39.18746916, -7.94229881],
                            [39.18746916, -7.94212613],
                            [39.18764181, -7.94192687],
                            [39.18788087, -7.94158149],
                            [39.18813321, -7.94126268],
                            [39.1883457, -7.94108999],
                            [39.18854491, -7.94089074],
                            [39.18875741, -7.94077119],
                            [39.18882381, -7.94065163],
                            [39.18875741, -7.94061178],
                            [39.18861132, -7.94061178],
                            [39.18841211, -7.94069148],
                            [39.18821289, -7.94074462],
                            [39.18797384, -7.9409173],
                            [39.18780119, -7.94114313],
                            [39.18764182, -7.94139552],
                            [39.18740276, -7.94167448],
                            [39.18728323, -7.94188702],
                            [39.18719027, -7.94209956],
                            [39.18695121, -7.94217926],
                            [39.18667232, -7.94224568],
                            [39.18651295, -7.94224568],
                            [39.18627389, -7.94224567],
                            [39.18612781, -7.94231209],
                            [39.185995, -7.94256448],
                            [39.18594187, -7.94309583],
                            [39.18598171, -7.94354748],
                            [39.18611452, -7.94383973],
                            [39.18631373, -7.94413197],
                            [39.18657934, -7.94439765],
                            [39.18679183, -7.94446407],
                            [39.18707073, -7.94458362],
                            [39.18737619, -7.94458362],
                            [39.18765508, -7.94462347],
                            [39.1879871, -7.94470318],
                            [39.18827928, -7.94487587],
                            [39.18846521, -7.94506184],
                            [39.18865114, -7.94532752],
                            [39.1887441, -7.94552678],
                            [39.18878395, -7.94569946],
                            [39.18862458, -7.94581902],
                            [39.18835896, -7.94579245],
                            [39.18794726, -7.94572603],
                            [39.18745587, -7.94555334],
                            [39.18715041, -7.94530094],
                            [39.18689808, -7.94511497],
                            [39.18667231, -7.94498213],
                            [39.18636685, -7.94486258],
                            [39.1861278, -7.94471645],
                            [39.18596843, -7.94455705],
                            [39.18579578, -7.94434451],
                            [39.18571609, -7.94403898],
                            [39.18562313, -7.94362718],
                            [39.18557001, -7.94322867],
                            [39.18549033, -7.94293643],
                            [39.18539736, -7.94257776],
                            [39.18545049, -7.94232537],
                            [39.18555673, -7.94204641],
                            [39.18582235, -7.94170104],
                            [39.18616765, -7.9414088],
                            [39.18656608, -7.94110327],
                            [39.18705747, -7.94087745],
                            [39.18750901, -7.94055864],
                            [39.18797384, -7.94030625],
                            [39.18826602, -7.94017341],
                            [39.1885582, -7.94006715],
                            [39.18887694, -7.93998744],
                            [39.18915583, -7.93986789],
                            [39.18935504, -7.93989446],
                            [39.18971362, -7.93998745],
                            [39.19012533, -7.94009372],
                            [39.1903511, -7.940107],
                            [39.19033782, -7.93998745],
                            [39.19021829, -7.93980148],
                            [39.19005893, -7.93962879],
                            [39.18988628, -7.93938968],
                            [39.18978003, -7.93913729],
                            [39.18958082, -7.93901773],
                            [39.18942145, -7.93884504],
                            [39.1892488, -7.93865907],
                            [39.18922224, -7.93840668],
                            [39.18930193, -7.93807458],
                            [39.1893949, -7.93770264],
                            [39.18954099, -7.93723771],
                            [39.18963395, -7.93673292],
                            [39.18962067, -7.93637426],
                            [39.18966052, -7.93605545],
                            [39.18983317, -7.93567022],
                            [39.1899527, -7.93537798],
                            [39.1900191, -7.93519201],
                            [39.19003238, -7.93497947],
                            [39.18997926, -7.93480678],
                            [39.18984646, -7.93471379],
                            [39.18968709, -7.93472708],
                            [39.189541, -7.93484663],
                            [39.18943475, -7.93497947],
                            [39.18924882, -7.93516544],
                            [39.18919569, -7.93536469],
                            [39.18899648, -7.93543111],
                            [39.18885039, -7.93539126],
                            [39.18874415, -7.93528499],
                            [39.18866446, -7.93507245],
                            [39.18878399, -7.93488648],
                            [39.18902305, -7.93458095],
                            [39.18918242, -7.93432856],
                            [39.18938163, -7.93422229],
                            [39.18952772, -7.93410274],
                            [39.18971365, -7.9339699],
                            [39.18985974, -7.93386363],
                            [39.1898863, -7.93365109],
                            [39.18975349, -7.93350497],
                            [39.18963397, -7.93341198],
                            [39.18946132, -7.93341198],
                            [39.18920898, -7.93342526],
                            [39.18907617, -7.93358467],
                            [39.18894337, -7.93378392],
                            [39.18886368, -7.93403632],
                            [39.18866447, -7.93432856],
                            [39.18847853, -7.93471379],
                            [39.18830588, -7.93496618],
                            [39.18811995, -7.93536469],
                            [39.18802698, -7.93573663],
                            [39.18793402, -7.93602888],
                            [39.18768168, -7.93624142],
                            [39.18724342, -7.93638754],
                            [39.18683171, -7.93646724],
                            [39.18636688, -7.93650709],
                            [39.18603486, -7.93666649],
                            [39.18575597, -7.93683918],
                            [39.18551691, -7.93705172],
                            [39.18530442, -7.93730411],
                            [39.1852513, -7.93749008],
                            [39.18534426, -7.93768934],
                            [39.18558331, -7.93772919],
                            [39.18587549, -7.93779561],
                            [39.18611454, -7.93784875],
                            [39.1863536, -7.93791517],
                            [39.18657937, -7.93806129],
                            [39.18673874, -7.93826055],
                            [39.18677858, -7.93849965],
                            [39.18671218, -7.93873876],
                            [39.18649968, -7.93883175],
                            [39.18619422, -7.9388716],
                            [39.18587549, -7.93876533],
                            [39.18567627, -7.93867234],
                            [39.18547706, -7.93847308],
                            [39.18529113, -7.93828711],
                            [39.18507864, -7.93802143],
                            [39.18486615, -7.93782217],
                            [39.18464038, -7.93764948],
                            [39.18452085, -7.93752993],
                            [39.18453413, -7.93729082],
                            [39.1845607, -7.93703843],
                            [39.18470679, -7.93681261],
                            [39.18503881, -7.9365735],
                            [39.18543723, -7.93634768],
                            [39.1858755, -7.936082],
                            [39.18626064, -7.9358429],
                            [39.18671219, -7.93549752],
                            [39.18717702, -7.93524513],
                            [39.18758872, -7.93493961],
                            [39.18784106, -7.93471378],
                            [39.18804027, -7.93436841],
                            [39.1880934, -7.93399646],
                            [39.1880934, -7.9336378],
                            [39.18801371, -7.93338541],
                            [39.18780122, -7.93321272],
                            [39.18761529, -7.93313301],
                            [39.18738952, -7.93311973],
                            [39.18716374, -7.93311973],
                            [39.18699109, -7.93321271],
                            [39.18684501, -7.93327913],
                            [39.18665907, -7.9334651],
                            [39.18644658, -7.93366436],
                            [39.18628721, -7.93378391],
                            [39.186088, -7.93382376],
                            [39.18583566, -7.93375734],
                            [39.18553021, -7.93355809],
                            [39.185331, -7.93330569],
                            [39.18523803, -7.93319942],
                            [39.18503882, -7.9330533],
                            [39.18475992, -7.93310643],
                            [39.18450759, -7.93322599],
                            [39.1842951, -7.93334554],
                            [39.18402948, -7.93351823],
                            [39.18384355, -7.93377062],
                            [39.18372402, -7.93396988],
                            [39.18357793, -7.93423555],
                            [39.18340528, -7.93446137],
                            [39.18317951, -7.93459421],
                            [39.18299357, -7.9346872],
                            [39.18272796, -7.93474033],
                            [39.18239594, -7.93475361],
                            [39.18222329, -7.93468719],
                            [39.18206392, -7.93456764],
                            [39.18185143, -7.93444808],
                            [39.18162566, -7.93440823],
                            [39.18141316, -7.93447465],
                            [39.18126708, -7.93447465],
                            [39.18105458, -7.9345145],
                            [39.18082881, -7.93446136],
                            [39.18064288, -7.93428867],
                            [39.18052335, -7.93416912],
                            [39.18025774, -7.93395658],
                            [39.18012493, -7.93365105],
                            [39.18008509, -7.93334552],
                            [39.18016478, -7.93306656],
                            [39.18037727, -7.93277432],
                            [39.18062961, -7.93242894],
                            [39.18101475, -7.93203043],
                            [39.18128037, -7.93167177],
                            [39.18147958, -7.93141938],
                            [39.18147958, -7.93110057],
                            [39.18132021, -7.93063564],
                            [39.18114757, -7.93035668],
                            [39.18086867, -7.93011757],
                            [39.18065618, -7.92985189],
                            [39.18043041, -7.92953308],
                            [39.1802312, -7.92926741],
                            [39.17996558, -7.92896188],
                            [39.17971325, -7.92874934],
                            [39.17943435, -7.9286165],
                            [39.1791289, -7.92849694],
                            [39.17873047, -7.9283641],
                            [39.1784383, -7.92819141],
                            [39.1782258, -7.92804529],
                            [39.17810628, -7.92784603],
                            [39.17798675, -7.92758036],
                            [39.17788051, -7.92731468],
                            [39.17772114, -7.92716856],
                            [39.17756177, -7.92712871],
                            [39.17736256, -7.92712871],
                            [39.17720319, -7.92712871],
                            [39.1769907, -7.92727483],
                            [39.17675164, -7.92744752],
                            [39.17661883, -7.92771319],
                            [39.17647274, -7.9278726],
                            [39.17624697, -7.92800543],
                            [39.17592823, -7.92805856],
                            [39.17543684, -7.92796558],
                            [39.17497202, -7.92783274],
                            [39.1744275, -7.9276999],
                            [39.17375019, -7.92746079],
                            [39.17336504, -7.92731466],
                            [39.17309943, -7.92715526],
                            [39.17274085, -7.9270357],
                            [39.17252836, -7.92692943],
                            [39.17224946, -7.92686301],
                            [39.17201041, -7.92691614],
                            [39.1718776, -7.92704898],
                            [39.17170495, -7.92726152],
                            [39.17149245, -7.92758033],
                            [39.17127996, -7.92781944],
                            [39.17092138, -7.92808511],
                            [39.17065576, -7.92829765],
                            [39.17037687, -7.92840392],
                            [39.17007141, -7.92847034],
                            [39.16968626, -7.92858989],
                            [39.1691816, -7.92861646],
                            [39.16879645, -7.92853675],
                            [39.16841131, -7.92832421],
                            [39.16784024, -7.92803196],
                            [39.16756135, -7.92764673],
                            [39.16720277, -7.92731464],
                            [39.16693715, -7.92706224],
                            [39.16673794, -7.92677],
                            [39.16652545, -7.92646447],
                            [39.16624655, -7.92626522],
                            [39.16588797, -7.9261988],
                            [39.16544971, -7.92613237],
                            [39.16495832, -7.92607924],
                            [39.16463958, -7.92607924],
                            [39.16426772, -7.92607923],
                            [39.16382945, -7.92601281],
                            [39.1635904, -7.92587998],
                            [39.16332479, -7.92562758],
                            [39.16316542, -7.92541504],
                            [39.16297949, -7.92525564],
                            [39.16275372, -7.92513608],
                            [39.16250138, -7.92513608],
                            [39.16231545, -7.92524235],
                            [39.16204983, -7.92540175],
                            [39.16181078, -7.92553459],
                            [39.16161157, -7.92564086],
                            [39.16135923, -7.92568071],
                            [39.1611069, -7.92566742],
                            [39.16085456, -7.92566742],
                            [39.16069519, -7.92568071],
                            [39.1605491, -7.92582683],
                            [39.16053582, -7.92606593],
                            [39.16052254, -7.92621206],
                            [39.16029677, -7.92631833],
                            [39.16009755, -7.92641131],
                            [39.15996475, -7.92654415],
                            [39.16001787, -7.92675669],
                            [39.15995146, -7.92710207],
                            [39.15979209, -7.92747401],
                            [39.159646, -7.92760685],
                            [39.15938039, -7.92762013],
                            [39.15908821, -7.92760685],
                            [39.15874291, -7.92752714],
                            [39.15831793, -7.92751386],
                            [39.15807887, -7.92751386],
                            [39.15777341, -7.927474],
                            [39.1575078, -7.9273943],
                            [39.15734843, -7.92726146],
                            [39.15710938, -7.92707549],
                            [39.15696329, -7.92687623],
                            [39.15689689, -7.92659727],
                            [39.15687033, -7.92617219],
                            [39.15679064, -7.92598622],
                            [39.15672424, -7.92578696],
                            [39.15657815, -7.92561427],
                            [39.15645863, -7.92561427],
                            [39.15628598, -7.92568069],
                            [39.15611332, -7.92578696],
                            [39.15591411, -7.92580024],
                            [39.15575474, -7.92573382],
                            [39.15555553, -7.92560098],
                            [39.1553032, -7.92542829],
                            [39.1552368, -7.92518918],
                            [39.15515711, -7.92484381],
                            [39.15501103, -7.92457813],
                            [39.15486494, -7.92444529],
                            [39.15458604, -7.92424603],
                            [39.15438683, -7.92400693],
                            [39.15418762, -7.9237811],
                            [39.15388216, -7.92354199],
                            [39.15364311, -7.92330288],
                            [39.15340406, -7.92293093],
                            [39.15317829, -7.92254571],
                            [39.15301892, -7.92224018],
                            [39.1529658, -7.92185495],
                            [39.1528994, -7.92153614],
                            [39.15280643, -7.9213236],
                            [39.15258066, -7.92105792],
                            [39.15240801, -7.92080553],
                            [39.1521424, -7.92065941],
                            [39.15191662, -7.9206594],
                            [39.15161116, -7.9206594],
                            [39.15137211, -7.9206594],
                            [39.15118618, -7.92060627],
                            [39.15098697, -7.92042029],
                            [39.15077448, -7.92012805],
                            [39.15061511, -7.91976939],
                            [39.15053543, -7.91939744],
                            [39.15042918, -7.91911848],
                            [39.15028309, -7.91883952],
                            [39.15008388, -7.91853399],
                            [39.14989795, -7.91825503],
                            [39.14967218, -7.91788309],
                            [39.14951281, -7.91772368],
                            [39.14930032, -7.91755099],
                            [39.14916752, -7.9172189],
                            [39.14903471, -7.91683367],
                            [39.14886206, -7.91644844],
                            [39.14875582, -7.91620933],
                            [39.14854333, -7.91595694],
                            [39.14822459, -7.91578425],
                            [39.1480121, -7.91578424],
                            [39.1477332, -7.91577096],
                            [39.14752071, -7.91575768],
                            [39.14728165, -7.91562484],
                            [39.14718869, -7.9154123],
                            [39.14717541, -7.91512005],
                            [39.14709572, -7.91498722],
                            [39.14693636, -7.91482781],
                            [39.14676371, -7.91478796],
                            [39.14649809, -7.91478796],
                            [39.14625904, -7.91482781],
                            [39.14604654, -7.91490751],
                            [39.14570124, -7.91496064],
                            [39.1453161, -7.91494736],
                            [39.14514345, -7.91488094],
                            [39.14495752, -7.9146684],
                            [39.14481143, -7.914416],
                            [39.14463879, -7.91400421],
                            [39.14461223, -7.91375181],
                            [39.14443958, -7.91337987],
                            [39.14425365, -7.91315404],
                            [39.14399235, -7.91311389],
                            [39.14427349, -7.91243097],
                            [39.14452862, -7.91166543],
                            [39.14369945, -7.91166542],
                            [39.14261515, -7.91141025],
                            [39.140893, -7.91134643],
                            [39.13885196, -7.91089985],
                            [39.13757633, -7.91077225],
                            [39.1361731, -7.91058085],
                            [39.13547149, -7.91013424],
                            [39.13476988, -7.9094963],
                            [39.13400446, -7.9094325],
                            [39.13344956, -7.90875625],
                            [39.1325566, -7.90703372],
                            [39.13219115, -7.90614015],
                            [39.13255691, -7.90593806],
                            [39.13272245, -7.90585041],
                            [39.13292693, -7.9057238],
                            [39.13309246, -7.90564588],
                            [39.13326773, -7.90563614],
                            [39.133443, -7.90565562],
                            [39.13356958, -7.90568484],
                            [39.13365722, -7.90574328],
                            [39.13376433, -7.90588937],
                            [39.13385196, -7.90608416],
                            [39.13397855, -7.90626921],
                            [39.13425119, -7.90657113],
                            [39.13459199, -7.90685358],
                            [39.134962, -7.90700941],
                            [39.13542939, -7.90714576],
                            [39.13599415, -7.90724316],
                            [39.13659786, -7.90724316],
                            [39.13717235, -7.90723342],
                            [39.13754237, -7.90719447],
                            [39.13800975, -7.90709708],
                            [39.13850635, -7.90697046],
                            [39.13882768, -7.90689255],
                            [39.13942165, -7.90668803],
                            [39.13968456, -7.90658089],
                            [39.1399572, -7.90654194],
                            [39.14028827, -7.90659063],
                            [39.14056091, -7.90666855],
                            [39.14098935, -7.90672699],
                            [39.14140805, -7.90668803],
                            [39.14185596, -7.90661986],
                            [39.14229414, -7.90657116],
                            [39.14266415, -7.90652247],
                            [39.142966, -7.90648351],
                            [39.1431997, -7.90647377],
                            [39.1433847, -7.90653221],
                            [39.14356971, -7.9066783],
                            [39.14360282, -7.90678544],
                            [39.14358724, -7.90705814],
                            [39.14354829, -7.9072763],
                            [39.14348597, -7.90751005],
                            [39.1434626, -7.907736],
                            [39.14350934, -7.90786846],
                            [39.14357944, -7.90795417],
                            [39.1437664, -7.90811779],
                            [39.14407799, -7.90836712],
                            [39.14447527, -7.90853854],
                            [39.14486475, -7.90872554],
                            [39.14525424, -7.90882683],
                            [39.14551909, -7.9088502],
                            [39.14572942, -7.90886579],
                            [39.14590079, -7.90886579],
                            [39.14625133, -7.90899045],
                            [39.14674988, -7.90915408],
                            [39.14714716, -7.90929433],
                            [39.14752886, -7.90949691],
                            [39.14788718, -7.90962157],
                            [39.14823772, -7.90966833],
                            [39.14851816, -7.90967612],
                            [39.14882975, -7.9096917],
                            [39.14910239, -7.90973845],
                            [39.14934387, -7.90982416],
                            [39.14959315, -7.90982416],
                            [39.14976452, -7.90980858],
                            [39.15003716, -7.90990987],
                            [39.1501618, -7.91001895],
                            [39.15023191, -7.91019816],
                            [39.15026306, -7.9103462],
                            [39.1503098, -7.9105254],
                            [39.15052013, -7.91066565],
                            [39.1506889, -7.91073318],
                            [39.15097972, -7.9107124],
                            [39.15132247, -7.91066046],
                            [39.15159252, -7.91057735],
                            [39.1519145, -7.91048386],
                            [39.15226763, -7.91049425],
                            [39.15257922, -7.91059813],
                            [39.15279734, -7.91077474],
                            [39.15302584, -7.91097213],
                            [39.1533582, -7.91133574],
                            [39.15357631, -7.91166818],
                            [39.1536594, -7.91195906],
                            [39.15359708, -7.91227072],
                            [39.15346206, -7.91258238],
                            [39.15335819, -7.91287327],
                            [39.15329587, -7.91309143],
                            [39.15337896, -7.91321609],
                            [39.15346829, -7.91330544],
                            [39.15371756, -7.91352152],
                            [39.15395021, -7.91370437],
                            [39.15402499, -7.91389552],
                            [39.15404992, -7.91414485],
                            [39.15414132, -7.914336],
                            [39.1543075, -7.91445235],
                            [39.15444876, -7.91459364],
                            [39.15465648, -7.91465182],
                            [39.15491406, -7.91461027],
                            [39.1550387, -7.91456871],
                            [39.15520488, -7.91454378],
                            [39.15543754, -7.91453547],
                            [39.15565357, -7.91461027],
                            [39.15586961, -7.91475156],
                            [39.15603579, -7.91485129],
                            [39.1563183, -7.91502582],
                            [39.15671714, -7.91530839],
                            [39.15698303, -7.91557435],
                            [39.15719075, -7.91579043],
                            [39.15739017, -7.91594834],
                            [39.15756466, -7.91603145],
                            [39.15786194, -7.91610348],
                            [39.15812783, -7.91604438],
                            [39.15842327, -7.91594096],
                            [39.15857099, -7.91576366],
                            [39.15885165, -7.91551248],
                            [39.159088, -7.91533518],
                            [39.15930957, -7.91532041],
                            [39.15961978, -7.91534996],
                            [39.15990044, -7.91557159],
                            [39.16009247, -7.91577844],
                            [39.16040268, -7.91605917],
                            [39.16066857, -7.91605917],
                            [39.16097878, -7.9161626],
                            [39.16125944, -7.91632512],
                            [39.1616435, -7.91667973],
                            [39.16187985, -7.91684225],
                            [39.16217528, -7.91688658],
                            [39.16241163, -7.91688658],
                            [39.16263321, -7.91710821],
                            [39.16275138, -7.91731506],
                            [39.16269229, -7.91758101],
                            [39.16261843, -7.91783218],
                            [39.1625298, -7.91812769],
                            [39.16245594, -7.91871869],
                            [39.16233776, -7.91932447],
                            [39.16229345, -7.91973817],
                            [39.16221294, -7.92000656],
                            [39.16356197, -7.92027641],
                            [39.16351083, -7.91976115],
                            [39.16334312, -7.9193977],
                            [39.16321269, -7.9190063],
                            [39.16317542, -7.91866148],
                            [39.16319405, -7.91837259],
                            [39.16329654, -7.91808369],
                            [39.16337108, -7.91786003],
                            [39.16363196, -7.91754318],
                            [39.16398601, -7.91720769],
                            [39.16447051, -7.91694675],
                            [39.16488046, -7.91676969],
                            [39.16529041, -7.91666718],
                            [39.16556993, -7.9166765],
                            [39.16573763, -7.91675105],
                            [39.16588671, -7.91683493],
                            [39.16613827, -7.91699336],
                            [39.16643642, -7.91717042],
                            [39.16672525, -7.91738477],
                            [39.16703271, -7.91759911],
                            [39.16727496, -7.91784141],
                            [39.16739608, -7.91813031],
                            [39.16749856, -7.91829805],
                            [39.16761037, -7.91840988],
                            [39.16776876, -7.9185124],
                            [39.16796442, -7.91863355],
                            [39.16816008, -7.91868946],
                            [39.16838369, -7.91868014],
                            [39.16858867, -7.91866151],
                            [39.16886818, -7.91859627],
                            [39.16924086, -7.9185124],
                            [39.16971604, -7.91837262],
                            [39.16984648, -7.9183633],
                            [39.16999555, -7.91849377],
                            [39.1702378, -7.91866151],
                            [39.17045209, -7.91874539],
                            [39.17059185, -7.91875471],
                            [39.17083409, -7.91872675],
                            [39.17116019, -7.91866152],
                            [39.17149561, -7.91863356],
                            [39.17171922, -7.91867084],
                            [39.17200805, -7.91875471],
                            [39.17229688, -7.91901565],
                            [39.17257639, -7.91916476],
                            [39.17282795, -7.91917408],
                            [39.17302361, -7.91909021],
                            [39.17320064, -7.91895042],
                            [39.17340562, -7.91868017],
                            [39.17356401, -7.91858697],
                            [39.17371308, -7.91856834],
                            [39.17388079, -7.91865221],
                            [39.17409508, -7.91881064],
                            [39.17426279, -7.91886655],
                            [39.17447709, -7.91897839],
                            [39.17467274, -7.91904362],
                            [39.17499884, -7.91909022],
                            [39.17526904, -7.91915545],
                            [39.1755765, -7.91923933],
                            [39.17613553, -7.91943503],
                            [39.17640573, -7.91945367],
                            [39.17670388, -7.91939776],
                            [39.17691817, -7.91930457],
                            [39.17721632, -7.91913682],
                            [39.17752378, -7.91905295],
                            [39.17795237, -7.91895976],
                            [39.17857662, -7.91881066],
                            [39.17912633, -7.91870815],
                            [39.17948038, -7.91856836],
                            [39.17964809, -7.9183913],
                            [39.1798717, -7.91806512],
                            [39.18002078, -7.91772963],
                            [39.18015122, -7.91751529],
                            [39.18028166, -7.91741278],
                            [39.18044937, -7.91733823],
                            [39.18066366, -7.91729163],
                            [39.18089659, -7.91738483],
                            [39.18108293, -7.9175153],
                            [39.181232, -7.91765508],
                            [39.18138108, -7.91786011],
                            [39.18169786, -7.91827016],
                            [39.18197737, -7.91858701],
                            [39.18231279, -7.91880135],
                            [39.18258298, -7.91890387],
                            [39.1829184, -7.91897842],
                            [39.18314201, -7.9189691],
                            [39.18347742, -7.91895979],
                            [39.18379421, -7.91894115],
                            [39.18412031, -7.91892251],
                            [39.18438119, -7.91896911],
                            [39.18462343, -7.91909958],
                            [39.18492158, -7.91923937],
                            [39.18507065, -7.9193978],
                            [39.18516382, -7.91957486],
                            [39.18513587, -7.91971465],
                            [39.18503338, -7.91980784],
                            [39.1848284, -7.91989171],
                            [39.18457684, -7.91999422],
                            [39.18427869, -7.92012469],
                            [39.18409235, -7.92032971],
                            [39.18393396, -7.92065589],
                            [39.18383147, -7.92085159],
                            [39.18382215, -7.92097274],
                            [39.18391532, -7.92109389],
                            [39.18413893, -7.92123368],
                            [39.18441844, -7.92134551],
                            [39.18468864, -7.92138279],
                            [39.18496815, -7.9214387],
                            [39.18517313, -7.92158781],
                            [39.18518245, -7.92180216],
                            [39.18510791, -7.9219699],
                            [39.18498679, -7.92198854],
                            [39.18490293, -7.92218424],
                            [39.18501474, -7.92234267],
                            [39.18524766, -7.92243586],
                            [39.18555513, -7.9224545],
                            [39.18617006, -7.92252906],
                            [39.18650547, -7.92261293],
                            [39.18666386, -7.92269681],
                            [39.18674772, -7.92286455],
                            [39.18676635, -7.92308821],
                            [39.18670113, -7.92328392],
                            [39.18657069, -7.92344234],
                            [39.18645888, -7.92367532],
                            [39.18638435, -7.92391762],
                            [39.1864123, -7.92409469],
                            [39.18662659, -7.92429039],
                            [39.18683157, -7.92440223],
                            [39.18701791, -7.9244861],
                            [39.18714835, -7.92463521],
                            [39.18732537, -7.9247284],
                            [39.18753035, -7.924775],
                            [39.18772601, -7.92468181],
                            [39.18779123, -7.92450474],
                            [39.18791235, -7.92423448],
                            [39.18802416, -7.92403878],
                            [39.18821982, -7.92388967],
                            [39.18839684, -7.92378716],
                            [39.18844343, -7.92363806],
                            [39.18844343, -7.92347031],
                            [39.18846207, -7.92327461],
                            [39.18861114, -7.9230789],
                            [39.18884407, -7.9228366],
                            [39.18904594, -7.92271235],
                            [39.18941863, -7.9226875],
                            [39.18974162, -7.9225881],
                            [39.18997766, -7.92235201],
                            [39.19012673, -7.92207865],
                            [39.19020127, -7.92175558],
                            [39.19044973, -7.92155677],
                            [39.19069818, -7.92134554],
                            [39.19105845, -7.92105975],
                            [39.19143113, -7.92074911],
                            [39.19191563, -7.92047575],
                            [39.19217651, -7.92033907],
                            [39.192338, -7.92033907],
                            [39.19230073, -7.92062486],
                            [39.19223862, -7.92097277],
                            [39.19210196, -7.92117158],
                            [39.1919032, -7.92143252],
                            [39.19182866, -7.92176801],
                            [39.19179139, -7.92212836],
                            [39.19167958, -7.92241414],
                            [39.19128205, -7.92262538],
                            [39.19093421, -7.92286146],
                            [39.19084725, -7.9231224],
                            [39.19077271, -7.92339577],
                            [39.19041245, -7.92358215],
                            [39.19017642, -7.9236567],
                            [39.19012672, -7.92381823],
                            [39.18992796, -7.92417858],
                            [39.18971677, -7.92452649],
                            [39.18962981, -7.92484956],
                            [39.1896298, -7.9251602],
                            [39.18931923, -7.92547084],
                            [39.18902108, -7.92558267],
                            [39.1887602, -7.9256945],
                            [39.1884869, -7.92593058],
                            [39.18820117, -7.92630335],
                            [39.18796514, -7.92665127],
                            [39.18781606, -7.92693706],
                            [39.1878906, -7.92739681],
                            [39.1878906, -7.92760804],
                            [39.18792787, -7.92791868],
                            [39.18806452, -7.92819205],
                            [39.18830055, -7.92862695],
                            [39.18849931, -7.92897486],
                            [39.18889684, -7.92938491],
                            [39.1892571, -7.92954645],
                            [39.18979128, -7.92964585],
                            [39.19032546, -7.92963343],
                            [39.19063604, -7.92955888],
                            [39.19087207, -7.92934764],
                            [39.19109568, -7.92918611],
                            [39.19118264, -7.92899973],
                            [39.19119507, -7.92872636],
                            [39.19121991, -7.92853998],
                            [39.1912696, -7.92832874],
                            [39.19134414, -7.92810508],
                            [39.19150564, -7.92789385],
                            [39.19170441, -7.927869],
                            [39.1918659, -7.92798083],
                            [39.19207709, -7.92811751],
                            [39.1923007, -7.92834117],
                            [39.19237524, -7.92856483],
                            [39.19244977, -7.92885062],
                            [39.19252431, -7.92904943],
                            [39.19264853, -7.92938493],
                            [39.19272307, -7.92963344],
                            [39.19262369, -7.9299068],
                            [39.19261126, -7.9303417],
                            [39.19267337, -7.93091328],
                            [39.19276033, -7.93144758],
                            [39.19300879, -7.93178308],
                            [39.19321997, -7.9318452],
                            [39.19331936, -7.9317458],
                            [39.19338147, -7.93144758],
                            [39.19344359, -7.93111209],
                            [39.19359266, -7.93060264],
                            [39.19384112, -7.93011804],
                            [39.19412685, -7.9298074],
                            [39.19446227, -7.92952162],
                            [39.19473557, -7.92936008],
                            [39.1950213, -7.92924826],
                            [39.19526975, -7.9291737],
                            [39.19548094, -7.92911158],
                            [39.19581636, -7.92908673],
                            [39.19608966, -7.9290246],
                            [39.19633812, -7.92882579],
                            [39.19656173, -7.92865183],
                            [39.19673565, -7.9284903],
                            [39.19694684, -7.9283909],
                            [39.19722014, -7.92837847],
                            [39.19749344, -7.92842817],
                            [39.19782886, -7.92851516],
                            [39.19804005, -7.92852758],
                            [39.19821397, -7.92836605],
                            [39.1981767, -7.92808026],
                            [39.19820155, -7.92778204],
                            [39.19832578, -7.92752111],
                            [39.19853696, -7.92744655],
                            [39.19867361, -7.92744655],
                            [39.19881027, -7.92752111],
                            [39.19899661, -7.9277572],
                            [39.19909599, -7.92793116],
                            [39.19918295, -7.92815482],
                            [39.19935687, -7.92835363],
                            [39.19958048, -7.92850274],
                            [39.19991589, -7.92849031],
                            [39.20016435, -7.92852759],
                            [39.20037554, -7.92863942],
                            [39.20068611, -7.92875125],
                            [39.20087245, -7.92881338],
                            [39.20107048, -7.92894155],
                            [39.20135123, -7.92869195],
                            [39.20136108, -7.92875351],
                            [39.20137806, -7.92899104],
                            [39.20138651, -7.92920311],
                            [39.20135259, -7.92948305],
                            [39.20131865, -7.92974602],
                            [39.2013441, -7.92992417],
                            [39.20147133, -7.93014472],
                            [39.20157309, -7.93047557],
                            [39.20153917, -7.93073852],
                            [39.20137805, -7.93101846],
                            [39.20123385, -7.93124751],
                            [39.20117448, -7.93145959],
                            [39.20109815, -7.9316462],
                            [39.200937, -7.93193463],
                            [39.20067409, -7.9322994],
                            [39.20048752, -7.93256237],
                            [39.20014826, -7.93282534],
                            [39.19994472, -7.93307983],
                            [39.19979206, -7.9333428],
                            [39.19966484, -7.93362274],
                            [39.19953763, -7.93392812],
                            [39.19931712, -7.93421655],
                            [39.19907964, -7.93436923],
                            [39.19886762, -7.93447103],
                            [39.19868103, -7.93450496],
                            [39.19847749, -7.93450496],
                            [39.19829091, -7.93442862],
                            [39.1981552, -7.93434379],
                            [39.198028, -7.93434379],
                            [39.19790925, -7.9343862],
                            [39.19779901, -7.93454737],
                            [39.19778204, -7.934734],
                            [39.19786685, -7.93492063],
                            [39.19803648, -7.9350818],
                            [39.19835875, -7.93517511],
                            [39.19882521, -7.93527692],
                            [39.19937647, -7.93529389],
                            [39.19980902, -7.93532782],
                            [39.20009736, -7.93532782],
                            [39.20031787, -7.9351497],
                            [39.20057232, -7.93487822],
                            [39.20077586, -7.93452194],
                            [39.20099637, -7.93403841],
                            [39.20108966, -7.93367364],
                            [39.2011151, -7.93340219],
                            [39.20117447, -7.93319012],
                            [39.20134409, -7.93288473],
                            [39.20151371, -7.93272355],
                            [39.20189537, -7.93259631],
                            [39.2021413, -7.93243514],
                            [39.20241271, -7.93237575],
                            [39.20279436, -7.93235878],
                            [39.20311665, -7.93235878],
                            [39.20351526, -7.932257],
                            [39.2037188, -7.9320025],
                            [39.20388842, -7.93173104],
                            [39.20401563, -7.93164622],
                            [39.20410892, -7.9316547],
                            [39.20423614, -7.93178195],
                            [39.20432943, -7.93195162],
                            [39.20447361, -7.93208733],
                            [39.20469412, -7.93218913],
                            [39.20486374, -7.93210431],
                            [39.20494855, -7.93203645],
                            [39.20506729, -7.93204492],
                            [39.20524539, -7.93221459],
                            [39.20544045, -7.93245211],
                            [39.20566095, -7.93259632],
                            [39.20598324, -7.9326642],
                            [39.20672957, -7.93268116],
                            [39.20727236, -7.93267267],
                            [39.20773034, -7.93257089],
                            [39.20804414, -7.93241819],
                            [39.20850212, -7.93228246],
                            [39.20873111, -7.93205342],
                            [39.20893465, -7.93176501],
                            [39.20929934, -7.93151051],
                            [39.20961314, -7.93128149],
                            [39.20982517, -7.93125601],
                            [39.210122, -7.93110333],
                            [39.21041037, -7.93078948],
                            [39.21094467, -7.93053498],
                            [39.21139417, -7.93039925],
                            [39.2119624, -7.93026352],
                            [39.21249671, -7.93026353],
                            [39.21303103, -7.93025506],
                            [39.21348051, -7.93033989],
                            [39.21396393, -7.93045016],
                            [39.21444735, -7.93068769],
                            [39.21513432, -7.93095068],
                            [39.21580432, -7.93123061],
                            [39.21661002, -7.93147661],
                            [39.21705952, -7.93172263],
                            [39.21733938, -7.93190924],
                            [39.21763623, -7.93208739],
                            [39.21790762, -7.93232492],
                            [39.21811116, -7.93266425],
                            [39.21834863, -7.93310536],
                            [39.21852673, -7.93351255],
                            [39.21878118, -7.93406393],
                            [39.21905255, -7.93450505],
                            [39.21925609, -7.934785],
                            [39.21943419, -7.93483589],
                            [39.21980737, -7.93475955],
                            [39.22015509, -7.93438629],
                            [39.22062156, -7.93380097],
                            [39.22093536, -7.93335986],
                            [39.22124916, -7.93294419],
                            [39.22144422, -7.93272363],
                            [39.22166472, -7.93262183],
                            [39.22199549, -7.93263032],
                            [39.22229232, -7.93263032],
                            [39.22262309, -7.93256247],
                            [39.22290296, -7.93246067],
                            [39.2230217, -7.93228252],
                            [39.22297931, -7.93211287],
                            [39.22277574, -7.93196017],
                            [39.22257221, -7.93175658],
                            [39.22246195, -7.93156995],
                            [39.2223517, -7.93131548],
                            [39.22224144, -7.93103552],
                            [39.22213968, -7.93058594],
                            [39.22214815, -7.93014481],
                            [39.22225841, -7.9297631],
                            [39.22248741, -7.92944922],
                            [39.22251284, -7.9292032],
                            [39.2224026, -7.92897417],
                            [39.22234323, -7.92859243],
                            [39.22238563, -7.92831249],
                            [39.2224959, -7.92803255],
                            [39.22255524, -7.92778656],
                            [39.22269944, -7.92734543],
                            [39.22284362, -7.92694674],
                            [39.22307262, -7.92653107],
                            [39.22331008, -7.92617477],
                            [39.22353058, -7.92582698],
                            [39.22375109, -7.92549614],
                            [39.22422605, -7.92474965],
                            [39.22458225, -7.92427461],
                            [39.22491301, -7.92392681],
                            [39.22519289, -7.92371472],
                            [39.22559998, -7.92346873],
                            [39.22586289, -7.92336693],
                            [39.22618518, -7.92329907],
                            [39.22660922, -7.92329059],
                            [39.22696544, -7.92329059],
                            [39.22723682, -7.92332452],
                            [39.2275167, -7.92344328],
                            [39.22770329, -7.92365536],
                            [39.2278305, -7.92402012],
                            [39.22788139, -7.92452911],
                            [39.22794072, -7.92521623],
                            [39.22796619, -7.92586093],
                            [39.22793227, -7.92643777],
                            [39.22788138, -7.92698917],
                            [39.22780503, -7.9274812],
                            [39.22773719, -7.9279732],
                            [39.22772022, -7.92853307],
                            [39.2277287, -7.92884694],
                            [39.227593, -7.93000062],
                            [39.22744034, -7.93100162],
                            [39.22733856, -7.93171419],
                            [39.22720286, -7.9324013],
                            [39.22713502, -7.93289331],
                            [39.22712653, -7.93327505],
                            [39.22716894, -7.93352954],
                            [39.22733009, -7.93376707],
                            [39.22768627, -7.93407246],
                            [39.22808488, -7.93434391],
                            [39.22832236, -7.93453901],
                            [39.22857678, -7.93481896],
                            [39.22878034, -7.93503101],
                            [39.22889907, -7.93537036],
                            [39.2289669, -7.93580299],
                            [39.22900931, -7.93625258],
                            [39.2289669, -7.93671916],
                            [39.22887362, -7.9370924],
                            [39.22859374, -7.93744019],
                            [39.22838172, -7.93763531],
                            [39.22802551, -7.93783042],
                            [39.22762689, -7.93795766],
                            [39.22726221, -7.93810186],
                            [39.22690601, -7.93833939],
                            [39.22665156, -7.93866174],
                            [39.22643954, -7.93900954],
                            [39.22638016, -7.9394337],
                            [39.22636321, -7.93982391],
                            [39.2264989, -7.94057041],
                            [39.22670244, -7.94121512],
                            [39.22682965, -7.94174106],
                            [39.22688054, -7.94214826],
                            [39.22686358, -7.94264026],
                            [39.22678724, -7.94302199],
                            [39.2267533, -7.9434631],
                            [39.22682964, -7.94376849],
                            [39.22702471, -7.94420962],
                            [39.2272537, -7.94460832],
                            [39.22737243, -7.94487129],
                            [39.22755901, -7.94515122],
                            [39.22800004, -7.94547357],
                            [39.22820358, -7.94562628],
                            [39.22833928, -7.94569413],
                            [39.22859371, -7.94564323],
                            [39.22889054, -7.94538875],
                            [39.22916194, -7.94503247],
                            [39.22938244, -7.94465921],
                            [39.22950117, -7.94435383],
                            [39.22952663, -7.94410783],
                            [39.22945877, -7.94373457],
                            [39.22937397, -7.94326801],
                            [39.22936548, -7.94275901],
                            [39.22939094, -7.9423773],
                            [39.22949272, -7.9416732],
                            [39.22956904, -7.94118968],
                            [39.22982347, -7.94069766],
                            [39.2301712, -7.94029048],
                            [39.23064616, -7.9399342],
                            [39.23091754, -7.93977303],
                            [39.23108716, -7.93973061],
                            [39.23125678, -7.93966276],
                            [39.231384, -7.93954399],
                            [39.23148577, -7.93933192],
                            [39.23155362, -7.93904349],
                            [39.23174869, -7.93875508],
                            [39.23210489, -7.93842424],
                            [39.23261377, -7.93806795],
                            [39.23339402, -7.93766077],
                            [39.23379263, -7.93743176],
                            [39.23405552, -7.93723663],
                            [39.2341743, -7.93708393],
                            [39.23418065, -7.93667039],
                            [39.23420185, -7.93633106],
                            [39.23432907, -7.93591753],
                            [39.23447748, -7.93568425],
                            [39.23451989, -7.93537674],
                            [39.23449869, -7.93504802],
                            [39.23435027, -7.93465569],
                            [39.23407465, -7.93415731],
                            [39.23378841, -7.9338392],
                            [39.23353397, -7.93372256],
                            [39.23308872, -7.93356349],
                            [39.23264346, -7.93353169],
                            [39.23225121, -7.93342565],
                            [39.23193316, -7.93316055],
                            [39.23162573, -7.93278942],
                            [39.23131829, -7.93245009],
                            [39.23102146, -7.93219561],
                            [39.23080944, -7.93193052],
                            [39.23061862, -7.93165482],
                            [39.230502, -7.93117765],
                            [39.2304808, -7.93053082],
                            [39.2305126, -7.93001124],
                            [39.23061863, -7.92960829],
                            [39.23087306, -7.92918415],
                            [39.2312547, -7.92857975],
                            [39.23174237, -7.92796473],
                            [39.23230426, -7.92728609],
                            [39.23306755, -7.92644841],
                            [39.23395806, -7.92564253],
                            [39.2342973, -7.92525019],
                            [39.23463655, -7.92476241],
                            [39.23510301, -7.92425344],
                            [39.23536805, -7.92374446],
                            [39.23563308, -7.92323549],
                            [39.23583452, -7.92269469],
                            [39.23591933, -7.92228114],
                            [39.23592992, -7.92159191],
                            [39.23588752, -7.92088146],
                            [39.23588752, -7.92051033],
                            [39.23596174, -7.92008617],
                            [39.23614195, -7.91969385],
                            [39.23640699, -7.91923789],
                            [39.23671444, -7.91867588],
                            [39.23705367, -7.9181245],
                            [39.2372127, -7.91791243],
                            [39.23741413, -7.91780638],
                            [39.23768978, -7.91772155],
                            [39.23806081, -7.91764734],
                            [39.23844247, -7.91762613],
                            [39.23887712, -7.9175201],
                            [39.23939659, -7.91728681],
                            [39.23981004, -7.91720198],
                            [39.2403507, -7.91708535],
                            [39.24131542, -7.91697931],
                            [39.2418349, -7.91698992],
                            [39.24242856, -7.91701113],
                            [39.24305403, -7.91701113],
                            [39.2435523, -7.91688389],
                            [39.24415659, -7.91679907],
                            [39.24479265, -7.91682028],
                            [39.24556656, -7.91683088],
                            [39.24622383, -7.91689448],
                            [39.24683871, -7.91702174],
                            [39.24742178, -7.91719142],
                            [39.24835852, -7.91776989],
                            [39.24892678, -7.91800308],
                            [39.24966988, -7.91830914],
                            [39.25045669, -7.91877551],
                            [39.25127264, -7.9194459],
                            [39.2517389, -7.91991227],
                            [39.25237999, -7.9206701],
                            [39.25288995, -7.92126765],
                            [39.25310852, -7.92174859],
                            [39.25332709, -7.92214208],
                            [39.25350193, -7.92227323],
                            [39.25374964, -7.9220255],
                            [39.25405561, -7.92166115],
                            [39.25423046, -7.92138424],
                            [39.25443446, -7.92128222],
                            [39.25471129, -7.92129682],
                            [39.25522127, -7.92122394],
                            [39.2554544, -7.92109277],
                            [39.25592065, -7.92083044],
                            [39.2559548, -7.92058603],
                            [39.25598482, -7.92059726],
                            [39.25593704, -7.92054606],
                            [39.25585519, -7.92031407],
                            [39.25550045, -7.91983643],
                            [39.254791, -7.91938609],
                            [39.25408153, -7.91882658],
                            [39.25356308, -7.91821248],
                            [39.25319471, -7.91765295],
                            [39.25303098, -7.91728449],
                            [39.25301734, -7.91701157],
                            [39.25290819, -7.91680687],
                            [39.25263533, -7.91671134],
                            [39.25236245, -7.91679321],
                            [39.25214416, -7.91679321],
                            [39.25191222, -7.91657487],
                            [39.25177579, -7.91638382],
                            [39.2516257, -7.91632923],
                            [39.25142105, -7.91650664],
                            [39.25138011, -7.91679321],
                            [39.2511891, -7.91699791],
                            [39.25094352, -7.91703884],
                            [39.25017949, -7.91698427],
                            [39.24985203, -7.91683414],
                            [39.24966102, -7.91656122],
                            [39.24951095, -7.91609722],
                            [39.24933359, -7.91576971],
                            [39.24915622, -7.91571512],
                            [39.24903343, -7.91593347],
                            [39.24884242, -7.91617911],
                            [39.24865141, -7.9162064],
                            [39.24848768, -7.91613817],
                            [39.24833761, -7.91591981],
                            [39.24833761, -7.91578335],
                            [39.24822846, -7.91556501],
                            [39.24816025, -7.91521019],
                            [39.24809203, -7.91480079],
                            [39.24801016, -7.91441869],
                            [39.24783281, -7.91422764],
                            [39.24761451, -7.9141594],
                            [39.24738256, -7.91425492],
                            [39.24717791, -7.91447327],
                            [39.24700054, -7.91463702],
                            [39.2468876, -7.9147058],
                            [39.24691872, -7.91475176],
                            [39.2469053, -7.91475345],
                            [39.24667765, -7.91495838],
                            [39.24635893, -7.91515191],
                            [39.24572151, -7.91533407],
                            [39.24538004, -7.91549345],
                            [39.24477678, -7.915687],
                            [39.24426457, -7.91592609],
                            [39.24372959, -7.91605132],
                            [39.24333122, -7.91606271],
                            [39.24302388, -7.91597163],
                            [39.24280763, -7.91574391],
                            [39.24272795, -7.91543654],
                            [39.24269381, -7.91502667],
                            [39.24259136, -7.91471927],
                            [39.24236371, -7.91450296],
                            [39.24210191, -7.91444603],
                            [39.24169215, -7.91441188],
                            [39.24139621, -7.9143777],
                            [39.24108889, -7.91428663],
                            [39.24079295, -7.91419555],
                            [39.24049701, -7.91392231],
                            [39.24032627, -7.91363771],
                            [39.24007586, -7.91348968],
                            [39.23963194, -7.91348968],
                            [39.23906282, -7.91360353],
                            [39.23876687, -7.91376292],
                            [39.23855062, -7.91395647],
                            [39.23823191, -7.91399061],
                            [39.23784489, -7.91402477],
                            [39.23766278, -7.91389953],
                            [39.23751481, -7.91383122],
                            [39.2373896, -7.91359213],
                            [39.23748066, -7.91325058],
                            [39.23756035, -7.91293182],
                            [39.23757172, -7.91259024],
                            [39.237401, -7.91239672],
                            [39.23723025, -7.9124081],
                            [39.23715059, -7.9124081],
                            [39.23687741, -7.9124081],
                            [39.2366839, -7.9122601],
                            [39.23645627, -7.91205517],
                            [39.23619447, -7.91188439],
                            [39.23592129, -7.91191855],
                            [39.23571639, -7.91212347],
                            [39.23569363, -7.91266995],
                            [39.23562533, -7.91317089],
                            [39.23542045, -7.9135352],
                            [39.2351245, -7.91388814],
                            [39.23488548, -7.91410445],
                            [39.23452124, -7.91422969],
                            [39.23412285, -7.91422969],
                            [39.23387244, -7.9142866],
                            [39.23372446, -7.91440045],
                            [39.23375862, -7.91462815],
                            [39.23390659, -7.91494693],
                            [39.2342253, -7.91508355],
                            [39.23458953, -7.91526571],
                            [39.23477165, -7.91544789],
                            [39.23480579, -7.91572111],
                            [39.23476026, -7.91599436],
                            [39.2346123, -7.91617652],
                            [39.23432773, -7.91629037],
                            [39.23419114, -7.91651805],
                            [39.23417976, -7.91674576],
                            [39.23431635, -7.917019],
                            [39.23445294, -7.91714423],
                            [39.23450984, -7.91741747],
                            [39.2345326, -7.91778179],
                            [39.23445293, -7.91806642],
                            [39.23423666, -7.91833965],
                            [39.23392936, -7.91857874],
                            [39.23358787, -7.91874951],
                            [39.23329191, -7.91873813],
                            [39.23303013, -7.91859012],
                            [39.23270003, -7.91838519],
                            [39.23232442, -7.91820303],
                            [39.2318919, -7.91802087],
                            [39.23167562, -7.91803226],
                            [39.23140245, -7.91813471],
                            [39.23124309, -7.91828272],
                            [39.23119756, -7.91851042],
                            [39.23114064, -7.91877227],
                            [39.23112926, -7.91910244],
                            [39.23089023, -7.91947813],
                            [39.23069673, -7.91983107],
                            [39.23041216, -7.92007016],
                            [39.23016175, -7.92022955],
                            [39.22986581, -7.92029786],
                            [39.22951295, -7.92037754],
                            [39.22914871, -7.92026369],
                            [39.22880724, -7.92011568],
                            [39.2285796, -7.91989937],
                            [39.22847716, -7.91950089],
                            [39.22853407, -7.91896581],
                            [39.22868205, -7.9183624],
                            [39.22863652, -7.917907],
                            [39.22846578, -7.91757683],
                            [39.22831782, -7.91721251],
                            [39.22822677, -7.91633588],
                            [39.22820399, -7.91588048],
                            [39.22814709, -7.915482],
                            [39.2283292, -7.91524291],
                            [39.22854549, -7.91522014],
                            [39.22890973, -7.91534539],
                            [39.22905768, -7.91520877],
                            [39.22908044, -7.9149583],
                            [39.22909183, -7.91475337],
                            [39.22903491, -7.91429798],
                            [39.22879589, -7.91407028],
                            [39.22847719, -7.91388812],
                            [39.2284089, -7.91352378],
                            [39.22839751, -7.91310255],
                            [39.22823816, -7.91288623],
                            [39.22796498, -7.91260161],
                            [39.22778286, -7.91232837],
                            [39.22765767, -7.91189574],
                            [39.2277601, -7.91150866],
                            [39.22819265, -7.9110077],
                            [39.22835199, -7.91078002],
                            [39.22834061, -7.91060924],
                            [39.2282837, -7.91035877],
                            [39.22811297, -7.91015384],
                            [39.22791946, -7.90989198],
                            [39.22788532, -7.90944798],
                            [39.22811297, -7.90925443],
                            [39.22868209, -7.90887872],
                            [39.22872762, -7.90855994],
                            [39.22845445, -7.90819562],
                            [39.22812435, -7.90801346],
                            [39.22756663, -7.90792238],
                            [39.22698612, -7.9076833],
                            [39.22659912, -7.90762637],
                            [39.22618935, -7.9075353],
                            [39.22592755, -7.9075353],
                            [39.22583649, -7.90754668],
                            [39.22556332, -7.90762636],
                            [39.2253812, -7.90781991],
                            [39.22524461, -7.90811592],
                            [39.22511939, -7.908355],
                            [39.22508525, -7.90860546],
                            [39.22507386, -7.90878762],
                            [39.22490313, -7.90894701],
                            [39.22467549, -7.90896978],
                            [39.22439092, -7.9088787],
                            [39.22417466, -7.908651],
                            [39.22401531, -7.90835499],
                            [39.22394702, -7.90811592],
                            [39.22401531, -7.90788823],
                            [39.22428849, -7.90771744],
                            [39.22447061, -7.90746697],
                            [39.22444785, -7.90711403],
                            [39.22442508, -7.90678387],
                            [39.22455028, -7.9064537],
                            [39.22481209, -7.90624878],
                            [39.22501697, -7.90603247],
                            [39.22508526, -7.90571369],
                            [39.22511941, -7.90530384],
                            [39.22499421, -7.90509889],
                            [39.22488039, -7.90495089],
                            [39.22484623, -7.9046549],
                            [39.22491453, -7.90430195],
                            [39.22510804, -7.90411979],
                            [39.22535845, -7.90385794],
                            [39.2255178, -7.90358469],
                            [39.22568855, -7.90335701],
                            [39.22582514, -7.90335701],
                            [39.22603003, -7.90336839],
                            [39.22620075, -7.9032887],
                            [39.22622351, -7.90302684],
                            [39.22631457, -7.90285606],
                            [39.22649669, -7.9029016],
                            [39.2266902, -7.90308377],
                            [39.22683816, -7.903061],
                            [39.22692922, -7.90279914],
                            [39.22662191, -7.90235512],
                            [39.22643979, -7.90210465],
                            [39.22638287, -7.90166065],
                            [39.22634873, -7.90107999],
                            [39.22624629, -7.90073846],
                            [39.2260528, -7.90048799],
                            [39.22589344, -7.90031721],
                            [39.22583652, -7.89999845],
                            [39.22569992, -7.89965688],
                            [39.22556335, -7.89958857],
                            [39.22530156, -7.89958857],
                            [39.22502837, -7.89952026],
                            [39.22489178, -7.89937226],
                            [39.22485764, -7.8991901],
                            [39.22502837, -7.89901932],
                            [39.22524465, -7.89884856],
                            [39.22517635, -7.89868915],
                            [39.22499424, -7.89854116],
                            [39.22481212, -7.8983476],
                            [39.22468691, -7.89811991],
                            [39.22464138, -7.89786944],
                            [39.2246983, -7.89761897],
                            [39.22488042, -7.89749374],
                            [39.225017, -7.89753928],
                            [39.22510806, -7.89771005],
                            [39.22527879, -7.8978239],
                            [39.22550645, -7.8978239],
                            [39.22571134, -7.89761897],
                            [39.22575687, -7.89733436],
                            [39.22560889, -7.89711805],
                            [39.22555199, -7.89681065],
                            [39.22552922, -7.89656017],
                            [39.22556336, -7.89634386],
                            [39.22568858, -7.89610477],
                            [39.22579101, -7.89588846],
                            [39.22596176, -7.8956266],
                            [39.22591623, -7.89539891],
                            [39.22589581, -7.89497142],
                            [39.2266754, -7.89454255],
                            [39.22827459, -7.89391323],
                            [39.22858477, -7.89372439],
                            [39.22878837, -7.89387901],
                            [39.22895691, -7.89399942],
                            [39.22898097, -7.89425227],
                            [39.22881245, -7.89439676],
                            [39.22858372, -7.89445696],
                            [39.22827073, -7.89443288],
                            [39.22798181, -7.89445696],
                            [39.22780125, -7.8945894],
                            [39.22775311, -7.89492654],
                            [39.22770495, -7.89521552],
                            [39.22757252, -7.89554061],
                            [39.22723547, -7.8958898],
                            [39.22711508, -7.89609448],
                            [39.22709101, -7.8964557],
                            [39.22713916, -7.89692529],
                            [39.22727157, -7.89737079],
                            [39.22739196, -7.89806914],
                            [39.22740398, -7.89867118],
                            [39.22764474, -7.89938157],
                            [39.22793365, -7.8997428],
                            [39.22831886, -7.90001973],
                            [39.22870408, -7.90026054],
                            [39.22890871, -7.90063381],
                            [39.2290652, -7.90100707],
                            [39.22916151, -7.90150072],
                            [39.22936616, -7.90168134],
                            [39.22959487, -7.90181378],
                            [39.22983564, -7.9017897],
                            [39.22994398, -7.90177767],
                            [39.23028103, -7.90176564],
                            [39.23053383, -7.90176564],
                            [39.23066625, -7.90194623],
                            [39.23067829, -7.90227134],
                            [39.23073848, -7.90262052],
                            [39.23088292, -7.90286134],
                            [39.23109962, -7.90298174],
                            [39.23132833, -7.90298174],
                            [39.23158113, -7.90296969],
                            [39.23206264, -7.90287338],
                            [39.23248397, -7.90287338],
                            [39.23273677, -7.90287338],
                            [39.23291734, -7.90301787],
                            [39.23308586, -7.90321051],
                            [39.23326643, -7.90341522],
                            [39.23350719, -7.90355972],
                            [39.23375999, -7.90351154],
                            [39.23383221, -7.90337909],
                            [39.23384426, -7.90324665],
                            [39.23385629, -7.90301787],
                            [39.23391647, -7.90283726],
                            [39.23401278, -7.90275298],
                            [39.2341452, -7.90263256],
                            [39.23419335, -7.90243992],
                            [39.23404889, -7.90228339],
                            [39.23394055, -7.90212686],
                            [39.23380814, -7.90206666],
                            [39.23349515, -7.90209074],
                            [39.23323032, -7.90206666],
                            [39.23306179, -7.90198238],
                            [39.23294141, -7.90176565],
                            [39.23273678, -7.90146462],
                            [39.23238767, -7.90109136],
                            [39.23227933, -7.9008385],
                            [39.23227933, -7.90068198],
                            [39.23243582, -7.90062178],
                            [39.23266455, -7.90062178],
                            [39.2329053, -7.90063383],
                            [39.23315811, -7.90064585],
                            [39.2333146, -7.90048933],
                            [39.23323032, -7.90029669],
                            [39.23315811, -7.90012811],
                            [39.23302569, -7.8998873],
                            [39.23285715, -7.89965854],
                            [39.232809, -7.89929731],
                            [39.23278494, -7.89900834],
                            [39.23290531, -7.89877956],
                            [39.23301365, -7.89851467],
                            [39.23312199, -7.89833406],
                            [39.23310998, -7.89812938],
                            [39.23304978, -7.89793672],
                            [39.23291736, -7.89781632],
                            [39.23292939, -7.89759959],
                            [39.23295347, -7.89740693],
                            [39.23304978, -7.89722632],
                            [39.23314607, -7.89708184],
                            [39.23310998, -7.89687715],
                            [39.23303774, -7.89664837],
                            [39.23284514, -7.89649182],
                            [39.23262846, -7.89637144],
                            [39.23239973, -7.89622695],
                            [39.23223119, -7.89604633],
                            [39.23212286, -7.89578144],
                            [39.23199044, -7.89558879],
                            [39.23183395, -7.89531187],
                            [39.23179784, -7.89505901],
                            [39.23182192, -7.89490248],
                            [39.23199044, -7.89474595],
                            [39.23217101, -7.89466167],
                            [39.23231446, -7.89463093],
                            [39.23229024, -7.89461155],
                            [39.23239577, -7.89457822],
                            [39.232556, -7.89446167],
                            [39.232556, -7.8941557],
                            [39.2324249, -7.89380604],
                            [39.2324249, -7.89342724],
                            [39.2324249, -7.89315041],
                            [39.23257057, -7.89290274],
                            [39.23270167, -7.89269876],
                            [39.23270167, -7.89245107],
                            [39.23255601, -7.89227624],
                            [39.23241034, -7.89214512],
                            [39.23223555, -7.89214512],
                            [39.2320025, -7.89237823],
                            [39.23171117, -7.89250936],
                            [39.23134701, -7.89246565],
                            [39.23099743, -7.89240737],
                            [39.23076438, -7.89229082],
                            [39.23028369, -7.89226167],
                            [39.2300215, -7.89227623],
                            [39.22986127, -7.89239279],
                            [39.22961364, -7.89261132],
                            [39.22941251, -7.89271784],
                            [39.22874613, -7.89244252],
                            [39.22873849, -7.89245335],
                            [39.22855389, -7.89257645],
                            [39.2283898, -7.89267902],
                            [39.22823598, -7.89276108],
                            [39.22804111, -7.89283289],
                            [39.22789754, -7.89283289],
                            [39.22765141, -7.89275083],
                            [39.22745654, -7.89275083],
                            [39.22727195, -7.89276108],
                            [39.22717964, -7.89283289],
                            [39.22701555, -7.89285341],
                            [39.22680019, -7.89280211],
                            [39.22662584, -7.89267901],
                            [39.22644123, -7.89263799],
                            [39.22622587, -7.89263799],
                            [39.2260823, -7.89254566],
                            [39.22603102, -7.89246359],
                            [39.22591821, -7.89240205],
                            [39.22574386, -7.89235076],
                            [39.22567208, -7.89220715],
                            [39.22555926, -7.89211482],
                            [39.2254362, -7.89208405],
                            [39.22530288, -7.8921456],
                            [39.22513879, -7.89234051],
                            [39.22496443, -7.89251488],
                            [39.2248106, -7.89260721],
                            [39.22465677, -7.89261746],
                            [39.22435936, -7.89254565],
                            [39.22411322, -7.89250463],
                            [39.22389785, -7.8925354],
                            [39.22367224, -7.89264823],
                            [39.22343635, -7.89264823],
                            [39.22331328, -7.89252514],
                            [39.22320047, -7.8923405],
                            [39.22322099, -7.89210456],
                            [39.22328252, -7.89201223],
                            [39.22348764, -7.89191992],
                            [39.22378505, -7.89174553],
                            [39.22394914, -7.89151986],
                            [39.22404144, -7.89132497],
                            [39.22411322, -7.8911711],
                            [39.22427733, -7.89096593],
                            [39.22444142, -7.89075052],
                            [39.2246037, -7.89063313],
                            [39.22481128, -7.89073124],
                            [39.22482662, -7.89063914],
                            [39.22481226, -7.89038066],
                            [39.22471177, -7.8901796],
                            [39.22458254, -7.89005036],
                            [39.22446768, -7.8899211],
                            [39.22442461, -7.88960517],
                            [39.22442461, -7.88933232],
                            [39.22441025, -7.8891169],
                            [39.22428104, -7.88898766],
                            [39.2239939, -7.88872916],
                            [39.22390774, -7.88857119],
                            [39.22387903, -7.88837014],
                            [39.22389339, -7.88814036],
                            [39.22390775, -7.88781007],
                            [39.22389339, -7.88753722],
                            [39.22379289, -7.88737925],
                            [39.22363496, -7.88719256],
                            [39.22356317, -7.8868479],
                            [39.22354881, -7.88660376],
                            [39.22367803, -7.88646016],
                            [39.22383597, -7.88646016],
                            [39.22400826, -7.88654633],
                            [39.22418055, -7.88663249],
                            [39.22438154, -7.88673301],
                            [39.22461127, -7.88680482],
                            [39.22495585, -7.88674737],
                            [39.22522865, -7.88663249],
                            [39.22560195, -7.88633092],
                            [39.22587474, -7.88625911],
                            [39.22643468, -7.88637401],
                            [39.2268367, -7.88651762],
                            [39.22731049, -7.88651762],
                            [39.22768379, -7.88644581],
                            [39.22778429, -7.8862304],
                            [39.22764072, -7.88594319],
                            [39.22752586, -7.88581394],
                            [39.22732485, -7.88581394],
                            [39.22712385, -7.88579958],
                            [39.2268367, -7.88565597],
                            [39.22650647, -7.8854262],
                            [39.22630547, -7.88515333],
                            [39.22623368, -7.88486612],
                            [39.22633418, -7.88455019],
                            [39.22653519, -7.88424862],
                            [39.2267362, -7.8840332],
                            [39.22677927, -7.88380343],
                            [39.22675056, -7.88364546],
                            [39.22675056, -7.88350185],
                            [39.22689413, -7.88340132],
                            [39.22712386, -7.88337261],
                            [39.22745408, -7.88332953],
                            [39.22807146, -7.883229],
                            [39.22837296, -7.88309976],
                            [39.22841604, -7.88295615],
                            [39.22828681, -7.8827551],
                            [39.22799967, -7.88256841],
                            [39.22766945, -7.88251096],
                            [39.22731051, -7.88255404],
                            [39.22693721, -7.88274073],
                            [39.22644906, -7.88305666],
                            [39.22607576, -7.88317156],
                            [39.22567374, -7.88315719],
                            [39.22541532, -7.88320028],
                            [39.22524302, -7.88332952],
                            [39.22499894, -7.88364545],
                            [39.22469743, -7.88386087],
                            [39.22423798, -7.88393266],
                            [39.22390776, -7.88381778],
                            [39.22362062, -7.88367417],
                            [39.22346269, -7.88350184],
                            [39.22320425, -7.88350184],
                            [39.22296018, -7.88360236],
                            [39.22285967, -7.8835162],
                            [39.22277353, -7.88321461],
                            [39.22273045, -7.88284125],
                            [39.22260124, -7.88266892],
                            [39.22248637, -7.88259711],
                            [39.22221359, -7.88255402],
                            [39.22198386, -7.88266891],
                            [39.22188336, -7.88288431],
                            [39.22184029, -7.88321461],
                            [39.22182593, -7.88353056],
                            [39.22183856, -7.88375458],
                            [39.2218018, -7.88392921],
                            [39.22165479, -7.88404869],
                            [39.22143425, -7.88412221],
                            [39.2211402, -7.8842325],
                            [39.22091968, -7.88427846],
                            [39.22068076, -7.88446227],
                            [39.22049699, -7.88456337],
                            [39.22034078, -7.88448984],
                            [39.22018456, -7.88448984],
                            [39.22002835, -7.88460014],
                            [39.21985376, -7.88462771],
                            [39.2196608, -7.88459095],
                            [39.21939433, -7.88440712],
                            [39.21922893, -7.88430602],
                            [39.21908191, -7.88431521],
                            [39.2190176, -7.88444389],
                            [39.21902678, -7.8846277],
                            [39.21898083, -7.88484829],
                            [39.21887057, -7.88499533],
                            [39.21869598, -7.88506886],
                            [39.21855814, -7.88507805],
                            [39.21852139, -7.88528945],
                            [39.21839274, -7.88546407],
                            [39.21821815, -7.88552841],
                            [39.21807113, -7.88543651],
                            [39.21796087, -7.88533539],
                            [39.21783223, -7.88516996],
                            [39.21770358, -7.88507805],
                            [39.21757495, -7.88508724],
                            [39.21742792, -7.88512401],
                            [39.21729927, -7.88512401],
                            [39.21717063, -7.88504129],
                            [39.21710631, -7.884931],
                            [39.21706037, -7.88474717],
                            [39.21697768, -7.88459094],
                            [39.21683065, -7.88445307],
                            [39.21667444, -7.88443469],
                            [39.21659174, -7.88454497],
                            [39.21656418, -7.88470122],
                            [39.21655499, -7.88487585],
                            [39.21660092, -7.88505047],
                            [39.2165366, -7.88518834],
                            [39.21641715, -7.88527106],
                            [39.21625175, -7.88524348],
                            [39.21616905, -7.88515157],
                            [39.21609554, -7.88506885],
                            [39.21594853, -7.88503209],
                            [39.21584744, -7.88505047],
                            [39.2157188, -7.88513319],
                            [39.21556259, -7.88519753],
                            [39.21540638, -7.88515157],
                            [39.21529612, -7.88503209],
                            [39.21519504, -7.88495856],
                            [39.21510315, -7.88495856],
                            [39.2149745, -7.88500451],
                            [39.21481829, -7.88496775],
                            [39.21456101, -7.88475636],
                            [39.21439561, -7.88460931],
                            [39.21423941, -7.88446225],
                            [39.21413832, -7.88436115],
                            [39.21411995, -7.88418652],
                            [39.21418427, -7.88406703],
                            [39.21427616, -7.88392918],
                            [39.21431291, -7.88379131],
                            [39.21435886, -7.88367183],
                            [39.21434967, -7.88357993],
                            [39.21426698, -7.88351559],
                            [39.21409239, -7.8835064],
                            [39.21386266, -7.88355234],
                            [39.21362375, -7.88362587],
                            [39.21330215, -7.88375455],
                            [39.21305405, -7.88383727],
                            [39.21287026, -7.88382808],
                            [39.21266812, -7.88371777],
                            [39.21257623, -7.88357073],
                            [39.21246597, -7.8833961],
                            [39.21245678, -7.88319389],
                            [39.21246597, -7.88305604],
                            [39.21249353, -7.88291817],
                            [39.21255786, -7.88273436],
                            [39.21265893, -7.88253215],
                            [39.21270489, -7.8822748],
                            [39.21281515, -7.88213695],
                            [39.21281515, -7.88194394],
                            [39.21280596, -7.88183364],
                            [39.21272326, -7.88180607],
                            [39.2126038, -7.88184283],
                            [39.2123557, -7.88194394],
                            [39.21208922, -7.88206342],
                            [39.21194221, -7.882284],
                            [39.21191464, -7.88251377],
                            [39.21198815, -7.88265163],
                            [39.21206166, -7.88281707],
                            [39.21208003, -7.88301927],
                            [39.2120341, -7.88319389],
                            [39.21200652, -7.88335014],
                            [39.21196977, -7.88352477],
                            [39.21195139, -7.88370858],
                            [39.21202491, -7.8838924],
                            [39.21211679, -7.88404864],
                            [39.2121811, -7.88422327],
                            [39.21216273, -7.88447143],
                            [39.21213516, -7.88463686],
                            [39.21200651, -7.88476554],
                            [39.21186869, -7.88485745],
                            [39.2116941, -7.88502289],
                            [39.2116114, -7.88512398],
                            [39.21165734, -7.88524346],
                            [39.21175841, -7.88529861],
                            [39.211933, -7.88528942],
                            [39.21206165, -7.88527104],
                            [39.21215354, -7.88525265],
                            [39.21228219, -7.88527104],
                            [39.21236489, -7.88537214],
                            [39.21236489, -7.88550081],
                            [39.21239245, -7.8856203],
                            [39.21237542, -7.88573101],
                            [39.21263694, -7.88571089],
                            [39.21332525, -7.8857109],
                            [39.21328607, -7.88581868],
                            [39.21320685, -7.88597716],
                            [39.21310889, -7.88604452],
                            [39.2130484, -7.88608612],
                            [39.21288996, -7.88629411],
                            [39.21264239, -7.88650212],
                            [39.21243443, -7.88657146],
                            [39.21214725, -7.88657146],
                            [39.2119294, -7.88657146],
                            [39.21167192, -7.88651202],
                            [39.21139464, -7.88650211],
                            [39.21111737, -7.88658136],
                            [39.21098862, -7.88676955],
                            [39.21096883, -7.88701718],
                            [39.21101834, -7.8872846],
                            [39.21099853, -7.88750252],
                            [39.21103813, -7.88764118],
                            [39.21115697, -7.88774023],
                            [39.21106785, -7.88777985],
                            [39.2108698, -7.88770061],
                            [39.21081038, -7.88777985],
                            [39.21080047, -7.88790862],
                            [39.21069154, -7.88799776],
                            [39.2105232, -7.88799776],
                            [39.21045387, -7.88819586],
                            [39.21065193, -7.88844349],
                            [39.21084998, -7.88862179],
                            [39.21084007, -7.88880998],
                            [39.21060241, -7.88902788],
                            [39.21009737, -7.88909721],
                            [39.20983, -7.8892557],
                            [39.20963195, -7.88945379],
                            [39.20957253, -7.88970142],
                            [39.20946359, -7.88990943],
                            [39.20917641, -7.89010752],
                            [39.20887933, -7.89019666],
                            [39.20866147, -7.89031551],
                            [39.20866146, -7.89057305],
                            [39.20872088, -7.89071173],
                            [39.20888924, -7.89083058],
                            [39.20921602, -7.89091972],
                            [39.20935465, -7.8910782],
                            [39.20923583, -7.8912763],
                            [39.20881, -7.89157345],
                            [39.20834458, -7.89194985],
                            [39.2080772, -7.89217765],
                            [39.20771079, -7.89221727],
                            [39.20758206, -7.89206869],
                            [39.20748304, -7.89182107],
                            [39.20728498, -7.89149421],
                            [39.2069879, -7.89125649],
                            [39.20668092, -7.89112772],
                            [39.20634423, -7.89095934],
                            [39.20599763, -7.89081077],
                            [39.20545298, -7.89063246],
                            [39.20488852, -7.89061266],
                            [39.20435378, -7.89071171],
                            [39.20403688, -7.89077113],
                            [39.20374971, -7.89079094],
                            [39.20319515, -7.8909296],
                            [39.20284855, -7.89101874],
                            [39.2026802, -7.89122675],
                            [39.20259108, -7.89148428],
                            [39.20261089, -7.89183096],
                            [39.20253165, -7.89219745],
                            [39.20247225, -7.89240544],
                            [39.20253165, -7.89273231],
                            [39.20242273, -7.89296012],
                            [39.20240292, -7.89321765],
                            [39.20256137, -7.89346528],
                            [39.20273961, -7.89363367],
                            [39.20289805, -7.89368319],
                            [39.20310602, -7.8936931],
                            [39.20320504, -7.89368319],
                            [39.20326446, -7.89382186],
                            [39.20332388, -7.89399025],
                            [39.203413, -7.89420815],
                            [39.20349882, -7.89450421],
                            [39.2037805, -7.89485638],
                            [39.20404457, -7.89515574],
                            [39.20406217, -7.89534943],
                            [39.20385092, -7.89540225],
                            [39.20371008, -7.89534943],
                            [39.20351641, -7.8952966],
                            [39.20337558, -7.89538465],
                            [39.20316432, -7.89533181],
                            [39.20281222, -7.8952966],
                            [39.20267138, -7.89552552],
                            [39.20279461, -7.89578965],
                            [39.20314672, -7.89600095],
                            [39.20326995, -7.89622987],
                            [39.2030763, -7.89644117],
                            [39.20281222, -7.89654683],
                            [39.20251293, -7.89675814],
                            [39.20231928, -7.89712792],
                            [39.20233689, -7.89756815],
                            [39.20244251, -7.89806119],
                            [39.20281221, -7.8984838],
                            [39.20311149, -7.8984838],
                            [39.20337556, -7.89846619],
                            [39.20379809, -7.8982725],
                            [39.20402694, -7.89811402],
                            [39.20430864, -7.89799076],
                            [39.20476635, -7.89795554],
                            [39.20518887, -7.89818446],
                            [39.20538253, -7.89848381],
                            [39.2053297, -7.89873034],
                            [39.20506563, -7.89878316],
                            [39.20444947, -7.89874794],
                            [39.20409736, -7.89888881],
                            [39.20402694, -7.89911772],
                            [39.20404455, -7.89938187],
                            [39.2038861, -7.89955794],
                            [39.20367484, -7.89948751],
                            [39.20353401, -7.89941707],
                            [39.20330514, -7.89952273],
                            [39.2031643, -7.89982208],
                            [39.20318191, -7.90017426],
                            [39.20305868, -7.90054405],
                            [39.20305867, -7.90059878],
                            [39.20253981, -7.89967877],
                            [39.20173844, -7.8990442],
                            [39.20175592, -7.89858946],
                            [39.20135101, -7.89758574],
                            [39.20117497, -7.89693421],
                            [39.20105173, -7.8961066],
                            [39.20108695, -7.89526137],
                            [39.20108695, -7.89473311],
                            [39.20108695, -7.89425767],
                            [39.20115737, -7.89387028],
                            [39.20135102, -7.8935005],
                            [39.20135103, -7.89306027],
                            [39.20131583, -7.89254962],
                            [39.20106935, -7.89196852],
                            [39.20089332, -7.89154591],
                            [39.20096374, -7.89107046],
                            [39.20115739, -7.89061265],
                            [39.20119261, -7.89019002],
                            [39.20119261, -7.88983785],
                            [39.20135104, -7.88964415],
                            [39.20159751, -7.88950329],
                            [39.20191441, -7.88945046],
                            [39.2022489, -7.88939763],
                            [39.20254818, -7.88915111],
                            [39.20275944, -7.8889222],
                            [39.20309393, -7.88872851],
                            [39.20330519, -7.88864046],
                            [39.20356927, -7.88872851],
                            [39.20381573, -7.88878133],
                            [39.20395657, -7.88858763],
                            [39.20409741, -7.88830589],
                            [39.20416783, -7.88790089],
                            [39.20420305, -7.88749589],
                            [39.20432628, -7.88723175],
                            [39.20453753, -7.88717893],
                            [39.20469598, -7.88730219],
                            [39.20467837, -7.88765436],
                            [39.20469598, -7.88812981],
                            [39.20483681, -7.88848198],
                            [39.20504807, -7.88860524],
                            [39.20534735, -7.88857003],
                            [39.20564664, -7.88842917],
                            [39.20610437, -7.88821786],
                            [39.20642125, -7.88797133],
                            [39.20661492, -7.88777764],
                            [39.20680857, -7.88770721],
                            [39.20709024, -7.88763677],
                            [39.20735433, -7.88747829],
                            [39.20747756, -7.8873022],
                            [39.20770642, -7.88723176],
                            [39.20807613, -7.88705569],
                            [39.20828739, -7.88680916],
                            [39.2083402, -7.88652742],
                            [39.2083402, -7.88622807],
                            [39.20804092, -7.88585828],
                            [39.20763601, -7.88557653],
                            [39.2072487, -7.8855061],
                            [39.20705505, -7.88533],
                            [39.20649168, -7.884925],
                            [39.20587552, -7.88469608],
                            [39.20541779, -7.88448478],
                            [39.20525934, -7.88430868],
                            [39.20540018, -7.8841326],
                            [39.20559385, -7.88404455],
                            [39.20559385, -7.88386847],
                            [39.2054354, -7.88360433],
                            [39.20531217, -7.88344585],
                            [39.20538259, -7.88316411],
                            [39.20554104, -7.88297042],
                            [39.20555864, -7.88261824],
                            [39.20553856, -7.88256689],
                            [39.20619409, -7.88342294],
                            [39.20769761, -7.88455084],
                            [39.20775126, -7.88451804],
                            [39.20790166, -7.88443342],
                            [39.20810848, -7.88437702],
                            [39.20824948, -7.88432999],
                            [39.20840928, -7.88440523],
                            [39.20861609, -7.88451805],
                            [39.2088135, -7.88452745],
                            [39.20920831, -7.88433941],
                            [39.20953733, -7.88406673],
                            [39.20981934, -7.88371886],
                            [39.21019536, -7.88338036],
                            [39.21051497, -7.88296666],
                            [39.21079698, -7.88254355],
                            [39.2112012, -7.88178195],
                            [39.21149261, -7.88113318],
                            [39.21174642, -7.88066306],
                            [39.21194382, -7.88028696],
                            [39.21216003, -7.87993907],
                            [39.21239504, -7.87920568],
                            [39.21249844, -7.87880139],
                            [39.21257366, -7.87850051],
                            [39.21261125, -7.87829366],
                            [39.21266766, -7.87806799],
                            [39.21279926, -7.87803978],
                            [39.21300606, -7.87819023],
                            [39.21312828, -7.87829366],
                            [39.21313767, -7.87858513],
                            [39.21303426, -7.87880139],
                            [39.21294027, -7.87910226],
                            [39.21284625, -7.87927151],
                            [39.21279925, -7.87948777],
                            [39.21294965, -7.87948777],
                            [39.21325047, -7.87934673],
                            [39.21349488, -7.87913988],
                            [39.21354188, -7.87891421],
                            [39.21353248, -7.87868856],
                            [39.21356068, -7.87841589],
                            [39.21362649, -7.87818083],
                            [39.2138333, -7.87793636],
                            [39.21404951, -7.87767309],
                            [39.21414351, -7.87748504],
                            [39.21415291, -7.87729699],
                            [39.21409651, -7.87707134],
                            [39.21407771, -7.87679867],
                            [39.21416232, -7.87657301],
                            [39.21434092, -7.87636616],
                            [39.21449133, -7.87614991],
                            [39.21458534, -7.87579261],
                            [39.21482974, -7.87541652],
                            [39.21498015, -7.87518145],
                            [39.21515876, -7.87488058],
                            [39.21539377, -7.87452328],
                            [39.21561937, -7.87422241],
                            [39.21601418, -7.87388393],
                            [39.21642781, -7.87355484],
                            [39.21681321, -7.87318815],
                            [39.21701063, -7.87288726],
                            [39.21705763, -7.87274624],
                            [39.21708583, -7.87251116],
                            [39.21700123, -7.8722197],
                            [39.21682263, -7.87190002],
                            [39.21658762, -7.87166496],
                            [39.21635261, -7.87154271],
                            [39.2161364, -7.87159913],
                            [39.21604239, -7.87175897],
                            [39.21583559, -7.87207866],
                            [39.21568519, -7.8722385],
                            [39.21546897, -7.87230431],
                            [39.21528097, -7.8722761],
                            [39.21514936, -7.87219149],
                            [39.21491436, -7.87202224],
                            [39.21463235, -7.87179658],
                            [39.21445374, -7.87168375],
                            [39.21427513, -7.87167434],
                            [39.21413414, -7.87182477],
                            [39.21420934, -7.87206924],
                            [39.21422813, -7.87225729],
                            [39.21414353, -7.87240773],
                            [39.21401192, -7.87237952],
                            [39.21385212, -7.87225729],
                            [39.21374872, -7.87214446],
                            [39.21353251, -7.87206924],
                            [39.21325991, -7.87212565],
                            [39.2129967, -7.87224788],
                            [39.21280869, -7.87236071],
                            [39.21261127, -7.87245474],
                            [39.21241388, -7.87251114],
                            [39.21225407, -7.87264279],
                            [39.21217887, -7.87284024],
                            [39.21219766, -7.87305649],
                            [39.21224466, -7.87334797],
                            [39.21224466, -7.87372407],
                            [39.21231047, -7.87423179],
                            [39.21222586, -7.87471132],
                            [39.21219766, -7.8750122],
                            [39.21213184, -7.87523785],
                            [39.21202844, -7.87531307],
                            [39.21169944, -7.87535068],
                            [39.21140803, -7.87524726],
                            [39.21119182, -7.87510621],
                            [39.21100381, -7.87493698],
                            [39.2107876, -7.87475832],
                            [39.21057139, -7.8746643],
                            [39.2104492, -7.8746643],
                            [39.21026119, -7.87473013],
                            [39.20998857, -7.87490877],
                            [39.20971597, -7.87500278],
                            [39.20941516, -7.87509681],
                            [39.20919895, -7.87520963],
                            [39.20893574, -7.87542589],
                            [39.20871953, -7.87569856],
                            [39.20852213, -7.87582079],
                            [39.20841872, -7.87575498],
                            [39.20828712, -7.87552931],
                            [39.20825892, -7.87515323],
                            [39.20828712, -7.87478652],
                            [39.20836233, -7.87440102],
                            [39.20838112, -7.87410955],
                            [39.20828713, -7.87400612],
                            [39.20814612, -7.87399671],
                            [39.20788291, -7.87401553],
                            [39.20765731, -7.87405313],
                            [39.2074693, -7.87399671],
                            [39.2073377, -7.87387449],
                            [39.20730009, -7.87371464],
                            [39.2074223, -7.87360181],
                            [39.20772311, -7.87346078],
                            [39.20801452, -7.87343258],
                            [39.20834353, -7.87326333],
                            [39.20847514, -7.87302827],
                            [39.20843753, -7.87273679],
                            [39.20831534, -7.87252053],
                            [39.20810853, -7.8724077],
                            [39.20794873, -7.8724077],
                            [39.20780772, -7.87241711],
                            [39.20767611, -7.87245472],
                            [39.20761971, -7.87255815],
                            [39.2076103, -7.87275559],
                            [39.20765731, -7.87292484],
                            [39.2076949, -7.87308468],
                            [39.20767611, -7.87321632],
                            [39.20760091, -7.87323512],
                            [39.2074129, -7.87324452],
                            [39.2072813, -7.87310348],
                            [39.2072343, -7.87290604],
                            [39.2071591, -7.87249232],
                            [39.20712149, -7.87214443],
                            [39.20706509, -7.87194699],
                            [39.2069711, -7.87170252],
                            [39.20682068, -7.87135462],
                            [39.20660447, -7.87118539],
                            [39.20620966, -7.87098793],
                            [39.20573965, -7.87075287],
                            [39.20548585, -7.87063064],
                            [39.20523203, -7.87040497],
                            [39.20499702, -7.87032035],
                            [39.20481842, -7.87011349],
                            [39.20465862, -7.86991605],
                            [39.20457401, -7.86965278],
                            [39.20456462, -7.86925788],
                            [39.20460222, -7.86883477],
                            [39.20464922, -7.86842105],
                            [39.20470562, -7.86808257],
                            [39.20477143, -7.86775348],
                            [39.20480903, -7.8674432],
                            [39.20480903, -7.86724576],
                            [39.20473383, -7.86694487],
                            [39.20459284, -7.86664399],
                            [39.20447062, -7.86631491],
                            [39.20432963, -7.86602344],
                            [39.20418862, -7.86576017],
                            [39.20413222, -7.86542168],
                            [39.20415102, -7.86504558],
                            [39.20432964, -7.8647071],
                            [39.20459285, -7.86434041],
                            [39.20490305, -7.8639361],
                            [39.20495005, -7.86360701],
                            [39.20501586, -7.86329673],
                            [39.20504407, -7.86300525],
                            [39.20494065, -7.8627984],
                            [39.20467744, -7.86270437],
                            [39.20435785, -7.86269497],
                            [39.20411343, -7.86261976],
                            [39.20383142, -7.8624505],
                            [39.20364342, -7.86233767],
                            [39.20339901, -7.86234708],
                            [39.2031264, -7.86248812],
                            [39.20278799, -7.86273257],
                            [39.20246837, -7.86310867],
                            [39.20212056, -7.86340014],
                            [39.20177274, -7.86366341],
                            [39.20170695, -7.8636164],
                            [39.20156594, -7.86351297],
                            [39.20157534, -7.86336254],
                            [39.20164114, -7.86308046],
                            [39.20185735, -7.8628454],
                            [39.20197016, -7.86265735],
                            [39.20201716, -7.86242229],
                            [39.20213936, -7.8620838],
                            [39.20236498, -7.86189575],
                            [39.20264698, -7.86165129],
                            [39.2027974, -7.86146324],
                            [39.20280679, -7.86135042],
                            [39.2027692, -7.86113416],
                            [39.20270339, -7.86096491],
                            [39.20268652, -7.86072562],
                            [39.20262677, -7.86072827],
                            [39.2025721, -7.86057835],
                            [39.20244159, -7.86035456],
                            [39.20233903, -7.86018672],
                            [39.20223649, -7.86001887],
                            [39.20215259, -7.85990698],
                            [39.20205004, -7.85972047],
                            [39.20203139, -7.85951534],
                            [39.20210598, -7.85939412],
                            [39.20221785, -7.85938479],
                            [39.20237633, -7.85944074],
                            [39.2025721, -7.85961791],
                            [39.20273991, -7.85973914],
                            [39.20291704, -7.8599536],
                            [39.20330859, -7.86030795],
                            [39.20342978, -7.86040118],
                            [39.20343911, -7.86043849],
                            [39.20360692, -7.86048511],
                            [39.2037654, -7.86052241],
                            [39.20388659, -7.86049444],
                            [39.20404507, -7.86055972],
                            [39.2041756, -7.86059701],
                            [39.20439001, -7.86055039],
                            [39.20433408, -7.86027998],
                            [39.20420357, -7.8599536],
                            [39.20402644, -7.85966453],
                            [39.20394253, -7.8593848],
                            [39.2040078, -7.85926357],
                            [39.20419424, -7.85926357],
                            [39.20435272, -7.85944074],
                            [39.20447392, -7.85960859],
                            [39.20459512, -7.85970184],
                            [39.20473495, -7.85962725],
                            [39.20488412, -7.85937547],
                            [39.2050426, -7.85918898],
                            [39.20493073, -7.85907709],
                            [39.20471632, -7.85891857],
                            [39.20450189, -7.85869476],
                            [39.20439935, -7.85858287],
                            [39.20426882, -7.85840571],
                            [39.20421289, -7.85816326],
                            [39.20425951, -7.85798608],
                            [39.20441799, -7.85793947],
                            [39.20463242, -7.85803272],
                            [39.20488413, -7.85816326],
                            [39.20512651, -7.85816326],
                            [39.20529431, -7.85807934],
                            [39.20544348, -7.85795811],
                            [39.20553671, -7.85786488],
                            [39.20568588, -7.85783691],
                            [39.20582572, -7.85787419],
                            [39.20590029, -7.85808867],
                            [39.20602149, -7.85827516],
                            [39.20617064, -7.85839639],
                            [39.2063571, -7.85840572],
                            [39.20655287, -7.85828449],
                            [39.20659017, -7.8580607],
                            [39.20673001, -7.85785555],
                            [39.20689781, -7.85765974],
                            [39.20687916, -7.85744526],
                            [39.20688849, -7.8572681],
                            [39.20693511, -7.85713755],
                            [39.20706562, -7.85701632],
                            [39.20731733, -7.85684848],
                            [39.20776482, -7.85651279],
                            [39.20810976, -7.85610252],
                            [39.20847334, -7.8556083],
                            [39.20869708, -7.85526328],
                            [39.20874371, -7.85502085],
                            [39.20874371, -7.8547784],
                            [39.20873438, -7.85461055],
                            [39.20862251, -7.85442407],
                            [39.20852928, -7.85442406],
                            [39.20835216, -7.85447068],
                            [39.2081657, -7.85458258],
                            [39.20799789, -7.85469447],
                            [39.20784873, -7.85468516],
                            [39.20774618, -7.85458258],
                            [39.20778348, -7.85444271],
                            [39.20802586, -7.85428419],
                            [39.208203, -7.85412566],
                            [39.20834283, -7.85397647],
                            [39.20839877, -7.85378065],
                            [39.20839877, -7.85366875],
                            [39.2084081, -7.85343565],
                            [39.20842674, -7.85321184],
                            [39.20851997, -7.85297873],
                            [39.20873439, -7.85268034],
                            [39.20899542, -7.85240993],
                            [39.20904203, -7.85217681],
                            [39.20908866, -7.85187841],
                            [39.2092285, -7.85166395],
                            [39.2094336, -7.85150542],
                            [39.20953614, -7.85121636],
                            [39.20962005, -7.85101122],
                            [39.20986243, -7.85088999],
                            [39.21007686, -7.85088066],
                            [39.21031924, -7.85080607],
                            [39.21044977, -7.85064756],
                            [39.21057096, -7.85044241],
                            [39.21053367, -7.85026524],
                            [39.21043111, -7.85019996],
                            [39.21023534, -7.85021862],
                            [39.20995566, -7.85029321],
                            [39.20955479, -7.8503678],
                            [39.2093497, -7.85026523],
                            [39.20920054, -7.85006941],
                            [39.20911663, -7.8497617],
                            [39.20904204, -7.84942601],
                            [39.20913528, -7.84926748],
                            [39.20922851, -7.84919289],
                            [39.20940564, -7.84919289],
                            [39.20961073, -7.84916492],
                            [39.20975989, -7.84895977],
                            [39.2097226, -7.84879193],
                            [39.20952683, -7.84863342],
                            [39.20918189, -7.84844691],
                            [39.20893018, -7.84827907],
                            [39.20869711, -7.84808325],
                            [39.20841744, -7.84785012],
                            [39.20822166, -7.84771025],
                            [39.20798859, -7.847617],
                            [39.20778351, -7.84760769],
                            [39.20755977, -7.84755174],
                            [39.20731737, -7.84741187],
                            [39.20715889, -7.84719739],
                            [39.20713093, -7.84693629],
                            [39.20705634, -7.84665655],
                            [39.2068326, -7.84625559],
                            [39.20664615, -7.84605044],
                            [39.20641309, -7.84594787],
                            [39.20611475, -7.84598517],
                            [39.20585373, -7.84608774],
                            [39.20562997, -7.8463395],
                            [39.2054342, -7.84653532],
                            [39.20534098, -7.84697359],
                            [39.20535962, -7.84744915],
                            [39.20549946, -7.84779416],
                            [39.20562065, -7.84817649],
                            [39.2058071, -7.84862407],
                            [39.20604949, -7.84895976],
                            [39.20631053, -7.84937937],
                            [39.20644104, -7.84971506],
                            [39.20645036, -7.84991089],
                            [39.20641307, -7.85006007],
                            [39.20625457, -7.85011602],
                            [39.2060588, -7.85011602],
                            [39.20590032, -7.8500694],
                            [39.20568591, -7.84988291],
                            [39.20549945, -7.84961249],
                            [39.20541555, -7.84953789],
                            [39.20525707, -7.84956587],
                            [39.20508926, -7.84977101],
                            [39.20501467, -7.84993886],
                            [39.20501467, -7.85011602],
                            [39.20507061, -7.85030252],
                            [39.20494941, -7.85046103],
                            [39.20477229, -7.85064753],
                            [39.20457651, -7.85069415],
                            [39.20442735, -7.85082469],
                            [39.2042036, -7.85114173],
                            [39.20404511, -7.85137485],
                            [39.20377476, -7.85163595],
                            [39.20361627, -7.85165459],
                            [39.20348576, -7.85156134],
                            [39.20351372, -7.85138418],
                            [39.20356033, -7.85121634],
                            [39.20348576, -7.85100186],
                            [39.2034205, -7.85089929],
                            [39.20351373, -7.85073145],
                            [39.20373747, -7.8506009],
                            [39.20400783, -7.85039577],
                            [39.20407308, -7.85018129],
                            [39.20408241, -7.84982696],
                            [39.20422225, -7.84952856],
                            [39.20439939, -7.84921152],
                            [39.20457652, -7.84894111],
                            [39.20466043, -7.84857745],
                            [39.20460449, -7.84831635],
                            [39.2045672, -7.84812053],
                            [39.20445533, -7.84797133],
                            [39.20428752, -7.84784078],
                            [39.20413836, -7.84764496],
                            [39.20406378, -7.84727197],
                            [39.20396123, -7.84695494],
                            [39.20388665, -7.84660059],
                            [39.20383072, -7.8462649],
                            [39.20374682, -7.84604111],
                            [39.20362562, -7.84594786],
                            [39.20351375, -7.84591989],
                            [39.20335527, -7.84591989],
                            [39.20324338, -7.84604111],
                            [39.20301033, -7.8461157],
                            [39.20286116, -7.8460784],
                            [39.20273997, -7.8459665],
                            [39.20256283, -7.84588258],
                            [39.20240435, -7.8459665],
                            [39.20218061, -7.84605042],
                            [39.20203145, -7.84588258],
                            [39.20188228, -7.84588258],
                            [39.20177974, -7.84599447],
                            [39.20154667, -7.84622759],
                            [39.20133225, -7.84647936],
                            [39.20110851, -7.84673112],
                            [39.20084747, -7.84690829],
                            [39.2005678, -7.84701087],
                            [39.2002042, -7.84715074],
                            [39.19979401, -7.847216],
                            [39.19924397, -7.84723466],
                            [39.19864733, -7.84724397],
                            [39.19807865, -7.84723465],
                            [39.19763116, -7.84719735],
                            [39.19737945, -7.84718802],
                            [39.19725826, -7.84711343],
                            [39.19720232, -7.84698289],
                            [39.19729555, -7.84683369],
                            [39.1975659, -7.84681504],
                            [39.19799474, -7.84680571],
                            [39.19839562, -7.84677774],
                            [39.19902956, -7.8467218],
                            [39.19930924, -7.84669382],
                            [39.19957028, -7.84658193],
                            [39.19972876, -7.84644206],
                            [39.19973808, -7.84624624],
                            [39.19974741, -7.84606907],
                            [39.19961689, -7.84587325],
                            [39.19961689, -7.84571472],
                            [39.19961689, -7.84552823],
                            [39.1998686, -7.84544431],
                            [39.2001949, -7.84540701],
                            [39.2004, -7.84532309],
                            [39.20042797, -7.84514592],
                            [39.20038135, -7.8448382],
                            [39.2002322, -7.84471698],
                            [39.20006437, -7.84459577],
                            [39.19989657, -7.84452116],
                            [39.19971012, -7.84439062],
                            [39.19960757, -7.84416683],
                            [39.19964486, -7.84387776],
                            [39.19971013, -7.84358869],
                            [39.19972877, -7.84337423],
                            [39.19964487, -7.84323436],
                            [39.19957029, -7.84301988],
                            [39.19954232, -7.84283339],
                            [39.19962623, -7.84268419],
                            [39.19984064, -7.84275878],
                            [39.20009235, -7.84300124],
                            [39.2003161, -7.84326233],
                            [39.20058645, -7.84344882],
                            [39.20108056, -7.84345815],
                            [39.20141617, -7.84330895],
                            [39.20157465, -7.84305719],
                            [39.20160262, -7.8427215],
                            [39.20170518, -7.84250703],
                            [39.20174246, -7.84223662],
                            [39.20193823, -7.84186363],
                            [39.20195689, -7.84162117],
                            [39.20198486, -7.84131346],
                            [39.20205944, -7.84104305],
                            [39.20235777, -7.84079129],
                            [39.20248828, -7.84040897],
                            [39.20247895, -7.84002665],
                            [39.20226454, -7.83965367],
                            [39.20230184, -7.83935527],
                            [39.20247896, -7.83915012],
                            [39.20267475, -7.83897297],
                            [39.20283323, -7.83877713],
                            [39.20301967, -7.8385347],
                            [39.20304764, -7.83835753],
                            [39.20321545, -7.83833888],
                            [39.20332733, -7.83843213],
                            [39.20347648, -7.83867457],
                            [39.20389601, -7.83889836],
                            [39.20423162, -7.8389077],
                            [39.20443671, -7.83883309],
                            [39.2047164, -7.83887972],
                            [39.20498675, -7.83891703],
                            [39.20495878, -7.83904757],
                            [39.20467911, -7.83912216],
                            [39.20446468, -7.83919677],
                            [39.20452994, -7.83942987],
                            [39.20452994, -7.83957907],
                            [39.2043528, -7.83968165],
                            [39.20406381, -7.83986814],
                            [39.20401719, -7.84012923],
                            [39.20397991, -7.84059546],
                            [39.20402651, -7.84092183],
                            [39.20407312, -7.84130416],
                            [39.20418499, -7.84149997],
                            [39.20424092, -7.84162118],
                            [39.20438076, -7.84182633],
                            [39.20469773, -7.84206878],
                            [39.20495877, -7.84223663],
                            [39.20541558, -7.84247907],
                            [39.20569525, -7.84251637],
                            [39.20625461, -7.84255368],
                            [39.20675804, -7.84255368],
                            [39.20709365, -7.84238582],
                            [39.20753182, -7.84224595],
                            [39.20803524, -7.84224596],
                            [39.20878106, -7.84201285],
                            [39.20923785, -7.84172379],
                            [39.20973196, -7.8413881],
                            [39.21002096, -7.84115498],
                            [39.2101981, -7.84090322],
                            [39.21012351, -7.84081929],
                            [39.2101608, -7.84068874],
                            [39.21056168, -7.84065144],
                            [39.21063625, -7.84080997],
                            [39.21046845, -7.84094984],
                            [39.21043115, -7.84110835],
                            [39.21054304, -7.84124822],
                            [39.21080406, -7.84113632],
                            [39.21105577, -7.84110835],
                            [39.21120493, -7.84118296],
                            [39.21127952, -7.84144406],
                            [39.21125155, -7.84171447],
                            [39.21129816, -7.84191028],
                            [39.211438, -7.84182636],
                            [39.21173631, -7.84154662],
                            [39.21197871, -7.84112702],
                            [39.21209991, -7.84091254],
                            [39.21232365, -7.84076336],
                            [39.21250079, -7.84055821],
                            [39.21250079, -7.84024117],
                            [39.21249146, -7.83989615],
                            [39.21245417, -7.83961641],
                            [39.2124262, -7.83934598],
                            [39.21249147, -7.83909422],
                            [39.21237027, -7.83891706],
                            [39.21223976, -7.83865596],
                            [39.21222111, -7.83853474],
                            [39.21223976, -7.83843217],
                            [39.2124169, -7.83833892],
                            [39.2126686, -7.83834825],
                            [39.21309745, -7.83801255],
                            [39.21308812, -7.8377235],
                            [39.21306948, -7.83753699],
                            [39.21305083, -7.83737848],
                            [39.21311609, -7.83720131],
                            [39.21319999, -7.8370148],
                            [39.2132, -7.83687493],
                            [39.21317203, -7.83660452],
                            [39.21308813, -7.8364833],
                            [39.21313219, -7.83622831],
                            [39.21286406, -7.83623347],
                            [39.21234355, -7.83623347],
                            [39.21226482, -7.8362315],
                            [39.21234055, -7.83589825],
                            [39.21251325, -7.83555278],
                            [39.21259174, -7.83536434],
                            [39.21249755, -7.83511309],
                            [39.21260745, -7.83492466],
                            [39.21267024, -7.83473622],
                            [39.21256034, -7.83456349],
                            [39.21240335, -7.83432794],
                            [39.21226206, -7.83413952],
                            [39.21240335, -7.83387256],
                            [39.21279584, -7.83357421],
                            [39.21299994, -7.83338577],
                            [39.21306274, -7.83315023],
                            [39.21306274, -7.83291468],
                            [39.21292145, -7.83263204],
                            [39.21273305, -7.8325064],
                            [39.21238766, -7.83252211],
                            [39.21219927, -7.83260062],
                            [39.21215216, -7.83264772],
                            [39.21205796, -7.83293038],
                            [39.21202657, -7.83321303],
                            [39.21190097, -7.83349569],
                            [39.21161838, -7.83366843],
                            [39.21128869, -7.83366842],
                            [39.21084912, -7.83363701],
                            [39.21058222, -7.83347998],
                            [39.21045663, -7.83332295],
                            [39.21045664, -7.83315021],
                            [39.21039383, -7.83299319],
                            [39.21025253, -7.83294608],
                            [39.21011124, -7.83310311],
                            [39.20995425, -7.83337005],
                            [39.20976585, -7.83351139],
                            [39.20953036, -7.83365271],
                            [39.20923207, -7.83368411],
                            [39.20883959, -7.8336841],
                            [39.20857269, -7.83355849],
                            [39.20836861, -7.83337004],
                            [39.20811742, -7.83329153],
                            [39.20797612, -7.83321301],
                            [39.20778773, -7.83299317],
                            [39.20772493, -7.83280473],
                            [39.20766214, -7.83263201],
                            [39.20744234, -7.83263201],
                            [39.20714405, -7.83252208],
                            [39.20709696, -7.83244357],
                            [39.20709696, -7.83225513],
                            [39.20704985, -7.8320667],
                            [39.20684577, -7.83189397],
                            [39.20667307, -7.83176833],
                            [39.20659458, -7.8315642],
                            [39.20656319, -7.83129724],
                            [39.20645329, -7.83109311],
                            [39.20632768, -7.83085757],
                            [39.20626489, -7.83062202],
                            [39.20650038, -7.83043358],
                            [39.20665738, -7.83044929],
                            [39.20687718, -7.83060632],
                            [39.20703417, -7.83104601],
                            [39.20739525, -7.83128156],
                            [39.20766214, -7.83142288],
                            [39.20791334, -7.83159562],
                            [39.20807033, -7.83159562],
                            [39.20802322, -7.83142288],
                            [39.20786623, -7.83129725],
                            [39.20770924, -7.83103031],
                            [39.20767783, -7.83084187],
                            [39.20777203, -7.83076335],
                            [39.20796043, -7.83084187],
                            [39.20821162, -7.83123444],
                            [39.208557, -7.83145428],
                            [39.20901229, -7.83156421],
                            [39.20940478, -7.83151711],
                            [39.20959316, -7.83128157],
                            [39.20964027, -7.8309518],
                            [39.20964027, -7.83059063],
                            [39.20949898, -7.83008814],
                            [39.20954608, -7.82955423],
                            [39.20976587, -7.82913026],
                            [39.21011126, -7.82861206],
                            [39.21033105, -7.82842363],
                            [39.21064504, -7.82840793],
                            [39.21103752, -7.82853355],
                            [39.21127301, -7.82886331],
                            [39.21130442, -7.82894183],
                            [39.21144571, -7.82914596],
                            [39.21146141, -7.82949143],
                            [39.21155561, -7.8298369],
                            [39.21168119, -7.83019806],
                            [39.21196379, -7.83046502],
                            [39.21235627, -7.83049642],
                            [39.21260747, -7.83044932],
                            [39.21262316, -7.83018236],
                            [39.21270165, -7.82988401],
                            [39.21289005, -7.82975839],
                            [39.21317264, -7.82978979],
                            [39.21351803, -7.82975839],
                            [39.21365932, -7.82958566],
                            [39.21362793, -7.829303],
                            [39.21364362, -7.82895754],
                            [39.21383202, -7.82881622],
                            [39.21402042, -7.82878481],
                            [39.21414601, -7.82900465],
                            [39.21409891, -7.82917738],
                            [39.21419311, -7.82936582],
                            [39.2144286, -7.82956996],
                            [39.21452279, -7.8297741],
                            [39.21453849, -7.83005675],
                            [39.21455419, -7.83027659],
                            [39.21472688, -7.83044932],
                            [39.21496237, -7.83054355],
                            [39.21527636, -7.83055925],
                            [39.21538626, -7.83046503],
                            [39.21548045, -7.83018237],
                            [39.21565315, -7.82996253],
                            [39.21581014, -7.82994683],
                            [39.21596713, -7.83005676],
                            [39.21612413, -7.8302452],
                            [39.21637532, -7.8302766],
                            [39.21659511, -7.83011957],
                            [39.21679921, -7.82977411],
                            [39.21687771, -7.82950716],
                            [39.21695621, -7.82914598],
                            [39.21695621, -7.82886334],
                            [39.21686201, -7.82861209],
                            [39.21673642, -7.82847076],
                            [39.21646953, -7.82856498],
                            [39.21637533, -7.82873772],
                            [39.21618693, -7.82875341],
                            [39.21596714, -7.82875341],
                            [39.21587294, -7.82856498],
                            [39.21585725, -7.82842366],
                            [39.21566885, -7.82840795],
                            [39.21552756, -7.82831373],
                            [39.21543337, -7.8282038],
                            [39.21544907, -7.82798396],
                            [39.21570026, -7.82793686],
                            [39.21585725, -7.82809389],
                            [39.21607704, -7.82806248],
                            [39.21618693, -7.82785835],
                            [39.21634394, -7.8276385],
                            [39.21639103, -7.82738726],
                            [39.21629684, -7.82697898],
                            [39.21610844, -7.82666492],
                            [39.21595145, -7.82631945],
                            [39.21582586, -7.82619384],
                            [39.21552757, -7.82616242],
                            [39.21524497, -7.82625664],
                            [39.21514709, -7.82635972],
                            [39.21500847, -7.82649107],
                            [39.21489903, -7.82658594],
                            [39.2147823, -7.82662243],
                            [39.21457073, -7.82657134],
                            [39.21448319, -7.82642539],
                            [39.21451238, -7.82622107],
                            [39.21462181, -7.82604594],
                            [39.21476771, -7.82583431],
                            [39.21499388, -7.82564459],
                            [39.21522734, -7.82547676],
                            [39.21528571, -7.82533081],
                            [39.21528571, -7.82514108],
                            [39.21519087, -7.82492946],
                            [39.21504496, -7.82487109],
                            [39.21484798, -7.82483459],
                            [39.21465099, -7.82475433],
                            [39.21456345, -7.82462298],
                            [39.2144905, -7.82437487],
                            [39.2144759, -7.82414865],
                            [39.2143154, -7.82399541],
                            [39.21426434, -7.82389325],
                            [39.2141549, -7.82376919],
                            [39.21408194, -7.82365243],
                            [39.21399439, -7.8234919],
                            [39.21395062, -7.82336055],
                            [39.21395062, -7.82325839],
                            [39.21403088, -7.82316352],
                            [39.21411842, -7.82317081],
                            [39.21423515, -7.8232219],
                            [39.21438836, -7.82332406],
                            [39.21455615, -7.82344812],
                            [39.21470207, -7.82357217],
                            [39.21488445, -7.82363054],
                            [39.21507415, -7.82361595],
                            [39.21513251, -7.82353568],
                            [39.21520547, -7.82335326],
                            [39.21526383, -7.82320001],
                            [39.21535137, -7.82315622],
                            [39.21544623, -7.82317812],
                            [39.21557754, -7.82328028],
                            [39.21570156, -7.82338244],
                            [39.21581099, -7.82344812],
                            [39.21592043, -7.82344082],
                            [39.21608094, -7.82339704],
                            [39.21619767, -7.82331677],
                            [39.21636547, -7.82332407],
                            [39.2164895, -7.82340434],
                            [39.21651867, -7.82352109],
                            [39.21651867, -7.82368894],
                            [39.21651867, -7.82395894],
                            [39.21653326, -7.82412677],
                            [39.21670836, -7.82438947],
                            [39.21706585, -7.82471785],
                            [39.21716069, -7.8248565],
                            [39.2172993, -7.82500975],
                            [39.21749628, -7.82525054],
                            [39.21764949, -7.82552784],
                            [39.21789025, -7.82587811],
                            [39.21802157, -7.82608973],
                            [39.2181383, -7.82625758],
                            [39.21837176, -7.82633054],
                            [39.21848119, -7.82621379],
                            [39.21856144, -7.82604596],
                            [39.21871465, -7.82603136],
                            [39.21888244, -7.82601677],
                            [39.21906484, -7.8259438],
                            [39.21925452, -7.82579056],
                            [39.21925452, -7.82557164],
                            [39.21933477, -7.82533813],
                            [39.21940044, -7.82523597],
                            [39.21953176, -7.82520678],
                            [39.2196339, -7.82509732],
                            [39.21969226, -7.82497327],
                            [39.21973604, -7.82491488],
                            [39.21982358, -7.82494407],
                            [39.21991112, -7.82502434],
                            [39.22002056, -7.82508272],
                            [39.22010082, -7.82512651],
                            [39.22018106, -7.82508273],
                            [39.22030509, -7.82503164],
                            [39.22034157, -7.82495867],
                            [39.22042182, -7.82494408],
                            [39.22058233, -7.82493678],
                            [39.22069906, -7.82495138],
                            [39.22076471, -7.82501704],
                            [39.22083038, -7.82511922],
                            [39.22089604, -7.82521408],
                            [39.22102736, -7.82524327],
                            [39.22118785, -7.82522138],
                            [39.22131188, -7.82530165],
                            [39.22130458, -7.825433],
                            [39.22122433, -7.82552786],
                            [39.22104195, -7.82559354],
                            [39.22088874, -7.82561542],
                            [39.2207939, -7.82566651],
                            [39.22069176, -7.82573947],
                            [39.22069176, -7.82584893],
                            [39.22073552, -7.82594381],
                            [39.22088144, -7.82607515],
                            [39.22113679, -7.82613353],
                            [39.22131917, -7.82614083],
                            [39.22156722, -7.82614083],
                            [39.22191741, -7.82615543],
                            [39.2222676, -7.82619921],
                            [39.22263238, -7.82626489],
                            [39.22275641, -7.82628679],
                            [39.22288773, -7.82634516],
                            [39.22291692, -7.82641814],
                            [39.22285125, -7.8265057],
                            [39.22269805, -7.82656407],
                            [39.22243541, -7.82660056],
                            [39.2222749, -7.82661516],
                            [39.22215817, -7.82663705],
                            [39.22204873, -7.82665893],
                            [39.22191011, -7.82673921],
                            [39.22180068, -7.82678299],
                            [39.22175692, -7.82687056],
                            [39.22168395, -7.82697272],
                            [39.22164019, -7.82714785],
                            [39.22164748, -7.82737406],
                            [39.22167665, -7.82759298],
                            [39.22172043, -7.82778272],
                            [39.22174962, -7.82793597],
                            [39.22175691, -7.82804542],
                            [39.22176421, -7.82819136],
                            [39.22170583, -7.82827164],
                            [39.22158181, -7.8283446],
                            [39.22145778, -7.82833001],
                            [39.22132646, -7.82828623],
                            [39.22119514, -7.82825704],
                            [39.22103464, -7.82825704],
                            [39.22090332, -7.82825704],
                            [39.2207574, -7.82825704],
                            [39.22067716, -7.82830811],
                            [39.22061149, -7.82843946],
                            [39.22053124, -7.8286292],
                            [39.2204437, -7.82876783],
                            [39.22029778, -7.82880432],
                            [39.22014457, -7.82876783],
                            [39.22002784, -7.82878243],
                            [39.21996948, -7.82885541],
                            [39.219933, -7.82898676],
                            [39.21999137, -7.82911082],
                            [39.22007161, -7.82921298],
                            [39.22015186, -7.82932973],
                            [39.22014456, -7.82946837],
                            [39.22004972, -7.82959242],
                            [39.2199184, -7.8296727],
                            [39.2197652, -7.82978216],
                            [39.21961929, -7.82987702],
                            [39.21953174, -7.82997918],
                            [39.21947337, -7.83011053],
                            [39.21947337, -7.83024918],
                            [39.21950985, -7.8304462],
                            [39.21961199, -7.83063593],
                            [39.21966306, -7.83078188],
                            [39.21979437, -7.83088404],
                            [39.22000595, -7.83099349],
                            [39.22006431, -7.83106648],
                            [39.22006431, -7.83111025],
                            [39.22004242, -7.83121971],
                            [39.22000595, -7.83133648],
                            [39.21988922, -7.83140215],
                            [39.21979437, -7.83151161],
                            [39.21968493, -7.83158457],
                            [39.21961928, -7.83170133],
                            [39.21961198, -7.83183998],
                            [39.21961928, -7.83200052],
                            [39.21971412, -7.83210998],
                            [39.21986733, -7.83213188],
                            [39.22002053, -7.83212458],
                            [39.22009349, -7.83202972],
                            [39.22012998, -7.83194214],
                            [39.22021022, -7.83192754],
                            [39.22039261, -7.83193484],
                            [39.22054582, -7.83181079],
                            [39.22061877, -7.83162107],
                            [39.22071363, -7.8315408],
                            [39.22080847, -7.83144594],
                            [39.22084493, -7.83140215],
                            [39.22093249, -7.83135836],
                            [39.22104193, -7.83137296],
                            [39.22115136, -7.83146782],
                            [39.22128998, -7.83159917],
                            [39.22142128, -7.83172323],
                            [39.22158179, -7.83177432],
                            [39.2217423, -7.83175242],
                            [39.22182255, -7.83159188],
                            [39.22191739, -7.83141675],
                            [39.2221089, -7.83128904],
                            [39.22222746, -7.83114311],
                            [39.22225482, -7.8309698],
                            [39.22221834, -7.83075999],
                            [39.22209979, -7.83064142],
                            [39.22202683, -7.8305046],
                            [39.22192652, -7.83040425],
                            [39.22199947, -7.83028567],
                            [39.2220998, -7.8301671],
                            [39.22216362, -7.83004852],
                            [39.22217275, -7.82990257],
                            [39.2222457, -7.82980224],
                            [39.2223369, -7.82977487],
                            [39.22246458, -7.82981136],
                            [39.22260137, -7.8298752],
                            [39.22265609, -7.82979311],
                            [39.2227108, -7.82971102],
                            [39.22280199, -7.82969278],
                            [39.22295703, -7.82972926],
                            [39.2230847, -7.82975662],
                            [39.22315766, -7.82974751],
                            [39.22324885, -7.82963805],
                            [39.22325797, -7.82955596],
                            [39.22330356, -7.82945561],
                            [39.2233674, -7.82940088],
                            [39.22349509, -7.82941001],
                            [39.22363188, -7.82959244],
                            [39.22375042, -7.829784],
                            [39.22390546, -7.8299573],
                            [39.22408784, -7.83006676],
                            [39.2242064, -7.83019447],
                            [39.22419727, -7.83034953],
                            [39.22408784, -7.83051372],
                            [39.22392369, -7.83065967],
                            [39.22377777, -7.83070527],
                            [39.2236957, -7.83078736],
                            [39.22373218, -7.83091507],
                            [39.22382338, -7.83115223],
                            [39.22391456, -7.83129818],
                            [39.2240696, -7.83131642],
                            [39.22429759, -7.83128905],
                            [39.22447998, -7.83128905],
                            [39.22468061, -7.83131642],
                            [39.22474445, -7.8313894],
                            [39.22486299, -7.8315171],
                            [39.2249086, -7.83161744],
                            [39.22491771, -7.83176338],
                            [39.2248174, -7.83184547],
                            [39.22473532, -7.83191845],
                            [39.22466237, -7.8320644],
                            [39.2247262, -7.83221033],
                            [39.22483563, -7.8322377],
                            [39.22500891, -7.83226506],
                            [39.22511834, -7.83236541],
                            [39.22516395, -7.83249311],
                            [39.2251457, -7.83260256],
                            [39.22509098, -7.83276675],
                            [39.22494506, -7.83283973],
                            [39.22475355, -7.83279411],
                            [39.22459853, -7.83271202],
                            [39.2244891, -7.83271202],
                            [39.2243523, -7.832785],
                            [39.22427023, -7.83294006],
                            [39.2240696, -7.8329583],
                            [39.2238872, -7.8329127],
                            [39.2237413, -7.83290357],
                            [39.22359538, -7.83293093],
                            [39.22351331, -7.83304039],
                            [39.22342211, -7.83325019],
                            [39.22343123, -7.83356033],
                            [39.22338562, -7.83382485],
                            [39.22331267, -7.8339343],
                            [39.22317588, -7.83403465],
                            [39.22302996, -7.83405289],
                            [39.22282933, -7.83400728],
                            [39.22268343, -7.83390694],
                            [39.22247367, -7.83387046],
                            [39.22233688, -7.83387046],
                            [39.22223657, -7.83398903],
                            [39.22221832, -7.8341441],
                            [39.22233688, -7.83425356],
                            [39.22257399, -7.83428093],
                            [39.22273814, -7.83439039],
                            [39.22285668, -7.83455457],
                            [39.22294788, -7.83471876],
                            [39.22307556, -7.83491031],
                            [39.22328531, -7.83501066],
                            [39.22354978, -7.83501977],
                            [39.22385072, -7.83497417],
                            [39.22413343, -7.83483735],
                            [39.22441612, -7.83475526],
                            [39.22459852, -7.8347735],
                            [39.22485387, -7.83479174],
                            [39.22495418, -7.8346458],
                            [39.22489946, -7.83446336],
                            [39.22493595, -7.83432654],
                            [39.22502713, -7.83420796],
                            [39.22522776, -7.83417148],
                            [39.22534632, -7.83425358],
                            [39.22536455, -7.83446336],
                            [39.22537368, -7.83478263],
                            [39.22539191, -7.83502891],
                            [39.22543752, -7.83522959],
                            [39.22554695, -7.83541201],
                            [39.2257111, -7.83552147],
                            [39.22592084, -7.83552147],
                            [39.22606676, -7.83549411],
                            [39.22613971, -7.83555796],
                            [39.22627651, -7.83571302],
                            [39.22644066, -7.83570391],
                            [39.22644977, -7.83554884],
                            [39.22641331, -7.83533905],
                            [39.22646802, -7.83512013],
                            [39.22659569, -7.83512924],
                            [39.22685104, -7.83510188],
                            [39.2268784, -7.83496506],
                            [39.22675072, -7.83486473],
                            [39.22658657, -7.83487385],
                            [39.22628563, -7.83484649],
                            [39.22616707, -7.83468228],
                            [39.22609412, -7.83442689],
                            [39.2260394, -7.83420796],
                            [39.22605764, -7.83401641],
                            [39.22625827, -7.83394344],
                            [39.22648626, -7.83398905],
                            [39.22673249, -7.83401641],
                            [39.22694224, -7.83401641],
                            [39.22717934, -7.83392521],
                            [39.22723406, -7.83380663],
                            [39.22722494, -7.83370629],
                            [39.22709727, -7.83360595],
                            [39.22691489, -7.83348736],
                            [39.22691489, -7.83334143],
                            [39.22696048, -7.8332867],
                            [39.22707904, -7.8332867],
                            [39.2272523, -7.83330494],
                            [39.22740734, -7.83332318],
                            [39.22754413, -7.83324108],
                            [39.22763533, -7.83310426],
                            [39.22761708, -7.83295831],
                            [39.22750765, -7.83283062],
                            [39.2273435, -7.83269379],
                            [39.22726142, -7.83248399],
                            [39.22737998, -7.83233806],
                            [39.22748029, -7.83220123],
                            [39.22741647, -7.83208265],
                            [39.22723407, -7.83200968],
                            [39.22706992, -7.83192757],
                            [39.2271064, -7.83184548],
                            [39.22737086, -7.83168129],
                            [39.2274803, -7.83149887],
                            [39.22735263, -7.83134379],
                            [39.22716112, -7.8312617],
                            [39.22714287, -7.83115224],
                            [39.22714287, -7.83096982],
                            [39.22701521, -7.83076914],
                            [39.22707905, -7.83055022],
                            [39.22721584, -7.83049549],
                            [39.22734351, -7.83050462],
                            [39.22752591, -7.83062319],
                            [39.22759886, -7.83080563],
                            [39.22768093, -7.83104279],
                            [39.227763, -7.8311705],
                            [39.22792715, -7.83110664],
                            [39.22811866, -7.83110664],
                            [39.22820074, -7.83128907],
                            [39.22832842, -7.83154448],
                            [39.22833753, -7.83179076],
                            [39.22834665, -7.83206442],
                            [39.22844696, -7.83226508],
                            [39.22862936, -7.83242927],
                            [39.22887558, -7.83251136],
                            [39.22897589, -7.8323107],
                            [39.22905798, -7.83214651],
                            [39.22924036, -7.83216475],
                            [39.22950482, -7.83216475],
                            [39.22968722, -7.83206442],
                            [39.2296325, -7.83192758],
                            [39.22957779, -7.83179989],
                            [39.22960515, -7.83167219],
                            [39.22964163, -7.83155361],
                            [39.22954131, -7.83147151],
                            [39.229441, -7.83138942],
                            [39.22941364, -7.83123434],
                            [39.22951395, -7.83111578],
                            [39.22953218, -7.8309607],
                            [39.229441, -7.83086948],
                            [39.22933157, -7.83073266],
                            [39.22934068, -7.83059583],
                            [39.22946836, -7.83050463],
                            [39.22966899, -7.8304955],
                            [39.22985138, -7.83041341],
                            [39.22997904, -7.83027659],
                            [39.23019793, -7.83017624],
                            [39.23045326, -7.829857],
                            [39.23059006, -7.82951037],
                            [39.23066302, -7.82920024],
                            [39.23072608, -7.82904887],
                            [39.23076689, -7.82904432],
                            [39.23087666, -7.82898943],
                            [39.23106871, -7.82896199],
                            [39.23120589, -7.82898943],
                            [39.23131564, -7.82912666],
                            [39.23161745, -7.82915411],
                            [39.23180951, -7.82905804],
                            [39.23216618, -7.8290306],
                            [39.23237197, -7.82887967],
                            [39.23245428, -7.82867385],
                            [39.23260518, -7.82846803],
                            [39.23272865, -7.82824848],
                            [39.23289327, -7.82813871],
                            [39.23309906, -7.82811126],
                            [39.23324996, -7.82797405],
                            [39.23323624, -7.82779567],
                            [39.23322252, -7.82754868],
                            [39.2331265, -7.8271782],
                            [39.23308534, -7.82665677],
                            [39.23320881, -7.82642351],
                            [39.2332774, -7.82614909],
                            [39.23330484, -7.82591581],
                            [39.23338715, -7.82564138],
                            [39.23338715, -7.82536694],
                            [39.23346946, -7.8251474],
                            [39.23356549, -7.82491414],
                            [39.23362036, -7.8246397],
                            [39.23368895, -7.82442016],
                            [39.23368895, -7.82438],
                            [39.23377279, -7.82435671],
                            [39.23402814, -7.824229],
                            [39.23416494, -7.82406481],
                            [39.2341923, -7.82388239],
                            [39.23413758, -7.82358136],
                            [39.23406462, -7.82323475],
                            [39.23401902, -7.82295198],
                            [39.23404638, -7.82253239],
                            [39.23402678, -7.82236895],
                            [39.23405937, -7.8223482],
                            [39.23426514, -7.82222471],
                            [39.23456696, -7.82216982],
                            [39.23464927, -7.82201889],
                            [39.23470414, -7.82192284],
                            [39.23478645, -7.82163468],
                            [39.2347453, -7.82131909],
                            [39.23460812, -7.8212642],
                            [39.23433374, -7.82118187],
                            [39.23416912, -7.82098977],
                            [39.23396335, -7.82077022],
                            [39.23375758, -7.82060556],
                            [39.23371641, -7.82034485],
                            [39.23374384, -7.82020764],
                            [39.23386732, -7.82015274],
                            [39.23403194, -7.82007041],
                            [39.23403194, -7.81987832],
                            [39.23389476, -7.81978227],
                            [39.23364782, -7.81963133],
                            [39.23356551, -7.81937061],
                            [39.23359295, -7.81910991],
                            [39.2337713, -7.81897269],
                            [39.23404567, -7.8188492],
                            [39.23423772, -7.81868453],
                            [39.23438862, -7.8183415],
                            [39.23442978, -7.81795729],
                            [39.23442978, -7.81755937],
                            [39.23423773, -7.81728494],
                            [39.23405939, -7.81705167],
                            [39.23396337, -7.81688701],
                            [39.23378503, -7.81670863],
                            [39.23349693, -7.81643419],
                            [39.23315398, -7.81617348],
                            [39.23283844, -7.81613232],
                            [39.23245433, -7.81609115],
                            [39.23200162, -7.81591277],
                            [39.23157635, -7.81583044],
                            [39.23132941, -7.81583044],
                            [39.23108248, -7.81584416],
                            [39.23087671, -7.81594021],
                            [39.23068464, -7.8160637],
                            [39.23064348, -7.81628324],
                            [39.23062976, -7.81642046],
                            [39.23061605, -7.81665373],
                            [39.23054745, -7.81684583],
                            [39.23060233, -7.81705165],
                            [39.23068503, -7.81715505],
                            [39.23061747, -7.81714153],
                            [39.23051716, -7.81723275],
                            [39.23038036, -7.81740604],
                            [39.23019798, -7.81750639],
                            [39.23003381, -7.81757025],
                            [39.22980584, -7.81760672],
                            [39.22957785, -7.81757936],
                            [39.22935898, -7.81750639],
                            [39.22909451, -7.81743342],
                            [39.22883005, -7.81747902],
                            [39.22867501, -7.81769794],
                            [39.22854734, -7.81790772],
                            [39.22841966, -7.81813577],
                            [39.2283923, -7.81838205],
                            [39.22841966, -7.81856449],
                            [39.2283923, -7.81870131],
                            [39.22829199, -7.8187834],
                            [39.22823727, -7.81894759],
                            [39.22823727, -7.81910265],
                            [39.22818255, -7.81926685],
                            [39.22803664, -7.81928509],
                            [39.22784513, -7.8192486],
                            [39.22770834, -7.81926685],
                            [39.22766275, -7.81939454],
                            [39.22766274, -7.81956786],
                            [39.22758067, -7.81974117],
                            [39.22743475, -7.81980501],
                            [39.22725235, -7.81969555],
                            [39.22717028, -7.81954049],
                            [39.22706997, -7.81943103],
                            [39.22696054, -7.81933981],
                            [39.22685111, -7.81932157],
                            [39.2266596, -7.81939454],
                            [39.22657752, -7.81949487],
                            [39.22643161, -7.81949487],
                            [39.22624921, -7.81951312],
                            [39.22606682, -7.8195496],
                            [39.22595739, -7.81963169],
                            [39.22581147, -7.81962258],
                            [39.22567468, -7.81950399],
                            [39.22554702, -7.81938541],
                            [39.22542846, -7.8193763],
                            [39.22533726, -7.81940366],
                            [39.22521872, -7.81954048],
                            [39.22515487, -7.81963169],
                            [39.22499072, -7.81968643],
                            [39.22486304, -7.81968643],
                            [39.22472625, -7.8196773],
                            [39.22461682, -7.81976852],
                            [39.22450738, -7.81991446],
                            [39.22441618, -7.8200604],
                            [39.2242338, -7.82008777],
                            [39.22408788, -7.81996918],
                            [39.22416085, -7.81975027],
                            [39.22441619, -7.8194675],
                            [39.22462594, -7.81922122],
                            [39.22492689, -7.81913913],
                            [39.22520959, -7.81896581],
                            [39.22547405, -7.81868306],
                            [39.22573853, -7.81851886],
                            [39.2259118, -7.81839116],
                            [39.22617626, -7.81833643],
                            [39.22644985, -7.81817225],
                            [39.22646809, -7.81805366],
                            [39.22648633, -7.81794421],
                            [39.22653192, -7.81783475],
                            [39.22665048, -7.81771617],
                            [39.22681463, -7.81761583],
                            [39.22691495, -7.81750638],
                            [39.22699703, -7.81729657],
                            [39.22698791, -7.81716888],
                            [39.22686935, -7.81705029],
                            [39.22674168, -7.81704118],
                            [39.22652282, -7.81708678],
                            [39.22642249, -7.81708678],
                            [39.22629482, -7.81703205],
                            [39.22622186, -7.81694083],
                            [39.22620363, -7.81678577],
                            [39.2261945, -7.81662158],
                            [39.2261945, -7.81649387],
                            [39.22610332, -7.81640267],
                            [39.22591181, -7.81632969],
                            [39.22574766, -7.81622023],
                            [39.22561998, -7.81609253],
                            [39.22557439, -7.81599218],
                            [39.22561086, -7.81591922],
                            [39.22573854, -7.81591922],
                            [39.22589356, -7.81595571],
                            [39.22605771, -7.81595571],
                            [39.22624922, -7.81597395],
                            [39.22648634, -7.81587362],
                            [39.22675992, -7.815828],
                            [39.22701527, -7.81574591],
                            [39.2271247, -7.81574591],
                            [39.22722501, -7.81576416],
                            [39.22729798, -7.81584626],
                            [39.22734357, -7.81597395],
                            [39.22746213, -7.81601956],
                            [39.22759892, -7.81588273],
                            [39.22775394, -7.81573679],
                            [39.22795458, -7.81565469],
                            [39.22813698, -7.81559085],
                            [39.2283832, -7.81559997],
                            [39.2286203, -7.81559997],
                            [39.22874798, -7.81550876],
                            [39.2288027, -7.81536282],
                            [39.22872974, -7.81523512],
                            [39.22857471, -7.81508004],
                            [39.22847993, -7.81501972],
                            [39.22851156, -7.81485102],
                            [39.22866001, -7.81472374],
                            [39.22874483, -7.81461769],
                            [39.22874483, -7.81446921],
                            [39.22860699, -7.81429952],
                            [39.22856458, -7.81412982],
                            [39.22852217, -7.81401316],
                            [39.228607, -7.8139177],
                            [39.22870243, -7.8139177],
                            [39.22881907, -7.81398134],
                            [39.22896751, -7.81401316],
                            [39.22912657, -7.81397075],
                            [39.22920079, -7.81384347],
                            [39.22924321, -7.81376922],
                            [39.22939165, -7.81375863],
                            [39.22957191, -7.81369499],
                            [39.22959311, -7.81356772],
                            [39.22969914, -7.81346167],
                            [39.22986881, -7.81341924],
                            [39.22993243, -7.81327076],
                            [39.22999604, -7.81320712],
                            [39.23010208, -7.81311167],
                            [39.23025053, -7.81310107],
                            [39.23037777, -7.81304805],
                            [39.2304414, -7.81294198],
                            [39.23053681, -7.81283593],
                            [39.23072768, -7.81285714],
                            [39.23084432, -7.81274048],
                            [39.23093975, -7.81260261],
                            [39.23096096, -7.81246472],
                            [39.23107759, -7.81228442],
                            [39.23128966, -7.81219958],
                            [39.23145931, -7.81222079],
                            [39.23159717, -7.81213595],
                            [39.23161623, -7.81198343],
                            [39.23166624, -7.81203346],
                            [39.23184863, -7.81206993],
                            [39.23203102, -7.8120517],
                            [39.23214046, -7.81194224],
                            [39.23222253, -7.81184191],
                            [39.23235932, -7.81189664],
                            [39.23261467, -7.81204257],
                            [39.23269675, -7.8121794],
                            [39.23284265, -7.81233447],
                            [39.2329612, -7.81241657],
                            [39.23302505, -7.81257163],
                            [39.23302504, -7.81281791],
                            [39.2330524, -7.81305508],
                            [39.23311624, -7.81318277],
                            [39.23325303, -7.81325575],
                            [39.23341718, -7.81325576],
                            [39.23359046, -7.81317366],
                            [39.23371812, -7.81313717],
                            [39.23390051, -7.81318278],
                            [39.23402819, -7.81321927],
                            [39.23411027, -7.81315541],
                            [39.23407378, -7.81300035],
                            [39.23392787, -7.8128544],
                            [39.23375461, -7.81268109],
                            [39.2336968, -7.81257701],
                            [39.23370835, -7.81257701],
                            [39.23379247, -7.81254545],
                            [39.23392917, -7.8124508],
                            [39.23406586, -7.81248235],
                            [39.2341605, -7.81256649],
                            [39.23425514, -7.81267167],
                            [39.23441288, -7.81281891],
                            [39.23457061, -7.81288202],
                            [39.23470731, -7.81288202],
                            [39.23483349, -7.81298721],
                            [39.23495967, -7.81307135],
                            [39.23504379, -7.81317653],
                            [39.23513844, -7.81326067],
                            [39.23521205, -7.81331326],
                            [39.23533823, -7.81331326],
                            [39.23550647, -7.81337636],
                            [39.23554854, -7.81351309],
                            [39.23556956, -7.81366036],
                            [39.23565369, -7.81372346],
                            [39.23588504, -7.81372346],
                            [39.23601647, -7.81370324],
                            [39.23608008, -7.81384866],
                            [39.23618951, -7.81387602],
                            [39.23628982, -7.81383042],
                            [39.23637191, -7.81383955],
                            [39.2364175, -7.81393075],
                            [39.23646309, -7.81405846],
                            [39.23654516, -7.81410407],
                            [39.23664549, -7.81411319],
                            [39.23678228, -7.81408583],
                            [39.23688259, -7.81400374],
                            [39.23699202, -7.81399461],
                            [39.23715617, -7.81398548],
                            [39.23729296, -7.81402198],
                            [39.2373568, -7.81406759],
                            [39.2373568, -7.81417705],
                            [39.23732945, -7.81428651],
                            [39.2373568, -7.8144507],
                            [39.23745711, -7.81452366],
                            [39.23759392, -7.81461488],
                            [39.23782189, -7.81466049],
                            [39.23794958, -7.8145419],
                            [39.23811373, -7.81452367],
                            [39.23825964, -7.81455103],
                            [39.23842379, -7.81456016],
                            [39.23853323, -7.81446894],
                            [39.23853323, -7.81433212],
                            [39.23867002, -7.81428652],
                            [39.23888888, -7.81432299],
                            [39.23898008, -7.81425003],
                            [39.23896152, -7.8141572],
                            [39.23896724, -7.81415904],
                            [39.23908844, -7.81414388],
                            [39.23922479, -7.81411358],
                            [39.23933084, -7.81387113],
                            [39.23951264, -7.81370444],
                            [39.23972473, -7.81349229],
                            [39.23986108, -7.81324984],
                            [39.23994813, -7.81313376],
                            [39.23999744, -7.813068],
                            [39.24004289, -7.81271948],
                            [39.24019439, -7.81238611],
                            [39.24020954, -7.81217396],
                            [39.24016409, -7.81184058],
                            [39.23998229, -7.81156783],
                            [39.2398005, -7.81143146],
                            [39.23945205, -7.81126477],
                            [39.23896725, -7.81112838],
                            [39.2387097, -7.81093139],
                            [39.23857336, -7.81065862],
                            [39.23869456, -7.81038587],
                            [39.23880061, -7.81017372],
                            [39.23881576, -7.80999189],
                            [39.23860366, -7.80993127],
                            [39.23834611, -7.80984034],
                            [39.23817946, -7.80967367],
                            [39.23807342, -7.80955245],
                            [39.23793707, -7.80962821],
                            [39.23787647, -7.80977974],
                            [39.23767953, -7.80991612],
                            [39.23751288, -7.80996158],
                            [39.23733107, -7.80994642],
                            [39.23711897, -7.80999188],
                            [39.23693718, -7.81024948],
                            [39.23678567, -7.81043132],
                            [39.23666447, -7.81058285],
                            [39.23648268, -7.81073439],
                            [39.23646578, -7.81074533],
                            [39.23628983, -7.81061962],
                            [39.23607096, -7.81051016],
                            [39.23587946, -7.81032774],
                            [39.23566972, -7.81014531],
                            [39.23557852, -7.80993551],
                            [39.23551468, -7.80970746],
                            [39.2354326, -7.80956151],
                            [39.2352411, -7.80938822],
                            [39.23506782, -7.80926963],
                            [39.2349128, -7.80928787],
                            [39.23476688, -7.8093426],
                            [39.23448417, -7.80941558],
                            [39.23418323, -7.80945205],
                            [39.23381845, -7.80946118],
                            [39.23369078, -7.80931523],
                            [39.23371814, -7.80916017],
                            [39.23389142, -7.8091328],
                            [39.23412852, -7.80905984],
                            [39.23423795, -7.80895949],
                            [39.23426531, -7.80875881],
                            [39.23421972, -7.8085399],
                            [39.23421972, -7.80837571],
                            [39.23421972, -7.80822065],
                            [39.23437475, -7.80796525],
                            [39.23450243, -7.80775544],
                            [39.23449331, -7.80754565],
                            [39.23438387, -7.80739059],
                            [39.23421972, -7.80724464],
                            [39.23408293, -7.80725375],
                            [39.23389142, -7.80729024],
                            [39.23366343, -7.80730848],
                            [39.23335337, -7.80740883],
                            [39.23308891, -7.80749092],
                            [39.23285179, -7.80759125],
                            [39.23273323, -7.80768247],
                            [39.23255997, -7.80777369],
                            [39.23239581, -7.80772807],
                            [39.23227727, -7.80767334],
                            [39.2320584, -7.80759124],
                            [39.23185777, -7.80758213],
                            [39.23174834, -7.80763686],
                            [39.23162065, -7.80770983],
                            [39.23142914, -7.80780105],
                            [39.23125587, -7.80780105],
                            [39.23106436, -7.80766422],
                            [39.23095873, -7.80766422],
                            [39.23091846, -7.80766422],
                            [39.2307999, -7.80767333],
                            [39.23067222, -7.80773719],
                            [39.23065399, -7.80790137],
                            [39.23065399, -7.80815678],
                            [39.23051719, -7.80827536],
                            [39.23023449, -7.80833009],
                            [39.2299609, -7.80826624],
                            [39.22979675, -7.80803819],
                            [39.22968732, -7.80784664],
                            [39.22955053, -7.80770982],
                            [39.22950492, -7.80750914],
                            [39.22949582, -7.80731759],
                            [39.22934078, -7.80725373],
                            [39.22919488, -7.80729022],
                            [39.22909455, -7.8074453],
                            [39.22887568, -7.80735408],
                            [39.22872066, -7.80734495],
                            [39.22855651, -7.8073632],
                            [39.22843795, -7.80746353],
                            [39.22853826, -7.80761859],
                            [39.22871153, -7.80777367],
                            [39.22870241, -7.80791049],
                            [39.22859297, -7.80794698],
                            [39.2284197, -7.80794698],
                            [39.2280914, -7.80797435],
                            [39.22779958, -7.80792873],
                            [39.2276719, -7.80794698],
                            [39.22744393, -7.80807467],
                            [39.22713386, -7.80805644],
                            [39.22707002, -7.80804731],
                            [39.22697882, -7.80812027],
                            [39.22688763, -7.80828446],
                            [39.22662316, -7.80835744],
                            [39.22645901, -7.80827535],
                            [39.2262675, -7.8080838],
                            [39.22606687, -7.80802907],
                            [39.22584801, -7.80799257],
                            [39.22564737, -7.80784662],
                            [39.22558353, -7.80765507],
                            [39.22562914, -7.80736319],
                            [39.22576593, -7.80713515],
                            [39.22572034, -7.80693447],
                            [39.22553794, -7.80687974],
                            [39.22538292, -7.80677941],
                            [39.22527349, -7.80668819],
                            [39.22520052, -7.806524],
                            [39.22530996, -7.80627772],
                            [39.22543764, -7.80620474],
                            [39.22543764, -7.80604968],
                            [39.22539203, -7.80588549],
                            [39.22524613, -7.80579427],
                            [39.22525524, -7.80563009],
                            [39.22541028, -7.8054659],
                            [39.22543764, -7.80530171],
                            [39.22542852, -7.80515576],
                            [39.22534644, -7.80503718],
                            [39.22520053, -7.80489123],
                            [39.22493607, -7.80489123],
                            [39.2247172, -7.80494596],
                            [39.2245348, -7.80508279],
                            [39.22424299, -7.80510103],
                            [39.22396028, -7.80511016],
                            [39.22365021, -7.80511927],
                            [39.22332191, -7.80500981],
                            [39.22313952, -7.80491859],
                            [39.22296343, -7.80452206],
                            [39.22268088, -7.80452206],
                            [39.22211701, -7.80450038],
                            [39.22205159, -7.8044848],
                            [39.22211652, -7.80428995],
                            [39.22222589, -7.80412586],
                            [39.22244463, -7.80402741],
                            [39.22265243, -7.80403835],
                            [39.22290398, -7.8041915],
                            [39.22323209, -7.80435559],
                            [39.22352739, -7.80431184],
                            [39.22362582, -7.80439935],
                            [39.22399768, -7.80451969],
                            [39.22412892, -7.80444311],
                            [39.22400862, -7.80428996],
                            [39.22419455, -7.80416963],
                            [39.22454453, -7.80418057],
                            [39.22490545, -7.80421339],
                            [39.22523356, -7.80421339],
                            [39.22556167, -7.80412587],
                            [39.22601008, -7.80418057],
                            [39.22611945, -7.80434467],
                            [39.22618507, -7.80462909],
                            [39.2261632, -7.80490258],
                            [39.22604289, -7.80520888],
                            [39.2260757, -7.80546049],
                            [39.22621788, -7.80548237],
                            [39.22632725, -7.8056574],
                            [39.22643662, -7.80591995],
                            [39.22644755, -7.80632471],
                            [39.22665536, -7.80666383],
                            [39.22691784, -7.80688262],
                            [39.22712564, -7.80679511],
                            [39.22733345, -7.80662008],
                            [39.22763968, -7.8065435],
                            [39.22789123, -7.80640129],
                            [39.22786936, -7.80624814],
                            [39.2278803, -7.80600747],
                            [39.22789124, -7.80572304],
                            [39.22818654, -7.80555895],
                            [39.22829591, -7.80577774],
                            [39.22849277, -7.80563553],
                            [39.2285912, -7.80553708],
                            [39.22867496, -7.8054533],
                            [39.22874432, -7.80538392],
                            [39.22889744, -7.80526359],
                            [39.2290615, -7.80528547],
                            [39.2292693, -7.80530735],
                            [39.2295646, -7.80528547],
                            [39.22979427, -7.80517608],
                            [39.22979427, -7.80496823],
                            [39.22966303, -7.8048479],
                            [39.22963022, -7.80453065],
                            [39.22964116, -7.80431186],
                            [39.22970678, -7.8040712],
                            [39.22953179, -7.80383053],
                            [39.22945523, -7.8036555],
                            [39.22932399, -7.80348046],
                            [39.22924743, -7.80334919],
                            [39.22922556, -7.80326167],
                            [39.2292365, -7.80309758],
                            [39.22941149, -7.80304289],
                            [39.22949898, -7.8031304],
                            [39.22966304, -7.80321792],
                            [39.22972866, -7.80332731],
                            [39.22990365, -7.80334919],
                            [39.23012239, -7.80330544],
                            [39.23027551, -7.80340389],
                            [39.23037394, -7.80355704],
                            [39.23052706, -7.8036008],
                            [39.23069111, -7.80356798],
                            [39.23082236, -7.80346953],
                            [39.2310411, -7.80346953],
                            [39.23129265, -7.80348047],
                            [39.23130358, -7.80360081],
                            [39.23131452, -7.80379772],
                            [39.2314567, -7.80387429],
                            [39.23170825, -7.80403839],
                            [39.23177387, -7.80427905],
                            [39.23186137, -7.80443221],
                            [39.23203636, -7.80449784],
                            [39.23233166, -7.8044869],
                            [39.23251758, -7.80439939],
                            [39.23264883, -7.80448691],
                            [39.23268164, -7.804651],
                            [39.23283475, -7.80490261],
                            [39.232966, -7.80508858],
                            [39.23314099, -7.80517609],
                            [39.23344722, -7.80516515],
                            [39.23366596, -7.80515422],
                            [39.23386283, -7.80493543],
                            [39.23396126, -7.80474946],
                            [39.23401595, -7.80455255],
                            [39.23408157, -7.80436658],
                            [39.23410345, -7.80414779],
                            [39.23424563, -7.80404934],
                            [39.23445343, -7.80404934],
                            [39.23459561, -7.80402746],
                            [39.23474873, -7.80404934],
                            [39.2348581, -7.80415873],
                            [39.2349784, -7.80431188],
                            [39.23509871, -7.80448692],
                            [39.23529558, -7.8045088],
                            [39.23548151, -7.80431189],
                            [39.2356565, -7.80431189],
                            [39.23576587, -7.8044541],
                            [39.23602835, -7.80462913],
                            [39.23612679, -7.80482604],
                            [39.23619241, -7.80508859],
                            [39.2363674, -7.80536208],
                            [39.23656426, -7.80544959],
                            [39.23672832, -7.8053402],
                            [39.23690331, -7.80536208],
                            [39.23706736, -7.80555899],
                            [39.23704549, -7.80583248],
                            [39.23711111, -7.8061169],
                            [39.23714392, -7.80628099],
                            [39.23748296, -7.80634663],
                            [39.23767983, -7.80620442],
                            [39.23777826, -7.80604033],
                            [39.23792045, -7.80589812],
                            [39.23810637, -7.80583248],
                            [39.23823762, -7.8058106],
                            [39.23845636, -7.80582154],
                            [39.23860948, -7.80578872],
                            [39.23875166, -7.80579966],
                            [39.23887196, -7.80593094],
                            [39.23894852, -7.80609503],
                            [39.23905789, -7.80612785],
                            [39.23925476, -7.80616067],
                            [39.23951724, -7.80618255],
                            [39.23972505, -7.80610597],
                            [39.23990004, -7.80600752],
                            [39.23991098, -7.80579967],
                            [39.23994379, -7.80558088],
                            [39.23992191, -7.80530739],
                            [39.23972505, -7.80503391],
                            [39.23964849, -7.80481512],
                            [39.23973599, -7.80471666],
                            [39.23986723, -7.80470573],
                            [39.23994379, -7.80479324],
                            [39.24007503, -7.80477136],
                            [39.24011878, -7.80466197],
                            [39.24019534, -7.80460727],
                            [39.24042502, -7.80462915],
                            [39.24055626, -7.80477136],
                            [39.24065469, -7.80503391],
                            [39.24096093, -7.80517613],
                            [39.24116873, -7.80524176],
                            [39.24140934, -7.80546055],
                            [39.24143122, -7.80570122],
                            [39.24160621, -7.8058325],
                            [39.24184682, -7.8058325],
                            [39.24195619, -7.8057231],
                            [39.24195619, -7.80549337],
                            [39.24203275, -7.8053621],
                            [39.242164, -7.80521989],
                            [39.24224055, -7.80503392],
                            [39.24198901, -7.80484795],
                            [39.24187964, -7.80454164],
                            [39.24183589, -7.80430097],
                            [39.24168277, -7.80400561],
                            [39.24170465, -7.80384152],
                            [39.24177027, -7.8036993],
                            [39.24191245, -7.80363367],
                            [39.24201088, -7.80348052],
                            [39.24199995, -7.80328361],
                            [39.24192339, -7.80314139],
                            [39.24173746, -7.80306482],
                            [39.2415406, -7.80302106],
                            [39.24134373, -7.80285697],
                            [39.24110312, -7.80262724],
                            [39.24088438, -7.8025616],
                            [39.24067658, -7.80269287],
                            [39.24046877, -7.80271475],
                            [39.24018441, -7.80270381],
                            [39.24002036, -7.80270381],
                            [39.23975787, -7.8028132],
                            [39.23945163, -7.80287884],
                            [39.23925477, -7.80278038],
                            [39.2390579, -7.80268192],
                            [39.23892666, -7.80244126],
                            [39.23888291, -7.80220059],
                            [39.23878448, -7.80197086],
                            [39.23870792, -7.80191616],
                            [39.23856574, -7.80190522],
                            [39.23840169, -7.80194898],
                            [39.23820482, -7.80199274],
                            [39.23797515, -7.80204743],
                            [39.23765797, -7.80212401],
                            [39.23739549, -7.8021787],
                            [39.237133, -7.80218964],
                            [39.2368377, -7.80218964],
                            [39.23659709, -7.80201461],
                            [39.23635648, -7.80166455],
                            [39.23630179, -7.80135824],
                            [39.23622523, -7.80106288],
                            [39.2361268, -7.80077845],
                            [39.23595181, -7.80055966],
                            [39.23599556, -7.80028617],
                            [39.23602837, -7.8001002],
                            [39.23599556, -7.80017678],
                            [39.23595181, -7.79993611],
                            [39.23586432, -7.79977202],
                            [39.23570026, -7.79962981],
                            [39.23556902, -7.79962981],
                            [39.23538309, -7.79970638],
                            [39.23527372, -7.79983765],
                            [39.23517529, -7.79997987],
                            [39.23507686, -7.80013302],
                            [39.23489093, -7.80014396],
                            [39.23473781, -7.80011114],
                            [39.23462844, -7.80000174],
                            [39.23474875, -7.79975014],
                            [39.23485812, -7.79961886],
                            [39.23493468, -7.79940007],
                            [39.2349128, -7.7992141],
                            [39.23474875, -7.79897344],
                            [39.2345847, -7.7988531],
                            [39.23445345, -7.79880934],
                            [39.23430034, -7.79884216],
                            [39.23409253, -7.79891874],
                            [39.23396129, -7.7989078],
                            [39.23381911, -7.79882028],
                            [39.23366599, -7.7987984],
                            [39.233491, -7.79882028],
                            [39.23327226, -7.79894061],
                            [39.23311914, -7.79892967],
                            [39.23292228, -7.79896249],
                            [39.23275822, -7.79905],
                            [39.23259417, -7.79908282],
                            [39.23249573, -7.79909376],
                            [39.2323973, -7.79906094],
                            [39.23214575, -7.79900624],
                            [39.23202544, -7.79878745],
                            [39.23202545, -7.79852491],
                            [39.23185045, -7.79836081],
                            [39.23166453, -7.79849209],
                            [39.23127079, -7.79861242],
                            [39.23093175, -7.79865618],
                            [39.23064739, -7.79867805],
                            [39.23037396, -7.79854678],
                            [39.23023178, -7.79839363],
                            [39.23022085, -7.79813108],
                            [39.23022085, -7.79798887],
                            [39.23023178, -7.79779196],
                            [39.2301771, -7.7976388],
                            [39.23012242, -7.79743095],
                            [39.23001305, -7.79724498],
                            [39.22990368, -7.79709183],
                            [39.22965213, -7.79701525],
                            [39.22945526, -7.79703713],
                            [39.22933495, -7.79719028],
                            [39.22915996, -7.79725592],
                            [39.22885373, -7.7972778],
                            [39.22846, -7.79728873],
                            [39.22813189, -7.79726685],
                            [39.22797877, -7.79715746],
                            [39.22788034, -7.79708088],
                            [39.22763972, -7.79700431],
                            [39.22743192, -7.79689491],
                            [39.22741005, -7.79676364],
                            [39.22746473, -7.79666518],
                            [39.22768347, -7.79659955],
                            [39.22791315, -7.79654485],
                            [39.22801158, -7.79641358],
                            [39.22811002, -7.79630418],
                            [39.22826313, -7.79630418],
                            [39.22849281, -7.796337],
                            [39.22874436, -7.79638076],
                            [39.22906153, -7.79645734],
                            [39.22930215, -7.79651204],
                            [39.2295537, -7.79654486],
                            [39.22970681, -7.79645734],
                            [39.22966307, -7.79632607],
                            [39.22969588, -7.79612916],
                            [39.22980525, -7.79609634],
                            [39.22989274, -7.79628231],
                            [39.23000211, -7.79638077],
                            [39.2301771, -7.79636983],
                            [39.23024273, -7.79623856],
                            [39.2302646, -7.79598695],
                            [39.23018804, -7.7957244],
                            [39.23010055, -7.79550561],
                            [39.23007868, -7.79531964],
                            [39.23030835, -7.79519931],
                            [39.23047241, -7.79539622],
                            [39.23058178, -7.79558219],
                            [39.23062552, -7.7957244],
                            [39.23063646, -7.79598695],
                            [39.23082239, -7.7961948],
                            [39.23096457, -7.79627138],
                            [39.23117237, -7.79640265],
                            [39.23111768, -7.79663238],
                            [39.23111768, -7.79682929],
                            [39.23134736, -7.79684023],
                            [39.23146767, -7.79703714],
                            [39.2314786, -7.79734344],
                            [39.23170828, -7.79740908],
                            [39.23202545, -7.79731063],
                            [39.23226606, -7.79746378],
                            [39.23253949, -7.79762787],
                            [39.23279104, -7.79748566],
                            [39.23291135, -7.79720124],
                            [39.23306446, -7.79712466],
                            [39.23320665, -7.79690587],
                            [39.23321758, -7.79670896],
                            [39.23309728, -7.79647924],
                            [39.23313009, -7.79631514],
                            [39.23323946, -7.79636984],
                            [39.23332695, -7.79650112],
                            [39.23342539, -7.79649018],
                            [39.23365506, -7.79668709],
                            [39.23399411, -7.7969387],
                            [39.23414723, -7.79691682],
                            [39.23410348, -7.79671991],
                            [39.23401598, -7.79649018],
                            [39.2340488, -7.79618387],
                            [39.23421285, -7.79609636],
                            [39.23456283, -7.7961073],
                            [39.23474876, -7.79617294],
                            [39.23483626, -7.79638079],
                            [39.23484719, -7.79670897],
                            [39.23490188, -7.79692776],
                            [39.23510968, -7.79720125],
                            [39.23525186, -7.79754037],
                            [39.23554716, -7.79775916],
                            [39.23579871, -7.7977154],
                            [39.23602838, -7.79755131],
                            [39.23624712, -7.79733253],
                            [39.23644399, -7.79724501],
                            [39.23658617, -7.79724501],
                            [39.23670648, -7.79732159],
                            [39.23683772, -7.79744192],
                            [39.23704552, -7.79752944],
                            [39.23715489, -7.79752944],
                            [39.23739551, -7.79752944],
                            [39.2375705, -7.79760602],
                            [39.23771268, -7.79780293],
                            [39.23800797, -7.79782481],
                            [39.23823765, -7.79794514],
                            [39.23844545, -7.79801078],
                            [39.23852201, -7.79786857],
                            [39.23868607, -7.79777011],
                            [39.23892668, -7.79785763],
                            [39.23909073, -7.79803266],
                            [39.23914542, -7.79822957],
                            [39.23936416, -7.79833897],
                            [39.23951728, -7.79826239],
                            [39.23971414, -7.798153],
                            [39.23982351, -7.79827333],
                            [39.23997663, -7.79847024],
                            [39.24012975, -7.79847024],
                            [39.2402938, -7.79830615],
                            [39.24046879, -7.79813112],
                            [39.24034849, -7.79802173],
                            [39.24009694, -7.79790139],
                            [39.23971414, -7.79775918],
                            [39.23935322, -7.79759508],
                            [39.23903605, -7.79742005],
                            [39.23879544, -7.79744193],
                            [39.23845639, -7.79744193],
                            [39.23819391, -7.79724502],
                            [39.23799704, -7.79705905],
                            [39.23784392, -7.79682932],
                            [39.237658, -7.79659959],
                            [39.23764706, -7.79630422],
                            [39.23762519, -7.79609637],
                            [39.23761425, -7.79577913],
                            [39.23762519, -7.79559316],
                            [39.23764706, -7.79544001],
                            [39.23759238, -7.7952431],
                            [39.23762519, -7.79491491],
                            [39.23773456, -7.79467425],
                            [39.23767988, -7.79447734],
                            [39.23739552, -7.79426949],
                            [39.23726428, -7.79398506],
                            [39.23716584, -7.79364594],
                            [39.23694711, -7.79336151],
                            [39.23676118, -7.79317554],
                            [39.23676118, -7.79303333],
                            [39.23692523, -7.79296769],
                            [39.23705648, -7.79282548],
                            [39.23701273, -7.79273796],
                            [39.23684868, -7.79273796],
                            [39.23667368, -7.7927489],
                            [39.23656432, -7.79259575],
                            [39.23660806, -7.7923879],
                            [39.23658619, -7.79221287],
                            [39.23650963, -7.79204877],
                            [39.23627996, -7.79193938],
                            [39.23609403, -7.79192844],
                            [39.23595185, -7.79174247],
                            [39.23588623, -7.79167683],
                            [39.23575498, -7.79175341],
                            [39.23553624, -7.79179716],
                            [39.23537219, -7.79166589],
                            [39.23528469, -7.79152368],
                            [39.23516439, -7.7915018],
                            [39.23508783, -7.79154555],
                            [39.2348144, -7.79161119],
                            [39.23468316, -7.79147992],
                            [39.23467222, -7.79127207],
                            [39.23450817, -7.79127207],
                            [39.23433318, -7.79131582],
                            [39.23421287, -7.79127206],
                            [39.23405975, -7.79105328],
                            [39.23395038, -7.7909767],
                            [39.23381914, -7.7909767],
                            [39.2336004, -7.79100952],
                            [39.23346916, -7.79096576],
                            [39.23334885, -7.79083448],
                            [39.23326136, -7.79070321],
                            [39.23308636, -7.79075791],
                            [39.23281294, -7.79085636],
                            [39.23247389, -7.79084542],
                            [39.23228796, -7.79080166],
                            [39.23210204, -7.79076884],
                            [39.23197079, -7.79082354],
                            [39.23185049, -7.79093293],
                            [39.23165362, -7.79103139],
                            [39.23145675, -7.7911736],
                            [39.23133645, -7.79134863],
                            [39.23117239, -7.79156742],
                            [39.23108489, -7.79165493],
                            [39.23092084, -7.79167681],
                            [39.23075678, -7.79164399],
                            [39.23073491, -7.79154554],
                            [39.2307021, -7.79134863],
                            [39.23058179, -7.79127205],
                            [39.23039587, -7.79131581],
                            [39.23025368, -7.79145802],
                            [39.23003494, -7.79150178],
                            [39.2299037, -7.79143614],
                            [39.22988183, -7.79129393],
                            [39.22975058, -7.79125017],
                            [39.22963028, -7.79125017],
                            [39.22941154, -7.7913158],
                            [39.22914905, -7.79135956],
                            [39.22902874, -7.79125016],
                            [39.22906156, -7.79102044],
                            [39.22896312, -7.79094386],
                            [39.22876626, -7.79096574],
                            [39.22859127, -7.79088916],
                            [39.22858033, -7.79076883],
                            [39.2286022, -7.79062661],
                            [39.22883188, -7.79063755],
                            [39.22910531, -7.7906485],
                            [39.22947716, -7.79067038],
                            [39.2297834, -7.79072507],
                            [39.22994745, -7.79071414],
                            [39.23016619, -7.79062662],
                            [39.23034118, -7.79046253],
                            [39.23025369, -7.79026562],
                            [39.23008964, -7.79014529],
                            [39.23001308, -7.79000307],
                            [39.23001308, -7.78989368],
                            [39.23008964, -7.78994838],
                            [39.23026463, -7.79000307],
                            [39.23045056, -7.79001401],
                            [39.23065836, -7.78999214],
                            [39.23062555, -7.78986086],
                            [39.23030838, -7.78965301],
                            [39.2301662, -7.78958737],
                            [39.23017713, -7.78944516],
                            [39.2301662, -7.78918261],
                            [39.23005683, -7.7891498],
                            [39.22988184, -7.78928107],
                            [39.22965216, -7.78935764],
                            [39.22930218, -7.78936858],
                            [39.22914906, -7.78930294],
                            [39.22896313, -7.78916073],
                            [39.22887564, -7.78899664],
                            [39.22894126, -7.78885443],
                            [39.22909438, -7.78877785],
                            [39.22924749, -7.78875597],
                            [39.22954279, -7.78876691],
                            [39.22969591, -7.78876691],
                            [39.22978341, -7.78866846],
                            [39.22983809, -7.78850437],
                            [39.22996934, -7.78841685],
                            [39.23013339, -7.78844967],
                            [39.23033026, -7.7886247],
                            [39.23045056, -7.78859189],
                            [39.23050525, -7.78843873],
                            [39.2306693, -7.78843873],
                            [39.23086617, -7.78847155],
                            [39.23101928, -7.78859189],
                            [39.2311724, -7.78852625],
                            [39.23124896, -7.78835122],
                            [39.2312599, -7.78815431],
                            [39.23116147, -7.78794646],
                            [39.23110678, -7.78770579],
                            [39.23101929, -7.78749794],
                            [39.23092086, -7.78711506],
                            [39.2307568, -7.78678688],
                            [39.23074981, -7.78677662],
                            [39.23059275, -7.78654621],
                            [39.23048338, -7.78614145],
                            [39.23045057, -7.78591172],
                            [39.23059275, -7.78584609],
                            [39.23074587, -7.7859883],
                            [39.23103023, -7.78632742],
                            [39.23132244, -7.78665513],
                            [39.23139115, -7.78673218],
                            [39.23186144, -7.7871807],
                            [39.23212392, -7.78738855],
                            [39.23227704, -7.78756359],
                            [39.23232079, -7.7877605],
                            [39.23234266, -7.78794647],
                            [39.23252859, -7.78804492],
                            [39.23271452, -7.78815432],
                            [39.23288951, -7.7881762],
                            [39.23307544, -7.78819808],
                            [39.2332723, -7.7881215],
                            [39.23340355, -7.78804493],
                            [39.23355667, -7.78803399],
                            [39.23361135, -7.78811056],
                            [39.23381915, -7.78813244],
                            [39.23401602, -7.78808869],
                            [39.23427851, -7.78813244],
                            [39.23440975, -7.78827466],
                            [39.23480348, -7.78832936],
                            [39.23507691, -7.78805587],
                            [39.23533939, -7.78762923],
                            [39.23519722, -7.7872026],
                            [39.2350441, -7.78700569],
                            [39.23481442, -7.78684159],
                            [39.234541, -7.78663374],
                            [39.23415821, -7.78634932],
                            [39.23403127, -7.78608044],
                            [39.23397228, -7.78595549],
                            [39.23381916, -7.78562731],
                            [39.23354574, -7.78528819],
                            [39.23334888, -7.78500376],
                            [39.23321763, -7.78485061],
                            [39.23297702, -7.7845443],
                            [39.23293327, -7.78433645],
                            [39.23309733, -7.7841286],
                            [39.23309733, -7.78392075],
                            [39.23326139, -7.78383324],
                            [39.23351294, -7.78390982],
                            [39.23380823, -7.7841833],
                            [39.23401603, -7.78451149],
                            [39.23410353, -7.78482873],
                            [39.23431133, -7.78513504],
                            [39.23466131, -7.78536477],
                            [39.23481443, -7.78551792],
                            [39.23493474, -7.78550698],
                            [39.23514254, -7.78540853],
                            [39.2353394, -7.78539759],
                            [39.23536128, -7.78566014],
                            [39.23530968, -7.78580922],
                            [39.23526284, -7.78594456],
                            [39.23519722, -7.78613053],
                            [39.23527378, -7.7862618],
                            [39.23552533, -7.78638214],
                            [39.23574407, -7.78640402],
                            [39.23580969, -7.78628369],
                            [39.23597375, -7.78613053],
                            [39.2362253, -7.78616335],
                            [39.23644403, -7.78650248],
                            [39.23668465, -7.78674315],
                            [39.2368487, -7.78699475],
                            [39.23694713, -7.7872026],
                            [39.23713306, -7.78734482],
                            [39.23741742, -7.78750891],
                            [39.2376471, -7.78780428],
                            [39.23773459, -7.78799025],
                            [39.23771272, -7.78824185],
                            [39.23759241, -7.78859192],
                            [39.23763615, -7.7889201],
                            [39.23773459, -7.78944519],
                            [39.23800801, -7.78978432],
                            [39.23821581, -7.78994841],
                            [39.23842361, -7.79012344],
                            [39.23848923, -7.79029847],
                            [39.23862048, -7.79050632],
                            [39.23881734, -7.7905829],
                            [39.2391017, -7.7905829],
                            [39.23928763, -7.79067042],
                            [39.23952824, -7.79090015],
                            [39.2396923, -7.79104236],
                            [39.23999853, -7.79098766],
                            [39.24027196, -7.79106424],
                            [39.24046882, -7.79118458],
                            [39.2406985, -7.7914143],
                            [39.24093911, -7.79172061],
                            [39.2411141, -7.79190658],
                            [39.24135471, -7.79189564],
                            [39.24137659, -7.79156746],
                            [39.24131097, -7.79122834],
                            [39.24130003, -7.79088921],
                            [39.24147503, -7.79086734],
                            [39.24166095, -7.79106425],
                            [39.24181407, -7.79108613],
                            [39.24193438, -7.79106425],
                            [39.24203281, -7.79090016],
                            [39.24218593, -7.79092204],
                            [39.2422953, -7.79108613],
                            [39.24231717, -7.7913268],
                            [39.24222967, -7.79156746],
                            [39.24213124, -7.79192846],
                            [39.24204374, -7.79236604],
                            [39.24209843, -7.79270516],
                            [39.24224061, -7.79290208],
                            [39.24254684, -7.79309899],
                            [39.2429187, -7.79309899],
                            [39.24319212, -7.7929349],
                            [39.24327962, -7.79289114],
                            [39.24348742, -7.7929896],
                            [39.24373897, -7.79321933],
                            [39.24390302, -7.79337248],
                            [39.24397958, -7.79345999],
                            [39.24399052, -7.79359127],
                            [39.24416551, -7.79372254],
                            [39.24443893, -7.79372254],
                            [39.2447233, -7.79373348],
                            [39.24477798, -7.79360221],
                            [39.24486548, -7.79329591],
                            [39.24475611, -7.7929896],
                            [39.24479986, -7.79261766],
                            [39.2450186, -7.79234418],
                            [39.24516078, -7.79211445],
                            [39.24539046, -7.79178627],
                            [39.24536859, -7.79144714],
                            [39.24517172, -7.79130493],
                            [39.24483268, -7.79129399],
                            [39.24463581, -7.79125023],
                            [39.24443895, -7.7910752],
                            [39.24424208, -7.79083453],
                            [39.24419833, -7.79053916],
                            [39.24426396, -7.79032038],
                            [39.24429677, -7.79006877],
                            [39.24419834, -7.78983904],
                            [39.24408897, -7.78966401],
                            [39.24391398, -7.78940146],
                            [39.24391398, -7.78912797],
                            [39.24403429, -7.78909516],
                            [39.24426396, -7.78909516],
                            [39.24460301, -7.7891608],
                            [39.24481081, -7.78929207],
                            [39.24505142, -7.78912798],
                            [39.24522642, -7.78889825],
                            [39.24535766, -7.78883262],
                            [39.2455764, -7.7887451],
                            [39.24570764, -7.78872322],
                            [39.24578421, -7.78842786],
                            [39.24579514, -7.78820907],
                            [39.24589358, -7.78826377],
                            [39.24605763, -7.78837316],
                            [39.24621075, -7.78831846],
                            [39.24618888, -7.78800122],
                            [39.24634199, -7.78780431],
                            [39.2465498, -7.78757458],
                            [39.24661542, -7.78739955],
                            [39.2467576, -7.78722452],
                            [39.24695447, -7.78710419],
                            [39.24697634, -7.78684164],
                            [39.24702009, -7.78660098],
                            [39.24716227, -7.78630561],
                            [39.24723883, -7.78604306],
                            [39.24718415, -7.78581334],
                            [39.24696541, -7.78559455],
                            [39.24679042, -7.78546327],
                            [39.24664824, -7.78537576],
                            [39.24638575, -7.78526636],
                            [39.24609045, -7.7852773],
                            [39.24586078, -7.78529918],
                            [39.24567485, -7.78531012],
                            [39.24541236, -7.78533199],
                            [39.24511706, -7.78523354],
                            [39.24498582, -7.78505851],
                            [39.24497488, -7.78493817],
                            [39.24511706, -7.78488348],
                            [39.24530299, -7.78488348],
                            [39.24556548, -7.78488348],
                            [39.24580609, -7.78485066],
                            [39.24607952, -7.78470845],
                            [39.24635294, -7.78459905],
                            [39.24659356, -7.78459906],
                            [39.24687792, -7.78459906],
                            [39.24699823, -7.78448966],
                            [39.24717322, -7.7845006],
                            [39.24733727, -7.78453342],
                            [39.24747945, -7.78438027],
                            [39.24779663, -7.78434745],
                            [39.24791693, -7.78435839],
                            [39.24800443, -7.78421618],
                            [39.24804818, -7.78411773],
                            [39.24819741, -7.78394871],
                            [39.24840262, -7.78375924],
                            [39.24856048, -7.78350662],
                            [39.24856048, -7.78322242],
                            [39.24856048, -7.78301716],
                            [39.24873412, -7.78282769],
                            [39.2489709, -7.78273296],
                            [39.24920768, -7.78273296],
                            [39.24928661, -7.78290664],
                            [39.24934975, -7.78330137],
                            [39.2493971, -7.78375925],
                            [39.24947603, -7.78393292],
                            [39.24952339, -7.78416976],
                            [39.24955495, -7.78459606],
                            [39.24936553, -7.78486447],
                            [39.24906561, -7.78506973],
                            [39.24881304, -7.78521183],
                            [39.24878147, -7.78557497],
                            [39.24878146, -7.7859697],
                            [39.24903403, -7.78630127],
                            [39.24939709, -7.78649074],
                            [39.24966544, -7.78652232],
                            [39.24994958, -7.78650653],
                            [39.25018636, -7.78649074],
                            [39.25040736, -7.78642759],
                            [39.25056521, -7.78652232],
                            [39.25059678, -7.78679073],
                            [39.25037579, -7.78709072],
                            [39.25004429, -7.78718545],
                            [39.24976015, -7.78735913],
                            [39.24939709, -7.78773807],
                            [39.24895509, -7.78859067],
                            [39.24876567, -7.78904855],
                            [39.24860781, -7.78934854],
                            [39.24841838, -7.78955379],
                            [39.24821317, -7.78956958],
                            [39.24810268, -7.78988536],
                            [39.24794482, -7.79018535],
                            [39.24778697, -7.79032745],
                            [39.2476449, -7.79046955],
                            [39.24762911, -7.79075375],
                            [39.24737654, -7.7911169],
                            [39.2473134, -7.7914011],
                            [39.24740811, -7.79160635],
                            [39.24758175, -7.79195371],
                            [39.2478501, -7.79219055],
                            [39.24824473, -7.79253791],
                            [39.24841837, -7.79277474],
                            [39.2489235, -7.79291684],
                            [39.24938128, -7.79302737],
                            [39.24982327, -7.79315368],
                            [39.25009162, -7.79337473],
                            [39.25020212, -7.79376945],
                            [39.25047047, -7.79397471],
                            [39.25077039, -7.79394313],
                            [39.25095982, -7.79372209],
                            [39.25111767, -7.7935642],
                            [39.25133239, -7.79311332],
                            [39.25149349, -7.79324516],
                            [39.25190877, -7.79373964],
                            [39.2520389, -7.79387848],
                            [39.25220539, -7.79405611],
                            [39.25258111, -7.79427371],
                            [39.25309528, -7.79443195],
                            [39.25368854, -7.79451108],
                            [39.25404451, -7.79472865],
                            [39.25436091, -7.79506488],
                            [39.2545122, -7.79522279],
                            [39.25481574, -7.79553962],
                            [39.25532989, -7.79589565],
                            [39.25582427, -7.79621214],
                            [39.25607447, -7.7962931],
                            [39.25649664, -7.79642971],
                            [39.25722831, -7.79664728],
                            [39.2578018, -7.79682532],
                            [39.25831595, -7.79700334],
                            [39.2588499, -7.79706268],
                            [39.25938383, -7.79718136],
                            [39.25991776, -7.79730004],
                            [39.26060989, -7.79743849],
                            [39.26110428, -7.79761653],
                            [39.26153935, -7.7978341],
                            [39.26203372, -7.79799234],
                            [39.262449, -7.79822969],
                            [39.26281474, -7.79840846],
                            [39.26356472, -7.79865851],
                            [39.26409495, -7.79874881],
                            [39.2640533, -7.79781187],
                            [39.26411575, -7.79683329],
                            [39.26399086, -7.79602124],
                            [39.2640325, -7.7950843],
                            [39.26424067, -7.79433474],
                            [39.26457372, -7.793606],
                            [39.26509415, -7.79283562],
                            [39.26578108, -7.792211],
                            [39.26592298, -7.79209535],
                            [39.26634314, -7.79175293],
                            [39.26707171, -7.79154472],
                            [39.26790436, -7.79108667],
                            [39.26843825, -7.79069643],
                            [39.26887334, -7.7907586],
                            [39.26926229, -7.79085585],
                            [39.26991055, -7.79101796],
                            [39.27039673, -7.79118006],
                            [39.27091534, -7.79130974],
                            [39.27136913, -7.79153668],
                            [39.27188772, -7.79166636],
                            [39.27243874, -7.79169879],
                            [39.27305458, -7.79176365],
                            [39.27363801, -7.79179606],
                            [39.27399455, -7.79192574],
                            [39.27470762, -7.79192575],
                            [39.2751614, -7.79189334],
                            [39.27558277, -7.79179607],
                            [39.27613378, -7.79163396],
                            [39.27674963, -7.7915367],
                            [39.27723582, -7.79137461],
                            [39.277722, -7.79111525],
                            [39.27827303, -7.79092073],
                            [39.27872679, -7.79072623],
                            [39.2792454, -7.79049928],
                            [39.27979643, -7.79017508],
                            [39.28021779, -7.78991572],
                            [39.28060674, -7.789397],
                            [39.28096328, -7.78881344],
                            [39.2812226, -7.7884244],
                            [39.28138465, -7.78797054],
                            [39.28161154, -7.78764634],
                            [39.28196809, -7.78716003],
                            [39.28232462, -7.7866089],
                            [39.28268117, -7.78618745],
                            [39.28303771, -7.78570114],
                            [39.28326459, -7.78534451],
                            [39.28388043, -7.78466372],
                            [39.28391622, -7.78465518],
                            [39.28391622, -7.78436641],
                            [39.28398025, -7.78391803],
                            [39.28423642, -7.78308531],
                            [39.28442856, -7.7824768],
                            [39.28455661, -7.78202842],
                            [39.28494085, -7.7814199],
                            [39.28535714, -7.78081138],
                            [39.28570934, -7.78020286],
                            [39.28638177, -7.77917799],
                            [39.28679804, -7.77866555],
                            [39.28665505, -7.77843452],
                            [39.28612738, -7.77836415],
                            [39.28545898, -7.77846971],
                            [39.28461471, -7.77839932],
                            [39.28429809, -7.77815302],
                            [39.2839815, -7.77801228],
                            [39.28355936, -7.77773079],
                            [39.28306686, -7.77769559],
                            [39.28271508, -7.77766041],
                            [39.2821874, -7.77755484],
                            [39.28169492, -7.77755484],
                            [39.28120242, -7.77755484],
                            [39.28067472, -7.77755483],
                            [39.28014706, -7.77751965],
                            [39.27995578, -7.77752404],
                            [39.27961939, -7.77758343],
                            [39.27934236, -7.7776428],
                            [39.27896639, -7.77770218],
                            [39.27863001, -7.77770218],
                            [39.27817487, -7.77772196],
                            [39.27773956, -7.77772196],
                            [39.27746252, -7.77772196],
                            [39.27718549, -7.77774176],
                            [39.27688867, -7.77780113],
                            [39.27663143, -7.77784071],
                            [39.27631482, -7.7779001],
                            [39.27607737, -7.77801884],
                            [39.27578056, -7.77815738],
                            [39.27548374, -7.77827613],
                            [39.27514735, -7.7783553],
                            [39.27487031, -7.77853343],
                            [39.27459329, -7.77869177],
                            [39.27417774, -7.77886991],
                            [39.27382155, -7.77894908],
                            [39.27350494, -7.77904802],
                            [39.27335796, -7.77906762],
                            [39.27320814, -7.7790876],
                            [39.27289153, -7.7790876],
                            [39.27255515, -7.7790876],
                            [39.27253463, -7.7790734],
                            [39.2725309, -7.77907082],
                            [39.2725309, -7.77882533],
                            [39.27261231, -7.77872357],
                            [39.27275475, -7.77860143],
                            [39.27308037, -7.77854036],
                            [39.27348738, -7.77845894],
                            [39.27375195, -7.77841823],
                            [39.27409792, -7.77819433],
                            [39.27442353, -7.77799076],
                            [39.27470845, -7.77764473],
                            [39.27509512, -7.77744117],
                            [39.27540039, -7.77723761],
                            [39.27582775, -7.77699335],
                            [39.27601091, -7.77691193],
                            [39.27643829, -7.77670838],
                            [39.2767639, -7.77650483],
                            [39.27706917, -7.77615878],
                            [39.27715058, -7.77573131],
                            [39.27715058, -7.77538526],
                            [39.27717092, -7.77503921],
                            [39.27727268, -7.77475425],
                            [39.27753724, -7.77473391],
                            [39.27772042, -7.77489673],
                            [39.27788321, -7.77512065],
                            [39.27796461, -7.77540563],
                            [39.27810707, -7.77567025],
                            [39.27822917, -7.77587381],
                            [39.27843268, -7.77609771],
                            [39.27863619, -7.77624021],
                            [39.27890075, -7.77624021],
                            [39.27908392, -7.77609771],
                            [39.27922637, -7.77589418],
                            [39.27926707, -7.77567026],
                            [39.27930777, -7.77544636],
                            [39.27918567, -7.77518173],
                            [39.27914497, -7.77495781],
                            [39.27934848, -7.77483568],
                            [39.27971479, -7.77483568],
                            [39.28008112, -7.77507995],
                            [39.28036601, -7.77530385],
                            [39.28071198, -7.77552777],
                            [39.28103759, -7.77550742],
                            [39.28126146, -7.77532422],
                            [39.28140393, -7.77489675],
                            [39.28140393, -7.77459143],
                            [39.28120042, -7.77430644],
                            [39.28101726, -7.77412325],
                            [39.28093586, -7.77383827],
                            [39.28085445, -7.7736347],
                            [39.28079341, -7.77334974],
                            [39.28061025, -7.77298333],
                            [39.28052884, -7.77271872],
                            [39.28036602, -7.77239303],
                            [39.28012183, -7.77206733],
                            [39.27999971, -7.77172129],
                            [39.27981657, -7.77127346],
                            [39.2796741, -7.77092741],
                            [39.27942991, -7.77056101],
                            [39.27932814, -7.77015391],
                            [39.27916535, -7.76984857],
                            [39.27906359, -7.76950252],
                            [39.27896183, -7.7690547],
                            [39.27879904, -7.76883078],
                            [39.27867692, -7.76844404],
                            [39.27845307, -7.76801657],
                            [39.27824955, -7.7675484],
                            [39.2781071, -7.7672227],
                            [39.27804606, -7.7668563],
                            [39.2780257, -7.76665275],
                            [39.27814781, -7.76659168],
                            [39.27845308, -7.76671381],
                            [39.27859552, -7.76695809],
                            [39.27879905, -7.76724307],
                            [39.27912466, -7.76738555],
                            [39.27934851, -7.76760947],
                            [39.27947063, -7.7679148],
                            [39.27953167, -7.7682812],
                            [39.27959271, -7.76862725],
                            [39.27959271, -7.76899365],
                            [39.27963341, -7.76927862],
                            [39.27967411, -7.76954323],
                            [39.27973517, -7.76986892],
                            [39.27995902, -7.77015391],
                            [39.28026429, -7.77035746],
                            [39.28046781, -7.77062209],
                            [39.2806713, -7.77086636],
                            [39.28087482, -7.77104956],
                            [39.28093587, -7.77131419],
                            [39.28093586, -7.77151773],
                            [39.28122077, -7.77184342],
                            [39.281465, -7.77206733],
                            [39.28164815, -7.77218947],
                            [39.28201446, -7.77235233],
                            [39.28231973, -7.77235233],
                            [39.2825843, -7.77235233],
                            [39.28284886, -7.77231162],
                            [39.28301166, -7.77214876],
                            [39.28303201, -7.7719045],
                            [39.28299131, -7.77151774],
                            [39.28282849, -7.77125311],
                            [39.28272675, -7.7709885],
                            [39.2825029, -7.77056103],
                            [39.28236044, -7.7702557],
                            [39.28211622, -7.76972645],
                            [39.28181098, -7.76925828],
                            [39.28174991, -7.769014],
                            [39.28160746, -7.76881045],
                            [39.28154642, -7.76844405],
                            [39.28136325, -7.76805729],
                            [39.2811394, -7.76765018],
                            [39.28091554, -7.76712093],
                            [39.28075272, -7.76685632],
                            [39.28048817, -7.76657134],
                            [39.28020326, -7.76646957],
                            [39.27987765, -7.76644921],
                            [39.27971483, -7.76630671],
                            [39.27979625, -7.76610317],
                            [39.27999974, -7.76591995],
                            [39.28028466, -7.76579784],
                            [39.28020326, -7.76557392],
                            [39.2801422, -7.76537035],
                            [39.27995904, -7.76500395],
                            [39.27977589, -7.76447471],
                            [39.27979625, -7.76408797],
                            [39.27991834, -7.76400653],
                            [39.28018291, -7.76431187],
                            [39.28054924, -7.7646172],
                            [39.28093589, -7.76484111],
                            [39.2810987, -7.7651261],
                            [39.28101729, -7.76541108],
                            [39.2812208, -7.76569604],
                            [39.28148537, -7.7658589],
                            [39.28177028, -7.76618459],
                            [39.28197378, -7.76640849],
                            [39.28231975, -7.76669348],
                            [39.28268607, -7.76679527],
                            [39.28264537, -7.76644922],
                            [39.28258432, -7.76616424],
                            [39.28240116, -7.76577748],
                            [39.28213659, -7.76535001],
                            [39.28207553, -7.76506504],
                            [39.28195345, -7.76459685],
                            [39.28172958, -7.76410832],
                            [39.28148538, -7.76345695],
                            [39.28126151, -7.76304984],
                            [39.28118013, -7.7627038],
                            [39.28126151, -7.76254094],
                            [39.28166853, -7.76235775],
                            [39.28207555, -7.76225597],
                            [39.28246222, -7.76219491],
                            [39.28290995, -7.76227632],
                            [39.28321522, -7.76260202],
                            [39.28347977, -7.76292771],
                            [39.28376468, -7.76325339],
                            [39.28413099, -7.76347731],
                            [39.28439556, -7.76355873],
                            [39.28463978, -7.76378265],
                            [39.28482293, -7.76414905],
                            [39.28478223, -7.76443402],
                            [39.28466012, -7.76488184],
                            [39.28459907, -7.7652686],
                            [39.28459907, -7.76569605],
                            [39.28466012, -7.76608281],
                            [39.28474152, -7.76630673],
                            [39.28498573, -7.76614389],
                            [39.28514853, -7.76579786],
                            [39.2853317, -7.76557394],
                            [39.28543345, -7.76520754],
                            [39.28561661, -7.76490221],
                            [39.28559627, -7.7643526],
                            [39.28567768, -7.76402692],
                            [39.28567768, -7.76347732],
                            [39.28569802, -7.762887],
                            [39.28565733, -7.76243919],
                            [39.28565733, -7.76209314],
                            [39.28577943, -7.76164532],
                            [39.286044, -7.76142141],
                            [39.28616611, -7.76119749],
                            [39.28632891, -7.76103466],
                            [39.28632892, -7.76072933],
                            [39.28649172, -7.76050541],
                            [39.28643068, -7.76013901],
                            [39.28643068, -7.75991511],
                            [39.28651207, -7.75973191],
                            [39.28653243, -7.75942657],
                            [39.28663419, -7.75914159],
                            [39.2868377, -7.75936551],
                            [39.28708191, -7.75954872],
                            [39.2872854, -7.75944692],
                            [39.28754997, -7.75934515],
                            [39.28769245, -7.75916195],
                            [39.28769245, -7.75885662],
                            [39.28789594, -7.75869378],
                            [39.28785524, -7.75838844],
                            [39.28785524, -7.75814416],
                            [39.2878349, -7.7578592],
                            [39.28767209, -7.75773706],
                            [39.28744823, -7.7578592],
                            [39.28712261, -7.75775742],
                            [39.28700051, -7.75751315],
                            [39.28728541, -7.7573096],
                            [39.2877128, -7.75720783],
                            [39.28805877, -7.75698392],
                            [39.28834366, -7.75667858],
                            [39.28838438, -7.75627147],
                            [39.28838439, -7.75582366],
                            [39.28830297, -7.75562009],
                            [39.28807912, -7.75549795],
                            [39.28791631, -7.75521298],
                            [39.28811982, -7.75490764],
                            [39.28834367, -7.75476516],
                            [39.28834367, -7.75443948],
                            [39.28811983, -7.75405272],
                            [39.28787562, -7.75388987],
                            [39.28761105, -7.75374738],
                            [39.2875093, -7.75342169],
                            [39.28757035, -7.75309601],
                            [39.28779422, -7.75287209],
                            [39.28807913, -7.75272961],
                            [39.2885065, -7.75260747],
                            [39.28897456, -7.75248534],
                            [39.28927983, -7.75228178],
                            [39.28960545, -7.75205788],
                            [39.28960545, -7.75165078],
                            [39.28956475, -7.7514065],
                            [39.28976827, -7.75108081],
                            [39.29015493, -7.75099939],
                            [39.29037878, -7.75089761],
                            [39.29039914, -7.75061265],
                            [39.29056195, -7.75024625],
                            [39.29064335, -7.74992055],
                            [39.29086722, -7.74967629],
                            [39.29064335, -7.74949308],
                            [39.29027704, -7.74957452],
                            [39.29003283, -7.74979842],
                            [39.28962581, -7.74996126],
                            [39.28923914, -7.74985947],
                            [39.28893387, -7.74977805],
                            [39.28858792, -7.74937094],
                            [39.28866931, -7.7492081],
                            [39.28852686, -7.74888241],
                            [39.28824195, -7.7488417],
                            [39.28807914, -7.74910631],
                            [39.28787564, -7.74924881],
                            [39.28771283, -7.7490656],
                            [39.28779423, -7.74882134],
                            [39.28769249, -7.74857707],
                            [39.28765177, -7.74839387],
                            [39.28755001, -7.74821068],
                            [39.28738722, -7.74810889],
                            [39.28716336, -7.74810889],
                            [39.28689879, -7.74831244],
                            [39.28669528, -7.74865849],
                            [39.28649177, -7.74847528],
                            [39.28651212, -7.74814961],
                            [39.28651212, -7.74782393],
                            [39.28620685, -7.74757965],
                            [39.2860237, -7.74737609],
                            [39.28584054, -7.74749822],
                            [39.28582019, -7.74782393],
                            [39.28582019, -7.74819032],
                            [39.28579983, -7.74853635],
                            [39.28563704, -7.74878062],
                            [39.28537247, -7.74884169],
                            [39.28512825, -7.74890276],
                            [39.28486369, -7.74900453],
                            [39.28461948, -7.74916736],
                            [39.28445668, -7.74939127],
                            [39.28431421, -7.7495134],
                            [39.28407, -7.7496559],
                            [39.28384614, -7.74981874],
                            [39.28404964, -7.75000195],
                            [39.28427351, -7.75014443],
                            [39.28433457, -7.75057191],
                            [39.28431421, -7.75095866],
                            [39.28451771, -7.75124363],
                            [39.28455843, -7.75144719],
                            [39.28455843, -7.75173218],
                            [39.28470088, -7.75201715],
                            [39.28498578, -7.75232249],
                            [39.28520963, -7.75256674],
                            [39.28520963, -7.75287208],
                            [39.28518929, -7.75307565],
                            [39.28506718, -7.75321813],
                            [39.28488402, -7.75317742],
                            [39.28472121, -7.75329953],
                            [39.28470087, -7.75364558],
                            [39.28470087, -7.75395092],
                            [39.28492473, -7.75431732],
                            [39.28518928, -7.754643],
                            [39.28535209, -7.75494834],
                            [39.28529104, -7.75519262],
                            [39.28498577, -7.7553351],
                            [39.28492473, -7.75559973],
                            [39.28492473, -7.75592541],
                            [39.2847619, -7.75629181],
                            [39.28455841, -7.75639357],
                            [39.28439559, -7.75637323],
                            [39.28419208, -7.75614932],
                            [39.28402928, -7.75614932],
                            [39.28376471, -7.75594575],
                            [39.28354086, -7.75586435],
                            [39.28325595, -7.75580327],
                            [39.28299138, -7.75580327],
                            [39.28280822, -7.75570149],
                            [39.28270647, -7.75525367],
                            [39.28254367, -7.7548262],
                            [39.28238085, -7.75448016],
                            [39.2821977, -7.75419517],
                            [39.28197384, -7.75380843],
                            [39.28177033, -7.75346238],
                            [39.2814447, -7.75297383],
                            [39.28126156, -7.75268887],
                            [39.28118017, -7.75226139],
                            [39.28101735, -7.75173216],
                            [39.2807935, -7.75138611],
                            [39.28073244, -7.75099935],
                            [39.28058998, -7.75053117],
                            [39.28044754, -7.75016478],
                            [39.28063069, -7.74998159],
                            [39.28073245, -7.75024621],
                            [39.28093595, -7.75014441],
                            [39.28111912, -7.7501037],
                            [39.28128192, -7.75018513],
                            [39.28154649, -7.75018513],
                            [39.28177034, -7.75022585],
                            [39.28209595, -7.75026657],
                            [39.28231982, -7.75022586],
                            [39.28248263, -7.75010371],
                            [39.28231982, -7.74992052],
                            [39.2821163, -7.7496966],
                            [39.28187211, -7.74947268],
                            [39.2815465, -7.7493302],
                            [39.28134297, -7.74910629],
                            [39.28111912, -7.74888238],
                            [39.28097666, -7.74876024],
                            [39.2807528, -7.74859741],
                            [39.28079351, -7.74839384],
                            [39.28105807, -7.74831242],
                            [39.28130227, -7.74847526],
                            [39.2815465, -7.74853634],
                            [39.28183141, -7.74847526],
                            [39.2820756, -7.74841421],
                            [39.28231983, -7.74825136],
                            [39.28256404, -7.74819031],
                            [39.28284896, -7.74819031],
                            [39.28305246, -7.74831243],
                            [39.28315422, -7.74849565],
                            [39.28335772, -7.74878061],
                            [39.28360195, -7.7487399],
                            [39.28380544, -7.7487399],
                            [39.2839479, -7.74857706],
                            [39.28404965, -7.74825137],
                            [39.28388686, -7.74800711],
                            [39.28380544, -7.74766106],
                            [39.28380545, -7.74737608],
                            [39.2836223, -7.74715218],
                            [39.28360196, -7.74682648],
                            [39.2837444, -7.74654151],
                            [39.28404966, -7.74639901],
                            [39.28419212, -7.74621582],
                            [39.28449738, -7.74607333],
                            [39.284823, -7.74607333],
                            [39.28514862, -7.74607333],
                            [39.28539283, -7.74605297],
                            [39.28553529, -7.7459512],
                            [39.28553529, -7.74562552],
                            [39.28563705, -7.74540161],
                            [39.2856574, -7.74509628],
                            [39.28594232, -7.74489271],
                            [39.28612547, -7.74460773],
                            [39.28647143, -7.74460774],
                            [39.2867767, -7.74464845],
                            [39.28695985, -7.74448561],
                            [39.28720407, -7.74442455],
                            [39.28755003, -7.74464845],
                            [39.28787565, -7.74483166],
                            [39.28814022, -7.74485201],
                            [39.28826231, -7.74507592],
                            [39.28832337, -7.7453609],
                            [39.28836408, -7.74564587],
                            [39.2886591, -7.74579722],
                            [39.2899133, -7.7458377],
                            [39.29132931, -7.74595909],
                            [39.29258351, -7.74595909],
                            [39.29387816, -7.7459591],
                            [39.29476822, -7.74604005],
                            [39.295456, -7.74620193],
                            [39.29634608, -7.74628285],
                            [39.29703386, -7.74628286],
                            [39.29796439, -7.74640427],
                            [39.29814064, -7.74629209],
                            [39.29885445, -7.74583773],
                            [39.29958269, -7.74551399],
                            [39.30006819, -7.7452712],
                            [39.30075596, -7.74490701],
                            [39.30144376, -7.74429999],
                            [39.30164605, -7.74357159],
                            [39.30213155, -7.74312646],
                            [39.30249566, -7.74268134],
                            [39.30298115, -7.74211479],
                            [39.303143, -7.74162919],
                            [39.3029407, -7.74086034],
                            [39.30253612, -7.74065799],
                            [39.30180789, -7.74057707],
                            [39.30132239, -7.74037473],
                            [39.30107965, -7.74001052],
                            [39.30063462, -7.73964633],
                            [39.30039187, -7.73928212],
                            [39.30039187, -7.73855372],
                            [39.30031096, -7.73798717],
                            [39.30031096, -7.73738017],
                            [39.30002775, -7.7368541],
                            [39.30002775, -7.7363685],
                            [39.30002776, -7.73631371],
                            [39.3005096, -7.7360542],
                            [39.30062888, -7.73541525],
                            [39.29960683, -7.73392444],
                            [39.29913838, -7.73324287],
                            [39.29947908, -7.73217802],
                            [39.29926615, -7.73128351],
                            [39.29858479, -7.7305168],
                            [39.29820151, -7.72983528],
                            [39.29747756, -7.72957967],
                            [39.29722205, -7.72885558],
                            [39.29624256, -7.72817404],
                            [39.29585932, -7.72719434],
                            [39.29470951, -7.72574612],
                            [39.29377263, -7.72485162],
                            [39.29275056, -7.72425525],
                            [39.29202663, -7.72340334],
                            [39.29134527, -7.72225328],
                            [39.29057872, -7.72127356],
                            [39.29011025, -7.7202939],
                            [39.28955666, -7.71965495],
                            [39.28870493, -7.71948455],
                            [39.28721446, -7.71841969],
                            [39.28593688, -7.71752519],
                            [39.2855962, -7.71697143],
                            [39.28503355, -7.71688258],
                            [39.28478708, -7.71684365],
                            [39.28440382, -7.71628989],
                            [39.28446102, -7.71604679],
                            [39.28457369, -7.71556774],
                            [39.28503417, -7.71495359],
                            [39.28538308, -7.71436738],
                            [39.28562029, -7.71401842],
                            [39.28589936, -7.7139626],
                            [39.28627613, -7.71393469],
                            [39.28648545, -7.71359971],
                            [39.28662502, -7.71313911],
                            [39.28649944, -7.71262265],
                            [39.28617846, -7.71248309],
                            [39.28615054, -7.71223186],
                            [39.28634593, -7.71206438],
                            [39.28644358, -7.71189688],
                            [39.28627614, -7.71167354],
                            [39.28634593, -7.71138044],
                            [39.28638779, -7.71097567],
                            [39.28619241, -7.71072441],
                            [39.28630406, -7.71057091],
                            [39.28630406, -7.71024988],
                            [39.28626221, -7.71001259],
                            [39.28635987, -7.70977533],
                            [39.28624826, -7.70951012],
                            [39.28622035, -7.70924493],
                            [39.28622035, -7.70881224],
                            [39.28612267, -7.70839351],
                            [39.28619242, -7.70818415],
                            [39.28603894, -7.70794684],
                            [39.28603894, -7.7077096],
                            [39.28608082, -7.70741648],
                            [39.28616455, -7.70715127],
                            [39.28610869, -7.70685817],
                            [39.28592733, -7.70641153],
                            [39.2858436, -7.70599279],
                            [39.28598311, -7.70557407],
                            [39.28623429, -7.70525305],
                            [39.2865692, -7.70486224],
                            [39.28695997, -7.70452726],
                            [39.28715532, -7.70440164],
                            [39.28709948, -7.70405269],
                            [39.28682038, -7.70380145],
                            [39.2865832, -7.70357814],
                            [39.28626223, -7.70322917],
                            [39.28582966, -7.70285233],
                            [39.28573196, -7.70265694],
                            [39.28584361, -7.7024336],
                            [39.28595525, -7.70222423],
                            [39.28605292, -7.70211256],
                            [39.28635991, -7.70225216],
                            [39.28642969, -7.70188927],
                            [39.2864855, -7.70154033],
                            [39.28641577, -7.70100993],
                            [39.28655529, -7.70078659],
                            [39.28689019, -7.70078659],
                            [39.2873228, -7.70080057],
                            [39.28768558, -7.70080057],
                            [39.28792282, -7.70059122],
                            [39.28797866, -7.70035392],
                            [39.28795076, -7.7000887],
                            [39.28792282, -7.69985146],
                            [39.28803449, -7.69966999],
                            [39.28810425, -7.69951647],
                            [39.28811821, -7.69932106],
                            [39.28799262, -7.69916753],
                            [39.28788097, -7.69894421],
                            [39.28804842, -7.69884649],
                            [39.28835543, -7.69884649],
                            [39.28866242, -7.69867899],
                            [39.28877406, -7.69846964],
                            [39.28857593, -7.69830216],
                            [39.28843634, -7.69805092],
                            [39.28864566, -7.69796717],
                            [39.2889527, -7.69795322],
                            [39.28945504, -7.69784156],
                            [39.28977601, -7.69775781],
                            [39.28987368, -7.69746471],
                            [39.28999927, -7.6972693],
                            [39.29018068, -7.69740889],
                            [39.29040395, -7.6972693],
                            [39.29030627, -7.69690642],
                            [39.29029229, -7.6965714],
                            [39.29026442, -7.69609686],
                            [39.29030628, -7.69572001],
                            [39.29030628, -7.69534316],
                            [39.29027837, -7.69496631],
                            [39.29033417, -7.69454757],
                            [39.29050162, -7.69436612],
                            [39.29068305, -7.69468715],
                            [39.29087838, -7.69503607],
                            [39.2911017, -7.69523149],
                            [39.29129705, -7.69510587],
                            [39.29138078, -7.69481277],
                            [39.29160404, -7.69446383],
                            [39.29161798, -7.69408695],
                            [39.29154824, -7.69382177],
                            [39.29149239, -7.69358449],
                            [39.29163197, -7.69340305],
                            [39.29193896, -7.69331931],
                            [39.29200875, -7.69299828],
                            [39.29186916, -7.69264934],
                            [39.29198082, -7.69237019],
                            [39.29213434, -7.69218874],
                            [39.2925669, -7.69214687],
                            [39.29287392, -7.69199333],
                            [39.29294366, -7.69175605],
                            [39.29288786, -7.69150482],
                            [39.29284601, -7.69133733],
                            [39.29313904, -7.69119776],
                            [39.29323671, -7.69098839],
                            [39.29301346, -7.69077899],
                            [39.29291579, -7.69047195],
                            [39.29301346, -7.69020676],
                            [39.29325071, -7.69002531],
                            [39.29339024, -7.68974616],
                            [39.29336231, -7.68942513],
                            [39.29349258, -7.68930883],
                            [39.29374064, -7.68930883],
                            [39.29403836, -7.68918473],
                            [39.29398875, -7.68881255],
                            [39.29413758, -7.68858922],
                            [39.29413758, -7.68826666],
                            [39.29401357, -7.68804332],
                            [39.29413758, -7.68786963],
                            [39.29448489, -7.68769594],
                            [39.29485699, -7.68734855],
                            [39.29512992, -7.68707561],
                            [39.29527876, -7.68672822],
                            [39.295502, -7.68648008],
                            [39.29589894, -7.68643046],
                            [39.29629589, -7.68625676],
                            [39.29661836, -7.6858101],
                            [39.29654395, -7.68509053],
                            [39.29691609, -7.68471831],
                            [39.29716415, -7.68409797],
                            [39.29716415, -7.6834032],
                            [39.29751148, -7.68258436],
                            [39.2976107, -7.68216254],
                            [39.29751149, -7.68188956],
                            [39.29767954, -7.68142261],
                            [39.29773475, -7.68126923],
                            [39.29783401, -7.68017743],
                            [39.29768513, -7.67950748],
                            [39.29711456, -7.67925935],
                            [39.29696572, -7.67881271],
                            [39.29726343, -7.67829161],
                            [39.29790845, -7.67801867],
                            [39.29847904, -7.67794421],
                            [39.29907443, -7.67762167],
                            [39.29927286, -7.6769517],
                            [39.29927286, -7.6764058],
                            [39.29914884, -7.67571098],
                            [39.2987767, -7.67501623],
                            [39.2989504, -7.67454475],
                            [39.29949617, -7.67471847],
                            [39.29944655, -7.67414777],
                            [39.29882633, -7.6733041],
                            [39.29835501, -7.67270856],
                            [39.2986527, -7.67233634],
                            [39.2989256, -7.67176564],
                            [39.29894546, -7.67104607],
                            [39.29889582, -7.67025203],
                            [39.29892066, -7.66891208],
                            [39.29892066, -7.66809326],
                            [39.29869669, -7.66746606],
                            [39.29854852, -7.66705107],
                            [39.29802756, -7.66613296],
                            [39.29743217, -7.66519008],
                            [39.29651425, -7.66449525],
                            [39.29589408, -7.66414788],
                            [39.29539794, -7.6639742],
                            [39.2949514, -7.66402383],
                            [39.29451162, -7.66392825],
                            [39.29451162, -7.66292053],
                            [39.29445349, -7.66216474],
                            [39.29437601, -7.66131206],
                            [39.29439539, -7.66045939],
                            [39.29418224, -7.66003301],
                            [39.29389161, -7.65956791],
                            [39.29383351, -7.65929661],
                            [39.29340726, -7.65857958],
                            [39.29288414, -7.65799819],
                            [39.29216728, -7.65759125],
                            [39.29148915, -7.65741681],
                            [39.29083042, -7.65718425],
                            [39.29005542, -7.65706797],
                            [39.28904793, -7.6569517],
                            [39.2881567, -7.65671913],
                            [39.28747083, -7.65621529],
                            [39.28714144, -7.65544011],
                            [39.28681209, -7.65481996],
                            [39.28690896, -7.65449056],
                            [39.28692833, -7.65373473],
                            [39.28687023, -7.65307587],
                            [39.28646334, -7.65232004],
                            [39.28607585, -7.65160302],
                            [39.28537836, -7.65036275],
                            [39.28470025, -7.64962637],
                            [39.28363463, -7.64826978],
                            [39.28254965, -7.64697138],
                            [39.28158092, -7.6456342],
                            [39.28094156, -7.64487844],
                            [39.28039905, -7.64443269],
                            [39.27997214, -7.64419134],
                            [39.27995342, -7.64418076],
                            [39.2799481, -7.64413646],
                            [39.27989531, -7.64369627],
                            [39.27989531, -7.64323117],
                            [39.27981782, -7.64263042],
                            [39.27966283, -7.6421847],
                            [39.27933345, -7.64175838],
                            [39.27890722, -7.64100258],
                            [39.27882971, -7.64034369],
                            [39.27830659, -7.63974294],
                            [39.27801597, -7.63883208],
                            [39.27774472, -7.63792127],
                            [39.27725647, -7.63716547],
                            [39.27721771, -7.63664225],
                            [39.27712087, -7.63586706],
                            [39.27684962, -7.6350725],
                            [39.27636525, -7.63443299],
                            [39.27619087, -7.63394853],
                            [39.27617152, -7.63292141],
                            [39.27607463, -7.63245631],
                            [39.27557091, -7.63197183],
                            [39.27498749, -7.63164359],
                            [39.27495088, -7.63162299],
                            [39.27417592, -7.63142922],
                            [39.27380778, -7.63106103],
                            [39.27378842, -7.63071218],
                            [39.27402093, -7.63011144],
                            [39.27429215, -7.62949131],
                            [39.2742934, -7.62948559],
                            [39.27442779, -7.62887115],
                            [39.27425343, -7.6284642],
                            [39.27386435, -7.6280451],
                            [39.27374967, -7.62792157],
                            [39.27343968, -7.62753398],
                            [39.27347843, -7.62708826],
                            [39.2735575, -7.62689056],
                            [39.27371094, -7.62650691],
                            [39.27388532, -7.62600304],
                            [39.27371094, -7.62549916],
                            [39.27340093, -7.62495658],
                            [39.27311033, -7.6243752],
                            [39.27299407, -7.62377443],
                            [39.27289721, -7.62261167],
                            [39.27278096, -7.62170085],
                            [39.27258722, -7.62131326],
                            [39.27239346, -7.62123873],
                            [39.27233531, -7.62121636],
                            [39.27187035, -7.6213908],
                            [39.27154097, -7.6214489],
                            [39.27152161, -7.62121636],
                            [39.2718316, -7.62090632],
                            [39.27227722, -7.62063497],
                            [39.27230354, -7.62051654],
                            [39.27239347, -7.62011175],
                            [39.2722966, -7.61956913],
                            [39.27223614, -7.61933273],
                            [39.27208347, -7.61873584],
                            [39.27198662, -7.61803816],
                            [39.27194786, -7.61737927],
                            [39.27179285, -7.61650724],
                            [39.27167663, -7.61524756],
                            [39.27163789, -7.61367785],
                            [39.27157975, -7.61282518],
                            [39.27138603, -7.61181744],
                            [39.27146351, -7.61077095],
                            [39.27157976, -7.60991829],
                            [39.27157977, -7.60889119],
                            [39.2718704, -7.60772843],
                            [39.27212228, -7.60718582],
                            [39.27221913, -7.60641064],
                            [39.27214165, -7.60579053],
                            [39.27190914, -7.60520915],
                            [39.27115355, -7.6046665],
                            [39.27063042, -7.60418202],
                            [39.2704948, -7.60369754],
                            [39.27041731, -7.60313556],
                            [39.27006854, -7.60267044],
                            [39.27016543, -7.60222475],
                            [39.27024421, -7.60219952],
                            [39.2706498, -7.6020697],
                            [39.27107607, -7.60177901],
                            [39.27136668, -7.6012364],
                            [39.27171542, -7.60081005],
                            [39.27192855, -7.60042248],
                            [39.27192856, -7.60009301],
                            [39.27216106, -7.59972482],
                            [39.27225792, -7.59929848],
                            [39.27188981, -7.59916281],
                            [39.2716573, -7.59893026],
                            [39.2717348, -7.59854268],
                            [39.27210295, -7.59823261],
                            [39.27231606, -7.59803885],
                            [39.27268418, -7.59780629],
                            [39.27260668, -7.59751558],
                            [39.27218043, -7.59732181],
                            [39.27185107, -7.59708927],
                            [39.27185107, -7.59672106],
                            [39.27208356, -7.59643035],
                            [39.27235482, -7.59606214],
                            [39.27225793, -7.59586835],
                            [39.27198671, -7.59584897],
                            [39.27204482, -7.5956552],
                            [39.27225794, -7.59548077],
                            [39.27231608, -7.59522883],
                            [39.2724517, -7.59488002],
                            [39.2725292, -7.59441491],
                            [39.27223858, -7.59398858],
                            [39.27223858, -7.59363977],
                            [39.27229671, -7.59321339],
                            [39.27251706, -7.5928199],
                            [39.27272349, -7.59249275],
                            [39.27316545, -7.59179233],
                            [39.2736677, -7.59099311],
                            [39.27378185, -7.59037657],
                            [39.27380468, -7.58909782],
                            [39.27382751, -7.58779624],
                            [39.27382752, -7.58714838],
                            [39.27382752, -7.58642614],
                            [39.27407867, -7.5854214],
                            [39.27423847, -7.5844852],
                            [39.2742613, -7.58359461],
                            [39.27430697, -7.58261271],
                            [39.2745581, -7.58167651],
                            [39.2747179, -7.57991821],
                            [39.27480923, -7.57895914],
                            [39.27503754, -7.57758905],
                            [39.27519735, -7.57672132],
                            [39.27531149, -7.57553392],
                            [39.2754713, -7.57432368],
                            [39.27572245, -7.57299924],
                            [39.27590507, -7.57208585],
                            [39.27604207, -7.57146932],
                            [39.2763617, -7.57064725],
                            [39.27640734, -7.57030473],
                            [39.27636171, -7.56920865],
                            [39.27649869, -7.56818112],
                            [39.27663566, -7.56742754],
                            [39.27695529, -7.56671966],
                            [39.27716075, -7.56573776],
                            [39.27732056, -7.56482439],
                            [39.2775717, -7.56404799],
                            [39.27798262, -7.56324877],
                            [39.27816527, -7.56238104],
                            [39.27834789, -7.561696],
                            [39.27871318, -7.56098812],
                            [39.27907846, -7.56034875],
                            [39.27937527, -7.55961804],
                            [39.27976337, -7.55886449],
                            [39.28026562, -7.55767707],
                            [39.28065373, -7.55648966],
                            [39.28090485, -7.55564477],
                            [39.281156, -7.55489124],
                            [39.28149844, -7.55388648],
                            [39.28209202, -7.55304161],
                            [39.28268559, -7.55265341],
                            [39.28309653, -7.55199121],
                            [39.28343899, -7.55123765],
                            [39.28378141, -7.55021007],
                            [39.28395066, -7.54940608],
                            [39.28396408, -7.54934236],
                            [39.28397533, -7.5492467],
                            [39.28392504, -7.54929837],
                            [39.28371103, -7.54839937],
                            [39.2839459, -7.54848328],
                            [39.2843052, -7.54764477],
                            [39.28466447, -7.54680625],
                            [39.28514351, -7.54488968],
                            [39.28526329, -7.54369182],
                            [39.28574232, -7.54261374],
                            [39.28646089, -7.54141588],
                            [39.28729921, -7.53973886],
                            [39.2876585, -7.53758271],
                            [39.2888561, -7.53518699],
                            [39.28921537, -7.53374955],
                            [39.29029323, -7.53183296],
                            [39.29101179, -7.53015597],
                            [39.29185012, -7.52788002],
                            [39.29244892, -7.52584365],
                            [39.29304773, -7.52416664],
                            [39.29352678, -7.52213027],
                            [39.29400583, -7.52069284],
                            [39.29436511, -7.51805754],
                            [39.29484414, -7.51602117],
                            [39.29496393, -7.51422438],
                            [39.29496393, -7.51254735],
                            [39.29556273, -7.51027141],
                            [39.2956825, -7.50931313],
                            [39.2956839, -7.50930049],
                            [39.29592203, -7.50715698],
                            [39.29580228, -7.5042821],
                            [39.29597785, -7.50322836],
                            [39.29604179, -7.50284464],
                            [39.29628132, -7.50104786],
                            [39.29699991, -7.49973019],
                            [39.2972394, -7.49865213],
                            [39.29711966, -7.49697512],
                            [39.29747894, -7.49491478],
                            [39.29747895, -7.49287841],
                            [39.29759873, -7.49084204],
                            [39.29771848, -7.48916503],
                            [39.29795801, -7.48724846],
                            [39.2983173, -7.48545165],
                            [39.29843707, -7.48401421],
                            [39.2989161, -7.48209763],
                            [39.29963468, -7.48066019],
                            [39.299799, -7.47950974],
                            [39.29987419, -7.47898319],
                            [39.30035324, -7.47646766],
                            [39.30043176, -7.47497561],
                            [39.30047301, -7.47419174],
                            [39.30052631, -7.4740318],
                            [39.30095206, -7.47275429],
                            [39.30143111, -7.4709575],
                            [39.30191015, -7.46963984],
                            [39.30202993, -7.4683222],
                            [39.30250896, -7.46688475],
                            [39.30298801, -7.46496816],
                            [39.30358681, -7.46353072],
                            [39.30418562, -7.46149436],
                            [39.30454489, -7.45981737],
                            [39.3051437, -7.45826013],
                            [39.305438, -7.45745065],
                            [39.30562275, -7.45694248],
                            [39.30598203, -7.45550505],
                            [39.3067006, -7.45318119],
                            [39.3078982, -7.45078546],
                            [39.30825749, -7.44898867],
                            [39.30921557, -7.44719187],
                            [39.31017366, -7.44503572],
                            [39.31089222, -7.44299935],
                            [39.31185031, -7.44024427],
                            [39.31316767, -7.43784854],
                            [39.31364671, -7.43677046],
                            [39.31424552, -7.43569238],
                            [39.31508384, -7.43425495],
                            [39.31568264, -7.43305709],
                            [39.31621698, -7.43210696],
                            [39.31676049, -7.43114051],
                            [39.31771857, -7.42970307],
                            [39.31855689, -7.42814585],
                            [39.31987425, -7.42730735],
                            [39.32131137, -7.42718757],
                            [39.32262872, -7.42718757],
                            [39.32370656, -7.42718758],
                            [39.32502391, -7.42682822],
                            [39.32586223, -7.42622929],
                            [39.32658079, -7.42575015],
                            [39.32825743, -7.4253908],
                            [39.32895169, -7.42521719],
                            [39.3292155, -7.42515123],
                            [39.3304131, -7.4245523],
                            [39.33083851, -7.42423317],
                            [39.33127147, -7.42447377],
                            [39.33281562, -7.42408765],
                            [39.33474579, -7.42383024],
                            [39.33693333, -7.42331542],
                            [39.33886351, -7.42241448],
                            [39.34027898, -7.42189965],
                            [39.34182312, -7.42087],
                            [39.34336727, -7.41971164],
                            [39.34426803, -7.41790974],
                            [39.34491142, -7.41675137],
                            [39.34555482, -7.41559301],
                            [39.34555483, -7.41404852],
                            [39.34529748, -7.41224661],
                            [39.34452541, -7.41018729],
                            [39.34401071, -7.40890021],
                            [39.34378951, -7.40865437],
                            [39.34328703, -7.40809594],
                            [39.3428526, -7.40761313],
                            [39.34285261, -7.40619735],
                            [39.34336733, -7.40516769],
                            [39.34439676, -7.4034945],
                            [39.34504016, -7.40169259],
                            [39.34616608, -7.40011597],
                            [39.34632695, -7.39989069],
                            [39.34697035, -7.39873233],
                            [39.34760999, -7.39809254],
                            [39.34761375, -7.39808879],
                            [39.34890053, -7.39654431],
                            [39.34992997, -7.39525724],
                            [39.35121676, -7.39306921],
                            [39.35147413, -7.39139602],
                            [39.35211753, -7.38895058],
                            [39.35211754, -7.38663384],
                            [39.35147416, -7.38457452],
                            [39.35095945, -7.38251519],
                            [39.35083078, -7.38019846],
                            [39.35072058, -7.37843469],
                            [39.35070211, -7.37813914],
                            [39.35070212, -7.37595111],
                            [39.35134551, -7.37517887],
                            [39.35250362, -7.37466404],
                            [39.35134552, -7.37381457],
                            [39.35065783, -7.37329868],
                            [39.35031609, -7.37304232],
                            [39.34941535, -7.37149782],
                            [39.349158, -7.37085429],
                            [39.34851462, -7.36699306],
                            [39.34877198, -7.36544857],
                            [39.34877199, -7.36338925],
                            [39.34902935, -7.36223088],
                            [39.34915804, -7.35978544],
                            [39.34941541, -7.35746871],
                            [39.34980145, -7.35360748],
                            [39.35005882, -7.35180558],
                            [39.35044486, -7.35013238],
                            [39.3514743, -7.34794436],
                            [39.35186034, -7.34665728],
                            [39.35237506, -7.34459797],
                            [39.35327582, -7.34253865],
                            [39.35430526, -7.34060804],
                            [39.35499249, -7.33906142],
                            [39.35533469, -7.33829131],
                            [39.35584941, -7.33739036],
                            [39.35649281, -7.33571716],
                            [39.35713621, -7.33430138],
                            [39.35777961, -7.33262819],
                            [39.35803697, -7.33069758],
                            [39.35868037, -7.32889568],
                            [39.35906642, -7.32696506],
                            [39.35777963, -7.32645023],
                            [39.35662153, -7.32567798],
                            [39.35610682, -7.32451961],
                            [39.35559211, -7.32374736],
                            [39.35533475, -7.32297511],
                            [39.35470361, -7.32223861],
                            [39.35456269, -7.32207416],
                            [39.35353326, -7.32130191],
                            [39.35195475, -7.32047487],
                            [39.35224808, -7.32029883],
                            [39.35330801, -7.31973341],
                            [39.35436794, -7.31959207],
                            [39.35571052, -7.32001613],
                            [39.35677045, -7.32036953],
                            [39.35689074, -7.32042109],
                            [39.35775971, -7.3207936],
                            [39.35860765, -7.32121767],
                            [39.36023288, -7.3217831],
                            [39.36086883, -7.32213649],
                            [39.36171678, -7.32220717],
                            [39.36255464, -7.32242772],
                            [39.36305935, -7.32256057],
                            [39.36383663, -7.3227726],
                            [39.36461391, -7.323126],
                            [39.36574451, -7.32241922],
                            [39.36614004, -7.32178624],
                            [39.36645113, -7.32128838],
                            [39.36694577, -7.32008686],
                            [39.36751107, -7.31817856],
                            [39.36793505, -7.31697703],
                            [39.36842969, -7.31577551],
                            [39.36948962, -7.31478602],
                            [39.3699136, -7.31372586],
                            [39.37040823, -7.31252433],
                            [39.37069089, -7.31125213],
                            [39.37062023, -7.31012129],
                            [39.3706909, -7.30944278],
                            [39.37043174, -7.30913172],
                            [39.37033759, -7.30901871],
                            [39.36948965, -7.30845328],
                            [39.36892435, -7.30767582],
                            [39.36857104, -7.30703972],
                            [39.36906569, -7.30569684],
                            [39.36963099, -7.30400058],
                            [39.3699843, -7.30286973],
                            [39.37012563, -7.30173888],
                            [39.37054961, -7.30053736],
                            [39.37139756, -7.29834635],
                            [39.37182154, -7.29707415],
                            [39.37217485, -7.29615534],
                            [39.37266949, -7.29516585],
                            [39.3730228, -7.29424704],
                            [39.37344677, -7.29346958],
                            [39.37401207, -7.29255077],
                            [39.37436539, -7.29141993],
                            [39.37464357, -7.29108603],
                            [39.3747187, -7.29099586],
                            [39.37521334, -7.28986502],
                            [39.37556665, -7.28908756],
                            [39.37606129, -7.28816875],
                            [39.37655593, -7.28689655],
                            [39.37705056, -7.28626045],
                            [39.37747454, -7.28541232],
                            [39.37789851, -7.28436629],
                            [39.37853448, -7.28351815],
                            [39.37881712, -7.28309409],
                            [39.37945308, -7.28238731],
                            [39.37945309, -7.28175121],
                            [39.37987706, -7.28118579],
                            [39.38008905, -7.28047901],
                            [39.38008905, -7.27998426],
                            [39.38001839, -7.27970155],
                            [39.37994773, -7.27927748],
                            [39.37994773, -7.27892409],
                            [39.3800184, -7.27871206],
                            [39.3801759, -7.27801949],
                            [39.38039958, -7.27824322],
                            [39.3816893, -7.27901723],
                            [39.38239864, -7.27972674],
                            [39.38252762, -7.28011374],
                            [39.38265658, -7.28037175],
                            [39.3830435, -7.27972674],
                            [39.38323697, -7.27869473],
                            [39.38375286, -7.27817873],
                            [39.38478464, -7.27772723],
                            [39.38549398, -7.27721123],
                            [39.38633231, -7.27617922],
                            [39.38665474, -7.27546971],
                            [39.3868482, -7.27501821],
                            [39.38710615, -7.27450221],
                            [39.38749306, -7.27398621],
                            [39.38787998, -7.2734702],
                            [39.3882669, -7.2727607],
                            [39.38884728, -7.27179319],
                            [39.38916971, -7.27121268],
                            [39.38944796, -7.27061631],
                            [39.38962112, -7.27024518],
                            [39.39013701, -7.26953567],
                            [39.39071739, -7.26856816],
                            [39.39155571, -7.26721365],
                            [39.39220058, -7.26656865],
                            [39.39284544, -7.26579465],
                            [39.39355479, -7.26463364],
                            [39.39432862, -7.26398863],
                            [39.39478003, -7.26295663],
                            [39.3954249, -7.26205362],
                            [39.39606976, -7.26121512],
                            [39.39639219, -7.26076361],
                            [39.39710154, -7.26031211],
                            [39.3981978, -7.25953811],
                            [39.3987137, -7.25882861],
                            [39.39929408, -7.2579901],
                            [39.39955202, -7.25740959],
                            [39.39980997, -7.25685489],
                            [39.40000343, -7.25633889],
                            [39.40045483, -7.25550038],
                            [39.40122867, -7.25453287],
                            [39.40174456, -7.25369437],
                            [39.40232494, -7.25304936],
                            [39.40284083, -7.25233986],
                            [39.4030875, -7.25166138],
                            [39.40309878, -7.25163035],
                            [39.40342121, -7.25072735],
                            [39.40374365, -7.24988884],
                            [39.40398358, -7.24944315],
                            [39.40419505, -7.24905034],
                            [39.40477543, -7.24821183],
                            [39.40542029, -7.24750233],
                            [39.40625861, -7.24692183],
                            [39.40647687, -7.24681267],
                            [39.40741937, -7.24634132],
                            [39.40765583, -7.24589786],
                            [39.40791411, -7.24602703],
                            [39.4086524, -7.24611934],
                            [39.40894359, -7.2464106],
                            [39.40920611, -7.24667319],
                            [39.40957526, -7.2468578],
                            [39.40994439, -7.24750395],
                            [39.40994439, -7.24833471],
                            [39.4105904, -7.24842702],
                            [39.41114411, -7.24842702],
                            [39.41160554, -7.24861164],
                            [39.41225155, -7.24870395],
                            [39.41326669, -7.24870395],
                            [39.41382041, -7.24842703],
                            [39.41465099, -7.24815011],
                            [39.41483556, -7.2479655],
                            [39.415297, -7.24750397],
                            [39.41631215, -7.24685783],
                            [39.41713713, -7.24630771],
                            [39.41714272, -7.24630398],
                            [39.41715652, -7.24627471],
                            [39.41880389, -7.24277787],
                            [39.41902157, -7.24243572],
                            [39.4200959, -7.24074712],
                            [39.42111106, -7.23899329],
                            [39.42203393, -7.23677793],
                            [39.42267994, -7.23474717],
                            [39.42314137, -7.23336257],
                            [39.42323367, -7.23225489],
                            [39.42332596, -7.2311472],
                            [39.42351054, -7.22994721],
                            [39.42351054, -7.22893184],
                            [39.42369512, -7.22773184],
                            [39.42362254, -7.22722369],
                            [39.42369512, -7.22719646],
                            [39.42412067, -7.22352265],
                            [39.42444882, -7.21859961],
                            [39.4246129, -7.21400477],
                            [39.4251051, -7.21121505],
                            [39.42592544, -7.20875353],
                            [39.42690984, -7.20547151],
                            [39.42723797, -7.2033382],
                            [39.42773018, -7.20104078],
                            [39.42920677, -7.19759466],
                            [39.43068336, -7.19382033],
                            [39.43150369, -7.19135881],
                            [39.43265215, -7.18938961],
                            [39.43330841, -7.1882409],
                            [39.43388848, -7.18708051],
                            [39.43412874, -7.18659989],
                            [39.4352772, -7.18479478],
                            [39.43626159, -7.18364607],
                            [39.43724597, -7.18249737],
                            [39.43823037, -7.18134866],
                            [39.43909523, -7.18033944],
                            [39.43916444, -7.18025868],
                            [39.4391649, -7.18025866],
                            [39.44538035, -7.17730967],
                            [39.44535467, -7.17726259],
                            [39.4454118, -7.17726888],
                            [39.44956637, -7.17360904],
                            [39.45804362, -7.16828317],
                            [39.46336175, -7.16412354],
                            [39.46901428, -7.16179141],
                            [39.47400251, -7.16045692],
                            [39.47998776, -7.15829048],
                            [39.48497714, -7.15811908],
                            [39.48652161, -7.15879224],
                            [39.48763947, -7.15927946],
                            [39.48913846, -7.16127192],
                            [39.49313674, -7.16741597],
                            [39.49580346, -7.1725644],
                            [39.49880126, -7.17621691],
                            [39.49963415, -7.1773792],
                            [39.5019627, -7.17737665],
                            [39.50462152, -7.17521352],
                            [39.50778039, -7.17404684],
                            [39.50894225, -7.17188534],
                            [39.50960403, -7.16872737],
                            [39.51342683, -7.16639672],
                            [39.5155875, -7.16506494],
                            [39.51608141, -7.16057778],
                            [39.51718699, -7.1581404],
                            [39.51774048, -7.15692017],
                            [39.51726514, -7.1552179],
                            [39.51690552, -7.15393004],
                            [39.51490899, -7.1532676],
                            [39.51407499, -7.15110832],
                            [39.51422742, -7.15026532],
                            [39.51440557, -7.14928007],
                            [39.51423591, -7.14628919],
                            [39.51452389, -7.14476935],
                            [39.51489726, -7.14279887],
                            [39.51821995, -7.13963791],
                            [39.52021249, -7.13681077],
                            [39.52107375, -7.13602668],
                            [39.52204001, -7.135147],
                            [39.52303617, -7.13365034],
                            [39.52286609, -7.13032712],
                            [39.52204206, -7.12672325],
                            [39.52203041, -7.12667232],
                            [39.52462072, -7.12278046],
                            [39.52501882, -7.12218233],
                            [39.52917204, -7.11835567],
                            [39.53113618, -7.11756803],
                            [39.53498995, -7.1160226],
                            [39.54114312, -7.11601544],
                            [39.54347095, -7.11568037],
                            [39.5424708, -7.11368751],
                            [39.54030578, -7.11103132],
                            [39.54013579, -7.10787428],
                            [39.54020262, -7.10763472],
                            [39.54129502, -7.10371868],
                            [39.54212378, -7.10139133],
                            [39.54212274, -7.10050639],
                            [39.54211989, -7.09806793],
                            [39.54120915, -7.09189947],
                            [39.54111416, -7.09125612],
                            [39.54127289, -7.0847753],
                            [39.54010015, -7.077299],
                            [39.5401548, -7.07695315],
                            [39.54125565, -7.06998616],
                            [39.54175102, -7.06699452],
                            [39.5434125, -7.06582939],
                            [39.54540616, -7.06433153],
                            [39.54634801, -7.06244357],
                            [39.54640151, -7.06233632],
                            [39.54652193, -7.06074758],
                            [39.54656526, -7.06017591],
                            [39.54638534, -7.05971006],
                            [39.54573132, -7.05801667],
                            [39.54600036, -7.0567185],
                            [39.54655804, -7.05402762],
                            [39.54655493, -7.05136889],
                            [39.54656526, -7.05117904],
                            [39.5467177, -7.04837764],
                            [39.54671361, -7.04488806],
                            [39.54621089, -7.04156524],
                            [39.54637269, -7.03774313],
                            [39.54666674, -7.03491972],
                            [39.54670154, -7.03458551],
                            [39.54665199, -7.03404775],
                            [39.54636473, -7.03093015],
                            [39.54685946, -7.02743999],
                            [39.54685458, -7.02326799],
                            [39.54685188, -7.02095935],
                            [39.54821224, -7.01808047],
                            [39.5483446, -7.01780036],
                            [39.54952859, -7.0159752],
                            [39.55050215, -7.01447443],
                            [39.55199482, -7.01131543],
                            [39.55117031, -7.00868932],
                            [39.55116038, -7.00865768],
                            [39.54932642, -7.0043394],
                            [39.54849065, -7.00051845],
                            [39.54837736, -7.00021758],
                            [39.54748999, -6.99786088],
                            [39.54648973, -6.99553566],
                            [39.54499089, -6.99337717],
                            [39.54388367, -6.9910326],
                            [39.54365756, -6.99055381],
                            [39.5436948, -6.98983084],
                            [39.54382017, -6.98739638],
                            [39.54365046, -6.9844055],
                            [39.54314959, -6.98257819],
                            [39.54032136, -6.98091974],
                            [39.53832631, -6.98092202],
                            [39.53616464, -6.98059214],
                            [39.53473018, -6.9805255],
                            [39.53267312, -6.98042993],
                            [39.53067714, -6.97960132],
                            [39.52901237, -6.97760914],
                            [39.52784469, -6.97412084],
                            [39.52684495, -6.9721279],
                            [39.52410172, -6.97054455],
                            [39.52368415, -6.97030354],
                            [39.52335961, -6.96996188],
                            [39.52068816, -6.96714959],
                            [39.51769094, -6.96283242],
                            [39.51552572, -6.959179],
                            [39.51402571, -6.95569103],
                            [39.51371915, -6.95516307],
                            [39.51312384, -6.95413783],
                            [39.51249615, -6.95305683],
                            [39.51219359, -6.95253575],
                            [39.51202572, -6.95104038],
                            [39.51019578, -6.94987915],
                            [39.50853031, -6.94705602],
                            [39.50752751, -6.94207193],
                            [39.50693915, -6.94011912],
                            [39.50652651, -6.93874956],
                            [39.50486235, -6.93708961],
                            [39.50477274, -6.93702774],
                            [39.50374579, -6.93631867],
                            [39.50269965, -6.93559636],
                            [39.502364, -6.93260561],
                            [39.50019941, -6.92928445],
                            [39.50011501, -6.9287399],
                            [39.49986466, -6.92712456],
                            [39.49986186, -6.9244658],
                            [39.49836139, -6.92031306],
                            [39.49736034, -6.91682449],
                            [39.49694186, -6.91578185],
                            [39.49636034, -6.91433294],
                            [39.49519274, -6.91051219],
                            [39.49469059, -6.90718926],
                            [39.49431006, -6.90544812],
                            [39.49385549, -6.90336815],
                            [39.49385281, -6.90079075],
                            [39.49385204, -6.9000447],
                            [39.49384928, -6.89738593],
                            [39.4936675, -6.89540966],
                            [39.49351305, -6.89373048],
                            [39.49301128, -6.89073989],
                            [39.49151219, -6.88775032],
                            [39.49113704, -6.88670359],
                            [39.49067869, -6.88542475],
                            [39.48901444, -6.88343238],
                            [39.48718482, -6.88227104],
                            [39.48568765, -6.88110935],
                            [39.48335923, -6.87978233],
                            [39.48125558, -6.87865265],
                            [39.4811972, -6.8786213],
                            [39.47969906, -6.87646255],
                            [39.47770178, -6.87380577],
                            [39.4771419, -6.87221165],
                            [39.47653495, -6.87048347],
                            [39.47603316, -6.86732667],
                            [39.47560487, -6.86491435],
                            [39.4755317, -6.86450222],
                            [39.47469833, -6.86217662],
                            [39.47353286, -6.86018369],
                            [39.47137127, -6.85935495],
                            [39.46987633, -6.86035346],
                            [39.46921392, -6.86284671],
                            [39.46873645, -6.86540644],
                            [39.46871788, -6.86550598],
                            [39.46722405, -6.86766769],
                            [39.46639313, -6.86783468],
                            [39.4645646, -6.86767028],
                            [39.46400886, -6.86730066],
                            [39.46256872, -6.86634282],
                            [39.4604076, -6.86601255],
                            [39.45774736, -6.86518423],
                            [39.45425511, -6.86335964],
                            [39.45209353, -6.86253082],
                            [39.45159936, -6.86234133],
                            [39.44993196, -6.86170198],
                            [39.44793646, -6.8607068],
                            [39.44627277, -6.85904661],
                            [39.44570603, -6.85835057],
                            [39.44410954, -6.85638983],
                            [39.44327556, -6.85323329],
                            [39.44194143, -6.84841547],
                            [39.44077613, -6.84642245],
                            [39.4394457, -6.84559279],
                            [39.43828223, -6.84559386],
                            [39.43529303, -6.84842154],
                            [39.43396666, -6.85207858],
                            [39.43264087, -6.85640031],
                            [39.43081462, -6.8587284],
                            [39.43041624, -6.85883741],
                            [39.42898671, -6.85922856],
                            [39.42665928, -6.85873212],
                            [39.42549431, -6.8570714],
                            [39.42416343, -6.85574319],
                            [39.42133766, -6.8555795],
                            [39.42081871, -6.85506158],
                            [39.41984044, -6.85408524],
                            [39.4178443, -6.85225906],
                            [39.41634739, -6.85109713],
                            [39.41431363, -6.85061487],
                            [39.41285624, -6.85026927],
                            [39.41069492, -6.84960643],
                            [39.40870167, -6.8511037],
                            [39.4070404, -6.85210217],
                            [39.40521151, -6.85143901],
                            [39.4042127, -6.84961193],
                            [39.40255017, -6.8491148],
                            [39.40252791, -6.8491021],
                            [39.40022211, -6.84778734],
                            [39.39839186, -6.84546241],
                            [39.39755972, -6.8441337],
                            [39.39556466, -6.84347064],
                            [39.39340367, -6.84314006],
                            [39.39024502, -6.84231176],
                            [39.38891454, -6.84131579],
                            [39.38808484, -6.84297821],
                            [39.38725568, -6.84530534],
                            [39.38459752, -6.84680305],
                            [39.38412821, -6.8476257],
                            [39.38190252, -6.84776749],
                            [39.38057957, -6.84796286],
                            [39.37885021, -6.84821825],
                            [39.37761318, -6.84846734],
                            [39.37528741, -6.84893566],
                            [39.37301874, -6.84939249],
                            [39.37288733, -6.84941895],
                            [39.37130229, -6.84971723],
                            [39.37124455, -6.84972809],
                            [39.36969784, -6.84962047],
                            [39.36843977, -6.84953292],
                            [39.36815533, -6.84948871],
                            [39.36688337, -6.84929099],
                            [39.36483013, -6.84897183],
                            [39.36267734, -6.84821876],
                            [39.36204081, -6.84791844],
                            [39.36140427, -6.84761812],
                            [39.3600469, -6.84698083],
                            [39.35963879, -6.84678922],
                            [39.35859991, -6.84618558],
                            [39.35840485, -6.84604326],
                            [39.35808948, -6.84581318],
                            [39.35774032, -6.84548333],
                            [39.35765712, -6.84540474],
                            [39.35701545, -6.84484437],
                            [39.35669553, -6.84450256],
                            [39.35658306, -6.8443824],
                            [39.35644254, -6.84423226],
                            [39.35594777, -6.84354636],
                            [39.35572101, -6.84335149],
                            [39.35563961, -6.84328154],
                            [39.35527938, -6.84286913],
                            [39.35503199, -6.84229176],
                            [39.35468044, -6.84181857],
                            [39.35456563, -6.84167334],
                            [39.35423774, -6.84125855],
                            [39.35401423, -6.84069811],
                            [39.35392091, -6.84046412],
                            [39.35354332, -6.83985636],
                            [39.3531961, -6.83924859],
                            [39.35293794, -6.8389016],
                            [39.35277945, -6.83868858],
                            [39.3526883, -6.83838036],
                            [39.3525581, -6.83775523],
                            [39.35250168, -6.83735584],
                            [39.35248889, -6.83726429],
                            [39.35241922, -6.83676544],
                            [39.35241054, -6.83625752],
                            [39.352276, -6.8359493],
                            [39.35209805, -6.83547611],
                            [39.35186368, -6.83476416],
                            [39.35161629, -6.83428229],
                            [39.35151999, -6.83414156],
                            [39.35139699, -6.83395445],
                            [39.35094351, -6.83326462],
                            [39.35076936, -6.83300334],
                            [39.35059522, -6.83274206],
                            [39.35014034, -6.83212565],
                            [39.3494978, -6.83118264],
                            [39.3488973, -6.83026967],
                            [39.3487817, -6.83010928],
                            [39.34852498, -6.82975311],
                            [39.34816468, -6.82887618],
                            [39.34756418, -6.82774697],
                            [39.34677152, -6.82660575],
                            [39.34670433, -6.8265246],
                            [39.34613498, -6.82583692],
                            [39.34610253, -6.82574811],
                            [39.34593661, -6.8258247],
                            [39.34466441, -6.82474093],
                            [39.34274416, -6.82476586],
                            [39.34152218, -6.82521485],
                            [39.34002588, -6.82528968],
                            [39.33655945, -6.82504022],
                            [39.33286859, -6.82401751],
                            [39.32955179, -6.8221467],
                            [39.32588587, -6.81995162],
                            [39.32506915, -6.81938806],
                            [39.32199949, -6.81726989],
                            [39.32169712, -6.81701589],
                            [39.32163837, -6.81697739],
                            [39.32164322, -6.81697213],
                            [39.32140298, -6.81669004],
                            [39.32117318, -6.81632437],
                            [39.32104262, -6.81609974],
                            [39.32087549, -6.81586989],
                            [39.32069792, -6.81560348],
                            [39.3204629, -6.81537362],
                            [39.31988841, -6.81496616],
                            [39.3196795, -6.81470497],
                            [39.31954371, -6.81450646],
                            [39.3194027, -6.81433407],
                            [39.31907916, -6.81398677],
                            [39.31836764, -6.81330081],
                            [39.31807712, -6.81300723],
                            [39.3175361, -6.8124605],
                            [39.31646453, -6.81193745],
                            [39.3159416, -6.81174881],
                            [39.31515293, -6.81138868],
                            [39.31408993, -6.81108],
                            [39.31341269, -6.81107999],
                            [39.31318124, -6.81107142],
                            [39.31300978, -6.81104569],
                            [39.31264974, -6.81101139],
                            [39.31237541, -6.8109428],
                            [39.31228112, -6.81095995],
                            [39.31214395, -6.81107999],
                            [39.3119725, -6.81107999],
                            [39.31174962, -6.81112286],
                            [39.31164674, -6.81123433],
                            [39.31150958, -6.81132865],
                            [39.31139814, -6.81139724],
                            [39.31123526, -6.81150871],
                            [39.31099523, -6.81156873],
                            [39.31073805, -6.81155158],
                            [39.31055803, -6.81149156],
                            [39.3104723, -6.81166305],
                            [39.31035228, -6.81184311],
                            [39.31013797, -6.81186884],
                            [39.31000938, -6.81197173],
                            [39.30990651, -6.81219467],
                            [39.30970934, -6.81252907],
                            [39.3095636, -6.81273486],
                            [39.30945216, -6.81306069],
                            [39.30935786, -6.81334365],
                            [39.3092207, -6.81360088],
                            [39.30902353, -6.81372093],
                            [39.30871491, -6.81384097],
                            [39.30834629, -6.81390099],
                            [39.30804625, -6.81417537],
                            [39.30783194, -6.81428684],
                            [39.30738616, -6.81471556],
                            [39.30688038, -6.81495565],
                            [39.30652054, -6.81501155],
                            [39.30593309, -6.81525705],
                            [39.30556694, -6.81530132],
                            [39.30540198, -6.81528924],
                            [39.30509216, -6.81520875],
                            [39.30445642, -6.81540997],
                            [39.30394945, -6.81566754],
                            [39.30361146, -6.81584059],
                            [39.30317289, -6.81606999],
                            [39.30294128, -6.8161408],
                            [39.30262269, -6.81628056],
                            [39.30239633, -6.81639516],
                            [39.30222306, -6.81646504],
                            [39.30186256, -6.81652095],
                            [39.3011957, -6.81655268],
                            [39.30080322, -6.81652128],
                            [39.30055203, -6.81665475],
                            [39.30038719, -6.81689814],
                            [39.30014385, -6.81705517],
                            [39.29988481, -6.81718864],
                            [39.29973763, -6.81730085],
                            [39.29963134, -6.81776157],
                            [39.29954022, -6.81820204],
                            [39.29953516, -6.81847037],
                            [39.2995959, -6.81865263],
                            [39.29957565, -6.81887033],
                            [39.29958071, -6.8190526],
                            [39.2997053, -6.8196212],
                            [39.29967931, -6.82002405],
                            [39.2995364, -6.82036193],
                            [39.29895564, -6.82100605],
                            [39.29827615, -6.82105066],
                            [39.29751445, -6.82129865],
                            [39.29691196, -6.82136254],
                            [39.29628834, -6.8212066],
                            [39.29552065, -6.82088605],
                            [39.29496313, -6.82089471],
                            [39.29445672, -6.82099606],
                            [39.29388478, -6.82101166],
                            [39.29339097, -6.82091389],
                            [39.29293634, -6.82092069],
                            [39.29288437, -6.8211806],
                            [39.29309225, -6.82181736],
                            [39.29335209, -6.82196031],
                            [39.29370288, -6.82203828],
                            [39.29431002, -6.82242633],
                            [39.2943835, -6.82259169],
                            [39.29464679, -6.82292855],
                            [39.2948856, -6.82335115],
                            [39.29518564, -6.82389012],
                            [39.29524687, -6.82431884],
                            [39.29511828, -6.82450258],
                            [39.29500806, -6.8247047],
                            [39.29495907, -6.82505992],
                            [39.29506929, -6.82548252],
                            [39.29519471, -6.8259055],
                            [39.29548932, -6.82628055],
                            [39.29594463, -6.82685206],
                            [39.29622139, -6.82753964],
                            [39.29653385, -6.8283969],
                            [39.29688203, -6.82919164],
                            [39.29730163, -6.83031679],
                            [39.29793549, -6.83111153],
                            [39.29824796, -6.8317009],
                            [39.29882825, -6.83280818],
                            [39.29791762, -6.83424586],
                            [39.29773014, -6.83469235],
                            [39.29840864, -6.83520134],
                            [39.29891752, -6.83573713],
                            [39.29919428, -6.83666582],
                            [39.30016739, -6.83760344],
                            [39.30021567, -6.83755516],
                            [39.30200329, -6.83845192],
                            [39.30294187, -6.84009838],
                            [39.30342715, -6.84276809],
                            [39.30420084, -6.84491173],
                            [39.3042244, -6.84517596],
                            [39.30426835, -6.84519009],
                            [39.30476018, -6.84620716],
                            [39.30490068, -6.8466119],
                            [39.30504254, -6.84702055],
                            [39.30513289, -6.84767013],
                            [39.30480535, -6.84826887],
                            [39.30445522, -6.84869815],
                            [39.30405991, -6.84945505],
                            [39.30386609, -6.84970389],
                            [39.30368154, -6.84994083],
                            [39.30332012, -6.85053957],
                            [39.30264244, -6.85126822],
                            [39.30235443, -6.85165797],
                            [39.30213419, -6.85219458],
                            [39.30215113, -6.85282721],
                            [39.30206642, -6.85356717],
                            [39.3014791, -6.85416591],
                            [39.30083531, -6.85462909],
                            [39.30068566, -6.85466298],
                            [39.30053318, -6.85471946],
                            [39.30036094, -6.85479854],
                            [39.30015482, -6.85493693],
                            [39.30006164, -6.85503013],
                            [39.30001928, -6.85512898],
                            [39.30002493, -6.85520806],
                            [39.30010399, -6.85533233],
                            [39.30034548, -6.85568367],
                            [39.30049029, -6.85646586],
                            [39.3005656, -6.85683088],
                            [39.30050767, -6.85702788],
                            [39.30035126, -6.85754354],
                            [39.30000949, -6.85784483],
                            [39.29972565, -6.85814032],
                            [39.29947077, -6.85856328],
                            [39.29937809, -6.85878346],
                            [39.29916955, -6.85892251],
                            [39.29876406, -6.85920642],
                            [39.29835606, -6.85970162],
                            [39.29824851, -6.85983217],
                            [39.29800521, -6.86017401],
                            [39.2976171, -6.86049268],
                            [39.2971421, -6.86078818],
                            [39.2968988, -6.86089826],
                            [39.29630781, -6.86105702],
                            [39.29588508, -6.86117058],
                            [39.29528264, -6.86136757],
                            [39.29494666, -6.86171521],
                            [39.29472653, -6.86209761],
                            [39.29455275, -6.86260748],
                            [39.29457592, -6.86304783],
                            [39.29508567, -6.86380105],
                            [39.2957808, -6.86434569],
                            [39.29648585, -6.86470704],
                            [39.29788354, -6.86542339],
                            [39.29852653, -6.86587532],
                            [39.29901312, -6.8662809],
                            [39.29962135, -6.86651267],
                            [39.30014109, -6.86663374],
                            [39.30073934, -6.86658799],
                            [39.30147502, -6.86657641],
                            [39.30184575, -6.86669808],
                            [39.30233233, -6.86714422],
                            [39.30255246, -6.86742234],
                            [39.30289422, -6.86787427],
                            [39.3033113, -6.868135],
                            [39.30383843, -6.86832041],
                            [39.30403538, -6.86845947],
                            [39.30455093, -6.86917213],
                            [39.3050491, -6.86980368],
                            [39.30520626, -6.86988845],
                            [39.30565942, -6.87013288],
                            [39.30603778, -6.87030234],
                            [39.30656297, -6.87041531],
                            [39.30713335, -6.87052263],
                            [39.30751736, -6.87053958],
                            [39.3078449, -6.87062431],
                            [39.30759004, -6.87105011],
                            [39.30725592, -6.8715938],
                            [39.30713124, -6.87239688],
                            [39.30715119, -6.87277596],
                            [39.30728085, -6.87317501],
                            [39.30749528, -6.87352916],
                            [39.30789922, -6.87412273],
                            [39.30802389, -6.87423746],
                            [39.30825329, -6.87441204],
                            [39.3084378, -6.87471631],
                            [39.30868714, -6.87510538],
                            [39.30902126, -6.87522509],
                            [39.30938531, -6.87526001],
                            [39.30979423, -6.87516524],
                            [39.31016825, -6.87516524],
                            [39.31064699, -6.87522011],
                            [39.31104594, -6.87537474],
                            [39.31141497, -6.8754745],
                            [39.31183886, -6.87540467],
                            [39.31228269, -6.87546453],
                            [39.31258574, -6.87552515],
                            [39.31279481, -6.87556697],
                            [39.31310553, -6.87562913],
                            [39.31329503, -6.8759783],
                            [39.31333492, -6.87633245],
                            [39.31370395, -6.87668161],
                            [39.31439214, -6.87701082],
                            [39.31480107, -6.87732008],
                            [39.31498059, -6.87773907],
                            [39.31510028, -6.878188],
                            [39.31532967, -6.87852718],
                            [39.31559897, -6.87870675],
                            [39.31593807, -6.87883644],
                            [39.31655645, -6.87882647],
                            [39.31690553, -6.87867683],
                            [39.31746407, -6.87836757],
                            [39.31804255, -6.8781481],
                            [39.31891027, -6.87784882],
                            [39.31950225, -6.8777903],
                            [39.31952688, -6.87799283],
                            [39.31873304, -6.87823371],
                            [39.31713269, -6.87923179],
                            [39.31628545, -6.87926946],
                            [39.31553234, -6.87940128],
                            [39.31472276, -6.87966492],
                            [39.31280233, -6.88128445],
                            [39.31215777, -6.88214406],
                            [39.31257981, -6.88137015],
                            [39.31288643, -6.88079369],
                            [39.31291323, -6.88006999],
                            [39.31273905, -6.87933289],
                            [39.31224202, -6.87905706],
                            [39.31146617, -6.87875661],
                            [39.31080425, -6.87944691],
                            [39.30992079, -6.88034788],
                            [39.30921056, -6.88046916],
                            [39.30877535, -6.88057266],
                            [39.30816673, -6.88056435],
                            [39.30740227, -6.88042275],
                            [39.30666612, -6.88025283],
                            [39.30607153, -6.87985635],
                            [39.3057884, -6.87945987],
                            [39.30564684, -6.87852531],
                            [39.30592949, -6.87727355],
                            [39.30561682, -6.87620128],
                            [39.30458052, -6.87599575],
                            [39.30358889, -6.8768357],
                            [39.30325834, -6.87738971],
                            [39.30262405, -6.87817604],
                            [39.30134303, -6.87865132],
                            [39.30037133, -6.87857878],
                            [39.29958817, -6.87833217],
                            [39.29879051, -6.87795501],
                            [39.29761577, -6.87709914],
                            [39.29664407, -6.87622876],
                            [39.29611672, -6.87531649],
                            [39.29609465, -6.87519343],
                            [39.29603303, -6.87484991],
                            [39.2959218, -6.87422977],
                            [39.29588496, -6.87402436],
                            [39.295851, -6.87352743],
                            [39.2957796, -6.87305428],
                            [39.29579745, -6.87268826],
                            [39.29571712, -6.87200978],
                            [39.29580683, -6.87141436],
                            [39.29580683, -6.8710477],
                            [39.29581036, -6.87097352],
                            [39.2958284, -6.87059477],
                            [39.29592184, -6.87004839],
                            [39.29605122, -6.86943729],
                            [39.29615185, -6.86893404],
                            [39.2960081, -6.86849549],
                            [39.29586434, -6.86797786],
                            [39.29538277, -6.86732363],
                            [39.29461845, -6.86733346],
                            [39.2941693, -6.86758451],
                            [39.29370695, -6.86758451],
                            [39.29303324, -6.86754486],
                            [39.29231989, -6.86754486],
                            [39.29150087, -6.86745237],
                            [39.29052333, -6.86721453],
                            [39.28986282, -6.86685777],
                            [39.28948985, -6.86667664],
                            [39.2892129, -6.86644225],
                            [39.2886164, -6.86599476],
                            [39.28799859, -6.86571775],
                            [39.28731687, -6.86556859],
                            [39.2869334, -6.86633569],
                            [39.28661384, -6.86725196],
                            [39.28629428, -6.86799776],
                            [39.28565516, -6.86863702],
                            [39.28497344, -6.8690845],
                            [39.2842065, -6.86940412],
                            [39.28284305, -6.86972375],
                            [39.28218263, -6.87006468],
                            [39.28137309, -6.87051216],
                            [39.28084049, -6.87095964],
                            [39.28042642, -6.87188208],
                            [39.28042353, -6.87169851],
                            [39.2804532, -6.87134676],
                            [39.28046167, -6.87127895],
                            [39.28071043, -6.87086749],
                            [39.2809394, -6.87067371],
                            [39.28114194, -6.87046231],
                            [39.2816263, -6.87005712],
                            [39.28199618, -6.86974002],
                            [39.28223937, -6.86958422],
                            [39.28247957, -6.86942405],
                            [39.28263971, -6.86932513],
                            [39.28269547, -6.86932456],
                            [39.28319944, -6.86913103],
                            [39.28334794, -6.86903201],
                            [39.28353243, -6.86891499],
                            [39.28367192, -6.86880247],
                            [39.28377092, -6.86868545],
                            [39.28385191, -6.86856843],
                            [39.2841579, -6.86775829],
                            [39.2841444, -6.86744324],
                            [39.2841669, -6.86704267],
                            [39.2841849, -6.86674112],
                            [39.2842074, -6.86642606],
                            [39.2842254, -6.86622803],
                            [39.2842569, -6.866084],
                            [39.28429677, -6.86593826],
                            [39.28436692, -6.86564143],
                            [39.28446944, -6.86545253],
                            [39.28455038, -6.86509633],
                            [39.28459354, -6.86470235],
                            [39.28473923, -6.86424361],
                            [39.28485794, -6.86399534],
                            [39.28493888, -6.86364994],
                            [39.28494547, -6.86344215],
                            [39.28494967, -6.86330993],
                            [39.28523565, -6.86284579],
                            [39.28531119, -6.86255974],
                            [39.28534896, -6.86237085],
                            [39.2854199, -6.86216875],
                            [39.28549428, -6.8621031],
                            [39.2855621, -6.8620659],
                            [39.28565617, -6.86198275],
                            [39.28572181, -6.86190397],
                            [39.28576337, -6.86186459],
                            [39.28579619, -6.86184051],
                            [39.28593402, -6.86166983],
                            [39.28595919, -6.86164879],
                            [39.28600325, -6.86164552],
                            [39.28611746, -6.86161452],
                            [39.28618109, -6.86149049],
                            [39.28628714, -6.86144806],
                            [39.28633119, -6.86138605],
                            [39.28642746, -6.86131914],
                            [39.28647477, -6.86123591],
                            [39.28649182, -6.86119631],
                            [39.28655409, -6.86117209],
                            [39.28669937, -6.86111327],
                            [39.28681699, -6.86107175],
                            [39.28692768, -6.86100947],
                            [39.28703492, -6.86094719],
                            [39.28713524, -6.86087799],
                            [39.28730128, -6.86081917],
                            [39.28737046, -6.86073267],
                            [39.28763647, -6.86056335],
                            [39.28785133, -6.86044055],
                            [39.28816777, -6.8601307],
                            [39.28833732, -6.85996468],
                            [39.28868519, -6.85973954],
                            [39.28921722, -6.8593916],
                            [39.28956509, -6.85895156],
                            [39.28986691, -6.85851151],
                            [39.29060869, -6.85771329],
                            [39.29079285, -6.85744722],
                            [39.29127373, -6.8570481],
                            [39.29210905, -6.85657753],
                            [39.29216386, -6.85654666],
                            [39.292701, -6.85620383],
                            [39.29343694, -6.85611811],
                            [39.29404642, -6.85600428],
                            [39.2942664, -6.85579449],
                            [39.29486493, -6.85547214],
                            [39.29521321, -6.85510985],
                            [39.2954005, -6.85497442],
                            [39.29532968, -6.85423678],
                            [39.29532923, -6.85423211],
                            [39.29525796, -6.85251264],
                            [39.29518668, -6.85143482],
                            [39.29531666, -6.85118319],
                            [39.29537372, -6.85113908],
                            [39.29690501, -6.84995543],
                            [39.29756825, -6.84944276],
                            [39.29842779, -6.84837753],
                            [39.29813429, -6.84755554],
                            [39.29781775, -6.84735279],
                            [39.29765631, -6.84724939],
                            [39.29773178, -6.84697679],
                            [39.29788691, -6.8468324],
                            [39.29803367, -6.8466958],
                            [39.2976605, -6.84562218],
                            [39.29757879, -6.84561217],
                            [39.29745505, -6.84559702],
                            [39.29729153, -6.84544604],
                            [39.29694352, -6.84531603],
                            [39.29671711, -6.84467018],
                            [39.29646554, -6.8443808],
                            [39.29638321, -6.84393682],
                            [39.29563955, -6.84232163],
                            [39.29550118, -6.84203225],
                            [39.2954341, -6.84143673],
                            [39.29552634, -6.84089572],
                            [39.29615683, -6.83996095],
                            [39.29620163, -6.83989453],
                            [39.29606096, -6.83958016],
                            [39.29555923, -6.83845883],
                            [39.29574785, -6.83833755],
                            [39.29584028, -6.83827812],
                            [39.29558205, -6.83767957],
                            [39.29479638, -6.83585851],
                            [39.29182826, -6.82899362],
                            [39.29117418, -6.82760126],
                            [39.28861652, -6.82558821],
                            [39.28599178, -6.82351645],
                            [39.28528738, -6.82336547],
                            [39.28442364, -6.82377646],
                            [39.28412176, -6.82345773],
                            [39.28341661, -6.82310021],
                            [39.28335717, -6.82296678],
                            [39.28330561, -6.82284787],
                            [39.28333408, -6.8228317],
                            [39.28357148, -6.82241755],
                            [39.28361599, -6.8223399],
                            [39.2836707, -6.82211106],
                            [39.2836881, -6.82203827],
                            [39.28376022, -6.8218022],
                            [39.28390445, -6.82160548],
                            [39.28397219, -6.82157394],
                            [39.2840408, -6.82154199],
                            [39.28428469, -6.82142843],
                            [39.28435116, -6.82144157],
                            [39.28457191, -6.82148519],
                            [39.28481571, -6.82153336],
                            [39.28495994, -6.82153664],
                            [39.28510417, -6.82153991],
                            [39.28524667, -6.82151922],
                            [39.28549029, -6.82148385],
                            [39.28551063, -6.8214809],
                            [39.28549793, -6.82138104],
                            [39.28547478, -6.82119912],
                            [39.28546474, -6.82112025],
                            [39.28545383, -6.82100472],
                            [39.28543285, -6.82078251],
                            [39.28540628, -6.82050107],
                            [39.28536649, -6.82007971],
                            [39.28507979, -6.81997217],
                            [39.28468817, -6.81982528],
                            [39.28458218, -6.81977874],
                            [39.28419154, -6.8196072],
                            [39.28390702, -6.8191645],
                            [39.2838645, -6.81909834],
                            [39.28361074, -6.81886482],
                            [39.28356168, -6.81881968],
                            [39.28326426, -6.81851597],
                            [39.28343854, -6.81836521],
                            [39.28364108, -6.81815792],
                            [39.28379181, -6.81799773],
                            [39.28389544, -6.81789408],
                            [39.28409768, -6.81772019],
                            [39.284346, -6.81747182],
                            [39.28457614, -6.81725979],
                            [39.28471847, -6.81713258],
                            [39.28486079, -6.81700537],
                            [39.28514545, -6.81673277],
                            [39.28539031, -6.81652826],
                            [39.2855847, -6.81634176],
                            [39.28566633, -6.81626344],
                            [39.28572346, -6.81620863],
                            [39.28594235, -6.81599863],
                            [39.28607809, -6.81615274],
                            [39.28621384, -6.81630684],
                            [39.28648532, -6.81661506],
                            [39.28668669, -6.81641116],
                            [39.28688806, -6.81620725],
                            [39.28714842, -6.81578911],
                            [39.28740877, -6.81537096],
                            [39.28747827, -6.81531258],
                            [39.28803868, -6.81484189],
                            [39.28848794, -6.81464029],
                            [39.28908605, -6.81437191],
                            [39.28968911, -6.81418341],
                            [39.29040526, -6.81393837],
                            [39.29119678, -6.81386297],
                            [39.29168677, -6.81382527],
                            [39.29217676, -6.81378757],
                            [39.29274676, -6.8138636],
                            [39.29302482, -6.81390068],
                            [39.29344885, -6.81405148],
                            [39.29387288, -6.81420229],
                            [39.29444947, -6.81447548],
                            [39.29447631, -6.81448819],
                            [39.29458901, -6.81454159],
                            [39.29551245, -6.81512595],
                            [39.29532399, -6.8154087],
                            [39.29551245, -6.8155972],
                            [39.29570091, -6.81578571],
                            [39.29609667, -6.81604961],
                            [39.29677511, -6.81608731],
                            [39.29746498, -6.81578935],
                            [39.29760433, -6.81572917],
                            [39.2984101, -6.81534901],
                            [39.29860316, -6.81525792],
                            [39.29905546, -6.81476782],
                            [39.29924835, -6.81407029],
                            [39.29930046, -6.81388187],
                            [39.29927678, -6.81308926],
                            [39.29913033, -6.81191731],
                            [39.29879235, -6.81129752],
                            [39.29870497, -6.8110991],
                            [39.29837551, -6.81035095],
                            [39.29821328, -6.81000709],
                            [39.29790233, -6.80934802],
                            [39.29780094, -6.80902686],
                            [39.29769955, -6.8087057],
                            [39.2974179, -6.80783801],
                            [39.29733904, -6.8076239],
                            [39.29726017, -6.8074098],
                            [39.29693346, -6.80712808],
                            [39.29660674, -6.80684636],
                            [39.29629129, -6.80643504],
                            [39.29615046, -6.80619277],
                            [39.2960209, -6.806035],
                            [39.29595331, -6.80592795],
                            [39.29588571, -6.8058209],
                            [39.29573362, -6.80564623],
                            [39.29555899, -6.80548847],
                            [39.29538528, -6.80524455],
                            [39.29524355, -6.80503208],
                            [39.29513652, -6.8049363],
                            [39.29506329, -6.80486868],
                            [39.29481444, -6.80463322],
                            [39.29464644, -6.80447427],
                            [39.29435352, -6.80412494],
                            [39.29426047, -6.80403868],
                            [39.29389161, -6.80369673],
                            [39.29337337, -6.80333612],
                            [39.29308045, -6.80313328],
                            [39.29278754, -6.80293045],
                            [39.29237069, -6.80259238],
                            [39.29213973, -6.80245715],
                            [39.29190878, -6.80232193],
                            [39.2915032, -6.80210782],
                            [39.29127224, -6.80191625],
                            [39.29104129, -6.80172468],
                            [39.29078287, -6.80152254],
                            [39.29050685, -6.80125773],
                            [39.29014634, -6.80098728],
                            [39.28988158, -6.8007619],
                            [39.28933518, -6.80028861],
                            [39.2888958, -6.79982659],
                            [39.28842262, -6.79942654],
                            [39.28820857, -6.79924624],
                            [39.28790438, -6.79892508],
                            [39.28755514, -6.79863209],
                            [39.28724474, -6.7984076],
                            [39.28718899, -6.79836728],
                            [39.28663132, -6.79784328],
                            [39.28662406, -6.79760241],
                            [39.28666349, -6.79733196],
                            [39.28668039, -6.79723054],
                            [39.28669729, -6.79712912],
                            [39.28682122, -6.79688684],
                            [39.28676602, -6.79673849],
                            [39.28661843, -6.7964699],
                            [39.28650577, -6.79628396],
                            [39.28639311, -6.79609803],
                            [39.28629586, -6.79578547],
                            [39.28628815, -6.79560368],
                            [39.28628045, -6.7954219],
                            [39.28632552, -6.79509511],
                            [39.28628045, -6.79477395],
                            [39.28614755, -6.79456934],
                            [39.28601007, -6.79425559],
                            [39.28598885, -6.79420182],
                            [39.28592557, -6.79404148],
                            [39.28570588, -6.79388935],
                            [39.28559322, -6.79378793],
                            [39.28551999, -6.79368651],
                            [39.28540733, -6.79353438],
                            [39.28534537, -6.79335408],
                            [39.28528341, -6.79306673],
                            [39.28521581, -6.79295404],
                            [39.28514822, -6.79284135],
                            [39.28502429, -6.79281881],
                            [39.28492853, -6.79285262],
                            [39.28481586, -6.79285262],
                            [39.28466377, -6.79276247],
                            [39.28454548, -6.79266105],
                            [39.28442718, -6.79251456],
                            [39.28431452, -6.79248638],
                            [39.28421313, -6.79250892],
                            [39.28414553, -6.79249765],
                            [39.28404977, -6.7924244],
                            [39.28400752, -6.79239905],
                            [39.28396527, -6.79237369],
                            [39.28386388, -6.79243004],
                            [39.28380754, -6.79259907],
                            [39.28375121, -6.7927681],
                            [39.28371061, -6.79282745],
                            [39.28360475, -6.7929822],
                            [39.28349772, -6.7931287],
                            [39.28331746, -6.7931287],
                            [39.28310341, -6.79306672],
                            [39.28289286, -6.79296764],
                            [39.28261634, -6.79270914],
                            [39.28243804, -6.79254244],
                            [39.28222069, -6.79233925],
                            [39.28205541, -6.79223517],
                            [39.28199532, -6.79222954],
                            [39.28193524, -6.7922239],
                            [39.28182633, -6.792284],
                            [39.28181506, -6.79239669],
                            [39.28181882, -6.79246242],
                            [39.28182257, -6.79252816],
                            [39.28182496, -6.79262357],
                            [39.28182633, -6.79267841],
                            [39.28174747, -6.79277231],
                            [39.28162354, -6.79285871],
                            [39.28142075, -6.79286622],
                            [39.28122359, -6.79280236],
                            [39.28102643, -6.7927385],
                            [39.28072225, -6.79258825],
                            [39.28060583, -6.79251688],
                            [39.28055607, -6.79248638],
                            [39.28048941, -6.79244551],
                            [39.28024156, -6.79229526],
                            [39.28011763, -6.79201918],
                            [39.27999371, -6.79174309],
                            [39.2799462, -6.79162785],
                            [39.2797687, -6.79170106],
                            [39.27959645, -6.7917721],
                            [39.27952257, -6.79139128],
                            [39.27927193, -6.79094212],
                            [39.27908938, -6.79064729],
                            [39.27872065, -6.78948457],
                            [39.27865489, -6.78927723],
                            [39.2784559, -6.78844252],
                            [39.27837779, -6.78811492],
                            [39.27810963, -6.78742647],
                            [39.27803693, -6.78704387],
                            [39.27831116, -6.78670101],
                            [39.27856668, -6.78644543],
                            [39.27878299, -6.78613712],
                            [39.27881598, -6.78609011],
                            [39.27899672, -6.78574725],
                            [39.2791089, -6.78546673],
                            [39.27926471, -6.78513011],
                            [39.27958256, -6.78488076],
                            [39.27988171, -6.78466258],
                            [39.27986914, -6.78463324],
                            [39.28001259, -6.78485583],
                            [39.28006868, -6.78481219],
                            [39.28027435, -6.78466258],
                            [39.28043016, -6.78461272],
                            [39.28071061, -6.78456285],
                            [39.28090382, -6.78453791],
                            [39.28102223, -6.78443194],
                            [39.28117181, -6.78449428],
                            [39.28129022, -6.78458155],
                            [39.28142733, -6.78474986],
                            [39.28151458, -6.78466259],
                            [39.28162053, -6.78437584],
                            [39.28171402, -6.78420753],
                            [39.28189476, -6.78405168],
                            [39.2820755, -6.78397065],
                            [39.28225623, -6.78400182],
                            [39.28230609, -6.78405792],
                            [39.28243697, -6.78415766],
                            [39.28258031, -6.78411402],
                            [39.28255539, -6.78396441],
                            [39.28251799, -6.78383351],
                            [39.2824619, -6.78364649],
                            [39.28239958, -6.78340961],
                            [39.28239958, -6.78319143],
                            [39.28241205, -6.78291715],
                            [39.28238088, -6.78269273],
                            [39.28237465, -6.78245585],
                            [39.28242451, -6.7822439],
                            [39.28255539, -6.78203195],
                            [39.28259279, -6.78189481],
                            [39.2827112, -6.7817639],
                            [39.28273613, -6.7816704],
                            [39.28282962, -6.78148962],
                            [39.28306021, -6.78153949],
                            [39.28328458, -6.7815956],
                            [39.28349024, -6.7816143],
                            [39.28373331, -6.7816143],
                            [39.2839452, -6.78163923],
                            [39.28426305, -6.78170781],
                            [39.28468685, -6.78167664],
                            [39.2848115, -6.781633],
                            [39.28486759, -6.78150833],
                            [39.28490499, -6.78130262],
                            [39.28490499, -6.7809847],
                            [39.28497978, -6.78062937],
                            [39.28501717, -6.78045483],
                            [39.28507326, -6.78024288],
                            [39.28505457, -6.78005587],
                            [39.2850795, -6.77985639],
                            [39.28514182, -6.77965691],
                            [39.28514805, -6.77945743],
                            [39.28515429, -6.77933275],
                            [39.28526647, -6.77924548],
                            [39.28529763, -6.77916444],
                            [39.2852727, -6.77899613],
                            [39.28522908, -6.77880289],
                            [39.28516358, -6.77855177],
                            [39.28517922, -6.7785473],
                            [39.28519792, -6.77842886],
                            [39.28520415, -6.77827925],
                            [39.28526024, -6.77807354],
                            [39.28526024, -6.77798003],
                            [39.28526024, -6.77785536],
                            [39.28526024, -6.77777432],
                            [39.28532256, -6.77763718],
                            [39.28542228, -6.77752497],
                            [39.28544721, -6.7773816],
                            [39.28540982, -6.77720705],
                            [39.2853475, -6.77704497],
                            [39.28525401, -6.7769016],
                            [39.28521662, -6.77680186],
                            [39.28517299, -6.77663355],
                            [39.28510444, -6.77646523],
                            [39.28501095, -6.77625329],
                            [39.28496109, -6.77616601],
                            [39.28487384, -6.77602887],
                            [39.28469934, -6.77550524],
                            [39.28461209, -6.77537433],
                            [39.28449991, -6.77523719],
                            [39.28436279, -6.77505641],
                            [39.28422568, -6.7749068],
                            [39.28408234, -6.77474472],
                            [39.28398886, -6.77461381],
                            [39.28391407, -6.77451407],
                            [39.28388291, -6.7744268],
                            [39.28388914, -6.77428965],
                            [39.28391407, -6.77421485],
                            [39.28390784, -6.77413381],
                            [39.28387668, -6.77407147],
                            [39.28382059, -6.77403407],
                            [39.28367724, -6.77380342],
                            [39.28365231, -6.77371615],
                            [39.28358999, -6.77366005],
                            [39.28349651, -6.77354784],
                            [39.28345911, -6.77342316],
                            [39.28341549, -6.77326109],
                            [39.28336563, -6.77318628],
                            [39.28324721, -6.77314264],
                            [39.28317866, -6.77307407],
                            [39.28312257, -6.77298057],
                            [39.28307271, -6.77281226],
                            [39.28294313, -6.77259813],
                            [39.28292937, -6.77257537],
                            [39.28283588, -6.77240706],
                            [39.28280472, -6.77233226],
                            [39.2827237, -6.77220135],
                            [39.28264891, -6.77206421],
                            [39.28261775, -6.77201434],
                            [39.28253673, -6.77199563],
                            [39.28249934, -6.77192083],
                            [39.28244325, -6.77178369],
                            [39.28241209, -6.77169018],
                            [39.28237469, -6.77159044],
                            [39.2823186, -6.77155927],
                            [39.28224381, -6.77151563],
                            [39.28215033, -6.77139719],
                            [39.28201322, -6.77118525],
                            [39.28196959, -6.77097953],
                            [39.2819509, -6.77091096],
                            [39.28190727, -6.77087979],
                            [39.28182002, -6.77079252],
                            [39.28176393, -6.77069278],
                            [39.28171407, -6.77064291],
                            [39.28165798, -6.7705494],
                            [39.28161435, -6.77042473],
                            [39.28153333, -6.77027512],
                            [39.28150217, -6.77017538],
                            [39.28143985, -6.7700694],
                            [39.28138376, -6.7699572],
                            [39.28132143, -6.76985122],
                            [39.28125288, -6.76971408],
                            [39.28119056, -6.76960811],
                            [39.28113447, -6.76953954],
                            [39.28110127, -6.76938055],
                            [39.28107633, -6.76925584],
                            [39.28105971, -6.76919765],
                            [39.28104309, -6.76913114],
                            [39.28100984, -6.76908125],
                            [39.28097659, -6.76903137],
                            [39.28092672, -6.76893991],
                            [39.28082697, -6.76877363],
                            [39.28072723, -6.7685159],
                            [39.28066073, -6.76824154],
                            [39.28062748, -6.7679838],
                            [39.28058592, -6.76785078],
                            [39.28051112, -6.76736026],
                            [39.28046956, -6.76701938],
                            [39.28045293, -6.76688636],
                            [39.28038644, -6.76642078],
                            [39.28036982, -6.76617136],
                            [39.28036982, -6.76601339],
                            [39.28037813, -6.76558938],
                            [39.28033657, -6.76476629],
                            [39.28031995, -6.76416769],
                            [39.2803741, -6.76337504],
                            [39.28035552, -6.76199327],
                            [39.2803858, -6.76151361],
                            [39.28039269, -6.76140462],
                            [39.28047322, -6.76113199],
                            [39.28046703, -6.7609399],
                            [39.28044844, -6.76086555],
                            [39.28051659, -6.76056193],
                            [39.28062646, -6.76030974],
                            [39.28075361, -6.75997059],
                            [39.28082627, -6.75951031],
                            [39.28095343, -6.75901369],
                            [39.28114719, -6.75861398],
                            [39.28129251, -6.75828694],
                            [39.28138333, -6.75814159],
                            [39.28154681, -6.75803258],
                            [39.28164975, -6.75801441],
                            [39.28170424, -6.75799624],
                            [39.28181929, -6.75802047],
                            [39.28188589, -6.75817793],
                            [39.28190405, -6.75821427],
                            [39.28202515, -6.75825666],
                            [39.28215836, -6.75828694],
                            [39.28228551, -6.75832934],
                            [39.28242478, -6.75836568],
                            [39.28257615, -6.75838385],
                            [39.28257009, -6.75828089],
                            [39.28252771, -6.7581961],
                            [39.28247322, -6.75817188],
                            [39.28237817, -6.75811408],
                            [39.28240014, -6.7579363],
                            [39.28241562, -6.75781107],
                            [39.28244437, -6.7575784],
                            [39.28254667, -6.75739783],
                            [39.28270312, -6.75718115],
                            [39.28270312, -6.75704272],
                            [39.28268507, -6.75685011],
                            [39.28263693, -6.75675381],
                            [39.2826249, -6.75651907],
                            [39.28272118, -6.75631443],
                            [39.28294985, -6.75603756],
                            [39.2830702, -6.75588107],
                            [39.28325674, -6.75579681],
                            [39.28333497, -6.75589311],
                            [39.28346736, -6.75602553],
                            [39.28367797, -6.7559834],
                            [39.28378027, -6.75597136],
                            [39.28371368, -6.75583766],
                            [39.28377945, -6.75572555],
                            [39.28382239, -6.75565236],
                            [39.28384256, -6.75552897],
                            [39.28388859, -6.75544771],
                            [39.28402817, -6.75545363],
                            [39.28406888, -6.755388],
                            [39.28417743, -6.75521298],
                            [39.28427973, -6.75520696],
                            [39.28439406, -6.75521298],
                            [39.28459264, -6.75524307],
                            [39.28469494, -6.755219],
                            [39.28461671, -6.75515881],
                            [39.28443618, -6.75511668],
                            [39.28412327, -6.75502037],
                            [39.28401496, -6.75494815],
                            [39.28402866, -6.75479554],
                            [39.28407545, -6.75470147],
                            [39.28414734, -6.75455692],
                            [39.28436397, -6.75450877],
                            [39.2845754, -6.75444182],
                            [39.284648, -6.75432048],
                            [39.28471901, -6.75420181],
                            [39.28489352, -6.7541055],
                            [39.28510523, -6.75412211],
                            [39.28518236, -6.7540092],
                            [39.28530873, -6.75373835],
                            [39.28532678, -6.75358186],
                            [39.2852967, -6.75348556],
                            [39.28530272, -6.7533712],
                            [39.28537493, -6.75313646],
                            [39.28555545, -6.7530522],
                            [39.28574043, -6.752997],
                            [39.28586235, -6.75281144],
                            [39.28587679, -6.75268099],
                            [39.2859827, -6.75251652],
                            [39.28612111, -6.75237206],
                            [39.2862896, -6.75224567],
                            [39.28640995, -6.75213733],
                            [39.28640995, -6.75202899],
                            [39.2863919, -6.7518725],
                            [39.28640995, -6.75171601],
                            [39.28645207, -6.75153544],
                            [39.28661703, -6.75134439],
                            [39.28676499, -6.7511081],
                            [39.28683442, -6.75074276],
                            [39.28689737, -6.75063862],
                            [39.28698404, -6.75034402],
                            [39.28710197, -6.75014507],
                            [39.28712604, -6.74996451],
                            [39.28713808, -6.7497057],
                            [39.28722233, -6.74947698],
                            [39.2872918, -6.74921871],
                            [39.28743001, -6.74896851],
                            [39.28757304, -6.74870956],
                            [39.28769772, -6.74848386],
                            [39.28770373, -6.74826116],
                            [39.28763152, -6.74791206],
                            [39.28763312, -6.74776832],
                            [39.28773178, -6.74757407],
                            [39.28778667, -6.747466],
                            [39.28789028, -6.74726202],
                            [39.28787223, -6.74712359],
                            [39.28776993, -6.74700321],
                            [39.2876917, -6.7468708],
                            [39.28759542, -6.74670829],
                            [39.28751118, -6.74651568],
                            [39.28742693, -6.74636521],
                            [39.28738353, -6.74614025],
                            [39.28736074, -6.74602213],
                            [39.28733667, -6.74567905],
                            [39.28709597, -6.74557071],
                            [39.28685527, -6.74502299],
                            [39.28667475, -6.74476418],
                            [39.28656643, -6.74458361],
                            [39.28644006, -6.74440304],
                            [39.28631971, -6.74427063],
                            [39.28621742, -6.74403589],
                            [39.28618131, -6.7438493],
                            [39.28614521, -6.74362058],
                            [39.28609105, -6.74348817],
                            [39.28601282, -6.74327149],
                            [39.28591654, -6.74309694],
                            [39.28577814, -6.74288628],
                            [39.28567584, -6.74269969],
                            [39.28560965, -6.74241078],
                            [39.28550134, -6.74203159],
                            [39.28555239, -6.74188248],
                            [39.28565178, -6.74159221],
                            [39.28551337, -6.74147183],
                            [39.28534488, -6.74132136],
                            [39.28523055, -6.74102042],
                            [39.28431559, -6.73894013],
                            [39.28430872, -6.73892451],
                            [39.28431877, -6.73882463],
                            [39.28432191, -6.73879342],
                            [39.28431984, -6.73878582],
                            [39.2842858, -6.738661],
                            [39.28413537, -6.73852859],
                            [39.28397289, -6.73831793],
                            [39.28393203, -6.73814856],
                            [39.28394882, -6.73793272],
                            [39.28403286, -6.73771049],
                            [39.28404511, -6.73755954],
                            [39.28400298, -6.73743917],
                            [39.28388865, -6.73737898],
                            [39.28375224, -6.7372459],
                            [39.28377108, -6.73695982],
                            [39.28378034, -6.73681922],
                            [39.28393078, -6.73667477],
                            [39.28397807, -6.73658961],
                            [39.28399095, -6.73656643],
                            [39.28394281, -6.73644605],
                            [39.2838345, -6.73637984],
                            [39.28381363, -6.73636879],
                            [39.2836299, -6.7362715],
                            [39.28347345, -6.73616316],
                            [39.28338318, -6.73602472],
                            [39.28326803, -6.73575693],
                            [39.28331483, -6.73570492],
                            [39.28331577, -6.73570021],
                            [39.28333562, -6.73560091],
                            [39.28334082, -6.73551769],
                            [39.28334082, -6.73540328],
                            [39.28333043, -6.73535127],
                            [39.28329403, -6.73517444],
                            [39.28326283, -6.73511203],
                            [39.28316404, -6.73506523],
                            [39.28306005, -6.73500282],
                            [39.28295606, -6.73496641],
                            [39.28283647, -6.73492481],
                            [39.28270951, -6.73487038],
                            [39.28269088, -6.7348624],
                            [39.28256089, -6.73481039],
                            [39.28250968, -6.73474144],
                            [39.28259313, -6.73469043],
                            [39.28262419, -6.73467144],
                            [39.28243726, -6.73444941],
                            [39.28223501, -6.73420919],
                            [39.28215585, -6.73401428],
                            [39.28191881, -6.73343066],
                            [39.28167655, -6.73327315],
                            [39.28143234, -6.73311438],
                            [39.28141206, -6.73286418],
                            [39.28135937, -6.7322142],
                            [39.28087395, -6.73203213],
                            [39.28058102, -6.73192225],
                            [39.27999725, -6.73143567],
                            [39.27987564, -6.73099774],
                            [39.27941349, -6.73077878],
                            [39.27897567, -6.73048683],
                            [39.27887838, -6.72980562],
                            [39.27741896, -6.72941635],
                            [39.27702979, -6.7288811],
                            [39.27676223, -6.72810257],
                            [39.27576496, -6.72778629],
                            [39.27527574, -6.72803096],
                            [39.27454878, -6.72839451],
                            [39.27430554, -6.72919737],
                            [39.27454878, -6.72995157],
                            [39.2744758, -6.73124102],
                            [39.27432986, -6.73257911],
                            [39.2743785, -6.73333331],
                            [39.27467038, -6.7338199],
                            [39.2747686, -6.73408656],
                            [39.27476837, -6.7340866],
                            [39.2747696, -6.73411139],
                            [39.27488791, -6.73417827],
                            [39.27493801, -6.7342066],
                            [39.27492923, -6.73430325],
                            [39.27487652, -6.73440868],
                            [39.2748326, -6.73467226],
                            [39.27492923, -6.73488313],
                            [39.2749468, -6.73502371],
                            [39.27485017, -6.73520821],
                            [39.2747799, -6.73526971],
                            [39.27474476, -6.73540151],
                            [39.27481503, -6.73554208],
                            [39.27486774, -6.73562116],
                            [39.27487652, -6.73576173],
                            [39.27486773, -6.73604289],
                            [39.27486773, -6.73625375],
                            [39.27481503, -6.73640312],
                            [39.27477111, -6.73659641],
                            [39.27485016, -6.73657005],
                            [39.27487652, -6.73671063],
                            [39.27473597, -6.73686878],
                            [39.27457786, -6.73706207],
                            [39.27374337, -6.73781767],
                            [39.27347106, -6.73808125],
                            [39.27322982, -6.73839742],
                            [39.27321632, -6.73841512],
                            [39.27297915, -6.73858205],
                            [39.27271562, -6.73887199],
                            [39.27259265, -6.73916193],
                            [39.27255751, -6.73930251],
                            [39.27228021, -6.73939371],
                            [39.27225556, -6.73953342],
                            [39.27233772, -6.73955808],
                            [39.2723295, -6.7397471],
                            [39.2722309, -6.73989503],
                            [39.27219804, -6.73996078],
                            [39.27214874, -6.7400594],
                            [39.27205836, -6.74011693],
                            [39.27199262, -6.7401498],
                            [39.27188581, -6.74015802],
                            [39.27182829, -6.74015802],
                            [39.27177899, -6.74013337],
                            [39.27167218, -6.74010049],
                            [39.27159001, -6.74021555],
                            [39.27180364, -6.74037992],
                            [39.27178721, -6.74057716],
                            [39.27181215, -6.7406229],
                            [39.27183651, -6.74066756],
                            [39.27141746, -6.74120997],
                            [39.27106731, -6.74163634],
                            [39.27085052, -6.74190032],
                            [39.27080737, -6.7419883],
                            [39.27063689, -6.74233589],
                            [39.27046434, -6.74267284],
                            [39.27040682, -6.74279612],
                            [39.27022606, -6.74296871],
                            [39.27004529, -6.74320704],
                            [39.26995491, -6.74343715],
                            [39.26989739, -6.74379054],
                            [39.26992204, -6.74411106],
                            [39.27002064, -6.74446445],
                            [39.27012745, -6.74477675],
                            [39.27016032, -6.74496577],
                            [39.27013567, -6.74513014],
                            [39.27017675, -6.74559859],
                            [39.2701521, -6.74619853],
                            [39.27006993, -6.74671629],
                            [39.27002063, -6.74702859],
                            [39.26999598, -6.7471683],
                            [39.27006171, -6.74741486],
                            [39.27016852, -6.74776825],
                            [39.27006993, -6.74785043],
                            [39.27016852, -6.74820382],
                            [39.27028355, -6.74817917],
                            [39.2703082, -6.74822848],
                            [39.27019317, -6.74835175],
                            [39.27032463, -6.74880376],
                            [39.27057935, -6.74877089],
                            [39.27057113, -6.74888595],
                            [39.2704561, -6.74900923],
                            [39.27046432, -6.74906675],
                            [39.27013565, -6.7491325],
                            [39.27017674, -6.74918181],
                            [39.27034928, -6.74915716],
                            [39.27039858, -6.7493544],
                            [39.27049718, -6.7493544],
                            [39.27057113, -6.74954342],
                            [39.27057934, -6.74978997],
                            [39.27058225, -6.74987197],
                            [39.27050269, -6.7502331],
                            [39.27057001, -6.75060036],
                            [39.27081479, -6.75056975],
                            [39.27106127, -6.75056527],
                            [39.27106451, -6.75074403],
                            [39.27103943, -6.75091965],
                            [39.27102689, -6.751089],
                            [39.27127771, -6.75229323],
                            [39.27118992, -6.75242494],
                            [39.27095791, -6.75282008],
                            [39.27071335, -6.75294552],
                            [39.2698919, -6.75349745],
                            [39.26906418, -6.75406193],
                            [39.26823018, -6.75462014],
                            [39.26816121, -6.75474558],
                            [39.26797309, -6.75501527],
                            [39.26761566, -6.75524107],
                            [39.26712028, -6.75544804],
                            [39.26642424, -6.75565502],
                            [39.26588497, -6.75572401],
                            [39.26540213, -6.75575536],
                            [39.26493184, -6.75592471],
                            [39.26413547, -6.75611287],
                            [39.26320741, -6.75629475],
                            [39.2614503, -6.756372],
                            [39.2606206, -6.75630691],
                            [39.25967185, -6.7562409],
                            [39.25965556, -6.75622053],
                            [39.25809198, -6.75587027],
                            [39.25754228, -6.7556707],
                            [39.25688672, -6.7554915],
                            [39.25629223, -6.75528786],
                            [39.25582397, -6.75509644],
                            [39.25527428, -6.75478284],
                            [39.25464722, -6.75432262],
                            [39.25422782, -6.75414749],
                            [39.25390409, -6.75405009],
                            [39.25377713, -6.75397176],
                            [39.2537107, -6.7537793],
                            [39.25365545, -6.75364666],
                            [39.25349977, -6.75355999],
                            [39.25318581, -6.75348086],
                            [39.25302558, -6.75343665],
                            [39.25284656, -6.75332983],
                            [39.25267749, -6.75312717],
                            [39.25241781, -6.7530498],
                            [39.25188739, -6.75279558],
                            [39.25166085, -6.75260768],
                            [39.25146195, -6.75235346],
                            [39.25129092, -6.75226454],
                            [39.25100335, -6.75219872],
                            [39.25073814, -6.75212687],
                            [39.25036795, -6.75199424],
                            [39.25009722, -6.75186713],
                            [39.24993029, -6.75171732],
                            [39.24977654, -6.75157935],
                            [39.24964128, -6.75145797],
                            [39.24950602, -6.75133658],
                            [39.24812472, -6.75020365],
                            [39.24730699, -6.74976153],
                            [39.24667159, -6.74923652],
                            [39.2462351, -6.74875571],
                            [39.24595332, -6.74845728],
                            [39.24497078, -6.74773899],
                            [39.24383758, -6.74682912],
                            [39.24372105, -6.74670091],
                            [39.24345303, -6.74646003],
                            [39.24323551, -6.74611036],
                            [39.24306849, -6.74589668],
                            [39.24290147, -6.74571408],
                            [39.24273833, -6.74549262],
                            [39.24266453, -6.74519346],
                            [39.24269949, -6.74473501],
                            [39.24248974, -6.74437758],
                            [39.24194205, -6.7436627],
                            [39.24168181, -6.74283905],
                            [39.24159785, -6.74276673],
                            [39.24172685, -6.74267369],
                            [39.24201358, -6.742554],
                            [39.24198034, -6.74251144],
                            [39.24178781, -6.74226494],
                            [39.24151462, -6.74196234],
                            [39.24134529, -6.74173425],
                            [39.24123918, -6.74154682],
                            [39.24114912, -6.74115974],
                            [39.24077305, -6.74052942],
                            [39.24048846, -6.74006177],
                            [39.24035633, -6.73978728],
                            [39.24027501, -6.73957378],
                            [39.2401937, -6.73928913],
                            [39.24011239, -6.7390858],
                            [39.24000059, -6.73889264],
                            [39.23982946, -6.73860171],
                            [39.23981441, -6.7385039],
                            [39.23978951, -6.73843897],
                            [39.23969311, -6.73818759],
                            [39.2395084, -6.73767953],
                            [39.23943584, -6.737409],
                            [39.23929071, -6.73691413],
                            [39.23917857, -6.73634008],
                            [39.23903344, -6.73591119],
                            [39.23901365, -6.73550869],
                            [39.2389279, -6.7351194],
                            [39.23894769, -6.73498743],
                            [39.23883216, -6.73437315],
                            [39.23881983, -6.7343357],
                            [39.2386006, -6.73367003],
                            [39.23844718, -6.73280042],
                            [39.23833467, -6.73209451],
                            [39.23819147, -6.73134767],
                            [39.23804828, -6.73065198],
                            [39.23797668, -6.73027344],
                            [39.23789486, -6.72998698],
                            [39.23771075, -6.72962891],
                            [39.23748572, -6.72924014],
                            [39.23723002, -6.72877976],
                            [39.23696408, -6.72816592],
                            [39.23681066, -6.72792038],
                            [39.23670837, -6.72767485],
                            [39.23643221, -6.72716331],
                            [39.2363197, -6.72684616],
                            [39.23632404, -6.72664427],
                            [39.2360068, -6.72540006],
                            [39.23588992, -6.72497419],
                            [39.23585605, -6.72468297],
                            [39.23529096, -6.72370723],
                            [39.23466393, -6.72236976],
                            [39.23413861, -6.72071458],
                            [39.23376376, -6.71953349],
                            [39.23325743, -6.71851414],
                            [39.23269046, -6.71613561],
                            [39.23261729, -6.71422311],
                            [39.23281949, -6.71210447],
                            [39.23272322, -6.71021695],
                            [39.23222257, -6.70783829],
                            [39.23203964, -6.7071064],
                            [39.23201631, -6.70693971],
                            [39.23152826, -6.7034529],
                            [39.23139448, -6.70220844],
                            [39.2310132, -6.70117807],
                            [39.23091955, -6.70085692],
                            [39.23087273, -6.70032167],
                            [39.23053159, -6.69968605],
                            [39.23004328, -6.69895677],
                            [39.22994336, -6.69884271],
                            [39.2294747, -6.69830777],
                            [39.22914025, -6.69794647],
                            [39.22912215, -6.69791579],
                            [39.22874559, -6.6972774],
                            [39.228371, -6.69664179],
                            [39.22794289, -6.69603293],
                            [39.22769539, -6.69572516],
                            [39.22757499, -6.69549099],
                            [39.2271402, -6.6949825],
                            [39.22662248, -6.69521379],
                            [39.22620252, -6.69468319],
                            [39.22587098, -6.69399899],
                            [39.22575494, -6.69367727],
                            [39.22534603, -6.69286479],
                            [39.22504764, -6.69239499],
                            [39.2249095, -6.6921518],
                            [39.22474372, -6.69172069],
                            [39.22461111, -6.69119009],
                            [39.22453669, -6.69076285],
                            [39.22356355, -6.68802526],
                            [39.22297055, -6.68557663],
                            [39.22277289, -6.68372115],
                            [39.22262084, -6.68265653],
                            [39.22263605, -6.68145504],
                            [39.22269687, -6.68069459],
                            [39.22271208, -6.67972123],
                            [39.22263606, -6.67893037],
                            [39.22252962, -6.67782012],
                            [39.22246881, -6.67619278],
                            [39.22223374, -6.67473372],
                            [39.22195832, -6.6742709],
                            [39.22158374, -6.67358768],
                            [39.22147357, -6.67282733],
                            [39.22122018, -6.67193474],
                            [39.22112103, -6.67119643],
                            [39.22108973, -6.6711245],
                            [39.2207134, -6.67025976],
                            [39.22065831, -6.67019365],
                            [39.22047692, -6.66997235],
                            [39.22030966, -6.66945525],
                            [39.22037049, -6.6686796],
                            [39.22055295, -6.66788874],
                            [39.22073542, -6.66720434],
                            [39.22082666, -6.66693058],
                            [39.22094477, -6.66680513],
                            [39.22105474, -6.66658078],
                            [39.22146529, -6.66595722],
                            [39.22148049, -6.66544012],
                            [39.22140447, -6.66519678],
                            [39.22097872, -6.6652424],
                            [39.2206442, -6.66534886],
                            [39.22040091, -6.66554658],
                            [39.21976228, -6.66556178],
                            [39.21907804, -6.66556178],
                            [39.21859591, -6.66555517],
                            [39.21796804, -6.66554657],
                            [39.21704051, -6.66522718],
                            [39.21639436, -6.66505595],
                            [39.21663479, -6.66467748],
                            [39.21676486, -6.66452373],
                            [39.21691858, -6.66435026],
                            [39.21722602, -6.66416892],
                            [39.21741127, -6.66403487],
                            [39.21753346, -6.66395208],
                            [39.21756893, -6.66390478],
                            [39.21815622, -6.66352631],
                            [39.2185149, -6.66326611],
                            [39.21858191, -6.66321092],
                            [39.21864103, -6.66313601],
                            [39.21865285, -6.66308082],
                            [39.21863709, -6.66299014],
                            [39.21855826, -6.66287975],
                            [39.2177069, -6.6616103],
                            [39.21718662, -6.66064047],
                            [39.21712847, -6.66056049],
                            [39.21669924, -6.65933294],
                            [39.21613111, -6.65841693],
                            [39.21558842, -6.65761119],
                            [39.21533404, -6.65702596],
                            [39.21495246, -6.65606755],
                            [39.21466926, -6.65533107],
                            [39.21461328, -6.65518548],
                            [39.21442673, -6.65465962],
                            [39.21425714, -6.65435429],
                            [39.21401123, -6.65395565],
                            [39.21385012, -6.6537521],
                            [39.21362965, -6.65351462],
                            [39.21341652, -6.65326751],
                            [39.21332115, -6.65314486],
                            [39.21315766, -6.65293136],
                            [39.21296692, -6.65270424],
                            [39.21266718, -6.65239989],
                            [39.21237652, -6.65208645],
                            [39.21208587, -6.65182299],
                            [39.21186334, -6.65154135],
                            [39.2116953, -6.65137782],
                            [39.21145915, -6.6510871],
                            [39.21120028, -6.65065102],
                            [39.21090963, -6.65013317],
                            [39.21080203, -6.65003227],
                            [39.21069164, -6.64992876],
                            [39.21053269, -6.64975614],
                            [39.21021479, -6.64926555],
                            [39.21001496, -6.64887489],
                            [39.20966527, -6.64830708],
                            [39.20961531, -6.64822985],
                            [39.20944728, -6.64795276],
                            [39.20921112, -6.64755302],
                            [39.20901584, -6.6473486],
                            [39.20887506, -6.64717599],
                            [39.20872065, -6.64698975],
                            [39.20847995, -6.6467717],
                            [39.20818929, -6.64652187],
                            [39.20798039, -6.64635379],
                            [39.20774877, -6.64612212],
                            [39.20751262, -6.645895],
                            [39.20739908, -6.64578598],
                            [39.20726738, -6.6456497],
                            [39.20689498, -6.64527267],
                            [39.20659604, -6.64491281],
                            [39.20636687, -6.64448533],
                            [39.20629607, -6.64435326],
                            [39.20528263, -6.64260163],
                            [39.20425298, -6.64125547],
                            [39.20384784, -6.64064331],
                            [39.20310982, -6.63952817],
                            [39.20288837, -6.63927437],
                            [39.20233149, -6.63863613],
                            [39.20196666, -6.63822255],
                            [39.2014371, -6.63791447],
                            [39.20134911, -6.63769842],
                            [39.20110911, -6.63727434],
                            [39.20099711, -6.63717031],
                            [39.20074112, -6.63669822],
                            [39.20051713, -6.63625012],
                            [39.20048513, -6.63602608],
                            [39.20038113, -6.63579403],
                            [39.20023713, -6.63549797],
                            [39.20013314, -6.6351939],
                            [39.19996514, -6.63477782],
                            [39.19961315, -6.63439374],
                            [39.19939716, -6.63411368],
                            [39.19849318, -6.63280141],
                            [39.19812519, -6.63236932],
                            [39.19798119, -6.63213727],
                            [39.1977252, -6.6318172],
                            [39.1974772, -6.63155314],
                            [39.19724521, -6.63127309],
                            [39.19691722, -6.63104104],
                            [39.19674922, -6.63081699],
                            [39.19664522, -6.63052093],
                            [39.19658122, -6.6303609],
                            [39.19650923, -6.63016085],
                            [39.19634923, -6.62995281],
                            [39.19611724, -6.62974477],
                            [39.19595724, -6.62961674],
                            [39.19583724, -6.62944871],
                            [39.19574124, -6.62926467],
                            [39.19559725, -6.62879257],
                            [39.19534926, -6.62800841],
                            [39.19506126, -6.62754431],
                            [39.19466927, -6.62733627],
                            [39.19427728, -6.62713622],
                            [39.19398129, -6.62681616],
                            [39.19370929, -6.62640007],
                            [39.1933893, -6.62597598],
                            [39.19299731, -6.62536785],
                            [39.19255732, -6.62491976],
                            [39.19233333, -6.62457569],
                            [39.19226133, -6.62435964],
                            [39.19202134, -6.62403158],
                            [39.19184534, -6.62380753],
                            [39.19164534, -6.6236875],
                            [39.19140535, -6.62361549],
                            [39.19127735, -6.62358348],
                            [39.19111735, -6.62361549],
                            [39.19093336, -6.62374351],
                            [39.19085336, -6.62390355],
                            [39.19082136, -6.6241756],
                            [39.19075736, -6.62441565],
                            [39.19067736, -6.6246797],
                            [39.19052536, -6.62500777],
                            [39.19049336, -6.62523182],
                            [39.19031736, -6.62560789],
                            [39.19023736, -6.62581594],
                            [39.19012536, -6.62617601],
                            [39.19004537, -6.62634405],
                            [39.18982137, -6.62653608],
                            [39.18974137, -6.62655209],
                            [39.18958307, -6.62657145],
                            [39.18920028, -6.62671503],
                            [39.18884143, -6.62681872],
                            [39.18843472, -6.62686658],
                            [39.18801206, -6.62689848],
                            [39.18762131, -6.62692241],
                            [39.18723055, -6.62693836],
                            [39.18682384, -6.62687455],
                            [39.18636132, -6.62673895],
                            [39.18609836, -6.62659984],
                            [39.18540916, -6.62642412],
                            [39.18462537, -6.62612675],
                            [39.18424698, -6.62588345],
                            [39.1835713, -6.62550498],
                            [39.18343168, -6.62535558],
                            [39.18299021, -6.6248832],
                            [39.18262535, -6.62454528],
                            [39.18220642, -6.62427495],
                            [39.1816929, -6.62393703],
                            [39.1809569, -6.62383065],
                            [39.18074075, -6.6237721],
                            [39.18073316, -6.62377004],
                            [39.18050475, -6.62377004],
                            [39.18022041, -6.62374673],
                            [39.17983352, -6.62364882],
                            [39.17953985, -6.62356955],
                            [39.17925085, -6.62344367],
                            [39.17886396, -6.62321987],
                            [39.17852368, -6.62301939],
                            [39.17814145, -6.62281424],
                            [39.17763802, -6.6225578],
                            [39.17712527, -6.62227806],
                            [39.17671041, -6.62205426],
                            [39.17636547, -6.62174188],
                            [39.1761091, -6.62149011],
                            [39.17576416, -6.62108914],
                            [39.17551711, -6.62073479],
                            [39.17475072, -6.62000234],
                            [39.17441805, -6.6196091],
                            [39.17397197, -6.61877722],
                            [39.17360905, -6.61795292],
                            [39.17328395, -6.61717398],
                            [39.17224016, -6.61581746],
                            [39.17160713, -6.61490403],
                            [39.17099486, -6.61420858],
                            [39.17043448, -6.61329514],
                            [39.17007127, -6.61266197],
                            [39.16990523, -6.61205994],
                            [39.16947975, -6.6113022],
                            [39.1691373, -6.61039915],
                            [39.16887786, -6.60945458],
                            [39.16843163, -6.60835432],
                            [39.16755002, -6.60673931],
                            [39.1661257, -6.60413011],
                            [39.16515637, -6.60204184],
                            [39.1648269, -6.60133204],
                            [39.16347815, -6.59898366],
                            [39.16287871, -6.59648539],
                            [39.16286586, -6.5963504],
                            [39.1626789, -6.59438684],
                            [39.1620295, -6.59233826],
                            [39.1612802, -6.59048954],
                            [39.15963172, -6.58804122],
                            [39.15827733, -6.58653357],
                            [39.15653458, -6.5845936],
                            [39.15473624, -6.58194543],
                            [39.15288794, -6.57914736],
                            [39.15242093, -6.5783132],
                            [39.15148923, -6.57664908],
                            [39.14904149, -6.57410084],
                            [39.14714324, -6.57295163],
                            [39.14394618, -6.57185238],
                            [39.14208362, -6.57088038],
                            [39.14164829, -6.5706532],
                            [39.14014967, -6.57015354],
                            [39.13899783, -6.57001528],
                            [39.13890081, -6.57000364],
                            [39.13840127, -6.5700536],
                            [39.13790173, -6.57105291],
                            [39.137602, -6.57145263],
                            [39.13660292, -6.57185235],
                            [39.13597699, -6.57186303],
                            [39.13557176, -6.5718167],
                            [39.1354502, -6.57178775],
                            [39.13501602, -6.57158509],
                            [39.13492919, -6.57154456],
                            [39.13474394, -6.57144033],
                            [39.13448344, -6.57115661],
                            [39.1343445, -6.57101185],
                            [39.13410716, -6.57085551],
                            [39.13373666, -6.57060074],
                            [39.133453, -6.57034596],
                            [39.13304778, -6.56997538],
                            [39.13213089, -6.56925113],
                            [39.13114089, -6.56843564],
                            [39.13058349, -6.56789476],
                            [39.12976819, -6.5670543],
                            [39.12906104, -6.56633035],
                            [39.12903608, -6.5662721],
                            [39.12855632, -6.56589045],
                            [39.12669751, -6.56457127],
                            [39.12585662, -6.56417286],
                            [39.12504229, -6.56390726],
                            [39.12405977, -6.56391611],
                            [39.12325429, -6.56395152],
                            [39.12266124, -6.56386298],
                            [39.12220982, -6.56361508],
                            [39.12129812, -6.56313699],
                            [39.12035986, -6.56253495],
                            [39.11972527, -6.56197945],
                            [39.11972183, -6.56199188],
                            [39.11863317, -6.56070011],
                            [39.11862802, -6.56070085],
                            [39.11863273, -6.56069771],
                            [39.11778817, -6.56063955],
                            [39.11640523, -6.56003435],
                            [39.11585634, -6.55957986],
                            [39.11428322, -6.55949652],
                            [39.11145272, -6.55773299],
                            [39.11098687, -6.55745054],
                            [39.11045987, -6.55689192],
                            [39.11000518, -6.55657467],
                            [39.10886125, -6.55661265],
                            [39.10846944, -6.5557637],
                            [39.10721104, -6.55589557],
                            [39.10602968, -6.5557032],
                            [39.10505329, -6.55526584],
                            [39.10376917, -6.55423234],
                            [39.10237874, -6.55301548],
                            [39.10176281, -6.55180017],
                            [39.10145301, -6.55104244],
                            [39.10085219, -6.55106181],
                            [39.10064285, -6.55001431],
                            [39.10055521, -6.54946141],
                            [39.09969219, -6.54978428],
                            [39.0989283, -6.54970189],
                            [39.09833085, -6.54905182],
                            [39.09786222, -6.5485386],
                            [39.0969141, -6.54756994],
                            [39.0957192, -6.54670077],
                            [39.09529296, -6.54639071],
                            [39.09471624, -6.54459775],
                            [39.09421688, -6.54440803],
                            [39.09388609, -6.54382374],
                            [39.09260222, -6.54308362],
                            [39.09216323, -6.54283056],
                            [39.09051396, -6.54123623],
                            [39.08973658, -6.53909964],
                            [39.08762738, -6.5367495],
                            [39.08699065, -6.53571906],
                            [39.08522535, -6.53218731],
                            [39.08311961, -6.53012567],
                            [39.08264991, -6.52952009],
                            [39.08256527, -6.52826316],
                            [39.08346357, -6.52894395],
                            [39.08433612, -6.52845946],
                            [39.08430532, -6.52687866],
                            [39.08333628, -6.52608342],
                            [39.08351164, -6.52529628],
                            [39.08266858, -6.52440704],
                            [39.08216403, -6.52379037],
                            [39.08187387, -6.52274383],
                            [39.08156125, -6.52079743],
                            [39.08052066, -6.52172274],
                            [39.0799928, -6.52298757],
                            [39.07988977, -6.52401609],
                            [39.07912682, -6.52401443],
                            [39.0785304, -6.5234451],
                            [39.07791153, -6.52388016],
                            [39.07702331, -6.52497661],
                            [39.07650048, -6.52475255],
                            [39.07596732, -6.52463249],
                            [39.07546416, -6.52413124],
                            [39.07446887, -6.5240287],
                            [39.07331699, -6.52435527],
                            [39.07347969, -6.523476],
                            [39.07356256, -6.52269018],
                            [39.0728401, -6.52316113],
                            [39.07356262, -6.52269008],
                            [39.0728239, -6.52146119],
                            [39.07232705, -6.52057857],
                            [39.07187409, -6.51951062],
                            [39.07140956, -6.5184428],
                            [39.07048837, -6.51783132],
                            [39.07102024, -6.51692365],
                            [39.07208986, -6.51557017],
                            [39.07178084, -6.5140038],
                            [39.07167553, -6.51481348],
                            [39.07125051, -6.51593914],
                            [39.07060623, -6.51619008],
                            [39.06943393, -6.51583591],
                            [39.06865362, -6.51448355],
                            [39.06757471, -6.51419745],
                            [39.06702371, -6.51269198],
                            [39.06603032, -6.51094981],
                            [39.06473687, -6.51015842],
                            [39.06380138, -6.51023996],
                            [39.06337894, -6.51066118],
                            [39.06239823, -6.51082413],
                            [39.06192189, -6.50973338],
                            [39.060465, -6.50881709],
                            [39.05942605, -6.50894616],
                            [39.05774484, -6.50857552],
                            [39.05655795, -6.50797905],
                            [39.05421023, -6.50792891],
                            [39.05171779, -6.50740719],
                            [39.05012834, -6.50606539],
                            [39.05024404, -6.50425094],
                            [39.05227291, -6.50468634],
                            [39.05318866, -6.50578291],
                            [39.05479959, -6.50517296],
                            [39.05520313, -6.50326239],
                            [39.05306947, -6.50185841],
                            [39.05323672, -6.50045901],
                            [39.05263672, -6.49927795],
                            [39.0532535, -6.49905002],
                            [39.05381584, -6.49871935],
                            [39.05506898, -6.49816027],
                            [39.05609488, -6.4979159],
                            [39.056534, -6.49789861],
                            [39.05666379, -6.49810478],
                            [39.05748881, -6.49842885],
                            [39.05853605, -6.49804565],
                            [39.05845195, -6.49731643],
                            [39.0584514, -6.49731623],
                            [39.05822197, -6.49706001],
                            [39.05762331, -6.49621837],
                            [39.05734814, -6.49567534],
                            [39.05735293, -6.49520182],
                            [39.05760035, -6.4947299],
                            [39.05770569, -6.49387126],
                            [39.05745448, -6.49335036],
                            [39.05697133, -6.49310444],
                            [39.0569189, -6.49264476],
                            [39.05670009, -6.49236496],
                            [39.05659223, -6.49196529],
                            [39.05659923, -6.49154931],
                            [39.05601227, -6.49130764],
                            [39.05528884, -6.49141784],
                            [39.05491851, -6.49169847],
                            [39.05437784, -6.49205527],
                            [39.05396501, -6.49242993],
                            [39.05361676, -6.49238641],
                            [39.0529061, -6.49223051],
                            [39.05249129, -6.49195874],
                            [39.05178777, -6.49198733],
                            [39.05123445, -6.49142095],
                            [39.05039246, -6.49115499],
                            [39.04935219, -6.49083932],
                            [39.04847024, -6.49043643],
                            [39.04744856, -6.49000458],
                            [39.04720224, -6.48961046],
                            [39.04675056, -6.48898223],
                            [39.04458965, -6.4888196],
                            [39.04458965, -6.48881687],
                            [39.04419593, -6.48849784],
                            [39.04364958, -6.48811567],
                            [39.04300425, -6.48727545],
                            [39.04223153, -6.48671759],
                            [39.04150523, -6.48703584],
                            [39.04119162, -6.48756836],
                            [39.04167283, -6.48834593],
                            [39.04217296, -6.48901874],
                            [39.04174457, -6.48927866],
                            [39.04125403, -6.48884803],
                            [39.04070405, -6.48837362],
                            [39.04009633, -6.48847907],
                            [39.03960878, -6.48899552],
                            [39.03956403, -6.48932082],
                            [39.03985165, -6.48987522],
                            [39.03994784, -6.49055286],
                            [39.03973435, -6.49070008],
                            [39.03929123, -6.49059101],
                            [39.03866422, -6.49021206],
                            [39.03796257, -6.48969755],
                            [39.03737651, -6.48947873],
                            [39.03684222, -6.48910758],
                            [39.03598727, -6.48938479],
                            [39.03537954, -6.48949026],
                            [39.03414561, -6.48981749],
                            [39.0333242, -6.49035902],
                            [39.03230577, -6.49000748],
                            [39.0312315, -6.48941565],
                            [39.02998976, -6.48954676],
                            [39.02947926, -6.48977538],
                            [39.02899231, -6.4900146],
                            [39.02855973, -6.49017072],
                            [39.02796478, -6.49088788],
                            [39.027344, -6.49153678],
                            [39.02686259, -6.49162561],
                            [39.02645767, -6.49189613],
                            [39.02592325, -6.49181381],
                            [39.02530966, -6.49119173],
                            [39.0244563, -6.49063715],
                            [39.02396118, -6.49037996],
                            [39.0232474, -6.49043198],
                            [39.02319786, -6.49121944],
                            [39.0232678, -6.49181729],
                            [39.02280349, -6.49175526],
                            [39.02233465, -6.49128908],
                            [39.02203735, -6.49078123],
                            [39.02156535, -6.4902343],
                            [39.02095022, -6.4901553],
                            [39.02028992, -6.49038992],
                            [39.01999006, -6.49097962],
                            [39.02000191, -6.49156831],
                            [39.01966567, -6.4918245],
                            [39.01898554, -6.491852],
                            [39.01819581, -6.491445],
                            [39.01746133, -6.49126681],
                            [39.01698594, -6.49121667],
                            [39.01584391, -6.49066222],
                            [39.01459029, -6.49020462],
                            [39.01354372, -6.48972715],
                            [39.0125424, -6.48922472],
                            [39.01221134, -6.4890302],
                            [39.01203578, -6.48867923],
                            [39.01137066, -6.48792062],
                            [39.01124862, -6.48717468],
                            [39.01116892, -6.48691219],
                            [39.01111606, -6.48645231],
                            [39.01091371, -6.48600995],
                            [39.01082203, -6.48573644],
                            [39.01054054, -6.48504317],
                            [39.01019483, -6.48447957],
                            [39.00870458, -6.48300346],
                            [39.00778003, -6.48152761],
                            [39.00695633, -6.48055591],
                            [39.00621975, -6.47974242],
                            [39.00517142, -6.47892998],
                            [39.00469419, -6.47883375],
                            [39.00469414, -6.47883416],
                            [39.00460177, -6.47881235],
                            [39.00449889, -6.47878277],
                            [39.00436735, -6.47874301],
                            [39.00393397, -6.47858726],
                            [39.00327424, -6.47825583],
                            [39.00266575, -6.47776061],
                            [39.00201985, -6.47748638],
                            [39.00154062, -6.47646611],
                            [39.00075044, -6.47604754],
                            [39.00040945, -6.47531047],
                            [39.00002373, -6.47460986],
                            [38.99998375, -6.47458786],
                            [38.99939814, -6.4742655],
                            [38.9987698, -6.47385193],
                            [38.99801193, -6.4733743],
                            [38.99739824, -6.47332979],
                            [38.99696716, -6.4732317],
                            [38.99661485, -6.47302013],
                            [38.99621019, -6.47277713],
                            [38.99587242, -6.47212078],
                            [38.99483003, -6.47174705],
                            [38.99413392, -6.47137088],
                            [38.99339151, -6.47157416],
                            [38.99273263, -6.47155458],
                            [38.99149785, -6.47156983],
                            [38.99017826, -6.47148454],
                            [38.98832101, -6.47152492],
                            [38.98683859, -6.47170033],
                            [38.98518224, -6.47214845],
                            [38.9832184, -6.47241261],
                            [38.98149811, -6.47241278],
                            [38.98049544, -6.47187568],
                            [38.98049537, -6.47187568],
                            [38.97972546, -6.47173913],
                            [38.97873893, -6.47156743],
                            [38.9784387, -6.47151598],
                            [38.97833066, -6.47150295],
                            [38.97825897, -6.47149418],
                            [38.97820968, -6.47148815],
                            [38.97818722, -6.47148549],
                            [38.97817901, -6.47148507],
                            [38.97817079, -6.47148445],
                            [38.97817079, -6.47148439],
                            [38.97700625, -6.47094231],
                            [38.97612531, -6.47056203],
                            [38.97536555, -6.47032705],
                            [38.97501795, -6.47000605],
                            [38.97501773, -6.47000619],
                            [38.97498173, -6.46998178],
                            [38.97484645, -6.46988143],
                            [38.97458699, -6.46970527],
                            [38.97376227, -6.46921386],
                            [38.97320529, -6.46892709],
                            [38.972867, -6.46875291],
                            [38.97171678, -6.46827953],
                            [38.96994887, -6.46736901],
                            [38.96896744, -6.46678484],
                            [38.96763325, -6.46633042],
                            [38.96644342, -6.46573155],
                            [38.96635105, -6.46569213],
                            [38.9655039, -6.46533051],
                            [38.96435368, -6.46485711],
                            [38.96281429, -6.46446872],
                            [38.96202085, -6.46425815],
                            [38.9615769, -6.4641259],
                            [38.96082319, -6.463752],
                            [38.95973985, -6.46321814],
                            [38.9591627, -6.46292953],
                            [38.95806181, -6.46253498],
                            [38.95710802, -6.46206519],
                            [38.95665458, -6.46140201],
                            [38.95665454, -6.46140199],
                            [38.95665455, -6.46140196],
                            [38.95593006, -6.46059945],
                            [38.95535815, -6.45986009],
                            [38.95481432, -6.45924671],
                            [38.9546596, -6.45900521],
                            [38.95469648, -6.45891879],
                            [38.95478804, -6.45870426],
                            [38.95487879, -6.45836589],
                            [38.95503964, -6.4580478],
                            [38.95508513, -6.45773428],
                            [38.95516804, -6.45689977],
                            [38.95535719, -6.45641893],
                            [38.95543779, -6.45611556],
                            [38.95558216, -6.45567115],
                            [38.95564864, -6.45559924],
                            [38.95563571, -6.45556512],
                            [38.95566898, -6.45552916],
                            [38.95582503, -6.45538444],
                            [38.95588179, -6.4553591],
                            [38.95591104, -6.45521937],
                            [38.95601239, -6.4548575],
                            [38.95618072, -6.45443522],
                            [38.95610296, -6.4542189],
                            [38.95594448, -6.45400585],
                            [38.95580516, -6.45398824],
                            [38.955449, -6.45403705],
                            [38.95525565, -6.45411395],
                            [38.95500465, -6.45419318],
                            [38.95466095, -6.45426453],
                            [38.95451279, -6.45431657],
                            [38.95434114, -6.454358],
                            [38.95418284, -6.45444505],
                            [38.95394246, -6.45450078],
                            [38.95364716, -6.45462789],
                            [38.95347907, -6.45476158],
                            [38.95337712, -6.45481178],
                            [38.95325387, -6.45490904],
                            [38.95309598, -6.45500766],
                            [38.95291734, -6.45516478],
                            [38.95277232, -6.45529752],
                            [38.9525559, -6.45537538],
                            [38.95231736, -6.45547721],
                            [38.95202246, -6.4556159],
                            [38.9517816, -6.45566005],
                            [38.9516446, -6.45570011],
                            [38.95150616, -6.45570558],
                            [38.95139175, -6.45573324],
                            [38.9510458, -6.45574691],
                            [38.950769, -6.45575791],
                            [38.95042304, -6.45577158],
                            [38.95007749, -6.45579682],
                            [38.94981267, -6.45581885],
                            [38.949315, -6.45579236],
                            [38.94900178, -6.45575863],
                            [38.94880713, -6.45580094],
                            [38.94855565, -6.45586866],
                            [38.94816041, -6.45580348],
                            [38.94786415, -6.45590756],
                            [38.94764683, -6.45596234],
                            [38.94747519, -6.45600376],
                            [38.94723344, -6.45602491],
                            [38.94685243, -6.45602843],
                            [38.94630451, -6.45590007],
                            [38.94595494, -6.45582154],
                            [38.94576777, -6.45575966],
                            [38.94561646, -6.45573101],
                            [38.94541635, -6.45563503],
                            [38.94506227, -6.45544128],
                            [38.94449971, -6.45523264],
                            [38.94412256, -6.45503977],
                            [38.94380401, -6.45486767],
                            [38.94352182, -6.4547403],
                            [38.94334478, -6.45464336],
                            [38.94315801, -6.45459306],
                            [38.94288738, -6.45446521],
                            [38.94253556, -6.45432906],
                            [38.94223165, -6.45423717],
                            [38.94183594, -6.45416048],
                            [38.94143844, -6.45403768],
                            [38.94109819, -6.45390106],
                            [38.94083995, -6.45379588],
                            [38.94054803, -6.45371509],
                            [38.94023126, -6.45358908],
                            [38.93990435, -6.45349808],
                            [38.93950816, -6.45340987],
                            [38.93900551, -6.45325658],
                            [38.93844255, -6.45303643],
                            [38.93810205, -6.45289186],
                            [38.93801973, -6.45285691],
                            [38.93773569, -6.45268344],
                            [38.93744159, -6.45254497],
                            [38.93714427, -6.45232588],
                            [38.93661855, -6.45217347],
                            [38.93559211, -6.45179893],
                            [38.9355915, -6.45179893],
                            [38.9354695, -6.45173404],
                            [38.93506133, -6.45163481],
                            [38.93479407, -6.45155731],
                            [38.93471087, -6.45153318],
                            [38.93441766, -6.45141779],
                            [38.93411416, -6.45133747],
                            [38.93395184, -6.4513208],
                            [38.93363957, -6.45131008],
                            [38.9332572, -6.451279],
                            [38.93292886, -6.45115347],
                            [38.93268309, -6.45107083],
                            [38.93247284, -6.4510099],
                            [38.93229581, -6.45091295],
                            [38.93184767, -6.45067671],
                            [38.9313853, -6.45037176],
                            [38.93086457, -6.45034621],
                            [38.93025451, -6.45010481],
                            [38.92970829, -6.44972231],
                            [38.92960361, -6.44970339],
                            [38.92936009, -6.44967835],
                            [38.92907974, -6.44959714],
                            [38.92849727, -6.44947006],
                            [38.92806159, -6.44925642],
                            [38.92773326, -6.44913089],
                            [38.92709136, -6.44895996],
                            [38.92642503, -6.44875547],
                            [38.92606389, -6.4486774],
                            [38.92574665, -6.4485398],
                            [38.92555859, -6.44845491],
                            [38.925429, -6.44839077],
                            [38.92519343, -6.44827313],
                            [38.92482602, -6.44803367],
                            [38.92447468, -6.44790902],
                            [38.92377146, -6.44764821],
                            [38.92326569, -6.44741421],
                            [38.92284199, -6.4472116],
                            [38.92238189, -6.44696425],
                            [38.9217613, -6.4467464],
                            [38.92133849, -6.44656687],
                            [38.92027896, -6.44605461],
                            [38.91983042, -6.44580678],
                            [38.91960636, -6.44568865],
                            [38.9195008, -6.44564665],
                            [38.91924305, -6.4455529],
                            [38.91900387, -6.44534305],
                            [38.91868077, -6.44505565],
                            [38.91835951, -6.44481435],
                            [38.91798976, -6.44451096],
                            [38.91782262, -6.44437382],
                            [38.9175217, -6.44406245],
                            [38.9170669, -6.44365323],
                            [38.91672113, -6.44344323],
                            [38.91671524, -6.44344144],
                            [38.91599974, -6.44294505],
                            [38.91527971, -6.44254634],
                            [38.91420419, -6.44191926],
                            [38.91346407, -6.44130197],
                            [38.91238678, -6.44062879],
                            [38.91191437, -6.43994975],
                            [38.91190335, -6.43994181],
                            [38.91190678, -6.43994187],
                            [38.91184512, -6.43986217],
                            [38.91124078, -6.43908087],
                            [38.91061371, -6.43840146],
                            [38.90964005, -6.43735638],
                            [38.90934808, -6.43704299],
                            [38.90866621, -6.43596581],
                            [38.90840298, -6.43554998],
                            [38.90831425, -6.4354098],
                            [38.90781751, -6.43451739],
                            [38.90710784, -6.43260595],
                            [38.90711631, -6.43228117],
                            [38.90647912, -6.43110209],
                            [38.90586968, -6.4301698],
                            [38.90529609, -6.42938093],
                            [38.90497345, -6.42877135],
                            [38.90465081, -6.42809006],
                            [38.90462851, -6.42801896],
                            [38.90467171, -6.42801683],
                            [38.90367839, -6.42547774],
                            [38.90324182, -6.42449524],
                            [38.90299904, -6.42394885],
                            [38.90300694, -6.42367234],
                            [38.90305152, -6.42211189],
                            [38.90298994, -6.42206649],
                            [38.9033938, -6.42057276],
                            [38.9032767, -6.41964226],
                            [38.90268, -6.41825738],
                            [38.90177938, -6.41589161],
                            [38.90234816, -6.4132946],
                            [38.90100444, -6.41081936],
                            [38.9006152, -6.40999896],
                            [38.90037536, -6.40949344],
                            [38.9004809, -6.40918962],
                            [38.90114389, -6.4072811],
                            [38.90125539, -6.40723093],
                            [38.90118749, -6.40718771],
                            [38.90045153, -6.40611183],
                            [38.9007346, -6.40492269],
                            [38.9011875, -6.40424319],
                            [38.90079122, -6.40260105],
                            [38.90080707, -6.40254443],
                            [38.90079122, -6.40254443],
                            [38.90033832, -6.40078904],
                            [38.89948914, -6.3998264],
                            [38.89824366, -6.39926014],
                            [38.89671512, -6.39897701],
                            [38.89541303, -6.39909025],
                            [38.89468811, -6.39925138],
                            [38.89388448, -6.39943],
                            [38.89241255, -6.39937337],
                            [38.8903745, -6.39897698],
                            [38.88839306, -6.3983541],
                            [38.8859021, -6.39733483],
                            [38.88550903, -6.39716929],
                            [38.88375082, -6.39642882],
                            [38.88120326, -6.39473005],
                            [38.87973133, -6.39371078],
                            [38.87859908, -6.39252164],
                            [38.87735361, -6.39127588],
                            [38.87650442, -6.390653],
                            [38.87531555, -6.38923736],
                            [38.8741833, -6.38816147],
                            [38.87231509, -6.38804821],
                            [38.87106995, -6.38838787],
                            [38.86982413, -6.38872771],
                            [38.86961364, -6.3887094],
                            [38.86830684, -6.38790058],
                            [38.86765981, -6.38462427],
                            [38.86650323, -6.38131036],
                            [38.86515502, -6.37811942],
                            [38.86447661, -6.37679647],
                            [38.86344318, -6.37478119],
                            [38.86115334, -6.37113097],
                            [38.8593392, -6.3679181],
                            [38.85913187, -6.3675509],
                            [38.85741196, -6.36489413],
                            [38.85510552, -6.36170632],
                            [38.85273014, -6.35793248],
                            [38.85045367, -6.35283856],
                            [38.84748189, -6.34767968],
                            [38.84475735, -6.34174903],
                            [38.84238138, -6.33706309],
                            [38.8400455, -6.33013587],
                            [38.83870333, -6.32411615],
                            [38.83812803, -6.31970556],
                            [38.83776927, -6.31432818],
                            [38.83791763, -6.31064797],
                            [38.83798696, -6.30892817],
                            [38.83880154, -6.30224618],
                            [38.84005228, -6.29401153],
                            [38.84136127, -6.28876473],
                            [38.84213883, -6.28291546],
                            [38.84427591, -6.27610055],
                            [38.84579072, -6.27139972],
                            [38.84819373, -6.26429725],
                            [38.85045746, -6.25866642],
                            [38.85148803, -6.25279555],
                            [38.85224654, -6.24884037],
                            [38.85231, -6.24860856],
                            [38.85334869, -6.24481384],
                            [38.85331283, -6.24481381],
                            [38.85335072, -6.24479296],
                            [38.85340933, -6.24432355],
                            [38.85339638, -6.24386221],
                            [38.85308643, -6.24268226],
                            [38.85286636, -6.24223845],
                            [38.85266137, -6.24192118],
                            [38.85242019, -6.24154723],
                            [38.85233421, -6.24136367],
                            [38.8521748, -6.24102336],
                            [38.85189711, -6.24058121],
                            [38.85143762, -6.24023666],
                            [38.85110458, -6.23987691],
                            [38.85093806, -6.23969704],
                            [38.85114693, -6.23950915],
                            [38.85130929, -6.23936311],
                            [38.85180063, -6.23919886],
                            [38.85238222, -6.23896275],
                            [38.85231292, -6.23837652],
                            [38.85233504, -6.23837684],
                            [38.85294763, -6.23802432],
                            [38.8535135, -6.23765007],
                            [38.85396645, -6.23735989],
                            [38.85426899, -6.237051],
                            [38.85482708, -6.23599594],
                            [38.85500634, -6.23499807],
                            [38.85541108, -6.23382049],
                            [38.85571575, -6.2327728],
                            [38.85616657, -6.23159388],
                            [38.85635996, -6.23068793],
                            [38.85652834, -6.22971344],
                            [38.85668814, -6.2288431],
                            [38.8573075, -6.22750924],
                            [38.85822473, -6.22610901],
                            [38.85888322, -6.22493561],
                            [38.85951834, -6.22375134],
                            [38.8600323, -6.22276682],
                            [38.86054299, -6.22166697],
                            [38.86105734, -6.22028998],
                            [38.86151934, -6.21909919],
                            [38.86153605, -6.21907814],
                            [38.861998, -6.2184966],
                            [38.86243516, -6.21805683],
                            [38.86281047, -6.21746878],
                            [38.86306397, -6.21705742],
                            [38.86318684, -6.21651134],
                            [38.8633584, -6.21605619],
                            [38.8633405, -6.21501785],
                            [38.86319245, -6.21467587],
                            [38.86314907, -6.21436547],
                            [38.86300136, -6.21403502],
                            [38.86269564, -6.21382458],
                            [38.86167116, -6.21347343],
                            [38.86123808, -6.21324361],
                            [38.86109298, -6.21300543],
                            [38.8608362, -6.21167392],
                            [38.86081549, -6.21161642],
                            [38.86053588, -6.21084001],
                            [38.86045605, -6.21005743],
                            [38.86038881, -6.20890509],
                            [38.85995132, -6.20770581],
                            [38.8596438, -6.20661817],
                            [38.85985367, -6.20548087],
                            [38.8594732, -6.20385285],
                            [38.85954493, -6.20231557],
                            [38.85998035, -6.20059459],
                            [38.86015296, -6.19977003],
                            [38.85998612, -6.19795111],
                            [38.86003375, -6.19678391],
                            [38.86037792, -6.1955042],
                            [38.86033721, -6.19325454],
                            [38.86045231, -6.19202765],
                            [38.8603193, -6.19058866],
                            [38.86002772, -6.18884262],
                            [38.85988809, -6.18757697],
                            [38.85986521, -6.18676964],
                            [38.8597693, -6.18582591],
                            [38.85983571, -6.1845081],
                            [38.86003045, -6.18324427],
                            [38.86006785, -6.18212354],
                            [38.86020722, -6.18134612],
                            [38.86035409, -6.18083397],
                            [38.86053193, -6.18019395],
                            [38.86069433, -6.17941586],
                            [38.86079153, -6.17877818],
                            [38.86087951, -6.17822157],
                            [38.86099053, -6.17766429],
                            [38.86098145, -6.17693736],
                            [38.86094819, -6.17649332],
                            [38.86096127, -6.17647238],
                            [38.86074686, -6.17580169],
                            [38.86077041, -6.17539707],
                            [38.8610565, -6.17489294],
                            [38.86115486, -6.17469404],
                            [38.86122671, -6.1743689],
                            [38.86144713, -6.17399353],
                            [38.86160929, -6.17359667],
                            [38.86158056, -6.1733897],
                            [38.86163678, -6.17291493],
                            [38.86179026, -6.17262219],
                            [38.86194309, -6.17230638],
                            [38.86192967, -6.17223749],
                            [38.86201525, -6.17199277],
                            [38.862041, -6.17166889],
                            [38.86208412, -6.17113677],
                            [38.8619812, -6.17074715],
                            [38.86195643, -6.17026306],
                            [38.8621835, -6.16928732],
                            [38.86177494, -6.16868675],
                            [38.86179641, -6.16862845],
                            [38.86175868, -6.16851406],
                            [38.86158677, -6.16771081],
                            [38.86139642, -6.16665414],
                            [38.86125073, -6.1659656],
                            [38.86102625, -6.16534845],
                            [38.86060886, -6.16442495],
                            [38.85992167, -6.16333568],
                            [38.85921221, -6.16269716],
                            [38.85875391, -6.16238651],
                            [38.85838196, -6.16228124],
                            [38.85753069, -6.1623622],
                            [38.85649912, -6.16259814],
                            [38.85596915, -6.16277498],
                            [38.85548191, -6.16293756],
                            [38.85439637, -6.16288641],
                            [38.85425271, -6.16269413],
                            [38.85423254, -6.16195598],
                            [38.85439897, -6.16129354],
                            [38.85456792, -6.16072336],
                            [38.85456036, -6.16044655],
                            [38.85420012, -6.15992546],
                            [38.85384051, -6.15942742],
                            [38.85374106, -6.15916467],
                            [38.8540395, -6.15826778],
                            [38.85404913, -6.15819827],
                            [38.85417419, -6.15771009],
                            [38.85382389, -6.15713101],
                            [38.85371639, -6.15699543],
                            [38.85353374, -6.15664262],
                            [38.8531844, -6.15609813],
                            [38.85276069, -6.15578653],
                            [38.85210805, -6.15553889],
                            [38.85143804, -6.15549947],
                            [38.85053407, -6.15533947],
                            [38.8498541, -6.15535804],
                            [38.84909126, -6.15529807],
                            [38.84858039, -6.15517351],
                            [38.8479505, -6.1549137],
                            [38.84746916, -6.15460366],
                            [38.84728036, -6.15444722],
                            [38.84696496, -6.15387873],
                            [38.84678188, -6.15308732],
                            [38.84694152, -6.15259819],
                            [38.84663842, -6.15247951],
                            [38.84579806, -6.15253707],
                            [38.84515891, -6.15247242],
                            [38.84462997, -6.15241891],
                            [38.84385435, -6.15231311],
                            [38.84327956, -6.15195945],
                            [38.84241496, -6.15112893],
                            [38.84160212, -6.15050476],
                            [38.84078468, -6.15013464],
                            [38.84034423, -6.15005431],
                            [38.83947547, -6.14991642],
                            [38.83884653, -6.1496912],
                            [38.83840689, -6.14921843],
                            [38.83812388, -6.14856825],
                            [38.83813926, -6.14786376],
                            [38.83821193, -6.14714618],
                            [38.83821673, -6.1464766],
                            [38.83828254, -6.14594697],
                            [38.83828292, -6.14594386],
                            [38.83826592, -6.14532105],
                            [38.83819111, -6.14511533],
                            [38.83799129, -6.14455522],
                            [38.83777869, -6.14394929],
                            [38.83754177, -6.14329786],
                            [38.83731368, -6.14296936],
                            [38.83713483, -6.14275494],
                            [38.83652473, -6.1419521],
                            [38.83602245, -6.14129636],
                            [38.83569095, -6.14098222],
                            [38.83527104, -6.14080899],
                            [38.83481088, -6.14042912],
                            [38.83461013, -6.1398344],
                            [38.83459672, -6.13976552],
                            [38.83455773, -6.13960499],
                            [38.83468816, -6.13931289],
                            [38.83461732, -6.13883005],
                            [38.83462192, -6.13857599],
                            [38.83462275, -6.13818354],
                            [38.83462085, -6.13811434],
                            [38.83393576, -6.13752129],
                            [38.83357807, -6.13709246],
                            [38.83389697, -6.13694525],
                            [38.83368293, -6.1367087],
                            [38.83336976, -6.13622094],
                            [38.83312669, -6.13576588],
                            [38.8329076, -6.1353448],
                            [38.83246421, -6.13473363],
                            [38.83189688, -6.1342297],
                            [38.83173669, -6.13385318],
                            [38.83157554, -6.13344206],
                            [38.83108845, -6.13249735],
                            [38.83049305, -6.13180952],
                            [38.83017414, -6.13195672],
                            [38.82980516, -6.1323823],
                            [38.82965245, -6.13269783],
                            [38.82949948, -6.1330139],
                            [38.82939516, -6.13426329],
                            [38.82913285, -6.1352169],
                            [38.82900514, -6.13518575],
                            [38.82888801, -6.13511969],
                            [38.82868883, -6.13458264],
                            [38.82861359, -6.13393834],
                            [38.82870284, -6.13340497],
                            [38.82872051, -6.13193864],
                            [38.82880063, -6.13107081],
                            [38.828765, -6.13061009],
                            [38.82858887, -6.13007242],
                            [38.82834329, -6.1295251],
                            [38.82818466, -6.12920624],
                            [38.82775867, -6.12838683],
                            [38.8274255, -6.12758796],
                            [38.82702507, -6.12686019],
                            [38.82690429, -6.12649145],
                            [38.82684895, -6.12632251],
                            [38.82688097, -6.12495967],
                            [38.82662501, -6.12403174],
                            [38.82628755, -6.12349846],
                            [38.82576885, -6.12308554],
                            [38.82574424, -6.1230285],
                            [38.82563328, -6.12276606],
                            [38.82522166, -6.12205014],
                            [38.82446273, -6.12128595],
                            [38.82339191, -6.12008014],
                            [38.82297242, -6.11907587],
                            [38.82291584, -6.11826947],
                            [38.82305233, -6.11735392],
                            [38.82318987, -6.11690004],
                            [38.82324707, -6.11645987],
                            [38.8230478, -6.11549577],
                            [38.82236912, -6.11429081],
                            [38.82174347, -6.11333833],
                            [38.82114809, -6.11265048],
                            [38.81987931, -6.11179633],
                            [38.81890899, -6.11131491],
                            [38.81830924, -6.11173523],
                            [38.81762294, -6.11236566],
                            [38.81687934, -6.11300919],
                            [38.81639324, -6.1133687],
                            [38.81586724, -6.11353307],
                            [38.81552958, -6.1134153],
                            [38.8151736, -6.11347118],
                            [38.81472931, -6.11409501],
                            [38.8141044, -6.11486226],
                            [38.81398067, -6.11582362],
                            [38.81355795, -6.11681622],
                            [38.81369372, -6.11798981],
                            [38.81373227, -6.11898138],
                            [38.81439302, -6.11952895],
                            [38.81412775, -6.11995169],
                            [38.81400382, -6.12005893],
                            [38.81397598, -6.12005738],
                            [38.81386521, -6.12005117],
                            [38.81361881, -6.11989629],
                            [38.81345308, -6.11973922],
                            [38.8132293, -6.11914512],
                            [38.81301339, -6.11883937],
                            [38.81290433, -6.11864613],
                            [38.81265132, -6.11824904],
                            [38.81249795, -6.1176992],
                            [38.81234092, -6.11743801],
                            [38.81215612, -6.11700445],
                            [38.81210028, -6.11664817],
                            [38.81208826, -6.11639606],
                            [38.81207335, -6.11608334],
                            [38.81197824, -6.11555499],
                            [38.81190187, -6.11529161],
                            [38.8123909, -6.11419333],
                            [38.81245994, -6.11376439],
                            [38.81261928, -6.11326375],
                            [38.81284557, -6.11268048],
                            [38.81294321, -6.11203146],
                            [38.81297391, -6.11146506],
                            [38.81330884, -6.11063645],
                            [38.81330973, -6.11062272],
                            [38.81334949, -6.11001207],
                            [38.81333754, -6.1095738],
                            [38.8132882, -6.10903267],
                            [38.81326096, -6.10845631],
                            [38.81316772, -6.10799717],
                            [38.81321078, -6.107038],
                            [38.81302441, -6.10654677],
                            [38.8126876, -6.10603655],
                            [38.81236925, -6.10577975],
                            [38.81207518, -6.10556846],
                            [38.81182512, -6.10570224],
                            [38.8111846, -6.10505024],
                            [38.81098337, -6.10485951],
                            [38.81078338, -6.1047149],
                            [38.81007718, -6.10419166],
                            [38.80938284, -6.10367964],
                            [38.8089881, -6.10315945],
                            [38.80795749, -6.10173321],
                            [38.80705363, -6.10030352],
                            [38.80622154, -6.09939127],
                            [38.80555894, -6.0987745],
                            [38.80499158, -6.0978435],
                            [38.80438431, -6.09671736],
                            [38.80376437, -6.09597246],
                            [38.80329956, -6.09541955],
                            [38.80318611, -6.09506484],
                            [38.80318548, -6.09504177],
                            [38.8028444, -6.09479713],
                            [38.80267584, -6.09453625],
                            [38.80285249, -6.09340032],
                            [38.80271044, -6.09326568],
                            [38.80180451, -6.0921823],
                            [38.80106368, -6.09123293],
                            [38.80101727, -6.09122266],
                            [38.80031048, -6.09067633],
                            [38.79970867, -6.09017332],
                            [38.79943452, -6.08984605],
                            [38.799022, -6.08909551],
                            [38.79806319, -6.0881867],
                            [38.7973538, -6.08712104],
                            [38.79709548, -6.08652789],
                            [38.7969433, -6.08559712],
                            [38.79675946, -6.08519815],
                            [38.79604601, -6.08398256],
                            [38.79536461, -6.08267376],
                            [38.79502774, -6.08173648],
                            [38.79486318, -6.08119848],
                            [38.79459797, -6.08035159],
                            [38.79417942, -6.07895484],
                            [38.79375029, -6.07759302],
                            [38.79370996, -6.07753217],
                            [38.79350978, -6.07723021],
                            [38.79301599, -6.07645878],
                            [38.79199196, -6.07484766],
                            [38.79118352, -6.07353078],
                            [38.79064388, -6.07234509],
                            [38.79000303, -6.07125447],
                            [38.7895232, -6.07014795],
                            [38.78889474, -6.06866457],
                            [38.78824207, -6.06756274],
                            [38.78804568, -6.06713359],
                            [38.78750647, -6.06595532],
                            [38.78716249, -6.06517981],
                            [38.78670518, -6.06447665],
                            [38.7859489, -6.06380468],
                            [38.7853381, -6.06339424],
                            [38.78513939, -6.06324512],
                            [38.78499673, -6.06313806],
                            [38.78476964, -6.06284414],
                            [38.7848181, -6.06279292],
                            [38.7848369, -6.06277305],
                            [38.78510748, -6.0625464],
                            [38.78525876, -6.06217294],
                            [38.78521017, -6.06208193],
                            [38.78527608, -6.06153765],
                            [38.78488665, -6.06078647],
                            [38.78462896, -6.06044804],
                            [38.78445615, -6.06022107],
                            [38.78408922, -6.05987325],
                            [38.78384191, -6.05968376],
                            [38.78348839, -6.05940482],
                            [38.78270468, -6.05857201],
                            [38.78192948, -6.05793637],
                            [38.78191447, -6.05792406],
                            [38.78128903, -6.05739858],
                            [38.78131207, -6.05739796],
                            [38.781246, -6.05708812],
                            [38.78121966, -6.0561193],
                            [38.78121058, -6.05597224],
                            [38.78117842, -6.05545098],
                            [38.78114503, -6.055071],
                            [38.78113029, -6.05452892],
                            [38.78108193, -6.05402239],
                            [38.78104344, -6.05303082],
                            [38.78085398, -6.05242424],
                            [38.78065733, -6.05205598],
                            [38.78059883, -6.05194642],
                            [38.78025981, -6.05092839],
                            [38.78002662, -6.04956122],
                            [38.77970594, -6.04836956],
                            [38.77956572, -6.04787707],
                            [38.77926299, -6.04607319],
                            [38.77909462, -6.04496973],
                            [38.77883007, -6.04414589],
                            [38.77857886, -6.04296389],
                            [38.77837568, -6.04227689],
                            [38.77838689, -6.04226504],
                            [38.77851067, -6.04130369],
                            [38.778467, -6.04097015],
                            [38.77834762, -6.0403963],
                            [38.77835852, -6.04037292],
                            [38.77842915, -6.04000166],
                            [38.77851474, -6.03975694],
                            [38.77846171, -6.03930868],
                            [38.77845485, -6.03925072],
                            [38.77841624, -6.03905226],
                            [38.77837443, -6.03883739],
                            [38.77836135, -6.03878004],
                            [38.77835278, -6.03869436],
                            [38.77829801, -6.03814694],
                            [38.77826601, -6.03812366],
                            [38.77814509, -6.03803568],
                            [38.77813788, -6.03777041],
                            [38.77819852, -6.03753374],
                            [38.77824212, -6.03736361],
                            [38.77821187, -6.03709896],
                            [38.77820685, -6.03691443],
                            [38.77820371, -6.03679909],
                            [38.77832262, -6.0365073],
                            [38.77833039, -6.03636859],
                            [38.77843871, -6.03611172],
                            [38.77848166, -6.03599513],
                            [38.77853614, -6.03587823],
                            [38.77865567, -6.03560952],
                            [38.77865504, -6.03558644],
                            [38.77873319, -6.03549199],
                            [38.77873287, -6.03548045],
                            [38.77885966, -6.03531751],
                            [38.77904483, -6.03507954],
                            [38.77903323, -6.0346528],
                            [38.7790123, -6.0343071],
                            [38.7790471, -6.03389065],
                            [38.77886423, -6.03352627],
                            [38.7786664, -6.03303534],
                            [38.77841401, -6.03223425],
                            [38.77837152, -6.0315198],
                            [38.77787278, -6.03117615],
                            [38.77780749, -6.03113116],
                            [38.77777755, -6.03087805],
                            [38.77769619, -6.03043012],
                            [38.77751881, -6.02941924],
                            [38.77736267, -6.02876559],
                            [38.77734065, -6.02753118],
                            [38.77734182, -6.02715027],
                            [38.77730186, -6.02652809],
                            [38.77715811, -6.02548166],
                            [38.77708529, -6.02407551],
                            [38.77713279, -6.02327781],
                            [38.77713295, -6.02243524],
                            [38.77712065, -6.02155838],
                            [38.7771386, -6.02094617],
                            [38.77729347, -6.01985699],
                            [38.77727027, -6.01900352],
                            [38.77732334, -6.01798638],
                            [38.7774558, -6.0169209],
                            [38.77745752, -6.01613599],
                            [38.77765849, -6.01504557],
                            [38.77802044, -6.01393924],
                            [38.77823332, -6.0132871],
                            [38.77859034, -6.01242327],
                            [38.77881536, -6.01179389],
                            [38.77940837, -6.01070435],
                            [38.77943823, -6.01053041],
                            [38.78000475, -6.00931463],
                            [38.78013455, -6.00899947],
                            [38.78054791, -6.00808795],
                            [38.78088069, -6.00760568],
                            [38.78085632, -6.00713311],
                            [38.78128591, -6.00639428],
                            [38.78187961, -6.00576674],
                            [38.78211529, -6.00551762],
                            [38.78230387, -6.00481996],
                            [38.7825426, -6.00427099],
                            [38.78270852, -6.00401255],
                            [38.78294349, -6.00332518],
                            [38.78368595, -6.00222003],
                            [38.78423913, -6.00136242],
                            [38.78431225, -6.00108341],
                            [38.78499248, -6.00023388],
                            [38.7850261, -6.00019835],
                            [38.78539094, -5.99962286],
                            [38.78537423, -5.99858452],
                            [38.78554719, -5.99773725],
                            [38.78526158, -5.9974103],
                            [38.78637505, -5.99553328],
                            [38.78644334, -5.99523659],
                            [38.78644465, -5.99523091],
                            [38.78604609, -5.99531763],
                            [38.78608155, -5.99522186],
                            [38.78665025, -5.99368584],
                            [38.78716502, -5.99262197],
                            [38.78760459, -5.99131769],
                            [38.7880822, -5.99015092],
                            [38.78816431, -5.98989986],
                            [38.7887562, -5.98809023],
                            [38.78934289, -5.98576637],
                            [38.79018625, -5.98402441],
                            [38.79077177, -5.98165441],
                            [38.79142516, -5.98014828],
                            [38.79212305, -5.97812159],
                            [38.79311176, -5.97529083],
                            [38.79470098, -5.97251352],
                            [38.79586399, -5.97019755],
                            [38.79614409, -5.9696348],
                            [38.79709227, -5.96772983],
                            [38.7978605, -5.96620913],
                            [38.79777934, -5.96527636],
                            [38.79684334, -5.96380052],
                            [38.7966524, -5.96347801],
                            [38.79598882, -5.96235718],
                            [38.79496603, -5.96011822],
                            [38.80531597, -5.94114126],
                            [38.81524462, -5.93211423],
                            [38.81614726, -5.90954661],
                            [38.81434212, -5.86350866],
                            [38.82066039, -5.83462212],
                            [38.82558457, -5.82572586],
                            [38.83024769, -5.81730124],
                            [38.84368636, -5.79302229],
                            [38.84864116, -5.78407069],
                            [38.85322189, -5.77705568],
                            [38.86251698, -5.76282103],
                            [38.87523288, -5.74334768],
                            [38.87752453, -5.7398382],
                            [38.88846019, -5.7187457],
                            [38.89016101, -5.71546519],
                            [38.89040235, -5.71407732],
                            [38.89377146, -5.69470298],
                            [38.89471672, -5.68548579],
                            [38.89738192, -5.6594975],
                            [38.90141991, -5.65142065],
                            [38.90460277, -5.64505424],
                            [38.90295993, -5.64176818],
                            [38.90006298, -5.64101406],
                            [38.89866034, -5.64064893],
                            [38.89856922, -5.64067741],
                            [38.89869558, -5.638529],
                            [38.90042894, -5.63833639],
                            [38.90254748, -5.63929947],
                            [38.90312527, -5.63756592],
                            [38.90485863, -5.63621761],
                            [38.9062068, -5.63621761],
                            [38.90813275, -5.6371807],
                            [38.91159946, -5.63621762],
                            [38.91352541, -5.63544715],
                            [38.91371801, -5.6325579],
                            [38.9139966, -5.63014319],
                            [38.91429581, -5.62754986],
                            [38.91448106, -5.62699403],
                            [38.9147773, -5.62610524],
                            [38.91525879, -5.62466061],
                            [38.92065146, -5.6198452],
                            [38.92314275, -5.61831193],
                            [38.92816267, -5.61522241],
                            [38.93201458, -5.61098485],
                            [38.93413314, -5.60366541],
                            [38.93413315, -5.5978869],
                            [38.93567391, -5.59566111],
                            [38.93740728, -5.58930476],
                            [38.94376294, -5.57524374],
                            [38.94877043, -5.56522767],
                            [38.95246184, -5.5626434],
                            [38.956073, -5.55802061],
                            [38.95813509, -5.55694034],
                            [38.95910638, -5.55643152],
                            [38.96271754, -5.55354227],
                            [38.96276479, -5.55348977],
                            [38.9666176, -5.5492084],
                            [38.96748428, -5.54733039],
                            [38.96748429, -5.54212974],
                            [38.96748429, -5.53851817],
                            [38.96791764, -5.53158396],
                            [38.96979545, -5.52609439],
                            [38.97225104, -5.52291621],
                            [38.9734499, -5.52165728],
                            [38.97513997, -5.5198825],
                            [38.97701778, -5.51583755],
                            [38.97817336, -5.51049244],
                            [38.97817337, -5.50789211],
                            [38.97932894, -5.50471394],
                            [38.98034007, -5.50095791],
                            [38.98192899, -5.49734635],
                            [38.98481792, -5.49301247],
                            [38.98741796, -5.48911198],
                            [38.98769095, -5.48897548],
                            [38.98944021, -5.48810075],
                            [38.98987355, -5.48795629],
                            [38.99214846, -5.48197347],
                            [38.99234337, -5.48146088],
                            [38.99327077, -5.4790219],
                            [38.99377362, -5.47769945],
                            [38.99496531, -5.47183968],
                            [38.9974841, -5.46151964],
                            [38.99764881, -5.46106666],
                            [38.99793247, -5.4602865],
                            [38.99845912, -5.45883805],
                            [38.99854038, -5.45631899],
                            [38.99756537, -5.4540437],
                            [38.99813412, -5.45331236],
                            [38.99910914, -5.4524185],
                            [38.99845914, -5.44421121],
                            [38.99577786, -5.44412995],
                            [38.99325907, -5.44388617],
                            [38.99041529, -5.44250474],
                            [38.98667774, -5.44014819],
                            [38.98499476, -5.43509865],
                            [38.98480898, -5.43454123],
                            [38.98350896, -5.43161586],
                            [38.98295572, -5.43037896],
                            [38.98172173, -5.42927337],
                            [38.98053914, -5.42788496],
                            [38.98054002, -5.42667013],
                            [38.98257825, -5.42376532],
                            [38.98379007, -5.4220383],
                            [38.98687762, -5.41529371],
                            [38.98703549, -5.41502149],
                            [38.98923391, -5.4112307],
                            [38.99142769, -5.40838659],
                            [38.99290899, -5.40764586],
                            [38.99354022, -5.40733021],
                            [38.99524649, -5.4071677],
                            [38.9962215, -5.4083866],
                            [38.996384, -5.40887416],
                            [38.99792777, -5.40854912],
                            [38.99890279, -5.40708644],
                            [39.00060906, -5.40603006],
                            [39.00239659, -5.40521746],
                            [39.00313315, -5.40481711],
                            [39.00613414, -5.40318596],
                            [39.00708611, -5.40290955],
                            [39.00865292, -5.40245462],
                            [39.01125296, -5.40310471],
                            [39.01198422, -5.40391731],
                            [39.01157796, -5.4057863],
                            [39.01271547, -5.40684268],
                            [39.01474675, -5.4061926],
                            [39.0163409, -5.40617218],
                            [39.01794969, -5.40335649],
                            [39.0192464, -5.40147017],
                            [39.02016177, -5.40013857],
                            [39.02358044, -5.39410496],
                            [39.02609417, -5.38887584],
                            [39.02730076, -5.38555735],
                            [39.02981449, -5.37821646],
                            [39.03061468, -5.37606572],
                            [39.03142328, -5.37389237],
                            [39.03242878, -5.36755708],
                            [39.03433921, -5.36454028],
                            [39.03592789, -5.36136258],
                            [39.03655129, -5.36011563],
                            [39.03778444, -5.35789572],
                            [39.03856228, -5.35649547],
                            [39.0392286, -5.3541853],
                            [39.04007052, -5.35126634],
                            [39.04037998, -5.35047049],
                            [39.04134764, -5.34798195],
                            [39.04157959, -5.34750145],
                            [39.04158818, -5.34750329],
                            [39.04233188, -5.34536492],
                            [39.04344743, -5.34331952],
                            [39.04391224, -5.34146007],
                            [39.04437705, -5.34006548],
                            [39.04493483, -5.33811306],
                            [39.04539964, -5.33634658],
                            [39.04567853, -5.33541685],
                            [39.04567853, -5.33365037],
                            [39.04595742, -5.33244173],
                            [39.04642223, -5.33086119],
                            [39.04670112, -5.32955958],
                            [39.04716594, -5.3277931],
                            [39.04790963, -5.32686337],
                            [39.04828148, -5.32593364],
                            [39.04846741, -5.32500392],
                            [39.04939703, -5.32407419],
                            [39.04986185, -5.32307474],
                            [39.05076823, -5.32161042],
                            [39.05160489, -5.32007637],
                            [39.05209294, -5.31930935],
                            [39.05237183, -5.31840287],
                            [39.05285988, -5.31735692],
                            [39.05334794, -5.31645044],
                            [39.05383599, -5.31533477],
                            [39.05439376, -5.31456775],
                            [39.0548121, -5.31359153],
                            [39.05530015, -5.31268505],
                            [39.05557904, -5.3118483],
                            [39.05557904, -5.31101154],
                            [39.05585792, -5.31017479],
                            [39.0564157, -5.30926831],
                            [39.05697347, -5.30850128],
                            [39.05746152, -5.30822237],
                            [39.05808902, -5.30703697],
                            [39.0586468, -5.30536346],
                            [39.05892569, -5.30417806],
                            [39.05969263, -5.30250455],
                            [39.06032012, -5.30076132],
                            [39.06073199, -5.29966288],
                            [39.06094762, -5.29908781],
                            [39.06122651, -5.29818133],
                            [39.06143567, -5.29720511],
                            [39.06157512, -5.29636836],
                            [39.0615054, -5.29525269],
                            [39.06192373, -5.29441594],
                            [39.0621329, -5.29343972],
                            [39.06269067, -5.29183595],
                            [39.06331817, -5.29072027],
                            [39.06401539, -5.28890731],
                            [39.06464288, -5.2872338],
                            [39.06513094, -5.28556029],
                            [39.06547955, -5.28416571],
                            [39.06610704, -5.28277112],
                            [39.0659676, -5.28123707],
                            [39.06610705, -5.2801214],
                            [39.06617677, -5.27886627],
                            [39.06645566, -5.27768086],
                            [39.06655499, -5.27663781],
                            [39.0665951, -5.27621655],
                            [39.06652538, -5.27551925],
                            [39.06638098, -5.27535077],
                            [39.06610706, -5.27503114],
                            [39.06618111, -5.27440161],
                            [39.0662465, -5.27384574],
                            [39.06652539, -5.27266034],
                            [39.06631476, -5.27185984],
                            [39.06617678, -5.27133548],
                            [39.06624651, -5.26980143],
                            [39.06645567, -5.26868576],
                            [39.06680428, -5.26763982],
                            [39.06736206, -5.26666361],
                            [39.06819872, -5.26533875],
                            [39.06903538, -5.26471118],
                            [39.06903538, -5.26338632],
                            [39.06889594, -5.26185227],
                            [39.06896566, -5.2607366],
                            [39.0690526, -5.2603888],
                            [39.06931427, -5.25934201],
                            [39.06959316, -5.25815661],
                            [39.07063899, -5.25704094],
                            [39.0713362, -5.255995],
                            [39.07117934, -5.25601914],
                            [39.07182296, -5.25480327],
                            [39.07154798, -5.25484043],
                            [39.07408107, -5.25183206],
                            [39.07496985, -5.24997351],
                            [39.07591143, -5.24800453],
                            [39.07674341, -5.24484265],
                            [39.07872518, -5.24130339],
                            [39.07907296, -5.24068229],
                            [39.08117205, -5.23738337],
                            [39.08256728, -5.23519062],
                            [39.08439764, -5.23302723],
                            [39.08539601, -5.23086385],
                            [39.08672718, -5.22836763],
                            [39.08789196, -5.22370802],
                            [39.08789197, -5.22087898],
                            [39.08639441, -5.21971407],
                            [39.08439765, -5.22054614],
                            [39.08156893, -5.22071255],
                            [39.08040673, -5.2202655],
                            [39.07940578, -5.21988048],
                            [39.07707624, -5.21871557],
                            [39.07482501, -5.21791147],
                            [39.0747467, -5.2178835],
                            [39.07175158, -5.21788349],
                            [39.07099335, -5.21811098],
                            [39.06842366, -5.21888197],
                            [39.06459655, -5.21938121],
                            [39.06126864, -5.22004687],
                            [39.0608671, -5.21974567],
                            [39.06060305, -5.21954762],
                            [39.06110224, -5.21788348],
                            [39.06132294, -5.21707415],
                            [39.06160143, -5.21605292],
                            [39.06209846, -5.21406461],
                            [39.06210062, -5.21405594],
                            [39.06259982, -5.21106048],
                            [39.06276621, -5.20922992],
                            [39.06289427, -5.20890976],
                            [39.0634318, -5.20756578],
                            [39.06476297, -5.20640088],
                            [39.06476966, -5.20639335],
                            [39.06609414, -5.20490315],
                            [39.06908926, -5.20340542],
                            [39.07008764, -5.20290618],
                            [39.07191799, -5.20140845],
                            [39.07274998, -5.19991072],
                            [39.0739918, -5.19613303],
                            [39.0740365, -5.19612099],
                            [39.07710895, -5.1952937],
                            [39.07974655, -5.19289563],
                            [39.08262392, -5.19097717],
                            [39.08370294, -5.18953832],
                            [39.08538141, -5.18726015],
                            [39.08649374, -5.18548023],
                            [39.08717977, -5.18438245],
                            [39.08849857, -5.18234409],
                            [39.09101627, -5.17994601],
                            [39.09269474, -5.17922659],
                            [39.09461299, -5.17826736],
                            [39.09497267, -5.17682851],
                            [39.09401355, -5.17443043],
                            [39.09197541, -5.17227216],
                            [39.09137596, -5.17023379],
                            [39.0914502, -5.16926857],
                            [39.09149585, -5.16867504],
                            [39.09245498, -5.16783571],
                            [39.09341041, -5.16699443],
                            [39.0934141, -5.16699638],
                            [39.09425334, -5.1673561],
                            [39.09533235, -5.16711629],
                            [39.09617159, -5.16555754],
                            [39.09677104, -5.16375898],
                            [39.09557214, -5.16232013],
                            [39.09557214, -5.16209657],
                            [39.09557214, -5.16040167],
                            [39.09569203, -5.15824339],
                            [39.09509258, -5.15668464],
                            [39.09353401, -5.15632493],
                            [39.09197543, -5.15632492],
                            [39.09089642, -5.15500598],
                            [39.09101631, -5.15344723],
                            [39.09137598, -5.15224819],
                            [39.09176691, -5.15130987],
                            [39.09197544, -5.15080934],
                            [39.09257489, -5.14973021],
                            [39.09304239, -5.14977271],
                            [39.09389369, -5.14985011],
                            [39.09533237, -5.15056954],
                            [39.0962915, -5.15080935],
                            [39.09725062, -5.1494904],
                            [39.09832964, -5.14841127],
                            [39.09976833, -5.14709233],
                            [39.09928877, -5.14529377],
                            [39.09868932, -5.14361511],
                            [39.09868932, -5.14121703],
                            [39.09901721, -5.1389216],
                            [39.099049, -5.13869905],
                            [39.09940867, -5.13594125],
                            [39.10024791, -5.1341427],
                            [39.10120703, -5.13294366],
                            [39.10276561, -5.13150481],
                            [39.10292796, -5.1304765],
                            [39.10312529, -5.12922664],
                            [39.10408441, -5.1280276],
                            [39.10480376, -5.12670865],
                            [39.10600266, -5.12598923],
                            [39.10620034, -5.12513254],
                            [39.10621501, -5.12512765],
                            [39.10660701, -5.1246376],
                            [39.10692642, -5.1241052],
                            [39.10853614, -5.12321081],
                            [39.10973505, -5.12249139],
                            [39.11093396, -5.12129235],
                            [39.1112404, -5.12056233],
                            [39.11125988, -5.12054655],
                            [39.11129066, -5.12047269],
                            [39.11267934, -5.11792521],
                            [39.11387825, -5.11672617],
                            [39.11454099, -5.11648945],
                            [39.11555671, -5.11612665],
                            [39.11687551, -5.11552714],
                            [39.11843409, -5.11504752],
                            [39.1195131, -5.1143281],
                            [39.12047223, -5.11396839],
                            [39.12203081, -5.11336887],
                            [39.12322971, -5.11324897],
                            [39.12382916, -5.11264945],
                            [39.12310982, -5.11181012],
                            [39.12322971, -5.1110907],
                            [39.12382917, -5.11013146],
                            [39.12358939, -5.10989166],
                            [39.12310982, -5.11001156],
                            [39.12131147, -5.11049117],
                            [39.11988223, -5.11069537],
                            [39.119633, -5.11073098],
                            [39.11927333, -5.10977175],
                            [39.11910942, -5.10907668],
                            [39.11961521, -5.10897551],
                            [39.1196473, -5.10888457],
                            [39.11991868, -5.10811557],
                            [39.11979309, -5.10790624],
                            [39.11961521, -5.10760973],
                            [39.1198681, -5.10685096],
                            [39.12037389, -5.10599102],
                            [39.12070101, -5.10571061],
                            [39.12072794, -5.10568752],
                            [39.12128431, -5.10548518],
                            [39.12199241, -5.1054346],
                            [39.12290283, -5.10533343],
                            [39.12381325, -5.10523226],
                            [39.12421788, -5.10432174],
                            [39.12422373, -5.10430888],
                            [39.12447078, -5.10376531],
                            [39.12477425, -5.10285479],
                            [39.1251283, -5.10224778],
                            [39.12543178, -5.1015396],
                            [39.12583641, -5.10083141],
                            [39.12589975, -5.10082808],
                            [39.12679741, -5.10078083],
                            [39.12730319, -5.10113492],
                            [39.12782465, -5.10113492],
                            [39.12791014, -5.10113492],
                            [39.12866882, -5.10113492],
                            [39.1288332, -5.10168187],
                            [39.12887114, -5.10209919],
                            [39.12913668, -5.10225095],
                            [39.12944015, -5.10206125],
                            [39.12962982, -5.10164393],
                            [39.12985742, -5.10183362],
                            [39.13000916, -5.10209919],
                            [39.12978155, -5.10228888],
                            [39.12989323, -5.10237824],
                            [39.12997123, -5.10244064],
                            [39.13035057, -5.10270621],
                            [39.13088164, -5.10282002],
                            [39.13137479, -5.1028959],
                            [39.13164032, -5.10323735],
                            [39.13171619, -5.1038823],
                            [39.13152652, -5.10456519],
                            [39.1319438, -5.10494457],
                            [39.13224727, -5.10475488],
                            [39.1320576, -5.10429962],
                            [39.13239901, -5.10380642],
                            [39.13220301, -5.10367838],
                            [39.13255707, -5.10332429],
                            [39.13240533, -5.10241377],
                            [39.13210297, -5.10081273],
                            [39.13201895, -5.10057265],
                            [39.13195894, -5.10023654],
                            [39.13181491, -5.09986442],
                            [39.13185092, -5.09960033],
                            [39.13183892, -5.09933625],
                            [39.1317549, -5.09897613],
                            [39.13171889, -5.09873605],
                            [39.13152685, -5.09872405],
                            [39.13138282, -5.098592],
                            [39.13119078, -5.09851998],
                            [39.13118409, -5.09849992],
                            [39.13114277, -5.09837593],
                            [39.13092672, -5.09824389],
                            [39.13059065, -5.09811184],
                            [39.1303866, -5.0979918],
                            [39.13053064, -5.09791978],
                            [39.13069867, -5.09800381],
                            [39.1308427, -5.09807583],
                            [39.13101074, -5.09821988],
                            [39.13115612, -5.09826835],
                            [39.13119078, -5.0982799],
                            [39.13138282, -5.0982799],
                            [39.13162287, -5.09819587],
                            [39.13163487, -5.09809984],
                            [39.13147884, -5.09793179],
                            [39.13139482, -5.09778774],
                            [39.1313108, -5.09753566],
                            [39.1312868, -5.09727157],
                            [39.1312868, -5.09707951],
                            [39.13149084, -5.09695947],
                            [39.13158686, -5.0970435],
                            [39.13171889, -5.09711552],
                            [39.13183892, -5.0970675],
                            [39.13185092, -5.09677941],
                            [39.13181491, -5.09651532],
                            [39.13161087, -5.09623923],
                            [39.13138282, -5.09616721],
                            [39.13120278, -5.0961432],
                            [39.13098674, -5.09598715],
                            [39.1308877, -5.09593381],
                            [39.1308307, -5.09590312],
                            [39.13067467, -5.09578308],
                            [39.13055464, -5.09580709],
                            [39.13037461, -5.095555],
                            [39.13059065, -5.09551899],
                            [39.13073468, -5.09531493],
                            [39.13079472, -5.09521486],
                            [39.1308067, -5.09519489],
                            [39.13099874, -5.09515888],
                            [39.13102275, -5.09502683],
                            [39.13090272, -5.09496681],
                            [39.13065067, -5.09499082],
                            [39.13061104, -5.09497299],
                            [39.13041061, -5.09488278],
                            [39.13030259, -5.09478675],
                            [39.13024258, -5.09471473],
                            [39.13015856, -5.09455868],
                            [39.12999052, -5.09429459],
                            [39.1298585, -5.09410253],
                            [39.12978648, -5.09397048],
                            [39.12970177, -5.09377564],
                            [39.12966646, -5.09369439],
                            [39.12953443, -5.09326225],
                            [39.12961845, -5.09291414],
                            [39.12965139, -5.09271645],
                            [39.12967846, -5.09255402],
                            [39.12964245, -5.0921939],
                            [39.12975048, -5.09178577],
                            [39.12977448, -5.09144966],
                            [39.12965446, -5.09131761],
                            [39.12947442, -5.09114956],
                            [39.12942641, -5.09089748],
                            [39.12945041, -5.09064539],
                            [39.12961845, -5.09038131],
                            [39.12972647, -5.09033329],
                            [39.1298585, -5.09030928],
                            [39.12988251, -5.09022526],
                            [39.12973848, -5.0900692],
                            [39.12965446, -5.08999718],
                            [39.12954643, -5.08980512],
                            [39.12946242, -5.08962506],
                            [39.12935439, -5.089445],
                            [39.12925837, -5.08925294],
                            [39.12915035, -5.08910889],
                            [39.12899432, -5.08904887],
                            [39.12881428, -5.08907288],
                            [39.12875426, -5.08896484],
                            [39.12883828, -5.0888448],
                            [39.12897031, -5.08874877],
                            [39.12899432, -5.08855671],
                            [39.12894631, -5.08829262],
                            [39.12898232, -5.08796851],
                            [39.12895726, -5.08776807],
                            [39.12894631, -5.08768042],
                            [39.12879028, -5.08729629],
                            [39.12864624, -5.08673211],
                            [39.12864625, -5.08642],
                            [39.12865825, -5.08615592],
                            [39.12864625, -5.08577179],
                            [39.12870626, -5.08533965],
                            [39.12865825, -5.08508757],
                            [39.12849021, -5.08491951],
                            [39.12831017, -5.08477546],
                            [39.12804612, -5.08459541],
                            [39.12802211, -5.08452338],
                            [39.12799811, -5.08429531],
                            [39.12799811, -5.08403122],
                            [39.12809413, -5.08387517],
                            [39.12828617, -5.08370711],
                            [39.12837019, -5.08353906],
                            [39.12837019, -5.08341902],
                            [39.12825016, -5.08323896],
                            [39.12815414, -5.08316694],
                            [39.12801011, -5.08310692],
                            [39.12790209, -5.08299888],
                            [39.12778206, -5.08284283],
                            [39.12769805, -5.08266277],
                            [39.12766204, -5.0824587],
                            [39.12766204, -5.08227864],
                            [39.12769805, -5.08208658],
                            [39.12781807, -5.08190652],
                            [39.1279501, -5.08172646],
                            [39.1279501, -5.08155841],
                            [39.12757802, -5.08165444],
                            [39.12722995, -5.08170245],
                            [39.12722995, -5.08195454],
                            [39.12698989, -5.08203856],
                            [39.12690588, -5.08223063],
                            [39.12672584, -5.08237467],
                            [39.12652179, -5.08262676],
                            [39.1263573, -5.08275837],
                            [39.12628174, -5.08281882],
                            [39.12593366, -5.08292685],
                            [39.12570562, -5.08289084],
                            [39.12554958, -5.08285483],
                            [39.12539355, -5.08296287],
                            [39.1251775, -5.08291485],
                            [39.12505748, -5.0827708],
                            [39.12512949, -5.08269878],
                            [39.12530953, -5.08265076],
                            [39.12535546, -5.08262205],
                            [39.12540555, -5.08259074],
                            [39.12556159, -5.08241068],
                            [39.12558559, -5.08221862],
                            [39.12546557, -5.08194253],
                            [39.12526152, -5.08163043],
                            [39.12522551, -5.08145037],
                            [39.12509349, -5.08106624],
                            [39.12508611, -5.080808],
                            [39.12508148, -5.08064611],
                            [39.12505748, -5.08035801],
                            [39.12500947, -5.08005791],
                            [39.12499747, -5.07936168],
                            [39.12497346, -5.0791216],
                            [39.12497346, -5.07888153],
                            [39.12500069, -5.0787953],
                            [39.12504548, -5.07865345],
                            [39.12509349, -5.0785094],
                            [39.1251535, -5.07842538],
                            [39.12520151, -5.07838936],
                            [39.12530954, -5.07834135],
                            [39.12538155, -5.07830534],
                            [39.12542956, -5.07830534],
                            [39.12542956, -5.07838936],
                            [39.12545357, -5.07853341],
                            [39.12544156, -5.07870147],
                            [39.12544156, -5.07884551],
                            [39.12544156, -5.07907359],
                            [39.12544156, -5.07924165],
                            [39.12545357, -5.0793977],
                            [39.12539355, -5.07945772],
                            [39.12534554, -5.07955375],
                            [39.12535755, -5.07962577],
                            [39.12547757, -5.07962577],
                            [39.12552558, -5.07956575],
                            [39.12554959, -5.07945772],
                            [39.12554959, -5.07931367],
                            [39.12564561, -5.07903758],
                            [39.1255976, -5.07883351],
                            [39.1256216, -5.07870147],
                            [39.12568162, -5.07842538],
                            [39.12565761, -5.07816129],
                            [39.12564561, -5.0778732],
                            [39.12570562, -5.07759711],
                            [39.12581365, -5.07732101],
                            [39.12591249, -5.07715295],
                            [39.12593367, -5.07711695],
                            [39.1260417, -5.07692489],
                            [39.12602969, -5.07668481],
                            [39.12593367, -5.07651675],
                            [39.12590967, -5.07631268],
                            [39.12594568, -5.07620465],
                            [39.12601769, -5.07612062],
                            [39.1260657, -5.07595257],
                            [39.1260297, -5.07580852],
                            [39.12595768, -5.07567648],
                            [39.12598169, -5.07559245],
                            [39.12594568, -5.07548441],
                            [39.12595768, -5.07532836],
                            [39.1260297, -5.07528035],
                            [39.12616173, -5.07520832],
                            [39.12628175, -5.0751483],
                            [39.12643778, -5.07508828],
                            [39.12655781, -5.07508828],
                            [39.12677386, -5.0751243],
                            [39.12690589, -5.07504027],
                            [39.12676185, -5.07498025],
                            [39.12654581, -5.07499225],
                            [39.12637777, -5.07505227],
                            [39.12619773, -5.07507628],
                            [39.12601769, -5.07507628],
                            [39.12601769, -5.07519632],
                            [39.12584966, -5.07520832],
                            [39.12569362, -5.07511229],
                            [39.12555826, -5.07501966],
                            [39.12546557, -5.07495624],
                            [39.12528554, -5.0748362],
                            [39.12508149, -5.07456011],
                            [39.12487745, -5.07428402],
                            [39.12481744, -5.07399592],
                            [39.12479343, -5.07365981],
                            [39.12470941, -5.07338372],
                            [39.12458939, -5.07296358],
                            [39.12446936, -5.07263948],
                            [39.12444536, -5.07242341],
                            [39.12440935, -5.07218333],
                            [39.12437334, -5.07195525],
                            [39.12440935, -5.07170317],
                            [39.12436134, -5.07145109],
                            [39.12422931, -5.071199],
                            [39.12410929, -5.07075486],
                            [39.12406128, -5.07039474],
                            [39.12402812, -5.07001891],
                            [39.12402527, -5.06998661],
                            [39.12404928, -5.06960248],
                            [39.12401327, -5.06931438],
                            [39.12396526, -5.0690503],
                            [39.12395326, -5.06877421],
                            [39.12384523, -5.06860615],
                            [39.1236892, -5.06807798],
                            [39.12366401, -5.0679923],
                            [39.12356918, -5.06766984],
                            [39.12341314, -5.06720169],
                            [39.12335313, -5.06703364],
                            [39.12335313, -5.06672153],
                            [39.12336513, -5.06656548],
                            [39.12340114, -5.06624138],
                            [39.12330512, -5.06595328],
                            [39.12327749, -5.06569539],
                            [39.12326911, -5.06561717],
                            [39.12325711, -5.0654131],
                            [39.12331712, -5.06526906],
                            [39.12338914, -5.06501697],
                            [39.1236532, -5.06481291],
                            [39.12371598, -5.06471587],
                            [39.12378523, -5.06460884],
                            [39.12385724, -5.06444079],
                            [39.12386799, -5.0643763],
                            [39.12389325, -5.06422471],
                            [39.12394126, -5.06393662],
                            [39.12395326, -5.06374456],
                            [39.12389325, -5.06354049],
                            [39.12376122, -5.06315636],
                            [39.12385724, -5.06278424],
                            [39.12400128, -5.06259218],
                            [39.12419332, -5.0623401],
                            [39.12439736, -5.06210002],
                            [39.12450539, -5.0617279],
                            [39.12460181, -5.06164899],
                            [39.12476944, -5.06151183],
                            [39.12494948, -5.06130776],
                            [39.12492548, -5.06091163],
                            [39.12490147, -5.06046748],
                            [39.12485346, -5.06031143],
                            [39.12488947, -5.06013137],
                            [39.12482946, -5.05993931],
                            [39.12475744, -5.05979526],
                            [39.12479345, -5.05962721],
                            [39.12498549, -5.05943514],
                            [39.12498549, -5.05924308],
                            [39.12494949, -5.058991],
                            [39.12492548, -5.05865489],
                            [39.12498549, -5.05819874],
                            [39.12484146, -5.05787463],
                            [39.12476945, -5.05737047],
                            [39.12476577, -5.0573576],
                            [39.12464942, -5.05695033],
                            [39.12468543, -5.05667424],
                            [39.12460141, -5.05637414],
                            [39.12459953, -5.0563309],
                            [39.12458941, -5.05609805],
                            [39.1245414, -5.05588198],
                            [39.12456541, -5.0556539],
                            [39.12470944, -5.05547384],
                            [39.12480546, -5.05531779],
                            [39.12482014, -5.05530845],
                            [39.12506952, -5.05514974],
                            [39.12510552, -5.05484964],
                            [39.12508152, -5.05460956],
                            [39.12509352, -5.05433347],
                            [39.12514153, -5.05403337],
                            [39.12516554, -5.05375728],
                            [39.12485347, -5.05342117],
                            [39.12460142, -5.05316909],
                            [39.12415732, -5.05314508],
                            [39.12394128, -5.052905],
                            [39.12355719, -5.05250887],
                            [39.12326913, -5.05208873],
                            [39.12308909, -5.05164459],
                            [39.12293306, -5.05103238],
                            [39.12283704, -5.05068427],
                            [39.12235694, -5.05031215],
                            [39.1222009, -5.04996403],
                            [39.12198486, -5.04963993],
                            [39.12182882, -5.0495679],
                            [39.12152876, -5.04962792],
                            [39.12130071, -5.04971195],
                            [39.12110867, -5.04978397],
                            [39.12090463, -5.04980798],
                            [39.1207846, -5.04988],
                            [39.12065257, -5.04988],
                            [39.12041252, -5.04971195],
                            [39.12011245, -5.04961592],
                            [39.11992041, -5.04947187],
                            [39.11968036, -5.04932782],
                            [39.1194043, -5.04933982],
                            [39.11917625, -5.04932782],
                            [39.11908023, -5.04947187],
                            [39.11900822, -5.04968794],
                            [39.11880417, -5.04981998],
                            [39.11858813, -5.05000004],
                            [39.11857612, -5.05025212],
                            [39.11851611, -5.05046819],
                            [39.1184681, -5.0507803],
                            [39.11843209, -5.05105639],
                            [39.11827606, -5.05134448],
                            [39.11816803, -5.05157256],
                            [39.11807201, -5.05172861],
                            [39.11797599, -5.05190867],
                            [39.11791598, -5.05210073],
                            [39.11786797, -5.05238882],
                            [39.11781996, -5.0526289],
                            [39.11774794, -5.05277295],
                            [39.11766392, -5.05297702],
                            [39.11766392, -5.05314507],
                            [39.11769993, -5.05330112],
                            [39.11770217, -5.05330464],
                            [39.11778395, -5.05343316],
                            [39.11780795, -5.05361322],
                            [39.11778395, -5.05381729],
                            [39.11772393, -5.05417741],
                            [39.11769993, -5.05435747],
                            [39.11767592, -5.05453753],
                            [39.11761591, -5.05474159],
                            [39.1175799, -5.05498167],
                            [39.11760391, -5.05517374],
                            [39.1175559, -5.0553778],
                            [39.11744787, -5.05559387],
                            [39.11717181, -5.05582195],
                            [39.11690775, -5.05608603],
                            [39.11673972, -5.05626609],
                            [39.11642765, -5.05632611],
                            [39.11624761, -5.05632611],
                            [39.1162116, -5.05645816],
                            [39.11616359, -5.05662621],
                            [39.11605557, -5.05674625],
                            [39.11588552, -5.05674625],
                            [39.11577951, -5.05674625],
                            [39.11573863, -5.05675279],
                            [39.11547945, -5.05679426],
                            [39.11520339, -5.05683028],
                            [39.11496333, -5.05683028],
                            [39.11463926, -5.0569143],
                            [39.11445923, -5.0568903],
                            [39.11437521, -5.0569143],
                            [39.11426718, -5.05686629],
                            [39.11393111, -5.0569023],
                            [39.11384709, -5.05697432],
                            [39.11371507, -5.05710637],
                            [39.11352302, -5.05716638],
                            [39.11333098, -5.05714238],
                            [39.11315094, -5.05719039],
                            [39.11291089, -5.05732243],
                            [39.11274286, -5.05743047],
                            [39.1125028, -5.05755051],
                            [39.11223875, -5.05753851],
                            [39.11193868, -5.05755051],
                            [39.1115426, -5.05763454],
                            [39.11117052, -5.05787461],
                            [39.11103849, -5.05804267],
                            [39.11075042, -5.05823473],
                            [39.11052878, -5.05831183],
                            [39.11047436, -5.05833076],
                            [39.1101863, -5.05834277],
                            [39.10988624, -5.05834277],
                            [39.10949015, -5.05839078],
                            [39.10928611, -5.05827074],
                            [39.1092501, -5.05811469],
                            [39.10913007, -5.0581387],
                            [39.10911807, -5.05827074],
                            [39.10896204, -5.05840278],
                            [39.108782, -5.0584388],
                            [39.10860196, -5.05849882],
                            [39.1083532, -5.05856667],
                            [39.1083379, -5.05857084],
                            [39.10806184, -5.05869088],
                            [39.1078578, -5.05884693],
                            [39.10773777, -5.05895496],
                            [39.10756974, -5.05905099],
                            [39.1073777, -5.05915903],
                            [39.10718565, -5.05921905],
                            [39.10698161, -5.05927907],
                            [39.10681357, -5.05935109],
                            [39.10658552, -5.05945913],
                            [39.10645359, -5.05960867],
                            [39.10640549, -5.05966319],
                            [39.10629746, -5.05980724],
                            [39.10623935, -5.05978545],
                            [39.10620144, -5.05977123],
                            [39.1061209, -5.05971547],
                            [39.10604541, -5.05966319],
                            [39.10596139, -5.05953115],
                            [39.10593738, -5.0593871],
                            [39.10604541, -5.05930308],
                            [39.10614143, -5.05924306],
                            [39.10629746, -5.05914702],
                            [39.10632147, -5.05905099],
                            [39.10623745, -5.05891895],
                            [39.10606941, -5.05875089],
                            [39.1059854, -5.05864286],
                            [39.10596139, -5.05869087],
                            [39.1059974, -5.05881091],
                            [39.10606941, -5.05893095],
                            [39.10611742, -5.05901498],
                            [39.10616543, -5.05911101],
                            [39.10604541, -5.05920704],
                            [39.10591338, -5.05927907],
                            [39.10576935, -5.05923105],
                            [39.10568533, -5.05911101],
                            [39.10556531, -5.05900298],
                            [39.10545728, -5.05905099],
                            [39.10521723, -5.05920704],
                            [39.10491717, -5.0593751],
                            [39.10467711, -5.05951914],
                            [39.10444906, -5.05972321],
                            [39.10405298, -5.06001131],
                            [39.10381293, -5.06023938],
                            [39.10379407, -5.06025414],
                            [39.10378986, -5.06025743],
                            [39.1036189, -5.06039124],
                            [39.10353687, -5.06045545],
                            [39.10326081, -5.06070753],
                            [39.10291273, -5.06094761],
                            [39.10256466, -5.06116368],
                            [39.1023246, -5.06135574],
                            [39.10210856, -5.06151179],
                            [39.10203654, -5.06159582],
                            [39.10207255, -5.06164384],
                            [39.10202454, -5.06171586],
                            [39.10195252, -5.06184791],
                            [39.1018325, -5.06197995],
                            [39.10168846, -5.062136],
                            [39.10159244, -5.06229205],
                            [39.10152043, -5.06242409],
                            [39.10156844, -5.06250812],
                            [39.10165246, -5.06255614],
                            [39.10170047, -5.06264016],
                            [39.10158044, -5.0627602],
                            [39.10152043, -5.06286824],
                            [39.10144841, -5.06280822],
                            [39.10142441, -5.06286824],
                            [39.10142441, -5.06295227],
                            [39.1014124, -5.06302429],
                            [39.10143641, -5.06309631],
                            [39.10146296, -5.06315606],
                            [39.10148442, -5.06320435],
                            [39.10142441, -5.06332439],
                            [39.10144841, -5.06343242],
                            [39.1014124, -5.06352846],
                            [39.10130438, -5.06360048],
                            [39.10106433, -5.06361248],
                            [39.10091279, -5.06362932],
                            [39.10084828, -5.06363649],
                            [39.10070416, -5.06364276],
                            [39.10057222, -5.06364849],
                            [39.10030817, -5.06363649],
                            [39.10005611, -5.0636605],
                            [39.09987607, -5.06372052],
                            [39.09972004, -5.06374452],
                            [39.09958801, -5.06378054],
                            [39.09939597, -5.06375653],
                            [39.09911991, -5.06381655],
                            [39.09878384, -5.06384055],
                            [39.09852731, -5.06385171],
                            [39.09850778, -5.06385256],
                            [39.09825572, -5.06378053],
                            [39.09800367, -5.06363649],
                            [39.09783563, -5.06351645],
                            [39.09757158, -5.06334839],
                            [39.09734353, -5.06319234],
                            [39.09718749, -5.0630843],
                            [39.09699545, -5.06295226],
                            [39.09683942, -5.06294026],
                            [39.09659937, -5.06284422],
                            [39.09656336, -5.06272419],
                            [39.09639532, -5.06255613],
                            [39.09617928, -5.06254413],
                            [39.09603525, -5.06243609],
                            [39.09585521, -5.06228004],
                            [39.09584396, -5.06226981],
                            [39.09559115, -5.06203996],
                            [39.0953631, -5.06182389],
                            [39.09506304, -5.0615598],
                            [39.09484699, -5.06140375],
                            [39.09464295, -5.0612597],
                            [39.09447491, -5.06103163],
                            [39.09425886, -5.06092359],
                            [39.09403082, -5.06067151],
                            [39.09391079, -5.06051546],
                            [39.09376676, -5.06038341],
                            [39.09356271, -5.06038341],
                            [39.09338268, -5.06041942],
                            [39.09334667, -5.06049145],
                            [39.09329866, -5.06052746],
                            [39.09309461, -5.06047944],
                            [39.09287857, -5.06043143],
                            [39.09268653, -5.06041942],
                            [39.09245848, -5.06032339],
                            [39.09233845, -5.06023936],
                            [39.09220642, -5.06022736],
                            [39.0920864, -5.06010732],
                            [39.09194237, -5.06001129],
                            [39.09171432, -5.05991526],
                            [39.09154628, -5.05978321],
                            [39.09128577, -5.05976056],
                            [39.09127022, -5.0597592],
                            [39.0911742, -5.05985524],
                            [39.09106618, -5.05993926],
                            [39.09091014, -5.06002329],
                            [39.0907301, -5.0600473],
                            [39.09053806, -5.05997528],
                            [39.09038203, -5.05987924],
                            [39.09018999, -5.05980722],
                            [39.09012998, -5.05987924],
                            [39.09002195, -5.05992726],
                            [39.09002195, -5.05990255],
                            [39.09002195, -5.05979522],
                            [39.09002195, -5.05965117],
                            [39.09002195, -5.05950712],
                            [39.08993793, -5.05949512],
                            [39.08991393, -5.05961516],
                            [39.08990193, -5.05972319],
                            [39.0897939, -5.05979522],
                            [39.08963787, -5.0597592],
                            [39.08951784, -5.05967518],
                            [39.08934981, -5.05960315],
                            [39.08919377, -5.05953113],
                            [39.08906175, -5.05951912],
                            [39.0888577, -5.05950712],
                            [39.08874968, -5.0594591],
                            [39.08864166, -5.05939908],
                            [39.08846162, -5.05938708],
                            [39.08830558, -5.05935107],
                            [39.08824011, -5.05933142],
                            [39.08818556, -5.05931506],
                            [39.08807753, -5.05936307],
                            [39.0879095, -5.05938708],
                            [39.08771746, -5.05939908],
                            [39.08752541, -5.05935107],
                            [39.08736938, -5.05930305],
                            [39.08723735, -5.05937507],
                            [39.08708132, -5.05954313],
                            [39.08690128, -5.05961515],
                            [39.08661322, -5.05957914],
                            [39.08644518, -5.0594711],
                            [39.08621713, -5.05941108],
                            [39.08601309, -5.05937507],
                            [39.08591707, -5.05925503],
                            [39.08591506, -5.05922493],
                            [39.08590507, -5.05907497],
                            [39.08583305, -5.05877487],
                            [39.08568902, -5.05861882],
                            [39.08541296, -5.05835474],
                            [39.08522092, -5.05816267],
                            [39.08504088, -5.05805464],
                            [39.08486084, -5.0579466],
                            [39.08475282, -5.0579346],
                            [39.08428472, -5.05816267],
                            [39.08415137, -5.0582084],
                            [39.08386463, -5.05830672],
                            [39.08351655, -5.05846277],
                            [39.08306045, -5.05866684],
                            [39.08231629, -5.05882289],
                            [39.08150012, -5.059171],
                            [39.08135222, -5.05925865],
                            [39.08117605, -5.05936306],
                            [39.0805039, -5.05951911],
                            [39.07998779, -5.05956713],
                            [39.07919562, -5.05974719],
                            [39.0781754, -5.05991524],
                            [39.07760489, -5.05999035],
                            [39.07754798, -5.06016107],
                            [39.07734883, -5.06030334],
                            [39.07700742, -5.0603887],
                            [39.07672292, -5.06033179],
                            [39.07658066, -5.06047406],
                            [39.07643841, -5.06033179],
                            [39.07615391, -5.06013261],
                            [39.07589785, -5.05993344],
                            [39.07547109, -5.05987653],
                            [39.07498743, -5.05987653],
                            [39.07456067, -5.05987653],
                            [39.07413391, -5.05996189],
                            [39.07382096, -5.06004725],
                            [39.07370716, -5.06024642],
                            [39.07359335, -5.06064478],
                            [39.07359335, -5.06078705],
                            [39.07376406, -5.06090086],
                            [39.07376406, -5.06104313],
                            [39.07353645, -5.06115695],
                            [39.07313814, -5.06107158],
                            [39.07282519, -5.06078705],
                            [39.07248378, -5.06073014],
                            [39.07251223, -5.06041715],
                            [39.07259758, -5.06021797],
                            [39.07262603, -5.06001879],
                            [39.07251223, -5.05996188],
                            [39.07231308, -5.05996188],
                            [39.07202857, -5.06001879],
                            [39.07194322, -5.06016106],
                            [39.07171561, -5.06050251],
                            [39.07134576, -5.06070168],
                            [39.0709759, -5.06078704],
                            [39.07057759, -5.06090086],
                            [39.07049224, -5.06107158],
                            [39.07057759, -5.0611854],
                            [39.07057759, -5.06141302],
                            [39.07023618, -5.06135612],
                            [39.07000858, -5.06135612],
                            [39.06978097, -5.06138457],
                            [39.06955337, -5.06149839],
                            [39.06935422, -5.06155529],
                            [39.06906971, -5.06135612],
                            [39.06892746, -5.06104312],
                            [39.06852915, -5.06090085],
                            [39.06810239, -5.06101467],
                            [39.06776098, -5.0612423],
                            [39.06753338, -5.06135611],
                            [39.06730577, -5.06158374],
                            [39.06696437, -5.06178292],
                            [39.06665141, -5.0619821],
                            [39.06639535, -5.06215282],
                            [39.06616775, -5.06238045],
                            [39.0662531, -5.06243736],
                            [39.06645226, -5.06252272],
                            [39.06656606, -5.06269344],
                            [39.06639535, -5.06280725],
                            [39.0661962, -5.06283571],
                            [39.06588324, -5.06292107],
                            [39.06585479, -5.06306334],
                            [39.06574099, -5.06334788],
                            [39.0657647, -5.0635597],
                            [39.06571412, -5.06391379],
                            [39.06546122, -5.06406554],
                            [39.06556238, -5.06411613],
                            [39.06561296, -5.06431847],
                            [39.06530949, -5.06431847],
                            [39.06530949, -5.0645208],
                            [39.0655118, -5.06457139],
                            [39.06571412, -5.06467256],
                            [39.06571412, -5.06492548],
                            [39.06536007, -5.06487489],
                            [39.06520833, -5.06512782],
                            [39.06515775, -5.06553249],
                            [39.06520833, -5.06603834],
                            [39.06499758, -5.06631936],
                            [39.0644909, -5.06625441],
                            [39.06449082, -5.06625326],
                            [39.06445795, -5.06624778],
                            [39.06445325, -5.06625404],
                            [39.0643852, -5.06625008],
                            [39.06384569, -5.06517095],
                            [39.06402553, -5.06346231],
                            [39.06495992, -5.06221633],
                            [39.06510455, -5.06202347],
                            [39.06554145, -5.06119982],
                            [39.06606643, -5.06021016],
                            [39.06634089, -5.05970861],
                            [39.06640881, -5.0595845],
                            [39.06868673, -5.05898498],
                            [39.07096465, -5.05802575],
                            [39.07198707, -5.05746801],
                            [39.07360224, -5.05658691],
                            [39.07432159, -5.05478835],
                            [39.07456137, -5.0533495],
                            [39.07540061, -5.05119123],
                            [39.07611995, -5.05107133],
                            [39.07767853, -5.05143104],
                            [39.08031612, -5.05143105],
                            [39.08235426, -5.05047182],
                            [39.08235426, -5.04891307],
                            [39.0831935, -5.04651499],
                            [39.08331339, -5.04459652],
                            [39.08499186, -5.04291787],
                            [39.08643055, -5.04183874],
                            [39.08762945, -5.04159893],
                            [39.08882835, -5.04243826],
                            [39.0895477, -5.04159893],
                            [39.08942781, -5.03992028],
                            [39.08894825, -5.03836152],
                            [39.08726978, -5.03812171],
                            [39.08475208, -5.03956056],
                            [39.08223438, -5.03968046],
                            [39.08043602, -5.04027998],
                            [39.07899733, -5.04171882],
                            [39.07671941, -5.04255815],
                            [39.07492106, -5.04219843],
                            [39.07240336, -5.04255814],
                            [39.07108456, -5.04375718],
                            [39.06976576, -5.04531593],
                            [39.06915439, -5.04555371],
                            [39.06760773, -5.04615525],
                            [39.06497014, -5.04615525],
                            [39.06317178, -5.04615525],
                            [39.06077397, -5.04663486],
                            [39.05951715, -5.04672797],
                            [39.05753693, -5.04687466],
                            [39.05417999, -5.04627514],
                            [39.05250153, -5.04555571],
                            [39.05106284, -5.04411686],
                            [39.05022361, -5.04267801],
                            [39.05013151, -5.04250696],
                            [39.04938438, -5.04111926],
                            [39.04938438, -5.04027993],
                            [39.04986394, -5.04027993],
                            [39.05070318, -5.04016003],
                            [39.05226176, -5.03860128],
                            [39.05406011, -5.03764205],
                            [39.0554988, -5.03692263],
                            [39.05654186, -5.03692263],
                            [39.05717727, -5.03692263],
                            [39.05825628, -5.03728235],
                            [39.05909552, -5.03692263],
                            [39.06029442, -5.0359634],
                            [39.06101377, -5.03644302],
                            [39.061853, -5.03716245],
                            [39.06257234, -5.03776197],
                            [39.06341157, -5.03752216],
                            [39.06389114, -5.03692264],
                            [39.06329169, -5.03500418],
                            [39.06341158, -5.03332552],
                            [39.06389114, -5.03188667],
                            [39.06497016, -5.03092744],
                            [39.06556961, -5.03104735],
                            [39.06592928, -5.03188668],
                            [39.06664862, -5.0326061],
                            [39.06784753, -5.03236629],
                            [39.06868676, -5.03224639],
                            [39.06988567, -5.03140706],
                            [39.06988567, -5.0293687],
                            [39.07012546, -5.02625119],
                            [39.07096469, -5.02421283],
                            [39.07195394, -5.02272879],
                            [39.07264316, -5.02169484],
                            [39.07374458, -5.02075067],
                            [39.07516087, -5.01953658],
                            [39.07611999, -5.01845744],
                            [39.07827802, -5.01749821],
                            [39.07995649, -5.01689869],
                            [39.08103551, -5.01725841],
                            [39.08199463, -5.01785793],
                            [39.08295375, -5.01905697],
                            [39.0835532, -5.02085553],
                            [39.0847521, -5.02193467],
                            [39.08583112, -5.02241429],
                            [39.08774937, -5.02157496],
                            [39.08966762, -5.02049583],
                            [39.09194554, -5.01941669],
                            [39.09278477, -5.01917689],
                            [39.09422346, -5.01917689],
                            [39.09566214, -5.01989632],
                            [39.09650138, -5.02013612],
                            [39.09782017, -5.02001622],
                            [39.0999782, -5.01941671],
                            [39.10062486, -5.01900095],
                            [39.10165667, -5.01833757],
                            [39.10345503, -5.01689873],
                            [39.10525339, -5.01641911],
                            [39.10669207, -5.01701863],
                            [39.10777109, -5.01785796],
                            [39.10844063, -5.01828409],
                            [39.10908988, -5.01869729],
                            [39.11112802, -5.01929682],
                            [39.11203201, -5.01902559],
                            [39.11352583, -5.0185774],
                            [39.11628331, -5.01821769],
                            [39.11832145, -5.0185774],
                            [39.11976014, -5.02013616],
                            [39.12015717, -5.02026155],
                            [39.12203805, -5.02085558],
                            [39.1239563, -5.02193472],
                            [39.12683367, -5.02253425],
                            [39.12839225, -5.02169492],
                            [39.1303105, -5.02001627],
                            [39.13055028, -5.01821771],
                            [39.13114974, -5.01426088],
                            [39.13104334, -5.01367559],
                            [39.13067018, -5.01162299],
                            [39.13126964, -5.00958462],
                            [39.13156394, -5.00865258],
                            [39.13198898, -5.00730644],
                            [39.13246855, -5.00550788],
                            [39.13378735, -5.00358942],
                            [39.13444273, -5.00181034],
                            [39.13462658, -5.00131125],
                            [39.13474648, -4.99867336],
                            [39.13414703, -4.99711461],
                            [39.1331879, -4.99639518],
                            [39.13222878, -4.99543595],
                            [39.1307901, -4.99387719],
                            [39.12964931, -4.99216582],
                            [39.12959119, -4.99207863],
                            [39.12779284, -4.98992036],
                            [39.12611438, -4.98860141],
                            [39.12371657, -4.98716256],
                            [39.12167843, -4.98572371],
                            [39.11976018, -4.98404505],
                            [39.1183215, -4.9824863],
                            [39.1171226, -4.98068774],
                            [39.11604358, -4.98044793],
                            [39.11556402, -4.98188677],
                            [39.11424522, -4.98188677],
                            [39.11304632, -4.98116735],
                            [39.11100818, -4.98080763],
                            [39.10885015, -4.98164696],
                            [39.10813081, -4.98344552],
                            [39.10779086, -4.98350733],
                            [39.10681201, -4.98368532],
                            [39.10669212, -4.98260619],
                            [39.1069319, -4.98104743],
                            [39.10621256, -4.97888916],
                            [39.10465399, -4.97840954],
                            [39.10369486, -4.97864935],
                            [39.10240937, -4.97900906],
                            [39.10155681, -4.98028804],
                            [39.10049112, -4.97922222],
                            [39.10027799, -4.97730376],
                            [39.10006485, -4.97623794],
                            [39.09921229, -4.97623794],
                            [39.09899915, -4.97581162],
                            [39.09985171, -4.97453264],
                            [39.10006485, -4.97367999],
                            [39.09878602, -4.97282734],
                            [39.09708091, -4.97282734],
                            [39.09580208, -4.97240101],
                            [39.09473639, -4.97133519],
                            [39.09303128, -4.96984305],
                            [39.092605, -4.97026938],
                            [39.092605, -4.97112203],
                            [39.09224465, -4.97169866],
                            [39.0924989, -4.97078324],
                            [39.0926772, -4.96977279],
                            [39.09297436, -4.96816796],
                            [39.09362811, -4.96727639],
                            [39.09446015, -4.96584987],
                            [39.09523276, -4.96460167],
                            [39.09654026, -4.96317515],
                            [39.09844207, -4.96162976],
                            [39.09892077, -4.96160004],
                            [39.09947237, -4.96139944],
                            [39.10050035, -4.96102331],
                            [39.1012776, -4.96094809],
                            [39.10180413, -4.96117377],
                            [39.10223036, -4.96149975],
                            [39.10290732, -4.96215171],
                            [39.10360936, -4.96275352],
                            [39.10433647, -4.96345564],
                            [39.10483792, -4.96395715],
                            [39.10533937, -4.96435836],
                            [39.10571546, -4.96478464],
                            [39.1061417, -4.96488495],
                            [39.10689388, -4.96478464],
                            [39.10782157, -4.96470942],
                            [39.10834809, -4.96480972],
                            [39.10887462, -4.96523601],
                            [39.10952651, -4.96581274],
                            [39.11037898, -4.96643963],
                            [39.11023447, -4.96698896],
                            [39.11048519, -4.96809228],
                            [39.11116215, -4.96924575],
                            [39.11186419, -4.96979742],
                            [39.11336855, -4.97037415],
                            [39.11549973, -4.97064999],
                            [39.1173551, -4.97092582],
                            [39.11875917, -4.97107628],
                            [39.12063962, -4.97147749],
                            [39.12219413, -4.97165302],
                            [39.1233224, -4.97215453],
                            [39.1243253, -4.97283157],
                            [39.12530314, -4.97378444],
                            [39.12610546, -4.97451163],
                            [39.12690779, -4.97541435],
                            [39.12763489, -4.97581556],
                            [39.12838707, -4.97584063],
                            [39.12868795, -4.97569018],
                            [39.12871302, -4.9754645],
                            [39.12858766, -4.97503822],
                            [39.12848737, -4.97458686],
                            [39.12848737, -4.9740352],
                            [39.12853751, -4.97328293],
                            [39.1289136, -4.97293188],
                            [39.13004188, -4.97185363],
                            [39.13124537, -4.97107629],
                            [39.13297538, -4.97009835],
                            [39.1344296, -4.96914548],
                            [39.13523192, -4.96836814],
                            [39.13605932, -4.96721467],
                            [39.13688672, -4.96581045],
                            [39.13726282, -4.96495788],
                            [39.13748847, -4.96415546],
                            [39.138015, -4.96312737],
                            [39.13874211, -4.9622748],
                            [39.13936892, -4.96154761],
                            [39.14039691, -4.96069505],
                            [39.14099865, -4.96021862],
                            [39.14137474, -4.95946635],
                            [39.14147503, -4.95896484],
                            [39.14147503, -4.95858871],
                            [39.14127445, -4.95806212],
                            [39.14097358, -4.95763584],
                            [39.14039691, -4.95658267],
                            [39.13989546, -4.95600593],
                            [39.13974502, -4.95547934],
                            [39.13946922, -4.95492768],
                            [39.13924357, -4.95364883],
                            [39.13891763, -4.95236998],
                            [39.13859169, -4.95126666],
                            [39.13839111, -4.94988751],
                            [39.13806516, -4.94833282],
                            [39.13801502, -4.94735488],
                            [39.13798995, -4.94662769],
                            [39.13796487, -4.94620141],
                            [39.13773922, -4.94567482],
                            [39.13756371, -4.94512316],
                            [39.13743835, -4.94442105],
                            [39.13736313, -4.94351833],
                            [39.13733806, -4.94279114],
                            [39.13691183, -4.94203887],
                            [39.1363853, -4.94143706],
                            [39.13605936, -4.94070987],
                            [39.13585878, -4.93968178],
                            [39.13575849, -4.93923042],
                            [39.13545762, -4.93840292],
                            [39.1351066, -4.93767574],
                            [39.13465529, -4.93714915],
                            [39.1341037, -4.93684824],
                            [39.13357717, -4.93672286],
                            [39.13305064, -4.93654734],
                            [39.13259934, -4.93629658],
                            [39.13224449, -4.93582502],
                            [39.13136068, -4.93488469],
                            [39.13070252, -4.9343581],
                            [39.12948023, -4.93369987],
                            [39.12831435, -4.93311686],
                            [39.12692282, -4.93219534],
                            [39.12592618, -4.93155591],
                            [39.12515519, -4.93106694],
                            [39.12445943, -4.93091648],
                            [39.12385768, -4.93086006],
                            [39.12342518, -4.93074722],
                            [39.12304037, -4.93060597],
                            [39.12271374, -4.93005764],
                            [39.12168577, -4.92852804],
                            [39.12030677, -4.92689813],
                            [39.11870212, -4.92541867],
                            [39.11712255, -4.92429027],
                            [39.11589399, -4.92336248],
                            [39.11431441, -4.92208362],
                            [39.11260947, -4.92115583],
                            [39.11077916, -4.92032833],
                            [39.10965089, -4.9199522],
                            [39.10862291, -4.92130628],
                            [39.10777044, -4.92245975],
                            [39.10694304, -4.92326216],
                            [39.10594013, -4.9240395],
                            [39.10491215, -4.92466639],
                            [39.10338272, -4.92541865],
                            [39.10182821, -4.92614584],
                            [39.10111391, -4.92648219],
                            [39.10031854, -4.9255421],
                            [39.0983573, -4.9242939],
                            [39.09706467, -4.92331316],
                            [39.09537087, -4.92273364],
                            [39.09354335, -4.92224327],
                            [39.09180498, -4.92161917],
                            [39.0910918, -4.92090591],
                            [39.09037706, -4.92010037],
                            [39.09092766, -4.91982504],
                            [39.09235402, -4.91887403],
                            [39.09425583, -4.91798246],
                            [39.09490958, -4.91709089],
                            [39.09490958, -4.91584268],
                            [39.09485015, -4.91423785],
                            [39.09496902, -4.91287077],
                            [39.09479072, -4.91168201],
                            [39.0955039, -4.9099583],
                            [39.09615765, -4.90989886],
                            [39.09675196, -4.91049325],
                            [39.09669253, -4.91168201],
                            [39.09687082, -4.91310853],
                            [39.09734627, -4.91417842],
                            [39.09901036, -4.91453505],
                            [39.0996641, -4.91441617],
                            [39.10019899, -4.91346516],
                            [39.10037729, -4.91239527],
                            [39.10055558, -4.91061213],
                            [39.10025843, -4.90918561],
                            [39.10025843, -4.90817516],
                            [39.10055559, -4.90686752],
                            [39.10103104, -4.90532213],
                            [39.10186308, -4.9037173],
                            [39.10227911, -4.90276629],
                            [39.10269513, -4.90211247],
                            [39.10281399, -4.90133977],
                            [39.1026357, -4.90098314],
                            [39.10180366, -4.90080483],
                            [39.10109048, -4.9009237],
                            [39.10073389, -4.90205303],
                            [39.10067446, -4.90288516],
                            [39.09990184, -4.90306347],
                            [39.09960469, -4.90240965],
                            [39.09930753, -4.90151808],
                            [39.09883208, -4.90080482],
                            [39.0981189, -4.90044819],
                            [39.09687084, -4.90021044],
                            [39.09580107, -4.90044819],
                            [39.0949096, -4.90098313],
                            [39.0939587, -4.90068594],
                            [39.09342381, -4.90003212],
                            [39.09330495, -4.89908111],
                            [39.0937804, -4.8978329],
                            [39.09425586, -4.89676302],
                            [39.09473131, -4.89587144],
                            [39.0952662, -4.89509875],
                            [39.09603881, -4.89426661],
                            [39.09651426, -4.89367223],
                            [39.09788119, -4.89349392],
                            [39.09996129, -4.89379111],
                            [39.1007339, -4.89349392],
                            [39.10156594, -4.89307786],
                            [39.10210082, -4.89266179],
                            [39.10251685, -4.89165134],
                            [39.10275457, -4.89058145],
                            [39.10352719, -4.89040314],
                            [39.10400264, -4.89064089],
                            [39.10477525, -4.89129471],
                            [39.10531013, -4.89153247],
                            [39.10566672, -4.89117584],
                            [39.1063799, -4.89046258],
                            [39.10685535, -4.88968989],
                            [39.10709308, -4.88897663],
                            [39.10703365, -4.88802562],
                            [39.1063799, -4.88743123],
                            [39.10566672, -4.88665853],
                            [39.10554786, -4.88600471],
                            [39.10560729, -4.88517258],
                            [39.10690882, -4.88463302],
                            [39.1085729, -4.88463303],
                            [39.1108313, -4.88481134],
                            [39.11112846, -4.88546516],
                            [39.11035585, -4.88647561],
                            [39.11041528, -4.88718887],
                            [39.11106902, -4.88802101],
                            [39.11207936, -4.88849651],
                            [39.11350571, -4.88849652],
                            [39.11433776, -4.88808045],
                            [39.11534809, -4.88754551],
                            [39.11606127, -4.88730776],
                            [39.11641786, -4.88754551],
                            [39.11606127, -4.88813989],
                            [39.11546695, -4.88849652],
                            [39.11546695, -4.88915034],
                            [39.11588297, -4.88956641],
                            [39.11576411, -4.8903391],
                            [39.11534809, -4.89057686],
                            [39.11415946, -4.89051742],
                            [39.11320855, -4.89075517],
                            [39.11303026, -4.89146843],
                            [39.113684, -4.89230056],
                            [39.11463491, -4.8931327],
                            [39.11463491, -4.89402427],
                            [39.11398116, -4.89449978],
                            [39.11320855, -4.89449978],
                            [39.11201992, -4.89402427],
                            [39.11083129, -4.89378651],
                            [39.11023697, -4.89396483],
                            [39.10982095, -4.89432146],
                            [39.10964266, -4.8948564],
                            [39.10946436, -4.89586685],
                            [39.10976152, -4.89723393],
                            [39.11035583, -4.89818494],
                            [39.11118787, -4.89877932],
                            [39.11231707, -4.89895764],
                            [39.11267366, -4.8996709],
                            [39.11231707, -4.90002753],
                            [39.11160389, -4.90068135],
                            [39.11172275, -4.90151348],
                            [39.11231707, -4.90210787],
                            [39.11338683, -4.90276169],
                            [39.11410001, -4.90454483],
                            [39.11392171, -4.90614967],
                            [39.11344626, -4.90721955],
                            [39.11344626, -4.90840832],
                            [39.11374341, -4.90929989],
                            [39.11421886, -4.91048866],
                            [39.11576408, -4.91084529],
                            [39.11701215, -4.91096417],
                            [39.11837907, -4.9105481],
                            [39.11915168, -4.91031035],
                            [39.1206969, -4.90983485],
                            [39.12135065, -4.91001316],
                            [39.1229553, -4.91096417],
                            [39.12384677, -4.91262845],
                            [39.1242772, -4.91325025],
                            [39.12491653, -4.91417384],
                            [39.12568914, -4.91512485],
                            [39.12664005, -4.91542205],
                            [39.12731639, -4.91561144],
                            [39.12812583, -4.91583811],
                            [39.13062196, -4.91667025],
                            [39.13198888, -4.9172052],
                            [39.13293979, -4.91690801],
                            [39.13377183, -4.91655138],
                            [39.13436614, -4.91613531],
                            [39.13478217, -4.91560037],
                            [39.13501989, -4.91453048],
                            [39.13525762, -4.9131634],
                            [39.1359708, -4.91114251],
                            [39.13692171, -4.90894329],
                            [39.13769432, -4.90745734],
                            [39.13822921, -4.90609026],
                            [39.13900182, -4.9049015],
                            [39.139715, -4.90323723],
                            [39.14066591, -4.90157296],
                            [39.14161681, -4.89978981],
                            [39.14238942, -4.8983633],
                            [39.1442318, -4.89598577],
                            [39.14512328, -4.89473757],
                            [39.14512328, -4.89366768],
                            [39.14476669, -4.89283555],
                            [39.14387522, -4.89212229],
                            [39.14274602, -4.89206285],
                            [39.14155739, -4.89194397],
                            [39.14019047, -4.89093352],
                            [39.14001217, -4.89045801],
                            [39.14060649, -4.88956644],
                            [39.14131967, -4.88873431],
                            [39.14215171, -4.88707004],
                            [39.14238944, -4.88564352],
                            [39.14233001, -4.88386037],
                            [39.14250831, -4.8829688],
                            [39.14280547, -4.88183947],
                            [39.14161684, -4.88148284],
                            [39.14072536, -4.88124509],
                            [39.13983389, -4.8809479],
                            [39.13918014, -4.88076958],
                            [39.14007162, -4.8794025],
                            [39.14066594, -4.87845149],
                            [39.14126025, -4.87839206],
                            [39.14233002, -4.87880813],
                            [39.14322149, -4.87928363],
                            [39.14423183, -4.87964026],
                            [39.1451233, -4.87886757],
                            [39.14559875, -4.87809487],
                            [39.14577705, -4.87750049],
                            [39.14577705, -4.8764306],
                            [39.14524217, -4.87512296],
                            [39.14512331, -4.87375588],
                            [39.1453016, -4.87274543],
                            [39.14571762, -4.87167554],
                            [39.14672796, -4.86995184],
                            [39.14738171, -4.86900083],
                            [39.14815432, -4.86822813],
                            [39.1490458, -4.86727712],
                            [39.15035329, -4.86620723],
                            [39.15172022, -4.86591004],
                            [39.15207681, -4.8653751],
                            [39.15261169, -4.86454297],
                            [39.15320601, -4.86400802],
                            [39.15391919, -4.86305701],
                            [39.15403805, -4.86145218],
                            [39.15391919, -4.86062005],
                            [39.15385976, -4.85931241],
                            [39.15362203, -4.85859915],
                            [39.15403806, -4.8580642],
                            [39.15552384, -4.8575887],
                            [39.15617759, -4.85741039],
                            [39.15635589, -4.85687544],
                            [39.15641532, -4.85598387],
                            [39.15629646, -4.85491398],
                            [39.15629646, -4.85402241],
                            [39.15629646, -4.85324971],
                            [39.15659362, -4.85253645],
                            [39.15659362, -4.85164488],
                            [39.15689078, -4.85051555],
                            [39.15689078, -4.84986173],
                            [39.15659362, -4.84920791],
                            [39.15623703, -4.84873241],
                            [39.15588045, -4.84831634],
                            [39.15619081, -4.84743137],
                            [39.15726058, -4.84707474],
                            [39.15844921, -4.84701531],
                            [39.1588058, -4.84677755],
                            [39.15886523, -4.84612373],
                            [39.1588058, -4.84541047],
                            [39.15874637, -4.84463778],
                            [39.15862751, -4.84344901],
                            [39.15815206, -4.842498],
                            [39.15779547, -4.84178474],
                            [39.15737945, -4.84119036],
                            [39.15714172, -4.84059598],
                            [39.15654741, -4.83994216],
                            [39.15607196, -4.83928834],
                            [39.15535878, -4.83780238],
                            [39.1548239, -4.83738631],
                            [39.1536947, -4.83738631],
                            [39.15304095, -4.8369108],
                            [39.15286266, -4.83637586],
                            [39.15214948, -4.83572204],
                            [39.15167403, -4.83494934],
                            [39.15131744, -4.83417664],
                            [39.15054483, -4.83322563],
                            [39.14989109, -4.83221518],
                            [39.14941564, -4.83114529],
                            [39.14882132, -4.83019428],
                            [39.1484053, -4.82977821],
                            [39.14775156, -4.82918383],
                            [39.14703838, -4.82858944],
                            [39.14662236, -4.82787619],
                            [39.14668179, -4.82722237],
                            [39.14680066, -4.82656855],
                            [39.14656293, -4.82561753],
                            [39.14644407, -4.82466652],
                            [39.14632521, -4.82318057],
                            [39.1466818, -4.82169461],
                            [39.14757327, -4.82145686],
                            [39.14840531, -4.82175405],
                            [39.14899963, -4.82288338],
                            [39.14965337, -4.82395327],
                            [39.15012882, -4.82532035],
                            [39.15048541, -4.82585529],
                            [39.15149575, -4.82603361],
                            [39.15209006, -4.82627136],
                            [39.15262495, -4.82668743],
                            [39.1531004, -4.82722237],
                            [39.15369471, -4.82746013],
                            [39.1542296, -4.82734125],
                            [39.1553588, -4.82680631],
                            [39.1563097, -4.82656856],
                            [39.15696345, -4.82650912],
                            [39.15815208, -4.82650912],
                            [39.15904355, -4.82674688],
                            [39.16035105, -4.82686575],
                            [39.16165854, -4.82644969],
                            [39.16278196, -4.82604573],
                            [39.16349514, -4.82600115],
                            [39.16416374, -4.82644694],
                            [39.16509979, -4.82707104],
                            [39.16616955, -4.82831925],
                            [39.16804165, -4.82943371],
                            [39.16960172, -4.83036987],
                            [39.16992364, -4.83154873],
                            [39.16978992, -4.83248488],
                            [39.16916589, -4.83270777],
                            [39.16863986, -4.83312112],
                            [39.16854186, -4.83319814],
                            [39.16854186, -4.83359934],
                            [39.16889162, -4.83394915],
                            [39.16898759, -4.83404513],
                            [39.16992364, -4.83426803],
                            [39.17059224, -4.83444634],
                            [39.17072596, -4.83524876],
                            [39.17045852, -4.83618491],
                            [39.16996821, -4.8370319],
                            [39.16983449, -4.83761142],
                            [39.16996821, -4.83836926],
                            [39.17059224, -4.83943915],
                            [39.17077053, -4.84028615],
                            [39.17045851, -4.84077651],
                            [39.17032479, -4.84126688],
                            [39.17054766, -4.84175724],
                            [39.17152828, -4.84215845],
                            [39.17246433, -4.8424705],
                            [39.17340037, -4.84300545],
                            [39.17375696, -4.84358497],
                            [39.1740244, -4.84438739],
                            [39.17375696, -4.84501149],
                            [39.17326665, -4.84585848],
                            [39.1731775, -4.84652716],
                            [39.17375696, -4.84737416],
                            [39.17455928, -4.84781994],
                            [39.17480638, -4.84796116],
                            [39.17549533, -4.84835489],
                            [39.17614722, -4.84894556],
                            [39.17654838, -4.84934677],
                            [39.17715012, -4.85021605],
                            [39.17725041, -4.85121907],
                            [39.1770164, -4.85212179],
                            [39.17651494, -4.85339228],
                            [39.17598006, -4.85506398],
                            [39.17594662, -4.85623417],
                            [39.17535293, -4.85710964],
                            [39.17508548, -4.85751085],
                            [39.17499634, -4.85826869],
                            [39.17542687, -4.85893414],
                            [39.17548665, -4.85902653],
                            [39.17593238, -4.85951689],
                            [39.1762444, -4.86018557],
                            [39.17593238, -4.8611663],
                            [39.17508548, -4.86161209],
                            [39.17423858, -4.86196872],
                            [39.17348083, -4.86232534],
                            [39.1733471, -4.86312776],
                            [39.1735254, -4.86379644],
                            [39.17406028, -4.86446512],
                            [39.17472889, -4.86473259],
                            [39.17544206, -4.86495549],
                            [39.1758878, -4.86557959],
                            [39.17642268, -4.86611453],
                            [39.17691299, -4.86629285],
                            [39.17771532, -4.86611454],
                            [39.17847307, -4.86584706],
                            [39.17887423, -4.86549044],
                            [39.18007772, -4.86477718],
                            [39.18119207, -4.86406392],
                            [39.18208354, -4.86357356],
                            [39.18319788, -4.86281572],
                            [39.1843568, -4.86179041],
                            [39.18578316, -4.86080969],
                            [39.18667463, -4.86023016],
                            [39.18787812, -4.86009643],
                            [39.18854672, -4.86027475],
                            [39.18890331, -4.86103258],
                            [39.18912618, -4.86268199],
                            [39.18894788, -4.86406393],
                            [39.1880564, -4.86566876],
                            [39.18627346, -4.86731817],
                            [39.18435679, -4.86843263],
                            [39.18279671, -4.86901216],
                            [39.18114748, -4.86963625],
                            [39.17981027, -4.87021577],
                            [39.17869593, -4.87083988],
                            [39.17811647, -4.87173145],
                            [39.17762616, -4.87248928],
                            [39.17758159, -4.87315796],
                            [39.17718042, -4.87529774],
                            [39.17682383, -4.87748209],
                            [39.17664553, -4.87908692],
                            [39.17669011, -4.88069176],
                            [39.17713584, -4.8818508],
                            [39.17784902, -4.8826978],
                            [39.17865134, -4.88323274],
                            [39.17963196, -4.88363395],
                            [39.18061258, -4.8833219],
                            [39.18168235, -4.88318817],
                            [39.18168235, -4.88381227],
                            [39.1815932, -4.88519421],
                            [39.18145948, -4.88590747],
                            [39.18101374, -4.88670988],
                            [39.17981025, -4.88737856],
                            [39.1788742, -4.88822555],
                            [39.17825017, -4.88889423],
                            [39.17758157, -4.88942918],
                            [39.17677924, -4.88991954],
                            [39.17646722, -4.89072196],
                            [39.17655637, -4.89156895],
                            [39.17704668, -4.89201474],
                            [39.17740327, -4.892728],
                            [39.17762614, -4.89344126],
                            [39.17722497, -4.89415451],
                            [39.17619978, -4.89433283],
                            [39.17508544, -4.89442198],
                            [39.17419396, -4.89473403],
                            [39.17356993, -4.8952244],
                            [39.17316877, -4.89598223],
                            [39.17334706, -4.89660633],
                            [39.17383737, -4.89723044],
                            [39.17455055, -4.89740875],
                            [39.17535287, -4.89740875],
                            [39.1761552, -4.89740875],
                            [39.17651179, -4.89776538],
                            [39.17642264, -4.89821117],
                            [39.1761552, -4.89874611],
                            [39.17566489, -4.89914732],
                            [39.17437225, -4.89968226],
                            [39.17365907, -4.90008347],
                            [39.1725893, -4.90061841],
                            [39.17263388, -4.90137625],
                            [39.17316876, -4.90204493],
                            [39.17392651, -4.90298108],
                            [39.17450597, -4.90387266],
                            [39.17526372, -4.90494255],
                            [39.17584317, -4.90574496],
                            [39.17695751, -4.90690401],
                            [39.1774924, -4.90810763],
                            [39.17735867, -4.9098462],
                            [39.17722495, -4.91162935],
                            [39.17740324, -4.91287755],
                            [39.17700208, -4.91408117],
                            [39.17673463, -4.91510648],
                            [39.17633347, -4.91626553],
                            [39.17624432, -4.91751373],
                            [39.17562029, -4.91907398],
                            [39.17504083, -4.92045592],
                            [39.17463966, -4.9217487],
                            [39.17446137, -4.92313064],
                            [39.17410478, -4.92482463],
                            [39.17352532, -4.92660777],
                            [39.17337372, -4.92742271],
                            [39.17316872, -4.92852466],
                            [39.173035, -4.92963912],
                            [39.17272298, -4.93124395],
                            [39.17272298, -4.93244758],
                            [39.17330244, -4.93333915],
                            [39.17334701, -4.93431988],
                            [39.17352531, -4.93503314],
                            [39.17392647, -4.93525603],
                            [39.17477337, -4.93525604],
                            [39.1752191, -4.93525604],
                            [39.1752191, -4.93489941],
                            [39.17499624, -4.93440904],
                            [39.17468422, -4.93382952],
                            [39.17499624, -4.93275963],
                            [39.17549422, -4.93214441],
                            [39.17575399, -4.93182348],
                            [39.17588771, -4.93102106],
                            [39.17660089, -4.92946081],
                            [39.17677919, -4.92856924],
                            [39.17722493, -4.92709814],
                            [39.17753695, -4.92500295],
                            [39.17789354, -4.92388848],
                            [39.17882959, -4.92313065],
                            [39.17981021, -4.92241739],
                            [39.18079083, -4.92112461],
                            [39.18154858, -4.91978725],
                            [39.18248463, -4.9180041],
                            [39.18333153, -4.91555228],
                            [39.18404471, -4.91394745],
                            [39.18475789, -4.91229804],
                            [39.18493619, -4.91122815],
                            [39.18506991, -4.91006911],
                            [39.18480247, -4.90922211],
                            [39.18435674, -4.90810764],
                            [39.18422302, -4.90708233],
                            [39.18417845, -4.90619076],
                            [39.18426759, -4.90512087],
                            [39.18457961, -4.90409556],
                            [39.18538194, -4.90320399],
                            [39.18622884, -4.90213411],
                            [39.18671915, -4.90097506],
                            [39.18725403, -4.89990517],
                            [39.18787807, -4.89892444],
                            [39.18903698, -4.89745335],
                            [39.19001761, -4.89598226],
                            [39.19073079, -4.89451116],
                            [39.19162226, -4.89304007],
                            [39.19233544, -4.89188102],
                            [39.19251374, -4.89094487],
                            [39.19242459, -4.88978582],
                            [39.19238002, -4.88844846],
                            [39.19251374, -4.8871111],
                            [39.19318235, -4.88564001],
                            [39.19358351, -4.88470386],
                            [39.19451956, -4.8827424],
                            [39.19509902, -4.8812713],
                            [39.19563391, -4.88006768],
                            [39.19639166, -4.87828453],
                            [39.19741686, -4.87717007],
                            [39.19848662, -4.8762785],
                            [39.19955639, -4.87529777],
                            [39.20075988, -4.8737821],
                            [39.20187423, -4.87284595],
                            [39.20321144, -4.87159774],
                            [39.20356803, -4.87083991],
                            [39.2036126, -4.87003749],
                            [39.20388005, -4.8689676],
                            [39.20405834, -4.86763024],
                            [39.20405834, -4.86656036],
                            [39.20445951, -4.865223],
                            [39.20472695, -4.86428685],
                            [39.2054847, -4.86303864],
                            [39.20588587, -4.86228081],
                            [39.20691106, -4.8612555],
                            [39.20771339, -4.86023019],
                            [39.20802541, -4.85907115],
                            [39.20829285, -4.85800126],
                            [39.20918432, -4.85688679],
                            [39.20998665, -4.85612896],
                            [39.21043239, -4.85514823],
                            [39.21043239, -4.85403376],
                            [39.21016495, -4.85305303],
                            [39.2100758, -4.85202772],
                            [39.21020953, -4.85118073],
                            [39.21069984, -4.85002168],
                            [39.21136844, -4.8487289],
                            [39.21159131, -4.84774817],
                            [39.21150217, -4.84636623],
                            [39.21190333, -4.84529635],
                            [39.21239364, -4.84418188],
                            [39.2129731, -4.84293368],
                            [39.21328512, -4.84186379],
                            [39.21328512, -4.8407939],
                            [39.21257194, -4.83945654],
                            [39.21190334, -4.83865412],
                            [39.21092272, -4.83798544],
                            [39.20998668, -4.83731676],
                            [39.20878319, -4.83669266],
                            [39.20744598, -4.83655892],
                            [39.20655451, -4.83664808],
                            [39.20597505, -4.8372276],
                            [39.2058859, -4.83794086],
                            [39.20628706, -4.83887701],
                            [39.20628706, -4.839724],
                            [39.20610876, -4.84092763],
                            [39.20575217, -4.84195294],
                            [39.20499442, -4.84360235],
                            [39.20441496, -4.84538549],
                            [39.20419209, -4.84663369],
                            [39.20441496, -4.84774816],
                            [39.20441496, -4.84895179],
                            [39.2038355, -4.85011083],
                            [39.2028103, -4.85131445],
                            [39.20142852, -4.85256265],
                            [39.20065157, -4.85319171],
                            [39.20051785, -4.8529911],
                            [39.19974896, -4.85185435],
                            [39.19948152, -4.85128597],
                            [39.19924751, -4.85061729],
                            [39.19898006, -4.84998204],
                            [39.1985789, -4.84948053],
                            [39.19804402, -4.84894559],
                            [39.1979103, -4.84844408],
                            [39.19817774, -4.84804287],
                            [39.1985789, -4.84750792],
                            [39.19891321, -4.84693955],
                            [39.19891321, -4.84627087],
                            [39.19898007, -4.84563562],
                            [39.19924751, -4.84520098],
                            [39.19944809, -4.84456573],
                            [39.19964868, -4.84383019],
                            [39.19961525, -4.84329524],
                            [39.19948153, -4.84282717],
                            [39.19954839, -4.84229222],
                            [39.19984926, -4.84185758],
                            [39.20021699, -4.84142294],
                            [39.20038415, -4.84082113],
                            [39.20028386, -4.84018588],
                            [39.20035072, -4.83965094],
                            [39.20035072, -4.83908256],
                            [39.2005513, -4.83824671],
                            [39.20068502, -4.83744429],
                            [39.20065159, -4.83667531],
                            [39.20058473, -4.83604007],
                            [39.20035072, -4.83520422],
                            [39.19994956, -4.83503705],
                            [39.1992141, -4.83523765],
                            [39.19824462, -4.83587289],
                            [39.19727514, -4.83657501],
                            [39.1962388, -4.83737742],
                            [39.19510217, -4.83834701],
                            [39.19406583, -4.83941689],
                            [39.19329694, -4.84005214],
                            [39.19256147, -4.84068738],
                            [39.19206002, -4.84142293],
                            [39.19179257, -4.84205817],
                            [39.19159199, -4.84279372],
                            [39.19122426, -4.84299433],
                            [39.19105711, -4.84272685],
                            [39.19088996, -4.84215848],
                            [39.19088996, -4.84162353],
                            [39.19072281, -4.84088798],
                            [39.19072281, -4.8400187],
                            [39.1906058, -4.83917171],
                            [39.19056123, -4.83796808],
                            [39.19069495, -4.83721024],
                            [39.19087325, -4.83618494],
                            [39.19105155, -4.83551626],
                            [39.19154186, -4.83484758],
                            [39.19207674, -4.83431263],
                            [39.19252248, -4.83373311],
                            [39.19283449, -4.83319817],
                            [39.19336938, -4.83261865],
                            [39.19394884, -4.83177165],
                            [39.19448372, -4.83123671],
                            [39.19488489, -4.83052345],
                            [39.19479574, -4.8302114],
                            [39.19435, -4.82998851],
                            [39.19394884, -4.82967645],
                            [39.1936814, -4.8293644],
                            [39.19340405, -4.82854217],
                            [39.1934932, -4.8276506],
                            [39.19376064, -4.82693734],
                            [39.19447382, -4.8264024],
                            [39.195187, -4.82586746],
                            [39.19564017, -4.82568419],
                            [39.19722501, -4.82528794],
                            [39.19896833, -4.82497094],
                            [39.19984, -4.82481243],
                            [39.20055318, -4.82465393],
                            [39.20071166, -4.82386142],
                            [39.20094939, -4.82291041],
                            [39.20071166, -4.82227641],
                            [39.20015697, -4.82172165],
                            [39.19896834, -4.82005738],
                            [39.19857213, -4.81942337],
                            [39.19857213, -4.81767985],
                            [39.19865138, -4.81569857],
                            [39.19904759, -4.81466831],
                            [39.1994438, -4.81466831],
                            [39.19991925, -4.81490607],
                            [39.20047394, -4.81538157],
                            [39.2009494, -4.81585708],
                            [39.20158333, -4.81672884],
                            [39.20205878, -4.8176006],
                            [39.20245499, -4.81894787],
                            [39.20293044, -4.81981963],
                            [39.20372286, -4.82061214],
                            [39.20506998, -4.82140465],
                            [39.20578315, -4.82140465],
                            [39.20625861, -4.82195941],
                            [39.20657557, -4.82283117],
                            [39.20760572, -4.82283117],
                            [39.20816041, -4.82219716],
                            [39.20879435, -4.82092915],
                            [39.20919056, -4.81989889],
                            [39.20950753, -4.81926488],
                            [39.21053768, -4.81847237],
                            [39.21180555, -4.81847237],
                            [39.212281, -4.81902713],
                            [39.21291494, -4.81989889],
                            [39.21354888, -4.82029515],
                            [39.21489599, -4.81974039],
                            [39.21608462, -4.81815538],
                            [39.21671856, -4.8163326],
                            [39.2173525, -4.81419283],
                            [39.21774871, -4.81157755],
                            [39.21822417, -4.80912077],
                            [39.21869963, -4.80690174],
                            [39.21917508, -4.80436571],
                            [39.21893736, -4.80246369],
                            [39.21838267, -4.80040317],
                            [39.21743176, -4.79762938],
                            [39.2171148, -4.79596511],
                            [39.21671859, -4.79445934],
                            [39.2161639, -4.79287432],
                            [39.21537148, -4.7914478],
                            [39.21442058, -4.78930803],
                            [39.21391705, -4.78835121],
                            [39.21362816, -4.78780226],
                            [39.2122018, -4.78772301],
                            [39.21085469, -4.787723],
                            [39.20998302, -4.78819851],
                            [39.20950757, -4.78899102],
                            [39.20831894, -4.78930802],
                            [39.20736804, -4.78978352],
                            [39.20649637, -4.78954577],
                            [39.20530774, -4.78891176],
                            [39.2037229, -4.78859475],
                            [39.2022173, -4.78883251],
                            [39.20102867, -4.78930801],
                            [39.20023625, -4.79025902],
                            [39.20063246, -4.79081378],
                            [39.20088403, -4.79100731],
                            [39.20166261, -4.79160629],
                            [39.20300972, -4.79231955],
                            [39.20435683, -4.79366682],
                            [39.20522849, -4.79469708],
                            [39.20633788, -4.79588584],
                            [39.20752651, -4.7964406],
                            [39.20831893, -4.79755012],
                            [39.2086359, -4.79953139],
                            [39.20847741, -4.80111641],
                            [39.2080812, -4.80285993],
                            [39.20744726, -4.80404869],
                            [39.20617939, -4.8043657],
                            [39.20507, -4.80412794],
                            [39.20380213, -4.80365243],
                            [39.20237577, -4.80341468],
                            [39.20166259, -4.80404869],
                            [39.20110789, -4.80563371],
                            [39.2005532, -4.80753573],
                            [39.20007774, -4.80912075],
                            [39.1994438, -4.81062651],
                            [39.19841366, -4.81118127],
                            [39.19730427, -4.81118127],
                            [39.19643261, -4.81038876],
                            [39.1960364, -4.80872449],
                            [39.19532322, -4.80713947],
                            [39.19445156, -4.8056337],
                            [39.19239127, -4.80444493],
                            [39.19072719, -4.80412792],
                            [39.1886669, -4.80349391],
                            [39.18763675, -4.80246365],
                            [39.18708206, -4.80127488],
                            [39.18692358, -4.80000687],
                            [39.18541798, -4.80040312],
                            [39.1847048, -4.80119563],
                            [39.18351617, -4.80222589],
                            [39.18280299, -4.80396941],
                            [39.18177284, -4.80563368],
                            [39.18074269, -4.80634694],
                            [39.17995027, -4.80626769],
                            [39.17899937, -4.80539592],
                            [39.17820695, -4.80444491],
                            [39.17796922, -4.80341465],
                            [39.17852392, -4.80198813],
                            [39.17899937, -4.80135413],
                            [39.17899937, -4.80016536],
                            [39.17844468, -4.79881809],
                            [39.17765226, -4.79723307],
                            [39.1767806, -4.7952518],
                            [39.17575046, -4.79327052],
                            [39.17440334, -4.7913685],
                            [39.17289775, -4.78986273],
                            [39.17178836, -4.78843621],
                            [39.17052049, -4.78645494],
                            [39.16925262, -4.78494917],
                            [39.1688581, -4.78453383],
                            [39.16774702, -4.78336415],
                            [39.16679612, -4.78225463],
                            [39.16568673, -4.78177912],
                            [39.16433962, -4.78201687],
                            [39.16459678, -4.78024568],
                            [39.1646568, -4.77993355],
                            [39.16489395, -4.77870029],
                            [39.16525054, -4.77768984],
                            [39.16572599, -4.77697658],
                            [39.165921, -4.77589927],
                            [39.16615501, -4.77523059],
                            [39.16658961, -4.77439474],
                            [39.16675676, -4.77385979],
                            [39.16685705, -4.77335828],
                            [39.16729164, -4.77265617],
                            [39.16759252, -4.77218809],
                            [39.16806054, -4.77165315],
                            [39.1684617, -4.77108477],
                            [39.1695649, -4.77044953],
                            [39.17043409, -4.77004832],
                            [39.17093554, -4.76974742],
                            [39.17130204, -4.76914808],
                            [39.17156948, -4.76844597],
                            [39.17187035, -4.76750982],
                            [39.17223809, -4.76577125],
                            [39.17237181, -4.7649354],
                            [39.17253896, -4.76419985],
                            [39.17304042, -4.76349774],
                            [39.17364216, -4.76236098],
                            [39.17457821, -4.76109049],
                            [39.17497938, -4.76002061],
                            [39.17531368, -4.75901759],
                            [39.17534711, -4.75831547],
                            [39.17541397, -4.75734589],
                            [39.17517996, -4.75694468],
                            [39.17484566, -4.75717872],
                            [39.17451135, -4.75778053],
                            [39.17447792, -4.75818174],
                            [39.17411019, -4.75858294],
                            [39.17344159, -4.75918475],
                            [39.17300699, -4.75958596],
                            [39.1725724, -4.7596194],
                            [39.17227153, -4.75948566],
                            [39.17227153, -4.75891728],
                            [39.17240525, -4.75841577],
                            [39.1725724, -4.75761336],
                            [39.17277298, -4.75677751],
                            [39.17273955, -4.75617569],
                            [39.17250554, -4.75574105],
                            [39.1719038, -4.75523954],
                            [39.17150264, -4.75477146],
                            [39.17143578, -4.75413622],
                            [39.17163636, -4.75373501],
                            [39.17180867, -4.75276006],
                            [39.17210583, -4.75174961],
                            [39.1720464, -4.7507986],
                            [39.1720464, -4.75008534],
                            [39.17151152, -4.74937208],
                            [39.17103607, -4.7487777],
                            [39.17008516, -4.74830219],
                            [39.1690154, -4.74776725],
                            [39.16776733, -4.7472323],
                            [39.1665787, -4.74663792],
                            [39.16586553, -4.74592466],
                            [39.16556837, -4.74408207],
                            [39.1656278, -4.74301218],
                            [39.16586553, -4.74164511],
                            [39.16622212, -4.74033747],
                            [39.16675701, -4.7391487],
                            [39.16721172, -4.7378981],
                            [39.16723155, -4.7379005],
                            [39.16764757, -4.73629567],
                            [39.16806359, -4.7346314],
                            [39.16859848, -4.73266994],
                            [39.16937109, -4.73053016],
                            [39.17038143, -4.72862814],
                            [39.17129554, -4.72749883],
                            [39.17140518, -4.72748377],
                            [39.17146939, -4.72734158],
                            [39.17210586, -4.72702331],
                            [39.17323506, -4.72631006],
                            [39.17400767, -4.72642893],
                            [39.17478028, -4.72726107],
                            [39.17561232, -4.72809321],
                            [39.17638493, -4.72797433],
                            [39.17668209, -4.72732051],
                            [39.17686039, -4.72625062],
                            [39.17751413, -4.72464579],
                            [39.17869038, -4.72374431],
                            [39.17953728, -4.72280816],
                            [39.17993845, -4.72187201],
                            [39.18074077, -4.72102502],
                            [39.18198884, -4.72031176],
                            [39.18261287, -4.7195985],
                            [39.18296946, -4.7183503],
                            [39.18292489, -4.71719126],
                            [39.18368264, -4.71589848],
                            [39.18446763, -4.71525456],
                            [39.18636944, -4.71418468],
                            [39.1868449, -4.71246097],
                            [39.18678547, -4.71044007],
                            [39.18613173, -4.70883524],
                            [39.18470537, -4.70853804],
                            [39.18369503, -4.7080031],
                            [39.18316015, -4.70627939],
                            [39.18321958, -4.70538782],
                            [39.18262527, -4.70479343],
                            [39.18167437, -4.704734],
                            [39.18084232, -4.70491231],
                            [39.1806046, -4.70431793],
                            [39.18102062, -4.7031886],
                            [39.18125835, -4.7019404],
                            [39.18072347, -4.70146489],
                            [39.17929711, -4.70110826],
                            [39.17905938, -4.70051388],
                            [39.17989143, -4.69997894],
                            [39.18030745, -4.69944399],
                            [39.17995086, -4.69896849],
                            [39.17941598, -4.6983741],
                            [39.17929711, -4.6978986],
                            [39.17977257, -4.6971259],
                            [39.18167438, -4.69706647],
                            [39.18238755, -4.69700703],
                            [39.18214983, -4.69641265],
                            [39.18137722, -4.69617489],
                            [39.18036688, -4.69540219],
                            [39.18030745, -4.6938568],
                            [39.18012916, -4.69213309],
                            [39.17953485, -4.69100377],
                            [39.17935655, -4.68975556],
                            [39.18036689, -4.68850736],
                            [39.18036689, -4.68749691],
                            [39.17935656, -4.68690253],
                            [39.17804906, -4.68666477],
                            [39.17644441, -4.68589208],
                            [39.17662271, -4.68470331],
                            [39.17816793, -4.68440612],
                            [39.17899997, -4.68422781],
                            [39.17959429, -4.68309848],
                            [39.17971315, -4.68179084],
                            [39.17959429, -4.68083983],
                            [39.18060463, -4.68119646],
                            [39.1816744, -4.68185028],
                            [39.18274416, -4.68196916],
                            [39.18304132, -4.68107759],
                            [39.18232814, -4.68012658],
                            [39.18209042, -4.67953219],
                            [39.18197156, -4.67905669],
                            [39.1837545, -4.67911613],
                            [39.18541858, -4.67917557],
                            [39.18678551, -4.67893782],
                            [39.18690437, -4.678284],
                            [39.18690437, -4.67697636],
                            [39.18708267, -4.67566872],
                            [39.18702324, -4.67495546],
                            [39.18743926, -4.67418276],
                            [39.18850903, -4.67364782],
                            [39.18922221, -4.67382613],
                            [39.19017311, -4.67430164],
                            [39.19094572, -4.67394501],
                            [39.19130231, -4.67305344],
                            [39.19189663, -4.6717458],
                            [39.19177777, -4.67073535],
                            [39.19368259, -4.6665213],
                            [39.19375231, -4.66533602],
                            [39.19388608, -4.66306173],
                            [39.19449652, -4.65878814],
                            [39.19103735, -4.65573558],
                            [39.18216358, -4.64942317],
                            [39.18148849, -4.64894252],
                            [39.17771863, -4.64625846],
                            [39.17200326, -4.64218924],
                            [39.17245363, -4.64153987],
                            [39.17300413, -4.63827825],
                            [39.17349206, -4.63538711],
                            [39.17757168, -4.62898264],
                            [39.21119056, -4.60391416],
                            [39.21197492, -4.60633588],
                            [39.21679083, -4.60798717],
                            [39.2232302, -4.60804231],
                            [39.22486405, -4.60523886],
                            [39.23775337, -4.60414158],
                            [39.24257277, -4.60539046],
                            [39.27656535, -4.63144505],
                            [39.28461544, -4.6315145],
                            [39.30550873, -4.63612258],
                            [39.30549497, -4.63773285],
                            [39.31751574, -4.64427779],
                            [39.32073605, -4.64430573],
                            [39.3341405, -4.63033038],
                            [39.33350035, -4.61100027],
                            [39.33190388, -4.60937618],
                            [39.33202702, -4.5948837],
                            [39.34362809, -4.55593009],
                            [39.34935512, -4.54510828],
                            [39.35743334, -4.54195549],
                            [39.36226431, -4.54199619],
                            [39.36384422, -4.54563322],
                            [39.36120186, -4.57258629],
                            [39.36517997, -4.57825682],
                            [39.37163816, -4.57629871],
                            [39.37328588, -4.57188373],
                            [39.37530209, -4.5714982],
                            [39.38201438, -4.53934471],
                            [39.38610418, -4.53172901],
                            [39.38615119, -4.52609249],
                            [39.40251966, -4.49442099],
                            [39.42157556, -4.47847365],
                            [39.43619268, -4.46369636],
                            [39.44842384, -4.44527447],
                            [39.46276622, -4.41478821],
                            [39.48013358, -4.35855174],
                            [39.53386975, -4.23453838],
                            [39.53628595, -4.23455738],
                            [39.57839755, -4.15312466],
                            [39.58778032, -4.13748834],
                            [39.58805229, -4.10164244],
                            [39.58566021, -4.09840201],
                            [39.58015534, -4.08063779],
                            [39.56975692, -4.07089244],
                            [39.55406584, -4.06875998],
                            [39.51605167, -4.0898174],
                            [39.51283023, -4.08979289],
                            [39.50803145, -4.08532653],
                            [39.51217614, -4.06965152],
                            [39.53017684, -4.03192984],
                            [39.54147331, -4.02919516],
                            [39.59103231, -4.02634382],
                            [39.6608395, -4.0095437],
                            [39.71470364, -3.97046249],
                            [39.7704612, -3.83589484],
                            [39.77705735, -3.81458674],
                            [39.78715821, -3.75260648],
                            [39.79213157, -3.73249424],
                            [39.7927464, -3.70147236],
                            [39.78744617, -3.65106923],
                            [39.77344245, -3.63646911],
                            [39.73094554, -3.60515986],
                            [39.72616726, -3.59666712],
                            [39.72864547, -3.58741727],
                            [39.78529775, -3.61076149],
                            [39.78528175, -3.61317902],
                            [39.81026677, -3.61254067],
                            [39.83750954, -3.57524711],
                            [39.84001163, -3.56236854],
                            [39.85810183, -3.50687608],
                            [39.8683436, -3.48074816],
                            [39.87158504, -3.4779481],
                            [39.90424791, -3.41085452],
                            [39.90556265, -3.39393582],
                            [39.90015566, -3.35601836],
                            [39.93741572, -3.32602191],
                            [39.93737397, -3.33287343],
                            [39.92925224, -3.34289923],
                            [39.92916304, -3.35740795],
                            [39.9355811, -3.36228441],
                            [39.94207642, -3.35466702],
                            [39.95096982, -3.3502888],
                            [39.97161544, -3.33550345],
                            [40.06300112, -3.28769202],
                            [40.08325557, -3.2712862],
                            [40.10355675, -3.17868043],
                            [40.11260566, -3.14768931],
                            [40.11928232, -3.10821665],
                            [40.12104124, -3.08242307],
                            [40.1226584, -3.081626],
                            [40.13085726, -3.05748132],
                            [40.1314334, -3.02643919],
                            [40.180487, -2.9775205],
                            [40.18052426, -2.97066606],
                            [40.17208379, -2.96578091],
                            [40.16482668, -2.96574103],
                            [40.14867361, -2.9704907],
                            [40.11953927, -2.99008625],
                            [40.11712044, -2.99007294],
                            [40.11741572, -2.93564545],
                            [40.11903694, -2.93404155],
                            [40.12187894, -2.85422703],
                            [40.12523396, -2.82924741],
                            [40.12919268, -2.76475837],
                            [40.12639528, -2.67886579],
                            [40.14904268, -2.66446284],
                            [40.14819106, -2.6737322],
                            [40.15133524, -2.69027911],
                            [40.15939846, -2.69031924],
                            [40.17194236, -2.68110777],
                            [40.18169168, -2.66623678],
                            [40.24128522, -2.59958821],
                            [40.24451678, -2.59839378],
                            [40.25829741, -2.58353842],
                            [40.26071699, -2.58355004],
                            [40.31855779, -2.54672048],
                            [40.36986358, -2.52840713],
                            [40.39615834, -2.51158784],
                            [40.42360483, -2.50768124],
                            [40.45062919, -2.50821005],
                            [40.46435743, -2.50544949],
                            [40.46198486, -2.49494927],
                            [40.43382321, -2.4782794],
                            [40.43706834, -2.47426009],
                            [40.44839225, -2.46785713],
                            [40.45808082, -2.46628755],
                            [40.54863309, -2.51471684],
                            [40.56152347, -2.51921539],
                            [40.58857146, -2.51611299],
                            [40.63193029, -2.47595835],
                            [40.65217942, -2.45990782],
                            [40.6841357, -2.44310171],
                            [40.7108037, -2.43595675],
                            [40.75982224, -2.39460039],
                            [40.7822259, -2.34746935],
                            [40.77500542, -2.33694262],
                            [40.74467886, -2.34972854],
                            [40.72786857, -2.31655758],
                            [40.72628647, -2.30888193],
                            [40.69976486, -2.28132312],
                            [40.68568863, -2.26955934],
                            [40.69342125, -2.25385101],
                            [40.70390405, -2.25631631],
                            [40.70796129, -2.25108601],
                            [40.69188291, -2.23527867],
                            [40.6793939, -2.22998048],
                            [40.68148187, -2.21263413],
                            [40.68154837, -2.19608679],
                            [40.70813537, -2.20830306],
                            [40.71781643, -2.20955349],
                            [40.73232778, -2.2140526],
                            [40.76503479, -2.21055329],
                            [40.84060607, -2.18865686],
                            [40.85802856, -2.17257795],
                            [40.86453538, -2.16049183],
                            [40.87018734, -2.1605144],
                            [40.88137911, -2.18882152],
                            [40.88704226, -2.18601795],
                            [40.89361009, -2.15858892],
                            [40.89130771, -2.12789408],
                            [40.888912, -2.121021],
                            [40.88735304, -2.10647978],
                            [40.87859434, -2.07414585],
                            [40.86733012, -2.06360562],
                            [40.85605102, -2.05710308],
                            [40.84649884, -2.02032817],
                            [40.85220074, -2.00662247],
                            [40.84337692, -1.99084476],
                            [40.82966367, -1.98756489],
                            [40.825662, -1.97786124],
                            [40.82568376, -1.97180562],
                            [40.81201277, -1.9568191],
                            [40.7987309, -1.94587177],
                            [40.76812436, -1.92598255],
                            [40.7689796, -1.91226097],
                            [40.77383001, -1.91025966],
                            [40.79924509, -1.91438593],
                            [40.80328433, -1.91359292],
                            [40.8154276, -1.90394684],
                            [40.82188898, -1.90316202],
                            [40.8259144, -1.90640595],
                            [40.82992307, -1.9144942],
                            [40.83229002, -1.93024734],
                            [40.84195834, -1.93593387],
                            [40.8427572, -1.93835891],
                            [40.84678837, -1.93998806],
                            [40.86686936, -1.96912901],
                            [40.87938262, -1.96957818],
                            [40.88502877, -1.97121371],
                            [40.88905275, -1.97486224],
                            [40.895432, -1.99709211],
                            [40.90187256, -2.00236479],
                            [40.90186081, -2.00559483],
                            [40.9163209, -2.02583709],
                            [40.91630753, -2.02947086],
                            [41.14196954, -2.02778006],
                            [41.18436406, -1.97705844],
                            [41.36770432, -1.91091016],
                            [41.51867246, -1.86669334],
                            [41.4986564, -10.5506175],
                        ],
                        [
                            [39.67747615, -4.86848786],
                            [39.67734691, -4.86895963],
                            [39.67730168, -4.86914059],
                            [39.67720475, -4.86951542],
                            [39.67714659, -4.86956712],
                            [39.67695919, -4.86963175],
                            [39.67689457, -4.86996781],
                            [39.67683641, -4.87034264],
                            [39.6767201, -4.87059469],
                            [39.67659732, -4.87076918],
                            [39.67649393, -4.87102122],
                            [39.67637762, -4.87116986],
                            [39.67638408, -4.87157055],
                            [39.67635177, -4.87199709],
                            [39.67625484, -4.87227498],
                            [39.67622899, -4.87262396],
                            [39.67617083, -4.87293417],
                            [39.67617083, -4.87332839],
                            [39.6761256, -4.87372262],
                            [39.67614498, -4.87397466],
                            [39.67613444, -4.87403912],
                            [39.67608682, -4.87433011],
                            [39.67617729, -4.87449814],
                            [39.6762096, -4.87473079],
                            [39.67613852, -4.87483419],
                            [39.6758865, -4.87487297],
                            [39.67581542, -4.87503454],
                            [39.67592527, -4.87565495],
                            [39.67598989, -4.87606856],
                            [39.67603513, -4.87650802],
                            [39.67613205, -4.87683116],
                            [39.67604805, -4.87704443],
                            [39.67599635, -4.87714137],
                            [39.6759881, -4.87719277],
                            [39.67597986, -4.87724417],
                            [39.67596547, -4.8773338],
                            [39.67595649, -4.87737652],
                            [39.67591235, -4.87766484],
                            [39.67635176, -4.87831111],
                            [39.67689456, -4.87916418],
                            [39.67720473, -4.87968119],
                            [39.6775666, -4.88035331],
                            [39.67785092, -4.88099958],
                            [39.6781094, -4.8817234],
                            [39.67831618, -4.88229211],
                            [39.67834203, -4.88267987],
                            [39.67825156, -4.8826928],
                            [39.6781611, -4.88270572],
                            [39.67841957, -4.88319688],
                            [39.67854881, -4.88384315],
                            [39.67831618, -4.88441186],
                            [39.67798016, -4.88477377],
                            [39.67795431, -4.88498058],
                            [39.67816109, -4.88518738],
                            [39.67823863, -4.8856527],
                            [39.67834202, -4.88622141],
                            [39.6783608, -4.88737094],
                            [39.67807313, -4.8883539],
                            [39.67826491, -4.88878544],
                            [39.67833682, -4.88973244],
                            [39.67828888, -4.89032581],
                            [39.67824093, -4.89091919],
                            [39.67765362, -4.89155451],
                            [39.67785738, -4.89293306],
                            [39.67794128, -4.89384409],
                            [39.67790532, -4.8944075],
                            [39.67791731, -4.89491097],
                            [39.67776149, -4.89543841],
                            [39.6775817, -4.89553431],
                            [39.6772341, -4.89540245],
                            [39.6771502, -4.89558226],
                            [39.6771502, -4.89586995],
                            [39.67722212, -4.89624156],
                            [39.67722212, -4.89658919],
                            [39.67717417, -4.89697279],
                            [39.6771058, -4.89719113],
                            [39.67676131, -4.89829113],
                            [39.67667577, -4.89946738],
                            [39.67666352, -4.89963584],
                            [39.67678576, -4.90088275],
                            [39.67689664, -4.90172793],
                            [39.67696112, -4.90259855],
                            [39.67683215, -4.90337244],
                            [39.67692888, -4.90394211],
                            [39.67678916, -4.90450102],
                            [39.67648824, -4.90449027],
                            [39.67642913, -4.90459238],
                            [39.67637002, -4.90469449],
                            [39.67643451, -4.90509219],
                            [39.6762733, -4.90537165],
                            [39.67617542, -4.90555988],
                            [39.67613358, -4.90564036],
                            [39.67626255, -4.90618853],
                            [39.67629349, -4.90682804],
                            [39.67629479, -4.90685493],
                            [39.67630814, -4.90703303],
                            [39.67635927, -4.9077148],
                            [39.67648824, -4.90834896],
                            [39.67620881, -4.9091336],
                            [39.6758434, -4.90967102],
                            [39.67573593, -4.91027293],
                            [39.67570734, -4.91039249],
                            [39.67561771, -4.91076736],
                            [39.67544575, -4.91118654],
                            [39.67541351, -4.9118207],
                            [39.67531678, -4.91206792],
                            [39.67534903, -4.91280956],
                            [39.6750696, -4.91326099],
                            [39.6748654, -4.91387365],
                            [39.67480092, -4.9141961],
                            [39.67488689, -4.91458305],
                            [39.67488846, -4.91460575],
                            [39.67490839, -4.91489475],
                            [39.67485465, -4.91564714],
                            [39.67487614, -4.91587823],
                            [39.67489764, -4.91610932],
                            [39.67483906, -4.91636569],
                            [39.67460494, -4.91647496],
                            [39.67418105, -4.91667279],
                            [39.67406407, -4.91702377],
                            [39.67415181, -4.91793046],
                            [39.67412256, -4.91861779],
                            [39.6741518, -4.91898339],
                            [39.67394343, -4.91927401],
                            [39.67402524, -4.91971036],
                            [39.67412212, -4.92022711],
                            [39.67420153, -4.92110079],
                            [39.67402284, -4.92177591],
                            [39.67412211, -4.92203404],
                            [39.67390372, -4.92264959],
                            [39.67366547, -4.92336442],
                            [39.67346731, -4.92377178],
                            [39.67330809, -4.9240991],
                            [39.67337334, -4.92443628],
                            [39.67342721, -4.92471465],
                            [39.67346692, -4.92519121],
                            [39.67332794, -4.92554862],
                            [39.67344707, -4.92630316],
                            [39.67324852, -4.92695842],
                            [39.67304998, -4.92745483],
                            [39.67318896, -4.92812995],
                            [39.67328823, -4.92890435],
                            [39.672911, -4.92963903],
                            [39.672918, -4.92991506],
                            [39.6731106, -4.93015048],
                            [39.6731213, -4.93053573],
                            [39.673132, -4.9311029],
                            [39.673239, -4.93134903],
                            [39.67324702, -4.93139451],
                            [39.67325505, -4.93143999],
                            [39.67326812, -4.93208951],
                            [39.67330015, -4.93263409],
                            [39.67334788, -4.93286842],
                            [39.67347632, -4.93349901],
                            [39.67358843, -4.93421978],
                            [39.67373256, -4.93516479],
                            [39.67389271, -4.93649421],
                            [39.67360444, -4.93799981],
                            [39.67315601, -4.93819202],
                            [39.67313689, -4.93820931],
                            [39.67248337, -4.93880067],
                            [39.67225915, -4.93982576],
                            [39.67254223, -4.94143948],
                            [39.67255237, -4.94149728],
                            [39.67257945, -4.94165171],
                            [39.67248336, -4.9425807],
                            [39.67248336, -4.9438941],
                            [39.67261148, -4.94568801],
                            [39.67202482, -4.94606938],
                            [39.67197087, -4.94610445],
                            [39.67201978, -4.9464224],
                            [39.67205401, -4.94664492],
                            [39.67209899, -4.94693734],
                            [39.67267554, -4.94687327],
                            [39.67261148, -4.94735379],
                            [39.67222711, -4.94751396],
                            [39.67200404, -4.94767331],
                            [39.6720029, -4.94767413],
                            [39.67193883, -4.94860312],
                            [39.67199655, -4.94866468],
                            [39.67241929, -4.94911566],
                            [39.67261147, -4.94969228],
                            [39.67293178, -4.95020483],
                            [39.67302787, -4.95071737],
                            [39.67305124, -4.95118478],
                            [39.6730599, -4.95135806],
                            [39.67277162, -4.95206281],
                            [39.6730599, -4.95414503],
                            [39.67334817, -4.95446537],
                            [39.67418097, -4.95408096],
                            [39.67418096, -4.95443334],
                            [39.67350832, -4.95478571],
                            [39.67299583, -4.95507402],
                            [39.6729638, -4.95526622],
                            [39.67312396, -4.9557147],
                            [39.6737966, -4.95603504],
                            [39.67462939, -4.95657962],
                            [39.67510985, -4.95609911],
                            [39.67555828, -4.95472164],
                            [39.67623092, -4.95401689],
                            [39.67706372, -4.95366452],
                            [39.67751214, -4.95324808],
                            [39.67773636, -4.95360045],
                            [39.67826959, -4.95369921],
                            [39.67840041, -4.95372344],
                            [39.67860118, -4.95376062],
                            [39.67911367, -4.95385673],
                            [39.67950586, -4.95427204],
                            [39.67965819, -4.95443334],
                            [39.68084332, -4.95475369],
                            [39.68149839, -4.95491747],
                            [39.68225266, -4.95510606],
                            [39.68276515, -4.95545844],
                            [39.6829253, -4.95609912],
                            [39.68385419, -4.95718829],
                            [39.68459089, -4.95750863],
                            [39.68500729, -4.95824541],
                            [39.6855518, -4.95875796],
                            [39.68587211, -4.95814931],
                            [39.68577602, -4.95715625],
                            [39.68534994, -4.95641541],
                            [39.68492158, -4.95584947],
                            [39.68454601, -4.95535328],
                            [39.68433465, -4.95507403],
                            [39.6838863, -4.95473773],
                            [39.68356592, -4.95449742],
                            [39.68285845, -4.95318073],
                            [39.68210974, -4.95178726],
                            [39.6818683, -4.95081349],
                            [39.68170815, -4.94937195],
                            [39.68206049, -4.94873127],
                            [39.68180424, -4.94789838],
                            [39.68097856, -4.94670818],
                            [39.68141988, -4.94658498],
                            [39.68100612, -4.9460303],
                            [39.68113161, -4.94536768],
                            [39.68177222, -4.94514344],
                            [39.68212455, -4.94514344],
                            [39.68234877, -4.94565599],
                            [39.68250892, -4.94620057],
                            [39.68298419, -4.9469294],
                            [39.68298938, -4.94693735],
                            [39.68375811, -4.94780228],
                            [39.68391826, -4.94834686],
                            [39.6834378, -4.94940399],
                            [39.68360494, -4.94989027],
                            [39.68379014, -4.95042908],
                            [39.68401435, -4.95081349],
                            [39.68392694, -4.95160025],
                            [39.68391826, -4.95167841],
                            [39.68369404, -4.95215892],
                            [39.68427059, -4.95279961],
                            [39.68580806, -4.95430521],
                            [39.68686506, -4.95478573],
                            [39.68760176, -4.95577879],
                            [39.68789004, -4.95638744],
                            [39.68769785, -4.95709219],
                            [39.68764459, -4.95756073],
                            [39.68764459, -4.95800064],
                            [39.68765683, -4.95857897],
                            [39.68787645, -4.959032],
                            [39.68821961, -4.95925164],
                            [39.68795881, -4.95958111],
                            [39.6878851, -4.95977462],
                            [39.687849, -4.9598694],
                            [39.68785797, -4.95995465],
                            [39.68790391, -4.96039106],
                            [39.6881098, -4.96077544],
                            [39.68830197, -4.9611461],
                            [39.68833797, -4.96121256],
                            [39.68848041, -4.96147557],
                            [39.68876867, -4.96185995],
                            [39.68896084, -4.96194232],
                            [39.6888373, -4.96220315],
                            [39.68879612, -4.96275227],
                            [39.68878239, -4.9630131],
                            [39.68885102, -4.96352103],
                            [39.68885102, -4.96375441],
                            [39.68875494, -4.96397405],
                            [39.68886475, -4.96426234],
                            [39.68902946, -4.96438589],
                            [39.68940008, -4.96411133],
                            [39.69003149, -4.96334257],
                            [39.69063545, -4.96272481],
                            [39.69087208, -4.96252932],
                            [39.69126687, -4.96220315],
                            [39.69151693, -4.96194444],
                            [39.69166494, -4.96179132],
                            [39.69187083, -4.96150303],
                            [39.69192133, -4.96138038],
                            [39.69196692, -4.96126966],
                            [39.69206013, -4.96111731],
                            [39.6921362, -4.96099298],
                            [39.6922372, -4.96102514],
                            [39.69224234, -4.96113708],
                            [39.6921555, -4.96121621],
                            [39.69216267, -4.96137379],
                            [39.69219043, -4.96154037],
                            [39.69237087, -4.96154037],
                            [39.69257907, -4.96158202],
                            [39.69267623, -4.96141544],
                            [39.69288443, -4.96142932],
                            [39.69296047, -4.96138707],
                            [39.69300935, -4.96135991],
                            [39.69314815, -4.96148485],
                            [39.69325919, -4.96156814],
                            [39.69338412, -4.96148485],
                            [39.69356456, -4.96151261],
                            [39.69370336, -4.96148485],
                            [39.69382691, -4.96168256],
                            [39.69384216, -4.96170695],
                            [39.69407812, -4.96201235],
                            [39.69428632, -4.9622761],
                            [39.69456392, -4.96234551],
                            [39.69482764, -4.96252597],
                            [39.69493868, -4.96274808],
                            [39.69488316, -4.96298406],
                            [39.69470272, -4.96316452],
                            [39.69453616, -4.96334498],
                            [39.69445288, -4.96351156],
                            [39.6944737, -4.96357403],
                            [39.69449452, -4.9636365],
                            [39.69452784, -4.96365957],
                            [39.69458474, -4.96369897],
                            [39.69460516, -4.9637624],
                            [39.69452045, -4.96382452],
                            [39.69446599, -4.9638273],
                            [39.69441153, -4.96383007],
                            [39.69422205, -4.96401958],
                            [39.69430326, -4.96417299],
                            [39.694204, -4.96429933],
                            [39.69406865, -4.96440762],
                            [39.69403209, -4.96461272],
                            [39.69387406, -4.96463888],
                            [39.69381443, -4.96464874],
                            [39.6937548, -4.96465861],
                            [39.69363554, -4.96467835],
                            [39.6935453, -4.9649581],
                            [39.69340093, -4.96507541],
                            [39.69339191, -4.96539126],
                            [39.69344605, -4.96546346],
                            [39.69342327, -4.96560885],
                            [39.69330168, -4.96564394],
                            [39.69322047, -4.96596881],
                            [39.6931934, -4.96625759],
                            [39.69299297, -4.96660475],
                            [39.69291368, -4.9666366],
                            [39.69287758, -4.96677197],
                            [39.69299489, -4.96689831],
                            [39.69299489, -4.96717806],
                            [39.69306707, -4.96757512],
                            [39.6931573, -4.96777366],
                            [39.69349116, -4.96808048],
                            [39.69364456, -4.9681617],
                            [39.6937799, -4.96809853],
                            [39.69385209, -4.9679],
                            [39.69389721, -4.96762025],
                            [39.6939333, -4.96745781],
                            [39.6940506, -4.96739464],
                            [39.69407767, -4.9672322],
                            [39.69389721, -4.96702465],
                            [39.6941679, -4.96673587],
                            [39.69433032, -4.96662758],
                            [39.69449274, -4.96651929],
                            [39.69472734, -4.96642002],
                            [39.69498902, -4.96627564],
                            [39.6952146, -4.96617637],
                            [39.69534994, -4.96619442],
                            [39.69560259, -4.96631173],
                            [39.69589134, -4.96651929],
                            [39.69603575, -4.96668999],
                            [39.6961891, -4.96687124],
                            [39.69627933, -4.96687124],
                            [39.69636957, -4.96681709],
                            [39.69645078, -4.9667449],
                            [39.69653198, -4.96672685],
                            [39.69648687, -4.9669705],
                            [39.69640566, -4.96725928],
                            [39.69634897, -4.96754704],
                            [39.69634464, -4.96756905],
                            [39.69613496, -4.96776464],
                            [39.69593315, -4.96786828],
                            [39.6958011, -4.9679361],
                            [39.69554845, -4.9679],
                            [39.69536383, -4.96778875],
                            [39.69515063, -4.96795982],
                            [39.6951294, -4.96797686],
                            [39.69498901, -4.96808951],
                            [39.69497999, -4.96831511],
                            [39.69520557, -4.96852267],
                            [39.69512436, -4.96882949],
                            [39.69510631, -4.96906412],
                            [39.69536799, -4.96924461],
                            [39.69572892, -4.969389],
                            [39.69590938, -4.969389],
                            [39.69590036, -4.96921754],
                            [39.69585524, -4.96906412],
                            [39.69590036, -4.96886559],
                            [39.69615301, -4.96889266],
                            [39.69621617, -4.9687212],
                            [39.69640566, -4.96862194],
                            [39.69667592, -4.96852365],
                            [39.69670342, -4.96851365],
                            [39.69691998, -4.96891071],
                            [39.6970463, -4.96896486],
                            [39.69714556, -4.96886559],
                            [39.69707337, -4.96850462],
                            [39.69710947, -4.96831511],
                            [39.69715437, -4.96805468],
                            [39.69715458, -4.96805341],
                            [39.69729034, -4.96785654],
                            [39.69733505, -4.96779171],
                            [39.6974704, -4.96795415],
                            [39.69756063, -4.9681978],
                            [39.69765988, -4.96850462],
                            [39.69779523, -4.96852267],
                            [39.69801179, -4.96826097],
                            [39.69814714, -4.96824292],
                            [39.69840881, -4.96833316],
                            [39.69877876, -4.96842341],
                            [39.69905848, -4.96858584],
                            [39.69931113, -4.96878438],
                            [39.69940136, -4.96880243],
                            [39.69959987, -4.96881145],
                            [39.69967206, -4.96896486],
                            [39.69977131, -4.96898291],
                            [39.69987959, -4.96900998],
                            [39.69974123, -4.96909354],
                            [39.6997262, -4.96918144],
                            [39.69980741, -4.96926266],
                            [39.69987959, -4.96940705],
                            [39.69992471, -4.96955144],
                            [39.70003865, -4.96969891],
                            [39.7000781, -4.96974997],
                            [39.70029595, -4.96978673],
                            [39.70028563, -4.9699485],
                            [39.70036684, -4.97047191],
                            [39.70039884, -4.97056152],
                            [39.70050219, -4.97085093],
                            [39.70069475, -4.97104351],
                            [39.70072777, -4.97107653],
                            [39.70083312, -4.97112395],
                            [39.70090823, -4.97115775],
                            [39.701065, -4.97108671],
                            [39.70107065, -4.97120287],
                            [39.70098042, -4.9714014],
                            [39.7010526, -4.97152774],
                            [39.70118613, -4.97155712],
                            [39.70119698, -4.9717353],
                            [39.70130525, -4.97198798],
                            [39.70145865, -4.97227675],
                            [39.70163009, -4.97252943],
                            [39.70148572, -4.97280918],
                            [39.70145102, -4.97290981],
                            [39.70139548, -4.97307089],
                            [39.70136841, -4.97357624],
                            [39.70144962, -4.97377478],
                            [39.70160694, -4.97381955],
                            [39.70161678, -4.97393096],
                            [39.70163009, -4.9740816],
                            [39.70174739, -4.97437037],
                            [39.70201808, -4.97447867],
                            [39.70258655, -4.97446062],
                            [39.70339864, -4.97419892],
                            [39.7041332, -4.97368575],
                            [39.70462579, -4.97334162],
                            [39.70581686, -4.97260163],
                            [39.70617528, -4.97241833],
                            [39.7066109, -4.97219554],
                            [39.70676311, -4.97197497],
                            [39.70691533, -4.97175439],
                            [39.70695074, -4.9719624],
                            [39.70703602, -4.97246336],
                            [39.70733266, -4.97245313],
                            [39.70766467, -4.97244168],
                            [39.70803318, -4.9720948],
                            [39.7082066, -4.97261512],
                            [39.70833896, -4.97275603],
                            [39.7085426, -4.97297284],
                            [39.70887861, -4.97333056],
                            [39.70883334, -4.97341544],
                            [39.70853176, -4.97398096],
                            [39.70781641, -4.97450127],
                            [39.70757795, -4.97519503],
                            [39.7078164, -4.97571535],
                            [39.70881357, -4.97614895],
                            [39.7104177, -4.97653919],
                            [39.71078622, -4.97718959],
                            [39.71100299, -4.97775327],
                            [39.71033099, -4.97801342],
                            [39.71015757, -4.97885894],
                            [39.71059112, -4.97979118],
                            [39.71113305, -4.98002966],
                            [39.71158828, -4.98002966],
                            [39.71191344, -4.98078846],
                            [39.71178338, -4.98135214],
                            [39.71171834, -4.98193749],
                            [39.7117617, -4.98245781],
                            [39.71125095, -4.98216974],
                            [39.71127388, -4.9810461],
                            [39.71090702, -4.98067919],
                            [39.71026501, -4.98010591],
                            [39.70992108, -4.97957848],
                            [39.70976058, -4.97868415],
                            [39.70987522, -4.97792741],
                            [39.70996694, -4.97756051],
                            [39.71003573, -4.97723947],
                            [39.7098523, -4.97698722],
                            [39.70904978, -4.97701015],
                            [39.70797212, -4.97696429],
                            [39.7075594, -4.97723946],
                            [39.70778869, -4.97801914],
                            [39.70799505, -4.97847777],
                            [39.70753647, -4.97854656],
                            [39.70728425, -4.97834018],
                            [39.70680275, -4.978065],
                            [39.70655053, -4.97751464],
                            [39.70613781, -4.97749171],
                            [39.7058168, -4.97739998],
                            [39.7054958, -4.97730826],
                            [39.70492258, -4.97742291],
                            [39.70441814, -4.97728532],
                            [39.70400542, -4.97687255],
                            [39.70334048, -4.97701014],
                            [39.70292776, -4.97758343],
                            [39.70230868, -4.97808792],
                            [39.70164374, -4.97870707],
                            [39.7009788, -4.97911984],
                            [39.70017629, -4.97934915],
                            [39.69985528, -4.97944088],
                            [39.69905277, -4.97932622],
                            [39.6983649, -4.9791657],
                            [39.69786047, -4.97907397],
                            [39.69774582, -4.97863827],
                            [39.69767704, -4.97788153],
                            [39.69758532, -4.97719359],
                            [39.69751653, -4.97643685],
                            [39.69714967, -4.97590942],
                            [39.69692038, -4.97570304],
                            [39.69714967, -4.97540493],
                            [39.69712675, -4.9749463],
                            [39.69731018, -4.97418956],
                            [39.69724139, -4.97373093],
                            [39.69708089, -4.9732035],
                            [39.69666817, -4.97269901],
                            [39.69664524, -4.97253849],
                            [39.69630131, -4.97267608],
                            [39.69524657, -4.9736392],
                            [39.69458164, -4.97414369],
                            [39.69432942, -4.97430421],
                            [39.69380205, -4.97412076],
                            [39.69327469, -4.9740061],
                            [39.69293075, -4.97389144],
                            [39.69242632, -4.9740749],
                            [39.69185309, -4.97430421],
                            [39.69134866, -4.974373],
                            [39.69015635, -4.97467111],
                            [39.68966915, -4.97499129],
                            [39.68926194, -4.97526279],
                            [39.68885473, -4.9758058],
                            [39.68878686, -4.97600943],
                            [39.68871899, -4.97621306],
                            [39.68874162, -4.97655244],
                            [39.68899047, -4.97680132],
                            [39.68917145, -4.97691445],
                            [39.68935243, -4.97734433],
                            [39.68948817, -4.97833985],
                            [39.68948817, -4.97853216],
                            [39.68951079, -4.9786566],
                            [39.68931849, -4.97878104],
                            [39.68919407, -4.9789281],
                            [39.68913751, -4.97915436],
                            [39.68899612, -4.97926183],
                            [39.68889432, -4.97944849],
                            [39.68874727, -4.9794994],
                            [39.68870768, -4.97974827],
                            [39.6885776, -4.97975959],
                            [39.68827784, -4.97991796],
                            [39.68822694, -4.98007069],
                            [39.68805161, -4.98017816],
                            [39.6878876, -4.98007068],
                            [39.68761612, -4.98012725],
                            [39.68736727, -4.97996887],
                            [39.68685426, -4.97965787],
                            [39.68677085, -4.97963934],
                            [39.68668744, -4.9796208],
                            [39.68581629, -4.9796208],
                            [39.68507489, -4.97921298],
                            [39.68463377, -4.97917621],
                            [39.68440762, -4.97915737],
                            [39.68424576, -4.97908235],
                            [39.68408391, -4.97900734],
                            [39.68364768, -4.97880516],
                            [39.68331404, -4.97854563],
                            [39.68272092, -4.97817489],
                            [39.68240582, -4.97776707],
                            [39.68229461, -4.97750755],
                            [39.68196098, -4.97722949],
                            [39.6818127, -4.97717388],
                            [39.6814976, -4.97711826],
                            [39.68136785, -4.97724802],
                            [39.68073766, -4.97735925],
                            [39.68042256, -4.97752608],
                            [39.68007039, -4.97756316],
                            [39.67988504, -4.97758169],
                            [39.67973676, -4.97758169],
                            [39.67960701, -4.97756315],
                            [39.67942166, -4.97767438],
                            [39.67927338, -4.97780414],
                            [39.67910656, -4.97791536],
                            [39.67884707, -4.97791536],
                            [39.67869879, -4.97789682],
                            [39.67814273, -4.97836025],
                            [39.6778647, -4.97854563],
                            [39.67767935, -4.9788793],
                            [39.67751253, -4.97928712],
                            [39.67725304, -4.97973201],
                            [39.67658577, -4.98004714],
                            [39.67597411, -4.98065887],
                            [39.67558487, -4.98119645],
                            [39.67571462, -4.98149305],
                            [39.67558487, -4.9815672],
                            [39.6755478, -4.98195648],
                            [39.67552927, -4.98234576],
                            [39.67552927, -4.98271651],
                            [39.67532538, -4.98282773],
                            [39.67502882, -4.98295749],
                            [39.67504735, -4.98308725],
                            [39.67489907, -4.98351361],
                            [39.67482493, -4.98399558],
                            [39.67489907, -4.98436632],
                            [39.67497321, -4.98453316],
                            [39.67514002, -4.98445901],
                            [39.67521416, -4.98458877],
                            [39.67504735, -4.98488537],
                            [39.67515856, -4.98518196],
                            [39.67526977, -4.98542295],
                            [39.67527752, -4.98550826],
                            [39.6752883, -4.98562686],
                            [39.67532537, -4.98590492],
                            [39.67549219, -4.98605321],
                            [39.6759741, -4.9864425],
                            [39.67627067, -4.9864425],
                            [39.67649309, -4.98657226],
                            [39.6766043, -4.98677617],
                            [39.67690086, -4.98712838],
                            [39.67712328, -4.98714692],
                            [39.67714182, -4.9873879],
                            [39.67706768, -4.98772157],
                            [39.67740131, -4.98796256],
                            [39.67767934, -4.98835184],
                            [39.67784927, -4.98864319],
                            [39.67793883, -4.98879673],
                            [39.6779931, -4.98884449],
                            [39.67815423, -4.9889863],
                            [39.67825614, -4.98922411],
                            [39.67830175, -4.98933055],
                            [39.67848204, -4.98960922],
                            [39.67869512, -4.98995346],
                            [39.67874429, -4.99039606],
                            [39.67851482, -4.99062555],
                            [39.67833453, -4.99103537],
                            [39.67818701, -4.99147797],
                            [39.67790837, -4.99165828],
                            [39.67754777, -4.99195335],
                            [39.6774986, -4.99215006],
                            [39.67761333, -4.9924943],
                            [39.6774986, -4.99262544],
                            [39.67748221, -4.99296968],
                            [39.67748221, -4.99323196],
                            [39.67758055, -4.99341228],
                            [39.67762972, -4.99362538],
                            [39.67762972, -4.99388766],
                            [39.67769529, -4.9940188],
                            [39.67785919, -4.99433026],
                            [39.67812144, -4.99437944],
                            [39.67808866, -4.99452697],
                            [39.67825257, -4.99465811],
                            [39.67853121, -4.99478925],
                            [39.6787279, -4.99465811],
                            [39.67895737, -4.99487122],
                            [39.67921962, -4.99508432],
                            [39.67946548, -4.99511711],
                            [39.67964577, -4.99508432],
                            [39.67961299, -4.99521546],
                            [39.67953104, -4.99536299],
                            [39.67951465, -4.99554331],
                            [39.67964577, -4.99560888],
                            [39.67980968, -4.99551053],
                            [39.67992442, -4.99541217],
                            [39.68015389, -4.99529742],
                            [39.68033418, -4.99524825],
                            [39.68036696, -4.99547774],
                            [39.68039974, -4.99565806],
                            [39.68036696, -4.99587116],
                            [39.68017027, -4.99596952],
                            [39.68013749, -4.99611705],
                            [39.68013749, -4.99624819],
                            [39.68025223, -4.99626458],
                            [39.68025223, -4.99647769],
                            [39.68026862, -4.9966744],
                            [39.68039974, -4.99680554],
                            [39.68043252, -4.99692028],
                            [39.68059643, -4.99696946],
                            [39.68067838, -4.9971006],
                            [39.68072756, -4.99729731],
                            [39.6806456, -4.99749402],
                            [39.68061282, -4.99773991],
                            [39.68085868, -4.99795301],
                            [39.6811701, -4.99805137],
                            [39.68109285, -4.99819342],
                            [39.6810062, -4.99818251],
                            [39.68097341, -4.99846118],
                            [39.68126845, -4.99854314],
                            [39.68130123, -4.99890378],
                            [39.68107176, -4.99918245],
                            [39.68100619, -4.99955948],
                            [39.68087507, -4.99988733],
                            [39.68051447, -5.00013322],
                            [39.68015388, -5.00055942],
                            [39.67994847, -5.0007907],
                            [39.67975759, -5.00081679],
                            [39.67948186, -5.00085449],
                            [39.67948186, -5.0010348],
                            [39.67967855, -5.00121512],
                            [39.67952551, -5.00138212],
                            [39.67926318, -5.00143087],
                            [39.67892458, -5.00149379],
                            [39.67880984, -5.00167411],
                            [39.67849842, -5.00190361],
                            [39.67849842, -5.00211671],
                            [39.67859676, -5.00231342],
                            [39.67880984, -5.00232981],
                            [39.67910487, -5.0021495],
                            [39.67918683, -5.00231342],
                            [39.679236, -5.00264127],
                            [39.6793999, -5.00267406],
                            [39.67954742, -5.00277241],
                            [39.67956381, -5.00298551],
                            [39.67966215, -5.00316583],
                            [39.67987523, -5.0032314],
                            [39.68013748, -5.0032314],
                            [39.6804653, -5.00326419],
                            [39.68084228, -5.00295273],
                            [39.68102258, -5.00285438],
                            [39.68107175, -5.00285438],
                            [39.6811701, -5.00278881],
                            [39.68138318, -5.00296912],
                            [39.68164543, -5.00296912],
                            [39.68184211, -5.00287077],
                            [39.68182572, -5.00260849],
                            [39.68174377, -5.00226425],
                            [39.68207158, -5.0021495],
                            [39.68215354, -5.00190361],
                            [39.68235023, -5.00151019],
                            [39.68238301, -5.00164133],
                            [39.68233384, -5.00198557],
                            [39.68246496, -5.00203475],
                            [39.68262887, -5.0021495],
                            [39.68282556, -5.00224786],
                            [39.68305502, -5.00246096],
                            [39.68321893, -5.00241178],
                            [39.68330088, -5.00229703],
                            [39.68333367, -5.00229703],
                            [39.68336645, -5.00249374],
                            [39.68348118, -5.00277242],
                            [39.68371065, -5.00288716],
                            [39.68395651, -5.00255931],
                            [39.68387456, -5.00293634],
                            [39.68402207, -5.00318223],
                            [39.68428432, -5.0034609],
                            [39.68434989, -5.00375597],
                            [39.68451379, -5.00387072],
                            [39.6846777, -5.00372318],
                            [39.68497273, -5.00344451],
                            [39.68513664, -5.00321502],
                            [39.68531694, -5.00310027],
                            [39.6855628, -5.00310027],
                            [39.68574309, -5.00318223],
                            [39.68610369, -5.00341173],
                            [39.68623481, -5.0036904],
                            [39.68644789, -5.00375597],
                            [39.68680849, -5.0034773],
                            [39.68687935, -5.00312293],
                            [39.68692322, -5.00290356],
                            [39.68703796, -5.00249375],
                            [39.6871363, -5.00213311],
                            [39.68726743, -5.00178887],
                            [39.6874969, -5.00157577],
                            [39.68780832, -5.00139545],
                            [39.68825087, -5.00139545],
                            [39.68857868, -5.00147741],
                            [39.68875898, -5.00157577],
                            [39.68920153, -5.00162495],
                            [39.6892174, -5.00162495],
                            [39.68956212, -5.00162495],
                            [39.68990633, -5.00149381],
                            [39.69015219, -5.00134628],
                            [39.69025053, -5.00105121],
                            [39.69011941, -5.00072336],
                            [39.68993423, -5.00064399],
                            [39.6897752, -5.00057583],
                            [39.68920153, -5.00024798],
                            [39.68918514, -4.99987095],
                            [39.689431, -4.9995431],
                            [39.68982438, -4.99929721],
                            [39.69019736, -4.99921113],
                            [39.69025053, -4.99919886],
                            [39.69038754, -4.99936356],
                            [39.69018122, -4.99945321],
                            [39.68982204, -4.99960927],
                            [39.689558, -4.99975781],
                            [39.68959101, -5.0000879],
                            [39.68986741, -5.00035571],
                            [39.68999143, -5.00038654],
                            [39.69031712, -5.0004675],
                            [39.6905482, -5.00069849],
                            [39.69043264, -5.00142476],
                            [39.6902346, -5.00167233],
                            [39.68977253, -5.00183738],
                            [39.68940947, -5.0019364],
                            [39.68908627, -5.00199213],
                            [39.6889309, -5.00201892],
                            [39.68860085, -5.00201892],
                            [39.68822129, -5.00188689],
                            [39.68777572, -5.00185388],
                            [39.68765195, -5.00201892],
                            [39.68752818, -5.00218397],
                            [39.68734665, -5.00249755],
                            [39.68749517, -5.00274512],
                            [39.68749517, -5.00317423],
                            [39.68748919, -5.00319617],
                            [39.68739615, -5.00353733],
                            [39.68726413, -5.00388393],
                            [39.6870661, -5.00429654],
                            [39.68683506, -5.00459362],
                            [39.686439, -5.00447809],
                            [39.68617496, -5.00426353],
                            [39.68602644, -5.00376839],
                            [39.6857789, -5.00350432],
                            [39.68556436, -5.0034218],
                            [39.68528382, -5.00365286],
                            [39.68485475, -5.00391693],
                            [39.68462371, -5.00426353],
                            [39.68447519, -5.00437906],
                            [39.68444218, -5.00500623],
                            [39.6845742, -5.00520428],
                            [39.68490426, -5.00515477],
                            [39.68526731, -5.00492371],
                            [39.68549835, -5.00487419],
                            [39.68558086, -5.00518778],
                            [39.68592951, -5.00532046],
                            [39.68629048, -5.00523729],
                            [39.68675255, -5.00561689],
                            [39.68737965, -5.00558389],
                            [39.68761068, -5.00550136],
                            [39.68802325, -5.00583145],
                            [39.68863385, -5.00622756],
                            [39.68954149, -5.00687124],
                            [39.68993755, -5.00772947],
                            [39.68988804, -5.00807607],
                            [39.6902841, -5.00843916],
                            [39.69041612, -5.00860421],
                            [39.69056313, -5.00863795],
                            [39.69063066, -5.00891779],
                            [39.69053164, -5.00911585],
                            [39.69077918, -5.0093139],
                            [39.6909277, -5.00949545],
                            [39.69084519, -5.0096605],
                            [39.69099371, -5.00994107],
                            [39.69115874, -5.01018864],
                            [39.69120825, -5.01048572],
                            [39.69122475, -5.01068377],
                            [39.69137327, -5.01089833],
                            [39.6915713, -5.01104687],
                            [39.69186835, -5.01117891],
                            [39.69211743, -5.01117395],
                            [39.6921819, -5.01103037],
                            [39.69239643, -5.01127794],
                            [39.69257796, -5.01142648],
                            [39.69263227, -5.01142368],
                            [39.69272694, -5.0114188],
                            [39.69267698, -5.01121192],
                            [39.69295752, -5.01139347],
                            [39.69318856, -5.01154201],
                            [39.69330408, -5.01167404],
                            [39.69338659, -5.01182258],
                            [39.69351861, -5.01197113],
                            [39.69383105, -5.01192976],
                            [39.69388167, -5.01207015],
                            [39.69388167, -5.01221869],
                            [39.69391467, -5.01235073],
                            [39.69394768, -5.01253228],
                            [39.69399719, -5.0126313],
                            [39.6941028, -5.01260618],
                            [39.69425376, -5.01257026],
                            [39.69414571, -5.01279635],
                            [39.6940962, -5.01296139],
                            [39.69414571, -5.01307693],
                            [39.69416709, -5.0131197],
                            [39.69426123, -5.01330799],
                            [39.69449226, -5.01348954],
                            [39.6947398, -5.01350604],
                            [39.69483927, -5.01340255],
                            [39.69501069, -5.01322419],
                            [39.69509858, -5.01341098],
                            [39.69496629, -5.01352859],
                            [39.6948729, -5.01358758],
                            [39.69468699, -5.01370501],
                            [39.69455094, -5.01386829],
                            [39.69446649, -5.01396964],
                            [39.6942607, -5.01442538],
                            [39.694246, -5.01480762],
                            [39.69429009, -5.01526336],
                            [39.69434889, -5.01561619],
                            [39.69464289, -5.01573381],
                            [39.69467229, -5.01589552],
                            [39.69449589, -5.01607194],
                            [39.69442239, -5.01616015],
                            [39.69423129, -5.01621895],
                            [39.6942019, -5.01602783],
                            [39.6939667, -5.01605724],
                            [39.6938491, -5.01626306],
                            [39.6936874, -5.01648358],
                            [39.6937756, -5.01663059],
                            [39.6938932, -5.0167188],
                            [39.6938932, -5.01683641],
                            [39.6939226, -5.01718925],
                            [39.6939226, -5.01746857],
                            [39.6939667, -5.01760089],
                            [39.69410603, -5.01771419],
                            [39.69387724, -5.01820995],
                            [39.69376285, -5.01878199],
                            [39.69330527, -5.01950656],
                            [39.69300022, -5.02011673],
                            [39.69320554, -5.02076035],
                            [39.69277143, -5.02145147],
                            [39.69265704, -5.02187096],
                            [39.69261891, -5.02244299],
                            [39.69254264, -5.02293875],
                            [39.69269361, -5.02332072],
                            [39.69258078, -5.023854],
                            [39.69239012, -5.0243879],
                            [39.69239012, -5.02537942],
                            [39.69216133, -5.02591332],
                            [39.69249315, -5.02657673],
                            [39.69269535, -5.02698098],
                            [39.69261125, -5.02723318],
                            [39.69242825, -5.02778196],
                            [39.69315274, -5.02823958],
                            [39.69364844, -5.02869721],
                            [39.69380677, -5.0290188],
                            [39.694088, -5.0291549],
                            [39.69397735, -5.02926557],
                            [39.69388053, -5.0294039],
                            [39.69390819, -5.02954224],
                            [39.6938252, -5.02966674],
                            [39.69370071, -5.0295284],
                            [39.69354856, -5.0294869],
                            [39.69353473, -5.02933473],
                            [39.69350707, -5.02914107],
                            [39.69335492, -5.0290304],
                            [39.69306445, -5.02876756],
                            [39.69293996, -5.02862923],
                            [39.69289847, -5.0284909],
                            [39.6925665, -5.0285739],
                            [39.69237286, -5.02864306],
                            [39.69215155, -5.02879523],
                            [39.69208239, -5.02901656],
                            [39.69206855, -5.02929323],
                            [39.69217921, -5.0294454],
                            [39.69217921, -5.0295699],
                            [39.69224837, -5.0298189],
                            [39.69209622, -5.02983273],
                            [39.69194407, -5.02995723],
                            [39.69194407, -5.0301924],
                            [39.69186108, -5.0303584],
                            [39.6916536, -5.0306074],
                            [39.69141846, -5.0307734],
                            [39.69133547, -5.0309809],
                            [39.69115565, -5.03120224],
                            [39.69125247, -5.03140974],
                            [39.69115565, -5.03154807],
                            [39.69097584, -5.03168641],
                            [39.69097584, -5.03199074],
                            [39.69060238, -5.03186624],
                            [39.69023712, -5.03198476],
                            [39.68969275, -5.03207147],
                            [39.68953241, -5.03209702],
                            [39.68947677, -5.03229103],
                            [39.68939666, -5.0323233],
                            [39.68937726, -5.03223278],
                            [39.68929322, -5.03220046],
                            [39.68907787, -5.03214716],
                            [39.68883606, -5.03218104],
                            [39.68842053, -5.03223925],
                            [39.68793272, -5.03241317],
                            [39.68771098, -5.03245439],
                            [39.6875552, -5.03248335],
                            [39.68735685, -5.03252022],
                            [39.68727723, -5.03253503],
                            [39.68708324, -5.03257109],
                            [39.68692078, -5.03260129],
                            [39.68688846, -5.03270474],
                            [39.68682547, -5.03269697],
                            [39.68676249, -5.03268921],
                            [39.68652645, -5.03273706],
                            [39.68632605, -5.0327112],
                            [39.68627434, -5.03240087],
                            [39.68615152, -5.03194831],
                            [39.68615798, -5.03162505],
                            [39.6862097, -5.03135998],
                            [39.68627434, -5.03104319],
                            [39.68634545, -5.03089449],
                            [39.68637131, -5.03064235],
                            [39.68637131, -5.03047426],
                            [39.68645535, -5.03033849],
                            [39.68650706, -5.03023505],
                            [39.68651999, -5.02987947],
                            [39.68659756, -5.02971784],
                            [39.68673332, -5.02965319],
                            [39.6867915, -5.02948509],
                            [39.68687173, -5.02933128],
                            [39.68680443, -5.02927821],
                            [39.686882, -5.0291489],
                            [39.68707593, -5.02887737],
                            [39.6871729, -5.02873513],
                            [39.68711472, -5.02857351],
                            [39.68700278, -5.02828679],
                            [39.68677857, -5.02803043],
                            [39.68674808, -5.02799569],
                            [39.68641656, -5.02761666],
                            [39.68598345, -5.02735159],
                            [39.68568608, -5.0273128],
                            [39.68522711, -5.02725461],
                            [39.68485217, -5.02728047],
                            [39.68437381, -5.02745503],
                            [39.68399887, -5.02762959],
                            [39.68374029, -5.02785587],
                            [39.68353989, -5.02823085],
                            [39.68337828, -5.02838601],
                            [39.68325546, -5.02857997],
                            [39.68315203, -5.02877392],
                            [39.68297749, -5.02896787],
                            [39.68289992, -5.02913597],
                            [39.68289991, -5.02943336],
                            [39.68282881, -5.02971137],
                            [39.68262194, -5.02976955],
                            [39.68239569, -5.03011867],
                            [39.68221075, -5.03034551],
                            [39.68189146, -5.0302803],
                            [39.68173632, -5.03032555],
                            [39.681704, -5.03042253],
                            [39.681704, -5.03057123],
                            [39.68180743, -5.030707],
                            [39.68198843, -5.03076518],
                            [39.68203368, -5.03095267],
                            [39.682066, -5.03112077],
                            [39.68207225, -5.03133687],
                            [39.68195611, -5.03132765],
                            [39.68171692, -5.03137937],
                            [39.68165888, -5.0315704],
                            [39.68145188, -5.0315604],
                            [39.6813937, -5.03169617],
                            [39.68163935, -5.03179961],
                            [39.68163971, -5.03188516],
                            [39.68159409, -5.03188463],
                            [39.68151006, -5.03188365],
                            [39.68145837, -5.03197425],
                            [39.68138724, -5.03197417],
                            [39.68130966, -5.03191598],
                            [39.68119328, -5.03185958],
                            [39.68112865, -5.03185869],
                            [39.68106402, -5.03185779],
                            [39.6809024, -5.03192244],
                            [39.6809024, -5.03201942],
                            [39.68088301, -5.03218752],
                            [39.68090887, -5.0322457],
                            [39.68095412, -5.03229742],
                            [39.68098634, -5.03233995],
                            [39.68091533, -5.03234268],
                            [39.6808313, -5.03240087],
                            [39.68088301, -5.03243966],
                            [39.68099937, -5.03251077],
                            [39.68095412, -5.03259482],
                            [39.6808898, -5.03273468],
                            [39.68077958, -5.03274352],
                            [39.68076665, -5.03289222],
                            [39.68079897, -5.03303445],
                            [39.68088947, -5.0331185],
                            [39.68102523, -5.03308617],
                            [39.68112219, -5.03314436],
                            [39.68102523, -5.0332478],
                            [39.68089594, -5.03339003],
                            [39.68080544, -5.03330599],
                            [39.68071493, -5.03341589],
                            [39.68067071, -5.03360302],
                            [39.68059211, -5.03361631],
                            [39.68058565, -5.03349348],
                            [39.68048222, -5.03346761],
                            [39.68033353, -5.03358399],
                            [39.68028182, -5.03381027],
                            [39.68028828, -5.03388785],
                            [39.68035939, -5.03394604],
                            [39.68024303, -5.03413999],
                            [39.68028828, -5.03425636],
                            [39.68009435, -5.03446971],
                            [39.67998445, -5.03472185],
                            [39.68005556, -5.03488995],
                            [39.68013313, -5.03507744],
                            [39.68028828, -5.03530372],
                            [39.6804305, -5.03552353],
                            [39.68039171, -5.03563991],
                            [39.68023656, -5.03565284],
                            [39.68011374, -5.03552353],
                            [39.67993439, -5.03524324],
                            [39.67978406, -5.03529079],
                            [39.67965477, -5.03545888],
                            [39.67958366, -5.03560111],
                            [39.67951255, -5.03571102],
                            [39.67953194, -5.03584679],
                            [39.67953642, -5.03588971],
                            [39.67944144, -5.03592437],
                            [39.6794479, -5.03607953],
                            [39.67943498, -5.03624763],
                            [39.67940912, -5.0363834],
                            [39.67947376, -5.03639633],
                            [39.67956426, -5.03642219],
                            [39.67949962, -5.03664847],
                            [39.67932508, -5.03664847],
                            [39.6794479, -5.03682303],
                            [39.67946782, -5.03699967],
                            [39.67930569, -5.03707517],
                            [39.67932294, -5.03722694],
                            [39.67922165, -5.03727559],
                            [39.67918286, -5.0373661],
                            [39.67917392, -5.03745205],
                            [39.67906004, -5.03750833],
                            [39.67900832, -5.0376441],
                            [39.67891136, -5.0376441],
                            [39.67884671, -5.03778633],
                            [39.67889843, -5.03798675],
                            [39.67894368, -5.0382195],
                            [39.67882184, -5.03819755],
                            [39.67855581, -5.03834234],
                            [39.67837481, -5.03852336],
                            [39.67828754, -5.03874964],
                            [39.67806055, -5.03892732],
                            [39.6779603, -5.03912784],
                            [39.67764701, -5.03989233],
                            [39.67733373, -5.04030591],
                            [39.67697032, -5.04073202],
                            [39.67679488, -5.04100774],
                            [39.67657559, -5.0412208],
                            [39.67635629, -5.04143385],
                            [39.67611819, -5.04160931],
                            [39.67601794, -5.04152158],
                            [39.67550416, -5.04112053],
                            [39.6750781, -5.04084481],
                            [39.67452672, -5.04040617],
                            [39.6740756, -5.04006778],
                            [39.67368713, -5.03971687],
                            [39.67336132, -5.03941608],
                            [39.67297285, -5.03920303],
                            [39.67299791, -5.03898997],
                            [39.67293525, -5.03878945],
                            [39.67232122, -5.03852626],
                            [39.67154428, -5.03868919],
                            [39.6710305, -5.03878945],
                            [39.67065456, -5.0390401],
                            [39.67014078, -5.03926569],
                            [39.66984003, -5.03940355],
                            [39.67007812, -5.03964167],
                            [39.67004053, -5.03998005],
                            [39.66989015, -5.04029337],
                            [39.66987762, -5.04059415],
                            [39.66996534, -5.04081974],
                            [39.67020343, -5.04109546],
                            [39.67025803, -5.04115328],
                            [39.67041646, -5.04132105],
                            [39.67024103, -5.04152157],
                            [39.6703914, -5.04180982],
                            [39.67055431, -5.04207301],
                            [39.67070468, -5.04241139],
                            [39.6707924, -5.04278737],
                            [39.67082999, -5.04300043],
                            [39.67105556, -5.04308816],
                            [39.67125606, -5.04315082],
                            [39.67134378, -5.0430255],
                            [39.67140643, -5.04308816],
                            [39.67145656, -5.04338894],
                            [39.6715944, -5.04368973],
                            [39.67161946, -5.04390278],
                            [39.67192021, -5.04399051],
                            [39.67237134, -5.0442913],
                            [39.67324853, -5.04467981],
                            [39.67406306, -5.04460462],
                            [39.67471469, -5.04503073],
                            [39.67547909, -5.04529392],
                            [39.67600541, -5.04544431],
                            [39.67697032, -5.04579523],
                            [39.67757182, -5.04600828],
                            [39.67837382, -5.04642186],
                            [39.67902544, -5.04638427],
                            [39.6792886, -5.04620881],
                            [39.67963948, -5.0463968],
                            [39.68036629, -5.04674772],
                            [39.68119335, -5.04711117],
                            [39.68165701, -5.04718636],
                            [39.68212067, -5.04726156],
                            [39.68254898, -5.04733891],
                            [39.68288911, -5.04743896],
                            [39.68325507, -5.04760788],
                            [39.68340931, -5.04767908],
                            [39.68396953, -5.04789919],
                            [39.68472982, -5.04813931],
                            [39.68529004, -5.04787918],
                            [39.68699069, -5.04825937],
                            [39.6883312, -5.0483194],
                            [39.68961169, -5.04875962],
                            [39.68970523, -5.04878249],
                            [39.69051204, -5.04897973],
                            [39.69111227, -5.04905977],
                            [39.69159245, -5.04923986],
                            [39.69170577, -5.04927687],
                            [39.69257283, -5.04956002],
                            [39.6932731, -5.04984016],
                            [39.69387333, -5.0499202],
                            [39.69423346, -5.05002025],
                            [39.69449356, -5.05000024],
                            [39.69471365, -5.05004027],
                            [39.69489525, -5.05015429],
                            [39.69497375, -5.05046047],
                            [39.69531388, -5.05064056],
                            [39.69567402, -5.05084066],
                            [39.69601415, -5.05100074],
                            [39.69625424, -5.05126087],
                            [39.69627425, -5.05104076],
                            [39.69623423, -5.05042046],
                            [39.6961542, -5.04992021],
                            [39.69623423, -5.0495],
                            [39.69617421, -5.04905978],
                            [39.69617421, -5.04875963],
                            [39.69627425, -5.04839945],
                            [39.69617421, -5.04813932],
                            [39.6962721, -5.0479097],
                            [39.69637429, -5.04817934],
                            [39.69657437, -5.04819935],
                            [39.69683447, -5.04811931],
                            [39.69705455, -5.04793923],
                            [39.6971746, -5.04765909],
                            [39.69709457, -5.04735894],
                            [39.69687448, -5.04701877],
                            [39.69629426, -5.04649851],
                            [39.69625036, -5.04644448],
                            [39.69577406, -5.04585819],
                            [39.69541392, -5.04589821],
                            [39.69539392, -5.04557805],
                            [39.69521385, -5.04517785],
                            [39.6948337, -5.04485769],
                            [39.69485371, -5.04453753],
                            [39.6945536, -5.04395725],
                            [39.69489417, -5.04373733],
                            [39.69493374, -5.04383719],
                            [39.69523386, -5.0436571],
                            [39.6953339, -5.04355705],
                            [39.69547395, -5.04315685],
                            [39.69541393, -5.04283669],
                            [39.69549396, -5.04239647],
                            [39.69563401, -5.04191624],
                            [39.69575789, -5.04188526],
                            [39.69579407, -5.04187622],
                            [39.69601416, -5.04195626],
                            [39.69633428, -5.04199628],
                            [39.69683447, -5.04193625],
                            [39.69721462, -5.04181619],
                            [39.6976748, -5.04167612],
                            [39.69774813, -5.0416718],
                            [39.69801493, -5.04165611],
                            [39.69807495, -5.04193625],
                            [39.69779484, -5.04231644],
                            [39.69783486, -5.04261659],
                            [39.69817499, -5.04255656],
                            [39.69855513, -5.04227642],
                            [39.69877522, -5.04181619],
                            [39.69909534, -5.04127592],
                            [39.69945548, -5.0410358],
                            [39.69958218, -5.04074975],
                            [39.6997756, -5.04067563],
                            [39.69987564, -5.04073566],
                            [39.69963555, -5.04105581],
                            [39.69929542, -5.04133595],
                            [39.6989953, -5.04189623],
                            [39.69853513, -5.04261659],
                            [39.69805494, -5.04277666],
                            [39.69777483, -5.04293674],
                            [39.69739469, -5.04267662],
                            [39.69740076, -5.04254294],
                            [39.6974147, -5.0422364],
                            [39.69735467, -5.04195626],
                            [39.69721462, -5.04195626],
                            [39.69701454, -5.04217637],
                            [39.69681447, -5.04229643],
                            [39.69649434, -5.04229643],
                            [39.69603417, -5.04229642],
                            [39.69591412, -5.04241648],
                            [39.69597414, -5.0426566],
                            [39.69599415, -5.04281668],
                            [39.69592723, -5.04288361],
                            [39.6958541, -5.04295675],
                            [39.69581408, -5.0432569],
                            [39.69565402, -5.04333694],
                            [39.69577406, -5.0438572],
                            [39.69563401, -5.04415735],
                            [39.69529388, -5.04413734],
                            [39.69515383, -5.04427741],
                            [39.69517383, -5.04455755],
                            [39.69529388, -5.04473763],
                            [39.69545394, -5.04495774],
                            [39.69565402, -5.04517785],
                            [39.69583409, -5.04517785],
                            [39.69603416, -5.045478],
                            [39.69623424, -5.04561807],
                            [39.69649434, -5.04579816],
                            [39.69669441, -5.04583818],
                            [39.69693451, -5.04605829],
                            [39.69725463, -5.04623838],
                            [39.69737467, -5.04629841],
                            [39.69775482, -5.04641847],
                            [39.69751473, -5.04657855],
                            [39.6974347, -5.04669861],
                            [39.69761477, -5.0468787],
                            [39.69787487, -5.04685869],
                            [39.69791488, -5.0470788],
                            [39.69789487, -5.04733893],
                            [39.69781484, -5.04763908],
                            [39.69767479, -5.04775914],
                            [39.69779483, -5.04785919],
                            [39.69793489, -5.04781917],
                            [39.69795979, -5.04771229],
                            [39.69801492, -5.0480793],
                            [39.69785486, -5.04819936],
                            [39.69769479, -5.04825939],
                            [39.69767479, -5.04851951],
                            [39.69764244, -5.04858172],
                            [39.69741469, -5.04901976],
                            [39.69721461, -5.04939995],
                            [39.69705455, -5.04976013],
                            [39.69697452, -5.05006028],
                            [39.69717459, -5.05042046],
                            [39.69723462, -5.05054052],
                            [39.69755474, -5.05064057],
                            [39.6979749, -5.05076063],
                            [39.69871518, -5.05126088],
                            [39.69919537, -5.05118084],
                            [39.69935543, -5.05102076],
                            [39.69971557, -5.05098074],
                            [39.69999567, -5.05084067],
                            [39.70021576, -5.05096073],
                            [39.70039583, -5.05116083],
                            [39.70067593, -5.05142096],
                            [39.70195643, -5.05220135],
                            [39.70252893, -5.05199425],
                            [39.70260895, -5.05200176],
                            [39.70268898, -5.05200927],
                            [39.70276127, -5.05208139],
                            [39.70273208, -5.05213245],
                            [39.70271977, -5.05224331],
                            [39.70278135, -5.0523357],
                            [39.70283062, -5.05231106],
                            [39.70286141, -5.05222484],
                            [39.70291683, -5.05215709],
                            [39.70298457, -5.05216941],
                            [39.70301722, -5.05219409],
                            [39.70297669, -5.05226939],
                            [39.70290452, -5.05240345],
                            [39.70295994, -5.05248967],
                            [39.70302768, -5.05258206],
                            [39.70312005, -5.05261285],
                            [39.70324322, -5.05253894],
                            [39.70326785, -5.0524712],
                            [39.7033048, -5.05235418],
                            [39.70341565, -5.05235418],
                            [39.70353266, -5.05237881],
                            [39.70361887, -5.05237881],
                            [39.70375436, -5.05242192],
                            [39.70378515, -5.0525451],
                            [39.70387752, -5.05265596],
                            [39.70391447, -5.05263133],
                            [39.70402532, -5.05256358],
                            [39.70408074, -5.05268676],
                            [39.70415464, -5.05276067],
                            [39.70421622, -5.05283457],
                            [39.70425317, -5.05291464],
                            [39.70435786, -5.05293928],
                            [39.70447487, -5.05295775],
                            [39.70461035, -5.05290848],
                            [39.70467194, -5.05286537],
                            [39.70477663, -5.05280378],
                            [39.70491827, -5.05272987],
                            [39.70504143, -5.05272987],
                            [39.7050907, -5.05288385],
                            [39.70510917, -5.05298855],
                            [39.7051478, -5.05309195],
                            [39.70510121, -5.05325208],
                            [39.70505041, -5.05342666],
                            [39.7050204, -5.05352979],
                            [39.70495867, -5.05374196],
                            [39.70493058, -5.05383848],
                            [39.70504143, -5.05396782],
                            [39.70518307, -5.05401094],
                            [39.70539245, -5.05393087],
                            [39.70544172, -5.05386312],
                            [39.70550946, -5.05373994],
                            [39.70565726, -5.0537461],
                            [39.70576195, -5.0536106],
                            [39.70590974, -5.05333345],
                            [39.70608833, -5.05303166],
                            [39.70618687, -5.05277915],
                            [39.70624845, -5.05247736],
                            [39.70632235, -5.0522618],
                            [39.70648862, -5.05210166],
                            [39.70669185, -5.05194153],
                            [39.70682733, -5.05179987],
                            [39.70701207, -5.05172597],
                            [39.70708597, -5.05159047],
                            [39.70732615, -5.05147961],
                            [39.70749858, -5.05136875],
                            [39.70763406, -5.05139954],
                            [39.70779417, -5.05141802],
                            [39.70786191, -5.05152888],
                            [39.70797892, -5.05157815],
                            [39.70812672, -5.05146729],
                            [39.70827452, -5.05138107],
                            [39.70834226, -5.05128868],
                            [39.70837305, -5.05105464],
                            [39.70842232, -5.05089451],
                            [39.70850419, -5.0509739],
                            [39.70850875, -5.05111048],
                            [39.70851469, -5.05128868],
                            [39.70847476, -5.05146651],
                            [39.70847774, -5.051572],
                            [39.70856833, -5.05170001],
                            [39.70857306, -5.05182556],
                            [39.70857627, -5.05191074],
                            [39.70853932, -5.05181219],
                            [39.70832378, -5.05176908],
                            [39.70817599, -5.0516767],
                            [39.70798508, -5.05162743],
                            [39.70787181, -5.05172019],
                            [39.70786807, -5.05181835],
                            [39.70805898, -5.05193537],
                            [39.70824988, -5.0520216],
                            [39.70843463, -5.05210167],
                            [39.70869944, -5.05208935],
                            [39.70877334, -5.0521263],
                            [39.70908741, -5.05220637],
                            [39.70948769, -5.05224332],
                            [39.70970939, -5.05225564],
                            [39.70995572, -5.05228644],
                            [39.71019589, -5.05221253],
                            [39.71026363, -5.05197849],
                            [39.71014047, -5.05176908],
                            [39.71003578, -5.05141802],
                            [39.70998036, -5.05120862],
                            [39.71001115, -5.05099306],
                            [39.71007889, -5.0507467],
                            [39.71017742, -5.05065431],
                            [39.71031906, -5.05057425],
                            [39.71039296, -5.05023551],
                            [39.71048533, -5.05003842],
                            [39.71051613, -5.04979206],
                            [39.71070699, -5.04977037],
                            [39.71105805, -5.04973047],
                            [39.71150145, -5.04985365],
                            [39.71123048, -5.05000147],
                            [39.71098761, -5.04993027],
                            [39.71083636, -5.05010001],
                            [39.71083447, -5.0501019],
                            [39.71061466, -5.05032173],
                            [39.71051613, -5.05061736],
                            [39.71024516, -5.05103617],
                            [39.71036833, -5.05145498],
                            [39.71054076, -5.05177524],
                            [39.71078709, -5.05207087],
                            [39.71113195, -5.05239114],
                            [39.71093488, -5.0526375],
                            [39.71031906, -5.05258823],
                            [39.71012199, -5.05266213],
                            [39.70948153, -5.05246504],
                            [39.70916131, -5.05256359],
                            [39.70861938, -5.05248968],
                            [39.70812672, -5.05229259],
                            [39.70775722, -5.05229259],
                            [39.70738773, -5.05212014],
                            [39.70726456, -5.0520216],
                            [39.70696897, -5.05239113],
                            [39.70682117, -5.05278531],
                            [39.70682117, -5.05298239],
                            [39.70679653, -5.05335193],
                            [39.70667337, -5.05399246],
                            [39.706698, -5.05438663],
                            [39.70620534, -5.05483008],
                            [39.7061807, -5.05544597],
                            [39.70586048, -5.05532279],
                            [39.70541708, -5.05569233],
                            [39.70494905, -5.05616041],
                            [39.70485052, -5.05633286],
                            [39.70514612, -5.05648068],
                            [39.70541708, -5.05655458],
                            [39.70563878, -5.05657922],
                            [39.70590974, -5.05655458],
                            [39.70597586, -5.05645752],
                            [39.7061144, -5.05625412],
                            [39.70635313, -5.05660386],
                            [39.70664873, -5.05697339],
                            [39.70682116, -5.05719512],
                            [39.70723992, -5.05754002],
                            [39.70763405, -5.05781101],
                            [39.70829813, -5.05798241],
                            [39.70839767, -5.0580081],
                            [39.70889034, -5.0580081],
                            [39.70928446, -5.05790956],
                            [39.70975249, -5.05778638],
                            [39.71026979, -5.05754002],
                            [39.71059002, -5.05677631],
                            [39.71064281, -5.05666015],
                            [39.71083635, -5.05623432],
                            [39.71110731, -5.05564307],
                            [39.71105828, -5.05515779],
                            [39.71152607, -5.05490399],
                            [39.71167387, -5.05460836],
                            [39.71196947, -5.05473154],
                            [39.71219117, -5.05443591],
                            [39.71260993, -5.05441128],
                            [39.71302869, -5.05487936],
                            [39.71307795, -5.05512572],
                            [39.71325039, -5.0553228],
                            [39.71339818, -5.05515035],
                            [39.71365074, -5.05487978],
                            [39.71371525, -5.05494737],
                            [39.71391187, -5.05504569],
                            [39.71389344, -5.05516859],
                            [39.71393645, -5.0552792],
                            [39.71410235, -5.05545126],
                            [39.71424367, -5.05560489],
                            [39.71436041, -5.05565405],
                            [39.71457546, -5.05570936],
                            [39.71452631, -5.05578924],
                            [39.71456317, -5.05593672],
                            [39.71483352, -5.05609035],
                            [39.71468606, -5.05626855],
                            [39.71482738, -5.05648363],
                            [39.71488882, -5.05679703],
                            [39.71501171, -5.05728863],
                            [39.71531278, -5.05749142],
                            [39.7155094, -5.05763275],
                            [39.71600094, -5.05780481],
                            [39.71609486, -5.05802972],
                            [39.71621204, -5.05817448],
                            [39.71658424, -5.05833993],
                            [39.71679103, -5.05834682],
                            [39.71694956, -5.05838129],
                            [39.7170047, -5.05862946],
                            [39.71706674, -5.05895345],
                            [39.71696335, -5.05917405],
                            [39.71667385, -5.05925677],
                            [39.71665317, -5.05951183],
                            [39.71697024, -5.05957387],
                            [39.71719081, -5.05971864],
                            [39.71728571, -5.0598013],
                            [39.71740448, -5.05990476],
                            [39.71745273, -5.06019429],
                            [39.71747239, -5.06019006],
                            [39.71744837, -5.06025849],
                            [39.71741919, -5.06034159],
                            [39.71735821, -5.06051533],
                            [39.71715431, -5.06109619],
                            [39.71723423, -5.06137024],
                            [39.71745117, -5.06156436],
                            [39.71732557, -5.06190693],
                            [39.71745117, -5.0621924],
                            [39.71745394, -5.06219567],
                            [39.71777086, -5.06256923],
                            [39.71805453, -5.0628138],
                            [39.71810197, -5.0628547],
                            [39.71831481, -5.06307191],
                            [39.71866143, -5.06342565],
                            [39.71933507, -5.06408795],
                            [39.7198717, -5.06447619],
                            [39.72019139, -5.06449903],
                            [39.72031699, -5.06473883],
                            [39.72046542, -5.06497862],
                            [39.72067772, -5.06501152],
                            [39.72087426, -5.06520022],
                            [39.72116358, -5.06537312],
                            [39.72070208, -5.0659952],
                            [39.72062182, -5.06653701],
                            [39.72047133, -5.06704873],
                            [39.7204011, -5.06760058],
                            [39.72044123, -5.06794172],
                            [39.72059172, -5.0682628],
                            [39.72088266, -5.0685036],
                            [39.72089657, -5.06851021],
                            [39.72128396, -5.06869424],
                            [39.72168526, -5.06863404],
                            [39.72194611, -5.06842334],
                            [39.72217685, -5.06809223],
                            [39.72245776, -5.06785142],
                            [39.72279887, -5.06779122],
                            [39.72317007, -5.06772098],
                            [39.7235814, -5.06764072],
                            [39.72380212, -5.06761062],
                            [39.72410309, -5.06747015],
                            [39.72429371, -5.06738988],
                            [39.72445423, -5.06733971],
                            [39.7247552, -5.06745008],
                            [39.72502608, -5.06743001],
                            [39.72525683, -5.06730961],
                            [39.72545748, -5.06702867],
                            [39.72549499, -5.06700616],
                            [39.72565813, -5.06690827],
                            [39.72584874, -5.06687817],
                            [39.72599695, -5.0669711],
                            [39.72604051, -5.06702556],
                            [39.72613637, -5.06714541],
                            [39.72631066, -5.06724999],
                            [39.72648494, -5.06709312],
                            [39.72660694, -5.06705826],
                            [39.72671151, -5.06716284],
                            [39.72683646, -5.06729759],
                            [39.72692364, -5.06731213],
                            [39.72697915, -5.06732138],
                            [39.72710994, -5.06725003],
                            [39.72716939, -5.06735705],
                            [39.72727641, -5.0674403],
                            [39.7274072, -5.06740462],
                            [39.7274072, -5.06759489],
                            [39.72744659, -5.06771306],
                            [39.72745476, -5.06773759],
                            [39.7275261, -5.06775543],
                            [39.72759745, -5.06777326],
                            [39.72774013, -5.06776137],
                            [39.72791849, -5.06783272],
                            [39.72803739, -5.06784461],
                            [39.72816818, -5.06774948],
                            [39.72822764, -5.06789218],
                            [39.72834654, -5.06793975],
                            [39.72848922, -5.06796353],
                            [39.72862002, -5.06795164],
                            [39.72878648, -5.06790407],
                            [39.7287895, -5.06780682],
                            [39.72891728, -5.06773759],
                            [39.72891728, -5.06782083],
                            [39.7290124, -5.06793975],
                            [39.72902429, -5.06802299],
                            [39.72894106, -5.06824894],
                            [39.72895295, -5.0684392],
                            [39.72908374, -5.06865325],
                            [39.72916698, -5.06874839],
                            [39.72937612, -5.06897635],
                            [39.72946384, -5.06905312],
                            [39.729568, -5.0690915],
                            [39.72964476, -5.06915181],
                            [39.72967217, -5.06920116],
                            [39.72972699, -5.06921761],
                            [39.7297873, -5.06923406],
                            [39.72985309, -5.06932179],
                            [39.72992436, -5.06933275],
                            [39.72996274, -5.06942596],
                            [39.73001208, -5.06951369],
                            [39.73008883, -5.06962884],
                            [39.73016559, -5.06968367],
                            [39.73031361, -5.06979333],
                            [39.73043423, -5.06996879],
                            [39.73057129, -5.07004555],
                            [39.73067546, -5.07008393],
                            [39.73070835, -5.07013876],
                            [39.73075221, -5.07022649],
                            [39.73082348, -5.07024842],
                            [39.7309112, -5.07026487],
                            [39.7310044, -5.07023746],
                            [39.73110857, -5.07017166],
                            [39.7313169, -5.07011135],
                            [39.7316294, -5.0699962],
                            [39.73184322, -5.06990848],
                            [39.73210637, -5.06987558],
                            [39.73228181, -5.0698043],
                            [39.73242984, -5.06969464],
                            [39.73256142, -5.06968367],
                            [39.73266498, -5.06967344],
                            [39.73263273, -5.07001208],
                            [39.73284234, -5.07033459],
                            [39.73303583, -5.07049585],
                            [39.73313257, -5.07067323],
                            [39.73303582, -5.07080223],
                            [39.73293908, -5.07067323],
                            [39.7327456, -5.07049585],
                            [39.73251987, -5.07025396],
                            [39.73227801, -5.07010883],
                            [39.73203616, -5.07022171],
                            [39.73172981, -5.07036684],
                            [39.73163306, -5.07051197],
                            [39.73146058, -5.07053845],
                            [39.73135396, -5.07061461],
                            [39.73130446, -5.07069078],
                            [39.73132731, -5.07086977],
                            [39.73143393, -5.07099925],
                            [39.73151389, -5.07098782],
                            [39.7315672, -5.07095736],
                            [39.73158243, -5.07101067],
                            [39.73165478, -5.07106018],
                            [39.7316662, -5.07108303],
                            [39.73167001, -5.07110588],
                            [39.73168524, -5.07118204],
                            [39.73173093, -5.07125059],
                            [39.73179948, -5.07131914],
                            [39.73189848, -5.0713915],
                            [39.73198606, -5.07145624],
                            [39.73208125, -5.07150193],
                            [39.73211552, -5.07152098],
                            [39.73206983, -5.07154002],
                            [39.73201271, -5.0715781],
                            [39.73197844, -5.07165807],
                            [39.73192513, -5.07167711],
                            [39.7319594, -5.07173043],
                            [39.73189467, -5.07174566],
                            [39.73181851, -5.07173805],
                            [39.7317119, -5.07168092],
                            [39.73161289, -5.07165426],
                            [39.73155958, -5.07173424],
                            [39.73157101, -5.07182563],
                            [39.73163955, -5.07182183],
                            [39.73167762, -5.07191322],
                            [39.73171951, -5.07199701],
                            [39.73173474, -5.07209221],
                            [39.73181471, -5.07212649],
                            [39.73182232, -5.07223692],
                            [39.73192132, -5.07233975],
                            [39.73197463, -5.07245019],
                            [39.73211171, -5.07257967],
                            [39.73219168, -5.07267868],
                            [39.7324468, -5.07288813],
                            [39.73275143, -5.0731471],
                            [39.73302559, -5.07326896],
                            [39.73345206, -5.07326896],
                            [39.7336653, -5.07320803],
                            [39.73352822, -5.07304047],
                            [39.73354181, -5.07296544],
                            [39.73381761, -5.07313186],
                            [39.73406131, -5.0731928],
                            [39.73425932, -5.0731928],
                            [39.73460964, -5.07320803],
                            [39.73485334, -5.07325373],
                            [39.73486598, -5.07309993],
                            [39.73487676, -5.07296873],
                            [39.73500565, -5.0729186],
                            [39.73502704, -5.07262518],
                            [39.73514273, -5.07259871],
                            [39.73521889, -5.07265964],
                            [39.73520366, -5.07282721],
                            [39.73535597, -5.07294907],
                            [39.73547782, -5.07299477],
                            [39.73523412, -5.0731014],
                            [39.7351275, -5.0731471],
                            [39.73517319, -5.07336036],
                            [39.73526458, -5.07351269],
                            [39.73550828, -5.07372595],
                            [39.7356149, -5.07381735],
                            [39.73593476, -5.07409154],
                            [39.73611753, -5.07419817],
                            [39.73631554, -5.07427434],
                            [39.73649831, -5.0743962],
                            [39.73665906, -5.0744736],
                            [39.73677402, -5.07461014],
                            [39.73686743, -5.07467482],
                            [39.7368818, -5.07467482],
                            [39.73703269, -5.07469638],
                            [39.7371178, -5.07473582],
                            [39.73732729, -5.07483291],
                            [39.73754285, -5.07496945],
                            [39.73769374, -5.07506287],
                            [39.73785901, -5.07510599],
                            [39.73816079, -5.07512036],
                            [39.73824701, -5.07507724],
                            [39.73841946, -5.07508443],
                            [39.7385488, -5.07507006],
                            [39.73870688, -5.07506287],
                            [39.73881466, -5.07504131],
                            [39.73875717, -5.0751491],
                            [39.73871406, -5.07529283],
                            [39.73875717, -5.07541499],
                            [39.7388035, -5.07546132],
                            [39.73882903, -5.07548685],
                            [39.73888651, -5.07542218],
                            [39.73888953, -5.07540701],
                            [39.73895118, -5.07548685],
                            [39.7389871, -5.0755659],
                            [39.73903022, -5.07565214],
                            [39.73901584, -5.07571681],
                            [39.73910925, -5.07567369],
                            [39.73918111, -5.07573118],
                            [39.7392314, -5.0758246],
                            [39.73926733, -5.07589646],
                            [39.73946852, -5.07596114],
                            [39.73948289, -5.07625577],
                            [39.73956193, -5.07647854],
                            [39.73959786, -5.07667257],
                            [39.73969126, -5.07690971],
                            [39.73959786, -5.07697439],
                            [39.73956911, -5.07716123],
                            [39.73997868, -5.07881404],
                            [39.7400649, -5.07885716],
                            [39.73909814, -5.07886922],
                            [39.73886577, -5.07895637],
                            [39.73866244, -5.0796245],
                            [39.73811057, -5.08023454],
                            [39.73776202, -5.08061218],
                            [39.7376735, -5.08064863],
                            [39.73771384, -5.08059298],
                            [39.73791005, -5.0803019],
                            [39.73826305, -5.07977826],
                            [39.73842326, -5.07926383],
                            [39.73847386, -5.07847954],
                            [39.73841483, -5.07763621],
                            [39.73828835, -5.07739165],
                            [39.73819559, -5.07696999],
                            [39.73800165, -5.07648086],
                            [39.7378836, -5.07615196],
                            [39.73782421, -5.07607277],
                            [39.73760533, -5.0757809],
                            [39.7373102, -5.07558693],
                            [39.73700664, -5.07554476],
                            [39.73661876, -5.07551946],
                            [39.73645854, -5.07546043],
                            [39.7362899, -5.0754773],
                            [39.73592731, -5.0755026],
                            [39.7356912, -5.07559536],
                            [39.73551412, -5.07565439],
                            [39.73532861, -5.07563753],
                            [39.7351431, -5.07578089],
                            [39.73499132, -5.07589896],
                            [39.73496602, -5.07581463],
                            [39.73479738, -5.07561223],
                            [39.73448538, -5.07551946],
                            [39.73412279, -5.07543513],
                            [39.73394572, -5.07537609],
                            [39.73387826, -5.07528333],
                            [39.73365902, -5.07521586],
                            [39.73349037, -5.07531706],
                            [39.733288, -5.07546043],
                            [39.73284375, -5.07499637],
                            [39.73234886, -5.07440862],
                            [39.73180758, -5.07406835],
                            [39.73177071, -5.0740356],
                            [39.73173384, -5.07400286],
                            [39.7316601, -5.07393736],
                            [39.73110006, -5.07384892],
                            [39.73071688, -5.07393736],
                            [39.7303988, -5.07379647],
                            [39.73062845, -5.07343622],
                            [39.73042212, -5.07311195],
                            [39.72989156, -5.07293508],
                            [39.72968063, -5.07263448],
                            [39.72940163, -5.07219732],
                            [39.72929003, -5.072002],
                            [39.72921563, -5.07175086],
                            [39.72903892, -5.07158344],
                            [39.72877852, -5.07149973],
                            [39.72854601, -5.07154623],
                            [39.72835071, -5.07162064],
                            [39.7282112, -5.07167645],
                            [39.72778339, -5.07192758],
                            [39.72749509, -5.07211361],
                            [39.72732768, -5.07225313],
                            [39.72716958, -5.07234614],
                            [39.72695568, -5.07235544],
                            [39.72656507, -5.07238334],
                            [39.72632326, -5.07249496],
                            [39.72610006, -5.07249496],
                            [39.72582105, -5.07253216],
                            [39.72561645, -5.07250426],
                            [39.72540254, -5.07258797],
                            [39.72519794, -5.07257867],
                            [39.72499333, -5.07260657],
                            [39.72478873, -5.07254146],
                            [39.72459343, -5.07256937],
                            [39.72443532, -5.07242985],
                            [39.72411912, -5.07241124],
                            [39.72385871, -5.07248565],
                            [39.72380291, -5.07242985],
                            [39.7235332, -5.07224382],
                            [39.7232914, -5.07214151],
                            [39.72302169, -5.0721043],
                            [39.72270549, -5.0721322],
                            [39.72254738, -5.0721136],
                            [39.72239858, -5.07202989],
                            [39.72221258, -5.07205779],
                            [39.72202657, -5.07215081],
                            [39.72192427, -5.0720764],
                            [39.72168247, -5.07195548],
                            [39.72141216, -5.07202138],
                            [39.72117173, -5.07203797],
                            [39.72076549, -5.07202138],
                            [39.72044216, -5.07203797],
                            [39.72027635, -5.07194676],
                            [39.72015199, -5.07198822],
                            [39.72006079, -5.07206284],
                            [39.71988669, -5.07211259],
                            [39.71970429, -5.07207942],
                            [39.71953848, -5.07202967],
                            [39.71934779, -5.07195505],
                            [39.71926489, -5.07197992],
                            [39.71903275, -5.07198821],
                            [39.71904104, -5.07206284],
                            [39.7188089, -5.07207942],
                            [39.71864309, -5.07206284],
                            [39.71834463, -5.0719965],
                            [39.71797155, -5.07186384],
                            [39.71776428, -5.0718058],
                            [39.7174955, -5.07160378],
                            [39.71722076, -5.07145724],
                            [39.71701929, -5.07132901],
                            [39.71692771, -5.0712191],
                            [39.71678118, -5.07120078],
                            [39.71663465, -5.07120078],
                            [39.71645149, -5.07127405],
                            [39.71634159, -5.07145723],
                            [39.71643317, -5.07169537],
                            [39.71645149, -5.07224491],
                            [39.71628664, -5.07281277],
                            [39.71599359, -5.0732524],
                            [39.71581043, -5.07361876],
                            [39.71582874, -5.07380195],
                            [39.7160119, -5.07409503],
                            [39.71584706, -5.07436981],
                            [39.71535253, -5.07431485],
                            [39.7147481, -5.07438812],
                            [39.71440595, -5.07441779],
                            [39.71424791, -5.07431721],
                            [39.71394621, -5.0740011],
                            [39.71370197, -5.07385741],
                            [39.71335716, -5.07364188],
                            [39.71314165, -5.07357004],
                            [39.71285431, -5.07355567],
                            [39.71266754, -5.07364188],
                            [39.71251669, -5.07365625],
                            [39.7123802, -5.07356285],
                            [39.71218625, -5.07359878],
                            [39.71206413, -5.0736347],
                            [39.71197074, -5.07372091],
                            [39.71170495, -5.07374246],
                            [39.7113817, -5.07380712],
                            [39.71114464, -5.07386459],
                            [39.71097224, -5.07395081],
                            [39.71084293, -5.07401546],
                            [39.7107711, -5.07409449],
                            [39.71071363, -5.07410168],
                            [39.71064898, -5.07423818],
                            [39.71062743, -5.07442497],
                            [39.71054122, -5.07439623],
                            [39.71045502, -5.07429565],
                            [39.71034727, -5.07428847],
                            [39.71021797, -5.07433876],
                            [39.70988753, -5.07436031],
                            [39.70971512, -5.07434594],
                            [39.7095902, -5.07431556],
                            [39.70944635, -5.07437885],
                            [39.70931977, -5.0744997],
                            [39.70911839, -5.07446517],
                            [39.7089918, -5.0743731],
                            [39.70885946, -5.07426376],
                            [39.70880768, -5.07419471],
                            [39.70883645, -5.07403934],
                            [39.70870987, -5.07390699],
                            [39.7085315, -5.0737919],
                            [39.70839341, -5.07369983],
                            [39.70841835, -5.07355124],
                            [39.70852244, -5.0733555],
                            [39.70861781, -5.07317617],
                            [39.7087559, -5.07300929],
                            [39.70888824, -5.07287694],
                            [39.70911263, -5.07262375],
                            [39.70925648, -5.07248564],
                            [39.70940032, -5.07237055],
                            [39.70954626, -5.07234882],
                            [39.70960746, -5.07224971],
                            [39.70962023, -5.07212717],
                            [39.70969952, -5.07199651],
                            [39.70978008, -5.07199076],
                            [39.7099642, -5.07180086],
                            [39.71025764, -5.0717203],
                            [39.71049355, -5.07169728],
                            [39.71071795, -5.07170879],
                            [39.71107424, -5.07168065],
                            [39.71120702, -5.07151314],
                            [39.71136237, -5.07144409],
                            [39.71157764, -5.07146155],
                            [39.71167125, -5.07135048],
                            [39.71177665, -5.07122542],
                            [39.71179391, -5.0709377],
                            [39.71169609, -5.07070752],
                            [39.71143142, -5.07031046],
                            [39.71091933, -5.06995368],
                            [39.71051657, -5.06955662],
                            [39.71028066, -5.0692689],
                            [39.71008503, -5.06900995],
                            [39.71007928, -5.06871072],
                            [39.71005626, -5.06840573],
                            [39.70977433, -5.06810075],
                            [39.70972254, -5.06780152],
                            [39.70958445, -5.06742172],
                            [39.70942335, -5.06719154],
                            [39.70907237, -5.0670707],
                            [39.70877892, -5.06690957],
                            [39.70845671, -5.06699589],
                            [39.70816902, -5.06723182],
                            [39.70799065, -5.0673987],
                            [39.70801942, -5.0675886],
                            [39.70795613, -5.06775548],
                            [39.70777776, -5.06788208],
                            [39.70744404, -5.06816404],
                            [39.70721964, -5.06834819],
                            [39.70699524, -5.06860714],
                            [39.70690745, -5.0686884],
                            [39.70681967, -5.06876967],
                            [39.70664957, -5.06889726],
                            [39.70656451, -5.06916662],
                            [39.70659286, -5.06950687],
                            [39.70650781, -5.06962028],
                            [39.70633771, -5.06976205],
                            [39.70609673, -5.07018736],
                            [39.7058841, -5.0705276],
                            [39.70578487, -5.07074026],
                            [39.70565729, -5.07117974],
                            [39.70564312, -5.07157669],
                            [39.70562894, -5.07188858],
                            [39.7056845, -5.07236648],
                            [39.70543181, -5.07243193],
                            [39.7052408, -5.07246564],
                            [39.70492621, -5.07264543],
                            [39.70464532, -5.07257801],
                            [39.70443185, -5.07255553],
                            [39.70420714, -5.0725443],
                            [39.70426332, -5.07201617],
                            [39.70434197, -5.07126331],
                            [39.70403861, -5.07083631],
                            [39.70336448, -5.07018458],
                            [39.70281395, -5.06958903],
                            [39.70245441, -5.06907214],
                            [39.70180276, -5.0686002],
                            [39.70118481, -5.06833052],
                            [39.70087021, -5.06799342],
                            [39.70038709, -5.06747652],
                            [39.70019609, -5.06727426],
                            [39.70018485, -5.06730797],
                            [39.70003879, -5.06736416],
                            [39.69985902, -5.06756642],
                            [39.69971296, -5.06788105],
                            [39.69963431, -5.06861143],
                            [39.69970172, -5.06875751],
                            [39.69971296, -5.06912832],
                            [39.69974666, -5.06954408],
                            [39.69988149, -5.06975758],
                            [39.70018485, -5.07009468],
                            [39.69988149, -5.07028571],
                            [39.69990396, -5.07074641],
                            [39.6996006, -5.07106104],
                            [39.69951072, -5.07143185],
                            [39.69963431, -5.07189256],
                            [39.69972419, -5.07211729],
                            [39.69962307, -5.07265666],
                            [39.69929724, -5.07326344],
                            [39.69926353, -5.07374662],
                            [39.69924106, -5.07394888],
                            [39.69920735, -5.07419609],
                            [39.69905006, -5.0742298],
                            [39.69907253, -5.07452195],
                            [39.699286, -5.07481411],
                            [39.69960059, -5.07517369],
                            [39.69985901, -5.07551079],
                            [39.69987025, -5.07571305],
                            [39.69985901, -5.07603892],
                            [39.69984553, -5.07646346],
                            [39.69971059, -5.07688909],
                            [39.69957564, -5.07731472],
                            [39.69895284, -5.0783736],
                            [39.69849612, -5.07899647],
                            [39.69810168, -5.07957782],
                            [39.69806015, -5.07972316],
                            [39.69808091, -5.07999307],
                            [39.69785255, -5.07972316],
                            [39.69747983, -5.07995846],
                            [39.69722975, -5.07995154],
                            [39.69685607, -5.07972315],
                            [39.69677303, -5.07941172],
                            [39.69637859, -5.07907952],
                            [39.69561047, -5.07845665],
                            [39.69507071, -5.07791682],
                            [39.69519527, -5.07746005],
                            [39.69513299, -5.07694099],
                            [39.69496691, -5.07665031],
                            [39.69463475, -5.0767126],
                            [39.69419878, -5.07733547],
                            [39.6938251, -5.07793758],
                            [39.69374206, -5.07826978],
                            [39.69378358, -5.07891342],
                            [39.6936175, -5.07937019],
                            [39.6933061, -5.08015916],
                            [39.69316077, -5.08092737],
                            [39.69324381, -5.08138414],
                            [39.69397042, -5.08221464],
                            [39.69451018, -5.0825676],
                            [39.69525754, -5.08350191],
                            [39.6954859, -5.0838964],
                            [39.6956935, -5.08435317],
                            [39.69585958, -5.08483071],
                            [39.69619174, -5.08530824],
                            [39.69612946, -5.0859934],
                            [39.69633706, -5.08653323],
                            [39.69522234, -5.08936586],
                            [39.69481871, -5.09051141],
                            [39.69436054, -5.09111146],
                            [39.69404419, -5.09160241],
                            [39.69392419, -5.09199517],
                            [39.69338378, -5.09275998],
                            [39.69294937, -5.0932565],
                            [39.69268045, -5.09379439],
                            [39.6921633, -5.09406334],
                            [39.69123243, -5.09445642],
                            [39.69034293, -5.09455986],
                            [39.68972235, -5.09493225],
                            [39.68908109, -5.0960701],
                            [39.68843982, -5.096608],
                            [39.68736415, -5.09760104],
                            [39.68643328, -5.09811824],
                            [39.68572995, -5.09863545],
                            [39.68533692, -5.09919403],
                            [39.68490251, -5.10000088],
                            [39.68463359, -5.1006629],
                            [39.68459222, -5.10124218],
                            [39.68465428, -5.10149044],
                            [39.68558514, -5.10161457],
                            [39.68606092, -5.10169732],
                            [39.68624004, -5.10219245],
                            [39.68747281, -5.10346716],
                            [39.68789069, -5.10338357],
                            [39.68819366, -5.10337312],
                            [39.68849663, -5.10336267],
                            [39.68866379, -5.10363433],
                            [39.68910257, -5.10451199],
                            [39.68958314, -5.10541055],
                            [39.68968761, -5.1063927],
                            [39.69000102, -5.10747933],
                            [39.69037712, -5.1082943],
                            [39.69096216, -5.10875403],
                            [39.69158899, -5.10913018],
                            [39.69209046, -5.10915107],
                            [39.69285539, -5.1095],
                            [39.69275908, -5.10952721],
                            [39.69242477, -5.11002874],
                            [39.69252924, -5.11065564],
                            [39.69252924, -5.11142882],
                            [39.69254416, -5.111889],
                            [39.69281739, -5.11229889],
                            [39.69359154, -5.11255697],
                            [39.69433533, -5.11278469],
                            [39.69567112, -5.11298204],
                            [39.6962783, -5.11311868],
                            [39.69668815, -5.11298205],
                            [39.69702516, -5.1130752],
                            [39.69869996, -5.11253545],
                            [39.69983559, -5.11390479],
                            [39.69996233, -5.11399257],
                            [39.69968083, -5.1142229],
                            [39.69932616, -5.11467521],
                            [39.69509931, -5.11507967],
                            [39.69431656, -5.11515795],
                            [39.69370341, -5.11547108],
                            [39.6934164, -5.11591469],
                            [39.69317281, -5.11617122],
                            [39.69294963, -5.1162399],
                            [39.69288097, -5.11646309],
                            [39.69270929, -5.11680648],
                            [39.69214277, -5.11658328],
                            [39.69181659, -5.11672063],
                            [39.69149042, -5.11685798],
                            [39.69071789, -5.11773361],
                            [39.69040888, -5.11797398],
                            [39.68967068, -5.11819718],
                            [39.68846157, -5.11862484],
                            [39.68826297, -5.11869508],
                            [39.68733594, -5.11921016],
                            [39.68726787, -5.11923948],
                            [39.68634887, -5.1196354],
                            [39.68622006, -5.11969089],
                            [39.68532737, -5.11989692],
                            [39.68464068, -5.11981108],
                            [39.68391965, -5.11986258],
                            [39.68378634, -5.11981687],
                            [39.6833188, -5.11965655],
                            [39.68307846, -5.11941618],
                            [39.68290678, -5.11931317],
                            [39.68276944, -5.11939901],
                            [39.68270077, -5.11977673],
                            [39.68292395, -5.12034332],
                            [39.68311279, -5.12075538],
                            [39.68312995, -5.12104726],
                            [39.68276944, -5.1215795],
                            [39.68266644, -5.12214608],
                            [39.68282094, -5.12274701],
                            [39.68326729, -5.12315907],
                            [39.68331879, -5.12348528],
                            [39.68366214, -5.12396602],
                            [39.68402265, -5.12415488],
                            [39.68443466, -5.12413771],
                            [39.68486384, -5.12487599],
                            [39.68534453, -5.12539106],
                            [39.6856192, -5.12551125],
                            [39.68599688, -5.12544257],
                            [39.68601405, -5.1258203],
                            [39.68616855, -5.12636971],
                            [39.68649473, -5.1269878],
                            [39.6867694, -5.12755438],
                            [39.68682091, -5.12791494],
                            [39.68692391, -5.12820681],
                            [39.68647756, -5.12861887],
                            [39.68609988, -5.12930564],
                            [39.68563636, -5.12985506],
                            [39.68527585, -5.13047315],
                            [39.6847265, -5.13110841],
                            [39.68464066, -5.1320012],
                            [39.68476083, -5.13258496],
                            [39.68508701, -5.13327172],
                            [39.68543035, -5.1337868],
                            [39.68579086, -5.13401],
                            [39.6858563, -5.13384727],
                            [39.68602168, -5.13343604],
                            [39.68671789, -5.13347776],
                            [39.68730158, -5.13349493],
                            [39.6879196, -5.13371813],
                            [39.68843462, -5.13397567],
                            [39.6888638, -5.13407868],
                            [39.68929298, -5.1341817],
                            [39.69037452, -5.13443924],
                            [39.69147322, -5.13476545],
                            [39.69224575, -5.13538354],
                            [39.69253759, -5.13521185],
                            [39.69284661, -5.13449075],
                            [39.69317278, -5.13392416],
                            [39.69399681, -5.13330607],
                            [39.69464917, -5.13328891],
                            [39.69519852, -5.13294552],
                            [39.69591955, -5.13275666],
                            [39.69617706, -5.1325678],
                            [39.69611862, -5.13230218],
                            [39.69616634, -5.1317109],
                            [39.69659596, -5.13151039],
                            [39.69689792, -5.13151039],
                            [39.69681769, -5.13195753],
                            [39.69695515, -5.13283735],
                            [39.69714758, -5.13319478],
                            [39.69670772, -5.13346972],
                            [39.69607542, -5.13396462],
                            [39.69525068, -5.13432205],
                            [39.69500326, -5.134542],
                            [39.69459089, -5.13495442],
                            [39.69415103, -5.13511938],
                            [39.69404107, -5.13583424],
                            [39.69398608, -5.13646661],
                            [39.69459089, -5.13737392],
                            [39.69544312, -5.13811627],
                            [39.69599294, -5.13828124],
                            [39.69728503, -5.13866616],
                            [39.69846716, -5.13916106],
                            [39.69975925, -5.13965596],
                            [39.70047402, -5.1399859],
                            [39.70107883, -5.1404533],
                            [39.70160116, -5.1409757],
                            [39.70206851, -5.14111317],
                            [39.7021235, -5.14136062],
                            [39.70231593, -5.14226793],
                            [39.70256335, -5.14259787],
                            [39.70322314, -5.1429553],
                            [39.70358053, -5.14364266],
                            [39.70393792, -5.14383512],
                            [39.7042953, -5.144385],
                            [39.70446025, -5.14482491],
                            [39.70512004, -5.14531981],
                            [39.70564237, -5.14559476],
                            [39.70619077, -5.14583745],
                            [39.70728139, -5.14682361],
                            [39.7083272, -5.14751093],
                            [39.70911903, -5.14819826],
                            [39.70934313, -5.14854192],
                            [39.70950747, -5.1488557],
                            [39.70995567, -5.14899017],
                            [39.71085208, -5.14831779],
                            [39.71138992, -5.14804884],
                            [39.71167379, -5.14776495],
                            [39.71189789, -5.14754082],
                            [39.71188295, -5.1473167],
                            [39.71195765, -5.14697303],
                            [39.71224151, -5.14689833],
                            [39.71224151, -5.14718222],
                            [39.71225645, -5.14751094],
                            [39.71261501, -5.14763047],
                            [39.71307816, -5.14786954],
                            [39.7133919, -5.14788449],
                            [39.71358612, -5.14757071],
                            [39.71381022, -5.14740635],
                            [39.71427337, -5.14760059],
                            [39.71458711, -5.14772013],
                            [39.71447299, -5.14837201],
                            [39.71462048, -5.14877241],
                            [39.71474691, -5.14896207],
                            [39.71494708, -5.14943621],
                            [39.71515779, -5.14967856],
                            [39.7154001, -5.14989983],
                            [39.71534743, -5.15017378],
                            [39.71529475, -5.15053202],
                            [39.715221, -5.1507849],
                            [39.71537903, -5.15094295],
                            [39.71569509, -5.15097456],
                            [39.71606383, -5.15084812],
                            [39.71609544, -5.15123798],
                            [39.71624294, -5.15170159],
                            [39.7163799, -5.15227057],
                            [39.71624293, -5.15256559],
                            [39.71599008, -5.15382998],
                            [39.71584259, -5.15405125],
                            [39.71567402, -5.15413555],
                            [39.71549492, -5.15479935],
                            [39.71534742, -5.15532618],
                            [39.71536308, -5.15571592],
                            [39.71516832, -5.15574765],
                            [39.71505243, -5.15601106],
                            [39.71482065, -5.15695936],
                            [39.71464154, -5.1574019],
                            [39.7144519, -5.15761263],
                            [39.71433601, -5.15810785],
                            [39.71425173, -5.15846609],
                            [39.71415691, -5.15864522],
                            [39.71418852, -5.15885595],
                            [39.71417798, -5.15909829],
                            [39.71410423, -5.15930902],
                            [39.71414637, -5.15955136],
                            [39.71420959, -5.15970941],
                            [39.71430441, -5.15983585],
                            [39.71431494, -5.16018356],
                            [39.71434293, -5.16042795],
                            [39.7141253, -5.16052073],
                            [39.71375656, -5.16054181],
                            [39.71355639, -5.16073147],
                            [39.71351425, -5.16091059],
                            [39.71354585, -5.1612583],
                            [39.71357746, -5.16146903],
                            [39.71360906, -5.16176405],
                            [39.71369335, -5.16204854],
                            [39.71350371, -5.16230142],
                            [39.71356692, -5.1625543],
                            [39.71349317, -5.1627545],
                            [39.71338782, -5.16296523],
                            [39.71336675, -5.16329186],
                            [39.71321925, -5.16360796],
                            [39.71294533, -5.16389245],
                            [39.71277676, -5.16434552],
                            [39.71251338, -5.16461948],
                            [39.71248912, -5.16479736],
                            [39.71248177, -5.16485128],
                            [39.71240537, -5.16499453],
                            [39.71222892, -5.16510416],
                            [39.71218678, -5.16533597],
                            [39.71188225, -5.16540501],
                            [39.71163893, -5.16555723],
                            [39.71128637, -5.16594102],
                            [39.71114377, -5.16603138],
                            [39.71101734, -5.16628426],
                            [39.71065957, -5.16620922],
                            [39.71023772, -5.16647392],
                            [39.71000594, -5.16679002],
                            [39.70982684, -5.16717987],
                            [39.70975317, -5.16737463],
                            [39.70961613, -5.16746436],
                            [39.70957399, -5.16763294],
                            [39.70970041, -5.16788582],
                            [39.70965827, -5.16808602],
                            [39.70955437, -5.16853942],
                            [39.70954238, -5.16859178],
                            [39.70942649, -5.16887626],
                            [39.70947916, -5.16927666],
                            [39.7096372, -5.16957168],
                            [39.70961612, -5.16981402],
                            [39.70973201, -5.17005637],
                            [39.70995326, -5.17042515],
                            [39.70979523, -5.17087822],
                            [39.70977415, -5.17114164],
                            [39.70982683, -5.17152096],
                            [39.70983737, -5.17179491],
                            [39.70987951, -5.17196349],
                            [39.70995325, -5.17215315],
                            [39.71010075, -5.17224798],
                            [39.71038521, -5.17235335],
                            [39.71072234, -5.17236389],
                            [39.71122804, -5.17233228],
                            [39.71157571, -5.17213208],
                            [39.71172321, -5.17186867],
                            [39.71192338, -5.17197403],
                            [39.71210248, -5.17203725],
                            [39.71238694, -5.17166847],
                            [39.71292425, -5.17126808],
                            [39.71316656, -5.17107842],
                            [39.71354584, -5.17070964],
                            [39.71372494, -5.17033032],
                            [39.71398833, -5.17013013],
                            [39.71413582, -5.16994047],
                            [39.71438867, -5.16982457],
                            [39.71457831, -5.16958223],
                            [39.71489438, -5.16951901],
                            [39.71537901, -5.16936096],
                            [39.7155265, -5.16925559],
                            [39.71584257, -5.16906593],
                            [39.71607434, -5.16887627],
                            [39.71631666, -5.16860232],
                            [39.71654844, -5.16849696],
                            [39.71688557, -5.16823354],
                            [39.71703307, -5.16814925],
                            [39.71717003, -5.16797013],
                            [39.71740181, -5.16788583],
                            [39.7176968, -5.16775939],
                            [39.71789698, -5.1676751],
                            [39.71829732, -5.16750652],
                            [39.71878195, -5.1673274],
                            [39.71895052, -5.16721149],
                            [39.71928765, -5.16713774],
                            [39.71952997, -5.16690593],
                            [39.71981443, -5.16674788],
                            [39.72014102, -5.16667413],
                            [39.72043602, -5.16654769],
                            [39.72072047, -5.16634749],
                            [39.72094172, -5.16622105],
                            [39.72122617, -5.16619998],
                            [39.72151063, -5.16603139],
                            [39.72197419, -5.16555725],
                            [39.72223015, -5.16525056],
                            [39.72256664, -5.16497521],
                            [39.722689, -5.16463868],
                            [39.722689, -5.1640574],
                            [39.72253605, -5.16362909],
                            [39.72238705, -5.1633062],
                            [39.72235251, -5.16323137],
                            [39.72216897, -5.16265008],
                            [39.7223831, -5.16243593],
                            [39.72253605, -5.16228296],
                            [39.72272676, -5.16201049],
                            [39.72296432, -5.16167108],
                            [39.72323963, -5.16127336],
                            [39.72305609, -5.16102861],
                            [39.72308668, -5.1605697],
                            [39.72314032, -5.16048625],
                            [39.723362, -5.16014139],
                            [39.72339921, -5.16003717],
                            [39.72351495, -5.15971308],
                            [39.72412676, -5.15928476],
                            [39.72424912, -5.15897883],
                            [39.7243103, -5.15879526],
                            [39.72452443, -5.15888705],
                            [39.7249527, -5.1591012],
                            [39.72510565, -5.15891764],
                            [39.72513624, -5.15848933],
                            [39.72541155, -5.15858111],
                            [39.72574805, -5.15830576],
                            [39.72605395, -5.15824458],
                            [39.72648222, -5.15845874],
                            [39.72715521, -5.15870349],
                            [39.72795056, -5.15848933],
                            [39.7294189, -5.15744914],
                            [39.73003071, -5.15601123],
                            [39.73062401, -5.1550025],
                            [39.73064251, -5.15497105],
                            [39.73073395, -5.15476663],
                            [39.73116255, -5.15380848],
                            [39.73159731, -5.15307131],
                            [39.73247472, -5.15265942],
                            [39.73313726, -5.15208635],
                            [39.73306563, -5.15158492],
                            [39.73285076, -5.15106558],
                            [39.73251054, -5.14966873],
                            [39.73260007, -5.1487375],
                            [39.73308354, -5.14812861],
                            [39.73435489, -5.14685712],
                            [39.73489208, -5.14648105],
                            [39.73542927, -5.14610497],
                            [39.73743478, -5.14605125],
                            [39.73990585, -5.14705412],
                            [39.74078326, -5.14744811],
                            [39.74205461, -5.14854052],
                            [39.7428962, -5.14884496],
                            [39.74350502, -5.14882705],
                            [39.74411383, -5.14861215],
                            [39.74450777, -5.1482898],
                            [39.74440034, -5.14784209],
                            [39.74436452, -5.14737648],
                            [39.74452568, -5.14674969],
                            [39.74511659, -5.14608708],
                            [39.74540309, -5.1456931],
                            [39.74586866, -5.14540656],
                            [39.74644166, -5.14497676],
                            [39.74703257, -5.14463651],
                            [39.74765929, -5.14454697],
                            [39.74844717, -5.14433207],
                            [39.74918133, -5.14386645],
                            [39.74977224, -5.14336502],
                            [39.74986784, -5.1429416],
                            [39.74989758, -5.14280986],
                            [39.74964689, -5.14243379],
                            [39.74916342, -5.14227261],
                            [39.74860833, -5.14186072],
                            [39.74852327, -5.14152961],
                            [39.74915011, -5.14167705],
                            [39.74967119, -5.14182297],
                            [39.74986828, -5.14195943],
                            [39.75021311, -5.14219819],
                            [39.7504007, -5.14276102],
                            [39.75031733, -5.14317793],
                            [39.75012974, -5.14342808],
                            [39.74975456, -5.14378245],
                            [39.74956697, -5.14401175],
                            [39.74945234, -5.14418894],
                            [39.7493377, -5.14436613],
                            [39.74923348, -5.14457458],
                            [39.74929601, -5.14486642],
                            [39.74937938, -5.14524164],
                            [39.74944191, -5.14549178],
                            [39.74940023, -5.14570024],
                            [39.74958781, -5.14592954],
                            [39.74900421, -5.14580447],
                            [39.74850414, -5.14582839],
                            [39.74804055, -5.14597687],
                            [39.74773277, -5.14607545],
                            [39.74721169, -5.14599207],
                            [39.74694073, -5.14592953],
                            [39.74662809, -5.14565854],
                            [39.74643766, -5.14591831],
                            [39.74621995, -5.14628121],
                            [39.7460748, -5.14700701],
                            [39.74592966, -5.14758765],
                            [39.74542165, -5.14856747],
                            [39.74506091, -5.14910847],
                            [39.74425759, -5.14944543],
                            [39.74306307, -5.14994649],
                            [39.74271127, -5.14999755],
                            [39.7401962, -5.15036263],
                            [39.73914851, -5.15062459],
                            [39.73941043, -5.15151523],
                            [39.73993427, -5.15193435],
                            [39.74061527, -5.15230108],
                            [39.74140019, -5.15266742],
                            [39.74249956, -5.15444756],
                            [39.74297102, -5.15628123],
                            [39.74348809, -5.15732617],
                            [39.74351932, -5.15830922],
                            [39.74288813, -5.1591772],
                            [39.74154686, -5.1603871],
                            [39.74081048, -5.16141289],
                            [39.74020559, -5.16328035],
                            [39.7399426, -5.16472698],
                            [39.7396007, -5.16588428],
                            [39.73865393, -5.16648923],
                            [39.73836998, -5.16669804],
                            [39.73775975, -5.16714679],
                            [39.7374751, -5.16751811],
                            [39.73715486, -5.16793586],
                            [39.73689187, -5.16880383],
                            [39.73686326, -5.16891826],
                            [39.73662887, -5.16985592],
                            [39.73639218, -5.1706976],
                            [39.73605048, -5.17123248],
                            [39.73578729, -5.17164448],
                            [39.7353402, -5.17238094],
                            [39.7351824, -5.17311741],
                            [39.73581359, -5.17369606],
                            [39.73691816, -5.17374867],
                            [39.73836462, -5.17280178],
                            [39.74065267, -5.17169709],
                            [39.74220433, -5.17132886],
                            [39.7435456, -5.17101323],
                            [39.74438718, -5.17025047],
                            [39.74480797, -5.16925098],
                            [39.74558771, -5.16850643],
                            [39.74564996, -5.16858493],
                            [39.7457095, -5.16861741],
                            [39.74580693, -5.16858493],
                            [39.74584767, -5.16869499],
                            [39.7457183, -5.16878221],
                            [39.74561749, -5.16885018],
                            [39.74547676, -5.16914249],
                            [39.74547135, -5.16920203],
                            [39.74553088, -5.16940232],
                            [39.7455796, -5.16945104],
                            [39.74576431, -5.1695332],
                            [39.74572032, -5.16956472],
                            [39.74568785, -5.16967298],
                            [39.74577986, -5.16978666],
                            [39.74599549, -5.169983],
                            [39.74615465, -5.17009796],
                            [39.74637571, -5.17027482],
                            [39.74644644, -5.17031019],
                            [39.74668518, -5.17034557],
                            [39.74680013, -5.17031904],
                            [39.74703002, -5.17036325],
                            [39.74739255, -5.1704959],
                            [39.74772855, -5.17056665],
                            [39.74793192, -5.17073467],
                            [39.74812645, -5.17091153],
                            [39.74832982, -5.17109724],
                            [39.74846245, -5.171336],
                            [39.74858624, -5.17157477],
                            [39.74871003, -5.17168973],
                            [39.74878077, -5.17182237],
                            [39.74870989, -5.1718753],
                            [39.74863045, -5.17195502],
                            [39.74849782, -5.17218494],
                            [39.74841824, -5.17244139],
                            [39.74845361, -5.17265363],
                            [39.74859508, -5.17269785],
                            [39.74872771, -5.17280396],
                            [39.74877312, -5.17298948],
                            [39.74867466, -5.17309579],
                            [39.74849782, -5.17308694],
                            [39.74828561, -5.17304273],
                            [39.74795845, -5.17305157],
                            [39.74786363, -5.17305457],
                            [39.74771971, -5.1732019],
                            [39.74768728, -5.17334547],
                            [39.74756055, -5.17347604],
                            [39.74739908, -5.17355402],
                            [39.74724223, -5.1737148],
                            [39.74699465, -5.17387398],
                            [39.74678244, -5.17458143],
                            [39.74672054, -5.17520929],
                            [39.74674707, -5.1755188],
                            [39.74676475, -5.17575757],
                            [39.74674707, -5.17588137],
                            [39.74673823, -5.17607592],
                            [39.74679128, -5.1762351],
                            [39.74680896, -5.17651808],
                            [39.74683549, -5.17666841],
                            [39.74702117, -5.1768718],
                            [39.74708307, -5.17696908],
                            [39.74716265, -5.17706635],
                            [39.74702559, -5.17717247],
                            [39.74688854, -5.17727859],
                            [39.74674693, -5.17764669],
                            [39.74641106, -5.17805679],
                            [39.74626959, -5.17814522],
                            [39.74632264, -5.17821596],
                            [39.74639338, -5.17825133],
                            [39.74675591, -5.17832208],
                            [39.74680012, -5.1784901],
                            [39.7469637, -5.1785918],
                            [39.74712728, -5.17869349],
                            [39.74741907, -5.17888804],
                            [39.7475753, -5.17918696],
                            [39.74762244, -5.17927714],
                            [39.74759084, -5.17941439],
                            [39.74756572, -5.17952347],
                            [39.74752921, -5.17957436],
                            [39.74746328, -5.17966624],
                            [39.7473837, -5.17968835],
                            [39.74730412, -5.17971045],
                            [39.74728643, -5.17963971],
                            [39.74725107, -5.17948937],
                            [39.7472157, -5.17932136],
                            [39.74709191, -5.17904722],
                            [39.74697696, -5.17895879],
                            [39.74676475, -5.17884382],
                            [39.74658791, -5.1786139],
                            [39.7464818, -5.17856969],
                            [39.74634917, -5.17854316],
                            [39.7462519, -5.17856969],
                            [39.74629611, -5.17870233],
                            [39.74638454, -5.17882614],
                            [39.74626959, -5.17883498],
                            [39.74619885, -5.17883498],
                            [39.74606622, -5.17886151],
                            [39.74592474, -5.17885267],
                            [39.74581864, -5.17884382],
                            [39.74573906, -5.17875539],
                            [39.74566832, -5.17860506],
                            [39.74565948, -5.17848125],
                            [39.74565948, -5.17842377],
                            [39.74565948, -5.17836629],
                            [39.74554453, -5.17826902],
                            [39.7454431, -5.17830776],
                            [39.74534166, -5.1783465],
                            [39.74522622, -5.17848125],
                            [39.7452713, -5.17887851],
                            [39.74518387, -5.17898263],
                            [39.74504053, -5.17915333],
                            [39.74505821, -5.1793302],
                            [39.7450759, -5.17948937],
                            [39.74517316, -5.17973698],
                            [39.74528811, -5.17988731],
                            [39.74542074, -5.17997574],
                            [39.745518, -5.18006418],
                            [39.7455799, -5.18010839],
                            [39.74559361, -5.18014496],
                            [39.74563295, -5.18024988],
                            [39.74560444, -5.18051161],
                            [39.74552143, -5.18061951],
                            [39.74543842, -5.18072741],
                            [39.74553524, -5.18104091],
                            [39.74549148, -5.18109882],
                            [39.74543842, -5.18101039],
                            [39.74523116, -5.18084335],
                            [39.74519084, -5.18089543],
                            [39.74512011, -5.18089543],
                            [39.74511126, -5.18081584],
                            [39.745014, -5.18072741],
                            [39.74490883, -5.18070715],
                            [39.74480179, -5.18084237],
                            [39.74477875, -5.18107818],
                            [39.74465147, -5.18124031],
                            [39.74465147, -5.18144371],
                            [39.74466031, -5.18165594],
                            [39.74475316, -5.1818328],
                            [39.744846, -5.18200967],
                            [39.74461541, -5.18194462],
                            [39.74460556, -5.1819575],
                            [39.74454537, -5.1820362],
                            [39.74442633, -5.1820362],
                            [39.74437736, -5.18203619],
                            [39.74414747, -5.18216],
                            [39.74414689, -5.18215973],
                            [39.74399715, -5.18208925],
                            [39.74353896, -5.18222147],
                            [39.74329862, -5.18252257],
                            [39.74303336, -5.18274364],
                            [39.74282999, -5.18278786],
                            [39.74257357, -5.18286745],
                            [39.74236935, -5.18287912],
                            [39.74226409, -5.18288513],
                            [39.74204304, -5.18286745],
                            [39.74189602, -5.18291301],
                            [39.74179626, -5.18303188],
                            [39.74177975, -5.18305155],
                            [39.74168935, -5.18315927],
                            [39.74162746, -5.18340688],
                            [39.74170704, -5.183513],
                            [39.74186619, -5.18358374],
                            [39.74200767, -5.18347762],
                            [39.74205697, -5.18340014],
                            [39.74206956, -5.18338035],
                            [39.74213146, -5.18343341],
                            [39.74220091, -5.1835208],
                            [39.74217799, -5.18354911],
                            [39.74215417, -5.18357855],
                            [39.7420784, -5.18367217],
                            [39.74189272, -5.18374292],
                            [39.74186496, -5.18384637],
                            [39.74178844, -5.18394061],
                            [39.74173356, -5.18400821],
                            [39.74158324, -5.18407896],
                            [39.74142409, -5.18393747],
                            [39.74142409, -5.18387556],
                            [39.74142409, -5.18374292],
                            [39.74137988, -5.18364564],
                            [39.74126493, -5.18354837],
                            [39.74114114, -5.18351299],
                            [39.74089356, -5.18347762],
                            [39.74084123, -5.18349346],
                            [39.74077679, -5.18356839],
                            [39.74072556, -5.18362796],
                            [39.74079629, -5.18385788],
                            [39.74082777, -5.18396615],
                            [39.74075097, -5.18405693],
                            [39.7406725, -5.1841497],
                            [39.74056695, -5.18413867],
                            [39.74040724, -5.18432656],
                            [39.74026082, -5.18439674],
                            [39.74017734, -5.18449458],
                            [39.74013888, -5.18463609],
                            [39.74007124, -5.18471566],
                            [39.7399386, -5.18471566],
                            [39.73992092, -5.18464492],
                            [39.73985018, -5.18456533],
                            [39.73973524, -5.18459186],
                            [39.73973524, -5.18449458],
                            [39.73979713, -5.18442384],
                            [39.73992976, -5.18438846],
                            [39.73997397, -5.1842735],
                            [39.7400005, -5.18416739],
                            [39.74013313, -5.1840878],
                            [39.74030113, -5.18400821],
                            [39.74043377, -5.18397284],
                            [39.7405045, -5.18391094],
                            [39.74053987, -5.18374292],
                            [39.74046913, -5.18352184],
                            [39.74048682, -5.18342456],
                            [39.74054871, -5.18334497],
                            [39.74061061, -5.18328307],
                            [39.74068135, -5.18317695],
                            [39.74078745, -5.18314158],
                            [39.74088472, -5.18307968],
                            [39.74092893, -5.18296472],
                            [39.74084051, -5.1828586],
                            [39.74069903, -5.1826729],
                            [39.74061061, -5.18258446],
                            [39.74045145, -5.18248719],
                            [39.74021271, -5.18245182],
                            [39.73998282, -5.18245182],
                            [39.73985019, -5.18248719],
                            [39.73978102, -5.18252013],
                            [39.7396645, -5.18257562],
                            [39.73946113, -5.18260215],
                            [39.73931082, -5.18265521],
                            [39.73908092, -5.18269942],
                            [39.73886871, -5.1827348],
                            [39.73862113, -5.18272595],
                            [39.73832934, -5.18284091],
                            [39.73794912, -5.18307083],
                            [39.73783453, -5.18314724],
                            [39.73768386, -5.1832477],
                            [39.73748049, -5.1833096],
                            [39.73731205, -5.18338024],
                            [39.73720638, -5.18342456],
                            [39.73694551, -5.18360582],
                            [39.73686154, -5.18368985],
                            [39.73676428, -5.18361911],
                            [39.73657859, -5.1835572],
                            [39.73635754, -5.18361026],
                            [39.73621606, -5.18356605],
                            [39.73597733, -5.18353952],
                            [39.73574743, -5.1835572],
                            [39.73549985, -5.18357489],
                            [39.73532216, -5.18362551],
                            [39.73515864, -5.18378007],
                            [39.7350312, -5.18390053],
                            [39.73489858, -5.18402589],
                            [39.73483669, -5.18417622],
                            [39.73494136, -5.1843685],
                            [39.73460679, -5.18468912],
                            [39.73445668, -5.18475844],
                            [39.73437051, -5.1848408],
                            [39.73426606, -5.18494063],
                            [39.73419335, -5.18501013],
                            [39.7340851, -5.18511359],
                            [39.73413486, -5.18527956],
                            [39.73404089, -5.18537004],
                            [39.73394363, -5.18525508],
                            [39.73383339, -5.18512789],
                            [39.73371989, -5.18523553],
                            [39.73357917, -5.18536898],
                            [39.73346615, -5.18547616],
                            [39.73343078, -5.18559996],
                            [39.73351921, -5.18579451],
                            [39.73379331, -5.185936],
                            [39.733811, -5.18600675],
                            [39.73378447, -5.18612171],
                            [39.73385521, -5.18621899],
                            [39.73402321, -5.18631626],
                            [39.73422658, -5.18635163],
                            [39.73439458, -5.18637816],
                            [39.73466868, -5.18642238],
                            [39.73486321, -5.18637816],
                            [39.73505774, -5.18660809],
                            [39.73506658, -5.18661693],
                            [39.735155, -5.18662577],
                            [39.735323, -5.18670536],
                            [39.73541142, -5.18676726],
                            [39.73564132, -5.18685569],
                            [39.73583585, -5.18688222],
                            [39.73601269, -5.1869795],
                            [39.73610995, -5.18701487],
                            [39.73629564, -5.18707677],
                            [39.73644595, -5.18725364],
                            [39.73649901, -5.18740397],
                            [39.73655206, -5.18759852],
                            [39.73657859, -5.18774885],
                            [39.73666701, -5.18785497],
                            [39.73687038, -5.18799646],
                            [39.73708259, -5.18816448],
                            [39.73722406, -5.18830597],
                            [39.7374009, -5.18854474],
                            [39.73752943, -5.1888035],
                            [39.73748048, -5.18886309],
                            [39.73747934, -5.18896187],
                            [39.7374009, -5.18905764],
                            [39.73740974, -5.18920797],
                            [39.73756006, -5.18927872],
                            [39.73756165, -5.18927957],
                            [39.73774189, -5.18937646],
                            [39.73769269, -5.18943789],
                            [39.73755718, -5.18944442],
                            [39.73755522, -5.18944685],
                            [39.7374628, -5.1895617],
                            [39.73747164, -5.18965013],
                            [39.73754284, -5.18976882],
                            [39.73755122, -5.18978278],
                            [39.73774575, -5.1897474],
                            [39.73775459, -5.18989774],
                            [39.73756107, -5.1899571],
                            [39.73753431, -5.18999087],
                            [39.73745395, -5.19009229],
                            [39.73741859, -5.19039295],
                            [39.73750701, -5.19083511],
                            [39.73759425, -5.19092332],
                            [39.73756006, -5.19096776],
                            [39.73740974, -5.19100313],
                            [39.73728595, -5.19126842],
                            [39.73722406, -5.19151603],
                            [39.73727711, -5.19179017],
                            [39.73730364, -5.19206431],
                            [39.73717984, -5.19247109],
                            [39.73715332, -5.19270985],
                            [39.73716216, -5.19307242],
                            [39.73726826, -5.19321391],
                            [39.73728595, -5.19364723],
                            [39.73732132, -5.19382409],
                            [39.7374009, -5.19390368],
                            [39.73747163, -5.19394789],
                            [39.7377369, -5.19403633],
                            [39.73791374, -5.19401864],
                            [39.73809058, -5.19367376],
                            [39.73817016, -5.19370913],
                            [39.73824974, -5.19392137],
                            [39.73832932, -5.19409823],
                            [39.73853269, -5.19418666],
                            [39.73861227, -5.19410707],
                            [39.7385769, -5.19398327],
                            [39.73855922, -5.19391252],
                            [39.73866532, -5.19385062],
                            [39.73879796, -5.19371798],
                            [39.73915164, -5.1934969],
                            [39.73924891, -5.1932316],
                            [39.73942575, -5.19311664],
                            [39.73966449, -5.19290441],
                            [39.73980596, -5.19267449],
                            [39.73994744, -5.19248878],
                            [39.7399828, -5.19239151],
                            [39.73999165, -5.19229423],
                            [39.74003586, -5.19217043],
                            [39.73997396, -5.19205547],
                            [39.73991207, -5.19202894],
                            [39.73982365, -5.19195819],
                            [39.73992091, -5.1918786],
                            [39.74008891, -5.19190513],
                            [39.74024807, -5.19209084],
                            [39.74033649, -5.19221464],
                            [39.74059291, -5.19244457],
                            [39.74067016, -5.1926309],
                            [39.74060175, -5.19273639],
                            [39.74068133, -5.19291325],
                            [39.74080512, -5.1930459],
                            [39.74098197, -5.19309896],
                            [39.74118533, -5.19316086],
                            [39.7413887, -5.19332888],
                            [39.74150365, -5.19325814],
                            [39.74152134, -5.19319623],
                            [39.74147713, -5.19307243],
                            [39.74162744, -5.19300168],
                            [39.7417866, -5.19307243],
                            [39.74181262, -5.193159],
                            [39.74173378, -5.19328863],
                            [39.74167165, -5.19339078],
                            [39.74179544, -5.19348806],
                            [39.74196344, -5.19354996],
                            [39.74219334, -5.19364723],
                            [39.74225523, -5.19374451],
                            [39.74234366, -5.19370029],
                            [39.74244092, -5.19359418],
                            [39.74246745, -5.19377104],
                            [39.74263545, -5.19405402],
                            [39.74275471, -5.19420297],
                            [39.74272387, -5.19425741],
                            [39.74290955, -5.19441659],
                            [39.74306871, -5.19442543],
                            [39.74323671, -5.19452271],
                            [39.74343124, -5.19453155],
                            [39.74345776, -5.19444312],
                            [39.7435904, -5.19450502],
                            [39.74376724, -5.19468188],
                            [39.74383798, -5.194788],
                            [39.7438833, -5.19520462],
                            [39.74384682, -5.19527437],
                            [39.74376724, -5.19527437],
                            [39.74363461, -5.19529206],
                            [39.74355503, -5.19530975],
                            [39.74344892, -5.19532743],
                            [39.7433605, -5.19518594],
                            [39.74326324, -5.1951771],
                            [39.7431306, -5.19508867],
                            [39.74301566, -5.19513288],
                            [39.7429626, -5.19510635],
                            [39.74292723, -5.19502677],
                            [39.74291839, -5.19495602],
                            [39.74282293, -5.19490493],
                            [39.7427946, -5.19495602],
                            [39.74278576, -5.19512404],
                            [39.74278576, -5.19529206],
                            [39.74284765, -5.19542471],
                            [39.74298913, -5.19549545],
                            [39.7431306, -5.19561926],
                            [39.74313945, -5.1957519],
                            [39.74326324, -5.19591108],
                            [39.74347545, -5.1960172],
                            [39.74370534, -5.1960172],
                            [39.74428008, -5.19574306],
                            [39.74437735, -5.19570769],
                            [39.74457187, -5.19555736],
                            [39.74471335, -5.19542471],
                            [39.74481061, -5.19514173],
                            [39.74492556, -5.19497371],
                            [39.74504051, -5.19487644],
                            [39.74515546, -5.1947261],
                            [39.74522619, -5.19453155],
                            [39.74530577, -5.19449618],
                            [39.74546493, -5.19435469],
                            [39.74559756, -5.19422204],
                            [39.74585399, -5.19404518],
                            [39.74578191, -5.19383712],
                            [39.74557774, -5.19383089],
                            [39.74570834, -5.19371099],
                            [39.74584583, -5.19367864],
                            [39.74592671, -5.19364628],
                            [39.74603995, -5.19367055],
                            [39.74602377, -5.19372717],
                            [39.74609656, -5.19387277],
                            [39.74614509, -5.19393749],
                            [39.74621788, -5.19401837],
                            [39.74628258, -5.19413971],
                            [39.7462745, -5.19423678],
                            [39.7462745, -5.19430958],
                            [39.74631494, -5.19439047],
                            [39.74641199, -5.19449562],
                            [39.74639777, -5.19463862],
                            [39.74634729, -5.19464122],
                            [39.7462745, -5.19457651],
                            [39.74615929, -5.19441053],
                            [39.74606421, -5.19441473],
                            [39.74600759, -5.19447944],
                            [39.74592671, -5.19459269],
                            [39.74580539, -5.19468167],
                            [39.7456702, -5.1949071],
                            [39.74551422, -5.19491624],
                            [39.74538481, -5.19501331],
                            [39.74531202, -5.19507802],
                            [39.74523923, -5.19519936],
                            [39.74513408, -5.19536922],
                            [39.7451907, -5.19546629],
                            [39.7453282, -5.19563616],
                            [39.74549805, -5.19566852],
                            [39.74575686, -5.19566043],
                            [39.74598333, -5.1956038],
                            [39.74612891, -5.1954582],
                            [39.74625023, -5.19540158],
                            [39.74626641, -5.19553909],
                            [39.74606858, -5.19576268],
                            [39.74595021, -5.19577282],
                            [39.74584583, -5.19578176],
                            [39.74576495, -5.19587074],
                            [39.7457326, -5.19596781],
                            [39.74574877, -5.19607296],
                            [39.74583774, -5.19614576],
                            [39.74600759, -5.19615385],
                            [39.74615317, -5.1961215],
                            [39.74629067, -5.19607296],
                            [39.74644434, -5.19607296],
                            [39.74670316, -5.19607296],
                            [39.74681639, -5.19607296],
                            [39.74693771, -5.19607296],
                            [39.74700242, -5.19608914],
                            [39.7469473, -5.19620899],
                            [39.74678312, -5.19622575],
                            [39.74669507, -5.19623474],
                            [39.74661883, -5.19617604],
                            [39.74651713, -5.19618621],
                            [39.74640943, -5.19612763],
                            [39.74630685, -5.19613767],
                            [39.74621725, -5.19619602],
                            [39.74615317, -5.19620239],
                            [39.74600051, -5.19629318],
                            [39.74590526, -5.19630287],
                            [39.74585397, -5.19630808],
                            [39.74570024, -5.19632372],
                            [39.74570024, -5.19624283],
                            [39.74561215, -5.19618713],
                            [39.74553848, -5.1961943],
                            [39.74546569, -5.19625901],
                            [39.74536864, -5.1964127],
                            [39.74531603, -5.19656321],
                            [39.74519376, -5.19657638],
                            [39.74515551, -5.1965805],
                            [39.74506129, -5.19659065],
                            [39.74499174, -5.19667676],
                            [39.74488346, -5.19668867],
                            [39.74481865, -5.19669581],
                            [39.74470542, -5.1967767],
                            [39.74466498, -5.19685759],
                            [39.74465689, -5.19697892],
                            [39.74467307, -5.19707599],
                            [39.74480247, -5.19724586],
                            [39.74490762, -5.1973591],
                            [39.74502085, -5.19746426],
                            [39.74502085, -5.19759368],
                            [39.74501276, -5.19773119],
                            [39.74500467, -5.1978687],
                            [39.74495614, -5.19799813],
                            [39.74500467, -5.19812755],
                            [39.74506938, -5.19825697],
                            [39.74502085, -5.19820844],
                            [39.74485909, -5.19812755],
                            [39.74473777, -5.19821653],
                            [39.74472159, -5.19839448],
                            [39.74485302, -5.19850368],
                            [39.74512195, -5.19859873],
                            [39.74520889, -5.19868366],
                            [39.74525944, -5.19878882],
                            [39.7453366, -5.19886136],
                            [39.74536054, -5.19888386],
                            [39.74536863, -5.19897284],
                            [39.74540705, -5.1990598],
                            [39.74551826, -5.19920742],
                            [39.74571035, -5.19932471],
                            [39.74584785, -5.19945818],
                            [39.74597912, -5.19958416],
                            [39.74604802, -5.19965029],
                            [39.74616732, -5.19970287],
                            [39.7462482, -5.19977365],
                            [39.74634728, -5.19989094],
                            [39.74640794, -5.19998194],
                            [39.74641461, -5.19998841],
                            [39.74647467, -5.20004665],
                            [39.74654544, -5.20007294],
                            [39.74661419, -5.20008103],
                            [39.74665058, -5.20017809],
                            [39.74673955, -5.20029134],
                            [39.74682852, -5.20037425],
                            [39.74691743, -5.20038487],
                            [39.74696399, -5.20039043],
                            [39.74711766, -5.20040661],
                            [39.74716821, -5.20051176],
                            [39.7472592, -5.2006331],
                            [39.74737432, -5.20072601],
                            [39.74737446, -5.20072612],
                            [39.747516, -5.2007787],
                            [39.74766967, -5.20079488],
                            [39.74791029, -5.20080499],
                            [39.74803363, -5.20081914],
                            [39.7481873, -5.20081914],
                            [39.74831873, -5.20084341],
                            [39.74848858, -5.20093239],
                            [39.7485742, -5.2009752],
                            [39.74867461, -5.20102541],
                            [39.74888287, -5.20112652],
                            [39.74902239, -5.20110832],
                            [39.74911743, -5.20106384],
                            [39.74922257, -5.20097486],
                            [39.74925088, -5.20083532],
                            [39.74923672, -5.20064321],
                            [39.74937826, -5.20049559],
                            [39.74947639, -5.20050718],
                            [39.74935451, -5.20063811],
                            [39.7493373, -5.20077058],
                            [39.7493201, -5.20090304],
                            [39.74930936, -5.20098575],
                            [39.74933193, -5.20105348],
                            [39.74951702, -5.20103993],
                            [39.74958796, -5.20115406],
                            [39.74962085, -5.20120698],
                            [39.74948542, -5.20125213],
                            [39.74939062, -5.2012431],
                            [39.74917845, -5.20130179],
                            [39.74886244, -5.20134694],
                            [39.74853628, -5.20126322],
                            [39.74835232, -5.20121601],
                            [39.74808147, -5.20111668],
                            [39.74798666, -5.2011528],
                            [39.74785575, -5.20116634],
                            [39.74753975, -5.20107153],
                            [39.74725083, -5.20099929],
                            [39.74722078, -5.2009819],
                            [39.74707928, -5.20089997],
                            [39.74677623, -5.20060455],
                            [39.74672265, -5.20055232],
                            [39.74636151, -5.20018211],
                            [39.74597504, -5.19986159],
                            [39.74589033, -5.19979133],
                            [39.74580625, -5.19972159],
                            [39.74561664, -5.19953197],
                            [39.74533224, -5.19948231],
                            [39.74509298, -5.19930623],
                            [39.74501624, -5.1992385],
                            [39.74491918, -5.19922948],
                            [39.74482212, -5.19922045],
                            [39.74478149, -5.19931074],
                            [39.74464155, -5.19931977],
                            [39.74430298, -5.19910306],
                            [39.7440434, -5.19887506],
                            [39.74378383, -5.19864706],
                            [39.74358971, -5.19848001],
                            [39.74351748, -5.19824072],
                            [39.7433956, -5.19768991],
                            [39.74337303, -5.19757253],
                            [39.74329177, -5.19740096],
                            [39.74317891, -5.19730164],
                            [39.7430796, -5.19729712],
                            [39.74302994, -5.1973829],
                            [39.74260559, -5.19787502],
                            [39.7421993, -5.19847549],
                            [39.74220381, -5.19857031],
                            [39.74224444, -5.19865157],
                            [39.74223993, -5.19875541],
                            [39.74220409, -5.19875883],
                            [39.74214513, -5.19876444],
                            [39.74205033, -5.19876896],
                            [39.74195553, -5.19879153],
                            [39.74189684, -5.19887731],
                            [39.74191941, -5.19895407],
                            [39.7418517, -5.19900373],
                            [39.7417885, -5.19897664],
                            [39.74150409, -5.19920238],
                            [39.74148152, -5.19927913],
                            [39.74152666, -5.19934686],
                            [39.74147249, -5.19936492],
                            [39.74140026, -5.19938297],
                            [39.74131449, -5.19955905],
                            [39.7412558, -5.19978028],
                            [39.74130095, -5.20001957],
                            [39.7412558, -5.20008277],
                            [39.74114746, -5.20006923],
                            [39.74105266, -5.20016856],
                            [39.74096689, -5.20037624],
                            [39.74096689, -5.20045299],
                            [39.74101203, -5.20050265],
                            [39.74102557, -5.20055232],
                            [39.7408766, -5.20062004],
                            [39.74080888, -5.20072388],
                            [39.74080437, -5.20085481],
                            [39.7408766, -5.2010038],
                            [39.74098946, -5.20105798],
                            [39.74116552, -5.2011257],
                            [39.74112037, -5.20117536],
                            [39.74099397, -5.2011257],
                            [39.74092626, -5.2010941],
                            [39.7408134, -5.2011257],
                            [39.74066894, -5.20119794],
                            [39.74052899, -5.20135144],
                            [39.74049288, -5.20159073],
                            [39.74051094, -5.20175326],
                            [39.74055608, -5.20180293],
                            [39.74050191, -5.20183904],
                            [39.74029425, -5.20211896],
                            [39.74010916, -5.20236276],
                            [39.73998727, -5.20271041],
                            [39.73990602, -5.20276458],
                            [39.73991053, -5.20282328],
                            [39.73996019, -5.2029813],
                            [39.74005047, -5.20303999],
                            [39.74004144, -5.20306708],
                            [39.73991341, -5.20314149],
                            [39.73991956, -5.20308965],
                            [39.73974801, -5.20305353],
                            [39.73963064, -5.20315737],
                            [39.73961258, -5.20330185],
                            [39.73956293, -5.20345987],
                            [39.73947264, -5.20358628],
                            [39.73942298, -5.20356822],
                            [39.73934624, -5.20357725],
                            [39.73925595, -5.20362692],
                            [39.73924241, -5.20372173],
                            [39.73913858, -5.20373527],
                            [39.73906635, -5.20369915],
                            [39.73889932, -5.20371721],
                            [39.73874583, -5.20370367],
                            [39.73869166, -5.20372624],
                            [39.73856526, -5.20379397],
                            [39.73847497, -5.20374882],
                            [39.73836211, -5.20378494],
                            [39.73837566, -5.20372173],
                            [39.73822668, -5.20358177],
                            [39.73814091, -5.2035908],
                            [39.73792516, -5.2037254],
                            [39.73786554, -5.20365852],
                            [39.73773462, -5.20367206],
                            [39.73768137, -5.20374376],
                            [39.73763079, -5.20369464],
                            [39.73757662, -5.20375333],
                            [39.73750439, -5.20373527],
                            [39.73745473, -5.2038346],
                            [39.73731028, -5.20392489],
                            [39.73730125, -5.20398359],
                            [39.73733285, -5.20406034],
                            [39.73741862, -5.20409646],
                            [39.73750439, -5.20411903],
                            [39.73760371, -5.20410097],
                            [39.73770302, -5.20418675],
                            [39.73794679, -5.20436283],
                            [39.73809125, -5.20446216],
                            [39.73812737, -5.20444862],
                            [39.73818605, -5.20446668],
                            [39.73819508, -5.2045344],
                            [39.73833503, -5.20467887],
                            [39.73839823, -5.20471048],
                            [39.73850206, -5.20464727],
                            [39.73851109, -5.20494073],
                            [39.7385472, -5.20503103],
                            [39.7386104, -5.205022],
                            [39.73860589, -5.20508521],
                            [39.7388, -5.20528838],
                            [39.73884514, -5.20530192],
                            [39.73897606, -5.20526129],
                            [39.73901669, -5.20522065],
                            [39.73903926, -5.20525226],
                            [39.73901217, -5.20531095],
                            [39.73893092, -5.2053561],
                            [39.73889932, -5.20540576],
                            [39.73898509, -5.20549606],
                            [39.7390528, -5.205478],
                            [39.73908892, -5.20541479],
                            [39.73915212, -5.20540576],
                            [39.73922886, -5.20555927],
                            [39.73931012, -5.20555927],
                            [39.73937332, -5.20553218],
                            [39.73943201, -5.20556378],
                            [39.73949972, -5.20555927],
                            [39.73958549, -5.20555024],
                            [39.73961877, -5.20562518],
                            [39.73951952, -5.20566299],
                            [39.73924647, -5.20576701],
                            [39.73894719, -5.20579367],
                            [39.73864633, -5.20597133],
                            [39.73858248, -5.20616289],
                            [39.73858248, -5.20627782],
                            [39.73842926, -5.2062012],
                            [39.73830157, -5.2061118],
                            [39.73811907, -5.20612535],
                            [39.73807173, -5.20626505],
                            [39.73809726, -5.20658431],
                            [39.73802065, -5.2068014],
                            [39.73777804, -5.20700573],
                            [39.7377142, -5.20690356],
                            [39.73780996, -5.20683652],
                            [39.73780996, -5.20659708],
                            [39.73774293, -5.20642468],
                            [39.73766951, -5.20639594],
                            [39.73768228, -5.20631294],
                            [39.73803023, -5.20582766],
                            [39.73829518, -5.20545732],
                            [39.73827603, -5.20533601],
                            [39.73819622, -5.20522107],
                            [39.73800788, -5.20513487],
                            [39.73780997, -5.20508379],
                            [39.73763439, -5.20501036],
                            [39.73758255, -5.20501775],
                            [39.73753863, -5.20499759],
                            [39.73749713, -5.20487947],
                            [39.73731836, -5.20481242],
                            [39.73714598, -5.20481242],
                            [39.73710768, -5.20492735],
                            [39.73712364, -5.20499121],
                            [39.73697999, -5.20506464],
                            [39.73685868, -5.20512529],
                            [39.73680441, -5.2052083],
                            [39.73681718, -5.20533281],
                            [39.7368108, -5.20548286],
                            [39.73689699, -5.20562653],
                            [39.73698318, -5.20572231],
                            [39.73691933, -5.20577339],
                            [39.73682995, -5.20571273],
                            [39.73673738, -5.20556906],
                            [39.73671184, -5.2054222],
                            [39.73656181, -5.20534558],
                            [39.73643257, -5.20536251],
                            [39.73643731, -5.20530089],
                            [39.73657138, -5.20520192],
                            [39.73663523, -5.20506463],
                            [39.73684591, -5.20499759],
                            [39.73690018, -5.20492097],
                            [39.73683315, -5.20480604],
                            [39.73671142, -5.20482581],
                            [39.736648, -5.2049082],
                            [39.73658669, -5.20491788],
                            [39.73653946, -5.20483158],
                            [39.73660969, -5.20472622],
                            [39.73652669, -5.2045634],
                            [39.73632239, -5.20438142],
                            [39.73621385, -5.20433672],
                            [39.73622343, -5.20423137],
                            [39.73620747, -5.20414198],
                            [39.7361117, -5.20401427],
                            [39.7359074, -5.20398554],
                            [39.73583398, -5.20397596],
                            [39.7357861, -5.20405897],
                            [39.7358244, -5.20412601],
                            [39.73576056, -5.20421221],
                            [39.7357031, -5.20429841],
                            [39.73569033, -5.20438142],
                            [39.73563146, -5.20439339],
                            [39.7355371, -5.20431757],
                            [39.73541899, -5.20424733],
                            [39.73530726, -5.2041739],
                            [39.73521788, -5.20416432],
                            [39.7352115, -5.20412921],
                            [39.73528811, -5.20409089],
                            [39.73535515, -5.20405897],
                            [39.73536153, -5.2040015],
                            [39.73536792, -5.20392488],
                            [39.73540303, -5.2039185],
                            [39.73549241, -5.20395042],
                            [39.7355371, -5.20393446],
                            [39.73556264, -5.20389615],
                            [39.73554668, -5.20385464],
                            [39.73545091, -5.20378121],
                            [39.73534238, -5.20373652],
                            [39.73528811, -5.20366628],
                            [39.73514127, -5.20366628],
                            [39.73506785, -5.20370778],
                            [39.73510615, -5.2037429],
                            [39.73501677, -5.2037876],
                            [39.7349838, -5.203796],
                            [39.7348827, -5.20368224],
                            [39.73479332, -5.20351623],
                            [39.73476459, -5.20340768],
                            [39.73465286, -5.20337895],
                            [39.73446771, -5.20327998],
                            [39.73434567, -5.20331685],
                            [39.73425383, -5.20336937],
                            [39.73412322, -5.2034086],
                            [39.73403676, -5.20334064],
                            [39.73393142, -5.20325763],
                            [39.73365688, -5.20323847],
                            [39.73350366, -5.20326721],
                            [39.73342385, -5.20337895],
                            [39.73343024, -5.20343322],
                            [39.73334068, -5.20346142],
                            [39.7330759, -5.20350346],
                            [39.73301525, -5.20354815],
                            [39.73305036, -5.20367266],
                            [39.73305355, -5.20373651],
                            [39.7330344, -5.20380037],
                            [39.73294905, -5.20382555],
                            [39.7328684, -5.20370778],
                            [39.73276944, -5.20365351],
                            [39.73265133, -5.20371736],
                            [39.73267049, -5.20385464],
                            [39.73269283, -5.20397596],
                            [39.73264495, -5.20402385],
                            [39.73259706, -5.20410685],
                            [39.7324981, -5.20419944],
                            [39.73245022, -5.20428245],
                            [39.73235765, -5.20418986],
                            [39.73227784, -5.20424413],
                            [39.73232253, -5.2043431],
                            [39.73236084, -5.20445804],
                            [39.73237042, -5.20454104],
                            [39.73232892, -5.2046017],
                            [39.73234169, -5.20466556],
                            [39.73228103, -5.20485073],
                            [39.73226826, -5.20500397],
                            [39.73230338, -5.20510613],
                            [39.7324183, -5.20521468],
                            [39.73248214, -5.20521149],
                            [39.73259706, -5.2054222],
                            [39.7327439, -5.20550521],
                            [39.73275722, -5.20549722],
                            [39.73282371, -5.20545732],
                            [39.73286202, -5.20535835],
                            [39.73285959, -5.20525405],
                            [39.73285882, -5.20522107],
                            [39.73279817, -5.20512529],
                            [39.73283648, -5.20491458],
                            [39.73292905, -5.20488265],
                            [39.73300205, -5.20496009],
                            [39.7330344, -5.20499439],
                            [39.7330727, -5.20510613],
                            [39.73309824, -5.20522426],
                            [39.73298332, -5.20536154],
                            [39.73296736, -5.20542539],
                            [39.73297055, -5.20548924],
                            [39.73287479, -5.20559141],
                            [39.73288755, -5.20575103],
                            [39.73291309, -5.20583085],
                            [39.7329897, -5.20599367],
                            [39.73310463, -5.20601283],
                            [39.73319401, -5.20600963],
                            [39.73318491, -5.20605876],
                            [39.73316208, -5.20618203],
                            [39.73301038, -5.20619711],
                            [39.7330097, -5.20619752],
                            [39.73283009, -5.20630654],
                            [39.73280918, -5.20639806],
                            [39.73280456, -5.20641828],
                            [39.73289394, -5.2066258],
                            [39.73291628, -5.20664975],
                            [39.73293863, -5.20667369],
                            [39.73280563, -5.20668177],
                            [39.7326723, -5.20666298],
                            [39.73265771, -5.20666092],
                            [39.73256788, -5.2066666],
                            [39.73245044, -5.20667402],
                            [39.73239914, -5.20658111],
                            [39.73235764, -5.20644063],
                            [39.73238715, -5.20628424],
                            [39.73238957, -5.20627143],
                            [39.73240382, -5.2062509],
                            [39.73246937, -5.20615649],
                            [39.73254599, -5.20597771],
                            [39.73255478, -5.20594899],
                            [39.73259387, -5.20582127],
                            [39.7325396, -5.20569676],
                            [39.73235445, -5.20563291],
                            [39.73225869, -5.20560098],
                            [39.73224592, -5.2055499],
                            [39.73227784, -5.20549882],
                            [39.73226507, -5.20544774],
                            [39.73217656, -5.20546289],
                            [39.73214377, -5.2055084],
                            [39.73207598, -5.20551976],
                            [39.73201927, -5.20540943],
                            [39.73195862, -5.20526257],
                            [39.73196819, -5.20519233],
                            [39.73211823, -5.20509975],
                            [39.73207992, -5.20494012],
                            [39.73197458, -5.20489542],
                            [39.73179581, -5.20487946],
                            [39.73177139, -5.204892],
                            [39.7316777, -5.20494012],
                            [39.73166174, -5.20498481],
                            [39.73159151, -5.20502632],
                            [39.7314734, -5.2050327],
                            [39.73139998, -5.20500078],
                            [39.73129463, -5.20504547],
                            [39.73113502, -5.20510294],
                            [39.7310233, -5.20522106],
                            [39.73095307, -5.20528172],
                            [39.73089241, -5.20521149],
                            [39.73085479, -5.20521968],
                            [39.73077111, -5.20526257],
                            [39.73072642, -5.20536154],
                            [39.7305951, -5.20538864],
                            [39.73058277, -5.20548605],
                            [39.7306115, -5.20556905],
                            [39.73068492, -5.20560098],
                            [39.73072642, -5.20562652],
                            [39.73074238, -5.20571272],
                            [39.73077111, -5.20577019],
                            [39.73072087, -5.20577845],
                            [39.73059234, -5.20571911],
                            [39.73049658, -5.20562333],
                            [39.73035931, -5.20560737],
                            [39.73021885, -5.20560417],
                            [39.73012947, -5.20569995],
                            [39.72999688, -5.20572426],
                            [39.72997624, -5.20594259],
                            [39.73000178, -5.20619799],
                            [39.73006563, -5.20630016],
                            [39.73002732, -5.20646617],
                            [39.73005286, -5.20681097],
                            [39.72995071, -5.20693867],
                            [39.72977194, -5.20704083],
                            [39.72964447, -5.20704609],
                            [39.72961871, -5.20672158],
                            [39.72955487, -5.20644063],
                            [39.72959318, -5.2061086],
                            [39.72961872, -5.2057638],
                            [39.72964425, -5.20562333],
                            [39.72975917, -5.20544454],
                            [39.72987409, -5.20527853],
                            [39.72995071, -5.20515082],
                            [39.73020609, -5.20526576],
                            [39.73038485, -5.20540623],
                            [39.7306913, -5.20515083],
                            [39.73081899, -5.2049465],
                            [39.73074238, -5.20461447],
                            [39.730487, -5.20428244],
                            [39.73034655, -5.20419305],
                            [39.73011708, -5.20427038],
                            [39.7300784, -5.20442292],
                            [39.73004019, -5.20443522],
                            [39.72998172, -5.20445404],
                            [39.72989963, -5.20414197],
                            [39.72984856, -5.20395041],
                            [39.73005286, -5.20384825],
                            [39.73009117, -5.2036567],
                            [39.72989963, -5.20349068],
                            [39.72963149, -5.20341406],
                            [39.72946549, -5.20318419],
                            [39.72936334, -5.20297987],
                            [39.72924176, -5.20304276],
                            [39.72909519, -5.20292879],
                            [39.72928673, -5.20272446],
                            [39.72949103, -5.20255845],
                            [39.72978472, -5.20241797],
                            [39.73018055, -5.20217534],
                            [39.73039762, -5.20181777],
                            [39.73033378, -5.20152405],
                            [39.73023163, -5.2011154],
                            [39.73006563, -5.20083445],
                            [39.72986133, -5.20063013],
                            [39.72976515, -5.20070209],
                            [39.72969534, -5.2006429],
                            [39.72969534, -5.20048965],
                            [39.7295038, -5.20022147],
                            [39.72938888, -5.20015762],
                            [39.72895474, -5.19968512],
                            [39.72873767, -5.19957019],
                            [39.72864773, -5.19965901],
                            [39.72855778, -5.19974783],
                            [39.72850783, -5.1995191],
                            [39.72843122, -5.19941694],
                            [39.72820138, -5.19932755],
                            [39.727946, -5.19931478],
                            [39.72766508, -5.19945525],
                            [39.72755744, -5.19957996],
                            [39.72744668, -5.19964921],
                            [39.72730755, -5.1997362],
                            [39.72709048, -5.19977451],
                            [39.72674572, -5.19977451],
                            [39.72659019, -5.19997421],
                            [39.7264265, -5.20004269],
                            [39.72649034, -5.19967235],
                            [39.72641373, -5.19935309],
                            [39.72637542, -5.199219],
                            [39.72624774, -5.19906575],
                            [39.72612005, -5.19888058],
                            [39.7259349, -5.1987018],
                            [39.72569867, -5.19849109],
                            [39.72557737, -5.19844001],
                            [39.72550045, -5.19842352],
                            [39.72548799, -5.19842085],
                            [39.72541776, -5.19841447],
                            [39.72525815, -5.19827399],
                            [39.72512407, -5.19805051],
                            [39.72497085, -5.19791004],
                            [39.7247857, -5.19782064],
                            [39.72463247, -5.19774402],
                            [39.72442179, -5.19750777],
                            [39.72413449, -5.19728429],
                            [39.72391103, -5.1971055],
                            [39.7235535, -5.19672878],
                            [39.72322151, -5.19661385],
                            [39.72318287, -5.19658958],
                            [39.72294698, -5.19644145],
                            [39.72276183, -5.19633928],
                            [39.72262137, -5.19634567],
                            [39.72248091, -5.19651168],
                            [39.72227023, -5.19671601],
                            [39.72204677, -5.19683094],
                            [39.72198293, -5.19697141],
                            [39.72196885, -5.19709548],
                            [39.72188077, -5.19720128],
                            [39.72196377, -5.19733537],
                            [39.72205954, -5.19734814],
                            [39.72213615, -5.19750777],
                            [39.72227661, -5.19748223],
                            [39.72233407, -5.19754608],
                            [39.72231421, -5.19767367],
                            [39.7222874, -5.19784587],
                            [39.72227703, -5.19791251],
                            [39.72214254, -5.19801858],
                            [39.72220638, -5.19824845],
                            [39.72226384, -5.19840808],
                            [39.72224832, -5.19850341],
                            [39.7222, -5.19853578],
                            [39.72211061, -5.19849108],
                            [39.72209298, -5.19861094],
                            [39.722117, -5.19871456],
                            [39.72209299, -5.19887288],
                            [39.72197654, -5.19903382],
                            [39.72195667, -5.19917509],
                            [39.721868, -5.19920622],
                            [39.72177862, -5.19940416],
                            [39.72176987, -5.19947567],
                            [39.72180416, -5.19951909],
                            [39.72179685, -5.19957644],
                            [39.72171478, -5.19961487],
                            [39.72167008, -5.1996468],
                            [39.72165482, -5.19978276],
                            [39.72164822, -5.19984157],
                            [39.72156155, -5.19992775],
                            [39.72148357, -5.19998174],
                            [39.72147855, -5.19998521],
                            [39.72146874, -5.20009228],
                            [39.72153601, -5.20011292],
                            [39.72153039, -5.20016852],
                            [39.72147855, -5.20025339],
                            [39.72138917, -5.20054072],
                            [39.72136308, -5.20084407],
                            [39.72133811, -5.20113454],
                            [39.72151686, -5.20142187],
                            [39.72180415, -5.20183691],
                            [39.72204038, -5.2020987],
                            [39.72208696, -5.20215219],
                            [39.72221276, -5.20229664],
                            [39.72238514, -5.20260952],
                            [39.72239152, -5.20286492],
                            [39.72251283, -5.20315864],
                            [39.72267882, -5.2033502],
                            [39.72275544, -5.20349067],
                            [39.72266744, -5.20366694],
                            [39.72261498, -5.20392486],
                            [39.7225639, -5.20416111],
                            [39.72255326, -5.2041807],
                            [39.72251162, -5.20425734],
                            [39.72244898, -5.20449953],
                            [39.7224426, -5.20467193],
                            [39.7224809, -5.20479963],
                            [39.72244143, -5.20486089],
                            [39.72235321, -5.2048571],
                            [39.7222766, -5.20486348],
                            [39.72218083, -5.20513804],
                            [39.72221275, -5.20527213],
                            [39.7220723, -5.20529129],
                            [39.72196737, -5.20546586],
                            [39.72202761, -5.20560416],
                            [39.72208506, -5.20575102],
                            [39.72209145, -5.2058468],
                            [39.72201017, -5.2059451],
                            [39.72183607, -5.20598089],
                            [39.72168284, -5.20603197],
                            [39.721536, -5.20605112],
                            [39.72142747, -5.2061022],
                            [39.7213695, -5.20622279],
                            [39.72122316, -5.2063193],
                            [39.72114487, -5.20652048],
                            [39.72124232, -5.20663217],
                            [39.72129978, -5.20668964],
                            [39.72125762, -5.20675026],
                            [39.72122367, -5.20679909],
                            [39.72106994, -5.20675987],
                            [39.72097417, -5.20674072],
                            [39.72095521, -5.206813],
                            [39.72080179, -5.20690673],
                            [39.72071879, -5.20700251],
                            [39.72073537, -5.20708742],
                            [39.72077588, -5.2072949],
                            [39.72078717, -5.20735275],
                            [39.72078847, -5.20735938],
                            [39.72079847, -5.2074106],
                            [39.72066133, -5.20740797],
                            [39.72049533, -5.2075676],
                            [39.7204698, -5.20766338],
                            [39.72055279, -5.2079124],
                            [39.72073794, -5.20814865],
                            [39.72085925, -5.20833382],
                            [39.72069964, -5.20839767],
                            [39.72062302, -5.20830189],
                            [39.72045703, -5.20811672],
                            [39.72032295, -5.20796986],
                            [39.72012361, -5.20801254],
                            [39.7201378, -5.2078677],
                            [39.71999096, -5.20787409],
                            [39.71962066, -5.20812311],
                            [39.71962705, -5.20823804],
                            [39.71911629, -5.20882547],
                            [39.71875876, -5.20902341],
                            [39.71846508, -5.20929798],
                            [39.71840762, -5.20922135],
                            [39.71818361, -5.20918103],
                            [39.71808839, -5.20916389],
                            [39.71768617, -5.20935544],
                            [39.71741803, -5.20929797],
                            [39.71739887, -5.20929159],
                            [39.71717542, -5.20942568],
                            [39.71704133, -5.20937846],
                            [39.71690724, -5.20933124],
                            [39.71690089, -5.20924051],
                            [39.71665189, -5.20895317],
                            [39.71632776, -5.2088802],
                            [39.71630713, -5.20867223],
                            [39.71603899, -5.20862753],
                            [39.71593045, -5.20833381],
                            [39.71563454, -5.20816543],
                            [39.71556015, -5.2081231],
                            [39.71550757, -5.20800156],
                            [39.71542559, -5.20781207],
                            [39.71536224, -5.20735688],
                            [39.71538778, -5.20693546],
                            [39.71575807, -5.20656512],
                            [39.71609007, -5.20623309],
                            [39.71609007, -5.20582444],
                            [39.71594322, -5.20543495],
                            [39.71566231, -5.20425369],
                            [39.71531433, -5.20384928],
                            [39.7152984, -5.20371733],
                            [39.71505579, -5.20327037],
                            [39.71494725, -5.20321291],
                            [39.71481956, -5.20325122],
                            [39.71485149, -5.20343639],
                            [39.71473018, -5.20343639],
                            [39.7145578, -5.20343],
                            [39.71439819, -5.20350024],
                            [39.71437265, -5.20362794],
                            [39.71423858, -5.20366625],
                            [39.71409812, -5.20363432],
                            [39.71369277, -5.20385588],
                            [39.71354906, -5.20393443],
                            [39.71298723, -5.20445162],
                            [39.71294859, -5.20448869],
                            [39.71267439, -5.20475173],
                            [39.71252755, -5.2047198],
                            [39.71224663, -5.20477727],
                            [39.71211163, -5.20495166],
                            [39.7120934, -5.20497521],
                            [39.71186995, -5.20507098],
                            [39.71139111, -5.20508375],
                            [39.71115489, -5.20509014],
                            [39.71088036, -5.20525615],
                            [39.7106569, -5.20572866],
                            [39.71040791, -5.2057542],
                            [39.70994184, -5.20584359],
                            [39.709629, -5.20574142],
                            [39.70918848, -5.20576696],
                            [39.70881179, -5.20580527],
                            [39.70816696, -5.20574142],
                            [39.70798182, -5.2057095],
                            [39.70760513, -5.20572865],
                            [39.70751575, -5.20572865],
                            [39.70726037, -5.20571588],
                            [39.706922, -5.2056648],
                            [39.70657085, -5.20571588],
                            [39.70656109, -5.2057289],
                            [39.70647509, -5.20584358],
                            [39.70642401, -5.20602237],
                            [39.70641067, -5.20618574],
                            [39.70639847, -5.20633524],
                            [39.70628994, -5.20639271],
                            [39.70624533, -5.20636943],
                            [39.70614309, -5.20631608],
                            [39.70613671, -5.20605429],
                            [39.70594518, -5.20603514],
                            [39.70596433, -5.20578612],
                            [39.70558765, -5.20498158],
                            [39.70524927, -5.20491135],
                            [39.70489434, -5.20455164],
                            [39.70477044, -5.20442607],
                            [39.70453421, -5.20431752],
                            [39.70409369, -5.20415789],
                            [39.7040375, -5.20401608],
                            [39.70395961, -5.20381948],
                            [39.70403623, -5.20345553],
                            [39.7041703, -5.20316819],
                            [39.70414601, -5.2030766],
                            [39.70408731, -5.20285532],
                            [39.70403393, -5.20245232],
                            [39.70393408, -5.20227427],
                            [39.70383831, -5.20234451],
                            [39.70367574, -5.20273292],
                            [39.70360847, -5.20289363],
                            [39.70353824, -5.20334698],
                            [39.70333394, -5.20360877],
                            [39.70326371, -5.20393441],
                            [39.70323817, -5.20415151],
                            [39.70300195, -5.20426644],
                            [39.70271465, -5.20434306],
                            [39.70253588, -5.20448992],
                            [39.70245288, -5.20447715],
                            [39.70236989, -5.20446438],
                            [39.70226135, -5.20455377],
                            [39.70223581, -5.20467509],
                            [39.70214643, -5.2047581],
                            [39.70201236, -5.20466871],
                            [39.70186503, -5.20476666],
                            [39.70179529, -5.2046687],
                            [39.70154629, -5.20463678],
                            [39.70122707, -5.20471979],
                            [39.700844, -5.20468147],
                            [39.70051839, -5.20470701],
                            [39.7003524, -5.20480279],
                            [39.69996933, -5.20491134],
                            [39.69950965, -5.2048411],
                            [39.69931173, -5.20493688],
                            [39.6992862, -5.20519229],
                            [39.69915851, -5.20513482],
                            [39.69890313, -5.20493049],
                            [39.69848176, -5.2049688],
                            [39.69811784, -5.20524975],
                            [39.69793908, -5.20528806],
                            [39.69765816, -5.20542853],
                            [39.69777308, -5.20556262],
                            [39.697722, -5.20567756],
                            [39.69745386, -5.20577333],
                            [39.69732617, -5.20599681],
                            [39.69707125, -5.20600897],
                            [39.69687287, -5.20580526],
                            [39.69668134, -5.20580526],
                            [39.69612276, -5.2059485],
                            [39.69593436, -5.20599681],
                            [39.69565344, -5.20628414],
                            [39.69535337, -5.20632884],
                            [39.69521292, -5.2061309],
                            [39.69511715, -5.20623945],
                            [39.69505968, -5.20653139],
                            [39.69496392, -5.20644377],
                            [39.69494477, -5.20627137],
                            [39.69455532, -5.20616282],
                            [39.69415948, -5.20604789],
                            [39.69380195, -5.20590742],
                            [39.69368134, -5.20607435],
                            [39.6934125, -5.20583718],
                            [39.69281875, -5.20546045],
                            [39.69239099, -5.20517312],
                            [39.69208454, -5.20500072],
                            [39.69175893, -5.20485386],
                            [39.69149078, -5.20441328],
                            [39.69126094, -5.20439413],
                            [39.69099918, -5.20408764],
                            [39.69080765, -5.20415788],
                            [39.69076934, -5.20395994],
                            [39.69080765, -5.20370453],
                            [39.69065442, -5.20362152],
                            [39.69057781, -5.20347466],
                            [39.69002875, -5.20279783],
                            [39.6888692, -5.20165457],
                            [39.68844541, -5.20127816],
                            [39.68863694, -5.20090144],
                            [39.68856671, -5.20071627],
                            [39.68831772, -5.20115046],
                            [39.68816449, -5.20153357],
                            [39.68788996, -5.2016932],
                            [39.68744305, -5.20217847],
                            [39.68705359, -5.2025105],
                            [39.68700252, -5.20290638],
                            [39.68686844, -5.20295107],
                            [39.68683014, -5.20313624],
                            [39.68674075, -5.20337888],
                            [39.68652072, -5.20366495],
                            [39.68648538, -5.20371091],
                            [39.68656199, -5.20383861],
                            [39.68668968, -5.20389608],
                            [39.68668329, -5.20410679],
                            [39.68662583, -5.20438135],
                            [39.68663856, -5.20440045],
                            [39.68675352, -5.20457291],
                            [39.68672798, -5.20480916],
                            [39.68688759, -5.20515396],
                            [39.6870089, -5.20527527],
                            [39.68693228, -5.20535828],
                            [39.68694224, -5.20563695],
                            [39.68694505, -5.20571585],
                            [39.68711743, -5.20596487],
                            [39.68723235, -5.20601595],
                            [39.68711105, -5.20628413],
                            [39.68683013, -5.20675025],
                            [39.68678592, -5.20698176],
                            [39.6867216, -5.20731853],
                            [39.68668967, -5.20772718],
                            [39.68671521, -5.20794428],
                            [39.68683651, -5.20802728],
                            [39.6869642, -5.20805282],
                            [39.68712381, -5.20798897],
                            [39.68728981, -5.20809113],
                            [39.68742388, -5.20824438],
                            [39.68737281, -5.20832739],
                            [39.68728981, -5.20848063],
                            [39.68723873, -5.20869134],
                            [39.68716212, -5.20857002],
                            [39.68718127, -5.20842316],
                            [39.68712614, -5.20829048],
                            [39.68697059, -5.2081933],
                            [39.68692626, -5.2083955],
                            [39.68685681, -5.20835261],
                            [39.68661944, -5.20820607],
                            [39.6863066, -5.2082763],
                            [39.68605761, -5.20837847],
                            [39.68578946, -5.20835292],
                            [39.68541278, -5.20833377],
                            [39.68522763, -5.20844232],
                            [39.68508717, -5.20876157],
                            [39.68487649, -5.20890205],
                            [39.68478072, -5.20890205],
                            [39.68462749, -5.20897867],
                            [39.68448185, -5.20904429],
                            [39.68434019, -5.2089659],
                            [39.68432742, -5.20882542],
                            [39.68418058, -5.2087935],
                            [39.68393797, -5.20881265],
                            [39.68383582, -5.20908722],
                            [39.68371452, -5.2091383],
                            [39.68336976, -5.20912553],
                            [39.68298031, -5.20908083],
                            [39.68261001, -5.20902975],
                            [39.68229717, -5.20892758],
                            [39.68206094, -5.20889566],
                            [39.68168426, -5.2087935],
                            [39.68160073, -5.20852692],
                            [39.68129394, -5.20836618],
                            [39.68108974, -5.20825919],
                            [39.68085428, -5.20813582],
                            [39.68082875, -5.20837207],
                            [39.68043929, -5.20862748],
                            [39.68041534, -5.2086541],
                            [39.68009453, -5.20901059],
                            [39.67987108, -5.20929792],
                            [39.67980723, -5.20962995],
                            [39.679903, -5.21010884],
                            [39.68005618, -5.21054163],
                            [39.68015838, -5.21083036],
                            [39.68007538, -5.21101553],
                            [39.68024137, -5.21125817],
                            [39.68055421, -5.21165405],
                            [39.68077128, -5.21166682],
                            [39.68101389, -5.21162212],
                            [39.68112243, -5.21153273],
                            [39.68106497, -5.21166044],
                            [39.6808479, -5.21182645],
                            [39.68075757, -5.21198131],
                            [39.68066913, -5.21213294],
                            [39.6804776, -5.21243304],
                            [39.68042014, -5.21229895],
                            [39.680356, -5.21199572],
                            [39.68034991, -5.21196692],
                            [39.67999692, -5.21191124],
                            [39.67980723, -5.21183283],
                            [39.6796157, -5.21183922],
                            [39.67939215, -5.21199572],
                            [39.67929647, -5.2120627],
                            [39.67902194, -5.21221594],
                            [39.67869634, -5.21203716],
                            [39.67845373, -5.21212016],
                            [39.67798128, -5.21226702],
                            [39.67763013, -5.21222871],
                            [39.67720258, -5.21213686],
                            [39.67713892, -5.21212319],
                            [39.67684484, -5.21201161],
                            [39.67681932, -5.21212212],
                            [39.67679347, -5.21211238],
                            [39.6765767, -5.21203077],
                            [39.67646438, -5.21189389],
                            [39.67640432, -5.21187114],
                            [39.67616809, -5.21182644],
                            [39.67612979, -5.21153911],
                            [39.67598933, -5.21153911],
                            [39.67589356, -5.21164127],
                            [39.6758361, -5.21185837],
                            [39.6757978, -5.21203077],
                            [39.67568926, -5.212101],
                            [39.67544027, -5.21212654],
                            [39.6749742, -5.21208185],
                            [39.67447406, -5.21203544],
                            [39.67408676, -5.21189668],
                            [39.67379946, -5.21189029],
                            [39.67358745, -5.21193873],
                            [39.67337809, -5.21186475],
                            [39.67312271, -5.21172428],
                            [39.67293756, -5.21173705],
                            [39.67284499, -5.21173066],
                            [39.67275241, -5.21172428],
                            [39.67280987, -5.21162211],
                            [39.6727588, -5.21132839],
                            [39.6723885, -5.21093251],
                            [39.67189051, -5.21067711],
                            [39.67165044, -5.21042777],
                            [39.67139253, -5.21015991],
                            [39.67117546, -5.21017268],
                            [39.67106692, -5.21007051],
                            [39.6708547, -5.20980975],
                            [39.67062001, -5.20952139],
                            [39.67042209, -5.209515],
                            [39.66984749, -5.20892757],
                            [39.66961026, -5.20865271],
                            [39.66946443, -5.20859554],
                            [39.66897282, -5.20803364],
                            [39.66883875, -5.2080528],
                            [39.66851953, -5.2081805],
                            [39.66831523, -5.20798256],
                            [39.66800877, -5.20797617],
                            [39.66763847, -5.20777823],
                            [39.66726321, -5.20782927],
                            [39.66629774, -5.20745259],
                            [39.66629774, -5.20729934],
                            [39.66612536, -5.20717164],
                            [39.66586279, -5.20734338],
                            [39.66571676, -5.20728657],
                            [39.66564653, -5.20705671],
                            [39.66503362, -5.20673106],
                            [39.66385888, -5.20586268],
                            [39.66332897, -5.20525609],
                            [39.6628246, -5.20436216],
                            [39.66279718, -5.20423323],
                            [39.66263307, -5.20346185],
                            [39.66260115, -5.20325114],
                            [39.66236493, -5.20303405],
                            [39.66178394, -5.20164846],
                            [39.66149065, -5.20098995],
                            [39.66123488, -5.20086947],
                            [39.66091661, -5.20090576],
                            [39.66089777, -5.20089694],
                            [39.66083905, -5.20086947],
                            [39.66084543, -5.2008567],
                            [39.66086659, -5.2008403],
                            [39.66110081, -5.20065876],
                            [39.66133065, -5.20032673],
                            [39.66149026, -5.20013518],
                            [39.66206486, -5.199005],
                            [39.66299699, -5.19745979],
                            [39.6634886, -5.19682766],
                            [39.66368652, -5.19637432],
                            [39.66373759, -5.19602313],
                            [39.66376951, -5.19564641],
                            [39.66378867, -5.19535269],
                            [39.66355244, -5.19467586],
                            [39.66294592, -5.19410758],
                            [39.66255836, -5.19385321],
                            [39.66192441, -5.19343714],
                            [39.66134343, -5.19282416],
                            [39.66097313, -5.19247298],
                            [39.66016869, -5.19175145],
                            [39.66025807, -5.19153435],
                            [39.6596324, -5.19086391],
                            [39.65842574, -5.18976566],
                            [39.65747446, -5.18869934],
                            [39.65689347, -5.18808636],
                            [39.6567484, -5.18763966],
                            [39.65654233, -5.18750531],
                            [39.65633164, -5.18707112],
                            [39.65608265, -5.18640706],
                            [39.65580812, -5.18635598],
                            [39.65568681, -5.18620912],
                            [39.65531013, -5.18585793],
                            [39.65501006, -5.18555144],
                            [39.65463163, -5.18546159],
                            [39.65398855, -5.18503424],
                            [39.65361187, -5.18518749],
                            [39.65345864, -5.18516195],
                            [39.65335011, -5.1849257],
                            [39.65294789, -5.18489377],
                            [39.65243337, -5.18481356],
                            [39.65221368, -5.18467029],
                            [39.65186892, -5.18468306],
                            [39.65176618, -5.18455462],
                            [39.65171569, -5.1844915],
                            [39.65149223, -5.18454897],
                            [39.65141562, -5.18444042],
                            [39.65106448, -5.18445319],
                            [39.65061756, -5.18452343],
                            [39.65041965, -5.18474052],
                            [39.65036219, -5.18495123],
                            [39.65017048, -5.18495035],
                            [39.64997576, -5.18482797],
                            [39.64992804, -5.18479799],
                            [39.64990251, -5.18490654],
                            [39.64979397, -5.18502147],
                            [39.64960882, -5.18504701],
                            [39.64935344, -5.18505339],
                            [39.64907253, -5.18508532],
                            [39.64893207, -5.18506616],
                            [39.648715, -5.18519387],
                            [39.64853623, -5.18516194],
                            [39.64843408, -5.18521941],
                            [39.64817781, -5.18532721],
                            [39.64796802, -5.18520025],
                            [39.64757218, -5.18555143],
                            [39.64741257, -5.18571106],
                            [39.6473998, -5.18588346],
                            [39.64730574, -5.18590698],
                            [39.64727211, -5.18591539],
                            [39.64713165, -5.18592177],
                            [39.64692097, -5.18599201],
                            [39.64679966, -5.18618995],
                            [39.64662728, -5.18638789],
                            [39.64678689, -5.18676461],
                            [39.64670389, -5.18676461],
                            [39.64658259, -5.18684124],
                            [39.64660174, -5.18702641],
                            [39.64698481, -5.1878054],
                            [39.64697842, -5.18794587],
                            [39.6471125, -5.1879778],
                            [39.64727211, -5.18785648],
                            [39.64732957, -5.18803526],
                            [39.64719549, -5.18868017],
                            [39.64725295, -5.18901219],
                            [39.64738064, -5.18904412],
                            [39.64754664, -5.18924206],
                            [39.6474381, -5.18959325],
                            [39.64759133, -5.18968264],
                            [39.64763602, -5.18976565],
                            [39.64758494, -5.18980396],
                            [39.64755941, -5.18993804],
                            [39.64742533, -5.19035947],
                            [39.64725295, -5.19137471],
                            [39.64727849, -5.19285607],
                            [39.64736148, -5.19367337],
                            [39.64745725, -5.19436297],
                            [39.64762324, -5.19442043],
                            [39.64757536, -5.19455771],
                            [39.6474764, -5.19477481],
                            [39.64738383, -5.1951643],
                            [39.6475977, -5.19548037],
                            [39.64748917, -5.19566554],
                            [39.64727529, -5.19609335],
                            [39.64709652, -5.19688191],
                            [39.64706141, -5.19752682],
                            [39.64703906, -5.19802167],
                            [39.6471061, -5.19842393],
                            [39.64704544, -5.19887728],
                            [39.64718909, -5.19902095],
                            [39.64710929, -5.19922846],
                            [39.64750832, -5.19986379],
                            [39.6476807, -5.2002054],
                            [39.64769666, -5.20034906],
                            [39.64787223, -5.2006779],
                            [39.64801907, -5.20074175],
                            [39.64817549, -5.20072898],
                            [39.64828083, -5.20084711],
                            [39.64822976, -5.20094288],
                            [39.64844044, -5.20121106],
                            [39.64873094, -5.20158459],
                            [39.64883628, -5.20171549],
                            [39.64890651, -5.20169314],
                            [39.64901824, -5.20178892],
                            [39.6494428, -5.20223269],
                            [39.64992163, -5.20285844],
                            [39.65022489, -5.20304999],
                            [39.65048666, -5.20327667],
                            [39.65058881, -5.20337244],
                            [39.65067181, -5.20336606],
                            [39.65073884, -5.20331178],
                            [39.65079949, -5.20336287],
                            [39.65094634, -5.2035193],
                            [39.65102614, -5.20363104],
                            [39.65118575, -5.20361189],
                            [39.65135813, -5.20372682],
                            [39.65160393, -5.20380025],
                            [39.65175716, -5.20397904],
                            [39.65187847, -5.20400138],
                            [39.65201254, -5.20392795],
                            [39.65215938, -5.20387368],
                            [39.65224876, -5.20394392],
                            [39.65242114, -5.20407481],
                            [39.65270844, -5.20433341],
                            [39.65281379, -5.2042951],
                            [39.65287444, -5.20437173],
                            [39.65299574, -5.20455051],
                            [39.65327985, -5.20478676],
                            [39.65348735, -5.20480592],
                            [39.65372676, -5.20476761],
                            [39.6539183, -5.20487296],
                            [39.65405556, -5.20503578],
                            [39.65422156, -5.20508367],
                            [39.65436202, -5.20506133],
                            [39.65444182, -5.20514114],
                            [39.65461739, -5.20522415],
                            [39.65479297, -5.20523692],
                            [39.65495896, -5.2053295],
                            [39.65512496, -5.20535824],
                            [39.65536757, -5.20535185],
                            [39.6555176, -5.20529758],
                            [39.65566763, -5.20522415],
                            [39.65591663, -5.20499428],
                            [39.65604113, -5.20489531],
                            [39.656255, -5.20488574],
                            [39.65644015, -5.20497832],
                            [39.65642738, -5.20516988],
                            [39.65652315, -5.20530077],
                            [39.65659657, -5.20538697],
                            [39.65671788, -5.20546679],
                            [39.65683918, -5.20550829],
                            [39.6569541, -5.20548594],
                            [39.65702433, -5.20546998],
                            [39.65716479, -5.20551787],
                            [39.65739463, -5.20554341],
                            [39.65760531, -5.20561365],
                            [39.65774258, -5.20577008],
                            [39.65778089, -5.20588502],
                            [39.65769789, -5.20597122],
                            [39.6576947, -5.20605422],
                            [39.6577362, -5.20615639],
                            [39.65778727, -5.20616596],
                            [39.65797242, -5.20618831],
                            [39.65807457, -5.20635433],
                            [39.65819268, -5.20646926],
                            [39.6583491, -5.20645968],
                            [39.65846083, -5.20641499],
                            [39.65853106, -5.20634475],
                            [39.65865556, -5.20632879],
                            [39.65891093, -5.20640541],
                            [39.65923016, -5.20651077],
                            [39.65942488, -5.20657462],
                            [39.65963876, -5.2066704],
                            [39.65990691, -5.2067981],
                            [39.66013675, -5.20693219],
                            [39.66047193, -5.20705989],
                            [39.66082946, -5.20736319],
                            [39.66145833, -5.20781015],
                            [39.66190843, -5.20810706],
                            [39.66231065, -5.20835928],
                            [39.66264583, -5.20853168],
                            [39.66279268, -5.20859872],
                            [39.66294909, -5.20871046],
                            [39.6631534, -5.2087807],
                            [39.66330662, -5.20884136],
                            [39.66345666, -5.20894033],
                            [39.66355242, -5.20903611],
                            [39.66363223, -5.20916381],
                            [39.66379823, -5.20921489],
                            [39.66413341, -5.20935217],
                            [39.66441752, -5.20948626],
                            [39.66476228, -5.20965228],
                            [39.66496019, -5.20977679],
                            [39.66533688, -5.20993322],
                            [39.66571037, -5.21013436],
                            [39.66617643, -5.21038657],
                            [39.66645416, -5.21053982],
                            [39.66687553, -5.21075691],
                            [39.6674214, -5.21112406],
                            [39.66781723, -5.21130923],
                            [39.66846845, -5.21169873],
                            [39.66971661, -5.21255115],
                            [39.66985387, -5.21268524],
                            [39.67011883, -5.21289276],
                            [39.67077323, -5.21340676],
                            [39.67095519, -5.21355362],
                            [39.67121695, -5.2137771],
                            [39.67137975, -5.21391758],
                            [39.67159044, -5.21413148],
                            [39.67189051, -5.21440924],
                            [39.67198628, -5.21447628],
                            [39.67208204, -5.21454333],
                            [39.672264, -5.21470615],
                            [39.67233742, -5.21480512],
                            [39.67231188, -5.21483704],
                            [39.67217462, -5.21489451],
                            [39.67216185, -5.2149871],
                            [39.67225123, -5.21514672],
                            [39.6723055, -5.2152872],
                            [39.67233742, -5.21537021],
                            [39.67242361, -5.21546598],
                            [39.67251938, -5.21549152],
                            [39.67261833, -5.21563519],
                            [39.67270452, -5.21564796],
                            [39.67284817, -5.21564796],
                            [39.67301736, -5.21565754],
                            [39.67316101, -5.21564477],
                            [39.6732472, -5.21558092],
                            [39.67323443, -5.21549791],
                            [39.67325997, -5.21544364],
                            [39.67334935, -5.21544044],
                            [39.67341001, -5.21553941],
                            [39.67354089, -5.21560007],
                            [39.67364942, -5.21559369],
                            [39.67381861, -5.21562242],
                            [39.67395268, -5.21567989],
                            [39.6741091, -5.21573097],
                            [39.67422722, -5.21580121],
                            [39.6743581, -5.21583952],
                            [39.67445067, -5.21585867],
                            [39.67450175, -5.21592572],
                            [39.67461028, -5.21603427],
                            [39.67476032, -5.21603746],
                            [39.67487204, -5.21600553],
                            [39.67502846, -5.21591614],
                            [39.67516573, -5.21578844],
                            [39.67532534, -5.21574693],
                            [39.67544984, -5.21568308],
                            [39.67552645, -5.21557134],
                            [39.67557753, -5.21534148],
                            [39.67569883, -5.21535425],
                            [39.67575629, -5.21547237],
                            [39.67582971, -5.2154628],
                            [39.67590633, -5.21542448],
                            [39.67597655, -5.21556177],
                            [39.67597975, -5.21565754],
                            [39.67602444, -5.21569585],
                            [39.67617128, -5.21564158],
                            [39.67636281, -5.21547237],
                            [39.67646816, -5.21542129],
                            [39.676698, -5.21544364],
                            [39.67683207, -5.21536702],
                            [39.67691188, -5.21539895],
                            [39.67693741, -5.21551069],
                            [39.67702999, -5.21557135],
                            [39.67722791, -5.21553623],
                            [39.67740986, -5.21543726],
                            [39.67757586, -5.21531913],
                            [39.67770355, -5.2151627],
                            [39.67779612, -5.21501584],
                            [39.67792701, -5.2149009],
                            [39.67805469, -5.21482747],
                            [39.67815046, -5.21476043],
                            [39.67829092, -5.21484025],
                            [39.67837392, -5.21492644],
                            [39.67857822, -5.21500307],
                            [39.67881444, -5.21493283],
                            [39.67888786, -5.21484982],
                            [39.67893575, -5.2147317],
                            [39.67903152, -5.21454972],
                            [39.67913686, -5.21461357],
                            [39.67940501, -5.21464231],
                            [39.67956781, -5.21466785],
                            [39.67978807, -5.214687],
                            [39.67996364, -5.21471574],
                            [39.68012645, -5.21475724],
                            [39.68018072, -5.21464231],
                            [39.68034671, -5.21462315],
                            [39.68056378, -5.21461677],
                            [39.68064997, -5.21456888],
                            [39.68080958, -5.21456249],
                            [39.68102027, -5.21459123],
                            [39.68111285, -5.2144316],
                            [39.68124054, -5.21447949],
                            [39.68134588, -5.21466146],
                            [39.68162999, -5.21462635],
                            [39.68178321, -5.21455292],
                            [39.68194602, -5.21450822],
                            [39.68208967, -5.21452099],
                            [39.68235462, -5.2144731],
                            [39.682444, -5.21435498],
                            [39.68258127, -5.21444437],
                            [39.68269619, -5.21450822],
                            [39.68295795, -5.21449865],
                            [39.68311756, -5.2143869],
                            [39.6833474, -5.21445076],
                            [39.6834304, -5.2145593],
                            [39.6835932, -5.2145625],
                            [39.68378155, -5.21444118],
                            [39.68407842, -5.21420812],
                            [39.68425719, -5.21410596],
                            [39.68446468, -5.21414108],
                            [39.68489244, -5.21398464],
                            [39.68508717, -5.21390163],
                            [39.68525635, -5.21384417],
                            [39.68536489, -5.21377074],
                            [39.68545427, -5.21373881],
                            [39.68555004, -5.2137899],
                            [39.68565219, -5.21381224],
                            [39.68572561, -5.21380905],
                            [39.68578946, -5.21374201],
                            [39.68588203, -5.21366858],
                            [39.68599695, -5.21365261],
                            [39.68615975, -5.21365581],
                            [39.68630341, -5.21362069],
                            [39.68642152, -5.21354087],
                            [39.68662582, -5.21350576],
                            [39.6867567, -5.21350895],
                            [39.68681097, -5.21348022],
                            [39.68695781, -5.21347702],
                            [39.68703123, -5.21340359],
                            [39.68702485, -5.21328547],
                            [39.68707592, -5.21316096],
                            [39.68718446, -5.21311945],
                            [39.68732173, -5.21313861],
                            [39.68750049, -5.21315777],
                            [39.68777502, -5.21310988],
                            [39.68789313, -5.21304603],
                            [39.68797294, -5.21298217],
                            [39.68809744, -5.2131003],
                            [39.68820597, -5.21306199],
                            [39.68826662, -5.21292471],
                            [39.68844858, -5.21292471],
                            [39.68854754, -5.21296462],
                            [39.6886465, -5.21300452],
                            [39.68879334, -5.21302368],
                            [39.68890507, -5.21298537],
                            [39.68906468, -5.21299175],
                            [39.68920833, -5.21299814],
                            [39.68947967, -5.21297579],
                            [39.6896297, -5.21300453],
                            [39.68992977, -5.21299495],
                            [39.69005427, -5.21298856],
                            [39.69023942, -5.2131035],
                            [39.69038945, -5.21322162],
                            [39.6905363, -5.21321524],
                            [39.69067356, -5.21315777],
                            [39.69079487, -5.21303965],
                            [39.69098959, -5.2129311],
                            [39.69117474, -5.21295025],
                            [39.69131839, -5.21295025],
                            [39.69137266, -5.21300134],
                            [39.69130562, -5.21312265],
                            [39.69135989, -5.21323439],
                            [39.69146843, -5.21330144],
                            [39.69155781, -5.21324397],
                            [39.69158335, -5.21310031],
                            [39.69169188, -5.21319289],
                            [39.69176849, -5.21332059],
                            [39.69181957, -5.21335571],
                            [39.69199195, -5.21341318],
                            [39.69206856, -5.21331421],
                            [39.69216752, -5.21334294],
                            [39.69231117, -5.21334614],
                            [39.69241652, -5.21335252],
                            [39.69252824, -5.21325036],
                            [39.69261763, -5.21335252],
                            [39.69275489, -5.21339402],
                            [39.69293685, -5.21341318],
                            [39.69300389, -5.21333975],
                            [39.69307092, -5.21320566],
                            [39.69311561, -5.21307157],
                            [39.69324649, -5.21305561],
                            [39.69338057, -5.21327909],
                            [39.69349549, -5.2134036],
                            [39.69359445, -5.21345149],
                            [39.69372533, -5.21343234],
                            [39.69399348, -5.21341637],
                            [39.69424566, -5.21342276],
                            [39.69442443, -5.21340041],
                            [39.69442123, -5.21325036],
                            [39.69449465, -5.21297261],
                            [39.69457127, -5.21286406],
                            [39.69457127, -5.21275232],
                            [39.69469896, -5.21264377],
                            [39.69484261, -5.21272997],
                            [39.69477238, -5.21296622],
                            [39.69480111, -5.21311308],
                            [39.69492241, -5.21314181],
                            [39.69504372, -5.21309712],
                            [39.69512991, -5.2131035],
                            [39.69507245, -5.21324078],
                            [39.69514268, -5.21335252],
                            [39.69544594, -5.21347065],
                            [39.69555128, -5.21351854],
                            [39.69564705, -5.21351215],
                            [39.69574601, -5.21348981],
                            [39.69589285, -5.21353131],
                            [39.69598862, -5.21354089],
                            [39.69606204, -5.21348342],
                            [39.69619292, -5.21351854],
                            [39.69626315, -5.21361751],
                            [39.69632061, -5.21358878],
                            [39.69653768, -5.21350258],
                            [39.69661748, -5.21359197],
                            [39.69675475, -5.21372606],
                            [39.69692394, -5.2136654],
                            [39.69708993, -5.21359516],
                            [39.69727827, -5.2135377],
                            [39.69748577, -5.21354728],
                            [39.69761027, -5.21365582],
                            [39.69773795, -5.21366221],
                            [39.69785287, -5.21372925],
                            [39.69796779, -5.21380588],
                            [39.69808271, -5.21379311],
                            [39.69827744, -5.21381226],
                            [39.69833809, -5.21387611],
                            [39.69855197, -5.21392081],
                            [39.69882012, -5.21393358],
                            [39.69909465, -5.21395593],
                            [39.69918084, -5.2138378],
                            [39.69928938, -5.21366221],
                            [39.69947772, -5.21360155],
                            [39.69957668, -5.21366221],
                            [39.69953837, -5.21383461],
                            [39.69967563, -5.21379311],
                            [39.69982567, -5.21371649],
                            [39.6999757, -5.21358878],
                            [39.70015766, -5.21340681],
                            [39.70034281, -5.21344512],
                            [39.70035239, -5.21384419],
                            [39.7003045, -5.21440928],
                            [39.70030769, -5.2145274],
                            [39.70040665, -5.2145721],
                            [39.7006333, -5.21456891],
                            [39.70073545, -5.21464872],
                            [39.70082803, -5.21489136],
                            [39.70101637, -5.21501587],
                            [39.70132921, -5.21516273],
                            [39.70156862, -5.21531917],
                            [39.70176973, -5.21552668],
                            [39.70207619, -5.21574378],
                            [39.70221664, -5.21587148],
                            [39.70236668, -5.21605985],
                            [39.70253906, -5.21609816],
                            [39.7027274, -5.2159098],
                            [39.70295724, -5.21581083],
                            [39.70308812, -5.21574059],
                            [39.70321581, -5.21578209],
                            [39.70323177, -5.21583158],
                            [39.70324773, -5.21588106],
                            [39.70314877, -5.21603431],
                            [39.70286147, -5.21613328],
                            [39.70270186, -5.21622586],
                            [39.70274017, -5.21630568],
                            [39.70282636, -5.21641422],
                            [39.70297639, -5.21654193],
                            [39.70311685, -5.21656108],
                            [39.70324773, -5.21651958],
                            [39.70338819, -5.21647169],
                            [39.70348396, -5.21635357],
                            [39.7036308, -5.21627694],
                            [39.70379679, -5.21634718],
                            [39.70386383, -5.2165132],
                            [39.70378083, -5.21656747],
                            [39.70349672, -5.21680053],
                            [39.70344884, -5.21690588],
                            [39.70351268, -5.21692823],
                            [39.70358291, -5.21690908],
                            [39.70364995, -5.2170272],
                            [39.70377764, -5.21708786],
                            [39.70389894, -5.21716448],
                            [39.70397556, -5.21729219],
                            [39.70407132, -5.21727622],
                            [39.70410005, -5.21739116],
                            [39.70407132, -5.21753802],
                            [39.70402344, -5.21771999],
                            [39.70408728, -5.2177615],
                            [39.7042469, -5.21779023],
                            [39.70434905, -5.21778384],
                            [39.70447354, -5.21779662],
                            [39.70455973, -5.21775192],
                            [39.70462358, -5.2177168],
                            [39.70462677, -5.21783493],
                            [39.70456931, -5.21792113],
                            [39.70459485, -5.2181031],
                            [39.70469061, -5.2181478],
                            [39.70476404, -5.21820207],
                            [39.70474169, -5.2182755],
                            [39.70463635, -5.21832978],
                            [39.70463954, -5.21841917],
                            [39.70470977, -5.21846387],
                            [39.70480234, -5.21843194],
                            [39.70489492, -5.21833936],
                            [39.70501303, -5.21830104],
                            [39.7050641, -5.21844471],
                            [39.70516625, -5.21871289],
                            [39.7051886, -5.21916623],
                            [39.70527798, -5.21932906],
                            [39.70530671, -5.21954935],
                            [39.70535779, -5.21959723],
                            [39.70545036, -5.21964193],
                            [39.70550144, -5.21976006],
                            [39.70548867, -5.2198686],
                            [39.70541525, -5.21992607],
                            [39.70536417, -5.21999311],
                            [39.70543759, -5.21999311],
                            [39.70560359, -5.21998992],
                            [39.7057217, -5.21999631],
                            [39.70576958, -5.22006016],
                            [39.70582385, -5.2201719],
                            [39.70587812, -5.22024214],
                            [39.70609519, -5.22047839],
                            [39.70620692, -5.22067952],
                            [39.7062963, -5.22075614],
                            [39.70652614, -5.22093493],
                            [39.70662829, -5.22117437],
                            [39.70671129, -5.22128292],
                            [39.70682302, -5.22135316],
                            [39.70703689, -5.2215064],
                            [39.70719651, -5.22165326],
                            [39.70725397, -5.22180012],
                            [39.70728908, -5.22194379],
                            [39.70734654, -5.22204276],
                            [39.70742315, -5.22208107],
                            [39.70753807, -5.22211938],
                            [39.70754765, -5.22222793],
                            [39.7076083, -5.22226943],
                            [39.7077328, -5.22233967],
                            [39.70788922, -5.22233328],
                            [39.70796902, -5.2222822],
                            [39.70805202, -5.22231093],
                            [39.70802968, -5.22238117],
                            [39.70805521, -5.2224131],
                            [39.70817971, -5.22245779],
                            [39.70830101, -5.22239394],
                            [39.70840955, -5.22243225],
                            [39.70844147, -5.22256315],
                            [39.70849893, -5.22262381],
                            [39.70864577, -5.22246099],
                            [39.70881177, -5.22233328],
                            [39.70885008, -5.22232371],
                            [39.70894106, -5.22238596],
                            [39.70903203, -5.22244822],
                            [39.70894265, -5.22266212],
                            [39.7088405, -5.22277705],
                            [39.70870643, -5.22305481],
                            [39.70878942, -5.2231857],
                            [39.70926826, -5.22354966],
                            [39.70963536, -5.22384976],
                            [39.70977582, -5.22395193],
                            [39.70994182, -5.22404451],
                            [39.7100695, -5.22403174],
                            [39.71017485, -5.22401897],
                            [39.71027381, -5.22403493],
                            [39.71033446, -5.22414667],
                            [39.71043661, -5.22417541],
                            [39.71060261, -5.22408282],
                            [39.7107271, -5.22421691],
                            [39.71094417, -5.2242648],
                            [39.7110144, -5.22425842],
                            [39.71112294, -5.22419456],
                            [39.71122509, -5.224351],
                            [39.71147408, -5.22454575],
                            [39.71178373, -5.22466068],
                            [39.71196569, -5.22477881],
                            [39.71215403, -5.22480754],
                            [39.71224022, -5.22492886],
                            [39.71254348, -5.2250406],
                            [39.71268074, -5.22525131],
                            [39.71283078, -5.22530239],
                            [39.7130255, -5.22540136],
                            [39.71314362, -5.22548756],
                            [39.71330961, -5.22550672],
                            [39.71343092, -5.22549076],
                            [39.71346603, -5.22540456],
                            [39.71351391, -5.2253439],
                            [39.7135618, -5.22536944],
                            [39.7135618, -5.22545883],
                            [39.71378206, -5.22580682],
                            [39.71390656, -5.22592814],
                            [39.71401828, -5.22597922],
                            [39.7141779, -5.22597603],
                            [39.71427685, -5.22593453],
                            [39.71435028, -5.22595368],
                            [39.7144205, -5.22599199],
                            [39.71451946, -5.22607181],
                            [39.71458012, -5.2261165],
                            [39.71461842, -5.22605904],
                            [39.71465673, -5.22597284],
                            [39.71473653, -5.22596326],
                            [39.7148323, -5.22600796],
                            [39.71493764, -5.22601434],
                            [39.71500787, -5.22599199],
                            [39.71508129, -5.22600796],
                            [39.7151611, -5.22607819],
                            [39.71519302, -5.22617397],
                            [39.7153239, -5.22628571],
                            [39.71543882, -5.22634318],
                            [39.71542925, -5.22645173],
                            [39.71545478, -5.22653793],
                            [39.7154899, -5.22655708],
                            [39.71552821, -5.22653154],
                            [39.71559205, -5.22651558],
                            [39.7156527, -5.22662093],
                            [39.71571016, -5.22672948],
                            [39.71578997, -5.22691784],
                            [39.71594319, -5.22708386],
                            [39.71608365, -5.2271541],
                            [39.71614111, -5.22717006],
                            [39.71616984, -5.2271094],
                            [39.71621134, -5.2270647],
                            [39.71625603, -5.22712536],
                            [39.71627199, -5.22724668],
                            [39.71631988, -5.22733288],
                            [39.71633584, -5.22743185],
                            [39.71630711, -5.22750209],
                            [39.71630072, -5.22761064],
                            [39.71632945, -5.2276713],
                            [39.71639968, -5.22763937],
                            [39.71651141, -5.22753721],
                            [39.71658164, -5.22748932],
                            [39.71670614, -5.22748932],
                            [39.71687532, -5.22750847],
                            [39.71703813, -5.22754679],
                            [39.71712112, -5.22756913],
                            [39.71735096, -5.22748613],
                            [39.71746588, -5.22742228],
                            [39.71753931, -5.22734246],
                            [39.71766387, -5.2272868],
                            [39.71768934, -5.22727542],
                            [39.71782022, -5.22728499],
                            [39.71800218, -5.22733608],
                            [39.71812029, -5.22735842],
                            [39.71820329, -5.22750209],
                            [39.71820201, -5.22752861],
                            [39.71819377, -5.22769996],
                            [39.71801687, -5.22756821],
                            [39.71790207, -5.22748431],
                            [39.71767248, -5.22743574],
                            [39.71753561, -5.22753288],
                            [39.71740316, -5.22763444],
                            [39.71720889, -5.22770951],
                            [39.7170102, -5.22761237],
                            [39.7167762, -5.22760353],
                            [39.71669673, -5.22769626],
                            [39.71668789, -5.22780666],
                            [39.7166614, -5.22786848],
                            [39.71651129, -5.22788614],
                            [39.71635676, -5.22784198],
                            [39.7162817, -5.2278199],
                            [39.71621547, -5.22792588],
                            [39.7162817, -5.22802302],
                            [39.71636559, -5.22810692],
                            [39.71644064, -5.22823056],
                            [39.71648038, -5.22836745],
                            [39.71658634, -5.22848667],
                            [39.71667023, -5.22849109],
                            [39.71670555, -5.22862356],
                            [39.71679827, -5.2287472],
                            [39.7169528, -5.22891941],
                            [39.71701903, -5.22888408],
                            [39.71704994, -5.22895473],
                            [39.71704552, -5.22908279],
                            [39.71703669, -5.2291711],
                            [39.71699254, -5.22922851],
                            [39.71711175, -5.22944929],
                            [39.71727953, -5.22964358],
                            [39.71737666, -5.22978488],
                            [39.71742964, -5.22985554],
                            [39.71744289, -5.23004099],
                            [39.71751353, -5.23015139],
                            [39.71754444, -5.23024412],
                            [39.71771663, -5.23061945],
                            [39.71780052, -5.23066802],
                            [39.7177652, -5.2307166],
                            [39.71765482, -5.23072543],
                            [39.71761067, -5.23090205],
                            [39.71761508, -5.23104336],
                            [39.71771663, -5.23121115],
                            [39.71782259, -5.2313922],
                            [39.71783142, -5.23146726],
                            [39.71782259, -5.23154675],
                            [39.71789765, -5.23155999],
                            [39.71795063, -5.23156882],
                            [39.71793297, -5.23162181],
                            [39.71780935, -5.23166597],
                            [39.71776078, -5.23171454],
                            [39.7177652, -5.23185143],
                            [39.71788441, -5.23193533],
                            [39.71802128, -5.23195299],
                            [39.71809634, -5.23186468],
                            [39.71823321, -5.23172779],
                            [39.71837449, -5.23169688],
                            [39.71846721, -5.23158207],
                            [39.71847818, -5.23146832],
                            [39.71847604, -5.23119349],
                            [39.71844514, -5.23110076],
                            [39.71856876, -5.23102128],
                            [39.71867914, -5.2309418],
                            [39.7186085, -5.23074309],
                            [39.71848487, -5.23065919],
                            [39.71842748, -5.23064595],
                            [39.71847163, -5.23053114],
                            [39.71865707, -5.23039867],
                            [39.71879835, -5.23030594],
                            [39.71886458, -5.23029269],
                            [39.71892639, -5.23030594],
                            [39.71900145, -5.23040308],
                            [39.71913391, -5.23046932],
                            [39.71927078, -5.23048257],
                            [39.71933259, -5.2304649],
                            [39.71933762, -5.23038574],
                            [39.71924429, -5.23030152],
                            [39.71924821, -5.23024379],
                            [39.71937674, -5.23019996],
                            [39.71949154, -5.23010282],
                            [39.71954011, -5.22997918],
                            [39.71965049, -5.22982463],
                            [39.7199154, -5.22945371],
                            [39.72004785, -5.22932124],
                            [39.72018472, -5.22918877],
                            [39.72034367, -5.22909163],
                            [39.7205556, -5.22901656],
                            [39.72068806, -5.22902098],
                            [39.72079402, -5.22904305],
                            [39.7209044, -5.22913578],
                            [39.72091765, -5.22924176],
                            [39.72093972, -5.22942722],
                            [39.72093531, -5.22951112],
                            [39.72091765, -5.22960385],
                            [39.72097946, -5.22974073],
                            [39.72113841, -5.22970982],
                            [39.72123112, -5.22961709],
                            [39.72126203, -5.229648],
                            [39.72126203, -5.22974073],
                            [39.72126645, -5.22985996],
                            [39.72138124, -5.2299836],
                            [39.72150928, -5.23015139],
                            [39.72156668, -5.23019555],
                            [39.72167706, -5.23014698],
                            [39.72173887, -5.23016464],
                            [39.72173887, -5.23025737],
                            [39.72173887, -5.23032802],
                            [39.72181393, -5.23043841],
                            [39.72206118, -5.23064154],
                            [39.72217156, -5.23072543],
                            [39.72226428, -5.23068569],
                            [39.72233934, -5.23072985],
                            [39.72236583, -5.23081375],
                            [39.72244972, -5.23097713],
                            [39.72251153, -5.23103895],
                            [39.72252919, -5.23111843],
                            [39.72260425, -5.2311096],
                            [39.72263515, -5.23114492],
                            [39.72262632, -5.23120233],
                            [39.72263515, -5.23125532],
                            [39.72266164, -5.23133038],
                            [39.72271463, -5.23136129],
                            [39.72281618, -5.23140987],
                            [39.7228824, -5.23141428],
                            [39.72296629, -5.23143194],
                            [39.72296629, -5.23151584],
                            [39.72309433, -5.23158649],
                            [39.72317381, -5.23163948],
                            [39.72323562, -5.23162182],
                            [39.72320913, -5.23170572],
                            [39.72322679, -5.23173221],
                            [39.72331068, -5.2317278],
                            [39.72341664, -5.23169689],
                            [39.72338132, -5.23174987],
                            [39.72322237, -5.23187351],
                            [39.72314731, -5.23205456],
                            [39.72312524, -5.23216936],
                            [39.72321354, -5.23231067],
                            [39.72330626, -5.23243431],
                            [39.72344755, -5.23242989],
                            [39.72350494, -5.23243872],
                            [39.72342106, -5.23249613],
                            [39.72344755, -5.23264626],
                            [39.72350936, -5.23273899],
                            [39.72350053, -5.23278315],
                            [39.72351377, -5.23287146],
                            [39.72356234, -5.23295977],
                            [39.72369038, -5.2329377],
                            [39.72376102, -5.23288029],
                            [39.72382725, -5.23287588],
                            [39.72392439, -5.23287146],
                            [39.7240436, -5.23290237],
                            [39.72421137, -5.23291562],
                            [39.72432175, -5.23298185],
                            [39.72443655, -5.23309225],
                            [39.724569, -5.23318056],
                            [39.7246838, -5.23323355],
                            [39.72481184, -5.23326004],
                            [39.72505909, -5.23335719],
                            [39.72518272, -5.23342784],
                            [39.72535932, -5.23355148],
                            [39.72559774, -5.23344109],
                            [39.72585824, -5.23343667],
                            [39.72611874, -5.2335294],
                            [39.72626444, -5.23358681],
                            [39.72658233, -5.2337546],
                            [39.72677218, -5.23386058],
                            [39.72717397, -5.23393123],
                            [39.72741239, -5.23402396],
                            [39.72749628, -5.23405487],
                            [39.7274742, -5.23432864],
                            [39.72747861, -5.23454943],
                            [39.72756692, -5.23455826],
                            [39.72768613, -5.23384733],
                            [39.72784508, -5.23386941],
                            [39.72798195, -5.23385616],
                            [39.72810557, -5.23385175],
                            [39.72829543, -5.23384292],
                            [39.72844554, -5.23384292],
                            [39.72853385, -5.23385175],
                            [39.72866189, -5.23387824],
                            [39.72877668, -5.23387824],
                            [39.72890472, -5.23388708],
                            [39.72909457, -5.23391357],
                            [39.72923145, -5.23397097],
                            [39.72941247, -5.23404604],
                            [39.72951843, -5.23410345],
                            [39.72969504, -5.23424033],
                            [39.72979659, -5.23435956],
                            [39.72985399, -5.2344832],
                            [39.72986282, -5.23457592],
                            [39.72995554, -5.23478788],
                            [39.73018954, -5.23492035],
                            [39.73043238, -5.23495567],
                            [39.73056925, -5.23496009],
                            [39.73071936, -5.23499542],
                            [39.73088273, -5.23503074],
                            [39.73104167, -5.23507048],
                            [39.73123594, -5.23525153],
                            [39.73135074, -5.23533543],
                            [39.73154942, -5.23555179],
                            [39.73164655, -5.23564894],
                            [39.73175252, -5.23582115],
                            [39.73185848, -5.23599336],
                            [39.73197769, -5.23616116],
                            [39.73207924, -5.23624506],
                            [39.73222494, -5.23627155],
                            [39.73233532, -5.23627597],
                            [39.73241921, -5.23624947],
                            [39.7325031, -5.23620532],
                            [39.73258257, -5.2361435],
                            [39.7326488, -5.23609934],
                            [39.73268854, -5.23607285],
                            [39.73272827, -5.23613908],
                            [39.73280333, -5.23613908],
                            [39.73289164, -5.23609493],
                            [39.7331124, -5.23596687],
                            [39.73323161, -5.2358344],
                            [39.73324485, -5.23570193],
                            [39.7333155, -5.2353001],
                            [39.73316979, -5.23515438],
                            [39.73312725, -5.23505897],
                            [39.73313889, -5.23488503],
                            [39.73323602, -5.2348497],
                            [39.73328901, -5.23483204],
                            [39.73335523, -5.23483646],
                            [39.73345678, -5.23488944],
                            [39.73358924, -5.2348762],
                            [39.73365547, -5.23480555],
                            [39.73381883, -5.23447879],
                            [39.73374614, -5.23432768],
                            [39.73358482, -5.23421826],
                            [39.73345678, -5.23415644],
                            [39.73331108, -5.23415202],
                            [39.73326252, -5.23419618],
                            [39.73319187, -5.23413878],
                            [39.73316762, -5.23408648],
                            [39.73318746, -5.23404605],
                            [39.73330225, -5.23400189],
                            [39.73350094, -5.23400631],
                            [39.73370845, -5.23403722],
                            [39.73386298, -5.2340328],
                            [39.73395129, -5.23389591],
                            [39.73396895, -5.23364422],
                            [39.73397998, -5.23358682],
                            [39.73399102, -5.23352941],
                            [39.73408816, -5.23334837],
                            [39.73418088, -5.23323356],
                            [39.73431333, -5.23293771],
                            [39.73437515, -5.23278316],
                            [39.73447228, -5.23266394],
                            [39.73454734, -5.23269485],
                            [39.73463564, -5.23273017],
                            [39.73474602, -5.23273901],
                            [39.73483874, -5.23283615],
                            [39.73502859, -5.23311434],
                            [39.73516105, -5.2332733],
                            [39.7352935, -5.23332629],
                            [39.73543038, -5.23323356],
                            [39.7355005, -5.23318379],
                            [39.73556725, -5.23313642],
                            [39.7356114, -5.23297304],
                            [39.73570412, -5.23244316],
                            [39.73580125, -5.23216497],
                            [39.73581472, -5.23198986],
                            [39.73581891, -5.23193535],
                            [39.73580125, -5.23151145],
                            [39.73578359, -5.23137456],
                            [39.73582775, -5.23114494],
                            [39.73585865, -5.23106105],
                            [39.73594254, -5.23105663],
                            [39.73599111, -5.23114053],
                            [39.73603967, -5.231273],
                            [39.73609266, -5.23130832],
                            [39.73608383, -5.23138781],
                            [39.73602643, -5.23148937],
                            [39.73616771, -5.23170132],
                            [39.73618231, -5.2317326],
                            [39.73626043, -5.23190003],
                            [39.73633549, -5.23236367],
                            [39.73637081, -5.23247848],
                            [39.73622511, -5.23249614],
                            [39.73614564, -5.23255355],
                            [39.73602643, -5.23265069],
                            [39.73595578, -5.23273459],
                            [39.73594695, -5.23288031],
                            [39.73600877, -5.23319824],
                            [39.73609707, -5.23341461],
                            [39.73628692, -5.23345876],
                            [39.73634041, -5.23348933],
                            [39.73637964, -5.23351175],
                            [39.73655183, -5.23346318],
                            [39.73664897, -5.23337045],
                            [39.73671078, -5.23330422],
                            [39.73672403, -5.23319382],
                            [39.73673727, -5.23310551],
                            [39.73682558, -5.23292888],
                            [39.73694037, -5.23279641],
                            [39.73700218, -5.23274342],
                            [39.73706841, -5.23274342],
                            [39.73709932, -5.23286265],
                            [39.73716996, -5.23292447],
                            [39.73728476, -5.23295538],
                            [39.73733332, -5.23296421],
                            [39.73733774, -5.2329907],
                            [39.7372671, -5.23306577],
                            [39.73722294, -5.2331011],
                            [39.73724502, -5.23318058],
                            [39.73722736, -5.23320266],
                            [39.73716113, -5.2332424],
                            [39.73705517, -5.23329539],
                            [39.73693154, -5.23341019],
                            [39.73683882, -5.23349409],
                            [39.73686531, -5.23362215],
                            [39.73691388, -5.23365747],
                            [39.73682116, -5.23373695],
                            [39.73668429, -5.23377669],
                            [39.73652534, -5.23382527],
                            [39.73635427, -5.23385855],
                            [39.73629681, -5.23397029],
                            [39.73628404, -5.23406288],
                            [39.73625531, -5.23413631],
                            [39.736217, -5.23421931],
                            [39.73614678, -5.23424485],
                            [39.73602866, -5.23424166],
                            [39.73583075, -5.23431509],
                            [39.73567433, -5.23435979],
                            [39.73556579, -5.2344811],
                            [39.73555302, -5.23456411],
                            [39.73553706, -5.23466947],
                            [39.73546364, -5.23477482],
                            [39.73547641, -5.23487379],
                            [39.73547641, -5.23490572],
                            [39.73540299, -5.23506216],
                            [39.73541256, -5.23520263],
                            [39.73547321, -5.23531437],
                            [39.73550833, -5.23538461],
                            [39.73549875, -5.23545165],
                            [39.73542214, -5.23552189],
                            [39.73535191, -5.2356879],
                            [39.73531679, -5.2358156],
                            [39.73527849, -5.23589861],
                            [39.73521464, -5.23599439],
                            [39.73521784, -5.23609655],
                            [39.73518911, -5.23623383],
                            [39.73517953, -5.23626895],
                            [39.73514122, -5.23639665],
                            [39.73510611, -5.23652755],
                            [39.73508695, -5.23658502],
                            [39.73505184, -5.23664568],
                            [39.73506461, -5.23676699],
                            [39.73502311, -5.2368947],
                            [39.73504545, -5.23700325],
                            [39.73512526, -5.23710222],
                            [39.73521783, -5.23717245],
                            [39.73525614, -5.23719799],
                            [39.73536787, -5.23718203],
                            [39.73545087, -5.23712776],
                            [39.73552748, -5.23712456],
                            [39.7355594, -5.23704794],
                            [39.73555621, -5.23697132],
                            [39.73559132, -5.23691066],
                            [39.73566794, -5.23678934],
                            [39.73577967, -5.23677977],
                            [39.7358499, -5.23674465],
                            [39.73595843, -5.23665206],
                            [39.73606377, -5.23646051],
                            [39.73616273, -5.23635196],
                            [39.73622977, -5.23619872],
                            [39.73630638, -5.23613806],
                            [39.73643088, -5.23611252],
                            [39.73653303, -5.23611571],
                            [39.73656815, -5.2361636],
                            [39.73657134, -5.23625938],
                            [39.73657453, -5.23633919],
                            [39.73660965, -5.23643497],
                            [39.73669264, -5.23646689],
                            [39.73674691, -5.23646689],
                            [39.73686183, -5.23641262],
                            [39.7371204, -5.23622426],
                            [39.73723851, -5.23615083],
                            [39.7373247, -5.2361636],
                            [39.73741089, -5.23616998],
                            [39.73746197, -5.23610613],
                            [39.73757051, -5.23591139],
                            [39.73767904, -5.23580284],
                            [39.73776204, -5.23579645],
                            [39.73788334, -5.23576453],
                            [39.73795676, -5.23573899],
                            [39.73808445, -5.23573579],
                            [39.73813553, -5.23571983],
                            [39.73819618, -5.23582519],
                            [39.73826003, -5.23591139],
                            [39.73835898, -5.23592096],
                            [39.73852179, -5.23591458],
                            [39.73870375, -5.23590181],
                            [39.73888889, -5.23589223],
                            [39.73898785, -5.23587308],
                            [39.73910277, -5.23582838],
                            [39.73924962, -5.23586031],
                            [39.73933261, -5.23580923],
                            [39.73939007, -5.23579007],
                            [39.73951457, -5.2357773],
                            [39.73965184, -5.2357773],
                            [39.73974122, -5.2357773],
                            [39.73985295, -5.23579007],
                            [39.7399551, -5.23580923],
                            [39.74008279, -5.235822],
                            [39.74020728, -5.23586669],
                            [39.74035093, -5.23589543],
                            [39.74047224, -5.23592097],
                            [39.74058716, -5.23591778],
                            [39.740651, -5.23588904],
                            [39.74074358, -5.23592735],
                            [39.74083615, -5.23593055],
                            [39.74094149, -5.23593374],
                            [39.74105642, -5.23595928],
                            [39.74123199, -5.23602952],
                            [39.74149375, -5.23606464],
                            [39.74161825, -5.23602952],
                            [39.74178424, -5.23601036],
                            [39.74190235, -5.23605506],
                            [39.74205239, -5.23612849],
                            [39.74217689, -5.23610933],
                            [39.74241949, -5.23605187],
                            [39.74259188, -5.23601356],
                            [39.7427036, -5.23596886],
                            [39.74271637, -5.23583796],
                            [39.74277702, -5.23581242],
                            [39.74288237, -5.23591139],
                            [39.74294621, -5.2359944],
                            [39.7430324, -5.23600398],
                            [39.74312498, -5.23606783],
                            [39.74324947, -5.23614445],
                            [39.74330693, -5.23609976],
                            [39.74340908, -5.23611572],
                            [39.74347931, -5.23615403],
                            [39.74349208, -5.2362083],
                            [39.7435687, -5.23622427],
                            [39.74365489, -5.23624342],
                            [39.74372511, -5.236253],
                            [39.7438113, -5.23624981],
                            [39.74386238, -5.23616361],
                            [39.74388792, -5.23606783],
                            [39.74392942, -5.23596886],
                            [39.74407945, -5.23562406],
                            [39.74406668, -5.23549636],
                            [39.74396772, -5.23551232],
                            [39.74386877, -5.23553467],
                            [39.74382727, -5.23553467],
                            [39.74380492, -5.23544208],
                            [39.74384642, -5.23539739],
                            [39.7440188, -5.23532396],
                            [39.74412414, -5.23518987],
                            [39.74421033, -5.23508132],
                            [39.74430291, -5.23509729],
                            [39.74435398, -5.23519945],
                            [39.74437633, -5.23527926],
                            [39.74438271, -5.2353942],
                            [39.74436356, -5.23543251],
                            [39.74433802, -5.23553467],
                            [39.74428695, -5.2356528],
                            [39.74429333, -5.23574857],
                            [39.74432845, -5.23580923],
                            [39.74440187, -5.23582839],
                            [39.74442421, -5.23582839],
                            [39.74437952, -5.23589543],
                            [39.74434441, -5.23593055],
                            [39.74429972, -5.23598482],
                            [39.74428056, -5.23601994],
                            [39.74427418, -5.23613807],
                            [39.74431568, -5.23617957],
                            [39.74442102, -5.23618596],
                            [39.74449444, -5.23618596],
                            [39.74457425, -5.23618596],
                            [39.74467321, -5.23618596],
                            [39.74484878, -5.23618915],
                            [39.74489028, -5.23620511],
                            [39.74497966, -5.23622746],
                            [39.74505308, -5.23615723],
                            [39.74511054, -5.23601675],
                            [39.74511373, -5.23592736],
                            [39.74519035, -5.23557298],
                            [39.74525419, -5.23544847],
                            [39.74532761, -5.23536227],
                            [39.74554468, -5.23514837],
                            [39.74561172, -5.23499513],
                            [39.74563726, -5.23481953],
                            [39.74562768, -5.23451624],
                            [39.74568036, -5.23425445],
                            [39.74573303, -5.23399265],
                            [39.74580964, -5.23391922],
                            [39.74603948, -5.23397669],
                            [39.74598528, -5.23415177],
                            [39.74585521, -5.23467975],
                            [39.74580165, -5.23526129],
                            [39.74568688, -5.23537607],
                            [39.74548796, -5.23552146],
                            [39.7454656, -5.23579902],
                            [39.74530433, -5.23622543],
                            [39.74518956, -5.23637847],
                            [39.74502889, -5.23652386],
                            [39.74500277, -5.23688595],
                            [39.74496003, -5.23699827],
                            [39.74477641, -5.23704419],
                            [39.74450097, -5.23705184],
                            [39.74436325, -5.23702888],
                            [39.74428674, -5.23686054],
                            [39.74420258, -5.2365162],
                            [39.74408016, -5.23653916],
                            [39.74391184, -5.23663098],
                            [39.74383533, -5.23658507],
                            [39.74366701, -5.23666924],
                            [39.74368231, -5.23679167],
                            [39.74372286, -5.23704719],
                            [39.7436211, -5.23728139],
                            [39.74370526, -5.23736556],
                            [39.74378942, -5.23756451],
                            [39.74388123, -5.23781703],
                            [39.74410311, -5.23786294],
                            [39.74424083, -5.23783233],
                            [39.74432499, -5.23771755],
                            [39.74442446, -5.23759512],
                            [39.74452046, -5.23761206],
                            [39.74448797, -5.23769549],
                            [39.74441681, -5.23787824],
                            [39.74448567, -5.23802363],
                            [39.74460808, -5.23813841],
                            [39.7447305, -5.23806189],
                            [39.7446999, -5.23783998],
                            [39.74484527, -5.23778642],
                            [39.74488352, -5.23786294],
                            [39.74499829, -5.23795476],
                            [39.74523547, -5.23780172],
                            [39.74535789, -5.23752626],
                            [39.745465, -5.2372967],
                            [39.74557212, -5.23702123],
                            [39.74554916, -5.2368835],
                            [39.74559507, -5.23673811],
                            [39.74571749, -5.23643969],
                            [39.74583225, -5.23647795],
                            [39.74597762, -5.2366769],
                            [39.74604648, -5.23659273],
                            [39.7461689, -5.23637082],
                            [39.74607709, -5.23597292],
                            [39.74598527, -5.2358964],
                            [39.74590876, -5.23585049],
                            [39.74601588, -5.23575102],
                            [39.74613829, -5.23555972],
                            [39.74629346, -5.23563174],
                            [39.74626836, -5.2357051],
                            [39.7462148, -5.23601883],
                            [39.74632192, -5.23617952],
                            [39.74628313, -5.23674079],
                            [39.74608473, -5.23735026],
                            [39.7459118, -5.23758343],
                            [39.74583225, -5.23782468],
                            [39.74590111, -5.23803128],
                            [39.74577104, -5.23806954],
                            [39.74546777, -5.23827967],
                            [39.74538084, -5.23853631],
                            [39.74565627, -5.23874291],
                            [39.74641373, -5.23927854],
                            [39.74685749, -5.23948515],
                            [39.74724004, -5.23956932],
                            [39.74752313, -5.23972236],
                            [39.74797454, -5.23959993],
                            [39.74816581, -5.2395081],
                            [39.74834944, -5.23924794],
                            [39.74836474, -5.23895717],
                            [39.74828058, -5.23880413],
                            [39.7483877, -5.23874291],
                            [39.74857132, -5.23863579],
                            [39.74862488, -5.2384751],
                            [39.74877025, -5.23836032],
                            [39.74886971, -5.23818433],
                            [39.74903804, -5.23821493],
                            [39.74922931, -5.23826084],
                            [39.74926757, -5.23839858],
                            [39.74902273, -5.23855927],
                            [39.74880272, -5.23874924],
                            [39.74874729, -5.23903369],
                            [39.74888721, -5.23922109],
                            [39.74886206, -5.23935507],
                            [39.74910307, -5.23959228],
                            [39.74920636, -5.23954636],
                            [39.74933642, -5.23955402],
                            [39.74940911, -5.23961523],
                            [39.74945119, -5.23976062],
                            [39.74953535, -5.23979123],
                            [39.74975341, -5.23973766],
                            [39.74982609, -5.2395961],
                            [39.74991408, -5.23955019],
                            [39.74999059, -5.23961906],
                            [39.75015509, -5.2395655],
                            [39.75028515, -5.23948132],
                            [39.7504114, -5.23938568],
                            [39.7504267, -5.23926707],
                            [39.75052616, -5.23923646],
                            [39.75054911, -5.2393742],
                            [39.75070214, -5.23937802],
                            [39.75097375, -5.23934742],
                            [39.75112677, -5.23927855],
                            [39.7512071, -5.23917525],
                            [39.75126066, -5.23908343],
                            [39.75135056, -5.23912849],
                            [39.751341, -5.23923647],
                            [39.75126449, -5.23933594],
                            [39.7513257, -5.23942011],
                            [39.75148637, -5.23940481],
                            [39.75169677, -5.23936655],
                            [39.75193395, -5.23940481],
                            [39.7521061, -5.23942011],
                            [39.75229738, -5.23952341],
                            [39.7524351, -5.23968028],
                            [39.75255369, -5.23986775],
                            [39.75262637, -5.23997488],
                            [39.75275644, -5.23992896],
                            [39.75296302, -5.23969558],
                            [39.75309309, -5.23941629],
                            [39.75323463, -5.23914847],
                            [39.75325758, -5.23905665],
                            [39.75323463, -5.23862049],
                            [39.75322698, -5.23847893],
                            [39.75324611, -5.23823407],
                            [39.75337618, -5.23816903],
                            [39.75360825, -5.23814605],
                            [39.75360918, -5.23844452],
                            [39.75360953, -5.23855928],
                            [39.7535598, -5.23876205],
                            [39.75353685, -5.23888065],
                            [39.75361718, -5.23896865],
                            [39.75373195, -5.23890361],
                            [39.75385054, -5.23874675],
                            [39.75394618, -5.23859754],
                            [39.75390027, -5.23826085],
                            [39.7539194, -5.23801599],
                            [39.75391175, -5.23780939],
                            [39.75398826, -5.23770609],
                            [39.75409537, -5.23771757],
                            [39.75423692, -5.2376334],
                            [39.75436316, -5.23741914],
                            [39.7544435, -5.2372508],
                            [39.75455061, -5.23728141],
                            [39.75464625, -5.23718959],
                            [39.75464625, -5.23700594],
                            [39.75473424, -5.2366616],
                            [39.75478014, -5.23653535],
                            [39.75486813, -5.23642822],
                            [39.7551474, -5.23604945],
                            [39.75537693, -5.23582755],
                            [39.75568986, -5.23570297],
                            [39.75577096, -5.23567068],
                            [39.75602727, -5.23563625],
                            [39.75639834, -5.23557121],
                            [39.75652458, -5.23547939],
                            [39.7566623, -5.23529957],
                            [39.75684593, -5.23513505],
                            [39.75700277, -5.23498584],
                            [39.75718257, -5.23495523],
                            [39.75734325, -5.23493228],
                            [39.75742358, -5.23480602],
                            [39.75748096, -5.23461472],
                            [39.75743123, -5.23441577],
                            [39.7573968, -5.2342283],
                            [39.75747523, -5.2341977],
                            [39.75755365, -5.23416709],
                            [39.75766493, -5.23421533],
                            [39.75770285, -5.23439665],
                            [39.75767387, -5.23467109],
                            [39.7577258, -5.23492463],
                            [39.75762633, -5.23498202],
                            [39.75744271, -5.23509297],
                            [39.75711371, -5.23527279],
                            [39.75693774, -5.23537609],
                            [39.75701808, -5.23547939],
                            [39.75687653, -5.2355253],
                            [39.75671586, -5.23568982],
                            [39.75664317, -5.23586581],
                            [39.75648633, -5.23587729],
                            [39.75621854, -5.23593085],
                            [39.75602344, -5.23607624],
                            [39.75580156, -5.23612215],
                            [39.75579313, -5.23611529],
                            [39.75574035, -5.23607241],
                            [39.75562176, -5.23609919],
                            [39.75551464, -5.2363364],
                            [39.75535397, -5.23650857],
                            [39.75532337, -5.23674578],
                            [39.75508619, -5.23716663],
                            [39.75506066, -5.23723149],
                            [39.75485283, -5.23775965],
                            [39.75470746, -5.23792799],
                            [39.7546386, -5.23855162],
                            [39.75455826, -5.23887683],
                            [39.75440907, -5.2391676],
                            [39.75434403, -5.23940481],
                            [39.75425604, -5.2395502],
                            [39.75422927, -5.23969367],
                            [39.75420249, -5.23983714],
                            [39.75423309, -5.24002079],
                            [39.75419219, -5.24021942],
                            [39.75419483, -5.24032304],
                            [39.75425604, -5.24049138],
                            [39.75435551, -5.24062146],
                            [39.75444732, -5.24065972],
                            [39.75452765, -5.24079363],
                            [39.75445114, -5.24101171],
                            [39.75440906, -5.24129483],
                            [39.75445879, -5.24134839],
                            [39.75453913, -5.24133309],
                            [39.75463712, -5.24138734],
                            [39.75464242, -5.24151674],
                            [39.75470363, -5.24163152],
                            [39.75487195, -5.24171569],
                            [39.75509, -5.24178838],
                            [39.75535014, -5.24168891],
                            [39.75556437, -5.24158943],
                            [39.75587041, -5.24145552],
                            [39.75605404, -5.24141344],
                            [39.75618793, -5.24140961],
                            [39.75640981, -5.24140196],
                            [39.75653223, -5.24128718],
                            [39.75669672, -5.2412451],
                            [39.75681149, -5.24123362],
                            [39.75698746, -5.24122979],
                            [39.75715579, -5.24116858],
                            [39.75719787, -5.24119153],
                            [39.75730116, -5.24124892],
                            [39.75745035, -5.24122979],
                            [39.75754599, -5.24129866],
                            [39.75770666, -5.24132162],
                            [39.7577717, -5.2412604],
                            [39.75787116, -5.24135605],
                            [39.7580127, -5.24151292],
                            [39.75816572, -5.24176543],
                            [39.75829197, -5.24194142],
                            [39.75833871, -5.24199207],
                            [39.75842968, -5.24209064],
                            [39.75847176, -5.24219394],
                            [39.75836465, -5.24219394],
                            [39.75821892, -5.24202577],
                            [39.75821545, -5.24202177],
                            [39.75803566, -5.2419529],
                            [39.75790941, -5.24189934],
                            [39.75775257, -5.24187255],
                            [39.75761102, -5.24182282],
                            [39.75757659, -5.24174247],
                            [39.75748861, -5.24168891],
                            [39.75740444, -5.24175012],
                            [39.75720169, -5.24183429],
                            [39.75696451, -5.24185725],
                            [39.75671203, -5.24186873],
                            [39.75645954, -5.24185725],
                            [39.75627592, -5.24187638],
                            [39.75608847, -5.24187255],
                            [39.75585511, -5.24201411],
                            [39.75576712, -5.24206385],
                            [39.75567531, -5.24199881],
                            [39.75557585, -5.24206768],
                            [39.75543048, -5.24214037],
                            [39.75537692, -5.24218628],
                            [39.75528893, -5.24231636],
                            [39.75517799, -5.24245027],
                            [39.75514739, -5.24264922],
                            [39.7552392, -5.24277165],
                            [39.75536161, -5.2427793],
                            [39.75543813, -5.24264922],
                            [39.75554141, -5.2426607],
                            [39.75564853, -5.24261479],
                            [39.75570974, -5.24250766],
                            [39.75577837, -5.24247646],
                            [39.75578814, -5.24259841],
                            [39.75579277, -5.24265623],
                            [39.75579772, -5.24271809],
                            [39.75572509, -5.24299776],
                            [39.75572121, -5.24301269],
                            [39.75566766, -5.24306625],
                            [39.75548786, -5.24310068],
                            [39.75543047, -5.24310833],
                            [39.75535014, -5.24310833],
                            [39.75530806, -5.24318485],
                            [39.75537309, -5.24337232],
                            [39.75548786, -5.24358275],
                            [39.75557298, -5.24362214],
                            [39.75574417, -5.24370136],
                            [39.75596987, -5.24369753],
                            [39.75603491, -5.2434718],
                            [39.75616162, -5.24344984],
                            [39.75617262, -5.24357128],
                            [39.75622618, -5.24370901],
                            [39.75642511, -5.24369753],
                            [39.75671412, -5.24368627],
                            [39.75701424, -5.24367458],
                            [39.75725525, -5.24354067],
                            [39.75749625, -5.24339146],
                            [39.75759173, -5.24339493],
                            [39.75770666, -5.24339911],
                            [39.75798592, -5.24334937],
                            [39.75843351, -5.2432652],
                            [39.75898056, -5.24308156],
                            [39.75905808, -5.2429571],
                            [39.75912593, -5.24284817],
                            [39.75910297, -5.24267601],
                            [39.75909915, -5.24250766],
                            [39.75919861, -5.24240054],
                            [39.75933251, -5.24238906],
                            [39.75929515, -5.24258777],
                            [39.75926008, -5.24273272],
                            [39.75927177, -5.24283092],
                            [39.75947282, -5.24301562],
                            [39.75961075, -5.24306239],
                            [39.75975569, -5.24303901],
                            [39.75989595, -5.24306472],
                            [39.75994738, -5.24310447],
                            [39.7600105, -5.24315591],
                            [39.7600783, -5.24324943],
                            [39.76018817, -5.24330788],
                            [39.76034948, -5.24332425],
                            [39.76044533, -5.24333126],
                            [39.7605365, -5.24332425],
                            [39.76056923, -5.24337334],
                            [39.7605552, -5.24349258],
                            [39.76054585, -5.24354168],
                            [39.76060429, -5.24363053],
                            [39.76060429, -5.24367495],
                            [39.76057858, -5.2437334],
                            [39.76050845, -5.24375444],
                            [39.7603939, -5.24372171],
                            [39.76021856, -5.24364923],
                            [39.76007128, -5.24360949],
                            [39.75993336, -5.24357441],
                            [39.75981413, -5.24354168],
                            [39.75958737, -5.24356039],
                            [39.75938164, -5.2435861],
                            [39.75918995, -5.24359312],
                            [39.75909648, -5.24361433],
                            [39.75891175, -5.24365624],
                            [39.75863823, -5.24373106],
                            [39.7585307, -5.24377081],
                            [39.75844186, -5.24375678],
                            [39.75835303, -5.24375444],
                            [39.75825952, -5.24380822],
                            [39.75814497, -5.24382926],
                            [39.75805145, -5.24388303],
                            [39.7580234, -5.24392745],
                            [39.75797665, -5.24395317],
                            [39.75791119, -5.2439181],
                            [39.7577943, -5.24395083],
                            [39.75772884, -5.24399993],
                            [39.7575465, -5.24407007],
                            [39.75739286, -5.24410958],
                            [39.75738285, -5.24411216],
                            [39.75737641, -5.24411437],
                            [39.75709765, -5.24421035],
                            [39.75674932, -5.24429686],
                            [39.75632852, -5.24444182],
                            [39.75608305, -5.24455171],
                            [39.75585629, -5.2446312],
                            [39.75580954, -5.24463354],
                            [39.75567395, -5.24464756],
                            [39.7555968, -5.2447177],
                            [39.75556407, -5.24480889],
                            [39.75561784, -5.2449141],
                            [39.75569732, -5.24493982],
                            [39.75576512, -5.24492579],
                            [39.75574875, -5.24497255],
                            [39.75571369, -5.24504503],
                            [39.75564355, -5.24516894],
                            [39.75558511, -5.24521337],
                            [39.75547523, -5.24521103],
                            [39.75539575, -5.24525779],
                            [39.7553677, -5.24526013],
                            [39.75526016, -5.24520869],
                            [39.75522509, -5.24527182],
                            [39.75522276, -5.24531858],
                            [39.75515262, -5.2453443],
                            [39.75499365, -5.24532793],
                            [39.75481598, -5.24531858],
                            [39.75469208, -5.24532793],
                            [39.75460792, -5.2453817],
                            [39.75452376, -5.24541444],
                            [39.75441155, -5.24538638],
                            [39.75435077, -5.24541677],
                            [39.75428765, -5.24554069],
                            [39.75428064, -5.24562486],
                            [39.75435077, -5.24579086],
                            [39.75445831, -5.24585399],
                            [39.75447467, -5.24589841],
                            [39.75439986, -5.24592413],
                            [39.75434375, -5.24586801],
                            [39.75428064, -5.2458306],
                            [39.75422687, -5.24583996],
                            [39.75410063, -5.24587269],
                            [39.75399777, -5.24588204],
                            [39.75390425, -5.24589139],
                            [39.7538201, -5.24593815],
                            [39.75377334, -5.24597322],
                            [39.75373126, -5.24596621],
                            [39.75363541, -5.24594984],
                            [39.75355593, -5.24596387],
                            [39.75351151, -5.24597089],
                            [39.75340164, -5.24591711],
                            [39.75325669, -5.24586567],
                            [39.75312578, -5.24584229],
                            [39.75308604, -5.24589139],
                            [39.75299954, -5.24589139],
                            [39.75292473, -5.24585398],
                            [39.75277044, -5.24588905],
                            [39.7526816, -5.24591243],
                            [39.75260446, -5.24588438],
                            [39.75251095, -5.24582826],
                            [39.75245016, -5.24585164],
                            [39.75238938, -5.24591009],
                            [39.7523099, -5.24596621],
                            [39.75228652, -5.24599426],
                            [39.75218833, -5.24597322],
                            [39.75213223, -5.24593581],
                            [39.7519569, -5.24586567],
                            [39.75178858, -5.24583995],
                            [39.7516787, -5.24586801],
                            [39.7515174, -5.2458797],
                            [39.75141687, -5.24589139],
                            [39.75126725, -5.24593815],
                            [39.75124317, -5.24594618],
                            [39.75119011, -5.24596387],
                            [39.75077632, -5.24591009],
                            [39.75062671, -5.24602232],
                            [39.75056125, -5.24600361],
                            [39.75046774, -5.24584463],
                            [39.75037671, -5.24577842],
                            [39.75037125, -5.24577445],
                            [39.75036488, -5.24576981],
                            [39.75008435, -5.24568564],
                            [39.74985057, -5.24564823],
                            [39.74971965, -5.24563888],
                            [39.74950458, -5.24563888],
                            [39.74935496, -5.24565758],
                            [39.74926145, -5.24572305],
                            [39.74926145, -5.24584463],
                            [39.74909313, -5.24588203],
                            [39.74899027, -5.24591944],
                            [39.74899027, -5.24599426],
                            [39.74886871, -5.24602232],
                            [39.74876585, -5.24608778],
                            [39.74861623, -5.2461813],
                            [39.74852272, -5.24635899],
                            [39.74848531, -5.24652733],
                            [39.74851337, -5.24667696],
                            [39.74861623, -5.24694817],
                            [39.74880325, -5.24722874],
                            [39.74895286, -5.2472942],
                            [39.74902767, -5.24736902],
                            [39.74912989, -5.24731962],
                            [39.74919599, -5.24752801],
                            [39.74928015, -5.24758412],
                            [39.74936183, -5.24754191],
                            [39.74948587, -5.24768699],
                            [39.74954499, -5.24765518],
                            [39.74970095, -5.24784598],
                            [39.74985992, -5.24789274],
                            [39.74997811, -5.24782407],
                            [39.75003759, -5.24814525],
                            [39.75019655, -5.24835099],
                            [39.75032747, -5.24840711],
                            [39.7505893, -5.24843516],
                            [39.75048644, -5.24855674],
                            [39.75039293, -5.24873443],
                            [39.75054254, -5.24898694],
                            [39.75070151, -5.24908981],
                            [39.75086983, -5.24929556],
                            [39.75098204, -5.24939843],
                            [39.75114101, -5.24958547],
                            [39.75130933, -5.24966964],
                            [39.75142154, -5.2496977],
                            [39.75149635, -5.24979122],
                            [39.75173013, -5.24982863],
                            [39.75180584, -5.24975454],
                            [39.75193585, -5.24988474],
                            [39.75209482, -5.24995021],
                            [39.75227249, -5.24995956],
                            [39.75244081, -5.24996891],
                            [39.75272134, -5.24990345],
                            [39.75288031, -5.24990345],
                            [39.75301122, -5.24997827],
                            [39.75310473, -5.25007179],
                            [39.75325435, -5.25006244],
                            [39.75332493, -5.24997334],
                            [39.75346007, -5.25005308],
                            [39.75351618, -5.2501092],
                            [39.75355358, -5.25020272],
                            [39.75355358, -5.25029624],
                            [39.75338526, -5.25033365],
                            [39.75310473, -5.25023077],
                            [39.75288031, -5.25020272],
                            [39.75259042, -5.25019336],
                            [39.75245016, -5.25021207],
                            [39.75235665, -5.25035235],
                            [39.75249691, -5.25043652],
                            [39.75264653, -5.25050198],
                            [39.75269329, -5.25063291],
                            [39.75272134, -5.25072644],
                            [39.75275874, -5.2510257],
                            [39.75274939, -5.25121275],
                            [39.7528616, -5.25139044],
                            [39.75306733, -5.25144655],
                            [39.75327305, -5.25146525],
                            [39.75341332, -5.25148396],
                            [39.75355358, -5.25139044],
                            [39.75362917, -5.2512727],
                            [39.7537032, -5.25129692],
                            [39.75377801, -5.25149331],
                            [39.75388087, -5.25157748],
                            [39.75396503, -5.25162424],
                            [39.75387152, -5.25174582],
                            [39.75393698, -5.25192351],
                            [39.75410529, -5.25207314],
                            [39.75432037, -5.25226954],
                            [39.75508715, -5.25280261],
                            [39.7554612, -5.25290548],
                            [39.75576978, -5.2529616],
                            [39.75615318, -5.25312058],
                            [39.75648981, -5.25325151],
                            [39.75667683, -5.25339179],
                            [39.75712569, -5.25373782],
                            [39.757294, -5.25378458],
                            [39.75749038, -5.25378458],
                            [39.75763999, -5.25378458],
                            [39.75777091, -5.25379394],
                            [39.75787377, -5.25382199],
                            [39.75778961, -5.2540558],
                            [39.75784572, -5.25423349],
                            [39.75810755, -5.25426154],
                            [39.75829457, -5.25425219],
                            [39.75829928, -5.25424837],
                            [39.75868731, -5.25393422],
                            [39.75872146, -5.25386189],
                            [39.7587855, -5.25389214],
                            [39.75884628, -5.25393656],
                            [39.7588907, -5.25398332],
                            [39.75898421, -5.2541083],
                            [39.75889984, -5.25414544],
                            [39.75879455, -5.25419179],
                            [39.75867329, -5.25424518],
                            [39.75854705, -5.25424985],
                            [39.75851899, -5.25430363],
                            [39.75850497, -5.25435039],
                            [39.75845963, -5.25432662],
                            [39.75835769, -5.25437143],
                            [39.75833981, -5.25439026],
                            [39.75822677, -5.25450937],
                            [39.75824314, -5.25460056],
                            [39.75836938, -5.25476422],
                            [39.75847457, -5.25484371],
                            [39.75853068, -5.25482968],
                            [39.75858445, -5.25482267],
                            [39.75863822, -5.25488346],
                            [39.75869199, -5.25495594],
                            [39.75872939, -5.2550214],
                            [39.75879251, -5.25511259],
                            [39.75886264, -5.25513129],
                            [39.7589468, -5.25515467],
                            [39.75896317, -5.25522715],
                            [39.75902957, -5.25530111],
                            [39.75906603, -5.25534171],
                            [39.75919461, -5.25535808],
                            [39.75904733, -5.25543523],
                            [39.75897457, -5.25537961],
                            [39.75890706, -5.25538847],
                            [39.75886553, -5.25536078],
                            [39.75879485, -5.25531366],
                            [39.75875744, -5.25522949],
                            [39.75868068, -5.25512855],
                            [39.75861718, -5.25513597],
                            [39.75856107, -5.25504245],
                            [39.75843462, -5.25487338],
                            [39.75833665, -5.25488346],
                            [39.75828989, -5.25478994],
                            [39.75813092, -5.25466836],
                            [39.75801871, -5.25478994],
                            [39.75786909, -5.25492087],
                            [39.75771948, -5.25503309],
                            [39.75769142, -5.25515467],
                            [39.75771012, -5.25524819],
                            [39.75782234, -5.25534171],
                            [39.7578844, -5.25545457],
                            [39.7579252, -5.25552875],
                            [39.7579252, -5.25582802],
                            [39.7579557, -5.25591954],
                            [39.75797195, -5.2559683],
                            [39.75805611, -5.25614599],
                            [39.75805611, -5.25619275],
                            [39.75800698, -5.25631909],
                            [39.7579252, -5.25633304],
                            [39.75786909, -5.25626757],
                            [39.75781298, -5.25609923],
                            [39.75770077, -5.2559496],
                            [39.75767372, -5.25591954],
                            [39.75767084, -5.25591634],
                            [39.75761661, -5.25585608],
                            [39.75750851, -5.25581941],
                            [39.75743389, -5.25583007],
                            [39.75731738, -5.25584673],
                            [39.75717711, -5.25597765],
                            [39.75718646, -5.25628627],
                            [39.75719581, -5.25651073],
                            [39.75733608, -5.25670712],
                            [39.75730803, -5.25687546],
                            [39.75709295, -5.25680999],
                            [39.75705555, -5.25670712],
                            [39.75699944, -5.25656684],
                            [39.75697139, -5.25642656],
                            [39.75690593, -5.25622081],
                            [39.75684983, -5.25595895],
                            [39.75690593, -5.25582802],
                            [39.7570836, -5.25576256],
                            [39.75718646, -5.25570644],
                            [39.75724257, -5.25569709],
                            [39.75737349, -5.25560357],
                            [39.75736413, -5.25547264],
                            [39.75731738, -5.25535106],
                            [39.75726127, -5.25526689],
                            [39.75710139, -5.2551932],
                            [39.7570275, -5.25520143],
                            [39.75693399, -5.25512661],
                            [39.75692464, -5.25501439],
                            [39.75689658, -5.25482734],
                            [39.75680307, -5.25467771],
                            [39.75668709, -5.25455602],
                            [39.7565693, -5.25456548],
                            [39.75650917, -5.25449457],
                            [39.75639905, -5.25450304],
                            [39.75619811, -5.2545185],
                            [39.75606582, -5.25452868],
                            [39.75591637, -5.25454018],
                            [39.7557662, -5.25455173],
                            [39.755709, -5.25455613],
                            [39.75561549, -5.2546216],
                            [39.75558743, -5.25472447],
                            [39.75556873, -5.2548741],
                            [39.75566224, -5.25504244],
                            [39.75559678, -5.25513596],
                            [39.75556873, -5.25525754],
                            [39.75561549, -5.2553043],
                            [39.755709, -5.25535106],
                            [39.75587732, -5.255323],
                            [39.75591472, -5.25538847],
                            [39.75583056, -5.25546329],
                            [39.75589602, -5.25555681],
                            [39.75598953, -5.25565968],
                            [39.75615785, -5.25571579],
                            [39.7561485, -5.25579061],
                            [39.75602041, -5.25574998],
                            [39.75598603, -5.25575447],
                            [39.75592407, -5.25576255],
                            [39.75581548, -5.25568927],
                            [39.75570737, -5.25570297],
                            [39.75560613, -5.25571579],
                            [39.75549885, -5.25567081],
                            [39.75543782, -5.25567838],
                            [39.75538171, -5.25562227],
                            [39.75536301, -5.25556616],
                            [39.7553256, -5.25540717],
                            [39.75518295, -5.25535723],
                            [39.7550771, -5.25536879],
                            [39.75492218, -5.25538571],
                            [39.75481129, -5.25539782],
                            [39.75480194, -5.25531365],
                            [39.754677, -5.25526215],
                            [39.75454011, -5.25527624],
                            [39.75429699, -5.25516402],
                            [39.75410061, -5.25526689],
                            [39.75418477, -5.25548199],
                            [39.75419412, -5.25594959],
                            [39.75432504, -5.25615534],
                            [39.75518534, -5.25664165],
                            [39.7557277, -5.25683805],
                            [39.75600823, -5.2568287],
                            [39.75643838, -5.25700639],
                            [39.75686853, -5.25758622],
                            [39.75694333, -5.25801642],
                            [39.75695268, -5.25850273],
                            [39.75689658, -5.25876458],
                            [39.75677501, -5.25908256],
                            [39.7566815, -5.25963433],
                            [39.75656929, -5.25992425],
                            [39.75676566, -5.26017675],
                            [39.75702749, -5.26063501],
                            [39.75717711, -5.26096233],
                            [39.75730802, -5.26153281],
                            [39.75728932, -5.26224357],
                            [39.75728932, -5.26252414],
                            [39.75731737, -5.26277664],
                            [39.75750439, -5.26299174],
                            [39.75774752, -5.26316008],
                            [39.7580748, -5.26327231],
                            [39.75807553, -5.26327283],
                            [39.75828053, -5.26342194],
                            [39.7584582, -5.2634687],
                            [39.75869197, -5.26334712],
                            [39.7589538, -5.26313203],
                            [39.75900128, -5.26304893],
                            [39.75906602, -5.26293563],
                            [39.75907264, -5.2629229],
                            [39.75918758, -5.26270183],
                            [39.7593746, -5.2625522],
                            [39.75978605, -5.26259896],
                            [39.75991471, -5.2626399],
                            [39.75953384, -5.26278493],
                            [39.75939021, -5.26299387],
                            [39.75918783, -5.26311139],
                            [39.75903767, -5.26320933],
                            [39.7589985, -5.26337256],
                            [39.75902462, -5.26349009],
                            [39.7589528, -5.26352926],
                            [39.75881571, -5.26358149],
                            [39.75875695, -5.26360435],
                            [39.75869819, -5.2636272],
                            [39.75858721, -5.26369249],
                            [39.75850234, -5.26369902],
                            [39.75829996, -5.26369902],
                            [39.75820856, -5.26375778],
                            [39.75811063, -5.26375778],
                            [39.75801924, -5.26366637],
                            [39.75790172, -5.26355538],
                            [39.75775157, -5.26349008],
                            [39.75756358, -5.2634502],
                            [39.75753613, -5.26344438],
                            [39.75738598, -5.26338562],
                            [39.75711831, -5.26305916],
                            [39.75704757, -5.26282334],
                            [39.75690287, -5.26234094],
                            [39.7568637, -5.2621516],
                            [39.7569551, -5.26174679],
                            [39.75694361, -5.26162903],
                            [39.75690287, -5.26121139],
                            [39.75676578, -5.26102858],
                            [39.75660909, -5.26096329],
                            [39.75645241, -5.26096329],
                            [39.75623697, -5.26095676],
                            [39.75608365, -5.26095676],
                            [39.75607376, -5.26095676],
                            [39.75578651, -5.26106122],
                            [39.75551231, -5.26111998],
                            [39.75537522, -5.26112651],
                            [39.75535473, -5.26112846],
                            [39.75523812, -5.26113957],
                            [39.75515325, -5.26133545],
                            [39.75515978, -5.26146603],
                            [39.75515325, -5.26155744],
                            [39.75511408, -5.2615607],
                            [39.75507491, -5.26156397],
                            [39.75503573, -5.26142033],
                            [39.75500309, -5.26137462],
                            [39.75497698, -5.26132892],
                            [39.75495087, -5.26126363],
                            [39.75501615, -5.26117875],
                            [39.75509449, -5.26108081],
                            [39.75525117, -5.2610351],
                            [39.7553491, -5.26097634],
                            [39.75546914, -5.26094864],
                            [39.75551884, -5.26093717],
                            [39.75572122, -5.26081311],
                            [39.75593666, -5.26074782],
                            [39.75602649, -5.26070829],
                            [39.75609987, -5.260676],
                            [39.75619127, -5.26049971],
                            [39.75619127, -5.26014714],
                            [39.7561978, -5.2598925],
                            [39.75615863, -5.25937669],
                            [39.75613905, -5.25901106],
                            [39.75611293, -5.25893924],
                            [39.7561978, -5.25884783],
                            [39.7561978, -5.25872377],
                            [39.75612599, -5.25869113],
                            [39.75603459, -5.25869113],
                            [39.75597584, -5.25869113],
                            [39.75583874, -5.25864542],
                            [39.75574081, -5.25855401],
                            [39.75564289, -5.25827979],
                            [39.75549926, -5.25818185],
                            [39.75535563, -5.25777704],
                            [39.75525771, -5.25757463],
                            [39.75511408, -5.25741793],
                            [39.75478385, -5.25734022],
                            [39.75478113, -5.25733958],
                            [39.75451999, -5.25730041],
                            [39.75426538, -5.25727429],
                            [39.75414171, -5.25718651],
                            [39.754063, -5.25713065],
                            [39.75393896, -5.25698048],
                            [39.75371699, -5.25686948],
                            [39.75357337, -5.25674542],
                            [39.75354725, -5.25657567],
                            [39.75354073, -5.25643202],
                            [39.75347544, -5.25611862],
                            [39.75338404, -5.25575952],
                            [39.75329265, -5.25561587],
                            [39.75319472, -5.25534165],
                            [39.75315555, -5.25513271],
                            [39.75307068, -5.25485849],
                            [39.75290094, -5.25461038],
                            [39.75267897, -5.25439492],
                            [39.75253535, -5.2542121],
                            [39.75232644, -5.25391828],
                            [39.75231121, -5.25390753],
                            [39.75210447, -5.25376158],
                            [39.75180416, -5.25360488],
                            [39.75160831, -5.25342859],
                            [39.75143857, -5.25333066],
                            [39.75111867, -5.25335024],
                            [39.75089671, -5.25340248],
                            [39.75073349, -5.25351347],
                            [39.7505964, -5.25365058],
                            [39.75049194, -5.25382034],
                            [39.75039691, -5.25392133],
                            [39.75038749, -5.25393134],
                            [39.7502765, -5.25398357],
                            [39.75012635, -5.25402928],
                            [39.75002842, -5.25402275],
                            [39.7498391, -5.2539901],
                            [39.74970853, -5.25389216],
                            [39.7496563, -5.25379423],
                            [39.74934946, -5.25381381],
                            [39.74931682, -5.25391828],
                            [39.74917972, -5.25397051],
                            [39.74905568, -5.2539901],
                            [39.74900019, -5.25398031],
                            [39.7489447, -5.25397051],
                            [39.74899693, -5.25385299],
                            [39.74907527, -5.25368323],
                            [39.74899693, -5.25351347],
                            [39.74882719, -5.25344818],
                            [39.74874232, -5.25347429],
                            [39.74870315, -5.25340247],
                            [39.74841589, -5.25326536],
                            [39.74827227, -5.2533633],
                            [39.74810906, -5.25348735],
                            [39.74799154, -5.25357223],
                            [39.74795237, -5.25363099],
                            [39.74793279, -5.25369629],
                            [39.74789362, -5.25376158],
                            [39.74764554, -5.25402927],
                            [39.74754761, -5.25421862],
                            [39.74749538, -5.25436879],
                            [39.74730605, -5.25453202],
                            [39.74720813, -5.25465607],
                            [39.74714937, -5.25474748],
                            [39.74707103, -5.25483889],
                            [39.74696005, -5.2548193],
                            [39.7470645, -5.25465607],
                            [39.7472016, -5.25439491],
                            [39.74727341, -5.25433614],
                            [39.74737134, -5.2541925],
                            [39.74741051, -5.25404886],
                            [39.74750191, -5.25393134],
                            [39.74752802, -5.25382034],
                            [39.74763901, -5.25361141],
                            [39.74770429, -5.25349388],
                            [39.74776958, -5.25340247],
                            [39.74790015, -5.25336983],
                            [39.74813517, -5.25324577],
                            [39.7483245, -5.25317395],
                            [39.74849424, -5.25303031],
                            [39.74863133, -5.2529846],
                            [39.74877496, -5.25288667],
                            [39.74913402, -5.25259285],
                            [39.74914708, -5.25245574],
                            [39.74919278, -5.25230557],
                            [39.74919931, -5.25213581],
                            [39.74914708, -5.25192035],
                            [39.74897734, -5.25178323],
                            [39.748899, -5.25174406],
                            [39.74885004, -5.25174079],
                            [39.74880107, -5.25173753],
                            [39.74870968, -5.25169835],
                            [39.74867051, -5.2515286],
                            [39.74859216, -5.25143719],
                            [39.74842243, -5.25121519],
                            [39.74829838, -5.25100626],
                            [39.7481417, -5.25075815],
                            [39.74793279, -5.25060145],
                            [39.7477043, -5.25034681],
                            [39.74744969, -5.25011176],
                            [39.74727995, -5.25001382],
                            [39.74720161, -5.25000076],
                            [39.74715591, -5.24999423],
                            [39.74711021, -5.24984406],
                            [39.74702534, -5.24968083],
                            [39.7469013, -5.24953719],
                            [39.74670544, -5.24936743],
                            [39.74655529, -5.24925643],
                            [39.74635291, -5.24915197],
                            [39.74622887, -5.2491585],
                            [39.74617664, -5.24930867],
                            [39.74615705, -5.24940661],
                            [39.74620275, -5.24958289],
                            [39.7462559, -5.2496335],
                            [39.74616358, -5.24977224],
                            [39.74601343, -5.24992894],
                            [39.74598731, -5.2501444],
                            [39.7460017, -5.25020195],
                            [39.74601996, -5.25027499],
                            [39.74608524, -5.25038598],
                            [39.74617616, -5.25046655],
                            [39.74628998, -5.25052408],
                            [39.74632679, -5.25054268],
                            [39.74646244, -5.25066345],
                            [39.7466271, -5.25075162],
                            [39.74657487, -5.25084956],
                            [39.74646389, -5.25075815],
                            [39.74628762, -5.25067327],
                            [39.74623439, -5.25064285],
                            [39.74615052, -5.25059492],
                            [39.74595467, -5.25056227],
                            [39.7458698, -5.25054921],
                            [39.74584369, -5.25038598],
                            [39.74585022, -5.25023581],
                            [39.7458202, -5.25016076],
                            [39.74578493, -5.25007258],
                            [39.7457327, -5.25001382],
                            [39.74572618, -5.24977224],
                            [39.74576535, -5.24956983],
                            [39.74576535, -5.24934784],
                            [39.74575882, -5.24917808],
                            [39.74572618, -5.24892344],
                            [39.74565436, -5.24869492],
                            [39.74554991, -5.24865575],
                            [39.74539323, -5.24874716],
                            [39.74528224, -5.24882551],
                            [39.74508639, -5.24892997],
                            [39.74499499, -5.24907361],
                            [39.7449297, -5.24919114],
                            [39.74485789, -5.24937396],
                            [39.74481219, -5.24945231],
                            [39.74470774, -5.24959595],
                            [39.74468815, -5.24974612],
                            [39.74464245, -5.24985712],
                            [39.74457064, -5.2498767],
                            [39.74447924, -5.24984406],
                            [39.74447924, -5.2496743],
                            [39.74455758, -5.24954372],
                            [39.74467509, -5.24945884],
                            [39.74472079, -5.24932825],
                            [39.74468815, -5.24917155],
                            [39.74463592, -5.24905403],
                            [39.74459023, -5.24892344],
                            [39.7444466, -5.24859698],
                            [39.74430297, -5.24844028],
                            [39.74406795, -5.24830317],
                            [39.74393085, -5.24823788],
                            [39.74380681, -5.24823135],
                            [39.74357179, -5.248153],
                            [39.74345821, -5.24808759],
                            [39.74335635, -5.24802894],
                            [39.74302993, -5.2478853],
                            [39.7427949, -5.24778083],
                            [39.74255335, -5.2477286],
                            [39.74242278, -5.24780042],
                            [39.74237055, -5.24785265],
                            [39.74230527, -5.24782001],
                            [39.74225304, -5.24765678],
                            [39.74218123, -5.24746743],
                            [39.74206372, -5.2473042],
                            [39.74180911, -5.24716056],
                            [39.74175442, -5.24713086],
                            [39.74158061, -5.2470365],
                            [39.7413717, -5.24704303],
                            [39.74131294, -5.24725197],
                            [39.74140434, -5.24743478],
                            [39.74144351, -5.24762413],
                            [39.74143895, -5.24773826],
                            [39.74143698, -5.24778736],
                            [39.74136517, -5.24789183],
                            [39.74120849, -5.24794406],
                            [39.7410975, -5.24783306],
                            [39.74101263, -5.24781348],
                            [39.74094735, -5.24782],
                            [39.74091471, -5.24770901],
                            [39.74099305, -5.2476176],
                            [39.74095388, -5.2475066],
                            [39.74095227, -5.24748565],
                            [39.74094735, -5.24742172],
                            [39.74082331, -5.24725196],
                            [39.74071885, -5.24726502],
                            [39.74065357, -5.24718014],
                            [39.74055564, -5.24700386],
                            [39.74049689, -5.24696468],
                            [39.74044466, -5.24694509],
                            [39.74039243, -5.24691898],
                            [39.74034673, -5.24691898],
                            [39.74029451, -5.24691245],
                            [39.74025533, -5.24688633],
                            [39.74017699, -5.24678186],
                            [39.74025534, -5.24657946],
                            [39.74022922, -5.24637705],
                            [39.74009212, -5.2459853],
                            [39.73995503, -5.2458743],
                            [39.7398114, -5.24565884],
                            [39.73964819, -5.24552173],
                            [39.73941317, -5.24541726],
                            [39.73932177, -5.24541073],
                            [39.73919773, -5.24542379],
                            [39.73911612, -5.24539767],
                            [39.73903452, -5.24537156],
                            [39.73887783, -5.2453585],
                            [39.73882561, -5.24531279],
                            [39.73874726, -5.24518221],
                            [39.73866892, -5.24500592],
                            [39.73841431, -5.24479046],
                            [39.73821193, -5.24467946],
                            [39.73791162, -5.24436606],
                            [39.7375852, -5.2443073],
                            [39.7374481, -5.244353],
                            [39.73725878, -5.24432035],
                            [39.73711515, -5.24445747],
                            [39.73706292, -5.24459458],
                            [39.73699764, -5.24481657],
                            [39.73699764, -5.24492104],
                            [39.73703028, -5.24511039],
                            [39.73704987, -5.24528014],
                            [39.73706292, -5.24541726],
                            [39.73719349, -5.24565231],
                            [39.73718696, -5.24582207],
                            [39.73726024, -5.24592675],
                            [39.73732406, -5.24601794],
                            [39.7374321, -5.24609744],
                            [39.73746769, -5.24632482],
                            [39.73747421, -5.24641622],
                            [39.73748727, -5.24663169],
                            [39.73743504, -5.24673615],
                            [39.73728489, -5.24682103],
                            [39.73716085, -5.2469255],
                            [39.73710862, -5.24704955],
                            [39.73705639, -5.24705935],
                            [39.73700416, -5.24706914],
                            [39.73696173, -5.24702997],
                            [39.73685768, -5.24715796],
                            [39.73691277, -5.24687327],
                            [39.73703681, -5.24679492],
                            [39.73714779, -5.24666433],
                            [39.73715432, -5.24655987],
                            [39.73714126, -5.24644234],
                            [39.73710862, -5.24633134],
                            [39.73698748, -5.24623126],
                            [39.73695847, -5.24620729],
                            [39.73686054, -5.24617464],
                            [39.73682137, -5.24616158],
                            [39.73669733, -5.24616811],
                            [39.73658634, -5.24601141],
                            [39.73654717, -5.24593306],
                            [39.73650147, -5.2458286],
                            [39.73647536, -5.24548255],
                            [39.73646883, -5.2452932],
                            [39.73648842, -5.24500592],
                            [39.73657982, -5.24486228],
                            [39.73665163, -5.24477087],
                            [39.7366908, -5.24469905],
                            [39.73677567, -5.24454887],
                            [39.73684096, -5.24437259],
                            [39.73707598, -5.24417018],
                            [39.73711515, -5.24404613],
                            [39.73713474, -5.24384372],
                            [39.73711515, -5.24368049],
                            [39.73699111, -5.24364785],
                            [39.73675609, -5.24359561],
                            [39.73655371, -5.24358255],
                            [39.73637091, -5.24359561],
                            [39.73625993, -5.24359561],
                            [39.73615547, -5.24359561],
                            [39.73594656, -5.24364785],
                            [39.73582252, -5.24370008],
                            [39.73565931, -5.2437719],
                            [39.73556138, -5.24381107],
                            [39.73542428, -5.24380455],
                            [39.73519579, -5.24381107],
                            [39.73498688, -5.24389595],
                            [39.73478449, -5.24400042],
                            [39.73474532, -5.24413753],
                            [39.73462781, -5.24412447],
                            [39.73443849, -5.24401348],
                            [39.7342818, -5.24404612],
                            [39.73426513, -5.24406152],
                            [39.73419693, -5.24412447],
                            [39.73409901, -5.24418977],
                            [39.73392927, -5.24425506],
                            [39.73379217, -5.24429423],
                            [39.73362896, -5.24431382],
                            [39.73345269, -5.24451622],
                            [39.73336782, -5.24459457],
                            [39.73325031, -5.24452928],
                            [39.73316393, -5.24461567],
                            [39.73315891, -5.24462069],
                            [39.73303487, -5.24458152],
                            [39.73302834, -5.24447705],
                            [39.73307404, -5.24432688],
                            [39.73302181, -5.24421588],
                            [39.7328586, -5.24417671],
                            [39.73275414, -5.24411141],
                            [39.73257788, -5.24403959],
                            [39.73239508, -5.24402],
                            [39.73210783, -5.244131],
                            [39.73182058, -5.24427464],
                            [39.73176182, -5.24446399],
                            [39.73170306, -5.24465986],
                            [39.73177488, -5.24481004],
                            [39.73185975, -5.24494062],
                            [39.73197726, -5.24489491],
                            [39.7320556, -5.24485574],
                            [39.73210662, -5.24488831],
                            [39.73216005, -5.24498632],
                            [39.73226483, -5.24505296],
                            [39.73234938, -5.24520179],
                            [39.73242132, -5.2452473],
                            [39.732508, -5.24530213],
                            [39.73244731, -5.2453846],
                            [39.73239508, -5.24543684],
                            [39.73237549, -5.24555436],
                            [39.73237549, -5.24575024],
                            [39.73236896, -5.24580247],
                            [39.73231021, -5.24571106],
                            [39.73225798, -5.24556742],
                            [39.73212741, -5.24556742],
                            [39.73207518, -5.2456523],
                            [39.73203601, -5.24567189],
                            [39.73200337, -5.24564577],
                            [39.73178793, -5.24560007],
                            [39.73176835, -5.24564577],
                            [39.73172265, -5.24569148],
                            [39.73164431, -5.24575024],
                            [39.73156596, -5.24578941],
                            [39.73132441, -5.24536502],
                            [39.73122648, -5.24544989],
                            [39.73121996, -5.24561965],
                            [39.73121343, -5.24577635],
                            [39.73121343, -5.24586123],
                            [39.73113509, -5.24587429],
                            [39.73100452, -5.24575024],
                            [39.73095882, -5.24564577],
                            [39.73089353, -5.2454956],
                            [39.730887, -5.24544337],
                            [39.73088048, -5.24535849],
                            [39.73077602, -5.24528014],
                            [39.73068462, -5.24526055],
                            [39.73062587, -5.24534543],
                            [39.73061934, -5.24541072],
                            [39.730541, -5.24548254],
                            [39.73041696, -5.24532584],
                            [39.73035167, -5.24522137],
                            [39.73024722, -5.24510385],
                            [39.73007095, -5.24468598],
                            [39.7302211, -5.24454887],
                            [39.73045613, -5.24432687],
                            [39.7304953, -5.24418976],
                            [39.73041043, -5.24376536],
                            [39.73011012, -5.24352378],
                            [39.72992732, -5.2435956],
                            [39.72986204, -5.24374577],
                            [39.72986204, -5.24388289],
                            [39.72982287, -5.2439743],
                            [39.72968577, -5.24398735],
                            [39.72956173, -5.24392859],
                            [39.72947686, -5.24384371],
                            [39.72935935, -5.24363478],
                            [39.72922878, -5.24350419],
                            [39.72913738, -5.24338014],
                            [39.72890889, -5.24307327],
                            [39.72858899, -5.24255093],
                            [39.72833438, -5.24202207],
                            [39.72825604, -5.24151932],
                            [39.72819076, -5.24112104],
                            [39.72770765, -5.24061176],
                            [39.72747916, -5.24035059],
                            [39.72724956, -5.24026858],
                            [39.72720496, -5.24025265],
                            [39.72715926, -5.24035712],
                            [39.72697647, -5.24022654],
                            [39.72678061, -5.240331],
                            [39.72674797, -5.2404877],
                            [39.72664352, -5.24061829],
                            [39.72656517, -5.24065093],
                            [39.72631709, -5.24058564],
                            [39.7259515, -5.24048117],
                            [39.72561202, -5.24063135],
                            [39.72541617, -5.24074887],
                            [39.72520726, -5.24082722],
                            [39.72495917, -5.24095127],
                            [39.72485472, -5.24101657],
                            [39.72463928, -5.24099698],
                            [39.72450218, -5.24096433],
                            [39.72442384, -5.2409578],
                            [39.72431939, -5.24088598],
                            [39.72419535, -5.24076846],
                            [39.72408436, -5.24069011],
                            [39.72401255, -5.24065746],
                            [39.72390809, -5.24060523],
                            [39.72381017, -5.24071622],
                            [39.72371877, -5.24076846],
                            [39.72362737, -5.24074234],
                            [39.72347069, -5.24070316],
                            [39.7234511, -5.24082069],
                            [39.72337276, -5.24092516],
                            [39.72328136, -5.24095127],
                            [39.72313121, -5.24101003],
                            [39.72311815, -5.24106227],
                            [39.72312468, -5.24119938],
                            [39.72312796, -5.2412566],
                            [39.72312468, -5.24131691],
                            [39.72304634, -5.2415846],
                            [39.72296147, -5.24177395],
                            [39.72296147, -5.24188495],
                            [39.72305726, -5.24193875],
                            [39.72299411, -5.24206776],
                            [39.72285701, -5.24203512],
                            [39.72276561, -5.24212653],
                            [39.72262851, -5.24218529],
                            [39.72245225, -5.24242034],
                            [39.72229556, -5.2424334],
                            [39.72218458, -5.24260316],
                            [39.72204748, -5.24274027],
                            [39.7218908, -5.24277944],
                            [39.72165577, -5.24281209],
                            [39.72145992, -5.24283168],
                            [39.72126407, -5.24289697],
                            [39.72121184, -5.24298185],
                            [39.72109433, -5.2430602],
                            [39.72097681, -5.24313855],
                            [39.72084625, -5.24324301],
                            [39.72072873, -5.24347806],
                            [39.72057858, -5.24364129],
                            [39.72047412, -5.24374576],
                            [39.72036967, -5.24393511],
                            [39.72034355, -5.24403305],
                            [39.72016728, -5.2443138],
                            [39.72016728, -5.2444248],
                            [39.72016076, -5.24462067],
                            [39.72009547, -5.24476431],
                            [39.72003852, -5.24481793],
                            [39.71998449, -5.24486878],
                            [39.71993879, -5.24487531],
                            [39.71978863, -5.24503854],
                            [39.71986045, -5.24521483],
                            [39.7199649, -5.24535194],
                            [39.72006283, -5.24550211],
                            [39.72023925, -5.24557798],
                            [39.72021298, -5.2456784],
                            [39.72003018, -5.24558046],
                            [39.71982127, -5.24549558],
                            [39.71967112, -5.24543029],
                            [39.71959931, -5.24540417],
                            [39.71942304, -5.24549558],
                            [39.71932511, -5.24546947],
                            [39.71918149, -5.24556087],
                            [39.7190248, -5.24566534],
                            [39.71875714, -5.24567187],
                            [39.71868532, -5.2457241],
                            [39.71867227, -5.24587427],
                            [39.71872449, -5.2459918],
                            [39.71872449, -5.24604403],
                            [39.71870491, -5.24616156],
                            [39.71867879, -5.24625297],
                            [39.7185417, -5.24627908],
                            [39.71846988, -5.24634438],
                            [39.71835237, -5.24639661],
                            [39.71829361, -5.24642925],
                            [39.71828709, -5.24652066],
                            [39.7183132, -5.2465729],
                            [39.71839807, -5.24667736],
                            [39.71843071, -5.24676224],
                            [39.71852787, -5.24679943],
                            [39.71852864, -5.24686018],
                            [39.7184046, -5.24687324],
                            [39.71831973, -5.24686018],
                            [39.71818916, -5.24684712],
                            [39.7180847, -5.24695159],
                            [39.71794108, -5.24708217],
                            [39.71790191, -5.24708381],
                            [39.71786274, -5.24708544],
                            [39.71778439, -5.2470887],
                            [39.71767994, -5.24701688],
                            [39.71747756, -5.24706911],
                            [39.71741227, -5.24710829],
                            [39.71732087, -5.24713441],
                            [39.7171903, -5.24720623],
                            [39.71713155, -5.24727152],
                            [39.71705974, -5.24734987],
                            [39.71690562, -5.24737395],
                            [39.71685083, -5.24738251],
                            [39.71672678, -5.24753921],
                            [39.7167072, -5.24766327],
                            [39.71666803, -5.24768939],
                            [39.71655052, -5.24764368],
                            [39.71640036, -5.24770244],
                            [39.71623715, -5.24785914],
                            [39.71611964, -5.2480289],
                            [39.71610658, -5.24815949],
                            [39.71612617, -5.24825742],
                            [39.71619145, -5.24836842],
                            [39.71624368, -5.24844677],
                            [39.71629446, -5.24846328],
                            [39.71638077, -5.248721],
                            [39.71638077, -5.24883852],
                            [39.71638077, -5.24889076],
                            [39.71626326, -5.24894299],
                            [39.71619798, -5.24889729],
                            [39.71620451, -5.24880588],
                            [39.71624368, -5.24860347],
                            [39.71614575, -5.2484533],
                            [39.7159499, -5.24833577],
                            [39.71579974, -5.24827048],
                            [39.7156757, -5.24822478],
                            [39.71549291, -5.24822478],
                            [39.71542109, -5.24826395],
                            [39.71527747, -5.24838801],
                            [39.71524482, -5.24852512],
                            [39.71522524, -5.24860347],
                            [39.71516648, -5.2486557],
                            [39.71510773, -5.2486557],
                            [39.71495104, -5.24859041],
                            [39.71475519, -5.24862306],
                            [39.71463115, -5.24872752],
                            [39.71453975, -5.2488124],
                            [39.71452669, -5.24893646],
                            [39.71441571, -5.24890381],
                            [39.71421986, -5.24870794],
                            [39.71391302, -5.24887117],
                            [39.71382815, -5.24898216],
                            [39.71369758, -5.24900175],
                            [39.71363882, -5.24894952],
                            [39.71360618, -5.24871446],
                            [39.71359312, -5.24851859],
                            [39.71352122, -5.24847169],
                            [39.71344297, -5.24842065],
                            [39.71329934, -5.24841412],
                            [39.71311655, -5.24838148],
                            [39.71299904, -5.24830965],
                            [39.71283582, -5.24822477],
                            [39.71256163, -5.24816601],
                            [39.71243106, -5.2481856],
                            [39.71221562, -5.24817907],
                            [39.71205241, -5.24815948],
                            [39.71186961, -5.24815295],
                            [39.71174557, -5.24819866],
                            [39.71166723, -5.24825742],
                            [39.71165417, -5.24831618],
                            [39.71159542, -5.2483423],
                            [39.71151708, -5.24833577],
                            [39.71139304, -5.24829006],
                            [39.71130164, -5.24827701],
                            [39.7110405, -5.24829659],
                            [39.71085117, -5.24831618],
                            [39.71065532, -5.24833577],
                            [39.71059004, -5.24835535],
                            [39.71051822, -5.24842718],
                            [39.71036154, -5.24862305],
                            [39.71034195, -5.24884504],
                            [39.71033543, -5.24891034],
                            [39.71030278, -5.24905398],
                            [39.71029625, -5.24914539],
                            [39.71031584, -5.2492825],
                            [39.71040071, -5.24942614],
                            [39.71047252, -5.24951755],
                            [39.71051559, -5.24952898],
                            [39.71055086, -5.24962202],
                            [39.71077283, -5.24974607],
                            [39.71086896, -5.24977129],
                            [39.71102744, -5.24976566],
                            [39.71114665, -5.24979705],
                            [39.71126899, -5.24977219],
                            [39.7113528, -5.24979436],
                            [39.71145598, -5.24982165],
                            [39.71147138, -5.24988972],
                            [39.7114779, -5.25003336],
                            [39.71153013, -5.2501313],
                            [39.71158889, -5.25020965],
                            [39.71160194, -5.25031411],
                            [39.71164764, -5.25037941],
                            [39.71160194, -5.25060793],
                            [39.7117064, -5.25076463],
                            [39.7118402, -5.25079759],
                            [39.71196754, -5.25073851],
                            [39.71205893, -5.25068628],
                            [39.7120912, -5.25069434],
                            [39.71215686, -5.25075157],
                            [39.71224173, -5.25082992],
                            [39.71238625, -5.25086572],
                            [39.71251593, -5.25082339],
                            [39.71274636, -5.25088084],
                            [39.71273789, -5.2510258],
                            [39.71288152, -5.2511825],
                            [39.71295986, -5.25131308],
                            [39.71292069, -5.25144366],
                            [39.71282929, -5.25145019],
                            [39.71273789, -5.25134573],
                            [39.71252245, -5.25129349],
                            [39.712418, -5.25131308],
                            [39.71233313, -5.2513392],
                            [39.71227437, -5.25131961],
                            [39.71226132, -5.25150896],
                            [39.71230701, -5.25162648],
                            [39.71241147, -5.25176359],
                            [39.71252245, -5.25186806],
                            [39.71263645, -5.25189397],
                            [39.71275384, -5.25192065],
                            [39.71289323, -5.25195234],
                            [39.71307737, -5.25194641],
                            [39.71316141, -5.2519656],
                            [39.71322717, -5.25198061],
                            [39.7132667, -5.25210311],
                            [39.7133124, -5.25218799],
                            [39.71342991, -5.25225981],
                            [39.71358676, -5.25229484],
                            [39.71363229, -5.25222064],
                            [39.7136966, -5.25223512],
                            [39.71395218, -5.25240346],
                            [39.71412914, -5.25244287],
                            [39.71419374, -5.25254057],
                            [39.71422638, -5.25261239],
                            [39.71427208, -5.2527495],
                            [39.71427208, -5.25279521],
                            [39.71418721, -5.25285397],
                            [39.71408275, -5.25291926],
                            [39.71403705, -5.2529715],
                            [39.71386078, -5.25303679],
                            [39.71383467, -5.25311514],
                            [39.71392607, -5.25331754],
                            [39.71403052, -5.25341548],
                            [39.71403705, -5.25354607],
                            [39.71404358, -5.25361789],
                            [39.71407622, -5.25376806],
                            [39.71387384, -5.25376806],
                            [39.71381508, -5.25370929],
                            [39.7136584, -5.25362442],
                            [39.71358006, -5.25366359],
                            [39.71353436, -5.25370277],
                            [39.71348866, -5.253644],
                            [39.71348213, -5.25349383],
                            [39.71348213, -5.2533306],
                            [39.71341685, -5.25320655],
                            [39.71333198, -5.25300414],
                            [39.71329281, -5.25293232],
                            [39.71320794, -5.25285397],
                            [39.71306431, -5.25289967],
                            [39.71297292, -5.25291273],
                            [39.71286846, -5.25288009],
                            [39.71269219, -5.2527038],
                            [39.71253551, -5.25267768],
                            [39.71244411, -5.25267115],
                            [39.71232007, -5.25257974],
                            [39.71211769, -5.25259933],
                            [39.71203282, -5.25263198],
                            [39.71192836, -5.25261239],
                            [39.71186961, -5.25255363],
                            [39.71185002, -5.25249486],
                            [39.71186308, -5.25231205],
                            [39.71173251, -5.25210311],
                            [39.71154971, -5.25192029],
                            [39.71137997, -5.25188112],
                            [39.71123635, -5.25195947],
                            [39.71115801, -5.25203782],
                            [39.71115148, -5.25209005],
                            [39.71111884, -5.25216187],
                            [39.71106008, -5.25220105],
                            [39.7108577, -5.25217493],
                            [39.7106292, -5.25220758],
                            [39.71052475, -5.2523251],
                            [39.71047252, -5.25240345],
                            [39.71042029, -5.25260586],
                            [39.71025708, -5.2526385],
                            [39.7100547, -5.25265809],
                            [39.70997962, -5.25272338],
                            [39.70990454, -5.25278867],
                            [39.70989801, -5.25286049],
                            [39.70975439, -5.25311513],
                            [39.70970216, -5.25314778],
                            [39.70963035, -5.25315431],
                            [39.70955201, -5.25303025],
                            [39.7092974, -5.2529519],
                            [39.70909501, -5.25299761],
                            [39.70892527, -5.25303678],
                            [39.70887957, -5.25306943],
                            [39.70880123, -5.25308249],
                            [39.70861191, -5.25310207],
                            [39.70852051, -5.25310207],
                            [39.70839647, -5.25308901],
                            [39.70833118, -5.25313472],
                            [39.70828548, -5.25314125],
                            [39.70814839, -5.25297149],
                            [39.70801782, -5.25287355],
                            [39.70784155, -5.25285396],
                            [39.70775668, -5.25287355],
                            [39.70769792, -5.25293884],
                            [39.70751513, -5.25300413],
                            [39.70719523, -5.25288008],
                            [39.70697979, -5.25289967],
                            [39.70677088, -5.25301066],
                            [39.70669254, -5.25316736],
                            [39.70669254, -5.25336324],
                            [39.706797, -5.25348729],
                            [39.70680352, -5.2535787],
                            [39.70663379, -5.25365052],
                            [39.70641182, -5.25364399],
                            [39.70637918, -5.25365379],
                            [39.70634653, -5.25366358],
                            [39.70624208, -5.25385293],
                            [39.70622902, -5.25418591],
                            [39.70627472, -5.25432956],
                            [39.70643793, -5.25466908],
                            [39.70642161, -5.2547409],
                            [39.70640529, -5.25481272],
                            [39.70630736, -5.25475395],
                            [39.70623555, -5.2545842],
                            [39.70610498, -5.25429038],
                            [39.70591565, -5.25422509],
                            [39.70581773, -5.25406839],
                            [39.70577856, -5.25392475],
                            [39.70573286, -5.25386598],
                            [39.70566105, -5.25381375],
                            [39.70541949, -5.25372234],
                            [39.70532809, -5.25365705],
                            [39.70515183, -5.25357217],
                            [39.7050996, -5.25353299],
                            [39.70498861, -5.25350688],
                            [39.70479929, -5.25348729],
                            [39.70466219, -5.25348076],
                            [39.70448592, -5.25348729],
                            [39.7044337, -5.25350688],
                            [39.70421826, -5.25355911],
                            [39.70412033, -5.25357217],
                            [39.70387878, -5.25360481],
                            [39.70378085, -5.25364399],
                            [39.70371883, -5.25365052],
                            [39.70365681, -5.25365705],
                            [39.70348707, -5.2536701],
                            [39.70336303, -5.25368969],
                            [39.70322593, -5.25371581],
                            [39.70309536, -5.25374845],
                            [39.70294521, -5.25376804],
                            [39.70290613, -5.25378873],
                            [39.70283422, -5.2538268],
                            [39.70260573, -5.25380722],
                            [39.70253392, -5.25384639],
                            [39.70240335, -5.2539378],
                            [39.70221089, -5.25417519],
                            [39.70220749, -5.25417938],
                            [39.70213568, -5.25434261],
                            [39.70207886, -5.25421001],
                            [39.70207692, -5.2542055],
                            [39.70199858, -5.25417938],
                            [39.70194635, -5.25424467],
                            [39.70192739, -5.25425387],
                            [39.70173091, -5.25434914],
                            [39.70169827, -5.25449931],
                            [39.7017505, -5.25455807],
                            [39.70167869, -5.25466907],
                            [39.70154698, -5.25459772],
                            [39.701522, -5.25458419],
                            [39.70142408, -5.25443402],
                            [39.70131962, -5.2542969],
                            [39.70114335, -5.25418591],
                            [39.70092139, -5.25403574],
                            [39.70084305, -5.25402268],
                            [39.70063414, -5.2539835],
                            [39.70050357, -5.25394433],
                            [39.70039911, -5.25391821],
                            [39.70038456, -5.25391027],
                            [39.70025548, -5.25383986],
                            [39.7000531, -5.25378763],
                            [39.6999617, -5.2537811],
                            [39.69983114, -5.25378763],
                            [39.69962875, -5.25377457],
                            [39.69950471, -5.25376151],
                            [39.69934803, -5.25376151],
                            [39.69917829, -5.25377457],
                            [39.69905425, -5.25380068],
                            [39.69898244, -5.25383986],
                            [39.69875394, -5.25399656],
                            [39.69869934, -5.25402262],
                            [39.69846669, -5.25413367],
                            [39.69830348, -5.25419243],
                            [39.69825125, -5.25414673],
                            [39.69821255, -5.25405507],
                            [39.69812721, -5.25385291],
                            [39.6979183, -5.25389862],
                            [39.6978726, -5.25405532],
                            [39.69781704, -5.25407754],
                            [39.69777467, -5.25409449],
                            [39.69757882, -5.25408144],
                            [39.69756825, -5.25407754],
                            [39.69745478, -5.25403573],
                            [39.69715447, -5.25391168],
                            [39.6969782, -5.25397044],
                            [39.6968509, -5.25402267],
                            [39.69672359, -5.2540749],
                            [39.69646245, -5.2540749],
                            [39.69637105, -5.25405205],
                            [39.69627966, -5.2540292],
                            [39.69598588, -5.25395738],
                            [39.69573779, -5.25385944],
                            [39.69557458, -5.25379415],
                            [39.6955093, -5.25370927],
                            [39.69539832, -5.25351992],
                            [39.69534038, -5.25344653],
                            [39.69530039, -5.25339587],
                            [39.69520246, -5.25319999],
                            [39.69513718, -5.25314776],
                            [39.69496091, -5.2532914],
                            [39.69488257, -5.25344157],
                            [39.69478464, -5.25346769],
                            [39.69472588, -5.25344157],
                            [39.6946606, -5.2533371],
                            [39.69446475, -5.25324569],
                            [39.69437988, -5.25322611],
                            [39.6940404, -5.25325222],
                            [39.69379884, -5.25306941],
                            [39.69362258, -5.25290618],
                            [39.69344631, -5.25283435],
                            [39.69324393, -5.25276906],
                            [39.6931003, -5.25271683],
                            [39.69292403, -5.25254054],
                            [39.69266942, -5.25234466],
                            [39.69247357, -5.2522402],
                            [39.69225813, -5.25210308],
                            [39.69182073, -5.25148281],
                            [39.69173586, -5.25129999],
                            [39.69161834, -5.25133917],
                            [39.69156612, -5.25126082],
                            [39.69150736, -5.25114329],
                            [39.69150083, -5.25102577],
                            [39.69135721, -5.25081683],
                            [39.69121358, -5.25066666],
                            [39.69099814, -5.2504512],
                            [39.69075006, -5.25030755],
                            [39.69050851, -5.25024879],
                            [39.69024084, -5.25021614],
                            [39.68996665, -5.25019656],
                            [39.68958147, -5.25022267],
                            [39.68947048, -5.25036632],
                            [39.68947048, -5.25056872],
                            [39.68945743, -5.25078418],
                            [39.68937908, -5.25087559],
                            [39.68937908, -5.25095394],
                            [39.68925504, -5.25106494],
                            [39.68915712, -5.25111717],
                            [39.68905919, -5.25110411],
                            [39.68909183, -5.2509213],
                            [39.68893515, -5.25086253],
                            [39.68888945, -5.25089518],
                            [39.68881764, -5.25087559],
                            [39.68864137, -5.250856],
                            [39.6886022, -5.25092782],
                            [39.68851733, -5.25092782],
                            [39.68848469, -5.25084947],
                            [39.68818438, -5.25060789],
                            [39.68800811, -5.25050996],
                            [39.68785143, -5.25041202],
                            [39.68772739, -5.25024879],
                            [39.68759029, -5.2501182],
                            [39.68760987, -5.25002679],
                            [39.68765091, -5.25003395],
                            [39.68770127, -5.2499615],
                            [39.68762946, -5.24978521],
                            [39.68733568, -5.24970686],
                            [39.68689827, -5.24965463],
                            [39.6868134, -5.24966116],
                            [39.68672853, -5.24966769],
                            [39.68658491, -5.24984398],
                            [39.68649351, -5.24998109],
                            [39.68634335, -5.25008556],
                            [39.68631683, -5.25011364],
                            [39.68623237, -5.25020308],
                            [39.68614097, -5.25029449],
                            [39.68607569, -5.25049689],
                            [39.68616056, -5.25064707],
                            [39.68614097, -5.25084947],
                            [39.6862389, -5.25098005],
                            [39.68619973, -5.25101923],
                            [39.68612791, -5.25100617],
                            [39.68606916, -5.25104535],
                            [39.68595817, -5.25111717],
                            [39.68590595, -5.25114328],
                            [39.68578191, -5.25120858],
                            [39.68569704, -5.25131304],
                            [39.68565786, -5.25144363],
                            [39.68569051, -5.25156768],
                            [39.68567092, -5.25173744],
                            [39.68566439, -5.25191373],
                            [39.68563828, -5.25209002],
                            [39.68567745, -5.25224019],
                            [39.68571662, -5.25238383],
                            [39.68579496, -5.25268417],
                            [39.68585372, -5.25289311],
                            [39.68590594, -5.25304328],
                            [39.68592553, -5.25314774],
                            [39.68589941, -5.25329792],
                            [39.6858733, -5.25367008],
                            [39.68598428, -5.25391166],
                            [39.68599081, -5.25403571],
                            [39.68598428, -5.25418589],
                            [39.68598428, -5.25427729],
                            [39.68597123, -5.25434912],
                            [39.68593858, -5.25446011],
                            [39.68592553, -5.25455805],
                            [39.685919, -5.25496939],
                            [39.68588636, -5.25515221],
                            [39.68588635, -5.25529585],
                            [39.6858276, -5.25544602],
                            [39.68583413, -5.25566801],
                            [39.6858276, -5.25577248],
                            [39.68584718, -5.25587042],
                            [39.68586024, -5.25596835],
                            [39.68586677, -5.25604018],
                            [39.68591247, -5.25613158],
                            [39.68595817, -5.25620993],
                            [39.68598428, -5.25625564],
                            [39.68604304, -5.25632746],
                            [39.68609526, -5.25637969],
                            [39.68609526, -5.25647763],
                            [39.68606915, -5.2565821],
                            [39.68606915, -5.25669309],
                            [39.68608873, -5.25679103],
                            [39.68605609, -5.25692815],
                            [39.68606915, -5.25704567],
                            [39.68611485, -5.25720237],
                            [39.68621277, -5.25737866],
                            [39.68627153, -5.25754189],
                            [39.6863564, -5.25766594],
                            [39.68642168, -5.25780306],
                            [39.6865392, -5.25797935],
                            [39.68659795, -5.25807075],
                            [39.68663712, -5.25820134],
                            [39.68670099, -5.25820521],
                            [39.68675342, -5.25820838],
                            [39.68677422, -5.25823398],
                            [39.68685256, -5.25846251],
                            [39.68690479, -5.25855391],
                            [39.68697007, -5.25861921],
                            [39.68703536, -5.25867144],
                            [39.68708759, -5.25871714],
                            [39.68713981, -5.25884773],
                            [39.68720628, -5.25885119],
                            [39.68728344, -5.25893261],
                            [39.68734889, -5.25893595],
                            [39.68736178, -5.25901096],
                            [39.68739442, -5.25912195],
                            [39.68742054, -5.25922642],
                            [39.6875511, -5.25931783],
                            [39.6876425, -5.25959859],
                            [39.68773538, -5.25960249],
                            [39.68783183, -5.25985322],
                            [39.687871, -5.25995769],
                            [39.68780571, -5.26010786],
                            [39.68779918, -5.26019274],
                            [39.68786447, -5.26027762],
                            [39.68808644, -5.26042779],
                            [39.68817783, -5.2604735],
                            [39.68812561, -5.26062367],
                            [39.68804727, -5.26073467],
                            [39.68802115, -5.2608326],
                            [39.68811255, -5.2609893],
                            [39.68815172, -5.2610546],
                            [39.68827576, -5.26111989],
                            [39.68836726, -5.26112183],
                            [39.68843897, -5.26112642],
                            [39.688517, -5.26112807],
                            [39.68867458, -5.26113141],
                            [39.68875887, -5.26132229],
                            [39.68885679, -5.26145941],
                            [39.68890302, -5.26146017],
                            [39.68901408, -5.26146202],
                            [39.68917051, -5.26146462],
                            [39.68918984, -5.26146494],
                            [39.68925546, -5.26146603],
                            [39.68932031, -5.26162264],
                            [39.68930399, -5.26165528],
                            [39.68928767, -5.26168793],
                            [39.68923544, -5.26189033],
                            [39.68921586, -5.26209927],
                            [39.6892942, -5.26222332],
                            [39.6893399, -5.2623082],
                            [39.68932031, -5.26238655],
                            [39.68942477, -5.26243879],
                            [39.68957492, -5.26271954],
                            [39.6896859, -5.26292195],
                            [39.68978383, -5.26303294],
                            [39.68984912, -5.26323535],
                            [39.69002538, -5.26338552],
                            [39.69012331, -5.26343122],
                            [39.69012984, -5.26352916],
                            [39.69024735, -5.26401885],
                            [39.69020818, -5.26418861],
                            [39.69016901, -5.26437143],
                            [39.69009067, -5.26444978],
                            [39.69007108, -5.26447589],
                            [39.69004497, -5.26451507],
                            [39.68992093, -5.2649917],
                            [39.6899144, -5.26529204],
                            [39.69003191, -5.26549445],
                            [39.6899601, -5.2656185],
                            [39.68977077, -5.26574909],
                            [39.6896859, -5.26586661],
                            [39.68961187, -5.26606053],
                            [39.6895488, -5.26622572],
                            [39.6895488, -5.2664673],
                            [39.68955533, -5.26653259],
                            [39.68969243, -5.26696351],
                            [39.68970249, -5.2671497],
                            [39.68970548, -5.26720509],
                            [39.68958144, -5.26719204],
                            [39.68942682, -5.26727638],
                            [39.689366, -5.26730956],
                            [39.68934642, -5.26741403],
                            [39.68933336, -5.26753155],
                            [39.68932683, -5.26757726],
                            [39.68922891, -5.26763602],
                            [39.68912445, -5.26766867],
                            [39.68909181, -5.26782537],
                            [39.68903305, -5.26784495],
                            [39.68903305, -5.26786454],
                            [39.68910486, -5.26804736],
                            [39.68909181, -5.26824323],
                            [39.68918321, -5.26836729],
                            [39.68915709, -5.26853052],
                            [39.68907222, -5.26857622],
                            [39.68898082, -5.26857622],
                            [39.68881108, -5.26879169],
                            [39.68887637, -5.26901368],
                            [39.68900694, -5.26915079],
                            [39.68901999, -5.26941196],
                            [39.68894818, -5.26952949],
                            [39.68890901, -5.2696666],
                            [39.68888942, -5.26985594],
                            [39.68888942, -5.26996041],
                            [39.68881108, -5.27017587],
                            [39.68897429, -5.27047622],
                            [39.68909833, -5.27071127],
                            [39.68938558, -5.27097244],
                            [39.68968589, -5.27088103],
                            [39.68985563, -5.27072433],
                            [39.68995946, -5.27071235],
                            [39.68994703, -5.27091367],
                            [39.6898948, -5.27105732],
                            [39.68997967, -5.27121402],
                            [39.69002537, -5.27125319],
                            [39.69013875, -5.27123922],
                            [39.69033881, -5.27121456],
                            [39.69036928, -5.27121081],
                            [39.69038444, -5.27120096],
                            [39.69043014, -5.27128584],
                            [39.69043666, -5.27142295],
                            [39.69042361, -5.27146213],
                            [39.69042361, -5.27151436],
                            [39.69020817, -5.271658],
                            [39.69011677, -5.27184735],
                            [39.6900776, -5.27194529],
                            [39.69017552, -5.27242845],
                            [39.69030609, -5.27268961],
                            [39.69068474, -5.2729769],
                            [39.69073044, -5.27295731],
                            [39.69094588, -5.27312707],
                            [39.69116132, -5.27338824],
                            [39.69135717, -5.27364288],
                            [39.69150733, -5.27393669],
                            [39.69162484, -5.27399545],
                            [39.69170971, -5.27414563],
                            [39.69181416, -5.27428927],
                            [39.69202307, -5.27419133],
                            [39.69199043, -5.27434803],
                            [39.69191209, -5.27462226],
                            [39.69206224, -5.27475284],
                            [39.69208183, -5.27486384],
                            [39.69219934, -5.27497483],
                            [39.69222518, -5.27497013],
                            [39.69235602, -5.27524253],
                            [39.69242131, -5.27531435],
                            [39.69253882, -5.27545146],
                            [39.69259758, -5.2755494],
                            [39.69272162, -5.27575834],
                            [39.69276732, -5.27580404],
                            [39.69316555, -5.2758628],
                            [39.69330265, -5.27632638],
                            [39.69372047, -5.27671813],
                            [39.69411218, -5.27721435],
                            [39.69460834, -5.27763222],
                            [39.69471279, -5.27778892],
                            [39.69505227, -5.27815455],
                            [39.69554843, -5.27849407],
                            [39.6960221, -5.2783774],
                            [39.69648853, -5.27857242],
                            [39.69672356, -5.27833737],
                            [39.69682801, -5.27844184],
                            [39.69703812, -5.27873369],
                            [39.69706304, -5.2787683],
                            [39.69766145, -5.27861474],
                            [39.69862566, -5.27845529],
                            [39.69886489, -5.27841573],
                            [39.69925993, -5.27831364],
                            [39.69939632, -5.2782784],
                            [39.69975096, -5.27818676],
                            [39.70071897, -5.27802398],
                            [39.70130653, -5.27828515],
                            [39.70169824, -5.27861161],
                            [39.70169855, -5.27861187],
                            [39.70194632, -5.27882054],
                            [39.70197243, -5.27863772],
                            [39.70185492, -5.27828515],
                            [39.70193955, -5.2782393],
                            [39.70216829, -5.27811539],
                            [39.70239026, -5.27786728],
                            [39.70269746, -5.27778596],
                            [39.70287336, -5.27750165],
                            [39.70313116, -5.27743448],
                            [39.70333035, -5.27748859],
                            [39.70377429, -5.27764529],
                            [39.70428351, -5.27776281],
                            [39.70461946, -5.27767149],
                            [39.70475179, -5.27776939],
                            [39.70488412, -5.27786728],
                            [39.7046491, -5.27799787],
                            [39.70485801, -5.27829821],
                            [39.70509303, -5.27837656],
                            [39.70535417, -5.27821986],
                            [39.70551086, -5.27840268],
                            [39.70547168, -5.27865079],
                            [39.70577199, -5.27875525],
                            [39.70595479, -5.27909477],
                            [39.70621593, -5.27932982],
                            [39.70641178, -5.27944735],
                            [39.70635955, -5.27965628],
                            [39.70646401, -5.27994357],
                            [39.70680349, -5.28027003],
                            [39.70697323, -5.28040061],
                            [39.70707768, -5.28064872],
                            [39.70707768, -5.28083154],
                            [39.70707768, -5.28102741],
                            [39.70714296, -5.28137999],
                            [39.70735188, -5.28168033],
                            [39.70759996, -5.28198068],
                            [39.70786109, -5.28218961],
                            [39.70818363, -5.28229284],
                            [39.70818752, -5.28229408],
                            [39.70848572, -5.28231215],
                            [39.70861839, -5.2823202],
                            [39.70899044, -5.28217746],
                            [39.7091033, -5.28213416],
                            [39.70947969, -5.28198975],
                            [39.70975435, -5.28203291],
                            [39.7100416, -5.28212432],
                            [39.71020214, -5.2820616],
                            [39.71034144, -5.28200718],
                            [39.71051165, -5.28179786],
                            [39.71098257, -5.28161524],
                            [39.71096864, -5.28152364],
                            [39.71153009, -5.28143223],
                            [39.7119559, -5.28114832],
                            [39.71203931, -5.28109271],
                            [39.71256158, -5.28114495],
                            [39.7128669, -5.28102563],
                            [39.71312443, -5.28092499],
                            [39.71314914, -5.2805312],
                            [39.71363225, -5.28012639],
                            [39.71410555, -5.27994757],
                            [39.71457235, -5.27987829],
                            [39.7150424, -5.28017863],
                            [39.71527742, -5.28051815],
                            [39.71553856, -5.28105354],
                            [39.71577358, -5.28121024],
                            [39.71590415, -5.28141918],
                            [39.71598249, -5.2815367],
                            [39.71603472, -5.2817587],
                            [39.71603472, -5.28190234],
                            [39.71600861, -5.28204598],
                            [39.71606083, -5.28228103],
                            [39.71623967, -5.28254932],
                            [39.71632197, -5.28267278],
                            [39.71649171, -5.28340405],
                            [39.71651782, -5.28370439],
                            [39.71649171, -5.28405697],
                            [39.71660922, -5.28431814],
                            [39.71673979, -5.28461848],
                            [39.71688341, -5.2848013],
                            [39.71694619, -5.28482821],
                            [39.71734041, -5.28499718],
                            [39.71769294, -5.28507553],
                            [39.71765626, -5.28545668],
                            [39.71746173, -5.28545668],
                            [39.71726278, -5.28546552],
                            [39.71704173, -5.28543015],
                            [39.71702707, -5.2854268],
                            [39.71688699, -5.28539478],
                            [39.71680741, -5.28536383],
                            [39.71647583, -5.28519581],
                            [39.71637415, -5.28509853],
                            [39.7162813, -5.28499241],
                            [39.71618846, -5.28481997],
                            [39.71617078, -5.28470059],
                            [39.71604699, -5.28452815],
                            [39.71594088, -5.28451046],
                            [39.7158613, -5.2845193],
                            [39.71583036, -5.28452815],
                            [39.71575962, -5.28447509],
                            [39.71568888, -5.28440434],
                            [39.71547225, -5.28421864],
                            [39.71529541, -5.28398872],
                            [39.71510088, -5.28363941],
                            [39.71509068, -5.28355635],
                            [39.71506993, -5.28338738],
                            [39.71507878, -5.28299828],
                            [39.71514509, -5.2827772],
                            [39.71521916, -5.28258937],
                            [39.71526888, -5.28246327],
                            [39.7153352, -5.28222451],
                            [39.7153352, -5.28203438],
                            [39.71526446, -5.28189731],
                            [39.71501246, -5.28134462],
                            [39.71473836, -5.28103068],
                            [39.71446867, -5.28092457],
                            [39.71432278, -5.28096878],
                            [39.71411941, -5.28105721],
                            [39.71397351, -5.28110143],
                            [39.7138453, -5.28115449],
                            [39.71365077, -5.28122081],
                            [39.71364344, -5.2812216],
                            [39.71348719, -5.2812385],
                            [39.71336782, -5.28129155],
                            [39.71321751, -5.28141094],
                            [39.71295918, -5.28155447],
                            [39.71293898, -5.28156569],
                            [39.71274445, -5.28165412],
                            [39.71255435, -5.28174698],
                            [39.71234655, -5.28187962],
                            [39.71225813, -5.28188846],
                            [39.71208571, -5.28190615],
                            [39.71200171, -5.28193268],
                            [39.71191329, -5.28208744],
                            [39.7117895, -5.28225988],
                            [39.71164802, -5.28233946],
                            [39.71139226, -5.2825374],
                            [39.71127665, -5.28262687],
                            [39.71117055, -5.28270645],
                            [39.71106002, -5.28279046],
                            [39.71080802, -5.28296733],
                            [39.71064444, -5.28298501],
                            [39.71060907, -5.28308671],
                            [39.71062233, -5.28316187],
                            [39.71052065, -5.28324146],
                            [39.71041897, -5.283352],
                            [39.7103217, -5.28343159],
                            [39.71016254, -5.28342275],
                            [39.71007412, -5.28338737],
                            [39.70998128, -5.28348907],
                            [39.70994149, -5.2835775],
                            [39.70983538, -5.28370573],
                            [39.70969833, -5.28383395],
                            [39.70964528, -5.28391796],
                            [39.70961433, -5.28399313],
                            [39.70960107, -5.28403734],
                            [39.70954359, -5.28413904],
                            [39.70943306, -5.28420536],
                            [39.70934464, -5.28420536],
                            [39.70915454, -5.28416115],
                            [39.70907054, -5.28416999],
                            [39.70899096, -5.28420536],
                            [39.70879643, -5.28433801],
                            [39.70872569, -5.28439549],
                            [39.70862843, -5.28443971],
                            [39.70856653, -5.28445739],
                            [39.70842064, -5.28451045],
                            [39.70830127, -5.28453698],
                            [39.70810674, -5.28452371],
                            [39.70798295, -5.28457235],
                            [39.70785916, -5.28458119],
                            [39.70772653, -5.28452814],
                            [39.70765137, -5.28458119],
                            [39.70753642, -5.28474921],
                            [39.70738611, -5.28480669],
                            [39.70720042, -5.28478901],
                            [39.707112, -5.28471384],
                            [39.70710758, -5.28439549],
                            [39.70704569, -5.28408598],
                            [39.70696611, -5.28393564],
                            [39.70688268, -5.28387605],
                            [39.70668758, -5.28373667],
                            [39.70651074, -5.28365708],
                            [39.70624989, -5.28369246],
                            [39.70596252, -5.28377647],
                            [39.70594042, -5.28377647],
                            [39.70590052, -5.28377447],
                            [39.70567515, -5.2837632],
                            [39.70556463, -5.28363055],
                            [39.70541873, -5.28340505],
                            [39.70515789, -5.28318397],
                            [39.70496336, -5.28291426],
                            [39.70471578, -5.2827993],
                            [39.70449915, -5.28272855],
                            [39.70445936, -5.28267991],
                            [39.70419852, -5.28243231],
                            [39.70409241, -5.28231735],
                            [39.7040062, -5.28231569],
                            [39.70386252, -5.28231292],
                            [39.7037741, -5.28222891],
                            [39.70368567, -5.28211837],
                            [39.70362378, -5.28205647],
                            [39.70357515, -5.28193267],
                            [39.70357957, -5.28172928],
                            [39.70369452, -5.28145514],
                            [39.70367683, -5.28125175],
                            [39.70361494, -5.2811191],
                            [39.70355304, -5.28081843],
                            [39.70342925, -5.28067694],
                            [39.7033541, -5.28059293],
                            [39.70331431, -5.28054429],
                            [39.70325241, -5.28024805],
                            [39.70315957, -5.28006677],
                            [39.70310652, -5.27986337],
                            [39.70302694, -5.27981031],
                            [39.70290315, -5.27996949],
                            [39.7028103, -5.28010214],
                            [39.70267767, -5.2802171],
                            [39.70252736, -5.28026131],
                            [39.7023063, -5.28023478],
                            [39.70219069, -5.28025519],
                            [39.70215599, -5.28026131],
                            [39.70207199, -5.28028342],
                            [39.70188188, -5.28022152],
                            [39.70162988, -5.28015962],
                            [39.70157461, -5.28016846],
                            [39.70151935, -5.2801773],
                            [39.70136461, -5.28024805],
                            [39.70122314, -5.28025689],
                            [39.70105514, -5.28022594],
                            [39.70095345, -5.28019057],
                            [39.70075893, -5.28011098],
                            [39.70060419, -5.28010656],
                            [39.70049366, -5.28016404],
                            [39.70044061, -5.28021709],
                            [39.70036103, -5.28022594],
                            [39.70020187, -5.28019941],
                            [39.70006924, -5.28029668],
                            [39.6999985, -5.28042933],
                            [39.69996313, -5.28051334],
                            [39.69998082, -5.28076095],
                            [39.70001176, -5.28098645],
                            [39.70001619, -5.28110141],
                            [39.70006482, -5.28138881],
                            [39.70010461, -5.28151261],
                            [39.70013997, -5.28157894],
                            [39.70017976, -5.28167179],
                            [39.70020629, -5.28180886],
                            [39.70024166, -5.28203436],
                            [39.70019303, -5.28222891],
                            [39.70015766, -5.28232618],
                            [39.70014439, -5.28237482],
                            [39.7000291, -5.28248094],
                            [39.7000291, -5.282449],
                            [39.7000291, -5.28219165],
                            [39.699869, -5.28181421],
                            [39.69969745, -5.28127664],
                            [39.69957165, -5.28103645],
                            [39.69952591, -5.28071619],
                            [39.69936654, -5.28037972],
                            [39.69932005, -5.28028156],
                            [39.69916237, -5.28021129],
                            [39.69904158, -5.28015746],
                            [39.69868304, -5.28018262],
                            [39.69860127, -5.28028957],
                            [39.69857611, -5.28048458],
                            [39.69861385, -5.2807425],
                            [39.69855345, -5.28092163],
                            [39.69844402, -5.281013],
                            [39.69831822, -5.28086831],
                            [39.69832451, -5.28044683],
                            [39.6981421, -5.2803336],
                            [39.69808549, -5.28013859],
                            [39.69823645, -5.28000648],
                            [39.69823645, -5.27983034],
                            [39.6980716, -5.2798353],
                            [39.69793453, -5.27978631],
                            [39.69775212, -5.27988067],
                            [39.69753826, -5.27987438],
                            [39.69734956, -5.27998761],
                            [39.69733698, -5.28027069],
                            [39.69721747, -5.28032102],
                            [39.69711683, -5.28044683],
                            [39.69717973, -5.28054748],
                            [39.69735585, -5.28066701],
                            [39.69736843, -5.28066072],
                            [39.69723021, -5.28082395],
                            [39.6971621, -5.28084163],
                            [39.69703506, -5.2808746],
                            [39.69682749, -5.28098783],
                            [39.69670169, -5.28098154],
                            [39.69665766, -5.28088718],
                            [39.69673943, -5.28078024],
                            [39.69668282, -5.28064813],
                            [39.69646798, -5.28051676],
                            [39.69632429, -5.28052861],
                            [39.69612301, -5.28059152],
                            [39.69593431, -5.28052232],
                            [39.695689, -5.28049716],
                            [39.69505656, -5.28094941],
                            [39.69497194, -5.28096267],
                            [39.69485708, -5.28081693],
                            [39.69463857, -5.28084314],
                            [39.6945568, -5.28103816],
                            [39.69446874, -5.28100041],
                            [39.69434294, -5.28092492],
                            [39.69435552, -5.28076765],
                            [39.69425461, -5.28066348],
                            [39.69404102, -5.28067958],
                            [39.69378313, -5.28089976],
                            [39.6936762, -5.28084314],
                            [39.6935814, -5.28060881],
                            [39.69344347, -5.28061668],
                            [39.69338686, -5.28051602],
                            [39.69324922, -5.28048211],
                            [39.69298429, -5.28049086],
                            [39.69283962, -5.28062297],
                            [39.69277672, -5.28071733],
                            [39.69287736, -5.28078023],
                            [39.69301574, -5.28079282],
                            [39.69298553, -5.2808691],
                            [39.69285849, -5.28088089],
                            [39.69261406, -5.28079173],
                            [39.69240816, -5.2808074],
                            [39.69210369, -5.28083056],
                            [39.69199676, -5.28091234],
                            [39.69170742, -5.28110106],
                            [39.69153461, -5.28126565],
                            [39.6914858, -5.28127182],
                            [39.69144324, -5.2812772],
                            [39.69139516, -5.28123449],
                            [39.69133702, -5.28118286],
                            [39.69126249, -5.28119128],
                            [39.69110652, -5.28120889],
                            [39.69100294, -5.28122058],
                            [39.69097778, -5.28131494],
                            [39.69109729, -5.2814093],
                            [39.69112847, -5.28143238],
                            [39.69120168, -5.28148655],
                            [39.69112874, -5.28149737],
                            [39.69106725, -5.28147781],
                            [39.69099036, -5.28145334],
                            [39.69085827, -5.28134011],
                            [39.69070731, -5.2812772],
                            [39.69063812, -5.2813401],
                            [39.69053119, -5.28147221],
                            [39.69053748, -5.28157286],
                            [39.69060911, -5.28183076],
                            [39.69063183, -5.28191256],
                            [39.69063812, -5.28205096],
                            [39.69057522, -5.28218306],
                            [39.69049206, -5.28230282],
                            [39.6902733, -5.28235291],
                            [39.69014616, -5.28234337],
                            [39.6900217, -5.28233404],
                            [39.68998396, -5.28247872],
                            [39.69000072, -5.28250442],
                            [39.69017266, -5.2827681],
                            [39.69021787, -5.28288546],
                            [39.69012234, -5.28291278],
                            [39.69006573, -5.28304489],
                            [39.6900846, -5.28330281],
                            [39.69010976, -5.28347266],
                            [39.69016637, -5.28375574],
                            [39.69007392, -5.28388562],
                            [39.68997137, -5.28392559],
                            [39.69000911, -5.28378719],
                            [39.68997766, -5.28365509],
                            [39.68985186, -5.28360476],
                            [39.68988332, -5.28340346],
                            [39.68987703, -5.28326506],
                            [39.68982671, -5.28297569],
                            [39.68980867, -5.28271708],
                            [39.68980784, -5.28270519],
                            [39.68978897, -5.28259825],
                            [39.68982042, -5.28240324],
                            [39.68995251, -5.28215161],
                            [39.69010976, -5.28227742],
                            [39.69021526, -5.28226687],
                            [39.69029846, -5.28225855],
                            [39.69043055, -5.28215161],
                            [39.69042831, -5.28203095],
                            [39.69042426, -5.28181191],
                            [39.69041168, -5.28144076],
                            [39.69062239, -5.28127405],
                            [39.69058151, -5.28109791],
                            [39.6905878, -5.28102557],
                            [39.69070416, -5.28102871],
                            [39.69089916, -5.28099412],
                            [39.69091488, -5.28083685],
                            [39.69098722, -5.28067014],
                            [39.69104697, -5.28035246],
                            [39.69108471, -5.28024552],
                            [39.69084884, -5.28003478],
                            [39.69061296, -5.27984291],
                            [39.69045257, -5.27969194],
                            [39.69034695, -5.27959303],
                            [39.6901192, -5.27957556],
                            [39.68991163, -5.27959443],
                            [39.68975123, -5.27969823],
                            [39.68967575, -5.27979888],
                            [39.68949648, -5.27987122],
                            [39.68935496, -5.27997816],
                            [39.68914739, -5.28007567],
                            [39.68896183, -5.28017946],
                            [39.68884861, -5.28034302],
                            [39.68885171, -5.28040163],
                            [39.68875112, -5.28040278],
                            [39.68861903, -5.28055062],
                            [39.6885404, -5.28070159],
                            [39.68862532, -5.28083999],
                            [39.68880773, -5.28089346],
                            [39.68887692, -5.28092177],
                            [39.68881087, -5.28099726],
                            [39.68872596, -5.28106646],
                            [39.68852468, -5.28085572],
                            [39.68851524, -5.28080225],
                            [39.68843347, -5.28072676],
                            [39.68823848, -5.28083999],
                            [39.68823534, -5.28083999],
                            [39.68810639, -5.28091548],
                            [39.68810954, -5.28103815],
                            [39.68802148, -5.28106645],
                            [39.68788309, -5.2812017],
                            [39.68787366, -5.28122058],
                            [39.68790299, -5.28131062],
                            [39.68782019, -5.28131808],
                            [39.68777302, -5.28150366],
                            [39.68779561, -5.28152741],
                            [39.68789567, -5.28163262],
                            [39.68782648, -5.28168609],
                            [39.68772584, -5.28174585],
                            [39.68783906, -5.28177416],
                            [39.68783906, -5.28177731],
                            [39.68777931, -5.28182134],
                            [39.68765036, -5.28179618],
                            [39.68750884, -5.28198175],
                            [39.68750884, -5.28197861],
                            [39.68723837, -5.28242839],
                            [39.68723837, -5.28243154],
                            [39.68725095, -5.28251961],
                            [39.68716603, -5.2825605],
                            [39.68716603, -5.28255735],
                            [39.6870591, -5.28268946],
                            [39.6870591, -5.2826926],
                            [39.68698991, -5.28284358],
                            [39.68691714, -5.28302241],
                            [39.68680121, -5.28304803],
                            [39.68678234, -5.28314868],
                            [39.68666283, -5.28320215],
                            [39.68662509, -5.2833028],
                            [39.68651501, -5.28352298],
                            [39.68638607, -5.28377461],
                            [39.68634204, -5.28396333],
                            [39.68635147, -5.28411745],
                            [39.68631059, -5.2842527],
                            [39.68631171, -5.2843851],
                            [39.6863119, -5.28440782],
                            [39.68631283, -5.28451775],
                            [39.68631373, -5.28462385],
                            [39.68634833, -5.28464273],
                            [39.68644268, -5.28461442],
                            [39.68647098, -5.28463644],
                            [39.6863802, -5.28471655],
                            [39.68632946, -5.28473394],
                            [39.68625083, -5.28484403],
                            [39.6862274, -5.28486001],
                            [39.68618164, -5.28489121],
                            [39.68616592, -5.2848629],
                            [39.68610931, -5.28493839],
                            [39.68609987, -5.28496355],
                            [39.68610616, -5.28501388],
                            [39.6861439, -5.28502017],
                            [39.6861785, -5.28502961],
                            [39.68617535, -5.2850988],
                            [39.68614076, -5.285168],
                            [39.68619108, -5.28524978],
                            [39.68632631, -5.28535987],
                            [39.68649614, -5.28541648],
                            [39.68648671, -5.28547625],
                            [39.68632317, -5.2854731],
                            [39.68632631, -5.28554544],
                            [39.68621624, -5.28553286],
                            [39.68616592, -5.28539761],
                            [39.68596464, -5.28526236],
                            [39.6858294, -5.28525293],
                            [39.68580739, -5.28531269],
                            [39.68577279, -5.28529696],
                            [39.68568159, -5.28535987],
                            [39.68563755, -5.28542906],
                            [39.68560296, -5.28553286],
                            [39.6855778, -5.28550141],
                            [39.68553691, -5.28553915],
                            [39.68544256, -5.28562408],
                            [39.68540168, -5.28573102],
                            [39.68543942, -5.28585998],
                            [39.68548659, -5.2859166],
                            [39.6854803, -5.28598894],
                            [39.68547401, -5.28604556],
                            [39.6854132, -5.28596922],
                            [39.68534192, -5.28599837],
                            [39.68521927, -5.28602354],
                            [39.68515322, -5.28608959],
                            [39.68526512, -5.28626468],
                            [39.68520354, -5.28629089],
                            [39.68508718, -5.28613992],
                            [39.68508403, -5.28613677],
                            [39.68496767, -5.28614621],
                            [39.6848859, -5.28621226],
                            [39.68479784, -5.28632864],
                            [39.68478211, -5.28643558],
                            [39.68491106, -5.28667148],
                            [39.68509032, -5.28674382],
                            [39.68519725, -5.28680044],
                            [39.68515951, -5.2868948],
                            [39.6852287, -5.28702376],
                            [39.68527588, -5.28709925],
                            [39.68540168, -5.2871307],
                            [39.68541426, -5.28719361],
                            [39.68536708, -5.28722821],
                            [39.68531991, -5.28726281],
                            [39.68512806, -5.2874012],
                            [39.6851029, -5.28738862],
                            [39.6851029, -5.28732257],
                            [39.68511234, -5.28729112],
                            [39.68510919, -5.2871653],
                            [39.68508403, -5.28709925],
                            [39.68501799, -5.28697973],
                            [39.68493936, -5.28691682],
                            [39.68487961, -5.28697344],
                            [39.68482614, -5.28704892],
                            [39.68474752, -5.28716216],
                            [39.68470349, -5.28712756],
                            [39.68459468, -5.28706003],
                            [39.6845368, -5.28708667],
                            [39.68453366, -5.28726595],
                            [39.68448963, -5.28725652],
                            [39.68448648, -5.28733515],
                            [39.68438758, -5.28734512],
                            [39.68433552, -5.28736975],
                            [39.68435753, -5.28741378],
                            [39.68440156, -5.28742322],
                            [39.6843481, -5.2874704],
                            [39.68428834, -5.28762767],
                            [39.68402102, -5.28764969],
                            [39.68407763, -5.28771259],
                            [39.68413424, -5.28783526],
                            [39.68401158, -5.28793277],
                            [39.68388893, -5.28796422],
                            [39.68369079, -5.28793277],
                            [39.68355707, -5.28781869],
                            [39.68348951, -5.28785099],
                            [39.68337629, -5.28795164],
                            [39.6832374, -5.28814889],
                            [39.683067, -5.28823224],
                            [39.6830555, -5.28823787],
                            [39.68296744, -5.28818125],
                            [39.68280705, -5.28807116],
                            [39.68269383, -5.28816867],
                            [39.68262464, -5.28831965],
                            [39.68256803, -5.28837312],
                            [39.68252085, -5.28833223],
                            [39.68250513, -5.28831336],
                            [39.68249702, -5.28830295],
                            [39.68244223, -5.28832908],
                            [39.68243298, -5.2883895],
                            [39.68239505, -5.28840772],
                            [39.68232901, -5.28841086],
                            [39.68216861, -5.28845175],
                            [39.68216232, -5.28857127],
                            [39.68209313, -5.28864047],
                            [39.68205038, -5.28861796],
                            [39.6819516, -5.28866563],
                            [39.68173145, -5.28882919],
                            [39.6817346, -5.28891097],
                            [39.68179121, -5.2890588],
                            [39.68160742, -5.28893032],
                            [39.68149243, -5.28898646],
                            [39.68139179, -5.28910913],
                            [39.68134776, -5.28906824],
                            [39.68123769, -5.2889613],
                            [39.68111503, -5.28898646],
                            [39.68093891, -5.28895815],
                            [39.68087355, -5.28895259],
                            [39.6807911, -5.28894557],
                            [39.68072852, -5.28896059],
                            [39.68063385, -5.28898331],
                            [39.68055522, -5.2889959],
                            [39.68056014, -5.28905185],
                            [39.6805049, -5.28907767],
                            [39.68047291, -5.28899055],
                            [39.6804137, -5.28901791],
                            [39.68043715, -5.28908436],
                            [39.68040426, -5.28909969],
                            [39.68035394, -5.28907138],
                            [39.68030048, -5.28917204],
                            [39.68025645, -5.2891626],
                            [39.68015266, -5.28907138],
                            [39.68005202, -5.28914058],
                            [39.67988219, -5.28928841],
                            [39.67980671, -5.28928841],
                            [39.67967776, -5.28928527],
                            [39.67953624, -5.28931358],
                            [39.67951108, -5.28938906],
                            [39.67954565, -5.28947435],
                            [39.67955825, -5.28950544],
                            [39.67955825, -5.2896344],
                            [39.67950793, -5.28972876],
                            [39.6795268, -5.28974921],
                            [39.67954567, -5.28976965],
                            [39.67966204, -5.29003072],
                            [39.67975639, -5.29009048],
                            [39.67977211, -5.29012036],
                            [39.67978784, -5.29015024],
                            [39.67964891, -5.29028106],
                            [39.67946076, -5.29037671],
                            [39.67939786, -5.29051196],
                            [39.67938213, -5.29044276],
                            [39.67938213, -5.29037042],
                            [39.67938213, -5.29032324],
                            [39.67945447, -5.29022887],
                            [39.67947648, -5.29015339],
                            [39.67947648, -5.29010112],
                            [39.67947648, -5.29009048],
                            [39.67947648, -5.29005273],
                            [39.67944042, -5.28997407],
                            [39.67937584, -5.29000555],
                            [39.67933496, -5.28999926],
                            [39.67926891, -5.2899741],
                            [39.67914626, -5.28994579],
                            [39.67904823, -5.28994214],
                            [39.67901417, -5.28995837],
                            [39.67893554, -5.28996152],
                            [39.6789324, -5.28995837],
                            [39.67890095, -5.28993636],
                            [39.67881289, -5.28989232],
                            [39.67876257, -5.28984829],
                            [39.67869967, -5.28972562],
                            [39.67872797, -5.28964698],
                            [39.67883176, -5.28967215],
                            [39.67892296, -5.28973191],
                            [39.67896699, -5.28975707],
                            [39.6790865, -5.28970989],
                            [39.67909279, -5.2897036],
                            [39.67909279, -5.28957149],
                            [39.67909279, -5.28956835],
                            [39.67898901, -5.28928527],
                            [39.67884931, -5.28916584],
                            [39.67876257, -5.28920349],
                            [39.67863991, -5.28917203],
                            [39.67840404, -5.28920978],
                            [39.67823421, -5.28919405],
                            [39.67797946, -5.28915001],
                            [39.67785052, -5.28904622],
                            [39.67764609, -5.28907138],
                            [39.67746368, -5.28909654],
                            [39.67682524, -5.28898331],
                            [39.67635664, -5.28887007],
                            [39.67617387, -5.28877037],
                            [39.67614907, -5.28875684],
                            [39.67574336, -5.28863417],
                            [39.67538169, -5.28851465],
                            [39.67516468, -5.28833536],
                            [39.67515525, -5.28821898],
                            [39.67514896, -5.28808688],
                            [39.67507348, -5.2878321],
                            [39.67499171, -5.28777863],
                            [39.67495082, -5.28759935],
                            [39.67487849, -5.28748611],
                            [39.67478414, -5.28735715],
                            [39.67464576, -5.28715271],
                            [39.67458286, -5.2870206],
                            [39.67444333, -5.28696687],
                            [39.67437214, -5.286986],
                            [39.67434698, -5.28689479],
                            [39.67423062, -5.28683188],
                            [39.67417715, -5.2868822],
                            [39.67413627, -5.28692309],
                            [39.67410796, -5.28689793],
                            [39.67399789, -5.2868822],
                            [39.67390668, -5.28685704],
                            [39.67389725, -5.28681615],
                            [39.67386265, -5.28679728],
                            [39.67379975, -5.28684761],
                            [39.67373987, -5.28690399],
                            [39.67367999, -5.28696038],
                            [39.67362992, -5.28697342],
                            [39.67358589, -5.2869168],
                            [39.67360476, -5.28684446],
                            [39.67362992, -5.28675953],
                            [39.67358904, -5.28664945],
                            [39.67346009, -5.28657396],
                            [39.67339495, -5.2866565],
                            [39.67334687, -5.28666832],
                            [39.67332171, -5.28661799],
                            [39.67317075, -5.28646072],
                            [39.67303552, -5.28632862],
                            [39.67290051, -5.28627115],
                            [39.67279964, -5.28629402],
                            [39.67265183, -5.28622482],
                            [39.67264239, -5.28614933],
                            [39.6725732, -5.28602352],
                            [39.67243168, -5.28598577],
                            [39.67230016, -5.28596333],
                            [39.67220838, -5.28598263],
                            [39.67214234, -5.28601723],
                            [39.67211089, -5.28599206],
                            [39.67195364, -5.28596061],
                            [39.67185614, -5.28601408],
                            [39.67178066, -5.28594488],
                            [39.67162027, -5.28594174],
                            [39.67147874, -5.28590085],
                            [39.67138753, -5.28588512],
                            [39.67130262, -5.28592287],
                            [39.6712523, -5.28591028],
                            [39.67119883, -5.28585052],
                            [39.67114537, -5.28580334],
                            [39.67108561, -5.28575931],
                            [39.6710636, -5.28574358],
                            [39.67100161, -5.28571307],
                            [39.67094094, -5.28572471],
                            [39.67089062, -5.28571213],
                            [39.67086861, -5.28564293],
                            [39.67078684, -5.28553599],
                            [39.67066104, -5.2854605],
                            [39.67060128, -5.28540388],
                            [39.67054153, -5.28537557],
                            [39.67048492, -5.28541961],
                            [39.6704346, -5.2853913],
                            [39.67036226, -5.28537557],
                            [39.67025848, -5.2853567],
                            [39.67017356, -5.28535041],
                            [39.67015469, -5.2853913],
                            [39.67011309, -5.28548479],
                            [39.67007292, -5.28549195],
                            [39.67000688, -5.28548251],
                            [39.66998172, -5.28541332],
                            [39.67000688, -5.28526863],
                            [39.67003204, -5.2852183],
                            [39.67011381, -5.28516483],
                            [39.67012324, -5.28507362],
                            [39.67010437, -5.28496982],
                            [39.66995656, -5.28484401],
                            [39.6698978, -5.28489953],
                            [39.66983076, -5.28491006],
                            [39.66976786, -5.28476223],
                            [39.66951626, -5.28468988],
                            [39.66926151, -5.28449172],
                            [39.66906652, -5.28438164],
                            [39.66906023, -5.2843376],
                            [39.66897217, -5.28430615],
                            [39.66896665, -5.28436847],
                            [39.66891556, -5.28437535],
                            [39.6688621, -5.28429357],
                            [39.66882436, -5.28414573],
                            [39.66871428, -5.28404823],
                            [39.66855804, -5.28400308],
                            [39.66841791, -5.28402004],
                            [39.66831487, -5.2840325],
                            [39.66825197, -5.28394128],
                            [39.66821423, -5.28386265],
                            [39.66827084, -5.28376829],
                            [39.66823624, -5.28366449],
                            [39.66818592, -5.28358271],
                            [39.66808214, -5.28363304],
                            [39.66806012, -5.2835607],
                            [39.6680098, -5.28341286],
                            [39.66793118, -5.28316753],
                            [39.66784626, -5.28301655],
                            [39.66777393, -5.28285299],
                            [39.66763869, -5.28280266],
                            [39.66759466, -5.28276177],
                            [39.66759466, -5.28265483],
                            [39.66753491, -5.28260136],
                            [39.66742798, -5.28254789],
                            [39.66730532, -5.28251958],
                            [39.66724871, -5.28249127],
                            [39.66728331, -5.28239691],
                            [39.66719138, -5.28227827],
                            [39.66705372, -5.28228682],
                            [39.66703171, -5.28226795],
                            [39.66700655, -5.28217359],
                            [39.66690905, -5.28211697],
                            [39.66680841, -5.28210125],
                            [39.66672035, -5.28214528],
                            [39.66659141, -5.28222077],
                            [39.66646561, -5.28229311],
                            [39.66632314, -5.28228069],
                            [39.66616998, -5.28228997],
                            [39.66608192, -5.2822711],
                            [39.66606305, -5.28220819],
                            [39.66604103, -5.28212326],
                            [39.6660316, -5.28207923],
                            [39.66598062, -5.28202896],
                            [39.66586177, -5.28203519],
                            [39.66581459, -5.28211697],
                            [39.66581557, -5.28224995],
                            [39.66577685, -5.28225222],
                            [39.66571081, -5.28215786],
                            [39.66564476, -5.28201003],
                            [39.6655339, -5.28196605],
                            [39.66540889, -5.28197229],
                            [39.66524849, -5.28204463],
                            [39.66522647, -5.28212955],
                            [39.66527365, -5.28227424],
                            [39.66524321, -5.28236309],
                            [39.66520446, -5.28236546],
                            [39.66521389, -5.28231513],
                            [39.66516043, -5.28224908],
                            [39.66497516, -5.28214745],
                            [39.66489939, -5.28215157],
                            [39.66478617, -5.28206665],
                            [39.66466352, -5.28210439],
                            [39.6645157, -5.28202261],
                            [39.6643181, -5.28192081],
                            [39.66422636, -5.2819251],
                            [39.66415116, -5.28202188],
                            [39.66400936, -5.2820289],
                            [39.66397476, -5.28198487],
                            [39.66389268, -5.28191824],
                            [39.66367599, -5.28192825],
                            [39.66344326, -5.28196285],
                            [39.66337407, -5.28202575],
                            [39.66330488, -5.28210124],
                            [39.66325456, -5.28205406],
                            [39.6631319, -5.28202261],
                            [39.66307844, -5.28209495],
                            [39.66279539, -5.28214528],
                            [39.66259398, -5.28217751],
                            [39.66248717, -5.28218302],
                            [39.66242427, -5.28237803],
                            [39.66241798, -5.28255732],
                            [39.66246201, -5.28266112],
                            [39.66254693, -5.28276806],
                            [39.66254378, -5.28284984],
                            [39.6624683, -5.2828058],
                            [39.66246201, -5.28275548],
                            [39.66236766, -5.28262023],
                            [39.66227646, -5.28248812],
                            [39.66224501, -5.28244094],
                            [39.66216324, -5.28236231],
                            [39.66208783, -5.28241379],
                            [39.66205316, -5.28241578],
                            [39.66205002, -5.28230569],
                            [39.6619651, -5.28224907],
                            [39.66181993, -5.28222037],
                            [39.66156586, -5.28223351],
                            [39.66120401, -5.28225222],
                            [39.66096499, -5.28232771],
                            [39.66072283, -5.28237174],
                            [39.66060284, -5.28230592],
                            [39.66048695, -5.28231198],
                            [39.66033851, -5.28240114],
                            [39.66018503, -5.28240948],
                            [39.66015673, -5.28238432],
                            [39.66009068, -5.28231512],
                            [39.66003093, -5.2823623],
                            [39.66003407, -5.28251013],
                            [39.65992085, -5.28256361],
                            [39.65982335, -5.2826328],
                            [39.65959691, -5.28276805],
                            [39.65959691, -5.28284983],
                            [39.65956861, -5.2829033],
                            [39.65950885, -5.2828687],
                            [39.65948684, -5.28283411],
                            [39.65941765, -5.28279322],
                            [39.65938934, -5.28285298],
                            [39.65948684, -5.2830071],
                            [39.65948684, -5.28309517],
                            [39.65943652, -5.28313606],
                            [39.65935789, -5.28305743],
                            [39.6592258, -5.28296307],
                            [39.65902767, -5.28303226],
                            [39.65892388, -5.28312662],
                            [39.6589459, -5.28318009],
                            [39.6589451, -5.2832321],
                            [39.65883897, -5.28323986],
                            [39.65873204, -5.28316751],
                            [39.65865656, -5.28321784],
                            [39.65861882, -5.28333422],
                            [39.65863454, -5.2834789],
                            [39.65853076, -5.28351036],
                            [39.65840906, -5.28338465],
                            [39.65824456, -5.28339712],
                            [39.65809238, -5.28337778],
                            [39.6580024, -5.28338454],
                            [39.657842, -5.28350407],
                            [39.65772563, -5.28357955],
                            [39.65759983, -5.28362673],
                            [39.65757467, -5.28379344],
                            [39.65767217, -5.28404192],
                            [39.65772249, -5.28417088],
                            [39.65777281, -5.28427782],
                            [39.65770047, -5.28434073],
                            [39.65760298, -5.28428411],
                            [39.65744573, -5.28414572],
                            [39.65729162, -5.28411741],
                            [39.65697398, -5.28417717],
                            [39.65692051, -5.28422435],
                            [39.65690479, -5.28424951],
                            [39.65688592, -5.28439106],
                            [39.65679786, -5.28442565],
                            [39.65669722, -5.28440364],
                            [39.65653997, -5.28451372],
                            [39.65647707, -5.28463954],
                            [39.6564865, -5.28474019],
                            [39.65644876, -5.28479366],
                            [39.65635441, -5.28485657],
                            [39.65628208, -5.28491947],
                            [39.65609967, -5.28490689],
                            [39.65595499, -5.28495093],
                            [39.65585121, -5.28502327],
                            [39.65575686, -5.28509561],
                            [39.65575686, -5.28521199],
                            [39.65581032, -5.28528119],
                            [39.65596959, -5.28538955],
                            [39.65590153, -5.28539757],
                            [39.65564993, -5.28528748],
                            [39.6554801, -5.28541959],
                            [39.65547381, -5.28553282],
                            [39.65559762, -5.28563974],
                            [39.65554614, -5.28564605],
                            [39.6554801, -5.28560516],
                            [39.65539528, -5.28552736],
                            [39.65524422, -5.2855454],
                            [39.65514044, -5.28558629],
                            [39.6551656, -5.2857121],
                            [39.65507754, -5.28574985],
                            [39.654976, -5.285716],
                            [39.65496432, -5.2857121],
                            [39.6548165, -5.28586623],
                            [39.65475046, -5.2859197],
                            [39.65481021, -5.28606124],
                            [39.65487311, -5.28620592],
                            [39.65489198, -5.28623738],
                            [39.65487166, -5.28628765],
                            [39.65484795, -5.28629085],
                            [39.65479657, -5.28632505],
                            [39.65474731, -5.28633174],
                            [39.65472215, -5.28630972],
                            [39.65468127, -5.28622165],
                            [39.65468441, -5.28613987],
                            [39.65474102, -5.28611156],
                            [39.65456805, -5.28594171],
                            [39.65440136, -5.28586937],
                            [39.65416863, -5.28587881],
                            [39.6540334, -5.28585993],
                            [39.65386356, -5.28599833],
                            [39.65367801, -5.28614302],
                            [39.65355535, -5.28624996],
                            [39.65344213, -5.28629399],
                            [39.65331727, -5.28655251],
                            [39.65325173, -5.28656144],
                            [39.65320626, -5.28656764],
                            [39.65316852, -5.28661167],
                            [39.65321569, -5.28666514],
                            [39.65325658, -5.28672805],
                            [39.65321884, -5.28678152],
                            [39.6531182, -5.28682556],
                            [39.65307102, -5.28690105],
                            [39.65308675, -5.28701742],
                            [39.65314021, -5.28703944],
                            [39.65320311, -5.28703944],
                            [39.65322513, -5.28706146],
                            [39.6531465, -5.28711179],
                            [39.65319053, -5.28722816],
                            [39.65332891, -5.2877094],
                            [39.65340754, -5.2877094],
                            [39.65343584, -5.2877786],
                            [39.65353334, -5.28798934],
                            [39.65367486, -5.28803652],
                            [39.65380695, -5.28803967],
                            [39.653873, -5.2879862],
                            [39.65392017, -5.2880208],
                            [39.65404597, -5.28809628],
                            [39.65416234, -5.28812459],
                            [39.65414347, -5.28817492],
                            [39.65417177, -5.28828186],
                            [39.65431644, -5.28836364],
                            [39.65442652, -5.28836679],
                            [39.65447684, -5.28834477],
                            [39.6545303, -5.28831961],
                            [39.65464981, -5.28836679],
                            [39.65463736, -5.28844446],
                            [39.65454603, -5.28846115],
                            [39.65457433, -5.28852405],
                            [39.65466554, -5.28856809],
                            [39.65473158, -5.28856809],
                            [39.65480077, -5.28853664],
                            [39.65484795, -5.28847373],
                            [39.65489827, -5.288458],
                            [39.65493286, -5.28847373],
                            [39.65500834, -5.28855236],
                            [39.65514043, -5.28867503],
                            [39.65521277, -5.28877883],
                            [39.65535429, -5.28875367],
                            [39.65550525, -5.28876625],
                            [39.65560904, -5.28885117],
                            [39.65571911, -5.28889521],
                            [39.65576314, -5.2889015],
                            [39.65581346, -5.2889361],
                            [39.65584491, -5.28898957],
                            [39.65588265, -5.28905248],
                            [39.65591725, -5.28910595],
                            [39.65597386, -5.28912797],
                            [39.65604305, -5.28909966],
                            [39.65606506, -5.28912482],
                            [39.65606506, -5.28915313],
                            [39.65607135, -5.28920345],
                            [39.65611538, -5.28923491],
                            [39.65619401, -5.28919087],
                            [39.65624118, -5.28920974],
                            [39.65642045, -5.28931983],
                            [39.65642988, -5.289345],
                            [39.65650536, -5.28941419],
                            [39.65655568, -5.28948025],
                            [39.65660286, -5.28953686],
                            [39.65672866, -5.28959977],
                            [39.65686075, -5.28965324],
                            [39.65691736, -5.28968784],
                            [39.65701485, -5.28975075],
                            [39.65713437, -5.28977277],
                            [39.65723815, -5.28977277],
                            [39.65737339, -5.28976333],
                            [39.65748032, -5.28975704],
                            [39.65759039, -5.2897476],
                            [39.65764071, -5.28977277],
                            [39.65782627, -5.28990173],
                            [39.65802126, -5.2900244],
                            [39.65829802, -5.29018166],
                            [39.65891444, -5.29071009],
                            [39.65941449, -5.29122592],
                            [39.65981705, -5.29169458],
                            [39.65996801, -5.29192105],
                            [39.65996801, -5.29198081],
                            [39.65996172, -5.29204057],
                            [39.65993971, -5.29211292],
                            [39.65995229, -5.29220728],
                            [39.66008123, -5.29235196],
                            [39.66008013, -5.29245049],
                            [39.66001079, -5.292472],
                            [39.65996172, -5.29248721],
                            [39.65992713, -5.29255641],
                            [39.659946, -5.29261932],
                            [39.65995543, -5.2926319],
                            [39.66001519, -5.29264134],
                            [39.6601001, -5.29268537],
                            [39.6601001, -5.29272312],
                            [39.66004664, -5.29278602],
                            [39.66002148, -5.29285208],
                            [39.66006551, -5.29291184],
                            [39.66014413, -5.29288982],
                            [39.66019445, -5.29284579],
                            [39.66022905, -5.29279231],
                            [39.66027937, -5.29283635],
                            [39.66034856, -5.29283635],
                            [39.66043662, -5.29276401],
                            [39.66048065, -5.29270739],
                            [39.6605121, -5.29262876],
                            [39.66052468, -5.29257214],
                            [39.6606033, -5.29250923],
                            [39.66064576, -5.29252653],
                            [39.66068822, -5.29254383],
                            [39.66090389, -5.29275004],
                            [39.66097441, -5.29281748],
                            [39.66113166, -5.29311629],
                            [39.66119142, -5.29318863],
                            [39.66134552, -5.29322323],
                            [39.66147447, -5.29325783],
                            [39.66166317, -5.29353148],
                            [39.66183614, -5.29371705],
                            [39.66207202, -5.29411966],
                            [39.66225128, -5.29439016],
                            [39.66237708, -5.29468582],
                            [39.66247458, -5.2950381],
                            [39.66243055, -5.29527715],
                            [39.66243684, -5.2955508],
                            [39.66251546, -5.29576783],
                            [39.66262554, -5.29581815],
                            [39.66272303, -5.29587792],
                            [39.66274269, -5.29601499],
                            [39.66268844, -5.29603833],
                            [39.66259409, -5.2960226],
                            [39.66257836, -5.29608866],
                            [39.66266013, -5.29620189],
                            [39.66263812, -5.2962585],
                            [39.66263811, -5.29638117],
                            [39.66269787, -5.29645352],
                            [39.66277335, -5.29645352],
                            [39.66284254, -5.29640634],
                            [39.66288657, -5.29640634],
                            [39.66293689, -5.29643465],
                            [39.6630281, -5.29658562],
                            [39.66292116, -5.296702],
                            [39.66291802, -5.29670515],
                            [39.6630218, -5.29689701],
                            [39.66319163, -5.29711719],
                            [39.66326082, -5.29718639],
                            [39.66335203, -5.29717695],
                            [39.66341493, -5.29712662],
                            [39.66341493, -5.29701968],
                            [39.66348727, -5.29701025],
                            [39.6635313, -5.29703541],
                            [39.66355331, -5.29713292],
                            [39.66361621, -5.29713921],
                            [39.66367911, -5.29708574],
                            [39.66371371, -5.29716437],
                            [39.66383007, -5.29727131],
                            [39.66399676, -5.29730277],
                            [39.66416659, -5.2973468],
                            [39.66432069, -5.29738455],
                            [39.66451254, -5.29744431],
                            [39.66457544, -5.2975827],
                            [39.66459745, -5.29770537],
                            [39.6645377, -5.29782804],
                            [39.66449052, -5.29788151],
                            [39.6644402, -5.29797587],
                            [39.66459627, -5.29812157],
                            [39.66451254, -5.2981646],
                            [39.66452197, -5.29825896],
                            [39.66465406, -5.29837219],
                            [39.66478301, -5.29842251],
                            [39.66488679, -5.2986364],
                            [39.66502203, -5.29886287],
                            [39.66510065, -5.29890376],
                            [39.66517613, -5.29889746],
                            [39.66517299, -5.29878738],
                            [39.6652296, -5.29880625],
                            [39.66530508, -5.29888488],
                            [39.66547491, -5.29912708],
                            [39.66574299, -5.2995758],
                            [39.6657835, -5.29964361],
                            [39.66592779, -5.29988511],
                            [39.66622027, -5.3003978],
                            [39.66639954, -5.30079726],
                            [39.66656307, -5.30108035],
                            [39.66678926, -5.30148194],
                            [39.66679209, -5.30148696],
                            [39.66699508, -5.30200165],
                            [39.66725526, -5.30293668],
                            [39.66741537, -5.30354002],
                            [39.66746683, -5.30387171],
                            [39.66751829, -5.3042034],
                            [39.66753104, -5.30436696],
                            [39.6675526, -5.30464375],
                            [39.66755546, -5.30484677],
                            [39.66758691, -5.30504979],
                            [39.66758284, -5.3050969],
                            [39.6675669, -5.3052814],
                            [39.66753831, -5.30550158],
                            [39.66750114, -5.30550158],
                            [39.66736104, -5.30551302],
                            [39.66730386, -5.30558736],
                            [39.66712142, -5.30559611],
                            [39.66707799, -5.30563025],
                            [39.66706941, -5.30571604],
                            [39.66703796, -5.30582469],
                            [39.66702939, -5.30594765],
                            [39.66708657, -5.30610492],
                            [39.66720665, -5.30608776],
                            [39.66741536, -5.30614495],
                            [39.66742108, -5.30623931],
                            [39.6675526, -5.30638228],
                            [39.66765267, -5.30645377],
                            [39.66779562, -5.30645377],
                            [39.66791571, -5.30647378],
                            [39.66798146, -5.3065081],
                            [39.66801291, -5.30657386],
                            [39.6680701, -5.30660532],
                            [39.66817016, -5.30661675],
                            [39.66820161, -5.30670825],
                            [39.66821901, -5.30683247],
                            [39.66815015, -5.30689126],
                            [39.66805866, -5.30693701],
                            [39.66798146, -5.30696846],
                            [39.66796145, -5.30701993],
                            [39.66795573, -5.30709142],
                            [39.66799576, -5.30708856],
                            [39.66805866, -5.30712001],
                            [39.6680558, -5.30716576],
                            [39.6680072, -5.3071772],
                            [39.66797575, -5.3072687],
                            [39.66805008, -5.30729729],
                            [39.66809583, -5.30735734],
                            [39.6680415, -5.30735734],
                            [39.66792714, -5.30738594],
                            [39.66791284, -5.3074517],
                            [39.66798146, -5.30762327],
                            [39.66808439, -5.30764042],
                            [39.66814729, -5.3077405],
                            [39.66820161, -5.30782057],
                            [39.66812728, -5.30785774],
                            [39.66813585, -5.30796354],
                            [39.66825308, -5.30801787],
                            [39.66832169, -5.30810079],
                            [39.66836744, -5.30815798],
                            [39.66836172, -5.30825806],
                            [39.66839603, -5.30830667],
                            [39.66848193, -5.30830176],
                            [39.6684961, -5.30830095],
                            [39.66857901, -5.30833812],
                            [39.66862476, -5.30844678],
                            [39.66859331, -5.30854114],
                            [39.6686076, -5.3086498],
                            [39.66866479, -5.30874416],
                            [39.6687477, -5.30876132],
                            [39.66875914, -5.30886426],
                            [39.66881632, -5.30893002],
                            [39.66892782, -5.30890715],
                            [39.66897643, -5.30891287],
                            [39.66901359, -5.30906728],
                            [39.66915023, -5.30988014],
                            [39.66917942, -5.31005377],
                            [39.66921087, -5.31074289],
                            [39.66922231, -5.31094019],
                            [39.66917942, -5.31105457],
                            [39.66918657, -5.31111033],
                            [39.66919371, -5.31116609],
                            [39.66933953, -5.31143773],
                            [39.66949106, -5.31168364],
                            [39.66957111, -5.31180946],
                            [39.66969691, -5.31183805],
                            [39.66983129, -5.31186093],
                            [39.66995423, -5.31189524],
                            [39.67009433, -5.31198674],
                            [39.67015723, -5.31199818],
                            [39.67024014, -5.31194957],
                            [39.67031448, -5.31198388],
                            [39.67041455, -5.31203249],
                            [39.67048602, -5.31210112],
                            [39.67048602, -5.31216689],
                            [39.67048031, -5.31226125],
                            [39.67051462, -5.31236991],
                            [39.67056036, -5.31242995],
                            [39.67065471, -5.31240708],
                            [39.6707462, -5.31231844],
                            [39.67079195, -5.31216403],
                            [39.6707605, -5.31205537],
                            [39.67068044, -5.31189524],
                            [39.67072619, -5.31180946],
                            [39.67078623, -5.31176657],
                            [39.67076622, -5.31172654],
                            [39.67078051, -5.31165505],
                            [39.67084055, -5.31157499],
                            [39.67098065, -5.31153496],
                            [39.67113504, -5.31158928],
                            [39.67128943, -5.31153782],
                            [39.67148671, -5.31149778],
                            [39.6715925, -5.31144346],
                            [39.67159535, -5.31136053],
                            [39.67166683, -5.3113348],
                            [39.67176261, -5.31143059],
                            [39.67165397, -5.31152781],
                            [39.67146527, -5.3116479],
                            [39.6712251, -5.3116765],
                            [39.67102496, -5.31164218],
                            [39.67089916, -5.311768],
                            [39.67085914, -5.31191097],
                            [39.67092775, -5.31210541],
                            [39.67097636, -5.31220835],
                            [39.67100066, -5.31225982],
                            [39.67102496, -5.31231129],
                            [39.67108215, -5.31244282],
                            [39.67142524, -5.31241423],
                            [39.67143667, -5.3126487],
                            [39.67151673, -5.31266586],
                            [39.6714767, -5.31272304],
                            [39.67128228, -5.31271161],
                            [39.67120223, -5.31280311],
                            [39.67119079, -5.31302042],
                            [39.6713509, -5.31310049],
                            [39.67157963, -5.31315768],
                            [39.67159678, -5.31323202],
                            [39.67149957, -5.31348937],
                            [39.67141952, -5.31375243],
                            [39.67153047, -5.31390567],
                            [39.6715396, -5.31391828],
                            [39.67148263, -5.31397525],
                            [39.67142523, -5.31403266],
                            [39.67141939, -5.31406726],
                            [39.67135662, -5.3144387],
                            [39.67127084, -5.31458738],
                            [39.67119079, -5.31484473],
                            [39.6711479, -5.31501058],
                            [39.67110501, -5.31517642],
                            [39.67113932, -5.31530224],
                            [39.67133374, -5.31550812],
                            [39.67121938, -5.31553099],
                            [39.67111966, -5.31548528],
                            [39.67104783, -5.31552527],
                            [39.67104583, -5.31554115],
                            [39.67102935, -5.31567163],
                            [39.67085341, -5.31577118],
                            [39.67053891, -5.31601709],
                            [39.67045886, -5.31629732],
                            [39.67030447, -5.31649747],
                            [39.67017295, -5.31670335],
                            [39.67011005, -5.31696642],
                            [39.67021297, -5.31698358],
                            [39.67025872, -5.3170808],
                            [39.67011005, -5.31706936],
                            [39.67011005, -5.31712655],
                            [39.67024728, -5.31719517],
                            [39.67018438, -5.31732099],
                            [39.67016723, -5.31753258],
                            [39.67037308, -5.3178414],
                            [39.67045314, -5.3180244],
                            [39.67069902, -5.3181445],
                            [39.67091631, -5.31816166],
                            [39.67102496, -5.31809875],
                            [39.67111073, -5.31817309],
                            [39.67121366, -5.3181445],
                            [39.67133374, -5.31821313],
                            [39.67148813, -5.31835038],
                            [39.67159106, -5.31829319],
                            [39.67164824, -5.31837325],
                            [39.67197989, -5.31848191],
                            [39.67211713, -5.31843616],
                            [39.67214572, -5.31833322],
                            [39.67240876, -5.31843044],
                            [39.67248881, -5.31833894],
                            [39.67255743, -5.31840185],
                            [39.67278044, -5.31852195],
                            [39.6728948, -5.31845332],
                            [39.67293483, -5.31852195],
                            [39.67307207, -5.31855626],
                            [39.67311781, -5.31848191],
                            [39.67343803, -5.31858485],
                            [39.6737754, -5.31860773],
                            [39.67408991, -5.31858485],
                            [39.67445587, -5.31871067],
                            [39.67466744, -5.31879645],
                            [39.67470747, -5.31871639],
                            [39.67486758, -5.31880789],
                            [39.67485042, -5.31893942],
                            [39.67511918, -5.31908239],
                            [39.67550802, -5.31929971],
                            [39.67566241, -5.31922537],
                            [39.67574246, -5.31929971],
                            [39.67578249, -5.31940265],
                            [39.67609127, -5.31943125],
                            [39.67626282, -5.31952275],
                            [39.67640005, -5.31954848],
                            [39.67653729, -5.31957422],
                            [39.67661163, -5.31941981],
                            [39.67667453, -5.31939693],
                            [39.67668596, -5.3194098],
                            [39.6766974, -5.31942267],
                            [39.67672027, -5.3194484],
                            [39.67678889, -5.31955706],
                            [39.67672599, -5.32000313],
                            [39.67679461, -5.32051782],
                            [39.67673743, -5.3206608],
                            [39.67671254, -5.32063774],
                            [39.67662878, -5.32075802],
                            [39.6766345, -5.32075802],
                            [39.67665737, -5.32090671],
                            [39.67672599, -5.32094674],
                            [39.67688038, -5.3212098],
                            [39.67703477, -5.32134134],
                            [39.67725206, -5.32138137],
                            [39.6772006, -5.32162156],
                            [39.67737214, -5.32210766],
                            [39.67746363, -5.32214198],
                            [39.67756656, -5.32220488],
                            [39.67760659, -5.3223021],
                            [39.67777813, -5.32236501],
                            [39.67783532, -5.32259948],
                            [39.67768092, -5.32267383],
                            [39.67769808, -5.32289686],
                            [39.67794396, -5.32301696],
                            [39.67800114, -5.32323999],
                            [39.6778925, -5.32332006],
                            [39.67794396, -5.32347446],
                            [39.67805261, -5.32352593],
                            [39.6780526, -5.32364031],
                            [39.67812694, -5.32376613],
                            [39.67823559, -5.32382331],
                            [39.67826418, -5.32377756],
                            [39.67834423, -5.32377184],
                            [39.67836711, -5.32389766],
                            [39.67825142, -5.32391723],
                            [39.67812122, -5.32419504],
                            [39.67840713, -5.32472689],
                            [39.67846431, -5.32488702],
                            [39.67857868, -5.3251844],
                            [39.67861299, -5.32539028],
                            [39.67867588, -5.32566478],
                            [39.67883599, -5.32591641],
                            [39.67900754, -5.32597932],
                            [39.67913334, -5.32623666],
                            [39.67920378, -5.32625428],
                            [39.67927058, -5.32627098],
                            [39.6793106, -5.32630529],
                            [39.67922176, -5.3263298],
                            [39.67914478, -5.32635104],
                            [39.67886033, -5.32649957],
                            [39.67881312, -5.32662554],
                            [39.6788778, -5.32674841],
                            [39.67883027, -5.32687717],
                            [39.67871591, -5.32687145],
                            [39.67860155, -5.32706018],
                            [39.67861298, -5.32742618],
                            [39.6786187, -5.32764922],
                            [39.67869875, -5.32804382],
                            [39.67882455, -5.32819822],
                            [39.67901325, -5.32847273],
                            [39.67924198, -5.32862142],
                            [39.67944212, -5.32859854],
                            [39.67955648, -5.32854136],
                            [39.67959651, -5.32859282],
                            [39.67966513, -5.3285242],
                            [39.67974935, -5.32858053],
                            [39.67977203, -5.32859569],
                            [39.67976125, -5.32863215],
                            [39.6797509, -5.32866717],
                            [39.67964572, -5.32883977],
                            [39.67957779, -5.32906763],
                            [39.67951645, -5.32927337],
                            [39.67958507, -5.32931912],
                            [39.67963082, -5.32942206],
                            [39.67953361, -5.32943921],
                            [39.67968228, -5.32973087],
                            [39.67987098, -5.32999394],
                            [39.68004824, -5.33006257],
                            [39.68008569, -5.33003952],
                            [39.68019692, -5.32997106],
                            [39.68024838, -5.33003969],
                            [39.68011179, -5.33015275],
                            [39.68008255, -5.33017694],
                            [39.68008255, -5.33031991],
                            [39.68019692, -5.33052579],
                            [39.68020835, -5.33113199],
                            [39.68024266, -5.33172675],
                            [39.68036274, -5.33180681],
                            [39.68039133, -5.33231579],
                            [39.68045423, -5.33245304],
                            [39.68059719, -5.3324702],
                            [39.680689, -5.33263002],
                            [39.68066581, -5.33273898],
                            [39.68052857, -5.33260173],
                            [39.68035702, -5.33263604],
                            [39.68018747, -5.33271748],
                            [39.6801626, -5.33282476],
                            [39.68044851, -5.33303064],
                            [39.68064293, -5.33324796],
                            [39.68095171, -5.33334518],
                            [39.68105464, -5.33343668],
                            [39.68096721, -5.33348322],
                            [39.68095171, -5.33356249],
                            [39.6810089, -5.33373978],
                            [39.68110039, -5.33373978],
                            [39.68123762, -5.333654],
                            [39.68139773, -5.33379125],
                            [39.68163824, -5.33381898],
                            [39.68160931, -5.33399141],
                            [39.6816865, -5.33407147],
                            [39.6817637, -5.33415154],
                            [39.6819524, -5.33414582],
                            [39.68203817, -5.33402572],
                            [39.68204389, -5.33402],
                            [39.68209535, -5.33409435],
                            [39.68201436, -5.33415711],
                            [39.68197527, -5.3344146],
                            [39.68214681, -5.3346262],
                            [39.68234695, -5.33466623],
                            [39.68255852, -5.33467195],
                            [39.68246131, -5.33474058],
                            [39.68237554, -5.33470055],
                            [39.68212966, -5.33482064],
                            [39.6822383, -5.33509514],
                            [39.68265001, -5.33561556],
                            [39.6830846, -5.33598157],
                            [39.6836507, -5.33624463],
                            [39.68429113, -5.33686799],
                            [39.68448555, -5.33698808],
                            [39.68461135, -5.33705671],
                            [39.68466282, -5.3370224],
                            [39.6846919, -5.33700918],
                            [39.68478862, -5.33696521],
                            [39.68482864, -5.33699952],
                            [39.68475533, -5.33708561],
                            [39.68474859, -5.33723399],
                            [39.68488582, -5.33748562],
                            [39.68504021, -5.33763431],
                            [39.68520032, -5.33763431],
                            [39.68550861, -5.33759467],
                            [39.6856006, -5.33758284],
                            [39.68593797, -5.33754853],
                            [39.68604702, -5.33760568],
                            [39.68604662, -5.337783],
                            [39.68608093, -5.33794313],
                            [39.68656125, -5.3383892],
                            [39.68671564, -5.33854933],
                            [39.6868586, -5.33857792],
                            [39.68674423, -5.33864083],
                            [39.68665879, -5.33868272],
                            [39.68659289, -5.33871504],
                            [39.68659556, -5.33889246],
                            [39.68677282, -5.33921271],
                            [39.68693017, -5.3391839],
                            [39.68693293, -5.33929278],
                            [39.68677854, -5.3392985],
                            [39.68673852, -5.33936712],
                            [39.68683572, -5.33971597],
                            [39.68696152, -5.34003623],
                            [39.68704158, -5.34007626],
                            [39.68716738, -5.34004194],
                            [39.68721312, -5.34010485],
                            [39.68748188, -5.34027642],
                            [39.68771061, -5.34024782],
                            [39.68822524, -5.34012201],
                            [39.68854546, -5.33992757],
                            [39.68881422, -5.33985323],
                            [39.68896289, -5.33975029],
                            [39.68927913, -5.33982635],
                            [39.68941463, -5.33985894],
                            [39.68991887, -5.33991228],
                            [39.68992927, -5.34000192],
                            [39.68984349, -5.34009342],
                            [39.68999216, -5.34032789],
                            [39.6900665, -5.3405738],
                            [39.6903181, -5.34107134],
                            [39.69040387, -5.34106562],
                            [39.69050108, -5.34104275],
                            [39.69055521, -5.34112445],
                            [39.69056211, -5.34117728],
                            [39.69058114, -5.34132297],
                            [39.69075268, -5.34135156],
                            [39.69083846, -5.34139731],
                            [39.69079843, -5.34146022],
                            [39.69072409, -5.34146022],
                            [39.69063832, -5.34142019],
                            [39.69054271, -5.34152938],
                            [39.69056398, -5.34169469],
                            [39.69068978, -5.34176904],
                            [39.69084417, -5.34164322],
                            [39.69101572, -5.34152885],
                            [39.6911358, -5.34146594],
                            [39.69140456, -5.34147738],
                            [39.6914821, -5.34152741],
                            [39.69158182, -5.34159176],
                            [39.69187917, -5.34164323],
                            [39.69198781, -5.34153457],
                            [39.69206215, -5.34147738],
                            [39.69203356, -5.34167754],
                            [39.69213077, -5.34186626],
                            [39.69218373, -5.3419669],
                            [39.69218795, -5.34197492],
                            [39.69221195, -5.34196892],
                            [39.69225657, -5.34195776],
                            [39.69226694, -5.34197286],
                            [39.6923178, -5.34204689],
                            [39.69233036, -5.34211761],
                            [39.69235378, -5.34224942],
                            [39.69269115, -5.34270121],
                            [39.69279884, -5.34273127],
                            [39.69293703, -5.34276984],
                            [39.69298406, -5.34284275],
                            [39.69305139, -5.34294712],
                            [39.69318863, -5.34314156],
                            [39.69326297, -5.343336],
                            [39.69332015, -5.34350757],
                            [39.69337733, -5.34367913],
                            [39.69365356, -5.3438503],
                            [39.69359805, -5.34385703],
                            [39.69347021, -5.34388058],
                            [39.69330536, -5.3438503],
                            [39.69322125, -5.34378133],
                            [39.69318424, -5.3436888],
                            [39.69320611, -5.34355589],
                            [39.69318256, -5.34342804],
                            [39.69314051, -5.34332373],
                            [39.69306145, -5.34322447],
                            [39.6928966, -5.34308652],
                            [39.69266783, -5.34302259],
                            [39.69249625, -5.34282744],
                            [39.69225738, -5.34251789],
                            [39.69206226, -5.34227227],
                            [39.69187049, -5.34210067],
                            [39.69173175, -5.34195561],
                            [39.69172246, -5.3419459],
                            [39.69154416, -5.34185842],
                            [39.69143637, -5.34182847],
                            [39.69136249, -5.34180795],
                            [39.69126829, -5.34183487],
                            [39.6910597, -5.34200983],
                            [39.69095877, -5.34214441],
                            [39.69074682, -5.3421747],
                            [39.69057861, -5.34218142],
                            [39.69051132, -5.34211413],
                            [39.69024891, -5.34193917],
                            [39.69017826, -5.34179785],
                            [39.69022536, -5.34155223],
                            [39.69016144, -5.34140082],
                            [39.69011013, -5.34133422],
                            [39.69010506, -5.34132765],
                            [39.69000332, -5.34119558],
                            [39.689788, -5.3409937],
                            [39.68966689, -5.34091631],
                            [39.68966689, -5.34079182],
                            [39.68964334, -5.34063704],
                            [39.68954013, -5.34051],
                            [39.68942466, -5.34036787],
                            [39.689396, -5.34035484],
                            [39.68920262, -5.34026693],
                            [39.68885273, -5.34025011],
                            [39.68860041, -5.3403174],
                            [39.68835818, -5.34044862],
                            [39.68807895, -5.34061349],
                            [39.68790064, -5.34072452],
                            [39.68773915, -5.34073462],
                            [39.68758776, -5.34075144],
                            [39.68740273, -5.34081873],
                            [39.68723788, -5.34075144],
                            [39.68710667, -5.34061349],
                            [39.68684426, -5.34035777],
                            [39.6866424, -5.34008187],
                            [39.68643718, -5.33981943],
                            [39.68626896, -5.33963773],
                            [39.68620841, -5.33937193],
                            [39.68613103, -5.33913303],
                            [39.68602001, -5.33893452],
                            [39.68591235, -5.33879657],
                            [39.6857946, -5.3386889],
                            [39.68567349, -5.33869226],
                            [39.68553892, -5.33875956],
                            [39.68546827, -5.33889078],
                            [39.6854178, -5.33890087],
                            [39.68533033, -5.33888068],
                            [39.68525295, -5.33901191],
                            [39.68514866, -5.33928444],
                            [39.68516884, -5.33950651],
                            [39.68517557, -5.33977232],
                            [39.68515605, -5.33984891],
                            [39.68513184, -5.33994392],
                            [39.68500399, -5.34005832],
                            [39.68498381, -5.34022318],
                            [39.68493567, -5.34043448],
                            [39.68492325, -5.34048899],
                            [39.68499054, -5.3405832],
                            [39.68503091, -5.34062694],
                            [39.68489634, -5.34070769],
                            [39.68487615, -5.34095332],
                            [39.68482905, -5.34102734],
                            [39.68482905, -5.34111818],
                            [39.68491316, -5.34112828],
                            [39.68493671, -5.34128642],
                            [39.68482905, -5.3415455],
                            [39.68475968, -5.34173412],
                            [39.68472139, -5.34183822],
                            [39.68457673, -5.34204347],
                            [39.68444888, -5.34229918],
                            [39.68445393, -5.34237825],
                            [39.68445393, -5.34239267],
                            [39.68445393, -5.34241021],
                            [39.68444168, -5.34241279],
                            [39.68442197, -5.34241694],
                            [39.68438328, -5.34243713],
                            [39.68433618, -5.34248424],
                            [39.68429244, -5.34259695],
                            [39.68427058, -5.34264069],
                            [39.68421338, -5.34271976],
                            [39.68419151, -5.34275004],
                            [39.68419824, -5.34277864],
                            [39.68421169, -5.34279068],
                            [39.6842302, -5.34280724],
                            [39.68429581, -5.34280051],
                            [39.68430926, -5.34281061],
                            [39.68435636, -5.34285267],
                            [39.68437487, -5.34291996],
                            [39.68436309, -5.34293174],
                            [39.68430254, -5.34293174],
                            [39.68422179, -5.34296875],
                            [39.68415619, -5.34304782],
                            [39.68418479, -5.34312016],
                            [39.68414946, -5.34313866],
                            [39.68408804, -5.34318392],
                            [39.68408554, -5.34318577],
                            [39.68397788, -5.34326484],
                            [39.68395265, -5.34333045],
                            [39.68405862, -5.3434112],
                            [39.68410741, -5.34351046],
                            [39.68416739, -5.34363351],
                            [39.68417301, -5.34364504],
                            [39.6842302, -5.3436871],
                            [39.68433954, -5.34371738],
                            [39.68439505, -5.34375103],
                            [39.68450944, -5.34384019],
                            [39.68458009, -5.34393609],
                            [39.68454813, -5.34403198],
                            [39.68447243, -5.34402189],
                            [39.68439001, -5.34397142],
                            [39.68428571, -5.34384188],
                            [39.6841891, -5.34375653],
                            [39.68418478, -5.34375271],
                            [39.68407902, -5.34362023],
                            [39.68401657, -5.34350877],
                            [39.68396611, -5.34349027],
                            [39.68386686, -5.3434869],
                            [39.68378107, -5.34348354],
                            [39.68368723, -5.34347528],
                            [39.68367173, -5.34344989],
                            [39.68361286, -5.34344148],
                            [39.68355566, -5.34349195],
                            [39.68344464, -5.34349027],
                            [39.68332353, -5.34346672],
                            [39.68311663, -5.34351046],
                            [39.68289963, -5.34352055],
                            [39.68276001, -5.34356261],
                            [39.68270114, -5.34357943],
                            [39.68262544, -5.34361139],
                            [39.68258507, -5.34361981],
                            [39.68252451, -5.34365682],
                            [39.68252619, -5.34367364],
                            [39.68248414, -5.34372916],
                            [39.68249255, -5.34374935],
                            [39.68250433, -5.34382842],
                            [39.68246227, -5.34383346],
                            [39.68243199, -5.3438301],
                            [39.68238627, -5.34382824],
                            [39.68236303, -5.3438015],
                            [39.68231929, -5.3438015],
                            [39.68225873, -5.34381159],
                            [39.68226378, -5.34386543],
                            [39.68223855, -5.34391253],
                            [39.68218976, -5.34391758],
                            [39.68215949, -5.34386879],
                            [39.6821107, -5.34387216],
                            [39.68209052, -5.34391253],
                            [39.68210734, -5.343963],
                            [39.68207874, -5.34399328],
                            [39.68205688, -5.34402693],
                            [39.68201015, -5.34402645],
                            [39.68198623, -5.34400169],
                            [39.68197277, -5.34394618],
                            [39.6819223, -5.34394281],
                            [39.68186511, -5.34394786],
                            [39.68181633, -5.34397309],
                            [39.68177596, -5.34403871],
                            [39.68172549, -5.3440488],
                            [39.68167335, -5.34406899],
                            [39.68166494, -5.34413292],
                            [39.68171035, -5.3442103],
                            [39.68169521, -5.34426077],
                            [39.68162456, -5.34423554],
                            [39.68155391, -5.34422376],
                            [39.68151354, -5.34427087],
                            [39.68150345, -5.34432807],
                            [39.68151859, -5.34437517],
                            [39.6814984, -5.34443069],
                            [39.68143785, -5.34446265],
                            [39.6814328, -5.34450976],
                            [39.6813857, -5.34470323],
                            [39.6813386, -5.34488996],
                            [39.68139243, -5.34508343],
                            [39.68138906, -5.3451625],
                            [39.68143953, -5.3452769],
                            [39.68145467, -5.34529036],
                            [39.68152532, -5.34528531],
                            [39.68153709, -5.34530887],
                            [39.68153541, -5.34539635],
                            [39.68158587, -5.34546364],
                            [39.68171708, -5.34555953],
                            [39.68180792, -5.3457059],
                            [39.68188698, -5.34578833],
                            [39.68188025, -5.34584721],
                            [39.681998, -5.34597675],
                            [39.68202155, -5.34604068],
                            [39.681998, -5.34607769],
                            [39.68212248, -5.34631658],
                            [39.68219985, -5.34639902],
                            [39.68228564, -5.34638724],
                            [39.68231592, -5.34644276],
                            [39.68236302, -5.34664968],
                            [39.68241685, -5.34678932],
                            [39.682469, -5.34688689],
                            [39.68255647, -5.34697942],
                            [39.68255983, -5.34702484],
                            [39.68253965, -5.34712915],
                            [39.68255647, -5.34722841],
                            [39.68261198, -5.34724523],
                            [39.68263216, -5.34726878],
                            [39.68265067, -5.34733608],
                            [39.68262207, -5.34735963],
                            [39.68270281, -5.34744879],
                            [39.68273326, -5.34747283],
                            [39.68273477, -5.34747403],
                            [39.68272325, -5.34750329],
                            [39.68271291, -5.34752954],
                            [39.68271409, -5.34753113],
                            [39.68282224, -5.34767591],
                            [39.68288953, -5.347686],
                            [39.68295345, -5.34766077],
                            [39.68298541, -5.34761871],
                            [39.68301401, -5.34763048],
                            [39.68300896, -5.34764731],
                            [39.68302074, -5.34768432],
                            [39.68305774, -5.34768432],
                            [39.6830712, -5.34771797],
                            [39.6830527, -5.34775161],
                            [39.68307457, -5.34778189],
                            [39.68320745, -5.34777516],
                            [39.68334539, -5.3480191],
                            [39.68339922, -5.34809144],
                            [39.68346146, -5.34805275],
                            [39.68351697, -5.3480578],
                            [39.68354893, -5.34813182],
                            [39.68363135, -5.34827314],
                            [39.68367677, -5.34830174],
                            [39.68373228, -5.34830846],
                            [39.68381807, -5.34834716],
                            [39.68405357, -5.34854736],
                            [39.68423524, -5.34863316],
                            [39.68427898, -5.34870381],
                            [39.68439168, -5.34873746],
                            [39.68446065, -5.3488098],
                            [39.68438832, -5.34884513],
                            [39.68437654, -5.34894943],
                            [39.68441691, -5.34899317],
                            [39.68443878, -5.34908234],
                            [39.68454812, -5.34936833],
                            [39.68449934, -5.34944572],
                            [39.68462886, -5.34974686],
                            [39.68473988, -5.35006818],
                            [39.68486772, -5.35024819],
                            [39.68495688, -5.35037269],
                            [39.68510322, -5.35048709],
                            [39.68537237, -5.35064522],
                            [39.68554731, -5.35067214],
                            [39.68567852, -5.35063177],
                            [39.68584841, -5.35051569],
                            [39.68598298, -5.3503525],
                            [39.68603513, -5.35024651],
                            [39.68610073, -5.3501624],
                            [39.68621344, -5.35001603],
                            [39.68641361, -5.34985958],
                            [39.68656669, -5.34975696],
                            [39.68671808, -5.34967116],
                            [39.68686106, -5.34961059],
                            [39.68700068, -5.34956517],
                            [39.68710666, -5.34956181],
                            [39.68718403, -5.34961059],
                            [39.68724796, -5.34962069],
                            [39.68729674, -5.34960555],
                            [39.68736066, -5.34957695],
                            [39.68743131, -5.34958536],
                            [39.6874741, -5.34967993],
                            [39.68738049, -5.34971114],
                            [39.6871995, -5.34972986],
                            [39.68701228, -5.34980476],
                            [39.68693115, -5.34982973],
                            [39.68685626, -5.34990462],
                            [39.68676889, -5.34992335],
                            [39.68668152, -5.34991087],
                            [39.68658167, -5.34994207],
                            [39.68651926, -5.349992],
                            [39.68638821, -5.3500669],
                            [39.68628211, -5.35026663],
                            [39.68617602, -5.35034777],
                            [39.68617602, -5.35048508],
                            [39.68619474, -5.35058494],
                            [39.68630708, -5.35064736],
                            [39.68624467, -5.3506848],
                            [39.68614482, -5.35074098],
                            [39.68611985, -5.35084708],
                            [39.68622438, -5.3512559],
                            [39.68615574, -5.35122157],
                            [39.68607617, -5.35116228],
                            [39.6860278, -5.35113263],
                            [39.68598568, -5.3510983],
                            [39.68596539, -5.35106709],
                            [39.68594199, -5.35101404],
                            [39.68592015, -5.35093914],
                            [39.68588895, -5.3509251],
                            [39.68584058, -5.35090169],
                            [39.68579845, -5.35088921],
                            [39.68575945, -5.35088609],
                            [39.68572513, -5.35088297],
                            [39.6857064, -5.35087049],
                            [39.68567364, -5.35081744],
                            [39.68563776, -5.35078935],
                            [39.68558471, -5.35078467],
                            [39.68554716, -5.350761],
                            [39.68546803, -5.35074834],
                            [39.68535409, -5.35073251],
                            [39.68525439, -5.35070877],
                            [39.68512462, -5.35063913],
                            [39.68500593, -5.35054892],
                            [39.68493313, -5.35050144],
                            [39.68483502, -5.35040964],
                            [39.68476539, -5.35036216],
                            [39.68466094, -5.35028144],
                            [39.68456124, -5.35016274],
                            [39.68448686, -5.34996648],
                            [39.68435551, -5.34971008],
                            [39.68426056, -5.34951541],
                            [39.68427005, -5.34940462],
                            [39.68428746, -5.34933339],
                            [39.68428904, -5.34924001],
                            [39.68424315, -5.34921311],
                            [39.68423999, -5.34911023],
                            [39.68420042, -5.34903901],
                            [39.68410231, -5.34891872],
                            [39.68401685, -5.34880635],
                            [39.68392506, -5.34873671],
                            [39.68383961, -5.34861801],
                            [39.68372408, -5.34856261],
                            [39.68361489, -5.34848981],
                            [39.68344081, -5.34839959],
                            [39.68332054, -5.34830621],
                            [39.6832699, -5.34831729],
                            [39.68321925, -5.34832837],
                            [39.68315754, -5.34831729],
                            [39.68311164, -5.34830621],
                            [39.68303093, -5.3482983],
                            [39.68297238, -5.34825398],
                            [39.68295972, -5.3482065],
                            [39.68292649, -5.34819859],
                            [39.68289009, -5.34822075],
                            [39.68284736, -5.34822233],
                            [39.68280621, -5.34816852],
                            [39.68280147, -5.34809096],
                            [39.68272234, -5.34801499],
                            [39.68264796, -5.34791053],
                            [39.68263214, -5.34787571],
                            [39.68260578, -5.34786045],
                            [39.68260207, -5.3478583],
                            [39.6825625, -5.34788363],
                            [39.68253402, -5.3479137],
                            [39.68248338, -5.34786147],
                            [39.68249129, -5.34781082],
                            [39.68253244, -5.34780133],
                            [39.68250553, -5.34773327],
                            [39.68245331, -5.34767629],
                            [39.68238843, -5.34770636],
                            [39.68229506, -5.3476114],
                            [39.68225708, -5.34754334],
                            [39.68220643, -5.3474927],
                            [39.68219694, -5.34741831],
                            [39.68215738, -5.34742464],
                            [39.68208141, -5.34748795],
                            [39.68204502, -5.3475196],
                            [39.68205293, -5.34756867],
                            [39.68206717, -5.34766047],
                            [39.68200229, -5.34771586],
                            [39.68200862, -5.3478678],
                            [39.68198488, -5.34796751],
                            [39.68195165, -5.34809096],
                            [39.68190733, -5.34818592],
                            [39.68186777, -5.34837585],
                            [39.68186144, -5.34845024],
                            [39.6819105, -5.34858477],
                            [39.68192632, -5.34861959],
                            [39.68197064, -5.34870822],
                            [39.68203077, -5.34875412],
                            [39.6820371, -5.34879685],
                            [39.68200703, -5.348838],
                            [39.68192316, -5.34882059],
                            [39.68188043, -5.34877311],
                            [39.68186302, -5.34873513],
                            [39.68184403, -5.34869714],
                            [39.68176491, -5.34864966],
                            [39.6817475, -5.3486428],
                            [39.68171268, -5.34862908],
                            [39.68167787, -5.34864966],
                            [39.68162248, -5.34869397],
                            [39.68156234, -5.34872879],
                            [39.68152594, -5.34876203],
                            [39.68152594, -5.34876361],
                            [39.68151487, -5.34881901],
                            [39.68153544, -5.34888073],
                            [39.68158133, -5.34898994],
                            [39.68158766, -5.34909757],
                            [39.68155601, -5.34908174],
                            [39.68150695, -5.34902001],
                            [39.68144365, -5.34894563],
                            [39.68142783, -5.34886966],
                            [39.68139459, -5.34882376],
                            [39.68136769, -5.34882376],
                            [39.68134079, -5.34886649],
                            [39.68124584, -5.3488934],
                            [39.68112873, -5.34891872],
                            [39.68108758, -5.3489203],
                            [39.68102428, -5.34895512],
                            [39.68097522, -5.3489567],
                            [39.680923, -5.34896778],
                            [39.68087394, -5.34900419],
                            [39.68080589, -5.3490485],
                            [39.68072676, -5.34904692],
                            [39.68065555, -5.34904059],
                            [39.68056535, -5.34902318],
                            [39.68053053, -5.34908015],
                            [39.68050363, -5.3490944],
                            [39.68047831, -5.34909123],
                            [39.68044666, -5.3490849],
                            [39.68041342, -5.34906749],
                            [39.68034854, -5.349039],
                            [39.68030106, -5.34907857],
                            [39.68024409, -5.34909756],
                            [39.6801887, -5.34904375],
                            [39.68005735, -5.34902792],
                            [39.67992917, -5.34897095],
                            [39.67980415, -5.34893296],
                            [39.67969653, -5.34887757],
                            [39.67961741, -5.3488475],
                            [39.67954936, -5.34879843],
                            [39.67949872, -5.34873987],
                            [39.67945916, -5.34868764],
                            [39.6794101, -5.34863383],
                            [39.67935471, -5.34861958],
                            [39.67933888, -5.34863383],
                            [39.67925976, -5.34867023],
                            [39.6792107, -5.34865757],
                            [39.67913474, -5.34864491],
                            [39.67909676, -5.34866865],
                            [39.67903978, -5.3487098],
                            [39.67899547, -5.34871929],
                            [39.67897648, -5.3486734],
                            [39.67899289, -5.34861852],
                            [39.67902396, -5.34858318],
                            [39.67909043, -5.34853728],
                            [39.67915531, -5.34849455],
                            [39.67916639, -5.34844707],
                            [39.67913948, -5.34838059],
                            [39.67907777, -5.34829513],
                            [39.67898281, -5.34829671],
                            [39.67892742, -5.3483521],
                            [39.67886412, -5.3483252],
                            [39.67885938, -5.34826822],
                            [39.67890527, -5.34823024],
                            [39.67895433, -5.3482065],
                            [39.67897648, -5.34813686],
                            [39.67895908, -5.34807355],
                            [39.67894011, -5.34802169],
                            [39.67893534, -5.34800866],
                            [39.67885779, -5.34792794],
                            [39.67883406, -5.34788046],
                            [39.6788024, -5.34782823],
                            [39.67871853, -5.34779974],
                            [39.67865998, -5.34778866],
                            [39.67859193, -5.34773643],
                            [39.67855553, -5.34770003],
                            [39.67850014, -5.34763197],
                            [39.67845425, -5.3475655],
                            [39.67839728, -5.34750535],
                            [39.67834664, -5.3474278],
                            [39.67830074, -5.34741514],
                            [39.67826751, -5.34739931],
                            [39.67824852, -5.34734392],
                            [39.67822795, -5.347355],
                            [39.67817414, -5.34738507],
                            [39.67814337, -5.34735691],
                            [39.67817572, -5.3472901],
                            [39.67815515, -5.34727586],
                            [39.67804121, -5.34728852],
                            [39.67799057, -5.34731859],
                            [39.67794942, -5.3473455],
                            [39.6778972, -5.34734866],
                            [39.67788454, -5.34732967],
                            [39.67787346, -5.34727903],
                            [39.67782915, -5.34722363],
                            [39.67775793, -5.3472268],
                            [39.67769147, -5.34721888],
                            [39.67760601, -5.34721097],
                            [39.67755379, -5.34718248],
                            [39.67749682, -5.34716824],
                            [39.67744618, -5.34718565],
                            [39.67743351, -5.34717773],
                            [39.67741611, -5.34712234],
                            [39.67735597, -5.34708277],
                            [39.67730217, -5.34708435],
                            [39.67725785, -5.34710018],
                            [39.67721513, -5.34710018],
                            [39.67716765, -5.34707644],
                            [39.6770996, -5.34704162],
                            [39.67703313, -5.34696565],
                            [39.67696192, -5.34695299],
                            [39.67691444, -5.34695457],
                            [39.67686064, -5.34698622],
                            [39.67680841, -5.34704162],
                            [39.67678151, -5.34706852],
                            [39.67674828, -5.34710967],
                            [39.6767641, -5.34715082],
                            [39.67678626, -5.34721097],
                            [39.67679417, -5.34729327],
                            [39.67684007, -5.34740248],
                            [39.67685589, -5.34746104],
                            [39.67688279, -5.34752751],
                            [39.67690653, -5.34753384],
                            [39.67695401, -5.3475924],
                            [39.67694609, -5.34764305],
                            [39.67694451, -5.34770477],
                            [39.67698882, -5.3478947],
                            [39.67699199, -5.34801498],
                            [39.67695242, -5.34809095],
                            [39.67687963, -5.34825397],
                            [39.67685431, -5.34830937],
                            [39.67684639, -5.34839325],
                            [39.67682582, -5.34846606],
                            [39.67680366, -5.34856893],
                            [39.67677043, -5.34865123],
                            [39.67672137, -5.34870346],
                            [39.6767128, -5.34876421],
                            [39.67670238, -5.34883799],
                            [39.67666598, -5.34885699],
                            [39.676628, -5.3489013],
                            [39.67657736, -5.3489472],
                            [39.6765014, -5.34901842],
                            [39.67647228, -5.34903489],
                            [39.6764286, -5.34905957],
                            [39.67638113, -5.34911497],
                            [39.6763384, -5.34915295],
                            [39.67629567, -5.34917511],
                            [39.6762387, -5.3492036],
                            [39.6762023, -5.34926058],
                            [39.67618015, -5.34926533],
                            [39.67612159, -5.34927799],
                            [39.67607253, -5.34930173],
                            [39.67609152, -5.34933971],
                            [39.67599182, -5.34938245],
                            [39.67589846, -5.34944575],
                            [39.67589212, -5.34946633],
                            [39.67587472, -5.34950906],
                            [39.67582566, -5.34954388],
                            [39.67575444, -5.3495518],
                            [39.67569906, -5.34963251],
                            [39.67559303, -5.34962618],
                            [39.67553447, -5.34964043],
                            [39.67545693, -5.349661],
                            [39.6753873, -5.34967366],
                            [39.67534615, -5.34967841],
                            [39.67529551, -5.34969899],
                            [39.67523063, -5.34971007],
                            [39.67517682, -5.34973539],
                            [39.67514042, -5.3497528],
                            [39.67508503, -5.34974489],
                            [39.67503914, -5.34972431],
                            [39.67499799, -5.34970057],
                            [39.67494102, -5.34972431],
                            [39.67489513, -5.34973855],
                            [39.67483183, -5.34977337],
                            [39.67478119, -5.34980503],
                            [39.67471947, -5.34983668],
                            [39.67463084, -5.34985409],
                            [39.67455805, -5.34986834],
                            [39.67451532, -5.34986675],
                            [39.67444727, -5.34986675],
                            [39.6743903, -5.34987783],
                            [39.67433808, -5.34991582],
                            [39.67428744, -5.34993797],
                            [39.67425737, -5.34996013],
                            [39.67424312, -5.34998546],
                            [39.67424629, -5.35002502],
                            [39.67426211, -5.35005984],
                            [39.67430484, -5.35006934],
                            [39.67430801, -5.35009308],
                            [39.67428585, -5.35011524],
                            [39.67425578, -5.35014848],
                            [39.67429218, -5.35022128],
                            [39.67434757, -5.35032099],
                            [39.67440454, -5.35033682],
                            [39.6744267, -5.35036056],
                            [39.67443461, -5.35041754],
                            [39.67449158, -5.35050617],
                            [39.67450266, -5.3505125],
                            [39.67454539, -5.35052041],
                            [39.67458495, -5.3505489],
                            [39.67460078, -5.35059797],
                            [39.67464825, -5.35060113],
                            [39.67467516, -5.35059797],
                            [39.67470048, -5.35059164],
                            [39.67473687, -5.3505948],
                            [39.67476694, -5.35062646],
                            [39.67480017, -5.35065178],
                            [39.67482708, -5.35068976],
                            [39.67484923, -5.35068185],
                            [39.67489038, -5.35065969],
                            [39.67492045, -5.35063437],
                            [39.67495685, -5.35064228],
                            [39.67496159, -5.3506961],
                            [39.67500432, -5.35073408],
                            [39.67506604, -5.3507689],
                            [39.67512459, -5.35081005],
                            [39.67519897, -5.35085278],
                            [39.6752417, -5.35089393],
                            [39.67525753, -5.35089552],
                            [39.67529867, -5.35089235],
                            [39.67533665, -5.35091609],
                            [39.67539204, -5.35091926],
                            [39.67542844, -5.35091609],
                            [39.67545534, -5.3509335],
                            [39.67550124, -5.35099365],
                            [39.67555029, -5.35103163],
                            [39.67562467, -5.35106962],
                            [39.67566107, -5.3510981],
                            [39.67566107, -5.35112818],
                            [39.67567532, -5.3511535],
                            [39.67569905, -5.35116774],
                            [39.67572596, -5.35117724],
                            [39.67573545, -5.35121997],
                            [39.67577976, -5.35130386],
                            [39.67577976, -5.3513735],
                            [39.6757307, -5.35142889],
                            [39.67571013, -5.35145263],
                            [39.67568639, -5.35153177],
                            [39.6756943, -5.351565],
                            [39.67568164, -5.35160141],
                            [39.6756769, -5.35164414],
                            [39.67565474, -5.35167263],
                            [39.675631, -5.35171061],
                            [39.67562784, -5.35177234],
                            [39.67557087, -5.35182615],
                            [39.67555346, -5.35185464],
                            [39.67553605, -5.35188946],
                            [39.67549174, -5.35190054],
                            [39.67546325, -5.35193219],
                            [39.67541578, -5.35196385],
                            [39.67539046, -5.352005],
                            [39.67535564, -5.35203032],
                            [39.67532557, -5.35206989],
                            [39.67529234, -5.35206672],
                            [39.67525752, -5.35210154],
                            [39.67522746, -5.35214269],
                            [39.67518789, -5.35219017],
                            [39.67515149, -5.35224082],
                            [39.67515149, -5.35229622],
                            [39.67511984, -5.35232312],
                            [39.67510402, -5.35234528],
                            [39.6751056, -5.35235003],
                            [39.67514358, -5.35238168],
                            [39.67518631, -5.35239593],
                            [39.67522904, -5.3524165],
                            [39.67529392, -5.35243233],
                            [39.67535089, -5.35239909],
                            [39.67539679, -5.3523706],
                            [39.67545534, -5.35237852],
                            [39.67552972, -5.35234528],
                            [39.67564208, -5.35229147],
                            [39.6757402, -5.35231838],
                            [39.6756769, -5.35235953],
                            [39.67562309, -5.35242283],
                            [39.6756041, -5.35246715],
                            [39.67554713, -5.35253995],
                            [39.67550282, -5.35255578],
                            [39.67544268, -5.35256528],
                            [39.67536039, -5.35256844],
                            [39.67530025, -5.35256844],
                            [39.67520214, -5.35256528],
                            [39.67515466, -5.35256211],
                            [39.6750787, -5.35255895],
                            [39.6750059, -5.35247031],
                            [39.67497425, -5.35244816],
                            [39.67494893, -5.35241967],
                            [39.67495843, -5.35234686],
                            [39.6750059, -5.35233104],
                            [39.67503122, -5.35229622],
                            [39.67504705, -5.35219809],
                            [39.67505654, -5.35217276],
                            [39.67517682, -5.35204298],
                            [39.67532874, -5.35191003],
                            [39.67539204, -5.35184356],
                            [39.67549965, -5.35175493],
                            [39.67554396, -5.35161882],
                            [39.67554713, -5.3515745],
                            [39.67555662, -5.3515017],
                            [39.67557878, -5.35143839],
                            [39.67556929, -5.35131177],
                            [39.67554396, -5.35126745],
                            [39.67552181, -5.35120731],
                            [39.67548383, -5.35115983],
                            [39.67543635, -5.35112501],
                            [39.6753509, -5.3510712],
                            [39.6752686, -5.35102055],
                            [39.67519897, -5.35098257],
                            [39.67509769, -5.35096357],
                            [39.67506921, -5.35094458],
                            [39.67497425, -5.35090343],
                            [39.67484132, -5.35084645],
                            [39.67479068, -5.35081796],
                            [39.67467674, -5.35077998],
                            [39.67461344, -5.35074832],
                            [39.67454064, -5.35071667],
                            [39.67448367, -5.35071983],
                            [39.67443303, -5.35072933],
                            [39.67438239, -5.35075782],
                            [39.67435074, -5.35074832],
                            [39.67433808, -5.35067868],
                            [39.67426844, -5.35059955],
                            [39.67422097, -5.35052674],
                            [39.67416083, -5.35039696],
                            [39.67409753, -5.35025768],
                            [39.67404689, -5.35009625],
                            [39.67403423, -5.34977337],
                            [39.67405006, -5.34972589],
                            [39.67411638, -5.34981365],
                            [39.67417349, -5.34986517],
                            [39.67419565, -5.34981452],
                            [39.67419881, -5.34970373],
                            [39.67420198, -5.34958978],
                            [39.67427478, -5.34954863],
                            [39.67437369, -5.34955878],
                            [39.67443936, -5.3496151],
                            [39.67453115, -5.34965625],
                            [39.67462293, -5.34962143],
                            [39.6747622, -5.34964676],
                            [39.67486506, -5.34964676],
                            [39.67496792, -5.34964676],
                            [39.67510719, -5.34954863],
                            [39.67529076, -5.34952331],
                            [39.67548383, -5.34943467],
                            [39.67567374, -5.34931439],
                            [39.67578768, -5.34924475],
                            [39.67585731, -5.34918461],
                            [39.67593327, -5.34910864],
                            [39.67599341, -5.34903583],
                            [39.67607886, -5.34896303],
                            [39.67616749, -5.34889972],
                            [39.67625927, -5.34880476],
                            [39.67628776, -5.34871296],
                            [39.67633523, -5.348618],
                            [39.67638904, -5.34855152],
                            [39.67643968, -5.34846289],
                            [39.67644601, -5.34838692],
                            [39.67645867, -5.34826664],
                            [39.67648083, -5.34815268],
                            [39.67648092, -5.34799953],
                            [39.67649349, -5.34800707],
                            [39.67653464, -5.34798491],
                            [39.67658528, -5.3479406],
                            [39.67662326, -5.34790578],
                            [39.67662642, -5.34782348],
                            [39.67663908, -5.34770952],
                            [39.67661693, -5.34762722],
                            [39.67660743, -5.34759557],
                            [39.67655996, -5.34751643],
                            [39.67648716, -5.34744996],
                            [39.67647133, -5.34742463],
                            [39.67645551, -5.34735499],
                            [39.67639854, -5.34728535],
                            [39.67637955, -5.3473455],
                            [39.67635423, -5.34739298],
                            [39.67630042, -5.34740881],
                            [39.67628143, -5.34740881],
                            [39.67624028, -5.34735499],
                            [39.67623395, -5.34729802],
                            [39.67630675, -5.34726003],
                            [39.67632258, -5.34716507],
                            [39.67638904, -5.34710176],
                            [39.67643652, -5.34696565],
                            [39.67644918, -5.34693083],
                            [39.67648716, -5.34689601],
                            [39.67650299, -5.34683903],
                            [39.67657262, -5.34677889],
                            [39.67671821, -5.34667126],
                            [39.67677518, -5.3466681],
                            [39.67687963, -5.34663961],
                            [39.67704738, -5.34662695],
                            [39.67714098, -5.346646],
                            [39.67716765, -5.3466586],
                            [39.67714914, -5.34671517],
                            [39.67718981, -5.34673457],
                            [39.67723412, -5.34673141],
                            [39.67725664, -5.34669618],
                            [39.67729137, -5.34671257],
                            [39.67736542, -5.34674753],
                            [39.67740503, -5.34676623],
                            [39.67740503, -5.34679788],
                            [39.67738287, -5.34682637],
                            [39.67743144, -5.34688793],
                            [39.67747466, -5.34690867],
                            [39.67756328, -5.34691184],
                            [39.67769622, -5.34687702],
                            [39.6778409, -5.34689662],
                            [39.67801589, -5.34697831],
                            [39.67837037, -5.34705428],
                            [39.6787122, -5.34726953],
                            [39.67886412, -5.34738349],
                            [39.67900339, -5.34751643],
                            [39.67923127, -5.34770003],
                            [39.6793997, -5.34786848],
                            [39.67942751, -5.34789629],
                            [39.67954791, -5.34801032],
                            [39.67978199, -5.3481242],
                            [39.67995099, -5.34825473],
                            [39.68011116, -5.34833311],
                            [39.68025042, -5.34835211],
                            [39.68042767, -5.34838376],
                            [39.68059858, -5.34840909],
                            [39.6807505, -5.34840909],
                            [39.68088344, -5.34836477],
                            [39.68099738, -5.34832045],
                            [39.68109233, -5.34822549],
                            [39.68117462, -5.34813053],
                            [39.68123792, -5.34800391],
                            [39.68129489, -5.34767471],
                            [39.68130756, -5.34763039],
                            [39.68125058, -5.34755442],
                            [39.68119994, -5.34751011],
                            [39.68116829, -5.34740248],
                            [39.68115563, -5.34733284],
                            [39.68115563, -5.34728853],
                            [39.68115498, -5.34728332],
                            [39.68114297, -5.34718723],
                            [39.68112398, -5.34706695],
                            [39.68109866, -5.34687069],
                            [39.68102903, -5.34664911],
                            [39.6810227, -5.34663645],
                            [39.68098472, -5.34661746],
                            [39.68088977, -5.34656048],
                            [39.68082014, -5.34646552],
                            [39.68080115, -5.34635789],
                            [39.6808961, -5.34633257],
                            [39.68098472, -5.34630725],
                            [39.68097839, -5.34623761],
                            [39.68095307, -5.34608567],
                            [39.68080748, -5.34591473],
                            [39.6807695, -5.34590207],
                            [39.68068087, -5.34589574],
                            [39.68061124, -5.34576912],
                            [39.68050363, -5.34571848],
                            [39.68047831, -5.34558553],
                            [39.680434, -5.34538294],
                            [39.68040868, -5.34516136],
                            [39.68026309, -5.3448828],
                            [39.68025676, -5.3448828],
                            [39.6801808, -5.34478784],
                            [39.68023068, -5.34472732],
                            [39.68031373, -5.34474986],
                            [39.68034538, -5.34469288],
                            [39.68031373, -5.34459792],
                            [39.68026942, -5.34449662],
                            [39.68028841, -5.34436367],
                            [39.68029474, -5.34411044],
                            [39.68023144, -5.3440408],
                            [39.68018713, -5.34391418],
                            [39.68023144, -5.34375591],
                            [39.68032006, -5.34366095],
                            [39.68035804, -5.34349635],
                            [39.68045299, -5.34323678],
                            [39.68053529, -5.34316081],
                            [39.68069354, -5.3430342],
                            [39.68082647, -5.34288226],
                            [39.68091509, -5.34271133],
                            [39.68097207, -5.34255305],
                            [39.68099739, -5.3424391],
                            [39.68101005, -5.34230615],
                            [39.68103537, -5.34217953],
                            [39.68104803, -5.34193896],
                            [39.68106702, -5.34188832],
                            [39.68113665, -5.34182501],
                            [39.68133602, -5.34178399],
                            [39.68157976, -5.34180602],
                            [39.68173446, -5.34170331],
                            [39.68182663, -5.34171105],
                            [39.68194058, -5.34164141],
                            [39.6820292, -5.34152746],
                            [39.68221277, -5.3413312],
                            [39.68228874, -5.34122991],
                            [39.68246598, -5.34107164],
                            [39.68271285, -5.34086272],
                            [39.68276983, -5.34074243],
                            [39.68285845, -5.34064747],
                            [39.68294707, -5.34041323],
                            [39.68301037, -5.34033093],
                            [39.6830167, -5.34026129],
                            [39.68297239, -5.34016633],
                            [39.68289643, -5.34002705],
                            [39.68279515, -5.33990043],
                            [39.68269387, -5.33983079],
                            [39.68261157, -5.33973583],
                            [39.68262362, -5.33964346],
                            [39.68271919, -5.33964087],
                            [39.68283352, -5.33968184],
                            [39.6829534, -5.33967885],
                            [39.68313707, -5.33973366],
                            [39.68327571, -5.33973062],
                            [39.68332688, -5.3397295],
                            [39.68337752, -5.33967252],
                            [39.68344082, -5.33955857],
                            [39.68346614, -5.33931166],
                            [39.68345981, -5.33887484],
                            [39.6833522, -5.33865959],
                            [39.68330156, -5.33860894],
                            [39.6832256, -5.33848232],
                            [39.68309267, -5.33823542],
                            [39.68288377, -5.33805816],
                            [39.68273818, -5.33795053],
                            [39.68253562, -5.33779859],
                            [39.68222544, -5.337615],
                            [39.68208618, -5.33744406],
                            [39.68193425, -5.3373491],
                            [39.68183297, -5.33729212],
                            [39.68174435, -5.3372668],
                            [39.6815861, -5.33722881],
                            [39.6815038, -5.33717184],
                            [39.68143417, -5.33707054],
                            [39.68142151, -5.33698191],
                            [39.68140252, -5.33690594],
                            [39.68133289, -5.33681098],
                            [39.68122528, -5.33676033],
                            [39.68104171, -5.33669702],
                            [39.68090877, -5.33666537],
                            [39.68083281, -5.3366717],
                            [39.68071887, -5.33670335],
                            [39.68067456, -5.33676666],
                            [39.68063658, -5.33689328],
                            [39.68075052, -5.33720349],
                            [39.68085813, -5.33728579],
                            [39.68098473, -5.33738708],
                            [39.68114299, -5.33754536],
                            [39.68119996, -5.33765931],
                            [39.6811873, -5.33773528],
                            [39.68114299, -5.33770996],
                            [39.68101005, -5.33762133],
                            [39.68090244, -5.33760866],
                            [39.68082015, -5.33765931],
                            [39.68077584, -5.33779226],
                            [39.6807252, -5.33791254],
                            [39.68071254, -5.33816578],
                            [39.68071887, -5.33833038],
                            [39.68082015, -5.33850131],
                            [39.68086446, -5.3385393],
                            [39.68106069, -5.33867858],
                            [39.68145949, -5.33882419],
                            [39.68178233, -5.3389698],
                            [39.68201654, -5.33902677],
                            [39.68213048, -5.33912807],
                            [39.68205452, -5.33920404],
                            [39.68188994, -5.33921037],
                            [39.68173169, -5.33910908],
                            [39.68167472, -5.33909008],
                            [39.68139619, -5.33902044],
                            [39.68122528, -5.33891915],
                            [39.68100372, -5.33881152],
                            [39.6808518, -5.33871656],
                            [39.68068089, -5.33864692],
                            [39.68053529, -5.33860261],
                            [39.68045933, -5.33860894],
                            [39.68043401, -5.33869124],
                            [39.6803264, -5.33884318],
                            [39.68024411, -5.33882419],
                            [39.68014915, -5.33869757],
                            [39.68017447, -5.33852664],
                            [39.68020613, -5.33836203],
                            [39.68023145, -5.33807715],
                            [39.68013649, -5.33796952],
                            [39.67983265, -5.33768463],
                            [39.67943069, -5.33761816],
                            [39.67942119, -5.33755168],
                            [39.6794022, -5.3374219],
                            [39.67935789, -5.33734277],
                            [39.67932307, -5.33727629],
                            [39.67930408, -5.33721615],
                            [39.67929775, -5.3370832],
                            [39.67929775, -5.33704838],
                            [39.67922179, -5.33701356],
                            [39.67912051, -5.33701356],
                            [39.67905404, -5.33699773],
                            [39.67902556, -5.33694709],
                            [39.67904138, -5.33689011],
                            [39.67908513, -5.33685977],
                            [39.67913634, -5.33684896],
                            [39.67918065, -5.33680781],
                            [39.67922179, -5.33675083],
                            [39.67921546, -5.33668752],
                            [39.67917748, -5.33659256],
                            [39.67909836, -5.33645645],
                            [39.67904455, -5.33638681],
                            [39.67896859, -5.33627602],
                            [39.67891478, -5.33618739],
                            [39.67888946, -5.3360766],
                            [39.67885781, -5.33598796],
                            [39.678804, -5.33589933],
                            [39.67875653, -5.33579487],
                            [39.67867424, -5.33565243],
                            [39.67862676, -5.33554481],
                            [39.67857296, -5.33549732],
                            [39.67849383, -5.33549099],
                            [39.67846218, -5.33551948],
                            [39.67849383, -5.33565559],
                            [39.67851598, -5.33573156],
                            [39.67856979, -5.33591199],
                            [39.67859511, -5.3359753],
                            [39.67859828, -5.33606077],
                            [39.67862676, -5.33618422],
                            [39.67867107, -5.33632666],
                            [39.67861727, -5.33633933],
                            [39.67854763, -5.33632666],
                            [39.67846851, -5.33629818],
                            [39.67844319, -5.33621587],
                            [39.67842103, -5.33608926],
                            [39.67839571, -5.33596264],
                            [39.67835773, -5.33587084],
                            [39.67828493, -5.33573156],
                            [39.67820264, -5.33561128],
                            [39.67818049, -5.33558279],
                            [39.67809503, -5.33555114],
                            [39.67804755, -5.33554164],
                            [39.67799058, -5.33548783],
                            [39.67797159, -5.33544035],
                            [39.67796843, -5.33531373],
                            [39.67796843, -5.33520927],
                            [39.67793361, -5.33516812],
                            [39.67785765, -5.33514913],
                            [39.67774054, -5.33509531],
                            [39.67761394, -5.33501618],
                            [39.67750949, -5.33493388],
                            [39.67739239, -5.33479776],
                            [39.67723097, -5.33454136],
                            [39.67719615, -5.33441475],
                            [39.67718982, -5.33437993],
                            [39.67715817, -5.33432295],
                            [39.67710753, -5.33423432],
                            [39.67709171, -5.33418367],
                            [39.67705056, -5.33412353],
                            [39.67700308, -5.3341077],
                            [39.67696194, -5.3341172],
                            [39.67692712, -5.33412669],
                            [39.67687965, -5.33408554],
                            [39.67682584, -5.33400324],
                            [39.67679103, -5.33394626],
                            [39.67670557, -5.33382914],
                            [39.67664543, -5.33369936],
                            [39.67662961, -5.33365188],
                            [39.67661062, -5.33362972],
                            [39.67655998, -5.33362656],
                            [39.67653149, -5.33364238],
                            [39.67647135, -5.33367404],
                            [39.67642071, -5.33366771],
                            [39.67642071, -5.33361706],
                            [39.67643654, -5.33356958],
                            [39.67645869, -5.33353159],
                            [39.67646186, -5.33350627],
                            [39.67643654, -5.33344613],
                            [39.67640805, -5.33347778],
                            [39.67636691, -5.33349994],
                            [39.67634159, -5.33346828],
                            [39.67630677, -5.33343663],
                            [39.67627196, -5.33340181],
                            [39.67620232, -5.33336066],
                            [39.67611687, -5.33335433],
                            [39.67603458, -5.33335433],
                            [39.67591747, -5.33341764],
                            [39.675851, -5.33346828],
                            [39.67580353, -5.3335221],
                            [39.67574655, -5.33356958],
                            [39.67568009, -5.33360756],
                            [39.67563578, -5.33372785],
                            [39.6755978, -5.33390511],
                            [39.67558514, -5.33395259],
                            [39.67556931, -5.33408554],
                            [39.67555981, -5.33421532],
                            [39.6755883, -5.33430712],
                            [39.67557564, -5.33433877],
                            [39.67557564, -5.33440525],
                            [39.67561995, -5.33448438],
                            [39.67566743, -5.33451604],
                            [39.67570857, -5.33454453],
                            [39.67569275, -5.33456035],
                            [39.6756611, -5.33462999],
                            [39.67573706, -5.33475345],
                            [39.67575288, -5.33483258],
                            [39.6757782, -5.33492754],
                            [39.67584783, -5.33506999],
                            [39.67582568, -5.33509531],
                            [39.67580669, -5.33514279],
                            [39.67583517, -5.33525675],
                            [39.67589531, -5.33534854],
                            [39.67594595, -5.33540869],
                            [39.67600925, -5.33545617],
                            [39.6760317, -5.33545165],
                            [39.67607888, -5.33543401],
                            [39.67608521, -5.33543401],
                            [39.67612952, -5.33546567],
                            [39.67618333, -5.33559228],
                            [39.67627828, -5.33573156],
                            [39.67633525, -5.33575689],
                            [39.6763131, -5.33578221],
                            [39.67625613, -5.33582019],
                            [39.676177, -5.33587084],
                            [39.67610737, -5.33586767],
                            [39.67598393, -5.33585185],
                            [39.67592379, -5.33587717],
                            [39.67576238, -5.33590882],
                            [39.6757244, -5.33601645],
                            [39.67569907, -5.33601961],
                            [39.6756516, -5.33602595],
                            [39.67555981, -5.33604177],
                            [39.67547752, -5.33607976],
                            [39.67543954, -5.33612091],
                            [39.67539523, -5.33619688],
                            [39.67530977, -5.33627285],
                            [39.67524014, -5.33635831],
                            [39.67520216, -5.33645644],
                            [39.67518, -5.33657989],
                            [39.67516101, -5.33664004],
                            [39.67514202, -5.33667486],
                            [39.67513886, -5.33673183],
                            [39.67509138, -5.33675083],
                            [39.67508189, -5.33683313],
                            [39.67513253, -5.33694708],
                            [39.67523381, -5.33704838],
                            [39.67553765, -5.33722564],
                            [39.67561362, -5.33730161],
                            [39.67574022, -5.33747887],
                            [39.67586682, -5.3376688],
                            [39.67612308, -5.33785018],
                            [39.67628461, -5.33782074],
                            [39.67644919, -5.3379347],
                            [39.67650469, -5.33796358],
                            [39.67660112, -5.33794736],
                            [39.67668974, -5.33804865],
                            [39.67691762, -5.33812462],
                            [39.67710753, -5.33822592],
                            [39.67740407, -5.33826537],
                            [39.67760128, -5.33823858],
                            [39.6776266, -5.33832721],
                            [39.6776266, -5.3383652],
                            [39.67744935, -5.33836519],
                            [39.67714551, -5.33837786],
                            [39.67696826, -5.33835253],
                            [39.67675304, -5.33833987],
                            [39.67657579, -5.33832721],
                            [39.67643653, -5.33823858],
                            [39.67614535, -5.33806131],
                            [39.67582884, -5.33792203],
                            [39.67570224, -5.33803599],
                            [39.67558829, -5.33812462],
                            [39.67537307, -5.33812462],
                            [39.67517051, -5.33817527],
                            [39.67496794, -5.33817527],
                            [39.67481602, -5.33802333],
                            [39.67465144, -5.337998],
                            [39.67456281, -5.33812462],
                            [39.67443621, -5.33830188],
                            [39.67443621, -5.33823857],
                            [39.67442355, -5.33812462],
                            [39.67434759, -5.33808663],
                            [39.67413236, -5.33787138],
                            [39.6738032, -5.33749153],
                            [39.67360063, -5.33751686],
                            [39.67346137, -5.33760549],
                            [39.67330945, -5.33747887],
                            [39.67299294, -5.33718765],
                            [39.67273974, -5.33670651],
                            [39.67248653, -5.33609875],
                            [39.67215737, -5.33579486],
                            [39.67206874, -5.3356176],
                            [39.67204342, -5.33533904],
                            [39.67196113, -5.33494337],
                            [39.67191682, -5.33481675],
                            [39.67186618, -5.33468697],
                            [39.67181238, -5.33446539],
                            [39.67178706, -5.33440683],
                            [39.67173167, -5.33428654],
                            [39.6717016, -5.3341805],
                            [39.67167628, -5.33412985],
                            [39.67163197, -5.33403014],
                            [39.67158449, -5.33390511],
                            [39.67152594, -5.3337959],
                            [39.67147846, -5.33356324],
                            [39.67147688, -5.33354267],
                            [39.67144365, -5.33350626],
                            [39.67137718, -5.33352051],
                            [39.67134078, -5.33353633],
                            [39.67129331, -5.33354583],
                            [39.67127273, -5.33352209],
                            [39.67129617, -5.33348822],
                            [39.67134395, -5.3334477],
                            [39.67132179, -5.33342554],
                            [39.67127273, -5.33336224],
                            [39.67123159, -5.33327835],
                            [39.67122526, -5.3332372],
                            [39.67122368, -5.33318972],
                            [39.67121735, -5.33313907],
                            [39.67122209, -5.3330821],
                            [39.67120469, -5.33300613],
                            [39.67119203, -5.33297447],
                            [39.67116354, -5.33292857],
                            [39.67112398, -5.3329349],
                            [39.67109707, -5.33292541],
                            [39.67108441, -5.33286368],
                            [39.67107492, -5.33280987],
                            [39.67106701, -5.33275289],
                            [39.67104643, -5.33271965],
                            [39.67098946, -5.33271807],
                            [39.67093566, -5.33270224],
                            [39.6709135, -5.33268642],
                            [39.67089926, -5.33265793],
                            [39.67087077, -5.33261203],
                            [39.6708502, -5.33257879],
                            [39.67081855, -5.33254397],
                            [39.67078848, -5.33249966],
                            [39.67076316, -5.33246325],
                            [39.67072676, -5.3324411],
                            [39.6706777, -5.33241894],
                            [39.67062864, -5.33240628],
                            [39.67057326, -5.33245059],
                            [39.67056851, -5.33248541],
                            [39.67055268, -5.33255347],
                            [39.67050995, -5.33254397],
                            [39.67049255, -5.33247908],
                            [39.67048463, -5.33243002],
                            [39.67047989, -5.33239678],
                            [39.67041975, -5.3323588],
                            [39.67035487, -5.33234139],
                            [39.67030264, -5.33237937],
                            [39.67026624, -5.33234455],
                            [39.67020136, -5.33234772],
                            [39.67015547, -5.33239361],
                            [39.67012307, -5.33241999],
                            [39.67008742, -5.33244901],
                            [39.67003203, -5.33244901],
                            [39.66998139, -5.33244268],
                            [39.66994974, -5.33242527],
                            [39.6699165, -5.33243476],
                            [39.66988644, -5.33246958],
                            [39.66985162, -5.33248383],
                            [39.66980414, -5.33246642],
                            [39.66978674, -5.33246325],
                            [39.66975509, -5.33245376],
                            [39.66972502, -5.3324316],
                            [39.66970286, -5.33241102],
                            [39.66965697, -5.3323762],
                            [39.66959525, -5.33235721],
                            [39.66956202, -5.33237779],
                            [39.6695367, -5.3323952],
                            [39.66951612, -5.33238253],
                            [39.66948764, -5.33236671],
                            [39.66944966, -5.33238253],
                            [39.669437, -5.33238253],
                            [39.6694006, -5.33239203],
                            [39.66935629, -5.33239678],
                            [39.66928982, -5.33242052],
                            [39.66927716, -5.33244743],
                            [39.66929773, -5.33248383],
                            [39.66934046, -5.33252814],
                            [39.66932147, -5.33256296],
                            [39.66930723, -5.33257246],
                            [39.66926925, -5.33256771],
                            [39.66924393, -5.33256929],
                            [39.66919487, -5.33257879],
                            [39.66916638, -5.33260253],
                            [39.66912998, -5.33262311],
                            [39.66910308, -5.33265001],
                            [39.66910308, -5.33268325],
                            [39.66913315, -5.33271016],
                            [39.66919012, -5.33270699],
                            [39.66921544, -5.33271332],
                            [39.66922019, -5.33271965],
                            [39.66921702, -5.33274339],
                            [39.66917746, -5.33276555],
                            [39.66911258, -5.33276555],
                            [39.66906352, -5.33274023],
                            [39.66905561, -5.33268958],
                            [39.6690651, -5.33262469],
                            [39.66906985, -5.33256455],
                            [39.66903029, -5.33251232],
                            [39.66898123, -5.33245534],
                            [39.66893375, -5.33245059],
                            [39.66889419, -5.33246642],
                            [39.66880715, -5.33250757],
                            [39.66873119, -5.33256771],
                            [39.66867896, -5.33264685],
                            [39.6686125, -5.3327608],
                            [39.66856502, -5.33281461],
                            [39.66849539, -5.33289375],
                            [39.66844633, -5.33290958],
                            [39.66839252, -5.33296339],
                            [39.66831498, -5.33301403],
                            [39.66827628, -5.33312048],
                            [39.66827067, -5.3331359],
                            [39.66825484, -5.33318338],
                            [39.66824693, -5.33323403],
                            [39.66827542, -5.33330684],
                            [39.66828966, -5.33336065],
                            [39.66831814, -5.33339863],
                            [39.66836404, -5.33342554],
                            [39.66843209, -5.33344612],
                            [39.66849064, -5.33346511],
                            [39.66856344, -5.33349518],
                            [39.66860775, -5.33351417],
                            [39.66865206, -5.33356165],
                            [39.66876442, -5.33361388],
                            [39.6688367, -5.33364339],
                            [39.66884196, -5.33364554],
                            [39.66892425, -5.33368669],
                            [39.66894008, -5.33375158],
                            [39.66892425, -5.33381489],
                            [39.66889081, -5.33381489],
                            [39.66887994, -5.33381489],
                            [39.66883088, -5.33377057],
                            [39.66879765, -5.33377215],
                            [39.66874543, -5.33375949],
                            [39.66868687, -5.33370093],
                            [39.66856027, -5.33364079],
                            [39.66842576, -5.33356957],
                            [39.66834188, -5.33352842],
                            [39.66827067, -5.33346352],
                            [39.66821528, -5.33345403],
                            [39.66812982, -5.3334477],
                            [39.66808235, -5.33344295],
                            [39.66804278, -5.33343978],
                            [39.66800164, -5.33349676],
                            [39.66801113, -5.33350467],
                            [39.66803012, -5.33353791],
                            [39.66800797, -5.33355216],
                            [39.66798739, -5.33357748],
                            [39.667951, -5.33364237],
                            [39.66790352, -5.33371043],
                            [39.66791143, -5.33373575],
                            [39.66793517, -5.33377057],
                            [39.66795416, -5.33381489],
                            [39.66796049, -5.3338687],
                            [39.66799531, -5.33394942],
                            [39.66808868, -5.33396841],
                            [39.6681045, -5.33399848],
                            [39.66808393, -5.33402064],
                            [39.66804911, -5.33401906],
                            [39.66798581, -5.33402064],
                            [39.66795416, -5.33404754],
                            [39.66792884, -5.33409661],
                            [39.66789877, -5.33412351],
                            [39.66784655, -5.33411085],
                            [39.6678054, -5.33415359],
                            [39.66775793, -5.33421848],
                            [39.66773735, -5.33428653],
                            [39.66769937, -5.33438466],
                            [39.66770254, -5.33440682],
                            [39.66773419, -5.33445114],
                            [39.66771203, -5.33449387],
                            [39.66764873, -5.3345366],
                            [39.66760126, -5.3346189],
                            [39.66757752, -5.33462682],
                            [39.66752371, -5.33465214],
                            [39.66747416, -5.33466728],
                            [39.66746674, -5.33466955],
                            [39.66742085, -5.33467113],
                            [39.66737021, -5.3347107],
                            [39.66734014, -5.33479142],
                            [39.66733855, -5.33481991],
                            [39.66733381, -5.33486739],
                            [39.66732273, -5.33490379],
                            [39.66731482, -5.33493228],
                            [39.66727051, -5.33499875],
                            [39.66723252, -5.33499401],
                            [39.66722145, -5.33494494],
                            [39.6672167, -5.33490379],
                            [39.66720404, -5.33485947],
                            [39.66721828, -5.3348025],
                            [39.66721037, -5.33476768],
                            [39.66720246, -5.33473761],
                            [39.66718347, -5.33470437],
                            [39.66713283, -5.33466005],
                            [39.66709168, -5.33464739],
                            [39.66703787, -5.33466164],
                            [39.6670173, -5.33467588],
                            [39.66697615, -5.33471861],
                            [39.66697774, -5.33476609],
                            [39.66697932, -5.33480566],
                            [39.6669445, -5.33485473],
                            [39.66690969, -5.33487688],
                            [39.6668274, -5.33489113],
                            [39.66679258, -5.33484681],
                            [39.66677992, -5.33478034],
                            [39.66671978, -5.33474552],
                            [39.66666756, -5.33476293],
                            [39.66665015, -5.3348294],
                            [39.66665965, -5.3349307],
                            [39.6667182, -5.33502882],
                            [39.66672137, -5.33506364],
                            [39.66670238, -5.33509372],
                            [39.66666439, -5.33509688],
                            [39.66661692, -5.33508897],
                            [39.66655837, -5.33513803],
                            [39.6665283, -5.33515544],
                            [39.66647924, -5.33519976],
                            [39.66644284, -5.33526148],
                            [39.66642227, -5.33530263],
                            [39.66638587, -5.33533112],
                            [39.66636055, -5.33535486],
                            [39.66633681, -5.33535011],
                            [39.66628933, -5.33531213],
                            [39.66623078, -5.33529472],
                            [39.66617064, -5.33531371],
                            [39.66613741, -5.33535011],
                            [39.66610101, -5.33536911],
                            [39.66607886, -5.33538493],
                            [39.66604404, -5.33541026],
                            [39.66597124, -5.33544033],
                            [39.66593168, -5.33545774],
                            [39.66589845, -5.33548464],
                            [39.66588421, -5.33550205],
                            [39.66584939, -5.33551946],
                            [39.6658209, -5.33551946],
                            [39.66579875, -5.3355068],
                            [39.66577976, -5.33548464],
                            [39.66573703, -5.33546882],
                            [39.66568797, -5.33547198],
                            [39.6656674, -5.33549731],
                            [39.6656405, -5.33553687],
                            [39.66561359, -5.33556536],
                            [39.66557086, -5.33562709],
                            [39.66553605, -5.33568565],
                            [39.66552022, -5.33570464],
                            [39.66551547, -5.33574737],
                            [39.66552497, -5.33580119],
                            [39.66549174, -5.33578219],
                            [39.66545692, -5.33576162],
                            [39.66540312, -5.33573629],
                            [39.66537463, -5.33572205],
                            [39.66532399, -5.33571097],
                            [39.66528601, -5.33573946],
                            [39.66524644, -5.33575212],
                            [39.66521954, -5.3357632],
                            [39.66519739, -5.33578536],
                            [39.66518789, -5.3357996],
                            [39.66517207, -5.33582493],
                            [39.66515624, -5.33580435],
                            [39.66512934, -5.33582651],
                            [39.66511035, -5.33584867],
                            [39.66508344, -5.3358819],
                            [39.66506762, -5.33591356],
                            [39.66506762, -5.33596104],
                            [39.66505654, -5.33597053],
                            [39.66501698, -5.33595471],
                            [39.66497741, -5.33600219],
                            [39.66493152, -5.33604334],
                            [39.66490936, -5.33606391],
                            [39.66490936, -5.33610348],
                            [39.66492202, -5.3361478],
                            [39.66492835, -5.33617945],
                            [39.66492202, -5.33620478],
                            [39.66490462, -5.33622377],
                            [39.66494576, -5.33628866],
                            [39.66494734, -5.33633614],
                            [39.66494101, -5.33637887],
                            [39.6649426, -5.33642794],
                            [39.66496159, -5.33648017],
                            [39.66500748, -5.33653556],
                            [39.66503597, -5.33660837],
                            [39.66509768, -5.33675556],
                            [39.66512301, -5.33680621],
                            [39.66516099, -5.33687426],
                            [39.66518156, -5.33693757],
                            [39.66520055, -5.33705311],
                            [39.66524486, -5.33708635],
                            [39.6652686, -5.33713699],
                            [39.66526702, -5.33720188],
                            [39.66524961, -5.3372367],
                            [39.66521321, -5.3372557],
                            [39.66515782, -5.33725411],
                            [39.66510243, -5.33724778],
                            [39.66507078, -5.33724462],
                            [39.6650423, -5.33720347],
                            [39.66504546, -5.33717023],
                            [39.66504863, -5.33711958],
                            [39.66503755, -5.33706735],
                            [39.66503122, -5.33700721],
                            [39.66501856, -5.33695815],
                            [39.66498058, -5.33688059],
                            [39.6649426, -5.33678247],
                            [39.66489987, -5.33676822],
                            [39.66488089, -5.3367393],
                            [39.66488879, -5.33672074],
                            [39.66488547, -5.33667595],
                            [39.66489987, -5.3366416],
                            [39.66489512, -5.33659412],
                            [39.66489987, -5.33653715],
                            [39.66490303, -5.33649441],
                            [39.66486347, -5.33647858],
                            [39.66484764, -5.33645801],
                            [39.66484238, -5.33643385],
                            [39.66484765, -5.33642002],
                            [39.66484448, -5.33639787],
                            [39.66482232, -5.33637254],
                            [39.66481125, -5.33633614],
                            [39.664797, -5.33633614],
                            [39.66475744, -5.33634722],
                            [39.66474593, -5.33633447],
                            [39.66475902, -5.33629657],
                            [39.66475111, -5.33625384],
                            [39.66474162, -5.33621585],
                            [39.66473687, -5.33618578],
                            [39.6647163, -5.33615413],
                            [39.66470047, -5.33613355],
                            [39.66468623, -5.33609715],
                            [39.6646704, -5.33606391],
                            [39.6646435, -5.33602276],
                            [39.66462451, -5.33600219],
                            [39.6646071, -5.33598636],
                            [39.66458495, -5.3359832],
                            [39.66455329, -5.33598636],
                            [39.66451848, -5.33599111],
                            [39.66449474, -5.33600377],
                            [39.66445992, -5.33603859],
                            [39.66442036, -5.33608924],
                            [39.66439662, -5.33617629],
                            [39.66439346, -5.33620319],
                            [39.66438713, -5.33625384],
                            [39.66437605, -5.33630449],
                            [39.66432857, -5.33630923],
                            [39.664308, -5.33631556],
                            [39.66427002, -5.33632031],
                            [39.66424154, -5.33632506],
                            [39.66420988, -5.33633614],
                            [39.66417507, -5.33634247],
                            [39.66416557, -5.3363488],
                            [39.66416083, -5.33637254],
                            [39.66417349, -5.33639628],
                            [39.66418615, -5.33640894],
                            [39.6641814, -5.33643427],
                            [39.66416557, -5.33644851],
                            [39.66414658, -5.33647225],
                            [39.66413234, -5.33647067],
                            [39.66410385, -5.33645801],
                            [39.66407062, -5.33645168],
                            [39.66405843, -5.33643539],
                            [39.66406429, -5.33640103],
                            [39.66407695, -5.33638837],
                            [39.6640918, -5.33638168],
                            [39.66409594, -5.3363583],
                            [39.66408645, -5.33633931],
                            [39.66406746, -5.33632348],
                            [39.66405005, -5.33632506],
                            [39.66401682, -5.33631715],
                            [39.66399941, -5.33628549],
                            [39.66401365, -5.33628233],
                            [39.66403422, -5.33626967],
                            [39.664068, -5.33622912],
                            [39.66407246, -5.33620194],
                            [39.66407537, -5.3361842],
                            [39.66406113, -5.33615571],
                            [39.66404214, -5.33616204],
                            [39.66400596, -5.33619601],
                            [39.66401049, -5.33616521],
                            [39.66397725, -5.33614463],
                            [39.66397223, -5.3361372],
                            [39.66395199, -5.3361072],
                            [39.66395617, -5.33607513],
                            [39.66395879, -5.33605511],
                            [39.66396301, -5.33602276],
                            [39.66395035, -5.33599427],
                            [39.6639187, -5.33598161],
                            [39.66389021, -5.33599111],
                            [39.66386964, -5.33600694],
                            [39.66383957, -5.33601801],
                            [39.66382375, -5.33600852],
                            [39.66384115, -5.33599111],
                            [39.66383482, -5.33595787],
                            [39.66382533, -5.33591514],
                            [39.66381742, -5.33587399],
                            [39.66375886, -5.33581859],
                            [39.66370347, -5.33579643],
                            [39.66368765, -5.33576161],
                            [39.66367182, -5.33574579],
                            [39.66363384, -5.33574262],
                            [39.66360378, -5.33574895],
                            [39.6635832, -5.3357442],
                            [39.6635468, -5.33574262],
                            [39.66351832, -5.33572996],
                            [39.66347084, -5.3357078],
                            [39.66344077, -5.33569989],
                            [39.66340596, -5.33567931],
                            [39.66337906, -5.33568564],
                            [39.6633569, -5.33571255],
                            [39.66333316, -5.33571413],
                            [39.66330309, -5.33569672],
                            [39.6632667, -5.33568564],
                            [39.6632208, -5.33566823],
                            [39.66319073, -5.33566982],
                            [39.66317016, -5.33569989],
                            [39.66314642, -5.33573946],
                            [39.66312743, -5.33577586],
                            [39.66310686, -5.33579802],
                            [39.66308312, -5.33583442],
                            [39.66305938, -5.33588032],
                            [39.66305147, -5.33590089],
                            [39.66305464, -5.3359278],
                            [39.66307046, -5.33596262],
                            [39.66309262, -5.33598794],
                            [39.66312585, -5.33601485],
                            [39.66312427, -5.33603542],
                            [39.66309736, -5.33604808],
                            [39.66308312, -5.33606549],
                            [39.66308629, -5.33608924],
                            [39.66310369, -5.33611614],
                            [39.66314009, -5.3361478],
                            [39.66312585, -5.33616521],
                            [39.66307679, -5.33616837],
                            [39.66303406, -5.3361747],
                            [39.6630214, -5.33618736],
                            [39.66300874, -5.33621902],
                            [39.66300083, -5.33625225],
                            [39.66298817, -5.33631556],
                            [39.66301349, -5.3363393],
                            [39.66303406, -5.33634563],
                            [39.66306255, -5.33635513],
                            [39.6630673, -5.33636779],
                            [39.66306255, -5.33639945],
                            [39.66302773, -5.33642477],
                            [39.66300083, -5.33646909],
                            [39.66301507, -5.33650074],
                            [39.66304039, -5.33652765],
                            [39.66307363, -5.33659095],
                            [39.66310369, -5.33666218],
                            [39.66313851, -5.33668433],
                            [39.66315433, -5.33671599],
                            [39.6631211, -5.3367334],
                            [39.6630942, -5.33674448],
                            [39.66308312, -5.33682994],
                            [39.66307362, -5.33685052],
                            [39.66308945, -5.33688534],
                            [39.66312901, -5.3369075],
                            [39.66312427, -5.33696131],
                            [39.66309895, -5.33697397],
                            [39.66308787, -5.33699138],
                            [39.66308945, -5.33702145],
                            [39.66309103, -5.33706577],
                            [39.66311319, -5.3371085],
                            [39.66312901, -5.33713224],
                            [39.66313376, -5.33715598],
                            [39.66311319, -5.3371639],
                            [39.66308154, -5.33716231],
                            [39.66306888, -5.33714807],
                            [39.66304925, -5.33711788],
                            [39.66304339, -5.33706996],
                            [39.66303881, -5.33703253],
                            [39.66300702, -5.33696848],
                            [39.66299766, -5.33689642],
                            [39.66297234, -5.33682361],
                            [39.66294851, -5.33677773],
                            [39.66294386, -5.33674448],
                            [39.66297551, -5.33672707],
                            [39.66299133, -5.33670966],
                            [39.66300014, -5.33667871],
                            [39.6629945, -5.33663211],
                            [39.66297818, -5.3366013],
                            [39.66297551, -5.33657988],
                            [39.6629581, -5.33655613],
                            [39.66292803, -5.3365498],
                            [39.66288847, -5.3365498],
                            [39.66286315, -5.33656247],
                            [39.66283038, -5.33656],
                            [39.6628231, -5.33651561],
                            [39.66281884, -5.33648966],
                            [39.66284732, -5.33644693],
                            [39.66284732, -5.33637887],
                            [39.66286631, -5.33634089],
                            [39.66283941, -5.3362934],
                            [39.66281567, -5.33625225],
                            [39.6628584, -5.33625225],
                            [39.6628679, -5.33622535],
                            [39.66283605, -5.33617566],
                            [39.66282948, -5.33613092],
                            [39.66282359, -5.33609082],
                            [39.66279035, -5.33605283],
                            [39.66276028, -5.33604017],
                            [39.66272389, -5.33602593],
                            [39.66269698, -5.33602593],
                            [39.662659, -5.33602751],
                            [39.66262893, -5.33604967],
                            [39.66260361, -5.33607341],
                            [39.66259887, -5.33610664],
                            [39.66257513, -5.3361652],
                            [39.66257355, -5.336257],
                            [39.66256405, -5.33632506],
                            [39.66254506, -5.33641686],
                            [39.66254506, -5.3364675],
                            [39.66254664, -5.33653714],
                            [39.66254506, -5.33659728],
                            [39.66256721, -5.33667326],
                            [39.66255614, -5.33669541],
                            [39.66256405, -5.33672232],
                            [39.66257196, -5.3367334],
                            [39.66260678, -5.33676347],
                            [39.66263685, -5.33678088],
                            [39.66262577, -5.33678879],
                            [39.66260678, -5.33680779],
                            [39.66259095, -5.33681728],
                            [39.66258462, -5.33683944],
                            [39.66257513, -5.33686001],
                            [39.6625862, -5.33691699],
                            [39.6625957, -5.33693915],
                            [39.66262102, -5.33694865],
                            [39.66263843, -5.33693282],
                            [39.66265267, -5.33694865],
                            [39.66264159, -5.33697239],
                            [39.66262577, -5.33699929],
                            [39.66266375, -5.33703253],
                            [39.66267008, -5.33705785],
                            [39.66265267, -5.33708001],
                            [39.66263368, -5.33708793],
                            [39.6626226, -5.33712433],
                            [39.66263843, -5.3371639],
                            [39.66264951, -5.33720188],
                            [39.66268749, -5.33720663],
                            [39.66267641, -5.33722087],
                            [39.66267324, -5.33726044],
                            [39.66270806, -5.33738073],
                            [39.66274604, -5.33741396],
                            [39.66278402, -5.33743454],
                            [39.66279985, -5.33743612],
                            [39.66281409, -5.33741871],
                            [39.66282517, -5.33740289],
                            [39.66283466, -5.33741238],
                            [39.66282517, -5.33743296],
                            [39.66280934, -5.33745037],
                            [39.66281567, -5.33747411],
                            [39.66282517, -5.33750418],
                            [39.66283466, -5.33753425],
                            [39.66286156, -5.33757382],
                            [39.66287739, -5.33762288],
                            [39.66286631, -5.33763713],
                            [39.66285998, -5.33766878],
                            [39.66288055, -5.33767986],
                            [39.66288372, -5.33769885],
                            [39.66288055, -5.33770202],
                            [39.66287422, -5.33770993],
                            [39.66286315, -5.33772101],
                            [39.6628584, -5.33774317],
                            [39.66287106, -5.33776691],
                            [39.66288055, -5.33778432],
                            [39.66289638, -5.33779382],
                            [39.66292012, -5.33781914],
                            [39.66292012, -5.33785079],
                            [39.66293278, -5.33789036],
                            [39.6629581, -5.3378872],
                            [39.66297234, -5.33789669],
                            [39.66297551, -5.33790935],
                            [39.66297551, -5.33795209],
                            [39.66297709, -5.33798058],
                            [39.66303406, -5.33799957],
                            [39.66303564, -5.33799957],
                            [39.66305147, -5.33799165],
                            [39.66306413, -5.33799324],
                            [39.66308154, -5.33800273],
                            [39.66308312, -5.33801381],
                            [39.66310527, -5.3380423],
                            [39.66311793, -5.33805655],
                            [39.66312901, -5.33805655],
                            [39.663148, -5.33805655],
                            [39.66315117, -5.33806762],
                            [39.66315908, -5.33809611],
                            [39.66314958, -5.33811669],
                            [39.66313851, -5.33813568],
                            [39.6631575, -5.33815942],
                            [39.66318124, -5.33817367],
                            [39.66319073, -5.33817208],
                            [39.66320339, -5.3381705],
                            [39.66324295, -5.33815626],
                            [39.66326194, -5.33814993],
                            [39.66329201, -5.33814834],
                            [39.66332208, -5.33813726],
                            [39.66334265, -5.33815309],
                            [39.66335057, -5.33819266],
                            [39.66333791, -5.3382623],
                            [39.66337747, -5.33827179],
                            [39.66340754, -5.33828446],
                            [39.66341861, -5.33829079],
                            [39.66342969, -5.33829712],
                            [39.66344394, -5.33832402],
                            [39.66344235, -5.33834935],
                            [39.66346609, -5.33837467],
                            [39.66348983, -5.33837625],
                            [39.66353097, -5.33839208],
                            [39.66355471, -5.33842848],
                            [39.66354522, -5.33846647],
                            [39.66354838, -5.33849337],
                            [39.66359111, -5.33847596],
                            [39.66361168, -5.3384823],
                            [39.66362276, -5.33849654],
                            [39.66364808, -5.33854086],
                            [39.66366549, -5.33858992],
                            [39.66366549, -5.33862949],
                            [39.66368765, -5.3386469],
                            [39.66370664, -5.33865956],
                            [39.66373987, -5.33866906],
                            [39.66379526, -5.3386928],
                            [39.66380317, -5.33872445],
                            [39.66379842, -5.3387656],
                            [39.66380475, -5.33881625],
                            [39.66382374, -5.33884948],
                            [39.66384115, -5.33887956],
                            [39.66387122, -5.33891279],
                            [39.66388071, -5.33891596],
                            [39.66390129, -5.33890171],
                            [39.66393294, -5.33887956],
                            [39.66395984, -5.33885423],
                            [39.66397567, -5.33886689],
                            [39.66398833, -5.33890805],
                            [39.66401998, -5.33889855],
                            [39.66404055, -5.33889222],
                            [39.66405796, -5.33887481],
                            [39.66409119, -5.3388843],
                            [39.66410227, -5.33889064],
                            [39.66410702, -5.33889697],
                            [39.66411809, -5.33890963],
                            [39.66412442, -5.33894603],
                            [39.66411176, -5.33897768],
                            [39.66410385, -5.3389856],
                            [39.66408011, -5.33900143],
                            [39.66406745, -5.33901567],
                            [39.66403264, -5.33901725],
                            [39.66401681, -5.33899668],
                            [39.66399149, -5.33900143],
                            [39.66397883, -5.33904258],
                            [39.66396301, -5.3390679],
                            [39.66393135, -5.33910272],
                            [39.6639092, -5.33911222],
                            [39.66388704, -5.33913754],
                            [39.66388546, -5.33917078],
                            [39.66389812, -5.33919452],
                            [39.66392502, -5.33920243],
                            [39.6639456, -5.33921826],
                            [39.66392977, -5.33924358],
                            [39.663963, -5.33927682],
                            [39.66397883, -5.33929264],
                            [39.66402789, -5.33931955],
                            [39.66404371, -5.33931955],
                            [39.66407378, -5.33931639],
                            [39.66411493, -5.33928473],
                            [39.66412917, -5.33924675],
                            [39.66416082, -5.33923567],
                            [39.66419564, -5.33923883],
                            [39.66423045, -5.33924042],
                            [39.66426832, -5.33923037],
                            [39.66429217, -5.33920401],
                            [39.66433965, -5.3392056],
                            [39.66433807, -5.33923567],
                            [39.66431749, -5.3392689],
                            [39.66427002, -5.33929898],
                            [39.66421938, -5.33930689],
                            [39.66416873, -5.3393148],
                            [39.66411018, -5.33935437],
                            [39.66404213, -5.33937495],
                            [39.66394718, -5.33941926],
                            [39.66392661, -5.33943034],
                            [39.6638997, -5.33946041],
                            [39.66387755, -5.33949048],
                            [39.66386647, -5.33952689],
                            [39.66385381, -5.33956012],
                            [39.66386014, -5.33959336],
                            [39.66387422, -5.33959415],
                            [39.66388863, -5.33957595],
                            [39.66390445, -5.33956645],
                            [39.66390603, -5.33959336],
                            [39.66389496, -5.33960444],
                            [39.66388863, -5.33962818],
                            [39.6638997, -5.33964559],
                            [39.66391236, -5.33966933],
                            [39.66390129, -5.33968357],
                            [39.66387597, -5.33968041],
                            [39.66386647, -5.33971523],
                            [39.66386172, -5.33976429],
                            [39.66388546, -5.33977221],
                            [39.66392186, -5.33978012],
                            [39.6639456, -5.33979753],
                            [39.66395826, -5.33983868],
                            [39.66398832, -5.33986084],
                            [39.66402631, -5.33988774],
                            [39.66402156, -5.33991623],
                            [39.66401365, -5.33992415],
                            [39.66399782, -5.33993206],
                            [39.66398516, -5.33993522],
                            [39.66396933, -5.33995105],
                            [39.66396459, -5.33997954],
                            [39.66397725, -5.34001436],
                            [39.66399465, -5.34003652],
                            [39.66403738, -5.34005393],
                            [39.66406429, -5.34006501],
                            [39.66409277, -5.34007134],
                            [39.66412284, -5.34011091],
                            [39.66411809, -5.34013939],
                            [39.66408644, -5.34013465],
                            [39.66405637, -5.34011249],
                            [39.66403264, -5.34012673],
                            [39.66400098, -5.34014098],
                            [39.66397408, -5.34014256],
                            [39.66394718, -5.34013148],
                            [39.66393135, -5.34009666],
                            [39.66391236, -5.34006184],
                            [39.66388862, -5.34001278],
                            [39.66385381, -5.3400017],
                            [39.66383007, -5.3399922],
                            [39.66381108, -5.3399558],
                            [39.66378734, -5.33994947],
                            [39.66377785, -5.33996213],
                            [39.66375253, -5.33997638],
                            [39.66373828, -5.3399558],
                            [39.6637098, -5.33995422],
                            [39.66369081, -5.33998429],
                            [39.66365283, -5.34000961],
                            [39.66362434, -5.34002544],
                            [39.66362118, -5.34005868],
                            [39.66358161, -5.34007609],
                            [39.66352306, -5.34009508],
                            [39.66342221, -5.34016521],
                            [39.6635143, -5.34000194],
                            [39.66366498, -5.33972565],
                            [39.66366917, -5.33947029],
                            [39.66349755, -5.33913539],
                            [39.66334268, -5.33897632],
                            [39.66293248, -5.33875444],
                            [39.662719, -5.33845722],
                            [39.66255576, -5.33819349],
                            [39.66235065, -5.33808464],
                            [39.66231816, -5.33810946],
                            [39.66222508, -5.33810558],
                            [39.66213718, -5.33804278],
                            [39.66209114, -5.33792975],
                            [39.66209114, -5.3378544],
                            [39.66209323, -5.33781254],
                            [39.66209532, -5.33777068],
                            [39.66206602, -5.33737717],
                            [39.66201161, -5.33718042],
                            [39.66196975, -5.33676598],
                            [39.66194045, -5.33653992],
                            [39.66187348, -5.336272],
                            [39.66186092, -5.33609618],
                            [39.66194464, -5.33583245],
                            [39.66208695, -5.33565244],
                            [39.66210788, -5.33550592],
                            [39.66212881, -5.33536777],
                            [39.66228787, -5.33507892],
                            [39.6623088, -5.33482775],
                            [39.66243437, -5.33460169],
                            [39.66260181, -5.33428773],
                            [39.66260599, -5.33401981],
                            [39.66257669, -5.33382305],
                            [39.66255995, -5.33352164],
                            [39.66243856, -5.33323698],
                            [39.66228369, -5.33306953],
                            [39.66214137, -5.33302348],
                            [39.66205347, -5.33298999],
                            [39.66199906, -5.33291464],
                            [39.66199069, -5.33280579],
                            [39.66191534, -5.33274719],
                            [39.66188433, -5.33278877],
                            [39.66177721, -5.33265928],
                            [39.6616349, -5.33266765],
                            [39.6616036, -5.33271109],
                            [39.66157211, -5.33270951],
                            [39.66155118, -5.33269695],
                            [39.66145491, -5.33254206],
                            [39.66131678, -5.33250857],
                            [39.66123725, -5.3324374],
                            [39.66106145, -5.33227414],
                            [39.66093169, -5.33216111],
                            [39.66086471, -5.33219042],
                            [39.66080611, -5.33216948],
                            [39.66077681, -5.33207739],
                            [39.66079774, -5.33199785],
                            [39.6606931, -5.3318597],
                            [39.6605173, -5.3317383],
                            [39.66051351, -5.33173565],
                            [39.66034987, -5.33162109],
                            [39.66033061, -5.33165442],
                            [39.66019918, -5.33158341],
                            [39.66008198, -5.33155411],
                            [39.65996896, -5.33154573],
                            [39.65991873, -5.33154155],
                            [39.6598685, -5.33150806],
                            [39.65978479, -5.33150387],
                            [39.65974293, -5.33148294],
                            [39.65964247, -5.33142015],
                            [39.6595755, -5.33147038],
                            [39.65952527, -5.33146201],
                            [39.65947504, -5.33142433],
                            [39.65939551, -5.33133642],
                            [39.6593405, -5.33128141],
                            [39.6593118, -5.3312527],
                            [39.65921971, -5.33120665],
                            [39.65912344, -5.3311899],
                            [39.65901461, -5.33119827],
                            [39.65893508, -5.33117316],
                            [39.6588681, -5.33113129],
                            [39.65888485, -5.3310685],
                            [39.65897693, -5.33100989],
                            [39.65907321, -5.33095129],
                            [39.65914018, -5.33080477],
                            [39.65915692, -5.33056615],
                            [39.65906902, -5.33045312],
                            [39.65901042, -5.33030661],
                            [39.65900205, -5.33020614],
                            [39.65896438, -5.33021032],
                            [39.65890996, -5.33016846],
                            [39.65888903, -5.33007218],
                            [39.65885136, -5.33006799],
                            [39.65881369, -5.33004287],
                            [39.65879695, -5.32995915],
                            [39.65871742, -5.32990054],
                            [39.65861905, -5.32990263],
                            [39.65856673, -5.32995077],
                            [39.65847046, -5.32995287],
                            [39.65841814, -5.32990473],
                            [39.65838465, -5.32983984],
                            [39.65842232, -5.32976867],
                            [39.65843906, -5.32973518],
                            [39.65849976, -5.32971634],
                            [39.65849767, -5.3296389],
                            [39.6584872, -5.32951959],
                            [39.65848929, -5.32943587],
                            [39.65844744, -5.32940028],
                            [39.65841604, -5.32932912],
                            [39.65832186, -5.32931656],
                            [39.65826536, -5.32928725],
                            [39.65824861, -5.32923911],
                            [39.65824443, -5.3291826],
                            [39.65822978, -5.32913655],
                            [39.65820466, -5.32913864],
                            [39.65812932, -5.32910515],
                            [39.65807909, -5.32905492],
                            [39.65796398, -5.32889584],
                            [39.65789492, -5.32884142],
                            [39.65785724, -5.32884351],
                            [39.65782899, -5.32881735],
                            [39.65780074, -5.32879118],
                            [39.6577819, -5.32871583],
                            [39.65770028, -5.32859234],
                            [39.65761866, -5.32849605],
                            [39.65757889, -5.32848768],
                            [39.65749936, -5.32841652],
                            [39.65744285, -5.3284207],
                            [39.65738844, -5.32834535],
                            [39.6573705, -5.32830499],
                            [39.65735495, -5.32827],
                            [39.65728798, -5.32817581],
                            [39.65718124, -5.32819255],
                            [39.65709753, -5.32826162],
                            [39.65701381, -5.32825953],
                            [39.65698451, -5.3282093],
                            [39.65704102, -5.32803347],
                            [39.65696986, -5.32791417],
                            [39.65691126, -5.32781998],
                            [39.65680243, -5.32772997],
                            [39.65670197, -5.32766299],
                            [39.65663709, -5.32760438],
                            [39.6565471, -5.32751647],
                            [39.65642571, -5.32737205],
                            [39.65626875, -5.32721297],
                            [39.6561641, -5.32708738],
                            [39.65608876, -5.32706436],
                            [39.65600504, -5.32706436],
                            [39.655959, -5.32703296],
                            [39.65591505, -5.3269597],
                            [39.65590249, -5.326899],
                            [39.6558125, -5.32675248],
                            [39.65574134, -5.32670853],
                            [39.65565135, -5.32666876],
                            [39.65558647, -5.32660178],
                            [39.6555467, -5.32651596],
                            [39.65549019, -5.32645526],
                            [39.65545252, -5.32640712],
                            [39.65537509, -5.32631711],
                            [39.65529137, -5.32624595],
                            [39.65518882, -5.32615385],
                            [39.65510301, -5.32607012],
                            [39.65505488, -5.32599477],
                            [39.65499627, -5.32601989],
                            [39.65497535, -5.32598221],
                            [39.65496488, -5.32592151],
                            [39.65491465, -5.32581895],
                            [39.65486024, -5.32580639],
                            [39.65481419, -5.32577918],
                            [39.65479745, -5.32572685],
                            [39.65474932, -5.32560126],
                            [39.65468862, -5.32552173],
                            [39.65464049, -5.32547358],
                            [39.65460491, -5.32543591],
                            [39.65456514, -5.32541916],
                            [39.65453166, -5.32533334],
                            [39.65450863, -5.32526009],
                            [39.65451073, -5.32519938],
                            [39.65449608, -5.32509054],
                            [39.65444585, -5.32503821],
                            [39.6544019, -5.3250131],
                            [39.65433702, -5.325011],
                            [39.65428679, -5.32500891],
                            [39.65422609, -5.32497961],
                            [39.65421144, -5.32491053],
                            [39.65423656, -5.32486239],
                            [39.65429097, -5.32481844],
                            [39.6542826, -5.32469494],
                            [39.65422191, -5.32466773],
                            [39.65417587, -5.32457145],
                            [39.65415284, -5.32448563],
                            [39.65414238, -5.32440609],
                            [39.65414238, -5.3242198],
                            [39.65408169, -5.32414864],
                            [39.65402518, -5.3240984],
                            [39.65401053, -5.32397282],
                            [39.65402309, -5.32389537],
                            [39.65398123, -5.3237949],
                            [39.65394356, -5.32377606],
                            [39.65392472, -5.3237342],
                            [39.65392472, -5.32364838],
                            [39.65395193, -5.32363792],
                            [39.6539603, -5.32355001],
                            [39.65392263, -5.32335744],
                            [39.65388286, -5.32312929],
                            [39.6538724, -5.32300789],
                            [39.65387659, -5.32295347],
                            [39.65388077, -5.32290951],
                            [39.65387659, -5.32281532],
                            [39.65387662, -5.32281498],
                            [39.65389333, -5.32263113],
                            [39.65390589, -5.32252019],
                            [39.65393937, -5.32238204],
                            [39.65393937, -5.32223762],
                            [39.65400844, -5.32210157],
                            [39.65402937, -5.32195923],
                            [39.65407541, -5.32174364],
                            [39.65413401, -5.32144642],
                            [39.6541675, -5.32123501],
                            [39.65416959, -5.32085407],
                            [39.65418634, -5.32062173],
                            [39.65425122, -5.3202973],
                            [39.65429307, -5.3202052],
                            [39.65448562, -5.32001891],
                            [39.65450027, -5.31991426],
                            [39.65449818, -5.31969448],
                            [39.6545212, -5.31926748],
                            [39.65457143, -5.31890328],
                            [39.65459027, -5.31870862],
                            [39.65455469, -5.31854536],
                            [39.65454632, -5.31844698],
                            [39.65456934, -5.31832767],
                            [39.65459864, -5.3182586],
                            [39.65464677, -5.3181979],
                            [39.65466352, -5.31810789],
                            [39.65469282, -5.31796556],
                            [39.65463212, -5.31790905],
                            [39.65458817, -5.31786509],
                            [39.65462794, -5.31783579],
                            [39.65468235, -5.31776881],
                            [39.65473258, -5.31763485],
                            [39.65474933, -5.3175595],
                            [39.65474723, -5.31748624],
                            [39.65470747, -5.31745275],
                            [39.65463213, -5.31742763],
                            [39.65455469, -5.31741088],
                            [39.65444167, -5.31736274],
                            [39.65435796, -5.31732088],
                            [39.65428052, -5.31729367],
                            [39.65417378, -5.31728739],
                            [39.654111, -5.31732088],
                            [39.65401054, -5.31743181],
                            [39.6539645, -5.31746321],
                            [39.65387659, -5.31752601],
                            [39.65382009, -5.31763903],
                            [39.65380125, -5.31769555],
                            [39.65373218, -5.31781904],
                            [39.65374265, -5.31787974],
                            [39.65377823, -5.31804719],
                            [39.65379288, -5.31816441],
                            [39.65384101, -5.31831511],
                            [39.65391636, -5.31842605],
                            [39.65393729, -5.3185014],
                            [39.6539624, -5.31857675],
                            [39.65399379, -5.31862489],
                            [39.65393729, -5.31866257],
                            [39.65395612, -5.31882165],
                            [39.65394665, -5.31895899],
                            [39.65393638, -5.31910791],
                            [39.65392814, -5.31922737],
                            [39.65400635, -5.31924236],
                            [39.65406286, -5.31927585],
                            [39.65406031, -5.31931024],
                            [39.65398751, -5.31934493],
                            [39.65398163, -5.31942719],
                            [39.65397675, -5.31949546],
                            [39.65394984, -5.31952284],
                            [39.65389543, -5.31950819],
                            [39.65385775, -5.31956889],
                            [39.65388078, -5.31962122],
                            [39.65390589, -5.31965052],
                            [39.65390432, -5.31967315],
                            [39.65389512, -5.31980612],
                            [39.65390589, -5.3198996],
                            [39.65396868, -5.31995612],
                            [39.65396615, -5.31999106],
                            [39.65396262, -5.32003971],
                            [39.65402728, -5.3201759],
                            [39.65402387, -5.32022106],
                            [39.65394984, -5.32020101],
                            [39.65391845, -5.32014868],
                            [39.65386194, -5.32012147],
                            [39.65380334, -5.32013403],
                            [39.65376776, -5.32015915],
                            [39.65371962, -5.32019264],
                            [39.65371733, -5.32022874],
                            [39.65371192, -5.32031408],
                            [39.65370273, -5.32045899],
                            [39.6536673, -5.32065522],
                            [39.65365865, -5.32079348],
                            [39.65365331, -5.32087896],
                            [39.6536487, -5.32095272],
                            [39.65364455, -5.32101905],
                            [39.65364141, -5.32106922],
                            [39.65363695, -5.32114063],
                            [39.65355638, -5.32114292],
                            [39.65344336, -5.32119524],
                            [39.65339104, -5.32122455],
                            [39.65329058, -5.32126222],
                            [39.65323407, -5.3212706],
                            [39.65315664, -5.32123292],
                            [39.65308339, -5.32118269],
                            [39.65302897, -5.32109268],
                            [39.65294735, -5.3210257],
                            [39.65291595, -5.32088965],
                            [39.65290968, -5.32077662],
                            [39.65286154, -5.32068452],
                            [39.65276527, -5.32059452],
                            [39.65268992, -5.3205401],
                            [39.65262714, -5.32050451],
                            [39.65256226, -5.3205401],
                            [39.6525204, -5.32056731],
                            [39.65245502, -5.32059346],
                            [39.65244715, -5.32059661],
                            [39.65241994, -5.32059661],
                            [39.65234041, -5.32061545],
                            [39.65230902, -5.32070964],
                            [39.65230804, -5.3208206],
                            [39.65232576, -5.32098384],
                            [39.65235925, -5.32108012],
                            [39.65241157, -5.32113454],
                            [39.65241058, -5.32120985],
                            [39.65238436, -5.32131246],
                            [39.65238338, -5.3213941],
                            [39.65243878, -5.3215741],
                            [39.6524597, -5.32164108],
                            [39.65238645, -5.32169131],
                            [39.65235925, -5.32177713],
                            [39.65235807, -5.32188182],
                            [39.65238436, -5.32195295],
                            [39.65247017, -5.32200528],
                            [39.65251203, -5.32200737],
                            [39.65251143, -5.32204074],
                            [39.6524618, -5.32206179],
                            [39.6524032, -5.32211412],
                            [39.6523425, -5.3221811],
                            [39.65223577, -5.32231506],
                            [39.6522348, -5.32246791],
                            [39.65220019, -5.32262694],
                            [39.65215624, -5.32265415],
                            [39.65206624, -5.32271485],
                            [39.65203275, -5.32280904],
                            [39.65203293, -5.32288441],
                            [39.65208926, -5.32292835],
                            [39.65212275, -5.32294718],
                            [39.65210182, -5.32298277],
                            [39.65205996, -5.32300161],
                            [39.65202229, -5.32302672],
                            [39.65200555, -5.32308952],
                            [39.65200573, -5.32313977],
                            [39.65205368, -5.32316905],
                            [39.65205372, -5.32319206],
                            [39.65197834, -5.32319417],
                            [39.65188835, -5.32318371],
                            [39.65185067, -5.32322557],
                            [39.65185157, -5.32331129],
                            [39.65179835, -5.32336162],
                            [39.65179873, -5.32339089],
                            [39.65170208, -5.32333022],
                            [39.65164557, -5.32335953],
                            [39.65164659, -5.32341175],
                            [39.65168115, -5.3234893],
                            [39.65168157, -5.32351243],
                            [39.6516372, -5.32355419],
                            [39.65162464, -5.32356047],
                            [39.65152209, -5.3236735],
                            [39.65144256, -5.32374466],
                            [39.65138396, -5.32381583],
                            [39.65128559, -5.32390165],
                            [39.6511663, -5.32401886],
                            [39.65114638, -5.32404695],
                            [39.65108468, -5.32413398],
                            [39.65108812, -5.32420879],
                            [39.65109409, -5.32421258],
                            [39.65115165, -5.3242491],
                            [39.65118723, -5.32429306],
                            [39.65114537, -5.32435167],
                            [39.65108677, -5.3243705],
                            [39.65103176, -5.32441451],
                            [39.65102398, -5.32442074],
                            [39.65102521, -5.32444563],
                            [39.65102769, -5.3244957],
                            [39.65103022, -5.32454685],
                            [39.65103224, -5.32458768],
                            [39.65098914, -5.32461699],
                            [39.6509884, -5.32461749],
                            [39.65093189, -5.32465726],
                            [39.65089422, -5.32469703],
                            [39.65088167, -5.3247054],
                            [39.65083771, -5.32474727],
                            [39.65085446, -5.32480378],
                            [39.65085584, -5.32482746],
                            [39.65087539, -5.32492937],
                            [39.65081469, -5.32497123],
                            [39.65076028, -5.32502146],
                            [39.6507247, -5.32507798],
                            [39.65069958, -5.32514287],
                            [39.65072679, -5.32521194],
                            [39.65070168, -5.32527892],
                            [39.650664, -5.32535636],
                            [39.6506685, -5.32542137],
                            [39.65070377, -5.32547358],
                            [39.65077074, -5.32555102],
                            [39.6507226, -5.32563056],
                            [39.65067865, -5.32566196],
                            [39.65061587, -5.32563684],
                            [39.6506054, -5.3256808],
                            [39.6506368, -5.32571429],
                            [39.65064324, -5.32580413],
                            [39.65065982, -5.32584825],
                            [39.65064307, -5.32590895],
                            [39.65062842, -5.32594244],
                            [39.65063132, -5.32598215],
                            [39.65068075, -5.32602198],
                            [39.65068392, -5.32606683],
                            [39.6506858, -5.32609352],
                            [39.6506772, -5.32609874],
                            [39.65062424, -5.32613082],
                            [39.65060959, -5.32622292],
                            [39.65061385, -5.32627974],
                            [39.6506075, -5.32641758],
                            [39.65060903, -5.32642253],
                            [39.65064517, -5.32653898],
                            [39.65066191, -5.32658084],
                            [39.65062633, -5.32673573],
                            [39.65059494, -5.32681318],
                            [39.6505761, -5.32689062],
                            [39.65058445, -5.32699578],
                            [39.65054471, -5.32710621],
                            [39.65049239, -5.32716691],
                            [39.65048611, -5.32727157],
                            [39.65049128, -5.32733182],
                            [39.65049852, -5.32741619],
                            [39.65050907, -5.32753913],
                            [39.6504882, -5.32762112],
                            [39.65045681, -5.32778857],
                            [39.65046412, -5.32786992],
                            [39.6504882, -5.32794765],
                            [39.65056354, -5.32808789],
                            [39.65059075, -5.32813393],
                            [39.65055936, -5.32828045],
                            [39.65057085, -5.32841314],
                            [39.65065144, -5.32848976],
                            [39.65068074, -5.3285107],
                            [39.65068405, -5.32855161],
                            [39.65060958, -5.3286007],
                            [39.65063261, -5.32868652],
                            [39.65068493, -5.32872629],
                            [39.65071214, -5.32884978],
                            [39.65071872, -5.32893156],
                            [39.65074771, -5.32908002],
                            [39.65081469, -5.32910514],
                            [39.65087538, -5.32910724],
                            [39.65095072, -5.32912607],
                            [39.65101142, -5.32916375],
                            [39.6510763, -5.32921189],
                            [39.65107855, -5.32924905],
                            [39.6509884, -5.32924329],
                            [39.65091515, -5.32922026],
                            [39.65083562, -5.32921398],
                            [39.65075609, -5.32923701],
                            [39.65073934, -5.32924957],
                            [39.65069539, -5.32925794],
                            [39.65060749, -5.32922026],
                            [39.65055098, -5.3292391],
                            [39.65055359, -5.32926739],
                            [39.6505447, -5.32933329],
                            [39.65058028, -5.32939399],
                            [39.65063051, -5.32942748],
                            [39.65059493, -5.32951749],
                            [39.65060151, -5.32958977],
                            [39.65060451, -5.32962279],
                            [39.65058447, -5.32967447],
                            [39.65047564, -5.32972261],
                            [39.65037518, -5.3297582],
                            [39.65035007, -5.32984401],
                            [39.65029774, -5.32995913],
                            [39.65024961, -5.33003239],
                            [39.65025767, -5.33010214],
                            [39.65022031, -5.33022287],
                            [39.65020775, -5.3303045],
                            [39.65014078, -5.33035473],
                            [39.65009473, -5.33040916],
                            [39.65008636, -5.33049497],
                            [39.65010253, -5.33061963],
                            [39.65012194, -5.33065196],
                            [39.65011566, -5.33072312],
                            [39.65006962, -5.33082987],
                            [39.65002357, -5.33088848],
                            [39.6500152, -5.33095127],
                            [39.65004869, -5.33102872],
                            [39.65011985, -5.33108105],
                            [39.65015752, -5.33109151],
                            [39.65018473, -5.33108733],
                            [39.65019196, -5.33114372],
                            [39.65018263, -5.3311878],
                            [39.6502203, -5.33125059],
                            [39.65027053, -5.33127361],
                            [39.65040448, -5.33133431],
                            [39.65042959, -5.33135943],
                            [39.65043634, -5.3314187],
                            [39.65041704, -5.33145781],
                            [39.6503689, -5.33147665],
                            [39.65028937, -5.33145572],
                            [39.65023705, -5.33142432],
                            [39.6502224, -5.33141176],
                            [39.65014077, -5.33136571],
                            [39.65008427, -5.33134687],
                            [39.65002776, -5.33134478],
                            [39.64999009, -5.33133431],
                            [39.64993777, -5.33134269],
                            [39.64991474, -5.33138455],
                            [39.64989591, -5.33142013],
                            [39.64989172, -5.3314892],
                            [39.64984358, -5.33156456],
                            [39.64979545, -5.33160223],
                            [39.64977033, -5.331642],
                            [39.6496929, -5.33166712],
                            [39.64964476, -5.33171735],
                            [39.64965315, -5.33176615],
                            [39.64965104, -5.33184922],
                            [39.64962174, -5.33192248],
                            [39.64958407, -5.33196644],
                            [39.64954849, -5.33199365],
                            [39.64954639, -5.33207109],
                            [39.64957779, -5.33214226],
                            [39.64958232, -5.33216713],
                            [39.64961127, -5.33222389],
                            [39.64957569, -5.33225529],
                            [39.64951082, -5.33231808],
                            [39.64952502, -5.33239312],
                            [39.6495443, -5.33240599],
                            [39.64949826, -5.33242692],
                            [39.64946477, -5.33247088],
                            [39.64945012, -5.33254623],
                            [39.64947105, -5.33257553],
                            [39.64946477, -5.33261949],
                            [39.64943547, -5.33265926],
                            [39.64940617, -5.33266972],
                            [39.6493685, -5.33266344],
                            [39.64932455, -5.332676],
                            [39.6493099, -5.33270949],
                            [39.64932646, -5.3327883],
                            [39.64936013, -5.33282461],
                            [39.64932245, -5.33285392],
                            [39.64928269, -5.33283299],
                            [39.64923665, -5.33280159],
                            [39.64917177, -5.33277438],
                            [39.64912782, -5.33282252],
                            [39.64914006, -5.33287674],
                            [39.64914628, -5.33290424],
                            [39.64915182, -5.33292878],
                            [39.64910898, -5.33294183],
                            [39.64906503, -5.33294392],
                            [39.64902945, -5.33298997],
                            [39.64900015, -5.33302974],
                            [39.64903154, -5.33307579],
                            [39.64900643, -5.33311137],
                            [39.64897504, -5.33311346],
                            [39.64890388, -5.33308416],
                            [39.64883481, -5.33312602],
                            [39.64878668, -5.33314905],
                            [39.64876365, -5.33318254],
                            [39.64876575, -5.3332537],
                            [39.64876365, -5.33332906],
                            [39.64878684, -5.33341692],
                            [39.64879802, -5.33345927],
                            [39.64882011, -5.333543],
                            [39.64888295, -5.33367023],
                            [39.64893108, -5.33371],
                            [39.6489855, -5.3337728],
                            [39.64905247, -5.33381047],
                            [39.64909642, -5.33386908],
                            [39.64909014, -5.33394862],
                            [39.64906084, -5.33398211],
                            [39.64894573, -5.33408886],
                            [39.64884528, -5.33407839],
                            [39.64872807, -5.33401769],
                            [39.64864436, -5.33388792],
                            [39.64858157, -5.33385652],
                            [39.64851251, -5.33385024],
                            [39.648456, -5.3338628],
                            [39.64840996, -5.33386489],
                            [39.64829066, -5.33381257],
                            [39.64821741, -5.33378117],
                            [39.64815672, -5.33376652],
                            [39.64805835, -5.33372675],
                            [39.64799556, -5.33374559],
                            [39.64790348, -5.33376652],
                            [39.6478386, -5.33380001],
                            [39.64780302, -5.33385443],
                            [39.64778628, -5.33392141],
                            [39.64770675, -5.33393815],
                            [39.64762931, -5.33394652],
                            [39.64753722, -5.33396955],
                            [39.64747025, -5.33401978],
                            [39.6474263, -5.33404909],
                            [39.64736979, -5.33413072],
                            [39.64734258, -5.33419351],
                            [39.64733421, -5.33423956],
                            [39.64732793, -5.33429398],
                            [39.64731119, -5.33434003],
                            [39.64728398, -5.3343798],
                            [39.64725468, -5.33439864],
                            [39.64720864, -5.33439236],
                            [39.64713539, -5.33437143],
                            [39.64709562, -5.33438817],
                            [39.64708934, -5.3344405],
                            [39.64706632, -5.33450539],
                            [39.6470433, -5.33453678],
                            [39.64699935, -5.33461214],
                            [39.64694703, -5.33467284],
                            [39.64691354, -5.33472307],
                            [39.64692191, -5.33477331],
                            [39.64694483, -5.33482277],
                            [39.64692819, -5.33484866],
                            [39.64688006, -5.33485284],
                            [39.6468382, -5.33485284],
                            [39.64678378, -5.33488006],
                            [39.64672727, -5.33489889],
                            [39.64670216, -5.33492192],
                            [39.6466917, -5.33497843],
                            [39.64670368, -5.33500274],
                            [39.6467503, -5.33503076],
                            [39.64673983, -5.33505588],
                            [39.646721, -5.33510611],
                            [39.646721, -5.33514379],
                            [39.64674102, -5.33518402],
                            [39.64675103, -5.33520412],
                            [39.64674192, -5.33523798],
                            [39.64672937, -5.33525891],
                            [39.64670635, -5.33530705],
                            [39.64670425, -5.33534263],
                            [39.64671992, -5.33537344],
                            [39.64673565, -5.3354452],
                            [39.64675437, -5.33548186],
                            [39.64678587, -5.33551427],
                            [39.64682577, -5.3355926],
                            [39.64683192, -5.3356587],
                            [39.64685925, -5.33571208],
                            [39.64688141, -5.33575536],
                            [39.64688005, -5.33580731],
                            [39.64685075, -5.33580312],
                            [39.64680262, -5.33574242],
                            [39.64672309, -5.33569428],
                            [39.64664356, -5.33564823],
                            [39.64656612, -5.335598],
                            [39.64651799, -5.33558962],
                            [39.64647403, -5.33559172],
                            [39.64645311, -5.33561893],
                            [39.64643218, -5.33561893],
                            [39.64640916, -5.33561055],
                            [39.64635893, -5.33557078],
                            [39.64633381, -5.33557078],
                            [39.64627312, -5.33556869],
                            [39.6462208, -5.33558544],
                            [39.64619568, -5.33560218],
                            [39.64617057, -5.33563986],
                            [39.64617885, -5.33565425],
                            [39.6461894, -5.33571102],
                            [39.64619784, -5.33572564],
                            [39.64618522, -5.33575079],
                            [39.64617266, -5.33575707],
                            [39.64613917, -5.33577382],
                            [39.64614127, -5.33583242],
                            [39.6461894, -5.33586801],
                            [39.64618731, -5.33591406],
                            [39.6462187, -5.33599569],
                            [39.64621242, -5.33601453],
                            [39.64619359, -5.3360208],
                            [39.64613708, -5.33599987],
                            [39.64607848, -5.33598522],
                            [39.64604499, -5.33598522],
                            [39.64599058, -5.33597476],
                            [39.64596128, -5.33595801],
                            [39.64589849, -5.33594964],
                            [39.64578548, -5.33595173],
                            [39.64573106, -5.33598941],
                            [39.64564107, -5.3359915],
                            [39.64558037, -5.33598731],
                            [39.64547573, -5.33599987],
                            [39.6453962, -5.33600406],
                            [39.64526016, -5.3360522],
                            [39.64519319, -5.33606476],
                            [39.64511157, -5.33615058],
                            [39.64508645, -5.33619035],
                            [39.64508854, -5.33623849],
                            [39.64502157, -5.33628244],
                            [39.64500274, -5.33627198],
                            [39.64490228, -5.33621756],
                            [39.64481438, -5.33617569],
                            [39.6447788, -5.33619872],
                            [39.64473694, -5.33626151],
                            [39.64472648, -5.33629082],
                            [39.64470555, -5.33630547],
                            [39.64464485, -5.33630756],
                            [39.64457579, -5.33629919],
                            [39.64450672, -5.33631384],
                            [39.64450001, -5.33631618],
                            [39.64445859, -5.33633058],
                            [39.64440836, -5.33637245],
                            [39.64437068, -5.33641222],
                            [39.6443372, -5.3365085],
                            [39.64430999, -5.33653152],
                            [39.64426604, -5.33654618],
                            [39.64420116, -5.33655245],
                            [39.64415721, -5.33657757],
                            [39.64411954, -5.3365985],
                            [39.64409442, -5.33668223],
                            [39.64408396, -5.33676595],
                            [39.64405466, -5.33683084],
                            [39.64399815, -5.3368727],
                            [39.64396676, -5.3368727],
                            [39.64393118, -5.33679944],
                            [39.64392071, -5.33672828],
                            [39.64391234, -5.33669897],
                            [39.64389979, -5.33664874],
                            [39.64386421, -5.3365985],
                            [39.64382026, -5.33657339],
                            [39.643747, -5.33657548],
                            [39.64369678, -5.33657339],
                            [39.64363399, -5.33654827],
                            [39.64356492, -5.33651896],
                            [39.64347284, -5.3365085],
                            [39.6434247, -5.33652734],
                            [39.64340168, -5.33656083],
                            [39.64336819, -5.3366006],
                            [39.64328448, -5.33661734],
                            [39.64320913, -5.33661525],
                            [39.64314425, -5.33661525],
                            [39.64305007, -5.33660687],
                            [39.64297473, -5.33665502],
                            [39.64293287, -5.3366906],
                            [39.64284288, -5.33671781],
                            [39.64280102, -5.33665711],
                            [39.64279893, -5.33659222],
                            [39.64276963, -5.33652524],
                            [39.64272149, -5.33648129],
                            [39.64265661, -5.33648966],
                            [39.64255197, -5.33651478],
                            [39.64245988, -5.33655664],
                            [39.64238244, -5.3365692],
                            [39.64229873, -5.33657757],
                            [39.64221292, -5.33659222],
                            [39.6421313, -5.33661943],
                            [39.6420727, -5.33667595],
                            [39.6420434, -5.33670734],
                            [39.64206223, -5.33677223],
                            [39.64205386, -5.33682456],
                            [39.64201619, -5.3368727],
                            [39.64196596, -5.33691456],
                            [39.64193038, -5.3369041],
                            [39.64191573, -5.33685595],
                            [39.64191782, -5.3367806],
                            [39.64186759, -5.33678479],
                            [39.64183829, -5.33683293],
                            [39.64177551, -5.33687061],
                            [39.64175667, -5.33689154],
                            [39.64172528, -5.33693968],
                            [39.641719, -5.33697526],
                            [39.64175458, -5.33706736],
                            [39.64176086, -5.3371155],
                            [39.64175039, -5.33718039],
                            [39.64173783, -5.33722434],
                            [39.641719, -5.33728086],
                            [39.64169388, -5.33731644],
                            [39.64166877, -5.33737714],
                            [39.6416583, -5.33745249],
                            [39.64162273, -5.33745249],
                            [39.64149506, -5.33744412],
                            [39.64142809, -5.33743784],
                            [39.64140507, -5.33745668],
                            [39.64139042, -5.33750901],
                            [39.64137786, -5.33755087],
                            [39.64134856, -5.33771204],
                            [39.64129624, -5.33783135],
                            [39.64128158, -5.33789414],
                            [39.64131954, -5.33792234],
                            [39.64137576, -5.33792554],
                            [39.6414056, -5.33794781],
                            [39.64137995, -5.33799461],
                            [39.64138414, -5.33807415],
                            [39.64135902, -5.33814113],
                            [39.64135902, -5.33817043],
                            [39.64137786, -5.33821229],
                            [39.6413653, -5.33829602],
                            [39.64133181, -5.33832114],
                            [39.64130461, -5.33837765],
                            [39.64130461, -5.33839858],
                            [39.64127949, -5.33851789],
                            [39.6412774, -5.33858278],
                            [39.64129414, -5.33865394],
                            [39.64131507, -5.33875023],
                            [39.64131507, -5.33883604],
                            [39.641336, -5.33887372],
                            [39.64136111, -5.33892605],
                            [39.64140716, -5.33893861],
                            [39.64146879, -5.33897891],
                            [39.64149296, -5.33902652],
                            [39.6415279, -5.33904926],
                            [39.64158296, -5.33906629],
                            [39.64161435, -5.33904745],
                            [39.64165621, -5.33902024],
                            [39.64168551, -5.33898256],
                            [39.64168551, -5.33891977],
                            [39.64164575, -5.33886326],
                            [39.64163319, -5.33880674],
                            [39.64161017, -5.33871464],
                            [39.64159552, -5.33869371],
                            [39.6415118, -5.33869371],
                            [39.64147831, -5.33866441],
                            [39.64147413, -5.33860371],
                            [39.64149297, -5.33858068],
                            [39.64153692, -5.33852417],
                            [39.64153692, -5.33849487],
                            [39.64153273, -5.33846138],
                            [39.64155157, -5.33842789],
                            [39.64159552, -5.33842161],
                            [39.64161226, -5.33838393],
                            [39.6416311, -5.33832742],
                            [39.64171272, -5.33829183],
                            [39.64176085, -5.33826044],
                            [39.64179853, -5.33825416],
                            [39.64185294, -5.33824579],
                            [39.64187387, -5.33823113],
                            [39.64190061, -5.33825128],
                            [39.64193666, -5.33826253],
                            [39.64196596, -5.33826462],
                            [39.64196386, -5.33829811],
                            [39.64193456, -5.3383337],
                            [39.6419241, -5.33837137],
                            [39.64191364, -5.33844254],
                            [39.64192619, -5.33849277],
                            [39.64195243, -5.33851196],
                            [39.64195549, -5.33858068],
                            [39.64198689, -5.33863301],
                            [39.64202456, -5.33867906],
                            [39.64206343, -5.33870705],
                            [39.64212502, -5.33871883],
                            [39.64216687, -5.3387272],
                            [39.64220226, -5.33875287],
                            [39.64224222, -5.33874604],
                            [39.64227361, -5.33873976],
                            [39.64243686, -5.33864348],
                            [39.64244941, -5.33860999],
                            [39.64250592, -5.33858906],
                            [39.64254987, -5.33859952],
                            [39.64257376, -5.33861787],
                            [39.64263149, -5.33866022],
                            [39.64264614, -5.33873976],
                            [39.64262312, -5.33878163],
                            [39.6426001, -5.33882349],
                            [39.64255615, -5.33887163],
                            [39.64254359, -5.3389721],
                            [39.64262521, -5.33906838],
                            [39.64263777, -5.3391228],
                            [39.64258754, -5.33912908],
                            [39.64256243, -5.33913118],
                            [39.64246616, -5.3391228],
                            [39.64236988, -5.33910187],
                            [39.64235711, -5.33915659],
                            [39.64231756, -5.3392463],
                            [39.64231337, -5.33939072],
                            [39.64232384, -5.3394284],
                            [39.64228407, -5.33950584],
                            [39.64226023, -5.33960711],
                            [39.64217106, -5.3397026],
                            [39.64211246, -5.33981144],
                            [39.64210624, -5.3398411],
                            [39.64211246, -5.33985958],
                            [39.64202246, -5.33988889],
                            [39.64195549, -5.33990772],
                            [39.64191782, -5.33998726],
                            [39.64190342, -5.34007382],
                            [39.64189828, -5.34010471],
                            [39.64183829, -5.34019239],
                            [39.64175667, -5.34029076],
                            [39.64172109, -5.34033263],
                            [39.64161854, -5.34050845],
                            [39.64156621, -5.34061101],
                            [39.64155721, -5.34071717],
                            [39.64154636, -5.34084518],
                            [39.64157668, -5.34093335],
                            [39.64157132, -5.34098766],
                            [39.6415097, -5.34104638],
                            [39.64155365, -5.34118871],
                            [39.64156831, -5.34125151],
                            [39.64154319, -5.34127244],
                            [39.64147413, -5.34127244],
                            [39.64147074, -5.34132107],
                            [39.6414825, -5.3414043],
                            [39.64148044, -5.34143087],
                            [39.6414511, -5.34153826],
                            [39.64141343, -5.34157594],
                            [39.64139041, -5.34161362],
                            [39.64133599, -5.34171409],
                            [39.64131716, -5.34177479],
                            [39.64131692, -5.34182707],
                            [39.64136739, -5.34187107],
                            [39.64134227, -5.34191293],
                            [39.64134106, -5.34197578],
                            [39.64131297, -5.3420134],
                            [39.64129414, -5.34206154],
                            [39.64129462, -5.34211389],
                            [39.64125646, -5.34217457],
                            [39.64122716, -5.34221853],
                            [39.64120833, -5.34226876],
                            [39.64125646, -5.34238179],
                            [39.64127111, -5.34242156],
                            [39.64125228, -5.34252412],
                            [39.64125554, -5.34258906],
                            [39.64126175, -5.34271255],
                            [39.64126848, -5.34284639],
                            [39.64127445, -5.34291254],
                            [39.64128654, -5.34304632],
                            [39.6413046, -5.34324625],
                            [39.64133599, -5.34330486],
                            [39.64131506, -5.34337602],
                            [39.64134855, -5.34341998],
                            [39.64134442, -5.34344365],
                            [39.64133585, -5.34349285],
                            [39.64129623, -5.34348486],
                            [39.64131716, -5.34359161],
                            [39.64130878, -5.34364813],
                            [39.64132553, -5.34375488],
                            [39.64128785, -5.34383651],
                            [39.64119367, -5.34393489],
                            [39.64116437, -5.34402698],
                            [39.64119786, -5.34411489],
                            [39.64118112, -5.34415466],
                            [39.64114345, -5.34423629],
                            [39.64119786, -5.34426769],
                            [39.64123135, -5.34428444],
                            [39.64123344, -5.34433467],
                            [39.64129413, -5.34442886],
                            [39.64133808, -5.34447282],
                            [39.64141971, -5.34447491],
                            [39.64147831, -5.34446654],
                            [39.64161644, -5.34446235],
                            [39.64173782, -5.34447491],
                            [39.6418048, -5.34450003],
                            [39.64182782, -5.34454608],
                            [39.64187805, -5.34459631],
                            [39.64197641, -5.34462143],
                            [39.64207269, -5.34462771],
                            [39.64207687, -5.34465073],
                            [39.64205385, -5.3446612],
                            [39.64195548, -5.34468422],
                            [39.64187177, -5.34466329],
                            [39.64178596, -5.34461934],
                            [39.64169178, -5.34457747],
                            [39.64161016, -5.34457329],
                            [39.64152016, -5.34456701],
                            [39.64148458, -5.34458375],
                            [39.64138413, -5.3446298],
                            [39.64132762, -5.34465701],
                            [39.64128157, -5.34471562],
                            [39.64125855, -5.3447826],
                            [39.6412732, -5.34485167],
                            [39.64123972, -5.3449354],
                            [39.64122088, -5.3449647],
                            [39.64120414, -5.34501284],
                            [39.64119995, -5.34508192],
                            [39.64125437, -5.34510494],
                            [39.641246, -5.3451468],
                            [39.64120832, -5.34518657],
                            [39.64117693, -5.34523471],
                            [39.64112879, -5.34530169],
                            [39.64110368, -5.34537495],
                            [39.64113089, -5.34544193],
                            [39.64116437, -5.34551728],
                            [39.64117693, -5.3456052],
                            [39.64117693, -5.34565124],
                            [39.64107856, -5.34567218],
                            [39.64098229, -5.34576637],
                            [39.6409641, -5.34578819],
                            [39.6409195, -5.34584172],
                            [39.64087765, -5.34585846],
                            [39.64082951, -5.34592963],
                            [39.64078347, -5.3461201],
                            [39.64077928, -5.34622685],
                            [39.64079184, -5.34630011],
                            [39.64076672, -5.34635453],
                            [39.64073114, -5.34640686],
                            [39.6406558, -5.34647593],
                            [39.64063487, -5.34653663],
                            [39.64066836, -5.34661199],
                            [39.64065789, -5.3466371],
                            [39.64064115, -5.34666641],
                            [39.64071649, -5.34676897],
                            [39.64075626, -5.34678781],
                            [39.64076882, -5.34682967],
                            [39.64071649, -5.34682967],
                            [39.64066417, -5.34684851],
                            [39.64062441, -5.34686316],
                            [39.64056581, -5.34695735],
                            [39.64060766, -5.34700759],
                            [39.64060766, -5.34703689],
                            [39.64060766, -5.34706619],
                            [39.64059929, -5.34710178],
                            [39.64058673, -5.34713527],
                            [39.64058255, -5.34722318],
                            [39.64061185, -5.34729434],
                            [39.64063487, -5.34737597],
                            [39.64068091, -5.34745761],
                            [39.64076463, -5.34754552],
                            [39.64079393, -5.34760412],
                            [39.640773, -5.34761459],
                            [39.64073533, -5.3476418],
                            [39.64072486, -5.34774227],
                            [39.64072486, -5.34781762],
                            [39.64073533, -5.34784902],
                            [39.640773, -5.34785949],
                            [39.64082741, -5.34786158],
                            [39.64085044, -5.34786158],
                            [39.64085881, -5.34789088],
                            [39.64086509, -5.34790763],
                            [39.64088183, -5.34798089],
                            [39.64092787, -5.34809392],
                            [39.64094462, -5.34820276],
                            [39.64098857, -5.34830532],
                            [39.64104507, -5.34842672],
                            [39.64107647, -5.3484623],
                            [39.64114344, -5.34848533],
                            [39.64115809, -5.34846858],
                            [39.64117693, -5.34854394],
                            [39.64119367, -5.3486172],
                            [39.64131296, -5.34872604],
                            [39.64136528, -5.34873441],
                            [39.6414197, -5.34874697],
                            [39.64144691, -5.34876162],
                            [39.64153899, -5.34878883],
                            [39.64162271, -5.34879093],
                            [39.6417148, -5.3487386],
                            [39.64178177, -5.34878046],
                            [39.64179642, -5.34879511],
                            [39.64180479, -5.34882442],
                            [39.6418027, -5.34887256],
                            [39.64177549, -5.34890605],
                            [39.64172317, -5.34894163],
                            [39.64169177, -5.34896675],
                            [39.64167503, -5.34900233],
                            [39.64167085, -5.34906512],
                            [39.64170433, -5.34911327],
                            [39.64172735, -5.34914466],
                            [39.64174619, -5.34917397],
                            [39.64175665, -5.3491949],
                            [39.6417734, -5.34929746],
                            [39.64181316, -5.34932886],
                            [39.64193246, -5.34945444],
                            [39.64195966, -5.34951933],
                            [39.64198896, -5.3495591],
                            [39.64201826, -5.3495884],
                            [39.64206431, -5.34960096],
                            [39.64209361, -5.34961143],
                            [39.64214174, -5.34957166],
                            [39.64216058, -5.34954654],
                            [39.64218569, -5.34955282],
                            [39.64218988, -5.34957794],
                            [39.64218988, -5.34960724],
                            [39.6421836, -5.34967841],
                            [39.64217523, -5.34972864],
                            [39.64217942, -5.34977888],
                            [39.64221918, -5.34985214],
                            [39.64229871, -5.3499233],
                            [39.64235522, -5.34993796],
                            [39.642418, -5.34997773],
                            [39.64240126, -5.3500468],
                            [39.64238661, -5.35011587],
                            [39.64245358, -5.35016401],
                            [39.64245149, -5.3501975],
                            [39.64243056, -5.35024774],
                            [39.64243893, -5.35030635],
                            [39.64248288, -5.35030844],
                            [39.64251009, -5.35028541],
                            [39.64253311, -5.35028751],
                            [39.64258962, -5.3503817],
                            [39.64261264, -5.35045286],
                            [39.64264194, -5.3505031],
                            [39.64270473, -5.35060566],
                            [39.64276751, -5.35061194],
                            [39.64285123, -5.35061194],
                            [39.64289204, -5.35060462],
                            [39.64293285, -5.35059729],
                            [39.6429475, -5.35062659],
                            [39.64292448, -5.35066218],
                            [39.6428303, -5.3506852],
                            [39.64276542, -5.3507145],
                            [39.64272356, -5.35079823],
                            [39.6427424, -5.35090498],
                            [39.64279054, -5.35097824],
                            [39.64287844, -5.35103057],
                            [39.64296006, -5.3510515],
                            [39.64300401, -5.35106615],
                            [39.64307517, -5.35107871],
                            [39.64314005, -5.35106824],
                            [39.64318818, -5.35104731],
                            [39.64322795, -5.35103475],
                            [39.64323841, -5.35108289],
                            [39.6432426, -5.35113732],
                            [39.64331376, -5.35118964],
                            [39.64322376, -5.35121895],
                            [39.64316516, -5.35125453],
                            [39.64313795, -5.35129011],
                            [39.64314633, -5.35133616],
                            [39.6432133, -5.351445],
                            [39.64325516, -5.35151408],
                            [39.64332422, -5.35155594],
                            [39.64336608, -5.35156641],
                            [39.64339329, -5.35157059],
                            [39.64337864, -5.35158943],
                            [39.64334515, -5.35160408],
                            [39.64331376, -5.35163129],
                            [39.64330957, -5.3516585],
                            [39.64327818, -5.35169409],
                            [39.6433012, -5.35174432],
                            [39.64334515, -5.35181339],
                            [39.64335561, -5.35188247],
                            [39.64337026, -5.35193061],
                            [39.6433891, -5.3519934],
                            [39.64343514, -5.35201643],
                            [39.64347491, -5.35202271],
                            [39.64351049, -5.35206248],
                            [39.6435356, -5.3521169],
                            [39.64354188, -5.35215248],
                            [39.64360257, -5.35216922],
                            [39.64362978, -5.35222365],
                            [39.64363397, -5.35227179],
                            [39.64367164, -5.35231784],
                            [39.64372187, -5.3522969],
                            [39.64375954, -5.35224458],
                            [39.64380349, -5.35222155],
                            [39.6438307, -5.35226342],
                            [39.64382442, -5.3522969],
                            [39.64379721, -5.35233249],
                            [39.64377628, -5.35234086],
                            [39.64373442, -5.35238272],
                            [39.6437114, -5.35241203],
                            [39.6436842, -5.3524497],
                            [39.6436842, -5.35247482],
                            [39.64366954, -5.35251668],
                            [39.64367582, -5.35253971],
                            [39.64369047, -5.3525711],
                            [39.64371977, -5.35262552],
                            [39.64373024, -5.35264646],
                            [39.64373652, -5.35266948],
                            [39.6437407, -5.35272599],
                            [39.64376372, -5.35279925],
                            [39.6437721, -5.35284949],
                            [39.64377419, -5.35288926],
                            [39.64377837, -5.35293321],
                            [39.64378465, -5.35297089],
                            [39.64381186, -5.35297717],
                            [39.64388093, -5.35298345],
                            [39.64394581, -5.35298973],
                            [39.64399185, -5.35299182],
                            [39.64402952, -5.35299601],
                            [39.64409021, -5.35299391],
                            [39.64415928, -5.35299182],
                            [39.64430369, -5.35296042],
                            [39.6443581, -5.35296252],
                            [39.64423881, -5.3530295],
                            [39.64422625, -5.35306927],
                            [39.64424718, -5.35311322],
                            [39.64427648, -5.35313625],
                            [39.6442995, -5.35316136],
                            [39.64434764, -5.35316346],
                            [39.64437275, -5.35316136],
                            [39.64439996, -5.35316764],
                            [39.64441252, -5.35317811],
                            [39.644446, -5.35319276],
                            [39.64445646, -5.35319509],
                            [39.64448368, -5.35320113],
                            [39.644536, -5.35322207],
                            [39.64459251, -5.35324509],
                            [39.64460192, -5.35326916],
                            [39.64461134, -5.35329323],
                            [39.64461762, -5.35334137],
                            [39.64461762, -5.35338533],
                            [39.64463018, -5.35347533],
                            [39.64463646, -5.35352975],
                            [39.64463227, -5.35358208],
                            [39.64462808, -5.35363022],
                            [39.64461343, -5.35367627],
                            [39.64456321, -5.35370139],
                            [39.64454917, -5.35365803],
                            [39.64447112, -5.3535758],
                            [39.64441042, -5.35354859],
                            [39.64435392, -5.35353603],
                            [39.64432848, -5.35352786],
                            [39.64429532, -5.35351719],
                            [39.64425346, -5.35351929],
                            [39.64422834, -5.35352347],
                            [39.64412998, -5.35358417],
                            [39.64408393, -5.35364069],
                            [39.64405045, -5.35369092],
                            [39.64404417, -5.35372441],
                            [39.6440358, -5.35375162],
                            [39.64399394, -5.35377883],
                            [39.64394999, -5.35376418],
                            [39.6438872, -5.35376628],
                            [39.64386418, -5.35376628],
                            [39.64380558, -5.35382279],
                            [39.64376582, -5.35389814],
                            [39.64373652, -5.35393582],
                            [39.64371977, -5.35394838],
                            [39.64374698, -5.35401745],
                            [39.64376582, -5.35405513],
                            [39.64375744, -5.35407815],
                            [39.64372814, -5.35407396],
                            [39.64370094, -5.3540635],
                            [39.64364443, -5.35406141],
                            [39.64356908, -5.35409699],
                            [39.6435649, -5.35413257],
                            [39.6435356, -5.35419118],
                            [39.6435356, -5.3542142],
                            [39.6435356, -5.35422885],
                            [39.64351258, -5.3543356],
                            [39.64348955, -5.35439212],
                            [39.64349374, -5.35442351],
                            [39.64345816, -5.35450724],
                            [39.6434456, -5.35453236],
                            [39.64340793, -5.35459096],
                            [39.6434163, -5.35466213],
                            [39.64346025, -5.35471655],
                            [39.64342467, -5.35478981],
                            [39.64339956, -5.35480446],
                            [39.64336189, -5.35485679],
                            [39.64337444, -5.35493842],
                            [39.64342886, -5.35496145],
                            [39.64347072, -5.35494679],
                            [39.64351676, -5.35491749],
                            [39.64354606, -5.35491749],
                            [39.6435921, -5.35492168],
                            [39.64363396, -5.35492586],
                            [39.6437114, -5.35493424],
                            [39.64375954, -5.35495517],
                            [39.64377363, -5.35497054],
                            [39.64380558, -5.3550054],
                            [39.64381604, -5.35502005],
                            [39.64376058, -5.35500445],
                            [39.64374907, -5.35500122],
                            [39.64372186, -5.35498447],
                            [39.64365071, -5.35498028],
                            [39.64362768, -5.35500122],
                            [39.6435942, -5.35504099],
                            [39.64357327, -5.3550682],
                            [39.64348955, -5.35503889],
                            [39.64346653, -5.35506401],
                            [39.64344142, -5.35513727],
                            [39.64344979, -5.35516657],
                            [39.64352932, -5.35518541],
                            [39.64352513, -5.35522518],
                            [39.64347909, -5.35529007],
                            [39.64347072, -5.35531937],
                            [39.64345397, -5.35534239],
                            [39.64345245, -5.35533409],
                            [39.643387, -5.35532356],
                            [39.64333049, -5.35535286],
                            [39.64331584, -5.35545124],
                            [39.64332631, -5.35553705],
                            [39.64339537, -5.35550775],
                            [39.64345397, -5.35551612],
                            [39.643477, -5.35555589],
                            [39.64349583, -5.35559357],
                            [39.6435356, -5.35563334],
                            [39.64357985, -5.35562876],
                            [39.64359629, -5.35562706],
                            [39.64366535, -5.35556427],
                            [39.64371977, -5.35550775],
                            [39.64379511, -5.35542193],
                            [39.64379284, -5.35541118],
                            [39.64381604, -5.35540519],
                            [39.64384953, -5.35541147],
                            [39.64391022, -5.35548891],
                            [39.64391232, -5.35553496],
                            [39.64390394, -5.35560613],
                            [39.64387883, -5.35563334],
                            [39.6438286, -5.3556145],
                            [39.64380349, -5.35559148],
                            [39.64374279, -5.35558938],
                            [39.64370931, -5.35561869],
                            [39.64369466, -5.35563125],
                            [39.64366535, -5.35564171],
                            [39.64362768, -5.35571288],
                            [39.64359838, -5.35576939],
                            [39.6436528, -5.35582591],
                            [39.64368838, -5.35586358],
                            [39.64371977, -5.35594312],
                            [39.64374279, -5.35596824],
                            [39.64375744, -5.35597452],
                            [39.64370512, -5.35602266],
                            [39.64369465, -5.35607708],
                            [39.64375953, -5.35612522],
                            [39.64382441, -5.35612522],
                            [39.6438579, -5.35615662],
                            [39.64386836, -5.35618592],
                            [39.64385371, -5.35624872],
                            [39.64387046, -5.35629895],
                            [39.64392487, -5.35632616],
                            [39.64396254, -5.35635128],
                            [39.64397301, -5.35636593],
                            [39.64400022, -5.35644547],
                            [39.64403579, -5.35647268],
                            [39.64406928, -5.35651036],
                            [39.64406719, -5.35652292],
                            [39.64402742, -5.35653129],
                            [39.64399394, -5.35652292],
                            [39.64392278, -5.35648105],
                            [39.64384953, -5.35648315],
                            [39.64380558, -5.35653547],
                            [39.64379302, -5.35666315],
                            [39.64380558, -5.35671967],
                            [39.64383278, -5.35675944],
                            [39.64385371, -5.35677409],
                            [39.64388511, -5.35678665],
                            [39.64391022, -5.35679502],
                            [39.64393743, -5.35680549],
                            [39.64396673, -5.35681177],
                            [39.64398975, -5.35681386],
                            [39.64401905, -5.35681177],
                            [39.64404207, -5.35680549],
                            [39.64407556, -5.35678665],
                            [39.64408812, -5.35678246],
                            [39.64414881, -5.35673223],
                            [39.64417811, -5.35671758],
                            [39.64421369, -5.35673013],
                            [39.64422625, -5.35674269],
                            [39.64423148, -5.3567563],
                            [39.64423671, -5.3567699],
                            [39.64423671, -5.35679293],
                            [39.64423252, -5.35680549],
                            [39.64422834, -5.35681805],
                            [39.6442116, -5.3568306],
                            [39.64413834, -5.35685991],
                            [39.6440923, -5.35688503],
                            [39.64407556, -5.35690386],
                            [39.64407137, -5.35691851],
                            [39.64408812, -5.35694154],
                            [39.64414044, -5.35694991],
                            [39.64418648, -5.35694782],
                            [39.6442409, -5.35694363],
                            [39.64425345, -5.35694154],
                            [39.64425119, -5.35693134],
                            [39.64427438, -5.35691852],
                            [39.64430368, -5.3569227],
                            [39.64430996, -5.35696456],
                            [39.64430787, -5.3569855],
                            [39.6443128, -5.35707422],
                            [39.64431415, -5.35709852],
                            [39.6442995, -5.35714667],
                            [39.64424717, -5.35720737],
                            [39.64420741, -5.35723876],
                            [39.64419694, -5.35731202],
                            [39.64414044, -5.35729946],
                            [39.64407765, -5.35726807],
                            [39.64404416, -5.35731411],
                            [39.64404416, -5.35736854],
                            [39.64407556, -5.35741458],
                            [39.64407556, -5.35746273],
                            [39.64407765, -5.3575025],
                            [39.64407137, -5.35754436],
                            [39.64407137, -5.35758831],
                            [39.64408602, -5.3576239],
                            [39.64407346, -5.35766995],
                            [39.64406091, -5.35774739],
                            [39.64406091, -5.35782065],
                            [39.64406091, -5.3578667],
                            [39.64406091, -5.35790647],
                            [39.6440923, -5.35796298],
                            [39.64401206, -5.35836588],
                            [39.64395501, -5.35865234],
                            [39.64385689, -5.35889291],
                            [39.64378902, -5.35909978],
                            [39.64375719, -5.35919679],
                            [39.64364483, -5.35939938],
                            [39.64365275, -5.35947535],
                            [39.64368123, -5.35956082],
                            [39.64367858, -5.35981979],
                            [39.64367807, -5.35986944],
                            [39.64365275, -5.36005937],
                            [39.64354754, -5.36021904],
                            [39.64352456, -5.36031419],
                            [39.64344525, -5.36044986],
                            [39.64340429, -5.36051994],
                            [39.64337962, -5.36054652],
                            [39.64313842, -5.36080641],
                            [39.64301024, -5.36090295],
                            [39.6428235, -5.36109763],
                            [39.64265733, -5.36130813],
                            [39.64250382, -5.36157719],
                            [39.64241045, -5.36181776],
                            [39.64229651, -5.36197603],
                            [39.64201957, -5.36231631],
                            [39.64176636, -5.36260753],
                            [39.64166033, -5.36271041],
                            [39.64162393, -5.36271832],
                            [39.64159228, -5.36271041],
                            [39.64155905, -5.36268192],
                            [39.64154797, -5.36264393],
                            [39.64154164, -5.36262019],
                            [39.64149891, -5.36260437],
                            [39.64147043, -5.36260437],
                            [39.64144511, -5.36257904],
                            [39.64141979, -5.36251415],
                            [39.64138339, -5.36250307],
                            [39.64134224, -5.36251415],
                            [39.64127758, -5.36258482],
                            [39.64124254, -5.36264235],
                            [39.64121248, -5.36272307],
                            [39.64121881, -5.36281487],
                            [39.6412552, -5.36288609],
                            [39.64131059, -5.36296839],
                            [39.64134224, -5.36302853],
                            [39.6413375, -5.36306493],
                            [39.64129635, -5.36311716],
                            [39.64120773, -5.36312824],
                            [39.64115076, -5.36312191],
                            [39.64107005, -5.36310292],
                            [39.64098617, -5.36310292],
                            [39.64095769, -5.3631045],
                            [39.64091338, -5.36310925],
                            [39.64086748, -5.36312508],
                            [39.64082317, -5.3631409],
                            [39.64077886, -5.36317414],
                            [39.64074246, -5.36319313],
                            [39.64069571, -5.36319993],
                            [39.64065701, -5.36325961],
                            [39.64064276, -5.36331658],
                            [39.64065226, -5.36336723],
                            [39.64068707, -5.36339572],
                            [39.64070132, -5.3634432],
                            [39.64066017, -5.36346536],
                            [39.64060795, -5.36347169],
                            [39.64053832, -5.36352234],
                            [39.64045128, -5.36353183],
                            [39.64040222, -5.36366003],
                            [39.64031518, -5.363736],
                            [39.6402772, -5.3638183],
                            [39.64024814, -5.36383266],
                            [39.64022023, -5.36387528],
                            [39.64021231, -5.36391327],
                            [39.64022814, -5.36393701],
                            [39.64025979, -5.36397499],
                            [39.6402772, -5.36403988],
                            [39.64023605, -5.36407945],
                            [39.64018857, -5.36410161],
                            [39.64013477, -5.36412851],
                            [39.64012686, -5.36417916],
                            [39.64014901, -5.36422981],
                            [39.64019807, -5.36426463],
                            [39.64014901, -5.36428204],
                            [39.64008254, -5.3642947],
                            [39.64002557, -5.36431844],
                            [39.64000184, -5.36434297],
                            [39.6399781, -5.3643675],
                            [39.63997177, -5.36440865],
                            [39.63999709, -5.3644498],
                            [39.64001608, -5.36451786],
                            [39.64000658, -5.36458434],
                            [39.63991638, -5.36466505],
                            [39.63985149, -5.36474419],
                            [39.63984991, -5.36482966],
                            [39.639842, -5.36497368],
                            [39.63985941, -5.36503224],
                            [39.63989422, -5.36508605],
                            [39.6398253, -5.36511336],
                            [39.63976604, -5.36521267],
                            [39.63973122, -5.36531396],
                            [39.63966159, -5.36536778],
                            [39.63958246, -5.36536461],
                            [39.63951283, -5.36537727],
                            [39.63951283, -5.36544375],
                            [39.63953815, -5.36549439],
                            [39.63960462, -5.36549756],
                            [39.6397059, -5.36551972],
                            [39.63972019, -5.36555903],
                            [39.63970274, -5.36558936],
                            [39.63960778, -5.36557353],
                            [39.63949149, -5.36553909],
                            [39.63945513, -5.36559897],
                            [39.63940522, -5.36568115],
                            [39.63938306, -5.36577612],
                            [39.63943687, -5.36586475],
                            [39.63939572, -5.36589007],
                            [39.63934781, -5.36593035],
                            [39.63930394, -5.36600403],
                            [39.63930199, -5.36608591],
                            [39.63928495, -5.36611482],
                            [39.63922856, -5.36612594],
                            [39.6391805, -5.36620661],
                            [39.63920265, -5.36631107],
                            [39.63918683, -5.36638388],
                            [39.63912986, -5.36642819],
                            [39.63907605, -5.36647567],
                            [39.63906023, -5.36656114],
                            [39.63906972, -5.36669725],
                            [39.63908238, -5.36677639],
                            [39.63915068, -5.36680627],
                            [39.63912669, -5.36684919],
                            [39.63907922, -5.36687135],
                            [39.63902541, -5.3669125],
                            [39.63898726, -5.3670703],
                            [39.63898461, -5.36707502],
                            [39.6389178, -5.36719422],
                            [39.63891147, -5.36725437],
                            [39.63892096, -5.36734933],
                            [39.63893995, -5.36739365],
                            [39.63900325, -5.36741264],
                            [39.63901908, -5.36744429],
                            [39.63900958, -5.36752343],
                            [39.638987, -5.36763918],
                            [39.63897793, -5.36765638],
                            [39.63900325, -5.36767537],
                            [39.63904123, -5.36772918],
                            [39.6390656, -5.36779848],
                            [39.6390603, -5.36780885],
                            [39.639055, -5.36781922],
                            [39.6390444, -5.36783997],
                            [39.63902816, -5.36785031],
                            [39.63900958, -5.36786213],
                            [39.63899788, -5.36785394],
                            [39.63897793, -5.36783997],
                            [39.63892729, -5.36779882],
                            [39.63890197, -5.36780515],
                            [39.63884733, -5.36782835],
                            [39.63882601, -5.36786846],
                            [39.63882725, -5.36790678],
                            [39.63883062, -5.36801015],
                            [39.63880702, -5.36805522],
                            [39.63879472, -5.36814543],
                            [39.63875345, -5.36822476],
                            [39.63872473, -5.36827996],
                            [39.63875321, -5.36831795],
                            [39.63881662, -5.36839085],
                            [39.63880385, -5.36841608],
                            [39.63872789, -5.36847939],
                            [39.63872776, -5.36848249],
                            [39.63872266, -5.36860179],
                            [39.63869014, -5.36866623],
                            [39.63866142, -5.36872312],
                            [39.63870498, -5.3687908],
                            [39.63867408, -5.36885291],
                            [39.6385728, -5.36897636],
                            [39.63855381, -5.36909981],
                            [39.63854115, -5.36927707],
                            [39.63855698, -5.36934038],
                            [39.63858546, -5.36935937],
                            [39.63862977, -5.36941319],
                            [39.63864243, -5.36948282],
                            [39.63865509, -5.36959361],
                            [39.63870257, -5.36972656],
                            [39.63869624, -5.3698057],
                            [39.63871523, -5.36990383],
                            [39.63873738, -5.3699798],
                            [39.6387627, -5.37009375],
                            [39.63879752, -5.37019188],
                            [39.6388355, -5.37021087],
                            [39.63889564, -5.37021404],
                            [39.63891779, -5.37018238],
                            [39.63894628, -5.37010641],
                            [39.63897793, -5.37006526],
                            [39.63901591, -5.37012857],
                            [39.63904123, -5.37019505],
                            [39.63907288, -5.37027102],
                            [39.63910137, -5.37026785],
                            [39.63924696, -5.37025202],
                            [39.63934508, -5.37029001],
                            [39.63944003, -5.37033749],
                            [39.63950966, -5.37042929],
                            [39.63955081, -5.37043245],
                            [39.63962044, -5.37040713],
                            [39.63967424, -5.37039447],
                            [39.63972488, -5.37037231],
                            [39.6397597, -5.37033116],
                            [39.63979452, -5.37032799],
                            [39.639823, -5.37034066],
                            [39.63992745, -5.37033116],
                            [39.63997492, -5.37027102],
                            [39.64001924, -5.37023936],
                            [39.64006355, -5.37025202],
                            [39.64008254, -5.37029951],
                            [39.64008254, -5.37030267],
                            [39.64011419, -5.37036914],
                            [39.64017116, -5.37042296],
                            [39.64025978, -5.37040713],
                            [39.64030409, -5.37037864],
                            [39.64035473, -5.37034699],
                            [39.64038005, -5.37038814],
                            [39.64046867, -5.37037231],
                            [39.64056046, -5.37036281],
                            [39.64066807, -5.37038814],
                            [39.64075037, -5.37041663],
                            [39.64077885, -5.37046411],
                            [39.64086431, -5.37053375],
                            [39.64091495, -5.3705749],
                            [39.64096209, -5.37058407],
                            [39.64102889, -5.37059706],
                            [39.64112068, -5.37058756],
                            [39.64115866, -5.37052109],
                            [39.64121246, -5.3704926],
                            [39.64126311, -5.37048943],
                            [39.64134223, -5.3705021],
                            [39.64138971, -5.37047361],
                            [39.64144351, -5.37047994],
                            [39.64145934, -5.3705654],
                            [39.64147833, -5.37066037],
                            [39.64162392, -5.3706477],
                            [39.64165557, -5.37074267],
                            [39.64174736, -5.37079331],
                            [39.64189295, -5.37079965],
                            [39.64197208, -5.37083447],
                            [39.64201956, -5.37081547],
                            [39.64204804, -5.37076483],
                            [39.64209235, -5.37076483],
                            [39.64213033, -5.37080598],
                            [39.64222845, -5.37083763],
                            [39.64231074, -5.37079332],
                            [39.64234872, -5.37074583],
                            [39.6423867, -5.37069202],
                            [39.64235189, -5.37063821],
                            [39.64235822, -5.37059073],
                            [39.64240569, -5.37057807],
                            [39.64246267, -5.37053059],
                            [39.64249432, -5.37055591],
                            [39.64255129, -5.3705844],
                            [39.64261459, -5.37054641],
                            [39.6426589, -5.37050843],
                            [39.64277284, -5.37047361],
                            [39.64277601, -5.37041663],
                            [39.64275069, -5.37029001],
                            [39.64274119, -5.37022671],
                            [39.64270321, -5.37016656],
                            [39.64265257, -5.37011592],
                            [39.64257977, -5.37005894],
                            [39.64251964, -5.37001779],
                            [39.64248799, -5.36999246],
                            [39.64243418, -5.37000196],
                            [39.6424057, -5.3699893],
                            [39.64237721, -5.36993865],
                            [39.64232657, -5.36990383],
                            [39.64236771, -5.36986901],
                            [39.64243102, -5.36983419],
                            [39.64254496, -5.36974556],
                            [39.64264308, -5.36974873],
                            [39.64270321, -5.36979304],
                            [39.64276335, -5.36981204],
                            [39.64278343, -5.36978928],
                            [39.64281082, -5.36975822],
                            [39.64285197, -5.36968542],
                            [39.64291844, -5.36963794],
                            [39.64295642, -5.36959679],
                            [39.64297857, -5.36958096],
                            [39.64301655, -5.36959679],
                            [39.64297286, -5.36965894],
                            [39.64296836, -5.36970586],
                            [39.64296275, -5.36976455],
                            [39.6429944, -5.36979937],
                            [39.64303238, -5.36979621],
                            [39.64305137, -5.36983419],
                            [39.64301022, -5.36996081],
                            [39.64295325, -5.36998297],
                            [39.64290261, -5.36997664],
                            [39.64285874, -5.37001499],
                            [39.64285197, -5.3700811],
                            [39.64289893, -5.37010481],
                            [39.64289628, -5.37013174],
                            [39.64284564, -5.37022354],
                            [39.64287729, -5.37029318],
                            [39.64293426, -5.37036282],
                            [39.64301972, -5.37039764],
                            [39.64309251, -5.37042929],
                            [39.64317164, -5.37042296],
                            [39.6432476, -5.37039764],
                            [39.64331723, -5.37038181],
                            [39.64339953, -5.37041663],
                            [39.64346599, -5.37041663],
                            [39.64351663, -5.37046728],
                            [39.64358626, -5.37042929],
                            [39.6436464, -5.37042613],
                            [39.64366223, -5.37038814],
                            [39.64369388, -5.37042929],
                            [39.64377933, -5.37039764],
                            [39.64381415, -5.37048627],
                            [39.64386479, -5.37051476],
                            [39.64388378, -5.37060656],
                            [39.6439819, -5.3706667],
                            [39.64413699, -5.37068886],
                            [39.64426675, -5.37061605],
                            [39.64435537, -5.37054641],
                            [39.64438703, -5.37045145],
                            [39.64446932, -5.37029318],
                            [39.64447565, -5.37017606],
                            [39.64447248, -5.37003995],
                            [39.64450413, -5.36997031],
                            [39.64459276, -5.36986902],
                            [39.6446434, -5.36976772],
                            [39.64474151, -5.36951132],
                            [39.64482697, -5.36935305],
                            [39.64484913, -5.36923593],
                            [39.64483014, -5.36913464],
                            [39.64477633, -5.36903967],
                            [39.6446972, -5.36896687],
                            [39.64460225, -5.3688909],
                            [39.64450414, -5.36883709],
                            [39.64447565, -5.36877378],
                            [39.64450414, -5.36875479],
                            [39.64456744, -5.36874845],
                            [39.64468138, -5.36867249],
                            [39.64473835, -5.36855853],
                            [39.64482064, -5.36850155],
                            [39.64502637, -5.36839076],
                            [39.64524476, -5.36836544],
                            [39.64540935, -5.36838443],
                            [39.64552329, -5.36833695],
                            [39.6455676, -5.36827997],
                            [39.64563407, -5.36808055],
                            [39.64567205, -5.36792861],
                            [39.64580498, -5.36775768],
                            [39.64592209, -5.36765322],
                            [39.64605185, -5.36755509],
                            [39.64609617, -5.36747912],
                            [39.64623226, -5.367381],
                            [39.64644749, -5.36724488],
                            [39.64655194, -5.3672069],
                            [39.64673234, -5.3671246],
                            [39.64693807, -5.36709294],
                            [39.64706151, -5.3671056],
                            [39.64722784, -5.36709799],
                            [39.64742161, -5.36722131],
                            [39.64750265, -5.36730588],
                            [39.64756959, -5.36733407],
                            [39.64761186, -5.36739045],
                            [39.64762596, -5.36743978],
                            [39.64767176, -5.36744682],
                            [39.64769994, -5.3675032],
                            [39.64779859, -5.36750672],
                            [39.64781973, -5.36758072],
                            [39.64788315, -5.36759481],
                            [39.6479677, -5.36761595],
                            [39.64796418, -5.36765824],
                            [39.64787258, -5.36766528],
                            [39.6478303, -5.36766881],
                            [39.64777393, -5.36771814],
                            [39.6477704, -5.36783089],
                            [39.64777393, -5.36789784],
                            [39.64781444, -5.36796302],
                            [39.64778978, -5.36801059],
                            [39.64778626, -5.36807049],
                            [39.64781621, -5.36812511],
                            [39.64784263, -5.36816563],
                            [39.64789019, -5.36814977],
                            [39.64790252, -5.36812511],
                            [39.64791485, -5.36811982],
                            [39.64797122, -5.36815858],
                            [39.64800998, -5.36819029],
                            [39.64807516, -5.36824667],
                            [39.64811743, -5.36829071],
                            [39.64818437, -5.36832419],
                            [39.64822841, -5.36833828],
                            [39.6482566, -5.36836999],
                            [39.64828302, -5.36842813],
                            [39.64829183, -5.36845984],
                            [39.64837991, -5.36857612],
                            [39.64846094, -5.36863602],
                            [39.64848913, -5.36861488],
                            [39.64853845, -5.36863955],
                            [39.6485913, -5.36860783],
                            [39.64863005, -5.36862897],
                            [39.64865471, -5.36865012],
                            [39.64863005, -5.36866421],
                            [39.6485913, -5.36869592],
                            [39.64856664, -5.36870297],
                            [39.6485455, -5.36873468],
                            [39.64858425, -5.36878401],
                            [39.6485772, -5.36885096],
                            [39.6485772, -5.36889676],
                            [39.64863005, -5.36896371],
                            [39.64865471, -5.36903066],
                            [39.64869347, -5.36908351],
                            [39.64878507, -5.3691117],
                            [39.64885906, -5.36909761],
                            [39.64892071, -5.36904475],
                            [39.6489577, -5.36900952],
                            [39.6489718, -5.36897604],
                            [39.64898237, -5.36897076],
                            [39.64901231, -5.36899366],
                            [39.64907221, -5.36899895],
                            [39.6491092, -5.36900952],
                            [39.6491321, -5.36902714],
                            [39.649155, -5.36905004],
                            [39.64920961, -5.3690518],
                            [39.64924308, -5.3690518],
                            [39.64929593, -5.36906413],
                            [39.64936111, -5.36906766],
                            [39.64943509, -5.36907294],
                            [39.64949499, -5.36905885],
                            [39.6495584, -5.36903418],
                            [39.64957104, -5.36902976],
                            [39.64959363, -5.36902185],
                            [39.64959892, -5.36904299],
                            [39.64957602, -5.36909408],
                            [39.64953903, -5.36912403],
                            [39.64952475, -5.36912641],
                            [39.64949675, -5.36913108],
                            [39.64940691, -5.3691346],
                            [39.6493065, -5.36913637],
                            [39.64922723, -5.3691258],
                            [39.6491092, -5.36910994],
                            [39.64909159, -5.36911522],
                            [39.64905988, -5.36912756],
                            [39.64900703, -5.36913637],
                            [39.64898765, -5.36916632],
                            [39.64894713, -5.36922445],
                            [39.64891895, -5.36923679],
                            [39.64889781, -5.36925441],
                            [39.64891367, -5.36930902],
                            [39.64893304, -5.36937068],
                            [39.64895947, -5.36942001],
                            [39.64900174, -5.36949753],
                            [39.64901231, -5.36951867],
                            [39.64900879, -5.36954157],
                            [39.64902641, -5.36958562],
                            [39.64907925, -5.36965433],
                            [39.64911977, -5.36970894],
                            [39.6492008, -5.36973713],
                            [39.64927303, -5.36977061],
                            [39.64937167, -5.36981994],
                            [39.64939458, -5.36983403],
                            [39.64940514, -5.36987103],
                            [39.6493981, -5.36990979],
                            [39.64935934, -5.36997849],
                            [39.64936463, -5.37005601],
                            [39.64941395, -5.37006658],
                            [39.649421, -5.37011239],
                            [39.64941043, -5.37017581],
                            [39.64947385, -5.370278],
                            [39.64951612, -5.3703379],
                            [39.6497099, -5.37048236],
                            [39.64980677, -5.3705364],
                            [39.6498931, -5.37058455],
                            [39.64993426, -5.37060307],
                            [39.65003403, -5.37064797],
                            [39.65012563, -5.37072901],
                            [39.650182, -5.37079596],
                            [39.65025246, -5.37085938],
                            [39.65022428, -5.37092633],
                            [39.65017495, -5.3710109],
                            [39.65015029, -5.37107432],
                            [39.65010801, -5.37110251],
                            [39.65004812, -5.37125402],
                            [39.64999175, -5.37138439],
                            [39.64990014, -5.37148305],
                            [39.64985787, -5.37154648],
                            [39.64983673, -5.37162399],
                            [39.64986139, -5.37167332],
                            [39.64982616, -5.37174732],
                            [39.64978036, -5.37182836],
                            [39.6497416, -5.37186359],
                            [39.64966762, -5.3718495],
                            [39.64959715, -5.37183541],
                            [39.64953374, -5.3718495],
                            [39.64948089, -5.37188826],
                            [39.64935758, -5.37189178],
                            [39.64929769, -5.37189531],
                            [39.64924836, -5.37188473],
                            [39.64922722, -5.37171913],
                            [39.64917438, -5.37152886],
                            [39.64912696, -5.3714296],
                            [39.64901936, -5.37125402],
                            [39.64892866, -5.37106185],
                            [39.64885729, -5.37099328],
                            [39.64883615, -5.370877],
                            [39.64878331, -5.37082415],
                            [39.6486917, -5.37077129],
                            [39.64861772, -5.37082415],
                            [39.64856135, -5.37086643],
                            [39.64849441, -5.37090519],
                            [39.64844156, -5.37094395],
                            [39.64842042, -5.37100737],
                            [39.64840633, -5.37110251],
                            [39.64839928, -5.37113422],
                            [39.64844156, -5.37119059],
                            [39.64844861, -5.37126107],
                            [39.64852964, -5.3713104],
                            [39.64854373, -5.37136677],
                            [39.64854373, -5.37140553],
                            [39.64852611, -5.37154295],
                            [39.64857896, -5.37169094],
                            [39.64861067, -5.37180722],
                            [39.64861067, -5.37187769],
                            [39.64858249, -5.37196578],
                            [39.64853316, -5.37207148],
                            [39.64849441, -5.37212081],
                            [39.6484627, -5.37215252],
                            [39.64837462, -5.37216662],
                            [39.64829359, -5.37217366],
                            [39.64823369, -5.37218071],
                            [39.64818085, -5.3722089],
                            [39.64813152, -5.37221595],
                            [39.64809981, -5.37224061],
                            [39.6480364, -5.37219481],
                            [39.64798355, -5.37219481],
                            [39.64795184, -5.37215252],
                            [39.6478849, -5.372149],
                            [39.64786024, -5.37216309],
                            [39.64780387, -5.37217014],
                            [39.64778273, -5.37218776],
                            [39.64774045, -5.37221242],
                            [39.64769817, -5.37224766],
                            [39.64766999, -5.3722688],
                            [39.64763476, -5.37227232],
                            [39.64758543, -5.37221242],
                            [39.64753259, -5.37217366],
                            [39.64747974, -5.37220185],
                            [39.64742337, -5.37221595],
                            [39.647367, -5.37228289],
                            [39.64734938, -5.37232165],
                            [39.64729653, -5.37237098],
                            [39.64725426, -5.37236746],
                            [39.6472155, -5.37239917],
                            [39.64718732, -5.37243793],
                            [39.64717675, -5.37251897],
                            [39.64721902, -5.37254364],
                            [39.64725778, -5.37256478],
                            [39.64726482, -5.37261058],
                            [39.64730006, -5.37273743],
                            [39.64732472, -5.37279381],
                            [39.64738109, -5.37288894],
                            [39.6473987, -5.37296999],
                            [39.64749735, -5.37299465],
                            [39.64752906, -5.37295942],
                            [39.64755372, -5.37300874],
                            [39.64756782, -5.3730616],
                            [39.64758895, -5.37311093],
                            [39.64752201, -5.3731215],
                            [39.64744098, -5.37312854],
                            [39.64738461, -5.37313912],
                            [39.64731062, -5.37314616],
                            [39.64727892, -5.37314969],
                            [39.64721902, -5.37314969],
                            [39.64719784, -5.37310091],
                            [39.64718068, -5.37303226],
                            [39.6471916, -5.37301353],
                            [39.64718848, -5.37293864],
                            [39.64720721, -5.37283253],
                            [39.64720409, -5.3727904],
                            [39.64716664, -5.37272487],
                            [39.64714792, -5.37263436],
                            [39.64714636, -5.37258755],
                            [39.64711984, -5.37253138],
                            [39.64709643, -5.37247521],
                            [39.64708239, -5.37242684],
                            [39.64708239, -5.37239251],
                            [39.64708239, -5.37236754],
                            [39.64713388, -5.37232697],
                            [39.64717444, -5.37227392],
                            [39.64724621, -5.37217094],
                            [39.64738351, -5.37213349],
                            [39.64752393, -5.3720898],
                            [39.6476737, -5.3720898],
                            [39.64780476, -5.37209604],
                            [39.64790149, -5.3720898],
                            [39.64796078, -5.37207108],
                            [39.64802006, -5.37205547],
                            [39.64816984, -5.37200242],
                            [39.64823537, -5.37200242],
                            [39.64832274, -5.37198369],
                            [39.64844132, -5.37188383],
                            [39.64841011, -5.37173716],
                            [39.64840075, -5.37167474],
                            [39.64836643, -5.37156864],
                            [39.64833522, -5.37149998],
                            [39.64829466, -5.37135955],
                            [39.64825409, -5.37115046],
                            [39.64825721, -5.37107244],
                            [39.64831026, -5.37095074],
                            [39.64839139, -5.37086023],
                            [39.6484694, -5.3707947],
                            [39.64854741, -5.37074477],
                            [39.64861294, -5.37070108],
                            [39.64873151, -5.37070732],
                            [39.64883136, -5.37072292],
                            [39.64894994, -5.37076973],
                            [39.64904979, -5.3708852],
                            [39.64910908, -5.37098818],
                            [39.64919021, -5.37115358],
                            [39.64924013, -5.37133771],
                            [39.64926509, -5.37143757],
                            [39.6492807, -5.37152183],
                            [39.6493275, -5.37160297],
                            [39.64947104, -5.37167787],
                            [39.64955217, -5.37168411],
                            [39.64962082, -5.3716529],
                            [39.6496489, -5.37163418],
                            [39.64973315, -5.37160921],
                            [39.649833, -5.37151559],
                            [39.64989541, -5.37140324],
                            [39.64991101, -5.3713065],
                            [39.64991725, -5.37122848],
                            [39.64992973, -5.37115046],
                            [39.64994534, -5.37111302],
                            [39.64996718, -5.37100067],
                            [39.64998278, -5.37094138],
                            [39.6499303, -5.37084947],
                            [39.64987262, -5.37079812],
                            [39.64979868, -5.37073229],
                            [39.64963395, -5.37064497],
                            [39.64943672, -5.37047015],
                            [39.64929497, -5.37028597],
                            [39.64922141, -5.37022049],
                            [39.64911886, -5.37016384],
                            [39.64897178, -5.37003324],
                            [39.64885945, -5.36980543],
                            [39.6487908, -5.36970869],
                            [39.64869407, -5.36957762],
                            [39.64864414, -5.36946839],
                            [39.64855677, -5.36933732],
                            [39.64852962, -5.36924832],
                            [39.64850061, -5.36922185],
                            [39.64841636, -5.3690783],
                            [39.64834459, -5.36896283],
                            [39.64826346, -5.36880992],
                            [39.64823155, -5.36873939],
                            [39.64817297, -5.36868509],
                            [39.64810744, -5.36868509],
                            [39.64805127, -5.36870381],
                            [39.64800135, -5.3687163],
                            [39.64798259, -5.3687061],
                            [39.64797326, -5.36869757],
                            [39.64797014, -5.36863204],
                            [39.64793676, -5.3685728],
                            [39.64789601, -5.36853528],
                            [39.64784904, -5.36849204],
                            [39.64780788, -5.36845416],
                            [39.64780164, -5.36845103],
                            [39.64775172, -5.36844479],
                            [39.64773924, -5.36845103],
                            [39.64769243, -5.36846664],
                            [39.64767683, -5.368476],
                            [39.64764875, -5.3684916],
                            [39.64759882, -5.36852905],
                            [39.64753329, -5.36861955],
                            [39.64748649, -5.36870069],
                            [39.64746152, -5.36877871],
                            [39.6474584, -5.3688068],
                            [39.64744592, -5.36888794],
                            [39.6474116, -5.36895659],
                            [39.64738351, -5.36898468],
                            [39.64734295, -5.36898468],
                            [39.64731174, -5.36888794],
                            [39.64732111, -5.36884737],
                            [39.64733983, -5.36877247],
                            [39.64732111, -5.36866948],
                            [39.64728184, -5.36867908],
                            [39.64722125, -5.36862579],
                            [39.64727118, -5.36860083],
                            [39.64733047, -5.3685821],
                            [39.64743032, -5.36857586],
                            [39.64745216, -5.36850097],
                            [39.64743032, -5.36841046],
                            [39.64745041, -5.36829601],
                            [39.6474116, -5.36826067],
                            [39.64733671, -5.36824819],
                            [39.64727742, -5.36826379],
                            [39.64720253, -5.36826691],
                            [39.64720253, -5.36822322],
                            [39.64722125, -5.36815768],
                            [39.64731175, -5.36819201],
                            [39.64736791, -5.36813272],
                            [39.64734607, -5.36804534],
                            [39.64731175, -5.36791115],
                            [39.64729926, -5.36772078],
                            [39.6472899, -5.36761468],
                            [39.64724934, -5.36747425],
                            [39.64726713, -5.36736852],
                            [39.64721466, -5.36731843],
                            [39.64715885, -5.36726516],
                            [39.6470746, -5.36725892],
                            [39.64701843, -5.36726204],
                            [39.6469217, -5.36731509],
                            [39.64686553, -5.36729636],
                            [39.64680625, -5.36733069],
                            [39.64674696, -5.36739935],
                            [39.64667669, -5.36749844],
                            [39.6466583, -5.3674816],
                            [39.64661278, -5.36743992],
                            [39.64654101, -5.36747113],
                            [39.64650669, -5.36756787],
                            [39.64641932, -5.36760532],
                            [39.64630699, -5.36767709],
                            [39.64622586, -5.36771454],
                            [39.64611976, -5.36776135],
                            [39.64605424, -5.3678737],
                            [39.64599183, -5.36799853],
                            [39.6459419, -5.36806718],
                            [39.64592942, -5.36828251],
                            [39.64588885, -5.36833869],
                            [39.64591382, -5.3684167],
                            [39.64592006, -5.3684916],
                            [39.64587463, -5.36850039],
                            [39.64581085, -5.36844791],
                            [39.64573284, -5.36852905],
                            [39.64571099, -5.36870069],
                            [39.64558618, -5.36885673],
                            [39.64556746, -5.36893162],
                            [39.64559242, -5.36902212],
                            [39.64556121, -5.36909078],
                            [39.64552377, -5.36917192],
                            [39.64548633, -5.36925306],
                            [39.64544576, -5.36942158],
                            [39.64540832, -5.36951208],
                            [39.64536151, -5.36961194],
                            [39.64526478, -5.3697118],
                            [39.64521173, -5.36978982],
                            [39.64516181, -5.36986784],
                            [39.64519613, -5.36995522],
                            [39.64527414, -5.37004572],
                            [39.6453147, -5.37009565],
                            [39.64538959, -5.37015495],
                            [39.6454676, -5.37008629],
                            [39.64559242, -5.36999579],
                            [39.64572035, -5.36994898],
                            [39.64576092, -5.36989593],
                            [39.64578276, -5.36980231],
                            [39.64581396, -5.36970556],
                            [39.64585453, -5.36969932],
                            [39.64585453, -5.36982415],
                            [39.64583269, -5.3699365],
                            [39.64586701, -5.37000515],
                            [39.64591382, -5.3701175],
                            [39.64596686, -5.37018615],
                            [39.64602303, -5.3701924],
                            [39.64610728, -5.37015183],
                            [39.64621649, -5.37015183],
                            [39.64623521, -5.37018616],
                            [39.64626018, -5.37027041],
                            [39.64630074, -5.37030786],
                            [39.64636939, -5.37034531],
                            [39.64650045, -5.37039212],
                            [39.64660654, -5.37046702],
                            [39.64654413, -5.37047014],
                            [39.64642244, -5.37049511],
                            [39.6463755, -5.37057726],
                            [39.6463101, -5.37052943],
                            [39.64622348, -5.37042118],
                            [39.64620089, -5.37040461],
                            [39.6461416, -5.37042645],
                            [39.64606359, -5.37045142],
                            [39.64597622, -5.37051383],
                            [39.64593566, -5.37057937],
                            [39.64597622, -5.37066363],
                            [39.64593757, -5.37074698],
                            [39.64588573, -5.37071044],
                            [39.64579524, -5.37067923],
                            [39.64570087, -5.37067987],
                            [39.64562253, -5.37062506],
                            [39.64558127, -5.37059619],
                            [39.64550817, -5.37054504],
                            [39.64546014, -5.37044427],
                            [39.64537711, -5.37038588],
                            [39.6453147, -5.37039212],
                            [39.64521797, -5.37043893],
                            [39.64518989, -5.37045454],
                            [39.64511188, -5.37052007],
                            [39.64500579, -5.37060121],
                            [39.64494962, -5.37060433],
                            [39.64490593, -5.37066987],
                            [39.6449309, -5.37076037],
                            [39.6449153, -5.37083526],
                            [39.64486225, -5.37083838],
                            [39.64473743, -5.37094449],
                            [39.64464694, -5.37108492],
                            [39.6446095, -5.37116918],
                            [39.64455333, -5.37121287],
                            [39.64447668, -5.3712276],
                            [39.64442852, -5.37119727],
                            [39.64438171, -5.37121599],
                            [39.64427005, -5.37131093],
                            [39.64425351, -5.37130068],
                            [39.64422257, -5.37128153],
                            [39.64415392, -5.37128153],
                            [39.64411648, -5.37131585],
                            [39.64405407, -5.37135642],
                            [39.64402287, -5.37136891],
                            [39.64400102, -5.37143756],
                            [39.6439667, -5.37150934],
                            [39.64393862, -5.37158736],
                            [39.64388682, -5.37164164],
                            [39.64379508, -5.37158736],
                            [39.6438294, -5.37153743],
                            [39.6438411, -5.37150231],
                            [39.64379609, -5.37147543],
                            [39.64373267, -5.37143756],
                            [39.64372643, -5.37140948],
                            [39.64371083, -5.37135018],
                            [39.64369835, -5.37130025],
                            [39.64368275, -5.37125344],
                            [39.6436453, -5.37125344],
                            [39.64358601, -5.37126904],
                            [39.64352673, -5.37130649],
                            [39.64347992, -5.37136266],
                            [39.64347368, -5.3714438],
                            [39.64350801, -5.3715031],
                            [39.64356729, -5.37152182],
                            [39.64358601, -5.3715343],
                            [39.64358914, -5.37155615],
                            [39.64360786, -5.37161856],
                            [39.64367963, -5.37163729],
                            [39.64367651, -5.37168722],
                            [39.6436609, -5.37171219],
                            [39.64363282, -5.37174651],
                            [39.64363906, -5.37194936],
                            [39.64372019, -5.37205859],
                            [39.64372955, -5.37211788],
                            [39.64375763, -5.37219278],
                            [39.64376388, -5.37223023],
                            [39.64374203, -5.37229888],
                            [39.64377636, -5.37243307],
                            [39.64382316, -5.37251109],
                            [39.64391053, -5.37266713],
                            [39.64393861, -5.37270146],
                            [39.64397918, -5.37275451],
                            [39.64399166, -5.37280756],
                            [39.64395734, -5.3729043],
                            [39.6439258, -5.37293061],
                            [39.64387621, -5.3729043],
                            [39.64386685, -5.37282628],
                            [39.64381582, -5.37271687],
                            [39.64374807, -5.37268042],
                            [39.64368274, -5.37264528],
                            [39.64368899, -5.37256414],
                            [39.64367026, -5.37249861],
                            [39.64362346, -5.37244243],
                            [39.64356729, -5.37244556],
                            [39.6434879, -5.37246106],
                            [39.64343624, -5.37243307],
                            [39.64341751, -5.3723613],
                            [39.64342999, -5.37230824],
                            [39.64344248, -5.3722708],
                            [39.64343936, -5.37209916],
                            [39.64340815, -5.37203362],
                            [39.64341751, -5.37196184],
                            [39.64342063, -5.37187446],
                            [39.64340191, -5.37183077],
                            [39.64336759, -5.37175588],
                            [39.64333951, -5.3716685],
                            [39.6433083, -5.37156863],
                            [39.64325838, -5.37147501],
                            [39.64319597, -5.37148749],
                            [39.6431086, -5.3715343],
                            [39.64303059, -5.37154367],
                            [39.64299314, -5.37148125],
                            [39.64295882, -5.37140011],
                            [39.64292961, -5.37135838],
                            [39.64287769, -5.37132833],
                            [39.64284025, -5.37133458],
                            [39.64269983, -5.37132833],
                            [39.6426187, -5.3713377],
                            [39.64253548, -5.37134463],
                            [39.64250637, -5.37134706],
                            [39.64234723, -5.37138139],
                            [39.64222553, -5.37136578],
                            [39.64216937, -5.37139387],
                            [39.64210477, -5.3714259],
                            [39.64206951, -5.37140635],
                            [39.64204455, -5.37136266],
                            [39.64197902, -5.37135018],
                            [39.64193846, -5.3713533],
                            [39.64188541, -5.37135642],
                            [39.64185109, -5.37135954],
                            [39.64179492, -5.37135954],
                            [39.641745, -5.37136578],
                            [39.64172003, -5.37137827],
                            [39.64167011, -5.37140635],
                            [39.64162018, -5.37145628],
                            [39.64159522, -5.37149685],
                            [39.64156089, -5.37159047],
                            [39.64156713, -5.37166225],
                            [39.64157649, -5.37175275],
                            [39.6415921, -5.37184013],
                            [39.64158273, -5.37190255],
                            [39.64152657, -5.37194624],
                            [39.64143608, -5.37195872],
                            [39.64143296, -5.37192751],
                            [39.64139239, -5.37186822],
                            [39.64134559, -5.37187758],
                            [39.64130814, -5.37190255],
                            [39.64127694, -5.37194624],
                            [39.64123934, -5.37198386],
                            [39.64121453, -5.3719712],
                            [39.64119546, -5.37198425],
                            [39.64115524, -5.37201177],
                            [39.6410866, -5.37205858],
                            [39.64104915, -5.372121],
                            [39.64107099, -5.3722115],
                            [39.64109284, -5.37229576],
                            [39.64111468, -5.37231448],
                            [39.64120517, -5.3723176],
                            [39.64120205, -5.37235505],
                            [39.64118957, -5.37239562],
                            [39.64115212, -5.37243307],
                            [39.64108659, -5.37242995],
                            [39.64105539, -5.3723925],
                            [39.64102731, -5.37235505],
                            [39.64099582, -5.37231217],
                            [39.64095198, -5.37229066],
                            [39.64093058, -5.37228015],
                            [39.64088065, -5.37229264],
                            [39.64082136, -5.37232696],
                            [39.64074023, -5.37242683],
                            [39.64069655, -5.3724674],
                            [39.64066534, -5.37250797],
                            [39.64067158, -5.3725735],
                            [39.64072151, -5.37260471],
                            [39.64071839, -5.37263279],
                            [39.64063621, -5.37267646],
                            [39.64060294, -5.37266088],
                            [39.64053741, -5.37271081],
                            [39.64049684, -5.37280443],
                            [39.64047188, -5.37287309],
                            [39.64049996, -5.37293238],
                            [39.64051557, -5.37298232],
                            [39.64050932, -5.37306658],
                            [39.64059982, -5.37313211],
                            [39.64067782, -5.3732507],
                            [39.64072151, -5.37329751],
                            [39.64074647, -5.3733568],
                            [39.64080888, -5.3734317],
                            [39.64087129, -5.37352844],
                            [39.64091809, -5.37365015],
                            [39.64091809, -5.37370008],
                            [39.64095866, -5.37376874],
                            [39.64096802, -5.37379995],
                            [39.64099298, -5.37379683],
                            [39.64103979, -5.37377186],
                            [39.64108659, -5.3738374],
                            [39.64108347, -5.3739435],
                            [39.64113964, -5.37403712],
                            [39.64115836, -5.37404336],
                            [39.64121765, -5.37402776],
                            [39.64122701, -5.37409954],
                            [39.6413175, -5.37424309],
                            [39.64132998, -5.37427742],
                            [39.64135806, -5.37432111],
                            [39.64141423, -5.37436168],
                            [39.64140799, -5.37441161],
                            [39.64148912, -5.37454268],
                            [39.6415328, -5.37456453],
                            [39.64157961, -5.37465191],
                            [39.64162954, -5.37474553],
                            [39.64167946, -5.37488284],
                            [39.64167322, -5.37499519],
                            [39.6418074, -5.37518555],
                            [39.64184796, -5.37518555],
                            [39.64186668, -5.37518243],
                            [39.64189477, -5.37521988],
                            [39.64192285, -5.37525108],
                            [39.6419759, -5.37532286],
                            [39.64201958, -5.37535719],
                            [39.64205391, -5.37535719],
                            [39.64209447, -5.37538216],
                            [39.64210383, -5.37550074],
                            [39.64213504, -5.37556316],
                            [39.64216936, -5.37560685],
                            [39.64220992, -5.37560061],
                            [39.64225673, -5.37555067],
                            [39.64234098, -5.37545393],
                            [39.64237842, -5.37546329],
                            [39.64238154, -5.37549138],
                            [39.64236282, -5.37554755],
                            [39.64234098, -5.37559749],
                            [39.64230666, -5.37570047],
                            [39.64231914, -5.37577537],
                            [39.6423597, -5.37585651],
                            [39.64243147, -5.37594077],
                            [39.642497, -5.37605311],
                            [39.64256565, -5.37609992],
                            [39.64263429, -5.37611865],
                            [39.64268422, -5.37611241],
                            [39.64271578, -5.37610869],
                            [39.64273727, -5.37610616],
                            [39.64280903, -5.37606247],
                            [39.6428652, -5.37601254],
                            [39.64291201, -5.37601878],
                            [39.64292761, -5.37604999],
                            [39.64284648, -5.37614673],
                            [39.64282776, -5.37617482],
                            [39.64277783, -5.37625908],
                            [39.64274039, -5.37635894],
                            [39.64274351, -5.37647129],
                            [39.64275599, -5.37657427],
                            [39.64279967, -5.37667726],
                            [39.642834, -5.37678024],
                            [39.6428964, -5.37680209],
                            [39.64297129, -5.37681145],
                            [39.64299938, -5.37683329],
                            [39.64296817, -5.37687074],
                            [39.64289328, -5.37687386],
                            [39.64282464, -5.37689259],
                            [39.64279655, -5.3769394],
                            [39.64278407, -5.37701117],
                            [39.64277471, -5.3771048],
                            [39.64277783, -5.3771953],
                            [39.64276847, -5.37725771],
                            [39.64278407, -5.37732013],
                            [39.64281215, -5.37735445],
                            [39.64284648, -5.37740127],
                            [39.64290264, -5.37741999],
                            [39.64293385, -5.37746056],
                            [39.64293697, -5.37750113],
                            [39.64287144, -5.37752921],
                            [39.64279031, -5.37757291],
                            [39.64275911, -5.37762596],
                            [39.64271542, -5.37765716],
                            [39.64267486, -5.37772582],
                            [39.64267798, -5.37779136],
                            [39.64267798, -5.37789434],
                            [39.6427123, -5.37797548],
                            [39.6427435, -5.37802541],
                            [39.64271996, -5.37804972],
                            [39.64268734, -5.37804101],
                            [39.6427123, -5.37817833],
                            [39.6427123, -5.37828755],
                            [39.64271542, -5.37834372],
                            [39.64265301, -5.37838741],
                            [39.64266549, -5.37852161],
                            [39.64262805, -5.37870885],
                            [39.64263117, -5.37876814],
                            [39.64264053, -5.37884616],
                            [39.64262493, -5.37892106],
                            [39.642575, -5.37899908],
                            [39.64255628, -5.37910518],
                            [39.64253444, -5.37915199],
                            [39.6425282, -5.37921129],
                            [39.64252508, -5.37925498],
                            [39.64250323, -5.37930179],
                            [39.64247515, -5.37936732],
                            [39.64245019, -5.37942037],
                            [39.64244395, -5.37948903],
                            [39.64243146, -5.37960138],
                            [39.64235746, -5.37965058],
                            [39.64232849, -5.37964507],
                            [39.64230041, -5.37967003],
                            [39.64226296, -5.37973245],
                            [39.6422091, -5.37978061],
                            [39.64215063, -5.37976989],
                            [39.64211319, -5.37981358],
                            [39.64209446, -5.37985103],
                            [39.64210383, -5.37992281],
                            [39.64211943, -5.37997898],
                            [39.64208946, -5.38001488],
                            [39.64205811, -5.3800095],
                            [39.64202582, -5.38000395],
                            [39.64197901, -5.38002267],
                            [39.64192596, -5.38008509],
                            [39.64187292, -5.38012878],
                            [39.64183235, -5.38019743],
                            [39.64182923, -5.38025361],
                            [39.64182923, -5.38032538],
                            [39.64177931, -5.38036595],
                            [39.64175278, -5.38045801],
                            [39.64172626, -5.38055008],
                            [39.64166385, -5.38070923],
                            [39.64166697, -5.38084654],
                            [39.64165449, -5.38097449],
                            [39.64166309, -5.38105767],
                            [39.64166385, -5.38106499],
                            [39.64165449, -5.38113677],
                            [39.64163265, -5.38122727],
                            [39.64162641, -5.3813365],
                            [39.64166073, -5.38142076],
                            [39.64165449, -5.3815331],
                            [39.64162641, -5.38163921],
                            [39.64163265, -5.38172659],
                            [39.64166385, -5.3817734],
                            [39.64166385, -5.38182957],
                            [39.64164863, -5.38186263],
                            [39.64159208, -5.38185766],
                            [39.64155776, -5.38180461],
                            [39.64153749, -5.38182826],
                            [39.64151719, -5.38182645],
                            [39.64149847, -5.38186078],
                            [39.64147975, -5.38191071],
                            [39.64144542, -5.38196376],
                            [39.64140873, -5.38192674],
                            [39.64134236, -5.38192115],
                            [39.64129253, -5.38191695],
                            [39.64127692, -5.38195752],
                            [39.64123012, -5.38204802],
                            [39.64118019, -5.38209483],
                            [39.64114587, -5.38214164],
                            [39.64110412, -5.38216727],
                            [39.64107906, -5.38216544],
                            [39.64105226, -5.38216349],
                            [39.64102417, -5.38221966],
                            [39.64096489, -5.38226959],
                            [39.6409212, -5.38230704],
                            [39.64091184, -5.38238506],
                            [39.6409056, -5.38247556],
                            [39.6409056, -5.38258167],
                            [39.64089624, -5.38267529],
                            [39.64084319, -5.38272522],
                            [39.64070901, -5.38274706],
                            [39.64063412, -5.38276267],
                            [39.64057172, -5.38280324],
                            [39.64054675, -5.38293743],
                            [39.64049683, -5.38303729],
                            [39.64042506, -5.38307786],
                            [39.64036889, -5.3830997],
                            [39.64034081, -5.38314652],
                            [39.64029088, -5.38322765],
                            [39.64029674, -5.3833127],
                            [39.6402628, -5.38331191],
                            [39.64024096, -5.38336185],
                            [39.64021911, -5.38347107],
                            [39.64016919, -5.38356781],
                            [39.64015047, -5.38368016],
                            [39.64018479, -5.38382995],
                            [39.64018167, -5.3839579],
                            [39.64021287, -5.38406401],
                            [39.64019415, -5.38415763],
                            [39.64020663, -5.38423565],
                            [39.64023472, -5.38429806],
                            [39.64031585, -5.38431991],
                            [39.6404781, -5.38435112],
                            [39.6406154, -5.38431055],
                            [39.64068093, -5.38423877],
                            [39.64077142, -5.3841233],
                            [39.64086503, -5.38407025],
                            [39.64101481, -5.3840328],
                            [39.64108034, -5.38399223],
                            [39.64116459, -5.38402656],
                            [39.64123636, -5.38404841],
                            [39.64130188, -5.38404529],
                            [39.64137989, -5.38399848],
                            [39.64143918, -5.38397663],
                            [39.64150159, -5.38397975],
                            [39.64155463, -5.3840172],
                            [39.641642, -5.38398599],
                            [39.64172937, -5.38387677],
                            [39.64183235, -5.38381123],
                            [39.64198213, -5.38375194],
                            [39.64210382, -5.3837613],
                            [39.64222863, -5.38381747],
                            [39.64236905, -5.3838362],
                            [39.64246578, -5.38383932],
                            [39.64260308, -5.38386116],
                            [39.64272165, -5.38387677],
                            [39.64284023, -5.38398911],
                            [39.64300873, -5.38416388],
                            [39.64301809, -5.38421693],
                            [39.64305865, -5.38428558],
                            [39.64306489, -5.38430431],
                            [39.64307425, -5.384348],
                            [39.64313978, -5.38442602],
                            [39.64321467, -5.38448219],
                            [39.64326772, -5.38456021],
                            [39.64324275, -5.38461326],
                            [39.64320219, -5.38466943],
                            [39.64318035, -5.3846944],
                            [39.64314602, -5.38474745],
                            [39.64308049, -5.38486292],
                            [39.6431117, -5.38490037],
                            [39.64313666, -5.38492221],
                            [39.64316787, -5.38494406],
                            [39.64317723, -5.3850564],
                            [39.64316474, -5.38510009],
                            [39.64313354, -5.38515003],
                            [39.64315538, -5.38523741],
                            [39.64315538, -5.3852811],
                            [39.64318347, -5.38530606],
                            [39.64317099, -5.38535287],
                            [39.6431429, -5.38539344],
                            [39.64311794, -5.38542153],
                            [39.64308049, -5.38544337],
                            [39.64301809, -5.38547146],
                            [39.64294944, -5.38558068],
                            [39.6429432, -5.38593645],
                            [39.64303057, -5.38608624],
                            [39.64320531, -5.38643888],
                            [39.64320219, -5.38650754],
                            [39.64332388, -5.38668542],
                            [39.64348926, -5.38704118],
                            [39.64356415, -5.38724403],
                            [39.64367648, -5.38740319],
                            [39.64373265, -5.38740943],
                            [39.64375137, -5.38751554],
                            [39.64377009, -5.38762164],
                            [39.64376385, -5.38774647],
                            [39.6436952, -5.387812],
                            [39.64362968, -5.38787442],
                            [39.64361719, -5.38804294],
                            [39.6437229, -5.38831779],
                            [39.64375761, -5.38840806],
                            [39.64387973, -5.38843275],
                            [39.64405093, -5.38846736],
                            [39.64411164, -5.3884702],
                            [39.64401816, -5.38849544],
                            [39.64399061, -5.38851402],
                            [39.64388399, -5.38858594],
                            [39.6438403, -5.38861715],
                            [39.64370612, -5.38865148],
                            [39.64360627, -5.38865772],
                            [39.64343716, -5.38871212],
                            [39.64334728, -5.3886546],
                            [39.64343777, -5.38840494],
                            [39.64337224, -5.38830196],
                            [39.64333792, -5.38817401],
                            [39.64341785, -5.38800747],
                            [39.64345025, -5.38793995],
                            [39.64351266, -5.38787754],
                            [39.64358443, -5.38779016],
                            [39.64361251, -5.38771838],
                            [39.64360939, -5.38764661],
                            [39.64357819, -5.38753114],
                            [39.64352514, -5.38739071],
                            [39.64343153, -5.38726276],
                            [39.64330048, -5.38707239],
                            [39.64320375, -5.38692884],
                            [39.64307269, -5.38667606],
                            [39.6430134, -5.38652626],
                            [39.64290731, -5.38649194],
                            [39.64285427, -5.3863203],
                            [39.64279498, -5.38615178],
                            [39.64269825, -5.38612369],
                            [39.64260118, -5.38617398],
                            [39.64256095, -5.38613617],
                            [39.64257967, -5.38610185],
                            [39.6426296, -5.38601447],
                            [39.64269513, -5.38566182],
                            [39.64272945, -5.38534663],
                            [39.64276378, -5.38508761],
                            [39.64277002, -5.38490973],
                            [39.64275416, -5.38466855],
                            [39.64267641, -5.38457581],
                            [39.64266717, -5.38452473],
                            [39.64262648, -5.38447595],
                            [39.64258009, -5.38437627],
                            [39.64252039, -5.38430431],
                            [39.64245798, -5.38429495],
                            [39.64239557, -5.38432303],
                            [39.64234877, -5.3843636],
                            [39.64230196, -5.38433864],
                            [39.64233005, -5.38426998],
                            [39.64224892, -5.38426686],
                            [39.64212722, -5.38428246],
                            [39.64204297, -5.38430431],
                            [39.64195872, -5.3842887],
                            [39.64187135, -5.38429182],
                            [39.64179334, -5.38433239],
                            [39.64176526, -5.38436672],
                            [39.64173093, -5.38442914],
                            [39.64167165, -5.38448219],
                            [39.64160184, -5.38445522],
                            [39.64154683, -5.38439793],
                            [39.64150315, -5.38442914],
                            [39.64141266, -5.38453212],
                            [39.64133777, -5.3846195],
                            [39.64120983, -5.38467255],
                            [39.64106317, -5.38479114],
                            [39.64120359, -5.38519371],
                            [39.64123791, -5.38529358],
                            [39.64121607, -5.38535599],
                            [39.64123373, -5.38546311],
                            [39.64126288, -5.38563998],
                            [39.64130344, -5.38568991],
                            [39.64140641, -5.38568367],
                            [39.64150627, -5.38575232],
                            [39.64152187, -5.38585219],
                            [39.64146258, -5.38592396],
                            [39.64135337, -5.38599574],
                            [39.6412816, -5.38607688],
                            [39.64134713, -5.3861549],
                            [39.64138769, -5.38616426],
                            [39.64143138, -5.38621107],
                            [39.64139393, -5.38625788],
                            [39.64130032, -5.38632341],
                            [39.64119423, -5.38641392],
                            [39.64115366, -5.386442],
                            [39.64108523, -5.38649124],
                            [39.64104757, -5.38646385],
                            [39.64095084, -5.38642016],
                            [39.64085411, -5.3865169],
                            [39.64076674, -5.38652002],
                            [39.64057015, -5.38654499],
                            [39.64040789, -5.38656995],
                            [39.64024875, -5.38656995],
                            [39.64009586, -5.38657307],
                            [39.63998976, -5.38658243],
                            [39.63986495, -5.38662612],
                            [39.63976198, -5.38676031],
                            [39.6397963, -5.3868789],
                            [39.63983998, -5.38700997],
                            [39.6397807, -5.38708175],
                            [39.63973701, -5.38701933],
                            [39.63967773, -5.38682897],
                            [39.63955915, -5.38678528],
                            [39.63941561, -5.38675095],
                            [39.63931264, -5.38677904],
                            [39.63919463, -5.38688236],
                            [39.63910358, -5.38683209],
                            [39.6389538, -5.3867884],
                            [39.63889139, -5.38685706],
                            [39.63887891, -5.38701309],
                            [39.63877666, -5.38702105],
                            [39.63860432, -5.38693195],
                            [39.63848262, -5.3869694],
                            [39.63849198, -5.38710359],
                            [39.63846044, -5.38717167],
                            [39.6384327, -5.38723154],
                            [39.63837653, -5.38716289],
                            [39.63835781, -5.38712544],
                            [39.63818931, -5.38710983],
                            [39.63799584, -5.38725339],
                            [39.63782494, -5.38737971],
                            [39.63770877, -5.38732828],
                            [39.63760736, -5.38737665],
                            [39.63750594, -5.38742503],
                            [39.63732496, -5.38761851],
                            [39.63726568, -5.38777455],
                            [39.63717476, -5.387887],
                            [39.6371471, -5.38792122],
                            [39.63704413, -5.38809598],
                            [39.63700356, -5.38827386],
                            [39.63705349, -5.38831131],
                            [39.63715022, -5.3883706],
                            [39.63715646, -5.38852352],
                            [39.63725319, -5.38867331],
                            [39.63727191, -5.38880126],
                            [39.63722667, -5.38882467],
                            [39.63720405, -5.38883637],
                            [39.63718142, -5.38884808],
                            [39.63711278, -5.3887763],
                            [39.63708469, -5.38868268],
                            [39.63701158, -5.388558],
                            [39.63685005, -5.38850675],
                            [39.63672585, -5.38846735],
                            [39.63649494, -5.38852976],
                            [39.63632956, -5.38858593],
                            [39.63620163, -5.38870764],
                            [39.63602064, -5.38880438],
                            [39.63590207, -5.38888864],
                            [39.63583654, -5.38899163],
                            [39.63589271, -5.38908837],
                            [39.63592391, -5.38911958],
                            [39.63595824, -5.38916951],
                            [39.63591541, -5.38923196],
                            [39.63581158, -5.38920696],
                            [39.63574917, -5.38910397],
                            [39.63564308, -5.38917887],
                            [39.63562748, -5.38933179],
                            [39.63561812, -5.38946598],
                            [39.63559627, -5.38955648],
                            [39.63554947, -5.38958144],
                            [39.63549642, -5.38952215],
                            [39.63545586, -5.38941917],
                            [39.63537161, -5.38935051],
                            [39.63531544, -5.38944725],
                            [39.63516566, -5.38951591],
                            [39.63508765, -5.38958768],
                            [39.6350034, -5.38971563],
                            [39.63486611, -5.38973124],
                            [39.63478186, -5.38979677],
                            [39.63476937, -5.38992784],
                            [39.63481618, -5.39008076],
                            [39.63493163, -5.39026176],
                            [39.63494411, -5.39036162],
                            [39.63484738, -5.39038035],
                            [39.63474129, -5.3902836],
                            [39.63462896, -5.39021183],
                            [39.6345291, -5.3902524],
                            [39.63445109, -5.39029921],
                            [39.63438869, -5.39038971],
                            [39.63436372, -5.39059256],
                            [39.63438557, -5.39070802],
                            [39.63432628, -5.3907642],
                            [39.63426699, -5.3906144],
                            [39.6341186, -5.39057222],
                            [39.63392999, -5.39054887],
                            [39.63381454, -5.39060504],
                            [39.63374589, -5.39065809],
                            [39.63363668, -5.39070802],
                            [39.63356179, -5.39074235],
                            [39.63341825, -5.39081101],
                            [39.63334648, -5.39085782],
                            [39.63327159, -5.39089214],
                            [39.63327471, -5.39097952],
                            [39.63323415, -5.39107315],
                            [39.63316238, -5.39111372],
                            [39.63313118, -5.39114804],
                            [39.63315614, -5.39127287],
                            [39.63308749, -5.39132592],
                            [39.63299784, -5.39135098],
                            [39.63295019, -5.39134777],
                            [39.63289715, -5.39128535],
                            [39.63281914, -5.3913072],
                            [39.63274362, -5.39133531],
                            [39.63265064, -5.39132904],
                            [39.6325071, -5.39136337],
                            [39.63247902, -5.3913977],
                            [39.63241037, -5.39149756],
                            [39.63242597, -5.3915787],
                            [39.63239477, -5.39164112],
                            [39.63220122, -5.39173683],
                            [39.63212685, -5.39173373],
                            [39.63206143, -5.391731],
                            [39.63200212, -5.39172852],
                            [39.63192671, -5.39172538],
                            [39.63183305, -5.39167971],
                            [39.63173637, -5.39167544],
                            [39.63169892, -5.39170353],
                            [39.63167084, -5.39176907],
                            [39.63170828, -5.39188453],
                            [39.63165836, -5.39201248],
                            [39.63155539, -5.39225902],
                            [39.6315273, -5.39244314],
                            [39.63162091, -5.39258045],
                            [39.63167708, -5.39257733],
                            [39.63175197, -5.39264911],
                            [39.63172076, -5.3927677],
                            [39.63168644, -5.39283947],
                            [39.63159907, -5.39290813],
                            [39.63153978, -5.39301735],
                            [39.63153666, -5.39318587],
                            [39.63163963, -5.39319836],
                            [39.63173013, -5.39324517],
                            [39.63169892, -5.39330758],
                            [39.63161779, -5.39334503],
                            [39.63154602, -5.39349171],
                            [39.63156474, -5.39361653],
                            [39.63155538, -5.39367583],
                            [39.63147425, -5.39377257],
                            [39.63140248, -5.3941689],
                            [39.63142121, -5.39423444],
                            [39.63144617, -5.39428437],
                            [39.63143993, -5.39435302],
                            [39.63147113, -5.39443104],
                            [39.6314961, -5.39449346],
                            [39.63147737, -5.39459332],
                            [39.63146273, -5.39465724],
                            [39.63139936, -5.3946651],
                            [39.63132447, -5.3946058],
                            [39.63127143, -5.39457772],
                            [39.63122774, -5.39462453],
                            [39.63113413, -5.3946963],
                            [39.6310218, -5.39485546],
                            [39.63094379, -5.394921],
                            [39.6309001, -5.39500838],
                            [39.63075033, -5.3951769],
                            [39.63070976, -5.39534541],
                            [39.63075344, -5.39547024],
                            [39.63073784, -5.39553578],
                            [39.63071189, -5.39554663],
                            [39.63065957, -5.39556852],
                            [39.63057558, -5.39558259],
                            [39.63052254, -5.3957199],
                            [39.63055062, -5.39587906],
                            [39.63057246, -5.39592275],
                            [39.63059431, -5.39596644],
                            [39.63067543, -5.39602261],
                            [39.63082521, -5.39610687],
                            [39.63089393, -5.3961756],
                            [39.63090946, -5.39619113],
                            [39.63099995, -5.39629412],
                            [39.63111853, -5.39642831],
                            [39.63124646, -5.39647512],
                            [39.63126518, -5.39655938],
                            [39.63121214, -5.39665924],
                            [39.63109668, -5.39661867],
                            [39.63107484, -5.39652817],
                            [39.63096624, -5.39642543],
                            [39.63095939, -5.39641894],
                            [39.63087826, -5.39630972],
                            [39.63079089, -5.39625667],
                            [39.63072224, -5.39620986],
                            [39.63063893, -5.39619276],
                            [39.63056934, -5.39620673],
                            [39.63052254, -5.39626291],
                            [39.63051942, -5.39632844],
                            [39.63051876, -5.39645856],
                            [39.63048509, -5.39646575],
                            [39.63044141, -5.39651569],
                            [39.63038212, -5.39662803],
                            [39.63034467, -5.39684024],
                            [39.63034779, -5.3969089],
                            [39.63039772, -5.39695571],
                            [39.630379, -5.39702748],
                            [39.63033531, -5.39706181],
                            [39.63032283, -5.397196],
                            [39.63029787, -5.39723657],
                            [39.6302573, -5.39730523],
                            [39.63020114, -5.39738637],
                            [39.63025106, -5.39748311],
                            [39.63031035, -5.39750808],
                            [39.63033276, -5.39757478],
                            [39.63024482, -5.39759858],
                            [39.63023858, -5.39768284],
                            [39.63031347, -5.3976766],
                            [39.63032916, -5.39772556],
                            [39.63030082, -5.39773345],
                            [39.63021362, -5.39775773],
                            [39.63016993, -5.39782951],
                            [39.63018553, -5.39790129],
                            [39.63023234, -5.39796682],
                            [39.63030411, -5.39797931],
                            [39.63044452, -5.39793562],
                            [39.63053189, -5.3979481],
                            [39.63065671, -5.39804172],
                            [39.63072224, -5.39810101],
                            [39.63077216, -5.39833195],
                            [39.63073784, -5.39842869],
                            [39.63064423, -5.39854416],
                            [39.63059742, -5.39862218],
                            [39.63060366, -5.39875637],
                            [39.63062238, -5.3988063],
                            [39.63067231, -5.39887807],
                            [39.63061614, -5.39887183],
                            [39.63054438, -5.39883126],
                            [39.63039772, -5.39879381],
                            [39.63029475, -5.39887807],
                            [39.63012, -5.39905283],
                            [39.62997023, -5.39922135],
                            [39.6299515, -5.39933994],
                            [39.62994838, -5.3994242],
                            [39.62994214, -5.39955527],
                            [39.62997959, -5.39963017],
                            [39.63008256, -5.39968322],
                            [39.63014809, -5.39970195],
                            [39.63029162, -5.39972067],
                            [39.63034779, -5.39968634],
                            [39.6304258, -5.39959896],
                            [39.63048821, -5.39954591],
                            [39.63055374, -5.3994991],
                            [39.63065047, -5.39947725],
                            [39.63070836, -5.39953052],
                            [39.63065983, -5.39954903],
                            [39.63056622, -5.39967386],
                            [39.63054749, -5.39974876],
                            [39.63055686, -5.39988295],
                            [39.6306099, -5.39998593],
                            [39.63069109, -5.40003131],
                            [39.63071599, -5.40004523],
                            [39.6307784, -5.4001014],
                            [39.63080648, -5.40015445],
                            [39.63081273, -5.40023871],
                            [39.63085485, -5.40026368],
                            [39.63089698, -5.40028864],
                            [39.63088137, -5.40035106],
                            [39.63084081, -5.4004322],
                            [39.63079712, -5.40052894],
                            [39.63072751, -5.40065063],
                            [39.63072629, -5.40065276],
                            [39.63068167, -5.40067249],
                            [39.63063486, -5.40058823],
                            [39.63063486, -5.40054766],
                            [39.63063798, -5.40052582],
                            [39.63065359, -5.4004478],
                            [39.63065983, -5.4003729],
                            [39.63065983, -5.40026368],
                            [39.6305787, -5.40023247],
                            [39.63052253, -5.40027928],
                            [39.63044452, -5.40036042],
                            [39.63034155, -5.40036042],
                            [39.63023546, -5.40027304],
                            [39.63017617, -5.40019502],
                            [39.63009816, -5.40007019],
                            [39.63001391, -5.40000778],
                            [39.62992219, -5.39996897],
                            [39.62983093, -5.40000442],
                            [39.62975804, -5.40003274],
                            [39.62970499, -5.40009828],
                            [39.62972996, -5.40019502],
                            [39.62969687, -5.40023094],
                            [39.6296145, -5.40026367],
                            [39.6295989, -5.40065689],
                            [39.62963634, -5.40079264],
                            [39.62967379, -5.40092839],
                            [39.62966443, -5.40104386],
                            [39.62957082, -5.40118429],
                            [39.62965506, -5.40136529],
                            [39.62967067, -5.40154005],
                            [39.62963322, -5.40165552],
                            [39.62965506, -5.4019395],
                            [39.62962074, -5.40207682],
                            [39.62965818, -5.40222349],
                            [39.62970499, -5.40233896],
                            [39.62965194, -5.40240137],
                            [39.62957705, -5.4026011],
                            [39.62952401, -5.40286324],
                            [39.62954897, -5.40298807],
                            [39.6294928, -5.40305984],
                            [39.62948344, -5.40317531],
                            [39.62951465, -5.40320184],
                            [39.62954585, -5.40322836],
                            [39.62953649, -5.40330014],
                            [39.62948344, -5.40343433],
                            [39.6295084, -5.40370895],
                            [39.62955521, -5.40395237],
                            [39.62958641, -5.40413649],
                            [39.62961138, -5.40424572],
                            [39.62969563, -5.40428005],
                            [39.62971123, -5.40437055],
                            [39.62975491, -5.40453595],
                            [39.62978816, -5.40472075],
                            [39.62972204, -5.40476239],
                            [39.62962074, -5.40482617],
                            [39.62956769, -5.40492292],
                            [39.62965194, -5.40507895],
                            [39.6296769, -5.40513825],
                            [39.62967378, -5.40523187],
                            [39.62965506, -5.40534421],
                            [39.62959577, -5.40542847],
                            [39.62957393, -5.40551898],
                            [39.62963946, -5.40556891],
                            [39.62970811, -5.40564068],
                            [39.62977363, -5.40565629],
                            [39.62985788, -5.40569686],
                            [39.62984454, -5.40578272],
                            [39.62974753, -5.40584928],
                            [39.62968314, -5.40589346],
                            [39.62967378, -5.40600893],
                            [39.62966754, -5.40611191],
                            [39.62967378, -5.40621178],
                            [39.62972683, -5.40623362],
                            [39.6297986, -5.40623362],
                            [39.62984228, -5.40628667],
                            [39.62983604, -5.40633661],
                            [39.62972059, -5.40636469],
                            [39.62970186, -5.40647392],
                            [39.62968314, -5.40652073],
                            [39.6297081, -5.40663307],
                            [39.62968626, -5.40668301],
                            [39.62965818, -5.4067579],
                            [39.6296769, -5.40688585],
                            [39.62974243, -5.40691082],
                            [39.62985164, -5.40687649],
                            [39.62988284, -5.40694827],
                            [39.62993591, -5.40707202],
                            [39.62988908, -5.40710742],
                            [39.6298142, -5.4071043],
                            [39.62974867, -5.4071043],
                            [39.6297081, -5.4071948],
                            [39.62968002, -5.40738517],
                            [39.62975803, -5.40774093],
                            [39.62982356, -5.40796874],
                            [39.62987972, -5.4083089],
                            [39.62998581, -5.40840253],
                            [39.63012623, -5.40858353],
                            [39.63031345, -5.40895801],
                            [39.63033529, -5.40914838],
                            [39.63038522, -5.40936683],
                            [39.63050379, -5.40946357],
                            [39.63051628, -5.40957592],
                            [39.63057868, -5.40980685],
                            [39.63063485, -5.40994728],
                            [39.63069726, -5.41005339],
                            [39.63074406, -5.41015637],
                            [39.63085015, -5.41033738],
                            [39.63090219, -5.41039875],
                            [39.6308564, -5.41044348],
                            [39.63087824, -5.41055583],
                            [39.63102802, -5.41072435],
                            [39.63109354, -5.41075555],
                            [39.63111539, -5.41075555],
                            [39.63116843, -5.41074619],
                            [39.63126828, -5.41070562],
                            [39.63131509, -5.41064945],
                            [39.63130885, -5.41054646],
                            [39.63130261, -5.41037483],
                            [39.63134317, -5.41021255],
                            [39.6313931, -5.41010644],
                            [39.63143054, -5.41005339],
                            [39.63149295, -5.40998473],
                            [39.63159592, -5.40994417],
                            [39.6317457, -5.40983806],
                            [39.63180962, -5.40980734],
                            [39.63180813, -5.40981048],
                            [39.63180663, -5.40981362],
                            [39.63180363, -5.40981989],
                            [39.63177072, -5.40988878],
                            [39.63171656, -5.40996327],
                            [39.63179224, -5.41007375],
                            [39.63177302, -5.41008622],
                            [39.63175379, -5.41009869],
                            [39.63172333, -5.41008514],
                            [39.6316329, -5.4100968],
                            [39.63158792, -5.41012577],
                            [39.63158792, -5.41023749],
                            [39.63163193, -5.41036276],
                            [39.63164887, -5.4104745],
                            [39.63160823, -5.41050156],
                            [39.63155745, -5.41058959],
                            [39.63155068, -5.41066745],
                            [39.631571, -5.41078595],
                            [39.63163531, -5.41092137],
                            [39.6316827, -5.41102294],
                            [39.63175379, -5.41107033],
                            [39.63182488, -5.41114481],
                            [39.63181475, -5.41124775],
                            [39.63174181, -5.41130004],
                            [39.63166249, -5.4113569],
                            [39.63159469, -5.4114055],
                            [39.63149314, -5.41141904],
                            [39.63138621, -5.41143856],
                            [39.63133742, -5.41147321],
                            [39.63121883, -5.41147926],
                            [39.63114108, -5.41153415],
                            [39.63114108, -5.41158493],
                            [39.63115801, -5.41165941],
                            [39.63119524, -5.4117102],
                            [39.63123248, -5.4117102],
                            [39.63127649, -5.41168988],
                            [39.63127987, -5.41171697],
                            [39.63126972, -5.41177452],
                            [39.63124263, -5.41182192],
                            [39.63122232, -5.41190994],
                            [39.63125956, -5.4121774],
                            [39.63138819, -5.41229251],
                            [39.63143897, -5.41231621],
                            [39.63149652, -5.41231282],
                            [39.63154053, -5.41232636],
                            [39.63152699, -5.41237037],
                            [39.63145625, -5.41235756],
                            [39.63140851, -5.41239407],
                            [39.63135434, -5.41251934],
                            [39.63126633, -5.41266153],
                            [39.63128325, -5.41281726],
                            [39.63129002, -5.41295607],
                            [39.63134419, -5.4131592],
                            [39.63136111, -5.41329462],
                            [39.63147621, -5.41342666],
                            [39.63155068, -5.41341989],
                            [39.63157099, -5.41347744],
                            [39.63157438, -5.41360609],
                            [39.63169286, -5.41372459],
                            [39.63169624, -5.41387016],
                            [39.6317064, -5.41413762],
                            [39.63177748, -5.41427304],
                            [39.63177748, -5.41441862],
                            [39.63180456, -5.41461498],
                            [39.63185873, -5.41475379],
                            [39.63197721, -5.41483842],
                            [39.63199256, -5.41490853],
                            [39.63192643, -5.41497046],
                            [39.63197382, -5.41508218],
                            [39.63213292, -5.41520745],
                            [39.6322751, -5.415265],
                            [39.6324105, -5.41529886],
                            [39.63251883, -5.41526162],
                            [39.6325933, -5.41521083],
                            [39.63264069, -5.41525485],
                            [39.63270501, -5.4152887],
                            [39.63280657, -5.4153124],
                            [39.63286073, -5.41527177],
                            [39.63291151, -5.41519729],
                            [39.63294874, -5.41522776],
                            [39.63292166, -5.41531917],
                            [39.63291405, -5.41547062],
                            [39.63284042, -5.415546],
                            [39.63290135, -5.41557647],
                            [39.63316201, -5.41562725],
                            [39.63334142, -5.41570512],
                            [39.63348021, -5.41578976],
                            [39.63361223, -5.41590487],
                            [39.63370363, -5.41607753],
                            [39.63371717, -5.41624003],
                            [39.63359307, -5.41631216],
                            [39.6335276, -5.41638561],
                            [39.63365285, -5.4165515],
                            [39.63370363, -5.41672417],
                            [39.63373409, -5.41681557],
                            [39.63382549, -5.41679188],
                            [39.63387966, -5.41681219],
                            [39.63387627, -5.41686974],
                            [39.63392028, -5.4169273],
                            [39.63398459, -5.41694761],
                            [39.63404553, -5.41693068],
                            [39.63412677, -5.41687651],
                            [39.63414343, -5.41687985],
                            [39.63419447, -5.41689006],
                            [39.63420124, -5.41692391],
                            [39.63417078, -5.41700855],
                            [39.63416739, -5.41715413],
                            [39.63431972, -5.41731325],
                            [39.63439758, -5.41736742],
                            [39.63439758, -5.41752315],
                            [39.63447206, -5.4176206],
                            [39.63444497, -5.41765519],
                            [39.63439758, -5.4176518],
                            [39.63429941, -5.41772628],
                            [39.63434003, -5.41780076],
                            [39.63442128, -5.41785493],
                            [39.63452283, -5.41784816],
                            [39.63466501, -5.41778384],
                            [39.63485119, -5.41760779],
                            [39.63494598, -5.41747575],
                            [39.63503737, -5.41733695],
                            [39.63513893, -5.4171609],
                            [39.63523033, -5.41693407],
                            [39.63530142, -5.41685959],
                            [39.6354199, -5.41688329],
                            [39.63544579, -5.41691796],
                            [39.63545873, -5.41693529],
                            [39.63547168, -5.41695263],
                            [39.63538149, -5.41694521],
                            [39.63536014, -5.41698124],
                            [39.63537081, -5.41706265],
                            [39.63536948, -5.41717609],
                            [39.63523737, -5.41717476],
                            [39.6352, -5.41723748],
                            [39.63506789, -5.41748972],
                            [39.63498516, -5.4176512],
                            [39.63498061, -5.4177839],
                            [39.63497849, -5.41784605],
                            [39.6349134, -5.41786826],
                            [39.63480367, -5.41793814],
                            [39.63475563, -5.41808895],
                            [39.63477698, -5.41819438],
                            [39.63491043, -5.4183879],
                            [39.63502386, -5.41840792],
                            [39.63510926, -5.41845463],
                            [39.63507723, -5.41850801],
                            [39.63498516, -5.41854805],
                            [39.63490509, -5.41853871],
                            [39.63480367, -5.41847465],
                            [39.63473295, -5.41838656],
                            [39.63466222, -5.4182451],
                            [39.63463553, -5.41820106],
                            [39.63461018, -5.41811564],
                            [39.63456881, -5.41804758],
                            [39.63450144, -5.41804634],
                            [39.63445939, -5.41807293],
                            [39.63444337, -5.41813833],
                            [39.63443858, -5.41818781],
                            [39.63440734, -5.41820773],
                            [39.63433929, -5.41819572],
                            [39.63432626, -5.41819206],
                            [39.63426322, -5.41817436],
                            [39.63420317, -5.41817436],
                            [39.63418449, -5.41822374],
                            [39.63415647, -5.4182144],
                            [39.63407373, -5.41814367],
                            [39.63390559, -5.4180716],
                            [39.63385755, -5.41795682],
                            [39.63384421, -5.41789677],
                            [39.63384021, -5.41781402],
                            [39.63381352, -5.41775797],
                            [39.63375614, -5.41763385],
                            [39.63372144, -5.41752709],
                            [39.63371477, -5.41746169],
                            [39.63368942, -5.41738562],
                            [39.63363337, -5.41734958],
                            [39.63357465, -5.4173656],
                            [39.63352795, -5.41744701],
                            [39.63352395, -5.41758447],
                            [39.63353729, -5.41766588],
                            [39.63363604, -5.417794],
                            [39.63367073, -5.41790744],
                            [39.63362269, -5.41794748],
                            [39.633588, -5.41802088],
                            [39.63360668, -5.41810096],
                            [39.6336149, -5.41816089],
                            [39.63355997, -5.41819438],
                            [39.63347857, -5.41822775],
                            [39.63343053, -5.41827446],
                            [39.63340251, -5.41832517],
                            [39.63342253, -5.41839724],
                            [39.63346923, -5.41844795],
                            [39.63353462, -5.41850401],
                            [39.63351193, -5.41854271],
                            [39.63343854, -5.41854938],
                            [39.63340251, -5.41860944],
                            [39.63336915, -5.41869752],
                            [39.63334158, -5.4187236],
                            [39.63331401, -5.41874967],
                            [39.63324638, -5.41879228],
                            [39.63324104, -5.41885901],
                            [39.63325439, -5.41897378],
                            [39.63321435, -5.41904452],
                            [39.63322503, -5.41912459],
                            [39.63323037, -5.41919933],
                            [39.63319567, -5.41925405],
                            [39.633193, -5.41939151],
                            [39.63319033, -5.4194916],
                            [39.63316898, -5.41963574],
                            [39.63316631, -5.41973316],
                            [39.63318766, -5.41988664],
                            [39.63319967, -5.41998006],
                            [39.63325572, -5.42013621],
                            [39.63329575, -5.42020161],
                            [39.63333178, -5.42030037],
                            [39.6333905, -5.42033106],
                            [39.63345588, -5.42034708],
                            [39.63346656, -5.42041114],
                            [39.63352261, -5.42048454],
                            [39.63357618, -5.42052585],
                            [39.63352661, -5.42056195],
                            [39.63345722, -5.42059798],
                            [39.63347457, -5.42067539],
                            [39.6334372, -5.42084755],
                            [39.63346522, -5.42090894],
                            [39.6335186, -5.42095031],
                            [39.63360134, -5.42098902],
                            [39.63361602, -5.42105041],
                            [39.63358933, -5.42109979],
                            [39.63353462, -5.42119588],
                            [39.63352127, -5.42127195],
                            [39.63352127, -5.42135069],
                            [39.6335226, -5.4214281],
                            [39.63354929, -5.42149749],
                            [39.63354662, -5.42158024],
                            [39.63361468, -5.42172704],
                            [39.63369742, -5.42178577],
                            [39.63374412, -5.4218605],
                            [39.63381084, -5.42195392],
                            [39.63382701, -5.42197013],
                            [39.63380566, -5.42201684],
                            [39.63376814, -5.42204735],
                            [39.63373345, -5.42208872],
                            [39.63374412, -5.42216746],
                            [39.63380951, -5.42226488],
                            [39.63382686, -5.42236898],
                            [39.63379349, -5.42248776],
                            [39.63379616, -5.42253581],
                            [39.63386689, -5.42258786],
                            [39.63395229, -5.42265992],
                            [39.63407106, -5.42267994],
                            [39.63421918, -5.42267327],
                            [39.63429258, -5.42269996],
                            [39.63439399, -5.42268662],
                            [39.63445271, -5.42268128],
                            [39.63447406, -5.42273466],
                            [39.63445805, -5.42279205],
                            [39.63443803, -5.42283476],
                            [39.63434462, -5.42285344],
                            [39.63411509, -5.42291216],
                            [39.63408574, -5.42304429],
                            [39.63406439, -5.42310167],
                            [39.6340804, -5.42317107],
                            [39.63408574, -5.42332188],
                            [39.63407506, -5.42336993],
                            [39.63409908, -5.4234153],
                            [39.63415913, -5.42342598],
                            [39.63426455, -5.42342064],
                            [39.63432727, -5.42338995],
                            [39.63439533, -5.4234073],
                            [39.63446338, -5.42346201],
                            [39.6345221, -5.42354075],
                            [39.63454078, -5.42362216],
                            [39.63454078, -5.42370491],
                            [39.63454078, -5.42381034],
                            [39.63453678, -5.42384771],
                            [39.63455412, -5.42393846],
                            [39.63461151, -5.42395982],
                            [39.63464753, -5.4240172],
                            [39.63460617, -5.42403589],
                            [39.63460884, -5.42418403],
                            [39.6346462, -5.42425743],
                            [39.63454078, -5.42433083],
                            [39.63452877, -5.42441625],
                            [39.63456747, -5.42452301],
                            [39.63465554, -5.42456972],
                            [39.63477297, -5.42472854],
                            [39.63482768, -5.42476724],
                            [39.63486905, -5.42476591],
                            [39.63494111, -5.42477392],
                            [39.6349678, -5.42481796],
                            [39.63498248, -5.42485266],
                            [39.63489307, -5.42490337],
                            [39.63478993, -5.42496297],
                            [39.6347316, -5.42502215],
                            [39.63469824, -5.42508087],
                            [39.6347236, -5.42518497],
                            [39.63479699, -5.42523168],
                            [39.63490908, -5.42527572],
                            [39.6350105, -5.42531843],
                            [39.63512793, -5.42531977],
                            [39.63525337, -5.42530509],
                            [39.63536947, -5.42529841],
                            [39.63545487, -5.4252477],
                            [39.63549757, -5.42519165],
                            [39.63559766, -5.42516362],
                            [39.63576713, -5.42517163],
                            [39.63584926, -5.42522023],
                            [39.63576046, -5.4253171],
                            [39.63570841, -5.4253945],
                            [39.63556162, -5.42541719],
                            [39.63542551, -5.42539851],
                            [39.6352934, -5.4253945],
                            [39.635208, -5.42538916],
                            [39.63511058, -5.42538516],
                            [39.63501717, -5.4254292],
                            [39.63497581, -5.42549727],
                            [39.63495579, -5.42554798],
                            [39.63495312, -5.42562806],
                            [39.63499982, -5.42570012],
                            [39.63502651, -5.42572682],
                            [39.63502518, -5.4257762],
                            [39.63498648, -5.42583225],
                            [39.63493444, -5.42583225],
                            [39.63487714, -5.42584442],
                            [39.63484103, -5.42588296],
                            [39.63483435, -5.42594969],
                            [39.63485704, -5.42600575],
                            [39.63488239, -5.42609383],
                            [39.6349251, -5.42611385],
                            [39.63496379, -5.42610717],
                            [39.63498381, -5.42611919],
                            [39.63499716, -5.42618325],
                            [39.6349251, -5.42621261],
                            [39.63491175, -5.42636742],
                            [39.63492776, -5.42644616],
                            [39.63497847, -5.42652223],
                            [39.63507722, -5.42660631],
                            [39.63510925, -5.42662366],
                            [39.63515862, -5.42664235],
                            [39.63516796, -5.42669039],
                            [39.6351266, -5.42669973],
                            [39.63508122, -5.42672109],
                            [39.63502117, -5.42676246],
                            [39.63499849, -5.4268759],
                            [39.63502251, -5.4269453],
                            [39.63504519, -5.42697733],
                            [39.63505187, -5.4270494],
                            [39.63509857, -5.42712013],
                            [39.63512259, -5.42712814],
                            [39.63519065, -5.42712413],
                            [39.63523602, -5.42714282],
                            [39.63527205, -5.42718819],
                            [39.63531875, -5.4273003],
                            [39.63537347, -5.42732566],
                            [39.63540015, -5.42735235],
                            [39.63541884, -5.42742975],
                            [39.63543618, -5.42756722],
                            [39.63543618, -5.42763395],
                            [39.63539615, -5.4276513],
                            [39.63531519, -5.42765899],
                            [39.63528139, -5.42769934],
                            [39.63527739, -5.42778743],
                            [39.63529073, -5.42786483],
                            [39.63529874, -5.42794891],
                            [39.63534544, -5.42802765],
                            [39.63533076, -5.42809171],
                            [39.63534277, -5.42819181],
                            [39.6353708, -5.42830925],
                            [39.63540816, -5.42840134],
                            [39.63546154, -5.42845472],
                            [39.63551892, -5.42848942],
                            [39.63558297, -5.42849476],
                            [39.635619, -5.42849476],
                            [39.63564035, -5.42846273],
                            [39.63567638, -5.42847074],
                            [39.6356924, -5.42852412],
                            [39.6356924, -5.42858418],
                            [39.63569106, -5.42862555],
                            [39.63572576, -5.42877102],
                            [39.63572976, -5.42883908],
                            [39.63572576, -5.42894985],
                            [39.63578714, -5.42904728],
                            [39.63583385, -5.42909266],
                            [39.63590324, -5.42912469],
                            [39.63600332, -5.42912469],
                            [39.636026, -5.42915271],
                            [39.63597129, -5.42918074],
                            [39.63596462, -5.42918074],
                            [39.63592592, -5.42918341],
                            [39.63587637, -5.42920531],
                            [39.63585253, -5.42923679],
                            [39.63584586, -5.42929551],
                            [39.63583918, -5.42941029],
                            [39.63582984, -5.42948636],
                            [39.63585653, -5.42955843],
                            [39.63586987, -5.42962115],
                            [39.63590057, -5.4296692],
                            [39.63599531, -5.42970657],
                            [39.63604068, -5.42975461],
                            [39.63610207, -5.4297426],
                            [39.63615945, -5.42975461],
                            [39.63617146, -5.42976262],
                            [39.63617146, -5.429808],
                            [39.63617012, -5.42987072],
                            [39.63616078, -5.42993078],
                            [39.6361034, -5.42996014],
                            [39.63607404, -5.43001486],
                            [39.63609006, -5.43007625],
                            [39.63608338, -5.43011095],
                            [39.63604335, -5.43013097],
                            [39.63601069, -5.43013525],
                            [39.63598197, -5.43017501],
                            [39.6359793, -5.4302404],
                            [39.6359873, -5.43032315],
                            [39.63603935, -5.43044326],
                            [39.63607671, -5.43052067],
                            [39.63611141, -5.43061276],
                            [39.63617146, -5.43066747],
                            [39.63625953, -5.4307262],
                            [39.63633426, -5.43073287],
                            [39.6363863, -5.43071018],
                            [39.636421, -5.43070618],
                            [39.63649306, -5.43072353],
                            [39.63645999, -5.4307521],
                            [39.63642795, -5.4307988],
                            [39.636421, -5.43080894],
                            [39.63638897, -5.43081295],
                            [39.63634442, -5.43083086],
                            [39.63632091, -5.43086499],
                            [39.63632225, -5.4309464],
                            [39.63636895, -5.43100379],
                            [39.63640899, -5.43102248],
                            [39.63643034, -5.43103182],
                            [39.63644055, -5.43105371],
                            [39.636421, -5.43108253],
                            [39.63638897, -5.43107986],
                            [39.63634226, -5.43108921],
                            [39.63635027, -5.43116928],
                            [39.63637696, -5.431224],
                            [39.63642367, -5.43126137],
                            [39.63647304, -5.43125469],
                            [39.63649039, -5.43125469],
                            [39.63650373, -5.4312587],
                            [39.63651708, -5.43129473],
                            [39.63651307, -5.43133611],
                            [39.63649039, -5.4314015],
                            [39.63649572, -5.43147223],
                            [39.63649706, -5.43163505],
                            [39.63653976, -5.43170312],
                            [39.63659714, -5.43175784],
                            [39.63666386, -5.43178453],
                            [39.63671991, -5.43181389],
                            [39.63677329, -5.43188729],
                            [39.63678263, -5.4318953],
                            [39.63681065, -5.43194735],
                            [39.63678984, -5.43202339],
                            [39.63675994, -5.43207013],
                            [39.63679597, -5.43220492],
                            [39.63688138, -5.4323237],
                            [39.63692408, -5.43236241],
                            [39.63701349, -5.43241045],
                            [39.6370682, -5.43246917],
                            [39.63707887, -5.43250387],
                            [39.63710556, -5.4325359],
                            [39.63720831, -5.43258261],
                            [39.63727504, -5.43261464],
                            [39.63737645, -5.43264134],
                            [39.63750856, -5.43269472],
                            [39.6375953, -5.43273476],
                            [39.63768871, -5.43274944],
                            [39.63776077, -5.43276145],
                            [39.63781949, -5.4327748],
                            [39.63788888, -5.43275478],
                            [39.63794226, -5.43270139],
                            [39.63800097, -5.43272275],
                            [39.63801432, -5.43276412],
                            [39.63796361, -5.43278681],
                            [39.63797962, -5.43286822],
                            [39.63801298, -5.43290158],
                            [39.6380757, -5.43288557],
                            [39.63815043, -5.43286021],
                            [39.63821048, -5.4328095],
                            [39.63828387, -5.43282818],
                            [39.63830789, -5.43285754],
                            [39.63830656, -5.43292027],
                            [39.63832124, -5.43294562],
                            [39.63840664, -5.43300301],
                            [39.63844668, -5.43303504],
                            [39.6384827, -5.43307108],
                            [39.63852007, -5.43310444],
                            [39.63857745, -5.4330991],
                            [39.63861748, -5.43310444],
                            [39.6386375, -5.43315382],
                            [39.6386762, -5.43321521],
                            [39.63873892, -5.43326593],
                            [39.63878295, -5.43326326],
                            [39.63884434, -5.43322589],
                            [39.63892307, -5.43319653],
                            [39.63895243, -5.43320587],
                            [39.63903249, -5.43325258],
                            [39.63910322, -5.43322856],
                            [39.63914859, -5.43317918],
                            [39.63922332, -5.4331605],
                            [39.63925535, -5.43321388],
                            [39.63926335, -5.43333132],
                            [39.63928737, -5.43343542],
                            [39.6394822, -5.43357022],
                            [39.63954625, -5.43358756],
                            [39.6395983, -5.4336196],
                            [39.63965167, -5.4336276],
                            [39.63971573, -5.43361426],
                            [39.6397651, -5.4335889],
                            [39.6398038, -5.43356354],
                            [39.63982515, -5.43350882],
                            [39.63987052, -5.43346612],
                            [39.63993057, -5.43346612],
                            [39.63999996, -5.43348747],
                            [39.640048, -5.43353151],
                            [39.64004667, -5.43357422],
                            [39.63998662, -5.43359157],
                            [39.63993191, -5.43364362],
                            [39.63990789, -5.43372503],
                            [39.63995192, -5.43378375],
                            [39.64002932, -5.4338011],
                            [39.64012807, -5.43381178],
                            [39.64020547, -5.43381178],
                            [39.64022148, -5.43386916],
                            [39.6402495, -5.43391321],
                            [39.64030021, -5.43391588],
                            [39.64038695, -5.4339092],
                            [39.640447, -5.43389853],
                            [39.64052707, -5.43389452],
                            [39.64057511, -5.43389853],
                            [39.64063783, -5.43387584],
                            [39.64069788, -5.43383714],
                            [39.64073924, -5.43384648],
                            [39.64074858, -5.43388251],
                            [39.64077127, -5.43395725],
                            [39.64081798, -5.43398794],
                            [39.6408887, -5.43401731],
                            [39.6408847, -5.43406935],
                            [39.6408887, -5.43414943],
                            [39.64090872, -5.43420815],
                            [39.64098878, -5.4342255],
                            [39.64107285, -5.43424152],
                            [39.64117961, -5.43430558],
                            [39.64129437, -5.43426287],
                            [39.64138912, -5.43423351],
                            [39.6414932, -5.43423351],
                            [39.64152256, -5.43426421],
                            [39.64157193, -5.43432693],
                            [39.64163866, -5.43435629],
                            [39.6417334, -5.43437498],
                            [39.64176676, -5.434395],
                            [39.64181347, -5.43437898],
                            [39.64185217, -5.4343643],
                            [39.64190288, -5.4343643],
                            [39.64191622, -5.43438432],
                            [39.64187352, -5.43442836],
                            [39.64189487, -5.43449242],
                            [39.6419349, -5.43455648],
                            [39.64201897, -5.43459785],
                            [39.64206167, -5.43462054],
                            [39.64213373, -5.43460586],
                            [39.64218444, -5.43456449],
                            [39.64226851, -5.43458451],
                            [39.6423299, -5.43462588],
                            [39.64240596, -5.43463122],
                            [39.64248202, -5.43461254],
                            [39.64252072, -5.43465391],
                            [39.64259412, -5.43469261],
                            [39.64267685, -5.43465925],
                            [39.64273156, -5.43463389],
                            [39.64275291, -5.43463389],
                            [39.6427756, -5.43464724],
                            [39.6428223, -5.43470062],
                            [39.642861, -5.43469795],
                            [39.64291038, -5.43466459],
                            [39.64295175, -5.4346806],
                            [39.64299845, -5.43468994],
                            [39.64304916, -5.43465658],
                            [39.64307051, -5.43459519],
                            [39.64306651, -5.43451244],
                            [39.64305984, -5.43441101],
                            [39.64312656, -5.43441235],
                            [39.64318794, -5.43442436],
                            [39.64325867, -5.43442436],
                            [39.64330804, -5.4344684],
                            [39.64333073, -5.43450443],
                            [39.64334941, -5.43452045],
                            [39.64338277, -5.4345338],
                            [39.64340145, -5.43452312],
                            [39.64343882, -5.43449376],
                            [39.64347084, -5.43451378],
                            [39.64349486, -5.4345418],
                            [39.64353623, -5.4345418],
                            [39.6435429, -5.43449242],
                            [39.64352422, -5.43440968],
                            [39.64351355, -5.4343643],
                            [39.64349086, -5.43428957],
                            [39.64353757, -5.43424286],
                            [39.64360562, -5.43428289],
                            [39.64364432, -5.43433895],
                            [39.64365366, -5.43433494],
                            [39.64368702, -5.43432827],
                            [39.64371771, -5.43432427],
                            [39.64373773, -5.4343256],
                            [39.6437751, -5.43433361],
                            [39.6438258, -5.43437365],
                            [39.64383648, -5.43442036],
                            [39.64382847, -5.43451378],
                            [39.64380579, -5.43454047],
                            [39.64372572, -5.4345378],
                            [39.64366434, -5.4345378],
                            [39.64361496, -5.4345725],
                            [39.64360562, -5.43462588],
                            [39.64365099, -5.43466058],
                            [39.6437404, -5.43469929],
                            [39.64381646, -5.43472598],
                            [39.64388185, -5.43473932],
                            [39.64394457, -5.43472197],
                            [39.64404198, -5.43466726],
                            [39.64407401, -5.43466325],
                            [39.64412739, -5.43468327],
                            [39.64418744, -5.43466592],
                            [39.64418343, -5.43460053],
                            [39.64412605, -5.43453914],
                            [39.64411404, -5.43445506],
                            [39.64411538, -5.43440968],
                            [39.64411805, -5.43434695],
                            [39.64415675, -5.43425754],
                            [39.64431955, -5.43411207],
                            [39.64448102, -5.43399996],
                            [39.64469052, -5.43385983],
                            [39.64486533, -5.43373438],
                            [39.64493473, -5.43370502],
                            [39.64498677, -5.43369034],
                            [39.64505216, -5.43367165],
                            [39.64513089, -5.43364096],
                            [39.64519227, -5.43362628],
                            [39.64528969, -5.43360492],
                            [39.64539558, -5.43362357],
                            [39.64538977, -5.43364363],
                            [39.64538562, -5.43370308],
                            [39.64538043, -5.43372103],
                            [39.64534306, -5.4337624],
                            [39.64526781, -5.4337697],
                            [39.64525443, -5.43381546],
                            [39.64523364, -5.43388652],
                            [39.64523497, -5.43394658],
                            [39.64524832, -5.43404801],
                            [39.64528568, -5.43409338],
                            [39.64539377, -5.43413075],
                            [39.64556592, -5.43412942],
                            [39.64561662, -5.43410139],
                            [39.64568068, -5.43409472],
                            [39.64575808, -5.43411474],
                            [39.64584081, -5.43408805],
                            [39.64598226, -5.43405602],
                            [39.64602496, -5.43405869],
                            [39.64608234, -5.434044],
                            [39.64612905, -5.43401598],
                            [39.6461464, -5.43399596],
                            [39.64619978, -5.43395993],
                            [39.6462665, -5.43397194],
                            [39.64633856, -5.43399463],
                            [39.64637859, -5.43400664],
                            [39.64640928, -5.43397327],
                            [39.6463946, -5.43390788],
                            [39.64640261, -5.43386117],
                            [39.64645199, -5.43385449],
                            [39.64648802, -5.43382113],
                            [39.64651871, -5.43374372],
                            [39.64657342, -5.43370368],
                            [39.64665349, -5.43370368],
                            [39.64670953, -5.43372504],
                            [39.64673088, -5.43379844],
                            [39.64670019, -5.4338625],
                            [39.6467509, -5.43396793],
                            [39.64678293, -5.43395058],
                            [39.64684831, -5.43392523],
                            [39.64692838, -5.43387585],
                            [39.64698843, -5.43377709],
                            [39.64708451, -5.43374239],
                            [39.64713122, -5.43382647],
                            [39.64703247, -5.43390788],
                            [39.64696841, -5.43395325],
                            [39.64692838, -5.4340013],
                            [39.64693105, -5.43404],
                            [39.64695507, -5.43404668],
                            [39.64703514, -5.43404267],
                            [39.64708718, -5.434036],
                            [39.64716458, -5.43402265],
                            [39.6472313, -5.43399463],
                            [39.64729669, -5.43399196],
                            [39.6473554, -5.43403733],
                            [39.6474368, -5.43399463],
                            [39.64753955, -5.43397327],
                            [39.64759694, -5.4339626],
                            [39.647677, -5.4339626],
                            [39.64775173, -5.43395726],
                            [39.64782379, -5.43394925],
                            [39.64782112, -5.43383715],
                            [39.64786649, -5.43377309],
                            [39.64794923, -5.43374372],
                            [39.64805065, -5.43371169],
                            [39.64806399, -5.43379177],
                            [39.64808534, -5.43388252],
                            [39.64820811, -5.43390121],
                            [39.64838426, -5.43387985],
                            [39.64852571, -5.43381045],
                            [39.64858976, -5.43375974],
                            [39.64864314, -5.43375974],
                            [39.64870185, -5.43384515],
                            [39.64880861, -5.43382647],
                            [39.64878459, -5.43370636],
                            [39.6488006, -5.43366098],
                            [39.64892337, -5.43369301],
                            [39.64898209, -5.43375173],
                            [39.64908617, -5.4337891],
                            [39.64918492, -5.43376508],
                            [39.64915556, -5.43366899],
                            [39.64905948, -5.43355154],
                            [39.64908083, -5.43346079],
                            [39.64912887, -5.43337805],
                            [39.64931303, -5.43342342],
                            [39.64934505, -5.43353019],
                            [39.6494011, -5.43363963],
                            [39.6494438, -5.43363963],
                            [39.64948117, -5.43352485],
                            [39.64950252, -5.43338072],
                            [39.64958258, -5.43341008],
                            [39.64967599, -5.43348749],
                            [39.6497614, -5.43348482],
                            [39.64995089, -5.43344211],
                            [39.65000694, -5.4333914],
                            [39.65004964, -5.43331399],
                            [39.65006031, -5.4332526],
                            [39.65012704, -5.43330331],
                            [39.65015906, -5.43334869],
                            [39.65026849, -5.43333],
                            [39.65033788, -5.4333914],
                            [39.65039259, -5.4334301],
                            [39.6504473, -5.4334688],
                            [39.65053538, -5.43349283],
                            [39.65067683, -5.43349549],
                            [39.65082895, -5.43349549],
                            [39.65088767, -5.4334261],
                            [39.65094105, -5.4333647],
                            [39.65102378, -5.4333647],
                            [39.65114922, -5.43339674],
                            [39.6512106, -5.43335937],
                            [39.65127732, -5.43326328],
                            [39.65136807, -5.43313782],
                            [39.65145614, -5.43306042],
                            [39.65153354, -5.43308444],
                            [39.65165631, -5.43310313],
                            [39.651691, -5.4330444],
                            [39.65176039, -5.43297767],
                            [39.65181911, -5.43300437],
                            [39.6519739, -5.43301771],
                            [39.65204329, -5.43295632],
                            [39.65213937, -5.43278016],
                            [39.65216606, -5.43265204],
                            [39.65220343, -5.43252658],
                            [39.6522061, -5.43244384],
                            [39.65222211, -5.43239045],
                            [39.65228083, -5.4323691],
                            [39.65242761, -5.43235309],
                            [39.65240626, -5.43244384],
                            [39.65235859, -5.43257681],
                            [39.65235555, -5.43265737],
                            [39.65237157, -5.43281486],
                            [39.65250768, -5.43290561],
                            [39.65268383, -5.43288692],
                            [39.65284129, -5.4327855],
                            [39.65296673, -5.43272677],
                            [39.65304146, -5.43262801],
                            [39.65306548, -5.43255328],
                            [39.65303879, -5.43244918],
                            [39.65299609, -5.43237177],
                            [39.65301744, -5.43229703],
                            [39.65309483, -5.43222764],
                            [39.65318558, -5.43217425],
                            [39.65324429, -5.43223031],
                            [39.65332169, -5.43239046],
                            [39.65337507, -5.4324732],
                            [39.65348182, -5.4324385],
                            [39.65357523, -5.43238245],
                            [39.65362594, -5.43229704],
                            [39.65365797, -5.43221963],
                            [39.65363929, -5.43212888],
                            [39.65363929, -5.43200876],
                            [39.65367932, -5.43196606],
                            [39.65373537, -5.43199008],
                            [39.65375405, -5.43211553],
                            [39.65372835, -5.43223853],
                            [39.65373003, -5.43235576],
                            [39.65378607, -5.43244117],
                            [39.65387148, -5.43247053],
                            [39.65400492, -5.43244384],
                            [39.65406097, -5.43238512],
                            [39.65415705, -5.43228636],
                            [39.6542638, -5.43222764],
                            [39.65444262, -5.43221429],
                            [39.65454404, -5.43221162],
                            [39.65454404, -5.43214222],
                            [39.65457073, -5.43203546],
                            [39.65466147, -5.43195805],
                            [39.65478691, -5.43189399],
                            [39.65502711, -5.43181392],
                            [39.65513119, -5.43174185],
                            [39.65520592, -5.43166177],
                            [39.65536872, -5.43163775],
                            [39.65552352, -5.43162174],
                            [39.65563828, -5.43164309],
                            [39.65571034, -5.43159771],
                            [39.65577439, -5.43154433],
                            [39.65582777, -5.43159237],
                            [39.65593986, -5.43157102],
                            [39.65606263, -5.43149362],
                            [39.65614537, -5.43142422],
                            [39.65622276, -5.43139486],
                            [39.65625746, -5.43144557],
                            [39.65630016, -5.43153899],
                            [39.65636688, -5.43159238],
                            [39.65644695, -5.43158437],
                            [39.65646563, -5.43153632],
                            [39.6564256, -5.43143222],
                            [39.65635621, -5.43135215],
                            [39.65637222, -5.43128809],
                            [39.65641492, -5.43120801],
                            [39.65642827, -5.43113061],
                            [39.65646563, -5.43106121],
                            [39.65655905, -5.43098647],
                            [39.6566311, -5.43098647],
                            [39.65668181, -5.43104252],
                            [39.65661776, -5.43111459],
                            [39.6566014, -5.43119308],
                            [39.65661509, -5.43129877],
                            [39.65668181, -5.43134414],
                            [39.65674587, -5.43131478],
                            [39.65681259, -5.4313388],
                            [39.65687931, -5.43138151],
                            [39.65698873, -5.4314082],
                            [39.65705546, -5.43138685],
                            [39.65709015, -5.4313388],
                            [39.65713019, -5.43126674],
                            [39.65721025, -5.43125606],
                            [39.65728498, -5.43125873],
                            [39.6573397, -5.43128254],
                            [39.65734903, -5.43134147],
                            [39.65738907, -5.43140553],
                            [39.65746113, -5.43140553],
                            [39.65753585, -5.43136817],
                            [39.65759991, -5.43130411],
                            [39.65770133, -5.43125072],
                            [39.65775203, -5.43128275],
                            [39.65780808, -5.43132012],
                            [39.65785879, -5.43126941],
                            [39.65790149, -5.43117065],
                            [39.65797889, -5.43115196],
                            [39.65804561, -5.43111193],
                            [39.65814436, -5.43105587],
                            [39.65824578, -5.43109057],
                            [39.65834453, -5.43114662],
                            [39.65846996, -5.43117065],
                            [39.65860341, -5.43123204],
                            [39.65872618, -5.43122136],
                            [39.65881158, -5.43112527],
                            [39.6588783, -5.43102384],
                            [39.65900374, -5.43100249],
                            [39.65912918, -5.43107456],
                            [39.65926262, -5.43101584],
                            [39.6593507, -5.4309411],
                            [39.65941208, -5.43082899],
                            [39.65946813, -5.43084234],
                            [39.65957755, -5.43093042],
                            [39.65974836, -5.43101317],
                            [39.65988714, -5.43093042],
                            [39.66003927, -5.43085569],
                            [39.66017271, -5.43085569],
                            [39.66032751, -5.43085569],
                            [39.66051967, -5.43087437],
                            [39.66065845, -5.43087704],
                            [39.66073051, -5.43085035],
                            [39.66078122, -5.43077561],
                            [39.66078922, -5.43065817],
                            [39.66085595, -5.4306208],
                            [39.66090932, -5.43072223],
                            [39.66098672, -5.43077027],
                            [39.66107479, -5.43079964],
                            [39.66114685, -5.43079163],
                            [39.66121091, -5.4307596],
                            [39.6612456, -5.43078629],
                            [39.66130165, -5.43078629],
                            [39.66142175, -5.43077027],
                            [39.66152583, -5.43075159],
                            [39.66160857, -5.4308637],
                            [39.66169931, -5.43094377],
                            [39.66179272, -5.43095178],
                            [39.66192617, -5.4309064],
                            [39.66203559, -5.43089573],
                            [39.662129, -5.43096246],
                            [39.66223042, -5.43098915],
                            [39.66229447, -5.43095445],
                            [39.66236653, -5.43091708],
                            [39.66243859, -5.43087437],
                            [39.66253734, -5.43085836],
                            [39.66263075, -5.43086637],
                            [39.66270014, -5.43089306],
                            [39.6627722, -5.43097847],
                            [39.66291098, -5.43103986],
                            [39.66302308, -5.43108257],
                            [39.66319388, -5.43120002],
                            [39.66326595, -5.43119735],
                            [39.66341807, -5.43115731],
                            [39.66354885, -5.43114663],
                            [39.66362891, -5.43115464],
                            [39.66376236, -5.43115464],
                            [39.66385577, -5.43108524],
                            [39.66395452, -5.43101051],
                            [39.66402124, -5.43105588],
                            [39.66410931, -5.43109325],
                            [39.66419472, -5.43112261],
                            [39.66426944, -5.43112795],
                            [39.66435218, -5.43108524],
                            [39.66441356, -5.4311146],
                            [39.66451498, -5.4311413],
                            [39.66462974, -5.43110126],
                            [39.66468579, -5.43105588],
                            [39.6648219, -5.43098382],
                            [39.66489396, -5.43091175],
                            [39.66492332, -5.43084769],
                            [39.66496069, -5.43077562],
                            [39.66496069, -5.43073825],
                            [39.66491265, -5.43070622],
                            [39.66488062, -5.43069554],
                            [39.66488863, -5.43061547],
                            [39.66497136, -5.43062348],
                            [39.66500873, -5.43057543],
                            [39.66500072, -5.43053806],
                            [39.66496069, -5.43049535],
                            [39.66489663, -5.43047133],
                            [39.66488863, -5.43043129],
                            [39.66496069, -5.43042329],
                            [39.66505677, -5.4303619],
                            [39.66515018, -5.4303005],
                            [39.66521156, -5.43031919],
                            [39.66522491, -5.43044197],
                            [39.66526388, -5.43058655],
                            [39.66531565, -5.43069287],
                            [39.66535568, -5.43071156],
                            [39.66546244, -5.43073825],
                            [39.66553183, -5.43072757],
                            [39.6656626, -5.4306475],
                            [39.66572666, -5.43058077],
                            [39.66573466, -5.43049536],
                            [39.66567061, -5.43041528],
                            [39.6655772, -5.43027114],
                            [39.66548646, -5.43016972],
                            [39.66548112, -5.43010832],
                            [39.66558521, -5.4301884],
                            [39.66575868, -5.43030051],
                            [39.66598287, -5.43038859],
                            [39.666143, -5.43036724],
                            [39.66631381, -5.43031118],
                            [39.66644725, -5.43025513],
                            [39.66656468, -5.4301884],
                            [39.66668478, -5.43009231],
                            [39.66672749, -5.4300069],
                            [39.66684492, -5.42999622],
                            [39.66691698, -5.4299028],
                            [39.66695434, -5.42981738],
                            [39.6670184, -5.42965723],
                            [39.66708779, -5.42947039],
                            [39.66708245, -5.42936362],
                            [39.66704508, -5.42926753],
                            [39.66703441, -5.42917144],
                            [39.66708779, -5.42909137],
                            [39.66717853, -5.42917144],
                            [39.66723724, -5.42907535],
                            [39.66729062, -5.42894723],
                            [39.66727995, -5.42880843],
                            [39.66721056, -5.42868031],
                            [39.66715184, -5.42865362],
                            [39.66704509, -5.42858956],
                            [39.66694367, -5.42857355],
                            [39.6668636, -5.42862159],
                            [39.66676218, -5.42867497],
                            [39.66660205, -5.4287764],
                            [39.66642057, -5.42885114],
                            [39.6662551, -5.4289152],
                            [39.66611098, -5.42897392],
                            [39.66595618, -5.42898994],
                            [39.66580672, -5.42893121],
                            [39.66567328, -5.42881911],
                            [39.66560389, -5.42864828],
                            [39.66561456, -5.42833866],
                            [39.66567862, -5.42811978],
                            [39.66576402, -5.42793294],
                            [39.66582808, -5.42776745],
                            [39.6658601, -5.42756459],
                            [39.66588679, -5.42744181],
                            [39.66583341, -5.42729768],
                            [39.66571065, -5.42722294],
                            [39.66530498, -5.42710549],
                            [39.66503275, -5.42695068],
                            [39.66475519, -5.42677985],
                            [39.66438688, -5.42669978],
                            [39.66434526, -5.42659639],
                            [39.66439685, -5.42657562],
                            [39.66444222, -5.42660231],
                            [39.66449293, -5.42662633],
                            [39.66455431, -5.426629],
                            [39.66464239, -5.42665302],
                            [39.66472245, -5.42669306],
                            [39.66481586, -5.42674111],
                            [39.66488258, -5.42677848],
                            [39.66498133, -5.42682919],
                            [39.66513613, -5.42690393],
                            [39.66518951, -5.42694397],
                            [39.66524822, -5.42696532],
                            [39.66532829, -5.42699201],
                            [39.66540835, -5.42702938],
                            [39.66546707, -5.42705607],
                            [39.66552579, -5.42702671],
                            [39.66560051, -5.42697066],
                            [39.66567257, -5.426976],
                            [39.66575798, -5.42704273],
                            [39.66584071, -5.42711746],
                            [39.6659448, -5.4271922],
                            [39.66599818, -5.42728028],
                            [39.66600885, -5.42741107],
                            [39.66602951, -5.42753726],
                            [39.66599208, -5.42765382],
                            [39.66594747, -5.42779277],
                            [39.66592345, -5.4279716],
                            [39.66591315, -5.42815397],
                            [39.66585616, -5.4283325],
                            [39.66582737, -5.42842269],
                            [39.66582203, -5.42854281],
                            [39.66584338, -5.42863356],
                            [39.66585672, -5.42867627],
                            [39.6658674, -5.42872164],
                            [39.66589409, -5.42876168],
                            [39.66596615, -5.42880973],
                            [39.66605422, -5.42881506],
                            [39.66615297, -5.42879638],
                            [39.666185, -5.42879371],
                            [39.66623037, -5.42876969],
                            [39.66627841, -5.428751],
                            [39.66635314, -5.42868427],
                            [39.66648658, -5.42863623],
                            [39.66660935, -5.42856416],
                            [39.66661469, -5.42856149],
                            [39.66669742, -5.42851612],
                            [39.66673212, -5.42847341],
                            [39.66675881, -5.42844138],
                            [39.66684955, -5.42837465],
                            [39.66688158, -5.42837198],
                            [39.66698299, -5.42836931],
                            [39.66703904, -5.42834262],
                            [39.66712711, -5.42834262],
                            [39.66719383, -5.42836931],
                            [39.66727657, -5.42843604],
                            [39.66734329, -5.42849209],
                            [39.66736197, -5.42858018],
                            [39.66737532, -5.42867093],
                            [39.66739934, -5.42878304],
                            [39.66749008, -5.42881774],
                            [39.66750876, -5.42887379],
                            [39.6674287, -5.42894853],
                            [39.6674232, -5.42908163],
                            [39.66741268, -5.42911935],
                            [39.66737799, -5.42916473],
                            [39.6674061, -5.42920902],
                            [39.66739205, -5.42925953],
                            [39.66736197, -5.42936759],
                            [39.6673593, -5.42947435],
                            [39.66747407, -5.42953575],
                            [39.66750876, -5.42955176],
                            [39.66760217, -5.42959447],
                            [39.66765822, -5.42964251],
                            [39.66770359, -5.42964251],
                            [39.66777298, -5.42959981],
                            [39.66779967, -5.42954375],
                            [39.6678397, -5.42953041],
                            [39.66787707, -5.42955176],
                            [39.66793044, -5.42961582],
                            [39.6679945, -5.42963451],
                            [39.66807723, -5.42967187],
                            [39.66813061, -5.42967721],
                            [39.66816797, -5.42969323],
                            [39.66823737, -5.42968789],
                            [39.6683201, -5.4296612],
                            [39.66841084, -5.42962116],
                            [39.66848023, -5.42956778],
                            [39.6685603, -5.42951973],
                            [39.66862969, -5.4293756],
                            [39.66865104, -5.42933823],
                            [39.66866439, -5.42922078],
                            [39.66867773, -5.4291674],
                            [39.66872043, -5.42909266],
                            [39.6687658, -5.42905529],
                            [39.66883786, -5.42897255],
                            [39.66888057, -5.42893518],
                            [39.66891526, -5.42897522],
                            [39.66894462, -5.42903394],
                            [39.66903269, -5.42898857],
                            [39.66903002, -5.42896187],
                            [39.66900334, -5.42889514],
                            [39.66898465, -5.42882841],
                            [39.66902736, -5.42878571],
                            [39.66906739, -5.42880439],
                            [39.66911543, -5.42882041],
                            [39.66917414, -5.42882041],
                            [39.6691955, -5.42882041],
                            [39.6692462, -5.42877236],
                            [39.66927556, -5.428743],
                            [39.66929691, -5.42869762],
                            [39.66929691, -5.4286389],
                            [39.66929424, -5.42857484],
                            [39.66930492, -5.42852413],
                            [39.66933428, -5.42851879],
                            [39.66938232, -5.42853214],
                            [39.66939833, -5.42858552],
                            [39.66941968, -5.42867093],
                            [39.66947573, -5.42872165],
                            [39.66955046, -5.4287083],
                            [39.66958248, -5.42868161],
                            [39.6696065, -5.42865492],
                            [39.66964387, -5.42856684],
                            [39.66966789, -5.42851879],
                            [39.66971326, -5.42850544],
                            [39.66977197, -5.42849744],
                            [39.66983336, -5.42846808],
                            [39.66983336, -5.42839868],
                            [39.66983603, -5.42833195],
                            [39.66986805, -5.42830259],
                            [39.66988407, -5.42830259],
                            [39.66990542, -5.42831059],
                            [39.66996146, -5.4283186],
                            [39.66999616, -5.42830259],
                            [39.67003086, -5.42825187],
                            [39.67003619, -5.42819849],
                            [39.67003886, -5.42813176],
                            [39.67007089, -5.42811041],
                            [39.67011359, -5.42812375],
                            [39.67014562, -5.42817447],
                            [39.67015896, -5.42822251],
                            [39.670199, -5.42827856],
                            [39.67025504, -5.42833195],
                            [39.67033511, -5.42840135],
                            [39.67040183, -5.42843605],
                            [39.67045788, -5.4284227],
                            [39.67051926, -5.42839601],
                            [39.67063135, -5.42842537],
                            [39.6707301, -5.42839067],
                            [39.67082351, -5.42830259],
                            [39.67089824, -5.42830526],
                            [39.67102368, -5.42839067],
                            [39.67118648, -5.42843872],
                            [39.67122918, -5.42842003],
                            [39.67131459, -5.42837199],
                            [39.67138932, -5.42836398],
                            [39.67145604, -5.42836131],
                            [39.67150141, -5.42841736],
                            [39.6715361, -5.42858285],
                            [39.67158948, -5.42869763],
                            [39.67167489, -5.42878838],
                            [39.67175762, -5.42883109],
                            [39.67184569, -5.4288471],
                            [39.67193377, -5.42881774],
                            [39.67203252, -5.4288471],
                            [39.67205654, -5.42888714],
                            [39.67215795, -5.42896722],
                            [39.67219532, -5.42899925],
                            [39.67228606, -5.4290206],
                            [39.67229674, -5.42900992],
                            [39.6723768, -5.42897256],
                            [39.67242529, -5.42901267],
                            [39.67241417, -5.42907932],
                            [39.67245153, -5.42916474],
                            [39.67252092, -5.42926083],
                            [39.67257964, -5.42931421],
                            [39.67270774, -5.42930353],
                            [39.67276646, -5.42919143],
                            [39.67280382, -5.42905263],
                            [39.67285186, -5.42896722],
                            [39.67291592, -5.42899391],
                            [39.67299064, -5.42912203],
                            [39.67300132, -5.4292715],
                            [39.67308139, -5.4294103],
                            [39.67321483, -5.42946369],
                            [39.67325753, -5.42951173],
                            [39.67319882, -5.4296185],
                            [39.67323749, -5.42980787],
                            [39.67323084, -5.42985339],
                            [39.67328956, -5.42988542],
                            [39.67340699, -5.42994948],
                            [39.67352442, -5.42998685],
                            [39.67365253, -5.42992812],
                            [39.67365786, -5.4296185],
                            [39.67363651, -5.4294103],
                            [39.6737059, -5.42931955],
                            [39.67378063, -5.42936226],
                            [39.67383401, -5.429437],
                            [39.67396745, -5.42951707],
                            [39.67409556, -5.42957579],
                            [39.67422901, -5.42955444],
                            [39.6742984, -5.42950106],
                            [39.67436245, -5.42953309],
                            [39.67445319, -5.42958647],
                            [39.67457062, -5.42958113],
                            [39.67461332, -5.42951173],
                            [39.67467738, -5.42955978],
                            [39.67478413, -5.4296185],
                            [39.67488555, -5.42959715],
                            [39.67495494, -5.42956512],
                            [39.67500832, -5.42956512],
                            [39.67506703, -5.42962384],
                            [39.67517379, -5.4296879],
                            [39.6753019, -5.42960782],
                            [39.6753446, -5.42965587],
                            [39.67544068, -5.42968256],
                            [39.67556878, -5.42973061],
                            [39.67566486, -5.42976264],
                            [39.67584635, -5.42981602],
                            [39.67596378, -5.42976798],
                            [39.67605452, -5.42972527],
                            [39.67611857, -5.42967189],
                            [39.67617195, -5.42963452],
                            [39.67625736, -5.42959181],
                            [39.67632675, -5.42954377],
                            [39.67634276, -5.42943166],
                            [39.67632141, -5.42927685],
                            [39.67633742, -5.42914339],
                            [39.67633742, -5.42902594],
                            [39.6763481, -5.42884444],
                            [39.67634276, -5.42862023],
                            [39.67644976, -5.42872773],
                            [39.67657606, -5.42891145],
                            [39.67651291, -5.4291813],
                            [39.67651865, -5.42940522],
                            [39.67662773, -5.42952005],
                            [39.67682866, -5.42981861],
                            [39.67690329, -5.4299277],
                            [39.67706977, -5.42997363],
                            [39.67715015, -5.42980139],
                            [39.67733959, -5.42981287],
                            [39.67751182, -5.42985306],
                            [39.67756923, -5.42998512],
                            [39.67759219, -5.43014014],
                            [39.67768979, -5.43031239],
                            [39.67791368, -5.43043296],
                            [39.67810887, -5.43046167],
                            [39.67836721, -5.43044444],
                            [39.6785624, -5.43035258],
                            [39.67878629, -5.43043296],
                            [39.67870592, -5.43064539],
                            [39.67894704, -5.43077171],
                            [39.67953261, -5.43051908],
                            [39.67981391, -5.43032387],
                            [39.68001484, -5.43011144],
                            [39.68014114, -5.42994493],
                            [39.68022151, -5.43009995],
                            [39.68017558, -5.43028942],
                            [39.68026744, -5.43059946],
                            [39.68048559, -5.43067985],
                            [39.68080708, -5.43097841],
                            [39.68103671, -5.43090377],
                            [39.6815821, -5.43093247],
                            [39.68200118, -5.43096692],
                            [39.68213322, -5.43081765],
                            [39.68223081, -5.43062818],
                            [39.68233989, -5.43061669],
                            [39.68243868, -5.43070673],
                            [39.68231119, -5.43093248],
                            [39.68221933, -5.43120807],
                            [39.68238582, -5.43156404],
                            [39.68232841, -5.43156978],
                            [39.68220785, -5.43170184],
                            [39.68238582, -5.43180519],
                            [39.68259249, -5.43178222],
                            [39.68274749, -5.4318626],
                            [39.6829774, -5.43211543],
                            [39.68294268, -5.43218413],
                            [39.68298861, -5.43234489],
                            [39.68310342, -5.4323736],
                            [39.68325843, -5.43236211],
                            [39.68333306, -5.43255158],
                            [39.68347658, -5.43283866],
                            [39.68366029, -5.43283866],
                            [39.68384974, -5.43303961],
                            [39.68389566, -5.43320612],
                            [39.683999, -5.4332176],
                            [39.68421715, -5.43319464],
                            [39.68446401, -5.43310277],
                            [39.68459605, -5.43294775],
                            [39.68467068, -5.43302239],
                            [39.68470513, -5.43325205],
                            [39.68490606, -5.4333554],
                            [39.68510699, -5.43332095],
                            [39.68522754, -5.43347597],
                            [39.68535958, -5.43381472],
                            [39.68548014, -5.43404438],
                            [39.68569829, -5.43430275],
                            [39.68599708, -5.43454149],
                            [39.68598042, -5.43459916],
                            [39.68604193, -5.4348401],
                            [39.68609575, -5.43497594],
                            [39.68612906, -5.43515537],
                            [39.686106, -5.43533479],
                            [39.686147, -5.43544244],
                            [39.68621876, -5.43549371],
                            [39.68628027, -5.43546038],
                            [39.68634435, -5.43550396],
                            [39.68631615, -5.43558598],
                            [39.68627258, -5.43567057],
                            [39.68637254, -5.43578847],
                            [39.6864443, -5.43583461],
                            [39.686534, -5.43581667],
                            [39.68660576, -5.43572183],
                            [39.68672365, -5.43576284],
                            [39.68675697, -5.43592176],
                            [39.68686974, -5.43608067],
                            [39.68686717, -5.43613963],
                            [39.68690305, -5.43622165],
                            [39.68700557, -5.43623959],
                            [39.68707733, -5.43631649],
                            [39.68706964, -5.43641389],
                            [39.68720804, -5.43648822],
                            [39.68739769, -5.43653179],
                            [39.68754634, -5.43650873],
                            [39.6876386, -5.4366984],
                            [39.68774368, -5.43698548],
                            [39.68781032, -5.43707775],
                            [39.68780519, -5.43719053],
                            [39.68782826, -5.43729562],
                            [39.68804354, -5.4374853],
                            [39.68820244, -5.43759808],
                            [39.68838953, -5.43781595],
                            [39.68852023, -5.43787234],
                            [39.68863813, -5.43805689],
                            [39.68868682, -5.43817223],
                            [39.6887432, -5.43827219],
                            [39.68881753, -5.4383414],
                            [39.68889679, -5.43846625],
                            [39.68892004, -5.43850288],
                            [39.68896361, -5.43855414],
                            [39.68907638, -5.43856696],
                            [39.68910713, -5.43865411],
                            [39.6891994, -5.43876689],
                            [39.6893583, -5.43870537],
                            [39.68950438, -5.43868999],
                            [39.68955564, -5.4387105],
                            [39.6896069, -5.438731],
                            [39.68948388, -5.43878227],
                            [39.68938649, -5.43894118],
                            [39.68937367, -5.43896938],
                            [39.68939418, -5.43902577],
                            [39.68947363, -5.43913599],
                            [39.6896069, -5.43918469],
                            [39.68981962, -5.43918981],
                            [39.69000671, -5.43915137],
                            [39.6900349, -5.43901552],
                            [39.69006565, -5.43888992],
                            [39.69020405, -5.43887711],
                            [39.69031586, -5.43902937],
                            [39.69029888, -5.43909754],
                            [39.69016561, -5.43917443],
                            [39.69008872, -5.43921288],
                            [39.69000252, -5.43928234],
                            [39.68996058, -5.43943588],
                            [39.68988369, -5.43947945],
                            [39.68988369, -5.43958454],
                            [39.68994263, -5.4396922],
                            [39.69002977, -5.43975628],
                            [39.69012973, -5.43978191],
                            [39.69023993, -5.43986906],
                            [39.69034501, -5.43986393],
                            [39.69052697, -5.43977678],
                            [39.69065768, -5.43977165],
                            [39.69083196, -5.43975628],
                            [39.69095241, -5.43972296],
                            [39.69118051, -5.43960761],
                            [39.69117282, -5.43951277],
                            [39.6910498, -5.43943844],
                            [39.69095498, -5.4393231],
                            [39.69099342, -5.43917187],
                            [39.69112156, -5.43911548],
                            [39.69131634, -5.4390514],
                            [39.69147524, -5.43903602],
                            [39.69163158, -5.43897963],
                            [39.6917059, -5.43896169],
                            [39.69184174, -5.43899501],
                            [39.69186736, -5.43892837],
                            [39.69191862, -5.43893222],
                            [39.69196988, -5.43893606],
                            [39.69200063, -5.43897216],
                            [39.69199551, -5.43903346],
                            [39.69191093, -5.43909754],
                            [39.69192887, -5.43916418],
                            [39.69209802, -5.439177],
                            [39.69242864, -5.43918213],
                            [39.69269774, -5.43919751],
                            [39.69305911, -5.43921032],
                            [39.69326414, -5.43921289],
                            [39.69348711, -5.43921032],
                            [39.69357937, -5.43927184],
                            [39.6937229, -5.43936924],
                            [39.69383054, -5.43930516],
                            [39.69390999, -5.43926671],
                            [39.69411245, -5.43931798],
                            [39.69431749, -5.43935386],
                            [39.69446101, -5.43934105],
                            [39.69452764, -5.43942307],
                            [39.69481725, -5.43950509],
                            [39.6952868, -5.43959726],
                            [39.69536571, -5.43961274],
                            [39.69575783, -5.43973578],
                            [39.69614482, -5.43982293],
                            [39.69629439, -5.43987748],
                            [39.69636267, -5.43990239],
                            [39.69656001, -5.44001517],
                            [39.69662829, -5.44007141],
                            [39.69664715, -5.44008693],
                            [39.69673429, -5.4401587],
                            [39.69690805, -5.44033211],
                            [39.69696751, -5.44049961],
                            [39.69689319, -5.44052524],
                            [39.69679067, -5.44054318],
                            [39.69671635, -5.4405765],
                            [39.69661896, -5.44067134],
                            [39.6965472, -5.44075592],
                            [39.69646518, -5.4408687],
                            [39.6963883, -5.44095329],
                            [39.69631397, -5.44112758],
                            [39.69630372, -5.44137109],
                            [39.69630372, -5.44148387],
                            [39.69634729, -5.44156845],
                            [39.69656001, -5.44158127],
                            [39.69663177, -5.44160177],
                            [39.69668405, -5.44171425],
                            [39.69672403, -5.44184784],
                            [39.69672403, -5.44194524],
                            [39.69679067, -5.44201701],
                            [39.69686756, -5.44205545],
                            [39.69690344, -5.44206058],
                            [39.69700851, -5.44201444],
                            [39.69707002, -5.44195293],
                            [39.69709565, -5.44193242],
                            [39.69714178, -5.44193499],
                            [39.69720359, -5.44198956],
                            [39.69722636, -5.44205802],
                            [39.69714947, -5.44215029],
                            [39.69704952, -5.44223744],
                            [39.6970395, -5.44230898],
                            [39.69706404, -5.44238749],
                            [39.69709565, -5.44248863],
                            [39.6971956, -5.44255527],
                            [39.69730068, -5.44262704],
                            [39.69738782, -5.44268343],
                            [39.69751596, -5.44274495],
                            [39.69764155, -5.44262192],
                            [39.69784145, -5.44246556],
                            [39.69796191, -5.44236047],
                            [39.69800804, -5.44228871],
                            [39.69809262, -5.44211185],
                            [39.69810287, -5.44210159],
                            [39.69820538, -5.4420939],
                            [39.69825408, -5.44214517],
                            [39.6982592, -5.44227845],
                            [39.69815925, -5.44241174],
                            [39.6980798, -5.44252964],
                            [39.69804648, -5.44265524],
                            [39.69812081, -5.44277058],
                            [39.69822845, -5.44275008],
                            [39.69824383, -5.44262705],
                            [39.69834378, -5.44250145],
                            [39.69846936, -5.44240405],
                            [39.69852083, -5.44248277],
                            [39.69855957, -5.44257557],
                            [39.69858213, -5.44262961],
                            [39.69849499, -5.44277058],
                            [39.69847192, -5.44300896],
                            [39.69846423, -5.44312687],
                            [39.69853343, -5.44323964],
                            [39.69856413, -5.44340068],
                            [39.69864107, -5.44359593],
                            [39.69875896, -5.44369076],
                            [39.69888711, -5.4437651],
                            [39.69887429, -5.44378304],
                            [39.69880253, -5.44385481],
                            [39.69877947, -5.44395733],
                            [39.69875127, -5.44410344],
                            [39.6987564, -5.44431874],
                            [39.69889223, -5.44438795],
                            [39.69899731, -5.44444434],
                            [39.69912033, -5.44445459],
                            [39.69923566, -5.44445716],
                            [39.69923957, -5.44452336],
                            [39.69928692, -5.44463914],
                            [39.69929239, -5.44469596],
                            [39.69930998, -5.4447391],
                            [39.69938174, -5.44478268],
                            [39.69942275, -5.44477499],
                            [39.69947401, -5.44476986],
                            [39.69951758, -5.44475961],
                            [39.69954833, -5.44477755],
                            [39.69955602, -5.44486726],
                            [39.6996406, -5.44491853],
                            [39.69971492, -5.44486214],
                            [39.6997713, -5.44476217],
                            [39.69983281, -5.44463914],
                            [39.69989432, -5.44456994],
                            [39.69995745, -5.44460833],
                            [39.70000965, -5.44472373],
                            [39.69999043, -5.44484035],
                            [39.69997121, -5.44495698],
                            [39.69991979, -5.44505621],
                            [39.69994814, -5.44512102],
                            [39.70002503, -5.44515947],
                            [39.70005312, -5.44526829],
                            [39.70010448, -5.44538503],
                            [39.70020927, -5.44539482],
                            [39.70023006, -5.44544142],
                            [39.70026081, -5.44561571],
                            [39.70031207, -5.44577719],
                            [39.7004069, -5.44588997],
                            [39.7005658, -5.44587459],
                            [39.70070419, -5.44579514],
                            [39.70075801, -5.44579514],
                            [39.70088872, -5.44582077],
                            [39.7010143, -5.44589766],
                            [39.70113988, -5.44595149],
                            [39.70123471, -5.44602839],
                            [39.70129878, -5.44605402],
                            [39.70140642, -5.44603864],
                            [39.70150125, -5.44599763],
                            [39.70154226, -5.44591817],
                            [39.70156789, -5.44576694],
                            [39.70159095, -5.44572593],
                            [39.70170748, -5.44569682],
                            [39.70174985, -5.44577719],
                            [39.70186518, -5.44589254],
                            [39.70189337, -5.44599507],
                            [39.70188056, -5.44616167],
                            [39.7018985, -5.44623344],
                            [39.70185493, -5.44630265],
                            [39.70178573, -5.44633084],
                            [39.70173447, -5.44633084],
                            [39.70165502, -5.44633084],
                            [39.70160377, -5.44636929],
                            [39.70162939, -5.44648207],
                            [39.70162171, -5.44655127],
                            [39.7015602, -5.44659997],
                            [39.70153969, -5.44673839],
                            [39.70154738, -5.4468281],
                            [39.70160633, -5.44689218],
                            [39.70164733, -5.44690756],
                            [39.70167809, -5.44691781],
                            [39.70175515, -5.44691],
                            [39.70177548, -5.44695113],
                            [39.70176779, -5.44697676],
                            [39.70174216, -5.44702034],
                            [39.70170884, -5.44708954],
                            [39.70168834, -5.44720745],
                            [39.70171141, -5.4473433],
                            [39.70174216, -5.44737149],
                            [39.70171141, -5.44740738],
                            [39.70166777, -5.44748795],
                            [39.70170372, -5.44756373],
                            [39.70167553, -5.44758423],
                            [39.70165689, -5.44767051],
                            [39.70171141, -5.44778673],
                            [39.7017271, -5.44789047],
                            [39.70177804, -5.44799947],
                            [39.70178829, -5.44810969],
                            [39.70179085, -5.44822503],
                            [39.70176523, -5.44832756],
                            [39.70172422, -5.44840701],
                            [39.7017114, -5.44850954],
                            [39.70175754, -5.44854799],
                            [39.70184467, -5.4485403],
                            [39.70194206, -5.44850698],
                            [39.70205483, -5.44842239],
                            [39.70215735, -5.44839164],
                            [39.70224705, -5.44832499],
                            [39.70229062, -5.44827886],
                            [39.70231374, -5.44827507],
                            [39.70234188, -5.44833268],
                            [39.70234188, -5.44842239],
                            [39.70234956, -5.44850185],
                            [39.70227524, -5.44860438],
                            [39.70215222, -5.44862232],
                            [39.70205739, -5.44866077],
                            [39.70189337, -5.44871972],
                            [39.70171653, -5.44875817],
                            [39.70161658, -5.44877355],
                            [39.70157045, -5.44883506],
                            [39.70160376, -5.44908882],
                            [39.70161401, -5.44915802],
                            [39.70166773, -5.44936166],
                            [39.70172394, -5.44949211],
                            [39.70177547, -5.44961171],
                            [39.70183698, -5.44969117],
                            [39.701919, -5.44973218],
                            [39.70204566, -5.44977897],
                            [39.70207021, -5.4498347],
                            [39.7020292, -5.44984496],
                            [39.7020292, -5.44995261],
                            [39.70207533, -5.44998337],
                            [39.70204458, -5.45001156],
                            [39.70196257, -5.4500859],
                            [39.70188824, -5.45017817],
                            [39.70182929, -5.4502525],
                            [39.70176779, -5.45043449],
                            [39.70172422, -5.45060622],
                            [39.70167039, -5.45073694],
                            [39.7016909, -5.45088817],
                            [39.70174984, -5.45108553],
                            [39.70177547, -5.45121626],
                            [39.70177804, -5.45132904],
                            [39.70174984, -5.45139312],
                            [39.70166271, -5.45142131],
                            [39.70160376, -5.4514777],
                            [39.70155506, -5.45159561],
                            [39.7014628, -5.45169044],
                            [39.7014591, -5.45177576],
                            [39.70149355, -5.4518673],
                            [39.70158069, -5.45198521],
                            [39.70160632, -5.45211593],
                            [39.70162395, -5.45228009],
                            [39.70165758, -5.45236969],
                            [39.70166014, -5.4525081],
                            [39.70163195, -5.45255936],
                            [39.70155506, -5.45264907],
                            [39.70147305, -5.45268752],
                            [39.70135516, -5.45273878],
                            [39.70121163, -5.4528003],
                            [39.70116294, -5.45283875],
                            [39.70112193, -5.45291564],
                            [39.7010758, -5.45297972],
                            [39.70111168, -5.45303098],
                            [39.70129108, -5.45306174],
                            [39.70136028, -5.45311557],
                            [39.70160888, -5.45309763],
                            [39.70187799, -5.45307712],
                            [39.70199588, -5.45306943],
                            [39.70210096, -5.45308994],
                            [39.70221116, -5.45312839],
                            [39.70233674, -5.45315402],
                            [39.70248283, -5.45313864],
                            [39.70258278, -5.45312839],
                            [39.70271861, -5.45317709],
                            [39.70286982, -5.45320528],
                            [39.70303128, -5.4532181],
                            [39.7031543, -5.45317965],
                            [39.70325169, -5.45315402],
                            [39.70332858, -5.45321041],
                            [39.70338496, -5.45325142],
                            [39.70353361, -5.45328474],
                            [39.7036515, -5.45343853],
                            [39.70371558, -5.45349749],
                            [39.70382065, -5.45351287],
                            [39.70394624, -5.4535872],
                            [39.70403337, -5.45363334],
                            [39.70415896, -5.45357951],
                            [39.7043563, -5.45349492],
                            [39.70449469, -5.45342316],
                            [39.70466897, -5.45339496],
                            [39.70493807, -5.45348211],
                            [39.70510466, -5.45356669],
                            [39.70530969, -5.45359745],
                            [39.70552241, -5.45356413],
                            [39.70566593, -5.45355644],
                            [39.70576076, -5.45351543],
                            [39.70576589, -5.45344879],
                            [39.70573257, -5.45336933],
                            [39.70570181, -5.45328987],
                            [39.70565056, -5.45321041],
                            [39.7056198, -5.45311301],
                            [39.70567619, -5.45303099],
                            [39.70576589, -5.45297716],
                            [39.70592222, -5.45288489],
                            [39.70598373, -5.4528567],
                            [39.70606575, -5.45276955],
                            [39.70616314, -5.45271828],
                            [39.70627847, -5.45265677],
                            [39.70645018, -5.45260294],
                            [39.70653475, -5.452585],
                            [39.70670903, -5.45257731],
                            [39.70681923, -5.45255168],
                            [39.70697813, -5.45249785],
                            [39.7071806, -5.45249016],
                            [39.707319, -5.45242352],
                            [39.70742664, -5.45231843],
                            [39.70743177, -5.45225948],
                            [39.70750865, -5.45221078],
                            [39.7076086, -5.45216977],
                            [39.70772137, -5.45218771],
                            [39.70788027, -5.45215951],
                            [39.70794947, -5.45212619],
                            [39.70799304, -5.45208775],
                            [39.70805967, -5.45204161],
                            [39.70813143, -5.45202367],
                            [39.70824164, -5.45203136],
                            [39.70827752, -5.45200572],
                            [39.70832621, -5.45193396],
                            [39.70834928, -5.45189294],
                            [39.70839541, -5.45178529],
                            [39.70842104, -5.45168789],
                            [39.70849793, -5.45159305],
                            [39.70858507, -5.45153923],
                            [39.7086722, -5.4515136],
                            [39.70867989, -5.45148027],
                            [39.70868758, -5.45141876],
                            [39.7087209, -5.45133674],
                            [39.70874397, -5.45125728],
                            [39.70871834, -5.45120089],
                            [39.70867733, -5.45114962],
                            [39.70861838, -5.45117269],
                            [39.708562, -5.45113168],
                            [39.7084928, -5.45112656],
                            [39.70843642, -5.45105991],
                            [39.70846205, -5.45092406],
                            [39.70850562, -5.4508241],
                            [39.70855688, -5.45073952],
                            [39.70862864, -5.450678],
                            [39.70870296, -5.45063186],
                            [39.70880291, -5.45056522],
                            [39.70887467, -5.45047551],
                            [39.70894387, -5.45035504],
                            [39.7090387, -5.4502884],
                            [39.70914634, -5.45025251],
                            [39.70918991, -5.45025251],
                            [39.70932831, -5.45027046],
                            [39.7094257, -5.45027046],
                            [39.70955128, -5.45031147],
                            [39.70967173, -5.45037555],
                            [39.70977681, -5.45035248],
                            [39.70980244, -5.45029352],
                            [39.70979219, -5.45027302],
                            [39.70981525, -5.4502115],
                            [39.70987933, -5.45017049],
                            [39.70993827, -5.45010385],
                            [39.70994853, -5.45002439],
                            [39.70996134, -5.44997313],
                            [39.7098537, -5.44992186],
                            [39.70988958, -5.44984241],
                            [39.7099639, -5.44981421],
                            [39.71005104, -5.44978089],
                            [39.71006642, -5.44973475],
                            [39.71007154, -5.44968605],
                            [39.71007411, -5.44953995],
                            [39.71006642, -5.44942461],
                            [39.71018175, -5.44946562],
                            [39.71026889, -5.44944511],
                            [39.71045341, -5.44939898],
                            [39.71056874, -5.44936053],
                            [39.71064819, -5.44939129],
                            [39.71070202, -5.44939385],
                            [39.71080453, -5.44937847],
                            [39.71089679, -5.44931183],
                            [39.71094036, -5.44922981],
                            [39.71096087, -5.44919136],
                            [39.71097368, -5.44911959],
                            [39.71096087, -5.44903501],
                            [39.71098239, -5.44892997],
                            [39.71104032, -5.448994],
                            [39.71116334, -5.4490914],
                            [39.71121972, -5.44909652],
                            [39.71125816, -5.44916829],
                            [39.71131711, -5.44926569],
                            [39.71137605, -5.44928364],
                            [39.71142219, -5.44927851],
                            [39.71147088, -5.44925544],
                            [39.71157083, -5.44921443],
                            [39.71159646, -5.44915291],
                            [39.7116426, -5.4490914],
                            [39.71169642, -5.44900681],
                            [39.71169642, -5.44890172],
                            [39.71175024, -5.44882226],
                            [39.71177843, -5.44876844],
                            [39.71181687, -5.44869923],
                            [39.71185275, -5.44868129],
                            [39.71191426, -5.44868898],
                            [39.71199115, -5.44867104],
                            [39.71208598, -5.44864028],
                            [39.71213904, -5.44867178],
                            [39.7121808, -5.44871461],
                            [39.71214749, -5.44876587],
                            [39.71210904, -5.44884277],
                            [39.71210648, -5.44897862],
                            [39.71213211, -5.44906833],
                            [39.7121116, -5.44921956],
                            [39.7120706, -5.44934515],
                            [39.7121116, -5.44947587],
                            [39.71217311, -5.44949894],
                            [39.7123192, -5.44949125],
                            [39.7124499, -5.44943486],
                            [39.71252935, -5.44937078],
                            [39.71259086, -5.44927851],
                            [39.712637, -5.44919393],
                            [39.71264981, -5.44910678],
                            [39.71265237, -5.44904782],
                            [39.71262931, -5.44895555],
                            [39.71259599, -5.44885559],
                            [39.71258574, -5.44877356],
                            [39.71262675, -5.44873255],
                            [39.71269084, -5.44874792],
                            [39.71274208, -5.4487992],
                            [39.7128164, -5.44879663],
                            [39.71289329, -5.44877613],
                            [39.71294967, -5.44876844],
                            [39.71300862, -5.44873768],
                            [39.71302399, -5.44879663],
                            [39.71294967, -5.44887353],
                            [39.7129266, -5.44894786],
                            [39.71292404, -5.4490914],
                            [39.71290354, -5.4491606],
                            [39.71285997, -5.44924006],
                            [39.71287534, -5.44931952],
                            [39.71292404, -5.44940923],
                            [39.71297017, -5.44951432],
                            [39.71305218, -5.44956302],
                            [39.71318802, -5.44958609],
                            [39.71327515, -5.44958353],
                            [39.71332641, -5.44951176],
                            [39.71335204, -5.44942974],
                            [39.71338023, -5.44943487],
                            [39.71340586, -5.44943487],
                            [39.71351863, -5.4494323],
                            [39.71362883, -5.4494118],
                            [39.71370572, -5.4493349],
                            [39.71375442, -5.44928876],
                            [39.71380311, -5.44918624],
                            [39.71380055, -5.44906064],
                            [39.71382361, -5.44890173],
                            [39.71383899, -5.44879151],
                            [39.71384155, -5.44869667],
                            [39.71381336, -5.44855313],
                            [39.71384412, -5.44855826],
                            [39.71393382, -5.44857108],
                            [39.7139492, -5.44852238],
                            [39.71394151, -5.44846086],
                            [39.71392869, -5.44835577],
                            [39.71390306, -5.44828144],
                            [39.71382618, -5.44825068],
                            [39.71381849, -5.44817635],
                            [39.71372879, -5.4479149],
                            [39.71373135, -5.4474766],
                            [39.71373904, -5.44705624],
                            [39.71373904, -5.44683837],
                            [39.71373648, -5.44648978],
                            [39.71373648, -5.4464462],
                            [39.71375186, -5.44611299],
                            [39.71378005, -5.44598227],
                            [39.71374929, -5.44581822],
                            [39.71378261, -5.44567212],
                            [39.71385694, -5.4454158],
                            [39.71390563, -5.44532609],
                            [39.71391332, -5.44514667],
                            [39.71393639, -5.44499288],
                            [39.71388769, -5.44482115],
                            [39.71385181, -5.44470837],
                            [39.71378261, -5.44454689],
                            [39.71371854, -5.44445718],
                            [39.71368779, -5.44434183],
                            [39.71364678, -5.44419317],
                            [39.71355195, -5.44407783],
                            [39.71347763, -5.44398043],
                            [39.71339818, -5.44393172],
                            [39.71324953, -5.44391122],
                            [39.71310345, -5.44388815],
                            [39.71302656, -5.44381894],
                            [39.71299068, -5.44375743],
                            [39.71299837, -5.44369335],
                            [39.71304963, -5.44361645],
                            [39.71306757, -5.44351905],
                            [39.71299068, -5.4435088],
                            [39.71290098, -5.4434806],
                            [39.71288304, -5.4434114],
                            [39.71272671, -5.44328324],
                            [39.712637, -5.44323454],
                            [39.71256524, -5.44323454],
                            [39.71248323, -5.44325248],
                            [39.71241403, -5.44322172],
                            [39.71239609, -5.44316277],
                            [39.71234484, -5.44311663],
                            [39.71224488, -5.44303205],
                            [39.71208342, -5.44290645],
                            [39.71190402, -5.44278598],
                            [39.71184507, -5.44271934],
                            [39.71172462, -5.44266039],
                            [39.71161441, -5.44260656],
                            [39.71156059, -5.44249378],
                            [39.71152984, -5.44240151],
                            [39.71150934, -5.44235793],
                            [39.7114427, -5.44230923],
                            [39.71142604, -5.4422836],
                            [39.71140938, -5.44225797],
                            [39.71131456, -5.44228104],
                            [39.71127099, -5.44224002],
                            [39.71125048, -5.44219389],
                            [39.7111736, -5.4420888],
                            [39.71112747, -5.44199652],
                            [39.71106852, -5.4419145],
                            [39.71099676, -5.44182223],
                            [39.71089681, -5.44170945],
                            [39.71080198, -5.4416428],
                            [39.71076097, -5.44159667],
                            [39.71071228, -5.44157616],
                            [39.71067383, -5.44158129],
                            [39.71063027, -5.44156591],
                            [39.71048674, -5.44149414],
                            [39.71040729, -5.44154028],
                            [39.71033297, -5.44158898],
                            [39.7103381, -5.44162999],
                            [39.71037654, -5.44170432],
                            [39.71034578, -5.4417402],
                            [39.7103381, -5.44177353],
                            [39.7103381, -5.4418376],
                            [39.7103381, -5.44188631],
                            [39.71033041, -5.44191963],
                            [39.71029709, -5.44196576],
                            [39.7102894, -5.44200421],
                            [39.71032784, -5.44207854],
                            [39.71033297, -5.44215031],
                            [39.71024327, -5.44207085],
                            [39.71015869, -5.44198114],
                            [39.71012538, -5.44185555],
                            [39.71007412, -5.44179916],
                            [39.71002799, -5.4417684],
                            [39.70995623, -5.44172482],
                            [39.70998698, -5.44166587],
                            [39.70993572, -5.44159923],
                            [39.70985115, -5.44157616],
                            [39.70983064, -5.44149926],
                            [39.70988703, -5.441448],
                            [39.70992291, -5.44132497],
                            [39.70985115, -5.44115324],
                            [39.7097717, -5.44082515],
                            [39.70969481, -5.44061497],
                            [39.7096128, -5.44031251],
                            [39.7094539, -5.44006132],
                            [39.70935139, -5.43993573],
                            [39.70911304, -5.43976656],
                            [39.7089362, -5.4394436],
                            [39.70881831, -5.43934363],
                            [39.70873629, -5.43918215],
                            [39.70854597, -5.43896801],
                            [39.70849026, -5.43890533],
                            [39.7082801, -5.43883612],
                            [39.70813402, -5.43875154],
                            [39.7078726, -5.43866695],
                            [39.70757531, -5.43846702],
                            [39.70734721, -5.43817482],
                            [39.70730851, -5.43814455],
                            [39.70714731, -5.43801847],
                            [39.7070166, -5.43792619],
                            [39.70686795, -5.43788262],
                            [39.70671418, -5.43784673],
                            [39.70659116, -5.43792363],
                            [39.70652709, -5.4379954],
                            [39.7064297, -5.4379954],
                            [39.7062913, -5.43803641],
                            [39.70621185, -5.43817226],
                            [39.70614265, -5.43817995],
                            [39.70599691, -5.43810109],
                            [39.70598632, -5.43809536],
                            [39.70589405, -5.43803641],
                            [39.70576335, -5.43795695],
                            [39.70564033, -5.43791594],
                            [39.70559932, -5.4379672],
                            [39.70554294, -5.43802615],
                            [39.70549937, -5.43796464],
                            [39.70551218, -5.43788774],
                            [39.70556088, -5.43772626],
                            [39.70557369, -5.43764937],
                            [39.70558394, -5.4375007],
                            [39.70560957, -5.43733922],
                            [39.70559932, -5.43718031],
                            [39.70555319, -5.43697525],
                            [39.70553013, -5.43687785],
                            [39.70552244, -5.43675482],
                            [39.7054558, -5.43661128],
                            [39.70534047, -5.43644211],
                            [39.70523283, -5.43626012],
                            [39.70518157, -5.43611915],
                            [39.70510469, -5.43594229],
                            [39.70504574, -5.43582438],
                            [39.70492529, -5.4356629],
                            [39.70487659, -5.43562189],
                            [39.7047792, -5.43556038],
                            [39.70472025, -5.43548604],
                            [39.70467669, -5.43543991],
                            [39.70460236, -5.43543734],
                            [39.70452291, -5.43544247],
                            [39.70448191, -5.43547835],
                            [39.70442809, -5.43553218],
                            [39.70437426, -5.43549373],
                            [39.70431276, -5.43550911],
                            [39.70425125, -5.43553731],
                            [39.70414617, -5.43558088],
                            [39.70406928, -5.43565778],
                            [39.70406928, -5.43574749],
                            [39.70404109, -5.43579619],
                            [39.70398983, -5.43582695],
                            [39.70389244, -5.43583207],
                            [39.70372842, -5.43592435],
                            [39.70363615, -5.43606532],
                            [39.7036054, -5.43613709],
                            [39.70361821, -5.43628832],
                            [39.70366178, -5.43641391],
                            [39.70367972, -5.43647543],
                            [39.70375148, -5.43647799],
                            [39.70375404, -5.43652157],
                            [39.70370279, -5.43657796],
                            [39.7036054, -5.43657027],
                            [39.70352338, -5.436519],
                            [39.70345162, -5.43648312],
                            [39.70329529, -5.43645749],
                            [39.70317739, -5.4364498],
                            [39.70306975, -5.43645236],
                            [39.70292879, -5.43646517],
                            [39.70280321, -5.43646005],
                            [39.70272376, -5.43647799],
                            [39.70264175, -5.43654976],
                            [39.70251617, -5.43655745],
                            [39.70239315, -5.43656001],
                            [39.70227443, -5.43653755],
                            [39.7022035, -5.43652413],
                            [39.70202153, -5.43651387],
                            [39.70198664, -5.43651169],
                            [39.70185751, -5.43650362],
                            [39.70173449, -5.43652669],
                            [39.70158751, -5.43655651],
                            [39.70155765, -5.43656257],
                            [39.70139106, -5.43657795],
                            [39.70130136, -5.43662665],
                            [39.70124241, -5.43665741],
                            [39.70118859, -5.43670611],
                            [39.70111427, -5.43675994],
                            [39.70108608, -5.43680607],
                            [39.70108095, -5.43689322],
                            [39.70102969, -5.43693167],
                            [39.70096562, -5.43691373],
                            [39.7009323, -5.43694192],
                            [39.70088361, -5.43698293],
                            [39.70082979, -5.43699575],
                            [39.70084773, -5.43696499],
                            [39.70085983, -5.43694823],
                            [39.70091437, -5.43687271],
                            [39.70091985, -5.43686323],
                            [39.7009251, -5.43685416],
                            [39.70092917, -5.43684714],
                            [39.70093576, -5.43683576],
                            [39.70094054, -5.43682749],
                            [39.70094804, -5.43681453],
                            [39.70095431, -5.4368037],
                            [39.70096021, -5.43679354],
                            [39.70096464, -5.43678585],
                            [39.70097247, -5.43677234],
                            [39.70098031, -5.43675879],
                            [39.70098146, -5.43675681],
                            [39.70098916, -5.4367435],
                            [39.70099526, -5.43673296],
                            [39.70099849, -5.43672739],
                            [39.70099895, -5.4367266],
                            [39.70100088, -5.43672259],
                            [39.70100152, -5.43672127],
                            [39.70103482, -5.43665228],
                            [39.7010707, -5.43656514],
                            [39.70106814, -5.43650618],
                            [39.70108864, -5.43639853],
                            [39.7011399, -5.43632676],
                            [39.70111171, -5.43624218],
                            [39.70109663, -5.4362306],
                            [39.7010015, -5.43615759],
                            [39.70101688, -5.43603456],
                            [39.70106558, -5.43590384],
                            [39.70107326, -5.43566546],
                            [39.7010502, -5.43545272],
                            [39.7010297, -5.43523485],
                            [39.70101688, -5.43505542],
                            [39.70104251, -5.43498622],
                            [39.70112452, -5.43497853],
                            [39.70120141, -5.43492727],
                            [39.70124242, -5.43476579],
                            [39.70123216, -5.43468889],
                            [39.7011194, -5.43455561],
                            [39.70118347, -5.43441463],
                            [39.7012091, -5.43427109],
                            [39.70122191, -5.43417882],
                            [39.70122191, -5.43405322],
                            [39.70125779, -5.43390712],
                            [39.70128599, -5.43371232],
                            [39.70128599, -5.4335534],
                            [39.70125523, -5.43345857],
                            [39.70118347, -5.43333297],
                            [39.70110146, -5.43323301],
                            [39.7009605, -5.43306127],
                            [39.70084261, -5.43297412],
                            [39.70084645, -5.43294209],
                            [39.70081409, -5.43291761],
                            [39.7008503, -5.43291005],
                            [39.70093487, -5.43291005],
                            [39.70108096, -5.43295875],
                            [39.7011886, -5.43299463],
                            [39.70130905, -5.43297669],
                            [39.70148589, -5.43298694],
                            [39.70167042, -5.43300488],
                            [39.70183188, -5.43297413],
                            [39.70194465, -5.4328716],
                            [39.70208048, -5.4327742],
                            [39.70217275, -5.4326973],
                            [39.70223426, -5.43264604],
                            [39.70231071, -5.43255449],
                            [39.7023419, -5.43256658],
                            [39.70241366, -5.43251276],
                            [39.70252386, -5.43247431],
                            [39.70262638, -5.43238716],
                            [39.70273658, -5.43232564],
                            [39.70279809, -5.43227694],
                            [39.70285191, -5.43222824],
                            [39.70291086, -5.43219749],
                            [39.70303388, -5.43217442],
                            [39.70307745, -5.43220774],
                            [39.70316715, -5.43213341],
                            [39.70340037, -5.43197449],
                            [39.70351314, -5.43187196],
                            [39.70364128, -5.43175149],
                            [39.70370023, -5.43162077],
                            [39.70373355, -5.43147723],
                            [39.70377712, -5.43134139],
                            [39.703813, -5.43118503],
                            [39.70383094, -5.431062],
                            [39.70381556, -5.4308672],
                            [39.70382325, -5.43070315],
                            [39.70380018, -5.43053911],
                            [39.70373355, -5.43042377],
                            [39.70360541, -5.43036225],
                            [39.70344394, -5.4302264],
                            [39.70334655, -5.43011619],
                            [39.70320303, -5.43002647],
                            [39.70309027, -5.42992651],
                            [39.70298262, -5.42988037],
                            [39.70290061, -5.42983167],
                            [39.70284679, -5.42974709],
                            [39.7027981, -5.4296907],
                            [39.70270071, -5.42967532],
                            [39.70273146, -5.42961124],
                            [39.70281347, -5.42959073],
                            [39.70285192, -5.42962918],
                            [39.70294162, -5.42969839],
                            [39.70308001, -5.42979835],
                            [39.70313896, -5.42983936],
                            [39.703243, -5.42991902],
                            [39.70336962, -5.42993164],
                            [39.70348495, -5.42997009],
                            [39.70359515, -5.43002391],
                            [39.70378481, -5.43009824],
                            [39.70393089, -5.43016232],
                            [39.70397959, -5.43013669],
                            [39.70401747, -5.43011105],
                            [39.70406416, -5.43011619],
                            [39.70417693, -5.43018027],
                            [39.70426063, -5.43020669],
                            [39.70438532, -5.43022115],
                            [39.70443066, -5.4302264],
                            [39.704669, -5.43020846],
                            [39.70483047, -5.43016233],
                            [39.70505856, -5.43005211],
                            [39.70525078, -5.4299342],
                            [39.70545837, -5.42980092],
                            [39.70562753, -5.42966507],
                            [39.70569416, -5.42951128],
                            [39.70572492, -5.42936005],
                            [39.70572235, -5.42916525],
                            [39.7057121, -5.42903197],
                            [39.70569672, -5.4288833],
                            [39.70574542, -5.42873207],
                            [39.7057608, -5.42862186],
                            [39.70574542, -5.42851164],
                            [39.70565572, -5.42837579],
                            [39.70559934, -5.42826045],
                            [39.7055122, -5.42818355],
                            [39.70547632, -5.42812973],
                            [39.70550195, -5.4281041],
                            [39.7055506, -5.4281392],
                            [39.70562496, -5.42813485],
                            [39.70568904, -5.42809128],
                            [39.70575567, -5.42806565],
                            [39.70586755, -5.42809954],
                            [39.7059197, -5.42809641],
                            [39.70597864, -5.42811948],
                            [39.70608116, -5.42818868],
                            [39.70614779, -5.42815023],
                            [39.70623013, -5.42811234],
                            [39.70637333, -5.4281041],
                            [39.70641177, -5.42806052],
                            [39.70635795, -5.42797337],
                            [39.70614523, -5.42780164],
                            [39.70595045, -5.42772218],
                            [39.70587869, -5.42772218],
                            [39.70579668, -5.42772218],
                            [39.70572492, -5.42773756],
                            [39.70564803, -5.42773756],
                            [39.70557627, -5.4277068],
                            [39.70548401, -5.42769142],
                            [39.70538662, -5.42770168],
                            [39.70531229, -5.42774269],
                            [39.70529692, -5.4275889],
                            [39.70532148, -5.42747433],
                            [39.7053738, -5.42746843],
                            [39.70547376, -5.42738897],
                            [39.70557371, -5.42731464],
                            [39.70567879, -5.42727619],
                            [39.7057403, -5.42736334],
                            [39.70581038, -5.42743276],
                            [39.70585819, -5.42742742],
                            [39.70593508, -5.42744023],
                            [39.7060299, -5.42750431],
                            [39.70610423, -5.42747612],
                            [39.70615548, -5.42738128],
                            [39.70620418, -5.42729926],
                            [39.7062093, -5.42722749],
                            [39.70615292, -5.42713265],
                            [39.70617342, -5.42704807],
                            [39.70621956, -5.42706345],
                            [39.70629901, -5.42707626],
                            [39.7063272, -5.42703013],
                            [39.70636052, -5.42687121],
                            [39.70637846, -5.42674049],
                            [39.7063272, -5.42668153],
                            [39.70653766, -5.42650006],
                            [39.70652044, -5.42643116],
                            [39.70654914, -5.42635652],
                            [39.70662952, -5.42621873],
                            [39.70662952, -5.42612112],
                            [39.70664674, -5.42598907],
                            [39.70680748, -5.4259661],
                            [39.70703138, -5.42575366],
                            [39.70708879, -5.42562735],
                            [39.7071462, -5.42550104],
                            [39.70708879, -5.42541492],
                            [39.70715768, -5.42538621],
                            [39.70738157, -5.4253575],
                            [39.7078949, -5.425006],
                            [39.70801881, -5.42492115],
                            [39.70844694, -5.42460105],
                            [39.7084722, -5.42458217],
                            [39.70861012, -5.42447905],
                            [39.70887182, -5.42420798],
                            [39.70909235, -5.42397954],
                            [39.70942532, -5.42368098],
                            [39.70947125, -5.42358912],
                            [39.70946551, -5.42339391],
                            [39.70958607, -5.4231585],
                            [39.70977552, -5.42292884],
                            [39.70985015, -5.4228255],
                            [39.71013719, -5.42242359],
                            [39.71031516, -5.422148],
                            [39.71036109, -5.42198723],
                            [39.71031516, -5.42182647],
                            [39.71020609, -5.42152217],
                            [39.71000516, -5.42109156],
                            [39.70991904, -5.42089635],
                            [39.70986382, -5.4207382],
                            [39.70979274, -5.42053463],
                            [39.70969515, -5.42042554],
                            [39.7096033, -5.42038535],
                            [39.70949996, -5.42039109],
                            [39.70934496, -5.42045425],
                            [39.70918421, -5.42059779],
                            [39.70911398, -5.4206659],
                            [39.70899476, -5.42078151],
                            [39.70882828, -5.42092505],
                            [39.70873642, -5.42095376],
                            [39.70861587, -5.42098247],
                            [39.70856133, -5.42098247],
                            [39.70850679, -5.42098247],
                            [39.70833456, -5.42097098],
                            [39.70824271, -5.42091931],
                            [39.70824271, -5.42081022],
                            [39.70824681, -5.42078821],
                            [39.70830586, -5.42047147],
                            [39.70825419, -5.4203509],
                            [39.70832386, -5.4204036],
                            [39.70836378, -5.42049144],
                            [39.70836378, -5.42054863],
                            [39.70834695, -5.42061088],
                            [39.7083398, -5.42067272],
                            [39.70837319, -5.4207467],
                            [39.7083937, -5.42079214],
                            [39.70841592, -5.42084136],
                            [39.70845966, -5.42085482],
                            [39.70850844, -5.42084304],
                            [39.70857068, -5.42081781],
                            [39.70860264, -5.42078753],
                            [39.70865815, -5.42075724],
                            [39.70870693, -5.42072528],
                            [39.7087473, -5.42070509],
                            [39.70873385, -5.42066808],
                            [39.70877758, -5.42062939],
                            [39.70880954, -5.42059406],
                            [39.70887515, -5.42054359],
                            [39.70890374, -5.42049144],
                            [39.7089643, -5.4204191],
                            [39.70896935, -5.42036358],
                            [39.70899786, -5.42029128],
                            [39.70901783, -5.42033327],
                            [39.70904504, -5.4203905],
                            [39.709077, -5.42042078],
                            [39.70911569, -5.42041573],
                            [39.70917457, -5.42039554],
                            [39.70919812, -5.42034844],
                            [39.70921326, -5.42031479],
                            [39.7092469, -5.42032993],
                            [39.7092755, -5.42031311],
                            [39.70932428, -5.42028956],
                            [39.70937811, -5.42026432],
                            [39.70944035, -5.42020881],
                            [39.70950763, -5.42019871],
                            [39.70963211, -5.42020712],
                            [39.7096994, -5.42024245],
                            [39.70977005, -5.42028956],
                            [39.70982388, -5.42034339],
                            [39.70987434, -5.42039891],
                            [39.70992211, -5.42044669],
                            [39.70993321, -5.42045779],
                            [39.709982, -5.42051836],
                            [39.71000386, -5.42059238],
                            [39.71007115, -5.42059238],
                            [39.71012498, -5.42053854],
                            [39.71016871, -5.42053181],
                            [39.7101889, -5.42055537],
                            [39.71018217, -5.42063275],
                            [39.71019226, -5.42068322],
                            [39.71022591, -5.42069332],
                            [39.71022778, -5.42077501],
                            [39.71019899, -5.42078753],
                            [39.71012498, -5.42078753],
                            [39.71013507, -5.42091202],
                            [39.71015189, -5.42102978],
                            [39.710236, -5.42118456],
                            [39.71039412, -5.42121148],
                            [39.71042776, -5.42132924],
                            [39.71042103, -5.42145373],
                            [39.71052869, -5.4216758],
                            [39.71045804, -5.42176665],
                            [39.71048495, -5.42191469],
                            [39.7105556, -5.42201227],
                            [39.71059598, -5.42215022],
                            [39.71060607, -5.42227807],
                            [39.71052533, -5.42243285],
                            [39.71039412, -5.42259772],
                            [39.71031337, -5.4227424],
                            [39.71021917, -5.42286689],
                            [39.71017207, -5.4229611],
                            [39.71006442, -5.42319999],
                            [39.7100476, -5.42335477],
                            [39.70999713, -5.42335813],
                            [39.70998637, -5.42344142],
                            [39.70990293, -5.42348599],
                            [39.70977509, -5.42366095],
                            [39.70955977, -5.42393349],
                            [39.70934109, -5.42421275],
                            [39.70922671, -5.42433052],
                            [39.70903158, -5.42457614],
                            [39.70898448, -5.42471745],
                            [39.70902822, -5.4247982],
                            [39.70893738, -5.42480493],
                            [39.70873552, -5.4249799],
                            [39.70859392, -5.42510533],
                            [39.70826452, -5.42539711],
                            [39.70797183, -5.42564273],
                            [39.70768923, -5.42596574],
                            [39.707363, -5.4262524],
                            [39.7070702, -5.42642333],
                            [39.70685489, -5.42666559],
                            [39.70682797, -5.42689439],
                            [39.70685489, -5.4271501],
                            [39.70692217, -5.42736544],
                            [39.70692217, -5.42774228],
                            [39.70704328, -5.42797108],
                            [39.70705674, -5.42836138],
                            [39.70709711, -5.42876514],
                            [39.7070702, -5.42911506],
                            [39.70712402, -5.42927657],
                            [39.70717785, -5.42942461],
                            [39.70709711, -5.42969378],
                            [39.70704185, -5.43008755],
                            [39.70695784, -5.43014727],
                            [39.70689525, -5.43019175],
                            [39.70674722, -5.43043401],
                            [39.70672031, -5.43059551],
                            [39.70671358, -5.43087142],
                            [39.70671022, -5.4310632],
                            [39.70683806, -5.43149724],
                            [39.70711056, -5.43181352],
                            [39.7074369, -5.43197166],
                            [39.70763876, -5.43196493],
                            [39.70780697, -5.43190437],
                            [39.70802228, -5.43177315],
                            [39.70826788, -5.43160491],
                            [39.70885663, -5.43115069],
                            [39.70895419, -5.43081422],
                            [39.70910559, -5.43047775],
                            [39.70915269, -5.43032634],
                            [39.70911568, -5.43020185],
                            [39.70912577, -5.43014802],
                            [39.7091796, -5.43010428],
                            [39.70925362, -5.4299024],
                            [39.7093209, -5.42966014],
                            [39.70933099, -5.42954574],
                            [39.70930408, -5.4292833],
                            [39.70923679, -5.4289973],
                            [39.70912241, -5.4287214],
                            [39.70898784, -5.42844886],
                            [39.70891719, -5.42830418],
                            [39.7088129, -5.4282167],
                            [39.70883981, -5.42815277],
                            [39.70884318, -5.42813595],
                            [39.70886673, -5.42806529],
                            [39.70894747, -5.42800473],
                            [39.70897102, -5.42811576],
                            [39.70903494, -5.42823352],
                            [39.70910895, -5.42827054],
                            [39.70923343, -5.42823352],
                            [39.70927064, -5.4282588],
                            [39.70916278, -5.42833446],
                            [39.70918297, -5.4283782],
                            [39.70916951, -5.4284455],
                            [39.70932427, -5.42875841],
                            [39.70940837, -5.42885599],
                            [39.70951267, -5.42888627],
                            [39.7096977, -5.42886945],
                            [39.70972462, -5.42894802],
                            [39.7096035, -5.42903768],
                            [39.70953958, -5.42920255],
                            [39.70964051, -5.42948518],
                            [39.70987264, -5.42997978],
                            [39.71011151, -5.43030616],
                            [39.71043448, -5.43046093],
                            [39.71065988, -5.43053159],
                            [39.71090548, -5.43064599],
                            [39.71116116, -5.43077048],
                            [39.71140339, -5.43086469],
                            [39.71152114, -5.43084451],
                            [39.71156824, -5.43081422],
                            [39.71155142, -5.43093199],
                            [39.71157833, -5.43116078],
                            [39.71153123, -5.43124826],
                            [39.71153796, -5.43151744],
                            [39.7116187, -5.43168567],
                            [39.71180374, -5.43189092],
                            [39.71181383, -5.43200531],
                            [39.71195177, -5.43213317],
                            [39.71212671, -5.43243599],
                            [39.71222427, -5.43248983],
                            [39.71227474, -5.43257058],
                            [39.71230838, -5.43283639],
                            [39.71245304, -5.43298443],
                            [39.71258675, -5.43312844],
                            [39.71262799, -5.43317285],
                            [39.71273564, -5.43323005],
                            [39.71273901, -5.4334353],
                            [39.71288367, -5.43359344],
                            [39.71297114, -5.4337583],
                            [39.71307543, -5.43400056],
                            [39.71317636, -5.43431684],
                            [39.71339168, -5.43451199],
                            [39.71359353, -5.43471723],
                            [39.71364736, -5.43485182],
                            [39.71381558, -5.43523203],
                            [39.71396508, -5.43556033],
                            [39.71397033, -5.43557186],
                            [39.71403688, -5.43579649],
                            [39.71405107, -5.4358444],
                            [39.71413855, -5.43605637],
                            [39.71418228, -5.4361674],
                            [39.71419237, -5.43637601],
                            [39.71425966, -5.4365678],
                            [39.71433367, -5.4367966],
                            [39.71436732, -5.43696819],
                            [39.71440096, -5.4371869],
                            [39.71446488, -5.43732821],
                            [39.71450189, -5.43741569],
                            [39.71449516, -5.43746953],
                            [39.7143875, -5.43749645],
                            [39.71435049, -5.43764449],
                            [39.71442787, -5.43788002],
                            [39.71455235, -5.43798096],
                            [39.71471383, -5.43805498],
                            [39.71483158, -5.43811554],
                            [39.71488541, -5.43821648],
                            [39.71496616, -5.43825013],
                            [39.71505026, -5.43822321],
                            [39.71510746, -5.43824677],
                            [39.7152824, -5.43831406],
                            [39.71541024, -5.43840154],
                            [39.71555827, -5.43839481],
                            [39.71553136, -5.43827369],
                            [39.71550781, -5.43821312],
                            [39.71559191, -5.43821312],
                            [39.71572312, -5.4381862],
                            [39.71575003, -5.43811555],
                            [39.71576686, -5.43799778],
                            [39.71575003, -5.43788338],
                            [39.71571303, -5.43777572],
                            [39.71569284, -5.43765459],
                            [39.71567266, -5.43764786],
                            [39.71563228, -5.43761421],
                            [39.715565, -5.43755701],
                            [39.71550444, -5.43745944],
                            [39.71546071, -5.43732149],
                            [39.71541024, -5.43711961],
                            [39.71546071, -5.43703549],
                            [39.71544052, -5.43693118],
                            [39.71543043, -5.43680669],
                            [39.71546407, -5.43672258],
                            [39.71552463, -5.43665865],
                            [39.71555827, -5.43654425],
                            [39.71552127, -5.43638947],
                            [39.71545734, -5.43601263],
                            [39.71541697, -5.43585786],
                            [39.71534296, -5.43571318],
                            [39.71529922, -5.43568626],
                            [39.71532614, -5.43563915],
                            [39.71540015, -5.43558532],
                            [39.71541697, -5.43529932],
                            [39.71542707, -5.43498305],
                            [39.7154809, -5.43479462],
                            [39.71545735, -5.43467686],
                            [39.71543043, -5.43451199],
                            [39.71541698, -5.43440432],
                            [39.71541025, -5.43429329],
                            [39.71543043, -5.4341116],
                            [39.71545398, -5.43405776],
                            [39.71551118, -5.43393664],
                            [39.71550445, -5.43384243],
                            [39.71552463, -5.43370111],
                            [39.71554818, -5.43364055],
                            [39.71559192, -5.43353624],
                            [39.71559865, -5.43345549],
                            [39.71560538, -5.43330408],
                            [39.71559865, -5.43319977],
                            [39.71556837, -5.43302481],
                            [39.71559528, -5.4329306],
                            [39.71561211, -5.43279265],
                            [39.71555828, -5.43268498],
                            [39.71545062, -5.43251002],
                            [39.71536651, -5.43226103],
                            [39.71527231, -5.43198513],
                            [39.71521512, -5.43169913],
                            [39.71521176, -5.43155782],
                            [39.71517475, -5.43142323],
                            [39.71514447, -5.4313593],
                            [39.71510074, -5.43128528],
                            [39.71503009, -5.43117088],
                            [39.71498299, -5.43098583],
                            [39.71500654, -5.43085124],
                            [39.71504018, -5.43067291],
                            [39.7149628, -5.43056861],
                            [39.71494262, -5.4304643],
                            [39.7149628, -5.43039701],
                            [39.71502336, -5.43029607],
                            [39.71502, -5.43022541],
                            [39.71502672, -5.43007737],
                            [39.71511083, -5.43003699],
                            [39.7151983, -5.42999998],
                            [39.71530932, -5.42994951],
                            [39.71541362, -5.42990241],
                            [39.71550782, -5.42980147],
                            [39.71571977, -5.42968707],
                            [39.71586443, -5.42959286],
                            [39.71597882, -5.42951884],
                            [39.71598218, -5.42944145],
                            [39.71597545, -5.42930686],
                            [39.71602255, -5.4293035],
                            [39.71611339, -5.42929677],
                            [39.71621095, -5.42930013],
                            [39.71627151, -5.42925303],
                            [39.71627488, -5.42914199],
                            [39.7163287, -5.42910835],
                            [39.71638926, -5.42914199],
                            [39.7165272, -5.42905788],
                            [39.71657093, -5.42894348],
                            [39.71660458, -5.42886609],
                            [39.71667859, -5.42885936],
                            [39.71678288, -5.42874496],
                            [39.71692755, -5.42856664],
                            [39.71703857, -5.42841859],
                            [39.71709576, -5.42837149],
                            [39.7171765, -5.42834457],
                            [39.71725388, -5.4282941],
                            [39.71733126, -5.42825036],
                            [39.71735145, -5.42828737],
                            [39.71726061, -5.42834793],
                            [39.71724715, -5.42844551],
                            [39.71728753, -5.4285128],
                            [39.71725052, -5.42858683],
                            [39.71735817, -5.42873824],
                            [39.71745574, -5.42886273],
                            [39.71758695, -5.42899395],
                            [39.71767778, -5.42901077],
                            [39.71774843, -5.42903096],
                            [39.71781908, -5.42910498],
                            [39.71793347, -5.42920929],
                            [39.71807813, -5.42926985],
                            [39.71822952, -5.42932032],
                            [39.71845157, -5.42943136],
                            [39.71857604, -5.4295323],
                            [39.7187779, -5.42961978],
                            [39.71884519, -5.42971735],
                            [39.71894612, -5.42974427],
                            [39.71896966, -5.42976782],
                            [39.71901004, -5.42982502],
                            [39.71908741, -5.42987213],
                            [39.71913115, -5.42983175],
                            [39.71914461, -5.42981157],
                            [39.71915806, -5.42979138],
                            [39.71923544, -5.42984858],
                            [39.71925563, -5.42994952],
                            [39.71930273, -5.42999326],
                            [39.7193902, -5.430037],
                            [39.71951131, -5.43008074],
                            [39.71959206, -5.4301985],
                            [39.71969635, -5.4302557],
                            [39.71974009, -5.43029271],
                            [39.71982083, -5.43028598],
                            [39.71992848, -5.43023551],
                            [39.72001932, -5.43013457],
                            [39.72002941, -5.42998316],
                            [39.7200967, -5.42992933],
                            [39.72018417, -5.42981157],
                            [39.72023464, -5.42972072],
                            [39.72035575, -5.42975773],
                            [39.72040285, -5.42988559],
                            [39.72035911, -5.43014803],
                            [39.72033893, -5.43033982],
                            [39.72035238, -5.43053497],
                            [39.72042976, -5.43075704],
                            [39.72054415, -5.43088153],
                            [39.72055424, -5.43102285],
                            [39.72067872, -5.43140642],
                            [39.72089403, -5.43182364],
                            [39.72119009, -5.43223413],
                            [39.72136839, -5.43245283],
                            [39.72147269, -5.43258405],
                            [39.72160389, -5.43275901],
                            [39.72177547, -5.43286332],
                            [39.72185285, -5.43292725],
                            [39.72194705, -5.43291042],
                            [39.72198406, -5.43297772],
                            [39.72211526, -5.43301473],
                            [39.72227339, -5.43305174],
                            [39.72237768, -5.43312913],
                            [39.72245506, -5.4331224],
                            [39.72254589, -5.43304838],
                            [39.72259901, -5.43321168],
                            [39.72273043, -5.43321168],
                            [39.72276739, -5.433313],
                            [39.72282214, -5.43336228],
                            [39.72288238, -5.43341705],
                            [39.72292618, -5.43347729],
                            [39.72295904, -5.4335211],
                            [39.72299189, -5.43357586],
                            [39.72305213, -5.43365253],
                            [39.72311236, -5.43370182],
                            [39.72317259, -5.43374015],
                            [39.72322735, -5.43383873],
                            [39.72337519, -5.43388801],
                            [39.72351756, -5.43401945],
                            [39.72357779, -5.43405778],
                            [39.7236216, -5.43407969],
                            [39.72368731, -5.43413993],
                            [39.72375302, -5.43420017],
                            [39.72384063, -5.43428231],
                            [39.72397752, -5.43438636],
                            [39.72410346, -5.43441922],
                            [39.72423488, -5.43445208],
                            [39.72426774, -5.43445208],
                            [39.72431154, -5.43445208],
                            [39.72437725, -5.43446303],
                            [39.72445939, -5.43450137],
                            [39.72463461, -5.43454518],
                            [39.72471127, -5.43457256],
                            [39.72480436, -5.43460542],
                            [39.72486459, -5.43461089],
                            [39.7249741, -5.4346328],
                            [39.72502339, -5.4346547],
                            [39.72512743, -5.4347478],
                            [39.7252917, -5.43485733],
                            [39.72557096, -5.43505448],
                            [39.72563667, -5.43509281],
                            [39.72571333, -5.43510377],
                            [39.72576808, -5.43508734],
                            [39.72589403, -5.43512567],
                            [39.72593783, -5.43516401],
                            [39.72597069, -5.43520782],
                            [39.7260364, -5.43528996],
                            [39.72622805, -5.43552545],
                            [39.72628828, -5.43557473],
                            [39.72634851, -5.43567331],
                            [39.72641422, -5.43577736],
                            [39.72647445, -5.43584855],
                            [39.72665515, -5.43596903],
                            [39.72669896, -5.43598546],
                            [39.72678109, -5.43604022],
                            [39.72687418, -5.43607856],
                            [39.72696179, -5.43616618],
                            [39.72708226, -5.43642904],
                            [39.72721915, -5.43650571],
                            [39.72729581, -5.4365769],
                            [39.72733962, -5.43659333],
                            [39.7274327, -5.43661524],
                            [39.72753674, -5.43665357],
                            [39.7275915, -5.43667548],
                            [39.72766268, -5.43673572],
                            [39.72767364, -5.43681786],
                            [39.72771197, -5.43698763],
                            [39.72778863, -5.43711906],
                            [39.72785433, -5.43722311],
                            [39.72792004, -5.43728883],
                            [39.7279748, -5.43734907],
                            [39.72805146, -5.43740931],
                            [39.72811717, -5.43751336],
                            [39.72818835, -5.4375736],
                            [39.72828692, -5.43758455],
                            [39.72840738, -5.43760098],
                            [39.72847309, -5.43760098],
                            [39.72860451, -5.43759003],
                            [39.72879068, -5.43743669],
                            [39.72886187, -5.43740931],
                            [39.72888377, -5.43731074],
                            [39.72885639, -5.43724502],
                            [39.72883997, -5.43716288],
                            [39.72884544, -5.43708621],
                            [39.72885092, -5.43692192],
                            [39.72882354, -5.43687263],
                            [39.72882902, -5.43679596],
                            [39.72883449, -5.43674667],
                            [39.72884544, -5.43669191],
                            [39.72887282, -5.43664262],
                            [39.72894401, -5.43650024],
                            [39.72896043, -5.43641809],
                            [39.72893853, -5.4363469],
                            [39.72893305, -5.4362538],
                            [39.72899876, -5.43622095],
                            [39.72906995, -5.43613332],
                            [39.7291028, -5.43600189],
                            [39.72920137, -5.43594165],
                            [39.72933278, -5.43583213],
                            [39.72940944, -5.43578831],
                            [39.72947515, -5.43572807],
                            [39.72948063, -5.43567879],
                            [39.72948063, -5.4356295],
                            [39.72950253, -5.43556378],
                            [39.72956276, -5.43550354],
                            [39.72965038, -5.43550354],
                            [39.72976537, -5.43553093],
                            [39.72991321, -5.43558569],
                            [39.7300501, -5.43552545],
                            [39.73009391, -5.43544331],
                            [39.73018152, -5.43541045],
                            [39.73026366, -5.4353995],
                            [39.73038412, -5.43543783],
                            [39.73049911, -5.43543783],
                            [39.73059496, -5.43537948],
                            [39.73062506, -5.43536116],
                            [39.73063053, -5.43525711],
                            [39.73065791, -5.43517497],
                            [39.73071267, -5.43510377],
                            [39.730751, -5.43499972],
                            [39.73086599, -5.43499425],
                            [39.73104121, -5.43498329],
                            [39.73117263, -5.43485186],
                            [39.73126024, -5.43472043],
                            [39.7312931, -5.43463281],
                            [39.73133143, -5.43455614],
                            [39.73145737, -5.43448495],
                            [39.73152855, -5.43441923],
                            [39.73157783, -5.43435351],
                            [39.7315395, -5.43418922],
                            [39.73144642, -5.43400851],
                            [39.73149022, -5.43395922],
                            [39.73156688, -5.43389898],
                            [39.73163807, -5.43383326],
                            [39.73164354, -5.43371278],
                            [39.73159974, -5.43359778],
                            [39.73153951, -5.43352111],
                            [39.73152308, -5.43347182],
                            [39.73155046, -5.43339515],
                            [39.73153951, -5.43331301],
                            [39.73153478, -5.433261],
                            [39.73153403, -5.43325277],
                            [39.73156688, -5.43321444],
                            [39.73161019, -5.43319587],
                            [39.73164354, -5.43318158],
                            [39.73163713, -5.4331726],
                            [39.73158879, -5.43310491],
                            [39.73157236, -5.43305015],
                            [39.73154498, -5.43295157],
                            [39.73146832, -5.43282014],
                            [39.7314957, -5.43271061],
                            [39.7314738, -5.4326449],
                            [39.73142999, -5.43261751],
                            [39.73140809, -5.43259013],
                            [39.73141356, -5.4325518],
                            [39.73140809, -5.43249703],
                            [39.73138619, -5.43242584],
                            [39.73133143, -5.43242036],
                            [39.7312712, -5.43238751],
                            [39.73129858, -5.43236012],
                            [39.73136976, -5.43235465],
                            [39.7314519, -5.4323656],
                            [39.73152308, -5.43240394],
                            [39.73163807, -5.4324587],
                            [39.73168188, -5.43244227],
                            [39.73170378, -5.43240394],
                            [39.73173663, -5.43238751],
                            [39.73180234, -5.43248061],
                            [39.73188448, -5.43254632],
                            [39.73195566, -5.43259561],
                            [39.73201042, -5.43259561],
                            [39.73208708, -5.43254632],
                            [39.73208708, -5.43244775],
                            [39.73209803, -5.43221226],
                            [39.73214731, -5.4320644],
                            [39.73218564, -5.43196583],
                            [39.73228968, -5.43191654],
                            [39.7323992, -5.43191654],
                            [39.73251966, -5.43191107],
                            [39.7326237, -5.43191107],
                            [39.73266751, -5.43186178],
                            [39.73269489, -5.43173582],
                            [39.7327825, -5.43168106],
                            [39.73280988, -5.4316482],
                            [39.73284273, -5.43163177],
                            [39.73291392, -5.43158796],
                            [39.7329632, -5.43159344],
                            [39.73310009, -5.43157153],
                            [39.73316032, -5.43150034],
                            [39.73320413, -5.43141272],
                            [39.73328079, -5.43130319],
                            [39.73336293, -5.43122105],
                            [39.73342864, -5.43118819],
                            [39.73347792, -5.43112795],
                            [39.7335272, -5.43107866],
                            [39.73363669, -5.4311569],
                            [39.73368606, -5.43122576],
                            [39.7337298, -5.43128676],
                            [39.73377273, -5.43142753],
                            [39.73380934, -5.43147951],
                            [39.73382335, -5.43149939],
                            [39.73390502, -5.43161535],
                            [39.73399811, -5.4316263],
                            [39.7341131, -5.43159892],
                            [39.73419524, -5.43154963],
                            [39.73424999, -5.43150582],
                            [39.73432665, -5.4315113],
                            [39.7344526, -5.43154963],
                            [39.73455664, -5.43154963],
                            [39.73462782, -5.43150582],
                            [39.7346771, -5.43141272],
                            [39.73470996, -5.43127034],
                            [39.73470448, -5.43114986],
                            [39.73461139, -5.43102938],
                            [39.73452926, -5.43088699],
                            [39.73441974, -5.4305146],
                            [39.73437045, -5.4304704],
                            [39.73426095, -5.43037222],
                            [39.73437594, -5.43037769],
                            [39.73448545, -5.43029555],
                            [39.7344964, -5.43020793],
                            [39.73459497, -5.43016959],
                            [39.73466068, -5.4300984],
                            [39.7346771, -5.42993411],
                            [39.7346771, -5.42984649],
                            [39.73479757, -5.42978625],
                            [39.73488796, -5.42947413],
                            [39.73488307, -5.42957668],
                            [39.73487846, -5.42968033],
                            [39.73487263, -5.42981159],
                            [39.73484908, -5.42988225],
                            [39.73475152, -5.4300673],
                            [39.73480535, -5.43019516],
                            [39.73488272, -5.43025909],
                            [39.73499038, -5.43036339],
                            [39.73509131, -5.43047106],
                            [39.73526625, -5.43050471],
                            [39.73537054, -5.43045424],
                            [39.73544792, -5.43034994],
                            [39.73551184, -5.43031629],
                            [39.73553241, -5.4303668],
                            [39.73553203, -5.43040041],
                            [39.73553876, -5.43047779],
                            [39.73559948, -5.43059567],
                            [39.73559932, -5.43061574],
                            [39.73570024, -5.4306965],
                            [39.73573725, -5.43073351],
                            [39.73587585, -5.43077864],
                            [39.73588192, -5.43078061],
                            [39.73598621, -5.43081089],
                            [39.73614096, -5.4308109],
                            [39.73629909, -5.4308008],
                            [39.73641684, -5.43079744],
                            [39.73651104, -5.43082435],
                            [39.73664561, -5.43077388],
                            [39.73671626, -5.4306393],
                            [39.73672971, -5.43059219],
                            [39.73668598, -5.43054845],
                            [39.73665234, -5.4305249],
                            [39.73657159, -5.43045424],
                            [39.73654468, -5.43035667],
                            [39.73655477, -5.4302389],
                            [39.7365615, -5.43016152],
                            [39.73657832, -5.43000674],
                            [39.73659851, -5.42994281],
                            [39.73652887, -5.42982472],
                            [39.73652113, -5.42981159],
                            [39.73651777, -5.42976785],
                            [39.73653087, -5.42975606],
                            [39.73655141, -5.42973757],
                            [39.73654804, -5.42966355],
                            [39.73652113, -5.42965345],
                            [39.73646057, -5.42960971],
                            [39.73648412, -5.42959625],
                            [39.73663552, -5.42956261],
                            [39.73668935, -5.42956261],
                            [39.73676672, -5.42953569],
                            [39.73672299, -5.42947849],
                            [39.73668935, -5.4294112],
                            [39.73664897, -5.42936073],
                            [39.73664897, -5.42935063],
                            [39.73666916, -5.4293439],
                            [39.73670617, -5.429354],
                            [39.73677345, -5.42932372],
                            [39.73682055, -5.42930353],
                            [39.73688784, -5.42931362],
                            [39.73684213, -5.42933802],
                            [39.7368441, -5.42937419],
                            [39.73686685, -5.42941367],
                            [39.73686765, -5.42942802],
                            [39.73693512, -5.42947155],
                            [39.7369383, -5.4295256],
                            [39.73697194, -5.42955251],
                            [39.73701568, -5.42956597],
                            [39.73711661, -5.42956261],
                            [39.73723099, -5.42956261],
                            [39.73727137, -5.42955251],
                            [39.73728146, -5.42951887],
                            [39.73730501, -5.42947513],
                            [39.73733529, -5.42943139],
                            [39.73739584, -5.4293439],
                            [39.73743285, -5.42927998],
                            [39.73742612, -5.42920932],
                            [39.73737902, -5.42914539],
                            [39.73730164, -5.42911174],
                            [39.73725791, -5.42908483],
                            [39.73720745, -5.42907473],
                            [39.73716371, -5.42909828],
                            [39.73710988, -5.42909828],
                            [39.7370796, -5.42908146],
                            [39.73707287, -5.42902763],
                            [39.73701568, -5.42898725],
                            [39.73694503, -5.42887622],
                            [39.7368542, -5.42876855],
                            [39.73681382, -5.42873154],
                            [39.73677345, -5.42869452],
                            [39.73677009, -5.42865751],
                            [39.73677936, -5.42864295],
                            [39.73679364, -5.4286205],
                            [39.73680861, -5.42862649],
                            [39.7368441, -5.42864069],
                            [39.73692148, -5.42866424],
                            [39.73699886, -5.42862723],
                            [39.73709505, -5.42862928],
                            [39.73709719, -5.42865988],
                            [39.73710315, -5.42874499],
                            [39.73718726, -5.42880556],
                            [39.73725791, -5.42885603],
                            [39.73734202, -5.42891659],
                            [39.7374306, -5.42896657],
                            [39.73747322, -5.42899061],
                            [39.73751023, -5.4290108],
                            [39.73757079, -5.42899398],
                            [39.7375977, -5.42892332],
                            [39.73759097, -5.42885266],
                            [39.73758425, -5.42880219],
                            [39.73757079, -5.42873154],
                            [39.73751023, -5.42867434],
                            [39.73743958, -5.4286205],
                            [39.73738912, -5.42856667],
                            [39.73739585, -5.42850274],
                            [39.73734202, -5.428459],
                            [39.73728819, -5.4284018],
                            [39.73733529, -5.42835806],
                            [39.73740257, -5.42825712],
                            [39.73740594, -5.42814608],
                            [39.73740594, -5.42808216],
                            [39.73739248, -5.42802832],
                            [39.73735547, -5.42803841],
                            [39.7373151, -5.42807543],
                            [39.73719735, -5.42807543],
                            [39.73714689, -5.42808552],
                            [39.73710988, -5.42810571],
                            [39.73705942, -5.42806197],
                            [39.73708297, -5.4280115],
                            [39.73713007, -5.42798795],
                            [39.7372209, -5.42794757],
                            [39.73730837, -5.42794757],
                            [39.73739585, -5.4279442],
                            [39.73742276, -5.42792402],
                            [39.73739921, -5.4278399],
                            [39.73735884, -5.42782981],
                            [39.73736557, -5.42779952],
                            [39.73738912, -5.42775242],
                            [39.73740594, -5.42768849],
                            [39.73739921, -5.42754717],
                            [39.73738912, -5.42750343],
                            [39.73736557, -5.42749334],
                            [39.73731847, -5.42751689],
                            [39.73727473, -5.42750343],
                            [39.73726464, -5.42746979],
                            [39.73730165, -5.42742605],
                            [39.73735548, -5.42740922],
                            [39.73737903, -5.42734866],
                            [39.73735548, -5.42729482],
                            [39.73733193, -5.42725781],
                            [39.73732856, -5.42721071],
                            [39.7373622, -5.4271737],
                            [39.73734538, -5.42707949],
                            [39.73730838, -5.42700883],
                            [39.73730165, -5.42698528],
                            [39.73733865, -5.42697518],
                            [39.73739585, -5.42698528],
                            [39.73749678, -5.42697855],
                            [39.73764481, -5.42699201],
                            [39.73773228, -5.42695163],
                            [39.73775246, -5.42680359],
                            [39.73781302, -5.42668919],
                            [39.73785339, -5.42662189],
                            [39.73792741, -5.42650749],
                            [39.73804179, -5.42636618],
                            [39.73804852, -5.42627197],
                            [39.73804852, -5.42623832],
                            [39.73804852, -5.42620468],
                            [39.73804852, -5.42611719],
                            [39.73806198, -5.42605663],
                            [39.73806198, -5.42596915],
                            [39.73801488, -5.42591531],
                            [39.73796778, -5.4258884],
                            [39.73795432, -5.42584802],
                            [39.73795432, -5.42572689],
                            [39.73804852, -5.42560577],
                            [39.73811581, -5.42551156],
                            [39.73823019, -5.42544426],
                            [39.73830179, -5.42555674],
                            [39.7382083, -5.42560774],
                            [39.73808748, -5.4257283],
                            [39.73806383, -5.42582873],
                            [39.73815731, -5.42594772],
                            [39.73822035, -5.4260902],
                            [39.73815731, -5.4263727],
                            [39.73804683, -5.42658519],
                            [39.73788581, -5.42678928],
                            [39.73783436, -5.42701016],
                            [39.73755298, -5.42723092],
                            [39.73750292, -5.42743514],
                            [39.7375964, -5.42782612],
                            [39.73771538, -5.4282001],
                            [39.73797526, -5.4285634],
                            [39.73790235, -5.42891406],
                            [39.73797884, -5.42925404],
                            [39.73787097, -5.42933805],
                            [39.73784286, -5.42947503],
                            [39.73801283, -5.42954302],
                            [39.73814031, -5.42941553],
                            [39.7382168, -5.42936453],
                            [39.73833578, -5.42927104],
                            [39.73846326, -5.42918604],
                            [39.73851425, -5.42909255],
                            [39.73855674, -5.42897356],
                            [39.73856524, -5.42896506],
                            [39.73866722, -5.42891406],
                            [39.73875878, -5.42891659],
                            [39.73873521, -5.42905855],
                            [39.73868847, -5.42914355],
                            [39.73864173, -5.42922854],
                            [39.73848875, -5.42939003],
                            [39.73824229, -5.42964502],
                            [39.73830178, -5.4299085],
                            [39.73869272, -5.43057147],
                            [39.73882019, -5.43118343],
                            [39.73891368, -5.4318039],
                            [39.73893788, -5.43188344],
                            [39.73909215, -5.43239037],
                            [39.7393641, -5.43292584],
                            [39.73937582, -5.43294141],
                            [39.73990801, -5.4336483],
                            [39.74030744, -5.43428577],
                            [39.74064738, -5.43479574],
                            [39.74074532, -5.43507022],
                            [39.74073237, -5.43531421],
                            [39.74085135, -5.4355437],
                            [39.74072387, -5.43567119],
                            [39.74049441, -5.43590068],
                            [39.74029894, -5.43612167],
                            [39.74026752, -5.43622333],
                            [39.74022773, -5.43635211],
                            [39.74020545, -5.43667413],
                            [39.74037542, -5.43696312],
                            [39.74057348, -5.43716982],
                            [39.74076636, -5.4373711],
                            [39.74100432, -5.43755809],
                            [39.74128477, -5.43756659],
                            [39.74143775, -5.43759209],
                            [39.74163321, -5.43761758],
                            [39.74203265, -5.43769408],
                            [39.74238109, -5.43785557],
                            [39.74267004, -5.43805956],
                            [39.74295049, -5.43813606],
                            [39.74295049, -5.43799157],
                            [39.74298449, -5.43784707],
                            [39.74305834, -5.43782912],
                            [39.74306097, -5.43791507],
                            [39.74308691, -5.43792275],
                            [39.74309012, -5.43802465],
                            [39.74309497, -5.43817856],
                            [39.74319695, -5.43828905],
                            [39.74330743, -5.43822955],
                            [39.74341791, -5.43808506],
                            [39.7435114, -5.43818706],
                            [39.74365587, -5.43834005],
                            [39.74374936, -5.43862903],
                            [39.74381734, -5.43873103],
                            [39.74397032, -5.43879052],
                            [39.74420828, -5.43892652],
                            [39.74436975, -5.43900301],
                            [39.74462471, -5.43909651],
                            [39.74474369, -5.43901151],
                            [39.7446842, -5.43879053],
                            [39.7446757, -5.43868003],
                            [39.74446324, -5.43857804],
                            [39.74448023, -5.43848454],
                            [39.74455672, -5.43836555],
                            [39.74451423, -5.43828055],
                            [39.74446324, -5.43812756],
                            [39.74458222, -5.43811056],
                            [39.7446587, -5.43827205],
                            [39.74471819, -5.43850154],
                            [39.74481168, -5.43851004],
                            [39.74487967, -5.43839955],
                            [39.74482018, -5.43822106],
                            [39.74474369, -5.43799157],
                            [39.74487117, -5.43812756],
                            [39.74504964, -5.43808506],
                            [39.74491366, -5.43789807],
                            [39.74496465, -5.43780458],
                            [39.74508363, -5.43777908],
                            [39.74506664, -5.43765159],
                            [39.74492216, -5.4375071],
                            [39.74479468, -5.43756659],
                            [39.7447182, -5.43766859],
                            [39.74442924, -5.43779608],
                            [39.74428477, -5.43772808],
                            [39.74421678, -5.43751559],
                            [39.74426777, -5.4374986],
                            [39.74439525, -5.4375071],
                            [39.74450573, -5.4374731],
                            [39.74455672, -5.43732011],
                            [39.7447182, -5.43721811],
                            [39.74486267, -5.43721386],
                            [39.74496465, -5.43720111],
                            [39.74509213, -5.43712887],
                            [39.74511338, -5.43700987],
                            [39.74516862, -5.43685688],
                            [39.74524511, -5.43683563],
                            [39.74528672, -5.43690373],
                            [39.74529185, -5.43695038],
                            [39.74531028, -5.43697478],
                            [39.74531868, -5.43705082],
                            [39.74532631, -5.43711985],
                            [39.74533434, -5.43719261],
                            [39.74537259, -5.43727761],
                            [39.74550856, -5.43735411],
                            [39.74583576, -5.43710762],
                            [39.74594624, -5.43703537],
                            [39.74601423, -5.43698013],
                            [39.74612046, -5.43691213],
                            [39.74616969, -5.43683953],
                            [39.74619204, -5.43699782],
                            [39.74622239, -5.43721267],
                            [39.74625398, -5.43743637],
                            [39.74626862, -5.43753998],
                            [39.74628618, -5.43766434],
                            [39.74637542, -5.43771534],
                            [39.74654114, -5.43772384],
                            [39.74663887, -5.43760909],
                            [39.74666862, -5.4375241],
                            [39.74669411, -5.4374391],
                            [39.74672811, -5.43742635],
                            [39.74680885, -5.43743485],
                            [39.74691933, -5.43737536],
                            [39.74692995, -5.43733073],
                            [39.74694057, -5.43728611],
                            [39.74696819, -5.43731798],
                            [39.74699581, -5.43734986],
                            [39.74706476, -5.4375183],
                            [39.7470808, -5.43761334],
                            [39.74706805, -5.43776633],
                            [39.7470638, -5.43792358],
                            [39.74698025, -5.43807989],
                            [39.74698731, -5.43812332],
                            [39.74709355, -5.43817431],
                            [39.74719553, -5.43823806],
                            [39.74716259, -5.43832145],
                            [39.74718278, -5.43844205],
                            [39.74727202, -5.43852279],
                            [39.74737825, -5.43856104],
                            [39.7473655, -5.43864604],
                            [39.74728901, -5.43883728],
                            [39.74721253, -5.43885003],
                            [39.74711432, -5.43890732],
                            [39.74711054, -5.43890952],
                            [39.74700856, -5.43901152],
                            [39.74689808, -5.43917726],
                            [39.74687683, -5.43933025],
                            [39.74684284, -5.43955124],
                            [39.74682584, -5.43975098],
                            [39.74691215, -5.44003656],
                            [39.74693632, -5.4401972],
                            [39.74699581, -5.44033744],
                            [39.74698306, -5.44044794],
                            [39.7469573, -5.44052618],
                            [39.74699581, -5.44078367],
                            [39.7470298, -5.44081767],
                            [39.74710204, -5.44084317],
                            [39.74717853, -5.44085592],
                            [39.74730176, -5.44085592],
                            [39.74732427, -5.440853],
                            [39.7473315, -5.44089841],
                            [39.74741224, -5.44092816],
                            [39.74748023, -5.44107265],
                            [39.74754397, -5.4411109],
                            [39.74767145, -5.4411364],
                            [39.74775643, -5.44113215],
                            [39.74785416, -5.4411024],
                            [39.74799864, -5.44118315],
                            [39.74815161, -5.44130214],
                            [39.74826635, -5.44134464],
                            [39.74845756, -5.44136164],
                            [39.74869127, -5.44137439],
                            [39.74889524, -5.44134039],
                            [39.74916295, -5.4412554],
                            [39.74934142, -5.4411789],
                            [39.74945615, -5.44104716],
                            [39.74950714, -5.44091967],
                            [39.74959212, -5.44086867],
                            [39.74962612, -5.44079642],
                            [39.74967711, -5.44076242],
                            [39.74968561, -5.44076242],
                            [39.74978334, -5.44072418],
                            [39.74988533, -5.44064343],
                            [39.75020402, -5.44056694],
                            [39.75030176, -5.44056694],
                            [39.75040799, -5.44053719],
                            [39.75045898, -5.44047344],
                            [39.75046323, -5.44041394],
                            [39.75045048, -5.44033745],
                            [39.75042074, -5.4402652],
                            [39.75036125, -5.44019721],
                            [39.75036125, -5.44014196],
                            [39.75034425, -5.44007821],
                            [39.750323, -5.44003147],
                            [39.75035275, -5.43998047],
                            [39.75048448, -5.43998472],
                            [39.750647, -5.44004247],
                            [39.75067144, -5.44013346],
                            [39.75062045, -5.44022696],
                            [39.75054574, -5.44038618],
                            [39.75058469, -5.44053452],
                            [39.7506077, -5.44062218],
                            [39.75067569, -5.44071568],
                            [39.75076493, -5.44073268],
                            [39.75099014, -5.44074968],
                            [39.7512111, -5.44078793],
                            [39.7515298, -5.44080067],
                            [39.75166578, -5.44073268],
                            [39.75177626, -5.44064343],
                            [39.75181875, -5.44057969],
                            [39.75188674, -5.44048194],
                            [39.75199722, -5.4403332],
                            [39.75206521, -5.44029495],
                            [39.75217144, -5.44020996],
                            [39.75224793, -5.44011646],
                            [39.7524094, -5.43995497],
                            [39.7524349, -5.43993797],
                            [39.75247314, -5.43991248],
                            [39.75254963, -5.43989548],
                            [39.75261762, -5.43989123],
                            [39.75268561, -5.43986148],
                            [39.75279609, -5.43982323],
                            [39.75288957, -5.43977223],
                            [39.75298731, -5.43973399],
                            [39.75306379, -5.43969999],
                            [39.75309779, -5.43965749],
                            [39.75310629, -5.43960649],
                            [39.75313603, -5.43952575],
                            [39.75322952, -5.4394535],
                            [39.75340799, -5.439394],
                            [39.75365445, -5.43930476],
                            [39.75377767, -5.43929201],
                            [39.75396464, -5.43922402],
                            [39.75407937, -5.43911777],
                            [39.75418561, -5.43904553],
                            [39.75426209, -5.43898178],
                            [39.75429184, -5.43891378],
                            [39.75429609, -5.43881604],
                            [39.75431734, -5.43876504],
                            [39.75432158, -5.43868005],
                            [39.75440657, -5.43866305],
                            [39.75444906, -5.4386078],
                            [39.7545298, -5.4385313],
                            [39.75458504, -5.43845481],
                            [39.75461904, -5.43835281],
                            [39.75468702, -5.43826357],
                            [39.75479326, -5.43819132],
                            [39.75489524, -5.43812758],
                            [39.75495473, -5.43806383],
                            [39.75506521, -5.43799583],
                            [39.75518419, -5.43793634],
                            [39.75533292, -5.4377961],
                            [39.75538391, -5.43765585],
                            [39.7554349, -5.43759636],
                            [39.75558787, -5.4376346],
                            [39.75572385, -5.43754536],
                            [39.75577059, -5.43743487],
                            [39.75589382, -5.43744337],
                            [39.7560468, -5.43747736],
                            [39.75612753, -5.43745187],
                            [39.75623377, -5.43750711],
                            [39.75635275, -5.43763036],
                            [39.75645048, -5.4376601],
                            [39.7566162, -5.43762186],
                            [39.75670969, -5.43757936],
                            [39.75681592, -5.43744337],
                            [39.75684141, -5.43743487],
                            [39.75693065, -5.43742212],
                            [39.75696889, -5.43737537],
                            [39.75706663, -5.43733287],
                            [39.75723235, -5.43734562],
                            [39.75740232, -5.43731588],
                            [39.75755529, -5.43724363],
                            [39.75771252, -5.43706514],
                            [39.75781875, -5.4369249],
                            [39.75796323, -5.43685265],
                            [39.75804396, -5.43675066],
                            [39.75808221, -5.43665291],
                            [39.75821818, -5.43659342],
                            [39.75838391, -5.43652117],
                            [39.75850714, -5.43637243],
                            [39.75856238, -5.43631293],
                            [39.75858787, -5.43626618],
                            [39.75868561, -5.43624494],
                            [39.75867711, -5.43615144],
                            [39.75866011, -5.43604095],
                            [39.75861337, -5.43599845],
                            [39.75854963, -5.4359602],
                            [39.75849864, -5.4359092],
                            [39.75845615, -5.43580296],
                            [39.75838391, -5.43563722],
                            [39.75829892, -5.43560747],
                            [39.75822668, -5.43559047],
                            [39.75814595, -5.43550548],
                            [39.75807796, -5.43543323],
                            [39.75804397, -5.43536098],
                            [39.75803972, -5.43535673],
                            [39.75802697, -5.43528024],
                            [39.75803547, -5.43517399],
                            [39.75803547, -5.435089],
                            [39.75805246, -5.4350125],
                            [39.75808646, -5.43495301],
                            [39.7581417, -5.43493601],
                            [39.75826918, -5.43493176],
                            [39.75855813, -5.43497426],
                            [39.75877909, -5.43496151],
                            [39.75884708, -5.43487651],
                            [39.75886408, -5.43477877],
                            [39.75886833, -5.43471077],
                            [39.75893207, -5.43468527],
                            [39.75896606, -5.43466402],
                            [39.7590298, -5.43468527],
                            [39.75911904, -5.43474477],
                            [39.75915303, -5.43471927],
                            [39.75920827, -5.43466827],
                            [39.7592656, -5.43465564],
                            [39.75929326, -5.43469802],
                            [39.75943348, -5.43470652],
                            [39.75949297, -5.43468527],
                            [39.75957371, -5.43467252],
                            [39.75960346, -5.43461728],
                            [39.75958221, -5.43449403],
                            [39.75956521, -5.43435804],
                            [39.75958646, -5.43417105],
                            [39.75958221, -5.43401381],
                            [39.75959496, -5.43395431],
                            [39.75955246, -5.43393306],
                            [39.75950572, -5.43392881],
                            [39.75946748, -5.43390757],
                            [39.75944623, -5.43384807],
                            [39.75931026, -5.43366533],
                            [39.75917428, -5.43354209],
                            [39.75890657, -5.43329985],
                            [39.75886833, -5.43322335],
                            [39.75881734, -5.4331936],
                            [39.75879184, -5.43313836],
                            [39.75879609, -5.43306611],
                            [39.75889383, -5.43304061],
                            [39.75906805, -5.43301086],
                            [39.75919978, -5.43307036],
                            [39.75927201, -5.43311286],
                            [39.75957371, -5.43317661],
                            [39.75970544, -5.43324885],
                            [39.75985841, -5.43332535],
                            [39.76001989, -5.43329135],
                            [39.76013887, -5.43318936],
                            [39.7602196, -5.43308311],
                            [39.76032159, -5.43293012],
                            [39.76034283, -5.43284937],
                            [39.76035983, -5.43280688],
                            [39.76047456, -5.43275588],
                            [39.76059846, -5.43283394],
                            [39.76066153, -5.43291737],
                            [39.76078476, -5.43294712],
                            [39.76104397, -5.43307886],
                            [39.7611587, -5.43310861],
                            [39.76138391, -5.43307886],
                            [39.76147314, -5.43314686],
                            [39.76165586, -5.43327435],
                            [39.76181734, -5.43328285],
                            [39.76200431, -5.43320211],
                            [39.76205105, -5.43312136],
                            [39.76211904, -5.43300237],
                            [39.76217428, -5.43285788],
                            [39.76224652, -5.43273038],
                            [39.76230176, -5.43253489],
                            [39.76230601, -5.43243715],
                            [39.76222527, -5.43230116],
                            [39.76219128, -5.43219066],
                            [39.76220827, -5.43207167],
                            [39.76228051, -5.43193993],
                            [39.762374, -5.43187193],
                            [39.76256521, -5.43175294],
                            [39.76255671, -5.43174019],
                            [39.76259921, -5.43174444],
                            [39.76277768, -5.43171044],
                            [39.7629519, -5.43165944],
                            [39.76307088, -5.4316297],
                            [39.7632536, -5.4316212],
                            [39.76346181, -5.4316212],
                            [39.7635468, -5.43154045],
                            [39.76359354, -5.43140871],
                            [39.76369977, -5.43125997],
                            [39.7638485, -5.43103898],
                            [39.76398448, -5.43089024],
                            [39.76426068, -5.4307245],
                            [39.76440516, -5.4306225],
                            [39.76454963, -5.43041851],
                            [39.76465162, -5.43030377],
                            [39.76487683, -5.43007853],
                            [39.76498731, -5.42990429],
                            [39.7650298, -5.42978955],
                            [39.76506805, -5.42962806],
                            [39.76511479, -5.42953456],
                            [39.76519553, -5.42939857],
                            [39.76525927, -5.42931358],
                            [39.76535275, -5.42915634],
                            [39.76541649, -5.4289651],
                            [39.76548448, -5.42879935],
                            [39.76555672, -5.42845512],
                            [39.76556097, -5.42822989],
                            [39.76556097, -5.4280514],
                            [39.7656587, -5.42778791],
                            [39.76583292, -5.42762642],
                            [39.76588816, -5.42758817],
                            [39.76590091, -5.42744368],
                            [39.76584142, -5.42733319],
                            [39.76583717, -5.42706545],
                            [39.76590516, -5.42692521],
                            [39.76593916, -5.42674672],
                            [39.76605389, -5.42664047],
                            [39.76634284, -5.42637699],
                            [39.76635134, -5.42637699],
                            [39.76647882, -5.42643649],
                            [39.76673377, -5.42658098],
                            [39.76692074, -5.42656398],
                            [39.76704397, -5.42647049],
                            [39.766874, -5.42619425],
                            [39.76689525, -5.42609651],
                            [39.76703547, -5.42593501],
                            [39.76707797, -5.42574802],
                            [39.7671842, -5.42556104],
                            [39.76736267, -5.42534855],
                            [39.76754964, -5.42522955],
                            [39.76795757, -5.42485558],
                            [39.76823802, -5.42459209],
                            [39.76843774, -5.42435835],
                            [39.76861621, -5.42414587],
                            [39.76879468, -5.42394188],
                            [39.7689689, -5.42373789],
                            [39.76908788, -5.42369539],
                            [39.76929185, -5.4235594],
                            [39.76934709, -5.42355515],
                            [39.76953831, -5.42345315],
                            [39.76961479, -5.42333416],
                            [39.76973802, -5.42311317],
                            [39.76977202, -5.42303243],
                            [39.76992074, -5.42279869],
                            [39.76999298, -5.4227222],
                            [39.7701757, -5.4225777],
                            [39.77026919, -5.4225607],
                            [39.77039666, -5.42252246],
                            [39.7704774, -5.42242471],
                            [39.77054539, -5.42228447],
                            [39.77063038, -5.42217398],
                            [39.77077485, -5.42198274],
                            [39.77088108, -5.42194874],
                            [39.77102131, -5.421817],
                            [39.77114879, -5.42161301],
                            [39.77132726, -5.42155351],
                            [39.7714165, -5.42153651],
                            [39.77156947, -5.42156201],
                            [39.77163822, -5.42174342],
                            [39.7717097, -5.42177875],
                            [39.77184992, -5.42177875],
                            [39.77195615, -5.42171925],
                            [39.77204964, -5.42163001],
                            [39.77213887, -5.42147277],
                            [39.77224936, -5.42126028],
                            [39.77235134, -5.42122628],
                            [39.77249157, -5.42114554],
                            [39.77259355, -5.42105204],
                            [39.77275077, -5.4209543],
                            [39.77277202, -5.42083955],
                            [39.77276777, -5.42081405],
                            [39.77274227, -5.42074606],
                            [39.77270403, -5.42066531],
                            [39.77265729, -5.42060582],
                            [39.77250856, -5.42057607],
                            [39.77235984, -5.42059732],
                            [39.77222386, -5.42068231],
                            [39.77213463, -5.42069081],
                            [39.77210063, -5.42056332],
                            [39.77210063, -5.42049532],
                            [39.77208788, -5.42035508],
                            [39.77204539, -5.42026583],
                            [39.77189667, -5.42014259],
                            [39.77179468, -5.41984086],
                            [39.77173095, -5.41962412],
                            [39.77160347, -5.41934363],
                            [39.77157372, -5.41916514],
                            [39.77155673, -5.4189739],
                            [39.77155248, -5.41894416],
                            [39.77150573, -5.41892716],
                            [39.77140049, -5.41896851],
                            [39.77138675, -5.4189739],
                            [39.77128902, -5.41901215],
                            [39.77119129, -5.4190504],
                            [39.77103406, -5.41912264],
                            [39.77090658, -5.41916089],
                            [39.77060913, -5.4191014],
                            [39.77040092, -5.41902915],
                            [39.77023945, -5.41885916],
                            [39.77017571, -5.41872317],
                            [39.77013746, -5.41859567],
                            [39.77008647, -5.41840868],
                            [39.77001423, -5.41821744],
                            [39.76997599, -5.41804745],
                            [39.76995475, -5.41787746],
                            [39.76990375, -5.41767772],
                            [39.76988676, -5.41757573],
                            [39.76985276, -5.41740999],
                            [39.76978902, -5.41715925],
                            [39.76974228, -5.41699776],
                            [39.76967429, -5.41670028],
                            [39.76965636, -5.4166046],
                            [39.7696488, -5.41656428],
                            [39.76961905, -5.41644104],
                            [39.76960631, -5.41639004],
                            [39.76953185, -5.41629903],
                            [39.76952982, -5.41629655],
                            [39.76944908, -5.4162243],
                            [39.7693301, -5.41616905],
                            [39.76921962, -5.41613931],
                            [39.76905815, -5.41613506],
                            [39.76888818, -5.41613506],
                            [39.7687437, -5.41614356],
                            [39.76856098, -5.41618605],
                            [39.76836976, -5.41626255],
                            [39.76830177, -5.41638154],
                            [39.76832727, -5.41672577],
                            [39.76825928, -5.41691701],
                            [39.76818704, -5.41707],
                            [39.76808081, -5.41722299],
                            [39.76796608, -5.41733349],
                            [39.76776636, -5.41739723],
                            [39.76763039, -5.41736749],
                            [39.76751141, -5.41733774],
                            [39.76738393, -5.41730799],
                            [39.76726495, -5.41727399],
                            [39.76703973, -5.41721874],
                            [39.76689101, -5.41720599],
                            [39.76678903, -5.41714225],
                            [39.76673591, -5.41710825],
                            [39.76670935, -5.41709125],
                            [39.76668279, -5.41707425],
                            [39.76660631, -5.41695101],
                            [39.76656382, -5.41678527],
                            [39.76653407, -5.41669602],
                            [39.76652982, -5.41654303],
                            [39.76652557, -5.41642404],
                            [39.76642359, -5.41628804],
                            [39.76629611, -5.41618605],
                            [39.76619838, -5.41610105],
                            [39.7661567, -5.41597006],
                            [39.76613889, -5.41591406],
                            [39.76615164, -5.41569308],
                            [39.7660709, -5.41558683],
                            [39.76602841, -5.41558258],
                            [39.76598591, -5.41561658],
                            [39.76592217, -5.41570583],
                            [39.76583294, -5.41572282],
                            [39.76579895, -5.41560808],
                            [39.76590943, -5.41547634],
                            [39.76594342, -5.41542109],
                            [39.76594342, -5.41534035],
                            [39.76589243, -5.4152426],
                            [39.76590518, -5.41513211],
                            [39.76596042, -5.41503861],
                            [39.76604965, -5.41486437],
                            [39.76613889, -5.41477938],
                            [39.76615164, -5.41467738],
                            [39.76618988, -5.41463913],
                            [39.76629611, -5.41453289],
                            [39.76638535, -5.41422691],
                            [39.76644909, -5.41386993],
                            [39.76643212, -5.41371299],
                            [39.76655219, -5.41389472],
                            [39.76664567, -5.41407746],
                            [39.76674341, -5.41434945],
                            [39.76679015, -5.41459594],
                            [39.76695162, -5.41504216],
                            [39.76704936, -5.4152929],
                            [39.76708516, -5.41538752],
                            [39.76710885, -5.41545014],
                            [39.76711522, -5.41556063],
                            [39.76712159, -5.41567113],
                            [39.76715559, -5.41598561],
                            [39.76709185, -5.41634259],
                            [39.76700261, -5.41664857],
                            [39.76696826, -5.41668293],
                            [39.76699055, -5.4167076],
                            [39.76758689, -5.4167353],
                            [39.7679417, -5.41662101],
                            [39.7681683, -5.41617958],
                            [39.7681535, -5.41569576],
                            [39.76843734, -5.41561002],
                            [39.76877793, -5.41548151],
                            [39.76922486, -5.41557891],
                            [39.76923238, -5.41558055],
                            [39.76947391, -5.41570832],
                            [39.76951688, -5.4160071],
                            [39.76965917, -5.41624884],
                            [39.77008524, -5.41636214],
                            [39.77028407, -5.41640457],
                            [39.77044035, -5.41648976],
                            [39.7706096, -5.41687014],
                            [39.77061121, -5.41687376],
                            [39.77069716, -5.41747133],
                            [39.77106637, -5.41752778],
                            [39.77112179, -5.41749297],
                            [39.77129336, -5.41738519],
                            [39.77153479, -5.4174418],
                            [39.77180479, -5.41762645],
                            [39.77193288, -5.41786821],
                            [39.77206116, -5.41826649],
                            [39.77218948, -5.41869324],
                            [39.77233183, -5.41897767],
                            [39.7725449, -5.41906278],
                            [39.77288563, -5.41904811],
                            [39.77315554, -5.41916161],
                            [39.77342528, -5.41914704],
                            [39.77365225, -5.41899021],
                            [39.77376563, -5.41883353],
                            [39.77396407, -5.41857713],
                            [39.7741767, -5.41830648],
                            [39.77421904, -5.4181072],
                            [39.77427552, -5.41786521],
                            [39.77437483, -5.41780816],
                            [39.77448827, -5.41769418],
                            [39.77472925, -5.41739503],
                            [39.7749987, -5.41715277],
                            [39.77518302, -5.41695331],
                            [39.77526792, -5.41672551],
                            [39.7751683, -5.41654064],
                            [39.77521064, -5.41634136],
                            [39.77536649, -5.41608502],
                            [39.77547994, -5.41598526],
                            [39.77566404, -5.41561503],
                            [39.77587659, -5.41528746],
                            [39.77610359, -5.4151591],
                            [39.77648698, -5.4152013],
                            [39.77668561, -5.4150872],
                            [39.77677054, -5.41488786],
                            [39.77689812, -5.41473117],
                            [39.77681282, -5.41464589],
                            [39.7770257, -5.41457447],
                            [39.77705386, -5.41438944],
                            [39.77732345, -5.41426102],
                            [39.77750773, -5.4140331],
                            [39.77779154, -5.41391889],
                            [39.77804672, -5.41361972],
                            [39.77821684, -5.41342028],
                            [39.77833022, -5.4132636],
                            [39.7784578, -5.4131069],
                            [39.77850006, -5.4128507],
                            [39.77858498, -5.41263713],
                            [39.77875506, -5.41240923],
                            [39.77891101, -5.41223826],
                            [39.77930807, -5.41186776],
                            [39.77940717, -5.41163995],
                            [39.7794352, -5.41135531],
                            [39.77950602, -5.41122714],
                            [39.77939211, -5.41097114],
                            [39.77919305, -5.41074371],
                            [39.77922129, -5.4106156],
                            [39.77913606, -5.41058725],
                            [39.77906493, -5.4104735],
                            [39.77913586, -5.41043072],
                            [39.77929204, -5.41043052],
                            [39.77950509, -5.41050139],
                            [39.77973224, -5.41048687],
                            [39.77993084, -5.41035854],
                            [39.78027124, -5.41008773],
                            [39.78072523, -5.40983099],
                            [39.78103718, -5.40951752],
                            [39.78103683, -5.40924715],
                            [39.78107913, -5.40901941],
                            [39.7811922, -5.40862081],
                            [39.78143336, -5.40846397],
                            [39.78178812, -5.4083212],
                            [39.78208588, -5.40802198],
                            [39.78235535, -5.40779395],
                            [39.78259606, -5.40729558],
                            [39.78273782, -5.40712463],
                            [39.78285098, -5.40679718],
                            [39.78302082, -5.40638428],
                            [39.78311967, -5.40597147],
                            [39.78327548, -5.40568667],
                            [39.78333192, -5.40541622],
                            [39.78338844, -5.40520269],
                            [39.78350187, -5.4050887],
                            [39.7835726, -5.40488938],
                            [39.78362916, -5.40470431],
                            [39.78369992, -5.40453346],
                            [39.78399796, -5.40444769],
                            [39.78425335, -5.40431929],
                            [39.78467928, -5.40431874],
                            [39.78489209, -5.40420462],
                            [39.78494857, -5.40396263],
                            [39.78503366, -5.40389137],
                            [39.7849907, -5.40360681],
                            [39.78489107, -5.40340772],
                            [39.7849051, -5.40327963],
                            [39.78500432, -5.40315143],
                            [39.78518861, -5.40293773],
                            [39.78533027, -5.40269563],
                            [39.78569928, -5.40259554],
                            [39.78599733, -5.402524],
                            [39.7862952, -5.40231016],
                            [39.78649364, -5.40205376],
                            [39.78680552, -5.4016976],
                            [39.78741518, -5.40105644],
                            [39.78775544, -5.40068601],
                            [39.78810986, -5.4002871],
                            [39.78839331, -5.39990251],
                            [39.78867711, -5.3997883],
                            [39.78883292, -5.39950349],
                            [39.78900275, -5.39909059],
                            [39.78925786, -5.39874873],
                            [39.78956994, -5.3985491],
                            [39.78972566, -5.39819314],
                            [39.78999496, -5.39785126],
                            [39.7901364, -5.39743839],
                            [39.79057567, -5.39678323],
                            [39.79098667, -5.39622771],
                            [39.79131284, -5.39594268],
                            [39.79131243, -5.39562961],
                            [39.7914679, -5.39508865],
                            [39.79156658, -5.39454777],
                            [39.79192131, -5.39439078],
                            [39.79250331, -5.39431886],
                            [39.79265918, -5.39409098],
                            [39.79267286, -5.39369251],
                            [39.79282883, -5.39353577],
                            [39.79326855, -5.39323636],
                            [39.79333917, -5.39295166],
                            [39.79350929, -5.39276644],
                            [39.7935089, -5.39246761],
                            [39.79370731, -5.39219697],
                            [39.79365038, -5.3920832],
                            [39.79340914, -5.3921689],
                            [39.79326716, -5.39216909],
                            [39.79303973, -5.39195593],
                            [39.79282652, -5.39175698],
                            [39.7926484, -5.39165018],
                            [39.79254235, -5.39158659],
                            [39.79232932, -5.39152994],
                            [39.79221563, -5.39144471],
                            [39.79220116, -5.39123127],
                            [39.79221517, -5.39108895],
                            [39.79221486, -5.39084704],
                            [39.79205817, -5.39044879],
                            [39.79178806, -5.39016453],
                            [39.79143304, -5.39009385],
                            [39.79126278, -5.39017945],
                            [39.79103555, -5.39012282],
                            [39.79073749, -5.39018013],
                            [39.79053875, -5.39019462],
                            [39.79048185, -5.39010931],
                            [39.79055269, -5.38999538],
                            [39.79068031, -5.38988137],
                            [39.79046732, -5.38985319],
                            [39.79019757, -5.38985354],
                            [39.78998454, -5.38979689],
                            [39.78978582, -5.38982561],
                            [39.78964398, -5.38992541],
                            [39.78950202, -5.38993982],
                            [39.78926067, -5.38994013],
                            [39.78906182, -5.38986924],
                            [39.78879204, -5.38984113],
                            [39.7886074, -5.38978445],
                            [39.788522, -5.38961379],
                            [39.78855004, -5.38934338],
                            [39.78857763, -5.38926007],
                            [39.78860661, -5.38917254],
                            [39.78877678, -5.38901579],
                            [39.78914581, -5.38894416],
                            [39.78950059, -5.38882985],
                            [39.78974176, -5.38868724],
                            [39.78979842, -5.38858755],
                            [39.78972721, -5.38841688],
                            [39.78965584, -5.38811814],
                            [39.78968396, -5.38790464],
                            [39.78969782, -5.38764848],
                            [39.78985368, -5.38740636],
                            [39.79000957, -5.3871927],
                            [39.79009442, -5.38693645],
                            [39.7902361, -5.38670858],
                            [39.79036376, -5.38662303],
                            [39.79044861, -5.38636677],
                            [39.79050511, -5.38613901],
                            [39.79063255, -5.3858827],
                            [39.79087344, -5.38552663],
                            [39.79115709, -5.38529858],
                            [39.79151194, -5.38524119],
                            [39.79182403, -5.38505579],
                            [39.79206514, -5.38487048],
                            [39.7924624, -5.38467074],
                            [39.79276035, -5.38452805],
                            [39.79278842, -5.38427187],
                            [39.79271715, -5.38405851],
                            [39.79274525, -5.38383078],
                            [39.79285875, -5.38377371],
                            [39.79312859, -5.38384451],
                            [39.79322788, -5.38377323],
                            [39.79324187, -5.38361668],
                            [39.79329858, -5.38355969],
                            [39.79332703, -5.38360234],
                            [39.79351154, -5.38355941],
                            [39.7935682, -5.38345972],
                            [39.79356798, -5.38328896],
                            [39.79359615, -5.38311816],
                            [39.79369536, -5.38298996],
                            [39.79378038, -5.38286177],
                            [39.79378019, -5.38271947],
                            [39.79373727, -5.38246338],
                            [39.79359493, -5.38217896],
                            [39.79359454, -5.38188012],
                            [39.79363689, -5.38169507],
                            [39.79363665, -5.38151008],
                            [39.7935655, -5.3813821],
                            [39.79338258, -5.38118703],
                            [39.79335225, -5.38115469],
                            [39.7933581, -5.38113409],
                            [39.79338052, -5.38105504],
                            [39.79355072, -5.38092674],
                            [39.79340845, -5.38069924],
                            [39.79320916, -5.38028682],
                            [39.79316246, -5.38021543],
                            [39.79301155, -5.37998474],
                            [39.79296733, -5.37991715],
                            [39.79303813, -5.37977475],
                            [39.79312295, -5.37949003],
                            [39.79308006, -5.37926241],
                            [39.79305221, -5.37924042],
                            [39.79281004, -5.3790493],
                            [39.79269617, -5.37882176],
                            [39.79268901, -5.37872298],
                            [39.79268172, -5.37862256],
                            [39.7925366, -5.37846539],
                            [39.79251112, -5.37843778],
                            [39.79236913, -5.37842374],
                            [39.79236887, -5.37822451],
                            [39.79229759, -5.37799692],
                            [39.79207015, -5.37776953],
                            [39.79185703, -5.37764173],
                            [39.79180004, -5.37748528],
                            [39.79185676, -5.37742828],
                            [39.79198447, -5.37738542],
                            [39.79201264, -5.37721462],
                            [39.79202192, -5.37712043],
                            [39.79204067, -5.37692998],
                            [39.79205448, -5.37663112],
                            [39.7921961, -5.37636056],
                            [39.79215334, -5.37623254],
                            [39.79191191, -5.37616171],
                            [39.79177005, -5.37624727],
                            [39.79165625, -5.37607666],
                            [39.79149983, -5.37587763],
                            [39.79130109, -5.37589212],
                            [39.79122235, -5.37597521],
                            [39.79101754, -5.37619133],
                            [39.79082074, -5.37647935],
                            [39.79059421, -5.37696032],
                            [39.79059489, -5.37749158],
                            [39.79046935, -5.37799771],
                            [39.7901417, -5.37835232],
                            [39.78986444, -5.37863096],
                            [39.78948618, -5.37888443],
                            [39.78946684, -5.37889415],
                            [39.78903217, -5.37911271],
                            [39.78898886, -5.37925225],
                            [39.7889065, -5.37951764],
                            [39.78885661, -5.37997308],
                            [39.78880659, -5.38032732],
                            [39.78874541, -5.38045035],
                            [39.78863037, -5.38068173],
                            [39.78863051, -5.38079299],
                            [39.78863102, -5.3811877],
                            [39.78837905, -5.3815169],
                            [39.78769789, -5.38174547],
                            [39.78726916, -5.38199901],
                            [39.78681534, -5.38237907],
                            [39.78646209, -5.38245542],
                            [39.78644826, -5.38245336],
                            [39.78595721, -5.38238018],
                            [39.78527582, -5.38243165],
                            [39.78464511, -5.38263485],
                            [39.78409043, -5.38309094],
                            [39.78376281, -5.38347084],
                            [39.78371278, -5.38382508],
                            [39.78376378, -5.38422979],
                            [39.78373896, -5.3845587],
                            [39.78343638, -5.38478678],
                            [39.78320955, -5.38504006],
                            [39.78283106, -5.38511644],
                            [39.78280547, -5.38483819],
                            [39.78247726, -5.38476271],
                            [39.78199833, -5.385244],
                            [39.78174649, -5.3856744],
                            [39.78136868, -5.38628205],
                            [39.78058739, -5.3871685],
                            [39.78054006, -5.38716223],
                            [39.78044095, -5.38737581],
                            [39.78037006, -5.38744706],
                            [39.78035609, -5.38763207],
                            [39.78052657, -5.38771723],
                            [39.78061172, -5.38768866],
                            [39.7806262, -5.38791633],
                            [39.78078266, -5.38814382],
                            [39.78079702, -5.38827187],
                            [39.78069793, -5.38849969],
                            [39.78059877, -5.38867058],
                            [39.78048529, -5.38874187],
                            [39.78041423, -5.38868504],
                            [39.78044246, -5.38855693],
                            [39.78038536, -5.38831509],
                            [39.78027149, -5.38808755],
                            [39.78015775, -5.38795962],
                            [39.78000137, -5.38778906],
                            [39.77978832, -5.38771818],
                            [39.77964637, -5.38773259],
                            [39.77936262, -5.38788949],
                            [39.77914992, -5.38808899],
                            [39.77902229, -5.38820299],
                            [39.77885225, -5.38845936],
                            [39.77880995, -5.3886871],
                            [39.77876752, -5.38881523],
                            [39.77866821, -5.38887228],
                            [39.77856864, -5.38871587],
                            [39.77835571, -5.3887446],
                            [39.77795847, -5.38895857],
                            [39.77746191, -5.38922958],
                            [39.77720643, -5.38928683],
                            [39.77699366, -5.3894294],
                            [39.77697964, -5.38957173],
                            [39.77699407, -5.3897567],
                            [39.77699434, -5.38997016],
                            [39.77686673, -5.39009839],
                            [39.77675317, -5.39011277],
                            [39.77672471, -5.39005588],
                            [39.77655447, -5.39015571],
                            [39.77636999, -5.3902271],
                            [39.77620303, -5.39025685],
                            [39.77612869, -5.3902701],
                            [39.77591586, -5.39036999],
                            [39.77571725, -5.39048408],
                            [39.77546193, -5.3906694],
                            [39.77537699, -5.39077183],
                            [39.77532018, -5.39084035],
                            [39.7752211, -5.39108239],
                            [39.77519602, -5.39123441],
                            [39.77517884, -5.39133859],
                            [39.77516487, -5.39152361],
                            [39.77517934, -5.39173704],
                            [39.77492392, -5.39183698],
                            [39.77464035, -5.39213618],
                            [39.77456955, -5.39227858],
                            [39.77459828, -5.39254892],
                            [39.77447056, -5.39259177],
                            [39.77434299, -5.39274847],
                            [39.77417318, -5.39318983],
                            [39.77424444, -5.39340319],
                            [39.77410266, -5.39355991],
                            [39.77381888, -5.39368834],
                            [39.77354926, -5.3937883],
                            [39.77340779, -5.39418693],
                            [39.77338017, -5.39479887],
                            [39.77353656, -5.39496944],
                            [39.77393424, -5.39509701],
                            [39.77400555, -5.39535306],
                            [39.77394916, -5.3956662],
                            [39.77386418, -5.39582285],
                            [39.77373609, -5.39558109],
                            [39.77349486, -5.39568101],
                            [39.77335318, -5.39590888],
                            [39.77322582, -5.39623634],
                            [39.77326854, -5.3963359],
                            [39.77356672, -5.39636398],
                            [39.77369458, -5.39643497],
                            [39.77359535, -5.39654894],
                            [39.77322622, -5.39654941],
                            [39.77295665, -5.39669206],
                            [39.77285757, -5.3969341],
                            [39.77261656, -5.39720478],
                            [39.77236117, -5.39733318],
                            [39.77226197, -5.39747561],
                            [39.77221954, -5.39760374],
                            [39.77207738, -5.39746162],
                            [39.77176517, -5.39756163],
                            [39.77149574, -5.39781812],
                            [39.77128325, -5.39818838],
                            [39.77108479, -5.39843055],
                            [39.77082954, -5.39867279],
                            [39.77063103, -5.39887226],
                            [39.77043237, -5.3989579],
                            [39.77016275, -5.39905785],
                            [39.76982239, -5.39935712],
                            [39.76965231, -5.39958503],
                            [39.76976615, -5.39979834],
                            [39.76976648, -5.40005449],
                            [39.76958202, -5.4001401],
                            [39.76931184, -5.39979891],
                            [39.76911305, -5.39978494],
                            [39.76892865, -5.39991324],
                            [39.76868731, -5.39992778],
                            [39.76851716, -5.40009876],
                            [39.76846069, -5.40035498],
                            [39.76836111, -5.40019857],
                            [39.76813397, -5.40021309],
                            [39.76780768, -5.40041273],
                            [39.76742445, -5.40049859],
                            [39.76726851, -5.40068379],
                            [39.76722395, -5.40076117],
                            [39.76711268, -5.40095436],
                            [39.76691454, -5.40145268],
                            [39.76681563, -5.40182279],
                            [39.76688695, -5.40209308],
                            [39.76696404, -5.40222405],
                            [39.76700684, -5.40229677],
                            [39.76702923, -5.40233482],
                            [39.76711474, -5.40259086],
                            [39.76717187, -5.40286116],
                            [39.76727156, -5.40310296],
                            [39.76765495, -5.40314516],
                            [39.76772613, -5.40330161],
                            [39.76751329, -5.40340149],
                            [39.76715866, -5.40364385],
                            [39.76703128, -5.40395708],
                            [39.76708824, -5.40409932],
                            [39.76714516, -5.40419886],
                            [39.76717382, -5.40441228],
                            [39.7669609, -5.40444101],
                            [39.76680476, -5.40446967],
                            [39.76660612, -5.40456953],
                            [39.76650693, -5.40472619],
                            [39.76662073, -5.40489681],
                            [39.76677708, -5.40503892],
                            [39.7668341, -5.40522384],
                            [39.76669213, -5.40522402],
                            [39.7664505, -5.40501087],
                            [39.76636519, -5.40491137],
                            [39.76622341, -5.40506808],
                            [39.76608176, -5.40532441],
                            [39.7657697, -5.40555249],
                            [39.76532993, -5.40583765],
                            [39.76493252, -5.40593776],
                            [39.76456367, -5.40616592],
                            [39.76440771, -5.40633688],
                            [39.76427988, -5.40629435],
                            [39.7641379, -5.40629453],
                            [39.76402441, -5.40636582],
                            [39.76402471, -5.40660774],
                            [39.76366999, -5.40677895],
                            [39.76345717, -5.40689306],
                            [39.76321606, -5.40709259],
                            [39.76308856, -5.40732044],
                            [39.76297546, -5.4077048],
                            [39.76304674, -5.4079324],
                            [39.76290497, -5.40810335],
                            [39.76276348, -5.40848775],
                            [39.76279221, -5.40875809],
                            [39.76297717, -5.40907092],
                            [39.76331835, -5.40941203],
                            [39.76337318, -5.4096307],
                            [39.76338969, -5.40969654],
                            [39.763603, -5.40996665],
                            [39.76371683, -5.41016574],
                            [39.76373126, -5.41035071],
                            [39.76393008, -5.41039315],
                            [39.76415712, -5.41029326],
                            [39.76442668, -5.41013638],
                            [39.76453456, -5.41018612],
                            [39.76461135, -5.41022153],
                            [39.76483848, -5.41019278],
                            [39.76539248, -5.41041977],
                            [39.76579023, -5.41059003],
                            [39.76614573, -5.41103073],
                            [39.76637359, -5.41158542],
                            [39.7663029, -5.41182743],
                            [39.76638856, -5.41219731],
                            [39.76638891, -5.41248192],
                            [39.76650307, -5.41293715],
                            [39.76647507, -5.41325026],
                            [39.76640789, -5.41343051],
                            [39.76640235, -5.41333446],
                            [39.76634286, -5.41317721],
                            [39.76624512, -5.41292648],
                            [39.76608365, -5.41248025],
                            [39.76603691, -5.41223377],
                            [39.76593918, -5.41196178],
                            [39.76584569, -5.41177904],
                            [39.76568847, -5.41154105],
                            [39.76555249, -5.41148581],
                            [39.76522105, -5.41142631],
                            [39.76515731, -5.41150705],
                            [39.76514031, -5.41158355],
                            [39.76505108, -5.4115963],
                            [39.7648896, -5.41159205],
                            [39.76478792, -5.41153394],
                            [39.76477062, -5.41152405],
                            [39.76457941, -5.4115028],
                            [39.76437969, -5.41149005],
                            [39.76422671, -5.41149855],
                            [39.76416298, -5.4115028],
                            [39.76398451, -5.4115878],
                            [39.76382728, -5.41161755],
                            [39.76381837, -5.41161825],
                            [39.76366581, -5.4116303],
                            [39.76352983, -5.4116133],
                            [39.76347884, -5.41156655],
                            [39.7634406, -5.41149005],
                            [39.76328453, -5.41130756],
                            [39.76316439, -5.41116707],
                            [39.76310065, -5.41112457],
                            [39.76303691, -5.41111182],
                            [39.76299442, -5.41103108],
                            [39.76291793, -5.41089508],
                            [39.7628372, -5.41082709],
                            [39.76277771, -5.41069535],
                            [39.76270122, -5.4106996],
                            [39.76255675, -5.41057635],
                            [39.76248451, -5.4105211],
                            [39.76241227, -5.41047861],
                            [39.76232304, -5.41046161],
                            [39.76222105, -5.41048286],
                            [39.76209357, -5.4105126],
                            [39.76195335, -5.41061035],
                            [39.76187686, -5.4106401],
                            [39.76178338, -5.4106741],
                            [39.76168564, -5.41072509],
                            [39.76167714, -5.41081859],
                            [39.7616559, -5.41084834],
                            [39.76157941, -5.41082709],
                            [39.76147743, -5.41079734],
                            [39.7613287, -5.41079734],
                            [39.76124372, -5.41081009],
                            [39.76110349, -5.41082284],
                            [39.76100151, -5.41084408],
                            [39.76089952, -5.41087383],
                            [39.76082304, -5.41092908],
                            [39.76074655, -5.41098433],
                            [39.76066156, -5.41105232],
                            [39.76060207, -5.41105657],
                            [39.76052983, -5.41104807],
                            [39.76050009, -5.41102682],
                            [39.76048734, -5.41099708],
                            [39.7604491, -5.41093333],
                            [39.76041935, -5.41085683],
                            [39.76038961, -5.41070809],
                            [39.76036836, -5.41062735],
                            [39.76029188, -5.41057635],
                            [39.7601729, -5.41055085],
                            [39.7601304, -5.4105551],
                            [39.76005816, -5.4106061],
                            [39.76001992, -5.4105636],
                            [39.76000717, -5.41048285],
                            [39.75994768, -5.41047435],
                            [39.75991369, -5.4105126],
                            [39.7598372, -5.4106061],
                            [39.75977346, -5.41072084],
                            [39.75973522, -5.41062734],
                            [39.75972247, -5.4105296],
                            [39.75965023, -5.41052535],
                            [39.75953125, -5.41066984],
                            [39.75949301, -5.41070384],
                            [39.75944202, -5.41072084],
                            [39.75936553, -5.41070384],
                            [39.75936553, -5.41066559],
                            [39.75936553, -5.41063159],
                            [39.75931029, -5.4105976],
                            [39.75932304, -5.4105721],
                            [39.75943352, -5.41050835],
                            [39.75953975, -5.41034686],
                            [39.75959074, -5.41028311],
                            [39.75959942, -5.41027643],
                            [39.75964598, -5.41024062],
                            [39.75967998, -5.41014287],
                            [39.75963324, -5.41004088],
                            [39.759527, -5.41000263],
                            [39.75948451, -5.40992613],
                            [39.75946751, -5.40986239],
                            [39.75941227, -5.40978589],
                            [39.75938646, -5.40975546],
                            [39.75929329, -5.40964565],
                            [39.75918281, -5.4096414],
                            [39.75912757, -5.4096499],
                            [39.75906808, -5.4096924],
                            [39.7589746, -5.40980714],
                            [39.75896185, -5.40973914],
                            [39.7589321, -5.40964565],
                            [39.7589406, -5.40955215],
                            [39.75897035, -5.40946716],
                            [39.75899584, -5.40929717],
                            [39.7589661, -5.40928017],
                            [39.75888961, -5.40924192],
                            [39.7586814, -5.40924617],
                            [39.75854542, -5.40925467],
                            [39.75834995, -5.40928017],
                            [39.75824372, -5.40926317],
                            [39.75814599, -5.40928442],
                            [39.75806525, -5.40936516],
                            [39.75798451, -5.40936941],
                            [39.75789103, -5.40929717],
                            [39.75772956, -5.40928442],
                            [39.75761908, -5.40926742],
                            [39.75759358, -5.40916542],
                            [39.7575001, -5.40916542],
                            [39.75740236, -5.40918242],
                            [39.75734712, -5.40919942],
                            [39.75727488, -5.40916542],
                            [39.75725364, -5.40908043],
                            [39.75709641, -5.40901243],
                            [39.75702842, -5.40903368],
                            [39.75693069, -5.40906768],
                            [39.75684571, -5.40899968],
                            [39.75674797, -5.40892743],
                            [39.75660775, -5.40883819],
                            [39.75631879, -5.40872344],
                            [39.75618282, -5.40865545],
                            [39.75607658, -5.4085832],
                            [39.75582588, -5.40848971],
                            [39.75572814, -5.40851096],
                            [39.75562616, -5.40859595],
                            [39.75551568, -5.4086257],
                            [39.75540095, -5.40861295],
                            [39.75530321, -5.4085747],
                            [39.75524372, -5.40853645],
                            [39.75520123, -5.40855345],
                            [39.75516724, -5.4085407],
                            [39.75515024, -5.40853433],
                            [39.75513324, -5.40852795],
                            [39.75509925, -5.40843871],
                            [39.75518848, -5.40840046],
                            [39.75526922, -5.40833246],
                            [39.75526072, -5.40825172],
                            [39.75522673, -5.40815822],
                            [39.75514599, -5.40809448],
                            [39.7550865, -5.40800948],
                            [39.75502701, -5.40788624],
                            [39.75490803, -5.40779274],
                            [39.75485279, -5.4077545],
                            [39.7547763, -5.40781399],
                            [39.75475081, -5.40772475],
                            [39.75467857, -5.40758025],
                            [39.7545256, -5.40746126],
                            [39.75440237, -5.40738477],
                            [39.75429189, -5.40734652],
                            [39.75425364, -5.40740601],
                            [39.75413041, -5.40735927],
                            [39.75390095, -5.40727427],
                            [39.75375223, -5.40725727],
                            [39.75363325, -5.40736352],
                            [39.75354826, -5.40738051],
                            [39.75346752, -5.40731252],
                            [39.75345053, -5.40723177],
                            [39.75353126, -5.40712128],
                            [39.75354789, -5.40704525],
                            [39.75357376, -5.40704478],
                            [39.75362475, -5.40698529],
                            [39.7536205, -5.40686629],
                            [39.75356101, -5.40676005],
                            [39.75354401, -5.4067388],
                            [39.75348452, -5.4067303],
                            [39.75343778, -5.40676855],
                            [39.75337404, -5.40685779],
                            [39.75328056, -5.40692154],
                            [39.75316158, -5.40696829],
                            [39.75306809, -5.40701078],
                            [39.75297461, -5.40706178],
                            [39.75286837, -5.40711703],
                            [39.75276214, -5.40713403],
                            [39.75257092, -5.40711703],
                            [39.75250719, -5.40703628],
                            [39.75255818, -5.40699378],
                            [39.75271965, -5.40693854],
                            [39.75290237, -5.40690454],
                            [39.75305109, -5.40681954],
                            [39.7530341, -5.40667505],
                            [39.75298736, -5.40664955],
                            [39.75283863, -5.40654331],
                            [39.75271115, -5.40644556],
                            [39.75260492, -5.40640732],
                            [39.75250719, -5.40638182],
                            [39.75241795, -5.40633082],
                            [39.75230747, -5.40621183],
                            [39.75225223, -5.40612683],
                            [39.75218849, -5.40604184],
                            [39.75223948, -5.40599509],
                            [39.75234146, -5.40603334],
                            [39.75237546, -5.40606733],
                            [39.75238821, -5.40608008],
                            [39.75246469, -5.40611833],
                            [39.75258367, -5.40614808],
                            [39.7527409, -5.40621183],
                            [39.75289387, -5.40631807],
                            [39.75315765, -5.40641157],
                            [39.75326781, -5.40640307],
                            [39.75330269, -5.40635074],
                            [39.7533103, -5.40633932],
                            [39.7533018, -5.40624158],
                            [39.75327206, -5.40609708],
                            [39.75317008, -5.40597809],
                            [39.7530001, -5.40577835],
                            [39.75285563, -5.40565511],
                            [39.75271965, -5.40560836],
                            [39.75264741, -5.40551487],
                            [39.75261767, -5.40539587],
                            [39.75251144, -5.40530238],
                            [39.7524222, -5.40533213],
                            [39.75230322, -5.40541287],
                            [39.75219699, -5.40548512],
                            [39.75207376, -5.40553186],
                            [39.75193353, -5.40553611],
                            [39.7518188, -5.40547237],
                            [39.75174656, -5.40537887],
                            [39.75171682, -5.40528538],
                            [39.75175506, -5.40521313],
                            [39.7518103, -5.40528963],
                            [39.75193353, -5.40541287],
                            [39.75203552, -5.40547662],
                            [39.752146, -5.40542137],
                            [39.75232872, -5.40515788],
                            [39.75235421, -5.40505164],
                            [39.75227348, -5.40490715],
                            [39.75215025, -5.40467341],
                            [39.75204402, -5.40459267],
                            [39.75193778, -5.40458842],
                            [39.75186555, -5.40458417],
                            [39.75182305, -5.40449492],
                            [39.75172532, -5.40447367],
                            [39.75158934, -5.40435893],
                            [39.75144062, -5.40434193],
                            [39.75140662, -5.40438018],
                            [39.75138113, -5.40444392],
                            [39.75135138, -5.40449067],
                            [39.75130889, -5.40445667],
                            [39.7512664, -5.40435893],
                            [39.7512239, -5.40425268],
                            [39.75116866, -5.40422293],
                            [39.75111767, -5.40423143],
                            [39.75108368, -5.40432068],
                            [39.75107943, -5.40445242],
                            [39.75105393, -5.40458416],
                            [39.75100294, -5.40460116],
                            [39.7509562, -5.40459266],
                            [39.7509562, -5.40456717],
                            [39.75095195, -5.40452042],
                            [39.75097745, -5.40433768],
                            [39.7509732, -5.40426118],
                            [39.7509307, -5.40412519],
                            [39.75090096, -5.4040147],
                            [39.75087546, -5.40391695],
                            [39.75080323, -5.40389995],
                            [39.75073524, -5.4038702],
                            [39.75068, -5.40375971],
                            [39.750646, -5.40359397],
                            [39.75070974, -5.40333048],
                            [39.75079048, -5.40312649],
                            [39.75084997, -5.4030075],
                            [39.75082872, -5.40288851],
                            [39.75076073, -5.40282051],
                            [39.75070549, -5.40274401],
                            [39.7506375, -5.40264627],
                            [39.75051003, -5.40259952],
                            [39.75028481, -5.40263352],
                            [39.75014884, -5.40269727],
                            [39.7500681, -5.40282476],
                            [39.75002136, -5.40282476],
                            [39.74991937, -5.40285451],
                            [39.74988697, -5.40288601],
                            [39.7497664, -5.40300325],
                            [39.7497409, -5.40311799],
                            [39.74983864, -5.40322424],
                            [39.74980039, -5.40325399],
                            [39.74966442, -5.40318599],
                            [39.74959218, -5.40319024],
                            [39.74951144, -5.40323274],
                            [39.74948595, -5.40332198],
                            [39.74942646, -5.40334323],
                            [39.74932022, -5.40329223],
                            [39.74923524, -5.40338998],
                            [39.7491375, -5.40358122],
                            [39.74905252, -5.40373421],
                            [39.74906952, -5.40388295],
                            [39.74908651, -5.40414644],
                            [39.74912476, -5.40429518],
                            [39.74909501, -5.40443542],
                            [39.74903127, -5.40452891],
                            [39.74908226, -5.40464791],
                            [39.74910846, -5.40482149],
                            [39.74911626, -5.40487315],
                            [39.74914175, -5.40495389],
                            [39.749129, -5.40505164],
                            [39.74906102, -5.40517063],
                            [39.74895903, -5.40531087],
                            [39.7488443, -5.40543411],
                            [39.74872532, -5.40543836],
                            [39.74865096, -5.40544261],
                            [39.7485766, -5.40544686],
                            [39.74844487, -5.40544686],
                            [39.74839813, -5.40545749],
                            [39.74835138, -5.40546811],
                            [39.7482834, -5.40543836],
                            [39.74823665, -5.40539162],
                            [39.74816442, -5.40544686],
                            [39.74805818, -5.40548936],
                            [39.74795195, -5.40554461],
                            [39.74785847, -5.40561685],
                            [39.74779048, -5.40560835],
                            [39.74770124, -5.4055786],
                            [39.747663, -5.40554036],
                            [39.74754402, -5.40552761],
                            [39.74742079, -5.40550211],
                            [39.7473358, -5.40547661],
                            [39.74726357, -5.40544261],
                            [39.74715733, -5.40537886],
                            [39.74704685, -5.40531087],
                            [39.74697461, -5.40526412],
                            [39.74691937, -5.40531087],
                            [39.74694487, -5.40525137],
                            [39.74696187, -5.40518763],
                            [39.74686413, -5.40506438],
                            [39.74681314, -5.40494539],
                            [39.7467834, -5.4047754],
                            [39.7467409, -5.40463091],
                            [39.74671966, -5.40450341],
                            [39.74665167, -5.40441842],
                            [39.74657093, -5.40433767],
                            [39.7464562, -5.40432492],
                            [39.74632872, -5.40432917],
                            [39.74619275, -5.40435042],
                            [39.74609419, -5.40436028],
                            [39.74602278, -5.40436742],
                            [39.7458783, -5.40438867],
                            [39.74573382, -5.40453316],
                            [39.74561909, -5.40470315],
                            [39.74553411, -5.40488164],
                            [39.74550703, -5.4049911],
                            [39.74543212, -5.40529387],
                            [39.74521966, -5.40573159],
                            [39.74504544, -5.40599083],
                            [39.74480323, -5.40619057],
                            [39.74448453, -5.40633506],
                            [39.7443528, -5.40635631],
                            [39.74416158, -5.40639455],
                            [39.74386838, -5.40636906],
                            [39.74355819, -5.4063818],
                            [39.74327348, -5.40632656],
                            [39.74309076, -5.40632656],
                            [39.74304899, -5.40633263],
                            [39.74313189, -5.40626355],
                            [39.74327565, -5.40624758],
                            [39.74348332, -5.40624758],
                            [39.74359514, -5.40629018],
                            [39.74372826, -5.40629018],
                            [39.74389333, -5.40629018],
                            [39.74409034, -5.40629018],
                            [39.74432463, -5.40624758],
                            [39.7444684, -5.406189],
                            [39.74469737, -5.40611444],
                            [39.74493698, -5.40594936],
                            [39.74500088, -5.40588545],
                            [39.74510737, -5.40575765],
                            [39.74515529, -5.40559256],
                            [39.74517659, -5.4054754],
                            [39.74530439, -5.40521979],
                            [39.74528841, -5.40506003],
                            [39.74530705, -5.40499346],
                            [39.74531969, -5.40494832],
                            [39.74532569, -5.40492689],
                            [39.74537894, -5.40479376],
                            [39.74540023, -5.40476713],
                            [39.74548011, -5.40467127],
                            [39.74548011, -5.40457542],
                            [39.74552803, -5.40449021],
                            [39.74563452, -5.40442098],
                            [39.74574634, -5.40433045],
                            [39.74585816, -5.40431448],
                            [39.74596466, -5.40426122],
                            [39.74606583, -5.40419732],
                            [39.74624155, -5.40416004],
                            [39.74641194, -5.40413341],
                            [39.74657168, -5.40420264],
                            [39.74657244, -5.40420301],
                            [39.74671545, -5.40427187],
                            [39.74682727, -5.40438371],
                            [39.74691247, -5.40447956],
                            [39.74694974, -5.40462867],
                            [39.74706156, -5.40474051],
                            [39.74714143, -5.40486299],
                            [39.74725325, -5.40501743],
                            [39.74731182, -5.40511328],
                            [39.74741299, -5.40523044],
                            [39.74748221, -5.40535292],
                            [39.74756209, -5.40537955],
                            [39.74767391, -5.40540085],
                            [39.74774845, -5.40540085],
                            [39.74790287, -5.405305],
                            [39.74811054, -5.40519849],
                            [39.74822236, -5.40516121],
                            [39.74824898, -5.40516121],
                            [39.74830223, -5.40516121],
                            [39.7484034, -5.40517719],
                            [39.74850457, -5.40514524],
                            [39.74863769, -5.40508666],
                            [39.74882405, -5.4050121],
                            [39.74888263, -5.40493755],
                            [39.74889328, -5.40479376],
                            [39.74878678, -5.40453282],
                            [39.74871223, -5.40427188],
                            [39.74864301, -5.40396833],
                            [39.74857379, -5.4038565],
                            [39.748446, -5.40382987],
                            [39.74849392, -5.40376597],
                            [39.74859509, -5.40369141],
                            [39.74865366, -5.40358491],
                            [39.74865899, -5.40351568],
                            [39.74864301, -5.40344645],
                            [39.74855249, -5.40345177],
                            [39.74846197, -5.40348372],
                            [39.7484247, -5.40340917],
                            [39.74846197, -5.40337189],
                            [39.7485099, -5.40328136],
                            [39.74846197, -5.40316953],
                            [39.74843002, -5.4031003],
                            [39.74842031, -5.40307655],
                            [39.7483821, -5.40298314],
                            [39.74837145, -5.40290859],
                            [39.74844067, -5.40287131],
                            [39.74851338, -5.4029004],
                            [39.74852055, -5.40290326],
                            [39.74869626, -5.40307367],
                            [39.74885068, -5.4031429],
                            [39.74895718, -5.4031429],
                            [39.7490477, -5.40311095],
                            [39.74918614, -5.40303107],
                            [39.74933523, -5.40298847],
                            [39.74944173, -5.40290859],
                            [39.7495642, -5.40277545],
                            [39.74957421, -5.4027374],
                            [39.74959082, -5.40267427],
                            [39.74964407, -5.40262102],
                            [39.74979849, -5.40254646],
                            [39.7500381, -5.40241333],
                            [39.75014992, -5.40239203],
                            [39.75022181, -5.40236274],
                            [39.75029369, -5.40233345],
                            [39.75042148, -5.40234943],
                            [39.75049337, -5.40235741],
                            [39.75056525, -5.4023654],
                            [39.7507037, -5.40243463],
                            [39.75083149, -5.40247191],
                            [39.75091669, -5.40246126],
                            [39.75093266, -5.40239735],
                            [39.75092734, -5.40230682],
                            [39.75092734, -5.40224292],
                            [39.75095929, -5.40220032],
                            [39.75102327, -5.40222163],
                            [39.75102318, -5.4022802],
                            [39.75105513, -5.40239203],
                            [39.75114565, -5.40258374],
                            [39.75121487, -5.4026583],
                            [39.75116695, -5.40285001],
                            [39.75125215, -5.40295652],
                            [39.75130007, -5.40308433],
                            [39.75130007, -5.40308965],
                            [39.75126279, -5.40315356],
                            [39.75131072, -5.40324409],
                            [39.75140656, -5.40328669],
                            [39.75168878, -5.40329734],
                            [39.75181124, -5.40329202],
                            [39.75193371, -5.40331864],
                            [39.75206683, -5.40336125],
                            [39.75216268, -5.40342515],
                            [39.7522532, -5.40341983],
                            [39.75226917, -5.40329734],
                            [39.7522958, -5.40317486],
                            [39.75230112, -5.4030364],
                            [39.75234372, -5.40296717],
                            [39.75229047, -5.40285001],
                            [39.7522319, -5.40278611],
                            [39.75214138, -5.40272753],
                            [39.75202424, -5.40266895],
                            [39.75193904, -5.40259972],
                            [39.7518006, -5.40249321],
                            [39.75166215, -5.40242398],
                            [39.75161955, -5.4023441],
                            [39.75174735, -5.40230683],
                            [39.75182722, -5.40219499],
                            [39.75188047, -5.40201393],
                            [39.75187514, -5.40186482],
                            [39.75188047, -5.40182222],
                            [39.75188047, -5.40171039],
                            [39.75177397, -5.40164116],
                            [39.7518006, -5.40159855],
                            [39.75192307, -5.40163583],
                            [39.75196034, -5.40162518],
                            [39.75205619, -5.4015027],
                            [39.75214138, -5.40126306],
                            [39.75219463, -5.40120448],
                            [39.75234905, -5.4011246],
                            [39.75251944, -5.40101276],
                            [39.75257269, -5.40085833],
                            [39.75254873, -5.40072786],
                            [39.75252477, -5.40059739],
                            [39.75252477, -5.40044295],
                            [39.75247684, -5.40028319],
                            [39.75251806, -5.40017491],
                            [39.75254224, -5.40011138],
                            [39.7526601, -5.40022925],
                            [39.75277207, -5.40035302],
                            [39.75283697, -5.40056877],
                            [39.75286436, -5.4006598],
                            [39.75288403, -5.40117226],
                            [39.75273081, -5.40131371],
                            [39.75253045, -5.40155535],
                            [39.75244794, -5.40164965],
                            [39.75227704, -5.40170859],
                            [39.75218865, -5.40189719],
                            [39.75207079, -5.40194434],
                            [39.75197722, -5.40213203],
                            [39.7519706, -5.40229797],
                            [39.75202953, -5.40240995],
                            [39.7521474, -5.4024571],
                            [39.75234187, -5.40252782],
                            [39.75250098, -5.40252782],
                            [39.75260706, -5.40243352],
                            [39.75268367, -5.4023628],
                            [39.75280897, -5.40246295],
                            [39.75281332, -5.40261623],
                            [39.7528693, -5.40281662],
                            [39.75292528, -5.40301701],
                            [39.75301556, -5.40322885],
                            [39.75301957, -5.40332348],
                            [39.75297243, -5.40372426],
                            [39.75288403, -5.40404842],
                            [39.75296653, -5.40437257],
                            [39.75309618, -5.40458475],
                            [39.75324006, -5.40458557],
                            [39.75324351, -5.40464958],
                            [39.75320226, -5.4049148],
                            [39.75343798, -5.4051977],
                            [39.75365603, -5.40525074],
                            [39.75392711, -5.40545703],
                            [39.7540155, -5.4055808],
                            [39.75419819, -5.40568099],
                            [39.75441034, -5.40579297],
                            [39.75448106, -5.40570457],
                            [39.75443981, -5.40556312],
                            [39.75436909, -5.40556312],
                            [39.7543632, -5.40540398],
                            [39.75446927, -5.40517413],
                            [39.7545282, -5.40513876],
                            [39.75458714, -5.40516234],
                            [39.75465196, -5.40533915],
                            [39.75464607, -5.40555133],
                            [39.75463428, -5.40579887],
                            [39.75479929, -5.40604051],
                            [39.75507626, -5.40623501],
                            [39.75534734, -5.40627037],
                            [39.75554182, -5.40632931],
                            [39.75567736, -5.40645308],
                            [39.75577754, -5.40657095],
                            [39.75570682, -5.40665936],
                            [39.75562129, -5.40684887],
                            [39.75566557, -5.40708371],
                            [39.75581879, -5.40713086],
                            [39.75587111, -5.40716507],
                            [39.75612523, -5.40733125],
                            [39.7563256, -5.40736072],
                            [39.75630245, -5.40724495],
                            [39.75630203, -5.40724284],
                            [39.75641989, -5.40723695],
                            [39.75644489, -5.40727133],
                            [39.75665561, -5.40756111],
                            [39.75686776, -5.40769666],
                            [39.75713885, -5.40789116],
                            [39.75730385, -5.40789116],
                            [39.75785191, -5.4078499],
                            [39.75800357, -5.40778141],
                            [39.7580346, -5.40776739],
                            [39.75818782, -5.40769666],
                            [39.75843533, -5.40753164],
                            [39.75860034, -5.40730768],
                            [39.7586298, -5.40715444],
                            [39.75870052, -5.40703067],
                            [39.75872409, -5.40701299],
                            [39.75882427, -5.40694816],
                            [39.75884785, -5.40680671],
                            [39.75881838, -5.40657096],
                            [39.75890847, -5.40639594],
                            [39.75898928, -5.40660043],
                            [39.75906, -5.40688333],
                            [39.7592309, -5.40693637],
                            [39.75941359, -5.40687154],
                            [39.75953734, -5.40678313],
                            [39.75964931, -5.40668883],
                            [39.75973771, -5.4066299],
                            [39.75978485, -5.40658275],
                            [39.75977896, -5.40648255],
                            [39.7597436, -5.40636468],
                            [39.75975539, -5.40628216],
                            [39.75983903, -5.40626717],
                            [39.75986735, -5.40632931],
                            [39.75986129, -5.40643814],
                            [39.75990271, -5.4065297],
                            [39.76003236, -5.40660043],
                            [39.76019148, -5.40654738],
                            [39.76037416, -5.40650613],
                            [39.76054506, -5.40647076],
                            [39.76060399, -5.40646487],
                            [39.76069828, -5.4064354],
                            [39.76086918, -5.40641772],
                            [39.76101062, -5.40640593],
                            [39.76115205, -5.40637647],
                            [39.76125223, -5.40637647],
                            [39.76142313, -5.40641183],
                            [39.76156457, -5.40646487],
                            [39.76170011, -5.40650024],
                            [39.76182976, -5.40638236],
                            [39.76185333, -5.40630574],
                            [39.76192405, -5.40628217],
                            [39.76220102, -5.40626449],
                            [39.7623071, -5.40622912],
                            [39.76243085, -5.4062527],
                            [39.76258997, -5.40635879],
                            [39.76270194, -5.40633521],
                            [39.76268426, -5.40624091],
                            [39.76258408, -5.40619965],
                            [39.7625664, -5.40611125],
                            [39.76267247, -5.40607589],
                            [39.76280212, -5.40609357],
                            [39.7630161, -5.40609249],
                            [39.76306731, -5.40617019],
                            [39.76320874, -5.40618197],
                            [39.76332661, -5.40617019],
                            [39.763415, -5.40609946],
                            [39.76345626, -5.40597569],
                            [39.76354465, -5.40592854],
                            [39.76357412, -5.40589318],
                            [39.76356822, -5.40579299],
                            [39.76369787, -5.40580477],
                            [39.76376859, -5.40576352],
                            [39.76382163, -5.40561028],
                            [39.76393949, -5.40557492],
                            [39.76387467, -5.40569279],
                            [39.76390413, -5.40582246],
                            [39.76403967, -5.40581067],
                            [39.76422825, -5.40568101],
                            [39.76441683, -5.40561028],
                            [39.76472917, -5.40548651],
                            [39.76503561, -5.40539221],
                            [39.76516526, -5.40528023],
                            [39.76536562, -5.40520361],
                            [39.76559545, -5.40510931],
                            [39.7657251, -5.40499144],
                            [39.76580171, -5.40491482],
                            [39.76581939, -5.40480873],
                            [39.76578403, -5.40469675],
                            [39.76568974, -5.40462013],
                            [39.76565438, -5.40466139],
                            [39.76549527, -5.404738],
                            [39.76535384, -5.404738],
                            [39.76518294, -5.40472032],
                            [39.76514168, -5.40463781],
                            [39.76511222, -5.40449047],
                            [39.76514758, -5.40442563],
                            [39.76528312, -5.40441974],
                            [39.76548938, -5.40447868],
                            [39.76559545, -5.40449636],
                            [39.76561313, -5.40444332],
                            [39.76576635, -5.40420757],
                            [39.76589011, -5.40409558],
                            [39.76600208, -5.40406612],
                            [39.76607869, -5.40400128],
                            [39.76614351, -5.40393056],
                            [39.76616119, -5.40377143],
                            [39.76620834, -5.4036123],
                            [39.76627906, -5.40350031],
                            [39.76629084, -5.40337655],
                            [39.76624959, -5.40329993],
                            [39.76620834, -5.40318205],
                            [39.76627906, -5.40312901],
                            [39.76633799, -5.40312901],
                            [39.76633799, -5.40304649],
                            [39.76631441, -5.40293451],
                            [39.7662378, -5.40289326],
                            [39.76616119, -5.40284021],
                            [39.76611405, -5.40272823],
                            [39.76609637, -5.40270466],
                            [39.76603155, -5.40268108],
                            [39.76591958, -5.40263393],
                            [39.76580172, -5.40250427],
                            [39.76570743, -5.40232156],
                            [39.76566028, -5.40215653],
                            [39.76558956, -5.40214475],
                            [39.76544224, -5.40215064],
                            [39.76535384, -5.40207992],
                            [39.76528902, -5.40192668],
                            [39.76531848, -5.40184416],
                            [39.76543045, -5.40174986],
                            [39.76559477, -5.40168861],
                            [39.76576088, -5.40183548],
                            [39.76578404, -5.40185595],
                            [39.76589011, -5.40169682],
                            [39.76597262, -5.40153769],
                            [39.76597851, -5.40135498],
                            [39.76601976, -5.401243],
                            [39.76606691, -5.40111923],
                            [39.76606691, -5.40097778],
                            [39.76608459, -5.40084812],
                            [39.76611405, -5.40070667],
                            [39.76613763, -5.40057111],
                            [39.76613173, -5.40042377],
                            [39.76611405, -5.40037072],
                            [39.7661612, -5.40033536],
                            [39.76626727, -5.40030589],
                            [39.76634978, -5.40023517],
                            [39.76640871, -5.40016444],
                            [39.76642639, -5.40012908],
                            [39.76642049, -5.39998174],
                            [39.76638514, -5.39993459],
                            [39.76626727, -5.39989922],
                            [39.76619656, -5.39991101],
                            [39.76610227, -5.39992869],
                            [39.76607869, -5.39997584],
                            [39.76604334, -5.40005835],
                            [39.76588422, -5.40008193],
                            [39.76579583, -5.40002299],
                            [39.7656485, -5.4000171],
                            [39.76563082, -5.39996995],
                            [39.76566028, -5.39995816],
                            [39.76574279, -5.39994048],
                            [39.76583708, -5.39990512],
                            [39.76581351, -5.39986975],
                            [39.76576047, -5.39975777],
                            [39.76570743, -5.39972241],
                            [39.76559546, -5.39971652],
                            [39.76557189, -5.399634],
                            [39.76543635, -5.39959275],
                            [39.76533027, -5.39967526],
                            [39.76528902, -5.39965758],
                            [39.76528902, -5.3996399],
                            [39.76525955, -5.39961043],
                            [39.76517705, -5.39964579],
                            [39.76507687, -5.39964579],
                            [39.7650533, -5.39955149],
                            [39.7653067, -5.39946308],
                            [39.76542456, -5.39941004],
                            [39.76533616, -5.39927448],
                            [39.76536563, -5.39920376],
                            [39.76537742, -5.39915661],
                            [39.76538331, -5.3990682],
                            [39.76528313, -5.39892086],
                            [39.76523009, -5.39890317],
                            [39.76514169, -5.39890317],
                            [39.76500615, -5.3988796],
                            [39.7649649, -5.3988796],
                            [39.76482936, -5.39884424],
                            [39.76477439, -5.39876928],
                            [39.76476453, -5.39875583],
                            [39.76481168, -5.39874699],
                            [39.76485882, -5.39873815],
                            [39.76492853, -5.39872782],
                            [39.76499437, -5.39877351],
                            [39.76508276, -5.39877941],
                            [39.76510633, -5.39872636],
                            [39.76507098, -5.39857902],
                            [39.76505153, -5.39848736],
                            [39.7651358, -5.39854365],
                            [39.76519164, -5.39873369],
                            [39.76537152, -5.39885602],
                            [39.76544066, -5.39899388],
                            [39.765566, -5.39907999],
                            [39.76558368, -5.39909767],
                            [39.76563671, -5.39904463],
                            [39.76572511, -5.39902694],
                            [39.76579583, -5.39900337],
                            [39.76589601, -5.3991625],
                            [39.7659439, -5.39930876],
                            [39.76603155, -5.39936878],
                            [39.76608937, -5.39939443],
                            [39.76615531, -5.39943951],
                            [39.76629674, -5.3994454],
                            [39.76652657, -5.39946308],
                            [39.76668569, -5.39942772],
                            [39.76681533, -5.39930985],
                            [39.76688605, -5.39929806],
                            [39.76694235, -5.39926558],
                            [39.76719249, -5.39912125],
                            [39.76725142, -5.39899748],
                            [39.76731044, -5.39890081],
                            [39.76731184, -5.39889852],
                            [39.76731437, -5.39890002],
                            [39.76736929, -5.39893265],
                            [39.76739692, -5.39888352],
                            [39.76742232, -5.39883835],
                            [39.76742822, -5.398691],
                            [39.76734571, -5.39862028],
                            [39.76728678, -5.39848472],
                            [39.76732804, -5.39843168],
                            [39.76747536, -5.39866153],
                            [39.76762269, -5.3987912],
                            [39.76769933, -5.39877842],
                            [39.76804699, -5.39872047],
                            [39.76817075, -5.39868511],
                            [39.76826504, -5.39866743],
                            [39.76839469, -5.39865564],
                            [39.76854201, -5.39862028],
                            [39.76876595, -5.39860849],
                            [39.76895453, -5.39852598],
                            [39.76909007, -5.39852009],
                            [39.76921383, -5.39843168],
                            [39.76919026, -5.39832559],
                            [39.76920204, -5.39821361],
                            [39.7693258, -5.39814878],
                            [39.76947134, -5.3980678],
                            [39.76958325, -5.39812038],
                            [39.76984439, -5.39824308],
                            [39.76999761, -5.39820772],
                            [39.77016262, -5.3981252],
                            [39.77020976, -5.39791303],
                            [39.77020976, -5.39778337],
                            [39.77028048, -5.39773622],
                            [39.77038656, -5.3976537],
                            [39.77056335, -5.39748868],
                            [39.77065764, -5.39734723],
                            [39.77072836, -5.3971822],
                            [39.77089336, -5.39693467],
                            [39.77119981, -5.39676964],
                            [39.77128231, -5.39672249],
                            [39.7713766, -5.3966164],
                            [39.77149446, -5.3964278],
                            [39.77147089, -5.3961449],
                            [39.77127358, -5.39601561],
                            [39.77112909, -5.39592094],
                            [39.77053978, -5.39569697],
                            [39.77016262, -5.39562625],
                            [39.76978546, -5.39549658],
                            [39.76945545, -5.39540228],
                            [39.7692433, -5.39553195],
                            [39.76860684, -5.39587378],
                            [39.76847821, -5.39592381],
                            [39.76839469, -5.3959563],
                            [39.76812361, -5.39606238],
                            [39.76799552, -5.39621276],
                            [39.76785252, -5.39638065],
                            [39.76766394, -5.39653388],
                            [39.76752251, -5.39649852],
                            [39.76736929, -5.39645137],
                            [39.7671925, -5.39638065],
                            [39.76695677, -5.39630992],
                            [39.76686248, -5.39636886],
                            [39.7666739, -5.39643958],
                            [39.76661497, -5.39635707],
                            [39.76647354, -5.39627456],
                            [39.76623781, -5.39620383],
                            [39.76604924, -5.39613311],
                            [39.76598811, -5.39610254],
                            [39.76586066, -5.39603881],
                            [39.76561315, -5.39587378],
                            [39.76528313, -5.39579127],
                            [39.76484704, -5.39554373],
                            [39.76452882, -5.39541406],
                            [39.76430488, -5.3952844],
                            [39.76408094, -5.39524904],
                            [39.76390415, -5.3952844],
                            [39.76378628, -5.39515474],
                            [39.76393951, -5.39514295],
                            [39.76410451, -5.39507222],
                            [39.76415166, -5.39487184],
                            [39.76417523, -5.39450642],
                            [39.76435202, -5.39419995],
                            [39.7643756, -5.39407028],
                            [39.76434024, -5.39391705],
                            [39.76429309, -5.39383453],
                            [39.76429309, -5.39375202],
                            [39.76439729, -5.39353954],
                            [39.76439836, -5.39353737],
                            [39.76452882, -5.39357521],
                            [39.76475276, -5.39332767],
                            [39.7648824, -5.39317443],
                            [39.76497669, -5.39304477],
                            [39.76512992, -5.39286796],
                            [39.765401, -5.39272651],
                            [39.76557779, -5.39265578],
                            [39.7657428, -5.39257327],
                            [39.76584887, -5.39244361],
                            [39.76583709, -5.39231394],
                            [39.7658253, -5.39208998],
                            [39.76575459, -5.39180708],
                            [39.76571278, -5.39169558],
                            [39.76568387, -5.39161848],
                            [39.76555422, -5.39138273],
                            [39.76535386, -5.39118234],
                            [39.76521242, -5.39108804],
                            [39.76511813, -5.39109983],
                            [39.76497072, -5.39113142],
                            [39.76495313, -5.39113519],
                            [39.76478812, -5.39118234],
                            [39.76465847, -5.39135915],
                            [39.76455239, -5.39141809],
                            [39.76438739, -5.39163027],
                            [39.76435203, -5.3918778],
                            [39.76426952, -5.39213713],
                            [39.76416345, -5.39229037],
                            [39.76405737, -5.39246718],
                            [39.76393951, -5.39258505],
                            [39.76380986, -5.39262042],
                            [39.76366843, -5.39260863],
                            [39.76362128, -5.39249075],
                            [39.76357414, -5.39236109],
                            [39.76351521, -5.39230215],
                            [39.7634835, -5.39229783],
                            [39.76325591, -5.39226679],
                            [39.76291411, -5.39205461],
                            [39.76260767, -5.39196031],
                            [39.76244266, -5.39184244],
                            [39.76235306, -5.39176697],
                            [39.76177085, -5.39127664],
                            [39.7617237, -5.39115876],
                            [39.76160584, -5.39101731],
                            [39.76132297, -5.39092301],
                            [39.76111082, -5.39079335],
                            [39.76098117, -5.39066368],
                            [39.76072188, -5.39045151],
                            [39.76059988, -5.39036339],
                            [39.76050973, -5.39029827],
                            [39.76029757, -5.39032184],
                            [39.7602628, -5.39035445],
                            [39.76015103, -5.39045924],
                            [39.760109, -5.39049866],
                            [39.75968469, -5.39073441],
                            [39.75949611, -5.39076977],
                            [39.75926039, -5.39074619],
                            [39.75921343, -5.39073739],
                            [39.75929156, -5.39070679],
                            [39.75933805, -5.39064867],
                            [39.75939228, -5.3906138],
                            [39.75946201, -5.39057893],
                            [39.75957436, -5.39051694],
                            [39.75965571, -5.39047045],
                            [39.75981067, -5.39038522],
                            [39.7598804, -5.39032323],
                            [39.75997374, -5.3902555],
                            [39.76007797, -5.39017987],
                            [39.76022518, -5.39016438],
                            [39.76043825, -5.39010239],
                            [39.76058158, -5.390145],
                            [39.76079465, -5.39026511],
                            [39.76084307, -5.39030967],
                            [39.7608915, -5.39035422],
                            [39.76096123, -5.39042396],
                            [39.76108132, -5.39045108],
                            [39.76120916, -5.39036585],
                            [39.76127114, -5.39039297],
                            [39.76128276, -5.39040846],
                            [39.76135637, -5.39044721],
                            [39.76146871, -5.39045496],
                            [39.76167016, -5.39056344],
                            [39.76176313, -5.39066417],
                            [39.7618871, -5.3908269],
                            [39.76193359, -5.39095475],
                            [39.76199557, -5.39119884],
                            [39.76200326, -5.39121022],
                            [39.76208467, -5.39133057],
                            [39.76218539, -5.39140805],
                            [39.76231711, -5.39147779],
                            [39.76248368, -5.39157078],
                            [39.76263089, -5.39163664],
                            [39.76276648, -5.39166764],
                            [39.76281297, -5.39164827],
                            [39.76293694, -5.39169476],
                            [39.76307252, -5.3917335],
                            [39.76327009, -5.39181487],
                            [39.76339406, -5.39184974],
                            [39.76348704, -5.39187686],
                            [39.7635782, -5.39187932],
                            [39.76363037, -5.39188073],
                            [39.76375046, -5.39184199],
                            [39.7638047, -5.39173738],
                            [39.76382019, -5.39166377],
                            [39.76382407, -5.39159403],
                            [39.76385893, -5.39141193],
                            [39.76395191, -5.3913267],
                            [39.76402939, -5.39117172],
                            [39.764072, -5.39105936],
                            [39.76412236, -5.3909625],
                            [39.7640875, -5.39088114],
                            [39.7640318, -5.39075663],
                            [39.76402164, -5.39073392],
                            [39.76408004, -5.39072044],
                            [39.76412236, -5.39071067],
                            [39.76423083, -5.39071454],
                            [39.76436642, -5.39067193],
                            [39.7643703, -5.39053245],
                            [39.76438579, -5.39041234],
                            [39.76447102, -5.39038522],
                            [39.76451363, -5.39038522],
                            [39.76457561, -5.39043946],
                            [39.76466859, -5.39051308],
                            [39.76476931, -5.39063706],
                            [39.76480418, -5.39071067],
                            [39.7648894, -5.39071454],
                            [39.76494751, -5.39071842],
                            [39.76506373, -5.3906758],
                            [39.76512571, -5.39069517],
                            [39.76521238, -5.39075938],
                            [39.76523031, -5.39077266],
                            [39.76548599, -5.39085402],
                            [39.76560221, -5.39094313],
                            [39.76583464, -5.39108649],
                            [39.76593924, -5.39115623],
                            [39.7660051, -5.39126471],
                            [39.76601179, -5.39144088],
                            [39.76598573, -5.39170251],
                            [39.76609807, -5.39186524],
                            [39.7661678, -5.3919466],
                            [39.76624041, -5.3920793],
                            [39.76622842, -5.39220993],
                            [39.76621789, -5.39232473],
                            [39.76620618, -5.39245237],
                            [39.76618717, -5.39265948],
                            [39.76615618, -5.39272535],
                            [39.76608257, -5.39277184],
                            [39.76597798, -5.39281833],
                            [39.76585789, -5.39289195],
                            [39.76575716, -5.39298881],
                            [39.76567968, -5.39307404],
                            [39.76560608, -5.39316703],
                            [39.76546662, -5.39329101],
                            [39.76536637, -5.39345723],
                            [39.76535815, -5.39353122],
                            [39.76538914, -5.39356996],
                            [39.76541238, -5.39365907],
                            [39.76535427, -5.3936552],
                            [39.76529616, -5.39370556],
                            [39.76523165, -5.39383755],
                            [39.76521094, -5.39387991],
                            [39.76514615, -5.39394545],
                            [39.76513345, -5.3940556],
                            [39.76511796, -5.39418986],
                            [39.7650831, -5.39422086],
                            [39.76498625, -5.39429447],
                            [39.76487778, -5.39434871],
                            [39.76481073, -5.39449437],
                            [39.76479255, -5.39464316],
                            [39.76487003, -5.39476714],
                            [39.76500562, -5.39487563],
                            [39.76512183, -5.39497249],
                            [39.76522256, -5.39499961],
                            [39.76530778, -5.39496474],
                            [39.76544932, -5.3949623],
                            [39.76544337, -5.39501898],
                            [39.76547824, -5.39505385],
                            [39.76552085, -5.39508872],
                            [39.76563707, -5.39512359],
                            [39.76576103, -5.39511196],
                            [39.76588887, -5.39515846],
                            [39.76600122, -5.39525144],
                            [39.76607482, -5.39520108],
                            [39.76615618, -5.39518945],
                            [39.76629176, -5.39528244],
                            [39.76634212, -5.39534443],
                            [39.76648933, -5.39539092],
                            [39.76667916, -5.39543741],
                            [39.76685736, -5.39547616],
                            [39.76706655, -5.39547616],
                            [39.76721376, -5.39546454],
                            [39.76736097, -5.39533668],
                            [39.76741521, -5.39523207],
                            [39.76746944, -5.39514296],
                            [39.76747719, -5.39508485],
                            [39.7674462, -5.39498024],
                            [39.76737647, -5.39495312],
                            [39.76732223, -5.39495699],
                            [39.76724088, -5.394895],
                            [39.76715953, -5.3948795],
                            [39.76719439, -5.39482914],
                            [39.76736872, -5.39479039],
                            [39.76743458, -5.39478264],
                            [39.76754305, -5.39476715],
                            [39.76769413, -5.3947439],
                            [39.76785684, -5.39467416],
                            [39.76793432, -5.39458893],
                            [39.76783747, -5.39449594],
                            [39.76774307, -5.39441097],
                            [39.76772125, -5.39439133],
                            [39.76772512, -5.3943061],
                            [39.76792269, -5.39439908],
                            [39.76792718, -5.39440463],
                            [39.76805441, -5.39456181],
                            [39.76813189, -5.39465092],
                            [39.76822099, -5.39456956],
                            [39.76824036, -5.39448819],
                            [39.76837207, -5.39451919],
                            [39.76857739, -5.39455793],
                            [39.76871246, -5.39457021],
                            [39.76879046, -5.3945773],
                            [39.76894154, -5.39457731],
                            [39.76908488, -5.39455406],
                            [39.76916623, -5.39448819],
                            [39.76923983, -5.39446107],
                            [39.76929407, -5.39448432],
                            [39.76938704, -5.39446495],
                            [39.76946065, -5.3944727],
                            [39.7695575, -5.39451532],
                            [39.7696466, -5.39446882],
                            [39.76965047, -5.39437584],
                            [39.76959236, -5.39432935],
                            [39.76957687, -5.3942906],
                            [39.76960786, -5.39427123],
                            [39.76978219, -5.39435647],
                            [39.770003, -5.39441846],
                            [39.7701076, -5.39438359],
                            [39.77017733, -5.39443396],
                            [39.7703594, -5.39442621],
                            [39.77039814, -5.39434484],
                            [39.77045625, -5.39438359],
                            [39.77056472, -5.39454631],
                            [39.77064607, -5.39459281],
                            [39.77081653, -5.39468966],
                            [39.77100248, -5.39472841],
                            [39.7712078, -5.39478652],
                            [39.77131627, -5.39480977],
                            [39.77152159, -5.39480977],
                            [39.77169591, -5.39481365],
                            [39.77187024, -5.39480977],
                            [39.77202907, -5.39477103],
                            [39.77216079, -5.39465092],
                            [39.77225376, -5.39456956],
                            [39.77233899, -5.39442621],
                            [39.77239322, -5.39432548],
                            [39.77242421, -5.394186],
                            [39.77245521, -5.39407752],
                            [39.77246295, -5.39391479],
                            [39.7724862, -5.39380243],
                            [39.77257142, -5.39371332],
                            [39.77260629, -5.39356222],
                            [39.77261791, -5.39348086],
                            [39.77261404, -5.39339175],
                            [39.7725598, -5.39336076],
                            [39.77243584, -5.39331039],
                            [39.77237385, -5.39315154],
                            [39.77235836, -5.39304693],
                            [39.7723971, -5.39299269],
                            [39.77254431, -5.39304693],
                            [39.77262179, -5.39317479],
                            [39.77268377, -5.39320966],
                            [39.77279224, -5.39318641],
                            [39.77288522, -5.39302369],
                            [39.77291621, -5.39292295],
                            [39.77299369, -5.39276798],
                            [39.77302629, -5.39263163],
                            [39.7730363, -5.39258976],
                            [39.77306342, -5.39255489],
                            [39.77307734, -5.39250679],
                            [39.77310603, -5.39240766],
                            [39.7731409, -5.39229143],
                            [39.77317576, -5.3921752],
                            [39.77319513, -5.39205897],
                            [39.77316801, -5.39197373],
                            [39.77311378, -5.39186137],
                            [39.77304792, -5.39183813],
                            [39.7730208, -5.39183813],
                            [39.77295882, -5.39183813],
                            [39.77286197, -5.39184587],
                            [39.77278062, -5.3918575],
                            [39.77270702, -5.39189237],
                            [39.77261017, -5.39190012],
                            [39.77256368, -5.39185362],
                            [39.77247845, -5.39179938],
                            [39.77243971, -5.39183038],
                            [39.77236223, -5.39183425],
                            [39.77226926, -5.39176839],
                            [39.77224602, -5.39171027],
                            [39.77223827, -5.39159404],
                            [39.77228088, -5.39151268],
                            [39.77242422, -5.39161729],
                            [39.77249782, -5.3917064],
                            [39.77264891, -5.39178388],
                            [39.77273801, -5.39174514],
                            [39.77280774, -5.39163666],
                            [39.77288134, -5.39156692],
                            [39.77307891, -5.3915398],
                            [39.77336171, -5.39145069],
                            [39.77346631, -5.39148168],
                            [39.77357865, -5.39148943],
                            [39.77370649, -5.39147006],
                            [39.77388082, -5.39140032],
                            [39.77384983, -5.39124147],
                            [39.77382659, -5.39116399],
                            [39.77372199, -5.39104388],
                            [39.77362127, -5.39083854],
                            [39.77356316, -5.39079205],
                            [39.77348181, -5.39076492],
                            [39.77339658, -5.39080367],
                            [39.77332685, -5.3907378],
                            [39.7733036, -5.39060607],
                            [39.77328036, -5.39054408],
                            [39.77332297, -5.39053634],
                            [39.77344307, -5.39055958],
                            [39.77357865, -5.39057895],
                            [39.77374136, -5.39058283],
                            [39.77387695, -5.39057895],
                            [39.77393118, -5.39050922],
                            [39.77392731, -5.39039298],
                            [39.77391181, -5.3903],
                            [39.7738692, -5.39022251],
                            [39.7737646, -5.39017214],
                            [39.77377235, -5.39012178],
                            [39.77392344, -5.39010628],
                            [39.77409002, -5.39011016],
                            [39.77416943, -5.3900811],
                            [39.77424885, -5.39005204],
                            [39.77429533, -5.38992419],
                            [39.77436894, -5.38973434],
                            [39.77442705, -5.38951738],
                            [39.77445417, -5.38941664],
                            [39.77447741, -5.38931591],
                            [39.7745084, -5.38928104],
                            [39.77456651, -5.38918805],
                            [39.77463237, -5.38914156],
                            [39.77465755, -5.38913962],
                            [39.77468273, -5.38913769],
                            [39.77478733, -5.38909507],
                            [39.77484156, -5.38898659],
                            [39.77490354, -5.38885873],
                            [39.77493454, -5.38878512],
                            [39.77501589, -5.388758],
                            [39.77517472, -5.388727],
                            [39.77525995, -5.3886069],
                            [39.77526382, -5.38847517],
                            [39.77522508, -5.38837831],
                            [39.7752677, -5.38832019],
                            [39.77532193, -5.38822334],
                            [39.77541103, -5.38816135],
                            [39.77552338, -5.38817297],
                            [39.77558635, -5.38825803],
                            [39.77561248, -5.38840543],
                            [39.77560232, -5.38850796],
                            [39.77562797, -5.38865339],
                            [39.77567059, -5.38875025],
                            [39.77575581, -5.38881999],
                            [39.7758914, -5.38888585],
                            [39.77602311, -5.38890523],
                            [39.77614321, -5.38885486],
                            [39.77628654, -5.38882774],
                            [39.77640276, -5.38873475],
                            [39.77649961, -5.38873475],
                            [39.77662745, -5.38869989],
                            [39.77675529, -5.38861465],
                            [39.77687926, -5.38854491],
                            [39.77689863, -5.38842481],
                            [39.7769025, -5.38835894],
                            [39.77685989, -5.38821946],
                            [39.77682115, -5.38814585],
                            [39.77677853, -5.38805286],
                            [39.77682889, -5.38795601],
                            [39.77687538, -5.38783203],
                            [39.77692574, -5.38774291],
                            [39.77701097, -5.38764218],
                            [39.77710007, -5.38740584],
                            [39.77715818, -5.38723537],
                            [39.77718142, -5.38716176],
                            [39.77719692, -5.38699903],
                            [39.77717755, -5.38675495],
                            [39.77713881, -5.38665421],
                            [39.77707296, -5.38652636],
                            [39.77705746, -5.38645662],
                            [39.77707296, -5.38632102],
                            [39.7770807, -5.38622803],
                            [39.77704196, -5.38612343],
                            [39.77696449, -5.38604981],
                            [39.77689088, -5.3859762],
                            [39.77687151, -5.38595683],
                            [39.77688313, -5.38592583],
                            [39.77688701, -5.38584447],
                            [39.77679791, -5.38575536],
                            [39.7767398, -5.38561588],
                            [39.77667394, -5.38549965],
                            [39.77657322, -5.38545703],
                            [39.77653448, -5.38541441],
                            [39.77652286, -5.3853253],
                            [39.77644925, -5.38528268],
                            [39.77638727, -5.38529818],
                            [39.77632141, -5.38538342],
                            [39.77625168, -5.38560038],
                            [39.77624393, -5.38572824],
                            [39.77622844, -5.38584447],
                            [39.77618582, -5.3859607],
                            [39.77618558, -5.38596134],
                            [39.77613934, -5.38608468],
                            [39.77602699, -5.38613117],
                            [39.77601537, -5.38600719],
                            [39.77607102, -5.3858926],
                            [39.77608123, -5.38587159],
                            [39.77610447, -5.38576698],
                            [39.7760851, -5.38563138],
                            [39.77605411, -5.38557714],
                            [39.77604636, -5.38546865],
                            [39.77604636, -5.38538729],
                            [39.77606961, -5.38529818],
                            [39.77610447, -5.38517808],
                            [39.77610835, -5.3850541],
                            [39.77613546, -5.38489912],
                            [39.7761006, -5.384841],
                            [39.77602699, -5.38470928],
                            [39.77594201, -5.38468231],
                            [39.77595339, -5.3847364],
                            [39.77588753, -5.38470928],
                            [39.77587591, -5.38460854],
                            [39.77593015, -5.38450006],
                            [39.77589915, -5.38442257],
                            [39.77584879, -5.38439933],
                            [39.77575969, -5.38435671],
                            [39.77568609, -5.38435671],
                            [39.77557762, -5.38432184],
                            [39.77547689, -5.38432571],
                            [39.77538005, -5.38432959],
                            [39.77528707, -5.38433346],
                            [39.77517085, -5.38434121],
                            [39.77509725, -5.38433346],
                            [39.77506238, -5.38427147],
                            [39.77505851, -5.38416686],
                            [39.77503914, -5.3840855],
                            [39.77496553, -5.38398477],
                            [39.77490355, -5.38398089],
                            [39.77486869, -5.38402738],
                            [39.77483769, -5.3840855],
                            [39.77475247, -5.38405838],
                            [39.77477184, -5.38395377],
                            [39.77474085, -5.38388403],
                            [39.77468274, -5.38384141],
                            [39.77457039, -5.38382979],
                            [39.77442706, -5.38380654],
                            [39.77441931, -5.38378717],
                            [39.77442318, -5.38374843],
                            [39.77446967, -5.38373293],
                            [39.77458589, -5.38369806],
                            [39.77464787, -5.38369806],
                            [39.77474859, -5.38365932],
                            [39.77473697, -5.3835857],
                            [39.774644, -5.38343848],
                            [39.77459364, -5.38335712],
                            [39.77459751, -5.38331837],
                            [39.77465175, -5.38327963],
                            [39.77467499, -5.38321376],
                            [39.77467254, -5.38319581],
                            [39.77466337, -5.38312853],
                            [39.77458202, -5.38308204],
                            [39.77445418, -5.38306266],
                            [39.77433021, -5.38308591],
                            [39.7742721, -5.3829968],
                            [39.77429922, -5.38300455],
                            [39.77439607, -5.38300455],
                            [39.77442706, -5.38298518],
                            [39.77449292, -5.38287669],
                            [39.77452003, -5.38288832],
                            [39.77464787, -5.38289994],
                            [39.77475247, -5.3828147],
                            [39.77484544, -5.38269072],
                            [39.77485707, -5.38259386],
                            [39.77504302, -5.38241177],
                            [39.77508797, -5.38229394],
                            [39.77515536, -5.38211732],
                            [39.77522897, -5.38186936],
                            [39.77529095, -5.38165627],
                            [39.77533744, -5.38141218],
                            [39.77538005, -5.38126883],
                            [39.77544034, -5.38114154],
                            [39.77544978, -5.3811216],
                            [39.77550014, -5.38092013],
                            [39.77548852, -5.3808194],
                            [39.77548465, -5.38073029],
                            [39.77551564, -5.38058306],
                            [39.77548077, -5.38047458],
                            [39.77545747, -5.3803921],
                            [39.7754475, -5.38035682],
                            [39.77543041, -5.38029636],
                            [39.77534906, -5.38017238],
                            [39.77526771, -5.38008327],
                            [39.77522509, -5.38004452],
                            [39.77515139, -5.37999538],
                            [39.77514374, -5.37999028],
                            [39.77511275, -5.37998641],
                            [39.77510888, -5.37992054],
                            [39.775105, -5.37985468],
                            [39.77503527, -5.37976557],
                            [39.77496554, -5.37971133],
                            [39.77493842, -5.37975395],
                            [39.77486094, -5.37968808],
                            [39.77478347, -5.37961834],
                            [39.77471374, -5.37953698],
                            [39.774644, -5.37945174],
                            [39.77459364, -5.37933551],
                            [39.77451616, -5.37926577],
                            [39.77445806, -5.37929677],
                            [39.77438445, -5.37931227],
                            [39.77436508, -5.3792619],
                            [39.77441157, -5.37916891],
                            [39.77438445, -5.3790798],
                            [39.77435733, -5.37902944],
                            [39.7743031, -5.37895582],
                            [39.77422175, -5.37888221],
                            [39.77415201, -5.3787931],
                            [39.77407066, -5.37869236],
                            [39.77400093, -5.378642],
                            [39.77396219, -5.37856838],
                            [39.77390796, -5.37851608],
                            [39.77385372, -5.37846378],
                            [39.77381498, -5.37841341],
                            [39.77374525, -5.37842891],
                            [39.77368714, -5.37841341],
                            [39.77365615, -5.37834754],
                            [39.77360966, -5.37828168],
                            [39.77352444, -5.37819257],
                            [39.77342371, -5.3781732],
                            [39.77332299, -5.37816932],
                            [39.77332296, -5.37816927],
                            [39.77326876, -5.37809571],
                            [39.77330557, -5.37805626],
                            [39.77332299, -5.37803759],
                            [39.77344308, -5.37804147],
                            [39.77352056, -5.37802597],
                            [39.77361741, -5.3779756],
                            [39.77358255, -5.37789812],
                            [39.77360192, -5.37782063],
                            [39.77360579, -5.37775864],
                            [39.7735925, -5.37774617],
                            [39.77354381, -5.37770052],
                            [39.77355529, -5.37766606],
                            [39.77356318, -5.37764241],
                            [39.77361354, -5.37765403],
                            [39.77368327, -5.37765403],
                            [39.77373363, -5.37759979],
                            [39.77375687, -5.37748743],
                            [39.7737375, -5.37742544],
                            [39.77369102, -5.37732471],
                            [39.77367165, -5.37727434],
                            [39.77361354, -5.37721235],
                            [39.77351669, -5.37718523],
                            [39.77349472, -5.37718248],
                            [39.77342372, -5.37717361],
                            [39.7733075, -5.37716973],
                            [39.77323777, -5.37717361],
                            [39.77316804, -5.3772201],
                            [39.77305182, -5.3772356],
                            [39.77297913, -5.37724432],
                            [39.77295497, -5.37724722],
                            [39.77290073, -5.37730534],
                            [39.77281551, -5.37731308],
                            [39.77279226, -5.37728596],
                            [39.77274965, -5.37724722],
                            [39.77266442, -5.37719298],
                            [39.7725792, -5.37718523],
                            [39.77245136, -5.37714649],
                            [39.77235451, -5.37711549],
                            [39.77221505, -5.37707287],
                            [39.77215419, -5.37702174],
                            [39.7721182, -5.37699151],
                            [39.77199423, -5.37692565],
                            [39.77209108, -5.37687528],
                            [39.77219568, -5.37692952],
                            [39.77240598, -5.37701922],
                            [39.7724591, -5.37704188],
                            [39.77259469, -5.37704188],
                            [39.77267023, -5.37702251],
                            [39.77274578, -5.37700313],
                            [39.77281782, -5.37695585],
                            [39.77286974, -5.37692177],
                            [39.77297821, -5.37692177],
                            [39.77310993, -5.37691402],
                            [39.77317578, -5.37689853],
                            [39.77328038, -5.37692952],
                            [39.77336948, -5.37699151],
                            [39.77345471, -5.37696439],
                            [39.77349732, -5.37690628],
                            [39.77358642, -5.37694889],
                            [39.77367552, -5.37696052],
                            [39.77372588, -5.3769179],
                            [39.77378787, -5.37692952],
                            [39.7738421, -5.37696827],
                            [39.77389927, -5.37711327],
                            [39.77391958, -5.3772201],
                            [39.77391183, -5.37738282],
                            [39.77386922, -5.37746419],
                            [39.77384419, -5.3775469],
                            [39.77386534, -5.37766178],
                            [39.7739308, -5.37770447],
                            [39.7739467, -5.37778963],
                            [39.77388374, -5.37780847],
                            [39.77390021, -5.37789812],
                            [39.77398931, -5.37807634],
                            [39.77398544, -5.37825069],
                            [39.77407454, -5.37839791],
                            [39.77421012, -5.37853739],
                            [39.7743767, -5.3786265],
                            [39.77439995, -5.37863425],
                            [39.77449292, -5.37864975],
                            [39.77454328, -5.37863812],
                            [39.77457427, -5.37858388],
                            [39.77450842, -5.37848702],
                            [39.77450842, -5.3784599],
                            [39.77453166, -5.37840566],
                            [39.77463332, -5.37836896],
                            [39.77465009, -5.37844841],
                            [39.77466725, -5.37852964],
                            [39.77472536, -5.37857226],
                            [39.77482608, -5.37856451],
                            [39.77490356, -5.37852964],
                            [39.77496942, -5.37847928],
                            [39.77503915, -5.37843278],
                            [39.77508176, -5.37840179],
                            [39.77516044, -5.37843728],
                            [39.77517474, -5.37849865],
                            [39.77508176, -5.37853739],
                            [39.7750314, -5.37855676],
                            [39.77496554, -5.37859551],
                            [39.77489581, -5.3786265],
                            [39.77480284, -5.37865362],
                            [39.77468274, -5.37870868],
                            [39.77470211, -5.37880085],
                            [39.77477185, -5.37894807],
                            [39.77484158, -5.37902556],
                            [39.77493209, -5.37904075],
                            [39.77496167, -5.37917666],
                            [39.77504302, -5.37928902],
                            [39.77516311, -5.37941687],
                            [39.77526383, -5.37949824],
                            [39.77536414, -5.37959043],
                            [39.77539555, -5.37972683],
                            [39.77543119, -5.37979501],
                            [39.77548465, -5.3798973],
                            [39.77554276, -5.37992829],
                            [39.77561636, -5.3798973],
                            [39.77566672, -5.3799128],
                            [39.77564543, -5.37995587],
                            [39.77566285, -5.38002903],
                            [39.77570159, -5.38003678],
                            [39.77575582, -5.38002128],
                            [39.77582556, -5.37995541],
                            [39.77587979, -5.37991667],
                            [39.77594952, -5.37990505],
                            [39.77598171, -5.37987117],
                            [39.77602313, -5.37982756],
                            [39.77607736, -5.37982369],
                            [39.77606572, -5.37989357],
                            [39.77606187, -5.37991667],
                            [39.77596502, -5.37999028],
                            [39.77589916, -5.3800329],
                            [39.77582943, -5.38011814],
                            [39.77579084, -5.38021402],
                            [39.77581267, -5.38030441],
                            [39.77582943, -5.38037385],
                            [39.77579456, -5.38040097],
                            [39.77579241, -5.38056328],
                            [39.77582943, -5.38071867],
                            [39.77589611, -5.38073397],
                            [39.77591853, -5.38082715],
                            [39.77589529, -5.38084652],
                            [39.77584501, -5.38094928],
                            [39.77587204, -5.38106349],
                            [39.7759224, -5.3811216],
                            [39.77601925, -5.38117972],
                            [39.77607736, -5.38117584],
                            [39.77611997, -5.38112935],
                            [39.77617421, -5.38114872],
                            [39.77610448, -5.38121846],
                            [39.77605902, -5.38124877],
                            [39.77603475, -5.38126495],
                            [39.77596114, -5.38129595],
                            [39.77592628, -5.38128045],
                            [39.77589916, -5.38129207],
                            [39.77586817, -5.38124171],
                            [39.77578681, -5.38116422],
                            [39.7757442, -5.38118747],
                            [39.77570934, -5.38126883],
                            [39.77566672, -5.38137731],
                            [39.77565897, -5.38151679],
                            [39.77571194, -5.38156057],
                            [39.77572483, -5.38161752],
                            [39.77564348, -5.38168339],
                            [39.77562023, -5.38200109],
                            [39.77558537, -5.38240015],
                            [39.77551951, -5.38260936],
                            [39.77552338, -5.38278758],
                            [39.77549509, -5.38291196],
                            [39.77550789, -5.38297355],
                            [39.7754614, -5.38300455],
                            [39.77539167, -5.38300842],
                            [39.77534518, -5.38303554],
                            [39.77528393, -5.38319966],
                            [39.77531924, -5.38337783],
                            [39.77533356, -5.3834501],
                            [39.77542653, -5.38351597],
                            [39.77549239, -5.3836167],
                            [39.77557374, -5.38368257],
                            [39.77569384, -5.38374456],
                            [39.77582555, -5.38380655],
                            [39.77600375, -5.38384529],
                            [39.77616258, -5.38387241],
                            [39.77630979, -5.38386079],
                            [39.77644151, -5.38381042],
                            [39.77649574, -5.3837678],
                            [39.77653061, -5.38370581],
                            [39.77657322, -5.38365545],
                            [39.77659646, -5.3836012],
                            [39.7766352, -5.38360508],
                            [39.77673593, -5.38365545],
                            [39.77682554, -5.38371151],
                            [39.77683277, -5.38374068],
                            [39.77676692, -5.38374068],
                            [39.77673022, -5.38383109],
                            [39.77675917, -5.3839499],
                            [39.77683575, -5.38402958],
                            [39.77686377, -5.38414362],
                            [39.77694899, -5.38430247],
                            [39.77704197, -5.38439933],
                            [39.7771117, -5.38444969],
                            [39.7772008, -5.38449231],
                            [39.7772899, -5.38450781],
                            [39.77732089, -5.38450394],
                            [39.77734801, -5.38448069],
                            [39.77737513, -5.38441483],
                            [39.77740224, -5.38436446],
                            [39.77744486, -5.38430634],
                            [39.77750297, -5.3842676],
                            [39.77752621, -5.38423273],
                            [39.77757906, -5.38422044],
                            [39.77759594, -5.3842831],
                            [39.77753008, -5.38437221],
                            [39.77747585, -5.3844342],
                            [39.7773945, -5.38451168],
                            [39.77744534, -5.38458148],
                            [39.77745648, -5.38462404],
                            [39.7773945, -5.38465891],
                            [39.77732476, -5.38464341],
                            [39.77723954, -5.38467441],
                            [39.77716593, -5.38469378],
                            [39.77710395, -5.38470153],
                            [39.77704197, -5.38474415],
                            [39.77697294, -5.38487193],
                            [39.77699935, -5.38498048],
                            [39.77706134, -5.38505797],
                            [39.77713107, -5.38510834],
                            [39.77723179, -5.38513933],
                            [39.77731314, -5.38518583],
                            [39.777379, -5.38523232],
                            [39.77745648, -5.3853408],
                            [39.77756882, -5.3853563],
                            [39.77765221, -5.38536822],
                            [39.77767342, -5.38544928],
                            [39.77776071, -5.38552499],
                            [39.77779044, -5.38563768],
                            [39.77780513, -5.38569337],
                            [39.77784774, -5.38567012],
                            [39.77787824, -5.38568756],
                            [39.77789423, -5.38574761],
                            [39.77793297, -5.38578248],
                            [39.77796396, -5.38577086],
                            [39.77801148, -5.3857421],
                            [39.7780182, -5.38576698],
                            [39.77799464, -5.3858371],
                            [39.77801432, -5.38591034],
                            [39.77805694, -5.3859762],
                            [39.778124, -5.38598434],
                            [39.77813442, -5.38602269],
                            [39.77810244, -5.38609793],
                            [39.77813442, -5.38621641],
                            [39.77824289, -5.38636751],
                            [39.77831649, -5.38640626],
                            [39.77837847, -5.386445],
                            [39.77845595, -5.38658448],
                            [39.778556, -5.38675857],
                            [39.77861866, -5.38698354],
                            [39.77865352, -5.38701841],
                            [39.77876587, -5.38706878],
                            [39.77885497, -5.38708427],
                            [39.77896731, -5.38708427],
                            [39.7791184, -5.38703003],
                            [39.77922299, -5.38699129],
                            [39.77928885, -5.3869448],
                            [39.77933146, -5.38688281],
                            [39.77940894, -5.38684406],
                            [39.77946705, -5.38682857],
                            [39.77952903, -5.3867782],
                            [39.77959876, -5.38671233],
                            [39.77963363, -5.38668521],
                            [39.77967624, -5.38656123],
                            [39.77973823, -5.38648762],
                            [39.77976922, -5.3864605],
                            [39.77981183, -5.38639464],
                            [39.7798467, -5.38634427],
                            [39.77987382, -5.38627453],
                            [39.77989318, -5.38622029],
                            [39.7799203, -5.3861738],
                            [39.77994742, -5.38615442],
                            [39.77999003, -5.38610406],
                            [39.77999778, -5.38604207],
                            [39.77997841, -5.38596845],
                            [39.77997066, -5.38591034],
                            [39.7799358, -5.38586385],
                            [39.77998229, -5.38581735],
                            [39.78001328, -5.38574374],
                            [39.78003265, -5.385705],
                            [39.78005589, -5.38563138],
                            [39.78004814, -5.38556552],
                            [39.78001715, -5.3855229],
                            [39.78004427, -5.38542217],
                            [39.78011013, -5.38542217],
                            [39.78022247, -5.38543379],
                            [39.78026896, -5.38539505],
                            [39.78031932, -5.38532918],
                            [39.7803813, -5.38522845],
                            [39.78040842, -5.38516258],
                            [39.78045103, -5.38510059],
                            [39.7805014, -5.38500374],
                            [39.78052464, -5.38496112],
                            [39.78055176, -5.38488363],
                            [39.780575, -5.38482164],
                            [39.78061761, -5.38477127],
                            [39.78065635, -5.38472478],
                            [39.78070671, -5.38467441],
                            [39.7807687, -5.38458918],
                            [39.78082293, -5.38448844],
                            [39.78083456, -5.38440321],
                            [39.78083068, -5.38433734],
                            [39.78086942, -5.38423661],
                            [39.78092366, -5.38423273],
                            [39.78098564, -5.38417849],
                            [39.78107861, -5.38406226],
                            [39.78117934, -5.38396153],
                            [39.78120645, -5.38393053],
                            [39.78121033, -5.3838453],
                            [39.78122582, -5.38380655],
                            [39.7813188, -5.3836787],
                            [39.78136141, -5.38359734],
                            [39.78140403, -5.38351598],
                            [39.78143889, -5.38344236],
                            [39.78146601, -5.38333775],
                            [39.78146988, -5.38327189],
                            [39.78143114, -5.38321765],
                            [39.78141177, -5.38313241],
                            [39.78135754, -5.38302393],
                            [39.78133042, -5.38292707],
                            [39.78127619, -5.38281859],
                            [39.7812452, -5.38274497],
                            [39.78120258, -5.38269848],
                            [39.78113673, -5.38262099],
                            [39.78112123, -5.38255513],
                            [39.78113673, -5.38246214],
                            [39.7811561, -5.38236141],
                            [39.7811406, -5.38229942],
                            [39.78111348, -5.38227617],
                            [39.78109992, -5.38223743],
                            [39.7811038, -5.3822045],
                            [39.78116578, -5.38225099],
                            [39.78125876, -5.38223743],
                            [39.78132268, -5.38217544],
                            [39.78139628, -5.3821367],
                            [39.78143696, -5.38209214],
                            [39.78148925, -5.3820379],
                            [39.78153381, -5.38199916],
                            [39.78155899, -5.3819391],
                            [39.7815861, -5.38184031],
                            [39.78161322, -5.38179769],
                            [39.78164228, -5.38173376],
                            [39.78165971, -5.38167758],
                            [39.78170232, -5.38164853],
                            [39.78174106, -5.38160784],
                            [39.78175268, -5.38154585],
                            [39.78176624, -5.38153617],
                            [39.78178174, -5.38150905],
                            [39.78181273, -5.38145481],
                            [39.78182435, -5.38139669],
                            [39.78181273, -5.3813192],
                            [39.78181273, -5.38126884],
                            [39.78183725, -5.38123995],
                            [39.78183985, -5.38124559],
                            [39.78191345, -5.38123784],
                            [39.78197544, -5.38123009],
                            [39.78200255, -5.38119522],
                            [39.78203354, -5.38113323],
                            [39.78206066, -5.38102475],
                            [39.78208778, -5.38097826],
                            [39.7821149, -5.38092014],
                            [39.78216913, -5.38086203],
                            [39.78223886, -5.3807458],
                            [39.78231634, -5.38061794],
                            [39.78235508, -5.38052496],
                            [39.7823822, -5.38044747],
                            [39.78237058, -5.38038548],
                            [39.78235508, -5.38029249],
                            [39.78233571, -5.38018401],
                            [39.78235896, -5.38010265],
                            [39.78244031, -5.38006778],
                            [39.78255653, -5.38003679],
                            [39.78266113, -5.37988569],
                            [39.78273473, -5.37967647],
                            [39.78274248, -5.37958348],
                            [39.78269987, -5.37947888],
                            [39.78265725, -5.37940526],
                            [39.78260689, -5.37935102],
                            [39.78253329, -5.37929291],
                            [39.78245968, -5.37922704],
                            [39.78234734, -5.3791418],
                            [39.7822931, -5.37908756],
                            [39.78224274, -5.37901782],
                            [39.78222337, -5.37891709],
                            [39.78212265, -5.37882023],
                            [39.78204517, -5.37882023],
                            [39.78193283, -5.37880086],
                            [39.78190571, -5.37875437],
                            [39.78196769, -5.37875049],
                            [39.78203355, -5.37877374],
                            [39.78212652, -5.37876599],
                            [39.7822195, -5.3787195],
                            [39.78228148, -5.378673],
                            [39.78230085, -5.37861876],
                            [39.78233959, -5.37855677],
                            [39.7823822, -5.3785219],
                            [39.78245193, -5.37846379],
                            [39.78243256, -5.37839792],
                            [39.78245581, -5.37835531],
                            [39.78249842, -5.37833981],
                            [39.78255266, -5.37834368],
                            [39.78261464, -5.37840567],
                            [39.78269212, -5.3784018],
                            [39.78275023, -5.37832431],
                            [39.78281221, -5.37828557],
                            [39.78284708, -5.37823133],
                            [39.78290131, -5.37821583],
                            [39.78295167, -5.37819258],
                            [39.78299041, -5.37813447],
                            [39.78301753, -5.37806085],
                            [39.78306789, -5.37798337],
                            [39.78309501, -5.3779485],
                            [39.78310663, -5.37788651],
                            [39.78308726, -5.37783227],
                            [39.7830369, -5.37780515],
                            [39.78305627, -5.37773541],
                            [39.7830524, -5.37765792],
                            [39.78298654, -5.3775998],
                            [39.78298654, -5.37756106],
                            [39.78302915, -5.37752619],
                            [39.7831006, -5.37757255],
                            [39.78315905, -5.3776715],
                            [39.7832151, -5.3777664],
                            [39.78321898, -5.37784001],
                            [39.78318208, -5.37793192],
                            [39.78321898, -5.37799499],
                            [39.78320735, -5.37803761],
                            [39.78316474, -5.37805698],
                            [39.78309888, -5.37816546],
                            [39.78301366, -5.37828169],
                            [39.78291681, -5.37838243],
                            [39.78281608, -5.37846379],
                            [39.78273473, -5.3785529],
                            [39.78256815, -5.37861876],
                            [39.7824868, -5.37860327],
                            [39.78241707, -5.37859164],
                            [39.78237833, -5.37861876],
                            [39.78234734, -5.37865363],
                            [39.78234735, -5.37883411],
                            [39.78244031, -5.37901008],
                            [39.78255266, -5.37906819],
                            [39.782665, -5.37911468],
                            [39.78282383, -5.37911081],
                            [39.7829478, -5.37909919],
                            [39.78313375, -5.37909531],
                            [39.78330807, -5.37904882],
                            [39.78337781, -5.37899071],
                            [39.78345141, -5.37896746],
                            [39.7838698, -5.37892872],
                            [39.7841216, -5.37892484],
                            [39.78437341, -5.3788861],
                            [39.78468332, -5.37882798],
                            [39.78499324, -5.37874275],
                            [39.78517531, -5.37868851],
                            [39.78535739, -5.37859552],
                            [39.78552397, -5.3785839],
                            [39.78579515, -5.37849866],
                            [39.78597335, -5.37846379],
                            [39.78617092, -5.3784483],
                            [39.78638011, -5.37839793],
                            [39.78663967, -5.37839793],
                            [39.78679075, -5.3784018],
                            [39.78698057, -5.37840181],
                            [39.78709292, -5.37837468],
                            [39.78720526, -5.37833982],
                            [39.78727112, -5.37831657],
                            [39.78730211, -5.37827008],
                            [39.78733697, -5.37825845],
                            [39.78740283, -5.37821971],
                            [39.78741058, -5.37802599],
                            [39.78743382, -5.37782065],
                            [39.78745319, -5.37754169],
                            [39.7875113, -5.37713101],
                            [39.78749581, -5.37706127],
                            [39.78744932, -5.37695279],
                            [39.7874532, -5.3768908],
                            [39.78746094, -5.37680556],
                            [39.78750743, -5.3767242],
                            [39.78753455, -5.37666221],
                            [39.7875423, -5.37656923],
                            [39.78753455, -5.37648786],
                            [39.78749968, -5.37636776],
                            [39.78746482, -5.37630189],
                            [39.78746869, -5.37624378],
                            [39.78746482, -5.37614692],
                            [39.78748031, -5.37610043],
                            [39.78756554, -5.37605781],
                            [39.78758104, -5.37600357],
                            [39.78753455, -5.37591446],
                            [39.78756554, -5.37587184],
                            [39.78762752, -5.37579822],
                            [39.78761978, -5.37574398],
                            [39.78757329, -5.37566262],
                            [39.78756942, -5.37559288],
                            [39.78757329, -5.37550765],
                            [39.78759266, -5.37539529],
                            [39.78762752, -5.37529068],
                            [39.78767789, -5.37527131],
                            [39.78777086, -5.37529843],
                            [39.78787546, -5.37532942],
                            [39.78797231, -5.37532555],
                            [39.78803429, -5.37529068],
                            [39.78807303, -5.37521707],
                            [39.78813889, -5.37514733],
                            [39.78821636, -5.37509309],
                            [39.78826285, -5.37505047],
                            [39.78830546, -5.37500398],
                            [39.78832871, -5.37492649],
                            [39.7883597, -5.37482188],
                            [39.78842168, -5.37471727],
                            [39.78849141, -5.37469403],
                            [39.78856115, -5.37460104],
                            [39.7885534, -5.37452743],
                            [39.7885379, -5.3744112],
                            [39.78846817, -5.37440732],
                            [39.78841781, -5.37443057],
                            [39.78834421, -5.37447706],
                            [39.78830934, -5.37455455],
                            [39.78826673, -5.37464754],
                            [39.78821249, -5.37464753],
                            [39.78822024, -5.37455455],
                            [39.78830934, -5.37431046],
                            [39.78834033, -5.37424073],
                            [39.78839069, -5.37414387],
                            [39.78844493, -5.37404701],
                            [39.78851466, -5.37387266],
                            [39.78858052, -5.37378355],
                            [39.78864637, -5.37368669],
                            [39.78875872, -5.37350459],
                            [39.78884007, -5.37341161],
                            [39.78892142, -5.37344648],
                            [39.78900966, -5.37362936],
                            [39.78906863, -5.37368669],
                            [39.78914224, -5.37368282],
                            [39.78925846, -5.37364795],
                            [39.7893863, -5.37361308],
                            [39.7894754, -5.37353946],
                            [39.78948315, -5.37347747],
                            [39.78950252, -5.37336512],
                            [39.78950252, -5.37322564],
                            [39.7894754, -5.37315978],
                            [39.78950639, -5.37309391],
                            [39.78955288, -5.37305129],
                            [39.78962261, -5.37299705],
                            [39.78973495, -5.37302417],
                            [39.78990541, -5.37302417],
                            [39.79002162, -5.37303967],
                            [39.7902037, -5.37304742],
                            [39.79033541, -5.3730358],
                            [39.79037028, -5.37299318],
                            [39.79040127, -5.37293894],
                            [39.79041677, -5.37289632],
                            [39.79046325, -5.37282271],
                            [39.79049037, -5.37272585],
                            [39.79048262, -5.37265223],
                            [39.790471, -5.37257475],
                            [39.790471, -5.37253988],
                            [39.79050199, -5.3725205],
                            [39.7905601, -5.37248951],
                            [39.79058335, -5.37242752],
                            [39.79061821, -5.3723694],
                            [39.79061434, -5.3722648],
                            [39.79060272, -5.37222218],
                            [39.79057947, -5.37214469],
                            [39.79054848, -5.37205558],
                            [39.79049425, -5.37202071],
                            [39.79043226, -5.37197809],
                            [39.79042064, -5.3719471],
                            [39.79038965, -5.37190835],
                            [39.79034316, -5.37190835],
                            [39.79028505, -5.37188511],
                            [39.79028893, -5.37175338],
                            [39.79026956, -5.37168751],
                            [39.79025019, -5.3715984],
                            [39.79019983, -5.37150542],
                            [39.79029063, -5.3715443],
                            [39.79046093, -5.37172242],
                            [39.79053651, -5.37183596],
                            [39.7906834, -5.37195915],
                            [39.79079955, -5.37213334],
                            [39.79080061, -5.37228319],
                            [39.79086474, -5.37237962],
                            [39.79092535, -5.37247075],
                            [39.79102828, -5.3725165],
                            [39.79111977, -5.37257941],
                            [39.7911312, -5.37268235],
                            [39.79108916, -5.3727618],
                            [39.79118839, -5.3729111],
                            [39.79139996, -5.37285392],
                            [39.79149145, -5.37282532],
                            [39.79161153, -5.37273382],
                            [39.79178308, -5.37262516],
                            [39.79178308, -5.37255654],
                            [39.79176592, -5.37250507],
                            [39.7917259, -5.37229919],
                            [39.79168587, -5.37219053],
                            [39.79165156, -5.37205328],
                            [39.79157151, -5.37193318],
                            [39.79148001, -5.37172159],
                            [39.79146858, -5.37152143],
                            [39.79144571, -5.37144136],
                            [39.79137137, -5.37124692],
                            [39.79129132, -5.37107536],
                            [39.79122842, -5.37086948],
                            [39.79132563, -5.37073795],
                            [39.79138281, -5.3707551],
                            [39.79164584, -5.37097814],
                            [39.79180595, -5.37112111],
                            [39.79201181, -5.37116686],
                            [39.79209758, -5.37112683],
                            [39.7922291, -5.3710868],
                            [39.7924178, -5.37101817],
                            [39.79268084, -5.37074367],
                            [39.79272086, -5.37072079],
                            [39.79276661, -5.37058354],
                            [39.79268084, -5.37045201],
                            [39.79260078, -5.37021753],
                            [39.79273802, -5.37005741],
                            [39.79278948, -5.36996591],
                            [39.79288097, -5.36982865],
                            [39.79306396, -5.36977147],
                            [39.79307539, -5.36945693],
                            [39.79309255, -5.3693883],
                            [39.79313258, -5.36922818],
                            [39.79306396, -5.36906233],
                            [39.79299534, -5.36886217],
                            [39.79296103, -5.3687192],
                            [39.79295531, -5.36857051],
                            [39.7929496, -5.36839894],
                            [39.7929496, -5.3682045],
                            [39.79301821, -5.36801006],
                            [39.79309255, -5.36793572],
                            [39.79312114, -5.36782134],
                            [39.79314401, -5.36767837],
                            [39.79334415, -5.36764978],
                            [39.79368724, -5.36760975],
                            [39.7937673, -5.36754112],
                            [39.79393313, -5.36754684],
                            [39.79408618, -5.36758703],
                            [39.7941447, -5.36766122],
                            [39.7942705, -5.36772984],
                            [39.79442489, -5.36771841],
                            [39.79463074, -5.36767265],
                            [39.79492237, -5.3675354],
                            [39.79507676, -5.36743818],
                            [39.79526546, -5.36724374],
                            [39.79536839, -5.36711793],
                            [39.79541986, -5.36700355],
                            [39.79551135, -5.36691777],
                            [39.79556853, -5.36679196],
                            [39.79560284, -5.36693493],
                            [39.79568861, -5.36711793],
                            [39.79584872, -5.36714652],
                            [39.79601455, -5.36714652],
                            [39.79610604, -5.36713509],
                            [39.7962204, -5.36714081],
                            [39.79631189, -5.3671694],
                            [39.79639767, -5.36721515],
                            [39.79656349, -5.36722659],
                            [39.79663211, -5.36720371],
                            [39.79672932, -5.36716368],
                            [39.79678079, -5.36714653],
                            [39.79683797, -5.36712365],
                            [39.79692374, -5.36710077],
                            [39.7970667, -5.36707218],
                            [39.79714103, -5.36706646],
                            [39.79723824, -5.36704931],
                            [39.79727255, -5.36702643],
                            [39.79733545, -5.36695209],
                            [39.79742694, -5.36690062],
                            [39.79750128, -5.36690634],
                            [39.7975699, -5.36692349],
                            [39.79768426, -5.36692349],
                            [39.79779862, -5.36692349],
                            [39.79787868, -5.36692349],
                            [39.79797017, -5.36693493],
                            [39.79812456, -5.36691777],
                            [39.79820462, -5.36693493],
                            [39.7982618, -5.36706646],
                            [39.79834185, -5.36724375],
                            [39.79846193, -5.36727806],
                            [39.79863559, -5.36718908],
                            [39.79871925, -5.36728378],
                            [39.79875317, -5.36742507],
                            [39.7988908, -5.36758116],
                            [39.79897657, -5.36774701],
                            [39.79892511, -5.36776988],
                            [39.7988279, -5.36775844],
                            [39.79878787, -5.36775844],
                            [39.79874784, -5.36779848],
                            [39.79874212, -5.36797004],
                            [39.79874212, -5.36815304],
                            [39.79867922, -5.36839895],
                            [39.7987428, -5.36863205],
                            [39.79878569, -5.36868185],
                            [39.7988622, -5.36877068],
                            [39.79880502, -5.36888505],
                            [39.79877071, -5.36891937],
                            [39.79868494, -5.36901087],
                            [39.79865635, -5.36910237],
                            [39.79867971, -5.3692066],
                            [39.79870781, -5.36923962],
                            [39.79881074, -5.3692625],
                            [39.79891939, -5.36933112],
                            [39.7989251, -5.36946838],
                            [39.79887936, -5.36951985],
                            [39.79881646, -5.36955988],
                            [39.79867922, -5.36966282],
                            [39.79864491, -5.36977147],
                            [39.79865246, -5.3698978],
                            [39.79871925, -5.36997735],
                            [39.79879358, -5.36994876],
                            [39.79890223, -5.36990301],
                            [39.79905662, -5.36972572],
                            [39.7991767, -5.36966854],
                            [39.79935969, -5.36959991],
                            [39.79949692, -5.36955416],
                            [39.79957698, -5.36951985],
                            [39.79966275, -5.36951985],
                            [39.7997714, -5.36953128],
                            [39.79991435, -5.36951413],
                            [39.79997153, -5.36942835],
                            [39.79991435, -5.36931969],
                            [39.79977711, -5.36925106],
                            [39.79970278, -5.369171],
                            [39.7997085, -5.36903946],
                            [39.79977712, -5.36892509],
                            [39.79989148, -5.36888506],
                            [39.80018883, -5.36875352],
                            [39.80052048, -5.36873065],
                            [39.80064628, -5.36877354],
                            [39.80077208, -5.36881643],
                            [39.80099509, -5.36885074],
                            [39.8011552, -5.36890221],
                            [39.80129815, -5.36899372],
                            [39.80152116, -5.36898228],
                            [39.80152116, -5.36885646],
                            [39.80158978, -5.36883931],
                            [39.80181753, -5.36884068],
                            [39.80204152, -5.36908522],
                            [39.80230423, -5.36950299],
                            [39.80241892, -5.36962851],
                            [39.80260762, -5.36962279],
                            [39.80287066, -5.36946838],
                            [39.80315085, -5.36942835],
                            [39.80341388, -5.36931969],
                            [39.80377985, -5.369171],
                            [39.80389993, -5.36915385],
                            [39.80402001, -5.36948554],
                            [39.80412243, -5.36973322],
                            [39.80418012, -5.36979436],
                            [39.80431164, -5.36972001],
                            [39.80446603, -5.3695027],
                            [39.80450034, -5.36946266],
                            [39.80460899, -5.36931398],
                            [39.80459183, -5.36921104],
                            [39.8045175, -5.36909094],
                            [39.80442601, -5.36900516],
                            [39.80430021, -5.36883359],
                            [39.80413438, -5.36873065],
                            [39.80396855, -5.3686849],
                            [39.8038885, -5.36866203],
                            [39.80375698, -5.36855909],
                            [39.8036712, -5.36842184],
                            [39.80364261, -5.36825599],
                            [39.80347107, -5.36809586],
                            [39.80337386, -5.36810158],
                            [39.80331096, -5.36803867],
                            [39.80325378, -5.36790142],
                            [39.80308795, -5.36775273],
                            [39.80303649, -5.36774701],
                            [39.80291069, -5.36777561],
                            [39.80276773, -5.36779276],
                            [39.80269339, -5.36767267],
                            [39.80264765, -5.36743819],
                            [39.80265909, -5.36728379],
                            [39.80268196, -5.3671351],
                            [39.80263621, -5.36690634],
                            [39.80265337, -5.36682056],
                            [39.80270483, -5.36663184],
                            [39.80268196, -5.36647171],
                            [39.80265337, -5.36643168],
                            [39.80265909, -5.36629443],
                            [39.80280204, -5.36632302],
                            [39.802945, -5.36636305],
                            [39.80322519, -5.36639165],
                            [39.80342533, -5.36638593],
                            [39.80349394, -5.36626012],
                            [39.80354541, -5.36596274],
                            [39.8036369, -5.3658598],
                            [39.80368265, -5.36567679],
                            [39.80380273, -5.36551667],
                            [39.80385991, -5.36535654],
                            [39.80396856, -5.36510491],
                            [39.80400286, -5.36496194],
                            [39.80416297, -5.36463597],
                            [39.80423731, -5.36449872],
                            [39.80431165, -5.36431571],
                            [39.80437455, -5.36409268],
                            [39.80434596, -5.3638868],
                            [39.80438599, -5.36366948],
                            [39.80450035, -5.36350936],
                            [39.8044832, -5.36328632],
                            [39.80452894, -5.36320626],
                            [39.80463759, -5.36299466],
                            [39.80477482, -5.36278878],
                            [39.80482057, -5.36260578],
                            [39.80486632, -5.36247997],
                            [39.80494637, -5.36237131],
                            [39.80503586, -5.36229985],
                            [39.80508361, -5.36234272],
                            [39.80511214, -5.36245651],
                            [39.80516366, -5.36250284],
                            [39.80531805, -5.36238847],
                            [39.80538095, -5.36226837],
                            [39.80546673, -5.36217687],
                            [39.80559253, -5.36218831],
                            [39.80576407, -5.3623999],
                            [39.80578123, -5.36270872],
                            [39.80578123, -5.36289744],
                            [39.80576979, -5.36330348],
                            [39.80563255, -5.36371524],
                            [39.80558109, -5.36387536],
                            [39.80554106, -5.36396687],
                            [39.80556393, -5.36414415],
                            [39.80559824, -5.36425281],
                            [39.80566114, -5.36447012],
                            [39.80578694, -5.36453303],
                            [39.80593906, -5.3646112],
                            [39.80602139, -5.36468744],
                            [39.80606141, -5.36481897],
                            [39.80608429, -5.36489904],
                            [39.80615291, -5.3648876],
                            [39.8062444, -5.36477894],
                            [39.80637591, -5.36460738],
                            [39.80638163, -5.36443009],
                            [39.80637592, -5.36430428],
                            [39.80633017, -5.36422421],
                            [39.80622724, -5.36404693],
                            [39.80615291, -5.36393827],
                            [39.80616434, -5.36388108],
                            [39.8062444, -5.36388108],
                            [39.80635876, -5.36389824],
                            [39.80647884, -5.36394971],
                            [39.80661036, -5.36394971],
                            [39.80677619, -5.3639154],
                            [39.80691343, -5.36385249],
                            [39.8070621, -5.36384105],
                            [39.80719934, -5.36386393],
                            [39.80726795, -5.36375527],
                            [39.80728511, -5.3637038],
                            [39.80739375, -5.36365805],
                            [39.80743378, -5.36356655],
                            [39.8074395, -5.36348077],
                            [39.8075024, -5.36342358],
                            [39.80745094, -5.36337783],
                            [39.8073766, -5.36336639],
                            [39.8072508, -5.3633378],
                            [39.807125, -5.36326345],
                            [39.80703351, -5.36324058],
                            [39.80689627, -5.36321198],
                            [39.80679906, -5.36321198],
                            [39.80673044, -5.36325201],
                            [39.80671901, -5.36318911],
                            [39.80671329, -5.36304613],
                            [39.8067476, -5.36297179],
                            [39.80681622, -5.36289744],
                            [39.80694202, -5.36288601],
                            [39.80711928, -5.36278879],
                            [39.80726796, -5.36270872],
                            [39.80742235, -5.36258291],
                            [39.8075048, -5.36258676],
                            [39.80763964, -5.36270301],
                            [39.80776544, -5.36270301],
                            [39.80784549, -5.36268585],
                            [39.80789124, -5.36260007],
                            [39.80793698, -5.36241706],
                            [39.80798273, -5.36230841],
                            [39.80805707, -5.36219975],
                            [39.80817143, -5.36209681],
                            [39.80820002, -5.36194812],
                            [39.80822861, -5.36167362],
                            [39.80817715, -5.36149061],
                            [39.8081943, -5.36135336],
                            [39.80833154, -5.36134192],
                            [39.8085374, -5.36140483],
                            [39.80866891, -5.36131905],
                            [39.80868035, -5.36119895],
                            [39.80875469, -5.3610617],
                            [39.80880043, -5.36093017],
                            [39.80882902, -5.3607872],
                            [39.80876612, -5.36072429],
                            [39.80862317, -5.36073573],
                            [39.80852596, -5.36077576],
                            [39.80844591, -5.36076432],
                            [39.80836013, -5.36068426],
                            [39.8082858, -5.36064994],
                            [39.80818859, -5.36058132],
                            [39.80809138, -5.36054129],
                            [39.80800561, -5.36058704],
                            [39.80794271, -5.36059276],
                            [39.80785693, -5.36054129],
                            [39.80774829, -5.36055272],
                            [39.8076568, -5.360547],
                            [39.8076568, -5.36044407],
                            [39.80763964, -5.36024963],
                            [39.80756531, -5.36014097],
                            [39.80748525, -5.36006662],
                            [39.80734801, -5.36004375],
                            [39.80724509, -5.36011809],
                            [39.8070907, -5.36023819],
                            [39.80693059, -5.36030109],
                            [39.80678191, -5.36031253],
                            [39.80669042, -5.36031253],
                            [39.80655319, -5.36024962],
                            [39.80648457, -5.36014097],
                            [39.80639879, -5.36003803],
                            [39.80629015, -5.35999228],
                            [39.80619866, -5.36002087],
                            [39.8060557, -5.36007234],
                            [39.8059928, -5.36009521],
                            [39.80584413, -5.36011237],
                            [39.80565543, -5.36013525],
                            [39.80546101, -5.36011237],
                            [39.80546673, -5.36000371],
                            [39.80548388, -5.35986646],
                            [39.80546101, -5.35963771],
                            [39.80544958, -5.35952333],
                            [39.80538668, -5.35928314],
                            [39.80531806, -5.35907726],
                            [39.8053638, -5.35900292],
                            [39.80550676, -5.35896288],
                            [39.80560397, -5.35888854],
                            [39.80556966, -5.35868838],
                            [39.80556394, -5.35842531],
                            [39.80562112, -5.35831094],
                            [39.80577551, -5.35827662],
                            [39.80609573, -5.35827662],
                            [39.806273, -5.35821372],
                            [39.80633018, -5.35819656],
                            [39.80641023, -5.35811078],
                            [39.8064617, -5.35800784],
                            [39.80647313, -5.35777909],
                            [39.80642167, -5.35771618],
                            [39.80631303, -5.35747599],
                            [39.80638164, -5.35739021],
                            [39.80654747, -5.35737877],
                            [39.80669043, -5.35735589],
                            [39.80670758, -5.35727583],
                            [39.80666755, -5.35706995],
                            [39.80663324, -5.35699561],
                            [39.80666184, -5.35689267],
                            [39.80676476, -5.35682976],
                            [39.80697062, -5.35669251],
                            [39.80707355, -5.35660673],
                            [39.80707926, -5.35649807],
                            [39.80697634, -5.3563551],
                            [39.80701636, -5.35632078],
                            [39.80713073, -5.356235],
                            [39.80722222, -5.35620641],
                            [39.80747954, -5.35620641],
                            [39.80753672, -5.35613778],
                            [39.80755959, -5.35606916],
                            [39.80761106, -5.3559319],
                            [39.8076568, -5.3558404],
                            [39.80768539, -5.35561737],
                            [39.80759962, -5.35549727],
                            [39.80752528, -5.35533143],
                            [39.80755388, -5.3550798],
                            [39.80753672, -5.35491395],
                            [39.80761106, -5.35474811],
                            [39.80774258, -5.35452507],
                            [39.80783979, -5.35449076],
                            [39.80811426, -5.35449648],
                            [39.8083144, -5.3544736],
                            [39.80840017, -5.35433635],
                            [39.80840017, -5.35405041],
                            [39.80836586, -5.35397034],
                            [39.80823434, -5.35386741],
                            [39.80804564, -5.35372443],
                            [39.80816001, -5.35360434],
                            [39.80820575, -5.35343849],
                            [39.80821147, -5.3533184],
                            [39.80829153, -5.35327837],
                            [39.80843448, -5.35313539],
                            [39.80846879, -5.35295239],
                            [39.80842304, -5.35284945],
                            [39.80827437, -5.35274651],
                            [39.80809139, -5.35268933],
                            [39.80795987, -5.35254635],
                            [39.80805136, -5.35239766],
                            [39.80807995, -5.35234619],
                            [39.80810283, -5.35230616],
                            [39.80810283, -5.35224326],
                            [39.80807995, -5.35216891],
                            [39.80807424, -5.35207741],
                            [39.80795987, -5.35191728],
                            [39.80798275, -5.35181434],
                            [39.80808567, -5.35175144],
                            [39.8081886, -5.3516485],
                            [39.80822863, -5.351557],
                            [39.80816573, -5.35147693],
                            [39.80806852, -5.35140831],
                            [39.80792556, -5.35137399],
                            [39.80769112, -5.35135112],
                            [39.80760368, -5.35134426],
                            [39.80739949, -5.35132824],
                            [39.80726225, -5.35120815],
                            [39.80727369, -5.35101371],
                            [39.80736518, -5.35079067],
                            [39.80744524, -5.35067629],
                            [39.80742236, -5.35039035],
                            [39.80735375, -5.35023022],
                            [39.80726226, -5.35000147],
                            [39.80711358, -5.34977272],
                            [39.80711358, -5.34965262],
                            [39.8071822, -5.34962403],
                            [39.80735947, -5.3495554],
                            [39.8073737, -5.34954045],
                            [39.80747383, -5.34943531],
                            [39.80763394, -5.34912649],
                            [39.80767969, -5.34904643],
                            [39.80777689, -5.34888058],
                            [39.80767969, -5.3486747],
                            [39.80773687, -5.34851457],
                            [39.80775402, -5.34822291],
                            [39.80769112, -5.34809138],
                            [39.80766253, -5.34796557],
                            [39.80770256, -5.34780544],
                            [39.80766825, -5.3475824],
                            [39.8076511, -5.34745659],
                            [39.80762251, -5.34733649],
                            [39.80742237, -5.34725071],
                            [39.80729085, -5.34723355],
                            [39.80713646, -5.34722784],
                            [39.80697063, -5.3472793],
                            [39.80679337, -5.34725643],
                            [39.8067419, -5.34711346],
                            [39.80670188, -5.34694761],
                            [39.80668472, -5.34675889],
                            [39.80663898, -5.34657017],
                            [39.80657608, -5.34650726],
                            [39.80641597, -5.34631854],
                            [39.80637022, -5.34615269],
                            [39.80637022, -5.34606119],
                            [39.80648459, -5.34596397],
                            [39.8066447, -5.34591822],
                            [39.80683912, -5.3458496],
                            [39.8070221, -5.34570663],
                            [39.80699923, -5.34557509],
                            [39.80685055, -5.34552934],
                            [39.80666185, -5.34558081],
                            [39.80646743, -5.34564372],
                            [39.8063931, -5.34559797],
                            [39.80618724, -5.34561512],
                            [39.80599854, -5.3456094],
                            [39.80595851, -5.3455465],
                            [39.80599854, -5.34544356],
                            [39.80608432, -5.34526055],
                            [39.80603285, -5.34507755],
                            [39.80596625, -5.34500678],
                            [39.80594136, -5.34498033],
                            [39.80552393, -5.34493458],
                            [39.80524374, -5.34490027],
                            [39.80500358, -5.34487739],
                            [39.8048206, -5.34483736],
                            [39.80462046, -5.34479161],
                            [39.80442604, -5.34468867],
                            [39.80426593, -5.34466579],
                            [39.80404864, -5.34466007],
                            [39.80389425, -5.34467151],
                            [39.80379704, -5.34466579],
                            [39.80375701, -5.34456285],
                            [39.80371127, -5.34443132],
                            [39.80361978, -5.34446563],
                            [39.80352828, -5.34448279],
                            [39.80351206, -5.34448369],
                            [39.80342536, -5.34448851],
                            [39.80326525, -5.34440273],
                            [39.80306511, -5.34429979],
                            [39.80303652, -5.34423688],
                            [39.80289928, -5.34421972],
                            [39.80281923, -5.34421972],
                            [39.80267627, -5.34411678],
                            [39.80254476, -5.34399097],
                            [39.80242467, -5.3439395],
                            [39.802276, -5.34392806],
                            [39.80213305, -5.34392234],
                            [39.80200725, -5.34383656],
                            [39.80206443, -5.34376221],
                            [39.80203584, -5.34364212],
                            [39.80209302, -5.34356777],
                            [39.80212161, -5.34341908],
                            [39.8022131, -5.34332186],
                            [39.80220167, -5.34319033],
                            [39.80216164, -5.3430588],
                            [39.80216736, -5.34297873],
                            [39.80224169, -5.34292726],
                            [39.80220167, -5.34278429],
                            [39.8022131, -5.34274998],
                            [39.80226457, -5.34265848],
                            [39.80226457, -5.34253838],
                            [39.80225313, -5.34243544],
                            [39.80231603, -5.34237826],
                            [39.80238465, -5.34228104],
                            [39.80237893, -5.34211519],
                            [39.80229888, -5.34193791],
                            [39.80222454, -5.34177206],
                            [39.80210446, -5.34161765],
                            [39.80199581, -5.34146896],
                            [39.80200725, -5.34134315],
                            [39.80195579, -5.34132599],
                            [39.80185858, -5.34130883],
                            [39.80178424, -5.34128024],
                            [39.80177852, -5.34119446],
                            [39.80176137, -5.3410858],
                            [39.80174421, -5.34101145],
                            [39.80173278, -5.34093139],
                            [39.80166416, -5.34085133],
                            [39.80160698, -5.34075411],
                            [39.8015498, -5.34067976],
                            [39.80149261, -5.34065117],
                            [39.801424, -5.34066832],
                            [39.8013325, -5.34069692],
                            [39.8012353, -5.3407541],
                            [39.80110949, -5.34078842],
                            [39.80097798, -5.34083417],
                            [39.80088649, -5.34101717],
                            [39.80081787, -5.34119445],
                            [39.80076068, -5.34130311],
                            [39.80071494, -5.34133743],
                            [39.80055483, -5.34137746],
                            [39.80039472, -5.34140033],
                            [39.80017171, -5.34142893],
                            [39.80005163, -5.34144036],
                            [39.80000996, -5.3414356],
                            [39.79985149, -5.34141749],
                            [39.79974285, -5.34137174],
                            [39.79958845, -5.34140033],
                            [39.79946265, -5.34138889],
                            [39.79927197, -5.34123665],
                            [39.79923164, -5.34101478],
                            [39.79909551, -5.34091898],
                            [39.79880309, -5.34077275],
                            [39.79825858, -5.3406114],
                            [39.79792078, -5.34055593],
                            [39.79764348, -5.34069207],
                            [39.79746198, -5.34075258],
                            [39.79732585, -5.34077779],
                            [39.79727543, -5.34075258],
                            [39.7971746, -5.34064669],
                            [39.79704351, -5.34062148],
                            [39.79695276, -5.34062652],
                            [39.79692251, -5.34056601],
                            [39.79674101, -5.34054584],
                            [39.79653429, -5.34056097],
                            [39.79626708, -5.34065677],
                            [39.79608558, -5.34076771],
                            [39.79594441, -5.34090385],
                            [39.79578307, -5.34112067],
                            [39.79568728, -5.34130723],
                            [39.7956035, -5.3414058],
                            [39.79551586, -5.34150893],
                            [39.79545535, -5.34163499],
                            [39.79529906, -5.34171566],
                            [39.79535956, -5.3416249],
                            [39.7954251, -5.34151901],
                            [39.79553463, -5.34137137],
                            [39.79554107, -5.3413627],
                            [39.79562678, -5.34123664],
                            [39.79572257, -5.34108033],
                            [39.79582845, -5.34089376],
                            [39.7959192, -5.34078283],
                            [39.79607549, -5.34066686],
                            [39.79624187, -5.34055593],
                            [39.79646371, -5.34045508],
                            [39.79662505, -5.34045508],
                            [39.79679142, -5.34046012],
                            [39.7969637, -5.34048771],
                            [39.79712922, -5.34058114],
                            [39.79722502, -5.34059627],
                            [39.79726535, -5.34055593],
                            [39.79733089, -5.34048029],
                            [39.79736619, -5.34041474],
                            [39.79737792, -5.3403691],
                            [39.79741156, -5.34023826],
                            [39.7973914, -5.34000127],
                            [39.79740148, -5.33992059],
                            [39.79740652, -5.33978949],
                            [39.79747711, -5.33976428],
                            [39.79759307, -5.33972899],
                            [39.79762836, -5.33960797],
                            [39.79767374, -5.33945166],
                            [39.79766365, -5.3393609],
                            [39.79760315, -5.3391945],
                            [39.79755273, -5.33910878],
                            [39.79748215, -5.33903314],
                            [39.79741156, -5.33896255],
                            [39.79731073, -5.33896255],
                            [39.79723006, -5.33898776],
                            [39.79707881, -5.33905835],
                            [39.79685697, -5.33913903],
                            [39.79674101, -5.33917433],
                            [39.79657463, -5.33918945],
                            [39.796378, -5.33912895],
                            [39.79626204, -5.33908861],
                            [39.79616121, -5.33902306],
                            [39.79606037, -5.33895246],
                            [39.79593937, -5.33882641],
                            [39.79582845, -5.33880119],
                            [39.79571753, -5.33878607],
                            [39.79562174, -5.33875077],
                            [39.79550578, -5.33867009],
                            [39.79543015, -5.33857429],
                            [39.79540494, -5.33852386],
                            [39.79541503, -5.33842806],
                            [39.79534444, -5.33828183],
                            [39.79530411, -5.3382062],
                            [39.79526881, -5.33813056],
                            [39.79526881, -5.33802467],
                            [39.79531923, -5.33793895],
                            [39.79540998, -5.33791374],
                            [39.79550074, -5.33784315],
                            [39.79555363, -5.3378027],
                            [39.79562174, -5.33783811],
                            [39.79564695, -5.33788853],
                            [39.79553603, -5.3379087],
                            [39.79540494, -5.33796921],
                            [39.7953999, -5.33810031],
                            [39.79548057, -5.33835243],
                            [39.79556124, -5.33851378],
                            [39.79569936, -5.3386224],
                            [39.79578812, -5.33867009],
                            [39.79594945, -5.33865497],
                            [39.79618137, -5.338897],
                            [39.79635783, -5.33903314],
                            [39.7965343, -5.33909365],
                            [39.79671076, -5.33911886],
                            [39.79691747, -5.33902306],
                            [39.79704352, -5.33892221],
                            [39.79710906, -5.33879615],
                            [39.7971696, -5.33862501],
                            [39.79728552, -5.33868522],
                            [39.79745694, -5.33865497],
                            [39.79762332, -5.33860959],
                            [39.79779474, -5.33842806],
                            [39.7979107, -5.33822133],
                            [39.79794095, -5.33811544],
                            [39.79801658, -5.33795913],
                            [39.79807204, -5.33774231],
                            [39.79808212, -5.33763642],
                            [39.79805691, -5.33746498],
                            [39.79803675, -5.33735909],
                            [39.79809221, -5.33731371],
                            [39.79812246, -5.33723303],
                            [39.79815271, -5.33716748],
                            [39.79826401, -5.33731889],
                            [39.79860142, -5.33748011],
                            [39.79908543, -5.33774735],
                            [39.79926189, -5.33786837],
                            [39.79943331, -5.33786332],
                            [39.79956944, -5.33784316],
                            [39.79973078, -5.33779273],
                            [39.79987195, -5.33772718],
                            [39.79999295, -5.33764146],
                            [39.80011395, -5.33752549],
                            [39.80017446, -5.33742464],
                            [39.80027025, -5.33735405],
                            [39.80031058, -5.33726329],
                            [39.80031058, -5.33723303],
                            [39.80030554, -5.33713219],
                            [39.80037237, -5.33705655],
                            [39.80046184, -5.33709689],
                            [39.8005425, -5.33709185],
                            [39.80062317, -5.33704143],
                            [39.80074418, -5.33693554],
                            [39.80084501, -5.33685486],
                            [39.80093072, -5.33678931],
                            [39.80094081, -5.33664308],
                            [39.80099122, -5.33647164],
                            [39.80106685, -5.3364313],
                            [39.8011576, -5.3363708],
                            [39.80120802, -5.33627499],
                            [39.80118785, -5.33620944],
                            [39.80112735, -5.33610859],
                            [39.80112735, -5.33601783],
                            [39.80116264, -5.33593716],
                            [39.80118281, -5.33585144],
                            [39.8010971, -5.33573042],
                            [39.80100635, -5.33568504],
                            [39.80102652, -5.33561445],
                            [39.80114224, -5.33557545],
                            [39.80118785, -5.33559428],
                            [39.80128365, -5.33556906],
                            [39.80136936, -5.33550856],
                            [39.80142482, -5.33543292],
                            [39.80141978, -5.33532703],
                            [39.8014954, -5.33526148],
                            [39.80156094, -5.33523627],
                            [39.80163153, -5.33518585],
                            [39.80163153, -5.33505979],
                            [39.80167691, -5.33497407],
                            [39.80168195, -5.33488835],
                            [39.80174749, -5.33486314],
                            [39.80180295, -5.33476733],
                            [39.80188362, -5.33460094],
                            [39.80200462, -5.33449001],
                            [39.80207016, -5.33439924],
                            [39.80206512, -5.33424797],
                            [39.8021105, -5.33414713],
                            [39.80209537, -5.33395048],
                            [39.80209033, -5.33374374],
                            [39.80207521, -5.33363785],
                            [39.80200966, -5.33356726],
                            [39.80194412, -5.333537],
                            [39.80195925, -5.33344624],
                            [39.80201975, -5.33334035],
                            [39.80212563, -5.33321934],
                            [39.80220629, -5.33317396],
                            [39.80235755, -5.33313362],
                            [39.80242309, -5.33307311],
                            [39.80246847, -5.33300756],
                            [39.80250376, -5.33291176],
                            [39.80247351, -5.33277057],
                            [39.80241301, -5.33259409],
                            [39.80236259, -5.33252854],
                            [39.802292, -5.3325235],
                            [39.80229201, -5.33247811],
                            [39.80230713, -5.33242265],
                            [39.80222142, -5.33232684],
                            [39.80207521, -5.33227138],
                            [39.80198446, -5.33228651],
                            [39.80192396, -5.33227642],
                            [39.80185841, -5.33224617],
                            [39.80174749, -5.3321907],
                            [39.80164666, -5.33216045],
                            [39.80158112, -5.3321907],
                            [39.80150549, -5.33223608],
                            [39.80145003, -5.33233189],
                            [39.80138449, -5.33244786],
                            [39.80139457, -5.332584],
                            [39.80145507, -5.33276048],
                            [39.8014702, -5.33290167],
                            [39.80146515, -5.33305294],
                            [39.80141474, -5.33309328],
                            [39.8013139, -5.33309832],
                            [39.80120802, -5.33308824],
                            [39.80114752, -5.33311345],
                            [39.80108702, -5.33309328],
                            [39.80103156, -5.33299747],
                            [39.80094081, -5.33296218],
                            [39.80089039, -5.33296218],
                            [39.80086014, -5.33297226],
                            [39.80081477, -5.33301764],
                            [39.80074922, -5.33307311],
                            [39.80068872, -5.33311345],
                            [39.80053747, -5.33309832],
                            [39.80050722, -5.33308823],
                            [39.80049713, -5.33302268],
                            [39.80040134, -5.33290167],
                            [39.80031563, -5.33279074],
                            [39.8001543, -5.33261425],
                            [39.80003833, -5.33251341],
                            [39.79994758, -5.33242769],
                            [39.79997279, -5.33232684],
                            [39.79998288, -5.33222599],
                            [39.80000808, -5.33214532],
                            [39.80002825, -5.33203943],
                            [39.80005346, -5.33199405],
                            [39.80006859, -5.33191337],
                            [39.80006354, -5.33187807],
                            [39.80002825, -5.33177723],
                            [39.80006859, -5.33171672],
                            [39.80010388, -5.33161083],
                            [39.80009884, -5.33150494],
                            [39.80010388, -5.33139905],
                            [39.80014421, -5.33129316],
                            [39.80017446, -5.33113685],
                            [39.80018959, -5.33100575],
                            [39.80018455, -5.33084944],
                            [39.80028776, -5.33075195],
                            [39.80033334, -5.33076644],
                            [39.80038765, -5.33078369],
                            [39.80045176, -5.33080406],
                            [39.80050722, -5.33078389],
                            [39.80058789, -5.33072842],
                            [39.80068368, -5.33063766],
                            [39.80074419, -5.33055194],
                            [39.80079964, -5.33045614],
                            [39.80090552, -5.33033512],
                            [39.80094081, -5.33023932],
                            [39.80102652, -5.33018385],
                            [39.80109711, -5.33013343],
                            [39.80113744, -5.33008805],
                            [39.80116265, -5.3300225],
                            [39.80116769, -5.32987123],
                            [39.8011929, -5.3298208],
                            [39.80124332, -5.32975525],
                            [39.80131895, -5.3296897],
                            [39.80137945, -5.32962919],
                            [39.80138449, -5.32951826],
                            [39.80137945, -5.32931153],
                            [39.80135424, -5.32922076],
                            [39.80132399, -5.32915521],
                            [39.80129626, -5.32912496],
                            [39.80126853, -5.32909471],
                            [39.80119795, -5.32905437],
                            [39.80112232, -5.32897873],
                            [39.80109711, -5.32891822],
                            [39.80112736, -5.32882242],
                            [39.80121448, -5.32880556],
                            [39.80131391, -5.3288325],
                            [39.80137441, -5.32880225],
                            [39.80143491, -5.328772],
                            [39.80150045, -5.32870645],
                            [39.80151054, -5.32862073],
                            [39.80154079, -5.32856526],
                            [39.80157104, -5.3286056],
                            [39.80164666, -5.32867115],
                            [39.80171725, -5.32865602],
                            [39.80179792, -5.32856526],
                            [39.8018685, -5.32842408],
                            [39.801929, -5.32833836],
                            [39.80199455, -5.3282123],
                            [39.80204496, -5.32815683],
                            [39.80206513, -5.32806103],
                            [39.80210042, -5.3279501],
                            [39.80211555, -5.32787446],
                            [39.8021507, -5.32784952],
                            [39.80223151, -5.32786942],
                            [39.80230714, -5.32782908],
                            [39.80237772, -5.32772823],
                            [39.80240293, -5.3276879],
                            [39.8024231, -5.32756688],
                            [39.80245839, -5.32736014],
                            [39.80248864, -5.32716349],
                            [39.80252393, -5.32700718],
                            [39.80255923, -5.32690129],
                            [39.80263485, -5.32676515],
                            [39.80273065, -5.32665422],
                            [39.80280123, -5.32657354],
                            [39.80293736, -5.3264021],
                            [39.80300794, -5.32629117],
                            [39.80304828, -5.32621049],
                            [39.8030987, -5.32613486],
                            [39.80316424, -5.32609452],
                            [39.80323482, -5.3260441],
                            [39.80326508, -5.3259735],
                            [39.80328524, -5.32589787],
                            [39.80332054, -5.32579702],
                            [39.80329533, -5.32574156],
                            [39.80326003, -5.32571634],
                            [39.80331549, -5.32567601],
                            [39.80341633, -5.32559533],
                            [39.80348187, -5.32549952],
                            [39.80351212, -5.3254138],
                            [39.80354237, -5.325318],
                            [39.80357262, -5.32515665],
                            [39.80358271, -5.32497512],
                            [39.80356254, -5.32486419],
                            [39.80347683, -5.32473813],
                            [39.80335079, -5.32458182],
                            [39.80327516, -5.32439021],
                            [39.80318945, -5.32425911],
                            [39.80316928, -5.32414818],
                            [39.80313903, -5.32405237],
                            [39.80309366, -5.32399187],
                            [39.80306341, -5.32390615],
                            [39.80299786, -5.32383555],
                            [39.8029172, -5.32381538],
                            [39.80277603, -5.32381538],
                            [39.80265502, -5.32373975],
                            [39.80246848, -5.32371454],
                            [39.80231723, -5.32369437],
                            [39.8021811, -5.32362377],
                            [39.80216597, -5.32349772],
                            [39.80215085, -5.32336661],
                            [39.80208531, -5.32326073],
                            [39.8020541, -5.32321031],
                            [39.80201976, -5.32315484],
                            [39.80192397, -5.32302374],
                            [39.80186347, -5.32277162],
                            [39.80185963, -5.32261402],
                            [39.80185843, -5.32256488],
                            [39.80190885, -5.3224237],
                            [39.80195926, -5.32229764],
                            [39.80205002, -5.32213628],
                            [39.80217606, -5.32192451],
                            [39.80223656, -5.32184887],
                            [39.80232227, -5.32174802],
                            [39.80236261, -5.32164214],
                            [39.80235756, -5.3215665],
                            [39.80234748, -5.3215312],
                            [39.80228194, -5.32144044],
                            [39.80220127, -5.32141019],
                            [39.80208531, -5.32136481],
                            [39.80195422, -5.32135472],
                            [39.80184835, -5.32134968],
                            [39.80174247, -5.32134464],
                            [39.80167188, -5.32133455],
                            [39.80156096, -5.32135472],
                            [39.80147525, -5.32136985],
                            [39.80138954, -5.32134968],
                            [39.80128871, -5.32137993],
                            [39.80113241, -5.32140514],
                            [39.80088033, -5.32143036],
                            [39.80074924, -5.32145052],
                            [39.80070795, -5.32147482],
                            [39.80066353, -5.32150095],
                            [39.80051732, -5.32154633],
                            [39.80039127, -5.32158667],
                            [39.80024002, -5.32158162],
                            [39.80007868, -5.32155641],
                            [39.79998289, -5.32151607],
                            [39.7998266, -5.32146565],
                            [39.79975097, -5.32139002],
                            [39.79961484, -5.32122866],
                            [39.79957703, -5.32115303],
                            [39.8000793, -5.32136087],
                            [39.80046701, -5.32140395],
                            [39.80064015, -5.32132314],
                            [39.80079011, -5.32125316],
                            [39.8013286, -5.32105928],
                            [39.80169478, -5.32108082],
                            [39.80238405, -5.3212747],
                            [39.80270714, -5.32133933],
                            [39.8027933, -5.32161937],
                            [39.80283638, -5.32207176],
                            [39.80262098, -5.32243798],
                            [39.80262098, -5.32256723],
                            [39.80287946, -5.32280419],
                            [39.80328871, -5.32293345],
                            [39.80356873, -5.32304116],
                            [39.80382721, -5.32304116],
                            [39.80402106, -5.32293345],
                            [39.80419338, -5.32269648],
                            [39.8044734, -5.3226534],
                            [39.80462417, -5.32267494],
                            [39.80445186, -5.32299807],
                            [39.80451648, -5.323472],
                            [39.80458109, -5.32381667],
                            [39.80492573, -5.32435523],
                            [39.80527036, -5.32489378],
                            [39.80535652, -5.32571238],
                            [39.80531344, -5.3263371],
                            [39.8050765, -5.32709108],
                            [39.80503342, -5.32803893],
                            [39.80520574, -5.32907295],
                            [39.80570115, -5.33036548],
                            [39.80591654, -5.33122717],
                            [39.80630426, -5.33150722],
                            [39.80664889, -5.33137796],
                            [39.80677813, -5.33118408],
                            [39.80692891, -5.33120563],
                            [39.80686429, -5.33139951],
                            [39.80677813, -5.33165801],
                            [39.80677813, -5.33193806],
                            [39.80677813, -5.33245507],
                            [39.80664889, -5.33286437],
                            [39.80690737, -5.33329521],
                            [39.8074674, -5.33338138],
                            [39.80819975, -5.33295054],
                            [39.80843669, -5.3327782],
                            [39.80851208, -5.3325197],
                            [39.80874901, -5.33225042],
                            [39.80878132, -5.33189498],
                            [39.80909365, -5.33158262],
                            [39.80935213, -5.33132411],
                            [39.80948137, -5.33092558],
                            [39.80954598, -5.33079633],
                            [39.80944649, -5.33065131],
                            [39.80934136, -5.33067785],
                            [39.8092552, -5.33067785],
                            [39.80927674, -5.33052705],
                            [39.80941675, -5.33030086],
                            [39.80934136, -5.33007467],
                            [39.80934136, -5.32992387],
                            [39.80940598, -5.32979462],
                            [39.80941675, -5.32967614],
                            [39.80932986, -5.32955166],
                            [39.80934136, -5.32939609],
                            [39.80939521, -5.32904065],
                            [39.80942752, -5.32867443],
                            [39.8095783, -5.32845901],
                            [39.8095783, -5.32765118],
                            [39.80943332, -5.32745215],
                            [39.8094706, -5.32718802],
                            [39.80956753, -5.32695106],
                            [39.809686, -5.32682181],
                            [39.80970754, -5.32669256],
                            [39.80972908, -5.32658484],
                            [39.80992294, -5.32657407],
                            [39.81013833, -5.32633711],
                            [39.81018141, -5.32606783],
                            [39.81028911, -5.32594935],
                            [39.81045066, -5.3258955],
                            [39.81056913, -5.32579856],
                            [39.81055836, -5.3254862],
                            [39.81042245, -5.32531196],
                            [39.81053682, -5.3250769],
                            [39.81056913, -5.32494764],
                            [39.81044815, -5.32479428],
                            [39.81032525, -5.32463848],
                            [39.81020154, -5.32448165],
                            [39.81010603, -5.32449526],
                            [39.81003064, -5.32449526],
                            [39.80991217, -5.3245168],
                            [39.80969677, -5.32447372],
                            [39.80954599, -5.32441986],
                            [39.80930906, -5.32447372],
                            [39.80915828, -5.32447372],
                            [39.80905835, -5.32434351],
                            [39.80896519, -5.32422213],
                            [39.80860902, -5.32420444],
                            [39.80850064, -5.3240619],
                            [39.8085444, -5.32399979],
                            [39.80877057, -5.32394593],
                            [39.80895365, -5.32394593],
                            [39.80909366, -5.32401056],
                            [39.8092229, -5.32408596],
                            [39.80928752, -5.32413981],
                            [39.80948137, -5.32421521],
                            [39.8095783, -5.32413981],
                            [39.80977216, -5.32412904],
                            [39.80980447, -5.32380591],
                            [39.80993371, -5.32351509],
                            [39.80977216, -5.32315965],
                            [39.80969677, -5.32292268],
                            [39.80958908, -5.32267495],
                            [39.80963216, -5.3225457],
                            [39.80984755, -5.3225457],
                            [39.81005218, -5.32253493],
                            [39.81021373, -5.32243799],
                            [39.81020296, -5.32229796],
                            [39.81012042, -5.32219803],
                            [39.81014911, -5.32189943],
                            [39.81021373, -5.32163016],
                            [39.81031066, -5.3215009],
                            [39.8104399, -5.32143628],
                            [39.81050451, -5.32143089],
                            [39.81056913, -5.32142551],
                            [39.81075222, -5.32129625],
                            [39.81085992, -5.32127471],
                            [39.81103224, -5.32113469],
                            [39.81099993, -5.32086541],
                            [39.81110763, -5.3208331],
                            [39.81127994, -5.32087618],
                            [39.81131625, -5.32092158],
                            [39.81132302, -5.32093004],
                            [39.81139259, -5.32101226],
                            [39.81144149, -5.32107006],
                            [39.81146303, -5.32140397],
                            [39.81149534, -5.32168401],
                            [39.81153842, -5.32189944],
                            [39.81161381, -5.322061],
                            [39.81169997, -5.32216871],
                            [39.81176459, -5.32230874],
                            [39.81176459, -5.32253493],
                            [39.81178612, -5.32282575],
                            [39.81185074, -5.32309502],
                            [39.81196921, -5.32326736],
                            [39.81207691, -5.32326736],
                            [39.81222769, -5.32364435],
                            [39.81248616, -5.32381669],
                            [39.81264771, -5.32406442],
                            [39.81282003, -5.32422599],
                            [39.81299235, -5.32438755],
                            [39.81328313, -5.32448449],
                            [39.81345545, -5.32448449],
                            [39.81366008, -5.32441987],
                            [39.81381085, -5.32423676],
                            [39.81377855, -5.32396748],
                            [39.81358485, -5.32374529],
                            [39.81325082, -5.32371975],
                            [39.81310005, -5.32373052],
                            [39.81300312, -5.32375206],
                            [39.81296044, -5.3237024],
                            [39.81277159, -5.32348267],
                            [39.81242155, -5.32331045],
                            [39.81223846, -5.32321351],
                            [39.8121523, -5.322955],
                            [39.81230308, -5.32282575],
                            [39.8125077, -5.32279343],
                            [39.81259386, -5.32275035],
                            [39.8127231, -5.32265341],
                            [39.81282003, -5.32248107],
                            [39.81297081, -5.32240568],
                            [39.81298158, -5.32240568],
                            [39.81314313, -5.32245953],
                            [39.81334775, -5.3226211],
                            [39.81344468, -5.32275035],
                            [39.81354161, -5.32289038],
                            [39.81360623, -5.32297654],
                            [39.81370316, -5.32312734],
                            [39.8137247, -5.32326736],
                            [39.81390778, -5.32340739],
                            [39.81419857, -5.32342893],
                            [39.81457552, -5.32342893],
                            [39.81479091, -5.32348279],
                            [39.81501708, -5.32352587],
                            [39.81522171, -5.32355818],
                            [39.81535094, -5.32354741],
                            [39.81545864, -5.32346124],
                            [39.81550172, -5.32324582],
                            [39.81550172, -5.32321351],
                            [39.81545878, -5.32316694],
                            [39.81521094, -5.32300886],
                            [39.81507093, -5.32301963],
                            [39.81490938, -5.3230304],
                            [39.8146186, -5.32292269],
                            [39.8146186, -5.32275035],
                            [39.81448936, -5.32271804],
                            [39.8144032, -5.32272881],
                            [39.81426319, -5.32275035],
                            [39.81420348, -5.32268436],
                            [39.81409087, -5.32244876],
                            [39.81373366, -5.32205488],
                            [39.81357392, -5.322061],
                            [39.81344468, -5.32225488],
                            [39.8134016, -5.32233028],
                            [39.81321852, -5.32222257],
                            [39.81312796, -5.3221211],
                            [39.81299575, -5.32197296],
                            [39.81306774, -5.32174864],
                            [39.81308928, -5.32149014],
                            [39.81330468, -5.32147937],
                            [39.81355238, -5.32145782],
                            [39.81375701, -5.32144705],
                            [39.81368162, -5.32125317],
                            [39.81377855, -5.32099467],
                            [39.81405857, -5.32091927],
                            [39.81417703, -5.32091927],
                            [39.81439243, -5.32091927],
                            [39.81475861, -5.32096236],
                            [39.814974, -5.32097313],
                            [39.81516786, -5.32097313],
                            [39.81537249, -5.32085465],
                            [39.81550173, -5.32079002],
                            [39.81562019, -5.32068231],
                            [39.81593252, -5.32067154],
                            [39.81612638, -5.32069308],
                            [39.81634177, -5.32069308],
                            [39.81657871, -5.32069308],
                            [39.81700951, -5.32043458],
                            [39.81725721, -5.32030533],
                            [39.81752646, -5.32026224],
                            [39.81762339, -5.32012222],
                            [39.81770955, -5.31989603],
                            [39.8177634, -5.31982063],
                            [39.81792494, -5.31973446],
                            [39.81824804, -5.31979909],
                            [39.81840959, -5.319756],
                            [39.8185819, -5.31969138],
                            [39.81867883, -5.31961598],
                            [39.81871114, -5.3194975],
                            [39.81873268, -5.31941133],
                            [39.81859925, -5.31928743],
                            [39.81846457, -5.31916238],
                            [39.81826958, -5.31898132],
                            [39.81825881, -5.31884046],
                            [39.81828035, -5.31871121],
                            [39.81832343, -5.31864658],
                            [39.81870037, -5.31850656],
                            [39.81881884, -5.31842039],
                            [39.81887269, -5.31828037],
                            [39.81864653, -5.31806495],
                            [39.81850266, -5.31793526],
                            [39.81831266, -5.3178603],
                            [39.81820496, -5.31761256],
                            [39.81823727, -5.31742945],
                            [39.8183342, -5.31726789],
                            [39.8184419, -5.31716018],
                            [39.81849575, -5.31706324],
                            [39.81828035, -5.3169663],
                            [39.81810609, -5.31681209],
                            [39.81783879, -5.31677242],
                            [39.81766389, -5.31671411],
                            [39.81761262, -5.31669702],
                            [39.81733261, -5.31678319],
                            [39.81717963, -5.31664561],
                            [39.81711721, -5.31649237],
                            [39.8170849, -5.31635235],
                            [39.81705259, -5.31609384],
                            [39.81714952, -5.31587842],
                            [39.81727876, -5.31584611],
                            [39.81736492, -5.31575994],
                            [39.81742954, -5.31567377],
                            [39.81762339, -5.31572763],
                            [39.8177634, -5.3159215],
                            [39.81781725, -5.3159969],
                            [39.81788144, -5.3159755],
                            [39.81801111, -5.31593228],
                            [39.81811881, -5.31565223],
                            [39.81815112, -5.31555529],
                            [39.81850652, -5.31551221],
                            [39.81857114, -5.31538295],
                            [39.81857114, -5.31513522],
                            [39.81828801, -5.31489735],
                            [39.81801902, -5.31467136],
                            [39.81786033, -5.31467206],
                            [39.81786033, -5.31433816],
                            [39.8180865, -5.31419813],
                            [39.81821574, -5.31406888],
                            [39.81831267, -5.31381038],
                            [39.81834498, -5.31368112],
                            [39.81845268, -5.31358418],
                            [39.8185496, -5.31326105],
                            [39.81834498, -5.31295946],
                            [39.8181942, -5.31273327],
                            [39.8180639, -5.31262967],
                            [39.81801111, -5.31248554],
                            [39.81802188, -5.31204392],
                            [39.81806496, -5.31181773],
                            [39.81816189, -5.3114192],
                            [39.81825882, -5.31117147],
                            [39.81839883, -5.31083756],
                            [39.81845268, -5.31053597],
                            [39.81853884, -5.31027747],
                            [39.81832243, -5.31011807],
                            [39.81801112, -5.30980354],
                            [39.81758188, -5.30948817],
                            [39.81740982, -5.30936175],
                            [39.81737703, -5.30933766],
                            [39.81710645, -5.30889877],
                            [39.81702029, -5.3084033],
                            [39.81702299, -5.30810575],
                            [39.81707683, -5.308235],
                            [39.81722761, -5.3087197],
                            [39.81733531, -5.3090536],
                            [39.8174284, -5.30920101],
                            [39.81746455, -5.30925825],
                            [39.81765841, -5.3093552],
                            [39.81786303, -5.30943059],
                            [39.8181969, -5.30955985],
                            [39.81842306, -5.30966756],
                            [39.8184656, -5.30970423],
                            [39.81873539, -5.30993683],
                            [39.81884309, -5.31014148],
                            [39.81894002, -5.3104323],
                            [39.81893401, -5.31044882],
                            [39.81885386, -5.31066927],
                            [39.81896155, -5.31089546],
                            [39.81899386, -5.31098163],
                            [39.81906925, -5.31124013],
                            [39.81899386, -5.31156326],
                            [39.81900463, -5.31184331],
                            [39.81902617, -5.3120695],
                            [39.81910156, -5.31231724],
                            [39.81928465, -5.31248958],
                            [39.81940312, -5.3126296],
                            [39.81957543, -5.31271577],
                            [39.8196939, -5.31276962],
                            [39.81985545, -5.31280194],
                            [39.82003854, -5.31285579],
                            [39.82018931, -5.31288811],
                            [39.82045856, -5.31307122],
                            [39.82074935, -5.31321124],
                            [39.82091089, -5.31324355],
                            [39.82102936, -5.31314661],
                            [39.82108321, -5.31283425],
                            [39.82113706, -5.31266192],
                            [39.82122322, -5.31250035],
                            [39.8212663, -5.3123711],
                            [39.82128784, -5.31223107],
                            [39.82132015, -5.31212336],
                            [39.82135246, -5.31204797],
                            [39.82152478, -5.31153096],
                            [39.82172941, -5.31112166],
                            [39.82175095, -5.31086315],
                            [39.82185865, -5.31043231],
                            [39.82181557, -5.31016303],
                            [39.82174018, -5.31000146],
                            [39.82178326, -5.30973219],
                            [39.82175095, -5.30933366],
                            [39.82172941, -5.3090967],
                            [39.8218048, -5.30885973],
                            [39.82200943, -5.30855814],
                            [39.82217097, -5.30839658],
                            [39.82224636, -5.30828887],
                            [39.82221405, -5.30803036],
                            [39.82223559, -5.30790111],
                            [39.82224636, -5.30783648],
                            [39.82224636, -5.30772877],
                            [39.82221405, -5.30757798],
                            [39.82240791, -5.30745949],
                            [39.82235406, -5.30728716],
                            [39.82233252, -5.30709328],
                            [39.82233252, -5.30698557],
                            [39.82235406, -5.30668398],
                            [39.82240791, -5.30647933],
                            [39.82251561, -5.30635008],
                            [39.82251561, -5.30623159],
                            [39.82242945, -5.30602694],
                            [39.82242945, -5.30590846],
                            [39.82262331, -5.30593],
                            [39.82274178, -5.30583307],
                            [39.82276332, -5.30568227],
                            [39.82273101, -5.30553148],
                            [39.82281717, -5.30547762],
                            [39.82294641, -5.30546685],
                            [39.82307565, -5.30535914],
                            [39.82322642, -5.30514372],
                            [39.82333412, -5.30496061],
                            [39.82336643, -5.30488521],
                            [39.82344182, -5.30482059],
                            [39.8234849, -5.30479904],
                            [39.82363568, -5.30476673],
                            [39.82376492, -5.30466979],
                            [39.82392646, -5.30442206],
                            [39.82399108, -5.30407738],
                            [39.82408801, -5.30401276],
                            [39.8241634, -5.30384042],
                            [39.82419571, -5.30361423],
                            [39.82421725, -5.30345266],
                            [39.82431418, -5.30323724],
                            [39.82435726, -5.30305413],
                            [39.82445419, -5.30283871],
                            [39.82457266, -5.30260175],
                            [39.82468036, -5.30240787],
                            [39.8248096, -5.30209551],
                            [39.82477729, -5.301837],
                            [39.82497114, -5.30166467],
                            [39.82503576, -5.30164313],
                            [39.82518654, -5.30164313],
                            [39.82547733, -5.30175084],
                            [39.82563887, -5.30162158],
                            [39.82577888, -5.30143848],
                            [39.82578965, -5.30141693],
                            [39.82569272, -5.30122305],
                            [39.82553118, -5.30108303],
                            [39.82534809, -5.30071681],
                            [39.82530501, -5.30058756],
                            [39.82543425, -5.30029674],
                            [39.82562811, -5.29962894],
                            [39.82568196, -5.2994566],
                            [39.82602659, -5.2990473],
                            [39.82631738, -5.29885342],
                            [39.82635507, -5.29879418],
                            [39.82639277, -5.29873494],
                            [39.82661894, -5.29847644],
                            [39.82668356, -5.29827179],
                            [39.82683433, -5.29819639],
                            [39.82689895, -5.29804559],
                            [39.82689895, -5.29785171],
                            [39.82689895, -5.29772246],
                            [39.82687741, -5.29765783],
                            [39.82667279, -5.29749627],
                            [39.82657586, -5.29737779],
                            [39.82657586, -5.29730239],
                            [39.82665125, -5.2970008],
                            [39.82673741, -5.29687155],
                            [39.82679126, -5.29672075],
                            [39.8268451, -5.29663458],
                            [39.8271682, -5.29664535],
                            [39.8272759, -5.29668844],
                            [39.82735129, -5.29673152],
                            [39.82742668, -5.2966669],
                            [39.82726513, -5.29644071],
                            [39.82715743, -5.29612834],
                            [39.82728667, -5.29595601],
                            [39.82732975, -5.29572981],
                            [39.82735129, -5.29556825],
                            [39.82756669, -5.29539591],
                            [39.827599, -5.29518049],
                            [39.82773901, -5.29490044],
                            [39.82779285, -5.29455577],
                            [39.82787901, -5.29441575],
                            [39.82796517, -5.29428649],
                            [39.82802979, -5.29409261],
                            [39.82788978, -5.2939849],
                            [39.82767439, -5.29386642],
                            [39.82721128, -5.29348943],
                            [39.82725436, -5.29327401],
                            [39.82732975, -5.29308013],
                            [39.82750207, -5.2928324],
                            [39.8277067, -5.29277854],
                            [39.82788979, -5.29281086],
                            [39.82819134, -5.29281086],
                            [39.82835289, -5.29271392],
                            [39.82841751, -5.29260621],
                            [39.82830981, -5.2924231],
                            [39.82809441, -5.29231539],
                            [39.82787902, -5.2922723],
                            [39.82772824, -5.29222922],
                            [39.82763131, -5.29214305],
                            [39.82740514, -5.29174452],
                            [39.82739438, -5.2915291],
                            [39.82737284, -5.29141062],
                            [39.827599, -5.29129214],
                            [39.82788979, -5.29113057],
                            [39.82807288, -5.29103363],
                            [39.82807288, -5.29077513],
                            [39.82815904, -5.29065665],
                            [39.82847136, -5.29040891],
                            [39.8286006, -5.29032274],
                            [39.82871907, -5.29024735],
                            [39.82878369, -5.29010732],
                            [39.82880523, -5.28997807],
                            [39.82868676, -5.28966571],
                            [39.82862214, -5.28934258],
                            [39.8286006, -5.28919178],
                            [39.8287083, -5.28877171],
                            [39.82876215, -5.28856706],
                            [39.82889139, -5.28843781],
                            [39.8290314, -5.28835164],
                            [39.82930064, -5.28821162],
                            [39.82946219, -5.2881793],
                            [39.8296022, -5.28812545],
                            [39.82966682, -5.2879962],
                            [39.82972067, -5.28785617],
                            [39.82982837, -5.28746841],
                            [39.82989299, -5.28726376],
                            [39.82995761, -5.28705911],
                            [39.82995761, -5.28680061],
                            [39.83001146, -5.28657442],
                            [39.83004377, -5.28632668],
                            [39.83015146, -5.28588507],
                            [39.83034532, -5.28570196],
                            [39.8303884, -5.28541114],
                            [39.83040994, -5.28521726],
                            [39.83047456, -5.28508801],
                            [39.83066842, -5.28484028],
                            [39.83077612, -5.28447406],
                            [39.83085151, -5.28432326],
                            [39.83103459, -5.28410784],
                            [39.83098075, -5.28388165],
                            [39.83098075, -5.28378471],
                            [39.83131461, -5.28349389],
                            [39.83142231, -5.28328924],
                            [39.83154078, -5.28296611],
                            [39.83156232, -5.28257835],
                            [39.83167002, -5.28224445],
                            [39.83170233, -5.28193209],
                            [39.83159463, -5.28182438],
                            [39.83168079, -5.28159819],
                            [39.83184234, -5.28141508],
                            [39.83196081, -5.28125351],
                            [39.83215466, -5.28085498],
                            [39.83213312, -5.28060725],
                            [39.83211159, -5.28046723],
                            [39.8322839, -5.28024103],
                            [39.83242391, -5.27996099],
                            [39.83252084, -5.27977788],
                            [39.83265008, -5.27960554],
                            [39.83283317, -5.27937935],
                            [39.83281163, -5.2790993],
                            [39.83286548, -5.27888388],
                            [39.83290856, -5.27874386],
                            [39.83316703, -5.27819453],
                            [39.8333932, -5.27780678],
                            [39.83373784, -5.27725745],
                            [39.83373784, -5.27695586],
                            [39.83389939, -5.27678353],
                            [39.83400708, -5.27654656],
                            [39.83410401, -5.27636345],
                            [39.83426556, -5.27622343],
                            [39.83443788, -5.27583567],
                            [39.83447019, -5.27546946],
                            [39.8345025, -5.27526481],
                            [39.83438403, -5.27506016],
                            [39.83441634, -5.27480165],
                            [39.83431941, -5.27454314],
                            [39.83433018, -5.27420924],
                            [39.83433018, -5.27398305],
                            [39.83430864, -5.2737784],
                            [39.8342871, -5.27350912],
                            [39.83438403, -5.27342295],
                            [39.83423326, -5.27323985],
                            [39.83400709, -5.27308905],
                            [39.83380246, -5.27307828],
                            [39.83371707, -5.27307015],
                            [39.8335763, -5.27305674],
                            [39.83344706, -5.27294903],
                            [39.83323166, -5.27281977],
                            [39.83301626, -5.27264744],
                            [39.83290857, -5.2724751],
                            [39.83286011, -5.27234357],
                            [39.83283318, -5.27227045],
                            [39.83276856, -5.27207657],
                            [39.83269317, -5.27196886],
                            [39.83262855, -5.27194732],
                            [39.83241315, -5.27195809],
                            [39.83226238, -5.27195809],
                            [39.83206852, -5.27200117],
                            [39.83196082, -5.27204425],
                            [39.8316808, -5.27203348],
                            [39.83156234, -5.27189346],
                            [39.83128232, -5.27189346],
                            [39.8312177, -5.27197963],
                            [39.83109923, -5.2718396],
                            [39.83094845, -5.27174266],
                            [39.83079768, -5.27172112],
                            [39.83067921, -5.27162418],
                            [39.83049612, -5.27153801],
                            [39.83039919, -5.27152724],
                            [39.8302161, -5.2715811],
                            [39.8299684, -5.27163495],
                            [39.82978531, -5.27165649],
                            [39.82930067, -5.2718396],
                            [39.82905296, -5.27195808],
                            [39.82878371, -5.27218427],
                            [39.82865447, -5.27229198],
                            [39.82851447, -5.27244278],
                            [39.82848216, -5.27253972],
                            [39.82833138, -5.27285208],
                            [39.82830984, -5.27303519],
                            [39.82827264, -5.27316912],
                            [39.82825599, -5.27322907],
                            [39.82823445, -5.27343372],
                            [39.82814829, -5.27367068],
                            [39.82801905, -5.2738861],
                            [39.82799751, -5.27401535],
                            [39.82793289, -5.27411229],
                            [39.82781442, -5.27412306],
                            [39.82773904, -5.27410152],
                            [39.8275021, -5.27413383],
                            [39.82741594, -5.27420923],
                            [39.827179, -5.27444619],
                            [39.82714669, -5.27465085],
                            [39.82715746, -5.27489858],
                            [39.827179, -5.275114],
                            [39.82706053, -5.27524325],
                            [39.82684514, -5.27528634],
                            [39.82674821, -5.27539405],
                            [39.82683437, -5.27565255],
                            [39.82675898, -5.27577104],
                            [39.82666205, -5.27581412],
                            [39.82644665, -5.27594337],
                            [39.82615586, -5.276008],
                            [39.82599432, -5.27604031],
                            [39.82575738, -5.27623419],
                            [39.82555275, -5.27633113],
                            [39.82541274, -5.27633113],
                            [39.8252512, -5.27629881],
                            [39.82484194, -5.27620187],
                            [39.82465886, -5.27618033],
                            [39.82451885, -5.27625573],
                            [39.8243573, -5.27639575],
                            [39.8242496, -5.27640652],
                            [39.82416344, -5.27623419],
                            [39.82395881, -5.27612648],
                            [39.82385112, -5.27604031],
                            [39.82377573, -5.2759326],
                            [39.82314031, -5.27542636],
                            [39.82265566, -5.27540481],
                            [39.82236488, -5.2754479],
                            [39.82214948, -5.27550175],
                            [39.821891, -5.27555561],
                            [39.82166484, -5.275631],
                            [39.82133097, -5.27586797],
                            [39.8213202, -5.27595414],
                            [39.82127712, -5.27601876],
                            [39.8210725, -5.27602953],
                            [39.82095403, -5.27593259],
                            [39.82062016, -5.27576026],
                            [39.82037246, -5.27573871],
                            [39.82010321, -5.27568486],
                            [39.81986627, -5.27566331],
                            [39.81961857, -5.27572794],
                            [39.8190693, -5.27594336],
                            [39.81878929, -5.27607261],
                            [39.81877852, -5.27621264],
                            [39.81854158, -5.27622341],
                            [39.81816464, -5.27616955],
                            [39.81787385, -5.27615878],
                            [39.81762614, -5.27618032],
                            [39.81751844, -5.27623418],
                            [39.81730305, -5.27634189],
                            [39.81665686, -5.27634189],
                            [39.81622606, -5.27638497],
                            [39.8160322, -5.27638497],
                            [39.81560141, -5.27632034],
                            [39.81531063, -5.27630957],
                            [39.81493368, -5.27620186],
                            [39.81487983, -5.27584372],
                            [39.81489599, -5.27590027],
                            [39.81498753, -5.27598105],
                            [39.81510061, -5.27602952],
                            [39.81520831, -5.27609415],
                            [39.81534832, -5.27610492],
                            [39.81545602, -5.27606722],
                            [39.81556372, -5.27605645],
                            [39.81569296, -5.27602952],
                            [39.81573065, -5.27602414],
                            [39.81586527, -5.27607261],
                            [39.81610759, -5.27606722],
                            [39.81661378, -5.27606184],
                            [39.81687225, -5.27600798],
                            [39.81704457, -5.27592182],
                            [39.81720612, -5.27580872],
                            [39.81732459, -5.27573871],
                            [39.81746998, -5.27568485],
                            [39.81758306, -5.27567408],
                            [39.81779846, -5.27557714],
                            [39.81808386, -5.27539942],
                            [39.81840696, -5.27530248],
                            [39.81869774, -5.27523786],
                            [39.81923624, -5.27512476],
                            [39.81959164, -5.27502782],
                            [39.81987166, -5.27503321],
                            [39.82023783, -5.27503321],
                            [39.82051246, -5.27509245],
                            [39.82079787, -5.27516785],
                            [39.82126635, -5.27517862],
                            [39.82169715, -5.27498474],
                            [39.82206332, -5.27476932],
                            [39.82229488, -5.27461314],
                            [39.8224295, -5.2746293],
                            [39.82254258, -5.2746293],
                            [39.82266105, -5.27454851],
                            [39.82277952, -5.27447312],
                            [39.82293568, -5.27451082],
                            [39.823178, -5.27464545],
                            [39.82356033, -5.27486088],
                            [39.82379727, -5.27489857],
                            [39.82386189, -5.27499013],
                            [39.82428191, -5.27523248],
                            [39.82466963, -5.27530249],
                            [39.82493349, -5.27529711],
                            [39.82506811, -5.27535096],
                            [39.82519196, -5.27537789],
                            [39.82527812, -5.27529172],
                            [39.82529428, -5.27517324],
                            [39.82556891, -5.27478548],
                            [39.82605894, -5.27424693],
                            [39.82619625, -5.27405574],
                            [39.82633357, -5.27386456],
                            [39.82642389, -5.2735233],
                            [39.8264305, -5.27349834],
                            [39.82666205, -5.2730675],
                            [39.82681283, -5.27286823],
                            [39.82688283, -5.27258819],
                            [39.82695284, -5.27249125],
                            [39.82707131, -5.27233507],
                            [39.82724901, -5.27202271],
                            [39.82723286, -5.27185037],
                            [39.82728132, -5.27173189],
                            [39.82735132, -5.27158109],
                            [39.82745364, -5.27138721],
                            [39.82755057, -5.27126335],
                            [39.82769057, -5.27125258],
                            [39.82790059, -5.27106947],
                            [39.82792213, -5.27084328],
                            [39.82799213, -5.2706871],
                            [39.82807291, -5.270574],
                            [39.8281106, -5.2704232],
                            [39.82816983, -5.27032626],
                            [39.82832061, -5.27025087],
                            [39.82835292, -5.2700516],
                            [39.82838523, -5.26990619],
                            [39.82851447, -5.26988465],
                            [39.82865986, -5.26987927],
                            [39.82875679, -5.26987927],
                            [39.82903142, -5.26969077],
                            [39.82914451, -5.26955614],
                            [39.82926836, -5.26941073],
                            [39.82933836, -5.26928686],
                            [39.82937606, -5.26916299],
                            [39.82928452, -5.26891526],
                            [39.82912835, -5.26890449],
                            [39.82905835, -5.26888833],
                            [39.82902065, -5.26885602],
                            [39.82916066, -5.26881293],
                            [39.82933298, -5.26870522],
                            [39.82938683, -5.26866214],
                            [39.82951068, -5.26864598],
                            [39.82965608, -5.26871061],
                            [39.82973685, -5.26878601],
                            [39.82983916, -5.26883448],
                            [39.82997379, -5.2688237],
                            [39.83000071, -5.26878062],
                            [39.83002764, -5.2686406],
                            [39.82998456, -5.2685275],
                            [39.8298984, -5.26846287],
                            [39.8298607, -5.26840363],
                            [39.82983378, -5.26830669],
                            [39.82982301, -5.26809127],
                            [39.82987147, -5.26795663],
                            [39.82984993, -5.26781661],
                            [39.82983916, -5.2677466],
                            [39.82995763, -5.26760119],
                            [39.8300061, -5.26750963],
                            [39.83003302, -5.26738577],
                            [39.83006533, -5.26728883],
                            [39.83017842, -5.26715958],
                            [39.83026996, -5.26708418],
                            [39.83034535, -5.2671865],
                            [39.83039381, -5.26731037],
                            [39.83044766, -5.26744501],
                            [39.83052844, -5.26746117],
                            [39.83063075, -5.26747194],
                            [39.83077076, -5.26748809],
                            [39.83092154, -5.26747732],
                            [39.83096461, -5.26735884],
                            [39.83100769, -5.2672996],
                            [39.83114232, -5.26726729],
                            [39.83121232, -5.26725113],
                            [39.83126617, -5.26724036],
                            [39.83134694, -5.26744501],
                            [39.83137387, -5.26761735],
                            [39.83128232, -5.26780584],
                            [39.83115847, -5.26794586],
                            [39.83105616, -5.26816129],
                            [39.83097, -5.26833362],
                            [39.83095923, -5.26844672],
                            [39.83098077, -5.26865137],
                            [39.83115847, -5.26883986],
                            [39.83132002, -5.26902836],
                            [39.83141156, -5.26909298],
                            [39.83157311, -5.26909837],
                            [39.83165388, -5.26913607],
                            [39.83156234, -5.26925455],
                            [39.8315408, -5.26936765],
                            [39.8316108, -5.2695723],
                            [39.83188005, -5.26981464],
                            [39.8320416, -5.26996005],
                            [39.83209545, -5.27007854],
                            [39.83212237, -5.27024549],
                            [39.83215468, -5.27031011],
                            [39.83208467, -5.27036397],
                            [39.83223545, -5.27043937],
                            [39.83238085, -5.27047707],
                            [39.83253701, -5.27054169],
                            [39.83266625, -5.27059555],
                            [39.83275241, -5.27068172],
                            [39.83303242, -5.27085405],
                            [39.83326397, -5.27097792],
                            [39.83345244, -5.2710587],
                            [39.83365707, -5.27108025],
                            [39.83378093, -5.27112333],
                            [39.83396401, -5.27122566],
                            [39.8341471, -5.27133337],
                            [39.83428172, -5.27148955],
                            [39.83442712, -5.27165111],
                            [39.83451327, -5.27176421],
                            [39.83469636, -5.27188808],
                            [39.83477714, -5.27200117],
                            [39.83487945, -5.27214658],
                            [39.83497099, -5.27222737],
                            [39.83505715, -5.27226507],
                            [39.83513254, -5.27222198],
                            [39.83519716, -5.27219505],
                            [39.83529947, -5.27216813],
                            [39.83536948, -5.27212504],
                            [39.8353964, -5.27202272],
                            [39.83534255, -5.27189885],
                            [39.83531024, -5.27184499],
                            [39.83529947, -5.27174806],
                            [39.83529947, -5.27171574],
                            [39.83533717, -5.27167266],
                            [39.83538025, -5.27138722],
                            [39.8352887, -5.27116642],
                            [39.83518639, -5.27095638],
                            [39.835111, -5.27084329],
                            [39.83517562, -5.27062786],
                            [39.83513793, -5.27054708],
                            [39.83517562, -5.27045014],
                            [39.83522947, -5.27038552],
                            [39.83527794, -5.27034782],
                            [39.83538563, -5.27018625],
                            [39.83535871, -5.27005161],
                            [39.83538563, -5.26997622],
                            [39.83550949, -5.26992236],
                            [39.83560103, -5.26985235],
                            [39.83566565, -5.26975002],
                            [39.83563873, -5.2694969],
                            [39.83556334, -5.26936227],
                            [39.83548795, -5.26925455],
                            [39.83546641, -5.26921147],
                            [39.8355418, -5.26896374],
                            [39.83554718, -5.26885603],
                            [39.83556334, -5.26880217],
                            [39.83576797, -5.26880217],
                            [39.83586489, -5.26872677],
                            [39.83591336, -5.26862445],
                            [39.83594567, -5.26855444],
                            [39.83597798, -5.26848981],
                            [39.83590797, -5.26830132],
                            [39.83587028, -5.26806974],
                            [39.83574643, -5.26794049],
                            [39.83560103, -5.26791356],
                            [39.83552565, -5.26798895],
                            [39.83549334, -5.26809667],
                            [39.83548795, -5.26816668],
                            [39.83541256, -5.26839287],
                            [39.83535333, -5.26852212],
                            [39.83529948, -5.2686406],
                            [39.83524377, -5.26865438],
                            [39.83515947, -5.26877524],
                            [39.83516485, -5.26895296],
                            [39.83513254, -5.26902836],
                            [39.83504639, -5.26910914],
                            [39.83498715, -5.2692007],
                            [39.83489022, -5.26930841],
                            [39.83476194, -5.26933705],
                            [39.8345989, -5.26937346],
                            [39.83449174, -5.26943766],
                            [39.83444954, -5.26944701],
                            [39.83419557, -5.2694215],
                            [39.83403402, -5.26939458],
                            [39.83386159, -5.26943387],
                            [39.83376595, -5.26945567],
                            [39.83358169, -5.26938919],
                            [39.83333398, -5.26931379],
                            [39.8331832, -5.26927071],
                            [39.83305396, -5.26921685],
                            [39.83294626, -5.2691253],
                            [39.83284395, -5.26896912],
                            [39.83274164, -5.26883448],
                            [39.83273087, -5.26866753],
                            [39.83269317, -5.26857059],
                            [39.83270933, -5.2683067],
                            [39.83255317, -5.26822591],
                            [39.83244547, -5.2681559],
                            [39.83240777, -5.26806435],
                            [39.83242393, -5.26799434],
                            [39.83248316, -5.26786508],
                            [39.83253163, -5.26779507],
                            [39.83254778, -5.26769275],
                            [39.83238623, -5.26763351],
                            [39.83229469, -5.26756888],
                            [39.83220853, -5.26747732],
                            [39.83217084, -5.2674127],
                            [39.83212776, -5.26726729],
                            [39.83202545, -5.26717035],
                            [39.83199314, -5.26709495],
                            [39.83207391, -5.26696031],
                            [39.83215468, -5.26684722],
                            [39.83203622, -5.26672335],
                            [39.83202545, -5.2666318],
                            [39.83207391, -5.26656178],
                            [39.83209007, -5.26646484],
                            [39.83208605, -5.26646127],
                            [39.83199314, -5.26637868],
                            [39.83193929, -5.26633559],
                            [39.83184236, -5.26616325],
                            [39.83179928, -5.26602862],
                            [39.83178851, -5.26592091],
                            [39.83172928, -5.2657755],
                            [39.83162158, -5.26547391],
                            [39.83159259, -5.26536623],
                            [39.83158388, -5.26533388],
                            [39.83155696, -5.26522617],
                            [39.83162696, -5.26508076],
                            [39.83166466, -5.26498921],
                            [39.83172928, -5.26492997],
                            [39.83178851, -5.26478994],
                            [39.83185852, -5.26447758],
                            [39.83179928, -5.26431063],
                            [39.83178313, -5.26420292],
                            [39.83184236, -5.26408982],
                            [39.83187467, -5.26382593],
                            [39.83187467, -5.26363744],
                            [39.8317939, -5.26354589],
                            [39.83172928, -5.26349742],
                            [39.83172928, -5.26342202],
                            [39.83172928, -5.26334662],
                            [39.83173467, -5.26323353],
                            [39.83168082, -5.26315813],
                            [39.83170774, -5.26299656],
                            [39.83180467, -5.26285115],
                            [39.83195006, -5.26274883],
                            [39.83202545, -5.26265189],
                            [39.83202545, -5.26243647],
                            [39.83204161, -5.26223182],
                            [39.8320093, -5.26205409],
                            [39.83194468, -5.261941],
                            [39.83181006, -5.26183867],
                            [39.83169697, -5.26181713],
                            [39.83164803, -5.26184765],
                            [39.8315085, -5.26181713],
                            [39.83141157, -5.26174712],
                            [39.83128088, -5.26183022],
                            [39.83122414, -5.2618663],
                            [39.83110034, -5.26194503],
                            [39.83109386, -5.26205409],
                            [39.83110463, -5.26212949],
                            [39.83107751, -5.26214648],
                            [39.83094847, -5.26218335],
                            [39.83083538, -5.26219412],
                            [39.83074923, -5.2621995],
                            [39.83071692, -5.26226951],
                            [39.8307223, -5.26232875],
                            [39.8307223, -5.26242031],
                            [39.83067384, -5.26258726],
                            [39.83051707, -5.26268396],
                            [39.83046382, -5.26281884],
                            [39.83031843, -5.2630181],
                            [39.8302215, -5.2631312],
                            [39.8301515, -5.26322275],
                            [39.83010319, -5.26325175],
                            [39.83003303, -5.26336278],
                            [39.82989841, -5.26329815],
                            [39.82980686, -5.26322814],
                            [39.8297207, -5.26314735],
                            [39.82963993, -5.26306119],
                            [39.82969378, -5.26296425],
                            [39.82984994, -5.26278114],
                            [39.82994149, -5.26261957],
                            [39.82997918, -5.26244185],
                            [39.82994149, -5.26237184],
                            [39.82990918, -5.26233952],
                            [39.82985533, -5.26228567],
                            [39.82984456, -5.2621995],
                            [39.82983917, -5.26211333],
                            [39.82979071, -5.26196792],
                            [39.8297207, -5.2619033],
                            [39.82962916, -5.2618279],
                            [39.82952685, -5.26174173],
                            [39.8294266, -5.26168324],
                            [39.82939761, -5.26166633],
                            [39.82926299, -5.26154785],
                            [39.82913913, -5.26147784],
                            [39.82904759, -5.26144553],
                            [39.82894528, -5.26139167],
                            [39.82885373, -5.26134859],
                            [39.82879352, -5.26131596],
                            [39.82872449, -5.26127857],
                            [39.82835294, -5.26098237],
                            [39.82828293, -5.26076695],
                            [39.82820216, -5.26065924],
                            [39.82821293, -5.26050844],
                            [39.82821831, -5.26032533],
                            [39.82815369, -5.25992142],
                            [39.82815369, -5.25971138],
                            [39.82820459, -5.25923012],
                            [39.82821293, -5.25915129],
                            [39.82812677, -5.25902203],
                            [39.82795445, -5.25891971],
                            [39.82778214, -5.25879584],
                            [39.82767982, -5.25870429],
                            [39.82767012, -5.25868308],
                            [39.82753443, -5.25838654],
                            [39.82750751, -5.25804725],
                            [39.82746712, -5.25796108],
                            [39.82742673, -5.25787492],
                            [39.82735134, -5.25774028],
                            [39.82729211, -5.25769719],
                            [39.82721949, -5.25763818],
                            [39.82711979, -5.25755717],
                            [39.82694209, -5.25746023],
                            [39.8265113, -5.25705093],
                            [39.82636052, -5.25692167],
                            [39.8263659, -5.25658777],
                            [39.82630129, -5.25629157],
                            [39.82627436, -5.25591997],
                            [39.82626898, -5.25579071],
                            [39.82630129, -5.25574224],
                            [39.82634437, -5.25563992],
                            [39.8262959, -5.25555375],
                            [39.82619897, -5.25545681],
                            [39.82614913, -5.25541149],
                            [39.82613974, -5.25540295],
                            [39.82615051, -5.25513368],
                            [39.82608589, -5.25486979],
                            [39.8260105, -5.25457358],
                            [39.82582741, -5.2543797],
                            [39.82571972, -5.25423429],
                            [39.82561202, -5.25415351],
                            [39.82552659, -5.25427472],
                            [39.82542893, -5.25431508],
                            [39.82532662, -5.25430969],
                            [39.82532123, -5.25417505],
                            [39.8253697, -5.25405119],
                            [39.82541816, -5.25397579],
                            [39.82545047, -5.25386269],
                            [39.82534816, -5.25364727],
                            [39.825262, -5.25346955],
                            [39.82519738, -5.25314642],
                            [39.82512199, -5.25299562],
                            [39.82499275, -5.25286098],
                            [39.82473966, -5.25251092],
                            [39.82462658, -5.25240321],
                            [39.82456196, -5.25233859],
                            [39.82454581, -5.25220395],
                            [39.82445426, -5.25208547],
                            [39.82418502, -5.25173002],
                            [39.82401808, -5.25144997],
                            [39.823905, -5.25128302],
                            [39.82382423, -5.25111068],
                            [39.82360345, -5.25090065],
                            [39.82344728, -5.25080909],
                            [39.82325881, -5.250696],
                            [39.8231565, -5.25064214],
                            [39.82293785, -5.2505158],
                            [39.82291418, -5.25050212],
                            [39.82274724, -5.25049135],
                            [39.82261262, -5.25042672],
                            [39.82253723, -5.25033516],
                            [39.82243492, -5.25025438],
                            [39.82238107, -5.2501736],
                            [39.82232183, -5.25002819],
                            [39.82228414, -5.24977507],
                            [39.82225722, -5.24962966],
                            [39.82234338, -5.249355],
                            [39.82240261, -5.24917189],
                            [39.82258031, -5.24885953],
                            [39.82260137, -5.24877982],
                            [39.8226557, -5.2485741],
                            [39.82279571, -5.24826174],
                            [39.8228711, -5.24813787],
                            [39.82279571, -5.24803016],
                            [39.82259108, -5.24787936],
                            [39.82239184, -5.24780396],
                            [39.82214952, -5.2477178],
                            [39.82195566, -5.24764778],
                            [39.82177258, -5.247567],
                            [39.82164872, -5.24752392],
                            [39.82152487, -5.24746468],
                            [39.82127178, -5.24724925],
                            [39.82109408, -5.24699075],
                            [39.82101869, -5.24682918],
                            [39.82101869, -5.24674301],
                            [39.82106715, -5.24647374],
                            [39.82111562, -5.24627986],
                            [39.82134717, -5.24599442],
                            [39.82147102, -5.24580055],
                            [39.82161642, -5.24555281],
                            [39.8216218, -5.24530508],
                            [39.82162719, -5.2451489],
                            [39.82151949, -5.24504657],
                            [39.82125563, -5.24487423],
                            [39.82107792, -5.24470728],
                            [39.82091638, -5.2445511],
                            [39.82072791, -5.24426567],
                            [39.82056097, -5.24402332],
                            [39.82040481, -5.24371634],
                            [39.82027019, -5.24355478],
                            [39.82011889, -5.24337957],
                            [39.82006556, -5.24331781],
                            [39.81993094, -5.24317779],
                            [39.81987709, -5.24305931],
                            [39.82019227, -5.24326945],
                            [39.82029711, -5.24333936],
                            [39.82045866, -5.24359786],
                            [39.82074945, -5.24394254],
                            [39.82098638, -5.24423336],
                            [39.82148179, -5.24467497],
                            [39.82175104, -5.24501964],
                            [39.82180489, -5.24537509],
                            [39.82175104, -5.24568745],
                            [39.82170796, -5.24576823],
                            [39.82166488, -5.24584902],
                            [39.82147102, -5.24621523],
                            [39.82135255, -5.24649528],
                            [39.8212987, -5.24673224],
                            [39.82132024, -5.24696921],
                            [39.82144948, -5.24714154],
                            [39.82166488, -5.24718463],
                            [39.82185874, -5.24720617],
                            [39.82206336, -5.24728157],
                            [39.82244031, -5.24736774],
                            [39.82277417, -5.24748622],
                            [39.82303265, -5.24765856],
                            [39.82311881, -5.24780935],
                            [39.82314035, -5.24799246],
                            [39.82309727, -5.24819711],
                            [39.82302188, -5.24836945],
                            [39.82296803, -5.24872489],
                            [39.82286033, -5.24903725],
                            [39.82283842, -5.24917496],
                            [39.82278494, -5.24951118],
                            [39.82274186, -5.24981277],
                            [39.82282802, -5.25008205],
                            [39.82293572, -5.25024361],
                            [39.8231188, -5.25032978],
                            [39.82330105, -5.25036623],
                            [39.8233342, -5.25037286],
                            [39.82351729, -5.25042672],
                            [39.82408809, -5.25080371],
                            [39.82431426, -5.25099759],
                            [39.82472351, -5.25144997],
                            [39.82502506, -5.25177311],
                            [39.82521892, -5.25195621],
                            [39.82552048, -5.2522578],
                            [39.82587047, -5.25257283],
                            [39.82595127, -5.25264556],
                            [39.82645745, -5.25341031],
                            [39.82674824, -5.2537119],
                            [39.82690979, -5.25426122],
                            [39.82738366, -5.25471361],
                            [39.82773906, -5.25504751],
                            [39.82790061, -5.2552737],
                            [39.82792215, -5.25563992],
                            [39.8280837, -5.25597382],
                            [39.82837448, -5.25613539],
                            [39.82863296, -5.25623233],
                            [39.82869758, -5.25625387],
                            [39.82881605, -5.25649084],
                            [39.82894528, -5.25653392],
                            [39.82906375, -5.25668472],
                            [39.82908529, -5.25678166],
                            [39.82921453, -5.25733098],
                            [39.82930069, -5.2575464],
                            [39.82951608, -5.25760026],
                            [39.82961301, -5.25758949],
                            [39.82969917, -5.25768643],
                            [39.82986072, -5.2578803],
                            [39.8299038, -5.2579557],
                            [39.83016227, -5.25831115],
                            [39.83031305, -5.25846194],
                            [39.83043152, -5.25860197],
                            [39.8304746, -5.25873122],
                            [39.83052845, -5.25886047],
                            [39.8305823, -5.25891433],
                            [39.83059307, -5.25901127],
                            [39.83062538, -5.25923746],
                            [39.83053922, -5.25947442],
                            [39.83046383, -5.25967907],
                            [39.8304661, -5.25970064],
                            [39.83048537, -5.25988372],
                            [39.83066845, -5.26005606],
                            [39.8310454, -5.26023917],
                            [39.83133491, -5.26037489],
                            [39.83139003, -5.26040074],
                            [39.83168082, -5.26042228],
                            [39.83189622, -5.26046536],
                            [39.83224085, -5.26049768],
                            [39.83254241, -5.26049768],
                            [39.83276857, -5.26045459],
                            [39.83299474, -5.26041151],
                            [39.83316706, -5.26028226],
                            [39.83330707, -5.260153],
                            [39.83346862, -5.26014223],
                            [39.83396403, -5.26017455],
                            [39.8342225, -5.26002375],
                            [39.83434097, -5.25991604],
                            [39.83473946, -5.25952828],
                            [39.83464831, -5.25947169],
                            [39.83435174, -5.25945289],
                            [39.8341902, -5.2591513],
                            [39.8341902, -5.25903281],
                            [39.83435174, -5.25894665],
                            [39.83448098, -5.25898973],
                            [39.83458868, -5.25905436],
                            [39.83473946, -5.25908667],
                            [39.83484716, -5.25908667],
                            [39.83490101, -5.25896819],
                            [39.83506256, -5.25852658],
                            [39.83509487, -5.25822499],
                            [39.8349961, -5.25817017],
                            [39.83494409, -5.25802034],
                            [39.83496563, -5.25774029],
                            [39.83509487, -5.25757872],
                            [39.83524564, -5.25755718],
                            [39.8354395, -5.25760026],
                            [39.83557951, -5.25762181],
                            [39.83578414, -5.25763258],
                            [39.8359026, -5.25763258],
                            [39.83635494, -5.25744947],
                            [39.8364411, -5.25729868],
                            [39.83663496, -5.25707248],
                            [39.83678573, -5.25685706],
                            [39.83700113, -5.25670627],
                            [39.83707652, -5.2565447],
                            [39.83714114, -5.25625388],
                            [39.83716268, -5.25597384],
                            [39.83721653, -5.25569379],
                            [39.83729192, -5.25540297],
                            [39.83739962, -5.25521986],
                            [39.8375504, -5.25503675],
                            [39.83780887, -5.25484288],
                            [39.83787349, -5.25455206],
                            [39.83787349, -5.25432587],
                            [39.83793811, -5.25418584],
                            [39.83808889, -5.25407813],
                            [39.83820736, -5.2540135],
                            [39.83851968, -5.25366883],
                            [39.83859507, -5.25344264],
                            [39.83859507, -5.25323799],
                            [39.83859507, -5.25305488],
                            [39.83860584, -5.25289332],
                            [39.83871354, -5.25274252],
                            [39.83885355, -5.25264558],
                            [39.83898279, -5.2525271],
                            [39.83903664, -5.2524517],
                            [39.83920896, -5.25216088],
                            [39.83932743, -5.25184852],
                            [39.8393382, -5.25165464],
                            [39.83952128, -5.25143922],
                            [39.83973668, -5.25110532],
                            [39.83986592, -5.2508899],
                            [39.84008132, -5.25082527],
                            [39.84021055, -5.25063139],
                            [39.84028594, -5.25053445],
                            [39.84044749, -5.2504052],
                            [39.8405875, -5.25034058],
                            [39.8405875, -5.25018978],
                            [39.84064135, -5.25001744],
                            [39.84066289, -5.2497374],
                            [39.84061981, -5.24962969],
                            [39.84073828, -5.24953275],
                            [39.84078136, -5.24944658],
                            [39.84081367, -5.24908036],
                            [39.84073828, -5.24867106],
                            [39.84071674, -5.24844487],
                            [39.84075982, -5.2482833],
                            [39.84105061, -5.24808942],
                            [39.84106138, -5.24788477],
                            [39.84117985, -5.24772321],
                            [39.84143832, -5.24768012],
                            [39.84156756, -5.24768012],
                            [39.84170757, -5.24762627],
                            [39.84173988, -5.24744316],
                            [39.84172911, -5.24729237],
                            [39.84179373, -5.24707695],
                            [39.84182604, -5.24693692],
                            [39.84176953, -5.24693397],
                            [39.84166449, -5.24667842],
                            [39.84150294, -5.24626912],
                            [39.84137371, -5.24589213],
                            [39.84120139, -5.2457521],
                            [39.84115831, -5.24554745],
                            [39.84116908, -5.24532126],
                            [39.84119062, -5.24511661],
                            [39.84125524, -5.24483656],
                            [39.84137371, -5.24465346],
                            [39.84148141, -5.24441649],
                            [39.84151372, -5.24421184],
                            [39.84158911, -5.24400719],
                            [39.84168603, -5.2438564],
                            [39.84176142, -5.24379177],
                            [39.84184758, -5.24359789],
                            [39.84173988, -5.24339324],
                            [39.84161065, -5.24323168],
                            [39.84176142, -5.24302703],
                            [39.84186912, -5.24279006],
                            [39.84197682, -5.2425531],
                            [39.84204144, -5.24226228],
                            [39.84203067, -5.241993],
                            [39.8420199, -5.24175604],
                            [39.84190143, -5.24152985],
                            [39.84162052, -5.24156591],
                            [39.84140997, -5.24159294],
                            [39.84120981, -5.24148868],
                            [39.84106139, -5.24141137],
                            [39.84078137, -5.24123903],
                            [39.84052289, -5.24114209],
                            [39.84031827, -5.24102361],
                            [39.84015672, -5.24074356],
                            [39.84014595, -5.24051737],
                            [39.84023211, -5.24037734],
                            [39.84035058, -5.24019423],
                            [39.8404152, -5.24014038],
                            [39.84054444, -5.24010807],
                            [39.84080291, -5.2400973],
                            [39.84100754, -5.24012961],
                            [39.84123371, -5.24015115],
                            [39.84137371, -5.24015115],
                            [39.84138023, -5.24014293],
                            [39.84162142, -5.23983879],
                            [39.84169142, -5.23969338],
                            [39.84176143, -5.23954797],
                            [39.84195529, -5.23931101],
                            [39.84210607, -5.23906328],
                            [39.84238608, -5.23889094],
                            [39.84274149, -5.23884786],
                            [39.8430215, -5.23870783],
                            [39.84329075, -5.23867552],
                            [39.84338768, -5.23843856],
                            [39.84330152, -5.23829853],
                            [39.8431292, -5.23796463],
                            [39.84307535, -5.23784615],
                            [39.8428543, -5.23789961],
                            [39.84266165, -5.23794621],
                            [39.84252478, -5.23797932],
                            [39.84248588, -5.23798873],
                            [39.84240762, -5.23769535],
                            [39.84248301, -5.23743685],
                            [39.84262302, -5.23718911],
                            [39.8427738, -5.23703832],
                            [39.84293535, -5.23693061],
                            [39.84319382, -5.23693061],
                            [39.84331229, -5.23679058],
                            [39.84360308, -5.23648899],
                            [39.84349538, -5.23628434],
                            [39.84326921, -5.23619817],
                            [39.84313997, -5.23603661],
                            [39.84308613, -5.23584273],
                            [39.84310767, -5.23565962],
                            [39.84320459, -5.23553037],
                            [39.84335537, -5.23550883],
                            [39.84358154, -5.23557345],
                            [39.84374309, -5.23565962],
                            [39.84394771, -5.23564885],
                            [39.84407695, -5.23555191],
                            [39.84404464, -5.23531495],
                            [39.84399079, -5.23513184],
                            [39.84379694, -5.2350349],
                            [39.84378078, -5.23492719],
                            [39.84376463, -5.23481948],
                            [39.84389387, -5.23449635],
                            [39.84390464, -5.23428093],
                            [39.84407695, -5.23428093],
                            [39.84422773, -5.23435632],
                            [39.84432466, -5.23444249],
                            [39.84444313, -5.23439941],
                            [39.84449698, -5.23432401],
                            [39.84462622, -5.23428093],
                            [39.84487392, -5.23428093],
                            [39.84470161, -5.23416245],
                            [39.84463699, -5.23409782],
                            [39.84455083, -5.23391471],
                            [39.84454006, -5.23371006],
                            [39.84460468, -5.2335485],
                            [39.84471238, -5.23345156],
                            [39.84488469, -5.23343001],
                            [39.84512163, -5.2331392],
                            [39.8451324, -5.23290223],
                            [39.84522933, -5.23274067],
                            [39.84535857, -5.23260064],
                            [39.84550935, -5.23248216],
                            [39.84562782, -5.2322452],
                            [39.8456709, -5.23205132],
                            [39.84590783, -5.23195438],
                            [39.84600476, -5.2318359],
                            [39.84602899, -5.23181301],
                            [39.84605323, -5.23179012],
                            [39.84610169, -5.23174434],
                            [39.84622016, -5.23144814],
                            [39.84629555, -5.2310927],
                            [39.84629016, -5.23067801],
                            [39.84637094, -5.23052183],
                            [39.84650018, -5.23022563],
                            [39.84655133, -5.2301233],
                            [39.84660249, -5.23002098],
                            [39.84664565, -5.22976254],
                            [39.84677481, -5.22963322],
                            [39.84695251, -5.22935317],
                            [39.84710867, -5.22897618],
                            [39.84711944, -5.2287823],
                            [39.84710329, -5.22872306],
                            [39.84706021, -5.22867998],
                            [39.84699559, -5.22863689],
                            [39.84688251, -5.22863689],
                            [39.84683943, -5.22860458],
                            [39.84679096, -5.22857765],
                            [39.8467425, -5.22858304],
                            [39.8466348, -5.22860997],
                            [39.84653787, -5.22860997],
                            [39.8463871, -5.22860458],
                            [39.84625786, -5.22859381],
                            [39.84613939, -5.22855072],
                            [39.8459563, -5.22850764],
                            [39.84595092, -5.228373],
                            [39.84597784, -5.22820605],
                            [39.8459563, -5.22793139],
                            [39.84592399, -5.22781829],
                            [39.84583847, -5.22777553],
                            [39.84581629, -5.22776444],
                            [39.84568706, -5.22774289],
                            [39.84553628, -5.22771058],
                            [39.84542858, -5.22766211],
                            [39.84531095, -5.22748517],
                            [39.84534242, -5.22730667],
                            [39.84525088, -5.22715048],
                            [39.84515933, -5.22696738],
                            [39.84495471, -5.22691891],
                            [39.84478239, -5.22683274],
                            [39.84466392, -5.22663886],
                            [39.8445502, -5.22638569],
                            [39.84455084, -5.22611108],
                            [39.8445994, -5.22593404],
                            [39.84464797, -5.22575701],
                            [39.84481445, -5.22575428],
                            [39.84533623, -5.22571514],
                            [39.84553674, -5.2257001],
                            [39.84578163, -5.22566465],
                            [39.84690909, -5.22550145],
                            [39.84719801, -5.22541115],
                            [39.84719801, -5.22514026],
                            [39.84717995, -5.22479714],
                            [39.84714384, -5.22449013],
                            [39.84714384, -5.22429147],
                            [39.84712578, -5.22403864],
                            [39.84705355, -5.22367746],
                            [39.84708967, -5.22340657],
                            [39.84712578, -5.22324403],
                            [39.84714384, -5.22282867],
                            [39.84705356, -5.22252166],
                            [39.84705356, -5.22217853],
                            [39.84687298, -5.22194376],
                            [39.84654795, -5.22161869],
                            [39.84649378, -5.2214381],
                            [39.84647572, -5.22113109],
                            [39.84651184, -5.2206796],
                            [39.84672853, -5.2203726],
                            [39.84621472, -5.2203723],
                            [39.84573155, -5.22037202],
                            [39.84620484, -5.22034925],
                            [39.8468459, -5.22031842],
                            [39.84696327, -5.22026424],
                            [39.84705356, -5.22017394],
                            [39.84712579, -5.21995723],
                            [39.84721608, -5.2196141],
                            [39.84725219, -5.21936127],
                            [39.84728831, -5.21880143],
                            [39.84721608, -5.21862084],
                            [39.84689105, -5.21862084],
                            [39.84662019, -5.21838607],
                            [39.84645767, -5.21798876],
                            [39.84639564, -5.21774835],
                            [39.84631321, -5.21742892],
                            [39.84634933, -5.21710385],
                            [39.84644788, -5.21694897],
                            [39.84647573, -5.2169052],
                            [39.84672853, -5.21676073],
                            [39.847505, -5.21686908],
                            [39.8478842, -5.21672461],
                            [39.84798352, -5.21660722],
                            [39.84808283, -5.21648984],
                            [39.8482634, -5.21636342],
                            [39.84862455, -5.21630925],
                            [39.84864261, -5.21611059],
                            [39.84882318, -5.21587582],
                            [39.84893153, -5.21578553],
                            [39.8489857, -5.21560493],
                            [39.84907599, -5.21547852],
                            [39.84925656, -5.21526181],
                            [39.8491121, -5.21508121],
                            [39.84910307, -5.21492771],
                            [39.84909404, -5.2147742],
                            [39.84927462, -5.21455749],
                            [39.84947325, -5.21443108],
                            [39.84958159, -5.21430466],
                            [39.84978022, -5.21405183],
                            [39.84992468, -5.21376288],
                            [39.8500872, -5.21332946],
                            [39.8502136, -5.21316692],
                            [39.85032194, -5.21293215],
                            [39.85037612, -5.21278768],
                            [39.85057475, -5.21242649],
                            [39.85082755, -5.21197501],
                            [39.85084561, -5.2118215],
                            [39.85086366, -5.211668],
                            [39.85095395, -5.21132487],
                            [39.8511887, -5.21092757],
                            [39.85109841, -5.21047608],
                            [39.85118365, -5.21033729],
                            [39.85129704, -5.21036773],
                            [39.85154984, -5.21009684],
                            [39.85164013, -5.21000654],
                            [39.85173042, -5.20950088],
                            [39.85162207, -5.20921193],
                            [39.85184181, -5.2086303],
                            [39.85201934, -5.20867015],
                            [39.8521638, -5.20834508],
                            [39.85196517, -5.20812837],
                            [39.85171236, -5.20774912],
                            [39.85162208, -5.20755047],
                            [39.85162208, -5.20726152],
                            [39.85164014, -5.20706287],
                            [39.85192905, -5.20675586],
                            [39.85198323, -5.20644885],
                            [39.85174848, -5.20614184],
                            [39.85142345, -5.20572647],
                            [39.85144151, -5.20554588],
                            [39.85151374, -5.20520275],
                            [39.85171237, -5.20476933],
                            [39.85209157, -5.2044262],
                            [39.85218186, -5.20422755],
                            [39.85210963, -5.20399278],
                            [39.85192906, -5.20375801],
                            [39.85174848, -5.20341488],
                            [39.85180266, -5.20316205],
                            [39.85198323, -5.20283698],
                            [39.8520374, -5.20252997],
                            [39.85200129, -5.20242162],
                            [39.85196517, -5.20231326],
                            [39.85183877, -5.20211461],
                            [39.85180266, -5.20189789],
                            [39.85173043, -5.2018076],
                            [39.85156791, -5.20184372],
                            [39.85136928, -5.20184372],
                            [39.85125616, -5.20182631],
                            [39.85113454, -5.2018076],
                            [39.85089979, -5.20173536],
                            [39.85075533, -5.20151865],
                            [39.85053865, -5.20110328],
                            [39.85037613, -5.20072404],
                            [39.85028585, -5.20034479],
                            [39.85024973, -5.19991136],
                            [39.84996082, -5.19973077],
                            [39.84972607, -5.19933346],
                            [39.84983442, -5.19909869],
                            [39.84990724, -5.19906471],
                            [39.85010528, -5.19897228],
                            [39.85044836, -5.19884586],
                            [39.8507734, -5.19866527],
                            [39.85108064, -5.19888309],
                            [39.85145957, -5.19888198],
                            [39.85180266, -5.19862915],
                            [39.85169432, -5.19821379],
                            [39.85178461, -5.19781648],
                            [39.85201935, -5.19758171],
                            [39.85218187, -5.1972747],
                            [39.85252496, -5.19680516],
                            [39.85277776, -5.19644397],
                            [39.85288611, -5.19573966],
                            [39.85301251, -5.19548683],
                            [39.85301251, -5.19512564],
                            [39.85303057, -5.19443939],
                            [39.85340977, -5.19402402],
                            [39.85378897, -5.19380731],
                            [39.85388829, -5.1936809],
                            [39.8539876, -5.19355448],
                            [39.85411401, -5.19319329],
                            [39.85402372, -5.19285017],
                            [39.85404178, -5.19254316],
                            [39.85407789, -5.19189302],
                            [39.85424041, -5.19174855],
                            [39.85429458, -5.19147766],
                            [39.85418624, -5.19108035],
                            [39.85442098, -5.1908817],
                            [39.85436681, -5.19062887],
                            [39.8544571, -5.19033992],
                            [39.85456544, -5.19005097],
                            [39.85470996, -5.18990628],
                            [39.85489048, -5.18987038],
                            [39.85476407, -5.18968978],
                            [39.85480019, -5.18959046],
                            [39.85483275, -5.18959253],
                            [39.85490647, -5.18935663],
                            [39.85502828, -5.1886561],
                            [39.85527192, -5.18774238],
                            [39.85536328, -5.18701139],
                            [39.85541459, -5.18679832],
                            [39.85516825, -5.18451513],
                            [39.85511373, -5.18330686],
                            [39.85500536, -5.18184379],
                            [39.85473446, -5.18067875],
                            [39.85427391, -5.17992011],
                            [39.85432809, -5.17916148],
                            [39.85403547, -5.17854955],
                            [39.8540301, -5.17853832],
                            [39.8531361, -5.17810482],
                            [39.85230798, -5.17825196],
                            [39.85159191, -5.17794225],
                            [39.85094173, -5.17748165],
                            [39.85058955, -5.17712943],
                            [39.85034573, -5.17742746],
                            [39.84974973, -5.17783387],
                            [39.84956095, -5.17787448],
                            [39.84912664, -5.17767131],
                            [39.84901827, -5.17748165],
                            [39.84861052, -5.17757858],
                            [39.84796947, -5.17773097],
                            [39.84717609, -5.17753583],
                            [39.84668845, -5.17731908],
                            [39.84625975, -5.17711453],
                            [39.84583105, -5.17690998],
                            [39.84625846, -5.17695837],
                            [39.84748927, -5.17715273],
                            [39.84854733, -5.17719592],
                            [39.84938946, -5.17689359],
                            [39.85021, -5.17650487],
                            [39.85096576, -5.17596498],
                            [39.8511492, -5.17576113],
                            [39.85154877, -5.17531712],
                            [39.85180789, -5.17484202],
                            [39.8517647, -5.17415096],
                            [39.85159196, -5.17363267],
                            [39.85152718, -5.17333033],
                            [39.85140645, -5.1728714],
                            [39.85156962, -5.17251441],
                            [39.85164557, -5.17234825],
                            [39.85185481, -5.17181015],
                            [39.85148117, -5.17130195],
                            [39.85134667, -5.17109269],
                            [39.85164558, -5.17065922],
                            [39.8518847, -5.17052305],
                            [39.85212383, -5.17038688],
                            [39.85266008, -5.17031693],
                            [39.85382583, -5.17050348],
                            [39.854875, -5.17041021],
                            [39.85641379, -5.1702703],
                            [39.85729976, -5.17015372],
                            [39.857836, -5.16917438],
                            [39.85829302, -5.16570006],
                            [39.85831795, -5.1646405],
                            [39.85832378, -5.16439277],
                            [39.85834238, -5.16360193],
                            [39.85833127, -5.16350373],
                            [39.8581943, -5.16229368],
                            [39.85866324, -5.16130633],
                            [39.85892789, -5.16112672],
                            [39.85935431, -5.16083734],
                            [39.86011943, -5.16014619],
                            [39.86053901, -5.15923289],
                            [39.86110667, -5.15802338],
                            [39.86155093, -5.15664108],
                            [39.8616003, -5.15516005],
                            [39.86177307, -5.15377776],
                            [39.86209392, -5.15254356],
                            [39.8623901, -5.15126],
                            [39.86268627, -5.15002581],
                            [39.86229138, -5.14906314],
                            [39.8619952, -5.14827325],
                            [39.86204457, -5.14755742],
                            [39.86204457, -5.14711311],
                            [39.86243947, -5.14657007],
                            [39.86290841, -5.14612576],
                            [39.86276032, -5.14545929],
                            [39.86246415, -5.14474346],
                            [39.86216798, -5.14392889],
                            [39.86229716, -5.14324598],
                            [39.86234075, -5.14301559],
                            [39.86241479, -5.14210229],
                            [39.86270185, -5.14145155],
                            [39.86278501, -5.14126304],
                            [39.86276033, -5.13997948],
                            [39.8629331, -5.13839971],
                            [39.86320459, -5.13669652],
                            [39.8632377, -5.13610064],
                            [39.86324055, -5.13604932],
                            [39.86330332, -5.13491929],
                            [39.86343929, -5.1336104],
                            [39.86350077, -5.13301863],
                            [39.86357924, -5.13264868],
                            [39.86377333, -5.13173359],
                            [39.86384631, -5.13138949],
                            [39.86406844, -5.12946415],
                            [39.86412274, -5.12861335],
                            [39.86414249, -5.12830401],
                            [39.86419185, -5.1267983],
                            [39.86411781, -5.12553942],
                            [39.86443866, -5.12462612],
                            [39.86455785, -5.12405993],
                            [39.86463611, -5.12368813],
                            [39.86463612, -5.12291028],
                            [39.86463612, -5.12265141],
                            [39.86471016, -5.122059],
                            [39.86483357, -5.1212938],
                            [39.86473484, -5.12040518],
                            [39.86473484, -5.11956593],
                            [39.86480889, -5.11847984],
                            [39.86483357, -5.11754185],
                            [39.86483357, -5.11745555],
                            [39.86483357, -5.1171796],
                            [39.86483357, -5.11650513],
                            [39.86458676, -5.11569056],
                            [39.86399355, -5.11567556],
                            [39.86320462, -5.1151722],
                            [39.86330335, -5.11448105],
                            [39.86340207, -5.11408611],
                            [39.86293313, -5.11356774],
                            [39.86293313, -5.11290128],
                            [39.86288377, -5.11228418],
                            [39.8620693, -5.11161772],
                            [39.86204291, -5.11160216],
                            [39.86110674, -5.11104999],
                            [39.86065442, -5.11116691],
                            [39.86009481, -5.11166708],
                            [39.8596796, -5.11176459],
                            [39.85863782, -5.11200924],
                            [39.85767607, -5.11169176],
                            [39.8565901, -5.11112403],
                            [39.85617052, -5.11060567],
                            [39.85562754, -5.11058099],
                            [39.85543009, -5.10947021],
                            [39.85523264, -5.10919869],
                            [39.85484261, -5.1091733],
                            [39.8543688, -5.10934679],
                            [39.85409731, -5.10909995],
                            [39.85402327, -5.10877906],
                            [39.85340686, -5.10875961],
                            [39.85271647, -5.10873782],
                            [39.85229559, -5.1084088],
                            [39.85170325, -5.10821133],
                            [39.85125899, -5.1077917],
                            [39.85022238, -5.10742144],
                            [39.84958068, -5.10712524],
                            [39.8487662, -5.10660688],
                            [39.84831369, -5.10604088],
                            [39.84802786, -5.10568335],
                            [39.8468749, -5.10599251],
                            [39.84605128, -5.10596509],
                            [39.84545894, -5.10596509],
                            [39.84496531, -5.10608851],
                            [39.8445951, -5.10650813],
                            [39.84417552, -5.10712523],
                            [39.84385466, -5.10784106],
                            [39.84385466, -5.10845816],
                            [39.84395339, -5.10907525],
                            [39.84373126, -5.10959362],
                            [39.8438053, -5.11006261],
                            [39.84427424, -5.11060565],
                            [39.84449637, -5.11109933],
                            [39.84434828, -5.11159301],
                            [39.84429892, -5.11230884],
                            [39.84469381, -5.11277784],
                            [39.84481722, -5.11346898],
                            [39.84508871, -5.11448102],
                            [39.84506403, -5.11519685],
                            [39.84452104, -5.11556711],
                            [39.84420019, -5.11578927],
                            [39.84422487, -5.11615952],
                            [39.84459508, -5.11675194],
                            [39.84481721, -5.11744309],
                            [39.8449653, -5.1180355],
                            [39.8449653, -5.11855386],
                            [39.84461976, -5.11897348],
                            [39.84434827, -5.11946716],
                            [39.84402741, -5.12013363],
                            [39.84424954, -5.12075072],
                            [39.84390401, -5.12107161],
                            [39.8436572, -5.12141719],
                            [39.84348443, -5.12166402],
                            [39.84370656, -5.12188618],
                            [39.8432623, -5.12223175],
                            [39.84279336, -5.1227748],
                            [39.84316357, -5.1229229],
                            [39.84353379, -5.12314506],
                            [39.84365719, -5.12344126],
                            [39.84348442, -5.1238362],
                            [39.84328698, -5.12410773],
                            [39.84276867, -5.1243052],
                            [39.84289208, -5.12474951],
                            [39.84284271, -5.1252185],
                            [39.84308952, -5.12586028],
                            [39.84301548, -5.1261318],
                            [39.84257122, -5.12632927],
                            [39.84249718, -5.1267489],
                            [39.84244781, -5.12719321],
                            [39.84284271, -5.12771157],
                            [39.84276867, -5.12820525],
                            [39.8431142, -5.12840272],
                            [39.84341038, -5.12840272],
                            [39.84370655, -5.12800778],
                            [39.84387932, -5.12822993],
                            [39.843904, -5.12867424],
                            [39.8440274, -5.1291926],
                            [39.84369079, -5.12929179],
                            [39.84313888, -5.12894576],
                            [39.84232441, -5.12869892],
                            [39.84210228, -5.1290445],
                            [39.84212696, -5.12990843],
                            [39.84210227, -5.13062426],
                            [39.84206303, -5.13132109],
                            [39.84148525, -5.13104389],
                            [39.84126312, -5.1316363],
                            [39.84084354, -5.13200656],
                            [39.8403746, -5.1324015],
                            [39.84064609, -5.13296923],
                            [39.84064609, -5.13346291],
                            [39.84025119, -5.13410469],
                            [39.8401031, -5.13477115],
                            [39.84017714, -5.13578319],
                            [39.84042395, -5.13647434],
                            [39.84086821, -5.13696802],
                            [39.84158396, -5.13751106],
                            [39.84207758, -5.13795537],
                            [39.84291674, -5.13795537],
                            [39.84345972, -5.13758512],
                            [39.84400271, -5.13733828],
                            [39.84380526, -5.13805411],
                            [39.8429661, -5.13857247],
                            [39.84268188, -5.13905602],
                            [39.84264085, -5.13905152],
                            [39.84232439, -5.13901678],
                            [39.84205761, -5.13874996],
                            [39.84180609, -5.13849842],
                            [39.84049799, -5.13800474],
                            [39.83995501, -5.13839968],
                            [39.83938734, -5.13921424],
                            [39.83864691, -5.14027565],
                            [39.83815329, -5.14136174],
                            [39.8376103, -5.14200352],
                            [39.83752683, -5.14318894],
                            [39.83748689, -5.14375607],
                            [39.83738817, -5.14420038],
                            [39.83679324, -5.14503263],
                            [39.83647356, -5.14465297],
                            [39.83657346, -5.1440735],
                            [39.83603401, -5.14347404],
                            [39.83557448, -5.14331418],
                            [39.83499507, -5.14331418],
                            [39.83441566, -5.14331418],
                            [39.8331969, -5.14349402],
                            [39.83269741, -5.14355396],
                            [39.83225277, -5.1435736],
                            [39.83179511, -5.14319666],
                            [39.83136437, -5.14311589],
                            [39.83117592, -5.14279279],
                            [39.83055673, -5.14271202],
                            [39.83031444, -5.14279279],
                            [39.82982985, -5.14287357],
                            [39.82921066, -5.14287357],
                            [39.82877992, -5.14306203],
                            [39.82840302, -5.14298126],
                            [39.82789152, -5.14300819],
                            [39.82761406, -5.14314633],
                            [39.82681737, -5.14297559],
                            [39.82621985, -5.14297559],
                            [39.82522399, -5.14277639],
                            [39.82445575, -5.14252028],
                            [39.82416938, -5.14265516],
                            [39.82425657, -5.14308941],
                            [39.82425657, -5.14377237],
                            [39.82439884, -5.14419921],
                            [39.82422287, -5.14425046],
                            [39.82371596, -5.14371545],
                            [39.82339448, -5.14383488],
                            [39.82334914, -5.14453478],
                            [39.82305871, -5.14411616],
                            [39.82330771, -5.14343692],
                            [39.82388765, -5.14354049],
                            [39.82405334, -5.14327121],
                            [39.82373825, -5.14289536],
                            [39.82376117, -5.14265864],
                            [39.82378409, -5.14242193],
                            [39.82442616, -5.14184193],
                            [39.82459186, -5.14088907],
                            [39.82473684, -5.1400605],
                            [39.82440545, -5.13960479],
                            [39.82388765, -5.13972907],
                            [39.82376597, -5.13945979],
                            [39.82394462, -5.13940284],
                            [39.82415691, -5.13936141],
                            [39.82449089, -5.13904811],
                            [39.82488701, -5.13880212],
                            [39.82507859, -5.13868043],
                            [39.82528571, -5.13865712],
                            [39.82562746, -5.13855096],
                            [39.8259459, -5.13852248],
                            [39.8262048, -5.13841373],
                            [39.8263006, -5.13822472],
                            [39.82637309, -5.13796061],
                            [39.82638862, -5.13767579],
                            [39.82633649, -5.13742211],
                            [39.82628148, -5.13715448],
                            [39.82626756, -5.13708673],
                            [39.82624136, -5.13695926],
                            [39.82611419, -5.1366841],
                            [39.82595885, -5.13644588],
                            [39.8255239, -5.13607302],
                            [39.8250061, -5.13577267],
                            [39.82484041, -5.13561731],
                            [39.82463329, -5.13554481],
                            [39.82437439, -5.13554481],
                            [39.82407407, -5.13547231],
                            [39.82386695, -5.13504767],
                            [39.82384173, -5.1349846],
                            [39.82380482, -5.13489231],
                            [39.82365983, -5.13475767],
                            [39.82357699, -5.13461267],
                            [39.82345271, -5.13451945],
                            [39.82331809, -5.1345091],
                            [39.8231731, -5.13456088],
                            [39.8229142, -5.13464374],
                            [39.8227278, -5.13477838],
                            [39.82269673, -5.13507874],
                            [39.8227505, -5.13520724],
                            [39.82249996, -5.13494408],
                            [39.82208572, -5.13519265],
                            [39.82146437, -5.13537908],
                            [39.8210981, -5.13542686],
                            [39.82098799, -5.13544122],
                            [39.82035564, -5.13518014],
                            [39.82025459, -5.13513842],
                            [39.8202016, -5.13511654],
                            [39.82015355, -5.1350967],
                            [39.8195312, -5.13497464],
                            [39.82008592, -5.13508336],
                            [39.82017934, -5.13503146],
                            [39.8202016, -5.13502033],
                            [39.82027323, -5.13498451],
                            [39.82036712, -5.13492416],
                            [39.82050125, -5.13485038],
                            [39.82063538, -5.13495098],
                            [39.82073597, -5.13500463],
                            [39.82075683, -5.13499073],
                            [39.82083657, -5.13493756],
                            [39.82099752, -5.13496439],
                            [39.82111005, -5.13494084],
                            [39.82112866, -5.13493694],
                            [39.8212859, -5.13490403],
                            [39.82139991, -5.13489062],
                            [39.82151392, -5.13485038],
                            [39.82158098, -5.13477661],
                            [39.82164134, -5.1346693],
                            [39.82171511, -5.13455529],
                            [39.82181571, -5.13442116],
                            [39.82195655, -5.13436081],
                            [39.82205044, -5.13419314],
                            [39.82217115, -5.13413279],
                            [39.82223151, -5.13409255],
                            [39.82231199, -5.13407913],
                            [39.82244612, -5.13411937],
                            [39.82248636, -5.13417302],
                            [39.82258695, -5.13409255],
                            [39.82267414, -5.13407243],
                            [39.82287533, -5.13405901],
                            [39.82306982, -5.1340456],
                            [39.82314359, -5.13399195],
                            [39.82321066, -5.13391147],
                            [39.82327101, -5.13380417],
                            [39.8233582, -5.13366333],
                            [39.82342526, -5.13350908],
                            [39.82344538, -5.1333146],
                            [39.8235728, -5.13309999],
                            [39.82363987, -5.13301951],
                            [39.82361975, -5.13294574],
                            [39.82350574, -5.13271101],
                            [39.82350574, -5.13251653],
                            [39.82355269, -5.13226168],
                            [39.82361975, -5.13210073],
                            [39.8235728, -5.13187941],
                            [39.82351915, -5.13171175],
                            [39.82347221, -5.13147702],
                            [39.82342191, -5.13138984],
                            [39.82337161, -5.13130265],
                            [39.82319724, -5.13126241],
                            [39.82319724, -5.13120206],
                            [39.8232576, -5.13108134],
                            [39.82323748, -5.13091368],
                            [39.82329784, -5.13078626],
                            [39.82327631, -5.13055482],
                            [39.82327101, -5.13049788],
                            [39.82319724, -5.13029668],
                            [39.82312347, -5.13012902],
                            [39.82301617, -5.12988088],
                            [39.82301617, -5.12977358],
                            [39.82306311, -5.12961262],
                            [39.82298263, -5.12945838],
                            [39.82301617, -5.12932425],
                            [39.82314359, -5.12916329],
                            [39.82330455, -5.12904928],
                            [39.8233757, -5.1289634],
                            [39.82349903, -5.12881455],
                            [39.82375388, -5.12868043],
                            [39.82409591, -5.12857312],
                            [39.82443123, -5.12837193],
                            [39.82456536, -5.12831828],
                            [39.82475314, -5.12815732],
                            [39.82480679, -5.12795613],
                            [39.82493422, -5.12787565],
                            [39.82507505, -5.12781529],
                            [39.8252293, -5.12768787],
                            [39.82535672, -5.12755374],
                            [39.82541037, -5.12734584],
                            [39.82537014, -5.1271983],
                            [39.82532319, -5.12706417],
                            [39.82520248, -5.12687639],
                            [39.82524942, -5.1267825],
                            [39.82546925, -5.12667931],
                            [39.82557804, -5.12662825],
                            [39.82560486, -5.12659471],
                            [39.82562498, -5.12646059],
                            [39.8256384, -5.12625269],
                            [39.82571217, -5.12600455],
                            [39.82583288, -5.12592407],
                            [39.82592677, -5.12581677],
                            [39.82597372, -5.1255418],
                            [39.82606761, -5.12542779],
                            [39.82623527, -5.12532719],
                            [39.82637611, -5.12515953],
                            [39.82660413, -5.12513941],
                            [39.8266846, -5.12515282],
                            [39.82685227, -5.12519977],
                            [39.82693274, -5.12517294],
                            [39.82712723, -5.12511259],
                            [39.82721441, -5.12510588],
                            [39.82741561, -5.12493822],
                            [39.82751621, -5.12486445],
                            [39.82778446, -5.12499187],
                            [39.82795213, -5.12501199],
                            [39.82811308, -5.12491139],
                            [39.82828745, -5.12479738],
                            [39.82841487, -5.1247102],
                            [39.82858253, -5.12454254],
                            [39.82863619, -5.12446206],
                            [39.82863619, -5.12435476],
                            [39.82857583, -5.12423404],
                            [39.82858924, -5.12416697],
                            [39.8286563, -5.12409991],
                            [39.82879043, -5.1240932],
                            [39.82888432, -5.1240865],
                            [39.8289648, -5.12401943],
                            [39.82907881, -5.12390542],
                            [39.82917941, -5.12378471],
                            [39.82921965, -5.12373105],
                            [39.82925989, -5.12361704],
                            [39.8292733, -5.12342926],
                            [39.8292733, -5.1232616],
                            [39.82923306, -5.12311406],
                            [39.82925318, -5.12293298],
                            [39.82921294, -5.12277874],
                            [39.82925318, -5.12267143],
                            [39.82931354, -5.12248365],
                            [39.82936048, -5.12240988],
                            [39.82938731, -5.12236293],
                            [39.82939402, -5.12224893],
                            [39.82944767, -5.1221148],
                            [39.82952144, -5.12202091],
                            [39.82950803, -5.12190019],
                            [39.82957509, -5.121813],
                            [39.82962874, -5.12163864],
                            [39.82967569, -5.12153133],
                            [39.82964886, -5.12137708],
                            [39.82962204, -5.12125637],
                            [39.82950803, -5.12115577],
                            [39.82947449, -5.12100152],
                            [39.82947449, -5.12096799],
                            [39.82950132, -5.12092775],
                            [39.82956838, -5.12090093],
                            [39.82965557, -5.12083386],
                            [39.8296891, -5.12069973],
                            [39.82972263, -5.1205656],
                            [39.82959521, -5.12021016],
                            [39.82943425, -5.1200492],
                            [39.82936048, -5.12008274],
                            [39.82917941, -5.12017663],
                            [39.82902518, -5.12008278],
                            [39.82902517, -5.11999559],
                            [39.82917942, -5.11989499],
                            [39.82929343, -5.11972733],
                            [39.82938732, -5.11962003],
                            [39.82935379, -5.11945907],
                            [39.82935379, -5.11930482],
                            [39.82930685, -5.11925788],
                            [39.82928337, -5.1192344],
                            [39.82923949, -5.11917394],
                            [39.82915259, -5.11901643],
                            [39.82917942, -5.11892254],
                            [39.82929343, -5.11885548],
                            [39.82940073, -5.11882865],
                            [39.82952145, -5.11874817],
                            [39.82954157, -5.11850674],
                            [39.82958181, -5.11840614],
                            [39.82954157, -5.11802388],
                            [39.82959522, -5.11762819],
                            [39.82955498, -5.11738006],
                            [39.82917942, -5.11700449],
                            [39.82910565, -5.11696425],
                            [39.82888433, -5.11650821],
                            [39.82880386, -5.11623325],
                            [39.82866973, -5.11591805],
                            [39.82860266, -5.11573697],
                            [39.82852889, -5.11550224],
                            [39.82842829, -5.11538823],
                            [39.8283277, -5.11534129],
                            [39.82828746, -5.11522057],
                            [39.82832099, -5.11495232],
                            [39.8283277, -5.11486513],
                            [39.82830758, -5.11479807],
                            [39.82829417, -5.11467735],
                            [39.82832099, -5.11456334],
                            [39.82820624, -5.11440269],
                            [39.8281198, -5.11428166],
                            [39.82816674, -5.11408047],
                            [39.82812955, -5.11388014],
                            [39.82815564, -5.11389071],
                            [39.82818174, -5.11390128],
                            [39.82821625, -5.11391669],
                            [39.82825076, -5.11393209],
                            [39.82837198, -5.11388014],
                            [39.82845279, -5.11381087],
                            [39.82853361, -5.11371274],
                            [39.8285567, -5.11357998],
                            [39.82857401, -5.1134299],
                            [39.82857401, -5.11324518],
                            [39.82857401, -5.11317015],
                            [39.82863174, -5.11303738],
                            [39.82864328, -5.11292194],
                            [39.82865483, -5.112743],
                            [39.82872409, -5.11258714],
                            [39.82867214, -5.11243706],
                            [39.82874141, -5.11231585],
                            [39.82876497, -5.11225144],
                            [39.82882799, -5.11207918],
                            [39.82885404, -5.11187347],
                            [39.82894344, -5.11185406],
                            [39.82918588, -5.11197528],
                            [39.82922628, -5.11191756],
                            [39.82925514, -5.11177902],
                            [39.82935327, -5.11162894],
                            [39.82942255, -5.11155979],
                            [39.82946871, -5.11156008],
                            [39.82953799, -5.11169244],
                            [39.82971512, -5.1116994],
                            [39.82993627, -5.11165203],
                            [39.8302018, -5.11171553],
                            [39.8303057, -5.11181366],
                            [39.8304731, -5.11195219],
                            [39.83070399, -5.11202146],
                            [39.83075017, -5.11190601],
                            [39.83073285, -5.11166935],
                            [39.83079633, -5.11155843],
                            [39.83085984, -5.11146732],
                            [39.83087138, -5.11125952],
                            [39.83087716, -5.11110367],
                            [39.83079634, -5.11101708],
                            [39.83075305, -5.11097956],
                            [39.83070976, -5.11094204],
                            [39.83094065, -5.1107631],
                            [39.83098683, -5.11057262],
                            [39.8309522, -5.11036481],
                            [39.83091179, -5.11021473],
                            [39.83087716, -5.1101166],
                            [39.83080212, -5.10993189],
                            [39.83073285, -5.10983376],
                            [39.83060009, -5.10962019],
                            [39.8305135, -5.10942393],
                            [39.83038651, -5.10927962],
                            [39.83025375, -5.10919304],
                            [39.83006326, -5.10916995],
                            [39.82994782, -5.109118],
                            [39.82973424, -5.10909491],
                            [39.8296592, -5.10900255],
                            [39.82952067, -5.10885247],
                            [39.82935904, -5.10876589],
                            [39.82921474, -5.10877166],
                            [39.82909352, -5.10877166],
                            [39.82887417, -5.10877743],
                            [39.82873564, -5.10882938],
                            [39.82857401, -5.10897946],
                            [39.82848743, -5.10921036],
                            [39.82856247, -5.10945856],
                            [39.82857401, -5.10962596],
                            [39.8282219, -5.10979336],
                            [39.82807862, -5.10994826],
                            [39.82800833, -5.11002425],
                            [39.82782361, -5.11001848],
                            [39.82770817, -5.10976449],
                            [39.8277024, -5.10965482],
                            [39.82762964, -5.1095376],
                            [39.8275985, -5.10948743],
                            [39.82758695, -5.10946434],
                            [39.82751191, -5.10947011],
                            [39.82739647, -5.10966637],
                            [39.82715403, -5.10976449],
                            [39.82703193, -5.10969548],
                            [39.82721752, -5.10937198],
                            [39.82745419, -5.10917572],
                            [39.82738492, -5.10882361],
                            [39.82730411, -5.10867353],
                            [39.82725696, -5.10857398],
                            [39.82725216, -5.10856386],
                            [39.82718866, -5.10847727],
                            [39.82714826, -5.10842532],
                            [39.82708476, -5.10835606],
                            [39.82702127, -5.10832142],
                            [39.826952, -5.1083272],
                            [39.82691737, -5.10833008],
                            [39.82688273, -5.10833297],
                            [39.82666339, -5.10841378],
                            [39.82657287, -5.10844772],
                            [39.82647867, -5.10848305],
                            [39.82646811, -5.10843025],
                            [39.82645558, -5.1083676],
                            [39.82637477, -5.1083676],
                            [39.82634417, -5.1083784],
                            [39.82627664, -5.10840224],
                            [39.82614965, -5.10845419],
                            [39.82604575, -5.10842532],
                            [39.82598841, -5.10838061],
                            [39.82599229, -5.10835496],
                            [39.82600837, -5.10824858],
                            [39.82602266, -5.10815403],
                            [39.8260527, -5.10814227],
                            [39.82615542, -5.10810208],
                            [39.82621492, -5.10801508],
                            [39.82624201, -5.10787118],
                            [39.82621315, -5.10784809],
                            [39.82610825, -5.10783062],
                            [39.82611776, -5.10777074],
                            [39.82612656, -5.10771533],
                            [39.826071, -5.10764135],
                            [39.82608038, -5.1075768],
                            [39.82606776, -5.10750609],
                            [39.82605152, -5.10741517],
                            [39.82602266, -5.10735745],
                            [39.82598226, -5.1073055],
                            [39.82590144, -5.10727087],
                            [39.82580331, -5.10727087],
                            [39.82568787, -5.10727664],
                            [39.82553202, -5.1073055],
                            [39.8254108, -5.10732282],
                            [39.82530321, -5.1072768],
                            [39.82530239, -5.10717273],
                            [39.82530135, -5.10703997],
                            [39.82530048, -5.10692369],
                            [39.82507576, -5.10679972],
                            [39.82507004, -5.10679657],
                            [39.82510133, -5.10643343],
                            [39.82510133, -5.10621894],
                            [39.82510133, -5.1060906],
                            [39.82510133, -5.10601977],
                            [39.82519325, -5.10569804],
                            [39.82502872, -5.10542878],
                            [39.82502474, -5.10542227],
                            [39.82488687, -5.10516182],
                            [39.82461597, -5.10504026],
                            [39.82424347, -5.10511585],
                            [39.8241321, -5.10506398],
                            [39.8241, -5.10504903],
                            [39.82413544, -5.1050239],
                            [39.82424347, -5.10494733],
                            [39.82448858, -5.10491668],
                            [39.82456517, -5.10450303],
                            [39.82451922, -5.10422726],
                            [39.82421284, -5.10422726],
                            [39.82383552, -5.10438691],
                            [39.82381454, -5.10439578],
                            [39.82347753, -5.10448771],
                            [39.82333535, -5.10442435],
                            [39.82313939, -5.10433702],
                            [39.82292806, -5.10414517],
                            [39.82269578, -5.1039343],
                            [39.82268094, -5.10392084],
                            [39.82268699, -5.10388454],
                            [39.82271158, -5.10373699],
                            [39.82281882, -5.10346122],
                            [39.82295669, -5.10310885],
                            [39.8230486, -5.10290968],
                            [39.82306392, -5.10274115],
                            [39.82301775, -5.10272609],
                            [39.82280084, -5.10265535],
                            [39.82266563, -5.10287904],
                            [39.82232861, -5.10330801],
                            [39.82200691, -5.10358378],
                            [39.82196738, -5.10362332],
                            [39.82176181, -5.10382891],
                            [39.82157798, -5.10393616],
                            [39.82134669, -5.10390947],
                            [39.82117969, -5.1038902],
                            [39.82078485, -5.1036992],
                            [39.82064958, -5.10341558],
                            [39.82061289, -5.10333865],
                            [39.82075076, -5.10304756],
                            [39.82091927, -5.10294032],
                            [39.82117969, -5.10281775],
                            [39.82107696, -5.1027779],
                            [39.82098055, -5.10271051],
                            [39.82039843, -5.10274115],
                            [39.82019307, -5.10283357],
                            [39.82009205, -5.10287903],
                            [39.81984695, -5.10292499],
                            [39.81966265, -5.10273975],
                            [39.81978443, -5.10278491],
                            [39.82005255, -5.10268027],
                            [39.82026722, -5.10259649],
                            [39.82057338, -5.10246695],
                            [39.82107972, -5.10253761],
                            [39.8212799, -5.10267893],
                            [39.82130345, -5.10285557],
                            [39.82110327, -5.103044],
                            [39.82095019, -5.10310288],
                            [39.82077356, -5.1031971],
                            [39.82081354, -5.10335703],
                            [39.82082066, -5.10338552],
                            [39.82095019, -5.10345618],
                            [39.82113859, -5.10358572],
                            [39.82127989, -5.10370349],
                            [39.82135055, -5.10372704],
                            [39.8214212, -5.1037506],
                            [39.82158605, -5.10372704],
                            [39.82169203, -5.10358573],
                            [39.82188043, -5.10337375],
                            [39.82188257, -5.1033715],
                            [39.82209239, -5.10314999],
                            [39.82228079, -5.10300867],
                            [39.8224221, -5.1028438],
                            [39.82245742, -5.10258471],
                            [39.82250453, -5.10250228],
                            [39.82264583, -5.10238451],
                            [39.82290489, -5.10234918],
                            [39.82298731, -5.10240807],
                            [39.82317572, -5.10265537],
                            [39.82322282, -5.10305578],
                            [39.82310506, -5.10343263],
                            [39.82298731, -5.10365639],
                            [39.82291666, -5.10388014],
                            [39.82322282, -5.1041039],
                            [39.82343477, -5.10416278],
                            [39.82353986, -5.10417592],
                            [39.82362318, -5.10418634],
                            [39.82372641, -5.10409898],
                            [39.82377625, -5.10405679],
                            [39.82402354, -5.10386837],
                            [39.82429437, -5.10382126],
                            [39.82460053, -5.10380949],
                            [39.82476538, -5.10389192],
                            [39.82524817, -5.10403324],
                            [39.8255661, -5.10380949],
                            [39.8257074, -5.10339731],
                            [39.82581338, -5.10306756],
                            [39.82580161, -5.1028438],
                            [39.82571918, -5.10254939],
                            [39.82563675, -5.10220787],
                            [39.82555433, -5.10177213],
                            [39.825519, -5.10152482],
                            [39.82552195, -5.1015123],
                            [39.8256132, -5.10112442],
                            [39.82564853, -5.10087711],
                            [39.82606067, -5.10041782],
                            [39.82606593, -5.100411],
                            [39.82649635, -5.09985255],
                            [39.82674363, -5.09948747],
                            [39.82676882, -5.09939588],
                            [39.82685407, -5.09908586],
                            [39.82687316, -5.09901641],
                            [39.82704831, -5.09869834],
                            [39.82732062, -5.09820382],
                            [39.82740885, -5.09801853],
                            [39.82743838, -5.09795651],
                            [39.82745604, -5.09788585],
                            [39.8274737, -5.09781519],
                            [39.82754681, -5.09758707],
                            [39.82772099, -5.09729702],
                            [39.82772099, -5.0971086],
                            [39.8279518, -5.09680885],
                            [39.82848683, -5.09610732],
                            [39.82875491, -5.09590816],
                            [39.82887746, -5.09566303],
                            [39.82900002, -5.09552514],
                            [39.82914555, -5.09542556],
                            [39.82924512, -5.09518809],
                            [39.82929108, -5.09508084],
                            [39.82943661, -5.09490465],
                            [39.82952086, -5.09474379],
                            [39.82965873, -5.09453696],
                            [39.82968851, -5.09445918],
                            [39.8299044, -5.09410587],
                            [39.83012029, -5.09351701],
                            [39.83015703, -5.09335718],
                            [39.83019376, -5.09319735],
                            [39.83037688, -5.09296695],
                            [39.83041233, -5.09257704],
                            [39.83031781, -5.0923171],
                            [39.83024102, -5.09210442],
                            [39.83017014, -5.09191537],
                            [39.83015184, -5.09178359],
                            [39.8301406, -5.0917027],
                            [39.83001065, -5.09165543],
                            [39.82995748, -5.09159636],
                            [39.82997521, -5.09149002],
                            [39.82976255, -5.09126552],
                            [39.8296326, -5.09114737],
                            [39.82946122, -5.09114625],
                            [39.8293256, -5.09114536],
                            [39.82916003, -5.09121235],
                            [39.82898284, -5.09121043],
                            [39.82891755, -5.09120972],
                            [39.82867565, -5.09131278],
                            [39.8285516, -5.09137777],
                            [39.82832714, -5.09149592],
                            [39.82820309, -5.09154318],
                            [39.82800815, -5.09159635],
                            [39.82786638, -5.09164362],
                            [39.82772486, -5.09163771],
                            [39.82736368, -5.09162264],
                            [39.8270394, -5.09156091],
                            [39.82678539, -5.09168497],
                            [39.8266316, -5.09167755],
                            [39.82649595, -5.09157863],
                            [39.82648413, -5.09160226],
                            [39.82643747, -5.09160025],
                            [39.82643688, -5.09166134],
                            [39.8264202, -5.09168427],
                            [39.82638962, -5.09172632],
                            [39.82634236, -5.0918622],
                            [39.8262693, -5.09189256],
                            [39.82635324, -5.09164073],
                            [39.8264125, -5.09146293],
                            [39.82620793, -5.09084917],
                            [39.82573743, -5.09043999],
                            [39.82552278, -5.09033515],
                            [39.82485781, -5.09001035],
                            [39.8246737, -5.08982622],
                            [39.82448959, -5.08964209],
                            [39.82436685, -5.08917154],
                            [39.82445552, -5.08906973],
                            [39.82491918, -5.08853732],
                            [39.82563516, -5.0882509],
                            [39.82577835, -5.08786218],
                            [39.82567828, -5.08732033],
                            [39.82585862, -5.08732818],
                            [39.82603897, -5.08733602],
                            [39.82662828, -5.08780261],
                            [39.8261863, -5.08839199],
                            [39.825155, -5.08866213],
                            [39.82475978, -5.08912713],
                            [39.82473756, -5.08915328],
                            [39.8246639, -5.08934974],
                            [39.82527777, -5.08966899],
                            [39.82582144, -5.08989398],
                            [39.82598986, -5.08996368],
                            [39.82653006, -5.09047939],
                            [39.82689838, -5.09087231],
                            [39.82716849, -5.09114244],
                            [39.82805246, -5.09067585],
                            [39.82905921, -5.08971811],
                            [39.82925565, -5.08939886],
                            [39.82942753, -5.0892024],
                            [39.82952575, -5.08961988],
                            [39.82974674, -5.08949709],
                            [39.83018873, -5.08932519],
                            [39.83033606, -5.08893227],
                            [39.83033606, -5.08851479],
                            [39.83005293, -5.08826637],
                            [39.83063072, -5.08772895],
                            [39.83166202, -5.08682032],
                            [39.83254599, -5.08632917],
                            [39.83340541, -5.08610815],
                            [39.83401928, -5.08571523],
                            [39.83451038, -5.08529775],
                            [39.83534524, -5.08478205],
                            [39.83618011, -5.08409443],
                            [39.83652388, -5.08271921],
                            [39.83672032, -5.08127032],
                            [39.83706409, -5.07923204],
                            [39.83694131, -5.07888823],
                            [39.83495238, -5.07793048],
                            [39.83468227, -5.07763579],
                            [39.83509971, -5.07677628],
                            [39.8353207, -5.07643247],
                            [39.8348875, -5.07632377],
                            [39.83401929, -5.07692362],
                            [39.83367553, -5.07743933],
                            [39.8329545, -5.07721682],
                            [39.83180936, -5.0773411],
                            [39.83149248, -5.07723666],
                            [39.83095359, -5.07705905],
                            [39.83032662, -5.0768524],
                            [39.83021924, -5.07681701],
                            [39.82895537, -5.07640045],
                            [39.82890131, -5.07634137],
                            [39.82844536, -5.07584308],
                            [39.82827516, -5.07532222],
                            [39.82887209, -5.07570695],
                            [39.83036173, -5.07668464],
                            [39.83161861, -5.07649841],
                            [39.83227032, -5.07612596],
                            [39.83348065, -5.07519484],
                            [39.83436513, -5.07398437],
                            [39.83441168, -5.0730998],
                            [39.83455133, -5.07207556],
                            [39.83428316, -5.0716662],
                            [39.83408611, -5.0713654],
                            [39.83405299, -5.07131485],
                            [39.83366686, -5.07072543],
                            [39.83338362, -5.07054588],
                            [39.83334166, -5.07051928],
                            [39.83303681, -5.07032604],
                            [39.83352721, -5.06881662],
                            [39.83391328, -5.06764661],
                            [39.83366489, -5.06683236],
                            [39.83325092, -5.06630793],
                            [39.83287834, -5.06601811],
                            [39.83274034, -5.06572829],
                            [39.83261615, -5.06531427],
                            [39.83261092, -5.06529898],
                            [39.83243676, -5.06478983],
                            [39.83256096, -5.0643206],
                            [39.83242296, -5.06390658],
                            [39.83205038, -5.06368577],
                            [39.83180214, -5.06360207],
                            [39.83156741, -5.06334074],
                            [39.83142941, -5.06347875],
                            [39.83131902, -5.06356156],
                            [39.83125002, -5.06343735],
                            [39.83119483, -5.06327174],
                            [39.83119483, -5.06303712],
                            [39.83116723, -5.06252649],
                            [39.83098121, -5.06230058],
                            [39.83097404, -5.06229188],
                            [39.83047727, -5.06201586],
                            [39.83024268, -5.06208487],
                            [39.83002189, -5.06218147],
                            [39.83000809, -5.06200206],
                            [39.83027028, -5.06182265],
                            [39.83049107, -5.06171224],
                            [39.83069805, -5.06180885],
                            [39.83105684, -5.06205726],
                            [39.83120247, -5.06215976],
                            [39.83142942, -5.06231948],
                            [39.831733, -5.0625403],
                            [39.8317352, -5.06253875],
                            [39.83196759, -5.06237469],
                            [39.83202279, -5.06209867],
                            [39.8320301, -5.06203774],
                            [39.83206418, -5.06175365],
                            [39.83196759, -5.06149143],
                            [39.83173673, -5.06123043],
                            [39.83165021, -5.06113261],
                            [39.83123856, -5.06104392],
                            [39.83102924, -5.06122921],
                            [39.83107064, -5.06088419],
                            [39.8310335, -5.06012784],
                            [39.83093558, -5.05931172],
                            [39.83073974, -5.05852825],
                            [39.83064181, -5.05810387],
                            [39.83034805, -5.0573204],
                            [39.83038069, -5.05709189],
                            [39.83054389, -5.05692866],
                            [39.83071182, -5.05628573],
                            [39.83062512, -5.05604545],
                            [39.83067669, -5.0558673],
                            [39.83078451, -5.05575009],
                            [39.83090171, -5.0555438],
                            [39.83092515, -5.05535158],
                            [39.83099546, -5.05523906],
                            [39.83107984, -5.05513122],
                            [39.83108453, -5.05504214],
                            [39.83115016, -5.05499057],
                            [39.83124392, -5.05491556],
                            [39.83130486, -5.05486868],
                            [39.83138997, -5.05480055],
                            [39.83147313, -5.05456394],
                            [39.83152059, -5.05437407],
                            [39.83178637, -5.05397536],
                            [39.83207114, -5.05360512],
                            [39.83242235, -5.05328235],
                            [39.83267864, -5.0532159],
                            [39.83268813, -5.05306401],
                            [39.83269762, -5.0529406],
                            [39.83273559, -5.05289313],
                            [39.8328495, -5.05276023],
                            [39.83306782, -5.05234252],
                            [39.83312477, -5.0520957],
                            [39.83320071, -5.05188685],
                            [39.83322918, -5.05175394],
                            [39.83347598, -5.05161154],
                            [39.83355192, -5.05150712],
                            [39.83363735, -5.05134573],
                            [39.83364684, -5.0511084],
                            [39.8336943, -5.05095651],
                            [39.83386516, -5.05088057],
                            [39.83395738, -5.05073564],
                            [39.83399805, -5.05067171],
                            [39.83415942, -5.05044388],
                            [39.83424259, -5.05027809],
                            [39.83423564, -5.05027795],
                            [39.83414503, -5.05000611],
                            [39.834131, -5.049964],
                            [39.83406237, -5.04977443],
                            [39.8341518, -5.04959555],
                            [39.83425991, -5.04955951],
                            [39.83436643, -5.049524],
                            [39.8345274, -5.04945245],
                            [39.83461683, -5.04929146],
                            [39.83473756, -5.049126],
                            [39.83485829, -5.04896054],
                            [39.83475097, -5.04878166],
                            [39.83467049, -5.0486475],
                            [39.8347152, -5.04848651],
                            [39.83480463, -5.04826291],
                            [39.83491195, -5.04825397],
                            [39.83500138, -5.04823608],
                            [39.83494772, -5.04809298],
                            [39.83482252, -5.04797671],
                            [39.83491195, -5.04778889],
                            [39.8347678, -5.04751753],
                            [39.83475992, -5.04750268],
                            [39.83464178, -5.04746635],
                            [39.83453635, -5.04722542],
                            [39.83448269, -5.04693027],
                            [39.83447648, -5.04690667],
                            [39.83443798, -5.04676034],
                            [39.83433066, -5.04637575],
                            [39.83412498, -5.04611638],
                            [39.83374938, -5.04594645],
                            [39.83345785, -5.04581686],
                            [39.83334695, -5.04576757],
                            [39.8332128, -5.04549925],
                            [39.83313232, -5.04517727],
                            [39.83298923, -5.04486424],
                            [39.83306972, -5.04456909],
                            [39.83325752, -5.04401457],
                            [39.83330223, -5.04384463],
                            [39.83316809, -5.04366575],
                            [39.83283721, -5.04348688],
                            [39.83255103, -5.04336166],
                            [39.83225592, -5.04312018],
                            [39.83214625, -5.04301615],
                            [39.83170689, -5.0430601],
                            [39.83164294, -5.04307075],
                            [39.83164052, -5.04307014],
                            [39.83164916, -5.0430687],
                            [39.8317131, -5.04305804],
                            [39.83215247, -5.0430141],
                            [39.83227953, -5.04300139],
                            [39.83295925, -5.04328464],
                            [39.83332742, -5.04362453],
                            [39.83358231, -5.04441762],
                            [39.83335574, -5.04512574],
                            [39.83349735, -5.04560725],
                            [39.8346302, -5.0461171],
                            [39.8354232, -5.04657029],
                            [39.83545152, -5.04654197],
                            [39.8358197, -5.04606045],
                            [39.8358197, -5.04566391],
                            [39.83581969, -5.04566386],
                            [39.83547985, -5.04481417],
                            [39.83533824, -5.04390778],
                            [39.83525328, -5.04328464],
                            [39.83491342, -5.04291642],
                            [39.83480014, -5.04257652],
                            [39.83491342, -5.0423216],
                            [39.83491342, -5.04195338],
                            [39.83465853, -5.04175511],
                            [39.83446028, -5.04135857],
                            [39.83441881, -5.04136073],
                            [39.83392658, -5.0413864],
                            [39.83423371, -5.04099035],
                            [39.83429035, -5.04067878],
                            [39.83417707, -5.04028223],
                            [39.834006, -5.04017336],
                            [39.83386553, -5.04008396],
                            [39.83362284, -5.04011697],
                            [39.833554, -5.03988569],
                            [39.83397882, -5.03991401],
                            [39.83426203, -5.03980071],
                            [39.83431366, -5.03931025],
                            [39.83431868, -5.03926255],
                            [39.83446029, -5.038866],
                            [39.83454525, -5.03855443],
                            [39.83445494, -5.03839702],
                            [39.83479761, -5.0372944],
                            [39.83503599, -5.03648978],
                            [39.83552765, -5.03581927],
                            [39.8356683, -5.03524467],
                            [39.8357655, -5.03482807],
                            [39.83586269, -5.03441146],
                            [39.83591824, -5.03396708],
                            [39.83639034, -5.03335605],
                            [39.83661251, -5.0328839],
                            [39.83661251, -5.03268948],
                            [39.83687169, -5.03255988],
                            [39.83694576, -5.03252284],
                            [39.83696285, -5.03245568],
                            [39.83733455, -5.03099528],
                            [39.83782263, -5.02987419],
                            [39.83870903, -5.02783821],
                            [39.839059, -5.02703437],
                            [39.83905119, -5.02698364],
                            [39.83901772, -5.02676607],
                            [39.83897966, -5.02651865],
                            [39.83896187, -5.02640301],
                            [39.83896947, -5.02638401],
                            [39.839059, -5.02616018],
                            [39.83913298, -5.02612318],
                            [39.83925324, -5.02606305],
                            [39.83937464, -5.02601448],
                            [39.8393018, -5.02562595],
                            [39.83915612, -5.02518885],
                            [39.83896188, -5.02472747],
                            [39.83866603, -5.02469066],
                            [39.83864623, -5.02431466],
                            [39.83900563, -5.02417879],
                            [39.83918533, -5.02411086],
                            [39.83936503, -5.02404293],
                            [39.83887906, -5.0233949],
                            [39.83887906, -5.02290887],
                            [39.83877107, -5.02236885],
                            [39.83839309, -5.0214508],
                            [39.83817711, -5.02074877],
                            [39.83779494, -5.02003211],
                            [39.83774514, -5.01993873],
                            [39.83742116, -5.0192907],
                            [39.8368272, -5.01875067],
                            [39.83569327, -5.01799463],
                            [39.83532689, -5.01805981],
                            [39.83472134, -5.01718459],
                            [39.83433704, -5.01664652],
                            [39.83418137, -5.01642856],
                            [39.83347942, -5.01599653],
                            [39.83341121, -5.01595662],
                            [39.83301188, -5.01572292],
                            [39.83275133, -5.01557044],
                            [39.83236908, -5.0148632],
                            [39.8322353, -5.01425153],
                            [39.83218849, -5.01352595],
                            [39.83215885, -5.01306642],
                            [39.83200595, -5.01241653],
                            [39.83231235, -5.01235638],
                            [39.83330216, -5.01216208],
                            [39.83340654, -5.01213971],
                            [39.83435209, -5.01193707],
                            [39.83562701, -5.01182456],
                            [39.83583969, -5.01169163],
                            [39.83652696, -5.01126204],
                            [39.83727691, -5.01028699],
                            [39.83723942, -5.00882442],
                            [39.8374644, -5.00777436],
                            [39.83780188, -5.00679932],
                            [39.83825186, -5.00623679],
                            [39.83907681, -5.00563676],
                            [39.83928662, -5.00748158],
                            [39.83933256, -5.0079932],
                            [39.8395603, -5.01052974],
                            [39.83971344, -5.01223529],
                            [39.8397566, -5.01271596],
                            [39.84046075, -5.01244768],
                            [39.84079606, -5.01238061],
                            [39.84136608, -5.01258182],
                            [39.84139961, -5.01224647],
                            [39.84150021, -5.01154225],
                            [39.84173493, -5.01083802],
                            [39.84196964, -5.01013379],
                            [39.84253967, -5.01003318],
                            [39.84313948, -5.01012026],
                            [39.84332835, -5.00976346],
                            [39.84387399, -5.00940666],
                            [39.84423075, -5.00837822],
                            [39.8444616, -5.00758067],
                            [39.84473442, -5.00686706],
                            [39.84509118, -5.00686706],
                            [39.84534301, -5.00699299],
                            [39.84559485, -5.00699299],
                            [39.84574175, -5.00669915],
                            [39.84576274, -5.0062584],
                            [39.84563682, -5.00588061],
                            [39.84532203, -5.00525095],
                            [39.84539057, -5.00445585],
                            [39.84542696, -5.00403363],
                            [39.84563682, -5.00388671],
                            [39.84562211, -5.00442376],
                            [39.84559485, -5.00541886],
                            [39.84578372, -5.00518799],
                            [39.84605654, -5.005167],
                            [39.84632936, -5.00502008],
                            [39.84660218, -5.00499909],
                            [39.8471688, -5.00474723],
                            [39.84752557, -5.00468427],
                            [39.84752557, -5.00504107],
                            [39.84712683, -5.00510404],
                            [39.84685401, -5.00527194],
                            [39.84666514, -5.0055238],
                            [39.84653922, -5.00588061],
                            [39.84691697, -5.00588061],
                            [39.84714782, -5.00588061],
                            [39.84717204, -5.0064498],
                            [39.84739965, -5.00682509],
                            [39.84773542, -5.00699299],
                            [39.8482181, -5.00688805],
                            [39.84863782, -5.00711893],
                            [39.84853289, -5.00745474],
                            [39.84819712, -5.00781154],
                            [39.84794528, -5.00823131],
                            [39.84779838, -5.00898689],
                            [39.84784035, -5.00980544],
                            [39.84784035, -5.01035114],
                            [39.84766726, -5.01085662],
                            [39.84807119, -5.0116944],
                            [39.84815514, -5.01276481],
                            [39.84840697, -5.01293271],
                            [39.84899458, -5.01301667],
                            [39.84981303, -5.01339446],
                            [39.85064969, -5.01354656],
                            [39.85096726, -5.01410807],
                            [39.85149192, -5.01415005],
                            [39.85170178, -5.01406609],
                            [39.85270911, -5.01427598],
                            [39.85380038, -5.01454883],
                            [39.85388371, -5.01435604],
                            [39.8538621, -5.01390206],
                            [39.85390533, -5.01364265],
                            [39.85391609, -5.01361342],
                            [39.85405664, -5.0132319],
                            [39.8542728, -5.01297249],
                            [39.85470511, -5.01273469],
                            [39.85502934, -5.01256175],
                            [39.85539681, -5.01206453],
                            [39.85537326, -5.01151675],
                            [39.85554811, -5.01180512],
                            [39.85559135, -5.01217262],
                            [39.85556845, -5.01220315],
                            [39.85533196, -5.01251851],
                            [39.85518065, -5.0128644],
                            [39.85505096, -5.01331838],
                            [39.8549164, -5.01412201],
                            [39.85531288, -5.01487353],
                            [39.85560702, -5.01543106],
                            [39.85589027, -5.01596793],
                            [39.85628304, -5.0167124],
                            [39.85669373, -5.01714477],
                            [39.85663654, -5.01799508],
                            [39.85684504, -5.01839861],
                            [39.85723412, -5.01876612],
                            [39.85753674, -5.01896068],
                            [39.8575057, -5.01924562],
                            [39.85760049, -5.01942656],
                            [39.85774775, -5.01970764],
                            [39.85791249, -5.02002208],
                            [39.85799066, -5.02017129],
                            [39.85856786, -5.02074709],
                            [39.85865605, -5.02071194],
                            [39.85878712, -5.02073424],
                            [39.85881585, -5.02084935],
                            [39.85886399, -5.02104222],
                            [39.85877837, -5.02123795],
                            [39.85860949, -5.02132264],
                            [39.8586711, -5.02157682],
                            [39.8587906, -5.02206979],
                            [39.85907192, -5.02216765],
                            [39.85923093, -5.02232668],
                            [39.85961011, -5.02247347],
                            [39.85998929, -5.02264474],
                            [39.86017157, -5.02272576],
                            [39.86042962, -5.02284046],
                            [39.86074821, -5.02314514],
                            [39.8608274, -5.02316538],
                            [39.86090659, -5.02318562],
                            [39.86105563, -5.02314303],
                            [39.86121532, -5.0230898],
                            [39.86136436, -5.0230898],
                            [39.86154534, -5.02313239],
                            [39.86166244, -5.02318562],
                            [39.86179307, -5.02323454],
                            [39.86208406, -5.02329921],
                            [39.86227805, -5.0232022],
                            [39.86253671, -5.02300819],
                            [39.86268221, -5.02295968],
                            [39.86279537, -5.0227495],
                            [39.86282771, -5.0223938],
                            [39.8631187, -5.02207044],
                            [39.86328036, -5.02171475],
                            [39.86339353, -5.0211327],
                            [39.86357135, -5.02059915],
                            [39.86376535, -5.01993626],
                            [39.86383002, -5.01945122],
                            [39.86391085, -5.01903085],
                            [39.86410484, -5.01870749],
                            [39.86413718, -5.01856198],
                            [39.86402401, -5.01843263],
                            [39.86363602, -5.01848114],
                            [39.86349053, -5.01830329],
                            [39.86357136, -5.01801226],
                            [39.86378152, -5.01754339],
                            [39.86407251, -5.01751105],
                            [39.8642282, -5.01788032],
                            [39.86428267, -5.01843263],
                            [39.86457366, -5.0184003],
                            [39.86502632, -5.01833563],
                            [39.86533348, -5.01825479],
                            [39.86551131, -5.01809311],
                            [39.86552747, -5.01776975],
                            [39.86567297, -5.01770507],
                            [39.8658508, -5.01780208],
                            [39.86611912, -5.01794368],
                            [39.86614179, -5.01809311],
                            [39.86601246, -5.01814161],
                            [39.86591546, -5.01823862],
                            [39.86581846, -5.01859432],
                            [39.8657053, -5.01888534],
                            [39.8654628, -5.01925721],
                            [39.86526881, -5.0195644],
                            [39.86522763, -5.01963928],
                            [39.86509098, -5.01988776],
                            [39.86492386, -5.01996382],
                            [39.86483526, -5.02000415],
                            [39.86486067, -5.02023683],
                            [39.86488082, -5.0204213],
                            [39.86478382, -5.02066382],
                            [39.86465449, -5.02093868],
                            [39.8644605, -5.02134288],
                            [39.86423417, -5.02176325],
                            [39.86410023, -5.02194425],
                            [39.86412552, -5.02224102],
                            [39.86413717, -5.02237764],
                            [39.86425033, -5.02244231],
                            [39.86446546, -5.0226242],
                            [39.86447914, -5.02277145],
                            [39.86450985, -5.02310212],
                            [39.86452516, -5.02326688],
                            [39.86473532, -5.02328305],
                            [39.86475208, -5.02327373],
                            [39.86488082, -5.02320221],
                            [39.86504248, -5.02312137],
                            [39.86523647, -5.02302436],
                            [39.86549513, -5.02302436],
                            [39.86583462, -5.02313754],
                            [39.86600616, -5.02317185],
                            [39.86607712, -5.02318604],
                            [39.86605384, -5.02306966],
                            [39.86604478, -5.02302436],
                            [39.86607712, -5.02283034],
                            [39.86617411, -5.02263633],
                            [39.86622903, -5.02260138],
                            [39.86635194, -5.02252315],
                            [39.86627079, -5.02242035],
                            [39.86610945, -5.02221596],
                            [39.86633578, -5.0219411],
                            [39.86649509, -5.02181497],
                            [39.86672377, -5.02163391],
                            [39.8669016, -5.02119737],
                            [39.86683599, -5.02109895],
                            [39.8668046, -5.02105186],
                            [39.8667076, -5.02080934],
                            [39.86659444, -5.02045364],
                            [39.8667561, -5.02013028],
                            [39.86681724, -5.01990607],
                            [39.8668531, -5.01977459],
                            [39.86714409, -5.01954823],
                            [39.86732192, -5.01935422],
                            [39.86712793, -5.01907936],
                            [39.86724109, -5.01890151],
                            [39.86732192, -5.01872366],
                            [39.86724109, -5.01841647],
                            [39.86737042, -5.0179476],
                            [39.86738659, -5.01755956],
                            [39.86746742, -5.01734938],
                            [39.86769375, -5.01731704],
                            [39.86764525, -5.01717153],
                            [39.86754825, -5.01710686],
                            [39.86754825, -5.01676733],
                            [39.86754825, -5.01628229],
                            [39.86758059, -5.01599126],
                            [39.86788774, -5.01594276],
                            [39.86803324, -5.01568407],
                            [39.86821594, -5.01547449],
                            [39.86830297, -5.01546994],
                            [39.86848424, -5.01537453],
                            [39.86856056, -5.01524095],
                            [39.86850332, -5.01494516],
                            [39.86849378, -5.01479249],
                            [39.86848424, -5.01452533],
                            [39.86846516, -5.01395284],
                            [39.86836975, -5.01357118],
                            [39.86836975, -5.01325631],
                            [39.86824573, -5.01296052],
                            [39.86826481, -5.01277923],
                            [39.86824573, -5.01251207],
                            [39.868427, -5.01231169],
                            [39.86863689, -5.0122449],
                            [39.86900897, -5.01216857],
                            [39.86931426, -5.01205407],
                            [39.86944783, -5.01189187],
                            [39.86934288, -5.01172012],
                            [39.8693715, -5.01161516],
                            [39.86968634, -5.01138617],
                            [39.8697722, -5.01112855],
                            [39.86984853, -5.01074688],
                            [39.87001072, -5.01037476],
                            [39.87008704, -5.0099454],
                            [39.87030647, -5.00977365],
                            [39.87050682, -5.00962098],
                            [39.87055453, -5.00936336],
                            [39.87029693, -5.00889583],
                            [39.87015383, -5.008476],
                            [39.87037326, -5.00827563],
                            [39.87052591, -5.0079703],
                            [39.87044004, -5.00759818],
                            [39.87039234, -5.00735964],
                            [39.87037326, -5.00718789],
                            [39.87042096, -5.00696844],
                            [39.87050683, -5.0067299],
                            [39.87055453, -5.00655815],
                            [39.87054499, -5.00632915],
                            [39.87050683, -5.00615741],
                            [39.87050683, -5.00600474],
                            [39.87052591, -5.00575666],
                            [39.87041142, -5.00558491],
                            [39.8702874, -5.00545133],
                            [39.87037326, -5.00516509],
                            [39.87051637, -5.00496471],
                            [39.87054499, -5.00474526],
                            [39.87056407, -5.00460214],
                            [39.87072626, -5.00446855],
                            [39.87088845, -5.00433497],
                            [39.87110788, -5.0041346],
                            [39.87129869, -5.00383881],
                            [39.87129869, -5.00342853],
                            [39.87131777, -5.00318999],
                            [39.87119375, -5.00276062],
                            [39.87116512, -5.00258887],
                            [39.87107926, -5.0026175],
                            [39.87084075, -5.00251254],
                            [39.87074534, -5.00235033],
                            [39.87069764, -5.00216904],
                            [39.8707358, -5.002045],
                            [39.87092661, -5.00194005],
                            [39.87120329, -5.00201638],
                            [39.87143226, -5.00209271],
                            [39.87167077, -5.00213088],
                            [39.87178525, -5.00204501],
                            [39.87189974, -5.00196867],
                            [39.87200469, -5.00184463],
                            [39.87196652, -5.00166334],
                            [39.8713941, -5.00150114],
                            [39.87132731, -5.00125306],
                            [39.87132731, -5.00113856],
                            [39.87133685, -5.00090956],
                            [39.87137502, -5.0007569],
                            [39.87163261, -5.00053744],
                            [39.87169939, -5.00048019],
                            [39.8717471, -5.00034661],
                            [39.8717471, -5.00011761],
                            [39.87178526, -4.99998403],
                            [39.87188066, -4.99989816],
                            [39.87193791, -4.99977412],
                            [39.87193791, -4.99959283],
                            [39.87196653, -4.99947833],
                            [39.87205239, -4.99936383],
                            [39.87206193, -4.99921117],
                            [39.87211917, -4.99908713],
                            [39.87227182, -4.99896309],
                            [39.87242447, -4.99881043],
                            [39.87234815, -4.99849555],
                            [39.87218596, -4.99803756],
                            [39.8722909, -4.99791352],
                            [39.87244355, -4.99767498],
                            [39.87279655, -4.99752232],
                            [39.87272023, -4.99736011],
                            [39.8729015, -4.99715974],
                            [39.8730446, -4.9970357],
                            [39.87328311, -4.9969212],
                            [39.87323541, -4.99673037],
                            [39.87297782, -4.99653],
                            [39.87292058, -4.99616742],
                            [39.87293012, -4.99566172],
                            [39.87297782, -4.99524189],
                            [39.87296828, -4.99490794],
                            [39.87293966, -4.99460261],
                            [39.87293012, -4.99443086],
                            [39.87302553, -4.9941637],
                            [39.87347393, -4.99417324],
                            [39.87376968, -4.99403966],
                            [39.87392233, -4.9938202],
                            [39.87389371, -4.99358167],
                            [39.87386509, -4.99334313],
                            [39.87389371, -4.99307596],
                            [39.87402728, -4.99279926],
                            [39.87393187, -4.99270384],
                            [39.87391279, -4.99234127],
                            [39.87384601, -4.99216952],
                            [39.87397003, -4.9919596],
                            [39.87417992, -4.99177832],
                            [39.87432303, -4.99163519],
                            [39.87434211, -4.99150161],
                            [39.87436119, -4.99136803],
                            [39.87441844, -4.9909482],
                            [39.87438982, -4.99082416],
                            [39.87428487, -4.9906715],
                            [39.87423717, -4.99038525],
                            [39.87444706, -4.9900513],
                            [39.87451384, -4.98966964],
                            [39.87468557, -4.98912577],
                            [39.87470465, -4.98889677],
                            [39.87465695, -4.98868686],
                            [39.87455201, -4.98833382],
                            [39.87446614, -4.9880762],
                            [39.87453293, -4.98789491],
                            [39.87452339, -4.98767546],
                            [39.87446614, -4.98743692],
                            [39.87457109, -4.98713159],
                            [39.87451385, -4.98690259],
                            [39.87463787, -4.98667359],
                            [39.87465276, -4.98649765],
                            [39.87467385, -4.98646934],
                            [39.87469494, -4.98644104],
                            [39.87468865, -4.98637816],
                            [39.87465093, -4.98624613],
                            [39.87459435, -4.98608266],
                            [39.87448119, -4.98593806],
                            [39.87451891, -4.9858689],
                            [39.87463836, -4.98585632],
                            [39.87468865, -4.98574315],
                            [39.8747138, -4.98563627],
                            [39.87476409, -4.98554825],
                            [39.87477037, -4.98540364],
                            [39.87481438, -4.98526532],
                            [39.87485839, -4.985127],
                            [39.87476409, -4.98505784],
                            [39.87468236, -4.98498868],
                            [39.87463836, -4.98488809],
                            [39.87465722, -4.98480007],
                            [39.87465093, -4.9846869],
                            [39.87453149, -4.98464289],
                            [39.87446234, -4.98455486],
                            [39.87455035, -4.98444169],
                            [39.87468865, -4.98433481],
                            [39.87467544, -4.98401563],
                            [39.87462296, -4.98371821],
                            [39.87462296, -4.9833858],
                            [39.87460547, -4.98298342],
                            [39.87464045, -4.98247606],
                            [39.87485037, -4.98219614],
                            [39.87486786, -4.98195121],
                            [39.8747804, -4.98165379],
                            [39.87499032, -4.98123391],
                            [39.87515907, -4.98088102],
                            [39.87518274, -4.98083152],
                            [39.8753052, -4.98041164],
                            [39.87525272, -4.97967684],
                            [39.87527021, -4.97925696],
                            [39.87524527, -4.97914056],
                            [39.87516525, -4.9787671],
                            [39.87488536, -4.97848718],
                            [39.8745355, -4.97866213],
                            [39.87425561, -4.97860964],
                            [39.87395823, -4.97848718],
                            [39.87348591, -4.97834721],
                            [39.87357338, -4.97811978],
                            [39.87362586, -4.97773489],
                            [39.8737833, -4.97754244],
                            [39.87367834, -4.97719254],
                            [39.87369583, -4.97677266],
                            [39.87415065, -4.97637027],
                            [39.87425561, -4.9759154],
                            [39.87408433, -4.97578662],
                            [39.87401276, -4.97564346],
                            [39.87406388, -4.97540828],
                            [39.87403321, -4.97512197],
                            [39.874115, -4.97478453],
                            [39.8742873, -4.97458113],
                            [39.8741227, -4.97416959],
                            [39.87408155, -4.97404612],
                            [39.87383465, -4.97371688],
                            [39.8739581, -4.97301725],
                            [39.87391695, -4.97223531],
                            [39.87358775, -4.97108298],
                            [39.87354364, -4.9709963],
                            [39.87349953, -4.97090962],
                            [39.8732802, -4.97061129],
                            [39.87299945, -4.97028664],
                            [39.87291171, -4.97015502],
                            [39.87278011, -4.96993566],
                            [39.87275379, -4.96956714],
                            [39.8727538, -4.96930391],
                            [39.87279766, -4.96900558],
                            [39.87277134, -4.96861951],
                            [39.87264852, -4.96844402],
                            [39.8727187, -4.96816324],
                            [39.87285908, -4.96782982],
                            [39.87292926, -4.96761923],
                            [39.87296436, -4.96721561],
                            [39.87299068, -4.96703135],
                            [39.87286785, -4.9667418],
                            [39.87290295, -4.96637327],
                            [39.87292927, -4.96615391],
                            [39.87294681, -4.9659872],
                            [39.87298191, -4.96582049],
                            [39.87294681, -4.96563623],
                            [39.87291172, -4.96546074],
                            [39.87296436, -4.96532913],
                            [39.87294681, -4.96517119],
                            [39.87293804, -4.96493428],
                            [39.87289418, -4.96471492],
                            [39.87286786, -4.96443414],
                            [39.87286786, -4.96421478],
                            [39.87304332, -4.96397788],
                            [39.87313983, -4.96375852],
                            [39.87323634, -4.96338999],
                            [39.87322757, -4.96326715],
                            [39.87313983, -4.96295128],
                            [39.87306087, -4.96258275],
                            [39.87311351, -4.96223178],
                            [39.87313983, -4.96189835],
                            [39.87311351, -4.9616439],
                            [39.87307842, -4.96136312],
                            [39.87306965, -4.96117008],
                            [39.87299946, -4.96103847],
                            [39.87290295, -4.96074891],
                            [39.87284154, -4.96055588],
                            [39.87277135, -4.96030142],
                            [39.8727889, -4.96014348],
                            [39.87268362, -4.95999432],
                            [39.87263975, -4.95973986],
                            [39.87261343, -4.95963457],
                            [39.87258711, -4.95952928],
                            [39.87241165, -4.9592134],
                            [39.87234146, -4.95904669],
                            [39.87224495, -4.95874836],
                            [39.87227127, -4.95832719],
                            [39.87241165, -4.95806396],
                            [39.87232392, -4.95796744],
                            [39.87223052, -4.95766176],
                            [39.87222741, -4.95765156],
                            [39.87222744, -4.95765147],
                            [39.87222746, -4.95765144],
                            [39.87222747, -4.95765139],
                            [39.87222756, -4.95765114],
                            [39.87222998, -4.95764449],
                            [39.87223484, -4.95763113],
                            [39.87223969, -4.95761778],
                            [39.87224121, -4.95761362],
                            [39.87224979, -4.95759],
                            [39.87233269, -4.95736201],
                            [39.87248184, -4.95702859],
                            [39.87235901, -4.95674781],
                            [39.87230637, -4.95640561],
                            [39.87233269, -4.95604586],
                            [39.87237656, -4.95587915],
                            [39.87236779, -4.95571243],
                            [39.87248184, -4.95542288],
                            [39.87249061, -4.95505436],
                            [39.87251693, -4.95480867],
                            [39.87255203, -4.9546639],
                            [39.87258712, -4.95451912],
                            [39.87258273, -4.95444454],
                            [39.87257835, -4.95436996],
                            [39.87253448, -4.95428221],
                            [39.87248184, -4.95409795],
                            [39.87242043, -4.95400143],
                            [39.87240288, -4.95391369],
                            [39.8722976, -4.95379962],
                            [39.87225373, -4.95364169],
                            [39.87227266, -4.95344622],
                            [39.87231515, -4.95342233],
                            [39.87225373, -4.95331703],
                            [39.87223619, -4.95317664],
                            [39.87228883, -4.95304503],
                            [39.87237656, -4.95282567],
                            [39.87237656, -4.95275548],
                            [39.87235902, -4.95263263],
                            [39.87230638, -4.9524396],
                            [39.87233108, -4.95232485],
                            [39.87249939, -4.95222024],
                            [39.87270118, -4.95217637],
                            [39.87271873, -4.95189559],
                            [39.87264854, -4.95186927],
                            [39.87255203, -4.95182539],
                            [39.87239411, -4.95173765],
                            [39.87238559, -4.95163693],
                            [39.87243618, -4.95160369],
                            [39.87249939, -4.95156216],
                            [39.87253449, -4.95145687],
                            [39.87253449, -4.95135158],
                            [39.87255203, -4.95109712],
                            [39.8725959, -4.95084267],
                            [39.87262222, -4.95057066],
                            [39.87257835, -4.95047414],
                            [39.87251694, -4.95043027],
                            [39.87244675, -4.95036008],
                            [39.87242043, -4.95032498],
                            [39.87237657, -4.95027233],
                            [39.87235902, -4.95020214],
                            [39.87235022, -4.950117],
                            [39.87240373, -4.95007736],
                            [39.87247276, -4.95002623],
                            [39.87254326, -4.94997401],
                            [39.87253449, -4.94985994],
                            [39.87249939, -4.94979852],
                            [39.87243798, -4.9497371],
                            [39.87232393, -4.949702],
                            [39.87226251, -4.94965813],
                            [39.87225374, -4.94958793],
                            [39.87225374, -4.94944754],
                            [39.87226251, -4.94928961],
                            [39.87228006, -4.94913167],
                            [39.87221865, -4.94907902],
                            [39.87219313, -4.9489706],
                            [39.87229761, -4.94888599],
                            [39.8723327, -4.94866663],
                            [39.87219709, -4.94836933],
                            [39.87212446, -4.94813687],
                            [39.8720954, -4.94778818],
                            [39.87211439, -4.94765197],
                            [39.87215036, -4.94739404],
                            [39.87234236, -4.94722157],
                            [39.87240047, -4.94703269],
                            [39.87235157, -4.9469486],
                            [39.87222531, -4.94691799],
                            [39.87210288, -4.94687973],
                            [39.8720187, -4.94683764],
                            [39.87191157, -4.9468185],
                            [39.87187714, -4.9467458],
                            [39.87186949, -4.94668075],
                            [39.87181975, -4.94665397],
                            [39.87171645, -4.94663101],
                            [39.87165523, -4.94659274],
                            [39.87154045, -4.94648177],
                            [39.87154045, -4.9463555],
                            [39.87146775, -4.94619862],
                            [39.87145245, -4.94607234],
                            [39.87155958, -4.94594224],
                            [39.87164758, -4.94586189],
                            [39.87165107, -4.94582529],
                            [39.87164407, -4.94575992],
                            [39.87162306, -4.94568755],
                            [39.87159272, -4.94562686],
                            [39.87156938, -4.94555215],
                            [39.8715437, -4.9454331],
                            [39.87150869, -4.94535139],
                            [39.87149235, -4.945237],
                            [39.87152503, -4.94519031],
                            [39.87156704, -4.94509927],
                            [39.87178367, -4.94500278],
                            [39.8718434, -4.94484347],
                            [39.87186331, -4.94463438],
                            [39.87195291, -4.94444521],
                            [39.87169407, -4.94443525],
                            [39.871485, -4.94429586],
                            [39.87133567, -4.9442162],
                            [39.87127594, -4.94407681],
                            [39.87132572, -4.94387768],
                            [39.87130581, -4.94363872],
                            [39.87133166, -4.94353912],
                            [39.87135751, -4.94343951],
                            [39.87144496, -4.94322571],
                            [39.87161987, -4.94293417],
                            [39.87164902, -4.94274952],
                            [39.87164902, -4.94261347],
                            [39.871581, -4.94249685],
                            [39.87140609, -4.94233164],
                            [39.87127006, -4.94219559],
                            [39.87129568, -4.94208488],
                            [39.87135751, -4.94206925],
                            [39.8714644, -4.94188461],
                            [39.87145468, -4.94173884],
                            [39.87147411, -4.94165138],
                            [39.87139638, -4.94156391],
                            [39.87129921, -4.94147645],
                            [39.87128949, -4.94134039],
                            [39.87133808, -4.94120434],
                            [39.87132836, -4.94104885],
                            [39.87134779, -4.94089336],
                            [39.87145468, -4.94076703],
                            [39.87150107, -4.94058346],
                            [39.87161015, -4.94053379],
                            [39.87157129, -4.94031999],
                            [39.87166846, -4.94014507],
                            [39.87141581, -4.93966888],
                            [39.87131864, -4.93928987],
                            [39.87150327, -4.93909551],
                            [39.8715227, -4.93908579],
                            [39.87132836, -4.93873594],
                            [39.87127671, -4.93838927],
                            [39.87136723, -4.93831806],
                            [39.87129921, -4.93763779],
                            [39.87124091, -4.9372685],
                            [39.87121176, -4.93689921],
                            [39.87138667, -4.9366271],
                            [39.87131865, -4.93633556],
                            [39.87136723, -4.93610232],
                            [39.87141582, -4.93586909],
                            [39.87136723, -4.93552895],
                            [39.87136724, -4.93521797],
                            [39.8714061, -4.9350722],
                            [39.87163931, -4.93459601],
                            [39.87159073, -4.93385743],
                            [39.87156958, -4.93342865],
                            [39.87171705, -4.93321604],
                            [39.87191139, -4.93272041],
                            [39.87197941, -4.93215676],
                            [39.87187293, -4.93128822],
                            [39.87211545, -4.93088369],
                            [39.87203772, -4.9305144],
                            [39.87193083, -4.92997018],
                            [39.87205494, -4.92954215],
                            [39.87222234, -4.92923161],
                            [39.87240697, -4.92870683],
                            [39.87226121, -4.92824036],
                            [39.87237782, -4.9277836],
                            [39.87241669, -4.92720052],
                            [39.87257216, -4.92668546],
                            [39.87274707, -4.92598575],
                            [39.87262075, -4.92546097],
                            [39.87253329, -4.92473211],
                            [39.87237782, -4.9241879],
                            [39.87227093, -4.92377002],
                            [39.87211546, -4.92313834],
                            [39.8720766, -4.92282736],
                            [39.87219402, -4.92228519],
                            [39.87236811, -4.92180695],
                            [39.87236819, -4.92136967],
                            [39.87246906, -4.92108707],
                            [39.87256245, -4.92082542],
                            [39.87273736, -4.92061162],
                            [39.87251387, -4.92049501],
                            [39.87239726, -4.92029093],
                            [39.87257198, -4.92010323],
                            [39.87268877, -4.91976615],
                            [39.87261104, -4.91906644],
                            [39.87252359, -4.91808491],
                            [39.87249444, -4.91734633],
                            [39.8724167, -4.91696732],
                            [39.87230981, -4.91658832],
                            [39.87212628, -4.91615709],
                            [39.87221264, -4.91584974],
                            [39.87233896, -4.91562622],
                            [39.87216406, -4.91535411],
                            [39.87214362, -4.91512949],
                            [39.87225151, -4.91473215],
                            [39.87206445, -4.91422618],
                            [39.87218394, -4.91376764],
                            [39.87242642, -4.91283712],
                            [39.87270822, -4.9120208],
                            [39.87280539, -4.91103927],
                            [39.87289284, -4.91004802],
                            [39.87294143, -4.90951352],
                            [39.87297058, -4.90889156],
                            [39.87303617, -4.90833032],
                            [39.87305804, -4.90814326],
                            [39.87321153, -4.90717866],
                            [39.87334955, -4.9066078],
                            [39.87334955, -4.9060733],
                            [39.87333012, -4.90545134],
                            [39.87325238, -4.9048391],
                            [39.87324266, -4.90406165],
                            [39.87322054, -4.90334617],
                            [39.87321351, -4.90311899],
                            [39.8732038, -4.9021569],
                            [39.87303861, -4.90112677],
                            [39.87284427, -4.90037848],
                            [39.87260135, -4.89970793],
                            [39.87228068, -4.89915399],
                            [39.87212035, -4.89894991],
                            [39.87196002, -4.89874583],
                            [39.8716588, -4.89840569],
                            [39.87115351, -4.89784204],
                            [39.8708037, -4.89739501],
                            [39.87068457, -4.89723443],
                            [39.87058021, -4.89709375],
                            [39.87044417, -4.89686051],
                            [39.87041502, -4.89644263],
                            [39.87053162, -4.8962677],
                            [39.87044417, -4.89607334],
                            [39.87037615, -4.89592757],
                            [39.87027898, -4.89577208],
                            [39.87013322, -4.89554856],
                            [39.87022068, -4.89532505],
                            [39.8702288, -4.89531766],
                            [39.87041807, -4.89514565],
                            [39.87044417, -4.89493632],
                            [39.87031785, -4.8948197],
                            [39.87003606, -4.89452816],
                            [39.8699486, -4.8943338],
                            [39.86984172, -4.89412],
                            [39.86975426, -4.89386733],
                            [39.86984172, -4.89366324],
                            [39.86975426, -4.89349804],
                            [39.86965709, -4.89327452],
                            [39.86960851, -4.89307044],
                            [39.86969884, -4.89282983],
                            [39.8697154, -4.892672],
                            [39.86979313, -4.89254566],
                            [39.86993889, -4.89247763],
                            [39.87002404, -4.89244065],
                            [39.87003606, -4.89233186],
                            [39.86990002, -4.89226383],
                            [39.86980285, -4.89211806],
                            [39.86975924, -4.89196106],
                            [39.8697746, -4.89181385],
                            [39.86978342, -4.89172934],
                            [39.86961823, -4.89171962],
                            [39.86952106, -4.89165159],
                            [39.86935277, -4.89155225],
                            [39.86936558, -4.89141836],
                            [39.8692587, -4.89123371],
                            [39.86916153, -4.89113653],
                            [39.86908379, -4.89101991],
                            [39.86902549, -4.89089358],
                            [39.86888945, -4.89083527],
                            [39.86879228, -4.89077696],
                            [39.86868129, -4.89064791],
                            [39.86869211, -4.89051512],
                            [39.86870483, -4.89035908],
                            [39.86864216, -4.8902285],
                            [39.86866596, -4.88993148],
                            [39.86859794, -4.88971768],
                            [39.86850077, -4.88953304],
                            [39.8684148, -4.88938803],
                            [39.86811251, -4.88866245],
                            [39.86801175, -4.88813841],
                            [39.86818513, -4.8880037],
                            [39.86821328, -4.88759422],
                            [39.86797144, -4.88757406],
                            [39.86774976, -4.88749344],
                            [39.86748777, -4.88741282],
                            [39.86736685, -4.88735236],
                            [39.86712502, -4.88721127],
                            [39.86698908, -4.88707042],
                            [39.8670041, -4.8867477],
                            [39.86686303, -4.8867477],
                            [39.8666212, -4.88668723],
                            [39.86643982, -4.88652599],
                            [39.86611737, -4.88632444],
                            [39.86595615, -4.88588102],
                            [39.86609722, -4.88561901],
                            [39.86573447, -4.88525621],
                            [39.86537172, -4.88491357],
                            [39.86502912, -4.8846314],
                            [39.8649082, -4.88459109],
                            [39.86466636, -4.88477249],
                            [39.86430361, -4.88495388],
                            [39.86400132, -4.88489342],
                            [39.8638775, -4.8851498],
                            [39.86372125, -4.8854177],
                            [39.86354267, -4.8855963],
                            [39.86334177, -4.88575257],
                            [39.8630739, -4.88588652],
                            [39.86289532, -4.88575257],
                            [39.86269442, -4.88555164],
                            [39.86260513, -4.88577489],
                            [39.86249352, -4.88588652],
                            [39.86224798, -4.88577489],
                            [39.86202475, -4.88570792],
                            [39.86184617, -4.88595349],
                            [39.86168992, -4.88624371],
                            [39.86177921, -4.88644464],
                            [39.86157831, -4.88662323],
                            [39.86126579, -4.88689113],
                            [39.86093096, -4.88715903],
                            [39.86090864, -4.8873153],
                            [39.86113186, -4.88744925],
                            [39.8613774, -4.88762785],
                            [39.86144437, -4.88789575],
                            [39.86147695, -4.88817275],
                            [39.86132739, -4.88830203],
                            [39.86113854, -4.88847102],
                            [39.86096957, -4.88861018],
                            [39.86073103, -4.88873941],
                            [39.86068133, -4.88898792],
                            [39.86042291, -4.88920661],
                            [39.86015455, -4.88934577],
                            [39.85997564, -4.88948494],
                            [39.85997564, -4.88974339],
                            [39.85983649, -4.88996208],
                            [39.85961782, -4.89021059],
                            [39.85966752, -4.89031994],
                            [39.85987625, -4.89062809],
                            [39.85997564, -4.89096607],
                            [39.85994582, -4.8912444],
                            [39.85994582, -4.89167184],
                            [39.86000545, -4.89191041],
                            [39.86038315, -4.8921788],
                            [39.8604726, -4.89256648],
                            [39.86044278, -4.89282493],
                            [39.86050242, -4.89294422],
                            [39.86057199, -4.89316291],
                            [39.86056206, -4.89337166],
                            [39.86062169, -4.8935307],
                            [39.86062687, -4.89353737],
                            [39.86076084, -4.89370963],
                            [39.86073102, -4.89395814],
                            [39.86071565, -4.89406271],
                            [39.86068133, -4.89429612],
                            [39.86058193, -4.89461421],
                            [39.86059187, -4.8947832],
                            [39.86071114, -4.89508141],
                            [39.86082047, -4.89556849],
                            [39.8607509, -4.89574742],
                            [39.86085029, -4.89600587],
                            [39.86090993, -4.89643331],
                            [39.86082047, -4.89666194],
                            [39.86071114, -4.89693034],
                            [39.86052229, -4.89715897],
                            [39.86019429, -4.8975367],
                            [39.86006508, -4.89781503],
                            [39.86016448, -4.89803372],
                            [39.86036326, -4.89835182],
                            [39.86036326, -4.89865997],
                            [39.86027381, -4.89899795],
                            [39.86006508, -4.89957449],
                            [39.85988617, -4.90022062],
                            [39.85946872, -4.90064806],
                            [39.85945878, -4.90083693],
                            [39.85964763, -4.90111526],
                            [39.85960787, -4.90136377],
                            [39.85973708, -4.90150294],
                            [39.85965757, -4.90167193],
                            [39.85944884, -4.90187073],
                            [39.85945878, -4.90208942],
                            [39.8596675, -4.90224847],
                            [39.86007502, -4.90255663],
                            [39.86015453, -4.9027952],
                            [39.85993586, -4.90327234],
                            [39.85995574, -4.90352085],
                            [39.86020423, -4.90391847],
                            [39.86050488, -4.90429648],
                            [39.8605521, -4.90435585],
                            [39.86054216, -4.90481311],
                            [39.86047326, -4.90486569],
                            [39.86016447, -4.90510138],
                            [39.86018434, -4.90541947],
                            [39.85988616, -4.90574751],
                            [39.85953548, -4.90593406],
                            [39.85941902, -4.90599602],
                            [39.85890217, -4.90625447],
                            [39.85851454, -4.90649304],
                            [39.85816666, -4.90678131],
                            [39.85796787, -4.90714911],
                            [39.85796787, -4.90756661],
                            [39.85786848, -4.90780518],
                            [39.8577989, -4.90828232],
                            [39.85767963, -4.90853083],
                            [39.85762993, -4.90889863],
                            [39.85764981, -4.9091869],
                            [39.85782872, -4.9095547],
                            [39.85794799, -4.90978333],
                            [39.85800763, -4.91052886],
                            [39.85782872, -4.91110541],
                            [39.85750072, -4.91171177],
                            [39.85731187, -4.91233802],
                            [39.85726217, -4.91265612],
                            [39.8571429, -4.91288475],
                            [39.85687454, -4.91304379],
                            [39.85640739, -4.91367998],
                            [39.85621854, -4.91414718],
                            [39.85567188, -4.91487283],
                            [39.85515503, -4.91569789],
                            [39.85497612, -4.91567801],
                            [39.85455867, -4.91584699],
                            [39.85394243, -4.91638378],
                            [39.85357468, -4.91657265],
                            [39.85329638, -4.91695038],
                            [39.85282923, -4.91756669],
                            [39.85218317, -4.91793449],
                            [39.85165638, -4.9182824],
                            [39.85103021, -4.91875954],
                            [39.84984743, -4.9195846],
                            [39.84886343, -4.92041959],
                            [39.84855531, -4.92094644],
                            [39.84839628, -4.92141364],
                            [39.84832671, -4.92196036],
                            [39.84826707, -4.92257667],
                            [39.84825713, -4.92289476],
                            [39.8484261, -4.92297429],
                            [39.84878391, -4.92318304],
                            [39.84902246, -4.92346137],
                            [39.8492113, -4.92370988],
                            [39.84930416, -4.92402564],
                            [39.84936039, -4.92421684],
                            [39.84936039, -4.9250916],
                            [39.84954924, -4.92561845],
                            [39.84972814, -4.92625463],
                            [39.84968525, -4.9267265],
                            [39.84967845, -4.92680136],
                            [39.8497182, -4.92716916],
                            [39.84981263, -4.9273282],
                            [39.84990705, -4.92748725],
                            [39.85019529, -4.9281135],
                            [39.85037375, -4.92847425],
                            [39.85066244, -4.92905784],
                            [39.85109977, -4.9304495],
                            [39.85127867, -4.93102605],
                            [39.85129471, -4.93162752],
                            [39.85129855, -4.93177158],
                            [39.85133831, -4.93217914],
                            [39.85142776, -4.93247736],
                            [39.85139794, -4.93314337],
                            [39.85146752, -4.93359069],
                            [39.85153709, -4.93403801],
                            [39.85150727, -4.93500223],
                            [39.85132836, -4.93527062],
                            [39.85115939, -4.93589687],
                            [39.85121903, -4.93652312],
                            [39.85155697, -4.93708972],
                            [39.85156691, -4.937378],
                            [39.85158678, -4.9376613],
                            [39.85160666, -4.9379446],
                            [39.85153709, -4.93819311],
                            [39.85152715, -4.93896847],
                            [39.85154702, -4.93964442],
                            [39.8515172, -4.94006192],
                            [39.85162654, -4.94038001],
                            [39.85176569, -4.94065834],
                            [39.85187502, -4.94126471],
                            [39.85186508, -4.94180149],
                            [39.85186039, -4.94181569],
                            [39.85185006, -4.94184693],
                            [39.85182956, -4.94188382],
                            [39.85186372, -4.94201621],
                            [39.85186158, -4.94209522],
                            [39.85185945, -4.94217423],
                            [39.85199609, -4.94236214],
                            [39.85214555, -4.94250307],
                            [39.85226938, -4.94267389],
                            [39.85238819, -4.94281188],
                            [39.85240176, -4.94282764],
                            [39.85262808, -4.94291732],
                            [39.85280743, -4.94304544],
                            [39.85290991, -4.94319492],
                            [39.8530551, -4.94337855],
                            [39.85323444, -4.94356219],
                            [39.85328141, -4.94363052],
                            [39.85343087, -4.94371594],
                            [39.85354189, -4.94383979],
                            [39.85369562, -4.94396791],
                            [39.853828, -4.94409176],
                            [39.85387497, -4.9442156],
                            [39.85391767, -4.94426899],
                            [39.85396037, -4.94432237],
                            [39.85406713, -4.94447184],
                            [39.85416961, -4.9446384],
                            [39.85421232, -4.94474836],
                            [39.8543998, -4.94513101],
                            [39.85451458, -4.94533381],
                            [39.85456814, -4.94555192],
                            [39.85462936, -4.94569733],
                            [39.85470588, -4.94583891],
                            [39.85471353, -4.9460264],
                            [39.85463319, -4.94629425],
                            [39.85462936, -4.94660037],
                            [39.85452606, -4.94666542],
                            [39.85451513, -4.94670778],
                            [39.85449545, -4.94678404],
                            [39.8546064, -4.94691797],
                            [39.85474031, -4.94714756],
                            [39.85466762, -4.9474001],
                            [39.85457579, -4.94763352],
                            [39.85446866, -4.94805826],
                            [39.85445653, -4.94808638],
                            [39.85455454, -4.94838046],
                            [39.85458127, -4.94867453],
                            [39.85470601, -4.94890622],
                            [39.85475056, -4.94916464],
                            [39.85467037, -4.94946762],
                            [39.85461691, -4.94972605],
                            [39.85452781, -4.94993992],
                            [39.85492877, -4.95050132],
                            [39.85508915, -4.95085777],
                            [39.85503569, -4.95149938],
                            [39.8548753, -4.95187365],
                            [39.85482184, -4.95251526],
                            [39.85442979, -4.9525509],
                            [39.85416249, -4.95271131],
                            [39.85375262, -4.95249744],
                            [39.85355659, -4.95226574],
                            [39.85341403, -4.95233703],
                            [39.85337839, -4.95280042],
                            [39.85337839, -4.95313904],
                            [39.85328928, -4.95344202],
                            [39.85289723, -4.95344202],
                            [39.85264775, -4.95360242],
                            [39.8522557, -4.95411927],
                            [39.85195275, -4.95463612],
                            [39.85195275, -4.95527773],
                            [39.852053, -4.95520374],
                            [39.85200621, -4.95586587],
                            [39.85198597, -4.95627745],
                            [39.85195275, -4.95695304],
                            [39.85196807, -4.95741271],
                            [39.85197057, -4.95748771],
                            [39.85173996, -4.9582517],
                            [39.85168544, -4.9584323],
                            [39.85134685, -4.95875311],
                            [39.85152505, -4.95937689],
                            [39.85172108, -4.95964423],
                            [39.85140031, -4.95975116],
                            [39.85118646, -4.95978681],
                            [39.8508657, -4.95982245],
                            [39.85042019, -4.95998285],
                            [39.85013506, -4.96023237],
                            [39.85005338, -4.96048896],
                            [39.84981955, -4.9605825],
                            [39.84955455, -4.96067604],
                            [39.84913366, -4.9605825],
                            [39.84905572, -4.96090989],
                            [39.84892516, -4.96110824],
                            [39.84860366, -4.96094107],
                            [39.84844778, -4.96117492],
                            [39.84844778, -4.96151791],
                            [39.84824513, -4.96167381],
                            [39.84796454, -4.9616894],
                            [39.84763718, -4.96140877],
                            [39.84738777, -4.9612061],
                            [39.84704483, -4.96139318],
                            [39.84671747, -4.96164262],
                            [39.84656159, -4.96198561],
                            [39.84648238, -4.96203727],
                            [39.84620306, -4.96221946],
                            [39.84603158, -4.96256244],
                            [39.84582893, -4.96299896],
                            [39.84578217, -4.96326399],
                            [39.84587107, -4.96337163],
                            [39.84607835, -4.96362256],
                            [39.84579096, -4.96371187],
                            [39.84548515, -4.9638069],
                            [39.84489642, -4.96438673],
                            [39.84485702, -4.96447189],
                            [39.84444265, -4.96536748],
                            [39.84384405, -4.96564972],
                            [39.84346241, -4.96600414],
                            [39.84335294, -4.9661058],
                            [39.84322254, -4.96641973],
                            [39.84306047, -4.96590895],
                            [39.84296707, -4.96561463],
                            [39.84296707, -4.96498314],
                            [39.84289692, -4.96463231],
                            [39.84265136, -4.9642464],
                            [39.84251105, -4.96357982],
                            [39.8424575, -4.963536],
                            [39.84212518, -4.96326407],
                            [39.84131836, -4.96357982],
                            [39.84100648, -4.96378776],
                            [39.8405817, -4.96407098],
                            [39.84041853, -4.96483794],
                            [39.84054662, -4.9656848],
                            [39.84041188, -4.96626624],
                            [39.84028306, -4.9668221],
                            [39.84015537, -4.9673731],
                            [39.83938901, -4.96750911],
                            [39.83903822, -4.96775469],
                            [39.83858219, -4.96803535],
                            [39.83833664, -4.96789502],
                            [39.83765913, -4.96791289],
                            [39.83758564, -4.9680415],
                            [39.83755349, -4.9681747],
                            [39.83750757, -4.96833087],
                            [39.83745705, -4.96843192],
                            [39.83739734, -4.96865699],
                            [39.83737395, -4.96870281],
                            [39.83708333, -4.96889173],
                            [39.83682177, -4.96925504],
                            [39.83673459, -4.9694585],
                            [39.83666193, -4.96964742],
                            [39.83656022, -4.96973462],
                            [39.83639143, -4.96983032],
                            [39.8363214, -4.96985483],
                            [39.83624787, -4.9699809],
                            [39.83617084, -4.97009647],
                            [39.83601327, -4.97013499],
                            [39.83584519, -4.9701595],
                            [39.83567712, -4.97018402],
                            [39.83549153, -4.97038363],
                            [39.83530945, -4.97045016],
                            [39.83501182, -4.97047818],
                            [39.83491378, -4.97059024],
                            [39.83486476, -4.9707058],
                            [39.83485425, -4.97081086],
                            [39.83483324, -4.97096495],
                            [39.83478772, -4.9710525],
                            [39.83475271, -4.97114355],
                            [39.8347387, -4.97126261],
                            [39.83466517, -4.97128362],
                            [39.83461264, -4.9712451],
                            [39.83459169, -4.97124742],
                            [39.83457074, -4.97124975],
                            [39.83451072, -4.97107144],
                            [39.83418404, -4.9710341],
                            [39.8339227, -4.97104343],
                            [39.83368935, -4.97115545],
                            [39.83354935, -4.9712768],
                            [39.83353842, -4.97143003],
                            [39.83340935, -4.97162218],
                            [39.83321334, -4.97167819],
                            [39.83305467, -4.9717902],
                            [39.832924, -4.9719769],
                            [39.83273732, -4.97206091],
                            [39.83260709, -4.97207888],
                            [39.83246665, -4.97209825],
                            [39.83232664, -4.97213559],
                            [39.83219884, -4.97211759],
                            [39.83176955, -4.97205171],
                            [39.83162548, -4.97203403],
                            [39.83146194, -4.97187865],
                            [39.83141046, -4.97165155],
                            [39.83171028, -4.9712039],
                            [39.83160064, -4.97090695],
                            [39.83162427, -4.97083445],
                            [39.83164791, -4.97076195],
                            [39.83166489, -4.97072058],
                            [39.83181854, -4.970346],
                            [39.83177589, -4.96981273],
                            [39.83202117, -4.96907681],
                            [39.83212781, -4.96877817],
                            [39.83202117, -4.96865019],
                            [39.83169057, -4.96842621],
                            [39.83132799, -4.9682769],
                            [39.831264, -4.96817024],
                            [39.83099147, -4.96812207],
                            [39.83059215, -4.96814891],
                            [39.83033621, -4.96825556],
                            [39.82995229, -4.96828756],
                            [39.82956838, -4.96834089],
                            [39.8291902, -4.96822966],
                            [39.82908151, -4.96811122],
                            [39.82906716, -4.96809558],
                            [39.82898219, -4.96807296],
                            [39.82865125, -4.96817024],
                            [39.82848062, -4.96819157],
                            [39.82824519, -4.96810857],
                            [39.82803272, -4.96789293],
                            [39.82796874, -4.96775428],
                            [39.8278621, -4.96758363],
                            [39.8278621, -4.96737032],
                            [39.82805405, -4.96727434],
                            [39.82804872, -4.96711435],
                            [39.82811804, -4.96703703],
                            [39.82808871, -4.96693037],
                            [39.82803006, -4.96683172],
                            [39.8279794, -4.96665307],
                            [39.82791009, -4.96648776],
                            [39.82785143, -4.9663571],
                            [39.82782211, -4.96621845],
                            [39.82784877, -4.96610646],
                            [39.82788076, -4.96603714],
                            [39.82791542, -4.96587716],
                            [39.82793944, -4.9657803],
                            [39.82925495, -4.96578362],
                            [39.83110585, -4.9657883],
                            [39.83115892, -4.96578843],
                            [39.83125712, -4.96590068],
                            [39.83136234, -4.96597083],
                            [39.83145353, -4.96606905],
                            [39.83147933, -4.96612389],
                            [39.83150965, -4.96618831],
                            [39.83161341, -4.96624839],
                            [39.83164292, -4.96626548],
                            [39.83164562, -4.9662783],
                            [39.83167098, -4.96639877],
                            [39.83168501, -4.96651102],
                            [39.83181127, -4.96660924],
                            [39.83190246, -4.96677761],
                            [39.83200768, -4.9669109],
                            [39.83211992, -4.96702315],
                            [39.83213395, -4.96712136],
                            [39.83221812, -4.96720555],
                            [39.83244259, -4.96739496],
                            [39.83254781, -4.96757035],
                            [39.83264601, -4.96777379],
                            [39.83280735, -4.96797724],
                            [39.83299318, -4.9681514],
                            [39.83308554, -4.96819011],
                            [39.83313002, -4.96820875],
                            [39.83354388, -4.9681877],
                            [39.8339367, -4.96822278],
                            [39.83428042, -4.96808949],
                            [39.8344628, -4.9679562],
                            [39.834561, -4.96789306],
                            [39.83465921, -4.96782992],
                            [39.83487666, -4.96768961],
                            [39.83509199, -4.96748693],
                            [39.83511516, -4.96746512],
                            [39.8353256, -4.96712137],
                            [39.83545186, -4.96686881],
                            [39.83559215, -4.9665461],
                            [39.83557111, -4.96627952],
                            [39.83556409, -4.96608309],
                            [39.83545888, -4.96588665],
                            [39.83540977, -4.96573231],
                            [39.83536769, -4.96560604],
                            [39.83535649, -4.96548232],
                            [39.83534417, -4.96533452],
                            [39.83531954, -4.96519903],
                            [39.8353688, -4.96508817],
                            [39.83543038, -4.964965],
                            [39.83545501, -4.96485415],
                            [39.8354427, -4.96469403],
                            [39.83538112, -4.96462012],
                            [39.83529491, -4.9645339],
                            [39.83522101, -4.96442305],
                            [39.83515944, -4.96427524],
                            [39.83508554, -4.9641767],
                            [39.83499933, -4.9641028],
                            [39.83490758, -4.96411262],
                            [39.83479581, -4.96411155],
                            [39.83473789, -4.964111],
                            [39.83467984, -4.96411044],
                            [39.83444996, -4.96403212],
                            [39.83425303, -4.96384373],
                            [39.83415885, -4.96350977],
                            [39.8341246, -4.9632015],
                            [39.83417598, -4.96287611],
                            [39.83417598, -4.96245652],
                            [39.8342616, -4.96231951],
                            [39.83455271, -4.96213969],
                            [39.83479245, -4.96210543],
                            [39.83498081, -4.96183142],
                            [39.83510925, -4.96122344],
                            [39.83506644, -4.96081241],
                            [39.835075, -4.96052127],
                            [39.83508356, -4.96026438],
                            [39.83527193, -4.95992186],
                            [39.83558016, -4.9596564],
                            [39.8358199, -4.95963928],
                            [39.8363165, -4.95957077],
                            [39.83654768, -4.95946802],
                            [39.83677886, -4.95936526],
                            [39.83683879, -4.95892855],
                            [39.83693297, -4.95861171],
                            [39.83692441, -4.95846614],
                            [39.83677886, -4.95835482],
                            [39.83666755, -4.95802086],
                            [39.83679598, -4.95773828],
                            [39.83709566, -4.95739576],
                            [39.8373782, -4.95717312],
                            [39.83735252, -4.95686485],
                            [39.83724121, -4.95647095],
                            [39.8371984, -4.95599998],
                            [39.83718984, -4.95576022],
                            [39.83715559, -4.95558896],
                            [39.83732683, -4.95540913],
                            [39.83739533, -4.95528069],
                            [39.83730971, -4.95498955],
                            [39.83720697, -4.95460421],
                            [39.83712135, -4.9544672],
                            [39.83713847, -4.95424456],
                            [39.83712135, -4.95359377],
                            [39.83694582, -4.95314421],
                            [39.8367703, -4.95269465],
                            [39.83659906, -4.95241207],
                            [39.83694154, -4.95215518],
                            [39.83701004, -4.95124749],
                            [39.83739534, -4.9497404],
                            [39.83787482, -4.94843881],
                            [39.83799544, -4.9482458],
                            [39.83855978, -4.94734275],
                            [39.83942528, -4.94684812],
                            [39.83999822, -4.94652069],
                            [39.84078593, -4.94614392],
                            [39.84157364, -4.94576715],
                            [39.84184763, -4.94528762],
                            [39.8424641, -4.94439706],
                            [39.84294358, -4.94364351],
                            [39.84294358, -4.94254744],
                            [39.84154801, -4.94039569],
                            [39.84129966, -4.94001278],
                            [39.84095275, -4.93963691],
                            [39.84022916, -4.93885294],
                            [39.83965574, -4.93823166],
                            [39.83739536, -4.93631354],
                            [39.83602542, -4.93562849],
                            [39.83417601, -4.93569699],
                            [39.83308007, -4.93579975],
                            [39.83198412, -4.9359025],
                            [39.83006621, -4.93535447],
                            [39.82965524, -4.93391587],
                            [39.82910726, -4.93302532],
                            [39.82801132, -4.93103869],
                            [39.82691537, -4.92953159],
                            [39.82575093, -4.92829851],
                            [39.82499747, -4.92781898],
                            [39.82500566, -4.92857169],
                            [39.82501234, -4.92918592],
                            [39.82362753, -4.92925757],
                            [39.82364388, -4.92958562],
                            [39.82266858, -4.92932607],
                            [39.8223946, -4.9281615],
                            [39.82136715, -4.92802449],
                            [39.8203397, -4.92775047],
                            [39.82006571, -4.92713393],
                            [39.81938074, -4.92658589],
                            [39.81896976, -4.92658589],
                            [39.81849029, -4.92699692],
                            [39.81718885, -4.92658589],
                            [39.81629839, -4.92624337],
                            [39.81540794, -4.92514729],
                            [39.81458598, -4.92473627],
                            [39.81424349, -4.92603785],
                            [39.81369552, -4.92713392],
                            [39.81342153, -4.92884653],
                            [39.81314754, -4.9300111],
                            [39.81259957, -4.93076465],
                            [39.81273656, -4.93234025],
                            [39.8129581, -4.93290873],
                            [39.81170911, -4.9330938],
                            [39.81081865, -4.93425837],
                            [39.81033917, -4.93508042],
                            [39.81013368, -4.93583397],
                            [39.80992819, -4.93679303],
                            [39.81080943, -4.93839023],
                            [39.81177759, -4.94124582],
                            [39.81320994, -4.94372612],
                            [39.81485993, -4.94761673],
                            [39.81577873, -4.94911767],
                            [39.81595149, -4.94923327],
                            [39.81670934, -4.94974037],
                            [39.81787379, -4.95008289],
                            [39.81868958, -4.95034054],
                            [39.81917522, -4.95049392],
                            [39.81990727, -4.95024988],
                            [39.81999718, -4.9502199],
                            [39.82004766, -4.9501505],
                            [39.82054516, -4.94946636],
                            [39.82143561, -4.94878131],
                            [39.82174385, -4.94847304],
                            [39.82205209, -4.94816478],
                            [39.82262089, -4.9479159],
                            [39.82314803, -4.94768525],
                            [39.82362751, -4.94679469],
                            [39.82385806, -4.94610297],
                            [39.8239015, -4.94597264],
                            [39.82445829, -4.94547766],
                            [39.82451797, -4.9454246],
                            [39.82520294, -4.94508208],
                            [39.82568241, -4.94508208],
                            [39.82506594, -4.94569862],
                            [39.82438097, -4.94617815],
                            [39.82307953, -4.94864431],
                            [39.82266855, -4.94932935],
                            [39.82160081, -4.94944253],
                            [39.82142167, -4.94946151],
                            [39.82085238, -4.95001123],
                            [39.82083822, -4.95011389],
                            [39.82077386, -4.95058059],
                            [39.82075005, -4.95113999],
                            [39.82074027, -4.95136996],
                            [39.82073459, -4.95150333],
                            [39.82079349, -4.95207268],
                            [39.82078644, -4.95225592],
                            [39.82077385, -4.95258314],
                            [39.82035461, -4.95246574],
                            [39.82028309, -4.95244571],
                            [39.82018493, -4.95270093],
                            [39.8201718, -4.95292427],
                            [39.8201653, -4.95303469],
                            [39.82021737, -4.95315207],
                            [39.8202599, -4.95324794],
                            [39.82000826, -4.95344698],
                            [39.81973343, -4.95344698],
                            [39.81938008, -4.95317212],
                            [39.81910525, -4.95289726],
                            [39.81896783, -4.95311322],
                            [39.81902672, -4.95360404],
                            [39.81875189, -4.95376111],
                            [39.81851633, -4.95376111],
                            [39.81837512, -4.95382511],
                            [39.8183417, -4.95400058],
                            [39.81832917, -4.95413844],
                            [39.8183417, -4.9542596],
                            [39.81836677, -4.95435568],
                            [39.81837094, -4.95446013],
                            [39.81837094, -4.95452697],
                            [39.81840436, -4.95463141],
                            [39.81842017, -4.95465447],
                            [39.81852132, -4.95465648],
                            [39.81862575, -4.95464395],
                            [39.81874272, -4.95462724],
                            [39.81890594, -4.95465611],
                            [39.81866409, -4.95484586],
                            [39.81853985, -4.95493284],
                            [39.81849015, -4.95498875],
                            [39.81844046, -4.95504467],
                            [39.81838122, -4.95514607],
                            [39.81837834, -4.95522276],
                            [39.81846531, -4.95524347],
                            [39.81846116, -4.95530974],
                            [39.8183949, -4.9553553],
                            [39.81841147, -4.95544641],
                            [39.81843632, -4.95549197],
                            [39.81843218, -4.95553753],
                            [39.81832864, -4.95560794],
                            [39.81819198, -4.95566592],
                            [39.81811744, -4.95571148],
                            [39.81805532, -4.95574876],
                            [39.81802633, -4.95577361],
                            [39.81802633, -4.95582745],
                            [39.81804704, -4.95590614],
                            [39.81800176, -4.95596895],
                            [39.81801805, -4.9560014],
                            [39.81809673, -4.9560304],
                            [39.81820855, -4.95607595],
                            [39.81827481, -4.95611323],
                            [39.81834935, -4.95617536],
                            [39.81837834, -4.95620435],
                            [39.8183742, -4.95624162],
                            [39.81835763, -4.9562789],
                            [39.81834521, -4.95632032],
                            [39.81830601, -4.95633773],
                            [39.81820026, -4.95632032],
                            [39.81812986, -4.95631203],
                            [39.81802219, -4.95629132],
                            [39.81793522, -4.95632032],
                            [39.81781303, -4.95642217],
                            [39.81767862, -4.95653421],
                            [39.81759715, -4.95660211],
                            [39.81753232, -4.95665616],
                            [39.8174999, -4.95668318],
                            [39.81748369, -4.95669669],
                            [39.81747963, -4.95670007],
                            [39.81747558, -4.95670345],
                            [39.81745221, -4.95672085],
                            [39.81742883, -4.95673825],
                            [39.8174355, -4.95679157],
                            [39.81744216, -4.95687156],
                            [39.81737551, -4.95692488],
                            [39.81735552, -4.95707818],
                            [39.81726222, -4.9573048],
                            [39.8172089, -4.95731814],
                            [39.81718891, -4.95739812],
                            [39.81712226, -4.9574781],
                            [39.81706894, -4.9575181],
                            [39.8170023, -4.95765807],
                            [39.81699563, -4.95773805],
                            [39.81699563, -4.95776471],
                            [39.81704228, -4.95791135],
                            [39.81710227, -4.95810465],
                            [39.81711559, -4.95828461],
                            [39.81710893, -4.95841792],
                            [39.81704228, -4.95854456],
                            [39.81693565, -4.95863787],
                            [39.81682235, -4.95878451],
                            [39.81675571, -4.95887782],
                            [39.81668906, -4.95900447],
                            [39.81673571, -4.95922442],
                            [39.81665574, -4.95933107],
                            [39.81655577, -4.95950437],
                            [39.81647579, -4.95949104],
                            [39.81640248, -4.95951103],
                            [39.81633584, -4.95958435],
                            [39.8162492, -4.95969766],
                            [39.81625586, -4.9598243],
                            [39.81628918, -4.95992428],
                            [39.81638249, -4.96007092],
                            [39.81646246, -4.96007092],
                            [39.8165491, -4.96007092],
                            [39.8165491, -4.96013757],
                            [39.81650911, -4.96022422],
                            [39.81640914, -4.9603442],
                            [39.81633583, -4.96040419],
                            [39.81620921, -4.96044418],
                            [39.81606259, -4.96041085],
                            [39.81598928, -4.96051083],
                            [39.8159093, -4.96071746],
                            [39.81592929, -4.96079078],
                            [39.81595595, -4.96089076],
                            [39.81602926, -4.96093075],
                            [39.81606925, -4.96106405],
                            [39.81603593, -4.96114404],
                            [39.81598261, -4.96123735],
                            [39.81594262, -4.96133733],
                            [39.81593596, -4.96145064],
                            [39.81595262, -4.9614973],
                            [39.81596928, -4.96154396],
                            [39.81604259, -4.96163061],
                            [39.81612257, -4.96159062],
                            [39.81631584, -4.96151063],
                            [39.81642247, -4.96155062],
                            [39.81638915, -4.96162394],
                            [39.81632917, -4.96173725],
                            [39.81618921, -4.96179058],
                            [39.81603593, -4.96191055],
                            [39.81604925, -4.96208385],
                            [39.81604925, -4.96222382],
                            [39.81605592, -4.96238379],
                            [39.81607591, -4.9624971],
                            [39.8161159, -4.96261041],
                            [39.81612923, -4.96274372],
                            [39.81612923, -4.96275039],
                            [39.81616922, -4.96281704],
                            [39.81623586, -4.96287036],
                            [39.81627002, -4.96294206],
                            [39.81622012, -4.96292475],
                            [39.81608258, -4.96287703],
                            [39.81608239, -4.96281633],
                            [39.81600927, -4.96279038],
                            [39.81589597, -4.96281704],
                            [39.81580933, -4.96283703],
                            [39.815796, -4.96290369],
                            [39.81574935, -4.96299034],
                            [39.81564271, -4.96306366],
                            [39.8155694, -4.96315697],
                            [39.81566271, -4.96327028],
                            [39.81576268, -4.96337026],
                            [39.81582932, -4.96341025],
                            [39.81591596, -4.96350357],
                            [39.81596261, -4.9635369],
                            [39.81598927, -4.96362355],
                            [39.81590263, -4.96365687],
                            [39.81586264, -4.96372352],
                            [39.81592929, -4.96379018],
                            [39.81600926, -4.96383017],
                            [39.81608258, -4.96383684],
                            [39.81619587, -4.96387016],
                            [39.81623586, -4.96394348],
                            [39.8163225, -4.96395015],
                            [39.81636249, -4.96407012],
                            [39.81636249, -4.96415011],
                            [39.81632916, -4.96427675],
                            [39.81630917, -4.96435673],
                            [39.81637582, -4.96443005],
                            [39.81644246, -4.96451004],
                            [39.81651577, -4.96457002],
                            [39.81662907, -4.96463001],
                            [39.81667572, -4.96469],
                            [39.81666239, -4.96480998],
                            [39.81661574, -4.96492995],
                            [39.81658242, -4.96500327],
                            [39.81658242, -4.96506326],
                            [39.81662797, -4.96515185],
                            [39.81658908, -4.96514991],
                            [39.81656242, -4.96519657],
                            [39.81660908, -4.96526988],
                            [39.81660241, -4.96542319],
                            [39.81664126, -4.96555701],
                            [39.81666239, -4.96562981],
                            [39.81665573, -4.96574979],
                            [39.81665573, -4.96588976],
                            [39.81669571, -4.96594975],
                            [39.81672237, -4.96604973],
                            [39.81672237, -4.96608408],
                            [39.81672237, -4.96609639],
                            [39.81678235, -4.96617637],
                            [39.81678423, -4.96617625],
                            [39.81688899, -4.96616971],
                            [39.81700229, -4.96628302],
                            [39.81706688, -4.96642567],
                            [39.81707377, -4.9664446],
                            [39.81712453, -4.9665842],
                            [39.8172254, -4.9668292],
                            [39.81721108, -4.96697375],
                            [39.81711014, -4.96699681],
                            [39.81702365, -4.96701656],
                            [39.81693719, -4.96681479],
                            [39.81686802, -4.96658779],
                            [39.81662016, -4.96662743],
                            [39.81659134, -4.96688685],
                            [39.81650488, -4.96708862],
                            [39.81651433, -4.96717802],
                            [39.81653802, -4.96720171],
                            [39.81660907, -4.9672633],
                            [39.81664223, -4.96733909],
                            [39.81664696, -4.96744332],
                            [39.8167038, -4.96752385],
                            [39.81673223, -4.96759491],
                            [39.81675591, -4.96771335],
                            [39.81680328, -4.96779388],
                            [39.81677012, -4.96788863],
                            [39.81673223, -4.96797864],
                            [39.81674644, -4.96814919],
                            [39.81684117, -4.96823446],
                            [39.81691223, -4.96828657],
                            [39.81701644, -4.96832447],
                            [39.81715854, -4.9683529],
                            [39.81727223, -4.96837658],
                            [39.81739065, -4.96840975],
                            [39.81744749, -4.96845712],
                            [39.81748539, -4.96854713],
                            [39.81748065, -4.96866557],
                            [39.81744275, -4.96878874],
                            [39.81739065, -4.9688219],
                            [39.81732433, -4.96877926],
                            [39.81723907, -4.96868925],
                            [39.81709696, -4.96862767],
                            [39.816917, -4.96866058],
                            [39.81685065, -4.96866083],
                            [39.8167417, -4.96873663],
                            [39.81661854, -4.968784],
                            [39.81659486, -4.96899244],
                            [39.81670854, -4.96912983],
                            [39.81691222, -4.96939039],
                            [39.81698801, -4.96951356],
                            [39.81695012, -4.96963199],
                            [39.81703065, -4.96969358],
                            [39.81708275, -4.96971727],
                            [39.81718222, -4.96978359],
                            [39.8172438, -4.96989255],
                            [39.81717866, -4.97004899],
                            [39.81698864, -4.96994764],
                            [39.81693797, -4.97007433],
                            [39.81696331, -4.97022637],
                            [39.81707732, -4.97032772],
                            [39.81703932, -4.97044175],
                            [39.81698392, -4.97044391],
                            [39.81695637, -4.97052656],
                            [39.81701146, -4.97067349],
                            [39.81712165, -4.97078369],
                            [39.81723184, -4.97079288],
                            [39.81736958, -4.97077451],
                            [39.81758077, -4.97073778],
                            [39.81770527, -4.97073778],
                            [39.81781952, -4.97073778],
                            [39.8179848, -4.97070104],
                            [39.81813172, -4.97072859],
                            [39.81805627, -4.97088789],
                            [39.81804908, -4.97090308],
                            [39.81792052, -4.97101328],
                            [39.81775793, -4.97111491],
                            [39.81770014, -4.97115103],
                            [39.81761037, -4.97111446],
                            [39.81745222, -4.97105001],
                            [39.81708492, -4.9711143],
                            [39.81694719, -4.97120613],
                            [39.81697473, -4.97129797],
                            [39.81716756, -4.97143572],
                            [39.81744304, -4.97149082],
                            [39.81769096, -4.97161939],
                            [39.81781951, -4.97180306],
                            [39.81795725, -4.97204183],
                            [39.81836127, -4.97237243],
                            [39.81844392, -4.97247804],
                            [39.81845654, -4.97249417],
                            [39.81852656, -4.97258365],
                            [39.81862756, -4.97259283],
                            [39.81875612, -4.972501],
                            [39.81887549, -4.97237243],
                            [39.81894895, -4.97213366],
                            [39.8189765, -4.97189489],
                            [39.81911423, -4.97170204],
                            [39.81934379, -4.97162857],
                            [39.81943562, -4.97182143],
                            [39.81955499, -4.97214285],
                            [39.81930706, -4.9723357],
                            [39.81905914, -4.97262957],
                            [39.8189118, -4.9728734],
                            [39.81869184, -4.97289589],
                            [39.81858165, -4.97308874],
                            [39.81857305, -4.97313521],
                            [39.81853574, -4.97333669],
                            [39.81861222, -4.97357311],
                            [39.81863675, -4.97364893],
                            [39.81878366, -4.97376831],
                            [39.81891222, -4.97386015],
                            [39.8190775, -4.97396117],
                            [39.81935297, -4.97398872],
                            [39.81956417, -4.97406218],
                            [39.8196009, -4.97423667],
                            [39.81977536, -4.97425504],
                            [39.8199071, -4.97426558],
                            [39.82000492, -4.9742734],
                            [39.82015184, -4.97417239],
                            [39.82016673, -4.97407562],
                            [39.82018857, -4.97393362],
                            [39.82051914, -4.97375913],
                            [39.82046404, -4.97352036],
                            [39.82046404, -4.97334588],
                            [39.82049159, -4.97318976],
                            [39.82046404, -4.97305201],
                            [39.82037222, -4.97284079],
                            [39.82037222, -4.9726663],
                            [39.82052832, -4.97256529],
                            [39.82067524, -4.97264794],
                            [39.82095071, -4.9726112],
                            [39.82119864, -4.97286834],
                            [39.8214282, -4.97308874],
                            [39.82142809, -4.97328456],
                            [39.82104134, -4.97355132],
                            [39.82080129, -4.97375138],
                            [39.82089464, -4.97404481],
                            [39.82117489, -4.97399599],
                            [39.8213214, -4.97425821],
                            [39.82141475, -4.97445828],
                            [39.82156812, -4.97447162],
                            [39.82172148, -4.97448495],
                            [39.82185485, -4.97460499],
                            [39.82182221, -4.97464625],
                            [39.82178958, -4.97468751],
                            [39.82177278, -4.97472832],
                            [39.82175598, -4.97476913],
                            [39.82181839, -4.97481714],
                            [39.82186159, -4.97493238],
                            [39.82182799, -4.97505241],
                            [39.82181235, -4.9750876],
                            [39.82178958, -4.97513884],
                            [39.82165036, -4.97516764],
                            [39.82152554, -4.97524927],
                            [39.82145832, -4.97541251],
                            [39.82144392, -4.97553735],
                            [39.82146792, -4.97562858],
                            [39.82155914, -4.97567659],
                            [39.82165516, -4.97593587],
                            [39.82152073, -4.97615193],
                            [39.82141511, -4.97629597],
                            [39.82130949, -4.9763872],
                            [39.82124228, -4.97654564],
                            [39.82124228, -4.97665128],
                            [39.82130949, -4.97693456],
                            [39.8213239, -4.97704979],
                            [39.8213575, -4.97716983],
                            [39.8213239, -4.97738109],
                            [39.82113666, -4.97754914],
                            [39.82103104, -4.97757794],
                            [39.82088702, -4.97764997],
                            [39.8207814, -4.9777844],
                            [39.82086301, -4.97798606],
                            [39.82098784, -4.97807729],
                            [39.82107905, -4.97800047],
                            [39.82109825, -4.97788523],
                            [39.82116067, -4.97781321],
                            [39.82130949, -4.97781321],
                            [39.82140551, -4.97783722],
                            [39.82154473, -4.97786123],
                            [39.82168396, -4.97787083],
                            [39.82177518, -4.97781321],
                            [39.82185679, -4.97767397],
                            [39.8219096, -4.97755394],
                            [39.8219288, -4.97734748],
                            [39.821924, -4.9770738],
                            [39.82199122, -4.97666088],
                            [39.82217365, -4.97670409],
                            [39.82233944, -4.97672155],
                            [39.82235608, -4.9767233],
                            [39.82238489, -4.9767377],
                            [39.82254812, -4.97675691],
                            [39.82267774, -4.97675691],
                            [39.82280256, -4.9767425],
                            [39.82289378, -4.97669929],
                            [39.82298499, -4.97669929],
                            [39.8230378, -4.97670409],
                            [39.82313382, -4.97676651],
                            [39.82316743, -4.97683373],
                            [39.82315782, -4.97694896],
                            [39.82324904, -4.9770594],
                            [39.82334986, -4.97711701],
                            [39.82345068, -4.97716983],
                            [39.82352749, -4.97713142],
                            [39.82354669, -4.97708821],
                            [39.8235611, -4.97701618],
                            [39.82361871, -4.97693456],
                            [39.82370512, -4.97687694],
                            [39.82380594, -4.97689135],
                            [39.82391156, -4.97693936],
                            [39.82400277, -4.9770498],
                            [39.82405078, -4.97720824],
                            [39.82405078, -4.97730907],
                            [39.82402678, -4.97739069],
                            [39.82411799, -4.97743391],
                            [39.82421881, -4.9774051],
                            [39.82432443, -4.97736189],
                            [39.82435324, -4.97728987],
                            [39.82433403, -4.97723705],
                            [39.82428602, -4.97715062],
                            [39.82425242, -4.97703059],
                            [39.82429563, -4.97697297],
                            [39.82434844, -4.97689615],
                            [39.82441565, -4.97680973],
                            [39.82452127, -4.9767233],
                            [39.82464609, -4.97660327],
                            [39.82475651, -4.97653605],
                            [39.82492934, -4.97642081],
                            [39.82505416, -4.9763632],
                            [39.82521739, -4.97635359],
                            [39.82539022, -4.9763872],
                            [39.82556305, -4.97646883],
                            [39.82564467, -4.97658406],
                            [39.82563507, -4.97673291],
                            [39.82558706, -4.97680012],
                            [39.82554385, -4.97683854],
                            [39.82549584, -4.97688175],
                            [39.82547184, -4.97696817],
                            [39.82551985, -4.9770594],
                            [39.82559666, -4.9770738],
                            [39.82569268, -4.97714583],
                            [39.82566867, -4.97721305],
                            [39.82561106, -4.97730427],
                            [39.82559186, -4.97738109],
                            [39.82557265, -4.97748672],
                            [39.82551984, -4.97756355],
                            [39.82542248, -4.97759538],
                            [39.82533741, -4.97755394],
                            [39.82519339, -4.97752513],
                            [39.82503976, -4.97751073],
                            [39.82492454, -4.97754914],
                            [39.82489573, -4.97766918],
                            [39.82488613, -4.9777604],
                            [39.82476611, -4.97784683],
                            [39.82466529, -4.97792845],
                            [39.82475171, -4.97798127],
                            [39.82484292, -4.97800047],
                            [39.82502055, -4.97801008],
                            [39.82514058, -4.97811571],
                            [39.82516938, -4.97824054],
                            [39.82517418, -4.97841819],
                            [39.82520779, -4.97871588],
                            [39.82520779, -4.97886472],
                            [39.82521259, -4.97905198],
                            [39.82510217, -4.97917682],
                            [39.82512617, -4.97922963],
                            [39.82521259, -4.97928245],
                            [39.8252129, -4.9792824],
                            [39.8253038, -4.97926804],
                            [39.82539022, -4.97919602],
                            [39.82543343, -4.9791144],
                            [39.82545316, -4.97907672],
                            [39.82548624, -4.97901357],
                            [39.82551504, -4.97890314],
                            [39.82551984, -4.97881191],
                            [39.82557745, -4.9787735],
                            [39.82566867, -4.97880711],
                            [39.82568904, -4.97884559],
                            [39.82571188, -4.97888873],
                            [39.82568787, -4.97906638],
                            [39.82568307, -4.97917201],
                            [39.8258175, -4.97928245],
                            [39.82593752, -4.97938328],
                            [39.82605754, -4.97963775],
                            [39.82612475, -4.97967136],
                            [39.82615355, -4.97959934],
                            [39.82614875, -4.97943609],
                            [39.82619196, -4.97927284],
                            [39.82628318, -4.97927284],
                            [39.8264032, -4.97942169],
                            [39.8264176, -4.97958013],
                            [39.82635999, -4.97977219],
                            [39.82633599, -4.97999305],
                            [39.82645601, -4.98004587],
                            [39.82649922, -4.97994504],
                            [39.82651842, -4.9798106],
                            [39.82657123, -4.97964255],
                            [39.82660041, -4.97950635],
                            [39.82662884, -4.97937367],
                            [39.82665765, -4.97908079],
                            [39.82673446, -4.97886473],
                            [39.82681607, -4.97871588],
                            [39.82701291, -4.9787879],
                            [39.82708012, -4.97900397],
                            [39.82705132, -4.97932086],
                            [39.82701291, -4.9794697],
                            [39.82701291, -4.97962335],
                            [39.82705131, -4.97970497],
                            [39.82704171, -4.979801],
                            [39.82705612, -4.97988742],
                            [39.82711373, -4.97971938],
                            [39.82715693, -4.97973378],
                            [39.82725295, -4.9798154],
                            [39.82730576, -4.97987782],
                            [39.82742098, -4.97996425],
                            [39.82742098, -4.97982021],
                            [39.82737297, -4.97967136],
                            [39.82739698, -4.97952732],
                            [39.82742098, -4.97939768],
                            [39.82741618, -4.97925364],
                            [39.8275218, -4.979124],
                            [39.82768023, -4.97913841],
                            [39.82786266, -4.97914321],
                            [39.82804989, -4.97918162],
                            [39.82817472, -4.97917202],
                            [39.82826113, -4.97921523],
                            [39.82837155, -4.97930166],
                            [39.82832354, -4.97941689],
                            [39.82812671, -4.97960414],
                            [39.82801629, -4.97973858],
                            [39.82798748, -4.9798106],
                            [39.82803069, -4.97988262],
                            [39.8280739, -4.97993064],
                            [39.82815071, -4.98001706],
                            [39.82834755, -4.98010829],
                            [39.82855398, -4.98018991],
                            [39.8286644, -4.98025233],
                            [39.82879403, -4.98025233],
                            [39.82881803, -4.98020432],
                            [39.82882283, -4.98005067],
                            [39.82884683, -4.97998345],
                            [39.82892845, -4.97985862],
                            [39.82902927, -4.97985382],
                            [39.82909648, -4.97993064],
                            [39.82917329, -4.97998826],
                            [39.82916849, -4.98005548],
                            [39.82909648, -4.98009869],
                            [39.82902927, -4.98020432],
                            [39.82902927, -4.98033396],
                            [39.82903887, -4.98055482],
                            [39.82898126, -4.98079969],
                            [39.82894285, -4.98094373],
                            [39.82899086, -4.98105897],
                            [39.82912048, -4.98120301],
                            [39.82918769, -4.98136626],
                            [39.8291973, -4.9815247],
                            [39.82920134, -4.98157729],
                            [39.8292069, -4.98164954],
                            [39.82930291, -4.98165434],
                            [39.82933247, -4.98174017],
                            [39.82949493, -4.98221192],
                            [39.82948601, -4.98253309],
                            [39.82948175, -4.98268646],
                            [39.82958719, -4.98289737],
                            [39.82946857, -4.98296328],
                            [39.82948175, -4.9832401],
                            [39.82952129, -4.98335873],
                            [39.82953447, -4.98378055],
                            [39.82958719, -4.98408372],
                            [39.82965309, -4.98414963],
                            [39.82969263, -4.98437372],
                            [39.82956083, -4.98457145],
                            [39.82949493, -4.98508553],
                            [39.82954765, -4.98540189],
                            [39.82961355, -4.9857578],
                            [39.82966627, -4.98592916],
                            [39.82986397, -4.98603462],
                            [39.83000895, -4.98620598],
                            [39.83003531, -4.98637734],
                            [39.82995623, -4.98646961],
                            [39.82979807, -4.98646961],
                            [39.82967945, -4.98645643],
                            [39.82954764, -4.98644325],
                            [39.82945538, -4.98662779],
                            [39.82927086, -4.98690461],
                            [39.82920496, -4.9869837],
                            [39.82907316, -4.98694415],
                            [39.82899407, -4.98708915],
                            [39.82891499, -4.9871946],
                            [39.82892817, -4.98736597],
                            [39.82884909, -4.98751096],
                            [39.82877001, -4.98761642],
                            [39.82861185, -4.98795914],
                            [39.82857231, -4.98817005],
                            [39.82851959, -4.98836777],
                            [39.82838778, -4.98878959],
                            [39.82819008, -4.98893459],
                            [39.828111, -4.98907958],
                            [39.82822962, -4.98914549],
                            [39.82829552, -4.98926413],
                            [39.82834824, -4.98936958],
                            [39.82832188, -4.98967276],
                            [39.82822962, -4.98977821],
                            [39.8283087, -4.98994958],
                            [39.82829552, -4.99018685],
                            [39.82820326, -4.99034503],
                            [39.82812418, -4.99046366],
                            [39.82808464, -4.99056912],
                            [39.82805828, -4.99067457],
                            [39.82826916, -4.99121502],
                            [39.8281769, -4.99145229],
                            [39.82801873, -4.99157093],
                            [39.82800555, -4.99174229],
                            [39.82821644, -4.99179501],
                            [39.82832188, -4.99184774],
                            [39.82825598, -4.9920191],
                            [39.8281769, -4.9921641],
                            [39.82829552, -4.99238819],
                            [39.82819007, -4.9924541],
                            [39.82795283, -4.99241455],
                            [39.82786057, -4.99241455],
                            [39.82786057, -4.99258592],
                            [39.82780785, -4.9927441],
                            [39.82787375, -4.99302091],
                            [39.82786057, -4.99311318],
                            [39.82768923, -4.99320545],
                            [39.82769582, -4.99327136],
                            [39.82770241, -4.99333727],
                            [39.82756814, -4.99348069],
                            [39.82743387, -4.9936241],
                            [39.82740011, -4.99375916],
                            [39.82736635, -4.99389423],
                            [39.82723803, -4.9941583],
                            [39.82710971, -4.99442238],
                            [39.82685992, -4.99452452],
                            [39.82644352, -4.99452452],
                            [39.82600895, -4.99481108],
                            [39.82559948, -4.99497472],
                            [39.82547569, -4.99523359],
                            [39.82523935, -4.99506476],
                            [39.82504804, -4.99505351],
                            [39.82512682, -4.99523359],
                            [39.82525061, -4.99554874],
                            [39.82545318, -4.99592016],
                            [39.8253744, -4.99630283],
                            [39.8252281, -4.99648291],
                            [39.824958, -4.9971132],
                            [39.82498051, -4.997766],
                            [39.82467665, -4.99843006],
                            [39.82444032, -4.99936424],
                            [39.82408437, -4.99968159],
                            [39.82413855, -4.99901643],
                            [39.82419274, -4.99835127],
                            [39.82459788, -4.996663],
                            [39.82363004, -4.99605522],
                            [39.82236219, -4.99563244],
                            [39.82183206, -4.99471668],
                            [39.82166114, -4.99458596],
                            [39.82127907, -4.9944854],
                            [39.8211182, -4.99432451],
                            [39.82119864, -4.99419379],
                            [39.82132935, -4.99404296],
                            [39.82154049, -4.99400274],
                            [39.82170136, -4.99389213],
                            [39.82171141, -4.99370107],
                            [39.82152038, -4.99331897],
                            [39.82138967, -4.99281619],
                            [39.82128913, -4.99245419],
                            [39.8210981, -4.99241397],
                            [39.82094168, -4.99253056],
                            [39.82081658, -4.99289664],
                            [39.82069592, -4.99313797],
                            [39.82032391, -4.99322847],
                            [39.81997201, -4.99335919],
                            [39.81964022, -4.99341952],
                            [39.81945924, -4.9933793],
                            [39.81932853, -4.99322846],
                            [39.81922799, -4.99286647],
                            [39.8190269, -4.99258491],
                            [39.81876549, -4.99245419],
                            [39.81849881, -4.99226022],
                            [39.81867163, -4.99197215],
                            [39.81911328, -4.99179931],
                            [39.81961254, -4.99189534],
                            [39.81990058, -4.99151125],
                            [39.81998512, -4.9909476],
                            [39.82001579, -4.99074307],
                            [39.82016941, -4.99032057],
                            [39.81995819, -4.98974444],
                            [39.8192093, -4.98911069],
                            [39.81855642, -4.98893785],
                            [39.81765217, -4.98829223],
                            [39.81794827, -4.98782952],
                            [39.81889209, -4.98720024],
                            [39.8189291, -4.98655245],
                            [39.81800379, -4.98662648],
                            [39.8172164, -4.98636101],
                            [39.81722653, -4.98586764],
                            [39.81778172, -4.98531239],
                            [39.81818885, -4.98520134],
                            [39.81887359, -4.98560852],
                            [39.81955832, -4.98540493],
                            [39.81987292, -4.98427593],
                            [39.81966936, -4.98375769],
                            [39.81844794, -4.98390576],
                            [39.81878106, -4.9844425],
                            [39.81905865, -4.98492372],
                            [39.81894761, -4.98512731],
                            [39.81855898, -4.98460907],
                            [39.81813553, -4.98453094],
                            [39.81767068, -4.98464609],
                            [39.81746711, -4.98392426],
                            [39.81583857, -4.98314691],
                            [39.81512758, -4.98264426],
                            [39.81369184, -4.98162923],
                            [39.81309965, -4.98111099],
                            [39.81248894, -4.98048171],
                            [39.81215583, -4.97994497],
                            [39.81219284, -4.97938047],
                            [39.81222986, -4.97912135],
                            [39.8120633, -4.97875119],
                            [39.8120633, -4.97847356],
                            [39.8120633, -4.97813116],
                            [39.81169318, -4.97742784],
                            [39.81134156, -4.97662273],
                            [39.81107322, -4.97642839],
                            [39.81121202, -4.97546596],
                            [39.81108248, -4.97504952],
                            [39.81108248, -4.97470712],
                            [39.81111949, -4.97445725],
                            [39.81103621, -4.97431844],
                            [39.81097144, -4.97393902],
                            [39.81078157, -4.97359569],
                            [39.81139358, -4.97149904],
                            [39.81162796, -4.97067861],
                            [39.8126957, -4.96899868],
                            [39.81347698, -4.96698016],
                            [39.8133077, -4.9666025],
                            [39.8115889, -4.96649832],
                            [39.81010448, -4.96662854],
                            [39.80923206, -4.96727968],
                            [39.80847682, -4.96821731],
                            [39.80716168, -4.96906378],
                            [39.80591476, -4.96965614],
                            [39.80559913, -4.96980608],
                            [39.80424492, -4.96979305],
                            [39.80253914, -4.96944144],
                            [39.80221415, -4.96845685],
                            [39.80473545, -4.96845685],
                            [39.80606554, -4.96742592],
                            [39.80653638, -4.96706098],
                            [39.80698661, -4.96543996],
                            [39.80554656, -4.96401807],
                            [39.8041065, -4.96259618],
                            [39.80368716, -4.9623381],
                            [39.80323557, -4.96214992],
                            [39.80278398, -4.96196173],
                            [39.80204208, -4.96135954],
                            [39.80099911, -4.96066056],
                            [39.80062278, -4.96049926],
                            [39.80033247, -4.96021967],
                            [39.79992389, -4.95983255],
                            [39.79979666, -4.95985356],
                            [39.79956907, -4.95974652],
                            [39.79917008, -4.95957421],
                            [39.79910409, -4.95954571],
                            [39.79909597, -4.9595422],
                            [39.7990951, -4.9595372],
                            [39.79902071, -4.95911207],
                            [39.79865513, -4.9585959],
                            [39.79828956, -4.95820878],
                            [39.79787022, -4.95763885],
                            [39.79770894, -4.95739152],
                            [39.7977842, -4.95713343],
                            [39.79799925, -4.95653124],
                            [39.79780571, -4.9561011],
                            [39.79817128, -4.95604734],
                            [39.7984831, -4.95519782],
                            [39.79833257, -4.95427302],
                            [39.79787023, -4.95301487],
                            [39.79762293, -4.95289658],
                            [39.79745089, -4.9526385],
                            [39.7974323, -4.95258737],
                            [39.79723585, -4.95204706],
                            [39.7970208, -4.95157391],
                            [39.79676275, -4.95146637],
                            [39.7965262, -4.95135884],
                            [39.79660147, -4.95103624],
                            [39.79659292, -4.95097216],
                            [39.79657996, -4.95087494],
                            [39.79654593, -4.95077544],
                            [39.79651191, -4.95067595],
                            [39.79633804, -4.95020285],
                            [39.79620364, -4.94967055],
                            [39.79605848, -4.94954151],
                            [39.7959187, -4.94933182],
                            [39.79586494, -4.94905223],
                            [39.79584344, -4.94888017],
                            [39.79575204, -4.94862747],
                            [39.79563377, -4.94829411],
                            [39.79541873, -4.94771343],
                            [39.7952897, -4.94729942],
                            [39.79499939, -4.94685315],
                            [39.79471983, -4.94671873],
                            [39.79430587, -4.94667572],
                            [39.79417147, -4.94668647],
                            [39.79414997, -4.94660582],
                            [39.79419298, -4.94655743],
                            [39.7943005, -4.94652517],
                            [39.79438652, -4.94650366],
                            [39.79458543, -4.94649291],
                            [39.7947736, -4.94652517],
                            [39.79491337, -4.94654668],
                            [39.79491875, -4.94646603],
                            [39.7949295, -4.9462886],
                            [39.79483273, -4.94613267],
                            [39.79469833, -4.94601976],
                            [39.79463919, -4.94581544],
                            [39.79453167, -4.94576705],
                            [39.79432738, -4.94557887],
                            [39.79365537, -4.94469171],
                            [39.79337581, -4.94436373],
                            [39.79302636, -4.94396585],
                            [39.79284895, -4.94367551],
                            [39.79270917, -4.94353571],
                            [39.79258846, -4.94339586],
                            [39.79253819, -4.94328424],
                            [39.79246692, -4.94302052],
                            [39.79232082, -4.94286015],
                            [39.79230657, -4.94264632],
                            [39.79224777, -4.94257683],
                            [39.79218898, -4.94250733],
                            [39.79205001, -4.94246457],
                            [39.79178988, -4.9423434],
                            [39.7917293, -4.94211175],
                            [39.79173286, -4.94195851],
                            [39.79166872, -4.94182308],
                            [39.79171505, -4.94170548],
                            [39.79165447, -4.94155223],
                            [39.79138365, -4.9408466],
                            [39.79124407, -4.94081348],
                            [39.79117341, -4.94079671],
                            [39.79107364, -4.9408858],
                            [39.79093348, -4.94094724],
                            [39.79083773, -4.94085555],
                            [39.79081351, -4.94083235],
                            [39.79060327, -4.94062921],
                            [39.79042154, -4.94040825],
                            [39.79024337, -4.94025145],
                            [39.79015816, -4.94014055],
                            [39.79011273, -4.93999379],
                            [39.79009875, -4.93994864],
                            [39.7900673, -4.93984703],
                            [39.78869877, -4.93542618],
                            [39.78806904, -4.93333575],
                            [39.78801286, -4.93314928],
                            [39.78775018, -4.93031774],
                            [39.78665296, -4.92837629],
                            [39.78642213, -4.92796785],
                            [39.78534219, -4.92625287],
                            [39.78426224, -4.9245379],
                            [39.78328445, -4.92342863],
                            [39.78252554, -4.92393476],
                            [39.78213604, -4.92278306],
                            [39.78155325, -4.92228655],
                            [39.7813403, -4.92210513],
                            [39.78060351, -4.92239988],
                            [39.78001407, -4.92399153],
                            [39.77868785, -4.92552423],
                            [39.77745003, -4.92726325],
                            [39.77585856, -4.92864857],
                            [39.77476811, -4.92970967],
                            [39.77452769, -4.9298658],
                            [39.77426709, -4.92900227],
                            [39.77335347, -4.92861909],
                            [39.77255774, -4.92832434],
                            [39.77058313, -4.92867804],
                            [39.76893272, -4.92802959],
                            [39.76701283, -4.92728416],
                            [39.76640531, -4.92676337],
                            [39.76527706, -4.92593879],
                            [39.76423561, -4.9251142],
                            [39.76276716, -4.92397819],
                            [39.76276206, -4.92397186],
                            [39.76268536, -4.92387659],
                            [39.76260357, -4.923775],
                            [39.76158984, -4.92251587],
                            [39.76100118, -4.92255385],
                            [39.75970992, -4.92074968],
                            [39.75874148, -4.91877459],
                            [39.75865967, -4.91831948],
                            [39.75843766, -4.91708437],
                            [39.75798192, -4.91636271],
                            [39.75722236, -4.91590692],
                            [39.75699922, -4.91582805],
                            [39.75640066, -4.91595562],
                            [39.75626329, -4.91614208],
                            [39.75597872, -4.91607339],
                            [39.75587079, -4.91649537],
                            [39.75584135, -4.91665239],
                            [39.75554697, -4.91631873],
                            [39.75537949, -4.91641906],
                            [39.75497785, -4.91653462],
                            [39.75498766, -4.91605376],
                            [39.75489935, -4.91573972],
                            [39.75455591, -4.91552382],
                            [39.75442835, -4.91520979],
                            [39.75409473, -4.91481724],
                            [39.75374148, -4.91471911],
                            [39.7535256, -4.91451302],
                            [39.75333917, -4.91454246],
                            [39.75315273, -4.91429712],
                            [39.75309385, -4.91409104],
                            [39.75291723, -4.91410085],
                            [39.75284854, -4.91396346],
                            [39.75267192, -4.91381626],
                            [39.75248548, -4.91378681],
                            [39.7523481, -4.91379663],
                            [39.75238735, -4.9141401],
                            [39.7525051, -4.91428731],
                            [39.75231867, -4.91437563],
                            [39.75216167, -4.91436582],
                            [39.75209298, -4.91406159],
                            [39.75201448, -4.91372793],
                            [39.75183786, -4.9136298],
                            [39.75161217, -4.91363961],
                            [39.75137667, -4.9137083],
                            [39.75126873, -4.91374756],
                            [39.75120986, -4.91361998],
                            [39.75115098, -4.91348259],
                            [39.75095473, -4.91358073],
                            [39.75080754, -4.91365924],
                            [39.75062111, -4.91366905],
                            [39.75043467, -4.91366905],
                            [39.75029729, -4.91361998],
                            [39.75024745, -4.9135633],
                            [39.75036285, -4.91343404],
                            [39.75085215, -4.91271386],
                            [39.75070444, -4.91237223],
                            [39.7504644, -4.91191981],
                            [39.75012282, -4.91210447],
                            [39.74979046, -4.91175361],
                            [39.74997511, -4.91145815],
                            [39.74976277, -4.91116269],
                            [39.74942118, -4.91070104],
                            [39.74896881, -4.91050714],
                            [39.74813793, -4.90937147],
                            [39.7478702, -4.90911294],
                            [39.74782404, -4.90848509],
                            [39.7476394, -4.90819886],
                            [39.74716856, -4.9080696],
                            [39.74698392, -4.90786647],
                            [39.74683621, -4.90761718],
                            [39.74650385, -4.90747868],
                            [39.74599609, -4.90765411],
                            [39.74569143, -4.90781107],
                            [39.74535908, -4.90811576],
                            [39.74525752, -4.90833736],
                            [39.74504246, -4.90850687],
                            [39.74495092, -4.90844585],
                            [39.74486702, -4.90841533],
                            [39.74478311, -4.90838482],
                            [39.74478311, -4.90812545],
                            [39.74484567, -4.90772942],
                            [39.74462563, -4.90744059],
                            [39.74439184, -4.90709674],
                            [39.7441993, -4.90686292],
                            [39.74377298, -4.90646405],
                            [39.74323663, -4.90635402],
                            [39.7430441, -4.90617522],
                            [39.74264527, -4.90631276],
                            [39.742494, -4.90618897],
                            [39.74274154, -4.90588638],
                            [39.74318162, -4.90569383],
                            [39.74287907, -4.9052537],
                            [39.74253526, -4.90534998],
                            [39.74226021, -4.90541875],
                            [39.74208265, -4.90489072],
                            [39.74174465, -4.90482581],
                            [39.74145738, -4.90491359],
                            [39.74121337, -4.90498223],
                            [39.74093886, -4.90498223],
                            [39.7408321, -4.90502036],
                            [39.74055073, -4.90494788],
                            [39.74029071, -4.9047687],
                            [39.73982237, -4.90465836],
                            [39.73936042, -4.90472294],
                            [39.73908591, -4.90484496],
                            [39.73900966, -4.9049746],
                            [39.73898678, -4.90514238],
                            [39.73879615, -4.90513475],
                            [39.73848777, -4.90500065],
                            [39.73833721, -4.90493517],
                            [39.73814038, -4.90491359],
                            [39.73797262, -4.90492122],
                            [39.73785062, -4.90477632],
                            [39.73791162, -4.90453228],
                            [39.73777437, -4.90392219],
                            [39.73751682, -4.90388681],
                            [39.73734735, -4.90392219],
                            [39.73728635, -4.9038383],
                            [39.73714909, -4.90393744],
                            [39.73704234, -4.90405946],
                            [39.73687458, -4.90419673],
                            [39.73660007, -4.90422724],
                            [39.73669158, -4.90383068],
                            [39.73669158, -4.90368578],
                            [39.73651619, -4.90362477],
                            [39.7363792, -4.90361884],
                            [39.73615781, -4.90371628],
                            [39.73595955, -4.9038383],
                            [39.73575367, -4.90382305],
                            [39.7354334, -4.90373916],
                            [39.73519208, -4.90356749],
                            [39.73496658, -4.90345515],
                            [39.73443643, -4.90305531],
                            [39.73426349, -4.90297178],
                            [39.73409055, -4.90288824],
                            [39.73333003, -4.90262852],
                            [39.73223097, -4.90257286],
                            [39.73191563, -4.90251721],
                            [39.7316344, -4.90240748],
                            [39.73067246, -4.90212899],
                            [39.73003961, -4.90210368],
                            [39.72895111, -4.90210368],
                            [39.72854608, -4.90202772],
                            [39.72788791, -4.9016986],
                            [39.72758415, -4.90131885],
                            [39.72700192, -4.90111631],
                            [39.72647033, -4.90076187],
                            [39.72566028, -4.90098973],
                            [39.72550839, -4.90142011],
                            [39.72528056, -4.90187582],
                            [39.72477428, -4.90255937],
                            [39.72419206, -4.90263532],
                            [39.7239136, -4.90304039],
                            [39.72363515, -4.90324293],
                            [39.72335669, -4.90367332],
                            [39.72340732, -4.90415434],
                            [39.72323012, -4.90440751],
                            [39.72262258, -4.90453409],
                            [39.72239475, -4.90617969],
                            [39.72317949, -4.90721769],
                            [39.72376171, -4.90762276],
                            [39.72378703, -4.90850885],
                            [39.72362248, -4.90934431],
                            [39.72345794, -4.91017977],
                            [39.72290103, -4.91139498],
                            [39.72239475, -4.91210385],
                            [39.72193909, -4.91228107],
                            [39.72118948, -4.91216992],
                            [39.72112904, -4.91180005],
                            [39.72130624, -4.91116713],
                            [39.72062276, -4.91152156],
                            [39.72029368, -4.91243297],
                            [39.72029368, -4.91245829],
                            [39.72054584, -4.91326892],
                            [39.72064807, -4.91359755],
                            [39.72082087, -4.91381545],
                            [39.72181252, -4.91506593],
                            [39.72190176, -4.91652896],
                            [39.72171714, -4.91700375],
                            [39.72137426, -4.91766319],
                            [39.72121602, -4.91937773],
                            [39.72055665, -4.92082849],
                            [39.7202509, -4.91991433],
                            [39.72090752, -4.91812668],
                            [39.7210247, -4.91780764],
                            [39.72136862, -4.91625986],
                            [39.72046585, -4.91441113],
                            [39.72045953, -4.91440032],
                            [39.71983589, -4.91333482],
                            [39.7196985, -4.91310009],
                            [39.71943412, -4.91264839],
                            [39.71999298, -4.91114361],
                            [39.72166955, -4.91041272],
                            [39.72265829, -4.90976782],
                            [39.72291623, -4.90895093],
                            [39.72205645, -4.90839202],
                            [39.72123966, -4.90882195],
                            [39.71900424, -4.90830602],
                            [39.71784354, -4.90886494],
                            [39.71513524, -4.90933787],
                            [39.71032115, -4.90873064],
                            [39.70828876, -4.90826717],
                            [39.70713931, -4.90800505],
                            [39.70223858, -4.9069732],
                            [39.70034707, -4.90602733],
                            [39.69945505, -4.90550066],
                            [39.69912189, -4.90516208],
                            [39.69878872, -4.90482351],
                            [39.6981224, -4.90414635],
                            [39.69705842, -4.90317899],
                            [39.69624163, -4.90223313],
                            [39.69583324, -4.90196442],
                            [39.69372721, -4.8998559],
                            [39.69304271, -4.89892683],
                            [39.69269937, -4.898445],
                            [39.69152703, -4.89679974],
                            [39.69120922, -4.89601736],
                            [39.69079363, -4.89511274],
                            [39.69104248, -4.89447473],
                            [39.69073731, -4.89396383],
                            [39.69075721, -4.89357236],
                            [39.69053215, -4.89332391],
                            [39.6904454, -4.89290222],
                            [39.69057809, -4.89239796],
                            [39.69043213, -4.89208611],
                            [39.69027291, -4.89182734],
                            [39.69018666, -4.89146241],
                            [39.69037906, -4.89118374],
                            [39.69034589, -4.89105104],
                            [39.69031271, -4.89091834],
                            [39.69016676, -4.89038754],
                            [39.69007388, -4.8900027],
                            [39.69020657, -4.88963114],
                            [39.69012032, -4.88939891],
                            [39.69003408, -4.88916669],
                            [39.68968246, -4.88829749],
                            [39.68954977, -4.88779986],
                            [39.68956304, -4.88736859],
                            [39.68952324, -4.88688423],
                            [39.68963602, -4.88653921],
                            [39.68958295, -4.88616764],
                            [39.68941046, -4.88570982],
                            [39.68911855, -4.88525864],
                            [39.68841531, -4.88423021],
                            [39.68745334, -4.88279703],
                            [39.68730272, -4.88255911],
                            [39.6860336, -4.88055438],
                            [39.68548296, -4.87943969],
                            [39.6846603, -4.87801979],
                            [39.68427551, -4.87732311],
                            [39.68382176, -4.87643048],
                            [39.68371411, -4.87627929],
                            [39.6836634, -4.87620808],
                            [39.68351805, -4.87600395],
                            [39.68346635, -4.87571959],
                            [39.68351851, -4.87566461],
                            [39.68370545, -4.87546755],
                            [39.68378299, -4.8752672],
                            [39.68352905, -4.87507462],
                            [39.68345343, -4.87472434],
                            [39.68343405, -4.8743495],
                            [39.68343405, -4.87408454],
                            [39.6835956, -4.87381957],
                            [39.68382823, -4.87361276],
                            [39.68397039, -4.8730182],
                            [39.68411255, -4.87267568],
                            [39.6842741, -4.87239132],
                            [39.68448088, -4.87214574],
                            [39.68456777, -4.87189469],
                            [39.6845972, -4.87180968],
                            [39.68453258, -4.87153178],
                            [39.6842741, -4.87107294],
                            [39.68411902, -4.87056885],
                            [39.68397685, -4.87034265],
                            [39.68389285, -4.87015524],
                            [39.68382823, -4.86995489],
                            [39.68366379, -4.86981356],
                            [39.68366022, -4.86966407],
                            [39.68354391, -4.86937325],
                            [39.68341353, -4.86900601],
                            [39.68340174, -4.86879161],
                            [39.6832921, -4.86869643],
                            [39.68327914, -4.86850724],
                            [39.68327251, -4.86841032],
                            [39.68317301, -4.8682265],
                            [39.68314973, -4.86793208],
                            [39.68294596, -4.86756724],
                            [39.68289772, -4.86707901],
                            [39.68282017, -4.86679465],
                            [39.68275153, -4.86652802],
                            [39.68271678, -4.86620655],
                            [39.68263924, -4.86605791],
                            [39.68248415, -4.86557321],
                            [39.68231206, -4.86513429],
                            [39.68225152, -4.86468136],
                            [39.68222568, -4.86415142],
                            [39.68221922, -4.86367318],
                            [39.68225167, -4.86338312],
                            [39.68223214, -4.8632208],
                            [39.68205121, -4.86292998],
                            [39.68208998, -4.86274902],
                            [39.68219983, -4.86250344],
                            [39.68227091, -4.8621997],
                            [39.68221276, -4.86212214],
                            [39.68214814, -4.86223201],
                            [39.68200597, -4.86241943],
                            [39.68182504, -4.86266501],
                            [39.68177334, -4.86287181],
                            [39.68165703, -4.863098],
                            [39.68140501, -4.86325311],
                            [39.68108838, -4.86356332],
                            [39.68077174, -4.86387352],
                            [39.68074589, -4.86423543],
                            [39.68066189, -4.86450686],
                            [39.68067481, -4.86490109],
                            [39.68051973, -4.86509497],
                            [39.68046157, -4.86545041],
                            [39.68041633, -4.8659545],
                            [39.68030002, -4.86614838],
                            [39.6801837, -4.86634872],
                            [39.68009324, -4.86677526],
                            [39.68011262, -4.86689805],
                            [39.67996723, -4.86717594],
                            [39.67982183, -4.86745384],
                            [39.67945996, -4.86784806],
                            [39.6790464, -4.86820351],
                            [39.6787233, -4.86823582],
                            [39.67840667, -4.8681518],
                            [39.67820635, -4.8681001],
                            [39.67766355, -4.86830691],
                            [39.67747615, -4.86848786],
                        ],
                        [
                            [39.85348156, -5.04796241],
                            [39.85279796, -5.04828626],
                            [39.85211435, -5.04828626],
                            [39.85188497, -5.04920932],
                            [39.85143074, -5.04918584],
                            [39.85119357, -5.0501797],
                            [39.85105708, -5.05075164],
                            [39.85033935, -5.05133333],
                            [39.85045211, -5.0520422],
                            [39.85048432, -5.05260607],
                            [39.85024269, -5.05308939],
                            [39.84996884, -5.0535566],
                            [39.84967888, -5.05379825],
                            [39.84945336, -5.05381436],
                            [39.84930838, -5.05358882],
                            [39.84900231, -5.05378214],
                            [39.84894211, -5.05407301],
                            [39.84887673, -5.05438892],
                            [39.84868014, -5.05458767],
                            [39.84884122, -5.05482933],
                            [39.84855127, -5.05500655],
                            [39.84829352, -5.05524821],
                            [39.84810022, -5.05545764],
                            [39.84805647, -5.05568408],
                            [39.84799645, -5.05599469],
                            [39.84813244, -5.05637595],
                            [39.84808567, -5.05661152],
                            [39.84793913, -5.05666594],
                            [39.84787469, -5.05653705],
                            [39.84760084, -5.05668205],
                            [39.84729478, -5.05697204],
                            [39.84703704, -5.05713315],
                            [39.84700294, -5.05732895],
                            [39.84692434, -5.05778039],
                            [39.84702093, -5.05834144],
                            [39.84706925, -5.05874421],
                            [39.8471659, -5.05913086],
                            [39.84724645, -5.05963029],
                            [39.84715127, -5.06012626],
                            [39.84712018, -5.06028823],
                            [39.84726255, -5.06066137],
                            [39.84743975, -5.06087081],
                            [39.84769749, -5.06114469],
                            [39.84810021, -5.06130579],
                            [39.84834184, -5.06159579],
                            [39.84848682, -5.06172467],
                            [39.84884122, -5.0614669],
                            [39.84908285, -5.06122524],
                            [39.84953389, -5.06106414],
                            [39.84956611, -5.06067748],
                            [39.84946946, -5.06025861],
                            [39.84950168, -5.06006528],
                            [39.84985607, -5.06016194],
                            [39.84998494, -5.06029083],
                            [39.85012992, -5.06046805],
                            [39.85041988, -5.06064526],
                            [39.85075816, -5.06077415],
                            [39.85132197, -5.06079026],
                            [39.85161193, -5.06011361],
                            [39.85182134, -5.05966252],
                            [39.85188578, -5.05930808],
                            [39.85186967, -5.0588731],
                            [39.85178913, -5.0587281],
                            [39.85172469, -5.05851866],
                            [39.85167637, -5.05832534],
                            [39.85195022, -5.05827701],
                            [39.8522885, -5.05818034],
                            [39.85262679, -5.058422],
                            [39.852659, -5.05874421],
                            [39.8528362, -5.05898587],
                            [39.852659, -5.05908254],
                            [39.852659, -5.05923559],
                            [39.852659, -5.05938864],
                            [39.85257727, -5.05962961],
                            [39.85270733, -5.05974307],
                            [39.85307783, -5.05958197],
                            [39.85333557, -5.05945308],
                            [39.85351277, -5.05930809],
                            [39.85349666, -5.05913087],
                            [39.85341612, -5.05900198],
                            [39.85341612, -5.05890532],
                            [39.85351277, -5.05879255],
                            [39.85372218, -5.05879255],
                            [39.85363026, -5.05904619],
                            [39.85362553, -5.05954975],
                            [39.85385105, -5.05964641],
                            [39.85399603, -5.05969474],
                            [39.85420545, -5.05969474],
                            [39.8543021, -5.05956586],
                            [39.85451151, -5.0596303],
                            [39.85462427, -5.0596303],
                            [39.85468871, -5.05974307],
                            [39.8541249, -5.06000084],
                            [39.8539316, -5.0600814],
                            [39.85382687, -5.06035602],
                            [39.85383494, -5.06083859],
                            [39.85360942, -5.06103192],
                            [39.85301339, -5.06117692],
                            [39.85288923, -5.06151534],
                            [39.85309394, -5.06177301],
                            [39.85343222, -5.06188578],
                            [39.85368996, -5.06182134],
                            [39.85388327, -5.06164412],
                            [39.8539477, -5.06182134],
                            [39.85372709, -5.06237107],
                            [39.8532067, -5.06265909],
                            [39.85272343, -5.06278798],
                            [39.85233682, -5.0631263],
                            [39.85203075, -5.0632874],
                            [39.85125753, -5.06341629],
                            [39.85075816, -5.06348073],
                            [39.85041987, -5.06381905],
                            [39.8504682, -5.06447959],
                            [39.85043598, -5.06504346],
                            [39.85017824, -5.06544622],
                            [39.85021046, -5.06592954],
                            [39.85027489, -5.06638064],
                            [39.85014588, -5.06676152],
                            [39.85001678, -5.06714264],
                            [39.85025878, -5.06746005],
                            [39.85054874, -5.06749227],
                            [39.8508387, -5.06734727],
                            [39.851032, -5.06699284],
                            [39.85137029, -5.06700895],
                            [39.8517569, -5.06710562],
                            [39.85207907, -5.06721839],
                            [39.85227238, -5.06734728],
                            [39.85246569, -5.06742783],
                            [39.85302949, -5.06744394],
                            [39.85315836, -5.06739561],
                            [39.85328723, -5.06734728],
                            [39.85338389, -5.06728284],
                            [39.85349665, -5.06710562],
                            [39.85362552, -5.0667673],
                            [39.85372217, -5.06642897],
                            [39.85391548, -5.0661712],
                            [39.85412489, -5.06604232],
                            [39.85430209, -5.06605843],
                            [39.85457594, -5.06633231],
                            [39.85491422, -5.06638064],
                            [39.85483368, -5.06686396],
                            [39.85464299, -5.06724402],
                            [39.85456226, -5.06740491],
                            [39.85483368, -5.06754061],
                            [39.85491422, -5.06760505],
                            [39.85475906, -5.06790473],
                            [39.85459204, -5.0684428],
                            [39.8543343, -5.06855557],
                            [39.85417322, -5.06863613],
                            [39.85373828, -5.06860391],
                            [39.85336778, -5.06847502],
                            [39.85273953, -5.06858779],
                            [39.85240125, -5.0687489],
                            [39.85219183, -5.06884556],
                            [39.85199853, -5.06895834],
                            [39.85185237, -5.06929406],
                            [39.85214351, -5.06948999],
                            [39.85238514, -5.0695061],
                            [39.85265899, -5.06939332],
                            [39.85277175, -5.06957054],
                            [39.8528684, -5.06965109],
                            [39.85315836, -5.06953832],
                            [39.85336777, -5.06944166],
                            [39.85381882, -5.06932888],
                            [39.85397991, -5.06932888],
                            [39.854141, -5.06973165],
                            [39.85426987, -5.07003775],
                            [39.85438263, -5.07034385],
                            [39.85462426, -5.07073051],
                            [39.85484978, -5.07085939],
                            [39.85533305, -5.07097217],
                            [39.85578409, -5.07105272],
                            [39.85594518, -5.07127827],
                            [39.85589685, -5.07171326],
                            [39.85566657, -5.07206934],
                            [39.8555525, -5.07224571],
                            [39.85555857, -5.07243823],
                            [39.85581631, -5.07266378],
                            [39.85602572, -5.07311488],
                            [39.85607405, -5.07364653],
                            [39.85613848, -5.07395263],
                            [39.85623513, -5.07425873],
                            [39.85629957, -5.07464539],
                            [39.85631568, -5.07503204],
                            [39.85626735, -5.07543481],
                            [39.85615459, -5.07596646],
                            [39.85596128, -5.07640144],
                            [39.85591295, -5.07670754],
                            [39.85594517, -5.07702976],
                            [39.85565521, -5.07714253],
                            [39.85544438, -5.07739913],
                            [39.85517455, -5.07772752],
                            [39.85497864, -5.07838304],
                            [39.85462542, -5.0788029],
                            [39.85438745, -5.07908576],
                            [39.85327111, -5.07941412],
                            [39.85293282, -5.07909191],
                            [39.85240123, -5.07930135],
                            [39.85170856, -5.07973633],
                            [39.85114475, -5.07983299],
                            [39.85072592, -5.08026798],
                            [39.85054872, -5.08104129],
                            [39.85054872, -5.08160516],
                            [39.84993122, -5.08246226],
                            [39.84976476, -5.0830084],
                            [39.8495983, -5.08355454],
                            [39.8494211, -5.08398953],
                            [39.84897005, -5.08511727],
                            [39.84855122, -5.08532671],
                            [39.8479552, -5.08545559],
                            [39.84764913, -5.08548781],
                            [39.84731085, -5.08521393],
                            [39.84700478, -5.08513338],
                            [39.8466826, -5.08537504],
                            [39.84655373, -5.08593891],
                            [39.84639264, -5.08648667],
                            [39.8457511, -5.08747054],
                            [39.8456242, -5.08766515],
                            [39.84523132, -5.08826767],
                            [39.84484005, -5.08886773],
                            [39.84492674, -5.08930602],
                            [39.84487841, -5.08991822],
                            [39.84476565, -5.09035321],
                            [39.84462067, -5.09069153],
                            [39.84455624, -5.09107819],
                            [39.84413741, -5.09122318],
                            [39.84407297, -5.0915615],
                            [39.8439441, -5.09191594],
                            [39.84374132, -5.09213378],
                            [39.84326753, -5.09235092],
                            [39.84271983, -5.09272147],
                            [39.84256274, -5.0929233],
                            [39.84249431, -5.09322089],
                            [39.84239765, -5.09378477],
                            [39.84218824, -5.09420364],
                            [39.84191415, -5.09443985],
                            [39.84164054, -5.09442919],
                            [39.84143113, -5.0941392],
                            [39.84106062, -5.09401031],
                            [39.84069012, -5.09386532],
                            [39.84058238, -5.09378488],
                            [39.84045256, -5.09375492],
                            [39.84026282, -5.09375991],
                            [39.84014299, -5.09377989],
                            [39.83994826, -5.09386977],
                            [39.83980345, -5.09390972],
                            [39.83931106, -5.09412418],
                            [39.83924339, -5.09409783],
                            [39.83924006, -5.09409374],
                            [39.83913185, -5.09403225],
                            [39.83912939, -5.09402979],
                            [39.839031, -5.0939981],
                            [39.83902856, -5.09398797],
                            [39.8389899, -5.09397697],
                            [39.83887194, -5.09394339],
                            [39.83882525, -5.0939301],
                            [39.83877855, -5.0939168],
                            [39.83837927, -5.09388221],
                            [39.83811858, -5.09387729],
                            [39.83802075, -5.09389065],
                            [39.83756974, -5.09391838],
                            [39.83741282, -5.09393574],
                            [39.83708966, -5.09404998],
                            [39.83682779, -5.0943063],
                            [39.83670243, -5.09448183],
                            [39.8366495, -5.09468382],
                            [39.83647956, -5.09481059],
                            [39.83640156, -5.09489139],
                            [39.83629291, -5.09508085],
                            [39.83626227, -5.09528981],
                            [39.83623441, -5.09544862],
                            [39.83624382, -5.0955606],
                            [39.83624597, -5.09565099],
                            [39.83622446, -5.09585114],
                            [39.83618787, -5.09599964],
                            [39.83617066, -5.09617827],
                            [39.83615559, -5.09634829],
                            [39.83619648, -5.096514],
                            [39.83620509, -5.09659794],
                            [39.83622665, -5.09680479],
                            [39.83618174, -5.09704221],
                            [39.8360855, -5.09754913],
                            [39.83597322, -5.09778334],
                            [39.83586094, -5.09801755],
                            [39.83554655, -5.09885815],
                            [39.83533482, -5.09924957],
                            [39.83505893, -5.09967307],
                            [39.83482796, -5.09997466],
                            [39.83451357, -5.10035966],
                            [39.83423768, -5.10070616],
                            [39.8336859, -5.10143767],
                            [39.83344851, -5.10220768],
                            [39.83350625, -5.10243226],
                            [39.83371798, -5.1026376],
                            [39.83405161, -5.10268893],
                            [39.83438525, -5.10264402],
                            [39.83474454, -5.1025606],
                            [39.83518083, -5.10235527],
                            [39.8353621, -5.10229484],
                            [39.83545031, -5.10226543],
                            [39.83580319, -5.10211143],
                            [39.83611757, -5.10199593],
                            [39.83631585, -5.10194986],
                            [39.83649979, -5.10192822],
                            [39.83674866, -5.10184165],
                            [39.83690015, -5.10179836],
                            [39.83706246, -5.10183082],
                            [39.83716226, -5.10187526],
                            [39.837217, -5.10189963],
                            [39.83739675, -5.10194957],
                            [39.83752658, -5.10203945],
                            [39.8376564, -5.10200949],
                            [39.83780619, -5.10203945],
                            [39.83808581, -5.10201948],
                            [39.8383055, -5.10197953],
                            [39.83846528, -5.10179976],
                            [39.83868498, -5.10162997],
                            [39.83882479, -5.10172985],
                            [39.83891081, -5.10169113],
                            [39.83902452, -5.10163996],
                            [39.8391843, -5.10158003],
                            [39.83941398, -5.1014502],
                            [39.83953382, -5.10130039],
                            [39.83960372, -5.10122049],
                            [39.83994325, -5.10120052],
                            [39.84029277, -5.10093086],
                            [39.84037266, -5.10062125],
                            [39.84052246, -5.10023174],
                            [39.84076213, -5.10003199],
                            [39.84092191, -5.0997124],
                            [39.84120152, -5.09950266],
                            [39.84163093, -5.09950266],
                            [39.8418706, -5.09966246],
                            [39.84203038, -5.09989217],
                            [39.84218018, -5.10000203],
                            [39.84197046, -5.10033162],
                            [39.84159099, -5.10065121],
                            [39.8411416, -5.10096082],
                            [39.84097184, -5.10120052],
                            [39.84089195, -5.10142024],
                            [39.84094852, -5.10151797],
                            [39.8410018, -5.10161],
                            [39.84134133, -5.10177979],
                            [39.84139126, -5.1018497],
                            [39.84157101, -5.10193958],
                            [39.84175077, -5.10169989],
                            [39.84184064, -5.10162998],
                            [39.8421602, -5.1017698],
                            [39.84217019, -5.10194957],
                            [39.8423799, -5.10232909],
                            [39.84252969, -5.10242897],
                            [39.84264953, -5.10269863],
                            [39.84286922, -5.10282846],
                            [39.84307894, -5.10288839],
                            [39.84328865, -5.10285843],
                            [39.84332859, -5.10299825],
                            [39.84321874, -5.10321797],
                            [39.84313885, -5.10341772],
                            [39.8432487, -5.10340773],
                            [39.84333858, -5.10332783],
                            [39.84350835, -5.10329787],
                            [39.84361819, -5.10321797],
                            [39.843758, -5.10300824],
                            [39.84388782, -5.10292834],
                            [39.84424733, -5.10302821],
                            [39.84466675, -5.10312809],
                            [39.84483501, -5.10318662],
                            [39.84489644, -5.10320799],
                            [39.84504623, -5.10318801],
                            [39.84540573, -5.10326791],
                            [39.84541007, -5.1032676],
                            [39.84568535, -5.10324794],
                            [39.84604486, -5.1032779],
                            [39.84624458, -5.10317803],
                            [39.84638439, -5.1030382],
                            [39.84640814, -5.10301445],
                            [39.84649424, -5.10292834],
                            [39.84664403, -5.10292834],
                            [39.84672392, -5.10299825],
                            [39.84668585, -5.10311249],
                            [39.84663405, -5.10326791],
                            [39.84653418, -5.10350761],
                            [39.84683377, -5.10364743],
                            [39.84734307, -5.10388713],
                            [39.84766263, -5.10396703],
                            [39.84794224, -5.10386716],
                            [39.84836167, -5.10384718],
                            [39.84853143, -5.10384718],
                            [39.84890093, -5.103997],
                            [39.84909066, -5.10433657],
                            [39.8494302, -5.10449637],
                            [39.84987958, -5.1046262],
                            [39.85035892, -5.10489586],
                            [39.85090816, -5.1051056],
                            [39.85156726, -5.1052654],
                            [39.85193675, -5.10552507],
                            [39.8523362, -5.1055051],
                            [39.85276561, -5.10549511],
                            [39.85319502, -5.10586464],
                            [39.85406382, -5.10606439],
                            [39.85427353, -5.10617425],
                            [39.85478283, -5.10662369],
                            [39.85518228, -5.10681345],
                            [39.85569158, -5.10682344],
                            [39.85614096, -5.10691332],
                            [39.85638063, -5.10711307],
                            [39.8565504, -5.10714303],
                            [39.85673015, -5.10708311],
                            [39.85776872, -5.10721295],
                            [39.85941646, -5.10707313],
                            [39.86167335, -5.10711308],
                            [39.86257211, -5.10679348],
                            [39.86304421, -5.10601781],
                            [39.86327115, -5.10564493],
                            [39.86412998, -5.10397704],
                            [39.86462569, -5.10259666],
                            [39.86463928, -5.10255883],
                            [39.86471503, -5.10212421],
                            [39.8648294, -5.10146806],
                            [39.86543045, -5.09908863],
                            [39.86665344, -5.09590162],
                            [39.86693564, -5.09516624],
                            [39.86702774, -5.09492625],
                            [39.86720857, -5.09445502],
                            [39.86783466, -5.09307745],
                            [39.86846076, -5.09169989],
                            [39.87091506, -5.08628983],
                            [39.87096515, -5.08618964],
                            [39.8715662, -5.08183154],
                            [39.87161629, -5.07857548],
                            [39.87170296, -5.07775211],
                            [39.87191682, -5.07572017],
                            [39.87181597, -5.07403905],
                            [39.87161631, -5.07071085],
                            [39.87101526, -5.06785554],
                            [39.87175518, -5.06630154],
                            [39.87201701, -5.06575162],
                            [39.87171649, -5.06434901],
                            [39.87031404, -5.06404845],
                            [39.87041421, -5.0629464],
                            [39.86891159, -5.06304659],
                            [39.86781433, -5.06430386],
                            [39.86723038, -5.06497297],
                            [39.86596634, -5.06501852],
                            [39.86380758, -5.06530638],
                            [39.86359159, -5.06534418],
                            [39.86236841, -5.06555826],
                            [39.8620446, -5.06512646],
                            [39.86149862, -5.06390437],
                            [39.86128904, -5.06343524],
                            [39.86056946, -5.06174403],
                            [39.86013771, -5.06109633],
                            [39.85952606, -5.05994486],
                            [39.85923823, -5.05832561],
                            [39.8587705, -5.0568503],
                            [39.85807981, -5.05495068],
                            [39.857907, -5.0544754],
                            [39.85747525, -5.05332393],
                            [39.85812288, -5.0505892],
                            [39.85938216, -5.04778251],
                            [39.85992185, -5.04526368],
                            [39.86010175, -5.04292476],
                            [39.86010175, -5.0404779],
                            [39.85963403, -5.03878668],
                            [39.8588065, -5.03835488],
                            [39.85765517, -5.03975823],
                            [39.85701146, -5.0418379],
                            [39.85564032, -5.04360844],
                            [39.85438105, -5.0449758],
                            [39.8534096, -5.04652308],
                            [39.85321483, -5.04725564],
                            [39.85348156, -5.04796241],
                        ],
                        [
                            [39.74214011, -5.44049572],
                            [39.74216598, -5.44015561],
                            [39.74216229, -5.43998926],
                            [39.7421512, -5.43985987],
                            [39.74219925, -5.4398229],
                            [39.74228427, -5.43976006],
                            [39.74233971, -5.43967133],
                            [39.74239516, -5.43953455],
                            [39.74243951, -5.4394791],
                            [39.74252453, -5.43945322],
                            [39.74256149, -5.4393645],
                            [39.7425578, -5.43926469],
                            [39.7424543, -5.43912421],
                            [39.74221034, -5.43903549],
                            [39.74198117, -5.43907985],
                            [39.74185549, -5.43909833],
                            [39.74175569, -5.43909833],
                            [39.7416374, -5.43909463],
                            [39.74154913, -5.43908232],
                            [39.74147846, -5.43907245],
                            [39.7413343, -5.43904288],
                            [39.74115688, -5.43888022],
                            [39.74109034, -5.43872126],
                            [39.74102011, -5.43854751],
                            [39.74096097, -5.43829613],
                            [39.7409314, -5.43816305],
                            [39.74087965, -5.43807802],
                            [39.7408279, -5.43802626],
                            [39.74074658, -5.43793754],
                            [39.74065048, -5.43774531],
                            [39.74060982, -5.43766768],
                            [39.74055067, -5.4375198],
                            [39.74048044, -5.43744217],
                            [39.74038064, -5.43739042],
                            [39.74024388, -5.43732387],
                            [39.74017365, -5.43726842],
                            [39.74009972, -5.43710207],
                            [39.73994447, -5.43670651],
                            [39.73987794, -5.43646992],
                            [39.73984097, -5.43630726],
                            [39.73980771, -5.43612242],
                            [39.7397923, -5.43591908],
                            [39.73978923, -5.43587843],
                            [39.73979292, -5.43578232],
                            [39.73979292, -5.43572317],
                            [39.73978923, -5.43567141],
                            [39.73984467, -5.43553833],
                            [39.73990012, -5.43547548],
                            [39.73990948, -5.43546499],
                            [39.73999253, -5.43537197],
                            [39.74007385, -5.43530174],
                            [39.74011451, -5.43527216],
                            [39.74016626, -5.43518344],
                            [39.74018104, -5.43512429],
                            [39.74020322, -5.43504296],
                            [39.74019213, -5.43498751],
                            [39.74014408, -5.43485073],
                            [39.74006645, -5.43475461],
                            [39.73998883, -5.43466589],
                            [39.73991121, -5.43456608],
                            [39.73985207, -5.43449584],
                            [39.73983728, -5.4344219],
                            [39.73974487, -5.4343036],
                            [39.73970791, -5.43424076],
                            [39.73965986, -5.43411876],
                            [39.73957114, -5.43397829],
                            [39.73950091, -5.43387847],
                            [39.7394159, -5.43376018],
                            [39.73934936, -5.43370842],
                            [39.73927543, -5.43372321],
                            [39.7392126, -5.43366036],
                            [39.73906959, -5.43352515],
                            [39.7390093, -5.43346813],
                            [39.73895385, -5.43339419],
                            [39.7389021, -5.43330547],
                            [39.73887992, -5.43324263],
                            [39.7388023, -5.43319826],
                            [39.73874316, -5.43309475],
                            [39.73866923, -5.43294319],
                            [39.738599, -5.43285077],
                            [39.73857313, -5.43274356],
                            [39.73855095, -5.43262896],
                            [39.73847702, -5.43256611],
                            [39.73845088, -5.43247878],
                            [39.73846224, -5.43247369],
                            [39.73848072, -5.43238867],
                            [39.7384992, -5.43231104],
                            [39.73848072, -5.43221492],
                            [39.73846224, -5.43209293],
                            [39.73839201, -5.43192657],
                            [39.73832547, -5.43186373],
                            [39.7382922, -5.43179718],
                            [39.73823306, -5.4316641],
                            [39.73821828, -5.43161234],
                            [39.73820719, -5.43153471],
                            [39.7381961, -5.43146078],
                            [39.73814805, -5.43138684],
                            [39.73808521, -5.43135357],
                            [39.7380889, -5.4313203],
                            [39.7380926, -5.43124267],
                            [39.73804455, -5.43115025],
                            [39.73811478, -5.43114655],
                            [39.73812217, -5.4310948],
                            [39.73812217, -5.43103934],
                            [39.73810881, -5.43097474],
                            [39.73809999, -5.43093214],
                            [39.73805194, -5.43084341],
                            [39.73800759, -5.43079166],
                            [39.73793735, -5.43075469],
                            [39.73791953, -5.43074852],
                            [39.73784125, -5.43072142],
                            [39.73773775, -5.43070294],
                            [39.73763425, -5.43069185],
                            [39.73754554, -5.43069924],
                            [39.73744204, -5.43070663],
                            [39.73731637, -5.43073621],
                            [39.7371833, -5.43075099],
                            [39.7370724, -5.43080275],
                            [39.73700957, -5.4308545],
                            [39.73696521, -5.43093953],
                            [39.73686541, -5.43105043],
                            [39.73675082, -5.43114655],
                            [39.73669168, -5.43117982],
                            [39.73662884, -5.43124636],
                            [39.736566, -5.43123897],
                            [39.73649577, -5.43122788],
                            [39.73637749, -5.43122788],
                            [39.73627399, -5.43122788],
                            [39.73621115, -5.43123527],
                            [39.73617419, -5.43129812],
                            [39.73615201, -5.43133878],
                            [39.73610396, -5.43135726],
                            [39.7360596, -5.43136096],
                            [39.73594871, -5.43141272],
                            [39.73592653, -5.43149774],
                            [39.73593392, -5.43163083],
                            [39.73599676, -5.43178609],
                            [39.73608178, -5.43191548],
                            [39.7361594, -5.43193026],
                            [39.73625275, -5.43189748],
                            [39.73630294, -5.43185433],
                            [39.73634792, -5.43181566],
                            [39.73644772, -5.43185633],
                            [39.73654013, -5.43190069],
                            [39.73662145, -5.43194875],
                            [39.73668059, -5.43199681],
                            [39.73673234, -5.43206705],
                            [39.73682105, -5.43206705],
                            [39.73690607, -5.43203747],
                            [39.73695042, -5.4321188],
                            [39.73686541, -5.43234431],
                            [39.73684323, -5.43242563],
                            [39.73681366, -5.43252175],
                            [39.7367693, -5.4325772],
                            [39.73671755, -5.43261417],
                            [39.73671385, -5.43262156],
                            [39.73665471, -5.43262526],
                            [39.73658818, -5.43264374],
                            [39.73649947, -5.4326955],
                            [39.73643663, -5.43271768],
                            [39.73629986, -5.4328064],
                            [39.73627029, -5.43285816],
                            [39.73625181, -5.43296536],
                            [39.73625181, -5.43307257],
                            [39.73632204, -5.43314281],
                            [39.73635161, -5.43319087],
                            [39.73635161, -5.43320196],
                            [39.73634791, -5.43324262],
                            [39.73631095, -5.43325741],
                            [39.73628138, -5.43325741],
                            [39.73627768, -5.43325371],
                            [39.7362592, -5.43322784],
                            [39.73621115, -5.43322784],
                            [39.73604481, -5.43321674],
                            [39.73597828, -5.43322044],
                            [39.73590435, -5.43323523],
                            [39.73588956, -5.43320196],
                            [39.73587848, -5.43317238],
                            [39.73583782, -5.43317608],
                            [39.73576758, -5.4332722],
                            [39.73573062, -5.43329807],
                            [39.73565669, -5.43330177],
                            [39.73563821, -5.43330177],
                            [39.73559755, -5.43331286],
                            [39.7355458, -5.43332395],
                            [39.73549775, -5.43336092],
                            [39.73546448, -5.43341637],
                            [39.73543491, -5.43341637],
                            [39.73540904, -5.43340528],
                            [39.73538316, -5.43332395],
                            [39.73539056, -5.43325001],
                            [39.73541643, -5.43317978],
                            [39.73541643, -5.4331502],
                            [39.73538686, -5.43312432],
                            [39.73531663, -5.4330393],
                            [39.73531663, -5.43299863],
                            [39.7353425, -5.43293209],
                            [39.73535359, -5.43282119],
                            [39.73533141, -5.43275095],
                            [39.73528706, -5.43270289],
                            [39.7352464, -5.43270289],
                            [39.73518356, -5.43272137],
                            [39.73511702, -5.43272137],
                            [39.73505049, -5.43271398],
                            [39.73497656, -5.43268441],
                            [39.73491742, -5.43264004],
                            [39.73489524, -5.43255132],
                            [39.73485458, -5.43249217],
                            [39.73478435, -5.43245521],
                            [39.73471412, -5.43244412],
                            [39.73460693, -5.43247739],
                            [39.73456257, -5.43253284],
                            [39.73454778, -5.43262526],
                            [39.73457366, -5.43273616],
                            [39.73458105, -5.43278792],
                            [39.73456627, -5.4328064],
                            [39.73451452, -5.4328027],
                            [39.73448864, -5.43276204],
                            [39.7344332, -5.43274725],
                            [39.73440363, -5.43278052],
                            [39.73435188, -5.43286555],
                            [39.73430752, -5.43295057],
                            [39.73428165, -5.43308735],
                            [39.73427425, -5.43321674],
                            [39.73431122, -5.43329807],
                            [39.73441841, -5.43334983],
                            [39.73451082, -5.43329807],
                            [39.73454039, -5.43334243],
                            [39.73449234, -5.43343116],
                            [39.73439623, -5.43353467],
                            [39.73430382, -5.43367884],
                            [39.73415227, -5.43381562],
                            [39.73410422, -5.43385628],
                            [39.73407095, -5.43386368],
                            [39.73405986, -5.43382671],
                            [39.7340266, -5.43379344],
                            [39.73397485, -5.43380823],
                            [39.73389722, -5.43388956],
                            [39.73387135, -5.4339524],
                            [39.73389722, -5.43405221],
                            [39.73382699, -5.43411506],
                            [39.73375306, -5.43404852],
                            [39.73366435, -5.43404112],
                            [39.7335091, -5.43415942],
                            [39.73342039, -5.43427032],
                            [39.73327254, -5.43423336],
                            [39.73301379, -5.4342925],
                            [39.73293247, -5.43440341],
                            [39.73282897, -5.43464],
                            [39.73279201, -5.43485441],
                            [39.73267372, -5.43500968],
                            [39.73245194, -5.43516494],
                            [39.73206807, -5.43535039],
                            [39.73183582, -5.43546259],
                            [39.7315796, -5.43558637],
                            [39.73144883, -5.43571062],
                            [39.73137441, -5.43578133],
                            [39.73135782, -5.43579709],
                            [39.73123953, -5.43595975],
                            [39.73122248, -5.43603722],
                            [39.73115452, -5.43608544],
                            [39.7310658, -5.43610762],
                            [39.73102514, -5.43618156],
                            [39.73102665, -5.4361903],
                            [39.73104363, -5.43628876],
                            [39.73109907, -5.43634052],
                            [39.73107951, -5.43640337],
                            [39.73097709, -5.43649578],
                            [39.73090969, -5.43649107],
                            [39.73084402, -5.43654754],
                            [39.73075161, -5.4367065],
                            [39.7305594, -5.43681001],
                            [39.73037828, -5.43679892],
                            [39.73010475, -5.43682479],
                            [39.72996798, -5.4370429],
                            [39.72980904, -5.43736822],
                            [39.72976442, -5.43757907],
                            [39.72962052, -5.43770832],
                            [39.72952072, -5.43801885],
                            [39.72939504, -5.43807431],
                            [39.7290598, -5.43820495],
                            [39.72885907, -5.43838483],
                            [39.72875188, -5.43861773],
                            [39.72866316, -5.43874712],
                            [39.72850792, -5.43880996],
                            [39.72848204, -5.43898371],
                            [39.72845617, -5.43915376],
                            [39.72854118, -5.43924249],
                            [39.72869643, -5.43924988],
                            [39.72885907, -5.43929055],
                            [39.72901801, -5.43933491],
                            [39.72911042, -5.43935339],
                            [39.72924719, -5.43946429],
                            [39.7293396, -5.4395678],
                            [39.72948006, -5.4396861],
                            [39.72958725, -5.43983028],
                            [39.72981273, -5.44006317],
                            [39.72996798, -5.44028128],
                            [39.73007887, -5.44040328],
                            [39.73018237, -5.4404957],
                            [39.73023781, -5.44051049],
                            [39.73032678, -5.44053492],
                            [39.73029695, -5.4406103],
                            [39.73030435, -5.44071751],
                            [39.73039306, -5.44092083],
                            [39.73059636, -5.44115373],
                            [39.73068137, -5.44132378],
                            [39.73081075, -5.44144947],
                            [39.73093273, -5.4415271],
                            [39.7310621, -5.44157886],
                            [39.73111015, -5.4415345],
                            [39.73121365, -5.44155298],
                            [39.73123213, -5.44161213],
                            [39.73131802, -5.44187042],
                            [39.73130606, -5.44194114],
                            [39.73133193, -5.44207792],
                            [39.73139477, -5.44220361],
                            [39.7314687, -5.44227755],
                            [39.73157959, -5.44235888],
                            [39.73171266, -5.4424513],
                            [39.73181616, -5.44248087],
                            [39.73189008, -5.44247718],
                            [39.73200097, -5.44244021],
                            [39.73211556, -5.44244021],
                            [39.73211926, -5.44237367],
                            [39.73219319, -5.44232561],
                            [39.73225602, -5.442333],
                            [39.73235213, -5.44236997],
                            [39.7324117, -5.44243286],
                            [39.73241127, -5.44251045],
                            [39.73244831, -5.44261396],
                            [39.73244823, -5.44277662],
                            [39.73245193, -5.44278401],
                            [39.73245193, -5.442906],
                            [39.73250368, -5.44310193],
                            [39.73250818, -5.44310829],
                            [39.73261087, -5.4432535],
                            [39.7327883, -5.44340507],
                            [39.73291764, -5.44346073],
                            [39.73292137, -5.44352706],
                            [39.73283635, -5.44368972],
                            [39.73263675, -5.4438265],
                            [39.73257021, -5.44389304],
                            [39.73245932, -5.44397437],
                            [39.73234104, -5.44402613],
                            [39.73229668, -5.44411485],
                            [39.73216361, -5.44422575],
                            [39.73203794, -5.44423684],
                            [39.73199728, -5.4442849],
                            [39.73201929, -5.44440036],
                            [39.73201206, -5.44455477],
                            [39.73205642, -5.44467676],
                            [39.73210447, -5.44471743],
                            [39.73213774, -5.44469155],
                            [39.73219318, -5.44466567],
                            [39.7322576, -5.44471761],
                            [39.73225602, -5.44479506],
                            [39.73228559, -5.44490226],
                            [39.73235582, -5.4449762],
                            [39.73242975, -5.44504644],
                            [39.73249259, -5.44509819],
                            [39.73255173, -5.44516104],
                            [39.73259918, -5.44522373],
                            [39.73259978, -5.44526455],
                            [39.73251477, -5.44527564],
                            [39.73243714, -5.44540872],
                            [39.73232995, -5.44556769],
                            [39.73225602, -5.44572295],
                            [39.73213774, -5.44585973],
                            [39.73209222, -5.44600091],
                            [39.73208599, -5.44618874],
                            [39.73219688, -5.44624789],
                            [39.73224289, -5.44628887],
                            [39.73224123, -5.44637728],
                            [39.73230777, -5.44645122],
                            [39.73231516, -5.44644382],
                            [39.73234473, -5.44642904],
                            [39.73238217, -5.44648823],
                            [39.73238169, -5.44656582],
                            [39.73232255, -5.44659909],
                            [39.73214882, -5.44656582],
                            [39.73206381, -5.44652885],
                            [39.73195292, -5.44649558],
                            [39.7318716, -5.44644012],
                            [39.73181615, -5.44644382],
                            [39.7317644, -5.44645861],
                            [39.73168308, -5.44645491],
                            [39.73161285, -5.44643643],
                            [39.73141694, -5.44642164],
                            [39.73128018, -5.44637358],
                            [39.73117298, -5.44633661],
                            [39.73102143, -5.44629965],
                            [39.73086249, -5.44631443],
                            [39.73077008, -5.44635879],
                            [39.73079965, -5.4465843],
                            [39.73085939, -5.44681307],
                            [39.7308477, -5.44689852],
                            [39.73076638, -5.44702791],
                            [39.73069985, -5.4471573],
                            [39.73064848, -5.44734959],
                            [39.73062222, -5.44752698],
                            [39.73055166, -5.44769417],
                            [39.73053721, -5.44778945],
                            [39.73046375, -5.44779496],
                            [39.73045589, -5.4478449],
                            [39.73039305, -5.4478449],
                            [39.73028585, -5.44778945],
                            [39.73007516, -5.44779684],
                            [39.72984599, -5.44780423],
                            [39.72974249, -5.44781902],
                            [39.72967226, -5.44778945],
                            [39.72949853, -5.44782641],
                            [39.72934698, -5.44785969],
                            [39.72915846, -5.44793732],
                            [39.72909562, -5.44802604],
                            [39.72910671, -5.44815912],
                            [39.72891081, -5.44824415],
                            [39.72882579, -5.44828851],
                            [39.72876665, -5.4483033],
                            [39.72869642, -5.4483033],
                            [39.72862249, -5.44825154],
                            [39.72853378, -5.44814803],
                            [39.7284118, -5.44812216],
                            [39.72821959, -5.44813325],
                            [39.72803846, -5.44815173],
                            [39.72782038, -5.44819609],
                            [39.72770949, -5.44828112],
                            [39.72753283, -5.44838259],
                            [39.72748922, -5.44849979],
                            [39.72747292, -5.44854359],
                            [39.72735094, -5.44858795],
                            [39.72722526, -5.44860274],
                            [39.72712546, -5.4486434],
                            [39.72703675, -5.44867297],
                            [39.72695543, -5.44873952],
                            [39.72684823, -5.44878018],
                            [39.72678909, -5.44882824],
                            [39.72672625, -5.44892066],
                            [39.72679762, -5.44903956],
                            [39.72676322, -5.44912398],
                            [39.72668929, -5.44917574],
                            [39.7265784, -5.44922749],
                            [39.72646011, -5.44926446],
                            [39.72646185, -5.44938835],
                            [39.72642685, -5.44947148],
                            [39.72631965, -5.44957499],
                            [39.72618289, -5.4496785],
                            [39.72609048, -5.44976722],
                            [39.72601571, -5.44979713],
                            [39.72597959, -5.44981158],
                            [39.72588348, -5.44987812],
                            [39.72579477, -5.44993357],
                            [39.72578737, -5.44991139],
                            [39.72570975, -5.44987443],
                            [39.725658, -5.44993357],
                            [39.72558777, -5.45001121],
                            [39.72550645, -5.45010363],
                            [39.72549906, -5.45021823],
                            [39.72557298, -5.4503624],
                            [39.72559516, -5.45042155],
                            [39.72554711, -5.45042155],
                            [39.72546579, -5.45041416],
                            [39.72541774, -5.45038828],
                            [39.72533755, -5.45035797],
                            [39.72532163, -5.45039197],
                            [39.72534011, -5.45044373],
                            [39.72542143, -5.45055094],
                            [39.72543991, -5.45066554],
                            [39.72542883, -5.45077644],
                            [39.72536968, -5.45100564],
                            [39.72536968, -5.45120157],
                            [39.72536968, -5.45150471],
                            [39.72534381, -5.45143816],
                            [39.72528097, -5.45121636],
                            [39.72526619, -5.45100564],
                            [39.72523292, -5.45089104],
                            [39.72512572, -5.4508171],
                            [39.72514051, -5.45072468],
                            [39.72497048, -5.45044003],
                            [39.72490764, -5.45032543],
                            [39.72483741, -5.45030695],
                            [39.72481153, -5.45041046],
                            [39.72475239, -5.45033652],
                            [39.72450843, -5.45007035],
                            [39.72439384, -5.44998163],
                            [39.72430513, -5.44988551],
                            [39.7241388, -5.44974873],
                            [39.72396876, -5.44957498],
                            [39.723832, -5.4494456],
                            [39.72367675, -5.44931621],
                            [39.72351781, -5.4492127],
                            [39.72347136, -5.44921371],
                            [39.72334777, -5.4492164],
                            [39.72324058, -5.44926076],
                            [39.72313708, -5.44928294],
                            [39.7230188, -5.4493199],
                            [39.72294117, -5.44935318],
                            [39.72284137, -5.44936427],
                            [39.72269352, -5.44938645],
                            [39.72255108, -5.4494909],
                            [39.72253973, -5.44949923],
                            [39.72246434, -5.44960825],
                            [39.72241999, -5.44976721],
                            [39.72243107, -5.44992248],
                            [39.72247577, -5.45004881],
                            [39.72251609, -5.45016277],
                            [39.72258263, -5.45033652],
                            [39.72261959, -5.45053984],
                            [39.72267503, -5.45061008],
                            [39.7227083, -5.45064705],
                            [39.7227083, -5.45077274],
                            [39.72274526, -5.45099455],
                            [39.72275266, -5.4511572],
                            [39.7228081, -5.45129768],
                            [39.72283505, -5.45140402],
                            [39.72276134, -5.45152847],
                            [39.72266279, -5.45169489],
                            [39.72262018, -5.45176684],
                            [39.72259371, -5.45181153],
                            [39.72254936, -5.45181893],
                            [39.72247141, -5.45185498],
                            [39.72243107, -5.45192244],
                            [39.72240889, -5.45201855],
                            [39.72233497, -5.45202964],
                            [39.72228691, -5.45198528],
                            [39.72222919, -5.45191202],
                            [39.72220929, -5.45194462],
                            [39.72216124, -5.45197419],
                            [39.7220947, -5.4519594],
                            [39.72206513, -5.45190026],
                            [39.72203556, -5.45179305],
                            [39.72203556, -5.45158233],
                            [39.72203556, -5.45144186],
                            [39.72196533, -5.45114611],
                            [39.72195055, -5.45087255],
                            [39.72185444, -5.45063226],
                            [39.72179699, -5.4505156],
                            [39.72173246, -5.45038458],
                            [39.72165484, -5.45021822],
                            [39.72160679, -5.45011471],
                            [39.72160309, -5.44995945],
                            [39.72164005, -5.44984485],
                            [39.72161048, -5.44964152],
                            [39.7215957, -5.44947517],
                            [39.72156243, -5.44946038],
                            [39.72152916, -5.44933839],
                            [39.72155873, -5.44924597],
                            [39.72157722, -5.44910179],
                            [39.72155134, -5.44893913],
                            [39.7214885, -5.44887259],
                            [39.72142567, -5.44885411],
                            [39.72137761, -5.44877648],
                            [39.72130775, -5.44869663],
                            [39.72127411, -5.44865818],
                            [39.72124064, -5.44847641],
                            [39.72122237, -5.44837722],
                            [39.72112996, -5.44818129],
                            [39.72105973, -5.44808148],
                            [39.72092666, -5.44797427],
                            [39.72089584, -5.44794743],
                            [39.72081207, -5.44787446],
                            [39.72075293, -5.44781901],
                            [39.72061986, -5.44775616],
                            [39.72053115, -5.44772659],
                            [39.720472, -5.44767483],
                            [39.72035002, -5.44760459],
                            [39.72023174, -5.44765635],
                            [39.7201726, -5.44780052],
                            [39.72015781, -5.44809627],
                            [39.72011346, -5.44846594],
                            [39.72005431, -5.44869514],
                            [39.71999517, -5.44893174],
                            [39.72000626, -5.4494308],
                            [39.71999887, -5.44958607],
                            [39.71998778, -5.44984484],
                            [39.7199693, -5.45017016],
                            [39.71995821, -5.45041784],
                            [39.71997669, -5.45062856],
                            [39.71989906, -5.45066922],
                            [39.71985471, -5.45082449],
                            [39.71983253, -5.45096497],
                            [39.7198584, -5.45106478],
                            [39.71992124, -5.4511609],
                            [39.72001417, -5.4512418],
                            [39.71998221, -5.45128312],
                            [39.71991182, -5.45137409],
                            [39.7198247, -5.45148671],
                            [39.71973642, -5.45160081],
                            [39.71978078, -5.45174129],
                            [39.71991754, -5.4519594],
                            [39.72000995, -5.45212576],
                            [39.72010606, -5.45229581],
                            [39.72017998, -5.45243629],
                            [39.7202613, -5.45262482],
                            [39.72030566, -5.45272094],
                            [39.7202907, -5.45281044],
                            [39.72024282, -5.4528799],
                            [39.72009127, -5.4528762],
                            [39.72002843, -5.45287251],
                            [39.71995451, -5.45290578],
                            [39.71992781, -5.45307262],
                            [39.71992493, -5.45309062],
                            [39.71993789, -5.45310789],
                            [39.72001365, -5.45320891],
                            [39.72000995, -5.45326437],
                            [39.72000279, -5.45333956],
                            [39.71999517, -5.45341963],
                            [39.72003094, -5.45344278],
                            [39.72018368, -5.45354162],
                            [39.72025391, -5.45361186],
                            [39.72032044, -5.45369689],
                            [39.72032216, -5.45381261],
                            [39.72027239, -5.45388912],
                            [39.72027609, -5.45412941],
                            [39.72035741, -5.45422183],
                            [39.72033893, -5.45433274],
                            [39.72039437, -5.45456563],
                            [39.72055701, -5.45475787],
                            [39.72063463, -5.45492792],
                            [39.7206679, -5.45503143],
                            [39.72068269, -5.45520148],
                            [39.7206642, -5.45537153],
                            [39.72074552, -5.45557855],
                            [39.72079727, -5.45578927],
                            [39.72085641, -5.45605544],
                            [39.72092295, -5.4560998],
                            [39.72095252, -5.45625876],
                            [39.72098948, -5.45654711],
                            [39.72109298, -5.45675783],
                            [39.72110777, -5.4570166],
                            [39.72116691, -5.45720883],
                            [39.72117351, -5.45732861],
                            [39.72114127, -5.45739198],
                            [39.7210708, -5.45753045],
                            [39.72101905, -5.45757851],
                            [39.72094143, -5.45761178],
                            [39.72080836, -5.45780771],
                            [39.72075291, -5.45799625],
                            [39.72075419, -5.45819095],
                            [39.72071225, -5.45827351],
                            [39.72070856, -5.45843616],
                            [39.72076031, -5.4585138],
                            [39.72078988, -5.45855446],
                            [39.72079357, -5.45861361],
                            [39.72074182, -5.4586247],
                            [39.72064202, -5.45858403],
                            [39.72053899, -5.45858427],
                            [39.72050526, -5.45865058],
                            [39.72051634, -5.45875409],
                            [39.72059027, -5.45878736],
                            [39.72073073, -5.45879475],
                            [39.72074552, -5.4588502],
                            [39.72077377, -5.45909544],
                            [39.72074552, -5.45915334],
                            [39.72067159, -5.45916073],
                            [39.72057918, -5.45923097],
                            [39.72052004, -5.45929382],
                            [39.72051634, -5.4594306],
                            [39.72062354, -5.45960435],
                            [39.72061615, -5.45976331],
                            [39.72060875, -5.45991857],
                            [39.72061984, -5.46016995],
                            [39.72069766, -5.4602444],
                            [39.72070486, -5.46025128],
                            [39.72081205, -5.46034001],
                            [39.72089337, -5.46039176],
                            [39.72099687, -5.46045091],
                            [39.7210708, -5.46041764],
                            [39.72113364, -5.46041025],
                            [39.7211669, -5.46049527],
                            [39.7211743, -5.46062096],
                            [39.72116024, -5.46069334],
                            [39.7211515, -5.46073836],
                            [39.72113415, -5.46082777],
                            [39.72108189, -5.46094628],
                            [39.7209673, -5.46110154],
                            [39.72092664, -5.46125681],
                            [39.72095621, -5.46146013],
                            [39.72101905, -5.46175587],
                            [39.7210671, -5.46199247],
                            [39.72107449, -5.46210337],
                            [39.72094142, -5.46216991],
                            [39.72086749, -5.46221797],
                            [39.72087858, -5.46235106],
                            [39.72096002, -5.4624266],
                            [39.72094512, -5.46246196],
                            [39.72084532, -5.46249153],
                            [39.72071964, -5.46265049],
                            [39.72062353, -5.46297951],
                            [39.72062353, -5.46308671],
                            [39.72064941, -5.4632161],
                            [39.7206568, -5.46330852],
                            [39.72054961, -5.4633307],
                            [39.72052003, -5.46348597],
                            [39.72053112, -5.4636782],
                            [39.7205496, -5.46380389],
                            [39.72061244, -5.46388152],
                            [39.72068267, -5.4639037],
                            [39.72076399, -5.46397764],
                            [39.72077508, -5.46412551],
                            [39.72075468, -5.46429483],
                            [39.72073812, -5.46443234],
                            [39.72066075, -5.46474075],
                            [39.72055346, -5.46500985],
                            [39.72047568, -5.46520497],
                            [39.7204535, -5.46534914],
                            [39.72040544, -5.46558574],
                            [39.72035369, -5.46581864],
                            [39.72038696, -5.46600347],
                            [39.72050524, -5.46611068],
                            [39.72061983, -5.46616613],
                            [39.72071594, -5.46621049],
                            [39.72076399, -5.46635837],
                            [39.72078986, -5.46647297],
                            [39.72081574, -5.46652842],
                            [39.72087488, -5.46653211],
                            [39.72092293, -5.46651363],
                            [39.7209562, -5.46650993],
                            [39.72097468, -5.46656539],
                            [39.72099686, -5.46664302],
                            [39.7210634, -5.46667629],
                            [39.72113732, -5.4666689],
                            [39.72117059, -5.46659866],
                            [39.72118538, -5.46650993],
                            [39.72123712, -5.46639903],
                            [39.72128518, -5.46634728],
                            [39.72138128, -5.46634358],
                            [39.72147369, -5.46633249],
                            [39.72151435, -5.46630661],
                            [39.72154762, -5.46625486],
                            [39.72158089, -5.46616983],
                            [39.72165851, -5.4660885],
                            [39.72176571, -5.46600348],
                            [39.72185072, -5.46595172],
                            [39.72186408, -5.4659374],
                            [39.72190247, -5.46589627],
                            [39.7219764, -5.465863],
                            [39.7220725, -5.46584821],
                            [39.72213165, -5.46596281],
                            [39.72213534, -5.46605154],
                            [39.72214273, -5.46623268],
                            [39.72215013, -5.46640273],
                            [39.72220557, -5.46646188],
                            [39.72222418, -5.46648713],
                            [39.72225732, -5.46653212],
                            [39.72233864, -5.4665506],
                            [39.72241257, -5.46652103],
                            [39.72244214, -5.46646188],
                            [39.72253455, -5.46641752],
                            [39.72255303, -5.46638425],
                            [39.72257151, -5.4663177],
                            [39.72257822, -5.46631245],
                            [39.72265653, -5.46625116],
                            [39.72273046, -5.46618462],
                            [39.72280069, -5.46614765],
                            [39.72288201, -5.4660922],
                            [39.7229929, -5.46605523],
                            [39.723089, -5.46599978],
                            [39.72312227, -5.46591106],
                            [39.72312966, -5.46587039],
                            [39.72311858, -5.46576688],
                            [39.72311118, -5.46566707],
                            [39.72315924, -5.46560423],
                            [39.72324056, -5.46558944],
                            [39.72336254, -5.46555987],
                            [39.72344386, -5.46554508],
                            [39.72350669, -5.46552659],
                            [39.72358062, -5.46551181],
                            [39.72366194, -5.46545636],
                            [39.72372108, -5.46546375],
                            [39.72379501, -5.46555987],
                            [39.72386524, -5.46568186],
                            [39.72397613, -5.46578907],
                            [39.72403897, -5.46590736],
                            [39.72405062, -5.46606009],
                            [39.7240057, -5.46622529],
                            [39.72387263, -5.46646927],
                            [39.72374326, -5.46657278],
                            [39.7236301, -5.46660808],
                            [39.72359541, -5.46673175],
                            [39.72353626, -5.4667798],
                            [39.723503, -5.46676132],
                            [39.72344016, -5.46682416],
                            [39.72348082, -5.46690549],
                            [39.72340689, -5.46703118],
                            [39.72328491, -5.46707555],
                            [39.72322207, -5.46719384],
                            [39.7229751, -5.46719718],
                            [39.72294854, -5.46719754],
                            [39.72277481, -5.46717166],
                            [39.72271567, -5.46722342],
                            [39.72256781, -5.46742304],
                            [39.72243475, -5.46745262],
                            [39.72230907, -5.46747849],
                            [39.72220187, -5.46754873],
                            [39.7220725, -5.46764115],
                            [39.72193943, -5.46774836],
                            [39.72190247, -5.46778902],
                            [39.72192465, -5.46787774],
                            [39.72199118, -5.46787774],
                            [39.72202075, -5.4679332],
                            [39.72204293, -5.4680441],
                            [39.72209098, -5.46809216],
                            [39.72220557, -5.46807367],
                            [39.72231276, -5.46801083],
                            [39.72236553, -5.46802622],
                            [39.72240148, -5.46803671],
                            [39.72246432, -5.46801083],
                            [39.72251976, -5.46799604],
                            [39.72259046, -5.4680143],
                            [39.72257346, -5.46807093],
                            [39.72254933, -5.46815131],
                            [39.72256412, -5.46820676],
                            [39.7225789, -5.46825482],
                            [39.72246432, -5.46826221],
                            [39.72230907, -5.46831027],
                            [39.72230907, -5.46846184],
                            [39.72240517, -5.46853208],
                            [39.72251606, -5.46856535],
                            [39.72259369, -5.46856535],
                            [39.72266022, -5.4686208],
                            [39.72274893, -5.46861341],
                            [39.72287091, -5.46857644],
                            [39.72289309, -5.4685062],
                            [39.72285983, -5.46835463],
                            [39.72294854, -5.468277],
                            [39.7229855, -5.46821415],
                            [39.72313336, -5.46826961],
                            [39.723296, -5.46837681],
                            [39.72341058, -5.46848772],
                            [39.7236952, -5.46876128],
                            [39.72387263, -5.46900896],
                            [39.7239059, -5.46918641],
                            [39.72385784, -5.46922707],
                            [39.72381349, -5.46925295],
                            [39.72372558, -5.46936768],
                            [39.72368411, -5.46953021],
                            [39.72367705, -5.46953771],
                            [39.72362497, -5.46959305],
                            [39.7234993, -5.46970026],
                            [39.72342167, -5.46974462],
                            [39.72334035, -5.46982965],
                            [39.72328491, -5.46993685],
                            [39.72319989, -5.47008473],
                            [39.72311857, -5.47018823],
                            [39.72297811, -5.47025108],
                            [39.7228783, -5.47038047],
                            [39.7227822, -5.47047658],
                            [39.72266392, -5.47067251],
                            [39.72263804, -5.47085365],
                            [39.722675, -5.47103849],
                            [39.72274523, -5.47117158],
                            [39.72304834, -5.47136751],
                            [39.72310009, -5.47139339],
                            [39.72324424, -5.47145623],
                            [39.72338101, -5.47153386],
                            [39.72344754, -5.47162259],
                            [39.72346972, -5.47163368],
                            [39.72355843, -5.47170761],
                            [39.72363236, -5.47174088],
                            [39.7236915, -5.47175567],
                            [39.72376173, -5.47179634],
                            [39.72383196, -5.47180003],
                            [39.72393916, -5.47178155],
                            [39.72408332, -5.47178155],
                            [39.72418682, -5.47180003],
                            [39.72437533, -5.47180743],
                            [39.72454906, -5.47174828],
                            [39.72465256, -5.47176676],
                            [39.72473018, -5.47177785],
                            [39.72479302, -5.47174828],
                            [39.72485955, -5.47170022],
                            [39.72493718, -5.47164847],
                            [39.72505916, -5.47160041],
                            [39.72516635, -5.47155235],
                            [39.72528464, -5.47147472],
                            [39.72534378, -5.47138969],
                            [39.72543249, -5.47141927],
                            [39.72556186, -5.471386],
                            [39.72565427, -5.47132315],
                            [39.72577995, -5.47127509],
                            [39.72591672, -5.47121594],
                            [39.72600173, -5.47117528],
                            [39.72600912, -5.47111983],
                            [39.72606457, -5.47110504],
                            [39.72612002, -5.47110504],
                            [39.72618285, -5.47108656],
                            [39.72626417, -5.4710422],
                            [39.72633441, -5.47098674],
                            [39.72642681, -5.47094978],
                            [39.72655249, -5.47087214],
                            [39.72657097, -5.470813],
                            [39.7266449, -5.47082409],
                            [39.72671143, -5.47078712],
                            [39.7267447, -5.47077603],
                            [39.72681493, -5.47080191],
                            [39.72692952, -5.470813],
                            [39.7270515, -5.47076864],
                            [39.727155, -5.47073167],
                            [39.72722153, -5.47062816],
                            [39.72722153, -5.47058749],
                            [39.72730655, -5.47065034],
                            [39.7273583, -5.47066143],
                            [39.72748028, -5.47057271],
                            [39.72750246, -5.47047659],
                            [39.72751355, -5.47039156],
                            [39.7275616, -5.4703546],
                            [39.72763553, -5.47024369],
                            [39.72767988, -5.4702326],
                            [39.72773533, -5.47022521],
                            [39.72777599, -5.47016236],
                            [39.72778338, -5.4701217],
                            [39.72787209, -5.47016976],
                            [39.72794233, -5.47018085],
                            [39.72795711, -5.47011431],
                            [39.72797929, -5.47006625],
                            [39.72802734, -5.47007364],
                            [39.72805322, -5.47007734],
                            [39.72811236, -5.47003298],
                            [39.72811975, -5.46995535],
                            [39.72809018, -5.46992947],
                            [39.72806061, -5.4698925],
                            [39.72806431, -5.46988511],
                            [39.72813454, -5.46990729],
                            [39.72819737, -5.46991468],
                            [39.72826391, -5.46985923],
                            [39.72829348, -5.46979269],
                            [39.72836371, -5.46970396],
                            [39.72841176, -5.46962264],
                            [39.72840437, -5.46957088],
                            [39.72842285, -5.4695487],
                            [39.72848569, -5.46951543],
                            [39.72847091, -5.46946367],
                            [39.72843394, -5.4694378],
                            [39.72842285, -5.46940083],
                            [39.72843025, -5.4693232],
                            [39.72844134, -5.46926405],
                            [39.7284746, -5.46921599],
                            [39.72845982, -5.46917532],
                            [39.72836371, -5.46915314],
                            [39.7283748, -5.46900897],
                            [39.72836002, -5.46864668],
                            [39.72828979, -5.46856905],
                            [39.72821956, -5.4682881],
                            [39.72816041, -5.46801823],
                            [39.72812345, -5.4678223],
                            [39.7280754, -5.46774467],
                            [39.72803474, -5.46761898],
                            [39.72802735, -5.46758201],
                            [39.72799408, -5.46750438],
                            [39.72794233, -5.46741935],
                            [39.72787579, -5.46733802],
                            [39.72779078, -5.46721973],
                            [39.7277498, -5.46713963],
                            [39.72770946, -5.46706076],
                            [39.72770946, -5.46689811],
                            [39.72772595, -5.46680245],
                            [39.72772794, -5.4667909],
                            [39.72775382, -5.4666763],
                            [39.72774642, -5.46651734],
                            [39.72771316, -5.46637316],
                            [39.72768788, -5.46632008],
                            [39.72767619, -5.46629553],
                            [39.72764293, -5.46618093],
                            [39.72764335, -5.46617501],
                            [39.72765401, -5.46602567],
                            [39.72764662, -5.46594434],
                            [39.72768728, -5.46582234],
                            [39.72770946, -5.46567078],
                            [39.72773534, -5.46554139],
                            [39.72775012, -5.46540091],
                            [39.72774273, -5.4652974],
                            [39.72772794, -5.46519759],
                            [39.72770577, -5.46503863],
                            [39.72766511, -5.46500166],
                            [39.72759857, -5.46494621],
                            [39.72749507, -5.46476506],
                            [39.72749507, -5.46475028],
                            [39.72746181, -5.46473549],
                            [39.72737309, -5.46474658],
                            [39.72729547, -5.46473919],
                            [39.72719612, -5.464688],
                            [39.72717349, -5.46467634],
                            [39.72702933, -5.4644952],
                            [39.72697758, -5.46426969],
                            [39.7269628, -5.4638002],
                            [39.72697389, -5.46365233],
                            [39.72697019, -5.46338616],
                            [39.72694801, -5.46316805],
                            [39.72692584, -5.46294625],
                            [39.72689626, -5.46283904],
                            [39.72688854, -5.46279784],
                            [39.72687409, -5.46272074],
                            [39.72689996, -5.46257657],
                            [39.72694801, -5.46250263],
                            [39.72699976, -5.46243609],
                            [39.72713653, -5.46240282],
                            [39.72723633, -5.46238064],
                            [39.72731765, -5.46234367],
                            [39.72738419, -5.46227343],
                            [39.72740267, -5.4621921],
                            [39.72735462, -5.46212926],
                            [39.72723264, -5.4619703],
                            [39.72717349, -5.46193333],
                            [39.7270663, -5.46191854],
                            [39.72695541, -5.46191854],
                            [39.72684821, -5.46191854],
                            [39.72679646, -5.46197399],
                            [39.72677059, -5.46197399],
                            [39.72671884, -5.46194442],
                            [39.72671145, -5.46188897],
                            [39.72670775, -5.46181133],
                            [39.72672993, -5.46180394],
                            [39.72682973, -5.46175588],
                            [39.72681495, -5.46171522],
                            [39.7267558, -5.46168564],
                            [39.72662643, -5.46168195],
                            [39.72663013, -5.46162649],
                            [39.72668188, -5.46158953],
                            [39.72667818, -5.46153408],
                            [39.72658947, -5.46154147],
                            [39.72648967, -5.46137511],
                            [39.72644161, -5.46129748],
                            [39.72639726, -5.46118288],
                            [39.72634883, -5.46113202],
                            [39.72632333, -5.46110525],
                            [39.72620874, -5.46100543],
                            [39.72610894, -5.46088714],
                            [39.7260535, -5.46087235],
                            [39.72602023, -5.46085017],
                            [39.72604241, -5.46079102],
                            [39.7260535, -5.4607023],
                            [39.72598327, -5.46068012],
                            [39.72593521, -5.4607023],
                            [39.72588346, -5.46071709],
                            [39.72583911, -5.46068751],
                            [39.725743, -5.46054334],
                            [39.72575779, -5.46050267],
                            [39.72581323, -5.46044352],
                            [39.72583911, -5.46040286],
                            [39.72582432, -5.46034001],
                            [39.72579475, -5.46028826],
                            [39.72573191, -5.46022172],
                            [39.72566908, -5.46017735],
                            [39.72559515, -5.46016626],
                            [39.72552122, -5.46017735],
                            [39.72552122, -5.46009972],
                            [39.72556927, -5.46008863],
                            [39.7256469, -5.46005536],
                            [39.72560993, -5.46002579],
                            [39.72558776, -5.46000361],
                            [39.72559515, -5.45997773],
                            [39.72558406, -5.45993337],
                            [39.72549535, -5.45992228],
                            [39.72543251, -5.45993706],
                            [39.72535789, -5.45998756],
                            [39.7253438, -5.46003688],
                            [39.72528465, -5.46003688],
                            [39.7252366, -5.45995555],
                            [39.7252366, -5.45983355],
                            [39.72524769, -5.45974853],
                            [39.72530683, -5.45963762],
                            [39.7253438, -5.4595489],
                            [39.72528465, -5.45951933],
                            [39.72526617, -5.45947497],
                            [39.72535119, -5.45930861],
                            [39.72548426, -5.45903505],
                            [39.72555416, -5.45891474],
                            [39.7256432, -5.45876149],
                            [39.7258502, -5.45844726],
                            [39.72612004, -5.45802953],
                            [39.72622723, -5.45789644],
                            [39.72631964, -5.45774857],
                            [39.7264601, -5.45754894],
                            [39.72665971, -5.45730496],
                            [39.72671515, -5.45720145],
                            [39.72690736, -5.45705358],
                            [39.72697759, -5.45694637],
                            [39.72704782, -5.45683916],
                            [39.72718459, -5.45670608],
                            [39.72732136, -5.45658039],
                            [39.72740268, -5.45651385],
                            [39.727484, -5.45642882],
                            [39.72762076, -5.45635489],
                            [39.72770208, -5.45630683],
                            [39.72786472, -5.45620702],
                            [39.72790538, -5.45610351],
                            [39.72798301, -5.45608872],
                            [39.72806433, -5.45607023],
                            [39.72818261, -5.45610351],
                            [39.72821588, -5.45603696],
                            [39.72819, -5.45589649],
                            [39.72823806, -5.45584843],
                            [39.72828241, -5.45577449],
                            [39.72833786, -5.45575231],
                            [39.72843027, -5.45574492],
                            [39.72852637, -5.45574122],
                            [39.7285966, -5.45568207],
                            [39.72862987, -5.45556378],
                            [39.72866314, -5.45555638],
                            [39.72874076, -5.45558226],
                            [39.72884056, -5.45560814],
                            [39.72889231, -5.45554529],
                            [39.72891449, -5.45544178],
                            [39.72897363, -5.4554196],
                            [39.72908083, -5.45531609],
                            [39.72913258, -5.45526803],
                            [39.72921759, -5.45526434],
                            [39.72933218, -5.45533458],
                            [39.72945786, -5.45536415],
                            [39.72959832, -5.45536785],
                            [39.729724, -5.45534197],
                            [39.72984598, -5.45531609],
                            [39.72996056, -5.45526804],
                            [39.73008254, -5.45524586],
                            [39.73017865, -5.45530131],
                            [39.73036716, -5.4555379],
                            [39.73060743, -5.45569317],
                            [39.73076267, -5.45575971],
                            [39.73079964, -5.45593715],
                            [39.73074789, -5.45608503],
                            [39.73078485, -5.45615157],
                            [39.73089205, -5.45621072],
                            [39.73092162, -5.45631423],
                            [39.73118775, -5.45662845],
                            [39.7312469, -5.45671348],
                            [39.73131343, -5.45677632],
                            [39.73137257, -5.45674305],
                            [39.73139105, -5.45666912],
                            [39.73148716, -5.4566876],
                            [39.7316461, -5.45673566],
                            [39.73183092, -5.45678372],
                            [39.73187897, -5.45682438],
                            [39.73197508, -5.45686135],
                            [39.73213033, -5.45685765],
                            [39.73230406, -5.45679481],
                            [39.73241495, -5.45676154],
                            [39.73249627, -5.45670609],
                            [39.73255911, -5.45663955],
                            [39.73261085, -5.45653604],
                            [39.73264412, -5.45641774],
                            [39.73265151, -5.45631423],
                            [39.7326663, -5.4562292],
                            [39.73269217, -5.45622181],
                            [39.73280676, -5.45629205],
                            [39.73293613, -5.45642144],
                            [39.73301006, -5.45642513],
                            [39.73312465, -5.4563512],
                            [39.73325033, -5.45627726],
                            [39.73336491, -5.45613678],
                            [39.73340557, -5.45603697],
                            [39.73342036, -5.45597782],
                            [39.73339448, -5.45588171],
                            [39.73335752, -5.45581886],
                            [39.73329468, -5.45582625],
                            [39.73326142, -5.45587431],
                            [39.73319858, -5.45586322],
                            [39.73312465, -5.45567099],
                            [39.73315422, -5.45557487],
                            [39.73320967, -5.45555269],
                            [39.73336861, -5.4554344],
                            [39.73349059, -5.45526434],
                            [39.7335793, -5.45518671],
                            [39.73371237, -5.45501296],
                            [39.7337826, -5.45487249],
                            [39.73383435, -5.45473201],
                            [39.7338861, -5.45441409],
                            [39.73380478, -5.45418488],
                            [39.73373825, -5.45407768],
                            [39.73352756, -5.45397047],
                            [39.7332799, -5.45378563],
                            [39.73313944, -5.45372279],
                            [39.73292135, -5.45353425],
                            [39.73286221, -5.45346771],
                            [39.73295462, -5.45332723],
                            [39.73300267, -5.4532422],
                            [39.73298419, -5.45319415],
                            [39.73295092, -5.45317936],
                            [39.73286221, -5.45317566],
                            [39.73279272, -5.45316573],
                            [39.73275871, -5.45316087],
                            [39.73267739, -5.45311651],
                            [39.73261825, -5.45292058],
                            [39.73266261, -5.45276532],
                            [39.73282895, -5.45249545],
                            [39.73292875, -5.45238825],
                            [39.73311674, -5.45211282],
                            [39.73335013, -5.45177089],
                            [39.73353495, -5.45158605],
                            [39.73403766, -5.4510611],
                            [39.73443687, -5.45069882],
                            [39.73449971, -5.45068773],
                            [39.7346032, -5.45078015],
                            [39.73477324, -5.45078754],
                            [39.73487673, -5.45071731],
                            [39.73508743, -5.45070252],
                            [39.7352168, -5.45065076],
                            [39.73536096, -5.45067664],
                            [39.73545706, -5.45066925],
                            [39.73553099, -5.45063598],
                            [39.73556426, -5.45055834],
                            [39.7356234, -5.45055834],
                            [39.73574168, -5.45053986],
                            [39.73580452, -5.45039199],
                            [39.73584149, -5.45034024],
                            [39.73590802, -5.45035502],
                            [39.73597086, -5.45036242],
                            [39.73600782, -5.45028848],
                            [39.7361372, -5.4502626],
                            [39.7363368, -5.45019236],
                            [39.73645139, -5.4500408],
                            [39.73648096, -5.44994098],
                            [39.73658076, -5.44981529],
                            [39.73676928, -5.44963045],
                            [39.73690234, -5.44956022],
                            [39.73700584, -5.44953064],
                            [39.73697997, -5.44960088],
                            [39.73689126, -5.44966373],
                            [39.73698736, -5.44972287],
                            [39.73713522, -5.44966373],
                            [39.73720914, -5.44954173],
                            [39.73731264, -5.44952325],
                            [39.73739026, -5.44951955],
                            [39.73763422, -5.44948258],
                            [39.73773403, -5.44950107],
                            [39.73794102, -5.44951955],
                            [39.73808148, -5.44964524],
                            [39.73807251, -5.44984461],
                            [39.73812214, -5.44997426],
                            [39.73821825, -5.45010364],
                            [39.73836388, -5.45040908],
                            [39.73842894, -5.45057683],
                            [39.73840641, -5.45066853],
                            [39.73844704, -5.45077433],
                            [39.73848225, -5.450866],
                            [39.73851984, -5.45096386],
                            [39.73856571, -5.45108329],
                            [39.73861746, -5.45112765],
                            [39.7387764, -5.45117571],
                            [39.73886511, -5.45119789],
                            [39.73902406, -5.45124595],
                            [39.73913125, -5.45135316],
                            [39.73919778, -5.45143818],
                            [39.73926432, -5.45150842],
                            [39.73939739, -5.45162672],
                            [39.7394898, -5.45169696],
                            [39.73954894, -5.45174132],
                            [39.73961178, -5.45175241],
                            [39.73965244, -5.45172653],
                            [39.73967831, -5.45168587],
                            [39.73969679, -5.45161933],
                            [39.73975224, -5.45150842],
                            [39.73981508, -5.45143079],
                            [39.73987052, -5.45135316],
                            [39.73998881, -5.45131989],
                            [39.74012188, -5.45127922],
                            [39.74017362, -5.45126444],
                            [39.74025864, -5.4511942],
                            [39.7402993, -5.45113875],
                            [39.74033996, -5.45110548],
                            [39.7404028, -5.45105742],
                            [39.74048042, -5.45099088],
                            [39.74051739, -5.45098348],
                            [39.7406024, -5.45098718],
                            [39.74067263, -5.45102045],
                            [39.74078353, -5.45106481],
                            [39.74089072, -5.45106851],
                            [39.74097204, -5.45106851],
                            [39.74103118, -5.45106481],
                            [39.74109772, -5.45103893],
                            [39.74115316, -5.45097609],
                            [39.74118273, -5.45086888],
                            [39.74122709, -5.45083931],
                            [39.74129362, -5.45080604],
                            [39.74137125, -5.45080604],
                            [39.74153019, -5.4507358],
                            [39.74160412, -5.45067665],
                            [39.74169653, -5.45061011],
                            [39.74177785, -5.45057684],
                            [39.74188874, -5.45052508],
                            [39.7419257, -5.4505066],
                            [39.74196267, -5.45047333],
                            [39.74198854, -5.45040679],
                            [39.74202181, -5.45034394],
                            [39.74206247, -5.45025152],
                            [39.74211791, -5.45021086],
                            [39.74215488, -5.4501665],
                            [39.74222511, -5.45006668],
                            [39.74230273, -5.44997057],
                            [39.74240993, -5.44987445],
                            [39.74245798, -5.44984118],
                            [39.7425393, -5.44978942],
                            [39.74259475, -5.44977833],
                            [39.74268716, -5.44975985],
                            [39.7427463, -5.44972658],
                            [39.74274999, -5.44963416],
                            [39.74277956, -5.44957871],
                            [39.74282392, -5.44950847],
                            [39.74288676, -5.44944562],
                            [39.7429459, -5.44939757],
                            [39.74303092, -5.44934581],
                            [39.7430494, -5.44931254],
                            [39.74307527, -5.4492497],
                            [39.74308267, -5.44909443],
                            [39.74307897, -5.44895765],
                            [39.74308636, -5.4488948],
                            [39.74308636, -5.44880608],
                            [39.74307158, -5.44870997],
                            [39.74306788, -5.44857688],
                            [39.74301983, -5.44851404],
                            [39.74296808, -5.4484438],
                            [39.74290155, -5.44836986],
                            [39.74288306, -5.44829593],
                            [39.74290524, -5.44815175],
                            [39.74297917, -5.44802976],
                            [39.74305679, -5.44800758],
                            [39.74313442, -5.4479854],
                            [39.74317508, -5.44789298],
                            [39.74319356, -5.44785971],
                            [39.74324531, -5.44779316],
                            [39.74327118, -5.4477525],
                            [39.74333033, -5.44769705],
                            [39.7433599, -5.44768226],
                            [39.74342643, -5.4476342],
                            [39.74348927, -5.44760832],
                            [39.74354841, -5.44756766],
                            [39.74359277, -5.44747154],
                            [39.74359277, -5.44741979],
                            [39.74357798, -5.44733846],
                            [39.74352623, -5.4472941],
                            [39.74348188, -5.44725343],
                            [39.74344491, -5.44721277],
                            [39.74345231, -5.44714992],
                            [39.74343752, -5.44710187],
                            [39.74341904, -5.44704641],
                            [39.74338947, -5.44697618],
                            [39.7433599, -5.44692072],
                            [39.74333772, -5.4468357],
                            [39.74331924, -5.44680243],
                            [39.74333772, -5.44661389],
                            [39.74336359, -5.4464845],
                            [39.74342274, -5.44630706],
                            [39.74344861, -5.44613331],
                            [39.74345231, -5.44599283],
                            [39.74348558, -5.44590411],
                            [39.74353732, -5.44588932],
                            [39.74360016, -5.44586714],
                            [39.7436667, -5.44578951],
                            [39.74369257, -5.44561946],
                            [39.74368518, -5.44553813],
                            [39.74361865, -5.4453348],
                            [39.74357059, -5.44518324],
                            [39.74348927, -5.44504646],
                            [39.7433599, -5.44486162],
                            [39.74327119, -5.44474332],
                            [39.74318617, -5.44465829],
                            [39.74309746, -5.44452151],
                            [39.74303832, -5.44439582],
                            [39.74296439, -5.44431449],
                            [39.74288677, -5.44419619],
                            [39.74283502, -5.44402614],
                            [39.74280175, -5.44386348],
                            [39.74279907, -5.44384828],
                            [39.74277957, -5.44373779],
                            [39.74277218, -5.44363428],
                            [39.74277218, -5.44351229],
                            [39.74275, -5.44340139],
                            [39.74274631, -5.443272],
                            [39.74267977, -5.44311304],
                            [39.74257258, -5.44288014],
                            [39.74251713, -5.44271748],
                            [39.74241363, -5.44251786],
                            [39.74231014, -5.44237738],
                            [39.74230972, -5.44237541],
                            [39.74225469, -5.44211491],
                            [39.74226948, -5.44190419],
                            [39.7422436, -5.44163063],
                            [39.74222512, -5.44146058],
                            [39.74222882, -5.44139034],
                            [39.74225839, -5.44127574],
                            [39.74229166, -5.44118701],
                            [39.74231753, -5.44112417],
                            [39.74229166, -5.44105023],
                            [39.7422436, -5.44099108],
                            [39.74218816, -5.44080625],
                            [39.74216968, -5.44065098],
                            [39.74214011, -5.44049572],
                        ],
                        [
                            [39.6488373, -5.47070623],
                            [39.64886318, -5.47058891],
                            [39.64892153, -5.47043246],
                            [39.64897979, -5.47019129],
                            [39.64897963, -5.47004142],
                            [39.64890791, -5.46985904],
                            [39.64894686, -5.46980035],
                            [39.64890122, -5.46968311],
                            [39.64883602, -5.46951375],
                            [39.64882283, -5.46934434],
                            [39.64880312, -5.46915539],
                            [39.64886801, -5.46903151],
                            [39.6488678, -5.46883603],
                            [39.64875721, -5.46878401],
                            [39.64851654, -5.46869305],
                            [39.64842015, -5.46869047],
                            [39.64828246, -5.46868678],
                            [39.64813946, -5.46871952],
                            [39.64791195, -5.46877189],
                            [39.64754142, -5.46885049],
                            [39.64739199, -5.46895491],
                            [39.6472556, -5.4690919],
                            [39.64718424, -5.46924837],
                            [39.64713902, -5.46951558],
                            [39.64704824, -5.46975027],
                            [39.64693783, -5.46986116],
                            [39.64684038, -5.46993295],
                            [39.64666482, -5.46993314],
                            [39.64647622, -5.46988773],
                            [39.64623555, -5.46979676],
                            [39.64600136, -5.46968623],
                            [39.64582574, -5.46962126],
                            [39.64566313, -5.46956279],
                            [39.64548755, -5.46953691],
                            [39.6452925, -5.46954364],
                            [39.64513647, -5.46956335],
                            [39.64497403, -5.46966127],
                            [39.64487012, -5.46977216],
                            [39.64485875, -5.46986872],
                            [39.6448379, -5.47004588],
                            [39.64485108, -5.47020877],
                            [39.6449032, -5.47030646],
                            [39.64487725, -5.47035862],
                            [39.64476665, -5.47029358],
                            [39.64470172, -5.47038487],
                            [39.64457842, -5.47060004],
                            [39.64461753, -5.47069775],
                            [39.64482575, -5.47084088],
                            [39.64488435, -5.47091901],
                            [39.6450924, -5.47090576],
                            [39.64521882, -5.47094784],
                            [39.64522899, -5.47095122],
                            [39.64522805, -5.47097325],
                            [39.64522264, -5.4711011],
                            [39.64511861, -5.47110122],
                            [39.64504704, -5.47105568],
                            [39.64497557, -5.47110137],
                            [39.64496932, -5.47122948],
                            [39.64496921, -5.4712317],
                            [39.64500188, -5.47138154],
                            [39.64502798, -5.47146623],
                            [39.64486553, -5.47155763],
                            [39.64477458, -5.47163592],
                            [39.64471614, -5.47170766],
                            [39.64468358, -5.47165557],
                            [39.64472246, -5.47153172],
                            [39.64477439, -5.47145998],
                            [39.64473529, -5.47137531],
                            [39.64471099, -5.47134022],
                            [39.64467668, -5.47129066],
                            [39.64448807, -5.47123874],
                            [39.64442305, -5.47123881],
                            [39.64437093, -5.47114112],
                            [39.64431237, -5.47110208],
                            [39.6443058, -5.47103041],
                            [39.64426667, -5.47092619],
                            [39.64420156, -5.47083503],
                            [39.6442535, -5.47076982],
                            [39.64435092, -5.47066545],
                            [39.6444029, -5.47063281],
                            [39.6444678, -5.47052197],
                            [39.64452624, -5.47045022],
                            [39.64462652, -5.47032124],
                            [39.64460308, -5.47002007],
                            [39.64470662, -5.46955658],
                            [39.64473249, -5.46944366],
                            [39.64474113, -5.46940594],
                            [39.6449028, -5.46925517],
                            [39.64511076, -5.46916227],
                            [39.64534186, -5.46908479],
                            [39.64566554, -5.46911533],
                            [39.64591233, -5.46930042],
                            [39.64605563, -5.46939091],
                            [39.64620536, -5.46948546],
                            [39.64651378, -5.46965503],
                            [39.64674486, -5.46956211],
                            [39.64692965, -5.46942289],
                            [39.64702179, -5.46911387],
                            [39.64714485, -5.4688975],
                            [39.64737582, -5.46869645],
                            [39.64756058, -5.46852634],
                            [39.64780689, -5.4682635],
                            [39.64797619, -5.46804707],
                            [39.64783713, -5.46772285],
                            [39.64792954, -5.46766097],
                            [39.64823781, -5.46769153],
                            [39.648361, -5.46759872],
                            [39.64819117, -5.46732088],
                            [39.64834516, -5.46719714],
                            [39.64860535, -5.46709541],
                            [39.64862246, -5.46708872],
                            [39.64883824, -5.46710393],
                            [39.64903835, -5.46687203],
                            [39.64877606, -5.46660973],
                            [39.64865265, -5.46650174],
                            [39.64832888, -5.46639397],
                            [39.64829788, -5.46622409],
                            [39.64859065, -5.46617744],
                            [39.64862133, -5.46603839],
                            [39.6485439, -5.46569866],
                            [39.64846665, -5.4655134],
                            [39.64822462, -5.46546819],
                            [39.64822001, -5.46546732],
                            [39.64798878, -5.46542123],
                            [39.64809643, -5.46520487],
                            [39.64819301, -5.46495519],
                            [39.64820401, -5.46492673],
                            [39.64820368, -5.46461781],
                            [39.64820037, -5.46454028],
                            [39.64818787, -5.46424712],
                            [39.64794103, -5.4640157],
                            [39.64767877, -5.46376885],
                            [39.64767839, -5.46341359],
                            [39.64769337, -5.46301198],
                            [39.64769314, -5.46279573],
                            [39.647801, -5.46278017],
                            [39.64797066, -5.46290356],
                            [39.64823286, -5.46308862],
                            [39.64880338, -5.46335059],
                            [39.64915802, -5.46350467],
                            [39.6491736, -5.46365911],
                            [39.64900437, -5.46393732],
                            [39.64892774, -5.464339],
                            [39.64880488, -5.46474073],
                            [39.64880526, -5.46509599],
                            [39.64880559, -5.46540491],
                            [39.64891389, -5.46579094],
                            [39.64922232, -5.46597596],
                            [39.64922212, -5.46579061],
                            [39.64926786, -5.46532718],
                            [39.64934467, -5.4650954],
                            [39.6496372, -5.46481706],
                            [39.64994517, -5.46456959],
                            [39.65009885, -5.46416783],
                            [39.65019104, -5.46390515],
                            [39.65009812, -5.46348821],
                            [39.65005147, -5.46310211],
                            [39.64965041, -5.46277817],
                            [39.64944958, -5.46233046],
                            [39.64952596, -5.46169709],
                            [39.64953766, -5.46162627],
                            [39.64958721, -5.46132632],
                            [39.64964022, -5.46117704],
                            [39.64966403, -5.46110999],
                            [39.64975607, -5.4607083],
                            [39.64987896, -5.46033746],
                            [39.64989349, -5.46008539],
                            [39.64989409, -5.46007486],
                            [39.65014063, -5.46002826],
                            [39.6502877, -5.46008539],
                            [39.65087788, -5.46008539],
                            [39.65089584, -5.46005833],
                            [39.65124991, -5.45968724],
                            [39.65160421, -5.4595324],
                            [39.6520354, -5.45922301],
                            [39.65232781, -5.45883655],
                            [39.6524045, -5.45849665],
                            [39.65257373, -5.45821844],
                            [39.65292815, -5.45817172],
                            [39.6532369, -5.45865021],
                            [39.65354503, -5.4585572],
                            [39.65369878, -5.45821722],
                            [39.65374443, -5.45767656],
                            [39.65368226, -5.4571978],
                            [39.65371255, -5.45670349],
                            [39.65383493, -5.45585383],
                            [39.65405039, -5.45557557],
                            [39.65420414, -5.45523559],
                            [39.65441966, -5.45501911],
                            [39.65452723, -5.45472552],
                            [39.65435691, -5.45399975],
                            [39.65426259, -5.45380857],
                            [39.65380088, -5.45287279],
                            [39.65353447, -5.45242063],
                            [39.65313697, -5.45174595],
                            [39.65296666, -5.45102018],
                            [39.65287342, -5.45030976],
                            [39.65291825, -5.45031271],
                            [39.65275407, -5.45007559],
                            [39.65251681, -5.44961949],
                            [39.6521885, -5.44918175],
                            [39.65211537, -5.44892627],
                            [39.65176895, -5.44857982],
                            [39.65144133, -5.44878097],
                            [39.65098592, -5.44870845],
                            [39.6508948, -5.44865378],
                            [39.65058492, -5.44841682],
                            [39.65043937, -5.448563],
                            [39.65014804, -5.44863634],
                            [39.64989305, -5.44863661],
                            [39.64965646, -5.44880115],
                            [39.64971147, -5.44914792],
                            [39.64987586, -5.44958584],
                            [39.65007667, -5.45002372],
                            [39.65031388, -5.45042505],
                            [39.6503325, -5.45080837],
                            [39.65033305, -5.45131948],
                            [39.65020591, -5.45164819],
                            [39.65007879, -5.45199515],
                            [39.64993344, -5.45232388],
                            [39.64967845, -5.45232416],
                            [39.64955063, -5.45201398],
                            [39.64944076, -5.45146647],
                            [39.64951336, -5.45122909],
                            [39.64938587, -5.45097424],
                            [39.64934909, -5.4509007],
                            [39.64909383, -5.45064542],
                            [39.64889292, -5.45011627],
                            [39.64880158, -5.44986081],
                            [39.64885569, -5.44936789],
                            [39.64885539, -5.44909408],
                            [39.64856406, -5.44916741],
                            [39.6482362, -5.44914951],
                            [39.64821778, -5.44894873],
                            [39.6481628, -5.44863847],
                            [39.64794399, -5.44840141],
                            [39.64774369, -5.44843813],
                            [39.64768881, -5.44821914],
                            [39.64759757, -5.44805495],
                            [39.64732412, -5.44781794],
                            [39.64719649, -5.4476903],
                            [39.64721449, -5.44748949],
                            [39.64703195, -5.44710635],
                            [39.64655825, -5.44696083],
                            [39.6462481, -5.4464683],
                            [39.64566468, -5.44590305],
                            [39.6451365, -5.44590361],
                            [39.64497284, -5.44614109],
                            [39.64477244, -5.44608654],
                            [39.64466275, -5.44570333],
                            [39.64426193, -5.44557598],
                            [39.64380662, -5.44559472],
                            [39.64377042, -5.4458138],
                            [39.64355189, -5.44583229],
                            [39.64320559, -5.44559536],
                            [39.64273191, -5.44546808],
                            [39.64242217, -5.44535889],
                            [39.64189397, -5.4453412],
                            [39.64167555, -5.44546921],
                            [39.64165763, -5.44574304],
                            [39.64149367, -5.44570671],
                            [39.64132941, -5.44537831],
                            [39.6410745, -5.44545159],
                            [39.64078317, -5.44552492],
                            [39.64054632, -5.44545216],
                            [39.63987232, -5.44534335],
                            [39.63969038, -5.44552608],
                            [39.63978164, -5.44570852],
                            [39.63943567, -5.44578191],
                            [39.63872559, -5.44600171],
                            [39.63852552, -5.44625748],
                            [39.63807065, -5.44669606],
                            [39.63785265, -5.44722566],
                            [39.63785323, -5.44777328],
                            [39.63790831, -5.44819306],
                            [39.63790848, -5.44835735],
                            [39.63801791, -5.44850327],
                            [39.63818189, -5.44855786],
                            [39.63827319, -5.44877681],
                            [39.63832829, -5.44921485],
                            [39.63860185, -5.44956139],
                            [39.63858378, -5.44968918],
                            [39.63840157, -5.44961636],
                            [39.63805509, -5.44921514],
                            [39.63787282, -5.44908755],
                            [39.63774481, -5.44859483],
                            [39.63754397, -5.44812043],
                            [39.63734324, -5.44775556],
                            [39.63705181, -5.44773762],
                            [39.63672432, -5.44806654],
                            [39.63623295, -5.44843214],
                            [39.63596009, -5.448761],
                            [39.63568718, -5.4490351],
                            [39.63545072, -5.44932741],
                            [39.63532344, -5.44952834],
                            [39.63510503, -5.44967461],
                            [39.63474119, -5.45007658],
                            [39.63474159, -5.45045991],
                            [39.63455986, -5.45084344],
                            [39.63443252, -5.45098961],
                            [39.63417767, -5.45111766],
                            [39.63404996, -5.45091699],
                            [39.63408606, -5.45060664],
                            [39.6343223, -5.45009527],
                            [39.63461327, -5.44967512],
                            [39.63481329, -5.44936459],
                            [39.63484928, -5.44894471],
                            [39.63477601, -5.4485432],
                            [39.63499437, -5.44836043],
                            [39.63524901, -5.44803159],
                            [39.63526688, -5.44770299],
                            [39.63548523, -5.44750197],
                            [39.63583116, -5.44739208],
                            [39.63599489, -5.44720937],
                            [39.63612202, -5.4468624],
                            [39.63641249, -5.44596765],
                            [39.63650278, -5.44549436],
                            [39.63650305, -5.44549295],
                            [39.63646626, -5.44514616],
                            [39.63597418, -5.44483636],
                            [39.63583271, -5.44481435],
                            [39.63539127, -5.4447457],
                            [39.63517273, -5.44476418],
                            [39.634863, -5.44465499],
                            [39.63442586, -5.44463719],
                            [39.6342802, -5.44467385],
                            [39.63407985, -5.44467406],
                            [39.63375204, -5.44469266],
                            [39.63358814, -5.44471109],
                            [39.63342435, -5.44483904],
                            [39.63342466, -5.44513111],
                            [39.63322456, -5.44536862],
                            [39.63304274, -5.44566087],
                            [39.63298848, -5.44602601],
                            [39.63277004, -5.44613577],
                            [39.63266102, -5.44639144],
                            [39.63278894, -5.4467929],
                            [39.63266196, -5.44728589],
                            [39.63242604, -5.44810757],
                            [39.63229918, -5.44871009],
                            [39.63191747, -5.44944065],
                            [39.63191789, -5.44984224],
                            [39.63193654, -5.45026207],
                            [39.6321919, -5.45062688],
                            [39.63239265, -5.45101001],
                            [39.63248419, -5.45146626],
                            [39.63253925, -5.4518678],
                            [39.63255785, -5.45223286],
                            [39.63255831, -5.45267096],
                            [39.63261339, -5.45309074],
                            [39.63239504, -5.45329177],
                            [39.63239542, -5.45365685],
                            [39.63223152, -5.45367527],
                            [39.63230378, -5.45310932],
                            [39.63241251, -5.45257984],
                            [39.63222957, -5.45181336],
                            [39.63210162, -5.45137539],
                            [39.63160904, -5.45059099],
                            [39.63128082, -5.45022625],
                            [39.63097108, -5.45011705],
                            [39.63073471, -5.45050063],
                            [39.63055298, -5.45088416],
                            [39.63020753, -5.45146865],
                            [39.63009873, -5.45192512],
                            [39.63008093, -5.45232673],
                            [39.62995378, -5.45265543],
                            [39.6298815, -5.45320313],
                            [39.62971796, -5.45356838],
                            [39.62962674, -5.45342245],
                            [39.62969912, -5.45296602],
                            [39.62978969, -5.45249132],
                            [39.62978937, -5.452181],
                            [39.62946122, -5.45188927],
                            [39.62953347, -5.45130507],
                            [39.62951484, -5.4509035],
                            [39.62933223, -5.45044734],
                            [39.62940455, -5.44993614],
                            [39.62940413, -5.44953455],
                            [39.62938545, -5.44907822],
                            [39.62943982, -5.44882261],
                            [39.62922084, -5.44842125],
                            [39.62881945, -5.44774626],
                            [39.62876449, -5.447436],
                            [39.62852719, -5.44692513],
                            [39.62825337, -5.44632303],
                            [39.62810687, -5.44555651],
                            [39.62768766, -5.44526488],
                            [39.62745097, -5.44533815],
                            [39.62757944, -5.44628723],
                            [39.62785313, -5.44676155],
                            [39.62790838, -5.44734562],
                            [39.62776292, -5.44758308],
                            [39.62756263, -5.44763805],
                            [39.62736194, -5.44730968],
                            [39.62725236, -5.44701773],
                            [39.62705156, -5.44657984],
                            [39.62688717, -5.44612366],
                            [39.6265957, -5.4460692],
                            [39.62621323, -5.4460696],
                            [39.62601262, -5.44581425],
                            [39.62593905, -5.44512067],
                            [39.6261024, -5.44457287],
                            [39.62670334, -5.44448098],
                            [39.62684895, -5.44438956],
                            [39.6269397, -5.44407914],
                            [39.62690248, -5.44331251],
                            [39.62701141, -5.44298382],
                            [39.62692407, -5.44290957],
                            [39.62671878, -5.44273505],
                            [39.62664683, -5.44267388],
                            [39.62657368, -5.44238189],
                            [39.62640946, -5.44208999],
                            [39.62604476, -5.44167053],
                            [39.62568035, -5.44152487],
                            [39.62538896, -5.44154343],
                            [39.62520661, -5.44132457],
                            [39.62487853, -5.44108761],
                            [39.62438678, -5.44108811],
                            [39.62398617, -5.44116155],
                            [39.62351265, -5.44118029],
                            [39.62305753, -5.44138155],
                            [39.62247481, -5.44147343],
                            [39.62209254, -5.44167462],
                            [39.6216558, -5.44204015],
                            [39.62112783, -5.44224149],
                            [39.62078204, -5.4424974],
                            [39.62049108, -5.4429358],
                            [39.62021874, -5.44377577],
                            [39.62031026, -5.44421378],
                            [39.62056563, -5.44459685],
                            [39.62063899, -5.44508964],
                            [39.62056629, -5.44523575],
                            [39.62038418, -5.44525419],
                            [39.62023873, -5.44550989],
                            [39.62016629, -5.44591156],
                            [39.61993023, -5.44660546],
                            [39.61971212, -5.44704378],
                            [39.61931162, -5.44722674],
                            [39.6190024, -5.44762865],
                            [39.61891197, -5.44824938],
                            [39.61900365, -5.44885167],
                            [39.61914975, -5.44923486],
                            [39.61915003, -5.44950867],
                            [39.61907753, -5.44985557],
                            [39.61904129, -5.45003815],
                            [39.61885897, -5.4498558],
                            [39.61885879, -5.44967326],
                            [39.61889493, -5.44939941],
                            [39.61867613, -5.44916233],
                            [39.61854851, -5.44903468],
                            [39.6184937, -5.44887045],
                            [39.61822037, -5.44874295],
                            [39.61814752, -5.44874303],
                            [39.61802012, -5.44883443],
                            [39.61798339, -5.4485424],
                            [39.61792849, -5.4482869],
                            [39.61770977, -5.44812283],
                            [39.61747303, -5.44815958],
                            [39.61732742, -5.448251],
                            [39.61716339, -5.44814165],
                            [39.61734539, -5.44801368],
                            [39.61760004, -5.44768485],
                            [39.6172903, -5.44757564],
                            [39.61694421, -5.44753948],
                            [39.61668932, -5.44763102],
                            [39.61648896, -5.44761297],
                            [39.61643422, -5.44752175],
                            [39.61652518, -5.44741213],
                            [39.61672538, -5.4472659],
                            [39.61683449, -5.4471015],
                            [39.61712564, -5.44684564],
                            [39.61728919, -5.44648039],
                            [39.6173071, -5.44618831],
                            [39.61705193, -5.44600603],
                            [39.61685149, -5.44591496],
                            [39.61654204, -5.44607956],
                            [39.61615952, -5.44604345],
                            [39.61581349, -5.44606205],
                            [39.61564981, -5.44629953],
                            [39.61550427, -5.44646396],
                            [39.61539492, -5.44639106],
                            [39.61546759, -5.44620844],
                            [39.61550375, -5.44595285],
                            [39.61519402, -5.44584364],
                            [39.61495706, -5.44566134],
                            [39.61497481, -5.44520496],
                            [39.6147924, -5.44493134],
                            [39.6145009, -5.44484036],
                            [39.6142458, -5.4447311],
                            [39.61393629, -5.44484094],
                            [39.61373608, -5.44496892],
                            [39.61353595, -5.44518818],
                            [39.61329933, -5.44533445],
                            [39.61311711, -5.44524336],
                            [39.61326261, -5.44504242],
                            [39.61373578, -5.44467686],
                            [39.61424566, -5.44458507],
                            [39.61470104, -5.44463937],
                            [39.61517443, -5.44449285],
                            [39.61533813, -5.44427363],
                            [39.61541076, -5.44405451],
                            [39.61541048, -5.4437807],
                            [39.61564701, -5.44354315],
                            [39.61561031, -5.44326938],
                            [39.61555543, -5.44303213],
                            [39.61550053, -5.44277663],
                            [39.61520903, -5.44268565],
                            [39.61488119, -5.44268599],
                            [39.61433504, -5.44292385],
                            [39.61386159, -5.4430156],
                            [39.61335164, -5.44303437],
                            [39.61298755, -5.44319903],
                            [39.61271442, -5.44327232],
                            [39.61271405, -5.44290724],
                            [39.61229506, -5.4428164],
                            [39.61205844, -5.44296267],
                            [39.61189446, -5.44290807],
                            [39.61207645, -5.44276186],
                            [39.61218526, -5.44230539],
                            [39.61244016, -5.44221386],
                            [39.61273118, -5.44183023],
                            [39.61256678, -5.44135579],
                            [39.61214768, -5.44115542],
                            [39.61201989, -5.44086348],
                            [39.61214729, -5.44077208],
                            [39.61234769, -5.44082664],
                            [39.6126209, -5.44084461],
                            [39.61282141, -5.4410087],
                            [39.61307658, -5.44119098],
                            [39.61336799, -5.44119069],
                            [39.61369571, -5.44108083],
                            [39.61411432, -5.44078833],
                            [39.61435083, -5.44053254],
                            [39.61447791, -5.44013081],
                            [39.61458697, -5.43991165],
                            [39.61427685, -5.43941911],
                            [39.61383935, -5.43903621],
                            [39.6138757, -5.43896316],
                            [39.61429476, -5.43912702],
                            [39.61489579, -5.43912641],
                            [39.61538741, -5.43899813],
                            [39.61606118, -5.43888792],
                            [39.61642524, -5.43868675],
                            [39.61646603, -5.43846057],
                            [39.61649768, -5.43828509],
                            [39.6169347, -5.43819337],
                            [39.61733525, -5.43806518],
                            [39.61746254, -5.43786425],
                            [39.61762603, -5.43744424],
                            [39.61782563, -5.43671387],
                            [39.61797077, -5.4361661],
                            [39.6180613, -5.43563663],
                            [39.61813346, -5.43496116],
                            [39.61798705, -5.43426765],
                            [39.6175862, -5.43410377],
                            [39.61722228, -5.43443272],
                            [39.61711347, -5.43488918],
                            [39.61713233, -5.43552806],
                            [39.61705991, -5.43594798],
                            [39.61693275, -5.43627668],
                            [39.6165689, -5.43667865],
                            [39.61619746, -5.43683865],
                            [39.6161866, -5.43684332],
                            [39.61565846, -5.43688037],
                            [39.61533063, -5.43688071],
                            [39.61511224, -5.43704522],
                            [39.61502095, -5.43682626],
                            [39.61503907, -5.43673497],
                            [39.61502067, -5.43655245],
                            [39.61505686, -5.43631511],
                            [39.6152389, -5.43622365],
                            [39.61565781, -5.43624148],
                            [39.61616241, -5.4362584],
                            [39.616186, -5.43625919],
                            [39.61662295, -5.43609446],
                            [39.61676841, -5.43585701],
                            [39.61682254, -5.43536409],
                            [39.6169497, -5.43503538],
                            [39.61680501, -5.4347172],
                            [39.61676716, -5.43463398],
                            [39.61669381, -5.43414119],
                            [39.61685736, -5.43377594],
                            [39.61703919, -5.43348369],
                            [39.61703869, -5.43299083],
                            [39.61696548, -5.43264407],
                            [39.61696509, -5.43226074],
                            [39.61690999, -5.43180444],
                            [39.61683681, -5.43147594],
                            [39.61700057, -5.43132974],
                            [39.61718253, -5.43116526],
                            [39.61725485, -5.43063582],
                            [39.61721805, -5.43027077],
                            [39.61708988, -5.4295955],
                            [39.61698008, -5.4290845],
                            [39.6168704, -5.42868302],
                            [39.61676058, -5.42815376],
                            [39.61666904, -5.42767924],
                            [39.61655939, -5.42731427],
                            [39.61666824, -5.42689432],
                            [39.61663145, -5.42652927],
                            [39.61655817, -5.4261095],
                            [39.61628459, -5.42572644],
                            [39.61595632, -5.42528867],
                            [39.61573751, -5.42503334],
                            [39.61555542, -5.42507003],
                            [39.61548233, -5.4248328],
                            [39.61522693, -5.42441322],
                            [39.61502639, -5.42421262],
                            [39.61491715, -5.42424924],
                            [39.61477151, -5.42430415],
                            [39.6145892, -5.4241218],
                            [39.61445928, -5.42398256],
                            [39.61433395, -5.42384824],
                            [39.61407895, -5.42383025],
                            [39.61410534, -5.42392245],
                            [39.61411551, -5.42395799],
                            [39.61435257, -5.42424982],
                            [39.61442562, -5.42445054],
                            [39.61435299, -5.42466966],
                            [39.61420745, -5.4248341],
                            [39.61411674, -5.42518102],
                            [39.61402603, -5.42552794],
                            [39.61389887, -5.42585664],
                            [39.61408126, -5.42611202],
                            [39.6140087, -5.42640416],
                            [39.6137543, -5.42697029],
                            [39.61351823, -5.42766419],
                            [39.61306329, -5.42802974],
                            [39.61293613, -5.42835844],
                            [39.61311844, -5.4285408],
                            [39.61282735, -5.42885141],
                            [39.61268215, -5.42934442],
                            [39.61268259, -5.42978252],
                            [39.61268321, -5.43040317],
                            [39.61277464, -5.43076816],
                            [39.61299375, -5.43131556],
                            [39.61293959, -5.43179022],
                            [39.61283052, -5.43199113],
                            [39.6126302, -5.43200959],
                            [39.61272111, -5.43186346],
                            [39.61277557, -5.43168086],
                            [39.61273888, -5.43142534],
                            [39.61255639, -5.43106044],
                            [39.61233782, -5.43104241],
                            [39.6120832, -5.43140775],
                            [39.61186509, -5.43184607],
                            [39.61168333, -5.43221134],
                            [39.61151942, -5.43221151],
                            [39.61162838, -5.43190108],
                            [39.61170074, -5.43140814],
                            [39.61155454, -5.43091542],
                            [39.61175451, -5.43055014],
                            [39.61179031, -5.42992946],
                            [39.61151664, -5.42945513],
                            [39.611261, -5.42879824],
                            [39.61098717, -5.42815962],
                            [39.61093228, -5.42790411],
                            [39.61069555, -5.42794086],
                            [39.61051348, -5.42799581],
                            [39.6105316, -5.42790452],
                            [39.61045869, -5.42784983],
                            [39.6100763, -5.42792323],
                            [39.60983983, -5.42821554],
                            [39.60960328, -5.42843483],
                            [39.60934829, -5.42841683],
                            [39.60896573, -5.42832594],
                            [39.60861972, -5.4283628],
                            [39.60783709, -5.4288747],
                            [39.6074188, -5.42947751],
                            [39.60729166, -5.42982447],
                            [39.60703701, -5.4301533],
                            [39.60674601, -5.43055519],
                            [39.60640044, -5.43103014],
                            [39.60636441, -5.43143177],
                            [39.60651053, -5.43185147],
                            [39.6067842, -5.4323258],
                            [39.6067481, -5.43265442],
                            [39.6067486, -5.43314728],
                            [39.60671266, -5.43364018],
                            [39.60660379, -5.43404188],
                            [39.60674995, -5.43449809],
                            [39.60658588, -5.43435222],
                            [39.6064581, -5.43406028],
                            [39.60647602, -5.43376819],
                            [39.60640246, -5.43305636],
                            [39.60631089, -5.43254533],
                            [39.60605547, -5.43210748],
                            [39.60572753, -5.43199829],
                            [39.60556383, -5.4322175],
                            [39.60538176, -5.43227245],
                            [39.60543609, -5.43196207],
                            [39.60558148, -5.43165161],
                            [39.60532632, -5.43146932],
                            [39.60503512, -5.43167041],
                            [39.60468942, -5.43201758],
                            [39.60427067, -5.43216403],
                            [39.60377883, -5.43207325],
                            [39.60359678, -5.43214645],
                            [39.60332368, -5.432238],
                            [39.6031681, -5.43235085],
                            [39.60299608, -5.43247563],
                            [39.60299594, -5.4323296],
                            [39.60302327, -5.43223531],
                            [39.60312299, -5.43189137],
                            [39.60332279, -5.43134354],
                            [39.60377767, -5.43090499],
                            [39.604196, -5.43033869],
                            [39.60470514, -5.42951674],
                            [39.60488691, -5.42915147],
                            [39.60528731, -5.42887726],
                            [39.60574198, -5.42821965],
                            [39.60606951, -5.42792726],
                            [39.60641528, -5.4276531],
                            [39.60657883, -5.42728785],
                            [39.6067607, -5.42703211],
                            [39.60681514, -5.42683126],
                            [39.60686947, -5.42652088],
                            [39.60668701, -5.42619249],
                            [39.60643151, -5.42566338],
                            [39.60597541, -5.4248789],
                            [39.60588382, -5.42434962],
                            [39.60571936, -5.42380216],
                            [39.60560967, -5.42338242],
                            [39.60542712, -5.42296276],
                            [39.60513534, -5.42257971],
                            [39.60482564, -5.42248875],
                            [39.60455227, -5.42230648],
                            [39.60453359, -5.42183189],
                            [39.60431455, -5.42133925],
                            [39.60391331, -5.42077377],
                            [39.60362106, -5.41991611],
                            [39.60329284, -5.41951484],
                            [39.60300095, -5.41902227],
                            [39.60292776, -5.41867551],
                            [39.60298209, -5.41836514],
                            [39.60298191, -5.4181826],
                            [39.60281774, -5.4179272],
                            [39.60287205, -5.41759857],
                            [39.60250768, -5.41747115],
                            [39.60214318, -5.41721596],
                            [39.60207, -5.41688745],
                            [39.60201502, -5.41654068],
                            [39.6021424, -5.41643103],
                            [39.60183254, -5.41617577],
                            [39.60150477, -5.41623086],
                            [39.60135895, -5.41610323],
                            [39.6011765, -5.41577483],
                            [39.60077583, -5.41577523],
                            [39.60035689, -5.41572088],
                            [39.60010156, -5.41535605],
                            [39.60004674, -5.41517356],
                            [39.60017405, -5.41499089],
                            [39.60031962, -5.41486297],
                            [39.60017333, -5.41426073],
                            [39.59979064, -5.4140238],
                            [39.59955355, -5.41369546],
                            [39.59940759, -5.41342179],
                            [39.59940728, -5.41311147],
                            [39.5992428, -5.41252749],
                            [39.59896929, -5.41219919],
                            [39.59898725, -5.41194361],
                            [39.59878643, -5.41145095],
                            [39.59876792, -5.41114064],
                            [39.59840337, -5.41083068],
                            [39.59836664, -5.41052039],
                            [39.59834323, -5.41021925],
                            [39.59832975, -5.41004582],
                            [39.59812895, -5.40957141],
                            [39.59812866, -5.40927934],
                            [39.59801914, -5.40902389],
                            [39.59785507, -5.40885976],
                            [39.59760015, -5.40891478],
                            [39.59741828, -5.40917051],
                            [39.59749138, -5.409426],
                            [39.59772842, -5.40971784],
                            [39.59769214, -5.40986391],
                            [39.59769236, -5.41008296],
                            [39.59765636, -5.41052109],
                            [39.5976203, -5.41088621],
                            [39.59772989, -5.41121468],
                            [39.59789382, -5.41123277],
                            [39.59796679, -5.41136048],
                            [39.59793069, -5.41168909],
                            [39.59807671, -5.41201753],
                            [39.59831365, -5.41219984],
                            [39.59825912, -5.41230941],
                            [39.59825943, -5.41261974],
                            [39.59836924, -5.41316725],
                            [39.59847905, -5.41371477],
                            [39.59853415, -5.41418933],
                            [39.59858907, -5.41448134],
                            [39.59876176, -5.41471165],
                            [39.59891733, -5.41491912],
                            [39.59891783, -5.41543147],
                            [39.59891785, -5.41544849],
                            [39.59888287, -5.41582045],
                            [39.5988818, -5.41583187],
                            [39.59893705, -5.41645246],
                            [39.59910166, -5.41716421],
                            [39.59906566, -5.41760234],
                            [39.59882872, -5.41742004],
                            [39.59861, -5.41723771],
                            [39.59850047, -5.41698226],
                            [39.59839095, -5.41672681],
                            [39.59831781, -5.41643481],
                            [39.59844504, -5.41617913],
                            [39.59860896, -5.41617897],
                            [39.59860865, -5.41586864],
                            [39.59858907, -5.41572252],
                            [39.59857196, -5.41559487],
                            [39.59849882, -5.41530287],
                            [39.59851689, -5.41515682],
                            [39.59822521, -5.41486504],
                            [39.59804283, -5.41460966],
                            [39.59784228, -5.41439081],
                            [39.59782056, -5.41436763],
                            [39.59756881, -5.41409901],
                            [39.59753151, -5.4140617],
                            [39.59729536, -5.41382547],
                            [39.59709506, -5.41386217],
                            [39.5970771, -5.41411775],
                            [39.59708798, -5.41439442],
                            [39.59709577, -5.41459234],
                            [39.59700959, -5.41492671],
                            [39.5969893, -5.41513209],
                            [39.59718416, -5.41534752],
                            [39.59716382, -5.41550156],
                            [39.59702036, -5.41546063],
                            [39.59689745, -5.41548129],
                            [39.59679519, -5.41567648],
                            [39.59682625, -5.41600503],
                            [39.59673425, -5.41621048],
                            [39.59652927, -5.41611827],
                            [39.59637573, -5.4162519],
                            [39.5963349, -5.41639569],
                            [39.59618132, -5.41648826],
                            [39.59619142, -5.41633423],
                            [39.59619123, -5.4161494],
                            [39.59598615, -5.41595451],
                            [39.59580165, -5.41585201],
                            [39.59566852, -5.41589322],
                            [39.59555592, -5.41598574],
                            [39.59563816, -5.41628343],
                            [39.59559732, -5.41641696],
                            [39.59548462, -5.4164068],
                            [39.59535144, -5.41640693],
                            [39.59536159, -5.41631451],
                            [39.59536145, -5.41617076],
                            [39.59532027, -5.41595517],
                            [39.59518699, -5.41585262],
                            [39.59502308, -5.41585278],
                            [39.5949205, -5.4157194],
                            [39.59480766, -5.41556549],
                            [39.59487931, -5.41550381],
                            [39.59474605, -5.41542179],
                            [39.59463335, -5.41540137],
                            [39.59453088, -5.41538093],
                            [39.59450009, -5.41531936],
                            [39.59448973, -5.41520642],
                            [39.59451012, -5.41510372],
                            [39.59442814, -5.41507299],
                            [39.59431549, -5.41511418],
                            [39.59425399, -5.41508343],
                            [39.59422318, -5.41500132],
                            [39.59413101, -5.41503221],
                            [39.59398776, -5.41520691],
                            [39.59398793, -5.41538147],
                            [39.59398795, -5.41540494],
                            [39.59398809, -5.41554575],
                            [39.59390622, -5.41563825],
                            [39.59382434, -5.4157102],
                            [39.59384499, -5.41588474],
                            [39.59387587, -5.41602846],
                            [39.5939682, -5.41616185],
                            [39.59401951, -5.41625422],
                            [39.59405046, -5.41648008],
                            [39.59404046, -5.41672652],
                            [39.5940407, -5.41697296],
                            [39.59405024, -5.41699861],
                            [39.59417423, -5.41733221],
                            [39.59421546, -5.41758887],
                            [39.59414376, -5.4175992],
                            [39.59407215, -5.41770195],
                            [39.59417478, -5.41789695],
                            [39.59421593, -5.41807146],
                            [39.594134, -5.41810235],
                            [39.59401118, -5.41821541],
                            [39.59409333, -5.41841043],
                            [39.59419583, -5.41847193],
                            [39.5942472, -5.4186259],
                            [39.59425764, -5.41882099],
                            [39.59425788, -5.41906742],
                            [39.59429906, -5.41927274],
                            [39.59424793, -5.4193652],
                            [39.59413531, -5.41943719],
                            [39.59407376, -5.4193551],
                            [39.59408387, -5.41922161],
                            [39.59410429, -5.41914971],
                            [39.59410411, -5.41896489],
                            [39.59400162, -5.41891365],
                            [39.59384796, -5.41892407],
                            [39.59378649, -5.41892413],
                            [39.59370467, -5.41905769],
                            [39.59369454, -5.41918092],
                            [39.59355105, -5.41910918],
                            [39.59348951, -5.41903737],
                            [39.59348945, -5.41897576],
                            [39.59341758, -5.41881154],
                            [39.59324334, -5.41872957],
                            [39.59325352, -5.41865768],
                            [39.59334551, -5.41844196],
                            [39.59341713, -5.41834948],
                            [39.5933658, -5.41823658],
                            [39.59327361, -5.41824694],
                            [39.59321204, -5.41814432],
                            [39.59321193, -5.41803137],
                            [39.59309918, -5.41796988],
                            [39.59299662, -5.41785703],
                            [39.59296577, -5.41773384],
                            [39.59281192, -5.41754917],
                            [39.59282198, -5.41735407],
                            [39.59262709, -5.41710782],
                            [39.59257562, -5.41685117],
                            [39.59258569, -5.41666634],
                            [39.5925751, -5.41631724],
                            [39.59257483, -5.41604],
                            [39.59241093, -5.41605043],
                            [39.59215497, -5.4162047],
                            [39.59202183, -5.4162459],
                            [39.59197066, -5.41629729],
                            [39.59182721, -5.41626663],
                            [39.59188857, -5.41616389],
                            [39.59190891, -5.41600985],
                            [39.59177571, -5.41598944],
                            [39.5916323, -5.41599985],
                            [39.59150938, -5.41601024],
                            [39.59140685, -5.41592819],
                            [39.59139647, -5.41578445],
                            [39.59123244, -5.4156614],
                            [39.59112993, -5.41558962],
                            [39.59110924, -5.41538428],
                            [39.59117048, -5.41515832],
                            [39.59098582, -5.41488127],
                            [39.5908118, -5.41502519],
                            [39.5905663, -5.41540534],
                            [39.59048458, -5.41565186],
                            [39.59035146, -5.41570333],
                            [39.59020809, -5.41576507],
                            [39.59010556, -5.41567276],
                            [39.59009515, -5.41549821],
                            [39.58997203, -5.41531351],
                            [39.58985917, -5.4151288],
                            [39.58963379, -5.41512901],
                            [39.58947006, -5.415314],
                            [39.58947028, -5.41555016],
                            [39.58933719, -5.41563244],
                            [39.58915266, -5.41549913],
                            [39.58916273, -5.41532457],
                            [39.58926497, -5.41510884],
                            [39.58915215, -5.41497546],
                            [39.5892545, -5.41487268],
                            [39.58938739, -5.41458505],
                            [39.58954085, -5.41436927],
                            [39.58960215, -5.41419466],
                            [39.58961216, -5.41394821],
                            [39.58977594, -5.41381457],
                            [39.58982644, -5.41371771],
                            [39.58988842, -5.41359883],
                            [39.58994953, -5.41322912],
                            [39.58995957, -5.41302375],
                            [39.58992863, -5.41280816],
                            [39.58980545, -5.41255157],
                            [39.58957995, -5.41242858],
                            [39.58934423, -5.41232613],
                            [39.58927522, -5.41230835],
                            [39.58902658, -5.41224429],
                            [39.58888316, -5.41224443],
                            [39.58866805, -5.41227544],
                            [39.58849391, -5.41228588],
                            [39.58844279, -5.41239888],
                            [39.58839173, -5.41256322],
                            [39.58823798, -5.41247095],
                            [39.58810475, -5.41241974],
                            [39.58807381, -5.41220414],
                            [39.58807362, -5.41200905],
                            [39.58812465, -5.41181391],
                            [39.58814495, -5.4116188],
                            [39.58803199, -5.4113314],
                            [39.58768358, -5.41122906],
                            [39.58759154, -5.41139344],
                            [39.58739693, -5.41142443],
                            [39.5873353, -5.4112602],
                            [39.58713027, -5.41111665],
                            [39.58698672, -5.4109833],
                            [39.58686358, -5.41076779],
                            [39.58670992, -5.41076794],
                            [39.58654599, -5.41074756],
                            [39.58647391, -5.41036771],
                            [39.58642246, -5.4101316],
                            [39.58618675, -5.41002915],
                            [39.58605332, -5.40977257],
                            [39.585838, -5.40957769],
                            [39.58558188, -5.40956767],
                            [39.58544852, -5.40937271],
                            [39.58527416, -5.40915724],
                            [39.58514082, -5.40899308],
                            [39.58499728, -5.40885974],
                            [39.58491539, -5.40893169],
                            [39.58477197, -5.40893183],
                            [39.58448489, -5.40868568],
                            [39.58431083, -5.40877826],
                            [39.5841058, -5.4086347],
                            [39.58397262, -5.40863483],
                            [39.58401348, -5.40851157],
                            [39.58382887, -5.40828585],
                            [39.58348044, -5.40816297],
                            [39.58306033, -5.4080607],
                            [39.58278371, -5.40804043],
                            [39.58250711, -5.40804069],
                            [39.58212807, -5.40804105],
                            [39.58189264, -5.40823637],
                            [39.58171874, -5.40850351],
                            [39.58139101, -5.40859623],
                            [39.58107355, -5.40871975],
                            [39.58076646, -5.40896648],
                            [39.58055137, -5.40901803],
                            [39.58041835, -5.40918244],
                            [39.5802238, -5.40927504],
                            [39.58015557, -5.409272],
                            [39.57999841, -5.40926499],
                            [39.57971166, -5.40935768],
                            [39.57931206, -5.40928618],
                            [39.5792404, -5.40933759],
                            [39.57924504, -5.40939052],
                            [39.57926111, -5.40957373],
                            [39.5791793, -5.40972783],
                            [39.57909745, -5.40984086],
                            [39.57905658, -5.40995385],
                            [39.57922071, -5.41017959],
                            [39.57931315, -5.4104362],
                            [39.57934394, -5.41049778],
                            [39.57951821, -5.41061056],
                            [39.57974374, -5.41077463],
                            [39.58001025, -5.41093867],
                            [39.58016415, -5.41118495],
                            [39.58041039, -5.4115749],
                            [39.5805745, -5.41179038],
                            [39.58084107, -5.41201602],
                            [39.58102564, -5.4121904],
                            [39.58107711, -5.41245732],
                            [39.58114907, -5.41271395],
                            [39.58119025, -5.41292954],
                            [39.58113914, -5.41304254],
                            [39.58105721, -5.41307342],
                            [39.5810366, -5.41293995],
                            [39.58094415, -5.41268334],
                            [39.580821, -5.41245756],
                            [39.58058515, -5.41221136],
                            [39.58030833, -5.41198572],
                            [39.58013394, -5.41173946],
                            [39.58003126, -5.41148285],
                            [39.57966216, -5.41117517],
                            [39.5791805, -5.4109908],
                            [39.57859637, -5.41077573],
                            [39.57822736, -5.41056045],
                            [39.57768418, -5.41031454],
                            [39.57732545, -5.41013005],
                            [39.57699756, -5.41005849],
                            [39.57662866, -5.40994589],
                            [39.5763622, -5.40984346],
                            [39.57604459, -5.40980269],
                            [39.57569628, -5.40980302],
                            [39.57539918, -5.40979304],
                            [39.57508155, -5.40973173],
                            [39.57473315, -5.40963965],
                            [39.57428239, -5.40962981],
                            [39.5740366, -5.40971218],
                            [39.57384214, -5.40989719],
                            [39.57370885, -5.40978437],
                            [39.57350397, -5.40979483],
                            [39.5733196, -5.40982581],
                            [39.57291997, -5.40971324],
                            [39.57267414, -5.40975454],
                            [39.57239763, -5.40984721],
                            [39.57213136, -5.40993988],
                            [39.57183438, -5.41005311],
                            [39.57168067, -5.41000191],
                            [39.57156428, -5.41000202],
                            [39.57139382, -5.41000218],
                            [39.57114796, -5.41000241],
                            [39.57082018, -5.41005406],
                            [39.57049237, -5.41006464],
                            [39.57013379, -5.41003417],
                            [39.56984696, -5.41004471],
                            [39.5695704, -5.41008604],
                            [39.56939606, -5.40989111],
                            [39.56913996, -5.40990162],
                            [39.56944795, -5.41060983],
                            [39.56994043, -5.41141027],
                            [39.57022728, -5.41141],
                            [39.57044211, -5.41109149],
                            [39.57058561, -5.4111735],
                            [39.57087263, -5.41135806],
                            [39.57092054, -5.41135032],
                            [39.5711287, -5.41131674],
                            [39.57138461, -5.41110087],
                            [39.57156877, -5.410844],
                            [39.57183506, -5.41078214],
                            [39.5721628, -5.41068942],
                            [39.57233676, -5.41048389],
                            [39.57253143, -5.41051451],
                            [39.57262392, -5.41082247],
                            [39.57249105, -5.41115117],
                            [39.57221466, -5.41136706],
                            [39.57203049, -5.41161367],
                            [39.57167213, -5.41182963],
                            [39.57159919, -5.41193036],
                            [39.57142659, -5.41216871],
                            [39.57137544, -5.4122509],
                            [39.57119127, -5.41249751],
                            [39.57105839, -5.41281594],
                            [39.57113039, -5.41312392],
                            [39.57133564, -5.41350364],
                            [39.57164311, -5.41364711],
                            [39.57171497, -5.41381133],
                            [39.57173556, -5.41391399],
                            [39.57188925, -5.41394465],
                            [39.57200182, -5.41381106],
                            [39.5721658, -5.41389305],
                            [39.57222745, -5.41407781],
                            [39.57215586, -5.41421137],
                            [39.57198166, -5.41417046],
                            [39.57201255, -5.41433472],
                            [39.57212538, -5.41447836],
                            [39.57224843, -5.41460146],
                            [39.5724841, -5.41465258],
                            [39.5725557, -5.41453957],
                            [39.57247358, -5.41436509],
                            [39.57241195, -5.41419059],
                            [39.57273978, -5.41420055],
                            [39.5730472, -5.41429267],
                            [39.57333417, -5.41441561],
                            [39.57352895, -5.41455918],
                            [39.57382625, -5.4147848],
                            [39.5742569, -5.41519511],
                            [39.57482064, -5.41550262],
                            [39.57540497, -5.41592306],
                            [39.57595851, -5.41628191],
                            [39.576307, -5.4164664],
                            [39.57662474, -5.41663039],
                            [39.57750622, -5.41711215],
                            [39.57821342, -5.41746059],
                            [39.57899244, -5.41792191],
                            [39.57965871, -5.41831146],
                            [39.58023273, -5.41864975],
                            [39.58082723, -5.41898803],
                            [39.58144217, -5.41926468],
                            [39.58202646, -5.41963376],
                            [39.58236482, -5.41994148],
                            [39.58268259, -5.42013627],
                            [39.58319508, -5.42041301],
                            [39.58361541, -5.42073091],
                            [39.58414839, -5.42099737],
                            [39.58466092, -5.42131518],
                            [39.58512225, -5.42165358],
                            [39.58553224, -5.42186882],
                            [39.58568617, -5.42213564],
                            [39.58616811, -5.42259723],
                            [39.58668074, -5.42301772],
                            [39.58713199, -5.42352042],
                            [39.58750119, -5.42393078],
                            [39.58776766, -5.42404347],
                            [39.58803384, -5.42385839],
                            [39.58811602, -5.4240842],
                            [39.58818811, -5.42447432],
                            [39.5883626, -5.42482326],
                            [39.58866001, -5.42515155],
                            [39.58931599, -5.42547949],
                            [39.58987985, -5.42589993],
                            [39.59013622, -5.42615638],
                            [39.59007496, -5.42637207],
                            [39.58982947, -5.42677276],
                            [39.58986044, -5.42700889],
                            [39.59021899, -5.42699827],
                            [39.59054664, -5.42681313],
                            [39.59099719, -5.42659706],
                            [39.59159123, -5.42644246],
                            [39.59196002, -5.42642156],
                            [39.59224669, -5.42623646],
                            [39.59241024, -5.42586665],
                            [39.59268653, -5.4255378],
                            [39.59287075, -5.4253528],
                            [39.59295257, -5.42520897],
                            [39.59317795, -5.42520875],
                            [39.59328041, -5.42522918],
                            [39.59314735, -5.42535253],
                            [39.59292227, -5.42566079],
                            [39.59286109, -5.42594835],
                            [39.59273848, -5.42628732],
                            [39.59249295, -5.42663667],
                            [39.59230872, -5.42681141],
                            [39.59176607, -5.42714052],
                            [39.59158176, -5.42723311],
                            [39.59130532, -5.42740793],
                            [39.59112109, -5.42758267],
                            [39.59102905, -5.42774705],
                            [39.59099847, -5.42791137],
                            [39.59121382, -5.42812678],
                            [39.59126521, -5.42830129],
                            [39.59114241, -5.42843489],
                            [39.59120413, -5.4287018],
                            [39.5913169, -5.42877357],
                            [39.59150129, -5.42876312],
                            [39.59168567, -5.4287424],
                            [39.59194175, -5.42870108],
                            [39.59221836, -5.42870081],
                            [39.59251534, -5.42858757],
                            [39.59293522, -5.42843314],
                            [39.59323211, -5.42821722],
                            [39.59346757, -5.4280527],
                            [39.59365176, -5.42782662],
                            [39.59376422, -5.42759035],
                            [39.59386642, -5.42734382],
                            [39.59395846, -5.42717944],
                            [39.59414465, -5.42704],
                            [39.59430021, -5.42700519],
                            [39.59440387, -5.42694155],
                            [39.59447316, -5.42707433],
                            [39.59460564, -5.42702222],
                            [39.59494549, -5.42686594],
                            [39.59515865, -5.42680797],
                            [39.5953603, -5.42677312],
                            [39.59550434, -5.4267441],
                            [39.59556783, -5.426848],
                            [39.5954643, -5.42704448],
                            [39.59513032, -5.42731049],
                            [39.59494601, -5.42740308],
                            [39.59469259, -5.42753617],
                            [39.59510189, -5.42769749],
                            [39.59494066, -5.42782472],
                            [39.59456044, -5.42793483],
                            [39.59428398, -5.42808527],
                            [39.59446853, -5.42823526],
                            [39.59478544, -5.42820607],
                            [39.59508515, -5.42825776],
                            [39.59525808, -5.42830957],
                            [39.5954022, -5.42836718],
                            [39.59570768, -5.42843042],
                            [39.59577696, -5.42855741],
                            [39.59560419, -5.4286731],
                            [39.59540833, -5.42873683],
                            [39.59547755, -5.42881184],
                            [39.59567931, -5.42888095],
                            [39.59590985, -5.4289096],
                            [39.59610002, -5.42891519],
                            [39.59633034, -5.42873014],
                            [39.5964282, -5.42862031],
                            [39.59648562, -5.42841232],
                            [39.59658919, -5.4282505],
                            [39.59671582, -5.42810598],
                            [39.59686549, -5.42794411],
                            [39.597021, -5.42785732],
                            [39.59718227, -5.42777053],
                            [39.59734354, -5.42768951],
                            [39.59752211, -5.42762002],
                            [39.59756825, -5.42765463],
                            [39.59741277, -5.42776452],
                            [39.59729183, -5.42783973],
                            [39.5971536, -5.42792072],
                            [39.59702693, -5.42803059],
                            [39.59702129, -5.42815189],
                            [39.59696378, -5.42827323],
                            [39.59694086, -5.42840032],
                            [39.5968718, -5.42849858],
                            [39.59673362, -5.42862],
                            [39.59667033, -5.42872403],
                            [39.59682031, -5.42887983],
                            [39.59708539, -5.42887379],
                            [39.59745419, -5.42887343],
                            [39.59751187, -5.42891958],
                            [39.59730453, -5.42904107],
                            [39.59715481, -5.42915096],
                            [39.59705699, -5.42930122],
                            [39.59708595, -5.42945137],
                            [39.59714938, -5.42948596],
                            [39.59737976, -5.42935866],
                            [39.59752382, -5.42935852],
                            [39.59766217, -5.42940459],
                            [39.59782343, -5.42930624],
                            [39.59797889, -5.42917902],
                            [39.5980999, -5.42916735],
                            [39.59810286, -5.42916854],
                            [39.59815755, -5.4291904],
                            [39.59809422, -5.42925399],
                            [39.5979733, -5.4293523],
                            [39.59783511, -5.42946218],
                            [39.59769687, -5.42953162],
                            [39.59759323, -5.42959332],
                            [39.59754137, -5.42962419],
                            [39.59732243, -5.42965906],
                            [39.59713806, -5.4296939],
                            [39.59713772, -5.42969412],
                            [39.59698832, -5.42979223],
                            [39.59697691, -5.42990198],
                            [39.59695399, -5.43004062],
                            [39.59681, -5.43011007],
                            [39.59660833, -5.43013338],
                            [39.59643536, -5.43004691],
                            [39.59629128, -5.43002972],
                            [39.59611264, -5.4300299],
                            [39.59618187, -5.43011069],
                            [39.5960839, -5.43010501],
                            [39.59592822, -5.43001276],
                            [39.59569186, -5.42992058],
                            [39.59551891, -5.42985144],
                            [39.59522491, -5.42974776],
                            [39.59505201, -5.42972483],
                            [39.59493678, -5.42974805],
                            [39.59479845, -5.42971353],
                            [39.59466596, -5.42977142],
                            [39.5944585, -5.42976584],
                            [39.59419916, -5.429743],
                            [39.5940609, -5.42978934],
                            [39.59398031, -5.42987605],
                            [39.5939977, -5.42998],
                            [39.59394021, -5.43012445],
                            [39.59371549, -5.43014777],
                            [39.5935196, -5.43018262],
                            [39.59336404, -5.43021743],
                            [39.59334057, -5.43024528],
                            [39.59330072, -5.43029258],
                            [39.59341603, -5.43035022],
                            [39.59344489, -5.43039062],
                            [39.59335275, -5.43045425],
                            [39.59326061, -5.43051787],
                            [39.59316265, -5.43052952],
                            [39.5930474, -5.43052963],
                            [39.59290333, -5.430524],
                            [39.59279965, -5.43057608],
                            [39.59264418, -5.4307033],
                            [39.59248296, -5.43084207],
                            [39.59238512, -5.43096924],
                            [39.59228719, -5.43099821],
                            [39.59220663, -5.43111958],
                            [39.59212619, -5.43136224],
                            [39.5920398, -5.43142008],
                            [39.59193613, -5.43147217],
                            [39.59188421, -5.43142024],
                            [39.59182085, -5.4314434],
                            [39.59169995, -5.43157059],
                            [39.59177508, -5.43178422],
                            [39.59201155, -5.43199769],
                            [39.5923056, -5.43215334],
                            [39.59269179, -5.43224538],
                            [39.59280123, -5.43219329],
                            [39.59296254, -5.43214692],
                            [39.59322762, -5.43214089],
                            [39.59350425, -5.43216372],
                            [39.59366563, -5.43219244],
                            [39.59383854, -5.43222115],
                            [39.59386155, -5.4321807],
                            [39.59400565, -5.43221521],
                            [39.59436303, -5.43231882],
                            [39.5946512, -5.43234742],
                            [39.59489331, -5.43243381],
                            [39.5951585, -5.43253752],
                            [39.5955274, -5.43262957],
                            [39.59573488, -5.43265824],
                            [39.59586171, -5.4327101],
                            [39.59587332, -5.43279672],
                            [39.59563698, -5.43272765],
                            [39.59544678, -5.4326874],
                            [39.5953258, -5.43272795],
                            [39.59530863, -5.43284926],
                            [39.59551036, -5.43288949],
                            [39.59560262, -5.43294716],
                            [39.59576973, -5.43293544],
                            [39.59575825, -5.43298744],
                            [39.59585056, -5.43309708],
                            [39.59599453, -5.43299298],
                            [39.59607516, -5.43294669],
                            [39.59617903, -5.43308521],
                            [39.59626555, -5.43317176],
                            [39.59639817, -5.43325249],
                            [39.59658259, -5.43326963],
                            [39.59678428, -5.43325788],
                            [39.597009, -5.43323456],
                            [39.59718188, -5.43323439],
                            [39.59721666, -5.43344806],
                            [39.59721108, -5.43362711],
                            [39.59733803, -5.43380603],
                            [39.59739008, -5.43399658],
                            [39.59743635, -5.43416981],
                            [39.59758052, -5.43426785],
                            [39.59771314, -5.43434858],
                            [39.59789753, -5.43433685],
                            [39.59805306, -5.43427316],
                            [39.59824906, -5.43434228],
                            [39.59839899, -5.43444609],
                            [39.59838188, -5.43462516],
                            [39.59830709, -5.4347523],
                            [39.59844122, -5.43495235],
                            [39.59858854, -5.43490876],
                            [39.59871866, -5.43499551],
                            [39.59870161, -5.43528225],
                            [39.59870181, -5.43549077],
                            [39.5987974, -5.43572527],
                            [39.59900544, -5.43570768],
                            [39.59916138, -5.43561196],
                            [39.5993347, -5.43555097],
                            [39.59945612, -5.43561166],
                            [39.59946506, -5.43588969],
                            [39.59931786, -5.43605491],
                            [39.59915334, -5.43624622],
                            [39.59913615, -5.43639394],
                            [39.5992402, -5.43641991],
                            [39.59938747, -5.43632419],
                            [39.59946541, -5.43623722],
                            [39.59963863, -5.43608066],
                            [39.59979456, -5.43596756],
                            [39.59988108, -5.43580239],
                            [39.59994165, -5.43568938],
                            [39.60008015, -5.43548941],
                            [39.60017541, -5.43538505],
                            [39.60020131, -5.43528076],
                            [39.6002618, -5.43508956],
                            [39.60033982, -5.43508948],
                            [39.60045255, -5.43512412],
                            [39.60036602, -5.4352806],
                            [39.60042687, -5.43545431],
                            [39.60055707, -5.43561926],
                            [39.60065254, -5.43573211],
                            [39.60079994, -5.43576672],
                            [39.60099066, -5.43577522],
                            [39.60119001, -5.43574027],
                            [39.60132864, -5.43566193],
                            [39.60148454, -5.43553145],
                            [39.60158844, -5.43540102],
                            [39.60176158, -5.43515757],
                            [39.60190877, -5.43498366],
                            [39.60203877, -5.43494877],
                            [39.60196956, -5.43508786],
                            [39.60182241, -5.4353139],
                            [39.60164075, -5.43569638],
                            [39.60149364, -5.43595718],
                            [39.60129448, -5.43618328],
                            [39.60106061, -5.43637466],
                            [39.60080931, -5.43647048],
                            [39.60078346, -5.43663558],
                            [39.60093105, -5.43685265],
                            [39.60121719, -5.43692187],
                            [39.60157269, -5.43699971],
                            [39.60172006, -5.43699957],
                            [39.60178076, -5.43701688],
                            [39.60172022, -5.43716465],
                            [39.60150371, -5.43737339],
                            [39.60143459, -5.43760804],
                            [39.6016428, -5.43776423],
                            [39.60171235, -5.43796399],
                            [39.60158259, -5.43824215],
                            [39.60142669, -5.43838133],
                            [39.60127945, -5.4385118],
                            [39.60135771, -5.438755],
                            [39.60160913, -5.43878081],
                            [39.60153981, -5.43880695],
                            [39.60118456, -5.43898976],
                            [39.60101127, -5.43907682],
                            [39.60116744, -5.43920699],
                            [39.60141876, -5.43912854],
                            [39.6017481, -5.43905002],
                            [39.60163562, -5.43926734],
                            [39.60156635, -5.43934561],
                            [39.60141906, -5.43943264],
                            [39.60140194, -5.43964987],
                            [39.60134132, -5.43971075],
                            [39.60124603, -5.43978035],
                            [39.60136747, -5.43985842],
                            [39.60156694, -5.43994511],
                            [39.60169706, -5.44003186],
                            [39.60169728, -5.44024908],
                            [39.60156748, -5.44049248],
                            [39.60149825, -5.4406055],
                            [39.60138556, -5.4406143],
                            [39.60106484, -5.44064937],
                            [39.60082212, -5.4406583],
                            [39.60058803, -5.44062378],
                            [39.60039743, -5.44073692],
                            [39.60028484, -5.44084999],
                            [39.60034571, -5.44103238],
                            [39.60047576, -5.44105832],
                            [39.60067525, -5.44116238],
                            [39.60083141, -5.44128387],
                            [39.60110017, -5.44130966],
                            [39.60129078, -5.44119652],
                            [39.60144671, -5.44108342],
                            [39.60159408, -5.44108327],
                            [39.60180216, -5.44110913],
                            [39.60184558, -5.44118728],
                            [39.60173306, -5.44136116],
                            [39.6016032, -5.44154375],
                            [39.60158605, -5.44173491],
                            [39.60169025, -5.44189989],
                            [39.60188973, -5.44200395],
                            [39.60198507, -5.44197779],
                            [39.6020544, -5.44196035],
                            [39.60216705, -5.44191679],
                            [39.60230567, -5.44182977],
                            [39.60238371, -5.44184707],
                            [39.60234921, -5.44202956],
                            [39.6021933, -5.44216004],
                            [39.60192474, -5.44233408],
                            [39.60182949, -5.44244712],
                            [39.60174299, -5.44263836],
                            [39.60164783, -5.44283829],
                            [39.60150922, -5.44292531],
                            [39.60148345, -5.44316861],
                            [39.60177825, -5.44322914],
                            [39.60193438, -5.44331587],
                            [39.60186516, -5.44344626],
                            [39.60188266, -5.44361133],
                            [39.60187426, -5.44388068],
                            [39.60187449, -5.44411526],
                            [39.60186605, -5.44434117],
                            [39.60181422, -5.44452368],
                            [39.60176248, -5.44480176],
                            [39.6017366, -5.44493212],
                            [39.60182336, -5.44500154],
                            [39.60172809, -5.44508852],
                            [39.60163299, -5.44534927],
                            [39.60148586, -5.44559269],
                            [39.60158993, -5.44563603],
                            [39.60181536, -5.44567056],
                            [39.60215351, -5.44573104],
                            [39.60255234, -5.44579146],
                            [39.60282987, -5.44592151],
                            [39.60292518, -5.44586928],
                            [39.60304641, -5.44573015],
                            [39.60316765, -5.44560839],
                            [39.60329828, -5.44550035],
                            [39.6033149, -5.4454866],
                            [39.60352292, -5.44545164],
                            [39.60367033, -5.44548625],
                            [39.60386121, -5.44565114],
                            [39.6039913, -5.44570314],
                            [39.60416469, -5.44571165],
                            [39.60432945, -5.44576362],
                            [39.60438163, -5.44592864],
                            [39.60432972, -5.44603296],
                            [39.60427776, -5.44609383],
                            [39.60422565, -5.44599831],
                            [39.60413023, -5.44593758],
                            [39.60407818, -5.44589419],
                            [39.60395679, -5.44587694],
                            [39.60389042, -5.44589515],
                            [39.60386146, -5.4459031],
                            [39.60374005, -5.44585109],
                            [39.60371399, -5.44579899],
                            [39.6036772, -5.44575299],
                            [39.60364455, -5.44571217],
                            [39.60359563, -5.4456986],
                            [39.60348846, -5.44566889],
                            [39.60342781, -5.44569501],
                            [39.60332385, -5.44576462],
                            [39.60331533, -5.44591234],
                            [39.60325359, -5.44599915],
                            [39.60322876, -5.44603406],
                            [39.60312483, -5.44613843],
                            [39.60295152, -5.44620811],
                            [39.60271754, -5.44628654],
                            [39.60258433, -5.44632917],
                            [39.60252688, -5.44634755],
                            [39.60241419, -5.44634766],
                            [39.6023101, -5.44628694],
                            [39.60215406, -5.4462871],
                            [39.60199808, -5.44634807],
                            [39.60184216, -5.44646987],
                            [39.60171228, -5.44662639],
                            [39.6016171, -5.44680894],
                            [39.60173858, -5.44692177],
                            [39.60176471, -5.44704338],
                            [39.60170419, -5.44720853],
                            [39.60165234, -5.44737366],
                            [39.60153978, -5.44751279],
                            [39.6013752, -5.44764328],
                            [39.60141001, -5.44778226],
                            [39.60145348, -5.44790385],
                            [39.60125409, -5.44790405],
                            [39.60130635, -5.44814728],
                            [39.60153189, -5.44829476],
                            [39.6018266, -5.44825971],
                            [39.60197408, -5.44837251],
                            [39.60202634, -5.44861573],
                            [39.60191375, -5.4487288],
                            [39.60173167, -5.44869422],
                            [39.60168845, -5.44882459],
                            [39.60187929, -5.44893735],
                            [39.60207009, -5.44902405],
                            [39.60239102, -5.4491975],
                            [39.60252122, -5.44936245],
                            [39.60267745, -5.44955344],
                            [39.60280848, -5.44972643],
                            [39.60289446, -5.44983994],
                            [39.60318926, -5.44989177],
                            [39.6033887, -5.44995239],
                            [39.60344081, -5.45003923],
                            [39.60328488, -5.45016102],
                            [39.60324164, -5.45026533],
                            [39.60336309, -5.4503434],
                            [39.60321581, -5.45043912],
                            [39.60304245, -5.45045667],
                            [39.60281699, -5.45039608],
                            [39.60251339, -5.45021392],
                            [39.60240077, -5.45029223],
                            [39.60244439, -5.45056153],
                            [39.60255724, -5.45071781],
                            [39.6027742, -5.45095218],
                            [39.60268771, -5.4511521],
                            [39.60261842, -5.45121299],
                            [39.60248837, -5.45120443],
                            [39.60229743, -5.45097872],
                            [39.60218471, -5.45096146],
                            [39.60212421, -5.45114398],
                            [39.60205483, -5.45110929],
                            [39.60189011, -5.45110946],
                            [39.60190762, -5.45127452],
                            [39.60185558, -5.45124851],
                            [39.60175145, -5.45115304],
                            [39.60161264, -5.45104892],
                            [39.6014043, -5.4507624],
                            [39.60124817, -5.45067568],
                            [39.60110935, -5.45055418],
                            [39.60084026, -5.45020691],
                            [39.60071863, -5.44993769],
                            [39.60055374, -5.44975539],
                            [39.60031957, -5.44965136],
                            [39.60012006, -5.44952992],
                            [39.59997278, -5.44962564],
                            [39.59986263, -5.44957058],
                            [39.59979932, -5.44953893],
                            [39.59949585, -5.4494871],
                            [39.59932241, -5.44942646],
                            [39.59916632, -5.44937448],
                            [39.59896698, -5.44942681],
                            [39.59888045, -5.44958329],
                            [39.59900218, -5.44995677],
                            [39.599167, -5.45006956],
                            [39.59936647, -5.45014755],
                            [39.59956595, -5.45024293],
                            [39.59974802, -5.45026013],
                            [39.59986948, -5.45035558],
                            [39.59998237, -5.45054661],
                            [39.59999116, -5.45067693],
                            [39.59993065, -5.45085076],
                            [39.59993095, -5.45114617],
                            [39.60013952, -5.45166727],
                            [39.60042588, -5.4519537],
                            [39.60060793, -5.45195352],
                            [39.60073812, -5.45210109],
                            [39.60105927, -5.45250044],
                            [39.60128488, -5.45271743],
                            [39.601493, -5.45277804],
                            [39.60157977, -5.45286484],
                            [39.60179657, -5.45293413],
                            [39.60190085, -5.45318599],
                            [39.60203109, -5.45339438],
                            [39.60213536, -5.45362887],
                            [39.60215289, -5.45382868],
                            [39.60235247, -5.45401963],
                            [39.60254323, -5.45405419],
                            [39.60292507, -5.45446217],
                            [39.60301189, -5.45459241],
                            [39.60295124, -5.45462722],
                            [39.6028646, -5.45467944],
                            [39.60286476, -5.45483583],
                            [39.60296024, -5.45495737],
                            [39.60297777, -5.4551485],
                            [39.60311669, -5.45536557],
                            [39.60322162, -5.45545227],
                            [39.60331625, -5.45553046],
                            [39.6034811, -5.45566931],
                            [39.603568, -5.45587774],
                            [39.60347272, -5.45596472],
                            [39.60340337, -5.45596479],
                            [39.60329071, -5.45599966],
                            [39.60328233, -5.45629507],
                            [39.6033952, -5.45646004],
                            [39.60349935, -5.45658157],
                            [39.60343009, -5.45667722],
                            [39.60341982, -5.45670014],
                            [39.60335224, -5.45685106],
                            [39.60320494, -5.45692072],
                            [39.60314434, -5.45700767],
                            [39.60293626, -5.4569905],
                            [39.60281489, -5.45699062],
                            [39.60289306, -5.45712956],
                            [39.60289313, -5.45720775],
                            [39.60267638, -5.4571819],
                            [39.60244231, -5.45718214],
                            [39.60231239, -5.45729522],
                            [39.60213908, -5.4573649],
                            [39.60198309, -5.45742587],
                            [39.60192248, -5.45749544],
                            [39.60193132, -5.4576692],
                            [39.6018967, -5.45773006],
                            [39.60183599, -5.45769536],
                            [39.60183617, -5.45787782],
                            [39.60175832, -5.45805167],
                            [39.60168034, -5.45809519],
                            [39.6016284, -5.45817344],
                            [39.60169792, -5.45833845],
                            [39.60192335, -5.45837298],
                            [39.60207939, -5.45836413],
                            [39.6021573, -5.4582511],
                            [39.60213982, -5.45811211],
                            [39.60226982, -5.45807722],
                            [39.6023827, -5.45825957],
                            [39.60257355, -5.4583897],
                            [39.60290312, -5.45852839],
                            [39.60318929, -5.45861498],
                            [39.6033539, -5.45851056],
                            [39.60370917, -5.45834512],
                            [39.60392587, -5.45831884],
                            [39.60419453, -5.45824037],
                            [39.60451518, -5.4581271],
                            [39.60456712, -5.45804885],
                            [39.60461881, -5.45772733],
                            [39.60457535, -5.45761442],
                            [39.60460982, -5.45740586],
                            [39.60449695, -5.45723221],
                            [39.60441881, -5.45711934],
                            [39.6044187, -5.45700639],
                            [39.604514, -5.45694547],
                            [39.60449656, -5.45684123],
                            [39.60438371, -5.45669364],
                            [39.60428821, -5.45655472],
                            [39.60423605, -5.45640707],
                            [39.60418043, -5.45631967],
                            [39.60411449, -5.45621604],
                            [39.6039929, -5.45599895],
                            [39.60393203, -5.45580787],
                            [39.60405341, -5.45581644],
                            [39.60414884, -5.45589454],
                            [39.60423565, -5.4560074],
                            [39.60438311, -5.45609413],
                            [39.60443506, -5.45603326],
                            [39.60443488, -5.45585081],
                            [39.60441738, -5.45568574],
                            [39.60439982, -5.45546855],
                            [39.60438228, -5.45526873],
                            [39.60438223, -5.45521252],
                            [39.60438198, -5.45496464],
                            [39.60436441, -5.45473007],
                            [39.60429473, -5.45440866],
                            [39.60429453, -5.45420014],
                            [39.60425085, -5.45387002],
                            [39.60411441, -5.4535892],
                            [39.60410317, -5.45356608],
                            [39.60399902, -5.45344454],
                            [39.60400755, -5.45330552],
                            [39.60405078, -5.45318384],
                            [39.60399863, -5.45305356],
                            [39.60400716, -5.45291454],
                            [39.60404173, -5.45280155],
                            [39.6040936, -5.45266249],
                            [39.60397203, -5.45245408],
                            [39.60400667, -5.4524193],
                            [39.60419747, -5.4524973],
                            [39.60420104, -5.45249558],
                            [39.60439676, -5.45240153],
                            [39.60430995, -5.45227998],
                            [39.60414101, -5.4521754],
                            [39.60412778, -5.45216721],
                            [39.60400639, -5.45214127],
                            [39.60389373, -5.45217613],
                            [39.60376363, -5.45211544],
                            [39.60380684, -5.45197639],
                            [39.60391946, -5.45189808],
                            [39.60408658, -5.45188502],
                            [39.60414483, -5.45188047],
                            [39.6042229, -5.45192384],
                            [39.60424044, -5.45212365],
                            [39.6044138, -5.4521061],
                            [39.6044484, -5.45202787],
                            [39.60440483, -5.45180202],
                            [39.60436129, -5.45161091],
                            [39.60420525, -5.45161107],
                            [39.60427449, -5.45149805],
                            [39.60446515, -5.45143704],
                            [39.60462106, -5.45130656],
                            [39.60475091, -5.45112397],
                            [39.60501062, -5.45075879],
                            [39.60511432, -5.45042853],
                            [39.60535687, -5.45025452],
                            [39.60545225, -5.4502718],
                            [39.60553046, -5.45045418],
                            [39.6056518, -5.45042799],
                            [39.6057557, -5.45030625],
                            [39.60601562, -5.45014959],
                            [39.60624082, -5.44995822],
                            [39.6061973, -5.44978449],
                            [39.60625786, -5.4496628],
                            [39.60638794, -5.44970611],
                            [39.60644016, -5.44991458],
                            [39.60670918, -5.45019234],
                            [39.60693477, -5.45038326],
                            [39.60709944, -5.45034834],
                            [39.60737674, -5.45023511],
                            [39.60756728, -5.45006115],
                            [39.6076539, -5.44999155],
                            [39.60774923, -5.4499567],
                            [39.60800933, -5.44999119],
                            [39.60810478, -5.45007798],
                            [39.60833892, -5.45015594],
                            [39.60842564, -5.45019061],
                            [39.60848657, -5.45023279],
                            [39.60853842, -5.45026869],
                            [39.60873784, -5.45030324],
                            [39.60893725, -5.45032042],
                            [39.60913682, -5.45050267],
                            [39.60909365, -5.45067648],
                            [39.60912844, -5.4507894],
                            [39.60928453, -5.45084137],
                            [39.60946656, -5.45081512],
                            [39.60959659, -5.45081499],
                            [39.60996947, -5.45092756],
                            [39.61011689, -5.45097086],
                            [39.61008244, -5.45119679],
                            [39.61022143, -5.45148337],
                            [39.61042966, -5.45164824],
                            [39.61068999, -5.45190863],
                            [39.61060359, -5.45219543],
                            [39.61056908, -5.45236055],
                            [39.61073394, -5.45250808],
                            [39.61082914, -5.4523516],
                            [39.61092432, -5.45216904],
                            [39.61088938, -5.45190842],
                            [39.61083696, -5.45150881],
                            [39.61075843, -5.45100496],
                            [39.61076673, -5.45064004],
                            [39.61097464, -5.45049212],
                            [39.6111654, -5.45053537],
                            [39.61116551, -5.45063963],
                            [39.61103562, -5.45078747],
                            [39.61126102, -5.45079593],
                            [39.61156428, -5.45063923],
                            [39.61179817, -5.45046522],
                            [39.61205821, -5.45043889],
                            [39.6121277, -5.45056915],
                            [39.61185903, -5.45063893],
                            [39.61184198, -5.45092566],
                            [39.61193754, -5.4511254],
                            [39.61205907, -5.45129036],
                            [39.61221529, -5.45146397],
                            [39.61244929, -5.45140291],
                            [39.61272665, -5.4513505],
                            [39.61299542, -5.45137629],
                            [39.61327283, -5.45137601],
                            [39.61343747, -5.45130633],
                            [39.6135326, -5.45108902],
                            [39.61350633, -5.4508284],
                            [39.61351471, -5.45054167],
                            [39.61377469, -5.45044583],
                            [39.61419081, -5.45046279],
                            [39.61436441, -5.45067113],
                            [39.6143127, -5.45097528],
                            [39.61420022, -5.45119261],
                            [39.61445184, -5.45140087],
                            [39.61470338, -5.45153963],
                            [39.6147276, -5.45156385],
                            [39.61482487, -5.45166114],
                            [39.61483386, -5.45197392],
                            [39.61486885, -5.45228667],
                            [39.61485447, -5.45232426],
                            [39.61478239, -5.45251265],
                            [39.61473067, -5.45279943],
                            [39.61474841, -5.45319908],
                            [39.61491349, -5.45355513],
                            [39.61498448, -5.45358635],
                            [39.61513031, -5.45365048],
                            [39.61526022, -5.45352871],
                            [39.61537309, -5.45369368],
                            [39.61533003, -5.45398044],
                            [39.61532161, -5.45422373],
                            [39.61559931, -5.45451016],
                            [39.61584219, -5.45464893],
                            [39.61603299, -5.45472693],
                            [39.61613716, -5.45486584],
                            [39.61627605, -5.45504815],
                            [39.61635416, -5.45513496],
                            [39.61631969, -5.45533482],
                            [39.6162853, -5.45562158],
                            [39.61635479, -5.45575183],
                            [39.61632024, -5.45587351],
                            [39.61612113, -5.45615174],
                            [39.61589585, -5.45626492],
                            [39.61578336, -5.45647356],
                            [39.61586153, -5.45661249],
                            [39.61600037, -5.45674268],
                            [39.61604384, -5.45687296],
                            [39.61582729, -5.45704695],
                            [39.61581009, -5.45717729],
                            [39.61605282, -5.45717705],
                            [39.6163388, -5.45707249],
                            [39.61648622, -5.45712447],
                            [39.61647773, -5.45729825],
                            [39.61648654, -5.45743725],
                            [39.61673803, -5.45751519],
                            [39.61709346, -5.45751483],
                            [39.61730162, -5.45761019],
                            [39.61733635, -5.45767097],
                            [39.61721517, -5.45785355],
                            [39.6170246, -5.45799276],
                            [39.61681674, -5.45819281],
                            [39.61657419, -5.45837552],
                            [39.61642698, -5.45854075],
                            [39.61634053, -5.45877542],
                            [39.61633216, -5.45906215],
                            [39.6163671, -5.45932277],
                            [39.61644539, -5.45958334],
                            [39.61658426, -5.45974828],
                            [39.61682722, -5.45996524],
                            [39.61702664, -5.4599911],
                            [39.61708712, -5.4597912],
                            [39.61718238, -5.45969553],
                            [39.61725155, -5.45951301],
                            [39.61731209, -5.45937393],
                            [39.61739895, -5.45953892],
                            [39.617408, -5.45991251],
                            [39.61733022, -5.46015587],
                            [39.61726974, -5.46034708],
                            [39.61750385, -5.46039028],
                            [39.61782454, -5.46032913],
                            [39.61793738, -5.46045934],
                            [39.61810219, -5.46055474],
                            [39.61816307, -5.46074583],
                            [39.61845801, -5.46093667],
                            [39.61864006, -5.46092779],
                            [39.61882196, -5.4607799],
                            [39.61877834, -5.46051929],
                            [39.61880425, -5.4604237],
                            [39.61902092, -5.46036265],
                            [39.61908142, -5.46018013],
                            [39.61898596, -5.46008466],
                            [39.61914192, -5.4600063],
                            [39.61942798, -5.45997994],
                            [39.61980943, -5.45998824],
                            [39.61979217, -5.46006645],
                            [39.61988754, -5.46007504],
                            [39.61995676, -5.45994464],
                            [39.62003459, -5.45976211],
                            [39.6201645, -5.45963165],
                            [39.62025988, -5.45965761],
                            [39.62048547, -5.45983984],
                            [39.62059832, -5.45998743],
                            [39.62078903, -5.45997854],
                            [39.62113572, -5.45989999],
                            [39.62132647, -5.45993454],
                            [39.62162123, -5.45994293],
                            [39.62179469, -5.46001225],
                            [39.62200282, -5.46008155],
                            [39.62234961, -5.46010725],
                            [39.62270503, -5.4600982],
                            [39.62296504, -5.46002842],
                            [39.62326848, -5.46005417],
                            [39.62351125, -5.46007999],
                            [39.62366735, -5.46014064],
                            [39.62389285, -5.46023598],
                            [39.62431764, -5.46024423],
                            [39.62441298, -5.46021806],
                            [39.62448216, -5.46005291],
                            [39.62455128, -5.45982694],
                            [39.62460316, -5.45969656],
                            [39.62459417, -5.45938379],
                            [39.62460263, -5.45918394],
                            [39.62468063, -5.45916649],
                            [39.62476739, -5.45922722],
                            [39.62475896, -5.45946181],
                            [39.62467266, -5.45984419],
                            [39.62465563, -5.46013962],
                            [39.62455183, -5.46035693],
                            [39.62475126, -5.46040017],
                            [39.62519342, -5.46043446],
                            [39.62541893, -5.46053849],
                            [39.62557506, -5.46062521],
                            [39.62587002, -5.46082474],
                            [39.62617362, -5.46099819],
                            [39.62647711, -5.46106738],
                            [39.6268326, -5.46111914],
                            [39.62700591, -5.46104945],
                            [39.62712707, -5.46084949],
                            [39.62712687, -5.46065835],
                            [39.62696193, -5.46044131],
                            [39.62690968, -5.46020678],
                            [39.62705709, -5.46024138],
                            [39.62736072, -5.46044958],
                            [39.6275776, -5.46058837],
                            [39.62774249, -5.46076197],
                            [39.62798543, -5.46096155],
                            [39.62798567, -5.46118745],
                            [39.62788184, -5.4613787],
                            [39.62776943, -5.46165685],
                            [39.62762222, -5.46181339],
                            [39.62747516, -5.46212633],
                            [39.62745807, -5.46236093],
                            [39.62761402, -5.46227389],
                            [39.62779606, -5.46225632],
                            [39.62783084, -5.46236054],
                            [39.62801284, -5.46230822],
                            [39.62828135, -5.46208204],
                            [39.62837671, -5.46208194],
                            [39.62834217, -5.46221231],
                            [39.62816042, -5.46249921],
                            [39.62799592, -5.46270791],
                            [39.62787481, -5.46296],
                            [39.62779716, -5.4633163],
                            [39.62771942, -5.46358573],
                            [39.62769357, -5.46374214],
                            [39.62790175, -5.46385488],
                            [39.62844803, -5.46396726],
                            [39.62879485, -5.46401902],
                            [39.62917632, -5.46404469],
                            [39.62965311, -5.4640355],
                            [39.62992175, -5.46393096],
                            [39.63007765, -5.46379178],
                            [39.63026444, -5.46366275],
                            [39.63032294, -5.4636407],
                            [39.63040699, -5.46358196],
                            [39.63050203, -5.46352688],
                            [39.63056775, -5.46342052],
                            [39.63062981, -5.46331782],
                            [39.63062238, -5.46320053],
                            [39.63069541, -5.46309049],
                            [39.63080134, -5.46296576],
                            [39.63090726, -5.46283003],
                            [39.63099865, -5.46279328],
                            [39.63106072, -5.46269058],
                            [39.63111544, -5.4625549],
                            [39.63119948, -5.46248517],
                            [39.63120323, -5.46257314],
                            [39.63124716, -5.46261341],
                            [39.63128747, -5.46268668],
                            [39.6312876, -5.46281497],
                            [39.63129867, -5.46290292],
                            [39.63122563, -5.46300563],
                            [39.63114177, -5.46325497],
                            [39.63105053, -5.46343834],
                            [39.6310141, -5.46357766],
                            [39.63104709, -5.46364727],
                            [39.63121163, -5.46360678],
                            [39.63123733, -5.46370572],
                            [39.63113864, -5.4637608],
                            [39.63105831, -5.46388551],
                            [39.63104377, -5.46396616],
                            [39.63112789, -5.46397341],
                            [39.63121562, -5.46392933],
                            [39.63123033, -5.46400262],
                            [39.63135105, -5.46403182],
                            [39.63148269, -5.46400969],
                            [39.6315558, -5.46397662],
                            [39.63166549, -5.46395085],
                            [39.63178984, -5.46395072],
                            [39.63182279, -5.46398367],
                            [39.63182285, -5.46403865],
                            [39.63164378, -5.4641708],
                            [39.63154877, -5.46424787],
                            [39.63153421, -5.46431386],
                            [39.6316294, -5.46440906],
                            [39.63174279, -5.46442361],
                            [39.63181233, -5.46446752],
                            [39.63189644, -5.4644601],
                            [39.632028, -5.46436466],
                            [39.63210106, -5.46428394],
                            [39.6321156, -5.46419962],
                            [39.63218499, -5.46410059],
                            [39.6322654, -5.46405285],
                            [39.63234222, -5.46406743],
                            [39.63240812, -5.46412967],
                            [39.63224738, -5.46430945],
                            [39.63229863, -5.46434971],
                            [39.63219632, -5.46444512],
                            [39.63211229, -5.46452952],
                            [39.63205019, -5.46459922],
                            [39.63203565, -5.46468721],
                            [39.63212351, -5.46476776],
                            [39.63223698, -5.46485561],
                            [39.63218585, -5.46492164],
                            [39.63205062, -5.46500975],
                            [39.63196653, -5.4650355],
                            [39.63197398, -5.46516011],
                            [39.63210566, -5.4651783],
                            [39.63212404, -5.46526625],
                            [39.63224479, -5.46532477],
                            [39.63241671, -5.46535392],
                            [39.63239117, -5.46541259],
                            [39.63237304, -5.46555923],
                            [39.6324353, -5.4656398],
                            [39.63240611, -5.46570581],
                            [39.63225986, -5.46574628],
                            [39.63211734, -5.46585273],
                            [39.63205162, -5.46595909],
                            [39.63202615, -5.46609108],
                            [39.63210685, -5.46631825],
                            [39.63228213, -5.46641838],
                            [39.63228617, -5.46642069],
                            [39.63228166, -5.4664245],
                            [39.63221674, -5.46647941],
                            [39.63211804, -5.46652716],
                            [39.63212545, -5.46661513],
                            [39.63213287, -5.46671775],
                            [39.63225356, -5.46671029],
                            [39.63219879, -5.46680199],
                            [39.63202342, -5.46697445],
                            [39.63202349, -5.46704409],
                            [39.63205288, -5.46716868],
                            [39.63212246, -5.46725658],
                            [39.63218104, -5.46731516],
                            [39.63221115, -5.46731513],
                            [39.63224687, -5.46731509],
                            [39.63240413, -5.4673076],
                            [39.63249924, -5.46732582],
                            [39.63254679, -5.46733311],
                            [39.63261265, -5.46735136],
                            [39.63267483, -5.46736229],
                            [39.63275902, -5.46742818],
                            [39.63281391, -5.46745745],
                            [39.6328798, -5.46751603],
                            [39.6328433, -5.46758571],
                            [39.63279212, -5.46760042],
                            [39.63274827, -5.46763712],
                            [39.63270448, -5.46773247],
                            [39.6325838, -5.46774359],
                            [39.63248147, -5.46781334],
                            [39.6324962, -5.4679123],
                            [39.63254014, -5.46796723],
                            [39.63248536, -5.46803693],
                            [39.63242687, -5.46806632],
                            [39.63239771, -5.46816165],
                            [39.63240518, -5.46830459],
                            [39.63247846, -5.46843647],
                            [39.63249323, -5.46856474],
                            [39.63249333, -5.46866371],
                            [39.63248245, -5.46874803],
                            [39.6324496, -5.46881037],
                            [39.63243871, -5.46889469],
                            [39.63239851, -5.46892405],
                            [39.63232169, -5.46890581],
                            [39.6323145, -5.46902311],
                            [39.63230731, -5.46914408],
                            [39.63234759, -5.46918802],
                            [39.63238412, -5.46915133],
                            [39.63242062, -5.46907798],
                            [39.63244998, -5.46917325],
                            [39.63250489, -5.46922817],
                            [39.63245013, -5.46931987],
                            [39.6324063, -5.46937123],
                            [39.63241371, -5.46946286],
                            [39.63245766, -5.46952512],
                            [39.63244306, -5.46955813],
                            [39.63237363, -5.46960585],
                            [39.63230791, -5.46971955],
                            [39.63231537, -5.46985516],
                            [39.63227525, -5.46995783],
                            [39.63223876, -5.47004218],
                            [39.6321583, -5.47004226],
                            [39.63216935, -5.47011556],
                            [39.63223895, -5.47021812],
                            [39.63219879, -5.47029147],
                            [39.63216227, -5.47033916],
                            [39.63215516, -5.47053343],
                            [39.63210045, -5.47068377],
                            [39.63207125, -5.47073512],
                            [39.63191051, -5.47091489],
                            [39.63186306, -5.47100291],
                            [39.63183758, -5.4711129],
                            [39.63175358, -5.47123395],
                            [39.63168057, -5.47136232],
                            [39.63164414, -5.47149797],
                            [39.63163699, -5.47165559],
                            [39.63162617, -5.47179856],
                            [39.63161536, -5.47195252],
                            [39.63160091, -5.47212481],
                            [39.63159737, -5.4722421],
                            [39.63163403, -5.47232637],
                            [39.63160868, -5.47255732],
                            [39.63153929, -5.47266002],
                            [39.63149186, -5.47277004],
                            [39.63149935, -5.47293497],
                            [39.6314848, -5.47300829],
                            [39.63147758, -5.47309627],
                            [39.6314301, -5.47316597],
                            [39.63139358, -5.47321732],
                            [39.63137175, -5.47332731],
                            [39.63134702, -5.47346587],
                            [39.63133903, -5.47351061],
                            [39.63133548, -5.47361691],
                            [39.63135389, -5.47373052],
                            [39.63139784, -5.47379279],
                            [39.63144914, -5.47388437],
                            [39.63148948, -5.47399062],
                            [39.63145674, -5.47415927],
                            [39.63144585, -5.47423625],
                            [39.63141669, -5.47433158],
                            [39.63144603, -5.4744012],
                            [39.63141685, -5.47448187],
                            [39.63139132, -5.4745442],
                            [39.63140968, -5.47462116],
                            [39.63138051, -5.47470183],
                            [39.6313989, -5.47479711],
                            [39.63149772, -5.47487032],
                            [39.63151976, -5.47496193],
                            [39.63151988, -5.47507556],
                            [39.63157483, -5.47516347],
                            [39.63167001, -5.47524767],
                            [39.6316957, -5.47533195],
                            [39.63176904, -5.47550781],
                            [39.63181303, -5.47560673],
                            [39.63182045, -5.47571302],
                            [39.63193395, -5.47582653],
                            [39.63193405, -5.47591817],
                            [39.63200731, -5.47602439],
                            [39.63208929, -5.47608941],
                            [39.63211346, -5.47610858],
                            [39.63217567, -5.47613784],
                            [39.63222705, -5.47631006],
                            [39.6322784, -5.47645295],
                            [39.63236267, -5.47658849],
                            [39.63236272, -5.47664347],
                            [39.63238107, -5.47669843],
                            [39.63242865, -5.47673503],
                            [39.63238121, -5.47683771],
                            [39.63230087, -5.47695143],
                            [39.63230464, -5.47706139],
                            [39.63230473, -5.47714569],
                            [39.63231214, -5.47722999],
                            [39.63235246, -5.47731425],
                            [39.63244759, -5.47735813],
                            [39.63250971, -5.47730309],
                            [39.6325463, -5.47731771],
                            [39.63255367, -5.47737268],
                            [39.63267078, -5.47743487],
                            [39.63272203, -5.4774788],
                            [39.63275868, -5.47755574],
                            [39.63279167, -5.47762535],
                            [39.632775, -5.47764448],
                            [39.6327661, -5.4776547],
                            [39.63270025, -5.4776401],
                            [39.63269151, -5.47763617],
                            [39.63262707, -5.47760719],
                            [39.63256848, -5.47754494],
                            [39.63251365, -5.47757066],
                            [39.63255397, -5.47765492],
                            [39.63252112, -5.4777136],
                            [39.63252523, -5.47771932],
                            [39.63258704, -5.47780517],
                            [39.63260539, -5.47785646],
                            [39.63262009, -5.47792609],
                            [39.6325506, -5.47792983],
                            [39.63249942, -5.47795554],
                            [39.63244828, -5.47801424],
                            [39.63239705, -5.47798497],
                            [39.63233484, -5.47795572],
                            [39.63224707, -5.47795947],
                            [39.63216661, -5.47796689],
                            [39.63213376, -5.47802191],
                            [39.63208621, -5.47802196],
                            [39.63208245, -5.47792666],
                            [39.63204951, -5.4779047],
                            [39.632002, -5.47793407],
                            [39.63195816, -5.47798544],
                            [39.63195091, -5.47804776],
                            [39.63202414, -5.47812465],
                            [39.63197664, -5.47816869],
                            [39.63187058, -5.4781688],
                            [39.63180477, -5.47819453],
                            [39.6317829, -5.47826053],
                            [39.63178667, -5.47837049],
                            [39.63176484, -5.47847681],
                            [39.63172832, -5.47853549],
                            [39.63166983, -5.47856121],
                            [39.63165154, -5.47855757],
                            [39.63157833, -5.478499],
                            [39.63150877, -5.47842943],
                            [39.63143927, -5.4784185],
                            [39.6313808, -5.47846988],
                            [39.63144308, -5.47856878],
                            [39.63159313, -5.47866026],
                            [39.63169553, -5.47864915],
                            [39.63168095, -5.47869682],
                            [39.63168472, -5.47880311],
                            [39.63170674, -5.4788764],
                            [39.63180191, -5.47895694],
                            [39.63186777, -5.47898253],
                            [39.63192637, -5.47905944],
                            [39.63202507, -5.47901168],
                            [39.6320579, -5.47893101],
                            [39.63213107, -5.47894926],
                            [39.63210921, -5.47902992],
                            [39.63205445, -5.47912162],
                            [39.63212034, -5.47918019],
                            [39.63222272, -5.47915076],
                            [39.63227388, -5.47910672],
                            [39.632347, -5.47908832],
                            [39.63234705, -5.47913597],
                            [39.63244585, -5.47918351],
                            [39.63248237, -5.47912849],
                            [39.63256285, -5.47914674],
                            [39.63257025, -5.4792237],
                            [39.63262514, -5.47925297],
                            [39.63264344, -5.47926395],
                            [39.6326069, -5.47930064],
                            [39.63255574, -5.47933735],
                            [39.63260707, -5.47946192],
                            [39.63266561, -5.47947652],
                            [39.63272791, -5.47959741],
                            [39.63279377, -5.479623],
                            [39.63284124, -5.47954964],
                            [39.63289602, -5.47947261],
                            [39.63296916, -5.4794652],
                            [39.63299849, -5.47953848],
                            [39.63305699, -5.47951642],
                            [39.6331082, -5.47952003],
                            [39.63314124, -5.47963729],
                            [39.63318524, -5.47974721],
                            [39.63325837, -5.4797288],
                            [39.63329486, -5.47965179],
                            [39.6333205, -5.47968475],
                            [39.63336449, -5.47978001],
                            [39.63342669, -5.47980193],
                            [39.63346687, -5.47975424],
                            [39.63350342, -5.47972855],
                            [39.63356194, -5.47972848],
                            [39.63361694, -5.47986771],
                            [39.63370837, -5.47986028],
                            [39.63377046, -5.47977591],
                            [39.63382889, -5.47969888],
                            [39.63389107, -5.47969881],
                            [39.63389842, -5.47973179],
                            [39.63392043, -5.47979408],
                            [39.63398996, -5.47983066],
                            [39.63400099, -5.4798893],
                            [39.63406693, -5.47999186],
                            [39.63415843, -5.48004674],
                            [39.63429374, -5.48003194],
                            [39.63433392, -5.47998791],
                            [39.63438147, -5.47999153],
                            [39.63448024, -5.48000975],
                            [39.63452773, -5.47995472],
                            [39.63461184, -5.47995096],
                            [39.63467411, -5.4800352],
                            [39.63476553, -5.48002411],
                            [39.63484597, -5.47999837],
                            [39.63490451, -5.4800203],
                            [39.63496668, -5.4800129],
                            [39.63501796, -5.48008982],
                            [39.6350692, -5.48011909],
                            [39.63512036, -5.48008238],
                            [39.63516785, -5.48003101],
                            [39.63517504, -5.47991005],
                            [39.63515304, -5.47985875],
                            [39.63511635, -5.47974883],
                            [39.63508695, -5.47961691],
                            [39.63513809, -5.47955454],
                            [39.63517098, -5.47952885],
                            [39.63514897, -5.47946656],
                            [39.63511601, -5.47941894],
                            [39.63500626, -5.47940073],
                            [39.63488921, -5.47938619],
                            [39.63478312, -5.47936431],
                            [39.63477207, -5.47928369],
                            [39.63473172, -5.47917377],
                            [39.63471696, -5.47905282],
                            [39.63463759, -5.47893195],
                            [39.63457902, -5.47887825],
                            [39.63453988, -5.47875611],
                            [39.63446172, -5.47863402],
                            [39.63440313, -5.47856077],
                            [39.63436404, -5.47849239],
                            [39.63438349, -5.47843861],
                            [39.63435416, -5.47836533],
                            [39.63430048, -5.47833118],
                            [39.63422725, -5.47825795],
                            [39.6343101, -5.47820899],
                            [39.63437832, -5.47816004],
                            [39.63449536, -5.47816481],
                            [39.63450542, -5.47814838],
                            [39.63453431, -5.47810123],
                            [39.63459772, -5.47812071],
                            [39.63469492, -5.47820523],
                            [39.63470999, -5.47821834],
                            [39.63473933, -5.47830139],
                            [39.63466137, -5.47836012],
                            [39.63457363, -5.47839931],
                            [39.63460297, -5.47847259],
                            [39.63473954, -5.47850177],
                            [39.63484679, -5.47846255],
                            [39.63494912, -5.47839403],
                            [39.63494897, -5.47824741],
                            [39.63493422, -5.47813502],
                            [39.63484635, -5.47804714],
                            [39.63485095, -5.47798641],
                            [39.63485116, -5.4779836],
                            [39.6348601, -5.47795436],
                            [39.6348706, -5.47792005],
                            [39.63493881, -5.47786622],
                            [39.63498752, -5.47780752],
                            [39.63509968, -5.47781229],
                            [39.63518261, -5.47783663],
                            [39.63519243, -5.47790016],
                            [39.63520711, -5.4779539],
                            [39.63532908, -5.47800264],
                            [39.6354558, -5.47793409],
                            [39.63553373, -5.47784604],
                            [39.6356556, -5.4778117],
                            [39.63575807, -5.47787023],
                            [39.63586058, -5.47796298],
                            [39.63597755, -5.47789933],
                            [39.636075, -5.47782591],
                            [39.63606999, -5.47770374],
                            [39.63610407, -5.47764994],
                            [39.63653805, -5.47762016],
                            [39.636655, -5.47753695],
                            [39.63672816, -5.47755154],
                            [39.63696223, -5.47754151],
                            [39.63722554, -5.47752657],
                            [39.63740599, -5.47754104],
                            [39.63752305, -5.47756535],
                            [39.63765954, -5.47751634],
                            [39.63787412, -5.47752588],
                            [39.6380497, -5.47755502],
                            [39.63811793, -5.47751096],
                            [39.63821543, -5.47748642],
                            [39.63832758, -5.47747653],
                            [39.63847873, -5.47745682],
                            [39.6386396, -5.47740289],
                            [39.63881017, -5.47730496],
                            [39.63894176, -5.47723151],
                            [39.63915146, -5.47723617],
                            [39.63938065, -5.47723104],
                            [39.63954158, -5.47724065],
                            [39.63966346, -5.47720631],
                            [39.63982926, -5.47720124],
                            [39.63998529, -5.47718641],
                            [39.64008284, -5.47720586],
                            [39.64015597, -5.47719112],
                            [39.64018029, -5.47713245],
                            [39.64027296, -5.47714212],
                            [39.6403949, -5.4771762],
                            [39.64051201, -5.4772445],
                            [39.64063894, -5.47737143],
                            [39.64067319, -5.47747891],
                            [39.64076112, -5.47762055],
                            [39.64088805, -5.47775237],
                            [39.64108336, -5.47798674],
                            [39.64120548, -5.4781821],
                            [39.64132269, -5.47834814],
                            [39.64139102, -5.47840183],
                            [39.64146422, -5.47845062],
                            [39.64155703, -5.47859714],
                            [39.64163998, -5.47864592],
                            [39.64174732, -5.47869468],
                            [39.64184492, -5.47876788],
                            [39.64193274, -5.47880689],
                            [39.64202543, -5.47883611],
                            [39.64214737, -5.4788653],
                            [39.64226935, -5.47892382],
                            [39.64239619, -5.47897256],
                            [39.64246445, -5.47896271],
                            [39.64256199, -5.47896749],
                            [39.64261568, -5.47901142],
                            [39.64267918, -5.47911398],
                            [39.64273284, -5.47913347],
                            [39.64284509, -5.47921644],
                            [39.64289391, -5.47927014],
                            [39.64294279, -5.47937761],
                            [39.64300621, -5.47940198],
                            [39.64305015, -5.47944591],
                            [39.6430112, -5.4795046],
                            [39.64296255, -5.47961217],
                            [39.64288957, -5.47976864],
                            [39.64277262, -5.47985185],
                            [39.64265073, -5.47987642],
                            [39.642602, -5.47990091],
                            [39.64257767, -5.4799498],
                            [39.64261186, -5.48000841],
                            [39.64271434, -5.48007184],
                            [39.64281683, -5.48014992],
                            [39.64285103, -5.48020853],
                            [39.64285112, -5.4802965],
                            [39.64284633, -5.48037959],
                            [39.64278791, -5.48046762],
                            [39.64288554, -5.48056526],
                            [39.64298321, -5.48068734],
                            [39.64310526, -5.48082405],
                            [39.64320777, -5.4809168],
                            [39.64323225, -5.48100963],
                            [39.6432665, -5.48111711],
                            [39.64330071, -5.48118549],
                            [39.64336896, -5.48116099],
                            [39.6434518, -5.48110714],
                            [39.64351519, -5.48110218],
                            [39.64354456, -5.48120478],
                            [39.64352524, -5.48138074],
                            [39.6435595, -5.48149311],
                            [39.64360343, -5.48153705],
                            [39.64364243, -5.48152235],
                            [39.64370092, -5.48149296],
                            [39.64379353, -5.48145376],
                            [39.64386172, -5.48138038],
                            [39.64393487, -5.4813803],
                            [39.64399347, -5.48145355],
                            [39.64407157, -5.48152677],
                            [39.64415944, -5.48161465],
                            [39.64416939, -5.48179546],
                            [39.64420357, -5.48183452],
                            [39.6443352, -5.48180506],
                            [39.64439862, -5.48182454],
                            [39.64441334, -5.48190761],
                            [39.64447191, -5.48195642],
                            [39.64457912, -5.48189277],
                            [39.64465714, -5.48188291],
                            [39.64473034, -5.48193659],
                            [39.64474507, -5.48202943],
                            [39.64471098, -5.48206856],
                            [39.64466714, -5.4821126],
                            [39.64463308, -5.48218594],
                            [39.64467703, -5.48223965],
                            [39.64472099, -5.48231291],
                            [39.64474541, -5.4823471],
                            [39.64474551, -5.48243996],
                            [39.64469203, -5.48258663],
                            [39.64478478, -5.4826745],
                            [39.64487254, -5.48265974],
                            [39.64497496, -5.48267429],
                            [39.64501406, -5.48275245],
                            [39.64511162, -5.48278167],
                            [39.64520917, -5.48280111],
                            [39.64525309, -5.48283039],
                            [39.64521898, -5.48285486],
                            [39.64520441, -5.48290863],
                            [39.64522398, -5.48296726],
                            [39.6452874, -5.48298674],
                            [39.64539475, -5.48305016],
                            [39.64547281, -5.4830794],
                            [39.64551176, -5.4830256],
                            [39.64555564, -5.48302066],
                            [39.64562401, -5.48310856],
                            [39.64569714, -5.48309382],
                            [39.64573126, -5.48307912],
                            [39.64576058, -5.48313773],
                            [39.64579968, -5.48321589],
                            [39.64586306, -5.48320116],
                            [39.64590693, -5.48318645],
                            [39.64601424, -5.48320588],
                            [39.64602892, -5.48324985],
                            [39.64609733, -5.48338173],
                            [39.64608273, -5.48341107],
                            [39.64602913, -5.48345023],
                            [39.64605362, -5.48355283],
                            [39.64608298, -5.48364566],
                            [39.64615621, -5.48371888],
                            [39.64621956, -5.48367483],
                            [39.64626824, -5.48360147],
                            [39.64634134, -5.48355252],
                            [39.64643397, -5.48352798],
                            [39.64649256, -5.48359145],
                            [39.64650727, -5.48367452],
                            [39.64649274, -5.48376251],
                            [39.64644399, -5.48378211],
                            [39.6463855, -5.48380172],
                            [39.64629288, -5.48383603],
                            [39.64622955, -5.48389475],
                            [39.64625891, -5.48399246],
                            [39.64636625, -5.4840461],
                            [39.64650282, -5.48407039],
                            [39.64661993, -5.4841338],
                            [39.64669314, -5.48418748],
                            [39.64680537, -5.48426066],
                            [39.64705903, -5.48432881],
                            [39.64727852, -5.48437256],
                            [39.64737122, -5.48441644],
                            [39.6474736, -5.48438701],
                            [39.64754187, -5.48438694],
                            [39.64768822, -5.48443565],
                            [39.64785409, -5.484499],
                            [39.64794681, -5.48455755],
                            [39.64808826, -5.48458672],
                            [39.64818583, -5.48462083],
                            [39.64833222, -5.48470864],
                            [39.64844444, -5.48475739],
                            [39.64850779, -5.48472311],
                            [39.64848822, -5.48466448],
                            [39.6485321, -5.48465466],
                            [39.64861992, -5.48469366],
                            [39.64867364, -5.48476691],
                            [39.64880048, -5.48481076],
                            [39.64894187, -5.48478128],
                            [39.64900039, -5.48478122],
                            [39.6490784, -5.48477136],
                            [39.64921983, -5.48478098],
                            [39.64926858, -5.48476138],
                            [39.64935155, -5.48482971],
                            [39.64940517, -5.48480033],
                            [39.64951732, -5.48479043],
                            [39.64959534, -5.48479035],
                            [39.64958553, -5.48474149],
                            [39.64958056, -5.48464864],
                            [39.6495463, -5.48453138],
                            [39.64950231, -5.48444346],
                            [39.64948751, -5.4842822],
                            [39.64949221, -5.4841258],
                            [39.64944819, -5.48399878],
                            [39.64938473, -5.48393532],
                            [39.64929695, -5.4839403],
                            [39.64915073, -5.48400888],
                            [39.6491263, -5.48396492],
                            [39.6491164, -5.48383298],
                            [39.64911627, -5.48370591],
                            [39.64902331, -5.48342744],
                            [39.6489841, -5.48324665],
                            [39.64895957, -5.48310495],
                            [39.64897407, -5.48298276],
                            [39.64895928, -5.48283616],
                            [39.64889086, -5.48269939],
                            [39.64884183, -5.48245508],
                            [39.64878315, -5.48230853],
                            [39.64870565, -5.48199925],
                            [39.64870479, -5.48199583],
                            [39.64855321, -5.48161968],
                            [39.64842615, -5.48136568],
                            [39.64838194, -5.4810725],
                            [39.64839156, -5.48094542],
                            [39.64836214, -5.48079884],
                            [39.64830833, -5.48063762],
                            [39.64825934, -5.48043241],
                            [39.64822991, -5.48027116],
                            [39.64820531, -5.48007081],
                            [39.64818558, -5.47986068],
                            [39.64813189, -5.47981676],
                            [39.64799047, -5.47981691],
                            [39.64795145, -5.47981206],
                            [39.64792695, -5.47970457],
                            [39.64795596, -5.47946995],
                            [39.64799979, -5.47941615],
                            [39.64810954, -5.47941603],
                            [39.64811683, -5.47941602],
                            [39.64821441, -5.47946479],
                            [39.64827287, -5.47940608],
                            [39.64826789, -5.47931323],
                            [39.64821906, -5.47924974],
                            [39.64813127, -5.47924495],
                            [39.64808813, -5.4792162],
                            [39.64805807, -5.47919616],
                            [39.64803851, -5.47914242],
                            [39.64803347, -5.47899581],
                            [39.64805289, -5.47891108],
                            [39.64805497, -5.47890656],
                            [39.64809181, -5.47882632],
                            [39.64809811, -5.47863735],
                            [39.64807188, -5.47843537],
                            [39.64805863, -5.47820731],
                            [39.64801301, -5.47810962],
                            [39.64795421, -5.47784251],
                            [39.64801261, -5.47773167],
                            [39.64809697, -5.47758171],
                            [39.64809685, -5.47746442],
                            [39.6480187, -5.47734721],
                            [39.64799244, -5.47711917],
                            [39.6479922, -5.47689761],
                            [39.6480505, -5.47668903],
                            [39.64809581, -5.47650001],
                            [39.64816043, -5.47612851],
                            [39.6481667, -5.47591347],
                            [39.64814048, -5.47571801],
                            [39.64804277, -5.47554869],
                            [39.64804253, -5.47532713],
                            [39.64806175, -5.47505995],
                            [39.64802905, -5.47488404],
                            [39.6480223, -5.47464946],
                            [39.64798293, -5.47431717],
                            [39.64801532, -5.47421288],
                            [39.64799551, -5.47392618],
                            [39.64804085, -5.47376323],
                            [39.64804718, -5.47360031],
                            [39.64804034, -5.47329406],
                            [39.64804659, -5.47305295],
                            [39.6481699, -5.47284429],
                            [39.6483321, -5.47252482],
                            [39.64844874, -5.47215327],
                            [39.64845507, -5.47199035],
                            [39.64854589, -5.47180128],
                            [39.64862374, -5.47163829],
                            [39.64865607, -5.47146883],
                            [39.64865581, -5.47123425],
                            [39.64871416, -5.47107128],
                            [39.648818, -5.47089522],
                            [39.6488373, -5.47070623],
                        ],
                        [
                            [39.65744172, -5.45025106],
                            [39.65759222, -5.450471],
                            [39.65771947, -5.45056353],
                            [39.6577659, -5.45074884],
                            [39.65790483, -5.45095721],
                            [39.65793976, -5.45118886],
                            [39.65792834, -5.4513163],
                            [39.65784751, -5.45138589],
                            [39.65789408, -5.45169862],
                            [39.65806779, -5.45199963],
                            [39.65821824, -5.45217323],
                            [39.65841483, -5.4522657],
                            [39.65838023, -5.45233524],
                            [39.65817209, -5.45225438],
                            [39.6580334, -5.45226611],
                            [39.65785996, -5.45220838],
                            [39.65767491, -5.45210432],
                            [39.65743214, -5.45206983],
                            [39.65718936, -5.45202376],
                            [39.65705069, -5.45205866],
                            [39.6570972, -5.45231347],
                            [39.65703952, -5.45241779],
                            [39.6569123, -5.45234843],
                            [39.65678502, -5.45222114],
                            [39.65668073, -5.45197798],
                            [39.65658803, -5.45176956],
                            [39.65647216, -5.45150324],
                            [39.65647193, -5.45129472],
                            [39.6564139, -5.45107468],
                            [39.65617091, -5.45083167],
                            [39.65591664, -5.45085511],
                            [39.6559051, -5.45086671],
                            [39.65575489, -5.45091321],
                            [39.65565105, -5.45108709],
                            [39.65553571, -5.45131891],
                            [39.65544333, -5.4514001],
                            [39.65545508, -5.45157385],
                            [39.65545534, -5.45181713],
                            [39.65542089, -5.45202568],
                            [39.65537248, -5.4521901],
                            [39.65532876, -5.45233857],
                            [39.65534054, -5.45254707],
                            [39.65542188, -5.45294086],
                            [39.65550301, -5.45313771],
                            [39.65563014, -5.45312598],
                            [39.65572232, -5.45285944],
                            [39.65582632, -5.45283616],
                            [39.65590771, -5.45327628],
                            [39.65590774, -5.45330547],
                            [39.65590786, -5.45341257],
                            [39.65590816, -5.45369332],
                            [39.65598925, -5.45385542],
                            [39.65619723, -5.45378568],
                            [39.65630141, -5.45392458],
                            [39.65630176, -5.45424895],
                            [39.6563599, -5.45457325],
                            [39.65659157, -5.45502479],
                            [39.65675388, -5.45547641],
                            [39.65686974, -5.45573114],
                            [39.65720523, -5.45599722],
                            [39.65750592, -5.45614749],
                            [39.65784119, -5.45620505],
                            [39.65789883, -5.45606597],
                            [39.65804912, -5.45608897],
                            [39.65833817, -5.45616975],
                            [39.65837311, -5.45641299],
                            [39.65850016, -5.45632017],
                            [39.65865052, -5.45641268],
                            [39.65892801, -5.45648189],
                            [39.65896281, -5.45659769],
                            [39.65884737, -5.45673683],
                            [39.65866232, -5.45663278],
                            [39.65854694, -5.45681825],
                            [39.65872052, -5.45700342],
                            [39.65902123, -5.45717685],
                            [39.65929859, -5.45713021],
                            [39.6594374, -5.45722274],
                            [39.65942613, -5.45748919],
                            [39.65955368, -5.45785975],
                            [39.6598201, -5.45838076],
                            [39.66007448, -5.45846158],
                            [39.66010926, -5.45855421],
                            [39.66007482, -5.45877436],
                            [39.66013282, -5.45895965],
                            [39.6603758, -5.45919107],
                            [39.66052657, -5.45965428],
                            [39.66074643, -5.45987415],
                            [39.66090838, -5.45998981],
                            [39.66120873, -5.4598273],
                            [39.661463, -5.45980385],
                            [39.6616134, -5.45993112],
                            [39.66183345, -5.46032475],
                            [39.66199538, -5.46042883],
                            [39.66213447, -5.46077621],
                            [39.66259771, -5.46158661],
                            [39.66297975, -5.46213066],
                            [39.66322294, -5.46254743],
                            [39.66346597, -5.46281361],
                            [39.66369719, -5.46285969],
                            [39.66388208, -5.46281315],
                            [39.66406707, -5.46285928],
                            [39.66426371, -5.4629865],
                            [39.66458772, -5.4633105],
                            [39.66477284, -5.46347248],
                            [39.66491167, -5.46358817],
                            [39.66478473, -5.46377366],
                            [39.66487749, -5.46404],
                            [39.66502798, -5.46424836],
                            [39.66510936, -5.46466531],
                            [39.6652831, -5.46498948],
                            [39.66539893, -5.46520946],
                            [39.66549204, -5.46578858],
                            [39.66565424, -5.46613593],
                            [39.66576995, -5.46624007],
                            [39.66604758, -5.46643669],
                            [39.66618641, -5.46655238],
                            [39.66630206, -5.46661018],
                            [39.6663599, -5.46664487],
                            [39.6664755, -5.46665632],
                            [39.66657953, -5.46665621],
                            [39.66662576, -5.46665616],
                            [39.6668224, -5.46678337],
                            [39.66697261, -5.46672528],
                            [39.66704211, -5.46686422],
                            [39.66718102, -5.46704941],
                            [39.66730807, -5.4669566],
                            [39.66740049, -5.46691016],
                            [39.66765491, -5.46702572],
                            [39.66782831, -5.46703711],
                            [39.66792059, -5.46686324],
                            [39.6679203, -5.46660838],
                            [39.66765423, -5.46641174],
                            [39.66748082, -5.46638877],
                            [39.66733044, -5.46628467],
                            [39.66715705, -5.46627328],
                            [39.66705276, -5.46604171],
                            [39.66709891, -5.46596056],
                            [39.66727223, -5.46590245],
                            [39.66724888, -5.46569395],
                            [39.66720248, -5.46554341],
                            [39.66697121, -5.46546257],
                            [39.66690167, -5.46528888],
                            [39.66695924, -5.46509188],
                            [39.66698311, -5.46502947],
                            [39.66701687, -5.46494122],
                            [39.66690118, -5.46484868],
                            [39.66688945, -5.46469809],
                            [39.6669586, -5.46451266],
                            [39.66693517, -5.46422308],
                            [39.66695601, -5.46419586],
                            [39.66705059, -5.46407235],
                            [39.66707336, -5.46375954],
                            [39.66714191, -5.46302965],
                            [39.66717613, -5.46262415],
                            [39.66725669, -5.4622997],
                            [39.66725647, -5.46210276],
                            [39.66722158, -5.46190587],
                            [39.66694393, -5.46169765],
                            [39.66675897, -5.46167469],
                            [39.66652527, -5.46159691],
                            [39.66651615, -5.46159387],
                            [39.66640058, -5.46161716],
                            [39.66616934, -5.4615595],
                            [39.66601908, -5.46155966],
                            [39.66588044, -5.46161774],
                            [39.66575336, -5.4616758],
                            [39.66564938, -5.46172226],
                            [39.66547607, -5.46179195],
                            [39.6653143, -5.46183847],
                            [39.66532559, -5.46159519],
                            [39.66544109, -5.46151397],
                            [39.66570696, -5.46152526],
                            [39.66578774, -5.46140932],
                            [39.66582231, -5.46131661],
                            [39.66596103, -5.46132804],
                            [39.66618069, -5.46136255],
                            [39.66634246, -5.46131603],
                            [39.66652732, -5.46124632],
                            [39.66655124, -5.46124629],
                            [39.66674694, -5.46124608],
                            [39.66687402, -5.46118801],
                            [39.66678138, -5.46103752],
                            [39.66657918, -5.46086902],
                            [39.66657313, -5.46086398],
                            [39.66633028, -5.46075999],
                            [39.66619166, -5.46082965],
                            [39.66602976, -5.46076032],
                            [39.66602959, -5.46060973],
                            [39.66623737, -5.46035464],
                            [39.66645698, -5.4603544],
                            [39.66662138, -5.46029927],
                            [39.6666303, -5.46029628],
                            [39.66663017, -5.46018047],
                            [39.66663007, -5.46008776],
                            [39.66662912, -5.46006737],
                            [39.66661823, -5.45983292],
                            [39.66661799, -5.45961281],
                            [39.66657154, -5.45941593],
                            [39.66660603, -5.45925371],
                            [39.66650188, -5.45913798],
                            [39.66634007, -5.45914974],
                            [39.66616401, -5.45922462],
                            [39.66613616, -5.45923647],
                            [39.66603968, -5.4592775],
                            [39.66568136, -5.4592779],
                            [39.66563648, -5.45923647],
                            [39.66553095, -5.45913905],
                            [39.66540348, -5.45884958],
                            [39.66526456, -5.4586528],
                            [39.66498692, -5.45844458],
                            [39.66510239, -5.4583402],
                            [39.665426, -5.45830509],
                            [39.66541422, -5.45810816],
                            [39.66526385, -5.45800407],
                            [39.6650441, -5.45788847],
                            [39.66488208, -5.45770329],
                            [39.66489339, -5.45748318],
                            [39.66462731, -5.45727495],
                            [39.66446538, -5.45717087],
                            [39.66439581, -5.45697401],
                            [39.66438382, -5.45691723],
                            [39.66434933, -5.45675396],
                            [39.66430701, -5.45667703],
                            [39.66422193, -5.45652241],
                            [39.66387496, -5.45632585],
                            [39.66369011, -5.45640715],
                            [39.66347066, -5.45655799],
                            [39.66332045, -5.45660449],
                            [39.66317006, -5.45648881],
                            [39.66308885, -5.45622246],
                            [39.6631003, -5.45611819],
                            [39.6633313, -5.45596733],
                            [39.66352773, -5.45589761],
                            [39.66343509, -5.45574712],
                            [39.66328449, -5.4554345],
                            [39.66303012, -5.45536527],
                            [39.66287993, -5.45543495],
                            [39.66276435, -5.45543507],
                            [39.66277571, -5.45526129],
                            [39.66265986, -5.45501815],
                            [39.66225489, -5.4546363],
                            [39.66202369, -5.45461339],
                            [39.6619195, -5.45446291],
                            [39.66182669, -5.45415023],
                            [39.66169926, -5.45389551],
                            [39.66136405, -5.45388429],
                            [39.66130647, -5.45408129],
                            [39.66126039, -5.45422036],
                            [39.66119104, -5.45422043],
                            [39.66114461, -5.45404672],
                            [39.66113287, -5.45387296],
                            [39.66095937, -5.45376889],
                            [39.66063564, -5.45368816],
                            [39.66034651, -5.45353787],
                            [39.66010355, -5.45332962],
                            [39.66005736, -5.45336442],
                            [39.65991873, -5.45343408],
                            [39.65986081, -5.4533183],
                            [39.65971038, -5.45316787],
                            [39.65962927, -5.4529826],
                            [39.65953659, -5.45278577],
                            [39.65932847, -5.45272808],
                            [39.65922448, -5.45276294],
                            [39.65905109, -5.45275155],
                            [39.65900475, -5.45265892],
                            [39.6590046, -5.45251991],
                            [39.65922421, -5.45251967],
                            [39.65942076, -5.45256579],
                            [39.659571, -5.45254246],
                            [39.65970954, -5.45239171],
                            [39.65970928, -5.45216002],
                            [39.65954729, -5.45199801],
                            [39.65925813, -5.45182456],
                            [39.65900381, -5.45179009],
                            [39.6587612, -5.4519062],
                            [39.65865695, -5.45169779],
                            [39.65859893, -5.45148933],
                            [39.65850617, -5.45122299],
                            [39.65859847, -5.45107229],
                            [39.65870249, -5.45106059],
                            [39.65876038, -5.45115321],
                            [39.65900333, -5.45134988],
                            [39.65923462, -5.45146547],
                            [39.65970874, -5.45166189],
                            [39.65997475, -5.45181219],
                            [39.66028694, -5.45190453],
                            [39.6604717, -5.45174214],
                            [39.6605988, -5.45170725],
                            [39.66080711, -5.45193871],
                            [39.6609691, -5.45208913],
                            [39.66106952, -5.45208902],
                            [39.6611078, -5.45208898],
                            [39.66116545, -5.45196149],
                            [39.66115367, -5.45175298],
                            [39.66113014, -5.4513823],
                            [39.66114158, -5.45126644],
                            [39.66097958, -5.45110444],
                            [39.66073698, -5.45122055],
                            [39.66072438, -5.45122688],
                            [39.66048283, -5.45134826],
                            [39.66035556, -5.45123255],
                            [39.66015872, -5.45091999],
                            [39.65991585, -5.45079282],
                            [39.65975407, -5.45082775],
                            [39.65964994, -5.45073519],
                            [39.65962653, -5.45046877],
                            [39.65948751, -5.45017931],
                            [39.65934852, -5.44991302],
                            [39.65917499, -5.4497742],
                            [39.65895523, -5.44963543],
                            [39.65874716, -5.44962407],
                            [39.65846975, -5.44962437],
                            [39.65819227, -5.44955517],
                            [39.65804199, -5.44953216],
                            [39.6578224, -5.44955557],
                            [39.65763745, -5.44954419],
                            [39.65747577, -5.44967179],
                            [39.65742963, -5.44976452],
                            [39.65740688, -5.4501005],
                            [39.65744172, -5.45025106],
                        ],
                        [
                            [39.56840742, -5.40689732],
                            [39.56841704, -5.40678522],
                            [39.56841689, -5.4066244],
                            [39.56839814, -5.40649772],
                            [39.56841103, -5.40641648],
                            [39.56850484, -5.40622146],
                            [39.5685372, -5.40615645],
                            [39.56860829, -5.40592897],
                            [39.56860171, -5.40581851],
                            [39.56864376, -5.4057275],
                            [39.56868579, -5.40560725],
                            [39.56873105, -5.40548375],
                            [39.56875693, -5.40542525],
                            [39.56875683, -5.40532129],
                            [39.56877298, -5.40526279],
                            [39.5688248, -5.40521726],
                            [39.5689059, -5.40527891],
                            [39.56892867, -5.40536661],
                            [39.56898711, -5.40547702],
                            [39.56903582, -5.40556469],
                            [39.56909745, -5.40561337],
                            [39.5691332, -5.40572055],
                            [39.56915275, -5.40582124],
                            [39.5691982, -5.40589917],
                            [39.56928895, -5.40588934],
                            [39.56937645, -5.40587951],
                            [39.5694122, -5.4059737],
                            [39.56945441, -5.40605163],
                            [39.56952252, -5.4061003],
                            [39.56957115, -5.40611],
                            [39.56964895, -5.40611967],
                            [39.56976247, -5.40619429],
                            [39.56979494, -5.40624949],
                            [39.5697464, -5.40634051],
                            [39.56978537, -5.40641844],
                            [39.56988267, -5.40648658],
                            [39.57000907, -5.40646372],
                            [39.57003167, -5.40637598],
                            [39.57002838, -5.4063175],
                            [39.57018388, -5.40622639],
                            [39.57027461, -5.40619381],
                            [39.57033942, -5.40617751],
                            [39.57038799, -5.40612873],
                            [39.57047873, -5.40610915],
                            [39.57060191, -5.40610903],
                            [39.57068624, -5.40617068],
                            [39.57075757, -5.40619336],
                            [39.57076077, -5.40614787],
                            [39.57076723, -5.40612187],
                            [39.57083532, -5.4061478],
                            [39.5708743, -5.40623224],
                            [39.57104294, -5.40632629],
                            [39.57120185, -5.40641711],
                            [39.57125699, -5.40645605],
                            [39.57134452, -5.40646896],
                            [39.57141913, -5.40653712],
                            [39.57147108, -5.40662479],
                            [39.57156188, -5.40666694],
                            [39.57161698, -5.40667014],
                            [39.57167853, -5.40662459],
                            [39.57173038, -5.40661805],
                            [39.57169806, -5.40671229],
                            [39.57170462, -5.40679676],
                            [39.57176947, -5.40682269],
                            [39.57184727, -5.40682587],
                            [39.5719445, -5.40682252],
                            [39.57203523, -5.4067867],
                            [39.57208057, -5.40674442],
                            [39.57211941, -5.40668916],
                            [39.57214536, -5.40670213],
                            [39.57220696, -5.40672481],
                            [39.57218746, -5.40666635],
                            [39.57224578, -5.4066403],
                            [39.57231057, -5.40659476],
                            [39.57233321, -5.40654276],
                            [39.57230074, -5.40648431],
                            [39.57235908, -5.40647775],
                            [39.57241091, -5.40644522],
                            [39.57244332, -5.40644519],
                            [39.57253735, -5.40648083],
                            [39.57257299, -5.40645806],
                            [39.57256967, -5.40637359],
                            [39.57265389, -5.40631828],
                            [39.57271221, -5.40628899],
                            [39.57273168, -5.40631821],
                            [39.57282239, -5.40625639],
                            [39.57290014, -5.40621408],
                            [39.57287085, -5.4060939],
                            [39.57282869, -5.40606795],
                            [39.57274442, -5.40607128],
                            [39.57270232, -5.40611031],
                            [39.57269905, -5.40608107],
                            [39.5726212, -5.40601941],
                            [39.57255316, -5.40604872],
                            [39.57253699, -5.40609097],
                            [39.57251434, -5.40612998],
                            [39.57247866, -5.40611052],
                            [39.57239438, -5.4060976],
                            [39.572349, -5.40610089],
                            [39.57231656, -5.40607493],
                            [39.5723522, -5.40605541],
                            [39.57236192, -5.4060489],
                            [39.57238133, -5.40601314],
                            [39.57237155, -5.40595142],
                            [39.57232937, -5.40591248],
                            [39.57217701, -5.40589313],
                            [39.57214465, -5.40594514],
                            [39.57215119, -5.40601011],
                            [39.57208633, -5.40597768],
                            [39.57203441, -5.405916],
                            [39.57201492, -5.40587379],
                            [39.57194684, -5.40585436],
                            [39.57184632, -5.40581871],
                            [39.57182681, -5.40574726],
                            [39.57177489, -5.40568558],
                            [39.57172952, -5.40569862],
                            [39.57163878, -5.40571819],
                            [39.57159334, -5.40565326],
                            [39.57165487, -5.40559472],
                            [39.57161592, -5.40553303],
                            [39.57156075, -5.40546161],
                            [39.57148619, -5.40545193],
                            [39.5714084, -5.40546175],
                            [39.57130144, -5.40546835],
                            [39.5712852, -5.40542938],
                            [39.57113275, -5.40531906],
                            [39.57101918, -5.40518271],
                            [39.57090236, -5.40504312],
                            [39.57081154, -5.40498148],
                            [39.57077259, -5.40491978],
                            [39.57078545, -5.40480931],
                            [39.57093129, -5.40478968],
                            [39.57096566, -5.40472927],
                            [39.57097013, -5.40472142],
                            [39.57089875, -5.40465001],
                            [39.57085982, -5.40461106],
                            [39.57092133, -5.40453628],
                            [39.57105746, -5.40452965],
                            [39.57112227, -5.4045036],
                            [39.57111569, -5.40439964],
                            [39.57107347, -5.40431196],
                            [39.57101185, -5.40427628],
                            [39.57092112, -5.4043121],
                            [39.57080445, -5.40432521],
                            [39.57070721, -5.40432855],
                            [39.57057428, -5.40429619],
                            [39.57051267, -5.404267],
                            [39.57051263, -5.40422152],
                            [39.57044125, -5.40415011],
                            [39.57047034, -5.40406236],
                            [39.57060964, -5.40398101],
                            [39.57055127, -5.40394858],
                            [39.57046374, -5.40393891],
                            [39.57045391, -5.40382846],
                            [39.57040202, -5.40379602],
                            [39.57032099, -5.40379285],
                            [39.57026583, -5.40373442],
                            [39.57026573, -5.4036337],
                            [39.57030783, -5.40359143],
                            [39.5703434, -5.40349393],
                            [39.57030764, -5.40339],
                            [39.57029462, -5.40332503],
                            [39.57018109, -5.40323417],
                            [39.57018536, -5.40321193],
                            [39.57020044, -5.40313344],
                            [39.57026852, -5.40314312],
                            [39.57030093, -5.40313984],
                            [39.57033977, -5.40307808],
                            [39.57043379, -5.40310073],
                            [39.57055697, -5.40311036],
                            [39.57060231, -5.40307458],
                            [39.57060874, -5.4030096],
                            [39.57060541, -5.40292513],
                            [39.57056967, -5.40283095],
                            [39.57059234, -5.40280818],
                            [39.5706896, -5.40282758],
                            [39.57079007, -5.40281774],
                            [39.57091647, -5.40280138],
                            [39.57103632, -5.40272005],
                            [39.57106218, -5.4026388],
                            [39.57110429, -5.40260952],
                            [39.57124366, -5.40260289],
                            [39.57129548, -5.40255736],
                            [39.57130513, -5.40248263],
                            [39.57130183, -5.4024209],
                            [39.57136015, -5.40239161],
                            [39.57136657, -5.40232337],
                            [39.57127252, -5.40226823],
                            [39.57125303, -5.40222601],
                            [39.57124324, -5.4021578],
                            [39.57120103, -5.40207336],
                            [39.57120419, -5.40199214],
                            [39.57127551, -5.40199857],
                            [39.57137273, -5.40197899],
                            [39.57140502, -5.40184575],
                            [39.5713887, -5.40172881],
                            [39.57131734, -5.40167039],
                            [39.57124274, -5.40162173],
                            [39.5713042, -5.40147872],
                            [39.57137541, -5.40137469],
                            [39.57147583, -5.40131287],
                            [39.57146279, -5.40122841],
                            [39.57138942, -5.40113913],
                            [39.57117089, -5.40115759],
                            [39.57098884, -5.40123078],
                            [39.57078842, -5.4011397],
                            [39.57091575, -5.40097529],
                            [39.57102486, -5.40079264],
                            [39.57095175, -5.4005189],
                            [39.57076951, -5.40039129],
                            [39.57073829, -5.40032884],
                            [39.57069653, -5.40024532],
                            [39.57060521, -5.39997159],
                            [39.57040473, -5.39980749],
                            [39.57036796, -5.39944244],
                            [39.57015426, -5.39924391],
                            [39.57015015, -5.39924009],
                            [39.57011278, -5.39920537],
                            [39.56989405, -5.39900478],
                            [39.56965738, -5.39909627],
                            [39.56956615, -5.39891381],
                            [39.56920175, -5.39874987],
                            [39.56881913, -5.39856768],
                            [39.56894657, -5.3985128],
                            [39.56900091, -5.39820242],
                            [39.5686185, -5.39823929],
                            [39.56843638, -5.39823946],
                            [39.56818128, -5.39809366],
                            [39.56798071, -5.39783829],
                            [39.56778014, -5.39758292],
                            [39.56765272, -5.39765605],
                            [39.56745243, -5.39769275],
                            [39.56714286, -5.39772955],
                            [39.56701515, -5.39749236],
                            [39.56705138, -5.39727327],
                            [39.56725172, -5.39729134],
                            [39.56743382, -5.39727292],
                            [39.56761579, -5.39710846],
                            [39.56770683, -5.39709012],
                            [39.56752443, -5.39677996],
                            [39.56740834, -5.39678007],
                            [39.56737873, -5.3967801],
                            [39.5672323, -5.39682428],
                            [39.5670146, -5.39688997],
                            [39.5667961, -5.39694493],
                            [39.56659564, -5.39679908],
                            [39.56634038, -5.396489],
                            [39.56612189, -5.39654396],
                            [39.56603073, -5.39643452],
                            [39.56573916, -5.396234],
                            [39.56539293, -5.39601527],
                            [39.56508333, -5.39601555],
                            [39.56471914, -5.39607065],
                            [39.56453719, -5.39625337],
                            [39.56433723, -5.39665515],
                            [39.56426465, -5.39694728],
                            [39.56424656, -5.39707508],
                            [39.56419184, -5.39698386],
                            [39.56404604, -5.39687447],
                            [39.56390023, -5.39674682],
                            [39.56370013, -5.39700257],
                            [39.56357294, -5.39731301],
                            [39.56342756, -5.39765998],
                            [39.56328202, -5.3978244],
                            [39.56328222, -5.39804345],
                            [39.56326426, -5.39831729],
                            [39.56315531, -5.39866422],
                            [39.56293713, -5.39906602],
                            [39.56271891, -5.39941305],
                            [39.56257072, -5.39958892],
                            [39.5625188, -5.39965054],
                            [39.56228214, -5.39976029],
                            [39.56184499, -5.39968768],
                            [39.56183581, -5.39971528],
                            [39.56182855, -5.39975549],
                            [39.5618213, -5.39980118],
                            [39.5618651, -5.39985231],
                            [39.56191623, -5.39993816],
                            [39.56200201, -5.40002397],
                            [39.56205128, -5.4000751],
                            [39.56218629, -5.40016635],
                            [39.56221733, -5.4002248],
                            [39.56227386, -5.40023205],
                            [39.56231037, -5.40027954],
                            [39.56236331, -5.40035259],
                            [39.56235427, -5.40042752],
                            [39.56241992, -5.40044756],
                            [39.5624327, -5.40046217],
                            [39.56246742, -5.4005462],
                            [39.56252213, -5.40056808],
                            [39.56252948, -5.40062473],
                            [39.56257144, -5.4006521],
                            [39.56261524, -5.40069958],
                            [39.56267552, -5.40081465],
                            [39.56272299, -5.40088771],
                            [39.56274672, -5.40092058],
                            [39.56274495, -5.40098089],
                            [39.56277232, -5.40099731],
                            [39.56284527, -5.40101735],
                            [39.56284532, -5.40107766],
                            [39.56289281, -5.40116899],
                            [39.56296401, -5.40127492],
                            [39.56301143, -5.40128584],
                            [39.56304064, -5.40132967],
                            [39.56310091, -5.40143926],
                            [39.56315203, -5.40150866],
                            [39.56323229, -5.40155428],
                            [39.56327061, -5.40158713],
                            [39.56327979, -5.40165657],
                            [39.56327802, -5.4017114],
                            [39.56341852, -5.40183554],
                            [39.56347515, -5.40194696],
                            [39.56349164, -5.40204198],
                            [39.56367419, -5.40227573],
                            [39.56386578, -5.40244185],
                            [39.56447192, -5.40332762],
                            [39.56467459, -5.40363446],
                            [39.56480417, -5.40377322],
                            [39.56493921, -5.40390102],
                            [39.56501957, -5.4040508],
                            [39.56508895, -5.40415491],
                            [39.56512, -5.4042097],
                            [39.5650891, -5.40431755],
                            [39.56513288, -5.40433396],
                            [39.56518395, -5.40436133],
                            [39.56520591, -5.40444171],
                            [39.56525157, -5.40452756],
                            [39.56530265, -5.40456407],
                            [39.56538844, -5.40466815],
                            [39.56540855, -5.40472479],
                            [39.56545236, -5.40477957],
                            [39.56545605, -5.4048216],
                            [39.56554367, -5.404933],
                            [39.56566774, -5.40503157],
                            [39.56574078, -5.40515394],
                            [39.56591059, -5.40541511],
                            [39.5661114, -5.40568844],
                            [39.56625013, -5.40585888],
                            [39.56631588, -5.40597821],
                            [39.56637916, -5.40605857],
                            [39.56643022, -5.4060707],
                            [39.56650076, -5.40611206],
                            [39.56659315, -5.40612172],
                            [39.56664173, -5.40607781],
                            [39.56664412, -5.40602908],
                            [39.56669518, -5.40604609],
                            [39.56674142, -5.40609478],
                            [39.56676824, -5.40618004],
                            [39.56689964, -5.40631393],
                            [39.56701877, -5.40632844],
                            [39.56707465, -5.40628453],
                            [39.56713779, -5.40622111],
                            [39.56720103, -5.4062503],
                            [39.5673495, -5.40644753],
                            [39.56748341, -5.40666426],
                            [39.56759533, -5.40676406],
                            [39.56760026, -5.40683959],
                            [39.56762954, -5.40695409],
                            [39.56777797, -5.40709528],
                            [39.56783391, -5.40712446],
                            [39.56786798, -5.40716586],
                            [39.56785832, -5.40723166],
                            [39.56804822, -5.4075385],
                            [39.56810419, -5.40758961],
                            [39.56816984, -5.40760661],
                            [39.56822088, -5.40758951],
                            [39.56825974, -5.40755779],
                            [39.56827426, -5.40748468],
                            [39.56823772, -5.4074043],
                            [39.5682328, -5.40733608],
                            [39.56817924, -5.40725328],
                            [39.56816693, -5.40709248],
                            [39.56816684, -5.40699745],
                            [39.56821785, -5.40695354],
                            [39.56828105, -5.40694373],
                            [39.5683637, -5.40693635],
                            [39.56840742, -5.40689732],
                        ],
                        [
                            [39.62723407, -5.38703326],
                            [39.62728221, -5.3870814],
                            [39.62738685, -5.38709815],
                            [39.62748313, -5.38709605],
                            [39.62758149, -5.38703745],
                            [39.62762126, -5.38701861],
                            [39.62770079, -5.38698512],
                            [39.62778241, -5.38692023],
                            [39.62779915, -5.38687628],
                            [39.62780334, -5.38680302],
                            [39.62784729, -5.38678627],
                            [39.62792891, -5.38676743],
                            [39.6279917, -5.3866858],
                            [39.62804611, -5.3866565],
                            [39.6280796, -5.38663766],
                            [39.62811518, -5.38659998],
                            [39.62815913, -5.38657905],
                            [39.62817378, -5.38649742],
                            [39.62811099, -5.38648486],
                            [39.62810053, -5.38646393],
                            [39.62814238, -5.3864137],
                            [39.62819889, -5.38639904],
                            [39.62824075, -5.38643253],
                            [39.62829935, -5.38642835],
                            [39.62835586, -5.3863823],
                            [39.62842283, -5.38632369],
                            [39.62842702, -5.38626299],
                            [39.62844376, -5.3861688],
                            [39.62836423, -5.38606833],
                            [39.62831191, -5.38600972],
                            [39.62825122, -5.38591135],
                            [39.62823238, -5.38582972],
                            [39.62826796, -5.3858046],
                            [39.62830354, -5.38582134],
                            [39.62839981, -5.3858967],
                            [39.62848771, -5.3859553],
                            [39.62858817, -5.38601391],
                            [39.62864886, -5.38600973],
                            [39.6287263, -5.3859867],
                            [39.62879955, -5.38591972],
                            [39.62882885, -5.38585902],
                            [39.62885815, -5.38581507],
                            [39.62889373, -5.38575646],
                            [39.6289042, -5.38572925],
                            [39.62891257, -5.38570204],
                            [39.62891466, -5.38566436],
                            [39.62897745, -5.38566436],
                            [39.62900047, -5.38566436],
                            [39.62903186, -5.38566227],
                            [39.6290486, -5.38566436],
                            [39.62907581, -5.38562041],
                            [39.62915116, -5.38556808],
                            [39.62921813, -5.38550528],
                            [39.6292265, -5.38548226],
                            [39.62924115, -5.38546551],
                            [39.6292851, -5.38542575],
                            [39.62925162, -5.38533574],
                            [39.62925371, -5.38530853],
                            [39.62927882, -5.38531272],
                            [39.62932277, -5.38528969],
                            [39.62935626, -5.38527295],
                            [39.62938556, -5.38520178],
                            [39.62931649, -5.38514108],
                            [39.62936463, -5.38506782],
                            [39.6294316, -5.38507201],
                            [39.62947765, -5.38503224],
                            [39.62946718, -5.38491921],
                            [39.62949858, -5.38486688],
                            [39.62953206, -5.38480828],
                            [39.62956346, -5.38473292],
                            [39.6295802, -5.3846178],
                            [39.62957392, -5.3844964],
                            [39.62956136, -5.38437919],
                            [39.62958439, -5.384375],
                            [39.62963671, -5.38439174],
                            [39.62972252, -5.38435198],
                            [39.62985018, -5.38422639],
                            [39.62992343, -5.38414894],
                            [39.63003017, -5.38405057],
                            [39.63005319, -5.38395638],
                            [39.63005319, -5.38384753],
                            [39.63011389, -5.38385381],
                            [39.63015365, -5.3838287],
                            [39.6301683, -5.38368636],
                            [39.63019342, -5.38360473],
                            [39.63030434, -5.38347077],
                            [39.63036922, -5.38338914],
                            [39.63046131, -5.38324472],
                            [39.63054502, -5.38316099],
                            [39.63064548, -5.38302912],
                            [39.63059316, -5.38283028],
                            [39.63056386, -5.38277586],
                            [39.63053874, -5.38270469],
                            [39.63054711, -5.38263562],
                            [39.630522, -5.38260841],
                            [39.63056595, -5.38257492],
                            [39.63064967, -5.38252259],
                            [39.63071664, -5.38247863],
                            [39.63073547, -5.38253515],
                            [39.63075116, -5.38255753],
                            [39.63077384, -5.38258347],
                            [39.63079198, -5.38260422],
                            [39.63088825, -5.38262725],
                            [39.63099499, -5.38262725],
                            [39.63111638, -5.38262306],
                            [39.63122312, -5.38259585],
                            [39.63126707, -5.38252259],
                            [39.63136125, -5.38241584],
                            [39.6314638, -5.38230909],
                            [39.63145543, -5.38220444],
                            [39.63141357, -5.38208513],
                            [39.63145333, -5.38188419],
                            [39.63147426, -5.38179209],
                            [39.63147845, -5.38160371],
                            [39.63145334, -5.38147184],
                            [39.63144496, -5.38144882],
                            [39.63140311, -5.38140905],
                            [39.63139683, -5.38138603],
                            [39.63141148, -5.38135882],
                            [39.63144078, -5.38134626],
                            [39.63146799, -5.38131067],
                            [39.63155379, -5.38124997],
                            [39.63157472, -5.38123323],
                            [39.63157891, -5.38122067],
                            [39.63158257, -5.38121334],
                            [39.63158623, -5.38120602],
                            [39.63159356, -5.38119137],
                            [39.63159356, -5.38110346],
                            [39.63158519, -5.38107206],
                            [39.631581, -5.3810302],
                            [39.63153914, -5.38100717],
                            [39.63152868, -5.38088786],
                            [39.63148264, -5.380846],
                            [39.63142404, -5.38084391],
                            [39.63135497, -5.38079995],
                            [39.63130474, -5.38070158],
                            [39.63131102, -5.38067646],
                            [39.63133614, -5.38064506],
                            [39.6313759, -5.38064715],
                            [39.63147845, -5.38063878],
                            [39.6315224, -5.38061785],
                            [39.63156426, -5.38056762],
                            [39.63157263, -5.38047971],
                            [39.63154961, -5.38031644],
                            [39.63145752, -5.38023062],
                            [39.63145334, -5.38006317],
                            [39.63141357, -5.38001085],
                            [39.63132358, -5.37999619],
                            [39.63125451, -5.37994805],
                            [39.6312294, -5.37974502],
                            [39.63115615, -5.37954617],
                            [39.63111429, -5.3794478],
                            [39.6310243, -5.37929709],
                            [39.63086315, -5.37919034],
                            [39.63077315, -5.37916313],
                            [39.63075013, -5.37918406],
                            [39.63074385, -5.37925104],
                            [39.63068944, -5.37931802],
                            [39.63058479, -5.37931802],
                            [39.63056522, -5.37932321],
                            [39.63048224, -5.37934523],
                            [39.63048433, -5.37931802],
                            [39.63050735, -5.37921546],
                            [39.63054713, -5.37917254],
                            [39.63058688, -5.37912964],
                            [39.63060572, -5.37899568],
                            [39.63056805, -5.37895382],
                            [39.63051744, -5.37892526],
                            [39.63048643, -5.37890777],
                            [39.63041945, -5.378868],
                            [39.63038388, -5.37887428],
                            [39.63031481, -5.37886172],
                            [39.63018296, -5.37875497],
                            [39.63012645, -5.37866287],
                            [39.63006576, -5.37857915],
                            [39.62996948, -5.37851845],
                            [39.62988786, -5.37841589],
                            [39.62982089, -5.37834053],
                            [39.62975392, -5.37825053],
                            [39.62968276, -5.37820029],
                            [39.62960951, -5.37814587],
                            [39.62962625, -5.37808099],
                            [39.62963672, -5.37797424],
                            [39.62959695, -5.37790726],
                            [39.62950905, -5.37784656],
                            [39.62945882, -5.37781516],
                            [39.62938766, -5.37776283],
                            [39.62933116, -5.37773353],
                            [39.62927256, -5.37769585],
                            [39.6292307, -5.37767492],
                            [39.62917, -5.37766446],
                            [39.62908838, -5.37766236],
                            [39.62903397, -5.37767492],
                            [39.62897746, -5.37772934],
                            [39.62891886, -5.37772934],
                            [39.62890211, -5.37767073],
                            [39.62893142, -5.37763096],
                            [39.62904234, -5.3775912],
                            [39.62913442, -5.37757026],
                            [39.62923488, -5.37755352],
                            [39.62927046, -5.37751166],
                            [39.62924116, -5.37744677],
                            [39.62922651, -5.3773463],
                            [39.62921396, -5.3772856],
                            [39.62918675, -5.3771935],
                            [39.62917529, -5.37715375],
                            [39.62915117, -5.37707001],
                            [39.62906117, -5.37691093],
                            [39.62901932, -5.37679372],
                            [39.62897118, -5.37662836],
                            [39.62887282, -5.37645672],
                            [39.62884142, -5.37631649],
                            [39.62875561, -5.37620346],
                            [39.62865725, -5.37616997],
                            [39.62858609, -5.37616787],
                            [39.62848145, -5.3761595],
                            [39.62843122, -5.37611555],
                            [39.62843959, -5.37607578],
                            [39.62843959, -5.37601926],
                            [39.62842285, -5.37598159],
                            [39.62841866, -5.37596275],
                            [39.62838308, -5.37591461],
                            [39.62833495, -5.3759167],
                            [39.62827216, -5.37596484],
                            [39.62825786, -5.37596316],
                            [39.62823658, -5.37596065],
                            [39.62815914, -5.37595856],
                            [39.6280838, -5.37597321],
                            [39.62801683, -5.37600461],
                            [39.62797915, -5.37597112],
                            [39.62798962, -5.37594182],
                            [39.62801683, -5.3758539],
                            [39.62800008, -5.37572204],
                            [39.62799631, -5.37571252],
                            [39.62795613, -5.3756111],
                            [39.62792265, -5.37556296],
                            [39.62786823, -5.37556505],
                            [39.62784805, -5.37557082],
                            [39.62780963, -5.3755818],
                            [39.62776359, -5.37557343],
                            [39.62776568, -5.3755211],
                            [39.62774057, -5.37547296],
                            [39.62768197, -5.37544993],
                            [39.6276422, -5.37540598],
                            [39.62760453, -5.37539132],
                            [39.62756686, -5.37537667],
                            [39.62751872, -5.37537667],
                            [39.62743501, -5.3753369],
                            [39.6273492, -5.37528876],
                            [39.62729897, -5.37529504],
                            [39.62726758, -5.37533481],
                            [39.62719642, -5.37534318],
                            [39.62716607, -5.37533167],
                            [39.62713572, -5.37532016],
                            [39.62712735, -5.37525946],
                            [39.62710224, -5.3752155],
                            [39.6270541, -5.37524271],
                            [39.6269955, -5.37523643],
                            [39.62698085, -5.37517155],
                            [39.62685528, -5.37508573],
                            [39.62669622, -5.3750334],
                            [39.62655181, -5.37495805],
                            [39.62638647, -5.37492665],
                            [39.62622113, -5.3748513],
                            [39.62617412, -5.37482954],
                            [39.62596789, -5.37473408],
                            [39.62591139, -5.3746692],
                            [39.62579419, -5.37458338],
                            [39.6256435, -5.3744536],
                            [39.62547397, -5.37437406],
                            [39.62537142, -5.37430081],
                            [39.62527954, -5.37424799],
                            [39.62518934, -5.37419615],
                            [39.62507423, -5.37411661],
                            [39.62499052, -5.37403916],
                            [39.62494657, -5.3739994],
                            [39.62489215, -5.37397009],
                            [39.62482518, -5.37395544],
                            [39.62479797, -5.37401195],
                            [39.62470798, -5.37400777],
                            [39.62463264, -5.37398684],
                            [39.62456768, -5.37398342],
                            [39.62455311, -5.37398265],
                            [39.62450288, -5.37394916],
                            [39.62448267, -5.37393079],
                            [39.62445683, -5.3739073],
                            [39.62436893, -5.37385497],
                            [39.62435698, -5.37385298],
                            [39.62429359, -5.37384241],
                            [39.62423917, -5.37385706],
                            [39.62418057, -5.37391148],
                            [39.62416806, -5.37389734],
                            [39.62413244, -5.37385706],
                            [39.62417429, -5.37373147],
                            [39.62405081, -5.3736331],
                            [39.62400686, -5.37361007],
                            [39.62397966, -5.37355565],
                            [39.62396291, -5.37350123],
                            [39.62389175, -5.37345518],
                            [39.62383525, -5.37345518],
                            [39.62376618, -5.37348449],
                            [39.62369502, -5.37352635],
                            [39.62363433, -5.37355984],
                            [39.62358201, -5.37359751],
                            [39.62351922, -5.37367287],
                            [39.62344806, -5.37378799],
                            [39.62342923, -5.3738445],
                            [39.6234376, -5.37390939],
                            [39.62346271, -5.37398056],
                            [39.6234669, -5.37403288],
                            [39.62342295, -5.37408102],
                            [39.62328272, -5.37406637],
                            [39.6232032, -5.37404335],
                            [39.6231425, -5.37400777],
                            [39.62307134, -5.37393451],
                            [39.62309018, -5.37382148],
                            [39.62309855, -5.37373147],
                            [39.62315925, -5.37359333],
                            [39.62315715, -5.37353681],
                            [39.62319901, -5.37351798],
                            [39.62325343, -5.37343216],
                            [39.62324401, -5.3734128],
                            [39.62323459, -5.37339343],
                            [39.62321575, -5.37335471],
                            [39.62315297, -5.37327308],
                            [39.62306716, -5.37325215],
                            [39.62297716, -5.37327727],
                            [39.62290391, -5.37326471],
                            [39.62282438, -5.37319354],
                            [39.62273858, -5.37312447],
                            [39.62265277, -5.37309935],
                            [39.622613, -5.37303865],
                            [39.62256277, -5.37289213],
                            [39.62250836, -5.37286283],
                            [39.62246022, -5.37283352],
                            [39.62245185, -5.37282306],
                            [39.62244557, -5.37278538],
                            [39.62247697, -5.37274352],
                            [39.62247906, -5.37267445],
                            [39.62241837, -5.37263258],
                            [39.62235349, -5.37267026],
                            [39.62228861, -5.37267235],
                            [39.62224047, -5.37260537],
                            [39.62226558, -5.37255095],
                            [39.62221954, -5.3725049],
                            [39.62212118, -5.37246304],
                            [39.62205002, -5.37242327],
                            [39.6219663, -5.37242955],
                            [39.62184282, -5.37236676],
                            [39.6218062, -5.37240025],
                            [39.62180201, -5.37252165],
                            [39.62172039, -5.37256351],
                            [39.6217183, -5.37261793],
                            [39.62170783, -5.37271003],
                            [39.6215697, -5.37276026],
                            [39.62151319, -5.37265351],
                            [39.62155505, -5.37249234],
                            [39.62168062, -5.37239187],
                            [39.6216576, -5.37221187],
                            [39.62159691, -5.37219512],
                            [39.62158435, -5.37214279],
                            [39.6215111, -5.37213442],
                            [39.62145041, -5.3720507],
                            [39.62140227, -5.37199209],
                            [39.62141692, -5.37193139],
                            [39.62133948, -5.37189999],
                            [39.6212453, -5.37191674],
                            [39.62119717, -5.37193976],
                            [39.62114485, -5.37203395],
                            [39.62113647, -5.37214489],
                            [39.62114977, -5.3722358],
                            [39.62106741, -5.37222024],
                            [39.62100881, -5.37218465],
                            [39.62100881, -5.37210093],
                            [39.62102555, -5.37198371],
                            [39.62108415, -5.37187069],
                            [39.62105067, -5.37180371],
                            [39.62102137, -5.37176812],
                            [39.62094602, -5.37174301],
                            [39.62089161, -5.37166975],
                            [39.62081836, -5.3715923],
                            [39.62077859, -5.37152951],
                            [39.62067813, -5.3714709],
                            [39.6205986, -5.3714416],
                            [39.62055884, -5.37143113],
                            [39.62050442, -5.37142694],
                            [39.62044373, -5.3714102],
                            [39.62041427, -5.37135932],
                            [39.62035583, -5.3713181],
                            [39.62030769, -5.37131182],
                            [39.62024281, -5.37132229],
                            [39.62018003, -5.37132229],
                            [39.62010887, -5.37131601],
                            [39.62008166, -5.37127205],
                            [39.62017375, -5.37124275],
                            [39.62014863, -5.37120298],
                            [39.62005445, -5.37118833],
                            [39.6199833, -5.37111925],
                            [39.61991632, -5.37111716],
                            [39.61986609, -5.37102925],
                            [39.61984517, -5.37096855],
                            [39.61980122, -5.37096436],
                            [39.61975099, -5.37098948],
                            [39.6196882, -5.3710125],
                            [39.61962541, -5.3710753],
                            [39.619571, -5.37099576],
                            [39.61956263, -5.37092041],
                            [39.61939101, -5.37084505],
                            [39.61928218, -5.37083459],
                            [39.61921939, -5.37084505],
                            [39.61917335, -5.37092041],
                            [39.61914058, -5.37092323],
                            [39.61910781, -5.37092605],
                            [39.61900173, -5.37087017],
                            [39.61901429, -5.37081994],
                            [39.61882175, -5.37074877],
                            [39.61872547, -5.37076552],
                            [39.61877152, -5.37089529],
                            [39.61867524, -5.37090366],
                            [39.61855804, -5.37081994],
                            [39.61854081, -5.37072877],
                            [39.61848711, -5.37070213],
                            [39.61836636, -5.37064223],
                            [39.61831108, -5.37061481],
                            [39.61823155, -5.37066504],
                            [39.61813109, -5.37071947],
                            [39.61813947, -5.37060225],
                            [39.61802645, -5.37045155],
                            [39.61796785, -5.37050178],
                            [39.61791885, -5.37062461],
                            [39.61782135, -5.37058132],
                            [39.61757857, -5.37042852],
                            [39.61746137, -5.37040759],
                            [39.61737556, -5.37033642],
                            [39.61733371, -5.37041178],
                            [39.61725418, -5.37043271],
                            [39.61721232, -5.37051225],
                            [39.61716209, -5.37060644],
                            [39.61715372, -5.37067551],
                            [39.61715162, -5.37074668],
                            [39.61708465, -5.37077179],
                            [39.61701818, -5.37077451],
                            [39.61694652, -5.37075086],
                            [39.61694652, -5.3706776],
                            [39.6170114, -5.37061272],
                            [39.61703442, -5.37058132],
                            [39.61703024, -5.37051015],
                            [39.61705954, -5.37045573],
                            [39.61707837, -5.37038038],
                            [39.6170721, -5.37028619],
                            [39.61703442, -5.37021712],
                            [39.61705326, -5.37013758],
                            [39.61705117, -5.37007478],
                            [39.61700303, -5.36998269],
                            [39.6169235, -5.36996803],
                            [39.61683769, -5.3699785],
                            [39.61674979, -5.36991361],
                            [39.61680211, -5.36983826],
                            [39.61674351, -5.36978384],
                            [39.61670584, -5.36968128],
                            [39.61664933, -5.36960174],
                            [39.61659829, -5.36959446],
                            [39.61650911, -5.36954732],
                            [39.61642539, -5.36954732],
                            [39.61635214, -5.3696143],
                            [39.61633121, -5.36954522],
                            [39.61643586, -5.36948034],
                            [39.61637517, -5.36937987],
                            [39.61636052, -5.36932545],
                            [39.61620146, -5.36919776],
                            [39.61617929, -5.36921089],
                            [39.61612193, -5.36917893],
                            [39.61608844, -5.3692166],
                            [39.61604302, -5.36925436],
                            [39.61599217, -5.36922707],
                            [39.61599635, -5.36916009],
                            [39.6159252, -5.36914544],
                            [39.61582892, -5.36914544],
                            [39.61583102, -5.36908055],
                            [39.61573056, -5.36907427],
                            [39.61570754, -5.36901985],
                            [39.61567405, -5.36893822],
                            [39.61562382, -5.3688524],
                            [39.61554011, -5.36877495],
                            [39.6155129, -5.3688231],
                            [39.61544802, -5.36886496],
                            [39.61539988, -5.36889635],
                            [39.6153714, -5.36889748],
                            [39.61532245, -5.36887124],
                            [39.61523664, -5.36885868],
                            [39.61515918, -5.36884449],
                            [39.61510269, -5.36881472],
                            [39.61500642, -5.36878333],
                            [39.61493945, -5.36878123],
                            [39.61489759, -5.36880007],
                            [39.61485783, -5.36884193],
                            [39.61480132, -5.3688524],
                            [39.61485783, -5.36878751],
                            [39.61490596, -5.36872681],
                            [39.61503781, -5.36863053],
                            [39.61510479, -5.36856564],
                            [39.61498758, -5.3685175],
                            [39.61489968, -5.36852168],
                            [39.61476783, -5.36849657],
                            [39.61477202, -5.36842331],
                            [39.6147176, -5.36834377],
                            [39.61463807, -5.3683333],
                            [39.61458994, -5.368394],
                            [39.61453761, -5.36844843],
                            [39.61442041, -5.36841075],
                            [39.61435553, -5.36841284],
                            [39.61431995, -5.36832493],
                            [39.61433251, -5.36825586],
                            [39.61426135, -5.36818469],
                            [39.6142174, -5.36822655],
                            [39.61422159, -5.36827051],
                            [39.6141588, -5.36830191],
                            [39.61412346, -5.36832049],
                            [39.61405207, -5.36828307],
                            [39.61403323, -5.36821609],
                            [39.61405416, -5.36814074],
                            [39.6140416, -5.36808004],
                            [39.61401021, -5.36800678],
                            [39.61392781, -5.36794581],
                            [39.61387208, -5.36791468],
                            [39.61383231, -5.36787282],
                            [39.61381138, -5.36780374],
                            [39.6137486, -5.367787],
                            [39.61368581, -5.36781421],
                            [39.61365233, -5.36784979],
                            [39.6136293, -5.36791886],
                            [39.61358117, -5.3679398],
                            [39.61357698, -5.36789375],
                            [39.61357907, -5.3678163],
                            [39.61353512, -5.36772839],
                            [39.61352604, -5.36771829],
                            [39.61347862, -5.3676656],
                            [39.6134535, -5.36757978],
                            [39.61338444, -5.36756513],
                            [39.61330281, -5.36756303],
                            [39.61320445, -5.36752954],
                            [39.6130998, -5.36751698],
                            [39.613014, -5.36752326],
                            [39.61288424, -5.36750233],
                            [39.6128361, -5.3675128],
                            [39.61272099, -5.36749396],
                            [39.6126017, -5.36746047],
                            [39.61255984, -5.36736837],
                            [39.61246775, -5.36735791],
                            [39.61230451, -5.36736419],
                            [39.61224116, -5.36737992],
                            [39.61217056, -5.36734325],
                            [39.61211196, -5.36737046],
                            [39.61203871, -5.36740186],
                            [39.61202406, -5.36741023],
                            [39.61200425, -5.36741799],
                            [39.61197593, -5.36742907],
                            [39.61195217, -5.36747659],
                            [39.61194872, -5.36748349],
                            [39.61193943, -5.36749093],
                            [39.61190686, -5.36751698],
                            [39.61188802, -5.36745419],
                            [39.61183361, -5.3674521],
                            [39.6117771, -5.36756931],
                            [39.61172088, -5.36760621],
                            [39.61171013, -5.36761327],
                            [39.61165571, -5.36762792],
                            [39.61162013, -5.36767606],
                            [39.61159011, -5.36774255],
                            [39.61154479, -5.36772211],
                            [39.61148828, -5.36772211],
                            [39.61146454, -5.36776169],
                            [39.61145689, -5.36777444],
                            [39.6114548, -5.36787072],
                            [39.61141999, -5.36790869],
                            [39.61137527, -5.36788956],
                            [39.61139201, -5.36782048],
                            [39.61137317, -5.36773257],
                            [39.61129364, -5.36772839],
                            [39.61117016, -5.36779955],
                            [39.61115342, -5.36786235],
                            [39.61110633, -5.367832],
                            [39.61102052, -5.36782467],
                            [39.61097029, -5.36786444],
                            [39.61092425, -5.36789688],
                            [39.61087611, -5.36794816],
                            [39.61084263, -5.36799421],
                            [39.61080077, -5.3680298],
                            [39.61076728, -5.36808317],
                            [39.61072333, -5.36811457],
                            [39.61072543, -5.36818364],
                            [39.61072647, -5.36821399],
                            [39.61071915, -5.36825062],
                            [39.61068775, -5.36827574],
                            [39.61065008, -5.36827155],
                            [39.61062748, -5.36825471],
                            [39.61058206, -5.36823806],
                            [39.61053811, -5.36823806],
                            [39.61051509, -5.36828306],
                            [39.6104837, -5.36831341],
                            [39.61045649, -5.36835737],
                            [39.61045021, -5.36841074],
                            [39.61045963, -5.3684704],
                            [39.6104251, -5.36851226],
                            [39.61038428, -5.36859075],
                            [39.61030894, -5.36864936],
                            [39.61028383, -5.36871215],
                            [39.6102922, -5.36882937],
                            [39.61029638, -5.36890891],
                            [39.61031313, -5.36900938],
                            [39.61032568, -5.36907636],
                            [39.61024615, -5.36909729],
                            [39.61021892, -5.36904818],
                            [39.61018464, -5.3690384],
                            [39.61012217, -5.36902056],
                            [39.6100702, -5.36900572],
                            [39.60999501, -5.36898426],
                            [39.60998801, -5.36891257],
                            [39.60990859, -5.36888943],
                            [39.60983176, -5.36886704],
                            [39.60978153, -5.36880843],
                            [39.609702, -5.36875401],
                            [39.60963503, -5.36885448],
                            [39.60957225, -5.36889635],
                            [39.60950946, -5.36894239],
                            [39.60947597, -5.36898844],
                            [39.6094676, -5.36907635],
                            [39.60940063, -5.36916008],
                            [39.60927505, -5.36926473],
                            [39.60926459, -5.36923543],
                            [39.6092311, -5.36918101],
                            [39.60917878, -5.36918729],
                            [39.60916832, -5.36920194],
                            [39.60913483, -5.36922915],
                            [39.60911809, -5.36924799],
                            [39.60906995, -5.36925218],
                            [39.60904274, -5.36927311],
                            [39.6089967, -5.36929404],
                            [39.60897478, -5.36931914],
                            [39.60891299, -5.3693045],
                            [39.60888578, -5.36928357],
                            [39.60886904, -5.36926055],
                            [39.60884811, -5.36923962],
                            [39.6088209, -5.36921241],
                            [39.60879788, -5.36918729],
                            [39.608733, -5.3691224],
                            [39.60870986, -5.36910193],
                            [39.60868277, -5.36909519],
                            [39.60862127, -5.36910904],
                            [39.60859905, -5.36910356],
                            [39.60857185, -5.3691538],
                            [39.60854045, -5.36920403],
                            [39.60856975, -5.36926055],
                            [39.60857812, -5.36934846],
                            [39.60851534, -5.36940079],
                            [39.60848421, -5.36936519],
                            [39.60845383, -5.36935836],
                            [39.60841906, -5.36935055],
                            [39.60834581, -5.36936939],
                            [39.60830814, -5.36939241],
                            [39.60826419, -5.369428],
                            [39.60822443, -5.36947614],
                            [39.60819513, -5.36949916],
                            [39.60814071, -5.36952219],
                            [39.60806537, -5.36954102],
                            [39.60795235, -5.36954521],
                            [39.60781631, -5.369608],
                            [39.60770958, -5.36969801],
                            [39.607674, -5.36973778],
                            [39.60759028, -5.36976917],
                            [39.60751423, -5.36984019],
                            [39.60746014, -5.36983065],
                            [39.60737262, -5.36981522],
                            [39.60725961, -5.36988011],
                            [39.60722821, -5.36989895],
                            [39.60721147, -5.36991569],
                            [39.6072031, -5.36993244],
                            [39.60720728, -5.36998267],
                            [39.60721566, -5.37002035],
                            [39.6072324, -5.37005593],
                            [39.60721566, -5.37008942],
                            [39.60716752, -5.3700664],
                            [39.60714487, -5.37004728],
                            [39.60710683, -5.37004128],
                            [39.60696451, -5.37017315],
                            [39.60694358, -5.3701857],
                            [39.60691009, -5.37020245],
                            [39.60688498, -5.37022547],
                            [39.60686109, -5.37024206],
                            [39.60684878, -5.37024032],
                            [39.60683266, -5.37023803],
                            [39.60678285, -5.37024993],
                            [39.60675703, -5.3702463],
                            [39.60672801, -5.37024222],
                            [39.60671277, -5.37022533],
                            [39.60668728, -5.37022172],
                            [39.60666941, -5.37021919],
                            [39.6066606, -5.37015968],
                            [39.60663802, -5.3701564],
                            [39.6065673, -5.37016799],
                            [39.60654562, -5.37016487],
                            [39.60651193, -5.37016002],
                            [39.6064671, -5.37015356],
                            [39.60641408, -5.37014593],
                            [39.60635548, -5.37015431],
                            [39.60619851, -5.37016686],
                            [39.60609178, -5.3702464],
                            [39.60608131, -5.37025896],
                            [39.60605201, -5.3703092],
                            [39.60602062, -5.37035106],
                            [39.60598278, -5.37037332],
                            [39.60595677, -5.37037005],
                            [39.60592225, -5.37036571],
                            [39.60590969, -5.37034687],
                            [39.60589714, -5.37032803],
                            [39.60587837, -5.37031074],
                            [39.60585672, -5.37030795],
                            [39.60583767, -5.3703055],
                            [39.60581761, -5.37030292],
                            [39.60578621, -5.37031757],
                            [39.60574226, -5.37033222],
                            [39.60569622, -5.37035524],
                            [39.6056439, -5.37038873],
                            [39.60556437, -5.37045153],
                            [39.60553716, -5.37049758],
                            [39.60554553, -5.37063154],
                            [39.60554344, -5.37067549],
                            [39.6055246, -5.37072154],
                            [39.60546182, -5.37080736],
                            [39.60544298, -5.37084503],
                            [39.60541159, -5.37086178],
                            [39.60536764, -5.37084294],
                            [39.60531531, -5.37081992],
                            [39.60525462, -5.3708848],
                            [39.60517091, -5.37099993],
                            [39.60515416, -5.37111295],
                            [39.60511232, -5.37115457],
                            [39.60499631, -5.37114616],
                            [39.60491348, -5.37114016],
                            [39.60487581, -5.37121552],
                            [39.60486953, -5.37134948],
                            [39.60480046, -5.3714646],
                            [39.60479837, -5.37149809],
                            [39.60474605, -5.37153786],
                            [39.60469564, -5.37157954],
                            [39.60465396, -5.37157763],
                            [39.60459381, -5.37155985],
                            [39.6045263, -5.37155669],
                            [39.60450746, -5.37162577],
                            [39.60449281, -5.37168856],
                            [39.604497, -5.37175345],
                            [39.60443421, -5.37183089],
                            [39.60441131, -5.37195322],
                            [39.60437795, -5.37195243],
                            [39.60432964, -5.37195129],
                            [39.60425817, -5.37194959],
                            [39.60422889, -5.3719489],
                            [39.60419562, -5.37194811],
                            [39.60417051, -5.3719816],
                            [39.60415167, -5.3720716],
                            [39.60410761, -5.37208907],
                            [39.60406168, -5.37208835],
                            [39.60397789, -5.37210143],
                            [39.60394238, -5.3721009],
                            [39.6039068, -5.37213439],
                            [39.60387122, -5.3722244],
                            [39.60382309, -5.37227673],
                            [39.6037017, -5.37233952],
                            [39.60347148, -5.37247557],
                            [39.60344218, -5.37250488],
                            [39.6033773, -5.37256767],
                            [39.60336474, -5.3726179],
                            [39.60335009, -5.37267442],
                            [39.60329359, -5.37276024],
                            [39.60333335, -5.37280419],
                            [39.60333963, -5.37286071],
                            [39.60330405, -5.37293606],
                            [39.60327894, -5.37301769],
                            [39.60319743, -5.37310511],
                            [39.60313453, -5.37317258],
                            [39.60310104, -5.37323119],
                            [39.60303825, -5.37330445],
                            [39.60297756, -5.37336096],
                            [39.60292105, -5.3734698],
                            [39.60289594, -5.37350748],
                            [39.60286245, -5.37358074],
                            [39.60283524, -5.37366028],
                            [39.6028185, -5.37371679],
                            [39.60279338, -5.37379424],
                            [39.60277873, -5.37389889],
                            [39.60287291, -5.37403704],
                            [39.60288338, -5.37409565],
                            [39.60288338, -5.37417937],
                            [39.6029357, -5.37424844],
                            [39.60294826, -5.37429449],
                            [39.60297965, -5.37435938],
                            [39.60299639, -5.37452892],
                            [39.60304453, -5.37457916],
                            [39.60308429, -5.37461265],
                            [39.60315545, -5.37466079],
                            [39.60322033, -5.37468381],
                            [39.60326847, -5.37470684],
                            [39.60333335, -5.37472358],
                            [39.60337521, -5.37476963],
                            [39.60338358, -5.37481359],
                            [39.6034359, -5.37495173],
                            [39.60349659, -5.37504383],
                            [39.60351305, -5.37517705],
                            [39.60346729, -5.37518407],
                            [39.60343171, -5.37521128],
                            [39.60346311, -5.3753892],
                            [39.60345055, -5.37541641],
                            [39.60345264, -5.3754478],
                            [39.60349659, -5.37556502],
                            [39.60360124, -5.37561735],
                            [39.6036703, -5.37563828],
                            [39.60372262, -5.37565084],
                            [39.60379378, -5.37567805],
                            [39.60385238, -5.37569479],
                            [39.60389005, -5.37571154],
                            [39.60391308, -5.37574503],
                            [39.60396749, -5.37580991],
                            [39.60407004, -5.37582875],
                            [39.60413492, -5.3758434],
                            [39.60414748, -5.37589364],
                            [39.60420189, -5.37594178],
                            [39.60427096, -5.37591666],
                            [39.60430235, -5.37591457],
                            [39.60434212, -5.37595225],
                            [39.60437142, -5.37600667],
                            [39.604407, -5.37614481],
                            [39.6044928, -5.37619086],
                            [39.60461838, -5.37625366],
                            [39.60473139, -5.37634575],
                            [39.60493859, -5.37646715],
                            [39.60501184, -5.37657181],
                            [39.60515834, -5.37667228],
                            [39.60527973, -5.37673089],
                            [39.60535926, -5.37676438],
                            [39.60544088, -5.37680415],
                            [39.60549111, -5.37682927],
                            [39.60553924, -5.37683764],
                            [39.60564598, -5.37685229],
                            [39.60572133, -5.37686485],
                            [39.60583853, -5.37687113],
                            [39.60589713, -5.37686904],
                            [39.60596829, -5.37688787],
                            [39.60602689, -5.37692346],
                            [39.60608549, -5.37694229],
                            [39.60616711, -5.37695485],
                            [39.60626757, -5.37698416],
                            [39.60636803, -5.37696951],
                            [39.60648104, -5.37696951],
                            [39.60659615, -5.37697369],
                            [39.60678033, -5.37695695],
                            [39.60681381, -5.37695695],
                            [39.60686823, -5.37695695],
                            [39.60692264, -5.37693811],
                            [39.60698124, -5.37690462],
                            [39.60705031, -5.37684601],
                            [39.60710054, -5.37678322],
                            [39.60714239, -5.37677485],
                            [39.60719053, -5.37675182],
                            [39.607201, -5.37673926],
                            [39.60720727, -5.37672043],
                            [39.60720727, -5.37668903],
                            [39.60720518, -5.37663461],
                            [39.607201, -5.37660112],
                            [39.60719262, -5.37655716],
                            [39.60720518, -5.37650274],
                            [39.60729727, -5.3764839],
                            [39.60734959, -5.37650693],
                            [39.60737471, -5.376576],
                            [39.60737052, -5.3766074],
                            [39.60736843, -5.37663461],
                            [39.6073747, -5.37668484],
                            [39.60739982, -5.37675182],
                            [39.60743958, -5.37682718],
                            [39.60751911, -5.37686904],
                            [39.6077598, -5.3768188],
                            [39.60787072, -5.37677066],
                            [39.60796699, -5.37675182],
                            [39.60803187, -5.37670996],
                            [39.60803187, -5.37666391],
                            [39.60807164, -5.37666182],
                            [39.60812396, -5.37665763],
                            [39.60817419, -5.37669322],
                            [39.60820977, -5.37673508],
                            [39.60828302, -5.37675182],
                            [39.60833325, -5.3767581],
                            [39.60836883, -5.3767581],
                            [39.60841905, -5.37674136],
                            [39.60851323, -5.37669112],
                            [39.60852788, -5.37668275],
                            [39.60856137, -5.37667857],
                            [39.60860951, -5.37668066],
                            [39.60864509, -5.37668275],
                            [39.60873299, -5.37664926],
                            [39.60876229, -5.37664926],
                            [39.60883135, -5.37664717],
                            [39.6089046, -5.37664089],
                            [39.6089339, -5.37664508],
                            [39.60896948, -5.37671834],
                            [39.60899878, -5.37673508],
                            [39.60903855, -5.37673717],
                            [39.60906576, -5.37670368],
                            [39.60908459, -5.37666391],
                            [39.60910552, -5.37663252],
                            [39.60913064, -5.37661577],
                            [39.60919551, -5.37662624],
                            [39.60923109, -5.37668485],
                            [39.60929388, -5.37672043],
                            [39.60933992, -5.37671624],
                            [39.60938597, -5.37673089],
                            [39.60948852, -5.37675392],
                            [39.609522, -5.37673299],
                            [39.60953247, -5.3766995],
                            [39.60954712, -5.37664089],
                            [39.60955758, -5.37658438],
                            [39.60957223, -5.37655926],
                            [39.60963711, -5.3765781],
                            [39.60971874, -5.37662205],
                            [39.60980454, -5.37665554],
                            [39.60989872, -5.37661159],
                            [39.61000755, -5.37654879],
                            [39.61014359, -5.37656973],
                            [39.61015615, -5.37655089],
                            [39.61020429, -5.37649856],
                            [39.61030893, -5.37647763],
                            [39.61037172, -5.37647972],
                            [39.61040939, -5.37647344],
                            [39.61044915, -5.37647135],
                            [39.61052868, -5.37646716],
                            [39.61059984, -5.3764253],
                            [39.61065426, -5.37636251],
                            [39.61067728, -5.37632902],
                            [39.61072332, -5.37627669],
                            [39.61073169, -5.37625157],
                            [39.61075262, -5.37624948],
                            [39.61079239, -5.37628297],
                            [39.61079448, -5.37632483],
                            [39.61079029, -5.37635414],
                            [39.61078611, -5.37638553],
                            [39.61076936, -5.37642739],
                            [39.61081331, -5.37647344],
                            [39.61084889, -5.37647763],
                            [39.61090331, -5.37646507],
                            [39.61093261, -5.3764546],
                            [39.61096819, -5.37642949],
                            [39.61101005, -5.376396],
                            [39.61100795, -5.37638344],
                            [39.61107074, -5.37631646],
                            [39.61109167, -5.37630809],
                            [39.61114399, -5.37632065],
                            [39.6111712, -5.37633948],
                            [39.61118376, -5.37636251],
                            [39.61125491, -5.37641484],
                            [39.61130305, -5.37642112],
                            [39.61135328, -5.37640228],
                            [39.61139514, -5.37639181],
                            [39.61143072, -5.37640646],
                            [39.6114349, -5.37645042],
                            [39.61144955, -5.37650903],
                            [39.6114935, -5.3765467],
                            [39.61152699, -5.37656345],
                            [39.61159815, -5.37658019],
                            [39.61162745, -5.37654042],
                            [39.61165884, -5.37651321],
                            [39.61168814, -5.37650484],
                            [39.61172163, -5.37650275],
                            [39.61174046, -5.37649856],
                            [39.61178441, -5.37649647],
                            [39.61181162, -5.37650484],
                            [39.61183674, -5.37651531],
                            [39.61186813, -5.37652787],
                            [39.61192045, -5.37653624],
                            [39.6119937, -5.37654042],
                            [39.61202091, -5.37654461],
                            [39.61205021, -5.37656554],
                            [39.61209416, -5.37658857],
                            [39.61216532, -5.37659066],
                            [39.61221555, -5.37657601],
                            [39.6122574, -5.37656136],
                            [39.6122888, -5.37652159],
                            [39.6123474, -5.37650484],
                            [39.61242902, -5.37651112],
                            [39.61254832, -5.37647763],
                            [39.61264877, -5.37643368],
                            [39.6127576, -5.3764881],
                            [39.61295224, -5.3765174],
                            [39.61297108, -5.37658857],
                            [39.61304642, -5.37659066],
                            [39.61315316, -5.37659485],
                            [39.61323478, -5.37666392],
                            [39.61329757, -5.37666811],
                            [39.61348384, -5.3766995],
                            [39.61360313, -5.37664718],
                            [39.61370777, -5.37663462],
                            [39.61375382, -5.37661369],
                            [39.61385428, -5.37660322],
                            [39.61397985, -5.3766095],
                            [39.61408031, -5.37663252],
                            [39.61420797, -5.37668485],
                            [39.61437122, -5.37669951],
                            [39.61449261, -5.37667753],
                            [39.61461399, -5.37665555],
                            [39.61498862, -5.37670997],
                            [39.61502838, -5.37675393],
                            [39.61504144, -5.37679043],
                            [39.61500745, -5.37681253],
                            [39.61500327, -5.37686696],
                            [39.61504303, -5.37689207],
                            [39.61510373, -5.37689207],
                            [39.6151414, -5.37693184],
                            [39.61519791, -5.37700719],
                            [39.61531929, -5.37711394],
                            [39.61535069, -5.37715999],
                            [39.61539255, -5.37719767],
                            [39.61543022, -5.37721651],
                            [39.61546789, -5.37720813],
                            [39.61548463, -5.37718092],
                            [39.61550975, -5.37716418],
                            [39.6155244, -5.37715581],
                            [39.61555788, -5.3771286],
                            [39.61557463, -5.37712441],
                            [39.61561858, -5.37713488],
                            [39.61563741, -5.37714325],
                            [39.6156416, -5.37716209],
                            [39.61564369, -5.37721441],
                            [39.61565206, -5.37723325],
                            [39.61566253, -5.37726465],
                            [39.61567508, -5.37730442],
                            [39.61568346, -5.37734209],
                            [39.61568764, -5.37736303],
                            [39.61569183, -5.37738186],
                            [39.61569392, -5.3774028],
                            [39.61570648, -5.37744884],
                            [39.61572113, -5.37750536],
                            [39.61579647, -5.37758071],
                            [39.61583624, -5.3776456],
                            [39.615876, -5.37764769],
                            [39.61590321, -5.37764769],
                            [39.61600157, -5.37762257],
                            [39.61604971, -5.37760374],
                            [39.6161104, -5.37759327],
                            [39.6161711, -5.37758908],
                            [39.61620249, -5.37759118],
                            [39.6162883, -5.37761629],
                            [39.61632597, -5.377677],
                            [39.61634062, -5.37775653],
                            [39.61634899, -5.37779002],
                            [39.61636364, -5.37782979],
                            [39.61638457, -5.3778591],
                            [39.6164641, -5.37792398],
                            [39.61657712, -5.37793445],
                            [39.61658549, -5.37798468],
                            [39.6166127, -5.37803701],
                            [39.61664618, -5.37810609],
                            [39.61672362, -5.37811027],
                            [39.61678431, -5.37816469],
                            [39.61689733, -5.37824005],
                            [39.61689733, -5.37827772],
                            [39.61691616, -5.37831121],
                            [39.61693291, -5.37833842],
                            [39.61694546, -5.37838447],
                            [39.61696011, -5.37843889],
                            [39.6169936, -5.37846192],
                            [39.61705011, -5.37848913],
                            [39.61710243, -5.37853727],
                            [39.61709406, -5.37859378],
                            [39.61721545, -5.37872774],
                            [39.61725521, -5.3787403],
                            [39.61729079, -5.37872984],
                            [39.61734939, -5.37869844],
                            [39.61736404, -5.37867751],
                            [39.61739962, -5.37867123],
                            [39.6174352, -5.37869425],
                            [39.61746241, -5.37874449],
                            [39.61751891, -5.37879054],
                            [39.61754821, -5.37881356],
                            [39.61756286, -5.37881356],
                            [39.61757751, -5.37882612],
                            [39.617611, -5.37886798],
                            [39.61762986, -5.37889568],
                            [39.61761937, -5.37891194],
                            [39.61761728, -5.3789538],
                            [39.61761949, -5.378983],
                            [39.61760472, -5.37900613],
                            [39.61761309, -5.37903543],
                            [39.61765914, -5.37907939],
                            [39.61773867, -5.37914218],
                            [39.61775122, -5.379228],
                            [39.61782447, -5.37927405],
                            [39.61792493, -5.37931172],
                            [39.61798772, -5.37933684],
                            [39.61802101, -5.37937175],
                            [39.61801493, -5.37938289],
                            [39.61804423, -5.37942057],
                            [39.61805888, -5.3794415],
                            [39.61807143, -5.37946034],
                            [39.61807981, -5.37948755],
                            [39.61809584, -5.37952714],
                            [39.61809027, -5.37953778],
                            [39.61809446, -5.37958802],
                            [39.61812585, -5.37962151],
                            [39.61815515, -5.37962988],
                            [39.61819073, -5.37962988],
                            [39.61822631, -5.37964034],
                            [39.61829956, -5.37968639],
                            [39.61831421, -5.37973244],
                            [39.61830165, -5.37979524],
                            [39.61827444, -5.37997106],
                            [39.61828491, -5.38000873],
                            [39.61830374, -5.3800506],
                            [39.61831421, -5.38011758],
                            [39.61831839, -5.38014897],
                            [39.61832467, -5.38022433],
                            [39.61833304, -5.38023898],
                            [39.61836444, -5.38027456],
                            [39.61839792, -5.38034154],
                            [39.61841048, -5.38043992],
                            [39.61843978, -5.38048597],
                            [39.6184628, -5.38049852],
                            [39.61851131, -5.38053798],
                            [39.61849838, -5.38056969],
                            [39.61844187, -5.38062202],
                            [39.61845195, -5.38068388],
                            [39.61844815, -5.38069318],
                            [39.61847954, -5.38072249],
                            [39.61850675, -5.38073295],
                            [39.61856326, -5.38074551],
                            [39.61860512, -5.38078319],
                            [39.6186114, -5.38085436],
                            [39.61861558, -5.38095064],
                            [39.61864488, -5.38103855],
                            [39.61865165, -5.38106406],
                            [39.61864488, -5.38108251],
                            [39.61868046, -5.38110762],
                            [39.61873488, -5.38115995],
                            [39.61875371, -5.38119344],
                            [39.61877367, -5.38121421],
                            [39.61876418, -5.38124158],
                            [39.61877255, -5.381296],
                            [39.61879557, -5.38132112],
                            [39.61882068, -5.38134624],
                            [39.61885208, -5.38135252],
                            [39.61888975, -5.3813567],
                            [39.61895044, -5.38136717],
                            [39.61900277, -5.38137345],
                            [39.61904672, -5.3813881],
                            [39.61909694, -5.38139647],
                            [39.61918694, -5.38139647],
                            [39.61920491, -5.38140843],
                            [39.61920305, -5.38141478],
                            [39.6192012, -5.38142113],
                            [39.6191974, -5.38143415],
                            [39.61917229, -5.38144462],
                            [39.6191409, -5.38145717],
                            [39.61906555, -5.38148229],
                            [39.61901532, -5.38151787],
                            [39.61895044, -5.38155974],
                            [39.61891277, -5.381723],
                            [39.61893789, -5.38174812],
                            [39.61897974, -5.38176905],
                            [39.61901323, -5.38181719],
                            [39.61902489, -5.38187049],
                            [39.61900904, -5.38192394],
                            [39.61901951, -5.38204743],
                            [39.61908229, -5.38212697],
                            [39.61911997, -5.3822107],
                            [39.61915764, -5.38225465],
                            [39.61918268, -5.38230319],
                            [39.61917438, -5.38233419],
                            [39.61914927, -5.38233629],
                            [39.61910288, -5.38233656],
                            [39.61908857, -5.38238861],
                            [39.61914089, -5.38248908],
                            [39.61920159, -5.38262723],
                            [39.61926437, -5.38274863],
                            [39.61931879, -5.38277584],
                            [39.61940878, -5.38280933],
                            [39.61951343, -5.38283236],
                            [39.61955738, -5.38285538],
                            [39.6196097, -5.38287422],
                            [39.61965993, -5.38290143],
                            [39.61971644, -5.38294957],
                            [39.61974364, -5.38299771],
                            [39.6197562, -5.38305004],
                            [39.61977294, -5.38307097],
                            [39.61982317, -5.38311074],
                            [39.61991735, -5.38310446],
                            [39.61997386, -5.38308562],
                            [39.62002618, -5.38307725],
                            [39.62006595, -5.38310446],
                            [39.61998223, -5.38316935],
                            [39.61995084, -5.3832133],
                            [39.61991735, -5.3832761],
                            [39.61985457, -5.38337029],
                            [39.61982659, -5.38343123],
                            [39.61981062, -5.3835189],
                            [39.61987968, -5.38363193],
                            [39.619932, -5.38367588],
                            [39.61997386, -5.38371147],
                            [39.61998014, -5.38376798],
                            [39.61995293, -5.3838538],
                            [39.61998432, -5.38393543],
                            [39.62010153, -5.38395845],
                            [39.62011827, -5.38396055],
                            [39.62018315, -5.38405892],
                            [39.62014155, -5.38409755],
                            [39.62013501, -5.38414265],
                            [39.62015385, -5.38421172],
                            [39.6202564, -5.38426196],
                            [39.620315, -5.38432266],
                            [39.62031919, -5.38434987],
                            [39.62035686, -5.38439173],
                            [39.62045313, -5.38448592],
                            [39.62049289, -5.38465128],
                            [39.62055149, -5.38471407],
                            [39.6206394, -5.38471826],
                            [39.620698, -5.38478733],
                            [39.6207545, -5.38490455],
                            [39.62082566, -5.3850699],
                            [39.62106425, -5.38528759],
                            [39.6211082, -5.38532736],
                            [39.62114797, -5.38542364],
                            [39.62130284, -5.38557435],
                            [39.62132586, -5.38562667],
                            [39.62138028, -5.38564551],
                            [39.62142841, -5.38560784],
                            [39.62148073, -5.38562877],
                            [39.62153096, -5.38572296],
                            [39.62167956, -5.38588203],
                            [39.62170258, -5.38589878],
                            [39.62174025, -5.38591971],
                            [39.62180095, -5.38591971],
                            [39.6218449, -5.38590506],
                            [39.62188257, -5.38587994],
                            [39.62192652, -5.38588413],
                            [39.62197619, -5.38590517],
                            [39.62197675, -5.38596157],
                            [39.62196419, -5.3860453],
                            [39.62208348, -5.38610181],
                            [39.62212116, -5.38614158],
                            [39.62220278, -5.38617298],
                            [39.62226766, -5.386196],
                            [39.62234928, -5.38623577],
                            [39.6224016, -5.38621275],
                            [39.62252927, -5.38621275],
                            [39.62260043, -5.38628391],
                            [39.62262973, -5.38638438],
                            [39.62267996, -5.38643253],
                            [39.62275111, -5.38645764],
                            [39.62279506, -5.38646602],
                            [39.62284948, -5.3864409],
                            [39.62291645, -5.38642206],
                            [39.62293738, -5.3864702],
                            [39.62300017, -5.38649951],
                            [39.62315504, -5.38651416],
                            [39.62318434, -5.3865016],
                            [39.62324503, -5.38651207],
                            [39.62329945, -5.38655393],
                            [39.6233434, -5.38657695],
                            [39.62347316, -5.38660416],
                            [39.62359455, -5.38658533],
                            [39.62370128, -5.38659998],
                            [39.62389592, -5.38663347],
                            [39.62403824, -5.38665231],
                            [39.62409475, -5.38667324],
                            [39.62413242, -5.38667114],
                            [39.62418265, -5.38664393],
                            [39.62422032, -5.38662928],
                            [39.62427264, -5.38663347],
                            [39.62431659, -5.38662928],
                            [39.62435845, -5.38661463],
                            [39.62438775, -5.38658951],
                            [39.62441705, -5.3865937],
                            [39.6244786, -5.38661672],
                            [39.62448402, -5.38664603],
                            [39.62451123, -5.38671301],
                            [39.62459285, -5.38675487],
                            [39.62467866, -5.38678208],
                            [39.62471843, -5.38677371],
                            [39.62477284, -5.38676115],
                            [39.62480842, -5.3867444],
                            [39.62485028, -5.38673185],
                            [39.62493121, -5.38672333],
                            [39.62494655, -5.38679464],
                            [39.62505119, -5.38688674],
                            [39.6251077, -5.38688883],
                            [39.62517677, -5.38688883],
                            [39.62523327, -5.38688255],
                            [39.62527095, -5.38688674],
                            [39.62531071, -5.38689092],
                            [39.62536303, -5.38688674],
                            [39.62540908, -5.38688464],
                            [39.62543628, -5.38689092],
                            [39.62547396, -5.38691813],
                            [39.62556395, -5.38698511],
                            [39.62560162, -5.38697046],
                            [39.62562464, -5.38694744],
                            [39.62566232, -5.38694535],
                            [39.62568115, -5.38694953],
                            [39.62570417, -5.3869579],
                            [39.62575859, -5.38700395],
                            [39.62581091, -5.38700186],
                            [39.62591346, -5.38699139],
                            [39.62603903, -5.38701233],
                            [39.62618135, -5.38705628],
                            [39.62622112, -5.38707093],
                            [39.62628809, -5.38709814],
                            [39.62637118, -5.38712588],
                            [39.62637808, -5.38714838],
                            [39.62644505, -5.38718606],
                            [39.626489, -5.38719233],
                            [39.62653086, -5.38719024],
                            [39.62658737, -5.38718606],
                            [39.62662086, -5.38718606],
                            [39.62668574, -5.38717978],
                            [39.62677992, -5.3871714],
                            [39.62682387, -5.38716931],
                            [39.6269034, -5.38716931],
                            [39.6269892, -5.38714838],
                            [39.62704362, -5.38713373],
                            [39.62708129, -5.38708768],
                            [39.62709385, -5.38707721],
                            [39.62713989, -5.38701442],
                            [39.62715245, -5.3869914],
                            [39.62718175, -5.38698302],
                            [39.62723407, -5.38703326],
                        ],
                        [
                            [39.638837, -5.15025265],
                            [39.63918297, -5.14992698],
                            [39.63918883, -5.14992146],
                            [39.63937918, -5.14956041],
                            [39.63948045, -5.14923718],
                            [39.639412, -5.14906807],
                            [39.63939231, -5.14889739],
                            [39.6394594, -5.14869029],
                            [39.63954897, -5.14841382],
                            [39.63966802, -5.14804632],
                            [39.63985178, -5.14768295],
                            [39.63989539, -5.1475467],
                            [39.63987147, -5.14732189],
                            [39.6400487, -5.14692802],
                            [39.64012404, -5.14669463],
                            [39.64017816, -5.146527],
                            [39.64039002, -5.14633065],
                            [39.64058037, -5.14610089],
                            [39.64077728, -5.14593677],
                            [39.64082323, -5.14551008],
                            [39.64079697, -5.14540505],
                            [39.64067882, -5.14539192],
                            [39.64063944, -5.14516216],
                            [39.64067883, -5.14482736],
                            [39.64089543, -5.14466982],
                            [39.64110547, -5.14446631],
                            [39.64121297, -5.14411258],
                            [39.64118424, -5.14384268],
                            [39.64113829, -5.14369826],
                            [39.64121267, -5.14346528],
                            [39.6414993, -5.14345537],
                            [39.64148618, -5.14324531],
                            [39.64145336, -5.14302211],
                            [39.64136147, -5.14281205],
                            [39.64136803, -5.14281205],
                            [39.64122363, -5.14255603],
                            [39.64144023, -5.14231314],
                            [39.6416634, -5.14214903],
                            [39.64174982, -5.14187607],
                            [39.64198503, -5.14166981],
                            [39.64208349, -5.14157791],
                            [39.64213135, -5.14141554],
                            [39.64190626, -5.14133502],
                            [39.64180124, -5.14115121],
                            [39.64172248, -5.14094771],
                            [39.64161089, -5.14081642],
                            [39.64164371, -5.14047506],
                            [39.64165028, -5.140265],
                            [39.6417122, -5.14010197],
                            [39.64167653, -5.13987113],
                            [39.64155838, -5.13967419],
                            [39.6415912, -5.13941161],
                            [39.64176843, -5.13918185],
                            [39.64193909, -5.1390046],
                            [39.64221477, -5.13881423],
                            [39.64252983, -5.13855165],
                            [39.64259929, -5.13835375],
                            [39.6425364, -5.13823655],
                            [39.64247732, -5.13808557],
                            [39.64247732, -5.13792802],
                            [39.64262173, -5.13779016],
                            [39.64276613, -5.13774421],
                            [39.64268736, -5.13731095],
                            [39.64277729, -5.13708183],
                            [39.64269393, -5.13685143],
                            [39.64270706, -5.13669388],
                            [39.64279442, -5.1364903],
                            [39.64294335, -5.13639848],
                            [39.64301191, -5.13623135],
                            [39.64308776, -5.13618185],
                            [39.64314027, -5.13589957],
                            [39.64323405, -5.13567175],
                            [39.64334375, -5.1356895],
                            [39.64340234, -5.13553871],
                            [39.64352097, -5.13549913],
                            [39.64356759, -5.13537224],
                            [39.64371132, -5.13534158],
                            [39.64373073, -5.1352844],
                            [39.64375602, -5.13520987],
                            [39.64387542, -5.13515121],
                            [39.64397388, -5.13503305],
                            [39.64415766, -5.13494771],
                            [39.64411828, -5.13475734],
                            [39.64414454, -5.13439629],
                            [39.64433489, -5.13427813],
                            [39.64461713, -5.13416653],
                            [39.64471231, -5.13405493],
                            [39.64480748, -5.13394333],
                            [39.64502409, -5.13364137],
                            [39.64512911, -5.1333394],
                            [39.64515183, -5.13300339],
                            [39.64515236, -5.13299562],
                            [39.64516187, -5.13285495],
                            [39.64503065, -5.13269607],
                            [39.64497158, -5.132486],
                            [39.64503722, -5.13234815],
                            [39.64527352, -5.13215121],
                            [39.64558858, -5.13203961],
                            [39.64593646, -5.13190176],
                            [39.6461662, -5.13182955],
                            [39.6463106, -5.13161292],
                            [39.64633686, -5.13129126],
                            [39.64631388, -5.13118622],
                            [39.64629091, -5.13108119],
                            [39.64611369, -5.13080548],
                            [39.64590364, -5.13071357],
                            [39.64576165, -5.13069148],
                            [39.64534937, -5.13062498],
                            [39.64503078, -5.13057359],
                            [39.64490266, -5.13059869],
                            [39.64479436, -5.13062167],
                            [39.64464339, -5.13073983],
                            [39.64436115, -5.13089081],
                            [39.64421674, -5.13109432],
                            [39.64400014, -5.13129125],
                            [39.64387542, -5.13158666],
                            [39.64375727, -5.13180329],
                            [39.64365225, -5.13218403],
                            [39.64356036, -5.13247287],
                            [39.64337001, -5.13270263],
                            [39.64310745, -5.13291926],
                            [39.64288428, -5.13303742],
                            [39.64269393, -5.13308337],
                            [39.64233292, -5.13320154],
                            [39.64171592, -5.13344442],
                            [39.64134835, -5.13368075],
                            [39.64094796, -5.13391707],
                            [39.64049505, -5.13417965],
                            [39.63985836, -5.13462604],
                            [39.63948422, -5.13483611],
                            [39.63925449, -5.1350593],
                            [39.63880815, -5.13561729],
                            [39.63840119, -5.13594552],
                            [39.63736411, -5.1363919],
                            [39.63733129, -5.13642473],
                            [39.63731816, -5.136707],
                            [39.63728534, -5.13692363],
                            [39.63720001, -5.1371337],
                            [39.63716719, -5.13741597],
                            [39.63704904, -5.13777703],
                            [39.63702935, -5.13813808],
                            [39.63688495, -5.13853851],
                            [39.63687182, -5.13883392],
                            [39.63686525, -5.13907024],
                            [39.63689807, -5.13937878],
                            [39.63691448, -5.1395593],
                            [39.63693089, -5.13973983],
                            [39.63692488, -5.13980354],
                            [39.63689807, -5.14008775],
                            [39.63685213, -5.14029781],
                            [39.63683243, -5.1405604],
                            [39.63685212, -5.14089519],
                            [39.63682587, -5.14130219],
                            [39.63685212, -5.14186674],
                            [39.63680618, -5.14230657],
                            [39.63683899, -5.14251335],
                            [39.63687181, -5.14272014],
                            [39.63683243, -5.14311401],
                            [39.63684556, -5.14332408],
                            [39.6369112, -5.14350132],
                            [39.63692432, -5.14386893],
                            [39.63688494, -5.14428907],
                            [39.63693745, -5.14457791],
                            [39.63694401, -5.14507025],
                            [39.63694859, -5.14532449],
                            [39.63695714, -5.14579891],
                            [39.63691776, -5.14646193],
                            [39.63692432, -5.14688863],
                            [39.63685212, -5.14713152],
                            [39.63678648, -5.14754509],
                            [39.63674709, -5.14793239],
                            [39.63660269, -5.14835253],
                            [39.63655674, -5.14869388],
                            [39.636583, -5.14877922],
                            [39.63659612, -5.14917966],
                            [39.636583, -5.14943568],
                            [39.63657643, -5.14964574],
                            [39.63640577, -5.14984268],
                            [39.63628762, -5.15005275],
                            [39.63615635, -5.15021029],
                            [39.63606445, -5.1505057],
                            [39.63597256, -5.15075515],
                            [39.63592005, -5.15101117],
                            [39.63585441, -5.15118841],
                            [39.6359463, -5.15127375],
                            [39.63616291, -5.15137222],
                            [39.63633357, -5.15145756],
                            [39.63639921, -5.15155603],
                            [39.63649766, -5.15171358],
                            [39.63666176, -5.15185143],
                            [39.63681929, -5.15185144],
                            [39.636944, -5.15186456],
                            [39.63710153, -5.15190395],
                            [39.63716245, -5.15174608],
                            [39.63708184, -5.15158229],
                            [39.6371348, -5.15144743],
                            [39.63725907, -5.15125406],
                            [39.63753475, -5.151044],
                            [39.6376529, -5.15096522],
                            [39.63794827, -5.15083393],
                            [39.63828959, -5.15082737],
                            [39.6385784, -5.15076172],
                            [39.63876218, -5.15047945],
                            [39.638837, -5.15025265],
                        ],
                        [
                            [39.65752452, -5.13671927],
                            [39.65740956, -5.13669874],
                            [39.65731514, -5.13671106],
                            [39.65720429, -5.13677676],
                            [39.65717966, -5.13692047],
                            [39.65714681, -5.13707239],
                            [39.65720839, -5.13721199],
                            [39.6572864, -5.13725305],
                            [39.65745883, -5.13727358],
                            [39.65760252, -5.13731875],
                            [39.6575861, -5.13746245],
                            [39.65754094, -5.13759795],
                            [39.65749167, -5.13775398],
                            [39.65750399, -5.13798391],
                            [39.65747525, -5.13823438],
                            [39.65720018, -5.13843557],
                            [39.65716323, -5.13862855],
                            [39.65711807, -5.13893239],
                            [39.65708112, -5.13913358],
                            [39.65692922, -5.13928961],
                            [39.65686353, -5.13950312],
                            [39.65684711, -5.13970431],
                            [39.656843, -5.13983981],
                            [39.65683889, -5.13997531],
                            [39.65666646, -5.14001226],
                            [39.65653919, -5.13993014],
                            [39.65643245, -5.13998762],
                            [39.65628876, -5.14013544],
                            [39.65625181, -5.14042696],
                            [39.65617791, -5.14062815],
                            [39.6561697, -5.14081703],
                            [39.65616764, -5.14092173],
                            [39.65616559, -5.14102643],
                            [39.65614096, -5.14123994],
                            [39.65610811, -5.14142471],
                            [39.65599316, -5.14160948],
                            [39.65600958, -5.14171213],
                            [39.6558782, -5.14186405],
                            [39.65575504, -5.14212272],
                            [39.65568114, -5.14234034],
                            [39.65561135, -5.14250869],
                            [39.65548407, -5.14264829],
                            [39.65528701, -5.14264008],
                            [39.65515153, -5.14284948],
                            [39.65501194, -5.14291518],
                            [39.6547574, -5.14296855],
                            [39.65467939, -5.14314921],
                            [39.65461781, -5.14322312],
                            [39.65451517, -5.14322312],
                            [39.65444128, -5.14338736],
                            [39.65441254, -5.14346127],
                            [39.65430579, -5.14356392],
                            [39.6542401, -5.14377332],
                            [39.65425242, -5.14391703],
                            [39.65437148, -5.1441059],
                            [39.65444538, -5.14432763],
                            [39.65453159, -5.14445491],
                            [39.65472455, -5.14479571],
                            [39.65493393, -5.14512829],
                            [39.65508173, -5.14542392],
                            [39.6552829, -5.14566617],
                            [39.65531985, -5.14581809],
                            [39.65542249, -5.14580167],
                            [39.65552923, -5.14580167],
                            [39.65564829, -5.1459618],
                            [39.65576735, -5.14613015],
                            [39.65593978, -5.14622459],
                            [39.65592746, -5.14640935],
                            [39.65593567, -5.14660233],
                            [39.65593157, -5.14672962],
                            [39.65603421, -5.14681174],
                            [39.65614095, -5.14688565],
                            [39.65624769, -5.14694313],
                            [39.65639549, -5.14692671],
                            [39.65656792, -5.14687744],
                            [39.6567732, -5.14681174],
                            [39.65703184, -5.14673783],
                            [39.65717554, -5.14677889],
                            [39.65733565, -5.14674605],
                            [39.6574465, -5.14669677],
                            [39.65749658, -5.14660604],
                            [39.65761893, -5.14645863],
                            [39.65770968, -5.14629317],
                            [39.65772568, -5.14608088],
                            [39.65765588, -5.14597412],
                            [39.65754503, -5.14587147],
                            [39.65745061, -5.14571955],
                            [39.65736439, -5.14565796],
                            [39.65740134, -5.14545267],
                            [39.6574465, -5.14532538],
                            [39.6576682, -5.14514882],
                            [39.65818139, -5.14454525],
                            [39.65841969, -5.14414708],
                            [39.65851394, -5.14391704],
                            [39.65883827, -5.14374869],
                            [39.65926935, -5.14349412],
                            [39.65963063, -5.14312048],
                            [39.65976201, -5.14275505],
                            [39.65967169, -5.14235266],
                            [39.65973327, -5.14201187],
                            [39.6596758, -5.1416875],
                            [39.65961832, -5.14136313],
                            [39.65947873, -5.14116193],
                            [39.6593761, -5.14072259],
                            [39.65949516, -5.14036538],
                            [39.65968557, -5.14013106],
                            [39.65999603, -5.14002458],
                            [39.6602752, -5.13988498],
                            [39.66088692, -5.13958935],
                            [39.66139601, -5.13940048],
                            [39.66166286, -5.13931015],
                            [39.66197488, -5.13888313],
                            [39.66241007, -5.13877637],
                            [39.66290683, -5.1386573],
                            [39.66339128, -5.13861213],
                            [39.66393731, -5.13866962],
                            [39.66477894, -5.13878048],
                            [39.66520592, -5.13876816],
                            [39.66563699, -5.13883386],
                            [39.66621998, -5.13866962],
                            [39.66678243, -5.13842737],
                            [39.66717245, -5.13816869],
                            [39.66743521, -5.13791002],
                            [39.6677267, -5.1376924],
                            [39.66784576, -5.13750763],
                            [39.66793197, -5.1373434],
                            [39.66798124, -5.13716273],
                            [39.6679484, -5.13703134],
                            [39.66790102, -5.13700477],
                            [39.66779994, -5.13694807],
                            [39.66769796, -5.13697796],
                            [39.66763227, -5.13687532],
                            [39.66750911, -5.1363128],
                            [39.66726688, -5.1361116],
                            [39.66719298, -5.13590631],
                            [39.66701234, -5.13579134],
                            [39.66686454, -5.13558193],
                            [39.6666798, -5.13547929],
                            [39.66651154, -5.13543773],
                            [39.66627669, -5.13537973],
                            [39.66607629, -5.13529452],
                            [39.66590386, -5.135352],
                            [39.66582996, -5.13540948],
                            [39.66575333, -5.13538468],
                            [39.66555899, -5.13526167],
                            [39.66523876, -5.13525345],
                            [39.66488159, -5.13515902],
                            [39.66465187, -5.13506991],
                            [39.66427373, -5.13492323],
                            [39.66400301, -5.13481822],
                            [39.66383879, -5.13467451],
                            [39.66367867, -5.1345308],
                            [39.6635514, -5.134461],
                            [39.66343645, -5.13439941],
                            [39.66328865, -5.13437067],
                            [39.66321475, -5.1342557],
                            [39.66313675, -5.13414073],
                            [39.66290684, -5.13407504],
                            [39.66275904, -5.13411199],
                            [39.66270567, -5.13406683],
                            [39.66259072, -5.13396007],
                            [39.66239365, -5.13382457],
                            [39.66233617, -5.13395186],
                            [39.66222943, -5.13397649],
                            [39.6621309, -5.13393954],
                            [39.66204468, -5.13386153],
                            [39.66195847, -5.13373424],
                            [39.66167929, -5.13357411],
                            [39.66154381, -5.1335659],
                            [39.66131801, -5.13346325],
                            [39.66115379, -5.13324974],
                            [39.6610881, -5.13320457],
                            [39.66105115, -5.13316762],
                            [39.66095673, -5.13315119],
                            [39.66085409, -5.13309371],
                            [39.6607884, -5.13304854],
                            [39.66072682, -5.13295821],
                            [39.66066113, -5.13289252],
                            [39.66059134, -5.13285146],
                            [39.66059544, -5.13269954],
                            [39.66052975, -5.13257225],
                            [39.66045175, -5.13250656],
                            [39.66034911, -5.13239159],
                            [39.66025058, -5.13221503],
                            [39.66016026, -5.13200152],
                            [39.66013152, -5.13183318],
                            [39.66011099, -5.13169357],
                            [39.66003299, -5.13154576],
                            [39.66001246, -5.13139384],
                            [39.66000015, -5.13127477],
                            [39.6599632, -5.13116801],
                            [39.65983593, -5.13112284],
                            [39.65983182, -5.13100788],
                            [39.65982771, -5.13090523],
                            [39.65986466, -5.1308149],
                            [39.65988109, -5.13070814],
                            [39.65988109, -5.13054801],
                            [39.65990161, -5.13044536],
                            [39.65994678, -5.1303386],
                            [39.65993035, -5.13025238],
                            [39.6598893, -5.13023596],
                            [39.65986467, -5.13011278],
                            [39.65979077, -5.13008814],
                            [39.65970866, -5.13013741],
                            [39.65964913, -5.13020721],
                            [39.65939664, -5.13057264],
                            [39.65930632, -5.13067529],
                            [39.65919957, -5.13076152],
                            [39.65915441, -5.13096271],
                            [39.65906409, -5.13114748],
                            [39.65902098, -5.13121112],
                            [39.65897787, -5.13127476],
                            [39.65896556, -5.13145132],
                            [39.65885881, -5.13151702],
                            [39.65886703, -5.13158271],
                            [39.65890808, -5.13170589],
                            [39.65881776, -5.13185781],
                            [39.65876439, -5.13196457],
                            [39.65869049, -5.13224377],
                            [39.65873154, -5.13242854],
                            [39.65866585, -5.13263794],
                            [39.65868228, -5.13283914],
                            [39.65864122, -5.13298285],
                            [39.65850574, -5.13314708],
                            [39.65846058, -5.13328258],
                            [39.65835384, -5.13346735],
                            [39.65830867, -5.13366854],
                            [39.6582512, -5.13401755],
                            [39.65819782, -5.13418179],
                            [39.65806234, -5.1343953],
                            [39.65798844, -5.13458007],
                            [39.65797202, -5.1346745],
                            [39.65802129, -5.13477715],
                            [39.65808287, -5.13485517],
                            [39.65822656, -5.13490444],
                            [39.65830457, -5.13504404],
                            [39.65828814, -5.13516311],
                            [39.65820603, -5.13537662],
                            [39.65804592, -5.13562709],
                            [39.65793096, -5.13600073],
                            [39.65793507, -5.13628815],
                            [39.65791454, -5.13642775],
                            [39.65773801, -5.13648113],
                            [39.65756968, -5.13666179],
                            [39.65752452, -5.13671927],
                        ],
                        [
                            [39.67081877, -5.13556517],
                            [39.67092283, -5.13552057],
                            [39.67113097, -5.135528],
                            [39.6712499, -5.13568412],
                            [39.67139857, -5.13582537],
                            [39.67173307, -5.1358328],
                            [39.67222367, -5.13602609],
                            [39.6727217, -5.13626399],
                            [39.6734799, -5.13653162],
                            [39.67389617, -5.13675464],
                            [39.67423067, -5.13705945],
                            [39.67503347, -5.1375501],
                            [39.67544973, -5.13795898],
                            [39.67572477, -5.13820431],
                            [39.67619307, -5.13810023],
                            [39.6765573, -5.13799616],
                            [39.676981, -5.13784747],
                            [39.67725603, -5.13760958],
                            [39.67746417, -5.13705945],
                            [39.67767974, -5.13681412],
                            [39.67796964, -5.13671748],
                            [39.6783636, -5.13591459],
                            [39.67875014, -5.13557262],
                            [39.67922587, -5.13500019],
                            [39.67936711, -5.13462848],
                            [39.67930021, -5.13422703],
                            [39.67924074, -5.13377355],
                            [39.67899544, -5.13349848],
                            [39.67874271, -5.13323085],
                            [39.67845281, -5.13279224],
                            [39.67834131, -5.13259151],
                            [39.67843051, -5.1324354],
                            [39.67853458, -5.13220494],
                            [39.67855688, -5.13195217],
                            [39.67848254, -5.13175145],
                            [39.67824468, -5.13158047],
                            [39.67799194, -5.13149869],
                            [39.67775629, -5.13156593],
                            [39.67716684, -5.13161764],
                            [39.67676544, -5.13181092],
                            [39.67628971, -5.13190013],
                            [39.67587153, -5.1320295],
                            [39.67537541, -5.13213059],
                            [39.67485072, -5.1323035],
                            [39.67425297, -5.13245026],
                            [39.67364897, -5.13266992],
                            [39.67312256, -5.13286136],
                            [39.67278117, -5.13298552],
                            [39.67241694, -5.13310075],
                            [39.67117557, -5.13357281],
                            [39.67077417, -5.13389248],
                            [39.67043967, -5.13450952],
                            [39.67052143, -5.13480689],
                            [39.67050657, -5.13508939],
                            [39.67070727, -5.13541649],
                            [39.67081877, -5.13556517],
                        ],
                        [
                            [39.63308222, -5.12558727],
                            [39.63312373, -5.12557344],
                            [39.63315486, -5.12552501],
                            [39.63315457, -5.12545575],
                            [39.63309606, -5.12538317],
                            [39.63304072, -5.12535204],
                            [39.6329577, -5.12535896],
                            [39.63291965, -5.1253555],
                            [39.63289198, -5.12529323],
                            [39.63289234, -5.12520328],
                            [39.63292311, -5.1251514],
                            [39.63288507, -5.12508221],
                            [39.6328401, -5.12500957],
                            [39.63281935, -5.12492308],
                            [39.63282013, -5.12479844],
                            [39.63287123, -5.12471552],
                            [39.63293003, -5.12462904],
                            [39.63297154, -5.12456331],
                            [39.63304418, -5.12453564],
                            [39.63304412, -5.12445954],
                            [39.63304405, -5.12436957],
                            [39.63298192, -5.12429694],
                            [39.63298203, -5.12420352],
                            [39.63301997, -5.12411014],
                            [39.63308569, -5.12399252],
                            [39.63308546, -5.12388876],
                            [39.63313411, -5.1238161],
                            [39.63318254, -5.12371232],
                            [39.63318182, -5.12357743],
                            [39.63320329, -5.12349438],
                            [39.63322404, -5.12342173],
                            [39.63322353, -5.12334568],
                            [39.63326209, -5.12328682],
                            [39.63331052, -5.12323839],
                            [39.6333624, -5.12322109],
                            [39.63340391, -5.12311385],
                            [39.63340325, -5.12306211],
                            [39.63336694, -5.12297992],
                            [39.63336456, -5.12297455],
                            [39.63334511, -5.12293051],
                            [39.63327247, -5.12288554],
                            [39.63322059, -5.12281635],
                            [39.63328631, -5.12280251],
                            [39.63334113, -5.12279059],
                            [39.63336586, -5.12278522],
                            [39.63345233, -5.12271949],
                            [39.63349384, -5.12267106],
                            [39.63353881, -5.12262609],
                            [39.63359761, -5.12261225],
                            [39.6335943, -5.12244288],
                            [39.63363912, -5.12229399],
                            [39.63367717, -5.12222827],
                            [39.63367467, -5.12211739],
                            [39.63371522, -5.12204492],
                            [39.63375326, -5.12198957],
                            [39.63382936, -5.12192039],
                            [39.63385357, -5.12179585],
                            [39.63381552, -5.1217232],
                            [39.63379131, -5.1216644],
                            [39.63382936, -5.12161597],
                            [39.63382699, -5.12153252],
                            [39.63390546, -5.12148797],
                            [39.63399193, -5.12147067],
                            [39.63401614, -5.12137381],
                            [39.63408532, -5.12130463],
                            [39.6341718, -5.12128041],
                            [39.63420293, -5.12127695],
                            [39.63424098, -5.1212216],
                            [39.63427902, -5.12113166],
                            [39.63427545, -5.1210521],
                            [39.63416142, -5.12089643],
                            [39.63418217, -5.12078919],
                            [39.6342306, -5.1207373],
                            [39.63429978, -5.12065773],
                            [39.63443814, -5.12063352],
                            [39.6344831, -5.12055049],
                            [39.6345419, -5.12052974],
                            [39.6346353, -5.12048822],
                            [39.63471139, -5.12039828],
                            [39.63474944, -5.12033601],
                            [39.63474403, -5.12025075],
                            [39.63476674, -5.12013537],
                            [39.63476033, -5.12003614],
                            [39.634829, -5.12004889],
                            [39.63486359, -5.12002468],
                            [39.63490855, -5.1199036],
                            [39.63493277, -5.11986555],
                            [39.63495698, -5.11986901],
                            [39.63499503, -5.11988284],
                            [39.63502616, -5.11985171],
                            [39.63502155, -5.1197903],
                            [39.63508842, -5.1197756],
                            [39.63512301, -5.11976868],
                            [39.63514722, -5.11961993],
                            [39.63514088, -5.11954088],
                            [39.63518873, -5.11951961],
                            [39.63526482, -5.1194954],
                            [39.63521986, -5.11935702],
                            [39.63524061, -5.11932935],
                            [39.6352752, -5.11926362],
                            [39.63537205, -5.11926362],
                            [39.63543777, -5.11920136],
                            [39.6354862, -5.11910795],
                            [39.63553808, -5.11901109],
                            [39.63557959, -5.11889693],
                            [39.63562455, -5.11880007],
                            [39.63570411, -5.11871705],
                            [39.63575254, -5.11863403],
                            [39.6358736, -5.1185683],
                            [39.63586646, -5.11850453],
                            [39.63592894, -5.11846106],
                            [39.63598429, -5.11839187],
                            [39.6359912, -5.11826042],
                            [39.63605692, -5.11820853],
                            [39.6361434, -5.11813588],
                            [39.63618145, -5.11805978],
                            [39.63629213, -5.11795254],
                            [39.63637515, -5.11789027],
                            [39.63642357, -5.117828],
                            [39.63647546, -5.11777265],
                            [39.63650659, -5.11767925],
                            [39.63659998, -5.11759277],
                            [39.6366657, -5.11749591],
                            [39.63672104, -5.11747515],
                            [39.63678676, -5.11738175],
                            [39.63689053, -5.11733678],
                            [39.63692166, -5.117233],
                            [39.63698392, -5.11718457],
                            [39.63699776, -5.1171223],
                            [39.63707731, -5.11706003],
                            [39.63716033, -5.11698739],
                            [39.63725372, -5.11690782],
                            [39.63734019, -5.11687323],
                            [39.63739553, -5.11687669],
                            [39.63744396, -5.11679021],
                            [39.63746126, -5.1166968],
                            [39.63754427, -5.11665183],
                            [39.63764804, -5.11663108],
                            [39.63770684, -5.11661032],
                            [39.63772068, -5.11653767],
                            [39.63776564, -5.11646157],
                            [39.63786249, -5.11644081],
                            [39.63795588, -5.11642698],
                            [39.6380389, -5.11638892],
                            [39.63807695, -5.11627823],
                            [39.63810808, -5.1162125],
                            [39.6381565, -5.11612602],
                            [39.63821876, -5.11608796],
                            [39.63830178, -5.11605683],
                            [39.63844014, -5.11602915],
                            [39.63863384, -5.11593921],
                            [39.63886904, -5.11587003],
                            [39.6390247, -5.11563479],
                            [39.63946744, -5.11529578],
                            [39.63976837, -5.11510897],
                            [39.63980062, -5.11508142],
                            [39.6399344, -5.11496714],
                            [39.64006793, -5.1147911],
                            [39.64008659, -5.1147665],
                            [39.64009538, -5.11476129],
                            [39.64038406, -5.11459008],
                            [39.6407092, -5.11436176],
                            [39.64109661, -5.11406772],
                            [39.64154627, -5.113874],
                            [39.64176764, -5.11370795],
                            [39.64218963, -5.11348655],
                            [39.64250439, -5.11333434],
                            [39.64301978, -5.11314062],
                            [39.64350057, -5.11289501],
                            [39.64400903, -5.11266323],
                            [39.64448291, -5.11237611],
                            [39.64487723, -5.11205093],
                            [39.64499829, -5.11183992],
                            [39.644981, -5.11162544],
                            [39.64481843, -5.11146631],
                            [39.64470082, -5.1114075],
                            [39.6445936, -5.11142134],
                            [39.64429613, -5.11161852],
                            [39.64394677, -5.11189872],
                            [39.64349019, -5.11216509],
                            [39.6426808, -5.11241762],
                            [39.64244534, -5.11243999],
                            [39.64198901, -5.11248335],
                            [39.64095133, -5.11250756],
                            [39.64059506, -5.11242108],
                            [39.64017999, -5.11224119],
                            [39.63967844, -5.11202325],
                            [39.63927029, -5.1117811],
                            [39.63885176, -5.1114836],
                            [39.63827411, -5.11117571],
                            [39.63791093, -5.11092664],
                            [39.63771031, -5.11085918],
                            [39.63750969, -5.11079173],
                            [39.63714996, -5.11064644],
                            [39.63683174, -5.11038699],
                            [39.63665879, -5.11019326],
                            [39.63638899, -5.10985771],
                            [39.63603618, -5.10953253],
                            [39.63569029, -5.10919697],
                            [39.63543779, -5.10895482],
                            [39.63517837, -5.10847051],
                            [39.63484631, -5.10789281],
                            [39.63456268, -5.10734969],
                            [39.63437243, -5.10682733],
                            [39.63419949, -5.10626692],
                            [39.63410956, -5.10590715],
                            [39.63412685, -5.10561311],
                            [39.63401617, -5.10536058],
                            [39.6341649, -5.10503886],
                            [39.63417182, -5.10453034],
                            [39.634241, -5.10412559],
                            [39.63422716, -5.10380042],
                            [39.63425138, -5.1036067],
                            [39.63432401, -5.10321579],
                            [39.63436206, -5.10288716],
                            [39.63434626, -5.10277177],
                            [39.63433045, -5.10265639],
                            [39.63428366, -5.10231479],
                            [39.63430672, -5.10199811],
                            [39.63433785, -5.10168677],
                            [39.63446929, -5.10147229],
                            [39.63455577, -5.10141348],
                            [39.63464916, -5.1012924],
                            [39.63475293, -5.10111598],
                            [39.63488783, -5.1009188],
                            [39.63494663, -5.10075621],
                            [39.63506769, -5.10057978],
                            [39.63510081, -5.10051528],
                            [39.63513341, -5.10045179],
                            [39.63513687, -5.10018888],
                            [39.63508153, -5.10006607],
                            [39.63502618, -5.09994327],
                            [39.63489062, -5.09985288],
                            [39.63472526, -5.09974262],
                            [39.63466645, -5.09961463],
                            [39.6344693, -5.09956274],
                            [39.63438628, -5.09950047],
                            [39.63435773, -5.09936648],
                            [39.63435515, -5.09918913],
                            [39.63439666, -5.09885704],
                            [39.63448434, -5.09867757],
                            [39.63454539, -5.09855262],
                            [39.63461803, -5.09817901],
                            [39.6346457, -5.09772929],
                            [39.63468029, -5.0974906],
                            [39.63469759, -5.09733147],
                            [39.63464916, -5.09714813],
                            [39.6345454, -5.09677452],
                            [39.6344192, -5.0964696],
                            [39.63443471, -5.09631443],
                            [39.63436553, -5.09621411],
                            [39.63416837, -5.09602039],
                            [39.63405358, -5.09572734],
                            [39.63393662, -5.09553954],
                            [39.63378443, -5.09539771],
                            [39.63371876, -5.09519936],
                            [39.63371871, -5.09503794],
                            [39.63368758, -5.09487535],
                            [39.63364478, -5.0947608],
                            [39.63364953, -5.0944706],
                            [39.63369104, -5.09422845],
                            [39.63361905, -5.09410618],
                            [39.63355614, -5.09393787],
                            [39.63346621, -5.09367842],
                            [39.63339011, -5.09347432],
                            [39.63330018, -5.09329443],
                            [39.633231, -5.0931699],
                            [39.63317318, -5.09310704],
                            [39.63315145, -5.09308341],
                            [39.63312378, -5.09306612],
                            [39.63305798, -5.09306864],
                            [39.63301985, -5.09308847],
                            [39.63289326, -5.09316081],
                            [39.63276667, -5.09323316],
                            [39.63259487, -5.09349541],
                            [39.63250445, -5.09386618],
                            [39.63246828, -5.0943093],
                            [39.63243211, -5.09461677],
                            [39.63241248, -5.09475743],
                            [39.63237785, -5.09500563],
                            [39.63236881, -5.09540353],
                            [39.63236881, -5.09553917],
                            [39.6322603, -5.09616316],
                            [39.63228743, -5.09635306],
                            [39.63216084, -5.09691374],
                            [39.63198, -5.09744729],
                            [39.63188053, -5.09777284],
                            [39.63172681, -5.09804414],
                            [39.63167256, -5.09830639],
                            [39.63162735, -5.098433],
                            [39.63146459, -5.09850534],
                            [39.63139225, -5.09875855],
                            [39.63124758, -5.09937349],
                            [39.6311029, -5.09966287],
                            [39.63108482, -5.09991608],
                            [39.63103056, -5.10009695],
                            [39.63082259, -5.10017833],
                            [39.63067792, -5.10024164],
                            [39.63056037, -5.10038633],
                            [39.63044282, -5.10044963],
                            [39.63027102, -5.1003592],
                            [39.63014443, -5.10025972],
                            [39.63003592, -5.10027781],
                            [39.63002456, -5.1003307],
                            [39.63003839, -5.10041027],
                            [39.63010065, -5.10044832],
                            [39.63015945, -5.10049675],
                            [39.63018713, -5.10054864],
                            [39.63014562, -5.10059707],
                            [39.63004877, -5.10062129],
                            [39.62993116, -5.10067318],
                            [39.62989312, -5.10075274],
                            [39.62997267, -5.10081847],
                            [39.63003147, -5.10087036],
                            [39.63019058, -5.10089458],
                            [39.63032894, -5.10093263],
                            [39.63039812, -5.10102257],
                            [39.63045692, -5.10115057],
                            [39.63049151, -5.10126818],
                            [39.63060566, -5.10142731],
                            [39.63063679, -5.10152071],
                            [39.63059874, -5.10166255],
                            [39.63051572, -5.10186665],
                            [39.63052264, -5.10207421],
                            [39.63049843, -5.1023129],
                            [39.63044308, -5.10240976],
                            [39.63040158, -5.10248241],
                            [39.63036353, -5.10267959],
                            [39.63030473, -5.10289407],
                            [39.63027014, -5.10308779],
                            [39.63024765, -5.10311892],
                            [39.63022517, -5.10315006],
                            [39.63014216, -5.10310855],
                            [39.63004531, -5.10301514],
                            [39.62990003, -5.10293212],
                            [39.62953684, -5.10294942],
                            [39.6293694, -5.10301383],
                            [39.62935698, -5.1030186],
                            [39.62933622, -5.10308433],
                            [39.62935696, -5.10312108],
                            [39.62945729, -5.10329881],
                            [39.62953684, -5.10340605],
                            [39.62958181, -5.10350983],
                            [39.62957489, -5.10368971],
                            [39.62949533, -5.10377966],
                            [39.62944845, -5.10381409],
                            [39.62927396, -5.10394224],
                            [39.62920132, -5.10408408],
                            [39.62922208, -5.10422937],
                            [39.62921862, -5.10437812],
                            [39.62921862, -5.10454071],
                            [39.62920824, -5.1047794],
                            [39.62925666, -5.10487972],
                            [39.62930509, -5.10491432],
                            [39.62928434, -5.1050008],
                            [39.62927396, -5.10518068],
                            [39.62922207, -5.105281],
                            [39.62924629, -5.10546089],
                            [39.62928779, -5.10558197],
                            [39.6293466, -5.10560964],
                            [39.62939848, -5.10565115],
                            [39.62950571, -5.10560272],
                            [39.62960602, -5.10547819],
                            [39.62968903, -5.10538824],
                            [39.62972052, -5.10534435],
                            [39.62980318, -5.10522911],
                            [39.62984187, -5.10508308],
                            [39.62982047, -5.10496967],
                            [39.62978934, -5.10484513],
                            [39.62985852, -5.10482437],
                            [39.62995883, -5.10472751],
                            [39.63003493, -5.10465833],
                            [39.63014907, -5.1044992],
                            [39.63019329, -5.10432343],
                            [39.63024242, -5.10412815],
                            [39.63030818, -5.10398376],
                            [39.63033736, -5.10386653],
                            [39.63038082, -5.10379003],
                            [39.6304011, -5.10370779],
                            [39.63047075, -5.10373469],
                            [39.63058836, -5.1037589],
                            [39.63064024, -5.10378658],
                            [39.63069904, -5.10383155],
                            [39.63067829, -5.103963],
                            [39.63068521, -5.10404603],
                            [39.63072326, -5.10414981],
                            [39.63073709, -5.10427088],
                            [39.63073363, -5.10443001],
                            [39.63067483, -5.10475865],
                            [39.63064716, -5.10493853],
                            [39.6307025, -5.10509074],
                            [39.63075093, -5.1052049],
                            [39.63074055, -5.10533981],
                            [39.63067829, -5.10547819],
                            [39.63066445, -5.10559235],
                            [39.63066099, -5.10586217],
                            [39.63066099, -5.10607665],
                            [39.63072325, -5.10622886],
                            [39.63083048, -5.10626692],
                            [39.63090312, -5.10628421],
                            [39.63102072, -5.10634648],
                            [39.63107261, -5.10641567],
                            [39.63121442, -5.10653328],
                            [39.6313424, -5.10667512],
                            [39.63149806, -5.1067616],
                            [39.63154994, -5.10678236],
                            [39.63163987, -5.10672701],
                            [39.63173326, -5.10671663],
                            [39.63177477, -5.10678928],
                            [39.63181628, -5.10686192],
                            [39.63189237, -5.10698992],
                            [39.6319996, -5.10716288],
                            [39.63205494, -5.10720785],
                            [39.63218638, -5.10724937],
                            [39.6322521, -5.10740504],
                            [39.63237317, -5.10759184],
                            [39.63250806, -5.10773021],
                            [39.63251498, -5.10782708],
                            [39.63251844, -5.10790145],
                            [39.6325219, -5.10797583],
                            [39.63251844, -5.10806577],
                            [39.63255649, -5.10813842],
                            [39.63251498, -5.10817647],
                            [39.63240775, -5.1082076],
                            [39.63235933, -5.10826641],
                            [39.63234549, -5.10834252],
                            [39.63236625, -5.10850856],
                            [39.63235241, -5.10877147],
                            [39.63233166, -5.10906897],
                            [39.63243543, -5.1092281],
                            [39.63253228, -5.10926616],
                            [39.63272943, -5.10953253],
                            [39.63288855, -5.10976084],
                            [39.63295772, -5.10994419],
                            [39.63308225, -5.11011369],
                            [39.6331618, -5.11015175],
                            [39.63325519, -5.11030742],
                            [39.63332783, -5.11051843],
                            [39.63343852, -5.11066373],
                            [39.6334443, -5.1107375],
                            [39.63345235, -5.11084015],
                            [39.63353138, -5.11083743],
                            [39.63355266, -5.11083669],
                            [39.63359417, -5.11073291],
                            [39.6337602, -5.11069486],
                            [39.63387088, -5.11074675],
                            [39.63406112, -5.11071908],
                            [39.63418565, -5.11064297],
                            [39.63426174, -5.11058416],
                            [39.63436897, -5.11061876],
                            [39.6344001, -5.11067411],
                            [39.63440356, -5.11080556],
                            [39.63443469, -5.11093356],
                            [39.63435513, -5.11109269],
                            [39.63432054, -5.11123106],
                            [39.63432054, -5.11136943],
                            [39.63432054, -5.11145591],
                            [39.63427212, -5.11148013],
                            [39.63424791, -5.1116185],
                            [39.63424994, -5.11166196],
                            [39.63425828, -5.1118399],
                            [39.6342825, -5.11190909],
                            [39.63427904, -5.11206476],
                            [39.63427904, -5.11219621],
                            [39.63429633, -5.11227924],
                            [39.63429633, -5.1123415],
                            [39.63429979, -5.11241069],
                            [39.63428595, -5.11249371],
                            [39.63430325, -5.11259749],
                            [39.63427904, -5.11269435],
                            [39.63426174, -5.11277046],
                            [39.63425482, -5.11283965],
                            [39.63426866, -5.11300223],
                            [39.63427212, -5.11300569],
                            [39.63430325, -5.11315099],
                            [39.63438972, -5.11324785],
                            [39.63441047, -5.1134139],
                            [39.63444852, -5.11349],
                            [39.63450041, -5.11359032],
                            [39.63462493, -5.11358686],
                            [39.63473907, -5.11353843],
                            [39.63478766, -5.11347365],
                            [39.63481171, -5.11344157],
                            [39.63489818, -5.1133793],
                            [39.6349812, -5.11331358],
                            [39.63515069, -5.11326169],
                            [39.63540665, -5.11331012],
                            [39.63567299, -5.11337239],
                            [39.63578021, -5.11351076],
                            [39.63585631, -5.1136941],
                            [39.63595316, -5.11396393],
                            [39.63598083, -5.11409884],
                            [39.63602234, -5.11418879],
                            [39.63599813, -5.11431332],
                            [39.63593587, -5.11441364],
                            [39.6358909, -5.11449321],
                            [39.63590473, -5.11455202],
                            [39.63582172, -5.11453472],
                            [39.63572833, -5.1145105],
                            [39.63564531, -5.1145105],
                            [39.63555192, -5.11459007],
                            [39.63548274, -5.11471806],
                            [39.6354862, -5.11480801],
                            [39.63537898, -5.11484952],
                            [39.63527521, -5.11489449],
                            [39.63516452, -5.11500865],
                            [39.63510226, -5.11514702],
                            [39.6350227, -5.11528193],
                            [39.63500215, -5.11534132],
                            [39.63499157, -5.11537188],
                            [39.63494315, -5.11559673],
                            [39.63489126, -5.11563825],
                            [39.63482208, -5.11586656],
                            [39.63458342, -5.11638546],
                            [39.63455575, -5.11649616],
                            [39.63450386, -5.11666912],
                            [39.63448657, -5.11683171],
                            [39.63437934, -5.11714651],
                            [39.63428941, -5.11724337],
                            [39.63429632, -5.1173264],
                            [39.63441393, -5.11761352],
                            [39.63441522, -5.11762069],
                            [39.63444506, -5.11778649],
                            [39.63447965, -5.11797675],
                            [39.63441739, -5.11810821],
                            [39.63445889, -5.1182535],
                            [39.63435858, -5.11835382],
                            [39.6343655, -5.11841955],
                            [39.63435858, -5.11854754],
                            [39.63428249, -5.11868245],
                            [39.63419601, -5.11876202],
                            [39.63410608, -5.11885542],
                            [39.63401615, -5.11895574],
                            [39.63395043, -5.11908028],
                            [39.63385358, -5.11915638],
                            [39.63377221, -5.1191383],
                            [39.63376019, -5.11913563],
                            [39.63373943, -5.1191633],
                            [39.63373943, -5.11919466],
                            [39.63373943, -5.11922557],
                            [39.63366679, -5.11940199],
                            [39.63358032, -5.11956112],
                            [39.63346617, -5.11972371],
                            [39.63341867, -5.11974621],
                            [39.63333473, -5.11978598],
                            [39.63323788, -5.11987938],
                            [39.6332448, -5.11994857],
                            [39.63326568, -5.1200093],
                            [39.63328285, -5.12005927],
                            [39.63320329, -5.12022877],
                            [39.63316525, -5.1202772],
                            [39.63315833, -5.1203879],
                            [39.63314103, -5.12050552],
                            [39.63306154, -5.12059539],
                            [39.63306148, -5.12059546],
                            [39.63296463, -5.12067503],
                            [39.63286432, -5.12072692],
                            [39.63283319, -5.12076151],
                            [39.63285048, -5.12082378],
                            [39.63285394, -5.12082724],
                            [39.63288853, -5.12090334],
                            [39.63281589, -5.12098291],
                            [39.63272942, -5.12106247],
                            [39.63266716, -5.12123544],
                            [39.63268791, -5.12142916],
                            [39.63265678, -5.12154332],
                            [39.6326049, -5.12174742],
                            [39.63257377, -5.12189617],
                            [39.63252188, -5.12197573],
                            [39.63247346, -5.12201379],
                            [39.63246308, -5.1221314],
                            [39.63248037, -5.1222767],
                            [39.63243195, -5.1223701],
                            [39.63233164, -5.12244274],
                            [39.63213448, -5.1225742],
                            [39.63200304, -5.1226849],
                            [39.63198574, -5.12279559],
                            [39.63193732, -5.12290629],
                            [39.63190965, -5.12303083],
                            [39.63189927, -5.12309656],
                            [39.63175399, -5.12334217],
                            [39.63170903, -5.123484],
                            [39.63170211, -5.12361545],
                            [39.63174016, -5.12385761],
                            [39.63178512, -5.12396831],
                            [39.6318128, -5.12405479],
                            [39.63190619, -5.1240963],
                            [39.63195807, -5.12417587],
                            [39.6319892, -5.12435229],
                            [39.63200304, -5.12453218],
                            [39.63205146, -5.12473628],
                            [39.63220711, -5.12478817],
                            [39.63234893, -5.12480892],
                            [39.63241811, -5.12495768],
                            [39.63251496, -5.12507875],
                            [39.63257722, -5.12524134],
                            [39.63254263, -5.1253382],
                            [39.63254955, -5.12541777],
                            [39.63261873, -5.12549041],
                            [39.63270174, -5.12559765],
                            [39.6327052, -5.12562187],
                            [39.63277438, -5.12560111],
                            [39.63277482, -5.12554223],
                            [39.63280551, -5.12547312],
                            [39.63285393, -5.12548695],
                            [39.63292657, -5.12551463],
                            [39.63300959, -5.12554576],
                            [39.63308222, -5.12558727],
                        ],
                        [
                            [39.63518191, -5.0882564],
                            [39.63547552, -5.08822252],
                            [39.63554075, -5.08779327],
                            [39.63554736, -5.08774978],
                            [39.63559193, -5.08773031],
                            [39.63575783, -5.08765782],
                            [39.63599498, -5.087511],
                            [39.63616437, -5.08709312],
                            [39.63634506, -5.08676559],
                            [39.63660479, -5.08649454],
                            [39.63657091, -5.0861896],
                            [39.63667378, -5.08611478],
                            [39.63681935, -5.0860089],
                            [39.63700003, -5.08608796],
                            [39.63719201, -5.08622349],
                            [39.63721459, -5.08635901],
                            [39.63733881, -5.08630254],
                            [39.63735407, -5.08614937],
                            [39.63755337, -5.08624607],
                            [39.63780181, -5.08634772],
                            [39.63791474, -5.0865736],
                            [39.63801637, -5.08686724],
                            [39.63809542, -5.08707053],
                            [39.63820835, -5.08723994],
                            [39.63841162, -5.08736418],
                            [39.63863747, -5.08737547],
                            [39.63878428, -5.08738677],
                            [39.63884937, -5.08755766],
                            [39.63887462, -5.08762394],
                            [39.63881815, -5.08810958],
                            [39.63901013, -5.08838064],
                            [39.63911502, -5.08842959],
                            [39.63934891, -5.08853875],
                            [39.63955457, -5.08858715],
                            [39.63973286, -5.0886291],
                            [39.63989872, -5.08865418],
                            [39.64021282, -5.08870167],
                            [39.64050943, -5.08874651],
                            [39.64070403, -5.08877593],
                            [39.64101369, -5.08872947],
                            [39.64115574, -5.08870816],
                            [39.64138687, -5.0884342],
                            [39.64146064, -5.08834676],
                            [39.64159372, -5.08835886],
                            [39.64195752, -5.08839193],
                            [39.64218338, -5.08832417],
                            [39.64190106, -5.0880757],
                            [39.64182201, -5.08791759],
                            [39.6418075, -5.08761067],
                            [39.6415284, -5.08742065],
                            [39.64151832, -5.08715954],
                            [39.6415127, -5.08714169],
                            [39.64141548, -5.08683337],
                            [39.64159616, -5.08684466],
                            [39.64174236, -5.08689882],
                            [39.64190106, -5.0869576],
                            [39.64208175, -5.0867769],
                            [39.64228501, -5.08642678],
                            [39.64244311, -5.08594114],
                            [39.64269155, -5.08568138],
                            [39.64300577, -5.08555045],
                            [39.64309809, -5.08551197],
                            [39.64373048, -5.08538774],
                            [39.64375988, -5.08539299],
                            [39.64404668, -5.08544421],
                            [39.64424994, -5.08524092],
                            [39.64402409, -5.08491339],
                            [39.6435498, -5.08485692],
                            [39.64322231, -5.08476657],
                            [39.64305292, -5.08454069],
                            [39.64302185, -5.08421874],
                            [39.64283836, -5.08373882],
                            [39.64271414, -5.08330965],
                            [39.64326748, -5.08276189],
                            [39.64382083, -5.08221414],
                            [39.64397893, -5.08162685],
                            [39.64368532, -5.08108474],
                            [39.643866, -5.0804071],
                            [39.64400151, -5.07987629],
                            [39.6437079, -5.07974076],
                            [39.64349334, -5.07960523],
                            [39.64209305, -5.07852101],
                            [39.64236407, -5.07775302],
                            [39.6423121, -5.07720562],
                            [39.64242054, -5.07695115],
                            [39.64277061, -5.0770415],
                            [39.64312069, -5.07693985],
                            [39.64327878, -5.07658974],
                            [39.64331266, -5.07609281],
                            [39.64352723, -5.07586693],
                            [39.64335523, -5.07481517],
                            [39.64296259, -5.07363072],
                            [39.64298518, -5.07252391],
                            [39.64309811, -5.07213992],
                            [39.64332396, -5.07164298],
                            [39.6434256, -5.07120252],
                            [39.64361757, -5.07072817],
                            [39.64386601, -5.07050229],
                            [39.64405799, -5.07021994],
                            [39.64429514, -5.06941807],
                            [39.64446489, -5.06918575],
                            [39.6445097, -5.06912443],
                            [39.64452347, -5.06911984],
                            [39.64471297, -5.06905667],
                            [39.64496141, -5.0688082],
                            [39.64514209, -5.06829997],
                            [39.64514209, -5.06790822],
                            [39.64514209, -5.06779174],
                            [39.6452889, -5.06747551],
                            [39.64545335, -5.06737393],
                            [39.64567285, -5.06723834],
                            [39.64598905, -5.06682046],
                            [39.64587612, -5.06644776],
                            [39.64573686, -5.06619305],
                            [39.64535666, -5.06596212],
                            [39.6454404, -5.06574809],
                            [39.64545829, -5.06570236],
                            [39.64570673, -5.06534095],
                            [39.64604551, -5.06502472],
                            [39.64606048, -5.06502293],
                            [39.64632783, -5.06499084],
                            [39.6465198, -5.0646972],
                            [39.6465311, -5.06452779],
                            [39.64665532, -5.0643245],
                            [39.64670049, -5.06409862],
                            [39.6466892, -5.06390662],
                            [39.64680212, -5.06372592],
                            [39.64694893, -5.06362427],
                            [39.64705056, -5.06327416],
                            [39.64702798, -5.06286758],
                            [39.64706435, -5.06272936],
                            [39.64708444, -5.06265299],
                            [39.64723381, -5.06251855],
                            [39.6473103, -5.0624497],
                            [39.64751357, -5.06229158],
                            [39.64770554, -5.06174947],
                            [39.64770554, -5.06106054],
                            [39.6474571, -5.06081207],
                            [39.64735547, -5.06066525],
                            [39.64741193, -5.0604789],
                            [39.6474684, -5.06029255],
                            [39.64759625, -5.05996011],
                            [39.64763779, -5.05985209],
                            [39.64788623, -5.05959233],
                            [39.64815725, -5.0592761],
                            [39.648441, -5.0588707],
                            [39.64847345, -5.05882434],
                            [39.6486993, -5.05855328],
                            [39.6488638, -5.05822769],
                            [39.64897325, -5.05816612],
                            [39.64918529, -5.05801562],
                            [39.64941102, -5.05785827],
                            [39.64953415, -5.05755043],
                            [39.64959571, -5.05735203],
                            [39.64967096, -5.05716049],
                            [39.6498488, -5.05681159],
                            [39.65008821, -5.05665425],
                            [39.65015662, -5.05638745],
                            [39.65027974, -5.05606592],
                            [39.65025238, -5.05591541],
                            [39.65049179, -5.05550495],
                            [39.65060808, -5.05520394],
                            [39.65082697, -5.05493714],
                            [39.65096377, -5.05475928],
                            [39.65103218, -5.05456089],
                            [39.65106638, -5.05404096],
                            [39.65108006, -5.05384942],
                            [39.65129895, -5.05381521],
                            [39.65148364, -5.05367155],
                            [39.6515794, -5.05340475],
                            [39.6515452, -5.05325424],
                            [39.65143576, -5.05300797],
                            [39.65160676, -5.0528301],
                            [39.65169569, -5.05270696],
                            [39.65188722, -5.05261118],
                            [39.65188038, -5.05239911],
                            [39.65177093, -5.05222124],
                            [39.65196246, -5.05208442],
                            [39.65192142, -5.05183815],
                            [39.65188722, -5.0515303],
                            [39.65183249, -5.05119509],
                            [39.65170253, -5.05105142],
                            [39.65178461, -5.05066832],
                            [39.65199641, -5.05047802],
                            [39.65199378, -5.05034635],
                            [39.65199114, -5.05021468],
                            [39.65198588, -5.04995134],
                            [39.65202801, -5.04965639],
                            [39.6521702, -5.04947732],
                            [39.65231239, -5.04929825],
                            [39.65262836, -5.0489085],
                            [39.65283754, -5.04865602],
                            [39.6529338, -5.04853982],
                            [39.652997, -5.04834495],
                            [39.65306019, -5.04815008],
                            [39.65301897, -5.04796159],
                            [39.65298647, -5.047813],
                            [39.65301288, -5.04775536],
                            [39.65308617, -5.04759546],
                            [39.65310233, -5.04756019],
                            [39.65321447, -5.04750972],
                            [39.65331298, -5.04746539],
                            [39.6534815, -5.04738112],
                            [39.65353416, -5.04712831],
                            [39.65358682, -5.0468755],
                            [39.65361842, -5.0467491],
                            [39.65363422, -5.0466859],
                            [39.65365002, -5.0466227],
                            [39.6535863, -5.04644973],
                            [39.65357629, -5.04642256],
                            [39.65352363, -5.04616975],
                            [39.65354469, -5.04593801],
                            [39.65371321, -5.04572733],
                            [39.65403972, -5.0457484],
                            [39.65414505, -5.04505318],
                            [39.65440836, -5.04470557],
                            [39.65452422, -5.0445897],
                            [39.65464007, -5.04447383],
                            [39.6543557, -5.04417889],
                            [39.65434647, -5.04405343],
                            [39.65430303, -5.0434626],
                            [39.65436598, -5.04284209],
                            [39.65437676, -5.04273577],
                            [39.65443858, -5.04251621],
                            [39.65461183, -5.04190081],
                            [39.65468221, -5.0416508],
                            [39.65480333, -5.04143486],
                            [39.65492445, -5.04121892],
                            [39.65489286, -5.04100825],
                            [39.65477164, -5.04062724],
                            [39.6547454, -5.04054477],
                            [39.65496659, -5.04014449],
                            [39.65503373, -5.04000243],
                            [39.65524043, -5.03956514],
                            [39.65537322, -5.03936379],
                            [39.65550601, -5.03916244],
                            [39.65556694, -5.03907006],
                            [39.65568806, -5.03883831],
                            [39.65580919, -5.03860657],
                            [39.65585441, -5.03828692],
                            [39.65602325, -5.03796455],
                            [39.65633023, -5.03722772],
                            [39.65671395, -5.03624528],
                            [39.65676, -5.03576941],
                            [39.65676, -5.03538564],
                            [39.65671396, -5.03489442],
                            [39.65642233, -5.03467951],
                            [39.65570093, -5.0345567],
                            [39.65554744, -5.03394268],
                            [39.65559348, -5.0333133],
                            [39.65554548, -5.03313191],
                            [39.65545534, -5.03279138],
                            [39.65554744, -5.03240761],
                            [39.65580837, -5.03223875],
                            [39.65592014, -5.03194533],
                            [39.65593116, -5.03191639],
                            [39.65623814, -5.03173218],
                            [39.65642233, -5.03182429],
                            [39.65669861, -5.03194709],
                            [39.65692884, -5.03177823],
                            [39.65703629, -5.03167078],
                            [39.65712838, -5.03144052],
                            [39.65683675, -5.03119491],
                            [39.65648373, -5.03056553],
                            [39.65669861, -5.02969054],
                            [39.65686745, -5.02941423],
                            [39.6568828, -5.02926072],
                            [39.65666792, -5.02918397],
                            [39.65654512, -5.02918397],
                            [39.65651443, -5.02899976],
                            [39.65654992, -5.02859152],
                            [39.65657582, -5.02829363],
                            [39.6568828, -5.02744934],
                            [39.65717443, -5.02677391],
                            [39.65754281, -5.02608313],
                            [39.65780374, -5.02565332],
                            [39.65791668, -5.02548013],
                            [39.65803398, -5.02530025],
                            [39.65806468, -5.02485508],
                            [39.65817212, -5.02464785],
                            [39.65827956, -5.02444061],
                            [39.65835631, -5.0240108],
                            [39.65828947, -5.02371255],
                            [39.65836988, -5.02360532],
                            [39.65837658, -5.02347129],
                            [39.65838328, -5.02333055],
                            [39.65828277, -5.02313621],
                            [39.65827209, -5.02307389],
                            [39.65824257, -5.02290165],
                            [39.65821576, -5.0226939],
                            [39.65816886, -5.02229851],
                            [39.65830287, -5.02215777],
                            [39.6585307, -5.02186961],
                            [39.65857393, -5.02182798],
                            [39.65871163, -5.02169536],
                            [39.65894616, -5.02156133],
                            [39.65907347, -5.0214072],
                            [39.65912038, -5.02125306],
                            [39.65911368, -5.02103861],
                            [39.65909358, -5.02093808],
                            [39.65888585, -5.02079065],
                            [39.65887001, -5.02075698],
                            [39.65883224, -5.02067672],
                            [39.65887685, -5.02057263],
                            [39.65889255, -5.02053599],
                            [39.65895956, -5.02038185],
                            [39.65900647, -5.02024782],
                            [39.65910698, -5.02009368],
                            [39.65918069, -5.01987923],
                            [39.65930801, -5.01963797],
                            [39.65943532, -5.01948384],
                            [39.65935472, -5.01937098],
                            [39.65933481, -5.0193431],
                            [39.65920749, -5.01910185],
                            [39.6592745, -5.01882708],
                            [39.65933481, -5.01862603],
                            [39.65936831, -5.0184987],
                            [39.65940885, -5.01842775],
                            [39.65950233, -5.01826415],
                            [39.65963142, -5.01808986],
                            [39.65963635, -5.0180832],
                            [39.65981727, -5.01795587],
                            [39.66011881, -5.01782854],
                            [39.66040695, -5.017661],
                            [39.66051248, -5.01769619],
                            [39.66056777, -5.01771462],
                            [39.66078219, -5.01777493],
                            [39.6608693, -5.01776823],
                            [39.66106363, -5.01745325],
                            [39.66125125, -5.01707797],
                            [39.6615729, -5.01670938],
                            [39.66150589, -5.01649493],
                            [39.6616332, -5.01619335],
                            [39.66180072, -5.01591189],
                            [39.66186773, -5.01569073],
                            [39.66184763, -5.01542937],
                            [39.66190794, -5.01518811],
                            [39.66202855, -5.01502057],
                            [39.66192134, -5.01468549],
                            [39.66205536, -5.01448445],
                            [39.66209556, -5.01435041],
                            [39.66212237, -5.01405554],
                            [39.66205536, -5.01382769],
                            [39.66198835, -5.01376067],
                            [39.66199505, -5.01355292],
                            [39.66207546, -5.01339209],
                            [39.66202856, -5.01324465],
                            [39.66212237, -5.01322455],
                            [39.66220948, -5.01319774],
                            [39.66225639, -5.01316423],
                            [39.66230329, -5.0130436],
                            [39.66222958, -5.01278894],
                            [39.66221618, -5.0126281],
                            [39.66230329, -5.01221931],
                            [39.66228163, -5.01219904],
                            [39.66209557, -5.01202496],
                            [39.66198835, -5.01185742],
                            [39.66199506, -5.01166977],
                            [39.66211567, -5.01149553],
                            [39.66230329, -5.01138161],
                            [39.66250432, -5.01127438],
                            [39.66256463, -5.01110684],
                            [39.66253782, -5.01077846],
                            [39.66267854, -5.01062433],
                            [39.66279246, -5.01053721],
                            [39.66289967, -5.01033616],
                            [39.66295665, -5.01023224],
                            [39.66301359, -5.01012841],
                            [39.66300689, -5.00994746],
                            [39.66297338, -5.00982013],
                            [39.6631007, -5.00963249],
                            [39.66324812, -5.00945155],
                            [39.66340894, -5.00934432],
                            [39.66352285, -5.00917678],
                            [39.66350945, -5.00906956],
                            [39.66345584, -5.00897573],
                            [39.66340894, -5.00888191],
                            [39.66342234, -5.00874788],
                            [39.66344914, -5.00858704],
                            [39.66338213, -5.0084329],
                            [39.66323026, -5.00836386],
                            [39.66301359, -5.00826536],
                            [39.66298679, -5.00815144],
                            [39.66304039, -5.00797719],
                            [39.6631342, -5.00778285],
                            [39.66315096, -5.00764882],
                            [39.66315148, -5.00764463],
                            [39.66316771, -5.00751478],
                            [39.66335533, -5.00730033],
                            [39.66350945, -5.00713279],
                            [39.66360997, -5.00691834],
                            [39.66367027, -5.00676421],
                            [39.66365017, -5.00652295],
                            [39.66365687, -5.00637551],
                            [39.66373728, -5.00618117],
                            [39.66374398, -5.00593991],
                            [39.66366357, -5.00580588],
                            [39.66352956, -5.00571876],
                            [39.66340894, -5.00557802],
                            [39.66321462, -5.00545069],
                            [39.6630672, -5.00538368],
                            [39.66258474, -5.00536357],
                            [39.66239879, -5.00525802],
                            [39.66221284, -5.00515247],
                            [39.66203527, -5.0050888],
                            [39.66158631, -5.0050687],
                            [39.66133168, -5.00518933],
                            [39.66124457, -5.00524964],
                            [39.66059459, -5.00532336],
                            [39.66031985, -5.0055043],
                            [39.66020949, -5.00569008],
                            [39.66024614, -5.00581257],
                            [39.66011213, -5.00579917],
                            [39.65977038, -5.00569865],
                            [39.65950235, -5.00547079],
                            [39.65924772, -5.00544398],
                            [39.65891267, -5.00546409],
                            [39.65879206, -5.00545739],
                            [39.65861784, -5.00536356],
                            [39.65836321, -5.00537697],
                            [39.658323, -5.00557131],
                            [39.6582716, -5.00568176],
                            [39.65826939, -5.00578576],
                            [39.65851062, -5.00584608],
                            [39.65861114, -5.00589969],
                            [39.65841681, -5.00616776],
                            [39.65821579, -5.00638221],
                            [39.65815838, -5.00650078],
                            [39.65801712, -5.00679257],
                            [39.65798125, -5.00703226],
                            [39.65798779, -5.00717611],
                            [39.65799466, -5.00732713],
                            [39.65798125, -5.00756839],
                            [39.65796225, -5.00777741],
                            [39.65795445, -5.00786326],
                            [39.6577625, -5.00823282],
                            [39.65776683, -5.00840609],
                            [39.6575658, -5.00863394],
                            [39.65735807, -5.00883499],
                            [39.65717045, -5.00902934],
                            [39.65699623, -5.00922368],
                            [39.65687561, -5.0093041],
                            [39.6567483, -5.00926389],
                            [39.65663438, -5.00910305],
                            [39.65643336, -5.00917677],
                            [39.65615192, -5.00937112],
                            [39.6560246, -5.00953866],
                            [39.65586318, -5.00991838],
                            [39.65585708, -5.01008819],
                            [39.65583028, -5.01028924],
                            [39.65580348, -5.01048358],
                            [39.65558905, -5.01077845],
                            [39.65549524, -5.01085887],
                            [39.65552204, -5.01114034],
                            [39.65554505, -5.01129225],
                            [39.65555554, -5.01136149],
                            [39.65554884, -5.01163626],
                            [39.65545503, -5.01203835],
                            [39.65542152, -5.01242035],
                            [39.65528081, -5.01246726],
                            [39.65523509, -5.01256143],
                            [39.65519717, -5.01263954],
                            [39.65503958, -5.01282914],
                            [39.65497927, -5.01312401],
                            [39.65501947, -5.01324464],
                            [39.65497257, -5.01343899],
                            [39.65498597, -5.01368695],
                            [39.65497927, -5.01397512],
                            [39.65498827, -5.0140682],
                            [39.65499937, -5.01418287],
                            [39.65509988, -5.01429679],
                            [39.65511328, -5.01445763],
                            [39.6549863, -5.01466519],
                            [39.65490556, -5.01488653],
                            [39.65485195, -5.01513449],
                            [39.65473819, -5.01531256],
                            [39.65461072, -5.01529533],
                            [39.65459732, -5.01529533],
                            [39.65456034, -5.01529533],
                            [39.6544968, -5.01529533],
                            [39.65440958, -5.01544246],
                            [39.65427576, -5.01566818],
                            [39.65427567, -5.01592528],
                            [39.65420197, -5.01624696],
                            [39.65403444, -5.01662895],
                            [39.65393393, -5.01696403],
                            [39.65385352, -5.017279],
                            [39.6536927, -5.01732591],
                            [39.65352518, -5.01742644],
                            [39.65338721, -5.01762936],
                            [39.65339786, -5.01781513],
                            [39.65333085, -5.01803628],
                            [39.65321318, -5.01819105],
                            [39.65301591, -5.01831105],
                            [39.65287565, -5.01850659],
                            [39.652902, -5.01866623],
                            [39.65280819, -5.018941],
                            [39.65253416, -5.01926328],
                            [39.65229818, -5.01954082],
                            [39.65203089, -5.01994624],
                            [39.65189687, -5.02023441],
                            [39.65184326, -5.02033493],
                            [39.65159785, -5.02051694],
                            [39.65142237, -5.02064709],
                            [39.6513675, -5.0206499],
                            [39.65124689, -5.02077723],
                            [39.65096427, -5.02112067],
                            [39.65071149, -5.02152095],
                            [39.65070095, -5.02161575],
                            [39.65072202, -5.02176322],
                            [39.6504903, -5.02190016],
                            [39.65014273, -5.02223724],
                            [39.64984782, -5.02230044],
                            [39.64948972, -5.02285872],
                            [39.64898416, -5.02348021],
                            [39.64866818, -5.02384889],
                            [39.64837327, -5.02424917],
                            [39.64803623, -5.02493386],
                            [39.6480257, -5.02493386],
                            [39.64783611, -5.0253552],
                            [39.64779398, -5.02550267],
                            [39.64788095, -5.0256283],
                            [39.64807836, -5.02591349],
                            [39.64804676, -5.02646124],
                            [39.64784664, -5.02731447],
                            [39.64758333, -5.02807289],
                            [39.64763599, -5.02882078],
                            [39.64749907, -5.02913679],
                            [39.6472711, -5.02935862],
                            [39.64710936, -5.029516],
                            [39.64658274, -5.02994788],
                            [39.64634049, -5.0304851],
                            [39.64645635, -5.03072737],
                            [39.64645635, -5.03074844],
                            [39.64642246, -5.03098566],
                            [39.64639315, -5.03119085],
                            [39.64623516, -5.031549],
                            [39.64606905, -5.03154246],
                            [39.64583433, -5.03153322],
                            [39.64570854, -5.0316122],
                            [39.64563481, -5.03205462],
                            [39.64570854, -5.0325181],
                            [39.64571547, -5.03260298],
                            [39.64575067, -5.03303425],
                            [39.64599292, -5.03329759],
                            [39.64589812, -5.03368733],
                            [39.64594025, -5.03394014],
                            [39.64583493, -5.03412975],
                            [39.64567694, -5.03456163],
                            [39.64568747, -5.03499351],
                            [39.64562428, -5.03548859],
                            [39.64546629, -5.03594154],
                            [39.64539256, -5.03646822],
                            [39.64540264, -5.03655521],
                            [39.64543001, -5.03669204],
                            [39.64547105, -5.03682202],
                            [39.64547105, -5.03693831],
                            [39.64537528, -5.03702725],
                            [39.64528636, -5.03710934],
                            [39.64534108, -5.03723248],
                            [39.64533424, -5.03738298],
                            [39.64511535, -5.0375198],
                            [39.64501959, -5.03764978],
                            [39.64508115, -5.03776608],
                            [39.64500591, -5.03790974],
                            [39.6449712, -5.03809576],
                            [39.64493128, -5.03809506],
                            [39.64489646, -5.03809445],
                            [39.64482122, -5.03814918],
                            [39.64479386, -5.03841598],
                            [39.64471177, -5.03860753],
                            [39.64454077, -5.03885381],
                            [39.64439028, -5.03919586],
                            [39.64424663, -5.03944898],
                            [39.64406878, -5.03974315],
                            [39.64399354, -5.04003731],
                            [39.64389778, -5.04016729],
                            [39.64378149, -5.04056407],
                            [39.64370393, -5.04079228],
                            [39.64358312, -5.04081719],
                            [39.64337791, -5.04116608],
                            [39.64329583, -5.04128922],
                            [39.64330951, -5.04143973],
                            [39.64331072, -5.04144314],
                            [39.64338475, -5.0416518],
                            [39.64350788, -5.04180914],
                            [39.64372677, -5.04204174],
                            [39.64371993, -5.04220593],
                            [39.64370625, -5.04245904],
                            [39.64362416, -5.04276689],
                            [39.64347367, -5.04306106],
                            [39.64333687, -5.04376569],
                            [39.6432411, -5.04408037],
                            [39.64330267, -5.04422404],
                            [39.64341211, -5.04449084],
                            [39.64341211, -5.04464818],
                            [39.64341895, -5.04503128],
                            [39.64338475, -5.04526388],
                            [39.64323426, -5.04550331],
                            [39.64310067, -5.04572775],
                            [39.64296713, -5.04580566],
                            [39.64294995, -5.04581568],
                            [39.64283869, -5.04588059],
                            [39.64248867, -5.0460848],
                            [39.64248183, -5.04616005],
                            [39.64251603, -5.04626267],
                            [39.64246131, -5.04652263],
                            [39.64226978, -5.04685784],
                            [39.64224469, -5.0469815],
                            [39.64217401, -5.04732988],
                            [39.64208799, -5.04766294],
                            [39.6419904, -5.04772941],
                            [39.64191408, -5.04778138],
                            [39.64185252, -5.04791821],
                            [39.64189356, -5.04806871],
                            [39.64189434, -5.04806934],
                            [39.64203721, -5.04818501],
                            [39.64222189, -5.04831499],
                            [39.64231082, -5.04843129],
                            [39.64220821, -5.04874597],
                            [39.64226978, -5.04899225],
                            [39.64222189, -5.04917696],
                            [39.64218769, -5.04934115],
                            [39.64222873, -5.0494506],
                            [39.64216717, -5.04951901],
                            [39.64222873, -5.04963531],
                            [39.64227662, -5.04969004],
                            [39.64226293, -5.04986791],
                            [39.64211929, -5.04992948],
                            [39.64201668, -5.05003209],
                            [39.64195512, -5.0502168],
                            [39.64180463, -5.05036046],
                            [39.64188672, -5.05049728],
                            [39.64185936, -5.05063411],
                            [39.64192092, -5.05070252],
                            [39.64196196, -5.0508667],
                            [39.6419004, -5.05102405],
                            [39.64185732, -5.05117216],
                            [39.64172255, -5.05118139],
                            [39.6415447, -5.05129085],
                            [39.64145578, -5.05138662],
                            [39.64142239, -5.05141395],
                            [39.64130529, -5.05150976],
                            [39.64127793, -5.05159185],
                            [39.64136001, -5.05163974],
                            [39.64142753, -5.05164424],
                            [39.64146262, -5.05164658],
                            [39.64148998, -5.05173552],
                            [39.64153786, -5.05184497],
                            [39.64172939, -5.05194759],
                            [39.64169519, -5.05211861],
                            [39.64170887, -5.05237173],
                            [39.64172255, -5.05259749],
                            [39.64163362, -5.05274799],
                            [39.64158574, -5.05293954],
                            [39.64158574, -5.05315161],
                            [39.64158916, -5.05322687],
                            [39.64159258, -5.05330212],
                            [39.64158574, -5.05341157],
                            [39.6415447, -5.05353471],
                            [39.64144893, -5.05370574],
                            [39.6413734, -5.05378386],
                            [39.64127793, -5.05377415],
                            [39.64127793, -5.05368522],
                            [39.64120952, -5.05363049],
                            [39.64114423, -5.05367118],
                            [39.64109513, -5.05370179],
                            [39.64081279, -5.05367153],
                            [39.64082647, -5.05378783],
                            [39.64085383, -5.05391781],
                            [39.6407307, -5.05406831],
                            [39.6406281, -5.05432143],
                            [39.64061467, -5.05438522],
                            [39.64060074, -5.05445141],
                            [39.6407307, -5.05463612],
                            [39.64075806, -5.05487556],
                            [39.64079911, -5.05504659],
                            [39.6406623, -5.05532707],
                            [39.64051181, -5.05555967],
                            [39.64037695, -5.05568416],
                            [39.64033397, -5.05572385],
                            [39.64032955, -5.05609474],
                            [39.64032712, -5.0562985],
                            [39.64004144, -5.05667757],
                            [39.64001522, -5.05671235],
                            [39.63987971, -5.05710764],
                            [39.639891, -5.05723188],
                            [39.63992137, -5.0572514],
                            [39.6400491, -5.05733352],
                            [39.64018461, -5.05746905],
                            [39.64011686, -5.05761587],
                            [39.63985712, -5.05792081],
                            [39.63977807, -5.05803375],
                            [39.63985712, -5.05818057],
                            [39.63993617, -5.0583048],
                            [39.64001522, -5.05844033],
                            [39.64008298, -5.05859845],
                            [39.63998134, -5.05895985],
                            [39.63983454, -5.05928738],
                            [39.63978937, -5.0596149],
                            [39.6397329, -5.05967137],
                            [39.63952677, -5.05966052],
                            [39.63951834, -5.05966008],
                            [39.63917956, -5.05988596],
                            [39.63902146, -5.06013442],
                            [39.63902146, -5.06037164],
                            [39.63902146, -5.06063136],
                            [39.6391118, -5.0607443],
                            [39.63913439, -5.06101535],
                            [39.63894241, -5.06112829],
                            [39.63897629, -5.06134288],
                            [39.63887465, -5.06136547],
                            [39.63870526, -5.06145582],
                            [39.63862621, -5.06176076],
                            [39.63869397, -5.06198663],
                            [39.63855846, -5.06215604],
                            [39.63845682, -5.06234804],
                            [39.63845682, -5.06242145],
                            [39.63845682, -5.06249486],
                            [39.6384907, -5.06266427],
                            [39.63838907, -5.06278851],
                            [39.63829872, -5.06293533],
                            [39.6381858, -5.06303697],
                            [39.6381858, -5.06316121],
                            [39.63814063, -5.06342097],
                            [39.6380277, -5.06361297],
                            [39.63789219, -5.06371461],
                            [39.63788089, -5.06385014],
                            [39.63770021, -5.0642906],
                            [39.63780184, -5.06452778],
                            [39.63763245, -5.06484401],
                            [39.63736592, -5.06508201],
                            [39.63731626, -5.06512636],
                            [39.63736143, -5.06549906],
                            [39.63735997, -5.06550489],
                            [39.63729367, -5.06577011],
                            [39.63719204, -5.06640257],
                            [39.63715816, -5.06675269],
                            [39.63701135, -5.06706892],
                            [39.6370904, -5.06726092],
                            [39.6369436, -5.06750938],
                            [39.63690972, -5.06763362],
                            [39.63697747, -5.06833384],
                            [39.63702264, -5.06858231],
                            [39.63707911, -5.06888725],
                            [39.63700006, -5.06904536],
                            [39.63700006, -5.06931642],
                            [39.6369323, -5.06967782],
                            [39.63686454, -5.069915],
                            [39.6367742, -5.0700957],
                            [39.63672903, -5.07031029],
                            [39.63674032, -5.07073946],
                            [39.63663869, -5.07101051],
                            [39.63652576, -5.07145098],
                            [39.63643542, -5.07182368],
                            [39.63624344, -5.0721625],
                            [39.63604017, -5.07238837],
                            [39.63588207, -5.07255778],
                            [39.63585314, -5.07254814],
                            [39.63571268, -5.07250131],
                            [39.63557717, -5.07238837],
                            [39.63539649, -5.07243355],
                            [39.63535132, -5.07255778],
                            [39.63534002, -5.07287401],
                            [39.63527683, -5.07310048],
                            [39.63520451, -5.07335965],
                            [39.63509158, -5.07356295],
                            [39.6349109, -5.07373236],
                            [39.63479683, -5.07383014],
                            [39.6347528, -5.07386788],
                            [39.63473829, -5.07389844],
                            [39.63453824, -5.07431964],
                            [39.63434627, -5.0745794],
                            [39.63427851, -5.07497469],
                            [39.63409782, -5.07561845],
                            [39.63405265, -5.07599115],
                            [39.63388326, -5.07632997],
                            [39.63380421, -5.07649938],
                            [39.63380421, -5.0768269],
                            [39.63379292, -5.07708666],
                            [39.63365741, -5.07736901],
                            [39.63352189, -5.07762877],
                            [39.63345414, -5.077945],
                            [39.63339767, -5.07818218],
                            [39.63326216, -5.07854358],
                            [39.63317182, -5.07856617],
                            [39.63321699, -5.07876946],
                            [39.63322828, -5.07891628],
                            [39.63311536, -5.0790631],
                            [39.6328895, -5.0792664],
                            [39.6327314, -5.07967298],
                            [39.63262977, -5.08006827],
                            [39.63260718, -5.08032803],
                            [39.63264106, -5.08088143],
                            [39.63264106, -5.08141225],
                            [39.63261847, -5.08181883],
                            [39.63259589, -5.08238353],
                            [39.63276528, -5.08249647],
                            [39.6328782, -5.08292564],
                            [39.63286691, -5.08336611],
                            [39.63302501, -5.08359199],
                            [39.63314923, -5.08382916],
                            [39.63320569, -5.08435998],
                            [39.63336379, -5.08446162],
                            [39.63356706, -5.08451809],
                            [39.6336461, -5.08485691],
                            [39.63369127, -5.08515055],
                            [39.63369127, -5.08539902],
                            [39.63388325, -5.08559102],
                            [39.63413169, -5.08586207],
                            [39.63422203, -5.0860089],
                            [39.63427849, -5.08635901],
                            [39.63427849, -5.0871157],
                            [39.63425591, -5.08743194],
                            [39.63414298, -5.08770299],
                            [39.63399617, -5.08807569],
                            [39.63390583, -5.08832416],
                            [39.633951, -5.08857263],
                            [39.6338042, -5.08890015],
                            [39.6338042, -5.08912603],
                            [39.63370256, -5.08951002],
                            [39.63370256, -5.08970202],
                            [39.63372515, -5.08976979],
                            [39.63380419, -5.08989402],
                            [39.63374773, -5.09018766],
                            [39.63396229, -5.09015378],
                            [39.63398101, -5.09005421],
                            [39.63418815, -5.09005214],
                            [39.63426719, -5.09033449],
                            [39.63431237, -5.09075236],
                            [39.63448176, -5.09106859],
                            [39.63449145, -5.09108521],
                            [39.6345608, -5.09120412],
                            [39.63469632, -5.09111377],
                            [39.63471876, -5.09109974],
                            [39.634877, -5.09100083],
                            [39.63498993, -5.09077495],
                            [39.63489959, -5.09059425],
                            [39.63486571, -5.09033449],
                            [39.63489808, -5.09013276],
                            [39.63506898, -5.08981496],
                            [39.63496734, -5.08952132],
                            [39.63480925, -5.08917121],
                            [39.63461727, -5.08888886],
                            [39.63451564, -5.08875333],
                            [39.63464607, -5.08850113],
                            [39.63468503, -5.08842581],
                            [39.6348883, -5.08832416],
                            [39.6351417, -5.08826568],
                            [39.63518191, -5.0882564],
                        ],
                        [
                            [39.20373006, -6.03841075],
                            [39.20382979, -6.03916593],
                            [39.20394376, -6.03971451],
                            [39.20395088, -6.04016334],
                            [39.20430706, -6.04045544],
                            [39.20437117, -6.0406478],
                            [39.20425007, -6.04076891],
                            [39.20403637, -6.04108238],
                            [39.2039895, -6.04124133],
                            [39.20387252, -6.04163808],
                            [39.20380841, -6.04212966],
                            [39.20361607, -6.04256425],
                            [39.20325277, -6.04269961],
                            [39.2031103, -6.04253575],
                            [39.2030177, -6.04248588],
                            [39.20278262, -6.04255],
                            [39.20263302, -6.04259274],
                            [39.20268289, -6.04287772],
                            [39.20310318, -6.04329093],
                            [39.20310318, -6.04366674],
                            [39.20310318, -6.04382526],
                            [39.20293933, -6.04441658],
                            [39.20278262, -6.0447158],
                            [39.2026259, -6.04463031],
                            [39.20241931, -6.0447443],
                            [39.202113, -6.0449509],
                            [39.20189217, -6.04539261],
                            [39.20198478, -6.04566334],
                            [39.20214149, -6.04608367],
                            [39.20202039, -6.04631878],
                            [39.20200561, -6.04636313],
                            [39.20192066, -6.046618],
                            [39.20192583, -6.04693334],
                            [39.20192779, -6.04705259],
                            [39.20201327, -6.04730906],
                            [39.20202039, -6.04742661],
                            [39.20202751, -6.04754417],
                            [39.20192066, -6.04765816],
                            [39.20190641, -6.04781489],
                            [39.20210587, -6.04829222],
                            [39.20204176, -6.04861994],
                            [39.20202154, -6.04866157],
                            [39.20192066, -6.0488693],
                            [39.20188504, -6.04926826],
                            [39.20187743, -6.049392],
                            [39.20186699, -6.04956167],
                            [39.20185655, -6.04973134],
                            [39.20201326, -6.05020867],
                            [39.2019286, -6.05042036],
                            [39.20191353, -6.05045803],
                            [39.20215898, -6.05095624],
                            [39.20216744, -6.05096921],
                            [39.20220289, -6.05102272],
                            [39.20220738, -6.05105113],
                            [39.20221272, -6.05108497],
                            [39.20215573, -6.0512702],
                            [39.20184229, -6.05187755],
                            [39.20170695, -6.05280194],
                            [39.20199901, -6.05329352],
                            [39.20231957, -6.05357137],
                            [39.20265438, -6.05360699],
                            [39.20269739, -6.05371829],
                            [39.20295211, -6.05437739],
                            [39.20318508, -6.05498021],
                            [39.2033418, -6.05546467],
                            [39.20366458, -6.05625369],
                            [39.20416323, -6.05752183],
                            [39.20431994, -6.05856198],
                            [39.20389253, -6.05908918],
                            [39.20442586, -6.05994509],
                            [39.20450515, -6.06007235],
                            [39.20473311, -6.06078478],
                            [39.20560218, -6.06203867],
                            [39.20588712, -6.06273686],
                            [39.20574465, -6.06317857],
                            [39.20610082, -6.06393375],
                            [39.20725483, -6.06575046],
                            [39.20740386, -6.06608362],
                            [39.20749703, -6.06629191],
                            [39.20789595, -6.0669901],
                            [39.2080028, -6.06776665],
                            [39.2083946, -6.06818699],
                            [39.20864392, -6.06883531],
                            [39.20937765, -6.0697686],
                            [39.20941184, -6.06982643],
                            [39.20986205, -6.0705879],
                            [39.21021822, -6.07133596],
                            [39.21023327, -6.07137182],
                            [39.21064563, -6.07235474],
                            [39.2107094, -6.07290263],
                            [39.21078755, -6.07357401],
                            [39.21083835, -6.07377005],
                            [39.21090063, -6.07401037],
                            [39.21102332, -6.07458297],
                            [39.2110716, -6.0748083],
                            [39.21137791, -6.07596957],
                            [39.21149728, -6.07629132],
                            [39.21179819, -6.07710234],
                            [39.21200039, -6.07778123],
                            [39.21224242, -6.07859386],
                            [39.21229684, -6.07877657],
                            [39.21234037, -6.07895732],
                            [39.21235577, -6.07902128],
                            [39.21275164, -6.08066507],
                            [39.2128596, -6.08111336],
                            [39.21295103, -6.08156795],
                            [39.21311662, -6.08239118],
                            [39.21328493, -6.08322794],
                            [39.21335112, -6.08355701],
                            [39.21381142, -6.08588831],
                            [39.21390675, -6.08637113],
                            [39.21395326, -6.08669398],
                            [39.21402785, -6.08721181],
                            [39.21402209, -6.0873861],
                            [39.21399935, -6.08807385],
                            [39.21396654, -6.08847249],
                            [39.21396816, -6.08847846],
                            [39.21396979, -6.08848442],
                            [39.21397304, -6.08849635],
                            [39.2139788, -6.08851745],
                            [39.21404992, -6.08877826],
                            [39.21406292, -6.08882594],
                            [39.21410349, -6.0890862],
                            [39.21417923, -6.08957199],
                            [39.21422486, -6.08986473],
                            [39.21444788, -6.09129529],
                            [39.21450217, -6.09164352],
                            [39.21452877, -6.09181416],
                            [39.21452877, -6.09202301],
                            [39.21449038, -6.09225334],
                            [39.21441632, -6.09269777],
                            [39.21440556, -6.09288075],
                            [39.21435206, -6.09379024],
                            [39.2143478, -6.09389044],
                            [39.21431993, -6.09454533],
                            [39.21430387, -6.09485058],
                            [39.21429999, -6.09492419],
                            [39.2142878, -6.09515582],
                            [39.21428137, -6.09542589],
                            [39.21427174, -6.09583058],
                            [39.21426707, -6.09606416],
                            [39.21425567, -6.09663387],
                            [39.21424541, -6.09716779],
                            [39.2142428, -6.09730329],
                            [39.21423961, -6.09746929],
                            [39.21409503, -6.09812798],
                            [39.21405622, -6.09849027],
                            [39.21404684, -6.09857782],
                            [39.21405246, -6.09869027],
                            [39.2140629, -6.09889913],
                            [39.21404299, -6.09902716],
                            [39.21402308, -6.09915518],
                            [39.21400668, -6.09926061],
                            [39.21391833, -6.09937307],
                            [39.21382998, -6.09948553],
                            [39.21379605, -6.09956253],
                            [39.21372556, -6.0997225],
                            [39.21364524, -6.09998357],
                            [39.21361825, -6.10007725],
                            [39.21360851, -6.10011106],
                            [39.21357697, -6.10022054],
                            [39.21348058, -6.10045349],
                            [39.21343239, -6.10071857],
                            [39.21339424, -6.1011664],
                            [39.21336264, -6.10153734],
                            [39.21335609, -6.10161423],
                            [39.2133842, -6.10183112],
                            [39.21339625, -6.10201588],
                            [39.2133842, -6.10218858],
                            [39.2133842, -6.10235326],
                            [39.21337664, -6.10269093],
                            [39.21337296, -6.10285529],
                            [39.21337215, -6.10289146],
                            [39.21327704, -6.10384489],
                            [39.21300524, -6.10528095],
                            [39.21291189, -6.1057742],
                            [39.21286689, -6.10590733],
                            [39.21239737, -6.10729656],
                            [39.21191633, -6.10871984],
                            [39.21188082, -6.10900042],
                            [39.21173507, -6.11015185],
                            [39.21165845, -6.11075722],
                            [39.21162637, -6.11101063],
                            [39.21150786, -6.11194691],
                            [39.21144395, -6.11245183],
                            [39.2114192, -6.11264736],
                            [39.21139853, -6.11303322],
                            [39.21094381, -6.11367403],
                            [39.21066827, -6.11398277],
                            [39.21042552, -6.11425477],
                            [39.21031728, -6.11473663],
                            [39.21014504, -6.11550331],
                            [39.21011313, -6.11566149],
                            [39.21001855, -6.11613033],
                            [39.21023853, -6.11723036],
                            [39.21054563, -6.11779345],
                            [39.2106345, -6.11795639],
                            [39.21083553, -6.11825181],
                            [39.21144293, -6.11914443],
                            [39.21189939, -6.11988695],
                            [39.21232558, -6.12065984],
                            [39.21275177, -6.12143273],
                            [39.21285348, -6.12161346],
                            [39.2130488, -6.12196052],
                            [39.21314225, -6.12211391],
                            [39.21355475, -6.12279104],
                            [39.21363725, -6.12303855],
                            [39.2136455, -6.1230908],
                            [39.21365375, -6.12313205],
                            [39.21368959, -6.12363237],
                            [39.213702, -6.12380549],
                            [39.21371497, -6.12398282],
                            [39.21375801, -6.12457111],
                            [39.21376611, -6.12468178],
                            [39.21383452, -6.12510533],
                            [39.2139157, -6.12560795],
                            [39.21392281, -6.12577041],
                            [39.21393995, -6.12616217],
                            [39.21396419, -6.1267164],
                            [39.2139648, -6.12675579],
                            [39.21395211, -6.12688799],
                            [39.21389977, -6.12743295],
                            [39.21387367, -6.12772321],
                            [39.21386438, -6.12780147],
                            [39.2138479, -6.12797307],
                            [39.21384349, -6.12801248],
                            [39.21378011, -6.1282482],
                            [39.21361651, -6.12885665],
                            [39.21356825, -6.12900464],
                            [39.21318909, -6.13016753],
                            [39.2127904, -6.13167155],
                            [39.21278305, -6.13169927],
                            [39.21225015, -6.13260359],
                            [39.21144382, -6.13397194],
                            [39.21031117, -6.1357744],
                            [39.21022128, -6.13577021],
                            [39.21013567, -6.13587723],
                            [39.20953638, -6.13666495],
                            [39.2095021, -6.13673027],
                            [39.20917681, -6.13734992],
                            [39.20908446, -6.13752571],
                            [39.20864602, -6.13836025],
                            [39.20854071, -6.13914676],
                            [39.20852355, -6.13927493],
                            [39.20801265, -6.13996847],
                            [39.20796112, -6.14003843],
                            [39.20735945, -6.14015165],
                            [39.20696803, -6.14060353],
                            [39.20696803, -6.14094601],
                            [39.20704796, -6.14101796],
                            [39.2074817, -6.14140837],
                            [39.20748574, -6.14193695],
                            [39.20747259, -6.14195974],
                            [39.20720774, -6.1424187],
                            [39.20679608, -6.14265806],
                            [39.20664198, -6.14252161],
                            [39.20586577, -6.14183433],
                            [39.20456448, -6.14190283],
                            [39.20401656, -6.14266485],
                            [39.20399944, -6.14312721],
                            [39.20375972, -6.14370943],
                            [39.20371692, -6.14399199],
                            [39.20328886, -6.14420604],
                            [39.20293785, -6.14462558],
                            [39.20241562, -6.14483963],
                            [39.2018677, -6.14520781],
                            [39.20149957, -6.14577291],
                            [39.20117425, -6.14652637],
                            [39.20072906, -6.1471086],
                            [39.1995733, -6.1489837],
                            [39.19963323, -6.14951884],
                            [39.20041514, -6.15057713],
                            [39.20059052, -6.15081449],
                            [39.20084295, -6.15126883],
                            [39.20114925, -6.1518201],
                            [39.20124645, -6.15199503],
                            [39.20168791, -6.15278957],
                            [39.20171796, -6.1531208],
                            [39.201731, -6.15326457],
                            [39.20158442, -6.15341754],
                            [39.20144604, -6.15356195],
                            [39.20122224, -6.15379551],
                            [39.20098748, -6.15404051],
                            [39.20065343, -6.15438911],
                            [39.20051547, -6.15453309],
                            [39.20008492, -6.15483848],
                            [39.1998889, -6.15497766],
                            [39.19961264, -6.15484623],
                            [39.19930038, -6.15476899],
                            [39.19924456, -6.15475518],
                            [39.19917606, -6.15473823],
                            [39.19913475, -6.15446142],
                            [39.19859771, -6.15428377],
                            [39.19753605, -6.15418874],
                            [39.19730471, -6.15399043],
                            [39.19630972, -6.15389044],
                            [39.19630971, -6.15389047],
                            [39.19614038, -6.15387859],
                            [39.19592942, -6.15385481],
                            [39.19589537, -6.15383553],
                            [39.19592347, -6.15364383],
                            [39.19566497, -6.15361114],
                            [39.1955491, -6.15361114],
                            [39.19550155, -6.15379835],
                            [39.19535002, -6.15376566],
                            [39.19523711, -6.15371515],
                            [39.19497564, -6.15317134],
                            [39.19461909, -6.15259485],
                            [39.19415819, -6.15285142],
                            [39.19402264, -6.15292247],
                            [39.19383628, -6.15275413],
                            [39.19252935, -6.15372684],
                            [39.19221022, -6.15334688],
                            [39.19224061, -6.15249576],
                            [39.19122242, -6.15243496],
                            [39.19103246, -6.15444877],
                            [39.19069813, -6.15446397],
                            [39.19069053, -6.15465395],
                            [39.19107805, -6.15465395],
                            [39.19120722, -6.15486673],
                            [39.19119202, -6.15501112],
                            [39.19119202, -6.15539109],
                            [39.19103245, -6.15571786],
                            [39.19090328, -6.15602183],
                            [39.19051774, -6.15641494],
                            [39.18980231, -6.15731259],
                            [39.18942183, -6.15752399],
                            [39.18922671, -6.15764107],
                            [39.18889501, -6.15779393],
                            [39.18875843, -6.15785572],
                            [39.1887064, -6.15785898],
                            [39.18860884, -6.15773864],
                            [39.18850803, -6.15780043],
                            [39.18853404, -6.15785247],
                            [39.18850153, -6.15790126],
                            [39.18834275, -6.1579863],
                            [39.18831291, -6.15795004],
                            [39.18818283, -6.15801183],
                            [39.18819584, -6.15806062],
                            [39.18812105, -6.15808339],
                            [39.18798771, -6.15813217],
                            [39.18791617, -6.1581712],
                            [39.18782512, -6.15820047],
                            [39.1876365, -6.1583078],
                            [39.18745764, -6.1580411],
                            [39.18734057, -6.15811916],
                            [39.18750317, -6.15839886],
                            [39.1871292, -6.15864604],
                            [39.18691131, -6.15879239],
                            [39.18680075, -6.15892574],
                            [39.18665766, -6.15905258],
                            [39.18652108, -6.1591534],
                            [39.18637474, -6.15927373],
                            [39.1859715, -6.15953392],
                            [39.18573085, -6.15969979],
                            [39.1853146, -6.16001852],
                            [39.18504794, -6.16024293],
                            [39.18490532, -6.16040933],
                            [39.18485282, -6.16047059],
                            [39.18480299, -6.1605662],
                            [39.18473263, -6.16071414],
                            [39.18470557, -6.16093245],
                            [39.18472, -6.16115977],
                            [39.18492024, -6.16171727],
                            [39.18504472, -6.16197887],
                            [39.18525398, -6.1622495],
                            [39.1855336, -6.16252374],
                            [39.18582404, -6.16275467],
                            [39.18604232, -6.16288097],
                            [39.18631291, -6.16309747],
                            [39.18637244, -6.16317505],
                            [39.18657088, -6.16336268],
                            [39.1866683, -6.16347996],
                            [39.18672783, -6.16365135],
                            [39.18677473, -6.16390033],
                            [39.18697497, -6.16432071],
                            [39.18692085, -6.16438566],
                            [39.18705435, -6.16469598],
                            [39.18714094, -6.16467433],
                            [39.18717341, -6.16474649],
                            [39.18740432, -6.16516506],
                            [39.18764244, -6.16563776],
                            [39.18787132, -6.16587345],
                            [39.18795633, -6.16596251],
                            [39.18830631, -6.16632696],
                            [39.18861298, -6.16665893],
                            [39.18894491, -6.16695482],
                            [39.18923027, -6.16725005],
                            [39.18936344, -6.16738782],
                            [39.18963764, -6.16770897],
                            [39.18986133, -6.16797959],
                            [39.19011389, -6.1682863],
                            [39.19032315, -6.16853167],
                            [39.19049994, -6.16870127],
                            [39.1906079, -6.16884917],
                            [39.19085007, -6.16914216],
                            [39.19103756, -6.16928279],
                            [39.1915141, -6.16995471],
                            [39.1919633, -6.17044302],
                            [39.19261561, -6.17093133],
                            [39.19268386, -6.17098993],
                            [39.19308433, -6.1713337],
                            [39.19331284, -6.17159153],
                            [39.19383794, -6.17203048],
                            [39.19388591, -6.172079],
                            [39.19393388, -6.17212753],
                            [39.19397602, -6.17217015],
                            [39.19402668, -6.17222141],
                            [39.19404771, -6.17224267],
                            [39.19408042, -6.17227577],
                            [39.1942536, -6.17241649],
                            [39.19437448, -6.17256708],
                            [39.19444658, -6.17260525],
                            [39.19458867, -6.17281735],
                            [39.19464455, -6.1728738],
                            [39.19470318, -6.17290006],
                            [39.19480549, -6.17300358],
                            [39.19490561, -6.17310489],
                            [39.1950932, -6.1732947],
                            [39.19515065, -6.1733094],
                            [39.19518882, -6.17339],
                            [39.1952885, -6.1734706],
                            [39.19564689, -6.17377177],
                            [39.19595439, -6.17404537],
                            [39.19620029, -6.17429579],
                            [39.19625696, -6.17435351],
                            [39.19641668, -6.17451617],
                            [39.19659111, -6.17469381],
                            [39.1966415, -6.17471771],
                            [39.1967853, -6.17486438],
                            [39.19682388, -6.17492344],
                            [39.19686417, -6.17493405],
                            [39.19692498, -6.17499611],
                            [39.19702747, -6.17507191],
                            [39.19711866, -6.17513129],
                            [39.19718999, -6.17520436],
                            [39.19719928, -6.17521387],
                            [39.1972565, -6.17522462],
                            [39.19725226, -6.17525431],
                            [39.19723954, -6.17529673],
                            [39.19728619, -6.17531794],
                            [39.19733921, -6.17540702],
                            [39.1974198, -6.1754664],
                            [39.19744599, -6.17549319],
                            [39.19750674, -6.17553639],
                            [39.19753431, -6.17560851],
                            [39.19760642, -6.17561487],
                            [39.19764941, -6.17565895],
                            [39.1977133, -6.17572444],
                            [39.19783466, -6.17584885],
                            [39.19791816, -6.17591392],
                            [39.19797048, -6.17596762],
                            [39.19813023, -6.17608784],
                            [39.19825747, -6.17618752],
                            [39.19829705, -6.17622833],
                            [39.19834654, -6.1762257],
                            [39.19834654, -6.17629145],
                            [39.19838895, -6.17629145],
                            [39.19843137, -6.17629993],
                            [39.19845012, -6.17631935],
                            [39.19855016, -6.17642291],
                            [39.19863495, -6.17647385],
                            [39.19866889, -6.176529],
                            [39.19870706, -6.1765905],
                            [39.19880885, -6.17666686],
                            [39.19888096, -6.17670716],
                            [39.19893397, -6.17673473],
                            [39.19895043, -6.17675185],
                            [39.19897048, -6.17677272],
                            [39.19931333, -6.17712954],
                            [39.19939538, -6.17721493],
                            [39.19959382, -6.17742145],
                            [39.19973135, -6.17753432],
                            [39.19983315, -6.17767218],
                            [39.19998584, -6.17786307],
                            [39.20005158, -6.17797336],
                            [39.20006006, -6.17804971],
                            [39.20010672, -6.17807516],
                            [39.20014277, -6.17808789],
                            [39.20021911, -6.17819606],
                            [39.20026153, -6.17829362],
                            [39.20028486, -6.17834452],
                            [39.20029423, -6.17835415],
                            [39.20031879, -6.17840815],
                            [39.20034931, -6.17845981],
                            [39.20042906, -6.17859479],
                            [39.20048632, -6.17870932],
                            [39.20051601, -6.17871993],
                            [39.20054146, -6.17881325],
                            [39.20062205, -6.17881961],
                            [39.20068143, -6.17890021],
                            [39.20068355, -6.1789808],
                            [39.20069203, -6.1790105],
                            [39.20064113, -6.17902746],
                            [39.20062841, -6.17908897],
                            [39.20066131, -6.1791218],
                            [39.20069627, -6.17913351],
                            [39.20072703, -6.17916425],
                            [39.20073868, -6.17921623],
                            [39.20074929, -6.17924804],
                            [39.20078534, -6.17924592],
                            [39.200814, -6.17927452],
                            [39.20084585, -6.1793063],
                            [39.20088513, -6.17934549],
                            [39.20090622, -6.17942196],
                            [39.20092106, -6.17946226],
                            [39.2009762, -6.17948771],
                            [39.20099431, -6.1795057],
                            [39.20100589, -6.17954285],
                            [39.20103558, -6.17961072],
                            [39.20107072, -6.17964548],
                            [39.20113796, -6.17971199],
                            [39.20112253, -6.17975707],
                            [39.20113525, -6.17979949],
                            [39.20118191, -6.17980585],
                            [39.20122856, -6.17982706],
                            [39.20125825, -6.17988433],
                            [39.20127946, -6.17988433],
                            [39.2012837, -6.17992462],
                            [39.20134096, -6.17993947],
                            [39.20137507, -6.17997314],
                            [39.20140246, -6.18002219],
                            [39.20143981, -6.18005899],
                            [39.20147244, -6.18011975],
                            [39.20151308, -6.1801597],
                            [39.20155303, -6.18015581],
                            [39.20156151, -6.1802258],
                            [39.20158484, -6.18026822],
                            [39.20161029, -6.18026609],
                            [39.20162089, -6.180317],
                            [39.2016434, -6.18033904],
                            [39.20168876, -6.18034669],
                            [39.20171116, -6.18036869],
                            [39.2017036, -6.18040184],
                            [39.20173248, -6.1804301],
                            [39.20173753, -6.18046546],
                            [39.20175356, -6.18048112],
                            [39.20177194, -6.18049906],
                            [39.2017933, -6.18051992],
                            [39.20183296, -6.18058636],
                            [39.20184357, -6.18061605],
                            [39.20187326, -6.18066271],
                            [39.20189271, -6.18068161],
                            [39.20192919, -6.18071704],
                            [39.20205988, -6.18086208],
                            [39.20209593, -6.18093419],
                            [39.20212562, -6.18092783],
                            [39.20213198, -6.18097873],
                            [39.20216203, -6.18100776],
                            [39.20217015, -6.18104448],
                            [39.20219772, -6.18104448],
                            [39.20221176, -6.18105804],
                            [39.20224438, -6.1811272],
                            [39.20226346, -6.18117174],
                            [39.20228955, -6.18119679],
                            [39.20234193, -6.18121203],
                            [39.20235908, -6.18122856],
                            [39.20240131, -6.18130324],
                            [39.20241403, -6.18135626],
                            [39.20241191, -6.18139444],
                            [39.202431, -6.18143685],
                            [39.20247104, -6.18147497],
                            [39.20248826, -6.18151321],
                            [39.20253703, -6.18152805],
                            [39.20255562, -6.18154576],
                            [39.2025646, -6.18158744],
                            [39.20259598, -6.18161725],
                            [39.20262018, -6.18164023],
                            [39.20265791, -6.18163622],
                            [39.20268124, -6.18169137],
                            [39.20265155, -6.18173591],
                            [39.20266003, -6.1817762],
                            [39.20267439, -6.18178972],
                            [39.20271093, -6.18178681],
                            [39.20274698, -6.18179105],
                            [39.20277298, -6.18181568],
                            [39.20279152, -6.18186316],
                            [39.20282333, -6.18192891],
                            [39.20287253, -6.18197525],
                            [39.20292247, -6.18202227],
                            [39.20295316, -6.18205117],
                            [39.20292512, -6.18209223],
                            [39.20295569, -6.18212083],
                            [39.20299086, -6.18217706],
                            [39.20300174, -6.18218722],
                            [39.20298874, -6.18224917],
                            [39.20298026, -6.18230644],
                            [39.20300359, -6.18235098],
                            [39.20304812, -6.18237007],
                            [39.20308629, -6.18235098],
                            [39.2031181, -6.18233825],
                            [39.20313892, -6.18235761],
                            [39.20317536, -6.18241885],
                            [39.20318809, -6.18248036],
                            [39.20322414, -6.1824846],
                            [39.20322838, -6.18252278],
                            [39.20321778, -6.18255671],
                            [39.20323764, -6.18257496],
                            [39.20325171, -6.18256732],
                            [39.20326867, -6.18262034],
                            [39.20329624, -6.18262458],
                            [39.20330473, -6.18266064],
                            [39.20330472, -6.18270942],
                            [39.20332475, -6.18272768],
                            [39.20334287, -6.18274421],
                            [39.20336017, -6.18275999],
                            [39.20337723, -6.18277555],
                            [39.20338743, -6.18284304],
                            [39.20341839, -6.18287111],
                            [39.20342348, -6.18290879],
                            [39.20344827, -6.18293119],
                            [39.20347154, -6.18295222],
                            [39.20346378, -6.18299575],
                            [39.20347862, -6.18303816],
                            [39.20351714, -6.1830727],
                            [39.20357405, -6.18307634],
                            [39.2035995, -6.18311664],
                            [39.20361223, -6.1831527],
                            [39.2036378, -6.18317564],
                            [39.20365252, -6.18320784],
                            [39.20367161, -6.18326086],
                            [39.20372038, -6.18332661],
                            [39.2037225, -6.18334782],
                            [39.20372462, -6.18336903],
                            [39.20375005, -6.18339157],
                            [39.2037734, -6.18347296],
                            [39.20381369, -6.18348144],
                            [39.20383914, -6.18353234],
                            [39.20385186, -6.18357688],
                            [39.20387731, -6.18357688],
                            [39.20389142, -6.18358929],
                            [39.20386883, -6.18361506],
                            [39.2038945, -6.18363753],
                            [39.2038964, -6.18365748],
                            [39.20390962, -6.18366902],
                            [39.20391973, -6.18369778],
                            [39.203948, -6.18372242],
                            [39.20396426, -6.18378049],
                            [39.20397274, -6.18381867],
                            [39.2040349, -6.18387239],
                            [39.20406393, -6.18396077],
                            [39.20409029, -6.18398339],
                            [39.2040915, -6.18401592],
                            [39.20411318, -6.18403445],
                            [39.20412967, -6.18409227],
                            [39.20416361, -6.18409439],
                            [39.20416785, -6.18413893],
                            [39.20418693, -6.18417923],
                            [39.20422029, -6.18420748],
                            [39.20422935, -6.18423225],
                            [39.20424843, -6.18428528],
                            [39.2042654, -6.18434254],
                            [39.20429642, -6.18436852],
                            [39.20432162, -6.18438962],
                            [39.20435447, -6.1844698],
                            [39.20437355, -6.18451858],
                            [39.20437568, -6.184561],
                            [39.20441809, -6.18457585],
                            [39.20445202, -6.18462887],
                            [39.20449868, -6.18470947],
                            [39.20450504, -6.18477734],
                            [39.204645, -6.18501488],
                            [39.20471923, -6.18515911],
                            [39.20474043, -6.18522485],
                            [39.204768, -6.1852906],
                            [39.20480193, -6.18529909],
                            [39.20481466, -6.18533514],
                            [39.20483587, -6.18541362],
                            [39.20486768, -6.18549209],
                            [39.20489524, -6.18551331],
                            [39.2049185, -6.18553121],
                            [39.20492281, -6.18555996],
                            [39.20493586, -6.18556997],
                            [39.20497339, -6.18559875],
                            [39.20497371, -6.18566813],
                            [39.20497371, -6.1856957],
                            [39.20499704, -6.18573812],
                            [39.20503093, -6.18576368],
                            [39.20504345, -6.18577312],
                            [39.20506816, -6.18579175],
                            [39.20508187, -6.18583356],
                            [39.20509883, -6.18589931],
                            [39.20513383, -6.18592539],
                            [39.20513276, -6.18597355],
                            [39.20514549, -6.18601172],
                            [39.2051773, -6.18606263],
                            [39.2051998, -6.186087],
                            [39.20522819, -6.18611777],
                            [39.20524728, -6.18617504],
                            [39.20527061, -6.18623018],
                            [39.20527909, -6.1862726],
                            [39.20529373, -6.18628312],
                            [39.20529818, -6.18631502],
                            [39.20530941, -6.18632306],
                            [39.20530454, -6.18633411],
                            [39.20531514, -6.18635956],
                            [39.20531938, -6.18638501],
                            [39.20534873, -6.18640583],
                            [39.20536802, -6.18641952],
                            [39.2053724, -6.18645076],
                            [39.20534695, -6.18646985],
                            [39.20539289, -6.18650211],
                            [39.2054233, -6.18657165],
                            [39.20544875, -6.18661831],
                            [39.20544238, -6.1866883],
                            [39.20545299, -6.18671163],
                            [39.20546783, -6.18671588],
                            [39.205506, -6.18675829],
                            [39.20552509, -6.1868495],
                            [39.20556963, -6.18695554],
                            [39.2056078, -6.18702553],
                            [39.20563537, -6.18714218],
                            [39.20565021, -6.18720581],
                            [39.20570006, -6.18723784],
                            [39.20569899, -6.18728853],
                            [39.20570111, -6.18735004],
                            [39.20572586, -6.18736562],
                            [39.20573716, -6.18738609],
                            [39.20576049, -6.18746881],
                            [39.2057923, -6.18754092],
                            [39.2058135, -6.18759607],
                            [39.20584956, -6.18767666],
                            [39.20588985, -6.18775514],
                            [39.20589409, -6.18783997],
                            [39.20591106, -6.1879036],
                            [39.20592886, -6.18791393],
                            [39.20593438, -6.18801601],
                            [39.20595983, -6.18810085],
                            [39.20599164, -6.18819629],
                            [39.20601921, -6.18829598],
                            [39.20605314, -6.18835748],
                            [39.20609556, -6.18850595],
                            [39.20610404, -6.18855261],
                            [39.20610828, -6.18860139],
                            [39.20614009, -6.18865442],
                            [39.20615494, -6.18871804],
                            [39.20616766, -6.18875198],
                            [39.20616766, -6.188805],
                            [39.2061613, -6.18884106],
                            [39.20618038, -6.18887287],
                            [39.20619575, -6.18888026],
                            [39.20623764, -6.18895771],
                            [39.20625673, -6.18903194],
                            [39.20626733, -6.18911466],
                            [39.20627157, -6.18918041],
                            [39.20630126, -6.18925888],
                            [39.20631399, -6.18933099],
                            [39.20636064, -6.18944977],
                            [39.20637973, -6.18954945],
                            [39.20639457, -6.18961732],
                            [39.20637125, -6.18967035],
                            [39.2063841, -6.1896754],
                            [39.20639245, -6.18967247],
                            [39.20639881, -6.1897064],
                            [39.2064073, -6.1897573],
                            [39.20643062, -6.189787],
                            [39.2064285, -6.1898782],
                            [39.20644971, -6.1899291],
                            [39.20645183, -6.18996516],
                            [39.20646031, -6.19002666],
                            [39.20646031, -6.19009877],
                            [39.20646668, -6.19016877],
                            [39.20648364, -6.19024936],
                            [39.20649424, -6.19031511],
                            [39.20650697, -6.19035965],
                            [39.20653242, -6.19051024],
                            [39.20654938, -6.19058447],
                            [39.20657695, -6.19061416],
                            [39.20661724, -6.19065446],
                            [39.20661937, -6.19072657],
                            [39.20655999, -6.19072869],
                            [39.20655787, -6.19073294],
                            [39.20654938, -6.19079232],
                            [39.20656847, -6.19087716],
                            [39.20657483, -6.19091958],
                            [39.20656211, -6.19095351],
                            [39.2065605, -6.19103822],
                            [39.20657817, -6.1914252],
                            [39.20663409, -6.19265015],
                            [39.20664611, -6.19535528],
                            [39.20664881, -6.19596235],
                            [39.20598644, -6.19859739],
                            [39.20538321, -6.19958172],
                            [39.20486777, -6.20042278],
                            [39.20354304, -6.20089384],
                            [39.20351963, -6.20091381],
                            [39.20288189, -6.20145756],
                            [39.20248343, -6.20179729],
                            [39.20173257, -6.20182126],
                            [39.20107021, -6.20168877],
                            [39.20054031, -6.20313141],
                            [39.20045387, -6.20400255],
                            [39.20034896, -6.20505985],
                            [39.20054592, -6.20667513],
                            [39.20056975, -6.20687052],
                            [39.20056596, -6.2075865],
                            [39.20056085, -6.20855248],
                            [39.20055917, -6.20886915],
                            [39.20055502, -6.20965277],
                            [39.20079883, -6.21017279],
                            [39.20149656, -6.21166097],
                            [39.20156624, -6.21176358],
                            [39.20203059, -6.21244729],
                            [39.20239051, -6.21297722],
                            [39.20239252, -6.21303347],
                            [39.20240512, -6.21338648],
                            [39.20244807, -6.21458912],
                            [39.20245183, -6.21469463],
                            [39.20245258, -6.21560662],
                            [39.20031579, -6.21653868],
                            [39.19950793, -6.21689107],
                            [39.19885445, -6.21812712],
                            [39.19893576, -6.21819489],
                            [39.20004775, -6.21912165],
                            [39.20190873, -6.21983203],
                            [39.20194169, -6.21988609],
                            [39.20226387, -6.22041454],
                            [39.20524712, -6.22221891],
                            [39.20603613, -6.22264997],
                            [39.20717936, -6.22327455],
                            [39.20762662, -6.2235189],
                            [39.20812262, -6.22403769],
                            [39.20846333, -6.22439404],
                            [39.20855711, -6.22449212],
                            [39.20906015, -6.22492605],
                            [39.20912534, -6.22498229],
                            [39.20912534, -6.22558611],
                            [39.20894777, -6.22579212],
                            [39.20889325, -6.22628622],
                            [39.20883412, -6.22682216],
                            [39.20828719, -6.22767462],
                            [39.20838116, -6.22794774],
                            [39.20851448, -6.22833527],
                            [39.20854464, -6.22840758],
                            [39.20899748, -6.22949319],
                            [39.20886469, -6.22972423],
                            [39.20855989, -6.23025455],
                            [39.20854969, -6.23027231],
                            [39.20847896, -6.23039536],
                            [39.20851448, -6.23111995],
                            [39.20920346, -6.23228497],
                            [39.20977594, -6.23289475],
                            [39.20998566, -6.23311814],
                            [39.21007713, -6.23321557],
                            [39.21014064, -6.23328412],
                            [39.21034348, -6.23350303],
                            [39.21098556, -6.23419601],
                            [39.21106444, -6.23428114],
                            [39.21131676, -6.23465854],
                            [39.21186708, -6.23548168],
                            [39.21186691, -6.23550342],
                            [39.21186453, -6.23580798],
                            [39.21185997, -6.23639096],
                            [39.21192708, -6.23667788],
                            [39.21203465, -6.23713781],
                            [39.21210041, -6.23741896],
                            [39.21214409, -6.23760571],
                            [39.21213933, -6.23769093],
                            [39.21207306, -6.23887728],
                            [39.21180314, -6.24001389],
                            [39.21183756, -6.24010902],
                            [39.2121938, -6.24109366],
                            [39.21200987, -6.24148686],
                            [39.2118091, -6.24191605],
                            [39.21174956, -6.24204333],
                            [39.21162556, -6.24230841],
                            [39.21165103, -6.24258166],
                            [39.2117037, -6.24314665],
                            [39.21185099, -6.24377164],
                            [39.21193809, -6.24414118],
                            [39.2119185, -6.24478128],
                            [39.21191678, -6.24483735],
                            [39.2114835, -6.24488708],
                            [39.21150481, -6.24520675],
                            [39.21158771, -6.24551287],
                            [39.21168948, -6.24588871],
                            [39.21156873, -6.24631494],
                            [39.21219379, -6.24728816],
                            [39.21261287, -6.2477499],
                            [39.21281885, -6.24824717],
                            [39.21337999, -6.24844608],
                            [39.21378486, -6.2488652],
                            [39.21394112, -6.24927722],
                            [39.21384168, -6.24966082],
                            [39.21414711, -6.25004443],
                            [39.21431758, -6.25019716],
                            [39.21448805, -6.25034989],
                            [39.21510601, -6.25039251],
                            [39.21541144, -6.25083295],
                            [39.21564584, -6.25066956],
                            [39.21659763, -6.25077612],
                            [39.21827359, -6.25076089],
                            [39.21894162, -6.25075481],
                            [39.21902047, -6.25075869],
                            [39.22056856, -6.2508349],
                            [39.22211665, -6.2509111],
                            [39.22242398, -6.25098171],
                            [39.22369351, -6.2512734],
                            [39.22523486, -6.25176356],
                            [39.22604459, -6.25269416],
                            [39.22648498, -6.25351109],
                            [39.22705321, -6.25398705],
                            [39.22703404, -6.25466264],
                            [39.22706742, -6.25488923],
                            [39.2275211, -6.25528625],
                            [39.22752122, -6.25528915],
                            [39.22769435, -6.25586145],
                            [39.22843306, -6.25627347],
                            [39.22945589, -6.25685598],
                            [39.23023721, -6.25738166],
                            [39.23072021, -6.25812046],
                            [39.23081036, -6.25821253],
                            [39.23138789, -6.25880242],
                            [39.23174304, -6.2593281],
                            [39.23189501, -6.25960674],
                            [39.23191351, -6.25964067],
                            [39.23174304, -6.25998165],
                            [39.23211239, -6.26032263],
                            [39.23202277, -6.26038762],
                            [39.23154416, -6.26073465],
                            [39.23191351, -6.26147344],
                            [39.23320625, -6.2617718],
                            [39.2338029, -6.26225486],
                            [39.23397337, -6.26261005],
                            [39.23464105, -6.26317835],
                            [39.23515924, -6.26336059],
                            [39.23516462, -6.26335922],
                            [39.23584013, -6.26346851],
                            [39.23598868, -6.26349254],
                            [39.23655489, -6.26374166],
                            [39.23690264, -6.2639191],
                            [39.23740226, -6.2640227],
                            [39.23790189, -6.26412629],
                            [39.23835504, -6.26437938],
                            [39.23876101, -6.2645102],
                            [39.23904738, -6.26461379],
                            [39.23921798, -6.26471738],
                            [39.23927891, -6.26481488],
                            [39.2393764, -6.26512566],
                            [39.2394678, -6.26538769],
                            [39.23938859, -6.26558878],
                            [39.2396384, -6.26589347],
                            [39.23971152, -6.26599706],
                            [39.23974807, -6.26604886],
                            [39.23978463, -6.26610066],
                            [39.23972979, -6.26640534],
                            [39.24053407, -6.26699643],
                            [39.2414422, -6.26728225],
                            [39.24200554, -6.26745956],
                            [39.24219137, -6.2673194],
                            [39.24251318, -6.26733852],
                            [39.24298232, -6.2673664],
                            [39.24311447, -6.26737425],
                            [39.24358515, -6.26745194],
                            [39.24376108, -6.26748098],
                            [39.24405584, -6.26752964],
                            [39.24451891, -6.26759667],
                            [39.24473216, -6.2675723],
                            [39.24494542, -6.26754792],
                            [39.2450947, -6.26744737],
                            [39.24521552, -6.26731578],
                            [39.24553949, -6.26696293],
                            [39.24583195, -6.26878494],
                            [39.24586851, -6.26916885],
                            [39.24595721, -6.26938231],
                            [39.24657676, -6.26994292],
                            [39.24663451, -6.26999518],
                            [39.24740857, -6.27072094],
                            [39.24802274, -6.27113461],
                            [39.24819876, -6.27125317],
                            [39.24856831, -6.27166067],
                            [39.24882768, -6.27194668],
                            [39.24893306, -6.27222897],
                            [39.24926736, -6.27312452],
                            [39.24927922, -6.27315629],
                            [39.24932934, -6.27339975],
                            [39.24933566, -6.27343046],
                            [39.24947273, -6.27379335],
                            [39.24955336, -6.27405946],
                            [39.24948886, -6.27427719],
                            [39.24949514, -6.27462903],
                            [39.24949692, -6.27472878],
                            [39.24952111, -6.27514004],
                            [39.24956142, -6.27570453],
                            [39.24960576, -6.27592626],
                            [39.2496098, -6.27594645],
                            [39.249763, -6.27633352],
                            [39.24985976, -6.27659157],
                            [39.24979525, -6.27668834],
                            [39.24986782, -6.27693026],
                            [39.24993232, -6.27719637],
                            [39.24996224, -6.27735262],
                            [39.25000489, -6.27757538],
                            [39.25015003, -6.27794633],
                            [39.25014575, -6.27804051],
                            [39.25014196, -6.27812374],
                            [39.2501339, -6.27839792],
                            [39.25010165, -6.27859145],
                            [39.25018228, -6.27891402],
                            [39.25022224, -6.27899395],
                            [39.25031935, -6.27918819],
                            [39.25048868, -6.27947044],
                            [39.25056124, -6.27969623],
                            [39.25054512, -6.27981719],
                            [39.25064994, -6.28002685],
                            [39.25063381, -6.28012362],
                            [39.25071444, -6.28035748],
                            [39.25084748, -6.28054295],
                            [39.25086975, -6.280574],
                            [39.25098052, -6.28072843],
                            [39.25116598, -6.28103486],
                            [39.25121435, -6.28114776],
                            [39.25139981, -6.28172031],
                            [39.25161002, -6.28209406],
                            [39.25161751, -6.28210738],
                            [39.25169486, -6.28226211],
                            [39.25176265, -6.28239769],
                            [39.25184932, -6.28252873],
                            [39.25222023, -6.28357705],
                            [39.25260726, -6.28412541],
                            [39.25260726, -6.28451248],
                            [39.25270671, -6.28461506],
                            [39.2531233, -6.28504471],
                            [39.25310717, -6.28552855],
                            [39.25352358, -6.28620724],
                            [39.25375251, -6.28671022],
                            [39.25389735, -6.28702847],
                            [39.25399187, -6.28764292],
                            [39.25402636, -6.28786713],
                            [39.25442952, -6.28828646],
                            [39.25445139, -6.28857633],
                            [39.25449402, -6.28914125],
                            [39.25525195, -6.29002829],
                            [39.25535631, -6.29021989],
                            [39.25600802, -6.29141633],
                            [39.25629716, -6.29194717],
                            [39.25707422, -6.2935926],
                            [39.25712906, -6.29365991],
                            [39.25779617, -6.29447865],
                            [39.25800085, -6.29472985],
                            [39.25875917, -6.29566053],
                            [39.2587592, -6.29566053],
                            [39.25878528, -6.29571009],
                            [39.25892324, -6.29597228],
                            [39.25896101, -6.29604407],
                            [39.25905777, -6.29652791],
                            [39.25900939, -6.29696337],
                            [39.25902552, -6.29741496],
                            [39.25954156, -6.29783429],
                            [39.25993464, -6.29834693],
                            [39.26031562, -6.29909228],
                            [39.26090693, -6.29950586],
                            [39.26120256, -6.30005997],
                            [39.26150896, -6.30018899],
                            [39.26154121, -6.30049543],
                            [39.26207338, -6.30118894],
                            [39.2621202, -6.3012227],
                            [39.2627668, -6.30168891],
                            [39.26354819, -6.30220065],
                            [39.26389989, -6.30279206],
                            [39.26394402, -6.30286626],
                            [39.26444393, -6.30320495],
                            [39.26475033, -6.30391459],
                            [39.26503254, -6.304596],
                            [39.26503251, -6.30459601],
                            [39.26497204, -6.30481373],
                            [39.26492273, -6.30490717],
                            [39.26481884, -6.30510404],
                            [39.26469386, -6.30537015],
                            [39.26463742, -6.30562417],
                            [39.26458501, -6.30583384],
                            [39.26477449, -6.30610801],
                            [39.26489141, -6.30630961],
                            [39.26514136, -6.3065717],
                            [39.26543163, -6.306862],
                            [39.26588317, -6.30698296],
                            [39.26626617, -6.30709989],
                            [39.26652822, -6.30722892],
                            [39.26672577, -6.30728133],
                            [39.2670362, -6.30744262],
                            [39.26732244, -6.30784179],
                            [39.26748609, -6.30793009],
                            [39.26824163, -6.30833773],
                            [39.26860447, -6.30859578],
                            [39.26893506, -6.30897076],
                            [39.26921727, -6.30928525],
                            [39.26925356, -6.30955137],
                            [39.26914873, -6.30982554],
                            [39.26922287, -6.30991788],
                            [39.26936241, -6.31009166],
                            [39.26970912, -6.31046261],
                            [39.27012176, -6.3107526],
                            [39.27030579, -6.31088194],
                            [39.27084925, -6.31126442],
                            [39.27095891, -6.31134159],
                            [39.27149406, -6.31186671],
                            [39.27165021, -6.31201993],
                            [39.2718136, -6.31218025],
                            [39.27178892, -6.31231322],
                            [39.27174103, -6.31257136],
                            [39.27186197, -6.31278505],
                            [39.27181879, -6.31295027],
                            [39.27167652, -6.31299875],
                            [39.27147494, -6.31319229],
                            [39.27135803, -6.31342211],
                            [39.2712653, -6.31368419],
                            [39.27129525, -6.31372147],
                            [39.27144672, -6.31390999],
                            [39.27174102, -6.31424868],
                            [39.27242236, -6.31444222],
                            [39.27284567, -6.31457124],
                            [39.27324077, -6.31463172],
                            [39.27342622, -6.31497444],
                            [39.27357022, -6.31501709],
                            [39.27436557, -6.31525266],
                            [39.27486743, -6.3153693],
                            [39.27493806, -6.31538571],
                            [39.27636523, -6.31587359],
                            [39.27705463, -6.31617599],
                            [39.2780343, -6.31669209],
                            [39.27822737, -6.31678022],
                            [39.27882045, -6.31705094],
                            [39.27945018, -6.31742302],
                            [39.27983479, -6.31765026],
                            [39.2802194, -6.31787751],
                            [39.28052984, -6.31814766],
                            [39.28102169, -6.31833716],
                            [39.28183203, -6.31837345],
                            [39.28246643, -6.31805774],
                            [39.28267463, -6.31795412],
                            [39.28299901, -6.31776601],
                            [39.28315439, -6.31767592],
                            [39.28362205, -6.31738561],
                            [39.28380132, -6.31729662],
                            [39.28417437, -6.31711143],
                            [39.28483555, -6.31687758],
                            [39.28486978, -6.31687955],
                            [39.28504519, -6.31688967],
                            [39.28521452, -6.31694612],
                            [39.285404, -6.31705902],
                            [39.28600471, -6.31691387],
                            [39.28612968, -6.31687758],
                            [39.28659735, -6.31669614],
                            [39.28683924, -6.31661147],
                            [39.28722224, -6.31643809],
                            [39.28783907, -6.31629294],
                            [39.28793986, -6.31626068],
                            [39.28875827, -6.3161115],
                            [39.28921787, -6.31604699],
                            [39.28969762, -6.31610344],
                            [39.2899113, -6.31619214],
                            [39.29005643, -6.31642197],
                            [39.29049587, -6.31651067],
                            [39.29083452, -6.31659938],
                            [39.29132638, -6.31661954],
                            [39.29166503, -6.31677276],
                            [39.29179807, -6.31694613],
                            [39.29196336, -6.31717596],
                            [39.29253584, -6.31742595],
                            [39.29296319, -6.31752675],
                            [39.29317283, -6.31764771],
                            [39.29355986, -6.31764771],
                            [39.29381385, -6.31758723],
                            [39.29393077, -6.31756304],
                            [39.29395899, -6.31772835],
                            [39.29380982, -6.31795818],
                            [39.29379772, -6.31823235],
                            [39.29396705, -6.31852669],
                            [39.29402349, -6.31872829],
                            [39.29433795, -6.31872829],
                            [39.29469273, -6.31870007],
                            [39.29493866, -6.31877265],
                            [39.29517652, -6.31883313],
                            [39.29532166, -6.3189057],
                            [39.29528537, -6.3190186],
                            [39.29540229, -6.31906295],
                            [39.29543051, -6.31916778],
                            [39.29535794, -6.31935729],
                            [39.29548292, -6.3195226],
                            [39.29581351, -6.31947019],
                            [39.29608362, -6.31947422],
                            [39.29634164, -6.31936132],
                            [39.29643034, -6.31902667],
                            [39.29647065, -6.31877265],
                            [39.29662788, -6.31867991],
                            [39.2968093, -6.31863153],
                            [39.29686171, -6.31870814],
                            [39.29698266, -6.31858718],
                            [39.29692622, -6.31845815],
                            [39.29708345, -6.31854686],
                            [39.29730922, -6.31867991],
                            [39.29767609, -6.31870411],
                            [39.29782123, -6.31864766],
                            [39.29794217, -6.31867588],
                            [39.29810747, -6.31875652],
                            [39.29817197, -6.31869604],
                            [39.29823648, -6.31863556],
                            [39.29815988, -6.31849847],
                            [39.29812763, -6.31838154],
                            [39.29829292, -6.31843396],
                            [39.29840581, -6.3183009],
                            [39.29844612, -6.31813962],
                            [39.29816391, -6.31798641],
                            [39.29787364, -6.31807914],
                            [39.29782929, -6.31801463],
                            [39.2977285, -6.31776061],
                            [39.29774866, -6.3175066],
                            [39.29754305, -6.31728483],
                            [39.29763981, -6.31715984],
                            [39.2978938, -6.3172929],
                            [39.29802281, -6.31722435],
                            [39.29829292, -6.31718807],
                            [39.29839774, -6.31724048],
                            [39.29853482, -6.3173816],
                            [39.29867189, -6.31752273],
                            [39.29883315, -6.31727677],
                            [39.29894604, -6.31694615],
                            [39.29910327, -6.31673245],
                            [39.29895007, -6.31638973],
                            [39.29894604, -6.31600265],
                            [39.29909118, -6.3157446],
                            [39.29913956, -6.31557123],
                            [39.29918793, -6.31539785],
                            [39.29917584, -6.31501884],
                            [39.29899039, -6.31470837],
                            [39.29883719, -6.31441404],
                            [39.29877874, -6.31437097],
                            [39.29860739, -6.31424469],
                            [39.29852364, -6.31416333],
                            [39.29846629, -6.3141076],
                            [39.29843, -6.31398261],
                            [39.29831712, -6.31376891],
                            [39.29829293, -6.31357134],
                            [39.29832518, -6.31341006],
                            [39.29840581, -6.31334958],
                            [39.29852676, -6.3132891],
                            [39.29854639, -6.31326667],
                            [39.29861142, -6.31319233],
                            [39.29855, -6.31312408],
                            [39.29850257, -6.31307137],
                            [39.29831309, -6.31306734],
                            [39.29820424, -6.31292219],
                            [39.29802282, -6.31263188],
                            [39.29787365, -6.31230932],
                            [39.29770432, -6.3120553],
                            [39.29748662, -6.31184967],
                            [39.29734148, -6.3117529],
                            [39.29730923, -6.31160371],
                            [39.29718828, -6.31138195],
                            [39.29711168, -6.31117229],
                            [39.29712026, -6.31109778],
                            [39.29717619, -6.31061184],
                            [39.29701493, -6.3104062],
                            [39.29690608, -6.31027315],
                            [39.29687382, -6.31013606],
                            [39.29684963, -6.30980946],
                            [39.29681335, -6.30973286],
                            [39.29674078, -6.30972882],
                            [39.29671256, -6.3095998],
                            [39.29667628, -6.30953529],
                            [39.29667628, -6.30946674],
                            [39.29660774, -6.30939417],
                            [39.29658355, -6.30933368],
                            [39.29660774, -6.30925304],
                            [39.29665209, -6.3091966],
                            [39.29677707, -6.30904741],
                            [39.29683149, -6.30893048],
                            [39.29688592, -6.30881355],
                            [39.29680529, -6.30869663],
                            [39.29684561, -6.30866437],
                            [39.29697058, -6.30866034],
                            [39.29713588, -6.30862808],
                            [39.29719635, -6.30849503],
                            [39.29703912, -6.30832568],
                            [39.29687786, -6.30819666],
                            [39.29684561, -6.30806763],
                            [39.29688995, -6.30794264],
                            [39.2967932, -6.30784184],
                            [39.29667225, -6.30789022],
                            [39.29659565, -6.30780958],
                            [39.29653518, -6.30760798],
                            [39.29652308, -6.30743863],
                            [39.29646261, -6.30724107],
                            [39.29644064, -6.30721553],
                            [39.29635225, -6.30711277],
                            [39.29599092, -6.30669271],
                            [39.29580546, -6.30632983],
                            [39.29574096, -6.30594678],
                            [39.29574031, -6.30593302],
                            [39.29572483, -6.30560406],
                            [39.2957208, -6.30530166],
                            [39.29577926, -6.30518473],
                            [39.29583772, -6.3050678],
                            [39.29595867, -6.30485411],
                            [39.29627313, -6.30461622],
                            [39.29632071, -6.30456521],
                            [39.29669605, -6.30416279],
                            [39.29677708, -6.30407593],
                            [39.29758339, -6.30351145],
                            [39.29771477, -6.30307347],
                            [39.29782529, -6.30270504],
                            [39.29773793, -6.3025455],
                            [39.29761928, -6.30232882],
                            [39.29745438, -6.30202766],
                            [39.29700289, -6.30201665],
                            [39.29679321, -6.30201153],
                            [39.29670606, -6.30171737],
                            [39.2966642, -6.30157607],
                            [39.29661052, -6.30114657],
                            [39.29659026, -6.30098455],
                            [39.29653519, -6.30054388],
                            [39.29641534, -6.30029893],
                            [39.29633073, -6.30012603],
                            [39.29618132, -6.29982067],
                            [39.29616429, -6.29978586],
                            [39.29586386, -6.29956552],
                            [39.2957751, -6.29950042],
                            [39.2954386, -6.29925363],
                            [39.29533755, -6.29908365],
                            [39.29508383, -6.29865689],
                            [39.29474543, -6.29821589],
                            [39.29455166, -6.29796338],
                            [39.29472304, -6.29759422],
                            [39.29492897, -6.29715063],
                            [39.29497095, -6.29706021],
                            [39.29518482, -6.2961639],
                            [39.29527089, -6.29580317],
                            [39.2953096, -6.29564094],
                            [39.29552103, -6.29454841],
                            [39.29557046, -6.294293],
                            [39.29559988, -6.29414102],
                            [39.2951844, -6.2935544],
                            [39.29491613, -6.29317563],
                            [39.29477744, -6.2929798],
                            [39.29469091, -6.29296167],
                            [39.29374989, -6.29276448],
                            [39.29366728, -6.29274717],
                            [39.29345623, -6.29270294],
                            [39.29308418, -6.29262498],
                            [39.29251976, -6.29133473],
                            [39.29226938, -6.29000255],
                            [39.29211661, -6.2891897],
                            [39.29182779, -6.28903917],
                            [39.29176942, -6.28900875],
                            [39.29175195, -6.28899964],
                            [39.29097165, -6.28859295],
                            [39.29092813, -6.28854793],
                            [39.29003632, -6.28762527],
                            [39.28956866, -6.28746399],
                            [39.28950416, -6.28720594],
                            [39.28927839, -6.28665758],
                            [39.28921389, -6.28623825],
                            [39.28884298, -6.28585118],
                            [39.28855271, -6.28510928],
                            [39.28833696, -6.28498701],
                            [39.28806892, -6.2848351],
                            [39.28795604, -6.28423837],
                            [39.28792601, -6.28415028],
                            [39.28771415, -6.28352873],
                            [39.28740775, -6.2829965],
                            [39.28690783, -6.28289973],
                            [39.28689171, -6.28267394],
                            [39.28689171, -6.28212558],
                            [39.28687558, -6.28178689],
                            [39.28684333, -6.28138369],
                            [39.28601983, -6.28088061],
                            [39.28590469, -6.2805812],
                            [39.28542109, -6.28005148],
                            [39.28530756, -6.279673],
                            [39.28528292, -6.27959086],
                            [39.28531633, -6.2790228],
                            [39.28532898, -6.2788078],
                            [39.28486841, -6.27832414],
                            [39.28473024, -6.27788655],
                            [39.28452298, -6.27751805],
                            [39.28436178, -6.27712652],
                            [39.2840258, -6.27681645],
                            [39.28407865, -6.2766145],
                            [39.2841315, -6.27641255],
                            [39.28374001, -6.27574464],
                            [39.28406241, -6.27558342],
                            [39.28395344, -6.27501548],
                            [39.28395069, -6.27500111],
                            [39.28388044, -6.274635],
                            [39.28392424, -6.27436277],
                            [39.28357881, -6.27387911],
                            [39.283078, -6.27351181],
                            [39.28309521, -6.27309605],
                            [39.28321503, -6.27264335],
                            [39.28330247, -6.27231299],
                            [39.2831643, -6.27176024],
                            [39.28288796, -6.27134568],
                            [39.28285975, -6.27126104],
                            [39.28272676, -6.27086202],
                            [39.28304916, -6.27049352],
                            [39.28288796, -6.2696644],
                            [39.28269997, -6.26936896],
                            [39.28208196, -6.26839768],
                            [39.28224316, -6.26770675],
                            [39.2821499, -6.26742692],
                            [39.28206987, -6.26718682],
                            [39.28198985, -6.26694672],
                            [39.28155231, -6.26591031],
                            [39.28169048, -6.26505671],
                            [39.28166745, -6.26468966],
                            [39.28137467, -6.26441225],
                            [39.28122991, -6.26427509],
                            [39.28142682, -6.26408819],
                            [39.28141696, -6.26395533],
                            [39.28139111, -6.26360719],
                            [39.2811319, -6.2631192],
                            [39.2810993, -6.26305782],
                            [39.28106871, -6.26270897],
                            [39.28118386, -6.26238654],
                            [39.28117306, -6.26235176],
                            [39.28107483, -6.2620352],
                            [39.2809766, -6.26171863],
                            [39.28125294, -6.26137316],
                            [39.28114444, -6.26106933],
                            [39.28103269, -6.26075637],
                            [39.28090752, -6.26040585],
                            [39.28021666, -6.25930035],
                            [39.2801919, -6.2592375],
                            [39.28013126, -6.25908353],
                            [39.27991729, -6.25854032],
                            [39.27998368, -6.25809944],
                            [39.27964138, -6.25769188],
                            [39.27939687, -6.25736584],
                            [39.27957618, -6.25694198],
                            [39.27915237, -6.25656703],
                            [39.27885896, -6.25591495],
                            [39.27889249, -6.25538068],
                            [39.27890806, -6.25513263],
                            [39.2792631, -6.25437731],
                            [39.28069829, -6.25364246],
                            [39.2813857, -6.2532905],
                            [39.2823911, -6.25300314],
                            [39.28289312, -6.25275074],
                            [39.28517883, -6.25160157],
                            [39.28668331, -6.25084518],
                            [39.28810567, -6.25106807],
                            [39.28873927, -6.25076915],
                            [39.28937286, -6.25047024],
                            [39.29144299, -6.25112233],
                            [39.2918016, -6.25060066],
                            [39.2918342, -6.25120384],
                            [39.29238841, -6.25183962],
                            [39.29331753, -6.25135056],
                            [39.29512686, -6.25350245],
                            [39.29651238, -6.25586626],
                            [39.29677318, -6.25648574],
                            [39.29740889, -6.25705632],
                            [39.2980446, -6.25731715],
                            [39.2980935, -6.25761059],
                            [39.2980609, -6.25824637],
                            [39.2983054, -6.25889846],
                            [39.29921822, -6.25948534],
                            [39.29954423, -6.2599907],
                            [39.30013104, -6.26015373],
                            [39.30031034, -6.26043086],
                            [39.30083195, -6.2606917],
                            [39.30104385, -6.26090363],
                            [39.30117425, -6.26137639],
                            [39.30156546, -6.2622241],
                            [39.30164696, -6.26263165],
                            [39.30180996, -6.26303921],
                            [39.30210337, -6.26344676],
                            [39.30236417, -6.26395213],
                            [39.30278798, -6.26460421],
                            [39.30291838, -6.26491396],
                            [39.30324438, -6.26628334],
                            [39.30376599, -6.26784834],
                            [39.30376599, -6.26915251],
                            [39.30413778, -6.2704693],
                            [39.3046136, -6.27052189],
                            [39.3046788, -6.27009804],
                            [39.30464621, -6.26797876],
                            [39.304255, -6.26654417],
                            [39.30370079, -6.26501177],
                            [39.3039942, -6.2637402],
                            [39.303766, -6.26240343],
                            [39.30334219, -6.2614579],
                            [39.3033748, -6.26080582],
                            [39.30311399, -6.25956685],
                            [39.30241446, -6.25816763],
                            [39.30239678, -6.25813227],
                            [39.30194037, -6.2568607],
                            [39.30194038, -6.25653466],
                            [39.30135357, -6.25568694],
                            [39.30148397, -6.25503486],
                            [39.30144416, -6.25488697],
                            [39.30125577, -6.25418715],
                            [39.30063636, -6.25301339],
                            [39.29988655, -6.25161141],
                            [39.29967682, -6.25142212],
                            [39.29854992, -6.25040505],
                            [39.29584532, -6.24695196],
                            [39.29548547, -6.24649253],
                            [39.29470377, -6.24463577],
                            [39.29418146, -6.24339512],
                            [39.29410954, -6.2429542],
                            [39.29343165, -6.23879792],
                            [39.29349685, -6.23605916],
                            [39.29310565, -6.23475499],
                            [39.29292716, -6.23414615],
                            [39.29337997, -6.23412646],
                            [39.29404072, -6.2352679],
                            [39.29446279, -6.235997],
                            [39.29543623, -6.23715398],
                            [39.29582123, -6.23761157],
                            [39.29583031, -6.23761172],
                            [39.29700249, -6.23763126],
                            [39.29803001, -6.23737435],
                            [39.29849874, -6.23725716],
                            [39.29951857, -6.2370433],
                            [39.29961897, -6.23702224],
                            [39.29971938, -6.23700119],
                            [39.30094985, -6.23687321],
                            [39.30218033, -6.23674523],
                            [39.30377503, -6.23648926],
                            [39.30422784, -6.23605609],
                            [39.30468066, -6.23562291],
                            [39.30477734, -6.23560093],
                            [39.30511378, -6.23552446],
                            [39.30582254, -6.23540632],
                            [39.30647223, -6.23522912],
                            [39.30714161, -6.23515036],
                            [39.30769286, -6.23528819],
                            [39.30798818, -6.23532757],
                            [39.30834255, -6.23532757],
                            [39.30876679, -6.23502451],
                            [39.30907176, -6.2349294],
                            [39.30907176, -6.23492935],
                            [39.30921936, -6.23496233],
                            [39.30936699, -6.23499531],
                            [39.30980046, -6.23540998],
                            [39.3102383, -6.23587643],
                            [39.31037841, -6.2360257],
                            [39.31067367, -6.23693042],
                            [39.31169137, -6.23718174],
                            [39.31303914, -6.23740908],
                            [39.31336965, -6.23829064],
                            [39.31374851, -6.23861541],
                            [39.31416345, -6.23897628],
                            [39.31425976, -6.23920744],
                            [39.31461448, -6.24005886],
                            [39.3150655, -6.24133992],
                            [39.31537219, -6.24238641],
                            [39.31571497, -6.24296379],
                            [39.31578714, -6.24355921],
                            [39.31603971, -6.24447941],
                            [39.31670722, -6.24583263],
                            [39.31708608, -6.24633784],
                            [39.31750103, -6.2464461],
                            [39.31800617, -6.24653632],
                            [39.31842112, -6.24696935],
                            [39.3186943, -6.24747676],
                            [39.31879998, -6.24767303],
                            [39.31928708, -6.24790759],
                            [39.3198644, -6.24812411],
                            [39.32013501, -6.24852105],
                            [39.32037856, -6.24863833],
                            [39.32062212, -6.24875561],
                            [39.32141592, -6.24906235],
                            [39.3220654, -6.24935104],
                            [39.32214069, -6.24941949],
                            [39.32256594, -6.24980613],
                            [39.32266075, -6.24989233],
                            [39.32280508, -6.25028928],
                            [39.32307569, -6.25039753],
                            [39.32386949, -6.25032536],
                            [39.3253515, -6.2504919],
                            [39.32547514, -6.2505058],
                            [39.32635915, -6.25066819],
                            [39.32690038, -6.25110122],
                            [39.32693035, -6.25115688],
                            [39.32719103, -6.25164105],
                            [39.32727924, -6.2518049],
                            [39.32751378, -6.25214772],
                            [39.3275679, -6.25252662],
                            [39.32809109, -6.25295965],
                            [39.32818129, -6.25330247],
                            [39.32852407, -6.25380768],
                            [39.3287586, -6.25413245],
                            [39.32902922, -6.25420463],
                            [39.32917354, -6.25467374],
                            [39.32950079, -6.2548798],
                            [39.32936662, -6.25531038],
                            [39.32922766, -6.25575633],
                            [39.32929983, -6.25611719],
                            [39.3294622, -6.25629762],
                            [39.32971477, -6.25660435],
                            [39.32987714, -6.25691108],
                            [39.33000343, -6.25703738],
                            [39.33005755, -6.25728999],
                            [39.33021992, -6.25747042],
                            [39.33029208, -6.25775911],
                            [39.33070702, -6.25810192],
                            [39.33088398, -6.2582157],
                            [39.3309596, -6.25826431],
                            [39.33124825, -6.25887778],
                            [39.33177144, -6.25927472],
                            [39.33198793, -6.25950928],
                            [39.33210033, -6.25958451],
                            [39.33221272, -6.25965974],
                            [39.33274481, -6.26015915],
                            [39.33294128, -6.26067493],
                            [39.33312137, -6.26108428],
                            [39.333424, -6.26145016],
                            [39.33344472, -6.26147521],
                            [39.33343513, -6.26149886],
                            [39.33341402, -6.26155094],
                            [39.33340788, -6.26171672],
                            [39.33351442, -6.2618458],
                            [39.33376602, -6.26215063],
                            [39.33370053, -6.26248221],
                            [39.33368409, -6.26258821],
                            [39.3336637, -6.26271963],
                            [39.33374799, -6.26286785],
                            [39.33379058, -6.26294273],
                            [39.33377827, -6.26299566],
                            [39.33374965, -6.26311875],
                            [39.3338929, -6.2635854],
                            [39.33400492, -6.26370671],
                            [39.33426332, -6.26398657],
                            [39.33434518, -6.2642199],
                            [39.33455597, -6.26441843],
                            [39.33455393, -6.26474796],
                            [39.33460918, -6.26532719],
                            [39.33468286, -6.26583274],
                            [39.33482406, -6.26622162],
                            [39.33516379, -6.26671079],
                            [39.33549327, -6.26688886],
                            [39.33570816, -6.26707921],
                            [39.33594965, -6.26743943],
                            [39.33605607, -6.26779352],
                            [39.33621569, -6.26800638],
                            [39.33626686, -6.26841573],
                            [39.33648788, -6.26890286],
                            [39.33691765, -6.26958033],
                            [39.33693197, -6.26963355],
                            [39.3369463, -6.26968677],
                            [39.33692174, -6.26987097],
                            [39.33689513, -6.2699508],
                            [39.3369156, -6.27029465],
                            [39.33698723, -6.27053207],
                            [39.33692378, -6.27068967],
                            [39.33690332, -6.27088002],
                            [39.33702611, -6.27106218],
                            [39.33702406, -6.27123206],
                            [39.33693606, -6.27127709],
                            [39.33700769, -6.27168849],
                            [39.33698722, -6.27202415],
                            [39.33695653, -6.27213877],
                            [39.3371755, -6.27234345],
                            [39.3372635, -6.27258496],
                            [39.33716936, -6.27277736],
                            [39.33713646, -6.27305434],
                            [39.33712025, -6.2731908],
                            [39.3371755, -6.27347325],
                            [39.33718164, -6.27370453],
                            [39.33724508, -6.27393172],
                            [39.33728396, -6.27435335],
                            [39.33742517, -6.27466446],
                            [39.33750089, -6.27477805],
                            [39.33757662, -6.27489165],
                            [39.33764763, -6.27502506],
                            [39.33772806, -6.27517615],
                            [39.33770175, -6.27523534],
                            [39.33769531, -6.27524983],
                            [39.3377035, -6.27559573],
                            [39.33773251, -6.2756955],
                            [39.33782015, -6.27599689],
                            [39.3379102, -6.27606853],
                            [39.33796136, -6.27637145],
                            [39.33800638, -6.27665799],
                            [39.33808415, -6.27674805],
                            [39.33813531, -6.27702436],
                            [39.33807801, -6.27712465],
                            [39.33817215, -6.27755651],
                            [39.33811484, -6.27803341],
                            [39.3381527, -6.27818794],
                            [39.33819057, -6.27834247],
                            [39.33853029, -6.27892374],
                            [39.33853438, -6.27908544],
                            [39.33852619, -6.27935151],
                            [39.33860601, -6.27967899],
                            [39.33871652, -6.27978338],
                            [39.33881261, -6.28001628],
                            [39.33876768, -6.28009039],
                            [39.33873494, -6.28031758],
                            [39.33879428, -6.28050997],
                            [39.33877587, -6.28058366],
                            [39.33876359, -6.2808088],
                            [39.33880656, -6.28096435],
                            [39.33904805, -6.28161931],
                            [39.33929773, -6.28212281],
                            [39.33936731, -6.2824769],
                            [39.3394901, -6.28275116],
                            [39.33949419, -6.28303361],
                            [39.33959651, -6.2832608],
                            [39.33951056, -6.28391781],
                            [39.33942256, -6.28417775],
                            [39.33942051, -6.28430464],
                            [39.33969884, -6.28454002],
                            [39.33995056, -6.28478154],
                            [39.33999763, -6.28496984],
                            [39.33985233, -6.28505375],
                            [39.33991577, -6.28531369],
                            [39.3399567, -6.28548357],
                            [39.34008428, -6.28573532],
                            [39.34002832, -6.28586631],
                            [39.3402166, -6.2862552],
                            [39.34038646, -6.28675256],
                            [39.340806, -6.28739319],
                            [39.34087821, -6.28744571],
                            [39.3410086, -6.28754056],
                            [39.34100451, -6.28761014],
                            [39.34105158, -6.28769406],
                            [39.3411314, -6.28768997],
                            [39.34123372, -6.28777593],
                            [39.34121735, -6.28786804],
                            [39.3411273, -6.28797651],
                            [39.34149772, -6.28856188],
                            [39.34160414, -6.28866627],
                            [39.34189679, -6.28910018],
                            [39.34190498, -6.28923322],
                            [39.34210758, -6.28948087],
                            [39.3421567, -6.2897449],
                            [39.34223446, -6.28977356],
                            [39.34226107, -6.28989841],
                            [39.34233679, -6.29013788],
                            [39.34245958, -6.29024227],
                            [39.34320042, -6.29164224],
                            [39.34337437, -6.29225627],
                            [39.34350739, -6.29256328],
                            [39.34361381, -6.29272088],
                            [39.34360563, -6.29281503],
                            [39.34358224, -6.29305575],
                            [39.34357083, -6.29317321],
                            [39.34357902, -6.29351297],
                            [39.34354628, -6.29359689],
                            [39.34360767, -6.29376063],
                            [39.34371204, -6.29400214],
                            [39.3441152, -6.29475125],
                            [39.34436693, -6.29526703],
                            [39.34464154, -6.29550219],
                            [39.34468004, -6.29553516],
                            [39.34477298, -6.29570667],
                            [39.34485195, -6.29585241],
                            [39.34493381, -6.29610211],
                            [39.34507093, -6.29626994],
                            [39.34510162, -6.29640093],
                            [39.34525921, -6.29651351],
                            [39.34537176, -6.29668543],
                            [39.34541269, -6.29698016],
                            [39.34542906, -6.29731583],
                            [39.34548432, -6.2974632],
                            [39.34561325, -6.29777021],
                            [39.34588953, -6.29810588],
                            [39.34602255, -6.29856435],
                            [39.34605734, -6.29889183],
                            [39.34612283, -6.29916814],
                            [39.34619037, -6.29921521],
                            [39.34625585, -6.2995734],
                            [39.3464953, -6.2999807],
                            [39.3465219, -6.30028771],
                            [39.34659353, -6.30041666],
                            [39.34660171, -6.30053332],
                            [39.34655874, -6.30073595],
                            [39.34680022, -6.30097951],
                            [39.34742851, -6.30145027],
                            [39.34805679, -6.30191283],
                            [39.34808339, -6.30211546],
                            [39.34853158, -6.3025944],
                            [39.34878534, -6.30283182],
                            [39.34872599, -6.30302422],
                            [39.34895111, -6.30316954],
                            [39.34936655, -6.30343766],
                            [39.34966534, -6.30381631],
                            [39.34991297, -6.30425841],
                            [39.35017697, -6.30450402],
                            [39.35053921, -6.30471483],
                            [39.35074181, -6.30495226],
                            [39.35080321, -6.30525313],
                            [39.35102423, -6.30545371],
                            [39.35131688, -6.30585692],
                            [39.35148469, -6.30624785],
                            [39.35171186, -6.30646276],
                            [39.3519206, -6.30664697],
                            [39.35219074, -6.30666334],
                            [39.35232786, -6.30687006],
                            [39.35253456, -6.30685164],
                            [39.35274535, -6.30672679],
                            [39.35300526, -6.3065303],
                            [39.35324061, -6.30629288],
                            [39.35331837, -6.30608207],
                            [39.35329177, -6.30575459],
                            [39.35328563, -6.30547009],
                            [39.35315875, -6.30531249],
                            [39.35275149, -6.30460636],
                            [39.35239335, -6.3040169],
                            [39.35221326, -6.30346223],
                            [39.35208024, -6.30344585],
                            [39.35195745, -6.30321662],
                            [39.35193084, -6.30297715],
                            [39.35198405, -6.30252072],
                            [39.3520168, -6.30215231],
                            [39.35190014, -6.30202336],
                            [39.3517487, -6.3020336],
                            [39.35170982, -6.30196605],
                            [39.35189196, -6.30181459],
                            [39.35189196, -6.30165904],
                            [39.35186126, -6.30157717],
                            [39.3517487, -6.30152805],
                            [39.35174461, -6.30145437],
                            [39.35181828, -6.30142776],
                            [39.35176303, -6.30119238],
                            [39.35154405, -6.30075847],
                            [39.35164024, -6.30065204],
                            [39.35161977, -6.30037778],
                            [39.35152359, -6.30022632],
                            [39.35149494, -6.30011784],
                            [39.35149494, -6.30011579],
                            [39.35159931, -6.30008305],
                            [39.35163615, -6.29995205],
                            [39.35156657, -6.29986814],
                            [39.35150722, -6.29981492],
                            [39.35147243, -6.29971463],
                            [39.35156657, -6.2996389],
                            [39.35160136, -6.29948539],
                            [39.3516075, -6.29933598],
                            [39.35136805, -6.29928481],
                            [39.35133736, -6.29918452],
                            [39.35128415, -6.29908014],
                            [39.35132098, -6.29899827],
                            [39.35140694, -6.29899417],
                            [39.35152973, -6.29911493],
                            [39.35163615, -6.29915178],
                            [39.35174257, -6.29908833],
                            [39.35180396, -6.29886728],
                            [39.35188582, -6.29874038],
                            [39.35179578, -6.2985357],
                            [39.35173438, -6.29835764],
                            [39.35179783, -6.29832489],
                            [39.35200248, -6.29829009],
                            [39.3522542, -6.29830442],
                            [39.3522808, -6.29824916],
                            [39.35232583, -6.29791145],
                            [39.3522542, -6.29742227],
                            [39.35234815, -6.29717843],
                            [39.35247467, -6.2975394],
                            [39.35264211, -6.29766965],
                            [39.35341978, -6.29786688],
                            [39.35371374, -6.29807156],
                            [39.3539035, -6.29864837],
                            [39.35433513, -6.29957127],
                            [39.35519095, -6.30052022],
                            [39.35641141, -6.3011268],
                            [39.356977, -6.30151382],
                            [39.35792956, -6.30190829],
                            [39.35806723, -6.30262279],
                            [39.35944026, -6.30326287],
                            [39.36034817, -6.30352709],
                            [39.36054165, -6.30370199],
                            [39.3604077, -6.30403691],
                            [39.36053049, -6.30431974],
                            [39.36097328, -6.3045914],
                            [39.36103654, -6.30482212],
                            [39.36144584, -6.30506401],
                            [39.36151282, -6.30542126],
                            [39.36159468, -6.30552546],
                            [39.36176584, -6.30543987],
                            [39.36187082, -6.30550877],
                            [39.36242541, -6.30587276],
                            [39.36248026, -6.30590876],
                            [39.36269235, -6.30634416],
                            [39.3628784, -6.30636277],
                            [39.36314631, -6.30669397],
                            [39.3631091, -6.30692097],
                            [39.36379747, -6.30731544],
                            [39.36377514, -6.30760942],
                            [39.36380038, -6.30774608],
                            [39.36388305, -6.30819368],
                            [39.36400956, -6.30820484],
                            [39.36417328, -6.30863652],
                            [39.36424739, -6.30894632],
                            [39.36413824, -6.30931846],
                            [39.36422506, -6.30978735],
                            [39.3643615, -6.30990395],
                            [39.36456243, -6.31003172],
                            [39.36476336, -6.31015949],
                            [39.36509824, -6.31022895],
                            [39.36552491, -6.31021407],
                            [39.36592925, -6.3104175],
                            [39.36627547, -6.31065414],
                            [39.3664229, -6.31075491],
                            [39.3665217, -6.31092692],
                            [39.36662383, -6.31110472],
                            [39.36666352, -6.31141483],
                            [39.36680491, -6.31157113],
                            [39.36709917, -6.31173922],
                            [39.36700088, -6.31195071],
                            [39.36698848, -6.31222609],
                            [39.3670753, -6.31230547],
                            [39.36725639, -6.31244565],
                            [39.36743747, -6.31258582],
                            [39.36760367, -6.31289097],
                            [39.3676384, -6.31308696],
                            [39.36778724, -6.3131465],
                            [39.36804522, -6.31338219],
                            [39.36823871, -6.31364765],
                            [39.36831561, -6.31368982],
                            [39.36854135, -6.31371215],
                            [39.36859344, -6.31360795],
                            [39.36889608, -6.31386101],
                            [39.36894321, -6.31396769],
                            [39.36894817, -6.31406444],
                            [39.36902011, -6.31407685],
                            [39.36906219, -6.31414938],
                            [39.36904492, -6.31421826],
                            [39.36899282, -6.31420585],
                            [39.36887623, -6.31427284],
                            [39.36865794, -6.31446139],
                            [39.36864553, -6.31461024],
                            [39.36861825, -6.31489554],
                            [39.36869018, -6.31496253],
                            [39.36911189, -6.31484345],
                            [39.36928554, -6.31475165],
                            [39.36928802, -6.31467226],
                            [39.36913918, -6.31459784],
                            [39.36914414, -6.31446139],
                            [39.3692384, -6.31439936],
                            [39.36922352, -6.31430013],
                            [39.36927809, -6.31426788],
                            [39.36959065, -6.31431253],
                            [39.3698412, -6.31442665],
                            [39.370826, -6.3149402],
                            [39.37173143, -6.31555051],
                            [39.37222543, -6.31590367],
                            [39.3723941, -6.31602424],
                            [39.37308833, -6.31652055],
                            [39.37324461, -6.31676864],
                            [39.37347283, -6.31686043],
                            [39.37350512, -6.31689857],
                            [39.37443283, -6.31799421],
                            [39.37487934, -6.31866653],
                            [39.37501826, -6.31896672],
                            [39.37510508, -6.31948275],
                            [39.37503314, -6.31974077],
                            [39.37489174, -6.31980775],
                            [39.37474787, -6.31963161],
                            [39.37395407, -6.31982512],
                            [39.3735522, -6.32039076],
                            [39.37311065, -6.32082492],
                            [39.37310275, -6.32087822],
                            [39.37293453, -6.32201327],
                            [39.37306352, -6.32227377],
                            [39.37310817, -6.32243999],
                            [39.3728998, -6.32259381],
                            [39.3731677, -6.32288407],
                            [39.37334135, -6.32336785],
                            [39.37362166, -6.32346957],
                            [39.37391385, -6.32344151],
                            [39.37393174, -6.3234398],
                            [39.37414755, -6.32377472],
                            [39.37425909, -6.32442435],
                            [39.37427406, -6.32451155],
                            [39.37476026, -6.32587109],
                            [39.37468088, -6.32608941],
                            [39.37414754, -6.32769951],
                            [39.37398382, -6.32809646],
                            [39.37403095, -6.32843386],
                            [39.37423188, -6.32888787],
                            [39.37475777, -6.32937413],
                            [39.37474041, -6.329585],
                            [39.37465359, -6.3296768],
                            [39.37457173, -6.32996954],
                            [39.37447746, -6.33013328],
                            [39.37438072, -6.33014321],
                            [39.37424676, -6.33007126],
                            [39.37420707, -6.33012832],
                            [39.37414754, -6.33022756],
                            [39.3743063, -6.33046076],
                            [39.37446506, -6.33057488],
                            [39.3746387, -6.33054015],
                            [39.37474289, -6.33042603],
                            [39.37476273, -6.33028214],
                            [39.37480738, -6.33019779],
                            [39.37488428, -6.33018786],
                            [39.3750108, -6.33026725],
                            [39.37510754, -6.33023252],
                            [39.37517452, -6.32991993],
                            [39.37532088, -6.32970905],
                            [39.37529607, -6.3294039],
                            [39.37534816, -6.32873405],
                            [39.37543251, -6.32820562],
                            [39.37553917, -6.32791784],
                            [39.37569545, -6.32769704],
                            [39.37584925, -6.32735715],
                            [39.37617422, -6.3269999],
                            [39.37634786, -6.32673196],
                            [39.37657112, -6.32667986],
                            [39.37675716, -6.32620105],
                            [39.37714414, -6.32560811],
                            [39.37750383, -6.32527071],
                            [39.37761794, -6.32495564],
                            [39.37772213, -6.3246356],
                            [39.37800492, -6.32434037],
                            [39.37818601, -6.32419648],
                            [39.37822074, -6.32385163],
                            [39.37814632, -6.3234398],
                            [39.377995, -6.32317186],
                            [39.37769237, -6.32255908],
                            [39.37769981, -6.32235565],
                            [39.37758322, -6.32209267],
                            [39.37760803, -6.32204553],
                            [39.37775438, -6.32202817],
                            [39.3778288, -6.32187435],
                            [39.37766756, -6.3215295],
                            [39.37758322, -6.32136576],
                            [39.37758818, -6.32113008],
                            [39.37748896, -6.32099859],
                            [39.37721361, -6.32085966],
                            [39.37698291, -6.32050985],
                            [39.37676958, -6.32022951],
                            [39.37674477, -6.3199442],
                            [39.37689113, -6.31970852],
                            [39.37684648, -6.31898905],
                            [39.37684648, -6.318736],
                            [39.37673485, -6.31826959],
                            [39.37671501, -6.31805127],
                            [39.37656865, -6.31694231],
                            [39.37653917, -6.31672216],
                            [39.37653144, -6.31666444],
                            [39.37650664, -6.31616082],
                            [39.37653699, -6.31601941],
                            [39.37656734, -6.315878],
                            [39.37660835, -6.31568697],
                            [39.3768961, -6.31521808],
                            [39.37711439, -6.31503449],
                            [39.37747657, -6.31479632],
                            [39.37789827, -6.31459785],
                            [39.37814385, -6.31452591],
                            [39.37839192, -6.31431503],
                            [39.37867967, -6.31422324],
                            [39.37901207, -6.3141885],
                            [39.37947347, -6.31425053],
                            [39.37979347, -6.31429022],
                            [39.38013332, -6.31441675],
                            [39.38061456, -6.31448622],
                            [39.38101476, -6.31452454],
                            [39.38096557, -6.31467104],
                            [39.3805389, -6.3146115],
                            [39.37976495, -6.31450234],
                            [39.37907037, -6.31458173],
                            [39.37818726, -6.31516722],
                            [39.37747284, -6.31546493],
                            [39.37726447, -6.31572294],
                            [39.37735377, -6.31636798],
                            [39.37796897, -6.31679469],
                            [39.37813765, -6.31732065],
                            [39.37817734, -6.31787637],
                            [39.37847501, -6.31875957],
                            [39.37869331, -6.31897789],
                            [39.37902075, -6.31883896],
                            [39.37957641, -6.31892828],
                            [39.37998323, -6.31891835],
                            [39.38057858, -6.31869011],
                            [39.38083657, -6.31836263],
                            [39.38103502, -6.31837256],
                            [39.3809606, -6.31892332],
                            [39.38079192, -6.31916645],
                            [39.38022633, -6.31949889],
                            [39.38021145, -6.32054087],
                            [39.38041486, -6.3212256],
                            [39.380653, -6.32148857],
                            [39.38098044, -6.32162254],
                            [39.38142199, -6.32147369],
                            [39.38201734, -6.32149354],
                            [39.38221083, -6.3217069],
                            [39.38204215, -6.32196987],
                            [39.38156587, -6.32211376],
                            [39.38121362, -6.32251071],
                            [39.38133269, -6.323513],
                            [39.38143191, -6.32439124],
                            [39.38156586, -6.32479314],
                            [39.38210168, -6.32596909],
                            [39.38217609, -6.3266439],
                            [39.38172462, -6.32813741],
                            [39.38127314, -6.32963091],
                            [39.38130539, -6.32974751],
                            [39.38133764, -6.32986412],
                            [39.38139221, -6.33013205],
                            [39.38127066, -6.33028587],
                            [39.38114911, -6.33043969],
                            [39.38104492, -6.33087136],
                            [39.38102814, -6.33095397],
                            [39.38098042, -6.33118892],
                            [39.38135252, -6.33171487],
                            [39.38141205, -6.33205228],
                            [39.38126817, -6.33247403],
                            [39.38153608, -6.33286105],
                            [39.38170476, -6.333258],
                            [39.3817726, -6.33367188],
                            [39.38180399, -6.33386334],
                            [39.38174941, -6.3342454],
                            [39.38174796, -6.3342489],
                            [39.38164026, -6.33450837],
                            [39.38174278, -6.3345876],
                            [39.382186, -6.33493013],
                            [39.38286073, -6.33551562],
                            [39.38307436, -6.33576133],
                            [39.38315841, -6.33585799],
                            [39.3828012, -6.33629463],
                            [39.38279167, -6.33630654],
                            [39.38248367, -6.33669157],
                            [39.38389374, -6.33837185],
                            [39.3843491, -6.33891447],
                            [39.38538104, -6.34066103],
                            [39.38695176, -6.34355623],
                            [39.38829019, -6.34602326],
                            [39.38804026, -6.34617857],
                            [39.38625421, -6.34899688],
                            [39.38587846, -6.35115762],
                            [39.38577792, -6.3517358],
                            [39.38498411, -6.35503044],
                            [39.38490473, -6.35614189],
                            [39.38577791, -6.35745181],
                            [39.38561915, -6.35931745],
                            [39.38629388, -6.36050828],
                            [39.38616087, -6.36092636],
                            [39.38601604, -6.36138156],
                            [39.38645263, -6.36304873],
                            [39.38700829, -6.36300904],
                            [39.38727103, -6.36323199],
                            [39.38790423, -6.36376932],
                            [39.38831806, -6.36412049],
                            [39.38772271, -6.36602582],
                            [39.38792116, -6.36630369],
                            [39.38994535, -6.36634338],
                            [39.39017853, -6.3644182],
                            [39.39021326, -6.36383271],
                            [39.39056551, -6.36253767],
                            [39.39088799, -6.36252775],
                            [39.39371591, -6.36270142],
                            [39.39711437, -6.36370867],
                            [39.39762041, -6.36387738],
                            [39.39814134, -6.36418997],
                            [39.3983001, -6.36446287],
                            [39.39833663, -6.36452297],
                            [39.3987615, -6.36522203],
                            [39.39899679, -6.36590184],
                            [39.39938661, -6.36702813],
                            [39.3994263, -6.3677724],
                            [39.3995553, -6.36819416],
                            [39.39954537, -6.36885408],
                            [39.39952579, -6.36910229],
                            [39.39947622, -6.36973064],
                            [39.3993618, -6.37043193],
                            [39.39950072, -6.37095292],
                            [39.40081048, -6.37305674],
                            [39.40106275, -6.37356617],
                            [39.40106847, -6.37357773],
                            [39.40173328, -6.37412353],
                            [39.40212473, -6.3750712],
                            [39.40205575, -6.37600405],
                            [39.40191684, -6.37681283],
                            [39.40215002, -6.3776613],
                            [39.40253203, -6.37819718],
                            [39.40255684, -6.37845023],
                            [39.40233854, -6.37876778],
                            [39.40260149, -6.38013228],
                            [39.40263125, -6.38058381],
                            [39.40250722, -6.38070289],
                            [39.40242784, -6.38099564],
                            [39.40240671, -6.38101798],
                            [39.4022542, -6.38117923],
                            [39.402279, -6.38130327],
                            [39.40219466, -6.38135785],
                            [39.40214009, -6.38123877],
                            [39.40198518, -6.3811679],
                            [39.40196644, -6.38144716],
                            [39.40190195, -6.3818193],
                            [39.40198629, -6.38200785],
                            [39.40223931, -6.38206739],
                            [39.4024328, -6.38235021],
                            [39.40255683, -6.3827422],
                            [39.40281481, -6.38282655],
                            [39.40300334, -6.3831193],
                            [39.40335559, -6.38349639],
                            [39.4038914, -6.38394296],
                            [39.40400551, -6.38412158],
                            [39.40405512, -6.38454334],
                            [39.40507218, -6.38510403],
                            [39.40524301, -6.38525589],
                            [39.405608, -6.38558036],
                            [39.40564326, -6.38563783],
                            [39.4058759, -6.386017],
                            [39.40617358, -6.38677616],
                            [39.40652582, -6.38763455],
                            [39.40648613, -6.38802654],
                            [39.40664489, -6.38873111],
                            [39.40695745, -6.38915783],
                            [39.40800427, -6.38969371],
                            [39.40874846, -6.39028417],
                            [39.40903125, -6.39032386],
                            [39.40913048, -6.39016508],
                            [39.40899156, -6.3896689],
                            [39.40888738, -6.38916776],
                            [39.4089618, -6.388746],
                            [39.40897172, -6.38828952],
                            [39.40934382, -6.38790746],
                            [39.40933885, -6.38766433],
                            [39.40912552, -6.38737158],
                            [39.40889234, -6.38703418],
                            [39.40882289, -6.38641891],
                            [39.40865917, -6.38631968],
                            [39.40864924, -6.38619563],
                            [39.40879312, -6.3861212],
                            [39.40867405, -6.38583838],
                            [39.4082573, -6.38575899],
                            [39.40821265, -6.38495518],
                            [39.40826971, -6.38427541],
                            [39.40855498, -6.38355595],
                            [39.40861204, -6.38311683],
                            [39.40852522, -6.38277446],
                            [39.40831436, -6.38252389],
                            [39.40849793, -6.38183668],
                            [39.40896429, -6.38087161],
                            [39.40912305, -6.38032084],
                            [39.40941329, -6.37982218],
                            [39.4093984, -6.37959394],
                            [39.40959685, -6.37931112],
                            [39.40958197, -6.37911512],
                            [39.4096936, -6.37878765],
                            [39.40983748, -6.37858173],
                            [39.40997887, -6.37824929],
                            [39.40999872, -6.37775807],
                            [39.40988709, -6.37744051],
                            [39.40968368, -6.3771304],
                            [39.40947779, -6.37699395],
                            [39.40955717, -6.37599414],
                            [39.40962167, -6.37529453],
                            [39.40965888, -6.37504892],
                            [39.40954229, -6.37474129],
                            [39.40922973, -6.37449568],
                            [39.40910074, -6.37360255],
                            [39.40905609, -6.37346858],
                            [39.40905113, -6.3732974],
                            [39.40910074, -6.37309644],
                            [39.40906105, -6.37289053],
                            [39.40955222, -6.37224549],
                            [39.40999625, -6.37188576],
                            [39.41035842, -6.37174931],
                            [39.41040803, -6.37179149],
                            [39.41052214, -6.37211153],
                            [39.41074788, -6.37234473],
                            [39.41148214, -6.37231],
                            [39.41190633, -6.37215122],
                            [39.41199315, -6.37201974],
                            [39.41195098, -6.37173443],
                            [39.41171036, -6.37130027],
                            [39.41147222, -6.37100256],
                            [39.41145486, -6.3707123],
                            [39.41171533, -6.37043692],
                            [39.41206261, -6.37020867],
                            [39.41228711, -6.37007719],
                            [39.41251161, -6.3699457],
                            [39.41267781, -6.36996555],
                            [39.41284401, -6.36998539],
                            [39.41308711, -6.37003005],
                            [39.4134468, -6.37013921],
                            [39.41359812, -6.37014665],
                            [39.41364029, -6.37000276],
                            [39.41361797, -6.36971498],
                            [39.41357332, -6.36904265],
                            [39.41345673, -6.36846708],
                            [39.41330293, -6.36830582],
                            [39.41313921, -6.36791632],
                            [39.41320867, -6.36771784],
                            [39.41369735, -6.36729609],
                            [39.41412154, -6.36682224],
                            [39.41392805, -6.36662377],
                            [39.41386604, -6.3663856],
                            [39.41392061, -6.36634094],
                            [39.41398263, -6.36642281],
                            [39.41405952, -6.36653942],
                            [39.41418356, -6.36653694],
                            [39.41424557, -6.36634094],
                            [39.41446139, -6.36615984],
                            [39.41459782, -6.3662293],
                            [39.4146524, -6.36626404],
                            [39.41496743, -6.36598369],
                            [39.41517085, -6.36584228],
                            [39.41526511, -6.36556194],
                            [39.41507658, -6.36524935],
                            [39.41511131, -6.36509553],
                            [39.41523534, -6.36497893],
                            [39.41509147, -6.36422721],
                            [39.41505426, -6.36405603],
                            [39.41506666, -6.36361691],
                            [39.41517829, -6.36312817],
                            [39.4152031, -6.36280565],
                            [39.41514357, -6.36272875],
                            [39.41522791, -6.36180337],
                            [39.41523039, -6.36132951],
                            [39.4153569, -6.36081845],
                            [39.415409, -6.36021063],
                            [39.41550078, -6.36006425],
                            [39.41537427, -6.35859804],
                            [39.41525272, -6.35831273],
                            [39.41526264, -6.3579406],
                            [39.41519561, -6.35760195],
                            [39.4151659, -6.35745186],
                            [39.4151752, -6.35731229],
                            [39.41520559, -6.35685644],
                            [39.41534699, -6.35596083],
                            [39.41545118, -6.35507763],
                            [39.41546358, -6.35449958],
                            [39.41558513, -6.35426885],
                            [39.41584312, -6.3540158],
                            [39.41586296, -6.35362382],
                            [39.4158332, -6.35282249],
                            [39.41573894, -6.35105112],
                            [39.41569429, -6.35058967],
                            [39.41559506, -6.3497412],
                            [39.41547351, -6.3487017],
                            [39.4153338, -6.3481231],
                            [39.41529739, -6.34797231],
                            [39.41516216, -6.34781775],
                            [39.41507165, -6.3477143],
                            [39.41489057, -6.34712384],
                            [39.41482607, -6.34683358],
                            [39.41459289, -6.34642919],
                            [39.41459041, -6.34642671],
                            [39.41450607, -6.34627785],
                            [39.41437212, -6.34627785],
                            [39.4143622, -6.34611411],
                            [39.41458793, -6.34597518],
                            [39.41476406, -6.34578415],
                            [39.41475166, -6.34552862],
                            [39.41468612, -6.34535412],
                            [39.4146505, -6.34525928],
                            [39.41463195, -6.3452099],
                            [39.41456809, -6.34503988],
                            [39.41417533, -6.34443197],
                            [39.41404716, -6.34423358],
                            [39.41369739, -6.34367538],
                            [39.41345925, -6.34347194],
                            [39.41322111, -6.34315935],
                            [39.41315662, -6.34296088],
                            [39.41288871, -6.34240763],
                            [39.41234793, -6.34179237],
                            [39.41198328, -6.34136069],
                            [39.41184933, -6.34113245],
                            [39.41169801, -6.34115726],
                            [39.41149956, -6.34112252],
                            [39.41148964, -6.34095878],
                            [39.41133832, -6.34062138],
                            [39.41113987, -6.34062882],
                            [39.41105553, -6.34053207],
                            [39.41099103, -6.34029638],
                            [39.41086948, -6.34022443],
                            [39.41079258, -6.3403584],
                            [39.41076388, -6.34038264],
                            [39.41068096, -6.34045268],
                            [39.41054452, -6.34043283],
                            [39.41048499, -6.3401252],
                            [39.41037584, -6.33973073],
                            [39.41017243, -6.3393834],
                            [39.40995661, -6.33912043],
                            [39.4098163, -6.33874023],
                            [39.40974328, -6.33854238],
                            [39.40959445, -6.33793952],
                            [39.40946545, -6.33710097],
                            [39.40941088, -6.33688513],
                            [39.40948282, -6.33674868],
                            [39.40953987, -6.33658246],
                            [39.40939848, -6.33645345],
                            [39.40931662, -6.3363046],
                            [39.40920003, -6.33621777],
                            [39.40914297, -6.33621528],
                            [39.40906359, -6.33621032],
                            [39.4089991, -6.33618303],
                            [39.40891228, -6.33609372],
                            [39.40887507, -6.33597216],
                            [39.40883538, -6.33588284],
                            [39.40869398, -6.33576376],
                            [39.40859972, -6.33560994],
                            [39.40857677, -6.33553241],
                            [39.40825739, -6.33509888],
                            [39.4077315, -6.33454315],
                            [39.40728003, -6.33427521],
                            [39.40719694, -6.33419829],
                            [39.40641181, -6.3334714],
                            [39.40602483, -6.33327789],
                            [39.40567754, -6.33311911],
                            [39.40556839, -6.33318857],
                            [39.40552374, -6.33351109],
                            [39.40546917, -6.3336004],
                            [39.40533025, -6.33356567],
                            [39.40529553, -6.33345155],
                            [39.4052856, -6.33330269],
                            [39.40493335, -6.33303476],
                            [39.40451165, -6.33301987],
                            [39.40434297, -6.33281147],
                            [39.40428839, -6.33263285],
                            [39.40471506, -6.33251873],
                            [39.40501274, -6.3327172],
                            [39.40509708, -6.33269983],
                            [39.40518142, -6.33268247],
                            [39.40520126, -6.33230537],
                            [39.4049532, -6.33215651],
                            [39.40502762, -6.331963],
                            [39.40509774, -6.33158164],
                            [39.40493832, -6.33131301],
                            [39.40471506, -6.33125346],
                            [39.40445708, -6.33104507],
                            [39.4044025, -6.33089125],
                            [39.40453191, -6.33073117],
                            [39.40431414, -6.33037181],
                            [39.4043132, -6.33037026],
                            [39.40431261, -6.33037018],
                            [39.40416933, -6.33035041],
                            [39.40386173, -6.33048934],
                            [39.40381915, -6.33048374],
                            [39.4036732, -6.33046453],
                            [39.40371785, -6.33029087],
                            [39.40396592, -6.33020156],
                            [39.40404481, -6.33008421],
                            [39.40415941, -6.32991377],
                            [39.40381587, -6.32882015],
                            [39.40348294, -6.32820461],
                            [39.40337218, -6.32799985],
                            [39.40326142, -6.32779508],
                            [39.403316, -6.32729393],
                            [39.40349108, -6.32639026],
                            [39.4034053, -6.32619241],
                            [39.40309274, -6.32614776],
                            [39.40223444, -6.32602867],
                            [39.40208065, -6.32605348],
                            [39.4019963, -6.32617752],
                            [39.4018636, -6.32628986],
                            [39.40180778, -6.32619737],
                            [39.40177305, -6.32595424],
                            [39.4016639, -6.32588478],
                            [39.40127692, -6.32587981],
                            [39.40101894, -6.32607333],
                            [39.40088551, -6.32608519],
                            [39.40057242, -6.32611302],
                            [39.40032932, -6.32598897],
                            [39.40017553, -6.32566645],
                            [39.40020033, -6.32537371],
                            [39.40025491, -6.32521493],
                            [39.40027615, -6.32520971],
                            [39.40055754, -6.3251405],
                            [39.40073615, -6.32480806],
                            [39.40053274, -6.32465921],
                            [39.40010607, -6.324426],
                            [39.40007134, -6.32427715],
                            [39.39981954, -6.3240762],
                            [39.39964963, -6.32382066],
                            [39.39947599, -6.32387028],
                            [39.39938173, -6.32383058],
                            [39.39933708, -6.32361226],
                            [39.39907459, -6.32348339],
                            [39.39906421, -6.32347829],
                            [39.39906568, -6.32347059],
                            [39.39910886, -6.32324509],
                            [39.39872684, -6.32283326],
                            [39.39852786, -6.3226658],
                            [39.39832002, -6.32249089],
                            [39.3983239, -6.32230863],
                            [39.39832498, -6.32225769],
                            [39.39842917, -6.32189051],
                            [39.39833987, -6.32149357],
                            [39.398067, -6.3211909],
                            [39.39764033, -6.32088822],
                            [39.39750322, -6.3208608],
                            [39.39749633, -6.32085942],
                            [39.39709459, -6.32077906],
                            [39.39702762, -6.32073193],
                            [39.39696064, -6.32068479],
                            [39.39679692, -6.32035235],
                            [39.39624126, -6.31980158],
                            [39.39580963, -6.31949891],
                            [39.3953532, -6.31897296],
                            [39.39448002, -6.31807983],
                            [39.39343911, -6.31735983],
                            [39.39335381, -6.31730083],
                            [39.39307598, -6.31707754],
                            [39.39284281, -6.31697335],
                            [39.39260467, -6.31719663],
                            [39.3925104, -6.31740502],
                            [39.39229211, -6.31751914],
                            [39.3920242, -6.3174993],
                            [39.39192001, -6.31725617],
                            [39.39211846, -6.31704281],
                            [39.39228715, -6.31674014],
                            [39.39207877, -6.31597602],
                            [39.3908087, -6.31469091],
                            [39.39060032, -6.31415007],
                            [39.39028777, -6.31399129],
                            [39.39007443, -6.31377793],
                            [39.38922606, -6.31321228],
                            [39.38820588, -6.31243338],
                            [39.3877228, -6.31185275],
                            [39.38750947, -6.31136649],
                            [39.3873904, -6.31074626],
                            [39.38722172, -6.31035924],
                            [39.38662141, -6.30981344],
                            [39.38637918, -6.30973141],
                            [39.38650691, -6.30921363],
                            [39.38655815, -6.3090059],
                            [39.38557583, -6.30772575],
                            [39.38572467, -6.30582042],
                            [39.38524929, -6.30419143],
                            [39.38493335, -6.30310878],
                            [39.3846456, -6.30258531],
                            [39.38460343, -6.30221318],
                            [39.3846311, -6.30213795],
                            [39.38497304, -6.30120841],
                            [39.38505364, -6.30082449],
                            [39.38578452, -6.30091846],
                            [39.38801708, -6.3009259],
                            [39.38871031, -6.30079931],
                            [39.3887133, -6.3024521],
                            [39.38872321, -6.30792139],
                            [39.38944936, -6.30804727],
                            [39.39038852, -6.30864762],
                            [39.39120181, -6.30886065],
                            [39.39154068, -6.30915115],
                            [39.39229588, -6.31003231],
                            [39.39250888, -6.31057457],
                            [39.39310917, -6.31099095],
                            [39.39360295, -6.31111683],
                            [39.39385468, -6.3114751],
                            [39.39423228, -6.31163972],
                            [39.39490034, -6.31150415],
                            [39.39494875, -6.31196894],
                            [39.39559745, -6.31253057],
                            [39.39636233, -6.31275328],
                            [39.39739831, -6.31265645],
                            [39.3975629, -6.31281138],
                            [39.39900553, -6.31334396],
                            [39.39979945, -6.31318903],
                            [39.40139699, -6.31397336],
                            [39.40209409, -6.31396368],
                            [39.40262661, -6.31366351],
                            [39.40281057, -6.31420576],
                            [39.40345926, -6.31429291],
                            [39.40469373, -6.31251606],
                            [39.40465984, -6.31191086],
                            [39.40543391, -6.3101272],
                            [39.4058701, -6.30912212],
                            [39.40621381, -6.30857503],
                            [39.40615088, -6.30801825],
                            [39.40581201, -6.30749052],
                            [39.40579749, -6.30716129],
                            [39.40610731, -6.306009],
                            [39.40626707, -6.30562167],
                            [39.40654301, -6.30549095],
                            [39.40670276, -6.30499227],
                            [39.40662046, -6.30455169],
                            [39.40678022, -6.30426119],
                            [39.40668824, -6.30388839],
                            [39.40672697, -6.30359306],
                            [39.40698354, -6.3030508],
                            [39.40743376, -6.30269253],
                            [39.40799048, -6.3021067],
                            [39.40869727, -6.3015596],
                            [39.40904409, -6.30140618],
                            [39.40920073, -6.30133689],
                            [39.40960738, -6.30085274],
                            [39.41065304, -6.30022818],
                            [39.41120976, -6.2998118],
                            [39.4112969, -6.29955036],
                            [39.41146149, -6.29940511],
                            [39.41179552, -6.29920661],
                            [39.41185846, -6.29927923],
                            [39.41285492, -6.29949753],
                            [39.41325267, -6.29966656],
                            [39.41358186, -6.29943416],
                            [39.41507289, -6.29945353],
                            [39.41540208, -6.29974403],
                            [39.41656393, -6.29945354],
                            [39.41763863, -6.29998611],
                            [39.41817115, -6.29997643],
                            [39.42171478, -6.29798171],
                            [39.42214146, -6.29756995],
                            [39.42281853, -6.29691657],
                            [39.42324199, -6.29651174],
                            [39.42347691, -6.29628716],
                            [39.4240191, -6.29604509],
                            [39.4248324, -6.29503804],
                            [39.4249393, -6.29473734],
                            [39.42576188, -6.2924236],
                            [39.42703991, -6.28899577],
                            [39.42714853, -6.28881652],
                            [39.42742719, -6.28835669],
                            [39.42752998, -6.28787378],
                            [39.42784353, -6.28640069],
                            [39.42827922, -6.28575192],
                            [39.42840509, -6.28552437],
                            [39.42853095, -6.28529682],
                            [39.42847286, -6.28505474],
                            [39.4282889, -6.28430914],
                            [39.42847286, -6.28339892],
                            [39.42843414, -6.28292445],
                            [39.42817272, -6.28179152],
                            [39.42817952, -6.28169178],
                            [39.42818725, -6.28157849],
                            [39.42822489, -6.28164969],
                            [39.42829859, -6.2817891],
                            [39.42836636, -6.28197066],
                            [39.42852994, -6.28277656],
                            [39.42860357, -6.28323189],
                            [39.42858905, -6.28332872],
                            [39.42854306, -6.28393392],
                            [39.42859147, -6.28441565],
                            [39.42875848, -6.28507653],
                            [39.42878269, -6.28535492],
                            [39.42868344, -6.28558973],
                            [39.42857331, -6.28574224],
                            [39.42846318, -6.28589475],
                            [39.42836152, -6.2861683],
                            [39.42833731, -6.2863668],
                            [39.42820902, -6.2864709],
                            [39.42803717, -6.28666214],
                            [39.42796455, -6.28705079],
                            [39.42797847, -6.28719477],
                            [39.42799239, -6.28733875],
                            [39.42813641, -6.28764861],
                            [39.42816908, -6.28763892],
                            [39.4283954, -6.28761593],
                            [39.4283712, -6.28766676],
                            [39.42825864, -6.28822233],
                            [39.42841355, -6.28837726],
                            [39.42794881, -6.28915191],
                            [39.4279004, -6.28939399],
                            [39.42785199, -6.28963607],
                            [39.42756153, -6.29010086],
                            [39.42721298, -6.29108854],
                            [39.42686442, -6.29207622],
                            [39.42645187, -6.29356687],
                            [39.42626413, -6.29424524],
                            [39.425659, -6.29544352],
                            [39.4255767, -6.29560814],
                            [39.4254581, -6.29584295],
                            [39.42541937, -6.29596399],
                            [39.4253758, -6.29613345],
                            [39.42531087, -6.29628878],
                            [39.42524593, -6.29644411],
                            [39.4251086, -6.29683897],
                            [39.42495706, -6.29699053],
                            [39.42440481, -6.29754284],
                            [39.42426748, -6.2979377],
                            [39.4245078, -6.29840123],
                            [39.42435331, -6.29857291],
                            [39.42394133, -6.29831539],
                            [39.42339202, -6.29819521],
                            [39.42013052, -6.30004932],
                            [39.41891175, -6.3007532],
                            [39.41805346, -6.30162875],
                            [39.41778113, -6.30206579],
                            [39.4165772, -6.30399789],
                            [39.4163, -6.30455894],
                            [39.41585623, -6.30545715],
                            [39.41542709, -6.30600651],
                            [39.41525073, -6.30607927],
                            [39.41405382, -6.30657305],
                            [39.41362467, -6.30844433],
                            [39.41314403, -6.30980058],
                            [39.41281788, -6.31227273],
                            [39.41197675, -6.31282209],
                            [39.41110402, -6.3139544],
                            [39.41070648, -6.31447019],
                            [39.41063694, -6.31499566],
                            [39.41055199, -6.3156376],
                            [39.41068931, -6.3173372],
                            [39.4115476, -6.31852178],
                            [39.41218273, -6.31986086],
                            [39.41278353, -6.32157763],
                            [39.41436278, -6.32138879],
                            [39.41626818, -6.32257337],
                            [39.41656, -6.32338025],
                            [39.41734962, -6.32399829],
                            [39.41839674, -6.32406696],
                            [39.41930652, -6.32494252],
                            [39.42047379, -6.32547472],
                            [39.42109176, -6.32662496],
                            [39.42148657, -6.3267623],
                            [39.42200155, -6.32696831],
                            [39.42213887, -6.3279297],
                            [39.42289416, -6.32890827],
                            [39.42333318, -6.32933578],
                            [39.42354646, -6.32954347],
                            [39.42448028, -6.33009447],
                            [39.42483057, -6.33096381],
                            [39.42460121, -6.33114844],
                            [39.42456461, -6.3311779],
                            [39.42459055, -6.33183316],
                            [39.4245192, -6.33289064],
                            [39.42482408, -6.33361726],
                            [39.42577765, -6.33589442],
                            [39.42616687, -6.33636802],
                            [39.42670528, -6.3365886],
                            [39.427003, -6.33699958],
                            [39.42762642, -6.33786018],
                            [39.42791184, -6.33824295],
                            [39.42865135, -6.33973511],
                            [39.42906002, -6.34030602],
                            [39.4290721, -6.34031957],
                            [39.42980601, -6.34114293],
                            [39.42989034, -6.3413181],
                            [39.42953356, -6.34164896],
                            [39.42956283, -6.34207823],
                            [39.42959194, -6.34250533],
                            [39.42973802, -6.34270609],
                            [39.43054552, -6.34381584],
                            [39.43081142, -6.34522224],
                            [39.43088283, -6.34559994],
                            [39.43088187, -6.34564692],
                            [39.43086337, -6.34654713],
                            [39.43101906, -6.34678069],
                            [39.43122664, -6.34712453],
                            [39.43144388, -6.34762916],
                            [39.43162882, -6.34805876],
                            [39.43206344, -6.34898649],
                            [39.43218345, -6.34938548],
                            [39.43230346, -6.34978447],
                            [39.43231643, -6.35030348],
                            [39.43217372, -6.35131555],
                            [39.43188829, -6.35170481],
                            [39.43189478, -6.3524444],
                            [39.43199208, -6.35293097],
                            [39.43201235, -6.35372003],
                            [39.43206424, -6.35787861],
                            [39.43236978, -6.35860375],
                            [39.43245345, -6.35974705],
                            [39.43235407, -6.36154085],
                            [39.43240155, -6.36262756],
                            [39.43310017, -6.36516104],
                            [39.43321749, -6.36639487],
                            [39.43323187, -6.3665461],
                            [39.43294644, -6.36719486],
                            [39.43317122, -6.36800004],
                            [39.43324313, -6.36888622],
                            [39.43325675, -6.36943569],
                            [39.43343555, -6.36987107],
                            [39.43343555, -6.3704775],
                            [39.4333511, -6.3722463],
                            [39.43335781, -6.37254568],
                            [39.43335781, -6.37273994],
                            [39.43331874, -6.37339777],
                            [39.43330132, -6.37369115],
                            [39.43329927, -6.37370727],
                            [39.43327197, -6.37392231],
                            [39.43322565, -6.3742871],
                            [39.43343554, -6.37475359],
                            [39.4336843, -6.37578762],
                            [39.43394084, -6.37633963],
                            [39.43403343, -6.3765962],
                            [39.4340419, -6.37714042],
                            [39.43419737, -6.37742031],
                            [39.43451634, -6.37783616],
                            [39.43463271, -6.37798787],
                            [39.43476486, -6.37864094],
                            [39.43488924, -6.37885863],
                            [39.43527005, -6.37921617],
                            [39.43524369, -6.37935789],
                            [39.43516996, -6.37975263],
                            [39.43518119, -6.37984986],
                            [39.43518341, -6.37986913],
                            [39.43519242, -6.37994709],
                            [39.43548005, -6.37998597],
                            [39.43572104, -6.38023476],
                            [39.436068, -6.38120068],
                            [39.43611607, -6.38147575],
                            [39.43616414, -6.38175082],
                            [39.43616866, -6.38186888],
                            [39.43618314, -6.38234774],
                            [39.43619574, -6.38257706],
                            [39.43620411, -6.38279593],
                            [39.43620583, -6.38284073],
                            [39.43621856, -6.3831736],
                            [39.43633012, -6.38345124],
                            [39.43645335, -6.38375792],
                            [39.43655283, -6.38400549],
                            [39.43668421, -6.38414564],
                            [39.43678605, -6.38425428],
                            [39.43673163, -6.38451862],
                            [39.43697262, -6.38528054],
                            [39.43713598, -6.38540622],
                            [39.43727579, -6.38551378],
                            [39.43734204, -6.38564678],
                            [39.43745342, -6.38587037],
                            [39.43748691, -6.38603118],
                            [39.43751678, -6.38617463],
                            [39.43747496, -6.38641163],
                            [39.43737685, -6.38696765],
                            [39.43757897, -6.38722422],
                            [39.43771644, -6.38739607],
                            [39.4380454, -6.38780732],
                            [39.43807649, -6.38818828],
                            [39.43828919, -6.38838464],
                            [39.43837967, -6.38846817],
                            [39.43849628, -6.38860812],
                            [39.43866354, -6.38903359],
                            [39.43904361, -6.38999204],
                            [39.43915212, -6.3902667],
                            [39.43930768, -6.39140551],
                            [39.43922056, -6.39244475],
                            [39.43956279, -6.39398805],
                            [39.44034057, -6.39599809],
                            [39.44055213, -6.39637769],
                            [39.44050235, -6.39659549],
                            [39.44063924, -6.39678841],
                            [39.44061585, -6.39706678],
                            [39.44069524, -6.3974356],
                            [39.44069464, -6.39743762],
                            [39.44058946, -6.39779031],
                            [39.44077302, -6.39795932],
                            [39.44081346, -6.39815124],
                            [39.44088548, -6.39925847],
                            [39.44095035, -6.39959498],
                            [39.44174058, -6.40034174],
                            [39.44218859, -6.40087692],
                            [39.44223836, -6.40126275],
                            [39.44237525, -6.40139343],
                            [39.4425557, -6.40161124],
                            [39.44296637, -6.40220242],
                            [39.44299126, -6.40240778],
                            [39.44297882, -6.40254469],
                            [39.44303191, -6.40322696],
                            [39.44315926, -6.40365238],
                            [39.44340193, -6.40413778],
                            [39.44361349, -6.40436803],
                            [39.44383127, -6.40405066],
                            [39.44391838, -6.4040631],
                            [39.44614596, -6.40589267],
                            [39.44715718, -6.40679313],
                            [39.45034601, -6.4096327],
                            [39.45088735, -6.41072173],
                            [39.45160913, -6.41159295],
                            [39.45167758, -6.41253262],
                            [39.45187047, -6.41272553],
                            [39.45361099, -6.41443529],
                            [39.45361893, -6.41444309],
                            [39.45372009, -6.41464466],
                            [39.45444027, -6.41607974],
                            [39.45475761, -6.41709409],
                            [39.45482605, -6.41753592],
                            [39.45482457, -6.41765254],
                            [39.45481983, -6.41802754],
                            [39.4548136, -6.41843826],
                            [39.45466725, -6.41866696],
                            [39.45461449, -6.4187494],
                            [39.45452738, -6.41946505],
                            [39.45463938, -6.41986955],
                            [39.45483227, -6.419938],
                            [39.45489449, -6.4201558],
                            [39.45502739, -6.42025459],
                            [39.45535494, -6.42049807],
                            [39.45603317, -6.42114526],
                            [39.45615762, -6.42148131],
                            [39.45597717, -6.42159954],
                            [39.45585895, -6.42186713],
                            [39.45570339, -6.42192314],
                            [39.45554783, -6.42215339],
                            [39.45549183, -6.42230896],
                            [39.45559139, -6.42237742],
                            [39.45554161, -6.42250188],
                            [39.4556225, -6.42278814],
                            [39.45587761, -6.42319263],
                            [39.45575939, -6.42326731],
                            [39.45562281, -6.42344762],
                            [39.4556785, -6.42355979],
                            [39.45579672, -6.42369047],
                            [39.45587761, -6.4239954],
                            [39.45589627, -6.4244559],
                            [39.45593983, -6.42471415],
                            [39.45598339, -6.42497241],
                            [39.45597716, -6.42523378],
                            [39.45578427, -6.42572539],
                            [39.45580916, -6.42590586],
                            [39.45573449, -6.42621079],
                            [39.4557656, -6.42638503],
                            [39.45589627, -6.42676463],
                            [39.45585271, -6.42696377],
                            [39.45569716, -6.42706956],
                            [39.45569011, -6.42706604],
                            [39.4555976, -6.42701978],
                            [39.4554477, -6.42704421],
                            [39.45559052, -6.42736656],
                            [39.45566194, -6.42752773],
                            [39.45573335, -6.4276889],
                            [39.45602694, -6.4283515],
                            [39.45635361, -6.42887423],
                            [39.4564569, -6.42903953],
                            [39.45668028, -6.42939696],
                            [39.45754679, -6.43087634],
                            [39.45773186, -6.4311923],
                            [39.45791229, -6.43150034],
                            [39.45817985, -6.43182394],
                            [39.45876474, -6.43282584],
                            [39.45878922, -6.43286151],
                            [39.45898252, -6.43314322],
                            [39.45904655, -6.43341284],
                            [39.45922853, -6.43380753],
                            [39.45949897, -6.43439404],
                            [39.45969808, -6.43449361],
                            [39.45986519, -6.43459684],
                            [39.46054431, -6.43501634],
                            [39.46132832, -6.43565731],
                            [39.46135943, -6.43594357],
                            [39.46123499, -6.4361676],
                            [39.46107943, -6.4362485],
                            [39.46079943, -6.43627961],
                            [39.46071597, -6.43632315],
                            [39.46053433, -6.43676431],
                            [39.46054731, -6.43710815],
                            [39.46052554, -6.43719521],
                            [39.46039811, -6.43770501],
                            [39.46055379, -6.43814617],
                            [39.46083922, -6.43831485],
                            [39.46103382, -6.43868465],
                            [39.46166684, -6.4390797],
                            [39.46184469, -6.43919069],
                            [39.46177649, -6.4396309],
                            [39.46177333, -6.43965131],
                            [39.46178708, -6.43968414],
                            [39.46197442, -6.4401314],
                            [39.4621545, -6.44053762],
                            [39.46255824, -6.44144839],
                            [39.46286313, -6.44216203],
                            [39.46286557, -6.4421788],
                            [39.46300584, -6.44314166],
                            [39.4632199, -6.44357633],
                            [39.4635702, -6.44400452],
                            [39.46380132, -6.44443428],
                            [39.46393809, -6.4446886],
                            [39.46402354, -6.44494227],
                            [39.46419281, -6.44544482],
                            [39.46469444, -6.44693404],
                            [39.46482216, -6.44731322],
                            [39.46508813, -6.44751433],
                            [39.46538634, -6.44772992],
                            [39.46586677, -6.44807722],
                            [39.46628172, -6.44837719],
                            [39.46673769, -6.44867446],
                            [39.46715745, -6.44894811],
                            [39.46744936, -6.44911679],
                            [39.46759856, -6.44935359],
                            [39.46774776, -6.44959038],
                            [39.46813048, -6.44994721],
                            [39.46808507, -6.45014183],
                            [39.46857808, -6.45054407],
                            [39.46921379, -6.45092684],
                            [39.46970031, -6.45117337],
                            [39.47282641, -6.4537213],
                            [39.47321621, -6.45430042],
                            [39.47352714, -6.45470899],
                            [39.47466279, -6.4562013],
                            [39.47546068, -6.45721986],
                            [39.47539581, -6.45737557],
                            [39.47592125, -6.45794648],
                            [39.47662183, -6.45885475],
                            [39.47720565, -6.45923752],
                            [39.47730944, -6.45951649],
                            [39.47775704, -6.45989277],
                            [39.4780749, -6.45999009],
                            [39.47863277, -6.46047018],
                            [39.47906739, -6.46096324],
                            [39.47902847, -6.46114489],
                            [39.47898955, -6.46156659],
                            [39.47919064, -6.46193638],
                            [39.47945012, -6.46216345],
                            [39.48053343, -6.46306524],
                            [39.48107488, -6.46349251],
                            [39.48117563, -6.46362966],
                            [39.48111725, -6.46377239],
                            [39.48128591, -6.46385024],
                            [39.48155187, -6.46412272],
                            [39.48192811, -6.46451847],
                            [39.48197395, -6.46486974],
                            [39.48202967, -6.464946],
                            [39.48251842, -6.46561488],
                            [39.48275843, -6.46584195],
                            [39.48294655, -6.46620526],
                            [39.48297693, -6.46629261],
                            [39.48305034, -6.46650369],
                            [39.48294007, -6.46675022],
                            [39.48255085, -6.46723679],
                            [39.48231062, -6.46764356],
                            [39.48238219, -6.46774931],
                            [39.48265464, -6.46793746],
                            [39.48290763, -6.4681775],
                            [39.48318008, -6.46825535],
                            [39.48343307, -6.46841754],
                            [39.48364714, -6.46846296],
                            [39.48387418, -6.46826184],
                            [39.48429583, -6.46796341],
                            [39.48462666, -6.46802829],
                            [39.48512615, -6.46817102],
                            [39.48541806, -6.46843701],
                            [39.48551254, -6.46847384],
                            [39.48580079, -6.46858623],
                            [39.48585268, -6.46880681],
                            [39.48593067, -6.4692868],
                            [39.48593701, -6.46932582],
                            [39.48588512, -6.46950098],
                            [39.48587214, -6.4698059],
                            [39.48606675, -6.47016272],
                            [39.48623541, -6.47063632],
                            [39.48650786, -6.47084393],
                            [39.48657921, -6.47103856],
                            [39.48671544, -6.47138889],
                            [39.48726034, -6.47194034],
                            [39.48769496, -6.47200522],
                            [39.48816202, -6.47230365],
                            [39.48826581, -6.47253072],
                            [39.48853826, -6.47275778],
                            [39.48892747, -6.472907],
                            [39.48940101, -6.4732768],
                            [39.48988753, -6.47338709],
                            [39.4906465, -6.47358172],
                            [39.49115248, -6.47376986],
                            [39.49147034, -6.47391908],
                            [39.49172981, -6.47400342],
                            [39.49207326, -6.47403731],
                            [39.4921839, -6.4741721],
                            [39.49246932, -6.47432131],
                            [39.4927288, -6.47447053],
                            [39.49341641, -6.47465218],
                            [39.49387698, -6.47475599],
                            [39.49427268, -6.47503495],
                            [39.49453216, -6.47501549],
                            [39.49473325, -6.47505442],
                            [39.49469433, -6.47468462],
                            [39.49458405, -6.47436673],
                            [39.4944608, -6.47430185],
                            [39.49431809, -6.47414615],
                            [39.49429214, -6.4740164],
                            [39.49446729, -6.47381528],
                            [39.49475271, -6.47369201],
                            [39.4949603, -6.4736466],
                            [39.49531059, -6.47351036],
                            [39.49579062, -6.47341954],
                            [39.49660797, -6.47331573],
                            [39.49700548, -6.47319587],
                            [39.49706485, -6.47327835],
                            [39.49712009, -6.47344849],
                            [39.49717532, -6.47361862],
                            [39.49726959, -6.47379541],
                            [39.49733291, -6.47384311],
                            [39.4973968, -6.47384322],
                            [39.49750424, -6.47381951],
                            [39.49760194, -6.47378961],
                            [39.49761328, -6.47379373],
                            [39.49828285, -6.47417791],
                            [39.49829175, -6.47420776],
                            [39.49861592, -6.47431914],
                            [39.49882263, -6.47435054],
                            [39.49902158, -6.47436947],
                            [39.4993705, -6.47437907],
                            [39.49967329, -6.47443362],
                            [39.50004424, -6.47447743],
                            [39.50026117, -6.47453582],
                            [39.50059719, -6.4746421],
                            [39.50069935, -6.47465945],
                            [39.50099016, -6.47470882],
                            [39.50107648, -6.4747093],
                            [39.50171412, -6.47465977],
                            [39.50201707, -6.4747233],
                            [39.50216855, -6.47475506],
                            [39.50232002, -6.47478682],
                            [39.5024735, -6.47477857],
                            [39.50252708, -6.4747135],
                            [39.50252645, -6.47465359],
                            [39.50252733, -6.47463663],
                            [39.50253349, -6.47451794],
                            [39.50259876, -6.47448167],
                            [39.50279406, -6.47447477],
                            [39.50288512, -6.47447156],
                            [39.50291308, -6.47447057],
                            [39.50308889, -6.47442842],
                            [39.50311059, -6.47442839],
                            [39.50316312, -6.47442831],
                            [39.50320335, -6.4744302],
                            [39.50324029, -6.47443244],
                            [39.50330782, -6.47445149],
                            [39.50337534, -6.47447053],
                            [39.50338472, -6.47447318],
                            [39.50339409, -6.47447582],
                            [39.50351186, -6.47449362],
                            [39.50351657, -6.4744943],
                            [39.5035707, -6.47454496],
                            [39.50362482, -6.47459562],
                            [39.50351072, -6.47473159],
                            [39.50349183, -6.47475411],
                            [39.50346296, -6.47486133],
                            [39.50349183, -6.4749768],
                            [39.5035578, -6.47506341],
                            [39.50370212, -6.47508403],
                            [39.50383408, -6.47512527],
                            [39.50413168, -6.47528172],
                            [39.50427995, -6.47537493],
                            [39.50442822, -6.47546813],
                            [39.50461754, -6.4755954],
                            [39.50477827, -6.47567139],
                            [39.50484434, -6.47570263],
                            [39.50503402, -6.47569026],
                            [39.50544225, -6.47579748],
                            [39.50571852, -6.47594182],
                            [39.5059247, -6.47590058],
                            [39.50602779, -6.47595832],
                            [39.50624471, -6.47602131],
                            [39.50638241, -6.47598306],
                            [39.50645663, -6.47601399],
                            [39.50653085, -6.47604492],
                            [39.50672569, -6.47605019],
                            [39.50683599, -6.47605317],
                            [39.50720972, -6.47598144],
                            [39.50726822, -6.47597596],
                            [39.50733082, -6.47597894],
                            [39.50752462, -6.47593357],
                            [39.50767307, -6.47593151],
                            [39.50782152, -6.47592945],
                            [39.50804285, -6.47588177],
                            [39.50828981, -6.47584815],
                            [39.50859674, -6.47576862],
                            [39.50915967, -6.47573672],
                            [39.50952865, -6.47563253],
                            [39.51015125, -6.47552156],
                            [39.51050318, -6.47547577],
                            [39.51062963, -6.47545932],
                            [39.5108458, -6.4753801],
                            [39.51106197, -6.47530088],
                            [39.51125228, -6.47522013],
                            [39.51172181, -6.47505969],
                            [39.51197804, -6.47490359],
                            [39.51214296, -6.4748366],
                            [39.51229324, -6.47489526],
                            [39.5125182, -6.47492321],
                            [39.51269864, -6.47485828],
                            [39.51307343, -6.47479957],
                            [39.51321847, -6.47477685],
                            [39.51338169, -6.47475246],
                            [39.51385834, -6.47470876],
                            [39.51419647, -6.47457267],
                            [39.5145346, -6.47438297],
                            [39.51493106, -6.47424788],
                            [39.51513663, -6.47418914],
                            [39.51516962, -6.47413141],
                            [39.51516962, -6.47401594],
                            [39.51520261, -6.47388809],
                            [39.51542116, -6.47371901],
                            [39.51554486, -6.47369839],
                            [39.51566801, -6.47355984],
                            [39.51576341, -6.47342621],
                            [39.51596134, -6.47334373],
                            [39.5161469, -6.47319527],
                            [39.51640429, -6.47311018],
                            [39.5167077, -6.4730798],
                            [39.51685202, -6.4729767],
                            [39.51705407, -6.47285298],
                            [39.51722325, -6.47279914],
                            [39.51751802, -6.47269591],
                            [39.51777981, -6.47257667],
                            [39.51797256, -6.47250029],
                            [39.51822515, -6.47237047],
                            [39.51840246, -6.4723746],
                            [39.51855916, -6.47229624],
                            [39.51873647, -6.4721519],
                            [39.51887502, -6.47208647],
                            [39.51909613, -6.47200566],
                            [39.51923954, -6.47184673],
                            [39.5193385, -6.47176013],
                            [39.51965682, -6.47173721],
                            [39.51980034, -6.47172714],
                            [39.52000239, -6.47167352],
                            [39.52010802, -6.47158637],
                            [39.52016733, -6.47153743],
                            [39.52026629, -6.47150032],
                            [39.52034876, -6.47150032],
                            [39.52053845, -6.47145083],
                            [39.52075287, -6.47146733],
                            [39.52083122, -6.47138072],
                            [39.52098378, -6.47140959],
                            [39.52103327, -6.47131886],
                            [39.52133081, -6.47114922],
                            [39.52145799, -6.47106318],
                            [39.52164355, -6.47080337],
                            [39.52174664, -6.47075388],
                            [39.52193989, -6.47070468],
                            [39.52197343, -6.47069614],
                            [39.52212333, -6.47061762],
                            [39.52229446, -6.47055144],
                            [39.5224064, -6.47051057],
                            [39.52259608, -6.47049407],
                            [39.52269924, -6.47046593],
                            [39.52280239, -6.4704378],
                            [39.52286823, -6.47041984],
                            [39.52297545, -6.47034973],
                            [39.52306204, -6.47024663],
                            [39.52323523, -6.47012704],
                            [39.52349501, -6.47004868],
                            [39.52371768, -6.47004043],
                            [39.52407531, -6.46995298],
                            [39.52448465, -6.46983424],
                            [39.52455888, -6.46968165],
                            [39.52464135, -6.46960742],
                            [39.52480216, -6.46954968],
                            [39.52492587, -6.46940534],
                            [39.52513617, -6.46936823],
                            [39.52546274, -6.46920225],
                            [39.52563924, -6.46911254],
                            [39.52590314, -6.4688981],
                            [39.52612994, -6.46882799],
                            [39.52621653, -6.46876613],
                            [39.52632374, -6.46865478],
                            [39.52657115, -6.46855581],
                            [39.52715257, -6.46840734],
                            [39.52758562, -6.46815373],
                            [39.52791901, -6.46796394],
                            [39.52820819, -6.46782174],
                            [39.52847622, -6.46774751],
                            [39.52874837, -6.46758667],
                            [39.52892981, -6.46754956],
                            [39.52919371, -6.46738872],
                            [39.52932154, -6.46725263],
                            [39.52964318, -6.46722377],
                            [39.52988646, -6.46696808],
                            [39.5300017, -6.46685283],
                            [39.53006021, -6.46679922],
                            [39.53021319, -6.46667172],
                            [39.53041283, -6.46643939],
                            [39.53043663, -6.46641634],
                            [39.53064519, -6.46624639],
                            [39.53109053, -6.46606081],
                            [39.53163591, -6.46584712],
                            [39.53171318, -6.46581749],
                            [39.53190944, -6.46563506],
                            [39.53206466, -6.46551679],
                            [39.53217502, -6.46544221],
                            [39.53223687, -6.465302],
                            [39.53245763, -6.46520474],
                            [39.53249665, -6.46518653],
                            [39.53263685, -6.4650793],
                            [39.53273169, -6.46498445],
                            [39.53299147, -6.46477825],
                            [39.53330898, -6.46463804],
                            [39.5334863, -6.46444009],
                            [39.53379372, -6.46429523],
                            [39.53390087, -6.46424474],
                            [39.53392761, -6.46422816],
                            [39.53414302, -6.46405789],
                            [39.53419708, -6.46400313],
                            [39.53425114, -6.46394838],
                            [39.53449244, -6.4636854],
                            [39.53459346, -6.46360498],
                            [39.53469449, -6.46352456],
                            [39.53490678, -6.46340071],
                            [39.5349419, -6.46338023],
                            [39.53502025, -6.46330599],
                            [39.5350821, -6.4631864],
                            [39.53528315, -6.46309538],
                            [39.53538724, -6.46304206],
                            [39.53573362, -6.46274926],
                            [39.53582693, -6.46263629],
                            [39.536047, -6.46235336],
                            [39.53629375, -6.46216704],
                            [39.53639956, -6.46204024],
                            [39.53668203, -6.46170177],
                            [39.53673151, -6.46165641],
                            [39.53677274, -6.46160279],
                            [39.53687583, -6.46150794],
                            [39.53695005, -6.46137597],
                            [39.53698304, -6.46122751],
                            [39.53716448, -6.46108317],
                            [39.53727994, -6.46094708],
                            [39.53731705, -6.46083161],
                            [39.53760982, -6.46075738],
                            [39.53765518, -6.46066253],
                            [39.5377294, -6.46062129],
                            [39.5377562, -6.46061304],
                            [39.53778301, -6.46060479],
                            [39.53785311, -6.46050994],
                            [39.53792733, -6.46045633],
                            [39.53796444, -6.46041303],
                            [39.53800155, -6.46036973],
                            [39.53817474, -6.46036148],
                            [39.53831082, -6.46012641],
                            [39.53838916, -6.46009342],
                            [39.53852936, -6.4599532],
                            [39.53854998, -6.45988722],
                            [39.53863245, -6.45989959],
                            [39.5386943, -6.45987073],
                            [39.53875616, -6.45984186],
                            [39.53880152, -6.45967277],
                            [39.53887693, -6.45956593],
                            [39.53896233, -6.45944596],
                            [39.53896646, -6.45937172],
                            [39.53894172, -6.45936348],
                            [39.53889878, -6.45938661],
                            [39.53883239, -6.4594309],
                            [39.53883451, -6.45945833],
                            [39.53862833, -6.45964391],
                            [39.53859122, -6.45962329],
                            [39.53859946, -6.45957792],
                            [39.53859122, -6.45956143],
                            [39.53865307, -6.45952637],
                            [39.53876284, -6.45943455],
                            [39.53882626, -6.4593676],
                            [39.53892522, -6.45921501],
                            [39.53911903, -6.45900057],
                            [39.53932108, -6.45891396],
                            [39.53936232, -6.45883148],
                            [39.53932108, -6.45872014],
                            [39.53947213, -6.45856035],
                            [39.53961797, -6.45843146],
                            [39.53978292, -6.45834485],
                            [39.53987363, -6.4581634],
                            [39.54001383, -6.4580768],
                            [39.54018308, -6.45791911],
                            [39.5403066, -6.45778399],
                            [39.54047154, -6.4576479],
                            [39.54058288, -6.45751594],
                            [39.54070246, -6.45747057],
                            [39.54081726, -6.45738225],
                            [39.54088172, -6.45732656],
                            [39.54095412, -6.4572495],
                            [39.54104471, -6.45717777],
                            [39.54105708, -6.45700869],
                            [39.54116017, -6.45698807],
                            [39.54122202, -6.45686435],
                            [39.54140758, -6.45679836],
                            [39.54146944, -6.45671176],
                            [39.54146119, -6.45665815],
                            [39.54151067, -6.45660454],
                            [39.54158902, -6.45647669],
                            [39.54171685, -6.45646845],
                            [39.54179519, -6.45643958],
                            [39.54194364, -6.45642308],
                            [39.54199312, -6.45642308],
                            [39.54203024, -6.45629936],
                            [39.54206735, -6.45627462],
                            [39.54213539, -6.45624163],
                            [39.54217043, -6.45622101],
                            [39.54220342, -6.45617977],
                            [39.54222816, -6.45612616],
                            [39.54230651, -6.45607255],
                            [39.54243434, -6.45601069],
                            [39.54253355, -6.45591972],
                            [39.54258085, -6.45587241],
                            [39.54263222, -6.45582395],
                            [39.54268359, -6.45577549],
                            [39.54277073, -6.45569751],
                            [39.5429704, -6.45549519],
                            [39.5430405, -6.45544158],
                            [39.54314359, -6.45533023],
                            [39.54329203, -6.45523538],
                            [39.54339512, -6.45510341],
                            [39.5434446, -6.45507042],
                            [39.54350233, -6.45500444],
                            [39.54350113, -6.45494654],
                            [39.54348996, -6.45486835],
                            [39.54355181, -6.45479411],
                            [39.54372088, -6.45479824],
                            [39.54372913, -6.45471163],
                            [39.54370438, -6.45460441],
                            [39.5436549, -6.45446832],
                            [39.54362604, -6.45440234],
                            [39.54361367, -6.45429924],
                            [39.54368789, -6.4541549],
                            [39.54381984, -6.45414253],
                            [39.54403014, -6.45407654],
                            [39.54418997, -6.45400146],
                            [39.54455383, -6.45385797],
                            [39.54471877, -6.45388684],
                            [39.54497443, -6.45388272],
                            [39.54497855, -6.4538621],
                            [39.5450301, -6.45383941],
                            [39.54508164, -6.45381673],
                            [39.54518602, -6.45361707],
                            [39.54522184, -6.45354867],
                            [39.54532493, -6.45342908],
                            [39.545577, -6.45324642],
                            [39.5457414, -6.45313215],
                            [39.54575378, -6.45299194],
                            [39.54567543, -6.45266202],
                            [39.54570017, -6.45259604],
                            [39.54582388, -6.45241046],
                            [39.54593727, -6.4523754],
                            [39.54607335, -6.45236097],
                            [39.5462218, -6.45232385],
                            [39.54631457, -6.4522723],
                            [39.54637024, -6.4521857],
                            [39.54638983, -6.45211353],
                            [39.54640942, -6.45204136],
                            [39.54642797, -6.45201456],
                            [39.5465125, -6.45199806],
                            [39.54661767, -6.45194196],
                            [39.54663621, -6.45193208],
                            [39.54666507, -6.45192589],
                            [39.54670219, -6.45188053],
                            [39.54671559, -6.45180011],
                            [39.54672899, -6.45171969],
                            [39.54677641, -6.45167227],
                            [39.54695166, -6.45169289],
                            [39.54701351, -6.4516867],
                            [39.54715577, -6.45163927],
                            [39.54724494, -6.45154185],
                            [39.54735989, -6.4514372],
                            [39.54742793, -6.45141864],
                            [39.54753094, -6.45141802],
                            [39.54778461, -6.45136091],
                            [39.54784028, -6.45130523],
                            [39.5479011, -6.45127224],
                            [39.54796192, -6.45123925],
                            [39.54800728, -6.45118151],
                            [39.54814129, -6.45113821],
                            [39.54834747, -6.45099387],
                            [39.54839077, -6.45092377],
                            [39.54842582, -6.45089696],
                            [39.54850449, -6.45086962],
                            [39.54858317, -6.45084228],
                            [39.54866565, -6.45084492],
                            [39.54869178, -6.45088047],
                            [39.5487374, -6.45090079],
                            [39.54880312, -6.45091861],
                            [39.54886085, -6.4509114],
                            [39.54894126, -6.45094851],
                            [39.54898868, -6.4509382],
                            [39.54902167, -6.4508784],
                            [39.5490681, -6.45080987],
                            [39.54907115, -6.45077324],
                            [39.5490629, -6.45073613],
                            [39.5490361, -6.45071138],
                            [39.54899486, -6.45070313],
                            [39.54897837, -6.45073819],
                            [39.54894332, -6.45075881],
                            [39.54880312, -6.45061653],
                            [39.54880621, -6.45059488],
                            [39.5488093, -6.45057323],
                            [39.54886271, -6.45051475],
                            [39.54897631, -6.45043508],
                            [39.54910864, -6.45043301],
                            [39.54919176, -6.45043817],
                            [39.54923403, -6.45043714],
                            [39.54926083, -6.45042064],
                            [39.54926083, -6.45036084],
                            [39.54923609, -6.45028661],
                            [39.54927939, -6.450233],
                            [39.54934124, -6.45018351],
                            [39.54937217, -6.45011547],
                            [39.5494299, -6.45007732],
                            [39.54948762, -6.45003917],
                            [39.5496938, -6.44974225],
                            [39.54973091, -6.44965152],
                            [39.54981438, -6.44953936],
                            [39.54987111, -6.44946594],
                            [39.54998857, -6.44934901],
                            [39.55002987, -6.44930717],
                            [39.55005049, -6.44924325],
                            [39.55019783, -6.44904496],
                            [39.55036781, -6.4488417],
                            [39.55043826, -6.44876138],
                            [39.55045871, -6.44873806],
                            [39.55054325, -6.44858754],
                            [39.55060098, -6.44854011],
                            [39.55062984, -6.44848031],
                            [39.55065664, -6.44846382],
                            [39.55073499, -6.44843289],
                            [39.55083382, -6.44835737],
                            [39.55090672, -6.44830167],
                            [39.55093265, -6.44828186],
                            [39.55094529, -6.44827205],
                            [39.55108185, -6.44809186],
                            [39.55111652, -6.44805285],
                            [39.55115119, -6.44801384],
                            [39.55117827, -6.44798337],
                            [39.55122474, -6.4478922],
                            [39.55123188, -6.44787821],
                            [39.55123165, -6.44787821],
                            [39.55125821, -6.44782615],
                            [39.55127161, -6.44779986],
                            [39.55128501, -6.44777357],
                            [39.55142933, -6.44764985],
                            [39.5514486, -6.44761671],
                            [39.55146692, -6.4475852],
                            [39.55150482, -6.44752061],
                            [39.55154273, -6.44745602],
                            [39.55157572, -6.44738385],
                            [39.55167811, -6.44731838],
                            [39.551926, -6.44719169],
                            [39.55215095, -6.44706837],
                            [39.55220249, -6.44703332],
                            [39.55225404, -6.44699826],
                            [39.55241073, -6.44695702],
                            [39.552571, -6.44692714],
                            [39.55273127, -6.44689726],
                            [39.55293648, -6.44684568],
                            [39.55299215, -6.44676732],
                            [39.55309317, -6.44671577],
                            [39.5531942, -6.44666422],
                            [39.55334418, -6.4465229],
                            [39.55345604, -6.44646421],
                            [39.55357137, -6.44637236],
                            [39.55362305, -6.44631781],
                            [39.55377021, -6.44623841],
                            [39.55381685, -6.44615285],
                            [39.55389726, -6.44606625],
                            [39.55398592, -6.44601676],
                            [39.55405808, -6.44594871],
                            [39.55408488, -6.44586829],
                            [39.5541591, -6.44584355],
                            [39.55421477, -6.44578788],
                            [39.5543158, -6.4457487],
                            [39.55444981, -6.44564148],
                            [39.55454053, -6.44550594],
                            [39.55456321, -6.4454394],
                            [39.55458589, -6.44532805],
                            [39.55464362, -6.44528888],
                            [39.55474258, -6.44526619],
                            [39.55476938, -6.44522495],
                            [39.55474464, -6.4451631],
                            [39.55477561, -6.44505658],
                            [39.55479413, -6.44495896],
                            [39.55487247, -6.44485792],
                            [39.55492547, -6.44485058],
                            [39.55502623, -6.44482094],
                            [39.55514875, -6.44480019],
                            [39.55523998, -6.44473886],
                            [39.55531474, -6.44465048],
                            [39.55539993, -6.44453909],
                            [39.55541472, -6.44451976],
                            [39.55542709, -6.44447542],
                            [39.55543327, -6.44445326],
                            [39.55543946, -6.44443109],
                            [39.55544977, -6.44440841],
                            [39.55546007, -6.44438573],
                            [39.55547583, -6.44436192],
                            [39.55547955, -6.44435631],
                            [39.55555051, -6.44431281],
                            [39.555594, -6.44426473],
                            [39.55560587, -6.44425626],
                            [39.55564208, -6.44423039],
                            [39.55573135, -6.44419834],
                            [39.55591219, -6.4440793],
                            [39.55591805, -6.44406216],
                            [39.55594194, -6.4439923],
                            [39.55594213, -6.44399195],
                            [39.55597857, -6.44392362],
                            [39.55597628, -6.44383205],
                            [39.55613881, -6.44377711],
                            [39.55619563, -6.44371188],
                            [39.55634024, -6.44354588],
                            [39.55637458, -6.44344286],
                            [39.55640892, -6.44330779],
                            [39.55647285, -6.44325713],
                            [39.5565429, -6.44323065],
                            [39.55660699, -6.44302919],
                            [39.55691373, -6.44280026],
                            [39.55690458, -6.44269495],
                            [39.55692289, -6.44259193],
                            [39.5569412, -6.44248891],
                            [39.55705108, -6.44242023],
                            [39.55725251, -6.44245686],
                            [39.55745395, -6.44227829],
                            [39.55747685, -6.44207225],
                            [39.55760046, -6.44211346],
                            [39.55759588, -6.44200815],
                            [39.55761877, -6.44193947],
                            [39.55772407, -6.44183874],
                            [39.55782478, -6.44166475],
                            [39.55786141, -6.44144955],
                            [39.55790719, -6.4413763],
                            [39.55795297, -6.44130304],
                            [39.55805369, -6.44117483],
                            [39.55814526, -6.44111989],
                            [39.55823224, -6.44106495],
                            [39.55823224, -6.44095506],
                            [39.55823224, -6.4408818],
                            [39.55833117, -6.44081242],
                            [39.55843647, -6.4407964],
                            [39.55858984, -6.44084676],
                            [39.55869514, -6.44084905],
                            [39.55879128, -6.44075977],
                            [39.55879586, -6.44071169],
                            [39.55877617, -6.44065835],
                            [39.55883019, -6.44057433],
                            [39.55879586, -6.44054457],
                            [39.55871803, -6.44052397],
                            [39.55869285, -6.44050336],
                            [39.5587455, -6.44043468],
                            [39.55883706, -6.44038203],
                            [39.55888284, -6.44029732],
                            [39.55888971, -6.44025383],
                            [39.55900645, -6.44023551],
                            [39.55913235, -6.44016912],
                            [39.5591747, -6.44013478],
                            [39.55921705, -6.44010044],
                            [39.55927198, -6.44003634],
                            [39.55934981, -6.44000658],
                            [39.55940475, -6.43994706],
                            [39.55943909, -6.43986006],
                            [39.5594986, -6.43979367],
                            [39.55959474, -6.43975017],
                            [39.55966342, -6.43967005],
                            [39.55968173, -6.43958763],
                            [39.55976414, -6.4394434],
                            [39.55993353, -6.43925339],
                            [39.55999076, -6.43915495],
                            [39.55999076, -6.43908856],
                            [39.56024027, -6.43874516],
                            [39.5603593, -6.43868106],
                            [39.56051267, -6.43860551],
                            [39.56054242, -6.43854599],
                            [39.56053785, -6.43838802],
                            [39.56062254, -6.43833308],
                            [39.56057905, -6.43821632],
                            [39.56063857, -6.43810643],
                            [39.56072784, -6.43802631],
                            [39.56085145, -6.43796221],
                            [39.56099566, -6.43795534],
                            [39.56104144, -6.43795076],
                            [39.56099795, -6.43784316],
                            [39.56096133, -6.43767833],
                            [39.5609888, -6.43746542],
                            [39.56101855, -6.4372777],
                            [39.56112156, -6.43710829],
                            [39.56130011, -6.43697093],
                            [39.56136449, -6.43694746],
                            [39.56154762, -6.43688336],
                            [39.56160942, -6.43683528],
                            [39.56169412, -6.43681239],
                            [39.56179484, -6.43680552],
                            [39.56179713, -6.43675516],
                            [39.56178568, -6.43666816],
                            [39.5618864, -6.4365308],
                            [39.56193447, -6.43644152],
                            [39.56195279, -6.43636139],
                            [39.56204893, -6.43630645],
                            [39.56208784, -6.4362515],
                            [39.56209929, -6.43609812],
                            [39.5622435, -6.4360157],
                            [39.56236253, -6.43593214],
                            [39.56248156, -6.43584858],
                            [39.56257542, -6.43578906],
                            [39.56276083, -6.43566543],
                            [39.56287757, -6.43548],
                            [39.56298058, -6.43525336],
                            [39.56317744, -6.43501069],
                            [39.56321407, -6.43497177],
                            [39.56325985, -6.43486646],
                            [39.56325756, -6.43475657],
                            [39.56326328, -6.43470048],
                            [39.56326901, -6.43464439],
                            [39.56331708, -6.43447956],
                            [39.56348418, -6.43432847],
                            [39.56360092, -6.43409495],
                            [39.56375658, -6.43399193],
                            [39.5639214, -6.43370119],
                            [39.56388019, -6.43361877],
                            [39.56391682, -6.43356612],
                            [39.56416404, -6.43358672],
                            [39.56431283, -6.43344478],
                            [39.56427391, -6.43333718],
                            [39.56430138, -6.43322272],
                            [39.56442042, -6.43283353],
                            [39.56467221, -6.43266183],
                            [39.56471113, -6.43243748],
                            [39.56481414, -6.43230241],
                            [39.56484847, -6.43213757],
                            [39.56491715, -6.43205516],
                            [39.56497208, -6.43197503],
                            [39.56500642, -6.43190406],
                            [39.56501787, -6.43177586],
                            [39.5650316, -6.43171863],
                            [39.56512774, -6.43167055],
                            [39.56515292, -6.43157669],
                            [39.56519184, -6.43142788],
                            [39.56525822, -6.43131341],
                            [39.56537725, -6.431144],
                            [39.56554287, -6.43084442],
                            [39.5656405, -6.43066782],
                            [39.56578471, -6.4304366],
                            [39.56580302, -6.43033129],
                            [39.56587856, -6.43020996],
                            [39.56593579, -6.43001079],
                            [39.56599759, -6.42993524],
                            [39.56607084, -6.42977956],
                            [39.56610747, -6.42975438],
                            [39.56623108, -6.42974064],
                            [39.5663318, -6.42966052],
                            [39.56643023, -6.42950255],
                            [39.56650119, -6.42936519],
                            [39.56663854, -6.4292576],
                            [39.56677359, -6.42906071],
                            [39.56684913, -6.42885467],
                            [39.56688805, -6.42865779],
                            [39.56694527, -6.42858911],
                            [39.56694756, -6.42853188],
                            [39.56694985, -6.42847464],
                            [39.56699106, -6.4283121],
                            [39.56702081, -6.42828463],
                            [39.56716274, -6.42821824],
                            [39.56721081, -6.42814727],
                            [39.56724743, -6.42809919],
                            [39.5672955, -6.42801678],
                            [39.56732068, -6.42796412],
                            [39.56733213, -6.4279252],
                            [39.56730237, -6.42787026],
                            [39.56726117, -6.42776037],
                            [39.56725659, -6.42762301],
                            [39.56728635, -6.4276047],
                            [39.56730695, -6.42746963],
                            [39.56732984, -6.42733456],
                            [39.56730924, -6.42724527],
                            [39.56732297, -6.42713309],
                            [39.56728177, -6.42707128],
                            [39.56727262, -6.4269385],
                            [39.5671925, -6.42681259],
                            [39.5672131, -6.42668667],
                            [39.56710551, -6.42654245],
                            [39.56711009, -6.42647377],
                            [39.56716045, -6.4263799],
                            [39.5673802, -6.42611892],
                            [39.56727033, -6.42569997],
                            [39.56716045, -6.42553743],
                            [39.56719708, -6.42541151],
                            [39.56720051, -6.42534283],
                            [39.56720395, -6.42527415],
                            [39.56720166, -6.42514824],
                            [39.56723828, -6.42508185],
                            [39.5672213, -6.42499935],
                            [39.56721539, -6.42467893],
                            [39.56719136, -6.42455416],
                            [39.56716732, -6.42442939],
                            [39.56723599, -6.42416383],
                            [39.56733443, -6.42382043],
                            [39.56739623, -6.42361553],
                            [39.56745804, -6.42341064],
                            [39.56746719, -6.42323894],
                            [39.56749123, -6.4231611],
                            [39.56751526, -6.42308326],
                            [39.56756219, -6.42298139],
                            [39.56760912, -6.42287951],
                            [39.56760683, -6.42276733],
                            [39.5676755, -6.42249033],
                            [39.56771441, -6.42222476],
                            [39.56773044, -6.42206909],
                            [39.56776249, -6.42181268],
                            [39.56783345, -6.42168448],
                            [39.56781971, -6.42142578],
                            [39.56783803, -6.42121974],
                            [39.56793417, -6.42099081],
                            [39.56797766, -6.42085574],
                            [39.5679914, -6.42073441],
                            [39.56800742, -6.42063596],
                            [39.5681173, -6.42049174],
                            [39.56816994, -6.42023762],
                            [39.5682638, -6.41985988],
                            [39.56827295, -6.41975915],
                            [39.56828211, -6.41965842],
                            [39.56831187, -6.41950732],
                            [39.56837367, -6.41928068],
                            [39.56841248, -6.4191688],
                            [39.56852933, -6.41883197],
                            [39.56865294, -6.41848399],
                            [39.56869186, -6.41829169],
                            [39.56880173, -6.41798492],
                            [39.56888185, -6.41768044],
                            [39.56897113, -6.41747211],
                            [39.56898028, -6.41725004],
                            [39.56910847, -6.41708063],
                            [39.56910847, -6.41690206],
                            [39.56917714, -6.41672578],
                            [39.56926642, -6.41653577],
                            [39.56928244, -6.41636178],
                            [39.56940147, -6.41607561],
                            [39.56944268, -6.41592681],
                            [39.56959147, -6.41543231],
                            [39.56960978, -6.41531556],
                            [39.56962809, -6.4151988],
                            [39.56973568, -6.41487371],
                            [39.56981351, -6.41463791],
                            [39.56988905, -6.41451429],
                            [39.57005844, -6.41420981],
                            [39.57055059, -6.41357337],
                            [39.57065, -6.4134237],
                            [39.57096263, -6.41295296],
                            [39.5712855, -6.41250887],
                            [39.57158158, -6.41202705],
                            [39.57214364, -6.41102328],
                            [39.5724899, -6.41036079],
                            [39.57255514, -6.41016505],
                            [39.57271071, -6.40988399],
                            [39.57302184, -6.40920644],
                            [39.57313727, -6.40887018],
                            [39.57341829, -6.4081575],
                            [39.57364511, -6.40771581],
                            [39.57383328, -6.40718336],
                            [39.57407653, -6.40633878],
                            [39.57425094, -6.40576043],
                            [39.57439321, -6.40539781],
                            [39.57586525, -6.39937023],
                            [39.57587148, -6.3993393],
                            [39.57587336, -6.39932982],
                            [39.57604933, -6.39844983],
                            [39.57617398, -6.39782647],
                            [39.57626118, -6.39687173],
                            [39.57618775, -6.39655501],
                            [39.57620725, -6.39646988],
                            [39.5763438, -6.39641731],
                            [39.57643559, -6.39624748],
                            [39.57644936, -6.39592158],
                            [39.57654115, -6.39573339],
                            [39.57646772, -6.39560946],
                            [39.57654574, -6.39547175],
                            [39.57665589, -6.3951734],
                            [39.57655033, -6.39497143],
                            [39.57660999, -6.39476488],
                            [39.57697716, -6.3944344],
                            [39.57710337, -6.39409703],
                            [39.57714468, -6.39391801],
                            [39.57719746, -6.39370457],
                            [39.57725024, -6.39353474],
                            [39.57742923, -6.39297016],
                            [39.57751185, -6.39278656],
                            [39.57767937, -6.39242853],
                            [39.57774592, -6.39222657],
                            [39.5777528, -6.39212558],
                            [39.57780558, -6.39190067],
                            [39.57777804, -6.39178362],
                            [39.57781247, -6.39169871],
                            [39.57784689, -6.39161379],
                            [39.57791344, -6.39138199],
                            [39.57805571, -6.39106527],
                            [39.57810849, -6.39087938],
                            [39.57815669, -6.39069577],
                            [39.57821406, -6.39034922],
                            [39.57827143, -6.39015414],
                            [39.57825766, -6.38993841],
                            [39.5782393, -6.38959874],
                            [39.57832191, -6.38932334],
                            [39.57831843, -6.38921699],
                            [39.57831732, -6.38918334],
                            [39.57836092, -6.38908006],
                            [39.5785191, -6.38893355],
                            [39.57860647, -6.3888781],
                            [39.57856975, -6.38882072],
                            [39.57850779, -6.38876335],
                            [39.57863286, -6.38854532],
                            [39.57897249, -6.38773746],
                            [39.57929376, -6.38580963],
                            [39.57944981, -6.38378999],
                            [39.57945899, -6.38229362],
                            [39.5793672, -6.38144904],
                            [39.57909631, -6.38077764],
                            [39.57894496, -6.3804025],
                            [39.5787981, -6.37979661],
                            [39.57864205, -6.37926416],
                            [39.57829325, -6.37911728],
                            [39.57810048, -6.37879597],
                            [39.57811884, -6.37855729],
                            [39.57783429, -6.37809828],
                            [39.57736616, -6.37710682],
                            [39.57702653, -6.37661109],
                            [39.57698063, -6.37619798],
                            [39.57687966, -6.37589504],
                            [39.5761178, -6.37522488],
                            [39.57554869, -6.37490357],
                            [39.57551197, -6.37471079],
                            [39.57527332, -6.37462817],
                            [39.57503466, -6.37475669],
                            [39.57452063, -6.37485767],
                            [39.57420854, -6.37466489],
                            [39.57348339, -6.37176395],
                            [39.57330137, -6.37047314],
                            [39.57328145, -6.37033184],
                            [39.57301526, -6.36751353],
                            [39.57270317, -6.36729321],
                            [39.57263578, -6.36718931],
                            [39.57248287, -6.36695354],
                            [39.57219832, -6.36665059],
                            [39.57181279, -6.36698108],
                            [39.57151906, -6.36709124],
                            [39.57136302, -6.36727484],
                            [39.57113354, -6.36743091],
                            [39.57061033, -6.36731156],
                            [39.56995861, -6.36699944],
                            [39.56928101, -6.36646178],
                            [39.5689976, -6.36607959],
                            [39.56859171, -6.36532307],
                            [39.56759236, -6.36265987],
                            [39.56750011, -6.3624323],
                            [39.5667898, -6.36054715],
                            [39.56596572, -6.35840983],
                            [39.56578997, -6.35791249],
                            [39.56544298, -6.35693061],
                            [39.56533844, -6.35629711],
                            [39.56525541, -6.35608184],
                            [39.56515394, -6.35585426],
                            [39.56495715, -6.35527611],
                            [39.5648526, -6.3548548],
                            [39.56492212, -6.35467968],
                            [39.56493562, -6.35464568],
                            [39.56477335, -6.35429709],
                            [39.56458201, -6.35388608],
                            [39.56433909, -6.35362776],
                            [39.56397317, -6.35325257],
                            [39.56380712, -6.35292967],
                            [39.56370258, -6.35262521],
                            [39.56351501, -6.35205013],
                            [39.56318291, -6.35155194],
                            [39.56287542, -6.35116753],
                            [39.56251565, -6.3506232],
                            [39.56231578, -6.35022341],
                            [39.56202982, -6.34998969],
                            [39.56188222, -6.34974367],
                            [39.56157165, -6.34908863],
                            [39.56143328, -6.34878725],
                            [39.56146095, -6.34861811],
                            [39.56139946, -6.34859044],
                            [39.56131643, -6.34850125],
                            [39.56129183, -6.34829828],
                            [39.56103354, -6.34805841],
                            [39.56087672, -6.34770783],
                            [39.56075987, -6.34729266],
                            [39.56034168, -6.34634548],
                            [39.56021868, -6.34587188],
                            [39.56017256, -6.34572734],
                            [39.55995732, -6.34539829],
                            [39.55986507, -6.34516457],
                            [39.55964367, -6.34503233],
                            [39.55942843, -6.34465407],
                            [39.55938845, -6.3444757],
                            [39.55925008, -6.34425736],
                            [39.55910249, -6.34379606],
                            [39.55904099, -6.34364845],
                            [39.55843522, -6.34346701],
                            [39.5582538, -6.34345778],
                            [39.55815541, -6.34331632],
                            [39.55808468, -6.34279352],
                            [39.55791556, -6.34252904],
                            [39.55742665, -6.34234453],
                            [39.55732825, -6.34225534],
                            [39.55713299, -6.34196473],
                            [39.55693773, -6.34167411],
                            [39.55682704, -6.34146499],
                            [39.55686086, -6.34110826],
                            [39.55681474, -6.34093912],
                            [39.55665791, -6.34081611],
                            [39.55633812, -6.34078535],
                            [39.55615877, -6.3400739],
                            [39.55606445, -6.33969978],
                            [39.55604613, -6.33964797],
                            [39.55597528, -6.33944761],
                            [39.55604293, -6.33940763],
                            [39.55603371, -6.33929692],
                            [39.55562474, -6.33896171],
                            [39.55538797, -6.3385527],
                            [39.55516043, -6.33800837],
                            [39.55498208, -6.33743329],
                            [39.55497234, -6.33739092],
                            [39.55495869, -6.33733156],
                            [39.55493858, -6.33724411],
                            [39.55484986, -6.33685821],
                            [39.55457926, -6.33642152],
                            [39.55447164, -6.33612322],
                            [39.5542687, -6.33568653],
                            [39.55411188, -6.33552661],
                            [39.55384436, -6.3351053],
                            [39.5537472, -6.33487905],
                            [39.55341387, -6.33410276],
                            [39.55318017, -6.3335],
                            [39.55299968, -6.33287642],
                            [39.55289897, -6.33252844],
                            [39.55287744, -6.33245407],
                            [39.55277428, -6.33209767],
                            [39.55250212, -6.33123295],
                            [39.55239391, -6.33088915],
                            [39.55227, -6.33049545],
                            [39.5520978, -6.32995727],
                            [39.55191958, -6.32940009],
                            [39.55168269, -6.3286595],
                            [39.5515465, -6.32806129],
                            [39.55152587, -6.32797064],
                            [39.55108615, -6.32606396],
                            [39.55088643, -6.32545726],
                            [39.55067411, -6.32481232],
                            [39.55031435, -6.32391433],
                            [39.54974548, -6.32291179],
                            [39.54903452, -6.32159914],
                            [39.54875945, -6.32100035],
                            [39.5485427, -6.32052851],
                            [39.5479297, -6.31919411],
                            [39.54775551, -6.31862942],
                            [39.5474478, -6.31763192],
                            [39.54716521, -6.31671582],
                            [39.5470288, -6.3162736],
                            [39.54699867, -6.31617594],
                            [39.54696224, -6.31605783],
                            [39.54690478, -6.31584565],
                            [39.54678289, -6.31539554],
                            [39.54665483, -6.31492264],
                            [39.54632788, -6.3137153],
                            [39.54631171, -6.31365558],
                            [39.5462438, -6.3135058],
                            [39.54504076, -6.31085221],
                            [39.54446917, -6.30959142],
                            [39.54412454, -6.30883127],
                            [39.54387637, -6.30828388],
                            [39.5429256, -6.3057148],
                            [39.54287908, -6.30542563],
                            [39.5428287, -6.30511247],
                            [39.5427434, -6.3047601],
                            [39.54238347, -6.30327327],
                            [39.54218812, -6.30150513],
                            [39.54214822, -6.30115041],
                            [39.5419244, -6.29916064],
                            [39.54146779, -6.29790266],
                            [39.54131846, -6.29749126],
                            [39.54088904, -6.29630818],
                            [39.54037136, -6.29507732],
                            [39.53960949, -6.29319196],
                            [39.53921879, -6.29179504],
                            [39.53901367, -6.29093539],
                            [39.53892099, -6.29060895],
                            [39.53878375, -6.29012557],
                            [39.53871444, -6.28988993],
                            [39.53840852, -6.28884977],
                            [39.53838517, -6.28877213],
                            [39.53828953, -6.28845405],
                            [39.53826101, -6.28835431],
                            [39.53821853, -6.28817888],
                            [39.53796869, -6.28714694],
                            [39.53786703, -6.28672707],
                            [39.53730932, -6.28396533],
                            [39.53643614, -6.27964142],
                            [39.53605074, -6.27736155],
                            [39.53587854, -6.27534411],
                            [39.53572275, -6.27151425],
                            [39.53569259, -6.27016217],
                            [39.53567355, -6.26930819],
                            [39.53562742, -6.26693804],
                            [39.53560796, -6.26593758],
                            [39.53559795, -6.2646226],
                            [39.53559179, -6.26381364],
                            [39.53558336, -6.2627064],
                            [39.53559278, -6.26244086],
                            [39.53562436, -6.26155006],
                            [39.53561529, -6.25991911],
                            [39.53561476, -6.25982478],
                            [39.53559977, -6.25712973],
                            [39.53556252, -6.25619296],
                            [39.53554237, -6.25568636],
                            [39.53540322, -6.25460234],
                            [39.53538657, -6.25447262],
                            [39.53530867, -6.25359921],
                            [39.5352851, -6.25334292],
                            [39.53515425, -6.25192009],
                            [39.53498785, -6.25011072],
                            [39.53496706, -6.24980828],
                            [39.53494085, -6.24942713],
                            [39.53484597, -6.24804724],
                            [39.53482151, -6.24769147],
                            [39.53479106, -6.24724858],
                            [39.53467626, -6.24563298],
                            [39.53469266, -6.24500971],
                            [39.53456146, -6.24342692],
                            [39.53449373, -6.24265491],
                            [39.53438751, -6.24144426],
                            [39.53423482, -6.23970392],
                            [39.53412687, -6.23847353],
                            [39.53407767, -6.23780105],
                            [39.53391777, -6.23682103],
                            [39.53386215, -6.23638764],
                            [39.53375888, -6.23558303],
                            [39.53375126, -6.23552363],
                            [39.53356408, -6.2340652],
                            [39.53347626, -6.23338093],
                            [39.53345148, -6.23318788],
                            [39.53332327, -6.23218883],
                            [39.53319504, -6.23118978],
                            [39.53318843, -6.23113428],
                            [39.53314487, -6.23076901],
                            [39.5329916, -6.22948378],
                            [39.53255113, -6.22579014],
                            [39.53249149, -6.22528999],
                            [39.53243944, -6.22433803],
                            [39.53239678, -6.22355794],
                            [39.53230208, -6.2218259],
                            [39.53257269, -6.21510519],
                            [39.53377237, -6.20690504],
                            [39.53434966, -6.20112253],
                            [39.53456164, -6.1979426],
                            [39.53486382, -6.19450557],
                            [39.53486045, -6.19369733],
                            [39.53485302, -6.19191653],
                            [39.53485029, -6.19126249],
                            [39.53481421, -6.19006269],
                            [39.53459774, -6.18766759],
                            [39.53426851, -6.18625579],
                            [39.53387614, -6.18507403],
                            [39.53329762, -6.1839793],
                            [39.53324925, -6.18388776],
                            [39.53277118, -6.18325628],
                            [39.53138661, -6.18142951],
                            [39.53061539, -6.18006282],
                            [39.53047107, -6.17955312],
                            [39.52989379, -6.17756849],
                            [39.52976977, -6.17728658],
                            [39.52930298, -6.17605294],
                            [39.52920602, -6.17557032],
                            [39.52909552, -6.17532449],
                            [39.52894444, -6.17503582],
                            [39.52873021, -6.17475616],
                            [39.52856559, -6.17442689],
                            [39.52844833, -6.1740232],
                            [39.52841902, -6.17385406],
                            [39.52834686, -6.17368491],
                            [39.5281766, -6.17252851],
                            [39.52815735, -6.17239775],
                            [39.52810614, -6.17161918],
                            [39.5277886, -6.17043084],
                            [39.52732766, -6.16805416],
                            [39.52705101, -6.167817],
                            [39.52696915, -6.16774683],
                            [39.52686672, -6.1674395],
                            [39.52650821, -6.16669166],
                            [39.5260063, -6.16553405],
                            [39.52582192, -6.16541112],
                            [39.52559657, -6.1653599],
                            [39.52509466, -6.16435595],
                            [39.52486931, -6.16371056],
                            [39.52467469, -6.16356714],
                            [39.52450056, -6.16327005],
                            [39.52438788, -6.16274759],
                            [39.52436428, -6.16203364],
                            [39.52434691, -6.16150803],
                            [39.52420351, -6.16077044],
                            [39.52417337, -6.16068377],
                            [39.52403962, -6.1602992],
                            [39.52380403, -6.15984845],
                            [39.52371184, -6.15928501],
                            [39.52364889, -6.15916855],
                            [39.52350698, -6.15890597],
                            [39.52342503, -6.15869084],
                            [39.52326114, -6.15827082],
                            [39.52278996, -6.1578713],
                            [39.52248266, -6.15730786],
                            [39.52239048, -6.15680589],
                            [39.5222061, -6.15661124],
                            [39.5221344, -6.15625269],
                            [39.52210367, -6.15589414],
                            [39.52151981, -6.15558681],
                            [39.521233, -6.15496191],
                            [39.52064914, -6.15439847],
                            [39.52052622, -6.15401943],
                            [39.5200661, -6.15279],
                            [39.51983994, -6.15218569],
                            [39.51985018, -6.15218569],
                            [39.51953264, -6.15160177],
                            [39.51930729, -6.15160177],
                            [39.51912292, -6.15160177],
                            [39.51873367, -6.15142761],
                            [39.51841614, -6.15142761],
                            [39.51828298, -6.15132517],
                            [39.51759659, -6.14923146],
                            [39.51709816, -6.14771105],
                            [39.51685318, -6.14696376],
                            [39.51649483, -6.14587067],
                            [39.51624095, -6.14509627],
                            [39.51623435, -6.14507613],
                            [39.51619792, -6.14491629],
                            [39.51597261, -6.14392772],
                            [39.51559928, -6.14228968],
                            [39.51555097, -6.14214562],
                            [39.51520389, -6.14111083],
                            [39.51513716, -6.14091188],
                            [39.51493949, -6.14032253],
                            [39.51486671, -6.14010552],
                            [39.51451351, -6.13905247],
                            [39.51451351, -6.13843049],
                            [39.51451351, -6.13804853],
                            [39.51435475, -6.13791681],
                            [39.51429558, -6.13786772],
                            [39.51366205, -6.13734212],
                            [39.51297704, -6.13528256],
                            [39.5128923, -6.13486757],
                            [39.51238294, -6.13237317],
                            [39.51166593, -6.1305087],
                            [39.51150246, -6.13019949],
                            [39.51072356, -6.12872619],
                            [39.51090866, -6.12827882],
                            [39.5109694, -6.12813202],
                            [39.51082093, -6.12797572],
                            [39.51019092, -6.12731247],
                            [39.50986314, -6.12675928],
                            [39.51010147, -6.12646133],
                            [39.51010897, -6.12645195],
                            [39.51008206, -6.12641695],
                            [39.50969925, -6.12591925],
                            [39.50978339, -6.12573412],
                            [39.50990411, -6.1254685],
                            [39.50955357, -6.12519018],
                            [39.50944338, -6.1251027],
                            [39.50931001, -6.12471042],
                            [39.50865445, -6.12456699],
                            [39.50806034, -6.12538654],
                            [39.50760964, -6.12516116],
                            [39.50672873, -6.12528409],
                            [39.50576587, -6.12481285],
                            [39.50508943, -6.12399351],
                            [39.50472107, -6.12329669],
                            [39.50414746, -6.12272301],
                            [39.50289779, -6.12251812],
                            [39.50224223, -6.12233372],
                            [39.50150472, -6.12260007],
                            [39.50054186, -6.12251812],
                            [39.50013214, -6.1221903],
                            [39.49912831, -6.12206736],
                            [39.49773524, -6.12147319],
                            [39.49669044, -6.12071511],
                            [39.49548174, -6.12046924],
                            [39.49363797, -6.1208995],
                            [39.4908928, -6.12255908],
                            [39.49068794, -6.12339911],
                            [39.49013481, -6.12436208],
                            [39.49018418, -6.12475715],
                            [39.49025772, -6.12534553],
                            [39.49001189, -6.12614459],
                            [39.49042161, -6.12790661],
                            [39.49113863, -6.12934082],
                            [39.49153285, -6.13017416],
                            [39.49204002, -6.13124626],
                            [39.49212197, -6.13235265],
                            [39.49097473, -6.13505715],
                            [39.49011465, -6.13649348],
                            [39.48945891, -6.13758855],
                            [39.48880317, -6.13868363],
                            [39.48860577, -6.13989141],
                            [39.48810663, -6.14294527],
                            [39.48800419, -6.1446868],
                            [39.488291, -6.14546537],
                            [39.48800419, -6.14636687],
                            [39.48825003, -6.14677664],
                            [39.48863927, -6.14681762],
                            [39.4888851, -6.14698153],
                            [39.4903806, -6.14771912],
                            [39.49103617, -6.1477601],
                            [39.49253167, -6.14737082],
                            [39.49333064, -6.14677665],
                            [39.49341258, -6.14622346],
                            [39.49347404, -6.14583417],
                            [39.49369939, -6.14583417],
                            [39.49378134, -6.14524],
                            [39.49349453, -6.1448917],
                            [39.49271605, -6.14442046],
                            [39.49224487, -6.14390824],
                            [39.49244973, -6.14372384],
                            [39.49281848, -6.14388775],
                            [39.49347404, -6.14419508],
                            [39.49464177, -6.14460486],
                            [39.49558413, -6.14524001],
                            [39.49564559, -6.14550636],
                            [39.4959324, -6.14671519],
                            [39.49623969, -6.14708398],
                            [39.49613726, -6.1475962],
                            [39.49699769, -6.14839526],
                            [39.49722411, -6.14865307],
                            [39.4983293, -6.14991142],
                            [39.49953804, -6.15332928],
                            [39.50058278, -6.15628339],
                            [39.50138175, -6.15736929],
                            [39.50195536, -6.15919278],
                            [39.50256995, -6.15982793],
                            [39.50240606, -6.16111871],
                            [39.50243412, -6.16156383],
                            [39.50254946, -6.16339295],
                            [39.50235456, -6.16498155],
                            [39.50213972, -6.1667326],
                            [39.5024675, -6.16796192],
                            [39.50244702, -6.16882244],
                            [39.50326647, -6.17074837],
                            [39.50418835, -6.17193671],
                            [39.50482342, -6.17208013],
                            [39.50492586, -6.17222356],
                            [39.50433175, -6.17257186],
                            [39.50492585, -6.17365776],
                            [39.50609357, -6.17468219],
                            [39.50791686, -6.1746822],
                            [39.50806026, -6.17451829],
                            [39.50861339, -6.1737807],
                            [39.50933042, -6.17279725],
                            [39.50969917, -6.17230552],
                            [39.50990403, -6.17242845],
                            [39.50963771, -6.17298164],
                            [39.50922798, -6.17357581],
                            [39.50861339, -6.17435438],
                            [39.50806332, -6.17504923],
                            [39.5075029, -6.17514081],
                            [39.5069197, -6.1752771],
                            [39.50633335, -6.17540318],
                            [39.50575611, -6.1755387],
                            [39.50900333, -6.17849762],
                            [39.51001358, -6.17893064],
                            [39.51095166, -6.17871413],
                            [39.51073518, -6.17929148],
                            [39.51004622, -6.17949465],
                            [39.51097968, -6.18177954],
                            [39.51201602, -6.18475374],
                            [39.51212426, -6.18558368],
                            [39.51023005, -6.18174521],
                            [39.50897578, -6.18117132],
                            [39.50914765, -6.18253907],
                            [39.50792092, -6.18225039],
                            [39.50683851, -6.1813122],
                            [39.50502092, -6.17986],
                            [39.5043129, -6.18095135],
                            [39.50481801, -6.18477629],
                            [39.50553961, -6.18614749],
                            [39.50813739, -6.18614749],
                            [39.51023005, -6.18672485],
                            [39.51133718, -6.18800053],
                            [39.51167325, -6.18939509],
                            [39.51087949, -6.18903424],
                            [39.50979708, -6.18852906],
                            [39.50907548, -6.18795171],
                            [39.50727146, -6.18780737],
                            [39.50555252, -6.18756328],
                            [39.50539529, -6.18831255],
                            [39.50619058, -6.18885962],
                            [39.50610022, -6.18948568],
                            [39.5057984, -6.19157666],
                            [39.50546744, -6.19386953],
                            [39.50583638, -6.195411],
                            [39.50575608, -6.19610676],
                            [39.50647768, -6.19668411],
                            [39.50757246, -6.19718154],
                            [39.50756009, -6.1976223],
                            [39.50618904, -6.19704495],
                            [39.50539528, -6.19661194],
                            [39.50510664, -6.19567375],
                            [39.50467368, -6.19372519],
                            [39.50344695, -6.19105495],
                            [39.50250887, -6.18953941],
                            [39.50149862, -6.18860122],
                            [39.50129839, -6.18858549],
                            [39.50114474, -6.18846256],
                            [39.50105255, -6.18816547],
                            [39.50078623, -6.18817571],
                            [39.5006121, -6.18800156],
                            [39.50031505, -6.18782741],
                            [39.50008969, -6.1881962],
                            [39.49986434, -6.18844206],
                            [39.49976191, -6.18865719],
                            [39.49970045, -6.18852402],
                            [39.49969021, -6.18851377],
                            [39.49927024, -6.18837035],
                            [39.49859419, -6.18820644],
                            [39.49847128, -6.18779667],
                            [39.49886052, -6.18694639],
                            [39.4985942, -6.18680297],
                            [39.49908587, -6.18603465],
                            [39.49960827, -6.18528681],
                            [39.49835861, -6.18437507],
                            [39.49593098, -6.18299208],
                            [39.49398478, -6.18171154],
                            [39.49280682, -6.18190618],
                            [39.49284779, -6.18231595],
                            [39.49277608, -6.183576],
                            [39.49217173, -6.18498972],
                            [39.49217173, -6.18705907],
                            [39.49080939, -6.1875303],
                            [39.49044064, -6.18772495],
                            [39.49045088, -6.18832936],
                            [39.49028699, -6.18863669],
                            [39.48996945, -6.18811423],
                            [39.48947778, -6.18819618],
                            [39.48895538, -6.18785812],
                            [39.48859687, -6.18802203],
                            [39.48866857, -6.18885182],
                            [39.48931388, -6.18948697],
                            [39.4896724, -6.18984552],
                            [39.4912396, -6.19059336],
                            [39.49188492, -6.19098264],
                            [39.49194637, -6.19116704],
                            [39.49166981, -6.19107484],
                            [39.49096303, -6.19083922],
                            [39.49127032, -6.19195585],
                            [39.49112692, -6.19267295],
                            [39.49132154, -6.19308273],
                            [39.49117813, -6.19309297],
                            [39.49084011, -6.1927754],
                            [39.49060452, -6.19161779],
                            [39.49036893, -6.19094166],
                            [39.48960069, -6.19094166],
                            [39.48937534, -6.19127972],
                            [39.48977829, -6.19290782],
                            [39.4898875, -6.19334908],
                            [39.49018454, -6.19442473],
                            [39.4901026, -6.1944862],
                            [39.48977482, -6.1942096],
                            [39.48960069, -6.19426082],
                            [39.48954947, -6.19460913],
                            [39.48908853, -6.1947423],
                            [39.48909877, -6.19459888],
                            [39.48906804, -6.19416862],
                            [39.48895537, -6.19329785],
                            [39.48876075, -6.19314419],
                            [39.48838175, -6.19324663],
                            [39.48806421, -6.1941174],
                            [39.48787983, -6.19450668],
                            [39.48757254, -6.19427106],
                            [39.48783886, -6.19323639],
                            [39.4877774, -6.19301101],
                            [39.48692722, -6.19300076],
                            [39.48584145, -6.19273441],
                            [39.48596437, -6.19144363],
                            [39.48533953, -6.19080848],
                            [39.48427425, -6.19068554],
                            [39.48288117, -6.19123873],
                            [39.48299385, -6.19218121],
                            [39.48292214, -6.19299051],
                            [39.48249193, -6.19305198],
                            [39.48214366, -6.19349248],
                            [39.48139295, -6.19388054],
                            [39.48123202, -6.19396372],
                            [39.48062767, -6.19383054],
                            [39.48058454, -6.19372142],
                            [39.48036016, -6.19315381],
                            [39.48010528, -6.19250903],
                            [39.48020771, -6.19227341],
                            [39.48036136, -6.19146411],
                            [39.48052525, -6.18753029],
                            [39.48025893, -6.18701807],
                            [39.48023845, -6.18602437],
                            [39.48013602, -6.18569655],
                            [39.47988057, -6.18545407],
                            [39.47953167, -6.18512287],
                            [39.47924486, -6.18513311],
                            [39.47908097, -6.185338],
                            [39.47889659, -6.18523556],
                            [39.47854833, -6.1849692],
                            [39.47824103, -6.18512287],
                            [39.47810787, -6.1857068],
                            [39.47796447, -6.18571704],
                            [39.47769814, -6.18530727],
                            [39.47744488, -6.18521959],
                            [39.47689918, -6.18503067],
                            [39.47665334, -6.18508189],
                            [39.47655091, -6.18549166],
                            [39.47625386, -6.18557362],
                            [39.47589535, -6.18600388],
                            [39.47590559, -6.18651609],
                            [39.47601826, -6.18656731],
                            [39.47618215, -6.18676196],
                            [39.47650993, -6.18727417],
                            [39.47658925, -6.18737653],
                            [39.47675783, -6.18759407],
                            [39.47682747, -6.18768395],
                            [39.47697087, -6.18787859],
                            [39.47689917, -6.1879503],
                            [39.47651956, -6.1877556],
                            [39.47649969, -6.18774541],
                            [39.47544465, -6.18684391],
                            [39.47494572, -6.186182],
                            [39.47494273, -6.18617803],
                            [39.47411304, -6.18494871],
                            [39.47389793, -6.18446722],
                            [39.47430766, -6.18349402],
                            [39.4750042, -6.18277691],
                            [39.47564952, -6.18234665],
                            [39.47641775, -6.18240812],
                            [39.47692991, -6.18233641],
                            [39.4774011, -6.18197786],
                            [39.47730614, -6.1817504],
                            [39.47784336, -6.18103948],
                            [39.47871735, -6.1798829],
                            [39.47876344, -6.17959606],
                            [39.47847664, -6.17859212],
                            [39.47801214, -6.17790446],
                            [39.47769816, -6.17743963],
                            [39.47767767, -6.17700425],
                            [39.47668409, -6.1757698],
                            [39.47645362, -6.17545735],
                            [39.47567002, -6.17385924],
                            [39.47578781, -6.17374655],
                            [39.47587801, -6.17344469],
                            [39.47592098, -6.17330092],
                            [39.47528078, -6.17206648],
                            [39.47499397, -6.17144158],
                            [39.47496836, -6.17096522],
                            [39.47459961, -6.17048373],
                            [39.47442548, -6.16975126],
                            [39.47411819, -6.1691366],
                            [39.47420355, -6.16906495],
                            [39.47427695, -6.16894196],
                            [39.47433841, -6.16872171],
                            [39.47426159, -6.16853731],
                            [39.474026, -6.16854243],
                            [39.4738365, -6.16865],
                            [39.47378528, -6.16886001],
                            [39.47384162, -6.16908026],
                            [39.47373407, -6.1691827],
                            [39.47346262, -6.16942344],
                            [39.47339454, -6.16944495],
                            [39.47317069, -6.16951564],
                            [39.4729351, -6.16984346],
                            [39.47299144, -6.1702686],
                            [39.47292485, -6.17046324],
                            [39.47288388, -6.1708013],
                            [39.47268414, -6.17087813],
                            [39.47253049, -6.171124],
                            [39.47242342, -6.17125872],
                            [39.47237172, -6.17132376],
                            [39.47198248, -6.17167207],
                            [39.47183395, -6.17309091],
                            [39.47194151, -6.17334701],
                            [39.47215661, -6.17458658],
                            [39.47209515, -6.17511416],
                            [39.47190565, -6.1756315],
                            [39.4715625, -6.17582102],
                            [39.47217709, -6.17670715],
                            [39.47221807, -6.17686082],
                            [39.47235123, -6.17695814],
                            [39.47259194, -6.17702985],
                            [39.47299655, -6.17694789],
                            [39.47303752, -6.17700424],
                            [39.47303752, -6.17717327],
                            [39.47316044, -6.17732181],
                            [39.47332945, -6.17719888],
                            [39.47340115, -6.1774806],
                            [39.47349334, -6.17785964],
                            [39.47318092, -6.17796208],
                            [39.47274559, -6.17781354],
                            [39.47239732, -6.17784427],
                            [39.47185955, -6.17779817],
                            [39.47169566, -6.17756767],
                            [39.4713013, -6.1770657],
                            [39.47094791, -6.17704521],
                            [39.47110668, -6.17756255],
                            [39.47158299, -6.1782899],
                            [39.47203881, -6.17866894],
                            [39.47198247, -6.17894041],
                            [39.47155226, -6.17891992],
                            [39.47146519, -6.17936555],
                            [39.47097352, -6.1797241],
                            [39.47057403, -6.17980093],
                            [39.4700926, -6.17980605],
                            [39.46986726, -6.17946287],
                            [39.46931925, -6.17968312],
                            [39.46858686, -6.18019021],
                            [39.46825395, -6.18084073],
                            [39.46839224, -6.18138368],
                            [39.46749596, -6.18157319],
                            [39.46759327, -6.18121977],
                            [39.4674345, -6.18086634],
                            [39.46731671, -6.18050779],
                            [39.46696844, -6.18063072],
                            [39.46673797, -6.1809739],
                            [39.46643579, -6.18119928],
                            [39.46623605, -6.18101488],
                            [39.46644091, -6.1807434],
                            [39.46688137, -6.18036436],
                            [39.46719891, -6.17991874],
                            [39.46725525, -6.17960116],
                            [39.46675333, -6.17945774],
                            [39.46616947, -6.17932969],
                            [39.46612338, -6.17961653],
                            [39.46589741, -6.180306],
                            [39.4658212, -6.18053852],
                            [39.46552415, -6.1806051],
                            [39.46476104, -6.1803746],
                            [39.46427449, -6.17996995],
                            [39.46394671, -6.18014411],
                            [39.46347552, -6.18083047],
                            [39.46329626, -6.18167051],
                            [39.46305555, -6.18278714],
                            [39.46308115, -6.18307398],
                            [39.46300433, -6.18373986],
                            [39.46295311, -6.18415988],
                            [39.4627124, -6.18442623],
                            [39.46246656, -6.18467721],
                            [39.46241534, -6.18491283],
                            [39.46231803, -6.18490259],
                            [39.46222585, -6.18438525],
                            [39.46208244, -6.18402158],
                            [39.46168296, -6.1836118],
                            [39.46148322, -6.1838423],
                            [39.46119129, -6.18418036],
                            [39.46104276, -6.18461062],
                            [39.46077644, -6.18522016],
                            [39.46063303, -6.18565554],
                            [39.46068425, -6.18599873],
                            [39.46056645, -6.18621386],
                            [39.46051523, -6.18656729],
                            [39.46047938, -6.18687974],
                            [39.46058181, -6.18719219],
                            [39.46098642, -6.18745342],
                            [39.46149346, -6.18769417],
                            [39.46203122, -6.18787856],
                            [39.46227194, -6.18779149],
                            [39.46258435, -6.18777612],
                            [39.46279946, -6.18771466],
                            [39.46303505, -6.18757636],
                            [39.4633321, -6.18757124],
                            [39.46326552, -6.18764807],
                            [39.46307602, -6.18769417],
                            [39.46281994, -6.1879093],
                            [39.46249728, -6.1879554],
                            [39.46239997, -6.18807321],
                            [39.46215414, -6.1881654],
                            [39.46181611, -6.1881654],
                            [39.46147297, -6.18809881],
                            [39.46121177, -6.18786832],
                            [39.46094545, -6.1878171],
                            [39.46070473, -6.18785295],
                            [39.4604845, -6.18791442],
                            [39.46040768, -6.18807832],
                            [39.46038207, -6.18823199],
                            [39.46005429, -6.18828833],
                            [39.45981358, -6.18847785],
                            [39.46005429, -6.18887738],
                            [39.46059718, -6.18939472],
                            [39.46129371, -6.18985059],
                            [39.46166246, -6.19010158],
                            [39.46234363, -6.19020915],
                            [39.46260996, -6.19014768],
                            [39.46297359, -6.18984547],
                            [39.46329625, -6.18972766],
                            [39.4634499, -6.18981986],
                            [39.46380328, -6.19006061],
                            [39.46427447, -6.19005036],
                            [39.46457152, -6.19013744],
                            [39.46497101, -6.19018866],
                            [39.46544731, -6.19033721],
                            [39.46585192, -6.19036794],
                            [39.46619506, -6.19021428],
                            [39.46641017, -6.19014257],
                            [39.46678405, -6.19009647],
                            [39.4672245, -6.19008622],
                            [39.46756765, -6.19019379],
                            [39.46791591, -6.19032184],
                            [39.46796713, -6.19047551],
                            [39.46784421, -6.19053185],
                            [39.46761374, -6.19034233],
                            [39.46723987, -6.19034233],
                            [39.46686087, -6.19042941],
                            [39.46651772, -6.19046526],
                            [39.46597996, -6.19063429],
                            [39.46579558, -6.190706],
                            [39.46559584, -6.19081869],
                            [39.46533976, -6.19095699],
                            [39.46495564, -6.19098772],
                            [39.46440763, -6.19101333],
                            [39.4641157, -6.19131041],
                            [39.46342941, -6.19149993],
                            [39.46336795, -6.19198654],
                            [39.46367012, -6.19230923],
                            [39.46407985, -6.19258583],
                            [39.46431032, -6.19281121],
                            [39.4643308, -6.19301609],
                            [39.46413106, -6.19285731],
                            [39.4638084, -6.19264217],
                            [39.46355744, -6.19259607],
                            [39.46341916, -6.19270364],
                            [39.46310162, -6.19242704],
                            [39.46271238, -6.19205825],
                            [39.46239485, -6.19172531],
                            [39.46174953, -6.19158188],
                            [39.46133468, -6.19168945],
                            [39.46127322, -6.1921402],
                            [39.46130395, -6.19253973],
                            [39.46096592, -6.19282144],
                            [39.46087374, -6.19221703],
                            [39.46059717, -6.19185336],
                            [39.46009013, -6.19165359],
                            [39.45982893, -6.19182262],
                            [39.45955237, -6.19193019],
                            [39.4590863, -6.19179189],
                            [39.45862536, -6.19172018],
                            [39.45832063, -6.19170225],
                            [39.45801589, -6.19168432],
                            [39.45749349, -6.19185847],
                            [39.45691988, -6.19168432],
                            [39.45644869, -6.19146919],
                            [39.45621822, -6.19179188],
                            [39.45589556, -6.19138723],
                            [39.45562412, -6.19134113],
                            [39.45527585, -6.19163822],
                            [39.45488661, -6.19166895],
                            [39.45467662, -6.19213507],
                            [39.45437445, -6.19377416],
                            [39.45433859, -6.19448614],
                            [39.45456394, -6.19544398],
                            [39.45478929, -6.19601766],
                            [39.45519902, -6.19666305],
                            [39.45577775, -6.19697551],
                            [39.45662281, -6.19730333],
                            [39.45797491, -6.19790774],
                            [39.45816953, -6.19829191],
                            [39.45813368, -6.19853777],
                            [39.45824635, -6.19887583],
                            [39.45895825, -6.19963904],
                            [39.45933725, -6.20002832],
                            [39.45981356, -6.20040224],
                            [39.4599672, -6.20062249],
                            [39.45957796, -6.2004637],
                            [39.45870729, -6.19972611],
                            [39.45842049, -6.19961855],
                            [39.45789296, -6.19932146],
                            [39.45771883, -6.19851216],
                            [39.45734496, -6.19820483],
                            [39.45691474, -6.19826117],
                            [39.45652038, -6.19860436],
                            [39.45668427, -6.19977221],
                            [39.4573808, -6.19978245],
                            [39.45750372, -6.2002793],
                            [39.45653062, -6.2002332],
                            [39.45623869, -6.20092469],
                            [39.45642819, -6.2011808],
                            [39.45617211, -6.20118592],
                            [39.45559849, -6.20186717],
                            [39.45488659, -6.2023589],
                            [39.45436931, -6.20298892],
                            [39.45377008, -6.20379822],
                            [39.4535857, -6.20423873],
                            [39.45336548, -6.20458191],
                            [39.45305818, -6.20492509],
                            [39.45289429, -6.20550902],
                            [39.45260236, -6.20642589],
                            [39.45283283, -6.20743495],
                            [39.45327328, -6.20833133],
                            [39.45355497, -6.20857719],
                            [39.45383153, -6.20966821],
                            [39.45382108, -6.20978706],
                            [39.45360874, -6.20960291],
                            [39.45354728, -6.20896776],
                            [39.45305561, -6.2084453],
                            [39.45279954, -6.20810724],
                            [39.45228738, -6.20661156],
                            [39.45225665, -6.20575104],
                            [39.45243079, -6.20489052],
                            [39.45268687, -6.20433733],
                            [39.452861, -6.20372267],
                            [39.45216447, -6.2032924],
                            [39.45228585, -6.20261131],
                            [39.45254347, -6.20263677],
                            [39.45256395, -6.2030363],
                            [39.45305562, -6.20324118],
                            [39.45346535, -6.2025753],
                            [39.45395702, -6.20178649],
                            [39.45420286, -6.20147916],
                            [39.4555652, -6.19966592],
                            [39.45538083, -6.19902053],
                            [39.45499671, -6.19863124],
                            [39.45461259, -6.19824196],
                            [39.45292247, -6.19611114],
                            [39.45234886, -6.19534281],
                            [39.45248202, -6.19478962],
                            [39.45249226, -6.19391885],
                            [39.45217473, -6.19160364],
                            [39.45218497, -6.19097873],
                            [39.45136552, -6.19026163],
                            [39.44965492, -6.18930891],
                            [39.44917673, -6.1882254],
                            [39.44899936, -6.18782348],
                            [39.44786233, -6.18611775],
                            [39.44781115, -6.18604096],
                            [39.44754483, -6.18531362],
                            [39.44672538, -6.18402283],
                            [39.44633614, -6.18297791],
                            [39.44572155, -6.1819125],
                            [39.44489186, -6.18112369],
                            [39.44458457, -6.17986364],
                            [39.44400071, -6.17890067],
                            [39.44356545, -6.17739764],
                            [39.44345782, -6.17702596],
                            [39.4433754, -6.1770866],
                            [39.44256667, -6.17768159],
                            [39.44212339, -6.17808916],
                            [39.44197363, -6.17822686],
                            [39.44193159, -6.17826552],
                            [39.44147249, -6.17849819],
                            [39.44103024, -6.17872233],
                            [39.44054871, -6.17896638],
                            [39.4404156, -6.17903384],
                            [39.43994438, -6.17912092],
                            [39.43947323, -6.17920799],
                            [39.43880742, -6.17926946],
                            [39.43818259, -6.17944361],
                            [39.43784456, -6.17957678],
                            [39.43746557, -6.17955629],
                            [39.43719924, -6.17977142],
                            [39.43715827, -6.18014022],
                            [39.43707633, -6.18022217],
                            [39.43686122, -6.17993533],
                            [39.4365232, -6.17994558],
                            [39.43615444, -6.17980216],
                            [39.43549888, -6.17987387],
                            [39.43534523, -6.18024266],
                            [39.43514037, -6.18037584],
                            [39.43504818, -6.1810622],
                            [39.43495599, -6.18182028],
                            [39.43481975, -6.18240566],
                            [39.43467942, -6.18300863],
                            [39.43465381, -6.18332108],
                            [39.4346282, -6.18363353],
                            [39.43459654, -6.18366067],
                            [39.4344848, -6.18375646],
                            [39.43435164, -6.18366426],
                            [39.43404434, -6.18394086],
                            [39.43405459, -6.18414574],
                            [39.43427994, -6.18475016],
                            [39.43405459, -6.18475016],
                            [39.43368583, -6.18443258],
                            [39.43308148, -6.18500626],
                            [39.43287662, -6.18562092],
                            [39.43295856, -6.18615363],
                            [39.43315318, -6.18626632],
                            [39.43330683, -6.18646096],
                            [39.43343999, -6.18681951],
                            [39.43361413, -6.18717806],
                            [39.43381899, -6.18729075],
                            [39.43389069, -6.18742392],
                            [39.43378826, -6.18754686],
                            [39.43420823, -6.18797712],
                            [39.43420823, -6.18808981],
                            [39.43393166, -6.18791565],
                            [39.43350145, -6.18769028],
                            [39.43346048, -6.18779272],
                            [39.43331707, -6.18832542],
                            [39.4333478, -6.18850982],
                            [39.43310196, -6.188643],
                            [39.43253859, -6.18953425],
                            [39.43201619, -6.19057917],
                            [39.43151427, -6.19144994],
                            [39.43130941, -6.19129627],
                            [39.43157573, -6.19077381],
                            [39.43246689, -6.1893601],
                            [39.43265127, -6.18810005],
                            [39.43253859, -6.18734197],
                            [39.43207765, -6.1866556],
                            [39.43201619, -6.18629705],
                            [39.4315655, -6.18555945],
                            [39.4317089, -6.18525213],
                            [39.43169866, -6.18461698],
                            [39.43148355, -6.18296764],
                            [39.43138112, -6.18236323],
                            [39.43095091, -6.18225054],
                            [39.43040802, -6.18251689],
                            [39.42960906, -6.18250664],
                            [39.42897398, -6.18295739],
                            [39.42849255, -6.18329546],
                            [39.42792918, -6.18366425],
                            [39.42733507, -6.18384865],
                            [39.42643367, -6.18482186],
                            [39.42603419, -6.18512918],
                            [39.42597273, -6.18544676],
                            [39.42641318, -6.18574384],
                            [39.42680242, -6.18609215],
                            [39.42664878, -6.18617411],
                            [39.42631075, -6.18614337],
                            [39.42573713, -6.18575409],
                            [39.42526595, -6.18593848],
                            [39.42474355, -6.18656339],
                            [39.42462063, -6.18656339],
                            [39.4245899, -6.18627655],
                            [39.42400604, -6.18670681],
                            [39.42359631, -6.1868912],
                            [39.42299197, -6.18687071],
                            [39.42253102, -6.18713706],
                            [39.42217251, -6.18773123],
                            [39.42187546, -6.18842785],
                            [39.42154768, -6.18879664],
                            [39.42153743, -6.18912446],
                            [39.4213633, -6.18911422],
                            [39.42086138, -6.18974936],
                            [39.42066676, -6.19025133],
                            [39.42011363, -6.19091721],
                            [39.41983706, -6.19116308],
                            [39.41999071, -6.19143967],
                            [39.42050287, -6.19172652],
                            [39.42078968, -6.19201336],
                            [39.42090235, -6.19232069],
                            [39.42082041, -6.19240264],
                            [39.42027752, -6.19212604],
                            [39.41969366, -6.1921158],
                            [39.41951952, -6.19228995],
                            [39.41939661, -6.19217726],
                            [39.41919174, -6.19227971],
                            [39.4188742, -6.19270997],
                            [39.41875129, -6.19273046],
                            [39.41874104, -6.19237191],
                            [39.41836205, -6.19078404],
                            [39.41836205, -6.18939081],
                            [39.41823913, -6.18905275],
                            [39.41800354, -6.18911421],
                            [39.41724555, -6.18928836],
                            [39.41708381, -6.18900372],
                            [39.41738895, -6.18870444],
                            [39.41819816, -6.18800782],
                            [39.41812646, -6.18764927],
                            [39.41786014, -6.18715755],
                            [39.41753236, -6.18735219],
                            [39.41732854, -6.1873212],
                            [39.41772698, -6.1868707],
                            [39.41782941, -6.18644044],
                            [39.41699971, -6.18683997],
                            [39.41600613, -6.18770049],
                            [39.41588321, -6.18806929],
                            [39.4159754, -6.18847906],
                            [39.41572956, -6.18877614],
                            [39.41542226, -6.1887659],
                            [39.41508424, -6.18874541],
                            [39.41493059, -6.188141],
                            [39.4146233, -6.18771073],
                            [39.41420333, -6.18827417],
                            [39.41429552, -6.18905274],
                            [39.41464378, -6.189483],
                            [39.41487938, -6.18996448],
                            [39.41472573, -6.19009766],
                            [39.41436722, -6.18955471],
                            [39.41401895, -6.18938056],
                            [39.41390628, -6.18950349],
                            [39.41388579, -6.19023083],
                            [39.41399846, -6.19059963],
                            [39.41441843, -6.19077378],
                            [39.41470524, -6.19107087],
                            [39.41446965, -6.19116307],
                            [39.41425454, -6.1910094],
                            [39.4137936, -6.19094794],
                            [39.41362971, -6.19169577],
                            [39.41330192, -6.19238214],
                            [39.41287171, -6.19307875],
                            [39.41295365, -6.1931812],
                            [39.41286147, -6.19325291],
                            [39.41275903, -6.19333486],
                            [39.41266685, -6.19334511],
                            [39.41198055, -6.19395976],
                            [39.41187469, -6.19402924],
                            [39.41066943, -6.19482028],
                            [39.40951793, -6.19612343],
                            [39.4095017, -6.1961418],
                            [39.40895076, -6.19701135],
                            [39.40884614, -6.19717647],
                            [39.40876738, -6.197232],
                            [39.4083167, -6.19754975],
                            [39.40851778, -6.19706865],
                            [39.40883476, -6.19645649],
                            [39.40900418, -6.19601376],
                            [39.40925558, -6.19562569],
                            [39.40947965, -6.19535241],
                            [39.40985128, -6.19495887],
                            [39.40998936, -6.19484263],
                            [39.41071477, -6.19423193],
                            [39.41087294, -6.19409747],
                            [39.4115892, -6.19348859],
                            [39.41226141, -6.19292015],
                            [39.41262211, -6.19249929],
                            [39.41283525, -6.19211668],
                            [39.41287985, -6.19193829],
                            [39.41300467, -6.19143893],
                            [39.41294456, -6.19126403],
                            [39.41293909, -6.19112738],
                            [39.413032, -6.19100167],
                            [39.41300467, -6.19059174],
                            [39.41303747, -6.19032392],
                            [39.41305386, -6.1900069],
                            [39.41307572, -6.18987572],
                            [39.41309758, -6.18974455],
                            [39.41311944, -6.18939474],
                            [39.4131577, -6.18911598],
                            [39.4131577, -6.18891922],
                            [39.41314131, -6.18881537],
                            [39.41305131, -6.18865044],
                            [39.41305723, -6.18847886],
                            [39.41305723, -6.18836349],
                            [39.41305723, -6.18824812],
                            [39.4131578, -6.18787538],
                            [39.41331753, -6.18736065],
                            [39.4134181, -6.18710032],
                            [39.41348909, -6.18679858],
                            [39.41350683, -6.18652051],
                            [39.41353641, -6.18643176],
                            [39.41366065, -6.18636076],
                            [39.41383658, -6.18628021],
                            [39.41421558, -6.18587044],
                            [39.41477895, -6.18522505],
                            [39.4153116, -6.18466161],
                            [39.41565987, -6.1838728],
                            [39.41649981, -6.18277666],
                            [39.41698124, -6.18141416],
                            [39.41752413, -6.18115806],
                            [39.41783142, -6.18064584],
                            [39.41892744, -6.17925262],
                            [39.41991079, -6.17871991],
                            [39.4206483, -6.17843307],
                            [39.42080194, -6.17799257],
                            [39.42095559, -6.17786964],
                            [39.42193894, -6.17723449],
                            [39.42241012, -6.17719351],
                            [39.4224511, -6.17672227],
                            [39.42265628, -6.17645994],
                            [39.42344468, -6.17545198],
                            [39.42605669, -6.17356703],
                            [39.42601572, -6.17323921],
                            [39.42644594, -6.17292164],
                            [39.4266508, -6.17248113],
                            [39.42763414, -6.17277822],
                            [39.42947791, -6.17261431],
                            [39.43051247, -6.1724709],
                            [39.43094268, -6.17284994],
                            [39.43136265, -6.17276798],
                            [39.43190554, -6.17229675],
                            [39.432346, -6.17218406],
                            [39.43282743, -6.17189722],
                            [39.43280694, -6.17176404],
                            [39.43287908, -6.17158517],
                            [39.43315521, -6.17158989],
                            [39.43332934, -6.17132354],
                            [39.43354445, -6.1706679],
                            [39.4337698, -6.17055521],
                            [39.43406195, -6.1705789],
                            [39.4345278, -6.17061668],
                            [39.43510141, -6.17052448],
                            [39.43545992, -6.17040155],
                            [39.4354884, -6.17035379],
                            [39.43577746, -6.16986885],
                            [39.43618719, -6.16961274],
                            [39.43660716, -6.16953079],
                            [39.43696567, -6.16920297],
                            [39.43704249, -6.1690954],
                            [39.43711932, -6.16898784],
                            [39.43683251, -6.16850636],
                            [39.43677105, -6.16813756],
                            [39.43673008, -6.16774828],
                            [39.43657643, -6.16718484],
                            [39.43658914, -6.16667345],
                            [39.43659592, -6.16640055],
                            [39.43661741, -6.1655355],
                            [39.4366586, -6.16489005],
                            [39.43666216, -6.16483432],
                            [39.43675895, -6.16331776],
                            [39.43677106, -6.16312809],
                            [39.43678818, -6.16162186],
                            [39.43679155, -6.16132509],
                            [39.43718079, -6.16055677],
                            [39.43727298, -6.15845668],
                            [39.43709885, -6.15692003],
                            [39.43693017, -6.15624853],
                            [39.43605406, -6.15276084],
                            [39.43554751, -6.15148816],
                            [39.43521412, -6.15065051],
                            [39.43415873, -6.14840871],
                            [39.43411504, -6.1483159],
                            [39.43405665, -6.14819187],
                            [39.43404256, -6.14817093],
                            [39.43382461, -6.14784689],
                            [39.43317374, -6.14687923],
                            [39.43290608, -6.14655189],
                            [39.43310716, -6.14654117],
                            [39.43311228, -6.14642848],
                            [39.43274353, -6.14606993],
                            [39.43255403, -6.14615701],
                            [39.43104828, -6.14471768],
                            [39.42958863, -6.14356519],
                            [39.4288921, -6.14337567],
                            [39.42870772, -6.14303761],
                            [39.42771926, -6.1423205],
                            [39.42692542, -6.14133192],
                            [39.42680762, -6.14090679],
                            [39.42703297, -6.14079922],
                            [39.42697151, -6.1405175],
                            [39.42657203, -6.14039457],
                            [39.42570136, -6.13885792],
                            [39.42554259, -6.1383457],
                            [39.42545411, -6.13782459],
                            [39.42540431, -6.13753128],
                            [39.42567576, -6.13728542],
                            [39.42562454, -6.13641977],
                            [39.42540432, -6.13572316],
                            [39.42493313, -6.13477556],
                            [39.42480828, -6.13443688],
                            [39.42455926, -6.13376137],
                            [39.42470266, -6.13332086],
                            [39.42472006, -6.13300549],
                            [39.42474364, -6.13257815],
                            [39.4245439, -6.1316408],
                            [39.42464633, -6.13132834],
                            [39.42486656, -6.13076491],
                            [39.42488771, -6.13001185],
                            [39.42489217, -6.12985316],
                            [39.42485939, -6.12973536],
                            [39.42482661, -6.12961757],
                            [39.42476105, -6.12938197],
                            [39.42459, -6.12876727],
                            [39.42457975, -6.12836774],
                            [39.42478974, -6.12781454],
                            [39.4249946, -6.12670816],
                            [39.42512264, -6.1259091],
                            [39.424969, -6.1253303],
                            [39.42481535, -6.12506394],
                            [39.42460537, -6.12425977],
                            [39.42477438, -6.12388585],
                            [39.42486145, -6.12355291],
                            [39.42494852, -6.12321997],
                            [39.42474878, -6.12128891],
                            [39.4247539, -6.12084329],
                            [39.42484609, -6.11930664],
                            [39.42495877, -6.11868686],
                            [39.42482561, -6.11778023],
                            [39.42462587, -6.11670458],
                            [39.42457465, -6.11610017],
                            [39.42460538, -6.11583381],
                            [39.42473342, -6.11535745],
                            [39.42483341, -6.1151713],
                            [39.42504072, -6.11489646],
                            [39.42524046, -6.11480426],
                            [39.42575741, -6.11481186],
                            [39.42593699, -6.11481451],
                            [39.42635184, -6.11474792],
                            [39.42667962, -6.11470694],
                            [39.42680766, -6.11447644],
                            [39.42682815, -6.11410253],
                            [39.42685376, -6.11388227],
                            [39.42687814, -6.11388227],
                            [39.42692103, -6.11362168],
                            [39.42713614, -6.11354997],
                            [39.42733076, -6.11335533],
                            [39.42735125, -6.11298654],
                            [39.42718736, -6.1123309],
                            [39.42754587, -6.11177771],
                            [39.42781219, -6.11085572],
                            [39.42761757, -6.11020008],
                            [39.42709518, -6.10877612],
                            [39.42663424, -6.10626626],
                            [39.42609859, -6.10320782],
                            [39.42607087, -6.10304955],
                            [39.42576518, -6.10071839],
                            [39.42563235, -6.09970541],
                            [39.42558139, -6.09950277],
                            [39.42555482, -6.09930013],
                            [39.42540998, -6.09748696],
                            [39.42523729, -6.09532533],
                            [39.42513962, -6.09450806],
                            [39.42493, -6.09275401],
                            [39.4249332, -6.09198107],
                            [39.42493701, -6.0910626],
                            [39.42493943, -6.09047604],
                            [39.42494298, -6.08961955],
                            [39.42494305, -6.08960247],
                            [39.42494472, -6.08919965],
                            [39.42494697, -6.08865568],
                            [39.42494745, -6.0885418],
                            [39.42495049, -6.087806],
                            [39.42635382, -6.08098329],
                            [39.42650235, -6.08033789],
                            [39.42665087, -6.0796925],
                            [39.42708109, -6.07822757],
                            [39.42762398, -6.07723387],
                            [39.42811565, -6.07554356],
                            [39.42896584, -6.07387373],
                            [39.4290666, -6.07363714],
                            [39.42967262, -6.07221415],
                            [39.4299287, -6.07147657],
                            [39.42990821, -6.07125119],
                            [39.42995859, -6.07073747],
                            [39.43010027, -6.07053409],
                            [39.43026672, -6.07043677],
                            [39.43040757, -6.07020371],
                            [39.43046391, -6.06990406],
                            [39.43051256, -6.06956856],
                            [39.43053561, -6.06928684],
                            [39.43055103, -6.06909982],
                            [39.43053481, -6.06897493],
                            [39.43057049, -6.06879004],
                            [39.43069861, -6.06850296],
                            [39.43066779, -6.06830184],
                            [39.43071482, -6.06821263],
                            [39.43077807, -6.06818344],
                            [39.43077645, -6.06797259],
                            [39.43080564, -6.06759631],
                            [39.43079915, -6.06741303],
                            [39.43080402, -6.0672622],
                            [39.43084132, -6.06709352],
                            [39.430817, -6.0668778],
                            [39.43083321, -6.06654369],
                            [39.43076348, -6.06634419],
                            [39.43074726, -6.06619822],
                            [39.430817, -6.06607334],
                            [39.43068077, -6.06584951],
                            [39.43067428, -6.06567759],
                            [39.43061428, -6.06547323],
                            [39.43055914, -6.064938],
                            [39.43060942, -6.06460064],
                            [39.43066942, -6.06438331],
                            [39.4308024, -6.06428924],
                            [39.43081376, -6.06420976],
                            [39.43080403, -6.06410272],
                            [39.43072456, -6.06398269],
                            [39.43062563, -6.06395674],
                            [39.43046508, -6.06398432],
                            [39.43041643, -6.06394377],
                            [39.43029967, -6.06367615],
                            [39.43035156, -6.06341016],
                            [39.43061591, -6.06312632],
                            [39.43062239, -6.06300955],
                            [39.43069213, -6.06270787],
                            [39.43056726, -6.06243215],
                            [39.43046022, -6.06248243],
                            [39.43041806, -6.0624581],
                            [39.43047644, -6.06238187],
                            [39.43036616, -6.06207046],
                            [39.43033697, -6.06181744],
                            [39.43025426, -6.06172337],
                            [39.43024302, -6.06170903],
                            [39.4301602, -6.06160335],
                            [39.43019102, -6.0615401],
                            [39.43014561, -6.06147522],
                            [39.43009209, -6.06147198],
                            [39.43005317, -6.06129357],
                            [39.42984721, -6.06128546],
                            [39.42935583, -6.0617623],
                            [39.42928771, -6.06175743],
                            [39.42912392, -6.06168282],
                            [39.42902662, -6.06162443],
                            [39.42895202, -6.0616439],
                            [39.42888066, -6.06158551],
                            [39.42891634, -6.06154172],
                            [39.42895688, -6.06148657],
                            [39.42893742, -6.06141845],
                            [39.42888877, -6.06136331],
                            [39.42881255, -6.06135195],
                            [39.42876876, -6.06141359],
                            [39.42871687, -6.06146873],
                            [39.42859686, -6.06141683],
                            [39.4285255, -6.06130654],
                            [39.4285255, -6.06123842],
                            [39.42842658, -6.06111515],
                            [39.42834549, -6.06108758],
                            [39.42830495, -6.06096918],
                            [39.42833252, -6.06086376],
                            [39.42829198, -6.06065453],
                            [39.42826765, -6.06057181],
                            [39.42830008, -6.06053289],
                            [39.42830657, -6.06048261],
                            [39.42830008, -6.06046477],
                            [39.42821738, -6.06040151],
                            [39.42796763, -6.06041124],
                            [39.42784924, -6.06050531],
                            [39.42775032, -6.06060101],
                            [39.42773734, -6.06072589],
                            [39.42740813, -6.0609562],
                            [39.42736921, -6.06119787],
                            [39.42740165, -6.06130167],
                            [39.42730272, -6.06141196],
                            [39.42725083, -6.06138601],
                            [39.42711947, -6.06126112],
                            [39.4270546, -6.06128221],
                            [39.42692972, -6.06141196],
                            [39.42688756, -6.06154658],
                            [39.42693783, -6.06166173],
                            [39.42688918, -6.06172823],
                            [39.42678052, -6.06187583],
                            [39.42668646, -6.06199909],
                            [39.42665889, -6.06208181],
                            [39.42657781, -6.06209965],
                            [39.4264578, -6.0620445],
                            [39.42630374, -6.0619699],
                            [39.42622589, -6.06180122],
                            [39.42607345, -6.06167795],
                            [39.42598426, -6.06169742],
                            [39.42590803, -6.06175094],
                            [39.42582857, -6.06174283],
                            [39.42579289, -6.06166822],
                            [39.42575235, -6.06165362],
                            [39.42570856, -6.06167471],
                            [39.42564369, -6.06168119],
                            [39.42563072, -6.06159199],
                            [39.42560964, -6.06128869],
                            [39.42561126, -6.06120922],
                            [39.42567451, -6.06117029],
                            [39.42578641, -6.0611265],
                            [39.42580587, -6.0610081],
                            [39.42590966, -6.0607859],
                            [39.42600372, -6.06065128],
                            [39.42600372, -6.06059776],
                            [39.42594696, -6.06055397],
                            [39.42587722, -6.06056857],
                            [39.42582046, -6.06052153],
                            [39.42583506, -6.06045828],
                            [39.42582371, -6.06037069],
                            [39.42577668, -6.06033825],
                            [39.42571343, -6.06021499],
                            [39.42570208, -6.060142],
                            [39.42565505, -6.06002198],
                            [39.42557234, -6.05995062],
                            [39.42549936, -6.05995386],
                            [39.42543774, -6.06000738],
                            [39.42537125, -6.06005766],
                            [39.42528367, -6.05999765],
                            [39.42519772, -6.05994413],
                            [39.42520259, -6.05987114],
                            [39.42527556, -6.05985492],
                            [39.42542476, -6.05984681],
                            [39.42545558, -6.05982249],
                            [39.42542963, -6.05968787],
                            [39.42543287, -6.05960677],
                            [39.4254199, -6.05956622],
                            [39.4253826, -6.05950946],
                            [39.42533881, -6.05952405],
                            [39.42528854, -6.05953216],
                            [39.42524151, -6.05951432],
                            [39.42524313, -6.0594089],
                            [39.42522367, -6.05935862],
                            [39.42508907, -6.05944296],
                            [39.42503068, -6.05959866],
                            [39.42502095, -6.05962948],
                            [39.42498852, -6.05966516],
                            [39.42494635, -6.05965543],
                            [39.42486527, -6.05942674],
                            [39.42478905, -6.05925482],
                            [39.42473715, -6.05908452],
                            [39.42479553, -6.05902775],
                            [39.4248604, -6.05895638],
                            [39.42485229, -6.05890773],
                            [39.42481175, -6.05889151],
                            [39.42472256, -6.05889962],
                            [39.42467066, -6.0588315],
                            [39.4246139, -6.05879257],
                            [39.42456363, -6.05880068],
                            [39.42452633, -6.05876176],
                            [39.42434632, -6.0587358],
                            [39.42416306, -6.05878122],
                            [39.42413063, -6.05858497],
                            [39.42419874, -6.05860119],
                            [39.42428145, -6.05859145],
                            [39.42443065, -6.05856713],
                            [39.42454255, -6.05848603],
                            [39.4246139, -6.05840169],
                            [39.42465607, -6.05840007],
                            [39.42474688, -6.05837574],
                            [39.42477932, -6.05830276],
                            [39.42478581, -6.0581876],
                            [39.42469823, -6.05809028],
                            [39.42468202, -6.05802703],
                            [39.42473067, -6.05777726],
                            [39.42477334, -6.05753761],
                            [39.42479391, -6.05742206],
                            [39.42483446, -6.05733447],
                            [39.42498852, -6.05720959],
                            [39.42503393, -6.05712849],
                            [39.42503555, -6.05689818],
                            [39.4249642, -6.05666787],
                            [39.42486365, -6.05651379],
                            [39.42479878, -6.05646351],
                            [39.42471283, -6.0564554],
                            [39.4246658, -6.05646027],
                            [39.42463985, -6.05647324],
                            [39.42461715, -6.0565073],
                            [39.42460093, -6.05651541],
                            [39.42456201, -6.05647162],
                            [39.42450849, -6.05643431],
                            [39.42443714, -6.05642945],
                            [39.42440957, -6.05644242],
                            [39.42438524, -6.05648459],
                            [39.42438362, -6.05655596],
                            [39.42434956, -6.05662246],
                            [39.4242928, -6.05661597],
                            [39.42426848, -6.05658353],
                            [39.42422955, -6.05652028],
                            [39.42416306, -6.05654136],
                            [39.42419226, -6.05663543],
                            [39.4241994, -6.05665907],
                            [39.42421334, -6.05670517],
                            [39.42423852, -6.05671895],
                            [39.42429929, -6.05675221],
                            [39.42430415, -6.05681709],
                            [39.42430354, -6.05681848],
                            [39.42428145, -6.05686899],
                            [39.42420685, -6.05687385],
                            [39.42418253, -6.05696144],
                            [39.42420199, -6.05703118],
                            [39.42418577, -6.05706848],
                            [39.42413549, -6.05709443],
                            [39.4240463, -6.05711227],
                            [39.4239944, -6.05706524],
                            [39.42394251, -6.05704091],
                            [39.42386953, -6.05706686],
                            [39.42379493, -6.05711876],
                            [39.42369276, -6.05713011],
                            [39.42357924, -6.05711876],
                            [39.42348518, -6.05706848],
                            [39.42342356, -6.05693386],
                            [39.42347707, -6.05682195],
                            [39.42355492, -6.05676518],
                            [39.42366844, -6.05672464],
                            [39.42367492, -6.05668247],
                            [39.42359222, -6.05660461],
                            [39.42353546, -6.05649432],
                            [39.42354194, -6.0564262],
                            [39.42343977, -6.05613264],
                            [39.42340248, -6.05610993],
                            [39.42329706, -6.05607425],
                            [39.42331652, -6.05595585],
                            [39.42321598, -6.05566066],
                            [39.42310246, -6.05552118],
                            [39.42310408, -6.05543035],
                            [39.42307489, -6.05531519],
                            [39.42298083, -6.05526654],
                            [39.42287866, -6.05528276],
                            [39.42281703, -6.05535736],
                            [39.42278946, -6.05542548],
                            [39.42276676, -6.05552604],
                            [39.42272135, -6.05558119],
                            [39.4226354, -6.0556266],
                            [39.42244403, -6.0556639],
                            [39.42234511, -6.0556412],
                            [39.42224618, -6.05561849],
                            [39.42197698, -6.05560714],
                            [39.42163804, -6.05556497],
                            [39.42149046, -6.05550171],
                            [39.42135748, -6.05544981],
                            [39.42130558, -6.05546441],
                            [39.42123098, -6.05550495],
                            [39.42107205, -6.05548549],
                            [39.42096502, -6.05535087],
                            [39.42056932, -6.05518219],
                            [39.42031146, -6.05512867],
                            [39.42021578, -6.05512705],
                            [39.42009091, -6.05517895],
                            [39.42001307, -6.05514651],
                            [39.41993198, -6.0549827],
                            [39.4198363, -6.05480267],
                            [39.4196352, -6.05474428],
                            [39.41955412, -6.0545756],
                            [39.41943411, -6.05450748],
                            [39.4192768, -6.05451234],
                            [39.41922381, -6.05460151],
                            [39.41927356, -6.054694],
                            [39.41910328, -6.05483672],
                            [39.41896219, -6.05489673],
                            [39.41883731, -6.05504919],
                            [39.41879839, -6.05513191],
                            [39.41879515, -6.05529086],
                            [39.41856811, -6.0554936],
                            [39.41837837, -6.05554712],
                            [39.41821944, -6.05570445],
                            [39.41810429, -6.05574499],
                            [39.41810429, -6.05581798],
                            [39.41811078, -6.05590881],
                            [39.41814645, -6.05593061],
                            [39.41816592, -6.05597044],
                            [39.41812862, -6.05600612],
                            [39.41787563, -6.05607262],
                            [39.41729181, -6.05607586],
                            [39.41729992, -6.05601423],
                            [39.41732911, -6.05590232],
                            [39.41727883, -6.05578554],
                            [39.41711017, -6.05575797],
                            [39.41705017, -6.05579851],
                            [39.41697233, -6.05588447],
                            [39.41688638, -6.05595422],
                            [39.41683286, -6.05598179],
                            [39.41677772, -6.05598017],
                            [39.41675339, -6.05589583],
                            [39.41654906, -6.0559088],
                            [39.4163058, -6.05602882],
                            [39.41598307, -6.05589907],
                            [39.41584685, -6.05589583],
                            [39.41556467, -6.05592826],
                            [39.415276, -6.05594935],
                            [39.41440351, -6.05558117],
                            [39.41348237, -6.0551984],
                            [39.41265367, -6.05456261],
                            [39.41227905, -6.05426418],
                            [39.41179091, -6.05379382],
                            [39.4116109, -6.05352296],
                            [39.41150225, -6.05338348],
                            [39.4108049, -6.05270552],
                            [39.41038163, -6.05215245],
                            [39.4101888, -6.05188274],
                            [39.40978691, -6.05132065],
                            [39.40911993, -6.05038781],
                            [39.40830583, -6.04925085],
                            [39.40789878, -6.04864182],
                            [39.40749172, -6.04803279],
                            [39.40695007, -6.04694125],
                            [39.40661762, -6.04618381],
                            [39.40652233, -6.04592452],
                            [39.40619921, -6.04504523],
                            [39.40598515, -6.04416291],
                            [39.40585379, -6.04342819],
                            [39.40572033, -6.04247577],
                            [39.40568675, -6.04223608],
                            [39.40566567, -6.04198631],
                            [39.40565594, -6.0413489],
                            [39.40573703, -6.04076177],
                            [39.40591055, -6.04027195],
                            [39.40600137, -6.04005299],
                            [39.40611813, -6.03987783],
                            [39.40625274, -6.03972861],
                            [39.4063468, -6.03969293],
                            [39.40645221, -6.03969131],
                            [39.40662411, -6.03973672],
                            [39.40671006, -6.03978051],
                            [39.40688521, -6.03978214],
                            [39.40707009, -6.03985188],
                            [39.4071382, -6.03984863],
                            [39.40715766, -6.03981133],
                            [39.40714631, -6.03971726],
                            [39.40715928, -6.0396686],
                            [39.40726145, -6.03969455],
                            [39.40752417, -6.03969455],
                            [39.40759553, -6.03968644],
                            [39.40761499, -6.03964752],
                            [39.40757445, -6.03952588],
                            [39.40744471, -6.03923231],
                            [39.40740416, -6.03888846],
                            [39.40744633, -6.03860787],
                            [39.4074966, -6.03848299],
                            [39.40762796, -6.03821051],
                            [39.40783717, -6.03796722],
                            [39.40797988, -6.03793154],
                            [39.40812259, -6.03804994],
                            [39.40844694, -6.03830944],
                            [39.40861397, -6.03840676],
                            [39.4087129, -6.03835161],
                            [39.40880696, -6.03828349],
                            [39.40885399, -6.0381213],
                            [39.40887669, -6.03794452],
                            [39.40899022, -6.03787477],
                            [39.40900968, -6.03766555],
                            [39.40894805, -6.03736387],
                            [39.40885724, -6.03706544],
                            [39.40874696, -6.03682864],
                            [39.40866263, -6.03667618],
                            [39.40852478, -6.03630152],
                            [39.40824747, -6.03575331],
                            [39.4081912, -6.03564876],
                            [39.40796205, -6.03522295],
                            [39.40769446, -6.03477044],
                            [39.40746742, -6.03459851],
                            [39.40734255, -6.03454985],
                            [39.40695171, -6.03391407],
                            [39.40676846, -6.03351994],
                            [39.40671656, -6.03343885],
                            [39.40657223, -6.0333691],
                            [39.40655601, -6.03327341],
                            [39.40658034, -6.03318745],
                            [39.4067117, -6.0330058],
                            [39.40666791, -6.0327544],
                            [39.40646033, -6.03253058],
                            [39.40643925, -6.03236838],
                            [39.40635167, -6.03203265],
                            [39.40610355, -6.03151202],
                            [39.40607436, -6.03133198],
                            [39.40605166, -6.03128981],
                            [39.40592192, -6.03117141],
                            [39.40574515, -6.03075296],
                            [39.40562352, -6.03052265],
                            [39.40550351, -6.03037992],
                            [39.40485969, -6.0292089],
                            [39.40473968, -6.02897373],
                            [39.404644, -6.02862177],
                            [39.40459049, -6.02817899],
                            [39.40457751, -6.02784974],
                            [39.40441048, -6.02742156],
                            [39.40430182, -6.02721557],
                            [39.40412992, -6.02702257],
                            [39.40405207, -6.02684902],
                            [39.40403424, -6.02671927],
                            [39.40396126, -6.02662195],
                            [39.4039272, -6.02653113],
                            [39.40394504, -6.02639975],
                            [39.40388179, -6.02617593],
                            [39.40353799, -6.02524982],
                            [39.40345366, -6.02490597],
                            [39.40339366, -6.02483623],
                            [39.40323473, -6.02462376],
                            [39.40318445, -6.02443075],
                            [39.40313418, -6.02423774],
                            [39.4030612, -6.02384362],
                            [39.40303526, -6.02344139],
                            [39.40300931, -6.02322729],
                            [39.40293795, -6.02300185],
                            [39.40284876, -6.02287372],
                            [39.40263469, -6.02270342],
                            [39.40244982, -6.02247797],
                            [39.4024336, -6.02242769],
                            [39.40242711, -6.02234173],
                            [39.40232494, -6.02222171],
                            [39.40219034, -6.02218765],
                            [39.40206871, -6.02218927],
                            [39.40185464, -6.02223631],
                            [39.40164868, -6.02227523],
                            [39.40150921, -6.02225415],
                            [39.40140218, -6.0221763],
                            [39.40128055, -6.02204979],
                            [39.40116703, -6.02186489],
                            [39.40116865, -6.02175946],
                            [39.40111838, -6.02166539],
                            [39.40098053, -6.02124694],
                            [39.40073565, -6.02058358],
                            [39.4005151, -6.02006457],
                            [39.40040482, -6.01974018],
                            [39.40032049, -6.01946121],
                            [39.40018, -6.01920454],
                            [39.39990172, -6.01847011],
                            [39.39965823, -6.017612],
                            [39.399589, -6.0173766],
                            [39.3990075, -6.01691966],
                            [39.39812141, -6.0169612],
                            [39.39762269, -6.01672568],
                            [39.39590629, -6.01442054],
                            [39.39409181, -6.01162493],
                            [39.39237541, -6.01015356],
                            [39.39002149, -6.00755414],
                            [39.38805989, -6.00534708],
                            [39.38649061, -6.00421903],
                            [39.38462709, -6.00240434],
                            [39.38310685, -6.00034442],
                            [39.38198738, -5.99790233],
                            [39.38187439, -5.99727277],
                            [39.38181524, -5.99701482],
                            [39.38147088, -5.99551322],
                            [39.38101894, -5.99389896],
                            [39.38048629, -5.99283355],
                            [39.3799368, -5.99150937],
                            [39.37856779, -5.98846461],
                            [39.37796804, -5.98713456],
                            [39.37718575, -5.98528293],
                            [39.37676853, -5.98363993],
                            [39.37656859, -5.98138624],
                            [39.37587842, -5.97758982],
                            [39.37535475, -5.97612083],
                            [39.37385718, -5.97191984],
                            [39.37143289, -5.96548539],
                            [39.37129366, -5.96511585],
                            [39.36981471, -5.95993891],
                            [39.36946963, -5.9562904],
                            [39.36850831, -5.95328285],
                            [39.36759629, -5.95047251],
                            [39.36610285, -5.94599077],
                            [39.36291237, -5.936554],
                            [39.36147712, -5.9326318],
                            [39.36108743, -5.93116641],
                            [39.36065098, -5.92952953],
                            [39.3602613, -5.92842269],
                            [39.35999631, -5.92631814],
                            [39.3596378, -5.92546073],
                            [39.35928761, -5.9246812],
                            [39.35806644, -5.92196282],
                            [39.35795024, -5.92170604],
                            [39.35754181, -5.9208035],
                            [39.35724701, -5.92018885],
                            [39.35715208, -5.91962418],
                            [39.35703716, -5.91913947],
                            [39.35647255, -5.91735551],
                            [39.35639049, -5.91695905],
                            [39.35634824, -5.91669331],
                            [39.3559466, -5.91358551],
                            [39.35576944, -5.91180004],
                            [39.3556718, -5.91081596],
                            [39.35573522, -5.9077293],
                            [39.35551932, -5.90365091],
                            [39.35470118, -5.89586353],
                            [39.3542231, -5.89357941],
                            [39.35392548, -5.88919717],
                            [39.35296978, -5.88165601],
                            [39.35281373, -5.87804971],
                            [39.35282457, -5.87568854],
                            [39.35284305, -5.87540479],
                            [39.35300967, -5.87284703],
                            [39.35303233, -5.8724901],
                            [39.35354876, -5.86852045],
                            [39.35443903, -5.86244707],
                            [39.3551042, -5.85764497],
                            [39.35524073, -5.85636149],
                            [39.3553008, -5.85599556],
                            [39.3556534, -5.85398673],
                            [39.35579138, -5.85303122],
                            [39.35587586, -5.8522649],
                            [39.35596034, -5.85149859],
                            [39.35613134, -5.85055797],
                            [39.35617409, -5.85000215],
                            [39.35630235, -5.84862329],
                            [39.35680391, -5.84655282],
                            [39.35768466, -5.84344458],
                            [39.35768772, -5.84343376],
                            [39.35769691, -5.84340133],
                            [39.35769652, -5.84339455],
                            [39.35763571, -5.8423352],
                            [39.35762083, -5.83965609],
                            [39.35922295, -5.83446637],
                            [39.35930253, -5.83420857],
                            [39.35928362, -5.83205996],
                            [39.35973741, -5.82862778],
                            [39.35976221, -5.82844023],
                            [39.35990441, -5.82678529],
                            [39.36004662, -5.82513035],
                            [39.36009124, -5.8246111],
                            [39.35982204, -5.82365382],
                            [39.35883495, -5.8216495],
                            [39.35877514, -5.81755114],
                            [39.35853527, -5.81606128],
                            [39.35820682, -5.81402116],
                            [39.35760859, -5.80932449],
                            [39.35745284, -5.80834189],
                            [39.35803363, -5.80376356],
                            [39.35781709, -5.80304261],
                            [39.35673921, -5.79945407],
                            [39.35508522, -5.79408373],
                            [39.35462613, -5.79306341],
                            [39.35456736, -5.7930182],
                            [39.353963, -5.79255325],
                            [39.35335088, -5.79199208],
                            [39.35319093, -5.79088215],
                            [39.35300524, -5.79043453],
                            [39.35285441, -5.79007095],
                            [39.35194218, -5.78787193],
                            [39.35177485, -5.78738582],
                            [39.35150283, -5.78659563],
                            [39.35144953, -5.78644077],
                            [39.35110636, -5.7853051],
                            [39.3510578, -5.7851444],
                            [39.35058659, -5.7839665],
                            [39.349975, -5.78247076],
                            [39.34936844, -5.78130195],
                            [39.34923922, -5.78115198],
                            [39.34880155, -5.780644],
                            [39.3482575, -5.77967202],
                            [39.34753721, -5.77842507],
                            [39.34727837, -5.77789115],
                            [39.34673096, -5.77676195],
                            [39.34595472, -5.77554535],
                            [39.34363373, -5.77190769],
                            [39.34363141, -5.77190783],
                            [39.34280173, -5.77067602],
                            [39.34000027, -5.76695168],
                            [39.33957714, -5.7661159],
                            [39.3392069, -5.76561866],
                            [39.33837121, -5.7654917],
                            [39.33764131, -5.76468766],
                            [39.33729223, -5.76379898],
                            [39.33687967, -5.76309015],
                            [39.33610745, -5.76256117],
                            [39.33589589, -5.76194756],
                            [39.33585415, -5.76171134],
                            [39.33583122, -5.76158156],
                            [39.33520393, -5.76058396],
                            [39.33493134, -5.76030404],
                            [39.33404594, -5.75939481],
                            [39.33334288, -5.75811261],
                            [39.33281558, -5.75776104],
                            [39.33056164, -5.75432803],
                            [39.32993584, -5.75277874],
                            [39.32993095, -5.75276664],
                            [39.32992167, -5.7527589],
                            [39.3291245, -5.75209451],
                            [39.32833872, -5.75057448],
                            [39.32785278, -5.7496852],
                            [39.32738752, -5.74905444],
                            [39.32585732, -5.74645901],
                            [39.32554543, -5.74595591],
                            [39.32425475, -5.74387391],
                            [39.32348965, -5.74343962],
                            [39.32285896, -5.74241592],
                            [39.3226315, -5.74159903],
                            [39.32251102, -5.74156932],
                            [39.32245164, -5.74156598],
                            [39.32239226, -5.74157518],
                            [39.32236996, -5.74157762],
                            [39.3221438, -5.7412783],
                            [39.32207368, -5.74113804],
                            [39.32199479, -5.74094518],
                            [39.32190714, -5.74077862],
                            [39.32180195, -5.74053316],
                            [39.321688, -5.74040166],
                            [39.32158609, -5.74041102],
                            [39.32150393, -5.74025264],
                            [39.32141628, -5.74015621],
                            [39.32129356, -5.74010361],
                            [39.32100431, -5.73988445],
                            [39.32078517, -5.73961269],
                            [39.32047838, -5.73920944],
                            [39.32018036, -5.73870975],
                            [39.31997876, -5.73840293],
                            [39.3198166, -5.73823637],
                            [39.31965444, -5.73806981],
                            [39.3194879, -5.73795584],
                            [39.31933889, -5.73764902],
                            [39.3190321, -5.73724577],
                            [39.3188042, -5.73689511],
                            [39.31807667, -5.73623763],
                            [39.31740174, -5.73536976],
                            [39.31672681, -5.73465092],
                            [39.31624471, -5.73403727],
                            [39.31591371, -5.733588],
                            [39.31575385, -5.73337102],
                            [39.31509882, -5.73263403],
                            [39.31407966, -5.73149502],
                            [39.31324695, -5.73056578],
                            [39.31275303, -5.72980092],
                            [39.31244931, -5.72929466],
                            [39.31206363, -5.72882127],
                            [39.31173931, -5.72823392],
                            [39.31143691, -5.72789642],
                            [39.3111345, -5.72755891],
                            [39.31074882, -5.7269891],
                            [39.31052969, -5.72643682],
                            [39.3102492, -5.72604233],
                            [39.31006965, -5.72574304],
                            [39.31001253, -5.72564784],
                            [39.30985979, -5.72546598],
                            [39.30954733, -5.72510811],
                            [39.30905711, -5.72459588],
                            [39.30871442, -5.72400291],
                            [39.30843477, -5.72347378],
                            [39.308304, -5.72334663],
                            [39.30819682, -5.72323577],
                            [39.30808963, -5.72312491],
                            [39.30741799, -5.72216759],
                            [39.30689206, -5.7218257],
                            [39.30654145, -5.72158901],
                            [39.30641962, -5.72147556],
                            [39.30603306, -5.72111562],
                            [39.30555096, -5.72084386],
                            [39.30526946, -5.72076566],
                            [39.30517734, -5.72076125],
                            [39.30481275, -5.72078322],
                            [39.30456049, -5.72079841],
                            [39.30421721, -5.72075431],
                            [39.30385158, -5.72063968],
                            [39.30362258, -5.72056334],
                            [39.30352385, -5.72050451],
                            [39.30345295, -5.72044067],
                            [39.30345308, -5.72044032],
                            [39.3034531, -5.72044028],
                            [39.30345312, -5.72044022],
                            [39.30345313, -5.72044015],
                            [39.30345314, -5.72044009],
                            [39.30345315, -5.72044002],
                            [39.30345315, -5.72043996],
                            [39.30345315, -5.72043989],
                            [39.30345314, -5.72043983],
                            [39.30345313, -5.72043976],
                            [39.30345312, -5.7204397],
                            [39.3034531, -5.72043963],
                            [39.30345308, -5.72043957],
                            [39.30345305, -5.72043951],
                            [39.30345302, -5.72043946],
                            [39.30345298, -5.7204394],
                            [39.30345295, -5.72043935],
                            [39.3034529, -5.7204393],
                            [39.30345286, -5.72043925],
                            [39.30345281, -5.7204392],
                            [39.30345276, -5.72043916],
                            [39.30345271, -5.72043912],
                            [39.30345265, -5.72043909],
                            [39.30345261, -5.72043907],
                            [39.30310327, -5.72025726],
                            [39.30256245, -5.71995758],
                            [39.30256241, -5.71995756],
                            [39.30256235, -5.71995753],
                            [39.30256229, -5.71995751],
                            [39.30199253, -5.71976464],
                            [39.30185227, -5.71972081],
                            [39.30185223, -5.7197208],
                            [39.30185217, -5.71972078],
                            [39.3018521, -5.71972077],
                            [39.30185204, -5.71972076],
                            [39.30185197, -5.71972076],
                            [39.30185191, -5.71972076],
                            [39.30185184, -5.71972077],
                            [39.30185178, -5.71972078],
                            [39.30185171, -5.7197208],
                            [39.30185165, -5.71972082],
                            [39.30185159, -5.71972084],
                            [39.30185153, -5.71972086],
                            [39.3018515, -5.71972088],
                            [39.30173755, -5.71978225],
                            [39.30173752, -5.71978226],
                            [39.30173747, -5.71978229],
                            [39.30173741, -5.71978233],
                            [39.30173736, -5.71978238],
                            [39.30173731, -5.71978242],
                            [39.30173727, -5.71978247],
                            [39.30173723, -5.71978252],
                            [39.30173719, -5.71978257],
                            [39.30173716, -5.71978263],
                            [39.30173712, -5.71978268],
                            [39.3017371, -5.71978274],
                            [39.30173707, -5.7197828],
                            [39.30173706, -5.71978286],
                            [39.30160569, -5.72026458],
                            [39.301431, -5.72035194],
                            [39.30119125, -5.72035048],
                            [39.30089875, -5.72034042],
                            [39.30031584, -5.72028552],
                            [39.29982935, -5.72026486],
                            [39.29962474, -5.72026075],
                            [39.29932753, -5.72026525],
                            [39.29892643, -5.72030301],
                            [39.29871114, -5.72032327],
                            [39.29858248, -5.72033539],
                            [39.29849124, -5.72035971],
                            [39.29827031, -5.72041864],
                            [39.29818804, -5.72044059],
                            [39.29811827, -5.72046595],
                            [39.29773315, -5.72060601],
                            [39.29728342, -5.72075922],
                            [39.29673298, -5.72096658],
                            [39.29616134, -5.72113243],
                            [39.29560244, -5.7212946],
                            [39.29537588, -5.72136033],
                            [39.29520416, -5.72141376],
                            [39.29515559, -5.7214288],
                            [39.29484098, -5.72152917],
                            [39.29429621, -5.72170295],
                            [39.29333201, -5.72207114],
                            [39.29270967, -5.72230783],
                            [39.29262202, -5.72243056],
                            [39.29220128, -5.72280751],
                            [39.29216853, -5.72297952],
                            [39.29221004, -5.7231757],
                            [39.29200844, -5.72342116],
                            [39.29171918, -5.72364908],
                            [39.2911319, -5.72449065],
                            [39.29086894, -5.72484131],
                            [39.29085456, -5.7249443],
                            [39.29083328, -5.72509667],
                            [39.29082058, -5.72518766],
                            [39.29092153, -5.72529716],
                            [39.29089967, -5.72544683],
                            [39.2906498, -5.72587574],
                            [39.29050079, -5.72605983],
                            [39.29036054, -5.72648062],
                            [39.29032132, -5.72679906],
                            [39.29029042, -5.72766408],
                            [39.29022044, -5.72821098],
                            [39.28966807, -5.73029399],
                            [39.28944893, -5.73128459],
                            [39.28927363, -5.73213493],
                            [39.2885461, -5.73423885],
                            [39.28844968, -5.7348262],
                            [39.28827437, -5.73574667],
                            [39.28800264, -5.73647428],
                            [39.28783609, -5.73751748],
                            [39.2878098, -5.73858697],
                            [39.28794128, -5.73885873],
                            [39.28787115, -5.74027012],
                            [39.28780696, -5.74188136],
                            [39.28778349, -5.74247048],
                            [39.28785362, -5.74326822],
                            [39.28795058, -5.7435245],
                            [39.28804142, -5.7437646],
                            [39.28826537, -5.74457541],
                            [39.28819371, -5.74522495],
                            [39.28813996, -5.74552957],
                            [39.28787862, -5.74607343],
                            [39.28708737, -5.74772009],
                            [39.28642446, -5.74920732],
                            [39.28656778, -5.75096333],
                            [39.28656008, -5.75124582],
                            [39.28652324, -5.75259659],
                            [39.28651403, -5.75293436],
                            [39.28656142, -5.75349993],
                            [39.28656139, -5.75349993],
                            [39.28657504, -5.75355277],
                            [39.28669321, -5.75379218],
                            [39.28695986, -5.75439524],
                            [39.2866023, -5.75544075],
                            [39.28669926, -5.75745905],
                            [39.28731204, -5.75746273],
                            [39.28853886, -5.75747011],
                            [39.28855544, -5.75747021],
                            [39.2885389, -5.75747022],
                            [39.28879273, -5.75787748],
                            [39.28893248, -5.75868271],
                            [39.28900899, -5.75912357],
                            [39.28883551, -5.75967433],
                            [39.28866581, -5.7602131],
                            [39.28887734, -5.76069546],
                            [39.2890691, -5.76079774],
                            [39.28925811, -5.76089856],
                            [39.28938503, -5.76208329],
                            [39.28931734, -5.76303108],
                            [39.28915742, -5.76397526],
                            [39.28915774, -5.76397698],
                            [39.28915869, -5.76398196],
                            [39.28936337, -5.76506307],
                            [39.2893724, -5.76511076],
                            [39.28950463, -5.76566619],
                            [39.28963648, -5.76622749],
                            [39.2896399, -5.76622757],
                            [39.28970833, -5.76641771],
                            [39.28974449, -5.76651819],
                            [39.28978064, -5.76661866],
                            [39.28985809, -5.76711566],
                            [39.28996059, -5.76777347],
                            [39.29032795, -5.76919811],
                            [39.29012801, -5.7696814],
                            [39.2896608, -5.77035633],
                            [39.28938393, -5.77099665],
                            [39.28937374, -5.7715066],
                            [39.28934932, -5.77272723],
                            [39.28952236, -5.77383481],
                            [39.28988574, -5.77418526],
                            [39.29084611, -5.77513276],
                            [39.29125084, -5.77530063],
                            [39.29196221, -5.77559569],
                            [39.29220014, -5.77641339],
                            [39.29217751, -5.77661801],
                            [39.29215489, -5.77682264],
                            [39.29186076, -5.77728839],
                            [39.29158765, -5.77772086],
                            [39.29094164, -5.77877666],
                            [39.2914459, -5.77958048],
                            [39.29198021, -5.78006254],
                            [39.29284872, -5.78112047],
                            [39.29342773, -5.78197794],
                            [39.29360589, -5.78272406],
                            [39.29387688, -5.78351387],
                            [39.29414787, -5.78430369],
                            [39.29427548, -5.78527754],
                            [39.29454947, -5.786266],
                            [39.29458614, -5.7863983],
                            [39.29479696, -5.78688655],
                            [39.29486353, -5.78696978],
                            [39.29489681, -5.78701139],
                            [39.2949301, -5.787053],
                            [39.29499566, -5.78775211],
                            [39.2950828, -5.78868143],
                            [39.29495545, -5.78909386],
                            [39.29494729, -5.78912028],
                            [39.294293, -5.79147256],
                            [39.29391954, -5.79254963],
                            [39.29377189, -5.79314029],
                            [39.29322473, -5.79460823],
                            [39.29288601, -5.7950686],
                            [39.29274704, -5.79539866],
                            [39.29258244, -5.79566544],
                            [39.29224862, -5.79639992],
                            [39.2921596, -5.79695634],
                            [39.29193706, -5.79717891],
                            [39.29144746, -5.79755728],
                            [39.29106914, -5.79838078],
                            [39.29122492, -5.7994046],
                            [39.29100237, -5.80080679],
                            [39.29135844, -5.80243154],
                            [39.29120265, -5.80361116],
                            [39.29135844, -5.80414532],
                            [39.29103138, -5.80469466],
                            [39.29062445, -5.80583928],
                            [39.29064988, -5.80650061],
                            [39.29075161, -5.80711108],
                            [39.2907516, -5.80777247],
                            [39.29079539, -5.80854218],
                            [39.2908019, -5.80865654],
                            [39.29081235, -5.80884028],
                            [39.29086332, -5.80973662],
                            [39.29131938, -5.81091369],
                            [39.29103986, -5.81209075],
                            [39.29040725, -5.81315011],
                            [39.29039254, -5.8140182],
                            [39.29033581, -5.81416293],
                            [39.29027908, -5.81430766],
                            [39.29016918, -5.81437742],
                            [39.29005929, -5.81444717],
                            [39.28966425, -5.81481999],
                            [39.28936937, -5.81519837],
                            [39.28931929, -5.81566579],
                            [39.28937771, -5.81619441],
                            [39.28943613, -5.81672304],
                            [39.28941944, -5.81800286],
                            [39.28919448, -5.81881124],
                            [39.28896877, -5.819266],
                            [39.28851329, -5.81987349],
                            [39.28826216, -5.82035106],
                            [39.28783697, -5.82073196],
                            [39.28767239, -5.82102436],
                            [39.28761107, -5.82116788],
                            [39.28741089, -5.82163645],
                            [39.287639, -5.82229863],
                            [39.28778194, -5.82244963],
                            [39.28803404, -5.82271596],
                            [39.28806742, -5.82313886],
                            [39.28819539, -5.82392345],
                            [39.28826215, -5.82431853],
                            [39.28822212, -5.82446121],
                            [39.28801195, -5.82475548],
                            [39.28795311, -5.82507498],
                            [39.2881969, -5.82510861],
                            [39.28845751, -5.82538606],
                            [39.28833141, -5.82610913],
                            [39.28816327, -5.82633613],
                            [39.28810443, -5.82675652],
                            [39.28838185, -5.8271685],
                            [39.28826415, -5.82754685],
                            [39.28729737, -5.8282615],
                            [39.2854731, -5.82890889],
                            [39.2841028, -5.82828672],
                            [39.28247188, -5.8296824],
                            [39.28296788, -5.83133031],
                            [39.28348069, -5.83165821],
                            [39.28352273, -5.83236446],
                            [39.28316123, -5.8330623],
                            [39.28243825, -5.83488678],
                            [39.28207676, -5.83568131],
                            [39.28059822, -5.83729542],
                            [39.28056364, -5.83823768],
                            [39.28082295, -5.83930096],
                            [39.28050313, -5.84067545],
                            [39.2804846, -5.84167356],
                            [39.28017684, -5.84272887],
                            [39.28005593, -5.84386664],
                            [39.27975915, -5.84504287],
                            [39.27935795, -5.84582886],
                            [39.27946237, -5.84637851],
                            [39.27904469, -5.84709304],
                            [39.27860502, -5.84757673],
                            [39.27768172, -5.84928063],
                            [39.27719809, -5.85161112],
                            [39.27710032, -5.85176901],
                            [39.27695303, -5.85251081],
                            [39.27680573, -5.85305269],
                            [39.27664792, -5.85335783],
                            [39.27647433, -5.85361036],
                            [39.27655849, -5.85373136],
                            [39.27659619, -5.85380225],
                            [39.27667558, -5.85385619],
                            [39.27665282, -5.85402188],
                            [39.27662993, -5.85444166],
                            [39.27661085, -5.85507895],
                            [39.27653835, -5.85553308],
                            [39.27651545, -5.85579639],
                            [39.27644587, -5.85584772],
                            [39.27637628, -5.85589906],
                            [39.27620715, -5.85635446],
                            [39.27610958, -5.85692697],
                            [39.27577132, -5.85731731],
                            [39.27566073, -5.85753851],
                            [39.27541354, -5.85818908],
                            [39.27519237, -5.85852738],
                            [39.27512732, -5.8587811],
                            [39.27513382, -5.85892423],
                            [39.2743885, -5.85968473],
                            [39.27409538, -5.86023354],
                            [39.2740499, -5.86052919],
                            [39.27412022, -5.86085845],
                            [39.27424107, -5.86116665],
                            [39.27407188, -5.86165615],
                            [39.2739833, -5.86169147],
                            [39.27391145, -5.8618208],
                            [39.27371508, -5.86211779],
                            [39.27348996, -5.86237646],
                            [39.27284816, -5.86336125],
                            [39.2723108, -5.8640778],
                            [39.27168816, -5.86439342],
                            [39.26992257, -5.86508438],
                            [39.26810582, -5.86540853],
                            [39.26666648, -5.86574974],
                            [39.26599906, -5.86619758],
                            [39.26582634, -5.8665388],
                            [39.26506542, -5.86688334],
                            [39.26421858, -5.86733033],
                            [39.26359521, -5.86822432],
                            [39.26319531, -5.86895363],
                            [39.26239551, -5.86927123],
                            [39.25964328, -5.86957706],
                            [39.25800246, -5.86968932],
                            [39.25702175, -5.87008027],
                            [39.2568263, -5.87036492],
                            [39.25673714, -5.87071472],
                            [39.2565554, -5.8710268],
                            [39.25645939, -5.87133545],
                            [39.25644567, -5.87160294],
                            [39.25622278, -5.87202476],
                            [39.25597589, -5.87224768],
                            [39.25567756, -5.87231626],
                            [39.25548211, -5.87236771],
                            [39.25516663, -5.87241572],
                            [39.25505005, -5.87248088],
                            [39.25502281, -5.8725641],
                            [39.25465373, -5.8725867],
                            [39.25451439, -5.87250007],
                            [39.25441647, -5.87238331],
                            [39.25429595, -5.87272983],
                            [39.25414154, -5.87284659],
                            [39.25384402, -5.87282399],
                            [39.25344481, -5.87295958],
                            [39.25324521, -5.87300102],
                            [39.2531021, -5.87313661],
                            [39.25278574, -5.87317804],
                            [39.25265769, -5.87306128],
                            [39.2526577, -5.87283152],
                            [39.25267653, -5.87245487],
                            [39.25266711, -5.87229667],
                            [39.2526577, -5.87213848],
                            [39.25197226, -5.87235317],
                            [39.2515015, -5.87248876],
                            [39.25103074, -5.87266579],
                            [39.25072568, -5.87284658],
                            [39.25036414, -5.87293321],
                            [39.25006661, -5.87309894],
                            [39.2496674, -5.8733852],
                            [39.24913262, -5.87348689],
                            [39.24902224, -5.87377066],
                            [39.24873183, -5.87382162],
                            [39.24825008, -5.87390614],
                            [39.24818848, -5.87410695],
                            [39.24788432, -5.87509838],
                            [39.24817726, -5.87589516],
                            [39.24650226, -5.87631395],
                            [39.24291963, -5.87719807],
                            [39.23719672, -5.87817525],
                            [39.23387107, -5.87790187],
                            [39.2338002, -5.87789605],
                            [39.23316375, -5.87830979],
                            [39.23286965, -5.87850097],
                            [39.23034245, -5.88148999],
                            [39.22751896, -5.88482944],
                            [39.22720674, -5.88511223],
                            [39.22713533, -5.88517691],
                            [39.22582503, -5.88636373],
                            [39.22556805, -5.8865965],
                            [39.22505299, -5.88706302],
                            [39.22411706, -5.88789977],
                            [39.22351757, -5.88843574],
                            [39.22198216, -5.88980845],
                            [39.22183346, -5.89007138],
                            [39.22178745, -5.89029271],
                            [39.22234986, -5.89227496],
                            [39.2234171, -5.8932046],
                            [39.2235548, -5.89440968],
                            [39.22327939, -5.89544261],
                            [39.2231761, -5.89650997],
                            [39.22296263, -5.89698891],
                            [39.22239722, -5.89825742],
                            [39.22185078, -5.89948338],
                            [39.22135419, -5.90050376],
                            [39.22007633, -5.90312947],
                            [39.21736627, -5.90600116],
                            [39.21346249, -5.90800166],
                            [39.20776241, -5.91207819],
                            [39.20770947, -5.91217889],
                            [39.2057706, -5.91586694],
                            [39.20537715, -5.91661535],
                            [39.2051433, -5.92232188],
                            [39.20563351, -5.92398245],
                            [39.20590663, -5.92490761],
                            [39.20592167, -5.92495857],
                            [39.2060598, -5.92780057],
                            [39.20645282, -5.93588659],
                            [39.20527368, -5.9360195],
                            [39.20548393, -5.93403148],
                            [39.20504433, -5.93332421],
                            [39.20466206, -5.93229197],
                            [39.20452827, -5.9317185],
                            [39.2044327, -5.92978783],
                            [39.204146, -5.92923348],
                            [39.20410778, -5.92860266],
                            [39.2038593, -5.92823947],
                            [39.20334324, -5.92739838],
                            [39.20294186, -5.92640437],
                            [39.20273162, -5.92567798],
                            [39.20238758, -5.92453105],
                            [39.20202442, -5.92355615],
                            [39.2019862, -5.92260038],
                            [39.20215822, -5.92210337],
                            [39.2024067, -5.92135787],
                            [39.2024067, -5.92084175],
                            [39.20239372, -5.92044887],
                            [39.20183677, -5.91958804],
                            [39.20136843, -5.91901837],
                            [39.20119122, -5.91830945],
                            [39.20090009, -5.91765116],
                            [39.20046973, -5.91639789],
                            [39.19968494, -5.91518259],
                            [39.19886218, -5.91428378],
                            [39.19800144, -5.91366347],
                            [39.19787486, -5.91301784],
                            [39.19791284, -5.91258743],
                            [39.19772297, -5.91204308],
                            [39.19746981, -5.91129618],
                            [39.19762171, -5.91081512],
                            [39.19795081, -5.91043534],
                            [39.19826726, -5.90981504],
                            [39.19849511, -5.90956185],
                            [39.19865966, -5.90891622],
                            [39.19881156, -5.9083339],
                            [39.19893814, -5.90757434],
                            [39.19905206, -5.90699201],
                            [39.19936851, -5.90667553],
                            [39.19977356, -5.90620713],
                            [39.20016595, -5.90580203],
                            [39.2004824, -5.90543491],
                            [39.20065961, -5.90491588],
                            [39.20060898, -5.90448546],
                            [39.20049506, -5.90390314],
                            [39.20017862, -5.90348538],
                            [39.19986217, -5.90302964],
                            [39.19984951, -5.90266252],
                            [39.19983686, -5.90215615],
                            [39.19969685, -5.90182668],
                            [39.19962167, -5.90164977],
                            [39.19938117, -5.90101681],
                            [39.19926725, -5.90028257],
                            [39.19900144, -5.89954833],
                            [39.1991027, -5.89913057],
                            [39.19920396, -5.89873813],
                            [39.19905207, -5.89851026],
                            [39.19907739, -5.89790261],
                            [39.19860905, -5.89767475],
                            [39.19827784, -5.89725213],
                            [39.19824197, -5.89720635],
                            [39.19807742, -5.89695316],
                            [39.1973306, -5.89678859],
                            [39.1966977, -5.89683923],
                            [39.19598886, -5.89685189],
                            [39.19559647, -5.89669997],
                            [39.19558381, -5.89624424],
                            [39.19529268, -5.89563659],
                            [39.19512813, -5.8951049],
                            [39.19481251, -5.89465397],
                            [39.1946851, -5.89447193],
                            [39.19458077, -5.89432155],
                            [39.19425474, -5.89385162],
                            [39.19400093, -5.89370479],
                            [39.19373565, -5.89379323],
                            [39.19347038, -5.89388166],
                            [39.19302825, -5.89414697],
                            [39.19248001, -5.89465989],
                            [39.19180798, -5.89533199],
                            [39.19145428, -5.89582723],
                            [39.19104753, -5.89635784],
                            [39.19069383, -5.89678232],
                            [39.19034013, -5.89747211],
                            [39.18973883, -5.89817959],
                            [39.1884832, -5.89989523],
                            [39.18766969, -5.90062039],
                            [39.18669701, -5.90164624],
                            [39.18514073, -5.90345031],
                            [39.18386741, -5.9048122],
                            [39.18333686, -5.90544893],
                            [39.18316, -5.90615641],
                            [39.1829301, -5.90682852],
                            [39.18298315, -5.90788974],
                            [39.18305389, -5.90882715],
                            [39.18296546, -5.9093047],
                            [39.18285935, -5.91059585],
                            [39.1829124, -5.91172782],
                            [39.18278861, -5.91285978],
                            [39.1830362, -5.91374413],
                            [39.1829124, -5.91454005],
                            [39.18280629, -5.91544208],
                            [39.18310693, -5.91591963],
                            [39.18315999, -5.91639718],
                            [39.18310848, -5.91687794],
                            [39.18305388, -5.91738765],
                            [39.18338989, -5.91851962],
                            [39.18370822, -5.91912098],
                            [39.184313, -5.920492],
                            [39.18504656, -5.92215496],
                            [39.1851137, -5.92272568],
                            [39.18544937, -5.9240014],
                            [39.18578505, -5.92527713],
                            [39.18576826, -5.92677107],
                            [39.18602002, -5.92796286],
                            [39.18613751, -5.92900358],
                            [39.18615429, -5.93038002],
                            [39.18623821, -5.93150468],
                            [39.18628856, -5.9326629],
                            [39.18608715, -5.93412327],
                            [39.18586896, -5.93548292],
                            [39.18570111, -5.93627185],
                            [39.18519759, -5.93751401],
                            [39.18477799, -5.93831973],
                            [39.18415699, -5.93900794],
                            [39.18378689, -5.93958246],
                            [39.18328065, -5.94092848],
                            [39.18235459, -5.94217571],
                            [39.18149149, -5.94358543],
                            [39.18122977, -5.94484714],
                            [39.18074803, -5.94650518],
                            [39.18067416, -5.94761325],
                            [39.18066474, -5.94775466],
                            [39.18066475, -5.94782196],
                            [39.180592, -5.94805477],
                            [39.18051926, -5.94828759],
                            [39.18078114, -5.94958261],
                            [39.18081044, -5.94986835],
                            [39.18095573, -5.95128505],
                            [39.18091539, -5.95173194],
                            [39.1807968, -5.95294428],
                            [39.18058598, -5.95365587],
                            [39.18067821, -5.95418297],
                            [39.18068512, -5.95422095],
                            [39.18078362, -5.95476279],
                            [39.18085622, -5.95491],
                            [39.18092882, -5.95505721],
                            [39.18094291, -5.95550816],
                            [39.18135154, -5.95686103],
                            [39.18194556, -5.95842342],
                            [39.18203575, -5.95871656],
                            [39.1823775, -5.9598274],
                            [39.18295342, -5.96322935],
                            [39.18328173, -5.96549538],
                            [39.18315392, -5.96639019],
                            [39.18328173, -5.96786023],
                            [39.18331746, -5.9688529],
                            [39.18338251, -5.97044702],
                            [39.18329309, -5.97160958],
                            [39.18327408, -5.97185678],
                            [39.18289457, -5.97290868],
                            [39.1826777, -5.97445942],
                            [39.18253674, -5.97566314],
                            [39.18253674, -5.97678011],
                            [39.18266685, -5.97794045],
                            [39.18307889, -5.97939359],
                            [39.1835343, -5.9807166],
                            [39.1838379, -5.98154077],
                            [39.18403581, -5.98221128],
                            [39.18401661, -5.9822977],
                            [39.18399741, -5.98238411],
                            [39.1838246, -5.98274897],
                            [39.18392061, -5.98368993],
                            [39.18399741, -5.98455408],
                            [39.18445823, -5.98545663],
                            [39.18424702, -5.98632077],
                            [39.18411261, -5.98714651],
                            [39.18418941, -5.98802985],
                            [39.18432382, -5.98860595],
                            [39.184503, -5.98888804],
                            [39.18442135, -5.98909467],
                            [39.18393059, -5.99005192],
                            [39.18358113, -5.99045966],
                            [39.18331322, -5.99099555],
                            [39.18328992, -5.99170618],
                            [39.18356107, -5.99279823],
                            [39.18372091, -5.99344199],
                            [39.1841053, -5.99438562],
                            [39.18452465, -5.99539915],
                            [39.18514201, -5.99636608],
                            [39.18656312, -5.99735631],
                            [39.18705025, -5.99772539],
                            [39.18771631, -5.99823004],
                            [39.18789104, -5.99876593],
                            [39.18792392, -5.99941892],
                            [39.18795681, -6.00007191],
                            [39.18797446, -6.00021314],
                            [39.18799211, -6.00035437],
                            [39.18838044, -6.00091929],
                            [39.18848635, -6.00187259],
                            [39.18809802, -6.00250812],
                            [39.18811715, -6.00329297],
                            [39.18813332, -6.00395572],
                            [39.18869817, -6.00522678],
                            [39.18953396, -6.00647321],
                            [39.18964534, -6.00667108],
                            [39.18989268, -6.00703912],
                            [39.19053314, -6.00799212],
                            [39.19069555, -6.00915073],
                            [39.19085795, -6.01030935],
                            [39.1914426, -6.01210682],
                            [39.19174803, -6.01392635],
                            [39.19194064, -6.01507374],
                            [39.19236256, -6.01677727],
                            [39.19252529, -6.01743429],
                            [39.19258067, -6.01757831],
                            [39.1931148, -6.01896722],
                            [39.19332889, -6.01952394],
                            [39.19346641, -6.01988153],
                            [39.19355527, -6.02011261],
                            [39.19390378, -6.02092216],
                            [39.1942523, -6.0217317],
                            [39.19456449, -6.02256979],
                            [39.19467951, -6.02287856],
                            [39.19692799, -6.02573445],
                            [39.19847944, -6.02667892],
                            [39.20044315, -6.02757333],
                            [39.20062469, -6.02765602],
                            [39.20062758, -6.02765734],
                            [39.20069263, -6.02771882],
                            [39.20191738, -6.02887641],
                            [39.20199627, -6.02895097],
                            [39.20261293, -6.02965796],
                            [39.20280093, -6.03085381],
                            [39.2030679, -6.03215121],
                            [39.20336119, -6.03329818],
                            [39.20349949, -6.03384201],
                            [39.2035306, -6.03478445],
                            [39.20365883, -6.03613808],
                            [39.20358046, -6.03803316],
                            [39.20365526, -6.03822195],
                            [39.20373006, -6.03841075],
                        ],
                        [
                            [39.21760348, -5.85502165],
                            [39.2176597, -5.85501401],
                            [39.21795958, -5.85504733],
                            [39.21830389, -5.8551001],
                            [39.21863513, -5.85521001],
                            [39.21873504, -5.85525153],
                            [39.21899264, -5.85535857],
                            [39.21933283, -5.85549993],
                            [39.21941316, -5.85553331],
                            [39.21965788, -5.85562402],
                            [39.21974992, -5.85572594],
                            [39.2199603, -5.85586073],
                            [39.22012795, -5.85584758],
                            [39.22032847, -5.85571936],
                            [39.22054213, -5.85550896],
                            [39.22061206, -5.85557357],
                            [39.22068199, -5.85563819],
                            [39.22077183, -5.8557027],
                            [39.22086167, -5.85576721],
                            [39.22097685, -5.85587779],
                            [39.22116575, -5.85579025],
                            [39.22135925, -5.85586397],
                            [39.22148826, -5.85591466],
                            [39.2216449, -5.85583172],
                            [39.22185223, -5.85560594],
                            [39.22193976, -5.85545388],
                            [39.22211484, -5.85541241],
                            [39.22239588, -5.85549075],
                            [39.22280132, -5.85566123],
                            [39.2231745, -5.85582251],
                            [39.22375501, -5.85580408],
                            [39.22437238, -5.85568427],
                            [39.22513718, -5.85560594],
                            [39.22549654, -5.85560134],
                            [39.22602176, -5.85568889],
                            [39.22651934, -5.85576722],
                            [39.22692478, -5.85583633],
                            [39.22708142, -5.8558133],
                            [39.22735786, -5.85584555],
                            [39.22768036, -5.85591928],
                            [39.22799365, -5.85603447],
                            [39.22832537, -5.85603447],
                            [39.22855574, -5.85602525],
                            [39.22884138, -5.85600682],
                            [39.22921457, -5.85605751],
                            [39.22950482, -5.8560529],
                            [39.22970754, -5.85600683],
                            [39.2300623, -5.85592849],
                            [39.23028805, -5.85575801],
                            [39.23039863, -5.85563821],
                            [39.23054145, -5.85551841],
                            [39.2307027, -5.85543086],
                            [39.2309561, -5.85541704],
                            [39.23140761, -5.85553684],
                            [39.23165179, -5.85559213],
                            [39.23188215, -5.85554145],
                            [39.23221387, -5.85537096],
                            [39.23244423, -5.85525577],
                            [39.23271145, -5.85497009],
                            [39.23292339, -5.85466137],
                            [39.23322285, -5.85441716],
                            [39.23338872, -5.8542605],
                            [39.23377572, -5.85419138],
                            [39.23431937, -5.85419138],
                            [39.23465109, -5.85420981],
                            [39.23476167, -5.85403472],
                            [39.23486303, -5.85357395],
                            [39.23480774, -5.85294729],
                            [39.23485479, -5.85276628],
                            [39.23490183, -5.85258527],
                            [39.23563533, -5.85200889],
                            [39.23675304, -5.8514849],
                            [39.23689276, -5.85099585],
                            [39.23703247, -5.8505068],
                            [39.23628151, -5.8491095],
                            [39.23601955, -5.84697862],
                            [39.23568774, -5.84493508],
                            [39.23584492, -5.84238502],
                            [39.23615927, -5.84154664],
                            [39.23610688, -5.84041134],
                            [39.23521621, -5.83925857],
                            [39.23423821, -5.83887431],
                            [39.23249032, -5.83813981],
                            [39.23307418, -5.83709703],
                            [39.2336814, -5.83638853],
                            [39.23420767, -5.8355282],
                            [39.23444043, -5.83513346],
                            [39.23418794, -5.83427339],
                            [39.23409128, -5.83394418],
                            [39.2339496, -5.83306866],
                            [39.23349418, -5.83181865],
                            [39.23319623, -5.83106517],
                            [39.23313997, -5.8309229],
                            [39.23320575, -5.83070529],
                            [39.23299829, -5.83053828],
                            [39.23293756, -5.83025994],
                            [39.23284648, -5.82983989],
                            [39.23274528, -5.82957673],
                            [39.23244489, -5.8292086],
                            [39.23238601, -5.82913645],
                            [39.23195083, -5.82868604],
                            [39.23182179, -5.82848614],
                            [39.23169276, -5.82828624],
                            [39.23161686, -5.82794717],
                            [39.23132843, -5.82755749],
                            [39.23113614, -5.82715768],
                            [39.23095397, -5.8267427],
                            [39.23060988, -5.8264593],
                            [39.23041253, -5.82616577],
                            [39.23026073, -5.82589249],
                            [39.2300735, -5.82557872],
                            [39.22976483, -5.82524977],
                            [39.22949158, -5.82492082],
                            [39.22935495, -5.8246273],
                            [39.2290564, -5.82421232],
                            [39.22892484, -5.8239694],
                            [39.22901472, -5.82370417],
                            [39.22909611, -5.8235229],
                            [39.2291036, -5.82350621],
                            [39.22912986, -5.82337863],
                            [39.22913188, -5.82336885],
                            [39.22912784, -5.82314665],
                            [39.22916015, -5.82300929],
                            [39.22921671, -5.82289617],
                            [39.2292773, -5.82276689],
                            [39.22935405, -5.82265781],
                            [39.22947127, -5.82253787],
                            [39.22949721, -5.82241119],
                            [39.22950855, -5.82235584],
                            [39.22951734, -5.82218003],
                            [39.22956222, -5.8220187],
                            [39.22952583, -5.82182584],
                            [39.22941546, -5.82166693],
                            [39.22932692, -5.82164025],
                            [39.22922261, -5.82173365],
                            [39.22910952, -5.82176815],
                            [39.22899338, -5.82174942],
                            [39.22888986, -5.8216259],
                            [39.22887005, -5.82149265],
                            [39.22898352, -5.8213088],
                            [39.2290501, -5.82120093],
                            [39.22922295, -5.82093443],
                            [39.22926616, -5.82087501],
                            [39.22932918, -5.82076877],
                            [39.22935799, -5.82064632],
                            [39.2293976, -5.82046986],
                            [39.2294138, -5.820324],
                            [39.2294106, -5.82030308],
                            [39.2293904, -5.82017094],
                            [39.22930397, -5.81995126],
                            [39.22922227, -5.81992749],
                            [39.22910592, -5.81989364],
                            [39.22895468, -5.81992245],
                            [39.22879624, -5.82000528],
                            [39.22845536, -5.82013697],
                            [39.2284546, -5.82013726],
                            [39.22844912, -5.82010734],
                            [39.22842206, -5.82004718],
                            [39.22840101, -5.8200141],
                            [39.22839843, -5.81997343],
                            [39.22845536, -5.8199541],
                            [39.22847143, -5.81994864],
                            [39.22856692, -5.81991623],
                            [39.22881901, -5.81983064],
                            [39.22899042, -5.81974342],
                            [39.22906861, -5.81967124],
                            [39.22908966, -5.81958703],
                            [39.2291077, -5.81950883],
                            [39.22912274, -5.81942763],
                            [39.22911973, -5.81939455],
                            [39.22906259, -5.81933139],
                            [39.22897538, -5.81928026],
                            [39.2289393, -5.81922312],
                            [39.22902651, -5.81922011],
                            [39.22930016, -5.81922913],
                            [39.22931219, -5.81910883],
                            [39.22928813, -5.81903966],
                            [39.2293048, -5.81886488],
                            [39.2292085, -5.81867227],
                            [39.22907368, -5.81861448],
                            [39.22870776, -5.81859522],
                            [39.22845536, -5.81861168],
                            [39.22841244, -5.81861448],
                            [39.22831615, -5.81870437],
                            [39.22831615, -5.8188392],
                            [39.22820701, -5.81883278],
                            [39.22814923, -5.81870437],
                            [39.22821343, -5.81857596],
                            [39.22830331, -5.81847323],
                            [39.22845536, -5.81828515],
                            [39.22854726, -5.81817146],
                            [39.22853441, -5.81806436],
                            [39.22850508, -5.81789176],
                            [39.22851992, -5.81766285],
                            [39.22854534, -5.8174247],
                            [39.22854535, -5.81721986],
                            [39.228912, -5.81736217],
                            [39.22917861, -5.81762881],
                            [39.22943253, -5.81757802],
                            [39.22989997, -5.81721407],
                            [39.23065592, -5.81637846],
                            [39.23065784, -5.81637302],
                            [39.23089464, -5.815702],
                            [39.23061613, -5.81530409],
                            [39.2313323, -5.81538367],
                            [39.2313323, -5.81490618],
                            [39.23165059, -5.81426951],
                            [39.2322474, -5.81371244],
                            [39.23298902, -5.8121286],
                            [39.23336143, -5.81216057],
                            [39.23280442, -5.81355327],
                            [39.23228718, -5.81470722],
                            [39.23173017, -5.8161795],
                            [39.23137208, -5.81769157],
                            [39.23144797, -5.81785286],
                            [39.23200867, -5.81904448],
                            [39.23292377, -5.81964135],
                            [39.23399801, -5.81995968],
                            [39.23503247, -5.81980052],
                            [39.23634544, -5.81856699],
                            [39.23726054, -5.81733346],
                            [39.23781756, -5.81673659],
                            [39.23765841, -5.8157816],
                            [39.23734012, -5.81458785],
                            [39.23745948, -5.81331453],
                            [39.23761863, -5.81224016],
                            [39.23805629, -5.81128517],
                            [39.23861331, -5.81021081],
                            [39.23940904, -5.80909665],
                            [39.24008542, -5.80786312],
                            [39.24080159, -5.80712698],
                            [39.24151775, -5.80639084],
                            [39.24231349, -5.80539606],
                            [39.24187584, -5.80495835],
                            [39.24056287, -5.80487877],
                            [39.24020479, -5.80551543],
                            [39.23932947, -5.80714687],
                            [39.23867074, -5.80710295],
                            [39.23813587, -5.80706729],
                            [39.2368229, -5.80647041],
                            [39.23566908, -5.80523688],
                            [39.23375932, -5.80384418],
                            [39.23073552, -5.80133732],
                            [39.22739342, -5.79883046],
                            [39.22739341, -5.79883043],
                            [39.22731734, -5.79724276],
                            [39.22729955, -5.7968716],
                            [39.22646099, -5.7950545],
                            [39.22625135, -5.79407607],
                            [39.22520315, -5.79197942],
                            [39.22513327, -5.78932367],
                            [39.2250634, -5.78659803],
                            [39.22562244, -5.78534004],
                            [39.22569233, -5.78268429],
                            [39.22513329, -5.77981887],
                            [39.22562246, -5.77422781],
                            [39.22583211, -5.7701743],
                            [39.22528103, -5.76937264],
                            [39.22352606, -5.76681966],
                            [39.22045133, -5.76402413],
                            [39.21975253, -5.76388435],
                            [39.2187742, -5.76633043],
                            [39.21744646, -5.7712925],
                            [39.21661702, -5.77325321],
                            [39.2151404, -5.77674377],
                            [39.21503707, -5.77757052],
                            [39.214791, -5.7795393],
                            [39.21565459, -5.78463058],
                            [39.21611871, -5.78736678],
                            [39.21479097, -5.79561359],
                            [39.21482395, -5.79584034],
                            [39.21488943, -5.79629056],
                            [39.21535001, -5.79945744],
                            [39.21493072, -5.80316152],
                            [39.21507284, -5.80614246],
                            [39.21491169, -5.8085398],
                            [39.21460953, -5.81275025],
                            [39.21469475, -5.81571668],
                            [39.21469298, -5.81700903],
                            [39.21465272, -5.81781015],
                            [39.21466545, -5.81829392],
                            [39.21463999, -5.8188668],
                            [39.21451269, -5.81947788],
                            [39.21446964, -5.81994766],
                            [39.21440134, -5.82030872],
                            [39.2144111, -5.82065027],
                            [39.21452819, -5.82080641],
                            [39.21446964, -5.8212553],
                            [39.21430376, -5.82187008],
                            [39.21392642, -5.82259285],
                            [39.21376449, -5.8233216],
                            [39.21356208, -5.8247791],
                            [39.21342474, -5.82587796],
                            [39.21335967, -5.82639854],
                            [39.21328017, -5.82688399],
                            [39.2129751, -5.82874674],
                            [39.21267148, -5.82903014],
                            [39.21279293, -5.82927306],
                            [39.21305606, -5.82949573],
                            [39.2130763, -5.83030545],
                            [39.21271196, -5.83115566],
                            [39.2127322, -5.83190465],
                            [39.21279292, -5.83275486],
                            [39.21305605, -5.83433382],
                            [39.21299533, -5.83554841],
                            [39.21279292, -5.83662129],
                            [39.21260556, -5.83733616],
                            [39.21260579, -5.83733616],
                            [39.2125934, -5.83745854],
                            [39.21246008, -5.83877518],
                            [39.21176797, -5.83995918],
                            [39.21160405, -5.84056029],
                            [39.21145834, -5.84170786],
                            [39.21103943, -5.84214503],
                            [39.21069337, -5.84250934],
                            [39.21063873, -5.84318331],
                            [39.21069337, -5.84349297],
                            [39.21082284, -5.84367181],
                            [39.21089372, -5.84447661],
                            [39.21105764, -5.84525987],
                            [39.21147655, -5.84620707],
                            [39.211699, -5.84701357],
                            [39.21176796, -5.84726357],
                            [39.2122415, -5.84813791],
                            [39.21225654, -5.84899496],
                            [39.21225972, -5.84917619],
                            [39.21266041, -5.84986838],
                            [39.21322502, -5.85063343],
                            [39.21366214, -5.85150777],
                            [39.21422676, -5.85258248],
                            [39.21435166, -5.85283591],
                            [39.21435993, -5.85295172],
                            [39.21439302, -5.85309786],
                            [39.21446746, -5.85331017],
                            [39.21452829, -5.85350341],
                            [39.21452806, -5.85350341],
                            [39.21453462, -5.85356501],
                            [39.21456234, -5.85382546],
                            [39.21477553, -5.85434052],
                            [39.21493441, -5.85487793],
                            [39.21497051, -5.85530559],
                            [39.21505658, -5.85556385],
                            [39.21511447, -5.85571581],
                            [39.21517235, -5.85586778],
                            [39.21527872, -5.85625809],
                            [39.21536201, -5.85645248],
                            [39.21544809, -5.85666631],
                            [39.21555614, -5.85687741],
                            [39.21558392, -5.85693169],
                            [39.21565356, -5.85715783],
                            [39.21573409, -5.85731056],
                            [39.21582849, -5.85734389],
                            [39.21590624, -5.85733556],
                            [39.21596455, -5.85726058],
                            [39.21603119, -5.85717449],
                            [39.21606451, -5.85707174],
                            [39.21612282, -5.85697177],
                            [39.21625054, -5.85689124],
                            [39.21657541, -5.85672185],
                            [39.21665594, -5.85653301],
                            [39.21684475, -5.85615534],
                            [39.21690154, -5.85607863],
                            [39.21705855, -5.85586654],
                            [39.21720294, -5.85560828],
                            [39.2173154, -5.85537224],
                            [39.21742646, -5.85513342],
                            [39.21754725, -5.85502928],
                            [39.21760348, -5.85502165],
                        ],
                        [
                            [39.28291358, -5.76959733],
                            [39.28278344, -5.76960181],
                            [39.28265331, -5.7696063],
                            [39.28243792, -5.76976785],
                            [39.28222252, -5.77009094],
                            [39.28210585, -5.77050377],
                            [39.28198918, -5.7710243],
                            [39.28187251, -5.77164356],
                            [39.28189046, -5.77196665],
                            [39.28181866, -5.77234358],
                            [39.28189712, -5.77279076],
                            [39.28190841, -5.77285514],
                            [39.28195328, -5.77344747],
                            [39.28200713, -5.77386031],
                            [39.28220457, -5.77444366],
                            [39.28247382, -5.77491932],
                            [39.28276101, -5.77508984],
                            [39.2830123, -5.77498214],
                            [39.28346103, -5.7747488],
                            [39.28383797, -5.77456931],
                            [39.28414311, -5.77438981],
                            [39.2843226, -5.77409365],
                            [39.28455595, -5.77403083],
                            [39.28480724, -5.77391416],
                            [39.2850675, -5.77372569],
                            [39.28541752, -5.77342055],
                            [39.28557906, -5.77297181],
                            [39.28529187, -5.77263975],
                            [39.28529412, -5.77241567],
                            [39.28548931, -5.7719487],
                            [39.2857765, -5.77135637],
                            [39.28583035, -5.77098841],
                            [39.28596497, -5.7703153],
                            [39.28597395, -5.77011786],
                            [39.28593805, -5.76972297],
                            [39.28592907, -5.76939988],
                            [39.28594702, -5.76922936],
                            [39.28572965, -5.76917266],
                            [39.28558804, -5.76901397],
                            [39.28532777, -5.76906782],
                            [39.28515868, -5.76914834],
                            [39.28495083, -5.76924731],
                            [39.28458287, -5.76944476],
                            [39.28420593, -5.76958835],
                            [39.28386489, -5.76958835],
                            [39.28362258, -5.76955245],
                            [39.28345206, -5.76948066],
                            [39.28321872, -5.76956143],
                            [39.28291358, -5.76959733],
                        ],
                        [
                            [39.2214846, -5.7566928],
                            [39.22126461, -5.75612884],
                            [39.22126327, -5.7561254],
                            [39.22119499, -5.75595037],
                            [39.22101695, -5.75584004],
                            [39.22078313, -5.75546867],
                            [39.22056306, -5.75517983],
                            [39.22035674, -5.75508355],
                            [39.22012292, -5.7547672],
                            [39.21980657, -5.75427204],
                            [39.21954524, -5.75392819],
                            [39.21942145, -5.75358433],
                            [39.21947647, -5.75336426],
                            [39.21956264, -5.75326851],
                            [39.21970968, -5.75310513],
                            [39.21994411, -5.75313043],
                            [39.22005638, -5.75314647],
                            [39.22013667, -5.75315794],
                            [39.22013667, -5.75295163],
                            [39.21997162, -5.75281409],
                            [39.21980037, -5.75286483],
                            [39.21960025, -5.75292412],
                            [39.21941457, -5.75306854],
                            [39.21922889, -5.75321296],
                            [39.21899506, -5.75373563],
                            [39.21883001, -5.75412075],
                            [39.2185687, -5.75441407],
                            [39.21840363, -5.75451962],
                            [39.21827984, -5.7550973],
                            [39.21834861, -5.75544116],
                            [39.21812854, -5.75600509],
                            [39.21797061, -5.75649469],
                            [39.21785345, -5.75685786],
                            [39.21771591, -5.75742178],
                            [39.21768575, -5.75768118],
                            [39.21764714, -5.75801322],
                            [39.21753711, -5.75845336],
                            [39.21723451, -5.75892101],
                            [39.21719325, -5.7592236],
                            [39.21725557, -5.759556],
                            [39.21727577, -5.75966374],
                            [39.21712448, -5.75984255],
                            [39.21739956, -5.76046149],
                            [39.21774342, -5.76135552],
                            [39.2178397, -5.76165812],
                            [39.21793598, -5.76196071],
                            [39.21811479, -5.76200197],
                            [39.21825233, -5.76249713],
                            [39.21837612, -5.76260716],
                            [39.21855493, -5.76259341],
                            [39.21866496, -5.76257966],
                            [39.21875838, -5.76188148],
                            [39.21882818, -5.76135983],
                            [39.21894601, -5.76121325],
                            [39.21906384, -5.76106668],
                            [39.21965527, -5.76069531],
                            [39.21968561, -5.75993949],
                            [39.21970936, -5.7593479],
                            [39.21978547, -5.75914133],
                            [39.21986159, -5.75893476],
                            [39.2202192, -5.75871469],
                            [39.22060432, -5.75856339],
                            [39.22093442, -5.75848087],
                            [39.22130579, -5.75849462],
                            [39.22153961, -5.7584396],
                            [39.22155337, -5.75809575],
                            [39.22141583, -5.75727049],
                            [39.2214846, -5.7566928],
                        ],
                        [
                            [39.38454736, -5.81880374],
                            [39.38448419, -5.81846362],
                            [39.38442102, -5.81812349],
                            [39.38439187, -5.81747726],
                            [39.38439747, -5.81696176],
                            [39.38438753, -5.81683958],
                            [39.38436272, -5.81653463],
                            [39.38433114, -5.8164083],
                            [39.38429955, -5.81628197],
                            [39.38422181, -5.81622852],
                            [39.38368733, -5.81639372],
                            [39.38349057, -5.81652364],
                            [39.38292934, -5.81689419],
                            [39.38243859, -5.81727319],
                            [39.38177292, -5.81789998],
                            [39.3815067, -5.8181171],
                            [39.38127246, -5.81830813],
                            [39.38082058, -5.81868227],
                            [39.38061164, -5.81920217],
                            [39.38070882, -5.81939167],
                            [39.38128806, -5.81973921],
                            [39.38136477, -5.81978524],
                            [39.38218593, -5.81999903],
                            [39.3830168, -5.82002818],
                            [39.38367275, -5.81998445],
                            [39.38440645, -5.81987756],
                            [39.38468341, -5.81971721],
                            [39.38468826, -5.81930907],
                            [39.38454736, -5.81880374],
                        ],
                        [
                            [39.24933268, -6.64415334],
                            [39.24721547, -6.64397832],
                            [39.24494577, -6.64530882],
                            [39.24475502, -6.64748246],
                            [39.24581555, -6.65028079],
                            [39.24715828, -6.65183062],
                            [39.24998878, -6.65270089],
                            [39.25324641, -6.65163892],
                            [39.25316248, -6.64874903],
                            [39.252205, -6.64695642],
                            [39.25099959, -6.64533121],
                            [39.24933268, -6.64415334],
                        ],
                        [
                            [39.26767326, -6.69147048],
                            [39.26656697, -6.68974275],
                            [39.26495725, -6.68855295],
                            [39.26300798, -6.68749279],
                            [39.26013935, -6.68653332],
                            [39.25807563, -6.68834073],
                            [39.25896061, -6.69101173],
                            [39.26095951, -6.69306468],
                            [39.26322921, -6.69505381],
                            [39.26482749, -6.69635899],
                            [39.26645633, -6.69765755],
                            [39.26918385, -6.69621355],
                            [39.26865361, -6.69428218],
                            [39.26767326, -6.69147048],
                        ],
                        [
                            [39.3834608, -7.4025276],
                            [39.38236592, -7.40301433],
                            [39.38139269, -7.40356188],
                            [39.38060194, -7.40410944],
                            [39.37956789, -7.40459616],
                            [39.37829053, -7.40453532],
                            [39.37737813, -7.40471783],
                            [39.37658738, -7.40496119],
                            [39.37567498, -7.40508287],
                            [39.37531002, -7.40532623],
                            [39.37543167, -7.40599547],
                            [39.37531002, -7.406178],
                            [39.37488423, -7.40629967],
                            [39.37415431, -7.40623883],
                            [39.37360687, -7.40605631],
                            [39.37293778, -7.40629967],
                            [39.37190373, -7.40599546],
                            [39.3718429, -7.40593462],
                            [39.37190373, -7.40642134],
                            [39.37257282, -7.4066647],
                            [39.37269447, -7.40727311],
                            [39.37336356, -7.40763815],
                            [39.3741543, -7.40794236],
                            [39.37470174, -7.40818572],
                            [39.37561414, -7.40824657],
                            [39.37652654, -7.40836825],
                            [39.37743894, -7.40836825],
                            [39.37816886, -7.40855078],
                            [39.37847299, -7.40891582],
                            [39.37786472, -7.40909834],
                            [39.37731728, -7.4093417],
                            [39.37786472, -7.40976758],
                            [39.37877712, -7.41025431],
                            [39.37956786, -7.41074104],
                            [39.38060191, -7.41104524],
                            [39.38157514, -7.41116692],
                            [39.38160994, -7.41116983],
                            [39.38230506, -7.41122777],
                            [39.38315663, -7.41122777],
                            [39.38346076, -7.41165366],
                            [39.38352159, -7.41183617],
                            [39.38431233, -7.4120187],
                            [39.38485977, -7.41250543],
                            [39.38558969, -7.41262711],
                            [39.38625879, -7.41274879],
                            [39.38747532, -7.41268796],
                            [39.3879011, -7.41250544],
                            [39.38850937, -7.41189704],
                            [39.38808359, -7.41092359],
                            [39.38820524, -7.41019351],
                            [39.38838773, -7.4095851],
                            [39.38911765, -7.40903754],
                            [39.39057749, -7.40861167],
                            [39.39167237, -7.40879419],
                            [39.39307138, -7.40885504],
                            [39.39392295, -7.40903756],
                            [39.39422708, -7.41001101],
                            [39.39544361, -7.41019354],
                            [39.39641684, -7.41019354],
                            [39.39732924, -7.41049774],
                            [39.39848494, -7.41092363],
                            [39.39915403, -7.41122784],
                            [39.39957981, -7.41183624],
                            [39.39994477, -7.41244465],
                            [39.39915402, -7.41317473],
                            [39.39891071, -7.41469574],
                            [39.39891071, -7.41542234],
                            [39.39891071, -7.41591254],
                            [39.39994475, -7.41694683],
                            [39.40049219, -7.41773776],
                            [39.40094242, -7.41787286],
                            [39.40170872, -7.41810281],
                            [39.40249947, -7.41761609],
                            [39.40286443, -7.41712936],
                            [39.40359435, -7.41706853],
                            [39.40456758, -7.41652097],
                            [39.40468924, -7.41573004],
                            [39.40468924, -7.41499996],
                            [39.40511503, -7.41451324],
                            [39.40584495, -7.41463492],
                            [39.40669652, -7.41469577],
                            [39.40700066, -7.41378316],
                            [39.40748727, -7.41329644],
                            [39.40803472, -7.41280972],
                            [39.40919042, -7.41287056],
                            [39.40973786, -7.4120188],
                            [39.41010282, -7.41256637],
                            [39.41034612, -7.41378317],
                            [39.41034612, -7.41445242],
                            [39.4107719, -7.41512166],
                            [39.41113686, -7.41573007],
                            [39.41150182, -7.41633847],
                            [39.41174513, -7.41676436],
                            [39.41241422, -7.41688604],
                            [39.41247504, -7.4174336],
                            [39.41265751, -7.41779865],
                            [39.41320496, -7.41773781],
                            [39.41369157, -7.41761613],
                            [39.41399571, -7.41725109],
                            [39.41423901, -7.41688605],
                            [39.41484728, -7.41652101],
                            [39.41545555, -7.41603429],
                            [39.41551638, -7.41542589],
                            [39.41515142, -7.41457412],
                            [39.41502977, -7.41414824],
                            [39.4144215, -7.41384403],
                            [39.41375241, -7.41396571],
                            [39.41411738, -7.41329647],
                            [39.41414129, -7.41320078],
                            [39.41423903, -7.41280975],
                            [39.41411738, -7.4118363],
                            [39.41369159, -7.41104537],
                            [39.41417821, -7.41031529],
                            [39.41442152, -7.40946353],
                            [39.41454318, -7.4086726],
                            [39.41363078, -7.40855092],
                            [39.41375244, -7.40782084],
                            [39.41302252, -7.40745579],
                            [39.4122926, -7.40775999],
                            [39.4116235, -7.40830755],
                            [39.41146859, -7.40829464],
                            [39.41089358, -7.40824671],
                            [39.41052862, -7.4076383],
                            [39.40961623, -7.40745578],
                            [39.40998119, -7.40660401],
                            [39.4102245, -7.40593477],
                            [39.40985954, -7.40532636],
                            [39.41010285, -7.40465712],
                            [39.41016368, -7.40392704],
                            [39.40919045, -7.40398788],
                            [39.40846054, -7.40398787],
                            [39.40827806, -7.40368367],
                            [39.40833888, -7.40301443],
                            [39.40852137, -7.40246687],
                            [39.40870385, -7.40185846],
                            [39.40890373, -7.40161855],
                            [39.40931212, -7.40112838],
                            [39.40985956, -7.40125006],
                            [39.41022451, -7.40191931],
                            [39.41071113, -7.40240603],
                            [39.41101526, -7.40191931],
                            [39.41071113, -7.40082418],
                            [39.41077196, -7.39997242],
                            [39.41065031, -7.39918149],
                            [39.41040701, -7.39826889],
                            [39.40998122, -7.39772133],
                            [39.40937296, -7.3975388],
                            [39.40882552, -7.3975388],
                            [39.4087647, -7.39705208],
                            [39.40943379, -7.39650451],
                            [39.40943379, -7.39595695],
                            [39.40852139, -7.39589611],
                            [39.4078523, -7.39601779],
                            [39.40712238, -7.39626114],
                            [39.40730486, -7.3958961],
                            [39.40773065, -7.3952877],
                            [39.40736569, -7.3949835],
                            [39.40712239, -7.39486181],
                            [39.40639247, -7.39504433],
                            [39.40554089, -7.39504433],
                            [39.40596668, -7.39443593],
                            [39.40608834, -7.39376668],
                            [39.4064533, -7.39321913],
                            [39.40730487, -7.39279324],
                            [39.40766984, -7.392124],
                            [39.40718322, -7.39194148],
                            [39.40651413, -7.39206316],
                            [39.40560173, -7.39206315],
                            [39.40487181, -7.39236735],
                            [39.40444602, -7.39285407],
                            [39.40395941, -7.39327995],
                            [39.40341196, -7.39376667],
                            [39.40316866, -7.39352331],
                            [39.40268205, -7.39340163],
                            [39.40213461, -7.39334078],
                            [39.40176965, -7.39321911],
                            [39.40128303, -7.39321911],
                            [39.4009789, -7.39279322],
                            [39.40110056, -7.39206314],
                            [39.40067477, -7.39194145],
                            [39.39988403, -7.39230649],
                            [39.39909328, -7.39236733],
                            [39.3982417, -7.39248901],
                            [39.39751179, -7.39303657],
                            [39.39672104, -7.39370581],
                            [39.3964169, -7.39437505],
                            [39.39605194, -7.39504429],
                            [39.39538284, -7.39553101],
                            [39.39471375, -7.39601773],
                            [39.39410548, -7.39674781],
                            [39.39313225, -7.39747789],
                            [39.39240233, -7.39778209],
                            [39.39112497, -7.39820797],
                            [39.39027339, -7.39863385],
                            [39.38954347, -7.39905973],
                            [39.38881355, -7.39954645],
                            [39.38832694, -7.40027653],
                            [39.38784032, -7.40124997],
                            [39.38704958, -7.40124997],
                            [39.38631965, -7.40143249],
                            [39.38510312, -7.40204089],
                            [39.38431237, -7.40246677],
                            [39.3834608, -7.4025276],
                        ],
                        [
                            [39.3710439, -7.53247386],
                            [39.3701932, -7.53313566],
                            [39.36924798, -7.53341928],
                            [39.36839728, -7.53436471],
                            [39.36735753, -7.53549922],
                            [39.36693429, -7.53710646],
                            [39.36697944, -7.53701192],
                            [39.36839726, -7.53909187],
                            [39.36981508, -7.54013186],
                            [39.37113839, -7.54060458],
                            [39.3724617, -7.54022641],
                            [39.37340692, -7.5397537],
                            [39.37435214, -7.53965916],
                            [39.3754864, -7.53984825],
                            [39.37718779, -7.54041552],
                            [39.3793618, -7.54107733],
                            [39.38096867, -7.54183368],
                            [39.38238649, -7.54296821],
                            [39.38333171, -7.54391364],
                            [39.38493858, -7.54429183],
                            [39.3858838, -7.54363003],
                            [39.38588381, -7.54249551],
                            [39.38578929, -7.54126644],
                            [39.38607286, -7.54013192],
                            [39.38682904, -7.53965921],
                            [39.38777426, -7.54003739],
                            [39.38853043, -7.54098282],
                            [39.38947564, -7.5420228],
                            [39.39013729, -7.54249552],
                            [39.39108251, -7.54363005],
                            [39.39212225, -7.54419731],
                            [39.39325651, -7.5450482],
                            [39.39457981, -7.54523729],
                            [39.39656478, -7.54495367],
                            [39.39818129, -7.54396559],
                            [39.39826617, -7.5439137],
                            [39.39864427, -7.54344099],
                            [39.39949497, -7.5425901],
                            [39.40044019, -7.54173922],
                            [39.40119637, -7.54107741],
                            [39.40204707, -7.5406047],
                            [39.40299229, -7.54060471],
                            [39.40412655, -7.54051017],
                            [39.40488273, -7.53994291],
                            [39.40507178, -7.53909202],
                            [39.40441013, -7.53833568],
                            [39.40337039, -7.53757932],
                            [39.40270873, -7.53653935],
                            [39.40242518, -7.53559391],
                            [39.40261422, -7.5344594],
                            [39.40346492, -7.53379759],
                            [39.40441015, -7.53323034],
                            [39.40544989, -7.53266309],
                            [39.40582798, -7.53190674],
                            [39.40563894, -7.53124494],
                            [39.4054499, -7.53020496],
                            [39.4049773, -7.52935407],
                            [39.40450469, -7.52840863],
                            [39.4032759, -7.52784137],
                            [39.40242521, -7.52765228],
                            [39.40138547, -7.5272741],
                            [39.40072381, -7.52717955],
                            [39.39996764, -7.52699047],
                            [39.39940051, -7.52661229],
                            [39.39911694, -7.52604503],
                            [39.39940052, -7.52528869],
                            [39.39940052, -7.52443779],
                            [39.39949504, -7.52339782],
                            [39.39873887, -7.52301964],
                            [39.39788818, -7.52301964],
                            [39.39713199, -7.52330326],
                            [39.3962813, -7.52358689],
                            [39.39609226, -7.52292509],
                            [39.39590321, -7.52216874],
                            [39.3958087, -7.52122331],
                            [39.39505252, -7.52103422],
                            [39.39467443, -7.52169602],
                            [39.39401278, -7.52273599],
                            [39.39325659, -7.52387051],
                            [39.39240589, -7.52491048],
                            [39.39146067, -7.52585591],
                            [39.39023188, -7.52689588],
                            [39.38834143, -7.52821948],
                            [39.38635647, -7.52935399],
                            [39.38619795, -7.52941413],
                            [39.38361533, -7.53039395],
                            [39.38125228, -7.53143392],
                            [39.37917279, -7.53237935],
                            [39.37718782, -7.53247388],
                            [39.37520287, -7.53228479],
                            [39.37359599, -7.53237933],
                            [39.37236721, -7.53237932],
                            [39.3710439, -7.53247386],
                        ],
                        [
                            [39.88287751, -7.62970107],
                            [39.88147066, -7.63121663],
                            [39.87995561, -7.63240742],
                            [39.87908985, -7.63392298],
                            [39.87692548, -7.63576329],
                            [39.87616795, -7.63706234],
                            [39.87422003, -7.63652105],
                            [39.87216388, -7.63760359],
                            [39.86999951, -7.63901088],
                            [39.86891733, -7.63998517],
                            [39.8675105, -7.64020167],
                            [39.86567078, -7.64139245],
                            [39.86372285, -7.64247499],
                            [39.86177492, -7.64399054],
                            [39.86058452, -7.64518134],
                            [39.85917767, -7.64723816],
                            [39.85809548, -7.64940325],
                            [39.85636398, -7.6521096],
                            [39.85495713, -7.65449119],
                            [39.85460453, -7.65489816],
                            [39.85355029, -7.656115],
                            [39.85254239, -7.65777136],
                            [39.85203522, -7.65860485],
                            [39.85030372, -7.66098643],
                            [39.84943796, -7.66347628],
                            [39.84835577, -7.66434231],
                            [39.84792288, -7.66726518],
                            [39.84781465, -7.66910551],
                            [39.84705712, -7.67116234],
                            [39.84651601, -7.67321917],
                            [39.84619135, -7.6750595],
                            [39.84608311, -7.67754935],
                            [39.84500092, -7.67982269],
                            [39.84424338, -7.68242079],
                            [39.84391871, -7.68447763],
                            [39.8432694, -7.6862097],
                            [39.84229542, -7.68826653],
                            [39.83969817, -7.69064811],
                            [39.83887411, -7.69103279],
                            [39.8380749, -7.69140588],
                            [39.83547767, -7.69205539],
                            [39.83298865, -7.69248839],
                            [39.83125716, -7.69335442],
                            [39.82909279, -7.69454521],
                            [39.82606268, -7.69638551],
                            [39.82346544, -7.69800931],
                            [39.82195038, -7.69984963],
                            [39.82032709, -7.70255598],
                            [39.82009323, -7.70401804],
                            [39.8198942, -7.70526235],
                            [39.81913666, -7.70786045],
                            [39.81870378, -7.71002554],
                            [39.81686406, -7.7129484],
                            [39.81578186, -7.71511348],
                            [39.81551534, -7.71532396],
                            [39.81372571, -7.71673729],
                            [39.81102025, -7.71933538],
                            [39.80842301, -7.72128394],
                            [39.80571755, -7.72290775],
                            [39.80290387, -7.72496456],
                            [39.80084772, -7.72637186],
                            [39.80077955, -7.72661735],
                            [39.79998197, -7.72723789],
                            [39.79792582, -7.7290782],
                            [39.79565323, -7.73124328],
                            [39.79283956, -7.73275883],
                            [39.79002588, -7.73384135],
                            [39.78677934, -7.7353569],
                            [39.78607632, -7.73666291],
                            [39.7860218, -7.7367642],
                            [39.78493961, -7.73827976],
                            [39.78353277, -7.73957881],
                            [39.78147662, -7.74109435],
                            [39.78006979, -7.74087783],
                            [39.77866296, -7.7402283],
                            [39.77790545, -7.73849622],
                            [39.77714794, -7.73600636],
                            [39.77563289, -7.73535683],
                            [39.77314388, -7.73546506],
                            [39.77076308, -7.73600632],
                            [39.76708367, -7.73578979],
                            [39.76405357, -7.73557326],
                            [39.76091525, -7.73503197],
                            [39.75831803, -7.73459894],
                            [39.7550715, -7.73405764],
                            [39.75225783, -7.73329984],
                            [39.74987704, -7.73297507],
                            [39.7478209, -7.73329982],
                            [39.74587298, -7.73340806],
                            [39.74403327, -7.73308328],
                            [39.74230179, -7.73243375],
                            [39.74078674, -7.73319152],
                            [39.73948811, -7.73459882],
                            [39.73851414, -7.73643914],
                            [39.73786483, -7.73643914],
                            [39.7369991, -7.73546484],
                            [39.73548405, -7.7348153],
                            [39.733969, -7.73449053],
                            [39.73223751, -7.73503179],
                            [39.73093889, -7.73654735],
                            [39.73072245, -7.73762989],
                            [39.7316964, -7.73892895],
                            [39.73310322, -7.7400115],
                            [39.73472649, -7.74033628],
                            [39.73667441, -7.74001152],
                            [39.73818947, -7.73925375],
                            [39.73948809, -7.73827947],
                            [39.74089493, -7.73730519],
                            [39.74186889, -7.73773821],
                            [39.7431675, -7.73784648],
                            [39.74479077, -7.73849601],
                            [39.74673869, -7.73860428],
                            [39.74836195, -7.73925382],
                            [39.74976878, -7.74066114],
                            [39.7505263, -7.74163543],
                            [39.7506345, -7.74304274],
                            [39.74955232, -7.74434179],
                            [39.74857835, -7.74574909],
                            [39.74706329, -7.74683163],
                            [39.74544002, -7.74769765],
                            [39.74424961, -7.74867193],
                            [39.74273455, -7.74997098],
                            [39.74208524, -7.75094526],
                            [39.74176058, -7.75235257],
                            [39.74240988, -7.75365163],
                            [39.74327562, -7.75430116],
                            [39.74424959, -7.7525691],
                            [39.74533178, -7.75105354],
                            [39.74717149, -7.75007926],
                            [39.74814545, -7.75018753],
                            [39.74966049, -7.75105357],
                            [39.75009336, -7.75224437],
                            [39.75095908, -7.75451772],
                            [39.75182482, -7.75603329],
                            [39.75301522, -7.75689934],
                            [39.75388095, -7.75689934],
                            [39.75453027, -7.75635807],
                            [39.75485492, -7.75527552],
                            [39.75507138, -7.75311044],
                            [39.75528782, -7.75170313],
                            [39.75582892, -7.74997106],
                            [39.75669467, -7.74845551],
                            [39.75734399, -7.74737297],
                            [39.75918369, -7.74780599],
                            [39.76026586, -7.74921331],
                            [39.76037407, -7.75062062],
                            [39.76080693, -7.75278571],
                            [39.76123979, -7.75462604],
                            [39.76188908, -7.75646637],
                            [39.76286303, -7.75852321],
                            [39.7634041, -7.76090482],
                            [39.76362053, -7.7628534],
                            [39.76372874, -7.76415245],
                            [39.76264655, -7.76480197],
                            [39.76178082, -7.76382768],
                            [39.76113152, -7.76350291],
                            [39.76004934, -7.76285338],
                            [39.75972468, -7.76393592],
                            [39.75961645, -7.76523497],
                            [39.7587507, -7.76642577],
                            [39.75831784, -7.76566798],
                            [39.75734388, -7.76469369],
                            [39.75701923, -7.76393591],
                            [39.75647815, -7.76220383],
                            [39.75572063, -7.76133779],
                            [39.75409737, -7.76068825],
                            [39.75323162, -7.76166253],
                            [39.75420557, -7.76382763],
                            [39.75409734, -7.76534319],
                            [39.75377267, -7.76696701],
                            [39.75301514, -7.76837431],
                            [39.7516083, -7.76988987],
                            [39.75150007, -7.7707559],
                            [39.75247403, -7.77075591],
                            [39.75431374, -7.76999814],
                            [39.75550414, -7.7701064],
                            [39.75701919, -7.77032292],
                            [39.75820959, -7.77010642],
                            [39.75972465, -7.76913214],
                            [39.76091505, -7.7680496],
                            [39.76221366, -7.76848262],
                            [39.76232187, -7.76978168],
                            [39.76232186, -7.77097248],
                            [39.76383692, -7.77053947],
                            [39.76632593, -7.7697817],
                            [39.76859851, -7.7693487],
                            [39.77033001, -7.7679414],
                            [39.7720615, -7.76675062],
                            [39.77390121, -7.76534332],
                            [39.77465875, -7.7637195],
                            [39.77736421, -7.76263698],
                            [39.77963679, -7.76090492],
                            [39.78115184, -7.76101318],
                            [39.78180114, -7.76187923],
                            [39.78169292, -7.76339478],
                            [39.78093538, -7.76480209],
                            [39.77996141, -7.76631765],
                            [39.78039427, -7.76783321],
                            [39.77996139, -7.76902401],
                            [39.778771, -7.76945702],
                            [39.77866277, -7.77043131],
                            [39.77898742, -7.77097258],
                            [39.7800696, -7.7711891],
                            [39.78141531, -7.77098201],
                            [39.78147643, -7.7709726],
                            [39.78320792, -7.77032308],
                            [39.78439832, -7.76989007],
                            [39.78580515, -7.77043135],
                            [39.78656267, -7.7712974],
                            [39.78710376, -7.77118914],
                            [39.78796951, -7.77053962],
                            [39.78894348, -7.76945708],
                            [39.7893294, -7.76907103],
                            [39.7902421, -7.76815804],
                            [39.79121607, -7.76675074],
                            [39.79132429, -7.76610121],
                            [39.79164896, -7.76447739],
                            [39.79305579, -7.76339486],
                            [39.79435441, -7.76393614],
                            [39.79457084, -7.76555996],
                            [39.79359687, -7.76696726],
                            [39.79351298, -7.76822576],
                            [39.79348863, -7.76859108],
                            [39.79478725, -7.76913236],
                            [39.79554478, -7.76837458],
                            [39.79602038, -7.76656674],
                            [39.79608588, -7.76631775],
                            [39.79630233, -7.76469393],
                            [39.79788395, -7.76390287],
                            [39.79803382, -7.76382791],
                            [39.7979295, -7.76356121],
                            [39.79705987, -7.76133805],
                            [39.79727631, -7.7602555],
                            [39.79834506, -7.76044417],
                            [39.79911602, -7.76058028],
                            [39.8001982, -7.76003901],
                            [39.80128039, -7.75949774],
                            [39.80290365, -7.75917299],
                            [39.80409405, -7.759173],
                            [39.80431049, -7.75819871],
                            [39.80301189, -7.75603361],
                            [39.80333656, -7.75419328],
                            [39.80323467, -7.75246038],
                            [39.80322835, -7.75235296],
                            [39.80290371, -7.75040437],
                            [39.80236264, -7.74802277],
                            [39.80073937, -7.74661545],
                            [39.79944077, -7.74466686],
                            [39.79868326, -7.7431513],
                            [39.79716821, -7.74282652],
                            [39.79511207, -7.74217698],
                            [39.79565317, -7.7412027],
                            [39.79649216, -7.74087632],
                            [39.79760109, -7.74044492],
                            [39.79868327, -7.74022842],
                            [39.80052298, -7.74022844],
                            [39.80268734, -7.74055321],
                            [39.80344485, -7.74163576],
                            [39.80441882, -7.74152751],
                            [39.805501, -7.74087799],
                            [39.80723248, -7.74174404],
                            [39.80777357, -7.7418523],
                            [39.80842288, -7.74141928],
                            [39.8090722, -7.740445],
                            [39.81037081, -7.73957897],
                            [39.81112834, -7.73947072],
                            [39.81210229, -7.74033676],
                            [39.81242694, -7.74109455],
                            [39.81242693, -7.74250185],
                            [39.81199406, -7.74434218],
                            [39.81047899, -7.74564122],
                            [39.80939681, -7.74574947],
                            [39.80853106, -7.74726503],
                            [39.80809818, -7.74845583],
                            [39.80798996, -7.74964662],
                            [39.80874748, -7.74986314],
                            [39.80982966, -7.75018791],
                            [39.81080362, -7.7499714],
                            [39.81147124, -7.75063926],
                            [39.81166936, -7.75083745],
                            [39.81112826, -7.75224476],
                            [39.8096132, -7.75311078],
                            [39.80853102, -7.75419332],
                            [39.80853101, -7.75581714],
                            [39.80907209, -7.75754921],
                            [39.81069534, -7.75917304],
                            [39.81177752, -7.75960607],
                            [39.81307614, -7.75938957],
                            [39.81373486, -7.75945547],
                            [39.81524049, -7.75960609],
                            [39.81697198, -7.75938959],
                            [39.81772951, -7.75928134],
                            [39.81870347, -7.75971437],
                            [39.81870346, -7.76068865],
                            [39.81772949, -7.76187945],
                            [39.81632264, -7.76404453],
                            [39.81588977, -7.76523532],
                            [39.81686372, -7.76631788],
                            [39.8179459, -7.76664265],
                            [39.81881164, -7.76610138],
                            [39.81978561, -7.76523535],
                            [39.82032671, -7.76404455],
                            [39.82130067, -7.76393631],
                            [39.82238284, -7.7646941],
                            [39.82281572, -7.76426108],
                            [39.82378968, -7.76339505],
                            [39.82465543, -7.76361156],
                            [39.82530474, -7.7630703],
                            [39.8262787, -7.76220427],
                            [39.82801019, -7.76177126],
                            [39.82796989, -7.76192243],
                            [39.82757731, -7.76339507],
                            [39.82801017, -7.76556017],
                            [39.82822659, -7.76740049],
                            [39.82692797, -7.76805001],
                            [39.82584579, -7.76815826],
                            [39.82562935, -7.76989033],
                            [39.82671152, -7.77086463],
                            [39.82855121, -7.77248846],
                            [39.82995804, -7.77465356],
                            [39.82833058, -7.77597629],
                            [39.82822654, -7.77606085],
                            [39.82790188, -7.77735991],
                            [39.82725256, -7.77822594],
                            [39.82617038, -7.7785507],
                            [39.82530464, -7.77768466],
                            [39.82584574, -7.77606084],
                            [39.82443892, -7.77497829],
                            [39.82270743, -7.77616908],
                            [39.8214088, -7.77735987],
                            [39.81967731, -7.7784424],
                            [39.81870334, -7.77898367],
                            [39.81686365, -7.77735984],
                            [39.81588969, -7.77790111],
                            [39.81480751, -7.7779011],
                            [39.81358834, -7.7775962],
                            [39.81307602, -7.77746807],
                            [39.81177741, -7.77671028],
                            [39.81091166, -7.77768456],
                            [39.80950483, -7.7775763],
                            [39.80907197, -7.77616899],
                            [39.80928841, -7.77443692],
                            [39.80863911, -7.77476168],
                            [39.80755692, -7.77595247],
                            [39.80604186, -7.77714326],
                            [39.80589948, -7.77714326],
                            [39.80431037, -7.77714325],
                            [39.80290354, -7.77757626],
                            [39.80041452, -7.77876705],
                            [39.79857481, -7.77963307],
                            [39.79695153, -7.78060735],
                            [39.79576114, -7.7807156],
                            [39.79446252, -7.78136511],
                            [39.79413787, -7.78071559],
                            [39.79392144, -7.77974129],
                            [39.79251461, -7.77984954],
                            [39.79175707, -7.78093208],
                            [39.79002558, -7.78255589],
                            [39.78946492, -7.78339714],
                            [39.78915982, -7.78385493],
                            [39.79121596, -7.78493749],
                            [39.79155053, -7.78607545],
                            [39.79160674, -7.78626662],
                            [39.79175704, -7.78677782],
                            [39.79089128, -7.78796862],
                            [39.78970088, -7.78959243],
                            [39.78915979, -7.78905116],
                            [39.7891598, -7.78742734],
                            [39.78872694, -7.7853705],
                            [39.78699545, -7.78515398],
                            [39.78634614, -7.78656128],
                            [39.78515574, -7.78688604],
                            [39.78288316, -7.78731904],
                            [39.78093524, -7.7878603],
                            [39.77887909, -7.78840156],
                            [39.77660651, -7.78883456],
                            [39.77487503, -7.78970059],
                            [39.77260244, -7.79121614],
                            [39.77087095, -7.79164914],
                            [39.76794906, -7.79175738],
                            [39.76481074, -7.79175736],
                            [39.76145599, -7.79197385],
                            [39.7613645, -7.79219349],
                            [39.76118128, -7.7922265],
                            [39.76015405, -7.79274028],
                            [39.75787132, -7.79376784],
                            [39.75604514, -7.79462414],
                            [39.75381948, -7.79548044],
                            [39.7529064, -7.79548043],
                            [39.75199331, -7.79530916],
                            [39.75136556, -7.7955946],
                            [39.75142262, -7.79616547],
                            [39.75130848, -7.79696469],
                            [39.75056659, -7.79759265],
                            [39.74879748, -7.79839186],
                            [39.74634355, -7.79930524],
                            [39.74530682, -7.79940894],
                            [39.74406082, -7.79953357],
                            [39.74240584, -7.79999026],
                            [39.74018019, -7.80033278],
                            [39.73852522, -7.80090364],
                            [39.73601422, -7.80141741],
                            [39.7340739, -7.80170283],
                            [39.73201945, -7.80175991],
                            [39.73053568, -7.80181698],
                            [39.72848123, -7.80187406],
                            [39.72642678, -7.80170278],
                            [39.7244294, -7.80158859],
                            [39.72134773, -7.80118897],
                            [39.71900795, -7.80078934],
                            [39.71735297, -7.80061807],
                            [39.7155268, -7.80038971],
                            [39.7141001, -7.80021844],
                            [39.7126734, -7.80010425],
                            [39.71130377, -7.80004716],
                            [39.7099912, -7.8002755],
                            [39.7085645, -7.8002184],
                            [39.707309, -7.80038966],
                            [39.70628177, -7.80027547],
                            [39.70548282, -7.8005609],
                            [39.70508334, -7.80136013],
                            [39.70508334, -7.80227352],
                            [39.70434145, -7.80267313],
                            [39.70291475, -7.80307273],
                            [39.70154511, -7.80352942],
                            [39.70040375, -7.80347233],
                            [39.69926238, -7.80392902],
                            [39.69840636, -7.80461406],
                            [39.69783568, -7.80541328],
                            [39.69749326, -7.80609833],
                            [39.69749326, -7.80695464],
                            [39.69806393, -7.80729716],
                            [39.69852047, -7.80798222],
                            [39.69874874, -7.80861018],
                            [39.69914822, -7.80912397],
                            [39.69937648, -7.81015154],
                            [39.69971889, -7.81095077],
                            [39.69983301, -7.81220669],
                            [39.69914819, -7.81312008],
                            [39.69823509, -7.81449017],
                            [39.69743614, -7.81540356],
                            [39.69686545, -7.81625987],
                            [39.69612356, -7.81740161],
                            [39.69560994, -7.81848627],
                            [39.69526753, -7.8192284],
                            [39.69538165, -7.82025597],
                            [39.69606647, -7.82065559],
                            [39.69697956, -7.82054142],
                            [39.69789265, -7.82094104],
                            [39.69874866, -7.82151192],
                            [39.69954761, -7.8220828],
                            [39.70017535, -7.82276785],
                            [39.70074603, -7.82356707],
                            [39.70125964, -7.82425212],
                            [39.70183031, -7.82493718],
                            [39.70234392, -7.82545096],
                            [39.70302874, -7.82527971],
                            [39.70365282, -7.82522296],
                            [39.70365649, -7.82522262],
                            [39.70365428, -7.82521046],
                            [39.70354236, -7.82459466],
                            [39.70314288, -7.82402379],
                            [39.70245807, -7.82345291],
                            [39.70194446, -7.82282494],
                            [39.70148792, -7.82231115],
                            [39.70097431, -7.82185445],
                            [39.70040363, -7.82139775],
                            [39.69960469, -7.82082687],
                            [39.69943349, -7.82002765],
                            [39.69931936, -7.81928551],
                            [39.6994335, -7.81877173],
                            [39.6996047, -7.8179725],
                            [39.69937644, -7.81728746],
                            [39.69903403, -7.81660241],
                            [39.69903404, -7.81597444],
                            [39.69926231, -7.81540357],
                            [39.69989006, -7.81551775],
                            [39.70040367, -7.81591737],
                            [39.70074608, -7.81654533],
                            [39.700689, -7.81723038],
                            [39.70051779, -7.81791543],
                            [39.70063192, -7.81888591],
                            [39.70040365, -7.81968513],
                            [39.70074605, -7.82037018],
                            [39.7013738, -7.82054145],
                            [39.70183034, -7.8207698],
                            [39.70222981, -7.8212265],
                            [39.70268636, -7.82088398],
                            [39.70262929, -7.82031311],
                            [39.70257224, -7.81957097],
                            [39.70257224, -7.81900009],
                            [39.70217276, -7.81842922],
                            [39.7021157, -7.81802961],
                            [39.70251518, -7.81740165],
                            [39.70251518, -7.81705913],
                            [39.7019445, -7.81711621],
                            [39.70154503, -7.81688786],
                            [39.70165916, -7.81637407],
                            [39.7014309, -7.81574611],
                            [39.70103143, -7.8148898],
                            [39.70131677, -7.81414767],
                            [39.70137384, -7.81340553],
                            [39.70165918, -7.81294883],
                            [39.7020016, -7.81272049],
                            [39.70188746, -7.8122067],
                            [39.70148799, -7.81157874],
                            [39.70137386, -7.81077951],
                            [39.70108852, -7.81009447],
                            [39.701488, -7.80969486],
                            [39.70148801, -7.80918107],
                            [39.7009744, -7.80866728],
                            [39.69989011, -7.80792513],
                            [39.69909116, -7.80729717],
                            [39.69863462, -7.80684047],
                            [39.69857755, -7.80621251],
                            [39.69909117, -7.80558455],
                            [39.69971892, -7.80586999],
                            [39.70051787, -7.80604126],
                            [39.70125975, -7.80569874],
                            [39.70228698, -7.8054704],
                            [39.70342834, -7.80495662],
                            [39.70434144, -7.80421449],
                            [39.70514039, -7.80444284],
                            [39.70548279, -7.80518498],
                            [39.70559693, -7.80621255],
                            [39.70508331, -7.80672633],
                            [39.70411315, -7.80695468],
                            [39.70354247, -7.80706885],
                            [39.70399901, -7.80758264],
                            [39.7049121, -7.80809643],
                            [39.70605346, -7.80815353],
                            [39.70696655, -7.80758266],
                            [39.70748016, -7.80701179],
                            [39.70713776, -7.806498],
                            [39.70668122, -7.80621256],
                            [39.70702362, -7.80598421],
                            [39.70765138, -7.80564169],
                            [39.70896394, -7.8056417],
                            [39.70999116, -7.80587006],
                            [39.71113252, -7.80712599],
                            [39.71215974, -7.80769686],
                            [39.71318696, -7.80838192],
                            [39.71392884, -7.80912406],
                            [39.71467072, -7.81009455],
                            [39.7155838, -7.81066543],
                            [39.7161526, -7.81099734],
                            [39.71626862, -7.81106504],
                            [39.71638275, -7.81175009],
                            [39.71706756, -7.81237805],
                            [39.71763824, -7.81232097],
                            [39.71815185, -7.81260641],
                            [39.71832305, -7.81334855],
                            [39.71798064, -7.81409068],
                            [39.71718168, -7.81454738],
                            [39.71712461, -7.8153466],
                            [39.71786649, -7.81620291],
                            [39.71877957, -7.81700214],
                            [39.71877956, -7.81797262],
                            [39.71900783, -7.81877185],
                            [39.71912196, -7.81957107],
                            [39.71992092, -7.81945691],
                            [39.72089108, -7.81865769],
                            [39.72094815, -7.8181439],
                            [39.72083402, -7.81717342],
                            [39.72111937, -7.81660255],
                            [39.72151884, -7.81631711],
                            [39.72226072, -7.81660255],
                            [39.72288848, -7.81614586],
                            [39.72340209, -7.81500412],
                            [39.72402985, -7.81426199],
                            [39.7248288, -7.81363403],
                            [39.72528535, -7.81283481],
                            [39.72579897, -7.81169307],
                            [39.72595712, -7.81150323],
                            [39.72694034, -7.81032297],
                            [39.72785343, -7.8098092],
                            [39.72865239, -7.81015173],
                            [39.72928013, -7.81055134],
                            [39.73007908, -7.81049426],
                            [39.73082097, -7.81026591],
                            [39.73110631, -7.81055135],
                            [39.73082096, -7.81146475],
                            [39.73028732, -7.81222099],
                            [39.73013614, -7.81243523],
                            [39.73013613, -7.81334862],
                            [39.73007906, -7.81409076],
                            [39.72956544, -7.81437619],
                            [39.72922303, -7.81511832],
                            [39.72967957, -7.8156892],
                            [39.7297836, -7.81636559],
                            [39.72979371, -7.81643134],
                            [39.72928009, -7.81677386],
                            [39.72899475, -7.81728765],
                            [39.72928008, -7.81780143],
                            [39.72962249, -7.81848648],
                            [39.72950835, -7.81911444],
                            [39.72870939, -7.81951405],
                            [39.72694029, -7.81905734],
                            [39.72619841, -7.81808685],
                            [39.7256848, -7.81745888],
                            [39.72517119, -7.81774432],
                            [39.72534238, -7.81848645],
                            [39.72488584, -7.81922859],
                            [39.72448636, -7.81991363],
                            [39.72488583, -7.82071286],
                            [39.72488582, -7.82151208],
                            [39.72431514, -7.82225421],
                            [39.72294549, -7.82436644],
                            [39.72168998, -7.82596487],
                            [39.72106223, -7.82767749],
                            [39.7205486, -7.82933302],
                            [39.72049152, -7.83093146],
                            [39.72111926, -7.83275826],
                            [39.72197527, -7.8335004],
                            [39.72334491, -7.83270119],
                            [39.72391559, -7.83304372],
                            [39.7247716, -7.83418547],
                            [39.72597003, -7.8349847],
                            [39.72699725, -7.83509888],
                            [39.72802448, -7.83469928],
                            [39.7290517, -7.83509889],
                            [39.73019306, -7.83606938],
                            [39.73070666, -7.83755366],
                            [39.73043059, -7.83790514],
                            [39.73007891, -7.83835288],
                            [39.72836686, -7.8386383],
                            [39.72799758, -7.83923502],
                            [39.72762497, -7.83983713],
                            [39.72659774, -7.84057926],
                            [39.72596999, -7.84120722],
                            [39.72465742, -7.84154974],
                            [39.72368727, -7.84092177],
                            [39.72266005, -7.83955167],
                            [39.72168989, -7.8393804],
                            [39.72089094, -7.83983709],
                            [39.72026319, -7.84040796],
                            [39.71872235, -7.84040795],
                            [39.71780926, -7.8406363],
                            [39.71695325, -7.84080755],
                            [39.71701031, -7.84166386],
                            [39.71763805, -7.842406],
                            [39.71815166, -7.84303397],
                            [39.71855113, -7.84400445],
                            [39.71856869, -7.84407031],
                            [39.71877939, -7.84486076],
                            [39.71952127, -7.84537455],
                            [39.71917886, -7.8460596],
                            [39.71849404, -7.84680173],
                            [39.71763802, -7.84720134],
                            [39.71680631, -7.84696981],
                            [39.71531831, -7.84588726],
                            [39.71437142, -7.8448047],
                            [39.71383031, -7.84642852],
                            [39.71355977, -7.84764638],
                            [39.71166595, -7.84818764],
                            [39.71058376, -7.84913486],
                            [39.71040203, -7.84949844],
                            [39.70990739, -7.85048804],
                            [39.70855466, -7.85143526],
                            [39.70839032, -7.85152178],
                            [39.70846089, -7.85100415],
                            [39.70688271, -7.84995167],
                            [39.70583059, -7.85008322],
                            [39.70477846, -7.84903073],
                            [39.70366457, -7.84895113],
                            [39.70293725, -7.84889916],
                            [39.70254271, -7.8481098],
                            [39.70030694, -7.84810978],
                            [39.69912331, -7.84679418],
                            [39.70083302, -7.84574171],
                            [39.70096455, -7.84389987],
                            [39.69951788, -7.84389986],
                            [39.6979397, -7.84337361],
                            [39.69596696, -7.84403139],
                            [39.69373119, -7.84508386],
                            [39.68965419, -7.84810972],
                            [39.68715538, -7.85153026],
                            [39.68597171, -7.85521394],
                            [39.68452502, -7.85784512],
                            [39.68228925, -7.85929227],
                            [39.67992194, -7.86297593],
                            [39.67729161, -7.86665959],
                            [39.67347763, -7.87073793],
                            [39.67018973, -7.87415847],
                            [39.66755939, -7.87876305],
                            [39.66453451, -7.88310451],
                            [39.66441141, -7.88347391],
                            [39.66348236, -7.88626195],
                            [39.66124658, -7.88836689],
                            [39.65861626, -7.89112964],
                            [39.65578539, -7.89357527],
                            [39.6557229, -7.89362925],
                            [39.65350621, -7.89531449],
                            [39.652435, -7.89612888],
                            [39.64954164, -7.89902317],
                            [39.64769197, -7.90017959],
                            [39.64533312, -7.90165435],
                            [39.64138765, -7.90362772],
                            [39.63717915, -7.9036277],
                            [39.63690155, -7.90383597],
                            [39.63530506, -7.90383596],
                            [39.63431984, -7.90408234],
                            [39.63296517, -7.9046983],
                            [39.63197995, -7.90531426],
                            [39.63037898, -7.90494466],
                            [39.62840855, -7.90469827],
                            [39.62668443, -7.90432868],
                            [39.62397509, -7.90408227],
                            [39.6213889, -7.90457503],
                            [39.61892586, -7.90556056],
                            [39.61683226, -7.90703887],
                            [39.61523128, -7.90876357],
                            [39.61301453, -7.91073465],
                            [39.61153672, -7.90937951],
                            [39.60858108, -7.90974907],
                            [39.60710325, -7.91061142],
                            [39.60525597, -7.91159695],
                            [39.6041476, -7.91172014],
                            [39.60266978, -7.91110416],
                            [39.60143826, -7.91147374],
                            [39.59996043, -7.91319843],
                            [39.59860576, -7.91307523],
                            [39.59688163, -7.91245926],
                            [39.59478805, -7.91344479],
                            [39.59454174, -7.91381437],
                            [39.59244817, -7.912952],
                            [39.59097034, -7.9128288],
                            [39.58912307, -7.91319836],
                            [39.58715263, -7.91479987],
                            [39.58579795, -7.91615499],
                            [39.58444327, -7.91787969],
                            [39.58308858, -7.91972758],
                            [39.58222651, -7.92169867],
                            [39.58136443, -7.92428573],
                            [39.58111811, -7.92638002],
                            [39.58099495, -7.92847431],
                            [39.58111809, -7.93007582],
                            [39.58161069, -7.93192373],
                            [39.58259588, -7.93401802],
                            [39.58345794, -7.93537316],
                            [39.58431999, -7.93672829],
                            [39.58542835, -7.93796023],
                            [39.58665986, -7.93943856],
                            [39.58838398, -7.94104009],
                            [39.58975439, -7.94241098],
                            [39.58986179, -7.94251842],
                            [39.59183221, -7.94338079],
                            [39.59355633, -7.94485912],
                            [39.59589621, -7.94584468],
                            [39.59811293, -7.94695344],
                            [39.59934444, -7.94941731],
                            [39.60008334, -7.95064926],
                            [39.60279267, -7.95126524],
                            [39.60427049, -7.9522508],
                            [39.6057483, -7.95397552],
                            [39.60661034, -7.95643939],
                            [39.60686123, -7.95708078],
                            [39.6077187, -7.95927285],
                            [39.60821129, -7.96087437],
                            [39.60884914, -7.96289494],
                            [39.60895019, -7.96321505],
                            [39.61018169, -7.96518615],
                            [39.61173766, -7.9666511],
                            [39.61227526, -7.96715726],
                            [39.61461514, -7.9685124],
                            [39.6167087, -7.97036031],
                            [39.61855598, -7.97134588],
                            [39.62064955, -7.97270102],
                            [39.62311258, -7.97430255],
                            [39.62495984, -7.9755345],
                            [39.62619135, -7.97701283],
                            [39.62742286, -7.97873754],
                            [39.62840806, -7.98120142],
                            [39.62803859, -7.98304932],
                            [39.62743138, -7.9845071],
                            [39.62742283, -7.98452763],
                            [39.62532924, -7.98526678],
                            [39.62323565, -7.98612912],
                            [39.62126522, -7.98686827],
                            [39.61978739, -7.98736104],
                            [39.61855586, -7.98883935],
                            [39.61830955, -7.99056406],
                            [39.61929475, -7.99315113],
                            [39.61941788, -7.995615],
                            [39.61966418, -7.99697013],
                            [39.61991047, -7.99968039],
                            [39.62163457, -8.00189788],
                            [39.62249662, -8.00386898],
                            [39.62274292, -8.00522411],
                            [39.62188086, -8.00522411],
                            [39.62027989, -8.00448494],
                            [39.61843261, -8.00411534],
                            [39.6174474, -8.00386895],
                            [39.61609273, -8.00423852],
                            [39.61510751, -8.00411532],
                            [39.61461492, -8.00300657],
                            [39.61461492, -8.00165145],
                            [39.61424547, -8.00054271],
                            [39.61276765, -7.99992673],
                            [39.61202875, -7.99955715],
                            [39.61141299, -7.99918756],
                            [39.61030461, -8.00066587],
                            [39.60993515, -8.00226738],
                            [39.61067405, -8.00374571],
                            [39.6117824, -8.0060864],
                            [39.61313706, -8.00793431],
                            [39.61387596, -8.01027499],
                            [39.61399909, -8.0119997],
                            [39.61387594, -8.01360121],
                            [39.61486114, -8.01507954],
                            [39.61621579, -8.01828258],
                            [39.61781675, -8.0200073],
                            [39.61793991, -8.01877536],
                            [39.61793992, -8.01778981],
                            [39.61793992, -8.01717385],
                            [39.61806308, -8.0163115],
                            [39.61818623, -8.01569553],
                            [39.61806309, -8.01483317],
                            [39.61954091, -8.01483318],
                            [39.61954091, -8.01581873],
                            [39.6195409, -8.01631151],
                            [39.6200335, -8.01742025],
                            [39.62052609, -8.01939135],
                            [39.62101869, -8.02086968],
                            [39.62163444, -8.02197841],
                            [39.62200389, -8.02345674],
                            [39.62175758, -8.02431909],
                            [39.62274279, -8.02542784],
                            [39.62335854, -8.0264134],
                            [39.62422059, -8.02776853],
                            [39.62397429, -8.02887727],
                            [39.62459004, -8.03010921],
                            [39.62582155, -8.03158754],
                            [39.62754566, -8.03281948],
                            [39.62914664, -8.03306588],
                            [39.6297624, -8.03257311],
                            [39.63087077, -8.03195715],
                            [39.63210229, -8.03084842],
                            [39.63284121, -8.02949329],
                            [39.63358013, -8.02801497],
                            [39.6339496, -8.02616707],
                            [39.63419591, -8.02518153],
                            [39.63370331, -8.02407278],
                            [39.63321071, -8.02345681],
                            [39.63321071, -8.02222488],
                            [39.63456538, -8.02296405],
                            [39.63493484, -8.02370322],
                            [39.63616634, -8.02444238],
                            [39.63739786, -8.02481197],
                            [39.63862938, -8.02481198],
                            [39.63936828, -8.02653669],
                            [39.63899881, -8.02887737],
                            [39.63838303, -8.03060207],
                            [39.63678205, -8.03244996],
                            [39.63665889, -8.03429786],
                            [39.63739779, -8.03577619],
                            [39.63899876, -8.03528342],
                            [39.63961453, -8.03429788],
                            [39.64096921, -8.03318915],
                            [39.64244703, -8.03368193],
                            [39.64466376, -8.03368194],
                            [39.64675735, -8.03269641],
                            [39.64774257, -8.03134129],
                            [39.64823519, -8.02961658],
                            [39.6482352, -8.02789187],
                            [39.64798891, -8.02629036],
                            [39.64651109, -8.02518161],
                            [39.64552589, -8.02407286],
                            [39.64343231, -8.02358008],
                            [39.64146188, -8.02308728],
                            [39.64047668, -8.02185535],
                            [39.64096929, -8.02037703],
                            [39.64035355, -8.01803635],
                            [39.63887574, -8.01643482],
                            [39.63862944, -8.01581885],
                            [39.63678217, -8.01409413],
                            [39.63641272, -8.01310858],
                            [39.63826, -8.01286221],
                            [39.63924521, -8.01384776],
                            [39.64072302, -8.01471013],
                            [39.64195453, -8.01705081],
                            [39.64330919, -8.0192683],
                            [39.64441754, -8.02062344],
                            [39.64540276, -8.01963789],
                            [39.64564908, -8.01815958],
                            [39.64614169, -8.01754361],
                            [39.64675745, -8.01779],
                            [39.64811211, -8.01852917],
                            [39.64946678, -8.01865237],
                            [39.650452, -8.01717406],
                            [39.65106778, -8.01495658],
                            [39.6515604, -8.01323187],
                            [39.65221192, -8.0113853],
                            [39.65229932, -8.01113759],
                            [39.65377713, -8.01249273],
                            [39.6558707, -8.01446384],
                            [39.65833374, -8.01483343],
                            [39.65993472, -8.01347832],
                            [39.66055049, -8.01224638],
                            [39.66202832, -8.0102753],
                            [39.66252094, -8.00818101],
                            [39.66227465, -8.00633311],
                            [39.66289042, -8.00485479],
                            [39.66264413, -8.0026373],
                            [39.66202839, -8.00005024],
                            [39.66141264, -7.99770956],
                            [39.66092006, -7.99487611],
                            [39.66092007, -7.9930282],
                            [39.66128792, -7.99229226],
                            [39.66153583, -7.99179627],
                            [39.66301366, -7.99056435],
                            [39.6644915, -7.98933242],
                            [39.66744714, -7.98883967],
                            [39.66966388, -7.9885933],
                            [39.67298898, -7.98871651],
                            [39.67631408, -7.98847014],
                            [39.68000863, -7.98847017],
                            [39.68345688, -7.98797742],
                            [39.68579677, -7.98785424],
                            [39.68825981, -7.98773106],
                            [39.68887557, -7.98773107],
                            [39.69096915, -7.98773108],
                            [39.69355534, -7.9876079],
                            [39.69589522, -7.98748472],
                            [39.6977425, -7.98736154],
                            [39.69875651, -7.9869558],
                            [39.69964412, -7.98766615],
                            [39.70112813, -7.98893861],
                            [39.70282415, -7.99063521],
                            [39.70487284, -7.992312],
                            [39.70515617, -7.9925439],
                            [39.7077002, -7.99318014],
                            [39.70918422, -7.99381637],
                            [39.71045623, -7.99381638],
                            [39.71215226, -7.99445262],
                            [39.71406028, -7.99466471],
                            [39.71519096, -7.99541875],
                            [39.71533229, -7.99551301],
                            [39.7159683, -7.99636131],
                            [39.71660429, -7.99784584],
                            [39.7176643, -7.99933037],
                            [39.71914832, -7.99890623],
                            [39.72084435, -7.99848209],
                            [39.72232838, -7.9976338],
                            [39.7240244, -7.99720967],
                            [39.72529643, -7.99593723],
                            [39.72678046, -7.99508894],
                            [39.72762848, -7.99381649],
                            [39.7286885, -7.99275613],
                            [39.72953652, -7.99190783],
                            [39.73038453, -7.99148369],
                            [39.73123255, -7.99042332],
                            [39.73165656, -7.98936295],
                            [39.73250459, -7.98724221],
                            [39.73314061, -7.98554561],
                            [39.73292862, -7.98342487],
                            [39.73250462, -7.98194034],
                            [39.73335265, -7.98045583],
                            [39.73420066, -7.97939546],
                            [39.73462468, -7.97748679],
                            [39.73589671, -7.97557812],
                            [39.73674474, -7.97366945],
                            [39.73801677, -7.97091249],
                            [39.7390768, -7.96942798],
                            [39.73971282, -7.96773139],
                            [39.74056084, -7.96603479],
                            [39.74098486, -7.96412612],
                            [39.74098487, -7.96200538],
                            [39.74098488, -7.96136915],
                            [39.74098489, -7.95967255],
                            [39.7414089, -7.95840011],
                            [39.74183293, -7.95564314],
                            [39.74246895, -7.95331032],
                            [39.74077294, -7.95097749],
                            [39.73939776, -7.94979834],
                            [39.73928893, -7.94970503],
                            [39.73801692, -7.94800843],
                            [39.73738092, -7.94694805],
                            [39.73610892, -7.94482729],
                            [39.73504891, -7.94355484],
                            [39.7349323, -7.94349652],
                            [39.73292888, -7.94249445],
                            [39.73208087, -7.94185822],
                            [39.7331409, -7.94079785],
                            [39.73483692, -7.94058579],
                            [39.73420092, -7.93910126],
                            [39.73292891, -7.93825296],
                            [39.73123289, -7.93740465],
                            [39.73106149, -7.93697599],
                            [39.7308089, -7.93634427],
                            [39.7310209, -7.93507182],
                            [39.73250492, -7.93570806],
                            [39.73377694, -7.93592014],
                            [39.73504896, -7.93528393],
                            [39.73568497, -7.93443563],
                            [39.736321, -7.93337526],
                            [39.73674501, -7.93189074],
                            [39.73759302, -7.93125452],
                            [39.73801703, -7.93019415],
                            [39.73822905, -7.92934586],
                            [39.73716903, -7.92913377],
                            [39.735685, -7.92998206],
                            [39.73377698, -7.92976997],
                            [39.73186895, -7.92913374],
                            [39.73123296, -7.92743713],
                            [39.73123297, -7.92574054],
                            [39.73144498, -7.92468016],
                            [39.732293, -7.92319565],
                            [39.73314102, -7.92213528],
                            [39.73343524, -7.92184096],
                            [39.73340941, -7.92178067],
                            [39.73404426, -7.92222034],
                            [39.73459599, -7.92242104],
                            [39.73514772, -7.92282244],
                            [39.73610072, -7.92312349],
                            [39.73695339, -7.92327402],
                            [39.73775591, -7.92327402],
                            [39.73865874, -7.92307333],
                            [39.73931079, -7.92257159],
                            [39.74021363, -7.92242107],
                            [39.74131709, -7.92212004],
                            [39.74216977, -7.92171865],
                            [39.74307261, -7.92166848],
                            [39.7436745, -7.92171866],
                            [39.74462749, -7.92201971],
                            [39.74558048, -7.92217024],
                            [39.74688457, -7.92257164],
                            [39.74763693, -7.92307339],
                            [39.74838929, -7.92332427],
                            [39.74904133, -7.92367549],
                            [39.74964322, -7.92362532],
                            [39.7508765, -7.92334063],
                            [39.75094732, -7.92332428],
                            [39.75159936, -7.92332429],
                            [39.75240188, -7.92322395],
                            [39.75340503, -7.92322395],
                            [39.75405708, -7.9229229],
                            [39.7548596, -7.92277239],
                            [39.75571228, -7.92292292],
                            [39.75621385, -7.92272223],
                            [39.75701638, -7.92227066],
                            [39.75761827, -7.92176892],
                            [39.75781891, -7.921217],
                            [39.75741765, -7.92056473],
                            [39.75651482, -7.91936054],
                            [39.75581262, -7.91840722],
                            [39.75484675, -7.91744101],
                            [39.75480948, -7.91740373],
                            [39.75365587, -7.91614936],
                            [39.75305398, -7.91484482],
                            [39.75260258, -7.91379116],
                            [39.75245211, -7.91273749],
                            [39.75265275, -7.9119347],
                            [39.75245212, -7.91108174],
                            [39.75240197, -7.91027894],
                            [39.75230166, -7.9093758],
                            [39.75205088, -7.90842248],
                            [39.75190041, -7.90746917],
                            [39.75230168, -7.90681691],
                            [39.75300388, -7.90676674],
                            [39.75345529, -7.90716814],
                            [39.75370608, -7.90772006],
                            [39.75415749, -7.90817163],
                            [39.75430796, -7.9090246],
                            [39.75415748, -7.90982738],
                            [39.75465905, -7.91093122],
                            [39.75485967, -7.91163367],
                            [39.75511046, -7.91223576],
                            [39.75501014, -7.91298838],
                            [39.75501013, -7.91364064],
                            [39.75506029, -7.91424274],
                            [39.75546155, -7.91459396],
                            [39.75596312, -7.91499536],
                            [39.7562139, -7.91549711],
                            [39.75611358, -7.9158985],
                            [39.75646469, -7.91640025],
                            [39.75731736, -7.91655078],
                            [39.75791925, -7.91614939],
                            [39.75837067, -7.91569782],
                            [39.75827036, -7.91489503],
                            [39.75806974, -7.91384136],
                            [39.75806974, -7.91263718],
                            [39.75791928, -7.91148316],
                            [39.75786913, -7.9104295],
                            [39.75801961, -7.90912497],
                            [39.75806977, -7.90772008],
                            [39.75827041, -7.90666642],
                            [39.75862152, -7.90571311],
                            [39.75892247, -7.9049605],
                            [39.75947421, -7.90425806],
                            [39.75997579, -7.90345527],
                            [39.76027674, -7.90240161],
                            [39.760678, -7.90169917],
                            [39.76112942, -7.90109708],
                            [39.76168116, -7.90029429],
                            [39.76218274, -7.89959186],
                            [39.76283479, -7.89954169],
                            [39.76299527, -7.89946142],
                            [39.76363731, -7.89914029],
                            [39.76499156, -7.89909013],
                            [39.76619534, -7.89929083],
                            [39.76734896, -7.89929084],
                            [39.76835211, -7.89929085],
                            [39.76915463, -7.89954173],
                            [39.76960604, -7.8993912],
                            [39.77035841, -7.89964208],
                            [39.77126124, -7.89989296],
                            [39.7720136, -7.90019401],
                            [39.7730669, -7.90024419],
                            [39.77381927, -7.89989298],
                            [39.774371, -7.89954176],
                            [39.77482243, -7.8984881],
                            [39.77522369, -7.89758496],
                            [39.77577543, -7.89683235],
                            [39.77579243, -7.89680258],
                            [39.77637733, -7.89577869],
                            [39.77687891, -7.89512643],
                            [39.77717985, -7.89472503],
                            [39.77763127, -7.89412294],
                            [39.77808269, -7.89367138],
                            [39.77853411, -7.89306928],
                            [39.77903569, -7.89326998],
                            [39.77933663, -7.89301912],
                            [39.78003884, -7.89261773],
                            [39.78084136, -7.89251738],
                            [39.78169404, -7.89216617],
                            [39.7824464, -7.89206582],
                            [39.78304828, -7.89281844],
                            [39.78354986, -7.89327001],
                            [39.78395111, -7.89362123],
                            [39.78430222, -7.89397246],
                            [39.78485395, -7.89387211],
                            [39.78520505, -7.89422334],
                            [39.78575678, -7.89452439],
                            [39.78645898, -7.89512649],
                            [39.78620818, -7.89577875],
                            [39.78565645, -7.89653137],
                            [39.78510472, -7.89718363],
                            [39.7844025, -7.89758502],
                            [39.78430219, -7.89823729],
                            [39.78455297, -7.89888955],
                            [39.78515486, -7.89949165],
                            [39.78595737, -7.89984288],
                            [39.78675989, -7.90004358],
                            [39.78786335, -7.90034463],
                            [39.78831701, -7.90062827],
                            [39.78866587, -7.90084638],
                            [39.7895687, -7.90134813],
                            [39.79022074, -7.9020004],
                            [39.79086643, -7.90243101],
                            [39.79112357, -7.90260251],
                            [39.79142451, -7.90325478],
                            [39.79172545, -7.90385687],
                            [39.79182576, -7.90470983],
                            [39.79157497, -7.90541228],
                            [39.79177559, -7.90611472],
                            [39.79222701, -7.90636559],
                            [39.79232732, -7.90691751],
                            [39.79262827, -7.90741926],
                            [39.79312984, -7.90761996],
                            [39.79338062, -7.9081217],
                            [39.79408282, -7.90842275],
                            [39.79473487, -7.90887433],
                            [39.7956377, -7.90922556],
                            [39.79639006, -7.90962696],
                            [39.79674116, -7.91002835],
                            [39.79684147, -7.91068063],
                            [39.79724273, -7.9109315],
                            [39.79764399, -7.9111322],
                            [39.79799509, -7.91193499],
                            [39.79759382, -7.91263743],
                            [39.79769413, -7.9132897],
                            [39.79764397, -7.91414266],
                            [39.79774428, -7.91494545],
                            [39.79844648, -7.91514616],
                            [39.79909853, -7.91529669],
                            [39.79965026, -7.91529669],
                            [39.80035246, -7.91549739],
                            [39.80090419, -7.91650088],
                            [39.8010045, -7.91735384],
                            [39.80165654, -7.91840751],
                            [39.80135559, -7.91966187],
                            [39.80115495, -7.92046467],
                            [39.80070352, -7.92131763],
                            [39.79995116, -7.92176919],
                            [39.7991988, -7.92181936],
                            [39.79839628, -7.92151831],
                            [39.79829597, -7.92086604],
                            [39.7977944, -7.92031412],
                            [39.79809535, -7.9195615],
                            [39.79829599, -7.91865836],
                            [39.79819567, -7.91775522],
                            [39.79784458, -7.91695243],
                            [39.79719254, -7.91660121],
                            [39.79613923, -7.91594893],
                            [39.79538687, -7.91594893],
                            [39.79438372, -7.91630014],
                            [39.79363136, -7.91635031],
                            [39.79297931, -7.91680188],
                            [39.79237742, -7.91740397],
                            [39.79172536, -7.91780536],
                            [39.790973, -7.91890919],
                            [39.79062188, -7.92021372],
                            [39.79062188, -7.92096634],
                            [39.79032093, -7.92202],
                            [39.79037108, -7.92292314],
                            [39.78996981, -7.92382628],
                            [39.78966886, -7.92477959],
                            [39.78901682, -7.92472941],
                            [39.78816414, -7.92508063],
                            [39.78781303, -7.92598376],
                            [39.78696035, -7.92618445],
                            [39.78640862, -7.92643533],
                            [39.78585688, -7.92733846],
                            [39.78565624, -7.92844229],
                            [39.78565624, -7.92919491],
                            [39.78585686, -7.929797],
                            [39.7858067, -7.93100119],
                            [39.78545559, -7.93190433],
                            [39.78570637, -7.93310852],
                            [39.78585684, -7.93366044],
                            [39.78645872, -7.93426253],
                            [39.78680983, -7.93466393],
                            [39.78776281, -7.93506533],
                            [39.78831454, -7.93551691],
                            [39.78901675, -7.93546673],
                            [39.78936785, -7.93551691],
                            [39.79017037, -7.93566744],
                            [39.79122368, -7.93551693],
                            [39.79107321, -7.93486465],
                            [39.79052148, -7.93491483],
                            [39.78976912, -7.93441308],
                            [39.78956849, -7.93396151],
                            [39.78901676, -7.93350993],
                            [39.78926755, -7.93270715],
                            [39.78981929, -7.93230575],
                            [39.79032086, -7.93270715],
                            [39.79072212, -7.9332089],
                            [39.7909729, -7.933811],
                            [39.79152463, -7.93386117],
                            [39.79172526, -7.9332089],
                            [39.79177542, -7.93255664],
                            [39.79222684, -7.93220542],
                            [39.79272842, -7.93220542],
                            [39.79328015, -7.93260682],
                            [39.79383188, -7.9332591],
                            [39.79463439, -7.93381102],
                            [39.79538675, -7.93391137],
                            [39.79659054, -7.93310859],
                            [39.79689149, -7.93240615],
                            [39.79734291, -7.93190441],
                            [39.79849653, -7.93160337],
                            [39.79924889, -7.93200477],
                            [39.80040251, -7.93210512],
                            [39.80145582, -7.93165356],
                            [39.80200756, -7.93095112],
                            [39.80250914, -7.93034903],
                            [39.80306087, -7.9296466],
                            [39.80396371, -7.92909468],
                            [39.80446529, -7.92879364],
                            [39.8042145, -7.92829189],
                            [39.80481639, -7.92779015],
                            [39.8051675, -7.92743893],
                            [39.80566908, -7.92653579],
                            [39.80591987, -7.92578318],
                            [39.80531799, -7.92498039],
                            [39.80406406, -7.92417759],
                            [39.80336186, -7.9231741],
                            [39.80321139, -7.92196991],
                            [39.80286029, -7.92121729],
                            [39.80321141, -7.9202138],
                            [39.80411425, -7.91936085],
                            [39.80551866, -7.91941103],
                            [39.80556881, -7.92071556],
                            [39.80536817, -7.92146818],
                            [39.80501706, -7.92242149],
                            [39.8056691, -7.92282289],
                            [39.80652178, -7.92272255],
                            [39.80792619, -7.92232116],
                            [39.80912997, -7.92242152],
                            [39.81003281, -7.92237135],
                            [39.81053439, -7.92166891],
                            [39.81188865, -7.92026403],
                            [39.81279976, -7.91862349],
                            [39.81289181, -7.91845776],
                            [39.81359402, -7.91705288],
                            [39.81354387, -7.9154473],
                            [39.81314262, -7.91444381],
                            [39.812942, -7.9134905],
                            [39.81228995, -7.91328979],
                            [39.81103602, -7.91303891],
                            [39.80958145, -7.9128382],
                            [39.80857831, -7.91233645],
                            [39.80767547, -7.91198523],
                            [39.80662217, -7.91138313],
                            [39.80637139, -7.91063051],
                            [39.80612061, -7.90962702],
                            [39.80630508, -7.90939636],
                            [39.80652187, -7.90912528],
                            [39.80732439, -7.9096772],
                            [39.80832753, -7.91063052],
                            [39.80907989, -7.91083122],
                            [39.81003288, -7.91073088],
                            [39.81103603, -7.91083124],
                            [39.81193886, -7.91143334],
                            [39.81274138, -7.91198526],
                            [39.81379468, -7.91218596],
                            [39.81464736, -7.91168422],
                            [39.81489815, -7.91068074],
                            [39.81489816, -7.90922568],
                            [39.81494833, -7.9078208],
                            [39.81550007, -7.90631557],
                            [39.81600165, -7.90526191],
                            [39.81715529, -7.90420825],
                            [39.82071647, -7.90215112],
                            [39.82382625, -7.89979294],
                            [39.82648462, -7.89653163],
                            [39.82919315, -7.89286891],
                            [39.83109915, -7.88990863],
                            [39.83325594, -7.88619574],
                            [39.83516194, -7.88343616],
                            [39.83638998, -7.88186649],
                            [39.83967615, -7.87766613],
                            [39.84278594, -7.8730501],
                            [39.8445385, -7.87048157],
                            [39.84494273, -7.86988913],
                            [39.84609161, -7.86817748],
                            [39.84730016, -7.86637693],
                            [39.84850395, -7.86406891],
                            [39.84949528, -7.86181292],
                            [39.85051028, -7.85950305],
                            [39.85126265, -7.85769678],
                            [39.85191471, -7.8560912],
                            [39.85251661, -7.85393371],
                            [39.8533693, -7.85152534],
                            [39.85387089, -7.8486654],
                            [39.85387091, -7.8467086],
                            [39.85397124, -7.84485214],
                            [39.85367031, -7.84269464],
                            [39.8535138, -7.84152037],
                            [39.85346969, -7.84118941],
                            [39.85296812, -7.8392326],
                            [39.85251672, -7.83732597],
                            [39.85236626, -7.83536917],
                            [39.85251674, -7.83416498],
                            [39.852918, -7.83301097],
                            [39.85326911, -7.83225836],
                            [39.8530866, -7.83217848],
                            [39.85333274, -7.8320286],
                            [39.85452314, -7.8306213],
                            [39.8553889, -7.82910574],
                            [39.85679575, -7.8266159],
                            [39.85917656, -7.82336828],
                            [39.86134094, -7.82055367],
                            [39.86404641, -7.8171978],
                            [39.86409445, -7.81709294],
                            [39.86523682, -7.8145997],
                            [39.86729298, -7.81091906],
                            [39.86880806, -7.8079962],
                            [39.86978203, -7.80572286],
                            [39.87140531, -7.80334127],
                            [39.8730286, -7.80074317],
                            [39.87443545, -7.79749554],
                            [39.87605874, -7.79457268],
                            [39.87724915, -7.79229934],
                            [39.87740345, -7.7919392],
                            [39.87887244, -7.78851045],
                            [39.87952177, -7.78547932],
                            [39.87941357, -7.78331423],
                            [39.88082042, -7.78017486],
                            [39.88211906, -7.77627771],
                            [39.88363414, -7.77162277],
                            [39.88471634, -7.76794212],
                            [39.88536568, -7.76426147],
                            [39.88688075, -7.76058083],
                            [39.88807117, -7.75635891],
                            [39.88861229, -7.75289477],
                            [39.88947806, -7.74845634],
                            [39.89045205, -7.7438014],
                            [39.89175069, -7.73936297],
                            [39.89315756, -7.73427502],
                            [39.89308822, -7.73425462],
                            [39.89315756, -7.73416676],
                            [39.89359044, -7.73254295],
                            [39.89391511, -7.7302696],
                            [39.89413155, -7.72767149],
                            [39.89445623, -7.72539816],
                            [39.89499734, -7.72204227],
                            [39.89575489, -7.71857812],
                            [39.89616504, -7.71662929],
                            [39.89618776, -7.71652129],
                            [39.8968371, -7.71370668],
                            [39.89770286, -7.70991778],
                            [39.89910971, -7.70634539],
                            [39.89965083, -7.70342252],
                            [39.89954262, -7.70039139],
                            [39.89963389, -7.70007187],
                            [39.90040838, -7.69736027],
                            [39.9009495, -7.69313834],
                            [39.90144621, -7.68999161],
                            [39.90159884, -7.68902468],
                            [39.90192351, -7.68664308],
                            [39.90235639, -7.68447799],
                            [39.90268106, -7.68274592],
                            [39.90311395, -7.68014782],
                            [39.90333039, -7.67765796],
                            [39.90376328, -7.67516811],
                            [39.90441261, -7.67202873],
                            [39.90452084, -7.66953888],
                            [39.90452084, -7.66940227],
                            [39.90452085, -7.66791506],
                            [39.90484552, -7.66596648],
                            [39.90462909, -7.66423441],
                            [39.90473733, -7.66206932],
                            [39.90517021, -7.66022899],
                            [39.90549487, -7.65860518],
                            [39.90571132, -7.65633183],
                            [39.90571133, -7.65416674],
                            [39.90571134, -7.65254292],
                            [39.90625245, -7.65016133],
                            [39.90657711, -7.64875402],
                            [39.90625247, -7.64756322],
                            [39.90592782, -7.64626416],
                            [39.90646891, -7.64528987],
                            [39.90625249, -7.64409907],
                            [39.90646893, -7.64323304],
                            [39.90650945, -7.64315197],
                            [39.90679359, -7.64258351],
                            [39.90679359, -7.64182573],
                            [39.9067936, -7.64074318],
                            [39.90744291, -7.64031017],
                            [39.90744291, -7.63955239],
                            [39.90722649, -7.63836159],
                            [39.90755115, -7.63717079],
                            [39.90701006, -7.63597999],
                            [39.90711829, -7.63489744],
                            [39.90701008, -7.6338149],
                            [39.90722652, -7.63327362],
                            [39.90733474, -7.63219108],
                            [39.90690188, -7.63089202],
                            [39.90722654, -7.62980948],
                            [39.9075512, -7.62829392],
                            [39.9069019, -7.62677835],
                            [39.90657726, -7.62558755],
                            [39.90690191, -7.62472152],
                            [39.90711836, -7.62385548],
                            [39.90657727, -7.62298944],
                            [39.9066855, -7.62233991],
                            [39.90690193, -7.62158213],
                            [39.90636085, -7.62114911],
                            [39.90625264, -7.61995831],
                            [39.90668552, -7.61920053],
                            [39.90636087, -7.61811798],
                            [39.90571157, -7.61649416],
                            [39.90506227, -7.61508685],
                            [39.90365544, -7.61432906],
                            [39.90224861, -7.61497858],
                            [39.90062534, -7.61551984],
                            [39.89911028, -7.61573634],
                            [39.89727057, -7.61616935],
                            [39.89532265, -7.61660236],
                            [39.89326651, -7.61714361],
                            [39.89218432, -7.61865917],
                            [39.89099391, -7.61930869],
                            [39.88980351, -7.62082425],
                            [39.88828845, -7.62179853],
                            [39.88709804, -7.62309757],
                            [39.88590763, -7.6251544],
                            [39.88471723, -7.6265617],
                            [39.88385147, -7.62840203],
                            [39.88287751, -7.62970107],
                        ],
                        [
                            [39.7748343, -7.92054037],
                            [39.77483599, -7.92054882],
                            [39.77490526, -7.92041022],
                            [39.7748343, -7.92054037],
                        ],
                        [
                            [39.77462818, -7.91950937],
                            [39.77393544, -7.91923218],
                            [39.77324271, -7.92027162],
                            [39.77296561, -7.92082599],
                            [39.77268852, -7.92075669],
                            [39.77206507, -7.92041021],
                            [39.77137234, -7.92020231],
                            [39.77088743, -7.92068739],
                            [39.77061034, -7.92117245],
                            [39.77102596, -7.92186542],
                            [39.77130305, -7.92255839],
                            [39.77095668, -7.92290487],
                            [39.77109523, -7.92345924],
                            [39.77088741, -7.92373642],
                            [39.77074887, -7.92352853],
                            [39.77061032, -7.92387501],
                            [39.77033323, -7.9240829],
                            [39.77012541, -7.92415219],
                            [39.76984831, -7.92456797],
                            [39.76970977, -7.92463727],
                            [39.76957122, -7.92442938],
                            [39.76943268, -7.92408289],
                            [39.76977905, -7.92373642],
                            [39.76970977, -7.92345923],
                            [39.76901704, -7.92311274],
                            [39.76943269, -7.92290486],
                            [39.76977905, -7.92276627],
                            [39.76964051, -7.92255838],
                            [39.76977906, -7.92228119],
                            [39.7699176, -7.922004],
                            [39.76957124, -7.92172682],
                            [39.76964051, -7.92131104],
                            [39.76957124, -7.92089527],
                            [39.76991761, -7.92082597],
                            [39.77005616, -7.92054879],
                            [39.77012543, -7.9202023],
                            [39.76964052, -7.91985582],
                            [39.76950198, -7.91937074],
                            [39.76936343, -7.91944004],
                            [39.76908634, -7.91978652],
                            [39.7686707, -7.92020229],
                            [39.76818579, -7.92068737],
                            [39.76804724, -7.92110314],
                            [39.76770087, -7.92172681],
                            [39.76721595, -7.92241977],
                            [39.76721595, -7.92311273],
                            [39.76693885, -7.9235978],
                            [39.76666176, -7.92429077],
                            [39.76638466, -7.92491443],
                            [39.7665232, -7.9255381],
                            [39.76631538, -7.92650825],
                            [39.76638465, -7.92699332],
                            [39.76631537, -7.9273398],
                            [39.76617683, -7.92754769],
                            [39.76589973, -7.92740909],
                            [39.76562265, -7.9272012],
                            [39.76534555, -7.92706261],
                            [39.76465283, -7.92685472],
                            [39.7639601, -7.92650823],
                            [39.7631981, -7.92671612],
                            [39.76257464, -7.92678541],
                            [39.76211538, -7.92711355],
                            [39.76208973, -7.92713188],
                            [39.7618819, -7.92775555],
                            [39.76160481, -7.9286564],
                            [39.7616048, -7.92934937],
                            [39.7616048, -7.92990374],
                            [39.76208971, -7.93031952],
                            [39.76292098, -7.9307353],
                            [39.76375226, -7.9308739],
                            [39.76423716, -7.93115109],
                            [39.76527625, -7.93135898],
                            [39.7661768, -7.93177477],
                            [39.76645389, -7.93212125],
                            [39.7666617, -7.93288352],
                            [39.76596897, -7.93336858],
                            [39.76527624, -7.93385365],
                            [39.76465278, -7.93413083],
                            [39.76389078, -7.93447731],
                            [39.76292095, -7.93517027],
                            [39.76278241, -7.93551675],
                            [39.76264386, -7.93600182],
                            [39.76236676, -7.93655619],
                            [39.76222821, -7.93724915],
                            [39.7622282, -7.93815001],
                            [39.76202038, -7.93877367],
                            [39.76208965, -7.93960523],
                            [39.76208964, -7.94029819],
                            [39.76174327, -7.94112974],
                            [39.76160472, -7.9419613],
                            [39.76208963, -7.94279286],
                            [39.76319799, -7.94334724],
                            [39.76407232, -7.94349302],
                            [39.7644449, -7.94355514],
                            [39.76492981, -7.94334725],
                            [39.76631527, -7.94334726],
                            [39.76714654, -7.94348586],
                            [39.76811636, -7.94397094],
                            [39.76846273, -7.94438672],
                            [39.76908618, -7.94501039],
                            [39.76957109, -7.94445602],
                            [39.77005601, -7.94362447],
                            [39.77067947, -7.94334729],
                            [39.77164928, -7.94334729],
                            [39.77268838, -7.94362448],
                            [39.77331183, -7.94369379],
                            [39.7736582, -7.94300082],
                            [39.77351966, -7.94237715],
                            [39.77296548, -7.94182278],
                            [39.77282694, -7.94112981],
                            [39.77338112, -7.94085263],
                            [39.77435095, -7.94064475],
                            [39.7749744, -7.94022898],
                            [39.77573641, -7.94043687],
                            [39.77594422, -7.94106054],
                            [39.77559785, -7.9417535],
                            [39.77559785, -7.94251576],
                            [39.77580566, -7.94313943],
                            [39.77635984, -7.94341662],
                            [39.77698329, -7.94348592],
                            [39.77746821, -7.94293155],
                            [39.77774531, -7.94230788],
                            [39.77809167, -7.94168422],
                            [39.77836877, -7.94119915],
                            [39.77899223, -7.94050619],
                            [39.77982351, -7.9403676],
                            [39.78058551, -7.94106057],
                            [39.78065477, -7.94196142],
                            [39.78079332, -7.94272368],
                            [39.78100113, -7.94348594],
                            [39.78141676, -7.94410961],
                            [39.78176312, -7.94466399],
                            [39.78280222, -7.94438681],
                            [39.7833564, -7.94438681],
                            [39.78363349, -7.94397104],
                            [39.78391059, -7.94348596],
                            [39.78488041, -7.94327808],
                            [39.78536532, -7.94272371],
                            [39.78564242, -7.94223864],
                            [39.78633515, -7.94216935],
                            [39.78737424, -7.94244654],
                            [39.78806697, -7.94293162],
                            [39.78785915, -7.94348599],
                            [39.78716641, -7.94424824],
                            [39.7874435, -7.94494121],
                            [39.78765132, -7.94549558],
                            [39.78806694, -7.94646573],
                            [39.78827476, -7.94764377],
                            [39.78827475, -7.94819814],
                            [39.78806693, -7.94944548],
                            [39.78744346, -7.95048492],
                            [39.78702782, -7.95152436],
                            [39.78647363, -7.95228661],
                            [39.78612726, -7.95325676],
                            [39.78557307, -7.9543655],
                            [39.78474179, -7.95505846],
                            [39.78411832, -7.95616719],
                            [39.78349487, -7.95658297],
                            [39.78273287, -7.95706804],
                            [39.78240216, -7.95723344],
                            [39.78217868, -7.95734522],
                            [39.78148595, -7.9576917],
                            [39.78086249, -7.95796888],
                            [39.78044685, -7.95845395],
                            [39.78016975, -7.95921621],
                            [39.77975411, -7.95990917],
                            [39.77961556, -7.96087932],
                            [39.77933846, -7.96191876],
                            [39.77892282, -7.96288891],
                            [39.77885354, -7.96358187],
                            [39.77843789, -7.96413624],
                            [39.77781444, -7.96441342],
                            [39.77684462, -7.96524497],
                            [39.7755977, -7.96545285],
                            [39.77469715, -7.96607651],
                            [39.77365806, -7.96579932],
                            [39.77282678, -7.96545283],
                            [39.77213405, -7.96545283],
                            [39.7720775, -7.96537505],
                            [39.77157987, -7.96469056],
                            [39.77164915, -7.96365112],
                            [39.77213407, -7.96275027],
                            [39.77261898, -7.96205731],
                            [39.77282681, -7.96143364],
                            [39.77331172, -7.96101787],
                            [39.77365809, -7.9603942],
                            [39.77393519, -7.95970124],
                            [39.77372737, -7.95914687],
                            [39.77303465, -7.95831531],
                            [39.77220337, -7.95873108],
                            [39.77130282, -7.95900826],
                            [39.77040227, -7.95935474],
                            [39.76922463, -7.95935473],
                            [39.76804699, -7.95935472],
                            [39.76680008, -7.95921612],
                            [39.7652068, -7.95907752],
                            [39.76430626, -7.95893892],
                            [39.76347498, -7.95886962],
                            [39.76257444, -7.95859243],
                            [39.76153534, -7.95817664],
                            [39.76098116, -7.95789945],
                            [39.76028844, -7.95755297],
                            [39.75945716, -7.95727578],
                            [39.75883371, -7.95651351],
                            [39.7583488, -7.95595914],
                            [39.75758681, -7.95512758],
                            [39.75682481, -7.95450391],
                            [39.75592427, -7.95401883],
                            [39.75530081, -7.95367234],
                            [39.75453881, -7.95353374],
                            [39.75336117, -7.95353374],
                            [39.75273771, -7.95367232],
                            [39.75197571, -7.95394951],
                            [39.75107516, -7.95429598],
                            [39.75024388, -7.95464246],
                            [39.74878915, -7.95519682],
                            [39.74781932, -7.95575118],
                            [39.74594895, -7.95623625],
                            [39.74567186, -7.9569985],
                            [39.74532548, -7.95824584],
                            [39.74518693, -7.95942387],
                            [39.74490983, -7.96018613],
                            [39.74511765, -7.96067121],
                            [39.74518692, -7.96129487],
                            [39.74484055, -7.96164135],
                            [39.74504837, -7.96212643],
                            [39.74484055, -7.96240361],
                            [39.74484054, -7.96323517],
                            [39.74470199, -7.96385884],
                            [39.74456344, -7.9644825],
                            [39.74463271, -7.96517547],
                            [39.74484052, -7.96566054],
                            [39.74442488, -7.96600702],
                            [39.74421706, -7.96649209],
                            [39.74456342, -7.96711576],
                            [39.74428633, -7.96760083],
                            [39.74400923, -7.96864028],
                            [39.74400923, -7.96919465],
                            [39.74414777, -7.96967972],
                            [39.74393995, -7.97044198],
                            [39.7438014, -7.97085776],
                            [39.74359358, -7.97120424],
                            [39.74359357, -7.97182791],
                            [39.74324721, -7.9718972],
                            [39.74283156, -7.97279805],
                            [39.74262374, -7.97349101],
                            [39.74227737, -7.97411468],
                            [39.74206955, -7.97494623],
                            [39.74179245, -7.97570849],
                            [39.74158463, -7.97647075],
                            [39.74082262, -7.97702511],
                            [39.73999134, -7.97792596],
                            [39.73943715, -7.97834174],
                            [39.73895224, -7.97882681],
                            [39.7385366, -7.97993555],
                            [39.73867514, -7.9807671],
                            [39.73860586, -7.98194514],
                            [39.73839803, -7.98305388],
                            [39.73839802, -7.98423192],
                            [39.7381902, -7.98520207],
                            [39.73763601, -7.98617221],
                            [39.73715109, -7.98714236],
                            [39.73680472, -7.98783532],
                            [39.73618126, -7.98873617],
                            [39.73569635, -7.98949842],
                            [39.7352807, -7.99019138],
                            [39.73486506, -7.99109223],
                            [39.73458796, -7.9916466],
                            [39.73382596, -7.99233956],
                            [39.73313323, -7.99289393],
                            [39.73264831, -7.99351759],
                            [39.73209413, -7.99386407],
                            [39.73153994, -7.99393336],
                            [39.73105503, -7.99400265],
                            [39.73057012, -7.99372547],
                            [39.72973885, -7.99344828],
                            [39.72918467, -7.99330968],
                            [39.7288383, -7.99337897],
                            [39.72863048, -7.99414123],
                            [39.72849192, -7.99531927],
                            [39.72849192, -7.99601223],
                            [39.72814555, -7.99705167],
                            [39.72772991, -7.99795252],
                            [39.72724499, -7.99864548],
                            [39.7266908, -7.99926915],
                            [39.72606735, -7.99947703],
                            [39.72537462, -7.99968492],
                            [39.72468188, -7.9999621],
                            [39.72426625, -8.00010069],
                            [39.72364279, -8.00044717],
                            [39.7230886, -8.00072435],
                            [39.72267296, -8.00100153],
                            [39.72225732, -8.0015559],
                            [39.72225732, -8.00190238],
                            [39.72191095, -8.00217956],
                            [39.7217724, -8.00273393],
                            [39.7217724, -8.00321901],
                            [39.72198022, -8.00391197],
                            [39.7222573, -8.00439705],
                            [39.72274221, -8.00474353],
                            [39.72343494, -8.00488213],
                            [39.7230193, -8.00509002],
                            [39.72364276, -8.0055058],
                            [39.7237813, -8.00606017],
                            [39.72447403, -8.00612947],
                            [39.72495893, -8.00640666],
                            [39.72565166, -8.00675315],
                            [39.72599803, -8.00675315],
                            [39.72613657, -8.007654],
                            [39.72620583, -8.00841626],
                            [39.72606729, -8.00862415],
                            [39.72710638, -8.00876275],
                            [39.72759129, -8.00897064],
                            [39.72800693, -8.00897064],
                            [39.72814547, -8.0097329],
                            [39.72814546, -8.01028727],
                            [39.72863037, -8.01091095],
                            [39.72911528, -8.01035658],
                            [39.72973874, -8.01084166],
                            [39.73036219, -8.01104955],
                            [39.73050074, -8.01056448],
                            [39.7311242, -8.0101487],
                            [39.7314013, -8.00945574],
                            [39.73147057, -8.00876278],
                            [39.73133203, -8.00758474],
                            [39.73195549, -8.00758474],
                            [39.73237113, -8.00793123],
                            [39.7329253, -8.00862419],
                            [39.73306385, -8.00917857],
                            [39.7326482, -8.00994082],
                            [39.73257893, -8.01056449],
                            [39.7323711, -8.01132675],
                            [39.73209401, -8.0121583],
                            [39.73230182, -8.01271267],
                            [39.732094, -8.01312845],
                            [39.73230182, -8.01361353],
                            [39.73202472, -8.01382141],
                            [39.73223254, -8.01423719],
                            [39.73230181, -8.01506875],
                            [39.73313308, -8.01499946],
                            [39.73396436, -8.01499946],
                            [39.73444927, -8.01520736],
                            [39.7348649, -8.01562314],
                            [39.7356269, -8.01603892],
                            [39.73618108, -8.01693978],
                            [39.73638889, -8.01763274],
                            [39.73638889, -8.01818711],
                            [39.7358347, -8.01881077],
                            [39.73521124, -8.01922655],
                            [39.73451851, -8.01957303],
                            [39.73444924, -8.01985021],
                            [39.73479559, -8.02061247],
                            [39.73514195, -8.02151333],
                            [39.73514195, -8.02255277],
                            [39.73493412, -8.02324573],
                            [39.73500339, -8.02400799],
                            [39.73590393, -8.02504744],
                            [39.73652738, -8.02608689],
                            [39.73687374, -8.02671056],
                            [39.73715083, -8.02754212],
                            [39.73825919, -8.02858157],
                            [39.73895191, -8.02955173],
                            [39.73934494, -8.03006587],
                            [39.73985245, -8.03072977],
                            [39.74075299, -8.03169993],
                            [39.74103008, -8.03246219],
                            [39.74255408, -8.03308586],
                            [39.74331607, -8.03454109],
                            [39.74449371, -8.03516477],
                            [39.74518643, -8.03585773],
                            [39.74594843, -8.03648141],
                            [39.74684897, -8.03717437],
                            [39.74768025, -8.03752086],
                            [39.74774951, -8.03772875],
                            [39.74885788, -8.03738228],
                            [39.74975844, -8.03668932],
                            [39.74996626, -8.03592706],
                            [39.75079754, -8.03564988],
                            [39.75190591, -8.03461045],
                            [39.7524601, -8.0336403],
                            [39.75315283, -8.03329382],
                            [39.75356847, -8.03273945],
                            [39.75398412, -8.0319079],
                            [39.7545383, -8.0318386],
                            [39.75502321, -8.03121494],
                            [39.7555774, -8.03086846],
                            [39.75613159, -8.0302448],
                            [39.75723996, -8.02948255],
                            [39.75793269, -8.02878959],
                            [39.75834834, -8.02802733],
                            [39.75883324, -8.02768086],
                            [39.75959525, -8.02698789],
                            [39.75960227, -8.02697385],
                            [39.7600109, -8.02615634],
                            [39.76063436, -8.02532479],
                            [39.76118854, -8.02504761],
                            [39.76195055, -8.02421606],
                            [39.76229692, -8.0235924],
                            [39.76278184, -8.02296873],
                            [39.76402875, -8.02213718],
                            [39.7641673, -8.02144422],
                            [39.76513713, -8.02054337],
                            [39.7654835, -8.01964252],
                            [39.76617623, -8.01894956],
                            [39.76638406, -8.0183259],
                            [39.76686896, -8.01846449],
                            [39.76700751, -8.01777153],
                            [39.76735388, -8.01728646],
                            [39.76804661, -8.01693998],
                            [39.76873935, -8.01638562],
                            [39.76908572, -8.01562336],
                            [39.76950136, -8.01520758],
                            [39.76998627, -8.0149304],
                            [39.77067901, -8.01389096],
                            [39.77109464, -8.01347518],
                            [39.77137174, -8.0131287],
                            [39.77254939, -8.01222786],
                            [39.77275721, -8.01167349],
                            [39.77317285, -8.01111912],
                            [39.77372704, -8.01028757],
                            [39.77414268, -8.0096639],
                            [39.77462759, -8.00924813],
                            [39.77490469, -8.00869376],
                            [39.77532033, -8.00820869],
                            [39.77580525, -8.00751573],
                            [39.7764287, -8.00696136],
                            [39.77684435, -8.0062684],
                            [39.77732926, -8.00585263],
                            [39.77739853, -8.00536756],
                            [39.77767563, -8.00481319],
                            [39.77767563, -8.00405093],
                            [39.778022, -8.00363515],
                            [39.77836837, -8.00294219],
                            [39.77857619, -8.00245712],
                            [39.77913037, -8.00245712],
                            [39.77899183, -8.00148697],
                            [39.77961529, -8.00127908],
                            [39.77968457, -8.00065541],
                            [39.77996167, -7.99996246],
                            [39.78003095, -7.99892301],
                            [39.78072368, -7.99850724],
                            [39.78100077, -7.99802217],
                            [39.78113932, -7.99712131],
                            [39.78162424, -7.99642835],
                            [39.78210916, -7.99566609],
                            [39.78287116, -7.99552751],
                            [39.7832868, -7.99483455],
                            [39.78356389, -7.99448806],
                            [39.78418735, -7.9939337],
                            [39.78446445, -7.99365652],
                            [39.78515718, -7.99324075],
                            [39.78564209, -7.99261708],
                            [39.78550355, -7.9922013],
                            [39.7864041, -7.99130046],
                            [39.78681975, -7.99067679],
                            [39.78758175, -7.99026102],
                            [39.78778958, -7.98956805],
                            [39.78806667, -7.98901369],
                            [39.78862086, -7.98866721],
                            [39.78896723, -7.98783565],
                            [39.78965996, -7.98735059],
                            [39.79035269, -7.98644974],
                            [39.79056051, -7.98575677],
                            [39.79132252, -7.98568748],
                            [39.7913918, -7.98485593],
                            [39.79173816, -7.98450945],
                            [39.79312362, -7.98381649],
                            [39.79360854, -7.98312353],
                            [39.79374709, -7.98229198],
                            [39.79416273, -7.98173761],
                            [39.79464765, -7.98090606],
                            [39.7947862, -7.98035169],
                            [39.7958253, -7.97965873],
                            [39.7958253, -7.97917366],
                            [39.79575603, -7.97848069],
                            [39.79693367, -7.97792633],
                            [39.79714149, -7.97737196],
                            [39.79741859, -7.97640181],
                            [39.79790351, -7.97577815],
                            [39.79783424, -7.97522378],
                            [39.79831915, -7.97432293],
                            [39.79915043, -7.97390716],
                            [39.79921971, -7.9730063],
                            [39.79970463, -7.97196686],
                            [39.80032809, -7.97168968],
                            [39.80046664, -7.97085812],
                            [39.8010901, -7.96995728],
                            [39.80136719, -7.96919502],
                            [39.80157502, -7.96857135],
                            [39.80205994, -7.9676705],
                            [39.80205994, -7.96704684],
                            [39.80289122, -7.96656177],
                            [39.80289122, -7.96566091],
                            [39.80337614, -7.96489866],
                            [39.80358396, -7.9642057],
                            [39.8032376, -7.96420569],
                            [39.80393033, -7.96351273],
                            [39.80416153, -7.96300394],
                            [39.8042767, -7.96275048],
                            [39.80476162, -7.96205752],
                            [39.80517726, -7.96115667],
                            [39.8055929, -7.96018652],
                            [39.80559291, -7.95956285],
                            [39.80573146, -7.9587313],
                            [39.80587001, -7.95783045],
                            [39.80607784, -7.9569989],
                            [39.80614711, -7.95616734],
                            [39.80607785, -7.95519719],
                            [39.80580076, -7.95415774],
                            [39.8058442, -7.95403824],
                            [39.80607786, -7.95339548],
                            [39.80580077, -7.95277181],
                            [39.80538514, -7.95187096],
                            [39.80510805, -7.95124729],
                            [39.80483097, -7.95034644],
                            [39.80413824, -7.94958417],
                            [39.80358406, -7.9490991],
                            [39.80358406, -7.94854472],
                            [39.8035148, -7.94757457],
                            [39.80296062, -7.94681231],
                            [39.80275281, -7.94584216],
                            [39.80268354, -7.94480272],
                            [39.80212936, -7.94424834],
                            [39.80150591, -7.94376326],
                            [39.80095173, -7.94348607],
                            [39.80018972, -7.94334747],
                            [39.79915063, -7.9428624],
                            [39.79859646, -7.94210013],
                            [39.79804228, -7.94147646],
                            [39.79714173, -7.94099138],
                            [39.79672609, -7.94092208],
                            [39.79610264, -7.9405063],
                            [39.79499428, -7.9403677],
                            [39.79423228, -7.9401598],
                            [39.79347027, -7.9401598],
                            [39.79270827, -7.93981331],
                            [39.79159991, -7.93953612],
                            [39.790353, -7.93925892],
                            [39.78952172, -7.93912033],
                            [39.78834409, -7.93891244],
                            [39.78785918, -7.93856595],
                            [39.78716645, -7.93821946],
                            [39.78647372, -7.93780368],
                            [39.785781, -7.93794227],
                            [39.78515754, -7.93808086],
                            [39.78453408, -7.93794226],
                            [39.78384135, -7.93787296],
                            [39.78307935, -7.93752647],
                            [39.78259445, -7.93745717],
                            [39.78238663, -7.9369721],
                            [39.78197099, -7.93641772],
                            [39.78176318, -7.93634843],
                            [39.78113972, -7.93551687],
                            [39.78093191, -7.93530897],
                            [39.78037773, -7.9348239],
                            [39.77989282, -7.93440811],
                            [39.77933864, -7.93406163],
                            [39.77878446, -7.93371515],
                            [39.77850737, -7.93336866],
                            [39.77795319, -7.93274499],
                            [39.77732973, -7.93239851],
                            [39.77712192, -7.93191343],
                            [39.77635992, -7.93142835],
                            [39.77587502, -7.93066609],
                            [39.7756672, -7.93004242],
                            [39.7756672, -7.92969594],
                            [39.77545939, -7.92941875],
                            [39.77497448, -7.92907226],
                            [39.77504375, -7.92858719],
                            [39.77525158, -7.92782493],
                            [39.77504376, -7.92768634],
                            [39.77455885, -7.92747845],
                            [39.77421249, -7.92692408],
                            [39.77442031, -7.926439],
                            [39.77407395, -7.92567674],
                            [39.77407396, -7.9246373],
                            [39.77421251, -7.92394433],
                            [39.77421251, -7.92325137],
                            [39.77442034, -7.92235052],
                            [39.77428179, -7.92172685],
                            [39.77448962, -7.92117248],
                            [39.7748343, -7.92054037],
                            [39.77462818, -7.91950937],
                        ],
                        [
                            [39.74037881, -8.04864057],
                            [39.74040652, -8.04867752],
                            [39.74068361, -8.04853893],
                            [39.74037881, -8.04864057],
                        ],
                        [
                            [39.73957525, -8.04756878],
                            [39.73839762, -8.04618284],
                            [39.73728926, -8.04465832],
                            [39.73638872, -8.04341098],
                            [39.73534964, -8.04223293],
                            [39.73410273, -8.04070841],
                            [39.73327147, -8.03959966],
                            [39.73216311, -8.0386295],
                            [39.73077766, -8.03696638],
                            [39.72994639, -8.03585763],
                            [39.72869949, -8.03461029],
                            [39.72772967, -8.03357084],
                            [39.72655204, -8.0323235],
                            [39.72558223, -8.03093757],
                            [39.72391968, -8.03079896],
                            [39.72294986, -8.03093755],
                            [39.72225713, -8.03079895],
                            [39.72066386, -8.02996739],
                            [39.71893204, -8.02892793],
                            [39.71747732, -8.02761129],
                            [39.71609187, -8.02698761],
                            [39.71512205, -8.02657183],
                            [39.71325169, -8.02553237],
                            [39.71207405, -8.02497799],
                            [39.71103496, -8.02421573],
                            [39.71061933, -8.02317628],
                            [39.71041152, -8.02234472],
                            [39.70951098, -8.02130527],
                            [39.70867971, -8.02033512],
                            [39.70812554, -8.01894919],
                            [39.707987, -8.01818693],
                            [39.707987, -8.01728607],
                            [39.70784846, -8.01617733],
                            [39.70770992, -8.01576155],
                            [39.70748897, -8.0153195],
                            [39.70729429, -8.01492999],
                            [39.70680938, -8.01430632],
                            [39.70632448, -8.01333617],
                            [39.70570103, -8.01264321],
                            [39.70583958, -8.01195024],
                            [39.70563176, -8.01208883],
                            [39.70486976, -8.01174235],
                            [39.70480049, -8.01111868],
                            [39.70459267, -8.01098009],
                            [39.70403849, -8.01077219],
                            [39.70410776, -8.01035642],
                            [39.70341503, -8.01028711],
                            [39.70355358, -8.01007923],
                            [39.70362286, -8.00945556],
                            [39.70355359, -8.00924767],
                            [39.7032765, -8.00876259],
                            [39.70293014, -8.00820822],
                            [39.70251451, -8.00730737],
                            [39.70209887, -8.00703018],
                            [39.70140614, -8.00668369],
                            [39.7005056, -8.00675298],
                            [39.70029777, -8.00744594],
                            [39.6999514, -8.00841609],
                            [39.69988212, -8.00931694],
                            [39.69995139, -8.01035639],
                            [39.69925865, -8.01132653],
                            [39.6991201, -8.01250457],
                            [39.69863518, -8.01340542],
                            [39.69815027, -8.01416768],
                            [39.69745754, -8.01444486],
                            [39.69662626, -8.01492993],
                            [39.6960028, -8.0152764],
                            [39.6952408, -8.01548429],
                            [39.69447879, -8.01562287],
                            [39.69371679, -8.01562287],
                            [39.69260843, -8.01555357],
                            [39.69163861, -8.01527637],
                            [39.69032242, -8.01499918],
                            [39.6893526, -8.01506847],
                            [39.68925867, -8.0150626],
                            [39.68824424, -8.01499917],
                            [39.68658169, -8.01520705],
                            [39.68568113, -8.01583071],
                            [39.68554258, -8.01638508],
                            [39.68512695, -8.01666226],
                            [39.68519621, -8.01756311],
                            [39.68484984, -8.01811748],
                            [39.68464202, -8.01853326],
                            [39.68429566, -8.01908763],
                            [39.68401856, -8.01998848],
                            [39.68401855, -8.02047355],
                            [39.68353364, -8.02102792],
                            [39.68353364, -8.02151299],
                            [39.68297945, -8.02206736],
                            [39.68270235, -8.02269102],
                            [39.68235599, -8.02296821],
                            [39.68221744, -8.02324539],
                            [39.68235598, -8.02414625],
                            [39.68207889, -8.02476991],
                            [39.68207888, -8.02608654],
                            [39.68194033, -8.0268488],
                            [39.68180177, -8.02837332],
                            [39.68187104, -8.02941276],
                            [39.6827023, -8.0306601],
                            [39.68297939, -8.03142236],
                            [39.68346429, -8.03246181],
                            [39.68374138, -8.03329337],
                            [39.68415701, -8.03398634],
                            [39.68415701, -8.03481789],
                            [39.68457264, -8.03606523],
                            [39.68478045, -8.03634242],
                            [39.68491899, -8.03745116],
                            [39.6854039, -8.03835201],
                            [39.68547317, -8.0386985],
                            [39.68575025, -8.03966865],
                            [39.6866508, -8.04022302],
                            [39.68734353, -8.03987655],
                            [39.6876899, -8.03932218],
                            [39.68831335, -8.0389064],
                            [39.68876671, -8.03855368],
                            [39.68893681, -8.03842133],
                            [39.68935245, -8.03800556],
                            [39.68921391, -8.03745119],
                            [39.6890061, -8.03682752],
                            [39.68949101, -8.03641174],
                            [39.68942174, -8.03613456],
                            [39.6892832, -8.03558019],
                            [39.68921393, -8.03474863],
                            [39.68990666, -8.03502582],
                            [39.69039156, -8.0354416],
                            [39.69094574, -8.03627316],
                            [39.69087646, -8.03696612],
                            [39.69066864, -8.03807486],
                            [39.691015, -8.03876783],
                            [39.69101499, -8.03966868],
                            [39.69108426, -8.04043094],
                            [39.69101498, -8.04147039],
                            [39.6904608, -8.04153968],
                            [39.68962952, -8.04195545],
                            [39.68969879, -8.04244053],
                            [39.68997588, -8.04334138],
                            [39.69094569, -8.04396505],
                            [39.69149988, -8.04368787],
                            [39.69219261, -8.04292562],
                            [39.69288534, -8.04271773],
                            [39.69371662, -8.04202477],
                            [39.69440936, -8.04098533],
                            [39.69517136, -8.04036167],
                            [39.69579482, -8.0398766],
                            [39.69648755, -8.03959942],
                            [39.6973881, -8.0401538],
                            [39.69856573, -8.04091606],
                            [39.69918918, -8.04195551],
                            [39.69995118, -8.04306426],
                            [39.70022826, -8.04389582],
                            [39.70043607, -8.04486597],
                            [39.70022825, -8.04576682],
                            [39.6998126, -8.04694485],
                            [39.69946624, -8.04756852],
                            [39.69911987, -8.04833077],
                            [39.69828859, -8.04909303],
                            [39.69766513, -8.04985528],
                            [39.69759585, -8.05068684],
                            [39.69815002, -8.05179559],
                            [39.6978743, -8.05240237],
                            [39.69780365, -8.05255784],
                            [39.6976651, -8.0533201],
                            [39.69752655, -8.05422095],
                            [39.69669527, -8.05539899],
                            [39.69614108, -8.05650772],
                            [39.69586398, -8.05733928],
                            [39.69544834, -8.05803224],
                            [39.69475561, -8.0585866],
                            [39.69406287, -8.05927956],
                            [39.69323159, -8.05976463],
                            [39.69240031, -8.06045759],
                            [39.69198467, -8.06121985],
                            [39.69094558, -8.06163562],
                            [39.68997575, -8.0619128],
                            [39.68900593, -8.06232857],
                            [39.68886738, -8.06288294],
                            [39.68865956, -8.0635066],
                            [39.68796683, -8.06378378],
                            [39.68789755, -8.06440745],
                            [39.68755118, -8.06454604],
                            [39.68755118, -8.065239],
                            [39.68699699, -8.06572408],
                            [39.6867199, -8.06676352],
                            [39.68623498, -8.06745648],
                            [39.68581934, -8.06773366],
                            [39.6854037, -8.06828803],
                            [39.68498806, -8.06856521],
                            [39.68484952, -8.06835732],
                            [39.68478024, -8.06814943],
                            [39.68443388, -8.06801084],
                            [39.6838797, -8.06794154],
                            [39.68339478, -8.0685652],
                            [39.68339478, -8.06918887],
                            [39.6833255, -8.07008972],
                            [39.68325622, -8.07085198],
                            [39.68290985, -8.07168353],
                            [39.68249421, -8.07244579],
                            [39.68207857, -8.07320805],
                            [39.68194002, -8.07390101],
                            [39.68110874, -8.07459397],
                            [39.68055455, -8.07549482],
                            [39.67958472, -8.07625707],
                            [39.67868417, -8.07695003],
                            [39.67812999, -8.0774351],
                            [39.67729871, -8.07805876],
                            [39.67612107, -8.07826664],
                            [39.67584398, -8.07785086],
                            [39.67549761, -8.07757367],
                            [39.67466634, -8.07750437],
                            [39.67404288, -8.07757366],
                            [39.67328088, -8.07792014],
                            [39.67272669, -8.07833592],
                            [39.67286523, -8.07909818],
                            [39.6729345, -8.07986044],
                            [39.67231104, -8.08027621],
                            [39.67154904, -8.08089987],
                            [39.67064849, -8.08103846],
                            [39.67002503, -8.08145423],
                            [39.66898593, -8.08180071],
                            [39.66836247, -8.08214718],
                            [39.6667692, -8.08221647],
                            [39.66600719, -8.08297873],
                            [39.66524519, -8.08304802],
                            [39.66420609, -8.0833252],
                            [39.66351336, -8.08360238],
                            [39.66295918, -8.08408745],
                            [39.66247427, -8.08401815],
                            [39.66247427, -8.08360237],
                            [39.66212791, -8.083048],
                            [39.66212791, -8.08263222],
                            [39.66150446, -8.08235503],
                            [39.66039609, -8.08228573],
                            [39.65956481, -8.08297868],
                            [39.65859499, -8.08360234],
                            [39.65776371, -8.08401812],
                            [39.65720952, -8.08464178],
                            [39.65665533, -8.08533474],
                            [39.65630896, -8.086097],
                            [39.65547768, -8.08665136],
                            [39.65499277, -8.08762151],
                            [39.65423076, -8.08907673],
                            [39.65367657, -8.08935391],
                            [39.65360729, -8.08997757],
                            [39.65395366, -8.09018547],
                            [39.65381511, -8.09073983],
                            [39.65339947, -8.09087842],
                            [39.65374583, -8.09164069],
                            [39.65339946, -8.09198717],
                            [39.65284528, -8.09212576],
                            [39.65333019, -8.09254154],
                            [39.65305309, -8.09302661],
                            [39.65312236, -8.09351168],
                            [39.65319163, -8.09371957],
                            [39.65333018, -8.09413535],
                            [39.65277599, -8.09448183],
                            [39.65263744, -8.0951055],
                            [39.65249889, -8.09614494],
                            [39.65346871, -8.09614495],
                            [39.65436925, -8.09656073],
                            [39.65533907, -8.09690722],
                            [39.65623962, -8.09690722],
                            [39.6567938, -8.09690723],
                            [39.65720944, -8.09669934],
                            [39.65776363, -8.09649146],
                            [39.65838708, -8.09642216],
                            [39.65907981, -8.09656076],
                            [39.65949544, -8.09718443],
                            [39.65894126, -8.0976695],
                            [39.65894125, -8.09815458],
                            [39.65914907, -8.09857035],
                            [39.65928761, -8.09905543],
                            [39.65963397, -8.09954051],
                            [39.66011888, -8.09954051],
                            [39.66101943, -8.09995629],
                            [39.66164289, -8.099887],
                            [39.66233562, -8.09967912],
                            [39.66295907, -8.09954053],
                            [39.66344399, -8.09926335],
                            [39.66399817, -8.09898616],
                            [39.66462163, -8.09870898],
                            [39.66517581, -8.09836251],
                            [39.66593782, -8.09829321],
                            [39.66669982, -8.0986397],
                            [39.66746182, -8.09884759],
                            [39.66829309, -8.09919408],
                            [39.66912436, -8.09967916],
                            [39.66954, -8.09988705],
                            [39.66988636, -8.10002565],
                            [39.67016345, -8.10016424],
                            [39.66995563, -8.10071861],
                            [39.6700249, -8.1009958],
                            [39.67037126, -8.10134228],
                            [39.67064835, -8.10168876],
                            [39.67106399, -8.10189666],
                            [39.67106399, -8.10155017],
                            [39.67127181, -8.10141158],
                            [39.67182599, -8.10175807],
                            [39.67217236, -8.10134229],
                            [39.672588, -8.1013423],
                            [39.67328073, -8.10113441],
                            [39.67355782, -8.10051075],
                            [39.67390419, -8.10016427],
                            [39.67397347, -8.0996099],
                            [39.6743891, -8.09933271],
                            [39.67515111, -8.09884764],
                            [39.67549748, -8.09829328],
                            [39.67577457, -8.09801609],
                            [39.67619021, -8.0980161],
                            [39.6764673, -8.0980161],
                            [39.67695221, -8.09787751],
                            [39.67736785, -8.09746173],
                            [39.67771422, -8.09725385],
                            [39.6782684, -8.09718455],
                            [39.67847622, -8.09718455],
                            [39.67944604, -8.09704597],
                            [39.67979241, -8.0965609],
                            [39.68013877, -8.09642231],
                            [39.68027732, -8.0965609],
                            [39.68097005, -8.09600653],
                            [39.68166278, -8.09614513],
                            [39.68256333, -8.09524428],
                            [39.6829097, -8.09524429],
                            [39.68311752, -8.09524429],
                            [39.68381025, -8.09524429],
                            [39.68457225, -8.09489782],
                            [39.68478007, -8.09510571],
                            [39.68568062, -8.09510571],
                            [39.68595771, -8.09475923],
                            [39.68678899, -8.09455135],
                            [39.68706608, -8.09441276],
                            [39.68699681, -8.09441276],
                            [39.68782808, -8.09413558],
                            [39.68838227, -8.09365051],
                            [39.689075, -8.09358121],
                            [39.68949064, -8.09316544],
                            [39.69004482, -8.09295755],
                            [39.69080683, -8.09281897],
                            [39.69226156, -8.09226461],
                            [39.69253866, -8.09171024],
                            [39.69281575, -8.09157165],
                            [39.69330066, -8.09122517],
                            [39.69392412, -8.09143306],
                            [39.69468612, -8.09087869],
                            [39.69496322, -8.09046292],
                            [39.69468612, -8.09032432],
                            [39.69447831, -8.08983925],
                            [39.69378558, -8.08990854],
                            [39.6939934, -8.08942347],
                            [39.69378559, -8.08893839],
                            [39.69371632, -8.08838402],
                            [39.69406268, -8.08796824],
                            [39.6937856, -8.08748317],
                            [39.6937856, -8.0868595],
                            [39.69406269, -8.08637443],
                            [39.69364706, -8.08582006],
                            [39.69399343, -8.08588935],
                            [39.6943398, -8.0849885],
                            [39.69475544, -8.08457273],
                            [39.69551744, -8.08429555],
                            [39.69607162, -8.08394907],
                            [39.69683363, -8.08394908],
                            [39.69780345, -8.08408767],
                            [39.69835763, -8.08401838],
                            [39.69932745, -8.08443417],
                            [39.70078218, -8.08471136],
                            [39.70133636, -8.08478066],
                            [39.70299891, -8.08498856],
                            [39.70389946, -8.08457279],
                            [39.70480001, -8.08401842],
                            [39.70549275, -8.08311758],
                            [39.70625475, -8.08207814],
                            [39.70597767, -8.08152376],
                            [39.70500785, -8.08090009],
                            [39.70466149, -8.08027642],
                            [39.70486931, -8.07944487],
                            [39.70563132, -8.0788212],
                            [39.70639333, -8.07819754],
                            [39.70701678, -8.07819755],
                            [39.7079866, -8.07792037],
                            [39.70861006, -8.07750459],
                            [39.70865707, -8.07749832],
                            [39.70964916, -8.07736601],
                            [39.71089607, -8.07701953],
                            [39.71103462, -8.07688094],
                            [39.71179662, -8.07639587],
                            [39.71297427, -8.07584151],
                            [39.71394409, -8.07535644],
                            [39.71449828, -8.07459419],
                            [39.71539883, -8.07417841],
                            [39.71560665, -8.07362404],
                            [39.71629938, -8.07334686],
                            [39.71643793, -8.0726539],
                            [39.71650721, -8.07203023],
                            [39.71699212, -8.07154516],
                            [39.71699213, -8.0709215],
                            [39.71775413, -8.07071361],
                            [39.71823905, -8.07015924],
                            [39.71844687, -8.0691198],
                            [39.71872397, -8.06877332],
                            [39.71934743, -8.06780318],
                            [39.71962453, -8.06683303],
                            [39.71920889, -8.06655584],
                            [39.71927817, -8.06558569],
                            [39.71948599, -8.06482343],
                            [39.71983236, -8.06468484],
                            [39.720248, -8.06413048],
                            [39.72010946, -8.0635761],
                            [39.72038655, -8.06302174],
                            [39.72031728, -8.06225948],
                            [39.7208022, -8.06135863],
                            [39.72149493, -8.06149722],
                            [39.72142566, -8.06087356],
                            [39.72197985, -8.06038849],
                            [39.72281112, -8.06052708],
                            [39.72385021, -8.06059639],
                            [39.72419658, -8.05983413],
                            [39.72461222, -8.05927976],
                            [39.72482005, -8.05872539],
                            [39.72530496, -8.05817102],
                            [39.72579395, -8.05773079],
                            [39.72599769, -8.05754736],
                            [39.72669042, -8.05733948],
                            [39.7275217, -8.05657722],
                            [39.72800662, -8.05588426],
                            [39.72869935, -8.05616145],
                            [39.72911499, -8.05588427],
                            [39.72939208, -8.05574568],
                            [39.72994626, -8.0554685],
                            [39.73056972, -8.05512202],
                            [39.73126246, -8.05435976],
                            [39.73147028, -8.0537361],
                            [39.73133174, -8.05332032],
                            [39.73160883, -8.05325103],
                            [39.73230156, -8.05297384],
                            [39.73250938, -8.05304314],
                            [39.73299429, -8.05304315],
                            [39.73375629, -8.05290456],
                            [39.73417193, -8.05235019],
                            [39.73458757, -8.05193441],
                            [39.73562667, -8.05144935],
                            [39.73652722, -8.05103358],
                            [39.73694286, -8.05040991],
                            [39.73749705, -8.04985554],
                            [39.73832833, -8.04937047],
                            [39.73909033, -8.0489547],
                            [39.74006015, -8.04874682],
                            [39.74037881, -8.04864057],
                            [39.73957525, -8.04756878],
                        ],
                        [
                            [39.40412725, -8.09657651],
                            [39.40496666, -8.09576802],
                            [39.40580605, -8.09497506],
                            [39.4062724, -8.09421323],
                            [39.40675427, -8.09357576],
                            [39.40680614, -8.09350164],
                            [39.40740713, -8.09264288],
                            [39.4080911, -8.09192769],
                            [39.408402, -8.09169446],
                            [39.40877506, -8.0918344],
                            [39.40913258, -8.09157008],
                            [39.40927248, -8.0911503],
                            [39.40947457, -8.09082379],
                            [39.40986318, -8.09035734],
                            [39.41034505, -8.09009303],
                            [39.41082692, -8.09010858],
                            [39.41132436, -8.09035735],
                            [39.41157306, -8.09023297],
                            [39.41163524, -8.08987537],
                            [39.41161969, -8.08951777],
                            [39.41151088, -8.08906688],
                            [39.41130882, -8.08872483],
                            [39.411029, -8.08825838],
                            [39.41073367, -8.08779195],
                            [39.41068703, -8.08748099],
                            [39.41071813, -8.08718557],
                            [39.41065596, -8.08657921],
                            [39.41043833, -8.08603503],
                            [39.41042727, -8.08601905],
                            [39.41015853, -8.08563078],
                            [39.40984765, -8.08522653],
                            [39.40955229, -8.08462017],
                            [39.40931915, -8.08426257],
                            [39.40921034, -8.08365619],
                            [39.40925697, -8.08340743],
                            [39.40914816, -8.08306538],
                            [39.40874401, -8.08287879],
                            [39.4083243, -8.08289434],
                            [39.40801342, -8.08303427],
                            [39.40756263, -8.0831742],
                            [39.40728283, -8.08334522],
                            [39.40703412, -8.083594],
                            [39.40670767, -8.08401378],
                            [39.40658332, -8.08430919],
                            [39.40655224, -8.08483782],
                            [39.40652114, -8.08558413],
                            [39.40644341, -8.08648589],
                            [39.40653668, -8.0871078],
                            [39.40656777, -8.08783858],
                            [39.40650558, -8.08869371],
                            [39.40628796, -8.08951775],
                            [39.4058838, -8.09054391],
                            [39.40572835, -8.09135239],
                            [39.4054641, -8.09214535],
                            [39.40524648, -8.09248739],
                            [39.40523093, -8.09200541],
                            [39.405262, -8.09161671],
                            [39.40524649, -8.09068382],
                            [39.40530864, -8.08981316],
                            [39.40532421, -8.08916015],
                            [39.4052154, -8.08856932],
                            [39.40519986, -8.08813397],
                            [39.40516877, -8.08768309],
                            [39.40505996, -8.0872322],
                            [39.40495115, -8.08703008],
                            [39.40465581, -8.08693678],
                            [39.404376, -8.0869057],
                            [39.40406511, -8.08689014],
                            [39.40372314, -8.08693678],
                            [39.40335006, -8.08707671],
                            [39.40317908, -8.0872944],
                            [39.40297587, -8.08784743],
                            [39.40294592, -8.08776082],
                            [39.40289928, -8.08816506],
                            [39.40297701, -8.08867814],
                            [39.40307027, -8.08917567],
                            [39.40316351, -8.08961102],
                            [39.40327233, -8.08998417],
                            [39.40348996, -8.09041952],
                            [39.40352105, -8.09097925],
                            [39.40339668, -8.09161671],
                            [39.40324125, -8.0920365],
                            [39.40285263, -8.09237855],
                            [39.40241739, -8.09256513],
                            [39.40213758, -8.09253403],
                            [39.40174897, -8.09272061],
                            [39.40160907, -8.09303156],
                            [39.40146917, -8.09351355],
                            [39.40125154, -8.09384005],
                            [39.40100284, -8.09413546],
                            [39.40073858, -8.09435313],
                            [39.40033443, -8.09446196],
                            [39.3999769, -8.09463299],
                            [39.39975928, -8.09485067],
                            [39.39957274, -8.09520826],
                            [39.39915303, -8.09553476],
                            [39.39879552, -8.09576799],
                            [39.3985779, -8.09614114],
                            [39.39853126, -8.09649873],
                            [39.39846909, -8.0967164],
                            [39.39820483, -8.0968097],
                            [39.39790948, -8.09682524],
                            [39.39752087, -8.09690298],
                            [39.39725662, -8.09688742],
                            [39.39686801, -8.09687187],
                            [39.39665038, -8.09702737],
                            [39.39671256, -8.09726057],
                            [39.39683691, -8.09750934],
                            [39.39693017, -8.0977892],
                            [39.39700789, -8.09799133],
                            [39.39708562, -8.0982401],
                            [39.39703899, -8.09844221],
                            [39.396868, -8.09859769],
                            [39.39671255, -8.09881537],
                            [39.39637058, -8.09904859],
                            [39.3961374, -8.09929735],
                            [39.39577988, -8.09979486],
                            [39.39528246, -8.10055674],
                            [39.39467622, -8.10130303],
                            [39.3943187, -8.10181611],
                            [39.39396118, -8.10242247],
                            [39.39343265, -8.10288891],
                            [39.39302851, -8.10304439],
                            [39.3927176, -8.10304439],
                            [39.39245336, -8.10324651],
                            [39.39225129, -8.10354192],
                            [39.3921891, -8.10388397],
                            [39.39222019, -8.10413273],
                            [39.39236009, -8.10425713],
                            [39.39270206, -8.10449035],
                            [39.3931684, -8.10469247],
                            [39.39371246, -8.10484795],
                            [39.39411661, -8.10494124],
                            [39.39438087, -8.10506562],
                            [39.39478502, -8.10517447],
                            [39.39504928, -8.10520555],
                            [39.3952669, -8.10497234],
                            [39.39553114, -8.10469248],
                            [39.39584205, -8.10450591],
                            [39.39637056, -8.10433488],
                            [39.39728768, -8.10393064],
                            [39.39809599, -8.10335536],
                            [39.39848459, -8.10267125],
                            [39.3989043, -8.10215818],
                            [39.39949499, -8.10141188],
                            [39.40016341, -8.10071223],
                            [39.40075409, -8.09999703],
                            [39.40120487, -8.0997638],
                            [39.40146914, -8.09971717],
                            [39.40195102, -8.09904861],
                            [39.40261943, -8.09820902],
                            [39.40330338, -8.09740053],
                            [39.40412725, -8.09657651],
                        ],
                        [
                            [39.38512215, -8.10188552],
                            [39.38512216, -8.101617],
                            [39.3851605, -8.10145078],
                            [39.38528834, -8.10134848],
                            [39.38548049, -8.10129508],
                            [39.38548971, -8.10130738],
                            [39.38552173, -8.10159569],
                            [39.38552173, -8.10181993],
                            [39.38563382, -8.10209222],
                            [39.38582598, -8.10228442],
                            [39.38593807, -8.1024606],
                            [39.38598612, -8.1026368],
                            [39.38613024, -8.10286103],
                            [39.38629038, -8.10303722],
                            [39.38661064, -8.10319739],
                            [39.38696294, -8.1031974],
                            [39.38739529, -8.1031974],
                            [39.38771556, -8.10316536],
                            [39.38808388, -8.10300521],
                            [39.38832409, -8.102813],
                            [39.38845219, -8.1026208],
                            [39.38842016, -8.10238053],
                            [39.38838813, -8.10214028],
                            [39.38830806, -8.10193207],
                            [39.38835612, -8.10175587],
                            [39.38843617, -8.10161172],
                            [39.38861233, -8.10132341],
                            [39.38885251, -8.10105112],
                            [39.38909272, -8.10082689],
                            [39.38938096, -8.10057062],
                            [39.38958914, -8.10033036],
                            [39.38971725, -8.10007409],
                            [39.38986138, -8.09980181],
                            [39.38992543, -8.09956155],
                            [39.39003752, -8.09932128],
                            [39.39019765, -8.09898494],
                            [39.39048591, -8.09856849],
                            [39.39069407, -8.09828019],
                            [39.39091825, -8.09821613],
                            [39.39101436, -8.09797587],
                            [39.39095029, -8.0977196],
                            [39.39080618, -8.09763952],
                            [39.39056597, -8.09767156],
                            [39.39035781, -8.09787977],
                            [39.39016563, -8.09832824],
                            [39.38987739, -8.09874468],
                            [39.38965321, -8.09911307],
                            [39.389397, -8.09952951],
                            [39.38915679, -8.09984985],
                            [39.38886855, -8.09992994],
                            [39.38861234, -8.09984984],
                            [39.38838814, -8.09984984],
                            [39.38819598, -8.10002603],
                            [39.38797178, -8.10039443],
                            [39.38789172, -8.10073077],
                            [39.38773159, -8.10105111],
                            [39.3873953, -8.10122731],
                            [39.38709105, -8.10122731],
                            [39.3869149, -8.10106714],
                            [39.38693092, -8.10079484],
                            [39.3871551, -8.10057061],
                            [39.38734726, -8.10028231],
                            [39.38750741, -8.10002603],
                            [39.38766755, -8.0997057],
                            [39.38768355, -8.09928925],
                            [39.38787572, -8.09882474],
                            [39.38798781, -8.09858451],
                            [39.38805187, -8.09820008],
                            [39.3880839, -8.09786374],
                            [39.38824403, -8.09755942],
                            [39.38829208, -8.09725508],
                            [39.3880839, -8.09723908],
                            [39.38792377, -8.09736721],
                            [39.38787572, -8.09752738],
                            [39.38774762, -8.09765551],
                            [39.38755545, -8.09776764],
                            [39.38736329, -8.09778364],
                            [39.38717114, -8.09779968],
                            [39.3871564, -8.09780044],
                            [39.38723151, -8.09751244],
                            [39.38734235, -8.09727961],
                            [39.38735935, -8.09724392],
                            [39.38746161, -8.09705213],
                            [39.38769175, -8.09674523],
                            [39.38794743, -8.09650228],
                            [39.38819031, -8.09624655],
                            [39.38845877, -8.09606755],
                            [39.38861218, -8.09587575],
                            [39.38870167, -8.09558163],
                            [39.38881674, -8.09526198],
                            [39.3889318, -8.09492953],
                            [39.38904686, -8.09455871],
                            [39.38922582, -8.09430296],
                            [39.38939202, -8.09403444],
                            [39.38953264, -8.09376592],
                            [39.38966048, -8.09347184],
                            [39.3898011, -8.0932033],
                            [39.3899673, -8.0929092],
                            [39.39019742, -8.0926279],
                            [39.39045308, -8.09232102],
                            [39.39064485, -8.09198855],
                            [39.3908366, -8.09160496],
                            [39.39097723, -8.09128529],
                            [39.39106673, -8.09097839],
                            [39.39122013, -8.09067153],
                            [39.39139911, -8.09035186],
                            [39.39153973, -8.09010891],
                            [39.39165479, -8.08984037],
                            [39.39180819, -8.08957185],
                            [39.3919616, -8.08929055],
                            [39.39207665, -8.08902204],
                            [39.39221729, -8.08872792],
                            [39.39235791, -8.0884722],
                            [39.39249853, -8.08829318],
                            [39.39262637, -8.0879863],
                            [39.39283091, -8.08764105],
                            [39.39306103, -8.08724466],
                            [39.39320165, -8.08692499],
                            [39.39336785, -8.08663091],
                            [39.39352127, -8.08633681],
                            [39.39373859, -8.08608107],
                            [39.39395591, -8.08590206],
                            [39.39405818, -8.08569747],
                            [39.39386643, -8.08558239],
                            [39.39366189, -8.08549289],
                            [39.39341899, -8.08544174],
                            [39.39320166, -8.08544173],
                            [39.39312496, -8.0854673],
                            [39.39309938, -8.08564632],
                            [39.39312495, -8.08594042],
                            [39.39312495, -8.08619614],
                            [39.39304824, -8.08640072],
                            [39.39284371, -8.08661812],
                            [39.39258803, -8.08682271],
                            [39.39233236, -8.08688664],
                            [39.39212782, -8.08698892],
                            [39.39191048, -8.08711679],
                            [39.39174428, -8.08729581],
                            [39.39150139, -8.08757711],
                            [39.3913352, -8.08778171],
                            [39.39114345, -8.08801187],
                            [39.39086219, -8.08834433],
                            [39.39068321, -8.08856169],
                            [39.39049145, -8.08868956],
                            [39.39024856, -8.08883023],
                            [39.39000567, -8.08895809],
                            [39.38986505, -8.08902203],
                            [39.38969884, -8.08902202],
                            [39.3895838, -8.08893252],
                            [39.38954544, -8.08877906],
                            [39.38957101, -8.08861284],
                            [39.38964772, -8.08843382],
                            [39.38976276, -8.0882548],
                            [39.38986506, -8.08808859],
                            [39.38986506, -8.087884],
                            [39.38986506, -8.08762825],
                            [39.3899034, -8.08725743],
                            [39.38998012, -8.08693778],
                            [39.39005682, -8.08660533],
                            [39.39008239, -8.08637514],
                            [39.39009517, -8.08608106],
                            [39.39004405, -8.08582532],
                            [39.38992899, -8.08572302],
                            [39.38977556, -8.08569745],
                            [39.38960938, -8.08573582],
                            [39.38951988, -8.0858381],
                            [39.38946876, -8.08597876],
                            [39.38936648, -8.08604269],
                            [39.38925142, -8.08599154],
                            [39.3892003, -8.08588925],
                            [39.38925142, -8.0857486],
                            [39.3893537, -8.08556959],
                            [39.38944318, -8.08542893],
                            [39.38937928, -8.08530105],
                            [39.3891875, -8.08530105],
                            [39.38900854, -8.08540337],
                            [39.38891904, -8.08560795],
                            [39.38879122, -8.08572302],
                            [39.38861223, -8.0857486],
                            [39.38836933, -8.08572302],
                            [39.38819035, -8.08565908],
                            [39.38798582, -8.0855568],
                            [39.38779407, -8.08542893],
                            [39.38765344, -8.08539054],
                            [39.38742332, -8.08530104],
                            [39.38721878, -8.08524989],
                            [39.38700147, -8.08522432],
                            [39.38687363, -8.08517317],
                            [39.38672021, -8.0850453],
                            [39.38654125, -8.08489187],
                            [39.38637504, -8.0846617],
                            [39.38623442, -8.08445711],
                            [39.3860171, -8.08423973],
                            [39.38577421, -8.08402236],
                            [39.38551855, -8.08375384],
                            [39.3852245, -8.08335745],
                            [39.38499438, -8.08308892],
                            [39.38471314, -8.08284597],
                            [39.38454696, -8.08270531],
                            [39.38443191, -8.08269253],
                            [39.38436799, -8.08278203],
                            [39.38438077, -8.08296105],
                            [39.3844319, -8.08317843],
                            [39.38445747, -8.083204],
                            [39.3845214, -8.08344695],
                            [39.38453418, -8.08366432],
                            [39.38464922, -8.08389449],
                            [39.38478985, -8.08420136],
                            [39.38491768, -8.08455941],
                            [39.38499437, -8.08487908],
                            [39.38512223, -8.08521153],
                            [39.38513501, -8.0855312],
                            [39.38512222, -8.08592759],
                            [39.38518613, -8.08640069],
                            [39.38523726, -8.08692496],
                            [39.3851989, -8.08729578],
                            [39.38517334, -8.08771775],
                            [39.38512221, -8.08826758],
                            [39.3850455, -8.08860004],
                            [39.3849688, -8.08884299],
                            [39.38493044, -8.08904757],
                            [39.38485375, -8.08946954],
                            [39.38481539, -8.08985313],
                            [39.38481539, -8.09018559],
                            [39.38477703, -8.0904797],
                            [39.38473869, -8.0906715],
                            [39.38466197, -8.09082493],
                            [39.38443187, -8.09106788],
                            [39.38417619, -8.09132362],
                            [39.38408669, -8.09151542],
                            [39.38398442, -8.09183509],
                            [39.38394607, -8.09215475],
                            [39.38383101, -8.09252559],
                            [39.38383101, -8.09281967],
                            [39.38383101, -8.09306262],
                            [39.38383101, -8.09330556],
                            [39.38384379, -8.09354853],
                            [39.38377987, -8.0938554],
                            [39.38367761, -8.09409835],
                            [39.3836776, -8.09440523],
                            [39.38362646, -8.09481442],
                            [39.38353698, -8.09514687],
                            [39.38337078, -8.09553047],
                            [39.38333242, -8.09576064],
                            [39.38329408, -8.09595244],
                            [39.38321738, -8.09610588],
                            [39.38307676, -8.09629768],
                            [39.38291055, -8.09627211],
                            [39.38275715, -8.09618259],
                            [39.38264209, -8.09606752],
                            [39.38260375, -8.09595244],
                            [39.38269323, -8.09583735],
                            [39.38285943, -8.09568392],
                            [39.38288499, -8.09554325],
                            [39.38283385, -8.0954026],
                            [39.38270601, -8.09527473],
                            [39.38269323, -8.09508293],
                            [39.38275715, -8.0949167],
                            [39.38288499, -8.09478885],
                            [39.38294891, -8.09460983],
                            [39.38288499, -8.09448195],
                            [39.38273158, -8.0943413],
                            [39.38252706, -8.09425178],
                            [39.3823992, -8.09411113],
                            [39.38227138, -8.09388097],
                            [39.3822458, -8.09365081],
                            [39.38224581, -8.09343344],
                            [39.38223303, -8.09310097],
                            [39.3822586, -8.09287082],
                            [39.38223303, -8.09267902],
                            [39.38209241, -8.09256393],
                            [39.38190065, -8.09271737],
                            [39.38190065, -8.0928836],
                            [39.38182393, -8.09304982],
                            [39.38172166, -8.0931649],
                            [39.38156826, -8.0931649],
                            [39.38144042, -8.09329277],
                            [39.38133815, -8.09351015],
                            [39.38132537, -8.09374032],
                            [39.38124866, -8.09399605],
                            [39.38106968, -8.09411112],
                            [39.38086514, -8.09417505],
                            [39.38069894, -8.09414949],
                            [39.3805711, -8.09399605],
                            [39.38043049, -8.0938426],
                            [39.3802643, -8.09367638],
                            [39.38004697, -8.09352294],
                            [39.37976573, -8.09344621],
                            [39.37947168, -8.09326719],
                            [39.3792288, -8.09301145],
                            [39.37901148, -8.09265342],
                            [39.37881973, -8.09230818],
                            [39.37866633, -8.09188621],
                            [39.37856405, -8.09152819],
                            [39.37851292, -8.09111901],
                            [39.37847454, -8.0908249],
                            [39.37842342, -8.0904413],
                            [39.37842342, -8.0900577],
                            [39.37851292, -8.0896741],
                            [39.37865354, -8.08931606],
                            [39.3787686, -8.08889411],
                            [39.37890922, -8.08852328],
                            [39.37906264, -8.08816526],
                            [39.37926718, -8.08787117],
                            [39.37944615, -8.08750034],
                            [39.37994474, -8.08675872],
                            [39.38009814, -8.08638788],
                            [39.38013648, -8.0860043],
                            [39.38020041, -8.08560791],
                            [39.38037938, -8.08535216],
                            [39.38052, -8.08514758],
                            [39.38066062, -8.08498134],
                            [39.38067341, -8.08467447],
                            [39.38075012, -8.08434202],
                            [39.3809419, -8.08400956],
                            [39.38106972, -8.08377939],
                            [39.3812615, -8.08365152],
                            [39.38146602, -8.08365152],
                            [39.38178562, -8.08365152],
                            [39.38192627, -8.08352366],
                            [39.38192627, -8.08334466],
                            [39.38182398, -8.08311447],
                            [39.38144046, -8.08306332],
                            [39.38122314, -8.08302497],
                            [39.38098025, -8.0828971],
                            [39.38078849, -8.08273086],
                            [39.38071177, -8.0825135],
                            [39.38069899, -8.08228334],
                            [39.38077571, -8.08209154],
                            [39.38094191, -8.08196366],
                            [39.38118479, -8.08193808],
                            [39.38151717, -8.08197645],
                            [39.38187512, -8.08196366],
                            [39.38210524, -8.08178465],
                            [39.38209246, -8.08152891],
                            [39.38196462, -8.08133711],
                            [39.38187512, -8.08110696],
                            [39.38182399, -8.08083842],
                            [39.38167057, -8.08058269],
                            [39.38146603, -8.08049319],
                            [39.38138933, -8.08054432],
                            [39.38123593, -8.08069777],
                            [39.38105695, -8.08081285],
                            [39.38094191, -8.08088959],
                            [39.38080128, -8.0808768],
                            [39.38063508, -8.0807617],
                            [39.38060952, -8.08055712],
                            [39.38076294, -8.08030138],
                            [39.38085242, -8.07989221],
                            [39.38085242, -8.07964925],
                            [39.38072457, -8.07944466],
                            [39.38053283, -8.07927843],
                            [39.38032829, -8.07912499],
                            [39.38021323, -8.0789204],
                            [39.38005982, -8.07870303],
                            [39.37993199, -8.07851122],
                            [39.37985528, -8.07825548],
                            [39.37985528, -8.07791025],
                            [39.37979136, -8.07766729],
                            [39.37979136, -8.07738598],
                            [39.3796891, -8.07716861],
                            [39.37962518, -8.07695124],
                            [39.37940279, -8.07680937],
                            [39.3791967, -8.07694186],
                            [39.37888662, -8.07723727],
                            [39.37857652, -8.07753265],
                            [39.37834026, -8.07781328],
                            [39.37804493, -8.07815299],
                            [39.37779388, -8.07850746],
                            [39.37761668, -8.07889148],
                            [39.37742473, -8.07923118],
                            [39.37729184, -8.07951181],
                            [39.37723277, -8.07986629],
                            [39.37718845, -8.08025029],
                            [39.37714414, -8.08056048],
                            [39.3770851, -8.0808854],
                            [39.37701124, -8.08112172],
                            [39.37692266, -8.08140235],
                            [39.37680453, -8.08165344],
                            [39.37667161, -8.08188975],
                            [39.37654524, -8.08214253],
                            [39.37644643, -8.08211783],
                            [39.37624803, -8.08209135],
                            [39.37610255, -8.08191938],
                            [39.37603641, -8.08176063],
                            [39.37597029, -8.08154895],
                            [39.3758248, -8.08149605],
                            [39.37559995, -8.08149605],
                            [39.37545447, -8.08157542],
                            [39.37532221, -8.08186646],
                            [39.37532221, -8.08219719],
                            [39.37533542, -8.08256761],
                            [39.37538833, -8.08295126],
                            [39.37540156, -8.08320262],
                            [39.37524283, -8.08340104],
                            [39.37508412, -8.08350687],
                            [39.37496509, -8.08371854],
                            [39.37493864, -8.08394344],
                            [39.37489895, -8.08414188],
                            [39.37476669, -8.08435355],
                            [39.37470056, -8.08457843],
                            [39.37470056, -8.08486949],
                            [39.37480637, -8.08508115],
                            [39.37499153, -8.08531927],
                            [39.37517634, -8.08544895],
                            [39.3750473, -8.08568559],
                            [39.37482579, -8.08590715],
                            [39.37457477, -8.08614346],
                            [39.37455939, -8.0861619],
                            [39.37404318, -8.08594181],
                            [39.37389809, -8.08570961],
                            [39.37363693, -8.08559351],
                            [39.37333226, -8.0855935],
                            [39.37304208, -8.08546289],
                            [39.37272287, -8.08539034],
                            [39.37244721, -8.08539034],
                            [39.37215702, -8.08533229],
                            [39.37188138, -8.08515814],
                            [39.37164923, -8.08494047],
                            [39.3712575, -8.08467925],
                            [39.37082223, -8.08459217],
                            [39.37041598, -8.08457766],
                            [39.36979212, -8.08454862],
                            [39.36935686, -8.08449058],
                            [39.36906668, -8.08444704],
                            [39.36893609, -8.08431644],
                            [39.36889259, -8.08398265],
                            [39.36877651, -8.08370693],
                            [39.36871846, -8.08346023],
                            [39.36877651, -8.08328607],
                            [39.36882004, -8.08303937],
                            [39.36880552, -8.08283622],
                            [39.36864592, -8.08282169],
                            [39.36842829, -8.08283622],
                            [39.36823969, -8.08293779],
                            [39.36797853, -8.08309742],
                            [39.36784795, -8.08334413],
                            [39.3676158, -8.08353278],
                            [39.36738366, -8.08360533],
                            [39.36715152, -8.08360533],
                            [39.36687585, -8.08360533],
                            [39.36649863, -8.08348924],
                            [39.36616492, -8.08338766],
                            [39.36567163, -8.08328606],
                            [39.36514931, -8.08316997],
                            [39.3644674, -8.08303935],
                            [39.36394509, -8.08289423],
                            [39.36356787, -8.08287972],
                            [39.36307456, -8.0828362],
                            [39.3628134, -8.0827346],
                            [39.3626393, -8.08240082],
                            [39.3624652, -8.08208156],
                            [39.36240716, -8.0818058],
                            [39.3624797, -8.08155911],
                            [39.36253775, -8.08128339],
                            [39.36272637, -8.08096413],
                            [39.3629585, -8.08070291],
                            [39.36320515, -8.08060133],
                            [39.36348082, -8.08054327],
                            [39.363771, -8.08061584],
                            [39.36409019, -8.08065938],
                            [39.36440938, -8.08061584],
                            [39.36469956, -8.08052878],
                            [39.36480112, -8.08038366],
                            [39.36471406, -8.080195],
                            [39.36449643, -8.08006439],
                            [39.36427879, -8.07993377],
                            [39.36409019, -8.07977415],
                            [39.36404666, -8.07957097],
                            [39.36391609, -8.07938233],
                            [39.36377101, -8.07930978],
                            [39.36362592, -8.0792372],
                            [39.36350984, -8.07903402],
                            [39.36346632, -8.07878732],
                            [39.36335024, -8.07868574],
                            [39.36319066, -8.07871476],
                            [39.36292949, -8.07868574],
                            [39.36266832, -8.0785116],
                            [39.36252325, -8.07826489],
                            [39.36247972, -8.07791659],
                            [39.36247972, -8.07774245],
                            [39.36266832, -8.07764089],
                            [39.36287145, -8.07762635],
                            [39.36316163, -8.07772794],
                            [39.36346632, -8.07772794],
                            [39.3637565, -8.0775538],
                            [39.36403216, -8.07735064],
                            [39.36413372, -8.07708942],
                            [39.36411922, -8.0768282],
                            [39.36398865, -8.07666857],
                            [39.36384356, -8.07652345],
                            [39.36380003, -8.07637833],
                            [39.36390159, -8.07623321],
                            [39.36398865, -8.07610259],
                            [39.36416275, -8.07608809],
                            [39.36436587, -8.07627675],
                            [39.36453997, -8.07655247],
                            [39.36471407, -8.07674112],
                            [39.36497522, -8.07674112],
                            [39.36513484, -8.07663956],
                            [39.36516385, -8.07642186],
                            [39.36512033, -8.07608809],
                            [39.36498975, -8.07585589],
                            [39.36497523, -8.07502871],
                            [39.36500426, -8.07463689],
                            [39.36503329, -8.07433214],
                            [39.36522189, -8.0741725],
                            [39.36552658, -8.07408543],
                            [39.36575872, -8.07388227],
                            [39.36575872, -8.07360654],
                            [39.36584578, -8.07337434],
                            [39.36591832, -8.07321472],
                            [39.36606341, -8.07302606],
                            [39.36622301, -8.07293898],
                            [39.36639712, -8.0727213],
                            [39.36661475, -8.07256168],
                            [39.36661475, -8.07238752],
                            [39.36648416, -8.072344],
                            [39.36628105, -8.0723585],
                            [39.36610694, -8.0724746],
                            [39.36597635, -8.07267776],
                            [39.36578775, -8.07288094],
                            [39.36551207, -8.072968],
                            [39.36523643, -8.07304056],
                            [39.36517838, -8.07286642],
                            [39.36525093, -8.07263423],
                            [39.36536699, -8.07225691],
                            [39.36559914, -8.07186509],
                            [39.36594734, -8.07164741],
                            [39.36635359, -8.07140071],
                            [39.36677434, -8.07113949],
                            [39.36716608, -8.07077668],
                            [39.36742724, -8.07029779],
                            [39.36763038, -8.06987695],
                            [39.36778997, -8.06932549],
                            [39.36794957, -8.06890465],
                            [39.36813818, -8.06849831],
                            [39.36831228, -8.06820807],
                            [39.36854443, -8.06801942],
                            [39.36874756, -8.06778722],
                            [39.36896519, -8.06762759],
                            [39.36919733, -8.0673954],
                            [39.36937143, -8.06710516],
                            [39.36950202, -8.06680039],
                            [39.36950202, -8.06652468],
                            [39.36958907, -8.06629248],
                            [39.36982121, -8.06604578],
                            [39.37000983, -8.0658136],
                            [39.37006786, -8.06558138],
                            [39.36999532, -8.0654508],
                            [39.36983572, -8.06540726],
                            [39.36960358, -8.06542176],
                            [39.36935694, -8.06555237],
                            [39.36906676, -8.06568298],
                            [39.36877658, -8.06582809],
                            [39.36854444, -8.06582809],
                            [39.36845739, -8.06575553],
                            [39.36848641, -8.06562493],
                            [39.36850089, -8.06524761],
                            [39.36848641, -8.06492835],
                            [39.3683123, -8.06466713],
                            [39.36805115, -8.06437688],
                            [39.36768843, -8.06417374],
                            [39.3673112, -8.06401408],
                            [39.36693398, -8.06379641],
                            [39.36667282, -8.06360775],
                            [39.36655675, -8.06331751],
                            [39.36661479, -8.06311433],
                            [39.36673087, -8.06291117],
                            [39.36680339, -8.06267897],
                            [39.36699201, -8.06254837],
                            [39.36722418, -8.06251935],
                            [39.36742728, -8.06251935],
                            [39.36760138, -8.06262093],
                            [39.36787705, -8.06282411],
                            [39.3681382, -8.0629402],
                            [39.36834133, -8.06291118],
                            [39.36848642, -8.06279508],
                            [39.36864601, -8.06270801],
                            [39.36882013, -8.06272252],
                            [39.36897971, -8.0628096],
                            [39.36924088, -8.0628096],
                            [39.36934243, -8.06270802],
                            [39.36929891, -8.06249034],
                            [39.36913932, -8.06230168],
                            [39.3689507, -8.06224363],
                            [39.3686315, -8.06212753],
                            [39.36841388, -8.06193887],
                            [39.36816724, -8.06173569],
                            [39.36808018, -8.06148899],
                            [39.36809469, -8.06128581],
                            [39.3682688, -8.06111168],
                            [39.36852996, -8.06098108],
                            [39.36879111, -8.0607634],
                            [39.3690958, -8.06057474],
                            [39.36929891, -8.06048767],
                            [39.36948753, -8.06048767],
                            [39.36976319, -8.06056023],
                            [39.3700824, -8.06057475],
                            [39.37034355, -8.06056023],
                            [39.37051766, -8.06042963],
                            [39.37056118, -8.06022645],
                            [39.37044512, -8.06002329],
                            [39.37022749, -8.05984915],
                            [39.36995181, -8.05977658],
                            [39.36969065, -8.05966048],
                            [39.36950205, -8.0594428],
                            [39.36944401, -8.05913806],
                            [39.36953108, -8.0589494],
                            [39.36969066, -8.05886232],
                            [39.36990829, -8.05884782],
                            [39.37011142, -8.05876074],
                            [39.370242, -8.05852856],
                            [39.370242, -8.05819478],
                            [39.3702275, -8.05789002],
                            [39.37002437, -8.05771587],
                            [39.36976321, -8.05765783],
                            [39.36971969, -8.05732405],
                            [39.36985025, -8.05704833],
                            [39.37014044, -8.05694673],
                            [39.3704161, -8.05694673],
                            [39.37074983, -8.05700479],
                            [39.3710545, -8.05707735],
                            [39.37137369, -8.05714992],
                            [39.37159132, -8.05729504],
                            [39.37180895, -8.05735308],
                            [39.37199757, -8.05720796],
                            [39.37202659, -8.05696126],
                            [39.37195404, -8.05674358],
                            [39.37177994, -8.05661297],
                            [39.37177994, -8.0564098],
                            [39.37193953, -8.0562937],
                            [39.37214265, -8.0562937],
                            [39.37243282, -8.0562937],
                            [39.37273753, -8.0562937],
                            [39.37295515, -8.05614858],
                            [39.37310024, -8.05590188],
                            [39.37328886, -8.05561164],
                            [39.37331787, -8.05540848],
                            [39.37321631, -8.05526335],
                            [39.37305673, -8.05516177],
                            [39.37269399, -8.05504567],
                            [39.37212816, -8.05487153],
                            [39.37196855, -8.05478445],
                            [39.37196855, -8.05458128],
                            [39.3720411, -8.05446519],
                            [39.3722007, -8.05442163],
                            [39.3723748, -8.05442163],
                            [39.37259244, -8.05442163],
                            [39.37282458, -8.05447971],
                            [39.37311476, -8.05456677],
                            [39.3733614, -8.05469739],
                            [39.37362255, -8.05475543],
                            [39.37378215, -8.05463933],
                            [39.37378215, -8.05446519],
                            [39.37370961, -8.05426203],
                            [39.37352099, -8.05413141],
                            [39.37344845, -8.05394277],
                            [39.37343395, -8.05365252],
                            [39.37341944, -8.05342033],
                            [39.37328887, -8.05324618],
                            [39.37311476, -8.0530285],
                            [39.37299869, -8.05268022],
                            [39.37299869, -8.05240448],
                            [39.37307124, -8.05224484],
                            [39.37307124, -8.0520562],
                            [39.37304223, -8.05198363],
                            [39.37282459, -8.05198363],
                            [39.37263598, -8.05204168],
                            [39.37249089, -8.05215777],
                            [39.37230227, -8.05228838],
                            [39.37205562, -8.05249155],
                            [39.371867, -8.05283984],
                            [39.37170741, -8.05317362],
                            [39.37162035, -8.05359446],
                            [39.37151878, -8.05402982],
                            [39.37127215, -8.05423298],
                            [39.37105451, -8.05437812],
                            [39.37069179, -8.05468288],
                            [39.37034357, -8.05500214],
                            [39.37003889, -8.0553359],
                            [39.36964716, -8.05552456],
                            [39.36918288, -8.05577124],
                            [39.36866055, -8.0560615],
                            [39.36809471, -8.05638076],
                            [39.36712263, -8.05685966],
                            [39.36661482, -8.05704831],
                            [39.36615054, -8.0572805],
                            [39.36571527, -8.0575272],
                            [39.36516393, -8.05771585],
                            [39.36467064, -8.05790451],
                            [39.3641193, -8.05816573],
                            [39.36368404, -8.05842693],
                            [39.36327779, -8.05874619],
                            [39.36295859, -8.05905095],
                            [39.3626394, -8.05948631],
                            [39.36240725, -8.05982009],
                            [39.36207356, -8.06003777],
                            [39.36156575, -8.06048763],
                            [39.36085481, -8.06101007],
                            [39.36008585, -8.06145993],
                            [39.35940396, -8.06198237],
                            [39.35876554, -8.06247577],
                            [39.35809815, -8.06286759],
                            [39.35753229, -8.06328845],
                            [39.357039, -8.06350613],
                            [39.35674882, -8.06375283],
                            [39.35658923, -8.06399953],
                            [39.35634257, -8.06436233],
                            [39.35605241, -8.06478317],
                            [39.35570418, -8.06534914],
                            [39.35537047, -8.06594414],
                            [39.3550658, -8.0665101],
                            [39.3547466, -8.06695998],
                            [39.3544129, -8.0673518],
                            [39.35381801, -8.06803386],
                            [39.35346982, -8.06846924],
                            [39.35303455, -8.06906422],
                            [39.35267183, -8.06964469],
                            [39.35248321, -8.07018165],
                            [39.35233812, -8.07068955],
                            [39.35217853, -8.07111041],
                            [39.3519609, -8.07145869],
                            [39.35184482, -8.07164735],
                            [39.35173433, -8.07180698],
                            [39.35173433, -8.07193437],
                            [39.35154171, -8.07215979],
                            [39.3514666, -8.07233152],
                            [39.35134856, -8.07267499],
                            [39.351091, -8.07294332],
                            [39.35091931, -8.07314725],
                            [39.35075834, -8.07343706],
                            [39.3505008, -8.07381272],
                            [39.35022178, -8.07401666],
                            [39.34991059, -8.07422059],
                            [39.34965304, -8.07436011],
                            [39.34943844, -8.07449965],
                            [39.34926672, -8.0747036],
                            [39.34905212, -8.07496119],
                            [39.34881604, -8.07522952],
                            [39.34863361, -8.07546566],
                            [39.3485263, -8.07578765],
                            [39.3485263, -8.07597013],
                            [39.34851558, -8.07629212],
                            [39.3483868, -8.07652824],
                            [39.34817217, -8.07681806],
                            [39.34810778, -8.07718299],
                            [39.34810777, -8.07756939],
                            [39.34804338, -8.07817045],
                            [39.34794682, -8.07883592],
                            [39.34790388, -8.07928674],
                            [39.34785023, -8.07968387],
                            [39.34773218, -8.08010247],
                            [39.34765706, -8.08041373],
                            [39.34766779, -8.08075721],
                            [39.34766779, -8.08116507],
                            [39.34761414, -8.08147635],
                            [39.34750683, -8.08172321],
                            [39.34743172, -8.08190568],
                            [39.34717418, -8.08206667],
                            [39.34695955, -8.08223841],
                            [39.34675567, -8.08235648],
                            [39.34661616, -8.08251747],
                            [39.34649811, -8.08269993],
                            [39.34632642, -8.0828824],
                            [39.34615473, -8.08307561],
                            [39.34588645, -8.08323662],
                            [39.34562889, -8.08338687],
                            [39.34551085, -8.08345127],
                            [39.34541429, -8.08358007],
                            [39.34530697, -8.08373033],
                            [39.34511382, -8.0838484],
                            [39.34496359, -8.0839128],
                            [39.34481336, -8.08399866],
                            [39.34463801, -8.08405792],
                            [39.34465823, -8.08411564],
                            [39.34446558, -8.08415574],
                            [39.34423912, -8.08439841],
                            [39.34388325, -8.08468963],
                            [39.34355975, -8.08489995],
                            [39.3432524, -8.08507792],
                            [39.34294507, -8.08512647],
                            [39.34252452, -8.08514265],
                            [39.34221718, -8.08512647],
                            [39.34173192, -8.08506174],
                            [39.34139224, -8.08506174],
                            [39.34103638, -8.08512647],
                            [39.34051878, -8.08512646],
                            [39.34024379, -8.08512646],
                            [39.3397747, -8.08502938],
                            [39.33916003, -8.08504555],
                            [39.33875564, -8.08511025],
                            [39.33810864, -8.0850779],
                            [39.33770423, -8.08502937],
                            [39.33729986, -8.08498083],
                            [39.33668519, -8.08488375],
                            [39.33616758, -8.0846896],
                            [39.33553674, -8.0844631],
                            [39.33511617, -8.08423657],
                            [39.33471179, -8.08392917],
                            [39.33432358, -8.08339527],
                            [39.33408095, -8.08292607],
                            [39.33398389, -8.08255396],
                            [39.33387068, -8.08211711],
                            [39.3338019, -8.08179036],
                            [39.3333952, -8.08167584],
                            [39.33285539, -8.08163084],
                            [39.33227059, -8.08163084],
                            [39.33191073, -8.08149586],
                            [39.33123596, -8.08118089],
                            [39.33051624, -8.08041597],
                            [39.32975151, -8.07960608],
                            [39.32948162, -8.07879621],
                            [39.32948162, -8.07816629],
                            [39.32952065, -8.0779712],
                            [39.32970657, -8.07704146],
                            [39.33015637, -8.07605159],
                            [39.33074118, -8.07515169],
                            [39.33141593, -8.07443181],
                            [39.33211069, -8.07417842],
                            [39.33302839, -8.07384463],
                            [39.33394607, -8.07384464],
                            [39.33486371, -8.07384464],
                            [39.33553113, -8.07342746],
                            [39.33661566, -8.07301021],
                            [39.33736647, -8.07267648],
                            [39.33820069, -8.07267648],
                            [39.33886811, -8.07259303],
                            [39.33970233, -8.07225926],
                            [39.34028633, -8.07150828],
                            [39.34078688, -8.07067386],
                            [39.34062003, -8.06975595],
                            [39.3405366, -8.06883811],
                            [39.34062003, -8.06808713],
                            [39.3405366, -8.06716925],
                            [39.33986923, -8.06666858],
                            [39.33936869, -8.06600103],
                            [39.33820072, -8.06550037],
                            [39.33678251, -8.06550036],
                            [39.335698, -8.06566722],
                            [39.3343632, -8.06608446],
                            [39.33336212, -8.06666855],
                            [39.33261129, -8.06725266],
                            [39.3315268, -8.06783676],
                            [39.33027543, -8.06792019],
                            [39.32927433, -8.06833741],
                            [39.32844009, -8.0687546],
                            [39.32727214, -8.06933871],
                            [39.32593734, -8.06983936],
                            [39.32501969, -8.07008968],
                            [39.32418544, -8.0699228],
                            [39.32285067, -8.06958902],
                            [39.32126559, -8.06933868],
                            [39.32018108, -8.06875457],
                            [39.3195137, -8.06792015],
                            [39.31884631, -8.06683538],
                            [39.31851258, -8.06600094],
                            [39.31834577, -8.06508308],
                            [39.31834577, -8.06500921],
                            [39.31798463, -8.06560266],
                            [39.31779851, -8.06633078],
                            [39.3175157, -8.06717936],
                            [39.31734602, -8.06777336],
                            [39.31717634, -8.06862194],
                            [39.31703493, -8.06952711],
                            [39.31692179, -8.07046054],
                            [39.31680868, -8.07147884],
                            [39.31675212, -8.07221429],
                            [39.31683694, -8.07328918],
                            [39.31661072, -8.07419431],
                            [39.3164976, -8.07470347],
                            [39.31646929, -8.07532577],
                            [39.31632792, -8.0758915],
                            [39.31615823, -8.07640064],
                            [39.31615823, -8.07682494],
                            [39.31621479, -8.07733408],
                            [39.31635619, -8.07789981],
                            [39.31649758, -8.07860696],
                            [39.31649758, -8.07931412],
                            [39.31649758, -8.07982327],
                            [39.31646927, -8.08033244],
                            [39.31621477, -8.08072843],
                            [39.31587541, -8.08118101],
                            [39.31533809, -8.08174673],
                            [39.31502478, -8.08215804],
                            [39.3148856, -8.08234074],
                            [39.31448969, -8.08268018],
                            [39.31398063, -8.08304788],
                            [39.31361299, -8.08335903],
                            [39.31317732, -8.08398849],
                            [39.31362791, -8.08431157],
                            [39.31377606, -8.08451902],
                            [39.31403284, -8.08477586],
                            [39.31423037, -8.08492405],
                            [39.31444765, -8.08504257],
                            [39.31469453, -8.08511174],
                            [39.31488221, -8.08521051],
                            [39.31516862, -8.08529943],
                            [39.31549455, -8.0854081],
                            [39.31582046, -8.08552665],
                            [39.31616613, -8.08567482],
                            [39.31652168, -8.08580325],
                            [39.31681798, -8.08594154],
                            [39.31712414, -8.08605022],
                            [39.31740067, -8.08616878],
                            [39.31756857, -8.08622804],
                            [39.3177266, -8.0862873],
                            [39.31794388, -8.0862873],
                            [39.31813153, -8.0862873],
                            [39.31831918, -8.08631695],
                            [39.31850681, -8.08636633],
                            [39.3186846, -8.08642561],
                            [39.31879324, -8.08654415],
                            [39.3188525, -8.08675161],
                            [39.31889198, -8.0869393],
                            [39.31889198, -8.08715662],
                            [39.31886237, -8.08736408],
                            [39.31878336, -8.0874925],
                            [39.31858584, -8.08767031],
                            [39.31837842, -8.087858],
                            [39.31816115, -8.08799631],
                            [39.31798337, -8.08811484],
                            [39.31785497, -8.08830253],
                            [39.31775621, -8.08850999],
                            [39.31770684, -8.08868781],
                            [39.31770684, -8.08885574],
                            [39.31778585, -8.08903356],
                            [39.31792411, -8.08917185],
                            [39.31810188, -8.08929041],
                            [39.31827965, -8.08934967],
                            [39.31849694, -8.08933979],
                            [39.3186747, -8.08932004],
                            [39.31881298, -8.08922126],
                            [39.31894138, -8.08912247],
                            [39.31906977, -8.08901381],
                            [39.31922779, -8.08894466],
                            [39.31940557, -8.08891501],
                            [39.31956359, -8.08891501],
                            [39.31975124, -8.08903357],
                            [39.31992901, -8.08921138],
                            [39.32012653, -8.08935957],
                            [39.32035368, -8.08945835],
                            [39.32051172, -8.08957689],
                            [39.32062036, -8.08974483],
                            [39.32069937, -8.08986335],
                            [39.32070924, -8.09005106],
                            [39.32067962, -8.09034743],
                            [39.32064998, -8.09056475],
                            [39.32064998, -8.09083147],
                            [39.32068947, -8.09104881],
                            [39.32086726, -8.09121674],
                            [39.32102527, -8.09135504],
                            [39.32115367, -8.09150322],
                            [39.32123268, -8.09169091],
                            [39.32133144, -8.09191813],
                            [39.32138083, -8.09214532],
                            [39.32138083, -8.09236266],
                            [39.32146972, -8.09256023],
                            [39.32154873, -8.09268865],
                            [39.32168699, -8.09298501],
                            [39.32177587, -8.09318258],
                            [39.32182526, -8.0934493],
                            [39.32182526, -8.09365676],
                            [39.32181537, -8.09388396],
                            [39.32171662, -8.09413095],
                            [39.32157835, -8.09436802],
                            [39.32140057, -8.09456559],
                            [39.32115366, -8.09476315],
                            [39.32086725, -8.09494097],
                            [39.32069935, -8.09505952],
                            [39.32060058, -8.09521756],
                            [39.32054132, -8.09540527],
                            [39.32040306, -8.09561271],
                            [39.32033392, -8.09585968],
                            [39.32033392, -8.09604737],
                            [39.32044255, -8.09617579],
                            [39.32057096, -8.09631411],
                            [39.32069934, -8.09644251],
                            [39.3209265, -8.0965907],
                            [39.32111415, -8.09671913],
                            [39.32125241, -8.09683766],
                            [39.32140056, -8.09696608],
                            [39.32157833, -8.09709451],
                            [39.32174623, -8.0972328],
                            [39.32190426, -8.0973316],
                            [39.32205239, -8.09741062],
                            [39.32223017, -8.09746002],
                            [39.3224672, -8.09746002],
                            [39.32272399, -8.09742051],
                            [39.32295115, -8.09739087],
                            [39.3231783, -8.09734149],
                            [39.32332644, -8.09727233],
                            [39.32343509, -8.09716367],
                            [39.32356349, -8.09703524],
                            [39.32373139, -8.09690683],
                            [39.32393879, -8.09671914],
                            [39.32413631, -8.0965512],
                            [39.32434373, -8.09638327],
                            [39.32455113, -8.09616593],
                            [39.32477829, -8.09594861],
                            [39.32497581, -8.09581032],
                            [39.32519309, -8.09568189],
                            [39.32542025, -8.09558311],
                            [39.32562765, -8.09550408],
                            [39.32587456, -8.09546455],
                            [39.32606221, -8.09545468],
                            [39.32624986, -8.09548431],
                            [39.32636839, -8.09553371],
                            [39.32646713, -8.09565225],
                            [39.32655603, -8.09576093],
                            [39.32663503, -8.09588934],
                            [39.32673381, -8.0960474],
                            [39.3267733, -8.09622522],
                            [39.32678318, -8.0963734],
                            [39.32678318, -8.09658084],
                            [39.32675355, -8.0967389],
                            [39.32665479, -8.09684756],
                            [39.32643752, -8.09705501],
                            [39.32621035, -8.09722295],
                            [39.32598319, -8.0973415],
                            [39.32579554, -8.09746991],
                            [39.32557827, -8.09763785],
                            [39.32538073, -8.09784529],
                            [39.32520295, -8.0980725],
                            [39.3251437, -8.09824044],
                            [39.32511406, -8.09842813],
                            [39.32511406, -8.0986257],
                            [39.3251437, -8.09883316],
                            [39.32519307, -8.09903073],
                            [39.32527208, -8.09926782],
                            [39.32543998, -8.09947527],
                            [39.32559801, -8.09976174],
                            [39.32572639, -8.09990005],
                            [39.32595355, -8.10009762],
                            [39.32620047, -8.10016677],
                            [39.32645725, -8.10016677],
                            [39.32672391, -8.10013714],
                            [39.32694119, -8.10007787],
                            [39.32710909, -8.10001859],
                            [39.32736587, -8.09991981],
                            [39.32759304, -8.09979138],
                            [39.3278597, -8.09967283],
                            [39.32810661, -8.09956419],
                            [39.32834363, -8.0994654],
                            [39.32865968, -8.09940613],
                            [39.32886707, -8.099416],
                            [39.32905473, -8.09942588],
                            [39.32920288, -8.09938638],
                            [39.32934116, -8.09929747],
                            [39.32945967, -8.09920856],
                            [39.32957818, -8.09918881],
                            [39.32980534, -8.09918881],
                            [39.33005225, -8.09918881],
                            [39.33028929, -8.09922832],
                            [39.33043743, -8.09927772],
                            [39.33064484, -8.09944566],
                            [39.33079297, -8.09963335],
                            [39.33089173, -8.09983092],
                            [39.33093125, -8.09999886],
                            [39.33096087, -8.10016679],
                            [39.33100038, -8.10032485],
                            [39.33108927, -8.10043352],
                            [39.3312374, -8.10054218],
                            [39.33132629, -8.10071999],
                            [39.33137568, -8.10093733],
                            [39.33128678, -8.10114477],
                            [39.33112877, -8.10129296],
                            [39.33101025, -8.10147075],
                            [39.33091149, -8.10164858],
                            [39.33087198, -8.10180663],
                            [39.33088185, -8.10200421],
                            [39.3309016, -8.1022413],
                            [39.33098061, -8.10253766],
                            [39.33108926, -8.10276489],
                            [39.33128677, -8.10293279],
                            [39.33152382, -8.10306122],
                            [39.33176084, -8.10315012],
                            [39.33205714, -8.10321929],
                            [39.33229416, -8.1033082],
                            [39.33251144, -8.10342673],
                            [39.33262995, -8.10355516],
                            [39.33271885, -8.1036737],
                            [39.33274849, -8.10383176],
                            [39.33271885, -8.10400958],
                            [39.33262994, -8.10410838],
                            [39.33256082, -8.10419727],
                            [39.33256082, -8.10433556],
                            [39.33263983, -8.10445411],
                            [39.33284725, -8.1045529],
                            [39.33308427, -8.10458253],
                            [39.33328181, -8.10458253],
                            [39.33344971, -8.10452327],
                            [39.33354847, -8.10444426],
                            [39.33363734, -8.10435533],
                            [39.33379538, -8.10430593],
                            [39.33394352, -8.10430593],
                            [39.33406204, -8.10439484],
                            [39.33414102, -8.1045134],
                            [39.33418055, -8.10478999],
                            [39.33418055, -8.10509622],
                            [39.33425956, -8.10544198],
                            [39.33428918, -8.10566919],
                            [39.33437807, -8.10592603],
                            [39.33444721, -8.10625202],
                            [39.3344867, -8.10664717],
                            [39.33443733, -8.1071411],
                            [39.33439781, -8.10755599],
                            [39.33431881, -8.10796102],
                            [39.33425955, -8.10828702],
                            [39.33420028, -8.1086624],
                            [39.334141, -8.10899828],
                            [39.33410152, -8.10927487],
                            [39.33400276, -8.1095811],
                            [39.33389412, -8.10975892],
                            [39.33387435, -8.1099071],
                            [39.33386448, -8.11010467],
                            [39.33389412, -8.1102331],
                            [39.33396324, -8.11032201],
                            [39.33410151, -8.11043067],
                            [39.33429904, -8.11045042],
                            [39.3345262, -8.11045042],
                            [39.33476322, -8.11038129],
                            [39.33497064, -8.11028249],
                            [39.33515829, -8.11020346],
                            [39.33529656, -8.11015407],
                            [39.3354447, -8.11010467],
                            [39.33569161, -8.11010468],
                            [39.33586938, -8.11010468],
                            [39.33600766, -8.11004542],
                            [39.33613604, -8.10995651],
                            [39.33623481, -8.10982808],
                            [39.33635333, -8.10970954],
                            [39.33650147, -8.10955148],
                            [39.33671876, -8.10939342],
                            [39.33688666, -8.10923536],
                            [39.33707431, -8.10916623],
                            [39.33729158, -8.10910695],
                            [39.33744959, -8.10898841],
                            [39.33757801, -8.10885998],
                            [39.33768664, -8.10873157],
                            [39.33780516, -8.10862289],
                            [39.33794343, -8.10859326],
                            [39.33812119, -8.10859326],
                            [39.33834837, -8.10859327],
                            [39.33855577, -8.10858339],
                            [39.33871379, -8.10853399],
                            [39.33892119, -8.1083463],
                            [39.33907922, -8.10815861],
                            [39.33914836, -8.10795117],
                            [39.33914836, -8.10767457],
                            [39.33910884, -8.10726954],
                            [39.33902983, -8.10694354],
                            [39.33901996, -8.10663731],
                            [39.3390496, -8.10640022],
                            [39.33915824, -8.10625204],
                            [39.33929648, -8.1060841],
                            [39.33947427, -8.10596556],
                            [39.33969156, -8.10594581],
                            [39.33985946, -8.10594581],
                            [39.34002736, -8.10598532],
                            [39.34018539, -8.10606435],
                            [39.34041253, -8.10625204],
                            [39.34059031, -8.10631132],
                            [39.34075821, -8.10631132],
                            [39.34089648, -8.10631132],
                            [39.34104462, -8.10635083],
                            [39.34116314, -8.10643975],
                            [39.34137054, -8.10658792],
                            [39.34148905, -8.10666695],
                            [39.34167671, -8.10666695],
                            [39.34187424, -8.1066472],
                            [39.34204214, -8.10662743],
                            [39.3421804, -8.10662743],
                            [39.34232855, -8.10662743],
                            [39.3424767, -8.10662743],
                            [39.34260507, -8.10662743],
                            [39.3427236, -8.10656817],
                            [39.34284212, -8.10644964],
                            [39.34296063, -8.10631133],
                            [39.34302977, -8.10615327],
                            [39.34310877, -8.10592607],
                            [39.34319767, -8.10565935],
                            [39.34328656, -8.10528395],
                            [39.34337545, -8.1049382],
                            [39.34347421, -8.10466159],
                            [39.34361248, -8.10441464],
                            [39.34374088, -8.10426647],
                            [39.34390878, -8.10413804],
                            [39.34420506, -8.10400963],
                            [39.34443223, -8.10391084],
                            [39.34465939, -8.10383182],
                            [39.34482729, -8.10379229],
                            [39.3449458, -8.10379229],
                            [39.34509395, -8.10383182],
                            [39.34522234, -8.10390097],
                            [39.34533097, -8.10400963],
                            [39.3454173, -8.10417719],
                            [39.34539389, -8.10448163],
                            [39.34515979, -8.10485626],
                            [39.34487886, -8.10520749],
                            [39.3447852, -8.1057929],
                            [39.34483203, -8.10647196],
                            [39.34490226, -8.10670612],
                            [39.34520657, -8.1072681],
                            [39.34560457, -8.10761934],
                            [39.34588549, -8.10815791],
                            [39.34623665, -8.1087199],
                            [39.3465644, -8.1091648],
                            [39.34682192, -8.10958628],
                            [39.34703261, -8.10984386],
                            [39.34729013, -8.10998438],
                            [39.34771152, -8.1100312],
                            [39.34817974, -8.1100312],
                            [39.34867136, -8.11014827],
                            [39.34904592, -8.11028877],
                            [39.3494205, -8.1104761],
                            [39.34979506, -8.11056976],
                            [39.35019305, -8.11052294],
                            [39.35033351, -8.11019512],
                            [39.35033351, -8.10991413],
                            [39.35016965, -8.1097502],
                            [39.35019305, -8.10946921],
                            [39.35033352, -8.10935215],
                            [39.3504974, -8.10935215],
                            [39.3507315, -8.10925849],
                            [39.35089537, -8.10890724],
                            [39.35098902, -8.10850916],
                            [39.35108266, -8.10829841],
                            [39.35129336, -8.10829841],
                            [39.35141041, -8.10836867],
                            [39.35152747, -8.10850917],
                            [39.35152747, -8.10876675],
                            [39.35162111, -8.10902431],
                            [39.35176157, -8.10923507],
                            [39.35176157, -8.10956289],
                            [39.35176157, -8.10991413],
                            [39.35157427, -8.11035903],
                            [39.35159769, -8.11073369],
                            [39.35169133, -8.11101468],
                            [39.35190201, -8.11113176],
                            [39.35222978, -8.11106152],
                            [39.3527448, -8.11103809],
                            [39.35318961, -8.11096785],
                            [39.35363441, -8.11094446],
                            [39.35398557, -8.1110381],
                            [39.35433674, -8.11117861],
                            [39.35461767, -8.11115519],
                            [39.35487517, -8.11099128],
                            [39.35503906, -8.11080395],
                            [39.35534339, -8.11056978],
                            [39.35535461, -8.11055856],
                            [39.35531706, -8.11051162],
                            [39.35531291, -8.11042418],
                            [39.35598229, -8.11093095],
                            [39.35615192, -8.11114304],
                            [39.3565336, -8.11148237],
                            [39.35695768, -8.11160964],
                            [39.35733935, -8.11156721],
                            [39.35772103, -8.11156721],
                            [39.35814512, -8.11182173],
                            [39.35848437, -8.11224592],
                            [39.35835715, -8.11283975],
                            [39.35780584, -8.11339119],
                            [39.356576, -8.11398503],
                            [39.35517652, -8.11432439],
                            [39.35432835, -8.11449404],
                            [39.35386185, -8.11479097],
                            [39.35364981, -8.11496064],
                            [39.35360741, -8.11504548],
                            [39.35381945, -8.11538483],
                            [39.35449798, -8.11572416],
                            [39.35526134, -8.11597868],
                            [39.35585503, -8.11597868],
                            [39.35653358, -8.11644529],
                            [39.35683044, -8.11691189],
                            [39.35687285, -8.11746332],
                            [39.35678802, -8.11831168],
                            [39.35678802, -8.11886311],
                            [39.35661839, -8.11971146],
                            [39.35644874, -8.12043257],
                            [39.35657597, -8.12115368],
                            [39.35729691, -8.12123852],
                            [39.35801785, -8.12111127],
                            [39.35856915, -8.1209416],
                            [39.35899325, -8.12085676],
                            [39.35950215, -8.12085676],
                            [39.36022309, -8.12119612],
                            [39.36065188, -8.12155432],
                            [39.36133043, -8.12169005],
                            [39.36133043, -8.12173529],
                            [39.36200895, -8.12233858],
                            [39.36231053, -8.12309267],
                            [39.36321522, -8.124073],
                            [39.36404455, -8.12475171],
                            [39.36434611, -8.12610909],
                            [39.36517541, -8.12776808],
                            [39.36585396, -8.12822056],
                            [39.36615551, -8.12754186],
                            [39.3674372, -8.12708942],
                            [39.36856809, -8.12648614],
                            [39.36917124, -8.12580746],
                            [39.36902046, -8.12512877],
                            [39.3685681, -8.12505336],
                            [39.3685681, -8.12399762],
                            [39.3687189, -8.12279107],
                            [39.36871891, -8.12135828],
                            [39.36841735, -8.12030256],
                            [39.36834196, -8.11902058],
                            [39.36811579, -8.11705992],
                            [39.3681158, -8.11585337],
                            [39.36819119, -8.11404353],
                            [39.3681912, -8.11245993],
                            [39.36871895, -8.11065011],
                            [39.36894513, -8.11004682],
                            [39.36894514, -8.10974517],
                            [39.36894514, -8.10914191],
                            [39.36894514, -8.10861403],
                            [39.36871896, -8.10816158],
                            [39.36826661, -8.10785993],
                            [39.36773887, -8.10755829],
                            [39.36773887, -8.10695503],
                            [39.36804044, -8.10620092],
                            [39.3684928, -8.10537141],
                            [39.36894516, -8.10514518],
                            [39.36917134, -8.10469274],
                            [39.37000065, -8.10424027],
                            [39.37030223, -8.10371242],
                            [39.37037762, -8.10295831],
                            [39.37007606, -8.10235504],
                            [39.36993055, -8.10184568],
                            [39.37006333, -8.10186339],
                            [39.37034141, -8.10191396],
                            [39.37069109, -8.10196196],
                            [39.37070169, -8.10224971],
                            [39.37070824, -8.10241976],
                            [39.3707186, -8.10268903],
                            [39.3707186, -8.10297628],
                            [39.37085373, -8.10333112],
                            [39.37085373, -8.10366907],
                            [39.37085373, -8.10404081],
                            [39.37088752, -8.10437875],
                            [39.37088752, -8.10463222],
                            [39.3709213, -8.10500394],
                            [39.37087061, -8.10540949],
                            [39.37087061, -8.10574743],
                            [39.37087061, -8.10606848],
                            [39.37085372, -8.10637262],
                            [39.37083682, -8.10660919],
                            [39.37083682, -8.10691333],
                            [39.37078614, -8.10716678],
                            [39.37075236, -8.10747095],
                            [39.37075235, -8.10774129],
                            [39.37068478, -8.10797785],
                            [39.370651, -8.1083327],
                            [39.37058344, -8.10867064],
                            [39.37060029, -8.1089917],
                            [39.37066787, -8.10926205],
                            [39.37076924, -8.10956618],
                            [39.37081992, -8.10988724],
                            [39.37090438, -8.11019139],
                            [39.37090438, -8.11047865],
                            [39.37100574, -8.1107828],
                            [39.37100574, -8.11108693],
                            [39.37100574, -8.11132351],
                            [39.37100573, -8.11159386],
                            [39.3710902, -8.11188111],
                            [39.37119155, -8.11220216],
                            [39.37130983, -8.11250631],
                            [39.37147876, -8.11275978],
                            [39.37173214, -8.11306391],
                            [39.37195177, -8.11333428],
                            [39.37210381, -8.11357084],
                            [39.37228963, -8.11382431],
                            [39.37249235, -8.11407776],
                            [39.37271195, -8.11429743],
                            [39.37295597, -8.11458906],
                            [39.37327632, -8.11494954],
                            [39.37351657, -8.11531001],
                            [39.37343651, -8.11579063],
                            [39.37351657, -8.11631132],
                            [39.3734365, -8.11699221],
                            [39.37319622, -8.11751289],
                            [39.37291591, -8.11811368],
                            [39.37259556, -8.11887468],
                            [39.37243539, -8.11947547],
                            [39.37243539, -8.12007625],
                            [39.3726356, -8.12071712],
                            [39.37275573, -8.12115769],
                            [39.37273562, -8.12176199],
                            [39.37273562, -8.1220409],
                            [39.37286683, -8.12230337],
                            [39.37299803, -8.12251666],
                            [39.37305198, -8.12263999],
                            [39.37311282, -8.12277915],
                            [39.37317846, -8.1229596],
                            [39.37324403, -8.12317288],
                            [39.37324403, -8.12343536],
                            [39.37326045, -8.12364864],
                            [39.37326045, -8.12396035],
                            [39.37327684, -8.12428846],
                            [39.37334246, -8.1246986],
                            [39.37342444, -8.12509234],
                            [39.37342444, -8.12548607],
                            [39.37342444, -8.12576495],
                            [39.37344086, -8.12602745],
                            [39.37344085, -8.12632276],
                            [39.37344085, -8.12660164],
                            [39.37344085, -8.12688053],
                            [39.37344085, -8.12719225],
                            [39.37335885, -8.12743832],
                            [39.37329324, -8.12771722],
                            [39.37324401, -8.1279961],
                            [39.37326043, -8.12830781],
                            [39.37332605, -8.12853748],
                            [39.37342442, -8.12870154],
                            [39.37353926, -8.128882],
                            [39.37363768, -8.12909529],
                            [39.37375247, -8.12929215],
                            [39.37391649, -8.12950543],
                            [39.37401491, -8.12976792],
                            [39.3741133, -8.12993198],
                            [39.37426093, -8.13009604],
                            [39.37439216, -8.13022727],
                            [39.37457257, -8.13022727],
                            [39.37478578, -8.13017806],
                            [39.37499901, -8.130014],
                            [39.37513023, -8.12981713],
                            [39.37526146, -8.12963668],
                            [39.37535984, -8.12948903],
                            [39.37550748, -8.12937418],
                            [39.37570428, -8.12934138],
                            [39.37585192, -8.12937418],
                            [39.37596676, -8.12952183],
                            [39.37599952, -8.12968589],
                            [39.37603233, -8.12989917],
                            [39.37603233, -8.13014526],
                            [39.37598313, -8.13039134],
                            [39.37595033, -8.13067023],
                            [39.3758683, -8.1308671],
                            [39.37572071, -8.13117881],
                            [39.37563868, -8.13135927],
                            [39.37537627, -8.13152333],
                            [39.37506463, -8.13160534],
                            [39.37463817, -8.13162175],
                            [39.37422813, -8.13155612],
                            [39.37390009, -8.13147409],
                            [39.37365407, -8.13139206],
                            [39.37344083, -8.13134285],
                            [39.37316202, -8.13134285],
                            [39.37299799, -8.13134284],
                            [39.37281758, -8.13140847],
                            [39.37271914, -8.13163813],
                            [39.37271914, -8.13188423],
                            [39.37275195, -8.1320975],
                            [39.37286677, -8.13229436],
                            [39.37306358, -8.13254046],
                            [39.37321121, -8.13277013],
                            [39.37344082, -8.13303261],
                            [39.37360485, -8.1332623],
                            [39.37367045, -8.13352478],
                            [39.37367045, -8.13383648],
                            [39.37330962, -8.13406616],
                            [39.37289954, -8.13419741],
                            [39.3723091, -8.13418099],
                            [39.37178425, -8.13404975],
                            [39.37140701, -8.13393491],
                            [39.37102973, -8.13377087],
                            [39.37066892, -8.13368882],
                            [39.37040649, -8.13368882],
                            [39.37007845, -8.13380366],
                            [39.36979961, -8.13396772],
                            [39.36943876, -8.13416459],
                            [39.36911073, -8.13431224],
                            [39.36870071, -8.1345091],
                            [39.36841003, -8.13467447],
                            [39.3683084, -8.13475921],
                            [39.36852848, -8.13473475],
                            [39.36875858, -8.13473475],
                            [39.36896314, -8.13470917],
                            [39.36930829, -8.13468361],
                            [39.36971739, -8.13468361],
                            [39.36993469, -8.13468361],
                            [39.37008811, -8.13468361],
                            [39.37022874, -8.13472196],
                            [39.370331, -8.13478589],
                            [39.3704844, -8.13484983],
                            [39.37068897, -8.13484983],
                            [39.37086792, -8.1347987],
                            [39.37109805, -8.1347987],
                            [39.37125145, -8.13483705],
                            [39.37139207, -8.13490098],
                            [39.37155827, -8.13504163],
                            [39.37178838, -8.13504164],
                            [39.37199292, -8.13504164],
                            [39.37221024, -8.13513115],
                            [39.37237644, -8.13518229],
                            [39.37254262, -8.13533574],
                            [39.37273436, -8.13545083],
                            [39.37292613, -8.13565541],
                            [39.37306675, -8.13582165],
                            [39.37324574, -8.1359623],
                            [39.3735142, -8.13614132],
                            [39.37382101, -8.13623082],
                            [39.37410227, -8.13626919],
                            [39.37437073, -8.13634591],
                            [39.37449857, -8.13643541],
                            [39.37463918, -8.13656329],
                            [39.37486927, -8.13674231],
                            [39.37504826, -8.13676788],
                            [39.37531673, -8.13676788],
                            [39.37557241, -8.13676788],
                            [39.37581531, -8.13670395],
                            [39.37594313, -8.13658888],
                            [39.37594313, -8.13647378],
                            [39.37582809, -8.13634591],
                            [39.37568747, -8.13625639],
                            [39.37549571, -8.13616689],
                            [39.37535509, -8.13611574],
                            [39.37522726, -8.13602624],
                            [39.37515056, -8.13596231],
                            [39.37508664, -8.13586002],
                            [39.37500991, -8.13571935],
                            [39.37503549, -8.13554035],
                            [39.37513776, -8.13545084],
                            [39.3753423, -8.13545084],
                            [39.3755852, -8.13543805],
                            [39.37581531, -8.13541249],
                            [39.37603263, -8.13536132],
                            [39.37633945, -8.13533576],
                            [39.37656957, -8.13527182],
                            [39.37685081, -8.13524624],
                            [39.37711926, -8.13515674],
                            [39.37728546, -8.13509281],
                            [39.37751558, -8.13502888],
                            [39.37760506, -8.13488823],
                            [39.37754114, -8.13470921],
                            [39.37747724, -8.13447906],
                            [39.37747724, -8.13426169],
                            [39.37760506, -8.13401872],
                            [39.37779681, -8.13386529],
                            [39.37788632, -8.13373742],
                            [39.37800136, -8.13360955],
                            [39.37823148, -8.13353284],
                            [39.37843602, -8.13352004],
                            [39.37864057, -8.13358397],
                            [39.37884511, -8.13367349],
                            [39.37907522, -8.13371184],
                            [39.37939479, -8.13372463],
                            [39.37968886, -8.13372463],
                            [39.38003401, -8.1336735],
                            [39.38016185, -8.1335712],
                            [39.38032803, -8.13345611],
                            [39.38043031, -8.13331546],
                            [39.38057093, -8.13314924],
                            [39.38071155, -8.132983],
                            [39.38092889, -8.13281678],
                            [39.38112063, -8.13271448],
                            [39.38137632, -8.13271448],
                            [39.38156808, -8.13274006],
                            [39.3817087, -8.13288072],
                            [39.3818493, -8.13305972],
                            [39.38204109, -8.13322597],
                            [39.38222007, -8.13316204],
                            [39.38225841, -8.13291907],
                            [39.38229677, -8.13272727],
                            [39.38238624, -8.13247155],
                            [39.38248853, -8.13219023],
                            [39.382578, -8.13196006],
                            [39.38270585, -8.13167876],
                            [39.38282091, -8.13142303],
                            [39.38293596, -8.13119286],
                            [39.38302545, -8.13093714],
                            [39.38337063, -8.13024664],
                            [39.38348569, -8.12991419],
                            [39.38369023, -8.129505],
                            [39.38384362, -8.1290319],
                            [39.38403539, -8.12840535],
                            [39.38417602, -8.1279706],
                            [39.38432943, -8.12753584],
                            [39.38447007, -8.12717781],
                            [39.38464902, -8.12664077],
                            [39.38486637, -8.12610372],
                            [39.38504532, -8.12569455],
                            [39.38523709, -8.12531095],
                            [39.38546723, -8.12487618],
                            [39.38569733, -8.12435195],
                            [39.38591464, -8.12396833],
                            [39.38614477, -8.12346964],
                            [39.38634931, -8.12298376],
                            [39.38655387, -8.12251064],
                            [39.38679677, -8.12201197],
                            [39.38705243, -8.12130868],
                            [39.38723141, -8.12075886],
                            [39.38747431, -8.12019623],
                            [39.38774279, -8.11958247],
                            [39.38801125, -8.11901986],
                            [39.38826693, -8.11845724],
                            [39.38850979, -8.11802248],
                            [39.38868412, -8.11766654],
                            [39.38881664, -8.11739593],
                            [39.38909789, -8.1168461],
                            [39.389328, -8.11642414],
                            [39.38953254, -8.11583594],
                            [39.38986494, -8.11520939],
                            [39.3900567, -8.11462119],
                            [39.39029958, -8.11400743],
                            [39.3906064, -8.1132658],
                            [39.39084929, -8.1125881],
                            [39.39102828, -8.11197434],
                            [39.3910922, -8.11162911],
                            [39.39115612, -8.11129662],
                            [39.39120726, -8.11092582],
                            [39.39120727, -8.11069565],
                            [39.39120727, -8.11047828],
                            [39.39113057, -8.11033762],
                            [39.39104109, -8.11024811],
                            [39.39087488, -8.1101714],
                            [39.39074704, -8.1101714],
                            [39.3906959, -8.1102609],
                            [39.39070868, -8.11038878],
                            [39.39073425, -8.1105678],
                            [39.39079817, -8.11079795],
                            [39.39082373, -8.11109204],
                            [39.39077259, -8.11142449],
                            [39.39067033, -8.11180811],
                            [39.39050413, -8.11217892],
                            [39.39036351, -8.11240908],
                            [39.39018453, -8.11262644],
                            [39.38996721, -8.11279269],
                            [39.38977544, -8.11298449],
                            [39.38959648, -8.11315071],
                            [39.38946864, -8.11327858],
                            [39.38937915, -8.11339366],
                            [39.3893408, -8.11354709],
                            [39.38934079, -8.113854],
                            [39.38932801, -8.11402021],
                            [39.38927687, -8.11414808],
                            [39.38907233, -8.11436546],
                            [39.38889336, -8.11457005],
                            [39.38872717, -8.11477463],
                            [39.3886121, -8.11496645],
                            [39.3884459, -8.11522217],
                            [39.38827972, -8.1154907],
                            [39.38812632, -8.11570807],
                            [39.38794734, -8.11589987],
                            [39.38779394, -8.11611725],
                            [39.38761496, -8.11624512],
                            [39.38744875, -8.11629627],
                            [39.38730813, -8.11629627],
                            [39.38715473, -8.11620675],
                            [39.38710359, -8.11604052],
                            [39.38707803, -8.11587428],
                            [39.38712917, -8.11564413],
                            [39.38719308, -8.11543955],
                            [39.38729535, -8.1152989],
                            [39.38741041, -8.11511988],
                            [39.38748711, -8.11491528],
                            [39.38761497, -8.11467233],
                            [39.38780673, -8.11431431],
                            [39.38798569, -8.11402021],
                            [39.38815189, -8.11382842],
                            [39.38833087, -8.11355987],
                            [39.38839479, -8.11334251],
                            [39.38842035, -8.11313792],
                            [39.38833087, -8.11293334],
                            [39.38821581, -8.11283105],
                            [39.38806241, -8.11280547],
                            [39.38789622, -8.11280547],
                            [39.38778117, -8.11283105],
                            [39.38762776, -8.11294612],
                            [39.3874232, -8.11311235],
                            [39.38724422, -8.1133425],
                            [39.38700134, -8.11352152],
                            [39.38686072, -8.1137261],
                            [39.38670731, -8.1139307],
                            [39.38664338, -8.11413529],
                            [39.38656668, -8.11445495],
                            [39.38656668, -8.11474904],
                            [39.38650276, -8.11510709],
                            [39.38640048, -8.11536283],
                            [39.3862215, -8.11554183],
                            [39.38605532, -8.11564413],
                            [39.38586357, -8.11569528],
                            [39.38564623, -8.11569527],
                            [39.38545449, -8.11559297],
                            [39.38530107, -8.11545232],
                            [39.3852755, -8.11523495],
                            [39.38530107, -8.11495364],
                            [39.38532664, -8.11464675],
                            [39.38523714, -8.1144038],
                            [39.38505818, -8.11419921],
                            [39.38491755, -8.11405856],
                            [39.38481529, -8.11393069],
                            [39.38478973, -8.11377724],
                            [39.38478973, -8.11361101],
                            [39.38491755, -8.11350872],
                            [39.38517323, -8.11338086],
                            [39.3854545, -8.11316349],
                            [39.38567182, -8.11301004],
                            [39.38587636, -8.11279267],
                            [39.38609368, -8.11254971],
                            [39.38619594, -8.11229397],
                            [39.386311, -8.11207661],
                            [39.38633658, -8.11183367],
                            [39.38633658, -8.11164187],
                            [39.38633658, -8.11133497],
                            [39.38633658, -8.11109202],
                            [39.38634936, -8.11068285],
                            [39.38645166, -8.11022251],
                            [39.3865667, -8.10987728],
                            [39.3867329, -8.10958319],
                            [39.38684796, -8.10936582],
                            [39.38700136, -8.10911007],
                            [39.38714198, -8.10886712],
                            [39.38725702, -8.10867532],
                            [39.3873593, -8.10862417],
                            [39.38756384, -8.10858582],
                            [39.38779397, -8.10841959],
                            [39.38801129, -8.108215],
                            [39.38817748, -8.10785697],
                            [39.38826697, -8.10756287],
                            [39.38836924, -8.10724322],
                            [39.38843316, -8.1068596],
                            [39.3884843, -8.10665501],
                            [39.388561, -8.1063865],
                            [39.38862492, -8.10619469],
                            [39.38870162, -8.10597733],
                            [39.38870162, -8.10577271],
                            [39.38859936, -8.10560649],
                            [39.3884843, -8.10567042],
                            [39.38842038, -8.10592618],
                            [39.38834368, -8.10618191],
                            [39.38821584, -8.10645043],
                            [39.38806244, -8.1066678],
                            [39.3879346, -8.10689795],
                            [39.38783232, -8.10710256],
                            [39.38774284, -8.10729435],
                            [39.387615, -8.107435],
                            [39.38748715, -8.1075373],
                            [39.38733375, -8.10760123],
                            [39.38723147, -8.10767795],
                            [39.38715477, -8.10779303],
                            [39.38710363, -8.10793368],
                            [39.38701415, -8.1081127],
                            [39.38688631, -8.10833008],
                            [39.38675847, -8.10845795],
                            [39.38657949, -8.10852188],
                            [39.38643887, -8.1085986],
                            [39.38623432, -8.10861138],
                            [39.38606813, -8.10861138],
                            [39.38595308, -8.1086881],
                            [39.38591472, -8.10885433],
                            [39.3859275, -8.10904613],
                            [39.38591472, -8.1092763],
                            [39.38582523, -8.10953204],
                            [39.38574853, -8.10972384],
                            [39.38563347, -8.10991564],
                            [39.38549285, -8.11005629],
                            [39.38539058, -8.11022251],
                            [39.3852883, -8.11040154],
                            [39.3852116, -8.11061891],
                            [39.38512212, -8.11093858],
                            [39.38505819, -8.11128381],
                            [39.3849687, -8.11164186],
                            [39.38485365, -8.11184645],
                            [39.38468746, -8.1118976],
                            [39.3844957, -8.1118976],
                            [39.38436786, -8.11189759],
                            [39.38429116, -8.11182086],
                            [39.38422724, -8.11169299],
                            [39.38421446, -8.11145004],
                            [39.38421446, -8.11114316],
                            [39.38421446, -8.11084907],
                            [39.38422725, -8.11054219],
                            [39.38422725, -8.11020972],
                            [39.38417611, -8.10990285],
                            [39.38407383, -8.10954481],
                            [39.38390764, -8.10917399],
                            [39.38385652, -8.10885432],
                            [39.38376702, -8.10861137],
                            [39.38371589, -8.10833007],
                            [39.38366475, -8.1080871],
                            [39.38366475, -8.1078058],
                            [39.38363919, -8.10748613],
                            [39.38365197, -8.10720483],
                            [39.38371589, -8.10684679],
                            [39.38384373, -8.10648876],
                            [39.38392043, -8.10623303],
                            [39.38397157, -8.10597731],
                            [39.38416333, -8.10560647],
                            [39.38435511, -8.10533795],
                            [39.38457243, -8.105095],
                            [39.38485368, -8.10478814],
                            [39.38512214, -8.1045068],
                            [39.38524997, -8.10419993],
                            [39.3853139, -8.10399535],
                            [39.3853139, -8.10371405],
                            [39.38531391, -8.10343271],
                            [39.38531633, -8.10341332],
                            [39.38535227, -8.10312584],
                            [39.38535227, -8.10287011],
                            [39.38530113, -8.10265274],
                            [39.38519884, -8.10239699],
                            [39.38512215, -8.10212847],
                            [39.38512215, -8.10188552],
                        ],
                        [
                            [39.39147738, -8.10629534],
                            [39.39185281, -8.10653005],
                            [39.39233774, -8.10678038],
                            [39.39257238, -8.10724977],
                            [39.39254109, -8.10765658],
                            [39.39200923, -8.10836066],
                            [39.39144606, -8.10889263],
                            [39.39086729, -8.10912733],
                            [39.39075779, -8.10936203],
                            [39.39111759, -8.10947155],
                            [39.39158687, -8.1094246],
                            [39.39199358, -8.10936203],
                            [39.39236901, -8.10903346],
                            [39.39271315, -8.10873617],
                            [39.39296344, -8.10840759],
                            [39.39330758, -8.10814162],
                            [39.39366737, -8.10757835],
                            [39.39371431, -8.10701507],
                            [39.39369867, -8.10654569],
                            [39.39362046, -8.10615452],
                            [39.39333888, -8.10563819],
                            [39.39304167, -8.10545043],
                            [39.39244724, -8.10532527],
                            [39.39171202, -8.10534091],
                            [39.39082038, -8.10551301],
                            [39.39060139, -8.10568511],
                            [39.39061703, -8.10584159],
                            [39.39072652, -8.10595111],
                            [39.39094553, -8.1059824],
                            [39.39113324, -8.10606063],
                            [39.39147738, -8.10629534],
                        ],
                        [
                            [39.32828204, -8.13618094],
                            [39.32801056, -8.13645249],
                            [39.32777784, -8.13678222],
                            [39.32754514, -8.13705377],
                            [39.32727364, -8.13715076],
                            [39.32702155, -8.13715075],
                            [39.32665311, -8.13715075],
                            [39.32634283, -8.13701498],
                            [39.32599378, -8.13691799],
                            [39.32581924, -8.13672401],
                            [39.3257029, -8.13639428],
                            [39.32570291, -8.13616152],
                            [39.32554776, -8.13602576],
                            [39.32535384, -8.13592879],
                            [39.32512115, -8.13592879],
                            [39.32490783, -8.13588998],
                            [39.32467513, -8.13606454],
                            [39.3244812, -8.13625852],
                            [39.3244812, -8.13654944],
                            [39.32469452, -8.13668524],
                            [39.32498538, -8.13676281],
                            [39.32529567, -8.13689861],
                            [39.32547019, -8.13699558],
                            [39.32554775, -8.13717014],
                            [39.32556714, -8.13746109],
                            [39.32541202, -8.13775203],
                            [39.3251599, -8.13794599],
                            [39.32498538, -8.13810116],
                            [39.32486904, -8.13839212],
                            [39.32469451, -8.13866365],
                            [39.32486904, -8.13905158],
                            [39.32504356, -8.13932313],
                            [39.32523747, -8.13959469],
                            [39.32531505, -8.13990502],
                            [39.32531504, -8.14021536],
                            [39.32523747, -8.14048693],
                            [39.3251793, -8.14075846],
                            [39.3249466, -8.14106882],
                            [39.32465571, -8.14106882],
                            [39.32440361, -8.14095242],
                            [39.32420969, -8.14099122],
                            [39.32409334, -8.14112698],
                            [39.32399637, -8.14151491],
                            [39.32395761, -8.14198044],
                            [39.32407394, -8.14225199],
                            [39.3244036, -8.14231017],
                            [39.32475268, -8.14203864],
                            [39.32512112, -8.14178648],
                            [39.32552834, -8.14145675],
                            [39.32593558, -8.14103002],
                            [39.32634281, -8.14056451],
                            [39.32676944, -8.14013779],
                            [39.3270991, -8.13969168],
                            [39.32737059, -8.13928436],
                            [39.32756452, -8.1390322],
                            [39.32797176, -8.13883823],
                            [39.32824323, -8.13883823],
                            [39.32849533, -8.13899342],
                            [39.32861168, -8.13930376],
                            [39.32861168, -8.13963349],
                            [39.32834021, -8.13996324],
                            [39.32808809, -8.14037054],
                            [39.32773904, -8.14081667],
                            [39.32748694, -8.14106883],
                            [39.32735119, -8.14135976],
                            [39.32721546, -8.14159252],
                            [39.32700214, -8.14188346],
                            [39.32675004, -8.14215501],
                            [39.32655611, -8.14240717],
                            [39.32634281, -8.14263993],
                            [39.32605193, -8.1427951],
                            [39.32578044, -8.14300846],
                            [39.32547017, -8.14320242],
                            [39.32521807, -8.14345456],
                            [39.3249078, -8.14368732],
                            [39.32475267, -8.14407525],
                            [39.32461691, -8.1443468],
                            [39.3246363, -8.1443468],
                            [39.32451995, -8.14457955],
                            [39.32434544, -8.1448899],
                            [39.32422907, -8.14518083],
                            [39.32411271, -8.14539419],
                            [39.32395759, -8.14558815],
                            [39.32374427, -8.14570455],
                            [39.32355036, -8.14585972],
                            [39.32355035, -8.14607307],
                            [39.32355035, -8.1464028],
                            [39.32345339, -8.14669375],
                            [39.32324007, -8.1469459],
                            [39.32300737, -8.14729503],
                            [39.32269708, -8.147489],
                            [39.32215412, -8.14807089],
                            [39.32188263, -8.14836183],
                            [39.32132024, -8.14884673],
                            [39.32112633, -8.14917646],
                            [39.32099058, -8.14938983],
                            [39.32089363, -8.14952562],
                            [39.32100999, -8.14979716],
                            [39.32114571, -8.15024327],
                            [39.32124268, -8.15061181],
                            [39.32124267, -8.15105792],
                            [39.32118449, -8.15160102],
                            [39.32120389, -8.15181439],
                            [39.32137841, -8.15189197],
                            [39.3216887, -8.15179498],
                            [39.3221347, -8.1516786],
                            [39.32261951, -8.15148465],
                            [39.32304613, -8.15134887],
                            [39.3233952, -8.15111611],
                            [39.32368606, -8.15082519],
                            [39.32399633, -8.15043726],
                            [39.32428723, -8.14995234],
                            [39.32465567, -8.14937045],
                            [39.32500474, -8.14884675],
                            [39.32537318, -8.14822607],
                            [39.32570286, -8.14774117],
                            [39.3260907, -8.14733384],
                            [39.32655609, -8.14694592],
                            [39.32700212, -8.14640282],
                            [39.32737056, -8.14609248],
                            [39.32781659, -8.14568516],
                            [39.32826261, -8.14527783],
                            [39.32880557, -8.1449675],
                            [39.32936794, -8.14454078],
                            [39.32985275, -8.14409467],
                            [39.33043451, -8.14362914],
                            [39.33091931, -8.14314424],
                            [39.33128777, -8.14273692],
                            [39.33165621, -8.1423102],
                            [39.3319471, -8.14188348],
                            [39.33227676, -8.14167013],
                            [39.33260644, -8.1412822],
                            [39.33283913, -8.14093306],
                            [39.33283913, -8.14066153],
                            [39.33283914, -8.14031238],
                            [39.33272278, -8.14011842],
                            [39.33252887, -8.13996325],
                            [39.33229616, -8.13988566],
                            [39.33198589, -8.13980807],
                            [39.33183076, -8.13961411],
                            [39.33169501, -8.13934257],
                            [39.33150109, -8.13901281],
                            [39.33128778, -8.13870248],
                            [39.33111326, -8.13839214],
                            [39.33091933, -8.13800421],
                            [39.3307642, -8.13759689],
                            [39.33058968, -8.13717016],
                            [39.33043454, -8.13674344],
                            [39.33033758, -8.13627794],
                            [39.33014365, -8.13596758],
                            [39.32998854, -8.13563785],
                            [39.3298334, -8.13538569],
                            [39.32965888, -8.13513355],
                            [39.32940676, -8.13507535],
                            [39.32909651, -8.13509474],
                            [39.32884441, -8.13523052],
                            [39.32857292, -8.13544388],
                            [39.32845656, -8.13585121],
                            [39.32828204, -8.13618094],
                        ],
                        [
                            [39.35764624, -8.13897531],
                            [39.35758897, -8.13926169],
                            [39.3574401, -8.13942204],
                            [39.3572225, -8.13954805],
                            [39.35698203, -8.13965113],
                            [39.35684459, -8.1398115],
                            [39.35679878, -8.14000623],
                            [39.3566728, -8.1401895],
                            [39.35655829, -8.14039569],
                            [39.35639795, -8.14054459],
                            [39.35620329, -8.14062478],
                            [39.35596278, -8.14064767],
                            [39.35573373, -8.14068204],
                            [39.3555505, -8.14072787],
                            [39.35541308, -8.14078515],
                            [39.35527565, -8.14086532],
                            [39.35513823, -8.14096841],
                            [39.35502372, -8.14110586],
                            [39.35498936, -8.14128913],
                            [39.35486338, -8.14138077],
                            [39.35456563, -8.14146094],
                            [39.35429078, -8.14152967],
                            [39.35426787, -8.14163278],
                            [39.35407319, -8.14166715],
                            [39.35388995, -8.14166715],
                            [39.35372963, -8.14173586],
                            [39.35363801, -8.14186185],
                            [39.35356931, -8.14196495],
                            [39.35354639, -8.14209096],
                            [39.35351204, -8.14226277],
                            [39.35343187, -8.14241169],
                            [39.3533517, -8.14257205],
                            [39.35317993, -8.14273242],
                            [39.35295087, -8.14289278],
                            [39.35272185, -8.14303024],
                            [39.35252716, -8.14321351],
                            [39.35230955, -8.14330515],
                            [39.35213778, -8.14347696],
                            [39.35202327, -8.14363733],
                            [39.35196602, -8.14379768],
                            [39.3519431, -8.14393514],
                            [39.35192019, -8.14407259],
                            [39.35182857, -8.1441757],
                            [39.35156519, -8.14431316],
                            [39.35104985, -8.14454223],
                            [39.35080934, -8.14472552],
                            [39.35062611, -8.14481714],
                            [39.35045432, -8.14492022],
                            [39.3502711, -8.14500042],
                            [39.35014513, -8.14500042],
                            [39.35003059, -8.14488589],
                            [39.34993899, -8.14475988],
                            [39.34977866, -8.14464532],
                            [39.34964123, -8.14457659],
                            [39.34942365, -8.14448496],
                            [39.34930912, -8.14448496],
                            [39.34918314, -8.14440476],
                            [39.34909154, -8.14433604],
                            [39.34891976, -8.14430168],
                            [39.34882814, -8.14418714],
                            [39.34880524, -8.14403824],
                            [39.34885103, -8.1438893],
                            [39.34893122, -8.14377477],
                            [39.34895412, -8.14360295],
                            [39.34881668, -8.14351131],
                            [39.34869071, -8.14353422],
                            [39.3485762, -8.14354568],
                            [39.34847314, -8.14347694],
                            [39.34830134, -8.14339677],
                            [39.34815247, -8.14339677],
                            [39.34801505, -8.14332804],
                            [39.34787762, -8.14321349],
                            [39.34769439, -8.14305311],
                            [39.34755696, -8.14293858],
                            [39.34744245, -8.14281257],
                            [39.34733938, -8.1426293],
                            [39.34720196, -8.14251476],
                            [39.34707598, -8.1425262],
                            [39.34695002, -8.14256056],
                            [39.34667515, -8.14286984],
                            [39.34653772, -8.1430073],
                            [39.34645757, -8.14314475],
                            [39.34632015, -8.14321348],
                            [39.34619417, -8.14323639],
                            [39.34613691, -8.14338529],
                            [39.34606821, -8.1435113],
                            [39.34605674, -8.1436602],
                            [39.34598804, -8.14378621],
                            [39.34598804, -8.14379765],
                            [39.34591821, -8.14400053],
                            [39.34585307, -8.14428291],
                            [39.34567933, -8.14452185],
                            [39.34557075, -8.14469563],
                            [39.34554903, -8.14495627],
                            [39.34563589, -8.14523865],
                            [39.34593994, -8.14530382],
                            [39.34617882, -8.1451735],
                            [39.3464177, -8.14510833],
                            [39.34661315, -8.14513006],
                            [39.34674342, -8.14534727],
                            [39.34663485, -8.14554276],
                            [39.3464611, -8.14571652],
                            [39.34622222, -8.14584686],
                            [39.34602679, -8.14595546],
                            [39.34583133, -8.14602062],
                            [39.34570105, -8.14612924],
                            [39.34580963, -8.14634645],
                            [39.34587476, -8.14645505],
                            [39.34585306, -8.14673743],
                            [39.34567932, -8.14686777],
                            [39.34550557, -8.14686776],
                            [39.34541873, -8.14693292],
                            [39.34544043, -8.14715013],
                            [39.34559244, -8.14738907],
                            [39.34559244, -8.14756285],
                            [39.34551848, -8.14773807],
                            [39.3453238, -8.14781824],
                            [39.34518638, -8.14793277],
                            [39.34502604, -8.14808169],
                            [39.34485427, -8.14825352],
                            [39.34467102, -8.14843679],
                            [39.34452215, -8.14863151],
                            [39.34432746, -8.14881478],
                            [39.34415568, -8.14899806],
                            [39.3439381, -8.14913552],
                            [39.34374341, -8.14935314],
                            [39.34361743, -8.14953642],
                            [39.34342275, -8.14971969],
                            [39.34323951, -8.14993733],
                            [39.34298755, -8.15021225],
                            [39.34275853, -8.15051005],
                            [39.34256383, -8.15078496],
                            [39.3423577, -8.15110571],
                            [39.34216302, -8.15132334],
                            [39.34196832, -8.15156389],
                            [39.34178509, -8.15182734],
                            [39.34160186, -8.15199915],
                            [39.34145298, -8.15217098],
                            [39.34131556, -8.15227406],
                            [39.34123539, -8.15240007],
                            [39.34116667, -8.15254897],
                            [39.34116667, -8.15267498],
                            [39.34122395, -8.15276662],
                            [39.34136137, -8.15276662],
                            [39.3415675, -8.1527208],
                            [39.34187671, -8.15257189],
                            [39.34217445, -8.15236571],
                            [39.34250656, -8.1521939],
                            [39.34279288, -8.15198772],
                            [39.34305627, -8.15180445],
                            [39.34333112, -8.15160971],
                            [39.34353726, -8.15138063],
                            [39.3437892, -8.15115152],
                            [39.34406405, -8.15085372],
                            [39.34430456, -8.15059026],
                            [39.34441907, -8.15048717],
                            [39.3446252, -8.15051006],
                            [39.3448199, -8.1505559],
                            [39.34500312, -8.15056734],
                            [39.34525507, -8.15060171],
                            [39.34544975, -8.15061316],
                            [39.34561008, -8.15070481],
                            [39.34570171, -8.15080789],
                            [39.34575895, -8.1509339],
                            [39.34581622, -8.15107136],
                            [39.34581621, -8.15123173],
                            [39.34580478, -8.15141499],
                            [39.34578186, -8.15155245],
                            [39.34577042, -8.15171282],
                            [39.34581621, -8.15182736],
                            [39.34588493, -8.15189609],
                            [39.346343, -8.151919],
                            [39.34651479, -8.15190755],
                            [39.34659496, -8.1517701],
                            [39.3466064, -8.15159826],
                            [39.34662931, -8.15140355],
                            [39.34666367, -8.15105991],
                            [39.34666367, -8.15079645],
                            [39.34678965, -8.15060171],
                            [39.34693852, -8.15061317],
                            [39.3470645, -8.15072772],
                            [39.34713321, -8.15087663],
                            [39.3471561, -8.15105991],
                            [39.34727063, -8.15122027],
                            [39.34740806, -8.15119737],
                            [39.34754547, -8.15108281],
                            [39.34756838, -8.1508881],
                            [39.34754548, -8.15072773],
                            [39.34754548, -8.150533],
                            [39.34751112, -8.15040699],
                            [39.34751112, -8.1502008],
                            [39.34751112, -8.15001755],
                            [39.34759131, -8.14997171],
                            [39.34774017, -8.15000609],
                            [39.34779744, -8.15014354],
                            [39.34788904, -8.15023517],
                            [39.34807229, -8.15036117],
                            [39.34827842, -8.15051008],
                            [39.34842729, -8.15059028],
                            [39.34870213, -8.15060172],
                            [39.34891973, -8.15060172],
                            [39.34918312, -8.15047572],
                            [39.34942362, -8.15037264],
                            [39.34967557, -8.15033827],
                            [39.3498817, -8.15032682],
                            [39.35004204, -8.15034974],
                            [39.35020237, -8.15040701],
                            [39.35033979, -8.15049865],
                            [39.35046575, -8.15056736],
                            [39.35063753, -8.15051009],
                            [39.35082077, -8.1503841],
                            [39.35110707, -8.15036119],
                            [39.35134756, -8.15033828],
                            [39.35143918, -8.15024665],
                            [39.3515079, -8.15008628],
                            [39.3515079, -8.14993736],
                            [39.3515079, -8.14979991],
                            [39.35138192, -8.14965101],
                            [39.35121013, -8.14951355],
                            [39.3509811, -8.14951355],
                            [39.35071772, -8.14951354],
                            [39.35056883, -8.1495021],
                            [39.35058027, -8.14941046],
                            [39.35066045, -8.14933028],
                            [39.35083223, -8.14919283],
                            [39.35102691, -8.14903246],
                            [39.35115289, -8.14896373],
                            [39.35131321, -8.14896373],
                            [39.35141628, -8.14902099],
                            [39.35164532, -8.14903246],
                            [39.35182855, -8.14902099],
                            [39.35202324, -8.14896373],
                            [39.35221792, -8.14896373],
                            [39.35238971, -8.14903246],
                            [39.35256149, -8.149147],
                            [39.35283634, -8.14923864],
                            [39.35307683, -8.14938755],
                            [39.35334022, -8.14952501],
                            [39.35354635, -8.14965102],
                            [39.35364943, -8.14984574],
                            [39.35363797, -8.15001757],
                            [39.35361507, -8.15016648],
                            [39.35368378, -8.15031539],
                            [39.35379829, -8.15045284],
                            [39.35388991, -8.15056738],
                            [39.35398153, -8.15068193],
                            [39.35409604, -8.15077357],
                            [39.35421059, -8.15084229],
                            [39.35442816, -8.15086522],
                            [39.35457704, -8.15088813],
                            [39.35476027, -8.15088813],
                            [39.35495496, -8.15079649],
                            [39.3551611, -8.15056738],
                            [39.35520691, -8.15040703],
                            [39.35532144, -8.15017794],
                            [39.35549321, -8.14996029],
                            [39.35563063, -8.14981139],
                            [39.35584823, -8.14966247],
                            [39.35604291, -8.14957084],
                            [39.35620325, -8.14949066],
                            [39.35647808, -8.1493303],
                            [39.35679874, -8.14923866],
                            [39.35693617, -8.14913558],
                            [39.35707359, -8.14891793],
                            [39.35719957, -8.14879194],
                            [39.35727973, -8.14856283],
                            [39.3573828, -8.14842539],
                            [39.35748587, -8.14820774],
                            [39.35746295, -8.14796719],
                            [39.35745152, -8.14776101],
                            [39.35739427, -8.14762355],
                            [39.35731409, -8.14744028],
                            [39.35726829, -8.14731429],
                            [39.35714231, -8.14722265],
                            [39.35698199, -8.14715391],
                            [39.35679875, -8.14715391],
                            [39.35658115, -8.14715391],
                            [39.35640938, -8.14717682],
                            [39.3561689, -8.14722264],
                            [39.35596275, -8.14730283],
                            [39.35571081, -8.14740592],
                            [39.35548177, -8.14755483],
                            [39.35528709, -8.14769227],
                            [39.35509239, -8.147761],
                            [39.35486336, -8.14777247],
                            [39.35468011, -8.147761],
                            [39.35449689, -8.14764646],
                            [39.35441673, -8.14754337],
                            [39.35430221, -8.14739445],
                            [39.3542793, -8.14718827],
                            [39.35434802, -8.14703937],
                            [39.35446253, -8.14691336],
                            [39.35462287, -8.14669573],
                            [39.35481757, -8.14645518],
                            [39.35492063, -8.14631772],
                            [39.35504659, -8.14607718],
                            [39.35510385, -8.145871],
                            [39.35505806, -8.14568771],
                            [39.35497788, -8.14553881],
                            [39.35487482, -8.1454128],
                            [39.35473739, -8.14533263],
                            [39.35454272, -8.14533263],
                            [39.35435948, -8.14533263],
                            [39.35418769, -8.14533263],
                            [39.35395865, -8.14533263],
                            [39.35380978, -8.14538989],
                            [39.35363799, -8.14543572],
                            [39.35343186, -8.14545862],
                            [39.35329444, -8.14549298],
                            [39.3531112, -8.14549298],
                            [39.35299669, -8.14535552],
                            [39.35299669, -8.14527535],
                            [39.35313411, -8.14509208],
                            [39.35331734, -8.14501188],
                            [39.35351203, -8.1449088],
                            [39.35397009, -8.14469116],
                            [39.35411899, -8.14454225],
                            [39.35432513, -8.14448499],
                            [39.35453126, -8.14448499],
                            [39.35486337, -8.14449643],
                            [39.3550466, -8.14464535],
                            [39.3552642, -8.14470262],
                            [39.35541307, -8.14456516],
                            [39.35558484, -8.14440479],
                            [39.35571082, -8.14430171],
                            [39.35585969, -8.14423298],
                            [39.35608874, -8.14425589],
                            [39.35632923, -8.14427882],
                            [39.35646665, -8.14416425],
                            [39.35660408, -8.14399244],
                            [39.3567415, -8.1437977],
                            [39.35679877, -8.14359152],
                            [39.35679877, -8.14337388],
                            [39.35683313, -8.14298442],
                            [39.35692475, -8.14284697],
                            [39.35711943, -8.14280115],
                            [39.35735992, -8.14273244],
                            [39.35754316, -8.14267515],
                            [39.35779509, -8.14254917],
                            [39.35801269, -8.14237733],
                            [39.35811577, -8.14214826],
                            [39.35818448, -8.14202225],
                            [39.35833335, -8.14202225],
                            [39.35853949, -8.14202225],
                            [39.35869982, -8.14191915],
                            [39.3588945, -8.14182751],
                            [39.35907773, -8.14166717],
                            [39.35926097, -8.14151825],
                            [39.3594213, -8.14136935],
                            [39.35954728, -8.14118607],
                            [39.35979921, -8.14102571],
                            [39.35998245, -8.14096844],
                            [39.36026875, -8.14086534],
                            [39.36050926, -8.14078517],
                            [39.36069248, -8.14067062],
                            [39.36081846, -8.14053316],
                            [39.36085282, -8.14039571],
                            [39.36085282, -8.14029261],
                            [39.36073828, -8.14020097],
                            [39.36057796, -8.14009787],
                            [39.36044054, -8.14009787],
                            [39.36028022, -8.14009787],
                            [39.36008553, -8.14004061],
                            [39.35989083, -8.13993752],
                            [39.35975342, -8.13983443],
                            [39.35947857, -8.13971987],
                            [39.35928387, -8.13962823],
                            [39.35919227, -8.1395366],
                            [39.35914646, -8.13943351],
                            [39.35915793, -8.13928459],
                            [39.35927244, -8.13920442],
                            [39.35944421, -8.13923878],
                            [39.35961599, -8.13928459],
                            [39.35983357, -8.1393075],
                            [39.36009698, -8.13934187],
                            [39.36028023, -8.1393075],
                            [39.36041764, -8.13922733],
                            [39.36047491, -8.13910133],
                            [39.36047491, -8.1389066],
                            [39.36047491, -8.13873477],
                            [39.36039474, -8.13856296],
                            [39.36028023, -8.13843695],
                            [39.36015425, -8.1382995],
                            [39.36005118, -8.13823077],
                            [39.35995955, -8.1382995],
                            [39.35985648, -8.13841405],
                            [39.35969616, -8.13841405],
                            [39.35962746, -8.13834532],
                            [39.35961599, -8.13825368],
                            [39.35961599, -8.13809331],
                            [39.35961599, -8.13791004],
                            [39.3595702, -8.13776112],
                            [39.35946712, -8.13761222],
                            [39.35936407, -8.13752057],
                            [39.35918082, -8.13740604],
                            [39.35905484, -8.13753204],
                            [39.35900903, -8.13768095],
                            [39.35887162, -8.13787568],
                            [39.35868839, -8.13812767],
                            [39.3584479, -8.13832241],
                            [39.35823029, -8.13835677],
                            [39.35801271, -8.13847131],
                            [39.35788673, -8.13863168],
                            [39.35777222, -8.13881495],
                            [39.35764624, -8.13897531],
                        ],
                        [
                            [39.37081317, -8.14536603],
                            [39.37113491, -8.14550012],
                            [39.37141641, -8.14571466],
                            [39.37155048, -8.14586216],
                            [39.37155048, -8.14611693],
                            [39.3713896, -8.14635828],
                            [39.37124215, -8.1465326],
                            [39.37128237, -8.1466935],
                            [39.37142983, -8.146841],
                            [39.37155048, -8.14704213],
                            [39.37169794, -8.14729688],
                            [39.37173814, -8.14757848],
                            [39.37167113, -8.14784664],
                            [39.37152366, -8.14796733],
                            [39.37130917, -8.14792711],
                            [39.37105448, -8.14775279],
                            [39.37083996, -8.14747119],
                            [39.37079976, -8.14728347],
                            [39.37069253, -8.14706894],
                            [39.37054505, -8.14688122],
                            [39.37026353, -8.14650577],
                            [39.36987477, -8.14606328],
                            [39.36953963, -8.14579511],
                            [39.36937877, -8.14571465],
                            [39.36925812, -8.14582194],
                            [39.3692045, -8.14606328],
                            [39.36928492, -8.14630464],
                            [39.36937877, -8.14658622],
                            [39.36956645, -8.14676054],
                            [39.3699284, -8.14692143],
                            [39.37012949, -8.14698849],
                            [39.37029034, -8.14714939],
                            [39.37045121, -8.14739075],
                            [39.37062548, -8.14768575],
                            [39.37070591, -8.14799413],
                            [39.37085336, -8.14832937],
                            [39.37100084, -8.14879866],
                            [39.37110808, -8.14913388],
                            [39.37128235, -8.14949591],
                            [39.371403, -8.14984454],
                            [39.37152365, -8.15019317],
                            [39.37176495, -8.15067587],
                            [39.37193923, -8.15106474],
                            [39.37205988, -8.15144018],
                            [39.37212689, -8.15185585],
                            [39.3721537, -8.15228493],
                            [39.37207328, -8.15264695],
                            [39.37191241, -8.1529956],
                            [39.37184538, -8.15343807],
                            [39.37183197, -8.1539476],
                            [39.371899, -8.15416214],
                            [39.37199284, -8.15416214],
                            [39.37215369, -8.15402805],
                            [39.37228775, -8.15385374],
                            [39.37235478, -8.15353195],
                            [39.37246202, -8.15334423],
                            [39.37255588, -8.15316991],
                            [39.37274356, -8.15292856],
                            [39.37290442, -8.15275424],
                            [39.37307869, -8.15236539],
                            [39.37326637, -8.15190949],
                            [39.37341384, -8.15150723],
                            [39.37361493, -8.15091725],
                            [39.3737758, -8.15020659],
                            [39.37392326, -8.14963001],
                            [39.37417797, -8.14922777],
                            [39.37435225, -8.14887914],
                            [39.3744193, -8.14836959],
                            [39.37428523, -8.14708237],
                            [39.37415118, -8.14653261],
                            [39.37384285, -8.14566105],
                            [39.37338706, -8.14465538],
                            [39.37307873, -8.14381065],
                            [39.37285083, -8.14344861],
                            [39.37271678, -8.14346202],
                            [39.37258273, -8.14371677],
                            [39.37251569, -8.144052],
                            [39.37250228, -8.14452131],
                            [39.37250228, -8.14503082],
                            [39.37255591, -8.14578171],
                            [39.37243526, -8.14596943],
                            [39.37227438, -8.1459158],
                            [39.37211352, -8.1457683],
                            [39.37195264, -8.14551353],
                            [39.37175156, -8.1451515],
                            [39.3716041, -8.14481628],
                            [39.37114832, -8.1443604],
                            [39.37078637, -8.14411902],
                            [39.37049146, -8.14387768],
                            [39.37029036, -8.1436095],
                            [39.36975414, -8.14331451],
                            [39.36956646, -8.14324746],
                            [39.36951283, -8.14338155],
                            [39.36964688, -8.14364973],
                            [39.36971391, -8.14406538],
                            [39.36976755, -8.14444083],
                            [39.36983456, -8.14464196],
                            [39.37026354, -8.14486991],
                            [39.3703976, -8.14499059],
                            [39.3706523, -8.14520513],
                            [39.37081317, -8.14536603],
                        ],
                        [
                            [39.36942192, -8.15446805],
                            [39.36932538, -8.15490255],
                            [39.36905992, -8.15515602],
                            [39.36890305, -8.15524048],
                            [39.36879446, -8.15502324],
                            [39.36866172, -8.1547215],
                            [39.36858931, -8.15433528],
                            [39.36846865, -8.15393701],
                            [39.3682394, -8.15327316],
                            [39.36804633, -8.15282661],
                            [39.36788946, -8.15242832],
                            [39.36779292, -8.15196968],
                            [39.36764813, -8.15158346],
                            [39.36751539, -8.15134207],
                            [39.36741886, -8.15129379],
                            [39.36727407, -8.15142655],
                            [39.36715338, -8.15157139],
                            [39.36720165, -8.15180071],
                            [39.36724992, -8.1519576],
                            [39.36735853, -8.15217486],
                            [39.36739473, -8.15242831],
                            [39.36727406, -8.1527904],
                            [39.36696031, -8.15301972],
                            [39.36653798, -8.15301972],
                            [39.36629666, -8.15311627],
                            [39.36615185, -8.15333352],
                            [39.36597084, -8.15379214],
                            [39.36559678, -8.15419043],
                            [39.36523477, -8.15443182],
                            [39.36495725, -8.15456459],
                            [39.36463145, -8.15483012],
                            [39.36445046, -8.15497495],
                            [39.36461937, -8.15504735],
                            [39.36490897, -8.15498701],
                            [39.36513825, -8.15492668],
                            [39.36552439, -8.15476976],
                            [39.36585019, -8.15463702],
                            [39.36613979, -8.15451632],
                            [39.36636906, -8.15427493],
                            [39.36655005, -8.15417837],
                            [39.36668278, -8.15417837],
                            [39.36676725, -8.154287],
                            [39.36681551, -8.1544077],
                            [39.36676724, -8.15476976],
                            [39.36676724, -8.15504736],
                            [39.36697239, -8.15521635],
                            [39.36718957, -8.1551198],
                            [39.36728611, -8.15490254],
                            [39.36738264, -8.15472149],
                            [39.36740678, -8.1544801],
                            [39.36752745, -8.15434734],
                            [39.36763606, -8.15433528],
                            [39.36775671, -8.15444389],
                            [39.36782913, -8.15446804],
                            [39.36790151, -8.1544077],
                            [39.36807044, -8.15433528],
                            [39.36815491, -8.15434735],
                            [39.36820319, -8.15446804],
                            [39.36822731, -8.15474564],
                            [39.36822731, -8.15521635],
                            [39.36828765, -8.1556629],
                            [39.36828765, -8.15591637],
                            [39.36813076, -8.15592844],
                            [39.36798597, -8.15581982],
                            [39.36785324, -8.15561463],
                            [39.36773258, -8.15560256],
                            [39.36770844, -8.15583189],
                            [39.36770843, -8.15619397],
                            [39.3677567, -8.15662846],
                            [39.36774463, -8.15703883],
                            [39.36762398, -8.15715951],
                            [39.36751536, -8.15706296],
                            [39.36741883, -8.15685778],
                            [39.36734645, -8.15658018],
                            [39.36731025, -8.15638708],
                            [39.36722578, -8.15608534],
                            [39.36716544, -8.15577154],
                            [39.36697239, -8.15561462],
                            [39.36679138, -8.15559049],
                            [39.36664659, -8.15581982],
                            [39.36658623, -8.1561819],
                            [39.3664897, -8.15651984],
                            [39.36626043, -8.15655605],
                            [39.36588636, -8.15650775],
                            [39.36562091, -8.15658017],
                            [39.36527097, -8.15662845],
                            [39.36494517, -8.15670088],
                            [39.36466763, -8.15686983],
                            [39.36446251, -8.1571233],
                            [39.36446251, -8.15749745],
                            [39.3646073, -8.15773884],
                            [39.36483657, -8.15796816],
                            [39.36495724, -8.15820955],
                            [39.36475209, -8.15839058],
                            [39.3643177, -8.15834231],
                            [39.36390744, -8.15824576],
                            [39.36344889, -8.15823367],
                            [39.36297828, -8.15824575],
                            [39.3627249, -8.15845091],
                            [39.36267664, -8.15876472],
                            [39.3626887, -8.15909059],
                            [39.36282143, -8.15940439],
                            [39.36320755, -8.15960958],
                            [39.36365403, -8.15970613],
                            [39.36410048, -8.15971822],
                            [39.36451076, -8.15966993],
                            [39.36464349, -8.1596458],
                            [39.36499342, -8.15966993],
                            [39.36511408, -8.15983892],
                            [39.36506583, -8.16022513],
                            [39.36508996, -8.16059928],
                            [39.36512616, -8.16092515],
                            [39.36527095, -8.16109412],
                            [39.36554848, -8.16123897],
                            [39.36569329, -8.16139586],
                            [39.36558468, -8.16160104],
                            [39.36537956, -8.1620114],
                            [39.36512615, -8.16233727],
                            [39.36496928, -8.16269935],
                            [39.36490894, -8.16306144],
                            [39.36489687, -8.16339939],
                            [39.36488481, -8.16373733],
                            [39.36477622, -8.16394249],
                            [39.36458315, -8.16422009],
                            [39.3643418, -8.16440113],
                            [39.3641246, -8.16461839],
                            [39.36402807, -8.16493217],
                            [39.36377466, -8.16535462],
                            [39.36359365, -8.16577704],
                            [39.36330647, -8.16641189],
                            [39.36306515, -8.16677881],
                            [39.36276588, -8.16709743],
                            [39.36254386, -8.16728088],
                            [39.36235079, -8.16744502],
                            [39.36217704, -8.16758986],
                            [39.36209015, -8.16773468],
                            [39.36203222, -8.16791814],
                            [39.36214807, -8.16816918],
                            [39.36227357, -8.16829471],
                            [39.36252454, -8.16837196],
                            [39.36281414, -8.16844919],
                            [39.36302652, -8.16854574],
                            [39.36320993, -8.16869058],
                            [39.36335474, -8.16884508],
                            [39.36339335, -8.16903819],
                            [39.36334508, -8.16922165],
                            [39.36324855, -8.16932784],
                            [39.36314235, -8.16945338],
                            [39.36309409, -8.16967545],
                            [39.36311306, -8.16986521],
                            [39.36292177, -8.16998129],
                            [39.36286299, -8.17017448],
                            [39.36278741, -8.17027528],
                            [39.36256067, -8.17028369],
                            [39.36220797, -8.17028369],
                            [39.36196443, -8.17028369],
                            [39.36170411, -8.17030888],
                            [39.36152776, -8.17040968],
                            [39.36131781, -8.17061965],
                            [39.36114146, -8.17081284],
                            [39.3610407, -8.17105643],
                            [39.36091472, -8.17125802],
                            [39.36082319, -8.17139242],
                            [39.36062896, -8.17139242],
                            [39.36033193, -8.17139242],
                            [39.3601834, -8.17141528],
                            [39.35994349, -8.17147241],
                            [39.35969214, -8.17154097],
                            [39.35949793, -8.17184951],
                            [39.35936082, -8.17219233],
                            [39.35908663, -8.17258085],
                            [39.35901808, -8.17282083],
                            [39.3588924, -8.17316363],
                            [39.35864106, -8.17358644],
                            [39.35840115, -8.173735],
                            [39.35820692, -8.173655],
                            [39.3581041, -8.17341503],
                            [39.35806982, -8.17311791],
                            [39.35808126, -8.17283225],
                            [39.35818408, -8.17240944],
                            [39.35829833, -8.17194091],
                            [39.35840116, -8.17163239],
                            [39.35849256, -8.17132385],
                            [39.35870277, -8.1711456],
                            [39.35888556, -8.17092617],
                            [39.35915976, -8.17072507],
                            [39.35940653, -8.17049651],
                            [39.35961674, -8.17024054],
                            [39.35979953, -8.16999373],
                            [39.35989094, -8.16971032],
                            [39.3599275, -8.16950006],
                            [39.35993664, -8.16926238],
                            [39.35986353, -8.1690704],
                            [39.35966245, -8.16892413],
                            [39.35943394, -8.16894243],
                            [39.35934255, -8.16906126],
                            [39.35926029, -8.16915268],
                            [39.35909578, -8.16914354],
                            [39.35899524, -8.16909783],
                            [39.35883073, -8.16896069],
                            [39.35861138, -8.16890584],
                            [39.35848342, -8.16886012],
                            [39.35829149, -8.16880529],
                            [39.35812698, -8.16890583],
                            [39.35800815, -8.1690704],
                            [39.35797161, -8.16925324],
                            [39.35794417, -8.16940865],
                            [39.35788933, -8.16965546],
                            [39.35780706, -8.16970117],
                            [39.3576974, -8.16975602],
                            [39.35762427, -8.16972861],
                            [39.35751461, -8.16964633],
                            [39.35746891, -8.16951834],
                            [39.3573958, -8.16938122],
                            [39.35735922, -8.16929892],
                            [39.35726784, -8.16924408],
                            [39.35714901, -8.16923494],
                            [39.35703935, -8.1692258],
                            [39.35692966, -8.16928979],
                            [39.35687484, -8.16942693],
                            [39.35685654, -8.1696646],
                            [39.35685654, -8.16992972],
                            [39.35690224, -8.17015827],
                            [39.3568474, -8.17037766],
                            [39.3567792, -8.17048173],
                            [39.35655281, -8.17048173],
                            [39.35626176, -8.17035235],
                            [39.3559707, -8.17009356],
                            [39.35542091, -8.16996418],
                            [39.35509748, -8.16996417],
                            [39.35480643, -8.17006121],
                            [39.35441834, -8.17028764],
                            [39.35428898, -8.17054644],
                            [39.35458005, -8.17090225],
                            [39.35503281, -8.1711287],
                            [39.3554209, -8.17148452],
                            [39.35574431, -8.17190504],
                            [39.35574431, -8.1723579],
                            [39.35545322, -8.17290783],
                            [39.35545322, -8.17326366],
                            [39.3550328, -8.17358713],
                            [39.35477408, -8.17329599],
                            [39.35461236, -8.17303723],
                            [39.35451535, -8.17281079],
                            [39.35422429, -8.17264904],
                            [39.35390088, -8.17242261],
                            [39.35364217, -8.17226086],
                            [39.35348046, -8.17193738],
                            [39.35344812, -8.17171094],
                            [39.35309237, -8.17132276],
                            [39.35293067, -8.17122573],
                            [39.35276896, -8.17129041],
                            [39.35270429, -8.17164624],
                            [39.35267195, -8.17190502],
                            [39.35283364, -8.17232556],
                            [39.35283364, -8.17264904],
                            [39.35260726, -8.17284312],
                            [39.35228385, -8.17300485],
                            [39.35221918, -8.17336068],
                            [39.35218683, -8.17371651],
                            [39.35238085, -8.17394295],
                            [39.35260725, -8.17423407],
                            [39.35299533, -8.17423407],
                            [39.35331874, -8.17423407],
                            [39.3536098, -8.17423407],
                            [39.35390087, -8.17439582],
                            [39.35415959, -8.1746546],
                            [39.35422428, -8.17510747],
                            [39.35422427, -8.1755927],
                            [39.35409492, -8.17578678],
                            [39.35373917, -8.17556035],
                            [39.35325406, -8.17549565],
                            [39.35289831, -8.17556034],
                            [39.35276894, -8.17575444],
                            [39.35296298, -8.17630434],
                            [39.35293065, -8.17666017],
                            [39.35338341, -8.17678956],
                            [39.35377151, -8.17678956],
                            [39.35415958, -8.17675721],
                            [39.35406255, -8.17730714],
                            [39.35406255, -8.17769531],
                            [39.35401415, -8.1782348],
                            [39.3537492, -8.1783514],
                            [39.35347367, -8.17847859],
                            [39.35321933, -8.17860579],
                            [39.35299679, -8.17864818],
                            [39.35243513, -8.17871179],
                            [39.35200063, -8.17867998],
                            [39.35161913, -8.17876478],
                            [39.35126941, -8.17882837],
                            [39.35099389, -8.17891318],
                            [39.35076074, -8.17913576],
                            [39.35052761, -8.17936896],
                            [39.35023087, -8.17948555],
                            [39.34995533, -8.17947496],
                            [39.34969041, -8.17944316],
                            [39.34936189, -8.17932654],
                            [39.34905457, -8.17920996],
                            [39.3489062, -8.17909336],
                            [39.34857769, -8.17898737],
                            [39.34831274, -8.17892375],
                            [39.34806902, -8.17891316],
                            [39.34791006, -8.17890255],
                            [39.3476981, -8.17890255],
                            [39.34751793, -8.17883896],
                            [39.34727421, -8.17870115],
                            [39.34705167, -8.17861637],
                            [39.34688211, -8.17847856],
                            [39.34669136, -8.17826655],
                            [39.34640521, -8.17801216],
                            [39.34617208, -8.17781076],
                            [39.34601312, -8.17774717],
                            [39.34590716, -8.17774717],
                            [39.34583297, -8.17782137],
                            [39.34583297, -8.17795917],
                            [39.34587534, -8.17810757],
                            [39.34593895, -8.17821356],
                            [39.34602373, -8.17835136],
                            [39.34604491, -8.17849975],
                            [39.34600252, -8.17858456],
                            [39.34583297, -8.17861637],
                            [39.34571639, -8.17865876],
                            [39.34571639, -8.17874354],
                            [39.34576939, -8.17884955],
                            [39.34602372, -8.17904035],
                            [39.34619328, -8.17917814],
                            [39.34624626, -8.17942194],
                            [39.34623567, -8.17963393],
                            [39.34635224, -8.17973994],
                            [39.34663835, -8.17975053],
                            [39.34699867, -8.17979293],
                            [39.34739076, -8.17988833],
                            [39.34769809, -8.18012153],
                            [39.34792064, -8.18034412],
                            [39.34818557, -8.18054552],
                            [39.34830216, -8.18077872],
                            [39.34823857, -8.18103311],
                            [39.34804781, -8.1812027],
                            [39.34806901, -8.1813511],
                            [39.34830216, -8.1813617],
                            [39.34845051, -8.1813299],
                            [39.34863067, -8.18126631],
                            [39.34880021, -8.1814359],
                            [39.34876842, -8.18177511],
                            [39.34883199, -8.18201889],
                            [39.34906514, -8.1821037],
                            [39.34929828, -8.18208248],
                            [39.34960562, -8.18205069],
                            [39.34989175, -8.1820401],
                            [39.35020967, -8.1820401],
                            [39.35055938, -8.1820295],
                            [39.35077132, -8.18210371],
                            [39.3508773, -8.1822309],
                            [39.35079252, -8.18242169],
                            [39.3504746, -8.18254888],
                            [39.35022026, -8.18254888],
                            [39.34999771, -8.18254888],
                            [39.34954202, -8.18258068],
                            [39.34938308, -8.18268668],
                            [39.34954202, -8.18283507],
                            [39.34983876, -8.18285628],
                            [39.35015667, -8.18307887],
                            [39.35036863, -8.18330146],
                            [39.35065474, -8.18338627],
                            [39.3508985, -8.18323787],
                            [39.35108924, -8.18311068],
                            [39.35134357, -8.18305768],
                            [39.35154493, -8.18289867],
                            [39.35193704, -8.18267608],
                            [39.35226556, -8.18227331],
                            [39.35261526, -8.18196591],
                            [39.35300736, -8.18157373],
                            [39.35337828, -8.18129812],
                            [39.35365382, -8.18101194],
                            [39.35383397, -8.18084235],
                            [39.35398259, -8.18088493],
                            [39.35400473, -8.18117283],
                            [39.35378331, -8.18179291],
                            [39.35347334, -8.18236871],
                            [39.35327408, -8.18278947],
                            [39.35314122, -8.18307737],
                            [39.35342905, -8.18332098],
                            [39.35365045, -8.18349815],
                            [39.35398258, -8.18349815],
                            [39.35429253, -8.18336528],
                            [39.35455824, -8.18307737],
                            [39.3547575, -8.1827009],
                            [39.35508963, -8.18228012],
                            [39.35562102, -8.18205867],
                            [39.35608598, -8.18197008],
                            [39.35604169, -8.18219154],
                            [39.35579813, -8.18287808],
                            [39.35542174, -8.183476],
                            [39.35504533, -8.18414038],
                            [39.35480179, -8.18473834],
                            [39.35462465, -8.18498193],
                            [39.35469987, -8.18511999],
                            [39.35459392, -8.18511322],
                            [39.35458425, -8.18512169],
                            [39.35444019, -8.18533229],
                            [39.35428504, -8.18556507],
                            [39.35415204, -8.1857646],
                            [39.35398583, -8.18607494],
                            [39.35378635, -8.18637425],
                            [39.35356469, -8.1866846],
                            [39.35338738, -8.18698389],
                            [39.35312142, -8.187261],
                            [39.35289977, -8.18740509],
                            [39.35274462, -8.18744943],
                            [39.35260055, -8.18757136],
                            [39.3525673, -8.1876822],
                            [39.35268922, -8.18780413],
                            [39.35289977, -8.1879593],
                            [39.35307708, -8.18807015],
                            [39.35321007, -8.18814775],
                            [39.35322115, -8.18832511],
                            [39.3531325, -8.18848029],
                            [39.35297734, -8.18856898],
                            [39.35268922, -8.18863548],
                            [39.35235676, -8.18872415],
                            [39.35201321, -8.18884607],
                            [39.35172506, -8.188968],
                            [39.35142586, -8.18911209],
                            [39.3511488, -8.18930053],
                            [39.35088283, -8.18947789],
                            [39.35058362, -8.1896109],
                            [39.35032874, -8.18965523],
                            [39.35018468, -8.18966633],
                            [39.3500406, -8.18976608],
                            [39.34994087, -8.18994344],
                            [39.34975248, -8.19006537],
                            [39.34949759, -8.19004319],
                            [39.34935351, -8.1899656],
                            [39.34907646, -8.1899656],
                            [39.34869968, -8.18997668],
                            [39.34843371, -8.1899545],
                            [39.34812342, -8.1898769],
                            [39.3479461, -8.18977715],
                            [39.34779097, -8.18976607],
                            [39.34763582, -8.18998777],
                            [39.34760257, -8.19038679],
                            [39.34749172, -8.19105187],
                            [39.34736983, -8.19173911],
                            [39.34714818, -8.19242634],
                            [39.34703737, -8.19268128],
                            [39.34692653, -8.19301383],
                            [39.34678248, -8.19326876],
                            [39.34662732, -8.19347938],
                            [39.34633918, -8.19368998],
                            [39.34611755, -8.19391166],
                            [39.34598457, -8.19405578],
                            [39.34591807, -8.19417769],
                            [39.34605106, -8.19422203],
                            [39.34625054, -8.19421095],
                            [39.34650542, -8.19408902],
                            [39.34681572, -8.19391166],
                            [39.34714818, -8.19368998],
                            [39.34745849, -8.1935237],
                            [39.34772444, -8.19336852],
                            [39.34811233, -8.19316902],
                            [39.34836721, -8.19303599],
                            [39.34872182, -8.19285865],
                            [39.34899888, -8.19259263],
                            [39.34920945, -8.19231552],
                            [39.3493535, -8.19207165],
                            [39.34964163, -8.19184996],
                            [39.34989653, -8.19155069],
                            [39.35019574, -8.19117381],
                            [39.35048389, -8.19095211],
                            [39.35082744, -8.19077477],
                            [39.35121528, -8.19056415],
                            [39.35156991, -8.19035357],
                            [39.35184698, -8.19024272],
                            [39.35204645, -8.19015404],
                            [39.3522016, -8.19019839],
                            [39.35221268, -8.19038681],
                            [39.35209078, -8.19051984],
                            [39.35191347, -8.19070828],
                            [39.35173615, -8.1909078],
                            [39.35163642, -8.1911184],
                            [39.35149233, -8.1915507],
                            [39.35129285, -8.19204949],
                            [39.35104906, -8.19258154],
                            [39.35081633, -8.19299167],
                            [39.35067227, -8.19329094],
                            [39.35050603, -8.19361241],
                            [39.3504063, -8.19388952],
                            [39.35036197, -8.19419989],
                            [39.35026224, -8.19445483],
                            [39.35016249, -8.19462109],
                            [39.34998519, -8.19464325],
                            [39.34977462, -8.19462109],
                            [39.34958622, -8.19457675],
                            [39.34945324, -8.1946765],
                            [39.34932026, -8.19492038],
                            [39.34912076, -8.19526399],
                            [39.34895455, -8.19546351],
                            [39.3487329, -8.1955411],
                            [39.34844477, -8.19548567],
                            [39.34831178, -8.1954746],
                            [39.34809015, -8.19544133],
                            [39.34799042, -8.19545242],
                            [39.34796825, -8.19561869],
                            [39.34794607, -8.19599556],
                            [39.34796824, -8.19619509],
                            [39.34786851, -8.19622833],
                            [39.34769118, -8.19620617],
                            [39.34756928, -8.19603991],
                            [39.34736982, -8.19571844],
                            [39.34727007, -8.19536373],
                            [39.3471925, -8.1952529],
                            [39.34704844, -8.19523072],
                            [39.34688221, -8.19533049],
                            [39.34681571, -8.1954635],
                            [39.34678246, -8.19569628],
                            [39.34687113, -8.19609532],
                            [39.34691546, -8.19652762],
                            [39.34702628, -8.19678257],
                            [39.34722574, -8.19712618],
                            [39.34754711, -8.19760281],
                            [39.34787958, -8.19795753],
                            [39.34805689, -8.1982568],
                            [39.34811231, -8.19853392],
                            [39.34810121, -8.19874452],
                            [39.34792389, -8.19882211],
                            [39.34763578, -8.19881103],
                            [39.3473698, -8.19865584],
                            [39.34710385, -8.19855607],
                            [39.34680462, -8.19826788],
                            [39.34661624, -8.19802402],
                            [39.34636135, -8.19779124],
                            [39.34627268, -8.19770258],
                            [39.34602889, -8.19765823],
                            [39.345774, -8.19763605],
                            [39.34550802, -8.1975363],
                            [39.34535287, -8.1974698],
                            [39.34505367, -8.19737003],
                            [39.34487636, -8.19739221],
                            [39.34476554, -8.19752522],
                            [39.34483203, -8.19776907],
                            [39.34508691, -8.19792426],
                            [39.34525314, -8.19810161],
                            [39.3453972, -8.19827897],
                            [39.34541938, -8.19850065],
                            [39.34543044, -8.19862258],
                            [39.34564101, -8.19864476],
                            [39.34577399, -8.19870017],
                            [39.34595131, -8.19885536],
                            [39.34615077, -8.19903271],
                            [39.34633916, -8.19907706],
                            [39.34656082, -8.19915465],
                            [39.34679354, -8.19914356],
                            [39.34697085, -8.19915465],
                            [39.3470595, -8.19922115],
                            [39.34711492, -8.199332],
                            [39.34708166, -8.19944283],
                            [39.34689327, -8.19952042],
                            [39.34669381, -8.19962019],
                            [39.34670487, -8.19977538],
                            [39.34679354, -8.19990839],
                            [39.34711492, -8.19999708],
                            [39.34746953, -8.20011901],
                            [39.34775767, -8.2002631],
                            [39.34846035, -8.20036093],
                            [39.34850706, -8.20027218],
                            [39.34852033, -8.20029725],
                            [39.34848917, -8.20036215],
                            [39.34853308, -8.20063661],
                            [39.34862088, -8.20090009],
                            [39.34877455, -8.20107574],
                            [39.34904893, -8.20121845],
                            [39.34952091, -8.20147097],
                            [39.3498831, -8.20172347],
                            [39.35030017, -8.20201989],
                            [39.35058555, -8.20234923],
                            [39.35083799, -8.20284325],
                            [39.35100262, -8.20323848],
                            [39.35111238, -8.20355685],
                            [39.35125508, -8.20382032],
                            [39.35149654, -8.20398501],
                            [39.35166117, -8.20407284],
                            [39.35180385, -8.20433631],
                            [39.35206728, -8.20464371],
                            [39.3525502, -8.20485228],
                            [39.35296729, -8.20494011],
                            [39.35327462, -8.2048962],
                            [39.35364779, -8.20473155],
                            [39.35401, -8.20447903],
                            [39.35439416, -8.20409479],
                            [39.35458075, -8.20372153],
                            [39.35463562, -8.20339219],
                            [39.35458075, -8.20306283],
                            [39.35450391, -8.20275543],
                            [39.35452586, -8.20232729],
                            [39.35461367, -8.20200892],
                            [39.3547344, -8.20172349],
                            [39.35488809, -8.20153685],
                            [39.35510758, -8.20140511],
                            [39.35533809, -8.2013722],
                            [39.35565638, -8.20141611],
                            [39.35587591, -8.20144902],
                            [39.35602956, -8.20139414],
                            [39.35619421, -8.2012624],
                            [39.35633689, -8.20109774],
                            [39.35644664, -8.20108676],
                            [39.35647957, -8.20122949],
                            [39.3564576, -8.20148197],
                            [39.3564137, -8.20170154],
                            [39.3563259, -8.2018772],
                            [39.35612833, -8.20200893],
                            [39.35595271, -8.20206383],
                            [39.35571125, -8.20208578],
                            [39.35548076, -8.20214067],
                            [39.35527222, -8.20221752],
                            [39.35509661, -8.20231632],
                            [39.35495392, -8.20250295],
                            [39.35489905, -8.20274446],
                            [39.35487708, -8.20307382],
                            [39.35496489, -8.20341415],
                            [39.35500879, -8.2036996],
                            [39.35501976, -8.20386425],
                            [39.35497585, -8.20402894],
                            [39.3548222, -8.20428142],
                            [39.35458074, -8.20459981],
                            [39.35426244, -8.2048962],
                            [39.35401, -8.20506089],
                            [39.35370266, -8.20521457],
                            [39.35336243, -8.20534632],
                            [39.35305509, -8.20551099],
                            [39.35289046, -8.20559881],
                            [39.35267094, -8.20552197],
                            [39.35241851, -8.20537926],
                            [39.35226483, -8.2052036],
                            [39.35202337, -8.20509383],
                            [39.35181483, -8.20498402],
                            [39.35161726, -8.20498402],
                            [39.3514636, -8.20506088],
                            [39.35147456, -8.20533534],
                            [39.35163921, -8.20566468],
                            [39.35199043, -8.20590621],
                            [39.35222092, -8.20622458],
                            [39.35241851, -8.20664176],
                            [39.35260509, -8.20689426],
                            [39.35291239, -8.20724557],
                            [39.35313191, -8.20737729],
                            [39.35340632, -8.2074322],
                            [39.35387827, -8.20744318],
                            [39.35421852, -8.20735535],
                            [39.35450389, -8.20721264],
                            [39.35489903, -8.20716872],
                            [39.35525025, -8.20699307],
                            [39.35565636, -8.20683937],
                            [39.35606247, -8.20654297],
                            [39.3563588, -8.20627949],
                            [39.35664418, -8.20605992],
                            [39.35696249, -8.20576352],
                            [39.35725882, -8.20542319],
                            [39.35761006, -8.20504994],
                            [39.35796128, -8.20470959],
                            [39.35801617, -8.20431438],
                            [39.35811495, -8.20391915],
                            [39.35842227, -8.20326047],
                            [39.35857595, -8.20282134],
                            [39.35876254, -8.20248101],
                            [39.35890522, -8.20201993],
                            [39.35911376, -8.20154787],
                            [39.35931132, -8.20115264],
                            [39.35958572, -8.20039514],
                            [39.35983818, -8.19963764],
                            [39.36004671, -8.19903384],
                            [39.36031013, -8.19857274],
                            [39.36065039, -8.1980348],
                            [39.3606943, -8.19767252],
                            [39.36085895, -8.19718949],
                            [39.36096869, -8.19678327],
                            [39.36112235, -8.19648688],
                            [39.36129797, -8.19621242],
                            [39.3615614, -8.19545492],
                            [39.3616053, -8.19501578],
                            [39.36166018, -8.19467546],
                            [39.36186872, -8.19421436],
                            [39.3619675, -8.19378622],
                            [39.36198947, -8.19336904],
                            [39.3620114, -8.19302872],
                            [39.36216509, -8.19253469],
                            [39.36217606, -8.19209557],
                            [39.36225289, -8.19138198],
                            [39.36232972, -8.19056958],
                            [39.36243948, -8.18985599],
                            [39.36253827, -8.18941685],
                            [39.36271388, -8.18873621],
                            [39.36272487, -8.1881873],
                            [39.36276877, -8.18767132],
                            [39.36296633, -8.18710045],
                            [39.36310903, -8.18656251],
                            [39.36332855, -8.18625513],
                            [39.36345936, -8.1857819],
                            [39.36324498, -8.18559428],
                            [39.3632177, -8.18559428],
                            [39.36329428, -8.18480944],
                            [39.36365977, -8.1839361],
                            [39.36388314, -8.18283937],
                            [39.3641065, -8.18151923],
                            [39.36422834, -8.18052405],
                            [39.36447201, -8.17932577],
                            [39.36465475, -8.17841182],
                            [39.36493903, -8.17723385],
                            [39.3651827, -8.17579185],
                            [39.36546699, -8.17455293],
                            [39.36569036, -8.17351713],
                            [39.36624478, -8.17211445],
                            [39.36633834, -8.17161531],
                            [39.36646311, -8.17110578],
                            [39.36662946, -8.17077302],
                            [39.36666065, -8.17055465],
                            [39.36657746, -8.17041947],
                            [39.36657181, -8.17041853],
                            [39.36694817, -8.16962719],
                            [39.36705678, -8.16905993],
                            [39.36727399, -8.16857714],
                            [39.36737052, -8.16821507],
                            [39.36747911, -8.16780472],
                            [39.36766012, -8.16746678],
                            [39.36779285, -8.16712883],
                            [39.36791354, -8.16683917],
                            [39.36803419, -8.16640466],
                            [39.36820314, -8.16593397],
                            [39.36833587, -8.16549947],
                            [39.36864959, -8.16483564],
                            [39.36880648, -8.16425631],
                            [39.36885475, -8.16382183],
                            [39.36884269, -8.16336318],
                            [39.36887887, -8.16318215],
                            [39.36886681, -8.16291661],
                            [39.36892714, -8.16249419],
                            [39.36869787, -8.16232522],
                            [39.36845655, -8.16261488],
                            [39.36828762, -8.16285627],
                            [39.36815487, -8.16326663],
                            [39.36809454, -8.16372528],
                            [39.36790147, -8.1641477],
                            [39.36782908, -8.16455805],
                            [39.36759981, -8.16500462],
                            [39.36744294, -8.16534256],
                            [39.36709299, -8.16594603],
                            [39.36677925, -8.16647709],
                            [39.36646553, -8.16695985],
                            [39.36611559, -8.16738229],
                            [39.36585013, -8.1674909],
                            [39.36585013, -8.16726158],
                            [39.36594666, -8.16703226],
                            [39.36610353, -8.16676675],
                            [39.36623626, -8.16656156],
                            [39.3662966, -8.16633224],
                            [39.36640519, -8.16612706],
                            [39.3665138, -8.16587362],
                            [39.36656206, -8.1656443],
                            [39.36689993, -8.16512531],
                            [39.36704474, -8.16463047],
                            [39.36723781, -8.16428045],
                            [39.36750327, -8.16361663],
                            [39.36769634, -8.16320629],
                            [39.36792562, -8.16273558],
                            [39.36802215, -8.16234935],
                            [39.36815488, -8.16187865],
                            [39.36821522, -8.16142],
                            [39.36827555, -8.16102173],
                            [39.36823936, -8.16071998],
                            [39.3681911, -8.16036998],
                            [39.36813074, -8.16011653],
                            [39.36817901, -8.15985099],
                            [39.36825143, -8.15962167],
                            [39.36831176, -8.15934407],
                            [39.36839622, -8.15904234],
                            [39.36855309, -8.15871647],
                            [39.36873409, -8.15852337],
                            [39.3689875, -8.15829405],
                            [39.36915643, -8.15805265],
                            [39.36936158, -8.15770265],
                            [39.36947017, -8.15738884],
                            [39.36962703, -8.15712332],
                            [39.36973565, -8.15688192],
                            [39.36990456, -8.15656813],
                            [39.37002525, -8.15624225],
                            [39.37012178, -8.15589224],
                            [39.37017004, -8.15559051],
                            [39.37020625, -8.15533704],
                            [39.37017005, -8.15493876],
                            [39.37010972, -8.15455255],
                            [39.37012179, -8.15399735],
                            [39.37019418, -8.15359906],
                            [39.37009765, -8.15335767],
                            [39.36989252, -8.15327317],
                            [39.36972357, -8.15333354],
                            [39.36966326, -8.15350249],
                            [39.36961498, -8.15373182],
                            [39.36954259, -8.15403356],
                            [39.36942192, -8.15446805],
                        ],
                        [
                            [39.3453387, -8.20437807],
                            [39.3452593, -8.20430998],
                            [39.34517988, -8.20428729],
                            [39.34507778, -8.20428729],
                            [39.34496433, -8.20428729],
                            [39.34480552, -8.20428728],
                            [39.34457861, -8.20420785],
                            [39.34447652, -8.20411708],
                            [39.3443971, -8.20399226],
                            [39.344295, -8.20387878],
                            [39.34421559, -8.203788],
                            [39.34412483, -8.20370858],
                            [39.34400004, -8.2036178],
                            [39.34389794, -8.20352701],
                            [39.34382988, -8.20352701],
                            [39.34371643, -8.20360647],
                            [39.34363701, -8.2036178],
                            [39.34353492, -8.20356107],
                            [39.34338742, -8.20349297],
                            [39.34327398, -8.2034249],
                            [39.34309247, -8.20339084],
                            [39.3429223, -8.2034249],
                            [39.34274078, -8.20350432],
                            [39.34261599, -8.20361779],
                            [39.34251388, -8.20379934],
                            [39.34244582, -8.20394685],
                            [39.34242313, -8.20411707],
                            [39.34241179, -8.20426458],
                            [39.34241179, -8.20440075],
                            [39.3423664, -8.20455961],
                            [39.34243447, -8.20468443],
                            [39.34252523, -8.20482059],
                            [39.34265003, -8.20492272],
                            [39.34277482, -8.20505889],
                            [39.34283153, -8.20519505],
                            [39.34284288, -8.20536527],
                            [39.34288825, -8.20550142],
                            [39.34291095, -8.20567164],
                            [39.34297901, -8.20579646],
                            [39.34306977, -8.20589858],
                            [39.34313783, -8.2060007],
                            [39.34325128, -8.20610283],
                            [39.34336473, -8.20619362],
                            [39.34347817, -8.20632978],
                            [39.34360297, -8.20643191],
                            [39.34372776, -8.20653403],
                            [39.34386388, -8.20662481],
                            [39.34397734, -8.20677232],
                            [39.34405674, -8.20691983],
                            [39.34413616, -8.2071014],
                            [39.34421558, -8.20724891],
                            [39.34430632, -8.20740775],
                            [39.34440843, -8.20753259],
                            [39.34453322, -8.20762337],
                            [39.34463533, -8.20769145],
                            [39.34476012, -8.20771414],
                            [39.34493028, -8.20771414],
                            [39.34503239, -8.20758932],
                            [39.34505508, -8.20745315],
                            [39.34508911, -8.20730564],
                            [39.34511181, -8.20715813],
                            [39.34511181, -8.20699927],
                            [39.34513448, -8.20678367],
                            [39.34513448, -8.20655674],
                            [39.34514583, -8.20634114],
                            [39.34514583, -8.20617093],
                            [39.34508911, -8.20602342],
                            [39.34508911, -8.20587591],
                            [39.34512316, -8.20572838],
                            [39.34520256, -8.20566031],
                            [39.34535004, -8.20564896],
                            [39.34548618, -8.20570569],
                            [39.34562233, -8.20584186],
                            [39.3457698, -8.20596668],
                            [39.34587189, -8.20611419],
                            [39.34596265, -8.20632979],
                            [39.34607609, -8.20647729],
                            [39.34624628, -8.20665886],
                            [39.34635971, -8.20680638],
                            [39.34655258, -8.2069085],
                            [39.34672275, -8.20709006],
                            [39.34688157, -8.20727161],
                            [39.34708578, -8.20739643],
                            [39.34732402, -8.20749856],
                            [39.34752821, -8.20756666],
                            [39.34776644, -8.20770281],
                            [39.34790259, -8.20770281],
                            [39.34809544, -8.20774819],
                            [39.34833368, -8.20775955],
                            [39.34850386, -8.20778225],
                            [39.34861729, -8.20779359],
                            [39.34877613, -8.20779359],
                            [39.34892361, -8.20779359],
                            [39.34899167, -8.2077482],
                            [39.34904532, -8.20772343],
                            [39.34895426, -8.2077032],
                            [39.34837905, -8.20718734],
                            [39.34802201, -8.20700879],
                            [39.34760545, -8.20671117],
                            [39.34720874, -8.20635405],
                            [39.34685168, -8.20601676],
                            [39.34653431, -8.20569932],
                            [39.34643512, -8.20530251],
                            [39.34644564, -8.20527451],
                            [39.34638241, -8.20527451],
                            [39.34626896, -8.20521776],
                            [39.34607609, -8.20519507],
                            [39.34591729, -8.2051043],
                            [39.34581518, -8.20502486],
                            [39.34571308, -8.20502486],
                            [39.34561097, -8.20500217],
                            [39.34555424, -8.20500217],
                            [39.34542946, -8.20500217],
                            [39.34537275, -8.20492273],
                            [39.34540678, -8.20485466],
                            [39.34544081, -8.20474118],
                            [39.34544081, -8.20460502],
                            [39.34542946, -8.20449154],
                            [39.3453387, -8.20437807],
                        ],
                        [
                            [39.32856693, -8.20673764],
                            [39.32859648, -8.20623533],
                            [39.32865556, -8.20561484],
                            [39.32844878, -8.20496478],
                            [39.32806475, -8.20446246],
                            [39.32806475, -8.2039897],
                            [39.32830108, -8.20351694],
                            [39.32830109, -8.20298508],
                            [39.32821247, -8.20260096],
                            [39.32782844, -8.20236459],
                            [39.32735577, -8.20242367],
                            [39.3270899, -8.20307372],
                            [39.32711944, -8.20360558],
                            [39.32691265, -8.20378286],
                            [39.32670587, -8.20360558],
                            [39.32632183, -8.20360558],
                            [39.32617412, -8.20398969],
                            [39.32620366, -8.20449201],
                            [39.3260855, -8.20455111],
                            [39.32573101, -8.20458065],
                            [39.32552422, -8.20481703],
                            [39.3254356, -8.20520115],
                            [39.32531743, -8.20555572],
                            [39.32502202, -8.20567391],
                            [39.32493339, -8.20638306],
                            [39.32490385, -8.20691492],
                            [39.32478567, -8.2076536],
                            [39.32449026, -8.20774224],
                            [39.32440164, -8.20756496],
                            [39.32443119, -8.20718084],
                            [39.32443119, -8.20676717],
                            [39.32428348, -8.20656034],
                            [39.32372221, -8.20667853],
                            [39.32351541, -8.20715129],
                            [39.32307231, -8.20744676],
                            [39.32289504, -8.20797862],
                            [39.32286551, -8.20842183],
                            [39.32330862, -8.20865822],
                            [39.32363358, -8.20865822],
                            [39.32369265, -8.20904234],
                            [39.32369265, -8.20945601],
                            [39.32384036, -8.20978104],
                            [39.3240176, -8.21013561],
                            [39.32437209, -8.21007652],
                            [39.32457888, -8.20995833],
                            [39.32478566, -8.20995833],
                            [39.32508107, -8.20998788],
                            [39.32528786, -8.210372],
                            [39.32499245, -8.21063792],
                            [39.32466749, -8.21049018],
                            [39.32434256, -8.210372],
                            [39.32395852, -8.21046064],
                            [39.32366311, -8.21063792],
                            [39.32363356, -8.21102204],
                            [39.32360402, -8.21161299],
                            [39.32342677, -8.21235169],
                            [39.32333815, -8.21314948],
                            [39.32313135, -8.21379952],
                            [39.32301318, -8.21444956],
                            [39.32283594, -8.21504051],
                            [39.32286548, -8.21566102],
                            [39.32301318, -8.21616333],
                            [39.32324951, -8.21648836],
                            [39.32375171, -8.21681339],
                            [39.32434252, -8.21728616],
                            [39.32496289, -8.21746344],
                            [39.32505151, -8.21719752],
                            [39.32514014, -8.21645882],
                            [39.32519922, -8.21560194],
                            [39.32519922, -8.21462687],
                            [39.32528785, -8.21406545],
                            [39.32596729, -8.21394726],
                            [39.32635132, -8.21344497],
                            [39.32655811, -8.21300174],
                            [39.32673537, -8.21238124],
                            [39.32691262, -8.21187893],
                            [39.32723756, -8.2110516],
                            [39.32765114, -8.21040155],
                            [39.32836013, -8.21001743],
                            [39.32895095, -8.20972196],
                            [39.3291282, -8.20910145],
                            [39.32954178, -8.20821503],
                            [39.32960087, -8.20756498],
                            [39.32960088, -8.20718086],
                            [39.32930547, -8.20703312],
                            [39.32898051, -8.20706267],
                            [39.3286851, -8.20706267],
                            [39.32856693, -8.20673764],
                        ],
                        [
                            [39.35202072, -8.21583327],
                            [39.35225235, -8.21593256],
                            [39.35248401, -8.21583327],
                            [39.3528811, -8.21586636],
                            [39.35326165, -8.21571741],
                            [39.35384075, -8.21578362],
                            [39.35427095, -8.21578362],
                            [39.35430403, -8.21561812],
                            [39.35410549, -8.21538643],
                            [39.35387385, -8.21507198],
                            [39.35346021, -8.21485685],
                            [39.35312929, -8.21465825],
                            [39.35286456, -8.21434381],
                            [39.35266601, -8.21411211],
                            [39.35216964, -8.21379766],
                            [39.35172291, -8.21351633],
                            [39.35139199, -8.21328462],
                            [39.35106108, -8.21303639],
                            [39.35082943, -8.21285434],
                            [39.35049852, -8.21262264],
                            [39.35028342, -8.21262264],
                            [39.35011796, -8.21278814],
                            [39.35011796, -8.21288744],
                            [39.35015105, -8.21318534],
                            [39.3503827, -8.21341702],
                            [39.35079634, -8.21374802],
                            [39.35122654, -8.21401282],
                            [39.351756, -8.21439344],
                            [39.35230201, -8.21475754],
                            [39.35250056, -8.21502233],
                            [39.35243436, -8.21527058],
                            [39.35213654, -8.21528714],
                            [39.35173945, -8.21528714],
                            [39.35147471, -8.21536987],
                            [39.35147471, -8.21560156],
                            [39.35167326, -8.21581671],
                            [39.35202072, -8.21583327],
                        ],
                        [
                            [39.34178795, -8.21796495],
                            [39.34224434, -8.21852289],
                            [39.34229505, -8.21913156],
                            [39.34214292, -8.21999383],
                            [39.34219363, -8.22080537],
                            [39.34219363, -8.22171837],
                            [39.34239646, -8.22237776],
                            [39.34280214, -8.22318932],
                            [39.34320782, -8.22415303],
                            [39.34366421, -8.22542108],
                            [39.34366421, -8.22559096],
                            [39.3436642, -8.22653697],
                            [39.34320781, -8.22750068],
                            [39.34285283, -8.22861656],
                            [39.34285283, -8.22932668],
                            [39.34275139, -8.22998606],
                            [39.34259927, -8.23074689],
                            [39.34214287, -8.23120338],
                            [39.34214286, -8.23176133],
                            [39.34239641, -8.23247143],
                            [39.34290352, -8.23272504],
                            [39.34341062, -8.23242072],
                            [39.3442727, -8.23155844],
                            [39.34462769, -8.230544],
                            [39.34472912, -8.2293774],
                            [39.34483054, -8.22831225],
                            [39.34498268, -8.22694276],
                            [39.34512495, -8.22630237],
                            [39.34518552, -8.22602976],
                            [39.34518552, -8.22481243],
                            [39.34493198, -8.22379799],
                            [39.3445263, -8.2224285],
                            [39.34391778, -8.2215155],
                            [39.34351209, -8.22050106],
                            [39.34341068, -8.21963879],
                            [39.34371495, -8.21837072],
                            [39.34320785, -8.21725484],
                            [39.34315714, -8.21649401],
                            [39.34290359, -8.21568245],
                            [39.34239649, -8.21542884],
                            [39.34183867, -8.21542884],
                            [39.34138228, -8.21558101],
                            [39.34123014, -8.21598679],
                            [39.34128086, -8.216494],
                            [39.34173724, -8.21730556],
                            [39.34178795, -8.21796495],
                        ],
                        [
                            [39.33781872, -8.22889476],
                            [39.33818429, -8.22857897],
                            [39.33853324, -8.22831305],
                            [39.33881573, -8.22801387],
                            [39.33909822, -8.22763159],
                            [39.33911485, -8.22711636],
                            [39.33894867, -8.22700002],
                            [39.33869943, -8.22709974],
                            [39.33840031, -8.22748202],
                            [39.33811782, -8.22783105],
                            [39.33780212, -8.22791414],
                            [39.33766917, -8.22824656],
                            [39.33761932, -8.22859559],
                            [39.33741992, -8.22856235],
                            [39.33723714, -8.2282798],
                            [39.33712081, -8.22798062],
                            [39.33690479, -8.22783104],
                            [39.33670539, -8.22781441],
                            [39.33638967, -8.22811359],
                            [39.33624013, -8.22851248],
                            [39.33624013, -8.22894462],
                            [39.33620689, -8.22947647],
                            [39.33609057, -8.22984213],
                            [39.3358247, -8.23000833],
                            [39.33552559, -8.23020778],
                            [39.33535942, -8.2304737],
                            [39.33530958, -8.2307064],
                            [39.33525972, -8.23107204],
                            [39.33514339, -8.23140445],
                            [39.3348443, -8.2315208],
                            [39.33461167, -8.23115515],
                            [39.33472798, -8.23067316],
                            [39.3348443, -8.23034073],
                            [39.3348443, -8.22997509],
                            [39.33501047, -8.22949309],
                            [39.3351434, -8.22886151],
                            [39.33537605, -8.22822992],
                            [39.3354259, -8.22771469],
                            [39.33537606, -8.22706649],
                            [39.33534283, -8.22616898],
                            [39.33529298, -8.22558726],
                            [39.33514342, -8.22493905],
                            [39.33486095, -8.22387533],
                            [39.33464493, -8.22311078],
                            [39.33449538, -8.22219666],
                            [39.33439569, -8.22121604],
                            [39.3343126, -8.22028529],
                            [39.33437908, -8.21968694],
                            [39.33456186, -8.21908861],
                            [39.33457849, -8.21868971],
                            [39.33446216, -8.21854012],
                            [39.33403014, -8.21845702],
                            [39.33364795, -8.21837391],
                            [39.33334883, -8.21834067],
                            [39.33319929, -8.21834067],
                            [39.33309959, -8.2185235],
                            [39.33299988, -8.21877281],
                            [39.33280048, -8.21888915],
                            [39.33260108, -8.21905535],
                            [39.3321358, -8.2191717],
                            [39.33190317, -8.21923818],
                            [39.3318367, -8.21937114],
                            [39.33178684, -8.21968693],
                            [39.33201949, -8.219903],
                            [39.33253461, -8.22001936],
                            [39.33313282, -8.22020217],
                            [39.33349838, -8.2205346],
                            [39.33373101, -8.220867],
                            [39.33384733, -8.22144872],
                            [39.33388056, -8.22193072],
                            [39.33399688, -8.22236286],
                            [39.33411319, -8.22271189],
                            [39.33417966, -8.22312741],
                            [39.33427935, -8.22372574],
                            [39.33442891, -8.22434071],
                            [39.33457846, -8.22485595],
                            [39.33459507, -8.22528808],
                            [39.33456183, -8.2256205],
                            [39.3344289, -8.22577007],
                            [39.33416304, -8.22575346],
                            [39.33386392, -8.22575346],
                            [39.33364792, -8.22575346],
                            [39.33361468, -8.22585318],
                            [39.33364791, -8.22608586],
                            [39.33364791, -8.22641828],
                            [39.33379745, -8.22675069],
                            [39.33394701, -8.22703324],
                            [39.33386391, -8.22749861],
                            [39.33353158, -8.22763157],
                            [39.33309955, -8.22771468],
                            [39.33240164, -8.22786426],
                            [39.33191975, -8.22803046],
                            [39.33162064, -8.22827978],
                            [39.33153756, -8.22852909],
                            [39.3316705, -8.22872853],
                            [39.33196959, -8.2290277],
                            [39.33221885, -8.22921053],
                            [39.33253457, -8.22955956],
                            [39.3326675, -8.22985873],
                            [39.3324681, -8.23002493],
                            [39.33211914, -8.22989197],
                            [39.33190312, -8.22972576],
                            [39.33133815, -8.22939336],
                            [39.33102244, -8.22936011],
                            [39.33090612, -8.22942659],
                            [39.33097258, -8.22969252],
                            [39.33123844, -8.23002493],
                            [39.33120521, -8.23027424],
                            [39.33103904, -8.23054016],
                            [39.33097258, -8.23088919],
                            [39.33095597, -8.23117175],
                            [39.33107227, -8.23152078],
                            [39.33133814, -8.23198617],
                            [39.33158739, -8.23231857],
                            [39.33175355, -8.23270085],
                            [39.33186987, -8.23306649],
                            [39.33193634, -8.23344877],
                            [39.33185326, -8.23374795],
                            [39.33158738, -8.2337978],
                            [39.33130489, -8.23371471],
                            [39.33103903, -8.23349863],
                            [39.3309061, -8.23338228],
                            [39.33073993, -8.23338228],
                            [39.33064023, -8.233482],
                            [39.330607, -8.23376456],
                            [39.33075654, -8.2341967],
                            [39.3307067, -8.23466207],
                            [39.33047405, -8.23504434],
                            [39.33014171, -8.23537676],
                            [39.32999216, -8.23567592],
                            [39.33007524, -8.23627427],
                            [39.33010848, -8.23673964],
                            [39.33020817, -8.23720502],
                            [39.33040757, -8.23740446],
                            [39.33065682, -8.23777011],
                            [39.33087284, -8.23835185],
                            [39.33090607, -8.23895018],
                            [39.33072328, -8.23963163],
                            [39.33044079, -8.24014686],
                            [39.33030786, -8.24079506],
                            [39.33030785, -8.24136017],
                            [39.33045741, -8.24225768],
                            [39.33072327, -8.24302221],
                            [39.33080635, -8.24367043],
                            [39.33067342, -8.24440173],
                            [39.33049063, -8.24506654],
                            [39.330474, -8.24543221],
                            [39.33062356, -8.24556517],
                            [39.33090604, -8.24563165],
                            [39.33112207, -8.24564828],
                            [39.33140456, -8.24564828],
                            [39.33153749, -8.24554854],
                            [39.33150426, -8.24528262],
                            [39.3313547, -8.24496683],
                            [39.33138794, -8.24455131],
                            [39.33142117, -8.24390311],
                            [39.3314378, -8.24323829],
                            [39.33160397, -8.24257346],
                            [39.33191969, -8.24175906],
                            [39.33241819, -8.24084492],
                            [39.33281701, -8.2402466],
                            [39.33314934, -8.2399308],
                            [39.33341521, -8.23964825],
                            [39.33374755, -8.23956515],
                            [39.33404665, -8.23958178],
                            [39.33452854, -8.23953191],
                            [39.33489412, -8.23913302],
                            [39.33512675, -8.2386344],
                            [39.33524308, -8.23850144],
                            [39.33547571, -8.23823552],
                            [39.33547571, -8.23796959],
                            [39.33534278, -8.23791972],
                            [39.33504366, -8.23811916],
                            [39.33472794, -8.23835186],
                            [39.33446208, -8.23858455],
                            [39.33419621, -8.23880062],
                            [39.33409651, -8.23865102],
                            [39.33406328, -8.2383186],
                            [39.33414636, -8.23780337],
                            [39.33437901, -8.23682276],
                            [39.33451195, -8.23580891],
                            [39.33471135, -8.23517732],
                            [39.33491075, -8.2345956],
                            [39.33517661, -8.234097],
                            [39.33550896, -8.23383106],
                            [39.33579145, -8.23341554],
                            [39.33620688, -8.23303327],
                            [39.33657244, -8.23268424],
                            [39.33703772, -8.23233521],
                            [39.33745314, -8.23210253],
                            [39.33791841, -8.23186984],
                            [39.3382009, -8.23148757],
                            [39.33836706, -8.23108869],
                            [39.33841692, -8.23073965],
                            [39.33858309, -8.23055682],
                            [39.33874925, -8.23034075],
                            [39.33858309, -8.22997511],
                            [39.33823414, -8.22985875],
                            [39.33796827, -8.23000834],
                            [39.33765255, -8.23000834],
                            [39.33760269, -8.2297424],
                            [39.33765255, -8.22932689],
                            [39.33781872, -8.22889476],
                        ],
                        [
                            [39.33846305, -8.23779231],
                            [39.33772451, -8.23799914],
                            [39.33695645, -8.23835372],
                            [39.33621793, -8.23882647],
                            [39.33544985, -8.23941742],
                            [39.33482949, -8.24003793],
                            [39.33409096, -8.24074705],
                            [39.33391371, -8.24136756],
                            [39.3340614, -8.24198807],
                            [39.33438636, -8.24257902],
                            [39.33465222, -8.2427563],
                            [39.33474086, -8.24340636],
                            [39.33527258, -8.24352454],
                            [39.33607019, -8.24340637],
                            [39.33701552, -8.2431109],
                            [39.33725184, -8.24249039],
                            [39.33731093, -8.24213581],
                            [39.3370746, -8.24184033],
                            [39.33648378, -8.2416926],
                            [39.3360702, -8.24166305],
                            [39.33615882, -8.24130848],
                            [39.33666103, -8.24033341],
                            [39.33710415, -8.23959471],
                            [39.33760636, -8.23912196],
                            [39.33796084, -8.23894467],
                            [39.33887662, -8.23909241],
                            [39.33994009, -8.23906288],
                            [39.34097404, -8.23853102],
                            [39.34215568, -8.23799916],
                            [39.34298282, -8.23723093],
                            [39.34324871, -8.23652178],
                            [39.34327824, -8.23590127],
                            [39.34313054, -8.23531032],
                            [39.34304192, -8.23471937],
                            [39.34283514, -8.23401023],
                            [39.34268743, -8.23347837],
                            [39.34239202, -8.23315334],
                            [39.34209661, -8.23333062],
                            [39.34209661, -8.23377384],
                            [39.3420966, -8.23439435],
                            [39.34215569, -8.23489666],
                            [39.34221477, -8.23548761],
                            [39.34197843, -8.23625585],
                            [39.34180118, -8.23672861],
                            [39.34132853, -8.23696499],
                            [39.34088541, -8.23708319],
                            [39.34050137, -8.2374673],
                            [39.34017643, -8.23794006],
                            [39.33991055, -8.23814689],
                            [39.33949697, -8.23788095],
                            [39.33926066, -8.23764458],
                            [39.33887662, -8.23767413],
                            [39.33846305, -8.23779231],
                        ],
                        [
                            [39.33420174, -8.24695357],
                            [39.33434476, -8.24731915],
                            [39.33431298, -8.24773241],
                            [39.33436063, -8.24808211],
                            [39.33458312, -8.2484159],
                            [39.33485328, -8.24859073],
                            [39.3351711, -8.24860663],
                            [39.33542537, -8.24849537],
                            [39.33558427, -8.24828874],
                            [39.33567963, -8.24790727],
                            [39.33569552, -8.24760526],
                            [39.33566374, -8.24727146],
                            [39.33563196, -8.24698536],
                            [39.33540949, -8.24666746],
                            [39.33517111, -8.24652441],
                            [39.3348374, -8.24638135],
                            [39.33455135, -8.24638135],
                            [39.33431299, -8.24619061],
                            [39.33402694, -8.24601577],
                            [39.33391571, -8.2458886],
                            [39.33380446, -8.24566608],
                            [39.333725, -8.24537998],
                            [39.33370912, -8.24510975],
                            [39.33370912, -8.24490311],
                            [39.33359788, -8.2446806],
                            [39.33345487, -8.24445807],
                            [39.33332774, -8.24431501],
                            [39.33308935, -8.24428322],
                            [39.332851, -8.2443627],
                            [39.3326762, -8.24447396],
                            [39.33261262, -8.24471238],
                            [39.33261262, -8.24496671],
                            [39.33266031, -8.24522102],
                            [39.33277152, -8.24552303],
                            [39.33286689, -8.24584092],
                            [39.33305758, -8.24622239],
                            [39.33326417, -8.24634956],
                            [39.33358198, -8.24652441],
                            [39.33389981, -8.24669925],
                            [39.33420174, -8.24695357],
                        ],
                        [
                            [39.27325184, -8.24469849],
                            [39.27336966, -8.24432136],
                            [39.2732754, -8.24403852],
                            [39.27334609, -8.24361426],
                            [39.27362887, -8.24375567],
                            [39.27372313, -8.24425066],
                            [39.27433581, -8.24467493],
                            [39.27480712, -8.24448636],
                            [39.27499564, -8.24420351],
                            [39.27483068, -8.24392068],
                            [39.27452434, -8.2436614],
                            [39.27412374, -8.24333141],
                            [39.27443008, -8.24311928],
                            [39.2746186, -8.24283645],
                            [39.27426513, -8.24250646],
                            [39.27377026, -8.24229433],
                            [39.27336967, -8.24184649],
                            [39.27351106, -8.24165793],
                            [39.27395879, -8.24170506],
                            [39.27410018, -8.24151651],
                            [39.27391167, -8.24121009],
                            [39.27358176, -8.24078583],
                            [39.27365245, -8.24071511],
                            [39.27419444, -8.24073868],
                            [39.27428871, -8.24000801],
                            [39.27414732, -8.2394659],
                            [39.27393524, -8.23939518],
                            [39.27367602, -8.23908876],
                            [39.27332255, -8.23875878],
                            [39.27280411, -8.23880592],
                            [39.27256847, -8.23887662],
                            [39.27242708, -8.23864092],
                            [39.27285125, -8.23833451],
                            [39.27344037, -8.23824024],
                            [39.27386454, -8.23800453],
                            [39.27395881, -8.23743885],
                            [39.27384099, -8.23689673],
                            [39.27360534, -8.2366139],
                            [39.27327543, -8.23642533],
                            [39.27278056, -8.23644889],
                            [39.27237997, -8.23640176],
                            [39.27228571, -8.23604819],
                            [39.27273344, -8.23595393],
                            [39.27315761, -8.23597749],
                            [39.2732283, -8.23571821],
                            [39.27311048, -8.23534109],
                            [39.27285127, -8.23510539],
                            [39.2724978, -8.23479898],
                            [39.27247422, -8.23453971],
                            [39.27254493, -8.23430399],
                            [39.2728984, -8.23428043],
                            [39.2732283, -8.23453971],
                            [39.27353465, -8.23435115],
                            [39.27381744, -8.23425687],
                            [39.27431229, -8.23451613],
                            [39.27468934, -8.23484612],
                            [39.27501925, -8.23482255],
                            [39.27556124, -8.23468114],
                            [39.27563193, -8.23430401],
                            [39.27523134, -8.23402117],
                            [39.27490143, -8.23397402],
                            [39.27440656, -8.23364404],
                            [39.27410021, -8.2333612],
                            [39.27381744, -8.23314907],
                            [39.27329901, -8.23288978],
                            [39.27278058, -8.23241838],
                            [39.27228573, -8.23218268],
                            [39.27167303, -8.23218267],
                            [39.27127242, -8.23218267],
                            [39.27091895, -8.23230052],
                            [39.2706126, -8.23277192],
                            [39.27044765, -8.2332669],
                            [39.27028269, -8.2339033],
                            [39.27018843, -8.23432757],
                            [39.26992921, -8.23475183],
                            [39.26983495, -8.23534108],
                            [39.26983495, -8.23578891],
                            [39.26990565, -8.23654316],
                            [39.26990564, -8.23713241],
                            [39.2698585, -8.23753311],
                            [39.26983494, -8.23805166],
                            [39.26988207, -8.23852306],
                            [39.2701884, -8.23925375],
                            [39.27049475, -8.23951301],
                            [39.27058901, -8.24005513],
                            [39.27042406, -8.24085653],
                            [39.27014127, -8.24125721],
                            [39.27021197, -8.24184648],
                            [39.27070682, -8.24201146],
                            [39.2715316, -8.24203503],
                            [39.27181437, -8.24227075],
                            [39.27183795, -8.24274215],
                            [39.27183795, -8.24318998],
                            [39.27155516, -8.24342568],
                            [39.27124881, -8.24377923],
                            [39.27124881, -8.24417993],
                            [39.2717201, -8.24455707],
                            [39.27230923, -8.24514632],
                            [39.27270984, -8.24566487],
                            [39.2726627, -8.2461127],
                            [39.27457146, -8.24615984],
                            [39.2744065, -8.24594771],
                            [39.27405303, -8.24561773],
                            [39.27362886, -8.24538202],
                            [39.27332252, -8.24524061],
                            [39.27306331, -8.24502847],
                            [39.27325184, -8.24469849],
                        ],
                        [
                            [39.2984971, -8.23335371],
                            [39.29884029, -8.233225],
                            [39.29914058, -8.23283882],
                            [39.29944088, -8.23249556],
                            [39.29969826, -8.23213083],
                            [39.29971972, -8.23178757],
                            [39.29976262, -8.23150867],
                            [39.30004146, -8.23114395],
                            [39.30006291, -8.23062904],
                            [39.30008437, -8.23024288],
                            [39.30023452, -8.23007124],
                            [39.30057771, -8.22989961],
                            [39.30107103, -8.22966362],
                            [39.30150003, -8.22944908],
                            [39.30186466, -8.22899854],
                            [39.30186466, -8.22839782],
                            [39.30177887, -8.22796874],
                            [39.30162872, -8.22775419],
                            [39.30143568, -8.22775419],
                            [39.30128553, -8.22792583],
                            [39.30109249, -8.22816182],
                            [39.30085655, -8.22826909],
                            [39.30053481, -8.22820473],
                            [39.30032032, -8.22814036],
                            [39.30002003, -8.22850508],
                            [39.29995567, -8.22889125],
                            [39.29967684, -8.22923452],
                            [39.29920495, -8.22964215],
                            [39.29890465, -8.22992105],
                            [39.29838987, -8.22985669],
                            [39.29778929, -8.22974942],
                            [39.2973174, -8.22974941],
                            [39.29688842, -8.2297065],
                            [39.29660957, -8.22985668],
                            [39.29611624, -8.22989959],
                            [39.29568724, -8.23009267],
                            [39.29525826, -8.23024286],
                            [39.29482928, -8.23045739],
                            [39.29429305, -8.23056466],
                            [39.29367101, -8.23045739],
                            [39.29311333, -8.23017847],
                            [39.2925771, -8.23009266],
                            [39.29225536, -8.23017847],
                            [39.29223391, -8.23037157],
                            [39.29251274, -8.23067192],
                            [39.29292029, -8.23090792],
                            [39.29322057, -8.23103665],
                            [39.29358521, -8.23140138],
                            [39.29394985, -8.23168027],
                            [39.29422869, -8.231809],
                            [39.29459333, -8.23187336],
                            [39.29485072, -8.23210936],
                            [39.29525825, -8.23238827],
                            [39.2957945, -8.23238827],
                            [39.29622348, -8.23238827],
                            [39.29656667, -8.232517],
                            [39.29695275, -8.23258136],
                            [39.2971458, -8.23258136],
                            [39.29748899, -8.23249555],
                            [39.29785362, -8.23253846],
                            [39.29804667, -8.23283882],
                            [39.29817537, -8.233225],
                            [39.2984971, -8.23335371],
                        ],
                        [
                            [39.32430033, -8.23067813],
                            [39.32391471, -8.22966561],
                            [39.32333626, -8.22956918],
                            [39.32290242, -8.23019597],
                            [39.32256499, -8.23067812],
                            [39.32242037, -8.23130491],
                            [39.32222754, -8.23241385],
                            [39.32217934, -8.23313707],
                            [39.32179371, -8.23381207],
                            [39.32160088, -8.23477638],
                            [39.3217937, -8.23598174],
                            [39.3218419, -8.23684962],
                            [39.32135985, -8.23766927],
                            [39.32131164, -8.23839248],
                            [39.32140806, -8.23911571],
                            [39.32116704, -8.23926035],
                            [39.3207332, -8.23930857],
                            [39.32029935, -8.23945321],
                            [39.31981732, -8.23993536],
                            [39.31938348, -8.24007999],
                            [39.31866041, -8.24007999],
                            [39.31827479, -8.24065856],
                            [39.31759994, -8.24133358],
                            [39.31755171, -8.2423943],
                            [39.3184194, -8.24297288],
                            [39.31904604, -8.24287646],
                            [39.31967269, -8.24258718],
                            [39.31981731, -8.24210502],
                            [39.32025114, -8.24210503],
                            [39.32015473, -8.24273181],
                            [39.31986551, -8.24282824],
                            [39.31962449, -8.24331039],
                            [39.31914244, -8.24379254],
                            [39.31890142, -8.2443229],
                            [39.31909423, -8.2449979],
                            [39.31943166, -8.24567292],
                            [39.3199137, -8.245914],
                            [39.32029932, -8.245914],
                            [39.32087779, -8.24567293],
                            [39.32097419, -8.24519077],
                            [39.32150444, -8.24490149],
                            [39.32189006, -8.24504613],
                            [39.32208288, -8.24572114],
                            [39.32198647, -8.24601043],
                            [39.32791555, -8.24605867],
                            [39.32757813, -8.24576938],
                            [39.32714429, -8.24572117],
                            [39.32656584, -8.24572116],
                            [39.3260356, -8.24538366],
                            [39.32632482, -8.24494972],
                            [39.32666227, -8.24446757],
                            [39.32661406, -8.24427472],
                            [39.32622843, -8.24388899],
                            [39.3258428, -8.24364792],
                            [39.32536076, -8.24316578],
                            [39.32536076, -8.24268363],
                            [39.32511974, -8.24176755],
                            [39.32521615, -8.24104432],
                            [39.32545717, -8.24065859],
                            [39.32593922, -8.24056217],
                            [39.32632484, -8.24056217],
                            [39.32656587, -8.24017646],
                            [39.32646947, -8.23974252],
                            [39.32598742, -8.23921216],
                            [39.32569821, -8.23844071],
                            [39.32569821, -8.23781393],
                            [39.32531259, -8.23704248],
                            [39.32502336, -8.23689785],
                            [39.32449312, -8.23660856],
                            [39.3242039, -8.23602998],
                            [39.32434851, -8.23545139],
                            [39.32434851, -8.23496925],
                            [39.32487875, -8.23501747],
                            [39.32531259, -8.23549962],
                            [39.32618026, -8.23540318],
                            [39.3266141, -8.23525855],
                            [39.32690333, -8.23516211],
                            [39.32757818, -8.2347764],
                            [39.32777101, -8.23323352],
                            [39.32777101, -8.23241387],
                            [39.32781921, -8.23173887],
                            [39.32796382, -8.23043707],
                            [39.32815665, -8.22942455],
                            [39.32834947, -8.22841205],
                            [39.32839768, -8.22735132],
                            [39.32844588, -8.22653167],
                            [39.32801206, -8.22614596],
                            [39.32762642, -8.22624238],
                            [39.32772283, -8.22706203],
                            [39.32757821, -8.22802632],
                            [39.3275782, -8.22874955],
                            [39.32724078, -8.22908705],
                            [39.32675873, -8.22932812],
                            [39.32618029, -8.23000313],
                            [39.32569824, -8.23062992],
                            [39.32550542, -8.23125671],
                            [39.32497519, -8.23154599],
                            [39.32434852, -8.23130491],
                            [39.32430033, -8.23067813],
                        ],
                        [
                            [39.30010563, -8.22381104],
                            [39.30010563, -8.22341009],
                            [39.30040629, -8.22295901],
                            [39.30062343, -8.22269171],
                            [39.30072364, -8.22234087],
                            [39.30084056, -8.22197333],
                            [39.30085726, -8.22170603],
                            [39.30075705, -8.22155567],
                            [39.30060673, -8.22145543],
                            [39.3003896, -8.22133849],
                            [39.30013904, -8.22128835],
                            [39.29990521, -8.22112129],
                            [39.299805, -8.22095423],
                            [39.29958787, -8.22075375],
                            [39.29922041, -8.22058669],
                            [39.29901997, -8.22060339],
                            [39.29878613, -8.22038621],
                            [39.29843538, -8.22028596],
                            [39.2979844, -8.2203528],
                            [39.29763365, -8.22051986],
                            [39.2972829, -8.22068692],
                            [39.29708245, -8.22112128],
                            [39.29713257, -8.22157236],
                            [39.29701563, -8.22192319],
                            [39.29688201, -8.2222239],
                            [39.29686531, -8.22249122],
                            [39.29713256, -8.22280864],
                            [39.2973831, -8.2229757],
                            [39.29751672, -8.22337665],
                            [39.29763363, -8.22389456],
                            [39.29773384, -8.22444588],
                            [39.29813472, -8.22466307],
                            [39.29851887, -8.2247466],
                            [39.29873601, -8.22498049],
                            [39.29885292, -8.22543156],
                            [39.29883622, -8.226033],
                            [39.2987193, -8.22646736],
                            [39.2987694, -8.22688503],
                            [39.29890302, -8.22721915],
                            [39.29923709, -8.22731939],
                            [39.29948762, -8.22723586],
                            [39.29963795, -8.22665115],
                            [39.29963795, -8.22635042],
                            [39.29957114, -8.22598288],
                            [39.29960455, -8.22564876],
                            [39.29977157, -8.22533132],
                            [39.29988849, -8.22483014],
                            [39.30005552, -8.22436235],
                            [39.30010563, -8.22381104],
                        ],
                        [
                            [39.27187079, -8.22133693],
                            [39.27199218, -8.22100737],
                            [39.27218295, -8.22066044],
                            [39.27239106, -8.22043495],
                            [39.27277257, -8.22010539],
                            [39.27320612, -8.21979316],
                            [39.27353561, -8.21949828],
                            [39.27384777, -8.21934217],
                            [39.27395181, -8.21913403],
                            [39.27384777, -8.21890853],
                            [39.27367436, -8.21875242],
                            [39.27343157, -8.21849223],
                            [39.27341423, -8.21823204],
                            [39.27343157, -8.21804124],
                            [39.27353562, -8.2177984],
                            [39.27376106, -8.21762495],
                            [39.27407322, -8.2174515],
                            [39.27421195, -8.21708723],
                            [39.27409057, -8.21680971],
                            [39.27388247, -8.21654952],
                            [39.27369171, -8.21634137],
                            [39.27350095, -8.21613322],
                            [39.27310208, -8.21589037],
                            [39.27270322, -8.21571692],
                            [39.27246043, -8.21547408],
                            [39.27216563, -8.21517919],
                            [39.27209626, -8.21478024],
                            [39.2721483, -8.21431191],
                            [39.27242575, -8.21394765],
                            [39.27258184, -8.21351401],
                            [39.27258185, -8.21316709],
                            [39.27239109, -8.21278548],
                            [39.27251248, -8.21245591],
                            [39.27275526, -8.21226511],
                            [39.27303274, -8.212109],
                            [39.27320615, -8.21190085],
                            [39.27336223, -8.21144986],
                            [39.27350097, -8.21115498],
                            [39.27350098, -8.21096418],
                            [39.27337957, -8.21089479],
                            [39.27327553, -8.21091214],
                            [39.27313679, -8.21094683],
                            [39.27299806, -8.21092948],
                            [39.27291134, -8.21070399],
                            [39.27296337, -8.21028769],
                            [39.27320616, -8.2098367],
                            [39.27351831, -8.20954184],
                            [39.27389984, -8.209299],
                            [39.27419464, -8.20903881],
                            [39.27442009, -8.20863986],
                            [39.27448946, -8.2082409],
                            [39.27468022, -8.20773788],
                            [39.27500973, -8.20737361],
                            [39.27511377, -8.20697467],
                            [39.27500973, -8.20673182],
                            [39.2746282, -8.2066451],
                            [39.27424668, -8.2066451],
                            [39.2741253, -8.20640226],
                            [39.27414264, -8.20609003],
                            [39.27402124, -8.20555232],
                            [39.2740039, -8.20496255],
                            [39.27374378, -8.20454626],
                            [39.27357036, -8.20426872],
                            [39.27355302, -8.2039565],
                            [39.27381315, -8.2039912],
                            [39.27419466, -8.20425139],
                            [39.2746629, -8.20444219],
                            [39.27504443, -8.2042167],
                            [39.27526987, -8.20373102],
                            [39.27525253, -8.20326268],
                            [39.27533924, -8.20279434],
                            [39.27549531, -8.2023607],
                            [39.27539127, -8.20192706],
                            [39.27507911, -8.20164952],
                            [39.27466291, -8.20154545],
                            [39.27426405, -8.20159749],
                            [39.2740386, -8.20163217],
                            [39.2738305, -8.20158014],
                            [39.27365709, -8.20135465],
                            [39.27350102, -8.20128526],
                            [39.27327557, -8.20138934],
                            [39.27322354, -8.20180564],
                            [39.27332758, -8.20222193],
                            [39.2732062, -8.20243008],
                            [39.27294607, -8.2025168],
                            [39.27263391, -8.2025168],
                            [39.27249518, -8.20258618],
                            [39.27225239, -8.20282902],
                            [39.27216569, -8.20314125],
                            [39.272131, -8.20343612],
                            [39.2720096, -8.20355755],
                            [39.27169746, -8.20328001],
                            [39.27143733, -8.20296778],
                            [39.27128126, -8.20255148],
                            [39.27141999, -8.20213519],
                            [39.2715067, -8.20178828],
                            [39.27140265, -8.20161481],
                            [39.27119455, -8.20152809],
                            [39.27086504, -8.20151074],
                            [39.27057024, -8.20154543],
                            [39.2703448, -8.20173623],
                            [39.27006732, -8.20170154],
                            [39.26973784, -8.20147604],
                            [39.26973784, -8.20118116],
                            [39.2698072, -8.20078221],
                            [39.26987657, -8.20033122],
                            [39.26987657, -8.19998431],
                            [39.26975519, -8.19946394],
                            [39.26961645, -8.19927313],
                            [39.26932163, -8.19908233],
                            [39.26895746, -8.19896092],
                            [39.2684372, -8.19875275],
                            [39.26805568, -8.19864868],
                            [39.26783024, -8.19864868],
                            [39.26755277, -8.19871807],
                            [39.26739669, -8.19896091],
                            [39.26725796, -8.19930782],
                            [39.26720593, -8.19955066],
                            [39.26704986, -8.19988022],
                            [39.26687644, -8.2001751],
                            [39.26668567, -8.20043529],
                            [39.2663287, -8.20072047],
                            [39.26642224, -8.20072172],
                            [39.26590267, -8.20077946],
                            [39.26526763, -8.2008372],
                            [39.26492125, -8.20118366],
                            [39.26503671, -8.20199206],
                            [39.26555628, -8.20233853],
                            [39.26613357, -8.20314693],
                            [39.26636449, -8.2037821],
                            [39.26611338, -8.20400851],
                            [39.26581856, -8.20411258],
                            [39.26538502, -8.20404319],
                            [39.26514223, -8.20406053],
                            [39.26502084, -8.20426868],
                            [39.26491678, -8.20466765],
                            [39.26484742, -8.20491049],
                            [39.26451792, -8.20494516],
                            [39.26401502, -8.20478905],
                            [39.2635988, -8.2048064],
                            [39.26333869, -8.20503189],
                            [39.26314791, -8.20534412],
                            [39.26297449, -8.2056043],
                            [39.26290514, -8.20600326],
                            [39.26287045, -8.20648893],
                            [39.2628531, -8.20692257],
                            [39.26302652, -8.20728684],
                            [39.26307854, -8.2076511],
                            [39.26309589, -8.20796333],
                            [39.2629398, -8.20808474],
                            [39.26274904, -8.20785924],
                            [39.262645, -8.20756438],
                            [39.26240221, -8.20707869],
                            [39.26203803, -8.20688789],
                            [39.26182992, -8.20688789],
                            [39.26162182, -8.20693991],
                            [39.26129234, -8.20690523],
                            [39.26104955, -8.2067838],
                            [39.26059866, -8.20669707],
                            [39.26032119, -8.20688788],
                            [39.26009574, -8.20730418],
                            [39.26011308, -8.20782454],
                            [39.26000904, -8.20824083],
                            [39.25997435, -8.20867448],
                            [39.26009573, -8.20900405],
                            [39.26049459, -8.20903875],
                            [39.2608241, -8.2088306],
                            [39.26108423, -8.20848368],
                            [39.26127499, -8.20824084],
                            [39.2615698, -8.20848368],
                            [39.26162181, -8.20891733],
                            [39.26146574, -8.20919486],
                            [39.26139637, -8.2096285],
                            [39.26139637, -8.21014888],
                            [39.26155245, -8.21086006],
                            [39.26176055, -8.21127636],
                            [39.26222878, -8.21141512],
                            [39.2626103, -8.21141512],
                            [39.26300915, -8.21172735],
                            [39.26307852, -8.2121263],
                            [39.26290511, -8.21243852],
                            [39.26255826, -8.21268136],
                            [39.26250623, -8.21299359],
                            [39.26248889, -8.2133405],
                            [39.26238484, -8.21373946],
                            [39.26221143, -8.21403433],
                            [39.26222877, -8.2143639],
                            [39.26259294, -8.21457205],
                            [39.26293977, -8.21465877],
                            [39.26351206, -8.21504039],
                            [39.26389357, -8.21552606],
                            [39.2640323, -8.21578625],
                            [39.26396294, -8.21609848],
                            [39.26365078, -8.21602909],
                            [39.26347737, -8.21602909],
                            [39.26311319, -8.21632397],
                            [39.2629571, -8.216827],
                            [39.26285306, -8.21729532],
                            [39.26285306, -8.21769429],
                            [39.26309584, -8.21778101],
                            [39.2633733, -8.2176596],
                            [39.26366812, -8.2176596],
                            [39.26382419, -8.21783305],
                            [39.26384153, -8.21812794],
                            [39.26401496, -8.21844015],
                            [39.26418837, -8.21882177],
                            [39.26410166, -8.21918602],
                            [39.26396293, -8.21944621],
                            [39.26392824, -8.21979312],
                            [39.26401495, -8.22005331],
                            [39.2643271, -8.22001862],
                            [39.26472596, -8.22001862],
                            [39.26476064, -8.22038288],
                            [39.26455254, -8.22078184],
                            [39.26427506, -8.22105936],
                            [39.26396292, -8.22121548],
                            [39.26375482, -8.22151035],
                            [39.26392823, -8.22166647],
                            [39.2647086, -8.22173584],
                            [39.26535025, -8.22185728],
                            [39.26588785, -8.22204808],
                            [39.26659887, -8.22251642],
                            [39.26691101, -8.22289802],
                            [39.26729254, -8.22340106],
                            [39.26760469, -8.22326228],
                            [39.26800355, -8.22305414],
                            [39.26819432, -8.22314087],
                            [39.26817698, -8.22341841],
                            [39.26824633, -8.22378266],
                            [39.26836773, -8.22397346],
                            [39.26859318, -8.22388675],
                            [39.26885331, -8.22388675],
                            [39.26902672, -8.22393877],
                            [39.26923482, -8.2242857],
                            [39.26959899, -8.22464995],
                            [39.26978975, -8.2246673],
                            [39.26989381, -8.22449385],
                            [39.27008457, -8.22426835],
                            [39.27032736, -8.2240949],
                            [39.27057014, -8.22404286],
                            [39.27083027, -8.22374798],
                            [39.27083028, -8.22353983],
                            [39.27060483, -8.22326229],
                            [39.27029268, -8.22279397],
                            [39.27003255, -8.22232563],
                            [39.26984179, -8.22192667],
                            [39.26989382, -8.22173587],
                            [39.27013661, -8.22164915],
                            [39.27036205, -8.2216665],
                            [39.27076091, -8.22170118],
                            [39.27122914, -8.22161446],
                            [39.27159332, -8.22147569],
                            [39.27187079, -8.22133693],
                        ],
                        [
                            [39.25998278, -8.21820564],
                            [39.26020227, -8.21801049],
                            [39.26010471, -8.21752264],
                            [39.26003154, -8.21713234],
                            [39.26020227, -8.21671766],
                            [39.2601535, -8.21642493],
                            [39.2599584, -8.21608343],
                            [39.25966574, -8.21559556],
                            [39.25939747, -8.2154492],
                            [39.25910482, -8.2154248],
                            [39.25898289, -8.21557116],
                            [39.25898289, -8.21593706],
                            [39.2589585, -8.2161322],
                            [39.25861706, -8.2161322],
                            [39.25837319, -8.21615659],
                            [39.25820246, -8.21654688],
                            [39.25830002, -8.2169128],
                            [39.25849512, -8.21727869],
                            [39.25830002, -8.21740065],
                            [39.25798297, -8.21744944],
                            [39.25786103, -8.21766898],
                            [39.25805614, -8.21796169],
                            [39.25830001, -8.21837638],
                            [39.25825123, -8.21866911],
                            [39.2585195, -8.219035],
                            [39.25864143, -8.21918136],
                            [39.25888532, -8.21910819],
                            [39.25908042, -8.21879107],
                            [39.25908042, -8.21842517],
                            [39.25905603, -8.21815684],
                            [39.25895849, -8.21786413],
                            [39.25908042, -8.21764459],
                            [39.25917798, -8.2176202],
                            [39.25949502, -8.21798609],
                            [39.25998278, -8.21820564],
                        ],
                        [
                            [39.25488575, -8.21279031],
                            [39.25473941, -8.21300985],
                            [39.2547638, -8.21327818],
                            [39.25505646, -8.21347333],
                            [39.25547106, -8.21330257],
                            [39.25581248, -8.21318061],
                            [39.25612953, -8.21305865],
                            [39.25644656, -8.2132538],
                            [39.25669044, -8.21344894],
                            [39.25693432, -8.21374165],
                            [39.25720258, -8.21400999],
                            [39.25737329, -8.21400999],
                            [39.25764156, -8.2139368],
                            [39.25764156, -8.21357091],
                            [39.25742207, -8.21318062],
                            [39.25732453, -8.2128879],
                            [39.25710504, -8.2124976],
                            [39.25673923, -8.21215609],
                            [39.25649535, -8.21181459],
                            [39.25630025, -8.2117902],
                            [39.25605637, -8.21196095],
                            [39.25583687, -8.21220488],
                            [39.25547106, -8.21242442],
                            [39.25510524, -8.21257077],
                            [39.25488575, -8.21279031],
                        ],
                        [
                            [39.31307258, -8.20004223],
                            [39.31323851, -8.19986522],
                            [39.31345974, -8.19973244],
                            [39.31364778, -8.19963288],
                            [39.31392431, -8.19954436],
                            [39.31407918, -8.19941159],
                            [39.31422297, -8.19916818],
                            [39.31426722, -8.19896904],
                            [39.31432252, -8.19867031],
                            [39.31432252, -8.19838265],
                            [39.31432252, -8.19819457],
                            [39.31432252, -8.19799542],
                            [39.31436679, -8.19786265],
                            [39.31428935, -8.19778519],
                            [39.31401282, -8.19780732],
                            [39.31376947, -8.19791797],
                            [39.31351505, -8.19800647],
                            [39.31324958, -8.19812817],
                            [39.3129509, -8.19823882],
                            [39.31271862, -8.19829412],
                            [39.31247528, -8.19827201],
                            [39.31230935, -8.19819456],
                            [39.31217662, -8.19811711],
                            [39.31204388, -8.19810604],
                            [39.31195538, -8.19829412],
                            [39.31195538, -8.19850435],
                            [39.31188902, -8.1986703],
                            [39.3117784, -8.19882519],
                            [39.31171203, -8.19901329],
                            [39.31155717, -8.19926774],
                            [39.31143549, -8.19947796],
                            [39.31143549, -8.19969924],
                            [39.31150186, -8.19986521],
                            [39.31161248, -8.20005329],
                            [39.31166777, -8.20027457],
                            [39.31174521, -8.20056223],
                            [39.31197749, -8.20079457],
                            [39.31230934, -8.20088309],
                            [39.31258588, -8.2008167],
                            [39.31270756, -8.20068394],
                            [39.31282923, -8.20044054],
                            [39.3129509, -8.20025244],
                            [39.31307258, -8.20004223],
                        ],
                        [
                            [39.29192174, -8.20230693],
                            [39.29164718, -8.20230693],
                            [39.2913589, -8.20218335],
                            [39.29100198, -8.20204604],
                            [39.29057643, -8.20197738],
                            [39.29032933, -8.20212843],
                            [39.29020579, -8.20241676],
                            [39.29017832, -8.20273257],
                            [39.2904117, -8.20319942],
                            [39.29093333, -8.20337791],
                            [39.29134516, -8.20340539],
                            [39.29167463, -8.20348776],
                            [39.29216881, -8.20365253],
                            [39.29253947, -8.20387223],
                            [39.29304739, -8.20394088],
                            [39.29389849, -8.20400954],
                            [39.29455742, -8.204037],
                            [39.29487316, -8.20395461],
                            [39.29496925, -8.20368],
                            [39.29501044, -8.2034054],
                            [39.2949418, -8.20303466],
                            [39.29472215, -8.2028287],
                            [39.29455743, -8.20255409],
                            [39.29436523, -8.20221082],
                            [39.2941456, -8.20200486],
                            [39.29395341, -8.20177144],
                            [39.29372004, -8.20157921],
                            [39.2933494, -8.20146936],
                            [39.29307485, -8.20145563],
                            [39.29295131, -8.20155175],
                            [39.29292384, -8.20184009],
                            [39.29284148, -8.20210098],
                            [39.29259438, -8.20222455],
                            [39.29223746, -8.20226574],
                            [39.29192174, -8.20230693],
                        ],
                        [
                            [39.28994491, -8.21217927],
                            [39.29034302, -8.21216554],
                            [39.29074111, -8.21253627],
                            [39.29112549, -8.21282462],
                            [39.2915785, -8.21316789],
                            [39.29168831, -8.213264],
                            [39.29205896, -8.21346996],
                            [39.29231978, -8.21338759],
                            [39.29255315, -8.21315415],
                            [39.29281398, -8.21314043],
                            [39.29306106, -8.21319535],
                            [39.29328071, -8.21338759],
                            [39.29345917, -8.21359355],
                            [39.29374744, -8.21363473],
                            [39.29407691, -8.21366221],
                            [39.29431028, -8.21348371],
                            [39.2944201, -8.21327775],
                            [39.29446129, -8.21303059],
                            [39.29437892, -8.21275598],
                            [39.29425538, -8.21252256],
                            [39.29407692, -8.21242645],
                            [39.29376118, -8.21235779],
                            [39.293569, -8.21235779],
                            [39.29325326, -8.21234405],
                            [39.2930199, -8.21222048],
                            [39.29312971, -8.21201452],
                            [39.29330817, -8.21183602],
                            [39.29358272, -8.21165752],
                            [39.29363764, -8.21130052],
                            [39.29355527, -8.21105338],
                            [39.293418, -8.21092979],
                            [39.29310226, -8.21094353],
                            [39.29281399, -8.21094353],
                            [39.29251197, -8.21077876],
                            [39.2922237, -8.21046295],
                            [39.29207269, -8.21021579],
                            [39.29186678, -8.21005103],
                            [39.29156478, -8.20987253],
                            [39.29126278, -8.20985879],
                            [39.29097449, -8.20991372],
                            [39.29060385, -8.20994117],
                            [39.29026066, -8.20980387],
                            [39.2899312, -8.20970775],
                            [39.28956055, -8.20972147],
                            [39.28918991, -8.20968029],
                            [39.28901144, -8.20952925],
                            [39.28881927, -8.20935074],
                            [39.28855845, -8.20904867],
                            [39.28824271, -8.20898002],
                            [39.28772106, -8.20899375],
                            [39.28732297, -8.20893882],
                            [39.28700723, -8.20896627],
                            [39.28682877, -8.20911732],
                            [39.28693859, -8.20941939],
                            [39.28728178, -8.20974894],
                            [39.28761123, -8.20996862],
                            [39.28791324, -8.20998236],
                            [39.28814662, -8.21011967],
                            [39.28821525, -8.21027069],
                            [39.28829761, -8.21068261],
                            [39.28859961, -8.21099842],
                            [39.2889428, -8.21125932],
                            [39.2891899, -8.21157511],
                            [39.28929972, -8.21183601],
                            [39.28956054, -8.21213808],
                            [39.28994491, -8.21217927],
                        ],
                        [
                            [39.30017301, -8.21394388],
                            [39.30025925, -8.21321078],
                            [39.29982811, -8.21290891],
                            [39.29944008, -8.21273642],
                            [39.2988796, -8.21260705],
                            [39.29836223, -8.21243455],
                            [39.29818979, -8.21200331],
                            [39.29775865, -8.21161519],
                            [39.29741373, -8.21161519],
                            [39.29724128, -8.21208956],
                            [39.29676701, -8.21260704],
                            [39.29624966, -8.21308139],
                            [39.29655144, -8.21359888],
                            [39.29728438, -8.21394387],
                            [39.29823288, -8.21450448],
                            [39.2989227, -8.2147201],
                            [39.2998281, -8.2147201],
                            [39.30017301, -8.21424575],
                            [39.30017301, -8.21394388],
                        ],
                        [
                            [39.31345209, -8.21290897],
                            [39.31336587, -8.21329709],
                            [39.31332274, -8.21377144],
                            [39.31310717, -8.21394394],
                            [39.31276226, -8.21398705],
                            [39.3125898, -8.2140733],
                            [39.31263292, -8.21463391],
                            [39.31271915, -8.21523765],
                            [39.31340897, -8.21523765],
                            [39.31396945, -8.21467705],
                            [39.31509042, -8.21446143],
                            [39.31590957, -8.21450455],
                            [39.31651318, -8.21450456],
                            [39.31703053, -8.21407332],
                            [39.31711676, -8.21372834],
                            [39.31694432, -8.21316773],
                            [39.31703054, -8.21290898],
                            [39.31664252, -8.21230526],
                            [39.31634072, -8.21221901],
                            [39.31599581, -8.21234837],
                            [39.31569401, -8.21239151],
                            [39.315306, -8.21277961],
                            [39.31496107, -8.21295211],
                            [39.31496107, -8.21355583],
                            [39.31431436, -8.21355582],
                            [39.31414192, -8.21325397],
                            [39.31388322, -8.21299522],
                            [39.31345209, -8.21290897],
                        ],
                        [
                            [39.31086525, -8.21640198],
                            [39.31121016, -8.21683322],
                            [39.31155507, -8.21653135],
                            [39.31237422, -8.21566888],
                            [39.31189998, -8.21480641],
                            [39.31138262, -8.21403018],
                            [39.31095148, -8.21359894],
                            [39.31047723, -8.21308145],
                            [39.30952872, -8.21308145],
                            [39.30905447, -8.2137283],
                            [39.30922692, -8.21446141],
                            [39.30948561, -8.21515138],
                            [39.31021854, -8.21605697],
                            [39.31086525, -8.21640198],
                        ],
                        [
                            [39.29342458, -8.21746255],
                            [39.29320745, -8.21741243],
                            [39.29280657, -8.21724537],
                            [39.29243912, -8.21724537],
                            [39.2923389, -8.21757949],
                            [39.2923389, -8.2178635],
                            [39.29245582, -8.21801385],
                            [39.29273976, -8.21831458],
                            [39.29300701, -8.21853176],
                            [39.29312393, -8.21883248],
                            [39.29304041, -8.21936709],
                            [39.29307382, -8.21955086],
                            [39.29335776, -8.21960098],
                            [39.29375862, -8.21958428],
                            [39.29412609, -8.21958428],
                            [39.29482759, -8.21958428],
                            [39.29504474, -8.21928357],
                            [39.29527858, -8.2188659],
                            [39.2953955, -8.21853178],
                            [39.2954122, -8.21806399],
                            [39.29532869, -8.21766304],
                            [39.29529529, -8.21724538],
                            [39.29531199, -8.2167943],
                            [39.29519507, -8.216527],
                            [39.29496122, -8.21652699],
                            [39.29467728, -8.21662723],
                            [39.29441004, -8.21692794],
                            [39.2941094, -8.21727879],
                            [39.29367511, -8.21747926],
                            [39.29342458, -8.21746255],
                        ],
                        [
                            [39.28522003, -8.21604014],
                            [39.28500554, -8.21584706],
                            [39.28470526, -8.21563252],
                            [39.28431917, -8.21541796],
                            [39.28412613, -8.21520342],
                            [39.28397598, -8.21490307],
                            [39.28397598, -8.21449544],
                            [39.28399744, -8.21404489],
                            [39.28395453, -8.21348709],
                            [39.28386874, -8.21316528],
                            [39.28371859, -8.21292928],
                            [39.28354701, -8.21290782],
                            [39.28333251, -8.21312237],
                            [39.28294642, -8.21346564],
                            [39.28264613, -8.21389472],
                            [39.28230294, -8.21404488],
                            [39.28189539, -8.21417361],
                            [39.28174526, -8.21447398],
                            [39.2817238, -8.21486014],
                            [39.28174525, -8.21526778],
                            [39.28176669, -8.21550378],
                            [39.2818525, -8.21571832],
                            [39.28208844, -8.21597577],
                            [39.28234582, -8.21634048],
                            [39.28251742, -8.21674812],
                            [39.28251742, -8.21702702],
                            [39.28264612, -8.21724157],
                            [39.28279625, -8.21730593],
                            [39.28313944, -8.21762774],
                            [39.28352553, -8.21801392],
                            [39.28382581, -8.21831428],
                            [39.28412611, -8.21844301],
                            [39.2844264, -8.21840009],
                            [39.28461944, -8.21809974],
                            [39.2846838, -8.21782083],
                            [39.28464089, -8.21749902],
                            [39.28457655, -8.21724158],
                            [39.28457656, -8.21696267],
                            [39.28474814, -8.21666232],
                            [39.28500554, -8.21649068],
                            [39.28519858, -8.21631905],
                            [39.28522003, -8.21604014],
                        ],
                        [
                            [39.30032025, -8.24378045],
                            [39.30001997, -8.24358737],
                            [39.29986982, -8.2432441],
                            [39.29952663, -8.24315829],
                            [39.29905474, -8.24322264],
                            [39.29838981, -8.24352299],
                            [39.29763908, -8.24352299],
                            [39.29716721, -8.24335136],
                            [39.29658807, -8.24335136],
                            [39.29618052, -8.24367317],
                            [39.29635213, -8.24403789],
                            [39.29665241, -8.24414516],
                            [39.29678111, -8.24446697],
                            [39.2967811, -8.24489605],
                            [39.29705994, -8.24515351],
                            [39.29731733, -8.24556115],
                            [39.29697414, -8.24568986],
                            [39.29669531, -8.24573277],
                            [39.29635212, -8.24560404],
                            [39.29620197, -8.24530369],
                            [39.29590168, -8.24526077],
                            [39.29570863, -8.24534658],
                            [39.29566574, -8.24577568],
                            [39.29562283, -8.24603312],
                            [39.29596602, -8.24605458],
                            [39.30038458, -8.24599024],
                            [39.30079213, -8.24556116],
                            [39.30094227, -8.24513207],
                            [39.30077068, -8.24457426],
                            [39.30079214, -8.24414518],
                            [39.30068489, -8.24386627],
                            [39.30032025, -8.24378045],
                        ],
                        [
                            [39.28232434, -8.24288361],
                            [39.2819473, -8.24245935],
                            [39.28180591, -8.24196438],
                            [39.28182948, -8.24154011],
                            [39.28187662, -8.2412337],
                            [39.28218297, -8.24104513],
                            [39.28270138, -8.240998],
                            [39.28303129, -8.24066802],
                            [39.28298417, -8.24033803],
                            [39.28314912, -8.23998449],
                            [39.28347903, -8.23974877],
                            [39.28347903, -8.23941879],
                            [39.28319626, -8.23915952],
                            [39.28274853, -8.23894738],
                            [39.28239506, -8.23887667],
                            [39.28194731, -8.23897095],
                            [39.28164098, -8.23899451],
                            [39.28133463, -8.23882953],
                            [39.28098116, -8.23873524],
                            [39.2807455, -8.23894738],
                            [39.28027421, -8.23908879],
                            [39.27989717, -8.23934807],
                            [39.28010926, -8.23974876],
                            [39.28032133, -8.24003161],
                            [39.2802742, -8.24033802],
                            [39.2802742, -8.24102156],
                            [39.2802742, -8.24156367],
                            [39.28001499, -8.2420115],
                            [39.28001498, -8.24250649],
                            [39.28001498, -8.24309574],
                            [39.27996784, -8.24356714],
                            [39.27977931, -8.24394427],
                            [39.2794494, -8.24436853],
                            [39.27930802, -8.2448635],
                            [39.27940228, -8.24531133],
                            [39.27956724, -8.2457356],
                            [39.27999139, -8.24580632],
                            [39.28022706, -8.24535849],
                            [39.28041557, -8.24476922],
                            [39.28069835, -8.24436854],
                            [39.28135817, -8.24417998],
                            [39.28171164, -8.24427426],
                            [39.28157026, -8.24483994],
                            [39.28178234, -8.24521708],
                            [39.28215937, -8.24557063],
                            [39.28263068, -8.24592418],
                            [39.28305485, -8.24566491],
                            [39.28359684, -8.24526422],
                            [39.28380893, -8.24491067],
                            [39.28387962, -8.24436855],
                            [39.28350259, -8.24396785],
                            [39.28317268, -8.24375572],
                            [39.28272495, -8.24333145],
                            [39.28232434, -8.24288361],
                        ],
                        [
                            [39.29088257, -8.23888886],
                            [39.2910327, -8.23923213],
                            [39.29124719, -8.23938232],
                            [39.29156895, -8.23927505],
                            [39.29182633, -8.23906049],
                            [39.29221242, -8.23863141],
                            [39.29236257, -8.23833106],
                            [39.29281301, -8.23828815],
                            [39.29330633, -8.23835252],
                            [39.29358518, -8.23858852],
                            [39.29399271, -8.23897469],
                            [39.2943359, -8.23938233],
                            [39.29467909, -8.23961833],
                            [39.29510809, -8.23959687],
                            [39.29534401, -8.23925361],
                            [39.29555852, -8.23884597],
                            [39.29549417, -8.23830962],
                            [39.29545128, -8.237902],
                            [39.29540838, -8.23758017],
                            [39.29525823, -8.23732273],
                            [39.29504374, -8.23717255],
                            [39.2948078, -8.237194],
                            [39.29457185, -8.23751581],
                            [39.29416432, -8.23749435],
                            [39.29377823, -8.23747289],
                            [39.29334924, -8.23745145],
                            [39.29279156, -8.23727981],
                            [39.29236258, -8.236958],
                            [39.29204083, -8.23676491],
                            [39.29184779, -8.23674345],
                            [39.2915475, -8.23676491],
                            [39.29126866, -8.23661472],
                            [39.29079677, -8.23655035],
                            [39.29064663, -8.23667908],
                            [39.29064663, -8.23708671],
                            [39.29068952, -8.23736561],
                            [39.29079676, -8.23762307],
                            [39.29056082, -8.23796633],
                            [39.29008895, -8.23822378],
                            [39.28963849, -8.23848123],
                            [39.28953125, -8.2388245],
                            [39.28970285, -8.23908194],
                            [39.28993879, -8.23908194],
                            [39.29034632, -8.23884595],
                            [39.29051793, -8.23869578],
                            [39.29079676, -8.23863141],
                            [39.29088257, -8.23888886],
                        ],
                        [
                            [39.28194734, -8.23347908],
                            [39.2821123, -8.2337855],
                            [39.28272499, -8.23435119],
                            [39.28307846, -8.23470474],
                            [39.28345549, -8.23484616],
                            [39.28362045, -8.23453976],
                            [39.28362046, -8.23416263],
                            [39.28340837, -8.23385621],
                            [39.28319629, -8.23357337],
                            [39.28293708, -8.23331409],
                            [39.2825836, -8.23298411],
                            [39.2821123, -8.23286625],
                            [39.28182952, -8.23293697],
                            [39.28185309, -8.23319624],
                            [39.28194734, -8.23347908],
                        ],
                        [
                            [39.30305232, -8.19377634],
                            [39.30293476, -8.19363525],
                            [39.30280545, -8.19344712],
                            [39.30280545, -8.19327074],
                            [39.30288775, -8.19311789],
                            [39.30308758, -8.19304734],
                            [39.30321689, -8.19303559],
                            [39.30340498, -8.19294153],
                            [39.30356955, -8.19271811],
                            [39.30367536, -8.19243592],
                            [39.30372237, -8.19223602],
                            [39.30375764, -8.19202439],
                            [39.30386345, -8.19168342],
                            [39.30398099, -8.19147177],
                            [39.3040868, -8.19130716],
                            [39.30421611, -8.19123662],
                            [39.30445121, -8.19122485],
                            [39.30465106, -8.19110727],
                            [39.30485088, -8.19094266],
                            [39.30499194, -8.19074278],
                            [39.30515654, -8.19056641],
                            [39.30533287, -8.19042532],
                            [39.30555622, -8.19033126],
                            [39.30581483, -8.19027248],
                            [39.30600293, -8.1902607],
                            [39.30615574, -8.19029597],
                            [39.30633208, -8.19024895],
                            [39.30649665, -8.19011961],
                            [39.30668474, -8.18991973],
                            [39.30687282, -8.18969632],
                            [39.30704916, -8.18949644],
                            [39.30715495, -8.18924951],
                            [39.30720199, -8.18907315],
                            [39.30726077, -8.18882623],
                            [39.30739007, -8.18861459],
                            [39.30754288, -8.18846173],
                            [39.30782501, -8.18826185],
                            [39.30806013, -8.18810899],
                            [39.30818944, -8.18797966],
                            [39.30829523, -8.18783854],
                            [39.30844805, -8.18768571],
                            [39.30863612, -8.18747407],
                            [39.30885949, -8.18722715],
                            [39.30912987, -8.18689793],
                            [39.30948253, -8.18650991],
                            [39.30977641, -8.18626299],
                            [39.31007031, -8.18599256],
                            [39.31037595, -8.18571037],
                            [39.31065807, -8.18541641],
                            [39.31108127, -8.18505191],
                            [39.31142218, -8.18466392],
                            [39.3115985, -8.18446402],
                            [39.31170431, -8.18428766],
                            [39.31186888, -8.18405249],
                            [39.31197468, -8.18382909],
                            [39.31216277, -8.18359394],
                            [39.31235085, -8.18333524],
                            [39.31259772, -8.18304131],
                            [39.3128916, -8.1827356],
                            [39.31322076, -8.18248868],
                            [39.31336182, -8.18232407],
                            [39.31338533, -8.18203012],
                            [39.31337359, -8.18166562],
                            [39.31335007, -8.18101894],
                            [39.31331481, -8.18059564],
                            [39.3132913, -8.18007829],
                            [39.31330307, -8.17953743],
                            [39.31326779, -8.17902006],
                            [39.313162, -8.1784792],
                            [39.31307971, -8.17798536],
                            [39.31305621, -8.17749153],
                            [39.31307971, -8.17705648],
                            [39.31312674, -8.17668022],
                            [39.3132678, -8.17628045],
                            [39.31345589, -8.17595123],
                            [39.31360871, -8.17564552],
                            [39.31374977, -8.17523399],
                            [39.31377329, -8.17494004],
                            [39.31376154, -8.17445795],
                            [39.31372628, -8.17410522],
                            [39.31374978, -8.17394061],
                            [39.31384384, -8.17376423],
                            [39.31394963, -8.17345853],
                            [39.31397315, -8.17318809],
                            [39.31391437, -8.17297644],
                            [39.31373803, -8.17285886],
                            [39.31359697, -8.17282359],
                            [39.3134324, -8.17283535],
                            [39.31315027, -8.17285886],
                            [39.31276234, -8.1729059],
                            [39.31244494, -8.17292941],
                            [39.31220984, -8.17291764],
                            [39.31208054, -8.17282358],
                            [39.31186893, -8.17275303],
                            [39.31159855, -8.17274128],
                            [39.31138697, -8.17274128],
                            [39.31111659, -8.17285885],
                            [39.3109285, -8.17299995],
                            [39.31072867, -8.17303522],
                            [39.31049355, -8.17302347],
                            [39.31019967, -8.17296468],
                            [39.30990579, -8.17285884],
                            [39.30968243, -8.17280007],
                            [39.30948259, -8.17290588],
                            [39.30922398, -8.17298819],
                            [39.30909467, -8.1730117],
                            [39.30889482, -8.1729529],
                            [39.30870672, -8.17284709],
                            [39.30849513, -8.17262368],
                            [39.30833058, -8.17236501],
                            [39.30823653, -8.17207105],
                            [39.30821302, -8.17187117],
                            [39.30821302, -8.17162425],
                            [39.30821302, -8.1713303],
                            [39.30816599, -8.17116569],
                            [39.30804845, -8.17111865],
                            [39.3078486, -8.17114217],
                            [39.30760172, -8.17120096],
                            [39.30737838, -8.17138909],
                            [39.30717855, -8.1715537],
                            [39.30704924, -8.1717771],
                            [39.30699046, -8.17198875],
                            [39.30704924, -8.17225917],
                            [39.30716679, -8.17248259],
                            [39.30726084, -8.17269422],
                            [39.30730785, -8.17291762],
                            [39.3072961, -8.17311752],
                            [39.30719029, -8.17337619],
                            [39.3070845, -8.17359959],
                            [39.30691992, -8.1737642],
                            [39.30679062, -8.17389354],
                            [39.30669657, -8.17409343],
                            [39.30663778, -8.17428156],
                            [39.30659076, -8.17458726],
                            [39.30653198, -8.17488122],
                            [39.30640267, -8.17518692],
                            [39.30633214, -8.17573955],
                            [39.30632039, -8.17620987],
                            [39.30620283, -8.1766214],
                            [39.30610878, -8.17695062],
                            [39.30612053, -8.17736216],
                            [39.30610878, -8.17799709],
                            [39.30614404, -8.17844389],
                            [39.30614404, -8.17896124],
                            [39.30619107, -8.17937277],
                            [39.30622891, -8.17974436],
                            [39.30628242, -8.17997981],
                            [39.30632521, -8.18023667],
                            [39.30642151, -8.18049353],
                            [39.30648571, -8.18072898],
                            [39.30654991, -8.18097513],
                            [39.30661409, -8.18119988],
                            [39.30667831, -8.18139252],
                            [39.30672994, -8.18169802],
                            [39.30680233, -8.18173615],
                            [39.30683759, -8.18198305],
                            [39.30686111, -8.18227702],
                            [39.30686111, -8.18264151],
                            [39.30684932, -8.18288843],
                            [39.30674353, -8.18305303],
                            [39.30661424, -8.18317062],
                            [39.30644967, -8.18315888],
                            [39.30628509, -8.18305303],
                            [39.30601472, -8.18301776],
                            [39.30580311, -8.18302953],
                            [39.30573258, -8.18327643],
                            [39.30563854, -8.18353512],
                            [39.30555625, -8.18386434],
                            [39.30545045, -8.18421707],
                            [39.30525061, -8.1846051],
                            [39.3050155, -8.18500486],
                            [39.30475688, -8.18528705],
                            [39.30456879, -8.1854987],
                            [39.30434544, -8.18553397],
                            [39.30413383, -8.18553397],
                            [39.30394575, -8.18551045],
                            [39.30378118, -8.18546342],
                            [39.30358135, -8.18534584],
                            [39.30336975, -8.18513419],
                            [39.3031699, -8.18504012],
                            [39.3028525, -8.18502837],
                            [39.30255862, -8.18502837],
                            [39.30232352, -8.18507541],
                            [39.30217069, -8.18512241],
                            [39.30207666, -8.18525177],
                            [39.30199436, -8.18546341],
                            [39.30184154, -8.18578088],
                            [39.30162995, -8.18603955],
                            [39.30139484, -8.18622768],
                            [39.30106569, -8.18635701],
                            [39.30078356, -8.18638053],
                            [39.30058371, -8.18638053],
                            [39.30046617, -8.18639228],
                            [39.30040739, -8.18652163],
                            [39.30033685, -8.186698],
                            [39.30023105, -8.1868626],
                            [39.30017227, -8.18705073],
                            [39.30019579, -8.18721536],
                            [39.30038387, -8.18737997],
                            [39.30056019, -8.1875681],
                            [39.30058371, -8.18775622],
                            [39.30058371, -8.18794435],
                            [39.30068951, -8.18805017],
                            [39.30086583, -8.18805017],
                            [39.30110095, -8.18803842],
                            [39.3013478, -8.18793259],
                            [39.30151239, -8.18787381],
                            [39.30166521, -8.1877915],
                            [39.30179452, -8.18776797],
                            [39.30190031, -8.18793259],
                            [39.30186504, -8.18817951],
                            [39.30178275, -8.18837941],
                            [39.30178275, -8.18861456],
                            [39.30194733, -8.18886148],
                            [39.30207664, -8.18924949],
                            [39.30222946, -8.18979036],
                            [39.3023235, -8.19035475],
                            [39.30240578, -8.19094265],
                            [39.30244104, -8.19146],
                            [39.30244104, -8.19183626],
                            [39.30240577, -8.19211845],
                            [39.30225295, -8.19235362],
                            [39.30210013, -8.1924947],
                            [39.30186502, -8.19258877],
                            [39.30166519, -8.19268283],
                            [39.3014771, -8.19280041],
                            [39.30137129, -8.19296502],
                            [39.30132428, -8.19324722],
                            [39.30134778, -8.19361172],
                            [39.30146533, -8.19385864],
                            [39.3017122, -8.19414084],
                            [39.30197081, -8.19452885],
                            [39.30213538, -8.19481103],
                            [39.30229997, -8.19501092],
                            [39.30241752, -8.19511676],
                            [39.30269965, -8.19509324],
                            [39.30285246, -8.19505797],
                            [39.30301705, -8.19492863],
                            [39.30307583, -8.19478753],
                            [39.30308758, -8.19465819],
                            [39.30305231, -8.19456412],
                            [39.30297002, -8.19439952],
                            [39.30298179, -8.19423491],
                            [39.30305231, -8.19412907],
                            [39.30308758, -8.19396447],
                            [39.30305232, -8.19377634],
                        ],
                        [
                            [39.32184199, -8.17431834],
                            [39.32159826, -8.17448087],
                            [39.32155762, -8.17484654],
                            [39.3216795, -8.17517159],
                            [39.32184198, -8.17549665],
                            [39.32212634, -8.17586233],
                            [39.32249194, -8.17586233],
                            [39.32265441, -8.17598423],
                            [39.32245133, -8.17626864],
                            [39.3220857, -8.17639052],
                            [39.32196386, -8.17691874],
                            [39.32196386, -8.17744695],
                            [39.32208569, -8.17797516],
                            [39.32237005, -8.1786659],
                            [39.32257317, -8.17903158],
                            [39.32293876, -8.17919409],
                            [39.32326374, -8.17919409],
                            [39.32342625, -8.17886905],
                            [39.32342625, -8.17838147],
                            [39.32358873, -8.17785325],
                            [39.32383246, -8.17744696],
                            [39.32419805, -8.17691875],
                            [39.32460429, -8.17630928],
                            [39.32480741, -8.17574046],
                            [39.32492925, -8.17525287],
                            [39.3250105, -8.17456213],
                            [39.32541674, -8.17354637],
                            [39.32561986, -8.17232742],
                            [39.32610732, -8.17082407],
                            [39.32639167, -8.16956451],
                            [39.32675728, -8.16846746],
                            [39.32716351, -8.1672079],
                            [39.32748848, -8.16619212],
                            [39.32748849, -8.16566391],
                            [39.32724476, -8.16542012],
                            [39.32704165, -8.1650138],
                            [39.32704165, -8.16452623],
                            [39.32720415, -8.16407929],
                            [39.32761037, -8.16359173],
                            [39.32805721, -8.16318541],
                            [39.32854469, -8.16249467],
                            [39.3289509, -8.16180395],
                            [39.32947899, -8.16099132],
                            [39.32976337, -8.16025996],
                            [39.33016958, -8.15965049],
                            [39.33053518, -8.15932545],
                            [39.33094139, -8.15908166],
                            [39.3309414, -8.15855347],
                            [39.33114452, -8.15790336],
                            [39.33146951, -8.15725326],
                            [39.33203821, -8.15623748],
                            [39.33264755, -8.15550613],
                            [39.33301314, -8.15489665],
                            [39.33301991, -8.15487412],
                            [39.33303046, -8.15487712],
                            [39.33303206, -8.1548747],
                            [39.33329449, -8.15448098],
                            [39.33372293, -8.15394531],
                            [39.33450839, -8.15305255],
                            [39.33550809, -8.15183836],
                            [39.33647209, -8.15087417],
                            [39.33715042, -8.15008853],
                            [39.33782879, -8.14951716],
                            [39.33797161, -8.14919576],
                            [39.33811442, -8.14876724],
                            [39.33854287, -8.14841011],
                            [39.33914982, -8.14805302],
                            [39.33961396, -8.14762449],
                            [39.33993528, -8.14712454],
                            [39.34029233, -8.1467317],
                            [39.34061364, -8.14644602],
                            [39.34107778, -8.14616033],
                            [39.34150622, -8.14580322],
                            [39.34207747, -8.14533898],
                            [39.34282725, -8.14462476],
                            [39.3434342, -8.14405339],
                            [39.34407683, -8.14341062],
                            [39.3446838, -8.14287493],
                            [39.34486232, -8.14233926],
                            [39.34500515, -8.14173218],
                            [39.34518364, -8.14123222],
                            [39.34529075, -8.14069657],
                            [39.34521935, -8.14030373],
                            [39.34500516, -8.13991092],
                            [39.34461242, -8.13983949],
                            [39.34454102, -8.1395181],
                            [39.34471951, -8.13908955],
                            [39.34461243, -8.13855389],
                            [39.34411257, -8.13830392],
                            [39.34379124, -8.13851819],
                            [39.34364843, -8.138911],
                            [39.34364843, -8.13930382],
                            [39.34364843, -8.13973235],
                            [39.34386264, -8.1401966],
                            [39.34407685, -8.14055371],
                            [39.34393405, -8.14101795],
                            [39.3436127, -8.14137506],
                            [39.34329139, -8.1418036],
                            [39.34272014, -8.14226784],
                            [39.34200608, -8.14266066],
                            [39.34139911, -8.14298206],
                            [39.34068508, -8.14323202],
                            [39.33968537, -8.14330345],
                            [39.3386857, -8.14330345],
                            [39.33793594, -8.14323201],
                            [39.33740038, -8.14316059],
                            [39.33679344, -8.14287489],
                            [39.33540101, -8.1422678],
                            [39.33472266, -8.14191069],
                            [39.33408, -8.14166072],
                            [39.33379439, -8.14176783],
                            [39.33368728, -8.14205352],
                            [39.33368728, -8.14244635],
                            [39.33365158, -8.14287488],
                            [39.33350875, -8.14323199],
                            [39.33350875, -8.14351768],
                            [39.33375866, -8.1438748],
                            [39.33393718, -8.14419619],
                            [39.33372297, -8.14448188],
                            [39.33340164, -8.1448747],
                            [39.33308031, -8.14544609],
                            [39.33250906, -8.14598175],
                            [39.33204492, -8.14641028],
                            [39.33140226, -8.14669595],
                            [39.33093812, -8.14698164],
                            [39.33043828, -8.14744588],
                            [39.33036687, -8.14805298],
                            [39.33036687, -8.1484815],
                            [39.33072389, -8.14891003],
                            [39.33097383, -8.14930287],
                            [39.33097382, -8.14987424],
                            [39.330831, -8.15023136],
                            [39.33054539, -8.15051703],
                            [39.33022404, -8.15090987],
                            [39.3297599, -8.15123126],
                            [39.32940288, -8.15148122],
                            [39.32908155, -8.15180263],
                            [39.32890304, -8.15212403],
                            [39.32865311, -8.15251683],
                            [39.32836749, -8.15298109],
                            [39.32836062, -8.15298453],
                            [39.32828639, -8.15267271],
                            [39.32803084, -8.15245641],
                            [39.32789322, -8.15224011],
                            [39.3275787, -8.15190584],
                            [39.32734277, -8.15166989],
                            [39.32712653, -8.15137493],
                            [39.32710728, -8.15135844],
                            [39.32728379, -8.15104066],
                            [39.32763767, -8.15049009],
                            [39.32783426, -8.15013617],
                            [39.32803085, -8.14976255],
                            [39.32822743, -8.14952661],
                            [39.32846333, -8.14927098],
                            [39.32854197, -8.14905468],
                            [39.32854197, -8.14877939],
                            [39.3283847, -8.14862209],
                            [39.32807018, -8.14860242],
                            [39.32779494, -8.14866141],
                            [39.32755904, -8.14889738],
                            [39.32740178, -8.14927098],
                            [39.32736245, -8.1496839],
                            [39.32712654, -8.15013617],
                            [39.32702824, -8.15041144],
                            [39.32673336, -8.15066706],
                            [39.32643848, -8.150962],
                            [39.32622223, -8.15131593],
                            [39.326006, -8.15165021],
                            [39.32580939, -8.15196483],
                            [39.32555383, -8.15220078],
                            [39.32525895, -8.15243674],
                            [39.3249444, -8.15263337],
                            [39.32470851, -8.15281032],
                            [39.32441362, -8.15310527],
                            [39.32415805, -8.15345921],
                            [39.32388282, -8.15369517],
                            [39.32364693, -8.15391146],
                            [39.32337172, -8.15408843],
                            [39.32321442, -8.15440305],
                            [39.32305716, -8.15463899],
                            [39.3229392, -8.15493395],
                            [39.32286058, -8.15526823],
                            [39.32288021, -8.15554351],
                            [39.32291954, -8.15585812],
                            [39.32293919, -8.1561334],
                            [39.32293919, -8.15638903],
                            [39.32288021, -8.15668397],
                            [39.32288021, -8.15699858],
                            [39.32272295, -8.15735252],
                            [39.32254601, -8.15766712],
                            [39.32246739, -8.15792275],
                            [39.32229044, -8.15819803],
                            [39.32213318, -8.15845366],
                            [39.32207422, -8.15866995],
                            [39.32183829, -8.15882726],
                            [39.32164172, -8.15884692],
                            [39.32146479, -8.15902389],
                            [39.32128785, -8.15924018],
                            [39.32120923, -8.1594958],
                            [39.3210716, -8.15981042],
                            [39.32097332, -8.16006603],
                            [39.32081604, -8.160302],
                            [39.3207374, -8.16049863],
                            [39.32095364, -8.16073458],
                            [39.32107159, -8.16083289],
                            [39.32120923, -8.16093121],
                            [39.32132716, -8.16102952],
                            [39.32152374, -8.16118684],
                            [39.32168102, -8.16136381],
                            [39.32182321, -8.16149024],
                            [39.32201096, -8.16136818],
                            [39.32239923, -8.16092435],
                            [39.32284299, -8.16048051],
                            [39.32312034, -8.15987021],
                            [39.32345314, -8.1593709],
                            [39.32370276, -8.15891666],
                            [39.32382674, -8.15861901],
                            [39.32385874, -8.15854215],
                            [39.32413956, -8.15826129],
                            [39.32448275, -8.15813645],
                            [39.32476358, -8.15798041],
                            [39.32520039, -8.15785559],
                            [39.3256372, -8.15763713],
                            [39.32604278, -8.1574499],
                            [39.32612656, -8.15741079],
                            [39.32620256, -8.15747052],
                            [39.32622032, -8.1574959],
                            [39.32634015, -8.15766714],
                            [39.3265564, -8.15780479],
                            [39.32663505, -8.15792277],
                            [39.32681195, -8.15796209],
                            [39.32700856, -8.15796209],
                            [39.3272248, -8.15788345],
                            [39.32746071, -8.15798177],
                            [39.32757867, -8.15804076],
                            [39.32769661, -8.1581194],
                            [39.32783422, -8.15829637],
                            [39.32798662, -8.15841493],
                            [39.32781352, -8.15855345],
                            [39.32712292, -8.15871596],
                            [39.32622924, -8.15912227],
                            [39.32602612, -8.15944733],
                            [39.32598551, -8.15973174],
                            [39.32602612, -8.16005679],
                            [39.32574177, -8.16009742],
                            [39.32545742, -8.16034121],
                            [39.32561991, -8.16066627],
                            [39.32578239, -8.16082879],
                            [39.32594488, -8.1611132],
                            [39.32590424, -8.16131636],
                            [39.32574176, -8.16160077],
                            [39.32590424, -8.16188519],
                            [39.32626986, -8.16192584],
                            [39.3265542, -8.16204772],
                            [39.3267167, -8.1624134],
                            [39.32659482, -8.16290097],
                            [39.32626986, -8.16322605],
                            [39.32606674, -8.16351044],
                            [39.3260261, -8.16387612],
                            [39.3260261, -8.16436369],
                            [39.3260261, -8.1648919],
                            [39.32590422, -8.1656639],
                            [39.32566049, -8.16672032],
                            [39.32529489, -8.16777673],
                            [39.32509179, -8.16858935],
                            [39.32488868, -8.16940197],
                            [39.32460432, -8.17005207],
                            [39.32415747, -8.17021459],
                            [39.32358876, -8.17041775],
                            [39.32302003, -8.17041774],
                            [39.32261383, -8.17074279],
                            [39.32212636, -8.17110848],
                            [39.32135453, -8.17147415],
                            [39.32082644, -8.17159605],
                            [39.32066396, -8.17183983],
                            [39.32074519, -8.17216489],
                            [39.32078583, -8.17265245],
                            [39.32090768, -8.17297751],
                            [39.321517, -8.17318067],
                            [39.32196388, -8.17334319],
                            [39.32216698, -8.17366823],
                            [39.32212635, -8.17403393],
                            [39.32184199, -8.17431834],
                        ],
                        [
                            [39.31361214, -8.16380551],
                            [39.31386693, -8.16371283],
                            [39.31406381, -8.16370125],
                            [39.31433017, -8.16370126],
                            [39.31465444, -8.16370126],
                            [39.31505978, -8.16377077],
                            [39.31552303, -8.16378237],
                            [39.3159052, -8.16385185],
                            [39.31613685, -8.16384027],
                            [39.31638005, -8.16373602],
                            [39.31663483, -8.16356225],
                            [39.31685489, -8.16334217],
                            [39.31708651, -8.16290198],
                            [39.317156, -8.16267029],
                            [39.31712126, -8.16247338],
                            [39.31699386, -8.16228804],
                            [39.31683172, -8.16204479],
                            [39.31664642, -8.16169728],
                            [39.31650746, -8.16143083],
                            [39.31642638, -8.16111807],
                            [39.31636848, -8.16078214],
                            [39.31634533, -8.16039987],
                            [39.31633374, -8.15998285],
                            [39.31634533, -8.15954266],
                            [39.31638007, -8.15906773],
                            [39.31646114, -8.1586623],
                            [39.31657695, -8.15815261],
                            [39.31668118, -8.15771242],
                            [39.31672751, -8.15745757],
                            [39.31662328, -8.15728382],
                            [39.31646115, -8.15716797],
                            [39.31621794, -8.15719115],
                            [39.31598632, -8.15731856],
                            [39.31584734, -8.15749233],
                            [39.31562729, -8.15781667],
                            [39.31548832, -8.15814102],
                            [39.31531459, -8.15853486],
                            [39.31515246, -8.15883604],
                            [39.31497875, -8.15910248],
                            [39.31483977, -8.15913722],
                            [39.31472396, -8.1590793],
                            [39.31468921, -8.15890555],
                            [39.31467763, -8.15868545],
                            [39.31473554, -8.15836111],
                            [39.31474712, -8.15799043],
                            [39.31471237, -8.15782824],
                            [39.31455024, -8.15781666],
                            [39.31439968, -8.15789776],
                            [39.31430701, -8.15807151],
                            [39.31413331, -8.15829161],
                            [39.31394801, -8.15852328],
                            [39.3137743, -8.15856961],
                            [39.3137048, -8.15841902],
                            [39.31376272, -8.15819893],
                            [39.31391328, -8.15782824],
                            [39.31402909, -8.15752708],
                            [39.31419121, -8.15716796],
                            [39.31439969, -8.15676253],
                            [39.31461974, -8.15628758],
                            [39.31483978, -8.15582425],
                            [39.31504823, -8.15548831],
                            [39.31518722, -8.15519873],
                            [39.31525671, -8.15497862],
                            [39.31527988, -8.15457319],
                            [39.31524513, -8.15431833],
                            [39.3151409, -8.15408668],
                            [39.31505983, -8.15398242],
                            [39.31493243, -8.15394767],
                            [39.31475871, -8.15395924],
                            [39.31460816, -8.15401716],
                            [39.3143997, -8.15416775],
                            [39.31400595, -8.15441101],
                            [39.31368166, -8.1546427],
                            [39.31342687, -8.15478169],
                            [39.31326474, -8.15502495],
                            [39.31320682, -8.15524505],
                            [39.31320682, -8.1554999],
                            [39.3131605, -8.15592849],
                            [39.31314892, -8.15625284],
                            [39.31311418, -8.15651928],
                            [39.3131026, -8.15679728],
                            [39.31310259, -8.15714478],
                            [39.31305627, -8.15751549],
                            [39.31297519, -8.1577935],
                            [39.3129636, -8.15809468],
                            [39.31290571, -8.15844219],
                            [39.31283619, -8.15882445],
                            [39.31267407, -8.15933414],
                            [39.31259299, -8.15968166],
                            [39.31254668, -8.15994808],
                            [39.31246559, -8.1602261],
                            [39.31225163, -8.16082199],
                            [39.31221082, -8.16078213],
                            [39.31194445, -8.16111805],
                            [39.31153911, -8.16148874],
                            [39.31116851, -8.16177832],
                            [39.31087896, -8.1620795],
                            [39.31076316, -8.16235752],
                            [39.31078632, -8.16257761],
                            [39.31093687, -8.16278612],
                            [39.31115691, -8.16297147],
                            [39.31138854, -8.16315678],
                            [39.31173596, -8.16345798],
                            [39.31208342, -8.16385184],
                            [39.31238453, -8.16412986],
                            [39.31255824, -8.16424569],
                            [39.31285935, -8.16423411],
                            [39.31310256, -8.16423411],
                            [39.31327628, -8.16421094],
                            [39.31333416, -8.16412986],
                            [39.31340368, -8.1639561],
                            [39.31361214, -8.16380551],
                        ],
                        [
                            [39.31167555, -8.15610968],
                            [39.31177098, -8.15580743],
                            [39.31177098, -8.15558472],
                            [39.31172325, -8.15537792],
                            [39.31161194, -8.15525066],
                            [39.31140519, -8.15517111],
                            [39.31123024, -8.15531428],
                            [39.31100758, -8.15556881],
                            [39.31080082, -8.15585516],
                            [39.31053044, -8.15591878],
                            [39.310435, -8.15571197],
                            [39.31043501, -8.15526653],
                            [39.31043501, -8.15486885],
                            [39.31048273, -8.15450297],
                            [39.31059407, -8.15416891],
                            [39.31060997, -8.15394619],
                            [39.31041912, -8.15394619],
                            [39.31013284, -8.15394619],
                            [39.30971932, -8.15383482],
                            [39.30928989, -8.15364393],
                            [39.30889229, -8.15343712],
                            [39.30855831, -8.1531985],
                            [39.3082561, -8.15291216],
                            [39.30809708, -8.15270536],
                            [39.30787442, -8.15224402],
                            [39.30777899, -8.15184632],
                            [39.30757223, -8.15128956],
                            [39.30731777, -8.15052597],
                            [39.30723825, -8.15003281],
                            [39.30714283, -8.14985782],
                            [39.30698378, -8.14990557],
                            [39.3066816, -8.15019188],
                            [39.30647484, -8.15057367],
                            [39.30625217, -8.15097139],
                            [39.30609313, -8.15136909],
                            [39.30599768, -8.15176678],
                            [39.30599768, -8.15205312],
                            [39.30614083, -8.15218039],
                            [39.30626807, -8.15232356],
                            [39.30644302, -8.152594],
                            [39.30660206, -8.15280081],
                            [39.30679291, -8.1528167],
                            [39.30701557, -8.15276899],
                            [39.30720641, -8.15276899],
                            [39.30731776, -8.15286443],
                            [39.30733367, -8.15300761],
                            [39.30733367, -8.15332577],
                            [39.30719052, -8.15354849],
                            [39.30703146, -8.15377119],
                            [39.30707919, -8.15394618],
                            [39.3072064, -8.15410525],
                            [39.30757222, -8.15426433],
                            [39.30798573, -8.15439161],
                            [39.30831973, -8.15459841],
                            [39.30851058, -8.15482113],
                            [39.3085583, -8.15499611],
                            [39.30854239, -8.15539381],
                            [39.3086219, -8.15601422],
                            [39.30865371, -8.15642783],
                            [39.30879685, -8.1567619],
                            [39.30906722, -8.15703234],
                            [39.30936941, -8.1571596],
                            [39.3097034, -8.1571596],
                            [39.31000559, -8.15714369],
                            [39.31027596, -8.15712778],
                            [39.31041911, -8.15692098],
                            [39.31056224, -8.15671418],
                            [39.31064176, -8.15652328],
                            [39.31083262, -8.15652328],
                            [39.31108708, -8.15657101],
                            [39.31134156, -8.15657101],
                            [39.3115006, -8.15644375],
                            [39.31167555, -8.15610968],
                        ],
                        [
                            [39.30986247, -8.14993738],
                            [39.30987838, -8.15017599],
                            [39.30997381, -8.15036688],
                            [39.31006923, -8.1505578],
                            [39.3103078, -8.15073278],
                            [39.31051455, -8.1509873],
                            [39.31080084, -8.15111456],
                            [39.3110553, -8.15119411],
                            [39.3113893, -8.1513532],
                            [39.31165966, -8.1513532],
                            [39.31196185, -8.1513532],
                            [39.31218452, -8.15116231],
                            [39.31242307, -8.15076461],
                            [39.31266166, -8.150351],
                            [39.31288431, -8.14982603],
                            [39.31301155, -8.14944424],
                            [39.31283661, -8.14941242],
                            [39.31251851, -8.1495397],
                            [39.31226405, -8.14969877],
                            [39.31188232, -8.14981012],
                            [39.31145291, -8.14995328],
                            [39.31110302, -8.15011237],
                            [39.31083265, -8.15011237],
                            [39.31040323, -8.15004873],
                            [39.31006923, -8.14990558],
                            [39.30986247, -8.14993738],
                        ],
                        [
                            [39.34680212, -8.15296931],
                            [39.34670986, -8.1531723],
                            [39.34670986, -8.1534122],
                            [39.34693125, -8.15359674],
                            [39.3472449, -8.15376283],
                            [39.34757701, -8.15389202],
                            [39.34781683, -8.15409501],
                            [39.34781682, -8.15435335],
                            [39.34765078, -8.15446408],
                            [39.34746629, -8.15451944],
                            [39.34722644, -8.15461171],
                            [39.34704195, -8.15475933],
                            [39.34682057, -8.15475933],
                            [39.3466914, -8.1545748],
                            [39.34661762, -8.15440872],
                            [39.34656225, -8.15424262],
                            [39.34647, -8.15398428],
                            [39.34626706, -8.15372593],
                            [39.34604568, -8.15346757],
                            [39.34587962, -8.15331993],
                            [39.34573202, -8.15319075],
                            [39.34549219, -8.15322766],
                            [39.34539992, -8.15335684],
                            [39.34547375, -8.15355984],
                            [39.34547375, -8.15376283],
                            [39.34547375, -8.1539289],
                            [39.34543683, -8.15418726],
                            [39.34551062, -8.15439026],
                            [39.34562133, -8.15468553],
                            [39.34571356, -8.15494386],
                            [39.34578737, -8.15522068],
                            [39.34552907, -8.15527601],
                            [39.34528925, -8.15512841],
                            [39.34503093, -8.15501768],
                            [39.34475419, -8.15479623],
                            [39.34455125, -8.15455633],
                            [39.34440365, -8.154298],
                            [39.34423761, -8.15409499],
                            [39.34401621, -8.15411345],
                            [39.34390551, -8.15427953],
                            [39.34385015, -8.15450097],
                            [39.34366566, -8.15457478],
                            [39.34351807, -8.15442714],
                            [39.34329668, -8.15433488],
                            [39.34311217, -8.15437179],
                            [39.34301992, -8.15453787],
                            [39.34296459, -8.15474086],
                            [39.34296459, -8.15507304],
                            [39.34305682, -8.155276],
                            [39.34338892, -8.15544211],
                            [39.34361031, -8.15562665],
                            [39.34405309, -8.15573737],
                            [39.34429294, -8.15590345],
                            [39.34462504, -8.15610645],
                            [39.34490179, -8.1564017],
                            [39.34503093, -8.15660473],
                            [39.34503092, -8.1568077],
                            [39.34484645, -8.15686306],
                            [39.34462503, -8.15699225],
                            [39.34444054, -8.15723214],
                            [39.34434829, -8.15749048],
                            [39.34442209, -8.15763812],
                            [39.34451435, -8.15787801],
                            [39.34479107, -8.15811791],
                            [39.34493867, -8.15837627],
                            [39.34521541, -8.1587638],
                            [39.34521541, -8.1590037],
                            [39.34517852, -8.15928051],
                            [39.34517852, -8.15950194],
                            [39.34530765, -8.15964958],
                            [39.34552905, -8.15970495],
                            [39.3457689, -8.15970495],
                            [39.34597184, -8.15953885],
                            [39.34597185, -8.15926205],
                            [39.34600875, -8.15898525],
                            [39.34617479, -8.15870844],
                            [39.34635929, -8.15837628],
                            [39.34639619, -8.15808101],
                            [39.3462855, -8.15776731],
                            [39.34604566, -8.15745358],
                            [39.34586117, -8.15721369],
                            [39.34578736, -8.15691843],
                            [39.34578736, -8.15658626],
                            [39.34600876, -8.1565678],
                            [39.34611946, -8.15669698],
                            [39.34619327, -8.15693687],
                            [39.34637774, -8.15730596],
                            [39.34652535, -8.15760122],
                            [39.34674674, -8.15789647],
                            [39.34707882, -8.15826556],
                            [39.34730022, -8.15843164],
                            [39.34755852, -8.15852391],
                            [39.34779837, -8.15846855],
                            [39.34813047, -8.15846856],
                            [39.34844409, -8.15870845],
                            [39.34849947, -8.15891144],
                            [39.3485179, -8.15918825],
                            [39.34872086, -8.15922515],
                            [39.34914519, -8.15922515],
                            [39.34944038, -8.15904062],
                            [39.34999386, -8.15887455],
                            [39.35034441, -8.15870845],
                            [39.35071339, -8.15850546],
                            [39.35110085, -8.15830248],
                            [39.35141449, -8.15815484],
                            [39.3517835, -8.15798876],
                            [39.3521525, -8.15780423],
                            [39.35265062, -8.15754589],
                            [39.35301961, -8.15726907],
                            [39.35338861, -8.15702917],
                            [39.35384984, -8.15690001],
                            [39.35421884, -8.15677083],
                            [39.35464319, -8.15656784],
                            [39.355086, -8.15630948],
                            [39.35560257, -8.15588505],
                            [39.35600848, -8.15555289],
                            [39.3565066, -8.15523918],
                            [39.35713389, -8.15490701],
                            [39.35761359, -8.15466711],
                            [39.35809328, -8.15433494],
                            [39.35857298, -8.15400279],
                            [39.35894198, -8.15372599],
                            [39.35938475, -8.15344917],
                            [39.35980909, -8.15326464],
                            [39.36014119, -8.15304319],
                            [39.36030724, -8.15280329],
                            [39.36030724, -8.15261876],
                            [39.36012274, -8.15256339],
                            [39.35980909, -8.1526003],
                            [39.35947701, -8.15271102],
                            [39.35914493, -8.1528771],
                            [39.35896042, -8.15298782],
                            [39.35857298, -8.15304319],
                            [39.35816709, -8.15322772],
                            [39.35772428, -8.15354143],
                            [39.35724459, -8.15379978],
                            [39.35676489, -8.15407659],
                            [39.35641436, -8.15426112],
                            [39.35602692, -8.15451948],
                            [39.35552877, -8.15463019],
                            [39.35519667, -8.15487009],
                            [39.35477234, -8.15509154],
                            [39.35451406, -8.15525761],
                            [39.35445871, -8.15507309],
                            [39.35431111, -8.15488854],
                            [39.35407125, -8.15472246],
                            [39.35381296, -8.15466709],
                            [39.35381296, -8.15442719],
                            [39.35410815, -8.15424266],
                            [39.35436644, -8.15400277],
                            [39.3543849, -8.15368905],
                            [39.35423731, -8.15339378],
                            [39.35396057, -8.15339378],
                            [39.35372072, -8.1534676],
                            [39.35359157, -8.15361523],
                            [39.35333327, -8.15370749],
                            [39.35309343, -8.15383667],
                            [39.35281667, -8.1540212],
                            [39.35255839, -8.15422419],
                            [39.35233699, -8.1544641],
                            [39.35211559, -8.15466709],
                            [39.35189419, -8.15487008],
                            [39.35169125, -8.15509152],
                            [39.3514883, -8.15531297],
                            [39.35126692, -8.15553442],
                            [39.35104552, -8.15575586],
                            [39.35075031, -8.15582967],
                            [39.35038133, -8.15597731],
                            [39.34995698, -8.15605111],
                            [39.34953264, -8.15605111],
                            [39.349219, -8.15605111],
                            [39.34896071, -8.15595884],
                            [39.34861016, -8.15573739],
                            [39.34827807, -8.1555713],
                            [39.34800133, -8.15540522],
                            [39.3477615, -8.15520223],
                            [39.34779839, -8.15499924],
                            [39.34814893, -8.15492543],
                            [39.34857326, -8.15492543],
                            [39.34890536, -8.15485161],
                            [39.34923745, -8.15474088],
                            [39.34968023, -8.15466708],
                            [39.34997544, -8.15457481],
                            [39.35038133, -8.1545379],
                            [39.35075032, -8.15439028],
                            [39.35128536, -8.15411348],
                            [39.3517097, -8.1540212],
                            [39.35198644, -8.15383667],
                            [39.35224475, -8.15365213],
                            [39.35246614, -8.15344914],
                            [39.35257686, -8.15319078],
                            [39.35257686, -8.1530247],
                            [39.35244769, -8.15282171],
                            [39.35224475, -8.15272945],
                            [39.35202337, -8.15272944],
                            [39.35182042, -8.15276636],
                            [39.35163593, -8.15285861],
                            [39.35146986, -8.15308005],
                            [39.35124847, -8.1533015],
                            [39.35104553, -8.15344914],
                            [39.35084257, -8.15352295],
                            [39.35065809, -8.15365213],
                            [39.35043669, -8.15367058],
                            [39.35027065, -8.15359675],
                            [39.35027065, -8.15331995],
                            [39.35034443, -8.15309851],
                            [39.35032598, -8.15287706],
                            [39.35012304, -8.15278479],
                            [39.34984631, -8.15271098],
                            [39.34960645, -8.15265561],
                            [39.34934816, -8.15263717],
                            [39.34918211, -8.15265561],
                            [39.34897916, -8.1526556],
                            [39.34870244, -8.1526556],
                            [39.34837034, -8.1526556],
                            [39.3481674, -8.15269251],
                            [39.3479829, -8.15282169],
                            [39.34785374, -8.15300623],
                            [39.34759545, -8.15302468],
                            [39.3473925, -8.15302468],
                            [39.34720801, -8.15300622],
                            [39.34704196, -8.15295089],
                            [39.34680212, -8.15296931],
                        ],
                        [
                            [39.35708766, -8.15828628],
                            [39.35679464, -8.15845995],
                            [39.35644739, -8.15869876],
                            [39.35590478, -8.15894841],
                            [39.35548153, -8.15919805],
                            [39.35534046, -8.15929574],
                            [39.35506917, -8.15935003],
                            [39.35474361, -8.15946941],
                            [39.35429867, -8.15948027],
                            [39.3538863, -8.15941515],
                            [39.35351733, -8.1593283],
                            [39.35328941, -8.15933916],
                            [39.35306152, -8.15936085],
                            [39.35286618, -8.15945856],
                            [39.35282278, -8.15954539],
                            [39.35292044, -8.15967564],
                            [39.35312664, -8.15979504],
                            [39.35332197, -8.15994701],
                            [39.35335453, -8.16019667],
                            [39.35336538, -8.16041375],
                            [39.35350646, -8.16053315],
                            [39.35369094, -8.16054401],
                            [39.35388629, -8.16047889],
                            [39.35407075, -8.16038119],
                            [39.35440718, -8.16030521],
                            [39.35470018, -8.16022922],
                            [39.35502575, -8.1601641],
                            [39.35527535, -8.16012069],
                            [39.35544898, -8.15999043],
                            [39.35567688, -8.1598819],
                            [39.35598074, -8.1597408],
                            [39.35619778, -8.15965395],
                            [39.35643652, -8.1595454],
                            [39.35677295, -8.15942602],
                            [39.35747833, -8.15929575],
                            [39.35798837, -8.1592632],
                            [39.35845501, -8.15911124],
                            [39.3588674, -8.15900269],
                            [39.35922551, -8.15891586],
                            [39.35950767, -8.15870962],
                            [39.35968129, -8.15848169],
                            [39.35972471, -8.15835141],
                            [39.35974643, -8.15821031],
                            [39.35974643, -8.15808007],
                            [39.35981154, -8.15789553],
                            [39.35987664, -8.15776527],
                            [39.36007198, -8.15755903],
                            [39.36029988, -8.15736365],
                            [39.36050608, -8.15701631],
                            [39.36054948, -8.15683179],
                            [39.36048438, -8.15662555],
                            [39.36036501, -8.15654957],
                            [39.36014795, -8.1566147],
                            [39.35988751, -8.15677753],
                            [39.35969215, -8.15703801],
                            [39.35922552, -8.15741792],
                            [39.35895423, -8.15765672],
                            [39.3585961, -8.15779782],
                            [39.35810776, -8.15779782],
                            [39.35783646, -8.15778699],
                            [39.357576, -8.15785211],
                            [39.3573264, -8.15801492],
                            [39.35708766, -8.15828628],
                        ],
                        [
                            [39.34212982, -8.16071998],
                            [39.34206265, -8.16102233],
                            [39.34201227, -8.16130788],
                            [39.34192831, -8.16164382],
                            [39.34176037, -8.16206376],
                            [39.34157565, -8.16239969],
                            [39.34139092, -8.16265165],
                            [39.34129016, -8.1629204],
                            [39.3411726, -8.16322275],
                            [39.34097107, -8.16355867],
                            [39.34076955, -8.16391141],
                            [39.34050087, -8.16431455],
                            [39.34024895, -8.16466728],
                            [39.33998026, -8.16490243],
                            [39.33979553, -8.16517119],
                            [39.3396276, -8.16549034],
                            [39.33944289, -8.16579267],
                            [39.33939251, -8.16606144],
                            [39.33940929, -8.1663134],
                            [39.33952685, -8.16653175],
                            [39.33976193, -8.16653175],
                            [39.34003064, -8.16621261],
                            [39.3403833, -8.16596066],
                            [39.34070237, -8.16559113],
                            [39.34103823, -8.16518799],
                            [39.34142449, -8.16478487],
                            [39.34172677, -8.16448252],
                            [39.34194509, -8.16431455],
                            [39.3421466, -8.16419697],
                            [39.34218019, -8.16391142],
                            [39.3421634, -8.16359228],
                            [39.34202906, -8.16328994],
                            [39.34204584, -8.16300439],
                            [39.34211303, -8.16268525],
                            [39.34239852, -8.16243328],
                            [39.34271759, -8.16214775],
                            [39.34291911, -8.16219813],
                            [39.34305346, -8.16248369],
                            [39.3431878, -8.16228212],
                            [39.34337253, -8.16201337],
                            [39.34360763, -8.16176141],
                            [39.34394348, -8.16149266],
                            [39.34422898, -8.16132469],
                            [39.34448088, -8.16115671],
                            [39.34456485, -8.16087117],
                            [39.34456485, -8.16070319],
                            [39.34454805, -8.16045124],
                            [39.34427936, -8.16031687],
                            [39.34401067, -8.16026647],
                            [39.34374199, -8.16024966],
                            [39.34342291, -8.16024966],
                            [39.34313742, -8.16024966],
                            [39.34280156, -8.16028326],
                            [39.3424657, -8.16035044],
                            [39.34224738, -8.16035044],
                            [39.34212982, -8.16046803],
                            [39.34212982, -8.16071998],
                        ],
                        [
                            [39.35677229, -8.16391198],
                            [39.35678372, -8.16417515],
                            [39.35689811, -8.16439254],
                            [39.35719555, -8.16455273],
                            [39.35751582, -8.16446119],
                            [39.35780181, -8.16427811],
                            [39.35801916, -8.16411793],
                            [39.35824793, -8.16416372],
                            [39.358328, -8.16441542],
                            [39.35868261, -8.16455273],
                            [39.35884276, -8.16463283],
                            [39.35883134, -8.16483877],
                            [39.35879701, -8.16506761],
                            [39.35873981, -8.16526213],
                            [39.35872837, -8.16557105],
                            [39.35878557, -8.16582275],
                            [39.35900291, -8.16599438],
                            [39.35941472, -8.16599438],
                            [39.35970069, -8.16568546],
                            [39.35997525, -8.16520491],
                            [39.3601354, -8.16475868],
                            [39.36018115, -8.16442687],
                            [39.36014684, -8.16425526],
                            [39.35998669, -8.16409507],
                            [39.35974647, -8.16393489],
                            [39.3594948, -8.16376326],
                            [39.35950624, -8.16348865],
                            [39.3595177, -8.16313396],
                            [39.35942617, -8.16292801],
                            [39.35924315, -8.16275639],
                            [39.35896861, -8.1625962],
                            [39.35880846, -8.16263052],
                            [39.35857969, -8.16248177],
                            [39.35841954, -8.16233304],
                            [39.35827083, -8.16235592],
                            [39.35811068, -8.16243601],
                            [39.35788189, -8.1624589],
                            [39.35756161, -8.16247034],
                            [39.35732136, -8.16259619],
                            [39.35710403, -8.16275638],
                            [39.35698963, -8.16299665],
                            [39.356921, -8.16323693],
                            [39.35678373, -8.16360306],
                            [39.35677229, -8.16391198],
                        ],
                        [
                            [39.34933411, -8.16387786],
                            [39.3493845, -8.16357551],
                            [39.3493845, -8.16337394],
                            [39.34931732, -8.1630884],
                            [39.34919978, -8.16295403],
                            [39.34908222, -8.16283645],
                            [39.34891429, -8.16283645],
                            [39.34872956, -8.16292043],
                            [39.34857843, -8.1630716],
                            [39.3484105, -8.16327317],
                            [39.34827614, -8.16347472],
                            [39.34815858, -8.16364269],
                            [39.34799067, -8.16387786],
                            [39.34778914, -8.16412981],
                            [39.34760441, -8.16436496],
                            [39.3474197, -8.16460013],
                            [39.34721818, -8.16485208],
                            [39.34699986, -8.16498645],
                            [39.3468655, -8.16491926],
                            [39.34671437, -8.16481848],
                            [39.3466304, -8.16475129],
                            [39.3464121, -8.16475129],
                            [39.34621058, -8.16486888],
                            [39.34605943, -8.16507043],
                            [39.34580753, -8.16543997],
                            [39.34572356, -8.16572552],
                            [39.34527014, -8.16607825],
                            [39.34490068, -8.16651499],
                            [39.34466558, -8.16681733],
                            [39.34443047, -8.16703569],
                            [39.34441368, -8.16728765],
                            [39.34436329, -8.16790912],
                            [39.34461519, -8.16777475],
                            [39.34490068, -8.16767397],
                            [39.34528693, -8.1675228],
                            [39.34574035, -8.16727085],
                            [39.34621057, -8.16706929],
                            [39.34664719, -8.16690132],
                            [39.34703344, -8.16675015],
                            [39.3474029, -8.16666617],
                            [39.34772196, -8.16641422],
                            [39.34790669, -8.16612866],
                            [39.34792349, -8.16584311],
                            [39.34804103, -8.16549038],
                            [39.34805783, -8.16518802],
                            [39.34820897, -8.16496967],
                            [39.34859522, -8.16491927],
                            [39.34881352, -8.16491927],
                            [39.34906542, -8.16480169],
                            [39.34921657, -8.16456654],
                            [39.34928374, -8.1642138],
                            [39.34933411, -8.16387786],
                        ],
                        [
                            [39.33812929, -8.16466563],
                            [39.33806274, -8.16447922],
                            [39.33803612, -8.16430614],
                            [39.33798286, -8.16411975],
                            [39.33788969, -8.16395998],
                            [39.3377699, -8.16389341],
                            [39.33750368, -8.16390672],
                            [39.3374238, -8.16403986],
                            [39.3372907, -8.16410643],
                            [39.33711766, -8.16414637],
                            [39.33690467, -8.164173],
                            [39.33670502, -8.164173],
                            [39.33658521, -8.16411974],
                            [39.33651867, -8.16402654],
                            [39.33642548, -8.16392003],
                            [39.33627907, -8.16390672],
                            [39.33615926, -8.16393335],
                            [39.33603945, -8.16397328],
                            [39.33594628, -8.16409311],
                            [39.33590635, -8.16423956],
                            [39.33586643, -8.16447921],
                            [39.33587972, -8.16470555],
                            [39.3358531, -8.16494521],
                            [39.33595959, -8.16519817],
                            [39.33610601, -8.16537127],
                            [39.33635892, -8.16561091],
                            [39.33659852, -8.1657973],
                            [39.33678487, -8.16585056],
                            [39.33699784, -8.16585057],
                            [39.33729069, -8.16585057],
                            [39.33750367, -8.16585057],
                            [39.33765008, -8.16575736],
                            [39.33779652, -8.16562423],
                            [39.33788968, -8.16547778],
                            [39.33798285, -8.16535795],
                            [39.33794293, -8.16523811],
                            [39.33786306, -8.16515824],
                            [39.33778321, -8.16502509],
                            [39.33782314, -8.16490527],
                            [39.33792961, -8.16487865],
                            [39.33804942, -8.16494521],
                            [39.33816922, -8.1649319],
                            [39.33820916, -8.1648254],
                            [39.33812929, -8.16466563],
                        ],
                        [
                            [39.33528073, -8.16481205],
                            [39.33526742, -8.16465229],
                            [39.33516094, -8.16443926],
                            [39.33510769, -8.16422624],
                            [39.33506776, -8.16403985],
                            [39.33506776, -8.16376025],
                            [39.33501451, -8.16362711],
                            [39.33492134, -8.16337414],
                            [39.33489472, -8.16312119],
                            [39.33480154, -8.16286821],
                            [39.33480154, -8.16273507],
                            [39.33470836, -8.16253536],
                            [39.33457378, -8.16236967],
                            [39.33452644, -8.16222766],
                            [39.33442884, -8.16212261],
                            [39.33433565, -8.16198948],
                            [39.33421585, -8.16190959],
                            [39.33409605, -8.16190959],
                            [39.33397625, -8.16190959],
                            [39.33386977, -8.16192292],
                            [39.33374997, -8.16200279],
                            [39.33369673, -8.16208268],
                            [39.33365679, -8.16216257],
                            [39.33360354, -8.16228237],
                            [39.3335636, -8.16245547],
                            [39.33355029, -8.1625753],
                            [39.33351035, -8.16274838],
                            [39.33348375, -8.16292146],
                            [39.33340389, -8.16314781],
                            [39.33327075, -8.16332088],
                            [39.33317758, -8.1635206],
                            [39.3330844, -8.16370699],
                            [39.33296461, -8.1638135],
                            [39.33285811, -8.16393333],
                            [39.33281819, -8.16406647],
                            [39.33284481, -8.16422623],
                            [39.33291137, -8.16435938],
                            [39.33309772, -8.16443926],
                            [39.33321752, -8.16441264],
                            [39.33336393, -8.16441264],
                            [39.33348374, -8.16435938],
                            [39.33360353, -8.16435938],
                            [39.33372334, -8.16439931],
                            [39.33374996, -8.16453245],
                            [39.33378988, -8.16466561],
                            [39.33378988, -8.16477212],
                            [39.33378988, -8.16491857],
                            [39.33382983, -8.16506503],
                            [39.33388306, -8.16527806],
                            [39.33401618, -8.16541119],
                            [39.33417591, -8.16551769],
                            [39.33430901, -8.1656109],
                            [39.33445545, -8.16565085],
                            [39.33462847, -8.16565085],
                            [39.33478823, -8.16559759],
                            [39.33485477, -8.16547777],
                            [39.33486809, -8.16535793],
                            [39.33486809, -8.1652381],
                            [39.33496126, -8.16521147],
                            [39.33509437, -8.16526473],
                            [39.33524079, -8.16518486],
                            [39.33528073, -8.16501178],
                            [39.33528073, -8.16481205],
                        ],
                        [
                            [39.33679399, -8.15822205],
                            [39.33700435, -8.15803593],
                            [39.33729559, -8.15777697],
                            [39.33748977, -8.15756659],
                            [39.33765967, -8.15729954],
                            [39.33778102, -8.15694348],
                            [39.33782148, -8.15672498],
                            [39.33787004, -8.15653887],
                            [39.33796711, -8.15632847],
                            [39.33798328, -8.15615044],
                            [39.33798329, -8.15599669],
                            [39.3379024, -8.15590769],
                            [39.33776486, -8.15589149],
                            [39.33760305, -8.15591577],
                            [39.33742504, -8.15607761],
                            [39.3372228, -8.15623947],
                            [39.33703673, -8.15649031],
                            [39.33691536, -8.15663598],
                            [39.33677783, -8.15691111],
                            [39.33656746, -8.15719435],
                            [39.33631667, -8.1574533],
                            [39.33600922, -8.15779316],
                            [39.33567751, -8.15804403],
                            [39.33539435, -8.15828679],
                            [39.33520018, -8.15844054],
                            [39.33507073, -8.1586024],
                            [39.33501409, -8.15876423],
                            [39.33501409, -8.15894226],
                            [39.33504645, -8.15912837],
                            [39.3351759, -8.15920121],
                            [39.33532963, -8.15921739],
                            [39.33549953, -8.15924976],
                            [39.33559662, -8.15934687],
                            [39.33569369, -8.15943588],
                            [39.33575842, -8.15958154],
                            [39.33577459, -8.15979195],
                            [39.33578268, -8.15988095],
                            [39.33583932, -8.16002662],
                            [39.33598494, -8.160059],
                            [39.33619529, -8.16002662],
                            [39.33639755, -8.15984859],
                            [39.33655937, -8.15965438],
                            [39.33664837, -8.15934687],
                            [39.3366969, -8.15896655],
                            [39.33671309, -8.15866712],
                            [39.33675354, -8.15836771],
                            [39.33679399, -8.15822205],
                        ],
                        [
                            [39.33455242, -8.17414656],
                            [39.33473897, -8.17428651],
                            [39.33489444, -8.17439538],
                            [39.33486334, -8.17462862],
                            [39.33472343, -8.17484632],
                            [39.33466124, -8.17503293],
                            [39.33475452, -8.17507958],
                            [39.33487889, -8.17514178],
                            [39.33508099, -8.17521953],
                            [39.33534529, -8.17523508],
                            [39.33553185, -8.17503294],
                            [39.33567176, -8.17481524],
                            [39.33584279, -8.17492407],
                            [39.33587388, -8.17514179],
                            [39.33604489, -8.17518844],
                            [39.33629363, -8.17521954],
                            [39.33643356, -8.17515734],
                            [39.33671339, -8.17506404],
                            [39.33702433, -8.17484633],
                            [39.33739745, -8.17445759],
                            [39.33763063, -8.17409993],
                            [39.33789494, -8.17380448],
                            [39.33803486, -8.17355567],
                            [39.33825251, -8.17327578],
                            [39.33854791, -8.17298032],
                            [39.33884329, -8.17276262],
                            [39.33890269, -8.17268092],
                            [39.33888183, -8.1726576],
                            [39.3390765, -8.17260713],
                            [39.33924751, -8.172405],
                            [39.33930969, -8.17215617],
                            [39.33938743, -8.17187627],
                            [39.33935633, -8.17184517],
                            [39.33934079, -8.17161191],
                            [39.33926306, -8.17145641],
                            [39.3391076, -8.17137866],
                            [39.33898321, -8.17150306],
                            [39.33871892, -8.17156526],
                            [39.33845464, -8.17153416],
                            [39.33836136, -8.17133201],
                            [39.33805042, -8.17119206],
                            [39.33791049, -8.171021],
                            [39.33783277, -8.1708344],
                            [39.33781721, -8.17063225],
                            [39.33777057, -8.17030569],
                            [39.33761511, -8.170088],
                            [39.33745965, -8.17001025],
                            [39.33731973, -8.17001025],
                            [39.33714872, -8.17015019],
                            [39.3369777, -8.17024349],
                            [39.33680669, -8.17027459],
                            [39.33674187, -8.17027776],
                            [39.33679504, -8.17032686],
                            [39.33679114, -8.17036789],
                            [39.33679114, -8.17066335],
                            [39.3367756, -8.1708966],
                            [39.33683778, -8.17108321],
                            [39.33691551, -8.17133201],
                            [39.33688442, -8.17165855],
                            [39.33679114, -8.17196956],
                            [39.3365113, -8.17234275],
                            [39.33615372, -8.17274706],
                            [39.33579616, -8.17308917],
                            [39.33556295, -8.17329132],
                            [39.33542303, -8.17350901],
                            [39.33520538, -8.17368007],
                            [39.33503436, -8.17369562],
                            [39.33490999, -8.17369562],
                            [39.33473898, -8.17371117],
                            [39.33456797, -8.17388222],
                            [39.33455242, -8.17414656],
                        ],
                        [
                            [39.34996103, -8.17252833],
                            [39.3502521, -8.17268511],
                            [39.35027451, -8.17286429],
                            [39.35011776, -8.17286428],
                            [39.34987147, -8.17286428],
                            [39.34971473, -8.17279708],
                            [39.3495356, -8.1727299],
                            [39.34933407, -8.17261792],
                            [39.34911018, -8.17255074],
                            [39.34886387, -8.17259553],
                            [39.34866235, -8.17266271],
                            [39.34834888, -8.17290908],
                            [39.348013, -8.1733346],
                            [39.34796823, -8.17362575],
                            [39.34799062, -8.17391688],
                            [39.34808018, -8.17418563],
                            [39.34823692, -8.1744544],
                            [39.34863995, -8.17454398],
                            [39.34928929, -8.1744768],
                            [39.34987147, -8.17434242],
                            [39.35040884, -8.17407367],
                            [39.35081189, -8.17373773],
                            [39.35119253, -8.17333461],
                            [39.35139405, -8.17288669],
                            [39.35141646, -8.17232678],
                            [39.35150601, -8.17172209],
                            [39.3515284, -8.17129656],
                            [39.35155081, -8.17100541],
                            [39.35132689, -8.17091583],
                            [39.3510582, -8.17091583],
                            [39.35069995, -8.17098302],
                            [39.35049843, -8.171095],
                            [39.35027451, -8.17120698],
                            [39.34996104, -8.17134135],
                            [39.34971474, -8.17147573],
                            [39.34958038, -8.17174448],
                            [39.34958038, -8.17194603],
                            [39.34958038, -8.1722148],
                            [39.34973712, -8.17237157],
                            [39.34996103, -8.17252833],
                        ],
                        [
                            [39.32802827, -8.1738524],
                            [39.32803935, -8.17386347],
                            [39.32806144, -8.1738524],
                            [39.32802827, -8.1738524],
                        ],
                        [
                            [39.32790665, -8.17376392],
                            [39.32776288, -8.17377498],
                            [39.32764124, -8.17377498],
                            [39.32749748, -8.17377498],
                            [39.32739797, -8.17371967],
                            [39.32733161, -8.17365332],
                            [39.32719893, -8.17355377],
                            [39.32707728, -8.17355377],
                            [39.32697777, -8.17359799],
                            [39.3269446, -8.17368649],
                            [39.32686719, -8.17377498],
                            [39.32678978, -8.17386346],
                            [39.32675662, -8.17397406],
                            [39.32663498, -8.17412892],
                            [39.32653544, -8.17429482],
                            [39.32650228, -8.17447179],
                            [39.32650228, -8.17462663],
                            [39.32650228, -8.17474828],
                            [39.32650228, -8.17488102],
                            [39.32659073, -8.17508012],
                            [39.3266239, -8.17526814],
                            [39.3267013, -8.17557784],
                            [39.32671236, -8.17585436],
                            [39.32672341, -8.17607558],
                            [39.32669025, -8.1764295],
                            [39.32662389, -8.17672813],
                            [39.32648013, -8.17712632],
                            [39.3263585, -8.17740284],
                            [39.32629214, -8.17764616],
                            [39.32621475, -8.17781207],
                            [39.3260931, -8.17813283],
                            [39.32598252, -8.1784204],
                            [39.32584984, -8.1786195],
                            [39.325695, -8.17895131],
                            [39.32563972, -8.17916146],
                            [39.32565078, -8.17928312],
                            [39.32571714, -8.17939373],
                            [39.32584984, -8.17940478],
                            [39.32622579, -8.17937161],
                            [39.32645801, -8.17942692],
                            [39.32661282, -8.1795154],
                            [39.3267566, -8.17957069],
                            [39.32692245, -8.17969237],
                            [39.32712149, -8.17975873],
                            [39.32730949, -8.17975873],
                            [39.32743111, -8.17969238],
                            [39.32749746, -8.17950434],
                            [39.32758592, -8.17930526],
                            [39.327597, -8.179073],
                            [39.327597, -8.1788407],
                            [39.32755276, -8.1786527],
                            [39.32749746, -8.17846465],
                            [39.32744218, -8.17827661],
                            [39.32738689, -8.17808859],
                            [39.32735372, -8.1779227],
                            [39.32727632, -8.17772359],
                            [39.3272321, -8.1774139],
                            [39.32720996, -8.17712632],
                            [39.32716573, -8.17691617],
                            [39.32716573, -8.17666178],
                            [39.32716573, -8.1764295],
                            [39.32720997, -8.17616406],
                            [39.32727633, -8.17592072],
                            [39.32730951, -8.17562209],
                            [39.32743113, -8.17538983],
                            [39.32753066, -8.17503589],
                            [39.32764124, -8.17477043],
                            [39.32780711, -8.17440543],
                            [39.32790665, -8.17408468],
                            [39.32802827, -8.1738524],
                            [39.32790665, -8.17376392],
                        ],
                        [
                            [39.34148917, -8.18180084],
                            [39.34131412, -8.18207599],
                            [39.34083898, -8.18215102],
                            [39.34061391, -8.18242616],
                            [39.3406639, -8.18282637],
                            [39.34078896, -8.18332664],
                            [39.34048886, -8.18347671],
                            [39.33998873, -8.18345171],
                            [39.33973864, -8.18330162],
                            [39.33963862, -8.18300145],
                            [39.33943856, -8.18272632],
                            [39.3392135, -8.1823261],
                            [39.33913848, -8.1819259],
                            [39.33896341, -8.18167576],
                            [39.33866333, -8.18155071],
                            [39.33828822, -8.18157571],
                            [39.3379131, -8.18162572],
                            [39.33748799, -8.18180083],
                            [39.33718787, -8.1819259],
                            [39.33696282, -8.18210097],
                            [39.33676276, -8.18242614],
                            [39.33671275, -8.18272631],
                            [39.33676276, -8.18312652],
                            [39.33676276, -8.18362677],
                            [39.33676276, -8.18412704],
                            [39.33673774, -8.18445221],
                            [39.33656268, -8.18480238],
                            [39.3362626, -8.18457727],
                            [39.33588749, -8.1844272],
                            [39.3356124, -8.18415204],
                            [39.33526232, -8.184052],
                            [39.33508724, -8.18422708],
                            [39.33513727, -8.18467732],
                            [39.33511225, -8.18522761],
                            [39.3349622, -8.18567785],
                            [39.33476214, -8.18595299],
                            [39.33451207, -8.185978],
                            [39.33421199, -8.18597799],
                            [39.33396191, -8.18600301],
                            [39.33386188, -8.18620311],
                            [39.33363681, -8.1863532],
                            [39.33323668, -8.18612806],
                            [39.33301163, -8.18580289],
                            [39.33278656, -8.18555277],
                            [39.3325615, -8.18530263],
                            [39.3322614, -8.18510254],
                            [39.33186128, -8.1850275],
                            [39.33136114, -8.18512753],
                            [39.33098603, -8.18542769],
                            [39.330886, -8.18590293],
                            [39.33083598, -8.1862281],
                            [39.33083598, -8.18645322],
                            [39.33086098, -8.18672837],
                            [39.33086098, -8.18690346],
                            [39.33078597, -8.18700353],
                            [39.33061089, -8.18717861],
                            [39.33041084, -8.18720363],
                            [39.33023578, -8.18722863],
                            [39.33003573, -8.18722863],
                            [39.32983567, -8.18722863],
                            [39.3296356, -8.18715358],
                            [39.32936052, -8.18705353],
                            [39.32908545, -8.18705353],
                            [39.3289104, -8.18712858],
                            [39.3289104, -8.18747875],
                            [39.32896041, -8.18787897],
                            [39.32921049, -8.18807907],
                            [39.32951057, -8.18845428],
                            [39.32991068, -8.18860434],
                            [39.33036082, -8.18877945],
                            [39.33078596, -8.18885448],
                            [39.33076094, -8.18915465],
                            [39.33076094, -8.18962989],
                            [39.33081095, -8.18995506],
                            [39.33091098, -8.19023021],
                            [39.33121107, -8.1906054],
                            [39.3316362, -8.19105564],
                            [39.33186125, -8.19148085],
                            [39.33203632, -8.19190609],
                            [39.33203632, -8.19213121],
                            [39.33211134, -8.19240634],
                            [39.33233639, -8.19258145],
                            [39.33263649, -8.19265649],
                            [39.33286154, -8.19283157],
                            [39.33316164, -8.1928566],
                            [39.33346172, -8.19283158],
                            [39.33371179, -8.19255643],
                            [39.33393688, -8.19225628],
                            [39.33416194, -8.19180605],
                            [39.33433699, -8.1915309],
                            [39.3347121, -8.19130578],
                            [39.33513724, -8.19115569],
                            [39.33546234, -8.1909556],
                            [39.33578744, -8.19083053],
                            [39.33606251, -8.19075551],
                            [39.3366877, -8.1909306],
                            [39.33733789, -8.1911307],
                            [39.337688, -8.19155592],
                            [39.33783804, -8.19200618],
                            [39.33796308, -8.19240636],
                            [39.33826316, -8.19238137],
                            [39.3384382, -8.19215624],
                            [39.3384382, -8.19185609],
                            [39.33863827, -8.19173104],
                            [39.3390384, -8.19173104],
                            [39.33943852, -8.19160597],
                            [39.33953855, -8.1912808],
                            [39.33928847, -8.19098063],
                            [39.33891336, -8.19073051],
                            [39.33883833, -8.19045536],
                            [39.33888835, -8.19008017],
                            [39.33873833, -8.18983004],
                            [39.3383382, -8.18985503],
                            [39.33796309, -8.1899801],
                            [39.33788806, -8.18965493],
                            [39.33796309, -8.18937978],
                            [39.33803811, -8.18882951],
                            [39.33806312, -8.18835425],
                            [39.33833821, -8.18837927],
                            [39.3386633, -8.18837927],
                            [39.33886336, -8.18812913],
                            [39.33903842, -8.18792905],
                            [39.33933851, -8.18795404],
                            [39.33958858, -8.18837928],
                            [39.33983865, -8.18867943],
                            [39.34018876, -8.18877949],
                            [39.3406389, -8.18860439],
                            [39.34113903, -8.18842931],
                            [39.34176423, -8.18825423],
                            [39.34236441, -8.1880291],
                            [39.3430146, -8.18765391],
                            [39.3436648, -8.18717866],
                            [39.34403991, -8.18670341],
                            [39.34403991, -8.18617814],
                            [39.34381486, -8.18577792],
                            [39.34373983, -8.18535272],
                            [39.34378985, -8.18487746],
                            [39.34406493, -8.18460231],
                            [39.34411495, -8.18427714],
                            [39.34393989, -8.18397699],
                            [39.34371483, -8.1836268],
                            [39.3436148, -8.18327661],
                            [39.34351476, -8.18295144],
                            [39.34366482, -8.18257625],
                            [39.34403993, -8.18237615],
                            [39.34423998, -8.18210101],
                            [39.34423999, -8.1818759],
                            [39.34386489, -8.18160075],
                            [39.34348977, -8.18127558],
                            [39.34338974, -8.1809754],
                            [39.34361481, -8.18067525],
                            [39.34396491, -8.18040011],
                            [39.34419, -8.17997491],
                            [39.34398993, -8.17944962],
                            [39.34366483, -8.17939958],
                            [39.34341476, -8.17954967],
                            [39.34316468, -8.17937459],
                            [39.34316468, -8.17907442],
                            [39.34293961, -8.17894935],
                            [39.34258952, -8.17894935],
                            [39.34218939, -8.17914945],
                            [39.34196434, -8.17942459],
                            [39.34188931, -8.17982481],
                            [39.34178926, -8.18022502],
                            [39.34161421, -8.1805752],
                            [39.34148918, -8.18092538],
                            [39.34148918, -8.18132558],
                            [39.34148917, -8.18180084],
                        ],
                        [
                            [39.32092599, -8.18578213],
                            [39.32115584, -8.1854815],
                            [39.32122656, -8.18512783],
                            [39.32142103, -8.18486256],
                            [39.32161553, -8.18461498],
                            [39.32179232, -8.18433204],
                            [39.32196912, -8.1840491],
                            [39.32214593, -8.18385456],
                            [39.32235808, -8.18351858],
                            [39.32258793, -8.18314721],
                            [39.32269401, -8.18282891],
                            [39.32283546, -8.18249291],
                            [39.32299458, -8.18215692],
                            [39.32313601, -8.18182091],
                            [39.32317137, -8.18152029],
                            [39.32317137, -8.18130808],
                            [39.3230653, -8.18125504],
                            [39.32294154, -8.18132577],
                            [39.32278242, -8.18143187],
                            [39.32265866, -8.18153798],
                            [39.32249954, -8.18169713],
                            [39.32234042, -8.18178555],
                            [39.3221813, -8.18190934],
                            [39.32195145, -8.18192702],
                            [39.32170393, -8.18192702],
                            [39.32156249, -8.18185628],
                            [39.32135033, -8.18178555],
                            [39.3211205, -8.18185628],
                            [39.32101439, -8.18194469],
                            [39.32101439, -8.18213922],
                            [39.32110281, -8.18235144],
                            [39.32129728, -8.18247522],
                            [39.3214564, -8.18259901],
                            [39.32168625, -8.18274048],
                            [39.32175697, -8.18291732],
                            [39.32179232, -8.18311185],
                            [39.32165089, -8.18330638],
                            [39.3214564, -8.18346553],
                            [39.32122656, -8.183607],
                            [39.32101438, -8.18380152],
                            [39.32083761, -8.18404909],
                            [39.32067847, -8.18424363],
                            [39.32053704, -8.18449119],
                            [39.32039558, -8.18468573],
                            [39.32027181, -8.18491561],
                            [39.32016575, -8.18516319],
                            [39.32009504, -8.18549919],
                            [39.31993592, -8.1857114],
                            [39.31967071, -8.18574676],
                            [39.31942319, -8.18567601],
                            [39.31926407, -8.18556992],
                            [39.31908726, -8.18558761],
                            [39.3189635, -8.18571139],
                            [39.31887511, -8.18592359],
                            [39.31894583, -8.18615349],
                            [39.31901654, -8.18641874],
                            [39.31901654, -8.18666632],
                            [39.31901654, -8.18693159],
                            [39.31894582, -8.18717917],
                            [39.31882206, -8.187356],
                            [39.31871599, -8.18751515],
                            [39.31850382, -8.18755053],
                            [39.3182386, -8.18753284],
                            [39.31797342, -8.18742674],
                            [39.31776126, -8.18740905],
                            [39.31749604, -8.18733831],
                            [39.31726622, -8.18733831],
                            [39.31705406, -8.18733831],
                            [39.31684189, -8.18739135],
                            [39.3166651, -8.18753283],
                            [39.31655901, -8.18769199],
                            [39.31655901, -8.1879042],
                            [39.31661205, -8.18816946],
                            [39.3166651, -8.18843472],
                            [39.31670044, -8.18857618],
                            [39.31680653, -8.18875303],
                            [39.31680653, -8.18894755],
                            [39.31673581, -8.18908903],
                            [39.31648829, -8.18921281],
                            [39.31624077, -8.18923049],
                            [39.31606396, -8.18924818],
                            [39.31594022, -8.18935428],
                            [39.31590484, -8.18963722],
                            [39.31590484, -8.1898848],
                            [39.31595787, -8.19013237],
                            [39.31608163, -8.19030922],
                            [39.31631148, -8.19039764],
                            [39.31662973, -8.19041532],
                            [39.31693028, -8.19041532],
                            [39.31723085, -8.19046838],
                            [39.31744301, -8.19046838],
                            [39.31763749, -8.19052144],
                            [39.31797341, -8.19060986],
                            [39.31825629, -8.19059217],
                            [39.31845077, -8.19039765],
                            [39.3186806, -8.19025617],
                            [39.31889277, -8.19018544],
                            [39.31912262, -8.19013239],
                            [39.31937014, -8.19006166],
                            [39.31954693, -8.18999093],
                            [39.31956462, -8.18981408],
                            [39.31952926, -8.18953114],
                            [39.31952926, -8.18930125],
                            [39.31954693, -8.18905367],
                            [39.31954693, -8.18885915],
                            [39.31956462, -8.18868231],
                            [39.31970605, -8.18834631],
                            [39.31988286, -8.18818715],
                            [39.32000662, -8.18801031],
                            [39.32014804, -8.18781579],
                            [39.32023646, -8.18755054],
                            [39.32030719, -8.18728527],
                            [39.32036022, -8.18698464],
                            [39.32039557, -8.18670169],
                            [39.32039557, -8.18636571],
                            [39.32050167, -8.18617117],
                            [39.32073151, -8.18597665],
                            [39.32092599, -8.18578213],
                        ],
                        [
                            [39.36065592, -8.1443769],
                            [39.36048316, -8.14429051],
                            [39.36027802, -8.14411771],
                            [39.36018083, -8.14408531],
                            [39.35998648, -8.14410691],
                            [39.35978133, -8.1441825],
                            [39.35955459, -8.14438769],
                            [39.35934943, -8.14470089],
                            [39.35907951, -8.14491688],
                            [39.3587772, -8.14520847],
                            [39.35850725, -8.14561886],
                            [39.35829129, -8.14598605],
                            [39.35825892, -8.14627765],
                            [39.35832369, -8.14653684],
                            [39.35857203, -8.14670962],
                            [39.35896075, -8.14664483],
                            [39.35916588, -8.14632083],
                            [39.35930625, -8.14608324],
                            [39.35940343, -8.14583485],
                            [39.35958699, -8.14569445],
                            [39.35978133, -8.14579166],
                            [39.35998647, -8.14604005],
                            [39.36021323, -8.14636405],
                            [39.36034278, -8.14655843],
                            [39.36048315, -8.14682843],
                            [39.36089345, -8.14693641],
                            [39.36114178, -8.14677444],
                            [39.36135773, -8.14645044],
                            [39.36142251, -8.14599686],
                            [39.3614441, -8.14552168],
                            [39.36126057, -8.14503568],
                            [39.36119577, -8.14487368],
                            [39.36096902, -8.14463609],
                            [39.36080707, -8.1444957],
                            [39.36065592, -8.1443769],
                        ],
                        [
                            [39.30792216, -8.14588082],
                            [39.30790624, -8.14623081],
                            [39.30790624, -8.14661262],
                            [39.30790624, -8.14693075],
                            [39.3077154, -8.14723301],
                            [39.307779, -8.14753526],
                            [39.30779491, -8.14782162],
                            [39.30790623, -8.1480125],
                            [39.30819252, -8.14798068],
                            [39.3084629, -8.14774206],
                            [39.30862194, -8.147408],
                            [39.30862194, -8.1470103],
                            [39.30860604, -8.14654897],
                            [39.30860604, -8.14618309],
                            [39.30855834, -8.14576948],
                            [39.30851062, -8.14548313],
                            [39.30822434, -8.14543541],
                            [39.30801758, -8.1456104],
                            [39.30792216, -8.14588082],
                        ],
                        [
                            [39.32131196, -8.1449854],
                            [39.32094795, -8.14488137],
                            [39.32062294, -8.14473834],
                            [39.32012893, -8.14446527],
                            [39.31972594, -8.14421821],
                            [39.31930992, -8.1438281],
                            [39.3188419, -8.14329497],
                            [39.31850391, -8.14286586],
                            [39.3180489, -8.14235875],
                            [39.31756789, -8.14170858],
                            [39.31720389, -8.14124046],
                            [39.31678788, -8.14052527],
                            [39.31661887, -8.14014819],
                            [39.31647588, -8.13984912],
                            [39.31625487, -8.13962807],
                            [39.31604687, -8.13952402],
                            [39.31590387, -8.13953702],
                            [39.31566987, -8.13961505],
                            [39.31551386, -8.13981009],
                            [39.31535785, -8.14012218],
                            [39.31518885, -8.14044727],
                            [39.31499384, -8.14078533],
                            [39.31482483, -8.14083736],
                            [39.31453883, -8.14088936],
                            [39.31436983, -8.14087636],
                            [39.31418783, -8.14092837],
                            [39.31412281, -8.14113642],
                            [39.31396682, -8.14131846],
                            [39.31381081, -8.14144848],
                            [39.31362881, -8.1414745],
                            [39.31344681, -8.1414875],
                            [39.3132908, -8.14160454],
                            [39.31321278, -8.14182558],
                            [39.3131608, -8.14204663],
                            [39.3128358, -8.14212466],
                            [39.31254978, -8.14218967],
                            [39.31231577, -8.14221568],
                            [39.31213378, -8.14235872],
                            [39.31195177, -8.14265779],
                            [39.31188677, -8.14291785],
                            [39.31175676, -8.14321692],
                            [39.31165276, -8.14348999],
                            [39.31147075, -8.14367204],
                            [39.31132775, -8.14385407],
                            [39.31132775, -8.14406213],
                            [39.31139273, -8.1443872],
                            [39.31150974, -8.14482932],
                            [39.31161375, -8.14501137],
                            [39.31199075, -8.14493335],
                            [39.31247177, -8.1447253],
                            [39.31300478, -8.14456926],
                            [39.31330377, -8.14455626],
                            [39.3136938, -8.14455626],
                            [39.3140578, -8.1446603],
                            [39.3144868, -8.14485533],
                            [39.31487683, -8.14507639],
                            [39.31537082, -8.14518043],
                            [39.31581284, -8.1451024],
                            [39.31615086, -8.14486834],
                            [39.31651486, -8.14451726],
                            [39.31680086, -8.14429621],
                            [39.31706087, -8.14420519],
                            [39.31725588, -8.14412716],
                            [39.31761988, -8.14414017],
                            [39.31800989, -8.14425721],
                            [39.31843891, -8.14454328],
                            [39.3188679, -8.14472533],
                            [39.31921892, -8.14494639],
                            [39.31950492, -8.14519345],
                            [39.31976493, -8.14557054],
                            [39.31994692, -8.14585661],
                            [39.32008993, -8.14610367],
                            [39.32023294, -8.14623371],
                            [39.32042793, -8.14627272],
                            [39.32072693, -8.14610367],
                            [39.32106495, -8.14573959],
                            [39.32133796, -8.14545352],
                            [39.32145496, -8.14512843],
                            [39.32131196, -8.1449854],
                        ],
                        [
                            [39.34474268, -8.12344502],
                            [39.345166, -8.12356955],
                            [39.34543991, -8.12349483],
                            [39.34561424, -8.12332049],
                            [39.34566403, -8.12294687],
                            [39.34536522, -8.12252345],
                            [39.3451162, -8.12214983],
                            [39.34481738, -8.12185093],
                            [39.34424463, -8.12152713],
                            [39.34382132, -8.12125316],
                            [39.34337307, -8.12092934],
                            [39.34304933, -8.12060555],
                            [39.34280033, -8.12018213],
                            [39.34280033, -8.11980851],
                            [39.34265093, -8.11950962],
                            [39.3422774, -8.11921072],
                            [39.34192878, -8.11891184],
                            [39.34160506, -8.11861294],
                            [39.34128133, -8.11841368],
                            [39.3408829, -8.11826424],
                            [39.34043467, -8.11823933],
                            [39.33996153, -8.11826424],
                            [39.33963781, -8.11838876],
                            [39.33930312, -8.11855415],
                            [39.33908997, -8.11878729],
                            [39.33886583, -8.11913599],
                            [39.33851722, -8.1194847],
                            [39.33799427, -8.11988319],
                            [39.33759583, -8.12003265],
                            [39.33709781, -8.12008247],
                            [39.33677408, -8.12005756],
                            [39.33682388, -8.12023191],
                            [39.33697329, -8.12045609],
                            [39.33727209, -8.12065535],
                            [39.33752113, -8.12077988],
                            [39.33786974, -8.12082969],
                            [39.33816859, -8.12097914],
                            [39.33829308, -8.12102897],
                            [39.3383678, -8.12125313],
                            [39.33841758, -8.12155202],
                            [39.33849231, -8.12195053],
                            [39.33874131, -8.12219961],
                            [39.33908995, -8.12242378],
                            [39.33951328, -8.12239888],
                            [39.33993661, -8.12239888],
                            [39.34021053, -8.12239888],
                            [39.34043465, -8.12257323],
                            [39.34068367, -8.12292194],
                            [39.34090778, -8.12299666],
                            [39.3411319, -8.12292194],
                            [39.34143072, -8.12292194],
                            [39.34160504, -8.12292194],
                            [39.34175444, -8.12314611],
                            [39.34197855, -8.12339519],
                            [39.34225248, -8.12361935],
                            [39.3426758, -8.12369407],
                            [39.34307424, -8.12366918],
                            [39.34339796, -8.12356954],
                            [39.34357229, -8.12329557],
                            [39.34372169, -8.12309631],
                            [39.34399561, -8.12309631],
                            [39.34439405, -8.12324575],
                            [39.34474268, -8.12344502],
                        ],
                        [
                            [39.32213468, -8.06646817],
                            [39.32222975, -8.06621139],
                            [39.32226777, -8.06598314],
                            [39.32237238, -8.06567882],
                            [39.32246747, -8.065384],
                            [39.32259106, -8.0651082],
                            [39.32271468, -8.06484192],
                            [39.32281925, -8.06457563],
                            [39.32288581, -8.06440444],
                            [39.32293336, -8.06421425],
                            [39.32295238, -8.06400503],
                            [39.3228573, -8.06374824],
                            [39.32278123, -8.06359609],
                            [39.32266714, -8.06335833],
                            [39.32258157, -8.06308253],
                            [39.32258157, -8.06274967],
                            [39.32258157, -8.06243584],
                            [39.32259107, -8.06219808],
                            [39.32259107, -8.06198885],
                            [39.32252453, -8.0618367],
                            [39.32241994, -8.0617321],
                            [39.32232485, -8.06169403],
                            [39.32220124, -8.06171307],
                            [39.32205862, -8.0618367],
                            [39.321916, -8.06202689],
                            [39.32176387, -8.06224563],
                            [39.32163076, -8.06248338],
                            [39.32150716, -8.06266408],
                            [39.32126945, -8.06296841],
                            [39.32104127, -8.06324421],
                            [39.32087962, -8.06353901],
                            [39.32059438, -8.06401452],
                            [39.32048979, -8.0642713],
                            [39.32035668, -8.06449003],
                            [39.32029962, -8.06463268],
                            [39.32019503, -8.06485142],
                            [39.32018553, -8.06502261],
                            [39.32025209, -8.06534595],
                            [39.32037569, -8.06560273],
                            [39.32053732, -8.06593558],
                            [39.32083208, -8.06642061],
                            [39.32106027, -8.06675347],
                            [39.32116486, -8.06695318],
                            [39.32133599, -8.06715292],
                            [39.32148811, -8.06729555],
                            [39.32161173, -8.06734309],
                            [39.32175435, -8.06728604],
                            [39.32194451, -8.06710533],
                            [39.32209663, -8.06691514],
                            [39.32210615, -8.06675347],
                            [39.32213468, -8.06646817],
                        ],
                        [
                            [39.32399138, -8.06859227],
                            [39.32451775, -8.06865351],
                            [39.3250686, -8.06865351],
                            [39.32549705, -8.06853107],
                            [39.32620704, -8.0682862],
                            [39.3268436, -8.06798008],
                            [39.327321, -8.06782093],
                            [39.32772497, -8.06766176],
                            [39.32842271, -8.06740464],
                            [39.32909598, -8.06703732],
                            [39.32961012, -8.06671897],
                            [39.32997735, -8.06643735],
                            [39.33039356, -8.06610678],
                            [39.33098115, -8.06560476],
                            [39.33136063, -8.06526194],
                            [39.33185027, -8.06488238],
                            [39.33233992, -8.06455179],
                            [39.33284183, -8.06434365],
                            [39.33331923, -8.06414774],
                            [39.33380888, -8.06398859],
                            [39.33429854, -8.06380491],
                            [39.33472697, -8.06365798],
                            [39.33502077, -8.06362126],
                            [39.33541249, -8.06358453],
                            [39.33560835, -8.06348658],
                            [39.33573076, -8.0633519],
                            [39.33566956, -8.06318048],
                            [39.33551041, -8.06300905],
                            [39.3353268, -8.06286213],
                            [39.33500853, -8.06276418],
                            [39.33469026, -8.06266622],
                            [39.33431078, -8.06249481],
                            [39.33394354, -8.0623846],
                            [39.33358854, -8.0622989],
                            [39.33322131, -8.06221318],
                            [39.33287855, -8.06220094],
                            [39.3325358, -8.06221317],
                            [39.33224202, -8.06226217],
                            [39.33188701, -8.06229889],
                            [39.33143408, -8.06243357],
                            [39.33094444, -8.06251929],
                            [39.33044254, -8.06260499],
                            [39.33009979, -8.06260499],
                            [39.32995287, -8.06261722],
                            [39.32976927, -8.06251928],
                            [39.32976927, -8.06228664],
                            [39.32978152, -8.06180912],
                            [39.32992841, -8.06146629],
                            [39.33014874, -8.06098878],
                            [39.33033238, -8.06068268],
                            [39.330516, -8.06037659],
                            [39.33056497, -8.06014394],
                            [39.33060168, -8.059997],
                            [39.33054049, -8.05955622],
                            [39.33047929, -8.05915217],
                            [39.33047929, -8.05887056],
                            [39.33060169, -8.05862569],
                            [39.33073636, -8.05842977],
                            [39.33073636, -8.05820939],
                            [39.33069963, -8.05803797],
                            [39.33062618, -8.05791552],
                            [39.33066291, -8.05773186],
                            [39.3308955, -8.05757269],
                            [39.33107911, -8.05738904],
                            [39.33117703, -8.05709519],
                            [39.33115257, -8.05666664],
                            [39.33112809, -8.05631156],
                            [39.33114032, -8.05591975],
                            [39.33126274, -8.05543],
                            [39.33137291, -8.05500145],
                            [39.33140961, -8.05462188],
                            [39.33138516, -8.05429129],
                            [39.33136068, -8.05408316],
                            [39.33136068, -8.05381378],
                            [39.33140962, -8.05361788],
                            [39.33156878, -8.05334851],
                            [39.33171567, -8.05303017],
                            [39.33174016, -8.05260163],
                            [39.33176464, -8.05227104],
                            [39.33167896, -8.05201392],
                            [39.33167896, -8.05179352],
                            [39.33164223, -8.05134049],
                            [39.33155655, -8.05097317],
                            [39.33150758, -8.0507038],
                            [39.33117706, -8.05116907],
                            [39.33117706, -8.05156088],
                            [39.33117706, -8.05198942],
                            [39.33114034, -8.05240572],
                            [39.33093223, -8.05287099],
                            [39.33065066, -8.05350767],
                            [39.33033241, -8.0540709],
                            [39.3298917, -8.05471983],
                            [39.3294388, -8.05525857],
                            [39.32905931, -8.05561365],
                            [39.32872879, -8.05596872],
                            [39.32854517, -8.05636052],
                            [39.32834932, -8.05674009],
                            [39.32816568, -8.05703394],
                            [39.32800655, -8.0573523],
                            [39.32788415, -8.05760941],
                            [39.32762708, -8.05796447],
                            [39.32744346, -8.05816041],
                            [39.32727207, -8.05830732],
                            [39.32706397, -8.05842976],
                            [39.32675794, -8.05856444],
                            [39.3268821, -8.05864222],
                            [39.32615812, -8.05873586],
                            [39.32586433, -8.05890726],
                            [39.3256195, -8.05900522],
                            [39.32543588, -8.0591399],
                            [39.32526451, -8.05926234],
                            [39.32500744, -8.05937253],
                            [39.32460347, -8.05960517],
                            [39.32424848, -8.05987455],
                            [39.32389348, -8.06022961],
                            [39.32359969, -8.06068265],
                            [39.32348949, -8.06100099],
                            [39.32334262, -8.06125811],
                            [39.32328141, -8.06146626],
                            [39.3232202, -8.0616989],
                            [39.3232202, -8.06188256],
                            [39.3232814, -8.06204173],
                            [39.32336709, -8.06222539],
                            [39.32342829, -8.06245802],
                            [39.32347727, -8.06275187],
                            [39.32347727, -8.06304574],
                            [39.32346501, -8.06358448],
                            [39.32339158, -8.06409872],
                            [39.32336708, -8.06466195],
                            [39.32315899, -8.06532313],
                            [39.32291415, -8.06608226],
                            [39.32268156, -8.06653528],
                            [39.32252243, -8.06692708],
                            [39.32238778, -8.06730665],
                            [39.32232657, -8.06762499],
                            [39.32238777, -8.06798006],
                            [39.32266931, -8.06812699],
                            [39.32302431, -8.06832291],
                            [39.32344051, -8.06845759],
                            [39.32399138, -8.06859227],
                        ],
                        [
                            [39.32548322, -8.0489208],
                            [39.32534291, -8.04890803],
                            [39.3252026, -8.04884425],
                            [39.32504953, -8.0487039],
                            [39.3248837, -8.04849978],
                            [39.32471789, -8.04834667],
                            [39.32442453, -8.04828288],
                            [39.32385053, -8.04828288],
                            [39.3234041, -8.04830838],
                            [39.32303419, -8.04830838],
                            [39.32263878, -8.04839769],
                            [39.32242193, -8.04856355],
                            [39.32216683, -8.0486784],
                            [39.32196275, -8.0487932],
                            [39.3217459, -8.04893353],
                            [39.32138875, -8.04893353],
                            [39.32080201, -8.04884423],
                            [39.32047037, -8.04870388],
                            [39.32020251, -8.04853803],
                            [39.31987086, -8.04829561],
                            [39.31971781, -8.04791287],
                            [39.31973056, -8.04761944],
                            [39.31973056, -8.04705807],
                            [39.31973056, -8.04653499],
                            [39.31975608, -8.04603741],
                            [39.31975608, -8.04564191],
                            [39.31966681, -8.04536124],
                            [39.31948822, -8.04518261],
                            [39.31929692, -8.04509331],
                            [39.31910556, -8.04509331],
                            [39.31887596, -8.04519537],
                            [39.31877392, -8.04527192],
                            [39.3185826, -8.04525917],
                            [39.31841678, -8.04520813],
                            [39.31821269, -8.04516985],
                            [39.31799586, -8.04520812],
                            [39.31784278, -8.04545053],
                            [39.31775349, -8.04584604],
                            [39.31766421, -8.04613947],
                            [39.31762594, -8.04649671],
                            [39.31767696, -8.04705806],
                            [39.31784277, -8.04779804],
                            [39.31800859, -8.04838492],
                            [39.31808512, -8.04895904],
                            [39.31808512, -8.04969902],
                            [39.31808511, -8.05055382],
                            [39.31818715, -8.05128103],
                            [39.31826368, -8.05216135],
                            [39.3183147, -8.05273546],
                            [39.31860808, -8.05319477],
                            [39.31920757, -8.05365406],
                            [39.31978156, -8.05396026],
                            [39.32054687, -8.05408785],
                            [39.32140148, -8.0541006],
                            [39.3221413, -8.05410061],
                            [39.32254947, -8.05399854],
                            [39.32294491, -8.05366684],
                            [39.32327652, -8.05319479],
                            [39.32363368, -8.05265894],
                            [39.32386327, -8.05212311],
                            [39.32434799, -8.05130658],
                            [39.32457757, -8.05077073],
                            [39.32483269, -8.05033695],
                            [39.3251133, -8.04994146],
                            [39.32535566, -8.04967353],
                            [39.32548321, -8.04941836],
                            [39.32548322, -8.04912493],
                            [39.32548322, -8.0489208],
                        ],
                        [
                            [39.34730396, -8.04899247],
                            [39.34721717, -8.04912271],
                            [39.34710866, -8.04925294],
                            [39.347011, -8.04936147],
                            [39.34695675, -8.04951341],
                            [39.34695675, -8.04969789],
                            [39.34704356, -8.04984985],
                            [39.34727141, -8.04992581],
                            [39.34754267, -8.05000178],
                            [39.34772711, -8.05004518],
                            [39.34791157, -8.05013201],
                            [39.34804179, -8.05024055],
                            [39.34815027, -8.05040334],
                            [39.34817198, -8.05058783],
                            [39.34811773, -8.05076147],
                            [39.34808519, -8.0510545],
                            [39.34806348, -8.05130412],
                            [39.34794413, -8.05148861],
                            [39.34774882, -8.05154287],
                            [39.34758605, -8.05162969],
                            [39.34751011, -8.05175992],
                            [39.34747757, -8.05194442],
                            [39.34752095, -8.05212893],
                            [39.34770542, -8.05233512],
                            [39.34798753, -8.05253047],
                            [39.34818283, -8.05271498],
                            [39.34828048, -8.05289946],
                            [39.34834557, -8.05308398],
                            [39.34834557, -8.05327932],
                            [39.34831303, -8.0533987],
                            [39.34818283, -8.0534204],
                            [39.34797667, -8.05336614],
                            [39.34783561, -8.05336614],
                            [39.34781391, -8.05349637],
                            [39.34784647, -8.05364829],
                            [39.34797666, -8.05378938],
                            [39.34809602, -8.05391962],
                            [39.34818283, -8.05406071],
                            [39.34820451, -8.0542018],
                            [39.34815025, -8.05435373],
                            [39.34802007, -8.05450568],
                            [39.34782476, -8.05483126],
                            [39.3476837, -8.05510257],
                            [39.34759689, -8.05531963],
                            [39.34744499, -8.05552584],
                            [39.34726054, -8.05571033],
                            [39.34715203, -8.05590567],
                            [39.34710863, -8.05609018],
                            [39.34705436, -8.05628553],
                            [39.34702183, -8.05642661],
                            [39.34707608, -8.05660026],
                            [39.34721713, -8.05664367],
                            [39.34743414, -8.05664367],
                            [39.34768369, -8.05671964],
                            [39.3479007, -8.0567739],
                            [39.34815024, -8.05690413],
                            [39.34845407, -8.05706693],
                            [39.34868192, -8.05706693],
                            [39.34896402, -8.05706693],
                            [39.34910507, -8.05704522],
                            [39.34918104, -8.05688244],
                            [39.3493221, -8.05671965],
                            [39.34954995, -8.0565894],
                            [39.3497561, -8.05656771],
                            [39.3498212, -8.05671965],
                            [39.34992971, -8.05683903],
                            [39.35014672, -8.05683903],
                            [39.35040712, -8.05670879],
                            [39.35066753, -8.05656772],
                            [39.35097135, -8.05643748],
                            [39.35128601, -8.05627468],
                            [39.35176341, -8.05597082],
                            [39.35216488, -8.05564524],
                            [39.35240359, -8.05553671],
                            [39.35258804, -8.05547159],
                            [39.35283759, -8.05548245],
                            [39.35306545, -8.05541733],
                            [39.35330417, -8.05521113],
                            [39.35349947, -8.0551677],
                            [39.35367307, -8.05524369],
                            [39.35372732, -8.05550416],
                            [39.35372732, -8.05573206],
                            [39.35368391, -8.05603594],
                            [39.35356456, -8.05626386],
                            [39.3532933, -8.05654602],
                            [39.35295694, -8.05677392],
                            [39.35265314, -8.05692586],
                            [39.35234932, -8.05711036],
                            [39.35211063, -8.05727315],
                            [39.35200211, -8.05740338],
                            [39.35193701, -8.05759873],
                            [39.35190446, -8.05794601],
                            [39.351937, -8.05833671],
                            [39.35198041, -8.05875997],
                            [39.35209976, -8.05877081],
                            [39.35222996, -8.05872742],
                            [39.35236017, -8.05872742],
                            [39.35243612, -8.05885765],
                            [39.35260972, -8.05906385],
                            [39.35278332, -8.05918323],
                            [39.35295693, -8.05936773],
                            [39.35321734, -8.05946541],
                            [39.35349945, -8.0596065],
                            [39.3536839, -8.05970417],
                            [39.35389006, -8.05986697],
                            [39.35395516, -8.06010572],
                            [39.35395516, -8.06037705],
                            [39.35393346, -8.06059409],
                            [39.35389006, -8.06074604],
                            [39.35391176, -8.06088713],
                            [39.35406366, -8.06101736],
                            [39.35429151, -8.06113674],
                            [39.35449767, -8.06118015],
                            [39.35469297, -8.06127783],
                            [39.35476892, -8.0614189],
                            [39.35475806, -8.06166853],
                            [39.35468213, -8.06180962],
                            [39.35450849, -8.06189643],
                            [39.35424811, -8.06202667],
                            [39.35392259, -8.06222202],
                            [39.35366218, -8.06244992],
                            [39.35348858, -8.06271039],
                            [39.35340178, -8.06292744],
                            [39.35340178, -8.06312279],
                            [39.35354284, -8.0632096],
                            [39.35384664, -8.06315535],
                            [39.35421555, -8.06305767],
                            [39.35474722, -8.06290574],
                            [39.35519209, -8.06274295],
                            [39.35555014, -8.06256931],
                            [39.35572374, -8.0623631],
                            [39.35589736, -8.06201582],
                            [39.35614692, -8.06147318],
                            [39.35635307, -8.06107163],
                            [39.35662434, -8.0605941],
                            [39.35687388, -8.06032279],
                            [39.35738386, -8.05969333],
                            [39.35780702, -8.05921582],
                            [39.35816508, -8.05889023],
                            [39.35857739, -8.05862978],
                            [39.35908736, -8.05828248],
                            [39.35965157, -8.05798946],
                            [39.36027005, -8.05767473],
                            [39.36152869, -8.05722978],
                            [39.36226651, -8.0569259],
                            [39.36293923, -8.05662203],
                            [39.3636011, -8.05632901],
                            [39.36432805, -8.05607939],
                            [39.36513099, -8.05575382],
                            [39.3653369, -8.05549517],
                            [39.36535595, -8.05551715],
                            [39.36569532, -8.05590885],
                            [39.36600865, -8.05569995],
                            [39.36653081, -8.05522991],
                            [39.36707908, -8.05468152],
                            [39.36757514, -8.05426368],
                            [39.36807117, -8.05392421],
                            [39.36856725, -8.05366307],
                            [39.36893277, -8.05329747],
                            [39.36953324, -8.0529841],
                            [39.36987265, -8.05274908],
                            [39.3703687, -8.05251407],
                            [39.37070811, -8.05235738],
                            [39.37112584, -8.05220071],
                            [39.37151747, -8.05193956],
                            [39.37196131, -8.05175678],
                            [39.37211588, -8.05173099],
                            [39.37215601, -8.05153933],
                            [39.37224177, -8.0512391],
                            [39.37247764, -8.05074586],
                            [39.37262773, -8.05027407],
                            [39.37290645, -8.04997381],
                            [39.37307798, -8.04950202],
                            [39.37344248, -8.04883722],
                            [39.37369977, -8.04842976],
                            [39.37399992, -8.04808663],
                            [39.37423578, -8.04780785],
                            [39.37438587, -8.04750762],
                            [39.3746646, -8.04718594],
                            [39.374879, -8.04697148],
                            [39.37505052, -8.04667125],
                            [39.37547933, -8.04639247],
                            [39.3756723, -8.04615656],
                            [39.37597247, -8.04604934],
                            [39.37633696, -8.04592068],
                            [39.37672288, -8.04585632],
                            [39.37686895, -8.04585633],
                            [39.37686895, -8.04557324],
                            [39.37692834, -8.04530593],
                            [39.37698773, -8.04497921],
                            [39.37713621, -8.0446822],
                            [39.37731438, -8.04435549],
                            [39.37755193, -8.04414759],
                            [39.37778949, -8.04393966],
                            [39.37805676, -8.04373175],
                            [39.37832402, -8.04355354],
                            [39.37853188, -8.04343474],
                            [39.37885851, -8.04331594],
                            [39.37921486, -8.04304863],
                            [39.37957119, -8.04290013],
                            [39.37998691, -8.04263282],
                            [39.38031357, -8.0422467],
                            [39.3806996, -8.0420685],
                            [39.38096685, -8.0419497],
                            [39.38132318, -8.0419497],
                            [39.38182799, -8.04215761],
                            [39.38212493, -8.04218731],
                            [39.38248128, -8.0420388],
                            [39.38262976, -8.04180118],
                            [39.38268915, -8.04150417],
                            [39.38268915, -8.04126656],
                            [39.38265945, -8.04099924],
                            [39.38251099, -8.04070224],
                            [39.38239221, -8.04037552],
                            [39.38227344, -8.0401676],
                            [39.38206555, -8.0398409],
                            [39.38203585, -8.03960326],
                            [39.38191708, -8.03927656],
                            [39.38191708, -8.03886074],
                            [39.38191708, -8.03844492],
                            [39.38194679, -8.03802911],
                            [39.38200618, -8.03767269],
                            [39.38203586, -8.03725686],
                            [39.38206719, -8.03705323],
                            [39.38209525, -8.03687074],
                            [39.38212496, -8.03660344],
                            [39.38218436, -8.03624702],
                            [39.38218436, -8.0358609],
                            [39.38221407, -8.03556389],
                            [39.38221407, -8.03523716],
                            [39.38203587, -8.03496986],
                            [39.38179832, -8.03473223],
                            [39.38156077, -8.03461343],
                            [39.38132322, -8.03452433],
                            [39.3808778, -8.03443522],
                            [39.38052147, -8.03440553],
                            [39.38016512, -8.03443522],
                            [39.37980878, -8.03449462],
                            [39.37942274, -8.03455402],
                            [39.37906641, -8.03464313],
                            [39.37876947, -8.03479164],
                            [39.37847253, -8.03488073],
                            [39.37826466, -8.03499953],
                            [39.37793802, -8.03511834],
                            [39.37767075, -8.03529654],
                            [39.37734412, -8.03541537],
                            [39.37704716, -8.03562327],
                            [39.37675022, -8.03580147],
                            [39.37651267, -8.03594998],
                            [39.37624541, -8.03606879],
                            [39.37594847, -8.03630639],
                            [39.37577505, -8.03638645],
                            [39.37573357, -8.03675352],
                            [39.37551489, -8.03705973],
                            [39.37529621, -8.03736596],
                            [39.37508847, -8.03769405],
                            [39.37496819, -8.03790186],
                            [39.37469482, -8.03814246],
                            [39.37449802, -8.03833931],
                            [39.37415906, -8.03836119],
                            [39.37383103, -8.03832837],
                            [39.37349207, -8.03826276],
                            [39.37322966, -8.03812058],
                            [39.37310939, -8.03798933],
                            [39.37290163, -8.03772686],
                            [39.37262828, -8.03746437],
                            [39.37237679, -8.03734407],
                            [39.37212532, -8.03725658],
                            [39.3718301, -8.03721284],
                            [39.37158954, -8.03719096],
                            [39.37123965, -8.03716908],
                            [39.37059454, -8.03715815],
                            [39.37018997, -8.03714721],
                            [39.37001503, -8.03708159],
                            [39.36989475, -8.03691754],
                            [39.36985102, -8.03675349],
                            [39.36984008, -8.03656757],
                            [39.36986196, -8.03616292],
                            [39.36989476, -8.03575826],
                            [39.36988382, -8.03547391],
                            [39.36977448, -8.03530985],
                            [39.36966514, -8.03521143],
                            [39.3694574, -8.03501458],
                            [39.36916218, -8.03475209],
                            [39.36883415, -8.03439118],
                            [39.36848426, -8.03401934],
                            [39.36760955, -8.03299129],
                            [39.36708471, -8.03232416],
                            [39.36669107, -8.0317664],
                            [39.36635212, -8.03116488],
                            [39.36616624, -8.03076022],
                            [39.36611157, -8.03036651],
                            [39.36608971, -8.0299181],
                            [39.36608972, -8.02927285],
                            [39.36601318, -8.028901],
                            [39.3658601, -8.02854009],
                            [39.36577263, -8.02829948],
                            [39.36572889, -8.02790577],
                            [39.36571796, -8.02735895],
                            [39.36577263, -8.02666995],
                            [39.36571796, -8.02636372],
                            [39.36565236, -8.02613405],
                            [39.36555396, -8.02592625],
                            [39.36540088, -8.02581688],
                            [39.36519313, -8.02558721],
                            [39.3648323, -8.02529192],
                            [39.36444961, -8.02499663],
                            [39.36388104, -8.02475604],
                            [39.36342181, -8.02469041],
                            [39.36303911, -8.02469041],
                            [39.36252521, -8.02469041],
                            [39.36210971, -8.02463573],
                            [39.36171608, -8.02451542],
                            [39.36124592, -8.02434043],
                            [39.36077575, -8.02417638],
                            [39.36044773, -8.02405608],
                            [39.36026185, -8.02392484],
                            [39.35999944, -8.02360767],
                            [39.35975889, -8.02323583],
                            [39.3593762, -8.02273274],
                            [39.35903723, -8.0222406],
                            [39.3588951, -8.02189062],
                            [39.35878576, -8.02163908],
                            [39.35872015, -8.02151877],
                            [39.35854521, -8.02143129],
                            [39.35839213, -8.02139847],
                            [39.35818438, -8.02142035],
                            [39.35800943, -8.02152971],
                            [39.35785636, -8.02180313],
                            [39.35780169, -8.02204373],
                            [39.35770328, -8.02241558],
                            [39.3576158, -8.02291865],
                            [39.35752833, -8.02347643],
                            [39.35742991, -8.02404513],
                            [39.35735338, -8.02435136],
                            [39.35720029, -8.02448258],
                            [39.35695974, -8.02449352],
                            [39.35664266, -8.02444978],
                            [39.35634744, -8.02435135],
                            [39.35587727, -8.02420917],
                            [39.35534149, -8.02399044],
                            [39.35461985, -8.0236842],
                            [39.35409501, -8.02343266],
                            [39.35361391, -8.02315924],
                            [39.35318747, -8.02295145],
                            [39.35282666, -8.02279833],
                            [39.35258611, -8.02277646],
                            [39.35238929, -8.02285301],
                            [39.3522034, -8.02298425],
                            [39.3520066, -8.02315924],
                            [39.35183165, -8.02341078],
                            [39.35167856, -8.02369513],
                            [39.35154736, -8.02396854],
                            [39.35150361, -8.02423103],
                            [39.35148175, -8.02462474],
                            [39.35142708, -8.02500751],
                            [39.35143802, -8.02552153],
                            [39.35152548, -8.0257184],
                            [39.35160202, -8.02596994],
                            [39.3517551, -8.02621054],
                            [39.3518535, -8.0264074],
                            [39.35196285, -8.02660427],
                            [39.35200658, -8.02686674],
                            [39.35198471, -8.02708547],
                            [39.3517879, -8.02726045],
                            [39.35154735, -8.02749012],
                            [39.35128492, -8.0276323],
                            [39.35106624, -8.02785103],
                            [39.35071635, -8.02833224],
                            [39.35033366, -8.02884625],
                            [39.35003843, -8.0291306],
                            [39.34968854, -8.02946963],
                            [39.34931677, -8.02979774],
                            [39.34894502, -8.03014771],
                            [39.34867165, -8.03044298],
                            [39.34843111, -8.03067265],
                            [39.34824523, -8.03094607],
                            [39.34813589, -8.03127416],
                            [39.34805934, -8.03158039],
                            [39.34802653, -8.03183192],
                            [39.34800467, -8.03206161],
                            [39.34779691, -8.03220377],
                            [39.34764384, -8.03235688],
                            [39.34764384, -8.03252094],
                            [39.34780785, -8.03273967],
                            [39.34796094, -8.03285997],
                            [39.34813588, -8.0329912],
                            [39.34829989, -8.03310058],
                            [39.34848577, -8.03311151],
                            [39.34863885, -8.03307871],
                            [39.3488794, -8.0330459],
                            [39.34911995, -8.0330459],
                            [39.34930582, -8.03312245],
                            [39.34940424, -8.03326463],
                            [39.3492949, -8.03343962],
                            [39.34908714, -8.03354898],
                            [39.34895592, -8.03366929],
                            [39.3489778, -8.03386613],
                            [39.34911995, -8.0339755],
                            [39.34928396, -8.03408488],
                            [39.34946984, -8.03416143],
                            [39.34959012, -8.03408488],
                            [39.34969946, -8.03392082],
                            [39.34986347, -8.03375677],
                            [39.35006027, -8.03353805],
                            [39.35033364, -8.0331662],
                            [39.35053044, -8.03298029],
                            [39.35067259, -8.03289279],
                            [39.35081473, -8.03285999],
                            [39.35088034, -8.03289279],
                            [39.35100061, -8.03299121],
                            [39.35123023, -8.03312246],
                            [39.35140517, -8.0332537],
                            [39.35155826, -8.03341775],
                            [39.35174413, -8.03363648],
                            [39.35193001, -8.03388803],
                            [39.35209402, -8.03410675],
                            [39.35212683, -8.03428174],
                            [39.35212683, -8.03458797],
                            [39.35208308, -8.0348067],
                            [39.35197374, -8.03500356],
                            [39.35185346, -8.03526603],
                            [39.35167851, -8.03552852],
                            [39.35153637, -8.03588941],
                            [39.3514161, -8.03619564],
                            [39.35128488, -8.03655655],
                            [39.35123021, -8.03681902],
                            [39.35119741, -8.03708151],
                            [39.35121927, -8.03738774],
                            [39.35124114, -8.03766115],
                            [39.35124114, -8.03792362],
                            [39.35124114, -8.03817516],
                            [39.35119741, -8.03836109],
                            [39.35104432, -8.03849233],
                            [39.35091312, -8.03862356],
                            [39.3508147, -8.0387548],
                            [39.35080378, -8.03891885],
                            [39.35083658, -8.03909383],
                            [39.35092404, -8.03921414],
                            [39.35104432, -8.03938912],
                            [39.35104432, -8.03957505],
                            [39.35094591, -8.03973909],
                            [39.35085845, -8.04003438],
                            [39.35076003, -8.04029685],
                            [39.35076003, -8.04052652],
                            [39.3507819, -8.0407562],
                            [39.35077096, -8.04094211],
                            [39.35076002, -8.04110616],
                            [39.35067672, -8.04123275],
                            [39.35054824, -8.04138471],
                            [39.35036378, -8.04148238],
                            [39.35017933, -8.0415258],
                            [39.34997317, -8.0416343],
                            [39.34977786, -8.04180796],
                            [39.34961512, -8.04201417],
                            [39.34949576, -8.04226377],
                            [39.34947406, -8.04254595],
                            [39.34949576, -8.04275215],
                            [39.34957171, -8.04292579],
                            [39.34973447, -8.04304517],
                            [39.34987551, -8.04317541],
                            [39.34997316, -8.0433599],
                            [39.35002742, -8.0435227],
                            [39.34997316, -8.0439351],
                            [39.34994061, -8.04436921],
                            [39.3498972, -8.04492272],
                            [39.3498972, -8.04518316],
                            [39.34982125, -8.04534597],
                            [39.34969105, -8.04549789],
                            [39.34950659, -8.04562812],
                            [39.34933299, -8.04575836],
                            [39.34924618, -8.04596457],
                            [39.34919193, -8.04619247],
                            [39.34923533, -8.04640953],
                            [39.34930044, -8.04669171],
                            [39.34934383, -8.04700644],
                            [39.34931128, -8.04732115],
                            [39.34912683, -8.04755991],
                            [39.34880131, -8.04776612],
                            [39.3483456, -8.04790721],
                            [39.34796585, -8.04795061],
                            [39.34768373, -8.04800488],
                            [39.34745588, -8.04812426],
                            [39.34728226, -8.04828704],
                            [39.34720631, -8.04842813],
                            [39.34719545, -8.04856922],
                            [39.34727142, -8.0486886],
                            [39.34730396, -8.04884053],
                            [39.34730396, -8.04899247],
                        ],
                        [
                            [39.34281877, -8.03597184],
                            [39.34252011, -8.03589714],
                            [39.34223213, -8.03580114],
                            [39.3420828, -8.03579048],
                            [39.34199746, -8.03586515],
                            [39.34199746, -8.03601452],
                            [39.34209346, -8.0361532],
                            [39.34224279, -8.0362919],
                            [39.34240279, -8.03647326],
                            [39.34249878, -8.03659063],
                            [39.34247744, -8.03676133],
                            [39.34238144, -8.03698537],
                            [39.34220012, -8.03722007],
                            [39.34197613, -8.03733744],
                            [39.34174147, -8.03736943],
                            [39.34144281, -8.03735877],
                            [39.34118682, -8.03735877],
                            [39.34097348, -8.03736942],
                            [39.34078149, -8.03752947],
                            [39.34068551, -8.03770015],
                            [39.34061084, -8.03790286],
                            [39.34052121, -8.03832961],
                            [39.34047218, -8.03828692],
                            [39.34035484, -8.0388097],
                            [39.34022685, -8.03901241],
                            [39.34010952, -8.03910843],
                            [39.33998153, -8.03905508],
                            [39.33986417, -8.03899106],
                            [39.33971487, -8.03899106],
                            [39.3396082, -8.03905507],
                            [39.3395122, -8.03924711],
                            [39.33945888, -8.0394925],
                            [39.3393842, -8.03992991],
                            [39.33930955, -8.04031398],
                            [39.33924555, -8.04068739],
                            [39.33916021, -8.04103946],
                            [39.33901089, -8.04127417],
                            [39.33878689, -8.04157288],
                            [39.3385629, -8.04174358],
                            [39.33847756, -8.04192496],
                            [39.33844556, -8.04221301],
                            [39.33844556, -8.04242637],
                            [39.3385949, -8.0426611],
                            [39.33869089, -8.04286379],
                            [39.33874422, -8.0430665],
                            [39.33873354, -8.04326921],
                            [39.33860555, -8.04346125],
                            [39.33862689, -8.04368529],
                            [39.33868022, -8.04385599],
                            [39.33875488, -8.04401602],
                            [39.33890421, -8.04415471],
                            [39.33904286, -8.04427207],
                            [39.33928819, -8.04430408],
                            [39.33948018, -8.04434674],
                            [39.33965085, -8.04438942],
                            [39.33977884, -8.04448544],
                            [39.33992816, -8.04464547],
                            [39.3400455, -8.04479484],
                            [39.34020549, -8.04486952],
                            [39.34040814, -8.04492287],
                            [39.34071747, -8.04491221],
                            [39.34087745, -8.04486952],
                            [39.34100546, -8.0447415],
                            [39.34119746, -8.04462415],
                            [39.34147477, -8.04443211],
                            [39.34186943, -8.04417605],
                            [39.34222142, -8.04381333],
                            [39.34269075, -8.04339725],
                            [39.34301074, -8.04300251],
                            [39.34337339, -8.04257576],
                            [39.34380004, -8.04209567],
                            [39.34405603, -8.04180762],
                            [39.34428002, -8.04170093],
                            [39.34440803, -8.04160491],
                            [39.34444003, -8.04145556],
                            [39.34447203, -8.04118883],
                            [39.34447203, -8.04106081],
                            [39.34461068, -8.04092213],
                            [39.34477068, -8.04069809],
                            [39.34494134, -8.04044203],
                            [39.34499467, -8.04027134],
                            [39.34495202, -8.04000462],
                            [39.34494134, -8.03971656],
                            [39.34500534, -8.03951385],
                            [39.34512267, -8.03935382],
                            [39.34524001, -8.03914046],
                            [39.34525066, -8.03892707],
                            [39.34525067, -8.0386817],
                            [39.34531466, -8.03839365],
                            [39.34541066, -8.03814827],
                            [39.34545333, -8.03788155],
                            [39.34544266, -8.03765751],
                            [39.34542134, -8.03746549],
                            [39.34527201, -8.03729477],
                            [39.34504802, -8.03712407],
                            [39.34485603, -8.03697471],
                            [39.3446747, -8.03684669],
                            [39.3445467, -8.03676134],
                            [39.34450405, -8.03661197],
                            [39.34450405, -8.03643061],
                            [39.34447205, -8.03621722],
                            [39.34438671, -8.03606787],
                            [39.34429071, -8.03596118],
                            [39.34417339, -8.03591851],
                            [39.34403473, -8.03590784],
                            [39.3438854, -8.03597185],
                            [39.34366141, -8.03603586],
                            [39.34336274, -8.03603586],
                            [39.34308542, -8.03603586],
                            [39.34281877, -8.03597184],
                        ],
                        [
                            [39.30652027, -8.03584271],
                            [39.30691764, -8.03543054],
                            [39.30716783, -8.03520974],
                            [39.30752104, -8.03494477],
                            [39.30771237, -8.03466507],
                            [39.30791841, -8.03437068],
                            [39.30831577, -8.03420874],
                            [39.30887503, -8.03401738],
                            [39.30943428, -8.03392905],
                            [39.30999354, -8.03388491],
                            [39.31036147, -8.03394378],
                            [39.31053808, -8.03384075],
                            [39.31034676, -8.0335905],
                            [39.310126, -8.03351689],
                            [39.30986109, -8.03334024],
                            [39.30958146, -8.03311944],
                            [39.30925768, -8.03283974],
                            [39.30908108, -8.03270726],
                            [39.30875729, -8.03272197],
                            [39.30847766, -8.03278085],
                            [39.30821277, -8.03298694],
                            [39.30788898, -8.03323718],
                            [39.30721199, -8.03345799],
                            [39.30677048, -8.03359048],
                            [39.30629951, -8.03372296],
                            [39.30594631, -8.03379656],
                            [39.30553423, -8.03364936],
                            [39.30521045, -8.03339911],
                            [39.30498971, -8.03307525],
                            [39.30488667, -8.03278084],
                            [39.30471006, -8.03250114],
                            [39.30457762, -8.03236867],
                            [39.30438628, -8.03220674],
                            [39.30410667, -8.03200064],
                            [39.30388591, -8.03176511],
                            [39.30356213, -8.03139711],
                            [39.3032825, -8.03113213],
                            [39.30287043, -8.0308966],
                            [39.30253192, -8.03069053],
                            [39.30222286, -8.03042554],
                            [39.30195796, -8.03007224],
                            [39.30184022, -8.02967479],
                            [39.30176663, -8.02932151],
                            [39.30160475, -8.02911541],
                            [39.3015606, -8.02883573],
                            [39.3015606, -8.02854132],
                            [39.30170778, -8.02833523],
                            [39.30170778, -8.02811443],
                            [39.30150173, -8.02796721],
                            [39.30120739, -8.02784945],
                            [39.30100136, -8.02761392],
                            [39.3010455, -8.02733423],
                            [39.30103078, -8.02706926],
                            [39.30094249, -8.02677485],
                            [39.30107494, -8.02653932],
                            [39.30117797, -8.02623018],
                            [39.30116325, -8.02589162],
                            [39.30092777, -8.02583273],
                            [39.30067758, -8.0258769],
                            [39.3005157, -8.0257444],
                            [39.3003538, -8.02555304],
                            [39.30023607, -8.02530279],
                            [39.30010361, -8.02524392],
                            [39.29994172, -8.02528806],
                            [39.29979456, -8.02540584],
                            [39.29951493, -8.02549416],
                            [39.29922058, -8.02536167],
                            [39.29907341, -8.02527334],
                            [39.2988085, -8.02521447],
                            [39.29860245, -8.02497894],
                            [39.29842585, -8.02462564],
                            [39.29821981, -8.02425763],
                            [39.29808736, -8.02393377],
                            [39.29799906, -8.02352162],
                            [39.29788131, -8.0233008],
                            [39.29768999, -8.02310942],
                            [39.29746924, -8.02291805],
                            [39.29720434, -8.02272669],
                            [39.29701299, -8.0225206],
                            [39.29685112, -8.02227035],
                            [39.2967481, -8.02204956],
                            [39.29677755, -8.02172569],
                            [39.29693942, -8.02154904],
                            [39.29711604, -8.02128407],
                            [39.29732208, -8.02104855],
                            [39.29742509, -8.02075413],
                            [39.29746925, -8.020445],
                            [39.29770472, -8.02022419],
                            [39.29774888, -8.01997394],
                            [39.29754284, -8.01969427],
                            [39.29732208, -8.01951761],
                            [39.29708661, -8.01934095],
                            [39.29704246, -8.01919376],
                            [39.29708924, -8.01920699],
                            [39.29665982, -8.01898768],
                            [39.29655679, -8.01884046],
                            [39.29627716, -8.01878157],
                            [39.29595338, -8.01881102],
                            [39.29579149, -8.01898767],
                            [39.29561489, -8.01903182],
                            [39.29542357, -8.01920848],
                            [39.29540885, -8.01956176],
                            [39.29540885, -8.02006226],
                            [39.29540884, -8.02066581],
                            [39.29534997, -8.0210927],
                            [39.29530583, -8.02191704],
                            [39.29533524, -8.02253531],
                            [39.29564431, -8.02352161],
                            [39.29583562, -8.02411041],
                            [39.29614469, -8.02500837],
                            [39.29639488, -8.02584745],
                            [39.29677753, -8.02670124],
                            [39.29721902, -8.02776111],
                            [39.2975428, -8.02815856],
                            [39.29829338, -8.02908597],
                            [39.29889678, -8.02957175],
                            [39.29970623, -8.03014584],
                            [39.30035378, -8.03058746],
                            [39.30089832, -8.03088187],
                            [39.30114851, -8.03108796],
                            [39.30147229, -8.03138238],
                            [39.3017519, -8.03175038],
                            [39.30184021, -8.0320448],
                            [39.30201681, -8.03236865],
                            [39.30225229, -8.03272194],
                            [39.30251719, -8.03370822],
                            [39.3026055, -8.03440008],
                            [39.30263493, -8.03535692],
                            [39.30262021, -8.03618127],
                            [39.30260549, -8.03693202],
                            [39.30260549, -8.03734419],
                            [39.30270848, -8.03777108],
                            [39.30289983, -8.03813911],
                            [39.30306171, -8.03825686],
                            [39.30353266, -8.03844824],
                            [39.30397418, -8.03865433],
                            [39.30438625, -8.03861017],
                            [39.3048572, -8.03846297],
                            [39.30515155, -8.03812439],
                            [39.30546062, -8.03759445],
                            [39.30571082, -8.03710868],
                            [39.30593158, -8.03663762],
                            [39.30616705, -8.03624017],
                            [39.30652027, -8.03584271],
                        ],
                        [
                            [39.33499488, -8.02131476],
                            [39.33511186, -8.02157478],
                            [39.33511186, -8.0218608],
                            [39.33508587, -8.02205582],
                            [39.3348909, -8.02243284],
                            [39.33472192, -8.02275787],
                            [39.33463093, -8.0232389],
                            [39.33460493, -8.02371992],
                            [39.33459193, -8.02435698],
                            [39.33465691, -8.02483801],
                            [39.33473491, -8.02516303],
                            [39.33470891, -8.02542305],
                            [39.33457893, -8.02570907],
                            [39.33455296, -8.02595609],
                            [39.3346959, -8.02648912],
                            [39.33494289, -8.02708717],
                            [39.33511183, -8.0275422],
                            [39.33538479, -8.02778923],
                            [39.33560577, -8.02795823],
                            [39.33574874, -8.02811424],
                            [39.33574874, -8.02837426],
                            [39.33574874, -8.02897231],
                            [39.33577474, -8.02942735],
                            [39.33589172, -8.02960935],
                            [39.3360087, -8.02967436],
                            [39.33609968, -8.02979135],
                            [39.33612567, -8.02998637],
                            [39.33602168, -8.03016839],
                            [39.33568374, -8.03038942],
                            [39.33541078, -8.03050642],
                            [39.33521582, -8.03068842],
                            [39.33515082, -8.03092245],
                            [39.33520281, -8.03128647],
                            [39.33543679, -8.0317935],
                            [39.33554075, -8.03222254],
                            [39.33557975, -8.03246955],
                            [39.33552775, -8.03272957],
                            [39.33531979, -8.0330676],
                            [39.33518981, -8.03337963],
                            [39.33511181, -8.03365264],
                            [39.33511181, -8.03405567],
                            [39.3351508, -8.03431569],
                            [39.33504681, -8.0346277],
                            [39.33505982, -8.03492672],
                            [39.3351898, -8.03519974],
                            [39.33539776, -8.03531675],
                            [39.33572271, -8.03531675],
                            [39.33596967, -8.03512175],
                            [39.33615165, -8.03490072],
                            [39.33617765, -8.03461472],
                            [39.33613865, -8.0343417],
                            [39.33611266, -8.03404267],
                            [39.33611266, -8.03382165],
                            [39.33613866, -8.03361364],
                            [39.33621664, -8.03350964],
                            [39.33641163, -8.03352264],
                            [39.33658059, -8.03365265],
                            [39.33674955, -8.03400367],
                            [39.33681455, -8.0343287],
                            [39.33686655, -8.03457573],
                            [39.33700952, -8.03461472],
                            [39.33721749, -8.03456271],
                            [39.33734747, -8.03425069],
                            [39.33751645, -8.03396467],
                            [39.3377764, -8.03373065],
                            [39.33795838, -8.03337964],
                            [39.33795839, -8.03296361],
                            [39.33793239, -8.03220954],
                            [39.33794539, -8.03178051],
                            [39.33810137, -8.0314425],
                            [39.33829633, -8.03097446],
                            [39.33863429, -8.03036342],
                            [39.33888125, -8.02993439],
                            [39.33899823, -8.02957037],
                            [39.33897224, -8.02872531],
                            [39.33897224, -8.02821826],
                            [39.33892026, -8.02788024],
                            [39.33881626, -8.02769823],
                            [39.3386213, -8.02752921],
                            [39.33841333, -8.0273342],
                            [39.33829635, -8.02711319],
                            [39.33823137, -8.02660615],
                            [39.33828335, -8.02615112],
                            [39.33830936, -8.02560509],
                            [39.33841334, -8.02521504],
                            [39.33846532, -8.02492904],
                            [39.33855632, -8.02472102],
                            [39.3387123, -8.02463002],
                            [39.33890727, -8.02464302],
                            [39.33903725, -8.024812],
                            [39.33920621, -8.02512405],
                            [39.33924523, -8.02550107],
                            [39.33920621, -8.0258391],
                            [39.33919322, -8.02617713],
                            [39.33908923, -8.02650215],
                            [39.33914122, -8.02681417],
                            [39.3393232, -8.02708719],
                            [39.33951816, -8.0271912],
                            [39.33983013, -8.0270872],
                            [39.34015506, -8.02691818],
                            [39.34036304, -8.02672316],
                            [39.34050601, -8.02660616],
                            [39.34070098, -8.02660616],
                            [39.34105193, -8.02677517],
                            [39.34129887, -8.02691819],
                            [39.34163684, -8.02690518],
                            [39.34197478, -8.02676219],
                            [39.34232574, -8.02671018],
                            [39.34272868, -8.02673617],
                            [39.34307961, -8.02685318],
                            [39.34333957, -8.0270222],
                            [39.34365153, -8.02741223],
                            [39.34393748, -8.02771125],
                            [39.34424942, -8.02794526],
                            [39.34453538, -8.02807527],
                            [39.34487334, -8.02801028],
                            [39.34505531, -8.02768525],
                            [39.3451333, -8.02729523],
                            [39.34545825, -8.02703521],
                            [39.34579619, -8.0268662],
                            [39.34599117, -8.02664519],
                            [39.34595218, -8.02630715],
                            [39.34593918, -8.02598213],
                            [39.34600418, -8.02568312],
                            [39.34612116, -8.0253321],
                            [39.34612116, -8.02502007],
                            [39.34601716, -8.02477306],
                            [39.34608218, -8.02434402],
                            [39.34627713, -8.024058],
                            [39.34627713, -8.02375898],
                            [39.34617315, -8.02335595],
                            [39.34608218, -8.02292692],
                            [39.34613417, -8.0225889],
                            [39.34626415, -8.02235487],
                            [39.34642013, -8.02208186],
                            [39.34635513, -8.02174384],
                            [39.34614717, -8.02140581],
                            [39.3459652, -8.0212108],
                            [39.3459652, -8.02093778],
                            [39.34605618, -8.02053475],
                            [39.34616016, -8.02009272],
                            [39.34621216, -8.0197937],
                            [39.34647214, -8.01945568],
                            [39.34696606, -8.01890964],
                            [39.347265, -8.01851962],
                            [39.347265, -8.0182206],
                            [39.34723902, -8.01793457],
                            [39.34705706, -8.01777856],
                            [39.34683607, -8.01777856],
                            [39.34657609, -8.01783057],
                            [39.34636815, -8.01783057],
                            [39.34618617, -8.01771354],
                            [39.34596521, -8.01758352],
                            [39.34566626, -8.01759654],
                            [39.34531532, -8.01760954],
                            [39.34487338, -8.01776555],
                            [39.34445744, -8.01794756],
                            [39.34402851, -8.01809057],
                            [39.34376855, -8.01816856],
                            [39.34343059, -8.01820758],
                            [39.34309265, -8.01828557],
                            [39.34284569, -8.01848059],
                            [39.34272871, -8.01864962],
                            [39.34268971, -8.01887062],
                            [39.34267671, -8.01911763],
                            [39.34259873, -8.01937765],
                            [39.34246873, -8.01957267],
                            [39.34224777, -8.01970268],
                            [39.34192282, -8.01970268],
                            [39.34163687, -8.01970267],
                            [39.34128592, -8.01970267],
                            [39.34106496, -8.01957266],
                            [39.34096275, -8.01919359],
                            [39.34061181, -8.01916852],
                            [39.34040396, -8.01902001],
                            [39.34010702, -8.0187824],
                            [39.33983976, -8.01845569],
                            [39.33978038, -8.01809928],
                            [39.33978038, -8.01777254],
                            [39.33981007, -8.01744584],
                            [39.34001793, -8.01714882],
                            [39.34004147, -8.01674859],
                            [39.33975217, -8.01656944],
                            [39.33946621, -8.01645244],
                            [39.33907627, -8.01630941],
                            [39.33872533, -8.01619242],
                            [39.3383484, -8.0161274],
                            [39.33801044, -8.0160494],
                            [39.33771148, -8.01599739],
                            [39.33734755, -8.01598439],
                            [39.33707459, -8.01601039],
                            [39.33674963, -8.01601039],
                            [39.33635969, -8.01598438],
                            [39.33604775, -8.01597138],
                            [39.33580078, -8.01597138],
                            [39.33560582, -8.01610139],
                            [39.33541084, -8.01638741],
                            [39.33531986, -8.01669942],
                            [39.33528086, -8.01699846],
                            [39.33528086, -8.01732347],
                            [39.33522887, -8.01770051],
                            [39.33511187, -8.01809053],
                            [39.33490391, -8.01836355],
                            [39.33468295, -8.01859756],
                            [39.33446196, -8.01890959],
                            [39.33431899, -8.01935161],
                            [39.33426701, -8.01983265],
                            [39.33437099, -8.02031368],
                            [39.33455298, -8.02074271],
                            [39.33482592, -8.02109376],
                            [39.33499488, -8.02131476],
                        ],
                        [
                            [39.29684098, -8.01558056],
                            [39.29677989, -8.01538501],
                            [39.29679211, -8.01514056],
                            [39.2968532, -8.01496945],
                            [39.29695096, -8.01468833],
                            [39.29701206, -8.01423611],
                            [39.29713426, -8.01379611],
                            [39.29736644, -8.01351499],
                            [39.29765971, -8.01320944],
                            [39.29774524, -8.01297721],
                            [39.29774524, -8.0128061],
                            [39.29767193, -8.01262277],
                            [39.29751308, -8.01250054],
                            [39.29731755, -8.01239053],
                            [39.29699983, -8.01229275],
                            [39.29662107, -8.01213386],
                            [39.29627888, -8.01192608],
                            [39.29592453, -8.01164497],
                            [39.29566792, -8.01138829],
                            [39.29544796, -8.01126607],
                            [39.29515469, -8.01120496],
                            [39.29477589, -8.01120496],
                            [39.29450706, -8.01108273],
                            [39.29427488, -8.01099717],
                            [39.29393274, -8.01080161],
                            [39.29361504, -8.01060606],
                            [39.29330953, -8.01047161],
                            [39.29305293, -8.01039827],
                            [39.29284519, -8.01038605],
                            [39.29255581, -8.01023664],
                            [39.29254884, -8.01032032],
                            [39.29250299, -8.01064155],
                            [39.29248005, -8.01096275],
                            [39.29241121, -8.01135279],
                            [39.2924112, -8.01165106],
                            [39.29236534, -8.01217875],
                            [39.29236534, -8.0127294],
                            [39.29234242, -8.01316534],
                            [39.29231945, -8.01364715],
                            [39.29227357, -8.01408309],
                            [39.29225062, -8.01435841],
                            [39.29225062, -8.01470053],
                            [39.29256412, -8.01474944],
                            [39.29274741, -8.01477388],
                            [39.29294293, -8.01483498],
                            [39.29315066, -8.01494499],
                            [39.29349282, -8.0151161],
                            [39.29393272, -8.01533611],
                            [39.2942993, -8.01560501],
                            [39.29453148, -8.01591057],
                            [39.29476365, -8.01641168],
                            [39.29491029, -8.01683946],
                            [39.29506914, -8.01725502],
                            [39.29522799, -8.01736503],
                            [39.29541128, -8.01734058],
                            [39.29558235, -8.01715725],
                            [39.29571677, -8.01697391],
                            [39.2958634, -8.0168639],
                            [39.29602226, -8.01679058],
                            [39.29627886, -8.0167417],
                            [39.29642551, -8.01663169],
                            [39.29649883, -8.0164239],
                            [39.29654771, -8.01628946],
                            [39.29666991, -8.01628946],
                            [39.29686541, -8.01624057],
                            [39.29690209, -8.01605724],
                            [39.29688985, -8.01582501],
                            [39.29684098, -8.01558056],
                        ],
                        [
                            [39.27366006, -8.01043546],
                            [39.27366007, -8.00957263],
                            [39.27401527, -8.00881128],
                            [39.27401528, -8.0081007],
                            [39.2738123, -8.00769465],
                            [39.27386305, -8.00723783],
                            [39.2739138, -8.00647652],
                            [39.27376158, -8.00591818],
                            [39.27386306, -8.0054614],
                            [39.27401529, -8.00495383],
                            [39.27376158, -8.00424323],
                            [39.27340638, -8.00327889],
                            [39.27300042, -8.00251755],
                            [39.27244224, -8.00170544],
                            [39.27213141, -8.00128988],
                            [39.27167473, -8.00094727],
                            [39.27136074, -8.00049048],
                            [39.27116094, -8.00014787],
                            [39.27098968, -7.99994802],
                            [39.27076133, -8.00000512],
                            [39.27076133, -8.00031916],
                            [39.2708184, -8.00074741],
                            [39.27093259, -8.00109002],
                            [39.27110386, -8.00140408],
                            [39.2715891, -8.00183234],
                            [39.27215997, -8.00228913],
                            [39.27258812, -8.00286014],
                            [39.27301627, -8.00331695],
                            [39.27318753, -8.0038023],
                            [39.2733017, -8.00434476],
                            [39.27324462, -8.00448751],
                            [39.27293063, -8.0044304],
                            [39.27255955, -8.00411636],
                            [39.27230268, -8.00377375],
                            [39.27204578, -8.00345969],
                            [39.27176034, -8.00323128],
                            [39.27147492, -8.00288869],
                            [39.27118948, -8.00246044],
                            [39.27093258, -8.00203218],
                            [39.27064714, -8.00171812],
                            [39.27027608, -8.00143262],
                            [39.26987647, -8.00117566],
                            [39.26941976, -8.00086162],
                            [39.26930559, -8.00049047],
                            [39.26933414, -8.00009075],
                            [39.26933414, -7.9996625],
                            [39.26933414, -7.99929134],
                            [39.26910579, -7.99917715],
                            [39.26842074, -7.99914859],
                            [39.2676786, -7.99903439],
                            [39.26725044, -7.99869178],
                            [39.26647976, -7.99826354],
                            [39.26576618, -7.99757832],
                            [39.26536656, -7.99706441],
                            [39.26493841, -7.99655051],
                            [39.26456734, -7.99586529],
                            [39.26419627, -7.99509444],
                            [39.26405356, -7.99449489],
                            [39.26396793, -7.99355274],
                            [39.26396793, -7.99263913],
                            [39.26416775, -7.99161131],
                            [39.26431047, -7.99089757],
                            [39.26462446, -7.99038366],
                            [39.26499552, -7.98998397],
                            [39.26539515, -7.98964136],
                            [39.26568058, -7.98898471],
                            [39.26602312, -7.98815676],
                            [39.26636564, -7.98750009],
                            [39.26693652, -7.98670069],
                            [39.26756449, -7.98604403],
                            [39.26804974, -7.98541593],
                            [39.26873479, -7.98478783],
                            [39.26936275, -7.98450233],
                            [39.27007635, -7.98418829],
                            [39.2705616, -7.98401698],
                            [39.27062023, -7.98396169],
                            [39.27062939, -7.98401666],
                            [39.27072725, -7.98399221],
                            [39.2711369, -7.98382829],
                            [39.27195623, -7.98360975],
                            [39.27239321, -7.98341855],
                            [39.27296674, -7.98325464],
                            [39.27340374, -7.98298147],
                            [39.27373146, -7.98265366],
                            [39.27394995, -7.9822439],
                            [39.27414112, -7.98221658],
                            [39.27435961, -7.98232585],
                            [39.27474197, -7.98232585],
                            [39.27515162, -7.98229854],
                            [39.27561592, -7.98221659],
                            [39.27591634, -7.98197074],
                            [39.27618944, -7.98172489],
                            [39.27638061, -7.98145171],
                            [39.27659912, -7.98117854],
                            [39.27676299, -7.9810966],
                            [39.27706341, -7.98115122],
                            [39.27722727, -7.98106927],
                            [39.2773092, -7.98079611],
                            [39.2773092, -7.98060489],
                            [39.27722727, -7.98038635],
                            [39.27692687, -7.98035903],
                            [39.27673568, -7.9801678],
                            [39.27648988, -7.97994928],
                            [39.27627139, -7.97994928],
                            [39.27586173, -7.98003123],
                            [39.2753155, -7.98022243],
                            [39.27493316, -7.98044097],
                            [39.27449617, -7.98057756],
                            [39.27405919, -7.98057756],
                            [39.27364953, -7.98057755],
                            [39.27345835, -7.98068682],
                            [39.27318524, -7.98082341],
                            [39.27296675, -7.98098731],
                            [39.27266634, -7.98120584],
                            [39.2723386, -7.9814517],
                            [39.27201086, -7.98164293],
                            [39.27173776, -7.98180682],
                            [39.27127347, -7.98207999],
                            [39.27086379, -7.98224388],
                            [39.27051437, -7.98235143],
                            [39.27051437, -7.98237795],
                            [39.27033194, -7.98243009],
                            [39.27008979, -7.98251084],
                            [39.26989605, -7.98255927],
                            [39.26971848, -7.98265614],
                            [39.26954088, -7.98270459],
                            [39.26926643, -7.98270459],
                            [39.2689597, -7.98270459],
                            [39.26876597, -7.98260769],
                            [39.26858837, -7.9825431],
                            [39.26839466, -7.98252697],
                            [39.26815249, -7.98259155],
                            [39.2678619, -7.98270458],
                            [39.26761973, -7.98291451],
                            [39.26742599, -7.98304369],
                            [39.26719998, -7.98310828],
                            [39.2670224, -7.98312442],
                            [39.26681253, -7.98301138],
                            [39.26650578, -7.98278532],
                            [39.26619904, -7.98262384],
                            [39.26577929, -7.98239778],
                            [39.26526269, -7.98223628],
                            [39.26487522, -7.98218785],
                            [39.26458464, -7.98218785],
                            [39.26432633, -7.98220399],
                            [39.26411645, -7.98230088],
                            [39.26397114, -7.98247851],
                            [39.2638743, -7.98263997],
                            [39.26376128, -7.98283376],
                            [39.26366442, -7.98302751],
                            [39.26350297, -7.98326975],
                            [39.26335768, -7.98344736],
                            [39.26316394, -7.98354425],
                            [39.26290563, -7.98367344],
                            [39.26267961, -7.98375418],
                            [39.26248589, -7.98385105],
                            [39.26216301, -7.98388335],
                            [39.26185625, -7.98388335],
                            [39.26163025, -7.98388335],
                            [39.26140422, -7.9838349],
                            [39.26098448, -7.98375417],
                            [39.26074231, -7.98375417],
                            [39.26048403, -7.98378647],
                            [39.26017727, -7.98389949],
                            [39.25988667, -7.98401252],
                            [39.25959609, -7.98425474],
                            [39.25940235, -7.98459385],
                            [39.25917634, -7.98486835],
                            [39.25895032, -7.98525589],
                            [39.25888572, -7.98551425],
                            [39.25878888, -7.98567573],
                            [39.25885344, -7.98590182],
                            [39.2591279, -7.98603099],
                            [39.2594185, -7.98611174],
                            [39.25974137, -7.98625707],
                            [39.25999968, -7.98643469],
                            [39.26030641, -7.98666077],
                            [39.26062929, -7.98687068],
                            [39.26095217, -7.98703217],
                            [39.26129122, -7.98716135],
                            [39.26177554, -7.9872098],
                            [39.26206612, -7.98716135],
                            [39.26240516, -7.98709676],
                            [39.26277647, -7.98696759],
                            [39.26316392, -7.98677382],
                            [39.26353524, -7.98651544],
                            [39.26376126, -7.98628939],
                            [39.26401956, -7.98614405],
                            [39.26429402, -7.9860956],
                            [39.26452004, -7.98611176],
                            [39.26458462, -7.98627325],
                            [39.26460076, -7.98648316],
                            [39.26447161, -7.98662848],
                            [39.26422944, -7.98685455],
                            [39.26392269, -7.98708063],
                            [39.26361597, -7.98733899],
                            [39.26338994, -7.98756506],
                            [39.26321235, -7.98780726],
                            [39.26309934, -7.98808179],
                            [39.26303478, -7.98838858],
                            [39.26295405, -7.98871155],
                            [39.26284104, -7.98898605],
                            [39.26266345, -7.98913138],
                            [39.26237285, -7.9894059],
                            [39.26211455, -7.98958352],
                            [39.26192082, -7.98964811],
                            [39.26164638, -7.9897127],
                            [39.26142034, -7.98969655],
                            [39.26116204, -7.98956737],
                            [39.26090375, -7.98945434],
                            [39.26064543, -7.98938973],
                            [39.26032256, -7.98937358],
                            [39.25998352, -7.98935745],
                            [39.25941848, -7.98948662],
                            [39.25906331, -7.98956736],
                            [39.25875657, -7.98972885],
                            [39.25838524, -7.98997105],
                            [39.25799779, -7.99016483],
                            [39.25762647, -7.99037474],
                            [39.2572713, -7.99061694],
                            [39.25688384, -7.99082687],
                            [39.25648023, -7.9910368],
                            [39.25617349, -7.99113367],
                            [39.25586676, -7.99131132],
                            [39.25551158, -7.99152121],
                            [39.25541471, -7.99166655],
                            [39.25525328, -7.99189261],
                            [39.25517255, -7.99215098],
                            [39.2551564, -7.99237706],
                            [39.2551887, -7.99266772],
                            [39.25531785, -7.99302297],
                            [39.25551157, -7.99331362],
                            [39.25567302, -7.99358814],
                            [39.25591517, -7.99383036],
                            [39.25615734, -7.99399185],
                            [39.25641564, -7.99407258],
                            [39.25662553, -7.99416946],
                            [39.25689997, -7.99423407],
                            [39.25720671, -7.99428251],
                            [39.25748116, -7.99434711],
                            [39.25780404, -7.99452472],
                            [39.25809464, -7.99479924],
                            [39.25841751, -7.99512219],
                            [39.2585951, -7.995429],
                            [39.2588211, -7.99571968],
                            [39.25901485, -7.99594574],
                            [39.25907942, -7.99615567],
                            [39.25920859, -7.99649476],
                            [39.2594023, -7.99676927],
                            [39.25975747, -7.99696305],
                            [39.26011264, -7.99707608],
                            [39.26054853, -7.99720527],
                            [39.26090372, -7.9973829],
                            [39.26122659, -7.99760896],
                            [39.26143646, -7.99789963],
                            [39.26175935, -7.99827104],
                            [39.26195306, -7.99848095],
                            [39.26246968, -7.99896539],
                            [39.26292172, -7.99941752],
                            [39.26319616, -7.99969203],
                            [39.26347062, -7.99998271],
                            [39.26366435, -8.00027336],
                            [39.26374506, -8.00056402],
                            [39.26374506, -8.0008224],
                            [39.26366434, -8.00108076],
                            [39.26345447, -8.00145215],
                            [39.2632123, -8.0017428],
                            [39.26298629, -8.00211421],
                            [39.26274411, -8.00237257],
                            [39.26250196, -8.00279241],
                            [39.26232436, -8.00314767],
                            [39.26219522, -8.00345446],
                            [39.26203377, -8.00376128],
                            [39.26187232, -8.00418112],
                            [39.26167859, -8.00452022],
                            [39.26143643, -8.00487547],
                            [39.26119427, -8.00510152],
                            [39.26103282, -8.00529531],
                            [39.26080681, -8.00558597],
                            [39.26062921, -8.00592508],
                            [39.26045164, -8.00621573],
                            [39.26030633, -8.00636107],
                            [39.26004803, -8.00649025],
                            [39.25974129, -8.00658712],
                            [39.25935382, -8.00660328],
                            [39.25906324, -8.00653869],
                            [39.25882106, -8.00637719],
                            [39.25861119, -8.00626417],
                            [39.25828831, -8.00624802],
                            [39.2579493, -8.00624802],
                            [39.25761025, -8.00613498],
                            [39.25730353, -8.00586048],
                            [39.25719052, -8.00552135],
                            [39.25704523, -8.00518226],
                            [39.25685149, -8.00481086],
                            [39.25660934, -8.0045525],
                            [39.25630259, -8.00440716],
                            [39.25593128, -8.00429412],
                            [39.25559224, -8.00424567],
                            [39.25526937, -8.00413265],
                            [39.25491421, -8.00395502],
                            [39.2544783, -8.00368051],
                            [39.25420385, -8.0033414],
                            [39.25391327, -8.00293772],
                            [39.25371953, -8.00258245],
                            [39.25346123, -8.0021626],
                            [39.25321908, -8.00183965],
                            [39.25297691, -8.00150056],
                            [39.2528639, -8.00106455],
                            [39.2528316, -8.00059628],
                            [39.2528639, -8.00022488],
                            [39.25302535, -7.99977273],
                            [39.2531545, -7.99938519],
                            [39.25317066, -7.99906224],
                            [39.25304149, -7.99872312],
                            [39.25288005, -7.99848092],
                            [39.25255717, -7.99828714],
                            [39.25226659, -7.99828714],
                            [39.25207285, -7.99833557],
                            [39.25186298, -7.9985132],
                            [39.25157239, -7.99867468],
                            [39.2512495, -7.99880386],
                            [39.25086203, -7.99883614],
                            [39.25050687, -7.9987877],
                            [39.25023242, -7.99865853],
                            [39.24981267, -7.9984486],
                            [39.24953821, -7.99819024],
                            [39.2493445, -7.99794802],
                            [39.24929607, -7.99756046],
                            [39.24929607, -7.99707603],
                            [39.24929607, -7.99668849],
                            [39.24934451, -7.9962525],
                            [39.24936065, -7.99591339],
                            [39.24919921, -7.99560658],
                            [39.24895704, -7.99541281],
                            [39.24881175, -7.99538051],
                            [39.24848886, -7.99528363],
                            [39.24832741, -7.99525134],
                            [39.24814984, -7.99525134],
                            [39.24805297, -7.99534822],
                            [39.24797225, -7.9954774],
                            [39.2479561, -7.99568733],
                            [39.24789152, -7.99591339],
                            [39.24784309, -7.99602642],
                            [39.24779465, -7.99622019],
                            [39.24758478, -7.99638168],
                            [39.24743949, -7.99657546],
                            [39.2472942, -7.99670462],
                            [39.24711661, -7.99683382],
                            [39.2469713, -7.99697914],
                            [39.24680987, -7.99715677],
                            [39.24663227, -7.99738284],
                            [39.24658385, -7.99765735],
                            [39.24658385, -7.99793185],
                            [39.24664842, -7.99814178],
                            [39.24679373, -7.99831941],
                            [39.24710045, -7.99843244],
                            [39.24747176, -7.99846473],
                            [39.2477462, -7.99843245],
                            [39.24798838, -7.99856163],
                            [39.24814983, -7.99881999],
                            [39.2483274, -7.99894917],
                            [39.24844042, -7.99912681],
                            [39.24855343, -7.99927214],
                            [39.24863413, -7.99944976],
                            [39.24863413, -7.99965968],
                            [39.24856957, -7.99985346],
                            [39.24840812, -7.99999877],
                            [39.24827897, -8.00016025],
                            [39.24808524, -8.00030559],
                            [39.24781079, -8.00045092],
                            [39.24753634, -8.0005801],
                            [39.24714887, -8.00070929],
                            [39.24669685, -8.00070929],
                            [39.24629324, -8.00074157],
                            [39.24603492, -8.00075773],
                            [39.24592192, -8.00090304],
                            [39.24572818, -8.00093534],
                            [39.24548603, -8.00087075],
                            [39.24530845, -8.00067698],
                            [39.24505015, -8.0004186],
                            [39.24490484, -8.00022485],
                            [39.24472727, -7.99985345],
                            [39.2446304, -7.99948205],
                            [39.24442052, -7.99941744],
                            [39.24416222, -7.99944975],
                            [39.24388777, -7.99949819],
                            [39.24361332, -7.99949819],
                            [39.24340345, -7.9994336],
                            [39.24314513, -7.9993367],
                            [39.24296755, -7.99914292],
                            [39.24266081, -7.99898145],
                            [39.24241865, -7.9989653],
                            [39.24204733, -7.99883611],
                            [39.24198276, -7.99880382],
                            [39.24180516, -7.99881996],
                            [39.2417406, -7.99902989],
                            [39.24169216, -7.99922366],
                            [39.24162758, -7.9996112],
                            [39.24157915, -7.99983728],
                            [39.24148227, -8.00006333],
                            [39.24138542, -8.00019253],
                            [39.2413047, -8.00046703],
                            [39.24132084, -8.00069311],
                            [39.24146613, -8.00079],
                            [39.24165987, -8.00093532],
                            [39.24178901, -8.0011614],
                            [39.24180515, -8.00140361],
                            [39.2417083, -8.0015651],
                            [39.24169215, -8.00177501],
                            [39.24169215, -8.00198494],
                            [39.241789, -8.00216255],
                            [39.2419666, -8.00235634],
                            [39.24198274, -8.00255009],
                            [39.24204732, -8.00288922],
                            [39.24212805, -8.00313142],
                            [39.24245092, -8.00322832],
                            [39.24290295, -8.00347053],
                            [39.24325813, -8.00364816],
                            [39.24362944, -8.00371275],
                            [39.2438716, -8.00377735],
                            [39.24424291, -8.00390653],
                            [39.24451737, -8.00406801],
                            [39.24482409, -8.00427793],
                            [39.2451147, -8.00469777],
                            [39.24530843, -8.00503688],
                            [39.24559903, -8.0050046],
                            [39.24608334, -8.00502074],
                            [39.24659996, -8.00498844],
                            [39.24719729, -8.00503689],
                            [39.2476009, -8.00503689],
                            [39.24797221, -8.00514991],
                            [39.24803679, -8.0053437],
                            [39.24816592, -8.00563435],
                            [39.24816592, -8.0058286],
                            [39.24833857, -8.00576581],
                            [39.24869379, -8.00586733],
                            [39.24981017, -8.00586734],
                            [39.25082507, -8.00586734],
                            [39.25153547, -8.00612111],
                            [39.25295632, -8.00652718],
                            [39.2544279, -8.00698399],
                            [39.25559503, -8.00744079],
                            [39.25681291, -8.0078976],
                            [39.25777703, -8.00850669],
                            [39.25869043, -8.00901424],
                            [39.25985755, -8.00957257],
                            [39.26122767, -8.01013088],
                            [39.26274999, -8.01073997],
                            [39.26437382, -8.01145055],
                            [39.26533796, -8.01190735],
                            [39.26625136, -8.0123134],
                            [39.26716475, -8.0125672],
                            [39.26797667, -8.01282099],
                            [39.26878857, -8.01312552],
                            [39.26919452, -8.01343004],
                            [39.27015867, -8.0133793],
                            [39.2708691, -8.01337931],
                            [39.27152879, -8.01312553],
                            [39.2724422, -8.01246569],
                            [39.27315261, -8.01155209],
                            [39.27366006, -8.01043546],
                        ],
                        [
                            [39.2868523, -7.98276213],
                            [39.28706952, -7.98301561],
                            [39.28739535, -7.98328719],
                            [39.28773929, -7.98364931],
                            [39.28804703, -7.98401144],
                            [39.28828234, -7.98435546],
                            [39.28857197, -7.98471757],
                            [39.28875298, -7.98489864],
                            [39.28900641, -7.98498916],
                            [39.28949517, -7.98502539],
                            [39.28994771, -7.98500727],
                            [39.29038215, -7.98475379],
                            [39.29061749, -7.9844641],
                            [39.29072611, -7.98410195],
                            [39.29063558, -7.98377606],
                            [39.29041836, -7.98332342],
                            [39.29029165, -7.9829613],
                            [39.29020115, -7.98250866],
                            [39.29007444, -7.98209222],
                            [39.29005635, -7.9817482],
                            [39.28983911, -7.98151283],
                            [39.2896219, -7.98124123],
                            [39.28931416, -7.98107828],
                            [39.28902453, -7.98091532],
                            [39.28859008, -7.9805894],
                            [39.28842717, -7.98044458],
                            [39.28820996, -7.98015488],
                            [39.28793842, -7.97984707],
                            [39.28773931, -7.97953926],
                            [39.28754019, -7.97924955],
                            [39.28728677, -7.97890557],
                            [39.28703334, -7.97863397],
                            [39.28681612, -7.97832618],
                            [39.28656269, -7.97803648],
                            [39.28630926, -7.97769246],
                            [39.28603774, -7.97738465],
                            [39.28580243, -7.97704065],
                            [39.2856214, -7.97667852],
                            [39.28547658, -7.97631641],
                            [39.28524127, -7.97582755],
                            [39.28511456, -7.97544732],
                            [39.28504215, -7.97508521],
                            [39.28504215, -7.9747774],
                            [39.28500595, -7.97434287],
                            [39.28493355, -7.97390832],
                            [39.28493356, -7.97340135],
                            [39.28493356, -7.97303923],
                            [39.28498785, -7.9726047],
                            [39.28507835, -7.97227878],
                            [39.28515077, -7.97209773],
                            [39.28518697, -7.97191667],
                            [39.28506028, -7.9717175],
                            [39.28487925, -7.97162697],
                            [39.28460772, -7.97153644],
                            [39.28439051, -7.9714459],
                            [39.28420948, -7.97135539],
                            [39.28397416, -7.97135538],
                            [39.28364833, -7.97137348],
                            [39.2833225, -7.97148212],
                            [39.28305097, -7.97155453],
                            [39.28288805, -7.97169938],
                            [39.28277944, -7.97186233],
                            [39.28274323, -7.97209772],
                            [39.28268893, -7.97238741],
                            [39.28268893, -7.97267711],
                            [39.28268893, -7.97300301],
                            [39.28265272, -7.97331082],
                            [39.2825803, -7.97347377],
                            [39.2825079, -7.97365482],
                            [39.2824717, -7.97381777],
                            [39.2824717, -7.97394452],
                            [39.28245359, -7.97414368],
                            [39.28241739, -7.97437906],
                            [39.28236311, -7.97456011],
                            [39.28243549, -7.97475929],
                            [39.28265272, -7.97488604],
                            [39.28281563, -7.9750852],
                            [39.28281563, -7.97533868],
                            [39.28267081, -7.97551973],
                            [39.28250789, -7.97564647],
                            [39.28227257, -7.97564647],
                            [39.28200104, -7.97573699],
                            [39.28180192, -7.97586376],
                            [39.28165711, -7.97613533],
                            [39.2816752, -7.97642502],
                            [39.28176571, -7.97666041],
                            [39.28201914, -7.97678715],
                            [39.28232686, -7.97684146],
                            [39.28247168, -7.97698632],
                            [39.28247168, -7.97727601],
                            [39.28250788, -7.97765624],
                            [39.28252599, -7.97801835],
                            [39.282707, -7.97828993],
                            [39.28299663, -7.97839858],
                            [39.28330436, -7.97834426],
                            [39.28348537, -7.9781451],
                            [39.283594, -7.97787353],
                            [39.28372071, -7.9775476],
                            [39.28379312, -7.97731222],
                            [39.28395603, -7.97704064],
                            [39.28408275, -7.97685958],
                            [39.28431807, -7.97678716],
                            [39.28457149, -7.97685958],
                            [39.28473441, -7.97700444],
                            [39.28495163, -7.9772217],
                            [39.28511455, -7.97743897],
                            [39.28513265, -7.97765625],
                            [39.28507833, -7.97789162],
                            [39.28491543, -7.97803647],
                            [39.28478871, -7.97825373],
                            [39.28471631, -7.97854343],
                            [39.2846982, -7.97886935],
                            [39.2846982, -7.97921335],
                            [39.28480681, -7.97966599],
                            [39.28495162, -7.97993759],
                            [39.28522316, -7.98017298],
                            [39.28556708, -7.98035403],
                            [39.28598342, -7.98062562],
                            [39.28612824, -7.98091531],
                            [39.28625495, -7.98106017],
                            [39.28636356, -7.98131365],
                            [39.28638167, -7.98158523],
                            [39.28641785, -7.9818206],
                            [39.28649027, -7.98205601],
                            [39.28667131, -7.9823819],
                            [39.2868523, -7.98276213],
                        ],
                        [
                            [39.24449496, -7.9698389],
                            [39.24430787, -7.96951966],
                            [39.24409874, -7.96923343],
                            [39.24388962, -7.96898024],
                            [39.24359246, -7.96871604],
                            [39.24333932, -7.96846283],
                            [39.24319625, -7.96834173],
                            [39.24302015, -7.96829771],
                            [39.24278903, -7.9682977],
                            [39.2425689, -7.96827568],
                            [39.24235978, -7.9682867],
                            [39.24222771, -7.96839678],
                            [39.24211765, -7.96856191],
                            [39.24208463, -7.96874905],
                            [39.24205235, -7.9690003],
                            [39.24213061, -7.96936038],
                            [39.24217756, -7.96973614],
                            [39.24214627, -7.9700336],
                            [39.24219322, -7.97028413],
                            [39.24225584, -7.97044069],
                            [39.24249063, -7.97055029],
                            [39.24285064, -7.97064423],
                            [39.2431324, -7.97087908],
                            [39.24335154, -7.97120787],
                            [39.24347679, -7.97158363],
                            [39.24344546, -7.97200635],
                            [39.24358632, -7.97253867],
                            [39.2436333, -7.9728518],
                            [39.24362546, -7.97313045],
                            [39.24357043, -7.9734607],
                            [39.24359244, -7.9737249],
                            [39.24361446, -7.97395609],
                            [39.24362546, -7.97429735],
                            [39.24375753, -7.9747487],
                            [39.24393363, -7.97511196],
                            [39.24421977, -7.97534318],
                            [39.24448393, -7.9752661],
                            [39.24473706, -7.97516702],
                            [39.24496818, -7.97506796],
                            [39.24510025, -7.97494686],
                            [39.24512227, -7.97472669],
                            [39.24518831, -7.97447349],
                            [39.2451773, -7.97416526],
                            [39.24523233, -7.97403315],
                            [39.2453644, -7.97395609],
                            [39.24550749, -7.97381297],
                            [39.24563957, -7.97361483],
                            [39.2456946, -7.97338365],
                            [39.24568358, -7.9730534],
                            [39.24557353, -7.97266809],
                            [39.2454745, -7.97227178],
                            [39.24544146, -7.97206263],
                            [39.24529838, -7.97190851],
                            [39.2451443, -7.97173237],
                            [39.24493518, -7.97152319],
                            [39.24478109, -7.97133606],
                            [39.24466004, -7.97117093],
                            [39.24459399, -7.97100581],
                            [39.24456098, -7.97049942],
                            [39.24456098, -7.97015815],
                            [39.24449496, -7.9698389],
                        ],
                        [
                            [39.23943829, -7.97189673],
                            [39.23946958, -7.97219421],
                            [39.23951461, -7.97249445],
                            [39.23992626, -7.97274149],
                            [39.24056469, -7.97267055],
                            [39.24083779, -7.97216629],
                            [39.24075314, -7.97200634],
                            [39.24062791, -7.97183412],
                            [39.24044008, -7.97152098],
                            [39.2405966, -7.97134876],
                            [39.24076879, -7.97109826],
                            [39.2408001, -7.97087907],
                            [39.24059661, -7.97083209],
                            [39.24040877, -7.97083209],
                            [39.24025224, -7.97083209],
                            [39.23987657, -7.97080078],
                            [39.23945393, -7.97073816],
                            [39.23925046, -7.97070684],
                            [39.2392661, -7.97087906],
                            [39.2392661, -7.9710826],
                            [39.23926609, -7.97138007],
                            [39.23936001, -7.97167755],
                            [39.23943829, -7.97189673],
                        ],
                        [
                            [39.29427903, -7.96841237],
                            [39.29490112, -7.96830571],
                            [39.29534548, -7.96807461],
                            [39.29562986, -7.96786125],
                            [39.29591424, -7.96754126],
                            [39.29610976, -7.96720348],
                            [39.29634082, -7.96691902],
                            [39.29673185, -7.96665235],
                            [39.29714066, -7.96636791],
                            [39.29767388, -7.96611902],
                            [39.29820711, -7.96581681],
                            [39.29881141, -7.96560346],
                            [39.29929132, -7.96533679],
                            [39.299789, -7.96503456],
                            [39.30002006, -7.96478567],
                            [39.30025113, -7.96457233],
                            [39.30042887, -7.96434123],
                            [39.30030445, -7.96411011],
                            [39.30009117, -7.96400343],
                            [39.29977123, -7.96386122],
                            [39.29968236, -7.96366565],
                            [39.29970014, -7.9633101],
                            [39.29970014, -7.96288342],
                            [39.29970014, -7.96252786],
                            [39.29962904, -7.96229674],
                            [39.29952238, -7.96210119],
                            [39.29932688, -7.9620834],
                            [39.29913137, -7.96210118],
                            [39.29888254, -7.96213674],
                            [39.29870479, -7.96206563],
                            [39.29865147, -7.96183451],
                            [39.29854483, -7.96165672],
                            [39.29845596, -7.96156783],
                            [39.2981538, -7.96156783],
                            [39.29788719, -7.96179895],
                            [39.2976028, -7.96211896],
                            [39.29737173, -7.96259896],
                            [39.29708735, -7.96315009],
                            [39.29674964, -7.96366564],
                            [39.29641194, -7.96427009],
                            [39.29612754, -7.96483898],
                            [39.29591425, -7.96501677],
                            [39.29557654, -7.96521232],
                            [39.29525662, -7.96530121],
                            [39.29488337, -7.96524787],
                            [39.29459899, -7.96515898],
                            [39.29438568, -7.96514121],
                            [39.29413685, -7.96526566],
                            [39.29387024, -7.96547899],
                            [39.29371027, -7.96578122],
                            [39.29365694, -7.96601232],
                            [39.29363916, -7.96629678],
                            [39.29360362, -7.96656346],
                            [39.29358585, -7.96681235],
                            [39.29358585, -7.96697235],
                            [39.29340811, -7.96681235],
                            [39.2931948, -7.96681235],
                            [39.29298153, -7.96693679],
                            [39.29267937, -7.96718568],
                            [39.29234166, -7.96748791],
                            [39.29202172, -7.96777235],
                            [39.29152405, -7.96819902],
                            [39.2911508, -7.96848348],
                            [39.29081309, -7.96875013],
                            [39.29095527, -7.96885681],
                            [39.2914885, -7.96875014],
                            [39.29212837, -7.96862569],
                            [39.29289265, -7.96859014],
                            [39.29356806, -7.96850124],
                            [39.29427903, -7.96841237],
                        ],
                        [
                            [39.3259477, -7.96467312],
                            [39.32644539, -7.96463993],
                            [39.32686013, -7.96455696],
                            [39.32725828, -7.96459016],
                            [39.32750712, -7.9647063],
                            [39.32795504, -7.96488884],
                            [39.32843613, -7.96492202],
                            [39.32900017, -7.96503818],
                            [39.32954764, -7.96503819],
                            [39.32997896, -7.96503819],
                            [39.33044347, -7.96512114],
                            [39.33065914, -7.96502159],
                            [39.33102411, -7.96478929],
                            [39.33138906, -7.96467314],
                            [39.33175405, -7.96442424],
                            [39.33198629, -7.96407578],
                            [39.33210243, -7.96366094],
                            [39.33206925, -7.96322952],
                            [39.33180381, -7.96288106],
                            [39.33155497, -7.96271512],
                            [39.33127295, -7.96273172],
                            [39.33095773, -7.96273172],
                            [39.33062596, -7.96266534],
                            [39.33039371, -7.96238326],
                            [39.33052642, -7.96205139],
                            [39.33092458, -7.9617859],
                            [39.3312066, -7.96148722],
                            [39.3312232, -7.96120514],
                            [39.33107389, -7.96097283],
                            [39.33074209, -7.96097283],
                            [39.33031076, -7.96098942],
                            [39.32999557, -7.9610226],
                            [39.32968037, -7.96118853],
                            [39.32953106, -7.96140425],
                            [39.32934856, -7.96150381],
                            [39.32909972, -7.96123831],
                            [39.32888407, -7.96085667],
                            [39.32860205, -7.96055799],
                            [39.32827024, -7.96034228],
                            [39.32797164, -7.96032568],
                            [39.32765643, -7.96034227],
                            [39.32739101, -7.96034227],
                            [39.32719194, -7.96022611],
                            [39.32715886, -7.96021473],
                            [39.32714823, -7.96021828],
                            [39.3271397, -7.96020337],
                            [39.32690378, -7.95977073],
                            [39.32687673, -7.95957897],
                            [39.32687673, -7.95929689],
                            [39.32710899, -7.95909778],
                            [39.32732465, -7.95908118],
                            [39.32755691, -7.95911436],
                            [39.32775598, -7.95923052],
                            [39.32800483, -7.95934667],
                            [39.32827026, -7.95933009],
                            [39.32841956, -7.95904799],
                            [39.32855228, -7.95889865],
                            [39.32855228, -7.95863316],
                            [39.32850252, -7.95843405],
                            [39.32865182, -7.95826811],
                            [39.32886749, -7.95838426],
                            [39.32906656, -7.95856679],
                            [39.329332, -7.95858339],
                            [39.32946471, -7.95846723],
                            [39.32944812, -7.95823492],
                            [39.32944812, -7.95795284],
                            [39.32944812, -7.95765416],
                            [39.32941495, -7.95743845],
                            [39.32923246, -7.95717296],
                            [39.32896704, -7.95707339],
                            [39.32866842, -7.95700702],
                            [39.32832005, -7.95700702],
                            [39.32803801, -7.95722273],
                            [39.32775599, -7.95752141],
                            [39.3274242, -7.95775371],
                            [39.327109, -7.95778691],
                            [39.32677721, -7.95782008],
                            [39.32636246, -7.95782008],
                            [39.32594773, -7.95785327],
                            [39.32559935, -7.95796942],
                            [39.32525096, -7.95808558],
                            [39.32490258, -7.95818513],
                            [39.32463716, -7.95815195],
                            [39.32455421, -7.95796941],
                            [39.32452102, -7.95760436],
                            [39.32468692, -7.95723931],
                            [39.32495237, -7.95684108],
                            [39.32528415, -7.95644284],
                            [39.32549982, -7.95611098],
                            [39.32556618, -7.9558123],
                            [39.32556618, -7.9555468],
                            [39.3253837, -7.95534768],
                            [39.32500214, -7.95514856],
                            [39.32450446, -7.955049],
                            [39.3241063, -7.95496603],
                            [39.32365838, -7.95494943],
                            [39.32334319, -7.95513196],
                            [39.32329341, -7.95549701],
                            [39.32311093, -7.95581229],
                            [39.32307774, -7.95611097],
                            [39.32294503, -7.95635986],
                            [39.32274596, -7.95657557],
                            [39.32309433, -7.95697382],
                            [39.32322704, -7.95718953],
                            [39.32344271, -7.95745502],
                            [39.3236252, -7.95782007],
                            [39.32374132, -7.95806898],
                            [39.32382427, -7.95826809],
                            [39.32397357, -7.95858337],
                            [39.32418923, -7.95883226],
                            [39.32443808, -7.95908117],
                            [39.32465374, -7.95939645],
                            [39.32480305, -7.9597615],
                            [39.32490257, -7.95994402],
                            [39.32523437, -7.96042523],
                            [39.32528413, -7.96070732],
                            [39.32538368, -7.96082346],
                            [39.32543344, -7.96093963],
                            [39.3254343, -7.96094206],
                            [39.32546783, -7.96094206],
                            [39.32556615, -7.96113874],
                            [39.32569887, -7.9614872],
                            [39.325815, -7.96210116],
                            [39.32573205, -7.96264873],
                            [39.32578179, -7.96336223],
                            [39.32578179, -7.9639596],
                            [39.32566568, -7.96444081],
                            [39.3259477, -7.96467312],
                        ],
                        [
                            [39.24822717, -7.95852241],
                            [39.24966639, -7.95809973],
                            [39.25126484, -7.95859594],
                            [39.25264284, -7.95881647],
                            [39.25396567, -7.9593678],
                            [39.25534366, -7.95964345],
                            [39.25611532, -7.96036016],
                            [39.25705236, -7.96030505],
                            [39.25859571, -7.95947807],
                            [39.25964296, -7.95969862],
                            [39.26129653, -7.95997427],
                            [39.26228868, -7.95997427],
                            [39.26355643, -7.9593127],
                            [39.26487929, -7.95920244],
                            [39.26587145, -7.95920244],
                            [39.26669823, -7.95881653],
                            [39.26741477, -7.95793443],
                            [39.26741477, -7.95710743],
                            [39.26818645, -7.95639074],
                            [39.2682967, -7.95545351],
                            [39.26906837, -7.95501246],
                            [39.2700054, -7.95501246],
                            [39.27022586, -7.95413035],
                            [39.2708322, -7.95390982],
                            [39.27176921, -7.95379957],
                            [39.27281649, -7.95396497],
                            [39.27413933, -7.95462655],
                            [39.27463542, -7.95512275],
                            [39.27557243, -7.95512276],
                            [39.27667482, -7.95490222],
                            [39.27794255, -7.95429577],
                            [39.27865912, -7.95341369],
                            [39.27926543, -7.95236618],
                            [39.27948591, -7.95104301],
                            [39.27910008, -7.94955446],
                            [39.27865914, -7.94861723],
                            [39.27908158, -7.94815613],
                            [39.27859741, -7.94817343],
                            [39.27706682, -7.94771415],
                            [39.27392912, -7.94710177],
                            [39.27094448, -7.94625975],
                            [39.26933737, -7.9447288],
                            [39.26893133, -7.94427393],
                            [39.26927618, -7.94367337],
                            [39.2695902, -7.94248677],
                            [39.26969487, -7.9418586],
                            [39.26997402, -7.9411955],
                            [39.27028804, -7.93997403],
                            [39.27042762, -7.93924114],
                            [39.27063698, -7.93819415],
                            [39.27021828, -7.93669347],
                            [39.27021828, -7.93561159],
                            [39.27053231, -7.93414582],
                            [39.27098591, -7.93309885],
                            [39.27150926, -7.93247064],
                            [39.27189309, -7.93201695],
                            [39.27210245, -7.93131898],
                            [39.27217221, -7.93030688],
                            [39.27217222, -7.9293297],
                            [39.27231181, -7.92849211],
                            [39.27262583, -7.92782902],
                            [39.27263328, -7.92780225],
                            [39.27217324, -7.92742213],
                            [39.27106874, -7.92604119],
                            [39.2700747, -7.9247707],
                            [39.26891499, -7.92245072],
                            [39.26830752, -7.9206279],
                            [39.26786571, -7.91952314],
                            [39.26753437, -7.91736886],
                            [39.26736872, -7.91554603],
                            [39.26743505, -7.91379584],
                            [39.26821593, -7.91223374],
                            [39.26967357, -7.90947405],
                            [39.27067417, -7.90782051],
                            [39.2706235, -7.90776981],
                            [39.26751485, -7.90950525],
                            [39.26556295, -7.91037295],
                            [39.26397249, -7.91145758],
                            [39.26238204, -7.91275916],
                            [39.26108074, -7.91355457],
                            [39.25970717, -7.91442227],
                            [39.25876734, -7.91543461],
                            [39.25876733, -7.91724235],
                            [39.25905651, -7.91941165],
                            [39.25963484, -7.92172556],
                            [39.25964953, -7.92204898],
                            [39.25970713, -7.92331636],
                            [39.25970712, -7.92490717],
                            [39.26006857, -7.92664262],
                            [39.26028546, -7.92881191],
                            [39.26043002, -7.93054733],
                            [39.26014085, -7.93242738],
                            [39.25969905, -7.93413873],
                            [39.25918496, -7.93568132],
                            [39.25918049, -7.93571261],
                            [39.25908249, -7.93569626],
                            [39.25840639, -7.93591516],
                            [39.25773246, -7.93641186],
                            [39.25723587, -7.9371569],
                            [39.25659741, -7.93733429],
                            [39.25578161, -7.93776004],
                            [39.25482388, -7.93807933],
                            [39.25411449, -7.93836315],
                            [39.25297944, -7.93893081],
                            [39.25227004, -7.93946297],
                            [39.25152516, -7.93981775],
                            [39.25095764, -7.94059827],
                            [39.25070934, -7.94169809],
                            [39.25060292, -7.94262052],
                            [39.25053198, -7.94372036],
                            [39.25056745, -7.94474922],
                            [39.25049649, -7.94584904],
                            [39.25017727, -7.94684244],
                            [39.24939692, -7.94780033],
                            [39.24858109, -7.94808416],
                            [39.24772981, -7.94879373],
                            [39.24705588, -7.94850991],
                            [39.24645288, -7.94797773],
                            [39.24563708, -7.94723268],
                            [39.24460844, -7.94591998],
                            [39.24350889, -7.94414607],
                            [39.2426576, -7.94286884],
                            [39.24198366, -7.94169806],
                            [39.24106143, -7.94105943],
                            [39.24010374, -7.94102397],
                            [39.23946528, -7.94162708],
                            [39.2394298, -7.94297527],
                            [39.23953621, -7.94467821],
                            [39.23903961, -7.94648761],
                            [39.23836569, -7.94726812],
                            [39.23744344, -7.94801316],
                            [39.23627292, -7.94868724],
                            [39.23496051, -7.94943228],
                            [39.23354171, -7.95056757],
                            [39.23269041, -7.95145452],
                            [39.23230023, -7.95248339],
                            [39.23240666, -7.95372513],
                            [39.23283229, -7.95503782],
                            [39.23329339, -7.95642149],
                            [39.23371903, -7.95791156],
                            [39.23435748, -7.95918879],
                            [39.23492502, -7.96043054],
                            [39.23492501, -7.96067274],
                            [39.23499854, -7.96067862],
                            [39.23705635, -7.96009055],
                            [39.23852619, -7.95979651],
                            [39.24029001, -7.95920846],
                            [39.2420538, -7.95920847],
                            [39.24469954, -7.95891445],
                            [39.24822717, -7.95852241],
                        ],
                        [
                            [39.27208723, -7.91699496],
                            [39.27203387, -7.91658136],
                            [39.27196717, -7.91615441],
                            [39.27182045, -7.91564741],
                            [39.27172708, -7.91523381],
                            [39.27160704, -7.91475349],
                            [39.27152699, -7.91419314],
                            [39.27156701, -7.91387292],
                            [39.27167373, -7.91361942],
                            [39.27192717, -7.91337927],
                            [39.27226066, -7.91305905],
                            [39.27260747, -7.9127522],
                            [39.27291426, -7.91248536],
                            [39.27319439, -7.91220518],
                            [39.27360789, -7.91176488],
                            [39.27383466, -7.9115114],
                            [39.27422149, -7.91112447],
                            [39.27448828, -7.9107509],
                            [39.27472838, -7.91048405],
                            [39.27492847, -7.91025725],
                            [39.27520859, -7.90993703],
                            [39.27540867, -7.90968353],
                            [39.27563545, -7.90943003],
                            [39.27584886, -7.90920323],
                            [39.2761023, -7.9090031],
                            [39.27630239, -7.90877629],
                            [39.27644912, -7.90857616],
                            [39.27651582, -7.90821592],
                            [39.27646247, -7.9079224],
                            [39.27640911, -7.9076689],
                            [39.27640911, -7.90748211],
                            [39.27640911, -7.90730866],
                            [39.27648914, -7.90714855],
                            [39.27664921, -7.90696177],
                            [39.27686264, -7.90676164],
                            [39.27706273, -7.90666825],
                            [39.27726282, -7.9066549],
                            [39.2774629, -7.90668159],
                            [39.27759629, -7.90677499],
                            [39.27775636, -7.90692175],
                            [39.27787641, -7.90706851],
                            [39.27804981, -7.9071619],
                            [39.27819654, -7.9071619],
                            [39.27843664, -7.90708186],
                            [39.27870343, -7.90696178],
                            [39.27897022, -7.90681502],
                            [39.27929034, -7.90664156],
                            [39.27969052, -7.90637473],
                            [39.2801307, -7.90600115],
                            [39.28043751, -7.90568095],
                            [39.28074431, -7.90541411],
                            [39.28107778, -7.90510724],
                            [39.28135791, -7.90484041],
                            [39.28157133, -7.90456022],
                            [39.28171805, -7.90433341],
                            [39.28171806, -7.90422667],
                            [39.2816647, -7.90415996],
                            [39.28139793, -7.90415995],
                            [39.2810111, -7.90410658],
                            [39.28065094, -7.90405323],
                            [39.28029078, -7.90405323],
                            [39.27997064, -7.90398651],
                            [39.2796105, -7.90395982],
                            [39.27922367, -7.90393314],
                            [39.27878347, -7.90390644],
                            [39.2782766, -7.90387976],
                            [39.27784974, -7.90387976],
                            [39.27748958, -7.90387976],
                            [39.2771161, -7.90390644],
                            [39.27672927, -7.90394647],
                            [39.27648915, -7.90403986],
                            [39.2762624, -7.90413326],
                            [39.276089, -7.90419997],
                            [39.27588891, -7.90438675],
                            [39.27559544, -7.90466693],
                            [39.27538202, -7.90493378],
                            [39.27516859, -7.90518727],
                            [39.27498185, -7.90546745],
                            [39.27478176, -7.9057076],
                            [39.27462169, -7.90597445],
                            [39.27450163, -7.90613456],
                            [39.27427487, -7.90633469],
                            [39.27408813, -7.90658819],
                            [39.2738747, -7.90686836],
                            [39.2735679, -7.9072953],
                            [39.27287426, -7.90809582],
                            [39.27252745, -7.90848274],
                            [39.27207392, -7.90886966],
                            [39.27168709, -7.90918986],
                            [39.27136696, -7.90948338],
                            [39.27112684, -7.90969686],
                            [39.2707667, -7.91004375],
                            [39.27031317, -7.91059077],
                            [39.26998244, -7.91103473],
                            [39.26949948, -7.91157808],
                            [39.2693394, -7.91172484],
                            [39.26912599, -7.91196499],
                            [39.2689259, -7.91217847],
                            [39.2687925, -7.91240529],
                            [39.26869913, -7.91268547],
                            [39.26867246, -7.91304571],
                            [39.26867246, -7.91343262],
                            [39.26871247, -7.91377952],
                            [39.26875248, -7.91419312],
                            [39.26880584, -7.91448665],
                            [39.26884585, -7.91474015],
                            [39.26897925, -7.91514041],
                            [39.26904593, -7.91552733],
                            [39.26909929, -7.91582085],
                            [39.26911263, -7.91610103],
                            [39.2691393, -7.91638122],
                            [39.2692727, -7.91660803],
                            [39.26947278, -7.91684818],
                            [39.26972623, -7.91698161],
                            [39.27000634, -7.91711503],
                            [39.2702331, -7.91715506],
                            [39.27049988, -7.91715506],
                            [39.27079334, -7.91720843],
                            [39.27104678, -7.9172618],
                            [39.27118017, -7.91739521],
                            [39.27132689, -7.91759534],
                            [39.27142027, -7.91782217],
                            [39.27150031, -7.91803563],
                            [39.27156699, -7.91815572],
                            [39.27171374, -7.91820909],
                            [39.27191382, -7.91814237],
                            [39.27202052, -7.91796893],
                            [39.27203387, -7.91768874],
                            [39.27208723, -7.91730182],
                            [39.27208723, -7.91699496],
                        ],
                        [
                            [39.24434374, -7.90104144],
                            [39.24372166, -7.90079256],
                            [39.24318252, -7.90064045],
                            [39.24264339, -7.900516],
                            [39.24220101, -7.90052983],
                            [39.2417863, -7.90054366],
                            [39.24124716, -7.90068192],
                            [39.24129988, -7.90065709],
                            [39.24062509, -7.90095847],
                            [39.24032096, -7.90115205],
                            [39.24003066, -7.90134562],
                            [39.23980946, -7.90152537],
                            [39.23953299, -7.90167747],
                            [39.23933945, -7.90191253],
                            [39.23924269, -7.90218907],
                            [39.23915974, -7.90245179],
                            [39.23921503, -7.90264537],
                            [39.23928415, -7.90290807],
                            [39.23946386, -7.90315697],
                            [39.23972652, -7.90340586],
                            [39.2405283, -7.90386215],
                            [39.24116421, -7.90419401],
                            [39.24145451, -7.90447055],
                            [39.24163422, -7.90477474],
                            [39.24168951, -7.9050513],
                            [39.24171715, -7.90553523],
                            [39.24192452, -7.90606067],
                            [39.24220099, -7.90644782],
                            [39.24251894, -7.90665524],
                            [39.24291983, -7.90650313],
                            [39.24356958, -7.90624043],
                            [39.24430224, -7.90592241],
                            [39.24495196, -7.90570118],
                            [39.2454358, -7.9052034],
                            [39.24571229, -7.90465032],
                            [39.24593348, -7.90393131],
                            [39.24601643, -7.90337822],
                            [39.24596114, -7.90281132],
                            [39.24572613, -7.9022444],
                            [39.24535288, -7.90180194],
                            [39.24477227, -7.90126268],
                            [39.24434374, -7.90104144],
                        ],
                        [
                            [39.39378938, -7.90562001],
                            [39.39360721, -7.90602085],
                            [39.39360721, -7.90649457],
                            [39.39368008, -7.90696829],
                            [39.39415368, -7.90718693],
                            [39.39477303, -7.90718693],
                            [39.39535593, -7.90693186],
                            [39.39575669, -7.90645815],
                            [39.396121, -7.90580223],
                            [39.39659463, -7.90525563],
                            [39.3970318, -7.90481835],
                            [39.39757828, -7.90470903],
                            [39.39801546, -7.90463616],
                            [39.39867123, -7.90463616],
                            [39.39918128, -7.90470904],
                            [39.39958203, -7.90438109],
                            [39.39983705, -7.90405312],
                            [39.40020136, -7.90427176],
                            [39.40063855, -7.90470904],
                            [39.40096642, -7.90470905],
                            [39.40133075, -7.9043811],
                            [39.40144005, -7.90398026],
                            [39.40147649, -7.90354297],
                            [39.40118503, -7.90303281],
                            [39.40078428, -7.90259553],
                            [39.40023781, -7.90219468],
                            [39.40016495, -7.90204892],
                            [39.40031067, -7.90157521],
                            [39.40060213, -7.90099217],
                            [39.40078429, -7.90051845],
                            [39.40093002, -7.89993541],
                            [39.40103932, -7.89917017],
                            [39.40103932, -7.89858714],
                            [39.40103932, -7.8980041],
                            [39.40103933, -7.89749394],
                            [39.40078431, -7.89712954],
                            [39.40060214, -7.89661938],
                            [39.40016498, -7.89647362],
                            [39.39987352, -7.89621854],
                            [39.39987352, -7.89574481],
                            [39.39980065, -7.89538042],
                            [39.39947277, -7.89494313],
                            [39.39899915, -7.89483381],
                            [39.39856198, -7.89472449],
                            [39.39837982, -7.89443297],
                            [39.3981248, -7.89395925],
                            [39.39779693, -7.89333976],
                            [39.39739618, -7.89348552],
                            [39.39714115, -7.89381349],
                            [39.39710472, -7.89410501],
                            [39.39725044, -7.89443296],
                            [39.39746903, -7.89487025],
                            [39.39739617, -7.8951982],
                            [39.39706827, -7.89527109],
                            [39.39663109, -7.8951982],
                            [39.39637607, -7.8948338],
                            [39.39604819, -7.89436009],
                            [39.39553815, -7.8939228],
                            [39.39506453, -7.8939228],
                            [39.39470022, -7.89395924],
                            [39.39451807, -7.89414143],
                            [39.39429947, -7.89450584],
                            [39.39422661, -7.89490668],
                            [39.39411731, -7.89523463],
                            [39.39397158, -7.89548971],
                            [39.39386229, -7.89592699],
                            [39.39378942, -7.89625494],
                            [39.39378941, -7.89669223],
                            [39.39357083, -7.89709307],
                            [39.3932065, -7.89753035],
                            [39.39317007, -7.89807694],
                            [39.39320649, -7.89855067],
                            [39.39342509, -7.8987693],
                            [39.39371655, -7.89898795],
                            [39.39470019, -7.89938878],
                            [39.39488236, -7.89960743],
                            [39.39502809, -7.89978962],
                            [39.39502809, -7.9000447],
                            [39.39473663, -7.90011759],
                            [39.39415371, -7.90000827],
                            [39.39364367, -7.90000827],
                            [39.39313363, -7.90008114],
                            [39.39262359, -7.90008113],
                            [39.39211354, -7.90037266],
                            [39.39178565, -7.90077349],
                            [39.39145777, -7.90121078],
                            [39.39156706, -7.90168449],
                            [39.39189493, -7.90219465],
                            [39.39229568, -7.90255905],
                            [39.39247785, -7.90285057],
                            [39.3925507, -7.90328786],
                            [39.39244141, -7.90372514],
                            [39.3924414, -7.90412596],
                            [39.39258713, -7.9045268],
                            [39.39287859, -7.90485477],
                            [39.3932429, -7.9051463],
                            [39.39368008, -7.90525562],
                            [39.39378938, -7.90562001],
                        ],
                        [
                            [39.40308178, -7.89646224],
                            [39.40314326, -7.89666722],
                            [39.40340967, -7.89676971],
                            [39.4036146, -7.89685169],
                            [39.40379903, -7.89689268],
                            [39.40404494, -7.89693369],
                            [39.40441382, -7.89691319],
                            [39.40470072, -7.89674921],
                            [39.40502861, -7.89654423],
                            [39.40535649, -7.89623677],
                            [39.40564339, -7.89601131],
                            [39.40580733, -7.89576534],
                            [39.40595078, -7.89549887],
                            [39.40597128, -7.89519141],
                            [39.40599177, -7.89494544],
                            [39.40601227, -7.89463798],
                            [39.40611473, -7.89431003],
                            [39.40611473, -7.89400256],
                            [39.40611474, -7.8936746],
                            [39.40607375, -7.89334664],
                            [39.40603277, -7.89310068],
                            [39.40590981, -7.89285469],
                            [39.4056844, -7.8928342],
                            [39.40541799, -7.89271121],
                            [39.40519256, -7.89271121],
                            [39.40504911, -7.8927727],
                            [39.40494664, -7.8928957],
                            [39.40476221, -7.89301867],
                            [39.40461877, -7.89316215],
                            [39.40453678, -7.89334664],
                            [39.40453678, -7.8935721],
                            [39.4045163, -7.89383857],
                            [39.4044958, -7.89406404],
                            [39.40435235, -7.89418703],
                            [39.40416792, -7.89435101],
                            [39.40408594, -7.89457647],
                            [39.40392199, -7.89461748],
                            [39.40375806, -7.89465847],
                            [39.40357361, -7.89482244],
                            [39.40345066, -7.89508891],
                            [39.40345066, -7.89531439],
                            [39.40369657, -7.89533488],
                            [39.40398347, -7.89535538],
                            [39.40439333, -7.89553986],
                            [39.40439333, -7.89576534],
                            [39.40429086, -7.89588831],
                            [39.40412691, -7.8960113],
                            [39.4039015, -7.8960113],
                            [39.40369657, -7.8960318],
                            [39.40349164, -7.8960728],
                            [39.40322523, -7.89627777],
                            [39.40308178, -7.89646224],
                        ],
                        [
                            [39.32357004, -7.91390294],
                            [39.32313403, -7.91408985],
                            [39.32282259, -7.91429751],
                            [39.32246963, -7.91442211],
                            [39.32222047, -7.91458825],
                            [39.32186751, -7.91465056],
                            [39.32149378, -7.91487899],
                            [39.32126538, -7.91500361],
                            [39.32093319, -7.91510744],
                            [39.32062175, -7.91527358],
                            [39.32078785, -7.91550201],
                            [39.3208086, -7.91550201],
                            [39.3209747, -7.91577198],
                            [39.32122386, -7.91593812],
                            [39.32161835, -7.91604197],
                            [39.32195055, -7.91614581],
                            [39.32213742, -7.91620811],
                            [39.32246962, -7.91620812],
                            [39.32271877, -7.91620812],
                            [39.32305098, -7.91620812],
                            [39.32330012, -7.91620812],
                            [39.32369461, -7.91620812],
                            [39.32406834, -7.91620812],
                            [39.32440055, -7.91608351],
                            [39.32464969, -7.91591737],
                            [39.32489884, -7.91575124],
                            [39.32510648, -7.91558511],
                            [39.32548021, -7.91543974],
                            [39.32585393, -7.91531512],
                            [39.32610309, -7.91516975],
                            [39.32637301, -7.91510746],
                            [39.32670521, -7.91500363],
                            [39.3270374, -7.91500363],
                            [39.32724503, -7.91479595],
                            [39.32736961, -7.91469212],
                            [39.32770181, -7.91469212],
                            [39.32786792, -7.91469212],
                            [39.32797173, -7.91450521],
                            [39.32801326, -7.914256],
                            [39.32820012, -7.9140691],
                            [39.32844927, -7.9140691],
                            [39.32873995, -7.91398603],
                            [39.32898909, -7.91392373],
                            [39.32923826, -7.91384066],
                            [39.32940436, -7.91365375],
                            [39.32917596, -7.91354992],
                            [39.32919673, -7.91334224],
                            [39.32944588, -7.91313457],
                            [39.32973657, -7.91311381],
                            [39.32994419, -7.91296844],
                            [39.33015182, -7.91278153],
                            [39.33035944, -7.91265693],
                            [39.33056707, -7.91244925],
                            [39.33071241, -7.91215851],
                            [39.33096156, -7.91182623],
                            [39.33118995, -7.91153549],
                            [39.33139759, -7.91128629],
                            [39.33148063, -7.91105785],
                            [39.33162597, -7.91082941],
                            [39.33168827, -7.91053866],
                            [39.3316675, -7.90989487],
                            [39.33160521, -7.90954184],
                            [39.33152217, -7.90916802],
                            [39.33141835, -7.90883574],
                            [39.33121073, -7.90858653],
                            [39.33106539, -7.90858653],
                            [39.330837, -7.90875267],
                            [39.33067089, -7.9089811],
                            [39.33042174, -7.90929261],
                            [39.33015183, -7.90966643],
                            [39.32981962, -7.91022715],
                            [39.32942514, -7.91064249],
                            [39.3292798, -7.91091247],
                            [39.32871919, -7.91151472],
                            [39.32828318, -7.91193006],
                            [39.32797173, -7.91230387],
                            [39.32736962, -7.91271922],
                            [39.32680903, -7.91309302],
                            [39.32626919, -7.9133007],
                            [39.32581241, -7.91346684],
                            [39.32541792, -7.91359144],
                            [39.32506495, -7.91359144],
                            [39.32462894, -7.91357067],
                            [39.3243175, -7.91357066],
                            [39.32394378, -7.9137368],
                            [39.32357004, -7.91390294],
                        ],
                        [
                            [39.40990065, -7.91517595],
                            [39.40945496, -7.91534578],
                            [39.40917907, -7.915367],
                            [39.40881827, -7.91549438],
                            [39.40852116, -7.91555805],
                            [39.40816036, -7.91568541],
                            [39.40788445, -7.91587646],
                            [39.40733265, -7.91640715],
                            [39.40716288, -7.91653454],
                            [39.40705676, -7.91666189],
                            [39.40684451, -7.91685294],
                            [39.40678084, -7.91706523],
                            [39.40658985, -7.91731994],
                            [39.40658985, -7.91755344],
                            [39.40658985, -7.91785064],
                            [39.40656863, -7.91810539],
                            [39.40658985, -7.91833888],
                            [39.40671718, -7.91861486],
                            [39.40678084, -7.91912433],
                            [39.40682329, -7.91935782],
                            [39.40686574, -7.91965502],
                            [39.40692354, -7.91994401],
                            [39.40692942, -7.91997342],
                            [39.40703552, -7.92027062],
                            [39.40703552, -7.9205678],
                            [39.40703552, -7.92092868],
                            [39.40703552, -7.92126832],
                            [39.40705674, -7.92150183],
                            [39.40714162, -7.92173535],
                            [39.4073963, -7.92179903],
                            [39.40771465, -7.92203253],
                            [39.40801177, -7.9222448],
                            [39.40856358, -7.92266935],
                            [39.40875457, -7.92290288],
                            [39.40886069, -7.92317882],
                            [39.40909415, -7.92334867],
                            [39.40930637, -7.92322129],
                            [39.40945493, -7.92324251],
                            [39.40968839, -7.92349726],
                            [39.40983695, -7.92362461],
                            [39.4100704, -7.92400672],
                            [39.41017651, -7.9243676],
                            [39.41019774, -7.92472846],
                            [39.41034629, -7.92504688],
                            [39.41043118, -7.92547144],
                            [39.41051606, -7.92591723],
                            [39.41057975, -7.926363],
                            [39.4107283, -7.92691493],
                            [39.41085564, -7.92740316],
                            [39.41100418, -7.92808246],
                            [39.41113153, -7.92848579],
                            [39.4111952, -7.92897402],
                            [39.4113862, -7.92941981],
                            [39.4116621, -7.92912263],
                            [39.41187433, -7.92878299],
                            [39.41210777, -7.92846457],
                            [39.41227758, -7.92816739],
                            [39.41242614, -7.9278702],
                            [39.4126596, -7.92759424],
                            [39.41282937, -7.92740317],
                            [39.41316894, -7.9274244],
                            [39.41344483, -7.92782774],
                            [39.41348728, -7.9282523],
                            [39.41357217, -7.928783],
                            [39.41359338, -7.92922878],
                            [39.41363583, -7.92954718],
                            [39.41365705, -7.92992929],
                            [39.41363583, -7.93031139],
                            [39.4134236, -7.9305449],
                            [39.41312647, -7.93082086],
                            [39.41276569, -7.93082085],
                            [39.4126808, -7.93099068],
                            [39.41265958, -7.93137278],
                            [39.41265958, -7.93190348],
                            [39.41270201, -7.9326889],
                            [39.41265957, -7.93362292],
                            [39.41259588, -7.93449327],
                            [39.41251099, -7.9348966],
                            [39.41251099, -7.93532114],
                            [39.41244732, -7.93576693],
                            [39.41240487, -7.93619149],
                            [39.41227754, -7.9366585],
                            [39.41223509, -7.93710429],
                            [39.41210773, -7.93755007],
                            [39.41198041, -7.93801708],
                            [39.41195917, -7.93856901],
                            [39.41185307, -7.9390148],
                            [39.41187428, -7.93950301],
                            [39.41191673, -7.9399488],
                            [39.4119804, -7.94043704],
                            [39.4120865, -7.94081915],
                            [39.41210772, -7.94120125],
                            [39.41212897, -7.94160459],
                            [39.41212896, -7.94198668],
                            [39.41210771, -7.94228387],
                            [39.41191672, -7.9426872],
                            [39.41185305, -7.9430693],
                            [39.41183183, -7.94351508],
                            [39.41204405, -7.94370614],
                            [39.41227751, -7.94370614],
                            [39.41257463, -7.94359999],
                            [39.41289297, -7.9433665],
                            [39.41314764, -7.94323913],
                            [39.41342355, -7.94319667],
                            [39.41374189, -7.94323914],
                            [39.41416635, -7.94338773],
                            [39.4146757, -7.94347264],
                            [39.41512139, -7.94353632],
                            [39.41543973, -7.94340896],
                            [39.41554586, -7.94306931],
                            [39.41552464, -7.9427509],
                            [39.41537608, -7.94251738],
                            [39.41516384, -7.94247494],
                            [39.4149304, -7.94241125],
                            [39.41450593, -7.94239001],
                            [39.41401779, -7.94239001],
                            [39.41378435, -7.94219897],
                            [39.41391169, -7.94198668],
                            [39.41429371, -7.94205037],
                            [39.41471816, -7.94209282],
                            [39.4148455, -7.94196547],
                            [39.41476061, -7.94168951],
                            [39.41459082, -7.941456],
                            [39.41429371, -7.94126494],
                            [39.41412392, -7.94109513],
                            [39.41412392, -7.94079793],
                            [39.41425126, -7.94064934],
                            [39.41456961, -7.94056444],
                            [39.41486674, -7.94054319],
                            [39.41531242, -7.94071303],
                            [39.41556708, -7.94101021],
                            [39.41554586, -7.94137109],
                            [39.41554586, -7.9416046],
                            [39.41575809, -7.94173197],
                            [39.41605522, -7.94173197],
                            [39.41630989, -7.94156213],
                            [39.41664946, -7.94134986],
                            [39.41696781, -7.94118005],
                            [39.4172437, -7.94118005],
                            [39.41741349, -7.94132864],
                            [39.41764693, -7.94154091],
                            [39.41792285, -7.94164706],
                            [39.41817751, -7.94147723],
                            [39.4184322, -7.94118005],
                            [39.4184322, -7.94084041],
                            [39.41815629, -7.9405432],
                            [39.41790163, -7.9402248],
                            [39.41773183, -7.93994882],
                            [39.41754084, -7.93958797],
                            [39.41730738, -7.93918463],
                            [39.41754084, -7.93884499],
                            [39.41788041, -7.93863272],
                            [39.41800775, -7.93831429],
                            [39.41781672, -7.93810203],
                            [39.41751961, -7.93805956],
                            [39.41722248, -7.93808079],
                            [39.41692537, -7.93808078],
                            [39.41673436, -7.93788973],
                            [39.41671314, -7.9377199],
                            [39.4167768, -7.93744396],
                            [39.41679803, -7.93708308],
                            [39.41684049, -7.93678589],
                            [39.4167768, -7.93648871],
                            [39.41671315, -7.93619151],
                            [39.41652214, -7.93574572],
                            [39.41641604, -7.93519379],
                            [39.41622502, -7.93485417],
                            [39.41609768, -7.9343447],
                            [39.41588546, -7.93400504],
                            [39.41577934, -7.93355925],
                            [39.41567322, -7.93315593],
                            [39.415652, -7.93271014],
                            [39.41565201, -7.93230681],
                            [39.41556712, -7.93181858],
                            [39.41556712, -7.93133034],
                            [39.41556713, -7.93086333],
                            [39.41552469, -7.93050245],
                            [39.41541857, -7.93009914],
                            [39.4151639, -7.92967457],
                            [39.41490922, -7.92941982],
                            [39.41467576, -7.9291651],
                            [39.41450599, -7.92895281],
                            [39.4143362, -7.92867685],
                            [39.41425131, -7.92825231],
                            [39.4142301, -7.92767915],
                            [39.4142301, -7.92725459],
                            [39.41425132, -7.9268088],
                            [39.41425132, -7.92632057],
                            [39.41427256, -7.92585355],
                            [39.41427256, -7.92545024],
                            [39.41408154, -7.92530163],
                            [39.41378442, -7.92521672],
                            [39.41350851, -7.92508936],
                            [39.41335996, -7.92485584],
                            [39.41335996, -7.92447376],
                            [39.41331752, -7.92407042],
                            [39.41331753, -7.9235185],
                            [39.41314772, -7.92296657],
                            [39.41308407, -7.92252078],
                            [39.41308407, -7.9221599],
                            [39.41323265, -7.92188396],
                            [39.41344486, -7.92158677],
                            [39.41367832, -7.92128959],
                            [39.41386933, -7.92105608],
                            [39.41420889, -7.92090749],
                            [39.41454846, -7.92080134],
                            [39.41486682, -7.92094994],
                            [39.41507903, -7.92131082],
                            [39.41527005, -7.92114099],
                            [39.41518515, -7.92080135],
                            [39.41518515, -7.92050415],
                            [39.41517922, -7.92037953],
                            [39.41516394, -7.92005836],
                            [39.41501538, -7.91976118],
                            [39.41484559, -7.91942154],
                            [39.4146758, -7.91903942],
                            [39.41444236, -7.91855119],
                            [39.41412402, -7.91814786],
                            [39.41386934, -7.91770208],
                            [39.41363588, -7.91729875],
                            [39.41350855, -7.91700156],
                            [39.41348733, -7.91674682],
                            [39.41372079, -7.91659823],
                            [39.41393302, -7.91676806],
                            [39.4140179, -7.91657701],
                            [39.41414524, -7.91621613],
                            [39.41416646, -7.91587649],
                            [39.41416646, -7.9154944],
                            [39.41412403, -7.91517597],
                            [39.41395424, -7.91494247],
                            [39.41369958, -7.91468774],
                            [39.41342367, -7.91445424],
                            [39.41312654, -7.91409336],
                            [39.41299921, -7.91373248],
                            [39.41293555, -7.91330791],
                            [39.41272333, -7.91305319],
                            [39.41240497, -7.91301073],
                            [39.41202295, -7.91311686],
                            [39.41181072, -7.91335037],
                            [39.41164095, -7.91358388],
                            [39.41153483, -7.91375369],
                            [39.41153482, -7.91411457],
                            [39.41130138, -7.91441175],
                            [39.41102547, -7.91464526],
                            [39.41066468, -7.91483631],
                            [39.41030389, -7.91498492],
                            [39.40990065, -7.91517595],
                        ],
                        [
                            [39.51161273, -7.93034248],
                            [39.50882503, -7.93094868],
                            [39.5055525, -7.93337357],
                            [39.50354533, -7.93660838],
                            [39.50288597, -7.9385871],
                            [39.50446161, -7.94064828],
                            [39.50640087, -7.9417395],
                            [39.50927117, -7.94196039],
                            [39.50955218, -7.94198201],
                            [39.51152559, -7.94360179],
                            [39.51427913, -7.9458619],
                            [39.51755163, -7.94755935],
                            [39.52155136, -7.950833],
                            [39.52433904, -7.95289419],
                            [39.523733, -7.95556158],
                            [39.52312696, -7.95847147],
                            [39.52215731, -7.96138135],
                            [39.5221573, -7.96332128],
                            [39.52239969, -7.96671615],
                            [39.52239967, -7.9692623],
                            [39.52385411, -7.97168721],
                            [39.52530854, -7.97411213],
                            [39.52676298, -7.97568833],
                            [39.52809624, -7.97459713],
                            [39.52918708, -7.9742334],
                            [39.53100514, -7.9744759],
                            [39.53221719, -7.97411217],
                            [39.53415647, -7.97229351],
                            [39.53706539, -7.96950488],
                            [39.54009553, -7.96550381],
                            [39.5438529, -7.95956281],
                            [39.5457922, -7.95531923],
                            [39.54712547, -7.95180313],
                            [39.54724669, -7.9498632],
                            [39.54627708, -7.9458621],
                            [39.54651951, -7.9421035],
                            [39.5459135, -7.93943609],
                            [39.54530749, -7.93798115],
                            [39.54433786, -7.93701118],
                            [39.54045933, -7.93591994],
                            [39.53876248, -7.93482873],
                            [39.53718682, -7.93434373],
                            [39.53536877, -7.93301002],
                            [39.53391433, -7.93119133],
                            [39.53258109, -7.92973638],
                            [39.53088424, -7.92803894],
                            [39.52906619, -7.92573526],
                            [39.52821777, -7.9245228],
                            [39.52724813, -7.9245228],
                            [39.52603608, -7.92622023],
                            [39.52482402, -7.92816014],
                            [39.52324835, -7.93034255],
                            [39.52155148, -7.93216122],
                            [39.52046063, -7.93276744],
                            [39.51743053, -7.93228244],
                            [39.51440042, -7.93155495],
                            [39.51161273, -7.93034248],
                        ],
                        [
                            [39.4244522, -7.96414786],
                            [39.42416524, -7.96446934],
                            [39.42382088, -7.96499749],
                            [39.42357983, -7.96528451],
                            [39.42333876, -7.96556007],
                            [39.42314364, -7.9658471],
                            [39.42292554, -7.96616858],
                            [39.42274187, -7.96649006],
                            [39.42254674, -7.96681154],
                            [39.42225975, -7.96723633],
                            [39.42201871, -7.96752337],
                            [39.42190391, -7.96777596],
                            [39.42183504, -7.96794818],
                            [39.42189243, -7.96804003],
                            [39.42199574, -7.96822375],
                            [39.42198427, -7.96837298],
                            [39.42185801, -7.96853373],
                            [39.42174321, -7.96869446],
                            [39.42155955, -7.96888965],
                            [39.42141032, -7.96906187],
                            [39.4213644, -7.96924558],
                            [39.42139883, -7.96940631],
                            [39.42153659, -7.96954409],
                            [39.42178911, -7.96962446],
                            [39.42212201, -7.96962446],
                            [39.42232862, -7.96955558],
                            [39.42255819, -7.96946373],
                            [39.42281073, -7.96940632],
                            [39.42300586, -7.96938336],
                            [39.42328135, -7.96937188],
                            [39.42349947, -7.96937188],
                            [39.42364868, -7.96944077],
                            [39.42370607, -7.9695441],
                            [39.42371755, -7.96981966],
                            [39.42371755, -7.97003781],
                            [39.42380938, -7.97015262],
                            [39.4239586, -7.97031335],
                            [39.42423409, -7.9705889],
                            [39.42438927, -7.97074411],
                            [39.42440629, -7.97076112],
                            [39.42452106, -7.97093336],
                            [39.42455548, -7.97111705],
                            [39.42438332, -7.97123187],
                            [39.42432015, -7.97122815],
                            [39.42418817, -7.97122038],
                            [39.42392415, -7.97111705],
                            [39.42372903, -7.97097927],
                            [39.42353388, -7.97085298],
                            [39.42333873, -7.97079558],
                            [39.42315509, -7.97081852],
                            [39.42314362, -7.97105964],
                            [39.42321247, -7.97126631],
                            [39.42337318, -7.97145],
                            [39.42346501, -7.97159926],
                            [39.42356831, -7.9718289],
                            [39.42356831, -7.97203556],
                            [39.42355685, -7.97220778],
                            [39.42335023, -7.97223074],
                            [39.42318952, -7.97211593],
                            [39.42309769, -7.97190927],
                            [39.42293698, -7.97163372],
                            [39.42273037, -7.97133519],
                            [39.42265003, -7.97122037],
                            [39.42244341, -7.97117446],
                            [39.42223679, -7.97117446],
                            [39.42214495, -7.97127779],
                            [39.42205311, -7.97146148],
                            [39.42205311, -7.97171407],
                            [39.42223679, -7.97204702],
                            [39.42230564, -7.97229962],
                            [39.4224434, -7.97249481],
                            [39.42265002, -7.9726785],
                            [39.42289106, -7.97282777],
                            [39.42314361, -7.97296556],
                            [39.42331577, -7.97312629],
                            [39.42338464, -7.97332147],
                            [39.42333872, -7.9734937],
                            [39.42316654, -7.9734937],
                            [39.42304028, -7.9735511],
                            [39.42304028, -7.97376924],
                            [39.42313212, -7.97390701],
                            [39.42335022, -7.97402185],
                            [39.42356831, -7.97411368],
                            [39.42374048, -7.97430887],
                            [39.42387824, -7.97448109],
                            [39.42401597, -7.97473369],
                            [39.4241652, -7.97506664],
                            [39.42431443, -7.97539959],
                            [39.42439477, -7.97568665],
                            [39.42444068, -7.97600811],
                            [39.4244292, -7.97628367],
                            [39.4244292, -7.97651329],
                            [39.42427999, -7.97652479],
                            [39.42410781, -7.97637552],
                            [39.42391266, -7.97627219],
                            [39.42377493, -7.9762033],
                            [39.42361422, -7.97609997],
                            [39.42337316, -7.97597367],
                            [39.42315506, -7.97591626],
                            [39.42297141, -7.9758818],
                            [39.42285661, -7.97577849],
                            [39.42284514, -7.97560625],
                            [39.42284514, -7.97543403],
                            [39.42290253, -7.97522737],
                            [39.42290253, -7.97494035],
                            [39.42287958, -7.9747222],
                            [39.42277627, -7.97454996],
                            [39.42263851, -7.97448109],
                            [39.42251225, -7.97442367],
                            [39.42235155, -7.9744696],
                            [39.42219086, -7.97458442],
                            [39.42215641, -7.97474515],
                            [39.4221564, -7.97500923],
                            [39.4221564, -7.97539959],
                            [39.42229416, -7.97573255],
                            [39.42243189, -7.97599663],
                            [39.4225926, -7.97622626],
                            [39.42277626, -7.97649032],
                            [39.42291401, -7.97668551],
                            [39.42294844, -7.97688069],
                            [39.42297141, -7.97708736],
                            [39.42297141, -7.97736291],
                            [39.42297141, -7.97760403],
                            [39.42278773, -7.97763846],
                            [39.42254669, -7.97750068],
                            [39.42240893, -7.97735143],
                            [39.42242042, -7.9771218],
                            [39.4224778, -7.97692662],
                            [39.42242042, -7.97677736],
                            [39.42229416, -7.97666254],
                            [39.42194978, -7.9765018],
                            [39.42180057, -7.97634106],
                            [39.42170875, -7.97613439],
                            [39.42159395, -7.9758244],
                            [39.42145621, -7.97551442],
                            [39.42132995, -7.97523885],
                            [39.4211348, -7.97494034],
                            [39.42098558, -7.9747107],
                            [39.42085932, -7.97463034],
                            [39.42077896, -7.97464181],
                            [39.42068713, -7.97480255],
                            [39.42058383, -7.97506663],
                            [39.42056086, -7.97530775],
                            [39.42057235, -7.97564453],
                            [39.42067947, -7.97592007],
                            [39.420756, -7.97628748],
                            [39.42086314, -7.97657835],
                            [39.42095496, -7.9768539],
                            [39.42110801, -7.97717538],
                            [39.42126105, -7.97746623],
                            [39.42135288, -7.97769587],
                            [39.42141411, -7.9779561],
                            [39.42141411, -7.97821634],
                            [39.42129166, -7.97833883],
                            [39.42104678, -7.97833883],
                            [39.42075599, -7.97826227],
                            [39.4204958, -7.97810919],
                            [39.42023725, -7.97806217],
                            [39.42021065, -7.97804086],
                            [39.42016473, -7.97804898],
                            [39.42015826, -7.97805012],
                            [39.4199907, -7.9780797],
                            [39.41982838, -7.97809322],
                            [39.41967962, -7.9781338],
                            [39.41953084, -7.97822851],
                            [39.41934146, -7.97833674],
                            [39.41904391, -7.97844497],
                            [39.41885454, -7.97848555],
                            [39.41866519, -7.97835026],
                            [39.41847582, -7.97820145],
                            [39.41819179, -7.97807969],
                            [39.4179348, -7.97801204],
                            [39.41778602, -7.97798498],
                            [39.41758312, -7.97801204],
                            [39.41739377, -7.97809321],
                            [39.41731262, -7.97828262],
                            [39.41736672, -7.9785126],
                            [39.41756961, -7.97867494],
                            [39.41781307, -7.97874259],
                            [39.41816474, -7.97883728],
                            [39.41844877, -7.97891846],
                            [39.41866519, -7.97897258],
                            [39.41884101, -7.97905377],
                            [39.41890865, -7.97920257],
                            [39.41890864, -7.97936492],
                            [39.4188816, -7.97962197],
                            [39.41881397, -7.97989253],
                            [39.41878691, -7.98010899],
                            [39.418841, -7.98033899],
                            [39.41897626, -7.98050133],
                            [39.41912505, -7.98062309],
                            [39.41927383, -7.98081248],
                            [39.41935499, -7.98097483],
                            [39.41927383, -7.98109658],
                            [39.41916563, -7.98119129],
                            [39.41912505, -7.98140774],
                            [39.41898979, -7.98151597],
                            [39.41874633, -7.98144834],
                            [39.41865165, -7.98123187],
                            [39.41855696, -7.98104246],
                            [39.4184623, -7.9808666],
                            [39.41829997, -7.98083953],
                            [39.41820531, -7.98094777],
                            [39.41821884, -7.98109658],
                            [39.41835408, -7.98129952],
                            [39.41850287, -7.98150244],
                            [39.41865164, -7.98165126],
                            [39.41871927, -7.98180007],
                            [39.4186246, -7.982003],
                            [39.41850287, -7.98217888],
                            [39.4184758, -7.98235475],
                            [39.4185164, -7.98255768],
                            [39.41865164, -7.98272002],
                            [39.41874632, -7.98285532],
                            [39.41881396, -7.98304473],
                            [39.41881396, -7.98322059],
                            [39.41875985, -7.98338293],
                            [39.41861106, -7.9834641],
                            [39.41855695, -7.98365351],
                            [39.41857048, -7.98388349],
                            [39.41875985, -7.98418112],
                            [39.41886805, -7.98441112],
                            [39.41892215, -7.9845464],
                            [39.41901682, -7.98474934],
                            [39.41911152, -7.98496579],
                            [39.41909797, -7.98511461],
                            [39.4190033, -7.98522283],
                            [39.41881395, -7.98523637],
                            [39.41861105, -7.98516873],
                            [39.41843522, -7.98514166],
                            [39.41829996, -7.98527695],
                            [39.41821882, -7.98545281],
                            [39.41804298, -7.98556105],
                            [39.41788066, -7.98542577],
                            [39.41778599, -7.9852093],
                            [39.41765075, -7.98493873],
                            [39.41752902, -7.98462758],
                            [39.41735318, -7.98432994],
                            [39.41710973, -7.98404583],
                            [39.41693389, -7.98385643],
                            [39.41669043, -7.98357233],
                            [39.41652811, -7.98350468],
                            [39.41637934, -7.98353174],
                            [39.41628465, -7.98361291],
                            [39.41616292, -7.9837076],
                            [39.41597357, -7.98380231],
                            [39.41575714, -7.9837482],
                            [39.4155813, -7.98363996],
                            [39.41541901, -7.98362643],
                            [39.41522966, -7.9837076],
                            [39.41514849, -7.98388348],
                            [39.41516201, -7.98407287],
                            [39.41527022, -7.98423523],
                            [39.41551368, -7.98439757],
                            [39.41579773, -7.98458698],
                            [39.41606823, -7.98481697],
                            [39.41635227, -7.98507401],
                            [39.41662279, -7.98526341],
                            [39.41679861, -7.98553399],
                            [39.4169474, -7.9857234],
                            [39.41709617, -7.98600749],
                            [39.4172179, -7.98635924],
                            [39.41731258, -7.98668394],
                            [39.41736669, -7.98703566],
                            [39.4174749, -7.98740095],
                            [39.41761014, -7.98777976],
                            [39.41767778, -7.9880368],
                            [39.41778598, -7.9883209],
                            [39.41794828, -7.98849677],
                            [39.41819174, -7.98867264],
                            [39.41844873, -7.98888911],
                            [39.4186381, -7.98914616],
                            [39.41882745, -7.9894032],
                            [39.41893566, -7.98966025],
                            [39.41905739, -7.99001199],
                            [39.41905739, -7.99029609],
                            [39.41909795, -7.99063432],
                            [39.41913852, -7.99110783],
                            [39.41920616, -7.99148662],
                            [39.41931436, -7.99181131],
                            [39.41953078, -7.99213601],
                            [39.41976069, -7.99252834],
                            [39.41996359, -7.9927989],
                            [39.42009885, -7.99308302],
                            [39.42030172, -7.99344829],
                            [39.42040993, -7.99382708],
                            [39.42046403, -7.9942059],
                            [39.42051814, -7.99457117],
                            [39.42053165, -7.99482822],
                            [39.42053165, -7.99505822],
                            [39.42050963, -7.99533474],
                            [39.42036075, -7.99527091],
                            [39.42007635, -7.99512868],
                            [39.41981225, -7.99496613],
                            [39.41960911, -7.99474262],
                            [39.41940596, -7.99451911],
                            [39.41923787, -7.99418908],
                            [39.41914811, -7.99403943],
                            [39.41912066, -7.99421777],
                            [39.41907229, -7.99441134],
                            [39.41902393, -7.99457263],
                            [39.4190159, -7.9945903],
                            [39.41902895, -7.99460771],
                            [39.41915765, -7.99483943],
                            [39.41923487, -7.99501965],
                            [39.41936357, -7.99521274],
                            [39.41950514, -7.99548308],
                            [39.41968532, -7.99576628],
                            [39.41974967, -7.995998],
                            [39.41969819, -7.99612673],
                            [39.41980115, -7.99634557],
                            [39.41982689, -7.99655153],
                            [39.41982689, -7.99683474],
                            [39.41994272, -7.99714369],
                            [39.41968531, -7.99725955],
                            [39.41935069, -7.99713081],
                            [39.41905468, -7.99687335],
                            [39.41882302, -7.99659015],
                            [39.41866858, -7.99626832],
                            [39.41851414, -7.99607523],
                            [39.41847336, -7.99605619],
                            [39.41857238, -7.99589525],
                            [39.418653, -7.99571785],
                            [39.41870137, -7.99549202],
                            [39.41873364, -7.99529847],
                            [39.41874536, -7.99525548],
                            [39.41853852, -7.99497963],
                            [39.41841383, -7.99482719],
                            [39.41827529, -7.99456388],
                            [39.41806747, -7.99421744],
                            [39.41792893, -7.99371856],
                            [39.41783193, -7.99337211],
                            [39.41759641, -7.99292865],
                            [39.41747172, -7.99252676],
                            [39.41725004, -7.99194475],
                            [39.41716691, -7.99155671],
                            [39.41705608, -7.99114097],
                            [39.41687597, -7.99075295],
                            [39.41675127, -7.99035107],
                            [39.41664043, -7.98992148],
                            [39.41648803, -7.98950573],
                            [39.4163772, -7.98918701],
                            [39.41616938, -7.98884056],
                            [39.41604468, -7.9886604],
                            [39.41589228, -7.98843867],
                            [39.41578144, -7.98823082],
                            [39.41560134, -7.98795366],
                            [39.41540737, -7.9876072],
                            [39.41522725, -7.98742704],
                            [39.41518571, -7.98726074],
                            [39.41504715, -7.98716374],
                            [39.41486703, -7.98691429],
                            [39.41472849, -7.98674799],
                            [39.41460379, -7.98662328],
                            [39.41439597, -7.98641541],
                            [39.41424356, -7.98635998],
                            [39.41409117, -7.98635998],
                            [39.41399418, -7.98640155],
                            [39.4138972, -7.98652627],
                            [39.41388334, -7.98673412],
                            [39.41403575, -7.98695587],
                            [39.41413272, -7.98712217],
                            [39.41428513, -7.9873439],
                            [39.41460379, -7.98757947],
                            [39.41482547, -7.9878012],
                            [39.41496401, -7.98805066],
                            [39.41507485, -7.98834166],
                            [39.41517184, -7.98866039],
                            [39.4151857, -7.98899299],
                            [39.4151857, -7.98938101],
                            [39.41517184, -7.98969974],
                            [39.41521338, -7.98983832],
                            [39.41535194, -7.98993532],
                            [39.41546279, -7.99007391],
                            [39.41562903, -7.99028178],
                            [39.41580915, -7.99058665],
                            [39.41589227, -7.99087767],
                            [39.41590613, -7.99115483],
                            [39.41591997, -7.99159829],
                            [39.41591997, -7.99204174],
                            [39.41594768, -7.99238818],
                            [39.41586456, -7.99281778],
                            [39.41583684, -7.99323351],
                            [39.41579528, -7.99369083],
                            [39.41572601, -7.99413429],
                            [39.41571215, -7.99455002],
                            [39.4157537, -7.99499346],
                            [39.41579527, -7.99539535],
                            [39.41587841, -7.99571408],
                            [39.41587841, -7.99597738],
                            [39.41586455, -7.99621297],
                            [39.41575369, -7.99639313],
                            [39.41571214, -7.99658715],
                            [39.41557765, -7.99680239],
                            [39.41558043, -7.99680119],
                            [39.41573104, -7.99674739],
                            [39.41561027, -7.9970789],
                            [39.41556126, -7.99736074],
                            [39.41548775, -7.99783863],
                            [39.41551225, -7.99825525],
                            [39.415549, -7.99876991],
                            [39.41564701, -7.99923555],
                            [39.41564701, -7.99962766],
                            [39.4155735, -7.99995851],
                            [39.41542649, -8.00030161],
                            [39.41516922, -8.00048542],
                            [39.4148752, -8.00053443],
                            [39.41471594, -8.00047316],
                            [39.41461793, -8.00033837],
                            [39.41445867, -8.00019133],
                            [39.41427491, -8.0001178],
                            [39.41414015, -8.0001178],
                            [39.41394413, -8.0001178],
                            [39.41373587, -8.00015456],
                            [39.41353985, -8.00033837],
                            [39.41328258, -8.00054668],
                            [39.41320908, -8.00074274],
                            [39.41320907, -8.00087753],
                            [39.41327033, -8.00097556],
                            [39.41344184, -8.00109809],
                            [39.41367461, -8.00118387],
                            [39.41393188, -8.00130641],
                            [39.4142504, -8.00145345],
                            [39.41461792, -8.00166177],
                            [39.41483844, -8.00179656],
                            [39.41503445, -8.00205388],
                            [39.41518146, -8.00238473],
                            [39.41518146, -8.00267882],
                            [39.41508345, -8.00283812],
                            [39.4149977, -8.00285037],
                            [39.41482618, -8.00302192],
                            [39.41480168, -8.00320573],
                            [39.41483843, -8.00342629],
                            [39.41500994, -8.00384292],
                            [39.41515695, -8.00417377],
                            [39.41524271, -8.00456589],
                            [39.41513245, -8.00481096],
                            [39.41497319, -8.00498251],
                            [39.41474532, -8.00515406],
                            [39.41457871, -8.00535992],
                            [39.4145199, -8.00552657],
                            [39.4145493, -8.0056246],
                            [39.41461791, -8.00578145],
                            [39.41475512, -8.00588928],
                            [39.41489233, -8.00606573],
                            [39.41504301, -8.00618472],
                            [39.41507836, -8.00616183],
                            [39.41522106, -8.00606302],
                            [39.41541866, -8.00594223],
                            [39.41560528, -8.00584342],
                            [39.41574796, -8.0057446],
                            [39.41589071, -8.00556893],
                            [39.4159895, -8.0054152],
                            [39.41611026, -8.00531638],
                            [39.41622002, -8.00521755],
                            [39.41634079, -8.00504188],
                            [39.41643958, -8.00486619],
                            [39.41656033, -8.00474544],
                            [39.41668109, -8.00461366],
                            [39.41675794, -8.00449289],
                            [39.41687867, -8.00435015],
                            [39.41696648, -8.00421837],
                            [39.41705431, -8.00407564],
                            [39.4171641, -8.00394388],
                            [39.4172629, -8.00381212],
                            [39.41737269, -8.00370232],
                            [39.41749342, -8.00358155],
                            [39.41760321, -8.00344979],
                            [39.41772396, -8.00330705],
                            [39.41782275, -8.00319725],
                            [39.41793256, -8.00309843],
                            [39.41805327, -8.00298862],
                            [39.41817404, -8.00290079],
                            [39.41828383, -8.00283492],
                            [39.41843749, -8.00274707],
                            [39.41858021, -8.00268119],
                            [39.41870097, -8.00259334],
                            [39.41877781, -8.00248356],
                            [39.41879489, -8.00241526],
                            [39.41880756, -8.00241422],
                            [39.41888907, -8.00238457],
                            [39.41891577, -8.00255817],
                            [39.41891577, -8.00282754],
                            [39.41891577, -8.00313731],
                            [39.4189427, -8.00346056],
                            [39.4189427, -8.00371645],
                            [39.41887537, -8.0040397],
                            [39.4189023, -8.00428213],
                            [39.41863299, -8.00464577],
                            [39.41822903, -8.00530572],
                            [39.41789239, -8.00575018],
                            [39.41765002, -8.00611382],
                            [39.41747497, -8.00639666],
                            [39.41739417, -8.00670643],
                            [39.41735377, -8.00700273],
                            [39.41736724, -8.00729904],
                            [39.41725951, -8.00758187],
                            [39.41715179, -8.00786471],
                            [39.417071, -8.0080802],
                            [39.41682862, -8.00821488],
                            [39.41666704, -8.0083361],
                            [39.4163708, -8.00851119],
                            [39.41626307, -8.00872668],
                            [39.41615535, -8.00895564],
                            [39.41600723, -8.00921154],
                            [39.41577832, -8.00937316],
                            [39.41552248, -8.00937316],
                            [39.41533396, -8.00929235],
                            [39.41515891, -8.00927888],
                            [39.41495693, -8.00945397],
                            [39.41479535, -8.00984455],
                            [39.41470109, -8.01018126],
                            [39.4145395, -8.01039675],
                            [39.41424326, -8.01047756],
                            [39.41396049, -8.01051796],
                            [39.41361039, -8.01053143],
                            [39.41334109, -8.01041021],
                            [39.41309871, -8.01024859],
                            [39.41296406, -8.01015431],
                            [39.41278901, -8.01019472],
                            [39.41264089, -8.010289],
                            [39.4125197, -8.010289],
                            [39.41230426, -8.01024859],
                            [39.4121696, -8.01019472],
                            [39.41200802, -8.0103294],
                            [39.41203495, -8.01050449],
                            [39.41221, -8.01067958],
                            [39.41233118, -8.0108412],
                            [39.41234465, -8.01104322],
                            [39.41227732, -8.01117791],
                            [39.41208881, -8.01117791],
                            [39.41200801, -8.01125872],
                            [39.41211574, -8.01144727],
                            [39.41223692, -8.0116089],
                            [39.41233118, -8.01187826],
                            [39.41222346, -8.01218804],
                            [39.41199454, -8.01240353],
                            [39.41169831, -8.01255168],
                            [39.41126741, -8.01259208],
                            [39.41074227, -8.01268636],
                            [39.41028444, -8.01286145],
                            [39.40990741, -8.01303653],
                            [39.40942266, -8.01341365],
                            [39.40896484, -8.01384463],
                            [39.4083185, -8.01447764],
                            [39.40776641, -8.01511066],
                            [39.40745671, -8.01539349],
                            [39.40702582, -8.01568979],
                            [39.40658146, -8.01586488],
                            [39.4061775, -8.01609384],
                            [39.40580047, -8.01621505],
                            [39.40540997, -8.01624199],
                            [39.40511374, -8.01634973],
                            [39.40495215, -8.01644401],
                            [39.40483096, -8.0166191],
                            [39.40485789, -8.01679419],
                            [39.4051272, -8.01682113],
                            [39.4054773, -8.01684807],
                            [39.40596205, -8.01691541],
                            [39.40659492, -8.0168346],
                            [39.4071874, -8.01648443],
                            [39.40786067, -8.01616119],
                            [39.40841275, -8.01577061],
                            [39.40912641, -8.0154339],
                            [39.40974582, -8.01525882],
                            [39.41032483, -8.01515107],
                            [39.4109577, -8.01511067],
                            [39.41153671, -8.01508373],
                            [39.41171176, -8.01492211],
                            [39.41190028, -8.01465275],
                            [39.41194067, -8.01430257],
                            [39.41203493, -8.0137773],
                            [39.41227731, -8.01334632],
                            [39.41268127, -8.01284799],
                            [39.41321989, -8.01236313],
                            [39.41369118, -8.01181093],
                            [39.41413554, -8.01140688],
                            [39.41470108, -8.01092202],
                            [39.41506465, -8.01062572],
                            [39.41556287, -8.01027554],
                            [39.41610148, -8.0099119],
                            [39.41662663, -8.00952132],
                            [39.41713832, -8.00922501],
                            [39.41774426, -8.00882097],
                            [39.41816169, -8.00852466],
                            [39.41863298, -8.00822836],
                            [39.41898308, -8.00808021],
                            [39.41938704, -8.00777044],
                            [39.41972367, -8.00728558],
                            [39.4201411, -8.00666603],
                            [39.42046427, -8.00607343],
                            [39.42082784, -8.0055751],
                            [39.4212722, -8.00507677],
                            [39.42167616, -8.00455151],
                            [39.42209359, -8.00408011],
                            [39.4223629, -8.00358179],
                            [39.42264567, -8.00309693],
                            [39.42282072, -8.00270634],
                            [39.42299577, -8.00239657],
                            [39.42323815, -8.00230229],
                            [39.4234536, -8.00230229],
                            [39.42350746, -8.00251779],
                            [39.42345359, -8.00296224],
                            [39.42331894, -8.00346057],
                            [39.42311695, -8.00383769],
                            [39.42295537, -8.00406665],
                            [39.42280725, -8.00434948],
                            [39.42273992, -8.00467273],
                            [39.42265913, -8.00509025],
                            [39.42243021, -8.00561551],
                            [39.42216091, -8.00593875],
                            [39.42198586, -8.00611384],
                            [39.42175694, -8.00627546],
                            [39.42162229, -8.00642361],
                            [39.42147417, -8.00655829],
                            [39.42143377, -8.00680072],
                            [39.42144724, -8.00707009],
                            [39.42160882, -8.00717784],
                            [39.42187813, -8.00708356],
                            [39.4221609, -8.00705663],
                            [39.42238981, -8.00702969],
                            [39.42252447, -8.00717784],
                            [39.42279377, -8.00725865],
                            [39.42307654, -8.00715091],
                            [39.4232516, -8.00676033],
                            [39.4231708, -8.00649096],
                            [39.42303615, -8.00615425],
                            [39.42302269, -8.0057906],
                            [39.42319774, -8.00541349],
                            [39.42344012, -8.005198],
                            [39.42358824, -8.00490169],
                            [39.42373636, -8.00448417],
                            [39.42392487, -8.00401278],
                            [39.42397874, -8.00351445],
                            [39.42415379, -8.00325855],
                            [39.42430191, -8.00305653],
                            [39.42449042, -8.00298919],
                            [39.42465201, -8.00312387],
                            [39.42470587, -8.00340671],
                            [39.4247328, -8.00399932],
                            [39.42478665, -8.00459192],
                            [39.42481358, -8.00510372],
                            [39.42475972, -8.00537309],
                            [39.42458467, -8.00583101],
                            [39.42430189, -8.00627547],
                            [39.42409991, -8.00661218],
                            [39.42381714, -8.00688154],
                            [39.42362862, -8.00709704],
                            [39.42361516, -8.007326],
                            [39.42346704, -8.00766271],
                            [39.42309001, -8.00773005],
                            [39.42276684, -8.00768964],
                            [39.42245714, -8.00770311],
                            [39.42222823, -8.00767617],
                            [39.42208011, -8.00777045],
                            [39.42210704, -8.00795901],
                            [39.4221609, -8.0081341],
                            [39.42238981, -8.00834959],
                            [39.4227803, -8.00853815],
                            [39.42302268, -8.00875365],
                            [39.42317079, -8.00898261],
                            [39.42326505, -8.00931932],
                            [39.42325158, -8.00972337],
                            [39.42327851, -8.01020823],
                            [39.42326504, -8.01057188],
                            [39.42315732, -8.01097593],
                            [39.4230092, -8.01144732],
                            [39.42286108, -8.01167628],
                            [39.42271296, -8.01201299],
                            [39.42260524, -8.01228236],
                            [39.42256484, -8.01249785],
                            [39.42252444, -8.01267294],
                            [39.4226187, -8.01280763],
                            [39.42283414, -8.01278069],
                            [39.42326504, -8.01257867],
                            [39.423669, -8.01217462],
                            [39.42420761, -8.0114608],
                            [39.42454425, -8.01082778],
                            [39.42490782, -8.01023518],
                            [39.4250694, -8.00987153],
                            [39.42517713, -8.00938667],
                            [39.42523099, -8.00899609],
                            [39.42524446, -8.00853816],
                            [39.42531179, -8.00808024],
                            [39.42555417, -8.0075415],
                            [39.42590427, -8.00663912],
                            [39.42620051, -8.00572327],
                            [39.42625437, -8.00523841],
                            [39.42625438, -8.00487477],
                            [39.42611972, -8.0046458],
                            [39.42595814, -8.00452459],
                            [39.42581002, -8.00424175],
                            [39.42578309, -8.00377036],
                            [39.42589082, -8.00323162],
                            [39.42603894, -8.00263901],
                            [39.42607934, -8.00227537],
                            [39.42597162, -8.00166929],
                            [39.42593122, -8.00095547],
                            [39.42594469, -8.00032245],
                            [39.42587737, -7.99955476],
                            [39.42589084, -7.99894868],
                            [39.42599856, -7.99787121],
                            [39.4259851, -7.9969823],
                            [39.4260659, -7.99640316],
                            [39.42541715, -7.99650446],
                            [39.42544353, -7.99648561],
                            [39.42555357, -7.99640858],
                            [39.42582193, -7.99626115],
                            [39.42606371, -7.99607305],
                            [39.42617119, -7.99591182],
                            [39.42630549, -7.99569686],
                            [39.42641298, -7.995455],
                            [39.4264138, -7.99545381],
                            [39.42637385, -7.99545381],
                            [39.42633409, -7.99546127],
                            [39.42633409, -7.99538715],
                            [39.42633417, -7.99508531],
                            [39.42635274, -7.99484387],
                            [39.42640845, -7.9946117],
                            [39.42641772, -7.99429597],
                            [39.42651986, -7.99405452],
                            [39.42660342, -7.9938688],
                            [39.42668698, -7.99371093],
                            [39.42681696, -7.99355306],
                            [39.42692838, -7.99339518],
                            [39.42705834, -7.99318161],
                            [39.42712334, -7.99301446],
                            [39.42712334, -7.99281944],
                            [39.42708622, -7.992643],
                            [39.42700264, -7.99243869],
                            [39.42693766, -7.99228082],
                            [39.42688196, -7.9920951],
                            [39.42689124, -7.99194652],
                            [39.42694696, -7.9917515],
                            [39.42701193, -7.99156578],
                            [39.42703051, -7.99126862],
                            [39.42708622, -7.99095289],
                            [39.42710477, -7.99058143],
                            [39.42710477, -7.99020998],
                            [39.42710477, -7.98982924],
                            [39.42708623, -7.98955064],
                            [39.42705836, -7.98942063],
                            [39.42693767, -7.98926276],
                            [39.42682626, -7.98908632],
                            [39.42667772, -7.98893775],
                            [39.42649204, -7.98877987],
                            [39.42642704, -7.98866843],
                            [39.42642704, -7.98852914],
                            [39.4264549, -7.98830627],
                            [39.42639919, -7.98804419],
                            [39.42634967, -7.98782957],
                            [39.42618463, -7.98778003],
                            [39.42603608, -7.98778003],
                            [39.42593703, -7.98791211],
                            [39.42590402, -7.98811021],
                            [39.42587102, -7.98834135],
                            [39.42580499, -7.98850644],
                            [39.42562343, -7.98867152],
                            [39.42540886, -7.98877057],
                            [39.42519429, -7.98888614],
                            [39.42494671, -7.9889852],
                            [39.42479815, -7.98911727],
                            [39.42478167, -7.98931539],
                            [39.42478167, -7.98949698],
                            [39.42464962, -7.98977763],
                            [39.42455058, -7.99002527],
                            [39.42446807, -7.99023988],
                            [39.42450106, -7.99047101],
                            [39.42468263, -7.99066912],
                            [39.42488069, -7.99088374],
                            [39.42501273, -7.9911479],
                            [39.42509524, -7.99149457],
                            [39.42511175, -7.99180826],
                            [39.42511175, -7.99212192],
                            [39.42524379, -7.99256768],
                            [39.42532632, -7.9929804],
                            [39.42542534, -7.99336011],
                            [39.42540884, -7.99385539],
                            [39.42539234, -7.99421858],
                            [39.42537584, -7.99466433],
                            [39.42529331, -7.9949615],
                            [39.42517777, -7.99517612],
                            [39.42501271, -7.99537422],
                            [39.42492212, -7.9955554],
                            [39.42470806, -7.9955554],
                            [39.42468897, -7.9955554],
                            [39.42467462, -7.99556438],
                            [39.42442174, -7.995733],
                            [39.42419294, -7.99578118],
                            [39.42400027, -7.99587754],
                            [39.42396414, -7.99599798],
                            [39.4237233, -7.99622683],
                            [39.42360288, -7.99631114],
                            [39.4234945, -7.99653999],
                            [39.42333795, -7.99669657],
                            [39.42314528, -7.99669657],
                            [39.42283219, -7.99670862],
                            [39.42262748, -7.99667248],
                            [39.42254318, -7.99679293],
                            [39.4225191, -7.99703382],
                            [39.42247093, -7.99735903],
                            [39.42248297, -7.99759992],
                            [39.42260339, -7.99773241],
                            [39.42269972, -7.99792513],
                            [39.42260339, -7.99811784],
                            [39.42239867, -7.99811784],
                            [39.42212171, -7.99800944],
                            [39.42195312, -7.99778059],
                            [39.42182066, -7.99743129],
                            [39.42176045, -7.99703382],
                            [39.42178454, -7.99658816],
                            [39.42182067, -7.99622682],
                            [39.42185679, -7.99608229],
                            [39.42208559, -7.99590162],
                            [39.42231439, -7.99569686],
                            [39.42242277, -7.99554028],
                            [39.42242999, -7.99553371],
                            [39.42224998, -7.99551476],
                            [39.42211698, -7.99551476],
                            [39.42222059, -7.99527792],
                            [39.42234011, -7.99505374],
                            [39.42247458, -7.99487441],
                            [39.42257918, -7.99465024],
                            [39.42268377, -7.99445596],
                            [39.42274352, -7.99418695],
                            [39.42271365, -7.99390299],
                            [39.42259412, -7.99364895],
                            [39.42248954, -7.99324542],
                            [39.42241484, -7.99296149],
                            [39.42235508, -7.99267753],
                            [39.42232517, -7.99242347],
                            [39.42232517, -7.99213951],
                            [39.42237, -7.99191534],
                            [39.4225792, -7.99161646],
                            [39.42269872, -7.99134744],
                            [39.42284814, -7.99109338],
                            [39.42286308, -7.9907945],
                            [39.42281827, -7.9904956],
                            [39.42269873, -7.99010704],
                            [39.4226539, -7.98973341],
                            [39.42265391, -7.98932989],
                            [39.42274354, -7.98906089],
                            [39.42299757, -7.98867234],
                            [39.42319181, -7.98847805],
                            [39.42319181, -7.98826881],
                            [39.42313203, -7.9880297],
                            [39.42314699, -7.98783542],
                            [39.42334121, -7.9877308],
                            [39.42356535, -7.98764115],
                            [39.42377453, -7.98752159],
                            [39.42393888, -7.98734225],
                            [39.42393888, -7.98711807],
                            [39.42381935, -7.98699851],
                            [39.42362511, -7.98687896],
                            [39.42350559, -7.98663984],
                            [39.42352051, -7.98640073],
                            [39.42361018, -7.9861915],
                            [39.42375958, -7.98590754],
                            [39.42372971, -7.98575809],
                            [39.42359523, -7.98565348],
                            [39.42337111, -7.98568338],
                            [39.42317687, -7.98580293],
                            [39.42287804, -7.98604205],
                            [39.42257922, -7.98623633],
                            [39.42231028, -7.98623633],
                            [39.42213097, -7.98608688],
                            [39.42207121, -7.9858328],
                            [39.42207121, -7.98554888],
                            [39.42219074, -7.98526492],
                            [39.42229533, -7.9850258],
                            [39.42250453, -7.98481657],
                            [39.42275852, -7.9846223],
                            [39.42304241, -7.98430845],
                            [39.4232964, -7.98400956],
                            [39.42356536, -7.98371065],
                            [39.42375959, -7.98339681],
                            [39.42389407, -7.98308299],
                            [39.42401361, -7.98285881],
                            [39.42417795, -7.98272431],
                            [39.42443197, -7.9826197],
                            [39.42471585, -7.98247024],
                            [39.42492503, -7.98238058],
                            [39.4251641, -7.98217136],
                            [39.42540317, -7.98185752],
                            [39.42550775, -7.9815885],
                            [39.4255372, -7.9814413],
                            [39.42555258, -7.98136433],
                            [39.42555393, -7.98136207],
                            [39.42555493, -7.98136207],
                            [39.42554263, -7.98111733],
                            [39.42553115, -7.98086474],
                            [39.42553115, -7.98061215],
                            [39.42547377, -7.98032511],
                            [39.42547377, -7.98012993],
                            [39.42554264, -7.97992325],
                            [39.42565742, -7.97973956],
                            [39.42580665, -7.97960179],
                            [39.42585257, -7.97944104],
                            [39.42585257, -7.97923438],
                            [39.42573778, -7.97909662],
                            [39.4256689, -7.97897031],
                            [39.4256115, -7.97880956],
                            [39.4256115, -7.97856847],
                            [39.42565742, -7.97825846],
                            [39.42565742, -7.97792551],
                            [39.42571481, -7.97754662],
                            [39.42577222, -7.97735145],
                            [39.42585258, -7.97721366],
                            [39.42590997, -7.97709884],
                            [39.42607068, -7.97705292],
                            [39.42624284, -7.97702997],
                            [39.42642651, -7.97691515],
                            [39.42650687, -7.97673144],
                            [39.42649538, -7.97657071],
                            [39.42641503, -7.97635256],
                            [39.42631172, -7.97619183],
                            [39.42612807, -7.97601961],
                            [39.42596737, -7.97587035],
                            [39.42579519, -7.97567517],
                            [39.42565744, -7.97551443],
                            [39.4255771, -7.97522738],
                            [39.42553118, -7.97495183],
                            [39.42555413, -7.97463036],
                            [39.42555413, -7.97430888],
                            [39.42555413, -7.97390703],
                            [39.42555414, -7.97359705],
                            [39.42549675, -7.97330999],
                            [39.42538196, -7.97295407],
                            [39.42527865, -7.97266704],
                            [39.42522126, -7.97241445],
                            [39.42522126, -7.97221927],
                            [39.42524422, -7.97195521],
                            [39.42528218, -7.9718109],
                            [39.42530161, -7.97173706],
                            [39.42539345, -7.97150742],
                            [39.4254738, -7.97122039],
                            [39.42548527, -7.97094483],
                            [39.42548528, -7.97061187],
                            [39.42542789, -7.97025596],
                            [39.42542789, -7.97000336],
                            [39.42551972, -7.96970484],
                            [39.4255312, -7.96939486],
                            [39.42547381, -7.96909633],
                            [39.42549677, -7.96891264],
                            [39.42555416, -7.96871745],
                            [39.42556564, -7.96852226],
                            [39.42556564, -7.9683271],
                            [39.42551972, -7.96810893],
                            [39.42543938, -7.96785635],
                            [39.42541643, -7.96762672],
                            [39.42541643, -7.96743154],
                            [39.42551973, -7.96722488],
                            [39.42563452, -7.96705266],
                            [39.42568042, -7.96681155],
                            [39.42568043, -7.96663933],
                            [39.4256489, -7.96651319],
                            [39.42562304, -7.96640971],
                            [39.42556565, -7.96612266],
                            [39.42538199, -7.96581266],
                            [39.42530163, -7.96557155],
                            [39.42529017, -7.96531896],
                            [39.42531313, -7.96506637],
                            [39.42546233, -7.9648597],
                            [39.42560009, -7.96467601],
                            [39.42565748, -7.96450379],
                            [39.42566897, -7.96428564],
                            [39.42557714, -7.96405601],
                            [39.42547384, -7.96386085],
                            [39.42542792, -7.96356232],
                            [39.42542792, -7.9632638],
                            [39.425382, -7.96309158],
                            [39.42527869, -7.96319491],
                            [39.42510651, -7.96334417],
                            [39.42496876, -7.96353936],
                            [39.42477361, -7.96379194],
                            [39.4244522, -7.96414786],
                        ],
                        [
                            [39.44379857, -7.98900903],
                            [39.44399209, -7.98944459],
                            [39.44423402, -7.98998902],
                            [39.44443963, -7.99046087],
                            [39.4445485, -7.99076333],
                            [39.44458478, -7.9910053],
                            [39.44458478, -7.99119888],
                            [39.44448802, -7.99138035],
                            [39.44436705, -7.99145294],
                            [39.44428239, -7.99156184],
                            [39.4442582, -7.99175541],
                            [39.4442461, -7.99198529],
                            [39.44418562, -7.99213046],
                            [39.44400417, -7.99226355],
                            [39.44382274, -7.99233614],
                            [39.44375017, -7.99250553],
                            [39.44383484, -7.99280799],
                            [39.44404047, -7.99330404],
                            [39.44414932, -7.99358229],
                            [39.44414932, -7.99378797],
                            [39.44402837, -7.99409043],
                            [39.4439437, -7.9943687],
                            [39.44387856, -7.99459674],
                            [39.44379855, -7.99487684],
                            [39.44371386, -7.9952519],
                            [39.44372597, -7.99556647],
                            [39.44370176, -7.99595362],
                            [39.44372597, -7.99626818],
                            [39.44368967, -7.99653435],
                            [39.44361709, -7.99686101],
                            [39.4435687, -7.99716347],
                            [39.44343566, -7.99740545],
                            [39.4433147, -7.99735705],
                            [39.44312116, -7.99721187],
                            [39.4429881, -7.9969699],
                            [39.4429881, -7.99659485],
                            [39.44300021, -7.99614719],
                            [39.4430244, -7.99597781],
                            [39.44298811, -7.99579634],
                            [39.44285506, -7.99569954],
                            [39.44261314, -7.99566325],
                            [39.44246799, -7.99569954],
                            [39.44237122, -7.99574794],
                            [39.44223817, -7.99594152],
                            [39.44212931, -7.99613509],
                            [39.44206883, -7.99632867],
                            [39.44202044, -7.99663113],
                            [39.44209301, -7.9969215],
                            [39.44222607, -7.99723606],
                            [39.44233494, -7.99745383],
                            [39.44235912, -7.99770791],
                            [39.44231073, -7.99787729],
                            [39.44220188, -7.99791359],
                            [39.44198415, -7.99781679],
                            [39.44177852, -7.9977805],
                            [39.44164546, -7.9977442],
                            [39.44146403, -7.9977805],
                            [39.44137935, -7.99787728],
                            [39.44139146, -7.99804666],
                            [39.44150031, -7.99821605],
                            [39.44156079, -7.99838542],
                            [39.4415487, -7.9986032],
                            [39.44140355, -7.99862739],
                            [39.4412463, -7.99856691],
                            [39.44110115, -7.99848221],
                            [39.44098019, -7.99847011],
                            [39.44085923, -7.9985306],
                            [39.44078665, -7.99868788],
                            [39.44085922, -7.99889356],
                            [39.44100437, -7.99907505],
                            [39.44098019, -7.99920812],
                            [39.4409197, -7.99934121],
                            [39.44076246, -7.99938961],
                            [39.44056892, -7.99955898],
                            [39.44041168, -7.99977676],
                            [39.44043588, -8.00006712],
                            [39.44059312, -8.00030909],
                            [39.44076245, -8.00061157],
                            [39.44087131, -8.00081723],
                            [39.44098018, -8.001156],
                            [39.44093179, -8.00139797],
                            [39.44085921, -8.00150686],
                            [39.44073826, -8.00156736],
                            [39.44065359, -8.00170043],
                            [39.44058102, -8.00171254],
                            [39.44044796, -8.00159155],
                            [39.44039957, -8.00168833],
                            [39.44042376, -8.00189401],
                            [39.44043587, -8.00211178],
                            [39.44037538, -8.00229327],
                            [39.44024233, -8.00236586],
                            [39.44021814, -8.00247474],
                            [39.44016975, -8.00264412],
                            [39.44025442, -8.00294659],
                            [39.4403149, -8.00314016],
                            [39.44032699, -8.00337003],
                            [39.44026651, -8.00352731],
                            [39.44007298, -8.00352731],
                            [39.43990363, -8.00351521],
                            [39.43987944, -8.0037088],
                            [39.43987944, -8.00393867],
                            [39.43982885, -8.00396999],
                            [39.43995202, -8.00415644],
                            [39.44006087, -8.00454359],
                            [39.44014555, -8.00500335],
                            [39.44016974, -8.00554778],
                            [39.44018183, -8.00604383],
                            [39.44016973, -8.00638259],
                            [39.44018183, -8.00666085],
                            [39.44024231, -8.00697542],
                            [39.44031488, -8.00727788],
                            [39.44035116, -8.00760454],
                            [39.44035116, -8.00782231],
                            [39.44020601, -8.00804009],
                            [39.44000039, -8.00817317],
                            [39.43977055, -8.00822157],
                            [39.43961331, -8.00828207],
                            [39.43957703, -8.00852403],
                            [39.43956494, -8.008766],
                            [39.43949236, -8.00903217],
                            [39.43939558, -8.00917736],
                            [39.43938349, -8.00943142],
                            [39.43954073, -8.0096976],
                            [39.43974636, -8.00990328],
                            [39.4399399, -8.01035092],
                            [39.43997618, -8.01073807],
                            [39.43995199, -8.01105263],
                            [39.43985523, -8.01124621],
                            [39.43975845, -8.01150029],
                            [39.43985522, -8.01174226],
                            [39.43993989, -8.01205682],
                            [39.44010923, -8.01244397],
                            [39.4401818, -8.01275854],
                            [39.44020599, -8.0131094],
                            [39.44013341, -8.01350865],
                            [39.44000036, -8.0139321],
                            [39.43975844, -8.01435556],
                            [39.4395891, -8.01477899],
                            [39.43946813, -8.01512985],
                            [39.43941976, -8.01544443],
                            [39.43945603, -8.01577108],
                            [39.43945603, -8.01613404],
                            [39.43938346, -8.01642441],
                            [39.43922621, -8.01675106],
                            [39.43905687, -8.01713823],
                            [39.43888752, -8.017356],
                            [39.43871818, -8.01773105],
                            [39.43860932, -8.01800932],
                            [39.4386819, -8.01821499],
                            [39.43880285, -8.01854166],
                            [39.43896009, -8.01857795],
                            [39.43910524, -8.01820289],
                            [39.43918991, -8.01775525],
                            [39.43943184, -8.01708983],
                            [39.43955279, -8.01659378],
                            [39.43966167, -8.01621873],
                            [39.43980682, -8.01590417],
                            [39.44007293, -8.01522666],
                            [39.44027855, -8.01456122],
                            [39.44055676, -8.01387161],
                            [39.44071401, -8.01325459],
                            [39.44099222, -8.01257706],
                            [39.44129462, -8.01175436],
                            [39.44159702, -8.01111313],
                            [39.44197201, -8.01030253],
                            [39.44217762, -8.00979439],
                            [39.44240746, -8.00934675],
                            [39.44249213, -8.0088386],
                            [39.44261308, -8.00816109],
                            [39.44280662, -8.00764085],
                            [39.44304854, -8.00707222],
                            [39.44330256, -8.00641889],
                            [39.4435082, -8.00582606],
                            [39.44371382, -8.00525743],
                            [39.44394366, -8.00450732],
                            [39.44423396, -8.00362413],
                            [39.44445167, -8.00307968],
                            [39.44468151, -8.00245057],
                            [39.44488713, -8.00193033],
                            [39.4450081, -8.00137379],
                            [39.44511697, -8.00078096],
                            [39.44521373, -8.00011554],
                            [39.44527422, -7.99958319],
                            [39.44525002, -7.99906296],
                            [39.44520165, -7.99849433],
                            [39.44518956, -7.99815557],
                            [39.44531051, -7.99778051],
                            [39.44537099, -7.99746596],
                            [39.44544357, -7.99698201],
                            [39.44540729, -7.99641337],
                            [39.44539023, -7.9962257],
                            [39.44538309, -7.9961472],
                            [39.44543148, -7.99555437],
                            [39.44549196, -7.99474376],
                            [39.44557664, -7.99372748],
                            [39.44561292, -7.99301368],
                            [39.4457097, -7.99203368],
                            [39.4457218, -7.99098111],
                            [39.44566132, -7.99043667],
                            [39.44558875, -7.98989224],
                            [39.4454436, -7.98920261],
                            [39.44525006, -7.98871867],
                            [39.44509282, -7.98819844],
                            [39.4448993, -7.98789597],
                            [39.44462109, -7.98741202],
                            [39.44439128, -7.98698857],
                            [39.44412516, -7.9866861],
                            [39.44390744, -7.98643204],
                            [39.44370181, -7.98620215],
                            [39.44354456, -7.98610537],
                            [39.44337523, -7.98614167],
                            [39.44343571, -7.98635945],
                            [39.44364132, -7.98681919],
                            [39.44391953, -7.98730312],
                            [39.44419773, -7.98777498],
                            [39.44443964, -7.98821053],
                            [39.44458479, -7.9884888],
                            [39.44468157, -7.98871867],
                            [39.44470575, -7.98902113],
                            [39.44468157, -7.98920261],
                            [39.44448803, -7.98899694],
                            [39.44416145, -7.98859768],
                            [39.44387115, -7.98789597],
                            [39.44355665, -7.98725474],
                            [39.44321798, -7.9866498],
                            [39.44300026, -7.98619005],
                            [39.44277042, -7.98593599],
                            [39.44251642, -7.98565772],
                            [39.44222612, -7.98543994],
                            [39.44189954, -7.98521007],
                            [39.4416697, -7.98505279],
                            [39.44147618, -7.98506489],
                            [39.44136731, -7.98519797],
                            [39.4414036, -7.98547623],
                            [39.44168181, -7.98587549],
                            [39.44208096, -7.98633523],
                            [39.44266156, -7.98706115],
                            [39.44307282, -7.98776288],
                            [39.44337522, -7.9883194],
                            [39.44379857, -7.98900903],
                        ],
                        [
                            [39.38439087, -8.01528441],
                            [39.38442795, -8.01507423],
                            [39.38442795, -8.01481459],
                            [39.38445267, -8.01456733],
                            [39.3845392, -8.01438187],
                            [39.38481114, -8.01404806],
                            [39.38508307, -8.01373899],
                            [39.3851696, -8.01355353],
                            [39.38514488, -8.01314553],
                            [39.38508307, -8.01270045],
                            [39.38494711, -8.01224301],
                            [39.38472462, -8.01182265],
                            [39.38451449, -8.01152592],
                            [39.38435381, -8.01137756],
                            [39.38406951, -8.01126629],
                            [39.38371106, -8.01117974],
                            [39.38325372, -8.01113029],
                            [39.38299413, -8.01117974],
                            [39.3826604, -8.01130337],
                            [39.38227722, -8.01147646],
                            [39.38198056, -8.01161245],
                            [39.3816839, -8.01178555],
                            [39.38135016, -8.01195863],
                            [39.3810535, -8.01202045],
                            [39.38076922, -8.01219354],
                            [39.3805838, -8.01235426],
                            [39.38018827, -8.01246553],
                            [39.37989161, -8.01250261],
                            [39.37955787, -8.01250261],
                            [39.37923649, -8.01261388],
                            [39.379014, -8.0128488],
                            [39.37880387, -8.01314551],
                            [39.37850721, -8.01344223],
                            [39.37826, -8.01360295],
                            [39.37801279, -8.01360295],
                            [39.37772849, -8.0135535],
                            [39.377506, -8.01354114],
                            [39.37720934, -8.0135535],
                            [39.37697449, -8.01365241],
                            [39.37672728, -8.01375131],
                            [39.37646771, -8.01385022],
                            [39.37629465, -8.01401094],
                            [39.37618341, -8.01418403],
                            [39.37609688, -8.01439421],
                            [39.37597327, -8.01449312],
                            [39.37582495, -8.01449312],
                            [39.3757755, -8.01436947],
                            [39.3757755, -8.01422112],
                            [39.37581259, -8.01404802],
                            [39.3759362, -8.01383786],
                            [39.37612161, -8.01368949],
                            [39.37626994, -8.01362768],
                            [39.37636882, -8.01350403],
                            [39.37636882, -8.01328149],
                            [39.37631937, -8.01302186],
                            [39.37615869, -8.01288587],
                            [39.3759362, -8.01288587],
                            [39.37584967, -8.01279932],
                            [39.37583732, -8.01262623],
                            [39.37596092, -8.01253969],
                            [39.37610924, -8.01247787],
                            [39.37612162, -8.01223061],
                            [39.37602273, -8.01206987],
                            [39.3758744, -8.01206987],
                            [39.37557775, -8.01215642],
                            [39.37547886, -8.01206987],
                            [39.37550358, -8.01177316],
                            [39.37563955, -8.01160007],
                            [39.37583732, -8.01156298],
                            [39.37602274, -8.0114888],
                            [39.37608453, -8.0113528],
                            [39.37612162, -8.01117971],
                            [39.37623287, -8.01083353],
                            [39.37625759, -8.01063572],
                            [39.37634412, -8.01043791],
                            [39.37651716, -8.01032665],
                            [39.37669021, -8.01035137],
                            [39.37675201, -8.010475],
                            [39.37677672, -8.0107099],
                            [39.37685089, -8.01093245],
                            [39.37692506, -8.01119207],
                            [39.37696214, -8.01146408],
                            [39.37712283, -8.0117608],
                            [39.37723408, -8.01193388],
                            [39.37741947, -8.01210698],
                            [39.37765432, -8.01210698],
                            [39.37791391, -8.01210698],
                            [39.3781364, -8.01205752],
                            [39.37838361, -8.01182262],
                            [39.37856902, -8.01164954],
                            [39.37874207, -8.01160008],
                            [39.37898929, -8.01145172],
                            [39.37905109, -8.01126627],
                            [39.37903873, -8.01106845],
                            [39.37892748, -8.01089537],
                            [39.37875444, -8.0107841],
                            [39.37853194, -8.01070991],
                            [39.37843307, -8.01054919],
                            [39.3784825, -8.0103761],
                            [39.37861847, -8.01026483],
                            [39.37887805, -8.01016592],
                            [39.3789893, -8.00993102],
                            [39.37893985, -8.00965902],
                            [39.37875445, -8.00957248],
                            [39.37850722, -8.00960957],
                            [39.37821058, -8.00977028],
                            [39.37787684, -8.00984447],
                            [39.37756782, -8.00994338],
                            [39.37746894, -8.00978266],
                            [39.3774813, -8.00960956],
                            [39.37755547, -8.00931284],
                            [39.37759254, -8.00899139],
                            [39.37755547, -8.00875648],
                            [39.37746894, -8.00865757],
                            [39.37729589, -8.00865757],
                            [39.37704868, -8.00870703],
                            [39.37688799, -8.0088183],
                            [39.37676437, -8.00899138],
                            [39.37657897, -8.00923866],
                            [39.37634412, -8.00936229],
                            [39.37605982, -8.00944884],
                            [39.37576316, -8.00949828],
                            [39.37542942, -8.00962192],
                            [39.37510804, -8.00984446],
                            [39.3748114, -8.01002991],
                            [39.37442821, -8.01014118],
                            [39.3740574, -8.01028953],
                            [39.3738967, -8.01035136],
                            [39.37376074, -8.01051207],
                            [39.37358768, -8.01068517],
                            [39.37345172, -8.01089535],
                            [39.37337756, -8.01120444],
                            [39.37335283, -8.01146406],
                            [39.37319214, -8.01164952],
                            [39.37294493, -8.0118226],
                            [39.37253703, -8.01203277],
                            [39.3721044, -8.01228004],
                            [39.37180774, -8.01260148],
                            [39.37164706, -8.01284876],
                            [39.37162234, -8.01305893],
                            [39.37158525, -8.01334329],
                            [39.37154817, -8.0136153],
                            [39.37143692, -8.01382546],
                            [39.37120207, -8.01396147],
                            [39.37097958, -8.01403564],
                            [39.37081888, -8.01403564],
                            [39.37074473, -8.01391201],
                            [39.37074473, -8.01371419],
                            [39.37074473, -8.01351638],
                            [39.37083125, -8.01329384],
                            [39.37094249, -8.01312075],
                            [39.3710043, -8.01289821],
                            [39.37095485, -8.01272511],
                            [39.37084361, -8.01261384],
                            [39.37064585, -8.01261384],
                            [39.37042336, -8.01253967],
                            [39.37031211, -8.0124284],
                            [39.3702503, -8.01231713],
                            [39.37022559, -8.01214403],
                            [39.37008962, -8.0120204],
                            [39.36981768, -8.01200804],
                            [39.36959519, -8.01211931],
                            [39.36938506, -8.01215639],
                            [39.36915021, -8.01215639],
                            [39.368903, -8.01209458],
                            [39.36860634, -8.01192149],
                            [39.36826024, -8.01178549],
                            [39.36800068, -8.01178549],
                            [39.36775345, -8.01179785],
                            [39.36750624, -8.01183493],
                            [39.36730847, -8.01197094],
                            [39.36725903, -8.01214402],
                            [39.36728375, -8.01234184],
                            [39.36740735, -8.0124902],
                            [39.36755568, -8.01270038],
                            [39.36813831, -8.01297915],
                            [39.36793886, -8.01298474],
                            [39.36803775, -8.01325673],
                            [39.36813663, -8.01356582],
                            [39.36808718, -8.01398618],
                            [39.36808718, -8.01433235],
                            [39.36809954, -8.01457962],
                            [39.36828495, -8.01481452],
                            [39.368458, -8.01509889],
                            [39.3688041, -8.01540798],
                            [39.36900187, -8.01574179],
                            [39.36907602, -8.01601378],
                            [39.36901423, -8.01637233],
                            [39.36900187, -8.01673087],
                            [39.36901422, -8.01699049],
                            [39.36911311, -8.01720067],
                            [39.36936032, -8.01727486],
                            [39.36970641, -8.0172625],
                            [39.37013904, -8.01715123],
                            [39.37050985, -8.01705232],
                            [39.37100429, -8.01682978],
                            [39.37141219, -8.01655778],
                            [39.37174593, -8.01642179],
                            [39.3720055, -8.01639707],
                            [39.37235159, -8.01647125],
                            [39.37261118, -8.01663197],
                            [39.37272242, -8.01680507],
                            [39.37273478, -8.01702761],
                            [39.37263589, -8.01725015],
                            [39.37233923, -8.01738614],
                            [39.37204257, -8.01747269],
                            [39.37151108, -8.01762104],
                            [39.37121441, -8.01785594],
                            [39.37088068, -8.01816503],
                            [39.37069526, -8.01836285],
                            [39.37052222, -8.01852356],
                            [39.3702132, -8.01852356],
                            [39.36991654, -8.01842465],
                            [39.3695828, -8.01833812],
                            [39.36931086, -8.01825157],
                            [39.36884116, -8.01825157],
                            [39.36856924, -8.01823921],
                            [39.36801301, -8.01811556],
                            [39.36761746, -8.01812793],
                            [39.36722191, -8.01833811],
                            [39.3669747, -8.01851119],
                            [39.36675221, -8.01875845],
                            [39.36669042, -8.01906754],
                            [39.36672749, -8.01932718],
                            [39.3668511, -8.01953735],
                            [39.36704887, -8.01973516],
                            [39.36739496, -8.01977227],
                            [39.36765453, -8.01983408],
                            [39.36781523, -8.02004426],
                            [39.36792647, -8.02040279],
                            [39.36797591, -8.02062533],
                            [39.36812424, -8.02069952],
                            [39.36828492, -8.02076135],
                            [39.36842089, -8.02076135],
                            [39.36863102, -8.02071188],
                            [39.36879172, -8.02076135],
                            [39.36900185, -8.02098389],
                            [39.36908836, -8.02125588],
                            [39.36917489, -8.02155261],
                            [39.36929849, -8.02165151],
                            [39.36953335, -8.02177515],
                            [39.36968168, -8.02179987],
                            [39.36991653, -8.0218246],
                            [39.37020083, -8.02175043],
                            [39.3704604, -8.0215897],
                            [39.37068289, -8.02133007],
                            [39.37085595, -8.02115698],
                            [39.37123912, -8.02104571],
                            [39.37154814, -8.02103335],
                            [39.37177063, -8.02099626],
                            [39.37198076, -8.02076136],
                            [39.37216616, -8.02056354],
                            [39.37233922, -8.02042755],
                            [39.37252463, -8.02031628],
                            [39.37273477, -8.0202421],
                            [39.37292016, -8.0202421],
                            [39.37321682, -8.02030391],
                            [39.37360001, -8.02035337],
                            [39.37398318, -8.02027919],
                            [39.37430456, -8.02018028],
                            [39.37458887, -8.02003192],
                            [39.37523161, -8.01963629],
                            [39.37571368, -8.0195003],
                            [39.37607214, -8.01925304],
                            [39.37624519, -8.01896867],
                            [39.37649241, -8.01863486],
                            [39.37676433, -8.01806615],
                            [39.37687558, -8.01765815],
                            [39.37702392, -8.01726253],
                            [39.3771846, -8.01699053],
                            [39.37738238, -8.01670618],
                            [39.37776557, -8.01628582],
                            [39.37824763, -8.01581602],
                            [39.37854429, -8.01548221],
                            [39.37886567, -8.01518548],
                            [39.37906343, -8.01504949],
                            [39.3792612, -8.01504949],
                            [39.37945897, -8.01508657],
                            [39.37963203, -8.01522257],
                            [39.3797309, -8.01543275],
                            [39.3797309, -8.01560584],
                            [39.37961965, -8.01586547],
                            [39.37932301, -8.01616219],
                            [39.37902635, -8.01650837],
                            [39.37868025, -8.01681745],
                            [39.37830942, -8.01701525],
                            [39.37808693, -8.01718835],
                            [39.37791389, -8.01739853],
                            [39.37791388, -8.01765815],
                            [39.37808692, -8.01781889],
                            [39.37835887, -8.01784361],
                            [39.37863079, -8.01783125],
                            [39.37879149, -8.0176829],
                            [39.37898926, -8.01747272],
                            [39.37914995, -8.01733671],
                            [39.37928591, -8.01721308],
                            [39.37949604, -8.01717599],
                            [39.37969382, -8.01716363],
                            [39.3797927, -8.0172749],
                            [39.37982978, -8.01747272],
                            [39.37980506, -8.01765816],
                            [39.37971853, -8.01793017],
                            [39.37947132, -8.01832578],
                            [39.3791005, -8.0186967],
                            [39.37874203, -8.01907995],
                            [39.3783465, -8.01936432],
                            [39.3780004, -8.01969813],
                            [39.37777791, -8.01994539],
                            [39.37758014, -8.02022976],
                            [39.37741944, -8.02048938],
                            [39.37729583, -8.02066247],
                            [39.37693738, -8.02077374],
                            [39.376616, -8.02079847],
                            [39.37628226, -8.02081083],
                            [39.37603505, -8.02084791],
                            [39.3759856, -8.02110755],
                            [39.37597324, -8.02133009],
                            [39.37599796, -8.02149081],
                            [39.3761092, -8.02157736],
                            [39.37631934, -8.02157736],
                            [39.37650475, -8.02155264],
                            [39.37664071, -8.02160208],
                            [39.37669017, -8.02173808],
                            [39.37667781, -8.02189881],
                            [39.37655418, -8.02209663],
                            [39.37634407, -8.02230681],
                            [39.37594852, -8.02252935],
                            [39.37562714, -8.02265298],
                            [39.37526869, -8.02265298],
                            [39.37493495, -8.02270242],
                            [39.37461357, -8.02283843],
                            [39.37444051, -8.02302387],
                            [39.3744158, -8.02328351],
                            [39.37450232, -8.02351842],
                            [39.37457647, -8.02376568],
                            [39.37463828, -8.02393878],
                            [39.37461357, -8.02411185],
                            [39.37446523, -8.02424785],
                            [39.37420566, -8.02438386],
                            [39.37378539, -8.02438385],
                            [39.37352583, -8.02438385],
                            [39.37336513, -8.02450748],
                            [39.37324152, -8.02474239],
                            [39.37335277, -8.02498965],
                            [39.37352582, -8.02511329],
                            [39.37364943, -8.02522456],
                            [39.37363707, -8.02533583],
                            [39.37355054, -8.02555837],
                            [39.37355054, -8.02578091],
                            [39.37359999, -8.025954],
                            [39.37359999, -8.02618891],
                            [39.37356289, -8.02639909],
                            [39.37327861, -8.02664635],
                            [39.37301902, -8.02679472],
                            [39.37289542, -8.02690599],
                            [39.37293249, -8.02710379],
                            [39.37300666, -8.0272398],
                            [39.373155, -8.02736344],
                            [39.37338985, -8.02738816],
                            [39.37363706, -8.0273758],
                            [39.37379774, -8.02728925],
                            [39.37405732, -8.02716562],
                            [39.37426746, -8.02702963],
                            [39.37471244, -8.02676999],
                            [39.3749102, -8.026634],
                            [39.37520686, -8.02658455],
                            [39.3753552, -8.02644854],
                            [39.37546644, -8.02625074],
                            [39.37552824, -8.02612709],
                            [39.37557769, -8.02586747],
                            [39.3757013, -8.02564492],
                            [39.37584962, -8.02552129],
                            [39.37605975, -8.02529875],
                            [39.37620808, -8.02503913],
                            [39.37635642, -8.02475476],
                            [39.37648002, -8.02445804],
                            [39.3766778, -8.02421077],
                            [39.37693736, -8.02397587],
                            [39.37730818, -8.02361734],
                            [39.37770373, -8.0232217],
                            [39.3783712, -8.02267771],
                            [39.37897689, -8.02214609],
                            [39.37959491, -8.02161446],
                            [39.37991629, -8.02140428],
                            [39.38034891, -8.02104575],
                            [39.38075682, -8.02073666],
                            [39.38130069, -8.02030394],
                            [39.38156026, -8.02014322],
                            [39.3818322, -8.01990832],
                            [39.38212885, -8.01968578],
                            [39.38242551, -8.01935197],
                            [39.38263564, -8.01905525],
                            [39.38272217, -8.01884507],
                            [39.38296938, -8.01844945],
                            [39.38300646, -8.01815272],
                            [39.3831548, -8.01781891],
                            [39.38336494, -8.01738619],
                            [39.38355034, -8.01712655],
                            [39.38366158, -8.01680511],
                            [39.38374811, -8.01642184],
                            [39.38389644, -8.01608803],
                            [39.38409421, -8.01571713],
                            [39.38429198, -8.01545749],
                            [39.38439087, -8.01528441],
                        ],
                        [
                            [39.38937198, -8.02709294],
                            [39.38922369, -8.02707439],
                            [39.38920516, -8.02727833],
                            [39.38920516, -8.02757498],
                            [39.3892793, -8.02809411],
                            [39.38939051, -8.0285947],
                            [39.3895944, -8.02913237],
                            [39.38972416, -8.02954026],
                            [39.38994659, -8.02994815],
                            [39.39028025, -8.03039312],
                            [39.39061389, -8.03067122],
                            [39.39104021, -8.03091225],
                            [39.39141093, -8.03122744],
                            [39.39170751, -8.03154262],
                            [39.39202262, -8.03189489],
                            [39.39246749, -8.03232132],
                            [39.3929865, -8.03272921],
                            [39.39341283, -8.03324834],
                            [39.3938577, -8.03363769],
                            [39.39424695, -8.03402703],
                            [39.39465474, -8.03452763],
                            [39.39513669, -8.03499114],
                            [39.39554448, -8.03543611],
                            [39.39587813, -8.03589962],
                            [39.39623031, -8.03636313],
                            [39.3965825, -8.03680809],
                            [39.39702736, -8.03721598],
                            [39.39752783, -8.03773511],
                            [39.39782441, -8.03819862],
                            [39.39806537, -8.03856942],
                            [39.39828781, -8.03912564],
                            [39.39852877, -8.03947791],
                            [39.39871414, -8.04010827],
                            [39.39882535, -8.04072011],
                            [39.39906632, -8.04153588],
                            [39.39912192, -8.04235166],
                            [39.39917753, -8.04327868],
                            [39.39915899, -8.04402028],
                            [39.39906631, -8.04448379],
                            [39.39889948, -8.04509562],
                            [39.39875119, -8.04561475],
                            [39.39862143, -8.04607826],
                            [39.39851021, -8.04659738],
                            [39.39837221, -8.04703411],
                            [39.39794382, -8.04759444],
                            [39.39764724, -8.04808885],
                            [39.39705409, -8.04848437],
                            [39.39632911, -8.04884693],
                            [39.39580187, -8.04917654],
                            [39.39534053, -8.04947317],
                            [39.39487917, -8.04976982],
                            [39.39438487, -8.04980277],
                            [39.39362695, -8.04990165],
                            [39.3930338, -8.05000054],
                            [39.39250654, -8.0500335],
                            [39.39194634, -8.05006645],
                            [39.39179226, -8.05006645],
                            [39.39148499, -8.05006645],
                            [39.39109368, -8.0499972],
                            [39.39079673, -8.0499297],
                            [39.3904188, -8.04984869],
                            [39.39009486, -8.04984869],
                            [39.38986538, -8.04987569],
                            [39.38967643, -8.04998369],
                            [39.38941998, -8.0501187],
                            [39.38916352, -8.05025371],
                            [39.38892056, -8.05037522],
                            [39.38855613, -8.05046972],
                            [39.38825918, -8.05046972],
                            [39.38794874, -8.05046971],
                            [39.38765178, -8.05045621],
                            [39.38750332, -8.05036171],
                            [39.38754381, -8.0502402],
                            [39.38769226, -8.0502267],
                            [39.38786776, -8.0502132],
                            [39.38807022, -8.0501997],
                            [39.38835366, -8.05017271],
                            [39.38861012, -8.05015921],
                            [39.38885308, -8.0501322],
                            [39.38901505, -8.0500512],
                            [39.38921752, -8.04995669],
                            [39.389258, -8.04982169],
                            [39.38917702, -8.04975418],
                            [39.38897456, -8.04972719],
                            [39.38866412, -8.04968668],
                            [39.3881512, -8.04965967],
                            [39.3875978, -8.04963267],
                            [39.38689592, -8.04961916],
                            [39.38642349, -8.04965967],
                            [39.3858971, -8.04967317],
                            [39.38542468, -8.04965966],
                            [39.38495228, -8.04960567],
                            [39.38445285, -8.04952465],
                            [39.38402094, -8.04945715],
                            [39.38325158, -8.04922764],
                            [39.38277916, -8.04909262],
                            [39.38245523, -8.04895761],
                            [39.38225275, -8.04887663],
                            [39.38182083, -8.0486741],
                            [39.38144291, -8.0484716],
                            [39.38099749, -8.04822858],
                            [39.38080851, -8.04806657],
                            [39.38057905, -8.04798557],
                            [39.38029561, -8.04790456],
                            [39.38003915, -8.04781005],
                            [39.37970171, -8.04770204],
                            [39.37932378, -8.04759403],
                            [39.37890536, -8.04751303],
                            [39.37851393, -8.04737802],
                            [39.378082, -8.04716201],
                            [39.37735314, -8.04675699],
                            [39.37705618, -8.04652748],
                            [39.37669176, -8.04631147],
                            [39.3764623, -8.04629797],
                            [39.37615185, -8.04640597],
                            [39.37580092, -8.04660848],
                            [39.37540948, -8.046892],
                            [39.37507204, -8.04721601],
                            [39.37488308, -8.04754002],
                            [39.3746941, -8.04776952],
                            [39.3746941, -8.04798554],
                            [39.37482908, -8.04814754],
                            [39.37501804, -8.04829605],
                            [39.375288, -8.04844457],
                            [39.37563893, -8.04855257],
                            [39.37612484, -8.04856608],
                            [39.37651627, -8.04860657],
                            [39.37688071, -8.04863358],
                            [39.37715066, -8.04868759],
                            [39.37748811, -8.04876859],
                            [39.37785253, -8.0488091],
                            [39.37820347, -8.0489036],
                            [39.37854091, -8.0489981],
                            [39.37879737, -8.04911961],
                            [39.37901333, -8.04932212],
                            [39.37921579, -8.04957864],
                            [39.37928327, -8.04974062],
                            [39.37933727, -8.04994314],
                            [39.37937776, -8.05017267],
                            [39.37937776, -8.05048318],
                            [39.37944524, -8.05076667],
                            [39.37960722, -8.0509827],
                            [39.37982318, -8.05106371],
                            [39.37994466, -8.05125272],
                            [39.38001214, -8.05154972],
                            [39.38007963, -8.05184675],
                            [39.38033608, -8.05204926],
                            [39.38059253, -8.05218427],
                            [39.38082199, -8.05219777],
                            [39.38113242, -8.05219777],
                            [39.38136189, -8.05223826],
                            [39.38142939, -8.05238678],
                            [39.38140239, -8.05264329],
                            [39.38126741, -8.05279179],
                            [39.3812539, -8.05302132],
                            [39.38136189, -8.05322384],
                            [39.38155087, -8.05348032],
                            [39.38169933, -8.05369633],
                            [39.38182081, -8.05387185],
                            [39.3818613, -8.05408784],
                            [39.3818883, -8.05427686],
                            [39.38200978, -8.05442538],
                            [39.38215823, -8.05456039],
                            [39.38233372, -8.05468189],
                            [39.38254968, -8.0548034],
                            [39.3829816, -8.05489791],
                            [39.38333254, -8.05489791],
                            [39.3835755, -8.05489791],
                            [39.38380496, -8.05488441],
                            [39.38399392, -8.0549249],
                            [39.38422338, -8.05508692],
                            [39.38442584, -8.0550734],
                            [39.38452032, -8.05488441],
                            [39.3846148, -8.0547629],
                            [39.38480378, -8.0547494],
                            [39.38500624, -8.05478991],
                            [39.3851817, -8.05493841],
                            [39.38545166, -8.05524894],
                            [39.38570811, -8.05551894],
                            [39.38592407, -8.05578896],
                            [39.38604555, -8.05599147],
                            [39.38624801, -8.05622099],
                            [39.38645047, -8.05642349],
                            [39.38661245, -8.05651799],
                            [39.38682841, -8.05651799],
                            [39.38699037, -8.0565855],
                            [39.38728733, -8.0566665],
                            [39.38754379, -8.05682852],
                            [39.38793521, -8.05704453],
                            [39.38817817, -8.05722003],
                            [39.38846161, -8.05726054],
                            [39.38866408, -8.05726054],
                            [39.38878556, -8.05719303],
                            [39.38889354, -8.05704453],
                            [39.38900153, -8.05689602],
                            [39.38912301, -8.05668001],
                            [39.38936595, -8.05649101],
                            [39.38956843, -8.05639649],
                            [39.38974387, -8.0563965],
                            [39.38993285, -8.05641],
                            [39.39010833, -8.05641],
                            [39.39027029, -8.056518],
                            [39.39036478, -8.05668002],
                            [39.39028379, -8.05688253],
                            [39.39005433, -8.05708503],
                            [39.38985186, -8.05734155],
                            [39.3896494, -8.05765206],
                            [39.38951442, -8.05797607],
                            [39.38943344, -8.05816509],
                            [39.38952792, -8.05832709],
                            [39.38981136, -8.05835411],
                            [39.3901488, -8.05824609],
                            [39.39058074, -8.05815159],
                            [39.39093166, -8.05797608],
                            [39.3913231, -8.05771958],
                            [39.39167404, -8.05757107],
                            [39.39201148, -8.05727406],
                            [39.39226794, -8.05711205],
                            [39.3924299, -8.05701754],
                            [39.39274036, -8.05700404],
                            [39.39284834, -8.05688254],
                            [39.39298329, -8.05676103],
                            [39.3930778, -8.05659903],
                            [39.39334775, -8.05611301],
                            [39.39353673, -8.05582948],
                            [39.39376616, -8.05549197],
                            [39.39402263, -8.05515447],
                            [39.39431959, -8.05476295],
                            [39.39469751, -8.05439843],
                            [39.39506195, -8.0540879],
                            [39.39543989, -8.0538314],
                            [39.39566935, -8.0536424],
                            [39.39603378, -8.05348039],
                            [39.39638472, -8.05331839],
                            [39.39678965, -8.05325088],
                            [39.3971001, -8.05314288],
                            [39.39724471, -8.05303162],
                            [39.39727556, -8.05300787],
                            [39.39735656, -8.05288638],
                            [39.39735656, -8.05258935],
                            [39.39735656, -8.05238685],
                            [39.39735656, -8.05226534],
                            [39.39734306, -8.05204933],
                            [39.39739704, -8.05176581],
                            [39.39751852, -8.0514823],
                            [39.397667, -8.05121229],
                            [39.39790996, -8.05105029],
                            [39.3982339, -8.05096929],
                            [39.39855784, -8.05099628],
                            [39.39888178, -8.05106379],
                            [39.39916524, -8.05105029],
                            [39.39951618, -8.05102329],
                            [39.39985362, -8.0510503],
                            [39.40006958, -8.0510503],
                            [39.40029904, -8.05111779],
                            [39.40055548, -8.05125281],
                            [39.40085244, -8.05142831],
                            [39.40116288, -8.05153631],
                            [39.40144632, -8.05168483],
                            [39.40174328, -8.05173884],
                            [39.40199974, -8.05168483],
                            [39.4022022, -8.05163084],
                            [39.40241816, -8.05159033],
                            [39.40267462, -8.05152283],
                            [39.40301206, -8.05137432],
                            [39.403255, -8.05118532],
                            [39.40352496, -8.05117182],
                            [39.40371392, -8.05129333],
                            [39.40398388, -8.05152284],
                            [39.40417284, -8.05169834],
                            [39.40434831, -8.05195486],
                            [39.40452377, -8.05227887],
                            [39.40467225, -8.05262989],
                            [39.40480723, -8.05298091],
                            [39.40500969, -8.05327792],
                            [39.40521215, -8.05354794],
                            [39.40534713, -8.05380444],
                            [39.4055091, -8.05414196],
                            [39.40563057, -8.05439847],
                            [39.40567106, -8.05460098],
                            [39.40567106, -8.05484399],
                            [39.40567106, -8.05520852],
                            [39.40567106, -8.05558654],
                            [39.40567106, -8.05587004],
                            [39.40575204, -8.05612656],
                            [39.40591401, -8.05638308],
                            [39.40606249, -8.05662608],
                            [39.40618397, -8.0568421],
                            [39.40633243, -8.0569096],
                            [39.40649441, -8.0568151],
                            [39.40661589, -8.05650459],
                            [39.40665638, -8.05618056],
                            [39.40661589, -8.05592406],
                            [39.4065619, -8.05562703],
                            [39.40650792, -8.05538402],
                            [39.40645392, -8.05523552],
                            [39.40641342, -8.05501951],
                            [39.40641342, -8.054844],
                            [39.40650792, -8.05461449],
                            [39.40671038, -8.05442548],
                            [39.40700732, -8.05426347],
                            [39.40726378, -8.05412848],
                            [39.40757422, -8.05392596],
                            [39.4077632, -8.05376396],
                            [39.40777669, -8.05325093],
                            [39.40773621, -8.05294042],
                            [39.40766871, -8.0526704],
                            [39.40760123, -8.05234638],
                            [39.40738527, -8.05202237],
                            [39.40716931, -8.05176585],
                            [39.40706133, -8.05161735],
                            [39.40706133, -8.05142833],
                            [39.40696685, -8.05114483],
                            [39.40689936, -8.05088831],
                            [39.40683188, -8.05061831],
                            [39.4067239, -8.05026729],
                            [39.40661592, -8.04992977],
                            [39.40649444, -8.04974075],
                            [39.40631897, -8.04959226],
                            [39.40607601, -8.04945725],
                            [39.40590054, -8.04943024],
                            [39.40583306, -8.04936274],
                            [39.40586005, -8.04914673],
                            [39.40595454, -8.04897122],
                            [39.40607601, -8.0487282],
                            [39.40625149, -8.04841769],
                            [39.40639997, -8.04812069],
                            [39.40645395, -8.04781016],
                            [39.40645395, -8.04755366],
                            [39.40645395, -8.04717564],
                            [39.40637298, -8.04679761],
                            [39.40635677, -8.0465816],
                            [39.4062353, -8.04612258],
                            [39.40618132, -8.04577158],
                            [39.40601934, -8.04550156],
                            [39.40589786, -8.04523154],
                            [39.40576288, -8.04501553],
                            [39.40564142, -8.04470502],
                            [39.40551994, -8.0443675],
                            [39.40546594, -8.04415149],
                            [39.40533097, -8.04393548],
                            [39.40520949, -8.04367896],
                            [39.40504753, -8.04340895],
                            [39.40495305, -8.04316595],
                            [39.40493955, -8.04290943],
                            [39.40493955, -8.04267992],
                            [39.40496655, -8.04253141],
                            [39.40507453, -8.0423019],
                            [39.40518251, -8.04209939],
                            [39.40523649, -8.04186988],
                            [39.405223, -8.04166737],
                            [39.40508802, -8.04146487],
                            [39.40502054, -8.04127585],
                            [39.40496656, -8.04107334],
                            [39.40502054, -8.04084383],
                            [39.40512852, -8.04047931],
                            [39.405277, -8.04023631],
                            [39.40539848, -8.04003379],
                            [39.40541198, -8.03981778],
                            [39.40541198, -8.03958827],
                            [39.40537148, -8.03937226],
                            [39.4052905, -8.03912924],
                            [39.40518253, -8.03888623],
                            [39.40503405, -8.03875124],
                            [39.40485857, -8.03869723],
                            [39.40465611, -8.03858922],
                            [39.40457513, -8.03844071],
                            [39.40454813, -8.0382517],
                            [39.40461563, -8.0380762],
                            [39.40476409, -8.03788718],
                            [39.40492607, -8.03772519],
                            [39.40506105, -8.03757667],
                            [39.40511503, -8.03737417],
                            [39.40506105, -8.03719865],
                            [39.40489908, -8.03696914],
                            [39.40471012, -8.03673963],
                            [39.40453464, -8.03648313],
                            [39.40434568, -8.0361861],
                            [39.40414322, -8.03583508],
                            [39.40398124, -8.03552457],
                            [39.40391376, -8.03524106],
                            [39.40388677, -8.03498455],
                            [39.40390027, -8.03459302],
                            [39.40390027, -8.03421501],
                            [39.40394077, -8.0337965],
                            [39.40396775, -8.03340497],
                            [39.40399475, -8.03309446],
                            [39.40400825, -8.03274344],
                            [39.40400826, -8.03239243],
                            [39.40400826, -8.03205491],
                            [39.40396776, -8.03178489],
                            [39.40384628, -8.03150138],
                            [39.40368432, -8.03117737],
                            [39.40350884, -8.03088035],
                            [39.40329289, -8.03065083],
                            [39.40302294, -8.03038083],
                            [39.40287447, -8.03021881],
                            [39.40272599, -8.03015132],
                            [39.40249653, -8.03011081],
                            [39.40230757, -8.03016482],
                            [39.40224009, -8.03032682],
                            [39.40224008, -8.03051583],
                            [39.40226707, -8.03071834],
                            [39.40233456, -8.03092085],
                            [39.40249652, -8.03113686],
                            [39.402618, -8.03142038],
                            [39.40271248, -8.03167688],
                            [39.40283396, -8.03200089],
                            [39.40292846, -8.03228442],
                            [39.40300944, -8.03260843],
                            [39.40310391, -8.03290544],
                            [39.40327938, -8.03321597],
                            [39.40341435, -8.03358047],
                            [39.40345485, -8.0339045],
                            [39.40345485, -8.03430951],
                            [39.40344135, -8.03468754],
                            [39.40337386, -8.03490355],
                            [39.40321189, -8.03509255],
                            [39.40303642, -8.03518705],
                            [39.40284745, -8.03518705],
                            [39.40275297, -8.03506554],
                            [39.40271247, -8.03486303],
                            [39.40267199, -8.03447152],
                            [39.40271247, -8.03395849],
                            [39.40276647, -8.03363448],
                            [39.40275297, -8.03332397],
                            [39.40264499, -8.03305395],
                            [39.40245603, -8.03279743],
                            [39.40228056, -8.03260843],
                            [39.4020646, -8.03247342],
                            [39.40179466, -8.03236541],
                            [39.40159218, -8.03237891],
                            [39.4014707, -8.03245991],
                            [39.40138972, -8.03264893],
                            [39.40126824, -8.03291893],
                            [39.40114676, -8.03317545],
                            [39.4009308, -8.03322946],
                            [39.40076883, -8.03320245],
                            [39.40068784, -8.03301343],
                            [39.40067436, -8.03278392],
                            [39.40068784, -8.03245991],
                            [39.40068784, -8.03213589],
                            [39.40066086, -8.03186589],
                            [39.40053938, -8.03170388],
                            [39.4003774, -8.03159587],
                            [39.40021544, -8.03158237],
                            [39.40003996, -8.03164988],
                            [39.39993198, -8.03178487],
                            [39.3998105, -8.03198739],
                            [39.39966202, -8.03221689],
                            [39.39954056, -8.03254092],
                            [39.39937858, -8.03281092],
                            [39.39920312, -8.03291892],
                            [39.39902764, -8.03290542],
                            [39.39885218, -8.03277043],
                            [39.3986767, -8.03262191],
                            [39.39847424, -8.03241941],
                            [39.39828528, -8.03221688],
                            [39.3981233, -8.03201438],
                            [39.39793434, -8.03182537],
                            [39.39770488, -8.03170386],
                            [39.39747542, -8.03159586],
                            [39.39707049, -8.03154185],
                            [39.39678705, -8.03147435],
                            [39.39647661, -8.03143384],
                            [39.39619315, -8.03132583],
                            [39.39595019, -8.03124483],
                            [39.39555732, -8.0309612],
                            [39.39552596, -8.03096789],
                            [39.39535914, -8.03078248],
                            [39.39519232, -8.03048585],
                            [39.39495134, -8.03015211],
                            [39.39467331, -8.02979984],
                            [39.39445087, -8.02939195],
                            [39.39420991, -8.02916947],
                            [39.39398746, -8.02907677],
                            [39.39376504, -8.02898408],
                            [39.39363529, -8.02878013],
                            [39.39363529, -8.0285391],
                            [39.39363529, -8.02824245],
                            [39.3936353, -8.02798289],
                            [39.39341285, -8.02777894],
                            [39.39324603, -8.02768624],
                            [39.39306066, -8.0276677],
                            [39.39285678, -8.02772332],
                            [39.39257873, -8.02781603],
                            [39.39230069, -8.02787164],
                            [39.39196703, -8.02781602],
                            [39.391689, -8.02770478],
                            [39.39146657, -8.02750084],
                            [39.39141095, -8.02725981],
                            [39.39139242, -8.02690754],
                            [39.39131828, -8.02668506],
                            [39.39124413, -8.02648112],
                            [39.39105878, -8.02635133],
                            [39.39068804, -8.02627717],
                            [39.39039148, -8.02616593],
                            [39.39016903, -8.02614739],
                            [39.39003929, -8.02624008],
                            [39.3900949, -8.02644403],
                            [39.39016903, -8.02664797],
                            [39.39029879, -8.0267963],
                            [39.39031732, -8.02713003],
                            [39.39029879, -8.02740813],
                            [39.39007636, -8.02750083],
                            [39.38983539, -8.02737104],
                            [39.38961296, -8.02727834],
                            [39.38937198, -8.02709294],
                        ],
                        [
                            [39.40985246, -8.04376202],
                            [39.40985246, -8.04331689],
                            [39.40985246, -8.04283567],
                            [39.40984043, -8.04251086],
                            [39.4098284, -8.04234244],
                            [39.40964799, -8.04210183],
                            [39.40946758, -8.04182514],
                            [39.40933527, -8.04162062],
                            [39.40927513, -8.04133189],
                            [39.40925109, -8.04106722],
                            [39.40922703, -8.04058602],
                            [39.40919095, -8.04017699],
                            [39.40914285, -8.03964765],
                            [39.40911879, -8.03925064],
                            [39.40899852, -8.03862507],
                            [39.4089023, -8.03825213],
                            [39.40879406, -8.03790325],
                            [39.40864972, -8.03753032],
                            [39.40857755, -8.03719346],
                            [39.40845728, -8.03698895],
                            [39.40834903, -8.03688068],
                            [39.40824079, -8.0368927],
                            [39.40816862, -8.03716941],
                            [39.40814457, -8.0374461],
                            [39.40813254, -8.03790325],
                            [39.40812051, -8.03836041],
                            [39.4081205, -8.03872131],
                            [39.4081205, -8.03909425],
                            [39.4081205, -8.03935892],
                            [39.40819267, -8.03963562],
                            [39.40825281, -8.03988826],
                            [39.40831294, -8.04014088],
                            [39.40836105, -8.04040556],
                            [39.40836105, -8.04075444],
                            [39.40836105, -8.04103113],
                            [39.40836104, -8.04153639],
                            [39.4083851, -8.04188529],
                            [39.40840915, -8.04214994],
                            [39.4085655, -8.04243867],
                            [39.40874591, -8.04279958],
                            [39.40889025, -8.04307627],
                            [39.40897445, -8.04341313],
                            [39.40899849, -8.04374998],
                            [39.40898646, -8.04415901],
                            [39.40897444, -8.04450789],
                            [39.40895038, -8.04478458],
                            [39.40895038, -8.04503722],
                            [39.40907066, -8.04522971],
                            [39.4092631, -8.04539813],
                            [39.40943148, -8.04560264],
                            [39.4095277, -8.04569889],
                            [39.40962391, -8.04575904],
                            [39.40978028, -8.04565077],
                            [39.4098765, -8.04543422],
                            [39.40986447, -8.04490488],
                            [39.40985246, -8.04421916],
                            [39.40985246, -8.04376202],
                        ],
                        [
                            [39.42333746, -8.04995776],
                            [39.42322332, -8.05051223],
                            [39.42292872, -8.05140213],
                            [39.42291354, -8.05127872],
                            [39.42252222, -8.0522246],
                            [39.42201678, -8.05318679],
                            [39.42147872, -8.05429574],
                            [39.42084285, -8.05566563],
                            [39.42048414, -8.05690505],
                            [39.42007653, -8.057802],
                            [39.41960368, -8.0587968],
                            [39.4193265, -8.0597753],
                            [39.41901671, -8.06060701],
                            [39.41886996, -8.06124304],
                            [39.418658, -8.06168335],
                            [39.41794061, -8.06223783],
                            [39.41751668, -8.06266184],
                            [39.41706015, -8.06290646],
                            [39.41671776, -8.06290646],
                            [39.41647319, -8.06284123],
                            [39.41632645, -8.06267815],
                            [39.41617971, -8.06243353],
                            [39.41596775, -8.06231937],
                            [39.41573949, -8.06233568],
                            [39.41557643, -8.06249876],
                            [39.41552753, -8.06279231],
                            [39.41583731, -8.06321631],
                            [39.41611449, -8.06344464],
                            [39.41635905, -8.06359142],
                            [39.41658731, -8.06359142],
                            [39.41681558, -8.06346094],
                            [39.41709276, -8.06346094],
                            [39.41741886, -8.06362404],
                            [39.41781015, -8.06367295],
                            [39.41823407, -8.06359142],
                            [39.41862539, -8.06331419],
                            [39.41893517, -8.06308586],
                            [39.4193428, -8.06266185],
                            [39.41961998, -8.06228677],
                            [39.41978302, -8.06179752],
                            [39.41975041, -8.06140612],
                            [39.41983194, -8.06099842],
                            [39.41992977, -8.06049286],
                            [39.42010912, -8.06001992],
                            [39.42043521, -8.05943283],
                            [39.42063087, -8.05892727],
                            [39.4207776, -8.05830756],
                            [39.42094066, -8.05773677],
                            [39.42134828, -8.05693767],
                            [39.42173959, -8.05599179],
                            [39.4221146, -8.05512746],
                            [39.42237548, -8.05427943],
                            [39.42279941, -8.05351295],
                            [39.4231418, -8.05292585],
                            [39.42351681, -8.05204521],
                            [39.42376138, -8.05126242],
                            [39.42410377, -8.05047962],
                            [39.42433205, -8.05002299],
                            [39.42460923, -8.04956636],
                            [39.42483748, -8.0490445],
                            [39.42511466, -8.04849002],
                            [39.42544076, -8.04775615],
                            [39.42575055, -8.0470549],
                            [39.42604404, -8.04650042],
                            [39.42650057, -8.04583178],
                            [39.4266473, -8.04534254],
                            [39.42676144, -8.04480438],
                            [39.42702232, -8.04429881],
                            [39.42746254, -8.04371172],
                            [39.42773972, -8.04335294],
                            [39.42791908, -8.04294524],
                            [39.42795169, -8.04247229],
                            [39.42791908, -8.04208091],
                            [39.42801691, -8.04183628],
                            [39.42791908, -8.04157534],
                            [39.42775604, -8.04155904],
                            [39.42756038, -8.04173842],
                            [39.42736473, -8.04193413],
                            [39.42720167, -8.04234183],
                            [39.4269408, -8.0428963],
                            [39.42669623, -8.04343448],
                            [39.42628862, -8.0442662],
                            [39.4258973, -8.04493483],
                            [39.42558752, -8.04561977],
                            [39.42514728, -8.04661458],
                            [39.42465814, -8.04754414],
                            [39.42428314, -8.04819648],
                            [39.42389182, -8.04863679],
                            [39.42358203, -8.0494196],
                            [39.42333746, -8.04995776],
                        ],
                        [
                            [39.41017184, -8.04947619],
                            [39.41016467, -8.0491674],
                            [39.41016468, -8.0488299],
                            [39.41016468, -8.04847802],
                            [39.41016468, -8.04803998],
                            [39.41014313, -8.04764502],
                            [39.41012878, -8.04743678],
                            [39.4100857, -8.04723571],
                            [39.41004263, -8.0471639],
                            [39.40987751, -8.0471639],
                            [39.40974109, -8.0471639],
                            [39.40956878, -8.0471639],
                            [39.40938212, -8.0471639],
                            [39.40923136, -8.04718543],
                            [39.40906623, -8.04725007],
                            [39.40892264, -8.0473506],
                            [39.40877905, -8.04747267],
                            [39.40868572, -8.04763065],
                            [39.40858521, -8.04778863],
                            [39.40856366, -8.04801125],
                            [39.40860674, -8.04822668],
                            [39.40867136, -8.04838467],
                            [39.40889026, -8.04899106],
                            [39.40884995, -8.04892414],
                            [39.40900699, -8.04928319],
                            [39.40916404, -8.04967591],
                            [39.40932108, -8.05016959],
                            [39.40951179, -8.05076428],
                            [39.40959031, -8.05130285],
                            [39.4096464, -8.051819],
                            [39.40968004, -8.05224536],
                            [39.40973613, -8.0525932],
                            [39.40975856, -8.05295225],
                            [39.40975856, -8.05333373],
                            [39.40975856, -8.05376011],
                            [39.40976978, -8.05428747],
                            [39.40975856, -8.05475873],
                            [39.40981465, -8.0552973],
                            [39.40981464, -8.05570123],
                            [39.40989317, -8.05612761],
                            [39.40993804, -8.05649788],
                            [39.40993804, -8.05696913],
                            [39.40992682, -8.05750771],
                            [39.40992682, -8.05791165],
                            [39.4099156, -8.05836046],
                            [39.40997169, -8.05885415],
                            [39.41002778, -8.05932541],
                            [39.41007263, -8.05976301],
                            [39.41017359, -8.06015572],
                            [39.41027456, -8.06049233],
                            [39.41034187, -8.06082894],
                            [39.41040916, -8.06125532],
                            [39.41056621, -8.0616929],
                            [39.41073449, -8.06200708],
                            [39.41083543, -8.06217538],
                            [39.41100371, -8.06222026],
                            [39.41111589, -8.06215294],
                            [39.41119441, -8.0619622],
                            [39.41123928, -8.06173778],
                            [39.41125048, -8.0615246],
                            [39.41125048, -8.06125532],
                            [39.41129537, -8.06098602],
                            [39.41126172, -8.06070552],
                            [39.41119441, -8.06046989],
                            [39.4111159, -8.06020061],
                            [39.41100372, -8.05986398],
                            [39.4108691, -8.05933663],
                            [39.41080179, -8.05901124],
                            [39.41075692, -8.05866341],
                            [39.41072328, -8.05837169],
                            [39.4107345, -8.05793409],
                            [39.41074572, -8.05745161],
                            [39.41073451, -8.05698035],
                            [39.41072329, -8.05649789],
                            [39.41071208, -8.05600418],
                            [39.41073451, -8.0554544],
                            [39.41071208, -8.0550168],
                            [39.41073452, -8.05464653],
                            [39.41067843, -8.05434358],
                            [39.4105999, -8.05398453],
                            [39.41048772, -8.05365914],
                            [39.41040919, -8.05337862],
                            [39.41029703, -8.05307567],
                            [39.41019606, -8.05288493],
                            [39.41018485, -8.0527054],
                            [39.41018485, -8.052481],
                            [39.41012159, -8.05204699],
                            [39.41009287, -8.05182437],
                            [39.41009287, -8.05165922],
                            [39.41010004, -8.05127862],
                            [39.41010004, -8.05088367],
                            [39.4101144, -8.05053179],
                            [39.41012876, -8.05017993],
                            [39.41012877, -8.04979933],
                            [39.41017184, -8.04947619],
                        ],
                        [
                            [39.3953119, -8.05806875],
                            [39.39526613, -8.05762256],
                            [39.39524326, -8.05746239],
                            [39.39515175, -8.05740517],
                            [39.39506024, -8.05743949],
                            [39.39500305, -8.0575539],
                            [39.39498016, -8.05773697],
                            [39.39500305, -8.05802301],
                            [39.3950488, -8.05833192],
                            [39.39501448, -8.05873236],
                            [39.39490009, -8.05916713],
                            [39.39475138, -8.05948748],
                            [39.39467132, -8.05981928],
                            [39.39465988, -8.06009388],
                            [39.39465988, -8.06033414],
                            [39.39465988, -8.06050576],
                            [39.39465988, -8.06072315],
                            [39.39465988, -8.06100917],
                            [39.39464842, -8.06130663],
                            [39.39467131, -8.06151259],
                            [39.39485432, -8.06163844],
                            [39.39502591, -8.06163845],
                            [39.39520893, -8.06162701],
                            [39.39535763, -8.06152403],
                            [39.39541481, -8.06132953],
                            [39.39544914, -8.0610206],
                            [39.39549489, -8.0606545],
                            [39.39548346, -8.06029982],
                            [39.39554066, -8.05994513],
                            [39.39557497, -8.05971631],
                            [39.39551777, -8.05914425],
                            [39.39547203, -8.05869804],
                            [39.39536908, -8.05830903],
                            [39.3953119, -8.05806875],
                        ],
                        [
                            [39.40366205, -8.05852034],
                            [39.40350317, -8.05792135],
                            [39.40331983, -8.05723676],
                            [39.40313651, -8.05671108],
                            [39.40305096, -8.05628325],
                            [39.40302652, -8.05616098],
                            [39.40295318, -8.05579424],
                            [39.40283097, -8.05530524],
                            [39.40267209, -8.05486516],
                            [39.40247653, -8.05462066],
                            [39.40236654, -8.05459622],
                            [39.40232987, -8.05477958],
                            [39.40232987, -8.05502408],
                            [39.40236653, -8.05545194],
                            [39.40241542, -8.05575756],
                            [39.4025132, -8.05609985],
                            [39.40261097, -8.05649104],
                            [39.4027454, -8.0569678],
                            [39.40276984, -8.05738344],
                            [39.40275763, -8.05773796],
                            [39.40281874, -8.0581536],
                            [39.40292873, -8.05871594],
                            [39.40301429, -8.05919268],
                            [39.4031365, -8.05966947],
                            [39.40335649, -8.05995064],
                            [39.40353983, -8.06006066],
                            [39.40369871, -8.05999954],
                            [39.40379649, -8.05979172],
                            [39.40377204, -8.05942498],
                            [39.40371093, -8.05891154],
                            [39.40366205, -8.05852034],
                        ],
                        [
                            [39.37355807, -8.03309628],
                            [39.37380029, -8.03308417],
                            [39.37410306, -8.03308418],
                            [39.37453906, -8.03315686],
                            [39.3749145, -8.0331932],
                            [39.37513249, -8.03309629],
                            [39.37526572, -8.03293883],
                            [39.37536261, -8.03279345],
                            [39.37536261, -8.03263597],
                            [39.37525361, -8.03250273],
                            [39.37520516, -8.03234525],
                            [39.37526572, -8.032212],
                            [39.37539894, -8.03209086],
                            [39.37555638, -8.03207874],
                            [39.37576227, -8.03206663],
                            [39.37593182, -8.03195761],
                            [39.37614982, -8.03169111],
                            [39.37633148, -8.03147306],
                            [39.37636782, -8.03123079],
                            [39.37630727, -8.03087949],
                            [39.37624672, -8.03061299],
                            [39.37616195, -8.03022536],
                            [39.37616195, -8.02993463],
                            [39.37617406, -8.02971657],
                            [39.37625884, -8.02958333],
                            [39.37640416, -8.02948642],
                            [39.37658582, -8.02943796],
                            [39.37674327, -8.02948642],
                            [39.37682805, -8.02953487],
                            [39.37694915, -8.02963178],
                            [39.37711871, -8.02976504],
                            [39.37723981, -8.02993463],
                            [39.37739726, -8.0301769],
                            [39.37744571, -8.03041918],
                            [39.37749414, -8.03060088],
                            [39.37760314, -8.03075837],
                            [39.37780903, -8.03078259],
                            [39.37800281, -8.03077048],
                            [39.37831769, -8.03058878],
                            [39.3785357, -8.03039496],
                            [39.37874158, -8.03016479],
                            [39.37887479, -8.02983772],
                            [39.37889903, -8.02953488],
                            [39.37885058, -8.02930473],
                            [39.37870526, -8.02909878],
                            [39.37857203, -8.02900187],
                            [39.37842669, -8.02897765],
                            [39.3782087, -8.02888074],
                            [39.37805125, -8.02878383],
                            [39.37783326, -8.02859001],
                            [39.37764757, -8.02842445],
                            [39.37730846, -8.0283114],
                            [39.37677557, -8.02808527],
                            [39.37625884, -8.02792374],
                            [39.37588744, -8.0279076],
                            [39.37553218, -8.02798834],
                            [39.37511232, -8.02821448],
                            [39.37483782, -8.02856981],
                            [39.37461175, -8.02873132],
                            [39.37425648, -8.02876362],
                            [39.37383664, -8.02866671],
                            [39.37307768, -8.02862472],
                            [39.37252994, -8.02868673],
                            [39.37206488, -8.02881078],
                            [39.37170318, -8.02892449],
                            [39.37140347, -8.02904852],
                            [39.37114509, -8.02904852],
                            [39.37095906, -8.02902785],
                            [39.37079371, -8.02889346],
                            [39.37064903, -8.02871774],
                            [39.37050435, -8.02846965],
                            [39.37046301, -8.02829391],
                            [39.37053535, -8.02813887],
                            [39.3706697, -8.02792178],
                            [39.37077305, -8.02777707],
                            [39.37081439, -8.02751864],
                            [39.37078339, -8.02727055],
                            [39.37071105, -8.0271155],
                            [39.37046301, -8.02682606],
                            [39.37023565, -8.02663999],
                            [39.37000828, -8.02643325],
                            [39.36970858, -8.02621618],
                            [39.36945021, -8.0261128],
                            [39.36916085, -8.02606111],
                            [39.3688198, -8.02595775],
                            [39.36861311, -8.02585437],
                            [39.36846843, -8.02567863],
                            [39.36838574, -8.02547189],
                            [39.36839609, -8.0252755],
                            [39.36841676, -8.02516179],
                            [39.3684891, -8.02494472],
                            [39.36850977, -8.02476898],
                            [39.36849943, -8.02461392],
                            [39.36844776, -8.02448988],
                            [39.36834441, -8.02434517],
                            [39.36837542, -8.02419011],
                            [39.36846843, -8.0240764],
                            [39.36861312, -8.02399371],
                            [39.36881981, -8.02385933],
                            [39.36897484, -8.02377663],
                            [39.36900584, -8.02363191],
                            [39.36900584, -8.02347685],
                            [39.36891283, -8.02334247],
                            [39.3687578, -8.02329079],
                            [39.36863379, -8.02317708],
                            [39.36865446, -8.02300136],
                            [39.36874747, -8.0228773],
                            [39.36886116, -8.02274293],
                            [39.36887149, -8.02257753],
                            [39.36876815, -8.02240181],
                            [39.36861312, -8.02227775],
                            [39.36847877, -8.02222607],
                            [39.36827207, -8.02225709],
                            [39.36802405, -8.02230877],
                            [39.36782769, -8.0223191],
                            [39.36756932, -8.0223191],
                            [39.36735228, -8.02226742],
                            [39.36716626, -8.02213303],
                            [39.36702158, -8.02197797],
                            [39.36694924, -8.02177125],
                            [39.36694924, -8.02160584],
                            [39.36700091, -8.0214508],
                            [39.36712494, -8.02132674],
                            [39.36713526, -8.0211717],
                            [39.36709392, -8.02103731],
                            [39.36696991, -8.02095461],
                            [39.36679422, -8.02095461],
                            [39.36653585, -8.02091326],
                            [39.3663705, -8.02076854],
                            [39.36623615, -8.02058248],
                            [39.36613281, -8.02041708],
                            [39.36598813, -8.0202827],
                            [39.3658641, -8.02020001],
                            [39.36569875, -8.02020001],
                            [39.36553339, -8.02022067],
                            [39.36538871, -8.02025169],
                            [39.3652957, -8.02037573],
                            [39.36517167, -8.02054112],
                            [39.36502699, -8.0206445],
                            [39.36484097, -8.02071685],
                            [39.36470661, -8.02068585],
                            [39.36454126, -8.02060314],
                            [39.36437591, -8.02052044],
                            [39.36425188, -8.02054112],
                            [39.36406586, -8.02061347],
                            [39.36397284, -8.02074786],
                            [39.36377649, -8.02078921],
                            [39.36352846, -8.02077887],
                            [39.36329076, -8.02061347],
                            [39.36302206, -8.02054111],
                            [39.36268102, -8.02036539],
                            [39.36241232, -8.02025168],
                            [39.36224696, -8.02022066],
                            [39.36214361, -8.02026201],
                            [39.36202992, -8.02034471],
                            [39.36187491, -8.02052043],
                            [39.36167855, -8.02067549],
                            [39.36148219, -8.02088223],
                            [39.36124448, -8.0210993],
                            [39.36101713, -8.02124403],
                            [39.3608931, -8.02149211],
                            [39.36076909, -8.0217402],
                            [39.36066574, -8.02205032],
                            [39.36059339, -8.02239143],
                            [39.36056239, -8.02267053],
                            [39.36056239, -8.02295997],
                            [39.36056239, -8.02319772],
                            [39.36056428, -8.02324676],
                            [39.36065575, -8.0233268],
                            [39.36101209, -8.02362383],
                            [39.36121994, -8.02371293],
                            [39.36154658, -8.02398024],
                            [39.36187324, -8.02415845],
                            [39.36191749, -8.02419166],
                            [39.36202845, -8.02418055],
                            [39.36228279, -8.02416844],
                            [39.36248867, -8.02416844],
                            [39.36277935, -8.02415633],
                            [39.36308212, -8.02415633],
                            [39.363506, -8.02418055],
                            [39.36380877, -8.02425324],
                            [39.36439009, -8.02444706],
                            [39.36477766, -8.02466512],
                            [39.36524998, -8.02487105],
                            [39.36551642, -8.02502853],
                            [39.36579497, -8.02522235],
                            [39.36592818, -8.0254525],
                            [39.36608563, -8.02571901],
                            [39.36625518, -8.0260582],
                            [39.36642474, -8.02642161],
                            [39.36643685, -8.02689404],
                            [39.36654584, -8.02718477],
                            [39.36663062, -8.02759664],
                            [39.36659427, -8.02797216],
                            [39.36669117, -8.02839615],
                            [39.36676382, -8.02883223],
                            [39.36681227, -8.02912297],
                            [39.36690916, -8.02936523],
                            [39.36704237, -8.0296802],
                            [39.36707871, -8.03004361],
                            [39.36709082, -8.03033434],
                            [39.36712715, -8.03066141],
                            [39.36722403, -8.03100059],
                            [39.36734515, -8.03126709],
                            [39.36758736, -8.03156993],
                            [39.36766003, -8.03175163],
                            [39.36786591, -8.03200603],
                            [39.36801124, -8.03224831],
                            [39.36815658, -8.03246635],
                            [39.36839879, -8.03275709],
                            [39.36854413, -8.03295091],
                            [39.36891956, -8.03338699],
                            [39.36934345, -8.03364138],
                            [39.36945245, -8.0337504],
                            [39.36959777, -8.03388366],
                            [39.36967044, -8.03401691],
                            [39.36981577, -8.0342713],
                            [39.36991266, -8.03448936],
                            [39.37004587, -8.03467106],
                            [39.37025177, -8.03480431],
                            [39.37048187, -8.0347922],
                            [39.3706151, -8.03471951],
                            [39.37074831, -8.03459838],
                            [39.37096632, -8.03445301],
                            [39.37114799, -8.03415017],
                            [39.37145076, -8.03398058],
                            [39.37170509, -8.03371408],
                            [39.37192308, -8.03348393],
                            [39.37211686, -8.03325376],
                            [39.37245597, -8.03314474],
                            [39.37264974, -8.03309628],
                            [39.37290408, -8.03310839],
                            [39.37318263, -8.03313263],
                            [39.3733764, -8.03313263],
                            [39.37355807, -8.03309628],
                        ],
                        [
                            [39.36327373, -8.01817155],
                            [39.36346402, -8.01787104],
                            [39.36356417, -8.01754048],
                            [39.36358419, -8.0171999],
                            [39.36358419, -8.01698955],
                            [39.36346402, -8.01659888],
                            [39.3633138, -8.01619819],
                            [39.36316359, -8.01596781],
                            [39.36306343, -8.01579753],
                            [39.36296328, -8.01567732],
                            [39.36281307, -8.01556712],
                            [39.36260277, -8.01547698],
                            [39.36240246, -8.01546696],
                            [39.36225225, -8.01549701],
                            [39.36210202, -8.01554708],
                            [39.36186167, -8.01563724],
                            [39.36158126, -8.01575745],
                            [39.36124076, -8.01590769],
                            [39.36089024, -8.01608801],
                            [39.36062986, -8.01626831],
                            [39.36046961, -8.01633843],
                            [39.36021925, -8.01633843],
                            [39.36002897, -8.0163785],
                            [39.35992882, -8.01645863],
                            [39.35976859, -8.01659886],
                            [39.35962838, -8.01670906],
                            [39.35949818, -8.01670905],
                            [39.35927786, -8.01670905],
                            [39.35904753, -8.01673911],
                            [39.35888728, -8.0168593],
                            [39.35882721, -8.01705965],
                            [39.35883722, -8.01721991],
                            [39.35895739, -8.01744028],
                            [39.35902749, -8.0176807],
                            [39.35911763, -8.01788104],
                            [39.35920775, -8.01814148],
                            [39.35941807, -8.01836186],
                            [39.35962837, -8.01851211],
                            [39.35984869, -8.01864234],
                            [39.35996887, -8.0188026],
                            [39.35996887, -8.01896288],
                            [39.35987873, -8.01910311],
                            [39.35972852, -8.01919327],
                            [39.35948815, -8.0192133],
                            [39.35931791, -8.01925337],
                            [39.35915768, -8.01933349],
                            [39.35899744, -8.01947374],
                            [39.35883721, -8.019624],
                            [39.35871703, -8.01975421],
                            [39.35858683, -8.01979428],
                            [39.35844663, -8.01977424],
                            [39.35830642, -8.0196941],
                            [39.35815621, -8.01954386],
                            [39.35799596, -8.01939359],
                            [39.35781571, -8.01912314],
                            [39.35766548, -8.01887271],
                            [39.35754531, -8.01867238],
                            [39.35748522, -8.01858222],
                            [39.3574051, -8.01855217],
                            [39.35724487, -8.01855217],
                            [39.35715473, -8.01859224],
                            [39.35704457, -8.01869241],
                            [39.35695444, -8.01881261],
                            [39.35686431, -8.01889275],
                            [39.35676415, -8.01891278],
                            [39.35665399, -8.01884266],
                            [39.35659391, -8.0187525],
                            [39.35657388, -8.01866236],
                            [39.35659391, -8.01854215],
                            [39.3565839, -8.01834181],
                            [39.35650378, -8.01810139],
                            [39.35635355, -8.01803129],
                            [39.35620334, -8.01805132],
                            [39.35601306, -8.01813144],
                            [39.35589288, -8.01824164],
                            [39.3557126, -8.01843195],
                            [39.35549228, -8.0186323],
                            [39.35527196, -8.01885267],
                            [39.35503161, -8.01906302],
                            [39.35480126, -8.01912312],
                            [39.35448079, -8.01912312],
                            [39.35414029, -8.01906302],
                            [39.35386989, -8.01891277],
                            [39.35365959, -8.01873245],
                            [39.35346932, -8.01847201],
                            [39.35326901, -8.01816149],
                            [39.35306873, -8.01788101],
                            [39.35289847, -8.01764061],
                            [39.35275827, -8.01753041],
                            [39.35265812, -8.01753041],
                            [39.3524378, -8.01749034],
                            [39.3522375, -8.01749034],
                            [39.35203721, -8.01752039],
                            [39.3518269, -8.01755044],
                            [39.35163661, -8.01760053],
                            [39.35140628, -8.01767065],
                            [39.35120599, -8.01774077],
                            [39.35101721, -8.01781629],
                            [39.35121285, -8.01803992],
                            [39.35122222, -8.01805282],
                            [39.3514504, -8.01836664],
                            [39.35162856, -8.01875276],
                            [39.35168795, -8.01899037],
                            [39.35168795, -8.01928738],
                            [39.35180672, -8.01982199],
                            [39.35180672, -8.02029724],
                            [39.35180672, -8.02059424],
                            [39.35192551, -8.02098036],
                            [39.35204428, -8.02127737],
                            [39.35222245, -8.02157438],
                            [39.35243031, -8.0218417],
                            [39.35263818, -8.0220199],
                            [39.35290542, -8.02225751],
                            [39.35332115, -8.02243575],
                            [39.35388536, -8.02276245],
                            [39.35415261, -8.02294066],
                            [39.35450894, -8.02311888],
                            [39.35489497, -8.02338618],
                            [39.35531069, -8.0236535],
                            [39.35566702, -8.02386141],
                            [39.35587489, -8.02406931],
                            [39.35629062, -8.02406931],
                            [39.35661726, -8.02406932],
                            [39.3569142, -8.02403962],
                            [39.35709237, -8.02389111],
                            [39.35715176, -8.02365351],
                            [39.35724085, -8.02338619],
                            [39.35727054, -8.02308918],
                            [39.35732994, -8.02276247],
                            [39.35738933, -8.02240606],
                            [39.35741902, -8.02204963],
                            [39.3575378, -8.02169321],
                            [39.3575378, -8.02136651],
                            [39.35765658, -8.02115859],
                            [39.35792383, -8.02106948],
                            [39.35813172, -8.02106948],
                            [39.35845834, -8.02109919],
                            [39.35878499, -8.0213368],
                            [39.35902254, -8.02160412],
                            [39.3592601, -8.02187143],
                            [39.35949766, -8.02210904],
                            [39.35967582, -8.02231694],
                            [39.3598243, -8.02249518],
                            [39.35997276, -8.02267338],
                            [39.36011365, -8.02281432],
                            [39.36034942, -8.02207817],
                            [39.36048962, -8.02172757],
                            [39.36055973, -8.02148717],
                            [39.3606699, -8.02123673],
                            [39.36082011, -8.0210965],
                            [39.36094029, -8.02093622],
                            [39.36110052, -8.02078598],
                            [39.36130083, -8.02061568],
                            [39.36167138, -8.02031517],
                            [39.36197182, -8.01994456],
                            [39.36231232, -8.01957393],
                            [39.3625026, -8.01924337],
                            [39.36287315, -8.01883267],
                            [39.36308345, -8.01850211],
                            [39.36327373, -8.01817155],
                        ],
                        [
                            [39.36318212, -8.01547681],
                            [39.36335045, -8.01572936],
                            [39.3634827, -8.01596987],
                            [39.363639, -8.01628256],
                            [39.36375923, -8.01664333],
                            [39.36378328, -8.01691992],
                            [39.36374721, -8.01728069],
                            [39.36369912, -8.0176054],
                            [39.36360292, -8.0179301],
                            [39.3633865, -8.01832695],
                            [39.36314604, -8.01871179],
                            [39.36295367, -8.01906054],
                            [39.36279737, -8.01939726],
                            [39.36256892, -8.0196498],
                            [39.36243666, -8.01984221],
                            [39.36248475, -8.0199264],
                            [39.36268915, -8.01993842],
                            [39.36295367, -8.01981816],
                            [39.36336245, -8.0196017],
                            [39.36366303, -8.01936119],
                            [39.36386743, -8.0191327],
                            [39.3640598, -8.0189523],
                            [39.36415599, -8.018808],
                            [39.36415599, -8.01860355],
                            [39.3641079, -8.01844721],
                            [39.36404777, -8.018351],
                            [39.36401171, -8.01821873],
                            [39.36404778, -8.01803834],
                            [39.36414398, -8.01786998],
                            [39.36436039, -8.01766553],
                            [39.3646249, -8.01746109],
                            [39.3647812, -8.01722058],
                            [39.3647812, -8.01694398],
                            [39.36472108, -8.01671548],
                            [39.36457681, -8.01653509],
                            [39.36440849, -8.01641483],
                            [39.36428826, -8.01630661],
                            [39.36420409, -8.01617432],
                            [39.36418578, -8.01586109],
                            [39.36418578, -8.01580236],
                            [39.36430028, -8.0154648],
                            [39.36438445, -8.01527237],
                            [39.36458885, -8.01504389],
                            [39.36473312, -8.01492362],
                            [39.36486538, -8.0147673],
                            [39.36491347, -8.01459892],
                            [39.36479323, -8.01447866],
                            [39.36466099, -8.01441854],
                            [39.36446862, -8.01443056],
                            [39.36405982, -8.0143584],
                            [39.36360293, -8.01420206],
                            [39.36320617, -8.01397358],
                            [39.36277334, -8.013709],
                            [39.36250882, -8.01344443],
                            [39.36218421, -8.01303554],
                            [39.36195577, -8.01254248],
                            [39.36182351, -8.01218171],
                            [39.36169125, -8.01192916],
                            [39.36159507, -8.0118089],
                            [39.36142675, -8.01166459],
                            [39.36117426, -8.01154434],
                            [39.36092177, -8.01147217],
                            [39.36064525, -8.01140001],
                            [39.36012824, -8.01127975],
                            [39.35961125, -8.01121963],
                            [39.35907021, -8.01117152],
                            [39.358445, -8.01114747],
                            [39.35778373, -8.01093099],
                            [39.35720661, -8.01064237],
                            [39.35676175, -8.01038982],
                            [39.3564732, -8.01020943],
                            [39.35635297, -8.01001702],
                            [39.35630487, -8.00978852],
                            [39.35628082, -8.00954801],
                            [39.35630487, -8.00939167],
                            [39.35647321, -8.00918722],
                            [39.35671367, -8.00892266],
                            [39.35691807, -8.00869416],
                            [39.35701425, -8.00848974],
                            [39.35701425, -8.00816503],
                            [39.35701425, -8.00780425],
                            [39.35696616, -8.0075998],
                            [39.3567257, -8.00765994],
                            [39.35652131, -8.00781628],
                            [39.35629286, -8.00802071],
                            [39.35614858, -8.0082011],
                            [39.35599228, -8.00840554],
                            [39.35590811, -8.00862201],
                            [39.35594418, -8.0088986],
                            [39.35592014, -8.00919925],
                            [39.3559081, -8.00948787],
                            [39.3557999, -8.0098246],
                            [39.35565563, -8.01024551],
                            [39.35548729, -8.01070248],
                            [39.3553911, -8.01114746],
                            [39.35529492, -8.01150823],
                            [39.3552829, -8.01200129],
                            [39.35530694, -8.0122899],
                            [39.35530694, -8.0125184],
                            [39.35542717, -8.01267474],
                            [39.35555943, -8.0127469],
                            [39.35572775, -8.01267474],
                            [39.35592012, -8.01262664],
                            [39.35614856, -8.01261461],
                            [39.35634093, -8.01268678],
                            [39.35647319, -8.0128431],
                            [39.35650925, -8.01316781],
                            [39.35652128, -8.01349251],
                            [39.35652128, -8.01366087],
                            [39.35660543, -8.01379316],
                            [39.35671365, -8.0139495],
                            [39.35679781, -8.01409381],
                            [39.35683387, -8.0142742],
                            [39.35683387, -8.01450268],
                            [39.35683387, -8.01480333],
                            [39.3568459, -8.01500777],
                            [39.3569541, -8.01523627],
                            [39.3571585, -8.01545273],
                            [39.3573148, -8.01550084],
                            [39.35748312, -8.01547679],
                            [39.35759134, -8.01535653],
                            [39.35768752, -8.01516412],
                            [39.35779572, -8.01495968],
                            [39.35792798, -8.01480333],
                            [39.35806025, -8.01480334],
                            [39.35821654, -8.01485144],
                            [39.35843296, -8.01498373],
                            [39.35864938, -8.0149717],
                            [39.35878164, -8.01486347],
                            [39.35891388, -8.01468308],
                            [39.35899805, -8.01444257],
                            [39.35911828, -8.01416597],
                            [39.35946696, -8.01390141],
                            [39.35979159, -8.01372102],
                            [39.35999599, -8.01346847],
                            [39.36023645, -8.01336023],
                            [39.36050095, -8.01336023],
                            [39.36070535, -8.01343239],
                            [39.3608857, -8.01354063],
                            [39.36101796, -8.01364887],
                            [39.36101796, -8.01399762],
                            [39.36096985, -8.01438244],
                            [39.36093379, -8.01467107],
                            [39.36065725, -8.01501982],
                            [39.36039274, -8.01541668],
                            [39.36011621, -8.01574136],
                            [39.35998395, -8.01592175],
                            [39.35998394, -8.01609011],
                            [39.36009216, -8.01623444],
                            [39.36027251, -8.01623444],
                            [39.36047689, -8.01611417],
                            [39.36075343, -8.01593379],
                            [39.36099391, -8.01576542],
                            [39.36127043, -8.01562111],
                            [39.36175136, -8.0154287],
                            [39.36206396, -8.01529641],
                            [39.36244871, -8.01520021],
                            [39.36271322, -8.01520021],
                            [39.36297774, -8.01532047],
                            [39.36318212, -8.01547681],
                        ],
                        [
                            [39.34969239, -8.01170029],
                            [39.34986041, -8.01162826],
                            [39.35017243, -8.0114122],
                            [39.35054447, -8.0111121],
                            [39.35080849, -8.01083602],
                            [39.3509525, -8.01057194],
                            [39.35106051, -8.01031986],
                            [39.35118052, -8.01011579],
                            [39.35151656, -8.00971968],
                            [39.35187659, -8.00916751],
                            [39.3521046, -8.00865135],
                            [39.35224861, -8.00819521],
                            [39.35234463, -8.00777508],
                            [39.35235663, -8.00725892],
                            [39.35239264, -8.0068148],
                            [39.35270467, -8.00615458],
                            [39.35292069, -8.00569845],
                            [39.3530527, -8.00544637],
                            [39.3530527, -8.00517029],
                            [39.35305271, -8.00470214],
                            [39.35311271, -8.00420999],
                            [39.35318472, -8.0038979],
                            [39.35322072, -8.00363382],
                            [39.35317272, -8.00336974],
                            [39.35302871, -8.00320167],
                            [39.35286069, -8.00311766],
                            [39.35268068, -8.00308163],
                            [39.35248866, -8.00312966],
                            [39.35230865, -8.0032857],
                            [39.35222463, -8.00345375],
                            [39.35205662, -8.00365782],
                            [39.3518166, -8.00389789],
                            [39.35160058, -8.00412595],
                            [39.35133656, -8.00437803],
                            [39.35119255, -8.00454609],
                            [39.35108453, -8.00482217],
                            [39.35089252, -8.00512226],
                            [39.3507005, -8.00541036],
                            [39.35048449, -8.00562641],
                            [39.35028047, -8.00589049],
                            [39.35017245, -8.00611856],
                            [39.35016045, -8.00632263],
                            [39.35016045, -8.00646667],
                            [39.35022047, -8.00661072],
                            [39.35014597, -8.00712073],
                            [39.35011245, -8.00712687],
                            [39.34993243, -8.00736695],
                            [39.34981242, -8.00760703],
                            [39.34970442, -8.00789511],
                            [39.34959641, -8.0081952],
                            [39.34951239, -8.0085073],
                            [39.34934438, -8.00884341],
                            [39.34914036, -8.00913149],
                            [39.34884034, -8.00939557],
                            [39.34858832, -8.00943158],
                            [39.34837229, -8.00935956],
                            [39.34827628, -8.00933554],
                            [39.34810827, -8.00933554],
                            [39.34794026, -8.00937156],
                            [39.34773624, -8.00947958],
                            [39.34750822, -8.00967165],
                            [39.34725619, -8.00976768],
                            [39.34702819, -8.00976768],
                            [39.34681217, -8.00983969],
                            [39.34660814, -8.00998373],
                            [39.34636812, -8.01011577],
                            [39.34624811, -8.01023581],
                            [39.34622411, -8.01048789],
                            [39.34624811, -8.01069196],
                            [39.34638012, -8.01099205],
                            [39.34645213, -8.01119612],
                            [39.34653614, -8.01134016],
                            [39.34668015, -8.01143619],
                            [39.34695617, -8.01150821],
                            [39.34713618, -8.01159224],
                            [39.3473522, -8.01168827],
                            [39.34749621, -8.01179631],
                            [39.34758022, -8.01196436],
                            [39.34756822, -8.01221643],
                            [39.34747221, -8.01249252],
                            [39.3474722, -8.01274459],
                            [39.34755622, -8.01291264],
                            [39.34768823, -8.01298467],
                            [39.34786824, -8.01296067],
                            [39.34806026, -8.01285263],
                            [39.34826427, -8.01281663],
                            [39.34846829, -8.01280463],
                            [39.3486363, -8.0127206],
                            [39.34884032, -8.01246852],
                            [39.34906835, -8.01219244],
                            [39.34928436, -8.01192836],
                            [39.34951238, -8.0117603],
                            [39.34969239, -8.01170029],
                        ],
                        [
                            [39.29800673, -8.00108445],
                            [39.29823079, -8.00084791],
                            [39.29824324, -8.00063626],
                            [39.29818099, -8.0004744],
                            [39.29791961, -8.00021296],
                            [39.2976458, -7.99996394],
                            [39.29744662, -7.99964026],
                            [39.29721013, -7.99930412],
                            [39.29699853, -7.99912982],
                            [39.29676204, -7.99890571],
                            [39.29657533, -7.99875633],
                            [39.29638862, -7.998582],
                            [39.29627661, -7.99848241],
                            [39.29608988, -7.99848241],
                            [39.29594053, -7.99864425],
                            [39.29575381, -7.99884348],
                            [39.2954302, -7.99911736],
                            [39.2952186, -7.99930411],
                            [39.29509412, -7.99944106],
                            [39.29493231, -7.9996776],
                            [39.29485763, -7.9999017],
                            [39.29473316, -8.00012579],
                            [39.29449665, -8.00023784],
                            [39.29405959, -8.00055602],
                            [39.29403989, -8.00061516],
                            [39.29385638, -8.00091343],
                            [39.29378757, -8.00128053],
                            [39.29378757, -8.00162469],
                            [39.2936958, -8.00194589],
                            [39.29364993, -8.00224416],
                            [39.29360406, -8.0025195],
                            [39.29360405, -8.00281777],
                            [39.29360405, -8.00307015],
                            [39.29355818, -8.00332252],
                            [39.29353526, -8.00366666],
                            [39.29353526, -8.00396494],
                            [39.29348935, -8.00426321],
                            [39.29344347, -8.00453854],
                            [39.29337466, -8.00488268],
                            [39.29335173, -8.00531862],
                            [39.29325996, -8.00559394],
                            [39.29323702, -8.00593808],
                            [39.29321409, -8.00623636],
                            [39.29309939, -8.0066264],
                            [39.29307271, -8.00677755],
                            [39.29331416, -8.00694833],
                            [39.29368756, -8.00713507],
                            [39.29402364, -8.00729693],
                            [39.29433482, -8.00744634],
                            [39.29450908, -8.00745878],
                            [39.29474557, -8.00732183],
                            [39.2950194, -8.00692345],
                            [39.29529325, -8.00616399],
                            [39.29539283, -8.00562864],
                            [39.29552973, -8.0052178],
                            [39.29559198, -8.00484431],
                            [39.29562933, -8.00448326],
                            [39.29576624, -8.00415956],
                            [39.29595296, -8.00372382],
                            [39.29622679, -8.00335032],
                            [39.29640105, -8.00295193],
                            [39.29665, -8.00257843],
                            [39.29701097, -8.00216759],
                            [39.29737193, -8.00171939],
                            [39.29775779, -8.00134591],
                            [39.29800673, -8.00108445],
                        ],
                        [
                            [39.2794116, -7.9986111],
                            [39.27953643, -7.998403],
                            [39.27952257, -7.99818104],
                            [39.27930064, -7.99802843],
                            [39.27906486, -7.99784808],
                            [39.2788568, -7.99773708],
                            [39.27856552, -7.99773707],
                            [39.27824651, -7.99782032],
                            [39.27791362, -7.99782032],
                            [39.27770557, -7.9977787],
                            [39.2774559, -7.99763996],
                            [39.2774143, -7.99739024],
                            [39.27748366, -7.99716828],
                            [39.27783041, -7.99686305],
                            [39.27814943, -7.99661333],
                            [39.27848231, -7.99633588],
                            [39.27871809, -7.99622489],
                            [39.27900938, -7.99597517],
                            [39.27914808, -7.99571158],
                            [39.27912035, -7.9954896],
                            [39.27888454, -7.99542024],
                            [39.27866263, -7.99554509],
                            [39.27848231, -7.99572545],
                            [39.27824652, -7.99579481],
                            [39.2780662, -7.99568383],
                            [39.27789977, -7.99553122],
                            [39.27766397, -7.99562834],
                            [39.27738657, -7.9957948],
                            [39.27716464, -7.99598904],
                            [39.27698434, -7.9960584],
                            [39.27674853, -7.9960584],
                            [39.27647114, -7.99605839],
                            [39.27631857, -7.99597516],
                            [39.27626308, -7.99573932],
                            [39.27636018, -7.99557284],
                            [39.27662371, -7.99539247],
                            [39.27697047, -7.99522601],
                            [39.27733109, -7.99508726],
                            [39.27767784, -7.99489304],
                            [39.27791363, -7.99467108],
                            [39.27837135, -7.99435199],
                            [39.27877359, -7.99414389],
                            [39.27948098, -7.99386642],
                            [39.2802577, -7.99364446],
                            [39.2807709, -7.99340861],
                            [39.28120088, -7.99308954],
                            [39.28153375, -7.99265946],
                            [39.28175569, -7.99239585],
                            [39.28199148, -7.99221552],
                            [39.28226889, -7.99204903],
                            [39.28253243, -7.99188255],
                            [39.28250468, -7.99171608],
                            [39.28213018, -7.99161895],
                            [39.28170022, -7.99139699],
                            [39.28147829, -7.9912305],
                            [39.28131186, -7.99109177],
                            [39.28113155, -7.9910224],
                            [39.28103445, -7.99111951],
                            [39.28104832, -7.9914941],
                            [39.28107605, -7.99179931],
                            [39.28107605, -7.99206291],
                            [39.28095123, -7.9923265],
                            [39.28065994, -7.99254846],
                            [39.28035479, -7.99268719],
                            [39.28006353, -7.99271495],
                            [39.2794255, -7.99267333],
                            [39.27887068, -7.99272882],
                            [39.27846845, -7.99289528],
                            [39.27814944, -7.99308952],
                            [39.27787203, -7.99325599],
                            [39.27756689, -7.99338086],
                            [39.27723401, -7.99360282],
                            [39.2769982, -7.99383867],
                            [39.27669306, -7.99399128],
                            [39.27615213, -7.99415776],
                            [39.27572214, -7.99426873],
                            [39.27529218, -7.99435198],
                            [39.27494542, -7.9945462],
                            [39.27466801, -7.99485141],
                            [39.27444608, -7.99525374],
                            [39.27426577, -7.99576707],
                            [39.27421029, -7.99603065],
                            [39.27418818, -7.99604377],
                            [39.27394675, -7.99654396],
                            [39.27382193, -7.99684917],
                            [39.27386353, -7.997196],
                            [39.27401612, -7.99758445],
                            [39.27441832, -7.99782031],
                            [39.27483444, -7.99788967],
                            [39.27525055, -7.99798678],
                            [39.27566663, -7.99815326],
                            [39.27584697, -7.99830587],
                            [39.27602727, -7.99848623],
                            [39.2762492, -7.99872208],
                            [39.27655434, -7.99887468],
                            [39.27688722, -7.99893018],
                            [39.2771785, -7.99888856],
                            [39.27744204, -7.99884695],
                            [39.27774718, -7.99877758],
                            [39.27801071, -7.99879146],
                            [39.27823263, -7.9988192],
                            [39.27864874, -7.99872209],
                            [39.27902324, -7.99863885],
                            [39.2794116, -7.9986111],
                        ],
                        [
                            [39.26957451, -7.99806668],
                            [39.26941455, -7.99783283],
                            [39.26918076, -7.9976605],
                            [39.268787, -7.99735284],
                            [39.26831943, -7.99693437],
                            [39.26769188, -7.99612206],
                            [39.2675196, -7.99581437],
                            [39.26738425, -7.99551897],
                            [39.26732274, -7.99534668],
                            [39.26712586, -7.99522361],
                            [39.26691668, -7.99519899],
                            [39.26653522, -7.99500206],
                            [39.2662276, -7.99478052],
                            [39.26589538, -7.99471898],
                            [39.26588428, -7.99471204],
                            [39.26588834, -7.99475672],
                            [39.26721482, -7.99652369],
                            [39.26896755, -7.99787226],
                            [39.26959329, -7.9980809],
                            [39.26957451, -7.99806668],
                        ],
                        [
                            [39.3277687, -7.99592099],
                            [39.32789971, -7.99580307],
                            [39.32808311, -7.99568516],
                            [39.32826651, -7.995541],
                            [39.32844991, -7.99544928],
                            [39.32865951, -7.99531825],
                            [39.32889533, -7.99518722],
                            [39.32918354, -7.99502998],
                            [39.32949795, -7.99491205],
                            [39.32983854, -7.99464999],
                            [39.33011366, -7.99436173],
                            [39.33045426, -7.99399484],
                            [39.33078178, -7.99357554],
                            [39.33103067, -7.99320865],
                            [39.33117478, -7.99289417],
                            [39.33131888, -7.99255348],
                            [39.33139749, -7.99227832],
                            [39.33165253, -7.99178449],
                            [39.33189816, -7.99133919],
                            [39.33215915, -7.99089388],
                            [39.3322666, -7.99061748],
                            [39.3322052, -7.99050999],
                            [39.33206704, -7.99049465],
                            [39.3318214, -7.99049464],
                            [39.33157579, -7.99063284],
                            [39.33122269, -7.99077104],
                            [39.33091564, -7.99089387],
                            [39.33060862, -7.99092458],
                            [39.33033227, -7.9909553],
                            [39.33019412, -7.99084781],
                            [39.33019412, -7.99063283],
                            [39.33025553, -7.99034108],
                            [39.3304551, -7.99008005],
                            [39.33065467, -7.98975758],
                            [39.33102313, -7.98946582],
                            [39.33133015, -7.98917407],
                            [39.3316372, -7.98883627],
                            [39.33194424, -7.98855986],
                            [39.33226661, -7.98829883],
                            [39.33255832, -7.98802242],
                            [39.33288072, -7.98766925],
                            [39.33321844, -7.98713184],
                            [39.33332591, -7.98667116],
                            [39.33343337, -7.98611837],
                            [39.33354084, -7.98573449],
                            [39.33367902, -7.98531989],
                            [39.33386324, -7.98487459],
                            [39.33412421, -7.98449071],
                            [39.33446197, -7.98413754],
                            [39.33479972, -7.983769],
                            [39.33495322, -7.98344654],
                            [39.33504534, -7.9831855],
                            [39.3350607, -7.9828784],
                            [39.33524491, -7.98258666],
                            [39.33549054, -7.98232561],
                            [39.33572082, -7.98218741],
                            [39.33615069, -7.98192637],
                            [39.33645771, -7.98169605],
                            [39.33707178, -7.98134287],
                            [39.33756306, -7.98106648],
                            [39.33797756, -7.9808515],
                            [39.33849953, -7.98062118],
                            [39.33894473, -7.98039084],
                            [39.33942065, -7.9800684],
                            [39.3399119, -7.97966915],
                            [39.34021895, -7.97926992],
                            [39.34089442, -7.9783486],
                            [39.34121682, -7.97796472],
                            [39.34176949, -7.97728908],
                            [39.34206117, -7.97702805],
                            [39.34230681, -7.97678236],
                            [39.34259849, -7.9764906],
                            [39.34281342, -7.97612208],
                            [39.34307441, -7.97576891],
                            [39.34345821, -7.97550786],
                            [39.34391876, -7.97527754],
                            [39.34442538, -7.97501649],
                            [39.34491664, -7.97486295],
                            [39.34542325, -7.9746787],
                            [39.34591452, -7.97447907],
                            [39.34612945, -7.97440229],
                            [39.34659, -7.97417197],
                            [39.3470045, -7.97400305],
                            [39.34743434, -7.97386486],
                            [39.34772603, -7.97384951],
                            [39.34814053, -7.97384951],
                            [39.34847828, -7.97391095],
                            [39.34875462, -7.97401842],
                            [39.34916913, -7.97411057],
                            [39.34944545, -7.97411057],
                            [39.34982925, -7.9739263],
                            [39.35015164, -7.97366525],
                            [39.35045868, -7.9733428],
                            [39.35071967, -7.97297427],
                            [39.35104205, -7.97232934],
                            [39.35130304, -7.97185333],
                            [39.35141051, -7.9713773],
                            [39.35142585, -7.97085524],
                            [39.35145656, -7.97050206],
                            [39.35145656, -7.97027174],
                            [39.35130305, -7.97022565],
                            [39.3510574, -7.97022565],
                            [39.35091925, -7.97010283],
                            [39.35101136, -7.96991856],
                            [39.35101136, -7.96964215],
                            [39.35085783, -7.96967287],
                            [39.35061222, -7.96967287],
                            [39.35042799, -7.96959609],
                            [39.35032054, -7.96951931],
                            [39.35013632, -7.96953467],
                            [39.34990603, -7.96961144],
                            [39.3496604, -7.96970358],
                            [39.34944547, -7.96956538],
                            [39.34959899, -7.96936576],
                            [39.3497218, -7.96919684],
                            [39.34967574, -7.96910472],
                            [39.34955295, -7.96908937],
                            [39.34955295, -7.96895117],
                            [39.3496297, -7.96881296],
                            [39.34978322, -7.96855193],
                            [39.34978323, -7.96826017],
                            [39.34978323, -7.96796843],
                            [39.34989068, -7.96761525],
                            [39.35009025, -7.96733884],
                            [39.35028982, -7.9671853],
                            [39.35052012, -7.96709317],
                            [39.35068898, -7.96709317],
                            [39.3508732, -7.96715458],
                            [39.35102673, -7.96724672],
                            [39.35119559, -7.96749241],
                            [39.35128771, -7.967907],
                            [39.35136446, -7.96824483],
                            [39.35151798, -7.96847515],
                            [39.35177896, -7.96850586],
                            [39.35203995, -7.96844445],
                            [39.35237769, -7.96819876],
                            [39.35262332, -7.96786095],
                            [39.35299176, -7.96738494],
                            [39.35342162, -7.96681679],
                            [39.35389752, -7.96612579],
                            [39.35408175, -7.96583405],
                            [39.35432739, -7.96545017],
                            [39.35445021, -7.96502021],
                            [39.35445021, -7.9646824],
                            [39.35431203, -7.96439064],
                            [39.35426599, -7.96416032],
                            [39.35435811, -7.96403747],
                            [39.35455768, -7.9639607],
                            [39.35481865, -7.96392999],
                            [39.35507963, -7.9638532],
                            [39.35535598, -7.96363824],
                            [39.35546343, -7.96343861],
                            [39.35567837, -7.9631008],
                            [39.3558933, -7.96285511],
                            [39.35603146, -7.96270155],
                            [39.35624638, -7.9626555],
                            [39.35661482, -7.96276299],
                            [39.35693722, -7.96287048],
                            [39.35719819, -7.96288583],
                            [39.35741313, -7.96276299],
                            [39.35761271, -7.96250194],
                            [39.35782764, -7.96236375],
                            [39.35801186, -7.96237911],
                            [39.35821143, -7.96247124],
                            [39.35839566, -7.96256337],
                            [39.35848777, -7.96244053],
                            [39.35865663, -7.96224092],
                            [39.35865664, -7.96194916],
                            [39.35865664, -7.96168813],
                            [39.35857989, -7.96136566],
                            [39.35857989, -7.96107392],
                            [39.35857989, -7.96072074],
                            [39.35870269, -7.96019865],
                            [39.35871805, -7.95987618],
                            [39.35874877, -7.95938482],
                            [39.35881017, -7.95893952],
                            [39.35888692, -7.95850955],
                            [39.35890228, -7.95815638],
                            [39.35884088, -7.95798749],
                            [39.35861061, -7.95789534],
                            [39.3582882, -7.95774178],
                            [39.35802723, -7.95761896],
                            [39.35781231, -7.9573579],
                            [39.35765879, -7.95712757],
                            [39.35756667, -7.95683583],
                            [39.35747456, -7.95659013],
                            [39.357275, -7.95640587],
                            [39.35694979, -7.95634509],
                            [39.35661568, -7.95667695],
                            [39.35651087, -7.95691279],
                            [39.35637987, -7.95722727],
                            [39.35632747, -7.95754175],
                            [39.35622266, -7.95794795],
                            [39.35606546, -7.95831485],
                            [39.35588206, -7.95862933],
                            [39.35563314, -7.95903553],
                            [39.35546284, -7.95923208],
                            [39.35531873, -7.95949413],
                            [39.35499124, -7.95979551],
                            [39.35466372, -7.96012308],
                            [39.35437551, -7.96047687],
                            [39.35411349, -7.96079135],
                            [39.353786, -7.96114514],
                            [39.35355018, -7.96140719],
                            [39.35327508, -7.96162995],
                            [39.35305237, -7.96180029],
                            [39.35284277, -7.96197062],
                            [39.35258077, -7.96204926],
                            [39.35229256, -7.96204926],
                            [39.35205676, -7.96212787],
                            [39.35186025, -7.96237682],
                            [39.35162444, -7.9626389],
                            [39.35141482, -7.96283545],
                            [39.35124453, -7.96295336],
                            [39.35102182, -7.9630451],
                            [39.35073362, -7.9630844],
                            [39.35030132, -7.96307128],
                            [39.34996071, -7.9631106],
                            [39.34963319, -7.96305819],
                            [39.34935809, -7.96295335],
                            [39.3490175, -7.96287474],
                            [39.34872928, -7.96282233],
                            [39.34846727, -7.9627306],
                            [39.34823147, -7.96262579],
                            [39.34808736, -7.96252095],
                            [39.34781227, -7.96248163],
                            [39.34751097, -7.96248163],
                            [39.34715724, -7.96265199],
                            [39.34693453, -7.9627175],
                            [39.34676424, -7.96279611],
                            [39.34659394, -7.96284852],
                            [39.34635813, -7.96294024],
                            [39.34606993, -7.96296645],
                            [39.34580792, -7.96294024],
                            [39.34555901, -7.96275678],
                            [39.34533632, -7.96250783],
                            [39.34510052, -7.96221956],
                            [39.3449433, -7.96195751],
                            [39.34475989, -7.96169543],
                            [39.3445634, -7.96148578],
                            [39.3444324, -7.96139406],
                            [39.34420969, -7.96136785],
                            [39.3441311, -7.96147266],
                            [39.34415729, -7.96166922],
                            [39.34430139, -7.9619837],
                            [39.34439309, -7.9623375],
                            [39.3444062, -7.96273059],
                            [39.34437999, -7.96305816],
                            [39.34428828, -7.96343817],
                            [39.34426207, -7.96372643],
                            [39.34432759, -7.96397538],
                            [39.34451098, -7.96428986],
                            [39.3447206, -7.96455193],
                            [39.34494329, -7.96487952],
                            [39.34504808, -7.96527261],
                            [39.34502189, -7.96567881],
                            [39.34491708, -7.96609812],
                            [39.34472059, -7.96653053],
                            [39.34453719, -7.96692363],
                            [39.34444546, -7.96714636],
                            [39.34440618, -7.96744774],
                            [39.34435375, -7.96785394],
                            [39.34427516, -7.96826014],
                            [39.34415726, -7.96864014],
                            [39.34403936, -7.96890222],
                            [39.34390836, -7.96913806],
                            [39.34371185, -7.96933461],
                            [39.34351534, -7.96942633],
                            [39.34334505, -7.96930841],
                            [39.34322714, -7.96909875],
                            [39.34317474, -7.96879738],
                            [39.34317474, -7.96844358],
                            [39.34317474, -7.96793255],
                            [39.34320094, -7.96744774],
                            [39.34321405, -7.96692363],
                            [39.34308305, -7.9664912],
                            [39.34291274, -7.96616362],
                            [39.34275553, -7.96592777],
                            [39.34269003, -7.96554776],
                            [39.34266383, -7.96512848],
                            [39.34266384, -7.96463056],
                            [39.34263765, -7.96423745],
                            [39.34259834, -7.96394916],
                            [39.34248044, -7.96373953],
                            [39.34241494, -7.9636216],
                            [39.34225773, -7.96345124],
                            [39.34206122, -7.96333333],
                            [39.34185164, -7.96334644],
                            [39.34169443, -7.96347745],
                            [39.34151103, -7.96371332],
                            [39.34145864, -7.96411952],
                            [39.34140621, -7.9644733],
                            [39.34135382, -7.96474846],
                            [39.34119661, -7.96508915],
                            [39.34101321, -7.96542984],
                            [39.34082981, -7.96575742],
                            [39.3406988, -7.96603259],
                            [39.3406333, -7.96626843],
                            [39.34058089, -7.96649119],
                            [39.34059399, -7.96675326],
                            [39.34062019, -7.96697602],
                            [39.34062019, -7.96727738],
                            [39.34060709, -7.96764428],
                            [39.34062018, -7.96806358],
                            [39.34063329, -7.96837806],
                            [39.34072499, -7.96865322],
                            [39.34086908, -7.9689153],
                            [39.34101319, -7.96922976],
                            [39.34122279, -7.96947873],
                            [39.34131449, -7.96970149],
                            [39.34143239, -7.96993735],
                            [39.3414455, -7.97008149],
                            [39.3415372, -7.97022561],
                            [39.34166819, -7.97023872],
                            [39.34189091, -7.97012079],
                            [39.34202192, -7.96998977],
                            [39.34223152, -7.96989805],
                            [39.34244112, -7.96978012],
                            [39.34269002, -7.96976701],
                            [39.34284723, -7.96987184],
                            [39.34282102, -7.97006838],
                            [39.34269001, -7.97027804],
                            [39.34255902, -7.97048769],
                            [39.3422839, -7.97082836],
                            [39.3420743, -7.97118216],
                            [39.34183851, -7.97145732],
                            [39.34157649, -7.97174559],
                            [39.34132759, -7.97195524],
                            [39.34118347, -7.97198146],
                            [39.34081667, -7.97199456],
                            [39.34037125, -7.97204696],
                            [39.33997825, -7.97219111],
                            [39.33969005, -7.97233523],
                            [39.33942804, -7.9726235],
                            [39.33921844, -7.9729773],
                            [39.33899572, -7.97333106],
                            [39.33879922, -7.97364554],
                            [39.33860272, -7.97392072],
                            [39.33823591, -7.97422209],
                            [39.33797392, -7.97435312],
                            [39.33775121, -7.97453656],
                            [39.33744989, -7.97474621],
                            [39.3372534, -7.97494276],
                            [39.33703069, -7.97509999],
                            [39.33674248, -7.97516552],
                            [39.33651977, -7.9750738],
                            [39.33641496, -7.97496897],
                            [39.33623156, -7.97481172],
                            [39.33604816, -7.97473311],
                            [39.33595646, -7.97477243],
                            [39.33586476, -7.97494276],
                            [39.33577306, -7.97523103],
                            [39.33577306, -7.97574206],
                            [39.33575995, -7.97616136],
                            [39.33565516, -7.97638412],
                            [39.33548485, -7.97665929],
                            [39.33530143, -7.97684273],
                            [39.33507874, -7.97703927],
                            [39.33479053, -7.97730133],
                            [39.33441061, -7.97769443],
                            [39.3339652, -7.97800891],
                            [39.33353289, -7.97817925],
                            [39.33320539, -7.97833649],
                            [39.33274687, -7.97844132],
                            [39.33218357, -7.97865096],
                            [39.33173815, -7.9788344],
                            [39.33109625, -7.97903095],
                            [39.33061153, -7.97920129],
                            [39.33012682, -7.97938473],
                            [39.32977311, -7.97955507],
                            [39.32938009, -7.9797123],
                            [39.32914429, -7.97985645],
                            [39.32902639, -7.98002678],
                            [39.3290002, -7.98023645],
                            [39.3290002, -7.98043298],
                            [39.3290002, -7.98077365],
                            [39.3290002, -7.98110125],
                            [39.3290002, -7.98137641],
                            [39.32901329, -7.98159917],
                            [39.32884297, -7.98178261],
                            [39.32869888, -7.98193985],
                            [39.32860718, -7.98213641],
                            [39.32860718, -7.98241157],
                            [39.32864647, -7.98267363],
                            [39.32873817, -7.98290949],
                            [39.32892157, -7.98309294],
                            [39.32915737, -7.98315847],
                            [39.32939319, -7.98322397],
                            [39.32960279, -7.98331571],
                            [39.3298124, -7.98345983],
                            [39.3300089, -7.9836564],
                            [39.3301137, -7.98387914],
                            [39.33021849, -7.98421983],
                            [39.33025779, -7.9845605],
                            [39.33019231, -7.98480947],
                            [39.32998269, -7.98480947],
                            [39.32962897, -7.98461292],
                            [39.32941937, -7.98436395],
                            [39.32917047, -7.98419362],
                            [39.32898708, -7.98408879],
                            [39.32879057, -7.98408879],
                            [39.32858097, -7.9841805],
                            [39.32837136, -7.98439015],
                            [39.32817485, -7.98475705],
                            [39.32814865, -7.98504532],
                            [39.32817485, -7.9853729],
                            [39.32829275, -7.98591014],
                            [39.32848926, -7.98651289],
                            [39.32865954, -7.98724665],
                            [39.32885605, -7.98782321],
                            [39.32898706, -7.98830804],
                            [39.32917045, -7.98883217],
                            [39.32935385, -7.98943491],
                            [39.32953725, -7.98986731],
                            [39.32966826, -7.99036524],
                            [39.32975995, -7.99078455],
                            [39.32975995, -7.99119075],
                            [39.32965516, -7.99158384],
                            [39.32945865, -7.99200315],
                            [39.32922283, -7.99251418],
                            [39.32902633, -7.99281553],
                            [39.32881673, -7.99314311],
                            [39.32859402, -7.99360174],
                            [39.32831892, -7.99416517],
                            [39.32812242, -7.99466309],
                            [39.3279128, -7.99512169],
                            [39.3278211, -7.99530515],
                            [39.3277032, -7.99544928],
                            [39.3276246, -7.99560651],
                            [39.32755908, -7.99577687],
                            [39.32755908, -7.9958948],
                            [39.32763769, -7.99592099],
                            [39.3277687, -7.99592099],
                        ],
                        [
                            [39.29712827, -7.98960156],
                            [39.29730986, -7.98900219],
                            [39.29727353, -7.98865708],
                            [39.29725538, -7.98827566],
                            [39.29727354, -7.98774892],
                            [39.29725538, -7.98729484],
                            [39.29714643, -7.98698608],
                            [39.29691037, -7.98655015],
                            [39.29663797, -7.9861324],
                            [39.29638375, -7.98578731],
                            [39.29603874, -7.98536956],
                            [39.29569372, -7.98493363],
                            [39.29538502, -7.98449772],
                            [39.29500367, -7.98402547],
                            [39.29458602, -7.98360772],
                            [39.29431364, -7.98335344],
                            [39.29384151, -7.98293567],
                            [39.29335121, -7.98246344],
                            [39.29309699, -7.98217282],
                            [39.2927338, -7.98180955],
                            [39.29244325, -7.98137364],
                            [39.2922072, -7.98102854],
                            [39.29198929, -7.98064711],
                            [39.29178954, -7.98026568],
                            [39.29158979, -7.97999324],
                            [39.29128109, -7.97959363],
                            [39.29099054, -7.97926671],
                            [39.29073632, -7.97888528],
                            [39.29053658, -7.97861284],
                            [39.29051842, -7.97830406],
                            [39.29055473, -7.9780861],
                            [39.29071817, -7.97795897],
                            [39.29091792, -7.97784998],
                            [39.29111767, -7.97784998],
                            [39.29131742, -7.97792264],
                            [39.29151717, -7.97799531],
                            [39.29175323, -7.97795897],
                            [39.29184403, -7.97784998],
                            [39.29184403, -7.97766836],
                            [39.29177139, -7.97743223],
                            [39.2916261, -7.97719611],
                            [39.29146269, -7.97699634],
                            [39.29133558, -7.97677836],
                            [39.29135374, -7.97652408],
                            [39.29144454, -7.9762698],
                            [39.29164429, -7.97610633],
                            [39.29191667, -7.97594286],
                            [39.2921709, -7.97570674],
                            [39.29244328, -7.97541613],
                            [39.2926612, -7.97508921],
                            [39.29291542, -7.97468962],
                            [39.29302437, -7.97436267],
                            [39.29316965, -7.97401757],
                            [39.29331493, -7.97363615],
                            [39.29340572, -7.97329107],
                            [39.29349653, -7.97301859],
                            [39.29353284, -7.9726735],
                            [39.29365996, -7.9723829],
                            [39.2938234, -7.97203779],
                            [39.29402312, -7.97180167],
                            [39.29418657, -7.97154739],
                            [39.29435, -7.97138393],
                            [39.29460423, -7.9711478],
                            [39.29482214, -7.97089352],
                            [39.29500373, -7.97074821],
                            [39.29520349, -7.97058476],
                            [39.29538508, -7.97034862],
                            [39.2956393, -7.97014884],
                            [39.29580273, -7.96989455],
                            [39.29565746, -7.96989455],
                            [39.29533058, -7.96996721],
                            [39.2951127, -7.97005802],
                            [39.29487663, -7.97007618],
                            [39.29464056, -7.9701125],
                            [39.29429553, -7.97011249],
                            [39.29396866, -7.97011249],
                            [39.29358734, -7.97009433],
                            [39.29329678, -7.97009433],
                            [39.29286097, -7.97009433],
                            [39.29253411, -7.97009433],
                            [39.29211644, -7.97009433],
                            [39.29169879, -7.97009432],
                            [39.29126297, -7.97016698],
                            [39.29084532, -7.97016697],
                            [39.29051846, -7.97022147],
                            [39.29010079, -7.97029412],
                            [39.28977392, -7.97031228],
                            [39.28974166, -7.97031228],
                            [39.28973194, -7.97032497],
                            [39.28960526, -7.97038257],
                            [39.28949584, -7.97043441],
                            [39.28900398, -7.97071324],
                            [39.28863325, -7.97085745],
                            [39.28834491, -7.97098105],
                            [39.28809775, -7.97114585],
                            [39.28793299, -7.97131066],
                            [39.28778883, -7.97153728],
                            [39.28766525, -7.97174327],
                            [39.28762405, -7.97196987],
                            [39.28760345, -7.97221708],
                            [39.28760345, -7.97252609],
                            [39.28760345, -7.97281448],
                            [39.28760345, -7.97312349],
                            [39.28752105, -7.97361791],
                            [39.28741808, -7.97411232],
                            [39.28741808, -7.97458614],
                            [39.28739748, -7.97495694],
                            [39.28739748, -7.97538956],
                            [39.28747985, -7.97590457],
                            [39.28754164, -7.97646079],
                            [39.28764462, -7.97679039],
                            [39.28772701, -7.97707879],
                            [39.28789178, -7.9773054],
                            [39.28791237, -7.97761441],
                            [39.28805654, -7.97804702],
                            [39.2882419, -7.97839723],
                            [39.28859203, -7.97878864],
                            [39.28904513, -7.97922126],
                            [39.28941587, -7.97950966],
                            [39.28982777, -7.97990108],
                            [39.29032208, -7.98033369],
                            [39.29075459, -7.9807457],
                            [39.2911459, -7.98121951],
                            [39.29161961, -7.98175513],
                            [39.29196974, -7.98224955],
                            [39.29227868, -7.98270276],
                            [39.29271119, -7.98325898],
                            [39.29297893, -7.98367099],
                            [39.29322607, -7.98406239],
                            [39.29339083, -7.9844332],
                            [39.29347323, -7.98488642],
                            [39.2935556, -7.98531904],
                            [39.2935556, -7.98564864],
                            [39.29345262, -7.98589584],
                            [39.29326726, -7.98620485],
                            [39.29299951, -7.98661685],
                            [39.29275236, -7.98698767],
                            [39.29262878, -7.98721427],
                            [39.29260819, -7.98746148],
                            [39.29260819, -7.98772929],
                            [39.29273176, -7.98801769],
                            [39.29287593, -7.9883473],
                            [39.29304068, -7.9886975],
                            [39.29322605, -7.98904773],
                            [39.2934526, -7.98935672],
                            [39.29359678, -7.98970694],
                            [39.29372035, -7.98999534],
                            [39.29373004, -7.99008259],
                            [39.29384276, -7.99009064],
                            [39.29428818, -7.99017974],
                            [39.29476328, -7.99017974],
                            [39.29503054, -7.99017974],
                            [39.29509142, -7.99017974],
                            [39.29547597, -7.99017974],
                            [39.29586199, -7.99015005],
                            [39.29618863, -7.99015005],
                            [39.29651527, -7.99012035],
                            [39.29678125, -7.99009617],
                            [39.29685587, -7.989874],
                            [39.29712827, -7.98960156],
                        ],
                        [
                            [39.30128323, -7.97065044],
                            [39.30134871, -7.97059688],
                            [39.30149747, -7.97051951],
                            [39.30159863, -7.97044213],
                            [39.30170573, -7.97032308],
                            [39.30178904, -7.97022192],
                            [39.30187829, -7.97010884],
                            [39.30194374, -7.96997791],
                            [39.3020211, -7.96984101],
                            [39.3020687, -7.96972793],
                            [39.30208655, -7.9696089],
                            [39.30208655, -7.96950176],
                            [39.30206275, -7.96940057],
                            [39.3020211, -7.96929941],
                            [39.30200326, -7.96919228],
                            [39.3019973, -7.96909112],
                            [39.3019973, -7.96898397],
                            [39.30202705, -7.96887089],
                            [39.30207466, -7.9687697],
                            [39.30214607, -7.96869234],
                            [39.30219961, -7.96862686],
                            [39.30228887, -7.96851973],
                            [39.30241384, -7.96841856],
                            [39.30251499, -7.96838285],
                            [39.30261614, -7.96838285],
                            [39.302753, -7.96838285],
                            [39.30283035, -7.96838285],
                            [39.30294341, -7.96838285],
                            [39.30305052, -7.96838285],
                            [39.30317548, -7.96837094],
                            [39.30327068, -7.96834119],
                            [39.30338374, -7.96826977],
                            [39.30350275, -7.96818644],
                            [39.30357415, -7.96811502],
                            [39.30363365, -7.9680436],
                            [39.30371101, -7.96794838],
                            [39.30375861, -7.96786506],
                            [39.30384192, -7.96777577],
                            [39.30391333, -7.9676746],
                            [39.30399068, -7.96756152],
                            [39.30404421, -7.96743654],
                            [39.30409779, -7.96729964],
                            [39.30412157, -7.96718062],
                            [39.30413349, -7.96703778],
                            [39.30415134, -7.96688898],
                            [39.30413349, -7.96671637],
                            [39.30412754, -7.96654378],
                            [39.30412157, -7.96639499],
                            [39.30410375, -7.9662581],
                            [39.30406209, -7.96612122],
                            [39.30402044, -7.96597242],
                            [39.30398474, -7.96586528],
                            [39.30392523, -7.96575221],
                            [39.30388359, -7.96564508],
                            [39.30383598, -7.96556771],
                            [39.30377647, -7.96548438],
                            [39.30373483, -7.96542487],
                            [39.30371697, -7.96531772],
                            [39.30368722, -7.96518679],
                            [39.30361582, -7.96505586],
                            [39.30353251, -7.96493682],
                            [39.30343731, -7.96484159],
                            [39.3033064, -7.9647523],
                            [39.30316357, -7.96467496],
                            [39.30300294, -7.96462137],
                            [39.30284823, -7.96460948],
                            [39.30265186, -7.96460948],
                            [39.30251501, -7.96460948],
                            [39.30239004, -7.96460948],
                            [39.30227105, -7.96461543],
                            [39.30213418, -7.96460352],
                            [39.30202112, -7.96460352],
                            [39.30183933, -7.9645968],
                            [39.30182903, -7.96459697],
                            [39.30061403, -7.96586761],
                            [39.29912294, -7.96752472],
                            [39.29701071, -7.96881072],
                            [39.29701939, -7.9688411],
                            [39.29723731, -7.96902273],
                            [39.29749153, -7.96905905],
                            [39.29792726, -7.96898396],
                            [39.29814743, -7.968978],
                            [39.29830808, -7.968978],
                            [39.29843898, -7.968978],
                            [39.29856396, -7.96900776],
                            [39.29871865, -7.96906727],
                            [39.29885552, -7.9691268],
                            [39.29899238, -7.9692101],
                            [39.29909948, -7.9692994],
                            [39.29918279, -7.96939462],
                            [39.29927204, -7.9695077],
                            [39.29939105, -7.96960889],
                            [39.29948624, -7.96971007],
                            [39.29958146, -7.96985888],
                            [39.29967666, -7.9700017],
                            [39.29976592, -7.97012668],
                            [39.29981946, -7.97027547],
                            [39.29992063, -7.97039451],
                            [39.30002178, -7.9705195],
                            [39.30011103, -7.97062068],
                            [39.30021813, -7.97070996],
                            [39.30032525, -7.97081709],
                            [39.3004502, -7.97088255],
                            [39.30061087, -7.97088256],
                            [39.30078938, -7.9708766],
                            [39.30090839, -7.97083494],
                            [39.30102738, -7.97077543],
                            [39.3011583, -7.97070996],
                            [39.30128323, -7.97065044],
                        ],
                        [
                            [39.32404409, -7.96901318],
                            [39.32396892, -7.96929517],
                            [39.32385615, -7.96976517],
                            [39.32374338, -7.97014116],
                            [39.32349904, -7.97089314],
                            [39.32323589, -7.97138192],
                            [39.32289757, -7.9719835],
                            [39.32259685, -7.97237829],
                            [39.32225853, -7.97277308],
                            [39.32186383, -7.97313027],
                            [39.32129997, -7.97354386],
                            [39.32090527, -7.97382584],
                            [39.3203414, -7.97403265],
                            [39.31981514, -7.97422063],
                            [39.31928886, -7.97437103],
                            [39.3187626, -7.97452142],
                            [39.31829271, -7.97459662],
                            [39.31787921, -7.97467182],
                            [39.31733416, -7.97478462],
                            [39.31695823, -7.974841],
                            [39.31663873, -7.97480341],
                            [39.31628162, -7.97461541],
                            [39.31592451, -7.97435222],
                            [39.31539824, -7.97416421],
                            [39.31496596, -7.97399502],
                            [39.31451487, -7.97390102],
                            [39.31412016, -7.973807],
                            [39.31370666, -7.97371304],
                            [39.31336835, -7.97371304],
                            [39.31321798, -7.973807],
                            [39.31321798, -7.97403262],
                            [39.31338714, -7.9742206],
                            [39.31357509, -7.97438981],
                            [39.31357509, -7.9745966],
                            [39.31351871, -7.97485979],
                            [39.31359388, -7.97501019],
                            [39.31381943, -7.97508539],
                            [39.31410136, -7.97508539],
                            [39.31451486, -7.9750854],
                            [39.31487197, -7.97517941],
                            [39.31504112, -7.97527337],
                            [39.31524788, -7.97546139],
                            [39.31539823, -7.97561178],
                            [39.31536064, -7.97591257],
                            [39.31519149, -7.97611937],
                            [39.31474039, -7.97623217],
                            [39.31440208, -7.97626976],
                            [39.31402616, -7.97632616],
                            [39.31378184, -7.97649536],
                            [39.31387582, -7.97670215],
                            [39.31413895, -7.97679615],
                            [39.31434569, -7.97692775],
                            [39.31462761, -7.97722854],
                            [39.314684, -7.97747292],
                            [39.31485316, -7.97769852],
                            [39.31511629, -7.97799933],
                            [39.31532305, -7.9782061],
                            [39.31552979, -7.97850691],
                            [39.31569894, -7.9787701],
                            [39.31592449, -7.97907091],
                            [39.31613123, -7.9793905],
                            [39.31631919, -7.97965369],
                            [39.31648834, -7.97993568],
                            [39.31686425, -7.98016129],
                            [39.31720254, -7.98038688],
                            [39.31737172, -7.98046208],
                            [39.31759726, -7.98051848],
                            [39.31776643, -7.98061247],
                            [39.31801076, -7.98074407],
                            [39.31816113, -7.98087567],
                            [39.31836787, -7.98104486],
                            [39.31864979, -7.98127045],
                            [39.31895052, -7.98143966],
                            [39.31927003, -7.98164645],
                            [39.31951438, -7.98175925],
                            [39.31964592, -7.98190965],
                            [39.3198527, -7.98213524],
                            [39.32000305, -7.98236085],
                            [39.32009703, -7.98281203],
                            [39.32009703, -7.98326322],
                            [39.3202098, -7.98371441],
                            [39.32030378, -7.98409039],
                            [39.32043534, -7.9843536],
                            [39.32071726, -7.98446638],
                            [39.32094282, -7.98441],
                            [39.32114956, -7.9842032],
                            [39.32135632, -7.98395881],
                            [39.32150667, -7.9837332],
                            [39.32148788, -7.98345121],
                            [39.3213939, -7.98320682],
                            [39.32124355, -7.98254884],
                            [39.32116836, -7.98222925],
                            [39.32126233, -7.98207885],
                            [39.32156307, -7.98200365],
                            [39.32190138, -7.98190966],
                            [39.32214572, -7.98187205],
                            [39.32242766, -7.98162768],
                            [39.3226344, -7.98151487],
                            [39.32278477, -7.98138326],
                            [39.32295392, -7.98127047],
                            [39.32321705, -7.98117649],
                            [39.32346139, -7.98095088],
                            [39.32383731, -7.9808193],
                            [39.32421322, -7.9807817],
                            [39.32453272, -7.9808757],
                            [39.32481466, -7.98110129],
                            [39.32498381, -7.98123289],
                            [39.32520936, -7.98157129],
                            [39.32528454, -7.98189088],
                            [39.32517177, -7.98228567],
                            [39.3249838, -7.98256766],
                            [39.32479586, -7.98301885],
                            [39.32470187, -7.98343243],
                            [39.3245891, -7.98380842],
                            [39.32455152, -7.98427841],
                            [39.32457031, -7.984598],
                            [39.32479585, -7.98482359],
                            [39.32505898, -7.984974],
                            [39.3253409, -7.984974],
                            [39.32564164, -7.984974],
                            [39.32601753, -7.9849552],
                            [39.32641224, -7.98474841],
                            [39.32671298, -7.98457921],
                            [39.32697609, -7.98412802],
                            [39.32710767, -7.98375203],
                            [39.32712647, -7.98339484],
                            [39.32701371, -7.98309406],
                            [39.32691973, -7.98286846],
                            [39.32684454, -7.98262407],
                            [39.32673175, -7.98228568],
                            [39.3266378, -7.98196607],
                            [39.3266378, -7.98174048],
                            [39.3266566, -7.98145849],
                            [39.32671299, -7.9811765],
                            [39.32686335, -7.98076291],
                            [39.32703251, -7.98042452],
                            [39.32722044, -7.98016133],
                            [39.32729565, -7.97989813],
                            [39.32742722, -7.97963494],
                            [39.32755878, -7.97935295],
                            [39.32769035, -7.97893934],
                            [39.3278031, -7.97863857],
                            [39.3278031, -7.97824377],
                            [39.3278031, -7.97788658],
                            [39.32774675, -7.97756699],
                            [39.32780311, -7.97734139],
                            [39.32785951, -7.97698421],
                            [39.32795348, -7.97657062],
                            [39.32802868, -7.97615704],
                            [39.32808507, -7.97570583],
                            [39.32810387, -7.97519824],
                            [39.32810387, -7.97472826],
                            [39.32816024, -7.97431467],
                            [39.32816025, -7.97397628],
                            [39.32816025, -7.9737131],
                            [39.32816025, -7.9733935],
                            [39.32816025, -7.97313029],
                            [39.32816025, -7.97292351],
                            [39.32816025, -7.9726979],
                            [39.32812268, -7.97247231],
                            [39.32810388, -7.97224674],
                            [39.3279535, -7.97190833],
                            [39.32787833, -7.97156994],
                            [39.32785954, -7.97126913],
                            [39.32789713, -7.97093074],
                            [39.3280287, -7.97061115],
                            [39.32819784, -7.97044197],
                            [39.32838582, -7.97019756],
                            [39.32863015, -7.96995317],
                            [39.32885571, -7.96976519],
                            [39.32911884, -7.9694456],
                            [39.32926919, -7.96910721],
                            [39.32938197, -7.9688252],
                            [39.32956993, -7.96850561],
                            [39.32960751, -7.96822362],
                            [39.32960751, -7.96790403],
                            [39.32945716, -7.96762204],
                            [39.32923161, -7.96747164],
                            [39.32889329, -7.96734005],
                            [39.3284986, -7.96734005],
                            [39.32808511, -7.96741523],
                            [39.32765281, -7.96739643],
                            [39.3273145, -7.96726484],
                            [39.32714533, -7.96707684],
                            [39.32699498, -7.96683245],
                            [39.3269198, -7.96656926],
                            [39.3268822, -7.96634365],
                            [39.32682582, -7.96609926],
                            [39.32676943, -7.96587367],
                            [39.32656269, -7.96561047],
                            [39.32635595, -7.96542248],
                            [39.32618678, -7.96529088],
                            [39.32598004, -7.96515927],
                            [39.32573569, -7.96514049],
                            [39.32552895, -7.96514049],
                            [39.32539738, -7.96540369],
                            [39.32535978, -7.96576087],
                            [39.32532219, -7.96604286],
                            [39.3252658, -7.96634365],
                            [39.32520943, -7.96655045],
                            [39.32511544, -7.96687005],
                            [39.32500267, -7.96724603],
                            [39.32483351, -7.96754682],
                            [39.32470194, -7.96784763],
                            [39.32445761, -7.968186],
                            [39.32428842, -7.96850559],
                            [39.32415688, -7.96876879],
                            [39.32404409, -7.96901318],
                        ],
                        [
                            [39.30892344, -7.97587231],
                            [39.30921532, -7.97562647],
                            [39.30936895, -7.97539598],
                            [39.30946112, -7.97519621],
                            [39.30949186, -7.9749811],
                            [39.30936896, -7.97485817],
                            [39.30910779, -7.97470452],
                            [39.30884663, -7.97458157],
                            [39.30861619, -7.97441256],
                            [39.30849329, -7.97418208],
                            [39.30833967, -7.9739823],
                            [39.30826286, -7.97365964],
                            [39.30812461, -7.97339841],
                            [39.30803242, -7.97327548],
                            [39.30790952, -7.97309111],
                            [39.3077559, -7.97304499],
                            [39.307633, -7.97307573],
                            [39.30743329, -7.97312183],
                            [39.30727967, -7.97315255],
                            [39.30707996, -7.97315255],
                            [39.30684952, -7.97310645],
                            [39.30660371, -7.97293742],
                            [39.30625038, -7.97279912],
                            [39.30589704, -7.97264547],
                            [39.30551298, -7.97246107],
                            [39.30528255, -7.97238424],
                            [39.30494458, -7.97219985],
                            [39.30465269, -7.97207692],
                            [39.3043147, -7.9719079],
                            [39.304115, -7.97184644],
                            [39.30383848, -7.97184644],
                            [39.30354659, -7.97175423],
                            [39.30334688, -7.97175423],
                            [39.30319326, -7.97180033],
                            [39.30308572, -7.97193862],
                            [39.30308572, -7.97209228],
                            [39.30320862, -7.97224593],
                            [39.3034237, -7.97233813],
                            [39.30359268, -7.97238424],
                            [39.30377703, -7.97238424],
                            [39.30397674, -7.97246107],
                            [39.30414573, -7.97261474],
                            [39.30428399, -7.97284522],
                            [39.30445297, -7.97304497],
                            [39.30465268, -7.97324473],
                            [39.30486777, -7.97342912],
                            [39.30508283, -7.97361352],
                            [39.30514428, -7.97373645],
                            [39.30514428, -7.97398229],
                            [39.30505211, -7.97436644],
                            [39.30497529, -7.97464304],
                            [39.30489846, -7.97479669],
                            [39.30489846, -7.97499645],
                            [39.30495993, -7.97516546],
                            [39.30511354, -7.9753345],
                            [39.30531327, -7.97548817],
                            [39.30558979, -7.97564182],
                            [39.30577413, -7.97579547],
                            [39.30597385, -7.97597987],
                            [39.30609674, -7.97617963],
                            [39.30609673, -7.97642548],
                            [39.30606602, -7.9766406],
                            [39.30597385, -7.97682499],
                            [39.30601992, -7.97700939],
                            [39.30618892, -7.97705549],
                            [39.30640399, -7.97705549],
                            [39.30661907, -7.97693256],
                            [39.30674196, -7.97667134],
                            [39.30691095, -7.97648695],
                            [39.30715676, -7.97636401],
                            [39.30746401, -7.97627184],
                            [39.30772517, -7.9762411],
                            [39.30797096, -7.9762411],
                            [39.30829357, -7.9762411],
                            [39.30850866, -7.9762411],
                            [39.30870837, -7.97610281],
                            [39.30892344, -7.97587231],
                        ],
                        [
                            [39.30691099, -7.96883473],
                            [39.30680344, -7.96872717],
                            [39.30658837, -7.96860424],
                            [39.30640402, -7.9686196],
                            [39.30618895, -7.96863496],
                            [39.30598924, -7.96863496],
                            [39.30582025, -7.96865033],
                            [39.30568199, -7.96869642],
                            [39.30546692, -7.96880399],
                            [39.30526721, -7.96900373],
                            [39.30489848, -7.96928032],
                            [39.30462198, -7.96955694],
                            [39.30437618, -7.96975667],
                            [39.30419182, -7.97001789],
                            [39.30394603, -7.97040204],
                            [39.30386921, -7.97063252],
                            [39.30376168, -7.97080155],
                            [39.30370023, -7.97097057],
                            [39.30373093, -7.97110886],
                            [39.3038692, -7.97120107],
                            [39.30402282, -7.97120107],
                            [39.30425327, -7.97120107],
                            [39.30457588, -7.9711396],
                            [39.30477559, -7.97103204],
                            [39.3050214, -7.97086302],
                            [39.30520575, -7.97063253],
                            [39.30535937, -7.97040205],
                            [39.30562053, -7.97027912],
                            [39.3059585, -7.97009473],
                            [39.30628111, -7.96994108],
                            [39.30658837, -7.96972594],
                            [39.30677272, -7.96952619],
                            [39.30691098, -7.96932643],
                            [39.30704924, -7.96908058],
                            [39.30700314, -7.96892692],
                            [39.30691099, -7.96883473],
                        ],
                        [
                            [39.31830256, -7.96899831],
                            [39.3187653, -7.96907236],
                            [39.31928352, -7.96925749],
                            [39.31970922, -7.9695537],
                            [39.31993132, -7.96973882],
                            [39.32019044, -7.96977586],
                            [39.32054211, -7.96962775],
                            [39.32089377, -7.96936856],
                            [39.32120842, -7.96920196],
                            [39.32148603, -7.96907237],
                            [39.32157859, -7.96888724],
                            [39.32137499, -7.96875766],
                            [39.32100483, -7.96877617],
                            [39.32065314, -7.96877617],
                            [39.32022747, -7.96870211],
                            [39.31994984, -7.9686836],
                            [39.31970923, -7.9686836],
                            [39.31956116, -7.96860954],
                            [39.31926503, -7.96851698],
                            [39.31891337, -7.96835037],
                            [39.31865424, -7.96820226],
                            [39.31843214, -7.96799863],
                            [39.31821004, -7.967832],
                            [39.31793241, -7.9677024],
                            [39.31756224, -7.96760985],
                            [39.31709952, -7.96751728],
                            [39.31671085, -7.96738768],
                            [39.31626664, -7.96731365],
                            [39.31585947, -7.96731364],
                            [39.31550779, -7.96731364],
                            [39.31517463, -7.96735066],
                            [39.31469342, -7.96736917],
                            [39.31437877, -7.96738767],
                            [39.31448983, -7.96757281],
                            [39.31469342, -7.96783199],
                            [39.3150821, -7.9681282],
                            [39.31550779, -7.96838737],
                            [39.31584094, -7.96855398],
                            [39.31613707, -7.96872061],
                            [39.31647023, -7.9688502],
                            [39.31680339, -7.9688502],
                            [39.31706251, -7.96875764],
                            [39.31728461, -7.96872062],
                            [39.31748821, -7.96875764],
                            [39.31783986, -7.9688502],
                            [39.31830256, -7.96899831],
                        ],
                        [
                            [39.37608079, -7.96520043],
                            [39.3760484, -7.96560544],
                            [39.37603221, -7.96584844],
                            [39.37601784, -7.966064],
                            [39.37601954, -7.9660649],
                            [39.37600718, -7.9662134],
                            [39.37596985, -7.96632542],
                            [39.37588275, -7.96644988],
                            [39.37573344, -7.96657434],
                            [39.37557166, -7.96661168],
                            [39.37537256, -7.96671124],
                            [39.37526058, -7.96679836],
                            [39.3752108, -7.96694772],
                            [39.3752108, -7.96729619],
                            [39.37526058, -7.96750778],
                            [39.37542234, -7.96769449],
                            [39.37572098, -7.96800562],
                            [39.37601962, -7.96827944],
                            [39.3763929, -7.96866528],
                            [39.37670398, -7.96902621],
                            [39.37700261, -7.96931246],
                            [39.37725148, -7.96954895],
                            [39.37747547, -7.96972318],
                            [39.37764968, -7.96974808],
                            [39.37792342, -7.96976052],
                            [39.37820961, -7.96971075],
                            [39.37843359, -7.96961118],
                            [39.37862025, -7.96942449],
                            [39.37880688, -7.96918801],
                            [39.37895621, -7.96890176],
                            [39.37913041, -7.96850349],
                            [39.37929217, -7.9681052],
                            [39.37939173, -7.9676945],
                            [39.37947883, -7.96753271],
                            [39.37960327, -7.96737091],
                            [39.37968048, -7.9672716],
                            [39.37967681, -7.96727089],
                            [39.37959549, -7.96670709],
                            [39.37957929, -7.96614007],
                            [39.37957929, -7.96558925],
                            [39.3795469, -7.96500603],
                            [39.37953072, -7.96447143],
                            [39.37949832, -7.96409882],
                            [39.3795955, -7.9638396],
                            [39.37969269, -7.9635966],
                            [39.37990324, -7.96335359],
                            [39.3800652, -7.96317538],
                            [39.38042153, -7.96302958],
                            [39.38072927, -7.96289999],
                            [39.38103703, -7.96285139],
                            [39.38124758, -7.96268938],
                            [39.38142573, -7.96246259],
                            [39.38158771, -7.96220338],
                            [39.38158771, -7.96205757],
                            [39.38145814, -7.96196037],
                            [39.38123138, -7.96196037],
                            [39.38102081, -7.96194417],
                            [39.38089126, -7.96178216],
                            [39.38085885, -7.96149056],
                            [39.38093983, -7.96115034],
                            [39.38105322, -7.96068052],
                            [39.38124759, -7.96046993],
                            [39.38134476, -7.96040512],
                            [39.38147435, -7.96048612],
                            [39.38152292, -7.96066433],
                            [39.38155533, -7.96084253],
                            [39.38163631, -7.96103695],
                            [39.38173349, -7.96115034],
                            [39.38197643, -7.96115034],
                            [39.38213841, -7.96108554],
                            [39.38241375, -7.96089114],
                            [39.38260811, -7.96059953],
                            [39.38273769, -7.96017832],
                            [39.38281867, -7.95974091],
                            [39.38280249, -7.9593359],
                            [39.3826891, -7.95886609],
                            [39.38254334, -7.9585907],
                            [39.38238137, -7.95833148],
                            [39.382187, -7.95810467],
                            [39.38208982, -7.95782926],
                            [39.38197645, -7.95757005],
                            [39.38194406, -7.95729464],
                            [39.38191167, -7.95690584],
                            [39.38187929, -7.95666282],
                            [39.38170112, -7.95654943],
                            [39.38150675, -7.95641982],
                            [39.38124761, -7.95629022],
                            [39.38100465, -7.95612822],
                            [39.38082649, -7.95593381],
                            [39.38071312, -7.95572321],
                            [39.38053495, -7.955626],
                            [39.38037299, -7.95565839],
                            [39.38017861, -7.9556746],
                            [39.37988707, -7.9556746],
                            [39.37966032, -7.9555126],
                            [39.37946596, -7.95539919],
                            [39.3792554, -7.95517239],
                            [39.37906104, -7.95499419],
                            [39.37888288, -7.95494557],
                            [39.37894767, -7.95520477],
                            [39.37899625, -7.95539918],
                            [39.37904486, -7.95552879],
                            [39.37902865, -7.95570699],
                            [39.37909343, -7.95591761],
                            [39.37915822, -7.95612821],
                            [39.37909343, -7.95646841],
                            [39.37896385, -7.95679243],
                            [39.37880189, -7.95716503],
                            [39.37862372, -7.95750525],
                            [39.37836458, -7.95789405],
                            [39.37799204, -7.95838006],
                            [39.37773289, -7.95884988],
                            [39.37755473, -7.95920628],
                            [39.37737656, -7.95964369],
                            [39.37723078, -7.96008109],
                            [39.37705262, -7.96056711],
                            [39.37690684, -7.96110172],
                            [39.37682586, -7.96157154],
                            [39.37676107, -7.96200895],
                            [39.37669629, -7.96239776],
                            [39.37655052, -7.96299718],
                            [39.37643714, -7.96348319],
                            [39.37632374, -7.96401779],
                            [39.37622657, -7.9644552],
                            [39.37612938, -7.96487643],
                            [39.37608079, -7.96520043],
                        ],
                        [
                            [39.3031202, -7.95443359],
                            [39.3029505, -7.95444775],
                            [39.30283736, -7.95458919],
                            [39.30266768, -7.95490038],
                            [39.30265352, -7.955254],
                            [39.30265352, -7.95573492],
                            [39.30276664, -7.95614511],
                            [39.3029222, -7.95651288],
                            [39.30306361, -7.95679578],
                            [39.30313433, -7.95702209],
                            [39.30302119, -7.95706451],
                            [39.30279493, -7.95706451],
                            [39.3025121, -7.95696551],
                            [39.30224341, -7.95680992],
                            [39.302003, -7.95688063],
                            [39.30180502, -7.9571211],
                            [39.3017626, -7.95734741],
                            [39.3018333, -7.95758787],
                            [39.30210199, -7.95775761],
                            [39.3023424, -7.95785661],
                            [39.30258281, -7.95799806],
                            [39.30286563, -7.95822439],
                            [39.30319088, -7.95833755],
                            [39.30345957, -7.95816781],
                            [39.30371413, -7.95789906],
                            [39.30411008, -7.95763031],
                            [39.30457675, -7.95738985],
                            [39.30515654, -7.95720597],
                            [39.30559496, -7.95705038],
                            [39.30600504, -7.95693724],
                            [39.30650001, -7.95693724],
                            [39.30681112, -7.95690894],
                            [39.30723535, -7.95690894],
                            [39.30768788, -7.95697967],
                            [39.30790381, -7.95702767],
                            [39.30790381, -7.95611686],
                            [39.30757476, -7.95611686],
                            [39.30729193, -7.95601784],
                            [39.30698082, -7.95596125],
                            [39.30662727, -7.95593296],
                            [39.30630202, -7.95587639],
                            [39.30596263, -7.95567835],
                            [39.30565151, -7.9555369],
                            [39.30546768, -7.95531059],
                            [39.30525556, -7.95509842],
                            [39.30504343, -7.95495698],
                            [39.30474646, -7.9547731],
                            [39.30442121, -7.95465993],
                            [39.30409595, -7.9545892],
                            [39.30372828, -7.95456091],
                            [39.30338887, -7.95446191],
                            [39.3031202, -7.95443359],
                        ],
                        [
                            [39.39251166, -7.95726449],
                            [39.39228493, -7.95717379],
                            [39.39185417, -7.95715111],
                            [39.39160476, -7.95726449],
                            [39.39131003, -7.95744591],
                            [39.39117399, -7.95769537],
                            [39.39112866, -7.95799016],
                            [39.39112866, -7.95860244],
                            [39.39103796, -7.95926009],
                            [39.39103796, -7.95991773],
                            [39.39103795, -7.96050734],
                            [39.39103795, -7.96107427],
                            [39.39103795, -7.96166388],
                            [39.39103795, -7.9622308],
                            [39.39087923, -7.96275238],
                            [39.39072053, -7.96306986],
                            [39.39031244, -7.96311523],
                            [39.39015373, -7.96286576],
                            [39.38988168, -7.9627297],
                            [39.38974563, -7.96288845],
                            [39.38972296, -7.96311523],
                            [39.38972296, -7.9635234],
                            [39.38965493, -7.96390894],
                            [39.3895189, -7.9643398],
                            [39.38931485, -7.96479333],
                            [39.38895209, -7.96542831],
                            [39.38870269, -7.96594987],
                            [39.38843064, -7.96642609],
                            [39.38820391, -7.96683429],
                            [39.3878865, -7.96724247],
                            [39.38759175, -7.96749192],
                            [39.38739778, -7.96782956],
                            [39.38703502, -7.96835366],
                            [39.38691569, -7.96867198],
                            [39.38680429, -7.96862246],
                            [39.38669196, -7.96878475],
                            [39.38662398, -7.96900232],
                            [39.38654242, -7.9691927],
                            [39.38640646, -7.9693831],
                            [39.38632488, -7.96955988],
                            [39.38621612, -7.96979106],
                            [39.38616173, -7.96996784],
                            [39.38613454, -7.97017182],
                            [39.38616173, -7.97038939],
                            [39.38629768, -7.97060699],
                            [39.38633847, -7.97078376],
                            [39.38636567, -7.97104215],
                            [39.38635206, -7.97139572],
                            [39.38633847, -7.97169488],
                            [39.38633847, -7.97195328],
                            [39.38644724, -7.97215725],
                            [39.38663758, -7.97225246],
                            [39.38690949, -7.97232044],
                            [39.38719499, -7.97233404],
                            [39.3875077, -7.97234765],
                            [39.38780681, -7.97234765],
                            [39.38802435, -7.97234765],
                            [39.3881875, -7.97238844],
                            [39.38825548, -7.97255163],
                            [39.38820109, -7.97279641],
                            [39.38811952, -7.97297319],
                            [39.38788838, -7.97312278],
                            [39.38769803, -7.97331316],
                            [39.38746691, -7.97343556],
                            [39.38727658, -7.97354434],
                            [39.38708622, -7.97372112],
                            [39.38686869, -7.97395231],
                            [39.38669194, -7.97421068],
                            [39.38656959, -7.97445546],
                            [39.38643364, -7.97468664],
                            [39.38632486, -7.97493141],
                            [39.38614812, -7.97520339],
                            [39.38597137, -7.97551617],
                            [39.38565866, -7.97576095],
                            [39.38541394, -7.97592413],
                            [39.38516921, -7.97607371],
                            [39.38499247, -7.97631849],
                            [39.38491089, -7.97659048],
                            [39.3848701, -7.97682164],
                            [39.38492448, -7.97701204],
                            [39.38508763, -7.97724322],
                            [39.38530517, -7.97740641],
                            [39.38552269, -7.97758319],
                            [39.38567225, -7.97771917],
                            [39.38567225, -7.97790957],
                            [39.38569945, -7.97809995],
                            [39.38569945, -7.97824954],
                            [39.38564506, -7.97843992],
                            [39.38556348, -7.9786575],
                            [39.38552269, -7.97886148],
                            [39.38553629, -7.97918787],
                            [39.38561785, -7.97948702],
                            [39.38568583, -7.97969102],
                            [39.3858082, -7.97988139],
                            [39.38583539, -7.98019416],
                            [39.38582179, -7.98047975],
                            [39.38584898, -7.98077891],
                            [39.38571301, -7.9808333],
                            [39.38550909, -7.98077891],
                            [39.38530516, -7.98064293],
                            [39.38506043, -7.98039815],
                            [39.38489728, -7.98012616],
                            [39.38485651, -7.97974541],
                            [39.3848021, -7.97924223],
                            [39.38473414, -7.97879348],
                            [39.38459818, -7.97833113],
                            [39.38444864, -7.97793674],
                            [39.38425829, -7.97752879],
                            [39.38409514, -7.97720241],
                            [39.3839456, -7.97699844],
                            [39.38378244, -7.97688964],
                            [39.38361929, -7.97698482],
                            [39.38352412, -7.97718881],
                            [39.38342895, -7.9774744],
                            [39.38342895, -7.97771916],
                            [39.38342895, -7.97803194],
                            [39.38342894, -7.97835831],
                            [39.38342894, -7.97869828],
                            [39.38342894, -7.97898387],
                            [39.38337457, -7.97924223],
                            [39.38329298, -7.97941901],
                            [39.38314343, -7.97950062],
                            [39.38292588, -7.97954141],
                            [39.38269478, -7.97943262],
                            [39.38257241, -7.97921503],
                            [39.38254521, -7.97901106],
                            [39.38243645, -7.97891587],
                            [39.38228689, -7.97890225],
                            [39.38206937, -7.97892946],
                            [39.38187902, -7.97891586],
                            [39.38177026, -7.97876627],
                            [39.38178387, -7.9785487],
                            [39.38187902, -7.97822231],
                            [39.38202858, -7.97784155],
                            [39.3822869, -7.97759676],
                            [39.38246366, -7.97739279],
                            [39.38268118, -7.97713443],
                            [39.38274916, -7.97682163],
                            [39.38274917, -7.97644088],
                            [39.38273557, -7.97610091],
                            [39.38265398, -7.97591051],
                            [39.382586, -7.97581534],
                            [39.38250444, -7.97570654],
                            [39.38239568, -7.97565215],
                            [39.38230052, -7.97565215],
                            [39.38219174, -7.97566573],
                            [39.38213735, -7.97574734],
                            [39.38198781, -7.97582893],
                            [39.38183825, -7.97595131],
                            [39.3816751, -7.9760465],
                            [39.38136239, -7.97612809],
                            [39.38113126, -7.9760465],
                            [39.3809953, -7.97595131],
                            [39.38079137, -7.97589691],
                            [39.38068261, -7.97599211],
                            [39.38054665, -7.97611449],
                            [39.38065541, -7.97627768],
                            [39.38092733, -7.97648166],
                            [39.38114487, -7.97661765],
                            [39.38141678, -7.97669925],
                            [39.38159352, -7.97671284],
                            [39.38171588, -7.97686243],
                            [39.38181104, -7.9770936],
                            [39.38177026, -7.977284],
                            [39.3816615, -7.97743359],
                            [39.38147115, -7.97748797],
                            [39.381308, -7.97748797],
                            [39.38119924, -7.97740639],
                            [39.38109048, -7.97732478],
                            [39.38092733, -7.9772432],
                            [39.38072338, -7.977148],
                            [39.38060101, -7.97703921],
                            [39.38034271, -7.97698481],
                            [39.38013878, -7.97699842],
                            [39.38000282, -7.97708001],
                            [39.37983967, -7.97721599],
                            [39.3797173, -7.9773656],
                            [39.37959494, -7.97755596],
                            [39.37959494, -7.97777355],
                            [39.3797037, -7.97801833],
                            [39.37993483, -7.97820872],
                            [39.38016595, -7.9783447],
                            [39.38036991, -7.97849429],
                            [39.38053304, -7.97865748],
                            [39.38060101, -7.97880706],
                            [39.38064182, -7.97897024],
                            [39.3806826, -7.97922863],
                            [39.38069619, -7.97950061],
                            [39.38065539, -7.97979979],
                            [39.38056022, -7.98011255],
                            [39.38039707, -7.98031654],
                            [39.38020673, -7.98047972],
                            [39.3799892, -7.98054772],
                            [39.37974449, -7.9806293],
                            [39.37951335, -7.9806837],
                            [39.37930941, -7.98075169],
                            [39.37909189, -7.98076529],
                            [39.37876559, -7.98077888],
                            [39.37853446, -7.98077888],
                            [39.37835771, -7.98090126],
                            [39.37819456, -7.98109166],
                            [39.37818097, -7.98143163],
                            [39.37822174, -7.9818124],
                            [39.37823534, -7.98219318],
                            [39.37822174, -7.98247874],
                            [39.37808579, -7.98276433],
                            [39.37789544, -7.9829139],
                            [39.37769151, -7.9829683],
                            [39.37741958, -7.9829819],
                            [39.37714767, -7.9829003],
                            [39.37683498, -7.98279151],
                            [39.37646789, -7.98268271],
                            [39.37615518, -7.98258754],
                            [39.37586968, -7.98246514],
                            [39.37550258, -7.98241074],
                            [39.37520347, -7.98241073],
                            [39.37489078, -7.98246513],
                            [39.37442852, -7.98253313],
                            [39.3741294, -7.98264191],
                            [39.37380312, -7.98270989],
                            [39.37336806, -7.98262831],
                            [39.37287859, -7.98256031],
                            [39.37249792, -7.98256031],
                            [39.37215803, -7.98257391],
                            [39.37179095, -7.9826419],
                            [39.37150543, -7.9825467],
                            [39.37116554, -7.98249231],
                            [39.37082564, -7.98261469],
                            [39.37049934, -7.98275068],
                            [39.37018664, -7.98283228],
                            [39.36969719, -7.98298186],
                            [39.36933011, -7.98317224],
                            [39.36877267, -7.98333543],
                            [39.3683376, -7.98337623],
                            [39.36790254, -7.98344422],
                            [39.36753547, -7.98355301],
                            [39.36727714, -7.98366179],
                            [39.36710039, -7.98385217],
                            [39.36697802, -7.98406977],
                            [39.36689646, -7.98427375],
                            [39.36674689, -7.98447772],
                            [39.36652937, -7.9846001],
                            [39.36629824, -7.98450491],
                            [39.3660671, -7.98449132],
                            [39.36582239, -7.98464089],
                            [39.36567282, -7.9848449],
                            [39.36546889, -7.98503527],
                            [39.36529215, -7.98519846],
                            [39.36496585, -7.98533444],
                            [39.36454438, -7.98557921],
                            [39.36419088, -7.9857424],
                            [39.36387819, -7.98591918],
                            [39.36356548, -7.98606877],
                            [39.36321199, -7.98624555],
                            [39.36298086, -7.98646314],
                            [39.3628585, -7.98659913],
                            [39.3628721, -7.98680311],
                            [39.36296726, -7.9868847],
                            [39.3631984, -7.9869391],
                            [39.36349751, -7.9869391],
                            [39.36387818, -7.9869663],
                            [39.36423167, -7.98699349],
                            [39.36443561, -7.98710228],
                            [39.36468034, -7.98730629],
                            [39.36488426, -7.98751026],
                            [39.36525136, -7.98789102],
                            [39.36555045, -7.988163],
                            [39.36595833, -7.98850298],
                            [39.36631181, -7.98874775],
                            [39.3666517, -7.98885655],
                            [39.36707319, -7.98892455],
                            [39.36739949, -7.98910133],
                            [39.36764421, -7.98930531],
                            [39.36782094, -7.98944131],
                            [39.36807927, -7.98959088],
                            [39.36847353, -7.98961809],
                            [39.36897658, -7.9894821],
                            [39.36933008, -7.98930532],
                            [39.36998266, -7.98900616],
                            [39.37036335, -7.98877497],
                            [39.37093438, -7.98842141],
                            [39.37143742, -7.98808144],
                            [39.37194047, -7.98782305],
                            [39.37236195, -7.98753747],
                            [39.37290577, -7.9873335],
                            [39.3734496, -7.98708872],
                            [39.37400705, -7.98688475],
                            [39.37465963, -7.98676235],
                            [39.37531223, -7.98661278],
                            [39.37588324, -7.98653119],
                            [39.37649507, -7.98647679],
                            [39.37691653, -7.9864632],
                            [39.37722923, -7.98651758],
                            [39.37743316, -7.98666719],
                            [39.37758273, -7.98680317],
                            [39.3776779, -7.98700716],
                            [39.37769149, -7.98725194],
                            [39.37759631, -7.98755111],
                            [39.3773516, -7.98786388],
                            [39.37713404, -7.98816305],
                            [39.37661742, -7.98866621],
                            [39.37635908, -7.98895178],
                            [39.37614156, -7.98914216],
                            [39.37596482, -7.98934615],
                            [39.37574728, -7.98957733],
                            [39.37559774, -7.9898221],
                            [39.37548896, -7.98998528],
                            [39.3753394, -7.99028446],
                            [39.37521704, -7.99069243],
                            [39.37508109, -7.99118199],
                            [39.37487713, -7.99160355],
                            [39.37461883, -7.99202513],
                            [39.37438769, -7.99246029],
                            [39.37415656, -7.99288183],
                            [39.37392543, -7.9932218],
                            [39.3737079, -7.99358898],
                            [39.37349036, -7.99386095],
                            [39.37328642, -7.99418732],
                            [39.37301451, -7.99451369],
                            [39.37274259, -7.99482647],
                            [39.37256585, -7.99513924],
                            [39.37245709, -7.99537043],
                            [39.37229393, -7.99575118],
                            [39.37219875, -7.99592797],
                            [39.37217156, -7.99621355],
                            [39.37213078, -7.99645832],
                            [39.37222595, -7.9966895],
                            [39.37222595, -7.99689349],
                            [39.37208998, -7.99713825],
                            [39.37192683, -7.99738305],
                            [39.37175009, -7.99755983],
                            [39.37165492, -7.9978318],
                            [39.37147818, -7.99803579],
                            [39.37130142, -7.99815819],
                            [39.37113827, -7.99823977],
                            [39.3710023, -7.99837576],
                            [39.37090715, -7.99855254],
                            [39.37086635, -7.99885171],
                            [39.37081198, -7.99919168],
                            [39.37079837, -7.99950447],
                            [39.37075759, -7.99977644],
                            [39.37063523, -8.0002796],
                            [39.37052645, -8.00057876],
                            [39.37052645, -8.00090513],
                            [39.37047208, -8.00117711],
                            [39.3703633, -8.00147629],
                            [39.37028172, -8.00193865],
                            [39.37025453, -8.00214263],
                            [39.37022346, -8.0022464],
                            [39.37021633, -8.00223927],
                            [39.37019208, -8.00232816],
                            [39.37014959, -8.00243015],
                            [39.37014959, -8.00254062],
                            [39.3701241, -8.00268512],
                            [39.37006462, -8.00288059],
                            [39.36997115, -8.00304208],
                            [39.36985219, -8.00313558],
                            [39.36969924, -8.00324605],
                            [39.36954628, -8.00331405],
                            [39.36946131, -8.00339054],
                            [39.36946131, -8.00350103],
                            [39.36947831, -8.00358602],
                            [39.36954628, -8.00365402],
                            [39.36966524, -8.003756],
                            [39.36986067, -8.00384101],
                            [39.37008161, -8.00396848],
                            [39.37023456, -8.00406199],
                            [39.37037051, -8.00415547],
                            [39.37048947, -8.00429995],
                            [39.37063392, -8.00443593],
                            [39.37079538, -8.00457193],
                            [39.37093983, -8.00463144],
                            [39.37111826, -8.00464843],
                            [39.37150913, -8.00464843],
                            [39.37176406, -8.00464843],
                            [39.37190851, -8.00456345],
                            [39.37198499, -8.00446146],
                            [39.37200199, -8.00430846],
                            [39.37190851, -8.00413848],
                            [39.37178105, -8.00397701],
                            [39.3716196, -8.00379002],
                            [39.37149215, -8.00359454],
                            [39.37138169, -8.00339055],
                            [39.37133071, -8.00312708],
                            [39.37133071, -8.00287211],
                            [39.37131371, -8.00272762],
                            [39.37126273, -8.00260863],
                            [39.37124706, -8.00249898],
                            [39.37124426, -8.00249898],
                            [39.37124426, -8.00211996],
                            [39.37147024, -8.00198962],
                            [39.3717396, -8.00197376],
                            [39.3719773, -8.00197376],
                            [39.37218327, -8.00194207],
                            [39.37235759, -8.00178359],
                            [39.3726428, -8.00145076],
                            [39.37288048, -8.00126057],
                            [39.37305479, -8.00113378],
                            [39.37329247, -8.00099113],
                            [39.37354599, -8.00088021],
                            [39.37384707, -8.00067415],
                            [39.37411644, -8.00049982],
                            [39.37444919, -8.00042058],
                            [39.37475025, -8.00038887],
                            [39.37522561, -8.00034133],
                            [39.37562175, -8.00034133],
                            [39.37587529, -8.00026208],
                            [39.37611295, -8.00015116],
                            [39.3763031, -8.0000719],
                            [39.37655664, -7.99999266],
                            [39.3767943, -7.99992928],
                            [39.37709538, -7.99991342],
                            [39.37744398, -7.99986588],
                            [39.37768166, -7.99978663],
                            [39.37791934, -7.99962813],
                            [39.3782204, -7.99942211],
                            [39.37853731, -7.99918436],
                            [39.37887007, -7.99893079],
                            [39.37923451, -7.99866136],
                            [39.37969404, -7.99837608],
                            [39.3802011, -7.99820174],
                            [39.38059722, -7.99807495],
                            [39.3810092, -7.99802741],
                            [39.38145287, -7.99804324],
                            [39.38167471, -7.99813834],
                            [39.38195993, -7.99823344],
                            [39.38224515, -7.99823344],
                            [39.38249868, -7.9980908],
                            [39.38281559, -7.99783723],
                            [39.38306911, -7.99761534],
                            [39.38319589, -7.99731422],
                            [39.38325926, -7.99694969],
                            [39.38329095, -7.99661686],
                            [39.38330681, -7.99625232],
                            [39.38330681, -7.99601459],
                            [39.38327512, -7.99574515],
                            [39.38321174, -7.99557082],
                            [39.38329095, -7.99538063],
                            [39.38346528, -7.9950795],
                            [39.38367125, -7.99484177],
                            [39.38387726, -7.9945565],
                            [39.38401985, -7.99425535],
                            [39.38408324, -7.99392254],
                            [39.38413079, -7.99354215],
                            [39.3840991, -7.99316177],
                            [39.38408325, -7.9928131],
                            [39.38411493, -7.99252781],
                            [39.38422587, -7.99229008],
                            [39.38438432, -7.99209989],
                            [39.38449523, -7.9919731],
                            [39.38463785, -7.99198894],
                            [39.38476461, -7.99208404],
                            [39.38490722, -7.99221083],
                            [39.38508152, -7.99240102],
                            [39.38535088, -7.99263876],
                            [39.38565195, -7.99273385],
                            [39.38588963, -7.99265462],
                            [39.3859847, -7.99240103],
                            [39.38593718, -7.9920682],
                            [39.38593718, -7.99170368],
                            [39.38593718, -7.9913867],
                            [39.38595302, -7.99108555],
                            [39.3860164, -7.99075274],
                            [39.38619069, -7.99040405],
                            [39.3863967, -7.98999199],
                            [39.38655515, -7.98973839],
                            [39.38679283, -7.98934217],
                            [39.38695127, -7.98899349],
                            [39.3870939, -7.98854972],
                            [39.38715728, -7.9881852],
                            [39.38715729, -7.98755124],
                            [39.38715729, -7.98701237],
                            [39.38715729, -7.98642597],
                            [39.38717312, -7.98577614],
                            [39.38717313, -7.98518974],
                            [39.38717313, -7.98466672],
                            [39.38720483, -7.98415955],
                            [39.38733159, -7.98377917],
                            [39.38749004, -7.9833354],
                            [39.38761682, -7.98282822],
                            [39.3876802, -7.98236861],
                            [39.38772774, -7.98175049],
                            [39.38772775, -7.98110068],
                            [39.38772775, -7.98057767],
                            [39.38780697, -7.98014976],
                            [39.38793374, -7.97976938],
                            [39.38815558, -7.97919882],
                            [39.38831403, -7.97894522],
                            [39.38844079, -7.97877088],
                            [39.3886468, -7.97875503],
                            [39.38880524, -7.97877088],
                            [39.38893202, -7.97896107],
                            [39.38893202, -7.97924635],
                            [39.38890031, -7.97970598],
                            [39.3887894, -7.98011805],
                            [39.38866263, -7.98049844],
                            [39.38859924, -7.98100561],
                            [39.38867847, -7.98156031],
                            [39.38883693, -7.98208334],
                            [39.38902706, -7.98262221],
                            [39.38921721, -7.98309767],
                            [39.38932813, -7.98347806],
                            [39.38929642, -7.98395352],
                            [39.38923305, -7.98446069],
                            [39.38901121, -7.98506296],
                            [39.38883692, -7.98566521],
                            [39.38875767, -7.98634671],
                            [39.38875766, -7.98699653],
                            [39.38874183, -7.98751954],
                            [39.38874183, -7.98804256],
                            [39.38883691, -7.98840709],
                            [39.38890028, -7.98866067],
                            [39.38886859, -7.98926294],
                            [39.38882104, -7.98986519],
                            [39.38880519, -7.99087954],
                            [39.38872596, -7.99175123],
                            [39.38864674, -7.99225839],
                            [39.38856751, -7.99271802],
                            [39.38856751, -7.99305085],
                            [39.3887735, -7.99332029],
                            [39.38916962, -7.99354217],
                            [39.3895816, -7.99363727],
                            [39.38994606, -7.99351048],
                            [39.39016789, -7.99325689],
                            [39.39024711, -7.99295576],
                            [39.39024711, -7.99263878],
                            [39.39027882, -7.9923218],
                            [39.39027882, -7.99202068],
                            [39.39027882, -7.99181462],
                            [39.39037389, -7.9917037],
                            [39.39061157, -7.99157691],
                            [39.39091264, -7.99145011],
                            [39.39105526, -7.99121237],
                            [39.39110277, -7.99097464],
                            [39.39110277, -7.99081615],
                            [39.39118202, -7.99072105],
                            [39.3912771, -7.99067349],
                            [39.39141968, -7.99057841],
                            [39.39164154, -7.99038822],
                            [39.39181584, -7.9901188],
                            [39.39187922, -7.98981765],
                            [39.39179999, -7.98962747],
                            [39.3916257, -7.98943728],
                            [39.39145139, -7.98923124],
                            [39.39137218, -7.98893011],
                            [39.39130879, -7.9884071],
                            [39.39129293, -7.98791578],
                            [39.39122956, -7.98737693],
                            [39.39111865, -7.98705995],
                            [39.3909285, -7.98683805],
                            [39.39072251, -7.98655277],
                            [39.39056405, -7.98606145],
                            [39.39043729, -7.98537994],
                            [39.39040561, -7.98468258],
                            [39.39042147, -7.98420712],
                            [39.390469, -7.98368408],
                            [39.39057992, -7.98312939],
                            [39.39070668, -7.98254297],
                            [39.39086515, -7.981909],
                            [39.39100776, -7.98121164],
                            [39.39118207, -7.98048258],
                            [39.39135636, -7.97992787],
                            [39.39143559, -7.97940485],
                            [39.39145144, -7.97897693],
                            [39.39151481, -7.97851731],
                            [39.39160989, -7.97802601],
                            [39.39170496, -7.97750297],
                            [39.39187927, -7.97693243],
                            [39.39203774, -7.9765362],
                            [39.39218034, -7.97640939],
                            [39.39235465, -7.97650449],
                            [39.39243386, -7.97667884],
                            [39.39243386, -7.97699582],
                            [39.39246554, -7.97753467],
                            [39.39254479, -7.97843807],
                            [39.39254478, -7.97935732],
                            [39.39257646, -7.98016561],
                            [39.39257646, -7.98097392],
                            [39.39257646, -7.98165542],
                            [39.39252891, -7.98235278],
                            [39.39248138, -7.98300259],
                            [39.39248138, -7.9834939],
                            [39.39248137, -7.98409617],
                            [39.39257645, -7.9845558],
                            [39.39276659, -7.98514221],
                            [39.39290918, -7.98571277],
                            [39.39302011, -7.98629919],
                            [39.39317857, -7.98672711],
                            [39.39325778, -7.98713919],
                            [39.3933687, -7.98758296],
                            [39.39330532, -7.98804258],
                            [39.39319439, -7.98850221],
                            [39.3931627, -7.98877163],
                            [39.3931627, -7.98910446],
                            [39.39314686, -7.98940559],
                            [39.39319438, -7.98969087],
                            [39.39341622, -7.99002371],
                            [39.3936856, -7.9901505],
                            [39.39390743, -7.99010295],
                            [39.39412927, -7.98994446],
                            [39.39433526, -7.98965918],
                            [39.39446202, -7.9893422],
                            [39.39462049, -7.98919956],
                            [39.39468386, -7.9891837],
                            [39.39482648, -7.9891837],
                            [39.39500077, -7.9891837],
                            [39.39523846, -7.98916787],
                            [39.39544444, -7.98915201],
                            [39.39552368, -7.98899353],
                            [39.39552369, -7.98877164],
                            [39.39544444, -7.98853391],
                            [39.39530185, -7.98832786],
                            [39.39517508, -7.98805843],
                            [39.39504832, -7.98770976],
                            [39.39496909, -7.98737694],
                            [39.39485817, -7.98718675],
                            [39.39481063, -7.9868856],
                            [39.39481063, -7.98663202],
                            [39.3947948, -7.98634674],
                            [39.3947631, -7.98604562],
                            [39.39485818, -7.98580787],
                            [39.39492157, -7.98557016],
                            [39.39504833, -7.98541166],
                            [39.39520679, -7.98541166],
                            [39.39534938, -7.98549089],
                            [39.395492, -7.98561768],
                            [39.39555538, -7.98574449],
                            [39.39576136, -7.98582374],
                            [39.39590398, -7.98590297],
                            [39.39610997, -7.98588714],
                            [39.39625256, -7.98569695],
                            [39.39644271, -7.9855543],
                            [39.39644549, -7.98555025],
                            [39.39641107, -7.98552959],
                            [39.39614433, -7.98539621],
                            [39.39609663, -7.98537095],
                            [39.39626227, -7.98515857],
                            [39.39629453, -7.98473918],
                            [39.3963913, -7.98406171],
                            [39.39642355, -7.98367456],
                            [39.39647194, -7.9832713],
                            [39.39664932, -7.98286804],
                            [39.39690734, -7.9826906],
                            [39.39713313, -7.98254543],
                            [39.39724602, -7.98228735],
                            [39.39721377, -7.981997],
                            [39.39710089, -7.98172278],
                            [39.39687512, -7.98149695],
                            [39.39677834, -7.98122275],
                            [39.39676223, -7.98085172],
                            [39.39677834, -7.98027104],
                            [39.39684286, -7.97977099],
                            [39.39700414, -7.97920643],
                            [39.3971009, -7.97883541],
                            [39.39721379, -7.97851282],
                            [39.39731055, -7.97814182],
                            [39.39748794, -7.97780308],
                            [39.39764921, -7.977545],
                            [39.39797175, -7.97731917],
                            [39.39818141, -7.97707722],
                            [39.39837492, -7.97686751],
                            [39.39861682, -7.9765933],
                            [39.39876197, -7.97627069],
                            [39.39893937, -7.97588356],
                            [39.39906838, -7.97539964],
                            [39.3991974, -7.974948],
                            [39.3991974, -7.97462537],
                            [39.3991974, -7.9743189],
                            [39.39908451, -7.97398016],
                            [39.39890712, -7.97367368],
                            [39.3986491, -7.97349624],
                            [39.39850395, -7.97330269],
                            [39.39843945, -7.97312525],
                            [39.3984072, -7.97273811],
                            [39.3984717, -7.97214128],
                            [39.3984717, -7.97173804],
                            [39.39847171, -7.97128638],
                            [39.39855235, -7.9707218],
                            [39.39858461, -7.97028628],
                            [39.39868136, -7.96989915],
                            [39.39882651, -7.96968946],
                            [39.3989394, -7.96933459],
                            [39.39913292, -7.96896359],
                            [39.39932643, -7.9685442],
                            [39.39948771, -7.96825385],
                            [39.39964899, -7.96806028],
                            [39.39982639, -7.96776995],
                            [39.40005214, -7.96749573],
                            [39.40026181, -7.96735054],
                            [39.40053597, -7.96710858],
                            [39.40085851, -7.9666408],
                            [39.40108428, -7.96625367],
                            [39.40131006, -7.96575363],
                            [39.40140682, -7.96523747],
                            [39.40147132, -7.96476968],
                            [39.40158421, -7.96422125],
                            [39.40168099, -7.96365667],
                            [39.40177775, -7.96318889],
                            [39.40182613, -7.96268886],
                            [39.40182613, -7.96217267],
                            [39.40181001, -7.961721],
                            [39.40172937, -7.9612371],
                            [39.40161649, -7.96088222],
                            [39.40155199, -7.9604951],
                            [39.4015036, -7.9601241],
                            [39.40145522, -7.95964018],
                            [39.40150361, -7.95926919],
                            [39.40156812, -7.95904335],
                            [39.40169713, -7.9588498],
                            [39.40189066, -7.95872076],
                            [39.40221319, -7.95873689],
                            [39.40248735, -7.95891431],
                            [39.40268087, -7.95901111],
                            [39.40289051, -7.95901111],
                            [39.40313243, -7.95896272],
                            [39.40330982, -7.95865624],
                            [39.40337433, -7.95839814],
                            [39.40342269, -7.95797875],
                            [39.4035356, -7.95741419],
                            [39.40358397, -7.95720448],
                            [39.40358397, -7.95697866],
                            [39.40361624, -7.95672058],
                            [39.40369686, -7.95628506],
                            [39.40379364, -7.95584953],
                            [39.40382589, -7.95547854],
                            [39.40382589, -7.95522044],
                            [39.40364849, -7.95491396],
                            [39.40342271, -7.95480105],
                            [39.40313245, -7.95478491],
                            [39.4029873, -7.95460748],
                            [39.40292279, -7.95438164],
                            [39.40289053, -7.95415584],
                            [39.40297118, -7.95404293],
                            [39.40318081, -7.95404293],
                            [39.40339048, -7.95415584],
                            [39.40363237, -7.95410744],
                            [39.4036485, -7.95391389],
                            [39.40348724, -7.9537687],
                            [39.4034066, -7.95359127],
                            [39.40345498, -7.9533977],
                            [39.40350337, -7.953188],
                            [39.40350337, -7.95302671],
                            [39.40343885, -7.95284927],
                            [39.40330985, -7.9526557],
                            [39.40322921, -7.95246213],
                            [39.40327758, -7.95231697],
                            [39.4033421, -7.95210725],
                            [39.4035195, -7.9519137],
                            [39.40366465, -7.95170399],
                            [39.40384202, -7.95155883],
                            [39.40403556, -7.95139752],
                            [39.40439036, -7.95107493],
                            [39.404729, -7.95088135],
                            [39.40501929, -7.95070391],
                            [39.40527732, -7.9506394],
                            [39.40543858, -7.95051036],
                            [39.40569662, -7.95039745],
                            [39.40601915, -7.95026839],
                            [39.40618043, -7.95022001],
                            [39.40639008, -7.94996193],
                            [39.40656748, -7.94971997],
                            [39.40664812, -7.94951027],
                            [39.40679325, -7.94933283],
                            [39.4070029, -7.94921992],
                            [39.40721255, -7.94915539],
                            [39.40740607, -7.94907476],
                            [39.40753508, -7.94892957],
                            [39.40764799, -7.94867149],
                            [39.40774473, -7.94841342],
                            [39.40774474, -7.94826823],
                            [39.40753509, -7.94813918],
                            [39.40743833, -7.94799402],
                            [39.40748672, -7.9478811],
                            [39.40755121, -7.94770367],
                            [39.40751898, -7.94754236],
                            [39.40718031, -7.94747783],
                            [39.40698678, -7.94738105],
                            [39.40698679, -7.94718748],
                            [39.40703516, -7.94683262],
                            [39.4071158, -7.94647774],
                            [39.40709968, -7.94625192],
                            [39.4070513, -7.94599384],
                            [39.40695453, -7.94575187],
                            [39.40687391, -7.94549378],
                            [39.40676102, -7.94509053],
                            [39.40663201, -7.94459048],
                            [39.40650299, -7.94425173],
                            [39.40643848, -7.94410656],
                            [39.40626108, -7.94409043],
                            [39.40603532, -7.94417109],
                            [39.40572889, -7.9442356],
                            [39.40543861, -7.94438078],
                            [39.40512145, -7.9445869],
                            [39.40480609, -7.94478762],
                            [39.40446205, -7.94510305],
                            [39.40403199, -7.94527511],
                            [39.40368796, -7.94530379],
                            [39.40342993, -7.94547584],
                            [39.40325791, -7.94570527],
                            [39.40294255, -7.94576262],
                            [39.40265584, -7.94587731],
                            [39.40251248, -7.94613541],
                            [39.40248381, -7.94650819],
                            [39.40248381, -7.94690966],
                            [39.40216844, -7.94733982],
                            [39.40188175, -7.94756922],
                            [39.40159504, -7.94742583],
                            [39.40156638, -7.94705303],
                            [39.40122233, -7.94688098],
                            [39.40096431, -7.9469957],
                            [39.40079228, -7.94736849],
                            [39.40064894, -7.94779863],
                            [39.40041957, -7.94837214],
                            [39.40007554, -7.94908905],
                            [39.39978883, -7.9495192],
                            [39.39947345, -7.94997801],
                            [39.39918675, -7.95052288],
                            [39.3989574, -7.95092434],
                            [39.39887139, -7.95141186],
                            [39.39881404, -7.95184198],
                            [39.39878537, -7.95250154],
                            [39.39878537, -7.95298906],
                            [39.39872802, -7.95361992],
                            [39.39872802, -7.95445155],
                            [39.39869935, -7.95505374],
                            [39.39864202, -7.95554123],
                            [39.39861334, -7.95605742],
                            [39.39844132, -7.95663095],
                            [39.3978679, -7.95823682],
                            [39.39735185, -7.95926917],
                            [39.39700781, -7.95987136],
                            [39.3969218, -7.96055961],
                            [39.39686444, -7.96121915],
                            [39.39695046, -7.96182138],
                            [39.39689312, -7.9625096],
                            [39.39672108, -7.96328387],
                            [39.39640571, -7.96377136],
                            [39.39626236, -7.96428752],
                            [39.39597566, -7.96491842],
                            [39.39568897, -7.96552061],
                            [39.39551694, -7.96612281],
                            [39.39551694, -7.96689708],
                            [39.39560295, -7.96752795],
                            [39.39571763, -7.96813017],
                            [39.39571762, -7.96873236],
                            [39.39566028, -7.9693059],
                            [39.39566028, -7.96985075],
                            [39.39554559, -7.97042427],
                            [39.39548825, -7.97096913],
                            [39.39554559, -7.97157132],
                            [39.3956316, -7.97205884],
                            [39.39563159, -7.97257501],
                            [39.39580362, -7.97303383],
                            [39.39580361, -7.97357868],
                            [39.39586094, -7.97406618],
                            [39.39586094, -7.97446764],
                            [39.39580361, -7.97498383],
                            [39.39571759, -7.97552868],
                            [39.39571759, -7.9761022],
                            [39.39563158, -7.976475],
                            [39.39554556, -7.97690514],
                            [39.39537354, -7.97727793],
                            [39.39523018, -7.97765072],
                            [39.39514417, -7.97799484],
                            [39.3951155, -7.97848234],
                            [39.3950295, -7.97885513],
                            [39.39480013, -7.9791419],
                            [39.39475943, -7.97920297],
                            [39.39475357, -7.97927916],
                            [39.39471548, -7.97966026],
                            [39.39465834, -7.97998423],
                            [39.39462021, -7.98036536],
                            [39.39456306, -7.98074648],
                            [39.39448684, -7.98112761],
                            [39.39441064, -7.98152778],
                            [39.39427726, -7.98190891],
                            [39.39423915, -7.98230909],
                            [39.39423915, -7.98265017],
                            [39.39423133, -7.98266005],
                            [39.39392846, -7.98300628],
                            [39.39372653, -7.98319382],
                            [39.39351018, -7.98319382],
                            [39.3933948, -7.98290529],
                            [39.39329385, -7.98250136],
                            [39.39327943, -7.98205414],
                            [39.39326499, -7.98159252],
                            [39.39330827, -7.98133285],
                            [39.39336596, -7.98098661],
                            [39.39348135, -7.98055383],
                            [39.39368328, -7.98019318],
                            [39.39389962, -7.97983252],
                            [39.39414481, -7.9794863],
                            [39.39424576, -7.9790391],
                            [39.3942602, -7.97856304],
                            [39.3942602, -7.97798599],
                            [39.39420251, -7.97736567],
                            [39.39420251, -7.97690403],
                            [39.39415925, -7.97641353],
                            [39.39408713, -7.97593747],
                            [39.39407272, -7.97551911],
                            [39.39402943, -7.97501421],
                            [39.3939429, -7.97450928],
                            [39.3938852, -7.97410535],
                            [39.39385638, -7.97367257],
                            [39.39385638, -7.97334077],
                            [39.39389674, -7.97309855],
                            [39.39372479, -7.97289528],
                            [39.39369433, -7.97284577],
                            [39.39370236, -7.97282572],
                            [39.3938039, -7.97201607],
                            [39.39385492, -7.97123369],
                            [39.39385492, -7.97046834],
                            [39.39383791, -7.96971999],
                            [39.39387193, -7.96903967],
                            [39.39390595, -7.96849542],
                            [39.39399096, -7.96774706],
                            [39.39410999, -7.96728785],
                            [39.39421204, -7.96655651],
                            [39.39433107, -7.96594422],
                            [39.39438207, -7.96543399],
                            [39.3944671, -7.96505981],
                            [39.39460314, -7.96478768],
                            [39.39487521, -7.96434548],
                            [39.39513026, -7.96380122],
                            [39.39526631, -7.96327398],
                            [39.39528331, -7.96274673],
                            [39.39528331, -7.96230454],
                            [39.39528331, -7.96172626],
                            [39.39533433, -7.96097792],
                            [39.39538534, -7.96031461],
                            [39.39541936, -7.95980437],
                            [39.39553839, -7.9594642],
                            [39.39572543, -7.95893696],
                            [39.39584446, -7.95840971],
                            [39.39596349, -7.95805253],
                            [39.39608252, -7.95762734],
                            [39.39625256, -7.95721916],
                            [39.39659266, -7.95672591],
                            [39.39683071, -7.95630072],
                            [39.39700074, -7.95594355],
                            [39.39718781, -7.95548434],
                            [39.39734084, -7.95497411],
                            [39.39740885, -7.95459994],
                            [39.39740885, -7.95412371],
                            [39.39734084, -7.95380056],
                            [39.39722182, -7.95344338],
                            [39.39711979, -7.95312024],
                            [39.39701777, -7.95279709],
                            [39.39703477, -7.95252496],
                            [39.39715379, -7.95211676],
                            [39.39728984, -7.95181063],
                            [39.39754489, -7.95148747],
                            [39.39774895, -7.95116434],
                            [39.39800402, -7.95087519],
                            [39.39822507, -7.95060306],
                            [39.39844613, -7.9502459],
                            [39.39868418, -7.94973565],
                            [39.39897327, -7.94919141],
                            [39.39912629, -7.94868116],
                            [39.39924532, -7.94817093],
                            [39.39927934, -7.94767771],
                            [39.39924533, -7.9472185],
                            [39.39919431, -7.94684431],
                            [39.3991263, -7.94670825],
                            [39.39885423, -7.9466062],
                            [39.39851417, -7.94655519],
                            [39.39817407, -7.94653817],
                            [39.39776598, -7.94653816],
                            [39.39751091, -7.94655518],
                            [39.39742588, -7.94669124],
                            [39.39737489, -7.94687832],
                            [39.39737488, -7.94718447],
                            [39.39730685, -7.94760967],
                            [39.39718784, -7.94817092],
                            [39.39700077, -7.94866984],
                            [39.39686476, -7.94923676],
                            [39.3966607, -7.94955424],
                            [39.39634327, -7.94989439],
                            [39.39586717, -7.95021187],
                            [39.39548174, -7.95046132],
                            [39.39511897, -7.95086951],
                            [39.3948469, -7.95125501],
                            [39.3948469, -7.95170858],
                            [39.39496027, -7.9524796],
                            [39.39496027, -7.95300118],
                            [39.39511896, -7.95325062],
                            [39.39545906, -7.95347741],
                            [39.39552707, -7.95379488],
                            [39.3955044, -7.9541804],
                            [39.39523233, -7.95458859],
                            [39.39475622, -7.95511017],
                            [39.3942801, -7.95551835],
                            [39.39382666, -7.95601725],
                            [39.39323718, -7.95665221],
                            [39.39287441, -7.95708307],
                            [39.39251166, -7.95726449],
                        ],
                        [
                            [39.30431109, -7.94666793],
                            [39.30400037, -7.94627944],
                            [39.30368966, -7.94584432],
                            [39.30344107, -7.94548691],
                            [39.30317695, -7.94503626],
                            [39.30294392, -7.9445856],
                            [39.30274194, -7.94402615],
                            [39.30253997, -7.94357551],
                            [39.30253988, -7.94357438],
                            [39.30186232, -7.94373385],
                            [39.30146109, -7.94391778],
                            [39.30148004, -7.94397781],
                            [39.30157671, -7.94421264],
                            [39.30160778, -7.94439911],
                            [39.30156118, -7.94457005],
                            [39.30142135, -7.94472545],
                            [39.30120384, -7.94492749],
                            [39.30100187, -7.94528488],
                            [39.30075328, -7.94565784],
                            [39.3005047, -7.9459997],
                            [39.30030273, -7.94632604],
                            [39.30008523, -7.94668346],
                            [39.29988325, -7.94710303],
                            [39.29966574, -7.9476003],
                            [39.29957252, -7.94800433],
                            [39.29951037, -7.94834621],
                            [39.29954144, -7.94859485],
                            [39.29965019, -7.94871917],
                            [39.29996092, -7.94879687],
                            [39.30034933, -7.94881241],
                            [39.30079988, -7.94864147],
                            [39.30117275, -7.94839285],
                            [39.30157669, -7.94811313],
                            [39.30198063, -7.94786449],
                            [39.30216708, -7.94767802],
                            [39.30227584, -7.94758478],
                            [39.30243119, -7.94755368],
                            [39.30255548, -7.94764693],
                            [39.30261764, -7.94783341],
                            [39.30269532, -7.94808205],
                            [39.30271085, -7.9482996],
                            [39.30277299, -7.94845501],
                            [39.30295943, -7.94854824],
                            [39.30323908, -7.94851718],
                            [39.30340998, -7.94851718],
                            [39.30353427, -7.9486881],
                            [39.30359642, -7.94885904],
                            [39.30379838, -7.94895229],
                            [39.30398483, -7.94895229],
                            [39.30414018, -7.94898336],
                            [39.30429555, -7.94910767],
                            [39.30438875, -7.94929417],
                            [39.30434215, -7.94951173],
                            [39.30432662, -7.94976035],
                            [39.30437323, -7.94994684],
                            [39.30454413, -7.95013331],
                            [39.30480824, -7.95030425],
                            [39.30513453, -7.95030425],
                            [39.30539862, -7.95021102],
                            [39.30563166, -7.9500867],
                            [39.30584917, -7.94980699],
                            [39.30619097, -7.94944957],
                            [39.30628419, -7.94924756],
                            [39.30602007, -7.94904554],
                            [39.30567828, -7.94870366],
                            [39.30542969, -7.94834623],
                            [39.30511897, -7.94791112],
                            [39.30480825, -7.94741385],
                            [39.30455967, -7.94700981],
                            [39.30431109, -7.94666793],
                        ],
                        [
                            [39.30911191, -7.9471772],
                            [39.30878525, -7.94663265],
                            [39.30848038, -7.94617523],
                            [39.30815374, -7.94578317],
                            [39.30767465, -7.94545644],
                            [39.30704313, -7.94521685],
                            [39.30643339, -7.94486834],
                            [39.30615029, -7.94445448],
                            [39.30569298, -7.94377926],
                            [39.30538811, -7.94330006],
                            [39.30512679, -7.9426684],
                            [39.30506147, -7.94203673],
                            [39.30501791, -7.94127437],
                            [39.30497437, -7.94068626],
                            [39.30473482, -7.94003281],
                            [39.30451706, -7.93944472],
                            [39.30434285, -7.93953184],
                            [39.30434285, -7.93990213],
                            [39.30432107, -7.94053379],
                            [39.30440817, -7.94094764],
                            [39.30438639, -7.94133971],
                            [39.30438639, -7.94179712],
                            [39.30440817, -7.94227632],
                            [39.30440816, -7.94284264],
                            [39.30453881, -7.94345252],
                            [39.30469126, -7.94401885],
                            [39.30488725, -7.94475942],
                            [39.30514856, -7.94526039],
                            [39.30562765, -7.94582672],
                            [39.30617206, -7.94658908],
                            [39.30654226, -7.94715541],
                            [39.30710844, -7.94765638],
                            [39.30760931, -7.94798311],
                            [39.30819729, -7.94833162],
                            [39.30893768, -7.94857121],
                            [39.30946033, -7.94870191],
                            [39.30991764, -7.9487237],
                            [39.31000475, -7.94850588],
                            [39.30980875, -7.94826628],
                            [39.30956922, -7.94798312],
                            [39.30937323, -7.94763462],
                            [39.30911191, -7.9471772],
                        ],
                        [
                            [39.41448839, -7.95240834],
                            [39.41447236, -7.95269694],
                            [39.41447236, -7.95295346],
                            [39.4143922, -7.95316189],
                            [39.41410368, -7.95329013],
                            [39.41375105, -7.95337031],
                            [39.41344649, -7.95343443],
                            [39.4130618, -7.95343443],
                            [39.41298166, -7.95359476],
                            [39.41278931, -7.95380317],
                            [39.41246871, -7.95383524],
                            [39.4122283, -7.95396351],
                            [39.41192375, -7.95423605],
                            [39.4116833, -7.95446051],
                            [39.41142684, -7.95462083],
                            [39.41134671, -7.95478116],
                            [39.41125052, -7.95492545],
                            [39.41125052, -7.95508579],
                            [39.4113467, -7.95524611],
                            [39.41144286, -7.95540643],
                            [39.41133066, -7.95556676],
                            [39.41133065, -7.95580724],
                            [39.41150698, -7.95587138],
                            [39.41171536, -7.95571105],
                            [39.41193976, -7.95555073],
                            [39.4121802, -7.9553904],
                            [39.41246871, -7.95537438],
                            [39.41267709, -7.95548661],
                            [39.41283739, -7.95564692],
                            [39.41320605, -7.95582328],
                            [39.41359074, -7.95574313],
                            [39.41391132, -7.9555828],
                            [39.41413573, -7.95539041],
                            [39.41429602, -7.95545455],
                            [39.41448837, -7.955679],
                            [39.41469676, -7.95595155],
                            [39.41496924, -7.95622412],
                            [39.41525776, -7.95611189],
                            [39.41572261, -7.9559676],
                            [39.4159951, -7.95577521],
                            [39.41617142, -7.95529421],
                            [39.4159951, -7.95497358],
                            [39.41575467, -7.95474911],
                            [39.41562643, -7.9543483],
                            [39.41578674, -7.9540597],
                            [39.41605923, -7.95367493],
                            [39.41626759, -7.95335428],
                            [39.41650804, -7.95317792],
                            [39.41687671, -7.95309776],
                            [39.41718125, -7.95301761],
                            [39.41759801, -7.9529214],
                            [39.41779037, -7.95272903],
                            [39.41788652, -7.95250456],
                            [39.41799875, -7.95224805],
                            [39.41788652, -7.95200755],
                            [39.41777432, -7.9517831],
                            [39.41787051, -7.95155864],
                            [39.41799875, -7.95125402],
                            [39.41799876, -7.95096542],
                            [39.4179667, -7.9507089],
                            [39.4179667, -7.95042032],
                            [39.4179667, -7.95017983],
                            [39.41788653, -7.9498752],
                            [39.41783846, -7.94961868],
                            [39.4176942, -7.94934613],
                            [39.41761406, -7.94912168],
                            [39.41761406, -7.94880103],
                            [39.41753393, -7.94843227],
                            [39.41748582, -7.94820782],
                            [39.41742171, -7.9478551],
                            [39.41740568, -7.94756652],
                            [39.41724539, -7.94722983],
                            [39.41706907, -7.94729395],
                            [39.41697291, -7.94740619],
                            [39.41679657, -7.94755047],
                            [39.41662028, -7.94758253],
                            [39.41649203, -7.94748634],
                            [39.41642791, -7.94722983],
                            [39.41633174, -7.9470695],
                            [39.41615542, -7.94692519],
                            [39.41599513, -7.9468771],
                            [39.41585087, -7.94694124],
                            [39.41570663, -7.94703743],
                            [39.4155944, -7.94721379],
                            [39.4154822, -7.94750237],
                            [39.4154822, -7.94791923],
                            [39.41548219, -7.94819177],
                            [39.41548219, -7.94859259],
                            [39.41530589, -7.94884911],
                            [39.41519367, -7.94905754],
                            [39.41519367, -7.94936215],
                            [39.41517764, -7.94969884],
                            [39.41530588, -7.95000346],
                            [39.41557837, -7.95011569],
                            [39.41583483, -7.95021189],
                            [39.41613938, -7.95029205],
                            [39.41650805, -7.95035619],
                            [39.41665231, -7.95050048],
                            [39.41663628, -7.950757],
                            [39.41657216, -7.95099748],
                            [39.41636379, -7.95115782],
                            [39.41612334, -7.95118989],
                            [39.41609128, -7.9513502],
                            [39.41607527, -7.95162275],
                            [39.41594702, -7.95184721],
                            [39.41581881, -7.95202356],
                            [39.41562644, -7.95219992],
                            [39.4154341, -7.95231216],
                            [39.41511352, -7.95231216],
                            [39.41482499, -7.9521839],
                            [39.41463265, -7.95221596],
                            [39.41448839, -7.95240834],
                        ],
                        [
                            [39.41053704, -7.96177893],
                            [39.41035173, -7.96201988],
                            [39.41035173, -7.9621496],
                            [39.41024056, -7.9622608],
                            [39.4100738, -7.96240907],
                            [39.40992556, -7.96244614],
                            [39.40979586, -7.96244614],
                            [39.40959202, -7.96244614],
                            [39.40946233, -7.96244614],
                            [39.40935114, -7.96235348],
                            [39.40912879, -7.96231641],
                            [39.40896202, -7.96231641],
                            [39.40872114, -7.96248321],
                            [39.40853583, -7.96274267],
                            [39.40835054, -7.96287242],
                            [39.4081467, -7.96289095],
                            [39.40792435, -7.96276122],
                            [39.40762787, -7.96246467],
                            [39.40736847, -7.96224226],
                            [39.40714612, -7.96194572],
                            [39.40696082, -7.96174184],
                            [39.40677553, -7.96172331],
                            [39.40647906, -7.9616677],
                            [39.40631228, -7.96177891],
                            [39.40603434, -7.96179744],
                            [39.40603434, -7.96159356],
                            [39.40618258, -7.96138969],
                            [39.40605286, -7.96125995],
                            [39.4058861, -7.96111169],
                            [39.40584905, -7.96092634],
                            [39.40571932, -7.96074103],
                            [39.40555257, -7.96055568],
                            [39.40536728, -7.960463],
                            [39.40523756, -7.96051861],
                            [39.40501521, -7.96053714],
                            [39.40482992, -7.96059274],
                            [39.40455196, -7.96062981],
                            [39.40431108, -7.96062981],
                            [39.40412579, -7.96057421],
                            [39.40401461, -7.96042593],
                            [39.40390344, -7.96038886],
                            [39.4037552, -7.96037034],
                            [39.4035699, -7.96033327],
                            [39.40347724, -7.96022206],
                            [39.40321783, -7.96024059],
                            [39.40312519, -7.96038886],
                            [39.40297695, -7.96050006],
                            [39.40297695, -7.96070394],
                            [39.40312519, -7.96088926],
                            [39.40321783, -7.96124142],
                            [39.40334754, -7.96142675],
                            [39.40344018, -7.96172329],
                            [39.40344018, -7.96194571],
                            [39.40340311, -7.96218664],
                            [39.40334754, -7.96246465],
                            [39.40362548, -7.9624276],
                            [39.40381077, -7.96240905],
                            [39.4041443, -7.96229784],
                            [39.40460756, -7.96229784],
                            [39.40492255, -7.96229784],
                            [39.4050708, -7.96252026],
                            [39.40521902, -7.96289094],
                            [39.40529314, -7.96324308],
                            [39.40531167, -7.96348402],
                            [39.40531167, -7.96372495],
                            [39.4054599, -7.96394735],
                            [39.4054599, -7.96426244],
                            [39.4054599, -7.96455898],
                            [39.4054599, -7.96474431],
                            [39.40545989, -7.96509646],
                            [39.40568224, -7.965393],
                            [39.40568224, -7.96574514],
                            [39.4055896, -7.96607875],
                            [39.40555254, -7.96643089],
                            [39.40536725, -7.9667089],
                            [39.4051449, -7.9669313],
                            [39.40488547, -7.96724639],
                            [39.40475577, -7.96750586],
                            [39.40481135, -7.96774679],
                            [39.40481134, -7.96796919],
                            [39.40464458, -7.96819161],
                            [39.40457048, -7.96845108],
                            [39.40449635, -7.96874762],
                            [39.40444076, -7.96906269],
                            [39.40438517, -7.96941483],
                            [39.40434811, -7.96982258],
                            [39.40438516, -7.97015619],
                            [39.40449635, -7.97039713],
                            [39.40460753, -7.97061953],
                            [39.404904, -7.9707122],
                            [39.40520046, -7.97084195],
                            [39.40570077, -7.97084195],
                            [39.40607136, -7.97078633],
                            [39.40638635, -7.97061954],
                            [39.40651608, -7.97037862],
                            [39.40668284, -7.9701006],
                            [39.40692374, -7.96978552],
                            [39.40712754, -7.96941485],
                            [39.40729432, -7.96902563],
                            [39.4074055, -7.96880322],
                            [39.40768345, -7.96833988],
                            [39.4080355, -7.96789508],
                            [39.40829493, -7.96750587],
                            [39.40844316, -7.96713519],
                            [39.40853581, -7.96678305],
                            [39.40877669, -7.9665977],
                            [39.40909171, -7.96659771],
                            [39.40949937, -7.96672745],
                            [39.40990702, -7.96674599],
                            [39.41024054, -7.96672745],
                            [39.41051848, -7.96652357],
                            [39.41053702, -7.96617145],
                            [39.41035171, -7.96593049],
                            [39.41016642, -7.96578222],
                            [39.4099626, -7.9657637],
                            [39.40961054, -7.96570811],
                            [39.4093326, -7.96555983],
                            [39.4093326, -7.96524475],
                            [39.40940671, -7.96496675],
                            [39.4095735, -7.96463314],
                            [39.40981436, -7.96454045],
                            [39.41001818, -7.96461459],
                            [39.41037026, -7.9647258],
                            [39.41074086, -7.96478141],
                            [39.41109291, -7.9647258],
                            [39.41137087, -7.96467022],
                            [39.41168587, -7.96468874],
                            [39.41192675, -7.96463315],
                            [39.41218618, -7.96481848],
                            [39.41248263, -7.96515209],
                            [39.4127791, -7.96559691],
                            [39.4131497, -7.96600464],
                            [39.41361294, -7.96626412],
                            [39.41398354, -7.96652359],
                            [39.41450237, -7.96665335],
                            [39.41491001, -7.96683868],
                            [39.41535471, -7.96683868],
                            [39.41542884, -7.9666348],
                            [39.41559561, -7.96648653],
                            [39.41581796, -7.96626413],
                            [39.41591062, -7.96598612],
                            [39.41579944, -7.96576372],
                            [39.41555855, -7.96570813],
                            [39.4153362, -7.96572665],
                            [39.41500267, -7.96576372],
                            [39.41461355, -7.96561544],
                            [39.41448383, -7.96528183],
                            [39.41428001, -7.96513357],
                            [39.41390942, -7.96483702],
                            [39.41363146, -7.96455902],
                            [39.41333499, -7.96429955],
                            [39.41311264, -7.96404006],
                            [39.41302001, -7.96383618],
                            [39.41311264, -7.96352112],
                            [39.41322383, -7.96315044],
                            [39.41324236, -7.9627983],
                            [39.41313118, -7.96255736],
                            [39.41294589, -7.9625759],
                            [39.41279765, -7.96270562],
                            [39.41250117, -7.96283537],
                            [39.41229735, -7.96270562],
                            [39.41233441, -7.96240908],
                            [39.41229735, -7.96218668],
                            [39.41222325, -7.96201988],
                            [39.41224177, -7.9617604],
                            [39.41259382, -7.96142679],
                            [39.41281618, -7.96116732],
                            [39.4129459, -7.96087078],
                            [39.41296441, -7.96059278],
                            [39.41300148, -7.9602777],
                            [39.4130756, -7.95998115],
                            [39.4130756, -7.95975875],
                            [39.41296442, -7.95957342],
                            [39.41277913, -7.95948074],
                            [39.41268649, -7.95938807],
                            [39.41240855, -7.95929541],
                            [39.41216765, -7.95936955],
                            [39.41190824, -7.95959195],
                            [39.41161177, -7.95979581],
                            [39.41133382, -7.95985142],
                            [39.41116705, -7.95996262],
                            [39.4110188, -7.96012942],
                            [39.41090764, -7.96029623],
                            [39.41066675, -7.9606113],
                            [39.41066675, -7.96085222],
                            [39.41066675, -7.96109317],
                            [39.4105741, -7.96144532],
                            [39.41053704, -7.96177893],
                        ],
                        [
                            [39.41726947, -7.96293423],
                            [39.41749183, -7.96303308],
                            [39.41786243, -7.9630825],
                            [39.41788711, -7.96293423],
                            [39.41786243, -7.96273654],
                            [39.41803536, -7.96248942],
                            [39.41825771, -7.96229173],
                            [39.41872715, -7.96211874],
                            [39.4189989, -7.96189634],
                            [39.41892478, -7.96164923],
                            [39.41882596, -7.96152568],
                            [39.41867772, -7.96140211],
                            [39.41840596, -7.96135269],
                            [39.41808479, -7.96140211],
                            [39.41786243, -7.9613774],
                            [39.41773889, -7.96122914],
                            [39.41761537, -7.96100672],
                            [39.41741772, -7.96110556],
                            [39.41722005, -7.96122913],
                            [39.4169977, -7.96125382],
                            [39.41665181, -7.96127855],
                            [39.41633065, -7.96113027],
                            [39.41633065, -7.96098201],
                            [39.41623181, -7.96083373],
                            [39.41610829, -7.96083373],
                            [39.41591064, -7.96083373],
                            [39.41581182, -7.96075959],
                            [39.41568827, -7.96075959],
                            [39.41556475, -7.96088314],
                            [39.41549062, -7.96105613],
                            [39.41549062, -7.96130325],
                            [39.41561415, -7.96152567],
                            [39.41571299, -7.96152567],
                            [39.41591064, -7.9616245],
                            [39.41620711, -7.96189633],
                            [39.41635533, -7.96221758],
                            [39.41647888, -7.96256355],
                            [39.41652828, -7.96290952],
                            [39.4166271, -7.9630578],
                            [39.41684945, -7.96290952],
                            [39.41707182, -7.96290952],
                            [39.41726947, -7.96293423],
                        ],
                        [
                            [39.36671053, -7.97467556],
                            [39.36680262, -7.97451182],
                            [39.36685377, -7.97433785],
                            [39.36683332, -7.97410248],
                            [39.366731, -7.97386713],
                            [39.36660824, -7.97367267],
                            [39.36652638, -7.97341684],
                            [39.36656731, -7.97317125],
                            [39.36667985, -7.97302797],
                            [39.36686401, -7.97296657],
                            [39.36715049, -7.97283351],
                            [39.36732443, -7.97270049],
                            [39.36739603, -7.97255721],
                            [39.36742673, -7.97235255],
                            [39.3674472, -7.97225022],
                            [39.3676723, -7.97200461],
                            [39.36781551, -7.97184088],
                            [39.36783598, -7.97165667],
                            [39.36777459, -7.9715134],
                            [39.36760068, -7.97141106],
                            [39.36746765, -7.97137013],
                            [39.36727327, -7.97139059],
                            [39.36717096, -7.9714213],
                            [39.36703795, -7.97155434],
                            [39.36696634, -7.97169761],
                            [39.36687425, -7.97173854],
                            [39.36680263, -7.97166691],
                            [39.36672077, -7.97157481],
                            [39.36664917, -7.97149293],
                            [39.36654686, -7.97144175],
                            [39.36638315, -7.97145199],
                            [39.36627062, -7.97156457],
                            [39.36614783, -7.9716669],
                            [39.36607621, -7.97181016],
                            [39.36601482, -7.97195344],
                            [39.36587159, -7.9722195],
                            [39.36576928, -7.97246513],
                            [39.36572834, -7.97274143],
                            [39.36558513, -7.97300749],
                            [39.36554419, -7.97322239],
                            [39.3654521, -7.97357033],
                            [39.3652884, -7.97396944],
                            [39.36518609, -7.97424575],
                            [39.36508378, -7.97451182],
                            [39.36504284, -7.97475742],
                            [39.36506331, -7.97502349],
                            [39.36505307, -7.97540212],
                            [39.36504284, -7.97564775],
                            [39.36506331, -7.97578077],
                            [39.36520655, -7.97580124],
                            [39.36538047, -7.97573984],
                            [39.36559532, -7.97563751],
                            [39.3657488, -7.9754226],
                            [39.36598412, -7.97525888],
                            [39.36628084, -7.97501326],
                            [39.36655708, -7.97482907],
                            [39.36671053, -7.97467556],
                        ],
                        [
                            [39.35688321, -7.97118707],
                            [39.3570243, -7.97108938],
                            [39.35721967, -7.97110024],
                            [39.35736076, -7.97124136],
                            [39.3574693, -7.97142591],
                            [39.35753442, -7.97166474],
                            [39.3576538, -7.97183844],
                            [39.35787087, -7.97202301],
                            [39.35810966, -7.97217499],
                            [39.35831587, -7.97228355],
                            [39.35858722, -7.97232698],
                            [39.35886941, -7.97222927],
                            [39.35915162, -7.97210985],
                            [39.35942297, -7.97205557],
                            [39.35965088, -7.97207728],
                            [39.35988967, -7.97220757],
                            [39.36009588, -7.97220757],
                            [39.36049747, -7.97218585],
                            [39.36083391, -7.97210985],
                            [39.36113782, -7.97212072],
                            [39.36137661, -7.97218585],
                            [39.3616588, -7.97221844],
                            [39.36188673, -7.97220758],
                            [39.36211466, -7.97220758],
                            [39.36229917, -7.97212072],
                            [39.36248368, -7.97201217],
                            [39.36265734, -7.97201217],
                            [39.36283101, -7.97183847],
                            [39.36296124, -7.97161049],
                            [39.36312406, -7.97142594],
                            [39.36326515, -7.97134996],
                            [39.36338453, -7.97130653],
                            [39.36351478, -7.97127396],
                            [39.36364502, -7.9712631],
                            [39.36379699, -7.9712631],
                            [39.36391636, -7.97120882],
                            [39.36401406, -7.97107855],
                            [39.36412258, -7.97093741],
                            [39.36415515, -7.97076373],
                            [39.36416601, -7.97056831],
                            [39.36416601, -7.9703512],
                            [39.36410087, -7.97002551],
                            [39.36410087, -7.96976496],
                            [39.36401406, -7.96955869],
                            [39.36395978, -7.96934157],
                            [39.36387296, -7.96907016],
                            [39.3636993, -7.96879878],
                            [39.36354735, -7.96862506],
                            [39.36334114, -7.96851651],
                            [39.36306979, -7.9684948],
                            [39.3627876, -7.96846223],
                            [39.36258137, -7.9684188],
                            [39.36241858, -7.96825596],
                            [39.36226662, -7.96806056],
                            [39.3621581, -7.96780001],
                            [39.36204521, -7.96760504],
                            [39.36194103, -7.96744524],
                            [39.36182988, -7.96730628],
                            [39.36168401, -7.96718816],
                            [39.36155203, -7.96711866],
                            [39.36135752, -7.96699361],
                            [39.36110052, -7.9668616],
                            [39.36089212, -7.96675739],
                            [39.36076014, -7.96673653],
                            [39.36052396, -7.96666706],
                            [39.36032253, -7.96661842],
                            [39.36019054, -7.96659062],
                            [39.36006551, -7.96653505],
                            [39.35991269, -7.96643776],
                            [39.35973209, -7.96634745],
                            [39.35937671, -7.96604414],
                            [39.35927103, -7.966063],
                            [39.35886944, -7.96585674],
                            [39.35856555, -7.96568306],
                            [39.35823993, -7.96567219],
                            [39.35788176, -7.96573733],
                            [39.35757786, -7.96587845],
                            [39.35724139, -7.966063],
                            [39.3569592, -7.96629097],
                            [39.35666616, -7.96651895],
                            [39.35650334, -7.96679036],
                            [39.35629713, -7.96711605],
                            [39.35610176, -7.96735489],
                            [39.35590639, -7.96745258],
                            [39.35558078, -7.96753943],
                            [39.35515748, -7.967572],
                            [39.35479933, -7.96759371],
                            [39.35448457, -7.967648],
                            [39.35423493, -7.96771312],
                            [39.35381165, -7.96782168],
                            [39.35338835, -7.96795194],
                            [39.35308444, -7.96807137],
                            [39.35287823, -7.96825592],
                            [39.35282395, -7.96842962],
                            [39.35283481, -7.96855989],
                            [39.35294335, -7.96869017],
                            [39.3530953, -7.96877701],
                            [39.35323638, -7.968853],
                            [39.35343177, -7.96887472],
                            [39.35368138, -7.969005],
                            [39.3538442, -7.96916784],
                            [39.35397443, -7.96931982],
                            [39.35406127, -7.96948266],
                            [39.35398529, -7.96967807],
                            [39.35376822, -7.96984092],
                            [39.3534209, -7.97000374],
                            [39.35314957, -7.97010146],
                            [39.35294334, -7.97025343],
                            [39.35284566, -7.97038372],
                            [39.3528565, -7.97055741],
                            [39.3529759, -7.97067682],
                            [39.35313871, -7.97082882],
                            [39.3534426, -7.97099166],
                            [39.35372479, -7.97112194],
                            [39.35409382, -7.97121963],
                            [39.35444114, -7.97131735],
                            [39.35467991, -7.97131735],
                            [39.35486442, -7.97131735],
                            [39.35498382, -7.97136077],
                            [39.35511406, -7.97151276],
                            [39.35513576, -7.97178416],
                            [39.35514662, -7.97217498],
                            [39.35513576, -7.97256579],
                            [39.35515746, -7.97289147],
                            [39.35523344, -7.97309775],
                            [39.35537455, -7.97314118],
                            [39.35565674, -7.97309775],
                            [39.35591723, -7.97301089],
                            [39.35619942, -7.97282634],
                            [39.35641651, -7.97260922],
                            [39.3565793, -7.97241381],
                            [39.35666614, -7.97224012],
                            [39.35673125, -7.97202301],
                            [39.35673125, -7.97174074],
                            [39.35673125, -7.97150191],
                            [39.35679637, -7.97133907],
                            [39.35688321, -7.97118707],
                        ],
                        [
                            [39.37475502, -8.00784597],
                            [39.37446784, -8.0079554],
                            [39.37415332, -8.00809217],
                            [39.37386616, -8.00821527],
                            [39.37355164, -8.00836574],
                            [39.37331917, -8.00854355],
                            [39.37297729, -8.00868032],
                            [39.3727038, -8.00884445],
                            [39.37243031, -8.00898124],
                            [39.37202006, -8.00909065],
                            [39.37169187, -8.00911801],
                            [39.37109018, -8.00910433],
                            [39.37067993, -8.00910433],
                            [39.37033807, -8.00906329],
                            [39.37007825, -8.00900858],
                            [39.36979107, -8.0088718],
                            [39.36959963, -8.00869398],
                            [39.36943553, -8.00852984],
                            [39.36929878, -8.00835203],
                            [39.36910734, -8.00825629],
                            [39.36888855, -8.00822893],
                            [39.36865607, -8.00826996],
                            [39.36846463, -8.00840674],
                            [39.36838257, -8.00858455],
                            [39.36832788, -8.00876237],
                            [39.36819112, -8.00888547],
                            [39.36794497, -8.00889914],
                            [39.36773986, -8.00885812],
                            [39.36764414, -8.00859822],
                            [39.36760311, -8.00814686],
                            [39.36748004, -8.00791433],
                            [39.36727493, -8.00784594],
                            [39.36706979, -8.00792802],
                            [39.36693305, -8.00806479],
                            [39.36686467, -8.00828363],
                            [39.36683733, -8.00850248],
                            [39.36678263, -8.00878971],
                            [39.36664588, -8.00902224],
                            [39.36650912, -8.00914534],
                            [39.36637238, -8.00924109],
                            [39.36618093, -8.00924109],
                            [39.36601683, -8.00921372],
                            [39.36572967, -8.00915901],
                            [39.36549719, -8.00915901],
                            [39.36522371, -8.00906327],
                            [39.3649502, -8.00892649],
                            [39.36458098, -8.00876235],
                            [39.36432116, -8.00869396],
                            [39.36399297, -8.00863925],
                            [39.3637605, -8.00863925],
                            [39.36356905, -8.00868028],
                            [39.36344598, -8.00881706],
                            [39.36340495, -8.00896751],
                            [39.36348701, -8.0092274],
                            [39.36371947, -8.00954199],
                            [39.36400664, -8.00978819],
                            [39.36425279, -8.01006175],
                            [39.36448526, -8.01029427],
                            [39.36466303, -8.01047209],
                            [39.36526471, -8.01069093],
                            [39.36557923, -8.01082771],
                            [39.36589375, -8.01081403],
                            [39.3661399, -8.01092346],
                            [39.3664134, -8.01107391],
                            [39.36663219, -8.01103289],
                            [39.36690569, -8.01104657],
                            [39.36730226, -8.01099185],
                            [39.3677125, -8.01088243],
                            [39.36816377, -8.01070462],
                            [39.36856033, -8.01049946],
                            [39.36886118, -8.0102806],
                            [39.36916203, -8.01007544],
                            [39.36947655, -8.00989763],
                            [39.3698321, -8.00982924],
                            [39.37018765, -8.00978822],
                            [39.37059788, -8.00969247],
                            [39.37098077, -8.00965143],
                            [39.3716782, -8.00956937],
                            [39.3722115, -8.00952834],
                            [39.3726491, -8.00952834],
                            [39.37301832, -8.00951467],
                            [39.37349694, -8.00936422],
                            [39.3740029, -8.00920008],
                            [39.37448153, -8.00906331],
                            [39.37494646, -8.00889917],
                            [39.37547978, -8.00874872],
                            [39.37582165, -8.00874872],
                            [39.37599942, -8.00862562],
                            [39.37627291, -8.00831104],
                            [39.37664213, -8.00801012],
                            [39.37702504, -8.00791437],
                            [39.37751732, -8.00791437],
                            [39.37785919, -8.00776392],
                            [39.3780233, -8.00759979],
                            [39.37810534, -8.00732624],
                            [39.37817372, -8.00712107],
                            [39.37809167, -8.00701165],
                            [39.37792757, -8.00694326],
                            [39.3777498, -8.00692958],
                            [39.37748998, -8.00691589],
                            [39.37716948, -8.00689024],
                            [39.37686094, -8.0069706],
                            [39.37668317, -8.00706635],
                            [39.37656009, -8.00717578],
                            [39.37650539, -8.00729888],
                            [39.37639599, -8.00742196],
                            [39.3762319, -8.00753139],
                            [39.37602677, -8.00759978],
                            [39.37573961, -8.00759978],
                            [39.37547979, -8.00766816],
                            [39.37519261, -8.0077092],
                            [39.37493279, -8.00777759],
                            [39.37475502, -8.00784597],
                        ],
                        [
                            [39.41934636, -7.99364429],
                            [39.41938025, -7.99355712],
                            [39.41938025, -7.99346995],
                            [39.41937057, -7.99335858],
                            [39.41936572, -7.99321812],
                            [39.41937057, -7.99305348],
                            [39.41935605, -7.99290334],
                            [39.41932216, -7.99277744],
                            [39.41927857, -7.99266605],
                            [39.41922532, -7.9925692],
                            [39.41918659, -7.99249172],
                            [39.4191527, -7.99243844],
                            [39.41907522, -7.99239001],
                            [39.41898324, -7.99238034],
                            [39.41892029, -7.99239001],
                            [39.41884283, -7.99242391],
                            [39.4187702, -7.99248204],
                            [39.41874116, -7.99254015],
                            [39.41868305, -7.99260794],
                            [39.41862979, -7.99268542],
                            [39.41856233, -7.99277991],
                            [39.41858043, -7.99282128],
                            [39.41881713, -7.99334736],
                            [39.4190012, -7.99379452],
                            [39.41914443, -7.99403332],
                            [39.41916237, -7.99399298],
                            [39.41920112, -7.99391548],
                            [39.41924952, -7.99385252],
                            [39.41928825, -7.99377505],
                            [39.41934636, -7.99364429],
                        ],
                        [
                            [39.29766829, -7.93585635],
                            [39.29772304, -7.93563731],
                            [39.29768654, -7.93536352],
                            [39.29744932, -7.93527225],
                            [39.29723034, -7.93530876],
                            [39.29701135, -7.93529051],
                            [39.29671938, -7.93516275],
                            [39.2965004, -7.93498021],
                            [39.29611718, -7.93479769],
                            [39.29589819, -7.9345969],
                            [39.29549674, -7.93441438],
                            [39.295296, -7.9342136],
                            [39.29504053, -7.93404933],
                            [39.2947668, -7.93392156],
                            [39.29462081, -7.93386681],
                            [39.29442008, -7.93372078],
                            [39.29425584, -7.93353825],
                            [39.29401862, -7.93337398],
                            [39.29376314, -7.93315496],
                            [39.29348942, -7.93293591],
                            [39.29319743, -7.93271689],
                            [39.29294196, -7.93251611],
                            [39.29246751, -7.93227882],
                            [39.29223027, -7.93222406],
                            [39.29195655, -7.93211455],
                            [39.29171932, -7.93207804],
                            [39.29139085, -7.93207804],
                            [39.29119011, -7.93216931],
                            [39.29104413, -7.93227881],
                            [39.29097113, -7.93238833],
                            [39.29089813, -7.93251611],
                            [39.2908069, -7.93268037],
                            [39.29062441, -7.93284465],
                            [39.29047843, -7.93304542],
                            [39.29029594, -7.93317319],
                            [39.29014994, -7.93319145],
                            [39.29005871, -7.93333746],
                            [39.29002221, -7.93364775],
                            [39.28996746, -7.93417708],
                            [39.2900587, -7.93472465],
                            [39.29011345, -7.93512621],
                            [39.29027768, -7.93552777],
                            [39.29044192, -7.93594757],
                            [39.29064264, -7.93629437],
                            [39.29084337, -7.93649516],
                            [39.29098936, -7.93669593],
                            [39.29129958, -7.93695146],
                            [39.2917193, -7.93726176],
                            [39.29206602, -7.93751731],
                            [39.29237624, -7.93773633],
                            [39.29268646, -7.93799186],
                            [39.29305143, -7.93821091],
                            [39.2934894, -7.93844819],
                            [39.29389085, -7.9385212],
                            [39.29427407, -7.93855771],
                            [39.29460254, -7.9385942],
                            [39.29505875, -7.93864897],
                            [39.29555145, -7.93868547],
                            [39.29587994, -7.93861246],
                            [39.2962449, -7.93848471],
                            [39.29651864, -7.93824743],
                            [39.29664637, -7.93795537],
                            [39.29664637, -7.9377181],
                            [39.29659162, -7.93733479],
                            [39.29653689, -7.93706101],
                            [39.29659162, -7.93675071],
                            [39.29688361, -7.93658643],
                            [39.29723033, -7.93642217],
                            [39.29744931, -7.93616664],
                            [39.29766829, -7.93585635],
                        ],
                        [
                            [39.29518653, -7.93244311],
                            [39.29571572, -7.93251613],
                            [39.29633617, -7.93268039],
                            [39.29688363, -7.93284468],
                            [39.29746757, -7.93304545],
                            [39.29792377, -7.93328273],
                            [39.29828874, -7.93346527],
                            [39.29847124, -7.93335575],
                            [39.29850774, -7.93304546],
                            [39.29858073, -7.93280818],
                            [39.29843474, -7.93257088],
                            [39.29806977, -7.93235186],
                            [39.2977413, -7.93213283],
                            [39.29735808, -7.93189553],
                            [39.29710261, -7.93171301],
                            [39.29653691, -7.93149399],
                            [39.29602595, -7.93145748],
                            [39.29564273, -7.93145748],
                            [39.29522301, -7.93149398],
                            [39.29489454, -7.93151222],
                            [39.29452959, -7.93153047],
                            [39.29432885, -7.93160348],
                            [39.29407337, -7.93167649],
                            [39.2937084, -7.93184077],
                            [39.29339818, -7.93184077],
                            [39.29319744, -7.93184077],
                            [39.29257699, -7.93191377],
                            [39.29246751, -7.9320963],
                            [39.29268649, -7.93220581],
                            [39.29292371, -7.93237009],
                            [39.29321568, -7.93255261],
                            [39.29350767, -7.93257086],
                            [39.2936719, -7.93260737],
                            [39.29387263, -7.93249786],
                            [39.29396387, -7.93233358],
                            [39.2942376, -7.93231533],
                            [39.29473031, -7.93242485],
                            [39.29518653, -7.93244311],
                        ],
                        [
                            [39.31938198, -7.93493102],
                            [39.3194056, -7.93511998],
                            [39.31945283, -7.93539162],
                            [39.31951186, -7.93558059],
                            [39.31965356, -7.93578137],
                            [39.31980705, -7.93600577],
                            [39.31996056, -7.93617111],
                            [39.32010226, -7.93633645],
                            [39.32038564, -7.93652543],
                            [39.32071625, -7.93671439],
                            [39.32095241, -7.93686793],
                            [39.32129483, -7.93700966],
                            [39.32151918, -7.93706871],
                            [39.32171991, -7.93706871],
                            [39.32197968, -7.93706871],
                            [39.32222764, -7.93703328],
                            [39.32241657, -7.93683251],
                            [39.32247561, -7.93653724],
                            [39.32247562, -7.93626561],
                            [39.32242838, -7.93595854],
                            [39.32236934, -7.93565146],
                            [39.32232211, -7.93527353],
                            [39.32228669, -7.93486016],
                            [39.32225127, -7.93454129],
                            [39.32225127, -7.93418697],
                            [39.32226309, -7.93372635],
                            [39.32229851, -7.93337205],
                            [39.32229851, -7.93305317],
                            [39.32229851, -7.93274609],
                            [39.3222867, -7.93252169],
                            [39.32221586, -7.93235635],
                            [39.32207417, -7.93215558],
                            [39.32195609, -7.93199023],
                            [39.32184982, -7.93182487],
                            [39.32175535, -7.9316241],
                            [39.32169632, -7.93143513],
                            [39.32169633, -7.9311635],
                            [39.32169633, -7.93084461],
                            [39.32169633, -7.93052574],
                            [39.32166089, -7.93024228],
                            [39.32157824, -7.93005331],
                            [39.32148379, -7.92992339],
                            [39.32134209, -7.92981711],
                            [39.32117679, -7.92968719],
                            [39.32107051, -7.92962813],
                            [39.32096425, -7.92956909],
                            [39.32083437, -7.92951003],
                            [39.3207399, -7.92951003],
                            [39.32058639, -7.92951003],
                            [39.32044471, -7.92952185],
                            [39.32035024, -7.92960452],
                            [39.32026759, -7.92968719],
                            [39.32017313, -7.92976986],
                            [39.3201377, -7.92992339],
                            [39.32003143, -7.93013599],
                            [39.31999601, -7.93033676],
                            [39.31988973, -7.93057296],
                            [39.31981889, -7.93076193],
                            [39.31974804, -7.93092728],
                            [39.31971262, -7.93104539],
                            [39.31964177, -7.93113986],
                            [39.3195355, -7.93135245],
                            [39.31946465, -7.93151781],
                            [39.31931115, -7.93164772],
                            [39.31911041, -7.93176582],
                            [39.31900415, -7.93189573],
                            [39.31889787, -7.93210832],
                            [39.31885065, -7.93229729],
                            [39.31883884, -7.93248625],
                            [39.31883884, -7.93269884],
                            [39.31886245, -7.93297048],
                            [39.3189333, -7.93326574],
                            [39.31902775, -7.9335492],
                            [39.3191104, -7.93387988],
                            [39.31919307, -7.93419877],
                            [39.31927572, -7.93449403],
                            [39.31933475, -7.93474205],
                            [39.31938198, -7.93493102],
                        ],
                        [
                            [39.29879973, -7.92786173],
                            [39.29869024, -7.92766096],
                            [39.29872674, -7.92733241],
                            [39.29879973, -7.92687609],
                            [39.29872674, -7.92640153],
                            [39.298599, -7.92607299],
                            [39.298599, -7.92574444],
                            [39.298672, -7.92545239],
                            [39.29872675, -7.92510559],
                            [39.29872675, -7.9250691],
                            [39.2986634, -7.92494983],
                            [39.29872675, -7.92495958],
                            [39.29921946, -7.9247223],
                            [39.29950181, -7.92450767],
                            [39.29950784, -7.92450739],
                            [39.29949975, -7.92450334],
                            [39.29927626, -7.92452197],
                            [39.29898757, -7.92453128],
                            [39.29872681, -7.92457785],
                            [39.29854055, -7.92461511],
                            [39.29837293, -7.92467099],
                            [39.29836464, -7.92467532],
                            [39.29836178, -7.92468579],
                            [39.29827053, -7.92490482],
                            [39.29823403, -7.92510559],
                            [39.29819755, -7.92536114],
                            [39.29812455, -7.92558016],
                            [39.2980333, -7.92578093],
                            [39.29790556, -7.92605473],
                            [39.29777782, -7.92627376],
                            [39.29755883, -7.92638328],
                            [39.29741285, -7.92658405],
                            [39.29724861, -7.92683958],
                            [39.29723036, -7.92711338],
                            [39.29717563, -7.92738716],
                            [39.29708438, -7.92756968],
                            [39.29702963, -7.92786172],
                            [39.29692013, -7.92817201],
                            [39.29681065, -7.92844581],
                            [39.29660991, -7.92862833],
                            [39.29640918, -7.92868309],
                            [39.29624494, -7.92868309],
                            [39.29602596, -7.92871959],
                            [39.29573399, -7.92871959],
                            [39.29546027, -7.92877435],
                            [39.29522302, -7.92890211],
                            [39.2949858, -7.9291029],
                            [39.29473032, -7.92941319],
                            [39.29458433, -7.92965046],
                            [39.29456608, -7.92992425],
                            [39.29458433, -7.93021629],
                            [39.29493105, -7.9302893],
                            [39.29533251, -7.9302893],
                            [39.29560624, -7.93028931],
                            [39.29635443, -7.93036232],
                            [39.2967194, -7.93045359],
                            [39.29721209, -7.93052659],
                            [39.29759531, -7.93063611],
                            [39.29803328, -7.93078214],
                            [39.298453, -7.9309099],
                            [39.29881797, -7.93101942],
                            [39.29900044, -7.93103768],
                            [39.29907344, -7.93083689],
                            [39.29907344, -7.93058136],
                            [39.29907344, -7.93028932],
                            [39.2989822, -7.92996077],
                            [39.29885447, -7.92965048],
                            [39.29870848, -7.92926718],
                            [39.29865373, -7.92895688],
                            [39.29867198, -7.92862834],
                            [39.29874498, -7.92837281],
                            [39.29879973, -7.92809902],
                            [39.29879973, -7.92786173],
                        ],
                        [
                            [39.27575269, -7.92806567],
                            [39.27608785, -7.92813013],
                            [39.27646166, -7.92819462],
                            [39.27704172, -7.9282333],
                            [39.27738977, -7.92824619],
                            [39.27767335, -7.92816884],
                            [39.27753156, -7.92784649],
                            [39.27723509, -7.92774335],
                            [39.27704172, -7.92774335],
                            [39.27683548, -7.92767888],
                            [39.27665502, -7.92762731],
                            [39.27637143, -7.92756284],
                            [39.27612652, -7.92753706],
                            [39.27588161, -7.92758863],
                            [39.27568824, -7.92769177],
                            [39.275482, -7.92778202],
                            [39.27549489, -7.92794963],
                            [39.27575269, -7.92806567],
                        ],
                        [
                            [39.28555121, -7.92197035],
                            [39.28555121, -7.92197193],
                            [39.28557176, -7.92215696],
                            [39.28560259, -7.92237283],
                            [39.28565398, -7.92265037],
                            [39.28569508, -7.92284569],
                            [39.28566425, -7.92319519],
                            [39.28571563, -7.9235447],
                            [39.28566425, -7.92399699],
                            [39.28565397, -7.92446985],
                            [39.28558203, -7.924696],
                            [39.2854587, -7.92493243],
                            [39.28548953, -7.92513802],
                            [39.28549981, -7.92540529],
                            [39.28558202, -7.92552865],
                            [39.28586978, -7.92548753],
                            [39.28631171, -7.92546697],
                            [39.2867639, -7.9254053],
                            [39.28724693, -7.92524083],
                            [39.28783386, -7.92512277],
                            [39.28843604, -7.92500231],
                            [39.28923895, -7.92498224],
                            [39.28958018, -7.92496216],
                            [39.28978091, -7.92490193],
                            [39.29026266, -7.9248417],
                            [39.29027467, -7.92482668],
                            [39.28975694, -7.92355558],
                            [39.28903903, -7.9225613],
                            [39.28804497, -7.92167749],
                            [39.28683002, -7.9211251],
                            [39.28583596, -7.92162223],
                            [39.28555121, -7.92197035],
                        ],
                        [
                            [39.32975051, -7.92599115],
                            [39.32998703, -7.92633526],
                            [39.33013756, -7.9267439],
                            [39.33013755, -7.92717405],
                            [39.33015905, -7.92749665],
                            [39.33026656, -7.92781927],
                            [39.3305461, -7.92818489],
                            [39.33082563, -7.92839996],
                            [39.33125568, -7.92846449],
                            [39.33162121, -7.92844299],
                            [39.33185775, -7.92822792],
                            [39.33187925, -7.9279053],
                            [39.33207277, -7.92756119],
                            [39.3323308, -7.92732461],
                            [39.33256733, -7.92713105],
                            [39.33265335, -7.92682995],
                            [39.33258883, -7.92657185],
                            [39.33220179, -7.92652884],
                            [39.33181474, -7.92652884],
                            [39.33153522, -7.9263998],
                            [39.33151371, -7.92618471],
                            [39.33175025, -7.92599116],
                            [39.33196527, -7.92558252],
                            [39.33209428, -7.92532443],
                            [39.33209428, -7.92485126],
                            [39.33209429, -7.92442112],
                            [39.33196528, -7.9240555],
                            [39.33177175, -7.92379741],
                            [39.33162123, -7.92353933],
                            [39.33155673, -7.92323823],
                            [39.33142772, -7.92295863],
                            [39.33144923, -7.92242095],
                            [39.33144923, -7.92188326],
                            [39.33136322, -7.92125955],
                            [39.33129871, -7.92061433],
                            [39.33127721, -7.92009816],
                            [39.3311267, -7.91968952],
                            [39.33095468, -7.91938841],
                            [39.33084717, -7.91919485],
                            [39.33065365, -7.91919485],
                            [39.33048162, -7.91936691],
                            [39.33050312, -7.91964649],
                            [39.33054613, -7.92018419],
                            [39.33054613, -7.92067884],
                            [39.33050311, -7.92097994],
                            [39.33022359, -7.92100146],
                            [39.33013758, -7.92065733],
                            [39.33003007, -7.92011966],
                            [39.32985805, -7.91949595],
                            [39.32977204, -7.91887224],
                            [39.32964303, -7.91857113],
                            [39.32949252, -7.91835606],
                            [39.329342, -7.91824852],
                            [39.32904096, -7.918227],
                            [39.32876143, -7.91829153],
                            [39.32846039, -7.91833454],
                            [39.32818087, -7.91818399],
                            [39.32800885, -7.91790439],
                            [39.32787984, -7.91747425],
                            [39.32768632, -7.91725918],
                            [39.32751429, -7.91725918],
                            [39.32734227, -7.91736671],
                            [39.32714876, -7.91758178],
                            [39.32686922, -7.91760328],
                            [39.32676171, -7.91738821],
                            [39.32676172, -7.91715164],
                            [39.3267187, -7.91691505],
                            [39.32648218, -7.91672149],
                            [39.32620265, -7.9164849],
                            [39.32598763, -7.91620532],
                            [39.3258156, -7.91592572],
                            [39.32584027, -7.91587638],
                            [39.3256308, -7.91594305],
                            [39.32512513, -7.91616431],
                            [39.32455626, -7.91632236],
                            [39.32452428, -7.9163309],
                            [39.32452546, -7.91635586],
                            [39.32452546, -7.91667846],
                            [39.32452546, -7.9171086],
                            [39.32450396, -7.9173667],
                            [39.32446094, -7.9176463],
                            [39.32441795, -7.91792588],
                            [39.32437493, -7.91829151],
                            [39.32439643, -7.91865714],
                            [39.32452545, -7.91902276],
                            [39.32486948, -7.91934537],
                            [39.32525653, -7.91958196],
                            [39.32570807, -7.91979703],
                            [39.32615963, -7.9199906],
                            [39.32648217, -7.92014114],
                            [39.32684771, -7.92031321],
                            [39.32708424, -7.92044225],
                            [39.32727776, -7.92065732],
                            [39.32747128, -7.92093692],
                            [39.32777231, -7.92115199],
                            [39.32813785, -7.92141009],
                            [39.32846038, -7.92153913],
                            [39.32869692, -7.9217542],
                            [39.32876141, -7.92207681],
                            [39.32873991, -7.92257148],
                            [39.3286754, -7.92313066],
                            [39.32869691, -7.92353932],
                            [39.32871841, -7.92390493],
                            [39.32893343, -7.92427056],
                            [39.32908395, -7.92472221],
                            [39.32921296, -7.92502331],
                            [39.32925596, -7.92534592],
                            [39.32934197, -7.92562552],
                            [39.32953549, -7.92581909],
                            [39.32975051, -7.92599115],
                        ],
                        [
                            [39.31915797, -7.91630675],
                            [39.31934484, -7.91616656],
                            [39.31934484, -7.91594851],
                            [39.31922025, -7.91574602],
                            [39.3190334, -7.91551239],
                            [39.31876868, -7.91523203],
                            [39.31851952, -7.91495166],
                            [39.31813022, -7.914609],
                            [39.31784994, -7.9143598],
                            [39.31763192, -7.91412616],
                            [39.31742949, -7.91403271],
                            [39.31713363, -7.91401714],
                            [39.31683775, -7.91401714],
                            [39.3165886, -7.91401714],
                            [39.3163083, -7.91397041],
                            [39.31610587, -7.91397041],
                            [39.31599687, -7.91383022],
                            [39.31595016, -7.91367446],
                            [39.31573215, -7.91356544],
                            [39.31556085, -7.91356544],
                            [39.31537399, -7.91356544],
                            [39.31498468, -7.91356544],
                            [39.31471996, -7.913581],
                            [39.31442409, -7.91359658],
                            [39.31414379, -7.91367445],
                            [39.31384793, -7.91381463],
                            [39.31356764, -7.91400154],
                            [39.31327176, -7.91418845],
                            [39.31300704, -7.9143442],
                            [39.31280461, -7.91445323],
                            [39.31257102, -7.91467129],
                            [39.3123063, -7.91487376],
                            [39.31213501, -7.91502953],
                            [39.31210387, -7.91529431],
                            [39.31218172, -7.91537218],
                            [39.31246202, -7.91537218],
                            [39.31263331, -7.91549679],
                            [39.31292918, -7.91548121],
                            [39.31322504, -7.91546564],
                            [39.31338077, -7.91545006],
                            [39.31356764, -7.91534104],
                            [39.31378564, -7.91512297],
                            [39.31395694, -7.91495164],
                            [39.31409709, -7.91481148],
                            [39.31436181, -7.91476475],
                            [39.31454866, -7.91473359],
                            [39.31471995, -7.91473359],
                            [39.31487568, -7.91482705],
                            [39.31501583, -7.91499838],
                            [39.31518712, -7.9151697],
                            [39.31549856, -7.91541892],
                            [39.31581, -7.91554353],
                            [39.31602801, -7.9156214],
                            [39.31618372, -7.91554353],
                            [39.31637059, -7.91545007],
                            [39.31652632, -7.91529433],
                            [39.31675989, -7.91526317],
                            [39.31690004, -7.91520087],
                            [39.31700905, -7.91521644],
                            [39.31710247, -7.91526317],
                            [39.31710246, -7.91540335],
                            [39.31702461, -7.91568371],
                            [39.31691561, -7.91593292],
                            [39.31677546, -7.91621328],
                            [39.31675988, -7.91646248],
                            [39.31685331, -7.91655594],
                            [39.31713362, -7.91649363],
                            [39.31739834, -7.91643134],
                            [39.31758521, -7.91630674],
                            [39.31783435, -7.91626001],
                            [39.31805236, -7.91627558],
                            [39.3183638, -7.91635347],
                            [39.31878424, -7.91636904],
                            [39.31915797, -7.91630675],
                        ],
                        [
                            [39.38168905, -7.91806154],
                            [39.38178688, -7.918245],
                            [39.38193362, -7.9184774],
                            [39.38211705, -7.91864863],
                            [39.38230048, -7.91883211],
                            [39.3823983, -7.91895441],
                            [39.38241053, -7.91915012],
                            [39.38241053, -7.91934581],
                            [39.38233716, -7.91952928],
                            [39.38215373, -7.91967606],
                            [39.3819703, -7.91973721],
                            [39.38177465, -7.91973721],
                            [39.38162791, -7.91982284],
                            [39.38157899, -7.91999407],
                            [39.38170128, -7.920202],
                            [39.38186024, -7.92040993],
                            [39.3820559, -7.92052001],
                            [39.38226378, -7.92056895],
                            [39.38248388, -7.9205934],
                            [39.38271623, -7.9205934],
                            [39.38288743, -7.92053225],
                            [39.38335212, -7.92052002],
                            [39.38356, -7.92061787],
                            [39.38379233, -7.92075241],
                            [39.38415919, -7.92091142],
                            [39.38439153, -7.92094812],
                            [39.38462388, -7.92097258],
                            [39.38491736, -7.92098481],
                            [39.38521084, -7.92092366],
                            [39.38535758, -7.92071572],
                            [39.38549209, -7.92050779],
                            [39.3854921, -7.9202754],
                            [39.3854921, -7.92006747],
                            [39.38533313, -7.91987177],
                            [39.38519863, -7.91956599],
                            [39.3850152, -7.91934582],
                            [39.38483177, -7.91908896],
                            [39.38466057, -7.91883212],
                            [39.38444046, -7.91859971],
                            [39.3843304, -7.91842848],
                            [39.38419589, -7.91830617],
                            [39.38406138, -7.91814716],
                            [39.38391464, -7.91797592],
                            [39.38374344, -7.91760898],
                            [39.38365784, -7.91748668],
                            [39.38356001, -7.91738883],
                            [39.38340103, -7.9173032],
                            [39.38327876, -7.91721758],
                            [39.38315647, -7.91718088],
                            [39.38305864, -7.91713197],
                            [39.3829119, -7.91705858],
                            [39.38278962, -7.91696073],
                            [39.38270402, -7.9168751],
                            [39.38255727, -7.9167528],
                            [39.38241055, -7.91663048],
                            [39.38230049, -7.91650818],
                            [39.38222712, -7.9163614],
                            [39.38211706, -7.91622684],
                            [39.38208037, -7.91610454],
                            [39.38203145, -7.91599445],
                            [39.38189694, -7.91587215],
                            [39.38182357, -7.91581098],
                            [39.38165238, -7.91571313],
                            [39.38148118, -7.91571313],
                            [39.38129775, -7.91571313],
                            [39.38106541, -7.91574983],
                            [39.38090644, -7.91584767],
                            [39.38075969, -7.91590882],
                            [39.38058849, -7.91598221],
                            [39.38046622, -7.91611677],
                            [39.38034393, -7.91625131],
                            [39.38027055, -7.91638585],
                            [39.38027055, -7.91653262],
                            [39.38034392, -7.91670386],
                            [39.38047843, -7.9168384],
                            [39.38060072, -7.91697294],
                            [39.380723, -7.9171075],
                            [39.38085751, -7.91721757],
                            [39.3809798, -7.91732765],
                            [39.38112655, -7.91743773],
                            [39.38127329, -7.91758451],
                            [39.3814078, -7.91770683],
                            [39.38159123, -7.91789029],
                            [39.38168905, -7.91806154],
                        ],
                        [
                            [39.35594105, -7.92966707],
                            [39.35632098, -7.92971457],
                            [39.35663759, -7.9297304],
                            [39.35700168, -7.9297304],
                            [39.35734995, -7.92965123],
                            [39.35766655, -7.92950874],
                            [39.35841058, -7.92919206],
                            [39.35875883, -7.92900205],
                            [39.35918626, -7.92876456],
                            [39.35945537, -7.92852705],
                            [39.35977199, -7.92824204],
                            [39.36005693, -7.9279887],
                            [39.36027855, -7.92773536],
                            [39.36050018, -7.92746618],
                            [39.36076929, -7.92710201],
                            [39.3608801, -7.92667449],
                            [39.36092759, -7.92635782],
                            [39.36086428, -7.92599364],
                            [39.3607218, -7.92572447],
                            [39.36048119, -7.92554079],
                            [39.36039254, -7.92540145],
                            [39.36030388, -7.92530012],
                            [39.36013609, -7.92521778],
                            [39.35997779, -7.92520195],
                            [39.35985114, -7.92520195],
                            [39.3597245, -7.92523362],
                            [39.35951871, -7.92524945],
                            [39.35929708, -7.92529695],
                            [39.35912295, -7.92531278],
                            [39.35891716, -7.92539195],
                            [39.35875885, -7.92558195],
                            [39.35864804, -7.92574029],
                            [39.35845808, -7.92596197],
                            [39.3583631, -7.9262153],
                            [39.35820479, -7.9264053],
                            [39.35810981, -7.92664282],
                            [39.357999, -7.92681699],
                            [39.35793567, -7.92697533],
                            [39.35780904, -7.9271495],
                            [39.35760325, -7.92726033],
                            [39.35734996, -7.9273395],
                            [39.35719165, -7.92741867],
                            [39.35687506, -7.92760868],
                            [39.35644764, -7.92764034],
                            [39.35606771, -7.92770368],
                            [39.3557986, -7.92778285],
                            [39.35560864, -7.92798868],
                            [39.35540283, -7.92824202],
                            [39.35530785, -7.92852703],
                            [39.35527619, -7.9288437],
                            [39.35529202, -7.92914455],
                            [39.355387, -7.92933455],
                            [39.35560863, -7.9294929],
                            [39.35594105, -7.92966707],
                        ],
                        [
                            [39.38935338, -7.92799031],
                            [39.38922743, -7.92811628],
                            [39.38906998, -7.92827375],
                            [39.38875512, -7.92884065],
                            [39.38873937, -7.92902962],
                            [39.38872362, -7.92925009],
                            [39.38881808, -7.92939182],
                            [39.38905424, -7.92954928],
                            [39.38938485, -7.92980124],
                            [39.38954228, -7.92997446],
                            [39.38973121, -7.93022641],
                            [39.38992014, -7.93049412],
                            [39.39009332, -7.93079332],
                            [39.39020352, -7.93098229],
                            [39.39039245, -7.93109251],
                            [39.39067583, -7.93117125],
                            [39.39094347, -7.93123425],
                            [39.39110091, -7.93106103],
                            [39.39125835, -7.93088781],
                            [39.39151025, -7.93087206],
                            [39.39171491, -7.93079332],
                            [39.39184086, -7.9306201],
                            [39.39184086, -7.9303524],
                            [39.39179363, -7.9301162],
                            [39.39168343, -7.929817],
                            [39.39147877, -7.92953354],
                            [39.39133708, -7.9292501],
                            [39.39128984, -7.92898239],
                            [39.39114815, -7.92876193],
                            [39.39114815, -7.92843124],
                            [39.39124261, -7.92825802],
                            [39.3914158, -7.92816354],
                            [39.39162046, -7.92814778],
                            [39.39184087, -7.92814778],
                            [39.39251786, -7.92830527],
                            [39.39272252, -7.92833676],
                            [39.39281699, -7.92822654],
                            [39.39281699, -7.92805332],
                            [39.39270678, -7.92784859],
                            [39.39256508, -7.92770688],
                            [39.39237617, -7.92754939],
                            [39.39231319, -7.92740766],
                            [39.39229745, -7.92720295],
                            [39.39223447, -7.92702973],
                            [39.39210852, -7.92688802],
                            [39.39190386, -7.92682502],
                            [39.39171493, -7.92682502],
                            [39.39154175, -7.92687227],
                            [39.3914158, -7.92699824],
                            [39.3912741, -7.92715571],
                            [39.39108519, -7.92726595],
                            [39.39094349, -7.92747066],
                            [39.39078606, -7.92765963],
                            [39.39066011, -7.92786434],
                            [39.39050266, -7.92795882],
                            [39.39025076, -7.92795882],
                            [39.3900146, -7.92795882],
                            [39.38971547, -7.92795882],
                            [39.38954229, -7.92795882],
                            [39.38935338, -7.92799031],
                        ],
                        [
                            [39.3879994, -7.93187987],
                            [39.38796792, -7.93208458],
                            [39.38796791, -7.93232079],
                            [39.38790493, -7.93249401],
                            [39.38779473, -7.93269872],
                            [39.38771602, -7.93293494],
                            [39.38771601, -7.93307665],
                            [39.38784196, -7.93320263],
                            [39.38806238, -7.93329712],
                            [39.38833, -7.93347034],
                            [39.38856617, -7.93367505],
                            [39.38870786, -7.93384827],
                            [39.38880233, -7.93400575],
                            [39.38895976, -7.93413173],
                            [39.38911719, -7.93414748],
                            [39.38925889, -7.93400575],
                            [39.38941632, -7.93383253],
                            [39.38946355, -7.93358058],
                            [39.38957377, -7.93334437],
                            [39.38963674, -7.93310817],
                            [39.38963674, -7.9328562],
                            [39.38954227, -7.93258851],
                            [39.38938484, -7.93233654],
                            [39.38918018, -7.93195861],
                            [39.38906997, -7.93173814],
                            [39.38895977, -7.93161217],
                            [39.3888653, -7.93142321],
                            [39.38869212, -7.93124999],
                            [39.38855043, -7.93113975],
                            [39.38837725, -7.93112401],
                            [39.38818833, -7.93110826],
                            [39.3879994, -7.9311555],
                            [39.38795217, -7.93128148],
                            [39.38795217, -7.93147043],
                            [39.38796792, -7.93162791],
                            [39.3879994, -7.93187987],
                        ],
                        [
                            [39.32599435, -7.92948644],
                            [39.32608881, -7.92938014],
                            [39.32620689, -7.92934471],
                            [39.3263604, -7.92932108],
                            [39.32659655, -7.92928566],
                            [39.32677366, -7.92917937],
                            [39.32692717, -7.92896679],
                            [39.32703344, -7.9287542],
                            [39.32706886, -7.9285298],
                            [39.32706886, -7.92834083],
                            [39.3269626, -7.92826997],
                            [39.3268209, -7.92815185],
                            [39.3266792, -7.92801014],
                            [39.32656113, -7.92790384],
                            [39.32646667, -7.92778574],
                            [39.32646667, -7.92764401],
                            [39.32641944, -7.92741961],
                            [39.32640764, -7.92727789],
                            [39.32633678, -7.92708892],
                            [39.32625413, -7.92679366],
                            [39.32614787, -7.92655744],
                            [39.32600618, -7.926274],
                            [39.32591171, -7.92609684],
                            [39.32581725, -7.92596693],
                            [39.32568736, -7.92580158],
                            [39.32548664, -7.92557718],
                            [39.32534494, -7.92540002],
                            [39.32515603, -7.92519925],
                            [39.32495528, -7.92503391],
                            [39.32476637, -7.92488036],
                            [39.32460105, -7.9248095],
                            [39.32443574, -7.92473863],
                            [39.32427044, -7.9247032],
                            [39.32411694, -7.9247032],
                            [39.32398706, -7.92472683],
                            [39.32389259, -7.9248095],
                            [39.32376271, -7.92495123],
                            [39.32365643, -7.92508114],
                            [39.32358559, -7.9252701],
                            [39.32357378, -7.92544726],
                            [39.32353835, -7.92568347],
                            [39.32351474, -7.92597873],
                            [39.32351474, -7.92623856],
                            [39.32356196, -7.92652201],
                            [39.32365642, -7.92680546],
                            [39.32375089, -7.92704167],
                            [39.32382173, -7.92724244],
                            [39.323928, -7.92746684],
                            [39.32409331, -7.927644],
                            [39.32422319, -7.92782116],
                            [39.32425861, -7.9279865],
                            [39.32425861, -7.92812823],
                            [39.32417596, -7.928329],
                            [39.32402246, -7.9285534],
                            [39.32393981, -7.92876599],
                            [39.32389258, -7.92893133],
                            [39.32389258, -7.92906124],
                            [39.32389257, -7.9292266],
                            [39.32396341, -7.92936832],
                            [39.32397523, -7.9295691],
                            [39.32398704, -7.92984073],
                            [39.32401064, -7.93017144],
                            [39.32403427, -7.93046669],
                            [39.32404606, -7.93069109],
                            [39.32406968, -7.93097454],
                            [39.32411692, -7.93128161],
                            [39.32418776, -7.93157687],
                            [39.32428222, -7.93182488],
                            [39.3244121, -7.93197843],
                            [39.32458922, -7.93202567],
                            [39.32481357, -7.93202567],
                            [39.32503791, -7.93202567],
                            [39.32521503, -7.93197843],
                            [39.32542756, -7.93184852],
                            [39.32565192, -7.93170679],
                            [39.32581722, -7.93145878],
                            [39.32587627, -7.93116352],
                            [39.32588808, -7.93085645],
                            [39.32593532, -7.93050213],
                            [39.32593532, -7.93013602],
                            [39.32592351, -7.92988798],
                            [39.32593532, -7.92969903],
                            [39.32599435, -7.92948644],
                        ],
                        [
                            [39.35161223, -8.05980429],
                            [39.35141031, -8.05989406],
                            [39.35129815, -8.05995015],
                            [39.35107381, -8.05998381],
                            [39.35090554, -8.05998381],
                            [39.35075973, -8.05993892],
                            [39.35065878, -8.05979308],
                            [39.35053538, -8.05956868],
                            [39.35045688, -8.05943405],
                            [39.35035592, -8.05933307],
                            [39.35029985, -8.05907503],
                            [39.35032228, -8.05887307],
                            [39.35043445, -8.05868234],
                            [39.3506027, -8.05849161],
                            [39.35068121, -8.05827844],
                            [39.35067001, -8.05813259],
                            [39.35053539, -8.05804284],
                            [39.35036714, -8.05804283],
                            [39.35013158, -8.05806526],
                            [39.34985116, -8.0581438],
                            [39.3496156, -8.05817747],
                            [39.34932397, -8.05821111],
                            [39.34905476, -8.05821111],
                            [39.34885286, -8.05821111],
                            [39.34875189, -8.05827843],
                            [39.3486846, -8.05837939],
                            [39.34860607, -8.05852528],
                            [39.34852756, -8.05865989],
                            [39.34842661, -8.05878332],
                            [39.34828079, -8.05880575],
                            [39.34810131, -8.05874964],
                            [39.34800036, -8.05862623],
                            [39.34791063, -8.05845795],
                            [39.34779846, -8.05831209],
                            [39.34761899, -8.05819988],
                            [39.34740584, -8.05815501],
                            [39.34739015, -8.05816042],
                            [39.34684502, -8.05812137],
                            [39.34662067, -8.05802038],
                            [39.34640755, -8.05796427],
                            [39.34625051, -8.05794183],
                            [39.34610469, -8.05796427],
                            [39.34599252, -8.05806524],
                            [39.3458467, -8.05823352],
                            [39.34576818, -8.05839061],
                            [39.34576818, -8.05860378],
                            [39.34582424, -8.05879452],
                            [39.34579062, -8.05896281],
                            [39.34567843, -8.05919842],
                            [39.34553261, -8.05932183],
                            [39.34548775, -8.05949013],
                            [39.34552141, -8.05965843],
                            [39.34563357, -8.05984915],
                            [39.3457233, -8.06006231],
                            [39.3457233, -8.06020817],
                            [39.3457233, -8.06038769],
                            [39.34565601, -8.06055598],
                            [39.34543166, -8.06078037],
                            [39.34521854, -8.0610833],
                            [39.34501662, -8.06149842],
                            [39.34494933, -8.06173403],
                            [39.34493809, -8.06194719],
                            [39.34488202, -8.06214915],
                            [39.34473619, -8.06231745],
                            [39.34441091, -8.06235109],
                            [39.34406319, -8.06221646],
                            [39.34364814, -8.06203695],
                            [39.3433565, -8.06187988],
                            [39.34309851, -8.06180133],
                            [39.34282932, -8.06174524],
                            [39.34253767, -8.06174524],
                            [39.34222359, -8.06176766],
                            [39.3419656, -8.06183499],
                            [39.34164032, -8.06183499],
                            [39.34129257, -8.06181254],
                            [39.34112434, -8.06172279],
                            [39.34085513, -8.06155449],
                            [39.34065322, -8.06143108],
                            [39.34040645, -8.06133011],
                            [39.34008115, -8.06131888],
                            [39.33973343, -8.06131888],
                            [39.33944178, -8.06128522],
                            [39.33916136, -8.06125155],
                            [39.3389258, -8.06129644],
                            [39.33876876, -8.06148717],
                            [39.33849955, -8.06183497],
                            [39.33828643, -8.06207058],
                            [39.3380621, -8.06226132],
                            [39.3379387, -8.06246326],
                            [39.33792749, -8.06262033],
                            [39.33796113, -8.06281107],
                            [39.33808452, -8.06293449],
                            [39.33825277, -8.0630018],
                            [39.33854442, -8.06295693],
                            [39.33898189, -8.06278864],
                            [39.33937448, -8.06258669],
                            [39.33978951, -8.0625418],
                            [39.34014845, -8.06249693],
                            [39.34064199, -8.06253059],
                            [39.34114674, -8.06259791],
                            [39.34171883, -8.06274376],
                            [39.3424255, -8.06295695],
                            [39.34285175, -8.06308036],
                            [39.3430985, -8.06313645],
                            [39.34327798, -8.063215],
                            [39.34341258, -8.06329353],
                            [39.34347989, -8.06342816],
                            [39.34356962, -8.0635628],
                            [39.34359206, -8.06371989],
                            [39.34362571, -8.06387695],
                            [39.34372666, -8.06398914],
                            [39.3438837, -8.06397791],
                            [39.3440856, -8.06392184],
                            [39.34423142, -8.06383206],
                            [39.34436603, -8.06382085],
                            [39.3444782, -8.06386573],
                            [39.34456794, -8.06401159],
                            [39.34460158, -8.06416867],
                            [39.34471376, -8.06429208],
                            [39.34493808, -8.06440427],
                            [39.34530824, -8.06452769],
                            [39.34601493, -8.06471843],
                            [39.34660942, -8.06489795],
                            [39.34727122, -8.06506624],
                            [39.34774234, -8.06518965],
                            [39.34819103, -8.06526817],
                            [39.34849387, -8.06527941],
                            [39.34868457, -8.06529063],
                            [39.34892012, -8.06538039],
                            [39.34914446, -8.06548137],
                            [39.34939124, -8.06557112],
                            [39.3496268, -8.06562722],
                            [39.34995208, -8.06557112],
                            [39.35018765, -8.0653804],
                            [39.35042321, -8.06511113],
                            [39.35074849, -8.06487551],
                            [39.35109623, -8.06460624],
                            [39.35152248, -8.06424723],
                            [39.35211697, -8.06368625],
                            [39.35233011, -8.06351797],
                            [39.35256566, -8.06346188],
                            [39.35278999, -8.06334967],
                            [39.3529246, -8.06315894],
                            [39.35298069, -8.06293455],
                            [39.3530592, -8.0626765],
                            [39.35318259, -8.06249699],
                            [39.35347424, -8.0622165],
                            [39.35376588, -8.062037],
                            [39.35403508, -8.06184626],
                            [39.35414726, -8.06171163],
                            [39.35414726, -8.06158821],
                            [39.35403508, -8.06147602],
                            [39.35383318, -8.06133016],
                            [39.35363128, -8.06125162],
                            [39.35353033, -8.06102723],
                            [39.3535079, -8.06072431],
                            [39.35354154, -8.06047747],
                            [39.35363129, -8.06033163],
                            [39.35365372, -8.06018578],
                            [39.35354154, -8.06005114],
                            [39.35336208, -8.05991651],
                            [39.35316017, -8.05984918],
                            [39.35295827, -8.05972577],
                            [39.35277879, -8.05957991],
                            [39.35258811, -8.05940039],
                            [39.35240864, -8.0592882],
                            [39.35226281, -8.05918723],
                            [39.35216186, -8.05918723],
                            [39.35206092, -8.05920967],
                            [39.35197115, -8.05935552],
                            [39.35190387, -8.05955748],
                            [39.35181413, -8.0596921],
                            [39.35161223, -8.05980429],
                        ],
                        [
                            [39.34882139, -8.06656677],
                            [39.34848298, -8.06639755],
                            [39.34797539, -8.06616321],
                            [39.34759794, -8.06603303],
                            [39.34728558, -8.06598094],
                            [39.3467129, -8.06598093],
                            [39.34589291, -8.06594188],
                            [39.34535929, -8.0658898],
                            [39.34500787, -8.06583773],
                            [39.34492978, -8.06595489],
                            [39.34492978, -8.0662413],
                            [39.34487771, -8.06676204],
                            [39.34485168, -8.0673088],
                            [39.34479962, -8.06798575],
                            [39.34474755, -8.06868874],
                            [39.34459136, -8.06937871],
                            [39.34435708, -8.07009472],
                            [39.34417487, -8.07069356],
                            [39.34396661, -8.07114921],
                            [39.34368029, -8.07165691],
                            [39.34319871, -8.07224274],
                            [39.34267809, -8.07273744],
                            [39.34224859, -8.07302383],
                            [39.34180606, -8.07324515],
                            [39.34149368, -8.07341439],
                            [39.34110322, -8.07360965],
                            [39.34073877, -8.07377889],
                            [39.3402509, -8.07402099],
                            [39.34017912, -8.07396115],
                            [39.33946329, -8.07413039],
                            [39.33891664, -8.07429962],
                            [39.33833094, -8.07436471],
                            [39.33788841, -8.07431263],
                            [39.33730273, -8.07431263],
                            [39.33693829, -8.07426055],
                            [39.33656085, -8.07433866],
                            [39.33628752, -8.07448186],
                            [39.33602722, -8.07446884],
                            [39.33581896, -8.074573],
                            [39.3355977, -8.07472921],
                            [39.33533739, -8.07492448],
                            [39.33506407, -8.07506768],
                            [39.33471264, -8.07518484],
                            [39.33432219, -8.07527598],
                            [39.33404887, -8.07540615],
                            [39.33389269, -8.07556238],
                            [39.33385364, -8.07575765],
                            [39.33387967, -8.07595292],
                            [39.33403585, -8.07610915],
                            [39.33437425, -8.07631744],
                            [39.3347517, -8.07636952],
                            [39.33509008, -8.07642159],
                            [39.3352723, -8.07647366],
                            [39.33541548, -8.07657781],
                            [39.33546754, -8.07672102],
                            [39.3352723, -8.07683818],
                            [39.33497295, -8.07703345],
                            [39.33459551, -8.07729381],
                            [39.33440027, -8.07761928],
                            [39.334166, -8.07798378],
                            [39.33404886, -8.07833528],
                            [39.33407489, -8.07855659],
                            [39.33412695, -8.07881697],
                            [39.33423107, -8.0789862],
                            [39.33437423, -8.07914242],
                            [39.33449138, -8.07945485],
                            [39.33451741, -8.0797673],
                            [39.33451741, -8.08002766],
                            [39.33464756, -8.08017086],
                            [39.3347777, -8.08032709],
                            [39.33495992, -8.08056144],
                            [39.33507706, -8.08082178],
                            [39.33510309, -8.08109518],
                            [39.33524627, -8.08130346],
                            [39.33548054, -8.08145968],
                            [39.3357018, -8.0814727],
                            [39.3360402, -8.08151176],
                            [39.33632654, -8.08160288],
                            [39.33666494, -8.08178515],
                            [39.33696429, -8.08200646],
                            [39.33736777, -8.08226682],
                            [39.33773219, -8.0825272],
                            [39.33804457, -8.0828136],
                            [39.33847407, -8.08310002],
                            [39.33882549, -8.08325623],
                            [39.3391769, -8.08342547],
                            [39.33961941, -8.08355565],
                            [39.33999685, -8.08363376],
                            [39.34034829, -8.08359471],
                            [39.34090796, -8.0835166],
                            [39.3413765, -8.08337341],
                            [39.34183205, -8.08310003],
                            [39.34217042, -8.08289173],
                            [39.342639, -8.08256628],
                            [39.34330279, -8.08211063],
                            [39.34379738, -8.08173311],
                            [39.34438308, -8.08123841],
                            [39.34478655, -8.08076975],
                            [39.34509893, -8.08040525],
                            [39.34543732, -8.07997564],
                            [39.34572366, -8.07961113],
                            [39.34595793, -8.07938981],
                            [39.34627029, -8.07918152],
                            [39.34653063, -8.07897324],
                            [39.34673887, -8.07868683],
                            [39.34692108, -8.07833534],
                            [39.34696013, -8.07803591],
                            [39.34689506, -8.07777555],
                            [39.34696013, -8.07728085],
                            [39.34711632, -8.07665597],
                            [39.34729855, -8.07584883],
                            [39.34740267, -8.07527604],
                            [39.34745473, -8.0747553],
                            [39.34755886, -8.07436474],
                            [39.34770202, -8.0739742],
                            [39.34787122, -8.07358365],
                            [39.34813153, -8.07320613],
                            [39.34843089, -8.07281557],
                            [39.34882136, -8.072412],
                            [39.34914674, -8.07206051],
                            [39.34945912, -8.07156581],
                            [39.34975847, -8.07105809],
                            [39.34997973, -8.07069359],
                            [39.35026608, -8.07042021],
                            [39.35050035, -8.07017285],
                            [39.35077367, -8.06995155],
                            [39.35094288, -8.06970421],
                            [39.35109907, -8.06936572],
                            [39.35125525, -8.06905329],
                            [39.35139842, -8.06879291],
                            [39.3516327, -8.06863668],
                            [39.35187999, -8.06845445],
                            [39.35202316, -8.06827219],
                            [39.35212728, -8.06805087],
                            [39.35216633, -8.06781655],
                            [39.35227047, -8.06751712],
                            [39.3523876, -8.06725676],
                            [39.35264791, -8.06704847],
                            [39.35289519, -8.06676207],
                            [39.35325963, -8.06638454],
                            [39.35336376, -8.06604607],
                            [39.35341582, -8.06582475],
                            [39.3534809, -8.06527797],
                            [39.35353294, -8.06505667],
                            [39.3537282, -8.06483536],
                            [39.35396247, -8.06453595],
                            [39.35409261, -8.06431462],
                            [39.35410564, -8.0640803],
                            [39.35401454, -8.06396314],
                            [39.35380629, -8.06401521],
                            [39.3534809, -8.06415841],
                            [39.35306442, -8.06432764],
                            [39.35255681, -8.06450989],
                            [39.35211428, -8.0647963],
                            [39.35172383, -8.06518684],
                            [39.35141145, -8.06559043],
                            [39.35106004, -8.06590286],
                            [39.35053941, -8.06626736],
                            [39.35014896, -8.06650169],
                            [39.34977151, -8.06660583],
                            [39.34927692, -8.06660583],
                            [39.34882139, -8.06656677],
                        ],
                        [
                            [39.41512242, -8.06636046],
                            [39.41511425, -8.06667944],
                            [39.41511424, -8.06699024],
                            [39.41511424, -8.06726833],
                            [39.41509788, -8.06753822],
                            [39.41512637, -8.06752089],
                            [39.41509112, -8.06809853],
                            [39.41498774, -8.06858968],
                            [39.41483267, -8.06918421],
                            [39.4147293, -8.06954611],
                            [39.41452255, -8.06954611],
                            [39.41428997, -8.0695978],
                            [39.41400567, -8.06977874],
                            [39.41379408, -8.06994193],
                            [39.41350334, -8.07008733],
                            [39.41312537, -8.0702909],
                            [39.41280556, -8.07034905],
                            [39.41245666, -8.07039266],
                            [39.41229676, -8.070509],
                            [39.41210777, -8.07069801],
                            [39.41202054, -8.07091612],
                            [39.41200602, -8.07122147],
                            [39.41194787, -8.07151227],
                            [39.41199147, -8.07168676],
                            [39.41220953, -8.07184671],
                            [39.41247119, -8.07203573],
                            [39.4126311, -8.07213752],
                            [39.41274741, -8.072312],
                            [39.41276195, -8.07254464],
                            [39.41257296, -8.07276274],
                            [39.41226767, -8.07295178],
                            [39.41196239, -8.07298086],
                            [39.41159897, -8.07311171],
                            [39.41123554, -8.07327166],
                            [39.41094479, -8.07332981],
                            [39.4106686, -8.07340253],
                            [39.41036331, -8.07341706],
                            [39.4102034, -8.07328619],
                            [39.40995628, -8.07306809],
                            [39.40978182, -8.07292268],
                            [39.409651, -8.07295177],
                            [39.40950563, -8.07309717],
                            [39.40943294, -8.07331527],
                            [39.40941841, -8.07357701],
                            [39.40950562, -8.07385326],
                            [39.40949108, -8.07412953],
                            [39.40936025, -8.07431857],
                            [39.4091422, -8.07460937],
                            [39.40901136, -8.07482747],
                            [39.40893867, -8.07504556],
                            [39.40888052, -8.07522006],
                            [39.40873516, -8.07536546],
                            [39.4085607, -8.07540908],
                            [39.4084008, -8.07540908],
                            [39.40831357, -8.07530731],
                            [39.40815367, -8.07520551],
                            [39.4080083, -8.07520551],
                            [39.40789199, -8.07530731],
                            [39.40790652, -8.07549631],
                            [39.40792107, -8.07574351],
                            [39.40799375, -8.07588891],
                            [39.40828451, -8.07603432],
                            [39.40842533, -8.07614246],
                            [39.40862157, -8.07627333],
                            [39.40874422, -8.07636329],
                            [39.40880147, -8.07647779],
                            [39.40880147, -8.07661683],
                            [39.40873606, -8.0767477],
                            [39.4086461, -8.07696035],
                            [39.40865428, -8.07705849],
                            [39.4087197, -8.07722207],
                            [39.40885054, -8.07739384],
                            [39.40897319, -8.07750016],
                            [39.40903861, -8.07761466],
                            [39.40913673, -8.07772918],
                            [39.40915309, -8.0778764],
                            [39.40921849, -8.07798273],
                            [39.40925121, -8.07813813],
                            [39.40921032, -8.07843258],
                            [39.40921032, -8.07861252],
                            [39.40921032, -8.07875972],
                            [39.40927574, -8.07899692],
                            [39.40934933, -8.07928319],
                            [39.40950469, -8.07947946],
                            [39.40975001, -8.07979027],
                            [39.40987266, -8.07992931],
                            [39.41006073, -8.08010925],
                            [39.41022427, -8.0802483],
                            [39.41040417, -8.0804037],
                            [39.41061679, -8.08050185],
                            [39.41082939, -8.08055911],
                            [39.41104199, -8.08061635],
                            [39.41132001, -8.08067361],
                            [39.4115735, -8.08068998],
                            [39.41179427, -8.08068998],
                            [39.41207229, -8.08066543],
                            [39.41205595, -8.08035464],
                            [39.41205595, -8.08021559],
                            [39.41202323, -8.08007655],
                            [39.41199053, -8.07991296],
                            [39.41199053, -8.07975759],
                            [39.41203961, -8.07961036],
                            [39.4121459, -8.0794713],
                            [39.41221131, -8.07938135],
                            [39.41231761, -8.07938135],
                            [39.41252204, -8.07949584],
                            [39.41280825, -8.07961036],
                            [39.41302902, -8.07961852],
                            [39.41324162, -8.07944679],
                            [39.41340518, -8.07925048],
                            [39.41361777, -8.07893968],
                            [39.41375678, -8.07873522],
                            [39.41388762, -8.07845714],
                            [39.414043, -8.07817086],
                            [39.41423925, -8.07785188],
                            [39.41432918, -8.07750018],
                            [39.41447638, -8.07712395],
                            [39.41454999, -8.0768786],
                            [39.41454999, -8.07669048],
                            [39.41463993, -8.0764533],
                            [39.41478711, -8.0762161],
                            [39.41490978, -8.07594619],
                            [39.41495884, -8.07566811],
                            [39.41501609, -8.07539002],
                            [39.41507332, -8.07515284],
                            [39.41517144, -8.07489928],
                            [39.41524505, -8.07463756],
                            [39.41524505, -8.07440037],
                            [39.41529411, -8.07421224],
                            [39.41540859, -8.07405686],
                            [39.41558032, -8.07390147],
                            [39.41566208, -8.07370517],
                            [39.41576022, -8.07350068],
                            [39.41576838, -8.07328804],
                            [39.41576022, -8.07305902],
                            [39.41574385, -8.07270733],
                            [39.41593192, -8.07249468],
                            [39.41607912, -8.07224113],
                            [39.41612001, -8.07195486],
                            [39.41612001, -8.07163588],
                            [39.41606277, -8.07130874],
                            [39.41592376, -8.07111244],
                            [39.415842, -8.07095703],
                            [39.4159074, -8.07067895],
                            [39.41591559, -8.07041723],
                            [39.41589925, -8.07030272],
                            [39.41593193, -8.06997555],
                            [39.41595648, -8.06957479],
                            [39.41596465, -8.06920674],
                            [39.41596466, -8.06881415],
                            [39.41596466, -8.06848698],
                            [39.41598919, -8.06806986],
                            [39.41605461, -8.06779177],
                            [39.41605461, -8.06758731],
                            [39.41603008, -8.06721924],
                            [39.41594013, -8.06702296],
                            [39.41585018, -8.06686755],
                            [39.41579294, -8.06660583],
                            [39.41579294, -8.06642589],
                            [39.4158093, -8.06620506],
                            [39.4158093, -8.06588609],
                            [39.4158093, -8.06568979],
                            [39.41578477, -8.06554256],
                            [39.41572754, -8.06541988],
                            [39.41565393, -8.06523994],
                            [39.415564, -8.06518269],
                            [39.41545769, -8.06515816],
                            [39.41531868, -8.06515816],
                            [39.4152042, -8.06520723],
                            [39.41513878, -8.06531356],
                            [39.41513878, -8.0654117],
                            [39.41513878, -8.06555074],
                            [39.41513878, -8.06576339],
                            [39.41513061, -8.06594333],
                            [39.41512242, -8.06613144],
                            [39.41512242, -8.06636046],
                        ],
                        [
                            [39.40174898, -8.09121245],
                            [39.40181115, -8.09088595],
                            [39.40190443, -8.09048171],
                            [39.40215314, -8.09021739],
                            [39.40243294, -8.08982868],
                            [39.402635, -8.08953329],
                            [39.40244849, -8.08933115],
                            [39.40209096, -8.08916014],
                            [39.4016868, -8.08906684],
                            [39.40111166, -8.08906683],
                            [39.40080078, -8.08884917],
                            [39.40047436, -8.0885693],
                            [39.40010128, -8.08825833],
                            [39.39974376, -8.087823],
                            [39.39944842, -8.0874343],
                            [39.39921525, -8.08732545],
                            [39.39896655, -8.08730992],
                            [39.39885774, -8.08768307],
                            [39.39904426, -8.08824278],
                            [39.39938624, -8.08867813],
                            [39.39977483, -8.08900464],
                            [39.40019455, -8.08939336],
                            [39.40052097, -8.08992197],
                            [39.40070751, -8.09054389],
                            [39.40075413, -8.0911969],
                            [39.40076967, -8.0915856],
                            [39.40058313, -8.09205204],
                            [39.40030334, -8.0926584],
                            [39.3999769, -8.09338916],
                            [39.39979037, -8.09391779],
                            [39.39974373, -8.09436867],
                            [39.40003908, -8.09438422],
                            [39.40062976, -8.09405773],
                            [39.40080076, -8.09390225],
                            [39.401065, -8.09317149],
                            [39.40146917, -8.09216089],
                            [39.40174898, -8.09121245],
                        ],
                        [
                            [39.34634978, -7.8895953],
                            [39.3462503, -7.88951571],
                            [39.34611102, -7.88951571],
                            [39.34597174, -7.88951571],
                            [39.34585236, -7.88951571],
                            [39.34571308, -7.88951571],
                            [39.34549422, -7.88951571],
                            [39.34531514, -7.88965501],
                            [39.34523557, -7.88983412],
                            [39.34513607, -7.88999333],
                            [39.34509629, -7.89023214],
                            [39.34517587, -7.89043116],
                            [39.34535494, -7.89066997],
                            [39.34559369, -7.89094858],
                            [39.34577276, -7.89110779],
                            [39.34595183, -7.8912869],
                            [39.34619059, -7.89152571],
                            [39.34627018, -7.89182423],
                            [39.34638956, -7.89206304],
                            [39.34656862, -7.89220235],
                            [39.34678748, -7.89222225],
                            [39.34700635, -7.89216255],
                            [39.34722521, -7.89200335],
                            [39.3473247, -7.89180433],
                            [39.3473247, -7.89152571],
                            [39.34734459, -7.89120729],
                            [39.3473247, -7.89086898],
                            [39.34726502, -7.89057047],
                            [39.34716552, -7.89039136],
                            [39.34698647, -7.89021225],
                            [39.34678749, -7.89009284],
                            [39.34654874, -7.88987392],
                            [39.34642935, -7.88973461],
                            [39.34634978, -7.8895953],
                        ],
                        [
                            [39.33627769, -7.88909276],
                            [39.33635905, -7.88903173],
                            [39.33631838, -7.88887914],
                            [39.33614548, -7.88877742],
                            [39.3360743, -7.8886757],
                            [39.33606412, -7.88859431],
                            [39.33606412, -7.88855363],
                            [39.33606412, -7.88849259],
                            [39.33606413, -7.88842139],
                            [39.33596241, -7.88839086],
                            [39.33589123, -7.88841121],
                            [39.33576918, -7.88836034],
                            [39.33571833, -7.88825862],
                            [39.33566749, -7.88818742],
                            [39.33557596, -7.88812638],
                            [39.33556579, -7.88800431],
                            [39.33551493, -7.88790258],
                            [39.33551493, -7.88783138],
                            [39.33551493, -7.88772965],
                            [39.33545392, -7.88760758],
                            [39.33545392, -7.88747533],
                            [39.33544375, -7.88733293],
                            [39.33540306, -7.88724137],
                            [39.33533188, -7.88715999],
                            [39.33528102, -7.88701757],
                            [39.33526068, -7.88692603],
                            [39.33526068, -7.88681413],
                            [39.33523017, -7.88671241],
                            [39.33519967, -7.88666154],
                            [39.33513864, -7.88666154],
                            [39.33503694, -7.88671241],
                            [39.33494541, -7.88672258],
                            [39.33489455, -7.88667171],
                            [39.33488438, -7.88659034],
                            [39.33484371, -7.88652929],
                            [39.33477251, -7.88649877],
                            [39.3347115, -7.88649877],
                            [39.33463013, -7.88650895],
                            [39.33455894, -7.88651912],
                            [39.33448774, -7.88654964],
                            [39.33444707, -7.88663101],
                            [39.33444707, -7.88672258],
                            [39.33445724, -7.88681413],
                            [39.33456911, -7.88686498],
                            [39.33460978, -7.8869362],
                            [39.33457928, -7.88707861],
                            [39.33463012, -7.88722102],
                            [39.33466064, -7.88732275],
                            [39.3347115, -7.88740413],
                            [39.33477251, -7.88753637],
                            [39.33481319, -7.88763809],
                            [39.33480302, -7.88771947],
                            [39.33470132, -7.88776016],
                            [39.33455894, -7.88776016],
                            [39.3344369, -7.88774999],
                            [39.33437587, -7.88779068],
                            [39.33431485, -7.88784154],
                            [39.33421315, -7.88785171],
                            [39.33407077, -7.88785171],
                            [39.33387753, -7.88785171],
                            [39.33373516, -7.88785171],
                            [39.33366396, -7.88786188],
                            [39.33356226, -7.88794327],
                            [39.33349107, -7.88804499],
                            [39.33339953, -7.88814671],
                            [39.33323682, -7.88815689],
                            [39.33315544, -7.88822809],
                            [39.33309443, -7.88840103],
                            [39.33305374, -7.88851292],
                            [39.3330334, -7.88863499],
                            [39.3330334, -7.88876723],
                            [39.33306391, -7.88895033],
                            [39.33319613, -7.88910292],
                            [39.33328765, -7.88922499],
                            [39.33346055, -7.88926569],
                            [39.33356225, -7.88916397],
                            [39.33355208, -7.88909275],
                            [39.33367412, -7.88903172],
                            [39.33374532, -7.88909275],
                            [39.33385719, -7.88914362],
                            [39.33400973, -7.88912327],
                            [39.33415212, -7.88912327],
                            [39.33438603, -7.88912327],
                            [39.33455893, -7.88914362],
                            [39.3346708, -7.8891538],
                            [39.3348437, -7.88916397],
                            [39.33502676, -7.88923517],
                            [39.33515897, -7.88930639],
                            [39.33530135, -7.88942846],
                            [39.33545391, -7.88949966],
                            [39.33562679, -7.88954036],
                            [39.33574884, -7.88954036],
                            [39.33584036, -7.88959121],
                            [39.33594208, -7.88962173],
                            [39.33608445, -7.88958104],
                            [39.33615565, -7.88954036],
                            [39.33615565, -7.88943862],
                            [39.33613531, -7.88936742],
                            [39.33612513, -7.88928605],
                            [39.33612514, -7.88919448],
                            [39.33619634, -7.88913346],
                            [39.33627769, -7.88909276],
                        ],
                        [
                            [39.28519005, -7.88782458],
                            [39.28492809, -7.88761497],
                            [39.28511146, -7.88732675],
                            [39.28534722, -7.88703852],
                            [39.28534722, -7.88672411],
                            [39.28526865, -7.88661929],
                            [39.2849281, -7.8866979],
                            [39.28445657, -7.88669789],
                            [39.28414221, -7.88640967],
                            [39.28411602, -7.88604285],
                            [39.28456135, -7.88599045],
                            [39.28479712, -7.88567602],
                            [39.28477092, -7.88533539],
                            [39.28466615, -7.88483757],
                            [39.28453517, -7.88452313],
                            [39.28435179, -7.88415631],
                            [39.28388026, -7.88384188],
                            [39.28346112, -7.88381568],
                            [39.28314677, -7.88376328],
                            [39.28291102, -7.88355366],
                            [39.28293721, -7.88318683],
                            [39.28325157, -7.88302961],
                            [39.28346112, -7.88284621],
                            [39.28346113, -7.88247939],
                            [39.28317298, -7.88206015],
                            [39.28285862, -7.88190293],
                            [39.28257048, -7.88179813],
                            [39.2823347, -7.88179813],
                            [39.28207274, -7.88195533],
                            [39.28186318, -7.88198154],
                            [39.28160122, -7.88216495],
                            [39.28139164, -7.88247938],
                            [39.28117246, -7.88309174],
                            [39.28105109, -7.88407769],
                            [39.28105109, -7.88465413],
                            [39.28133924, -7.88530919],
                            [39.2816012, -7.88578082],
                            [39.28186316, -7.88609524],
                            [39.28207273, -7.88638347],
                            [39.28228229, -7.88672409],
                            [39.28259664, -7.88709091],
                            [39.28312056, -7.88735293],
                            [39.28348731, -7.88753636],
                            [39.2834873, -7.88779838],
                            [39.28338253, -7.88816519],
                            [39.28340871, -7.88850582],
                            [39.28356589, -7.88871544],
                            [39.28385405, -7.88887266],
                            [39.28408981, -7.88892506],
                            [39.28450895, -7.88892506],
                            [39.28492809, -7.88892506],
                            [39.28505907, -7.88887266],
                            [39.28555678, -7.88887266],
                            [39.28592353, -7.88874166],
                            [39.28581874, -7.88821762],
                            [39.28542581, -7.88787699],
                            [39.28519005, -7.88782458],
                        ],
                        [
                            [39.26948858, -7.88751975],
                            [39.26952191, -7.88733636],
                            [39.26953025, -7.88718631],
                            [39.26963026, -7.88702792],
                            [39.26970526, -7.88694457],
                            [39.26975528, -7.88682786],
                            [39.26986362, -7.88671949],
                            [39.2699803, -7.88659446],
                            [39.27023032, -7.88642774],
                            [39.27048034, -7.886211],
                            [39.27072204, -7.88601927],
                            [39.27094706, -7.88580253],
                            [39.27117208, -7.88559413],
                            [39.27136376, -7.88541907],
                            [39.27155544, -7.88525235],
                            [39.2716888, -7.88506063],
                            [39.27180548, -7.88486891],
                            [39.27188882, -7.88471885],
                            [39.27196382, -7.88451878],
                            [39.27202216, -7.88436041],
                            [39.27207218, -7.88415201],
                            [39.27213052, -7.88397695],
                            [39.27220552, -7.88371852],
                            [39.27227219, -7.88348512],
                            [39.27233888, -7.88328505],
                            [39.2723722, -7.88315168],
                            [39.27240555, -7.88300997],
                            [39.27246389, -7.88286826],
                            [39.27250556, -7.88265986],
                            [39.27257223, -7.88246812],
                            [39.2726389, -7.88226807],
                            [39.27271391, -7.88205966],
                            [39.27272224, -7.88192629],
                            [39.27277226, -7.88172622],
                            [39.27278058, -7.88162618],
                            [39.27282225, -7.88150115],
                            [39.27289727, -7.88136778],
                            [39.27293061, -7.88119271],
                            [39.27301395, -7.88100099],
                            [39.27309729, -7.88078425],
                            [39.27314729, -7.8806342],
                            [39.27323063, -7.88050917],
                            [39.27329732, -7.88035911],
                            [39.27338066, -7.88021741],
                            [39.27341399, -7.8800757],
                            [39.27345566, -7.87991731],
                            [39.27350567, -7.87975059],
                            [39.27357234, -7.8795672],
                            [39.27361401, -7.87936713],
                            [39.27365568, -7.87918373],
                            [39.27368903, -7.87898367],
                            [39.27370568, -7.87871691],
                            [39.27373903, -7.87852519],
                            [39.2737557, -7.87838348],
                            [39.27374736, -7.87819175],
                            [39.27373903, -7.87808337],
                            [39.27370569, -7.87798336],
                            [39.27368069, -7.87790833],
                            [39.27366402, -7.87784164],
                            [39.27359735, -7.8778333],
                            [39.27353068, -7.87788332],
                            [39.273414, -7.87797501],
                            [39.27330566, -7.87807504],
                            [39.27310563, -7.87828344],
                            [39.27290561, -7.8784585],
                            [39.27273893, -7.87862522],
                            [39.27256392, -7.87880861],
                            [39.27243056, -7.87893365],
                            [39.27231389, -7.87905869],
                            [39.27219721, -7.8791754],
                            [39.27208053, -7.87933377],
                            [39.27191385, -7.87950883],
                            [39.27178883, -7.87965055],
                            [39.27168049, -7.87980894],
                            [39.27161381, -7.87993397],
                            [39.27150547, -7.88009235],
                            [39.2714138, -7.88023406],
                            [39.27132212, -7.88033409],
                            [39.27120559, -7.88052094],
                            [39.27117412, -7.8805351],
                            [39.27092996, -7.88095029],
                            [39.27073462, -7.8813166],
                            [39.27046603, -7.88181726],
                            [39.27031954, -7.88228128],
                            [39.27020967, -7.88273308],
                            [39.270112, -7.88309942],
                            [39.26992886, -7.88339247],
                            [39.26966029, -7.88364891],
                            [39.26942833, -7.88385649],
                            [39.26920857, -7.88403965],
                            [39.26901325, -7.8843205],
                            [39.26874467, -7.88468683],
                            [39.26856153, -7.88504094],
                            [39.26834179, -7.8854317],
                            [39.26817087, -7.88581024],
                            [39.26808844, -7.88615263],
                            [39.26802177, -7.88646106],
                            [39.2679801, -7.88672782],
                            [39.26789675, -7.88696123],
                            [39.26781341, -7.88716129],
                            [39.2677384, -7.88737803],
                            [39.26767173, -7.88762811],
                            [39.26762171, -7.8878365],
                            [39.26758004, -7.88800324],
                            [39.26757172, -7.88816161],
                            [39.26758839, -7.88830332],
                            [39.26768005, -7.88832833],
                            [39.26778007, -7.88832833],
                            [39.26788841, -7.88832833],
                            [39.26796342, -7.88826165],
                            [39.26803843, -7.88819496],
                            [39.2681301, -7.88811993],
                            [39.26825511, -7.88806158],
                            [39.26840514, -7.8879949],
                            [39.26848848, -7.88789486],
                            [39.2687385, -7.8878115],
                            [39.26885518, -7.88779483],
                            [39.26900519, -7.8877615],
                            [39.26917187, -7.8877615],
                            [39.26932188, -7.8877615],
                            [39.26943856, -7.8877615],
                            [39.26947191, -7.88768647],
                            [39.26948858, -7.88751975],
                        ],
                        [
                            [39.22740414, -7.88130931],
                            [39.22763381, -7.88128281],
                            [39.22804017, -7.88135349],
                            [39.22827868, -7.88142418],
                            [39.22858785, -7.88156555],
                            [39.22869386, -7.88164508],
                            [39.22890587, -7.88167159],
                            [39.22909138, -7.88155672],
                            [39.22933871, -7.88138885],
                            [39.22956839, -7.88129166],
                            [39.22979807, -7.88119445],
                            [39.23001891, -7.88101775],
                            [39.23019559, -7.88077035],
                            [39.23034576, -7.8805848],
                            [39.23029276, -7.88040809],
                            [39.23024859, -7.8802137],
                            [39.23023976, -7.87992213],
                            [39.23023976, -7.8795422],
                            [39.23025744, -7.87909157],
                            [39.23028394, -7.87870281],
                            [39.23034577, -7.87841122],
                            [39.23045178, -7.87781041],
                            [39.23056662, -7.8771919],
                            [39.23063729, -7.87665293],
                            [39.23076979, -7.87633484],
                            [39.2308493, -7.87602559],
                            [39.23096414, -7.87548662],
                            [39.23100832, -7.87500065],
                            [39.23102597, -7.87453236],
                            [39.23106132, -7.87401106],
                            [39.23107015, -7.87356045],
                            [39.23103482, -7.87320701],
                            [39.23094648, -7.87298612],
                            [39.23078747, -7.87281825],
                            [39.23065498, -7.87266803],
                            [39.23054897, -7.87254434],
                            [39.23044296, -7.87242947],
                            [39.23036346, -7.87237646],
                            [39.23025747, -7.87238529],
                            [39.23010728, -7.87241179],
                            [39.22993062, -7.87258851],
                            [39.22984228, -7.87272988],
                            [39.22971859, -7.87284475],
                            [39.2295596, -7.87297729],
                            [39.22944476, -7.87302147],
                            [39.22932108, -7.8730568],
                            [39.22912674, -7.87310981],
                            [39.22899423, -7.87310098],
                            [39.22878222, -7.87300379],
                            [39.22853489, -7.87286242],
                            [39.22824337, -7.87267687],
                            [39.22810204, -7.8725355],
                            [39.22796954, -7.87237645],
                            [39.2278812, -7.87219973],
                            [39.22776636, -7.87193466],
                            [39.22756319, -7.87159007],
                            [39.22746602, -7.87116596],
                            [39.22740418, -7.87089205],
                            [39.22737768, -7.87076835],
                            [39.22736001, -7.87061815],
                            [39.22728051, -7.87046794],
                            [39.22711268, -7.87030889],
                            [39.22691834, -7.87022937],
                            [39.22664449, -7.87007917],
                            [39.22644132, -7.86984944],
                            [39.22637065, -7.86962854],
                            [39.22636182, -7.86931047],
                            [39.22632648, -7.8689482],
                            [39.22639715, -7.8686036],
                            [39.22642365, -7.86832971],
                            [39.22636183, -7.86821484],
                            [39.22626466, -7.86816183],
                            [39.22613215, -7.86817066],
                            [39.22600848, -7.86820599],
                            [39.22596431, -7.86821484],
                            [39.2258583, -7.86823251],
                            [39.22574346, -7.86822367],
                            [39.22564629, -7.8681088],
                            [39.22562862, -7.86792325],
                            [39.22570813, -7.86772887],
                            [39.2257788, -7.86762284],
                            [39.2258583, -7.8674373],
                            [39.22582298, -7.86732244],
                            [39.22578763, -7.86713689],
                            [39.2256728, -7.86697784],
                            [39.2256198, -7.86683647],
                            [39.22557563, -7.86662442],
                            [39.22555796, -7.86640352],
                            [39.2256728, -7.86623564],
                            [39.22578764, -7.86624447],
                            [39.22590248, -7.8662975],
                            [39.22599082, -7.86641236],
                            [39.22608798, -7.86657139],
                            [39.22621165, -7.86658024],
                            [39.22630882, -7.86646538],
                            [39.22627349, -7.866271],
                            [39.22628232, -7.86605894],
                            [39.22645018, -7.86594408],
                            [39.22657384, -7.86575853],
                            [39.226565, -7.8655288],
                            [39.22637951, -7.86537858],
                            [39.22614099, -7.86517537],
                            [39.22598198, -7.8650605],
                            [39.22589365, -7.86495447],
                            [39.22580532, -7.86478659],
                            [39.22568165, -7.86453919],
                            [39.2254608, -7.86438899],
                            [39.22520462, -7.86428295],
                            [39.22493962, -7.86408857],
                            [39.22473644, -7.8639472],
                            [39.22448911, -7.86377932],
                            [39.22418876, -7.86363795],
                            [39.22409159, -7.86347008],
                            [39.22394141, -7.86325802],
                            [39.22390609, -7.86303712],
                            [39.22387957, -7.86280739],
                            [39.22386192, -7.86264836],
                            [39.22376475, -7.86252465],
                            [39.22365874, -7.86240095],
                            [39.22349973, -7.86228609],
                            [39.22334956, -7.86225958],
                            [39.22322588, -7.86224191],
                            [39.22319056, -7.86213589],
                            [39.22315521, -7.86195034],
                            [39.22313756, -7.86176479],
                            [39.22302272, -7.86159691],
                            [39.22286371, -7.86137601],
                            [39.2227047, -7.86117278],
                            [39.22259871, -7.86107559],
                            [39.22253687, -7.86087238],
                            [39.22258987, -7.86066916],
                            [39.22260754, -7.86049244],
                            [39.22256337, -7.86037757],
                            [39.22243086, -7.86028923],
                            [39.2222012, -7.8602362],
                            [39.22203334, -7.86014784],
                            [39.22188318, -7.86009483],
                            [39.22174184, -7.85991812],
                            [39.22163583, -7.85975908],
                            [39.22156516, -7.85961771],
                            [39.2214415, -7.85941448],
                            [39.22132666, -7.85925544],
                            [39.22122066, -7.85917592],
                            [39.22109698, -7.85918475],
                            [39.22100865, -7.85922893],
                            [39.22092031, -7.85929079],
                            [39.22092031, -7.8593438],
                            [39.2208408, -7.85947634],
                            [39.22074363, -7.85946749],
                            [39.22069946, -7.85938798],
                            [39.22053163, -7.85930846],
                            [39.22037262, -7.85912291],
                            [39.22021362, -7.85895502],
                            [39.22008994, -7.85877831],
                            [39.21988678, -7.85859276],
                            [39.21970127, -7.85844255],
                            [39.21944509, -7.85840721],
                            [39.21920657, -7.85840721],
                            [39.21900341, -7.85836303],
                            [39.21880906, -7.85828351],
                            [39.21851755, -7.85820398],
                            [39.21821721, -7.85799192],
                            [39.21804053, -7.85786823],
                            [39.21789036, -7.85771801],
                            [39.21773135, -7.85755014],
                            [39.21759886, -7.85738226],
                            [39.21743985, -7.85721439],
                            [39.21729851, -7.85713487],
                            [39.21721017, -7.85701116],
                            [39.21706884, -7.85685211],
                            [39.216954, -7.85678143],
                            [39.21678616, -7.8567726],
                            [39.21671549, -7.8567726],
                            [39.21668015, -7.85686979],
                            [39.21668015, -7.85702],
                            [39.21661832, -7.85707301],
                            [39.21651231, -7.85707301],
                            [39.21639747, -7.85696698],
                            [39.21624731, -7.85689629],
                            [39.2160883, -7.85692279],
                            [39.21596463, -7.85697582],
                            [39.21578795, -7.8570465],
                            [39.21562894, -7.8570465],
                            [39.21547877, -7.85705533],
                            [39.21553177, -7.8571702],
                            [39.21565544, -7.85737342],
                            [39.21597345, -7.85783287],
                            [39.21620313, -7.85815979],
                            [39.21633562, -7.85832768],
                            [39.21642396, -7.85847788],
                            [39.21645046, -7.85858392],
                            [39.21646813, -7.85874295],
                            [39.21655647, -7.85891084],
                            [39.21662714, -7.85899035],
                            [39.21676848, -7.85902569],
                            [39.21687449, -7.85914055],
                            [39.21701583, -7.85926426],
                            [39.21724549, -7.85929077],
                            [39.21761651, -7.8592996],
                            [39.21785503, -7.85929077],
                            [39.21798752, -7.85933495],
                            [39.21811119, -7.85949398],
                            [39.21825254, -7.85960885],
                            [39.21845571, -7.85962653],
                            [39.21855287, -7.85962653],
                            [39.21866771, -7.85969721],
                            [39.2187914, -7.85984743],
                            [39.21887972, -7.86000646],
                            [39.2190299, -7.86019201],
                            [39.21919774, -7.86031572],
                            [39.21940974, -7.86058961],
                            [39.21955992, -7.86079284],
                            [39.21963942, -7.86092538],
                            [39.21971892, -7.86111976],
                            [39.21970126, -7.86127881],
                            [39.21971009, -7.86145551],
                            [39.2198691, -7.86161456],
                            [39.2201341, -7.86178243],
                            [39.22033728, -7.86181778],
                            [39.22040795, -7.86167641],
                            [39.22041678, -7.86147319],
                            [39.22050512, -7.86136717],
                            [39.22061113, -7.86120813],
                            [39.22075247, -7.86116395],
                            [39.22092913, -7.86126998],
                            [39.22121181, -7.86141135],
                            [39.22152983, -7.8615969],
                            [39.22180367, -7.86175594],
                            [39.22198918, -7.86181779],
                            [39.22212168, -7.86185313],
                            [39.22222769, -7.86198567],
                            [39.22227186, -7.86214471],
                            [39.22222769, -7.86231259],
                            [39.22213052, -7.86243628],
                            [39.22202451, -7.86253349],
                            [39.22185667, -7.86254232],
                            [39.22164466, -7.86252464],
                            [39.22132664, -7.86251581],
                            [39.22109696, -7.86248046],
                            [39.22099097, -7.86249813],
                            [39.22087613, -7.86257765],
                            [39.22082313, -7.86275437],
                            [39.22078779, -7.86296642],
                            [39.22076129, -7.86322266],
                            [39.22079661, -7.86341704],
                            [39.22095562, -7.86368212],
                            [39.22111463, -7.86392951],
                            [39.22125597, -7.86421225],
                            [39.22137963, -7.86442432],
                            [39.2214238, -7.86459219],
                            [39.22141497, -7.86475124],
                            [39.22140615, -7.86500748],
                            [39.22132663, -7.86526369],
                            [39.22122063, -7.86549342],
                            [39.22114112, -7.865732],
                            [39.22107045, -7.86589987],
                            [39.22102628, -7.86605007],
                            [39.22106162, -7.86623562],
                            [39.22118529, -7.86643885],
                            [39.22137962, -7.86652721],
                            [39.22161815, -7.8666244],
                            [39.22181248, -7.86663323],
                            [39.22195382, -7.86665974],
                            [39.22205099, -7.86670392],
                            [39.22219232, -7.86681878],
                            [39.22229834, -7.86696015],
                            [39.22243967, -7.86707502],
                            [39.22259868, -7.86714571],
                            [39.22271352, -7.86718989],
                            [39.22280185, -7.86727825],
                            [39.22282517, -7.86734177],
                            [39.22289018, -7.86774654],
                            [39.22287251, -7.86800277],
                            [39.22285486, -7.86820598],
                            [39.22286368, -7.86842688],
                            [39.22293435, -7.86860359],
                            [39.22296085, -7.86881566],
                            [39.22295202, -7.86895703],
                            [39.22304036, -7.86913373],
                            [39.22313753, -7.86932811],
                            [39.22311103, -7.86954018],
                            [39.22301384, -7.86978757],
                            [39.22307568, -7.86998195],
                            [39.22327002, -7.87012334],
                            [39.22345553, -7.87029122],
                            [39.22356154, -7.8704856],
                            [39.22362338, -7.87068882],
                            [39.22357921, -7.87089203],
                            [39.22349086, -7.87104225],
                            [39.2233937, -7.87100689],
                            [39.22326119, -7.87079484],
                            [39.22321702, -7.87064464],
                            [39.22309335, -7.87058278],
                            [39.22299618, -7.87060929],
                            [39.22294317, -7.87070648],
                            [39.22296084, -7.87098039],
                            [39.22296084, -7.87122779],
                            [39.22297851, -7.87137799],
                            [39.2228725, -7.87145752],
                            [39.22270466, -7.87152821],
                            [39.22251032, -7.87161655],
                            [39.22238666, -7.87168724],
                            [39.22233364, -7.87184628],
                            [39.22234248, -7.87198765],
                            [39.22235131, -7.87221738],
                            [39.22231598, -7.8723941],
                            [39.2221923, -7.87241176],
                            [39.22198914, -7.87245594],
                            [39.22182128, -7.87250012],
                            [39.22175062, -7.87257965],
                            [39.22169762, -7.87277403],
                            [39.22166229, -7.87304794],
                            [39.22160927, -7.87325999],
                            [39.22163577, -7.87344554],
                            [39.22176828, -7.87357807],
                            [39.22198029, -7.87379012],
                            [39.2220863, -7.87394917],
                            [39.22213047, -7.87411705],
                            [39.22227181, -7.87433795],
                            [39.2223778, -7.87449699],
                            [39.22241314, -7.87465604],
                            [39.22233363, -7.87487692],
                            [39.22218346, -7.87496528],
                            [39.22207745, -7.87501829],
                            [39.22198913, -7.87508015],
                            [39.22192728, -7.87521267],
                            [39.22190961, -7.87540707],
                            [39.22203328, -7.87555727],
                            [39.22225413, -7.87555727],
                            [39.22250146, -7.8755661],
                            [39.22266932, -7.87561912],
                            [39.22283715, -7.87587536],
                            [39.22304033, -7.87600788],
                            [39.2234025, -7.87603441],
                            [39.22371168, -7.87606091],
                            [39.22387069, -7.87614043],
                            [39.2239502, -7.87626412],
                            [39.22402087, -7.87649385],
                            [39.22396785, -7.8766794],
                            [39.22382651, -7.87679427],
                            [39.22380884, -7.87694447],
                            [39.22386186, -7.87711236],
                            [39.22391485, -7.87725373],
                            [39.22410919, -7.87733324],
                            [39.22434771, -7.87741278],
                            [39.22457737, -7.87750997],
                            [39.22472755, -7.87766901],
                            [39.22492189, -7.87793408],
                            [39.2251074, -7.87824332],
                            [39.22523106, -7.87838469],
                            [39.22539007, -7.87844655],
                            [39.22559325, -7.87846423],
                            [39.22581409, -7.87848189],
                            [39.22596427, -7.87849073],
                            [39.22608793, -7.87864976],
                            [39.22622044, -7.87886183],
                            [39.22633528, -7.87909155],
                            [39.22639711, -7.8792771],
                            [39.22654729, -7.87928595],
                            [39.22671512, -7.87928595],
                            [39.22676812, -7.87941847],
                            [39.22673279, -7.87956869],
                            [39.22666212, -7.87978074],
                            [39.22662678, -7.8799928],
                            [39.22671512, -7.88003698],
                            [39.22682996, -7.87993979],
                            [39.2269713, -7.87990444],
                            [39.22709497, -7.8799928],
                            [39.22711264, -7.88019602],
                            [39.22708614, -7.88032855],
                            [39.2269978, -7.88048759],
                            [39.22686528, -7.88049644],
                            [39.22675045, -7.8805141],
                            [39.22667978, -7.88060246],
                            [39.22669745, -7.88077033],
                            [39.22675044, -7.88092055],
                            [39.22666212, -7.88104424],
                            [39.22650311, -7.88116794],
                            [39.22623809, -7.88123862],
                            [39.22598191, -7.8813093],
                            [39.22569923, -7.88131815],
                            [39.2255579, -7.88134465],
                            [39.2254254, -7.88144184],
                            [39.22535473, -7.88156554],
                            [39.22526639, -7.88172458],
                            [39.22523989, -7.88193664],
                            [39.22523105, -7.88211336],
                            [39.22536356, -7.88213986],
                            [39.22555789, -7.88199849],
                            [39.2257699, -7.88179527],
                            [39.22600842, -7.88167157],
                            [39.22629993, -7.88166273],
                            [39.2265826, -7.88168925],
                            [39.22691829, -7.88169808],
                            [39.2271303, -7.88154788],
                            [39.22740414, -7.88130931],
                        ],
                        [
                            [39.21498154, -7.87699847],
                            [39.21491198, -7.87726683],
                            [39.21491198, -7.87744576],
                            [39.2148126, -7.87759485],
                            [39.21469334, -7.87769426],
                            [39.21463372, -7.87791292],
                            [39.21461384, -7.87821112],
                            [39.21458403, -7.87846956],
                            [39.21456416, -7.87863853],
                            [39.21445485, -7.87873793],
                            [39.21437535, -7.87885721],
                            [39.21434552, -7.87896654],
                            [39.21423621, -7.87904607],
                            [39.21414678, -7.87923492],
                            [39.21414678, -7.8794536],
                            [39.21413684, -7.87963251],
                            [39.21434552, -7.87984125],
                            [39.21466352, -7.87996052],
                            [39.21510079, -7.87988101],
                            [39.21554797, -7.87964245],
                            [39.21587591, -7.87933432],
                            [39.21591566, -7.87911565],
                            [39.21588585, -7.87889697],
                            [39.21575667, -7.87878764],
                            [39.21557779, -7.87868823],
                            [39.2153989, -7.87866835],
                            [39.21524985, -7.87861865],
                            [39.21517035, -7.87846956],
                            [39.21524985, -7.87813161],
                            [39.21535916, -7.87778371],
                            [39.21554798, -7.87762467],
                            [39.2158163, -7.87741594],
                            [39.2160051, -7.87728672],
                            [39.21622374, -7.87711775],
                            [39.21633305, -7.87690901],
                            [39.21635292, -7.87672016],
                            [39.21634298, -7.87658099],
                            [39.2162138, -7.8764816],
                            [39.2160846, -7.87644184],
                            [39.21594549, -7.87642196],
                            [39.21570698, -7.87641202],
                            [39.21552811, -7.8764319],
                            [39.21532935, -7.8764816],
                            [39.2151803, -7.87656111],
                            [39.21502129, -7.87673009],
                            [39.21498154, -7.87699847],
                        ],
                        [
                            [39.22548877, -7.86085766],
                            [39.22547022, -7.86124703],
                            [39.22541461, -7.86154369],
                            [39.22543314, -7.86186816],
                            [39.22543314, -7.86214629],
                            [39.22552584, -7.86246149],
                            [39.2256556, -7.86253566],
                            [39.22580389, -7.86247076],
                            [39.22591512, -7.86255419],
                            [39.22602633, -7.86281377],
                            [39.22623024, -7.863027],
                            [39.22643416, -7.86307336],
                            [39.22666587, -7.86303627],
                            [39.22688831, -7.86279524],
                            [39.22694392, -7.86234097],
                            [39.22691613, -7.86179401],
                            [39.22691613, -7.86161787],
                            [39.22704589, -7.86145099],
                            [39.22723126, -7.86126557],
                            [39.22734249, -7.86097819],
                            [39.22734249, -7.86059809],
                            [39.22736102, -7.86044048],
                            [39.22746297, -7.86026434],
                            [39.22763907, -7.8601531],
                            [39.22775957, -7.86004185],
                            [39.22788933, -7.86006966],
                            [39.22800983, -7.86010675],
                            [39.22803764, -7.86022726],
                            [39.22800982, -7.86055174],
                            [39.22806543, -7.86080205],
                            [39.22821374, -7.86090402],
                            [39.22826008, -7.86119142],
                            [39.22827861, -7.8613861],
                            [39.22820447, -7.86149735],
                            [39.22800982, -7.86159006],
                            [39.22788006, -7.86169203],
                            [39.22784298, -7.86213703],
                            [39.22782445, -7.86237806],
                            [39.22789859, -7.86254494],
                            [39.22809323, -7.86261911],
                            [39.22823226, -7.86257275],
                            [39.22831568, -7.86244297],
                            [39.2283991, -7.86244297],
                            [39.22846399, -7.86248932],
                            [39.22848252, -7.86258202],
                            [39.22846398, -7.86272108],
                            [39.22833422, -7.86288795],
                            [39.22830641, -7.86303628],
                            [39.2284547, -7.86323097],
                            [39.22859374, -7.86334221],
                            [39.22864008, -7.86351835],
                            [39.22858446, -7.86371305],
                            [39.22832494, -7.8638521],
                            [39.22812104, -7.86399116],
                            [39.22796347, -7.86414877],
                            [39.22790786, -7.86429709],
                            [39.22794493, -7.86448251],
                            [39.22796834, -7.8646152],
                            [39.22855663, -7.86473288],
                            [39.22855666, -7.86473283],
                            [39.22864008, -7.86463084],
                            [39.22874203, -7.8645474],
                            [39.22886252, -7.86446397],
                            [39.22890887, -7.8644547],
                            [39.22904789, -7.86451032],
                            [39.22927033, -7.86460304],
                            [39.22939083, -7.86465866],
                            [39.2295484, -7.86460304],
                            [39.22964109, -7.86449179],
                            [39.22978011, -7.864362],
                            [39.22994695, -7.864362],
                            [39.23024355, -7.86440835],
                            [39.23041965, -7.86448252],
                            [39.23059575, -7.86462158],
                            [39.23082746, -7.86466794],
                            [39.23102211, -7.86457523],
                            [39.23116114, -7.86450106],
                            [39.23127237, -7.86438982],
                            [39.23139285, -7.86441763],
                            [39.23159676, -7.86448253],
                            [39.23188409, -7.8645845],
                            [39.23205093, -7.86445471],
                            [39.23205093, -7.86428784],
                            [39.23205093, -7.86410243],
                            [39.23205093, -7.86400046],
                            [39.23208799, -7.86391702],
                            [39.23231045, -7.86380577],
                            [39.23266266, -7.86365744],
                            [39.23287584, -7.86346275],
                            [39.23298705, -7.86326808],
                            [39.23299632, -7.8631012],
                            [39.23290364, -7.86293433],
                            [39.23280169, -7.86286942],
                            [39.23264411, -7.86286942],
                            [39.23247728, -7.86290651],
                            [39.23240314, -7.86300849],
                            [39.23224557, -7.8630363],
                            [39.23215288, -7.86294359],
                            [39.23214362, -7.86280453],
                            [39.2320602, -7.86271183],
                            [39.23191189, -7.86274891],
                            [39.23184702, -7.86284162],
                            [39.23175434, -7.86285088],
                            [39.2315875, -7.8628138],
                            [39.23142066, -7.86284161],
                            [39.23126309, -7.86288797],
                            [39.23114261, -7.8629714],
                            [39.23104065, -7.86292505],
                            [39.23106846, -7.86275818],
                            [39.23115188, -7.86260057],
                            [39.23133725, -7.86238734],
                            [39.23147627, -7.86217412],
                            [39.231708, -7.86199797],
                            [39.23198605, -7.86191454],
                            [39.23216215, -7.86177548],
                            [39.23216215, -7.86158081],
                            [39.23205094, -7.86146955],
                            [39.2318841, -7.86146955],
                            [39.23172653, -7.86154372],
                            [39.23151335, -7.8616086],
                            [39.23132798, -7.86163642],
                            [39.23117968, -7.86164569],
                            [39.23104992, -7.86156225],
                            [39.23103139, -7.86140466],
                            [39.23104992, -7.86112653],
                            [39.23106847, -7.86086695],
                            [39.23109626, -7.86057957],
                            [39.23098505, -7.86032926],
                            [39.2307904, -7.86006968],
                            [39.23050309, -7.85968031],
                            [39.23027136, -7.85935582],
                            [39.23006747, -7.85911479],
                            [39.22995624, -7.85874396],
                            [39.22990063, -7.85819698],
                            [39.22981722, -7.85787251],
                            [39.22972453, -7.85765002],
                            [39.22972453, -7.85748314],
                            [39.22999332, -7.85729774],
                            [39.23018796, -7.85704742],
                            [39.23035479, -7.85686202],
                            [39.23047529, -7.85669514],
                            [39.23048456, -7.85648191],
                            [39.23047529, -7.85611108],
                            [39.2303548, -7.85588858],
                            [39.23008601, -7.85564755],
                            [39.22991918, -7.85545286],
                            [39.22987283, -7.85521182],
                            [39.22990065, -7.85498005],
                            [39.22987284, -7.85482245],
                            [39.22976162, -7.85468339],
                            [39.22959479, -7.85448871],
                            [39.22943721, -7.85441454],
                            [39.22919622, -7.85437746],
                            [39.22892744, -7.85445162],
                            [39.2287328, -7.85459068],
                            [39.22868646, -7.85479464],
                            [39.22856597, -7.85504495],
                            [39.22846402, -7.85521181],
                            [39.22835279, -7.85536942],
                            [39.22824155, -7.85557338],
                            [39.2281396, -7.85575879],
                            [39.2279635, -7.85586076],
                            [39.22783374, -7.85596274],
                            [39.22770398, -7.85590712],
                            [39.22760203, -7.85576805],
                            [39.22764838, -7.85547139],
                            [39.22772251, -7.85515619],
                            [39.22789862, -7.85489661],
                            [39.2281118, -7.85456286],
                            [39.22824156, -7.85420131],
                            [39.22826011, -7.85392319],
                            [39.22821377, -7.85358018],
                            [39.22814888, -7.8533206],
                            [39.22808401, -7.85302393],
                            [39.22809328, -7.85278289],
                            [39.22811181, -7.85260675],
                            [39.22810254, -7.85241207],
                            [39.22804694, -7.85213394],
                            [39.22798205, -7.85191145],
                            [39.22790791, -7.85180947],
                            [39.22777815, -7.85162406],
                            [39.22763911, -7.85152207],
                            [39.2275279, -7.85149427],
                            [39.22750008, -7.85166113],
                            [39.22751863, -7.85210614],
                            [39.22754642, -7.85258821],
                            [39.22754642, -7.85281997],
                            [39.22750008, -7.85295903],
                            [39.22734252, -7.85320934],
                            [39.22724056, -7.85337621],
                            [39.22715714, -7.85362653],
                            [39.22709226, -7.85393246],
                            [39.22704592, -7.85438671],
                            [39.22700884, -7.85479463],
                            [39.22699029, -7.85520254],
                            [39.22686981, -7.85550847],
                            [39.226842, -7.85569389],
                            [39.22673077, -7.85594419],
                            [39.22659174, -7.85614815],
                            [39.22641564, -7.85641699],
                            [39.22636003, -7.85669512],
                            [39.22636929, -7.85693615],
                            [39.22638784, -7.85718647],
                            [39.22636929, -7.85748313],
                            [39.22636929, -7.85792812],
                            [39.22632295, -7.8582526],
                            [39.22616537, -7.85858633],
                            [39.2259244, -7.85898498],
                            [39.22576682, -7.85930945],
                            [39.22560927, -7.85951341],
                            [39.2255073, -7.8597359],
                            [39.22547022, -7.86004184],
                            [39.22547022, -7.86034777],
                            [39.22548877, -7.86085766],
                        ],
                        [
                            [39.24804515, -7.85278064],
                            [39.24813231, -7.85257461],
                            [39.24821154, -7.85235269],
                            [39.24829077, -7.85217042],
                            [39.24829869, -7.85204362],
                            [39.24838586, -7.85192474],
                            [39.24861564, -7.85178209],
                            [39.24886919, -7.85169491],
                            [39.24898011, -7.85156811],
                            [39.24892466, -7.85141753],
                            [39.2488058, -7.85133036],
                            [39.24869487, -7.85125904],
                            [39.24862356, -7.85112431],
                            [39.24868695, -7.85101335],
                            [39.2487741, -7.8509975],
                            [39.24893258, -7.8510292],
                            [39.24913859, -7.8510926],
                            [39.24927328, -7.85114808],
                            [39.24943967, -7.85104505],
                            [39.24960605, -7.85083107],
                            [39.24978038, -7.85056161],
                            [39.24997846, -7.85043483],
                            [39.25015277, -7.8503001],
                            [39.25025578, -7.85015745],
                            [39.250232, -7.8500148],
                            [39.2500973, -7.84991176],
                            [39.249923, -7.84991176],
                            [39.24972491, -7.84991968],
                            [39.24956645, -7.84988006],
                            [39.24943175, -7.8498008],
                            [39.24932874, -7.84974534],
                            [39.24921782, -7.84974533],
                            [39.2490752, -7.8498008],
                            [39.2490039, -7.84977703],
                            [39.24896427, -7.84968193],
                            [39.24893259, -7.84947588],
                            [39.24887712, -7.8493253],
                            [39.24878203, -7.84933323],
                            [39.24867111, -7.84933322],
                            [39.24862357, -7.84927775],
                            [39.24856018, -7.84911925],
                            [39.24854434, -7.84895281],
                            [39.24847303, -7.84879431],
                            [39.2483938, -7.84870714],
                            [39.24832249, -7.84867543],
                            [39.24817987, -7.84867543],
                            [39.2479184, -7.84867543],
                            [39.24771239, -7.84865166],
                            [39.24749054, -7.84864373],
                            [39.24726869, -7.84861202],
                            [39.24699137, -7.84852485],
                            [39.24679329, -7.84846937],
                            [39.24657936, -7.84843767],
                            [39.24637335, -7.84839804],
                            [39.24622279, -7.84836635],
                            [39.24608017, -7.84832671],
                            [39.24595341, -7.84830294],
                            [39.24581872, -7.84830294],
                            [39.24570778, -7.84855654],
                            [39.24553347, -7.84893695],
                            [39.24531161, -7.84931735],
                            [39.24517691, -7.84967399],
                            [39.24511352, -7.84991966],
                            [39.24511352, -7.85009402],
                            [39.24516106, -7.8502446],
                            [39.24533538, -7.85036348],
                            [39.24551761, -7.85047442],
                            [39.24570777, -7.85072011],
                            [39.2459217, -7.85110843],
                            [39.24614355, -7.8514096],
                            [39.24638126, -7.85176623],
                            [39.24658726, -7.85211494],
                            [39.24676157, -7.85240024],
                            [39.24692004, -7.85270139],
                            [39.24709435, -7.85296292],
                            [39.24718942, -7.85315313],
                            [39.24729243, -7.85331955],
                            [39.24741129, -7.85346221],
                            [39.24755391, -7.85347807],
                            [39.24760144, -7.85334334],
                            [39.24760937, -7.85315313],
                            [39.24768068, -7.85303425],
                            [39.24787875, -7.85292331],
                            [39.24804515, -7.85278064],
                        ],
                        [
                            [39.32505576, -7.84970366],
                            [39.32508783, -7.84976784],
                            [39.32514556, -7.84985125],
                            [39.32519689, -7.84990259],
                            [39.32524179, -7.84998601],
                            [39.32529312, -7.85010152],
                            [39.32537652, -7.8502106],
                            [39.32542785, -7.85030044],
                            [39.32547275, -7.85039027],
                            [39.32552408, -7.85049937],
                            [39.32558183, -7.85060203],
                            [39.32565881, -7.85069188],
                            [39.32571654, -7.85078171],
                            [39.32577429, -7.8508523],
                            [39.32583202, -7.85092289],
                            [39.32587052, -7.85101272],
                            [39.32591542, -7.85109614],
                            [39.32598599, -7.85119882],
                            [39.32606298, -7.85131432],
                            [39.32615921, -7.85144907],
                            [39.32624983, -7.85157781],
                            [39.32638013, -7.85176832],
                            [39.32646033, -7.85190869],
                            [39.32654053, -7.85207914],
                            [39.32670091, -7.85221951],
                            [39.32684125, -7.85237993],
                            [39.32695151, -7.85254035],
                            [39.3270718, -7.85268072],
                            [39.32717205, -7.85280104],
                            [39.32724222, -7.85296146],
                            [39.32730236, -7.85309182],
                            [39.32735248, -7.85323219],
                            [39.32742265, -7.85337256],
                            [39.32748278, -7.85355303],
                            [39.32751286, -7.8536934],
                            [39.32753291, -7.85376358],
                            [39.32758303, -7.85390395],
                            [39.32768327, -7.85397414],
                            [39.32779354, -7.85404432],
                            [39.32789378, -7.85412454],
                            [39.32801407, -7.85422479],
                            [39.32810429, -7.85428496],
                            [39.32816443, -7.85440528],
                            [39.32818449, -7.8545256],
                            [39.32818449, -7.85467599],
                            [39.32816442, -7.85482639],
                            [39.32810429, -7.85500686],
                            [39.32803412, -7.8552074],
                            [39.32800404, -7.85534777],
                            [39.32800404, -7.85548814],
                            [39.32805416, -7.85560846],
                            [39.32816442, -7.85569869],
                            [39.32831479, -7.8557388],
                            [39.32850525, -7.8557388],
                            [39.32872579, -7.85571875],
                            [39.32890622, -7.85563853],
                            [39.32909668, -7.85552824],
                            [39.32919691, -7.85540793],
                            [39.32928714, -7.85528761],
                            [39.32931721, -7.85512719],
                            [39.32938738, -7.85497678],
                            [39.32944753, -7.85480635],
                            [39.32948762, -7.85462586],
                            [39.3295177, -7.85444539],
                            [39.32952772, -7.8542248],
                            [39.32954776, -7.85392401],
                            [39.32957784, -7.85367335],
                            [39.32954777, -7.8535029],
                            [39.32948763, -7.85332243],
                            [39.32944754, -7.85312189],
                            [39.32939741, -7.8529715],
                            [39.32935732, -7.85284115],
                            [39.32931722, -7.85271082],
                            [39.32927712, -7.85257045],
                            [39.32919692, -7.85242004],
                            [39.32912676, -7.85226965],
                            [39.32902653, -7.8520992],
                            [39.32895636, -7.8519488],
                            [39.32886614, -7.85186859],
                            [39.32878594, -7.85176833],
                            [39.32869573, -7.85165804],
                            [39.32861553, -7.85157782],
                            [39.32851528, -7.85148758],
                            [39.32838498, -7.85135725],
                            [39.32825467, -7.85122689],
                            [39.32813438, -7.85110657],
                            [39.3280241, -7.85102637],
                            [39.32791384, -7.8509261],
                            [39.32780358, -7.85084588],
                            [39.32763317, -7.85075565],
                            [39.32747278, -7.85062531],
                            [39.32730237, -7.85050499],
                            [39.32719211, -7.85036462],
                            [39.32704174, -7.85022425],
                            [39.32689139, -7.85003375],
                            [39.32678111, -7.84988334],
                            [39.32676106, -7.84975301],
                            [39.32660068, -7.84951237],
                            [39.32652048, -7.84932186],
                            [39.32640021, -7.84913136],
                            [39.32630998, -7.84901105],
                            [39.32615962, -7.8489208],
                            [39.32603933, -7.8488406],
                            [39.32593908, -7.84877041],
                            [39.32579875, -7.84870023],
                            [39.32563836, -7.84863004],
                            [39.32545793, -7.84851975],
                            [39.32535769, -7.84840945],
                            [39.32527749, -7.84829917],
                            [39.32516723, -7.84814876],
                            [39.32506698, -7.84801843],
                            [39.32496675, -7.847858],
                            [39.32489658, -7.84768755],
                            [39.32481638, -7.84753715],
                            [39.32478631, -7.84735668],
                            [39.32470612, -7.84721631],
                            [39.32467605, -7.84709599],
                            [39.32467605, -7.84699572],
                            [39.324656, -7.84689547],
                            [39.32463596, -7.84682528],
                            [39.32461591, -7.8467551],
                            [39.32456579, -7.84671498],
                            [39.32448559, -7.84667488],
                            [39.32442704, -7.84679681],
                            [39.32435648, -7.8468738],
                            [39.32430516, -7.84693797],
                            [39.324241, -7.84700214],
                            [39.32418968, -7.84707914],
                            [39.32412552, -7.84715615],
                            [39.32406777, -7.84720747],
                            [39.3240357, -7.84727166],
                            [39.32399721, -7.84731657],
                            [39.32395872, -7.84736149],
                            [39.32392664, -7.84741282],
                            [39.32390098, -7.84747057],
                            [39.32386891, -7.84752833],
                            [39.32384324, -7.8475925],
                            [39.32381758, -7.84765667],
                            [39.32381758, -7.84772726],
                            [39.32381758, -7.84779143],
                            [39.32384323, -7.84787485],
                            [39.32384323, -7.84794542],
                            [39.32385606, -7.84802884],
                            [39.32387531, -7.84811227],
                            [39.32392021, -7.84818286],
                            [39.32395871, -7.84820852],
                            [39.32398438, -7.84827911],
                            [39.32403569, -7.84834328],
                            [39.32406136, -7.84840102],
                            [39.32406777, -7.84849728],
                            [39.3241191, -7.8485807],
                            [39.32417042, -7.84865129],
                            [39.32421533, -7.84872829],
                            [39.32427307, -7.84881813],
                            [39.32433723, -7.84890796],
                            [39.32440138, -7.84898498],
                            [39.32446553, -7.84908123],
                            [39.32453611, -7.84915181],
                            [39.32461309, -7.84920957],
                            [39.32469649, -7.84928014],
                            [39.32477347, -7.84935716],
                            [39.32483122, -7.84942773],
                            [39.32491462, -7.84952399],
                            [39.32496593, -7.84958175],
                            [39.32501085, -7.84963308],
                            [39.32505576, -7.84970366],
                        ],
                        [
                            [39.34860228, -7.84828414],
                            [39.34865731, -7.8483514],
                            [39.34872456, -7.84841255],
                            [39.34879793, -7.84850429],
                            [39.34886519, -7.84857768],
                            [39.34891411, -7.84865107],
                            [39.34898749, -7.84873669],
                            [39.34905473, -7.84882843],
                            [39.34910365, -7.84890793],
                            [39.34914645, -7.84895686],
                            [39.34918924, -7.84901801],
                            [39.34923205, -7.8490914],
                            [39.34926874, -7.84916479],
                            [39.34930542, -7.84923818],
                            [39.34934822, -7.84932379],
                            [39.3493849, -7.84942776],
                            [39.3494277, -7.84955006],
                            [39.34945828, -7.84967238],
                            [39.34947049, -7.84977634],
                            [39.34948884, -7.84989866],
                            [39.34948273, -7.84997816],
                            [39.34948273, -7.85006989],
                            [39.34947662, -7.85014939],
                            [39.34945215, -7.85021667],
                            [39.3494277, -7.85028395],
                            [39.34941368, -7.85035348],
                            [39.35016549, -7.85035348],
                            [39.350792, -7.85044749],
                            [39.35132407, -7.85063909],
                            [39.35128948, -7.85037569],
                            [39.35128948, -7.85023045],
                            [39.35128184, -7.85010049],
                            [39.35128184, -7.8499476],
                            [39.35128948, -7.84980999],
                            [39.35129714, -7.84963416],
                            [39.35131242, -7.84949656],
                            [39.35134298, -7.8493819],
                            [39.35138884, -7.84925958],
                            [39.3514347, -7.84912198],
                            [39.35146528, -7.84898439],
                            [39.35150349, -7.84882384],
                            [39.35154171, -7.84866331],
                            [39.35159521, -7.84851043],
                            [39.35166399, -7.84840339],
                            [39.35171749, -7.84822758],
                            [39.35178628, -7.84807469],
                            [39.35183214, -7.84792944],
                            [39.3518627, -7.8477689],
                            [39.35190856, -7.84763129],
                            [39.35194678, -7.84748605],
                            [39.35199264, -7.8473408],
                            [39.35201556, -7.8472032],
                            [39.3520385, -7.84707325],
                            [39.35206907, -7.84691271],
                            [39.35206907, -7.84679805],
                            [39.35208436, -7.84666808],
                            [39.352092, -7.84657635],
                            [39.35212257, -7.84646933],
                            [39.35215314, -7.84631644],
                            [39.35218371, -7.8461712],
                            [39.35219899, -7.84601067],
                            [39.35222957, -7.84587306],
                            [39.35222957, -7.84575839],
                            [39.35223721, -7.84561314],
                            [39.35222957, -7.84539146],
                            [39.35222193, -7.84523091],
                            [39.35222194, -7.84509331],
                            [39.35222194, -7.84494806],
                            [39.3522143, -7.84480282],
                            [39.35219136, -7.84467287],
                            [39.35218372, -7.84450468],
                            [39.3521608, -7.8443518],
                            [39.35211494, -7.84421419],
                            [39.35209201, -7.84409953],
                            [39.35205379, -7.84395428],
                            [39.35200793, -7.84383196],
                            [39.35199265, -7.8437173],
                            [39.35197737, -7.8435797],
                            [39.35195444, -7.84346504],
                            [39.35190858, -7.84333507],
                            [39.35186272, -7.84320511],
                            [39.35182451, -7.84309809],
                            [39.35179395, -7.84293756],
                            [39.35176337, -7.84279232],
                            [39.35174809, -7.84266235],
                            [39.35171751, -7.84250947],
                            [39.35166402, -7.84239479],
                            [39.35165638, -7.84230305],
                            [39.35164874, -7.84217311],
                            [39.3516411, -7.84205843],
                            [39.3516258, -7.84194377],
                            [39.35159524, -7.84181381],
                            [39.3515723, -7.84168384],
                            [39.35154938, -7.8415539],
                            [39.35150353, -7.84144686],
                            [39.35146531, -7.84133984],
                            [39.35143473, -7.84124046],
                            [39.35139653, -7.8411258],
                            [39.35131246, -7.84091939],
                            [39.35125132, -7.84080473],
                            [39.35119017, -7.84071299],
                            [39.35113667, -7.84062125],
                            [39.35109081, -7.84053718],
                            [39.35105259, -7.84045308],
                            [39.35098382, -7.8403537],
                            [39.35093185, -7.84024667],
                            [39.35089363, -7.84016259],
                            [39.35085542, -7.8400785],
                            [39.35082484, -7.83998676],
                            [39.35080192, -7.83987974],
                            [39.35074842, -7.83978036],
                            [39.35071021, -7.83966568],
                            [39.35068729, -7.83955866],
                            [39.35063377, -7.83945164],
                            [39.35059557, -7.83935227],
                            [39.35055735, -7.83924525],
                            [39.35051914, -7.83915351],
                            [39.35048092, -7.83902355],
                            [39.35042742, -7.83890123],
                            [39.3504045, -7.83880951],
                            [39.35035101, -7.83870247],
                            [39.35031279, -7.83861074],
                            [39.35025165, -7.83851136],
                            [39.35019814, -7.83841198],
                            [39.35015228, -7.83829732],
                            [39.35010642, -7.83820558],
                            [39.35009114, -7.83812149],
                            [39.35007586, -7.83804505],
                            [39.35004529, -7.8379686],
                            [39.35001473, -7.83788451],
                            [39.34997651, -7.83778513],
                            [39.34993065, -7.83769339],
                            [39.34990771, -7.83758637],
                            [39.34987715, -7.83747171],
                            [39.34983894, -7.83737233],
                            [39.34982366, -7.8372653],
                            [39.34977014, -7.83718122],
                            [39.34970901, -7.83709712],
                            [39.34967845, -7.83698245],
                            [39.34965551, -7.83688307],
                            [39.34960201, -7.83675312],
                            [39.34954851, -7.83662316],
                            [39.34949502, -7.83653142],
                            [39.34942622, -7.83647027],
                            [39.34936509, -7.83638618],
                            [39.34931923, -7.83630208],
                            [39.34930395, -7.83621799],
                            [39.34928101, -7.83612627],
                            [39.34924279, -7.83601159],
                            [39.3491511, -7.83591986],
                            [39.3491511, -7.83583578],
                            [39.34911288, -7.83575168],
                            [39.34907466, -7.83569817],
                            [39.3490288, -7.83563701],
                            [39.34899823, -7.83555293],
                            [39.34896767, -7.83548412],
                            [39.34892945, -7.83540768],
                            [39.34890651, -7.8353083],
                            [39.34886831, -7.83521657],
                            [39.34881482, -7.83512483],
                            [39.3487766, -7.83502545],
                            [39.34874602, -7.83494136],
                            [39.34871545, -7.83484198],
                            [39.34865431, -7.83475025],
                            [39.34859317, -7.83465851],
                            [39.34850146, -7.83457442],
                            [39.3484021, -7.83450562],
                            [39.34831803, -7.83442917],
                            [39.34821867, -7.83437566],
                            [39.34811931, -7.83435273],
                            [39.34803524, -7.83435273],
                            [39.34793588, -7.8343833],
                            [39.34785947, -7.83441388],
                            [39.34781361, -7.83447504],
                            [39.34780597, -7.83455149],
                            [39.34779831, -7.83463558],
                            [39.34781361, -7.83476553],
                            [39.34783653, -7.83486491],
                            [39.34785946, -7.83504838],
                            [39.34789002, -7.83514775],
                            [39.34793588, -7.83523185],
                            [39.34796646, -7.83533123],
                            [39.34800467, -7.83543825],
                            [39.34804288, -7.8355147],
                            [39.34809639, -7.83560643],
                            [39.34817281, -7.83569817],
                            [39.3482263, -7.83578225],
                            [39.34826452, -7.83585106],
                            [39.34831802, -7.83593515],
                            [39.34837916, -7.83600395],
                            [39.34844795, -7.83609568],
                            [39.34854731, -7.83622565],
                            [39.34863902, -7.83635559],
                            [39.3487078, -7.8364932],
                            [39.34878423, -7.83663844],
                            [39.34885301, -7.83673782],
                            [39.34890651, -7.83689072],
                            [39.34888359, -7.83702831],
                            [39.34880715, -7.83714299],
                            [39.34874601, -7.83721178],
                            [39.34866959, -7.83731116],
                            [39.3486008, -7.83744876],
                            [39.3485473, -7.83757108],
                            [39.34852436, -7.83765516],
                            [39.34851672, -7.83773925],
                            [39.34851672, -7.83784627],
                            [39.34850144, -7.83799918],
                            [39.34846322, -7.83814442],
                            [39.34845558, -7.83829731],
                            [39.34845558, -7.83843491],
                            [39.34845558, -7.83856486],
                            [39.34843266, -7.83868718],
                            [39.34841736, -7.83887065],
                            [39.3483715, -7.8390159],
                            [39.34832565, -7.83913056],
                            [39.34820337, -7.83926816],
                            [39.34800466, -7.83937518],
                            [39.34785944, -7.83945927],
                            [39.34770658, -7.83953573],
                            [39.34759194, -7.83960452],
                            [39.34746201, -7.83968097],
                            [39.34729387, -7.83980327],
                            [39.34721744, -7.83992559],
                            [39.34705694, -7.83996382],
                            [39.34691173, -7.83997146],
                            [39.34679708, -7.83999439],
                            [39.34666716, -7.84003261],
                            [39.34652959, -7.84012435],
                            [39.34649137, -7.84020844],
                            [39.34641495, -7.84027723],
                            [39.34634616, -7.84041484],
                            [39.34633088, -7.84059067],
                            [39.34630794, -7.84078178],
                            [39.3463003, -7.84094231],
                            [39.3463003, -7.84114106],
                            [39.34633851, -7.84133218],
                            [39.34641495, -7.84150801],
                            [39.34650665, -7.84163031],
                            [39.34661366, -7.84176028],
                            [39.34671301, -7.84190552],
                            [39.34671301, -7.84202784],
                            [39.34665186, -7.8421425],
                            [39.3465678, -7.84225716],
                            [39.34653722, -7.84235654],
                            [39.34646078, -7.84244828],
                            [39.34635379, -7.84250944],
                            [39.34630029, -7.84260118],
                            [39.34631557, -7.84278464],
                            [39.34634615, -7.84297575],
                            [39.34635838, -7.84309196],
                            [39.34638283, -7.84319592],
                            [39.34648065, -7.843306],
                            [39.34659684, -7.84339774],
                            [39.346713, -7.84347724],
                            [39.3468414, -7.84356897],
                            [39.34696979, -7.84363625],
                            [39.3470554, -7.84368517],
                            [39.34716545, -7.84375244],
                            [39.34728775, -7.84380749],
                            [39.3473611, -7.84386864],
                            [39.34743447, -7.84399096],
                            [39.34752008, -7.84413161],
                            [39.347569, -7.8442478],
                            [39.34762403, -7.84440071],
                            [39.34768516, -7.84452301],
                            [39.34770962, -7.84466368],
                            [39.34775854, -7.84482268],
                            [39.34779522, -7.8450245],
                            [39.34781967, -7.84517738],
                            [39.34785025, -7.84534862],
                            [39.34788081, -7.84555656],
                            [39.34789304, -7.84567887],
                            [39.34789304, -7.84580119],
                            [39.34789304, -7.84601523],
                            [39.3478747, -7.846162],
                            [39.34786859, -7.84636382],
                            [39.34786859, -7.84657786],
                            [39.34786858, -7.84680414],
                            [39.34788693, -7.84699985],
                            [39.34788693, -7.84713439],
                            [39.34790527, -7.84721389],
                            [39.34790527, -7.84731175],
                            [39.34794806, -7.84741571],
                            [39.34798475, -7.84752579],
                            [39.34804589, -7.84762364],
                            [39.34808868, -7.84767868],
                            [39.34812537, -7.84773372],
                            [39.34817429, -7.84781322],
                            [39.34824766, -7.84789273],
                            [39.34829658, -7.84796612],
                            [39.34835159, -7.84802728],
                            [39.34841885, -7.84809454],
                            [39.34847999, -7.84816793],
                            [39.34854115, -7.84821686],
                            [39.34860228, -7.84828414],
                        ],
                        [
                            [39.31017688, -7.84518573],
                            [39.3102813, -7.84523049],
                            [39.31037079, -7.84530509],
                            [39.31048268, -7.84536478],
                            [39.31057219, -7.84543939],
                            [39.31068406, -7.84547667],
                            [39.31081832, -7.84547667],
                            [39.31101971, -7.84543192],
                            [39.31114651, -7.8453051],
                            [39.31128077, -7.84520064],
                            [39.31140011, -7.84512605],
                            [39.31155675, -7.84505144],
                            [39.31169101, -7.84501414],
                            [39.31183272, -7.84499922],
                            [39.31198189, -7.84500669],
                            [39.31212362, -7.8450216],
                            [39.31225041, -7.8450216],
                            [39.31236975, -7.84495446],
                            [39.31245926, -7.84484255],
                            [39.31248163, -7.84473064],
                            [39.31251146, -7.84460382],
                            [39.31249656, -7.84450683],
                            [39.31241451, -7.84440984],
                            [39.31238467, -7.8443054],
                            [39.3123623, -7.84420841],
                            [39.31230263, -7.84410397],
                            [39.31223549, -7.84402189],
                            [39.31209378, -7.84391745],
                            [39.31198189, -7.84383539],
                            [39.31186255, -7.84380554],
                            [39.31166864, -7.84379063],
                            [39.31151201, -7.84379063],
                            [39.3113852, -7.84379063],
                            [39.31123602, -7.84379063],
                            [39.31105701, -7.84379063],
                            [39.31090784, -7.84379808],
                            [39.31080341, -7.84385031],
                            [39.31073629, -7.84386522],
                            [39.31063932, -7.84386522],
                            [39.31046031, -7.84384284],
                            [39.31040064, -7.84378316],
                            [39.31029623, -7.84376078],
                            [39.31022163, -7.84376078],
                            [39.31009483, -7.84376077],
                            [39.30999786, -7.84380553],
                            [39.30993074, -7.84386522],
                            [39.30985615, -7.84389506],
                            [39.30976665, -7.84399205],
                            [39.30969206, -7.84410396],
                            [39.30964731, -7.84422333],
                            [39.30958764, -7.84430539],
                            [39.30949813, -7.84440983],
                            [39.30943101, -7.84443221],
                            [39.30936387, -7.84443221],
                            [39.30925198, -7.84443221],
                            [39.30916249, -7.84443968],
                            [39.30900585, -7.84443968],
                            [39.30886414, -7.84443968],
                            [39.30877463, -7.84443968],
                            [39.30867021, -7.84442475],
                            [39.30855087, -7.84443968],
                            [39.30841661, -7.84448443],
                            [39.30829727, -7.84457397],
                            [39.30817793, -7.8446411],
                            [39.30806604, -7.84471571],
                            [39.30801383, -7.84477539],
                            [39.30801383, -7.84485],
                            [39.30801383, -7.84498427],
                            [39.30800637, -7.84511857],
                            [39.30803622, -7.8452454],
                            [39.30808842, -7.84534239],
                            [39.30814808, -7.84540207],
                            [39.30822268, -7.84549159],
                            [39.30829727, -7.84558858],
                            [39.30837931, -7.84566318],
                            [39.30852102, -7.84567811],
                            [39.30869258, -7.84567811],
                            [39.30887904, -7.84564081],
                            [39.30908044, -7.84561096],
                            [39.30922215, -7.84554382],
                            [39.30939371, -7.84551397],
                            [39.30951305, -7.84544684],
                            [39.30964731, -7.84534239],
                            [39.30975918, -7.84525286],
                            [39.30987106, -7.84520064],
                            [39.31003515, -7.84518573],
                            [39.31017688, -7.84518573],
                        ],
                        [
                            [39.3150709, -7.83841038],
                            [39.3152334, -7.83837287],
                            [39.31548339, -7.83833537],
                            [39.3156584, -7.83828536],
                            [39.31587089, -7.83823535],
                            [39.31608338, -7.83814784],
                            [39.31633337, -7.83804782],
                            [39.31639587, -7.83787279],
                            [39.31640837, -7.83764774],
                            [39.31633337, -7.83734769],
                            [39.31619588, -7.83716015],
                            [39.31610838, -7.83704763],
                            [39.31598339, -7.83691009],
                            [39.31588339, -7.83677256],
                            [39.3157959, -7.83672255],
                            [39.3156959, -7.83666004],
                            [39.31555839, -7.83666004],
                            [39.3153459, -7.83669755],
                            [39.31508341, -7.83674756],
                            [39.31482092, -7.83684757],
                            [39.31460843, -7.83692259],
                            [39.31444593, -7.83692258],
                            [39.31433344, -7.83692258],
                            [39.31415845, -7.83686007],
                            [39.31400845, -7.83682256],
                            [39.31370845, -7.83673505],
                            [39.31354596, -7.83661002],
                            [39.31335846, -7.8364725],
                            [39.31317097, -7.83637248],
                            [39.31297098, -7.83629746],
                            [39.31275849, -7.83624745],
                            [39.312446, -7.83613493],
                            [39.31223351, -7.83605992],
                            [39.312096, -7.83600991],
                            [39.31179601, -7.83595989],
                            [39.31159602, -7.83594739],
                            [39.31144603, -7.83594739],
                            [39.31130853, -7.83595989],
                            [39.31113354, -7.8359849],
                            [39.31089605, -7.8359974],
                            [39.31060856, -7.8360224],
                            [39.31045856, -7.8360224],
                            [39.31030855, -7.8360224],
                            [39.31015856, -7.83595989],
                            [39.30992107, -7.83584737],
                            [39.30960858, -7.83570984],
                            [39.3092211, -7.83554731],
                            [39.30897111, -7.83540978],
                            [39.30864612, -7.83527225],
                            [39.30848361, -7.83519724],
                            [39.30822113, -7.8350597],
                            [39.30799613, -7.83500969],
                            [39.30782114, -7.83489717],
                            [39.30754615, -7.83479715],
                            [39.30723367, -7.83468462],
                            [39.30695868, -7.83468462],
                            [39.30665867, -7.83468462],
                            [39.30642118, -7.83468462],
                            [39.3060837, -7.83468462],
                            [39.30582121, -7.83468462],
                            [39.30565871, -7.83468462],
                            [39.30555872, -7.8346221],
                            [39.30544622, -7.83453459],
                            [39.30534623, -7.83447208],
                            [39.30513373, -7.83443457],
                            [39.30490873, -7.83443457],
                            [39.30458374, -7.83442206],
                            [39.30438375, -7.83440956],
                            [39.30430875, -7.83450958],
                            [39.30432125, -7.8346471],
                            [39.30432125, -7.83475963],
                            [39.30422125, -7.83477213],
                            [39.30408376, -7.83479713],
                            [39.30402126, -7.83488465],
                            [39.30407126, -7.83502217],
                            [39.30418375, -7.83513469],
                            [39.30433375, -7.83520972],
                            [39.30449624, -7.83523473],
                            [39.30455874, -7.83530974],
                            [39.30459624, -7.83545977],
                            [39.30457124, -7.8355973],
                            [39.30452124, -7.83579733],
                            [39.30440874, -7.83588485],
                            [39.30422125, -7.83595986],
                            [39.30409625, -7.83608489],
                            [39.30395876, -7.8361599],
                            [39.30392126, -7.83630993],
                            [39.30397126, -7.83648496],
                            [39.30407125, -7.83660998],
                            [39.30427124, -7.8366975],
                            [39.30432124, -7.83679752],
                            [39.30433374, -7.83694756],
                            [39.30439624, -7.83709759],
                            [39.30448373, -7.83721011],
                            [39.30459623, -7.83727262],
                            [39.30475872, -7.83724762],
                            [39.30487122, -7.83721011],
                            [39.30499621, -7.83721011],
                            [39.30504621, -7.83734764],
                            [39.30507122, -7.83752267],
                            [39.30507122, -7.83769771],
                            [39.30507122, -7.83789774],
                            [39.30515872, -7.83803527],
                            [39.30528371, -7.83816029],
                            [39.30540871, -7.83828532],
                            [39.3056087, -7.83839784],
                            [39.30590869, -7.83838534],
                            [39.30615868, -7.83832283],
                            [39.30629617, -7.8381603],
                            [39.30644617, -7.83792275],
                            [39.30644617, -7.83773522],
                            [39.30647117, -7.83757269],
                            [39.30657117, -7.83747267],
                            [39.30667116, -7.83728514],
                            [39.30667116, -7.8370851],
                            [39.30662117, -7.83688505],
                            [39.30648367, -7.83672251],
                            [39.30632118, -7.83660999],
                            [39.30632118, -7.83645996],
                            [39.30645867, -7.83644746],
                            [39.30673366, -7.83644746],
                            [39.30695867, -7.83644747],
                            [39.30717116, -7.83644747],
                            [39.30739615, -7.83645997],
                            [39.30753365, -7.83654749],
                            [39.30762114, -7.83664751],
                            [39.30770864, -7.83676003],
                            [39.30777114, -7.83686005],
                            [39.30788363, -7.83691006],
                            [39.30805862, -7.83693508],
                            [39.30823362, -7.83701009],
                            [39.30828361, -7.83721013],
                            [39.30830861, -7.83744767],
                            [39.30830861, -7.83766021],
                            [39.30832111, -7.83782274],
                            [39.3084461, -7.83796027],
                            [39.30863361, -7.83794777],
                            [39.30880861, -7.83776023],
                            [39.30885861, -7.83751019],
                            [39.3089961, -7.83726014],
                            [39.3090961, -7.8370226],
                            [39.30920859, -7.83683505],
                            [39.30930859, -7.83673503],
                            [39.30940859, -7.83674753],
                            [39.30948358, -7.83684755],
                            [39.30954608, -7.83699759],
                            [39.30957108, -7.83717263],
                            [39.30970857, -7.83733516],
                            [39.30987107, -7.83742267],
                            [39.31000856, -7.83739767],
                            [39.31018355, -7.83734766],
                            [39.31034605, -7.83734766],
                            [39.31045856, -7.83743518],
                            [39.31050856, -7.8375727],
                            [39.31063355, -7.83781025],
                            [39.31064605, -7.83799778],
                            [39.31063355, -7.83816031],
                            [39.31050855, -7.83831034],
                            [39.31035856, -7.83842286],
                            [39.31022105, -7.83856039],
                            [39.31015855, -7.83866041],
                            [39.31018355, -7.83878544],
                            [39.31030854, -7.83887296],
                            [39.31045855, -7.83883545],
                            [39.31068354, -7.83871043],
                            [39.31089604, -7.83856039],
                            [39.31110853, -7.83849788],
                            [39.31132102, -7.83847288],
                            [39.31150851, -7.83847288],
                            [39.31162101, -7.83851038],
                            [39.3118335, -7.83862291],
                            [39.31207099, -7.83877295],
                            [39.3122585, -7.83884796],
                            [39.31239599, -7.83887297],
                            [39.31257098, -7.83887297],
                            [39.31273348, -7.83887297],
                            [39.31294597, -7.83884797],
                            [39.31307096, -7.83886047],
                            [39.31324596, -7.8390105],
                            [39.31337095, -7.83916053],
                            [39.31355844, -7.83932306],
                            [39.31380843, -7.83933556],
                            [39.31403344, -7.83933556],
                            [39.31430843, -7.83933556],
                            [39.31455842, -7.83933557],
                            [39.31487091, -7.83933557],
                            [39.3151459, -7.83939808],
                            [39.31532089, -7.83934807],
                            [39.31542089, -7.83923555],
                            [39.31542089, -7.83914803],
                            [39.31532089, -7.83904801],
                            [39.31520839, -7.838948],
                            [39.3150709, -7.83887298],
                            [39.31485841, -7.83879797],
                            [39.31467092, -7.83879797],
                            [39.31453342, -7.83872295],
                            [39.31438343, -7.83859791],
                            [39.31427093, -7.83847289],
                            [39.31427093, -7.83834786],
                            [39.31437093, -7.83833536],
                            [39.31453342, -7.83833536],
                            [39.31468342, -7.83834787],
                            [39.31489591, -7.83839788],
                            [39.3150709, -7.83841038],
                        ],
                        [
                            [39.39142508, -7.83651175],
                            [39.39089372, -7.83653143],
                            [39.3904411, -7.83645269],
                            [39.39014591, -7.8363149],
                            [39.38987039, -7.83613776],
                            [39.3896736, -7.83582281],
                            [39.38955551, -7.83546849],
                            [39.38933904, -7.83517323],
                            [39.38890609, -7.83517323],
                            [39.38839442, -7.83527165],
                            [39.38811891, -7.83538975],
                            [39.38788275, -7.8357047],
                            [39.38786308, -7.83603932],
                            [39.38784339, -7.83637395],
                            [39.38768596, -7.83674794],
                            [39.38752851, -7.83704321],
                            [39.38750884, -7.83731878],
                            [39.38721364, -7.83751563],
                            [39.38695779, -7.83743688],
                            [39.38672165, -7.83706288],
                            [39.38656422, -7.8367873],
                            [39.38636742, -7.8366692],
                            [39.3860919, -7.83649204],
                            [39.38585575, -7.83649204],
                            [39.38561959, -7.83664952],
                            [39.38554087, -7.83688572],
                            [39.38554087, -7.83720067],
                            [39.38569831, -7.83755499],
                            [39.38597382, -7.83781088],
                            [39.3863871, -7.83810614],
                            [39.38672164, -7.83842109],
                            [39.38705619, -7.83867698],
                            [39.38727267, -7.83897224],
                            [39.3875285, -7.83936592],
                            [39.38784337, -7.83985802],
                            [39.38809921, -7.84017297],
                            [39.38837473, -7.84036982],
                            [39.38882735, -7.84035013],
                            [39.38949646, -7.84035013],
                            [39.38989006, -7.84035013],
                            [39.39014589, -7.84036982],
                            [39.39032301, -7.8402714],
                            [39.39048044, -7.84007455],
                            [39.3905198, -7.83979899],
                            [39.39059852, -7.83956277],
                            [39.39067723, -7.83932657],
                            [39.39075597, -7.83914941],
                            [39.39105116, -7.83914941],
                            [39.39120859, -7.83930688],
                            [39.39120859, -7.83960214],
                            [39.39126763, -7.83997615],
                            [39.39162186, -7.84017298],
                            [39.39217289, -7.84021235],
                            [39.3925468, -7.84003519],
                            [39.39296008, -7.8399171],
                            [39.3934127, -7.83973994],
                            [39.39392437, -7.83952342],
                            [39.3941802, -7.83932658],
                            [39.39451477, -7.83938563],
                            [39.39479027, -7.83946438],
                            [39.39508546, -7.8396809],
                            [39.39526258, -7.83968091],
                            [39.39542003, -7.83956279],
                            [39.39561682, -7.83946438],
                            [39.39587265, -7.83936596],
                            [39.39608913, -7.8393069],
                            [39.39636465, -7.83926754],
                            [39.39650241, -7.839425],
                            [39.39665984, -7.83964153],
                            [39.39685663, -7.83981869],
                            [39.39701407, -7.84001554],
                            [39.39721086, -7.8400549],
                            [39.3974667, -7.83983838],
                            [39.3975651, -7.8395628],
                            [39.39762415, -7.83936597],
                            [39.3976635, -7.83909038],
                            [39.39780127, -7.83881482],
                            [39.3980571, -7.83861797],
                            [39.39829325, -7.83853924],
                            [39.39854908, -7.83853924],
                            [39.39888363, -7.83853924],
                            [39.39911979, -7.83836208],
                            [39.3992182, -7.83802746],
                            [39.39919851, -7.8377322],
                            [39.39915916, -7.83735819],
                            [39.39898204, -7.83706293],
                            [39.39880492, -7.83706293],
                            [39.39841133, -7.83708262],
                            [39.39791935, -7.83708262],
                            [39.39750607, -7.83698419],
                            [39.39726992, -7.83692514],
                            [39.39701409, -7.83668893],
                            [39.39701409, -7.83637399],
                            [39.39715185, -7.83605904],
                            [39.39750607, -7.83592126],
                            [39.39805711, -7.83570474],
                            [39.39841133, -7.83548822],
                            [39.39880493, -7.83523231],
                            [39.39910012, -7.83491738],
                            [39.39929693, -7.83466149],
                            [39.39939533, -7.83436623],
                            [39.39943469, -7.83405128],
                            [39.39967084, -7.83401192],
                            [39.39988731, -7.83403159],
                            [39.40004476, -7.83426781],
                            [39.40004476, -7.83458274],
                            [39.40020219, -7.83489769],
                            [39.40041866, -7.83507485],
                            [39.40071385, -7.83535044],
                            [39.40089097, -7.83560633],
                            [39.40089097, -7.83596064],
                            [39.40089097, -7.83633464],
                            [39.40089097, -7.83672832],
                            [39.40102872, -7.83706294],
                            [39.40122551, -7.83733853],
                            [39.40148134, -7.83745663],
                            [39.40169782, -7.8373582],
                            [39.40177655, -7.83718105],
                            [39.40175687, -7.83692515],
                            [39.40187494, -7.83666926],
                            [39.40209142, -7.83664959],
                            [39.40220949, -7.83668896],
                            [39.40228822, -7.83686611],
                            [39.40222918, -7.83704327],
                            [39.40226853, -7.83735821],
                            [39.40244565, -7.83751569],
                            [39.40268181, -7.83765347],
                            [39.40295732, -7.837496],
                            [39.40325251, -7.83724011],
                            [39.40350834, -7.83696453],
                            [39.40364611, -7.83668896],
                            [39.40364611, -7.83647244],
                            [39.40342963, -7.83635434],
                            [39.40313444, -7.83629528],
                            [39.40285892, -7.83621654],
                            [39.40258342, -7.83603938],
                            [39.4025047, -7.83570476],
                            [39.40244566, -7.83538981],
                            [39.40264245, -7.83517329],
                            [39.4027999, -7.8350355],
                            [39.40276054, -7.83470087],
                            [39.40276054, -7.83442529],
                            [39.40287862, -7.83420876],
                            [39.40313445, -7.83407097],
                            [39.40331157, -7.83399224],
                            [39.40358709, -7.83387414],
                            [39.40374452, -7.83418909],
                            [39.40386259, -7.83456308],
                            [39.40405939, -7.83483867],
                            [39.40437426, -7.83513393],
                            [39.40463009, -7.83537013],
                            [39.40502368, -7.83544886],
                            [39.40541728, -7.8354095],
                            [39.4056928, -7.83552761],
                            [39.4057912, -7.83574414],
                            [39.40573215, -7.83605907],
                            [39.40569279, -7.83639371],
                            [39.40553536, -7.83662991],
                            [39.40525984, -7.8368858],
                            [39.40510241, -7.83714169],
                            [39.4051024, -7.83741728],
                            [39.40512207, -7.83779127],
                            [39.40525983, -7.83798812],
                            [39.40553535, -7.83800779],
                            [39.40571246, -7.83792906],
                            [39.40590926, -7.83767317],
                            [39.40608638, -7.83747634],
                            [39.40638157, -7.83749601],
                            [39.40647997, -7.83767317],
                            [39.40689324, -7.83773223],
                            [39.40714907, -7.83773223],
                            [39.40732619, -7.83775191],
                            [39.40750331, -7.83796844],
                            [39.40750331, -7.83826369],
                            [39.40762138, -7.83859833],
                            [39.40770009, -7.83883453],
                            [39.40781818, -7.83903138],
                            [39.40795593, -7.83914948],
                            [39.40811337, -7.83928728],
                            [39.40813304, -7.83946443],
                            [39.40831016, -7.83968096],
                            [39.40856599, -7.83975969],
                            [39.40884151, -7.83966127],
                            [39.4091367, -7.83962191],
                            [39.40921542, -7.83928728],
                            [39.40919575, -7.83897233],
                            [39.40899895, -7.83877548],
                            [39.4087628, -7.83857865],
                            [39.40868409, -7.83836212],
                            [39.40890056, -7.83828339],
                            [39.40941223, -7.83840149],
                            [39.40972711, -7.83846055],
                            [39.4102978, -7.83846055],
                            [39.41073076, -7.83844086],
                            [39.41104564, -7.83824403],
                            [39.41138018, -7.83818498],
                            [39.41165571, -7.8381653],
                            [39.4119509, -7.83812594],
                            [39.41226577, -7.83812594],
                            [39.41258064, -7.83826371],
                            [39.41287584, -7.83820467],
                            [39.41293487, -7.83800782],
                            [39.41293487, -7.8377913],
                            [39.4128168, -7.83751573],
                            [39.41273809, -7.83727952],
                            [39.41273809, -7.83712205],
                            [39.41285616, -7.83700393],
                            [39.41307264, -7.83674804],
                            [39.41319071, -7.83647248],
                            [39.41323007, -7.83621659],
                            [39.41323007, -7.83601974],
                            [39.41319072, -7.8358229],
                            [39.41326945, -7.83562606],
                            [39.4135056, -7.83538986],
                            [39.41372207, -7.83538986],
                            [39.41380079, -7.8354489],
                            [39.41380079, -7.83562606],
                            [39.41380079, -7.83586227],
                            [39.41380078, -7.83615753],
                            [39.41380078, -7.83639375],
                            [39.41380078, -7.83668901],
                            [39.41372207, -7.83700394],
                            [39.41372206, -7.83729921],
                            [39.4135843, -7.83763384],
                            [39.4135843, -7.83785036],
                            [39.41370239, -7.83798815],
                            [39.41403694, -7.83800783],
                            [39.41433213, -7.83800783],
                            [39.41460765, -7.83800783],
                            [39.41482413, -7.83798816],
                            [39.41502092, -7.83773227],
                            [39.41521771, -7.83737795],
                            [39.41535547, -7.83710237],
                            [39.41535547, -7.8368268],
                            [39.41521771, -7.83661028],
                            [39.41506028, -7.83613786],
                            [39.41515868, -7.83600007],
                            [39.41535547, -7.83592134],
                            [39.41555228, -7.83590165],
                            [39.41578843, -7.83590165],
                            [39.41592619, -7.83601975],
                            [39.41614266, -7.83619691],
                            [39.41633945, -7.83637407],
                            [39.41653625, -7.83653153],
                            [39.4168708, -7.83659059],
                            [39.41704792, -7.83641344],
                            [39.41710697, -7.83619691],
                            [39.41706761, -7.83600008],
                            [39.41708728, -7.83574419],
                            [39.41712664, -7.83542924],
                            [39.41712664, -7.83517335],
                            [39.41710697, -7.83483872],
                            [39.41704793, -7.83442535],
                            [39.41704793, -7.83416946],
                            [39.41720536, -7.83393326],
                            [39.4172841, -7.83367735],
                            [39.41736281, -7.83344115],
                            [39.41730377, -7.83310653],
                            [39.41722505, -7.83283094],
                            [39.41706762, -7.83267348],
                            [39.41681179, -7.83261442],
                            [39.41647723, -7.83263411],
                            [39.41624108, -7.83279157],
                            [39.41624108, -7.83304746],
                            [39.41624108, -7.83336241],
                            [39.41624107, -7.83357894],
                            [39.41616236, -7.83383483],
                            [39.41600491, -7.83414978],
                            [39.4157294, -7.83440567],
                            [39.41555228, -7.83464187],
                            [39.41537517, -7.83475997],
                            [39.41500124, -7.83475996],
                            [39.41462734, -7.83468123],
                            [39.41448958, -7.83456313],
                            [39.41448958, -7.83440566],
                            [39.41458798, -7.8342285],
                            [39.41478477, -7.83409071],
                            [39.41506029, -7.83389387],
                            [39.4152571, -7.83357893],
                            [39.41533581, -7.83338208],
                            [39.41533582, -7.83310652],
                            [39.41533582, -7.83283094],
                            [39.41523741, -7.83265378],
                            [39.41502094, -7.83259472],
                            [39.4147651, -7.83277187],
                            [39.41445023, -7.83283093],
                            [39.41401727, -7.83288999],
                            [39.41350561, -7.83290966],
                            [39.41319073, -7.83306714],
                            [39.41305298, -7.83338207],
                            [39.41287586, -7.83379545],
                            [39.41269874, -7.83407101],
                            [39.41260034, -7.83436629],
                            [39.41246258, -7.83462218],
                            [39.41226579, -7.83501584],
                            [39.41222643, -7.83540953],
                            [39.41230514, -7.83574417],
                            [39.41242322, -7.83601973],
                            [39.41242321, -7.83625595],
                            [39.41218707, -7.83645278],
                            [39.4118525, -7.83647247],
                            [39.41136052, -7.83647247],
                            [39.41104564, -7.83647247],
                            [39.41075045, -7.83659057],
                            [39.41053397, -7.83676772],
                            [39.41029781, -7.83698425],
                            [39.4098255, -7.83698425],
                            [39.40947127, -7.83690551],
                            [39.40909736, -7.83692519],
                            [39.40854633, -7.83690551],
                            [39.40817241, -7.83686614],
                            [39.40783786, -7.83676771],
                            [39.40762139, -7.83662992],
                            [39.40738524, -7.83639372],
                            [39.40728684, -7.8361772],
                            [39.40716877, -7.8359213],
                            [39.40695229, -7.8356654],
                            [39.4066571, -7.83535046],
                            [39.4063619, -7.83507488],
                            [39.40583056, -7.83479929],
                            [39.40539761, -7.83446467],
                            [39.40516145, -7.83414973],
                            [39.40500402, -7.83397257],
                            [39.40500402, -7.8336773],
                            [39.40522049, -7.83353952],
                            [39.40551569, -7.8335592],
                            [39.40571247, -7.8336773],
                            [39.40588959, -7.83389383],
                            [39.40614542, -7.83416941],
                            [39.40671614, -7.83440563],
                            [39.40705068, -7.83440563],
                            [39.40730653, -7.83440563],
                            [39.40752301, -7.83442531],
                            [39.40775916, -7.83458278],
                            [39.40791659, -7.83470089],
                            [39.40823146, -7.83477963],
                            [39.40854634, -7.83474026],
                            [39.40886122, -7.83466153],
                            [39.40919577, -7.83462216],
                            [39.40968775, -7.83434658],
                            [39.4098452, -7.83399227],
                            [39.40982787, -7.83368048],
                            [39.40968933, -7.83346631],
                            [39.40950042, -7.83321436],
                            [39.40923592, -7.83301279],
                            [39.40899661, -7.83289941],
                            [39.40868174, -7.83288681],
                            [39.40842984, -7.83298759],
                            [39.40820313, -7.83298759],
                            [39.40806459, -7.83283642],
                            [39.40795123, -7.83267264],
                            [39.40781269, -7.83255926],
                            [39.40764897, -7.83239549],
                            [39.40751041, -7.83224432],
                            [39.40738446, -7.83214354],
                            [39.40723333, -7.83214354],
                            [39.4070444, -7.83214354],
                            [39.40698143, -7.83208054],
                            [39.4068303, -7.83206795],
                            [39.40672953, -7.83219392],
                            [39.4065406, -7.83226951],
                            [39.40633908, -7.83219392],
                            [39.40620054, -7.83208054],
                            [39.40616276, -7.83189158],
                            [39.40616276, -7.83175299],
                            [39.40618795, -7.83160182],
                            [39.40625093, -7.83142546],
                            [39.4063139, -7.83127428],
                            [39.40625093, -7.83108532],
                            [39.40615017, -7.83092154],
                            [39.40593606, -7.83092154],
                            [39.40579751, -7.83092154],
                            [39.40565896, -7.83103492],
                            [39.40553301, -7.83118609],
                            [39.4052937, -7.83124908],
                            [39.40511738, -7.8311987],
                            [39.40496623, -7.83112311],
                            [39.40486549, -7.83099713],
                            [39.40477732, -7.83085856],
                            [39.40473954, -7.83071997],
                            [39.40471434, -7.83051841],
                            [39.40470175, -7.83027906],
                            [39.40471434, -7.83016568],
                            [39.4048529, -7.83006489],
                            [39.4050922, -7.8300523],
                            [39.40520556, -7.8300523],
                            [39.40540706, -7.8300523],
                            [39.40559599, -7.8299893],
                            [39.40574713, -7.82983814],
                            [39.40591087, -7.82966176],
                            [39.40603682, -7.82957359],
                            [39.40617536, -7.82966176],
                            [39.40636429, -7.82973735],
                            [39.40650284, -7.82973735],
                            [39.40666656, -7.82978774],
                            [39.4068429, -7.82978774],
                            [39.40698144, -7.82977514],
                            [39.40714517, -7.82972476],
                            [39.40730891, -7.82963657],
                            [39.40729632, -7.82942241],
                            [39.40725853, -7.82927124],
                            [39.40711999, -7.82915786],
                            [39.40698144, -7.82904448],
                            [39.40694366, -7.8289059],
                            [39.40694367, -7.82880512],
                            [39.40708221, -7.82880512],
                            [39.40724594, -7.82871693],
                            [39.40739707, -7.82856577],
                            [39.40756081, -7.828402],
                            [39.40769936, -7.82827601],
                            [39.40773714, -7.82813744],
                            [39.40773714, -7.82797367],
                            [39.40768677, -7.8278477],
                            [39.40763638, -7.82765872],
                            [39.40744746, -7.82748236],
                            [39.40732152, -7.82733118],
                            [39.40718297, -7.8272556],
                            [39.40698145, -7.8272556],
                            [39.40674215, -7.82728079],
                            [39.40650285, -7.82733117],
                            [39.4063769, -7.82746974],
                            [39.40616278, -7.82765872],
                            [39.40594866, -7.8278225],
                            [39.40569676, -7.82799886],
                            [39.40540707, -7.82816262],
                            [39.40523075, -7.82828861],
                            [39.40501663, -7.82843978],
                            [39.4048403, -7.82852797],
                            [39.40466397, -7.82865394],
                            [39.40446245, -7.82881771],
                            [39.40429872, -7.82893109],
                            [39.40413498, -7.82904447],
                            [39.40393347, -7.82918304],
                            [39.40380752, -7.82922084],
                            [39.40369417, -7.82915784],
                            [39.403606, -7.82906966],
                            [39.40351783, -7.82890589],
                            [39.40344226, -7.82875472],
                            [39.40339189, -7.82855315],
                            [39.4033415, -7.82838937],
                            [39.40330372, -7.82821301],
                            [39.40327853, -7.82797365],
                            [39.40326594, -7.82780987],
                            [39.40326594, -7.8276587],
                            [39.40325335, -7.82750754],
                            [39.40324076, -7.82733116],
                            [39.40319037, -7.82712961],
                            [39.40314, -7.82694063],
                            [39.40296366, -7.82682725],
                            [39.40282512, -7.82672647],
                            [39.40271176, -7.82668868],
                            [39.40249765, -7.82668868],
                            [39.40234651, -7.82673906],
                            [39.40219538, -7.82675166],
                            [39.40203163, -7.82675166],
                            [39.40185531, -7.82667608],
                            [39.40180493, -7.8265501],
                            [39.40170416, -7.82636113],
                            [39.40167898, -7.82617216],
                            [39.40169157, -7.82599579],
                            [39.40172937, -7.82579423],
                            [39.4018805, -7.82563046],
                            [39.40201905, -7.82551708],
                            [39.40218279, -7.82530292],
                            [39.40232133, -7.82513914],
                            [39.40240949, -7.82496278],
                            [39.40232133, -7.8248494],
                            [39.40205683, -7.8247738],
                            [39.40181753, -7.82461004],
                            [39.40171677, -7.82445887],
                            [39.40151526, -7.82433288],
                            [39.40135152, -7.8241943],
                            [39.40136411, -7.82401794],
                            [39.40138931, -7.82382897],
                            [39.40155304, -7.82365261],
                            [39.40175456, -7.82355183],
                            [39.40191829, -7.82338805],
                            [39.40213241, -7.82329987],
                            [39.40233393, -7.82321169],
                            [39.40252285, -7.82316129],
                            [39.40269919, -7.82316129],
                            [39.40283773, -7.82322428],
                            [39.40298886, -7.82323688],
                            [39.40298886, -7.82308571],
                            [39.40288812, -7.82289674],
                            [39.40279995, -7.82272038],
                            [39.40278736, -7.8225566],
                            [39.40262361, -7.82243062],
                            [39.4024347, -7.82238024],
                            [39.40228355, -7.82238023],
                            [39.40223318, -7.82230464],
                            [39.40217019, -7.82211567],
                            [39.40193089, -7.8219645],
                            [39.40179235, -7.82186371],
                            [39.40169159, -7.82182593],
                            [39.40165381, -7.82173774],
                            [39.40161603, -7.82149838],
                            [39.40150268, -7.82141019],
                            [39.40137673, -7.82142279],
                            [39.40126337, -7.82153617],
                            [39.40116261, -7.82162436],
                            [39.40108704, -7.82177552],
                            [39.40108704, -7.82192671],
                            [39.40107444, -7.82214087],
                            [39.40103666, -7.82240542],
                            [39.40094849, -7.82269516],
                            [39.4008981, -7.82295973],
                            [39.40087291, -7.82328726],
                            [39.40074696, -7.82353923],
                            [39.4006462, -7.82390456],
                            [39.40059583, -7.8242069],
                            [39.40054544, -7.82449665],
                            [39.40051711, -7.82481946],
                            [39.40049742, -7.82481946],
                            [39.40026127, -7.82546903],
                            [39.40004479, -7.82615797],
                            [39.39976927, -7.82670914],
                            [39.39949375, -7.82739808],
                            [39.3992576, -7.82790986],
                            [39.39900176, -7.82844133],
                            [39.39858848, -7.82918931],
                            [39.39817521, -7.82974048],
                            [39.3978013, -7.83025226],
                            [39.39742738, -7.83064594],
                            [39.39707314, -7.83096087],
                            [39.39677795, -7.83131518],
                            [39.39638435, -7.83174823],
                            [39.39591204, -7.83214191],
                            [39.39551845, -7.83245686],
                            [39.39504613, -7.83283085],
                            [39.39473127, -7.83306706],
                            [39.39465254, -7.83334263],
                            [39.39465254, -7.83371664],
                            [39.39459351, -7.83407094],
                            [39.39435734, -7.83440558],
                            [39.39410151, -7.8346221],
                            [39.39380632, -7.83489767],
                            [39.39349144, -7.83499609],
                            [39.39315689, -7.83523229],
                            [39.39286169, -7.83538977],
                            [39.39266489, -7.83556693],
                            [39.39225162, -7.83586218],
                            [39.39183836, -7.83623617],
                            [39.39142508, -7.83651175],
                        ],
                        [
                            [39.32779674, -7.82842536],
                            [39.32781939, -7.8281534],
                            [39.32750219, -7.82794943],
                            [39.32720764, -7.82767747],
                            [39.3269584, -7.82740551],
                            [39.32668651, -7.82715621],
                            [39.3265279, -7.82681627],
                            [39.32630132, -7.82654432],
                            [39.32600677, -7.826363],
                            [39.32566689, -7.82613638],
                            [39.32541766, -7.82584176],
                            [39.32514576, -7.82563778],
                            [39.32476057, -7.82547914],
                            [39.32428476, -7.8252525],
                            [39.32401287, -7.82502587],
                            [39.3238316, -7.82461793],
                            [39.32376363, -7.82430065],
                            [39.32344641, -7.82409668],
                            [39.32303857, -7.82407401],
                            [39.32235883, -7.82393803],
                            [39.32154314, -7.82393803],
                            [39.32077277, -7.82391537],
                            [39.32009303, -7.82391536],
                            [39.31932265, -7.8238927],
                            [39.31884685, -7.8238927],
                            [39.318439, -7.82377938],
                            [39.31805382, -7.82366606],
                            [39.31769128, -7.82359808],
                            [39.31721547, -7.8235754],
                            [39.31685294, -7.8235754],
                            [39.31637712, -7.82357539],
                            [39.31599193, -7.82368871],
                            [39.31567472, -7.82382469],
                            [39.31545442, -7.82400497],
                            [39.31549079, -7.82401838],
                            [39.31549079, -7.82421021],
                            [39.31549079, -7.82437827],
                            [39.31552811, -7.82458369],
                            [39.31562147, -7.82480778],
                            [39.31588285, -7.82491981],
                            [39.31620023, -7.82495717],
                            [39.31644294, -7.82512523],
                            [39.31676031, -7.82542402],
                            [39.31709636, -7.82564809],
                            [39.31741375, -7.82583483],
                            [39.31769379, -7.82596556],
                            [39.31795517, -7.82590954],
                            [39.31832856, -7.82585351],
                            [39.31858994, -7.82576015],
                            [39.31875797, -7.8256481],
                            [39.31894466, -7.82555474],
                            [39.3192247, -7.82561077],
                            [39.31952342, -7.82577883],
                            [39.31974746, -7.82600292],
                            [39.31995282, -7.82628302],
                            [39.32028887, -7.82654446],
                            [39.32081162, -7.8267312],
                            [39.32114766, -7.82688059],
                            [39.32142771, -7.82695529],
                            [39.32178244, -7.82695529],
                            [39.32202514, -7.82678723],
                            [39.32234252, -7.82658182],
                            [39.32264125, -7.82650712],
                            [39.32293995, -7.82648845],
                            [39.32320133, -7.82663784],
                            [39.32342537, -7.8268059],
                            [39.32346271, -7.82704866],
                            [39.32355605, -7.8273101],
                            [39.32381743, -7.82745949],
                            [39.32411614, -7.82749684],
                            [39.32443353, -7.82764623],
                            [39.32460155, -7.82783297],
                            [39.32467622, -7.82813176],
                            [39.32484425, -7.82828115],
                            [39.32514297, -7.82833718],
                            [39.32534833, -7.82835584],
                            [39.32551636, -7.82846789],
                            [39.3257404, -7.8286733],
                            [39.32596443, -7.82884137],
                            [39.32624447, -7.82889739],
                            [39.32652452, -7.82902811],
                            [39.32689791, -7.82917749],
                            [39.32728997, -7.8291775],
                            [39.32760735, -7.82906546],
                            [39.32760735, -7.82903269],
                            [39.32761305, -7.82903796],
                            [39.32761548, -7.82903727],
                            [39.32775142, -7.82894661],
                            [39.32777408, -7.82871998],
                            [39.32779674, -7.82842536],
                        ],
                        [
                            [39.32700069, -7.82484446],
                            [39.32717776, -7.82469094],
                            [39.32731943, -7.82451382],
                            [39.32740208, -7.82433671],
                            [39.3274493, -7.82411234],
                            [39.32750833, -7.82386437],
                            [39.32750833, -7.8236164],
                            [39.32747291, -7.82338023],
                            [39.32737846, -7.82322672],
                            [39.32727222, -7.82310863],
                            [39.32702431, -7.82303779],
                            [39.32683541, -7.82309683],
                            [39.32669374, -7.82316767],
                            [39.32655207, -7.8232031],
                            [39.32645762, -7.82325033],
                            [39.32636319, -7.82330937],
                            [39.32625693, -7.82335661],
                            [39.32610347, -7.82341566],
                            [39.32587916, -7.82343927],
                            [39.32574929, -7.82345107],
                            [39.32559581, -7.82345107],
                            [39.32541874, -7.82345107],
                            [39.32526526, -7.82345106],
                            [39.3251236, -7.82347469],
                            [39.32500554, -7.82352192],
                            [39.32491109, -7.82359276],
                            [39.32482845, -7.82369905],
                            [39.32479303, -7.82384074],
                            [39.32482845, -7.82397063],
                            [39.32485206, -7.82410053],
                            [39.32494651, -7.82421861],
                            [39.32505275, -7.82432488],
                            [39.32518262, -7.82440754],
                            [39.32530068, -7.82445477],
                            [39.32541874, -7.82456105],
                            [39.3255368, -7.82470275],
                            [39.32569026, -7.82484445],
                            [39.32577289, -7.82496253],
                            [39.32585553, -7.82504519],
                            [39.3259972, -7.82513966],
                            [39.32615068, -7.82513966],
                            [39.32637498, -7.82506881],
                            [39.3266111, -7.82499797],
                            [39.32677637, -7.8249153],
                            [39.32687082, -7.82485625],
                            [39.32700069, -7.82484446],
                        ],
                        [
                            [39.35237444, -7.82336558],
                            [39.35252445, -7.82344893],
                            [39.35275777, -7.82351561],
                            [39.35294109, -7.82348228],
                            [39.35297443, -7.82334891],
                            [39.35297443, -7.82311554],
                            [39.35297444, -7.82296551],
                            [39.35282444, -7.82276547],
                            [39.3527411, -7.8225821],
                            [39.35262445, -7.82243207],
                            [39.35252446, -7.82224871],
                            [39.35237445, -7.82211534],
                            [39.35229112, -7.82198198],
                            [39.35217446, -7.82184861],
                            [39.35202446, -7.82171526],
                            [39.35192447, -7.82164858],
                            [39.35177447, -7.82164857],
                            [39.35162448, -7.82173193],
                            [39.35149115, -7.82188196],
                            [39.35142449, -7.82203199],
                            [39.35147448, -7.82219869],
                            [39.35159115, -7.82238205],
                            [39.35159115, -7.82256542],
                            [39.35149115, -7.82273212],
                            [39.35139115, -7.82289882],
                            [39.35144115, -7.82314887],
                            [39.3515578, -7.82334891],
                            [39.35167447, -7.82334891],
                            [39.35180779, -7.82331557],
                            [39.35194113, -7.82326556],
                            [39.3521078, -7.82324889],
                            [39.35225779, -7.82328224],
                            [39.35237444, -7.82336558],
                        ],
                        [
                            [39.35595767, -7.82526597],
                            [39.35626876, -7.82533265],
                            [39.35651321, -7.82524375],
                            [39.35680209, -7.82482144],
                            [39.3569132, -7.82439914],
                            [39.35711319, -7.82391016],
                            [39.35711319, -7.82342118],
                            [39.35720208, -7.82295442],
                            [39.35713541, -7.82250988],
                            [39.35695764, -7.82195422],
                            [39.35689099, -7.82153192],
                            [39.35664655, -7.82106516],
                            [39.35644656, -7.82050949],
                            [39.35626879, -7.82002051],
                            [39.35609101, -7.81944262],
                            [39.35589102, -7.81908699],
                            [39.35564658, -7.81879805],
                            [39.35544659, -7.81857577],
                            [39.35522438, -7.81837573],
                            [39.35506883, -7.8180201],
                            [39.35489107, -7.81770893],
                            [39.3547133, -7.81741999],
                            [39.35437997, -7.81721995],
                            [39.35422441, -7.81721995],
                            [39.3541133, -7.81726439],
                            [39.35404665, -7.81750889],
                            [39.35404665, -7.81777561],
                            [39.35397998, -7.81806456],
                            [39.35377999, -7.81837572],
                            [39.35357999, -7.81868691],
                            [39.35340222, -7.81888694],
                            [39.35324666, -7.81906476],
                            [39.35320222, -7.8193537],
                            [39.35320222, -7.81970931],
                            [39.35329111, -7.82026498],
                            [39.35344664, -7.82068729],
                            [39.35357998, -7.82102068],
                            [39.35375776, -7.82126518],
                            [39.35384662, -7.82168748],
                            [39.35397996, -7.82193198],
                            [39.3540244, -7.82230983],
                            [39.35406885, -7.82264322],
                            [39.35426884, -7.82282104],
                            [39.35457993, -7.82295441],
                            [39.35482438, -7.82302108],
                            [39.35509104, -7.82322112],
                            [39.35520214, -7.8235323],
                            [39.35535768, -7.82399906],
                            [39.35544657, -7.82442136],
                            [39.35560212, -7.82484367],
                            [39.35580211, -7.82513261],
                            [39.35595767, -7.82526597],
                        ],
                        [
                            [39.34870374, -7.81968152],
                            [39.34877874, -7.81989406],
                            [39.34889123, -7.82013161],
                            [39.34901623, -7.82031914],
                            [39.34914122, -7.82046917],
                            [39.34932871, -7.82069422],
                            [39.34947871, -7.82085675],
                            [39.34961622, -7.82100677],
                            [39.34971621, -7.8211443],
                            [39.34984121, -7.82126932],
                            [39.3499662, -7.82136934],
                            [39.3501287, -7.82136934],
                            [39.35024119, -7.82129433],
                            [39.35034119, -7.82115681],
                            [39.35037869, -7.82101928],
                            [39.35035369, -7.82084425],
                            [39.35029119, -7.82069422],
                            [39.35025369, -7.82053169],
                            [39.3501787, -7.82035666],
                            [39.3501537, -7.82014412],
                            [39.3500912, -7.81999409],
                            [39.3500537, -7.81984406],
                            [39.35001621, -7.81975654],
                            [39.34986621, -7.8195815],
                            [39.34975372, -7.81943147],
                            [39.34962872, -7.81926894],
                            [39.34949121, -7.81916892],
                            [39.34931622, -7.81914391],
                            [39.34916623, -7.81913141],
                            [39.34905373, -7.81913141],
                            [39.34892874, -7.81913141],
                            [39.34884124, -7.81918142],
                            [39.34875374, -7.81926893],
                            [39.34871624, -7.81941896],
                            [39.34866625, -7.81954398],
                            [39.34870374, -7.81968152],
                        ],
                        [
                            [39.36268672, -7.82035216],
                            [39.3628728, -7.82070373],
                            [39.36307956, -7.82091052],
                            [39.36326563, -7.82062101],
                            [39.36343103, -7.82026945],
                            [39.36367915, -7.81987653],
                            [39.36382388, -7.81940088],
                            [39.36382388, -7.81888389],
                            [39.36374117, -7.818553],
                            [39.36361713, -7.81826348],
                            [39.363307, -7.81799465],
                            [39.36303821, -7.81782921],
                            [39.36276944, -7.81768445],
                            [39.36243863, -7.81768445],
                            [39.36219053, -7.81770512],
                            [39.3619631, -7.81753968],
                            [39.36181838, -7.81714676],
                            [39.36169432, -7.81681587],
                            [39.36138419, -7.81654704],
                            [39.36111542, -7.81654704],
                            [39.36105144, -7.81654704],
                            [39.36105318, -7.81656214],
                            [39.36076394, -7.816485],
                            [39.36047448, -7.816485],
                            [39.36026772, -7.81631956],
                            [39.36008165, -7.81609208],
                            [39.35983355, -7.81592662],
                            [39.35958544, -7.81580255],
                            [39.3593787, -7.81588527],
                            [39.35919261, -7.81605071],
                            [39.35900654, -7.81627819],
                            [39.35894451, -7.81652634],
                            [39.35873775, -7.81669179],
                            [39.35853099, -7.81656771],
                            [39.35836559, -7.81642294],
                            [39.35836559, -7.81677451],
                            [39.35840695, -7.81708471],
                            [39.35867572, -7.81739491],
                            [39.35890314, -7.81770511],
                            [39.3591926, -7.81820143],
                            [39.35942003, -7.81849094],
                            [39.35966813, -7.81884251],
                            [39.35989555, -7.81919407],
                            [39.36016434, -7.81931815],
                            [39.36043312, -7.81935951],
                            [39.36068122, -7.81935951],
                            [39.36094999, -7.81942156],
                            [39.36105338, -7.81950428],
                            [39.36119811, -7.81973176],
                            [39.36135651, -7.81994961],
                            [39.36135651, -7.8200438],
                            [39.3613436, -7.82036699],
                            [39.36142553, -7.82037283],
                            [39.36171499, -7.82024876],
                            [39.36194242, -7.82012468],
                            [39.36219052, -7.82006264],
                            [39.36243862, -7.820104],
                            [39.36268672, -7.82035216],
                        ],
                        [
                            [39.3650777, -7.81617047],
                            [39.36482823, -7.81592094],
                            [39.36456318, -7.81581178],
                            [39.36440726, -7.81581178],
                            [39.36420457, -7.81587415],
                            [39.36411101, -7.81609248],
                            [39.36415779, -7.81635761],
                            [39.36429811, -7.81656034],
                            [39.3645164, -7.81679427],
                            [39.36475028, -7.8170594],
                            [39.364875, -7.8173713],
                            [39.3650777, -7.81766761],
                            [39.36517124, -7.81794833],
                            [39.36535834, -7.81810429],
                            [39.36565459, -7.81821345],
                            [39.36590405, -7.81821345],
                            [39.36626266, -7.81816666],
                            [39.36649655, -7.8180419],
                            [39.36679278, -7.81783916],
                            [39.36702667, -7.81783917],
                            [39.36718258, -7.8180575],
                            [39.3673229, -7.81835381],
                            [39.36747882, -7.8187125],
                            [39.36775948, -7.81877489],
                            [39.36810249, -7.8187125],
                            [39.36822723, -7.8184162],
                            [39.36832079, -7.8181043],
                            [39.3682896, -7.8177612],
                            [39.3681025, -7.81749607],
                            [39.36800894, -7.81718417],
                            [39.36802454, -7.8167475],
                            [39.3681181, -7.81629524],
                            [39.3681181, -7.81601453],
                            [39.36814929, -7.81573381],
                            [39.3683052, -7.815765],
                            [39.36857025, -7.81590536],
                            [39.36877295, -7.81606131],
                            [39.36897564, -7.81626405],
                            [39.36920952, -7.81663834],
                            [39.36945898, -7.81695024],
                            [39.36964608, -7.81719978],
                            [39.36983318, -7.81757407],
                            [39.37000469, -7.81793276],
                            [39.37019179, -7.81830705],
                            [39.37031652, -7.81865014],
                            [39.37045684, -7.81889966],
                            [39.37069072, -7.8191024],
                            [39.37090901, -7.81918038],
                            [39.3712988, -7.81916479],
                            [39.37184452, -7.81919598],
                            [39.37226548, -7.8191492],
                            [39.37257732, -7.81900884],
                            [39.37268647, -7.81877491],
                            [39.37268647, -7.8184786],
                            [39.37260851, -7.81821348],
                            [39.37234345, -7.81804193],
                            [39.37207839, -7.81787038],
                            [39.37192247, -7.81760527],
                            [39.3718757, -7.81719979],
                            [39.37196926, -7.81666955],
                            [39.3718757, -7.81599895],
                            [39.37173538, -7.81554668],
                            [39.37150152, -7.81515679],
                            [39.37122086, -7.8148293],
                            [39.37092462, -7.81457978],
                            [39.37056601, -7.81436144],
                            [39.37012943, -7.81411191],
                            [39.36977082, -7.81386239],
                            [39.3694434, -7.81361286],
                            [39.36911598, -7.8134881],
                            [39.36875737, -7.81330095],
                            [39.36824284, -7.81302023],
                            [39.36772832, -7.8128019],
                            [39.3671982, -7.81256797],
                            [39.36677721, -7.81233403],
                            [39.36626269, -7.81210011],
                            [39.36596645, -7.81183498],
                            [39.36571698, -7.81156987],
                            [39.36556106, -7.81113319],
                            [39.36546752, -7.81049379],
                            [39.36537397, -7.80983878],
                            [39.36531161, -7.80940212],
                            [39.36510892, -7.80901224],
                            [39.36481267, -7.80865354],
                            [39.36442289, -7.80838843],
                            [39.36414223, -7.80818569],
                            [39.36389277, -7.80798295],
                            [39.36359652, -7.80774901],
                            [39.36326909, -7.8074839],
                            [39.36312651, -7.8073435],
                            [39.36278391, -7.80715502],
                            [39.36261262, -7.80693229],
                            [39.36240707, -7.80657247],
                            [39.36223576, -7.80624694],
                            [39.36197882, -7.80598994],
                            [39.361739, -7.80581861],
                            [39.36137928, -7.80576719],
                            [39.36108808, -7.80576719],
                            [39.36072836, -7.80578434],
                            [39.36048855, -7.80581861],
                            [39.360283, -7.80585286],
                            [39.36026055, -7.80584735],
                            [39.36026044, -7.80585008],
                            [39.36006102, -7.80595887],
                            [39.35979224, -7.80604158],
                            [39.35958548, -7.80606227],
                            [39.35931671, -7.8061243],
                            [39.35923401, -7.80643451],
                            [39.35939941, -7.8066413],
                            [39.35966818, -7.80672402],
                            [39.35970953, -7.80684811],
                            [39.3595648, -7.80691015],
                            [39.35925468, -7.80684811],
                            [39.35904792, -7.8068481],
                            [39.35888252, -7.80701354],
                            [39.35875846, -7.80722034],
                            [39.35861373, -7.80709626],
                            [39.35853103, -7.80691015],
                            [39.35832429, -7.80672402],
                            [39.35809686, -7.8065379],
                            [39.35778674, -7.80651723],
                            [39.35760065, -7.80668266],
                            [39.35760065, -7.80691014],
                            [39.35762132, -7.80726169],
                            [39.35749728, -7.80732373],
                            [39.35720782, -7.80732373],
                            [39.35695972, -7.80732373],
                            [39.3567116, -7.8073651],
                            [39.35631877, -7.80738577],
                            [39.35598798, -7.80719966],
                            [39.35553312, -7.80693081],
                            [39.35520231, -7.806724],
                            [39.35485084, -7.80664128],
                            [39.35447868, -7.80670332],
                            [39.35404449, -7.80680672],
                            [39.35389976, -7.80695148],
                            [39.35389976, -7.80711693],
                            [39.35392043, -7.80736509],
                            [39.35392043, -7.80771665],
                            [39.35387909, -7.80800616],
                            [39.3537757, -7.80833705],
                            [39.35354827, -7.80881268],
                            [39.35348624, -7.80914355],
                            [39.35350692, -7.80939173],
                            [39.35369299, -7.80955716],
                            [39.35400312, -7.80959852],
                            [39.35429258, -7.8095158],
                            [39.35447867, -7.80941241],
                            [39.35460271, -7.80963989],
                            [39.35443731, -7.809826],
                            [39.35412718, -7.80995008],
                            [39.35387908, -7.81019824],
                            [39.35375502, -7.81046708],
                            [39.35361029, -7.81067389],
                            [39.35367232, -7.81090137],
                            [39.35398245, -7.81114952],
                            [39.35445797, -7.81125292],
                            [39.35493352, -7.81129429],
                            [39.35522297, -7.81131497],
                            [39.35528499, -7.81152177],
                            [39.35528498, -7.81183197],
                            [39.35528498, -7.81247306],
                            [39.35532634, -7.81292802],
                            [39.35551241, -7.8131555],
                            [39.35580186, -7.81334161],
                            [39.35623605, -7.81336229],
                            [39.35648415, -7.81313481],
                            [39.35648415, -7.81270054],
                            [39.35650484, -7.81222489],
                            [39.35650484, -7.81168721],
                            [39.35667024, -7.81123225],
                            [39.35683565, -7.81096342],
                            [39.35716644, -7.81096342],
                            [39.35733184, -7.81110817],
                            [39.35741456, -7.81154245],
                            [39.35743523, -7.81187334],
                            [39.35766265, -7.81214218],
                            [39.35786941, -7.81241102],
                            [39.35797278, -7.8126385],
                            [39.35803481, -7.81299005],
                            [39.35822088, -7.81330027],
                            [39.3586137, -7.8136725],
                            [39.35886182, -7.81394134],
                            [39.35931667, -7.81421019],
                            [39.3597922, -7.81447903],
                            [39.36010233, -7.81462379],
                            [39.36030909, -7.8144997],
                            [39.36030946, -7.81449911],
                            [39.36025857, -7.81429549],
                            [39.36049374, -7.81426782],
                            [39.36078998, -7.81426782],
                            [39.36103945, -7.81425222],
                            [39.36127332, -7.81425222],
                            [39.36152279, -7.81417426],
                            [39.36170989, -7.81420545],
                            [39.36181903, -7.8143926],
                            [39.36205291, -7.81462652],
                            [39.36220882, -7.81479806],
                            [39.36253625, -7.81486045],
                            [39.36270776, -7.81468891],
                            [39.36275454, -7.8143926],
                            [39.362661, -7.81414307],
                            [39.36247389, -7.81390913],
                            [39.36241152, -7.81365962],
                            [39.36261422, -7.81348807],
                            [39.36289486, -7.81339449],
                            [39.36308197, -7.81331653],
                            [39.36345617, -7.81334772],
                            [39.36378359, -7.81347248],
                            [39.36412661, -7.81356605],
                            [39.36450081, -7.81369082],
                            [39.36481265, -7.81367523],
                            [39.36515566, -7.81369082],
                            [39.36551427, -7.81370642],
                            [39.36574816, -7.813722],
                            [39.36596644, -7.81383118],
                            [39.36623149, -7.81409629],
                            [39.36643419, -7.81429904],
                            [39.36662129, -7.81453297],
                            [39.36662129, -7.81468892],
                            [39.36646537, -7.81472011],
                            [39.36646537, -7.81493845],
                            [39.36648096, -7.81518797],
                            [39.36635623, -7.8154063],
                            [39.36618473, -7.81548429],
                            [39.36605998, -7.81559345],
                            [39.36581052, -7.8157182],
                            [39.36568577, -7.81584296],
                            [39.36568577, -7.8160457],
                            [39.36563901, -7.81632642],
                            [39.36552986, -7.81656034],
                            [39.3652804, -7.81641999],
                            [39.3650777, -7.81617047],
                        ],
                        [
                            [39.24424883, -7.81267416],
                            [39.24441278, -7.81251019],
                            [39.24446059, -7.81240088],
                            [39.24441278, -7.8122984],
                            [39.2443513, -7.81226424],
                            [39.24424201, -7.81214127],
                            [39.24409175, -7.81203878],
                            [39.24404392, -7.8119158],
                            [39.24400979, -7.81175183],
                            [39.24398929, -7.81159469],
                            [39.24396196, -7.81147172],
                            [39.24398929, -7.81127359],
                            [39.24402344, -7.81104131],
                            [39.24404393, -7.81079536],
                            [39.24403027, -7.81059723],
                            [39.24398929, -7.81041276],
                            [39.24388, -7.81027612],
                            [39.24375022, -7.81023513],
                            [39.24362728, -7.81023513],
                            [39.24352483, -7.81028978],
                            [39.24337455, -7.81039226],
                            [39.24328576, -7.81052207],
                            [39.24326526, -7.8106587],
                            [39.2432311, -7.81083635],
                            [39.24319696, -7.81095932],
                            [39.24310815, -7.81106179],
                            [39.2429852, -7.81115745],
                            [39.24284177, -7.81128043],
                            [39.24271882, -7.81132825],
                            [39.2426027, -7.81141706],
                            [39.24249341, -7.81149222],
                            [39.24243877, -7.81165618],
                            [39.24241829, -7.81180649],
                            [39.24242511, -7.81192947],
                            [39.24241144, -7.81207976],
                            [39.24238413, -7.8122574],
                            [39.24236363, -7.81238038],
                            [39.2423363, -7.81245552],
                            [39.24228166, -7.81254435],
                            [39.2421487, -7.81268886],
                            [39.24202894, -7.81285861],
                            [39.24191964, -7.81300892],
                            [39.24183768, -7.81316606],
                            [39.24173523, -7.81330952],
                            [39.24167375, -7.81348033],
                            [39.24167374, -7.81369212],
                            [39.2416767, -7.81370734],
                            [39.24172155, -7.81393807],
                            [39.24187866, -7.81415669],
                            [39.24206309, -7.81426601],
                            [39.24224067, -7.81427284],
                            [39.24237729, -7.81422502],
                            [39.24252755, -7.81413621],
                            [39.24257536, -7.81402006],
                            [39.24258279, -7.814008],
                            [39.24263002, -7.81393125],
                            [39.24274614, -7.8138151],
                            [39.24286224, -7.81367847],
                            [39.24303985, -7.81347349],
                            [39.24323793, -7.81327538],
                            [39.24344285, -7.81308408],
                            [39.24358628, -7.81298159],
                            [39.24385951, -7.8128313],
                            [39.24406442, -7.81273564],
                            [39.24424883, -7.81267416],
                        ],
                        [
                            [39.27539352, -7.80912077],
                            [39.27541555, -7.80882715],
                            [39.2756357, -7.80861429],
                            [39.27595127, -7.80845279],
                            [39.27617877, -7.80832067],
                            [39.27638425, -7.80812983],
                            [39.27643562, -7.80805642],
                            [39.27610539, -7.80777015],
                            [39.27592925, -7.80764536],
                            [39.27569442, -7.80750588],
                            [39.27550362, -7.80739578],
                            [39.27527611, -7.80724897],
                            [39.27521741, -7.80717557],
                            [39.27511466, -7.80710951],
                            [39.27500459, -7.80710216],
                            [39.27486514, -7.80715354],
                            [39.27471104, -7.80709483],
                            [39.27456426, -7.80699939],
                            [39.27436612, -7.80688195],
                            [39.27421934, -7.80680854],
                            [39.27399918, -7.80672781],
                            [39.27378636, -7.80664706],
                            [39.27364693, -7.80655898],
                            [39.27349281, -7.80650759],
                            [39.27336072, -7.80648557],
                            [39.27322862, -7.80648557],
                            [39.27308918, -7.80650759],
                            [39.2730158, -7.8065663],
                            [39.27294975, -7.80664705],
                            [39.27293506, -7.80678652],
                            [39.27300845, -7.80695534],
                            [39.27302314, -7.80713886],
                            [39.27311854, -7.80731502],
                            [39.27321394, -7.80749854],
                            [39.27331669, -7.80766737],
                            [39.27333136, -7.80780683],
                            [39.27329467, -7.80804172],
                            [39.27331668, -7.80829864],
                            [39.27327264, -7.80838672],
                            [39.27330934, -7.80882715],
                            [39.27333135, -7.8092015],
                            [39.27340474, -7.80938502],
                            [39.27348547, -7.80952448],
                            [39.27365426, -7.80970064],
                            [39.27383772, -7.80986214],
                            [39.27400651, -7.80985479],
                            [39.27423402, -7.80964927],
                            [39.27428537, -7.80948044],
                            [39.27446151, -7.80937033],
                            [39.27470369, -7.80934097],
                            [39.27497522, -7.80943639],
                            [39.27524676, -7.80940704],
                            [39.27539352, -7.80912077],
                        ],
                        [
                            [39.38865544, -7.80680816],
                            [39.38856985, -7.80667362],
                            [39.38848424, -7.80658801],
                            [39.38832528, -7.80645347],
                            [39.38819077, -7.80629445],
                            [39.38806848, -7.80617213],
                            [39.38788505, -7.80608652],
                            [39.38778722, -7.80600091],
                            [39.38773937, -7.80595647],
                            [39.38761601, -7.8058419],
                            [39.3874815, -7.80567065],
                            [39.38740815, -7.80543826],
                            [39.38739591, -7.80526702],
                            [39.38728586, -7.80497348],
                            [39.38726141, -7.80477778],
                            [39.38718803, -7.80460654],
                            [39.38710243, -7.80436192],
                            [39.38705353, -7.80416621],
                            [39.38695569, -7.80400719],
                            [39.38679672, -7.80383597],
                            [39.38664998, -7.80367696],
                            [39.38655216, -7.80356688],
                            [39.38636873, -7.80340788],
                            [39.38613639, -7.80328556],
                            [39.38596518, -7.80323664],
                            [39.38570838, -7.8031021],
                            [39.38550051, -7.80305317],
                            [39.38520703, -7.80293085],
                            [39.38503583, -7.80280854],
                            [39.3848524, -7.80268622],
                            [39.38468119, -7.80247831],
                            [39.3845467, -7.80230705],
                            [39.38444886, -7.80209913],
                            [39.38431435, -7.8018912],
                            [39.38419206, -7.8016955],
                            [39.38408201, -7.80154872],
                            [39.38395972, -7.80143864],
                            [39.38381298, -7.80129186],
                            [39.3836418, -7.80109615],
                            [39.38345835, -7.80090046],
                            [39.38331161, -7.80072921],
                            [39.38321379, -7.80057021],
                            [39.38309151, -7.80044791],
                            [39.38298145, -7.80044791],
                            [39.38287139, -7.80044791],
                            [39.38279802, -7.80052128],
                            [39.38281024, -7.80070477],
                            [39.38289586, -7.80087598],
                            [39.382957, -7.801035],
                            [39.38307927, -7.80123069],
                            [39.38322602, -7.8014631],
                            [39.38337276, -7.80163433],
                            [39.38353174, -7.80175664],
                            [39.38366623, -7.80192789],
                            [39.38377631, -7.80205019],
                            [39.38387413, -7.80220921],
                            [39.38400864, -7.80235598],
                            [39.38414315, -7.80251498],
                            [39.38426544, -7.80271069],
                            [39.38438771, -7.80289415],
                            [39.38447332, -7.80308986],
                            [39.38463228, -7.80327331],
                            [39.38480348, -7.80344456],
                            [39.38495022, -7.80360358],
                            [39.38512142, -7.80383597],
                            [39.38525594, -7.80401942],
                            [39.38536598, -7.80419067],
                            [39.3855861, -7.8044353],
                            [39.38576953, -7.80469214],
                            [39.38590404, -7.80487563],
                            [39.38603856, -7.80503461],
                            [39.38618531, -7.80519363],
                            [39.38634425, -7.80534041],
                            [39.38647878, -7.8055361],
                            [39.38660105, -7.80568288],
                            [39.38676003, -7.80585413],
                            [39.38690677, -7.80601314],
                            [39.38706575, -7.8061966],
                            [39.38720025, -7.80633114],
                            [39.38735519, -7.806464],
                            [39.38737144, -7.80647792],
                            [39.38754265, -7.8066247],
                            [39.38771383, -7.80680815],
                            [39.38788504, -7.80697941],
                            [39.38805626, -7.80711395],
                            [39.3882152, -7.80726073],
                            [39.38833749, -7.80735857],
                            [39.388472, -7.80741972],
                            [39.38864321, -7.80745642],
                            [39.38875326, -7.80739527],
                            [39.38877773, -7.80726073],
                            [39.38877773, -7.80711395],
                            [39.38876549, -7.80695494],
                            [39.38865544, -7.80680816],
                        ],
                        [
                            [39.38980957, -7.80498534],
                            [39.38954338, -7.80482053],
                            [39.38928987, -7.80463035],
                            [39.38896031, -7.80432607],
                            [39.38880641, -7.80412686],
                            [39.38852934, -7.80376822],
                            [39.388504, -7.80345127],
                            [39.38854203, -7.80322305],
                            [39.38851668, -7.80304555],
                            [39.38836457, -7.8028427],
                            [39.3881491, -7.80258914],
                            [39.38795897, -7.80239896],
                            [39.38776883, -7.80206932],
                            [39.38748997, -7.80163826],
                            [39.38714774, -7.80115649],
                            [39.38689423, -7.80081418],
                            [39.386552, -7.80034507],
                            [39.38627314, -7.80006615],
                            [39.38601963, -7.79977454],
                            [39.38580414, -7.79957169],
                            [39.38557599, -7.79954633],
                            [39.38544923, -7.7997365],
                            [39.38534783, -7.79997739],
                            [39.3853605, -7.80025632],
                            [39.38537317, -7.80045917],
                            [39.38543656, -7.80071273],
                            [39.38565203, -7.80100433],
                            [39.38594357, -7.80133397],
                            [39.38627313, -7.80167629],
                            [39.38657733, -7.80203129],
                            [39.38674212, -7.80231021],
                            [39.38702097, -7.80260181],
                            [39.3872111, -7.80304555],
                            [39.38748996, -7.8034893],
                            [39.3878322, -7.80397107],
                            [39.38806036, -7.8043134],
                            [39.38830119, -7.80455428],
                            [39.38839494, -7.80467306],
                            [39.38849133, -7.80479516],
                            [39.38866877, -7.80516284],
                            [39.38877017, -7.80545444],
                            [39.389011, -7.80566996],
                            [39.38934057, -7.80568266],
                            [39.38961943, -7.80564462],
                            [39.38986027, -7.80558123],
                            [39.3901011, -7.80549248],
                            [39.3901011, -7.80526427],
                            [39.38980957, -7.80498534],
                        ],
                        [
                            [39.39476917, -7.80230252],
                            [39.39463142, -7.80204661],
                            [39.39463142, -7.80177104],
                            [39.39471014, -7.80151515],
                            [39.39492662, -7.80141673],
                            [39.39496598, -7.80116085],
                            [39.39486759, -7.80086558],
                            [39.39472981, -7.80064905],
                            [39.39471015, -7.80033412],
                            [39.39472982, -7.80003885],
                            [39.39471015, -7.79978297],
                            [39.39449367, -7.79954676],
                            [39.39423784, -7.79917275],
                            [39.39408041, -7.79889718],
                            [39.3940017, -7.79844444],
                            [39.39408041, -7.79807045],
                            [39.39435592, -7.79781454],
                            [39.3945724, -7.79746024],
                            [39.39469047, -7.79708625],
                            [39.39480855, -7.79673194],
                            [39.39480855, -7.7963973],
                            [39.39461176, -7.79606269],
                            [39.3943953, -7.79582647],
                            [39.39417881, -7.79553119],
                            [39.39390331, -7.79521626],
                            [39.39374586, -7.794921],
                            [39.3935294, -7.79460604],
                            [39.39339168, -7.7944365],
                            [39.39327356, -7.79429112],
                            [39.39309645, -7.79399584],
                            [39.39280125, -7.79379901],
                            [39.39252574, -7.7935431],
                            [39.39228958, -7.7933069],
                            [39.39211248, -7.79312974],
                            [39.39189599, -7.79293291],
                            [39.39165984, -7.79283448],
                            [39.3915024, -7.79291321],
                            [39.39148271, -7.79309037],
                            [39.39148271, -7.7933069],
                            [39.39136463, -7.79344469],
                            [39.39106944, -7.79354309],
                            [39.39077425, -7.79354309],
                            [39.39051842, -7.79344468],
                            [39.39018387, -7.79332658],
                            [39.38996739, -7.79326752],
                            [39.38975092, -7.79314941],
                            [39.38939668, -7.79301163],
                            [39.38906213, -7.7927951],
                            [39.38876693, -7.79259825],
                            [39.38858983, -7.79234238],
                            [39.38845207, -7.79196837],
                            [39.38835368, -7.79173215],
                            [39.38815688, -7.79147628],
                            [39.38797976, -7.791181],
                            [39.38764521, -7.79090543],
                            [39.38725161, -7.79053144],
                            [39.38685801, -7.79025586],
                            [39.38666123, -7.79009839],
                            [39.38640539, -7.7899606],
                            [39.38605117, -7.78980311],
                            [39.38569694, -7.78968501],
                            [39.38536237, -7.78948816],
                            [39.3850475, -7.78927163],
                            [39.38473263, -7.78895668],
                            [39.3844768, -7.78872048],
                            [39.38410288, -7.78850396],
                            [39.3837093, -7.78828743],
                            [39.38339443, -7.78811029],
                            [39.38280404, -7.78785438],
                            [39.38252852, -7.7877166],
                            [39.38227269, -7.78748038],
                            [39.38207589, -7.78730322],
                            [39.38150519, -7.78681113],
                            [39.38120999, -7.78667332],
                            [39.38079672, -7.78637808],
                            [39.38048186, -7.78614186],
                            [39.38020634, -7.78592534],
                            [39.37993082, -7.78564975],
                            [39.37975371, -7.78541355],
                            [39.37953725, -7.78513797],
                            [39.37926173, -7.78492144],
                            [39.37904525, -7.78468523],
                            [39.37880909, -7.78456712],
                            [39.37859261, -7.78452776],
                            [39.37845485, -7.78464586],
                            [39.37863197, -7.7850986],
                            [39.37890747, -7.78551196],
                            [39.37914365, -7.78574816],
                            [39.37951756, -7.78610249],
                            [39.37973403, -7.78633871],
                            [39.37993082, -7.78657491],
                            [39.38004891, -7.7868308],
                            [39.38042281, -7.78738195],
                            [39.38058024, -7.78763786],
                            [39.38087545, -7.78795279],
                            [39.38122967, -7.78832679],
                            [39.38150519, -7.78866143],
                            [39.38168229, -7.78889763],
                            [39.38183973, -7.78913384],
                            [39.38209557, -7.78942909],
                            [39.3823514, -7.78978341],
                            [39.3827253, -7.79017711],
                            [39.38305985, -7.7905511],
                            [39.38337474, -7.79086603],
                            [39.38351249, -7.79100384],
                            [39.38378801, -7.79127941],
                            [39.38404384, -7.79157467],
                            [39.38412255, -7.79175183],
                            [39.38412255, -7.79194867],
                            [39.38414224, -7.79222424],
                            [39.38422095, -7.79249982],
                            [39.38431935, -7.79267697],
                            [39.38449646, -7.79289351],
                            [39.38463423, -7.79303128],
                            [39.38463423, -7.7932675],
                            [39.38463423, -7.7936218],
                            [39.38459487, -7.79393675],
                            [39.38459487, -7.79423202],
                            [39.38459487, -7.79446822],
                            [39.38465389, -7.79470444],
                            [39.38479165, -7.7949997],
                            [39.38500811, -7.79521622],
                            [39.3852246, -7.79553116],
                            [39.38536235, -7.79570833],
                            [39.38548042, -7.7959839],
                            [39.38575594, -7.79631852],
                            [39.38595273, -7.79643663],
                            [39.38618889, -7.79665317],
                            [39.38640536, -7.79686968],
                            [39.38650376, -7.79704685],
                            [39.38672024, -7.79728305],
                            [39.38703512, -7.79765704],
                            [39.38738935, -7.79791295],
                            [39.38770421, -7.79801136],
                            [39.38801909, -7.79805073],
                            [39.3882946, -7.79805073],
                            [39.38866852, -7.79801137],
                            [39.38871894, -7.79803628],
                            [39.38904243, -7.79773579],
                            [39.38925892, -7.79795233],
                            [39.38945571, -7.79830663],
                            [39.38977058, -7.79870031],
                            [39.39008544, -7.79905463],
                            [39.39040032, -7.79940895],
                            [39.39057097, -7.79955526],
                            [39.39067584, -7.79964515],
                            [39.39091198, -7.79992073],
                            [39.39126622, -7.80011757],
                            [39.39165981, -7.80035378],
                            [39.3920337, -7.80060968],
                            [39.39238795, -7.8009443],
                            [39.39264379, -7.80116084],
                            [39.39287993, -7.80145609],
                            [39.39311609, -7.80179073],
                            [39.39329321, -7.80212535],
                            [39.39352937, -7.80240092],
                            [39.39372616, -7.80265683],
                            [39.39400168, -7.80297176],
                            [39.39467078, -7.80326704],
                            [39.39496597, -7.80326704],
                            [39.39518244, -7.80318831],
                            [39.3952218, -7.80297177],
                            [39.3951234, -7.80271588],
                            [39.39496597, -7.80253874],
                            [39.39476917, -7.80230252],
                        ],
                        [
                            [39.36132051, -7.79810965],
                            [39.36155487, -7.79805103],
                            [39.36178922, -7.79805103],
                            [39.3622775, -7.79805103],
                            [39.36266811, -7.79805103],
                            [39.36303919, -7.79807057],
                            [39.36327355, -7.79807057],
                            [39.36354698, -7.7980315],
                            [39.36364463, -7.79787524],
                            [39.36366417, -7.79766034],
                            [39.36366417, -7.79734778],
                            [39.36366417, -7.79699614],
                            [39.36366417, -7.79670313],
                            [39.36382043, -7.79658592],
                            [39.36419151, -7.79641009],
                            [39.36438681, -7.79627335],
                            [39.36440633, -7.79611707],
                            [39.36417197, -7.79588265],
                            [39.36395715, -7.79576544],
                            [39.36364464, -7.79570682],
                            [39.36344934, -7.79555055],
                            [39.36342981, -7.79533566],
                            [39.36352746, -7.79521845],
                            [39.36364465, -7.79514032],
                            [39.3638595, -7.79510124],
                            [39.36413292, -7.79504265],
                            [39.36444541, -7.79510124],
                            [39.36473837, -7.79514033],
                            [39.36485555, -7.79492544],
                            [39.36505086, -7.79476917],
                            [39.36530477, -7.7945738],
                            [39.365461, -7.7942808],
                            [39.36548053, -7.79400729],
                            [39.36555867, -7.79377287],
                            [39.36555867, -7.79349939],
                            [39.36544147, -7.79324543],
                            [39.36528525, -7.79299148],
                            [39.36510946, -7.79271797],
                            [39.36510946, -7.79244448],
                            [39.36510946, -7.79211239],
                            [39.36512899, -7.79179983],
                            [39.36530478, -7.79154588],
                            [39.36542197, -7.79127238],
                            [39.36542197, -7.79103796],
                            [39.36542197, -7.79080354],
                            [39.36542197, -7.79054959],
                            [39.36536336, -7.79031517],
                            [39.36522665, -7.79017842],
                            [39.36497275, -7.79013935],
                            [39.36477747, -7.79019794],
                            [39.36475792, -7.79043237],
                            [39.3646798, -7.79054958],
                            [39.36460167, -7.79074493],
                            [39.36446496, -7.79082309],
                            [39.36425012, -7.790784],
                            [39.3640548, -7.79080353],
                            [39.3640548, -7.79119425],
                            [39.36403527, -7.79135053],
                            [39.36389856, -7.79137005],
                            [39.36370327, -7.79125284],
                            [39.36354701, -7.79103795],
                            [39.36339077, -7.79088167],
                            [39.36323454, -7.79074493],
                            [39.36307828, -7.79068632],
                            [39.36290251, -7.79068632],
                            [39.36268767, -7.79068632],
                            [39.36268767, -7.79066678],
                            [39.36241424, -7.79088167],
                            [39.36221894, -7.79094029],
                            [39.36204316, -7.79109655],
                            [39.3618869, -7.79127236],
                            [39.36173066, -7.79146773],
                            [39.36151582, -7.79164352],
                            [39.36134005, -7.7917412],
                            [39.36126193, -7.7919561],
                            [39.36112521, -7.79209284],
                            [39.36094942, -7.79221005],
                            [39.36075413, -7.79226865],
                            [39.36050023, -7.79232726],
                            [39.3602854, -7.79232725],
                            [39.36016822, -7.79224913],
                            [39.36001197, -7.79209284],
                            [39.35991432, -7.79197563],
                            [39.35979714, -7.79197562],
                            [39.3596409, -7.79197562],
                            [39.35952371, -7.79197562],
                            [39.35940653, -7.79203423],
                            [39.35936745, -7.79222958],
                            [39.35940653, -7.79250308],
                            [39.35938698, -7.7927375],
                            [39.35934792, -7.7929133],
                            [39.3592698, -7.79310866],
                            [39.3591526, -7.79324541],
                            [39.3590159, -7.79334308],
                            [39.35887918, -7.79351889],
                            [39.358762, -7.79369471],
                            [39.358762, -7.79390959],
                            [39.35870341, -7.7940268],
                            [39.35848857, -7.7940854],
                            [39.35823468, -7.79410494],
                            [39.35803936, -7.79414401],
                            [39.35780499, -7.79420261],
                            [39.35757062, -7.79433935],
                            [39.35737532, -7.79443703],
                            [39.35716048, -7.79461284],
                            [39.3570433, -7.79478867],
                            [39.35690658, -7.79496447],
                            [39.35675032, -7.79508167],
                            [39.35659408, -7.79521842],
                            [39.35643785, -7.79535518],
                            [39.356223, -7.79551145],
                            [39.35612535, -7.79576541],
                            [39.35612535, -7.79592169],
                            [39.35618395, -7.79613657],
                            [39.35632066, -7.79635145],
                            [39.35655503, -7.79652727],
                            [39.35682846, -7.79676169],
                            [39.35704329, -7.79693752],
                            [39.35723858, -7.7971524],
                            [39.35745343, -7.79740636],
                            [39.35766826, -7.79764078],
                            [39.35784404, -7.79785566],
                            [39.35803934, -7.79814869],
                            [39.35825419, -7.79834405],
                            [39.35841042, -7.79851987],
                            [39.35854713, -7.79869568],
                            [39.35876198, -7.79883243],
                            [39.35899635, -7.79883243],
                            [39.35907447, -7.79861754],
                            [39.35907447, -7.79844171],
                            [39.35907447, -7.7982073],
                            [39.35907447, -7.79793381],
                            [39.35905494, -7.79771892],
                            [39.35899635, -7.79750406],
                            [39.35887917, -7.79728916],
                            [39.35874246, -7.79709381],
                            [39.35866432, -7.79691799],
                            [39.35856668, -7.79672264],
                            [39.35846903, -7.79656636],
                            [39.35833232, -7.79641007],
                            [39.3582542, -7.7961952],
                            [39.35815653, -7.7960389],
                            [39.35807841, -7.79590216],
                            [39.3580003, -7.79578495],
                            [39.35798077, -7.79557007],
                            [39.35801982, -7.79535518],
                            [39.35823467, -7.79529658],
                            [39.35854714, -7.79533563],
                            [39.35891822, -7.79545285],
                            [39.35917213, -7.79562868],
                            [39.35938697, -7.79580449],
                            [39.35954323, -7.79599984],
                            [39.35962133, -7.79623426],
                            [39.35969945, -7.79646869],
                            [39.35981663, -7.79666404],
                            [39.35985569, -7.79685939],
                            [39.35983616, -7.79709381],
                            [39.35975804, -7.79738683],
                            [39.35973851, -7.79773846],
                            [39.35975804, -7.79803148],
                            [39.35983616, -7.79832451],
                            [39.3599924, -7.79867614],
                            [39.36024631, -7.79900825],
                            [39.36048068, -7.7990473],
                            [39.36059786, -7.79898871],
                            [39.3608127, -7.79883243],
                            [39.36098847, -7.79863709],
                            [39.36104706, -7.79840265],
                            [39.36118378, -7.79824639],
                            [39.36132051, -7.79810965],
                        ],
                        [
                            [39.3363989, -7.79528705],
                            [39.33650556, -7.79516259],
                            [39.33666556, -7.79510925],
                            [39.33680777, -7.79500256],
                            [39.3369322, -7.79487808],
                            [39.33698555, -7.79477139],
                            [39.33719887, -7.79471806],
                            [39.33730553, -7.7945936],
                            [39.33723443, -7.79446912],
                            [39.33718109, -7.7942913],
                            [39.33734108, -7.79414906],
                            [39.33753664, -7.79413128],
                            [39.33773218, -7.79413128],
                            [39.33794551, -7.79414906],
                            [39.33817663, -7.79429131],
                            [39.33828329, -7.79438022],
                            [39.33847883, -7.79455805],
                            [39.33872771, -7.79480699],
                            [39.3388166, -7.79503812],
                            [39.33883438, -7.79523372],
                            [39.33890548, -7.7954471],
                            [39.33902993, -7.79567824],
                            [39.33922547, -7.79585607],
                            [39.33938548, -7.79598053],
                            [39.33954547, -7.7961228],
                            [39.33975877, -7.79608723],
                            [39.33995433, -7.79592718],
                            [39.34013211, -7.79574938],
                            [39.34032766, -7.79567825],
                            [39.34048765, -7.79558936],
                            [39.34068321, -7.79551823],
                            [39.3408432, -7.79544711],
                            [39.34109208, -7.79542931],
                            [39.3412343, -7.7952693],
                            [39.34125208, -7.79503813],
                            [39.34134097, -7.79491367],
                            [39.34144764, -7.79473586],
                            [39.34144764, -7.79454026],
                            [39.34150096, -7.79429132],
                            [39.34150096, -7.79400683],
                            [39.34166097, -7.79381123],
                            [39.34190984, -7.79381124],
                            [39.34210539, -7.79382902],
                            [39.34228315, -7.79393571],
                            [39.34237204, -7.79402461],
                            [39.34256761, -7.79416685],
                            [39.34265648, -7.79406018],
                            [39.34267425, -7.79388236],
                            [39.34269204, -7.79372233],
                            [39.34276315, -7.79347339],
                            [39.34281649, -7.7932778],
                            [39.34297649, -7.79322446],
                            [39.34322537, -7.79322446],
                            [39.3433498, -7.79340227],
                            [39.34343867, -7.79358009],
                            [39.34352757, -7.79377567],
                            [39.34366979, -7.79388237],
                            [39.34393643, -7.79398906],
                            [39.34420311, -7.79406018],
                            [39.34452309, -7.79414909],
                            [39.34475419, -7.79409575],
                            [39.3448253, -7.79398906],
                            [39.34493197, -7.79384682],
                            [39.34514529, -7.79384682],
                            [39.34542973, -7.79384682],
                            [39.34569639, -7.79377568],
                            [39.34589195, -7.79363344],
                            [39.34603416, -7.79345562],
                            [39.34605194, -7.79322447],
                            [39.34603416, -7.79302887],
                            [39.34587417, -7.7929044],
                            [39.34564307, -7.79279773],
                            [39.34548308, -7.79269104],
                            [39.34534087, -7.79254878],
                            [39.34526974, -7.79242432],
                            [39.34526974, -7.79228207],
                            [39.34537641, -7.79212203],
                            [39.34560751, -7.79197979],
                            [39.34574974, -7.79189086],
                            [39.34585639, -7.79180197],
                            [39.34612306, -7.7917842],
                            [39.34642526, -7.79190867],
                            [39.34658526, -7.79194422],
                            [39.34678081, -7.79196201],
                            [39.3469408, -7.79199756],
                            [39.34706524, -7.7918731],
                            [39.34718969, -7.79174862],
                            [39.34731412, -7.79160638],
                            [39.34747411, -7.79160638],
                            [39.34759856, -7.79160638],
                            [39.34777633, -7.79169529],
                            [39.347883, -7.79181977],
                            [39.34806078, -7.79194423],
                            [39.34836298, -7.79208649],
                            [39.34848741, -7.79208649],
                            [39.34861186, -7.79210426],
                            [39.34871853, -7.79219317],
                            [39.34886074, -7.79231764],
                            [39.34887851, -7.79251323],
                            [39.3488963, -7.79265547],
                            [39.34902075, -7.79265547],
                            [39.34916296, -7.79242434],
                            [39.34926963, -7.79213983],
                            [39.34948294, -7.79187311],
                            [39.34953628, -7.7915175],
                            [39.34964294, -7.79117965],
                            [39.34976738, -7.79091293],
                            [39.34983849, -7.79069955],
                            [39.34998072, -7.79050397],
                            [39.35010515, -7.79041506],
                            [39.35028294, -7.79041506],
                            [39.35042516, -7.79057509],
                            [39.35042516, -7.79087738],
                            [39.35030071, -7.79114409],
                            [39.35019404, -7.79153528],
                            [39.3500696, -7.79183756],
                            [39.34990961, -7.79215762],
                            [39.34971405, -7.79233543],
                            [39.34957184, -7.79261992],
                            [39.34948293, -7.79286886],
                            [39.34951851, -7.79308224],
                            [39.34955405, -7.79324227],
                            [39.34969627, -7.79326004],
                            [39.34983848, -7.79315335],
                            [39.34998071, -7.79295777],
                            [39.35014069, -7.79272661],
                            [39.35024736, -7.79233543],
                            [39.35035403, -7.79199757],
                            [39.35040737, -7.79173087],
                            [39.35049624, -7.79153529],
                            [39.35056737, -7.79137524],
                            [39.35074513, -7.79121521],
                            [39.35090512, -7.79100183],
                            [39.35110068, -7.79080625],
                            [39.35140288, -7.79071734],
                            [39.35165176, -7.790664],
                            [39.35195399, -7.79071734],
                            [39.35214954, -7.79085959],
                            [39.35225619, -7.7910374],
                            [39.35227397, -7.79121522],
                            [39.35229175, -7.79144637],
                            [39.35229175, -7.79167754],
                            [39.35223842, -7.79190869],
                            [39.35207843, -7.79222875],
                            [39.35202509, -7.79253104],
                            [39.35202508, -7.79277996],
                            [39.35195398, -7.7930467],
                            [39.35190065, -7.79336674],
                            [39.35197175, -7.79359791],
                            [39.35206064, -7.79374016],
                            [39.35223842, -7.79377571],
                            [39.35229174, -7.79361568],
                            [39.35232731, -7.79311781],
                            [39.35236285, -7.79286887],
                            [39.35241618, -7.79263772],
                            [39.3524873, -7.79249546],
                            [39.35266506, -7.79242435],
                            [39.35300284, -7.79244214],
                            [39.35328727, -7.79244214],
                            [39.35358949, -7.79244214],
                            [39.35392724, -7.792371],
                            [39.35415836, -7.79217542],
                            [39.35430058, -7.79194425],
                            [39.35435392, -7.79173088],
                            [39.3543717, -7.79142861],
                            [39.3543717, -7.79116189],
                            [39.3543717, -7.79091295],
                            [39.3543717, -7.79069957],
                            [39.3543717, -7.79043285],
                            [39.35431836, -7.79030839],
                            [39.35430058, -7.79004166],
                            [39.35424726, -7.78986385],
                            [39.35424726, -7.78959713],
                            [39.35430059, -7.78931264],
                            [39.35430059, -7.78908147],
                            [39.35430059, -7.78888589],
                            [39.35430059, -7.78853026],
                            [39.35430059, -7.78821018],
                            [39.35422949, -7.78790791],
                            [39.35412282, -7.78760563],
                            [39.35405171, -7.78737448],
                            [39.35405171, -7.78714333],
                            [39.35408728, -7.78689439],
                            [39.35412282, -7.78660989],
                            [39.35412282, -7.78632539],
                            [39.35412283, -7.78586307],
                            [39.35408728, -7.78550745],
                            [39.35414061, -7.78518739],
                            [39.35405172, -7.78483176],
                            [39.35405173, -7.7845117],
                            [39.3539984, -7.78422719],
                            [39.35401618, -7.78392492],
                            [39.35405173, -7.78360486],
                            [39.35414062, -7.7832848],
                            [39.3541584, -7.78296472],
                            [39.35419395, -7.78257354],
                            [39.35414062, -7.7823246],
                            [39.35403396, -7.78209345],
                            [39.35387396, -7.78196897],
                            [39.35364286, -7.78182673],
                            [39.35335844, -7.78177339],
                            [39.35309178, -7.78177339],
                            [39.35268289, -7.78177339],
                            [39.35232735, -7.78164892],
                            [39.35198959, -7.78157778],
                            [39.35170514, -7.78150665],
                            [39.35147404, -7.78150664],
                            [39.35124294, -7.78150664],
                            [39.35101183, -7.78150664],
                            [39.35078073, -7.78150664],
                            [39.35046074, -7.78159556],
                            [39.35028298, -7.78175558],
                            [39.35012297, -7.78189785],
                            [39.3500341, -7.78214679],
                            [39.3500163, -7.78246683],
                            [39.35021631, -7.78277023],
                            [39.35025102, -7.78304807],
                            [39.34997325, -7.78308279],
                            [39.34969548, -7.78315224],
                            [39.34952186, -7.78339535],
                            [39.34948716, -7.78367318],
                            [39.34959131, -7.78391629],
                            [39.34973019, -7.78429831],
                            [39.34979963, -7.78471505],
                            [39.34969547, -7.78506234],
                            [39.34927882, -7.78520126],
                            [39.34900105, -7.78534017],
                            [39.34868854, -7.78554854],
                            [39.34834134, -7.78558326],
                            [39.3480983, -7.78582637],
                            [39.34820247, -7.78617367],
                            [39.34841077, -7.78652095],
                            [39.34851493, -7.78738919],
                            [39.34837606, -7.78773648],
                            [39.34806356, -7.7881185],
                            [39.34782052, -7.78853523],
                            [39.34754275, -7.78884781],
                            [39.34729971, -7.78919509],
                            [39.34688304, -7.78929928],
                            [39.3466747, -7.78895198],
                            [39.3466747, -7.78860468],
                            [39.34695248, -7.78829214],
                            [39.34719555, -7.78791011],
                            [39.34743858, -7.78763228],
                            [39.34729972, -7.7874239],
                            [39.34698721, -7.78725026],
                            [39.34670944, -7.78725025],
                            [39.34639695, -7.78735444],
                            [39.3459803, -7.78735444],
                            [39.34577196, -7.78714606],
                            [39.34532059, -7.78683352],
                            [39.34514699, -7.7864862],
                            [39.34486922, -7.78617366],
                            [39.34455674, -7.78582636],
                            [39.34431369, -7.78558325],
                            [39.34403593, -7.7854096],
                            [39.34354983, -7.78502759],
                            [39.34309846, -7.78468029],
                            [39.3426818, -7.78433301],
                            [39.34229988, -7.78412462],
                            [39.34188321, -7.78412462],
                            [39.34143184, -7.78412462],
                            [39.3410499, -7.78391625],
                            [39.34077213, -7.78360369],
                            [39.34052909, -7.78329113],
                            [39.34028604, -7.78287437],
                            [39.34004299, -7.78249236],
                            [39.33969579, -7.78207561],
                            [39.33934858, -7.78193671],
                            [39.33900136, -7.78186722],
                            [39.33872359, -7.78190196],
                            [39.33848056, -7.7819367],
                            [39.33830695, -7.7819367],
                            [39.33802918, -7.7819367],
                            [39.33782085, -7.78214505],
                            [39.33789028, -7.78249235],
                            [39.33809861, -7.78277018],
                            [39.33834167, -7.7829091],
                            [39.33865415, -7.78304802],
                            [39.33907081, -7.78308275],
                            [39.3393833, -7.78332585],
                            [39.33969578, -7.78353422],
                            [39.33979995, -7.7838468],
                            [39.34004299, -7.78412461],
                            [39.34025132, -7.78447191],
                            [39.3403902, -7.7848192],
                            [39.3405638, -7.78513176],
                            [39.34059852, -7.78544433],
                            [39.34073742, -7.78575687],
                            [39.34073742, -7.7861389],
                            [39.34077212, -7.78638201],
                            [39.34077212, -7.78669456],
                            [39.3405638, -7.78693766],
                            [39.34035548, -7.78714604],
                            [39.34014714, -7.78721549],
                            [39.34004297, -7.78738916],
                            [39.3399041, -7.78752806],
                            [39.33976522, -7.78784063],
                            [39.33962633, -7.78815317],
                            [39.33948744, -7.78829211],
                            [39.33941799, -7.78860465],
                            [39.33927911, -7.78888248],
                            [39.33900133, -7.78891722],
                            [39.33886245, -7.78891722],
                            [39.33868885, -7.78874358],
                            [39.33841108, -7.788431],
                            [39.33827219, -7.78818791],
                            [39.33816803, -7.78797953],
                            [39.33806388, -7.7879448],
                            [39.33792499, -7.7879448],
                            [39.33778611, -7.78801427],
                            [39.33768194, -7.78811846],
                            [39.33747362, -7.78832681],
                            [39.33730001, -7.78832681],
                            [39.33716111, -7.78822263],
                            [39.33702224, -7.78811845],
                            [39.33681391, -7.78804897],
                            [39.33639727, -7.78794479],
                            [39.3362931, -7.78801426],
                            [39.33625837, -7.78811845],
                            [39.3361195, -7.78829209],
                            [39.33622363, -7.78860463],
                            [39.3364667, -7.78877829],
                            [39.33674447, -7.7890214],
                            [39.33705694, -7.78919504],
                            [39.33736944, -7.78940341],
                            [39.33757778, -7.78961178],
                            [39.33768193, -7.78992435],
                            [39.33775138, -7.79023691],
                            [39.33785555, -7.79041056],
                            [39.33785554, -7.79065366],
                            [39.33785554, -7.79089677],
                            [39.33775138, -7.79107041],
                            [39.33743887, -7.79107041],
                            [39.3371264, -7.79107041],
                            [39.3371611, -7.79120931],
                            [39.33719583, -7.79145243],
                            [39.33719583, -7.79176498],
                            [39.3369875, -7.79190391],
                            [39.33677916, -7.79186917],
                            [39.33657086, -7.7916608],
                            [39.33653613, -7.79138296],
                            [39.33643196, -7.79127877],
                            [39.33615419, -7.79113986],
                            [39.33591115, -7.7908273],
                            [39.33580699, -7.79068838],
                            [39.33573755, -7.79048001],
                            [39.33563338, -7.79027163],
                            [39.33539033, -7.79006326],
                            [39.33511258, -7.78995907],
                            [39.33486951, -7.79002852],
                            [39.33473064, -7.79030635],
                            [39.3346959, -7.79065365],
                            [39.3346959, -7.79100093],
                            [39.33438341, -7.79103566],
                            [39.33403621, -7.79093148],
                            [39.33389733, -7.79065365],
                            [39.33368899, -7.79044526],
                            [39.33341122, -7.79023689],
                            [39.33316819, -7.79023689],
                            [39.3327168, -7.79058418],
                            [39.33268209, -7.79093147],
                            [39.33268209, -7.79117458],
                            [39.33293236, -7.79142852],
                            [39.33309235, -7.79149963],
                            [39.33319901, -7.79157077],
                            [39.33321679, -7.79167746],
                            [39.33327011, -7.79183749],
                            [39.333199, -7.79194416],
                            [39.33309235, -7.79201529],
                            [39.33302123, -7.79206864],
                            [39.33300345, -7.79221089],
                            [39.33302123, -7.7923887],
                            [39.33302123, -7.79253096],
                            [39.33298568, -7.79265541],
                            [39.33293236, -7.79281545],
                            [39.33289678, -7.7929577],
                            [39.33295012, -7.79308217],
                            [39.33311011, -7.79327776],
                            [39.33339455, -7.79336666],
                            [39.33362565, -7.79333111],
                            [39.33378565, -7.79311773],
                            [39.33387454, -7.7929577],
                            [39.33399898, -7.79288658],
                            [39.33412342, -7.79283324],
                            [39.33423009, -7.79283324],
                            [39.33442562, -7.79283324],
                            [39.33463896, -7.79277989],
                            [39.33474563, -7.792691],
                            [39.3348523, -7.79258431],
                            [39.33494117, -7.79256652],
                            [39.3350834, -7.79265541],
                            [39.33533228, -7.79272655],
                            [39.33542115, -7.79261986],
                            [39.33559894, -7.79260208],
                            [39.33565226, -7.79279769],
                            [39.33556338, -7.79293994],
                            [39.33545672, -7.79311774],
                            [39.3353856, -7.79333112],
                            [39.33529671, -7.79343781],
                            [39.3351545, -7.79349115],
                            [39.33513672, -7.79366895],
                            [39.3351545, -7.79386455],
                            [39.3351545, -7.79407793],
                            [39.33526115, -7.79414905],
                            [39.33540338, -7.79418462],
                            [39.33556337, -7.79422018],
                            [39.33572336, -7.79434465],
                            [39.33574114, -7.79452245],
                            [39.33570558, -7.79475362],
                            [39.33554559, -7.79494922],
                            [39.33543892, -7.79505589],
                            [39.33527892, -7.79521594],
                            [39.33515449, -7.79526927],
                            [39.3349945, -7.79528704],
                            [39.33488783, -7.79537595],
                            [39.33467449, -7.79537595],
                            [39.33453228, -7.79537595],
                            [39.33433674, -7.79537595],
                            [39.33419453, -7.79537595],
                            [39.33403452, -7.79537595],
                            [39.33389231, -7.79537595],
                            [39.33366119, -7.79537594],
                            [39.33337676, -7.79537594],
                            [39.33321677, -7.79539373],
                            [39.33309234, -7.79541151],
                            [39.33293234, -7.79541151],
                            [39.33282568, -7.79542928],
                            [39.33270123, -7.79542928],
                            [39.33261234, -7.79535817],
                            [39.33250567, -7.79535817],
                            [39.33239901, -7.79542928],
                            [39.33232791, -7.79560711],
                            [39.33232791, -7.79578491],
                            [39.33238124, -7.79592715],
                            [39.33257679, -7.79599829],
                            [39.33271901, -7.79601608],
                            [39.33291455, -7.79605163],
                            [39.33309234, -7.79617611],
                            [39.33327009, -7.79637171],
                            [39.33341231, -7.79660286],
                            [39.3334123, -7.79676289],
                            [39.3334123, -7.79692292],
                            [39.33353675, -7.79692292],
                            [39.33362564, -7.79686958],
                            [39.33376785, -7.79692292],
                            [39.33387452, -7.79702961],
                            [39.33401673, -7.79718963],
                            [39.33423007, -7.79729632],
                            [39.33476337, -7.79733189],
                            [39.33501225, -7.79733189],
                            [39.33524337, -7.79718963],
                            [39.33538558, -7.79701183],
                            [39.33561669, -7.79688736],
                            [39.33574113, -7.79670956],
                            [39.33583001, -7.79651396],
                            [39.33586558, -7.79635393],
                            [39.33599002, -7.79626503],
                            [39.33599002, -7.79608721],
                            [39.33597224, -7.79592717],
                            [39.33606113, -7.79576715],
                            [39.3362389, -7.79564268],
                            [39.3362389, -7.79546488],
                            [39.3363989, -7.79528705],
                        ],
                        [
                            [39.38730534, -7.78619302],
                            [39.38751338, -7.78619303],
                            [39.38751761, -7.78620712],
                            [39.38752118, -7.786219],
                            [39.38762439, -7.78630933],
                            [39.38768889, -7.78637386],
                            [39.38780501, -7.78646419],
                            [39.38793402, -7.78659324],
                            [39.38806304, -7.78670937],
                            [39.38815335, -7.78689003],
                            [39.38826946, -7.7870836],
                            [39.38833397, -7.78730298],
                            [39.38838557, -7.78743202],
                            [39.38841137, -7.78758688],
                            [39.38848877, -7.78775463],
                            [39.38854038, -7.78788367],
                            [39.3886952, -7.7879224],
                            [39.38887583, -7.7879482],
                            [39.38906934, -7.78798693],
                            [39.38926287, -7.78802564],
                            [39.38931447, -7.78809015],
                            [39.38934027, -7.78821919],
                            [39.38936608, -7.78841276],
                            [39.38937897, -7.78859342],
                            [39.38946929, -7.78873538],
                            [39.3895596, -7.7888386],
                            [39.38974022, -7.78894184],
                            [39.38992084, -7.78898055],
                            [39.39008855, -7.78899346],
                            [39.39019178, -7.78905798],
                            [39.39037239, -7.78917412],
                            [39.39051431, -7.78934187],
                            [39.39064332, -7.78945802],
                            [39.39079813, -7.78961288],
                            [39.39095296, -7.78981934],
                            [39.39106907, -7.7899742],
                            [39.39115938, -7.79016776],
                            [39.39126259, -7.79033553],
                            [39.39136579, -7.79046457],
                            [39.39149481, -7.79063232],
                            [39.39161093, -7.79076136],
                            [39.39172704, -7.79095494],
                            [39.39177864, -7.79110978],
                            [39.39194635, -7.79136786],
                            [39.39202377, -7.79158725],
                            [39.39210118, -7.79176791],
                            [39.39220439, -7.79200019],
                            [39.39233341, -7.79227119],
                            [39.39247531, -7.79251636],
                            [39.39261723, -7.79269702],
                            [39.39279785, -7.7929035],
                            [39.39292686, -7.79309707],
                            [39.39314619, -7.79331644],
                            [39.39335262, -7.79349709],
                            [39.39349452, -7.79362613],
                            [39.39364987, -7.79375563],
                            [39.39372674, -7.79381972],
                            [39.39401059, -7.79400038],
                            [39.39426861, -7.79414233],
                            [39.39457825, -7.79428426],
                            [39.39472017, -7.7943875],
                            [39.39487498, -7.79445204],
                            [39.39508141, -7.79449076],
                            [39.39527493, -7.79449076],
                            [39.39549425, -7.79449076],
                            [39.39570068, -7.79451656],
                            [39.39589419, -7.79455527],
                            [39.39601032, -7.79463271],
                            [39.39607482, -7.79476175],
                            [39.39608772, -7.7949553],
                            [39.39611352, -7.79516179],
                            [39.39612642, -7.79536825],
                            [39.39613933, -7.79560053],
                            [39.39619093, -7.79580699],
                            [39.39621673, -7.79600057],
                            [39.39626834, -7.79616832],
                            [39.39630704, -7.79632316],
                            [39.39638444, -7.7964522],
                            [39.39648765, -7.79656837],
                            [39.39661666, -7.7966716],
                            [39.39682309, -7.7966845],
                            [39.3969908, -7.7966845],
                            [39.39713272, -7.7966587],
                            [39.39730043, -7.79655545],
                            [39.39742948, -7.79646512],
                            [39.39761009, -7.79642641],
                            [39.3977907, -7.7964135],
                            [39.39791972, -7.79631028],
                            [39.39806162, -7.79621995],
                            [39.39810034, -7.796078],
                            [39.39812615, -7.79593605],
                            [39.39813808, -7.79587473],
                            [39.39813861, -7.79587206],
                            [39.39814014, -7.79587393],
                            [39.39816918, -7.79586276],
                            [39.39816918, -7.79584797],
                            [39.39816918, -7.79564125],
                            [39.39816919, -7.79539758],
                            [39.39819133, -7.79519821],
                            [39.39830207, -7.79502099],
                            [39.39845711, -7.79484379],
                            [39.39854569, -7.79466657],
                            [39.39870071, -7.79448936],
                            [39.3987381, -7.79434953],
                            [39.39873811, -7.79415016],
                            [39.39880037, -7.79396325],
                            [39.39883776, -7.79380127],
                            [39.39892496, -7.79368912],
                            [39.39898725, -7.79355207],
                            [39.39906199, -7.79345238],
                            [39.39918658, -7.79329039],
                            [39.39931115, -7.79319072],
                            [39.39948555, -7.79311594],
                            [39.39962258, -7.79307857],
                            [39.3997347, -7.7930661],
                            [39.39988419, -7.79304119],
                            [39.40003368, -7.79296643],
                            [39.40014581, -7.79284181],
                            [39.40015826, -7.79271721],
                            [39.40009598, -7.79255523],
                            [39.40000878, -7.79245555],
                            [39.39984682, -7.79231848],
                            [39.39963505, -7.79219388],
                            [39.39941082, -7.79209419],
                            [39.39917411, -7.79200697],
                            [39.39888759, -7.79190729],
                            [39.39870073, -7.79180758],
                            [39.39850141, -7.79167053],
                            [39.39833946, -7.79158331],
                            [39.39814014, -7.79152101],
                            [39.39801557, -7.79144625],
                            [39.39785362, -7.79139641],
                            [39.39769167, -7.79130917],
                            [39.39755463, -7.79122195],
                            [39.39745498, -7.79115965],
                            [39.39731794, -7.7910475],
                            [39.39715599, -7.79096028],
                            [39.39695667, -7.79084813],
                            [39.39684457, -7.79077338],
                            [39.39675735, -7.79066124],
                            [39.39662031, -7.79056155],
                            [39.39645837, -7.79043695],
                            [39.39639607, -7.79029989],
                            [39.39629642, -7.79018774],
                            [39.39614693, -7.79005067],
                            [39.39604728, -7.78995099],
                            [39.39587286, -7.78982638],
                            [39.39576076, -7.78973916],
                            [39.39557387, -7.78960208],
                            [39.3954244, -7.78946503],
                            [39.39532248, -7.78939708],
                            [39.3952749, -7.78936535],
                            [39.39516278, -7.78926566],
                            [39.39497593, -7.78920335],
                            [39.39483887, -7.78911613],
                            [39.39467694, -7.78902893],
                            [39.3945399, -7.78894169],
                            [39.39440288, -7.78889184],
                            [39.39424092, -7.78885447],
                            [39.39406653, -7.78880462],
                            [39.39390457, -7.78880462],
                            [39.39380492, -7.78880461],
                            [39.39358068, -7.78880461],
                            [39.39339381, -7.78881708],
                            [39.39324433, -7.78885447],
                            [39.39306991, -7.78887938],
                            [39.39290797, -7.7889043],
                            [39.39279585, -7.7889043],
                            [39.39265881, -7.7889043],
                            [39.39252178, -7.78882954],
                            [39.39234737, -7.78871741],
                            [39.39228509, -7.78863017],
                            [39.39219789, -7.78846817],
                            [39.39219789, -7.78829375],
                            [39.39219789, -7.78814422],
                            [39.39219789, -7.78798224],
                            [39.39219789, -7.78783271],
                            [39.39217297, -7.78765825],
                            [39.39208577, -7.78750873],
                            [39.39201101, -7.78737167],
                            [39.39192381, -7.7872346],
                            [39.39182416, -7.78710999],
                            [39.39172449, -7.78698539],
                            [39.39164976, -7.78689817],
                            [39.39148781, -7.78674864],
                            [39.39137569, -7.78664897],
                            [39.39125111, -7.78652435],
                            [39.39115145, -7.78642466],
                            [39.39100196, -7.78633746],
                            [39.39084001, -7.78623777],
                            [39.39071544, -7.78611315],
                            [39.3905784, -7.786001],
                            [39.39044137, -7.78587642],
                            [39.39026696, -7.78571443],
                            [39.39015486, -7.78557736],
                            [39.39004274, -7.78542785],
                            [39.38998877, -7.78539636],
                            [39.38989324, -7.78534061],
                            [39.38971883, -7.78532814],
                            [39.38956933, -7.7853157],
                            [39.38939493, -7.78535306],
                            [39.38930773, -7.78535306],
                            [39.38914577, -7.78535306],
                            [39.38899629, -7.78539044],
                            [39.38898028, -7.78539635],
                            [39.38894589, -7.78540903],
                            [39.3887596, -7.78547768],
                            [39.38859765, -7.78550259],
                            [39.38838586, -7.78550259],
                            [39.38822392, -7.78551506],
                            [39.38813672, -7.78540291],
                            [39.38814919, -7.7852783],
                            [39.38826129, -7.78522846],
                            [39.38841077, -7.78519108],
                            [39.38856028, -7.7851537],
                            [39.38873468, -7.78511633],
                            [39.38894645, -7.7850914],
                            [39.38910842, -7.78505402],
                            [39.38930774, -7.78504155],
                            [39.38943231, -7.78504155],
                            [39.3895818, -7.78504155],
                            [39.38969392, -7.78504155],
                            [39.38988078, -7.7850291],
                            [39.39005519, -7.7850291],
                            [39.39031679, -7.78499172],
                            [39.39038179, -7.78499172],
                            [39.39045383, -7.78499172],
                            [39.39062823, -7.78499172],
                            [39.39076527, -7.78499172],
                            [39.39087739, -7.78500419],
                            [39.39102689, -7.7850291],
                            [39.3912885, -7.78509141],
                            [39.39153765, -7.78516618],
                            [39.39179926, -7.78519109],
                            [39.39202349, -7.78526586],
                            [39.39216053, -7.78532815],
                            [39.39234738, -7.78542786],
                            [39.39249687, -7.78553999],
                            [39.39259654, -7.78562722],
                            [39.3926339, -7.7857269],
                            [39.39268372, -7.78590134],
                            [39.39269619, -7.78608825],
                            [39.39269619, -7.78622532],
                            [39.39274602, -7.78647452],
                            [39.39288306, -7.78654929],
                            [39.393045, -7.78653683],
                            [39.39315712, -7.78644961],
                            [39.39324434, -7.78634992],
                            [39.39341874, -7.78626269],
                            [39.39355577, -7.78611316],
                            [39.39373018, -7.78602596],
                            [39.39386721, -7.78596365],
                            [39.39400425, -7.78596365],
                            [39.39415374, -7.78596365],
                            [39.39431569, -7.78602596],
                            [39.39441534, -7.7860758],
                            [39.39451501, -7.78613811],
                            [39.39463957, -7.7862004],
                            [39.39483889, -7.78626269],
                            [39.3950133, -7.78626269],
                            [39.39518772, -7.7862627],
                            [39.39539951, -7.7862627],
                            [39.39557389, -7.78632501],
                            [39.3957483, -7.78634993],
                            [39.39593517, -7.78643715],
                            [39.39605974, -7.78653684],
                            [39.39607221, -7.7866739],
                            [39.39605974, -7.78681097],
                            [39.39594762, -7.78687326],
                            [39.3958355, -7.78697295],
                            [39.39571094, -7.78703525],
                            [39.39557389, -7.78708508],
                            [39.3953995, -7.78719723],
                            [39.39512542, -7.78729692],
                            [39.39491364, -7.78737168],
                            [39.39476415, -7.78744643],
                            [39.3946022, -7.78755857],
                            [39.39449008, -7.78763335],
                            [39.39446518, -7.78780779],
                            [39.39447763, -7.78798225],
                            [39.39461465, -7.78806945],
                            [39.39480152, -7.78810683],
                            [39.39498839, -7.78809439],
                            [39.39508806, -7.78803209],
                            [39.39523754, -7.78800716],
                            [39.3954244, -7.78794487],
                            [39.39557388, -7.78782025],
                            [39.39569847, -7.78769567],
                            [39.39578567, -7.78759596],
                            [39.39594762, -7.78753367],
                            [39.39605974, -7.78745891],
                            [39.39617186, -7.78737169],
                            [39.39629643, -7.78737169],
                            [39.39642101, -7.78724707],
                            [39.39652068, -7.78715987],
                            [39.39669508, -7.78711002],
                            [39.39690685, -7.78701034],
                            [39.39709373, -7.78694803],
                            [39.39728058, -7.78694803],
                            [39.39752973, -7.78694804],
                            [39.39772905, -7.78694804],
                            [39.39794084, -7.78694804],
                            [39.39815261, -7.78698542],
                            [39.39833948, -7.78701035],
                            [39.39875057, -7.7870228],
                            [39.39893746, -7.78699788],
                            [39.39901219, -7.78691066],
                            [39.39893746, -7.78677359],
                            [39.39878796, -7.78672375],
                            [39.39861355, -7.78669884],
                            [39.39833948, -7.786649],
                            [39.39809033, -7.786649],
                            [39.39780381, -7.78657422],
                            [39.39761693, -7.78652438],
                            [39.39733041, -7.78647454],
                            [39.39710617, -7.78639978],
                            [39.39684459, -7.78627517],
                            [39.39655805, -7.78617548],
                            [39.39625907, -7.78606333],
                            [39.3959227, -7.78598858],
                            [39.39566111, -7.78593875],
                            [39.39539951, -7.78586397],
                            [39.39521262, -7.78585151],
                            [39.39495101, -7.78580166],
                            [39.39473924, -7.78578921],
                            [39.39452746, -7.78571445],
                            [39.39429077, -7.78565214],
                            [39.39405408, -7.78556492],
                            [39.39379246, -7.7854403],
                            [39.39355577, -7.78531572],
                            [39.39331908, -7.7851911],
                            [39.39311976, -7.7850665],
                            [39.39295781, -7.78499173],
                            [39.39273357, -7.78489206],
                            [39.39247197, -7.78475498],
                            [39.39221036, -7.78464283],
                            [39.39202349, -7.78458054],
                            [39.3917868, -7.78450576],
                            [39.39158748, -7.78449332],
                            [39.3914474, -7.78449332],
                            [39.3913757, -7.78449332],
                            [39.39115146, -7.78449332],
                            [39.39086494, -7.78449332],
                            [39.39069053, -7.78450576],
                            [39.39045383, -7.78453068],
                            [39.39027942, -7.78458053],
                            [39.39000537, -7.7846179],
                            [39.3897313, -7.7846179],
                            [39.38945722, -7.78465529],
                            [39.38912087, -7.78466775],
                            [39.38873468, -7.78466775],
                            [39.38837341, -7.78466775],
                            [39.38798723, -7.78466774],
                            [39.38768825, -7.78463036],
                            [39.38730207, -7.78458052],
                            [39.38710275, -7.78450574],
                            [39.38697816, -7.78440607],
                            [39.38687849, -7.78429394],
                            [39.38686606, -7.78406964],
                            [39.38686606, -7.78388273],
                            [39.38696571, -7.78373322],
                            [39.38710275, -7.78360861],
                            [39.38731452, -7.78350892],
                            [39.38746402, -7.78349646],
                            [39.38762596, -7.78350892],
                            [39.38781282, -7.78355877],
                            [39.3880869, -7.78358368],
                            [39.38856029, -7.78358369],
                            [39.38880943, -7.78344663],
                            [39.38900876, -7.78323479],
                            [39.3890586, -7.78303543],
                            [39.3890586, -7.78283606],
                            [39.3890586, -7.78259933],
                            [39.38899631, -7.7823501],
                            [39.38884681, -7.78211337],
                            [39.38873469, -7.78196384],
                            [39.38852291, -7.78178938],
                            [39.38838588, -7.7816648],
                            [39.38826131, -7.78156511],
                            [39.38804953, -7.78146543],
                            [39.38772564, -7.78130343],
                            [39.38743911, -7.78115392],
                            [39.38715259, -7.78099192],
                            [39.38684115, -7.78087977],
                            [39.38666673, -7.78075517],
                            [39.38647988, -7.78061811],
                            [39.38631792, -7.78050595],
                            [39.38614352, -7.7803938],
                            [39.38598157, -7.78029413],
                            [39.38581963, -7.78016952],
                            [39.38567013, -7.78004492],
                            [39.38552064, -7.77993278],
                            [39.38542099, -7.77980817],
                            [39.3852964, -7.77963372],
                            [39.38518428, -7.77943435],
                            [39.38505972, -7.77924746],
                            [39.38492268, -7.77917269],
                            [39.38482302, -7.77917269],
                            [39.38464862, -7.77908547],
                            [39.38442438, -7.77899825],
                            [39.38427488, -7.77889855],
                            [39.3842126, -7.7788238],
                            [39.38406311, -7.77871165],
                            [39.38395099, -7.77859951],
                            [39.38376412, -7.77844998],
                            [39.38367694, -7.77831292],
                            [39.38349006, -7.77815094],
                            [39.38339041, -7.77800141],
                            [39.38329074, -7.77788926],
                            [39.38316618, -7.77777711],
                            [39.38299177, -7.77765251],
                            [39.38275507, -7.777503],
                            [39.38261803, -7.77740331],
                            [39.38231906, -7.77730363],
                            [39.38210727, -7.77715409],
                            [39.38197025, -7.77704196],
                            [39.3818083, -7.77689243],
                            [39.38162143, -7.77675536],
                            [39.38140966, -7.7766183],
                            [39.38126017, -7.77650615],
                            [39.3810733, -7.77638153],
                            [39.3808989, -7.77625695],
                            [39.38073694, -7.77615726],
                            [39.3805999, -7.77605758],
                            [39.38040058, -7.77590805],
                            [39.38015143, -7.77575852],
                            [39.38005178, -7.77563392],
                            [39.37995211, -7.77545947],
                            [39.37981509, -7.77524763],
                            [39.37971542, -7.77499843],
                            [39.37971542, -7.77486137],
                            [39.379641, -7.77476831],
                            [39.37961577, -7.77473675],
                            [39.37952856, -7.77468692],
                            [39.37949289, -7.77469286],
                            [39.37937906, -7.77471184],
                            [39.37930433, -7.77486137],
                            [39.37919221, -7.77496104],
                            [39.37905517, -7.77504826],
                            [39.37888077, -7.77497351],
                            [39.37875618, -7.77481153],
                            [39.37864406, -7.774662],
                            [39.37853196, -7.77451247],
                            [39.37839492, -7.77442524],
                            [39.37822052, -7.77447507],
                            [39.37808347, -7.77461216],
                            [39.37797137, -7.77474922],
                            [39.37780942, -7.77482398],
                            [39.37772221, -7.77487382],
                            [39.37758518, -7.77487382],
                            [39.37744815, -7.77486136],
                            [39.3773111, -7.77474921],
                            [39.37717407, -7.77459969],
                            [39.37702459, -7.7744377],
                            [39.3769374, -7.77430065],
                            [39.3767879, -7.77413865],
                            [39.37661349, -7.77398913],
                            [39.37645154, -7.77381468],
                            [39.37628958, -7.77366515],
                            [39.37610273, -7.77354055],
                            [39.37591588, -7.77339103],
                            [39.37570409, -7.77320411],
                            [39.37549232, -7.77302967],
                            [39.37539265, -7.77292999],
                            [39.37525561, -7.77281785],
                            [39.37501892, -7.7727057],
                            [39.37479467, -7.77260601],
                            [39.37462028, -7.77249386],
                            [39.37442096, -7.77239419],
                            [39.37424656, -7.77220729],
                            [39.37409706, -7.77204529],
                            [39.3740902, -7.7719149],
                            [39.3740846, -7.77180855],
                            [39.37418427, -7.77164655],
                            [39.37423409, -7.77148458],
                            [39.37435868, -7.77129767],
                            [39.37442096, -7.77108583],
                            [39.37444588, -7.77086156],
                            [39.3744708, -7.77063727],
                            [39.37444588, -7.77048774],
                            [39.37435869, -7.77028837],
                            [39.37424657, -7.77007655],
                            [39.37410953, -7.7699021],
                            [39.37398495, -7.7697775],
                            [39.37397249, -7.76961551],
                            [39.37397249, -7.76944106],
                            [39.37397249, -7.76924169],
                            [39.37407216, -7.76914202],
                            [39.3742092, -7.76904233],
                            [39.37433377, -7.76898002],
                            [39.37444589, -7.76889282],
                            [39.37457046, -7.76883051],
                            [39.37469504, -7.76875575],
                            [39.37488191, -7.76868098],
                            [39.3750314, -7.76858131],
                            [39.3750937, -7.76843178],
                            [39.3751809, -7.76828225],
                            [39.37519335, -7.76804551],
                            [39.37513106, -7.76783369],
                            [39.3750314, -7.76770907],
                            [39.37481962, -7.76760939],
                            [39.37455802, -7.7675097],
                            [39.37434623, -7.76744741],
                            [39.37410954, -7.76742248],
                            [39.37386037, -7.76739755],
                            [39.37368597, -7.76729788],
                            [39.37349912, -7.76719819],
                            [39.37331226, -7.76712342],
                            [39.37312539, -7.76702375],
                            [39.37292607, -7.76693653],
                            [39.37268938, -7.76692405],
                            [39.37251498, -7.76688667],
                            [39.37220354, -7.76688667],
                            [39.37190455, -7.76689914],
                            [39.37168031, -7.76693652],
                            [39.37141871, -7.76696145],
                            [39.37115709, -7.76699881],
                            [39.37093285, -7.76699881],
                            [39.37072108, -7.76699881],
                            [39.37048438, -7.76697388],
                            [39.37024769, -7.76682437],
                            [39.36999855, -7.76676208],
                            [39.36984905, -7.76666237],
                            [39.36963726, -7.76657515],
                            [39.3693632, -7.76651285],
                            [39.36905176, -7.76651285],
                            [39.36874031, -7.76653778],
                            [39.36846625, -7.76656269],
                            [39.3681299, -7.76656269],
                            [39.36799288, -7.76660008],
                            [39.36783091, -7.76660008],
                            [39.36766897, -7.76661252],
                            [39.36741982, -7.76662499],
                            [39.3672454, -7.76671221],
                            [39.36710838, -7.7668119],
                            [39.36685923, -7.76683681],
                            [39.36667235, -7.76687419],
                            [39.36652285, -7.76701126],
                            [39.36641073, -7.76724801],
                            [39.36634847, -7.76739752],
                            [39.36634847, -7.76758445],
                            [39.3663609, -7.76782118],
                            [39.36647302, -7.76802054],
                            [39.36654779, -7.76809532],
                            [39.36657269, -7.76824484],
                            [39.3666599, -7.76839436],
                            [39.36680187, -7.76854636],
                            [39.36689166, -7.76872604],
                            [39.36699433, -7.76895703],
                            [39.36709617, -7.76921613],
                            [39.36709616, -7.76945675],
                            [39.36709616, -7.76959392],
                            [39.36709616, -7.76971746],
                            [39.36703602, -7.76995809],
                            [39.36691572, -7.77009845],
                            [39.36675533, -7.77019872],
                            [39.36651476, -7.77037919],
                            [39.36629422, -7.77037919],
                            [39.36611379, -7.77037918],
                            [39.3659133, -7.77035913],
                            [39.36581306, -7.77021876],
                            [39.36565267, -7.77007839],
                            [39.36551233, -7.76983777],
                            [39.36550565, -7.76981549],
                            [39.3654522, -7.76963725],
                            [39.36539206, -7.76937654],
                            [39.36533191, -7.76911585],
                            [39.36527177, -7.7689755],
                            [39.36521163, -7.76871481],
                            [39.36517153, -7.76853432],
                            [39.36513143, -7.76835385],
                            [39.3650312, -7.76821348],
                            [39.36489085, -7.76809316],
                            [39.36475051, -7.76801296],
                            [39.36452998, -7.76797284],
                            [39.36434954, -7.76795279],
                            [39.36398867, -7.76795279],
                            [39.36378819, -7.76801295],
                            [39.36368795, -7.76815332],
                            [39.36356767, -7.76825358],
                            [39.36338722, -7.76831373],
                            [39.36322684, -7.7684541],
                            [39.36310656, -7.76855436],
                            [39.36290605, -7.76857441],
                            [39.36270558, -7.76857441],
                            [39.3625051, -7.76851426],
                            [39.36232467, -7.76837389],
                            [39.36222442, -7.76831372],
                            [39.36200389, -7.76829367],
                            [39.36178336, -7.76829367],
                            [39.36152273, -7.76829367],
                            [39.36132226, -7.76833378],
                            [39.36114183, -7.76857441],
                            [39.36106161, -7.76877494],
                            [39.36106161, -7.76911583],
                            [39.36106161, -7.76943668],
                            [39.36116187, -7.76973748],
                            [39.3613022, -7.76997812],
                            [39.36146259, -7.77025885],
                            [39.36164302, -7.77043933],
                            [39.3617232, -7.77063987],
                            [39.36202392, -7.77090055],
                            [39.36226452, -7.77106097],
                            [39.36228959, -7.7710944],
                            [39.36244495, -7.77130161],
                            [39.36270556, -7.77148208],
                            [39.36316669, -7.77168262],
                            [39.36336716, -7.77168262],
                            [39.36364784, -7.77176284],
                            [39.36392851, -7.77178289],
                            [39.36420921, -7.77170267],
                            [39.36432948, -7.77148208],
                            [39.36450992, -7.77126151],
                            [39.36463021, -7.77108103],
                            [39.3647505, -7.77096072],
                            [39.36505124, -7.77108103],
                            [39.36523167, -7.7711813],
                            [39.36547224, -7.7711011],
                            [39.36559253, -7.77098078],
                            [39.36575291, -7.7711412],
                            [39.3659133, -7.77140187],
                            [39.36609374, -7.77154226],
                            [39.36629422, -7.7717428],
                            [39.36643456, -7.77192327],
                            [39.36663503, -7.77216389],
                            [39.36689566, -7.77232431],
                            [39.36717635, -7.77240453],
                            [39.36747707, -7.77240454],
                            [39.36763744, -7.77218395],
                            [39.36779783, -7.77196338],
                            [39.36807852, -7.77172274],
                            [39.36815872, -7.77140188],
                            [39.36823891, -7.77122141],
                            [39.36851959, -7.77108104],
                            [39.36888046, -7.77088052],
                            [39.36928143, -7.77088053],
                            [39.36956209, -7.7710209],
                            [39.36974252, -7.77124147],
                            [39.36992298, -7.77140189],
                            [39.37000316, -7.7717027],
                            [39.37000316, -7.7720837],
                            [39.36990292, -7.77234438],
                            [39.36970242, -7.77262513],
                            [39.36956208, -7.77274545],
                            [39.36960219, -7.77298607],
                            [39.36966233, -7.77358767],
                            [39.36974251, -7.77388846],
                            [39.36982271, -7.77416919],
                            [39.36990291, -7.77446999],
                            [39.37006329, -7.77469058],
                            [39.37030387, -7.77479083],
                            [39.3706046, -7.774851],
                            [39.37096547, -7.77497131],
                            [39.3712261, -7.77497131],
                            [39.37158696, -7.77501143],
                            [39.37178746, -7.77489111],
                            [39.37200798, -7.77475074],
                            [39.37226861, -7.77471064],
                            [39.37252924, -7.77471064],
                            [39.37282996, -7.77471064],
                            [39.3730505, -7.77485101],
                            [39.37307055, -7.77501143],
                            [39.37317077, -7.77531221],
                            [39.37331111, -7.77553281],
                            [39.37333116, -7.7758336],
                            [39.37327102, -7.77613438],
                            [39.37321088, -7.77641513],
                            [39.37337126, -7.77671592],
                            [39.37349154, -7.77701671],
                            [39.37365192, -7.77725734],
                            [39.37387247, -7.77729746],
                            [39.374093, -7.77719719],
                            [39.37427343, -7.77701672],
                            [39.37443381, -7.77679615],
                            [39.37463429, -7.77677608],
                            [39.37485484, -7.77697662],
                            [39.37495507, -7.77727739],
                            [39.37505532, -7.777498],
                            [39.37509541, -7.77777874],
                            [39.3752157, -7.77803941],
                            [39.37537608, -7.77807951],
                            [39.37551642, -7.77805946],
                            [39.37563671, -7.77805946],
                            [39.375757, -7.77819983],
                            [39.37589734, -7.77805946],
                            [39.37599759, -7.77787899],
                            [39.37617802, -7.77793916],
                            [39.37631836, -7.77817979],
                            [39.37655892, -7.77842044],
                            [39.37675943, -7.7785608],
                            [39.37704009, -7.77862096],
                            [39.37726063, -7.77864102],
                            [39.3775012, -7.77860092],
                            [39.37764155, -7.77842045],
                            [39.37784203, -7.77823994],
                            [39.37812271, -7.77817979],
                            [39.37836328, -7.77819985],
                            [39.37848357, -7.77842045],
                            [39.37860387, -7.77872122],
                            [39.37862392, -7.77900196],
                            [39.37880435, -7.77912228],
                            [39.37902487, -7.77918244],
                            [39.37956619, -7.77926267],
                            [39.37980676, -7.77938298],
                            [39.37998719, -7.7795434],
                            [39.38006739, -7.77980409],
                            [39.38000723, -7.78000461],
                            [39.37992705, -7.78012493],
                            [39.37978671, -7.78024526],
                            [39.37972657, -7.78042573],
                            [39.37974662, -7.78062625],
                            [39.3798268, -7.78084683],
                            [39.38000723, -7.78096715],
                            [39.38026786, -7.78100725],
                            [39.38058864, -7.78100725],
                            [39.38090941, -7.78104737],
                            [39.38119009, -7.78100725],
                            [39.38143066, -7.78092705],
                            [39.38171135, -7.78082678],
                            [39.38191183, -7.78080674],
                            [39.3821123, -7.78094711],
                            [39.38239298, -7.78114763],
                            [39.38273381, -7.78138828],
                            [39.38307462, -7.78168908],
                            [39.38349565, -7.78202997],
                            [39.3839768, -7.78237088],
                            [39.38431761, -7.78273182],
                            [39.38475869, -7.78309277],
                            [39.38519973, -7.78349384],
                            [39.38562076, -7.78379462],
                            [39.38594153, -7.78415559],
                            [39.38630239, -7.78449649],
                            [39.38650288, -7.78485743],
                            [39.3868838, -7.78527856],
                            [39.38712437, -7.78551918],
                            [39.38720457, -7.78569965],
                            [39.38730481, -7.78592022],
                            [39.38730534, -7.78619302],
                        ],
                        [
                            [39.36103768, -7.77183536],
                            [39.36088865, -7.7717484],
                            [39.36078931, -7.77163661],
                            [39.36076447, -7.77152483],
                            [39.36073964, -7.77138819],
                            [39.36060304, -7.77127641],
                            [39.3605161, -7.77118945],
                            [39.36042919, -7.77100314],
                            [39.36042919, -7.77076712],
                            [39.36052853, -7.77059323],
                            [39.3605782, -7.77043176],
                            [39.3605782, -7.77028271],
                            [39.3605782, -7.7701585],
                            [39.36062789, -7.76995976],
                            [39.36068998, -7.76979829],
                            [39.36066514, -7.76967408],
                            [39.36062789, -7.76953744],
                            [39.3605782, -7.76938838],
                            [39.36045402, -7.76930143],
                            [39.36032984, -7.76923932],
                            [39.36016841, -7.76917722],
                            [39.36005663, -7.76913996],
                            [39.35990762, -7.76912754],
                            [39.35978344, -7.76912753],
                            [39.35964684, -7.76918964],
                            [39.35953507, -7.76928902],
                            [39.35939847, -7.76936353],
                            [39.35932396, -7.76942564],
                            [39.35918735, -7.76946291],
                            [39.35905075, -7.76946291],
                            [39.35890174, -7.76948774],
                            [39.35872787, -7.76948774],
                            [39.35857886, -7.76948774],
                            [39.35835532, -7.76943806],
                            [39.35816905, -7.76947533],
                            [39.35808211, -7.76957469],
                            [39.35795793, -7.7696989],
                            [39.35780892, -7.76984796],
                            [39.35764747, -7.76992249],
                            [39.35751087, -7.76999702],
                            [39.35758538, -7.77015848],
                            [39.35768474, -7.77027027],
                            [39.35777165, -7.7704069],
                            [39.35785859, -7.77051869],
                            [39.35790826, -7.7706429],
                            [39.3579331, -7.77079196],
                            [39.35792068, -7.77095344],
                            [39.35783374, -7.77110249],
                            [39.35769714, -7.77123912],
                            [39.35756053, -7.77137576],
                            [39.35756053, -7.77154965],
                            [39.35769714, -7.77173596],
                            [39.3579455, -7.7718726],
                            [39.35823113, -7.77194713],
                            [39.35846707, -7.77208377],
                            [39.35867819, -7.77214587],
                            [39.35892655, -7.77218313],
                            [39.35928668, -7.77228251],
                            [39.35955989, -7.77233219],
                            [39.35988277, -7.77241915],
                            [39.35998944, -7.77244582],
                            [39.36013113, -7.77248125],
                            [39.3603795, -7.77253094],
                            [39.36067755, -7.77260547],
                            [39.36086382, -7.77268],
                            [39.36101284, -7.77268],
                            [39.36116185, -7.77265515],
                            [39.36124879, -7.77254336],
                            [39.36126121, -7.77240673],
                            [39.36117428, -7.77228252],
                            [39.36108734, -7.77217073],
                            [39.36108734, -7.77205893],
                            [39.36107494, -7.77193472],
                            [39.36104854, -7.77186433],
                            [39.36103768, -7.77183536],
                        ],
                        [
                            [39.34235261, -7.76633144],
                            [39.34261609, -7.76604594],
                            [39.3430113, -7.76604594],
                            [39.34329675, -7.76604594],
                            [39.34349435, -7.7658483],
                            [39.3435163, -7.7655628],
                            [39.34338457, -7.76534319],
                            [39.343165, -7.76507965],
                            [39.34309913, -7.76481612],
                            [39.34305523, -7.76442081],
                            [39.34303326, -7.76411335],
                            [39.34287958, -7.76387177],
                            [39.34287958, -7.76363021],
                            [39.34270392, -7.76312508],
                            [39.34252827, -7.76279568],
                            [39.34250632, -7.76233448],
                            [39.34259415, -7.7618733],
                            [39.3427698, -7.76152191],
                            [39.34298937, -7.76123642],
                            [39.34336262, -7.76103878],
                            [39.34382371, -7.76095092],
                            [39.34435066, -7.76095092],
                            [39.34472393, -7.76099485],
                            [39.34509718, -7.76110465],
                            [39.34560217, -7.76125839],
                            [39.34608522, -7.76134623],
                            [39.34656826, -7.76136819],
                            [39.34702934, -7.76132428],
                            [39.34731478, -7.76110466],
                            [39.34749043, -7.76073133],
                            [39.34749043, -7.76033602],
                            [39.34749043, -7.75994071],
                            [39.34740261, -7.75969914],
                            [39.34727087, -7.75945758],
                            [39.34702935, -7.75919403],
                            [39.34680979, -7.75897442],
                            [39.34652435, -7.75888658],
                            [39.34628283, -7.75886462],
                            [39.34597545, -7.75886462],
                            [39.34573393, -7.75888657],
                            [39.34555828, -7.75895246],
                            [39.34531676, -7.75899637],
                            [39.34498741, -7.75917206],
                            [39.34489958, -7.75945757],
                            [39.34470197, -7.75941364],
                            [39.34461414, -7.75919402],
                            [39.34446045, -7.75893048],
                            [39.34446045, -7.75849126],
                            [39.34446045, -7.75816184],
                            [39.34435068, -7.75792026],
                            [39.34417503, -7.75770066],
                            [39.34402133, -7.757503],
                            [39.34393351, -7.75721751],
                            [39.34375786, -7.75701984],
                            [39.34369199, -7.75673435],
                            [39.34369199, -7.75638297],
                            [39.34380178, -7.75596571],
                            [39.34388961, -7.7555704],
                            [39.34388961, -7.75510922],
                            [39.34373592, -7.7548896],
                            [39.34351635, -7.7548896],
                            [39.34329679, -7.75502138],
                            [39.34309917, -7.75521902],
                            [39.3429894, -7.7554606],
                            [39.34298939, -7.75581197],
                            [39.3428357, -7.75611944],
                            [39.342682, -7.75649277],
                            [39.34270395, -7.75684415],
                            [39.34250635, -7.75701984],
                            [39.34224287, -7.75701984],
                            [39.34200135, -7.75686612],
                            [39.34178178, -7.75682219],
                            [39.3414744, -7.75682219],
                            [39.34125483, -7.75693199],
                            [39.34114506, -7.75721749],
                            [39.34123288, -7.75748103],
                            [39.34125483, -7.75770065],
                            [39.34112308, -7.75792025],
                            [39.34077179, -7.75805202],
                            [39.34044244, -7.75805202],
                            [39.34020092, -7.75789829],
                            [39.33982767, -7.75767867],
                            [39.33958615, -7.75743709],
                            [39.33932267, -7.75708571],
                            [39.33908115, -7.75701982],
                            [39.33886158, -7.75708571],
                            [39.33872985, -7.7572614],
                            [39.3387079, -7.7575469],
                            [39.33877376, -7.75792024],
                            [39.33892745, -7.75824966],
                            [39.33912507, -7.75853517],
                            [39.33936657, -7.75873281],
                            [39.33952027, -7.75897439],
                            [39.33971788, -7.75928186],
                            [39.33993744, -7.7594795],
                            [39.34017896, -7.75954539],
                            [39.34042048, -7.75954539],
                            [39.34083765, -7.75954539],
                            [39.34143047, -7.75969911],
                            [39.34143047, -7.75994069],
                            [39.34127677, -7.76007246],
                            [39.34103525, -7.76020424],
                            [39.34083765, -7.76027011],
                            [39.34079374, -7.76051168],
                            [39.34088157, -7.7607313],
                            [39.34077177, -7.76092895],
                            [39.34059612, -7.76101679],
                            [39.34046439, -7.76101679],
                            [39.3403107, -7.76101679],
                            [39.34002526, -7.76101679],
                            [39.33984961, -7.76103876],
                            [39.33973983, -7.76132425],
                            [39.33963005, -7.76158778],
                            [39.3394983, -7.76182936],
                            [39.33927873, -7.76187329],
                            [39.33912505, -7.76189525],
                            [39.33894939, -7.76189524],
                            [39.33870788, -7.76182936],
                            [39.33848831, -7.76182936],
                            [39.33826874, -7.76182935],
                            [39.33804919, -7.76182935],
                            [39.33778572, -7.76200504],
                            [39.33747832, -7.76220269],
                            [39.33728072, -7.76240035],
                            [39.33710505, -7.76257604],
                            [39.33690745, -7.76272977],
                            [39.33664398, -7.7628835],
                            [39.33633658, -7.76294937],
                            [39.33609506, -7.76314703],
                            [39.33600723, -7.76347645],
                            [39.33598528, -7.76389371],
                            [39.33605115, -7.76426705],
                            [39.33618288, -7.76453058],
                            [39.33644637, -7.76477216],
                            [39.33675374, -7.76496982],
                            [39.33695136, -7.76512354],
                            [39.33728071, -7.76512354],
                            [39.33750026, -7.76499178],
                            [39.33763201, -7.76483805],
                            [39.33789548, -7.76470628],
                            [39.33811503, -7.76470628],
                            [39.33831265, -7.76475021],
                            [39.33846635, -7.7648161],
                            [39.33857612, -7.76494786],
                            [39.33870787, -7.76525532],
                            [39.33864199, -7.76558474],
                            [39.3384883, -7.76602396],
                            [39.33835655, -7.76633143],
                            [39.33815895, -7.76670476],
                            [39.33809307, -7.76699027],
                            [39.33820286, -7.76723184],
                            [39.33837851, -7.76756126],
                            [39.33866394, -7.76756127],
                            [39.33886154, -7.76758322],
                            [39.33910306, -7.76747342],
                            [39.33921286, -7.76729772],
                            [39.33936654, -7.76710007],
                            [39.33949828, -7.76694634],
                            [39.33960806, -7.7668585],
                            [39.33980568, -7.76683654],
                            [39.34000328, -7.76694634],
                            [39.34002523, -7.76729772],
                            [39.33991545, -7.7675393],
                            [39.34004719, -7.76791265],
                            [39.34026675, -7.76817618],
                            [39.34055219, -7.76817618],
                            [39.34081566, -7.76797854],
                            [39.34110109, -7.76780284],
                            [39.34136457, -7.76756128],
                            [39.34173783, -7.76723186],
                            [39.34200131, -7.7669244],
                            [39.34215501, -7.76661693],
                            [39.34235261, -7.76633144],
                        ],
                        [
                            [39.33329523, -7.76340678],
                            [39.33318074, -7.76344496],
                            [39.33303573, -7.76350604],
                            [39.33289834, -7.76350603],
                            [39.33273042, -7.76350603],
                            [39.33260832, -7.76355184],
                            [39.33251673, -7.76360527],
                            [39.33244803, -7.76368924],
                            [39.33244803, -7.76381903],
                            [39.33260832, -7.7639717],
                            [39.33274569, -7.76403277],
                            [39.33287545, -7.76403277],
                            [39.33296704, -7.76403277],
                            [39.33311967, -7.76403277],
                            [39.3332647, -7.76403277],
                            [39.33340971, -7.76404804],
                            [39.33349366, -7.76404804],
                            [39.33362342, -7.76404804],
                            [39.33371499, -7.76397934],
                            [39.33374552, -7.76386484],
                            [39.33368448, -7.76376559],
                            [39.33360051, -7.76368162],
                            [39.33360051, -7.76362054],
                            [39.33365395, -7.76357474],
                            [39.33372264, -7.76355184],
                            [39.33385238, -7.76352131],
                            [39.33394397, -7.7634297],
                            [39.33399741, -7.76333044],
                            [39.33399741, -7.7632541],
                            [39.33393635, -7.7631854],
                            [39.33383712, -7.7631854],
                            [39.33369975, -7.76321594],
                            [39.33357763, -7.76326937],
                            [39.33348604, -7.76332282],
                            [39.33337918, -7.76335335],
                            [39.33329523, -7.76340678],
                        ],
                        [
                            [39.33475979, -7.7626807],
                            [39.33503117, -7.76263999],
                            [39.33531611, -7.76247713],
                            [39.33546535, -7.76225999],
                            [39.33551963, -7.76205642],
                            [39.33538395, -7.76177141],
                            [39.33520755, -7.76154069],
                            [39.33501759, -7.76137784],
                            [39.33489549, -7.7612964],
                            [39.33480051, -7.76113354],
                            [39.33475979, -7.76090282],
                            [39.33471909, -7.76068567],
                            [39.33458341, -7.76046852],
                            [39.33433917, -7.7602921],
                            [39.33399995, -7.76010209],
                            [39.33364717, -7.75989851],
                            [39.33332153, -7.75968136],
                            [39.33296874, -7.75946422],
                            [39.33261596, -7.75930136],
                            [39.33233102, -7.75911136],
                            [39.33201894, -7.75896206],
                            [39.33177472, -7.75890777],
                            [39.33151691, -7.7588942],
                            [39.3312591, -7.75871776],
                            [39.33097417, -7.75856848],
                            [39.33071635, -7.75837848],
                            [39.33056711, -7.75820204],
                            [39.33037715, -7.75798489],
                            [39.33024147, -7.75779488],
                            [39.33006507, -7.75769988],
                            [39.32991581, -7.75767273],
                            [39.32978013, -7.7576456],
                            [39.32952233, -7.7576456],
                            [39.32925095, -7.75769988],
                            [39.32908812, -7.7577406],
                            [39.32889816, -7.75780846],
                            [39.32874892, -7.75784917],
                            [39.32851824, -7.75788988],
                            [39.32832829, -7.75797132],
                            [39.32813833, -7.75798488],
                            [39.32800263, -7.75803917],
                            [39.32788051, -7.75816132],
                            [39.32782625, -7.75825632],
                            [39.32782625, -7.75843274],
                            [39.32790765, -7.75851418],
                            [39.32808405, -7.75854133],
                            [39.32827401, -7.75855489],
                            [39.32843683, -7.75855489],
                            [39.3287082, -7.7585956],
                            [39.329061, -7.75859561],
                            [39.32929166, -7.75859561],
                            [39.3294409, -7.75866348],
                            [39.32960374, -7.75875847],
                            [39.32963086, -7.75886705],
                            [39.32956302, -7.7589892],
                            [39.3294409, -7.7590842],
                            [39.32921024, -7.75909776],
                            [39.32893887, -7.75907062],
                            [39.32872177, -7.7590842],
                            [39.32851823, -7.75916562],
                            [39.32839613, -7.7592742],
                            [39.32840969, -7.7594642],
                            [39.32847753, -7.75962706],
                            [39.32855895, -7.75974921],
                            [39.32868107, -7.75988492],
                            [39.32883031, -7.75989849],
                            [39.32897957, -7.75991207],
                            [39.32915596, -7.75995278],
                            [39.32933236, -7.75999349],
                            [39.32956302, -7.76006135],
                            [39.32978012, -7.76011564],
                            [39.3300922, -7.76011564],
                            [39.33028216, -7.76004779],
                            [39.33052639, -7.76006135],
                            [39.33070278, -7.76019708],
                            [39.3307842, -7.76035994],
                            [39.33077062, -7.76057709],
                            [39.3306485, -7.76072637],
                            [39.33052638, -7.76088923],
                            [39.33035, -7.76105208],
                            [39.33018718, -7.76116067],
                            [39.32999721, -7.76122852],
                            [39.32982081, -7.76133709],
                            [39.32967157, -7.76144567],
                            [39.32959015, -7.76159495],
                            [39.32968513, -7.76179853],
                            [39.32987509, -7.76192068],
                            [39.33007861, -7.7619071],
                            [39.33014647, -7.7618121],
                            [39.33028215, -7.7617171],
                            [39.3304314, -7.76164924],
                            [39.33059424, -7.76164924],
                            [39.33075706, -7.76170353],
                            [39.33096058, -7.76183925],
                            [39.33120482, -7.76186639],
                            [39.33144904, -7.76182568],
                            [39.33165258, -7.76173068],
                            [39.33188325, -7.76173068],
                            [39.33212749, -7.76173069],
                            [39.33229031, -7.76185283],
                            [39.33242599, -7.76201569],
                            [39.33253453, -7.76223283],
                            [39.33256167, -7.76247712],
                            [39.33260239, -7.76273498],
                            [39.33277877, -7.76288428],
                            [39.33298231, -7.76289784],
                            [39.33315869, -7.76278928],
                            [39.33334865, -7.76266713],
                            [39.33356575, -7.76263999],
                            [39.33380998, -7.76263999],
                            [39.33409492, -7.76262642],
                            [39.33448842, -7.7626807],
                            [39.33475979, -7.7626807],
                        ],
                        [
                            [39.36159196, -7.76274479],
                            [39.36133615, -7.76274479],
                            [39.3610235, -7.76274479],
                            [39.36068241, -7.76274479],
                            [39.36036976, -7.76274479],
                            [39.36002868, -7.76277323],
                            [39.35974445, -7.76294381],
                            [39.35937493, -7.76302909],
                            [39.35906229, -7.76319966],
                            [39.35869277, -7.7634271],
                            [39.35832327, -7.76365454],
                            [39.35812431, -7.76393883],
                            [39.35789693, -7.76408098],
                            [39.35772638, -7.76427998],
                            [39.35755584, -7.76456429],
                            [39.35747056, -7.76493387],
                            [39.35755584, -7.76527503],
                            [39.35781164, -7.76530346],
                            [39.35806746, -7.7650476],
                            [39.35832327, -7.76482017],
                            [39.35866435, -7.76459272],
                            [39.35897701, -7.76439371],
                            [39.35934651, -7.76416628],
                            [39.35974445, -7.76393884],
                            [39.36002867, -7.7637114],
                            [39.36042661, -7.7635124],
                            [39.36085295, -7.7634271],
                            [39.36130773, -7.76339868],
                            [39.36181935, -7.76339868],
                            [39.36227411, -7.76337026],
                            [39.36287101, -7.76337026],
                            [39.3634679, -7.76337026],
                            [39.36403637, -7.76337026],
                            [39.36454799, -7.76337027],
                            [39.36505961, -7.76342712],
                            [39.36551438, -7.7635977],
                            [39.36625339, -7.76382515],
                            [39.36699239, -7.76408102],
                            [39.36764613, -7.76428003],
                            [39.36829987, -7.76439375],
                            [39.36878306, -7.76462119],
                            [39.36946521, -7.76493392],
                            [39.37020423, -7.76516137],
                            [39.37077269, -7.76544566],
                            [39.37151169, -7.76570154],
                            [39.37233597, -7.7658437],
                            [39.37284759, -7.76612798],
                            [39.37341607, -7.76635544],
                            [39.37421191, -7.76658287],
                            [39.37495093, -7.76686718],
                            [39.37549097, -7.76706619],
                            [39.37620155, -7.76735048],
                            [39.3769974, -7.76769164],
                            [39.37765113, -7.76797594],
                            [39.37824802, -7.76828867],
                            [39.37867437, -7.76851611],
                            [39.37921442, -7.76871512],
                            [39.37969761, -7.76894257],
                            [39.38023766, -7.76928373],
                            [39.38063558, -7.76936901],
                            [39.38109036, -7.76936901],
                            [39.38131774, -7.76914158],
                            [39.38103351, -7.76891415],
                            [39.38060716, -7.76874357],
                            [39.38023766, -7.76862984],
                            [39.37995343, -7.76845926],
                            [39.37964077, -7.76831711],
                            [39.37929969, -7.7681181],
                            [39.37890177, -7.76789067],
                            [39.37853227, -7.76772009],
                            [39.37810591, -7.76757793],
                            [39.37765113, -7.76735048],
                            [39.37731005, -7.76720834],
                            [39.37682687, -7.76700932],
                            [39.37628681, -7.76681031],
                            [39.37586047, -7.76658288],
                            [39.37557623, -7.76638387],
                            [39.37517831, -7.76618486],
                            [39.37455299, -7.76604271],
                            [39.37392769, -7.76587213],
                            [39.37352975, -7.76570155],
                            [39.37307499, -7.76561625],
                            [39.37276233, -7.76550254],
                            [39.37222229, -7.76524666],
                            [39.37162539, -7.7649908],
                            [39.37122747, -7.7649055],
                            [39.37085797, -7.76476335],
                            [39.37020423, -7.76456434],
                            [39.36952207, -7.76436533],
                            [39.36886833, -7.76416632],
                            [39.36818618, -7.76388202],
                            [39.36758928, -7.76371144],
                            [39.36707767, -7.76362615],
                            [39.36682186, -7.7633987],
                            [39.36642394, -7.76319969],
                            [39.3661397, -7.76317127],
                            [39.36582704, -7.76311441],
                            [39.36534384, -7.76294383],
                            [39.36500276, -7.7628301],
                            [39.36469012, -7.76274481],
                            [39.36412164, -7.76271638],
                            [39.36372372, -7.76271638],
                            [39.36341106, -7.76277324],
                            [39.36304156, -7.76277324],
                            [39.36270048, -7.76277324],
                            [39.36233097, -7.76277324],
                            [39.36190462, -7.76274479],
                            [39.36159196, -7.76274479],
                        ],
                        [
                            [39.39543632, -7.78424862],
                            [39.39563525, -7.78433207],
                            [39.39578928, -7.78440268],
                            [39.39597538, -7.78449896],
                            [39.39609089, -7.7845824],
                            [39.39620642, -7.7846466],
                            [39.39630268, -7.78474289],
                            [39.39640535, -7.78484558],
                            [39.39646311, -7.78494186],
                            [39.39652087, -7.78503814],
                            [39.39665563, -7.78514085],
                            [39.39678399, -7.78521146],
                            [39.396938, -7.78521146],
                            [39.39700859, -7.78513443],
                            [39.39700859, -7.78504457],
                            [39.39697651, -7.78495471],
                            [39.39688666, -7.78483916],
                            [39.39672622, -7.78469794],
                            [39.39657862, -7.7845439],
                            [39.39632835, -7.78437058],
                            [39.39618075, -7.78424862],
                            [39.39604597, -7.78416518],
                            [39.39585345, -7.78409458],
                            [39.39569302, -7.78401112],
                            [39.39552617, -7.78389558],
                            [39.39545833, -7.78383389],
                            [39.39538498, -7.7837672],
                            [39.39523096, -7.78365809],
                            [39.39510903, -7.78361956],
                            [39.39495502, -7.78351687],
                            [39.39480742, -7.78340132],
                            [39.39461489, -7.78327295],
                            [39.39437745, -7.78313814],
                            [39.39422342, -7.7830547],
                            [39.39405017, -7.782952],
                            [39.39390897, -7.78290064],
                            [39.39376138, -7.78284288],
                            [39.39362661, -7.78276585],
                            [39.39344693, -7.78265672],
                            [39.39331215, -7.78256044],
                            [39.39319664, -7.78248342],
                            [39.39308113, -7.78239997],
                            [39.39299769, -7.78234221],
                            [39.39287576, -7.78229085],
                            [39.39274742, -7.78222025],
                            [39.39261907, -7.78213037],
                            [39.39250356, -7.78208544],
                            [39.39236238, -7.78204051],
                            [39.39226612, -7.78204051],
                            [39.39216343, -7.78205976],
                            [39.39209284, -7.78213679],
                            [39.39207359, -7.78222665],
                            [39.39206717, -7.78236786],
                            [39.39212493, -7.78248982],
                            [39.39222761, -7.78261179],
                            [39.39231745, -7.78272092],
                            [39.39242012, -7.78281078],
                            [39.3925228, -7.78289423],
                            [39.39267041, -7.78297124],
                            [39.39281159, -7.78301618],
                            [39.39297202, -7.78304185],
                            [39.3931068, -7.7830547],
                            [39.39322231, -7.78309321],
                            [39.39342125, -7.78317024],
                            [39.39363303, -7.7832601],
                            [39.39388972, -7.78338848],
                            [39.39417208, -7.78355538],
                            [39.39448013, -7.78374152],
                            [39.39469832, -7.78388916],
                            [39.39495992, -7.78403869],
                            [39.39496784, -7.78404322],
                            [39.39519888, -7.78415233],
                            [39.39543632, -7.78424862],
                        ],
                        [
                            [39.39594952, -7.7897418],
                            [39.39614507, -7.78987628],
                            [39.39640173, -7.7899863],
                            [39.39647506, -7.7900352],
                            [39.39679284, -7.79009632],
                            [39.39712283, -7.79021858],
                            [39.39724506, -7.7902797],
                            [39.39747726, -7.7904753],
                            [39.39751393, -7.79063422],
                            [39.39764837, -7.7908298],
                            [39.39797837, -7.79103764],
                            [39.39814947, -7.79118433],
                            [39.39851613, -7.79131881],
                            [39.39882168, -7.79144105],
                            [39.39909057, -7.79158775],
                            [39.39937167, -7.79169777],
                            [39.39967722, -7.79180781],
                            [39.40003166, -7.79196673],
                            [39.40023943, -7.79197895],
                            [39.40033721, -7.79184447],
                            [39.40043499, -7.79173445],
                            [39.40053276, -7.79148995],
                            [39.4005572, -7.79125769],
                            [39.40088721, -7.7910132],
                            [39.40119276, -7.79059756],
                            [39.40130275, -7.79032862],
                            [39.40147386, -7.7901208],
                            [39.40162053, -7.78988852],
                            [39.40168163, -7.7896807],
                            [39.40157164, -7.7895829],
                            [39.40132719, -7.78957068],
                            [39.40105831, -7.78949732],
                            [39.40099721, -7.78946066],
                            [39.40077722, -7.78941176],
                            [39.40059388, -7.78920394],
                            [39.40028833, -7.78911836],
                            [39.40015388, -7.78917948],
                            [39.40001945, -7.7892895],
                            [39.39986057, -7.78936285],
                            [39.39961612, -7.78936285],
                            [39.39946946, -7.78931395],
                            [39.39933501, -7.78919169],
                            [39.39915169, -7.78910613],
                            [39.39902946, -7.78910613],
                            [39.39890724, -7.78915503],
                            [39.39882169, -7.78921615],
                            [39.39867503, -7.78925283],
                            [39.39845503, -7.78922837],
                            [39.39833281, -7.78910613],
                            [39.39819837, -7.78900833],
                            [39.39794171, -7.78895943],
                            [39.39779504, -7.78895943],
                            [39.3977095, -7.7889472],
                            [39.39745283, -7.78895942],
                            [39.39719617, -7.7889472],
                            [39.39696395, -7.7888494],
                            [39.39678061, -7.7888005],
                            [39.39646284, -7.78873938],
                            [39.39627952, -7.78873938],
                            [39.3961084, -7.7888005],
                            [39.39583952, -7.78898386],
                            [39.39571731, -7.78915502],
                            [39.39548508, -7.78927726],
                            [39.39566842, -7.78947286],
                            [39.3957662, -7.78957066],
                            [39.39594952, -7.7897418],
                        ],
                        [
                            [39.38645302, -7.78924851],
                            [39.38659897, -7.78941068],
                            [39.38682599, -7.78952422],
                            [39.38703677, -7.78963776],
                            [39.38716649, -7.7896702],
                            [39.38740973, -7.78973507],
                            [39.38762053, -7.78983241],
                            [39.38784755, -7.78994593],
                            [39.38807456, -7.79005945],
                            [39.38830158, -7.79027032],
                            [39.38847995, -7.79048117],
                            [39.38865833, -7.79075688],
                            [39.38886912, -7.79106506],
                            [39.38907992, -7.79132456],
                            [39.38927451, -7.79155164],
                            [39.38943666, -7.79176247],
                            [39.3896961, -7.79189223],
                            [39.38992312, -7.79192466],
                            [39.39013392, -7.79194089],
                            [39.39032851, -7.79194089],
                            [39.39034471, -7.7917787],
                            [39.39029609, -7.79160029],
                            [39.39016635, -7.79145431],
                            [39.39008529, -7.79129212],
                            [39.38998798, -7.79116237],
                            [39.38982585, -7.79098396],
                            [39.38964748, -7.79083799],
                            [39.38953397, -7.79065958],
                            [39.38945288, -7.79049738],
                            [39.38932315, -7.79030277],
                            [39.38920964, -7.79014056],
                            [39.38911235, -7.78999458],
                            [39.38898263, -7.78979995],
                            [39.38886912, -7.78967021],
                            [39.38875561, -7.78952423],
                            [39.38857725, -7.78937826],
                            [39.38844752, -7.78928095],
                            [39.38823671, -7.78923228],
                            [39.38800971, -7.78911874],
                            [39.38781512, -7.78900522],
                            [39.38749081, -7.78885924],
                            [39.38716649, -7.7887457],
                            [39.38700436, -7.78868083],
                            [39.38680976, -7.78863216],
                            [39.38663139, -7.78858351],
                            [39.38646922, -7.78858351],
                            [39.38630709, -7.78858351],
                            [39.38619358, -7.78863216],
                            [39.38608007, -7.78872949],
                            [39.38612872, -7.7889079],
                            [39.38629085, -7.78911874],
                            [39.38645302, -7.78924851],
                        ],
                        [
                            [39.37670717, -7.79594612],
                            [39.37662232, -7.7960876],
                            [39.3765233, -7.7962149],
                            [39.37641015, -7.79631394],
                            [39.3763253, -7.79641296],
                            [39.3762687, -7.79661104],
                            [39.376297, -7.79680909],
                            [39.37632529, -7.79693643],
                            [39.37646672, -7.796993],
                            [39.37676374, -7.79714863],
                            [39.37690519, -7.79714863],
                            [39.37704662, -7.79714863],
                            [39.3771032, -7.79704959],
                            [39.37734366, -7.79716277],
                            [39.37744265, -7.79720522],
                            [39.37759824, -7.79717693],
                            [39.37766897, -7.79706374],
                            [39.37788112, -7.79706374],
                            [39.37806501, -7.7970496],
                            [39.37824887, -7.79706374],
                            [39.37840446, -7.79706374],
                            [39.37857419, -7.79707789],
                            [39.37870148, -7.79717694],
                            [39.37887121, -7.79730426],
                            [39.37904093, -7.79747402],
                            [39.37916822, -7.79765793],
                            [39.37938039, -7.79784186],
                            [39.37963497, -7.7980682],
                            [39.37990372, -7.79830871],
                            [39.38014417, -7.79853507],
                            [39.38037047, -7.79877557],
                            [39.38052604, -7.79894533],
                            [39.38068163, -7.79903023],
                            [39.38080894, -7.79904437],
                            [39.3808655, -7.79888875],
                            [39.3808655, -7.79873313],
                            [39.38095037, -7.79867655],
                            [39.38107768, -7.79870484],
                            [39.38121912, -7.79877557],
                            [39.38130398, -7.79864825],
                            [39.38130398, -7.79840774],
                            [39.3811201, -7.79809651],
                            [39.38102109, -7.79784187],
                            [39.38085136, -7.79753062],
                            [39.38070993, -7.79717694],
                            [39.3805685, -7.7968657],
                            [39.38041291, -7.79655445],
                            [39.38028561, -7.79627152],
                            [39.38014418, -7.79603101],
                            [39.38000273, -7.79580466],
                            [39.37984714, -7.79555],
                            [39.37969157, -7.79533779],
                            [39.37955013, -7.79516802],
                            [39.37942284, -7.79489923],
                            [39.37928139, -7.79461629],
                            [39.3791258, -7.7942909],
                            [39.37901267, -7.79406455],
                            [39.37894194, -7.79388064],
                            [39.3788288, -7.79373916],
                            [39.37872979, -7.79358353],
                            [39.37865907, -7.79344207],
                            [39.37854591, -7.79332888],
                            [39.37840448, -7.793244],
                            [39.37822059, -7.79321571],
                            [39.37799431, -7.79315911],
                            [39.37795185, -7.79304595],
                            [39.37793772, -7.79290447],
                            [39.37783872, -7.79276299],
                            [39.37771141, -7.79264981],
                            [39.37759826, -7.79259322],
                            [39.37747097, -7.79259322],
                            [39.3773861, -7.79270641],
                            [39.37732954, -7.79280543],
                            [39.37730124, -7.79291861],
                            [39.37718809, -7.7929752],
                            [39.37715981, -7.79311668],
                            [39.37731539, -7.79321571],
                            [39.37747097, -7.79338548],
                            [39.37744267, -7.79352696],
                            [39.37734367, -7.79358353],
                            [39.37723051, -7.79369672],
                            [39.37706078, -7.79375329],
                            [39.37690521, -7.79380988],
                            [39.37684863, -7.79393722],
                            [39.37689105, -7.79405039],
                            [39.37697592, -7.79420602],
                            [39.37707493, -7.79438993],
                            [39.37708907, -7.79455969],
                            [39.37714564, -7.79475775],
                            [39.37703249, -7.79488508],
                            [39.37693348, -7.79494167],
                            [39.37673546, -7.79496996],
                            [39.37659403, -7.79502654],
                            [39.37655159, -7.79515386],
                            [39.37660816, -7.79526706],
                            [39.37670718, -7.79539438],
                            [39.37676375, -7.79557829],
                            [39.37674961, -7.79576221],
                            [39.37670717, -7.79594612],
                        ],
                        [
                            [39.41120781, -7.8103942],
                            [39.41108641, -7.8105642],
                            [39.4108679, -7.81070991],
                            [39.41050369, -7.81075848],
                            [39.41026089, -7.81063705],
                            [39.41009093, -7.8105642],
                            [39.4098967, -7.81041848],
                            [39.40962962, -7.81032134],
                            [39.40943538, -7.81019992],
                            [39.40916831, -7.81015135],
                            [39.40887695, -7.81027278],
                            [39.40865842, -7.81046705],
                            [39.40848846, -7.81066133],
                            [39.40824567, -7.81078276],
                            [39.40812427, -7.81100133],
                            [39.40812427, -7.81126846],
                            [39.40829423, -7.81158418],
                            [39.4085613, -7.81211845],
                            [39.40870698, -7.81248273],
                            [39.4089255, -7.81299272],
                            [39.4091683, -7.81364842],
                            [39.40921686, -7.81410985],
                            [39.40921685, -7.81444984],
                            [39.40931396, -7.81478984],
                            [39.40936252, -7.81500841],
                            [39.40958104, -7.8153484],
                            [39.40979956, -7.81556697],
                            [39.40979956, -7.8158584],
                            [39.40967816, -7.81610124],
                            [39.40943535, -7.81610124],
                            [39.40924111, -7.8160284],
                            [39.40904689, -7.81590697],
                            [39.40880408, -7.81571268],
                            [39.40848844, -7.81556696],
                            [39.40793001, -7.81546982],
                            [39.4073473, -7.81546982],
                            [39.40676457, -7.8153241],
                            [39.40632754, -7.81520268],
                            [39.40593906, -7.81520268],
                            [39.40559915, -7.81520268],
                            [39.40511354, -7.81522696],
                            [39.40477364, -7.81534838],
                            [39.40457939, -7.8156398],
                            [39.40470079, -7.81600409],
                            [39.405065, -7.81641693],
                            [39.40525922, -7.81678122],
                            [39.40525922, -7.81704835],
                            [39.40506499, -7.81743692],
                            [39.40467651, -7.81750978],
                            [39.4044337, -7.81770406],
                            [39.40406951, -7.81770406],
                            [39.40382671, -7.81770406],
                            [39.40370531, -7.81758263],
                            [39.40338968, -7.81750977],
                            [39.40307404, -7.81743691],
                            [39.40275839, -7.81750977],
                            [39.40261271, -7.81765548],
                            [39.40256417, -7.81792261],
                            [39.40263699, -7.81823833],
                            [39.40280695, -7.81855404],
                            [39.40290407, -7.81882117],
                            [39.40280695, -7.81901546],
                            [39.40253988, -7.81906403],
                            [39.4022728, -7.8191126],
                            [39.40207855, -7.81933117],
                            [39.40205427, -7.81952544],
                            [39.40200571, -7.81974401],
                            [39.40178719, -7.81984115],
                            [39.4015444, -7.81988972],
                            [39.40127732, -7.81998686],
                            [39.40120447, -7.82035113],
                            [39.4013016, -7.82056971],
                            [39.40152012, -7.82078828],
                            [39.40186002, -7.820934],
                            [39.40219995, -7.8210797],
                            [39.40256415, -7.82134684],
                            [39.40283122, -7.8215897],
                            [39.40302546, -7.82188111],
                            [39.40324398, -7.8219054],
                            [39.40351105, -7.82192969],
                            [39.40382669, -7.82202683],
                            [39.40414234, -7.82202683],
                            [39.40421518, -7.82185685],
                            [39.40423946, -7.82151685],
                            [39.40404522, -7.82127399],
                            [39.40380242, -7.8210797],
                            [39.40343823, -7.82081257],
                            [39.40319543, -7.82056971],
                            [39.40314687, -7.82030257],
                            [39.40314687, -7.8199383],
                            [39.40324399, -7.8195983],
                            [39.4035839, -7.81940402],
                            [39.40387526, -7.81933117],
                            [39.40414235, -7.81933118],
                            [39.4044337, -7.81937975],
                            [39.40457938, -7.81952545],
                            [39.40470078, -7.81969545],
                            [39.40470078, -7.81996259],
                            [39.40460365, -7.82010831],
                            [39.40460365, -7.82035115],
                            [39.40484645, -7.82061829],
                            [39.4050407, -7.82081258],
                            [39.40533204, -7.82083687],
                            [39.40579337, -7.82086115],
                            [39.40627896, -7.82086116],
                            [39.40657032, -7.82059401],
                            [39.40695879, -7.82025402],
                            [39.40739584, -7.82018118],
                            [39.40856126, -7.82027831],
                            [39.40894975, -7.82042402],
                            [39.4093625, -7.82049688],
                            [39.40962958, -7.8206426],
                            [39.40965386, -7.82090974],
                            [39.40953245, -7.82122544],
                            [39.40936249, -7.82149258],
                            [39.40926537, -7.8218083],
                            [39.40936249, -7.822124],
                            [39.40970241, -7.82251257],
                            [39.41001805, -7.82273113],
                            [39.41040652, -7.82280399],
                            [39.4105522, -7.82304685],
                            [39.410795, -7.82343542],
                            [39.41091639, -7.82377541],
                            [39.41094067, -7.82406683],
                            [39.41094067, -7.82440682],
                            [39.41094067, -7.82469825],
                            [39.41094066, -7.82494111],
                            [39.41094066, -7.82520823],
                            [39.41111062, -7.82530538],
                            [39.41132915, -7.82518397],
                            [39.41162051, -7.82503825],
                            [39.41183902, -7.82489254],
                            [39.41215466, -7.82489254],
                            [39.41234891, -7.8249654],
                            [39.41256741, -7.82498968],
                            [39.41273737, -7.8247954],
                            [39.41276166, -7.82450397],
                            [39.41268882, -7.82421256],
                            [39.41254315, -7.82389684],
                            [39.41234891, -7.82360541],
                            [39.41203327, -7.82328971],
                            [39.41176619, -7.82304685],
                            [39.41132916, -7.82273113],
                            [39.41111064, -7.82239115],
                            [39.41106208, -7.82209972],
                            [39.4110378, -7.82183259],
                            [39.4110378, -7.8212983],
                            [39.4110378, -7.82083689],
                            [39.4110378, -7.82052118],
                            [39.41103781, -7.82025403],
                            [39.41130489, -7.82018119],
                            [39.41159625, -7.82018119],
                            [39.41183904, -7.82018119],
                            [39.4122518, -7.82003548],
                            [39.41210612, -7.81974405],
                            [39.41191188, -7.81916121],
                            [39.41179048, -7.81886978],
                            [39.41164482, -7.81852979],
                            [39.41149914, -7.81821408],
                            [39.41115922, -7.81765552],
                            [39.41115922, -7.81724266],
                            [39.41115922, -7.81675696],
                            [39.41098926, -7.81636839],
                            [39.41098926, -7.81571269],
                            [39.41098927, -7.81515413],
                            [39.41098927, -7.81459556],
                            [39.41111067, -7.814207],
                            [39.41125636, -7.8135513],
                            [39.41142632, -7.81296845],
                            [39.41162056, -7.81243418],
                            [39.41181479, -7.81209418],
                            [39.41210616, -7.81189991],
                            [39.4125432, -7.81180276],
                            [39.41290739, -7.81180277],
                            [39.41315019, -7.81180277],
                            [39.41336872, -7.81194847],
                            [39.41353866, -7.81231275],
                            [39.4136115, -7.81265276],
                            [39.41366006, -7.81296845],
                            [39.41383002, -7.81335703],
                            [39.41399998, -7.8135756],
                            [39.41416994, -7.81367273],
                            [39.41458269, -7.81367273],
                            [39.4148255, -7.81367273],
                            [39.4151654, -7.81367273],
                            [39.41550533, -7.81374559],
                            [39.41555389, -7.81410988],
                            [39.4155296, -7.81449843],
                            [39.41550532, -7.81481415],
                            [39.4154082, -7.81505701],
                            [39.41550532, -7.81529985],
                            [39.41574812, -7.81551842],
                            [39.41603947, -7.81593128],
                            [39.41633083, -7.81634412],
                            [39.41667075, -7.81668413],
                            [39.41698638, -7.81678127],
                            [39.41732631, -7.81675699],
                            [39.41759337, -7.81653842],
                            [39.41800614, -7.81641699],
                            [39.4182975, -7.81651413],
                            [39.41846745, -7.81680556],
                            [39.41820038, -7.81690271],
                            [39.41822466, -7.81707269],
                            [39.41837032, -7.81726698],
                            [39.41871025, -7.81750984],
                            [39.41912301, -7.81743698],
                            [39.41912301, -7.81712127],
                            [39.4191473, -7.81668414],
                            [39.41922012, -7.81622271],
                            [39.41922012, -7.81595558],
                            [39.41909874, -7.81563986],
                            [39.41883166, -7.81532415],
                            [39.41827323, -7.81488702],
                            [39.41788475, -7.81447416],
                            [39.41754482, -7.8140856],
                            [39.4171078, -7.81355133],
                            [39.41676787, -7.8130899],
                            [39.41623373, -7.81265277],
                            [39.4157967, -7.81214276],
                            [39.41565102, -7.81170563],
                            [39.41540822, -7.8112442],
                            [39.4151897, -7.8109285],
                            [39.41487407, -7.81053993],
                            [39.41450987, -7.81005423],
                            [39.41424279, -7.80961708],
                            [39.41397572, -7.8092771],
                            [39.41373292, -7.80905853],
                            [39.41346584, -7.80883995],
                            [39.41329589, -7.80866995],
                            [39.41307737, -7.80852425],
                            [39.41281028, -7.80857282],
                            [39.41256748, -7.80886423],
                            [39.41242181, -7.80900995],
                            [39.41232469, -7.80915566],
                            [39.41222757, -7.80932566],
                            [39.41222757, -7.80949565],
                            [39.41205761, -7.8097385],
                            [39.41179052, -7.8097385],
                            [39.41162058, -7.80978707],
                            [39.41145061, -7.8099085],
                            [39.41130493, -7.81017564],
                            [39.41120781, -7.8103942],
                        ],
                        [
                            [39.43827497, -7.82513096],
                            [39.43809893, -7.82513096],
                            [39.43795489, -7.82524303],
                            [39.43781083, -7.8253871],
                            [39.43765079, -7.82556319],
                            [39.43758678, -7.82577129],
                            [39.43749075, -7.82601142],
                            [39.43729868, -7.82626756],
                            [39.43715464, -7.82642763],
                            [39.4369946, -7.82649168],
                            [39.43672251, -7.82660373],
                            [39.43656246, -7.82660373],
                            [39.4363384, -7.82671579],
                            [39.43619436, -7.82679582],
                            [39.43595428, -7.82689187],
                            [39.43569821, -7.82706796],
                            [39.43549014, -7.82735613],
                            [39.43529808, -7.82756423],
                            [39.43521805, -7.8277243],
                            [39.43512202, -7.8278844],
                            [39.43501, -7.82801247],
                            [39.43486595, -7.82801247],
                            [39.4347059, -7.82810851],
                            [39.43452986, -7.82810851],
                            [39.4343538, -7.82812452],
                            [39.43424176, -7.82822056],
                            [39.4340497, -7.82825258],
                            [39.43387365, -7.82825258],
                            [39.43372961, -7.82810851],
                            [39.43364958, -7.82798044],
                            [39.43352156, -7.82782036],
                            [39.43331349, -7.82780434],
                            [39.43313743, -7.82786838],
                            [39.4330094, -7.82794841],
                            [39.43289736, -7.82817253],
                            [39.43281735, -7.82834863],
                            [39.43284934, -7.82854073],
                            [39.43288136, -7.82871682],
                            [39.43280133, -7.82897295],
                            [39.4326893, -7.82921309],
                            [39.4327053, -7.82943719],
                            [39.43280133, -7.82961329],
                            [39.43292937, -7.82985341],
                            [39.43320145, -7.82986943],
                            [39.43348953, -7.82985341],
                            [39.4337136, -7.82985341],
                            [39.43401768, -7.82975736],
                            [39.43419374, -7.82966132],
                            [39.43448182, -7.82951724],
                            [39.43470589, -7.82938917],
                            [39.43488195, -7.82929313],
                            [39.43513803, -7.82926112],
                            [39.43541012, -7.8292131],
                            [39.43560217, -7.82911705],
                            [39.43585824, -7.82911705],
                            [39.43621035, -7.82911705],
                            [39.43656245, -7.82911705],
                            [39.43689856, -7.82911705],
                            [39.43718664, -7.82918108],
                            [39.43745872, -7.82932516],
                            [39.43765078, -7.82950125],
                            [39.4377628, -7.82967735],
                            [39.43777882, -7.82988545],
                            [39.43777882, -7.83009357],
                            [39.43779481, -7.83028567],
                            [39.43792285, -7.83047776],
                            [39.43809891, -7.83073389],
                            [39.43830696, -7.83103806],
                            [39.43849902, -7.8313102],
                            [39.4387711, -7.83147028],
                            [39.43902718, -7.83156633],
                            [39.43923524, -7.83156633],
                            [39.4394753, -7.83156633],
                            [39.43971539, -7.83145428],
                            [39.43989143, -7.83124618],
                            [39.44000347, -7.83087799],
                            [39.44013152, -7.83036572],
                            [39.44021153, -7.82977341],
                            [39.44030756, -7.82934117],
                            [39.44037159, -7.82884492],
                            [39.44045162, -7.82842871],
                            [39.44053164, -7.82798047],
                            [39.44057965, -7.8276603],
                            [39.44064368, -7.82730811],
                            [39.44075571, -7.82703598],
                            [39.44077171, -7.82689189],
                            [39.4406917, -7.82671581],
                            [39.44059567, -7.82636362],
                            [39.44043562, -7.8261395],
                            [39.44035559, -7.82581933],
                            [39.44035559, -7.82540311],
                            [39.44040361, -7.82505094],
                            [39.44043562, -7.82476278],
                            [39.44045164, -7.82455467],
                            [39.44040361, -7.82436258],
                            [39.44030758, -7.82431455],
                            [39.44008353, -7.82431455],
                            [39.4396834, -7.82444262],
                            [39.43953936, -7.82450665],
                            [39.43941132, -7.82458669],
                            [39.43933129, -7.82474677],
                            [39.43921926, -7.82489084],
                            [39.43913924, -7.82503491],
                            [39.43897919, -7.82514698],
                            [39.43888317, -7.82514698],
                            [39.4386751, -7.82514698],
                            [39.43846704, -7.82514698],
                            [39.43827497, -7.82513096],
                        ],
                        [
                            [39.4144742, -7.83052239],
                            [39.41429763, -7.83035936],
                            [39.41418897, -7.83016915],
                            [39.41408032, -7.82996537],
                            [39.41394448, -7.82977517],
                            [39.4138494, -7.82958497],
                            [39.41372716, -7.82961214],
                            [39.41357774, -7.8296529],
                            [39.41346909, -7.82955779],
                            [39.41323818, -7.82947627],
                            [39.41303444, -7.82950345],
                            [39.41288504, -7.82957138],
                            [39.41278995, -7.82970723],
                            [39.41277637, -7.82991102],
                            [39.41278994, -7.8300333],
                            [39.41285786, -7.83023708],
                            [39.41300728, -7.83052239],
                            [39.41318385, -7.83063106],
                            [39.41330609, -7.83078051],
                            [39.41348266, -7.83094355],
                            [39.41372715, -7.83113375],
                            [39.41393089, -7.83129678],
                            [39.41408031, -7.83148698],
                            [39.41421613, -7.83156849],
                            [39.41459644, -7.83156849],
                            [39.41474586, -7.83150056],
                            [39.4150311, -7.83140547],
                            [39.41531634, -7.83135113],
                            [39.41545216, -7.83117452],
                            [39.41545216, -7.83105225],
                            [39.41545216, -7.83095713],
                            [39.41537067, -7.83073977],
                            [39.41524842, -7.83054956],
                            [39.4151126, -7.83046804],
                            [39.41497676, -7.83056315],
                            [39.41484094, -7.83064467],
                            [39.41466437, -7.83065825],
                            [39.41450137, -7.83054955],
                            [39.4144742, -7.83052239],
                        ],
                        [
                            [39.41076611, -7.83098429],
                            [39.41063029, -7.83113374],
                            [39.41052162, -7.83121526],
                            [39.41048087, -7.83136469],
                            [39.41054879, -7.83150054],
                            [39.41058954, -7.83163641],
                            [39.41056237, -7.83179944],
                            [39.41042655, -7.83194888],
                            [39.41024997, -7.83204399],
                            [39.41012773, -7.83219342],
                            [39.41000548, -7.83231569],
                            [39.40986966, -7.83242438],
                            [39.40982891, -7.83256024],
                            [39.40986966, -7.83272327],
                            [39.41003265, -7.83272327],
                            [39.41020922, -7.83281836],
                            [39.4103043, -7.83299499],
                            [39.41024997, -7.83321236],
                            [39.41016847, -7.83332105],
                            [39.41018205, -7.83348408],
                            [39.41031787, -7.83366069],
                            [39.41050803, -7.83381013],
                            [39.4106982, -7.83389164],
                            [39.4109291, -7.83390524],
                            [39.41124151, -7.83385089],
                            [39.41144525, -7.8336607],
                            [39.41151315, -7.83341615],
                            [39.41152675, -7.83315803],
                            [39.41154033, -7.8328863],
                            [39.41147241, -7.83262818],
                            [39.41147241, -7.83235646],
                            [39.41151316, -7.83205757],
                            [39.41158108, -7.8318402],
                            [39.41158108, -7.83160924],
                            [39.411649, -7.83141905],
                            [39.4117984, -7.83120166],
                            [39.4117984, -7.83090278],
                            [39.41174408, -7.83065823],
                            [39.41162183, -7.83048162],
                            [39.41140451, -7.83041369],
                            [39.41126868, -7.83035935],
                            [39.41118719, -7.83026425],
                            [39.4110921, -7.83026425],
                            [39.41101061, -7.83037293],
                            [39.41096986, -7.83052238],
                            [39.41096986, -7.83069899],
                            [39.41091553, -7.83084844],
                            [39.41076611, -7.83098429],
                        ],
                        [
                            [39.4391552, -7.83438381],
                            [39.43915519, -7.83472],
                            [39.43912319, -7.83504016],
                            [39.43912319, -7.83532831],
                            [39.43915519, -7.83561646],
                            [39.4391592, -7.83610472],
                            [39.4391792, -7.83662499],
                            [39.43921921, -7.83700519],
                            [39.43929924, -7.83734536],
                            [39.43929924, -7.83776558],
                            [39.43925922, -7.83818581],
                            [39.43905915, -7.83830586],
                            [39.4388391, -7.83830586],
                            [39.43861903, -7.83832588],
                            [39.438539, -7.83838591],
                            [39.43863903, -7.83858601],
                            [39.43873907, -7.83868606],
                            [39.43889911, -7.83882613],
                            [39.43913918, -7.8389262],
                            [39.43939926, -7.8389262],
                            [39.43971936, -7.83900623],
                            [39.4398963, -7.83910063],
                            [39.44001944, -7.83916633],
                            [39.44019949, -7.83924636],
                            [39.44023951, -7.8394865],
                            [39.44017949, -7.83974662],
                            [39.44001944, -7.83992672],
                            [39.43975937, -7.84014683],
                            [39.43968871, -7.84017178],
                            [39.43941925, -7.84026689],
                            [39.4392192, -7.84038696],
                            [39.43915918, -7.84060707],
                            [39.43915918, -7.84096727],
                            [39.43919918, -7.84128744],
                            [39.43897913, -7.84156758],
                            [39.43873906, -7.84168763],
                            [39.43845896, -7.84170765],
                            [39.4382589, -7.84176768],
                            [39.4382389, -7.84194777],
                            [39.43831891, -7.8422079],
                            [39.43851898, -7.84232797],
                            [39.43873905, -7.84232797],
                            [39.43905913, -7.84246804],
                            [39.4392592, -7.84258811],
                            [39.43933922, -7.84280822],
                            [39.43933921, -7.84310838],
                            [39.4392592, -7.84340853],
                            [39.43921919, -7.8437087],
                            [39.43901913, -7.84374872],
                            [39.438639, -7.8437087],
                            [39.43821888, -7.84370869],
                            [39.43799883, -7.84384877],
                            [39.43777876, -7.84410891],
                            [39.43765871, -7.84446908],
                            [39.4375987, -7.84480927],
                            [39.43753868, -7.84508942],
                            [39.43721859, -7.84522949],
                            [39.43705853, -7.84522949],
                            [39.43661841, -7.84518946],
                            [39.43621828, -7.84514944],
                            [39.43605824, -7.84490931],
                            [39.43589819, -7.84466919],
                            [39.43573815, -7.844329],
                            [39.43567812, -7.84406887],
                            [39.4355581, -7.84376871],
                            [39.43539804, -7.84348856],
                            [39.43531803, -7.84346856],
                            [39.43517799, -7.84360863],
                            [39.43511796, -7.84388878],
                            [39.43499793, -7.84420895],
                            [39.43499793, -7.84444907],
                            [39.43483787, -7.84470919],
                            [39.43455779, -7.84472921],
                            [39.43423769, -7.84468919],
                            [39.43411767, -7.84442904],
                            [39.43405764, -7.84420894],
                            [39.43395761, -7.84402884],
                            [39.43379757, -7.8437687],
                            [39.43353749, -7.84360862],
                            [39.43351749, -7.84332848],
                            [39.43365753, -7.84314838],
                            [39.43379757, -7.84300831],
                            [39.4339176, -7.84282822],
                            [39.4339176, -7.84252805],
                            [39.43367754, -7.84210783],
                            [39.43311737, -7.8420678],
                            [39.43279727, -7.84200778],
                            [39.43265723, -7.84168761],
                            [39.43265724, -7.84140746],
                            [39.43269725, -7.84114734],
                            [39.43275727, -7.8409072],
                            [39.43275727, -7.8407271],
                            [39.4325372, -7.84044695],
                            [39.43235716, -7.84024685],
                            [39.4321771, -7.84004675],
                            [39.43199705, -7.84006676],
                            [39.43191702, -7.84016681],
                            [39.43181699, -7.8403469],
                            [39.43163694, -7.84054702],
                            [39.43147689, -7.84074711],
                            [39.43131684, -7.84096723],
                            [39.4311768, -7.84106728],
                            [39.43101676, -7.84074711],
                            [39.43089672, -7.84046697],
                            [39.43087672, -7.84028687],
                            [39.43071666, -7.84032689],
                            [39.43059663, -7.84050699],
                            [39.4305166, -7.84064706],
                            [39.43019651, -7.84068708],
                            [39.42993642, -7.84068708],
                            [39.42967636, -7.84048697],
                            [39.42967636, -7.8401668],
                            [39.4298364, -7.83994669],
                            [39.42989641, -7.83964653],
                            [39.42989642, -7.83938639],
                            [39.42989642, -7.83908624],
                            [39.42989642, -7.83878607],
                            [39.42977639, -7.83846592],
                            [39.42953633, -7.83818577],
                            [39.42953633, -7.83800568],
                            [39.42953633, -7.83782558],
                            [39.42961634, -7.83760546],
                            [39.42981641, -7.8373053],
                            [39.42989643, -7.83696512],
                            [39.43007648, -7.83662495],
                            [39.43027655, -7.83636481],
                            [39.43035657, -7.83602464],
                            [39.43035657, -7.83570447],
                            [39.43031656, -7.83544433],
                            [39.43015652, -7.83522422],
                            [39.42999648, -7.83506413],
                            [39.42969638, -7.83504413],
                            [39.4294363, -7.83504412],
                            [39.42919623, -7.83510415],
                            [39.42865608, -7.83536429],
                            [39.42865607, -7.83558439],
                            [39.42871609, -7.83588456],
                            [39.42865607, -7.83610468],
                            [39.42841599, -7.83628476],
                            [39.42821593, -7.83652489],
                            [39.42807589, -7.83670499],
                            [39.42805589, -7.83694511],
                            [39.42807589, -7.83718524],
                            [39.42819593, -7.83734531],
                            [39.42839598, -7.83758545],
                            [39.42851602, -7.83782558],
                            [39.42861605, -7.8380857],
                            [39.42865606, -7.83840587],
                            [39.42865606, -7.83874606],
                            [39.42865606, -7.83910624],
                            [39.42869606, -7.83952645],
                            [39.42869605, -7.83990667],
                            [39.42867605, -7.84032688],
                            [39.42855601, -7.8407471],
                            [39.42827593, -7.8411273],
                            [39.42807587, -7.84138744],
                            [39.42807587, -7.84168759],
                            [39.42821591, -7.84198775],
                            [39.42841596, -7.8422679],
                            [39.42871606, -7.84216785],
                            [39.42891612, -7.84202778],
                            [39.42915618, -7.84190771],
                            [39.42945628, -7.84190771],
                            [39.42977638, -7.84216786],
                            [39.42961632, -7.84242798],
                            [39.42931624, -7.8426481],
                            [39.4291762, -7.8428282],
                            [39.42929622, -7.84298827],
                            [39.4295563, -7.84292825],
                            [39.42979637, -7.84294825],
                            [39.42995643, -7.84312835],
                            [39.43007645, -7.84330844],
                            [39.43007645, -7.84354857],
                            [39.42999644, -7.84386874],
                            [39.42997642, -7.84420892],
                            [39.4298564, -7.84442903],
                            [39.4295763, -7.84422893],
                            [39.42935625, -7.84402883],
                            [39.42911618, -7.84392877],
                            [39.42883608, -7.84390875],
                            [39.42859601, -7.84394878],
                            [39.42863602, -7.8441889],
                            [39.42887609, -7.84462914],
                            [39.42901613, -7.84488927],
                            [39.42925621, -7.8451294],
                            [39.42947627, -7.84532951],
                            [39.42961631, -7.84556963],
                            [39.42961631, -7.84580975],
                            [39.42941625, -7.8458898],
                            [39.42909615, -7.84576973],
                            [39.42879607, -7.84546958],
                            [39.42843596, -7.84528948],
                            [39.42803584, -7.84500933],
                            [39.42761572, -7.84462913],
                            [39.42729562, -7.84436899],
                            [39.42695553, -7.84404882],
                            [39.42671546, -7.84376867],
                            [39.42627532, -7.84330843],
                            [39.42591521, -7.84292823],
                            [39.42563513, -7.84262808],
                            [39.42549509, -7.84236794],
                            [39.42539507, -7.84208779],
                            [39.42523503, -7.84178763],
                            [39.42519501, -7.8415275],
                            [39.42497495, -7.84146746],
                            [39.42469486, -7.84164755],
                            [39.42449481, -7.84190769],
                            [39.42433475, -7.84208779],
                            [39.42405467, -7.84228789],
                            [39.42373457, -7.84228788],
                            [39.42353451, -7.84228788],
                            [39.42333446, -7.84246798],
                            [39.42325443, -7.8427081],
                            [39.42325443, -7.84302827],
                            [39.42327443, -7.84330842],
                            [39.42341447, -7.84346851],
                            [39.42365454, -7.84362859],
                            [39.42391462, -7.84364861],
                            [39.42411467, -7.84366861],
                            [39.42447478, -7.84380869],
                            [39.42515499, -7.84412886],
                            [39.42537506, -7.84428894],
                            [39.4255351, -7.84442901],
                            [39.42573515, -7.84460911],
                            [39.42595522, -7.84474918],
                            [39.42613528, -7.8449493],
                            [39.42625532, -7.84508937],
                            [39.42645537, -7.84526947],
                            [39.42667544, -7.84548957],
                            [39.42691551, -7.84576972],
                            [39.42719559, -7.84598984],
                            [39.4272556, -7.84624998],
                            [39.4272556, -7.8466702],
                            [39.4272556, -7.84705039],
                            [39.42735562, -7.84719047],
                            [39.42755569, -7.8470504],
                            [39.42771574, -7.84693033],
                            [39.42791581, -7.84683028],
                            [39.42817587, -7.84693033],
                            [39.4282759, -7.84713044],
                            [39.4282759, -7.84739057],
                            [39.42831591, -7.84767072],
                            [39.42849597, -7.84785081],
                            [39.42887608, -7.84781079],
                            [39.42919617, -7.84779079],
                            [39.42943624, -7.84773076],
                            [39.42967633, -7.8478108],
                            [39.42951627, -7.84809096],
                            [39.4292962, -7.8483911],
                            [39.42903613, -7.84873128],
                            [39.42879606, -7.84899142],
                            [39.42879606, -7.84927156],
                            [39.42883606, -7.84963176],
                            [39.42883605, -7.85003195],
                            [39.42893609, -7.85039215],
                            [39.42911615, -7.85057224],
                            [39.42911615, -7.85083239],
                            [39.42909613, -7.85121258],
                            [39.42909613, -7.8516128],
                            [39.42925619, -7.85179289],
                            [39.42937621, -7.85171285],
                            [39.42957627, -7.85173285],
                            [39.42967631, -7.85189294],
                            [39.42987636, -7.85209305],
                            [39.4300164, -7.85227314],
                            [39.4303365, -7.85227314],
                            [39.43079664, -7.85221312],
                            [39.43109672, -7.85207305],
                            [39.43127678, -7.85187293],
                            [39.43147685, -7.85161281],
                            [39.4316769, -7.85127262],
                            [39.43179693, -7.85095245],
                            [39.43203702, -7.85057226],
                            [39.43227709, -7.85025209],
                            [39.43247714, -7.84991192],
                            [39.43271721, -7.8496918],
                            [39.43291728, -7.8496718],
                            [39.43307732, -7.84979185],
                            [39.43323738, -7.8498919],
                            [39.43355748, -7.8498919],
                            [39.43381754, -7.84983188],
                            [39.43415764, -7.84965178],
                            [39.43441773, -7.84943168],
                            [39.4346578, -7.84921156],
                            [39.43487787, -7.84895142],
                            [39.43507792, -7.84869129],
                            [39.43527799, -7.84843115],
                            [39.43547805, -7.84813099],
                            [39.43573813, -7.84789087],
                            [39.43605823, -7.84753068],
                            [39.43645834, -7.84725053],
                            [39.43673843, -7.84697039],
                            [39.43699851, -7.84669024],
                            [39.43747865, -7.84604991],
                            [39.43767872, -7.84574976],
                            [39.43785876, -7.84552964],
                            [39.43801882, -7.84534954],
                            [39.43825889, -7.84532954],
                            [39.43839893, -7.84520947],
                            [39.43857899, -7.84502938],
                            [39.43873904, -7.8448693],
                            [39.43899911, -7.84470921],
                            [39.43919917, -7.84454914],
                            [39.43943925, -7.84436904],
                            [39.43959929, -7.84432902],
                            [39.43971933, -7.84446909],
                            [39.43971933, -7.84466921],
                            [39.43977935, -7.84480928],
                            [39.4399594, -7.84480928],
                            [39.44023948, -7.84460917],
                            [39.44047955, -7.844289],
                            [39.44049889, -7.84424388],
                            [39.44065961, -7.84386878],
                            [39.44079966, -7.84342856],
                            [39.4409597, -7.84314841],
                            [39.44111975, -7.84276822],
                            [39.44131981, -7.84228796],
                            [39.44139984, -7.84178769],
                            [39.44147987, -7.84136748],
                            [39.44149987, -7.84106733],
                            [39.44145986, -7.84066711],
                            [39.44141985, -7.84022689],
                            [39.44125981, -7.8398667],
                            [39.44125981, -7.83952651],
                            [39.44107976, -7.83918633],
                            [39.4408797, -7.83886616],
                            [39.44073966, -7.83852599],
                            [39.44061962, -7.83822583],
                            [39.4405196, -7.83788565],
                            [39.44037956, -7.83756548],
                            [39.44037923, -7.83755839],
                            [39.44035956, -7.83712526],
                            [39.44031955, -7.83670504],
                            [39.44041957, -7.83636485],
                            [39.4405396, -7.83612473],
                            [39.44055962, -7.83578456],
                            [39.44055962, -7.83548439],
                            [39.44043958, -7.83518424],
                            [39.44031955, -7.83482405],
                            [39.44027954, -7.83444386],
                            [39.44029954, -7.83398361],
                            [39.44019552, -7.8333753],
                            [39.44011549, -7.83305513],
                            [39.44001946, -7.83284701],
                            [39.43997145, -7.83263891],
                            [39.43992344, -7.83236676],
                            [39.4397794, -7.83223869],
                            [39.43963536, -7.83220667],
                            [39.43953933, -7.83233474],
                            [39.4394593, -7.83247881],
                            [39.43936327, -7.83273496],
                            [39.43936327, -7.83297508],
                            [39.43928324, -7.83326323],
                            [39.43920323, -7.83361542],
                            [39.43917121, -7.83398361],
                            [39.4391552, -7.83438381],
                        ],
                        [
                            [39.42149576, -7.85072646],
                            [39.42127507, -7.85060959],
                            [39.4211842, -7.85053169],
                            [39.42109333, -7.85053169],
                            [39.42100246, -7.85050571],
                            [39.42088562, -7.85046677],
                            [39.42075579, -7.85047975],
                            [39.42074281, -7.85057065],
                            [39.42069087, -7.8507005],
                            [39.42061298, -7.85083035],
                            [39.42058702, -7.85098616],
                            [39.42045719, -7.851129],
                            [39.42034035, -7.85134974],
                            [39.42026246, -7.85147959],
                            [39.42019754, -7.85159646],
                            [39.42013264, -7.8518172],
                            [39.42000282, -7.85194705],
                            [39.41992493, -7.85210288],
                            [39.41997685, -7.85223273],
                            [39.42017159, -7.85233661],
                            [39.4203793, -7.8524275],
                            [39.42062596, -7.85254437],
                            [39.4208077, -7.85258333],
                            [39.42095051, -7.85260931],
                            [39.42108033, -7.85257035],
                            [39.42124911, -7.85250541],
                            [39.4213919, -7.85238855],
                            [39.42149576, -7.85224573],
                            [39.42157366, -7.85207691],
                            [39.42163856, -7.85188214],
                            [39.42166453, -7.8516614],
                            [39.4216905, -7.85134975],
                            [39.42167752, -7.85119394],
                            [39.42167752, -7.85099915],
                            [39.42159963, -7.85084332],
                            [39.42149576, -7.85072646],
                        ],
                        [
                            [39.39511015, -7.85146889],
                            [39.39521971, -7.8511182],
                            [39.39541692, -7.85092095],
                            [39.39552649, -7.85065795],
                            [39.39574561, -7.85043877],
                            [39.39592092, -7.85015384],
                            [39.39611813, -7.84980317],
                            [39.39631534, -7.84949631],
                            [39.39644682, -7.84912373],
                            [39.39655638, -7.8487292],
                            [39.39677552, -7.84842236],
                            [39.396907, -7.8480936],
                            [39.39706038, -7.84769909],
                            [39.39710421, -7.84728265],
                            [39.39723569, -7.84699773],
                            [39.3972795, -7.8467128],
                            [39.39732333, -7.84636212],
                            [39.39732333, -7.8459676],
                            [39.39727951, -7.84563885],
                            [39.3972357, -7.84522241],
                            [39.39716995, -7.84484981],
                            [39.39701657, -7.84449914],
                            [39.39690701, -7.84417037],
                            [39.39684127, -7.84390735],
                            [39.39673171, -7.84368819],
                            [39.3965345, -7.84338133],
                            [39.39640302, -7.84305257],
                            [39.39614008, -7.8427238],
                            [39.39585522, -7.84237313],
                            [39.39554843, -7.84213203],
                            [39.39519784, -7.84182519],
                            [39.39497871, -7.84162792],
                            [39.39458429, -7.84129917],
                            [39.39418987, -7.84112383],
                            [39.39381735, -7.8411019],
                            [39.39357631, -7.84123341],
                            [39.39335718, -7.8414745],
                            [39.39346675, -7.8418471],
                            [39.39348866, -7.84219777],
                            [39.39364204, -7.84241695],
                            [39.39375161, -7.84270188],
                            [39.39377352, -7.84292106],
                            [39.39377352, -7.84320599],
                            [39.39377352, -7.84340325],
                            [39.39390499, -7.84357858],
                            [39.39416794, -7.8438416],
                            [39.39427751, -7.84401694],
                            [39.39427751, -7.84425803],
                            [39.39412411, -7.84460871],
                            [39.39388307, -7.84482789],
                            [39.39370778, -7.84506898],
                            [39.39333526, -7.84546349],
                            [39.39298465, -7.84583609],
                            [39.39263405, -7.84607718],
                            [39.39230536, -7.84629634],
                            [39.39208624, -7.84642785],
                            [39.39186711, -7.84649359],
                            [39.39160415, -7.84655936],
                            [39.39138503, -7.84653743],
                            [39.3913193, -7.8463621],
                            [39.39127548, -7.84616484],
                            [39.39125356, -7.84594566],
                            [39.39125356, -7.84557306],
                            [39.39140694, -7.8453539],
                            [39.39145077, -7.84515663],
                            [39.39151652, -7.84489362],
                            [39.39173564, -7.8446087],
                            [39.39184521, -7.84432377],
                            [39.39184521, -7.84408268],
                            [39.39173564, -7.84384159],
                            [39.39158226, -7.84360049],
                            [39.39129739, -7.84340324],
                            [39.3909687, -7.84331556],
                            [39.39059619, -7.84322789],
                            [39.39017986, -7.84314021],
                            [39.3899169, -7.84303063],
                            [39.38941292, -7.84296487],
                            [39.38888702, -7.84294295],
                            [39.38866789, -7.84287721],
                            [39.38855833, -7.84263611],
                            [39.38840494, -7.8423731],
                            [39.3881639, -7.84221968],
                            [39.38801051, -7.84232926],
                            [39.38796669, -7.84259228],
                            [39.38796669, -7.8428772],
                            [39.38796668, -7.84307446],
                            [39.38796668, -7.84333746],
                            [39.38812007, -7.84357855],
                            [39.38820772, -7.84388541],
                            [39.38816389, -7.84421417],
                            [39.38809816, -7.84445526],
                            [39.38787903, -7.84447717],
                            [39.38765991, -7.84432375],
                            [39.38750653, -7.84417033],
                            [39.38726547, -7.84414841],
                            [39.38702443, -7.84432375],
                            [39.38684914, -7.84454293],
                            [39.38658618, -7.84474018],
                            [39.3864328, -7.84482786],
                            [39.3860822, -7.84476209],
                            [39.38579734, -7.84471825],
                            [39.38557821, -7.84458675],
                            [39.38544673, -7.84447716],
                            [39.38513996, -7.84445525],
                            [39.38496466, -7.84454292],
                            [39.38496465, -7.84487167],
                            [39.38496465, -7.84513469],
                            [39.3850304, -7.84539769],
                            [39.38513996, -7.84566071],
                            [39.38516186, -7.84594564],
                            [39.38531526, -7.84616482],
                            [39.38542482, -7.84638398],
                            [39.3855782, -7.84662508],
                            [39.38573159, -7.8467785],
                            [39.38566585, -7.84704151],
                            [39.38531526, -7.84701959],
                            [39.3850523, -7.84680042],
                            [39.38489892, -7.84658124],
                            [39.38463596, -7.84636207],
                            [39.38432919, -7.84638398],
                            [39.38415388, -7.84660316],
                            [39.38380329, -7.84673467],
                            [39.38343077, -7.84682232],
                            [39.38318973, -7.84701958],
                            [39.38305825, -7.84732643],
                            [39.38277339, -7.84758943],
                            [39.38248852, -7.8476771],
                            [39.38222558, -7.84780861],
                            [39.38222558, -7.84811545],
                            [39.38233513, -7.84833463],
                            [39.38248852, -7.84850998],
                            [39.38253234, -7.84877298],
                            [39.38248852, -7.84912367],
                            [39.38246661, -7.84938667],
                            [39.38218175, -7.84958393],
                            [39.38202836, -7.84969352],
                            [39.38180923, -7.84969352],
                            [39.38169967, -7.84984694],
                            [39.38169967, -7.85008803],
                            [39.38172157, -7.85037296],
                            [39.38172157, -7.85059213],
                            [39.38152436, -7.8507894],
                            [39.38119567, -7.85092089],
                            [39.38084507, -7.85092089],
                            [39.38053829, -7.85103048],
                            [39.38029725, -7.85122774],
                            [39.38018769, -7.8514469],
                            [39.38003431, -7.85164416],
                            [39.37974944, -7.85162225],
                            [39.37961796, -7.85160034],
                            [39.37953031, -7.85146883],
                            [39.37946458, -7.85120581],
                            [39.37944266, -7.85105239],
                            [39.3793331, -7.85092088],
                            [39.37909206, -7.85092088],
                            [39.37887295, -7.85092088],
                            [39.37869764, -7.85074555],
                            [39.37860999, -7.85046062],
                            [39.37847851, -7.85015377],
                            [39.37839086, -7.84975925],
                            [39.37832513, -7.84934282],
                            [39.37823748, -7.84910173],
                            [39.37828131, -7.84886063],
                            [39.37850044, -7.84870722],
                            [39.37869765, -7.8484442],
                            [39.37893869, -7.84820311],
                            [39.37909208, -7.84807162],
                            [39.3789606, -7.84787434],
                            [39.37867574, -7.8475675],
                            [39.37852235, -7.84737025],
                            [39.37823749, -7.84710723],
                            [39.37801837, -7.84686614],
                            [39.37779924, -7.84680039],
                            [39.37749247, -7.84680039],
                            [39.37709804, -7.84680039],
                            [39.37676935, -7.84677846],
                            [39.3765064, -7.84673464],
                            [39.37626537, -7.84671271],
                            [39.37600241, -7.84662504],
                            [39.37587095, -7.84655929],
                            [39.37576137, -7.84638394],
                            [39.37576137, -7.8462086],
                            [39.37595858, -7.84607711],
                            [39.37624345, -7.84596751],
                            [39.37639685, -7.84590176],
                            [39.37674745, -7.84559493],
                            [39.37672553, -7.84517849],
                            [39.37668172, -7.84487164],
                            [39.37661597, -7.84447712],
                            [39.37635303, -7.84423603],
                            [39.37622155, -7.84401687],
                            [39.37598051, -7.84384152],
                            [39.37554227, -7.84360043],
                            [39.37519166, -7.84338124],
                            [39.37492872, -7.84311824],
                            [39.37479724, -7.84292098],
                            [39.37477533, -7.84267988],
                            [39.37486297, -7.84252646],
                            [39.37486297, -7.84219769],
                            [39.37486297, -7.8419566],
                            [39.37486297, -7.8416936],
                            [39.37484107, -7.84134291],
                            [39.37479725, -7.84097032],
                            [39.37468769, -7.84079498],
                            [39.37468769, -7.84042238],
                            [39.37468769, -7.8400717],
                            [39.37481916, -7.83978678],
                            [39.37486298, -7.83930459],
                            [39.37486299, -7.83899774],
                            [39.37466578, -7.83884432],
                            [39.37449049, -7.83877857],
                            [39.37435901, -7.83877857],
                            [39.37420562, -7.83884432],
                            [39.37400839, -7.83912924],
                            [39.37392076, -7.83937034],
                            [39.37385501, -7.83961143],
                            [39.37381118, -7.83983061],
                            [39.37372354, -7.84002786],
                            [39.37359206, -7.84000594],
                            [39.37346058, -7.83994019],
                            [39.37337293, -7.83989635],
                            [39.37321955, -7.83983061],
                            [39.37308807, -7.83980868],
                            [39.37291278, -7.83980868],
                            [39.37269365, -7.83989635],
                            [39.37273747, -7.84009361],
                            [39.37267172, -7.84024702],
                            [39.37251834, -7.8403347],
                            [39.37234305, -7.8403347],
                            [39.3722773, -7.84055388],
                            [39.37238686, -7.84075113],
                            [39.37254026, -7.84092648],
                            [39.37260599, -7.84114564],
                            [39.37260599, -7.84132099],
                            [39.37273747, -7.84154017],
                            [39.37284702, -7.8417155],
                            [39.37284702, -7.84193468],
                            [39.37273746, -7.84237303],
                            [39.37251833, -7.84263605],
                            [39.37218964, -7.84281137],
                            [39.37192669, -7.84285521],
                            [39.37175139, -7.84294288],
                            [39.37166374, -7.84314014],
                            [39.371598, -7.84344699],
                            [39.37155417, -7.84373192],
                            [39.37155417, -7.84406067],
                            [39.37155417, -7.84430177],
                            [39.37153227, -7.84463052],
                            [39.37140078, -7.84498121],
                            [39.37137886, -7.8452223],
                            [39.37131313, -7.84548531],
                            [39.3712693, -7.8457264],
                            [39.3712474, -7.846099],
                            [39.37124739, -7.84638393],
                            [39.37133503, -7.84653734],
                            [39.37140078, -7.84682227],
                            [39.37153226, -7.84695378],
                            [39.37159799, -7.84726064],
                            [39.37159799, -7.8474798],
                            [39.37159798, -7.84789624],
                            [39.3714665, -7.84818117],
                            [39.37131312, -7.848488],
                            [39.37120356, -7.84881677],
                            [39.37113781, -7.84905786],
                            [39.3711159, -7.84954004],
                            [39.3711159, -7.84982497],
                            [39.3711159, -7.85015373],
                            [39.3711159, -7.85039483],
                            [39.37109398, -7.85072359],
                            [39.37109397, -7.85098661],
                            [39.37111589, -7.8512277],
                            [39.37102824, -7.85151263],
                            [39.37098441, -7.85175372],
                            [39.3707872, -7.85201672],
                            [39.37056807, -7.85221397],
                            [39.37054616, -7.85241125],
                            [39.37052424, -7.85269616],
                            [39.37052424, -7.85291534],
                            [39.37054616, -7.85311259],
                            [39.37056806, -7.85339752],
                            [39.37065572, -7.8536167],
                            [39.37074337, -7.85381396],
                            [39.37074337, -7.85403314],
                            [39.37065571, -7.85427423],
                            [39.37041467, -7.85455916],
                            [39.37021746, -7.85482216],
                            [39.37002025, -7.85504134],
                            [39.3699545, -7.85532627],
                            [39.3699545, -7.85556736],
                            [39.36993259, -7.85578653],
                            [39.37002025, -7.85591804],
                            [39.37019555, -7.85609338],
                            [39.37032701, -7.8562468],
                            [39.37041467, -7.85640022],
                            [39.37041467, -7.85659748],
                            [39.37034893, -7.85679473],
                            [39.37015172, -7.85690433],
                            [39.36999832, -7.85690433],
                            [39.36982303, -7.85683857],
                            [39.36966965, -7.85670707],
                            [39.36940669, -7.85664131],
                            [39.36920948, -7.85655363],
                            [39.36896844, -7.85648789],
                            [39.36877123, -7.85642214],
                            [39.36853019, -7.85642214],
                            [39.36833297, -7.85642214],
                            [39.36813576, -7.85657556],
                            [39.36800429, -7.8567509],
                            [39.36780706, -7.85692623],
                            [39.3676756, -7.85714541],
                            [39.3676756, -7.85734267],
                            [39.36771942, -7.85758376],
                            [39.36774133, -7.85782485],
                            [39.36789471, -7.85797827],
                            [39.36817958, -7.85806594],
                            [39.36837679, -7.85806594],
                            [39.36861782, -7.85806594],
                            [39.36881505, -7.85806595],
                            [39.36896843, -7.85815362],
                            [39.36909991, -7.85835087],
                            [39.36918755, -7.85852622],
                            [39.36916564, -7.85872348],
                            [39.36907799, -7.85898648],
                            [39.36883695, -7.85913991],
                            [39.36855209, -7.85913991],
                            [39.3682234, -7.85913991],
                            [39.36798236, -7.85907414],
                            [39.36778515, -7.85905223],
                            [39.36760984, -7.85907414],
                            [39.36750029, -7.85924949],
                            [39.36745646, -7.85944674],
                            [39.36745646, -7.85966592],
                            [39.36758794, -7.85986318],
                            [39.36776324, -7.86003851],
                            [39.3678728, -7.86014811],
                            [39.36807001, -7.86030153],
                            [39.36815766, -7.86045495],
                            [39.36822339, -7.86058646],
                            [39.36822339, -7.86078371],
                            [39.36807001, -7.8610248],
                            [39.36787279, -7.86120015],
                            [39.36763176, -7.86135357],
                            [39.36747837, -7.86150699],
                            [39.36719351, -7.86168233],
                            [39.36695247, -7.86190149],
                            [39.36686481, -7.8621426],
                            [39.36686481, -7.86233986],
                            [39.36690864, -7.86264669],
                            [39.36697437, -7.86293162],
                            [39.36708393, -7.86310697],
                            [39.36717158, -7.86334806],
                            [39.36728114, -7.86356723],
                            [39.36745644, -7.8637645],
                            [39.36765365, -7.86387408],
                            [39.36782896, -7.86396176],
                            [39.36796043, -7.86409325],
                            [39.36815764, -7.86413709],
                            [39.36828912, -7.86413709],
                            [39.36844251, -7.86413709],
                            [39.36859589, -7.86418092],
                            [39.36868354, -7.86437818],
                            [39.36868354, -7.86453162],
                            [39.36846441, -7.86475078],
                            [39.36820147, -7.8650138],
                            [39.36811381, -7.86521105],
                            [39.36811381, -7.86543022],
                            [39.36813572, -7.86556173],
                            [39.36813572, -7.86589049],
                            [39.36813572, -7.86617542],
                            [39.36813571, -7.86646035],
                            [39.36809189, -7.8667672],
                            [39.36796042, -7.86696445],
                            [39.36777294, -7.86703264],
                            [39.36738338, -7.86703264],
                            [39.36707174, -7.86699368],
                            [39.36679905, -7.86699367],
                            [39.3664095, -7.86699367],
                            [39.36598098, -7.86699367],
                            [39.36566934, -7.86711055],
                            [39.3654356, -7.86742226],
                            [39.3654356, -7.86773399],
                            [39.36555246, -7.86824053],
                            [39.36607952, -7.86820783],
                            [39.36609785, -7.86821374],
                            [39.36633889, -7.86821374],
                            [39.366558, -7.86814798],
                            [39.36679904, -7.86810416],
                            [39.36704008, -7.86810416],
                            [39.36728112, -7.86812607],
                            [39.36741259, -7.86830142],
                            [39.36736877, -7.86869593],
                            [39.36725921, -7.86909044],
                            [39.3672373, -7.86944113],
                            [39.3672373, -7.86974797],
                            [39.36734686, -7.86994522],
                            [39.36756597, -7.86992332],
                            [39.36787276, -7.86981372],
                            [39.3681138, -7.86963839],
                            [39.36833291, -7.86948496],
                            [39.3686397, -7.86935346],
                            [39.36885883, -7.86909045],
                            [39.36912177, -7.86887128],
                            [39.36929708, -7.86867401],
                            [39.36945047, -7.86858636],
                            [39.36962577, -7.86834527],
                            [39.36973533, -7.86812608],
                            [39.36986681, -7.8679069],
                            [39.36995445, -7.86779732],
                            [39.3701955, -7.86779732],
                            [39.37039271, -7.8680165],
                            [39.37041462, -7.86823567],
                            [39.37039271, -7.8685206],
                            [39.37023931, -7.86882745],
                            [39.37012975, -7.86909045],
                            [39.37010784, -7.86935347],
                            [39.37019549, -7.86966031],
                            [39.37041461, -7.86972607],
                            [39.3707214, -7.86961647],
                            [39.37089669, -7.86950689],
                            [39.37118155, -7.86924388],
                            [39.37142259, -7.8692658],
                            [39.37172937, -7.86946306],
                            [39.37201423, -7.86961648],
                            [39.37225527, -7.86972608],
                            [39.37249631, -7.86992334],
                            [39.37275925, -7.86992334],
                            [39.37293456, -7.86983567],
                            [39.37293456, -7.86963841],
                            [39.37271543, -7.86935348],
                            [39.37249631, -7.86909046],
                            [39.3722991, -7.86891513],
                            [39.37205807, -7.86865211],
                            [39.37172938, -7.86843293],
                            [39.37146642, -7.868148],
                            [39.37142259, -7.86790691],
                            [39.37142259, -7.86762198],
                            [39.37144452, -7.86731514],
                            [39.37151025, -7.86709596],
                            [39.37161981, -7.86689871],
                            [39.37183894, -7.8668768],
                            [39.37201425, -7.86705213],
                            [39.37216763, -7.86729324],
                            [39.37243057, -7.86755624],
                            [39.37278118, -7.86773159],
                            [39.37308795, -7.86788501],
                            [39.37335091, -7.86792884],
                            [39.37359194, -7.86801652],
                            [39.37381106, -7.86827952],
                            [39.37400827, -7.86847678],
                            [39.37416167, -7.86863021],
                            [39.37429313, -7.8684987],
                            [39.37429314, -7.86810419],
                            [39.37424933, -7.86777543],
                            [39.37411785, -7.8673809],
                            [39.37400828, -7.86707406],
                            [39.37400828, -7.86678914],
                            [39.3740302, -7.86652612],
                            [39.3740302, -7.8661097],
                            [39.3740302, -7.86582477],
                            [39.37367961, -7.86564942],
                            [39.37324134, -7.865496],
                            [39.37286884, -7.86543024],
                            [39.37251823, -7.86518914],
                            [39.37236485, -7.86492614],
                            [39.37234295, -7.86457547],
                            [39.37234295, -7.86429054],
                            [39.37236485, -7.86393985],
                            [39.37245251, -7.86354534],
                            [39.37258399, -7.86326041],
                            [39.37271545, -7.86297548],
                            [39.37284693, -7.86277823],
                            [39.37284693, -7.86253714],
                            [39.37267163, -7.86238372],
                            [39.37247442, -7.86223028],
                            [39.37243059, -7.86192344],
                            [39.37251825, -7.8617481],
                            [39.37264973, -7.86152893],
                            [39.37262782, -7.8613755],
                            [39.37256208, -7.86120017],
                            [39.37234296, -7.86104675],
                            [39.37227721, -7.86076182],
                            [39.37232104, -7.86043306],
                            [39.37251825, -7.86025771],
                            [39.37284694, -7.8602358],
                            [39.37313181, -7.8602358],
                            [39.37339477, -7.86023581],
                            [39.37361388, -7.86019197],
                            [39.37372346, -7.86012621],
                            [39.37378919, -7.85992895],
                            [39.37392067, -7.85979744],
                            [39.37403023, -7.85953444],
                            [39.37403023, -7.8593591],
                            [39.37403023, -7.85907417],
                            [39.37392067, -7.85870157],
                            [39.37383302, -7.85837282],
                            [39.37365772, -7.85813171],
                            [39.37346051, -7.85784678],
                            [39.3732633, -7.85760569],
                            [39.37284696, -7.85738652],
                            [39.37262784, -7.8572331],
                            [39.37240871, -7.85710159],
                            [39.3722334, -7.85701392],
                            [39.37218958, -7.85683858],
                            [39.37229915, -7.85659748],
                            [39.37247444, -7.8564879],
                            [39.37284696, -7.85646599],
                            [39.37330713, -7.85646599],
                            [39.37370155, -7.85668516],
                            [39.37407407, -7.85681667],
                            [39.37416172, -7.85690435],
                            [39.37446849, -7.85690435],
                            [39.37475335, -7.85690435],
                            [39.37490674, -7.85690435],
                            [39.37510395, -7.85690435],
                            [39.37536691, -7.85688242],
                            [39.37552029, -7.85686052],
                            [39.37552029, -7.85666326],
                            [39.375345, -7.85637833],
                            [39.37514778, -7.85615915],
                            [39.37495057, -7.85587422],
                            [39.37470953, -7.85567696],
                            [39.37449042, -7.85526053],
                            [39.3744685, -7.85495369],
                            [39.37449042, -7.85469067],
                            [39.37451232, -7.85442767],
                            [39.37457806, -7.85414274],
                            [39.37479719, -7.85412082],
                            [39.37495057, -7.85425232],
                            [39.37514778, -7.85451534],
                            [39.37521353, -7.85480027],
                            [39.37532309, -7.85512904],
                            [39.37541074, -7.85539204],
                            [39.37562985, -7.85567697],
                            [39.37578325, -7.8559619],
                            [39.37606812, -7.85618108],
                            [39.37626532, -7.85650983],
                            [39.37644061, -7.85677285],
                            [39.37668167, -7.85690436],
                            [39.3769227, -7.85686052],
                            [39.37720757, -7.8568386],
                            [39.37755816, -7.85681669],
                            [39.37795259, -7.85664134],
                            [39.37825937, -7.85659751],
                            [39.37850041, -7.85640025],
                            [39.3788291, -7.85615916],
                            [39.37904822, -7.85598382],
                            [39.37902631, -7.85565507],
                            [39.37891674, -7.85515096],
                            [39.37869763, -7.8548222],
                            [39.37852232, -7.85449343],
                            [39.37839084, -7.85414276],
                            [39.37817173, -7.85390166],
                            [39.37823746, -7.85366057],
                            [39.37850042, -7.85359481],
                            [39.37878528, -7.85359481],
                            [39.37907015, -7.85361674],
                            [39.37933309, -7.85372632],
                            [39.37959605, -7.85390167],
                            [39.3796837, -7.85412084],
                            [39.37977134, -7.85434002],
                            [39.37977133, -7.85466878],
                            [39.37974943, -7.85539206],
                            [39.37983708, -7.85565507],
                            [39.37996854, -7.85585233],
                            [39.38020959, -7.85583041],
                            [39.38040681, -7.8556989],
                            [39.38060402, -7.85550164],
                            [39.38071358, -7.85534822],
                            [39.38086696, -7.85515097],
                            [39.38112992, -7.85495372],
                            [39.38130523, -7.85466879],
                            [39.38154627, -7.85438386],
                            [39.38174348, -7.85412084],
                            [39.38196259, -7.85385784],
                            [39.38220363, -7.85352908],
                            [39.38292676, -7.85298114],
                            [39.38327736, -7.85287156],
                            [39.3835403, -7.85287156],
                            [39.38371561, -7.85287156],
                            [39.38413195, -7.85287156],
                            [39.38448255, -7.85287156],
                            [39.3847455, -7.85280582],
                            [39.38476741, -7.85256472],
                            [39.38476741, -7.8522798],
                            [39.38467976, -7.85195103],
                            [39.3845483, -7.8516661],
                            [39.3843949, -7.85142501],
                            [39.38443873, -7.85122776],
                            [39.38461403, -7.85118392],
                            [39.38478934, -7.85135925],
                            [39.3848989, -7.85149076],
                            [39.3849208, -7.85170994],
                            [39.3849208, -7.85197294],
                            [39.38505228, -7.85221404],
                            [39.38524949, -7.85221404],
                            [39.38533714, -7.85214829],
                            [39.3854467, -7.8519072],
                            [39.38546862, -7.85168802],
                            [39.38553435, -7.85149076],
                            [39.38568774, -7.85142502],
                            [39.38577539, -7.85157844],
                            [39.38577539, -7.85177569],
                            [39.38573156, -7.85208255],
                            [39.385622, -7.85234555],
                            [39.38557818, -7.8526524],
                            [39.3856001, -7.85293733],
                            [39.38573156, -7.85320033],
                            [39.38595069, -7.85339759],
                            [39.38632321, -7.85341951],
                            [39.38676145, -7.85346335],
                            [39.38730926, -7.85346336],
                            [39.38774752, -7.85361678],
                            [39.38855829, -7.85366062],
                            [39.38899654, -7.85366062],
                            [39.38932523, -7.85379211],
                            [39.38976347, -7.8537702],
                            [39.38996069, -7.85366062],
                            [39.39026746, -7.85341953],
                            [39.39046468, -7.8532661],
                            [39.39070572, -7.85313461],
                            [39.39092484, -7.85298117],
                            [39.39092484, -7.85267433],
                            [39.39096867, -7.85219215],
                            [39.39110015, -7.85192913],
                            [39.39116588, -7.85166613],
                            [39.39129736, -7.85146888],
                            [39.39129736, -7.85122778],
                            [39.39129736, -7.85098669],
                            [39.39123163, -7.85081135],
                            [39.39112206, -7.85052642],
                            [39.39110015, -7.85021958],
                            [39.39118781, -7.84995656],
                            [39.39127546, -7.84971547],
                            [39.39147267, -7.84969356],
                            [39.39164796, -7.84991273],
                            [39.391889, -7.85017575],
                            [39.39199857, -7.85046068],
                            [39.39215195, -7.85076752],
                            [39.39221769, -7.85111819],
                            [39.39221768, -7.85146888],
                            [39.39221768, -7.85179764],
                            [39.39221768, -7.85214832],
                            [39.39245872, -7.85243325],
                            [39.39267785, -7.85265243],
                            [39.39309419, -7.85265243],
                            [39.39351052, -7.85256476],
                            [39.39370775, -7.85241134],
                            [39.39401452, -7.85230175],
                            [39.39440894, -7.85203875],
                            [39.39478146, -7.85190724],
                            [39.39502249, -7.85173189],
                            [39.39511015, -7.85146889],
                        ],
                        [
                            [39.40423428, -7.85370446],
                            [39.40419243, -7.85378816],
                            [39.40420917, -7.85386348],
                            [39.40424263, -7.85393045],
                            [39.40425101, -7.85403089],
                            [39.40416732, -7.85409786],
                            [39.40411711, -7.8541732],
                            [39.40410037, -7.85422341],
                            [39.40407526, -7.85433223],
                            [39.40410037, -7.85444941],
                            [39.40416732, -7.85452475],
                            [39.40427611, -7.85463357],
                            [39.40441837, -7.85477586],
                            [39.40452717, -7.85489304],
                            [39.40466943, -7.85501859],
                            [39.40477822, -7.85507718],
                            [39.40488699, -7.85514415],
                            [39.40499579, -7.85514415],
                            [39.40508785, -7.85514415],
                            [39.40513805, -7.85517764],
                            [39.40521337, -7.85525297],
                            [39.40529706, -7.85527808],
                            [39.40539747, -7.85527808],
                            [39.40549789, -7.85526134],
                            [39.405523, -7.85519438],
                            [39.40560668, -7.85517764],
                            [39.40564015, -7.85521112],
                            [39.40573221, -7.85526971],
                            [39.40587447, -7.85526971],
                            [39.40596653, -7.85520274],
                            [39.40605021, -7.85508556],
                            [39.40606695, -7.85496839],
                            [39.40615901, -7.85490978],
                            [39.40627616, -7.85489304],
                            [39.40632638, -7.85480934],
                            [39.40632638, -7.85473402],
                            [39.40625105, -7.85465867],
                            [39.40619248, -7.85460008],
                            [39.40619248, -7.85451638],
                            [39.40619248, -7.85441594],
                            [39.40619248, -7.85430712],
                            [39.40615901, -7.85424853],
                            [39.40610042, -7.85418157],
                            [39.40601674, -7.85418157],
                            [39.40592469, -7.85424016],
                            [39.40585774, -7.85421506],
                            [39.4058159, -7.85413971],
                            [39.40574059, -7.85410624],
                            [39.40567364, -7.8541146],
                            [39.40561506, -7.85413971],
                            [39.40553974, -7.85413135],
                            [39.4054477, -7.8540895],
                            [39.4053891, -7.8540309],
                            [39.40532217, -7.85396395],
                            [39.40522174, -7.85387187],
                            [39.40512969, -7.8538049],
                            [39.40506274, -7.85374631],
                            [39.40497906, -7.85368772],
                            [39.4048368, -7.85364587],
                            [39.40476985, -7.85364587],
                            [39.40471127, -7.85364587],
                            [39.40464432, -7.85366261],
                            [39.40458575, -7.85370446],
                            [39.4045188, -7.85371283],
                            [39.40445185, -7.85371283],
                            [39.40436816, -7.85367934],
                            [39.40429285, -7.85367098],
                            [39.40423428, -7.85370446],
                        ],
                        [
                            [39.40774063, -7.85512742],
                            [39.40773226, -7.8552446],
                            [39.40773226, -7.85534506],
                            [39.4077239, -7.85543712],
                            [39.40766532, -7.8555292],
                            [39.40763184, -7.8556129],
                            [39.40753142, -7.85572172],
                            [39.40744773, -7.85580542],
                            [39.40738078, -7.85586401],
                            [39.40731383, -7.85595607],
                            [39.40725526, -7.85603978],
                            [39.40717995, -7.85609001],
                            [39.40712137, -7.85612348],
                            [39.40707115, -7.85614023],
                            [39.40696236, -7.85614859],
                            [39.40693725, -7.85614859],
                            [39.40685357, -7.85614859],
                            [39.40678663, -7.85614859],
                            [39.40670295, -7.85619882],
                            [39.40662762, -7.85624903],
                            [39.40652721, -7.856316],
                            [39.40641005, -7.85630764],
                            [39.406318, -7.85630764],
                            [39.40622594, -7.85634949],
                            [39.40612552, -7.85644993],
                            [39.40597488, -7.85653363],
                            [39.40585773, -7.85662569],
                            [39.4057071, -7.85672615],
                            [39.40558994, -7.85684333],
                            [39.40555646, -7.85695214],
                            [39.40561505, -7.85706932],
                            [39.40568199, -7.85715302],
                            [39.40576567, -7.85722837],
                            [39.40590795, -7.85733717],
                            [39.40610041, -7.85733717],
                            [39.40624268, -7.85725347],
                            [39.40628452, -7.85718652],
                            [39.40636821, -7.8571614],
                            [39.40649374, -7.85716977],
                            [39.40656067, -7.85721162],
                            [39.4066611, -7.85727858],
                            [39.40676152, -7.85733717],
                            [39.40685356, -7.85731207],
                            [39.40692889, -7.85733717],
                            [39.4070042, -7.8573874],
                            [39.40708788, -7.8573874],
                            [39.4071883, -7.8573874],
                            [39.40731383, -7.8573874],
                            [39.40736404, -7.85729532],
                            [39.40743935, -7.85727023],
                            [39.40749794, -7.85727859],
                            [39.40756488, -7.85727859],
                            [39.40768205, -7.85727859],
                            [39.40770716, -7.85722838],
                            [39.40771552, -7.85715303],
                            [39.40771552, -7.85707771],
                            [39.40771552, -7.85702748],
                            [39.40775736, -7.85697727],
                            [39.4077992, -7.85698563],
                            [39.4079331, -7.85698563],
                            [39.40799168, -7.85693542],
                            [39.40801679, -7.85685171],
                            [39.40801679, -7.85676801],
                            [39.40800841, -7.85667593],
                            [39.40800841, -7.85655875],
                            [39.4080419, -7.85650852],
                            [39.4081172, -7.8565169],
                            [39.40820089, -7.85650015],
                            [39.40825111, -7.85641645],
                            [39.40825111, -7.85630764],
                            [39.408226, -7.8562323],
                            [39.40823436, -7.85615698],
                            [39.40830968, -7.85603978],
                            [39.40839337, -7.85602304],
                            [39.40843521, -7.8559226],
                            [39.40839337, -7.85586402],
                            [39.40835153, -7.85578031],
                            [39.40835153, -7.85567149],
                            [39.40840173, -7.85561291],
                            [39.40849379, -7.85557105],
                            [39.40850216, -7.85548735],
                            [39.40849379, -7.85540365],
                            [39.40842684, -7.85535342],
                            [39.40836826, -7.85532831],
                            [39.40830969, -7.85532831],
                            [39.40825947, -7.85531994],
                            [39.40821763, -7.85527809],
                            [39.40818416, -7.85522786],
                            [39.40818416, -7.8551609],
                            [39.408226, -7.85512742],
                            [39.40829295, -7.8550772],
                            [39.40829295, -7.85501861],
                            [39.40823436, -7.85496002],
                            [39.40815068, -7.85496002],
                            [39.40810048, -7.85500187],
                            [39.40805026, -7.85509395],
                            [39.40800006, -7.85511069],
                            [39.40794147, -7.85511069],
                            [39.407908, -7.85511069],
                            [39.40784105, -7.8550521],
                            [39.40774063, -7.85512742],
                        ],
                        [
                            [39.38977132, -7.85718188],
                            [39.38977713, -7.85712959],
                            [39.38974227, -7.8570831],
                            [39.38968999, -7.85703662],
                            [39.3896377, -7.85696689],
                            [39.38962028, -7.85692621],
                            [39.38962028, -7.85686229],
                            [39.38964933, -7.85680419],
                            [39.38967837, -7.85675771],
                            [39.38969579, -7.85671122],
                            [39.38970161, -7.85663569],
                            [39.3896377, -7.85655433],
                            [39.3895738, -7.85655433],
                            [39.38951571, -7.85657177],
                            [39.38946924, -7.8565892],
                            [39.3893821, -7.85660082],
                            [39.38933562, -7.85660082],
                            [39.38927753, -7.85658919],
                            [39.3891962, -7.85657177],
                            [39.38912068, -7.85652528],
                            [39.38907421, -7.85649041],
                            [39.38903935, -7.85644393],
                            [39.38901031, -7.85636259],
                            [39.38901031, -7.85628704],
                            [39.38903935, -7.85618826],
                            [39.38905677, -7.85613016],
                            [39.38903935, -7.85607786],
                            [39.38900449, -7.85602556],
                            [39.38897545, -7.85596745],
                            [39.38894059, -7.85592679],
                            [39.38890573, -7.8558803],
                            [39.38887088, -7.85582219],
                            [39.3888186, -7.85578732],
                            [39.38873846, -7.85579447],
                            [39.38861221, -7.85582603],
                            [39.38856487, -7.8558576],
                            [39.38845441, -7.85588917],
                            [39.38826504, -7.85588917],
                            [39.38810723, -7.85577868],
                            [39.38804411, -7.85574711],
                            [39.38790209, -7.85571554],
                            [39.38782318, -7.85569976],
                            [39.38771272, -7.85569976],
                            [39.38758647, -7.85569976],
                            [39.38749179, -7.85569976],
                            [39.3873182, -7.85571554],
                            [39.38725508, -7.85579446],
                            [39.38725508, -7.85590495],
                            [39.38730242, -7.85604701],
                            [39.38730242, -7.85620485],
                            [39.3872393, -7.85639426],
                            [39.38711305, -7.85645741],
                            [39.38692367, -7.85652055],
                            [39.38668696, -7.85652055],
                            [39.38640291, -7.8565679],
                            [39.38618198, -7.8565679],
                            [39.38599261, -7.85659946],
                            [39.38588215, -7.85667838],
                            [39.38581902, -7.85678887],
                            [39.3857559, -7.85689936],
                            [39.38561387, -7.85710456],
                            [39.38558231, -7.85727818],
                            [39.38548763, -7.85740445],
                            [39.38529826, -7.85751494],
                            [39.38517201, -7.85753073],
                            [39.38510395, -7.85761606],
                            [39.38500632, -7.85766045],
                            [39.38492641, -7.85773148],
                            [39.38492641, -7.85782916],
                            [39.38497968, -7.85790906],
                            [39.38501519, -7.85800672],
                            [39.38501519, -7.85812215],
                            [39.38497968, -7.85820205],
                            [39.38496193, -7.85829084],
                            [39.38496193, -7.85838851],
                            [39.38494418, -7.85848617],
                            [39.38493529, -7.85859272],
                            [39.38493528, -7.85875253],
                            [39.38493528, -7.85886796],
                            [39.38493528, -7.85894786],
                            [39.38491753, -7.85904552],
                            [39.38487316, -7.85915207],
                            [39.3848554, -7.85925861],
                            [39.38485539, -7.85940067],
                            [39.38481102, -7.85952497],
                            [39.38474001, -7.85961377],
                            [39.38471337, -7.8597203],
                            [39.38471337, -7.85982686],
                            [39.38474888, -7.85993339],
                            [39.38479325, -7.86003994],
                            [39.38488203, -7.86014648],
                            [39.38497079, -7.86019975],
                            [39.38510394, -7.86023528],
                            [39.38525484, -7.86027966],
                            [39.38538799, -7.86033294],
                            [39.3855034, -7.86033294],
                            [39.3856543, -7.86036846],
                            [39.38581407, -7.86036846],
                            [39.38597386, -7.86037734],
                            [39.38613364, -7.86037734],
                            [39.38628454, -7.86037734],
                            [39.38646208, -7.86037734],
                            [39.3866396, -7.86037734],
                            [39.38679939, -7.86037734],
                            [39.38691478, -7.86037734],
                            [39.38706569, -7.86035957],
                            [39.38714558, -7.86029744],
                            [39.38726986, -7.86029744],
                            [39.38736749, -7.86030631],
                            [39.38744738, -7.86030632],
                            [39.38749177, -7.86025304],
                            [39.38758053, -7.86012874],
                            [39.38768706, -7.86003108],
                            [39.38780245, -7.86001331],
                            [39.3879001, -7.8599778],
                            [39.38795336, -7.85988902],
                            [39.38799774, -7.85980022],
                            [39.38804213, -7.85967592],
                            [39.38804213, -7.85956051],
                            [39.38804213, -7.85940068],
                            [39.3880865, -7.85926752],
                            [39.3880865, -7.85913433],
                            [39.3880865, -7.85905441],
                            [39.38803325, -7.85897451],
                            [39.38797112, -7.8588946],
                            [39.38790898, -7.85882357],
                            [39.38787347, -7.85869927],
                            [39.38787347, -7.85859273],
                            [39.38784684, -7.8584773],
                            [39.38776696, -7.85840628],
                            [39.38768707, -7.85829972],
                            [39.38765155, -7.85818431],
                            [39.3876693, -7.85812216],
                            [39.38775808, -7.85798898],
                            [39.38781133, -7.85792683],
                            [39.38787347, -7.85782028],
                            [39.38795337, -7.85774925],
                            [39.38806876, -7.85774925],
                            [39.38822855, -7.85775813],
                            [39.38838832, -7.85776702],
                            [39.38852148, -7.85776702],
                            [39.38862799, -7.85775813],
                            [39.38875226, -7.85775813],
                            [39.38887654, -7.85775813],
                            [39.38900082, -7.85772262],
                            [39.38908958, -7.85764272],
                            [39.3891323, -7.857577],
                            [39.38917876, -7.85754214],
                            [39.38925429, -7.85750729],
                            [39.38931238, -7.85745498],
                            [39.38934724, -7.8574085],
                            [39.38941114, -7.85734459],
                            [39.38949247, -7.85730972],
                            [39.38957379, -7.85727485],
                            [39.38962609, -7.85725743],
                            [39.38968999, -7.85725162],
                            [39.38974809, -7.85723999],
                            [39.38977132, -7.85718188],
                        ],
                        [
                            [39.39070405, -7.8562011],
                            [39.39088022, -7.8562011],
                            [39.39105638, -7.85613818],
                            [39.39108155, -7.85602489],
                            [39.39121997, -7.85589904],
                            [39.39138354, -7.85584869],
                            [39.39142129, -7.85572284],
                            [39.39138354, -7.85558438],
                            [39.39129547, -7.85549628],
                            [39.39115705, -7.85532008],
                            [39.39109413, -7.85519421],
                            [39.39109413, -7.85510611],
                            [39.3909683, -7.85496767],
                            [39.39090538, -7.85486697],
                            [39.39082988, -7.85476628],
                            [39.39082988, -7.85466559],
                            [39.39075438, -7.85455231],
                            [39.39069147, -7.85452715],
                            [39.39057822, -7.85448939],
                            [39.39050272, -7.85440128],
                            [39.39030139, -7.85442645],
                            [39.39018814, -7.8543887],
                            [39.39002455, -7.8543761],
                            [39.38987355, -7.85436352],
                            [39.38973513, -7.85438869],
                            [39.38958413, -7.85438869],
                            [39.3894583, -7.854288],
                            [39.38929473, -7.85422507],
                            [39.3890808, -7.85425024],
                            [39.38894238, -7.85437609],
                            [39.38882913, -7.85450196],
                            [39.38869072, -7.85457747],
                            [39.38860263, -7.8547411],
                            [39.38859005, -7.85495506],
                            [39.38869071, -7.8551061],
                            [39.38869071, -7.85524455],
                            [39.38879138, -7.85542076],
                            [39.3889298, -7.85548369],
                            [39.38909338, -7.85555921],
                            [39.38931987, -7.85562214],
                            [39.3895338, -7.85572283],
                            [39.38974772, -7.85579835],
                            [39.3899868, -7.85593679],
                            [39.39017554, -7.85603749],
                            [39.39032655, -7.8561004],
                            [39.39051529, -7.85617594],
                            [39.39070405, -7.8562011],
                        ],
                        [
                            [39.41651778, -7.86363792],
                            [39.41641125, -7.86373264],
                            [39.41624554, -7.86367344],
                            [39.41607983, -7.86369711],
                            [39.4159141, -7.86379183],
                            [39.41577207, -7.86392206],
                            [39.41560635, -7.86400493],
                            [39.41540513, -7.8639931],
                            [39.41523942, -7.86391023],
                            [39.41501453, -7.86391023],
                            [39.41482513, -7.86391023],
                            [39.41468309, -7.86379183],
                            [39.41452922, -7.86369711],
                            [39.41443452, -7.86372078],
                            [39.4143635, -7.86388653],
                            [39.41435166, -7.86405228],
                            [39.41431616, -7.86421805],
                            [39.41417412, -7.8644193],
                            [39.41393738, -7.8644193],
                            [39.41378351, -7.8642654],
                            [39.41361778, -7.86411148],
                            [39.41346391, -7.8639694],
                            [39.41338106, -7.86388653],
                            [39.41328636, -7.86388653],
                            [39.41322717, -7.86399309],
                            [39.41313249, -7.8640286],
                            [39.41300228, -7.8640286],
                            [39.41290759, -7.86407597],
                            [39.41287208, -7.86412332],
                            [39.41286025, -7.86425355],
                            [39.4129786, -7.86438379],
                            [39.41300227, -7.86450217],
                            [39.4129786, -7.86466794],
                            [39.4129786, -7.86484552],
                            [39.4129786, -7.86501127],
                            [39.41296677, -7.86521254],
                            [39.4129431, -7.86546116],
                            [39.4127537, -7.86561508],
                            [39.4126235, -7.86578083],
                            [39.41246962, -7.86587553],
                            [39.41230391, -7.86587553],
                            [39.41213819, -7.86579266],
                            [39.41206718, -7.86566243],
                            [39.412008, -7.865473],
                            [39.41199616, -7.86527173],
                            [39.41199616, -7.86511781],
                            [39.41193699, -7.86491656],
                            [39.4119133, -7.86476264],
                            [39.4118778, -7.86463241],
                            [39.4118778, -7.86446666],
                            [39.4118778, -7.86427722],
                            [39.41179495, -7.86407596],
                            [39.41174759, -7.86395756],
                            [39.41170025, -7.86380365],
                            [39.41155821, -7.86360239],
                            [39.41142801, -7.86342479],
                            [39.41120312, -7.8632472],
                            [39.41101372, -7.86321169],
                            [39.41083618, -7.86316432],
                            [39.41065862, -7.86306961],
                            [39.41049291, -7.86298674],
                            [39.41033904, -7.86286834],
                            [39.4102325, -7.86278547],
                            [39.41020883, -7.86301041],
                            [39.41022067, -7.86303409],
                            [39.41022067, -7.86317616],
                            [39.41022067, -7.86329456],
                            [39.41030353, -7.86338927],
                            [39.41035087, -7.86351951],
                            [39.41035087, -7.86364974],
                            [39.41027985, -7.86376813],
                            [39.41029168, -7.86396939],
                            [39.41035087, -7.86412331],
                            [39.41043372, -7.86421803],
                            [39.41046923, -7.86437193],
                            [39.41046923, -7.86453768],
                            [39.41046923, -7.8646916],
                            [39.41046923, -7.86488103],
                            [39.41048106, -7.86504678],
                            [39.41061127, -7.8651415],
                            [39.41068229, -7.86528357],
                            [39.41072964, -7.86537828],
                            [39.410848, -7.86546115],
                            [39.41101371, -7.86546115],
                            [39.41112025, -7.86546115],
                            [39.41127412, -7.86546115],
                            [39.41136882, -7.86550852],
                            [39.41147535, -7.86560323],
                            [39.41157004, -7.86572163],
                            [39.41159372, -7.86584002],
                            [39.41159371, -7.86597025],
                            [39.41151086, -7.86608865],
                            [39.41139249, -7.86621887],
                            [39.41130963, -7.86632542],
                            [39.4112386, -7.8664675],
                            [39.41122677, -7.86660957],
                            [39.4112031, -7.86672797],
                            [39.41112025, -7.86685819],
                            [39.41095453, -7.86685819],
                            [39.41089535, -7.86670429],
                            [39.41087168, -7.86653854],
                            [39.41069412, -7.8664083],
                            [39.41061127, -7.86637279],
                            [39.41048105, -7.86638462],
                            [39.41043371, -7.86649117],
                            [39.41051657, -7.86658589],
                            [39.41067045, -7.86668061],
                            [39.41070595, -7.86677532],
                            [39.41068228, -7.86682269],
                            [39.41063494, -7.86684636],
                            [39.41057575, -7.86687004],
                            [39.41051657, -7.86689371],
                            [39.41045738, -7.86700027],
                            [39.41051657, -7.86709499],
                            [39.41063494, -7.86715419],
                            [39.41069412, -7.86718969],
                            [39.41076513, -7.86726074],
                            [39.41076513, -7.86733177],
                            [39.41076513, -7.86746201],
                            [39.41076513, -7.86755671],
                            [39.41078882, -7.86761591],
                            [39.41085983, -7.86767511],
                            [39.41094269, -7.86775799],
                            [39.41102554, -7.86791191],
                            [39.41102554, -7.86804213],
                            [39.4110137, -7.86820788],
                            [39.4109782, -7.8683618],
                            [39.41089534, -7.86848018],
                            [39.41072963, -7.86859858],
                            [39.41062309, -7.86864593],
                            [39.4105284, -7.86864593],
                            [39.41038636, -7.86855123],
                            [39.41027983, -7.8684565],
                            [39.41012595, -7.86832628],
                            [39.40999575, -7.86816053],
                            [39.40993657, -7.86804213],
                            [39.40984187, -7.86787638],
                            [39.40973534, -7.8677935],
                            [39.40966433, -7.86768695],
                            [39.40954596, -7.86759223],
                            [39.40941576, -7.86748568],
                            [39.40921454, -7.86741463],
                            [39.4090725, -7.86737913],
                            [39.40888311, -7.86737913],
                            [39.40870556, -7.86737913],
                            [39.40850433, -7.86737912],
                            [39.40832678, -7.86739095],
                            [39.40824392, -7.86741463],
                            [39.40822025, -7.86748567],
                            [39.40827943, -7.8676159],
                            [39.4083978, -7.86771062],
                            [39.40850433, -7.86772245],
                            [39.4086227, -7.86775798],
                            [39.40871738, -7.86780533],
                            [39.4088476, -7.86788822],
                            [39.40896597, -7.8679474],
                            [39.40908432, -7.86804213],
                            [39.40922636, -7.86804213],
                            [39.4093329, -7.868125],
                            [39.40945126, -7.86819605],
                            [39.40954596, -7.86827892],
                            [39.40961697, -7.86834995],
                            [39.40964066, -7.86846835],
                            [39.40949861, -7.86855122],
                            [39.40940392, -7.86855122],
                            [39.40919086, -7.86856305],
                            [39.40910801, -7.86863409],
                            [39.40901331, -7.86871697],
                            [39.40900146, -7.86877617],
                            [39.40890678, -7.86879984],
                            [39.40880025, -7.86889456],
                            [39.40865821, -7.86898927],
                            [39.40836228, -7.86901294],
                            [39.40827943, -7.86898927],
                            [39.40816106, -7.86897744],
                            [39.40806637, -7.86902479],
                            [39.40801901, -7.86922606],
                            [39.40801901, -7.86936813],
                            [39.40806637, -7.86947468],
                            [39.40814923, -7.86958124],
                            [39.4082676, -7.86964043],
                            [39.40837412, -7.86973516],
                            [39.40844514, -7.86985354],
                            [39.40868188, -7.86988906],
                            [39.40880025, -7.86987723],
                            [39.40893044, -7.86986539],
                            [39.40913167, -7.86985354],
                            [39.4092382, -7.86980619],
                            [39.40940391, -7.86979434],
                            [39.40958147, -7.86979434],
                            [39.40974718, -7.86972332],
                            [39.40991289, -7.86968779],
                            [39.41005493, -7.86975884],
                            [39.41016146, -7.86988907],
                            [39.41017329, -7.87003114],
                            [39.41016146, -7.87014954],
                            [39.41004309, -7.87030344],
                            [39.40999574, -7.87049288],
                            [39.40999574, -7.87067046],
                            [39.40999574, -7.87078886],
                            [39.41001941, -7.87094276],
                            [39.41011411, -7.87112036],
                            [39.41017328, -7.87132163],
                            [39.41025615, -7.87145186],
                            [39.41025615, -7.87159393],
                            [39.41026798, -7.87171233],
                            [39.41022063, -7.8719136],
                            [39.41011411, -7.8720675],
                            [39.40998389, -7.87217407],
                            [39.40981818, -7.8722451],
                            [39.4096288, -7.87226877],
                            [39.40942757, -7.87226877],
                            [39.40922634, -7.87226877],
                            [39.40913166, -7.87226877],
                            [39.40898962, -7.87229245],
                            [39.40888309, -7.87233981],
                            [39.40887125, -7.87244636],
                            [39.40889492, -7.87257659],
                            [39.40889492, -7.87273051],
                            [39.40889492, -7.87287258],
                            [39.40889492, -7.87297914],
                            [39.40888309, -7.87313304],
                            [39.40876472, -7.87325144],
                            [39.40861083, -7.87326328],
                            [39.40848064, -7.87326328],
                            [39.40831492, -7.87326328],
                            [39.40818473, -7.87320408],
                            [39.4079835, -7.87310936],
                            [39.40786513, -7.87300281],
                            [39.40772309, -7.87293176],
                            [39.40754553, -7.87291993],
                            [39.40728514, -7.87289625],
                            [39.40711943, -7.87289625],
                            [39.40698921, -7.87294361],
                            [39.40690635, -7.87303833],
                            [39.40678798, -7.87315671],
                            [39.40668146, -7.87320407],
                            [39.40665779, -7.8733343],
                            [39.4067288, -7.8734527],
                            [39.40679983, -7.8735474],
                            [39.40690635, -7.87370132],
                            [39.40706024, -7.87380788],
                            [39.40716676, -7.87389075],
                            [39.40732063, -7.8739618],
                            [39.407439, -7.87397363],
                            [39.40754553, -7.87406835],
                            [39.40771124, -7.87409202],
                            [39.40781778, -7.87406835],
                            [39.40795982, -7.87403283],
                            [39.40812554, -7.87412755],
                            [39.40824389, -7.87419858],
                            [39.40846879, -7.87418675],
                            [39.40858716, -7.87415123],
                            [39.40875287, -7.87409203],
                            [39.40890676, -7.87400916],
                            [39.40900144, -7.87386708],
                            [39.40915533, -7.87374869],
                            [39.4092737, -7.87364214],
                            [39.40946308, -7.87364214],
                            [39.40956961, -7.87370134],
                            [39.4096998, -7.87385524],
                            [39.40980635, -7.87399731],
                            [39.40990103, -7.87411571],
                            [39.4100194, -7.87426963],
                            [39.41006674, -7.87447089],
                            [39.41012592, -7.87463665],
                            [39.41018511, -7.8748024],
                            [39.41029163, -7.87499183],
                            [39.41039816, -7.87515758],
                            [39.41046919, -7.87527598],
                            [39.41056389, -7.87539437],
                            [39.4109545, -7.8754062],
                            [39.41114388, -7.87533518],
                            [39.41132142, -7.87507471],
                            [39.41136878, -7.87487344],
                            [39.41141612, -7.87466033],
                            [39.41141612, -7.87444722],
                            [39.41141612, -7.87413939],
                            [39.41135694, -7.87390261],
                            [39.41135694, -7.8736895],
                            [39.41142797, -7.87361847],
                            [39.41155817, -7.87363031],
                            [39.41167654, -7.87363032],
                            [39.41183041, -7.87363032],
                            [39.41198429, -7.87355927],
                            [39.41215, -7.87345272],
                            [39.4122802, -7.87327513],
                            [39.41229205, -7.87283708],
                            [39.41228021, -7.87260028],
                            [39.41226838, -7.8723635],
                            [39.41215001, -7.87212671],
                            [39.41212634, -7.87194913],
                            [39.41204349, -7.87172418],
                            [39.41191327, -7.87144003],
                            [39.41183042, -7.87114404],
                            [39.41166471, -7.87082439],
                            [39.41161737, -7.87059944],
                            [39.41161737, -7.87042184],
                            [39.41161737, -7.87020874],
                            [39.41164104, -7.87009034],
                            [39.41178308, -7.86990092],
                            [39.4118541, -7.86966413],
                            [39.41196064, -7.86946285],
                            [39.41201982, -7.86930895],
                            [39.41215002, -7.86909583],
                            [39.41224472, -7.86887089],
                            [39.41237492, -7.86859859],
                            [39.41246961, -7.86834996],
                            [39.4124933, -7.86813686],
                            [39.4124933, -7.86795926],
                            [39.41246961, -7.86776984],
                            [39.4123986, -7.86755672],
                            [39.41232759, -7.86746202],
                            [39.41224473, -7.86728442],
                            [39.4122684, -7.86714235],
                            [39.41237493, -7.8670358],
                            [39.41248146, -7.8669174],
                            [39.41257616, -7.86683452],
                            [39.41270635, -7.86682269],
                            [39.41286024, -7.8668227],
                            [39.41300226, -7.86689373],
                            [39.41309696, -7.86702395],
                            [39.41319166, -7.86711867],
                            [39.41335737, -7.86713052],
                            [39.4135941, -7.86713052],
                            [39.41387818, -7.86711868],
                            [39.41413859, -7.86695293],
                            [39.41425697, -7.86678718],
                            [39.41429246, -7.86657406],
                            [39.41437532, -7.86631361],
                            [39.41441084, -7.86621889],
                            [39.41445818, -7.86608866],
                            [39.41454105, -7.86598211],
                            [39.41468308, -7.86597026],
                            [39.41481329, -7.86597026],
                            [39.41495533, -7.86595842],
                            [39.41505002, -7.86604129],
                            [39.41507369, -7.86620706],
                            [39.41508553, -7.86633729],
                            [39.41509737, -7.86643201],
                            [39.41515656, -7.86653856],
                            [39.41526308, -7.86668063],
                            [39.41538144, -7.86679901],
                            [39.41555899, -7.86687006],
                            [39.41565369, -7.86687006],
                            [39.41579573, -7.86682271],
                            [39.41591409, -7.86671614],
                            [39.41607982, -7.86660959],
                            [39.41623369, -7.86651489],
                            [39.41644674, -7.86637281],
                            [39.41665981, -7.86626625],
                            [39.41679001, -7.86612419],
                            [39.41694389, -7.86598212],
                            [39.41708594, -7.86584005],
                            [39.41720429, -7.86569797],
                            [39.41732266, -7.86557958],
                            [39.41750022, -7.86541383],
                            [39.41767776, -7.86525991],
                            [39.41786716, -7.86507048],
                            [39.41799736, -7.86485738],
                            [39.41817491, -7.86471531],
                            [39.41825777, -7.86462059],
                            [39.41838797, -7.86450219],
                            [39.41847082, -7.86433644],
                            [39.41847083, -7.86418254],
                            [39.41847083, -7.86407599],
                            [39.418459, -7.86393392],
                            [39.41837614, -7.86382737],
                            [39.41832879, -7.86370897],
                            [39.41816307, -7.86355505],
                            [39.41800921, -7.86342482],
                            [39.41789084, -7.86329459],
                            [39.41772512, -7.86316435],
                            [39.4176541, -7.86298677],
                            [39.4176541, -7.86283285],
                            [39.4176186, -7.86265525],
                            [39.41759491, -7.86251318],
                            [39.41754757, -7.86237112],
                            [39.41745289, -7.86222905],
                            [39.41738186, -7.862158],
                            [39.41733452, -7.86205145],
                            [39.41729901, -7.8619449],
                            [39.41721615, -7.86185018],
                            [39.41707411, -7.86181466],
                            [39.4169084, -7.86180283],
                            [39.41675451, -7.86181466],
                            [39.41664799, -7.8619449],
                            [39.4165888, -7.8620633],
                            [39.4165533, -7.86218168],
                            [39.4165533, -7.86230007],
                            [39.41656513, -7.86245399],
                            [39.41665983, -7.86257237],
                            [39.41669534, -7.86272629],
                            [39.4166835, -7.86284469],
                            [39.41669534, -7.86296307],
                            [39.41669534, -7.86311699],
                            [39.41667165, -7.86334194],
                            [39.41662431, -7.86351954],
                            [39.41651778, -7.86363792],
                        ],
                        [
                            [39.41539975, -7.86922987],
                            [39.41531847, -7.8693762],
                            [39.41517218, -7.86947376],
                            [39.41502589, -7.86953879],
                            [39.41484709, -7.86953879],
                            [39.41470078, -7.86947376],
                            [39.4146195, -7.86937619],
                            [39.41457074, -7.86924612],
                            [39.41457074, -7.86903477],
                            [39.41466827, -7.8689047],
                            [39.41476581, -7.86877463],
                            [39.41478206, -7.86866081],
                            [39.41474955, -7.86853074],
                            [39.41470078, -7.86840067],
                            [39.41452198, -7.86838441],
                            [39.41437569, -7.86825434],
                            [39.4142619, -7.86823807],
                            [39.4141481, -7.86825434],
                            [39.41398556, -7.86828685],
                            [39.41380676, -7.86836814],
                            [39.4136442, -7.86846569],
                            [39.41343288, -7.86859576],
                            [39.41331909, -7.86877462],
                            [39.41318905, -7.86895347],
                            [39.41310778, -7.86918109],
                            [39.41310778, -7.86940871],
                            [39.41322156, -7.86971763],
                            [39.41333535, -7.86997777],
                            [39.4134979, -7.87025418],
                            [39.41351415, -7.87043303],
                            [39.41361168, -7.87069317],
                            [39.41367671, -7.87090454],
                            [39.41375798, -7.87121345],
                            [39.41375798, -7.87148985],
                            [39.41375798, -7.87170122],
                            [39.4137092, -7.8719451],
                            [39.41359542, -7.87215646],
                            [39.41359542, -7.87243287],
                            [39.41359542, -7.87270926],
                            [39.41348163, -7.87287185],
                            [39.41333533, -7.87311573],
                            [39.41299397, -7.87361976],
                            [39.41288018, -7.87397745],
                            [39.41283141, -7.87418881],
                            [39.41286393, -7.87446523],
                            [39.41301022, -7.87456278],
                            [39.4132378, -7.87457903],
                            [39.41351413, -7.87448148],
                            [39.41367669, -7.87428637],
                            [39.41390426, -7.87410752],
                            [39.41408307, -7.8739612],
                            [39.41429439, -7.87374983],
                            [39.41452196, -7.87348969],
                            [39.41463575, -7.87326207],
                            [39.414652, -7.8730507],
                            [39.41476579, -7.87285559],
                            [39.41486332, -7.87261172],
                            [39.41492834, -7.8724166],
                            [39.41505838, -7.87222151],
                            [39.41517217, -7.87204266],
                            [39.41543226, -7.87191259],
                            [39.41569234, -7.87183129],
                            [39.41600119, -7.8718313],
                            [39.41617999, -7.87201014],
                            [39.41640756, -7.87215647],
                            [39.41657012, -7.87233533],
                            [39.41673267, -7.87251418],
                            [39.4169765, -7.87270928],
                            [39.41717156, -7.8728556],
                            [39.41739913, -7.87300194],
                            [39.41756168, -7.87322956],
                            [39.41775675, -7.87331086],
                            [39.41801684, -7.87348971],
                            [39.41814688, -7.87363603],
                            [39.41842321, -7.87352223],
                            [39.41868329, -7.87332712],
                            [39.41887835, -7.87314827],
                            [39.41895963, -7.87288813],
                            [39.41913843, -7.87264426],
                            [39.41941478, -7.87240037],
                            [39.4196586, -7.872189],
                            [39.41986992, -7.87196138],
                            [39.42017877, -7.87170124],
                            [39.42060141, -7.87139233],
                            [39.42084525, -7.87105089],
                            [39.42110533, -7.87066068],
                            [39.42138167, -7.87041679],
                            [39.42164175, -7.87018917],
                            [39.42188559, -7.86992903],
                            [39.42214567, -7.86965264],
                            [39.4222432, -7.86944127],
                            [39.4222432, -7.8691486],
                            [39.4222432, -7.86890473],
                            [39.42216193, -7.86875839],
                            [39.42203188, -7.86866084],
                            [39.42190184, -7.8685958],
                            [39.42182057, -7.86844947],
                            [39.42169052, -7.86830315],
                            [39.42156048, -7.86817308],
                            [39.42151172, -7.86804301],
                            [39.42139793, -7.86791293],
                            [39.42128415, -7.86791293],
                            [39.42123539, -7.86791293],
                            [39.42104032, -7.86791293],
                            [39.42087776, -7.86796171],
                            [39.42071522, -7.86810803],
                            [39.4205364, -7.86825437],
                            [39.42043887, -7.86843321],
                            [39.42043887, -7.86866083],
                            [39.4203901, -7.86882343],
                            [39.42026006, -7.86903479],
                            [39.42026006, -7.86921364],
                            [39.42016253, -7.86932744],
                            [39.42006501, -7.86947378],
                            [39.41996746, -7.86966888],
                            [39.41978866, -7.86983147],
                            [39.41952857, -7.8698965],
                            [39.419301, -7.87002657],
                            [39.41907343, -7.8702542],
                            [39.41905717, -7.87054685],
                            [39.41907343, -7.87080699],
                            [39.41910594, -7.87098584],
                            [39.4191872, -7.8711647],
                            [39.4191872, -7.87129477],
                            [39.41905716, -7.87134355],
                            [39.41889462, -7.8713598],
                            [39.41871582, -7.87140857],
                            [39.41848823, -7.87140857],
                            [39.41830943, -7.87142484],
                            [39.41813062, -7.87144109],
                            [39.41803309, -7.87142484],
                            [39.41780552, -7.87131102],
                            [39.41762672, -7.87113217],
                            [39.41752918, -7.87092081],
                            [39.41754544, -7.87075821],
                            [39.41765922, -7.87064441],
                            [39.41765922, -7.87053059],
                            [39.41756169, -7.87040052],
                            [39.41743165, -7.87033548],
                            [39.4174154, -7.87020542],
                            [39.41730161, -7.87015663],
                            [39.41709029, -7.87002656],
                            [39.41691149, -7.86988023],
                            [39.41683021, -7.86970139],
                            [39.41687897, -7.86957132],
                            [39.41686273, -7.86940872],
                            [39.41673269, -7.8692624],
                            [39.4167652, -7.86911606],
                            [39.41678145, -7.86892096],
                            [39.41670017, -7.86879089],
                            [39.41671644, -7.86864457],
                            [39.41683022, -7.86848197],
                            [39.41683022, -7.86836816],
                            [39.41674894, -7.86823808],
                            [39.41660265, -7.86807549],
                            [39.41660265, -7.86797794],
                            [39.41660265, -7.86788039],
                            [39.41648886, -7.8677991],
                            [39.41640758, -7.86776657],
                            [39.41632632, -7.86776657],
                            [39.41624504, -7.86776657],
                            [39.41616376, -7.86770154],
                            [39.41601747, -7.86765277],
                            [39.41587116, -7.86766902],
                            [39.41570861, -7.86775032],
                            [39.41561108, -7.86791291],
                            [39.41559483, -7.86814053],
                            [39.41559483, -7.86833563],
                            [39.41559483, -7.86857952],
                            [39.41559483, -7.86877463],
                            [39.4155298, -7.86895348],
                            [39.41548103, -7.86911605],
                            [39.41539975, -7.86922987],
                        ],
                        [
                            [39.39579679, -7.87339763],
                            [39.39555768, -7.87337923],
                            [39.39535536, -7.87337923],
                            [39.39520821, -7.87354481],
                            [39.39520821, -7.87365519],
                            [39.39509783, -7.87374719],
                            [39.39493229, -7.87369199],
                            [39.39474836, -7.87369199],
                            [39.39463799, -7.87391276],
                            [39.39465638, -7.87407834],
                            [39.39482194, -7.87417033],
                            [39.39496907, -7.87428073],
                            [39.39498748, -7.8744095],
                            [39.39513463, -7.8744647],
                            [39.39526337, -7.8744647],
                            [39.39541052, -7.87453829],
                            [39.3954841, -7.87468547],
                            [39.3954841, -7.87485105],
                            [39.39537374, -7.87497984],
                            [39.39518979, -7.87503504],
                            [39.39507944, -7.87505343],
                            [39.39495068, -7.87516381],
                            [39.3949139, -7.87547657],
                            [39.39500586, -7.87569735],
                            [39.39502425, -7.87591812],
                            [39.39513462, -7.8761389],
                            [39.3951714, -7.87634128],
                            [39.39531855, -7.87654366],
                            [39.39550249, -7.87674603],
                            [39.39568642, -7.87689321],
                            [39.39585196, -7.87716919],
                            [39.39598072, -7.87744515],
                            [39.39598072, -7.87768433],
                            [39.3960175, -7.87794189],
                            [39.3960175, -7.87825466],
                            [39.39609107, -7.87854902],
                            [39.39614626, -7.87880659],
                            [39.39618303, -7.87906417],
                            [39.39620144, -7.87934012],
                            [39.39620144, -7.8795977],
                            [39.39623822, -7.87978167],
                            [39.39623822, -7.88009444],
                            [39.39623821, -7.88033361],
                            [39.39623821, -7.88057279],
                            [39.39621982, -7.88073837],
                            [39.39620143, -7.88105113],
                            [39.39614625, -7.8812903],
                            [39.39609106, -7.88151107],
                            [39.39609106, -7.88178705],
                            [39.39609106, -7.88198941],
                            [39.39609106, -7.88222858],
                            [39.39603589, -7.88239417],
                            [39.39609105, -7.88263334],
                            [39.3962382, -7.88287251],
                            [39.39634857, -7.88309329],
                            [39.3965325, -7.88331407],
                            [39.39660608, -7.88347964],
                            [39.39673484, -7.88370042],
                            [39.39671645, -7.883866],
                            [39.39645892, -7.88412356],
                            [39.39605427, -7.88416036],
                            [39.39581515, -7.88414197],
                            [39.39518976, -7.88401318],
                            [39.39500582, -7.88425234],
                            [39.39498743, -7.88449151],
                            [39.39506101, -7.88471229],
                            [39.39515297, -7.88493306],
                            [39.39517136, -7.88519063],
                            [39.39528173, -7.88537462],
                            [39.39546566, -7.88554019],
                            [39.39572317, -7.88566899],
                            [39.3959623, -7.88563218],
                            [39.3962198, -7.88555859],
                            [39.39638534, -7.88541141],
                            [39.39656929, -7.88511706],
                            [39.39664287, -7.88485948],
                            [39.39671644, -7.88460192],
                            [39.39690037, -7.88441793],
                            [39.39704753, -7.88423396],
                            [39.39717629, -7.88421557],
                            [39.39723146, -7.88434434],
                            [39.39734183, -7.88441793],
                            [39.39754415, -7.88443634],
                            [39.3977097, -7.8846571],
                            [39.39791202, -7.88487788],
                            [39.39807756, -7.88509865],
                            [39.39816954, -7.88524584],
                            [39.39802239, -7.88535623],
                            [39.39774648, -7.88535623],
                            [39.39754414, -7.88533782],
                            [39.39736021, -7.88546661],
                            [39.39736021, -7.88568739],
                            [39.39761771, -7.88594495],
                            [39.39789362, -7.88611055],
                            [39.39824311, -7.88625773],
                            [39.39864777, -7.88640492],
                            [39.39881331, -7.8865153],
                            [39.39903403, -7.88679127],
                            [39.39918118, -7.88695685],
                            [39.39938351, -7.88715922],
                            [39.39956744, -7.8873432],
                            [39.39975139, -7.88749039],
                            [39.39986174, -7.88765597],
                            [39.40006408, -7.88780315],
                            [39.40019282, -7.88785835],
                            [39.40037677, -7.88785835],
                            [39.40061588, -7.88782155],
                            [39.40081822, -7.88776635],
                            [39.40103895, -7.88767436],
                            [39.40122288, -7.88754559],
                            [39.40122288, -7.88732482],
                            [39.40129645, -7.88703045],
                            [39.40131485, -7.88675448],
                            [39.40131485, -7.8865705],
                            [39.40151718, -7.88642332],
                            [39.40153557, -7.88612895],
                            [39.40155396, -7.88598177],
                            [39.40168273, -7.88579778],
                            [39.40190345, -7.885761],
                            [39.40206899, -7.885761],
                            [39.40225294, -7.88587138],
                            [39.40245526, -7.88600017],
                            [39.4026208, -7.88600017],
                            [39.40293349, -7.88600017],
                            [39.40315421, -7.88600017],
                            [39.40330136, -7.88600017],
                            [39.40346692, -7.88598178],
                            [39.40359567, -7.88587138],
                            [39.40359567, -7.88568741],
                            [39.40354048, -7.88555862],
                            [39.40341174, -7.88541144],
                            [39.40341174, -7.88520907],
                            [39.40357728, -7.88509868],
                            [39.40377961, -7.88513548],
                            [39.40394515, -7.88530106],
                            [39.40422105, -7.88548503],
                            [39.4043682, -7.88568742],
                            [39.40451535, -7.88594498],
                            [39.40460733, -7.88618417],
                            [39.40475446, -7.88636814],
                            [39.40497519, -7.88653372],
                            [39.40519591, -7.8866257],
                            [39.40537986, -7.88671771],
                            [39.40561897, -7.88693848],
                            [39.4058029, -7.88703047],
                            [39.40596844, -7.88686489],
                            [39.40622596, -7.88692007],
                            [39.40644669, -7.88703047],
                            [39.40664901, -7.88704887],
                            [39.40681456, -7.88704887],
                            [39.40692492, -7.88723284],
                            [39.40709046, -7.88745361],
                            [39.40721922, -7.88767439],
                            [39.40723761, -7.88791356],
                            [39.40723761, -7.88822634],
                            [39.4072376, -7.8884839],
                            [39.40734797, -7.88868628],
                            [39.40751351, -7.88885186],
                            [39.40780781, -7.88885186],
                            [39.40806532, -7.88887026],
                            [39.40821247, -7.88898064],
                            [39.40835962, -7.88916463],
                            [39.40852516, -7.88936701],
                            [39.4087091, -7.88955098],
                            [39.40889303, -7.88975336],
                            [39.40902179, -7.88995573],
                            [39.40918733, -7.89013972],
                            [39.40948163, -7.89026849],
                            [39.40964717, -7.89026849],
                            [39.40988629, -7.89026849],
                            [39.4101254, -7.89026849],
                            [39.41034613, -7.8902501],
                            [39.41060365, -7.8903053],
                            [39.41082438, -7.89043409],
                            [39.41106349, -7.89048927],
                            [39.41130261, -7.89048928],
                            [39.41152333, -7.89047088],
                            [39.41174406, -7.89039729],
                            [39.41189121, -7.89019491],
                            [39.41201996, -7.88993735],
                            [39.41203836, -7.88971657],
                            [39.41209353, -7.8894774],
                            [39.41209353, -7.88921984],
                            [39.41216711, -7.88898065],
                            [39.41216711, -7.8887047],
                            [39.41227748, -7.88850232],
                            [39.41246141, -7.88831835],
                            [39.41270053, -7.88811596],
                            [39.41299483, -7.88804237],
                            [39.41312359, -7.88789519],
                            [39.41349147, -7.8878216],
                            [39.4136754, -7.88765602],
                            [39.41384094, -7.88745364],
                            [39.41391452, -7.88714088],
                            [39.41391452, -7.88686492],
                            [39.41382255, -7.88664415],
                            [39.41378578, -7.88636818],
                            [39.41393291, -7.8862026],
                            [39.41426401, -7.88596343],
                            [39.41455831, -7.88574266],
                            [39.41474224, -7.88548508],
                            [39.41472385, -7.88522751],
                            [39.41470546, -7.88502513],
                            [39.41483421, -7.88487795],
                            [39.41481582, -7.88465717],
                            [39.41470547, -7.88445481],
                            [39.41461349, -7.88419723],
                            [39.41487101, -7.88393967],
                            [39.41505494, -7.88360851],
                            [39.41505494, -7.88333254],
                            [39.41499977, -7.88313016],
                            [39.41466868, -7.88292779],
                            [39.41442956, -7.88281739],
                            [39.41435599, -7.88303817],
                            [39.4143376, -7.88324055],
                            [39.41408007, -7.88346132],
                            [39.41376738, -7.88346132],
                            [39.4134363, -7.88333253],
                            [39.4131788, -7.88313015],
                            [39.4128477, -7.88287258],
                            [39.41268216, -7.88261502],
                            [39.41266377, -7.88235744],
                            [39.41251663, -7.88209988],
                            [39.41242466, -7.8818791],
                            [39.41213036, -7.88189749],
                            [39.41201999, -7.88206307],
                            [39.41172569, -7.88206307],
                            [39.41148657, -7.88198948],
                            [39.41122907, -7.88195269],
                            [39.41098995, -7.88191588],
                            [39.41067726, -7.88189749],
                            [39.41045654, -7.88206306],
                            [39.41034616, -7.88228384],
                            [39.41034616, -7.88250462],
                            [39.41041974, -7.882707],
                            [39.41062207, -7.88290936],
                            [39.41071404, -7.88314855],
                            [39.41058527, -7.88333252],
                            [39.41032777, -7.88329573],
                            [39.40999669, -7.88309335],
                            [39.40961041, -7.88287257],
                            [39.40929772, -7.88279898],
                            [39.40896664, -7.88279898],
                            [39.40869073, -7.88279898],
                            [39.40847, -7.88270699],
                            [39.4085068, -7.88250461],
                            [39.40848841, -7.88204466],
                            [39.40845162, -7.88154791],
                            [39.4082125, -7.88116156],
                            [39.40786302, -7.8808304],
                            [39.40749516, -7.88066482],
                            [39.40729282, -7.88049925],
                            [39.40699852, -7.88040724],
                            [39.4067778, -7.88040724],
                            [39.40661226, -7.88033365],
                            [39.40644672, -7.88007608],
                            [39.40624438, -7.87994729],
                            [39.40576615, -7.87972652],
                            [39.40539828, -7.87957935],
                            [39.4050304, -7.87946895],
                            [39.40469932, -7.87934016],
                            [39.40433145, -7.87921138],
                            [39.40401876, -7.8790274],
                            [39.40374285, -7.87888021],
                            [39.40339338, -7.87860425],
                            [39.40308067, -7.87827308],
                            [39.40276798, -7.87796032],
                            [39.40251048, -7.87773954],
                            [39.40238172, -7.87757396],
                            [39.40236333, -7.87738999],
                            [39.40225297, -7.87709562],
                            [39.4021426, -7.87693004],
                            [39.40197707, -7.87674606],
                            [39.40182992, -7.87663568],
                            [39.4015908, -7.87663567],
                            [39.40133328, -7.87663567],
                            [39.40105738, -7.87669085],
                            [39.40074469, -7.87681964],
                            [39.40057914, -7.87691163],
                            [39.40026644, -7.87691163],
                            [39.39995375, -7.87683803],
                            [39.39980661, -7.87650688],
                            [39.399825, -7.87621251],
                            [39.399825, -7.87588136],
                            [39.39980661, -7.87567897],
                            [39.39980661, -7.87531102],
                            [39.39975144, -7.87499825],
                            [39.39967787, -7.87479587],
                            [39.39945714, -7.8746671],
                            [39.39923642, -7.8746487],
                            [39.39890532, -7.8746487],
                            [39.39864782, -7.8746487],
                            [39.39827994, -7.8746487],
                            [39.39800403, -7.8746487],
                            [39.39769134, -7.8746487],
                            [39.39737865, -7.8745935],
                            [39.39715792, -7.87431752],
                            [39.3969556, -7.87411516],
                            [39.39680845, -7.87389438],
                            [39.39655093, -7.8737472],
                            [39.39620146, -7.87352642],
                            [39.39599913, -7.87343443],
                            [39.39579679, -7.87339763],
                        ],
                        [
                            [39.37812407, -7.88785257],
                            [39.37831249, -7.88801747],
                            [39.37852445, -7.88804103],
                            [39.37885417, -7.88804103],
                            [39.37925454, -7.88789969],
                            [39.37967848, -7.88764057],
                            [39.37977268, -7.88747566],
                            [39.38003175, -7.8872401],
                            [39.38036149, -7.88721654],
                            [39.38076186, -7.88738144],
                            [39.38118578, -7.88745211],
                            [39.38156261, -7.88747567],
                            [39.38193944, -7.88733433],
                            [39.38208075, -7.88712232],
                            [39.38229272, -7.88691032],
                            [39.38264599, -7.88681608],
                            [39.38295217, -7.88658051],
                            [39.38302282, -7.88627428],
                            [39.38302282, -7.88596803],
                            [39.38306993, -7.88563824],
                            [39.38306993, -7.88537911],
                            [39.38302283, -7.88509642],
                            [39.38292861, -7.88488441],
                            [39.38278731, -7.88464883],
                            [39.38274022, -7.88438971],
                            [39.38257535, -7.88405991],
                            [39.38245759, -7.88380079],
                            [39.38241048, -7.88342387],
                            [39.38241048, -7.88316474],
                            [39.3825047, -7.88297629],
                            [39.38266955, -7.88274073],
                            [39.38278732, -7.88252872],
                            [39.38283442, -7.88226958],
                            [39.38276377, -7.88208114],
                            [39.3825989, -7.88193978],
                            [39.3823634, -7.88189268],
                            [39.38203368, -7.88179844],
                            [39.38186881, -7.88158643],
                            [39.3817275, -7.88137442],
                            [39.38163329, -7.8811153],
                            [39.38160974, -7.88085617],
                            [39.38151555, -7.88059703],
                            [39.38142133, -7.88026723],
                            [39.38144488, -7.88000811],
                            [39.38149199, -7.87972543],
                            [39.38149199, -7.87941919],
                            [39.38146844, -7.87916006],
                            [39.38128003, -7.87892448],
                            [39.38116227, -7.87875959],
                            [39.38097386, -7.87890093],
                            [39.38097385, -7.87918362],
                            [39.38087965, -7.87944275],
                            [39.38073833, -7.87970188],
                            [39.38062058, -7.87998455],
                            [39.38045572, -7.88014946],
                            [39.38033795, -7.88038502],
                            [39.38033795, -7.88064414],
                            [39.38033795, -7.8808326],
                            [39.3802673, -7.88099751],
                            [39.38014954, -7.88113884],
                            [39.38005534, -7.88137442],
                            [39.38003177, -7.88158643],
                            [39.38010244, -7.88189267],
                            [39.38012599, -7.88210468],
                            [39.38024375, -7.88226957],
                            [39.38033794, -7.88245803],
                            [39.38047925, -7.88255226],
                            [39.38078543, -7.88264649],
                            [39.38092673, -7.88288206],
                            [39.38097384, -7.88316474],
                            [39.38090318, -7.88340031],
                            [39.38083253, -7.88365944],
                            [39.38076187, -7.88382434],
                            [39.38057346, -7.88391856],
                            [39.38047924, -7.88417769],
                            [39.38029083, -7.88441326],
                            [39.38010243, -7.88462527],
                            [39.37977269, -7.88462527],
                            [39.37958428, -7.88462527],
                            [39.37939588, -7.88462527],
                            [39.37913681, -7.88462527],
                            [39.37892483, -7.88467239],
                            [39.37873642, -7.88469594],
                            [39.37857157, -7.88469594],
                            [39.37835959, -7.88464882],
                            [39.37828894, -7.88453104],
                            [39.37812409, -7.88436614],
                            [39.37795922, -7.88420125],
                            [39.37793567, -7.88398922],
                            [39.37791211, -7.88382433],
                            [39.37786502, -7.88370655],
                            [39.37786502, -7.88354164],
                            [39.37784146, -7.8834003],
                            [39.37772372, -7.88321184],
                            [39.3774882, -7.88309405],
                            [39.37727623, -7.88314117],
                            [39.37715847, -7.88323539],
                            [39.37704071, -7.88325896],
                            [39.37687584, -7.88321184],
                            [39.37671099, -7.88309405],
                            [39.37654612, -7.88295271],
                            [39.37635771, -7.88281137],
                            [39.37619286, -7.88269358],
                            [39.37598088, -7.88264647],
                            [39.37581603, -7.88264646],
                            [39.37553341, -7.88267003],
                            [39.37536855, -7.88281137],
                            [39.37522724, -7.88302338],
                            [39.37506237, -7.88316471],
                            [39.37487395, -7.88335317],
                            [39.37459134, -7.88354163],
                            [39.37452068, -7.88375365],
                            [39.37466199, -7.88403633],
                            [39.3748504, -7.88413055],
                            [39.37510947, -7.88422479],
                            [39.3754863, -7.88446036],
                            [39.37574537, -7.88471949],
                            [39.37605154, -7.8849315],
                            [39.37623994, -7.88511996],
                            [39.37640481, -7.88523775],
                            [39.37673453, -7.8854262],
                            [39.37675809, -7.88563821],
                            [39.37666387, -7.88577955],
                            [39.3764048, -7.8858031],
                            [39.37612219, -7.88589734],
                            [39.37595732, -7.88615646],
                            [39.37567471, -7.88627425],
                            [39.37546273, -7.8864627],
                            [39.37539208, -7.88667471],
                            [39.37536853, -7.88695739],
                            [39.37520366, -7.88721651],
                            [39.37510946, -7.88756988],
                            [39.3748975, -7.88785255],
                            [39.37487393, -7.88813525],
                            [39.37477973, -7.88841792],
                            [39.37489749, -7.8886535],
                            [39.37518011, -7.88879484],
                            [39.37539207, -7.88893618],
                            [39.37555693, -7.88879484],
                            [39.37565114, -7.88855927],
                            [39.37569824, -7.88837081],
                            [39.37588666, -7.88822947],
                            [39.37621638, -7.88813525],
                            [39.37647545, -7.88797035],
                            [39.37675808, -7.88801747],
                            [39.37706424, -7.8879468],
                            [39.37732331, -7.88780546],
                            [39.3779592, -7.88773478],
                            [39.37812407, -7.88785257],
                        ],
                        [
                            [39.34903583, -7.88481907],
                            [39.34889656, -7.88436134],
                            [39.34891645, -7.88398322],
                            [39.34893635, -7.8835653],
                            [39.34893636, -7.88318717],
                            [39.34883688, -7.88280905],
                            [39.34851853, -7.88237124],
                            [39.34825987, -7.88223193],
                            [39.34810071, -7.88205282],
                            [39.34790173, -7.8819334],
                            [39.34770277, -7.8817344],
                            [39.34750381, -7.88157518],
                            [39.34732474, -7.88147567],
                            [39.34712578, -7.88149558],
                            [39.34710587, -7.88169459],
                            [39.34706608, -7.88195331],
                            [39.34706608, -7.88223192],
                            [39.34704618, -7.88249063],
                            [39.34704618, -7.88278915],
                            [39.34710587, -7.88312746],
                            [39.34734462, -7.88342599],
                            [39.34764308, -7.88396331],
                            [39.34798131, -7.88444095],
                            [39.34829966, -7.88487876],
                            [39.3486578, -7.88525688],
                            [39.34887666, -7.885635],
                            [39.34923479, -7.88593353],
                            [39.34943376, -7.88621214],
                            [39.34965261, -7.88664997],
                            [39.34991127, -7.88708779],
                            [39.35003065, -7.8873863],
                            [39.35016993, -7.88768482],
                            [39.35036889, -7.88806294],
                            [39.35060764, -7.88832166],
                            [39.35068723, -7.88812264],
                            [39.35068724, -7.88776443],
                            [39.35080661, -7.88748582],
                            [39.35110507, -7.88708779],
                            [39.35124434, -7.88655046],
                            [39.35122444, -7.88605295],
                            [39.35106528, -7.88567481],
                            [39.35090611, -7.8853365],
                            [39.35074693, -7.88509769],
                            [39.35054797, -7.88479916],
                            [39.350349, -7.88456035],
                            [39.35013015, -7.88460016],
                            [39.34987148, -7.88479916],
                            [39.34965262, -7.88503799],
                            [39.34933427, -7.88503798],
                            [39.34903583, -7.88481907],
                        ],
                        [
                            [39.34207204, -7.8821921],
                            [39.34183328, -7.88243091],
                            [39.34161441, -7.88259011],
                            [39.34139555, -7.88274932],
                            [39.34131597, -7.88302794],
                            [39.34131597, -7.88326675],
                            [39.34137565, -7.88354536],
                            [39.34147513, -7.88388369],
                            [39.34161441, -7.88410259],
                            [39.34191286, -7.884222],
                            [39.34203223, -7.88446082],
                            [39.34205214, -7.88475934],
                            [39.34209192, -7.88503795],
                            [39.34227099, -7.88519716],
                            [39.34264902, -7.88523696],
                            [39.34308675, -7.88535636],
                            [39.34352447, -7.88549567],
                            [39.34386272, -7.88567478],
                            [39.34394231, -7.885834],
                            [39.34394231, -7.88605292],
                            [39.34388261, -7.88629173],
                            [39.34370353, -7.88645093],
                            [39.34350457, -7.88647084],
                            [39.34340509, -7.88653053],
                            [39.34328571, -7.88666984],
                            [39.34334541, -7.88688876],
                            [39.34350457, -7.88714746],
                            [39.34384281, -7.88744599],
                            [39.34422085, -7.8876848],
                            [39.34467846, -7.88798331],
                            [39.34501671, -7.88816242],
                            [39.34533506, -7.88824203],
                            [39.34567329, -7.88832164],
                            [39.34587225, -7.88824203],
                            [39.34601153, -7.88804303],
                            [39.34613092, -7.88778431],
                            [39.3462304, -7.88746589],
                            [39.34630999, -7.88716738],
                            [39.3462702, -7.88678927],
                            [39.34613092, -7.88641113],
                            [39.34597176, -7.88595341],
                            [39.34581258, -7.88557529],
                            [39.3457131, -7.88529667],
                            [39.34553403, -7.88485886],
                            [39.34545444, -7.88456033],
                            [39.34535496, -7.88438122],
                            [39.34521569, -7.8840031],
                            [39.34511621, -7.88372449],
                            [39.34499682, -7.88350557],
                            [39.34485755, -7.88336626],
                            [39.34467848, -7.88310756],
                            [39.34455911, -7.88282894],
                            [39.34441984, -7.88247072],
                            [39.34422088, -7.88223191],
                            [39.34400201, -7.8820926],
                            [39.34380305, -7.8819931],
                            [39.34352448, -7.88185377],
                            [39.34324593, -7.88167468],
                            [39.34306688, -7.88151546],
                            [39.3429077, -7.88143585],
                            [39.34260926, -7.88143585],
                            [39.34239039, -7.88145576],
                            [39.34239039, -7.88165476],
                            [39.34223121, -7.88193338],
                            [39.34207204, -7.8821921],
                        ],
                        [
                            [39.31456322, -7.88154955],
                            [39.3141676, -7.88161551],
                            [39.31383791, -7.88170343],
                            [39.3135302, -7.88183535],
                            [39.3132225, -7.8819013],
                            [39.31280489, -7.88192328],
                            [39.31256311, -7.88201122],
                            [39.31227739, -7.88207717],
                            [39.31188177, -7.88223106],
                            [39.31144217, -7.882297],
                            [39.31095863, -7.88258279],
                            [39.31049707, -7.88286859],
                            [39.31010144, -7.8831324],
                            [39.30974977, -7.88337423],
                            [39.30944206, -7.88359406],
                            [39.3091783, -7.88385786],
                            [39.30884863, -7.88409969],
                            [39.30851894, -7.88447343],
                            [39.30827716, -7.88475921],
                            [39.3078156, -7.88522087],
                            [39.30748591, -7.88557263],
                            [39.30717819, -7.88590239],
                            [39.30684851, -7.88625413],
                            [39.30665069, -7.88640803],
                            [39.30651882, -7.88658389],
                            [39.30654079, -7.88678175],
                            [39.30678256, -7.88680374],
                            [39.30720017, -7.88678176],
                            [39.30757382, -7.88673778],
                            [39.30788153, -7.88662787],
                            [39.30825517, -7.88653993],
                            [39.30856288, -7.88643001],
                            [39.30900247, -7.8863201],
                            [39.30950798, -7.88616621],
                            [39.30999153, -7.8859024],
                            [39.31049706, -7.88568257],
                            [39.3109806, -7.88541875],
                            [39.31157403, -7.88515494],
                            [39.31192571, -7.88504503],
                            [39.31240925, -7.88486916],
                            [39.3130027, -7.88469328],
                            [39.31348624, -7.8845394],
                            [39.31390383, -7.88436352],
                            [39.31427749, -7.88416567],
                            [39.31460718, -7.88383591],
                            [39.31506874, -7.88339622],
                            [39.31548635, -7.88291257],
                            [39.31590395, -7.88231901],
                            [39.31614572, -7.88187932],
                            [39.31625563, -7.88141766],
                            [39.31605781, -7.88135171],
                            [39.31564021, -7.88139567],
                            [39.31535448, -7.88143964],
                            [39.3149149, -7.8814836],
                            [39.31456322, -7.88154955],
                        ],
                        [
                            [39.3726395, -7.88172184],
                            [39.37246727, -7.88172184],
                            [39.37236129, -7.88172184],
                            [39.37224206, -7.88172184],
                            [39.37210959, -7.88165559],
                            [39.37199036, -7.88158933],
                            [39.37185788, -7.88156282],
                            [39.37176515, -7.88153633],
                            [39.37165917, -7.88147007],
                            [39.37151344, -7.88140382],
                            [39.37134121, -7.88143033],
                            [39.37122198, -7.88152308],
                            [39.3710895, -7.88165559],
                            [39.3710895, -7.88176159],
                            [39.37118225, -7.88188085],
                            [39.37135446, -7.88200011],
                            [39.37143395, -7.88217237],
                            [39.37155318, -7.88226513],
                            [39.3717254, -7.88221212],
                            [39.37188437, -7.88222537],
                            [39.37196385, -7.88234463],
                            [39.37210958, -7.88243738],
                            [39.37225531, -7.88239764],
                            [39.37248052, -7.88241089],
                            [39.37259975, -7.88249039],
                            [39.37273223, -7.88247714],
                            [39.37282496, -7.88246389],
                            [39.37295744, -7.88253015],
                            [39.37306342, -7.88266266],
                            [39.3732489, -7.88263615],
                            [39.37340787, -7.88253015],
                            [39.37351385, -7.8825169],
                            [39.37369932, -7.8825169],
                            [39.37379206, -7.88238439],
                            [39.3738583, -7.88229164],
                            [39.37397752, -7.88229164],
                            [39.37425573, -7.88229164],
                            [39.37453394, -7.88223863],
                            [39.3747724, -7.88206637],
                            [39.37485188, -7.88192062],
                            [39.37487838, -7.88178811],
                            [39.37481214, -7.88166886],
                            [39.37478565, -7.88150984],
                            [39.3747989, -7.88139059],
                            [39.37475915, -7.88123157],
                            [39.37469291, -7.88105932],
                            [39.37473266, -7.88092681],
                            [39.37477241, -7.8808473],
                            [39.37482539, -7.88072804],
                            [39.37483864, -7.88056903],
                            [39.37485189, -7.88044977],
                            [39.37493137, -7.88027752],
                            [39.37486514, -7.88013176],
                            [39.37486514, -7.879986],
                            [39.37486514, -7.87984024],
                            [39.37475916, -7.87976074],
                            [39.37463993, -7.87965473],
                            [39.37462668, -7.87962823],
                            [39.37442797, -7.87973423],
                            [39.37432198, -7.87966798],
                            [39.374216, -7.87972098],
                            [39.37408352, -7.87978724],
                            [39.37407027, -7.87990649],
                            [39.37407027, -7.88002575],
                            [39.37407026, -7.88015826],
                            [39.37401728, -7.88029077],
                            [39.37393778, -7.88047627],
                            [39.37393778, -7.88072804],
                            [39.37387155, -7.88099306],
                            [39.37379207, -7.88113882],
                            [39.37368607, -7.88127133],
                            [39.37359334, -7.88139058],
                            [39.37344761, -7.88145683],
                            [39.3733019, -7.88160259],
                            [39.37314292, -7.88172184],
                            [39.37294419, -7.88172184],
                            [39.37279848, -7.88172184],
                            [39.3726395, -7.88172184],
                        ],
                        [
                            [39.39030142, -7.88113004],
                            [39.39047903, -7.88125441],
                            [39.39062112, -7.88137876],
                            [39.39078096, -7.88153863],
                            [39.39090528, -7.88168075],
                            [39.3910829, -7.88178735],
                            [39.39122499, -7.88178735],
                            [39.39140259, -7.88187618],
                            [39.39156244, -7.88180512],
                            [39.39156244, -7.88155641],
                            [39.39156244, -7.881361],
                            [39.39156244, -7.88120111],
                            [39.39158021, -7.88107675],
                            [39.39170453, -7.88098794],
                            [39.39186437, -7.88097017],
                            [39.39202422, -7.88098794],
                            [39.39211303, -7.881059],
                            [39.39225512, -7.881059],
                            [39.39232616, -7.88089912],
                            [39.39232616, -7.88068594],
                            [39.39232617, -7.88054382],
                            [39.3923084, -7.88041946],
                            [39.39227287, -7.88025957],
                            [39.39213078, -7.88011745],
                            [39.39202423, -7.88002864],
                            [39.39182886, -7.87997533],
                            [39.39159796, -7.87995758],
                            [39.39131379, -7.87995758],
                            [39.39111842, -7.87995758],
                            [39.39102963, -7.87976216],
                            [39.39104738, -7.87946016],
                            [39.39111842, -7.87926474],
                            [39.391225, -7.87906933],
                            [39.39134932, -7.87876732],
                            [39.39147365, -7.87855415],
                            [39.39154469, -7.87825215],
                            [39.39158022, -7.87793237],
                            [39.39159797, -7.87748826],
                            [39.39154469, -7.87720402],
                            [39.39142037, -7.87688426],
                            [39.39118948, -7.87674214],
                            [39.39088755, -7.87674213],
                            [39.39062114, -7.87681319],
                            [39.39024815, -7.87683094],
                            [39.38996398, -7.87672436],
                            [39.38976861, -7.87652895],
                            [39.38962652, -7.87642235],
                            [39.38946669, -7.876298],
                            [39.38921803, -7.87617365],
                            [39.38895161, -7.87606706],
                            [39.38879176, -7.87592494],
                            [39.38857865, -7.875996],
                            [39.38838326, -7.87612035],
                            [39.38818789, -7.87628024],
                            [39.38806357, -7.87652894],
                            [39.3880458, -7.87677764],
                            [39.3880458, -7.87706188],
                            [39.3880458, -7.8772573],
                            [39.3880458, -7.87748825],
                            [39.38801029, -7.87771918],
                            [39.38801028, -7.87803896],
                            [39.38801028, -7.87834095],
                            [39.38801028, -7.87862519],
                            [39.38801028, -7.87894495],
                            [39.38806356, -7.87924696],
                            [39.38815237, -7.87946014],
                            [39.38824116, -7.87963779],
                            [39.38834773, -7.8798332],
                            [39.38847205, -7.88004638],
                            [39.38852533, -7.88033062],
                            [39.38861414, -7.88049051],
                            [39.38877399, -7.88068592],
                            [39.38888055, -7.88082804],
                            [39.3891292, -7.88097016],
                            [39.38925353, -7.88109451],
                            [39.38937786, -7.88128993],
                            [39.38955546, -7.88144982],
                            [39.38973308, -7.88152088],
                            [39.38987516, -7.88150311],
                            [39.38994619, -7.88136099],
                            [39.38998172, -7.88123664],
                            [39.39012381, -7.88113004],
                            [39.39030142, -7.88113004],
                        ],
                        [
                            [39.36988531, -7.87806112],
                            [39.36982391, -7.87799972],
                            [39.36974715, -7.8779383],
                            [39.36964737, -7.87787688],
                            [39.36958597, -7.87782314],
                            [39.36954759, -7.8777387],
                            [39.36948619, -7.87764657],
                            [39.36940177, -7.87758515],
                            [39.36928664, -7.87756212],
                            [39.36915616, -7.87754677],
                            [39.36904871, -7.8775007],
                            [39.36900266, -7.87742393],
                            [39.36900266, -7.87735484],
                            [39.36900267, -7.87727808],
                            [39.36893359, -7.87718595],
                            [39.36887219, -7.87711686],
                            [39.36887219, -7.87705544],
                            [39.3688952, -7.87698635],
                            [39.36895661, -7.87692493],
                            [39.36896429, -7.87681746],
                            [39.36895661, -7.87669463],
                            [39.36888753, -7.87664857],
                            [39.36881078, -7.87661017],
                            [39.36877241, -7.87654875],
                            [39.36878776, -7.87648735],
                            [39.36883382, -7.87641826],
                            [39.36890289, -7.87634148],
                            [39.36893359, -7.87628006],
                            [39.3688645, -7.87621097],
                            [39.36880312, -7.8762033],
                            [39.36871868, -7.87621097],
                            [39.36862657, -7.87625704],
                            [39.36851913, -7.87625704],
                            [39.36845005, -7.8762033],
                            [39.36839632, -7.87614188],
                            [39.36840401, -7.87608046],
                            [39.36841935, -7.87604208],
                            [39.36842702, -7.87594995],
                            [39.36838098, -7.87589623],
                            [39.3683119, -7.87585783],
                            [39.3682505, -7.87584248],
                            [39.36818909, -7.87584248],
                            [39.36815072, -7.87578106],
                            [39.36815839, -7.87571197],
                            [39.36819677, -7.87564288],
                            [39.36819677, -7.87558914],
                            [39.36815839, -7.87553541],
                            [39.36811235, -7.87549703],
                            [39.36805862, -7.87544328],
                            [39.36798187, -7.87544328],
                            [39.36789744, -7.87545095],
                            [39.36785906, -7.87555843],
                            [39.36773625, -7.87558913],
                            [39.36765951, -7.87566591],
                            [39.3675674, -7.87575035],
                            [39.367506, -7.8758348],
                            [39.36741389, -7.87590389],
                            [39.3673525, -7.87599602],
                            [39.36737552, -7.87608046],
                            [39.36745995, -7.87614955],
                            [39.36746762, -7.87621864],
                            [39.36744459, -7.87630309],
                            [39.36739087, -7.87637218],
                            [39.36743692, -7.87646431],
                            [39.36754437, -7.87649502],
                            [39.3675674, -7.87660249],
                            [39.36752902, -7.87669462],
                            [39.36749065, -7.87676371],
                            [39.36752135, -7.87680209],
                            [39.36758275, -7.87687118],
                            [39.36768253, -7.87690189],
                            [39.36773625, -7.87697867],
                            [39.36772858, -7.87704777],
                            [39.36771323, -7.87715524],
                            [39.36778231, -7.877232],
                            [39.36782068, -7.87731646],
                            [39.36782835, -7.87740858],
                            [39.3677516, -7.87748535],
                            [39.3677516, -7.87757747],
                            [39.36778231, -7.87771565],
                            [39.36785138, -7.87780778],
                            [39.36792813, -7.87789991],
                            [39.36805861, -7.87801506],
                            [39.36812768, -7.87809951],
                            [39.36831189, -7.87832213],
                            [39.368404, -7.87833749],
                            [39.36848074, -7.87829911],
                            [39.36858052, -7.87823769],
                            [39.36866494, -7.87823002],
                            [39.36872634, -7.8782684],
                            [39.36878775, -7.87836051],
                            [39.36887218, -7.87841426],
                            [39.36897196, -7.87839891],
                            [39.36899497, -7.87833749],
                            [39.36911011, -7.87833749],
                            [39.36919454, -7.87837588],
                            [39.36926362, -7.87841426],
                            [39.36932501, -7.87841426],
                            [39.36938641, -7.87841426],
                            [39.3694248, -7.87835284],
                            [39.36946317, -7.87828375],
                            [39.36952458, -7.87823002],
                            [39.36961667, -7.87823002],
                            [39.36974715, -7.87823002],
                            [39.36987763, -7.87823003],
                            [39.3699467, -7.87814557],
                            [39.36988531, -7.87806112],
                        ],
                        [
                            [39.38852661, -7.88448359],
                            [39.38836198, -7.88459338],
                            [39.38817905, -7.88459338],
                            [39.38803271, -7.88455678],
                            [39.38794123, -7.88453848],
                            [39.38781319, -7.88470315],
                            [39.38770342, -7.88484953],
                            [39.38770342, -7.88505081],
                            [39.38764855, -7.88521548],
                            [39.38759366, -7.88539845],
                            [39.38759366, -7.88559972],
                            [39.38761195, -7.88578269],
                            [39.38764855, -7.88602055],
                            [39.38774001, -7.88629502],
                            [39.38790464, -7.88644139],
                            [39.38806928, -7.88666097],
                            [39.3882888, -7.88682564],
                            [39.3885266, -7.88700861],
                            [39.38878272, -7.88717328],
                            [39.38903881, -7.88724647],
                            [39.38929491, -7.88726477],
                            [39.38967907, -7.88733796],
                            [39.3901364, -7.88737456],
                            [39.39039249, -7.88737456],
                            [39.39061202, -7.88739286],
                            [39.39081324, -7.88748435],
                            [39.3909413, -7.88763072],
                            [39.39110593, -7.88786859],
                            [39.39125227, -7.88805157],
                            [39.39139862, -7.88806987],
                            [39.39165472, -7.88808815],
                            [39.39183765, -7.88808815],
                            [39.39187425, -7.88797838],
                            [39.39176448, -7.887832],
                            [39.39163643, -7.88766732],
                            [39.39149009, -7.88746605],
                            [39.39149009, -7.88726478],
                            [39.39156326, -7.88713671],
                            [39.39178278, -7.88706351],
                            [39.39203888, -7.88708181],
                            [39.39209376, -7.88702693],
                            [39.39202059, -7.88686224],
                            [39.39187425, -7.88675247],
                            [39.39187425, -7.8865695],
                            [39.39192913, -7.88633162],
                            [39.39178279, -7.88618525],
                            [39.39156327, -7.88596568],
                            [39.39139863, -7.88580101],
                            [39.39132546, -7.88565462],
                            [39.39127058, -7.88552655],
                            [39.39106935, -7.88527038],
                            [39.39095959, -7.88503252],
                            [39.39095959, -7.88477636],
                            [39.39097789, -7.88459339],
                            [39.39099619, -7.884447],
                            [39.39105106, -7.88428233],
                            [39.39116082, -7.88402618],
                            [39.39121571, -7.8838249],
                            [39.39121571, -7.88362363],
                            [39.39114254, -7.88355045],
                            [39.39097789, -7.88355045],
                            [39.39086813, -7.88355045],
                            [39.39075839, -7.88356873],
                            [39.39057546, -7.88360533],
                            [39.3904474, -7.88371512],
                            [39.39039251, -7.8838432],
                            [39.39024617, -7.88397127],
                            [39.39011811, -7.88409936],
                            [39.39006324, -7.88419084],
                            [39.3899169, -7.88433721],
                            [39.38980714, -7.88435551],
                            [39.38964249, -7.88437381],
                            [39.38947786, -7.88437381],
                            [39.38934981, -7.88437381],
                            [39.38914859, -7.88439211],
                            [39.38887419, -7.88439211],
                            [39.38852661, -7.88448359],
                        ],
                        [
                            [39.37656227, -7.87333282],
                            [39.37655413, -7.87324324],
                            [39.37655413, -7.87312923],
                            [39.37652156, -7.87307222],
                            [39.37648086, -7.87299892],
                            [39.37640758, -7.87291747],
                            [39.37635872, -7.87283603],
                            [39.37635058, -7.87273831],
                            [39.37628544, -7.87263243],
                            [39.37618773, -7.87261615],
                            [39.37609817, -7.87265687],
                            [39.37601675, -7.87266502],
                            [39.37592719, -7.87266502],
                            [39.37582134, -7.87264873],
                            [39.3756992, -7.87264059],
                            [39.37556078, -7.87264059],
                            [39.37547123, -7.8726813],
                            [39.37538981, -7.87275459],
                            [39.37533281, -7.87282789],
                            [39.37526767, -7.87290118],
                            [39.37517811, -7.87296633],
                            [39.37512111, -7.87306407],
                            [39.37509669, -7.87316179],
                            [39.37508855, -7.87330024],
                            [39.37512111, -7.8734224],
                            [39.37520253, -7.87356085],
                            [39.37534909, -7.873683],
                            [39.37546308, -7.87374815],
                            [39.3755445, -7.87387032],
                            [39.37569919, -7.87407392],
                            [39.37582947, -7.87408206],
                            [39.37594347, -7.87415536],
                            [39.37609816, -7.87428566],
                            [39.37624472, -7.87443225],
                            [39.37636685, -7.87460328],
                            [39.37636685, -7.87472543],
                            [39.37646455, -7.8748476],
                            [39.37652969, -7.87496976],
                            [39.3766844, -7.87509192],
                            [39.37674138, -7.87516522],
                            [39.3768391, -7.87517336],
                            [39.37695308, -7.87517336],
                            [39.37705079, -7.87512449],
                            [39.37711593, -7.8750512],
                            [39.37714035, -7.87498605],
                            [39.37718921, -7.87491275],
                            [39.37726249, -7.87488833],
                            [39.37730319, -7.87485574],
                            [39.37739277, -7.87477431],
                            [39.37739277, -7.87470101],
                            [39.37737649, -7.87462772],
                            [39.37733577, -7.87456256],
                            [39.37733577, -7.87446484],
                            [39.37736833, -7.87439155],
                            [39.37740091, -7.87432639],
                            [39.37740905, -7.87426124],
                            [39.37740091, -7.8741635],
                            [39.37731135, -7.87401691],
                            [39.37716478, -7.87398434],
                            [39.3771078, -7.87390291],
                            [39.37704266, -7.87378889],
                            [39.3769938, -7.87374002],
                            [39.37692866, -7.87367486],
                            [39.3768391, -7.87360971],
                            [39.37675769, -7.87353642],
                            [39.37671697, -7.87346312],
                            [39.37662741, -7.87338168],
                            [39.37656227, -7.87333282],
                        ],
                        [
                            [39.32450863, -7.87025332],
                            [39.32450863, -7.86994595],
                            [39.32418427, -7.86957029],
                            [39.32401354, -7.8695532],
                            [39.32384283, -7.8695532],
                            [39.32368918, -7.8695532],
                            [39.32351847, -7.86962151],
                            [39.32334775, -7.8697752],
                            [39.32317703, -7.86998009],
                            [39.32302339, -7.870185],
                            [39.32286974, -7.87033869],
                            [39.32275023, -7.87049236],
                            [39.32256244, -7.87052652],
                            [39.32230637, -7.87054358],
                            [39.32211858, -7.87054358],
                            [39.32191371, -7.87049235],
                            [39.32169179, -7.87044114],
                            [39.321504, -7.87044114],
                            [39.32126499, -7.87044114],
                            [39.32111134, -7.87044114],
                            [39.32083819, -7.87050943],
                            [39.32054797, -7.87064603],
                            [39.32034312, -7.87091924],
                            [39.3202919, -7.87117538],
                            [39.32027482, -7.87143151],
                            [39.3202919, -7.87170473],
                            [39.32030896, -7.87192671],
                            [39.32034311, -7.87206331],
                            [39.32051383, -7.87225114],
                            [39.32076991, -7.87237067],
                            [39.32102598, -7.87231944],
                            [39.32128206, -7.87226823],
                            [39.32158935, -7.87216577],
                            [39.32189665, -7.87214869],
                            [39.3221015, -7.87214869],
                            [39.32228929, -7.87226823],
                            [39.32246001, -7.87243898],
                            [39.32263072, -7.87266096],
                            [39.32271608, -7.87301955],
                            [39.32281851, -7.87330983],
                            [39.32298922, -7.87358304],
                            [39.32326237, -7.87378796],
                            [39.32348431, -7.87380504],
                            [39.32382573, -7.87380504],
                            [39.32420131, -7.87373673],
                            [39.32442326, -7.87356598],
                            [39.32455983, -7.87327569],
                            [39.32466227, -7.87300249],
                            [39.32466227, -7.87267805],
                            [39.32461105, -7.87237068],
                            [39.32459398, -7.87182427],
                            [39.32455984, -7.87139738],
                            [39.32452569, -7.87102171],
                            [39.32450863, -7.87066313],
                            [39.32450863, -7.87025332],
                        ],
                        [
                            [39.38025644, -7.86911786],
                            [39.38034817, -7.86911786],
                            [39.38045661, -7.86914288],
                            [39.38058171, -7.86910952],
                            [39.38062341, -7.86901775],
                            [39.38055668, -7.86895102],
                            [39.38048996, -7.86890096],
                            [39.38047328, -7.86880919],
                            [39.38053167, -7.8687091],
                            [39.38059838, -7.86865904],
                            [39.38060673, -7.86857562],
                            [39.38053167, -7.86850889],
                            [39.38044827, -7.86842547],
                            [39.38037321, -7.86832535],
                            [39.38037321, -7.86823359],
                            [39.38044827, -7.86815017],
                            [39.38044827, -7.86805841],
                            [39.38048163, -7.86791659],
                            [39.38058172, -7.8678999],
                            [39.38071516, -7.86794161],
                            [39.38083192, -7.86794161],
                            [39.38088197, -7.86788323],
                            [39.38088197, -7.86778311],
                            [39.38081524, -7.86772473],
                            [39.38074852, -7.86766633],
                            [39.38068179, -7.8675996],
                            [39.38064843, -7.8675245],
                            [39.38066512, -7.86742441],
                            [39.38069848, -7.86734933],
                            [39.38068179, -7.86726591],
                            [39.38062342, -7.86720751],
                            [39.38060673, -7.86720751],
                            [39.38053167, -7.86727424],
                            [39.38044827, -7.867366],
                            [39.38038988, -7.86742441],
                            [39.38029815, -7.86743275],
                            [39.38022308, -7.86737435],
                            [39.380123, -7.86734933],
                            [39.38003125, -7.867366],
                            [39.37996454, -7.86746611],
                            [39.37986445, -7.86757456],
                            [39.37973934, -7.86761627],
                            [39.37961424, -7.86762461],
                            [39.37949748, -7.86773305],
                            [39.37936403, -7.86784985],
                            [39.37910548, -7.86805006],
                            [39.37898871, -7.86815016],
                            [39.37883025, -7.86825862],
                            [39.3787385, -7.86836706],
                            [39.37863843, -7.86848384],
                            [39.37855503, -7.8685923],
                            [39.37855503, -7.86870909],
                            [39.37855503, -7.8688509],
                            [39.37862174, -7.86903443],
                            [39.37874684, -7.86920962],
                            [39.37885526, -7.86921797],
                            [39.37893866, -7.86929304],
                            [39.37901372, -7.86940149],
                            [39.37915552, -7.86945989],
                            [39.37928062, -7.8695016],
                            [39.37947244, -7.86946823],
                            [39.37955584, -7.86937647],
                            [39.37961423, -7.86929305],
                            [39.37972265, -7.86921797],
                            [39.3798561, -7.86921797],
                            [39.3799812, -7.86925134],
                            [39.38005626, -7.86925134],
                            [39.38018971, -7.86921797],
                            [39.38025644, -7.86911786],
                        ],
                        [
                            [39.38243325, -7.86855894],
                            [39.38248329, -7.86867574],
                            [39.38258338, -7.86875916],
                            [39.38271681, -7.8688843],
                            [39.38289196, -7.86899274],
                            [39.38302541, -7.86894268],
                            [39.38314217, -7.86894268],
                            [39.38323392, -7.86899274],
                            [39.38333401, -7.86909285],
                            [39.38345077, -7.86908451],
                            [39.38354252, -7.86900943],
                            [39.38354252, -7.86892601],
                            [39.38349247, -7.86880921],
                            [39.38352583, -7.86869242],
                            [39.38365093, -7.86872579],
                            [39.38381774, -7.86879253],
                            [39.38387613, -7.86870077],
                            [39.38387613, -7.86858398],
                            [39.38387613, -7.86849221],
                            [39.38396786, -7.86841714],
                            [39.38408464, -7.86842548],
                            [39.38413467, -7.86837543],
                            [39.38413467, -7.868292],
                            [39.38413467, -7.86820024],
                            [39.38418471, -7.86810847],
                            [39.38430147, -7.86810847],
                            [39.38433485, -7.86815853],
                            [39.38443492, -7.86815853],
                            [39.38447662, -7.86808345],
                            [39.38454335, -7.86800837],
                            [39.38462676, -7.86789992],
                            [39.38471849, -7.86792495],
                            [39.38476853, -7.86804174],
                            [39.38482692, -7.86812516],
                            [39.38490198, -7.86811682],
                            [39.38500206, -7.86805843],
                            [39.38508546, -7.86799169],
                            [39.3851772, -7.86800003],
                            [39.38527729, -7.86804174],
                            [39.38537737, -7.86810015],
                            [39.38551082, -7.86816688],
                            [39.38561925, -7.8680918],
                            [39.38566095, -7.86802505],
                            [39.38575268, -7.86804175],
                            [39.38582776, -7.86811682],
                            [39.38590282, -7.86815853],
                            [39.38600289, -7.86810015],
                            [39.38601958, -7.86802506],
                            [39.38601958, -7.86789992],
                            [39.386128, -7.8678749],
                            [39.38619473, -7.8678749],
                            [39.38626144, -7.86785821],
                            [39.38631149, -7.86779148],
                            [39.38634485, -7.86771641],
                            [39.38635319, -7.86762464],
                            [39.38642825, -7.86750785],
                            [39.3865617, -7.86744945],
                            [39.3865617, -7.86734935],
                            [39.38654501, -7.86723255],
                            [39.38644494, -7.86712411],
                            [39.38636988, -7.8670824],
                            [39.38629481, -7.867024],
                            [39.38622809, -7.86694892],
                            [39.38623643, -7.86687384],
                            [39.3861697, -7.86672369],
                            [39.38609464, -7.86664027],
                            [39.38602793, -7.86657352],
                            [39.38597788, -7.86647342],
                            [39.3859612, -7.86634829],
                            [39.38594453, -7.8662732],
                            [39.38588614, -7.86621481],
                            [39.38581108, -7.86615641],
                            [39.38571099, -7.86608133],
                            [39.38567763, -7.86603128],
                            [39.38561092, -7.86590614],
                            [39.38546912, -7.86587278],
                            [39.38531066, -7.86587278],
                            [39.3851939, -7.8657977],
                            [39.3851939, -7.86567257],
                            [39.38521057, -7.86558915],
                            [39.38514386, -7.86549738],
                            [39.38501041, -7.86549738],
                            [39.38493535, -7.86541396],
                            [39.38493535, -7.86533888],
                            [39.38493535, -7.86525546],
                            [39.38491866, -7.86515535],
                            [39.38485195, -7.86511363],
                            [39.3847602, -7.86512198],
                            [39.3846768, -7.86519706],
                            [39.38462677, -7.86517204],
                            [39.38462677, -7.8651053],
                            [39.38458507, -7.86502188],
                            [39.3845517, -7.86490508],
                            [39.38445162, -7.86484669],
                            [39.38431817, -7.86492177],
                            [39.38419307, -7.86506359],
                            [39.38408465, -7.86518872],
                            [39.38393452, -7.86527214],
                            [39.38381775, -7.8654223],
                            [39.38374269, -7.8655641],
                            [39.38370934, -7.86572262],
                            [39.38371766, -7.86590614],
                            [39.38370933, -7.86609802],
                            [39.38365929, -7.86627319],
                            [39.38360924, -7.86638999],
                            [39.38352584, -7.86646507],
                            [39.38342575, -7.8665318],
                            [39.38330064, -7.86664859],
                            [39.38322558, -7.86670699],
                            [39.38313384, -7.86679041],
                            [39.38305043, -7.86685714],
                            [39.38293367, -7.86689885],
                            [39.38280857, -7.86689885],
                            [39.38272517, -7.86689885],
                            [39.38259172, -7.86689885],
                            [39.38251666, -7.86691554],
                            [39.38245829, -7.86698227],
                            [39.38239155, -7.86705736],
                            [39.38233318, -7.86715746],
                            [39.38227479, -7.86725757],
                            [39.38226645, -7.86734934],
                            [39.38223309, -7.86747447],
                            [39.38226645, -7.86758291],
                            [39.38226645, -7.86769135],
                            [39.38226645, -7.86777477],
                            [39.3822581, -7.86787489],
                            [39.38224143, -7.86798333],
                            [39.38224143, -7.86810846],
                            [39.38227479, -7.86822526],
                            [39.38234985, -7.86835873],
                            [39.38241658, -7.86846719],
                            [39.38243325, -7.86855894],
                        ],
                        [
                            [39.40727333, -7.86546114],
                            [39.40742721, -7.86553218],
                            [39.40756924, -7.86560321],
                            [39.40764026, -7.86568609],
                            [39.4077823, -7.86573344],
                            [39.4079125, -7.86576896],
                            [39.40807821, -7.86578081],
                            [39.40812557, -7.86565057],
                            [39.40812557, -7.86549666],
                            [39.40798353, -7.86529539],
                            [39.40787699, -7.86518884],
                            [39.40775864, -7.86507044],
                            [39.40762842, -7.86495204],
                            [39.40751007, -7.86482182],
                            [39.40740353, -7.86471527],
                            [39.40732067, -7.86463239],
                            [39.40728517, -7.86451399],
                            [39.40720232, -7.86437192],
                            [39.40713129, -7.86426537],
                            [39.40706028, -7.86414697],
                            [39.40696558, -7.86402857],
                            [39.40689457, -7.86393387],
                            [39.40683538, -7.86382732],
                            [39.40670517, -7.8637326],
                            [39.40658681, -7.86360237],
                            [39.40649211, -7.86351949],
                            [39.40637374, -7.86344845],
                            [39.40627906, -7.8634366],
                            [39.40613702, -7.8634366],
                            [39.40597131, -7.8634366],
                            [39.40579375, -7.86344844],
                            [39.4057109, -7.8634958],
                            [39.40559252, -7.86357867],
                            [39.40545048, -7.86368524],
                            [39.40533211, -7.86372074],
                            [39.40521376, -7.86379179],
                            [39.40507172, -7.86382731],
                            [39.40497702, -7.86386282],
                            [39.40491783, -7.86393386],
                            [39.40488232, -7.86404041],
                            [39.40492967, -7.86412329],
                            [39.40502436, -7.86412329],
                            [39.40515457, -7.86408776],
                            [39.40528477, -7.86408776],
                            [39.40536763, -7.86413513],
                            [39.40540314, -7.86422984],
                            [39.4053913, -7.86438376],
                            [39.40539129, -7.86453766],
                            [39.40550966, -7.86483365],
                            [39.40563986, -7.86497573],
                            [39.40586476, -7.86499941],
                            [39.40603047, -7.8650586],
                            [39.40616068, -7.86510596],
                            [39.40635007, -7.86516516],
                            [39.40655129, -7.86521251],
                            [39.40672883, -7.86523619],
                            [39.40693006, -7.86529539],
                            [39.40713128, -7.86534274],
                            [39.40727333, -7.86546114],
                        ],
                        [
                            [39.23536421, -7.86240272],
                            [39.23540899, -7.86250723],
                            [39.23537915, -7.86260426],
                            [39.23528959, -7.86270876],
                            [39.23522989, -7.86284312],
                            [39.23522989, -7.86297747],
                            [39.23537915, -7.86313422],
                            [39.23567763, -7.86334321],
                            [39.23587166, -7.86338053],
                            [39.23601345, -7.86344772],
                            [39.23617763, -7.86343279],
                            [39.23622241, -7.86329844],
                            [39.23622241, -7.86312675],
                            [39.23614777, -7.86296255],
                            [39.2361254, -7.8628058],
                            [39.23611793, -7.86269383],
                            [39.23620747, -7.86264905],
                            [39.23627464, -7.86261173],
                            [39.23625972, -7.86235048],
                            [39.23617017, -7.86212655],
                            [39.23602837, -7.86193995],
                            [39.23590899, -7.86188771],
                            [39.23567764, -7.86181305],
                            [39.23549855, -7.86181305],
                            [39.23536421, -7.86182052],
                            [39.2352672, -7.86193248],
                            [39.23525975, -7.86205938],
                            [39.23531198, -7.86221612],
                            [39.23536421, -7.86240272],
                        ],
                        [
                            [39.35117626, -7.85817459],
                            [39.35118897, -7.85802861],
                            [39.35119531, -7.85788898],
                            [39.35122069, -7.8577557],
                            [39.35122069, -7.85766684],
                            [39.35122705, -7.85752086],
                            [39.35126511, -7.85740662],
                            [39.35126511, -7.85727333],
                            [39.35127145, -7.85711466],
                            [39.35128416, -7.85694963],
                            [39.35128416, -7.85682905],
                            [39.35128416, -7.8567021],
                            [39.35128416, -7.85659421],
                            [39.35129684, -7.85648631],
                            [39.35130953, -7.85636571],
                            [39.35130953, -7.85621339],
                            [39.35129685, -7.85608011],
                            [39.35129685, -7.85596586],
                            [39.35133972, -7.85564711],
                            [39.35151246, -7.85564711],
                            [39.35151246, -7.85564283],
                            [39.35151247, -7.85540705],
                            [39.35149116, -7.85505197],
                            [39.35143966, -7.85484593],
                            [39.35141391, -7.85451971],
                            [39.35138816, -7.85419348],
                            [39.35138816, -7.85395311],
                            [39.35137099, -7.85369558],
                            [39.351371, -7.85340369],
                            [39.35137959, -7.85322341],
                            [39.35137959, -7.85296586],
                            [39.35136242, -7.85272549],
                            [39.35136242, -7.85250229],
                            [39.35135384, -7.8523306],
                            [39.35136242, -7.85219323],
                            [39.35135385, -7.85207306],
                            [39.351371, -7.85184984],
                            [39.35135385, -7.85164382],
                            [39.3513154, -7.85150475],
                            [39.3513099, -7.85135639],
                            [39.3513099, -7.851263],
                            [39.3513099, -7.85114212],
                            [39.35130441, -7.85101026],
                            [39.35129342, -7.85095532],
                            [39.35124398, -7.85091686],
                            [39.35118356, -7.85091686],
                            [39.35116708, -7.8509663],
                            [39.35112864, -7.85102125],
                            [39.35109019, -7.8510707],
                            [39.35103525, -7.85109267],
                            [39.35096933, -7.85109267],
                            [39.35088145, -7.85108168],
                            [39.35078806, -7.85108168],
                            [39.35067272, -7.85105971],
                            [39.35061229, -7.85103773],
                            [39.35054088, -7.85099377],
                            [39.35048046, -7.85093883],
                            [39.35043651, -7.85090587],
                            [39.35036511, -7.85089488],
                            [39.35031567, -7.85089488],
                            [39.35027172, -7.85093334],
                            [39.35027172, -7.85100476],
                            [39.35023327, -7.85111465],
                            [39.3502058, -7.85124651],
                            [39.35020032, -7.85133991],
                            [39.35017285, -7.85144429],
                            [39.35016186, -7.85154869],
                            [39.35015088, -7.85162561],
                            [39.35011791, -7.8517245],
                            [39.35011243, -7.85185637],
                            [39.35010144, -7.85198274],
                            [39.35007397, -7.85207614],
                            [39.35005201, -7.85218602],
                            [39.35003002, -7.85229042],
                            [39.35000255, -7.8523948],
                            [39.3499641, -7.85246623],
                            [39.34993115, -7.85256512],
                            [39.34992017, -7.85265303],
                            [39.3498927, -7.85274094],
                            [39.34986523, -7.85282336],
                            [39.34982678, -7.85290028],
                            [39.34978284, -7.85297719],
                            [39.34973889, -7.85303213],
                            [39.34970594, -7.85311455],
                            [39.34967847, -7.85320795],
                            [39.34964552, -7.85327388],
                            [39.34959608, -7.8533508],
                            [39.34951918, -7.85338926],
                            [39.34944227, -7.85339475],
                            [39.34937086, -7.85337277],
                            [39.34932692, -7.8533453],
                            [39.34928847, -7.85328487],
                            [39.3492665, -7.85321345],
                            [39.34922805, -7.85314201],
                            [39.34922255, -7.8530596],
                            [39.34921705, -7.85297718],
                            [39.34921157, -7.85289478],
                            [39.34921157, -7.85280687],
                            [39.34921705, -7.85263654],
                            [39.34921705, -7.85246623],
                            [39.34921157, -7.85232337],
                            [39.34921705, -7.85215855],
                            [39.34921706, -7.85200471],
                            [39.34922256, -7.85186187],
                            [39.34922256, -7.85174098],
                            [39.34920607, -7.85166406],
                            [39.34918411, -7.85160912],
                            [39.34917862, -7.85155967],
                            [39.34913467, -7.85148825],
                            [39.34908524, -7.85140584],
                            [39.3490303, -7.85132892],
                            [39.34896988, -7.85126299],
                            [39.34893143, -7.85120256],
                            [39.34887649, -7.85114211],
                            [39.34881608, -7.85107069],
                            [39.34876664, -7.85101025],
                            [39.34870622, -7.85094982],
                            [39.34865679, -7.85089487],
                            [39.34862382, -7.85083992],
                            [39.34859087, -7.85077949],
                            [39.3485634, -7.85071906],
                            [39.34854692, -7.85064762],
                            [39.34853045, -7.8505707],
                            [39.34852495, -7.85049928],
                            [39.34852495, -7.85041138],
                            [39.34851397, -7.85033445],
                            [39.34850847, -7.85025753],
                            [39.34850781, -7.8502457],
                            [39.34850109, -7.85024533],
                            [39.34844416, -7.85015115],
                            [39.34843421, -7.85005165],
                            [39.34841432, -7.84994219],
                            [39.34838446, -7.84984269],
                            [39.34838446, -7.84975313],
                            [39.34838446, -7.84965362],
                            [39.34838696, -7.84954665],
                            [39.34838696, -7.84944963],
                            [39.34838696, -7.84936009],
                            [39.34838696, -7.84924814],
                            [39.34838696, -7.84918097],
                            [39.34838696, -7.84910635],
                            [39.3483571, -7.84903171],
                            [39.34830489, -7.84898694],
                            [39.34826758, -7.84891977],
                            [39.34821534, -7.84883768],
                            [39.34817058, -7.84877798],
                            [39.34811835, -7.84872573],
                            [39.34805866, -7.84869588],
                            [39.34798406, -7.84868841],
                            [39.34787959, -7.84868841],
                            [39.34780499, -7.84869588],
                            [39.34772291, -7.84869588],
                            [39.34764829, -7.84870335],
                            [39.34755876, -7.84868841],
                            [39.34749908, -7.84865111],
                            [39.34743192, -7.84859139],
                            [39.34736477, -7.84852424],
                            [39.34731254, -7.84846452],
                            [39.34724538, -7.84840482],
                            [39.34712601, -7.84837498],
                            [39.34704394, -7.84834513],
                            [39.34694694, -7.84834513],
                            [39.34683503, -7.84833765],
                            [39.34673803, -7.84832273],
                            [39.34666341, -7.84828541],
                            [39.34657388, -7.84823318],
                            [39.34652165, -7.84817347],
                            [39.34643212, -7.84811377],
                            [39.34637242, -7.84805407],
                            [39.34629781, -7.84799435],
                            [39.34625305, -7.8479645],
                            [39.34617843, -7.84791228],
                            [39.34612621, -7.84791228],
                            [39.34606651, -7.84793465],
                            [39.34597698, -7.84799435],
                            [39.34594714, -7.84803914],
                            [39.34593222, -7.84809137],
                            [39.3459173, -7.84815854],
                            [39.34593222, -7.84824063],
                            [39.34593967, -7.84830035],
                            [39.34592475, -7.84835258],
                            [39.34588745, -7.84838989],
                            [39.34585014, -7.84843467],
                            [39.3458203, -7.84852423],
                            [39.34581283, -7.84859885],
                            [39.34579046, -7.84865855],
                            [39.34573822, -7.84872572],
                            [39.3457233, -7.84880782],
                            [39.34573075, -7.84887498],
                            [39.34573075, -7.84895894],
                            [39.34573075, -7.8490373],
                            [39.34571957, -7.84913805],
                            [39.34571397, -7.84921641],
                            [39.34569718, -7.84928917],
                            [39.3456636, -7.84936193],
                            [39.34561883, -7.8494347],
                            [39.34557966, -7.84949627],
                            [39.34554049, -7.84953545],
                            [39.34550132, -7.84956344],
                            [39.34545656, -7.84959143],
                            [39.34540619, -7.8496362],
                            [39.34535023, -7.84967538],
                            [39.34524391, -7.84974255],
                            [39.34518794, -7.84979292],
                            [39.34513759, -7.84986568],
                            [39.34509842, -7.84992726],
                            [39.34504806, -7.84997762],
                            [39.34503126, -7.8500336],
                            [39.3449697, -7.85010637],
                            [39.34494733, -7.85017352],
                            [39.34490816, -7.85024069],
                            [39.34488577, -7.85029667],
                            [39.34487458, -7.85038062],
                            [39.34485778, -7.85045338],
                            [39.34485778, -7.85052055],
                            [39.34485778, -7.85059891],
                            [39.34485778, -7.85064928],
                            [39.34486898, -7.85076078],
                            [39.34417306, -7.85076078],
                            [39.34417485, -7.85076555],
                            [39.34419038, -7.85081606],
                            [39.34419426, -7.85085102],
                            [39.34419815, -7.85089374],
                            [39.34420981, -7.850952],
                            [39.34422144, -7.85099473],
                            [39.34422144, -7.85104135],
                            [39.34424476, -7.85108795],
                            [39.34426416, -7.85113069],
                            [39.34428747, -7.85116953],
                            [39.344303, -7.85121615],
                            [39.34433795, -7.85127052],
                            [39.34436902, -7.85132103],
                            [39.34440786, -7.8513754],
                            [39.34444281, -7.85142591],
                            [39.34447776, -7.85146863],
                            [39.34450882, -7.85150359],
                            [39.34454377, -7.851523],
                            [39.34459038, -7.85153466],
                            [39.34463697, -7.85153855],
                            [39.34468358, -7.85153855],
                            [39.34473019, -7.85153079],
                            [39.34478067, -7.85151524],
                            [39.34481173, -7.8514764],
                            [39.34486223, -7.85144532],
                            [39.34490495, -7.85140648],
                            [39.34493213, -7.8513754],
                            [39.34497485, -7.85136376],
                            [39.34501757, -7.85136376],
                            [39.34506029, -7.85137152],
                            [39.34509911, -7.85139095],
                            [39.34513795, -7.85142978],
                            [39.34518456, -7.85147251],
                            [39.34522728, -7.85150747],
                            [39.34528163, -7.85156186],
                            [39.34532048, -7.85160071],
                            [39.34535543, -7.85164343],
                            [39.34540204, -7.85169393],
                            [39.34545252, -7.85174053],
                            [39.345503, -7.85180268],
                            [39.34555737, -7.8518493],
                            [39.34561951, -7.8518998],
                            [39.3456814, -7.85195442],
                            [39.34573601, -7.85198477],
                            [39.34580275, -7.85200905],
                            [39.3458695, -7.85199691],
                            [39.34593625, -7.85200297],
                            [39.3460212, -7.85203332],
                            [39.34610008, -7.85208188],
                            [39.34622752, -7.85211829],
                            [39.34631853, -7.85216685],
                            [39.34643382, -7.85223969],
                            [39.34652485, -7.85228217],
                            [39.34660372, -7.85233072],
                            [39.34665834, -7.85237322],
                            [39.34670081, -7.85242784],
                            [39.34673115, -7.8524946],
                            [39.34674329, -7.85260385],
                            [39.34674329, -7.85273738],
                            [39.34674935, -7.85288911],
                            [39.34674329, -7.85304084],
                            [39.34671901, -7.85319257],
                            [39.34670688, -7.85327754],
                            [39.34669474, -7.85335038],
                            [39.34668868, -7.85347176],
                            [39.3466644, -7.85357494],
                            [39.3466644, -7.85366599],
                            [39.34665834, -7.85377523],
                            [39.34662799, -7.85385413],
                            [39.34658551, -7.85393304],
                            [39.34655518, -7.8539998],
                            [39.34651876, -7.85407263],
                            [39.34647022, -7.85413939],
                            [39.34640347, -7.85415153],
                            [39.34633672, -7.85415759],
                            [39.34627605, -7.85414545],
                            [39.34623357, -7.85410904],
                            [39.34617896, -7.85404227],
                            [39.34612436, -7.85398765],
                            [39.34605153, -7.85390875],
                            [39.34598479, -7.85381164],
                            [39.34594838, -7.8537206],
                            [39.34589377, -7.85362349],
                            [39.34584523, -7.85353246],
                            [39.34580882, -7.85346568],
                            [39.3457724, -7.85338071],
                            [39.345736, -7.85333824],
                            [39.34567533, -7.85325933],
                            [39.34560858, -7.85318042],
                            [39.34554184, -7.85313795],
                            [39.34546295, -7.85308331],
                            [39.34540226, -7.85305298],
                            [39.34532945, -7.85305298],
                            [39.34525663, -7.85308331],
                            [39.34518382, -7.85313795],
                            [39.34514741, -7.85321685],
                            [39.34514741, -7.85330182],
                            [39.34514741, -7.85336858],
                            [39.34513527, -7.85344141],
                            [39.34513527, -7.85353246],
                            [39.34513527, -7.85362349],
                            [39.34514133, -7.85375095],
                            [39.34514741, -7.85384198],
                            [39.34515954, -7.85395124],
                            [39.34517168, -7.85404227],
                            [39.34519596, -7.85412118],
                            [39.3452263, -7.85421221],
                            [39.3452445, -7.85430932],
                            [39.34528697, -7.85438823],
                            [39.34532945, -7.85443678],
                            [39.34536585, -7.8544914],
                            [39.3454144, -7.85455816],
                            [39.34545687, -7.85463101],
                            [39.34549329, -7.8547099],
                            [39.34551755, -7.8547888],
                            [39.34552969, -7.8548495],
                            [39.34554183, -7.85491018],
                            [39.34557216, -7.85498909],
                            [39.34560858, -7.85506193],
                            [39.3456389, -7.85512869],
                            [39.34566318, -7.85520758],
                            [39.34567532, -7.85529257],
                            [39.34568138, -7.85537146],
                            [39.34569352, -7.85546857],
                            [39.34570565, -7.85555354],
                            [39.34575419, -7.85562638],
                            [39.34578454, -7.85570527],
                            [39.34580882, -7.85577203],
                            [39.34586948, -7.85582667],
                            [39.34593017, -7.85589949],
                            [39.34601512, -7.85600267],
                            [39.34609401, -7.85612407],
                            [39.34615467, -7.85622724],
                            [39.3462093, -7.85631221],
                            [39.34625784, -7.85643359],
                            [39.34628817, -7.85654285],
                            [39.34634279, -7.85668244],
                            [39.34636705, -7.85681596],
                            [39.34640346, -7.85692522],
                            [39.34644594, -7.85702839],
                            [39.34646147, -7.85706463],
                            [39.34650388, -7.85704646],
                            [39.34653136, -7.85714637],
                            [39.34657578, -7.85726697],
                            [39.34660751, -7.85733678],
                            [39.34661385, -7.85742564],
                            [39.34664557, -7.85752719],
                            [39.34670269, -7.85761605],
                            [39.34677883, -7.85769855],
                            [39.34681691, -7.85778741],
                            [39.34686767, -7.85787627],
                            [39.3468994, -7.85799051],
                            [39.34695017, -7.85814284],
                            [39.34701996, -7.8582317],
                            [39.34708341, -7.8583269],
                            [39.34717225, -7.85841576],
                            [39.34725475, -7.85853635],
                            [39.34736896, -7.85859983],
                            [39.3474578, -7.85865695],
                            [39.34755933, -7.85872676],
                            [39.34767988, -7.85879024],
                            [39.34776238, -7.85889814],
                            [39.34782583, -7.85901872],
                            [39.34789564, -7.85910758],
                            [39.34792736, -7.85920913],
                            [39.34795275, -7.85930434],
                            [39.34799717, -7.85943763],
                            [39.3480162, -7.85955187],
                            [39.34804159, -7.85967881],
                            [39.34807966, -7.8597994],
                            [39.34811772, -7.85991364],
                            [39.34814945, -7.86004693],
                            [39.34817484, -7.86015482],
                            [39.34824464, -7.86025003],
                            [39.34830809, -7.86036429],
                            [39.34837155, -7.86047218],
                            [39.34842232, -7.86054199],
                            [39.34851748, -7.86062451],
                            [39.34860632, -7.86064989],
                            [39.34868882, -7.86066259],
                            [39.34879035, -7.86066259],
                            [39.34887919, -7.86065625],
                            [39.34895533, -7.86064989],
                            [39.34905051, -7.86064989],
                            [39.34917743, -7.86065625],
                            [39.34924088, -7.86068163],
                            [39.34927895, -7.86075144],
                            [39.34927895, -7.86083396],
                            [39.34923453, -7.86091013],
                            [39.34919011, -7.86097994],
                            [39.349133, -7.8610688],
                            [39.34908858, -7.861164],
                            [39.34903148, -7.86124652],
                            [39.34901879, -7.86132902],
                            [39.34900609, -7.86142422],
                            [39.34898071, -7.86152577],
                            [39.34898071, -7.86164637],
                            [39.34900609, -7.86170349],
                            [39.34910127, -7.86176696],
                            [39.3492028, -7.86176696],
                            [39.34932337, -7.8616908],
                            [39.3494249, -7.86158924],
                            [39.34952008, -7.86149405],
                            [39.34958353, -7.86139884],
                            [39.34967872, -7.86129093],
                            [39.34976756, -7.861164],
                            [39.34987543, -7.86102437],
                            [39.349996, -7.86091013],
                            [39.35011022, -7.86077049],
                            [39.35018001, -7.86064355],
                            [39.35024982, -7.86053565],
                            [39.35035135, -7.86039602],
                            [39.3504148, -7.86026909],
                            [39.3504846, -7.86012945],
                            [39.35059248, -7.85997079],
                            [39.35066228, -7.85985018],
                            [39.35071304, -7.85974229],
                            [39.35078284, -7.85961534],
                            [39.3508463, -7.85945668],
                            [39.35088438, -7.85931704],
                            [39.35089707, -7.8591774],
                            [39.35090341, -7.85907585],
                            [39.35097321, -7.85898067],
                            [39.35101128, -7.85884103],
                            [39.35104936, -7.85870139],
                            [39.35110013, -7.85851733],
                            [39.35114455, -7.85833961],
                            [39.35117626, -7.85817459],
                        ],
                        [
                            [39.29879595, -7.8539392],
                            [39.29898913, -7.85364936],
                            [39.29902132, -7.85319851],
                            [39.29905352, -7.85265107],
                            [39.29905353, -7.85236123],
                            [39.29905353, -7.852007],
                            [39.29879596, -7.85136292],
                            [39.29869937, -7.85104089],
                            [39.2985384, -7.85087988],
                            [39.29837742, -7.85071886],
                            [39.29811985, -7.85059004],
                            [39.29789447, -7.85059004],
                            [39.29770129, -7.85062225],
                            [39.29757251, -7.85078326],
                            [39.29744372, -7.85100868],
                            [39.29744372, -7.85133072],
                            [39.29744372, -7.85171715],
                            [39.29737933, -7.85207139],
                            [39.29737933, -7.85242563],
                            [39.29737932, -7.85268325],
                            [39.29737932, -7.85290869],
                            [39.29737932, -7.85335952],
                            [39.29737932, -7.85368156],
                            [39.29725054, -7.8541002],
                            [39.29721834, -7.85445444],
                            [39.29721834, -7.85471207],
                            [39.29725054, -7.8550341],
                            [39.29741152, -7.85538834],
                            [39.29760469, -7.85561377],
                            [39.29779786, -7.85583919],
                            [39.29815202, -7.85580699],
                            [39.2983452, -7.85564597],
                            [39.29863497, -7.85538834],
                            [39.29869936, -7.85506631],
                            [39.29873156, -7.85464767],
                            [39.29873156, -7.85432563],
                            [39.29879595, -7.8539392],
                        ],
                        [
                            [39.35254373, -7.85066394],
                            [39.35260979, -7.85061438],
                            [39.35265933, -7.85055933],
                            [39.35270336, -7.85050427],
                            [39.35271988, -7.85042719],
                            [39.35274741, -7.85035562],
                            [39.35275291, -7.85028954],
                            [39.35276942, -7.85022897],
                            [39.35278044, -7.85015739],
                            [39.35279695, -7.85007481],
                            [39.35281347, -7.84999222],
                            [39.35284648, -7.84994267],
                            [39.3528575, -7.84987661],
                            [39.35287951, -7.84981053],
                            [39.35289603, -7.84967838],
                            [39.35287952, -7.84962332],
                            [39.35286852, -7.84956827],
                            [39.35284099, -7.84952973],
                            [39.35280246, -7.84948569],
                            [39.35275841, -7.84943613],
                            [39.35275291, -7.84943613],
                            [39.35266815, -7.84943437],
                            [39.35264348, -7.84945903],
                            [39.35261882, -7.8494837],
                            [39.35258712, -7.84950132],
                            [39.35255893, -7.84953656],
                            [39.35254836, -7.8495718],
                            [39.35251665, -7.84962465],
                            [39.35249551, -7.84967046],
                            [39.35247086, -7.84969865],
                            [39.35245323, -7.84973389],
                            [39.35242153, -7.84976208],
                            [39.35238983, -7.84976912],
                            [39.3523546, -7.84976912],
                            [39.35231936, -7.84976912],
                            [39.35229118, -7.84976912],
                            [39.35225596, -7.84976912],
                            [39.35223482, -7.84978321],
                            [39.35221015, -7.84980084],
                            [39.35219254, -7.84985018],
                            [39.35217493, -7.84992614],
                            [39.35215842, -7.85000874],
                            [39.35215842, -7.85010233],
                            [39.35215842, -7.85015739],
                            [39.35215842, -7.85022346],
                            [39.35215842, -7.85027852],
                            [39.3521529, -7.85032258],
                            [39.3521419, -7.85037213],
                            [39.35213638, -7.85043269],
                            [39.35209236, -7.85048225],
                            [39.35206482, -7.85052629],
                            [39.35205932, -7.85059236],
                            [39.35205382, -7.85066394],
                            [39.35208134, -7.85069697],
                            [39.35212538, -7.85071899],
                            [39.35216941, -7.8507245],
                            [39.35223547, -7.8507245],
                            [39.35229602, -7.85071899],
                            [39.35236208, -7.85070798],
                            [39.35241162, -7.85068596],
                            [39.35247768, -7.85068596],
                            [39.35254373, -7.85066394],
                        ],
                        [
                            [39.36309312, -7.85345294],
                            [39.36296294, -7.85371338],
                            [39.36283274, -7.85396078],
                            [39.36271559, -7.85431236],
                            [39.36266351, -7.85462487],
                            [39.36259842, -7.85493738],
                            [39.36253331, -7.85538012],
                            [39.3623771, -7.85579681],
                            [39.36224691, -7.85617443],
                            [39.36209068, -7.85657809],
                            [39.36190842, -7.85692967],
                            [39.36183032, -7.85717708],
                            [39.36183031, -7.85747972],
                            [39.3618201, -7.85747707],
                            [39.36179125, -7.85761411],
                            [39.36176928, -7.857702],
                            [39.36176196, -7.85782653],
                            [39.36176928, -7.85795836],
                            [39.36184983, -7.85805359],
                            [39.36198165, -7.85814881],
                            [39.36217205, -7.85814881],
                            [39.36237709, -7.85814881],
                            [39.3625748, -7.85806823],
                            [39.36268465, -7.8579657],
                            [39.3627652, -7.85789978],
                            [39.36287504, -7.85785583],
                            [39.36305812, -7.85781187],
                            [39.36323387, -7.85775329],
                            [39.36338291, -7.85771068],
                            [39.36340661, -7.85771148],
                            [39.36371799, -7.85756774],
                            [39.36408252, -7.85735939],
                            [39.36448609, -7.85713802],
                            [39.36482457, -7.8568776],
                            [39.36522815, -7.85660414],
                            [39.36568381, -7.85629163],
                            [39.36603529, -7.85596611],
                            [39.36629568, -7.85564056],
                            [39.36660812, -7.85502856],
                            [39.36689453, -7.85446863],
                            [39.36707679, -7.8539608],
                            [39.36727207, -7.85321857],
                            [39.36733716, -7.85293211],
                            [39.36744132, -7.85250239],
                            [39.36761056, -7.85196851],
                            [39.36777981, -7.85162996],
                            [39.36788396, -7.85136953],
                            [39.36788933, -7.85136624],
                            [39.36784499, -7.85116225],
                            [39.36781737, -7.85058199],
                            [39.36773449, -7.85000175],
                            [39.36765162, -7.84939386],
                            [39.36765162, -7.84884124],
                            [39.367624, -7.8483715],
                            [39.36756875, -7.84792938],
                            [39.36767689, -7.84734718],
                            [39.36767689, -7.84711601],
                            [39.3674804, -7.84725474],
                            [39.36732417, -7.84733287],
                            [39.36710285, -7.84742402],
                            [39.36689456, -7.84761934],
                            [39.366504, -7.84790581],
                            [39.36617854, -7.84816624],
                            [39.36589213, -7.84827041],
                            [39.36565779, -7.84843969],
                            [39.3653844, -7.84870011],
                            [39.36517611, -7.84901264],
                            [39.36495479, -7.84932515],
                            [39.36475951, -7.84965068],
                            [39.36455121, -7.85000226],
                            [39.36438198, -7.85028873],
                            [39.36422575, -7.85062729],
                            [39.3640565, -7.85107002],
                            [39.36383518, -7.85155181],
                            [39.36360084, -7.85207267],
                            [39.36341858, -7.85259353],
                            [39.36324934, -7.8530623],
                            [39.36309312, -7.85345294],
                        ],
                        [
                            [39.36653013, -7.84516524],
                            [39.36661415, -7.84482915],
                            [39.36666455, -7.84439221],
                            [39.36666456, -7.84389646],
                            [39.36663936, -7.84359395],
                            [39.36658055, -7.84345111],
                            [39.36651335, -7.84339229],
                            [39.36638733, -7.84336708],
                            [39.36630332, -7.8434259],
                            [39.3662109, -7.84354353],
                            [39.3660933, -7.8437368],
                            [39.36597569, -7.84393006],
                            [39.36583287, -7.84421575],
                            [39.36566486, -7.84462748],
                            [39.36559766, -7.84511482],
                            [39.36553044, -7.84545934],
                            [39.36549684, -7.84579545],
                            [39.36545483, -7.84614836],
                            [39.36540442, -7.84637523],
                            [39.36536242, -7.84653487],
                            [39.36526162, -7.84671132],
                            [39.3651104, -7.84701381],
                            [39.36496759, -7.84731631],
                            [39.36488358, -7.84752638],
                            [39.36477436, -7.84777006],
                            [39.36474916, -7.84799693],
                            [39.36481638, -7.84812297],
                            [39.36500959, -7.84812297],
                            [39.3651524, -7.84803053],
                            [39.36538762, -7.8478709],
                            [39.36556404, -7.84764403],
                            [39.36571525, -7.84740034],
                            [39.36581606, -7.84713986],
                            [39.36592528, -7.84685418],
                            [39.36600927, -7.84651807],
                            [39.3662193, -7.84599711],
                            [39.36637051, -7.84559379],
                            [39.36653013, -7.84516524],
                        ],
                        [
                            [39.36668975, -7.84702223],
                            [39.36680735, -7.84683737],
                            [39.36693336, -7.84675335],
                            [39.36700897, -7.84672814],
                            [39.36717698, -7.84672814],
                            [39.367345, -7.84666932],
                            [39.3674122, -7.84650127],
                            [39.36746261, -7.84625759],
                            [39.36743741, -7.84590468],
                            [39.3673618, -7.845619],
                            [39.36727779, -7.84528289],
                            [39.36706779, -7.8450056],
                            [39.36687456, -7.8450392],
                            [39.36672334, -7.84529128],
                            [39.36658894, -7.84567781],
                            [39.36650493, -7.84603912],
                            [39.36636212, -7.84639203],
                            [39.36626129, -7.84668612],
                            [39.36614369, -7.8469634],
                            [39.36610169, -7.84713146],
                            [39.36610169, -7.84719027],
                            [39.36616048, -7.84720709],
                            [39.36621929, -7.84713146],
                            [39.36637891, -7.84703063],
                            [39.36647132, -7.84708944],
                            [39.36658053, -7.84713147],
                            [39.36668975, -7.84702223],
                        ],
                        [
                            [39.35231836, -7.85388476],
                            [39.35231836, -7.85380279],
                            [39.35236445, -7.8537362],
                            [39.35238495, -7.85366959],
                            [39.35240031, -7.853603],
                            [39.3524208, -7.85353128],
                            [39.35244128, -7.85344932],
                            [39.35246176, -7.85337249],
                            [39.35248737, -7.85330588],
                            [39.35251298, -7.8532188],
                            [39.35252836, -7.85313684],
                            [39.35255397, -7.85307537],
                            [39.35256932, -7.8529934],
                            [39.35259493, -7.8529012],
                            [39.35261542, -7.85280899],
                            [39.35264615, -7.85273215],
                            [39.35266663, -7.85263482],
                            [39.35268712, -7.85253236],
                            [39.35270249, -7.85244016],
                            [39.35271273, -7.85237355],
                            [39.35271785, -7.85228648],
                            [39.35271785, -7.85219427],
                            [39.35271785, -7.8521123],
                            [39.3527025, -7.85205082],
                            [39.35269225, -7.85199448],
                            [39.35266151, -7.85196374],
                            [39.35260006, -7.85192277],
                            [39.35253349, -7.85191251],
                            [39.3524669, -7.85190739],
                            [39.35240545, -7.85190739],
                            [39.35235423, -7.85190739],
                            [39.35228765, -7.85192788],
                            [39.35224155, -7.85196374],
                            [39.35219034, -7.85200472],
                            [39.352134, -7.85205082],
                            [39.35207254, -7.85210205],
                            [39.35202132, -7.85213791],
                            [39.35198548, -7.85218914],
                            [39.35194962, -7.8522455],
                            [39.35192913, -7.85231209],
                            [39.35190353, -7.85236844],
                            [39.35187279, -7.85242478],
                            [39.35184718, -7.85249137],
                            [39.35181134, -7.85257334],
                            [39.35179598, -7.85265531],
                            [39.35179086, -7.85274238],
                            [39.35179086, -7.85284484],
                            [39.35178573, -7.85291143],
                            [39.35177037, -7.85298315],
                            [39.35177037, -7.8530856],
                            [39.35176525, -7.85316245],
                            [39.35176525, -7.8532649],
                            [39.35176525, -7.85335198],
                            [39.35176525, -7.85343908],
                            [39.35176525, -7.85350567],
                            [39.35180109, -7.85357226],
                            [39.35182159, -7.85362861],
                            [39.3518523, -7.85369009],
                            [39.35186768, -7.85377204],
                            [39.35188816, -7.85385401],
                            [39.35191889, -7.85393086],
                            [39.35191889, -7.85399745],
                            [39.35191889, -7.85406916],
                            [39.35194961, -7.85414601],
                            [39.35203156, -7.85424846],
                            [39.35208278, -7.8542792],
                            [39.35213399, -7.85432531],
                            [39.35221082, -7.85417675],
                            [39.35220569, -7.85418187],
                            [39.35224154, -7.8540999],
                            [39.35227227, -7.85404868],
                            [39.35229788, -7.85397696],
                            [39.35231836, -7.85388476],
                        ],
                        [
                            [39.38184983, -7.84251597],
                            [39.38221668, -7.84247281],
                            [39.3826051, -7.84225697],
                            [39.38295036, -7.84223539],
                            [39.38344668, -7.84219223],
                            [39.38405089, -7.84219223],
                            [39.38446089, -7.84227856],
                            [39.38478456, -7.84247282],
                            [39.38510825, -7.84271024],
                            [39.38536719, -7.84299084],
                            [39.38553983, -7.843293],
                            [39.38579877, -7.84331459],
                            [39.38620877, -7.84333617],
                            [39.38646773, -7.84303401],
                            [39.38661877, -7.84255915],
                            [39.38661877, -7.84219224],
                            [39.38674825, -7.84195481],
                            [39.38705035, -7.84173897],
                            [39.38715825, -7.84154472],
                            [39.38707195, -7.84137206],
                            [39.3867051, -7.84126413],
                            [39.3861872, -7.84115622],
                            [39.38579878, -7.84102671],
                            [39.38545353, -7.8408972],
                            [39.38519457, -7.84078929],
                            [39.38506511, -7.84059503],
                            [39.38491405, -7.84035761],
                            [39.38491405, -7.84003385],
                            [39.38482774, -7.83990434],
                            [39.38459036, -7.83992592],
                            [39.38428826, -7.83999068],
                            [39.3840509, -7.83999068],
                            [39.38377036, -7.84005543],
                            [39.38344669, -7.84001226],
                            [39.38318773, -7.83981801],
                            [39.38305827, -7.8396885],
                            [39.38277773, -7.83958058],
                            [39.38256194, -7.83958058],
                            [39.38245406, -7.83975326],
                            [39.38260511, -7.84003384],
                            [39.38288563, -7.84024968],
                            [39.38307984, -7.8404871],
                            [39.38336036, -7.8407677],
                            [39.38340353, -7.84115621],
                            [39.38318772, -7.84135045],
                            [39.38286405, -7.84139362],
                            [39.38260511, -7.8415663],
                            [39.38241089, -7.84180371],
                            [39.38210878, -7.84180371],
                            [39.38180668, -7.84189004],
                            [39.38137511, -7.84214904],
                            [39.38143984, -7.84238648],
                            [39.38184983, -7.84251597],
                        ],
                        [
                            [39.42618248, -7.84005967],
                            [39.42618248, -7.83989664],
                            [39.42607382, -7.83972003],
                            [39.42591082, -7.83957058],
                            [39.4258565, -7.83950264],
                            [39.4256935, -7.8394483],
                            [39.42553051, -7.83951624],
                            [39.42539469, -7.83952982],
                            [39.42521812, -7.83952982],
                            [39.42504153, -7.83952981],
                            [39.42493288, -7.83961133],
                            [39.42476988, -7.83963851],
                            [39.42460689, -7.83976078],
                            [39.42457972, -7.83988305],
                            [39.42462047, -7.8400189],
                            [39.42470196, -7.840114],
                            [39.42486495, -7.84024987],
                            [39.42508227, -7.84042648],
                            [39.42532676, -7.84058951],
                            [39.42557125, -7.84067103],
                            [39.42591082, -7.84080688],
                            [39.42619606, -7.84086122],
                            [39.42642697, -7.8408884],
                            [39.42638622, -7.84075255],
                            [39.42623681, -7.8406031],
                            [39.42622323, -7.84044007],
                            [39.4262504, -7.84024988],
                            [39.42618248, -7.84005967],
                        ],
                        [
                            [39.41733039, -7.84423981],
                            [39.41721005, -7.84444043],
                            [39.41710307, -7.84465441],
                            [39.41702284, -7.84488178],
                            [39.41684902, -7.84540337],
                            [39.41679553, -7.84555048],
                            [39.41679553, -7.84573772],
                            [39.41679553, -7.84589821],
                            [39.4169025, -7.84599183],
                            [39.41698273, -7.84611221],
                            [39.41715655, -7.84623257],
                            [39.41738387, -7.84633956],
                            [39.41761117, -7.84637968],
                            [39.41779837, -7.84632619],
                            [39.41794545, -7.84613895],
                            [39.41810592, -7.84597847],
                            [39.41827974, -7.84583135],
                            [39.41844019, -7.84565749],
                            [39.41860065, -7.84553711],
                            [39.41868088, -7.84540338],
                            [39.41882795, -7.84526964],
                            [39.4190419, -7.84510914],
                            [39.41920235, -7.84500215],
                            [39.41934943, -7.84490852],
                            [39.41953663, -7.84477478],
                            [39.41975058, -7.84464105],
                            [39.41993777, -7.84444044],
                            [39.41995115, -7.84421307],
                            [39.41983081, -7.84405259],
                            [39.41976395, -7.84391884],
                            [39.41967035, -7.8437851],
                            [39.41959012, -7.84357111],
                            [39.41945641, -7.84339724],
                            [39.41937619, -7.84330362],
                            [39.41926921, -7.84318325],
                            [39.41916225, -7.84307626],
                            [39.41908202, -7.84299601],
                            [39.41896168, -7.84296927],
                            [39.41882796, -7.84296927],
                            [39.41876111, -7.84306288],
                            [39.41864077, -7.84312975],
                            [39.41854718, -7.84325012],
                            [39.41842682, -7.84337049],
                            [39.41830648, -7.84349085],
                            [39.41818614, -7.84354436],
                            [39.41799895, -7.84361123],
                            [39.4178385, -7.84361123],
                            [39.4176513, -7.84361123],
                            [39.41751759, -7.84370484],
                            [39.41742398, -7.84405258],
                            [39.41733039, -7.84423981],
                        ],
                        [
                            [39.42267785, -7.84586068],
                            [39.42284498, -7.84571739],
                            [39.42296437, -7.84553828],
                            [39.42302405, -7.84543082],
                            [39.42309568, -7.84523977],
                            [39.42311955, -7.84502484],
                            [39.42321506, -7.84484573],
                            [39.42331056, -7.84464274],
                            [39.42331056, -7.84448751],
                            [39.42323894, -7.84440393],
                            [39.42298825, -7.84435616],
                            [39.42286886, -7.8443084],
                            [39.4227853, -7.84423676],
                            [39.42265398, -7.84421288],
                            [39.42253459, -7.84426064],
                            [39.42249878, -7.84436809],
                            [39.42248684, -7.84451139],
                            [39.42243909, -7.84465468],
                            [39.42235554, -7.8447502],
                            [39.42229583, -7.84486961],
                            [39.4222839, -7.8450129],
                            [39.42224808, -7.84518006],
                            [39.42215259, -7.84527559],
                            [39.42206901, -7.84532335],
                            [39.42196158, -7.84546664],
                            [39.42196158, -7.84564575],
                            [39.42192575, -7.84596814],
                            [39.42185413, -7.84603979],
                            [39.42184219, -7.84617113],
                            [39.42191381, -7.84632636],
                            [39.42200932, -7.84644577],
                            [39.42220033, -7.84655323],
                            [39.42239134, -7.84649354],
                            [39.42249877, -7.84635024],
                            [39.42266591, -7.84611143],
                            [39.42267785, -7.84586068],
                        ],
                        [
                            [39.41608686, -7.84568422],
                            [39.41604675, -7.84561735],
                            [39.41588629, -7.84559061],
                            [39.41573922, -7.84559061],
                            [39.41564561, -7.84555047],
                            [39.415552, -7.84547024],
                            [39.41544504, -7.84541673],
                            [39.41539156, -7.84547024],
                            [39.41537818, -7.8455906],
                            [39.41537818, -7.84575109],
                            [39.41537818, -7.84591159],
                            [39.41537818, -7.84607207],
                            [39.41547179, -7.84624593],
                            [39.41557875, -7.84648668],
                            [39.41569909, -7.84664716],
                            [39.41573921, -7.84684778],
                            [39.41573921, -7.84700826],
                            [39.41576594, -7.84719551],
                            [39.41580605, -7.84740949],
                            [39.41589965, -7.84752986],
                            [39.41607347, -7.84759673],
                            [39.41626067, -7.84769036],
                            [39.41639438, -7.84773047],
                            [39.41650136, -7.84765023],
                            [39.41655485, -7.84740949],
                            [39.41659496, -7.84723563],
                            [39.41664844, -7.8470484],
                            [39.41667519, -7.84691465],
                            [39.41672867, -7.84676753],
                            [39.4167153, -7.84664717],
                            [39.41663508, -7.84654018],
                            [39.41654148, -7.84643317],
                            [39.41643451, -7.84633956],
                            [39.41631417, -7.84627269],
                            [39.41606011, -7.84609883],
                            [39.41608686, -7.84593833],
                            [39.41608686, -7.84580458],
                            [39.41608686, -7.84568422],
                        ],
                        [
                            [39.39807284, -7.84556675],
                            [39.39797126, -7.84576991],
                            [39.39795857, -7.84604927],
                            [39.39792048, -7.84637943],
                            [39.39792048, -7.84669688],
                            [39.3978824, -7.84695083],
                            [39.39780623, -7.8472048],
                            [39.39780623, -7.84739527],
                            [39.39770465, -7.84763653],
                            [39.39764119, -7.8478143],
                            [39.39760309, -7.84806826],
                            [39.39745076, -7.84820794],
                            [39.39738727, -7.84833493],
                            [39.39737459, -7.84858888],
                            [39.39737458, -7.84886825],
                            [39.3973238, -7.8490587],
                            [39.39715876, -7.84932536],
                            [39.3970445, -7.84952854],
                            [39.39695563, -7.84980789],
                            [39.39687946, -7.85002376],
                            [39.39684138, -7.85022693],
                            [39.39668903, -7.8504682],
                            [39.39666364, -7.85070946],
                            [39.39658747, -7.85088723],
                            [39.396524, -7.8510777],
                            [39.39644781, -7.85128086],
                            [39.39659143, -7.85151419],
                            [39.39661285, -7.85150944],
                            [39.39668902, -7.85159831],
                            [39.39684137, -7.85168721],
                            [39.3970318, -7.85168721],
                            [39.39719683, -7.85168721],
                            [39.39741265, -7.85167452],
                            [39.397565, -7.85159832],
                            [39.39771734, -7.85149674],
                            [39.39780621, -7.85144595],
                            [39.39789507, -7.85143326],
                            [39.39798394, -7.85140786],
                            [39.39807281, -7.85139516],
                            [39.39814898, -7.85138245],
                            [39.39827593, -7.85138245],
                            [39.39844097, -7.85145865],
                            [39.39869486, -7.85159832],
                            [39.39880912, -7.85168721],
                            [39.39880912, -7.8518269],
                            [39.39878373, -7.85197927],
                            [39.39884721, -7.85222053],
                            [39.39894877, -7.85234751],
                            [39.39906303, -7.85244911],
                            [39.39929154, -7.85251259],
                            [39.39949466, -7.85251259],
                            [39.39971048, -7.85248719],
                            [39.39981205, -7.8524364],
                            [39.40001517, -7.8523983],
                            [39.40018021, -7.85234751],
                            [39.40042142, -7.85223324],
                            [39.40051027, -7.85213165],
                            [39.40053568, -7.85197928],
                            [39.40053568, -7.85183959],
                            [39.40051028, -7.85163643],
                            [39.40044681, -7.85150945],
                            [39.40028177, -7.85140787],
                            [39.4001929, -7.85130627],
                            [39.4001929, -7.85116661],
                            [39.40007866, -7.85106501],
                            [39.39995169, -7.85106501],
                            [39.39986284, -7.85095074],
                            [39.39983745, -7.85083645],
                            [39.3996978, -7.85072216],
                            [39.39955815, -7.85063329],
                            [39.39949467, -7.85053169],
                            [39.39949467, -7.85040471],
                            [39.39944389, -7.85025234],
                            [39.39930425, -7.85016345],
                            [39.39918999, -7.85007456],
                            [39.39903765, -7.84987139],
                            [39.39899956, -7.84969363],
                            [39.39901227, -7.84952855],
                            [39.39903766, -7.84933808],
                            [39.39903766, -7.84905871],
                            [39.39903766, -7.84888094],
                            [39.39903766, -7.84867778],
                            [39.39903766, -7.84846191],
                            [39.39915191, -7.84828413],
                            [39.39921539, -7.84800478],
                            [39.39930426, -7.84776352],
                            [39.39930426, -7.84752225],
                            [39.39930426, -7.84735718],
                            [39.39916461, -7.84715401],
                            [39.39919, -7.84703973],
                            [39.39919001, -7.84684925],
                            [39.3991773, -7.84667149],
                            [39.39916462, -7.84645562],
                            [39.39915192, -7.84629054],
                            [39.39912653, -7.84611277],
                            [39.39898688, -7.84596038],
                            [39.39888532, -7.8458842],
                            [39.39877107, -7.84575722],
                            [39.39874568, -7.84563024],
                            [39.39865681, -7.84545246],
                            [39.39863142, -7.84532548],
                            [39.39850448, -7.84517311],
                            [39.39835212, -7.84505882],
                            [39.39827595, -7.84519851],
                            [39.39816169, -7.84536358],
                            [39.39807284, -7.84556675],
                        ],
                        [
                            [39.42432589, -7.84890857],
                            [39.42432589, -7.84879171],
                            [39.42432589, -7.84863588],
                            [39.42427396, -7.84850603],
                            [39.42419607, -7.84842812],
                            [39.42409222, -7.84829827],
                            [39.42383257, -7.84819439],
                            [39.42375467, -7.84811647],
                            [39.42363783, -7.84801259],
                            [39.42358591, -7.84796066],
                            [39.42336522, -7.84788274],
                            [39.42323539, -7.84785678],
                            [39.42309259, -7.84784379],
                            [39.42292381, -7.84784379],
                            [39.42280697, -7.84790871],
                            [39.42269013, -7.84798663],
                            [39.4226512, -7.84810349],
                            [39.4226512, -7.8482593],
                            [39.42270312, -7.84840214],
                            [39.42280697, -7.84866185],
                            [39.42288487, -7.84885662],
                            [39.42294977, -7.84906437],
                            [39.42304065, -7.8492202],
                            [39.42323539, -7.84932408],
                            [39.42343011, -7.84941499],
                            [39.42366379, -7.84946692],
                            [39.42392344, -7.84947991],
                            [39.42407923, -7.84945394],
                            [39.42418308, -7.84933707],
                            [39.42424799, -7.84922021],
                            [39.42429992, -7.84905141],
                            [39.42432589, -7.84890857],
                        ],
                        [
                            [39.42414509, -7.83779083],
                            [39.42414509, -7.83795386],
                            [39.42413149, -7.83813047],
                            [39.42407717, -7.83827992],
                            [39.42399567, -7.83844295],
                            [39.42399567, -7.83855163],
                            [39.42403642, -7.8386875],
                            [39.42421299, -7.83875542],
                            [39.42474272, -7.83876902],
                            [39.42493288, -7.83874184],
                            [39.42504153, -7.83863315],
                            [39.42516378, -7.83853805],
                            [39.42532677, -7.83851088],
                            [39.42553051, -7.8383886],
                            [39.42569351, -7.83829351],
                            [39.42581575, -7.83814408],
                            [39.42589725, -7.83800821],
                            [39.42589725, -7.83787235],
                            [39.42580218, -7.83779084],
                            [39.42567993, -7.8377365],
                            [39.42538112, -7.8377229],
                            [39.42510946, -7.83766856],
                            [39.42487854, -7.83769574],
                            [39.42463407, -7.83765498],
                            [39.42437599, -7.83765498],
                            [39.42421299, -7.83766856],
                            [39.42414509, -7.83779083],
                        ],
                        [
                            [39.42415867, -7.8370572],
                            [39.42415867, -7.83731532],
                            [39.42432166, -7.83741043],
                            [39.42455257, -7.83738326],
                            [39.42472914, -7.83735608],
                            [39.42494646, -7.8373425],
                            [39.42515021, -7.83731533],
                            [39.42535395, -7.83719305],
                            [39.42550337, -7.8370572],
                            [39.42554411, -7.83689417],
                            [39.42554411, -7.83674474],
                            [39.4253947, -7.83666322],
                            [39.4253132, -7.83650019],
                            [39.42519096, -7.83632356],
                            [39.42505514, -7.83618771],
                            [39.42487855, -7.83610619],
                            [39.42463408, -7.83607902],
                            [39.42448466, -7.83614695],
                            [39.42448466, -7.83637791],
                            [39.42441674, -7.8365817],
                            [39.42424017, -7.83678549],
                            [39.42415867, -7.8370572],
                        ],
                        [
                            [39.4201246, -7.83582087],
                            [39.41998878, -7.83582087],
                            [39.41985295, -7.83595672],
                            [39.41982578, -7.83617411],
                            [39.41982578, -7.83632354],
                            [39.41992085, -7.83650017],
                            [39.4200431, -7.83667678],
                            [39.42020609, -7.83678547],
                            [39.42036908, -7.83693491],
                            [39.4205185, -7.83700284],
                            [39.42065432, -7.83700284],
                            [39.42076299, -7.83689415],
                            [39.42080374, -7.83671754],
                            [39.42095314, -7.83664961],
                            [39.42107539, -7.83648658],
                            [39.42098031, -7.8363643],
                            [39.42081732, -7.83620127],
                            [39.4206679, -7.83606542],
                            [39.42050491, -7.83606542],
                            [39.42045059, -7.83592956],
                            [39.42034192, -7.83580729],
                            [39.42023327, -7.83582087],
                            [39.4201246, -7.83582087],
                        ],
                        [
                            [39.42151793, -7.82677407],
                            [39.4212243, -7.82663495],
                            [39.42103885, -7.8266504],
                            [39.42097702, -7.82678952],
                            [39.42079158, -7.82692864],
                            [39.42065249, -7.82695955],
                            [39.42032796, -7.82695955],
                            [39.41998797, -7.82682043],
                            [39.41981798, -7.82661949],
                            [39.41975615, -7.82632579],
                            [39.41963252, -7.82610938],
                            [39.4195398, -7.82598573],
                            [39.41952435, -7.82598573],
                            [39.41936981, -7.82589297],
                            [39.41921527, -7.82601664],
                            [39.41918435, -7.82621758],
                            [39.41910709, -7.8263567],
                            [39.41907618, -7.82654219],
                            [39.41898345, -7.82669676],
                            [39.41885981, -7.82678951],
                            [39.41859709, -7.82680497],
                            [39.41839619, -7.82686681],
                            [39.41831893, -7.82699046],
                            [39.41819529, -7.82712957],
                            [39.41797892, -7.82722233],
                            [39.41771621, -7.82726869],
                            [39.4175153, -7.82733053],
                            [39.41736076, -7.82743872],
                            [39.4172835, -7.82763968],
                            [39.41709803, -7.82774787],
                            [39.41691259, -7.82793338],
                            [39.41691259, -7.82816523],
                            [39.41691259, -7.82838165],
                            [39.41705167, -7.82841256],
                            [39.41725257, -7.82841256],
                            [39.41743803, -7.82833526],
                            [39.41762348, -7.8282889],
                            [39.41777802, -7.82825799],
                            [39.41793256, -7.82813432],
                            [39.41817983, -7.82808796],
                            [39.41844254, -7.82814978],
                            [39.41865891, -7.82824253],
                            [39.41887526, -7.82838165],
                            [39.41896799, -7.82864442],
                            [39.41898344, -7.82882993],
                            [39.41899889, -7.82899996],
                            [39.41921526, -7.82899996],
                            [39.41946251, -7.82881447],
                            [39.41974069, -7.82864443],
                            [39.42001887, -7.82852078],
                            [39.4201425, -7.82838166],
                            [39.4203125, -7.82828891],
                            [39.42052885, -7.82838166],
                            [39.4208534, -7.82859806],
                            [39.42103884, -7.82872173],
                            [39.42142519, -7.8288763],
                            [39.42188882, -7.82895359],
                            [39.42230607, -7.82903088],
                            [39.42261515, -7.82892268],
                            [39.42275424, -7.8287681],
                            [39.42295516, -7.82862898],
                            [39.42301697, -7.82848986],
                            [39.42290879, -7.82831983],
                            [39.42264608, -7.82827346],
                            [39.42241426, -7.82825801],
                            [39.42210518, -7.8281498],
                            [39.42191973, -7.82796431],
                            [39.4217961, -7.82776336],
                            [39.42174974, -7.82754695],
                            [39.42178065, -7.82729962],
                            [39.42185791, -7.82714505],
                            [39.42164156, -7.82697501],
                            [39.42151793, -7.82677407],
                        ],
                        [
                            [39.37409844, -7.8162526],
                            [39.37409844, -7.81603684],
                            [39.37409844, -7.81585514],
                            [39.37405302, -7.81563938],
                            [39.37400762, -7.81543497],
                            [39.37361025, -7.81512835],
                            [39.37342858, -7.81512834],
                            [39.37320151, -7.81517377],
                            [39.37301985, -7.81529869],
                            [39.37282684, -7.81546903],
                            [39.37272465, -7.81570751],
                            [39.37272465, -7.81599141],
                            [39.37272465, -7.81628667],
                            [39.37283819, -7.81654786],
                            [39.37299714, -7.81672956],
                            [39.37299714, -7.81685448],
                            [39.37311068, -7.81710432],
                            [39.37330369, -7.81733144],
                            [39.37347399, -7.81735415],
                            [39.37367834, -7.81747906],
                            [39.37376918, -7.81772891],
                            [39.37375782, -7.81789925],
                            [39.37374646, -7.81808094],
                            [39.37362158, -7.81825128],
                            [39.37355345, -7.81831942],
                            [39.37353075, -7.81844434],
                            [39.37372376, -7.81861468],
                            [39.37406437, -7.81872825],
                            [39.37439362, -7.81888723],
                            [39.37475693, -7.81908029],
                            [39.37515431, -7.81917113],
                            [39.3754495, -7.81913707],
                            [39.37585823, -7.81904622],
                            [39.37625561, -7.81896673],
                            [39.37645996, -7.81889859],
                            [39.37667569, -7.81875097],
                            [39.37684599, -7.81858063],
                            [39.37684599, -7.8183535],
                            [39.37674381, -7.81816045],
                            [39.37664163, -7.81797875],
                            [39.37642592, -7.81776299],
                            [39.37617613, -7.81759264],
                            [39.37600583, -7.81746773],
                            [39.37577876, -7.81725195],
                            [39.37543815, -7.8170589],
                            [39.37515432, -7.81684314],
                            [39.37481371, -7.81666143],
                            [39.37451852, -7.81649109],
                            [39.37409844, -7.8162526],
                        ],
                        [
                            [39.3282993, -7.82436032],
                            [39.32841735, -7.82454925],
                            [39.32861805, -7.82473819],
                            [39.32875971, -7.82489169],
                            [39.32899583, -7.82512786],
                            [39.32917291, -7.82531679],
                            [39.32931458, -7.82542307],
                            [39.32945625, -7.82554116],
                            [39.32956249, -7.8256002],
                            [39.32970416, -7.825612],
                            [39.32988125, -7.82554116],
                            [39.33007014, -7.82534042],
                            [39.33022361, -7.82518691],
                            [39.33036528, -7.8250334],
                            [39.33047154, -7.8248799],
                            [39.33053057, -7.82477361],
                            [39.33053057, -7.82469096],
                            [39.33043612, -7.8246083],
                            [39.33023542, -7.82453746],
                            [39.33001112, -7.82440756],
                            [39.32972779, -7.82430129],
                            [39.32946806, -7.82412416],
                            [39.32929098, -7.82402969],
                            [39.32916111, -7.82394703],
                            [39.32899583, -7.82386438],
                            [39.32886598, -7.82380533],
                            [39.3287125, -7.82380533],
                            [39.32855902, -7.82380533],
                            [39.32841736, -7.82385256],
                            [39.32831111, -7.82393523],
                            [39.32827569, -7.8240415],
                            [39.32825208, -7.8241832],
                            [39.3282993, -7.82436032],
                        ],
                        [
                            [39.33677575, -7.75100241],
                            [39.33664402, -7.75117812],
                            [39.33664402, -7.75148557],
                            [39.33649032, -7.75170518],
                            [39.33646837, -7.75194676],
                            [39.33662207, -7.75229814],
                            [39.33664402, -7.75262756],
                            [39.33684162, -7.75293502],
                            [39.33714901, -7.75313267],
                            [39.33747836, -7.75311072],
                            [39.33769793, -7.7528911],
                            [39.33791748, -7.75273737],
                            [39.33811509, -7.75253972],
                            [39.33826878, -7.75238599],
                            [39.33848835, -7.75218834],
                            [39.33853226, -7.75190284],
                            [39.3386201, -7.75170519],
                            [39.3384664, -7.7515295],
                            [39.33826879, -7.75130988],
                            [39.33811509, -7.75113419],
                            [39.33791749, -7.75087066],
                            [39.33785162, -7.75062908],
                            [39.3377638, -7.75040946],
                            [39.33767597, -7.75016789],
                            [39.33750032, -7.75010202],
                            [39.3372588, -7.75018986],
                            [39.33717098, -7.7503875],
                            [39.33699533, -7.75051928],
                            [39.33688555, -7.75080477],
                            [39.33677575, -7.75100241],
                        ],
                        [
                            [39.34446051, -7.74351365],
                            [39.34435074, -7.74322814],
                            [39.34413117, -7.74300854],
                            [39.34388966, -7.7428548],
                            [39.34364814, -7.7428548],
                            [39.3435164, -7.74311834],
                            [39.34353835, -7.74338187],
                            [39.34364814, -7.74366738],
                            [39.34391161, -7.74388698],
                            [39.34395551, -7.74415051],
                            [39.34395551, -7.74437013],
                            [39.34380183, -7.74450191],
                            [39.34362617, -7.7446776],
                            [39.34353834, -7.74498504],
                            [39.34362616, -7.74527055],
                            [39.34347248, -7.74549017],
                            [39.34347248, -7.74560293],
                            [39.34347248, -7.74570977],
                            [39.34347248, -7.74573173],
                            [39.34347248, -7.74606115],
                            [39.34342856, -7.74630272],
                            [39.34318703, -7.74647841],
                            [39.34294551, -7.7466541],
                            [39.34272596, -7.74667607],
                            [39.34257226, -7.74687372],
                            [39.34246248, -7.74715921],
                            [39.34241856, -7.7475106],
                            [39.3425503, -7.74781805],
                            [39.34272595, -7.74797178],
                            [39.34281378, -7.74816944],
                            [39.34257225, -7.74825729],
                            [39.34226486, -7.74834513],
                            [39.34195747, -7.74849885],
                            [39.34175986, -7.74869651],
                            [39.34178181, -7.748982],
                            [39.34191356, -7.74915769],
                            [39.34211116, -7.74933338],
                            [39.34230878, -7.74926751],
                            [39.34252833, -7.74909182],
                            [39.34261616, -7.74889416],
                            [39.34287965, -7.74896004],
                            [39.34312115, -7.74909182],
                            [39.34338464, -7.74922358],
                            [39.34371397, -7.74946516],
                            [39.34406528, -7.74957496],
                            [39.34439463, -7.74972869],
                            [39.34463615, -7.74975065],
                            [39.34489963, -7.74968478],
                            [39.34522897, -7.74946516],
                            [39.34538266, -7.74913574],
                            [39.34538266, -7.74882828],
                            [39.34536071, -7.74858672],
                            [39.34536071, -7.74827926],
                            [39.34549245, -7.74792788],
                            [39.34562418, -7.7476863],
                            [39.34586571, -7.74746669],
                            [39.34606332, -7.74711531],
                            [39.34610253, -7.74685382],
                            [39.34612918, -7.74667609],
                            [39.34612918, -7.74625882],
                            [39.34612919, -7.74597332],
                            [39.34612919, -7.74575371],
                            [39.34621701, -7.74555605],
                            [39.34626093, -7.74527056],
                            [39.34626093, -7.74498505],
                            [39.34615116, -7.74474349],
                            [39.34597551, -7.74445799],
                            [39.34586572, -7.74432623],
                            [39.34571202, -7.74430425],
                            [39.34549247, -7.74430425],
                            [39.34520703, -7.74434818],
                            [39.3449216, -7.74428229],
                            [39.3447679, -7.74406267],
                            [39.34465813, -7.74382111],
                            [39.34446051, -7.74351365],
                        ],
                        [
                            [39.74336037, -7.71192697],
                            [39.74344567, -7.71184164],
                            [39.74370157, -7.71184164],
                            [39.74395747, -7.71175632],
                            [39.74408543, -7.71158566],
                            [39.74417073, -7.711415],
                            [39.74442663, -7.71132967],
                            [39.74468253, -7.71124435],
                            [39.74476783, -7.71111635],
                            [39.74485313, -7.71090303],
                            [39.74498108, -7.7109457],
                            [39.74523698, -7.71098837],
                            [39.74536493, -7.71073238],
                            [39.74566348, -7.71068972],
                            [39.74587673, -7.71081771],
                            [39.74608998, -7.71094571],
                            [39.74634588, -7.7110737],
                            [39.74651648, -7.71111637],
                            [39.74672973, -7.71111637],
                            [39.74690033, -7.71094571],
                            [39.74715623, -7.71086038],
                            [39.74736948, -7.71090305],
                            [39.74766803, -7.71094572],
                            [39.74792393, -7.71081772],
                            [39.74813718, -7.7107324],
                            [39.74830778, -7.71068973],
                            [39.74843574, -7.71056174],
                            [39.74869164, -7.71056174],
                            [39.74877694, -7.71039108],
                            [39.74886224, -7.71026309],
                            [39.74894754, -7.71009243],
                            [39.74907549, -7.71004977],
                            [39.74924609, -7.7100071],
                            [39.74941669, -7.70987911],
                            [39.74950199, -7.70975112],
                            [39.74962994, -7.70949514],
                            [39.74980054, -7.70936714],
                            [39.7500138, -7.70919649],
                            [39.7500302, -7.70915382],
                            [39.75022704, -7.70915383],
                            [39.75039765, -7.70919649],
                            [39.75065355, -7.7089405],
                            [39.7507815, -7.70889784],
                            [39.7508668, -7.70872718],
                            [39.75090945, -7.70859919],
                            [39.751208, -7.70868452],
                            [39.7514639, -7.70872719],
                            [39.7517198, -7.70868452],
                            [39.7518051, -7.7084712],
                            [39.7518904, -7.70830054],
                            [39.7518904, -7.70804455],
                            [39.75214631, -7.7080019],
                            [39.7522316, -7.7078739],
                            [39.75248751, -7.70778857],
                            [39.75278606, -7.70757526],
                            [39.75287136, -7.7074046],
                            [39.75287136, -7.70719128],
                            [39.75287136, -7.70693529],
                            [39.75304196, -7.70689262],
                            [39.75299931, -7.70672197],
                            [39.75291402, -7.70646598],
                            [39.75291402, -7.70625266],
                            [39.75308461, -7.70616733],
                            [39.75321257, -7.70595401],
                            [39.75329787, -7.70582602],
                            [39.75316992, -7.70557003],
                            [39.75329787, -7.70531404],
                            [39.75338317, -7.70522872],
                            [39.75329787, -7.70493006],
                            [39.75308462, -7.70467407],
                            [39.75308462, -7.70446075],
                            [39.75308463, -7.70424743],
                            [39.75316993, -7.70394878],
                            [39.75295668, -7.70382078],
                            [39.75278608, -7.70369279],
                            [39.75244488, -7.70356479],
                            [39.75248753, -7.7033088],
                            [39.75248754, -7.70318082],
                            [39.75240224, -7.70301015],
                            [39.75257283, -7.70292483],
                            [39.75282874, -7.70288216],
                            [39.75295669, -7.70275417],
                            [39.75312729, -7.70258351],
                            [39.75325524, -7.70241286],
                            [39.75312729, -7.70219953],
                            [39.75299934, -7.70207154],
                            [39.75291405, -7.70181555],
                            [39.75304199, -7.70173022],
                            [39.75291405, -7.70155957],
                            [39.7528714, -7.70138891],
                            [39.75304199, -7.70130358],
                            [39.753042, -7.70109026],
                            [39.75291405, -7.70087693],
                            [39.75282875, -7.7009196],
                            [39.75248755, -7.70087693],
                            [39.7526155, -7.70066361],
                            [39.7527008, -7.70049295],
                            [39.7527861, -7.70036496],
                            [39.75274345, -7.70015164],
                            [39.75265815, -7.70002365],
                            [39.75235961, -7.69985298],
                            [39.75214636, -7.69972499],
                            [39.75210371, -7.69951167],
                            [39.75210371, -7.69917035],
                            [39.75201841, -7.69895703],
                            [39.75171986, -7.6988717],
                            [39.75150661, -7.69895703],
                            [39.75129336, -7.69908502],
                            [39.75108011, -7.69917035],
                            [39.75095216, -7.69912768],
                            [39.75086686, -7.69899969],
                            [39.75086686, -7.69882903],
                            [39.75065361, -7.69878636],
                            [39.75035506, -7.69878636],
                            [39.75005651, -7.6986157],
                            [39.75009916, -7.69844505],
                            [39.75005652, -7.69827439],
                            [39.74988591, -7.69827438],
                            [39.74958736, -7.69831705],
                            [39.74937411, -7.69831705],
                            [39.74920351, -7.69818905],
                            [39.74903291, -7.69810372],
                            [39.74873436, -7.69818905],
                            [39.74852112, -7.69810372],
                            [39.74839316, -7.69797573],
                            [39.74817992, -7.69793306],
                            [39.74800931, -7.69797572],
                            [39.74783871, -7.69789039],
                            [39.74788136, -7.6977624],
                            [39.74779606, -7.69763441],
                            [39.74758281, -7.69763441],
                            [39.74736956, -7.69771973],
                            [39.74724161, -7.69789039],
                            [39.74702836, -7.69793305],
                            [39.74681511, -7.69801838],
                            [39.74672981, -7.69797572],
                            [39.74668716, -7.69776239],
                            [39.74668716, -7.6976344],
                            [39.74655922, -7.6975064],
                            [39.74647392, -7.69742107],
                            [39.74630331, -7.69737841],
                            [39.74617537, -7.69737841],
                            [39.74600477, -7.69742107],
                            [39.74583417, -7.69746374],
                            [39.74566357, -7.69742107],
                            [39.74549296, -7.69733574],
                            [39.74527972, -7.69733574],
                            [39.74502381, -7.69720774],
                            [39.74489587, -7.69707975],
                            [39.74463997, -7.69712241],
                            [39.74451202, -7.69720774],
                            [39.74425612, -7.6972504],
                            [39.74408552, -7.69716507],
                            [39.74391491, -7.69707974],
                            [39.74370167, -7.69707974],
                            [39.74357372, -7.69720774],
                            [39.74340311, -7.69737839],
                            [39.74314721, -7.69733572],
                            [39.74284866, -7.69742105],
                            [39.74272072, -7.69725039],
                            [39.74259277, -7.69716507],
                            [39.74237951, -7.69716506],
                            [39.74233686, -7.69737839],
                            [39.74216627, -7.69742105],
                            [39.74199566, -7.69742105],
                            [39.74195301, -7.69763437],
                            [39.74195301, -7.69780503],
                            [39.74186771, -7.69801835],
                            [39.74182506, -7.69814634],
                            [39.74161181, -7.69810367],
                            [39.74139856, -7.69806101],
                            [39.74110001, -7.69797568],
                            [39.74097206, -7.69797568],
                            [39.74084411, -7.69801834],
                            [39.74084411, -7.698189],
                            [39.74080146, -7.69835966],
                            [39.74071616, -7.69840232],
                            [39.7407588, -7.69853031],
                            [39.7408441, -7.69874364],
                            [39.74063086, -7.69904229],
                            [39.74046025, -7.69917028],
                            [39.7403323, -7.69929827],
                            [39.7401617, -7.69946893],
                            [39.7401617, -7.69976758],
                            [39.74020435, -7.69985291],
                            [39.7400764, -7.70019422],
                            [39.73999109, -7.70045021],
                            [39.73977784, -7.7005782],
                            [39.73960724, -7.70074886],
                            [39.73943664, -7.70096218],
                            [39.73951344, -7.7010219],
                            [39.73930869, -7.70109017],
                            [39.73913809, -7.70091951],
                            [39.73879689, -7.70066352],
                            [39.73854099, -7.70053553],
                            [39.73837039, -7.70074885],
                            [39.73832774, -7.70091951],
                            [39.73841304, -7.70113283],
                            [39.73828509, -7.70134615],
                            [39.73798654, -7.70138882],
                            [39.73785859, -7.70109017],
                            [39.73768799, -7.70083418],
                            [39.73751739, -7.70074885],
                            [39.73730414, -7.70074884],
                            [39.73717619, -7.7009195],
                            [39.73709089, -7.70109016],
                            [39.73687764, -7.70109016],
                            [39.73670704, -7.70083417],
                            [39.73653644, -7.70070618],
                            [39.73632319, -7.70049285],
                            [39.73610994, -7.70049285],
                            [39.73593934, -7.70062084],
                            [39.73564079, -7.70053551],
                            [39.73525694, -7.70062084],
                            [39.73495839, -7.70053551],
                            [39.73470249, -7.7006635],
                            [39.73461719, -7.70074883],
                            [39.73448924, -7.7006635],
                            [39.73440394, -7.70049284],
                            [39.73410539, -7.70045017],
                            [39.73393479, -7.70062083],
                            [39.73380684, -7.70083415],
                            [39.73376418, -7.70104748],
                            [39.73363624, -7.70109014],
                            [39.73338034, -7.70109014],
                            [39.73325238, -7.7010048],
                            [39.73312444, -7.70083415],
                            [39.73291119, -7.70074882],
                            [39.73256999, -7.70074881],
                            [39.73235674, -7.70074881],
                            [39.73218613, -7.70096213],
                            [39.73197288, -7.70113279],
                            [39.73193023, -7.70134611],
                            [39.73197288, -7.7016021],
                            [39.73193023, -7.70177276],
                            [39.73193022, -7.70211407],
                            [39.73180228, -7.70241273],
                            [39.73188757, -7.70254072],
                            [39.73197287, -7.70271138],
                            [39.73214347, -7.7029247],
                            [39.73231407, -7.70305269],
                            [39.73227142, -7.70322335],
                            [39.73214347, -7.70343667],
                            [39.73227142, -7.70365],
                            [39.73235672, -7.70373533],
                            [39.73248466, -7.70394865],
                            [39.73235671, -7.70416197],
                            [39.73222876, -7.70441796],
                            [39.73227142, -7.70458861],
                            [39.73239936, -7.70471661],
                            [39.73239936, -7.70488726],
                            [39.73248466, -7.70522859],
                            [39.73274056, -7.70531391],
                            [39.7326979, -7.7055699],
                            [39.73265526, -7.70582589],
                            [39.73274055, -7.70599655],
                            [39.73282585, -7.70625253],
                            [39.73291115, -7.70646585],
                            [39.73316705, -7.7062952],
                            [39.7333803, -7.70638053],
                            [39.73350825, -7.70659385],
                            [39.7337215, -7.70667918],
                            [39.73384945, -7.70684984],
                            [39.73402005, -7.70684984],
                            [39.73419065, -7.70684984],
                            [39.7344039, -7.70684984],
                            [39.7345745, -7.7070205],
                            [39.73453185, -7.70719116],
                            [39.73478774, -7.70740448],
                            [39.73495835, -7.70740449],
                            [39.7351716, -7.70727649],
                            [39.73538485, -7.70727649],
                            [39.73555545, -7.70723383],
                            [39.7357687, -7.70719117],
                            [39.7359393, -7.70723383],
                            [39.7361952, -7.70731916],
                            [39.73649375, -7.7072765],
                            [39.736707, -7.70723384],
                            [39.7368776, -7.70714851],
                            [39.73700555, -7.70702052],
                            [39.73709085, -7.7068072],
                            [39.73709085, -7.70655121],
                            [39.73709085, -7.70642321],
                            [39.73730411, -7.70638055],
                            [39.73743205, -7.70646588],
                            [39.73756, -7.70638056],
                            [39.73781591, -7.70629523],
                            [39.73807181, -7.70638056],
                            [39.7381571, -7.70659388],
                            [39.73837035, -7.70672188],
                            [39.7384983, -7.7069352],
                            [39.7385836, -7.70706319],
                            [39.7387542, -7.70719119],
                            [39.73896745, -7.70719119],
                            [39.73913805, -7.70727651],
                            [39.7393513, -7.70740451],
                            [39.73947925, -7.70757517],
                            [39.73947924, -7.70774583],
                            [39.73943659, -7.70791649],
                            [39.7396072, -7.70812981],
                            [39.7397778, -7.70804448],
                            [39.73999104, -7.70804448],
                            [39.74033225, -7.70804448],
                            [39.74050285, -7.70808715],
                            [39.74054549, -7.70830047],
                            [39.74041754, -7.70842847],
                            [39.74020429, -7.70864179],
                            [39.74007634, -7.70889777],
                            [39.74003369, -7.70911109],
                            [39.73999104, -7.70936708],
                            [39.73999103, -7.7095804],
                            [39.73999103, -7.70979373],
                            [39.73994838, -7.70996439],
                            [39.73990573, -7.71022037],
                            [39.73990573, -7.71039102],
                            [39.73994838, -7.71060435],
                            [39.74003368, -7.71077501],
                            [39.74020428, -7.710903],
                            [39.74020428, -7.71107366],
                            [39.74016163, -7.71124432],
                            [39.74007633, -7.71141498],
                            [39.74024692, -7.71158564],
                            [39.74041752, -7.71167096],
                            [39.74058812, -7.71171363],
                            [39.74075872, -7.71179896],
                            [39.74097197, -7.71184163],
                            [39.74114257, -7.71192696],
                            [39.74135582, -7.71205495],
                            [39.74148377, -7.71222561],
                            [39.74161172, -7.71235361],
                            [39.74186762, -7.71243893],
                            [39.74208087, -7.71235361],
                            [39.74225147, -7.71222561],
                            [39.74233677, -7.71209762],
                            [39.74255002, -7.71201229],
                            [39.74280592, -7.7120123],
                            [39.74297653, -7.71209762],
                            [39.74323242, -7.71209763],
                            [39.74336037, -7.71192697],
                        ],
                        [
                            [39.67827833, -7.68924663],
                            [39.67837093, -7.68906138],
                            [39.67858697, -7.689154],
                            [39.67877216, -7.68930838],
                            [39.67886475, -7.68918488],
                            [39.67895734, -7.68909226],
                            [39.67938943, -7.68918488],
                            [39.67969807, -7.68921576],
                            [39.67982153, -7.68903051],
                            [39.67997585, -7.6888144],
                            [39.68013017, -7.68872177],
                            [39.68034622, -7.68853653],
                            [39.6805314, -7.68847478],
                            [39.68068572, -7.68844391],
                            [39.68068572, -7.68819691],
                            [39.68062399, -7.68794992],
                            [39.68068572, -7.68776467],
                            [39.68068572, -7.68754855],
                            [39.68077832, -7.68736331],
                            [39.68084005, -7.68723981],
                            [39.68108696, -7.68730156],
                            [39.681303, -7.68739419],
                            [39.68145732, -7.68751768],
                            [39.68154991, -7.68745594],
                            [39.68154991, -7.68723982],
                            [39.68145732, -7.68705457],
                            [39.68158078, -7.68696195],
                            [39.6817351, -7.68708545],
                            [39.68195114, -7.68736332],
                            [39.68213633, -7.68751769],
                            [39.68232151, -7.68739419],
                            [39.68238324, -7.68723982],
                            [39.68253756, -7.68736332],
                            [39.68247583, -7.68757944],
                            [39.68256842, -7.68785731],
                            [39.68278446, -7.68801168],
                            [39.68306224, -7.68801168],
                            [39.68318569, -7.68785731],
                            [39.6830931, -7.68770294],
                            [39.68327829, -7.68770294],
                            [39.68349433, -7.68770294],
                            [39.68355606, -7.68754857],
                            [39.68367952, -7.6873942],
                            [39.6838647, -7.68717808],
                            [39.68398816, -7.68717808],
                            [39.68423507, -7.68717809],
                            [39.68442025, -7.68711634],
                            [39.68442025, -7.68690022],
                            [39.68445112, -7.68674585],
                            [39.68454371, -7.68665322],
                            [39.68479062, -7.68652973],
                            [39.68503753, -7.68649886],
                            [39.68525358, -7.68625186],
                            [39.68528445, -7.68594312],
                            [39.68534618, -7.68566525],
                            [39.68531531, -7.68548001],
                            [39.68543877, -7.68526389],
                            [39.68559309, -7.68517127],
                            [39.68568568, -7.68498602],
                            [39.68596346, -7.68483165],
                            [39.68621037, -7.68480078],
                            [39.68633383, -7.6847699],
                            [39.68642642, -7.68461553],
                            [39.68636469, -7.68446116],
                            [39.68645728, -7.68439941],
                            [39.68651901, -7.68424504],
                            [39.68654988, -7.6840598],
                            [39.68673506, -7.68402892],
                            [39.68698197, -7.6840598],
                            [39.68719802, -7.6839363],
                            [39.6873832, -7.68384368],
                            [39.68766098, -7.68331882],
                            [39.68775357, -7.6829792],
                            [39.68769185, -7.68270134],
                            [39.68753753, -7.68251609],
                            [39.68759926, -7.68226909],
                            [39.68766099, -7.6821456],
                            [39.68806222, -7.6822691],
                            [39.68827826, -7.68217648],
                            [39.68840172, -7.68208385],
                            [39.68843258, -7.68186773],
                            [39.68830913, -7.68155899],
                            [39.68806222, -7.68149724],
                            [39.68800049, -7.6811885],
                            [39.68775359, -7.68100325],
                            [39.68769186, -7.68091063],
                            [39.6875684, -7.68069451],
                            [39.68759927, -7.68038576],
                            [39.68759927, -7.68023139],
                            [39.68775359, -7.68013877],
                            [39.68796964, -7.6801079],
                            [39.68815482, -7.67992265],
                            [39.68834001, -7.67973741],
                            [39.68864865, -7.67952129],
                            [39.68874124, -7.67939779],
                            [39.68867951, -7.6792743],
                            [39.68883383, -7.6792743],
                            [39.68914247, -7.6792743],
                            [39.68935852, -7.67921255],
                            [39.68966716, -7.67911993],
                            [39.6898832, -7.67905818],
                            [39.68991407, -7.67881119],
                            [39.68991407, -7.67847157],
                            [39.6899758, -7.67825545],
                            [39.69003753, -7.67803933],
                            [39.69003753, -7.67779234],
                            [39.69003753, -7.67754534],
                            [39.69000667, -7.6772366],
                            [39.6900684, -7.67698961],
                            [39.6900684, -7.67674261],
                            [39.68994495, -7.67655737],
                            [39.68975976, -7.67624862],
                            [39.68982149, -7.67597075],
                            [39.68991409, -7.67581638],
                            [39.6898215, -7.67556939],
                            [39.6898215, -7.67529152],
                            [39.68957459, -7.6749519],
                            [39.68951286, -7.67476666],
                            [39.68957459, -7.67455054],
                            [39.68954373, -7.67436529],
                            [39.68942028, -7.67418004],
                            [39.68942028, -7.67390218],
                            [39.68938941, -7.67368606],
                            [39.68936871, -7.67343755],
                            [39.68935855, -7.67331556],
                            [39.68920424, -7.67303769],
                            [39.68901905, -7.67282157],
                            [39.68886474, -7.67251283],
                            [39.68864869, -7.67220408],
                            [39.68840178, -7.67195709],
                            [39.68824746, -7.67198796],
                            [39.68793882, -7.67204971],
                            [39.6878771, -7.67192621],
                            [39.68772278, -7.67174096],
                            [39.68741414, -7.67177184],
                            [39.68722896, -7.67186446],
                            [39.6871055, -7.67198796],
                            [39.68688946, -7.67177183],
                            [39.68679686, -7.67161746],
                            [39.68673514, -7.67155571],
                            [39.6864265, -7.67149396],
                            [39.68636477, -7.67121609],
                            [39.68627218, -7.6709691],
                            [39.68621046, -7.67078385],
                            [39.68593268, -7.67078385],
                            [39.68580923, -7.6705986],
                            [39.68565491, -7.67050598],
                            [39.68546973, -7.67053685],
                            [39.68525368, -7.6707221],
                            [39.68503763, -7.67081472],
                            [39.68485245, -7.67093822],
                            [39.6846364, -7.67124696],
                            [39.68442035, -7.67161745],
                            [39.68432776, -7.67189532],
                            [39.68438948, -7.67208056],
                            [39.6845438, -7.67211144],
                            [39.68460553, -7.67226581],
                            [39.68445121, -7.67245105],
                            [39.68432775, -7.67257455],
                            [39.68445121, -7.6727598],
                            [39.6844512, -7.67300679],
                            [39.68448207, -7.67325378],
                            [39.68460552, -7.67340816],
                            [39.68482157, -7.67353166],
                            [39.68469811, -7.67365515],
                            [39.68451293, -7.67368602],
                            [39.68451293, -7.67393302],
                            [39.68463638, -7.67427264],
                            [39.68472897, -7.67455051],
                            [39.68463638, -7.674674],
                            [39.68445119, -7.67476662],
                            [39.68442033, -7.67498274],
                            [39.68442033, -7.67516799],
                            [39.68448205, -7.67538411],
                            [39.68438946, -7.67560023],
                            [39.68423514, -7.67566198],
                            [39.68408082, -7.67578547],
                            [39.68404996, -7.67600159],
                            [39.68398823, -7.67621771],
                            [39.68389563, -7.67640296],
                            [39.68371045, -7.67643383],
                            [39.68358699, -7.67668082],
                            [39.68352526, -7.67698957],
                            [39.68352526, -7.67717481],
                            [39.68352526, -7.67732918],
                            [39.68327835, -7.67739093],
                            [39.6830623, -7.67726743],
                            [39.68290798, -7.67736005],
                            [39.68269194, -7.6775453],
                            [39.68250675, -7.67760705],
                            [39.68222898, -7.67748355],
                            [39.68204379, -7.67769967],
                            [39.68185861, -7.67800841],
                            [39.68167343, -7.6781319],
                            [39.6816117, -7.67794666],
                            [39.68139565, -7.67800841],
                            [39.68121047, -7.6782554],
                            [39.68105615, -7.67840977],
                            [39.68080924, -7.67853326],
                            [39.6805006, -7.67878026],
                            [39.68037714, -7.6789655],
                            [39.68037714, -7.6792125],
                            [39.68046973, -7.67939774],
                            [39.68037714, -7.67961386],
                            [39.68025368, -7.67967561],
                            [39.68016109, -7.67986085],
                            [39.68003763, -7.68013872],
                            [39.67985245, -7.68044746],
                            [39.67979072, -7.68063271],
                            [39.67966726, -7.68084883],
                            [39.67945121, -7.68081795],
                            [39.67932776, -7.68072533],
                            [39.67911171, -7.68078708],
                            [39.67898825, -7.68097232],
                            [39.67895739, -7.68112669],
                            [39.67877221, -7.68118844],
                            [39.67867961, -7.68140456],
                            [39.67867961, -7.68174418],
                            [39.67858702, -7.68192942],
                            [39.67837097, -7.68199117],
                            [39.67830924, -7.68236166],
                            [39.67840183, -7.68263953],
                            [39.67827837, -7.68282477],
                            [39.67812406, -7.68282477],
                            [39.67796974, -7.68270128],
                            [39.67784628, -7.6827939],
                            [39.67778455, -7.68297914],
                            [39.6776611, -7.68313351],
                            [39.67741418, -7.68325701],
                            [39.67725986, -7.68341138],
                            [39.67707468, -7.68328788],
                            [39.67701295, -7.68319526],
                            [39.67695123, -7.68297914],
                            [39.67679691, -7.68288651],
                            [39.67664259, -7.68291739],
                            [39.67648827, -7.68291739],
                            [39.67636482, -7.68291739],
                            [39.67617963, -7.68294826],
                            [39.6761179, -7.68310263],
                            [39.67617963, -7.68328788],
                            [39.67617963, -7.683504],
                            [39.67617963, -7.68365837],
                            [39.67605617, -7.68381274],
                            [39.67596358, -7.68396711],
                            [39.67590185, -7.68415235],
                            [39.67599444, -7.68430673],
                            [39.67608703, -7.68455372],
                            [39.67614876, -7.68464634],
                            [39.67614876, -7.68483159],
                            [39.67596357, -7.68486246],
                            [39.67584012, -7.68492421],
                            [39.67577839, -7.68520208],
                            [39.6756858, -7.68538732],
                            [39.67546975, -7.6854182],
                            [39.67534629, -7.68523295],
                            [39.6752537, -7.68501683],
                            [39.67525371, -7.68480071],
                            [39.67497593, -7.68476983],
                            [39.67472902, -7.68486245],
                            [39.6745747, -7.68467721],
                            [39.67442038, -7.68458458],
                            [39.6742352, -7.68470808],
                            [39.6742352, -7.6849242],
                            [39.67401915, -7.68483158],
                            [39.67380311, -7.68464633],
                            [39.67352533, -7.68470808],
                            [39.67327842, -7.68483157],
                            [39.67300064, -7.68507856],
                            [39.67306237, -7.68504769],
                            [39.67290805, -7.68523293],
                            [39.67287719, -7.68538731],
                            [39.67287718, -7.68560343],
                            [39.67272286, -7.68578867],
                            [39.67290805, -7.68597392],
                            [39.67312409, -7.68600479],
                            [39.67309323, -7.68612829],
                            [39.67312409, -7.68631354],
                            [39.67324755, -7.68637529],
                            [39.67352532, -7.68649878],
                            [39.67367964, -7.68646791],
                            [39.67367964, -7.68659141],
                            [39.67367964, -7.68690015],
                            [39.67398827, -7.68693103],
                            [39.67414259, -7.68705453],
                            [39.67414259, -7.68723977],
                            [39.67429691, -7.68742502],
                            [39.67445123, -7.68767201],
                            [39.67454382, -7.68788813],
                            [39.67460554, -7.68810425],
                            [39.67469813, -7.68838212],
                            [39.67485245, -7.6885365],
                            [39.67497591, -7.68875262],
                            [39.67503763, -7.68890699],
                            [39.67525368, -7.68906136],
                            [39.67556232, -7.68906136],
                            [39.67559318, -7.68921573],
                            [39.67577836, -7.68937011],
                            [39.67602527, -7.68946273],
                            [39.67621046, -7.68940098],
                            [39.67654996, -7.68940099],
                            [39.67664255, -7.68952448],
                            [39.67688946, -7.68955536],
                            [39.67725983, -7.68955536],
                            [39.67738328, -7.68940099],
                            [39.67750674, -7.68933924],
                            [39.67781538, -7.68937012],
                            [39.67809315, -7.68946274],
                            [39.67826158, -7.68926618],
                            [39.67827833, -7.68924663],
                        ],
                        [
                            [39.22475242, -7.92712614],
                            [39.22448518, -7.92737481],
                            [39.22436086, -7.92758617],
                            [39.22431114, -7.92787215],
                            [39.22421169, -7.92815812],
                            [39.22414331, -7.92836327],
                            [39.22396307, -7.92866167],
                            [39.22378905, -7.92896629],
                            [39.22363986, -7.92928335],
                            [39.22354663, -7.92955688],
                            [39.22354663, -7.92980555],
                            [39.22367715, -7.9300107],
                            [39.22382632, -7.93022207],
                            [39.22399414, -7.93033396],
                            [39.2241806, -7.93037749],
                            [39.22446651, -7.93036505],
                            [39.22466539, -7.93037127],
                            [39.2249131, -7.93049567],
                            [39.22488914, -7.93044588],
                            [39.22504452, -7.93068211],
                            [39.2251937, -7.93091834],
                            [39.22534286, -7.93116702],
                            [39.22561012, -7.93134108],
                            [39.22593953, -7.93137838],
                            [39.22616328, -7.93132866],
                            [39.22631245, -7.93117946],
                            [39.22637461, -7.93092457],
                            [39.22639947, -7.9307132],
                            [39.22636217, -7.93042723],
                            [39.22636839, -7.93022208],
                            [39.2262503, -7.92986151],
                            [39.22619437, -7.92951959],
                            [39.22611976, -7.92924605],
                            [39.2260887, -7.92892899],
                            [39.22610114, -7.92863059],
                            [39.22611977, -7.92846895],
                            [39.22623787, -7.92825138],
                            [39.2263311, -7.92811461],
                            [39.22646785, -7.92794055],
                            [39.22660458, -7.92771674],
                            [39.22661702, -7.92750537],
                            [39.22652379, -7.92728778],
                            [39.22635597, -7.92715102],
                            [39.22623788, -7.92703911],
                            [39.22618816, -7.9268464],
                            [39.22611357, -7.9266226],
                            [39.22598927, -7.92648582],
                            [39.22584632, -7.92639879],
                            [39.22568472, -7.92639879],
                            [39.22553555, -7.92644855],
                            [39.22535531, -7.92652312],
                            [39.22516264, -7.92667854],
                            [39.22501346, -7.9268464],
                            [39.22475242, -7.92712614],
                        ],
                        [
                            [39.33558195, -8.2685139],
                            [39.33574538, -8.2685248],
                            [39.33573447, -8.26874274],
                            [39.33572358, -8.26915684],
                            [39.33575626, -8.26940748],
                            [39.33583252, -8.2696581],
                            [39.33594146, -8.26988694],
                            [39.33599594, -8.27020297],
                            [39.33598505, -8.27056258],
                            [39.33594145, -8.27091129],
                            [39.33593058, -8.27123821],
                            [39.33590878, -8.27137984],
                            [39.33578894, -8.27136897],
                            [39.33563642, -8.2712491],
                            [39.33546209, -8.27115102],
                            [39.33525507, -8.27115102],
                            [39.3350372, -8.27118371],
                            [39.33484109, -8.2712273],
                            [39.33459052, -8.27124909],
                            [39.33437262, -8.27135807],
                            [39.33417652, -8.27135807],
                            [39.33393683, -8.27140165],
                            [39.33368624, -8.27144524],
                            [39.3333703, -8.2715651],
                            [39.33304345, -8.27170677],
                            [39.33294541, -8.27189202],
                            [39.33290182, -8.27212086],
                            [39.33296719, -8.2723715],
                            [39.33304345, -8.27245867],
                            [39.33319597, -8.27257855],
                            [39.33344656, -8.27261123],
                            [39.33364266, -8.27256765],
                            [39.3337843, -8.27248048],
                            [39.33395862, -8.27243689],
                            [39.33416561, -8.27239331],
                            [39.33436171, -8.27230612],
                            [39.33455783, -8.27231703],
                            [39.33477571, -8.2723606],
                            [39.33490645, -8.27242599],
                            [39.33515704, -8.27254587],
                            [39.33539672, -8.27245868],
                            [39.33553835, -8.2723824],
                            [39.33578893, -8.27252407],
                            [39.33591966, -8.27274202],
                            [39.33612667, -8.27293817],
                            [39.33639903, -8.27305803],
                            [39.33657335, -8.27306894],
                            [39.33679125, -8.27290547],
                            [39.33705273, -8.27272022],
                            [39.33716168, -8.27253497],
                            [39.33727061, -8.2724151],
                            [39.33728152, -8.27218627],
                            [39.33713988, -8.27200101],
                            [39.33700912, -8.27189204],
                            [39.33698736, -8.27178306],
                            [39.33719435, -8.27161961],
                            [39.33727062, -8.27147795],
                            [39.33753209, -8.27127091],
                            [39.33784805, -8.27102027],
                            [39.33810952, -8.27076963],
                            [39.3381749, -8.27048631],
                            [39.33818577, -8.27001771],
                            [39.33809864, -8.26946197],
                            [39.33804416, -8.26904789],
                            [39.33794612, -8.26889531],
                            [39.33780448, -8.26874275],
                            [39.33761927, -8.26861198],
                            [39.33754301, -8.268503],
                            [39.33741226, -8.26826326],
                            [39.33737958, -8.26803443],
                            [39.33732511, -8.2677184],
                            [39.33725974, -8.26730431],
                            [39.33722706, -8.26699921],
                            [39.33721617, -8.26661779],
                            [39.33710722, -8.26621458],
                            [39.33698739, -8.26595306],
                            [39.33676949, -8.26570242],
                            [39.33656248, -8.26546269],
                            [39.3363446, -8.26528832],
                            [39.33619208, -8.26508129],
                            [39.33613758, -8.26489603],
                            [39.33629012, -8.26475435],
                            [39.33629012, -8.26447103],
                            [39.33622475, -8.2642313],
                            [39.33596328, -8.26391529],
                            [39.33571271, -8.26370823],
                            [39.33539676, -8.26347939],
                            [39.33522245, -8.26327234],
                            [39.33502634, -8.26307619],
                            [39.33484112, -8.26284735],
                            [39.3347104, -8.26266209],
                            [39.33457964, -8.26250953],
                            [39.3344707, -8.26247684],
                            [39.33437266, -8.26249864],
                            [39.33436175, -8.26258581],
                            [39.33437266, -8.26273837],
                            [39.33448159, -8.26298901],
                            [39.33462324, -8.26332683],
                            [39.33473217, -8.26363195],
                            [39.33481934, -8.26396976],
                            [39.33492829, -8.26439475],
                            [39.33491738, -8.26489603],
                            [39.33485202, -8.26527742],
                            [39.33480843, -8.26574601],
                            [39.33479753, -8.26608382],
                            [39.33478007, -8.26612872],
                            [39.33478664, -8.26645433],
                            [39.33478664, -8.26681393],
                            [39.33473216, -8.26734789],
                            [39.33467767, -8.26782737],
                            [39.33466679, -8.26833954],
                            [39.33467767, -8.26866646],
                            [39.33471037, -8.26893889],
                            [39.33478663, -8.26916773],
                            [39.3348411, -8.26938567],
                            [39.33492826, -8.26952734],
                            [39.33509168, -8.26956003],
                            [39.33521152, -8.26948375],
                            [39.33529869, -8.26916773],
                            [39.33534227, -8.2688735],
                            [39.33546211, -8.26863377],
                            [39.33558195, -8.2685139],
                        ],
                        [
                            [39.59693737, -8.27550552],
                            [39.59941352, -8.27521299],
                            [39.60140467, -8.27495927],
                            [39.60429224, -8.27421616],
                            [39.60616132, -8.27337576],
                            [39.60851487, -8.27154706],
                            [39.60930828, -8.26962869],
                            [39.60925778, -8.26947415],
                            [39.60867891, -8.26770269],
                            [39.60797236, -8.26727141],
                            [39.60674817, -8.26652415],
                            [39.60626437, -8.26622883],
                            [39.60418167, -8.2667802],
                            [39.60288473, -8.26945123],
                            [39.60057314, -8.27126464],
                            [39.59862012, -8.27209838],
                            [39.5961636, -8.27354739],
                            [39.59671669, -8.27553159],
                            [39.59693737, -8.27550552],
                        ],
                        [
                            [39.34373805, -8.27333874],
                            [39.3438613, -8.27344146],
                            [39.34410777, -8.2735031],
                            [39.34443642, -8.27349282],
                            [39.34469317, -8.27340038],
                            [39.34493965, -8.27325656],
                            [39.34516558, -8.27306138],
                            [39.34535045, -8.27274297],
                            [39.34546343, -8.2723834],
                            [39.34553532, -8.27200335],
                            [39.34554558, -8.27167463],
                            [39.34558666, -8.27134593],
                            [39.34550451, -8.27117129],
                            [39.34544289, -8.27099666],
                            [39.34531964, -8.27075013],
                            [39.34516559, -8.27045223],
                            [39.34500128, -8.2702776],
                            [39.34483696, -8.27007216],
                            [39.34470345, -8.26994888],
                            [39.34455968, -8.26983589],
                            [39.34436454, -8.26980507],
                            [39.34416941, -8.26980507],
                            [39.34399482, -8.26984616],
                            [39.3440734, -8.26992694],
                            [39.3436502, -8.26999339],
                            [39.34366846, -8.26975598],
                            [39.3436137, -8.26940901],
                            [39.34346763, -8.26911682],
                            [39.3432668, -8.26884289],
                            [39.34302945, -8.26860549],
                            [39.34286514, -8.26849592],
                            [39.34259126, -8.26842287],
                            [39.34239043, -8.26811241],
                            [39.34198877, -8.26803936],
                            [39.34183812, -8.26803822],
                            [39.34172517, -8.26805877],
                            [39.341643, -8.26820258],
                            [39.34155057, -8.2683053],
                            [39.34156085, -8.26846966],
                            [39.34167381, -8.2686032],
                            [39.34182787, -8.26863402],
                            [39.34197164, -8.26863402],
                            [39.34208461, -8.26874701],
                            [39.34206408, -8.26886001],
                            [39.34192029, -8.26894218],
                            [39.34186894, -8.26907572],
                            [39.3418484, -8.26918871],
                            [39.34194083, -8.26928117],
                            [39.34219759, -8.26926063],
                            [39.34245433, -8.26920927],
                            [39.34263919, -8.26919898],
                            [39.34280352, -8.26921954],
                            [39.34292673, -8.26929146],
                            [39.34303972, -8.26941473],
                            [39.34311161, -8.26964071],
                            [39.34310134, -8.26979478],
                            [39.3430808, -8.26993861],
                            [39.34303972, -8.27013378],
                            [39.34296783, -8.27029813],
                            [39.34292672, -8.27046249],
                            [39.34293702, -8.27072957],
                            [39.34299862, -8.27098638],
                            [39.34312187, -8.27120209],
                            [39.34316294, -8.27132537],
                            [39.34322457, -8.27144864],
                            [39.34325538, -8.27159245],
                            [39.34329645, -8.27172599],
                            [39.34329645, -8.2718698],
                            [39.34315268, -8.27189035],
                            [39.34301917, -8.27176707],
                            [39.34288566, -8.27167462],
                            [39.34274188, -8.27157189],
                            [39.34260837, -8.27142809],
                            [39.34247486, -8.27132536],
                            [39.34234135, -8.27119182],
                            [39.34217703, -8.27119182],
                            [39.34205379, -8.27126372],
                            [39.34196137, -8.271387],
                            [39.3419819, -8.27156162],
                            [39.34204352, -8.2718287],
                            [39.34216676, -8.27189034],
                            [39.3423208, -8.27192116],
                            [39.34246459, -8.27195198],
                            [39.34251594, -8.27199307],
                            [39.3425981, -8.27212661],
                            [39.34268026, -8.27228068],
                            [39.34277269, -8.27234232],
                            [39.34295754, -8.27243477],
                            [39.34316294, -8.27249641],
                            [39.34326564, -8.27256832],
                            [39.34327592, -8.27269157],
                            [39.34320403, -8.27281486],
                            [39.34302943, -8.27282511],
                            [39.34281376, -8.27282511],
                            [39.3425981, -8.27282511],
                            [39.34240297, -8.27287648],
                            [39.34222838, -8.2729792],
                            [39.34193055, -8.27303055],
                            [39.34153002, -8.27317439],
                            [39.34120137, -8.27329763],
                            [39.34089328, -8.27332845],
                            [39.34059544, -8.27331818],
                            [39.34029761, -8.27329763],
                            [39.34009221, -8.27321545],
                            [39.33980466, -8.27313328],
                            [39.33953764, -8.27306136],
                            [39.33936306, -8.27298945],
                            [39.33896253, -8.27291755],
                            [39.33873658, -8.27281484],
                            [39.3384182, -8.27279429],
                            [39.33832579, -8.27285591],
                            [39.33827443, -8.27305109],
                            [39.33830524, -8.27320518],
                            [39.3384182, -8.27333872],
                            [39.33857226, -8.27350308],
                            [39.33877766, -8.27355443],
                            [39.33899333, -8.27363662],
                            [39.33925007, -8.27367771],
                            [39.33957872, -8.27364689],
                            [39.33989708, -8.27364689],
                            [39.34017437, -8.27367771],
                            [39.34032842, -8.27378043],
                            [39.34071869, -8.27388316],
                            [39.3409549, -8.2739448],
                            [39.34122192, -8.27395506],
                            [39.34153001, -8.27396533],
                            [39.34185866, -8.27385234],
                            [39.34223864, -8.27370854],
                            [39.3426597, -8.27354418],
                            [39.34292671, -8.27337982],
                            [39.34311159, -8.27326684],
                            [39.3432451, -8.27325655],
                            [39.34337862, -8.27324629],
                            [39.34356347, -8.27330792],
                            [39.34373805, -8.27333874],
                        ],
                        [
                            [39.32103959, -8.28756766],
                            [39.32127043, -8.28789955],
                            [39.32132814, -8.28830359],
                            [39.32135698, -8.28867879],
                            [39.32161667, -8.28893852],
                            [39.32194849, -8.28903953],
                            [39.32219374, -8.28901068],
                            [39.32236686, -8.28895295],
                            [39.32251112, -8.28876536],
                            [39.32249672, -8.28847677],
                            [39.32246786, -8.28817373],
                            [39.32228031, -8.2878274],
                            [39.3220062, -8.28746664],
                            [39.32181866, -8.28727905],
                            [39.32164554, -8.28712031],
                            [39.32153011, -8.28693271],
                            [39.32138585, -8.28675957],
                            [39.32096746, -8.28631222],
                            [39.32072221, -8.28609576],
                            [39.32053467, -8.2860236],
                            [39.32030382, -8.2859659],
                            [39.32018841, -8.28603804],
                            [39.32018841, -8.28632664],
                            [39.32020283, -8.28658639],
                            [39.32041923, -8.28688942],
                            [39.32060678, -8.28707703],
                            [39.32079434, -8.28727904],
                            [39.32090974, -8.28740891],
                            [39.32103959, -8.28756766],
                        ],
                        [
                            [39.3323401, -8.28689724],
                            [39.332173, -8.28686541],
                            [39.33199794, -8.28681766],
                            [39.33181494, -8.28673807],
                            [39.33163988, -8.28665052],
                            [39.3314887, -8.28663459],
                            [39.33130567, -8.2865948],
                            [39.33105901, -8.2865948],
                            [39.33089988, -8.28663459],
                            [39.33078052, -8.28668235],
                            [39.33067707, -8.28676194],
                            [39.33060547, -8.2869052],
                            [39.33058159, -8.28705642],
                            [39.33064525, -8.28715192],
                            [39.33074868, -8.2872713],
                            [39.33092373, -8.28737478],
                            [39.33118632, -8.2874862],
                            [39.33152051, -8.28765333],
                            [39.33177515, -8.28778864],
                            [39.33207751, -8.28786026],
                            [39.33222074, -8.28791598],
                            [39.33237192, -8.28804333],
                            [39.33251515, -8.28812291],
                            [39.33265041, -8.28811495],
                            [39.33278567, -8.28797965],
                            [39.33293687, -8.28781252],
                            [39.33304032, -8.28774885],
                            [39.33317558, -8.28766926],
                            [39.33343021, -8.28765334],
                            [39.33360527, -8.28767722],
                            [39.33382807, -8.28774089],
                            [39.33399516, -8.28778069],
                            [39.33410654, -8.28769315],
                            [39.33414635, -8.28752601],
                            [39.33414636, -8.28730315],
                            [39.33415431, -8.2871201],
                            [39.33410655, -8.28691317],
                            [39.33395538, -8.286754],
                            [39.33379623, -8.28662665],
                            [39.33362915, -8.28658686],
                            [39.33340634, -8.2865789],
                            [39.33321538, -8.28661074],
                            [39.33308806, -8.28666645],
                            [39.33298462, -8.28679379],
                            [39.33276977, -8.28686541],
                            [39.33255494, -8.28692112],
                            [39.3323401, -8.28689724],
                        ],
                        [
                            [39.32279967, -8.28883751],
                            [39.32287181, -8.28922713],
                            [39.32300166, -8.28953017],
                            [39.32321806, -8.2900208],
                            [39.32329019, -8.29029497],
                            [39.32326134, -8.29059801],
                            [39.32304494, -8.29084332],
                            [39.32277082, -8.29101648],
                            [39.32249671, -8.29110306],
                            [39.32222259, -8.29100204],
                            [39.32200618, -8.29084332],
                            [39.32170321, -8.29075672],
                            [39.32135697, -8.29067016],
                            [39.32111172, -8.29068458],
                            [39.32072219, -8.29069902],
                            [39.32046251, -8.29084331],
                            [39.32034707, -8.29120406],
                            [39.32034707, -8.29153597],
                            [39.32036151, -8.29196886],
                            [39.3204625, -8.29253164],
                            [39.32053464, -8.2929357],
                            [39.3205779, -8.29326757],
                            [39.32073661, -8.29359948],
                            [39.32092415, -8.29383035],
                            [39.3211117, -8.29390251],
                            [39.32131368, -8.29387366],
                            [39.32153008, -8.29375822],
                            [39.32181863, -8.2935562],
                            [39.32207831, -8.29331089],
                            [39.3225111, -8.29319544],
                            [39.32281408, -8.29289242],
                            [39.32331904, -8.29256052],
                            [39.3237807, -8.29234407],
                            [39.32434335, -8.29215646],
                            [39.324805, -8.29214204],
                            [39.32515125, -8.29214204],
                            [39.3256562, -8.29211318],
                            [39.32616114, -8.29208433],
                            [39.32655067, -8.29199776],
                            [39.32683922, -8.29195447],
                            [39.32708448, -8.29181016],
                            [39.32727201, -8.29170916],
                            [39.32741629, -8.2915937],
                            [39.32750287, -8.2914927],
                            [39.32740187, -8.29121852],
                            [39.32728646, -8.29100206],
                            [39.32707004, -8.29081448],
                            [39.32683922, -8.29058359],
                            [39.32665167, -8.29046814],
                            [39.32627658, -8.29026612],
                            [39.32582935, -8.2900641],
                            [39.32551195, -8.28996308],
                            [39.32520898, -8.28989092],
                            [39.32484831, -8.28993422],
                            [39.32448762, -8.28984764],
                            [39.3242568, -8.28963118],
                            [39.32411253, -8.28942916],
                            [39.32401155, -8.28915498],
                            [39.32398269, -8.28885196],
                            [39.32401155, -8.28850563],
                            [39.32399711, -8.28826032],
                            [39.32392498, -8.28804385],
                            [39.32380958, -8.2878707],
                            [39.32365086, -8.28776968],
                            [39.32343447, -8.28774083],
                            [39.32324693, -8.28774083],
                            [39.32307379, -8.28785627],
                            [39.32292952, -8.28798614],
                            [39.32284296, -8.28818817],
                            [39.32279968, -8.28839018],
                            [39.32279967, -8.28883751],
                        ],
                        [
                            [39.31958246, -8.28837574],
                            [39.31935164, -8.28820257],
                            [39.31919293, -8.28791398],
                            [39.31907753, -8.28756765],
                            [39.31897654, -8.28732234],
                            [39.31881784, -8.28707703],
                            [39.31863029, -8.28687499],
                            [39.31842831, -8.28673069],
                            [39.31824077, -8.28660081],
                            [39.31802437, -8.28654308],
                            [39.31791682, -8.28650435],
                            [39.31788033, -8.28654417],
                            [39.31755189, -8.28689796],
                            [39.31747883, -8.28701487],
                            [39.3174761, -8.28701928],
                            [39.31764926, -8.28714916],
                            [39.31788009, -8.28730789],
                            [39.31799551, -8.28750992],
                            [39.31819749, -8.2877408],
                            [39.3184283, -8.28801498],
                            [39.31850044, -8.28830358],
                            [39.31854373, -8.28859218],
                            [39.31858701, -8.28882307],
                            [39.31867355, -8.28924155],
                            [39.31899095, -8.28958788],
                            [39.31935163, -8.28984762],
                            [39.31969789, -8.29004964],
                            [39.32015954, -8.29017951],
                            [39.32063563, -8.29032382],
                            [39.32108284, -8.29035269],
                            [39.32129927, -8.29020837],
                            [39.3214291, -8.28987648],
                            [39.32145796, -8.28948688],
                            [39.32135698, -8.28919826],
                            [39.32122714, -8.28889523],
                            [39.32098189, -8.28863548],
                            [39.3206212, -8.28847676],
                            [39.32026054, -8.28847676],
                            [39.31982772, -8.28843347],
                            [39.31958246, -8.28837574],
                        ],
                        [
                            [39.65543678, -8.29667956],
                            [39.65428842, -8.30127394],
                            [39.64912089, -8.30184822],
                            [39.65371423, -8.30644263],
                            [39.65715925, -8.30471975],
                            [39.66003011, -8.30127397],
                            [39.66290097, -8.29897679],
                            [39.65945596, -8.29725388],
                            [39.65543678, -8.29667956],
                        ],
                        [
                            [39.61352208, -8.37535826],
                            [39.61237373, -8.37650686],
                            [39.61352206, -8.37880406],
                            [39.61696706, -8.38110127],
                            [39.62041209, -8.37880409],
                            [39.6204121, -8.37650689],
                            [39.61926377, -8.37420969],
                            [39.6169671, -8.37363538],
                            [39.61352208, -8.37535826],
                        ],
                        [
                            [39.50536419, -8.52621411],
                            [39.50684419, -8.52759734],
                            [39.50835852, -8.52897007],
                            [39.50989956, -8.53033515],
                            [39.51145204, -8.53169739],
                            [39.51299689, -8.53306058],
                            [39.51452648, -8.5344295],
                            [39.51602556, -8.535807],
                            [39.51748267, -8.53719785],
                            [39.51962641, -8.53624774],
                            [39.52227748, -8.53483211],
                            [39.52366214, -8.53231469],
                            [39.52386815, -8.53020266],
                            [39.52340662, -8.52726834],
                            [39.52327235, -8.52687282],
                            [39.52299677, -8.52606099],
                            [39.52275055, -8.52533564],
                            [39.52186941, -8.52350503],
                            [39.52033602, -8.52094177],
                            [39.52009403, -8.52055787],
                            [39.51873776, -8.5184062],
                            [39.51763922, -8.51673012],
                            [39.51651777, -8.51506645],
                            [39.51537727, -8.51341517],
                            [39.51421386, -8.5117782],
                            [39.5130314, -8.5101565],
                            [39.51183365, -8.50855101],
                            [39.51001035, -8.50617378],
                            [39.5087554, -8.50456543],
                            [39.50743561, -8.50294945],
                            [39.5060586, -8.50140215],
                            [39.50460911, -8.4999989],
                            [39.50230518, -8.4983333],
                            [39.50140883, -8.49816842],
                            [39.50032928, -8.49796985],
                            [39.49816647, -8.4983886],
                            [39.49595407, -8.5002211],
                            [39.49529415, -8.50228828],
                            [39.49614858, -8.50500319],
                            [39.49722201, -8.50669373],
                            [39.49727765, -8.50678135],
                            [39.49830372, -8.50854805],
                            [39.49939846, -8.5103052],
                            [39.50025923, -8.51244361],
                            [39.50048939, -8.51301537],
                            [39.50046349, -8.51316303],
                            [39.50013843, -8.51501674],
                            [39.49922295, -8.51710967],
                            [39.49951664, -8.51973967],
                            [39.5011113, -8.52174841],
                            [39.5012751, -8.52195474],
                            [39.50323954, -8.52410875],
                            [39.50432801, -8.52518734],
                            [39.50536419, -8.52621411],
                        ],
                        [
                            [39.57222676, -8.57015053],
                            [39.57270099, -8.57006429],
                            [39.57278721, -8.56958996],
                            [39.57326144, -8.56963308],
                            [39.57339077, -8.56924499],
                            [39.57308899, -8.56915875],
                            [39.57283033, -8.56911563],
                            [39.57239921, -8.56946059],
                            [39.57205432, -8.56946059],
                            [39.57183876, -8.56950371],
                            [39.57188187, -8.56984868],
                            [39.57192498, -8.57019365],
                            [39.57222676, -8.57015053],
                        ],
                        [
                            [39.45719149, -8.64037354],
                            [39.45899965, -8.63944839],
                            [39.46163935, -8.63800532],
                            [39.46414937, -8.636467],
                            [39.46648014, -8.6347941],
                            [39.46771225, -8.63252414],
                            [39.46760163, -8.62974007],
                            [39.46536623, -8.63052214],
                            [39.46288297, -8.63157129],
                            [39.4602127, -8.63283215],
                            [39.45836261, -8.6337678],
                            [39.45647439, -8.63476351],
                            [39.45456711, -8.63580404],
                            [39.45266359, -8.63687701],
                            [39.45077912, -8.63796811],
                            [39.44892903, -8.63906396],
                            [39.44713618, -8.64014937],
                            [39.44541959, -8.64121184],
                            [39.44400049, -8.64278461],
                            [39.44480159, -8.64490674],
                            [39.44812034, -8.64497163],
                            [39.4498865, -8.64402267],
                            [39.45169465, -8.64309653],
                            [39.45352562, -8.64218757],
                            [39.45536434, -8.64128343],
                            [39.45719149, -8.64037354],
                        ],
                        [
                            [39.5519446, -9.03082029],
                            [39.55123127, -9.03132859],
                            [39.55026251, -9.03158365],
                            [39.54903849, -9.03168693],
                            [39.54796787, -9.03204359],
                            [39.54750987, -9.03275439],
                            [39.54684891, -9.03417561],
                            [39.5466978, -9.0354439],
                            [39.54690313, -9.03630588],
                            [39.54858786, -9.03726709],
                            [39.55058011, -9.03919157],
                            [39.55252083, -9.04076104],
                            [39.55313441, -9.04172385],
                            [39.55328883, -9.04263662],
                            [39.55257633, -9.04370287],
                            [39.55267912, -9.04420994],
                            [39.55313952, -9.04507153],
                            [39.55513098, -9.04643802],
                            [39.5566129, -9.04816034],
                            [39.55814484, -9.04922318],
                            [39.56008392, -9.04967672],
                            [39.56074736, -9.0498786],
                            [39.56054418, -9.05043686],
                            [39.55993293, -9.05099574],
                            [39.55916809, -9.0511998],
                            [39.55794392, -9.05125239],
                            [39.55661813, -9.05155874],
                            [39.55534318, -9.05176357],
                            [39.55508914, -9.05242335],
                            [39.55580446, -9.0531831],
                            [39.55498907, -9.05369156],
                            [39.55386653, -9.05349037],
                            [39.55294764, -9.05303526],
                            [39.55187642, -9.05303687],
                            [39.55070334, -9.05314009],
                            [39.54947939, -9.05334482],
                            [39.54896967, -9.05359919],
                            [39.54881826, -9.05466459],
                            [39.5495858, -9.05623584],
                            [39.55060749, -9.05719803],
                            [39.55112007, -9.05882038],
                            [39.55112162, -9.05983483],
                            [39.55086927, -9.0616105],
                            [39.55092275, -9.06323354],
                            [39.55148496, -9.06394281],
                            [39.5527091, -9.06383951],
                            [39.55276182, -9.06495533],
                            [39.55306983, -9.06622292],
                            [39.55342855, -9.06728755],
                            [39.55332924, -9.06906299],
                            [39.55424965, -9.07048183],
                            [39.5560876, -9.07144276],
                            [39.55823069, -9.07179455],
                            [39.55909697, -9.07118455],
                            [39.56067766, -9.07072563],
                            [39.56241249, -9.07097657],
                            [39.56373946, -9.07138031],
                            [39.56450363, -9.07071974],
                            [39.56491006, -9.06965394],
                            [39.5647548, -9.06823395],
                            [39.56480288, -9.06635715],
                            [39.56474862, -9.06427762],
                            [39.56479741, -9.06285731],
                            [39.56535656, -9.06158839],
                            [39.56637488, -9.06036948],
                            [39.56795361, -9.05869319],
                            [39.5691738, -9.05610445],
                            [39.57110589, -9.05209437],
                            [39.57370113, -9.04813397],
                            [39.5749215, -9.04569738],
                            [39.57537841, -9.04432716],
                            [39.57609084, -9.04326087],
                            [39.57695621, -9.04214362],
                            [39.57818066, -9.04229386],
                            [39.57884442, -9.04269859],
                            [39.58006912, -9.04300099],
                            [39.58190552, -9.0430488],
                            [39.58287607, -9.04390953],
                            [39.58394922, -9.04512516],
                            [39.58471722, -9.04689921],
                            [39.58630081, -9.04831691],
                            [39.58783365, -9.04988684],
                            [39.58941668, -9.05094946],
                            [39.59033668, -9.05206387],
                            [39.59049169, -9.05328095],
                            [39.59039406, -9.05596938],
                            [39.5908558, -9.05759175],
                            [39.59188017, -9.0601262],
                            [39.59274894, -9.06108852],
                            [39.59382226, -9.06235483],
                            [39.59418109, -9.06341941],
                            [39.59398012, -9.06529646],
                            [39.59479782, -9.06620813],
                            [39.59632926, -9.06686502],
                            [39.59689274, -9.06828432],
                            [39.59699694, -9.06960292],
                            [39.59669288, -9.07082075],
                            [39.59592828, -9.07117705],
                            [39.59485534, -9.07016436],
                            [39.59311834, -9.0685948],
                            [39.59173843, -9.06702465],
                            [39.58979724, -9.06535395],
                            [39.58815982, -9.06226254],
                            [39.58677996, -9.06069236],
                            [39.58611443, -9.05922249],
                            [39.58539691, -9.05714402],
                            [39.58437506, -9.05613121],
                            [39.5835069, -9.05552393],
                            [39.58217924, -9.05466377],
                            [39.5811072, -9.05415825],
                            [39.58018934, -9.05436259],
                            [39.57983333, -9.05502255],
                            [39.57973309, -9.0561386],
                            [39.57937886, -9.05791444],
                            [39.57927912, -9.05933482],
                            [39.58030097, -9.06034765],
                            [39.58106711, -9.0609551],
                            [39.58050697, -9.06156465],
                            [39.57979419, -9.06242806],
                            [39.57938732, -9.06318954],
                            [39.57994999, -9.06415237],
                            [39.5799508, -9.06465959],
                            [39.57918603, -9.06491442],
                            [39.57796134, -9.06466275],
                            [39.57668515, -9.06410682],
                            [39.57561375, -9.06400706],
                            [39.57454314, -9.06441452],
                            [39.57388104, -9.06507495],
                            [39.57388257, -9.06603867],
                            [39.57439382, -9.06674798],
                            [39.57515989, -9.06730472],
                            [39.57623244, -9.06811459],
                            [39.57750864, -9.06867052],
                            [39.57944784, -9.06912395],
                            [39.58123336, -9.06917184],
                            [39.58220358, -9.06977896],
                            [39.58302092, -9.07048777],
                            [39.58419651, -9.07190611],
                            [39.58424843, -9.07246397],
                            [39.58394268, -9.07266735],
                            [39.58338129, -9.07251608],
                            [39.58205463, -9.07231531],
                            [39.58067712, -9.07221606],
                            [39.57975897, -9.07226824],
                            [39.57843352, -9.07282828],
                            [39.57884293, -9.07363919],
                            [39.58067915, -9.07348411],
                            [39.58266907, -9.07373455],
                            [39.58374084, -9.07403717],
                            [39.58440565, -9.07505055],
                            [39.58563219, -9.07641809],
                            [39.58675531, -9.07692351],
                            [39.58752241, -9.07808888],
                            [39.58793168, -9.07879833],
                            [39.58885043, -9.07910119],
                            [39.58900189, -9.07813722],
                            [39.59017562, -9.07838894],
                            [39.59073951, -9.08006186],
                            [39.59166067, -9.08183564],
                            [39.59370391, -9.08345543],
                            [39.59538881, -9.08431496],
                            [39.59717633, -9.08552936],
                            [39.59814797, -9.08694799],
                            [39.59855962, -9.08907764],
                            [39.59846349, -9.09262834],
                            [39.59887735, -9.09607676],
                            [39.5994408, -9.09744532],
                            [39.59944325, -9.09891626],
                            [39.59939392, -9.09993078],
                            [39.59990544, -9.10074149],
                            [39.6005701, -9.10160267],
                            [39.60149052, -9.1028692],
                            [39.602869, -9.10347558],
                            [39.6041986, -9.10535009],
                            [39.60465946, -9.10636376],
                            [39.60501795, -9.10717472],
                            [39.60501914, -9.10788482],
                            [39.60430557, -9.10829178],
                            [39.60338895, -9.10930774],
                            [39.60298209, -9.11006925],
                            [39.60354363, -9.1102712],
                            [39.60441068, -9.1101176],
                            [39.60507357, -9.10991361],
                            [39.60583987, -9.110521],
                            [39.60675913, -9.11107741],
                            [39.60701482, -9.11143203],
                            [39.60716916, -9.1121926],
                            [39.60762893, -9.11254689],
                            [39.60813894, -9.11244459],
                            [39.60920534, -9.10950094],
                            [39.60976241, -9.10706536],
                            [39.6100637, -9.10422443],
                            [39.61052044, -9.10280345],
                            [39.61092686, -9.10178833],
                            [39.61133292, -9.10057032],
                            [39.61148391, -9.09935274],
                            [39.61143134, -9.09843984],
                            [39.61086861, -9.09752779],
                            [39.61025461, -9.09646365],
                            [39.60994585, -9.09489179],
                            [39.6098926, -9.09357311],
                            [39.61034986, -9.09245646],
                            [39.61070524, -9.09144143],
                            [39.61126443, -9.09027389],
                            [39.61156751, -9.08849811],
                            [39.61166558, -9.08616474],
                            [39.61161164, -9.08444028],
                            [39.61084497, -9.0835793],
                            [39.60992483, -9.08246495],
                            [39.60997318, -9.08089249],
                            [39.6098691, -9.07967534],
                            [39.61007092, -9.07835623],
                            [39.61042681, -9.07764553],
                            [39.61098848, -9.07794892],
                            [39.61170344, -9.07840422],
                            [39.61282548, -9.07825017],
                            [39.61333527, -9.07804643],
                            [39.61435381, -9.07703028],
                            [39.61501482, -9.07576112],
                            [39.61521645, -9.07434056],
                            [39.61536724, -9.07302154],
                            [39.6158248, -9.07210777],
                            [39.61663936, -9.07114268],
                            [39.61694257, -9.06946835],
                            [39.61622425, -9.06703491],
                            [39.61596539, -9.06480358],
                            [39.61631918, -9.06287555],
                            [39.61687814, -9.06160655],
                            [39.61748889, -9.06079397],
                            [39.61855925, -9.06028494],
                            [39.62009044, -9.06078957],
                            [39.62105965, -9.06078793],
                            [39.62264055, -9.06053163],
                            [39.62325181, -9.06002337],
                            [39.62309764, -9.05936425],
                            [39.62273952, -9.05875619],
                            [39.62227946, -9.05819904],
                            [39.62251842, -9.05757808],
                            [39.62253338, -9.05753922],
                            [39.62283831, -9.05687932],
                            [39.6221757, -9.05718477],
                            [39.62184868, -9.05723412],
                            [39.62115575, -9.05733867],
                            [39.62108689, -9.05684287],
                            [39.6210008, -9.05622305],
                            [39.62089765, -9.05556384],
                            [39.62074401, -9.05520905],
                            [39.62018377, -9.05571722],
                            [39.61947494, -9.05608971],
                            [39.61921544, -9.05622608],
                            [39.61855231, -9.0562272],
                            [39.6176841, -9.05562],
                            [39.61732706, -9.05532774],
                            [39.61650922, -9.05465827],
                            [39.61610079, -9.05445607],
                            [39.61515512, -9.05450715],
                            [39.61513169, -9.05450842],
                            [39.61416318, -9.05491582],
                            [39.61304311, -9.05618575],
                            [39.61248251, -9.05649102],
                            [39.61187142, -9.05710071],
                            [39.61064699, -9.05700131],
                            [39.60977819, -9.05603904],
                            [39.60921631, -9.05558348],
                            [39.60845098, -9.05548331],
                            [39.60778862, -9.05594091],
                            [39.60661572, -9.05614574],
                            [39.60518675, -9.05574233],
                            [39.60411528, -9.05559193],
                            [39.60268631, -9.05518851],
                            [39.60176728, -9.0546828],
                            [39.60028614, -9.05356935],
                            [39.59972318, -9.05245439],
                            [39.59870189, -9.05179667],
                            [39.5966604, -9.05114062],
                            [39.59492465, -9.05028116],
                            [39.59354581, -9.04931968],
                            [39.59191135, -9.04800355],
                            [39.58986759, -9.04592723],
                            [39.58905021, -9.04516771],
                            [39.58731442, -9.0442575],
                            [39.58557733, -9.04253572],
                            [39.58460555, -9.04091416],
                            [39.58348107, -9.03949573],
                            [39.58299115, -9.0383801],
                            [39.58296911, -9.03832993],
                            [39.58220212, -9.03716454],
                            [39.58148654, -9.03625267],
                            [39.57980248, -9.03574811],
                            [39.57743556, -9.03515115],
                            [39.57740414, -9.03514323],
                            [39.57712215, -9.03499798],
                            [39.5761279, -9.03448584],
                            [39.57495352, -9.03372685],
                            [39.57393176, -9.032714],
                            [39.57250252, -9.03205685],
                            [39.57193944, -9.03078966],
                            [39.57137564, -9.02906598],
                            [39.57040555, -9.02845882],
                            [39.56953844, -9.02846017],
                            [39.56872312, -9.02896865],
                            [39.56852501, -9.02936421],
                            [39.56816379, -9.03008541],
                            [39.56750229, -9.03110089],
                            [39.56684038, -9.03186275],
                            [39.56576939, -9.03196585],
                            [39.56377923, -9.03141096],
                            [39.56204365, -9.03055134],
                            [39.56076698, -9.02958957],
                            [39.55959288, -9.02898269],
                            [39.5581632, -9.02802114],
                            [39.55709073, -9.02716048],
                            [39.55637563, -9.02650218],
                            [39.55560998, -9.02614828],
                            [39.55464117, -9.02635263],
                            [39.55367266, -9.02675988],
                            [39.55275477, -9.02691343],
                            [39.55137766, -9.02696622],
                            [39.55035822, -9.02742425],
                            [39.54969651, -9.02833825],
                            [39.54959573, -9.02914997],
                            [39.5499537, -9.0297581],
                            [39.55097369, -9.02965513],
                            [39.55173826, -9.02929892],
                            [39.55224864, -9.02950104],
                            [39.5523515, -9.03005884],
                            [39.5519446, -9.03082029],
                        ],
                        [
                            [39.51974998, -9.05707116],
                            [39.51990054, -9.0569965],
                            [39.52004787, -9.05691569],
                            [39.52019175, -9.05682886],
                            [39.52033192, -9.05673616],
                            [39.52046813, -9.05663775],
                            [39.52060017, -9.05653379],
                            [39.52072677, -9.05663379],
                            [39.52085765, -9.05672813],
                            [39.52099256, -9.05681662],
                            [39.52113122, -9.05689908],
                            [39.52127338, -9.05697537],
                            [39.52141875, -9.05704532],
                            [39.52156707, -9.05710881],
                            [39.52171803, -9.05716571],
                            [39.52187134, -9.05721591],
                            [39.52202672, -9.05725932],
                            [39.52218386, -9.05729585],
                            [39.52234245, -9.05732543],
                            [39.52250218, -9.057348],
                            [39.52266276, -9.05736352],
                            [39.52282386, -9.05737196],
                            [39.52298518, -9.0573733],
                            [39.5231464, -9.05736755],
                            [39.52330721, -9.0573547],
                            [39.52346731, -9.05733479],
                            [39.52362637, -9.05730785],
                            [39.52378409, -9.05727394],
                            [39.52394016, -9.05723313],
                            [39.5240943, -9.05718548],
                            [39.52424618, -9.0571311],
                            [39.52439553, -9.05707009],
                            [39.52454205, -9.05700256],
                            [39.52468545, -9.05692866],
                            [39.52482547, -9.05684851],
                            [39.52496183, -9.05676228],
                            [39.52509426, -9.05667013],
                            [39.52522251, -9.05657225],
                            [39.52534633, -9.05646881],
                            [39.52546548, -9.05636003],
                            [39.52557973, -9.05624611],
                            [39.52568886, -9.05612727],
                            [39.52579266, -9.05600375],
                            [39.52589093, -9.05587577],
                            [39.52598347, -9.0557436],
                            [39.5260701, -9.05560748],
                            [39.52615067, -9.05546769],
                            [39.52622501, -9.05532448],
                            [39.52629297, -9.05517814],
                            [39.52635444, -9.05502895],
                            [39.52640928, -9.0548772],
                            [39.5264574, -9.05472318],
                            [39.52649869, -9.0545672],
                            [39.52638941, -9.05451145],
                            [39.52628344, -9.05444963],
                            [39.52618111, -9.05438193],
                            [39.52608277, -9.05430858],
                            [39.52598871, -9.0542298],
                            [39.52589924, -9.05414585],
                            [39.52581464, -9.05405699],
                            [39.52573517, -9.0539635],
                            [39.52566111, -9.05386568],
                            [39.52559266, -9.05376385],
                            [39.52553006, -9.05365832],
                            [39.52547351, -9.05354943],
                            [39.52542318, -9.05343752],
                            [39.52537923, -9.05332296],
                            [39.5253418, -9.0532061],
                            [39.52531102, -9.05308732],
                            [39.52528697, -9.052967],
                            [39.52526974, -9.0528455],
                            [39.52525938, -9.05272324],
                            [39.52525593, -9.05260058],
                            [39.52525938, -9.05247792],
                            [39.52526975, -9.05235565],
                            [39.52528698, -9.05223416],
                            [39.52531102, -9.05211383],
                            [39.52534181, -9.05199505],
                            [39.52537924, -9.0518782],
                            [39.52542319, -9.05176364],
                            [39.52547352, -9.05165173],
                            [39.52553007, -9.05154284],
                            [39.52559267, -9.05143731],
                            [39.52566112, -9.05133548],
                            [39.52573519, -9.05123766],
                            [39.52581465, -9.05114417],
                            [39.52589925, -9.05105531],
                            [39.52598873, -9.05097136],
                            [39.52608279, -9.05089258],
                            [39.52618113, -9.05081923],
                            [39.52628345, -9.05075154],
                            [39.52638943, -9.05068972],
                            [39.52649871, -9.05063397],
                            [39.52665161, -9.05059544],
                            [39.5268026, -9.05055],
                            [39.52695138, -9.05049777],
                            [39.52709763, -9.05043884],
                            [39.52724106, -9.05037333],
                            [39.52738138, -9.05030138],
                            [39.52751828, -9.05022314],
                            [39.5276515, -9.05013877],
                            [39.52778075, -9.05004844],
                            [39.52790577, -9.04995234],
                            [39.52802631, -9.04985067],
                            [39.52814211, -9.04974363],
                            [39.52825294, -9.04963145],
                            [39.52835856, -9.04951435],
                            [39.52845877, -9.04939259],
                            [39.52855335, -9.0492664],
                            [39.52864211, -9.04913605],
                            [39.52872487, -9.04900181],
                            [39.52880145, -9.04886395],
                            [39.5288717, -9.04872276],
                            [39.52893548, -9.04857852],
                            [39.52899265, -9.04843154],
                            [39.5290431, -9.04828212],
                            [39.52908672, -9.04813056],
                            [39.52912342, -9.04797718],
                            [39.52915312, -9.0478223],
                            [39.52917577, -9.04766622],
                            [39.52919132, -9.04750928],
                            [39.52919974, -9.04735179],
                            [39.529201, -9.04719408],
                            [39.52919511, -9.04703648],
                            [39.52918208, -9.04687931],
                            [39.52916194, -9.04672289],
                            [39.52913472, -9.04656755],
                            [39.52910048, -9.0464136],
                            [39.5290593, -9.04626136],
                            [39.52901126, -9.04611115],
                            [39.52895645, -9.04596327],
                            [39.52838371, -9.0455218],
                            [39.52780705, -9.04508548],
                            [39.5272265, -9.04465433],
                            [39.52664212, -9.0442284],
                            [39.52605395, -9.04380772],
                            [39.52546204, -9.04339232],
                            [39.52486644, -9.04298223],
                            [39.52426719, -9.04257749],
                            [39.52366435, -9.04217813],
                            [39.52305795, -9.04178419],
                            [39.52269384, -9.04180122],
                            [39.52233011, -9.04182493],
                            [39.52196686, -9.04185529],
                            [39.52160424, -9.04189229],
                            [39.52124235, -9.04193593],
                            [39.52088132, -9.04198619],
                            [39.52052127, -9.04204305],
                            [39.52016232, -9.04210649],
                            [39.51980459, -9.0421765],
                            [39.51944821, -9.04225305],
                            [39.51909328, -9.0423361],
                            [39.51873994, -9.04242565],
                            [39.51838829, -9.04252164],
                            [39.51617634, -9.04399659],
                            [39.51604116, -9.044121],
                            [39.51591088, -9.04425053],
                            [39.5157857, -9.04438499],
                            [39.51566579, -9.04452418],
                            [39.51555135, -9.0446679],
                            [39.51544253, -9.04481593],
                            [39.51533951, -9.04496804],
                            [39.51524244, -9.04512403],
                            [39.51515145, -9.04528364],
                            [39.51506669, -9.04544664],
                            [39.51498829, -9.0456128],
                            [39.51491635, -9.04578186],
                            [39.51485099, -9.04595357],
                            [39.5147923, -9.04612767],
                            [39.51474038, -9.04630391],
                            [39.51469529, -9.04648203],
                            [39.51465711, -9.04666175],
                            [39.51462589, -9.04684281],
                            [39.51460168, -9.04702494],
                            [39.51458452, -9.04720787],
                            [39.51457443, -9.04739132],
                            [39.51457142, -9.04757503],
                            [39.5145755, -9.04775872],
                            [39.51458667, -9.04794212],
                            [39.5146049, -9.04812494],
                            [39.51463018, -9.04830693],
                            [39.51466246, -9.0484878],
                            [39.51470169, -9.0486673],
                            [39.51482014, -9.04860575],
                            [39.51494169, -9.04855056],
                            [39.515066, -9.0485019],
                            [39.51519271, -9.0484599],
                            [39.51532146, -9.04842467],
                            [39.51545191, -9.04839632],
                            [39.51558367, -9.04837492],
                            [39.51571638, -9.04836054],
                            [39.51584966, -9.04835321],
                            [39.51598315, -9.04835297],
                            [39.51611646, -9.0483598],
                            [39.51624922, -9.04837368],
                            [39.51638106, -9.04839459],
                            [39.51651161, -9.04842246],
                            [39.51664049, -9.0484572],
                            [39.51676736, -9.04849874],
                            [39.51689184, -9.04854694],
                            [39.5170136, -9.04860167],
                            [39.51713228, -9.04866278],
                            [39.51724756, -9.0487301],
                            [39.51735911, -9.04880343],
                            [39.51746662, -9.04888257],
                            [39.51756978, -9.04896731],
                            [39.5176683, -9.04905739],
                            [39.51776192, -9.04915257],
                            [39.51785036, -9.04925258],
                            [39.51793337, -9.04935713],
                            [39.51801073, -9.04946594],
                            [39.51808221, -9.0495787],
                            [39.51814762, -9.04969509],
                            [39.51820676, -9.04981479],
                            [39.51825948, -9.04993745],
                            [39.51830563, -9.05006273],
                            [39.51834507, -9.05019029],
                            [39.5183777, -9.05031975],
                            [39.51840342, -9.05045077],
                            [39.51842216, -9.05058296],
                            [39.51843387, -9.05071596],
                            [39.51843852, -9.0508494],
                            [39.51843609, -9.05098289],
                            [39.51842658, -9.05111607],
                            [39.51841004, -9.05124856],
                            [39.51838649, -9.05137998],
                            [39.51835602, -9.05150997],
                            [39.5183187, -9.05163816],
                            [39.51827463, -9.0517642],
                            [39.51822396, -9.05188772],
                            [39.5181668, -9.05200837],
                            [39.51810334, -9.05212583],
                            [39.51803373, -9.05223976],
                            [39.51795819, -9.05234984],
                            [39.51787692, -9.05245576],
                            [39.51779016, -9.05255723],
                            [39.51769814, -9.05265395],
                            [39.51760112, -9.05274565],
                            [39.51749938, -9.05283208],
                            [39.5173932, -9.052913],
                            [39.51728288, -9.05298818],
                            [39.51716873, -9.0530574],
                            [39.51705108, -9.05312047],
                            [39.51693025, -9.05317721],
                            [39.51680658, -9.05322747],
                            [39.51668042, -9.0532711],
                            [39.51655213, -9.05330799],
                            [39.51642207, -9.05333802],
                            [39.5163679, -9.05325782],
                            [39.51630806, -9.05318177],
                            [39.51624284, -9.05311028],
                            [39.5161726, -9.05304372],
                            [39.5160977, -9.05298244],
                            [39.51601855, -9.05292678],
                            [39.51593556, -9.05287702],
                            [39.51584917, -9.05283342],
                            [39.51575985, -9.05279622],
                            [39.51566805, -9.05276562],
                            [39.51557427, -9.05274178],
                            [39.51547901, -9.05272482],
                            [39.51538277, -9.05271483],
                            [39.51528606, -9.05271187],
                            [39.51518938, -9.05271595],
                            [39.51509326, -9.05272705],
                            [39.5149982, -9.05274511],
                            [39.51490471, -9.05277004],
                            [39.51481328, -9.0528017],
                            [39.51472438, -9.05283993],
                            [39.51463851, -9.05288452],
                            [39.5145561, -9.05293524],
                            [39.5144776, -9.05299181],
                            [39.51440342, -9.05305395],
                            [39.51433395, -9.05312132],
                            [39.51426956, -9.05319356],
                            [39.5142106, -9.0532703],
                            [39.51415736, -9.05335112],
                            [39.51411015, -9.05343559],
                            [39.5140692, -9.05352328],
                            [39.51403474, -9.05361371],
                            [39.51400694, -9.05370641],
                            [39.51398595, -9.05380089],
                            [39.51397189, -9.05389664],
                            [39.51396482, -9.05399316],
                            [39.51396479, -9.05408995],
                            [39.5139718, -9.05418647],
                            [39.5139858, -9.05428223],
                            [39.51400673, -9.05437672],
                            [39.51403447, -9.05446944],
                            [39.51406888, -9.0545599],
                            [39.51410977, -9.05464761],
                            [39.51415693, -9.05473211],
                            [39.51421011, -9.05481297],
                            [39.51427212, -9.05496918],
                            [39.51434052, -9.0551227],
                            [39.5144152, -9.05527326],
                            [39.51449605, -9.05542061],
                            [39.5145829, -9.05556448],
                            [39.51467563, -9.05570465],
                            [39.51477407, -9.05584087],
                            [39.51487805, -9.0559729],
                            [39.51498739, -9.05610052],
                            [39.51510192, -9.05622351],
                            [39.51522142, -9.05634167],
                            [39.51534571, -9.05645478],
                            [39.51547456, -9.05656267],
                            [39.51560776, -9.05666513],
                            [39.51574508, -9.05676201],
                            [39.51588628, -9.05685312],
                            [39.51603112, -9.05693833],
                            [39.51617936, -9.05701747],
                            [39.51633074, -9.05709042],
                            [39.51648501, -9.05715706],
                            [39.5166419, -9.05721726],
                            [39.51680114, -9.05727092],
                            [39.51696246, -9.05731796],
                            [39.51712558, -9.0573583],
                            [39.51729024, -9.05739185],
                            [39.51745614, -9.05741858],
                            [39.517623, -9.05743842],
                            [39.51779054, -9.05745136],
                            [39.51795846, -9.05745735],
                            [39.5181265, -9.05745641],
                            [39.51829435, -9.05744852],
                            [39.51846173, -9.0574337],
                            [39.51862836, -9.05741197],
                            [39.51879395, -9.05738338],
                            [39.51895821, -9.05734797],
                            [39.51912087, -9.0573058],
                            [39.51928165, -9.05725695],
                            [39.51944028, -9.05720149],
                            [39.51959648, -9.05713952],
                            [39.51974998, -9.05707116],
                        ],
                        [
                            [40.33842307, -10.25832444],
                            [40.34382064, -10.26121464],
                            [40.34613225, -10.26094471],
                            [40.34770387, -10.25746412],
                            [40.34526259, -10.25130318],
                            [40.3421452, -10.2477384],
                            [40.34208475, -10.24767501],
                            [40.34197252, -10.24754094],
                            [40.34102931, -10.24646237],
                            [40.3376377, -10.24258401],
                            [40.33277824, -10.23826745],
                            [40.33219722, -10.2379377],
                            [40.33035095, -10.23708986],
                            [40.32847417, -10.23635553],
                            [40.32664992, -10.23576502],
                            [40.32652507, -10.23573017],
                            [40.32451463, -10.2354075],
                            [40.32166499, -10.2359845],
                            [40.32009333, -10.23735303],
                            [40.31907866, -10.23913169],
                            [40.31875828, -10.24113349],
                            [40.31890489, -10.24153452],
                            [40.31883082, -10.24199741],
                            [40.32125386, -10.24643269],
                            [40.32545367, -10.24705271],
                            [40.32614593, -10.24952473],
                            [40.32704057, -10.25035897],
                            [40.32739525, -10.25247723],
                            [40.32757764, -10.2533472],
                            [40.32999294, -10.2541036],
                            [40.33365111, -10.25127355],
                            [40.3360169, -10.25476916],
                            [40.33611933, -10.25486829],
                            [40.33625591, -10.25512232],
                            [40.33842307, -10.25832444],
                        ],
                        [
                            [40.37368422, -10.2732364],
                            [40.36980861, -10.27177825],
                            [40.36839029, -10.27419069],
                            [40.36825673, -10.27645186],
                            [40.36834453, -10.27885423],
                            [40.36839335, -10.27943455],
                            [40.36840628, -10.27978837],
                            [40.3685113, -10.28103676],
                            [40.36939408, -10.28215747],
                            [40.37309226, -10.28367348],
                            [40.37612357, -10.28209687],
                            [40.37703299, -10.27791274],
                            [40.37542089, -10.27437568],
                            [40.37476836, -10.27388264],
                            [40.37368422, -10.2732364],
                        ],
                        [
                            [40.39482249, -10.32086987],
                            [40.39548997, -10.32385896],
                            [40.39572271, -10.32594766],
                            [40.39915202, -10.32638546],
                            [40.40138355, -10.32597342],
                            [40.40333663, -10.32757389],
                            [40.40636551, -10.32897684],
                            [40.40841772, -10.32857343],
                            [40.40881827, -10.33083189],
                            [40.40839102, -10.33311235],
                            [40.40842908, -10.33521161],
                            [40.40910818, -10.33712385],
                            [40.40995123, -10.33901522],
                            [40.41055389, -10.34188511],
                            [40.41043558, -10.34388892],
                            [40.4102907, -10.3459748],
                            [40.41009614, -10.34810269],
                            [40.40984045, -10.35023338],
                            [40.40950101, -10.35232695],
                            [40.40906233, -10.35434407],
                            [40.40817734, -10.35714058],
                            [40.4069681, -10.35954305],
                            [40.40475939, -10.3608421],
                            [40.4026423, -10.36105856],
                            [40.40035735, -10.36114537],
                            [40.39820201, -10.3614362],
                            [40.39631767, -10.3621077],
                            [40.39447895, -10.36290596],
                            [40.3926708, -10.36378916],
                            [40.39089327, -10.36473624],
                            [40.38829537, -10.36623462],
                            [40.38680015, -10.36780158],
                            [40.3887341, -10.36975783],
                            [40.39053088, -10.37101107],
                            [40.39225122, -10.37229388],
                            [40.39350244, -10.37460004],
                            [40.39216727, -10.37639501],
                            [40.38725405, -10.37684904],
                            [40.38589989, -10.37525527],
                            [40.38324495, -10.3742958],
                            [40.38116211, -10.37440643],
                            [40.38009754, -10.37450107],
                            [40.38447536, -10.37822579],
                            [40.38561712, -10.38031462],
                            [40.38948628, -10.3844854],
                            [40.39356781, -10.3854398],
                            [40.39484873, -10.38483602],
                            [40.39657802, -10.3831834],
                            [40.39709294, -10.38011531],
                            [40.39828703, -10.37696948],
                            [40.39910626, -10.3744235],
                            [40.39977414, -10.37180341],
                            [40.40172885, -10.36992546],
                            [40.40512403, -10.36938719],
                            [40.40685585, -10.36833274],
                            [40.40934768, -10.36839659],
                            [40.41191232, -10.3678619],
                            [40.41289415, -10.36793236],
                            [40.41538799, -10.36844482],
                            [40.41885356, -10.36678445],
                            [40.42231841, -10.36497451],
                            [40.42495849, -10.36443938],
                            [40.42676571, -10.3633845],
                            [40.42902895, -10.36300056],
                            [40.43068956, -10.3629184],
                            [40.4315197, -10.36283993],
                            [40.43325648, -10.36290696],
                            [40.43680214, -10.36229293],
                            [40.43913642, -10.36093652],
                            [40.44048136, -10.3578647],
                            [40.44152669, -10.35531765],
                            [40.44185524, -10.35482471],
                            [40.44228662, -10.35340557],
                            [40.44249344, -10.35209796],
                            [40.44266041, -10.35134709],
                            [40.44273408, -10.35080096],
                            [40.44261203, -10.34874658],
                            [40.44155918, -10.34603983],
                            [40.44038033, -10.34439836],
                            [40.43901105, -10.34285802],
                            [40.43759583, -10.34142451],
                            [40.43601273, -10.34017696],
                            [40.43432664, -10.33902578],
                            [40.43270924, -10.3378231],
                            [40.43075997, -10.33561514],
                            [40.42919969, -10.33309053],
                            [40.42777698, -10.33045241],
                            [40.42687675, -10.32865455],
                            [40.42599546, -10.32684051],
                            [40.42511435, -10.32502352],
                            [40.42421412, -10.32321904],
                            [40.42327952, -10.32143931],
                            [40.42229154, -10.31969777],
                            [40.42067414, -10.31718647],
                            [40.41889656, -10.31478018],
                            [40.4176339, -10.3132226],
                            [40.41632549, -10.31169951],
                            [40.41497896, -10.31020781],
                            [40.41359805, -10.30874567],
                            [40.41219427, -10.30731022],
                            [40.41006194, -10.3052024],
                            [40.40858934, -10.30379757],
                            [40.40705981, -10.30241075],
                            [40.40547672, -10.30109075],
                            [40.40303921, -10.29934252],
                            [40.40049863, -10.29813215],
                            [40.3984578, -10.29868726],
                            [40.39674883, -10.29979936],
                            [40.39523066, -10.30112217],
                            [40.39386118, -10.30263105],
                            [40.39270533, -10.30426958],
                            [40.39119097, -10.306839],
                            [40.38978706, -10.30948862],
                            [40.38888688, -10.31128166],
                            [40.38800189, -10.31308426],
                            [40.38711309, -10.31488592],
                            [40.38620904, -10.31667615],
                            [40.38519437, -10.31853594],
                            [40.38381339, -10.32074296],
                            [40.38246303, -10.32291853],
                            [40.38448099, -10.3242881],
                            [40.38649889, -10.32311118],
                            [40.38868092, -10.3218522],
                            [40.39080944, -10.32077254],
                            [40.39278541, -10.3202003],
                            [40.39482249, -10.32086987],
                        ],
                    ],
                ],
            },
            geometry_name: "the_geom",
            properties: {
                LAKES: "Indian Ocean",
                Region_Nam: "",
                Region_Cod: null,
                zoneId: null,
            },
            drilldown: null,
            region_nam: "",
        },
    ],
    totalFeatures: 52,
    numberMatched: 52,
    numberReturned: 52,
    timeStamp: "2023-12-02T05:22:40.724Z",
    crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG::4210" } },
}

export default mapData
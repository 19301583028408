import { notifications } from "@mantine/notifications";
import * as XLSX from "xlsx";

export function downloadLineTrendData(props: {
  name: string;
  extension: "xlsx" | "csv";
  columnHeaders: string[];
  data: { year: string, value: number}[];
}) {
  try {
    const csvData = [props.columnHeaders, ...props.data.map(dt => [dt.year.toString(), dt.value.toString()])]
    const worksheet = XLSX.utils.json_to_sheet(csvData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    /* fix row A1 */
    XLSX.utils.sheet_add_aoa(worksheet, [props.columnHeaders.map((_, __) => "")], { origin: "A1" });

    /* calculate column width */
    let min_width = 20;
    let max_width = min_width;
    csvData.forEach((csvEntry) => {
      let _max_width = (csvEntry as string[]).reduce((w, r) => Math.max(w, r?.length ?? 10), min_width);
      max_width = Math.max(_max_width, max_width);
    });

    const fileExtension = `.${props.extension}`;

    worksheet["!cols"] = props.columnHeaders.map(() => ({ wch: max_width }));

    const fileName = `${props.name}${fileExtension}`;

    XLSX.writeFile(workbook, fileName);
  } catch (error) {
    notifications.show({ message: `Error downloading ${props.extension} file`, color: "red" });
    throw error;
  }
}
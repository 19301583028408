import { Footer, Group } from "@mantine/core";
import { Logo } from "../logo";

export const FooterCmp: React.FC = () => {
    return (
        <Footer
            height={80}
            p="md"
            sx={(theme) => ({
                backgroundColor: "white",
                borderTop: `1px solid ${theme.colors.gray[3]}`,
                position: "relative",
            })}
        >
            <Group align="center" position="left">
                <Logo />
            </Group>
        </Footer>
    );
};

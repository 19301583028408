import request from "./axios";
import qs from "qs";
import geo_server_env_config from "./geo-server-env-config";

export default function geoService(serviceParams: any) {
    const params = qs.stringify(serviceParams);
    return request({
        url: "cite/ows?" + params,
        baseURL: geo_server_env_config.GEOSERVER_BASEURL,
        method: "get",
        timeout: 72000,
        bfLoading: false,
        isAlertErrorMsg: false,
        headers: {
            "content-type": "application/json",
        },
    });
}
